import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';

export default (theme: KomodoTheme) =>
  createStyles({
    clearFiltersContainer: {
      display: 'flex',
      fontFamily: theme.typography.fontFamily,
      fontHeight: '1rem',
      fontSize: '1rem',
      fontWeight: 300,
      paddingTop: 30,
      placeItems: 'baseline',
    },
    clearFiltersText: {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
    filterText: {
      fontFamily: theme.typography.fontFamily,
      fontSize: '1rem',
      fontWeight: 300,
    },
    showingFiltersText: {
      paddingRight: 10,
    },
  });
