import { QueryHookOptions, useQuery } from '@apollo/client';

import { ProgramBreakdownQuery, ProgramBreakdownQueryVariables } from '../../../generated/graphql';
import { programBreakdownQuery } from '../queries';

export const useProgramBreakdownQuery = (
  programID: UUID,
  projectIDs: UUID[],
  options?: QueryHookOptions<ProgramBreakdownQuery, ProgramBreakdownQueryVariables>
) =>
  useQuery<ProgramBreakdownQuery, ProgramBreakdownQueryVariables>(programBreakdownQuery, {
    variables: {
      programID,
      projectIDs,
    },
    skip: !programID || !projectIDs.length,
    ...options,
  });
