import { createStyles } from '@material-ui/core';

const AssetsActionMenuStyles = () =>
  createStyles({
    black: {
      color: 'black',
    },
    hasUrl: {
      textDecoration: 'underline',
    },
    positionButton: {
      position: 'relative',
      right: 4,
      bottom: 36,
      zIndex: 160,
    },
  });

export default AssetsActionMenuStyles;
