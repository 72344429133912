import { InsightsMilestone } from '../../../../generated/graphql';

type Props = {
  data: InsightsMilestone;
};

export default function MilestoneTooltipContent(props: Props) {
  return (
    <>
      <strong className="type-body2">{props.data.name}</strong>
      <div className="type-body2">{props.data.designPhase}</div>
      {props.data.date && (
        <div className="type-body2">
          {props.data.date.toLocaleString(undefined, { dateStyle: 'short' })}
        </div>
      )}
    </>
  );
}
