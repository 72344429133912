import { ReactNode } from 'react';

type Props = {
  children: ReactNode;
};

const CardHeader = (props: Props) => (
  <div className="flex h-[107px] flex-shrink-0 flex-col gap-2 p-3">{props.children}</div>
);

export default CardHeader;
