import { CostRangeInput } from '../../../generated/graphql';
import { NumberInput } from '../../scales';
import ExpandableFilterContainer from '../common/ExpandableFilterContainer';

type Props = {
  'data-cy'?: string;
  description?: string;
  onChange: (value: CostRangeInput) => void;
  title: string;
  value: {
    min?: number | null;
    max?: number | null;
  };
};

export default function NumberRangeFilters(props: Props) {
  return (
    <ExpandableFilterContainer
      canReset={typeof props.value.min === 'number' || typeof props.value.max === 'number'}
      data-cy={props['data-cy']}
      description={props.description}
      onReset={() => props.onChange({ min: null, max: null })}
      title={props.title}
    >
      <div className="flex flex-row gap-2">
        <div className="min-w-0 flex-1">
          <NumberInput
            label="Min"
            onChange={(value) => props.onChange({ min: value, max: props.value.max })}
            placeholder="0"
            value={props.value.min ?? null}
          />
        </div>
        <div className="min-w-0 flex-1">
          <NumberInput
            label="Max"
            onChange={(value) => props.onChange({ min: props.value.min, max: value })}
            placeholder="99999"
            value={props.value.max ?? null}
          />
        </div>
      </div>
    </ExpandableFilterContainer>
  );
}
