import { IdeaGroupsQuery, IdeaGroupsQueryVariables } from '../../../../generated/graphql';
import { QueryHookOptions, useQuery } from '../../../../hooks/useMountAwareQuery';
import { ideaGroupsQuery } from './queries';

export default function useIdeaGroupsQuery(
  projectID: UUID,
  milestoneID: UUID | undefined,
  options?: QueryHookOptions<IdeaGroupsQuery, IdeaGroupsQueryVariables>
) {
  return useQuery<IdeaGroupsQuery, IdeaGroupsQueryVariables>(ideaGroupsQuery, {
    skip: !projectID,
    ...options,
    variables: { projectID, milestoneID },
  });
}
