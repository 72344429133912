import { Permission } from '../../../../generated/graphql';
import { filterMarkupModePermissions } from '../utils';

import MarkupModePermissionsTableView from './MarkupModePermissionsTableView';
import PermissionsHideCostsView from './PermissionsHideCostsView';

type Props = {
  displayCostPermission: Permission;
  markupModePermissions: Permission[];
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  hideCosts: boolean;
};

const CostParamsTableView = (props: Props) => {
  const markupModePermissions = filterMarkupModePermissions(props.markupModePermissions);

  return (
    <div className="w-7/12 border-b">
      <div className="grid grid-cols-7">
        <div className="col-span-3 px-2 py-2 type-button">Feature</div>
        <div className="col-span-4 px-2 py-2 type-button">Description</div>
      </div>
      <PermissionsHideCostsView displayCostPermission={props.displayCostPermission} />
      <MarkupModePermissionsTableView
        hideCosts={props.hideCosts}
        permissions={markupModePermissions}
      />
    </div>
  );
};

export default CostParamsTableView;
