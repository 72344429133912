import { createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
export default (theme: any) =>
  createStyles({
    captionColor: {
      color: theme.palette.joinPrimary,
      fontSize: 12,
      padding: '2px 0px 2px 0px',
    },
    contentContainer: { display: 'flex', flexDirection: 'column', gap: '8px' },
    field: {
      width: '100%',
      minWidth: '100%',
      '@media print': {
        padding: 0,
        paddingRight: 16,
        flex: 1,
      },
    },
    noPrint: {
      '@media print': {
        display: 'none',
        height: 0,
        width: 0,
      },
    },
  });
