import { useRef } from 'react';
import { useButton } from 'react-aria';

import { Clear } from '@material-ui/icons';

export default function ClearIconButton(props: { onClick: () => void }) {
  const ref = useRef<HTMLButtonElement>(null);
  const { buttonProps } = useButton(
    {
      'aria-label': 'clear',
      onPress: props.onClick,
    },
    ref
  );

  return (
    <button
      {...buttonProps}
      ref={ref}
      className="flex items-center rounded-full p-1 text-base outline-none hover:bg-button-secondary-hover focus-visible:outline active:outline-none"
    >
      <Clear fontSize="inherit" />
    </button>
  );
}
