import { ReactNode, RefObject, useLayoutEffect, useRef, useState } from 'react';

type Props = {
  children: ReactNode;
  id: UUID;
  onUnmount: (id: UUID) => void;
  /**
   * Passing in a scroll container ref makes this component load in the children before they
   * are scrolled into view, minimizing jank as the component is rendered for the first time.
   */
  scrollContainerRef?: RefObject<HTMLElement>;
  /**
   * The estimated size is used for calculating total height and thus
   * the scroll position. Guess too low and the scroll indicator will
   * get to the bottom before the list is over. Guess too high and the
   * opposite will happen. In both cases you will be able to navigate to
   * the full extents of the list, you'll just notice a bit of scrollbar
   * jank if you're being very observant. */
  heightEstimate: number;
};

/**
 * This component renders its children if they are in the viewport and a placeholder if they are
 * not. The purpose it serves is mainly a particular set of scenarios in when virtualizing lists.
 * Standard virtualization techniques tend to require a flat list of components/elements where you
 * have a single scroll container containing children that are rows. This works in most cases,
 * but tends to fall apart when you have a tree structure of nested lists that /also/ need to be
 * virtualized. In those cases, you're stuck because the nested list will always render entirely.
 *
 * This approach handles any arbitrary nesting or DOM layout because it relies on the
 * IntersectionObserver API to tell us whether an element will be visible or not, rather than the
 * scroll position itself.
 *
 * The downside of this is that we need to actually render something for every element, even those
 * that have scrolled out of view. This is kind of ugly if you're looking at the DOM via Dev Tools,
 * but performance wise, it shouldn't be a problem until you're milling around 10k elements and
 * doing resize/reflow operations.
 *
 * At which point...maybe consider paginating your query?
 */

export default function IfVisible(props: Props) {
  const [isVisible, setIsVisible] = useState(false);

  const ref = useRef<HTMLDivElement>(null);
  const height = useRef(props.heightEstimate);

  useLayoutEffect(() => {
    const element = ref.current;
    if (!element) {
      console.error('IfVisible: Fatally failed to find the wrapping element.');
      return () => {};
    }

    /**
     * Set up an IntersectionObserver such that whenever this component scrolls into view
     * we toggle `isVisible` to true, and vice versa. */
    const observer = new IntersectionObserver(
      ([entry]) => {
        height.current = entry.boundingClientRect.height;
        setIsVisible(entry.isIntersecting);
        if (isVisible && !entry.isIntersecting) props.onUnmount(props.id);
      },
      /**
       * By default, the root is null so we watch for intersection of the device's viewport.
       * By passing in a value for `root`, we set up this observer to notify us 500px before/after
       * element has scrolled into/out-of view. */
      { root: props.scrollContainerRef?.current ?? null, rootMargin: '1000px 0px 1000px 0px' }
    );

    observer.observe(element);

    return () => observer.unobserve(element);
  }, [isVisible, props, props.id, props.scrollContainerRef]);

  /**
   * At some point, this may benefit from having a placeholder passed in, or at least
   * being able to override the classname etc, but I consciously stripped that functionality.
   * I originally did have it in placeholder but it made me realize that I could better fix
   * the problem of showing loading rows by using under/overscan rows via `rootMargin` in the
   * IntersectionObserver. Even if your row is slow to fully render (eg lazy-loading), consider
   * whether the loading indicator should be in /that/ component.  */
  const placeholder = <div data-cy="if-visible-placeholder" style={{ height: height.current }} />;

  return <div ref={ref}>{isVisible ? props.children : placeholder}</div>;
}
