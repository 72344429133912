import { FC } from 'react';

import { Typography } from '@material-ui/core';

import { ITEM_NUMBER_CHARLIMIT } from '../../../../constants';
import { withStyles } from '../../../../theme/komodo-mui-theme';
import { formatItemDescriptor } from '../../../Items/ItemsQuickLink/ItemsQuickLink';
import ItemsIcons from '../../../ItemsList/ItemsIcons/ItemsIcons';
import { CostReportCategoriesTreeNode } from '../CostReportCategoriesTree';

import { styles } from './CostReportListRowStyles';
import { cellDescriptionText } from './CostReportListRowUtils';

type MSRItemRowDescriptionPrintProps = {
  classes: Classes<typeof styles>;
  fontWeight: number;
  height: number;
  width: number;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  parentIsChosen: boolean;
  treeNode: CostReportCategoriesTreeNode;
};

const MSRItemRowDescriptionPrint: FC<MSRItemRowDescriptionPrintProps> = ({
  classes,
  fontWeight,
  height,
  width,
  parentIsChosen,
  treeNode,
}) => {
  const {
    data: { id: itemId, status },
  } = treeNode;
  return (
    <div className={classes.itemPrintRow}>
      <div className={classes.itemRow} style={{ height }}>
        {status && (
          <ItemsIcons
            notChosen={parentIsChosen}
            status={treeNode.data.status}
            variant={undefined}
          />
        )}
        {itemId && status ? (
          <Typography
            className={classes.itemDescription}
            style={{ fontWeight, width, paddingRight: itemId && status ? '8px' : '0px' }}
          >
            {formatItemDescriptor(treeNode.data, true, false, ITEM_NUMBER_CHARLIMIT)}
          </Typography>
        ) : (
          <Typography className={classes.itemDescription} style={{ fontWeight, width }}>
            {cellDescriptionText(treeNode)}
          </Typography>
        )}
        <div className={classes.spacer} />
      </div>
    </div>
  );
};

export default withStyles(styles)(MSRItemRowDescriptionPrint);
