import { FC } from 'react';

import { Dialog, DialogActions, DialogContent, Divider, Typography } from '@material-ui/core';
import { DeleteOutline } from '@material-ui/icons';

import {
  CategorizationEvent,
  categorizationEvent,
} from '../../../analytics/analyticsEventProperties';
import { RemoveCategorizationsPayload } from '../../../generated/graphql';
import useSendAnalytics from '../../../hooks/useSendAnalytics';
import { withStyles } from '../../../theme/komodo-mui-theme';
import { removeYear } from '../../../utilities/string';
import { getProjectIdFromUrl } from '../../../utilities/url';
import { Button } from '../../scales';
import ShadedHelpTip from '../../shared-widgets/ShadedHelpTip/ShadedHelpTip';
import { useRemoveCategorizations } from '../../table/hooks/hooks';
import styles from '../DialogsStyles';

type DialogsRemoveCategorizationProps = {
  classes: Classes<typeof styles>;
  categorization: CategorizationMetadata;
  onClose: () => void;
  onSuccess?: (payload: RemoveCategorizationsPayload) => void;
};

const DialogsRemoveCategorization: FC<DialogsRemoveCategorizationProps> = ({
  classes,
  categorization,
  onClose,
  onSuccess,
}) => {
  const projectId = getProjectIdFromUrl();
  const categorizationName = categorization
    ? `${removeYear(categorization.name, (categorization as CategorizationFeDefined).builtin)}`
    : '';

  const removeCategorizations = useRemoveCategorizations();
  const sendAnalytics = useSendAnalytics();

  const isStandard = !!categorization.createdFrom;

  const standardTip =
    'You can always add back the latest version of a company standard categorization in Manage Categorization.';

  const projectCatTip =
    'You can download the categorization details as a backup to easily restore later if needed.';

  return (
    <Dialog disableBackdropClick open>
      <div className={classes.titleContainer}>
        <Typography variant="title">{`Delete Categorization: ${categorizationName}`}</Typography>
      </div>
      <Divider />
      <DialogContent className={classes.content}>
        <Typography>
          Are you sure? Deleting this categorization is a permanent action and cannot be undone. If
          any items or milestone costs were tagged to this categorization, their categories will be
          removed.
        </Typography>
        <div className={classes.tipContainer}>
          <ShadedHelpTip shiftLeft tip={isStandard ? standardTip : projectCatTip} />
        </div>
      </DialogContent>
      <DialogActions className={classes.action}>
        <Button
          label="Cancel"
          onClick={() => {
            sendAnalytics(
              categorizationEvent(CategorizationEvent.DELETE_CANCEL, {
                projectID: projectId,
                categorizationID: categorization.id,
              })
            );
            onClose();
          }}
          type="secondary"
        />
        <Button
          data-cy="delete-categorization-confirm-button"
          label="Delete"
          onClick={() => {
            removeCategorizations(projectId, [categorization.id], onSuccess);
            sendAnalytics(
              categorizationEvent(CategorizationEvent.DELETE, {
                projectID: projectId,
                categorizationID: categorization.id,
              })
            );
            onClose();
          }}
          startIcon={<DeleteOutline />}
          type="destructive"
        />
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(DialogsRemoveCategorization);
