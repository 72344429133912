import * as React from 'react';
import { useEffectOnce } from 'react-use';

interface Focusable {
  focus: () => void;
}

// used by editors to grab focus from a DOM element when they first mount, and to return
// focus to that DOM element when they unmount, as well as perform other instantiation and
// cleanup actions
function useFocusHandler<T extends Focusable>(
  inputRef: React.RefObject<T> | null,
  previousFocusedElement: unknown | null,
  onFocus?: () => void,
  onCleanup?: () => void
) {
  useEffectOnce(() => {
    setTimeout(() => {
      if (inputRef && inputRef.current) {
        inputRef.current.focus();
      }
      if (onFocus) onFocus();
    }, 0);
    return () => {
      if (onCleanup) onCleanup();
      // Return focus to whomever we stole it from
      if (previousFocusedElement) {
        setTimeout(() => {
          if (previousFocusedElement instanceof HTMLElement) {
            previousFocusedElement.focus();
          }
        }, 0);
      }
    };
  });
}

export default useFocusHandler;
