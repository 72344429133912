import { createStyles } from '@material-ui/core';

export const textSubTitle = {
  fontWeight: 500,
  fontSize: 14,
};

export default () =>
  createStyles({
    textTitle: {
      fontWeight: 700,
      fontSize: 32,
      marginTop: 12,
    },
    textSubTitle,
  });
