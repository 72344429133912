import {
  GetDetailedMilestonesQuery,
  GetDetailedMilestonesQueryVariables,
} from '../../../generated/graphql';
import { useQuery } from '../../../hooks/useMountAwareQuery';

import { getDetailedMilestonesQuery } from './queries';

export default function useDetailedMilestonesQuery(projectId: UUID, showDrafts: boolean) {
  return useQuery<GetDetailedMilestonesQuery, GetDetailedMilestonesQueryVariables>(
    getDetailedMilestonesQuery,
    {
      variables: { projectID: projectId, showDrafts },
      skip: !projectId,
    }
  );
}
