import useProjectPropsQuery from '../../../../../hooks/useProjectPropsQuery';
import { useMilestonesQuery } from '../../../../Milestones/hooks';
import JoinSelect from '../../../../Select/JoinSelect/JoinSelect';

type Props = {
  milestoneID: UUID | undefined;
  onChange: (milestoneID?: UUID) => void;
  projectID?: UUID;
};

const MilestoneSelect = ({ onChange, milestoneID, projectID }: Props) => {
  const { data: projectData } = useProjectPropsQuery(projectID);
  const { data: milestoneData } = useMilestonesQuery(projectID || '', false);
  const activeMilestoneID = projectData?.project?.activeMilestone.id;
  const milestones = milestoneData?.milestones ?? [];

  return (
    <div className="flex flex-col gap-0.5">
      <div className="type-label">Milestone</div>
      <JoinSelect
        entries={milestones}
        onChange={(milestoneID: UUID) => onChange(milestoneID ?? undefined)}
        value={milestoneID ?? activeMilestoneID}
      />
    </div>
  );
};

export default MilestoneSelect;
