import { FC, useEffect } from 'react';

import { Typography } from '@material-ui/core';

import { exportItemsListAnalytics } from '../../../analytics/analyticsEventProperties';
import JoinAPI from '../../../api/joinAPI';
import { ALL_MILESTONES, VIEW_FILTER } from '../../../constants';
import { VisibilityView } from '../../../generated/graphql';
import {
  getCategorizationsForProjectFromQueryData,
  useProjectCategorizationsQuery,
} from '../../../hooks/useProjectCategorizationsQuery';
import useProjectPropsQuery from '../../../hooks/useProjectPropsQuery';
import useSendAnalytics from '../../../hooks/useSendAnalytics';
import { checkCostModeIncludesMarkups, useCostMode } from '../../../utilities/costMode';
import { computeDistinctCreators } from '../../../utilities/items';
import { isNonNullable } from '../../../utilities/types';
import { getProjectIdFromUrl } from '../../../utilities/url';
import { useFilterManager } from '../../FilterPanel/filterUtils';
import { useItemsListSettings } from '../../ItemsList/ItemsListSettingsUtils';
import { formatSettingsForExport, useItemsTree } from '../../ItemsList/ItemsListUtils';
import { useItemsListQuery } from '../../ItemsList/useItemsListQuery';
import { useMilestonesQuery } from '../../Milestones/hooks';
import { useLoadTimer } from '../../PerfMonitor/utils';
import useMemoWrapper from '../../useMemoWrapper';

const ExportItemsList: FC = () => {
  const projectId = getProjectIdFromUrl();
  const costMode = useCostMode();
  const sendAnalytics = useSendAnalytics();

  // STATE
  const settingsManager = useItemsListSettings();
  const { settings, addSettingsChange } = settingsManager;
  const { currentMilestone, activityID } = settings;

  const filterManager = useFilterManager(settings[VIEW_FILTER], (newValue: string) =>
    addSettingsChange(VIEW_FILTER, newValue)
  );
  const { filterQueryInput: viewFilter } = filterManager;

  // DATA
  const {
    data: { project },
    loading: projectPropsLoading,
  } = useProjectPropsQuery(projectId);
  const projectName = project?.name;
  const activeMilestoneId = project?.activeMilestone.id;
  const { data, loading: projectCategorizationsLoading } =
    useProjectCategorizationsQuery(projectId);
  const categorizations = getCategorizationsForProjectFromQueryData(data);

  const { data: { milestones = [] } = {}, loading: milestonesLoading } = useMilestonesQuery(
    projectId,
    false
  );

  const loadingProjectData =
    projectPropsLoading || projectCategorizationsLoading || milestonesLoading;

  const filteredMilestoneId =
    (currentMilestone && currentMilestone[0]) || activeMilestoneId || ALL_MILESTONES;
  const milestoneId = (filteredMilestoneId !== ALL_MILESTONES && filteredMilestoneId) || null;

  // DATA
  const {
    itemsTree: { orderedItemIDs },
    loading: loadingItemsTree,
  } = useItemsTree(filterManager, settings);
  const itemIds: UUID[] = [];
  orderedItemIDs.forEach((itemId, index) => {
    // Only add the first occurence of an itemId
    if (index === orderedItemIDs.findIndex((id) => id === itemId)) itemIds.push(itemId);
  });

  // ItemsListQuery is overkill for what we want - which is just a list of creators
  const { data: { itemsList: { items: itemsList = [] } = {} } = {}, loading: loadingItemsList } =
    useItemsListQuery(
      milestoneId,
      activityID,
      projectId,
      true,
      viewFilter,
      VisibilityView.HYBRID_VIEW,
      settings.integrations,
      true,
      loadingProjectData
    );
  const creators = useMemoWrapper(computeDistinctCreators, itemsList);

  const hooksLoading = loadingProjectData || loadingItemsTree || loadingItemsList;
  useLoadTimer('ExportItemsList', hooksLoading);

  const milestone = milestones.find((m) => m.id === milestoneId);
  const milestoneName = milestone?.name || '';

  const fileNameTokens = [projectName, milestoneName].filter(isNonNullable);
  const exportListAndClose = () => {
    sendAnalytics(exportItemsListAnalytics(filteredMilestoneId, settings));
    JoinAPI.exportItemsList(
      itemIds,
      projectId,
      milestoneId,
      viewFilter,
      formatSettingsForExport(settings, creators, categorizations).map(
        (line) => `${line.header}: ${line.value}`
      ),
      checkCostModeIncludesMarkups(costMode),
      fileNameTokens.filter(isNonNullable),
      () => {
        window.close();
        return undefined;
      }
    );
  };

  const EXPORT_DELAY = 50;

  useEffect(() => {
    if (!hooksLoading) setTimeout(() => exportListAndClose(), EXPORT_DELAY);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hooksLoading]);

  useEffect(() => {
    if (projectName) document.title = `${projectName} - Export Items List`;
  }, [projectName]);

  return (
    <Typography style={{ fontSize: 18, paddingTop: 16 }}>
      &nbsp;&nbsp;&nbsp;&nbsp;Exporting Items List...
    </Typography>
  );
};

export default ExportItemsList;
