import ProjectSelection from '../../../ProjectsList/ProjectSelection/ProjectSelection';
import { Button, Dialog } from '../../../scales';

type ProjectSelectionDialogProps = {
  onBack: () => void;
  onChange: (projectID?: UUID) => void;
  onClose: () => void;
  onNext: () => void;
  progressPercent?: number;
  selected?: UUID;
};

const ProjectSelectionDialog = (props: ProjectSelectionDialogProps) => {
  const selectedProjectIDs = props.selected ? [props.selected] : [];
  const onChange = (selected: UUID[]) => props.onChange(selected[0]);
  return (
    <Dialog
      footerLeft={<Button label="Close" onClick={props.onBack} type="secondary" />}
      footerRight={
        <Button
          isDisabled={!props.selected}
          label="Next: General Info"
          onClick={props.onNext}
          type="primary"
        />
      }
      isFullHeight
      isOpen
      onClose={props.onClose}
      progressPercent={props.progressPercent}
      size="lg"
      title="Select a Destination Project"
    >
      <ProjectSelection
        onChange={onChange}
        selectedProjectIDs={selectedProjectIDs}
        selectionMode="single"
      />
    </Dialog>
  );
};

export default ProjectSelectionDialog;
