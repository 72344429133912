import { useState } from 'react';

import { DeleteOutline, Edit, MoreVert } from '@material-ui/icons';
import { useNavigate } from 'react-router-dom';
import { EditProgramInput, ProgramFieldsFragment } from '../../generated/graphql';
import ShareGroupAdd from '../Icons/ShareGroupAdd';
import { Button, IconMenuButton, MenuEntry } from '../scales';

import ProgramUsersDialogData from './ProgramUsersDialog/ProgramUsersDialogData';
import { useDeleteProgramMutation } from './ProgramDialog/hooks/useDeleteProgramMutation';
import { useEditProgramMutation } from './ProgramDialog/hooks/useEditProgramMutation';
import { generateSharedPath } from '../../utilities/routes/links';
import { RouteKeys } from '../../routes/paths';

type Props = {
  program?: ProgramFieldsFragment;
};

export default function ProgramDashboardMenu({ program }: Props) {
  const [isOpen, setIsOpen] = useState(false);
  // TODO add actual inputs via dialog
  const [input] = useState<EditProgramInput>({
    programID: program?.id || '',
    name: 'New new name',
  });

  // ToDo: We'll need some kind of permission check to ensure the user can
  // share the program

  // Hooks
  const navigate = useNavigate();
  const [deleteProgram] = useDeleteProgramMutation(program?.id || '', {
    onCompleted: () => {
      navigate(generateSharedPath(RouteKeys.SEARCH_PROJECTS));
    },
  });
  const [editProgram] = useEditProgramMutation(input);

  const menuEntries: MenuEntry[] = [];
  menuEntries.push(
    {
      disabled: false,
      id: 'edit-program',
      label: 'Edit Program',
      onClick: () => {
        editProgram();
      },
      startAdornment: <Edit />,
      type: 'default',
    },
    {
      disabled: false,
      id: 'delete-program',
      label: 'Delete Program',
      onClick: () => {
        deleteProgram();
      },
      startAdornment: <DeleteOutline />,
      type: 'destructive',
    }
  );

  return (
    <div className="flex h-20 items-center bg-background-primary p-6">
      {program && (
        <ProgramUsersDialogData isOpen={isOpen} program={program} setIsOpen={setIsOpen} />
      )}
      <div className="flex-grow type-giant type-heading1">{program?.name}</div>
      <Button
        label="Share"
        onClick={() => setIsOpen(true)}
        startIcon={<ShareGroupAdd />}
        type="primary"
      />
      <IconMenuButton
        aria-label="export-menu"
        entries={menuEntries}
        icon={<MoreVert />}
        type="secondary"
      />
    </div>
  );
}
