import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../../theme/komodo-mui-theme';
import { LANDSCAPE_WIDTH } from '../../PrintUtils';

const COST_ROW_HEIGHT = 14;
const CELL_PADDING = 2;
export const TOTAL_COST_ROW_HEIGHT = COST_ROW_HEIGHT + 1; // 1px border height
const cellPadding = `${CELL_PADDING}px`;
const columnFlex = '0 1 20%';

const ProjectCompsStyles = (theme: KomodoTheme) =>
  createStyles({
    content: {
      background: theme.palette.backgroundWhite,
      display: 'flex',
      flexDirection: 'row',
    },
    average: {
      borderRight: `${theme.border.lineBorderBlack} !important`,
      '&:last-of-type': {
        '& .line-fields:last-of-type': {
          opacity: 1, // important to make lines end
        },
      },
    },
    cell: {
      height: COST_ROW_HEIGHT,
      display: 'inline-flex',
      padding: cellPadding,
      alignItems: 'center',
      borderLeft: theme.border.line,
      justifyContent: 'end',
      '&:first-of-type': {
        borderLeft: 'unset',
      },
    },
    cellTotal: {
      borderTop: 'unset !important',
    },
    columnContent: {
      borderRight: theme.border.line,
      flex: columnFlex,
      overflow: 'hidden',
      '&:last-of-type': {
        borderRight: 'unset',
        '& .line-fields:last-of-type': {
          opacity: 0, // important to make lines end
        },
      },
    },
    costCell: {
      ...theme.typography.number,
    },
    costRow: {
      alignItems: 'center',
      borderTop: theme.border.line,
      display: 'flex',
      height: TOTAL_COST_ROW_HEIGHT - 1,
    },
    costRowBorder: {
      borderTop: theme.border.lineBorderBlack,
    },
    labelColumnContent: {
      flex: columnFlex,
      backgroundColor: theme.palette.backgroundWhite,
      borderRight: 'unset',
      display: 'flex',
      overflow: 'visible',
    },
    indentedLabelCell: {
      paddingLeft: CELL_PADDING * 2 + 12,
    },
    pageBreakHeader: {
      background: theme.palette.backgroundWhite,
      position: 'relative',
      height: 0,
      width: LANDSCAPE_WIDTH,
    },
    tableHeaderElipsis: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    columnHeaderTitle_color: {
      height: 6,
      paddingBottom: 2,
      width: '100%',
    },
    columnHeaderTitle_container: {
      display: 'flex',
      paddingBottom: CELL_PADDING * 2,
      width: '100%',
    },
    columnHeaderTitle_titleTextPlacement: {
      flex: 'auto',
      height: '100%',
      marginTop: 'auto',
      paddingLeft: CELL_PADDING * 2,
    },
    columnHeaderTitle_thumbnail: {
      paddingLeft: CELL_PADDING * 2,
      paddingRight: CELL_PADDING * 2,
    },
    columnHeaderData_container: {
      width: '100%',
      height: '100%',
      backgroundColor: theme.palette.nestingGrey,
      paddingTop: CELL_PADDING,
      paddingBottom: CELL_PADDING * 2,
    },
    columnHeaderData_divider: {
      margin: CELL_PADDING * 2,
      backgroundColor: theme.palette.innerBorder,
    },
    columnHeaderData_averageCompDivider: {
      backgroundColor: 'unset',
    },
    columnHeaderData_row: {
      display: 'flex',
      paddingLeft: CELL_PADDING * 2,
      minHeight: 9,
      alignItems: 'baseline',
    },
    columnGroupHeader_costHeader: {
      paddingTop: CELL_PADDING * 4,
    },
    titleText: {
      ...theme.typography.printTitle,
    },
    bodyTextBold: {
      ...theme.typography.printBodyBold,
      fontWeight: 700,
      paddingRight: CELL_PADDING,
    },
    bodyText: {
      ...theme.typography.printBody,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    bodyTextDrescription: {
      ...theme.typography.printBody,
    },
  });

export default ProjectCompsStyles;
