import { FC } from 'react';

import useMilestoneQuantitiesQuery, {
  emptyQuantities,
} from '../../Milestone/hooks/useMilestoneQuantitiesQuery';

import ProjectSummary from './ProjectSummary';

type ProjectSummaryPrintDataProps = {
  project: ProjectProps;
  milestone?: Pick<Milestone, 'id' | 'name'>;
  thumbnailURL?: string;
};

const ProjectSummaryData: FC<ProjectSummaryPrintDataProps> = ({
  project,
  milestone,
  thumbnailURL,
}) => {
  const { data: { quantities = emptyQuantities } = {} } = useMilestoneQuantitiesQuery(
    milestone?.id
  );

  return (
    <ProjectSummary
      displayName={milestone?.name}
      project={project}
      quantities={quantities}
      thumbnailURL={thumbnailURL}
    />
  );
};

export default ProjectSummaryData;
