import { CostReportColumnKey } from '../../../../generated/graphql';
import { getDate } from '../../../VarianceReport/VarianceReportUtils';
import {
  ColumnDescription,
  VarianceColumnDescription,
  getColumnHeaderFromColumnKey,
  getColumnTermFromColumnKey,
  getMatchingCostReportDescriptions,
  getMatchingVarianceDescriptions,
  getShadedColumns,
} from '../../CostReportColumns/CostReportColumns';
import {
  getIsUnitColumn,
  getNonCategorizedUnitText,
  keyIsCategorized,
} from '../CostReportList/CostReportListUtils';
import {
  HEADER_PADDING,
  RANGE_COL_WIDTH,
  REPORT_COL_WIDTH,
  VARIANCE_COL_WIDTH,
} from '../CostReportListRow/CostReportListRowStyles';

export type ListHeader = {
  text: string;
  subtext?: string;
  title: string;
  subheaders?: ListSubheader[];
  columnTitles?: ListSubheader[];
  isWide?: boolean;
  metrics?: MetricInfo[];
};

export type MetricInfo = {
  abbreviation: string;
  magnitude: number;
};

export type ListSubheader = {
  subheaderText: string;
  isShaded: boolean;
  isWide: boolean;
  isCategorized: boolean;
};

const formatVarianceHeaderText = (isVarianceColumn: boolean, milestoneName?: string) =>
  !isVarianceColumn ? milestoneName : 'Variance';

const formatVarianceSubheaderText = (
  t: TermStore,
  columnKey: CostReportColumnKey | undefined,
  date: string | undefined,
  isVarianceColumn: boolean,
  showDates: boolean
) => {
  const milestoneColumnText = `${getColumnTermFromColumnKey(t, columnKey)}${
    showDates ? ` (${getDate(date)})` : ''
  }`;
  return isVarianceColumn ? 'Difference' : milestoneColumnText;
};

const getHeaderMetrics = (
  settings: CostReportSettings,
  milestoneQuantities: Quantity[][] | undefined,
  i: number
) => {
  const varianceColumns = 'varianceColumns' in settings ? settings.varianceColumns : undefined;
  const totalMetrics =
    varianceColumns &&
    varianceColumns.filter(
      (column: string) => !keyIsCategorized(column) && getIsUnitColumn(column)
    );

  const quantities =
    milestoneQuantities && milestoneQuantities.length > i ? milestoneQuantities[i] : undefined;
  return quantities && totalMetrics
    ? totalMetrics
        .map((metric) => {
          const abbreviation = getNonCategorizedUnitText(metric);
          const foundUnit = quantities.find(
            (quantity) => quantity.unit.abbreviationSingular === abbreviation
          );
          if (!foundUnit) return null;
          const magnitude = foundUnit ? foundUnit.magnitude : 0;
          return {
            abbreviation,
            magnitude,
          };
        })
        .filter((elem): elem is MetricInfo => !!elem)
    : null;
};

export const formatCostReportListHeaders = (
  t: TermStore,
  displayColumnDescriptions: ColumnDescription[] | VarianceColumnDescription[] = [],
  headerDescriptions: ColumnDescription[] | VarianceColumnDescription[],
  isVariance: boolean,
  settings: CostReportSettings,
  milestoneQuantities: Quantity[][] | undefined
): ListHeader[] => {
  const showDates = displayColumnDescriptions.some((d) => d && 'date' in d && !!d.date);

  const shadedColumns = getShadedColumns(headerDescriptions);

  return headerDescriptions.map((headerDescription, i) => {
    const milestoneName =
      headerDescription && 'milestoneName' in headerDescription
        ? headerDescription.milestoneName
        : undefined;
    const date =
      headerDescription && 'date' in headerDescription ? headerDescription.date : undefined;
    const title =
      headerDescription && 'title' in headerDescription ? headerDescription.title : undefined;
    const columnKey =
      headerDescription && 'columnKey' in headerDescription
        ? headerDescription.columnKey
        : undefined;
    const isVarianceColumn = isVariance && CostReportColumnKey.VARIANCE_KEY === columnKey;

    const isShaded = !isVariance && !!columnKey && shadedColumns.includes(columnKey);

    const isWide = !!columnKey && !!headerDescription?.isWide;
    const text = isVariance
      ? formatVarianceHeaderText(isVarianceColumn, milestoneName)
      : getColumnTermFromColumnKey(t, columnKey);
    const subtext = isVariance
      ? formatVarianceSubheaderText(t, columnKey, date, isVarianceColumn, showDates)
      : '';

    const matchingDescriptions = isVariance
      ? getMatchingVarianceDescriptions(displayColumnDescriptions, i)
      : getMatchingCostReportDescriptions(displayColumnDescriptions, columnKey);

    const getListSubheader = (
      t: TermStore,
      columnKey: CostReportColumnKey,
      matchingDescriptions: ColumnDescription[] | VarianceColumnDescription[],
      columnText: string,
      isVariance: boolean,
      isCategorized: boolean,
      isShaded = false,
      isWide = false
    ): ListSubheader => {
      if (!columnKey)
        return { subheaderText: '', isShaded: false, isWide: false, isCategorized: false };
      return {
        subheaderText: columnText,
        isShaded,
        isWide: isWide || (isVariance && matchingDescriptions.length <= 1),
        isCategorized,
      };
    };
    const subheaders = matchingDescriptions.map((description) => {
      if (!description || !description?.columnKey) {
        return { subheaderText: '', isShaded: false, isWide: false, isCategorized: false };
      }
      const columnText = getColumnHeaderFromColumnKey(description.columnKey);
      return getListSubheader(
        t,
        description.columnKey,
        matchingDescriptions,
        columnText,
        isVariance,
        keyIsCategorized(description.columnKey),
        undefined,
        description.isWide
      );
    });

    const columnTitles = matchingDescriptions.map((description): ListSubheader => {
      if (!description || !description?.columnKey)
        return { subheaderText: '', isShaded: false, isWide: false, isCategorized: false };
      let columnText = '';

      if (!description.unitID) {
        columnText = '';
      } else {
        const isCategorized = keyIsCategorized(description.columnKey);
        columnText = isCategorized ? 'Categorized' : 'Total'; // TODO - use helper
      }

      return getListSubheader(
        t,
        description.columnKey,
        matchingDescriptions,
        columnText,
        isVariance,
        isShaded,
        description.isWide
      );
    });

    const metrics = getHeaderMetrics(settings, milestoneQuantities, i);

    return {
      text: text ?? '',
      subtext,
      title: title ?? '',
      subheaders,
      columnTitles,
      isShaded,
      isWide,
      metrics: metrics ?? undefined,
    };
  });
};

export const getHeaderWidth = (
  isVariance: boolean,
  numPerHeader: number,
  columnIsWide: boolean
) => {
  if (isVariance) {
    const isWide = numPerHeader > 2 || columnIsWide;
    return isWide
      ? (VARIANCE_COL_WIDTH + HEADER_PADDING) * (numPerHeader > 2 ? numPerHeader : 2)
      : (VARIANCE_COL_WIDTH + HEADER_PADDING) * 2;
  }

  if (columnIsWide) {
    return RANGE_COL_WIDTH;
  }

  const isWide = numPerHeader > 1 || columnIsWide;
  return isWide ? numPerHeader * REPORT_COL_WIDTH : REPORT_COL_WIDTH;
};

export const getSubHeaderWidth = (isVariance: boolean, isWide: boolean) => {
  if (isVariance) {
    return isWide ? 2 * (VARIANCE_COL_WIDTH + HEADER_PADDING) : VARIANCE_COL_WIDTH + HEADER_PADDING;
  }
  return isWide ? RANGE_COL_WIDTH : '';
};
