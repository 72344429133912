import { Dispatch, FC, SetStateAction, useState } from 'react';

import { ReactiveVar, useReactiveVar } from '@apollo/client';
import { Chip, Typography } from '@material-ui/core';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';

import { MASTERFORMAT_CATEGORY, UNIFORMAT_CATEGORY } from '../../../../constants';
import useSendAnalytics from '../../../../hooks/useSendAnalytics';
import { withStyles } from '../../../../theme/komodo-mui-theme';
import ShadedHelpTip from '../../../shared-widgets/ShadedHelpTip/ShadedHelpTip';
import { getKeyByLevel, updateBuiltInMap } from '../../utils';
import DraftCategorizationSelect from '../DraftCategorizationSelect/DraftCategorizationSelect';

import NewCategorizationsBuiltInCategoryMatchCounts from './NewCategorizationBuiltinCategoryMatchCounts';
import styles from './NewCategorizationBuiltinMapStyles';
import NewCategorizationsBuiltInMapRadioButtons from './NewCategorizationsBuiltinMapRadioButtons';
import {
  BuiltInRadioButtonOptions,
  DisableBuiltInsTip,
  levelsMf,
  levelsUf,
  shouldShowDisableBuiltInTip,
} from './NewCategorizationsBuiltinMapUtils';

type NewCategorizationsBuiltInMapProps = {
  classes: Classes<typeof styles>;
  radioBuiltInSelection: BuiltInRadioButtonOptions;
  updateRadioBuiltInSelection: Dispatch<SetStateAction<BuiltInRadioButtonOptions>>;
  importEstimateKey: string;
  importEstimateVar: ReactiveVar<ImportEstimateParameters>;
  hasMasterFormat: boolean;
  hasUniFormat: boolean;
};

const NewCategorizationsBuiltInMap: FC<NewCategorizationsBuiltInMapProps> = ({
  classes,
  radioBuiltInSelection,
  updateRadioBuiltInSelection,
  importEstimateKey,
  importEstimateVar,
  hasMasterFormat,
  hasUniFormat,
}) => {
  // const [radioSelection, setRadioValue] = useState(BuiltInRadioButtonOptions.ENABLED);
  const disableSelectors = radioBuiltInSelection === BuiltInRadioButtonOptions.DISABLED;
  const [uniFormatLevels, updateUniformatLevels] = useState<Level[]>(levelsUf);
  const [masterFormatLevels, updateMasterFormatLevels] = useState<Level[]>(levelsMf);

  const sendAnalytics = useSendAnalytics();
  const importEstimate = useReactiveVar(importEstimateVar);
  const { categorizations: categorizationsNew, mappingBuiltIn } = importEstimate;

  const showDisableBuiltInTip = !![...uniFormatLevels, ...masterFormatLevels].find(
    (level) => level.showDisableTip
  );

  const updateLevelShowDisabledTip = (
    selectedDraftCateogrizationName: string | undefined,
    draftCategorizations: DraftCategorization[],
    currentLevel: Level,
    levels: Level[]
  ) => {
    const selectedDraftCategorization = draftCategorizations.find(
      (draftCat) => draftCat.name === selectedDraftCateogrizationName
    );

    const showDisabledBuiltInTip = shouldShowDisableBuiltInTip(
      selectedDraftCategorization,
      currentLevel
    );

    const updatedLevel: Level = { ...currentLevel };
    updatedLevel.showDisableTip = showDisabledBuiltInTip;
    const updatedLevels: Level[] = [
      // filter out the old level
      ...levels.filter((level) => level.level !== currentLevel.level),
      // adding the updated level
      updatedLevel,
    ].sort((a, b) => a.level - b.level);

    if (currentLevel.builtIn === MASTERFORMAT_CATEGORY) updateMasterFormatLevels(updatedLevels);
    if (currentLevel.builtIn === UNIFORMAT_CATEGORY) updateUniformatLevels(updatedLevels);
  };

  const renderMfUfHeader = (headOuter: string, headJoin: string) => (
    <div className={classes.header}>
      <Typography className={classes.headOuter}>{headOuter}</Typography>
      <Typography className={classes.headJoin}>{headJoin}</Typography>
    </div>
  );

  // Warn about disabled: If there is only one disabled, label here
  const renderDisabled = (name: string) => (
    <div className={classes.disablePadding}>
      <div className={classes.containerDisabled}>
        <Typography>Your project has {name} disabled</Typography>
      </div>
    </div>
  );

  const renderMfUf = (levels: Level[]) => (
    <div className={classes.containerBuiltIn}>
      {levels.map((level) => {
        const { name, builtIn } = level;
        const draftCategorizationName = getKeyByLevel(mappingBuiltIn, level);
        const selectedDraftCategorization = categorizationsNew.find(
          (draftCategorization) => draftCategorization.name === draftCategorizationName
        );
        const showMatchCounts = draftCategorizationName && selectedDraftCategorization;
        return (
          <div key={name} className={classes.chipPadding}>
            <div className={classes.horizontal}>
              <div>
                <DraftCategorizationSelect
                  cySelect={`dropdown-${builtIn}-${level.level}`}
                  disabled={radioBuiltInSelection === BuiltInRadioButtonOptions.DISABLED}
                  draftCategorizations={categorizationsNew}
                  level={level}
                  levels={levels}
                  selectedDraftCategorization={selectedDraftCategorization}
                  updateLevelShowDisabledTip={updateLevelShowDisabledTip}
                  updateMap={(n: string | undefined) =>
                    updateBuiltInMap(
                      importEstimateVar,
                      importEstimateKey,
                      importEstimate,
                      categorizationsNew,
                      mappingBuiltIn,
                      n,
                      level,
                      sendAnalytics
                    )
                  }
                />
                {showMatchCounts && (
                  <NewCategorizationsBuiltInCategoryMatchCounts
                    builtInCategorizationName={builtIn}
                    selectedDraftCategorization={selectedDraftCategorization}
                  />
                )}
              </div>
              <ArrowRightAltIcon className={classes.arrow} />
              <Chip
                key={name}
                classes={{
                  root: classes.alignLeft,
                  label: classes.chipLabel,
                }}
                className={classes.chip}
                label={name}
                title={name}
              />
            </div>
          </div>
        );
      })}
    </div>
  );

  // return the componentry in the correct order based on which built-ins are enabled
  if (hasMasterFormat && !hasUniFormat) {
    return (
      <div>
        <div className={classes.innerContainer}>
          {showDisableBuiltInTip && (
            <div className={classes.paddingBottom}>
              <ShadedHelpTip shiftLeft tip={DisableBuiltInsTip} />
            </div>
          )}
          <NewCategorizationsBuiltInMapRadioButtons
            hasMasterFormat={hasMasterFormat}
            hasUniFormat={hasUniFormat}
            selectedOption={radioBuiltInSelection}
            setSelectedOption={updateRadioBuiltInSelection}
          />
          <div className={disableSelectors ? classes.disableContent : ''}>
            {renderMfUfHeader('Imported WBS Code', 'MasterFormat in Join')}
            {renderMfUf(levelsMf)}
          </div>
          {renderMfUfHeader('UniFormat', '')}
          {renderDisabled('UniFormat')}
        </div>
      </div>
    );
  }

  if (hasUniFormat && !hasMasterFormat) {
    return (
      <div>
        <div className={classes.innerContainer}>
          {showDisableBuiltInTip && (
            <div className={classes.paddingBottom}>
              <ShadedHelpTip shiftLeft tip={DisableBuiltInsTip} />
            </div>
          )}
          <NewCategorizationsBuiltInMapRadioButtons
            hasMasterFormat={hasMasterFormat}
            hasUniFormat={hasUniFormat}
            selectedOption={radioBuiltInSelection}
            setSelectedOption={updateRadioBuiltInSelection}
          />
          <div className={disableSelectors ? classes.disableContent : ''}>
            {renderMfUfHeader('Imported WBS Code', 'UniFormat in Join')}
            {renderMfUf(levelsUf)}
          </div>
          {renderMfUfHeader('MasterFormat', '')}
          {renderDisabled('MasterFormat')}
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className={classes.innerContainer}>
        {showDisableBuiltInTip && (
          <div className={classes.paddingBottom}>
            <ShadedHelpTip shiftLeft tip={DisableBuiltInsTip} />
          </div>
        )}
        <NewCategorizationsBuiltInMapRadioButtons
          hasMasterFormat={hasMasterFormat}
          hasUniFormat={hasUniFormat}
          selectedOption={radioBuiltInSelection}
          setSelectedOption={updateRadioBuiltInSelection}
        />
        <div className={disableSelectors ? classes.disableContent : ''}>
          {renderMfUfHeader('Imported WBS Code', 'MasterFormat in Join')}
          {renderMfUf(levelsMf)}
          {renderMfUfHeader('Imported WBS Code', 'UniFormat in Join')}
          {renderMfUf(levelsUf)}
        </div>
      </div>
    </div>
  );
};

export default withStyles(styles)(NewCategorizationsBuiltInMap);
