import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../../theme/komodo-mui-theme';

const ItemsListItemStyles = (theme: KomodoTheme) =>
  createStyles({
    column3Text: {
      textAlign: 'right',
      paddingRight: theme.spacing.unit * 4,
    },
    cta: {
      flexShrink: 0,
      textTransform: 'none',
    },
  });

export default ItemsListItemStyles;
