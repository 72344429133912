import { FC, useState } from 'react';

import { QuantityListItem } from '../MilestoneDetailsQuantitiesUtils';

import MilestoneDetailsQuantitiesCollapseBody from './MilestoneDetailsQuantitiesCollapseBody';
import MilestoneDetailsQuantitiesCollapseHeader from './MilestoneDetailsQuantitiesCollapseHeader';

type MilestoneDetailsQuantitiesCollapseProps = {
  canEditMetrics: boolean;
  canViewMetrics: boolean;
  getSetMagnitude: (unitID: UUID) => (magnitude: number) => void;
  getSubmitFunc: (unitID: UUID) => () => void;
  openUnit: UUID | null;
  setOpenUnit: (quantityID: UUID | null) => void;
  quantityListItem: QuantityListItem;
};

const MilestoneDetailsQuantitiesCollapse: FC<MilestoneDetailsQuantitiesCollapseProps> = ({
  canEditMetrics,
  canViewMetrics,
  getSetMagnitude,
  getSubmitFunc,
  openUnit,
  setOpenUnit,
  quantityListItem,
}) => {
  const { unitID } = quantityListItem;
  const quantityOpen = openUnit === unitID;
  const [groupBys, setGroupBy] = useState<Categorization[]>([]);
  const setCollapse = () => {
    if (canViewMetrics) {
      if (quantityOpen) {
        setOpenUnit(null);
      } else {
        setOpenUnit(unitID);
      }
    }
  };

  return (
    <>
      <MilestoneDetailsQuantitiesCollapseHeader
        canEditMetrics={canEditMetrics}
        canViewMetrics={canViewMetrics}
        getSetMagnitude={getSetMagnitude}
        getSubmitFunc={getSubmitFunc}
        quantityListItem={quantityListItem}
        quantityOpen={quantityOpen}
        setCollapse={setCollapse}
      />
      {quantityOpen && (
        <MilestoneDetailsQuantitiesCollapseBody
          canEditMetrics={canEditMetrics}
          canViewMetrics={canViewMetrics}
          groupBys={groupBys}
          quantityListItem={quantityListItem}
          setGroupBy={setGroupBy}
        />
      )}
    </>
  );
};

export default MilestoneDetailsQuantitiesCollapse;
