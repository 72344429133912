import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';
import { CHART_BORDER } from '../../ExecutiveDashboard/ExecutiveDashboardUtils';

const barText = (theme: KomodoTheme) => ({
  ...theme.typography.number,
  paddingLeft: 4,
  fontWeight: 400,
  fontSize: 12,
});

const ChartsSingleHorizontalBarGraphStyles = (theme: KomodoTheme) =>
  createStyles({
    chartLabel: {
      fontFamily: theme.typography.fontFamily,
      fontSize: '0.9rem',
    },
    project: {
      ...theme.typography.h3TableHeader,
      margin: `6px 3px 3px ${CHART_BORDER}px`,
      color: theme.palette.primaryBlue,
      textDecoration: 'auto',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      display: 'block',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    projectNoPermission: {
      ...theme.typography.h3TableHeader,
      margin: `6px 3px 3px ${CHART_BORDER}px`,
      color: theme.palette.shadedGrey,
      textDecoration: 'auto',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      display: 'block',
      lineHeight: 'initial',
    },
    projectContainer: {
      width: 75,
      display: 'block',
      maxHeight: 24,
    },
    totalLabel: {
      ...barText(theme),
      color: theme.palette.shadedGrey,
      paddingLeft: 10,
      paddingRight: 10,
      position: 'relative',
      top: 2,
    },
  });

export default ChartsSingleHorizontalBarGraphStyles;
