import { useEffect } from 'react';

import { YC_DEMO_MOCKS_INSIGHTS_V2 } from '../../../features';
import { InsightsProjectsQuery, InsightsProjectsQueryVariables } from '../../../generated/graphql';
import { useHasFeature } from '../../../hooks/useHasFeature';
import { useQuery } from '../../../hooks/useMountAwareQuery';
import useQueryPagination from '../../../hooks/useQueryPagination';

import { fixProjectMocks } from './demoData';
import { insightsProjectsQuery } from './queries';

export const useInsightsProjectsQuery = (params: InsightsProjectsQueryVariables) => {
  const useDemoData = useHasFeature(YC_DEMO_MOCKS_INSIGHTS_V2);
  const query = useQuery<InsightsProjectsQuery, InsightsProjectsQueryVariables>(
    insightsProjectsQuery,
    {
      variables: params,
      skip: !params.input.companyID,
    }
  );
  const insightsProjectsData = query?.data?.insights;

  const { fetchMore, resetPagination } = useQueryPagination(
    params.pagination.limit,
    insightsProjectsData?.length ?? 0,
    query.fetchMore
  );

  // Fully reset pagination whenever the query variables (filters, search, sort..) change.
  useEffect(() => {
    resetPagination();
  }, [resetPagination, params.input]);

  if (!useDemoData) {
    return { data: insightsProjectsData, fetchMore };
  }
  return { data: insightsProjectsData?.map((p) => fixProjectMocks(p)), fetchMore };
};
