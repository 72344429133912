import {
  ListDetailedItemsQuery,
  ListDetailedItemsQueryVariables,
} from '../../../../generated/graphql';
import { useQuery } from '../../../../hooks/useMountAwareQuery';
import { useCostMode } from '../../../../utilities/costMode';

import { listDetailedItemsQuery } from './queries';

function useListDetailedItemsQuery(
  projectId: string,
  milestoneId: string | null,
  viewFilter: ViewFilterInput
) {
  return useQuery<ListDetailedItemsQuery, ListDetailedItemsQueryVariables>(listDetailedItemsQuery, {
    variables: {
      costMode: useCostMode(),
      viewFilter,
      milestoneId,
      projectId,
    },
  });
}

export default useListDetailedItemsQuery;
