import { FC } from 'react';

import { UPLOAD_ATTACH_ITEM_ASSET } from '../../../../actions/actionTypes';
import { getItemAssetAnalytics } from '../../../../analytics/analyticsEventProperties';
import { UserSource } from '../../../../generated/graphql';
import { useItemUploadAttachAssets } from '../../../assets/hooks/AssetsMutationHook';
import useOptimisticProjectUploadAssets from '../../../assets/hooks/useOptimisticProjectUploadAssets';

import FilesDialogSidebar from './FilesDialogSidebar';

type FilesDialogSidebarItemWrapperProps = {
  itemID: UUID;
  userSources?: UserSource[];
  onItemMutated?: () => void;
};

const FilesDialogSidebarItemWrapper: FC<FilesDialogSidebarItemWrapperProps> = ({
  itemID,
  userSources,
  onItemMutated,
}) => {
  const analyticsEvent = getItemAssetAnalytics(UPLOAD_ATTACH_ITEM_ASSET, itemID);
  const { onDropFile, uploadingAssets } = useItemUploadAttachAssets(itemID, analyticsEvent, {
    onCompleted: onItemMutated,
  });

  useOptimisticProjectUploadAssets(uploadingAssets);

  return <FilesDialogSidebar onDropFile={onDropFile} userSources={userSources} />;
};

export default FilesDialogSidebarItemWrapper;
