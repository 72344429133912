import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../../theme/komodo-mui-theme';

export default (theme: KomodoTheme) =>
  createStyles({
    button: {
      backgroundColor: theme.palette.backgroundWhite,
      '&:hover': {
        backgroundColor: theme.palette.primaryGrey,
        color: theme.palette.backgroundWhite,
      },
      margin: 0,
      padding: 8,
      paddingRight: 12,
    },
    content: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    hidden: {
      display: 'none',
    },
    icon: {
      width: theme.iconSizes.mediumCompact,
      height: theme.iconSizes.mediumCompact,
    },
    buttonText: {
      paddingLeft: 4,
      paddingTop: 3,
    },
  });
