import useAssetBlobUrlQuery from '../../assets/hooks/useAssetBlobUrlQuery';

type Props = {
  assetID: UUID;
};

export default function TeamImage(props: Props) {
  const { url } = useAssetBlobUrlQuery(props.assetID);
  return <img alt="logo" className="text-primary-grey h-10 object-contain" src={url} />;
}
