import { TimelineData } from '../../../shared-widgets/TimelineChart/timeline/timeline.types';
import { useTimelineQuery } from '../../../Timeline/hooks/TimelineHook';
import { buildTree } from '../../../Timeline/Timeline';
import { ACTIVITIES_DEFAULT, getTimelineData } from '../../../Timeline/TimelineUtils';
import useMemoWrapper from '../../../useMemoWrapper';
import { InsightsProject } from '../../types';

export function useTimeline(project: InsightsProject) {
  const projectID = project.id;
  const timelineQueryResults = useTimelineQuery({ projectID, types: ACTIVITIES_DEFAULT });
  // Project timeline mock data if defined or real data
  const data = getDemoMocks(project) || timelineQueryResults.data;

  const activities = data?.timeline?.activities ?? [];
  const tree = useMemoWrapper(buildTree, activities);
  const timelineData: TimelineData[] = useMemoWrapper(getTimelineData, tree);
  return { timelineData, activities };
}

function getDemoMocks(project: InsightsProject) {
  return project?.timelineActivitiesMockData
    ? {
        timeline: {
          __typename: 'Timeline',
          activities: project?.timelineActivitiesMockData,
        },
      }
    : undefined;
}
