import { FC, useEffect, useState } from 'react';
import { titleCase } from 'title-case';

import { CircularProgress, LinearProgress, Typography } from '@material-ui/core';

import {
  UserSourceSystemEventType,
  getUserSourceSystemAnalyticsEvent,
} from '../../../analytics/analyticsEventProperties';
import { USER_SOURCES } from '../../../api/refetchSets';
import useSendAnalytics from '../../../hooks/useSendAnalytics';
import { withStyles } from '../../../theme/komodo-mui-theme';
import { getSourceFromURL } from '../../../utilities/url';
import { useConnectUserSource } from '../hooks';

import styles from './AuthorizeRedirectPageStyles';
import { useSuccessClose } from './AuthorizeRedirectPageUseUtils';
import { checkResultData, getUserSourceVariables } from './AuthorizeRedirectPageUtils';

const CODE = 'code';
const STATE = 'state';
const ERROR = 'error';

type AuthorizeRedirectPageProps = {
  classes: Classes<typeof styles>;
};

const AuthorizeRedirectPage: FC<AuthorizeRedirectPageProps> = ({ classes }) => {
  const sourceSystem = getSourceFromURL();
  const sourceString = sourceSystem ? titleCase(sourceSystem.toLowerCase()) : 'the source';
  const params = new URLSearchParams(window.location.search);
  const code = params.get(CODE);
  const state = params.get(STATE);
  const sourceError = params.get(ERROR);
  const [fired, setFired] = useState(false);

  // Hooks
  const sendAnalytics = useSendAnalytics();
  const [connectUserSource, result] = useConnectUserSource();
  const { data, error } = result || {};

  // Handle mutation when ready
  useEffect(() => {
    if (code && !fired && sourceSystem) {
      const variables = getUserSourceVariables(code, sourceSystem, state);
      setFired(true);
      connectUserSource({
        variables,
        refetchQueries: USER_SOURCES,
      }).then((result) => {
        if (checkResultData(true, result?.data)) {
          sendAnalytics(
            getUserSourceSystemAnalyticsEvent(UserSourceSystemEventType.ADD, sourceSystem)
          );
        }
      });
    }
  }, [code, state, sourceSystem, fired, connectUserSource, sendAnalytics]);

  // Kick off response
  const hasError = !!error || !!sourceError;
  const hasFailed = hasError || checkResultData(false, data);
  const dataIsReady = checkResultData(true, data) || hasFailed;
  const progress = useSuccessClose(dataIsReady, 3000, sourceSystem);

  // Return components

  if (checkResultData(true, data))
    return (
      <>
        <LinearProgress value={progress} variant="determinate" />
        <div className={classes.content} data-cy="redirect-success-content">
          <Typography className={classes.padded} variant="subheading">
            {`You've successfully connected to ${sourceString}!`}
          </Typography>
          <Typography className={classes.padded}>
            We will close this tab so you can continue with your connected source account.
          </Typography>
        </div>
      </>
    );

  if (hasFailed)
    return (
      <>
        <LinearProgress value={progress} variant="determinate" />
        <div className={classes.content} data-cy="redirect-fail-content">
          <Typography
            className={classes.padded}
            variant="subheading"
          >{`The connection to ${sourceString} failed.`}</Typography>
          <Typography className={classes.padded}>{result?.error?.message}</Typography>
          <Typography className={classes.padded}>Taking you back to your project...</Typography>
        </div>
      </>
    );

  return (
    <div className={classes.content}>
      <CircularProgress />
      <Typography className={classes.padded} variant="subheading">
        Processing your request...
      </Typography>
    </div>
  );
};

export default withStyles(styles)(AuthorizeRedirectPage);
