import './MenuBar.scss';

import { useState } from 'react';
import { useDebounce } from 'react-use';

import {
  Code,
  FormatBold,
  FormatClear,
  FormatIndentDecrease,
  FormatIndentIncrease,
  FormatItalic,
  FormatListBulleted,
  FormatListNumbered,
  FormatQuote,
  FormatStrikethrough,
  FormatUnderlined,
  Highlight,
  Redo,
  Undo,
} from '@material-ui/icons';
import { Editor } from '@tiptap/core';

import useSendAnalytics from '../../../../hooks/useSendAnalytics';
import { isMac } from '../../../../utilities/utilities';
import Checklist from '../../../Icons/Checklist';
import { HorizontalRuleIcon } from '../../../Icons/HorizontalRuleIcon';
import {
  MenuBarAction,
  getTextStylingMenuAnalyticsEvent,
} from '../TipTapTextArea/TipTapTextAreaUtils';

import MenuItem from './MenuItem';

const FOCUS_DELAY = 100;

type Props = {
  editor: Editor;
  isDisabled?: boolean;
};

export default function MenuBar(props: Props) {
  const isEditorFocused = props.editor?.isFocused;
  const sendAnalytics = useSendAnalytics();
  const getOnClick = (action: () => void, analyticsName: MenuBarAction) => () => {
    action();
    sendAnalytics(getTextStylingMenuAnalyticsEvent(analyticsName));
  };

  const [isFocused, setIsFocused] = useState<boolean>(isEditorFocused);
  useDebounce(() => setIsFocused(isEditorFocused), FOCUS_DELAY, [isEditorFocused]);
  const isDisabled = props.isDisabled || !isFocused;

  const commandIcon = isMac() ? '⌘' : 'Ctrl';
  const optionIcon = isMac() ? '⌥' : 'Alt';
  const getTitle = (name: string, keyString: string, commandString: string) =>
    `${name} (${keyString}+${commandString})`;

  return (
    <div className="flex flex-wrap items-center border-b p-1" role="menubar">
      <MenuItem
        icon={<FormatBold fontSize="inherit" />}
        isActive={props.editor.isActive(MenuBarAction.BOLD)}
        isDisabled={isDisabled}
        onClick={getOnClick(
          () => props.editor.chain().focus().toggleBold().run(),
          MenuBarAction.BOLD
        )}
        title={getTitle('Bold', commandIcon, 'B')} // (⌘+B)
      />
      <MenuItem
        icon={<FormatItalic fontSize="inherit" />}
        isActive={props.editor.isActive(MenuBarAction.ITALIC)}
        isDisabled={isDisabled}
        onClick={getOnClick(
          () => props.editor.chain().focus().toggleItalic().run(),
          MenuBarAction.ITALIC
        )}
        title={getTitle('Italic', commandIcon, 'I')} // (⌘+I)
      />
      <MenuItem
        icon={<FormatStrikethrough fontSize="inherit" />}
        isActive={props.editor.isActive(MenuBarAction.STRIKE)}
        isDisabled={isDisabled}
        onClick={getOnClick(
          () => props.editor.chain().focus().toggleStrike().run(),
          MenuBarAction.STRIKE
        )}
        title={getTitle('Strikethrough', `${commandIcon}+Shift`, 'X')} // (⌘+Shift+X)
      />
      <MenuItem
        icon={<FormatUnderlined fontSize="inherit" />}
        isActive={props.editor.isActive(MenuBarAction.UNDERLINE)}
        isDisabled={isDisabled}
        onClick={getOnClick(
          () => props.editor.chain().focus().toggleUnderline().run(),
          MenuBarAction.UNDERLINE
        )}
        title={getTitle('Underline', commandIcon, 'U')} // (⌘+U)
      />
      <Divider />
      <MenuItem
        icon={<Code fontSize="inherit" />}
        isActive={props.editor.isActive(MenuBarAction.CODE)}
        isDisabled={isDisabled}
        onClick={getOnClick(
          () => props.editor.chain().focus().toggleCode().run(),
          MenuBarAction.CODE
        )}
        title={getTitle('Code', commandIcon, 'E')} // (⌘+E)
      />
      <MenuItem
        icon={<Highlight fontSize="inherit" />}
        isActive={props.editor.isActive(MenuBarAction.HIGHLIGHT)}
        isDisabled={isDisabled}
        onClick={getOnClick(
          () => props.editor.chain().focus().toggleHighlight().run(),
          MenuBarAction.HIGHLIGHT
        )}
        title={getTitle('Highlight', `${commandIcon}+Shift`, 'H')} // (⌘+Shift+H)
      />
      <Divider />
      <MenuItem
        icon={<b className="block h-4 w-4 text-[12px]">H1</b>}
        isActive={props.editor.isActive(MenuBarAction.HEADING, { level: 1 })}
        isDisabled={isDisabled}
        onClick={getOnClick(
          () => props.editor.chain().focus().toggleHeading({ level: 1 }).run(),
          MenuBarAction.HEADING_1
        )}
        title={getTitle('Heading 1', `${commandIcon}+${optionIcon}`, '1')} // (⌘+Alt+1)
      />
      <MenuItem
        icon={<b className="block h-4 w-4 text-[12px]">H2</b>}
        isActive={props.editor.isActive(MenuBarAction.HEADING, { level: 2 })}
        isDisabled={isDisabled}
        onClick={getOnClick(
          () => props.editor.chain().focus().toggleHeading({ level: 2 }).run(),
          MenuBarAction.HEADING_2
        )}
        title={getTitle('Heading 2', `${commandIcon}+${optionIcon}`, '2')} // (⌘+Alt+2)
      />
      <Divider />
      <MenuItem
        icon={<FormatListBulleted fontSize="inherit" />}
        isActive={props.editor.isActive(MenuBarAction.BULLET_LIST)}
        isDisabled={isDisabled}
        onClick={getOnClick(
          () => props.editor.chain().focus().toggleBulletList().run(),
          MenuBarAction.BULLET_LIST
        )}
        title={getTitle('Bullet List', `${commandIcon}+Shift`, '8')} // (⌘+Shift+8)
      />
      <MenuItem
        icon={<FormatListNumbered fontSize="inherit" />}
        isActive={props.editor.isActive(MenuBarAction.ORDERED_LIST)}
        isDisabled={isDisabled}
        onClick={getOnClick(
          () => props.editor.chain().focus().toggleOrderedList().run(),
          MenuBarAction.ORDERED_LIST
        )}
        title={getTitle('Numbered List', `${commandIcon}+Shift`, '7')} // (⌘+Shift+7)
      />
      <MenuItem
        icon={
          <div className="icon-sm">
            <Checklist />
          </div>
        }
        isActive={props.editor.isActive(MenuBarAction.TASK_LIST)}
        isDisabled={isDisabled}
        onClick={getOnClick(
          () => props.editor.chain().focus().toggleTaskList().run(),
          MenuBarAction.TASK_LIST
        )}
        title={getTitle('Task List', `${commandIcon}+Shift`, '9')} // (⌘+Shift+9)
      />
      <MenuItem
        icon={<FormatIndentDecrease fontSize="inherit" />}
        isActive={false}
        isDisabled={isDisabled}
        onClick={getOnClick(
          () => props.editor.chain().focus().outdent().run(),
          MenuBarAction.OUTDENT
        )}
        title="Outdent (Shift+Tab)" // (Shift+Tab)
      />
      <MenuItem
        icon={<FormatIndentIncrease fontSize="inherit" />}
        isActive={false}
        isDisabled={isDisabled}
        onClick={getOnClick(
          () => props.editor.chain().focus().indent().run(),
          MenuBarAction.INDENT
        )}
        title="Indent (Tab)" // (Tab)
      />
      <Divider />
      <MenuItem
        icon={<FormatQuote fontSize="inherit" />}
        isActive={props.editor.isActive(MenuBarAction.BLOCKQUOTE)}
        isDisabled={isDisabled}
        onClick={getOnClick(
          () => props.editor.chain().focus().toggleBlockquote().run(),
          MenuBarAction.BLOCKQUOTE
        )}
        title="Blockquote"
      />
      <MenuItem
        icon={<HorizontalRuleIcon />}
        isActive={false}
        isDisabled={isDisabled}
        onClick={getOnClick(
          () => props.editor.chain().focus().setHorizontalRule().run(),
          MenuBarAction.HORIZONTAL_RULE
        )}
        title="Horizontal Rule (---)"
      />
      <Divider />
      <MenuItem
        icon={<Undo fontSize="inherit" />}
        isActive={false}
        isDisabled={isDisabled || !props.editor.can().undo()}
        onClick={getOnClick(() => props.editor.chain().focus().undo().run(), MenuBarAction.UNDO)}
        title={getTitle('Undo', commandIcon, 'Z')} // (⌘+Z)
      />
      <MenuItem
        icon={<Redo fontSize="inherit" />}
        isActive={false}
        isDisabled={isDisabled || !props.editor.can().redo()}
        onClick={getOnClick(() => props.editor.chain().focus().redo().run(), MenuBarAction.REDO)}
        title={getTitle('Redo', commandIcon, 'Y')} // (⌘+Y)
      />
      <Divider />
      <MenuItem
        icon={<FormatClear fontSize="inherit" />}
        isActive={false}
        isDisabled={isDisabled}
        onClick={getOnClick(
          () => props.editor.chain().focus().clearNodes().unsetAllMarks().run(),
          MenuBarAction.CLEAR_FORMAT
        )}
        title={getTitle('Clear Formatting', `${commandIcon}+Alt`, '0')} // (⌘+Alt+0)
      />
    </div>
  );
}

const Divider = () => <div className="mx-2 h-5 w-0.5 bg-border-separator" />;
