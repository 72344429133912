import { useState } from 'react';

import { Check } from '@material-ui/icons';

import {
  ItemShareEvent,
  ItemShareKey,
  itemShareEvent,
} from '../../../../analytics/analyticsEventProperties';
import { PermissionResource, ResourceType } from '../../../../generated/graphql';
import useSendAnalytics from '../../../../hooks/useSendAnalytics';
import usePermissions from '../../../../utilities/permissions/usePermissions';
import { getItemIdFromUrl, getProjectIdFromUrl } from '../../../../utilities/url';
import { useCurrentCollaborator } from '../../../contexts/current-collaborator';
import ShareGroupAdd from '../../../Icons/ShareGroupAdd';
import { useItemQuery } from '../../../Items/hooks/useItemQuery';
import { Button } from '../../../scales';
import ShareDraftItemDialog from '../ShareDraftItemDialog/ShareDraftItemDialog';
import SharedResourceBadge from '../../../dragon-scales/Share/SharedResourceIcon/SharedResourceBadge';

export type Props = {
  isDisabled: boolean;
  itemID?: string;
  itemIDs: UUID[];
  items?: ItemsListItem[];
  setShowPublishItemDialog?: (setShowPublishItemDialog: boolean) => void;
};

export default function ShareItemButton(props: Props) {
  const isBulk = props.itemIDs?.length > 1;
  const analyticsKey = isBulk ? ItemShareKey.BULK : ItemShareKey.DRAFT;
  const projectID = getProjectIdFromUrl();
  const itemId = getItemIdFromUrl();
  const { data: { item: currentItem = null } = {} } = useItemQuery(itemId);
  const sendAnalytics = useSendAnalytics();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const collaborator = useCurrentCollaborator();

  // Permissions
  const isItemOwner = collaborator?.user?.id === currentItem?.createdBy?.id;
  const { canEdit } = usePermissions({ trades: currentItem?.categories });
  const canShareDraft = canEdit(PermissionResource.SHARE_ITEMS_SCENARIOS) || isItemOwner;
  const canPublishDraft = canEdit(PermissionResource.PUBLISH_ITEMS_SCENARIOS) || isItemOwner;

  return (
    <>
      {props.itemID && props.setShowPublishItemDialog ? (
        <div className="flex gap-4">
          <SharedResourceBadge resourceID={props.itemID} resourceType={ResourceType.ITEM} />
          {!props.isDisabled && canShareDraft && (
            <Button
              data-cy="share-draft-button"
              label="Share draft"
              onClick={() => {
                setIsDialogOpen(!isDialogOpen);
                sendAnalytics(itemShareEvent(analyticsKey, ItemShareEvent.SHARE_CTA));
              }}
              startIcon={<ShareGroupAdd />}
              type="secondary"
            />
          )}
          {!props.isDisabled && canPublishDraft && (
            <Button
              data-cy="publish-item-button"
              label="Publish item"
              onClick={() => {
                props.setShowPublishItemDialog?.(true);
                sendAnalytics(itemShareEvent(analyticsKey, ItemShareEvent.PUBLISH_CTA));
              }}
              startIcon={<Check />}
              type="primary"
            />
          )}
        </div>
      ) : (
        <Button
          data-cy="share-item-button"
          label="Share Items"
          onClick={() => {
            setIsDialogOpen(!isDialogOpen);
            sendAnalytics(itemShareEvent(analyticsKey, ItemShareEvent.CTA));
          }}
          startIcon={<ShareGroupAdd />}
          type="primary"
        />
      )}
      {isDialogOpen && collaborator && (
        <ShareDraftItemDialog
          collaboratorID={collaborator?.id}
          itemIDs={props.itemIDs}
          items={props.items}
          onClose={() => setIsDialogOpen(false)}
          projectID={projectID}
        />
      )}
    </>
  );
}
