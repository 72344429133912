import { useMutation } from '@apollo/client';

import { REFETCH_SCENARIO_SANDBOX } from '../../../../api/refetchSets';
import { AddItemToScenariosMutationVariables } from '../../../../generated/graphql';

import { addItemToScenariosMutation } from './queries';

const useAddItemToScenariosMutation = (variables?: AddItemToScenariosMutationVariables) =>
  useMutation(addItemToScenariosMutation, {
    refetchQueries: REFETCH_SCENARIO_SANDBOX,
    variables,
  });

export default useAddItemToScenariosMutation;
