import { FC } from 'react';

import { GetApp, Print } from '@material-ui/icons';

import { isDownloadingItemsListToExcelVar } from '../../api/apollo/reactiveVars';
import { useShouldDisplayCosts } from '../../utilities/permissions/useShouldDisplayCosts';
import { Export } from '../Icons/Export';
import IconMenu from '../Select/SelectMenu/IconMenu';

type ItemsListExportPanelProps = {
  exportList: () => void;
  printList: () => void;
  printDetails: (withHistory: boolean) => void;
};

const ItemsListExportPanel: FC<ItemsListExportPanelProps> = ({
  printList,
  exportList,
  printDetails,
}) => {
  const { shouldDisplayCosts } = useShouldDisplayCosts();

  const options = [
    ...(shouldDisplayCosts
      ? [
          {
            name: 'Download .xlsx',
            callback: () => exportList(),
            icon: <GetApp />,
            loadingVar: isDownloadingItemsListToExcelVar,
          },
        ]
      : []),
    {
      name: 'Print list only',
      callback: () => printList(),
      icon: <Print />,
    },
    {
      name: 'Print list and details without change history',
      callback: () => printDetails(false),
      icon: <Print />,
    },
    {
      name: 'Print list and details with change history',
      callback: () => printDetails(true),
      icon: <Print />,
    },
  ];

  return (
    <IconMenu
      icon={<Export data-cy="exportItemsButtonContainer" />}
      isBottomOriented
      options={options}
    />
  );
};

export default ItemsListExportPanel;
