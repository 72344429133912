import { DeleteOutline, Edit, RotateLeft, Visibility, VisibilityOff } from '@material-ui/icons';

import { CUSTOMIZE_BUTTON, DELETE_BUTTON, HIDE_BUTTON, SHOW_BUTTON } from '../../../constants';
import { MenuEntry } from '../../scales';
import { AverageCompInputUpdateFunctions } from '../ProjectCompsSetInputStore/ProjectCompsSetInputUpdaters';

export const getAverageCompDropdownMenuOptions = (
  isHidden = false,
  averageCompInputUpdateFunctions: AverageCompInputUpdateFunctions,
  onOpenCustomizationDialog: () => void,
  onOpenDeleteDialog: () => void,
  isResettable = false
) => {
  const menuOptions: MenuEntry[] = [
    {
      onClick: onOpenCustomizationDialog,
      startAdornment: <Edit />,
      id: CUSTOMIZE_BUTTON,
      label: CUSTOMIZE_BUTTON,
    },
    {
      onClick: averageCompInputUpdateFunctions.toggleAverageCompInputIsHidden,
      startAdornment: isHidden ? <Visibility /> : <VisibilityOff />,
      id: isHidden ? SHOW_BUTTON : HIDE_BUTTON,
      label: isHidden ? SHOW_BUTTON : HIDE_BUTTON,
    },
    {
      onClick: averageCompInputUpdateFunctions.resetAverageCompInput,
      id: 'Reset to default average',
      label: 'Reset to default average',
      disabled: !isResettable,
      startAdornment: <RotateLeft />,
    },
    {
      onClick: onOpenDeleteDialog,
      startAdornment: <DeleteOutline />,
      id: DELETE_BUTTON,
      label: DELETE_BUTTON,
      type: 'destructive',
    },
  ];
  return menuOptions;
};
