import { renderCostString } from '../../../CostReport/CostReportUtils';
import { Icon } from '../../../scales';
import { CostTimeSeries } from '../InsightsCost/types';

type Props = {
  costData: CostTimeSeries;
};

export default function CombinedHoverContent(props: Props) {
  return (
    <div className="flex w-80 flex-col gap-2 rounded-lg bg-background-1 p-4 shadow-md">
      <div className="!font-bold type-body2">{props.costData.date.toLocaleDateString()}</div>
      <CostLine
        className="!font-bold text-entities-estimate"
        cost={renderCostString({ cost: props.costData.estimate, short: true })}
        icon={<Line className="stroke-entities-estimate" strokeDasharray="4, 3" strokeWidth={3} />}
        label="Combined Estimate"
      />
      <Divider />
      <CostLine
        className="!font-bold text-entities-estimate"
        cost={renderCostString({ cost: props.costData.runningTotal, short: true })}
        icon={<Line className="stroke-entities-estimate" strokeWidth={3} />}
        label="Combined Running Total"
      />
      <CostLine
        className="text-entities-estimate"
        cost={renderCostString({
          cost: props.costData.pendingMax,
          short: true,
        })}
        icon={<Icon className="text-entities-item-upcoming" name="arrow_upward" size="sm" />}
        label="Potential Upper Range"
      />
      <CostLine
        className="text-entities-estimate"
        cost={renderCostString({
          cost: props.costData.pendingMin,
          short: true,
        })}
        icon={<Icon className="text-entities-item-upcoming" name="arrow_downward" size="sm" />}
        label="Potential Lower Range"
      />
      <Divider />
      <CostLine
        className="!font-bold text-entities-budget"
        cost={renderCostString({ cost: props.costData.budget, short: true })}
        icon={<Line className="stroke-entities-budget" strokeWidth={3} />}
        label="Combined Budget"
      />
    </div>
  );
}

const CostLine = (props: {
  icon: React.ReactNode;
  cost: string;
  label: string;
  className: string;
}) => (
  <div className="flex w-full items-center justify-between gap-1">
    <div className="flex items-center gap-2">
      {props.icon}
      <div className={`type-body2 ${props.className}`}>{props.label}</div>
    </div>
    <div className={`type-body2 ${props.className}`}>{props.cost}</div>
  </div>
);

export const Line = (props: {
  className?: string;
  strokeColor?: string;
  strokeWidth: number;
  strokeDasharray?: string;
}) => (
  <svg height="10" width="16">
    <line
      className={props.className}
      stroke={props.strokeColor}
      strokeDasharray={props.strokeDasharray}
      strokeWidth={props.strokeWidth}
      x1="0"
      x2="20"
      y1="5"
      y2="5"
    />
  </svg>
);

const Divider = () => (
  <svg className="h-2 w-auto">
    <line stroke="black" strokeWidth={4} x1="0" x2="450" y1="0" y2="0" />
  </svg>
);
