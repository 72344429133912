import { ComponentProps, useRef } from 'react';
import { useButton, useMenuTrigger } from 'react-aria';
import { useMenuTriggerState } from 'react-stately';

import { ArrowDropDown, ArrowDropUp, CallSplit } from '@material-ui/icons';

import { Status } from '../../../../generated/graphql';
import { pluralizeCountString } from '../../../../utilities/string';
import { renderCostString } from '../../../CostReport/CostReportUtils';
import { getStatusIsAcceptedIncorporated } from '../../../Items/ItemsUtils';
import ItemsIconsMap from '../../../ItemsList/ItemsIcons/ItemsIconsMap';
import { Menu, Popover } from '../../../scales';

import ItemOptionSelectOptionLabel from './ItemOptionSelectOptionsLabel';

const RESET_SELECTION = 'RESET_SELECTION';

type Option = ComponentProps<typeof ItemOptionSelectOptionLabel>['options'][number] & {
  cost?: Cost | null;
};

type Props = {
  options: Option[];
  onChange: (selectedOptionID: UUID | undefined) => void;
};

export default function ItemOptionSelect(props: Props) {
  const state = useMenuTriggerState({});
  const ref = useRef<HTMLButtonElement>(null);
  const { menuProps } = useMenuTrigger({}, state, ref);

  const { buttonProps } = useButton(
    {
      onPress: () => {
        state.toggle();
        ref.current?.blur();
      },
      'aria-label': 'change item option status',
    },
    ref
  );

  const itemAcceptedOption = props.options.find((option) =>
    getStatusIsAcceptedIncorporated(option.status)
  );

  const hasAcceptedOption = !!itemAcceptedOption;

  let bgColor = 'bg-item-status-pending-tint';
  if (hasAcceptedOption) {
    bgColor = 'bg-item-status-accepted-tint';
  }

  return (
    <div className="relative">
      <button
        data-cy="item-option-select-button"
        {...buttonProps}
        /**
         * This component's styling is tightly related to the ItemStatusSelect since they both
         * render into the same spots. If you're making changes here, consider whether that
         * component should also be updated.
         *
         * At time of writing we're still pre-MVP and unsure of the best abstractions, so
         * despite it being /possible/ to move some of this into a wrapper component that
         * renders the `<button>` with `children` props, I've punted on that.
         */
        ref={ref}
        className={`flex h-6 w-[136px] items-center gap-1 whitespace-nowrap rounded-sm px-1 focus:outline active:outline-none ${bgColor}`}
      >
        {hasAcceptedOption && <ItemsIconsMap status={Status.ACCEPTED} variant="small" />}
        <div className="grow truncate text-left type-body2">
          <ItemOptionSelectOptionLabel
            itemAcceptedOption={itemAcceptedOption}
            options={props.options}
          />
        </div>
        {state.isOpen ? <ArrowDropUp fontSize="inherit" /> : <ArrowDropDown fontSize="inherit" />}
      </button>
      {state.isOpen && (
        <Popover placement="bottom end" state={state} triggerRef={ref}>
          <Menu
            {...menuProps}
            autoFocus
            data-cy="item-option-select-menu"
            sections={[
              {
                'aria-label': 'First Section',
                entries: [
                  {
                    id: RESET_SELECTION,
                    label: itemAcceptedOption
                      ? `${itemAcceptedOption.number}: ${itemAcceptedOption.name}`
                      : pluralizeCountString('option', props.options?.length),
                    onClick: () => props.onChange(undefined),
                    startAdornment: <CallSplit />,
                  },
                ],
              },
              {
                'aria-label': 'Second Section',
                entries: props.options
                  .sort((a, b) => a.number.localeCompare(b.number, undefined, { numeric: true }))
                  .map((option) => ({
                    id: option.id,
                    label: `${option.number} ${option.name}`,
                    onClick: () => props.onChange(option.id),
                    startAdornment: <ItemsIconsMap status={option.status} variant="medium" />,
                    endAdornment: `(${renderCostString({
                      cost: option?.cost,
                      isSigned: true,
                      isWide: true,
                    })})`,
                  })),
              },
            ]}
          />
        </Popover>
      )}
    </div>
  );
}
