import {
  ALL_MILESTONES,
  ASSIGNEE,
  BUCKET,
  BUCKET_MEETING,
  BUCKET_MILESTONE,
  CREATOR,
  STATUS,
  VISIBILITY,
} from '../../constants';
import { ItemsSortKey } from '../../generated/graphql';
import { makeOptionsFromCategorizations } from '../../utilities/categorization';
import { removeYear } from '../../utilities/string';
import { getEntry } from '../Select/JoinSelect/JoinSelect';
import { MultiGroupOrderOption } from '../shared-widgets/MultiGroup/MultiGroupOrderCategorizationUtils';

export const computeGroupByOptions = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  projectCategorizations: any[] = [],
  isScheduleImpactEnabled: boolean,
  filteredMilestoneId?: string
): MultiGroupOrderOption[] => {
  const singleCategorizations = [
    {
      name: 'Status',
      variant: STATUS,
    },
    {
      name: 'Assignee',
      variant: ASSIGNEE,
    },
    {
      name: 'Creator',
      variant: CREATOR,
    },
  ];
  const bucketName =
    !filteredMilestoneId || filteredMilestoneId === ALL_MILESTONES
      ? BUCKET_MILESTONE
      : BUCKET_MEETING;
  if (bucketName !== BUCKET_MEETING) {
    singleCategorizations.push({
      name: BUCKET_MILESTONE,
      variant: BUCKET,
    });
  }
  if (isScheduleImpactEnabled) {
    singleCategorizations.push({
      name: 'Schedule impact',
      variant: 'schedule impact',
    });
  }

  singleCategorizations.push({
    name: 'Share settings',
    variant: VISIBILITY,
  });

  if (projectCategorizations) {
    const options = makeOptionsFromCategorizations(projectCategorizations);
    return [...singleCategorizations, ...options];
  }
  return singleCategorizations;
};

export const computeSortBy = (
  categorizations: Categorization[],
  isScheduleImpactEnabled: boolean,
  shouldDisplayCosts: boolean
) => {
  const sortBy = [
    getEntry(ItemsSortKey.SORT_NUMBER, 'Item number'),
    getEntry(ItemsSortKey.SORT_STATUS, 'Status'),
    getEntry(ItemsSortKey.SORT_VISIBILITY, 'Visibility'),
    getEntry(ItemsSortKey.SORT_CREATOR, 'Creator'),
    getEntry(ItemsSortKey.SORT_CREATION_TIME, 'Creation time'),
    getEntry(ItemsSortKey.SORT_DUE_DATE, 'Due date'),
    getEntry(ItemsSortKey.SORT_DATE_MODIFIED, 'Last updated'),
  ];
  if (shouldDisplayCosts) {
    sortBy.push(getEntry(ItemsSortKey.SORT_COST_IMPACT, 'Cost impact'));
    sortBy.push(getEntry(ItemsSortKey.SORT_COST_IMPACT_MAGNITUDE, 'Magnitude'));
  }
  if (isScheduleImpactEnabled) {
    sortBy.push(getEntry(ItemsSortKey.SORT_SCHEDULE_IMPACT, 'Schedule impact'));
  }
  (categorizations || []).map((c) =>
    sortBy.push(getEntry(c.name || '', removeYear(c.name, (c as CategorizationFeDefined).builtin)))
  );

  return sortBy;
};
