import { FC } from 'react';

import { withStyles } from '../../../../theme/komodo-mui-theme';
import PrintPageBreak from '../../PrintSharedComponents/PrintPageBreak';
import { LANDSCAPE_WIDTH } from '../../PrintUtils';

import PrintProjectCompStyles from './PrintProjectCompStyles';

type PrintProjectCompRowProps = {
  classes: Classes<typeof PrintProjectCompStyles>;
  content: JSX.Element;
  hasBorder?: boolean;
  hasBreakpoint: boolean;
  headerHeight: number;
  pageHeader?: JSX.Element;
};

const PrintProjectCompRow: FC<PrintProjectCompRowProps> = ({
  classes,
  content,
  hasBorder,
  hasBreakpoint,
  headerHeight,
  pageHeader,
}) => {
  const breakpointHeader = hasBreakpoint && (
    <>
      {/* break page */}
      <PrintPageBreak pageWidth={LANDSCAPE_WIDTH} />
      {/* insert header (if applicable) */}
      {pageHeader && <div className={classes.pageBreakHeader}>{pageHeader}</div>}
      {/* adjust for height of header */}
      <div style={{ height: headerHeight, width: '100%' }} />
    </>
  );
  return (
    <>
      {breakpointHeader}
      <div className={`${classes.costRow} ${hasBorder ? classes.costRowBorder : ''}`}>
        {content}
      </div>
    </>
  );
};

export default withStyles(PrintProjectCompStyles)(PrintProjectCompRow);
