import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../theme/komodo-mui-theme';

const breadcrumbsStyles = (theme: KomodoTheme) =>
  createStyles({
    breadcrumb: {
      fontWeight: 400,
    },
    breadcrumbButton: {
      ...theme.linkFocus,
      borderRadius: theme.border.radius,
    },
    breadcrumbLink: {
      color: theme.palette.joinPrimary,
      cursor: 'pointer',
      maxWidth: '215px',
      overflow: 'hidden',
      textDecoration: 'underline',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    breadcrumbSeparator: {
      color: theme.palette.joinPrimary,
      padding: '0 4px',
    },
    inlineRow: {
      display: 'flex',
      flexDirection: 'row',
    },
    inlineRowNoPadding: {
      padding: '0px !important',
    },
  });

export default breadcrumbsStyles;
