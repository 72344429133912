import { GridController, GridType } from '../types';

export const LIMIT = 250;
const FETCH_TIMEOUT = 1000;
export const ROWS_DEBOUNCE = 100;
export const OFFSET_TOP = 0.5; // Used to move a pagination page area top by half

export const fetchPage = (
  grid: GridController,
  startRowDeb: number,
  endRowDeb: number,
  offsetsLoaded: Map<number, boolean>
) => {
  if (grid.type !== GridType.ESTIMATE_GRID) return;
  const startRowOffset = Math.round(startRowDeb / LIMIT - OFFSET_TOP) * LIMIT;
  const endRowOffset = Math.round(endRowDeb / LIMIT - OFFSET_TOP) * LIMIT;
  const isStartRowOffsetLoaded = offsetsLoaded.get(startRowOffset);
  const isEndRowOffsetLoaded = offsetsLoaded.get(endRowOffset);
  if (!isStartRowOffsetLoaded || !isEndRowOffsetLoaded) {
    let offset = startRowOffset;
    let limit = LIMIT;
    if (!isStartRowOffsetLoaded && !isEndRowOffsetLoaded) {
      limit = 2 * LIMIT;
      offsetsLoaded.set(startRowOffset, true);
      offsetsLoaded.set(endRowOffset, true);
    } else if (!isStartRowOffsetLoaded) {
      offsetsLoaded.set(startRowOffset, true);
    } else if (!isEndRowOffsetLoaded) {
      offset = endRowOffset;
      offsetsLoaded.set(endRowOffset, true);
    }
    const lastCall = grid.getLinesCallParams?.();
    if (
      lastCall &&
      lastCall.offset === offset &&
      lastCall.limit === limit &&
      Date.now() - FETCH_TIMEOUT < lastCall.time
    ) {
      return;
    }
    grid.setLinesCallParams({
      offset,
      limit,
      time: Date.now(),
    });

    grid.fetchMore({
      variables: {
        pagination: { offset, limit },
      },
    });
  }
};
