import { FC, useContext, useEffect, useRef } from 'react';

import { Typography } from '@material-ui/core';

import { CostTableColumnInputKey } from '../../../generated/graphql';
import { withStyles } from '../../../theme/komodo-mui-theme';
import { getCurrencySymbol } from '../../../utilities/currency';
import NormalTooltip from '../../NormalTooltip/NormalTooltip';
import { ProjectCompIntersectionElements } from '../constants/elementIdentifiers';
import ObserverContext from '../context/observer';
import styles from '../ProjectCompsStyles';

import { formatGridTemplateColumns } from './ProjectCompCostTableUtils';

type ProjectCompsCostTableHeaderProps = {
  classes: Classes<typeof styles>;
  costTableColumnInputs: CostTableColumnInputs;
  shouldObserve?: boolean;
  isAverage?: boolean;
  selectedUnits?: Unit[];
};

const ProjectCompsCostTableHeader: FC<ProjectCompsCostTableHeaderProps> = ({
  classes,
  costTableColumnInputs,
  shouldObserve = false,
  isAverage = false,
  selectedUnits,
}) => {
  const { projectCompIntersectionObserver } = useContext(ObserverContext);
  const tableHeader = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const currentTableHeader = tableHeader.current;
    if (projectCompIntersectionObserver && currentTableHeader && shouldObserve) {
      projectCompIntersectionObserver.observe(currentTableHeader);
    }
    return () => {
      if (projectCompIntersectionObserver && currentTableHeader) {
        projectCompIntersectionObserver.unobserve(currentTableHeader);
      }
    };
  }, [projectCompIntersectionObserver, tableHeader, shouldObserve]);

  const gridTemplateColumns = formatGridTemplateColumns(costTableColumnInputs);

  return (
    <div
      ref={tableHeader}
      className={`${classes.row} ${classes.row_tableHeader} ${
        shouldObserve ? ProjectCompIntersectionElements.COST_TABLE_HEADER : ''
      }`}
      style={{ gridTemplateColumns }}
    >
      {costTableColumnInputs?.map((input) => {
        // TODO - consolidate styles of each of these to avoid switch stmt - DD-713
        const unit = selectedUnits?.find((u) => u.id === input.unitID);
        switch (input.key) {
          case CostTableColumnInputKey.TOTAL: {
            const headerTitle = (
              <div
                className={`${classes.cell} ${classes.whiteBG}`}
                data-cy="project-comp-table-header"
              >
                <Typography className={classes.costColumnHeaderLabel}>Total</Typography>
              </div>
            );
            return isAverage ? (
              <NormalTooltip
                key={unit?.abbreviationSingular}
                placement="right"
                title="Total is calculated based on the pinned unit"
              >
                {headerTitle}
              </NormalTooltip>
            ) : (
              headerTitle
            );
          }
          case CostTableColumnInputKey.TOTAL_PER_METRIC:
            return (
              <NormalTooltip key={unit?.abbreviationSingular} placement="right" title={unit?.name}>
                <div
                  className={`${classes.cell} ${classes.whiteBG}`}
                  data-cy="project-comp-table-header"
                >
                  <Typography
                    className={`${classes.costColumnHeaderLabel} ${classes.tableHeaderElipsis}`}
                  >{`${getCurrencySymbol()}/${unit?.abbreviationSingular}`}</Typography>
                </div>
              </NormalTooltip>
            );
          case CostTableColumnInputKey.PERCENT:
            return (
              <div
                className={`${classes.cell} ${isAverage ? '' : classes.whiteBG}`}
                data-cy="project-comp-table-header"
              >
                <Typography className={classes.costColumnHeaderLabel}>Percent</Typography>
              </div>
            );
          case CostTableColumnInputKey.METRIC:
            return (
              <div
                className={`${classes.cell} ${isAverage ? '' : classes.whiteBG}`}
                data-cy="project-comp-table-header"
              >
                <Typography className={classes.costColumnHeaderLabel}>
                  {unit?.abbreviationSingular}
                </Typography>
              </div>
            );
          default:
            throw new Error('Unsupported cell type in ProjectCompsCostTableHeader');
        }
      })}
    </div>
  );
};

export default withStyles(styles)(ProjectCompsCostTableHeader);
