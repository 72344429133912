import joinAPI from '../../../api/joinAPI';
import {
  GetCategorizationQuery,
  GetCategorizationQueryVariables,
} from '../../../generated/graphql';

import { getCategorizationQuery } from './queries';

type CategorizationQueryResult = {
  data?: GetCategorizationQuery;
};

const getCategorization = (
  id: UUID,
  onSuccess: (result: CategorizationQueryResult) => void,
  pagination?: Pagination
) => {
  const variables: GetCategorizationQueryVariables = {
    id,
    pagination,
  };
  joinAPI
    .requestGraphQL(getCategorizationQuery, variables)
    .then((r: CategorizationQueryResult) => onSuccess(r));
};

export default getCategorization;
