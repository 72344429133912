import { Navigate, useOutlet, useOutletContext, useParams } from 'react-router-dom';

import { LinearProgress } from '@material-ui/core';

import { Role } from '../../../generated/graphql';
import useRolesQuery from '../../../hooks/useRolesQuery';
import { RouteKeys } from '../../../routes/paths';
import { generateSharedPath } from '../../../utilities/routes/links';
import { ListDetail } from '../../scales';

import VerticalMenu from './VerticalMenu';

const RolesRoute = () => {
  const { projectId } = useParams();
  if (!projectId) {
    throw new Error('Failed to get projectId param');
  }

  const rolesQueryResult = useRolesQuery(projectId);
  const roles =
    rolesQueryResult.data?.projectRoles ?? rolesQueryResult.previousData?.projectRoles ?? [];

  const outlet = useOutlet(roles satisfies Role[]);
  if (!outlet && roles[0]?.id) {
    return (
      <Navigate
        to={generateSharedPath(RouteKeys.PROJECT_TEAM_ROLES_ROLE_VIEW, {
          projectId,
          roleId: roles[0].id,
        })}
      />
    );
  }

  if (roles.length === 0) return <LinearProgress />;

  return <ListDetail detail={outlet} list={<VerticalMenu projectID={projectId} roles={roles} />} />;
};

export default RolesRoute;

export function useRoles() {
  return useOutletContext<Role[]>();
}
