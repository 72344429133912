import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../../theme/komodo-mui-theme';

export default (theme: KomodoTheme) =>
  createStyles({
    categorizedMetricContainer: {
      border: `1px solid ${theme.palette.borderDarkGrey}`,
      padding: 4,
      position: 'relative',
      maxHeight: 230,
      overflowY: 'scroll',
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: '100%',
    },
    link: {
      ...theme.typography.link,
      color: theme.palette.link,
      textAlign: 'center',
      paddingBottom: 16,
    },
    metricContainer: {
      border: `1px solid ${theme.palette.borderDarkGrey}`,
      padding: 4,
      position: 'relative',
      marginTop: 16,
    },
    unitsContent: {
      textAlign: 'center',
    },
    warning: {
      height: 12,
      paddingTop: 4,
      textAlign: 'right',
      width: '100%',
    },
  });
