import { FC } from 'react';

import { withStyles } from '../../../../theme/komodo-mui-theme';
import { Thumbnail } from '../../../dragon-scales';
import { CompsPrintThumbnailSize, getHeaderTitleDisplayData } from '../PrintProjectCompsUtils';

import PrintProjectCompStyles from './PrintProjectCompStyles';

type PrintColumnHeaderTitleProps = {
  classes: Classes<typeof PrintProjectCompStyles>;
  compData: ProjectComp | AverageComp;
  onThumbnailLoad: () => void;
  thumbnailSize: CompsPrintThumbnailSize;
};

const PrintColumnHeaderTitle: FC<PrintColumnHeaderTitleProps> = ({
  classes,
  compData,
  onThumbnailLoad,
  thumbnailSize,
}) => {
  const { color, name, thumbnail } = getHeaderTitleDisplayData(compData);
  return (
    <>
      <div className={classes.columnHeaderTitle_color} style={{ backgroundColor: color }} />
      {thumbnailSize === CompsPrintThumbnailSize.LARGE && (
        <div className={classes.columnHeaderTitle_thumbnail}>
          <div className="pb-1">
            <Thumbnail onLoad={onThumbnailLoad} size={40} thumbnail={thumbnail} />
          </div>
        </div>
      )}
      <div className={classes.columnHeaderTitle_container}>
        {thumbnailSize === CompsPrintThumbnailSize.SMALL && (
          <div className={classes.columnHeaderTitle_thumbnail}>
            <Thumbnail onLoad={onThumbnailLoad} size={16} thumbnail={thumbnail} />
          </div>
        )}
        <div
          className={`${classes.columnHeaderTitle_titleTextPlacement} ${classes.titleText} truncate`}
        >
          {name}
        </div>
      </div>
    </>
  );
};

export default withStyles(PrintProjectCompStyles)(PrintColumnHeaderTitle);
