import { Navigate, useLocation } from 'react-router-dom';

import { useIsJoinAccessDeactivated } from '../hooks/useIsJoinAccessDeactivated';
import { RouteKeys } from '../routes/paths';
import { generateSharedPath } from '../utilities/routes/links';

import useInitHubspotToken from './login/hooks/useInitHubspotToken';
import SignUpLayer from './login/SignUpLayer';

export default function RootRoute() {
  useInitHubspotToken();

  const { pathname } = useLocation();
  const isAccessDeactivated = useIsJoinAccessDeactivated();
  if (isAccessDeactivated && pathname !== generateSharedPath(RouteKeys.DEACTIVATED)) {
    return <Navigate to="/deactivated" />;
  }

  return <SignUpLayer />;
}
