import { FC } from 'react';

import { InsertChart, Print, TrendingUp } from '@material-ui/icons';

import {
  PROJECT_DASHBOARD_PRINT,
  PROJECT_DASHBOARD_PRINT_WITH_COST_SUMMARY,
  PROJECT_DASHBOARD_RUN_CATEGORY_TRENDS,
  PROJECT_DASHBOARD_RUN_ESTIMATE_CHART,
} from '../../../actions/actionTypes';
import { analyticsEvent } from '../../../analytics/analyticsEventProperties';
import { JoinProjectRoutes } from '../../../api/gqlEnums';
import useSendAnalytics from '../../../hooks/useSendAnalytics';
import { generateSharedPath } from '../../../utilities/routes/links';
import { Export } from '../../Icons/Export';
import IconMenu from '../../Select/SelectMenu/IconMenu';

type DashboardExportPanelProps = {
  onEstimateTableClick: () => void;
  printOnClick: (route: JoinProjectRoutes) => void;
  projectId: UUID;
};

const DASHBOARD_EXPORT = 'dashboard-export';

const DashboardExportPanel: FC<DashboardExportPanelProps> = ({
  onEstimateTableClick,
  printOnClick,
  projectId,
}) => {
  const onBreakdownClick = () => {
    window.open(generateSharedPath(JoinProjectRoutes.BREAKDOWNS, { projectId }), '_blank');
  };

  const sendAnalytics = useSendAnalytics();

  const options = [
    {
      callback: () => {
        sendAnalytics(analyticsEvent(PROJECT_DASHBOARD_RUN_CATEGORY_TRENDS));
        onBreakdownClick();
      },
      icon: <TrendingUp />,
      name: 'Category Trends Breakdown',
    },
    {
      callback: () => {
        sendAnalytics(analyticsEvent(PROJECT_DASHBOARD_RUN_ESTIMATE_CHART));
        onEstimateTableClick(); // RENAME???
      },
      icon: <InsertChart />,
      name: 'Estimate Chart with Details',
    },
    {
      callback: () => {
        sendAnalytics(analyticsEvent(PROJECT_DASHBOARD_PRINT_WITH_COST_SUMMARY));
        printOnClick(JoinProjectRoutes.PRINT_PROJECT_DASHBOARD_SUMMARY);
      },
      icon: <Print />,
      name: 'Print Dashboard With Cost Summary',
    },
    {
      callback: () => {
        sendAnalytics(analyticsEvent(PROJECT_DASHBOARD_PRINT));
        printOnClick(JoinProjectRoutes.PRINT_PROJECT_DASHBOARD);
      },
      icon: <Print />,
      name: 'Print Dashboard',
    },
  ];

  return (
    <div>
      <IconMenu cy={DASHBOARD_EXPORT} icon={<Export />} isBottomOriented options={options} />
    </div>
  );
};

export default DashboardExportPanel;
