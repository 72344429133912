import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';

export default (theme: KomodoTheme) =>
  createStyles({
    card: {
      paddingBottom: 8,
    },
    collapseTitle: {
      ...theme.typography.cardHeader,
      fontWeight: 500,
      paddingTop: 2,
    },
  });
