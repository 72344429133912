import { useMutation } from '@apollo/client';

import { REFETCH_SCENARIO_SANDBOX } from '../../../api/refetchSets';
import {
  AddScenarioToSandboxMutation,
  AddScenarioToSandboxMutationVariables,
} from '../../../generated/graphql';

import { addScenarioToSandboxMutation as addScenarioToSandbox } from './queries';

const useAddScenarioToSandboxMutation = ({ milestoneID }: { milestoneID: UUID }) => {
  const [addFn] = useMutation<AddScenarioToSandboxMutation, AddScenarioToSandboxMutationVariables>(
    addScenarioToSandbox,
    {
      refetchQueries: REFETCH_SCENARIO_SANDBOX,
    }
  );

  const addScenario = ({ scenarioID }: { scenarioID: UUID }) =>
    addFn({
      variables: {
        scenarioID,
        milestoneID,
      },
    });

  return [addScenario];
};

export default useAddScenarioToSandboxMutation;
