import { detailedMilestoneFields, gql } from '../../../../api/graphqlFragments';

export const createMilestoneMutation = gql`
  mutation createMilestone($projectID: UUID!, $input: MilestoneInput) {
    createMilestone(projectID: $projectID, milestone: $input) {
      ...detailedMilestoneFields
    }
  }
  ${detailedMilestoneFields}
`;
