import { PermissionGroup } from '../../../../generated/graphql';
import { Table } from '../../../scales';
import {
  getIsDisabledCostPermission,
  getPermissionDescriptionStrings,
  getPermissionLevelString,
  getPermissionsTableLabels,
} from '../utils';

import LevelDescriptionCell from './cells/LevelDescriptionCell';
import PermissionDescriptionCell from './cells/PermissionDescriptionCell';
import PermissionTextCell from './cells/PermissionTextCell';
import { PermissionDisplayData } from './permissions';

type Props = {
  canViewCosts?: boolean;
  group: PermissionGroup;
  permissionsDisplayData: PermissionDisplayData[];
  shouldDisableMarkups?: boolean;
};

const PermissionsTableView = (props: Props) => {
  return (
    <Table
      columnWidths={['3fr', '4fr', '2fr', '3fr']}
      entries={props.permissionsDisplayData.map((displayData) => {
        const matchedPermission = props.group.permissions.find(
          (p) => p.resource === displayData.resource
        );

        if (!matchedPermission) return [];
        const disabled = getIsDisabledCostPermission(
          props.group,
          matchedPermission,
          props.shouldDisableMarkups,
          props.canViewCosts
        );

        return [
          {
            key: displayData.label,
            component: <PermissionTextCell disabled={disabled} label={displayData.label} />,
          },
          {
            key: displayData.description,
            component: <PermissionDescriptionCell disabled={disabled} displayData={displayData} />,
          },
          {
            key: `${matchedPermission.level}-${matchedPermission.id}`,
            component: (
              <PermissionTextCell
                disabled={disabled}
                label={getPermissionLevelString(matchedPermission.level, disabled)}
              />
            ),
          },
          {
            key: `${matchedPermission.actions}-${matchedPermission.id}`,
            component: (
              <LevelDescriptionCell
                disabled={disabled}
                labels={getPermissionDescriptionStrings(matchedPermission, disabled)}
              />
            ),
          },
        ];
      })}
      headerContent={getPermissionsTableLabels(false)}
    />
  );
};

export default PermissionsTableView;
