import { useState } from 'react';

import {
  getCategorizationsForProjectFromQueryData,
  useProjectCategorizationsQuery,
} from '../../../hooks/useProjectCategorizationsQuery';
import useProjectPropsQuery from '../../../hooks/useProjectPropsQuery';
import { getProjectIdFromUrl } from '../../../utilities/url';
import { useLoadTimer } from '../../PerfMonitor/utils';
import VarianceReport from '../VarianceReport/VarianceReport';

const VarianceReportData = () => {
  const projectID = getProjectIdFromUrl();

  const { data: projectData, loading: projectLoading, error } = useProjectPropsQuery(projectID);
  const { data: catData, loading: catLoading } = useProjectCategorizationsQuery(projectID);

  const milestoneID = projectData?.project?.activeMilestone?.id ?? '';
  const categorizations = getCategorizationsForProjectFromQueryData(catData);

  const hooksLoading = projectLoading || catLoading;

  const [varianceReportIsLoading, setIsLoading] = useState(true);
  useLoadTimer('VarianceReport', varianceReportIsLoading);

  return !hooksLoading ? (
    <>
      <VarianceReport
        categorizations={categorizations}
        error={error}
        milestoneID={milestoneID}
        setIsLoading={setIsLoading}
      />
    </>
  ) : null;
};

export default VarianceReportData;
