import { Link } from 'react-router-dom';

import { RouteKeys } from '../../../routes/paths';
import { generateSharedPath } from '../../../utilities/routes/links';
import { pluralizeCountString } from '../../../utilities/string';
import { Chip, Icon } from '../../scales';
import { ProjectIdea } from '../types';
import { getRelevanceText } from '../utils/getRelevanceText';

type Props = { ideas: ProjectIdea[]; projectID: UUID };

export default function SuggestedIdeas(props: Props) {
  return (
    <div className="grid w-full grid-cols-[repeat(auto-fill,minmax(280px,1fr))] gap-6">
      {props.ideas.map((idea) => (
        <SuggestionTile key={idea.id} idea={idea} projectID={props.projectID} />
      ))}
    </div>
  );
}

function SuggestionTile(props: { idea: ProjectIdea; projectID: UUID }) {
  const alternatesText = pluralizeCountString('Alternate', props.idea.alternates.length);
  const relevanceText = getRelevanceText(props.idea);
  const uniformatText =
    props.idea.alternates[0].uniFormat?.number &&
    `UniFormat: ${props.idea.alternates[0].uniFormat?.number}`;
  const masterformatText =
    props.idea.alternates[0].masterFormat?.number &&
    `MasterFormat: ${props.idea.alternates[0].masterFormat?.number}`;

  return (
    <Link
      className="flex h-64 w-full cursor-pointer rounded bg-cover bg-no-repeat"
      style={{ backgroundImage: `url(${props.idea.root.imageURL})` }}
      to={generateSharedPath(RouteKeys.PROJECT_IDEAS_IDEA, {
        projectId: props.projectID,
        ideaID: props.idea.id,
      })}
    >
      <div className="flex w-full flex-col justify-between">
        <div className="flex justify-end p-2">
          <Chip backgroundColor="#000000B3" text={alternatesText} />
        </div>
        <div className="flex flex-col gap-2 rounded-b bg-gradient-to-t from-[#000000B3] from-70% to-[#0000] to-100% p-2 pt-6 text-type-secondary">
          <div className="type-heading3">{props.idea.name}</div>
          <div className="flex items-center gap-2 type-label">
            <Icon name="bulb_ray" />
            {relevanceText}
          </div>
          {(uniformatText || masterformatText) && (
            <div className="flex gap-2">
              {uniformatText && <Chip backgroundColor="#000000B3" text={uniformatText} />}
              {masterformatText && <Chip backgroundColor="#000000B3" text={masterformatText} />}
            </div>
          )}
        </div>
      </div>
    </Link>
  );
}
