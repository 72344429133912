import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../theme/komodo-mui-theme';

export const DIALOG_PADDING = 24;
export const DIALOG_MODAL_WIDTH = 544;
export const BUTTON_SIZE = 36;

export const actionSection = (theme: KomodoTheme) => ({
  background: theme.palette.backgroundWhite,
  borderTop: theme.border.line,
  borderBottomLeftRadius: '4px',
  borderBottomRightRadius: '4px',
  margin: 0,
  padding: '16px 24px',
  display: 'flex',
  justifyContent: 'end',
  gap: '4px',
});

export const titleSection = (theme: KomodoTheme) => ({
  alignItems: 'center',
  background: theme.palette.backgroundWhite,
  borderTopLeftRadius: '4px',
  borderTopRightRadius: '4px',
  display: 'flex',
  flexDirection: 'row' as const,
  justifyContent: 'space-between',
  padding: '16px 12px 16px 28px',
});

const dialogsStyles = (theme: KomodoTheme) =>
  createStyles({
    action: actionSection(theme),
    actionFlex: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    bold: {
      fontWeight: 600,
    },
    boldBlue: {
      color: theme.palette.middleBlue,
      fontWeight: 500,
      fontSize: '1em',
    },
    borderGrey: {
      border: theme.border.line,
    },
    buttonsContainer: {
      display: 'flex',
    },
    buttonContainer: {
      padding: 4,
    },
    textContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    textInput: {
      margin: 0,
    },
    center: {
      margin: 'auto',
    },
    centerText: {
      textAlign: 'center',
    },
    check: {
      marginLeft: 6,
    },
    leftText: {
      textAlign: 'left',
    },
    close: {
      color: theme.palette.joinPrimary,
      height: 32,
      margin: 0,
      width: 32,
    },
    content: {
      padding: DIALOG_PADDING,
    },
    viewOnlyPadding: {
      padding: DIALOG_PADDING / 2,
    },
    dialogContent: {
      background: theme.palette.backgroundWhite,
      padding: 0,
      overflowY: 'auto',
      maxHeight: 475,
    },
    dialogOverlay: {
      width: '100vw',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100vh',
      position: 'fixed',
      touchAction: 'none',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      left: 0,
      bottom: 0,
      right: 0,
      top: 0,
      zIndex: 9999999,
    },
    dialogTabs: {
      borderBottom: theme.border.line,
    },
    dialogTextAll: {
      fontFamily: theme.typography.fontFamily,
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: '18.28px',
    },
    pageCountText: {
      fontFamily: theme.typography.fontFamily,
      fontSize: 14,
      fontWeight: 500,
    },
    dialogHelpText: {
      fontWeight: 400,
    },
    dialogItalic: {
      fontStyle: 'italic',
    },
    dialogPaper: {
      maxWidth: '900px',
      width: DIALOG_MODAL_WIDTH,
      maxHeight: '95vh',
    },
    dialogPaperSmall: {
      width: '600px',
      overflowX: 'hidden',
    },
    dialogPaperLarge: {
      maxWidth: '900px',
      width: '75%',
      maxHeight: '95vh',
    },
    dialogPaperKeyboardShortcuts: {
      minHeight: '704px',
      width: '470px',
    },
    dialogPaperDialogsNewItem: {
      background: theme.palette.backgroundWhite,
      borderRadius: '4px',
      maxHeight: '596px',
      maxWidth: 900,
    },
    dialogPaperDialogsNewItemWithSubtitle: {
      maxHeight: '646px',
    },
    dynamicHeight: {
      height: 'auto !important',
    },
    flex: {
      display: 'flex',
    },
    alignItemsCenter: {
      alignItems: 'center',
    },
    footerCentered: {
      display: 'flex',
      flexGrow: 1,
      gap: '10px',
      justifyContent: 'center',
    },
    helpText: {
      paddingBottom: 10,
      fontWeight: 400,
      fontSize: 16,
      lineHeight: '23px',
      fontFamily: theme.typography.fontFamily,
    },
    inline: { display: 'inline-flex', alignItems: 'center' },
    inputHeight: {
      maxHeight: 31,
    },
    inputText: {
      fontSize: '16px',
      fontWeight: 400,
      fontFamily: theme.typography.fontFamily,
    },
    info: {
      color: theme.palette.joinGrey400,
      marginLeft: 4,
      marginBottom: 4,
      fontSize: 16,
    },
    iconAdd: {
      fontSize: '20px',
    },
    iconTooltip: {
      height: 16,
      color: theme.palette.disabledGrey,
    },
    link: {
      color: theme.palette.link,
      cursor: 'pointer',
      fontFamily: theme.typography.fontFamily,
      textDecoration: 'underline',
    },
    linkButton: {
      ...theme.typography.link,
      fontFamily: theme.typography.fontFamily,
      display: 'flex',
      alignItems: 'center',
      margin: '8px 0',
    },
    loading: {
      height: 3,
    },
    error: {
      color: theme.palette.red,
    },
    contentEnd: { justifyContent: 'end' },
    contentWidth: {
      width: '80%',
      maxWidth: 1200,
    },
    pullLeft: {
      marginRight: 'auto',
    },
    pullRight: {
      marginLeft: 'auto',
    },
    noPadding: {
      padding: 0,
    },
    paddingLeft: {
      paddingLeft: '4px',
    },
    onboarding: {
      display: 'flex',
      flexDirection: 'column',
      maxHeight: '65vh',
      overflowY: 'auto',
    },
    spacer: {
      flexGrow: 1,
    },
    span2: {
      gridColumn: 'span 2',
    },
    splitColumns: {
      display: 'grid',
      gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
      gap: '10px',
    },
    subHeader: {
      padding: '8px 0px 0px 0px',
      fontSize: '16px',
    },
    subtitle: {
      padding: '0px 24px 0px',
    },
    tabs: {
      textTransform: 'capitalize',
    },
    tabTitle: {
      paddingBottom: '0px !important',
    },
    titleContainer: titleSection(theme),
    titleBorderBottom: {
      borderBottom: theme.border.line,
    },

    verticalPadding: { padding: '16px 0px' },
    paddingBottom: { paddingBottom: '24px !important' },
    checkbox: { height: BUTTON_SIZE, marginRight: 8, width: BUTTON_SIZE },
    paddingBottomHalf: { paddingBottom: '12px !important' },
    fullHeight: {
      height: '100vh',
      maxWidth: '900px',
      [theme.breakpoints.down('md')]: {
        margin: 0,
        minHeight: '300px',
        width: '100%',
      },
      overflowY: 'hidden',
      padding: 0,
    },
    fullWidth: {
      width: '100%',
    },
    halfHeight: {
      height: '50%',
      overflow: 'hidden',
    },
    contentContainer: {
      display: 'contents',
      height: '100%',
    },
    horizontal: {
      display: 'flex',
      flex: 1,
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    horizontalContainer: {
      paddingBottom: '4px',
      display: 'flex',
      flex: 1,
      alignItems: 'center',
      justifyContent: 'space-between',
      [theme.breakpoints.down('xs')]: {
        flexWrap: 'wrap',
      },
    },
    verticalContainer: {
      paddingBottom: '4px',
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      justifyContent: 'space-between',
      gap: '10px',
    },
    iconButtonText: {
      padding: 4,
    },
    selectOuterContainer: {
      fontWeight: 600,
      width: 350,
    },
    tipContainer: {
      alignItems: 'baseline',
      display: 'flex',
      justifyContent: 'center',
      marginTop: 34,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    whiteButton: {
      border: `1px solid ${theme.palette.joinPrimary}`,
      whiteSpace: 'nowrap',
      padding: '4px 16px 4px 12px',
      minWidth: BUTTON_SIZE,
      '@media print': {
        display: 'none',
      },
    },
    videoBox: {
      width: '100%',
      height: '350px',
      border: 'none',
    },
    replaceEstimateContainer: {
      width: '80%',
      maxWidth: 840,
    },
    newTitleText: {
      ...theme.typography.title,
      fontWeight: 500,
      paddingBottom: 8,
    },
    paddingBottomExcel: {
      paddingBottom: 10,
    },
    marginBottom: {
      marginBottom: 8,
    },
    transitionManager: {
      width: 700,
    },
  });

export default dialogsStyles;
