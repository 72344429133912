import { isItemEstimateVariant } from '../../actions/gridAnalytics';
import { PermissionResource } from '../../generated/graphql';
import { checkCostModeIncludesMarkups, useCostMode } from '../../utilities/costMode';
import usePermissions, {
  getItemLinesPermissionResource,
} from '../../utilities/permissions/usePermissions';
import { EstimateGridPermissions, GridVariant } from '../JoinGrid/types';

export const useGridPermissions = (
  variant: GridVariant,
  canViewDetails: boolean,
  linesReadOnly?: boolean,
  itemID?: UUID,
  itemTrade?: Category[]
): EstimateGridPermissions => {
  const costMode = useCostMode();

  const { canView, canEdit, inTrade } = usePermissions({ trades: itemTrade });
  let canEditLines: boolean;
  if (isItemEstimateVariant(variant) || !!itemID) {
    // item estimate
    canEditLines = canEdit(getItemLinesPermissionResource(inTrade));
  } else if (variant !== GridVariant.ITEM_TEMPLATE) {
    // milestone estimate
    canEditLines = canEdit(PermissionResource.MILESTONE_LINES);
  } else {
    // item template estimate
    canEditLines = false; // you can never edit item template lines
  }
  const canEditItemTemplate = canEdit(PermissionResource.ITEM_TEMPLATE);
  const canEditColumns = variant === GridVariant.ITEM_TEMPLATE ? canEditItemTemplate : canEditLines;
  const canViewMarkups = canView(PermissionResource.MARKUPS);
  const canEditMarkups =
    variant === GridVariant.ITEM_TEMPLATE
      ? canEditItemTemplate
      : canEdit(PermissionResource.MARKUPS);
  const canEditOwnerCosts = canEdit(PermissionResource.OWNER_COSTS);
  const canViewOwnerCosts = canView(PermissionResource.OWNER_COSTS);
  const canViewEstimateCostSubtotals = canView(PermissionResource.ESTIMATE_COST_SUBTOTALS);

  const canViewMarkupDetails = checkCostModeIncludesMarkups(costMode) && canViewMarkups;
  const viewEstimateCostSubtotals =
    checkCostModeIncludesMarkups(costMode) && canViewEstimateCostSubtotals;
  const summaryMarkups =
    checkCostModeIncludesMarkups(costMode) && !canViewMarkups && !canViewOwnerCosts;

  return {
    canViewEstimate: canViewDetails,
    canEditLines: !linesReadOnly && canEditLines,
    canEditColumns: !linesReadOnly && canEditColumns,
    canViewMarkupDetails,
    canViewMarkups: (canViewMarkupDetails || summaryMarkups) && viewEstimateCostSubtotals,
    summaryMarkups,
    canEditMarkups: !linesReadOnly && checkCostModeIncludesMarkups(costMode) && canEditMarkups,
    canEditOwnerCosts: !linesReadOnly && canEditOwnerCosts,
    canViewOwnerCosts,
    canViewEstimateCostSubtotals,
  };
};
