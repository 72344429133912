import { KeyboardEvent } from 'react';

import { Info } from '@material-ui/icons';

import {
  LeadEvent,
  LeadKey,
  SendAnalyticsFn,
  leadEvent,
} from '../../../../../analytics/analyticsEventProperties';
import { CreateProjectMutationVariables, DesignPhaseType } from '../../../../../generated/graphql';
import MilestoneDesignPhaseSelect from '../../../../dragon-scales/MilestoneDesignPhaseSelect/MilestoneDesignPhaseSelect';
import { Lightbulb } from '../../../../Icons/Lightbulb';
import { ProjectIcon } from '../../../../Icons/ProjectIcon';
import {
  Button,
  Callout,
  DateInput,
  DialogContent,
  DialogFlowStep,
  TextInput,
  Tooltip,
} from '../../../../scales';
import { milestoneLabel, milestoneTooltip } from '../../DialogsNewProjectUtils';
import isValidProjectInput from '../../isValidProjectInput';

type Props = {
  designPhaseTypes: DesignPhaseType[];
  onKeyDown: (e: KeyboardEvent<HTMLInputElement>) => void;
  project: Partial<CreateProjectMutationVariables>;
  setProject: (values: Partial<CreateProjectMutationVariables>) => void;
  submitted: boolean;
  template?: ProjectTemplate;
  onCreateProject: () => Promise<unknown>;
  sendAnalytics: SendAnalyticsFn;
};

export default function getMilestoneStep(props: Props): DialogFlowStep {
  const {
    designPhaseTypes,
    onKeyDown,
    project,
    setProject,
    submitted,
    template,
    onCreateProject,
    sendAnalytics,
  } = props;

  return {
    renderContent: () => (
      <DialogContent>
        <div className="flex h-100 flex-col justify-between">
          <div className="flex flex-col gap-2">
            {template && (
              <Callout isFullWidth>
                <div className="flex items-center gap-1">
                  <ProjectIcon />
                  <div className="inline items-center type-body1">{`Creating from ${template.name} template.`}</div>
                </div>
              </Callout>
            )}
            <div className="pb-2 pt-3 type-heading3">Active Milestone</div>
            <div className="flex flex-row gap-2">
              <div className="flex w-1/2 flex-col gap-0.5">
                <div className="flex items-center gap-1 type-label">
                  {milestoneLabel(!template)}
                  <Tooltip
                    content={
                      <div className="type-body3">
                        <p>{milestoneTooltip}</p>
                        {template && (
                          <p>
                            Once a project is created you can go to the milestone and change its
                            name.
                          </p>
                        )}
                      </div>
                    }
                  >
                    <div>
                      <Info className="text-type-muted" fontSize="inherit" />
                    </div>
                  </Tooltip>
                </div>
                <TextInput
                  aria-label="Set active milestone name"
                  data-cy="active-milestone-name-text-input"
                  isDisabled={!!template || submitted}
                  onChange={(milestone) => {
                    setProject({ ...project, milestone });
                  }}
                  onKeyDown={onKeyDown}
                  placeholder="Set milestone name"
                  value={project.milestone || ''}
                />
              </div>
              <div className="flex w-1/2 flex-col">
                <DateInput
                  data-cy="milestone-date-picker"
                  isClearable
                  isDisabled={submitted}
                  label="Milestone Date *"
                  onChange={(date: string | null) => {
                    setProject({ ...project, milestoneDate: date ?? undefined });
                  }}
                  value={project.milestoneDate}
                />
              </div>
            </div>
            <MilestoneDesignPhaseSelect
              entries={designPhaseTypes}
              isDisabled={submitted}
              label="Milestone Design Phase *"
              onChange={(designPhase) =>
                setProject({ ...project, milestoneDesignPhaseID: designPhase.id })
              }
              value={project.milestoneDesignPhaseID}
            />
          </div>
          <div className="flex p-2">
            <Callout isFullWidth>
              <div className="flex items-center gap-1">
                <Lightbulb />
                <div className="inline type-body3">
                  Tip: In a Join project, you&#39;ll manage and present items, which are potential
                  changes relative to a particular project milestone.
                </div>
              </div>
            </Callout>
          </div>
        </div>
      </DialogContent>
    ),
    renderFooterRight: ({ onNext }) => (
      <Button
        data-cy="create-project-button"
        isDisabled={!isValidProjectInput(project)}
        label="Create Project"
        onClick={() => {
          if (!submitted) {
            onCreateProject().then(() => onNext());
            sendAnalytics(leadEvent(LeadKey.NEW_PROJECT, LeadEvent.CREATE));
          }
        }}
        type="primary"
      />
    ),
  };
}
