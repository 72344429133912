import { ProgramProjectFieldsFragment } from '../../../../../generated/graphql';
import ProjectSelection from '../../../../ProjectsList/ProjectSelection/ProjectSelection';
import { Button, DialogFlowStep, Tooltip } from '../../../../scales';

type Props = {
  onChange: (newProjectIDs: UUID[]) => void;
  onSubmit: () => void;
  selectedProjects: ProgramProjectFieldsFragment[];
};

export default function getProjectSelectionStep(props: Props): DialogFlowStep {
  const selectedProjectCount = props.selectedProjects.length;
  return {
    title: 'New Program',
    renderContent: () => (
      <ProjectSelectionContent
        onChange={props.onChange}
        selectedProjects={props.selectedProjects}
      />
    ),
    renderFooterRight: (args) => {
      const disabled = selectedProjectCount < 2;
      return (
        <div className="flex items-center gap-4">
          <div>{`${selectedProjectCount} selected`}</div>
          <Tooltip
            content={
              selectedProjectCount < 2
                ? `You must select at least 2 Projects you are an Administrator role in`
                : ''
            }
          >
            <Button
              isDisabled={disabled}
              label="Add Details"
              onClick={() => {
                props.onSubmit();
                args.onNext();
              }}
              type="primary"
            />
          </Tooltip>
        </div>
      );
    },
  };
}

type ProjectSelectionContentProps = {
  selectedProjects: ProgramProjectFieldsFragment[];
  onChange: (newProjectIDs: UUID[]) => void;
};

function ProjectSelectionContent(props: ProjectSelectionContentProps) {
  return (
    <div>
      <div className="flex h-16 items-center px-6">
        Select at least 2 Projects you are an Administrator role in to create a Program
      </div>
      <ProjectSelection
        disableIfNotAdmin
        onChange={(newProjectIDs) => props.onChange(newProjectIDs)}
        selectedProjectIDs={props.selectedProjects.map((p) => p.id)}
        selectionMode="multiple"
      />
    </div>
  );
}
