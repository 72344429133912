import { EstimateType } from '../../../api/gqlEnums';
import theme from '../../../theme/komodo-mui-theme';
import { CELL_TEXT_PADDING } from '../../JoinGrid/style/styleConstants';
import { GridVariant } from '../../JoinGrid/types';

export const getMaxWidth = (width: number, enableColumnMenu?: boolean) =>
  width
    ? width - (enableColumnMenu ? theme.iconSizes.compact : CELL_TEXT_PADDING) - CELL_TEXT_PADDING
    : undefined;

// used for analytics when editing an estimate
export const getEstimateTypeFromGridVariant = (variant: GridVariant): EstimateType | null => {
  switch (variant) {
    case GridVariant.MILESTONE_ESTIMATE:
      return EstimateType.ACTIVE_ESTIMATE;
    case GridVariant.MILESTONE_BUDGET:
      return EstimateType.BUDGET;
    case GridVariant.ITEM_FULLSCREEN:
    case GridVariant.ITEM_DETAILS:
      return EstimateType.ITEM;
    default:
  }
  return null;
};
