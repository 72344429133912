import { FC } from 'react';

import { withStyles } from '../../../theme/komodo-mui-theme';

import ItemsStatusHoverSelector, {
  ItemsStatusHoverSelectorProps,
} from './ItemsStatusHoverSelector';
import ItemsStatusHoverSelectorStylesCompact from './ItemsStatusHoverSelectorStylesCompact';

const ItemsStatusHoverSelectorCompact: FC<ItemsStatusHoverSelectorProps> = (props) => (
  <ItemsStatusHoverSelector {...props} />
);

export default withStyles(ItemsStatusHoverSelectorStylesCompact)(ItemsStatusHoverSelectorCompact);
