import ProjectCostRow from '../../../../ProjectsList/ProjectCostRow';

type Props = {
  currency?: string; // TODO: Defaulting to USD for now
  project: Pick<ForecastingProject, 'estimate' | 'runningTotal' | 'budget'>;
  onClick?: () => void;
};

export default function ActiveMilestoneCosts(props: Props) {
  return (
    <div
      className="grid h-full w-50 shrink-0 grid-cols-2 gap-y-1"
      onClick={props.onClick}
      onKeyDown={(event) => {
        if (props.onClick && event.key === 'Enter') props.onClick();
      }}
    >
      <ProjectCostRow
        className="text-entities-estimate"
        cost={props.project.estimate}
        currency={props.currency ?? 'USD'}
        label="Estimate"
      />
      <ProjectCostRow
        className="text-type-primary"
        cost={props.project.runningTotal}
        currency={props.currency ?? 'USD'}
        label="Running Total"
      />
      <ProjectCostRow
        className="text-entities-milestone"
        cost={props.project.budget}
        currency={props.currency ?? 'USD'}
        label="Budget"
      />
    </div>
  );
}
