import * as d3 from 'd3';
import { extent } from 'd3-array';
import { ScaleTime, scaleTime } from 'd3-scale';
import { getWeek, startOfWeek } from 'date-fns';

import { TimelineGroups } from '../../../../api/gqlEnums';
import { EXPAND } from '../../../../constants';
import { getUtcPrimitives, setAllDayRange } from '../../../../utilities/dates';

import {
  DivSelection,
  GroupByType,
  ItemDueState,
  SVGGSelection,
  SVGSVGSelection,
  type TimelineData,
  TimelineExpandedMap,
  TimelineItemData,
  TimelineItemGroup,
  TimelineItemLegend,
  type TimelineOptions,
  TimelineSettings,
  TimelineType,
} from './timeline.types';

export const DAY = 1;

export const DAY_MILLISECONDS = 8.64e7;
export const WEEK_MS = 7 * DAY_MILLISECONDS;
export const TWO_WEEKS_MS = 2 * WEEK_MS;
export const MONTH_MS = DAY_MILLISECONDS * 30;
export const QUARTER_MS = 3 * MONTH_MS;
export const YEAR_MS = 365 * DAY_MILLISECONDS;
export const TWO_YEARS_MS = 2 * YEAR_MS;
export const CHART_GROUP = 'chart-group';
export const AXIS_HEIGHT = 20;
export const FONT_STYLE = '12px larsseit';
export const FONT_STYLE_DETAILS = '14px larsseit';
export const ROW_HEIGHT = 18;
export const NARROW_WIDTH = 1200;
export const X_TICKS_COUNT = 10;
export const X_TICKS_COUNT_NARROW = 6;
export const X_AXIS_TOP = 0;
export const X_AXIS_TOP_NO_TIMELINE = 36;
export const EVENT_SIZE = 8;
export const EVENT_SIZE_HALF = EVENT_SIZE / 2;
export const BRUSH_ROW_MAX_HEIGHT = 10;
export const BRUSH_ROW_MIN_HEIGHT = 1.6;
export const BRUSH_MILESTONE_MAX_RATIO = 0.7;
export const BRUSH_MILESTONE_MIN_RATIO = 0.5;
export const BRUSH_HEIGHT = 24;
export const BRUSH_INNER_GAP = 4;
export const BRUSH_INNER_HEIGHT = BRUSH_HEIGHT - BRUSH_INNER_GAP * 2;
export const BRUSH_EVENT_TOP = 4;
export const ZOOM_HEIGHT = BRUSH_HEIGHT;
export const BRUSH_HANDLE_CLASS = 'handle-custom';
export const ZOOM_OUT_PERCENTAGES = [95, 80];
export const ITEMS_HEIGHT = 120;
export const ITEMS_HEIGHT_DETAILS = 200;
export const TIMELINE_MIN_HEIGHT = 120;

export const FormatTime = d3.timeFormat('%m/%d/%Y');

export function normalizeGroupName(group: string): string {
  return (group || 'none').toLocaleLowerCase().replaceAll(' ', '-');
}

export function getGroupContainerId(group: string): string {
  return `group-${normalizeGroupName(group)}-container`;
}

export function getGroupSvgId(group: string): string {
  return `group-${normalizeGroupName(group)}-svg`;
}

export const getTimelineChart = (chart: DivSelection): DivSelection =>
  chart
    .select('#timeline-groups')
    .select('#group-chart-group-container')
    .select('#group-chart-group-svg');

export const getItemsChart = (chart: DivSelection): DivSelection =>
  chart.select('#item-container').select('#items-svg');

export const getChartMaxHeight = (groupHeight: number, options: TimelineOptions) =>
  Math.max(groupHeight, options.dataExpanded.length * ROW_HEIGHT);

export const isInterval = (d: TimelineData) =>
  Boolean(d.type === TimelineType.PHASE && d.start !== d.end);

export const isMilestone = (d: TimelineData) =>
  Boolean(d.type === TimelineType.MILESTONE || d.type === TimelineType.ACTIVE_MILESTONE);

export const isEvent = (d: TimelineData) => Boolean(d.type === TimelineType.EVENT);

export const isInstantInterval = (d: TimelineData, options: TimelineOptions) =>
  Boolean(d.type === TimelineType.PHASE && !d.end && d.start >= (options.today || 0));

export const isEndDateAlert = (d: TimelineData) => Boolean(d.type === TimelineType.PHASE && !d.end);

export const isExpandable = (d: TimelineData) => Boolean(d.children && d.children.length);

export const getHeightParams = (options: TimelineOptions) => {
  const groupHeight = options.height.timelineHeight - AXIS_HEIGHT;
  return { groupHeight };
};

export const getWidthParams = (options: TimelineOptions) => {
  const { width } = options;
  const ticksCount = width < NARROW_WIDTH ? X_TICKS_COUNT_NARROW : X_TICKS_COUNT;
  return { ticksCount };
};

export const getDomainByPercentage = (d: TimelineData, options: TimelineOptions, percents = 80) => {
  const { start, end, type } = d;
  if (
    type === TimelineType.EVENT ||
    type === TimelineType.MILESTONE ||
    type === TimelineType.ACTIVE_MILESTONE ||
    (!end && isInstantInterval(d, options))
  ) {
    // Apply about a week around the event data point
    const startMilli = new Date(start).getTime() - DAY_MILLISECONDS * 4;
    const endMilli = new Date(start).getTime() + DAY_MILLISECONDS * 4;
    const bigStart = new Date(startMilli);
    const bigEnd = new Date(endMilli);
    return [bigStart, bigEnd];
  }
  const startMilli = new Date(start).getTime();
  const endMilli = new Date(end || options.today || 0).getTime();
  const delta = endMilli - startMilli;
  const bigDelta = (delta / percents) * 100;
  const additions = (bigDelta - delta) / 2;
  const bigStart = new Date(startMilli - additions);
  const bigEnd = new Date(endMilli + additions);
  return [bigStart, bigEnd];
};

export const getExpandedData = (
  data: TimelineData[],
  expandedMap: TimelineExpandedMap
): TimelineData[] => {
  const expandedData = [];
  for (let index = 0; index < data.length; index += 1) {
    const element = data[index];
    expandedData.push(element);
    const { children, id } = element;
    const isExpanded = !!expandedMap[id];
    if (children?.length && isExpanded) {
      expandedData.push(...getExpandedData(children, expandedMap));
    }
  }
  return expandedData;
};

export const getFlatData = (data: TimelineData[]): TimelineData[] => {
  const zoomData = [];
  for (let index = 0; index < data.length; index += 1) {
    const element = data[index];
    zoomData.push(element);
    const { children } = element;
    if (children?.length) {
      zoomData.push(...getFlatData(children));
    }
  }
  return zoomData;
};

export const updateHorizontalLine = (
  axisSvg: SVGSVGSelection | SVGGSelection,
  width: number,
  top: number
) => {
  // Add axis line
  axisSvg
    .append('line')
    .attr('class', 'x-axis stroke-chart-axis')
    .attr('shape-rendering', 'geometricPrecision')
    .attr('x1', 16)
    .attr('x2', width - 16)
    .attr('y1', top)
    .attr('y2', top);
};

export const updateVerticalLine = (
  axisSvg: SVGSVGSelection | SVGGSelection,
  height: number,
  left: number
) => {
  // Add axis line
  axisSvg
    .append('line')
    .attr('class', 'x-axis stroke-chart-axis stroke')
    .attr('shape-rendering', 'geometricPrecision')
    .attr('x1', left)
    .attr('x2', left)
    .attr('y1', 0)
    .attr('y2', height);
};

export const extendByPercentage = (
  range: [Date, Date] | [undefined, undefined],
  percents = ZOOM_OUT_PERCENTAGES
) => {
  let [start, end] = range;
  if (!start || !end) return range;
  if (start === end) {
    start = new Date(start.setDate(start.getDate() - 5 * DAY));
    end = new Date(end.setDate(end.getDate() + 10 * DAY));
  }
  const startMilli = new Date(start).getTime();
  const endMilli = new Date(end).getTime();
  const delta = endMilli - startMilli;
  const bigDelta = [(delta / percents[0]) * 100, (delta / percents[1]) * 100];
  const additions = [(bigDelta[0] - delta) / 2, (bigDelta[1] - delta) / 2];
  const bigStart = new Date(startMilli - additions[0]);
  const bigEnd = new Date(endMilli + additions[1]);
  return [bigStart, bigEnd];
};

export const getRange = (data: TimelineData[], items?: TimelineItemData[]) => {
  const set1 = data.map((d) => new Date(d.start));
  const set2 = data.map((d) => new Date(d.end || d.start));
  const set3 = (items ?? []).map((d) => new Date(d.dueDate));
  const ext = [...set1, ...set2, ...set3].filter((date) => date);
  const dateRange = extent(ext);
  return dateRange;
};

export const getRangeToday = (
  data: TimelineData[],
  items?: TimelineItemData[],
  today?: string | Date | undefined
) => {
  const dataRange = getRange(data, items);
  const todayDate = new Date(today || data[0]?.start || 0);
  const ext = [...dataRange, todayDate].filter((date) => date) as Date[];
  const dateRange = extent(ext);
  return dateRange;
};

export const getRangeExtended = (
  data: TimelineData[],
  items?: TimelineItemData[],
  today?: string | Date | undefined
) => {
  const dateRange = today ? getRangeToday(data, items, today) : getRange(data, items);
  const dateRangeExtended = extendByPercentage(dateRange);
  return dateRangeExtended;
};

/**
 * Calculates the [min, max] date range in ISO format.
 * The Range is extended by extra ZOOM_OUT_PERCENTAGES for user convenience.
 *
 * @param data timeline data set for range calculation or single [td] value
 * @param today optional date to include in range calculation
 *
 */
export const getRangeExtendedStr = (
  data: TimelineData[],
  items?: TimelineItemData[],
  today?: string | Date | undefined
) => {
  const [minExt, maxExt] = getRangeExtended(data, items, today);
  if (!minExt || !maxExt) return ['0', '0'];
  const newMin = minExt.toISOString();
  const newMax = maxExt.toISOString();
  return [newMin, newMax];
};

export const createXScale = (options: TimelineOptions): ScaleTime<number, number> => {
  const { data, items, width, today, margin } = options;
  const rangeMargin = margin.left ? 16 : 0;
  const rangeOuter = getOuterRange(options);
  const dateRangeExtended = rangeOuter ?? getRangeExtended(data, items, today);
  return scaleTime()
    .domain(dateRangeExtended as Iterable<Date>)
    .range([rangeMargin, width - rangeMargin]);
};

export const getOuterRange = ({ range }: TimelineOptions) =>
  (range?.[0] ?? '0') !== '0'
    ? [new Date(range?.[0] ?? '0'), new Date(range?.[1] ?? '0')]
    : undefined;

/**
 * Make sure that domain range is never smaller than defined value
 *
 * @param rawDomain Date[]
 * @returns fixed domain or initial domain value - Date[]
 */
export const fixDomain = (rawDomain: Date[]) => {
  const rawStart = rawDomain[0].getTime();
  const rawEnd = rawDomain[1].getTime();
  if (rawEnd - rawStart < TWO_WEEKS_MS) {
    const average = Math.floor((rawEnd + rawStart) / 2);
    const start = average - WEEK_MS;
    const end = average + WEEK_MS;
    return [new Date(start), new Date(end)];
  }
  return rawDomain;
};

export const getItemsGroupBy = ([start, end]: Date[]): GroupByType => {
  const deltaMs = end.getTime() - start.getTime();
  let groupBy = GroupByType.Month;
  if (deltaMs < QUARTER_MS) {
    groupBy = GroupByType.Day;
  } else if (deltaMs < TWO_YEARS_MS) {
    groupBy = GroupByType.Week;
  }
  return groupBy;
};

export const createGroup = (
  start: string,
  end: string,
  itemData: TimelineItemData
): TimelineItemGroup => ({
  counter: { 0: 0, 1: 0, 2: 0 },
  data: [itemData],
  start,
  end,
});

export const updateDueStateCount = (group: TimelineItemGroup, { dueState }: TimelineItemData) => {
  const { counter } = group;
  counter[dueState] += 1;
};

export const byMonthKey = (date: string): string => `${date.split('-')[0]}-${date.split('-')[1]}`;
export const byWeekKey = (date: string): string =>
  `${date.split('-')[0]}-${getWeek(new Date(date))}`;
export const byDayKey = (date: string): string => date.split('T')[0];

export const byMonthLimits = (date: Date) => {
  const [year, month] = getUtcPrimitives(date);
  const startDate = new Date(year, month, 1);
  const endDate = new Date(year, month + 1, 0);
  return [startDate, endDate];
};
export const byWeekLimits = (date: Date) => {
  const startDate = startOfWeek(date);
  const [year, month, day] = getUtcPrimitives(startDate);
  const endDate = new Date(year, month, day + 6);
  return [startDate, endDate];
};
export const byDayLimits = (date: Date) => {
  const startDate = new Date(date);
  const endDate = new Date(date);
  return [startDate, endDate];
};

export const keyFunc = {
  [GroupByType.Month]: byMonthKey,
  [GroupByType.Week]: byWeekKey,
  [GroupByType.Day]: byDayKey,
};

export const limitsFunc = {
  [GroupByType.Month]: byMonthLimits,
  [GroupByType.Week]: byWeekLimits,
  [GroupByType.Day]: byDayLimits,
};

export const getItemsGroups = (
  items: TimelineItemData[],
  type: GroupByType
): TimelineItemGroup[] => {
  const groups = items.reduce((groups: { [key in string]: TimelineItemGroup }, item) => {
    const { dueDate } = item;
    const groupKey = keyFunc[type](dueDate);
    if (groups[groupKey]) {
      groups[groupKey].data.push(item);
    } else {
      const date = new Date(item.dueDate);
      const [startDate, endDate] = setAllDayRange(limitsFunc[type](date));
      const start = startDate.toISOString();
      const end = endDate.toISOString();
      // eslint-disable-next-line no-param-reassign
      groups[groupKey] = createGroup(start, end, item);
    }
    updateDueStateCount(groups[groupKey], item);
    return groups;
  }, {});

  const result = Object.keys(groups).map((k) => groups[k]);
  result.sort((a, b) => new Date(a.start).getTime() - new Date(b.start).getTime());
  return result;
};

export const getItemsLegend = (groups: TimelineItemGroup[]): TimelineItemLegend => {
  const group = groups.reduce(
    (groupAcc: TimelineItemLegend, group) => {
      const { counter } = groupAcc;
      counter[ItemDueState.Decided] += group.counter[ItemDueState.Decided];
      counter[ItemDueState.PastDue] += group.counter[ItemDueState.PastDue];
      counter[ItemDueState.Upcoming] += group.counter[ItemDueState.Upcoming];
      return groupAcc;
    },
    { counter: { 0: 0, 1: 0, 2: 0 } }
  );
  return group;
};

export const isSettingsTimeline = (settings: TimelineSettings) =>
  settings[EXPAND].includes(TimelineGroups.TIMELINE);

export const isSettingsItems = (settings: TimelineSettings) =>
  settings[EXPAND].includes(TimelineGroups.ITEMS);

export const setReactiveMaxBarItems = (groups: TimelineItemGroup[]) => {
  const max = Math.max(...groups.map((g) => g.data.length));
  return max;
};
