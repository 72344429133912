import { compareCosts } from '../../../../utilities/sorting';
import { useLocalStorageParams } from '../../../../utilities/urlState';
import { getScenarioName } from '../../common/ScenariosUtils';
import useSendScenariosAnalytics, {
  ScenariosAnalyticsEvents,
} from '../../hooks/useSendScenariosAnalytics';
import { getCountText } from '../../hooks/Utils';
import { ScenarioSandbox } from '../../types';
import { ScenarioSortKey } from '../ScenarioTiles';

import useLoadScenariosSummaryByMilestoneIDQuery from './useLoadScenariosSummaryByMilestoneIDQuery';

const useLibraryScenarios = (sandbox: ScenarioSandbox) => {
  // Analytics
  const sendScenariosAnalytics = useSendScenariosAnalytics();
  const { milestoneID } = sandbox;

  // State
  const [settings, setSettings] = useLocalStorageParams(
    { sortKey: ScenarioSortKey.CREATION_TIME, searchText: '' },
    `Scenarios-Library-Scenarios-`
  );
  const { sortKey, searchText } = settings;
  const setSortKey = (sortKey: ScenarioSortKey) => {
    setSettings({ ...settings, sortKey });
    sendScenariosAnalytics(ScenariosAnalyticsEvents.SORT_LIBRARY_SCENARIOS, {
      sortKey,
      milestoneID,
    });
  };

  const setSearchText = (searchText: string) => {
    setSettings({ ...settings, searchText });
    sendScenariosAnalytics(ScenariosAnalyticsEvents.SEARCH_LIBRARY_SCENARIOS, {
      searchText,
      milestoneID,
    });
  };

  // Data
  const allScenarios =
    useLoadScenariosSummaryByMilestoneIDQuery(sandbox.milestoneID).data
      ?.loadScenariosSummaryByMilestoneID ?? [];

  const sandboxScenarioIDs = sandbox.scenarios.map((s) => s.scenarioID);

  // filtering and sorting
  const scenarios = allScenarios
    .filter((s) => getScenarioName(s).toLowerCase().includes(searchText.toLowerCase()))
    .sort((a, b) => {
      switch (sortKey) {
        case ScenarioSortKey.COST_IMPACT:
          return compareCosts(a.costImpact.report?.range, b.costImpact.report?.range);
        case ScenarioSortKey.NAME:
          return a.name.localeCompare(b.name);
        default:
          return 0;
      }
    });

  const countString = getCountText(allScenarios.length, scenarios.length);

  return {
    countString,
    sandboxScenarioIDs,
    scenarios,
    searchText,
    setSearchText,
    setSortKey,
    sortKey,
  };
};

export default useLibraryScenarios;
