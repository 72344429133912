import { ComponentProps, useCallback } from 'react';

import { getProjectIdFromUrl } from '../../../utilities/url';
import NavigationTabs from '../../Navigation/NavigationTabs';
import { ScrollContainer } from '../../scales';
import useAddScenarioToSandboxMutation from '../hooks/useAddScenarioToSandboxMutation';
import useDeleteScenarioMutation from '../hooks/useDeleteScenarioMutation';
import { useGetMilestoneNameFromID } from '../ScenarioUtils';
import { ScenarioSandbox } from '../types';

import useLibraryItems from './hooks/useLibraryItems';
import useLibraryScenarios from './hooks/useLibraryScenarios';
import ItemTiles from './ItemTiles';
import ScenarioTiles from './ScenarioTiles';
import SidebarCollapseButton from './SidebarCollapseButton';

export type LibrarySettings = {
  view: View;
  collapsed: boolean;
};

type Props = {
  onAddToAllScenarios: (itemID: UUID) => void;
  onCreateScenario: ComponentProps<typeof ScenarioTiles>['onCreateScenario'];
  onAddToScenario: (itemID: UUID, scenarioID: UUID) => void;
  sandbox: ScenarioSandbox;
  settings: LibrarySettings;
  setSettings: (settings: LibrarySettings) => void;
};

enum View {
  ITEMS = 'Items',
  SCENARIOS = 'Scenarios',
}

const LibraryOpen = (props: Props) => {
  // Content Hooks
  const ic = useLibraryItems(props.sandbox);
  const sc = useLibraryScenarios(props.sandbox);
  const projectID = getProjectIdFromUrl();
  const { milestoneName } = useGetMilestoneNameFromID(projectID, props.sandbox.milestoneID);

  // Mutations
  const [deleteScenario] = useDeleteScenarioMutation();
  const [addScenarioToSandbox] = useAddScenarioToSandboxMutation({
    milestoneID: props.sandbox.milestoneID,
  });

  // Functions
  const onAddScenario = useCallback(
    (scenarioID: UUID) => {
      addScenarioToSandbox({ scenarioID });
    },
    [addScenarioToSandbox]
  );

  const onDeleteScenario = useCallback(
    (scenarioID: UUID) => deleteScenario({ scenarioID }),
    [deleteScenario]
  );

  return (
    // The parent div is the overall sidebar. We want it to be stickied to the left-hand side of the screen
    // and have the Scenario cards scroll horizontally underneath it.
    <div className="sticky left-0 flex w-80 shrink-0 flex-col bg-background-primary">
      {/* This direct child is also stickied, but to the top of this Library sidebar. We want the header and
      nav tabs to remain visible even as the list of item/scenario tiles is scrolled through. */}
      <div className="sticky top-0 bg-background-primary">
        <div className="flex items-center gap-1 px-4 py-2 type-heading3">
          <SidebarCollapseButton
            isClosed={props.settings.collapsed}
            onClick={() =>
              props.setSettings({ ...props.settings, collapsed: !props.settings.collapsed })
            }
          />
          Library
        </div>
        <div className="flex items-end border-b pr-1">
          <NavigationTabs
            options={[
              {
                key: View.ITEMS,
                label: `Items${ic.countString}`,
                title: View.ITEMS,
              },
              {
                key: View.SCENARIOS,
                label: `Scenarios${sc.countString}`,
                title: View.SCENARIOS,
              },
            ]}
            setView={(view: View) => {
              const newSettings = { ...props.settings, view };
              props.setSettings(newSettings);
            }}
            view={props.settings.view}
          />
        </div>
      </div>
      <ScrollContainer direction="vertical">
        <div className="flex flex-col gap-4 px-2 py-4">
          {props.settings.view === View.ITEMS ? (
            <ItemTiles
              items={ic.filteredItems}
              onAddToAllScenarios={props.onAddToAllScenarios}
              onAddToScenario={props.onAddToScenario}
              scenarios={props.sandbox.scenarios}
              searchText={ic.searchText}
              setSearchText={ic.setSearchText}
              setSortKey={ic.setSortKey}
              sortKey={ic.sortKey}
            />
          ) : (
            <ScenarioTiles
              milestoneName={milestoneName}
              onAddScenario={onAddScenario}
              onCreateScenario={props.onCreateScenario}
              onDeleteScenario={onDeleteScenario}
              sandboxScenarioIDs={sc.sandboxScenarioIDs}
              scenarios={sc.scenarios}
              searchText={sc.searchText}
              setSearchText={sc.setSearchText}
              setSortKey={sc.setSortKey}
              sortKey={sc.sortKey}
            />
          )}
        </div>
      </ScrollContainer>
    </div>
  );
};

export default LibraryOpen;
