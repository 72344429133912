import { FC, useCallback, useEffect, useState } from 'react';

import { TimelineEvent, timelineEvent } from '../../analytics/analyticsEventProperties';
import { TimelineActivityType } from '../../api/gqlEnumsBe';
import { CREATE } from '../../constants';
import { PermissionResource } from '../../generated/graphql';
import useSendAnalytics from '../../hooks/useSendAnalytics';
import { getTodayAtUtcNoon } from '../../utilities/dates';
import usePermissions from '../../utilities/permissions/usePermissions';
import { getProjectIdFromUrl } from '../../utilities/url';
import { usePersistentStates } from '../../utilities/urlState';
import { TimelineData } from '../shared-widgets/TimelineChart/timeline/timeline.types';
import TimelineChart from '../shared-widgets/TimelineChart/TimelineChart';
import { getRangeExtendedStr } from '../shared-widgets/TimelineChart/TimelineChartUtils';
import useMemoWrapper from '../useMemoWrapper';

import { useTimelineQuery } from './hooks/TimelineHook';
import { useItemsConditionally } from './hooks/useItemsConditionally';
import { Activity, ActivityNode } from './Timeline.types';
import TimelineActivityModal from './TimelineActivityModal';
import TimelineHeader from './TimelineHeader';
import TimelineTable from './TimelineTable';
import {
  ACTIVITIES_DEFAULT,
  computeExpandable,
  computeExpandableMap,
  filterChildren,
  getExpandSettings,
  getTimelineData,
  getTimelineUrlConfig,
  tableNodeToTimelineData,
} from './TimelineUtils';

const DATE_STR_INIT = '0';

const Timeline: FC = () => {
  const projectID = getProjectIdFromUrl();

  const [settings, setSettings] = usePersistentStates(...getTimelineUrlConfig(projectID));

  // Activities
  const [activityTypes, setActivityTypes] = useState<TimelineActivityType[]>(ACTIVITIES_DEFAULT);
  const {
    data: recentData,
    loading: timelineLoading,
    previousData,
  } = useTimelineQuery({ projectID, types: activityTypes });
  const data = recentData || previousData;
  const activities = data?.timeline?.activities ?? [];
  const expandableActivities = useMemoWrapper(computeExpandable, activities);

  const sendAnalytics = useSendAnalytics();

  const { items, loading: itemsLoading, withoutDueDateCount } = useItemsConditionally(projectID);

  const today = getTodayAtUtcNoon();

  const loading = timelineLoading || itemsLoading;

  // Min and Max date range values for zoom domain and pickers
  const [range, setRange] = useState<string[]>([DATE_STR_INIT, DATE_STR_INIT]);
  const [startStr, setStartStr] = useState<string>(DATE_STR_INIT);
  const [endStr, setEndStr] = useState<string>(DATE_STR_INIT);
  const rangeDate = [new Date(range[0]), new Date(range[1])];
  const startDate = new Date(startStr);
  const endDate = new Date(endStr);

  // Expand states
  const { collapse = [], expand = [] } = settings ?? {};
  const expandedMap = useMemoWrapper(computeExpandableMap, collapse, expand);

  const tree = useMemoWrapper(buildTree, activities);

  const [timelineActivityModal, setTimelineActivityModal] = useState({
    activity: undefined,
    open: false,
    type: CREATE,
  });

  // Permissions
  const { canEdit, canAdd, canDelete } = usePermissions();

  const canAddActivities = canAdd(PermissionResource.TIMELINE);
  const canEditActivities = canEdit(PermissionResource.TIMELINE);
  const canDeleteActivities = canDelete(PermissionResource.TIMELINE);

  const timelineData: TimelineData[] = useMemoWrapper(getTimelineData, tree);

  const setStartEnd = ([start, end]: string[]) => {
    setStartStr(start);
    setEndStr(end);
  };

  useEffect(() => {
    if (loading) return;
    const domain = getRangeExtendedStr(timelineData, items, today);
    setStartEnd(domain);
    setRange(domain);
  }, [data, items, loading, timelineData, today]);

  const onZoom = (domain: string[]) => setStartEnd(domain);

  const setZoomInNode = (a: ActivityNode) => {
    const td = tableNodeToTimelineData(a);
    const domain = getRangeExtendedStr([td]);
    setStartEnd(domain);
  };

  const onExpand = useCallback(
    (id: UUID) => {
      expandedMap[id] = !expandedMap[id];
      const [newCollapse, newExpand] = getExpandSettings(expandedMap);
      setSettings({ collapse: newCollapse, expand: newExpand });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO CT-566: Fix this pls :)
    [collapse, expand, expandedMap]
  );

  const onAnalytics = (event: TimelineEvent) => {
    sendAnalytics(timelineEvent(event, { source: 'timeline' }));
  };

  if (!data || startStr === DATE_STR_INIT || itemsLoading) return null;

  const header = (
    <TimelineHeader
      activityTypes={activityTypes}
      canAddActivities={canAddActivities}
      endDate={endDate}
      expandableActivities={expandableActivities}
      rangeDate={rangeDate}
      setActivityTypes={setActivityTypes}
      setEndStr={setEndStr}
      setStartStr={setStartStr}
      setTimelineActivityModal={setTimelineActivityModal}
      startDate={startDate}
    />
  );

  const chart = (
    <TimelineChart
      activities={activities}
      data={timelineData}
      endDate={endStr}
      expandedMap={expandedMap}
      items={items}
      maxHeight={400}
      onAnalytics={onAnalytics}
      onExpand={onExpand}
      onZoom={onZoom}
      settings={{ ...settings, projectID }}
      startDate={startStr}
      today={today}
      withoutDueDateCount={withoutDueDateCount}
      zoomLineCompressed
    />
  );

  const table = (
    <TimelineTable
      activities={tree}
      canEdit={canEditActivities && canDeleteActivities}
      expandedMap={expandedMap}
      onExpand={onExpand}
      setModal={setTimelineActivityModal}
      setZoomIn={setZoomInNode}
    />
  );

  return (
    <div className="bg-background-primary">
      {activities && (
        <TimelineActivityModal
          activities={activities}
          activity={timelineActivityModal.activity}
          onClose={() =>
            setTimelineActivityModal({ activity: undefined, open: false, type: CREATE })
          }
          open={timelineActivityModal.open}
          type={timelineActivityModal.type as 'add' | 'edit' | 'delete'}
          types={activityTypes}
        />
      )}
      {header}
      {chart}
      {table}
    </div>
  );
};

export const buildTree = (activities: Activity[]) => {
  const indices = new Map<UUID, number>();
  const parents = new Map<UUID, number>();

  activities.forEach((a, i) => {
    indices.set(a.id, i);
    a.children.forEach((id) => parents.set(id, i));
  });

  const roots: number[] = [];
  activities.forEach((a, i) => {
    if (!parents.has(a.id)) roots.push(i);
  });

  const mapChildren =
    (depth: number, parent: UUID) =>
    (childID: UUID): ActivityNode => {
      const activity = activities[indices.get(childID)!];
      return {
        ...activity,
        children: filterChildren(activity.children, activities)
          .map(mapChildren(depth + 1, parent))
          .filter((a) => a !== null),
        depth,
        parent,
      };
    };

  const tree = roots.map((i) => {
    const activity = activities[i];
    const depth = 0;
    return {
      ...activity,
      children: filterChildren(activity.children, activities)
        .map(mapChildren(depth + 1, activity.id))
        .filter((a) => a !== null),
      depth,
    };
  });

  return tree;
};

export default Timeline;
