import { FC } from 'react';
import { Link } from 'react-router-dom';

import { CircularProgress, Typography } from '@material-ui/core';
import { LocationOnOutlined, Restore } from '@material-ui/icons';

import { JoinRoutes } from '../../../api/gqlEnums';
import { GetRecentlyCreatedProjectsQuery, ImageDimension } from '../../../generated/graphql';
import { withStyles } from '../../../theme/komodo-mui-theme';
import { parseDate } from '../../../utilities/dates';
import { generateSharedPath } from '../../../utilities/routes/links';
import { noPermissionTooltip } from '../../../utilities/string';
import { containsValidUUID } from '../../../utilities/uuid';
import useThumbnailQuery from '../../assets/hooks/useThumbnailQuery';
import NormalTooltip from '../../NormalTooltip/NormalTooltip';
import { ProjectMap } from '../ExecutiveDashboardUtils';

import ExecutiveDashboardMostRecentlyCreatedStyles from './ExecutiveDashboardMostRecentlyCreatedStyles';

type ExecutiveDashboardMostRecentlyCreatedRowProps = {
  classes: Classes<typeof ExecutiveDashboardMostRecentlyCreatedStyles>;
  project?: GetRecentlyCreatedProjectsQuery['recentlyCreatedProjects'][number] | null;
  projectMap?: ProjectMap;
};

const ExecutiveDashboardMostRecentlyCreatedRow: FC<
  ExecutiveDashboardMostRecentlyCreatedRowProps
> = ({ classes, project, projectMap }) => {
  const {
    name = '-',
    location,
    createdBy,
    createdAt,
    status,
    thumbnail: thumbnailID = '',
    projectId = '',
  } = project ?? {};

  const { data, loading } = useThumbnailQuery(thumbnailID, ImageDimension._92);
  const { asset } = data || {};
  const { thumbnailURL } =
    asset || (!containsValidUUID(thumbnailID) && { thumbnailURL: thumbnailID }) || {};

  const thumbnailImage = thumbnailURL ? (
    <img alt="thumb" className={classes.thumbnail} src={thumbnailURL} />
  ) : (
    <div className={classes.thumbnail}>
      {loading && <CircularProgress className={classes.loadingSmall} size={35} />}
    </div>
  );

  const { hasAccess = false, milestoneName = '' } = projectMap?.get(projectId) || {};
  const path = hasAccess ? generateSharedPath(JoinRoutes.PROJECT, { projectId }) : '';

  const projectName = hasAccess ? (
    <Link className={`${classes.nameText} ${classes.paddingRight} ${classes.linkText}`} to={path}>
      {name}
    </Link>
  ) : (
    <span
      className={`${classes.nameText} ${classes.paddingRight} ${project ? classes.noPermissionProject : classes.rowDisabled}`}
    >
      {name}
    </span>
  );

  const tooltip = hasAccess ? (
    <>
      <div>{name}</div>
      <div>{milestoneName}</div>
      <div>{location}</div>
    </>
  ) : (
    noPermissionTooltip(name)
  );

  return (
    <tr className={classes.row}>
      {project ? (
        <td className={classes.thumbnail}>{thumbnailImage}</td>
      ) : (
        <>
          <td className={classes.thumbnail}>
            <div className={classes.thumbnailPlaceholder} />
          </td>
        </>
      )}
      <td className={classes.cell}>
        <Typography className={classes.projectType}>
          <NormalTooltip title={project ? tooltip : ''}>{projectName}</NormalTooltip>
          {project && (
            <>
              <span className={classes.paddingRight}>·</span>
              <span className={classes.status}>{status}</span>
            </>
          )}
        </Typography>

        <Typography className={classes.projectText} variant="caption">
          {location ? (
            <div className={classes.iconText}>
              <LocationOnOutlined className={classes.icon} />
              <span className={classes.paddingRight}>{location}</span>
            </div>
          ) : (
            '\xA0'
          )}
          {project && (
            <div className={classes.iconText}>
              <Restore className={classes.icon} />
              Created by {createdBy} on {parseDate(createdAt)}
            </div>
          )}
        </Typography>
      </td>
    </tr>
  );
};

export default withStyles(ExecutiveDashboardMostRecentlyCreatedStyles)(
  ExecutiveDashboardMostRecentlyCreatedRow
);
