import { isGmpInsightsMilestone } from '../../../ExecutiveDashboardV2/utils';

import { InsightsMilestone } from './types';

export const getMilestoneIcon = (m: InsightsMilestone) => {
  if (isGmpInsightsMilestone(m)) {
    if (m.active) {
      return (
        <svg
          fill="none"
          height="13"
          viewBox="0 0 13 13"
          width="13"
          xmlns="http://www.w3.org/2000/svg"
          y={-1.75}
        >
          <rect className="fill-theme-bauhaus-berlin" height="12" rx="2" width="13" y="0" />
          <svg
            fill="none"
            height="9"
            viewBox="0 0 8 9"
            width="8"
            x={2.75}
            xmlns="http://www.w3.org/2000/svg"
            y={2}
          >
            <path
              className="fill-theme-bauhaus-laszlo"
              d="M4.66797 1.02536L4.16797 0.0253601H0.167969V8.52536H1.16797V5.02536H3.66797L4.16797 6.02536H7.66797V1.02536H4.66797ZM6.66797 5.02536H4.66797L4.16797 4.02536H1.16797V1.02536H3.66797L4.16797 2.02536H6.66797V5.02536Z"
            />
          </svg>
        </svg>
      );
    }
    return (
      <svg
        fill="none"
        height="13"
        viewBox="0 0 13 13"
        width="13"
        xmlns="http://www.w3.org/2000/svg"
        y={-1.75}
      >
        <rect className="fill-transparent" height="12" rx="2" width="13" y="0" />
        <svg fill="none" height="9" viewBox="0 0 9 9" width="9" x={1.5} y={2}>
          <path
            className="fill-theme-bauhaus-laszlo"
            d="M5.33203 1.0256L4.83203 0.0256042H0.832031V8.5256H1.83203V5.0256H4.33203L4.83203 6.0256H8.33203V1.0256H5.33203ZM7.33203 5.0256H5.33203L4.83203 4.0256H1.83203V1.0256H4.33203L4.83203 2.0256H7.33203V5.0256Z"
          />
        </svg>
      </svg>
    );
  }
  if (m.active) {
    return (
      <svg
        fill="none"
        height="13"
        viewBox="0 0 13 13"
        width="13"
        xmlns="http://www.w3.org/2000/svg"
        y={-1.75}
      >
        <rect className="fill-entities-milestone" height="12" rx="2" width="13" y="0" />
        <svg fill="none" height="9" viewBox="0 0 8 9" width="8" x={2.25} y={2}>
          <path
            className="fill-background-primary"
            d="M5.53203 1.08423L5.33203 0.0842285H0.832031V8.58423H1.83203V5.08423H4.63203L4.83203 6.08423H8.33203V1.08423H5.53203Z"
          />
        </svg>
      </svg>
    );
  }
  return (
    <svg
      fill="none"
      height="13"
      viewBox="0 0 13 13"
      width="13"
      xmlns="http://www.w3.org/2000/svg"
      y={-1.75}
    >
      <rect className="fill-transparent" height="12" rx="2" width="13" y="0" />
      <svg fill="none" height="9" viewBox="0 0 8 9" width="8" x={2.5} y={2}>
        <path
          className="fill-entities-milestone"
          d="M4.66797 1.02536L4.16797 0.0253601H0.167969V8.52536H1.16797V5.02536H3.66797L4.16797 6.02536H7.66797V1.02536H4.66797ZM6.66797 5.02536H4.66797L4.16797 4.02536H1.16797V1.02536H3.66797L4.16797 2.02536H6.66797V5.02536Z"
        />
      </svg>
    </svg>
  );
};
