import ProjectSelection from '../../../../ProjectsList/ProjectSelection/ProjectSelection';
import { Callout } from '../../../../scales';
import { CreateEstimateParameters } from '../CreateEstimateDialogFlowUtils';

type Props = {
  params: CreateEstimateParameters;
};

export default function ProjectSelectionContent(props: Props) {
  // State
  const {
    params: {
      onSetProjectID,
      variables: { projectID },
    },
  } = props;

  const selectedProjectIDs = projectID ? [projectID] : [];
  const onSelectionChanged = (ids: UUID[]) => {
    onSetProjectID(ids[0]);
  };

  const callout = (
    <Callout isFullWidth>
      <div className="mb-2 type-heading3">Choose Project</div>
      <div className="type-body1">
        Add an estimate from the comparison average to the selected project.
      </div>
    </Callout>
  );

  return (
    <ProjectSelection
      callout={callout}
      onChange={onSelectionChanged}
      selectedProjectIDs={selectedProjectIDs}
      selectionMode="single"
    />
  );
}
