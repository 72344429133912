import { useCompanyRoutesData } from '../CompanyTabUtils';

import CompanyTabInsightsAlerts from './CompanyTabInsightsAlerts';

export default function CompanyTabInsightsAlertsData() {
  const { companyID } = useCompanyRoutesData();

  if (!companyID) return null;

  return <CompanyTabInsightsAlerts companyID={companyID} />;
}
