import { itemSidebarScenariosOpenVar } from '../../../api/apollo/reactiveVars';
import { isPrintKeys } from '../../Print/PrintUtils';

export enum SandboxView {
  SANDBOX_VIEW_WORK = 'Workspace',
  SANDBOX_VIEW_PRESENT = 'Presentation',
}

export const isWorkspaceView = (view: SandboxView) => view === SandboxView.SANDBOX_VIEW_WORK;

// move an element in array from one index to another
export const moveElementInArray = <T = unknown>(arr: T[], fromIndex: number, toIndex: number) => {
  const newArr: T[] = [...arr];
  const element = arr[fromIndex];
  newArr.splice(fromIndex, 1);
  newArr.splice(toIndex, 0, element);
  return newArr;
};

export const sandboxKeyHandler = (id: string, printFn: () => void) => (e: KeyboardEvent) => {
  if (isPrintKeys(e)) {
    e.preventDefault();
    e.stopPropagation();
    printFn();
  }
  if (e.key === 'Escape' && id) {
    itemSidebarScenariosOpenVar({ id: '', color: '' });
  }
};
