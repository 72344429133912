import { FC } from 'react';

import { Typography } from '@material-ui/core';

import { GENERAL_INFO, OPTIONS } from '../../../constants';
import { ResourceType, Visibility } from '../../../generated/graphql';
import { withStyles } from '../../../theme/komodo-mui-theme';
import { formatCost } from '../../../utilities/currency';
import { getDraftItemIDs, getSharedUsersMap } from '../../../utilities/items';
import ItemsButtonAddOption from '../../Items/ItemsButtonAddOption/ItemsButtonAddOption';
import ItemsQuickLink from '../../Items/ItemsQuickLink/ItemsQuickLink';
import { useGetSharedResources } from '../../ItemsList/ItemsSharing/hooks/useGetShareResourceHook';
import useMemoWrapper from '../../useMemoWrapper';

import styles from './ItemSidebarOptionsStyles';

type ItemSidebarOptionsProps = {
  shouldDisplayCosts: boolean;
  classes: Classes<typeof styles>;
  isProjectItemSidebar?: boolean;
  openSidebar: (id?: string) => void;
  options: Option[];
  setExpanded: (name: string[]) => void;
  setOpenDialogAddOption: (b: boolean) => void;
};

const ItemSidebarOptions: FC<ItemSidebarOptionsProps> = ({
  shouldDisplayCosts,
  classes,
  isProjectItemSidebar,
  openSidebar,
  options,
  setOpenDialogAddOption,
  setExpanded,
}) => {
  const cost = (cost: CostScalar) => (
    <Typography className={classes.cardHeaderTypography}>{formatCost(cost.value)}</Typography>
  );

  const handleClick = (id?: UUID) => {
    openSidebar(id);
    setExpanded([GENERAL_INFO]);
  };

  const draftOptionIDs = useMemoWrapper(getDraftItemIDs, options);
  const sharedResourcesResult = useGetSharedResources(draftOptionIDs, ResourceType.ITEM);
  const sharedUsersMap = useMemoWrapper(getSharedUsersMap, options, sharedResourcesResult.data);

  return (
    <>
      {isProjectItemSidebar && (
        <div className={classes.optionsHeader}>
          <div className={classes.floatRight}>
            <ItemsButtonAddOption setOpenDialogAddOption={setOpenDialogAddOption} type="primary" />
          </div>
        </div>
      )}
      <div
        className={`${classes.backgroundColor} ${
          (isProjectItemSidebar || options.length !== 0) && classes.containerPadding
        } ${classes.optionsContainerGrowth}`}
      >
        {options.map((option: Option) => (
          <div key={`${option.id}${option.name}`} className={classes.itemSidebarOptionsLine}>
            <div style={{ width: '60%' }}>
              <ItemsQuickLink
                charNumberLimit={200}
                ellipses
                handleClick={handleClick}
                hasDefaultWidth={false}
                isDraftItem={option.visibility === Visibility.PRIVATE_DRAFT}
                isPrint={false}
                item={{
                  id: option.id,
                  name: option.name,
                  number: option.number,
                  status: option.status,
                }}
                sharedUsers={sharedUsersMap.get(option.id)}
                showNumberSign
                textVariant="bodySidebar"
                variant={OPTIONS}
              />
            </div>
            {option.cost && shouldDisplayCosts ? cost(option.cost as CostScalar) : ''}
          </div>
        ))}
      </div>
    </>
  );
};
export default withStyles(styles)(ItemSidebarOptions);
