import { createStyles } from '@material-ui/core';

export default () =>
  createStyles({
    filterContainer: {
      display: 'contents',
    },
    filterContainerPadding: {
      paddingTop: 16,
    },
    paddedText: {
      flex: 1,
      paddingRight: 16,
      textAlign: 'right',
    },
    showInput: { flex: 1 },
  });
