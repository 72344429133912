import { useState } from 'react';

import { useSetOrgLevel } from '../../../Organizations/hooks/hooks';
import { TextInput } from '../../../scales';
import useMemoWrapper from '../../../useMemoWrapper';
import { getUsedLevels } from '../utils';

export default function OrgLevelInput(props: {
  organizationID: UUID;
  levelName: string;
  levelNumber: number;
  levels: string[];
}) {
  const levels = useMemoWrapper(getUsedLevels, props.levels, props.levelName);
  const [setOrgLevel] = useSetOrgLevel();
  const [error, setError] = useState('');
  const [name, setName] = useState(props.levelName);

  return (
    <TextInput
      aria-label="Level Name"
      errorMessage={error}
      onBlur={() => {
        const fixed = name.trim();
        if (fixed === props.levelName) return;
        if (fixed === '') {
          setName(props.levelName);
        }
        if (error) {
          setError('');
          setName(props.levelName);
        } else {
          setOrgLevel({
            variables: {
              input: {
                organizationID: props.organizationID,
                name: fixed,
                number: props.levelNumber,
              },
            },
          });
        }
      }}
      onChange={(name) => {
        const isUnique = !levels.includes(name);
        if (isUnique) {
          setError('');
        } else {
          setError('Level name already exists');
        }
        if (name === '') {
          setError('Required');
        }
        setName(name);
      }}
      value={name}
    />
  );
}
