import { GetProjectNameQuery, GetProjectNameQueryVariables } from '../../../generated/graphql';
import { useQuery } from '../../../hooks/useMountAwareQuery';

import { getProjectNameQuery } from './queries';

function useGetProjectNameQuery(projectID: UUID) {
  return useQuery<GetProjectNameQuery, GetProjectNameQueryVariables>(getProjectNameQuery, {
    variables: { projectID },
    skip: !projectID,
  });
}

export default useGetProjectNameQuery;
