import { createStyles } from '@material-ui/core';

import theme, { KomodoTheme } from '../../../theme/komodo-mui-theme';

export const dropZoneStyle = {
  border: `2px dashed ${theme.palette.joinGrey400}`,
  borderRadius: 8,
};

export default (theme: KomodoTheme) =>
  createStyles({
    containerGrow: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
    },
    draggingInputs: {
      border: `2px solid ${theme.palette.borderBlue}`,
    },
    hidden: {
      opacity: 0,
      position: 'absolute',
      zIndex: -1,
    },
    uploadAreaFileExplorer: {
      justifyContent: 'center !important',
    },
    uploadAreaPadded: {
      display: 'flex',
      flexGrow: 1,
      justifyContent: 'space-between',
      alignItems: 'center',
      flexDirection: 'column',
      backgroundColor: theme.palette.backgroundWhite,
    },
    uploadFileIcon: {
      verticalAlign: 'text-bottom',
      height: 20,
      marginRight: 3,
      width: 16,
    },
    uploadText: {
      ...theme.typography.body2,
      fontWeight: 500,
      fontSize: 14,
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      marginBottom: 5,
    },
    fileExplorerBackground: {
      backgroundColor: 'unset !important',
    },
  });
