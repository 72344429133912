export type PieChartSize = {
  diameter: number;
  insideDiameter?: number;
};

export type PieChartData = { name: string; share: number };
export type CenterLabel = { label: string; sublabel?: string };
export type PieChartOptions = {
  includeGradient?: boolean; // if you want to include a gradient then there must be an inside diameter on the chart
  spaceBetweenWedges?: boolean;
};

export const DEFAULT_EMPTY_VALUE = 'DEFAULT_EMPTY_VALUE';

const polarToCartesian = (
  centerX: number,
  centerY: number,
  radius: number,
  angleInRadians: number
) => {
  return {
    x: centerX + radius * Math.cos(angleInRadians),
    y: centerY + radius * Math.sin(angleInRadians),
  };
};

export const describeArc = (
  x: number,
  y: number,
  radius: number,
  startAngle: number,
  endAngle: number,
  padAngle: number // add a gap between pie slices
) => {
  const start = polarToCartesian(x, y, radius, endAngle - padAngle);
  const end = polarToCartesian(x, y, radius, startAngle + padAngle);

  let cx = x;
  let cy = y;
  if (padAngle) {
    const centerAngle = (endAngle + startAngle) / 2;
    const offset = radius * padAngle * 2;
    cx = x + Math.cos(centerAngle) * offset;
    cy = y + Math.sin(centerAngle) * offset;
  }

  const largeArcFlag = Math.abs(endAngle - startAngle) <= Math.PI ? '0' : '1';

  const d = [
    // move to the start of the arc
    'M',
    start.x,
    start.y,

    // draw an arc around the circle
    'A',
    radius,
    radius,
    0,
    largeArcFlag,
    0,
    end.x,
    end.y,

    // line back to center
    'L',
    cx,
    cy,
  ].join(' ');

  return d;
};
