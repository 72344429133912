import { FC } from 'react';

import { UploadPlatform } from '../../../api/gqlEnums';
import { withStyles } from '../../../theme/komodo-mui-theme';
import BorderlessJoinSelect from '../../Select/BorderlessJoinSelect/BorderlessJoinSelect';
import { SelectEntry } from '../../Select/JoinSelect/JoinSelect';

import styles from './EstimateToolSelectStyles';

type EstimateToolSelectProps = {
  entries: SelectEntry[];
  selected: UploadPlatform;
  setSelectedEstimatePlatform: (selected: UploadPlatform) => void;
};

const EstimateToolSelect: FC<EstimateToolSelectProps> = ({
  entries,
  selected,
  setSelectedEstimatePlatform,
}) => {
  return (
    <BorderlessJoinSelect
      cySelect="button-selectPlatform"
      entries={entries}
      onChange={setSelectedEstimatePlatform}
      value={selected}
      valuePlaceholder={UploadPlatform.EMPTY_STATE}
    />
  );
};

export default withStyles(styles)(EstimateToolSelect);
