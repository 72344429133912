import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';

const LEFT_CONTENT_WIDTH = 80;
const RIGHT_CONTENT_WIDTH = 100;

export default (theme: KomodoTheme) =>
  createStyles({
    overflow: {
      display: 'block',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      width: '100%',
      minWidth: LEFT_CONTENT_WIDTH + RIGHT_CONTENT_WIDTH,
    },
    tooltipLine: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    leftContent: {
      width: LEFT_CONTENT_WIDTH,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    rightContent: {
      width: RIGHT_CONTENT_WIDTH,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    uncategorizedText: {
      color: theme.palette.disabledGrey,
    },
  });
