import { RefObject, useEffect } from 'react';
import { useRafState } from 'react-use';

interface State {
  x: number;
  y: number;
  direction: 'up' | 'down';
}

let scrollY = 0;

const useJoinScroll = (ref: RefObject<HTMLElement>, wait = 250): State => {
  const [state, setState] = useRafState<State>({
    x: 0,
    y: 0,
    direction: 'down',
  });

  useEffect(() => {
    const refCurrent = ref.current;
    const applyScroll = () => {
      if (refCurrent) {
        const y = refCurrent.scrollTop;
        const direction = y >= scrollY ? 'down' : 'up';
        scrollY = y;
        setState({
          x: refCurrent.scrollLeft,
          y,
          direction,
        });
      }
    };
    // Throttling implementation, with 'wait' milliseconds as timeout
    let cached: Event | null = null;
    const handler = (event: Event) => {
      if (wait === 0) {
        applyScroll();
        return;
      }
      if (!cached) {
        setTimeout(() => {
          applyScroll();
          cached = null;
        }, wait);
      }
      cached = event;
    };

    if (refCurrent) {
      refCurrent.addEventListener('scroll', handler, {
        capture: false,
        passive: true,
      });
    }

    return () => {
      if (refCurrent) {
        refCurrent.removeEventListener('scroll', handler);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref]);

  return state;
};

export default useJoinScroll;
