import { useMemo } from 'react';

import { PermissionResource } from '../../../../generated/graphql';
import { useCompanyUserQuery } from '../../../../hooks/useCompanyUserQuery';
import usePermissions from '../../../../utilities/permissions/usePermissions';

export default () => {
  const hasCompany = !!useCompanyUserQuery().data?.companyUser?.company;

  const { canView } = usePermissions();
  const hasAllCompanySearchAccess = canView(PermissionResource.ALL_COMPANY_SEARCH_ACCESS);
  const hasAllProjectsAccessPermission = canView(PermissionResource.ALL_PROJECTS_ACCESS);

  return useMemo(
    () => ({
      hasAllProjectsAccess: hasCompany,
      hasItemsAccess: hasCompany,
      hasAllItemsAccess: hasCompany && hasAllCompanySearchAccess,
      hasAllResultsLinkAccess: hasCompany && hasAllProjectsAccessPermission,
    }),
    [hasAllCompanySearchAccess, hasAllProjectsAccessPermission, hasCompany]
  );
};
