import { useCallback, useEffect, useState } from 'react';

const DELAY = 2000;

export const useHideOnMouseStop = () => {
  const [hide, setHide] = useState(false);

  let timer: NodeJS.Timeout;
  const onMouseMove = useCallback(() => {
    clearTimeout(timer);
    if (hide) setHide(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO CT-566: Fix this pls :)
    timer = setTimeout(() => setHide(true), DELAY);
  }, [hide, setHide]);

  useEffect(() => {
    window.addEventListener('mousemove', onMouseMove);
    return () => {
      window.removeEventListener('mousemove', onMouseMove);
    };
  }, [onMouseMove]);

  return hide;
};
