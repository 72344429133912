import {
  costTypeFields,
  detailedItemLikeItemFields,
  detailedOptionFields,
  gql,
} from '../../../../api/graphqlFragments';

const detailedPrintItemFields = gql`
  fragment detailedPrintItemFields on Item {
    ...detailedItemLikeItemFields
    options {
      ...detailedOptionFields
      unfilteredCost {
        ...costTypeFields
      }
    }
    optionSubtotals {
      status
      adds
      deducts
    }
    unfilteredCost {
      ...costTypeFields
    }
  }
  ${detailedItemLikeItemFields}
  ${detailedOptionFields}
  ${costTypeFields}
`;

export const listDetailedItemsQuery = gql`
  query listDetailedItems(
    $projectId: UUID!
    $milestoneId: UUID
    $costMode: CostMode!
    $viewFilter: ViewFilter!
  ) {
    items(
      query: {
        project: $projectId
        milestone: $milestoneId
        costMode: $costMode
        viewFilter: $viewFilter
      }
    ) {
      ...detailedPrintItemFields
    }
  }
  ${detailedPrintItemFields}
`;
