import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MarketingBanner, useBannerVisibility } from '../../../scales';
import { useCompanyUserQuery } from '../../../../hooks/useCompanyUserQuery';
import ProgramDialog from '../../../ProgramReporting/ProgramDialog/ProgramDialog';
import { generateSharedPath } from '../../../../utilities/routes/links';
import { RouteKeys } from '../../../../routes/paths';
import useHasModuleEntitlement from '../../../../hooks/useHasModuleEntitlement';
import { PROJECT_CREATION } from '../../../../moduleEntitlementFlagsList';
import { NS_PROGRAM_REPORTING } from '../../../../features';
import { useHasFeature } from '../../../../hooks/useHasFeature';
import { useProgramEligibleProjectsQuery } from '../../../ProgramReporting/ProgramDialog/hooks/useProgramEligibleProjectsQuery';

const PROGRAM_REPORTING_KEY = 'PROGRAM_REPORTING_KEY';

export default function ProgramBanner() {
  const hasProgramReportingFeature = useHasFeature(NS_PROGRAM_REPORTING);
  const hasProjectCreationEntitlement = useHasModuleEntitlement(PROJECT_CREATION);
  const potentialProjects = useProgramEligibleProjectsQuery()?.data?.programEligibleProjects;
  const isAdmin = useCompanyUserQuery().data?.companyUser?.role?.name === 'Admin';

  const [isDialogOpen, setDisplayProgramDialog] = useState(false);
  const navigate = useNavigate();

  const { isHidden, onDismiss, onRemindMeLater } = useBannerVisibility({
    localStorageKey: PROGRAM_REPORTING_KEY,
  });

  const isBannerVisible =
    isAdmin &&
    !isHidden &&
    hasProgramReportingFeature &&
    hasProjectCreationEntitlement &&
    (potentialProjects?.length ?? 0) >= 2;

  if (!isBannerVisible && !isDialogOpen) return null;

  return (
    <>
      {isBannerVisible && (
        <div className="flex p-4">
          <MarketingBanner
            buttonLabel="Group Projects into a Program"
            description="Group multi-phase or multi-building projects into a Program which creates a combined Dashboard to help you and your project team see the big picture"
            header="Related Projects can now be grouped into a Program"
            image={
              <img
                alt="Creatre Program"
                className="h-auto max-w-sm"
                src="/img/Program_Reporting/Create-Program-transparent.gif"
              />
            }
            onCTA={() => setDisplayProgramDialog(true)}
            onDismiss={onDismiss}
            onRemindMeLater={onRemindMeLater}
          />
        </div>
      )}
      {isDialogOpen && (
        <ProgramDialog
          onClose={() => setDisplayProgramDialog(false)}
          onCompleted={(programID) => {
            onDismiss();
            navigate(generateSharedPath(RouteKeys.PROGRAM_DASHBOARD, { programID }));
          }}
        />
      )}
    </>
  );
}
