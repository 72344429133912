import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';

export default (theme: KomodoTheme) =>
  createStyles({
    columnName: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      padding: '4px 0px',
    },
    iconWhite: {
      color: theme.palette.backgroundWhite,
    },
    root: {
      color: theme.palette.joinPrimary,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    rootError: {
      color: theme.palette.red,
      border: `1px solid ${theme.palette.red}`,
    },
    error: {
      width: 16,
      height: 16,
      margin: 4,
    },
    newBadgeContainer: {
      display: 'inline-block',
      marginLeft: 2,
    },
    crop: {
      flex: '1 1 auto',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    right: {
      textAlign: 'right',
      marginRight: 4,
    },
    info: {
      color: theme.palette.joinGrey400,
      margin: 4,
      width: 20,
      height: 20,
    },
  });
