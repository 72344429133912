import { FC, useRef } from 'react';
import * as React from 'react';

import { DELETE_CELL, INHERITED_MARKUP_CHECKBOX_CELL } from '../../constants';

import useUpdate from './hooks/useUpdate';
import { renderCell } from './renderers';
import { CellData, Column, GridController } from './types';
import { getCypressTag } from './utilities/cell';

type MouseHandler = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;

interface CellProps {
  column: Column;
  columnIndex: number;
  data: CellData;
  grid: GridController;
  height: number;
  isSourceCell: boolean;
  isTotalCell: boolean;
  onClick: () => void;
  onDoubleClick: () => void;
  onMouseDown: MouseHandler;
  onMouseEnter: MouseHandler;
  row: number;
}

const JoinCell: FC<CellProps> = ({
  column,
  columnIndex,
  data,
  grid,
  height,
  isSourceCell,
  isTotalCell,
  onClick,
  onDoubleClick,
  onMouseDown,
  onMouseEnter,
  row,
}) => {
  const update = useUpdate();
  // eslint-disable-next-line no-param-reassign
  data.data.update = update;
  const domRef = useRef<HTMLDivElement>(null);
  // eslint-disable-next-line no-param-reassign
  data.dom = domRef;
  const { selected, rangeHead, borders, value, error } = data.data;
  const { linesReadOnly } = grid;

  // Inherited markup checkbox: do not do focus as in editable cell
  const checkbox = column.type === INHERITED_MARKUP_CHECKBOX_CELL || column.type === DELETE_CELL;

  let cellClass = '';
  if (selected && !checkbox) {
    cellClass = `join-grid-selected ${rangeHead ? 'range-head' : ''}`;
  }

  // Borders
  let cellBorders = '';
  if (selected && !checkbox) {
    cellBorders = `${borders.left ? 'join-grid-range-left' : ''} \
      ${borders.right ? 'join-grid-range-right' : ''} \
      ${borders.top ? 'join-grid-range-top' : ''} \
      ${borders.bottom ? 'join-grid-range-bottom' : ''}`;
  }
  const endCellBorder = isTotalCell ? 'column-end-cell-border' : '';
  const startCellBorder = isSourceCell ? 'column-start-cell-border' : '';

  // Styles, together
  const className = `join-grid-cell ${cellClass} ${cellBorders} ${startCellBorder} ${endCellBorder}`;
  const dataCy = getCypressTag(grid.linePrefix, row, column.name);

  return (
    <div
      ref={domRef}
      className={className}
      data-cy={dataCy}
      data-grid-selected={selected} // used for cypress copy/paste testing
      onClick={onClick}
      onDoubleClick={onDoubleClick}
      onKeyDown={() => {}}
      onMouseDown={onMouseDown}
      onMouseEnter={onMouseEnter}
      role="textbox"
      style={{ height }}
      tabIndex={0}
    >
      {renderCell(
        grid,
        column,
        columnIndex,
        row,
        value,
        error,
        onDoubleClick,
        linesReadOnly,
        isTotalCell,
        dataCy
      )}
    </div>
  );
};
export default JoinCell;
