import { cloneDeep } from 'lodash';
import mime from 'mime';

import { INIT_UPLOAD, UPLOAD_COMPLETED, UPLOAD_STARTED } from '../../actions/actionTypes';
import { EstimateType } from '../../api/gqlEnums';
import { IMAGE, MODEL, PDF } from '../../constants';
import { Maybe } from '../../generated/graphql';
import theme from '../../theme/komodo-mui-theme';
import { parseDate } from '../../utilities/dates';

import { FOOTER_PADDING, FOOTER_TEXT_PADDING } from './AssetsViewer/AssetsViewerFooterStyles';
import { HORIZONTAL_MARGIN, VERTICAL_MARGIN } from './AssetsViewer/AssetsViewerStyles';

type teamGUID = {
  designTeamGUID: string;
  generalContractorGUID: string;
  ownerGUID: string;
};

// ASSET UPLOAD FNS

export const getActiveUploads = (
  uploads: UploadingAsset[] = [],
  assetActionType: AssetActionType | null
) => {
  if (assetActionType === null) return [];
  let assetUpload: UploadingAsset | undefined;
  switch (assetActionType.type) {
    case UPLOAD_STARTED:
      if (assetActionType.data?.tempID && assetActionType.data?.file)
        uploads.push({
          id: assetActionType.data?.tempID,
          itemID: assetActionType.data?.itemID,
          milestoneID: assetActionType.data?.milestoneID,
          name: assetActionType.data?.file?.name,
          size: assetActionType.data?.file?.size,
        });
      return cloneDeep(uploads);
    case UPLOAD_COMPLETED:
      assetUpload = assetActionType.data?.tempID
        ? getAssetByID(uploads, assetActionType.data?.tempID)
        : undefined;
      if (assetUpload) {
        if (
          assetActionType.data?.itemID ||
          assetActionType.data?.milestoneID ||
          assetActionType.data?.project?.id
        ) {
          // This was uploaded for a particular destination, so we
          // don't want to keep it around in the dialog.
          return uploads.filter((upload) => upload.id !== assetUpload?.id);
        }
        return cloneDeep(uploads);
      }
      return uploads;
    default:
      return uploads;
  }
};

export const initUploadWithEstimateType = (
  actionData: AssetActionDataWithRequiredFile,
  estimateType: EstimateType
) => ({
  data: { ...actionData, estimateType },
  type: INIT_UPLOAD,
});

export const startUpload = (action: AssetActionData): AssetActionType => ({
  data: { ...action },
  type: UPLOAD_STARTED,
});

export const completeUpload = (action: AssetActionData): AssetActionType => ({
  data: { ...action },
  type: UPLOAD_COMPLETED,
});

// UTILITIES

export const getTeamGUID = (team?: Maybe<Team>): teamGUID => {
  if (!team) {
    return { designTeamGUID: '', generalContractorGUID: '', ownerGUID: '' };
  }

  const { designTeam, generalContractor, owner } = team || {};
  const designTeamGUID = designTeam?.picture ?? '';
  const generalContractorGUID = generalContractor?.picture ?? '';
  const ownerGUID = owner?.picture ?? '';

  return { designTeamGUID, generalContractorGUID, ownerGUID };
};

const getAssetByID = (uploads: UploadingAsset[], assetID: UUID) =>
  uploads.find((upload) => upload.id === assetID);

export const isImage = (name: string) => {
  const type = mime.getType(name) || '';
  if (!type.startsWith('image')) return false;
  const isVendorImageMime = type.includes('vnd.'); // vendor-specific "image-types" are generally web-unrenderable here, like DWG, DXF, and PSD. Kinda sloppy Mime-types.
  if (isVendorImageMime) return false;
  return true;
};

export const assetIsImageType = (type?: Maybe<string>) => type === IMAGE;

export const isPdf = (name: string) => {
  return (name || '').toLowerCase().endsWith('.pdf');
};
export const assetIsPdfType = (type?: Maybe<string>) => type === PDF;

export const isModel = (name: string) => {
  return (name || '').includes('.rvt');
};
export const assetIsModelType = (type?: Maybe<string>) => type === MODEL;

export const calcMaxViewerHeight = (windowHeight: number) =>
  windowHeight -
  2 * VERTICAL_MARGIN -
  (2 * FOOTER_PADDING + theme.iconSizes.medium + FOOTER_TEXT_PADDING);

export const calcMaxViewerWidth = (windowWidth: number) => windowWidth - 2 * HORIZONTAL_MARGIN;

export const getTruncatedAssetText = (asset: EntityAttachment, bookmark?: Bookmark) => {
  let nameText = bookmark ? bookmark.name : asset.name;
  if (nameText && nameText.length > 62) {
    nameText = `${nameText.slice(0, 62)}...`;
  }
  return nameText;
};

export const isRemoteAssetType = (a: EntityAttachment) => a?.__typename === 'RemoteAsset';

export const getNonNullEntityAttachments = (
  entityAttachments: (EntityAttachment | null)[] | null | undefined
) => {
  const nonNullEntityAttachments: EntityAttachment[] = [];
  if (entityAttachments) {
    entityAttachments.forEach((attach) => {
      if (attach) nonNullEntityAttachments.push(attach);
    });
  }
  return nonNullEntityAttachments;
};

// Temp asset id for uploading
export const getAssetTempID = (name: string, size: number) => `${name}/${size}`;

export const getTotalAttachmentCount = (assetCount: number, remoteAssetCount: number) =>
  assetCount + remoteAssetCount;

export const getUploadedByText = (name?: string) => `Uploaded by ${name || 'you'}`;

export const getUploadedText = (
  uploadedAsset: Pick<Asset, 'id' | 'name' | 'lastUpdated' | 'type'> & {
    uploadedBy?: Pick<User, 'id' | 'name' | 'email'> | null;
  }
) =>
  `${getUploadedByText(uploadedAsset.uploadedBy?.name)}, last updated on ${parseDate(
    uploadedAsset.lastUpdated
  )}`;
