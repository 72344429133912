import type { BrushBehavior, D3BrushEvent } from 'd3-brush';
import { type ScaleTime } from 'd3-scale';

import {
  BrushGroupSelection,
  BrushSelection,
  type TimelineData,
  TimelineEvent,
  type TimelineOptions,
} from './timeline.types';
import { TODAY_GREEN } from './timelineColor';
import { BRUSH_HANDLE_CLASS, fixDomain } from './utils';

export const handleBrush =
  (
    brushSvg: BrushSelection,
    brush: BrushBehavior<unknown>,
    updateOuter: (domain: Date[]) => void,
    brushXScale: ScaleTime<number, number>,
    timelineXScaleFixed: ScaleTime<number, number>,
    options: TimelineOptions
  ) =>
  (event: D3BrushEvent<TimelineData>) => {
    const { selection, sourceEvent, type } = event;
    const brushGroup: BrushGroupSelection = brushSvg.select('#brush-group');
    // update brush's handle position and visibility
    if (selection === null) {
      brushGroup
        .selectAll(`.${BRUSH_HANDLE_CLASS}`)
        // hide
        .attr('display', 'none');
    } else {
      const handleTop = options.zoomLineCompressed ? 12 : 0;
      brushGroup
        .selectAll(`.${BRUSH_HANDLE_CLASS}`)
        .attr('transform', (_d, i) => {
          const pos = [selection[i], handleTop];
          return `translate(${pos})`;
        })
        // display
        .attr('display', null);
    }

    if (!sourceEvent) {
      return;
    }

    let domain: Date[] = brushXScale.domain();

    if (selection === null) {
      const range = [16, timelineXScaleFixed.range()[1]];
      brushGroup.call(brush).call(brush.move, range);
    } else {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
      domain = selection.map(brushXScale.invert as any);
    }
    domain = fixDomain(domain);

    if (type === 'end') {
      options.onZoom(domain);
      if (selection !== null) options.onAnalytics(TimelineEvent.TIMELINE_SUBCHART_ZOOM);
    }

    updateOuter(domain);
  };

export const addBrushHandle = (brush: BrushGroupSelection, path: string[]) =>
  brush
    .selectAll(`.${BRUSH_HANDLE_CLASS}`)
    .data([
      { type: 'w', cy: 'zoomMin' },
      { type: 'e', cy: 'zoomMax' },
    ])
    .enter()
    .append('path')
    .attr('class', `${BRUSH_HANDLE_CLASS} stroke-button-secondary-outline fill-button-secondary`)
    .attr('cursor', `${'ew'}-resize`)
    .attr('d', (d) => path[+/[se]/.test(d.type)])
    .attr('data-cy', (d) => d.cy);

export const addToday = (
  today: Date | string,
  brushSvg: BrushSelection,
  timelineXScaleFixed: ScaleTime<number, number>,
  y1: number,
  y2: number
) => {
  const todayLine = brushSvg.select('#line-today');
  if (!todayLine.empty()) return;
  brushSvg
    .data([new Date(today)], () => new Date(today).getTime())
    .append('line')
    .attr('id', 'line-today')
    .attr('class', 'line brush-today')
    .attr('y1', y1)
    .attr('shape-rendering', 'geometricPrecision')
    .attr('stroke-width', '1.5px')
    .attr('x1', (d: Date) => timelineXScaleFixed(d))
    .attr('x2', (d: Date) => timelineXScaleFixed(d))
    .attr('y2', y2)
    .attr('stroke', TODAY_GREEN);
};
