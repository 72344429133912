import { GetProjectPropsQuery, GetProjectPropsQueryVariables } from '../generated/graphql';

import { getProjectPropsQuery } from './queries';
import { useQuery } from './useMountAwareQuery';
import { MountPolicy } from './usePolicyOnFirstMount';

const useProjectPropsQuery = (projectID: UUID | null | undefined, mountPolicy?: MountPolicy) => {
  const queryResult = useQuery<GetProjectPropsQuery, GetProjectPropsQueryVariables>(
    getProjectPropsQuery,
    {
      variables: { id: projectID! }, // Will be defined when not skipping
      skip: !projectID,
    },
    mountPolicy
  );

  return {
    ...queryResult,
    data: {
      project: queryResult.data?.project ?? undefined,
    },
  };
};

export default useProjectPropsQuery;
