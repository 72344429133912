import { ListDraftCategoriesQuery, ListDraftCategoriesQueryVariables } from '../generated/graphql';

import { listDraftCategoriesQuery } from './queries';
import { useQuery } from './useMountAwareQuery';

export const useDraftCategorizationsQuery = (estimateID: UUID | undefined) =>
  useQuery<ListDraftCategoriesQuery, ListDraftCategoriesQueryVariables>(listDraftCategoriesQuery, {
    variables: { estimateID: estimateID || '' },
    skip: !estimateID,
  });
