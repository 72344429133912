import { createStyles } from '@material-ui/core/styles';

import { MAX_COL_WIDTH } from '../../../constants';
import { KomodoTheme } from '../../../theme/komodo-mui-theme';

const styles = (theme: KomodoTheme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      paddingLeft: 25,
      paddingRight: 10,
      '&:hover': {
        backgroundColor: theme.palette.hoverBackground,
      },
      minHeight: 36,
      [theme.breakpoints.up('xl')]: {
        maxWidth: MAX_COL_WIDTH,
      },
    },
    description: {
      flexGrow: 1,
      paddingTop: 3,
    },
    hidden: {
      color: '#C4C4C4',
    },
    date: {
      width: 150,
      marginLeft: 20,
      marginRight: 10,
      textAlign: 'left',
    },
    selected: {
      backgroundColor: theme.palette.hoverBackground,
    },
    visible: {
      width: 24,
      height: 24,
    },
    visibleIcon: {
      width: 36,
      height: 36,
      margin: 0,
      color: 'inherit',
      '@media print': {
        display: 'none',
      },
    },
  });

export default styles;
