import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';

const styles = (theme: KomodoTheme) =>
  createStyles({
    cardHeader: { padding: '8px 24px', background: theme.palette.backgroundWhite },
    containerLeft: {
      alignItems: 'center',
      display: 'flex',
    },
    disabledCursor: {
      cursor: 'auto',
    },
    estimateContent: {
      minHeight: 36,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
    },
    expandableContainer: {
      borderTop: theme.border.line,
      minHeight: 58,
      alignItems: 'center',
      cursor: 'pointer',
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      '&:last-of-type': {
        borderBottom: theme.border.line,
      },
      width: '100%',
    },
    expander: {
      color: theme.palette.joinPrimary,
      marginLeft: -8,
      marginRight: 8,
      '@media print': {
        display: 'none',
      },
    },
    expanderPlaceholder: {
      width: 24,
    },
    header: {
      ...theme.typography.webParagraph,
    },
    iconContainer: {
      paddingLeft: 4,
    },
    light: {
      fontWeight: 300,
    },
    subHeader: {
      paddingTop: 2,
      ...theme.typography.webTableText,
    },
  });

export default styles;
