import { listCategoriesQuery } from '../components/Inputs/InputsSelectReference/hooks/queries';
import { ListCategoriesQuery, ListCategoriesQueryVariables } from '../generated/graphql';

import { QueryHookOptions, useQuery } from './useMountAwareQuery';

const useListCategoriesQuery = (
  categorizationID: UUID | undefined,
  term: string,
  level: number | undefined,
  limit = 1000,
  options: QueryHookOptions<ListCategoriesQuery, ListCategoriesQueryVariables> = {}
) =>
  useQuery<ListCategoriesQuery, ListCategoriesQueryVariables>(listCategoriesQuery, {
    variables: { categorizationID, term, limit, level },
    skip: !categorizationID,
    ...options,
  });

export default useListCategoriesQuery;
