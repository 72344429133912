import { ComponentProps, useMemo } from 'react';

import { UserStatus } from '../../api/gqlEnumsBe';
import useCollaboratorsQuery from '../../hooks/useCollaboratorsQuery';

import AssigneeSelect from './AssigneeSelect';

type Props = {
  includeUnassigned?: ComponentProps<typeof AssigneeSelect>['includeUnassigned'];
  isClearable?: ComponentProps<typeof AssigneeSelect>['isClearable'];
  isDisabled?: boolean;
  label?: ComponentProps<typeof AssigneeSelect>['label'];
  onChange: ComponentProps<typeof AssigneeSelect>['onChange'];
  projectID: UUID;
  validUserIDs?: UUID[];
  value: ComponentProps<typeof AssigneeSelect>['value'];
};

export default function AssigneeSelectData({
  projectID,
  validUserIDs,
  ...assigneeSelectProps
}: Props) {
  const { data } = useCollaboratorsQuery(projectID);
  const users = useMemo(
    () =>
      data?.collaborators
        .filter(
          (collaborator) =>
            collaborator.user.status !== UserStatus.DEACTIVATED &&
            (!validUserIDs || validUserIDs.includes(collaborator.user.id))
        )
        .map((collaborator) => collaborator.user) ?? [],
    [data?.collaborators, validUserIDs]
  );

  return <AssigneeSelect availableUsers={users} {...assigneeSelectProps} />;
}
