import { LoadUserQuery, LoadUserQueryVariables } from '../generated/graphql';

import { loadUserQuery } from './queries';
import { useQuery } from './useMountAwareQuery';

function useUserQuery(userEmail?: string) {
  return useQuery<LoadUserQuery, LoadUserQueryVariables>(loadUserQuery, {
    variables: { userEmail },
    skip: !userEmail,
  });
}

export default useUserQuery;
