import { FC, useLayoutEffect, useRef, useState } from 'react';

import { setItemScheduleImpactExpanded } from '../../../analytics/analyticsEventProperties';
import { withStyles } from '../../../theme/komodo-mui-theme';
import { getItemIdFromUrl } from '../../../utilities/url';
import { SCHEDULE_IMPACT_DEFAULT } from '../../shared-widgets/ScheduleImpact';
import { useItemQuery } from '../hooks/useItemQuery';

import ScheduleImpactCollapseStyles from './AssetsCollapseStyles';
import ItemDetailsCollapse from './ItemDetailsCollapse';
import ScheduleImpactHeader from './ScheduleImpactWrapper/ScheduleImpactHeader';
import ScheduleImpactWrapperItemData from './ScheduleImpactWrapper/ScheduleImpactWrapperItemData';

type ScheduleImpactCollapseProps = {
  canEdit: boolean;
  canView: boolean;
  classes: Classes<typeof ScheduleImpactCollapseStyles>;
};

const LOCALSTORAGE_SCHEDULE_IMPACT_IS_EXPANDED = 'ScheduleImpactIsExpanded';

const ScheduleImpactCollapse: FC<ScheduleImpactCollapseProps> = ({
  canEdit = false,
  canView = false,
  classes,
}) => {
  const itemID = getItemIdFromUrl();
  const { data: itemData, loading: itemLoading } = useItemQuery(itemID);
  const item = itemData?.item;
  const scheduleImpact = item?.scheduleImpact ?? SCHEDULE_IMPACT_DEFAULT;

  const [localImpact, setLocalImpact] = useState(scheduleImpact);
  const [isExpanded, setIsExpandedState] = useState(
    localStorage.getItem(LOCALSTORAGE_SCHEDULE_IMPACT_IS_EXPANDED) === 'true' ?? false
  );

  const setIsExpanded = (value: boolean) => {
    setIsExpandedState(value);
    localStorage.setItem(LOCALSTORAGE_SCHEDULE_IMPACT_IS_EXPANDED, value.toString());
  };

  const headerContent = <ScheduleImpactHeader scheduleImpact={localImpact} />;
  const scrollRef = useRef<HTMLDivElement>(null);
  useLayoutEffect(() => {
    if (window.location.hash && window.location.hash.split('?')[0].slice(1) === 'schedule-impact') {
      setIsExpanded(true);
      if (scrollRef.current) {
        scrollRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location]);

  if (itemLoading) return null;

  return (
    <div ref={scrollRef} className={classes.card} data-cy="schedule-impact-collapse">
      <ItemDetailsCollapse
        analyticsEvent={setItemScheduleImpactExpanded(isExpanded)}
        canExpand={canView && canEdit}
        headerContent={headerContent}
        isExpanded={isExpanded && canEdit}
        setIsExpanded={setIsExpanded}
      >
        <ScheduleImpactWrapperItemData
          item={item}
          localImpact={localImpact}
          scheduleImpact={scheduleImpact}
          setLocalImpact={setLocalImpact}
        />
      </ItemDetailsCollapse>
    </div>
  );
};

export default withStyles(ScheduleImpactCollapseStyles)(ScheduleImpactCollapse);
