import { useMutation } from '@apollo/client';

import { TimelineActivityType } from '../../../api/gqlEnumsBe';
import { REFETCH_TIMELINE_EVENTS_PHASES } from '../../../api/refetchSets';
import {
  TimelineActivityInput,
  UpdateTimelineActivityMutation,
  UpdateTimelineActivityMutationVariables,
} from '../../../generated/graphql';

import { updateTimelineActivityMutation } from './queries';

export function useUpdateTimelineActivity() {
  const [updateTimelineActivityFn, mutationResult] = useMutation<
    UpdateTimelineActivityMutation,
    UpdateTimelineActivityMutationVariables
  >(updateTimelineActivityMutation);

  const submitFunc = (
    projectID: UUID,
    types: TimelineActivityType[],
    activityID: UUID,
    input: TimelineActivityInput,
    onSuccess?: () => void,
    onFailure?: () => void
  ) => {
    return updateTimelineActivityFn({
      variables: {
        projectID,
        activityID,
        input,
      },
      refetchQueries: REFETCH_TIMELINE_EVENTS_PHASES(projectID, types),
    })
      .then(() => {
        onSuccess?.();
      })
      .catch(() => {
        onFailure?.();
      });
  };

  return [submitFunc, mutationResult] as const;
}
