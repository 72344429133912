import { JoinGridWrapperProps } from '../types';

export const filterControllerProps = (props: JoinGridWrapperProps) => {
  const { estimateID, gridType, sortData, errors, projectID, permissions, width, viewFilter } =
    props;
  return {
    estimateID,
    gridType,
    sortData,
    errors,
    projectID,
    permissions,
    width,
    viewFilter,
  };
};
