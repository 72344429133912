import * as d3 from 'd3';

import { formatCost } from '../../../../utilities/currency';
import SVGWithDimensions from '../../../Charts/ChartsD3/SVGWithDimensions';
import { useChartDimensions } from '../../../Charts/ChartsD3/useChartDimensions';
import CostLabelBlur from '../../../dragon-scales/TimelineCharts/ChartsCostTrendline/CostLabelBlur';
import CostYAxis from '../../../dragon-scales/TimelineCharts/ChartsCostTrendline/CostYAxis';
import ContingenciesFillPattern from '../../../dragon-scales/TimelineCharts/InsightsContingencies/ContingenciesFillPattern';
import { ContingenciesTimeSeries } from '../../../dragon-scales/TimelineCharts/InsightsContingencies/types';
import {
  getAreaDataAllow,
  getAreaDataCont,
  getYDomain,
} from '../../../dragon-scales/TimelineCharts/InsightsContingencies/utils';
import InsightsEmptyChart from '../../../dragon-scales/TimelineCharts/InsightsCost/InsightsEmptyChart';
import { getYDomainPadded } from '../../../dragon-scales/TimelineCharts/InsightsCost/utils';
import TimelineArea from '../../../dragon-scales/TimelineCharts/TimelineArea';
import TimelineBottomTooltip from '../../../dragon-scales/TimelineCharts/TimelineBottomTooltip';
import TimelineCircle from '../../../dragon-scales/TimelineCharts/TimelineCircle';
import TimelineHoverSections from '../../../dragon-scales/TimelineCharts/TimelineHoverSections';
import TimelinePath from '../../../dragon-scales/TimelineCharts/TimelinePath';
import TimelineXAxis from '../../../dragon-scales/TimelineCharts/TimelineXAxis';
import useMemoWrapper from '../../../useMemoWrapper';
import { LEFT_MARGIN } from '../utils';

import ContingenciesTooltipContent from './ContingenciesTooltipContent';
import { NARROW_WIDTH } from '../../Charts/XAxis';

const costFormat = { short: true, showCurrencySymbol: false };

type Props = {
  data: ContingenciesTimeSeries[];
  height: number;
  hoverDate?: Date | undefined;
  hasAllowance: boolean;
  hasContingency: boolean;
  isPrint?: boolean;
  setHoverDate: (date: Date | undefined) => void;
  today: Date;
  totalRange: [Date, Date];
};

export default function InsightsContingencies(props: Props) {
  const {
    data,
    height,
    hoverDate,
    hasAllowance,
    hasContingency,
    isPrint,
    setHoverDate,
    today,
    totalRange,
  } = props;
  const { ref, dimensions } = useChartDimensions({
    height,
    marginTop: 0,
    marginRight: 0,
    marginBottom: 0,
    marginLeft: 0,
  });
  const { width } = dimensions;
  const margin = {
    left: LEFT_MARGIN,
    right: 0,
    bottom: 32,
    top: 8,
  };

  // x domain
  const xMin = totalRange[0];
  const xMax = totalRange[1];
  const xDomain: [Date, Date] = [xMin, xMax];
  const xRange: [number, number] = [margin.left, width - margin.right];
  // create x scale
  const x = d3.scaleTime().domain(xDomain).range(xRange);

  // y domain
  const [yDataMin, yDataMax] = useMemoWrapper(getYDomain, data, hasAllowance, hasContingency);
  // Add vertical padding
  const { yDomain, yMin, yMax } = useMemoWrapper(getYDomainPadded, yDataMin, yDataMax);
  const yRange: [number, number] = [height - margin.bottom, margin.top];
  // create y scale
  const y = d3.scaleLinear().domain(yDomain).range(yRange);

  // Get area data separated
  const pendingContingencies = useMemoWrapper(getAreaDataCont, data);
  const pendingAllowances = useMemoWrapper(getAreaDataAllow, data);
  // Last data index, the first point for horizontal lines after today
  const lastIndex = data.length - 1;

  const todayData = [
    { date: today, value: yMin },
    { date: today, value: yMax },
  ];

  const bounds = {
    right: x(xMax),
    left: x(xMin),
    top: y(yMin),
    bottom: y(yMax),
  };

  // Hover index for tooltips
  const hoverIndex = data.findIndex(({ date }) => date.getTime() === hoverDate?.getTime());
  const hoverCA = data[hoverIndex];

  if (!data.length || !data[0]) {
    return (
      <InsightsEmptyChart height={height} margin={margin} today={today} totalRange={totalRange} />
    );
  }

  return (
    <SVGWithDimensions ref={ref} data-cy="line-chart" dimensions={dimensions}>
      <TimelineXAxis
        range={xRange}
        tickTotal={width < NARROW_WIDTH ? 10 : 18}
        x={x}
        y={y}
        yPosition={yMin}
      />
      <CostYAxis
        range={yRange}
        tickFormat={(v: number) => formatCost(v, costFormat)}
        tickTotal={4}
        x={x}
        xPosition={xMin}
        y={y}
      />
      {/* Hover Line */}
      {hoverDate && (
        <TimelinePath<{ value: number }>
          // current hover date
          data={[
            { date: hoverDate, value: yMin },
            { date: hoverDate, value: yMax },
          ]}
          stroke="stroke-chart-axis"
          strokeWidth={1.5}
          x={x}
          y={y}
        />
      )}
      {hasContingency && (
        <>
          {/* Pending Area Contingencies */}
          <TimelineArea<{ y0: number; y: number }, { x: Date }>
            data={pendingContingencies}
            fill="url(#pattern-contingencies)"
            filterNulls
            hasSteps
            x={x}
            y={y}
          />
          {/* Total number Contingencies */}
          <TimelinePath<{ contingencyRemaining: number }>
            // Before today
            data={data}
            field="contingencyRemaining"
            filterNulls
            hasSteps
            stroke="stroke-entities-contingencies"
            strokeWidth={1.5}
            x={x}
            y={y}
          />
          <TimelinePath<{ value: number }>
            // After today Contingencies
            data={[
              { date: data[lastIndex].date, value: data[lastIndex].contingencyRemaining },
              { date: xMax, value: data[lastIndex].contingencyRemaining },
            ]}
            filterNulls
            stroke="stroke-entities-contingencies-future"
            strokeWidth={1.5}
            x={x}
            y={y}
          />
          {!isPrint && hoverDate && (
            <TimelineCircle<{ value: number }>
              data={{ date: hoverCA.date, value: hoverCA.contingencyRemaining }}
              fill="fill-entities-contingencies"
              x={x}
              y={y}
            />
          )}
        </>
      )}
      {hasAllowance && (
        <>
          {/* Pending Area Allowances */}
          <TimelineArea<{ y0: number; y: number }, { x: Date }>
            data={pendingAllowances}
            fill="url(#pattern-allowances)"
            filterNulls
            hasSteps
            x={x}
            y={y}
          />
          {/* Total number Allowances */}
          <TimelinePath<{ allowanceRemaining: number }>
            // Before today
            data={data}
            field="allowanceRemaining"
            filterNulls
            hasSteps
            stroke="stroke-entities-allowances"
            strokeWidth={1.5}
            x={x}
            y={y}
          />
          <TimelinePath<{ value: number }>
            // After today Allowances
            data={[
              { date: data[lastIndex].date, value: data[lastIndex].allowanceRemaining },
              { date: xMax, value: data[lastIndex].allowanceRemaining },
            ]}
            filterNulls
            stroke="stroke-entities-allowances-future"
            strokeWidth={1.5}
            x={x}
            y={y}
          />
          {!isPrint && hoverDate && (
            <TimelineCircle<{ value: number }>
              data={{ date: hoverCA.date, value: hoverCA.allowanceRemaining }}
              fill="fill-entities-allowances"
              x={x}
              y={y}
            />
          )}
        </>
      )}
      {/* Today */}
      <TimelinePath<{ value: number }>
        data={todayData}
        stroke="stroke-selection-focus-fill"
        strokeWidth={1.5}
        x={x}
        y={y}
      />
      {/* Hover Points */}
      {!isPrint && hoverDate && (
        <TimelineBottomTooltip
          key={`tooltip-${hoverIndex}-${hoverDate}`}
          content={
            <ContingenciesTooltipContent
              data={hoverCA}
              hasAllowance={hasAllowance}
              hasContingency={hasContingency}
            />
          }
          data={{
            value: yMin,
            date: hoverDate,
          }}
          isOpen
          offset={40}
          x={x}
          y={y}
        />
      )}
      <TimelineHoverSections
        bounds={bounds}
        data={data}
        onHoverIndex={(index) =>
          index === -1 ? setHoverDate(undefined) : setHoverDate(data[index]?.date)
        }
        x={x}
      />
      <ContingenciesFillPattern
        fill="fill-entities-contingencies"
        fillBgn="fill-entities-contingencies-drawn"
        id="pattern-contingencies"
      />
      <ContingenciesFillPattern
        fill="fill-entities-allowances"
        fillBgn="fill-entities-allowances-drawn"
        id="pattern-allowances"
      />
      <CostLabelBlur />
    </SVGWithDimensions>
  );
}
