import {
  detailedMilestoneFields,
  gql,
  importEstimateErrorFields,
} from '../../../api/graphqlFragments';

export const importEstimateErrorsQuery = gql`
  query importEstimateErrors($estimateID: UUID!) {
    importEstimateErrors(estimateID: $estimateID) {
      ...importEstimateErrorFields
    }
  }
  ${importEstimateErrorFields}
`;

export const applyMilestoneDraftEstimateMutation = gql`
  mutation applyMilestoneDraftEstimate($projectID: UUID!, $estimateID: UUID!) {
    applyMilestoneDraftEstimate(projectID: $projectID, estimateID: $estimateID) {
      ...detailedMilestoneFields
    }
  }
  ${detailedMilestoneFields}
`;

export const deleteMilestoneDraftEstimateMutation = gql`
  mutation deleteMilestoneDraftEstimate($projectID: UUID!, $estimateID: UUID!) {
    deleteMilestoneDraftEstimate(projectID: $projectID, estimateID: $estimateID)
  }
`;
