import {
  commonCostReportColumnFields,
  commonUserReportCommentFields,
  gql,
} from '../../../api/graphqlFragments';

export const varianceReportsQuery = gql`
  query varianceReports(
    $columnSets: [VarianceColumnSet!]!
    $groupByItems: [GroupByItem!]!
    $costMode: CostMode!
    $projectID: UUID!
    $shouldIncludeOwnerCosts: Boolean
    $viewFilter: ViewFilter!
  ) {
    varianceReports(
      parameters: {
        columnSets: $columnSets
        groupByItems: $groupByItems
        costMode: $costMode
        projectID: $projectID
        shouldIncludeOwnerCosts: $shouldIncludeOwnerCosts
        viewFilter: $viewFilter
      }
    ) {
      milestoneID
      name
      date
      varianceColumns {
        ...commonCostReportColumnFields
      }
    }
  }
  ${commonCostReportColumnFields}
`;

export const loadVarianceReportComments = gql`
  query loadVarianceReportComments(
    $reportID: UUID!
    $userReportCommentViewParameters: UserReportCommentViewParameters!
    $costMode: CostMode
  ) {
    varianceReportComments(
      reportID: $reportID
      userReportCommentViewParameters: $userReportCommentViewParameters
      costMode: $costMode
    ) {
      itemComments {
        ...commonUserReportCommentFields
      }
      itemLineComments {
        ...commonUserReportCommentFields
      }
      subtotalComments {
        ...commonUserReportCommentFields
      }
      markupComments {
        ...commonUserReportCommentFields
      }
    }
  }
  ${commonUserReportCommentFields}
`;

export const createUserReportCommentMutation = gql`
  mutation createUserReportComment(
    $projectID: UUID!
    $userReportID: UUID!
    $reportCommentInput: UserReportCommentInput!
    $costMode: CostMode
  ) {
    createUserReportComment(
      projectID: $projectID
      userReportID: $userReportID
      reportCommentInput: $reportCommentInput
      costMode: $costMode
    ) {
      ...commonUserReportCommentFields
    }
  }
  ${commonUserReportCommentFields}
`;

export const updateUserReportCommentMutation = gql`
  mutation updateUserReportComment(
    $projectID: UUID!
    $commentID: UUID!
    $text: MentionableTextInput!
    $costs: [USCents!]!
    $costMode: CostMode
  ) {
    updateUserReportComment(
      projectID: $projectID
      commentID: $commentID
      text: $text
      costs: $costs
      costMode: $costMode
    ) {
      ...commonUserReportCommentFields
    }
  }
  ${commonUserReportCommentFields}
`;

export const deleteUserReportCommentMutation = gql`
  mutation deleteUserReportComment($projectID: UUID!, $commentID: UUID!) {
    deleteUserReportComment(projectID: $projectID, commentID: $commentID)
  }
`;

export const userReportNotesPerMilestoneIDQuery = gql`
  query userReportNotesPerMilestoneID($projectID: UUID!, $reportID: UUID!) {
    userReportNotesPerMilestoneID(projectID: $projectID, reportID: $reportID) {
      milestoneID
      noteCount
    }
  }
`;
