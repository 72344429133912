import { FC } from 'react';
import usePlacesAutocomplete, {
  Suggestion as PlacesSuggestion,
  getGeocode,
  getLatLng,
} from 'use-places-autocomplete';

import { SET_LOCATION_PLACEHOLDER } from '../../../constants';
import { LocationDetailsInput } from '../../../generated/graphql';
import TextInputAutocomplete from '../../dragon-scales/InputAutocomplete/TextInputAutocomplete';

type Suggestion = {
  key: string;
  value: string;
  mainText: string;
  secondaryText?: string;
};

type PlacesAutocompleteProps = {
  defaultValue?: string;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  disabled?: boolean;
  label?: string;
  onChange: (locationDetails?: LocationDetailsInput) => void;
  placeholder?: string;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  locationRequired?: boolean;
};

const PlacesAutocomplete: FC<PlacesAutocompleteProps> = ({
  defaultValue = '',
  disabled,
  label,
  onChange,
  placeholder = SET_LOCATION_PLACEHOLDER,
  locationRequired,
}) => {
  const {
    ready,
    value,
    suggestions: { data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      types: ['locality', 'administrative_area_level_3', 'sublocality'], // Restrict suggestion results
    },
    debounce: 300,
    defaultValue,
  });

  const handleBlur = () => {
    if (value !== defaultValue) {
      if (value === '' && !locationRequired) {
        onChange(undefined);
      } else {
        setValue(defaultValue, false);
      }
    }
    clearSuggestions();
  };

  const handleInput = (value: string) => {
    setValue(value);
  };

  const handleSelect = (suggestion: Suggestion) => {
    const { value, key: placeID } = suggestion;
    clearSuggestions();
    if (value === defaultValue) {
      setValue(value, false);
      return;
    }

    const locationDetails: LocationDetailsInput = {
      name: value,
      lat: 0,
      lon: 0,
      city: '',
      state: '',
      stateLong: '',
      country: '',
    };
    // Get latitude and longitude via utility functions
    getGeocode({ placeId: placeID })
      .then((results) => {
        const geoCodeResults = results[0];
        // Extract location results from Google Maps API
        geoCodeResults.address_components.forEach(
          (details: { types: string[]; long_name: string; short_name: string }) => {
            // Locality refers to city name in Google Maps API
            if (details.types.includes('locality')) {
              locationDetails.city = details.long_name;
            } else if (details.types.includes('sublocality')) {
              // In Google API some cities are classified as sublocality (For Ex: Queens, Brooklyn)
              locationDetails.city = details.long_name;
            }
            // Administrative_area_level_1 refers to State name in Google Maps API
            else if (details.types.includes('administrative_area_level_1')) {
              locationDetails.stateLong = details.long_name;
              locationDetails.state = details.short_name;
            } else if (details.types.includes('country')) {
              locationDetails.country = details.short_name;
            }
          }
        );
        // Obtain lat/lon data from API in next step
        return getLatLng(results[0]);
      })
      .then(({ lat, lng }) => {
        setValue(value, false);
        locationDetails.lat = lat;
        locationDetails.lon = lng;
        onChange(locationDetails);
      })
      .catch(() => {
        setValue('', false);
        onChange(undefined);
      });
  };

  const suggestions = data.map((value: PlacesSuggestion) => ({
    key: value.place_id,
    value: value.description,
    mainText: value.structured_formatting.main_text,
    secondaryText: value.structured_formatting.secondary_text,
  }));

  const entries = suggestions.map((e) => ({
    id: e.key,
    label: e.mainText,
    description: e.secondaryText,
    'data-cy': 'PlacesAutocomplete-MenuItem',
  }));
  const onSelectionChange = (selectedKey: string) => {
    const value = suggestions.find(({ key }) => key === selectedKey);
    if (value) handleSelect(value);
  };

  return (
    <TextInputAutocomplete
      data-cy="project-location-text-input"
      disabled={disabled || !ready}
      entries={entries}
      label={label}
      onBlur={handleBlur}
      onSelectionChange={onSelectionChange}
      onTextInputChange={handleInput}
      placeholder={placeholder}
      value={value}
    />
  );
};

export default PlacesAutocomplete;
