import { FC, MouseEvent, SyntheticEvent, useState } from 'react';
import * as React from 'react';

import { Menu } from '@material-ui/core';
import { PopoverOrigin } from '@material-ui/core/Popover';
import MoreVert from '@material-ui/icons/MoreVert';

import { withStyles } from '../../../theme/komodo-mui-theme';

import IconMenuIcon from './IconMenuIcon';
import styles from './IconMenuStyles';
import SelectMenu from './SelectMenu';
import { MenuOption } from './SelectOption';
import { SelectVariants } from './SelectStyles';

interface IconMenuProps {
  classes: Classes<typeof styles>;
  className?: string | undefined;
  cy?: string;
  onClick?: () => void;
  options: MenuOption[];
  variant?: SelectVariants;
  id?: string;
  iconButtonClassName?: string;
  isBottomOriented?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  icon?: any | null;
  tooltip?: string;
}

const IconMenu: FC<IconMenuProps> = ({
  classes,
  className,
  cy = '',
  icon = <MoreVert />,
  iconButtonClassName,
  id,
  isBottomOriented = false,
  onClick = () => {},
  options,
  variant = SelectVariants.MENU,
  tooltip,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const anchorOrigin: PopoverOrigin = isBottomOriented
    ? {
        vertical: 'bottom',
        horizontal: 'right',
      }
    : { vertical: 'top', horizontal: 'right' };
  const transformOrigin: PopoverOrigin | undefined = isBottomOriented
    ? {
        vertical: 'top',
        horizontal: 'right',
      }
    : undefined;
  const menuStyle: React.CSSProperties | undefined = isBottomOriented
    ? { marginTop: 16 }
    : undefined;

  const handleClick = (event: MouseEvent) => {
    if (event) {
      setAnchorEl(event.currentTarget);
      event.stopPropagation();
      event.preventDefault();
      if (onClick) {
        onClick();
      }
    }
  };

  function handleClose(event?: SyntheticEvent<object>) {
    setAnchorEl(null);
    if (event) {
      event.stopPropagation();
    }
  }

  const menuIsOpen = Boolean(anchorEl);

  return (
    <div className={className || ''} id={id || ''}>
      <IconMenuIcon
        cy={cy}
        handleClick={handleClick}
        icon={icon}
        iconButtonClassName={iconButtonClassName}
        iconName={anchorEl && 'menu'}
        id={id}
        menuIsOpen={menuIsOpen}
        tooltip={tooltip}
        variant={variant}
      />
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={anchorOrigin}
        className={`print:hidden ${isBottomOriented ? classes.bottomOriented : ''}`}
        getContentAnchorEl={null}
        id="menu"
        onClose={(event) => handleClose(event)}
        open={menuIsOpen}
        style={menuStyle}
        transformOrigin={transformOrigin}
      >
        <SelectMenu onClose={(event) => handleClose(event)} options={options} variant={variant} />
      </Menu>
    </div>
  );
};

/** @deprecated in favor of design system component, please use scales/IconMenu */
export default withStyles(styles)(IconMenu);
