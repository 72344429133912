import { useMutation } from '@apollo/client';

import { REFETCH_SCENARIO_SANDBOX } from '../../../api/refetchSets';
import { getItemOverlayInputsFromItemIDs } from '../ScenarioUtils';

import { createScenarioInSandboxMutation } from './queries';

export const useCreateScenarioInSandboxMutation = (milestoneID: string) => {
  const name = '';
  const description = null;
  const [createFn] = useMutation(createScenarioInSandboxMutation);

  const createScenario = (color: string, itemIDs: UUID[] = []) => {
    const itemOverlays = getItemOverlayInputsFromItemIDs(itemIDs);
    createFn({
      variables: {
        milestoneID,
        name,
        description,
        color,
        itemOverlays,
      },
      refetchQueries: REFETCH_SCENARIO_SANDBOX,
    });
  };

  return [createScenario];
};
