import { ComponentProps } from 'react';

import { ItemsSortKey } from '../../../generated/graphql';
import SearchBar from '../../Search/SearchBar/SearchBar';
import JoinSelect from '../../Select/JoinSelect/JoinSelect';

import ItemTile from './ItemTile';

type Props = {
  items: ComponentProps<typeof ItemTile>['item'][];
  onAddToAllScenarios: ComponentProps<typeof ItemTile>['onAddToAllScenarios'];
  onAddToScenario: ComponentProps<typeof ItemTile>['onAddToScenario'];
  scenarios: ComponentProps<typeof ItemTile>['scenarios'];
  sortKey: ItemsSortKey;
  setSortKey: (sortBy: ItemsSortKey) => void;
  searchText: string;
  setSearchText: (searchText: string) => void;
};

const ItemTiles = (props: Props) => (
  <>
    <div className="flex flex-col gap-1">
      <SearchBar
        data-cy="item-search-bar"
        onChange={(e) => {
          props.setSearchText(e.target.value);
        }}
        onClear={() => {
          props.setSearchText('');
        }}
        placeholder=""
        searchTerm={props.searchText}
      />
      <div className="flex flex-col gap-0.5">
        <div className="type-label">Sort By</div>
        <JoinSelect
          cySelect="item-sort-select"
          entries={[
            { name: 'Item number', id: ItemsSortKey.SORT_NUMBER },
            { name: 'Cost impact', id: ItemsSortKey.SORT_COST_IMPACT },
            { name: 'Date created', id: ItemsSortKey.SORT_CREATION_TIME },
          ]}
          onChange={props.setSortKey}
          value={props.sortKey}
        />
      </div>
    </div>
    <div className="flex flex-col gap-2">
      {props.items.map((item) => (
        <ItemTile
          key={item.id}
          item={item}
          onAddToAllScenarios={props.onAddToAllScenarios}
          onAddToScenario={props.onAddToScenario}
          scenarios={props.scenarios}
          searchText={props.searchText}
        />
      ))}
    </div>
  </>
);

export default ItemTiles;
