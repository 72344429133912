import { createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
const styles = (theme: any) =>
  createStyles({
    cardHeaderTypography: {
      display: 'inline',
      color: theme.palette.joinPrimary,
    },
    root: {
      flexDirection: 'row',
      justifyContent: 'flex-start',
      zIndex: 1,
      '@media print': {
        display: 'none',
      },
    },
    milestoneTimelineLeft: {
      position: 'relative',
      left: '6px',
    },
    milestoneTimelineRight: {
      zIndex: 1,
      width: '100%',
    },
    costImpact: {
      flexGrow: 0,
      flexShrink: 0,
      ...theme.typography.number,
    },
    expander: {
      color: theme.palette.shadedGrey,
      height: '20px',
      marginRight: 4,
      width: '20px',
      '@media print': {
        display: 'none',
        height: '0px',
      },
    },
    currentMilestone: {
      fontWeight: 500,
    },
    mappedMilestoneLine: {
      ...theme.typography.webParagraph,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexWrap: 'nowrap',
    },
    itemMilestoneData: {
      textAlign: 'right',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    notDisplayed: {
      display: 'none',
    },
    previousMilestoneList: {
      alignItems: 'center',
      paddingTop: 4,
      display: 'flex',
      flexDirection: 'row',
      '&:focus': {
        outline: 'none',
      },
    },
    removeButton: {
      flexGrow: 0,
      flexShrink: 0,
      width: 24,
      height: 24,
      marginLeft: '4px',
      color: theme.palette.red,
    },
    removeIcon: {
      height: theme.spacing.smallIcon,
      width: theme.spacing.smallIcon,
    },
    milestoneNameTextLabel: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      display: 'inline-block',
      flex: '1 1 auto',
      paddingRight: '10px',
      marginRight: 'auto',
    },
    milestoneStatus: {
      padding: '6px, 6px 6px 0px',
      marginRight: 4,
      marginLeft: 4,
    },
    costText: { ...theme.typography.webParagraph },
  });

export default styles;
