import { FC, useEffect, useRef, useState } from 'react';
import { useScroll } from 'react-use';

import { ApolloError, useReactiveVar } from '@apollo/client';
import { Card, Divider } from '@material-ui/core';

import { EXPORT_MILESTONE_SUMMARY_REPORT } from '../../../actions/actionTypes';
import {
  analyticsEvent,
  reportDistributionExperiment,
} from '../../../analytics/analyticsEventProperties';
import {
  currentUserReportVar,
  isDownloadingCostReportToExcelVar,
  itemSidebarOpenVar,
  reportDistributionExperimentTrackerVar,
  setReactiveLocal,
} from '../../../api/apollo/reactiveVars';
import { JoinProjectRoutes } from '../../../api/gqlEnums';
import JoinAPI from '../../../api/joinAPI';
import { COSTS, DISPLAY, REPORT_DISTRIBUTION_EXPERIMENT_TRACKER } from '../../../constants';
import { PermissionResource } from '../../../generated/graphql';
import { useListKeyPressListener } from '../../../hooks/useListKeyPressListener';
import useSendAnalytics from '../../../hooks/useSendAnalytics';
import { withStyles } from '../../../theme/komodo-mui-theme';
import { checkCostModeIncludesMarkups, useCostMode } from '../../../utilities/costMode';
import usePermissions from '../../../utilities/permissions/usePermissions';
import { generateSharedPath } from '../../../utilities/routes/links';
import { getMilestoneIdFromUrl, getProjectIdFromUrl } from '../../../utilities/url';
import { useCurrentUser } from '../../contexts/current-user';
import ItemSidebarWrapper from '../../frame/ItemSidebar/ItemSidebarWrapper';
import DistributionDialogData from '../../ReportsTab/DistributionDialog/DistributionDialogData';
import { isDefaultReport } from '../../ReportsTab/ReportsManagerMenu/utils';
import ReportDistributionPrompt from '../CostReportExportPanel/ReportDistributionPrompt';
import CostReportHeader from '../CostReportHeader/CostReportHeader';
import { NodeData } from '../CostReportList/CostReportCategoriesTree';
import { getMSRExportColumns } from '../CostReportUtils';
import MilestoneCostReport from '../MilestoneCostReport';

import styles from './CostReportStyles';
import useCostReportParams from './useCostReportParams';

type CostReportProps = {
  categorizations: Categorization[];
  classes: Classes<typeof styles>;
  defaultCategorizations: Categorization[];
  error?: ApolloError;
  enabledUnits: Unit[];
  milestones: Milestone[];
  projectName: string;
  setIsLoading?: (loading: boolean) => void;
};

const CostReport: FC<CostReportProps> = ({
  categorizations,
  defaultCategorizations,
  classes,
  error,
  enabledUnits,
  milestones,
  projectName,
  setIsLoading = () => {},
}) => {
  // Experimental report distribution constants and logic
  const userID = useCurrentUser().id;
  const { canView } = usePermissions();
  const hasReportDistributionPermission = canView(PermissionResource.REPORT_DISTRIBUTION);
  const canViewOwnerCosts = canView(PermissionResource.OWNER_COSTS);
  const [showReportDistributionPrompt, setShowReportDistributionPrompt] = useState(false);
  const [showReportDistributionDialog, setShowReportDistributionDialog] = useState(false);
  const [reportModalOpenOverride, setReportModalOpenOverride] = useState(false);
  const currentReport = useReactiveVar(currentUserReportVar);
  const isViewingSavedReport = !!currentReport?.id && !isDefaultReport(currentReport?.id ?? '');

  const reportDistributionExperimentTracker = useReactiveVar(
    reportDistributionExperimentTrackerVar
  );
  const hasNotSeenReportDistributionPrompt =
    !reportDistributionExperimentTracker?.userIDs.includes(userID);
  const shouldSeeReportDistributionExperiment =
    hasNotSeenReportDistributionPrompt && hasReportDistributionPermission;

  const storeUserIDForReportDistributionExperiment = () => {
    setReactiveLocal(
      reportDistributionExperimentTrackerVar,
      REPORT_DISTRIBUTION_EXPERIMENT_TRACKER,
      {
        userIDs: [...(reportDistributionExperimentTracker?.userIDs ?? []), userID],
      },
      false
    );
  };

  // Read our project and milestone ids from the URL to populate queries
  const milestoneId = getMilestoneIdFromUrl();
  const projectId = getProjectIdFromUrl();

  const isItemSidebarOpen = useReactiveVar(itemSidebarOpenVar);
  const [itemNodes, setNavigationData] = useState<NodeData[] | null>(null);

  const milestone = milestones.find((milestone) => milestone.id === milestoneId);
  const milestoneName = milestone?.name ?? '';

  const {
    columnDescriptions,
    displayColumnDescriptions,
    displayGroupBy,
    headerDescriptions,
    filterManager,
    setSetting,
    setSettings,
    settings,
  } = useCostReportParams(
    categorizations,
    defaultCategorizations,
    milestoneName,
    COSTS,
    enabledUnits
  );

  const { status, viewMode } = settings;
  const { search } = window.location;
  // Print click and key press
  const printOnClick = (route: JoinProjectRoutes) => {
    window.open(generateSharedPath(route, { projectId, milestoneId, search }), '_blank');
  };

  // Key Events
  useListKeyPressListener(() => printOnClick(JoinProjectRoutes.PRINT_MSR));

  // Scrollling
  // We are gonna watch the x scrolling and pass along a prop to turn on the border
  // we throttle the listening and give it a threshold on to overwhelm us
  const [scroll, setScroll] = useState(false);
  const scrollRef = useRef(null);
  const { x } = useScroll(scrollRef);
  useEffect(() => {
    if (x > 2) {
      if (!scroll) setScroll(true);
    } else if (scroll) {
      setScroll(false);
    }
  }, [x, scroll]);

  const costMode = useCostMode();
  const includeMarkups = checkCostModeIncludesMarkups(costMode);
  const includeOwnerCosts = canViewOwnerCosts && includeMarkups;
  const sendAnalytics = useSendAnalytics();
  const { filterQueryInput: viewFilter } = filterManager;
  const filenameTokens = [projectName, milestoneName];

  const columns = getMSRExportColumns(settings, enabledUnits);
  const columnSets = [{ milestoneID: milestoneId, columns }];

  const exportCostReport = () => {
    if (isDownloadingCostReportToExcelVar()) return;
    sendAnalytics(analyticsEvent(EXPORT_MILESTONE_SUMMARY_REPORT));
    JoinAPI.exportCostReport(
      projectId,
      displayGroupBy,
      columnSets,
      viewFilter,
      costMode,
      includeMarkups,
      false,
      filenameTokens,
      {
        groupBys: displayGroupBy.map((g) => g.id),
        milestoneEstimates: [],
      },
      settings?.estimateLines === DISPLAY,
      includeOwnerCosts
    );
  };

  return (
    <div className={classes.componentWithSidebar}>
      <div className={classes.root}>
        <Card className={classes.card} elevation={0} square>
          <div ref={scrollRef} className={classes.scrollBody}>
            <div className={classes.sticky}>
              {milestone && (
                <CostReportHeader
                  categorizations={categorizations}
                  columnDescriptions={columnDescriptions}
                  currentReport={currentReport}
                  displayColumnDescriptions={displayColumnDescriptions}
                  displayGroupBy={displayGroupBy}
                  exportCostReport={exportCostReport}
                  filterManager={filterManager}
                  milestone={milestone}
                  printOnClick={
                    shouldSeeReportDistributionExperiment
                      ? () => {
                          setShowReportDistributionPrompt(true);
                        }
                      : printOnClick
                  }
                  projectId={projectId}
                  reportModalOpenOverride={reportModalOpenOverride}
                  setReportModalOpenOverride={setReportModalOpenOverride}
                  setSetting={setSetting}
                  setSettings={setSettings}
                  setShowReportDistributionDialog={setShowReportDistributionDialog}
                  settings={settings}
                  status={status}
                  viewMode={viewMode}
                />
              )}
              <Divider />
            </div>
            <MilestoneCostReport
              categorizations={categorizations}
              displayGroupBy={displayGroupBy}
              error={error}
              filterManager={filterManager}
              headerDescriptions={headerDescriptions}
              milestoneDescriptions={displayColumnDescriptions}
              scroll={scroll}
              setIsLoading={setIsLoading}
              setNavigationData={setNavigationData}
              setSettings={setSettings}
              settings={settings}
            />
          </div>
        </Card>
      </div>
      {isItemSidebarOpen && itemNodes ? (
        <ItemSidebarWrapper items={itemNodes} milestones={milestones} />
      ) : (
        <div />
      )}
      {shouldSeeReportDistributionExperiment && (
        <ReportDistributionPrompt
          isOpen={showReportDistributionPrompt}
          isViewingSavedReport={isViewingSavedReport}
          onClose={() => {
            setShowReportDistributionPrompt(false);
          }}
          onPrint={() => {
            storeUserIDForReportDistributionExperiment();
            printOnClick(JoinProjectRoutes.PRINT_MSR);
          }}
          setReportModalOpenOverride={setReportModalOpenOverride}
          setShowReportDistributionDialog={setShowReportDistributionDialog}
        />
      )}
      {shouldSeeReportDistributionExperiment && (
        <DistributionDialogData
          distributionDialogsState={{
            distributionDialogDataIsOpen: showReportDistributionDialog,
            distributionHistoryDialogIsOpen: false,
            reportID: currentReport?.id ?? '',
            reportName: currentReport?.name ?? '',
            isEditingDistribution: false,
          }}
          onClose={() => {
            storeUserIDForReportDistributionExperiment();
            setShowReportDistributionDialog(false);
            sendAnalytics(
              reportDistributionExperiment({
                action: 'Viewed Report Distribution Dialog from Report Distribution Prompt',
              })
            );
          }}
          projectID={projectId}
        />
      )}
    </div>
  );
};

export default withStyles(styles)(CostReport);
