import { FC } from 'react';

import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons';

type Props = {
  shiftViewer: (index: number) => void;
};

const DialogNavigation: FC<Props> = ({ shiftViewer }) => {
  const onClick = (index: number) => () => shiftViewer(index);

  const buttonStyle =
    'h-36 w-36 bg-transparent m-4 absolute top-[45%] hover:bg-background-2 hover:bg-opacity-50 rounded-[50%]';
  const iconStyle = '!h-36 !w-36 text-type-secondary';
  return (
    <>
      <div className={`${buttonStyle} left-1`}>
        <button aria-label="Previous Milestone" onClick={onClick(-1)}>
          <KeyboardArrowLeft className={`${iconStyle} pr-2`} />
        </button>
      </div>
      <div className={`${buttonStyle} right-1`}>
        <button aria-label="Next Milestone" onClick={onClick(1)}>
          <KeyboardArrowRight className={`${iconStyle} pl-2`} />
        </button>
      </div>
    </>
  );
};

export default DialogNavigation;
