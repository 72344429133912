import { FC } from 'react';

import { Typography } from '@material-ui/core';
import { Today as Calendar } from '@material-ui/icons';

import { withStyles } from '../../../theme/komodo-mui-theme';
import { parseDate } from '../../../utilities/dates';

import MilestoneMeetingChipStyles from './MilestoneMeetingChipStyles';

type MilestoneMeetingChipProps = {
  classes: Classes<typeof MilestoneMeetingChipStyles>;
  date?: string | null;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  hideDate?: boolean;
  id?: string;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  inline?: boolean;
  isMilestone?: boolean;
  name?: string;
  variant?: string;
};

export const CHIP = 'chip';
export const SECTIONHEADER = 'section-header';
const MilestoneMeetingChip: FC<MilestoneMeetingChipProps> = ({
  classes,
  date = '',
  hideDate = false,
  id = null,
  inline = false,
  isMilestone = false,
  name = null,
  variant = CHIP,
}) => {
  // TODO(Drew): insert a link to filter to this checkpoint

  const displayValue = id
    ? `${name}${date && !hideDate ? ` (${parseDate(date)})` : ''}`
    : `${isMilestone ? 'Milestone' : 'Meeting'}`;

  return (
    <div
      className={`${classes.root} ${!id ? classes.empty : ''} ${
        variant === SECTIONHEADER ? classes.title : ''
      }`}
    >
      {!inline && <Calendar className={classes.icon} />}
      <Typography
        className={`${
          variant === SECTIONHEADER
            ? `${classes.title} ${classes.sectionHeaderText}`
            : `${classes.text}`
        }`}
        variant="body1"
      >
        {displayValue}
      </Typography>
    </div>
  );
};

export default withStyles(MilestoneMeetingChipStyles)(MilestoneMeetingChip);
