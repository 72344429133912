import { useCallback } from 'react';

import { copyItemAnalyticsEvent } from '../../../analytics/analyticsEventProperties';
import useSendAnalytics from '../../../hooks/useSendAnalytics';

const useSendCopyItemAnalytics = () => {
  const sendAnalytics = useSendAnalytics();

  return useCallback(
    (
      type: Parameters<typeof copyItemAnalyticsEvent>[0],
      eventProperties?: Parameters<typeof copyItemAnalyticsEvent>[1]
    ) => {
      sendAnalytics(copyItemAnalyticsEvent(type, eventProperties));
    },
    [sendAnalytics]
  );
};

export default useSendCopyItemAnalytics;
