import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';

const styles = (theme: KomodoTheme) =>
  createStyles({
    card: {
      overflow: 'visible',
      backgroundColor: 'white',
      display: 'flex',
      minHeight: `calc(100vh - 48px - 2px)`,
      flexDirection: 'column',
      border: theme.border.line,
      '@media print': {
        border: 'none',
        marginTop: 0,
        marginBottom: 0,
      },
    },
    empty: {
      padding: 24,
    },
    editContainer: {
      flex: '1 1 100%',
      flexDirection: 'column',
      display: 'flex',
    },
    root: {
      minWidth: 700,
      flexGrow: 1,
      overflow: 'auto',
      margin: '0 auto',
      '@media print': {
        width: '100%',
        border: `0px none`,
        maxWidth: 'none',
        overflowX: 'visible',
      },
    },
  });

export default styles;
