import { useState } from 'react';

import {
  EventProperties,
  KeyDatesNudgeEventType,
} from '../../../analytics/analyticsEventProperties';
import { TimelineActivityType } from '../../../api/gqlEnumsBe';
import { useProjectID } from '../../../utilities/routes/params';
import DismissableStatusBanner from '../../dragon-scales/DismissableStatusBanner/DismissableStatusBanner';
import KeyDatesNudgeDialog from '../Dialog/KeyDatesNudgeDialog';
import { useKeyDates } from '../hooks/useKeyDates';
import useSendKeyDatesAnalytics from '../hooks/useSendKeyDatesAnalytics';

type Props = {
  activityTypes: TimelineActivityType[];
};

export function KeyDatesBanner(props: Props) {
  const projectID = useProjectID();
  const { activityTypes } = props;
  const [openDialog, setOpenDialog] = useState(false);
  const sendKeyDatesAnalytics = useSendKeyDatesAnalytics();
  const keyDateLocation = 'Timeline Dialog';
  const keyDates = useKeyDates({
    activityTypes,
    projectID,
    onCreateEvent: (eventProps: EventProperties) => {
      sendKeyDatesAnalytics(KeyDatesNudgeEventType.CREATE_EVENT, keyDates, {
        ...eventProps,
        keyDateLocation,
      });
    },
    onCreateMilestone: (eventProps: EventProperties) => {
      sendKeyDatesAnalytics(KeyDatesNudgeEventType.CREATE_MILESTONE, keyDates, {
        ...eventProps,
        keyDateLocation,
      });
    },
  });

  if (!keyDates.hasSuggestions) return null;

  return (
    <div className="px-3 pb-6">
      <DismissableStatusBanner
        buttonLabel="Add Key Dates"
        description="Help your team stay on track by adding GMP and Construction dates"
        header="Track your team's progress towards delivery"
        localStorageKey="KEY_DATES_NUDGE"
        onCTA={() => {
          sendKeyDatesAnalytics(KeyDatesNudgeEventType.OPEN_DIALOG, keyDates);
          setOpenDialog(true);
        }}
        onDismiss={() => {
          sendKeyDatesAnalytics(KeyDatesNudgeEventType.DISMISS_BANNER, keyDates);
        }}
        onRemindMeLater={() => {
          sendKeyDatesAnalytics(KeyDatesNudgeEventType.REMIND_LATER, keyDates);
        }}
      />
      <KeyDatesNudgeDialog
        isOpen={openDialog}
        keyDates={keyDates}
        onClose={() => {
          sendKeyDatesAnalytics(KeyDatesNudgeEventType.CLOSE_DIALOG, keyDates);
          setOpenDialog(false);
        }}
        projectID={projectID}
      />
    </div>
  );
}
