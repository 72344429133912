import { VARIANCE_HEADER } from '../../../../constants';
import theme from '../../../../theme/komodo-mui-theme';
import { isNonNullable } from '../../../../utilities/types';

export const formatUnitText = (name: string, abbreviationSingular: string) =>
  `${name} (${abbreviationSingular})`;

export const formatUnitName = (unit: Unit) => formatUnitText(unit.name, unit.abbreviationSingular);

// TODO - update these colors. Do we want these mapping to default units?
export const modalUnitsColors = [
  theme.palette.unitBeige,
  theme.palette.unitBlue,
  theme.palette.unitMidPurple,
  theme.palette.unitLightGreen,
  theme.palette.unitFuscia,
  theme.palette.unitMagenta,
  theme.palette.unitYellow,
  theme.palette.unitOrange,
  theme.palette.unitPeach,
  theme.palette.unitPink,
  theme.palette.unitRed,
  theme.palette.unitRoyalBlue,
];

export const formatMilestoneNames = (milestoneIDs: UUID[], milestones: Milestone[]) => {
  const milestoneNames: string[] = milestoneIDs.map((milestoneID: UUID) => {
    const foundMilestone = milestones.find((milestone) => milestone.id === milestoneID);
    if (foundMilestone) return foundMilestone.name;
    return '';
  });
  milestoneNames.push(VARIANCE_HEADER);
  return milestoneNames;
};

export const getMilestoneQuantities = (milestones: Milestone[], milestoneIDs: UUID[]) => {
  const milestone1 = milestones.find(
    (milestone) => milestone.id === (milestoneIDs && milestoneIDs.length && milestoneIDs[0])
  );
  const milestone1Quantities = milestone1 && milestone1.quantities?.filter(isNonNullable);
  const milestone2 = milestones.find(
    (milestone) => milestone.id === (milestoneIDs && milestoneIDs.length && milestoneIDs[1])
  );
  const milestone2Quantities = milestone2 && milestone2.quantities?.filter(isNonNullable);

  return { milestone1Quantities, milestone2Quantities };
};

export const findQuantity = (unit: Unit, quantities?: Quantity[]) =>
  quantities && quantities.find((quantity) => quantity.unit.id === (unit && unit.id));
