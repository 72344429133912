import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';

export default (theme: KomodoTheme) =>
  createStyles({
    item: {
      paddingLeft: 2,
      paddingRight: 2,
    },
    popup: {
      border: `1px solid ${theme.palette.backgroundWhite}`,
    },
    select: {
      border: theme.border.line,
      borderRadius: theme.border.radius,
      height: 34,
      marginTop: 20,
      minHeight: 34,
      padding: '2px 2px',
    },
  });
