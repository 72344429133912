import { ComponentProps } from 'react';

import TextInput from '../TextInput/TextInput';

type TextInputProps = ComponentProps<typeof TextInput>;
type PassableTextInputProps = Omit<TextInputProps, 'defaultValue' | 'value' | 'onChange'>;
type Props = {
  defaultValue?: number;
  onChange?: (value: number | null) => void;
  value?: number | null;
} & PassableTextInputProps;

export default function NumberInput(props: Props) {
  return (
    <TextInput
      {...props}
      // We coerce the `label` and `aria-label` to strings so that the TextInput's
      // type validation passes. The TextInput component uses a StrictUnion<> to ensure
      // that either an aria-label or label is always passed in. TypeScript isn't smart
      // enough to infer the type safety if we do the same approach here, though.
      // As a result, we're relying on React Aria's console warning to notify about
      // cases where we're missing both an aria-label and a label.
      // Future versions of TypeScript (>4.9) may be able to infer this so feel free to
      // try it out, dear reader.
      aria-label={props['aria-label'] ?? ''}
      defaultValue={stringifyNumber(props.defaultValue)}
      label={props.label ?? ''}
      onChange={(newValue) => {
        const numberValue = newValue ? Number(newValue) : null;
        props.onChange?.(numberValue);
      }}
      type="number"
      value={stringifyNumber(props.value)}
    />
  );
}

const stringifyNumber = (numberValue: number | null | undefined): string | undefined => {
  let stringValue: string | undefined;
  if (numberValue !== undefined) {
    if (numberValue === null) {
      stringValue = '';
    } else if (typeof numberValue === 'number') {
      stringValue = `${numberValue}`;
    }
  }

  return stringValue;
};
