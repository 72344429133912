import { Check } from '@material-ui/icons';

import { navEventTypes } from '../../../../analytics/analyticsEventProperties';
import { TOTAL } from '../../../../constants';
import { useCurrencySymbol } from '../../../../utilities/currency';
import { pluralizeQuantityText } from '../../../../utilities/string';
import { BabyMenuButton, Icon } from '../../../scales';
import useSendNavAnalytics from '../../hooks/useSendNavAnalytics';

type Props = {
  quantity?: Quantity;
  quantities: Quantity[];
  onChange: (quantityID: UUID | undefined) => void;
};

export default function UnitToggle(props: Props) {
  const sendNavAnalytics = useSendNavAnalytics();

  const onChange = (q: Quantity | undefined) => {
    const qID = q?.id || TOTAL;
    props.onChange(qID);
  };

  const currencySymbol = useCurrencySymbol();
  const totalCostLabel = `Total ${currencySymbol}`;
  const selectedText = props.quantity
    ? getLabel(currencySymbol, props.quantity.unit)
    : totalCostLabel;

  return (
    <div className="flex justify-between gap-1 pb-2 type-body3" data-cy="unit-toggle">
      {props.quantity && (
        <div className="whitespace-nowrap rounded bg-background-2 px-1.5 py-1">
          {pluralizeQuantityText(props.quantity)}
        </div>
      )}
      <div className="min-w-0 flex-shrink flex-grow" />
      <div className="flex min-w-0 flex-shrink items-center gap-1">
        <div className="truncate">{selectedText}</div>
        <BabyMenuButton
          aria-label="change units of measure"
          data-cy="units-of-measure-menu-button"
          icon={<Icon name="settings" />}
          sections={[
            {
              entries: [
                {
                  id: TOTAL,
                  endAdornment: !props.quantity && <Check />,
                  label: totalCostLabel,
                  onClick: () => {
                    onChange(undefined);
                    sendNavAnalytics(navEventTypes.SELECT_UNIT);
                  },
                },
              ],
            },
            {
              entries: props.quantities.map((q) => ({
                id: q.id,
                label: getLabel(currencySymbol, q.unit),
                description: getDescription(q.unit),
                endAdornment: props.quantity?.id === q.id && <Check />,
                onClick: () => {
                  onChange(q);
                  sendNavAnalytics(navEventTypes.SELECT_UNIT, { unitID: q.id });
                },
              })),
            },
          ]}
        />
      </div>
    </div>
  );
}

const getLabel = (currencySymbol: string, unit: Unit) =>
  `${currencySymbol}/${unit.abbreviationSingular}`;

const getDescription = (unit: Unit) => {
  const labelMatchingDecription = [
    unit.abbreviationSingular.toLocaleLowerCase(),
    unit.abbreviationPlural.toLocaleLowerCase(),
  ].includes(unit.name.toLocaleLowerCase());
  if (labelMatchingDecription) {
    return undefined;
  }
  return unit.name;
};
