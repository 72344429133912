import { JoinCompanyRoutes, JoinCompanyStandardsRoutes } from '../../api/gqlEnums';

import CompanyTabCollaboratorsData from './CompanyTabCollaborators/CompanyTabCollaboratorsData';
import CompanyTabCollaboratorsProfileData from './CompanyTabCollaboratorsProfile/CompanyTabCollaboratorsProfileData';
import CompanyTabInsightsAlertsData from './CompanyTabInsightsAlerts/CompanyTabInsightsAlertsData';
import CompanyTabMembersData from './CompanyTabMembers/CompanyTabMembersData';
import CompanyTabMembersProfileData from './CompanyTabMembersProfile/CompanyTabMembersProfileData';
import CompanyTabOrganizationsData from './CompanyTabOrganizations/CompanyTabOrganizationsData';
import {
  RouteToOrganizationsComponent,
  routeToOrganizationsComponent,
} from './CompanyTabOrganizations/routes';
import CompanyTabProjectStatsData from './CompanyTabProjectStats/CompanyTabProjectStatsData';
import CompanyTabSettingsData from './CompanyTabSettings.tsx/CompanyTabSettingsData';
import CompanyCategorizationsData from './Standards/CompanyCategorizations/CompanyCategorizationsData';
import ProjectTemplatesData from './Standards/ProjectTemplates/ProjectTemplatesData';
import ProjectTypesData from './Standards/ProjectTypes/ProjectTypesData';
import StandardsData from './Standards/StandardsData';

export type RouteToCompanyStandardsComponent = {
  [key in JoinCompanyStandardsRoutes]: { element: JSX.Element };
};
export const routeToCompanyStandardsComponent: RouteToCompanyStandardsComponent = {
  [JoinCompanyStandardsRoutes.COMPANY_CATEGORIZATIONS]: { element: <CompanyCategorizationsData /> },
  [JoinCompanyStandardsRoutes.COMPANY_PROJECT_TEMPLATES]: { element: <ProjectTemplatesData /> },
  [JoinCompanyStandardsRoutes.COMPANY_PROJECT_TYPES]: { element: <ProjectTypesData /> },
};

export type RouteToCompanyComponent = {
  [key in JoinCompanyRoutes]: {
    element: JSX.Element;
    subs?: RouteToCompanyStandardsComponent | RouteToOrganizationsComponent;
  };
};
export const routeToCompanyComponent: RouteToCompanyComponent = {
  [JoinCompanyRoutes.COMPANY_COLLABORATORS]: { element: <CompanyTabCollaboratorsData /> },
  [JoinCompanyRoutes.COMPANY_COLLABORATORS_PROFILE]: {
    element: <CompanyTabCollaboratorsProfileData />,
  },
  [JoinCompanyRoutes.COMPANY_INSIGHTS_ALERTS]: { element: <CompanyTabInsightsAlertsData /> },
  [JoinCompanyRoutes.COMPANY_MEMBERS]: { element: <CompanyTabMembersData /> },
  [JoinCompanyRoutes.COMPANY_MEMBERS_PROFILE]: { element: <CompanyTabMembersProfileData /> },
  [JoinCompanyRoutes.COMPANY_PROJECT_STATS]: { element: <CompanyTabProjectStatsData /> },
  [JoinCompanyRoutes.COMPANY_ORGANIZATIONS]: {
    element: <CompanyTabOrganizationsData />,
    subs: routeToOrganizationsComponent,
  },
  [JoinCompanyRoutes.COMPANY_SETTINGS]: { element: <CompanyTabSettingsData /> },
  [JoinCompanyRoutes.COMPANY_STANDARDS]: {
    element: <StandardsData />,
    subs: routeToCompanyStandardsComponent,
  },
};
