import ShareGroup from '../../../Icons/ShareGroup';
import { Tooltip } from '../../../scales';

import SharedUsersTooltipContent from './SharedUsersTooltipContent';

type Props = {
  noun?: string;
  onTooltipOpen?: () => void;
  sharedUsers?: { id: UUID; name: string }[];
};

export default function SharedBadge(props: Props) {
  if (!props.sharedUsers || props.sharedUsers.length < 2) return null;

  const tooltipContent = (
    <SharedUsersTooltipContent
      hasPrivateParent={false}
      noun={props.noun}
      sharedUsers={props.sharedUsers}
    />
  );
  return (
    <Tooltip content={tooltipContent} onOpen={props.onTooltipOpen}>
      <div className="flex items-center gap-1" data-cy="share-icon">
        <ShareGroup />
        <div data-cy="share-count">{props.sharedUsers.length}</div>
      </div>
    </Tooltip>
  );
}
