import { KeyboardArrowDown, KeyboardArrowRight } from '@material-ui/icons';

type Props = {
  isExpanded: boolean;
};

export default function ExpandableFiltersIcon(props: Props) {
  // TODO: Could add animation styling here to match other locations in app

  return props.isExpanded ? <KeyboardArrowDown /> : <KeyboardArrowRight />;
}
