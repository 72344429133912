import { ReactNode } from 'react';
import { Item } from 'react-stately';

import { Chip } from '../../scales';

export type Props = {
  badge?: ReactNode;
  'data-cy'?: string;
  description?: string;
  endAdornment?: ReactNode;
  id: string;
  label: string;
  startAdornment?: ReactNode;
};

export default function Entry(props: Props) {
  return (
    <Item key={props.id} aria-label={props.label} data-cy={props['data-cy']}>
      {props.startAdornment}
      <div className="flex flex-1 items-center gap-2">
        <div className="flex flex-col gap-0.5">
          <div className="py-0.5 type-body1">{props.label}</div>
          {props.description && <div className="py-0.5 type-label">{props.description}</div>}
        </div>
        {props.badge && <Chip text={props.badge} />}
      </div>
      {props.endAdornment}
    </Item>
  );
}
