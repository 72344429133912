import { DateGroup } from '../../../generated/graphql';

import ItemActivityDateSelect from './ItemActivityDateSelect';
import { getFormattedDateText } from './ItemActivityFeedUtils';

type ItemActivityDateDividerProps = {
  date: string;
  handleSelect: (dateGroup: DateGroup) => void;
  currentDateGroup?: DateGroup | null;
};

const ItemActivityDateDivider = ({
  date,
  handleSelect,
  currentDateGroup,
}: ItemActivityDateDividerProps) => {
  const localDate = new Date(date);
  const formattedDateText = getFormattedDateText(localDate, currentDateGroup);
  const lineComponent = (
    <div className="relative w-full">
      <svg
        fill="none"
        height="2"
        viewBox="0 0 850 2"
        width="100%"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path className="stroke-border-default" d="M0 1L850 1" />
      </svg>
    </div>
  );

  return (
    <div className="flex w-[850px] items-center justify-center pt-2" id={currentDateGroup || ''}>
      {lineComponent}
      <strong className="flex w-auto whitespace-nowrap pl-4 pr-1 type-body2">
        {formattedDateText}
      </strong>
      <ItemActivityDateSelect currentDateGroup={currentDateGroup} handleSelect={handleSelect} />
      {lineComponent}
    </div>
  );
};

ItemActivityDateDivider.displayName = 'ItemActivityDateDivider';
export default ItemActivityDateDivider;
