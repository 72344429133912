import { FC } from 'react';
import * as React from 'react';

import { SendAnalyticsFn } from '../../analytics/analyticsEventProperties';
import { ITEM, ITEM_WITH_OPTIONS, OPTION } from '../../constants';
import { MarkupType } from '../../generated/graphql';
import { ContingencyReportView } from '../ContingencyReport/ContingencyReport/ContingencyReportUtils';
import { SummaryValue } from '../FilterPanel/filterUtils';

// SHARED STYLE CONSTANTS
export const LANDSCAPE_WIDTH = 960;
export const PORTRAIT_WIDTH = 741;
export const CHART_HEIGHT = 538;
export const MARGIN = 38;
export const SHADOW = `2px 5px ${MARGIN / 2}px ${MARGIN}px #C7C7C7`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
export const hasAny = (things: any) => things && things.length > 0;

// Adds a extra info only used in print views

interface PrintNode {
  display?: string;
  id?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  levels?: any;
  name?: string;
  number?: string;
}

interface PrintItemTree {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  branches: any;
  items: ItemDataQueryItem[];
  level: number;
  node: PrintNode;
  totalItems: number;
}

export const hasItemsOrOptions = (tree: PrintItemTree) => {
  // totalItems: count of all items (not options) in the tree
  if (tree.totalItems > 0) return true;
  // items.length: count of all items or options at current depth in tree
  if (tree.items && tree.items.length > 0) return true;
  // We recursively check subtrees for hidden options in as subtree's items array
  let branchHasItems = false;
  if (tree.branches) {
    tree.branches.forEach((subtree: PrintItemTree) => {
      if (branchHasItems) return;
      if (hasItemsOrOptions(subtree)) branchHasItems = true;
    });
  }
  return branchHasItems;
};

// navigates the tree in order, building flat list of items
// orders branches such that options directly follow parents
export const flattenTree = (flatItems: ItemDataQueryItem[], tree: PrintItemTree) => {
  const orderedBranch: ItemDataQueryItem[] = [];
  tree.items.forEach((item) => {
    if ('itemType' in item && item.itemType === ITEM_WITH_OPTIONS) {
      orderedBranch.push(item);
      tree.items
        .filter(
          (i) =>
            i.itemType === OPTION && 'options' in item && item.options.some((o) => o.id === i.id)
        )
        .forEach((i) => orderedBranch.push(i));
    } else if (item.itemType === ITEM) {
      orderedBranch.push(item);
    } else if (item.itemType === OPTION) {
      if (!tree.items.some((i) => i.id === (item as PrintOption).parent)) orderedBranch.push(item);
    }
  });
  flatItems.push(...orderedBranch);
  if (!tree.branches || tree.branches.length === 0) return flatItems;
  tree.branches.values().forEach((branch: PrintItemTree) => flattenTree(flatItems, branch));
  return flatItems;
};

// uses the items and options to build list in order of tree without duplicates

export const rowIsEmpty = (row: PrintGridRow, tableVariant: string) => {
  let rowNotEmpty = false;
  if (tableVariant === 'estimate') {
    if (
      (row as PrintViewCell[]).some(
        (cell) =>
          cell.value.string !== '' && cell.value.string !== '0' && cell.value.category !== null
      )
    )
      rowNotEmpty = true;
  } else {
    const markupRow = row as Markup;
    const value = markupRow.value || '';
    if (markupRow.type === MarkupType.FIXED) {
      if (value.toString() !== '0' || markupRow.name !== '') rowNotEmpty = true;
    } else if (
      value.toString() !== '0' ||
      markupRow.name !== '' ||
      (markupRow.markupReference?.appliesTo && markupRow.markupReference?.appliesTo.length > 0)
    )
      rowNotEmpty = true;
  }
  return !rowNotEmpty;
};

export const hasValidRow = (gridRows: PrintGridRow[] | PrintViewLine[], tableVariant: string) => {
  const validRow =
    tableVariant === 'estimate'
      ? gridRows.some((row) => !rowIsEmpty((row as PrintViewLine).cells, tableVariant))
      : gridRows.some((row) => !rowIsEmpty(row as PrintGridRow, tableVariant));
  return validRow;
};

export const LandscapeOrientation: FC = () => (
  <style type="text/css">{`@media print{@page {size: landscape;}}`}</style>
);

export const PortraitOrientation: FC = () => (
  <style type="text/css">{`@media print{@page {size: portrait;}}`}</style>
);

export const isCtrlMeta = (event: KeyboardEvent | React.KeyboardEvent | React.MouseEvent) =>
  event.ctrlKey || event.metaKey;
export const isPrintKeys = (event: KeyboardEvent | React.KeyboardEvent) =>
  isCtrlMeta(event) && event.key === 'p';

export const getFilterLabels = (filters: SummaryValue[]) => {
  const hasFilters = hasAny(filters);
  if (!hasFilters) return null;
  return (
    <>
      <div className="print-subheader-inline-filter">
        <span className="print-bold-text">Filtered by:&nbsp;</span>
      </div>
      {filters.map(
        ({ header, value }) =>
          value && (
            <div key={value} className="print-subheader-inline-filter">
              {header && <span className="print-bold-text">{header}:&nbsp;</span>}
              <span>{value}</span>
            </div>
          )
      )}
    </>
  );
};

export const getGroupByLabels = (groupByNameStrings: string[]) => {
  const hasGroupBy = hasAny(groupByNameStrings);
  if (!hasGroupBy) return null;
  return (
    <div className="print-subheader-inline">
      <span className="print-bold-text">Grouped by:&nbsp;</span>
      <span>{groupByNameStrings.join(', ')}</span>
    </div>
  );
};

const getRowIndex = (displayNodes: number[], nodeNumber: number) =>
  displayNodes.findIndex((n) => n === nodeNumber);

export const getHasBreakpoint = (
  nodeNumber: number,
  rowHeight: number,
  pageHeight: number,
  displayNodes?: number[]
) => {
  const rowIdx = displayNodes ? getRowIndex(displayNodes, nodeNumber) : nodeNumber;
  if (rowIdx > -1) {
    const location = rowHeight * (rowIdx + 1);
    const locationOnPage = location % pageHeight;
    const isFirstRow = locationOnPage < rowHeight;
    return isFirstRow;
  }
  return false;
};

export type printProps = {
  projectID: UUID;
  sendAnalytics: SendAnalyticsFn;
  milestoneID?: UUID;
  currentReportView?: ContingencyReportView;
};

export type printFn = (props: printProps) => void;

export function printReport(
  isPrint: boolean,
  loading: boolean,
  hasOpenedPrintDialog: boolean,
  setHasOpenedPrintDialog: (value: boolean) => void,
  print: printFn,
  printProps: printProps
): () => void {
  // when printing show the print dialog when we're done loading
  if (isPrint && !loading && !hasOpenedPrintDialog) {
    setHasOpenedPrintDialog(true);
    window.print();
    return () => {};
  }
  // do nothing / don't override normal behavior if we've already shown the print window
  if (isPrint) {
    return () => {};
  }

  // otherwise open the print view in a new tab when pressing command + p
  const handleKey = (e: KeyboardEvent) => {
    if (isPrintKeys(e)) {
      e.stopImmediatePropagation();
      e.preventDefault();
      print(printProps);
    }
  };
  window.addEventListener('keydown', handleKey);
  return () => window.removeEventListener('keydown', handleKey);
}
