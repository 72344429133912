const MARGIN = 4;

export default function TableChartPlaceholder({ height }: { height: number }) {
  return (
    <div
      className="w-full bg-background-2"
      style={{ marginTop: MARGIN, marginBottom: MARGIN, height: height - MARGIN * 2 }}
    />
  );
}
