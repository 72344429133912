import { FC } from 'react';

import {
  projectCompsAnalyticsEvent,
  projectCompsEventTypes,
} from '../../../analytics/analyticsEventProperties';
import { PcMarkup, UnitCount } from '../../../generated/graphql';
import useSendAnalytics from '../../../hooks/useSendAnalytics';
import { withStyles } from '../../../theme/komodo-mui-theme';
import { Button } from '../../scales';
import { EscalationTargetLocation, ProjectCompSectionVariant } from '../constants/projectCompTypes';
import ProjectCompSectionCosts from '../ProjectCompSection/ProjectCompSectionCosts';
import ProjectCompSectionDisplaySettings from '../ProjectCompSection/ProjectCompSectionDisplaySettings';
import ProjectCompSectionEscalation from '../ProjectCompSection/ProjectCompSectionEscalation';
import ProjectCompSectionMetrics from '../ProjectCompSection/ProjectCompSectionMetrics';
import ProjectCompSectionProjectInformation from '../ProjectCompSection/ProjectCompSectionProjectInformation';
import { useProjectCompsSetInputUpdateFunctions } from '../ProjectCompsSetInputStore/ProjectCompsSetInputUpdaters';
import styles, { NAME_HEIGHT, PADDING } from '../ProjectCompsStyles';

type ProjectCompsSidebarProps = {
  autoEscalatedTo?: { location?: string; time?: string; isLocationTargetFuzzyMatch?: boolean };
  categories: ProjectCompsSet['categories'];
  classes: Classes<typeof styles>;
  costTableColumnInputs: CostTableColumnInputs;
  hasAverage: boolean;
  hasColumnMissingUnit: boolean;
  hasMarkups: boolean;
  hasProjects: boolean;
  markups: PcMarkup[];
  onEditCategorizations: () => void;
  onTargetLocationChange: (location: EscalationTargetLocation) => void;
  selectedUnits: Unit[];
  targetLocation: EscalationTargetLocation;
  unit: Unit;
  unitCounts: UnitCount[];
  units?: Unit[];
};

const ProjectCompsSidebar: FC<ProjectCompsSidebarProps> = ({
  autoEscalatedTo,
  categories,
  classes,
  costTableColumnInputs,
  hasAverage,
  hasColumnMissingUnit,
  hasMarkups,
  hasProjects,
  markups,
  onEditCategorizations,
  onTargetLocationChange,
  selectedUnits,
  targetLocation,
  unit,
  unitCounts,
  units,
}) => {
  const sendAnalytics = useSendAnalytics();
  const { createAverageCompInput } = useProjectCompsSetInputUpdateFunctions();
  const onCreateAverage = () => {
    createAverageCompInput();
    sendAnalytics(
      projectCompsAnalyticsEvent(projectCompsEventTypes.PROJECT_COMPS_CREATE_AVERAGE_CTA)
    );
  };

  const cannotAddAverage = hasAverage || !hasProjects;

  return (
    <div className={classes.labelComp}>
      <div
        className={classes.labelHeader}
        style={{
          height: 200 + NAME_HEIGHT + PADDING,
          paddingTop: PADDING,
          width: 'unset',
        }}
      >
        <div className="flex flex-col gap-2 px-4">
          <Button
            data-cy="select-categorizations-button"
            isDisabled={!hasProjects}
            isFullWidth
            label="Select categorizations"
            onClick={onEditCategorizations}
            type="secondary"
          />
          <Button
            data-cy="add-average-button"
            isDisabled={cannotAddAverage}
            isFullWidth
            label="Start a comparison average"
            onClick={onCreateAverage}
            type="primary"
          />
        </div>
        <ProjectCompSectionDisplaySettings />
      </div>
      <ProjectCompSectionProjectInformation isLabels />
      <ProjectCompSectionMetrics
        hasError={hasColumnMissingUnit}
        selectedUnits={selectedUnits}
        unit={unit}
        unitCounts={unitCounts}
        units={units}
        variant={ProjectCompSectionVariant.SIDEBAR}
      />
      <ProjectCompSectionEscalation
        autoEscalatedTo={autoEscalatedTo}
        onTargetLocationChange={onTargetLocationChange}
        targetLocation={targetLocation}
        variant={ProjectCompSectionVariant.SIDEBAR}
      />
      <ProjectCompSectionCosts
        categories={categories}
        costTableColumnInputs={costTableColumnInputs}
        hasMarkups={hasMarkups}
        hasProjects={hasProjects}
        markups={markups}
        onEditCategorizations={onEditCategorizations}
        selectedUnits={selectedUnits}
        variant={ProjectCompSectionVariant.SIDEBAR}
      />
    </div>
  );
};

export default withStyles(styles)(ProjectCompsSidebar);
