import * as React from 'react';
import { useState } from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  Divider,
  IconButton,
  LinearProgress,
  Typography,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';

import { DesignPhaseType } from '../../../generated/graphql';
import { withStyles } from '../../../theme/komodo-mui-theme';
import { isTextValid } from '../../../utilities/string';
import { getProjectIdFromUrl } from '../../../utilities/url';
import MilestoneDesignPhaseSelect from '../../dragon-scales/MilestoneDesignPhaseSelect/MilestoneDesignPhaseSelect';
import { useDesignPhaseTypes } from '../../Milestone/hooks/useDesignPhaseTypesQuery';
import MilestoneDatePicker from '../../Milestone/MilestoneDatePicker/MilestoneDatePicker';
import MilestoneTitle from '../../Milestone/MilestoneTitle/MilestoneTitle';
import useMemoWrapper from '../../useMemoWrapper';
import DialogsStyles from '../DialogsStyles';

import useCreateMilestone from './hooks/CreateMilestoneHook';

type MilestonePageProps = {
  classes: Classes<typeof DialogsStyles>;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  open: boolean;
  onClose: () => void;
};

const DialogsNewMilestone: React.FC<MilestonePageProps> = ({ classes, open, onClose }) => {
  const projectId = getProjectIdFromUrl();
  const [createMilestone] = useCreateMilestone();
  const designPhaseTypes = useDesignPhaseTypes();

  const [name, setName] = useState<string>('');
  const [date, setDate] = useState<string | null>(null);
  const [designPhase, setDesignPhase] = useState<DesignPhaseType | undefined>(undefined);
  const [loading, setLoading] = useState(false);

  const onCreateMilestoneApi = (
    projectIdProp: UUID,
    nameProp: string,
    date: string,
    designPhaseID: UUID,
    isDraftProp: boolean | false
  ) => {
    const formattedDate = new Date(date).toISOString();
    createMilestone(projectIdProp, nameProp, formattedDate, designPhaseID, isDraftProp, () => {
      setLoading(false);
    });
    // reset input values
    setName('');
    setDate('');
    setDesignPhase(undefined);
    onClose();
  };

  const canEditMilestones = true;

  const isValidName = useMemoWrapper(isTextValid, name);

  const header = (
    <div className={`${classes.titleContainer}`}>
      <Typography data-cy="input-itemOptionTitle" variant="title">
        Create New Milestone
      </Typography>
      <IconButton onClick={onClose} title="Close dialog">
        <Close />
      </IconButton>
    </div>
  );

  const loadingBar = (
    <div className={classes.loading}>
      <LinearProgress hidden={!loading} />
    </div>
  );

  const disabled = !isValidName || !date || !designPhase?.id;

  const content = (
    <div className={`${classes.verticalContainer} ${classes.content} ${classes.paddingBottom}`}>
      <MilestoneTitle
        autoFocus
        disabled={!canEditMilestones}
        name={name}
        onEnterKey={() => {
          if (disabled) return;
          onCreateMilestoneApi(projectId, name, date, designPhase.id, false);
        }}
        onSet={setName}
        onUpdate={setName}
      />

      <MilestoneDatePicker date={date} disabled={!canEditMilestones} onUpdate={setDate} />
      <div className="flex flex-col gap-0.5">
        <div className="type-label">Milestone Design Phase *</div>
        <MilestoneDesignPhaseSelect
          entries={designPhaseTypes}
          onChange={(designPhase: DesignPhaseType) => setDesignPhase(designPhase)}
          value={designPhase?.id}
        />
      </div>
    </div>
  );

  const footer = (
    <DialogActions className={classes.action}>
      <Button
        color="secondary"
        data-cy="create-draft-milestone-button"
        disabled={disabled}
        onClick={() => {
          if (disabled) return;
          onCreateMilestoneApi(projectId, name, date, designPhase.id, true);
        }}
      >
        Create as Draft
      </Button>
      <Button
        color="primary"
        data-cy="create-published-item-button"
        disabled={disabled}
        onClick={() => {
          if (disabled) return;
          onCreateMilestoneApi(projectId, name, date, designPhase.id, false);
        }}
        variant="contained"
      >
        Create and Publish
      </Button>
    </DialogActions>
  );

  return (
    <Dialog
      classes={{
        paper: `${classes.dialogPaper} ${classes.dialogPaperSmall}`,
      }}
      data-cy="dialog-newMilestone"
      maxWidth={false}
      onClose={onClose}
      open={open}
    >
      {header}
      <Divider />
      {loadingBar}
      {content}
      <Divider />
      {footer}
    </Dialog>
  );
};

const StyledDialogsNewMilestone = withStyles(DialogsStyles)(DialogsNewMilestone);

export default StyledDialogsNewMilestone;
