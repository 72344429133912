import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../theme/komodo-mui-theme';

const NAV_HEIGHT = 32;
export default (theme: KomodoTheme) =>
  createStyles({
    root: {
      minHeight: NAV_HEIGHT,
      height: NAV_HEIGHT,
    },
    tab: {
      textDecoration: 'none !important',
      color: theme.palette.primaryGrey,
      minWidth: 140,
      maxWidth: 'none',
      textTransform: 'unset',
    },
    wrapper: {
      display: 'block',
    },
  });
