import { FC } from 'react';
import { Link } from 'react-router-dom';

import { Typography } from '@material-ui/core';

import { withStyles } from '../../../theme/komodo-mui-theme';
import { noPermissionTooltip } from '../../../utilities/string';
import {
  CHART_DEFAULT_BAR_HEIGHT,
  ProjectInfoBarChartData,
  ProjectMap,
} from '../../ExecutiveDashboard/ExecutiveDashboardUtils';
import NormalTooltip from '../../NormalTooltip/NormalTooltip';

import ChartsSingleHorizontalBarGraphSegment from './ChartsSingleHorizontalBarGraphSegment';
import ChartsSingleHorizontalBarGraphStyles from './ChartsSingleHorizontalBarGraphStyles';

const calculateSegmentWidth = (barWidth: number, width: number, cumulativeWidth: number) => {
  return cumulativeWidth + Math.floor(barWidth) > width
    ? width - cumulativeWidth
    : Math.floor(barWidth);
};

type ChartsSingleHorizontalBarGraphProps = {
  barHeight?: number;
  classes: Classes<typeof ChartsSingleHorizontalBarGraphStyles>;
  colors: string[];
  data: ProjectInfoBarChartData;
  formatValue: (value: number) => string;
  projectMap?: ProjectMap;
  maxBarLength: number;
  selected: UUID | null;
  setSelected: (id: string | null) => void;
  labelLocation: number;
};

// generates a single bar chart
// each number in the data array will be represented by a different color
// the lenght of colors must be at least as long as the length of data
const ChartsSingleHorizontalBarGraph: FC<ChartsSingleHorizontalBarGraphProps> = ({
  barHeight = CHART_DEFAULT_BAR_HEIGHT,
  classes,
  colors,
  data,
  formatValue,
  projectMap,
  maxBarLength,
  selected,
  setSelected,
  labelLocation,
}) => {
  // find the pixel width of each segment of the bar
  const { isPlaceholder, project, total, values, placeholderText } = data;
  if (colors.length < values.length) return null;

  const barWidths = values.map((d) => (d * maxBarLength) / total);
  let cumulativeWidth = -1;
  const { hasAccess = false, milestoneName = '', name = '' } = projectMap?.get(project.id) || {};

  const permissionTooltip = (
    <>
      <div>{name}</div>
      <div>{milestoneName}</div>
    </>
  );
  const tooltip = project.id ? noPermissionTooltip(project.name) : '';

  const content = (
    <tr>
      <td className={classes.projectContainer}>
        {hasAccess ? (
          <NormalTooltip title={permissionTooltip}>
            <Link className={classes.project} to={`/${project.id}/project`}>
              {project.abbreviation || project.name}
            </Link>
          </NormalTooltip>
        ) : (
          <NormalTooltip title={tooltip}>
            <Typography>
              <span className={`${classes.projectNoPermission} ${classes.chartLabel}`}>
                {project.abbreviation || project.name}
              </span>
            </Typography>
          </NormalTooltip>
        )}
      </td>
      {barWidths.map((barWidth, i) => {
        // prevent the cumulative length of all bars from
        // extending beyond the total width
        const width = calculateSegmentWidth(barWidth, maxBarLength, cumulativeWidth);
        cumulativeWidth += width + 1; // add one for
        return (
          <ChartsSingleHorizontalBarGraphSegment
            key={`${project.id}-${i.toString()}-bar`}
            barHeight={barHeight}
            color={colors[i]}
            isPlaceholder={isPlaceholder}
            label={formatValue(values[i])}
            labelLocation={labelLocation}
            placeholderText={placeholderText}
            projectID={project.id}
            selected={selected}
            setSelected={setSelected}
            width={width}
          />
        );
      })}
      {!isPlaceholder && (
        <td>
          <Typography className={classes.totalLabel}>{formatValue(total)}</Typography>
        </td>
      )}
    </tr>
  );

  return (
    <span>
      <table
        style={{
          width: maxBarLength,
          height: barHeight,
          borderCollapse: 'collapse',
          display: 'inline',
        }}
      >
        <tbody className={classes.chartLabel}>{content}</tbody>
      </table>
    </span>
  );
};

export default withStyles(ChartsSingleHorizontalBarGraphStyles)(ChartsSingleHorizontalBarGraph);
