import {
  commonCostReportColumnFields,
  costTypeFields,
  detailedItemFields,
  gql,
  itemLinkFields,
} from '../../../api/graphqlFragments';

export const createScenarioInSandboxMutation = gql`
  mutation createScenarioInSandbox(
    $milestoneID: UUID!
    $name: String!
    $description: String
    $color: String!
    $itemOverlays: [ItemOverlay!]!
  ) {
    createScenarioInSandbox(
      milestoneID: $milestoneID
      name: $name
      description: $description
      color: $color
      itemOverlays: $itemOverlays
    )
  }
`;

export const copyScenarioMutation = gql`
  mutation copyScenario($scenarioID: UUID!, $milestoneID: UUID!, $color: String!) {
    copyScenario(scenarioID: $scenarioID, milestoneID: $milestoneID, color: $color)
  }
`;

export const updateScenarioMetadataMutation = gql`
  mutation updateScenarioMetadata(
    $scenarioID: UUID!
    $name: String
    $description: String
    $color: String
    $thumbnailAssetID: UUID
  ) {
    updateScenarioMetadata(
      scenarioID: $scenarioID
      name: $name
      description: $description
      color: $color
      thumbnailAssetID: $thumbnailAssetID
    ) {
      scenarioID
      name
      description
      color
      thumbnailAssetID
    }
  }
`;

export const removeScenarioFromSandboxMutation = gql`
  mutation removeScenarioFromSandbox($scenarioID: UUID!, $milestoneID: UUID!) {
    removeScenarioFromSandbox(scenarioID: $scenarioID, milestoneID: $milestoneID)
  }
`;

export const addScenarioToSandboxMutation = gql`
  mutation addScenarioToSandbox($scenarioID: UUID!, $milestoneID: UUID!) {
    addScenarioToSandbox(scenarioID: $scenarioID, milestoneID: $milestoneID)
  }
`;

export const updateSandboxMutation = gql`
  mutation updateSandbox($scenarioIDs: [UUID!]!, $milestoneID: UUID!) {
    updateSandbox(scenarioIDs: $scenarioIDs, milestoneID: $milestoneID)
  }
`;

export const deleteScenarioMutation = gql`
  mutation deleteScenario($scenarioID: UUID!) {
    deleteScenario(scenarioID: $scenarioID)
  }
`;

const itemInScenarioFields = gql`
  fragment itemInScenarioFields on ItemsListItem {
    cost {
      ...costTypeFields
    }
    id
    creator {
      id
      name
      picture
      status
    }
    createdAt
    isOverlay
    overlay {
      baseStatus
    }
    name
    number
    parentID
    status
    options {
      ...itemLinkFields
    }
    itemType
    visibility
  }
  ${costTypeFields}
  ${itemLinkFields}
`;

export const loadOrCreateScenarioSandboxByMilestoneIDQuery = gql`
  query loadOrCreateScenarioSandboxByMilestoneID(
    $milestoneID: UUID!
    $costMode: CostMode!
    $scenariosSortBy: ScenariosSortBy!
  ) {
    loadOrCreateScenarioSandboxByMilestoneID(
      milestoneID: $milestoneID
      costMode: $costMode
      scenariosSortBy: $scenariosSortBy
    ) {
      id
      milestoneID
      scenarios {
        scenarioID
        name
        description
        color
        thumbnailAssetID
        items {
          ...itemInScenarioFields
        }
        costReports {
          costReportColumns {
            ...commonCostReportColumnFields
          }
        }
        createdBy
      }
      milestone {
        milestoneID
        items {
          ...itemInScenarioFields
        }
        costReports {
          costReportColumns {
            ...commonCostReportColumnFields
          }
        }
      }
    }
  }
  ${itemInScenarioFields}
  ${commonCostReportColumnFields}
`;

export const removeItemFromScenariosMutation = gql`
  mutation RemoveItemFromScenarios($scenarioIDs: [UUID!]!, $itemID: UUID!) {
    removeItemFromScenarios(scenarioIDs: $scenarioIDs, itemID: $itemID)
  }
`;

export const applyScenarioMutation = gql`
  mutation applyScenario($scenarioID: UUID!, $milestoneID: UUID!, $costMode: CostMode!) {
    applyScenario(scenarioID: $scenarioID, milestoneID: $milestoneID, costMode: $costMode)
  }
`;

export const createScenarioItemMutation = gql`
  mutation createScenarioItem(
    $itemInput: ScenarioItemCreationInput!
    $scenarioID: UUID!
    $projectID: UUID!
    $milestoneID: UUID!
    $costMode: CostMode!
  ) {
    createScenarioItem(
      itemInput: $itemInput
      scenarioID: $scenarioID
      projectID: $projectID
      milestoneID: $milestoneID
      costMode: $costMode
    ) {
      ...detailedItemFields
    }
  }
  ${detailedItemFields}
`;

export const deleteScenarioItemMutation = gql`
  mutation deleteScenarioItem($itemID: UUID!, $milestoneID: UUID!) {
    deleteScenarioItem(itemID: $itemID, milestoneID: $milestoneID)
  }
`;

export const canApplyScenarioQuery = gql`
  query canApplyScenario(
    $scenarioID: UUID!
    $projectID: UUID!
    $milestoneID: UUID!
    $costMode: CostMode!
  ) {
    canApplyScenario(
      scenarioID: $scenarioID
      projectID: $projectID
      milestoneID: $milestoneID
      costMode: $costMode
    ) {
      hasApplyPermission
      hasChanges
    }
  }
`;
