import SvgIcon from '@material-ui/core/SvgIcon';

import { IconProps } from '.';

export const ProjectIcon = (props: IconProps) => (
  <SvgIcon {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <svg fill="none" height="20" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg">
      <rect fill="#C2C9CB" height="16" width="16" x="2" y="2" />
      <path
        d="M18.355 4.26411C18.259 3.90678 17.9096 3.61078 17.4323 3.46145C17.4616 3.38145 17.4776 3.29878 17.4776 3.20811C17.4776 2.79211 17.1416 2.45611 16.7256 2.45611C16.5576 2.45611 16.403 2.51478 16.275 2.60811C15.9203 2.22411 15.4163 1.98145 14.8536 1.98145C14.091 1.98145 13.4323 2.42411 13.1176 3.06678C13.0136 3.03478 12.9043 3.01345 12.7896 3.01345C12.147 3.01345 11.627 3.53345 11.627 4.17611C11.627 4.20545 11.6323 4.23211 11.635 4.26145H18.3523L18.355 4.26411Z"
        fill="white"
      />
      <path d="M14.3228 19.9997V3.26367L5.28809 7.02634V19.9997H14.3228Z" fill="#E7EAEF" />
      <path
        d="M5.90427 5.73619C5.88294 5.32286 5.5576 4.99219 5.14693 4.96552C5.04027 4.45086 4.5816 4.06152 4.03494 4.06152C3.40827 4.06152 2.89893 4.57086 2.89893 5.19752C2.89893 5.20552 2.89893 5.21352 2.89893 5.22152C2.8856 5.22152 2.87227 5.21619 2.85627 5.21619C2.52294 5.21619 2.24027 5.43486 2.1416 5.73352H5.90427V5.73619Z"
        fill="white"
      />
      <path
        d="M9.62943 5.22375C9.55476 5.22375 9.49609 5.16509 9.49609 5.09042V2.54642C9.49609 2.47175 9.55476 2.41309 9.62943 2.41309C9.70409 2.41309 9.76276 2.47175 9.76276 2.54642V5.09042C9.76276 5.16509 9.70409 5.22375 9.62943 5.22375Z"
        fill="white"
      />
      <path d="M16.5815 7.17285H8.95215V19.9995H16.5815V7.17285Z" fill="white" />
      <path
        d="M8.76224 5.5622C8.68757 5.5622 8.62891 5.50353 8.62891 5.42887V3.7222C8.62891 3.64753 8.68757 3.58887 8.76224 3.58887C8.83691 3.58887 8.89557 3.64753 8.89557 3.7222V5.42887C8.89557 5.50353 8.83691 5.5622 8.76224 5.5622Z"
        fill="white"
      />
      <path
        d="M10.0559 18.2697C9.90392 18.2697 9.78125 18.147 9.78125 17.995V8.35767C9.78125 8.20567 9.90392 8.08301 10.0559 8.08301H12.8666C13.0186 8.08301 13.1412 8.20567 13.1412 8.35767C13.1412 8.50967 13.0186 8.63234 12.8666 8.63234H10.3306V17.995C10.3306 18.147 10.2079 18.2697 10.0559 18.2697Z"
        fill="#DDE2E9"
      />
      <path
        d="M6.54154 8.69111C6.50687 8.69111 6.4722 8.67777 6.4482 8.65111C6.39487 8.59777 6.39487 8.51511 6.4482 8.46177L7.1522 7.75777C7.20554 7.70444 7.2882 7.70444 7.34154 7.75777C7.39487 7.81111 7.39487 7.89377 7.34154 7.94711L6.63754 8.65111C6.61087 8.67777 6.5762 8.69111 6.5442 8.69111H6.54154Z"
        fill="white"
      />
      <path
        d="M6.39212 10.2482C6.35746 10.2482 6.32279 10.2349 6.29879 10.2082C6.24546 10.1549 6.24546 10.0722 6.29879 10.0189L7.45346 8.86422C7.50679 8.81089 7.58946 8.81089 7.64279 8.86422C7.69612 8.91755 7.69612 9.00022 7.64279 9.05355L6.48812 10.2082C6.46146 10.2349 6.42679 10.2482 6.39479 10.2482H6.39212Z"
        fill="white"
      />
      <path
        d="M6.49076 15.5683C6.45609 15.5683 6.42142 15.555 6.39742 15.5283C6.34409 15.475 6.34409 15.3923 6.39742 15.339L7.65342 14.083C7.70676 14.0296 7.78942 14.0296 7.84276 14.083C7.89609 14.1363 7.89609 14.219 7.84276 14.2723L6.58676 15.5283C6.56009 15.555 6.52542 15.5683 6.49342 15.5683H6.49076Z"
        fill="white"
      />
      <path
        d="M11.4612 10.5386C11.3092 10.5386 11.1865 10.416 11.1865 10.264V9.58131C11.1865 9.42931 11.3092 9.30664 11.4612 9.30664C11.6132 9.30664 11.7359 9.42931 11.7359 9.58131V10.264C11.7359 10.416 11.6132 10.5386 11.4612 10.5386Z"
        fill="#C2C9CB"
      />
      <path
        d="M13.219 10.5386C13.067 10.5386 12.9443 10.416 12.9443 10.264V9.58131C12.9443 9.42931 13.067 9.30664 13.219 9.30664C13.371 9.30664 13.4937 9.42931 13.4937 9.58131V10.264C13.4937 10.416 13.371 10.5386 13.219 10.5386Z"
        fill="#C2C9CB"
      />
      <path
        d="M14.9758 10.5386C14.8238 10.5386 14.7012 10.416 14.7012 10.264V9.58131C14.7012 9.42931 14.8238 9.30664 14.9758 9.30664C15.1278 9.30664 15.2505 9.42931 15.2505 9.58131V10.264C15.2505 10.416 15.1278 10.5386 14.9758 10.5386Z"
        fill="#C2C9CB"
      />
      <path
        d="M11.4612 12.6822C11.3092 12.6822 11.1865 12.5595 11.1865 12.4075V11.7249C11.1865 11.5729 11.3092 11.4502 11.4612 11.4502C11.6132 11.4502 11.7359 11.5729 11.7359 11.7249V12.4075C11.7359 12.5595 11.6132 12.6822 11.4612 12.6822Z"
        fill="#C2C9CB"
      />
      <path
        d="M13.219 12.6822C13.067 12.6822 12.9443 12.5595 12.9443 12.4075V11.7249C12.9443 11.5729 13.067 11.4502 13.219 11.4502C13.371 11.4502 13.4937 11.5729 13.4937 11.7249V12.4075C13.4937 12.5595 13.371 12.6822 13.219 12.6822Z"
        fill="#C2C9CB"
      />
      <path
        d="M14.9758 12.6822C14.8238 12.6822 14.7012 12.5595 14.7012 12.4075V11.7249C14.7012 11.5729 14.8238 11.4502 14.9758 11.4502C15.1278 11.4502 15.2505 11.5729 15.2505 11.7249V12.4075C15.2505 12.5595 15.1278 12.6822 14.9758 12.6822Z"
        fill="#C2C9CB"
      />
      <path
        d="M11.4612 14.8297C11.3092 14.8297 11.1865 14.707 11.1865 14.555V13.8723C11.1865 13.7203 11.3092 13.5977 11.4612 13.5977C11.6132 13.5977 11.7359 13.7203 11.7359 13.8723V14.555C11.7359 14.707 11.6132 14.8297 11.4612 14.8297Z"
        fill="#C2C9CB"
      />
      <path
        d="M13.219 14.8297C13.067 14.8297 12.9443 14.707 12.9443 14.555V13.8723C12.9443 13.7203 13.067 13.5977 13.219 13.5977C13.371 13.5977 13.4937 13.7203 13.4937 13.8723V14.555C13.4937 14.707 13.371 14.8297 13.219 14.8297Z"
        fill="#C2C9CB"
      />
      <path
        d="M14.9758 14.8297C14.8238 14.8297 14.7012 14.707 14.7012 14.555V13.8723C14.7012 13.7203 14.8238 13.5977 14.9758 13.5977C15.1278 13.5977 15.2505 13.7203 15.2505 13.8723V14.555C15.2505 14.707 15.1278 14.8297 14.9758 14.8297Z"
        fill="#C2C9CB"
      />
      <path
        d="M11.4612 16.9732C11.3092 16.9732 11.1865 16.8505 11.1865 16.6985V16.0159C11.1865 15.8639 11.3092 15.7412 11.4612 15.7412C11.6132 15.7412 11.7359 15.8639 11.7359 16.0159V16.6985C11.7359 16.8505 11.6132 16.9732 11.4612 16.9732Z"
        fill="#C2C9CB"
      />
      <path
        d="M13.219 16.9732C13.067 16.9732 12.9443 16.8505 12.9443 16.6985V16.0159C12.9443 15.8639 13.067 15.7412 13.219 15.7412C13.371 15.7412 13.4937 15.8639 13.4937 16.0159V16.6985C13.4937 16.8505 13.371 16.9732 13.219 16.9732Z"
        fill="#C2C9CB"
      />
      <path
        d="M14.9758 16.9732C14.8238 16.9732 14.7012 16.8505 14.7012 16.6985V16.0159C14.7012 15.8639 14.8238 15.7412 14.9758 15.7412C15.1278 15.7412 15.2505 15.8639 15.2505 16.0159V16.6985C15.2505 16.8505 15.1278 16.9732 14.9758 16.9732Z"
        fill="#C2C9CB"
      />
      <path
        d="M11.4612 19.1197C11.3092 19.1197 11.1865 18.997 11.1865 18.845V18.1624C11.1865 18.0104 11.3092 17.8877 11.4612 17.8877C11.6132 17.8877 11.7359 18.0104 11.7359 18.1624V18.845C11.7359 18.997 11.6132 19.1197 11.4612 19.1197Z"
        fill="#C2C9CB"
      />
      <path
        d="M13.219 19.1197C13.067 19.1197 12.9443 18.997 12.9443 18.845V18.1624C12.9443 18.0104 13.067 17.8877 13.219 17.8877C13.371 17.8877 13.4937 18.0104 13.4937 18.1624V18.845C13.4937 18.997 13.371 19.1197 13.219 19.1197Z"
        fill="#C2C9CB"
      />
      <path
        d="M14.9758 19.1197C14.8238 19.1197 14.7012 18.997 14.7012 18.845V18.1624C14.7012 18.0104 14.8238 17.8877 14.9758 17.8877C15.1278 17.8877 15.2505 18.0104 15.2505 18.1624V18.845C15.2505 18.997 15.1278 19.1197 14.9758 19.1197Z"
        fill="#C2C9CB"
      />
      <path d="M5.33305 11.9336H3.51172V20.0003H5.33305V11.9336Z" fill="#DDE2E9" />
      <path
        d="M10.3306 20V19.2454C10.3306 19.0934 10.2079 18.9707 10.0559 18.9707C9.90392 18.9707 9.78125 19.0934 9.78125 19.2454V20H10.3306Z"
        fill="#DDE2E9"
      />
      <path
        d="M9.22414 20.0004V7.47512H16.5788C16.7308 7.47512 16.8535 7.35245 16.8535 7.20045C16.8535 7.04845 16.7308 6.92578 16.5788 6.92578H8.94947C8.79747 6.92578 8.6748 7.04845 8.6748 7.20045V20.0004H9.22414Z"
        fill="#C2C9CB"
      />
      <path
        d="M5.42168 20.0004V7.08844L14.3737 3.35777C14.4403 3.32844 14.475 3.2511 14.4457 3.18444C14.4163 3.1151 14.339 3.0831 14.2723 3.11244L5.23501 6.8751C5.18434 6.89644 5.15234 6.94444 5.15234 6.99777V20.0004H5.41901H5.42168Z"
        fill="white"
      />
      <path
        d="M16.3816 19.9651H16.9149V7.1731C16.9149 7.02643 16.7949 6.90643 16.6483 6.90643H14.6056V4.9571C14.6056 4.81043 14.4856 4.69043 14.3389 4.69043C14.1923 4.69043 14.0723 4.81043 14.0723 4.9571V7.1731C14.0723 7.31976 14.1923 7.43976 14.3389 7.43976H16.3816V19.9651Z"
        fill="#9B9B9B"
      />
    </svg>
  </SvgIcon>
);
