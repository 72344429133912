import { FC } from 'react';

import { PENDING } from '../../../constants';
import { compareCost } from '../../../utilities/compare';
import { parseDate } from '../../../utilities/dates';
import { getItemStatusLabel } from '../../../utilities/item-status';
import { renderCostString } from '../../CostReport/CostReportUtils';
import { getItemDescriptor, styleItemNumber } from '../../Items/ItemsListItem/ItemsListItemUtils';
import { getStatusIsAcceptedIncorporated } from '../../Items/ItemsUtils';
import ItemsIcons from '../../ItemsList/ItemsIcons/ItemsIcons';
import { SMALL } from '../../ItemsList/ItemsIcons/ItemsIconsMap';
import { isScheduleSettingsDisabled } from '../../ProjectProperties/ProjectScheduleImpact/ProjectScheduleImpactSettings';
import { getScheduleImpactText } from '../../shared-widgets/ScheduleImpact';

type PrintItemsListOptionProps = {
  shouldDisplayCosts: boolean;
  isExact?: boolean;
  itemsAssigneeMap: Map<string, UserLink | undefined | null>;
  itemsTreeOption: ItemsTreeOption;
  itemsListItemsMap: Map<UUID, ItemsListItem>;
};

const PrintItemsListOption: FC<PrintItemsListOptionProps> = ({
  shouldDisplayCosts,
  isExact = false,
  itemsAssigneeMap,
  itemsTreeOption,
  itemsListItemsMap,
}) => {
  const isScheduleImpactEnabled = !isScheduleSettingsDisabled();

  if (!itemsTreeOption) {
    return <></>;
  }

  const { cost: itemsTreeOptionCost, itemLikeID } = itemsTreeOption;
  const itemsListItem = itemsListItemsMap.get(itemLikeID);
  if (!itemsListItem) return <></>;
  const {
    assigneeID,
    cost: itemsListItemCost,
    dueDate,
    name,
    number,
    parent,
    status,
  } = itemsListItem;
  const assignee = itemsAssigneeMap.get(itemLikeID);

  // parentIsChosen is used to display option status icons
  const parentIsChosen = getStatusIsAcceptedIncorporated(parent?.status);

  // Options have an extra bit of display logic for "Not Chosen" case
  const optionStatus =
    getStatusIsAcceptedIncorporated(parent?.status) && status === PENDING
      ? 'Not Chosen'
      : getItemStatusLabel(status);

  // leftMainText dispalys main item information in the top left
  const leftMainText = `${styleItemNumber(number)} (${optionStatus}) ${name}`;

  // leftSubText displays assignee/due date information in the bottom left
  const hasAssignee = !!assigneeID;
  const hasDueDate = dueDate;
  const leftSubText = (
    <>
      {hasAssignee && `Assignee: ${assignee?.name}`}
      {hasAssignee && hasDueDate && <div className="print-spacer" />}
      {hasDueDate && `Due Date: ${parseDate(dueDate)}`}
    </>
  );

  const totalCost = itemsListItemCost;
  const categorizedCost = itemsTreeOptionCost;
  const hasDifferentCategorizedCost = categorizedCost && !compareCost(categorizedCost, totalCost);

  // rightMainText displays cost impact in the top right
  const rightMainText: string = renderCostString({
    cost: hasDifferentCategorizedCost ? categorizedCost : totalCost,
    isExact,
    isWide: true,
  });

  // rightSubText displays option information in the bottom right

  let rightSubText = `Option in ${getItemDescriptor(parent ?? null)}`;

  if (shouldDisplayCosts && hasDifferentCategorizedCost) {
    rightSubText += `, across all categories: ${renderCostString({
      cost: totalCost,
      isExact,
      isWide: true,
    })}`;
  }

  const [numberText, criticalPathText] = getScheduleImpactText(itemsListItem.scheduleImpact, {
    critical: 'critical path',
    noncritical: '',
  });

  return (
    <div className={isScheduleImpactEnabled ? 'print-item-row' : 'print-item-row-2'}>
      <div className="print-item-row-details">
        <div className="print-item-row-details-top">
          <ItemsIcons notChosen={parentIsChosen} status={status} variant={SMALL} />
          <div className="print-item-row-details-top-text">{leftMainText}</div>
        </div>
        <div className="print-item-row-bottom-text">{leftSubText}</div>
      </div>
      <div className="print-item-row-cost print-item-row-col">
        {shouldDisplayCosts && <div className="print-no-wrap-text">{rightMainText}</div>}
        <div className="print-item-row-bottom-text print-item-row-bottom-text-right print-right-align">
          {rightSubText}
        </div>
      </div>
      {isScheduleImpactEnabled && (
        <div className="print-item-row-cost print-item-row-col">
          <div className="print-no-wrap-text print-lowercase">{numberText}</div>
          <div className="print-item-row-bottom-text print-item-row-bottom-text-right print-right-align print-red">
            {criticalPathText}
          </div>
        </div>
      )}
    </div>
  );
};

export default PrintItemsListOption;
