import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../../../theme/komodo-mui-theme';

export const BUTTON_SIZE = 24;
const NAVIGATION_MARGIN = 16;
const NAVIGATION_PADDING = 2;

export default (theme: KomodoTheme) =>
  createStyles({
    navigationButton: {
      height: BUTTON_SIZE,
      opacity: 1,
      width: BUTTON_SIZE,
    },
    navigationButtonContainer: {
      '&:hover': {
        backgroundColor: theme.palette.hoverSemitransparentJoinGrey200,
      },
      height: 28,
      margin: 6,
      width: 28,
    },
    fitPDFButtonPadding: {
      marginTop: 8,
      marginBottom: 8,
    },
    navigationContainer: {
      alignItems: 'center',
      background: theme.palette.backgroundWhite,
      borderRadius: theme.border.radius,
      top: 0,
      display: 'flex',
      filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
      flexFlow: 'column',
      justifyContent: 'space-between',
      marginTop: NAVIGATION_MARGIN,
      marginRight: NAVIGATION_MARGIN,
      padding: NAVIGATION_PADDING,
      position: 'absolute',
      right: 0,
      zIndex: 1000,
    },
    navigationContainerHide: {
      opacity: 0,
      ...theme.opacityFadeTransition,
    },
    navigationContainerShow: {
      opacity: 1,
      ...theme.opacityFadeTransition,
    },
    navigationDivider: {
      width: BUTTON_SIZE,
      height: NAVIGATION_PADDING,
      backgroundColor: theme.palette.backgroundGrey,
    },
  });
