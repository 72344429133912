import { ApolloError, FetchResult, useMutation } from '@apollo/client';

import { REFETCH_REMOVE_CATEGORIZATION } from '../../../api/refetchSets';
import {
  CategorizationImportMetadata,
  CategoryMapInput,
  RemoveCategorizationsMutation,
  RemoveCategorizationsMutationVariables,
  RemoveCategorizationsPayload,
  ReplaceCategoriesMutation,
  ReplaceCategoriesMutationVariables,
  UpsertCategorizationImportMetadataMutation,
  UpsertCategorizationImportMetadataMutationVariables,
} from '../../../generated/graphql';

import {
  removeCategorizationsMutation,
  replaceCategoriesMutation,
  upsertCategorizationImportMetadataMutation,
} from './queries';

export const useRemoveCategorizations = () => {
  const [mutationFunc] = useMutation<
    RemoveCategorizationsMutation,
    RemoveCategorizationsMutationVariables
  >(removeCategorizationsMutation);
  return (
    projectID: UUID,
    categorizationIDs: UUID[],
    onSuccess?: (payload: RemoveCategorizationsPayload) => void,
    onFailure?: (e: ApolloError) => void
  ) =>
    mutationFunc({
      variables: { projectID, categorizationIDs },
      refetchQueries: REFETCH_REMOVE_CATEGORIZATION,
    })
      .then((result) => {
        const removeCategorizations = result?.data?.removeCategorizations;
        if (removeCategorizations) onSuccess?.(removeCategorizations);
      })
      .catch((e) => {
        onFailure?.(e);
      });
};

export const useReplaceCategories = () => {
  const [mutationFunc, mutationResults] = useMutation<
    ReplaceCategoriesMutation,
    ReplaceCategoriesMutationVariables
  >(replaceCategoriesMutation, {
    fetchPolicy: 'network-only',
  });
  const replaceCategories = (
    variables: {
      categorizationID: UUID;
      categories: CategoryMapInput[];
      metadata?: CategorizationImportMetadata;
      projectID?: UUID;
      assetID?: UUID;
    },
    onSuccess?: (res: FetchResult<ReplaceCategoriesMutation>) => void,
    onError?: (e: ApolloError) => void
  ) =>
    mutationFunc({
      variables,
    }).then(
      (result) => onSuccess?.(result),
      (error: ApolloError) => onError?.(error)
    );
  return [replaceCategories, mutationResults] as const;
};

export const useUpsertCategorizationImportMetadata = () => {
  const [mutationFunc, mutationResults] = useMutation<
    UpsertCategorizationImportMetadataMutation,
    UpsertCategorizationImportMetadataMutationVariables
  >(upsertCategorizationImportMetadataMutation);

  const upsertCategorizationImportMetadata = (
    variables: UpsertCategorizationImportMetadataMutationVariables,
    onSuccess?: (res: FetchResult<UpsertCategorizationImportMetadataMutation>) => void,
    onError?: (e: ApolloError) => void
  ) =>
    mutationFunc({
      variables,
    }).then(
      (result) => onSuccess?.(result),
      (error: ApolloError) => onError?.(error)
    );
  return [upsertCategorizationImportMetadata, mutationResults] as const;
};
