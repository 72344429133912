import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { Divider } from '@material-ui/core';

import { NULL_ID } from '../../../../constants';
import { SearchResultType } from '../../../../generated/graphql';
import { useCompanyUserQuery } from '../../../../hooks/useCompanyUserQuery';
import { RouteKeys } from '../../../../routes/paths';
import { generateSharedPath } from '../../../../utilities/routes/links';
import { getSubtree, makeTree } from '../../../../utilities/utilities';
import useOrganizationsQuery from '../../../CompanyTab/CompanyTabOrganizations/hooks/useOrganizationsQuery';
import AdvancedFilters from '../../../frame/AdvancedFiltersSidebar/AdvancedFilters';
import ProjectsFilters, {
  FilterOptions,
} from '../../../frame/AdvancedFiltersSidebar/ProjectsFilters';
import { activeFiltersCountProjects } from '../../../HomeTab/Search/projects/activeFilterCountProjects';
import SearchHeader from '../../../HomeTab/Search/SearchHeader';
import useSearchProjectsFilterOptionsQuery from '../../../ProjectsList/hooks/useSearchProjectsFilterOptionsQuery';

import ForecastingProjectsList from './ForecastingProjectsList';
import { useForecastingProjectsQuery } from './hooks/useForecastingProjectsQuery';
import useForecastingProjectsQueryParams from './hooks/useForecastingProjectsQueryParams';

type Props = {
  isFilterMenuOpen: boolean;
  onToggleFilterMenu: (isOpen: boolean) => void;
};

const ForecastingProjectsListData = (props: Props) => {
  const filterOptions = useFilterOptions();
  const params = useForecastingProjectsQueryParams();
  const { data, fetchMore, loading, previousData } = useForecastingProjectsQuery(params.variables);
  // Navigate to new Project Comp report
  const navigate = useNavigate();
  const onCreateComparisonReport = useCallback(
    (projectIDs: string[]) => {
      navigate(generateSharedPath(RouteKeys.FORECASTING_PROJECT_COMPS), {
        state: { projectIDs }, // parsed in `useHandleIncomingLink()`
      });
    },
    [navigate]
  );

  const activeFiltersCount = useMemo(() => {
    return activeFiltersCountProjects(params.variables.filters);
  }, [params.variables.filters]);

  return (
    <>
      <SearchHeader
        activeFiltersCount={activeFiltersCount}
        isFilterMenuOpen={props.isFilterMenuOpen}
        isViewingPrograms={false}
        onChangeSearch={params.onChangeSearch}
        onToggleFilterMenu={props.onToggleFilterMenu}
        search={params.variables.search}
        searchPlaceholder="Search projects"
      />
      <Divider />
      <div className="flex overflow-hidden">
        {props.isFilterMenuOpen && (
          <AdvancedFilters
            hasActiveFilters={activeFiltersCount > 0}
            onCloseFiltersPanel={props.onToggleFilterMenu}
            onResetFilters={params.onResetFilters}
          >
            <ProjectsFilters
              filterOptions={filterOptions}
              filterValues={params.variables.filters}
              isExpanded
              onChangeFilters={params.onChangeFilters}
            />
          </AdvancedFilters>
        )}
        <div className="overflow-x-auto">
          <ForecastingProjectsList
            isLoading={loading}
            onCreateComparisonReport={onCreateComparisonReport}
            onFetchMore={fetchMore}
            projects={data?.forecastingProjects ?? previousData?.forecastingProjects ?? []}
            searchText={params.variables.search}
            sortManager={params.sortManager}
          />
        </div>
      </div>
    </>
  );
};

const useFilterOptions = (): FilterOptions => {
  const { data: filterOptionsData } = useSearchProjectsFilterOptionsQuery(SearchResultType.ALL);
  const { data: companyUserData } = useCompanyUserQuery();
  const companyID = companyUserData?.companyUser?.company?.id;
  const organizationsQueryResult = useOrganizationsQuery(companyID);
  return useMemo(() => {
    const options = filterOptionsData?.searchProjectsFilterOptions;
    if (!options)
      return {
        companies: [],
        locations: [],
        milestoneDesignPhases: [],
        organizationNodes: [],
        statuses: [],
        types: [],
        projectTypes: [],
        projectLeads: [],
      };
    const orgs = organizationsQueryResult.data?.organizations ?? [];
    const orgTrees = orgs.flatMap((org) =>
      makeTree(org.nodes.map((node) => ({ ...node, label: node.name })))
    );
    const subtree = getSubtree(
      orgTrees,
      options.organizationNodes.map((node) => node.id)
    );
    const filterOptions = {
      companies: options.companies.map((id) => ({ id, label: id })),
      locations: options.locations.map((id) => ({ id, label: id })),
      milestoneDesignPhases: options.milestoneDesignPhases.map((id) => ({ id, label: id })),
      organizationNodes: subtree,
      projectLeads: [
        { id: NULL_ID, label: 'Unassigned' },
        ...options.projectLeads.map(({ id, name }) => ({ id, label: name })),
      ],
      statuses: options.statuses.map((id) => ({ id, label: id })),
      types: options.types.map((id) => ({ id, label: id })),
      projectTypes: [],
    };

    filterOptions.types = (options.projectTypes || []).map((type) => ({
      ...type,
      label: type.name,
    }));

    return filterOptions;
  }, [filterOptionsData?.searchProjectsFilterOptions, organizationsQueryResult]);
};

export default ForecastingProjectsListData;
