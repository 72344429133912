import { createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
const styles = (theme: any) =>
  createStyles({
    root: {},
    alignCloseButton: {
      position: 'absolute',
      top: 12,
      right: 12,
      margin: 8,
      width: 32,
      height: 32,
    },
    card: {
      marginTop: 8,
      marginBottom: 24,
      overflow: 'visible',
      backgroundColor: 'white',
      border: theme.border.line,
    },
    createCategorization: {
      padding: '8px 0px',
    },
    listItem: {
      borderTop: theme.border.line,
      justifyContent: 'space-between',
      height: 62,
    },
    editable: {
      cursor: 'pointer',
    },
    disabled: {
      color: theme.palette.disabled,
    },
    hint: {
      paddingTop: 16,
    },
    button: {
      backgroundColor: theme.palette.joinPrimary,
      color: 'white',
      borderRadius: 20,
    },
    content: {
      padding: '12px 16px',
      flex: '0 0 auto',
    },
    shrink: {
      flex: '0 1 auto',
      overflowY: 'auto',
    },
    name: {
      justifyContent: 'flex-start',
      display: 'inline-flex',
      alignItems: 'center',
    },
    categoryPanel: {
      paddingTop: '24px',
    },
    tablesContainer: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: 24,
    },
  });

export default styles;
