import { FC } from 'react';

import { Typography } from '@material-ui/core';

import { withStyles } from '../../../theme/komodo-mui-theme';

import {
  GroupByOption,
  MultiGroupOrderOption,
  useMilestoneQuantities,
} from './MultiGroupOrderCategorizationUtils';
import MultiGroupOrderSuggestion from './MultiGroupOrderSuggestion';
import MultiGroupOrderStyles from './MultiGroupStyles';

type MultiGroupOrderSuggestionsProps = {
  classes: Classes<typeof MultiGroupOrderStyles>;
  handleSet: (toSet: GroupByOption[]) => void;
  options: MultiGroupOrderOption[];
};

const MultiGroupOrderSuggestions: FC<MultiGroupOrderSuggestionsProps> = ({
  classes,
  handleSet,
  options,
}) => {
  // Data
  const quantities = useMilestoneQuantities();
  const breakdownQuantities = quantities.filter((q) => q.hasBreakdown);
  const hasSuggestions = breakdownQuantities.length > 0;
  if (!hasSuggestions) return null;

  // Components
  const suggestedGroupings = breakdownQuantities.map((quantity: Quantity) => (
    <MultiGroupOrderSuggestion
      key={quantity.id}
      handleSet={handleSet}
      options={options}
      quantity={quantity}
    />
  ));

  return (
    <div className={classes.suggestions}>
      <div className={classes.suggestionsHeader}>
        <Typography className={classes.unitHeaderText}>Metrics Categorizations</Typography>
      </div>
      {suggestedGroupings}
    </div>
  );
};

export default withStyles(MultiGroupOrderStyles)(MultiGroupOrderSuggestions);
