type Props = {
  detail: string;
  icon: JSX.Element;
  title: string;
};

export default function InAppNotificationsPlaceholder({ detail, icon, title }: Props) {
  return (
    <div className="flex items-start gap-3 p-4">
      <div className="icon-md">{icon}</div>
      <div className="flex flex-col gap-1">
        <div className="type-body1" data-cy="IAN-Placeholder-Title">
          {title}
        </div>
        <div className="type-body3">{detail}</div>
      </div>
      <div />
    </div>
  );
}
