import { gql } from '../../../../api/graphqlFragments';

const projectDateVisibility = gql`
  fragment projectDateVisibility on ProjectDateVisibility {
    milestoneDates {
      milestoneID
      dates {
        date
        visible
      }
    }
  }
`;

export const toggleAllMilestonesTrendDateVisibilityMutation = gql`
  mutation toggleAllMilestonesTrendDateVisibility(
    $projectID: UUID!
    $intervalType: IntervalType!
    $toggles: [DateVisibilityToggle!]!
  ) {
    toggleAllMilestonesTrendDateVisibility(
      projectID: $projectID
      intervalType: $intervalType
      toggles: $toggles
    ) {
      ...projectDateVisibility
    }
  }
  ${projectDateVisibility}
`;
