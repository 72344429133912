import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../theme/komodo-mui-theme';

const styles = (theme: KomodoTheme) =>
  createStyles({
    caption: {
      margin: 0,
    },
    cardHeaderTypography: {
      display: 'inline',
      paddingTop: 4,
    },
    categories: {
      '@media print': {
        flex: '1 0 auto',
      },
    },
    costSummaryImpact: {
      display: 'inline-block',
      marginLeft: '-12px',
      maxWidth: '10px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    currentMilestone: {
      textAlign: 'left',
      wordBreak: 'break-word',
      padding: `9px 6px 8px ${theme.spacing.selectPaddingLeft}px`,
    },
    currentMilestoneEdit: {
      '&:hover': {
        backgroundColor: theme.palette.backgroundGrey,
        borderRadius: 100,
      },
      alignItems: 'center',
      display: 'flex',
      height: 24,
      width: 24,
    },
    currentMilestoneEditIcon: {
      '@media print': {
        display: 'none',
      },
      fontSize: 16,
      margin: 'auto',
    },
    currentMilestoneSelector: {
      alignItems: 'center',
      borderRadius: theme.border.radius,
      '@media print': {
        border: 'none',
        padding: 0,
      },
      alignSelf: 'flex-start',
      background: 'none',
      backgroundColor: theme.palette.backgroundWhite,
      border: theme.border.line,
      cursor: 'pointer',
      display: 'flex',
      justifyContent: 'space-between',
      margin: 0,
      padding: '0 5px 0 0',
      width: '100%',
    },
    currentMilestoneSelectorDisabled: {
      border: 0,
      padding: 8,
      cursor: 'unset',
      backgroundColor: 'unset',
    },
    currentMilestoneViewOnly: {
      color: theme.palette.primaryGrey,
      flexWrap: 'nowrap',
      maxWidth: 180,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    date: {
      '@media print': {
        width: 140,
      },
      '& svg': {
        '@media print': {
          display: 'none',
        },
      },
    },
    disabledPicker: {
      paddingTop: '6px',
    },
    error: {
      display: 'none',
    },

    field: {
      marginBottom: 8,
      width: theme.itemFieldWidth,
      '@media print': {
        padding: 0,
        paddingRight: 16,
        flex: 1,
      },
    },
    datePickerIcon: {
      ...theme.arrowIcon,
      color: theme.palette.joinPrimary,
      height: 36,
      marginTop: 5,
      width: 36,
    },
    inlinePrint: {
      display: 'none',
      alignItems: 'baseline',
      '@media print': {
        display: 'inline-flex',
      },
    },
    noPrint: {
      '@media print': {
        display: 'none',
        height: 0,
        width: 0,
      },
    },

    picker: {
      ...theme.typography.body1,
      ...theme.typography.number,
      borderRadius: theme.border.radius,
      cursor: 'pointer',
      fontSize: 14,
      fontWeight: 300,
      padding: 9,
      background: theme.palette.backgroundWhite,
    },

    printDate: {
      padding: 0,
      ...theme.typography.body2,
    },
    printOnly: {
      display: 'none',
      '@media print': {
        display: 'block',
      },
    },
    smallIcon: {
      color: theme.palette.primaryGrey,
      height: theme.spacing.smallIcon,
      width: theme.spacing.smallIcon,
    },
    summaryCenterContent: {
      [theme.breakpoints.down('md')]: {
        display: 'flex',
        flexDirection: 'row',
        flexFlow: 'wrap',
      },
    },
    summaryCenterField: {
      flex: '0 0 50%',
    },
    summaryContent: {
      [theme.breakpoints.down('md')]: {
        display: 'flex',
        flexDirection: 'row',
      },
      paddingBottom: 350,
    },
    summary: {
      pageBreakInside: 'avoid',
      marginTop: 16,
    },
  });

export default styles;
