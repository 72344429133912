import { useMutation } from '@apollo/client';

import {
  CreateContingencyDrawMutationVariables,
  MutationCreateContingencyDrawArgs,
} from '../../../generated/graphql';

import { createContingencyDrawMutation } from './createContingencyDrawMutation';

export function useCreateContingencyDraw() {
  const [createContingencyDraw] = useMutation<
    MutationCreateContingencyDrawArgs,
    CreateContingencyDrawMutationVariables
  >(createContingencyDrawMutation);
  return (
    projectID: UUID,
    estimateID: UUID,
    itemID: UUID,
    contingencyID?: UUID,
    onSuccess?: () => void,
    analytics?: () => void
  ) => {
    return createContingencyDraw({
      variables: { projectID, estimateID, itemID, contingencyID },
    }).then(() => {
      if (onSuccess) onSuccess();
      if (analytics) analytics();
    });
  };
}
