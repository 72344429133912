import { useMutation } from '@apollo/client';

import {
  AcceptTermsOfServiceMutation,
  AcceptTermsOfServiceMutationVariables,
  AcceptedTermsOfServiceQuery,
  AcceptedTermsOfServiceQueryVariables,
} from '../../../generated/graphql';
import { useQuery } from '../../../hooks/useMountAwareQuery';
import { MountPolicy } from '../../../hooks/usePolicyOnFirstMount';

import { acceptTermsOfServiceMutation, acceptedTermsOfServiceQuery } from './queries';

export const useAcceptedTOS = () => {
  const result = useQuery<AcceptedTermsOfServiceQuery, AcceptedTermsOfServiceQueryVariables>(
    acceptedTermsOfServiceQuery,
    {},
    MountPolicy.SKIP_ON_MOUNT
  );

  return {
    dateEffective:
      result.data?.acceptedTermsOfService.dateEffective ??
      result.previousData?.acceptedTermsOfService.dateEffective ??
      undefined,
    accepted:
      result.data?.acceptedTermsOfService.accepted ??
      result.previousData?.acceptedTermsOfService.accepted ??
      undefined,
  };
};

export const useAcceptTOSMutation = () =>
  useMutation<AcceptTermsOfServiceMutation, AcceptTermsOfServiceMutationVariables>(
    acceptTermsOfServiceMutation
  );
