import { GetTokenSilentlyOptions } from '@auth0/auth0-react';
import { GetTokenSilentlyVerboseResponse } from '@auth0/auth0-spa-js';

type GetAccessTokenSilentlyProps = {
  (
    options: GetTokenSilentlyOptions & { detailedResponse: true }
  ): Promise<GetTokenSilentlyVerboseResponse>;
  (options?: GetTokenSilentlyOptions): Promise<string>;
  (options: GetTokenSilentlyOptions): Promise<GetTokenSilentlyVerboseResponse | string>;
};

let getAccessTokenSilently: GetAccessTokenSilentlyProps | undefined;

// This exposes the getAccessTokenSilently function to be called from a non-react component file
export const tokenUtil = {
  getAccessTokenSilently: () => getAccessTokenSilently?.(),
  setGetAccessTokenSilentlyFunc: (func: GetAccessTokenSilentlyProps) => {
    getAccessTokenSilently = func;
  },
};
