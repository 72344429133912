import { cloneDeep } from 'lodash';
import { useCallback, useMemo } from 'react';

import { LazyQueryHookOptions, useLazyQuery } from '@apollo/client';

import { ToastType } from '../../../api/gqlEnums';
import {
  LocationEscalationMultipleQuery,
  LocationEscalationMultipleQueryVariables,
  TimeEscalationInput,
  TimeEscalationMultipleQuery,
  TimeEscalationMultipleQueryVariables,
} from '../../../generated/graphql';
import { setToast } from '../../../hooks/useToastParametersLocalQuery';
import { parseDate } from '../../../utilities/dates';
import { isNonNullable } from '../../../utilities/types';
import { EscalationTargetLocation } from '../constants/projectCompTypes';
import { useProjectCompsSetInputUpdateFunctions } from '../ProjectCompsSetInputStore/ProjectCompsSetInputUpdaters';

import {
  locationEscalationMultiple as locationEscalationMultipleQuery,
  timeEscalationMultiple as timeEscalationMultipleQuery,
} from './queries';

export type EscalationTarget = EscalationTargetLocation & {
  date: string;
};

type EscalatableProject = Pick<ProjectProps, 'id' | 'name' | 'location' | 'lat' | 'lon'> & {
  date?: string | null;
  inputID: UUID;
};

// The `projects` argument passed in should be stable. That is, do not recreate the array or object
// per render pass. Instead, ensure that the address of the array and the addresses of its objects
// are the same across renders (unless array or object contents are legitimately changing).
// Otherwise, the functions returned from this hook will be newly-created ones and React will
// rerender any component subtree that takes those functions as props.
const useAutoEscalateMultiple = (target: EscalationTarget, projects: ProjectComp[]) => {
  const memoizedProjects: EscalatableProject[] = useMemo(
    () => projects.map((p) => ({ ...p.project, inputID: p.input.id, date: p.milestone.date })),
    [projects]
  );

  const memoizedTarget = useMemo(
    () => ({
      location: target.location,
      lat: target.lat,
      lon: target.lon,
      date: target.date,
    }),
    [target.location, target.lat, target.lon, target.date]
  );

  const onAutoEscalateMultipleLocations = useAutoEscalateMultipleLocations(
    memoizedTarget,
    memoizedProjects
  );
  const onAutoEscalateMultipleTimes = useAutoEscalateMultipleTimes(
    memoizedTarget,
    memoizedProjects
  );

  return {
    onAutoEscalateMultipleLocations,
    onAutoEscalateMultipleTimes,
  };
};

export default useAutoEscalateMultiple;

const useAutoEscalateMultipleLocations = (
  target: EscalationTarget,
  projects: EscalatableProject[]
) => {
  const { setEscalationMultiple } = useProjectCompsSetInputUpdateFunctions();

  const [fetchLocationEscalationMultiple] = useLocationEscalationMultipleLazyQuery({
    onCompleted: ({ locationEscalationMultiple }) => {
      if (!locationEscalationMultiple) {
        setToast({ message: 'Failed to calculate escalation' }, ToastType.SERVER_ERROR);
        return;
      }

      const escalationValues = locationEscalationMultiple
        .map(
          (e, i) =>
            e && {
              id: projects[i].inputID,
              location: `${e.percentage}`,
              locationMeta: {
                isAutoEscalated: true,
                isFuzzyMatch: e.fuzzyMatch,
                sourceLabel: e.from.name,
                targetLabel: e.to.name,
                targetIsFuzzyMatch: e.targetIsFuzzyMatch,
                sourceIsFuzzyMatch: e.sourceIsFuzzyMatch,
              },
            }
        )
        .filter(isNonNullable);

      setEscalationMultiple(escalationValues);
    },
  });

  // Why this monstrosity? Why not just set `variables` in the definition of the query like a sane human being?
  // This bug: https://github.com/apollographql/apollo-client/issues/5912
  const wrapper = useCallback(
    (finalTarget: EscalationTarget = target, finalProjects: EscalatableProject[] = projects) => {
      return fetchLocationEscalationMultiple({
        variables: {
          locations: finalProjects.map((p) => ({
            from: { name: p.location, lat: p.lat, lon: p.lon },
            to: {
              name: finalTarget.location,
              lat: finalTarget.lat,
              lon: finalTarget.lon,
            },
          })),
        },
      });
    },
    [fetchLocationEscalationMultiple, projects, target]
  );

  return wrapper;
};

const useAutoEscalateMultipleTimes = (target: EscalationTarget, projects: EscalatableProject[]) => {
  const { setEscalationMultiple } = useProjectCompsSetInputUpdateFunctions();

  const [fetchTimeEscalationMultiple] = useTimeEscalationMultipleLazyQuery({
    onCompleted: ({ timeEscalationMultiple }) => {
      if (!timeEscalationMultiple) {
        setToast({ message: 'Failed to calculate escalation' }, ToastType.SERVER_ERROR);
        return;
      }

      // Clone to remove `readonly` property of the response
      const escalations = cloneDeep(timeEscalationMultiple);

      const escalationValues = projects
        .map((p) => {
          // Note the `variables` we sent in this query - we only fetched escalation for the
          // projects that have dates. So first, if we don't have a date, we send off the
          // project without any new escalation values, just the ID.
          if (!p.date) return undefined;

          // Now, we know that we sent off for escalation for this project and that the next value
          // in the array is tied to this project.
          const e = escalations.shift();
          if (!e) return undefined;

          return {
            id: p.inputID,
            time: `${e.percentage}`,
            timeMeta: {
              isAutoEscalated: true,
              isFuzzyMatch: e.fuzzyMatch,
              sourceLabel: parseDate(p.date),
              targetLabel: `${e.to.year} Q${e.to.quarter}`,
            },
          };
        })
        .filter(isNonNullable);

      setEscalationMultiple(escalationValues);
    },
  });

  // Why this monstrosity? Why not just set `variables` in the definition of the query like a sane human being?
  // This bug: https://github.com/apollographql/apollo-client/issues/5912
  const wrapper = useCallback(() => {
    const dates: TimeEscalationInput[] = [];
    projects.forEach((p) => {
      if (p.date) {
        dates.push({
          from: p.date,
          to: target.date,
        });
      }
    });

    return fetchTimeEscalationMultiple({
      variables: { dates },
    });
  }, [fetchTimeEscalationMultiple, projects, target.date]);

  return wrapper;
};

const useLocationEscalationMultipleLazyQuery = (
  options?: LazyQueryHookOptions<
    LocationEscalationMultipleQuery,
    LocationEscalationMultipleQueryVariables
  >
) =>
  useLazyQuery<LocationEscalationMultipleQuery, LocationEscalationMultipleQueryVariables>(
    locationEscalationMultipleQuery,
    {
      fetchPolicy: 'network-only', // force refetches since we depend on onCompleted
      ...options,
    }
  );

const useTimeEscalationMultipleLazyQuery = (
  options?: LazyQueryHookOptions<TimeEscalationMultipleQuery, TimeEscalationMultipleQueryVariables>
) =>
  useLazyQuery<TimeEscalationMultipleQuery, TimeEscalationMultipleQueryVariables>(
    timeEscalationMultipleQuery,
    {
      fetchPolicy: 'network-only', // force refetches since we depend on onCompleted
      ...options,
    }
  );
