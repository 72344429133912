import { useRef } from 'react';
import { useOption } from 'react-aria';
import { ComboBoxState, Node } from 'react-stately';

type Props = {
  item: Node<object>;
  state: ComboBoxState<object>;
};

export default function ComboBoxOption({ item, state }: Props) {
  const ref = useRef<HTMLLIElement>(null);
  const { optionProps, isSelected, isFocused, isDisabled } = useOption(
    { key: item.key },
    state,
    ref
  );

  return (
    <li
      {...optionProps}
      ref={ref}
      className={[
        'flex flex-grow items-center gap-2 p-2 outline-none transition focus-visible:bg-selection-hover',
        isSelected ? 'bg-selection-selected' : 'bg-background-primary',
        isDisabled ? 'cursor-not-allowed text-type-inactive' : '',
        isFocused ? 'bg-selection-hover' : '',
      ].join(' ')}
    >
      {item.rendered}
    </li>
  );
}
