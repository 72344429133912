import { FC, ReactElement } from 'react';

import { AssistantPhoto, Info } from '@material-ui/icons';

import { withStyles } from '../../../theme/komodo-mui-theme';
import NormalTooltip from '../../NormalTooltip/NormalTooltip';
import { Tooltip } from '../../scales';

import styles from './ReportsListEntryStyles';

export type ReportsListEntryType = {
  name: string;
  description: string;
  type: string;
  shared?: string | undefined;
  owner?: string | undefined;
  last?: string | undefined;
  isBuiltIn?: boolean;
  hasDistributionScheduled?: boolean;
  isFollowingActiveMilestone?: boolean;
};

type ReportsListEntrySettingsComponentType = {
  settings: ReactElement;
};

type ClassesType = {
  classes: Classes<typeof styles>;
};

interface ReportsListEntryProps
  extends ClassesType,
    ReportsListEntryType,
    ReportsListEntrySettingsComponentType {}

const ReportsListEntry: FC<ReportsListEntryProps> = ({
  classes,
  name,
  description,
  type,
  shared,
  owner,
  last,
  settings,
  isBuiltIn = false,
  hasDistributionScheduled,
  isFollowingActiveMilestone,
}) => (
  <div
    className={`${classes.containerRow} ${
      isBuiltIn ? classes.containerRowBuiltInGrid : classes.containerRowGrid
    }`}
    data-cy={`report-${type}-${name}`}
  >
    <div className={classes.cell} data-cy="tooltip-reportDescription">
      {description && (
        <NormalTooltip title={description}>
          <Info className={classes.info} />
        </NormalTooltip>
      )}
    </div>
    <div className={classes.cell} data-cy="reportName">
      <p className={`${classes.text} ${classes.textName}`}>{name}</p>
    </div>
    <div className={classes.cell}>
      <p className={classes.text}>{type}</p>
    </div>
    {!isBuiltIn && (
      <div className={classes.cell}>
        <Tooltip
          content={hasDistributionScheduled ? 'Has Distribution Scheduled' : ''}
          placement="bottom"
        >
          <div className="flex items-center justify-center gap-2">
            <p className={classes.text}>{shared}</p>
            {hasDistributionScheduled && (
              <img
                alt="Has Distribution Scheduled"
                src="/img/ReportDistribution/Icon_ScheduleDistribution.svg"
                width={18}
              />
            )}
          </div>
        </Tooltip>
        <Tooltip
          content={
            isFollowingActiveMilestone
              ? 'This Milestone Summary Report is following the Active Milestone'
              : ''
          }
          placement="bottom"
        >
          <div>
            {isFollowingActiveMilestone && (
              <AssistantPhoto style={{ marginLeft: '4px', width: '18px' }} />
            )}
          </div>
        </Tooltip>
      </div>
    )}
    {!isBuiltIn && (
      <div className={classes.cell}>
        <p className={classes.text}>{owner}</p>
      </div>
    )}
    {!isBuiltIn && (
      <div className={classes.cell}>
        <p className={classes.text}>{last}</p>
      </div>
    )}
    <div className={classes.cell}>{settings}</div>
  </div>
);

export const StyledReportsListEntry = withStyles(styles)(ReportsListEntry);

export default StyledReportsListEntry;
