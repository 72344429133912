import { DocumentNode } from 'graphql';
import { useEffect, useState } from 'react';

import {
  QueryHookOptions as BaseQueryHookOptions,
  OperationVariables,
  QueryResult,
  WatchQueryFetchPolicy,
  useQuery as defaultUseQuery,
} from '@apollo/client';

import { PartialNullable, StrictUnion } from '../utilities/types';

import usePolicyOnFirstMount, { MountPolicy } from './usePolicyOnFirstMount';

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
interface SkippableQueryHookOpts<TData = any, TVariables = OperationVariables>
  extends Omit<BaseQueryHookOptions<TData, TVariables>, 'skip' | 'variables'> {
  skip: boolean;
  variables: PartialNullable<TVariables>;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
interface UnskippableQueryHookOpts<TData = any, TVariables = OperationVariables>
  extends Omit<BaseQueryHookOptions<TData, TVariables>, 'skip'> {
  skip?: never;
}

// If you want to write a hook that takes an options parameter and passes it to useQuery,
// use this type instead of the QueryHookOptions that's exported by Apollo.
export type QueryHookOptions<TData, TVariables> = StrictUnion<
  SkippableQueryHookOpts<TData, TVariables> | UnskippableQueryHookOpts<TData, TVariables>
>;

// This wraps the existing useQuery from Apollo so that
// fetchPolicy is cache-and-network on first mount and while loading.
// After loading and first mount fetchPolicy is going to be changed to cache-first
// The aim is to keep the app cache in sync more often
// and update it each time when user navigates to the page and component is mounted.
// It also should decrease the usage of refetch.
/**
 *
 * @param query Apollo DocumentNode
 * @param originalOptions initial state of the QueryHookOptions
 * @param mountPolicy skipOnMount - rely on the cached value / fetchOnVars - refetch query on variables change, even if there is a cached value
 *
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
export function useQuery<TData = any, TVariables = OperationVariables>(
  query: DocumentNode,
  originalOptions?: QueryHookOptions<TData, TVariables>,
  mountPolicy?: MountPolicy
): QueryResult<TData, TVariables> {
  const policy = usePolicyOnFirstMount(mountPolicy);

  const [fetchPolicy, setFetchPolicy] = useState<WatchQueryFetchPolicy>(policy);

  // `variables` is a different type than TVariables because of the games we play in the
  // `SkippableQueryHookOpts` interface. In that interface, we allow the property values in
  // `variables` to be T | null | undefined because that's why we'd generally be using `skip`.
  // This approach allows us to have typechecks at the callsite of `useQuery` on `variables`
  // that don't whine about required properties being undefined while we're skipping on them.
  // As a result, this cast to TVariables is safe IFF the required properties in `variables` that
  // were undefined (and passed our looser typecheck) are defined by the time `skip === false`.
  // Alas, we don't have a way of really testing that condition at runtime so we're relying on the dev.
  //
  // TL; DR - if you provide `skip` as an option, it's up to you to make sure that the required properties
  //          of `variables` are set by the time `skip === false`.
  const variables: TVariables | undefined = originalOptions?.variables as TVariables;

  const options = {
    ...originalOptions,
    fetchPolicy,
    variables,
  };

  const result = defaultUseQuery<TData, TVariables>(query, options);

  let inCache = false;

  if (result.loading) {
    inCache =
      result.client.readQuery({
        query,
        variables: result.variables,
      }) !== null;
  }

  useEffect(
    () => {
      if (fetchPolicy !== policy) setFetchPolicy(policy);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [result.loading]
  );

  return { ...result, loading: !inCache && result.loading };
}
