import { FC } from 'react';
import * as React from 'react';

import { InputVariant } from '../../../api/gqlEnums';
import { withStyles } from '../../../theme/komodo-mui-theme';

import styles from './SelectCategoryChipStyles';

interface SelectCategoryChipProps {
  buttonRef?: React.MutableRefObject<HTMLButtonElement>;
  categoryString: string;
  classes: Classes<typeof styles>;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  disabled?: boolean;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  expanded?: boolean;
  hasSelectedCategory: boolean;
  inputId: UUID;
  isCompact?: boolean;
  keyId?: UUID | string;
  onClick: () => void;
  placeholder?: string;
  variant?: string;
}

const SelectCategoryChip: FC<SelectCategoryChipProps> = ({
  buttonRef = undefined,
  categoryString,
  classes,
  disabled = false,
  expanded = false,
  hasSelectedCategory,
  inputId,
  isCompact = true,
  keyId = 'SelectCategory',
  onClick,
  placeholder = undefined,
  variant = undefined,
}) => {
  const isGrid = variant === InputVariant.GRID;
  const value = !hasSelectedCategory ? placeholder : categoryString;

  return (
    <button
      key={`SelectCategoryChipInputSingle-${keyId}`}
      ref={buttonRef}
      className={`${isCompact ? classes.heightCompact : classes.height} ${classes.closeBg}`}
      onClick={onClick}
      type="button"
    >
      <div
        className={`${classes.input} ${isGrid ? classes.inputGrid : ''} ${
          isCompact ? classes.inputCompact : classes.height
        }`}
      >
        <div
          className={`${classes.containerChip} ${!hasSelectedCategory ? classes.empty : ''}`}
          data-cy={`chip-${inputId}`}
        >
          <div className={classes.value} title={value}>
            {value}
          </div>
          {!disabled && (
            <div
              className={`${classes.dropdownArrow} ${
                expanded ? classes.dropdownArrowExpanded : ''
              } ${isGrid ? classes.dropdownArrowGrid : ''}`}
              data-cy={`deleteIcon-removeCategory-${inputId}`}
            />
          )}
        </div>
      </div>
    </button>
  );
};

export default withStyles(styles)(SelectCategoryChip);
