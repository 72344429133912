import { ComponentProps } from 'react';

import { SortManager } from '../scales';

import ItemsTableViewApply from './Sandbox/ItemsTable/ItemsTableViewApply';

type Props = {
  itemEntries: ComponentProps<typeof ItemsTableViewApply>['itemEntries'];
  sortManager?: SortManager;
  title: string;
};

function ScenarioItemSummarySection(props: Props) {
  if (props.itemEntries.length === 0) {
    return null;
  }

  return (
    <div className="flex flex-col gap-2 py-3">
      <div className="type-heading3">{props.title}</div>
      <ItemsTableViewApply itemEntries={props.itemEntries} sortManager={props.sortManager} />
    </div>
  );
}

export default ScenarioItemSummarySection;
