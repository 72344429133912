import EmailVerificationSendUser from './EmailVerificationSendUser';

type Props = {
  children: JSX.Element;
  isEmailVerified: boolean;
};

export default function EmailVerificationLayer(props: Props) {
  if (props.isEmailVerified) {
    return props.children;
  }

  return <EmailVerificationSendUser />;
}
