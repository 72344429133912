import { ComponentProps } from 'react';

import { Status } from '../../../../generated/graphql';
import { isNonNullable } from '../../../../utilities/types';
import { generateTitle } from '../../../Items/ItemsListItem/ItemsListItemUtils';
import { ScenarioItemEntry } from '../../common/ItemStatus/ItemStatusUtils';
import { ScenarioItems } from '../../types';

import StatusCell from './cells/StatusCell/StatusCellEdit/StatusCellEdit';

export const getScenarioItemTitle = (item: ComponentProps<typeof StatusCell>['item']) => {
  const title = generateTitle(item);
  const option = item.options.filter((o) => o.status !== Status.NOTCHOSEN);
  if (option.length === 1) {
    const optionTitle = generateTitle(option[0]);
    return `${title} / ${optionTitle}`;
  }
  return title;
};

// This function is used to get the scenario items that are not options
// and have the options as a property.
export const getScenarioItemEntries = (items: ScenarioItems) =>
  items
    .filter((i) => !i.parentID)
    .map((item) => {
      const options = (item?.options || [])
        .map((o) => items.find((item) => item.id === o.id))
        .filter(isNonNullable);
      const scenarioItemEntry: ScenarioItemEntry = {
        ...item,
        options,
      };
      return scenarioItemEntry;
    });
