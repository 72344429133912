import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';

const styles = (theme: KomodoTheme) =>
  createStyles({
    card: {
      overflow: 'visible',
      backgroundColor: 'unset',
      border: theme.border.line,
      '@media print': {
        border: 'none',
        marginTop: 0,
        marginBottom: 0,
      },
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    background: {
      backgroundColor: theme.palette.backgroundWhite,
    },
    editButton: theme.roundButton,
    empty: {
      padding: 24,
    },
    root: {
      minWidth: 700,
      flexGrow: 1,
      overflow: 'hidden',
      margin: '0 auto',
      '@media print': {
        width: '100%',
        border: `0px none`,
        maxWidth: 'none',
        overflowX: 'visible',
      },
    },
    actionIcon: {
      float: 'left',
      marginRight: '4px',
    },
    actionText: {
      ...theme.typography.body1,
      fontWeight: 500,
      float: 'left',
      textTransform: 'none',
      paddingTop: '3px',
    },
    controlHeader: {
      alignItems: 'center',
      display: 'flex',
      flex: '1 1 auto',
      flexWrap: 'wrap',
      justifyContent: 'flex-end',
    },
    fullscreenCollapse: {
      display: 'flex',
      flex: '1 0 0px',
      flexDirection: 'column',
    },
    header: {
      display: 'flex',
      '@media print': {
        display: 'none',
      },
    },
    headerOptions: {
      display: 'flex',
      alignItems: 'center',
    },
    tabContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    title: {
      display: 'flex',
      flex: '1 0 315px',
      alignItems: 'center',
      gap: '8px',
    },
    printHeader: {
      display: 'none',
      '@media print': {
        display: 'block',
      },
    },
    menu: {
      display: 'inline',
    },
    selectFilterMenu: {
      fontWeight: 600,
      padding: '0px 5px 24px 24px',
      width: 350,
    },
    subHeader: {
      display: 'flex',
      paddingRight: 24,
    },
    wrapper: {
      alignItems: 'center',
      display: 'flex',
    },
  });

export default styles;
