import { useEffectOnce } from 'react-use';

import { filesExplorerNavigationVar } from '../../../api/apollo/reactiveVars';
import { UserSource } from '../../../generated/graphql';
import { getSourceNameText } from '../Files/FilesDialog/FilesDialogUtils';

import { getExplorerRootComp } from './FilesExplorerHelper';

export const resetNavInitial = (userSource: UserSource) => {
  const element = getExplorerRootComp(userSource);
  const rootNavBarEntry = {
    id: 'source',
    name: getSourceNameText(userSource.sourceSystem),
    element,
  };
  filesExplorerNavigationVar([rootNavBarEntry]);
};

export const useInitFilesNavigation = (userSource: UserSource) => {
  useEffectOnce(() => {
    resetNavInitial(userSource);
    return () => {
      filesExplorerNavigationVar([]);
    };
  });
};
