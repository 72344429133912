import { useMutation } from '@apollo/client';

import { createQuantity } from '../../../analytics/analyticsEventProperties';
import { REFETCH_QUANTITY_NEW_ESTIMATE } from '../../../api/refetchSets';
import {
  RemoveQuantityBreakdownMutation,
  RemoveQuantityBreakdownMutationVariables,
} from '../../../generated/graphql';
import useSendAnalytics from '../../../hooks/useSendAnalytics';

import { removeQuantityBreakdownMutation } from './queries';

export default () => {
  const [removeQuantityBreakdownFunc] = useMutation<
    RemoveQuantityBreakdownMutation,
    RemoveQuantityBreakdownMutationVariables
  >(removeQuantityBreakdownMutation);

  const sendAnalytics = useSendAnalytics();

  return (
    projectID: UUID,
    milestoneID: UUID,
    quantityID: UUID,
    estimateID: UUID,
    unitID: UUID,
    magnitude: number
  ) =>
    removeQuantityBreakdownFunc({
      refetchQueries: REFETCH_QUANTITY_NEW_ESTIMATE,
      variables: {
        projectID,
        milestoneID,
        quantityID,
        estimateID,
        unitID,
        magnitude,
      },
    }).then((response) => {
      const quantity = response.data?.removeQuantityBreakdown;
      if (quantity)
        sendAnalytics(
          createQuantity(
            quantity.id,
            quantity.magnitude.toString(),
            quantity.unit.name,
            milestoneID
          )
        ); // TODO - update to correct analytics
    });
};
