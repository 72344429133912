import { gql } from '@apollo/client';
import { programFields } from '../../api/graphqlFragments';

export const createProgramMutation = gql`
  mutation createProgram($input: ProgramCreationInput!) {
    createProgram(input: $input)
  }
`;

export const deleteProgramMutation = gql`
  mutation deleteProgram($programID: UUID!) {
    deleteProgram(programID: $programID)
  }
`;

export const editProgramMutation = gql`
  mutation editProgram($input: EditProgramInput!) {
    editProgram(input: $input) {
      ...programFields
    }
  }
  ${programFields}
`;
