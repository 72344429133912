import { useState } from 'react';

import {
  CompanyAdminEventType,
  companyAdminAnalyticsEvent,
} from '../../../../analytics/analyticsEventProperties';
import { Org } from '../../../../generated/graphql';
import useSendAnalytics from '../../../../hooks/useSendAnalytics';
import { useSetOrgName } from '../../../Organizations/hooks/hooks';
import { TextInput } from '../../../scales';
import useMemoWrapper from '../../../useMemoWrapper';
import { getUsedNames } from '../utils';

export default function OrgNameInput(props: {
  organizationID: UUID;
  organizationName: string;
  organizations: Org[];
}) {
  const sendAnalytics = useSendAnalytics();
  const names = useMemoWrapper(getUsedNames, props.organizations, props.organizationName);
  const [setOrgName] = useSetOrgName();
  const [error, setError] = useState('');
  const [name, setName] = useState(props.organizationName);

  return (
    <TextInput
      aria-label="Organization Name"
      errorMessage={error}
      onBlur={() => {
        const fixed = name.trim();
        if (fixed === '') {
          setName(props.organizationName);
        }
        if (fixed === props.organizationName) return;
        if (error) {
          setError('');
          setName(props.organizationName);
        } else {
          setOrgName({
            variables: { input: { organizationID: props.organizationID, name: fixed } },
          });
          sendAnalytics(
            companyAdminAnalyticsEvent(CompanyAdminEventType.ORGANIZATIONS_CHANGE_NAME, {
              organization: fixed,
            })
          );
        }
      }}
      onChange={(name) => {
        const isUnique = !names.includes(name);
        if (isUnique) {
          setError('');
        } else {
          setError('Organization name already exists');
        }
        if (name === '') {
          setError('Required');
        }
        setName(name);
      }}
      value={name}
    />
  );
}
