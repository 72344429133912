import { createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
export default (theme: any) =>
  createStyles({
    menuLabel: {
      padding: '13px 4px 10px 10px',
      alignSelf: 'baseline',
      minWidth: '54px',
      [theme.breakpoints.up('xl')]: {
        padding: '13px 2px 10px 4px',
      },
      '@media print': {
        flex: '1 1 auto',
        textAlign: 'right',
      },
    },
    fullWidth: {
      width: '100%',
    },
    hide: {
      opacity: 0,
    },
    smallIcon: {
      '& svg': {
        fontSize: 20,
      },
    },
    milestoneBucket: {
      paddingLeft: '1rem',
    },
    name: {
      '@media print': {
        backgroundColor: theme.palette.backgroundWhite,
        color: 'black',
        fontSize: '16px',
        fontStyle: 'normal',
        paddingLeft: '0px',
        position: 'relative',
        zIndex: 1,
      },
      flexWrap: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    checkboxMenuItem: {
      paddingLeft: 0,
      '&$selectOptions': {
        backgroundColor: 'white',
        '&:hover': {
          backgroundColor: 'rgba(0, 0, 0, 0.08)',
        },
      },
    },
    select: {
      padding: 1,
      paddingRight: 28,
      height: 31,
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    selectOptions: {
      ...theme.typography.body1,
      backgroundColor: 'white',
      padding: '8px 16px 8px 0px',
    },
    showPrint: {
      display: 'none',
      '@media print': {
        display: 'inline-block',
      },
    },
  });
