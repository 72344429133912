import { createStyles } from '@material-ui/core';

export default (theme) =>
  createStyles({
    empty: {
      backgroundColor: 'white',
      padding: 24,
      marginTop: 4,
      flexGrow: 1,
      borderRight: theme.border.line,
    },
    list: {
      paddingBottom: 0,
      marginTop: 4,
    },
  });
