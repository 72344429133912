import { SelectEntry, SelectEntryID } from './types';

export default function defaultEntryFilter(
  entry: SelectEntry,
  searchString: string,
  parentID: SelectEntryID | null
) {
  // If we're searching, search the entire set of entries
  if (searchString) {
    const searchFn = (entry: SelectEntry) =>
      Boolean(
        entry.label.toLocaleLowerCase().includes(searchString.toLocaleLowerCase()) ||
          entry.description?.toLocaleLowerCase().includes(searchString.toLocaleLowerCase())
      );
    // A section entry's sub-entries don't exist outside the section entry, so
    // we need to separately handle sections and search within them
    return entry.isSection ? entry.entries.filter(searchFn) : searchFn(entry);
  }

  // Otherwise, include the entry only if it matches our currently-selected parentID
  return (entry.parentID ?? null) === parentID;
}
