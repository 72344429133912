import useAssetBlobUrlQuery from '../../assets/hooks/useAssetBlobUrlQuery';

type Props = {
  assetID: UUID;
};

export default function UploadImage(props: Props) {
  const { url } = useAssetBlobUrlQuery(props.assetID);
  return <img alt="logo" className="h-[60px] w-[600px] rounded-[4px] object-contain" src={url} />;
}
