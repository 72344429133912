import { ReactNode } from 'react';

import ExpandableFiltersIcon from './ExpandableFiltersIcon';

type Props = {
  icon: ReactNode;
  isExpanded: boolean;
  setIsExpanded?: (isExpanded: boolean) => void;
  title: string;
  children: ReactNode;
};

export default function ExpandableFiltersSection(props: Props) {
  const onClick = () => {
    if (props.setIsExpanded) props.setIsExpanded(!props.isExpanded);
  };

  const sectionStyles = [
    'mb-4 flex flex-col overflow-hidden rounded-3xl bg-background-1',
    props.isExpanded ? 'flex-shrink-1' : 'flex-shrink-0',
  ].join(' ');

  return (
    <div className={sectionStyles}>
      {props.setIsExpanded ? (
        <button
          aria-label={`toggle ${props.title} visibility`}
          className={[
            'flex w-full items-center gap-1 p-6 text-left text-type-primary type-heading2 focus-visible:outline',
            props.isExpanded ? 'rounded-t-3xl' : 'rounded-3xl',
          ].join(' ')}
          onClick={onClick}
        >
          {props.icon}
          <div className="flex-grow type-heading2">{props.title}</div>
          <ExpandableFiltersIcon isExpanded={props.isExpanded} />
        </button>
      ) : (
        <div className="flex w-full items-center gap-1 rounded-t-3xl p-6 text-left text-type-primary type-heading2">
          {props.icon}
          <div className="flex-grow type-heading2">{props.title}</div>
        </div>
      )}
      {props.isExpanded && (
        <div className="mx-4 mb-6 flex flex-col gap-3 overflow-y-auto">{props.children}</div>
      )}
    </div>
  );
}
