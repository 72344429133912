import { SourceSystem, UserSource } from '../../../generated/graphql';

import FileExplorerFolderRoot from './FileExplorerFolderRoot';
import FileExplorerHubsRoot from './FileExplorerHubsRoot';

export const getExplorerRootComp = (userSource: UserSource) =>
  userSource.sourceSystem === SourceSystem.EGNYTE ? (
    <FileExplorerFolderRoot userSource={userSource} />
  ) : (
    <FileExplorerHubsRoot userSource={userSource} />
  );
