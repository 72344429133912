import { createContext } from 'react';

export type RowHighlightableElement = HTMLDivElement;

type RowHighlightContextType = {
  onRegisterAsHighlightable: (ref: React.RefObject<RowHighlightableElement>) => void;
  onRegisterHighlightableArea: (ref: React.RefObject<HTMLDivElement>) => void;
  onScroll: () => void;
};

const RowHighlightContext = createContext<RowHighlightContextType>({
  onRegisterAsHighlightable: () => {
    throw new Error(
      '`useRowHighlight` must be called by a component that is a descendent of the `RowHighlightProvider` component'
    );
  },
  onRegisterHighlightableArea: () => {
    throw new Error(
      '`useRowHighlight` must be called by a component that is a descendent of the `RowHighlightProvider` component'
    );
  },
  onScroll: () => {
    throw new Error(
      '`useRowHighlight` must be called by a component that is a descendent of the `RowHighlightProvider` component'
    );
  },
});

export default RowHighlightContext;
