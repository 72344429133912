import { useMutation } from '@apollo/client';

import { setProjectTypeMutation } from './projectPropertyMutations';

export const useSetProjectType = () => {
  const [setProjectType] = useMutation(setProjectTypeMutation);

  return (projectID: UUID, typeID: UUID) =>
    setProjectType({
      variables: { projectID, typeID },
    });
};
