import { FC } from 'react';

import { isSecondPopupVar } from '../../../api/apollo/reactiveVars';
import { VIEW_OPTIONS } from '../../../constants';
import JoinSelect, { SelectEntry, getEntry } from '../../Select/JoinSelect/JoinSelect';

type CostReportSelectProps = {
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  hideHeader?: boolean;
  selected?: string;
  onChange: (type: string) => void;
};

const CostReportSelect: FC<CostReportSelectProps> = ({
  hideHeader = false,
  selected = VIEW_OPTIONS.CHART_AND_LIST,
  onChange,
}) => {
  const entries = [
    getEntry(VIEW_OPTIONS.CHART_AND_LIST, 'Chart and List'),
    getEntry(VIEW_OPTIONS.CHART, 'Chart'),
    getEntry(VIEW_OPTIONS.LIST, 'List'),
  ] as SelectEntry[];

  return (
    <div className="flex flex-col gap-0.5" data-cy="editMenu-view">
      {!hideHeader && <div className="type-label">View</div>}
      <JoinSelect
        entries={entries}
        hidePrint={false}
        onChange={(type: string | null) => onChange(type || '')}
        onClose={() => isSecondPopupVar(false)}
        onOpen={() => isSecondPopupVar(true)}
        value={selected}
      />
    </div>
  );
};

export default CostReportSelect;
