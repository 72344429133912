import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../../theme/komodo-mui-theme';

export default (theme: KomodoTheme) =>
  createStyles({
    chipContainer: {
      alignContent: 'center',
      background: 'none',
      display: 'grid',
      minHeight: 38,
      padding: 12,
      width: 481,
    },
    chipHidden: {
      opacity: 0,
      padding: 2,
    },
    chipPadding: {
      padding: 2,
    },
    root: {
      background: theme.palette.nestingGrey,
      padding: 2,
      margin: 2,
      fontFamily: theme.fontFamily,
      width: 481,
      borderRadius: 1,
      height: 40,
    },
    chipLabel: {
      font: theme.fontFamily,
      fontSize: 16,
      lineHeight: '19.2px',
      color: theme.palette.shadedGrey,
    },
    selectCategorization: {
      width: 210,
    },
    container: {
      border: 'none',
    },
    controls: {
      display: 'inline-flex',
      fontSize: '12px',
      fontFamily: theme.typography.fontFamily,
      position: 'absolute',
      top: '0',
      right: '0',
    },
    controlsButton: {
      cursor: 'pointer',
      textDecoration: 'underline',
      color: theme.palette.blue,
      marginLeft: '10px',
    },
    fullContainer: {
      position: 'relative',
      paddingTop: '24px',
    },
    list: {
      listStyleType: 'disc',
      paddingLeft: 20,
    },
    listItem: {
      display: 'list-item',
      paddingTop: 0,
      paddingBottom: 0,
    },
    autoWidth: {
      width: 'auto',
    },
    addRemoveIcon: {
      marginLeft: 'auto',
      fontSize: 24,
      color: theme.palette.chartBlack,
      '&:hover': {
        color: theme.palette.joinPrimary,
        opacity: 0.5,
      },
    },
    header: {
      display: 'flex',
      alignItems: 'center',
      width: 495,
    },
    headerText: {
      paddingRight: 235,
      fontWeight: 700,
    },
  });
