import { FC } from 'react';

import { compareCost } from '../../../utilities/compare';
import { parseDate } from '../../../utilities/dates';
import { getItemStatusLabel } from '../../../utilities/item-status';
import { pluralizeCountString } from '../../../utilities/string';
import { renderCostString } from '../../CostReport/CostReportUtils';
import { styleItemNumber } from '../../Items/ItemsListItem/ItemsListItemUtils';
import { getStatusIsAcceptedIncorporated } from '../../Items/ItemsUtils';
import ItemsIcons from '../../ItemsList/ItemsIcons/ItemsIcons';
import { SMALL } from '../../ItemsList/ItemsIcons/ItemsIconsMap';
import { isScheduleSettingsDisabled } from '../../ProjectProperties/ProjectScheduleImpact/ProjectScheduleImpactSettings';
import { getScheduleImpactText } from '../../shared-widgets/ScheduleImpact';

type PrintItemsListItemProps = {
  shouldDisplayCosts: boolean;
  isExact?: boolean;
  itemsAssigneeMap: Map<string, UserLink | undefined | null>;
  itemsTreeItem: ItemsTreeItem;
  itemsListItemsMap: Map<UUID, ItemsListItem>;
};

const PrintItemsListItem: FC<PrintItemsListItemProps> = ({
  shouldDisplayCosts,
  isExact = false,
  itemsAssigneeMap,
  itemsTreeItem,
  itemsListItemsMap,
}) => {
  const isScheduleImpactEnabled = !isScheduleSettingsDisabled();

  if (!itemsTreeItem) {
    return <></>;
  }

  const { cost: itemsTreeItemCost, itemLikeID } = itemsTreeItem;
  const itemsListItem = itemsListItemsMap.has(itemLikeID)
    ? itemsListItemsMap.get(itemLikeID)
    : null;

  if (!itemsListItem) return null;

  const {
    assigneeID,
    cost: itemsListItemCost,
    dueDate,
    name,
    number,
    options,
    status,
  } = itemsListItem;
  const assignee = itemsAssigneeMap.get(itemLikeID);

  // leftMainText dispalys main item information in the top left
  const leftMainText = `${styleItemNumber(number)} (${getItemStatusLabel(status)}) ${name}`;

  // leftSubText displays assignee/due date information in the bottom left
  const hasAssignee = !!assigneeID;
  const hasDueDate = dueDate;
  const leftSubText = (
    <>
      {hasAssignee && `Assignee: ${assignee?.name}`}
      {hasAssignee && hasDueDate && <div className="print-spacer" />}
      {hasDueDate && `Due Date: ${parseDate(dueDate)}`}
    </>
  );

  const totalCost = itemsListItemCost;
  const categorizedCost = itemsTreeItemCost;
  const hasDifferentCategorizedCost = categorizedCost && !compareCost(categorizedCost, totalCost);

  // rightMainText displays cost impact in the top right
  const rightMainText: string = renderCostString({
    cost: hasDifferentCategorizedCost ? categorizedCost : totalCost,
    isExact,
    isWide: true,
  });

  const hasOptions = !!options.length;

  // rightSubText displays option information in the bottom right
  let rightSubText = '';
  if (hasOptions) {
    rightSubText += pluralizeCountString('option', options.length);
    if (getStatusIsAcceptedIncorporated(status)) {
      const acceptingOption = options.find((option) =>
        getStatusIsAcceptedIncorporated(option.status)
      );
      rightSubText += ` (${acceptingOption?.number} ${acceptingOption?.status.toLowerCase()})`;
    }
  }
  if (shouldDisplayCosts && hasDifferentCategorizedCost) {
    if (hasOptions) rightSubText += ', ';
    rightSubText += `across all categories: ${renderCostString({
      cost: totalCost,
      isExact,
      isWide: true,
    })}`;
  }

  const [numberText, criticalPathText] = getScheduleImpactText(itemsListItem.scheduleImpact, {
    critical: 'critical path',
    noncritical: '',
  });

  return (
    <div className={isScheduleImpactEnabled ? 'print-item-row' : 'print-item-row-2'}>
      <div className="print-item-row-details">
        <div className="print-item-row-details-top">
          <ItemsIcons status={status} variant={SMALL} />
          <div className="print-item-row-details-top-text">{leftMainText}</div>
        </div>
        <div className="print-item-row-bottom-text">{leftSubText}</div>
      </div>
      <div className="print-item-row-cost print-item-row-col">
        {shouldDisplayCosts && <div className="print-no-wrap-text">{rightMainText}</div>}
        <div className="print-item-row-bottom-text print-item-row-bottom-text-right print-right-align">
          {rightSubText}
        </div>
      </div>
      {isScheduleImpactEnabled && (
        <div className="print-item-row-cost print-item-row-col">
          <div className="print-no-wrap-text print-lowercase">{numberText}</div>
          <div className="print-item-row-bottom-text print-item-row-bottom-text-right print-right-align print-red">
            {criticalPathText}
          </div>
        </div>
      )}
    </div>
  );
};

export default PrintItemsListItem;
