import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';

export default (theme: KomodoTheme) =>
  createStyles({
    focused: {
      background: 'none',
    },
    label: {
      ...theme.typography.label,
    },
    root: {
      display: 'flex',
      alignItems: 'center',
      ...theme.typography.webParagraph,
    },
    searchBox: {
      boxSizing: 'border-box',
      width: '100%',
    },
    searchIcon: {
      color: theme.palette.joinPrimary,
      marginLeft: theme.spacing.generic[3],
      width: 16,
      height: 16,
    },
    iconButton: {
      height: 20,
      margin: 'auto',
      marginRight: 4,
      width: 20,
      '& svg': {
        fontSize: 20,
        margin: 4,
        color: theme.palette.joinPrimary,
      },
    },
    iconProjectsList: {
      margin: theme.spacing.generic[2],
      marginRight: 0,
      paddingTop: 5,
    },
  });
