import { gql } from '@apollo/client';

import {
  programCollaboratorFields,
  programCostBreakdownFields,
  programCostFields,
  programFields,
  programProjectFields,
} from '../../api/graphqlFragments';

export const programEligibleProjectsQuery = gql`
  query programEligibleProjects {
    programEligibleProjects {
      ...programProjectFields
    }
  }
  ${programProjectFields}
`;

export const programQuery = gql`
  query program($programID: UUID!) {
    program(programID: $programID) {
      ...programFields
    }
  }
  ${programFields}
`;

export const programCostsQuery = gql`
  query programCosts($programID: UUID!, $includedProjectIDs: [UUID!]!) {
    programCosts(programID: $programID, includedProjectIDs: $includedProjectIDs) {
      ...programCostFields
    }
  }
  ${programCostFields}
`;

export const programBreakdownQuery = gql`
  query programBreakdown($programID: UUID!, $projectIDs: [UUID!]!) {
    programBreakdown(programID: $programID, projectIDs: $projectIDs) {
      ...programCostBreakdownFields
    }
  }
  ${programCostBreakdownFields}
`;

export const programCostTrendlineQuery = gql`
  query programCostTrendline($programID: UUID!, $includedProjectIDs: [UUID!]!) {
    programCostTrendline(programID: $programID, includedProjectIDs: $includedProjectIDs) {
      minDate
      maxDate
      combined {
        minCost
        maxCost
        combinedCostTimeSeries {
          date
          estimate
          budget
          runningTotal
          pendingMin
          pendingMax
        }
      }
      separated {
        projectName
        activeMilestoneName
        projectID
        minCost
        maxCost
        separatedCostTimeSeries {
          date
          gap
          runningTotal
          monthOverMonthPercentChange
        }
      }
    }
  }
`;

export const programCollaboratorsQuery = gql`
  query programCollaborators($programID: UUID!) {
    programCollaborators(programID: $programID) {
      ...programCollaboratorFields
    }
  }
  ${programCollaboratorFields}
`;

export const programCategorizationsQuery = gql`
  query programCategorizations($programID: UUID!, $includedProjectIDs: [UUID!]!) {
    programCategorizations(programID: $programID, includedProjectIDs: $includedProjectIDs) {
      name
      missingProjectNames
    }
  }
`;

export const programCategorizedTotalsQuery = gql`
  query programCategorizedTotals(
    $programID: UUID!
    $includedProjectIDs: [UUID!]!
    $categorizationName: String!
  ) {
    programCategorizedTotals(
      programID: $programID
      includedProjectIDs: $includedProjectIDs
      categorizationName: $categorizationName
    ) {
      categoryName
      estimate
      budget
      runningTotal
      gap
      accepted
    }
  }
`;
