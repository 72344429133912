// TODO: type enforce this function, use Status instead of string

import { Status } from '../generated/graphql';

// this requires a lot of cleanup as we often use string constants instead of the enum
export const getItemStatusLabel = (status?: string | null, args?: { short?: boolean }) => {
  switch (status) {
    case Status.NOTAPPLICABLE:
      if (args?.short) return 'N/A';
      return 'Not Applicable';
    case Status.REJECTED:
      return 'Rejected';
    case Status.NOTCHOSEN:
      return 'Not Chosen';
    case Status.PENDING:
      return 'Pending';
    case Status.ACCEPTED:
      return 'Accepted';
    case Status.INCORPORATED:
      return 'Incorporated';
    default:
      return '';
  }
};

export const getHoverDisplay = (chosen: boolean, status: string) =>
  chosen && status === Status.PENDING
    ? getItemStatusLabel(Status.NOTCHOSEN)
    : getItemStatusLabel(status);

// Note: This works for this enum where the key is the same as the value
export const getItemStatusFromString = (status: string) => Status[status as keyof typeof Status];
