import { useParams } from 'react-router-dom';

import { useCompanyRoutesData } from '../CompanyTabUtils';

import CompanyOrganization from './CompanyOrganization';
import useOrganizationQuery from './hooks/useOrganizationQuery';
import useOrganizationsQuery from './hooks/useOrganizationsQuery';

export default function CompanyOrganizationData() {
  const { companyID } = useCompanyRoutesData();
  const { organizationId } = useParams();
  const organizationsQueryResult = useOrganizationsQuery(companyID);
  const organizationQueryResult = useOrganizationQuery(organizationId);

  const loading = organizationsQueryResult.loading || organizationQueryResult.loading;
  if (loading) return null;
  if (!organizationQueryResult.data?.organization) return null;
  if (!organizationsQueryResult.data?.organizations) return null;

  return (
    <CompanyOrganization
      key={organizationId}
      organization={organizationQueryResult.data?.organization}
      organizations={organizationsQueryResult.data?.organizations}
    />
  );
}
