import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../../theme/komodo-mui-theme';

const Styles = (theme: KomodoTheme) =>
  createStyles({
    inlineText: {
      inlineSize: 'max-content',
    },
    valueDecoration: {
      cursor: 'pointer',
    },
    valueText: {
      ...theme.typography.header,
      color: theme.palette.shadedGrey,
      fontSize: '1.5rem',
      fontWeight: 400,
    },
    valueTextActive: {
      color: theme.palette.primaryGrey,
      fontWeight: 500,
    },
  });

export default Styles;
