import {
  VISIBILITY_DRAFT_ITEM,
  VISIBILITY_INTERNAL_DRAFT,
  VISIBILITY_PUBLISHED_ITEM,
  VISIBILITY_SHARED_DRAFT,
} from '../../constants';
import { Visibility } from '../../generated/graphql';

export const visibilityDisplayStrings = new Map([
  [Visibility.PRIVATE_DRAFT, VISIBILITY_DRAFT_ITEM],
  [Visibility.INTERNAL_DRAFT, VISIBILITY_INTERNAL_DRAFT],
  [Visibility.SHARED_DRAFT, VISIBILITY_SHARED_DRAFT],
  [Visibility.PUBLISHED, VISIBILITY_PUBLISHED_ITEM],
]);
