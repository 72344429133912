import { FC } from 'react';

import { createStyles } from '@material-ui/core';

import { KomodoTheme, withStyles } from '../../../../theme/komodo-mui-theme';

const styles = (theme: KomodoTheme) =>
  createStyles({
    greenDot: {
      color: theme.palette.brightGreen,
      fontSize: 6,
      margin: '0 4px',
      verticalAlign: 'top',
    },
  });

type ImportMapTooltipProps = {
  classes: Classes<typeof styles>;
};

const ImportMapTooltip: FC<ImportMapTooltipProps> = ({ classes }) => (
  <>
    <div>
      We automatically imported any WBS codes in your original estimate to help speed up your setup
      in Join.
    </div>
    <br />
    <div>
      We’ll automatically match any WBS codes in your estimate to categorization in Join based on
      their names, but you can make adjustments if you need to.
    </div>
    <br />
    <div>
      <span className={classes.greenDot}>{'\u2B24'}</span> means that we found a WBS code that
      hasn’t yet been set up in Join. We’ll automatically create a new categorization in Join to
      match your WBS code unless you want to pick a different categorization instead.
    </div>
  </>
);

export default withStyles(styles)(ImportMapTooltip);
