type Props = {
  x: number;
  y: number;
  height: number;
  width: number;
};

const AverageColRect = ({ x, y, width, height }: Props) => (
  <rect className="fill-background-1" height={height} width={width} x={x} y={y} />
);

export default AverageColRect;
