import {
  GetMilestoneQuantitiesQuery,
  GetMilestoneQuantitiesQueryVariables,
} from '../../../generated/graphql';
import { useQuery } from '../../../hooks/useMountAwareQuery';

import { getMilestoneQuantitiesQuery } from './queries';

export const emptyQuantities: Quantity[] = [];

const useMilestoneQuantitiesQuery = (milestoneID?: UUID) =>
  useQuery<GetMilestoneQuantitiesQuery, GetMilestoneQuantitiesQueryVariables>(
    getMilestoneQuantitiesQuery,
    {
      variables: { milestoneID },
      skip: !milestoneID,
    }
  );

export default useMilestoneQuantitiesQuery;
