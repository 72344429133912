import { useMemo } from 'react';

import { useMutation } from '@apollo/client';

import {
  createUpdateCustomUnit,
  removeUnit,
  toggleUnits,
} from '../../../analytics/analyticsEventProperties';
import { REFETCH_QUANTITY } from '../../../api/refetchSets';
import {
  CreateOrUpdateCustomUnitMutation,
  CreateOrUpdateCustomUnitMutationVariables,
  GetProjectUnitsQuery,
  GetProjectUnitsQueryVariables,
  RemoveCustomUnitMutation,
  RemoveCustomUnitMutationVariables,
  ToggleProjectUnitsMutation,
  ToggleProjectUnitsMutationVariables,
} from '../../../generated/graphql';
import { useQuery } from '../../../hooks/useMountAwareQuery';
import useSendAnalytics from '../../../hooks/useSendAnalytics';
import { getProjectIdFromUrl } from '../../../utilities/url';
import { unitCompareFunc } from '../../Inputs/UnitToggle/UnitToggleUtils';

import {
  createOrUpdateCustomUnitMutation,
  getProjectUnitsQuery,
  removeCustomUnitMutation,
  toggleProjectUnitsMutation,
} from './queries';

export const useCreateOrUpdateUnit = () => {
  const [createOrUpdateCustomUnitFunc] = useMutation<
    CreateOrUpdateCustomUnitMutation,
    CreateOrUpdateCustomUnitMutationVariables
  >(createOrUpdateCustomUnitMutation);

  const sendAnalytics = useSendAnalytics();

  return (unit: UnitInput, projectID: UUID, onSuccess?: (unit: Unit) => void) =>
    createOrUpdateCustomUnitFunc({
      variables: {
        unit,
        projectID,
      },
      refetchQueries: REFETCH_QUANTITY,
    }).then(({ data }) => {
      const createOrUpdateCustomUnit = data?.createOrUpdateCustomUnit;
      if (!createOrUpdateCustomUnit) return;
      if (onSuccess) onSuccess(createOrUpdateCustomUnit);
      sendAnalytics(createUpdateCustomUnit(projectID, unit.name));
    });
};

export const useToggleProjectUnits = () => {
  const [toggleProjectUnitsFunc] = useMutation<
    ToggleProjectUnitsMutation,
    ToggleProjectUnitsMutationVariables
  >(toggleProjectUnitsMutation);

  const sendAnalytics = useSendAnalytics();

  return (projectID: UUID, units: UnitToggleInput[], onSuccess: (units: Unit[]) => void) =>
    toggleProjectUnitsFunc({
      variables: {
        projectID,
        units,
      },
      refetchQueries: REFETCH_QUANTITY,
    }).then(({ data }) => {
      const toggleProjectUnits = data?.toggleProjectUnits;
      if (!toggleProjectUnits) return;

      onSuccess(toggleProjectUnits);
      const enabled: string[] = toggleProjectUnits
        .filter((u: Unit) => u.isEnabled)
        .map((u: Unit) => u.name);
      const disabled: string[] = toggleProjectUnits
        .filter((u: Unit) => !u.isEnabled)
        .map((u: Unit) => u.name);
      sendAnalytics(toggleUnits(projectID, enabled, disabled));
    });
};

export const useRemoveCustomUnit = () => {
  const [removeCustomUnitFunc] = useMutation<
    RemoveCustomUnitMutation,
    RemoveCustomUnitMutationVariables
  >(removeCustomUnitMutation);

  const sendAnalytics = useSendAnalytics();

  return (projectID: UUID, unitID: UUID, unitName: string, onSuccess?: (removedID: UUID) => void) =>
    removeCustomUnitFunc({
      variables: {
        projectID,
        unitID,
      },
      refetchQueries: REFETCH_QUANTITY,
    }).then(({ data }) => {
      const id = data?.removeCustomUnit;
      if (onSuccess && id) onSuccess(id);
      sendAnalytics(removeUnit(projectID, unitName));
    });
};

export const useGetProjectUnitsQuery = (projectID: UUID, isEnabledOnly = true) =>
  useQuery<GetProjectUnitsQuery, GetProjectUnitsQueryVariables>(getProjectUnitsQuery, {
    variables: { projectID, isEnabledOnly },
    skip: !projectID,
  });

export const useSortedUnits = (enabled: boolean) => {
  const projectID = getProjectIdFromUrl();
  const {
    data: { getProjectUnits } = { getProjectUnits: [] },
    loading: unitsLoading,
    refetch,
  } = useGetProjectUnitsQuery(projectID, enabled);

  const units = useMemo(() => getProjectUnits.slice().sort(unitCompareFunc), [getProjectUnits]);

  return { refetch, units, unitsLoading };
};
