import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';

export default (theme: KomodoTheme) =>
  createStyles({
    accepted: {
      color: theme.palette.accepted,
      '& rect': {
        fill: `${theme.palette.accepted} !important`,
      },
    },
    arrow: {
      stroke: theme.palette.primaryGrey,
      strokeWidth: 1.5,
      fill: 'none',
    },
    bold: {
      fontWeight: 700,
    },
    budget: {
      color: theme.palette.budget,
      '& rect': {
        fill: `none !important`,
        mixBlendMode: 'multiply',
        stroke: `${theme.palette.budget} !important`,
        strokeWidth: 2,
      },
    },
    change: {
      '& rect': {
        fill: 'none !important',
        stroke: `${theme.palette.primaryGrey} !important`,
        strokeWidth: 0,
      },
    },
    columnContainer: {
      display: 'flex',
      padding: '8px 0px 0px 6px',
    },
    dataLabel: {
      ...theme.typography.number,
      fontSize: 14,
      fontWeight: 700,
    },
    divider: {
      color: theme.palette.joinPrimary,
      marginBottom: 2,
      marginTop: 2,
    },
    ellipsis: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    emptyState: {
      color: theme.palette.primaryBlue,
      cursor: 'pointer',
      paddingBottom: 32,
      paddingLeft: 24,
    },
    estimate: {
      '& rect': {
        fill: `none !important`,
        stroke: `${theme.palette.primaryGrey} !important`,
        strokeDasharray: '2 2',
        strokeWidth: 2,
      },
    },
    graph: {
      fontFamily: theme.typography.fontFamily,
      width: '100%',
    },
    gridLines: {
      stroke: theme.palette.joinGrey400,
    },
    inline: {
      display: 'inline-flex',
    },
    legend: {
      fontFamily: theme.typography.fontFamily,
      fontSize: 14,
      fontWeight: 400,
      color: theme.palette.primaryGrey,
      '& > div': {
        padding: 0,
      },
      '& span': {
        color: theme.palette.primaryGrey,
      },
    },
    legendElement: {
      fontFamily: theme.typography.fontFamily,
      fontSize: 14,
      fontWeight: 400,
      display: 'flex',
      '& > div': {
        fontWeight: 700,
        width: 14,
      },
      '& > span': {
        marginLeft: 10,
      },
    },
    milestoneName: {
      fontSize: 12,
      marginBottom: 6,
    },
    noPermission: {
      fill: theme.palette.shadedGrey,
    },
    number: {
      ...theme.typography.number,
    },
    projectName: {
      fontSize: 14,
    },
    remaining: {
      color: theme.palette.shadedGrey,
      '& rect': {
        fill: `${theme.palette.joinGrey400} !important`,
        stroke: `none !important`,
      },
    },
    rowContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    running: {
      '& rect': {
        fill: `none !important`,
        stroke: `${theme.palette.runningTotal} !important`,
        strokeWidth: 2,
      },
    },
    spacer: {
      flexGrow: 1,
    },
    xAxis: {
      '& > g line': {
        stroke: 'none',
      },
      '& > line': {
        stroke: theme.palette.disabledGrey,
        strokeWidth: 1,
      },
      '& text': {
        fill: theme.palette.primaryBlue,
        ...theme.typography.h3TableHeader,
        '& a:visited': {
          fill: theme.palette.primaryBlue,
        },
        '& a:hover': {
          textDecoration: 'underline',
        },
      },
    },
    yAxis: {
      '& line': {
        stroke: 'none',
      },
      '& text': {
        fill: theme.palette.primaryGrey,
        fontSize: 12, // TODO: Unify
        ...theme.typography.number,
      },
    },
  });
