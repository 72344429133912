import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../../../theme/komodo-mui-theme';

export const PDF_PAGE_TOP_BOTTOM_PADDING = 8;

export default (theme: KomodoTheme) =>
  createStyles({
    pdfPage: {
      '&:first-child': {
        paddingTop: 0,
      },
      background: theme.palette.shadedGrey,
      paddingBottom: PDF_PAGE_TOP_BOTTOM_PADDING,
      paddingTop: PDF_PAGE_TOP_BOTTOM_PADDING,
    },
  });
