import { FC } from 'react';

import { GetApp, Print } from '@material-ui/icons';

import { isDownloadingCostReportToExcelVar } from '../../api/apollo/reactiveVars';
import { EstimateType, JoinProjectRoutes } from '../../api/gqlEnums';
import usePermissions from '../../utilities/permissions/usePermissions';
import { generateSharedPath } from '../../utilities/routes/links';
import { isEnumValue } from '../../utilities/types';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';
import { Export } from '../Icons/Export';
import ReportsManagerMenu from '../ReportsTab/ReportsManagerMenu/ReportsManagerMenu';
import IconMenu from '../Select/SelectMenu/IconMenu';

type Props = {
  exportReport: () => void;
  print: () => void;
  milestoneID: UUID;
  milestoneName: string;
  projectID: UUID;
  setSettings: (newSettings: ProjectCostBreakdownSettings) => void;
  settings: ProjectCostBreakdownSettings;
};

const ProjectCostBreakdownReportHeader: FC<Props> = ({
  exportReport,
  print,
  milestoneID,
  milestoneName,
  projectID,
  setSettings,
  settings,
}) => {
  const { isViewOnly } = usePermissions();

  const options = [
    {
      name: 'Print Report with Cost Summary',
      icon: <Print />,
      callback: print,
    },
    {
      callback: exportReport,
      icon: <GetApp />,
      name: 'Download.xlsx',
      loadingVar: isDownloadingCostReportToExcelVar,
    },
  ];

  return (
    // top padding is smaller to account for loading bar spacer
    <div className="ml-4 mr-3 flex pb-4 pt-3">
      <div>
        <Breadcrumbs
          links={[
            {
              display: 'Milestones',
              destination: generateSharedPath(JoinProjectRoutes.MILESTONES, {
                projectId: projectID,
              }),
              tooltip: 'Back to milestones list',
            },
            {
              display: milestoneName,
              destination: generateSharedPath(JoinProjectRoutes.MILESTONE_DETAILS, {
                projectId: projectID,
                milestoneId: milestoneID,
              }),
              tooltip: 'Back to milestone details',
            },
          ]}
        />
        <div className="type-heading2">Project Cost Breakdown Report</div>
      </div>
      <div className="flex-grow">
        <ReportsManagerMenu
          isViewOnly={isViewOnly}
          setSettings={(newSettings: Record<string, string | string[] | number[] | null>) => {
            const estimateType = newSettings.estimateType;
            if (typeof estimateType === 'string' && isEnumValue(EstimateType, estimateType))
              setSettings({ estimateType });
          }}
          settings={settings}
          variant="projectCostBreakdown"
        />
      </div>
      <IconMenu cy="breakdown-report-export" icon={<Export />} isBottomOriented options={options} />
      <div />
    </div>
  );
};

export default ProjectCostBreakdownReportHeader;
