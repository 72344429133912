import {
  commonCategorizationFields,
  commonCategoryFields,
  gql,
} from '../../../api/graphqlFragments';

export const copyItemDataQuery = gql`
  query copyItemData($itemID: UUID!, $projectID: UUID) {
    copyItemData(itemID: $itemID, projectID: $projectID) {
      convertedItemCategories {
        ...commonCategoryFields
      }
      itemEstimateCategorizations {
        ...commonCategorizationFields
      }
    }
  }
  ${commonCategoryFields}
  ${commonCategorizationFields}
`;
