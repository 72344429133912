import { ComponentProps } from 'react';

import { ScrollContainer } from '../../../scales';
import SandboxView from '../SandboxView';

import MilestonePresentationColumn from './MilestonePresentationColumn';
import SandboxPresentationColumn from './SandboxPresentationColumn';
import { getShouldGrow } from './SandboxPresentationViewUtils';
import ScenariosChartData from './ScenariosChart/ScenariosChartData';

type Props = Pick<
  ComponentProps<typeof SandboxView>,
  'milestoneName' | 'sandbox' | 'showMilestone' | 'sortManager'
>;

const CHART_PRESO_HEIGHT = 200;

export default function SandboxPresentationView(props: Props) {
  const shouldGrow = getShouldGrow(props.showMilestone, props.sandbox.scenarios.length);
  return (
    <div className="flex flex-col overflow-hidden">
      <ScenariosChartData
        height={CHART_PRESO_HEIGHT}
        milestoneName={props.milestoneName}
        sandbox={props.sandbox}
        showMilestone={props.showMilestone}
      />
      <ScrollContainer className="gap-2 py-2" direction="horizontal">
        {props.showMilestone && (
          <MilestonePresentationColumn
            milestone={props.sandbox.milestone}
            milestoneName={props.milestoneName}
            shouldGrow={shouldGrow}
          />
        )}
        {props.sandbox.scenarios.map((s) => (
          <SandboxPresentationColumn
            key={s.scenarioID}
            onLoad={() => {}}
            scenario={s}
            shouldGrow={shouldGrow}
            sortManager={props.sortManager}
          />
        ))}
      </ScrollContainer>
    </div>
  );
}
