import SvgIcon from '@material-ui/core/SvgIcon';

import { IconProps } from '.';

export default (props: IconProps) => (
  <SvgIcon {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        d="M19.7,10.7v-7c0-1.5-1.2-2.8-2.6-2.8H4.5C3,0.9,1.9,2.1,1.9,3.7v10c0,1.5,1.2,2.8,2.6,2.8H8c0.2,3.7,3.3,6.6,7.1,6.6
                c3.9,0,7.1-3.2,7.1-7.1C22.1,13.9,21.2,12,19.7,10.7z M4.5,2.7h12.6c0.4,0,0.8,0.4,0.8,0.9c-0.1,0-0.2,0-0.2,0.1l-5.4,2.4
                c-0.9,0.4-2,0.4-2.9,0L4,3.7c-0.1,0-0.2,0-0.2-0.1C3.7,3.1,4.1,2.7,4.5,2.7z M3.7,13.7V5.6l4.9,2.2c0.7,0.3,1.4,0.5,2.2,0.5
                c0.7,0,1.5-0.2,2.2-0.5l4.9-2.2v3.9C17,9.1,16,8.9,15,8.9c-3.4,0-6.3,2.5-7,5.7H4.5C4.1,14.6,3.7,14.2,3.7,13.7z M15,21.2
                c-2.9,0-5.2-2.3-5.2-5.2s2.3-5.2,5.2-5.2c2.9,0,5.2,2.3,5.2,5.2S17.9,21.2,15,21.2z"
      />
      <polygon points="15.7,12.8 13.8,12.8 13.8,17.5 17.8,17.5 17.8,15.6 15.7,15.6" />
    </g>
  </SvgIcon>
);
