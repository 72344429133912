import { FC, ReactNode, RefObject, useContext, useEffect, useRef, useState } from 'react';

import { TermKey } from '../../api/gqlEnums';
import { RESIZE } from '../../constants';
import { ItemDrawInfo, MarkupMode } from '../../generated/graphql';
import { BUTTON_ESTIMATE_TABLE_ADD_LINE, BUTTON_MARKUP_TABLE_ADD_LINE } from '../../tagConstants';
import { useCostMode } from '../../utilities/costMode';
import EstimateTotal from '../estimate/EstimateAccordion/EstimateTotal';
import SectionCollapse from '../estimate/EstimateAccordion/SectionCollapse';
import { ProjectTermStore } from '../ProjectDisplaySettings/TerminologyProvider';

import { MarkupGridController } from './GridController';
import { useGetDoMilestoneMarkupsHaveContingencyDrawsLazyQuery } from './hooks/markupMutation';
import useUpdate from './hooks/useUpdate';
import { Grid, GridProps } from './JoinGrid';
import { ItemGridDisplayProps } from './JoinGridVirtualTable';
import MarkupDeletionConfirmationDialog from './MarkupDeletionConfirmationDialog';
import {
  EstimateArmatureSubtotals,
  EstimateGridPermissions,
  GridController,
  GridVariant,
} from './types';
import { DEFAULT_COLLAPSE_HEIGHT, DEFAULT_ROW_HEIGHT } from './utilities/size';
import { getMarkupHeaderName, showGrid } from './utils';

const ITEM_DETAILS_MAX_HEIGHT = 400;

const calcNumTrue = (openBools: boolean[]) => openBools.filter((bool) => bool).length;
const markupHasRows = (grid: GridController | undefined, isItemEstimateView: boolean) =>
  isItemEstimateView ? grid && grid.numRows() > 0 : false;

export type SectionHeightProps = {
  numGrids: number;
  numOpen: number;
  startHeight?: number;
};

export const calcMaxSectionHeight = (
  props: SectionHeightProps | null | undefined,
  variant: GridVariant,
  max?: number
) => {
  const windowHeight = max || window.innerHeight;
  if (variant === GridVariant.ITEM_TEMPLATE) return windowHeight;
  if (!props) return undefined;
  const { numGrids, numOpen, startHeight = 0 } = props;
  const borderHeights = 3 * 2 + 1;
  const totalHeight =
    windowHeight -
    startHeight -
    numGrids * DEFAULT_COLLAPSE_HEIGHT -
    numOpen * DEFAULT_ROW_HEIGHT -
    borderHeights;
  return numOpen > 0 ? totalHeight / numOpen : undefined;
};

const calcDifference = (grid: GridController, maxSectionHeight?: number, isOpen = false) => {
  const totalSectionHeight = grid && grid.numRows() * DEFAULT_ROW_HEIGHT + DEFAULT_ROW_HEIGHT;
  return maxSectionHeight && maxSectionHeight - totalSectionHeight > DEFAULT_ROW_HEIGHT && isOpen
    ? maxSectionHeight - totalSectionHeight
    : 0;
};

type JoinGridAccordionProps = {
  costOfConstruction: number;
  directRef: React.RefObject<HTMLDivElement>;
  gridData: {
    estimate: GridController;
    markup: MarkupGridController;
    incorporatedMarkups?: MarkupGridController;
    inheritedMarkups?: MarkupGridController;
    ownerCosts?: MarkupGridController;
    inheritedOwnerCostMarkups?: MarkupGridController;
    contingencyDraws?: Pick<ItemDrawInfo, 'id' | 'draw' | 'name' | 'error'>[];
    incorporatedDraws?: MarkupGridController;
    itemDraws?: MarkupGridController;
    subtotals?: EstimateArmatureSubtotals;
  };
  gridType: GridProps['gridType'];
  hasWidth: boolean;
  isExpanded?: boolean;
  isFiltering: boolean;
  isItemEstimateView?: boolean;
  markupRef: RefObject<HTMLDivElement> | undefined;
  ownerCostRef: RefObject<HTMLDivElement> | undefined;
  incorporatedMarkupRef: RefObject<HTMLDivElement> | undefined;
  incorporatedDrawRef: RefObject<HTMLDivElement> | undefined;
  itemDrawRef: RefObject<HTMLDivElement> | undefined;
  inheritedOwnerCostRef: RefObject<HTMLDivElement> | undefined;
  noDataPlaceholder?: ReactNode;
  permissions: EstimateGridPermissions;
  hasOwnerCostEstimate: boolean;
  variant: GridVariant;
};

const JoinGridAccordion: FC<JoinGridAccordionProps> = ({
  costOfConstruction,
  directRef,
  gridData,
  gridType,
  hasWidth,
  isFiltering,
  isItemEstimateView = false,
  markupRef,
  ownerCostRef,
  permissions,
  incorporatedMarkupRef,
  incorporatedDrawRef,
  itemDrawRef,
  inheritedOwnerCostRef,
  noDataPlaceholder,
  hasOwnerCostEstimate = false,
  isExpanded = false,
  variant,
}) => {
  const {
    canEditLines,
    canEditMarkups,
    canViewOwnerCosts,
    canViewMarkups,
    canEditOwnerCosts,
    canViewMarkupDetails,
    canViewEstimate,
  } = permissions;
  const t = useContext(ProjectTermStore);
  const startRef = useRef<HTMLDivElement>(null);
  const forceUpdate = useUpdate();
  const costMode = useCostMode();
  const shouldShowOwnerCosts = canViewOwnerCosts && costMode.markupMode !== MarkupMode.NO_MARKUPS;

  const {
    markup,
    estimate,
    inheritedMarkups,
    incorporatedMarkups,
    incorporatedDraws,
    itemDraws,
    ownerCosts,
    inheritedOwnerCostMarkups,
    subtotals,
  } = gridData;
  // we want to show the cost of construction if there are visible owner costs and the estimate subtotals exist
  const shouldShowCostOfConstruction =
    shouldShowOwnerCosts && subtotals && (ownerCosts || inheritedOwnerCostMarkups);

  const isReadOnlyVariant = variant === GridVariant.READ_ONLY;

  // STATE
  const [directCostOpen, setDirectCostOpen] = useState(canViewEstimate || isExpanded);
  const [markupOpen, setMarkupOpen] = useState(
    markupHasRows(markup, isItemEstimateView) || isExpanded || isReadOnlyVariant
  );
  const [incorporatedMarkupOpen, setIncorporatedMarkupOpen] = useState(
    markupHasRows(incorporatedMarkups, isItemEstimateView) || isExpanded || isReadOnlyVariant
  );
  const [incorporatedDrawsOpen, setIncorporatedDrawsOpen] = useState(
    markupHasRows(incorporatedDraws, isItemEstimateView) || isExpanded || isReadOnlyVariant
  );
  const [itemDrawsOpen, setItemDrawsOpen] = useState(
    markupHasRows(itemDraws, isItemEstimateView) || isExpanded || isReadOnlyVariant
  );
  const [inheritedMarkupOpen, setInheritedMarkupOpen] = useState(
    markupHasRows(inheritedMarkups, isItemEstimateView) || isExpanded || isReadOnlyVariant
  );
  const [ownerCostsOpen, setOwnerCostsOpen] = useState(isReadOnlyVariant);
  const [ownerCostEstimateID, setOwnerCostEstimateIDEstimateID] = useState(
    hasOwnerCostEstimate ? estimate.estimateID : undefined
  );
  const [isInheritedOwnerCostMarkupsOpen, setIsInheritedOwnerCostMarkupsOpen] =
    useState(isReadOnlyVariant);
  const currentStartFromTop = startRef.current?.offsetTop;
  const [isMarkupDeletionDialogOpen, setIsMarkupDeletionDialogOpen] = useState(false);

  // MARKUP DELETION VARIABLES
  const markupDeletionFunc = () => {
    markup.numSelectedRows = 0;
    markup.previouslySelectedRow = -1;
    markup.deleteLines();
    forceUpdate();
  };
  const [getDoMilestoneMarkupsHaveContingencyDraws] =
    useGetDoMilestoneMarkupsHaveContingencyDrawsLazyQuery({
      onCompleted: (res) => {
        if (res.getDoMilestoneMarkupsHaveContingencyDraws) {
          // we have to show the dialog to confirm that they want to delete this contingency with draws
          setIsMarkupDeletionDialogOpen(true);
        } else {
          // this isn't a contingency/there are no draws, so we're fine
          markupDeletionFunc();
        }
      },
    });

  // DISPLAY
  const startHeight = currentStartFromTop;
  // Once we drop the divider at Y, we set the height of the estimate section as
  // the height, minus the headers

  const numOpen = calcNumTrue([
    directCostOpen,
    markupOpen,
    incorporatedMarkupOpen,
    isInheritedOwnerCostMarkupsOpen,
    inheritedMarkupOpen,
  ]);
  const numGrids = calcNumTrue([
    !!markup,
    !!estimate,
    !!inheritedMarkups,
    !!incorporatedMarkups,
    !!inheritedOwnerCostMarkups,
    !!ownerCosts, // this is intentionally duplicated because owner costs include
    !!ownerCosts, // a subtotal in addition to the collapsible estimate
  ]);
  const sectionHeightProps: SectionHeightProps = {
    numGrids,
    numOpen,
    startHeight,
  };
  const maxSectionHeight = calcMaxSectionHeight(
    sectionHeightProps,
    variant,
    variant === GridVariant.ITEM_DETAILS || variant === GridVariant.READ_ONLY
      ? ITEM_DETAILS_MAX_HEIGHT
      : undefined
  );

  const totalDifference =
    calcDifference(estimate, maxSectionHeight, directCostOpen) +
    calcDifference(markup, maxSectionHeight, markupOpen) +
    (inheritedMarkups
      ? calcDifference(inheritedMarkups, maxSectionHeight, inheritedMarkupOpen)
      : 0) +
    (incorporatedMarkups
      ? calcDifference(incorporatedMarkups, maxSectionHeight, incorporatedMarkupOpen)
      : 0) +
    (ownerCosts ? calcDifference(ownerCosts, maxSectionHeight, ownerCostsOpen) : 0) +
    (inheritedOwnerCostMarkups
      ? calcDifference(inheritedOwnerCostMarkups, maxSectionHeight, ownerCostsOpen)
      : 0);

  const displayProps: ItemGridDisplayProps = {
    maxSectionHeight,
    totalDifference,
  };

  // ACTIONS
  const resize = () => {
    window.dispatchEvent(new Event(RESIZE));
  };
  useEffect(resize, [directCostOpen, markupOpen, inheritedMarkupOpen, ownerCostsOpen]);

  // COMPONENTRY
  const showDirectCosts = showGrid(isReadOnlyVariant, estimate);
  const { isItem } = estimate;
  const estimateComps = canViewEstimate && showDirectCosts && (
    <>
      <SectionCollapse
        collapsed={!canViewEstimate || !directCostOpen}
        disabled={!canViewEstimate}
        grid={estimate}
        setCollapse={() => {
          if (!isReadOnlyVariant) setDirectCostOpen(!directCostOpen);
        }}
        term={t.titleCase(TermKey.DIRECT_COST)}
      />
      <div ref={startRef} />
      <div
        ref={directRef}
        className={`${'join-grid-fullscreen'} ${isItem ? '' : 'join-grid-fullscreen-flex'}`}
        data-cy="grid-component-direct-costs"
        style={{ display: !directCostOpen ? 'none' : undefined }}
      >
        {noDataPlaceholder ||
          (canViewEstimate && hasWidth && (
            <Grid
              buttons={
                canEditLines
                  ? {
                      id: BUTTON_ESTIMATE_TABLE_ADD_LINE,
                      isAddDisabled: isFiltering,
                      onAddClick: () => {
                        estimate.addLine('Button', estimate.scrollToBottom);
                        forceUpdate();
                      },
                      onDeleteClick: () => {
                        estimate.numSelectedRows = 0;
                        estimate.previouslySelectedRow = -1;
                        estimate.deleteLines();
                        forceUpdate();
                      },
                      tooltip: isFiltering
                        ? `New rows can't be added when filtering an estimate.  Remove the filter to add rows.`
                        : '',
                    }
                  : undefined
              }
              grid={estimate}
              gridType={gridType}
              itemGridDisplayProps={{ ...displayProps, isDirectCost: true }}
            />
          ))}
      </div>
    </>
  );

  const showMarkups = showGrid(isReadOnlyVariant, markup);
  const markupsComps = showMarkups ? (
    <>
      <SectionCollapse
        collapsed={!canViewMarkupDetails || !markupOpen}
        disabled={!canViewMarkupDetails}
        grid={markup}
        includeSpacer={isReadOnlyVariant}
        setCollapse={() => {
          if (!isReadOnlyVariant) setMarkupOpen(!markupOpen);
        }}
        term={getMarkupHeaderName(t, !isItem)}
      />
      <div
        ref={markupRef}
        className="join-grid-fullscreen"
        data-cy="grid-component-markups"
        style={{ display: !markupOpen ? 'none' : undefined }}
      >
        {hasWidth && (
          <Grid
            buttons={
              canEditMarkups
                ? {
                    id: BUTTON_MARKUP_TABLE_ADD_LINE,
                    isAddDisabled: isFiltering,
                    onAddClick: () => {
                      markup.addLine('Button', markup.scrollToBottom);
                      forceUpdate();
                    },
                    onDeleteClick: () => {
                      const selectedMarkupIDs = markup.data.lines
                        .filter((_, i) => markup.isRowSelectedArr[i])
                        .map((row) => row.id);

                      if (markup.estimateID && markup.projectID && selectedMarkupIDs.length) {
                        getDoMilestoneMarkupsHaveContingencyDraws({
                          variables: {
                            estimateID: markup.estimateID,
                            projectID: markup.projectID,
                            markupIDs: selectedMarkupIDs,
                          },
                        });
                      }
                    },
                    tooltip: isFiltering
                      ? `New rows can't be added when filtering an estimate.  Remove the filter to add rows.`
                      : '',
                  }
                : undefined
            }
            grid={markup}
            gridType={gridType}
            itemGridDisplayProps={displayProps}
          />
        )}
      </div>
    </>
  ) : null;

  const showInheritedMarkups = showGrid(isReadOnlyVariant, inheritedMarkups);
  const inheritedMarkupsComps = inheritedMarkups && showInheritedMarkups && (
    <>
      <SectionCollapse
        collapsed={!inheritedMarkupOpen}
        disabled={!canViewMarkupDetails}
        grid={inheritedMarkups}
        includeSpacer={isReadOnlyVariant}
        setCollapse={() => {
          if (!isReadOnlyVariant) setInheritedMarkupOpen(!inheritedMarkupOpen);
        }}
        term={getMarkupHeaderName(t, true)}
        termSuffix={` - [${inheritedMarkups.milestoneName}]`}
      />
      <div
        className="join-grid-fullscreen"
        data-cy="grid-component-markups"
        style={{ display: !inheritedMarkupOpen ? 'none' : undefined }}
      >
        {hasWidth && (
          <Grid grid={inheritedMarkups} gridType={gridType} itemGridDisplayProps={displayProps} />
        )}
      </div>
    </>
  );

  const showInheritedOwnerCostMarkups = showGrid(isReadOnlyVariant, inheritedOwnerCostMarkups);
  const inheritedOwnerCostMarkupsComps = shouldShowOwnerCosts &&
    isItem &&
    inheritedOwnerCostMarkups &&
    showInheritedOwnerCostMarkups && (
      <>
        <SectionCollapse
          collapsed={!isInheritedOwnerCostMarkupsOpen}
          disabled={!canViewOwnerCosts}
          grid={inheritedOwnerCostMarkups}
          includeSpacer={isReadOnlyVariant}
          setCollapse={() => {
            if (!isReadOnlyVariant)
              setIsInheritedOwnerCostMarkupsOpen(!isInheritedOwnerCostMarkupsOpen);
          }}
          term={`Owner Cost ${t.titleCase(TermKey.MARKUP)}`}
        />
        <div
          ref={inheritedOwnerCostRef}
          className="join-grid-fullscreen"
          data-cy="grid-component-markups"
          style={{ display: !isInheritedOwnerCostMarkupsOpen ? 'none' : undefined }}
        >
          {hasWidth && (
            <Grid
              grid={inheritedOwnerCostMarkups}
              gridType={gridType}
              itemGridDisplayProps={displayProps}
            />
          )}
        </div>
      </>
    );

  const showIncorporatedMarkups = showGrid(isReadOnlyVariant, incorporatedMarkups);
  const incorporatedMarkupsComps = incorporatedMarkups && showIncorporatedMarkups && (
    <>
      <SectionCollapse
        collapsed={!incorporatedMarkupOpen}
        disabled={!canViewMarkupDetails}
        grid={incorporatedMarkups}
        includeSpacer={isReadOnlyVariant}
        setCollapse={() => {
          if (!isReadOnlyVariant) setIncorporatedMarkupOpen(!incorporatedMarkupOpen);
        }}
        term={`Incorporated Item ${t.titleCase(TermKey.MARKUP)}`}
      />
      <div
        ref={incorporatedMarkupRef}
        className="join-grid-fullscreen"
        data-cy="grid-component-incorporated-markups"
        style={{ display: !incorporatedMarkupOpen ? 'none' : undefined }}
      >
        {hasWidth && (
          <Grid
            buttons={
              canEditMarkups
                ? {
                    onDeleteClick: () => {
                      incorporatedMarkups.numSelectedRows = 0;
                      incorporatedMarkups.previouslySelectedRow = -1;
                      incorporatedMarkups.deleteLines();
                      forceUpdate();
                    },
                  }
                : undefined
            }
            grid={incorporatedMarkups}
            gridType={gridType}
            itemGridDisplayProps={displayProps}
          />
        )}
      </div>
    </>
  );

  const showIncorporatedDraws = showGrid(isReadOnlyVariant, incorporatedDraws);
  const incorporatedDrawsComps = incorporatedDraws && showIncorporatedDraws && (
    <>
      <SectionCollapse
        collapsed={!incorporatedDrawsOpen}
        disabled={!canViewMarkupDetails}
        grid={incorporatedDraws}
        includeSpacer={isReadOnlyVariant}
        setCollapse={() => {
          if (!isReadOnlyVariant) setIncorporatedDrawsOpen(!incorporatedDrawsOpen);
        }}
        term="Incorporated Item Draws"
      />
      <div
        ref={incorporatedDrawRef}
        className="join-grid-fullscreen"
        data-cy="grid-component-incorporated-markups"
        style={{ display: !incorporatedDrawsOpen ? 'none' : undefined }}
      >
        {hasWidth && (
          <Grid
            buttons={
              canEditMarkups
                ? {
                    onDeleteClick: () => {
                      incorporatedDraws.numSelectedRows = 0;
                      incorporatedDraws.previouslySelectedRow = -1;
                      incorporatedDraws.deleteLines();
                      forceUpdate();
                    },
                  }
                : undefined
            }
            grid={incorporatedDraws}
            gridType={gridType}
            itemGridDisplayProps={displayProps}
          />
        )}
      </div>
    </>
  );

  // item draws are currently only shown in the read only historical item estimates
  const showItemDraws = showGrid(isReadOnlyVariant, itemDraws);
  const itemDrawsComps = itemDraws && showItemDraws && (
    <>
      <SectionCollapse
        collapsed={!itemDrawsOpen}
        disabled={!canViewMarkupDetails}
        grid={itemDraws}
        includeSpacer={isReadOnlyVariant}
        setCollapse={() => {
          if (!isReadOnlyVariant) setItemDrawsOpen(!itemDrawsOpen);
        }}
        term="Contingency & Allowance Draws"
      />
      <div
        ref={itemDrawRef}
        className="join-grid-fullscreen"
        data-cy="grid-component-incorporated-markups"
        style={{ display: !itemDrawsOpen ? 'none' : undefined }}
      >
        {hasWidth && (
          <Grid grid={itemDraws} gridType={gridType} itemGridDisplayProps={displayProps} />
        )}
      </div>
    </>
  );

  const showMilestoneOwnerCosts = showGrid(isReadOnlyVariant, ownerCosts);
  const milestoneOwnerCostsComp = shouldShowOwnerCosts &&
    !isItem &&
    ownerCosts &&
    showMilestoneOwnerCosts && (
      <>
        <SectionCollapse
          collapsed={!ownerCostsOpen}
          disabled={!canViewOwnerCosts}
          grid={ownerCosts}
          includeSpacer={isReadOnlyVariant}
          setCollapse={() => {
            if (!isReadOnlyVariant) setOwnerCostsOpen(!ownerCostsOpen);
          }}
          term="Owner Costs"
        />
        <div ref={ownerCostRef} style={{ display: !ownerCostsOpen ? 'none' : undefined }}>
          {hasWidth && (
            <Grid
              buttons={
                canEditOwnerCosts
                  ? {
                      id: BUTTON_MARKUP_TABLE_ADD_LINE,
                      isAddDisabled: isFiltering,
                      onAddClick: () => {
                        if (ownerCostEstimateID) {
                          ownerCosts.addLine('Button', ownerCosts.scrollToBottom);
                        } else if (ownerCosts.createOwnerCostEstimate) {
                          ownerCosts.createOwnerCostEstimate((id) => {
                            setOwnerCostEstimateIDEstimateID(id);
                          });
                        }
                        forceUpdate();
                      },
                      onDeleteClick: () => {
                        ownerCosts.numSelectedRows = 0;
                        ownerCosts.previouslySelectedRow = -1;
                        ownerCosts.deleteLines();
                        forceUpdate();
                      },
                      tooltip: isFiltering
                        ? `New rows can't be added when filtering an estimate.  Remove the filter to add rows.`
                        : '',
                    }
                  : undefined
              }
              grid={ownerCosts}
              gridType={gridType}
            />
          )}
        </div>
      </>
    );

  return (
    <div>
      {estimateComps}
      {canViewMarkups && (
        <>
          {incorporatedMarkupsComps}
          {markupsComps}
          {inheritedMarkupsComps}
          <MarkupDeletionConfirmationDialog
            isOpen={isMarkupDeletionDialogOpen}
            onClose={() => setIsMarkupDeletionDialogOpen(false)}
            onConfirm={markupDeletionFunc}
          />
        </>
      )}
      {canViewMarkups && incorporatedDrawsComps}
      {shouldShowCostOfConstruction && (
        <>
          {isReadOnlyVariant && <div className="h-4 w-full" />}
          <EstimateTotal
            color="Grey"
            cost={{
              value: costOfConstruction,
            }}
            cy="construction"
            header="Cost of Construction"
            tooltip={
              canViewEstimate // if the user can't see direct costs then hide this tooltip
                ? `Cost of Construction (includes ${t.titleCase(TermKey.DIRECT_COST)}, ${
                    incorporatedMarkups || incorporatedDraws
                      ? `Incorporated Item ${t.titleCase(TermKey.MARKUP)}${
                          incorporatedDraws ? ' / Draws' : ''
                        }, `
                      : ''
                  }Milestone ${t.titleCase(TermKey.MARKUP)}, Contingencies, and Allowances)`
                : undefined
            }
            topBorder={!isReadOnlyVariant}
          />
        </>
      )}
      {canViewMarkupDetails && itemDrawsComps}
      {canViewOwnerCosts && inheritedOwnerCostMarkupsComps}
      {milestoneOwnerCostsComp}
    </div>
  );
};

export default JoinGridAccordion;
