import { useMemo } from 'react';

import { TermKey } from '../../../api/gqlEnums';
import { VARIANCE_KEY } from '../../../constants';
import theme from '../../../theme/komodo-mui-theme';
import { cleanCategoryForChart, reportTypes } from '../../../utilities/dashboard';
import { isDateValid, parseDate } from '../../../utilities/dates';
import { VarianceColumnDescription } from '../../CostReport/CostReportColumns/CostReportColumns';
import { getCostReportByType, getCostValue } from '../../CostReport/CostReportUtils';
import { sortCategories } from '../ChartsItems/ChartsItemsUtils';

const { palette } = theme;

type groupedCategories = {
  TARGET_REPORT: number;
};

export const hasTarget = (groupedCategories?: groupedCategories[] | null) =>
  !!groupedCategories && groupedCategories.some((c) => (c.TARGET_REPORT || 0) > 0);

export type LegendLabel = {
  title: string;
  color: string;
  strokeWidth?: number;
  strokeDasharray?: string;
};

export const useLegendLabels = (t: TermStore, hasTarget: boolean) =>
  useMemo(() => {
    const defaultLegend: LegendLabel[] = [
      {
        title: `${t.titleCase(TermKey.ESTIMATE)} Total`,
        strokeDasharray: '4, 2',
        color: palette.primaryGrey,
      },
      {
        title: t.titleCase(TermKey.RUNNING_TOTAL),
        color: palette.joinPrimary,
        strokeWidth: 4,
      },
    ];
    const budgetLegend = {
      title: t.titleCase(TermKey.TARGET),
      color: palette.budget,
      strokeWidth: 8,
    };
    if (hasTarget) {
      return [...defaultLegend, budgetLegend];
    }
    return defaultLegend;
  }, [t, hasTarget]);

type CostReportsSubtotalType = NonNullable<
  MilestoneCostReport['costReportColumns'][number]['report']
>['subtotals'][number];

const categoriesFromSubtotals = (
  subtotal: CostReportsSubtotalType,
  mapValue: string,
  categories: CostReportsSubtotalType['categories'] = []
) => {
  const catArray = categories;
  if (subtotal.categories.length === 1) {
    const category = subtotal.categories[0];
    const value = Number(getCostValue((subtotal.subtotalValue as SubtotalCost).range));
    if (value !== 0) {
      const matchingIndex = categories.findIndex(
        (c) => c.id === category.id && c.number === category.number // because the id is empty for a few cases
      );
      const update = {
        [mapValue]: value,
      };
      if (matchingIndex > -1) {
        const matchingCat = categories[matchingIndex];
        catArray[matchingIndex] = { ...matchingCat, ...update };
      } else if (category) {
        catArray.push({
          ...category,
          ...update,
        });
      }
    }
  }
};

export const makeEstimateChartCategoriesList = (
  costReports: MilestoneCostReport,
  termStore: TermStore
) => {
  if (costReports) {
    const categories: CostReportsSubtotalType['categories'] = [];
    reportTypes.forEach((type) => {
      const report = getCostReportByType(costReports, type);
      if (!report) return;
      report.subtotals.forEach((subtotal) => {
        categoriesFromSubtotals(subtotal, type, categories); // TODO: Don't operate on the list...
      });
    });
    // These are report-enriched categories...
    return categories.map((c) => cleanCategoryForChart(c, termStore)).sort(sortCategories);
  }
  return null;
};

export const makeVarianceChartKey = (
  milestoneID?: string | null,
  columnKey?: string,
  date?: string | null
) =>
  `${milestoneID || ''}${columnKey || VARIANCE_KEY}${
    (date && isDateValid(date) && parseDate(date)) || ''
  }`;

type VarianceReportsSubtotalType = NonNullable<
  VarianceReport['varianceColumns'][number]['report']
>['subtotals'][number];

export const makeVarianceChartCategoriesList = (
  costReports: VarianceReports,
  displayColumnDescriptions: VarianceColumnDescription[],
  termStore: TermStore
) => {
  if (costReports) {
    const categories: VarianceReportsSubtotalType['categories'] = [];
    costReports.forEach((varianceReport: VarianceReport, i: number) => {
      if (!varianceReport) return;
      const { milestoneID, varianceColumns, date } = varianceReport;
      if (!varianceColumns) return;
      const { report } = varianceColumns && varianceColumns[0];
      const column = displayColumnDescriptions[i];
      const columnKey = column?.columnKey;
      if (!report) return;
      report.subtotals.forEach((subtotal) => {
        categoriesFromSubtotals(
          subtotal,
          makeVarianceChartKey(milestoneID, columnKey, date),
          categories
        );
      });
    });
    return categories.map((c) => cleanCategoryForChart(c, termStore)).sort(sortCategories);
  }
  return null;
};

export const getVarianceDisplayValues = (column: VarianceColumnDescription) => {
  if (!column) return { chartKey: makeVarianceChartKey() };
  const { columnKey, milestoneID, milestoneName, date } = column;
  const chartKey = makeVarianceChartKey(milestoneID, columnKey, date);
  return { columnKey, milestoneID, milestoneName, chartKey, date };
};
