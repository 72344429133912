import { useState } from 'react';

import { ProgramCollaboratorFieldsFragment, SortDirection } from '../../../generated/graphql';
import TableSelectable from '../../dragon-scales/TableSelectable/TableSelectable';
import { filterEnabledSelectableEntries } from '../../dragon-scales/TableSelectable/TableSelectableUtils';

import ProgramUsersTableRow from './ProgramUsersTableRow';
import { ProgramUsersSortBy, ProgramUsersSortKey } from './types';
import {
  getDisabledIndices,
  getSelectableIndices,
  getSelectedIndices,
  membersHeaderContent,
  sortCollaborators,
  sortToTop,
  usersTableGridWidth,
} from './utils';

export type Props = {
  disabledIds: UUID[];
  entries: ProgramCollaboratorFieldsFragment[];
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  loading: boolean;
  onToggleEntry: (entry: ProgramCollaboratorFieldsFragment) => void;
  onSelectAll: (ids: string[]) => void;
  onUnselectAll: (ids: string[]) => void;
  projectCount: number;
  searchTerm: string;
  selectedIds: string[];
};

export default function ProgramUsersTable(props: Props) {
  const [sortState, setSortState] = useState<ProgramUsersSortBy>({
    sortDirection: SortDirection.SORT_ASCENDING,
    sortKey: ProgramUsersSortKey.NAME,
  });

  const sortManager = {
    sortState,
    setSort: (state: SortBy) => setSortState(state),
  };

  let entries = sortCollaborators(sortState, props.entries);
  if (props.disabledIds?.length) {
    entries = sortToTop(entries, props.disabledIds);
  }

  const joinTableEntries = entries.map((entry) =>
    ProgramUsersTableRow({
      entry,
      onClick: props.onToggleEntry,
      projectCount: props.projectCount,
      searchTerm: props.searchTerm,
    })
  );

  const onToggleTableCell = (index: number) => props.onToggleEntry(entries[index]);

  const selectedIndices = getSelectedIndices(entries, props.selectedIds, props.disabledIds);
  const selectableIndices = getSelectableIndices(entries);

  // this should only have a single value, the program owner
  const disabledIndices = getDisabledIndices(entries, props.disabledIds);

  const onSelectAll = () =>
    props.onSelectAll(
      filterEnabledSelectableEntries(
        entries.map((e) => ({ id: e.user.id })),
        selectableIndices,
        disabledIndices
      )
    );

  const onUnselectAll = () =>
    props.onUnselectAll(
      filterEnabledSelectableEntries(
        entries.map((e) => ({ id: e.user.id })),
        selectableIndices,
        disabledIndices
      )
    );

  return (
    <TableSelectable
      columnWidths={usersTableGridWidth}
      disabledIndices={disabledIndices}
      entries={joinTableEntries}
      headerContent={membersHeaderContent}
      loading={props.loading}
      onSelectAll={onSelectAll}
      onToggleEntry={onToggleTableCell}
      onUnselectAll={onUnselectAll}
      selectableIndices={selectableIndices}
      selectedIndices={selectedIndices}
      sortManager={sortManager}
    />
  );
}
