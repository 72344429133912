import { getTextWidth } from '../../../utilities/string';

import { DEFAULT_MAX_WIDTH, NavigationBarEntry } from './NavBreadcrumb/NavBreadcrumbUtils';
import { ARROW_WIDTH, MENU_WIDTH } from './NavigationBarStyles';

type TruncatedEntry = {
  maxWidth: number;
  textWidth: number;
};

export type NavigationBarTruncatedEntry = NavigationBarEntry & TruncatedEntry;

const getIsTruncated = (width: number, currentWidth: number) =>
  currentWidth >= width - DEFAULT_MAX_WIDTH - 2 * ARROW_WIDTH;

const getCrumbParams = (width: number, value: string, isLastOne: boolean) => {
  let maxWidth = DEFAULT_MAX_WIDTH;
  const textWidth = getTextWidth(value, 16);
  if (isLastOne) maxWidth = width / 2;
  const truncatedWidth = textWidth > maxWidth ? maxWidth : textWidth;
  const crumbWidth = ARROW_WIDTH + truncatedWidth;
  return { crumbWidth, maxWidth, textWidth };
};

const getNavigationBarEntries = (
  entries: NavigationBarEntry[],
  width: number
): [NavigationBarTruncatedEntry[], NavigationBarEntry[]] => {
  let currentWidth = 0;
  const renderBreadcrumbEntry = (e: NavigationBarEntry, index: number) => {
    const isLastOne = index === 0;
    const value = e.name;
    const { crumbWidth, maxWidth, textWidth } = getCrumbParams(width, value, isLastOne);
    currentWidth += crumbWidth;
    return { ...e, maxWidth, textWidth };
  };

  let index = 0;
  const breadcrumbs = [];
  const menuOptions = [...entries];
  while (menuOptions.length && !getIsTruncated(width, currentWidth)) {
    const entry = menuOptions.pop();
    if (!entry) break;
    breadcrumbs.push(renderBreadcrumbEntry(entry, index));
    index += 1;
  }

  return [breadcrumbs, menuOptions];
};

export const getNavigationBarParams = (entries: NavigationBarEntry[], totalWidth: number) => {
  const width = totalWidth - MENU_WIDTH - ARROW_WIDTH;

  // Prediction
  let [breadcrumbs, menuOptions] = getNavigationBarEntries(entries, width);
  let hasMenu = !!menuOptions.length;

  // Correction
  if (!hasMenu) [breadcrumbs, menuOptions] = getNavigationBarEntries(entries, totalWidth);
  const hasBreadcrumbs = !!breadcrumbs.length;
  hasMenu = !!menuOptions.length;

  return {
    breadcrumbEntries: breadcrumbs,
    hasBreadcrumbs,
    hasMenu,
    menuOptions,
  };
};
