import { FC, useState } from 'react';

import { withStyles } from '../../../theme/komodo-mui-theme';
import DialogsCompanyRoles from '../../Dialogs/DialogsCompanyRoles/DialogsCompanyRoles';
import { GroupsTwoTone } from '../../Icons/GroupsTwoTone';
import CTAIconButton from '../../shared-widgets/CTAIconButton';

import CompanyTabRoleInfoStyles from './CompanyTabRoleInfoStyles';

type CompanyTabRoleInfoProps = {
  classes: Classes<typeof CompanyTabRoleInfoStyles>;
  onClick?: () => void;
};

const CompanyTabRoleInfo: FC<CompanyTabRoleInfoProps> = ({ classes, onClick }) => {
  const [open, setOpen] = useState(false);
  const icon = <GroupsTwoTone className={classes.icon} />;
  return (
    <>
      <CTAIconButton
        buttonText="View Company Roles"
        icon={icon}
        isSmaller
        onClick={() => {
          setOpen(true);
          onClick?.();
        }}
        variant="outlined"
      />
      <DialogsCompanyRoles
        onClose={() => {
          setOpen(false);
        }}
        open={open}
      />
    </>
  );
};

export default withStyles(CompanyTabRoleInfoStyles)(CompanyTabRoleInfo);
