import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';

const HALF_SIDEBAR_WIDTH = '50%';
const FULL_SIDEBAR_WIDTH = '100%';

const styles = (theme: KomodoTheme) =>
  createStyles({
    avatarDisabled: { display: 'flex', alignItems: 'center', gap: '4px' },
    backgroundColor: { backgroundColor: theme.palette.backgroundWhite },
    captionColor: {
      color: theme.palette.joinPrimary,
      fontSize: 12,
    },
    date: {
      backgroundColor: theme.palette.ds.background.primary,
      '@media print': {
        width: 140,
      },
      '& svg': {
        '@media print': {
          display: 'none',
        },
      },
    },
    datePickerIcon: {
      color: theme.palette.joinPrimary,
      '&:hover': {
        backgroundColor: 'inherit',
      },
      height: 36,
      marginTop: 5,
      width: 36,
    },
    entryTopPadding: { paddingTop: '6px' },
    error: {
      display: 'none',
    },
    flexBetween: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    flexGrow: {
      flex: `0 0 calc(50% - 8px)`,
      gap: 2,
    },
    fullContainer: {
      width: FULL_SIDEBAR_WIDTH,
    },
    generalContainerHeight: {
      height: 'max-content',
      padding: '8px 18px',
      flexGrow: 1,
      backgroundColor: theme.palette.backgroundWhite,
      gap: '8px',
      display: 'flex',
      flexDirection: 'column',
    },
    generalContainerSections: { display: 'flex', gap: '8px' },
    halfContainer: {
      width: HALF_SIDEBAR_WIDTH,
      maxWidth: HALF_SIDEBAR_WIDTH,
    },
    picker: {
      ...theme.typography.body1,
      ...theme.typography.number,
      cursor: 'pointer',
      fontSize: 14,
      fontWeight: 300,
      padding: 9,
    },
    statusDisabled: { display: 'flex', gap: '4px' },
    title: {
      alignItems: 'center',
      display: 'flex',
      ...theme.typography.h3,
      overflow: 'hidden',
      padding: '4px 6px',
      color: theme.palette.joinPrimary,
      minHeight: 30,
      '@media print': {
        paddingLeft: 0,
      },
      wordBreak: 'break-word',
      overflowWrap: 'break-word',
    },
    titleInput: {
      paddingTop: 1,
    },
  });

export default styles;
