import { createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
export default (theme: any) =>
  createStyles({
    link: {
      textDecorationLine: 'none',
      cursor: 'pointer',
      flexShrink: 1,
      '&:hover': {
        textDecoration: 'underline',
        color: theme.palette.joinPrimary,
      },
      '&:focus': {
        outline: 'none',
      },
    },
  });
