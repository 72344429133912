import { Status } from '../../../../../../../generated/graphql';
import { ScenarioItemEntry } from '../../../../../common/ItemStatus/ItemStatusUtils';

export const getItemOptionStatusChanges = (item: ScenarioItemEntry, selectedOptionID?: UUID) =>
  item.options.map((option) => {
    if (!selectedOptionID) {
      return {
        itemID: option.id,
        status: Status.PENDING,
      };
    }

    return {
      itemID: option.id,
      status: selectedOptionID === option.id ? Status.ACCEPTED : Status.NOTCHOSEN,
    };
  });
