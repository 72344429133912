import { FC } from 'react';

import useProjectPropsQuery from '../../../hooks/useProjectPropsQuery';
import { isNonNullable } from '../../../utilities/types';
import { getTeamGUID } from '../../assets/utils';
import { useActiveProjectBannerQuery } from '../../ProjectProperties/PrintHeader/hooks/GetActiveProjectBannerQuery';

import { PrintPageHeader } from './PrintPageHeader';

type PrintPageHeaderWrapperProps = {
  milestoneName?: string;
  projectId: UUID;
  reportName: string;
  triggerOnComplete?: () => void;
};

export const PrintPageHeaderWrapper: FC<PrintPageHeaderWrapperProps> = ({
  milestoneName,
  projectId,
  reportName,
  triggerOnComplete,
}) => {
  const projectPropsQueryResult = useProjectPropsQuery(projectId);
  const activeProjectBannerQueryResult = useActiveProjectBannerQuery(projectId);

  const loading = projectPropsQueryResult.loading || activeProjectBannerQueryResult.loading;
  if (loading) return null;

  const { team, name: projectName = '' } = projectPropsQueryResult.data?.project ?? {};
  const { designTeamGUID, generalContractorGUID, ownerGUID } = getTeamGUID(team);
  const teamsIDs = [designTeamGUID, generalContractorGUID, ownerGUID];
  const bannerID = activeProjectBannerQueryResult.data?.activeProjectBanner?.asset?.id;
  const bannerOrTeamsIDs = bannerID ? [bannerID] : teamsIDs;

  const onCompleted = () => {
    setTimeout(() => triggerOnComplete?.(), 0);
  };

  return (
    <PrintPageHeader
      assetIDs={bannerOrTeamsIDs.filter((id) => {
        return isNonNullable(id) && id !== '';
      })}
      milestoneName={milestoneName}
      onCompleted={onCompleted}
      projectName={projectName}
      reportName={reportName}
    />
  );
};
