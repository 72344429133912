import {
  AverageCompInput,
  ProjectCompInput,
  ProjectCompsCostTableCellType,
} from '../../../generated/graphql';
import { hasMatchingCategoryContent } from '../../../utilities/category';

export function resetCategoryLine(
  prevState: AverageCompInput | ProjectCompInput,
  category: CategoryContentInput
) {
  // remove category line from category line inputs
  return (
    prevState.categoryLineInputs?.filter(
      (input) => !hasMatchingCategoryContent(category, input.category)
    ) ?? []
  );
}

export function createOrUpdateCategoryLineInputCost(
  prevState: AverageCompInput | ProjectCompInput,
  category: CategoryContentInput,
  cellType: ProjectCompsCostTableCellType,
  editedValue: number,
  unitID?: UUID
) {
  // create or update category line input to include new editedValue and cellType
  const initialCategoryLineInput = prevState.categoryLineInputs?.find((categoryLineInput) =>
    hasMatchingCategoryContent(
      category,
      categoryLineInput.category,
      unitID,
      categoryLineInput.unitID
    )
  ) ?? { category, unitID };
  const categoryLineInputs =
    prevState.categoryLineInputs?.filter(
      (categoryLineInput) => !hasMatchingCategoryContent(category, categoryLineInput.category)
    ) ?? [];
  categoryLineInputs.push({
    ...initialCategoryLineInput,
    editedValue,
    cellType,
  });
  return categoryLineInputs;
}

export function createOrUpdateCategoryLineInputCostDescription(
  prevState: AverageCompInput | ProjectCompInput,
  category: CategoryContentInput,
  description: string
) {
  // create or update category line input to include new description
  const initialCategoryLineInput = prevState.categoryLineInputs?.find((categoryLineInput) =>
    hasMatchingCategoryContent(category, categoryLineInput.category)
  ) ?? { category, cellType: ProjectCompsCostTableCellType.QUANTITY, editedValue: 0 };
  const categoryLineInputs =
    prevState.categoryLineInputs?.filter(
      (categoryLineInput) => !hasMatchingCategoryContent(category, categoryLineInput.category)
    ) ?? [];
  categoryLineInputs.push({
    ...initialCategoryLineInput,
    description,
  });
  return categoryLineInputs;
}

export function resetMarkupLine(
  prevState: AverageCompInput | ProjectCompInput,
  markupName: string
) {
  // remove markup line from markup line inputs
  return (
    prevState.markupLineInputs?.filter(
      (markupLineInput) => markupLineInput.markupName !== markupName
    ) ?? []
  );
}

export function updateMarkupLineInputName(
  prevState: AverageCompInput | ProjectCompInput,
  previousMarkupName: string,
  newMarkupName: string
) {
  // Find the current markup line input and exit if it doesn't.
  const initialMarkupLineInput = prevState.markupLineInputs?.find(
    (markupLineInput) => markupLineInput.markupName === previousMarkupName
  );

  // We don't create an input by default here.
  // This ensures that we don't mark the default empty value as "changed"
  if (!initialMarkupLineInput) return prevState.markupLineInputs;

  const markupLineInputs =
    prevState.markupLineInputs?.filter(
      (markupLineInput) => markupLineInput.markupName !== previousMarkupName
    ) ?? [];
  markupLineInputs.push({
    ...initialMarkupLineInput,
    markupName: newMarkupName,
  });
  return markupLineInputs;
}

export function createOrUpdateMarkupLineInputCost(
  prevState: AverageCompInput | ProjectCompInput,
  markupName: string,
  cellType: ProjectCompsCostTableCellType,
  editedValue: number,
  unitID?: UUID
) {
  // create or update markup line input to include new editedValue and cellType
  const initialMarkupLineInput = prevState.markupLineInputs?.find(
    (markupLineInput) => markupName === markupLineInput.markupName
  ) ?? { markupName, unitID };
  const markupLineInputs =
    prevState.markupLineInputs?.filter(
      (markupLineInput) => markupName !== markupLineInput.markupName
    ) ?? [];
  markupLineInputs.push({
    ...initialMarkupLineInput,
    editedValue,
    cellType,
  });
  return markupLineInputs;
}

export function createOrUpdateMarkupLineInputCostDescription(
  prevState: AverageCompInput | ProjectCompInput,
  markupName: string,
  description: string
) {
  // create or update markup line input to include new description
  const initialMarkupLineInput = prevState.markupLineInputs?.find(
    (markupLineInput) => markupLineInput.markupName === markupName
  ) ?? { markupName, cellType: ProjectCompsCostTableCellType.QUANTITY, editedValue: 0 };
  const markupLineInputs =
    prevState.markupLineInputs?.filter(
      (markupLineInput) => markupLineInput.markupName !== markupName
    ) ?? [];
  markupLineInputs.push({
    ...initialMarkupLineInput,
    description,
  });
  return markupLineInputs;
}
