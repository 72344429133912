import { getItemStatusLabel } from '../../../../utilities/item-status';
import ItemsIconsMap from '../../../ItemsList/ItemsIcons/ItemsIconsMap';

type Props = {
  status: string;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  hideLabel?: boolean;
};

const ItemStatusDisplay = (props: Props) => (
  <div className="flex items-center gap-1 whitespace-nowrap">
    <ItemsIconsMap status={props.status} variant="small" />
    {!props.hideLabel && (
      <div className="type-label">{getItemStatusLabel(props.status, { short: true })}</div>
    )}
  </div>
);

export default ItemStatusDisplay;
