/* eslint-disable jsx-a11y/click-events-have-key-events */
import { FC, ReactNode } from 'react';

import { Collapse } from '@material-ui/core';

import useSendAnalytics, { EventProperties } from '../../../hooks/useSendAnalytics';
import CollapseIcon from '../../shared-widgets/CollapseIcon';

type ItemDetailsCollapseProps = {
  analyticsEvent?: { type: string; eventProperties: EventProperties };
  canExpand: boolean;
  dataCy?: string;
  children: ReactNode;
  hasGrayBackground?: boolean;
  headerContent: JSX.Element;
  isExpanded: boolean;
  isReadOnly?: boolean;
  setIsExpanded: (expanded: boolean) => void;
  style?: Record<string, string | number>;
};

const ItemDetailsCollapse: FC<ItemDetailsCollapseProps> = ({
  analyticsEvent,
  dataCy = 'itemEstimate',
  canExpand = false,
  children,
  headerContent,
  hasGrayBackground = true,
  isExpanded,
  isReadOnly = false,
  setIsExpanded,
  style,
}) => {
  const sendAnalytics = useSendAnalytics();

  const isCollapsable = !isReadOnly && canExpand;
  const headerStyle = [
    'pt-1',
    'pr-[30px]',
    'pb-1',
    'items-center',
    'flex',
    'flex-wrap',
    'gap-1',
    'min-h-[48px]',
  ];
  if (isCollapsable) {
    headerStyle.push('pl-5');
    headerStyle.push('cursor-pointer');
  } else {
    headerStyle.push('pl-3');
    headerStyle.push('cursor-default');
  }

  if (hasGrayBackground) headerStyle.push('bg-background-2');
  const collapseChildren = (
    <div className={headerStyle.join(' ')}>
      {isCollapsable && <CollapseIcon className="l-2 relative" isCollapsed={!isExpanded} />}
      {headerContent}
    </div>
  );

  return (
    <div className={`w-full ${!isReadOnly ? 'border-2 border-border-muted' : ''}`}>
      <div
        aria-disabled={!canExpand}
        data-cy={`collapse-${dataCy}`}
        onClick={() => {
          if (canExpand) {
            setIsExpanded(!isExpanded);
            if (analyticsEvent) sendAnalytics(analyticsEvent);
          }
        }}
        style={style}
      >
        <Collapse
          className="min-h-[48px]"
          collapsedHeight="40"
          data-cy="button-addOption"
          in={isExpanded}
        >
          {collapseChildren}
        </Collapse>
      </div>
      {canExpand && (
        <Collapse in={isExpanded} unmountOnExit>
          {children}
        </Collapse>
      )}
    </div>
  );
};

export default ItemDetailsCollapse;
