import { FC, useState } from 'react';
import { DragDropContext } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';

import { Divider, Typography, withStyles } from '@material-ui/core';

import { UnitCount } from '../../../../generated/graphql';
import theme from '../../../../theme/komodo-mui-theme';
import NormalTooltipInfoIcon from '../../../NormalTooltip/NormalTooltipInfoIcon';

import MetricsChip from './MetricsChip';
import MetricsDragChip from './MetricsDragChip';
import styles from './MetricsSelectionDialogStyles';

const getUnitCountMap = (unitCounts: UnitCount[]) => {
  const unitIDCountTextMap = new Map<string, string>();
  unitCounts.map((uc: UnitCount) => unitIDCountTextMap.set(uc.unitID, uc.countText));
  return unitIDCountTextMap;
};

type MetricsSelectionProps = {
  classes: Classes<typeof styles>;
  selectedUnits: Unit[];
  setSelectedUnits: (units: Unit[]) => void;
  unitCounts: UnitCount[];
  units: Unit[];
};

const MetricsSelection: FC<MetricsSelectionProps> = ({
  classes,
  selectedUnits,
  setSelectedUnits,
  unitCounts,
  units,
}) => {
  const [rerender, setRerender] = useState(false);
  const notSelectedUnits = units.filter((unit: Unit) => !selectedUnits.includes(unit));
  const unitIDCountTextMap = getUnitCountMap(unitCounts);

  const onToggleUnitSelected = (unitID: UUID) => {
    const isUnitSelected = selectedUnits.find((unit: Unit) => unit.id === unitID);
    if (!isUnitSelected) {
      const updatedSelectedUnits = [...selectedUnits];
      const selectedUnit = units.find((unit: Unit) => unit.id === unitID);
      if (selectedUnit) {
        updatedSelectedUnits.push(selectedUnit);
      }
      setSelectedUnits(updatedSelectedUnits);
    } else {
      const updatedSelectedLocalUnits = selectedUnits.filter((unit: Unit) => unit.id !== unitID);
      setSelectedUnits(updatedSelectedLocalUnits);
    }
  };

  const sortCards = (dragIndex: number, hoverIndex: number) => {
    const previousUnits = [...selectedUnits];
    const reorderable = previousUnits.splice(dragIndex, 1);
    previousUnits.splice(hoverIndex, 0, reorderable[0]);
    setSelectedUnits(previousUnits);
    setRerender(!rerender);
  };

  return (
    <>
      <div className={classes.topHeaderRow}>
        <Typography className={classes.sectionHeader}>Visible in report</Typography>
        <NormalTooltipInfoIcon title="In “X of Y projects”, the first number represents the amount of projects with the enabled metric. The second number represents the total number of projects in the report." />
      </div>
      <Typography className={classes.sectionSubheader}>
        Click and drag to reorder how rows appear in the report
      </Typography>
      <Divider style={{ backgroundColor: theme.palette.notapplicable }} />
      {selectedUnits &&
        selectedUnits.length > 0 &&
        selectedUnits.map((unit: Unit, index: number) => (
          <MetricsDragChip
            key={unit.id}
            id={unit.id}
            index={index}
            onSelect={onToggleUnitSelected}
            sortCards={sortCards}
            text={`${unit.name} (${unit.abbreviationSingular})`}
            unitCountText={unitIDCountTextMap.get(unit.id) ?? ''}
          />
        ))}
      <Typography className={classes.sectionHeader}>Hidden in report</Typography>
      <Typography className={classes.sectionSubheader}>
        Select the eye icon to show a metric
      </Typography>
      <Divider style={{ backgroundColor: theme.palette.notapplicable }} />
      {notSelectedUnits &&
        notSelectedUnits.length > 0 &&
        notSelectedUnits.map((unit: Unit) => (
          <MetricsChip
            key={unit.id}
            id={unit.id}
            onSelect={onToggleUnitSelected}
            text={`${unit.name} (${unit.abbreviationSingular})`}
            unitCountText={unitIDCountTextMap.get(unit.id) ?? ''}
          />
        ))}
    </>
  );
};

const StyledMetricsSelectionComponent = withStyles(styles)(MetricsSelection);
export default DragDropContext(HTML5Backend)(StyledMetricsSelectionComponent);
