import { useMutation } from '@apollo/client';

import {
  SetMilestoneDescriptionMutation,
  SetMilestoneDescriptionMutationVariables,
} from '../../../generated/graphql';

import { setMilestoneDescriptionMutation } from './queries';

/*
- useUpdateMilestoneDescription
*/

function useUpdateMilestoneDescription(projectID: UUID) {
  const [setMilestoneDescription, mutationResult] = useMutation<
    SetMilestoneDescriptionMutation,
    SetMilestoneDescriptionMutationVariables
  >(setMilestoneDescriptionMutation);
  const updateMilestoneDescriptionFunc = (milestone: Milestone) => {
    const { description, descriptionStyled, id: milestoneID } = milestone;
    const variables = { projectID, input: { milestoneID, description, descriptionStyled } };
    setMilestoneDescription({ variables });
  };
  return [updateMilestoneDescriptionFunc, mutationResult] as const;
}

export default useUpdateMilestoneDescription;
