import {
  commonProjectFields,
  detailedMilestoneFields,
  gql,
  quantityFields,
  unitFields,
} from '../../../api/graphqlFragments';

export const getMilestoneItemCountQuery = gql`
  query getMilestoneItemCount($id: UUID!) {
    itemCounts(id: $id) {
      rejected
      pending
      accepted
      incorporated
      total
    }
  }
`;

const commonMilestonePublishDraftFields = gql`
  fragment commonMilestonePublishDraftFields on Milestone {
    id
    name
    description
    deletable
    date
    isDraft
    createdBy {
      id
      email
      name
    }
  }
`;

export const setMilestonePublishDraftMutation = gql`
  mutation setMilestonePublishDraft($projectID: UUID!, $input: SetMilestonePublishDraftInput!) {
    setMilestonePublishDraft(projectID: $projectID, milestone: $input) {
      ...commonMilestonePublishDraftFields
    }
  }
  ${commonMilestonePublishDraftFields}
`;

const sourceMilestoneFields = gql`
  fragment sourceMilestoneFields on Milestone {
    id
    name
  }
`;

export const getSourceMilestoneQuery = gql`
  query getSourceMilestone($id: UUID!) {
    milestone(id: $id) {
      ...sourceMilestoneFields
    }
  }
  ${sourceMilestoneFields}
`;

const commonBucketFields = gql`
  fragment commonBucketFields on Bucket {
    id
    milestone {
      id
      name
      date
    }
    name
    date
    itemsCount
  }
`;

export const createOrUpdateBucketMutation = gql`
  mutation createOrUpdateBucket(
    $projectID: UUID!
    $id: UUID
    $milestone: UUID!
    $name: String
    $date: Time
  ) {
    createOrUpdateBucket(
      projectID: $projectID
      bucket: { id: $id, milestone: $milestone, name: $name, date: $date }
    ) {
      ...commonBucketFields
    }
  }
  ${commonBucketFields}
`;

export const deleteBucketMutation = gql`
  mutation deleteBucket($projectID: UUID!, $bucket: UUID!) {
    deleteBucket(projectID: $projectID, bucket: $bucket) {
      ...commonBucketFields
    }
  }
  ${commonBucketFields}
`;

export const getProjectUnitsQuery = gql`
  query getProjectUnits($projectID: UUID!, $isEnabledOnly: Boolean!) {
    getProjectUnits(projectID: $projectID, isEnabledOnly: $isEnabledOnly) {
      ...unitFields
    }
  }
  ${unitFields}
`;

export const toggleProjectUnitsMutation = gql`
  mutation toggleProjectUnits($projectID: UUID!, $units: [UnitToggleInput!]!) {
    toggleProjectUnits(projectID: $projectID, units: $units) {
      ...unitFields
    }
  }
  ${unitFields}
`;

export const createOrUpdateCustomUnitMutation = gql`
  mutation createOrUpdateCustomUnit($unit: UnitInput!, $projectID: UUID!) {
    createOrUpdateCustomUnit(unit: $unit, projectID: $projectID) {
      ...unitFields
    }
  }
  ${unitFields}
`;

export const removeCustomUnitMutation = gql`
  mutation removeCustomUnit($projectID: UUID!, $unitID: UUID!) {
    removeCustomUnit(projectID: $projectID, unitID: $unitID)
  }
`;

export const createQuantityMutation = gql`
  mutation createQuantity(
    $projectID: UUID!
    $milestoneID: UUID!
    $unitID: UUID!
    $magnitude: Numeric!
  ) {
    createQuantity(
      projectID: $projectID
      quantity: { unitID: $unitID, magnitude: $magnitude }
      milestoneID: $milestoneID
    ) {
      ...quantityFields
    }
  }
  ${quantityFields}
`;

export const addQuantityBreakdownMutation = gql`
  mutation addQuantityBreakdown(
    $projectID: UUID!
    $milestoneID: UUID!
    $quantityID: UUID
    $unitID: UUID
    $fields: [FieldInput!]!
  ) {
    addQuantityBreakdown(
      projectID: $projectID
      quantity: { quantityID: $quantityID, unitID: $unitID, fields: $fields }
      milestoneID: $milestoneID
    ) {
      ...quantityFields
    }
  }
  ${quantityFields}
`;

export const removeQuantityBreakdownMutation = gql`
  mutation removeQuantityBreakdown(
    $projectID: UUID!
    $milestoneID: UUID!
    $quantityID: UUID!
    $estimateID: UUID!
    $unitID: UUID!
    $magnitude: Numeric!
  ) {
    removeQuantityBreakdown(
      projectID: $projectID
      quantity: {
        quantityID: $quantityID
        estimateID: $estimateID
        unitID: $unitID
        magnitude: $magnitude
      }
      milestoneID: $milestoneID
    ) {
      ...quantityFields
    }
  }
  ${quantityFields}
`;

export const getMilestoneQuantitiesQuery = gql`
  query getMilestoneQuantities($milestoneID: UUID!) {
    quantities(milestoneID: $milestoneID) {
      ...quantityFields
    }
  }
  ${quantityFields}
`;

export const deleteMilestonesMutation = gql`
  mutation deleteMilestones($projectID: UUID!, $milestoneID: UUID!) {
    updateProject(project: { id: $projectID, milestoneID: $milestoneID }) {
      ...commonProjectFields
    }
  }
  ${commonProjectFields}
`;

export const setMilestoneNameMutation = gql`
  mutation setMilestoneName($projectID: UUID!, $input: SetMilestoneNameInput) {
    setMilestoneName(projectID: $projectID, milestone: $input) {
      ...detailedMilestoneFields
    }
  }
  ${detailedMilestoneFields}
`;

export const setMilestoneDescriptionMutation = gql`
  mutation setMilestoneDescription($projectID: UUID!, $input: SetMilestoneDescriptionInput) {
    setMilestoneDescription(projectID: $projectID, milestone: $input) {
      ...detailedMilestoneFields
    }
  }
  ${detailedMilestoneFields}
`;

export const setMilestoneDateMutation = gql`
  mutation setMilestoneDate($projectID: UUID!, $input: SetMilestoneDateInput) {
    setMilestoneDate(projectID: $projectID, milestone: $input) {
      ...detailedMilestoneFields
    }
  }
  ${detailedMilestoneFields}
`;

export const setMilestoneDesignPhaseMutation = gql`
  mutation setMilestoneDesignPhase($projectID: UUID!, $input: SetMilestoneDesignPhaseInput!) {
    setMilestoneDesignPhase(projectID: $projectID, milestone: $input) {
      ...detailedMilestoneFields
    }
  }
  ${detailedMilestoneFields}
`;

export const setMilestoneTimelinePhaseMutation = gql`
  mutation setMilestoneTimelinePhase($projectID: UUID!, $input: SetMilestoneTimelinePhaseInput!) {
    setMilestoneTimelinePhase(projectID: $projectID, input: $input) {
      ...detailedMilestoneFields
    }
  }
  ${detailedMilestoneFields}
`;

export const getMilestoneQuery = gql`
  query getMilestone($id: UUID!) {
    milestone(id: $id) {
      ...detailedMilestoneFields
    }
  }
  ${detailedMilestoneFields}
`;

export const getDesignPhaseTypes = gql`
  query designPhaseTypes {
    designPhaseTypes {
      id
      name
      abbreviation
    }
  }
`;
