import { FC } from 'react';

import Comments from '../Icons/Comments';

type CommentsChipProps = {
  commentCount: number;
};

const CommentsChip: FC<CommentsChipProps> = ({ commentCount }) => {
  if (!commentCount) return null; // Don't display if no comments.

  return (
    <div className="color flex flex-row items-center gap-1" data-cy="item-list-comment">
      <div className="flex h-4 w-4">
        <Comments />
      </div>
      <div className="type-label">{commentCount}</div>
    </div>
  );
};

export default CommentsChip;
