import { NS_PROGRAM_REPORTING } from '../../features';
import HasFeatureFlag from '../HasFeatureFlag';

import ProgramDashboardRoute from './ProgramDashboardRoute';

export default function ProgramReportingRoute() {
  return (
    <HasFeatureFlag flag={NS_PROGRAM_REPORTING}>
      <ProgramDashboardRoute />
    </HasFeatureFlag>
  );
}
