import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';

const BUTTON_SIZE = 32;
const HORIZONTAL_PADDING = 2;
export const ARROW_WIDTH = BUTTON_SIZE * HORIZONTAL_PADDING;
export const MENU_WIDTH = 40;

const Styles = (theme: KomodoTheme) =>
  createStyles({
    arrow: {
      color: theme.palette.shadedGrey,
      height: BUTTON_SIZE,
      padding: `0px ${HORIZONTAL_PADDING}px 0px ${HORIZONTAL_PADDING}px`,
      width: BUTTON_SIZE,
    },
    breadcrumb: {
      direction: 'ltr',
      display: 'flex',
    },
    centerAligned: {
      alignSelf: 'center',
    },
    container: {
      alignItems: 'end',
      direction: 'rtl',
      display: 'flex',
      height: 40,
    },
    fullWidth: {
      width: '100%',
    },
    hidden: {
      opacity: 0,
      padding: 0,
      width: 0,
    },
    horizontal: {
      display: 'flex',
    },
    pushLeft: {
      marginRight: 'auto',
    },
  });

export default Styles;
