import { Outlet } from 'react-router-dom';

import { JoinCompanyStandardsRoutes } from '../../../api/gqlEnums';
import {
  COMPANY_CATEGORIZATIONS,
  COMPANY_PROJECT_TEMPLATES,
  COMPANY_PROJECT_TYPES,
} from '../../../constants';
import { useIsRouteAccessible } from '../../../hooks/useIsRouteAccessible';
import { SharedRoute } from '../../../routes/routes';
import { generateSharedPath } from '../../../utilities/routes/links';
import { ListDetail } from '../../scales';

import CompanyTabHeader from './CompanyTabHeader';
import StandardsSidebar from './StandardsSidebar';

export default function Standards() {
  const { isAccessible } = useIsRouteAccessible();
  const links = companyStandardsLinks(isAccessible);

  return (
    <ListDetail
      detail={<Outlet />}
      header={<CompanyTabHeader title="Company Standards" />}
      list={<StandardsSidebar links={links} />}
    />
  );
}

const companyStandardsLinks = (isAccessible: (route: SharedRoute) => boolean) => {
  const links = [
    {
      name: COMPANY_CATEGORIZATIONS,
      route: {
        path: generateSharedPath(JoinCompanyStandardsRoutes.COMPANY_CATEGORIZATIONS, {}),
      },
    },
    {
      name: COMPANY_PROJECT_TEMPLATES,
      route: { path: generateSharedPath(JoinCompanyStandardsRoutes.COMPANY_PROJECT_TEMPLATES, {}) },
    },
    {
      name: COMPANY_PROJECT_TYPES,
      route: {
        path: generateSharedPath(JoinCompanyStandardsRoutes.COMPANY_PROJECT_TYPES, {}),
      },
    },
  ];

  const filteredLinks = links.filter((link) => isAccessible(link.route));
  return filteredLinks;
};
