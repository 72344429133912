import { gql } from '../../../../api/graphqlFragments';

export const setDisabledProjectCategorizationsMutation = gql`
  mutation setDisabledProjectCategorizations(
    $projectID: UUID!
    $categorizationIDs: [UUID!]!
    $disable: Boolean!
  ) {
    setDisabledProjectCategorizations(
      projectID: $projectID
      categorizationIDs: $categorizationIDs
      disable: $disable
    )
  }
`;
