import { SortManager, Table, TableCell, TableHeader } from '../../../scales';

type props = {
  entries: TableCell[][];
  headerContent: TableHeader[];
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  loading?: boolean;
  sortManager: SortManager;
};

export default function DistributionHistoryTable(props: props) {
  const {
    entries: entriesOuter,
    headerContent: headerContentOuter,
    loading = false,
    sortManager,
  } = props;

  const columnWidthsOuter = ['0.2fr', '0.2fr', '0.2fr', '0.15fr', '0.2fr', '0.05fr'];

  const columnWidths = columnWidthsOuter.map((width) => `minmax(0, ${width})`);

  return (
    <Table
      columnWidths={columnWidths}
      entries={entriesOuter}
      hasParentScroll={false}
      headerContent={headerContentOuter}
      isCompact={false}
      isLoading={loading}
      onNeedMoreData={() => {}}
      onScroll={() => {}}
      rowHeight="auto"
      sortManager={sortManager}
    />
  );
}
