import { FC } from 'react';

import { useReactiveVar } from '@apollo/client';

import { itemSidebarOpenVar } from '../../../api/apollo/reactiveVars';
import { OpenSidebar } from '../../Icons/OpenSidebar';
import { Button } from '../../scales';

type Props = {
  itemID: UUID;
  eventID: UUID;
  selectedEventID?: UUID | null;
  setSelectedEventID?: (eventID: UUID | null) => void;
};

const ItemHistorySidebarButton: FC<Props> = ({
  itemID,
  eventID,
  selectedEventID,
  setSelectedEventID,
}) => {
  const isSidebarOpen = !!useReactiveVar(itemSidebarOpenVar);
  const isSelectedEvent = eventID === selectedEventID;

  const handleClick = () => {
    if (!isSidebarOpen || !isSelectedEvent) {
      itemSidebarOpenVar(itemID);
      setSelectedEventID?.(eventID);
    } else {
      itemSidebarOpenVar(null);
      setSelectedEventID?.(null);
    }
  };

  return (
    <div
      className={`my-auto h-10 group-hover:block ${isSelectedEvent ? 'block' : 'hidden'}`}
      data-cy="item-activity-sidebar-button"
    >
      <Button
        label="Details"
        onClick={handleClick}
        startIcon={<OpenSidebar className={isSidebarOpen ? 'rotate-180' : ''} />}
        type="secondary"
      />
    </div>
  );
};

export default ItemHistorySidebarButton;
