import { SearchResultType } from '../../../../../generated/graphql';
import useCoreSearchPermissions from '../../hooks/useCoreSearchPermissions';
import { DEFAULT_ITEMS_SEARCH_QUERY } from '../SearchItemsUtils';

import { useSearchItemsQuery } from './useSearchItemsQuery';

export default () => {
  const { hasAllItemsAccess, hasItemsAccess } = useCoreSearchPermissions();

  const allItemsCount = useSearchItemsQuery(
    {
      ...DEFAULT_ITEMS_SEARCH_QUERY,
      searchResultType: SearchResultType.ALL,
      pagination: { limit: 25, offset: 0 },
    },
    { skip: !hasAllItemsAccess }
  ).data?.searchItems?.total;

  const myItemsCount = useSearchItemsQuery(
    {
      ...DEFAULT_ITEMS_SEARCH_QUERY,
      searchResultType: SearchResultType.MY,
      pagination: { limit: 25, offset: 0 },
    },
    { skip: !hasItemsAccess }
  ).data?.searchItems?.total;

  const itemsCount = {
    all: allItemsCount ?? 0,
    my: myItemsCount ?? 0,
  };

  return itemsCount;
};
