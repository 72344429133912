import { FC } from 'react';

import { Add } from '@material-ui/icons/';

import { Button } from '../../scales';

type ItemsButtonAddOptionProps = {
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  disabled?: boolean;
  setOpenDialogAddOption: (b: boolean) => void;
  type: 'primary' | 'secondary';
};

const ItemsButtonAddOption: FC<ItemsButtonAddOptionProps> = ({
  disabled = false,
  setOpenDialogAddOption,
  type,
}) => (
  <Button
    isDisabled={disabled}
    label="Add Options"
    onClick={() => {
      setOpenDialogAddOption(true);
    }}
    startIcon={<Add />}
    type={type}
  />
);

export default ItemsButtonAddOption;
