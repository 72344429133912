import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { SearchToggleValue } from '../../SearchToggle';

export const useUpdateSearchProjectsFromURL = (
  setSearch: (search: string) => void,
  resetFilters: () => void,
  setToggle?: (toggleValue: SearchToggleValue) => void
) => {
  const location = useLocation();
  const navigate = useNavigate();
  const search = new URLSearchParams(location.search).get('search');
  const toggleValue = new URLSearchParams(location.search).get('toggle');

  useEffect(() => {
    const params = new URLSearchParams(document.location.search);
    if (search !== null) {
      setSearch(search);
      resetFilters();
      params.delete('search');
    }
    if (toggleValue !== null) {
      if (setToggle) {
        if (toggleValue === SearchToggleValue.ALL) setToggle(SearchToggleValue.ALL);
        else setToggle(SearchToggleValue.MY);
      }
      params.delete('toggle');
    }
    if (search !== null || toggleValue !== null) navigate(`?${params.toString()}`);
  }, [navigate, toggleValue, setToggle, search, setSearch, resetFilters]);
};
