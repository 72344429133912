import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';

const ExecutiveDashboardProjectMapStyles = (theme: KomodoTheme) =>
  createStyles({
    avatar: {
      backgroundColor: 'transparent',
      height: 20,
      width: 20,
      top: 3,
      display: 'inline',
    },
    container: {
      display: 'flex',
      height: '100%',
      justifyContent: 'center',
      position: 'relative',
    },
    cost: {
      ...theme.typography.number,
      fontSize: Number(theme.typography.chart.fontSize),
    },
    extraProjects: {
      color: theme.palette.chartDarkGrey,
      fontSize: Number(theme.typography.chart.fontSize),
    },
    hintContainer: {
      background: theme.palette.backgroundWhite,
      borderRadius: theme.border.radius,
      boxShadow: theme.hintShadow,
      maxWidth: 300,
      padding: '7px 10px',
      pointerEvents: 'none',
      textAlign: 'left',
      top: 0,
      whiteSpace: 'nowrap',
      zIndex: 2,
    },
    iconError: {
      color: theme.palette.backgroundGrey,
      fontSize: 14,
    },
    label: {
      fontFamily: theme.typography.fontFamily,
      fontSize: 12,
      display: 'inline',
      paddingLeft: 4,
    },
    lastRow: {
      paddingTop: 4,
    },
    loading: {
      background: theme.palette.backgroundWhite,
      height: '100%',
      position: 'absolute',
      width: '100%',
      zIndex: 2,
    },
    location: {
      fontFamily: theme.typography.fontFamily,
      fontSize: 12,
    },
    locationlessProjects: {
      position: 'absolute',
      width: 275,
      backgroundColor: theme.palette.joinPrimary,
      zIndex: 1,
      opacity: 0.4,
      color: theme.palette.backgroundGrey,
      height: 16,
      borderRadius: 13,
      padding: 5,
      top: 10,
      display: 'flex',
      justifyContent: 'center',
    },
    map: {
      height: '100%',
      position: 'absolute',
      width: '100%',
      '&.mapboxgl-map': {
        overflow: 'visible',
        fontFamily: theme.typography.fontFamily,
      },
      '& .mapboxgl-ctrl-bottom-left': {
        '& .mapboxgl-ctrl': {
          margin: '0 0 10px 0',
        },
        '& .mapboxgl-ctrl-logo': {
          transform: 'scale(0.75)',
        },
      },
      '& .mapboxgl-ctrl-attrib': {
        fontSize: 9,
      },
      '& .mapboxgl-ctrl-attrib.mapboxgl-compact': {
        padding: '0 18px 0 0',
        borderRadius: 9,
      },
      '& .mapboxgl-ctrl-attrib.mapboxgl-compact-show': {
        padding: '0 21px 0 6px',
      },
      '& .mapboxgl-ctrl-attrib-button': {
        backgroundBlendMode: 'saturation',
        backgroundImage: `url("data:image/svg+xml;charset=utf-8,%3Csvg width='18' height='18' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg' fill-rule='evenodd'%3E%3Cpath d='M4 10a6 6 0 1012 0 6 6 0 10-12 0m5-3a1 1 0 102 0 1 1 0 10-2 0m0 3a1 1 0 112 0v3a1 1 0 11-2 0'/%3E%3C/svg%3E")`,
        width: 18,
        height: 18,
        borderRadius: 9,
      },
    },
    name: {
      fontFamily: theme.typography.fontFamily,
      ...theme.typography.h3TableHeader,
      marginTop: 6,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    popup: {
      position: 'fixed',
      zIndex: 2,
      '& .mapboxgl-popup-tip': {
        display: 'none',
      },
      '& .mapboxgl-popup-content': {
        background: 'none',
        border: 'none',
        boxShadow: 'none',
        padding: 0,
        visibility: 'hidden',
      },
    },
    locationContainer: {
      background: theme.palette.backgroundWhite,
      borderRadius: theme.border.radius,
      boxShadow: theme.hintShadow,
      fontSize: 12,
      padding: '7px 10px',
      position: 'relative',
      textAlign: 'left',
      visibility: 'visible',
      whiteSpace: 'nowrap',
    },
    popupContent: {
      '& > div': {
        display: 'flex',
        justifyContent: 'space-between',
      },
    },
    project: {
      alignSelf: 'center',
      top: 3,
    },
    status: {
      fontFamily: theme.typography.fontFamily,
      fontSize: 12,
      marginRight: 6,
    },
  });

export default ExecutiveDashboardProjectMapStyles;
