import { FC, useEffect, useState } from 'react';
import { useEffectOnce } from 'react-use';

import { useAuth0 } from '@auth0/auth0-react';

import {
  SIGN_UP_RESEND_EMAIL_CTA,
  SIGN_UP_VERIFY_EMAIL_VIEW,
} from '../../../../../actions/actionTypes';
import { analyticsEvent } from '../../../../../analytics/analyticsEventProperties';
import useSendAnalytics from '../../../../../hooks/useSendAnalytics';
import { useCurrentUser } from '../../../../contexts/current-user';
import SignupContainer from '../../SignupContainer';
import SignupContentBox from '../../SignupContentBox';

import EmailVerification from './EmailVerification';
import { useSendEmailVerificationMutation } from './EmailVerificationHooks';

const EmailVerificationSendUser: FC = () => {
  document.title = `Join - Sign Up`;
  const currentUser = useCurrentUser();

  const sendAnalytics = useSendAnalytics();
  useEffectOnce(() => {
    sendAnalytics(analyticsEvent(SIGN_UP_VERIFY_EMAIL_VIEW));
  });

  const { location } = window;
  const { origin } = location;
  const sendEmailCall = useSendEmailVerificationMutation(origin);

  const [emailSent, setEmailSent] = useState(false);
  const sendEmail = () => {
    sendEmailCall();
    setEmailSent(true);
  };

  const { logout } = useAuth0();

  // Effect for verify email on page
  useEffect(() => {
    if (!emailSent) sendEmail();
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO CT-566: Fix this pls :)
  }, [emailSent]);

  const onResendCta = () => {
    sendAnalytics(analyticsEvent(SIGN_UP_RESEND_EMAIL_CTA));
    sendEmailCall();
  };

  // resend the verification email on page load if they signed up more than a day ago
  if (!currentUser) return null;
  return (
    <SignupContainer>
      <SignupContentBox
        onBack={() => logout({ returnTo: window.location.origin })}
        title="Please verify your email address."
      >
        <EmailVerification email={currentUser.email} resendEmail={onResendCta} />
      </SignupContentBox>
    </SignupContainer>
  );
};

export default EmailVerificationSendUser;
