import { LazyQueryHookOptions, useLazyQuery } from '@apollo/client';

import {
  LocationEscalationQuery,
  LocationEscalationQueryVariables,
} from '../../../../generated/graphql';

import { locationEscalationQuery } from './queries';

export const useLocationEscalationLazyQuery = (
  options?: LazyQueryHookOptions<LocationEscalationQuery, LocationEscalationQueryVariables>
) =>
  useLazyQuery<LocationEscalationQuery, LocationEscalationQueryVariables>(locationEscalationQuery, {
    fetchPolicy: 'no-cache', // force refetches since we depend on onCompleted
    ...options,
  });
