import { createStyles } from '@material-ui/core';

export const portalWidth = 380;
const inputHeight = 42;

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
export default (theme: any) =>
  createStyles({
    chipsWrapper: {
      alignItems: 'center',
      display: 'flex',
      fontFamily: theme.typography.fontFamily,
      justifyContent: 'flex-start',
      width: '100%',
    },
    containerOuter: {
      minHeight: inputHeight,
      position: 'relative',
      marginBottom: 3,
    },
    containerSelect: {
      position: 'absolute',
      top: 0,
      width: '100%',
      zIndex: 999,
    },
    empty: {
      color: theme.palette.subtitleGrey,
    },
    selectContentButton: {
      alignItems: 'center',
      background: theme.palette.backgroundWhite,
      border: theme.border.line,
      borderRadius: theme.border.radius,
      boxSizing: 'border-box',
      cursor: 'pointer',
      display: 'flex',
      justifyContent: 'space-between',
      minHeight: inputHeight,
      padding: '4px 8px 4px 4px',
      textAlign: 'left',
      width: '100%',
      wordBreak: 'break-word',
    },
  });
