import { MountPolicy } from '../../hooks/usePolicyOnFirstMount';
import useProjectPropsQuery from '../../hooks/useProjectPropsQuery';
import { useActiveProjectBannerQuery } from '../ProjectProperties/PrintHeader/hooks/GetActiveProjectBannerQuery';
import { useProjectBannersQuery } from '../ProjectProperties/PrintHeader/hooks/GetProjectBannersQuery';

import ProjectBanner from './ProjectBanner';
import TeamImage from './TeamImage';
import useTeamData from './useTeamData';

type Props = {
  projectID: UUID;
};

export default function TeamSummary(props: Props) {
  const team = useProjectPropsQuery(props.projectID, MountPolicy.SKIP_ON_MOUNT).data.project?.team;
  const teamData = useTeamData(team, props.projectID);
  const teamImages = teamData.map(({ role, image, name }) => (
    <TeamImage key={`${name} ${role}`} image={image} name={name} role={role} />
  ));

  // Get active project banner query
  const bannerAssetID = useActiveProjectBannerQuery(props.projectID).data?.activeProjectBanner
    ?.asset.id;
  const activeBanner = bannerAssetID && <ProjectBanner id={bannerAssetID} />;

  // Get project banners
  const banners = useProjectBannersQuery(props.projectID).data?.projectBanners ?? [];
  const isBanners = Boolean(banners.length);

  return <div className="h-full">{activeBanner || (isBanners && teamImages)}</div>;
}
