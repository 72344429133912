import { FC } from 'react';

import { useReactiveVar } from '@apollo/client';

import { projectCompsSetInputVar, setLocalStorage } from '../../api/apollo/reactiveVars';
import { PROJECT_COMPS_REACTIVE_VAR } from '../../constants';

const SyncLocalStorageWithProjectComps: FC = () => {
  const projectCompsSetInput = useReactiveVar(projectCompsSetInputVar);
  setLocalStorage(PROJECT_COMPS_REACTIVE_VAR, projectCompsSetInput);
  return null;
};

export default SyncLocalStorageWithProjectComps;
