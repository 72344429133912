import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';

export default (theme: KomodoTheme) =>
  createStyles({
    bannerUploadContainer: {
      border: theme.border.line,
      borderRadius: '4px',
      marginBottom: 20,
      position: 'relative',
    },
    hidden: {
      opacity: 0,
      position: 'absolute',
      zIndex: -1,
    },
    hint: {
      color: theme.palette.joinPrimary,
      flexGrow: 1,
      padding: 20,
    },
    hintSubtitle: {
      borderTop: `1px dashed ${theme.palette.joinGrey400}`,
      padding: '4px 6px 2px 6px',
      fontWeight: 400,
    },
    label: {
      fontWeight: 400,
      color: theme.palette.shadedGrey,
      fontFamily: theme.typography.fontFamily,
      fontSize: '12.5px',
    },
    link: {
      color: theme.palette.link,
      cursor: 'pointer',
      textDecoration: 'underline',
    },
    loadingContainer: {
      objectFit: 'contain',
      display: 'flex',
      background: theme.palette.backgroundWhite,
    },
    loading: {
      margin: 'auto',
    },
    logosContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: 60,
    },
  });
