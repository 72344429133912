import { TimelineData, TimelineType } from './timeline.types';

export const MILESTONE_PATH =
  'M 2 -3.75 L 1.75 -5 H -3.875 V 5.625 V 5.625 V 1.25 H 0.875 L 1.125 2.5 H 5.5 V -3.75 H 2 Z';

export const EVENT_PATH = 'M 0 -5.0912 L 5.0912 0 L 0 5.0912 L -5.0912 0 Z';

export const ARROW_COLLAPSED_PATH = 'M -1 -4 L 3 0 L -1 4 Z';
export const ARROW_EXPANDED_PATH = 'M 4 -1 L 0 3 L -4 -1 Z';

export const ALERT_PATH =
  'M -0.5 1.5 H 0.5 V 2.5 H -0.5 V 1.5 M -0.5 -2.5 H 0.5 V 0.5 H -0.5 V -2.5 M 0 -5 C -2.765 -5 -5 -2.75 -5 0 A 5 5 90 0 0 0 5 A 5 5 90 0 0 5 0 A 5 5 90 0 0 0 -5 M 0 4 A 4 4 90 0 1 -4 0 A 4 4 90 0 1 0 -4 A 4 4 90 0 1 4 0 A 4 4 90 0 1 0 4 Z';

export const getInstantPath = (d: TimelineData) => {
  switch (d.type) {
    case TimelineType.EVENT:
      return EVENT_PATH;
    case TimelineType.ACTIVE_MILESTONE:
    case TimelineType.MILESTONE:
      return MILESTONE_PATH;
    default:
      return EVENT_PATH;
  }
};
