import { useMutation } from '@apollo/client';

import { DELETE_MILESTONE } from '../../../actions/actionTypes';
import { analyticsEvent } from '../../../analytics/analyticsEventProperties';
import { REFETCH_TIMELINE_EVENTS_PHASES } from '../../../api/refetchSets';
import {
  DeleteMilestonesMutation,
  DeleteMilestonesMutationVariables,
} from '../../../generated/graphql';
import useSendAnalytics from '../../../hooks/useSendAnalytics';
import { getMilestonesQuery } from '../../Milestones/hooks/queries';
import { ACTIVITIES_DEFAULT } from '../../Timeline/TimelineUtils';

import { deleteMilestonesMutation } from './queries';

function useDeleteMilestone() {
  const [deleteMilestoneFunc] = useMutation<
    DeleteMilestonesMutation,
    DeleteMilestonesMutationVariables
  >(deleteMilestonesMutation);

  const sendAnalytics = useSendAnalytics();

  const submitFunc = (
    projectId: UUID,
    milestoneID: UUID,
    showDrafts = true,
    onSuccess: () => void
  ) =>
    deleteMilestoneFunc({
      variables: {
        projectID: projectId,
        milestoneID,
      },
      refetchQueries: REFETCH_TIMELINE_EVENTS_PHASES(projectId, ACTIVITIES_DEFAULT),
      update: (dataProxy, { data }) => {
        const updateProject = data?.updateProject;
        if (!updateProject) return;
        // update apollo cache
        dataProxy.writeQuery({
          query: getMilestonesQuery,
          variables: { projectID: projectId, showDrafts },
          data: { milestones: updateProject.milestones },
        });
      },
    }).then(() => {
      if (onSuccess) onSuccess();
      sendAnalytics(analyticsEvent(DELETE_MILESTONE));
    });

  return submitFunc;
}

export default useDeleteMilestone;
