import useAssetBlobUrlQuery from '../assets/hooks/useAssetBlobUrlQuery';

type Props = {
  id: UUID;
};

export default function ProjectBanner(props: Props) {
  const { id } = props;
  const { url } = useAssetBlobUrlQuery(id);
  if (!url) return null;
  return (
    <img
      key={id}
      alt="logo"
      className="max-h-full max-w-full bg-background-primary object-contain px-6 py-0 print:p-1"
      src={url}
    />
  );
}
