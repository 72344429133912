import {
  GetCategorizationMetadataQuery,
  GetCategorizationMetadataQueryVariables,
  Maybe,
} from '../../../generated/graphql';
import { useQuery } from '../../../hooks/useMountAwareQuery';
import { getCategorizationMetadataQuery } from '../hooks/queries';

function useGetCategorization(categorizationId?: Maybe<UUID>) {
  return useQuery<GetCategorizationMetadataQuery, GetCategorizationMetadataQueryVariables>(
    getCategorizationMetadataQuery,
    {
      variables: { id: categorizationId },
      skip: !categorizationId,
    }
  );
}

export default useGetCategorization;
