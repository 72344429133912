import { createStyles } from '@material-ui/core';

import { ImageDimension } from '../../../generated/graphql';
import { KomodoTheme } from '../../../theme/komodo-mui-theme';

const PROJECT_IMAGE_DIMENSION = Number.parseInt(ImageDimension._92.slice(1), 10);

const styles = (theme: KomodoTheme) =>
  createStyles({
    clickable: {
      cursor: 'pointer',
    },
    milestoneButton: {
      minHeight: 32,
      paddingLeft: 4,
      marginLeft: 4,
    },
    milestoneText: {
      color: theme.palette.joinPrimary,
    },
    avatar: {
      width: PROJECT_IMAGE_DIMENSION,
      height: PROJECT_IMAGE_DIMENSION,
    },
    loading: {
      backgroundColor: theme.palette.backgroundGrey,
    },
    top: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'top',
      marginBottom: 16,
    },
    projectName: {
      wordBreak: 'break-word',
      paddingLeft: 8,
      fontSize: '1.3125rem',
    },
    projectSummary: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'left',
    },
    thumbnail: {
      height: PROJECT_IMAGE_DIMENSION,
    },
  });

export default styles;
