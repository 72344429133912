import { useCallback, useMemo } from 'react';
import { useEffectOnce } from 'react-use';

import {
  IN_APP_NOTIFICATIONS_POLLING_INTERVAL_SHORT,
  NULL_ID,
  PAGINATION_DEFAULT_LIMIT,
} from '../../../constants';
import { PaginationByOffsetId } from '../../../generated/graphql';
import useLocalStorage from '../../../hooks/useLocalStorage';
import useStack from '../../../hooks/useStack';
import { useCostMode } from '../../../utilities/costMode';
import useLoadInAppNotificationsCountQuery from '../hooks/useLoadInAppNotificationsCountQuery';
import useLoadInAppNotificationsQuery from '../hooks/useLoadInAppNotificationsQuery';
import useSetAllInAppNotificationsReadMutation from '../hooks/useSetAllInAppNotificationsReadMutation';
import { groupNotificationsByDate } from '../InAppNotificationUtils/InAppNotificationUtils';

import InAppNotificationsPanel from './InAppNotificationsPanel';

type Props = {
  projectID?: UUID;
};

export default function InAppNotificationsPanelData(props: Props) {
  const { onNextPage, onPreviousPage, onResetPagination, currentPage, pagination } =
    usePagination();

  const [showAllProjectNotifications, setShowAllProjectNotifications] = useLocalStorage(
    'NOTIFICATIONS_SHOW_ALL_PROJECTS',
    true
  );
  const [showUnreadOnly, setShowUnreadOnly] = useLocalStorage(
    'NOTIFICATIONS_SHOW_UNREAD_ONLY',
    false
  );

  const onChangeShowUnreadOnly = useCallback(
    (value: boolean) => {
      setShowUnreadOnly(value);
      onResetPagination();
    },
    [onResetPagination, setShowUnreadOnly]
  );

  const onChangeShowAllProjectNotifications = useCallback(
    (value: boolean) => {
      setShowAllProjectNotifications(value);
      onResetPagination();
    },
    [onResetPagination, setShowAllProjectNotifications]
  );

  const { data, previousData, refetch, startPolling, stopPolling } = useLoadInAppNotificationsQuery(
    {
      variables: {
        costMode: useCostMode(),
        pagination,
        projectID: showAllProjectNotifications ? null : props.projectID,
        showUnreadOnly,
      },
    }
  );

  const nextOffsetID =
    data?.loadInAppNotifications.paginationData.nextOffsetID ??
    previousData?.loadInAppNotifications.paginationData.nextOffsetID;

  const onViewNextPage = useCallback(
    () => nextOffsetID && onNextPage(nextOffsetID),
    [nextOffsetID, onNextPage]
  );

  const {
    data: { loadInAppNotificationsCount } = {},
    refetch: refetchCount,
    startPolling: startPollingCount,
    stopPolling: stopPollingCount,
  } = useLoadInAppNotificationsCountQuery(props.projectID);

  useEffectOnce(() => {
    startPolling(IN_APP_NOTIFICATIONS_POLLING_INTERVAL_SHORT);
    startPollingCount(IN_APP_NOTIFICATIONS_POLLING_INTERVAL_SHORT);
    return () => {
      stopPolling();
      stopPollingCount();
    };
  });

  const notificationsData = useMemo(() => {
    const notifications =
      data?.loadInAppNotifications.inAppNotifications ??
      previousData?.loadInAppNotifications.inAppNotifications ??
      [];

    return groupNotificationsByDate(notifications);
  }, [data, previousData]);

  const [setAllInAppNotificationsRead] = useSetAllInAppNotificationsReadMutation({
    variables: {
      projectID: props.projectID,
      allProjects: showAllProjectNotifications,
    },
  });

  const onMarkAllRead = useCallback(async () => {
    await setAllInAppNotificationsRead();
    onResetPagination();
    refetch();
    refetchCount();
  }, [onResetPagination, refetch, refetchCount, setAllInAppNotificationsRead]);

  return (
    <InAppNotificationsPanel
      currentPage={currentPage}
      loadInAppNotificationsCount={loadInAppNotificationsCount}
      notificationsData={notificationsData}
      onChangeShowAllProjectNotifications={onChangeShowAllProjectNotifications}
      onChangeShowUnreadOnly={onChangeShowUnreadOnly}
      onMarkAllRead={onMarkAllRead}
      onViewNextPage={nextOffsetID !== NULL_ID ? onViewNextPage : undefined}
      onViewPreviousPage={pagination.offsetID !== NULL_ID ? onPreviousPage : undefined}
      projectID={props.projectID}
      showAllProjectNotifications={showAllProjectNotifications}
      showUnreadOnly={showUnreadOnly}
    />
  );
}

function usePagination() {
  const { clear, pop, push, stack, top } = useStack<UUID>();

  const pagination: PaginationByOffsetId = {
    offsetID: top ?? NULL_ID,
    limit: PAGINATION_DEFAULT_LIMIT,
  };

  return {
    currentPage: stack.length,
    pagination,
    onResetPagination: clear,
    onNextPage: push,
    onPreviousPage: pop,
  };
}
