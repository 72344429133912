import { useState } from 'react';

export const SCROLL_BUFFER = 4;

export const useScrollToHashOnce = (hash: string, yOffset = 0, isVertical = false) => {
  const [hasScrolled, setHasScrolled] = useState(false);
  const scrollToElement = (el: HTMLElement) => {
    if (yOffset !== 0) {
      const y = el.getBoundingClientRect().top + yOffset;
      const x = el.getBoundingClientRect().left;
      if (isVertical) {
        window.scrollTo({ left: x, behavior: 'smooth' });
      } else {
        window.scrollTo({ top: y, behavior: 'smooth' });
      }
    } else {
      el.scrollIntoView();
    }
    setHasScrolled(true);
  };

  const scrollToHash = () => {
    const el = document.getElementById(hash.substring(1));
    if (el) {
      setTimeout(() => scrollToElement(el), 1000);
    }
  };
  if (!hasScrolled) {
    scrollToHash();
  }
};
