import { max, scaleBand, scaleLinear } from 'd3';

import BandCostAxes from '../../../Charts/ChartsD3/Axes/BandCostAxes';
import SVGWithDimensions from '../../../Charts/ChartsD3/SVGWithDimensions';
import { useChartDimensions } from '../../../Charts/ChartsD3/useChartDimensions';
import AverageColRect from '../AverageColRect';
import AverageLine from '../AverageLine';
import ProjectLabelChip from '../ProjectLabelChip';

import Bar from './Bar';
import {
  CHART_TOP_PADDING_PERCENT,
  COLUMN_X_PADDING_PERCENT,
  TICK_COUNT,
  TotalBar,
} from './ProjectCompsChartUtils';
import Tooltip from './TooltipChartBar';

type Props = {
  hasAverageComp: boolean;
  isCost: boolean;
  totalData: TotalBar[];
  unitAbbreviation: string;
  yLabel: string;
};

export default function ProjectCompsChartBar(props: Props) {
  // Sizing
  const { ref, dimensions } = useChartDimensions({});

  // D3 calculations
  const maxY =
    (max(props.totalData.map((d) => Number(d.value))) ?? 0) * (1 + CHART_TOP_PADDING_PERCENT);
  const x = scaleBand<string>()
    .domain(props.totalData.map((c) => c.id))
    .range([0, dimensions.boundedWidth]);
  const y = scaleLinear<number, number>().domain([0, maxY]).range([dimensions.boundedHeight, 0]);

  // Style dimensions
  const bandWidth = x.bandwidth();
  const columnWidth = bandWidth * (1 - COLUMN_X_PADDING_PERCENT * 2);

  return (
    <SVGWithDimensions ref={ref} dimensions={dimensions}>
      {props.hasAverageComp && (
        <AverageColRect
          height={dimensions.boundedHeight}
          width={dimensions.boundedWidth / props.totalData.length}
          x={0}
          y={0}
        />
      )}
      <BandCostAxes
        height={dimensions.height}
        isCost={props.isCost}
        marginLeft={dimensions.marginLeft}
        tickCount={TICK_COUNT}
        x={x}
        xComponents={props.totalData.map((c) => (
          <ProjectLabelChip
            key={c.id}
            color={c.color ?? undefined}
            text={c.name}
            width={bandWidth}
          />
        ))}
        y={y}
        yLabel={props.yLabel}
      />
      {props.totalData.map((d) => {
        const height = y(0) - y(d.value);
        const tooltip = (
          <Tooltip name={d.name} total={d.total} unitAbbreviation={props.unitAbbreviation} />
        );
        if (height > 0)
          return (
            <Bar
              key={d.id}
              fill={d.color ?? ''}
              height={height}
              opacity={1}
              tooltip={tooltip}
              width={columnWidth}
              x={(x(d.id) ?? 0) + x.bandwidth() * COLUMN_X_PADDING_PERCENT}
              y={y(d.value)}
            />
          );
        return null;
      })}
      {props.hasAverageComp && (
        <AverageLine width={dimensions.boundedWidth} y={y(props.totalData[0].value)} />
      )}
    </SVGWithDimensions>
  );
}
