import { fromNow, parseDate } from '../../../utilities/dates';

export const groupNotificationsByDate = (notificationsData: InAppNotification[]) => {
  const notificationByDate: Record<string, InAppNotificationWithTime[]> = {};
  const notificationGroupedByDates: InAppNotificationWithTime[][] = [];
  notificationsData.forEach((n) => {
    const notification = { ...n } as InAppNotificationWithTime;
    const nowDate = parseDate(new Date(Date.now()));
    const notificationDate = parseDate(notification.createdAt);
    const isToday = nowDate === notificationDate;
    const timeElapsed = fromNow(String(new Date(notification.createdAt)));

    // Determine elapsed time from entry date
    if (isToday) {
      notification.timeDateDisplay = 'TODAY';
      notification.timeElapsedDisplay = timeElapsed;
    } else if (notificationDate) {
      notification.timeDateDisplay = notificationDate;
    }

    // Sort notifications by date
    if (notificationDate) {
      if (notificationByDate[notificationDate]) {
        notificationByDate[notificationDate].push(notification);
      } else {
        notificationByDate[notificationDate] = [notification];
      }
    }
  });

  const notifications = Object.entries(notificationByDate);
  notifications.forEach((notificationGroup) => {
    notificationGroupedByDates.push(notificationGroup[1]);
  });

  return notificationGroupedByDates;
};
