import { createStyles } from '@material-ui/core';

export default () =>
  createStyles({
    bold: {
      fontWeight: 700,
    },
    marginTop: {
      marginTop: '0.46em',
    },
    formLabelRoot: {
      marginLeft: -4,
      alignItems: 'flex-start',
      marginBottom: '1em',
    },
    smallButton: {
      height: 32,
      width: 32,
    },
    tip: {
      margin: 'auto',
      width: 440,
      padding: '10px 0px',
    },
    tipContent: {
      display: 'inline',
    },
    tipIcon: {
      alignSelf: 'end',
      paddingLeft: 7,
      paddingBottom: 5,
    },
    tipFlex: {
      display: 'flex',
    },
  });
