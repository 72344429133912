import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAltOutlined';

import { getItemStatusLabel } from '../../../../utilities/item-status';
import { IconsOptions } from '../../../Icons';
import ItemsIconsMap from '../../../ItemsList/ItemsIcons/ItemsIconsMap';
import Dot from '../Dot';

type Props = {
  status: string;
  baseStatus: string | undefined;
  isOptionSelection?: boolean;
  isScenarioItem: boolean;
};

const ItemStatusChanged = (props: Props) => {
  let statusBeforeComponent: JSX.Element;

  if (props.isOptionSelection) {
    statusBeforeComponent = (
      <div className="text-base">
        <IconsOptions fontSize="inherit" />
      </div>
    );
  } else if (props.isScenarioItem) {
    statusBeforeComponent = (
      <div className="h-2 w-2 fill-button-icon-outline">
        <Dot />
      </div>
    );
  } else {
    statusBeforeComponent = <ItemsIconsMap status={props.baseStatus} variant="small" />;
  }

  return (
    <div className="flex items-center gap-1 whitespace-nowrap">
      {statusBeforeComponent}
      <ArrowRightAltIcon />
      <ItemsIconsMap status={props.status} variant="small" />
      <div className="type-label">{getItemStatusLabel(props.status, { short: true })}</div>
    </div>
  );
};

export default ItemStatusChanged;
