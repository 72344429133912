import { TimelineGroups } from '../../api/gqlEnums';
import { getProjectIdFromUrl } from '../../utilities/url';
import { usePersistentStates } from '../../utilities/urlState';
import { Button } from '../scales';

import { getTimelineUrlConfig } from './TimelineUtils';

export default function TimelineExpandAll({
  expandableActivities,
}: {
  expandableActivities: UUID[];
}) {
  const projectID = getProjectIdFromUrl();
  const [settings, setSettings] = usePersistentStates(...getTimelineUrlConfig(projectID));
  const anyCollapsed = !!settings?.collapse?.length;

  const setAllExpanded = (isExpandAll: boolean) => {
    let collapse: string[] = [];
    let expand: string[] = [TimelineGroups.TIMELINE, TimelineGroups.ITEMS, ...expandableActivities];
    if (!isExpandAll) {
      collapse = [...expand];
      expand = [];
    }
    setSettings({ collapse, expand });
  };

  return (
    <Button
      label={`${anyCollapsed ? 'expand' : 'collapse'} all`}
      onClick={() => setAllExpanded(anyCollapsed)}
      type="tertiary"
    />
  );
}
