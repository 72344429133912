import { pluralizeCountString } from '../../../../utilities/string';
import ItemsOptionChipSquare from '../../../Items/ItemsOptionChipSquare/ItemsOptionChipSquare';
import { ScenarioOptionEntry } from '../ItemStatus/ItemStatusUtils';

type Props = {
  itemAcceptedOption?: ScenarioOptionEntry;
  options: ScenarioOptionEntry[];
};

export default function ItemOptionSelectOptionLabel(props: Props) {
  const acceptedOptionComponent = props.itemAcceptedOption ? (
    <div className="overflow-hidden text-ellipsis whitespace-nowrap">
      {`${props.itemAcceptedOption.number}: ${props.itemAcceptedOption.name}`}
    </div>
  ) : null;

  const optionTextSquareComponent = (
    <div className="flex gap-1">
      <div>{pluralizeCountString('option', props.options?.length)}</div>
      {props.options.length ? <OptionChipList options={props.options} /> : null}
    </div>
  );

  return props.itemAcceptedOption ? acceptedOptionComponent : optionTextSquareComponent;
}

function OptionChipList(props: { options: ScenarioOptionEntry[] }) {
  const chips = props.options.map((o) => (
    <ItemsOptionChipSquare key={o.id} name={o.name} number={o.number} status={o.status} url="" />
  ));

  if (chips.length <= 5) return <div>{chips}</div>;

  return (
    <div className="flex gap-1">
      <div>{chips.slice(0, 3)}</div>
      <div>+{chips.length - 3}</div>
    </div>
  );
}
