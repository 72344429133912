import { InputVariant, SelectVariant } from '../../../../api/gqlEnums';
import { removeYear } from '../../../../utilities/string';
import SelectCategory from '../../../Select/SelectCategory/SelectCategory';
import { MenuOption } from '../../../Select/SelectMenu/SelectOption';
import { MenuOptionsProps, getLines, updateCategories } from '../EstimateHeaderUtils';

export const replaceCategory = (
  categorizationID: UUID,
  key: string,
  errorsMap: Map<string, number[]>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  updateColumnCells: (value: any, indices: number[], issuedCategory: string) => void
) => {
  if (!categorizationID) return null;
  return (
    <SelectCategory
      addCategories={(newCategories) => {
        updateCategories(newCategories, key, errorsMap, updateColumnCells);
      }}
      categorizationID={categorizationID}
      includeUncategorizedCategory={false}
      inputValue=""
      inputVariant={InputVariant.GRID}
      noun="category"
      onSubmit={() => {}}
      removeCategories={(newCategories) => {
        updateCategories(newCategories, key, errorsMap, updateColumnCells);
      }}
      selectedCategories={[]}
      selectVariant={SelectVariant.SINGLE}
    />
  );
};

const replaceCategoryTitle = (key: string) => `Replace ${key} with...`;

export const categoryNotFound = (key: string, errorsMap: Map<string, number[]>) => {
  const count = mapCount(key, errorsMap);
  const lineCount = getLines(count);
  const lineDescription = lineCount ? `(${lineCount})` : '';
  return `${key} ${lineDescription}`;
};

const mapCount = (key: string, errorsMap: Map<string, number[]>) =>
  (errorsMap.get(key) || []).length;

export const categoryNotFoundTooltip = (
  key: string,
  errorsMap: Map<string, number[]>,
  categorizationName = ''
) => {
  const count = mapCount(key, errorsMap);
  if (count > 0) return `${key} not found in ${categorizationName}, appears in ${count}`;
  return `${key} not found in ${categorizationName}`;
};

export const getSubMenuOptions = (
  menuOptions: Pick<
    MenuOptionsProps,
    | 'canEditCategory'
    | 'categorization'
    | 'errorsMap'
    | 'hasGroups'
    | 'isErrorsMode'
    | 'onAddAllErrors'
    | 'onCategoryError'
    | 'updateColumnCells'
  >,
  width?: number
) => {
  const {
    canEditCategory,
    categorization,
    errorsMap,
    hasGroups,
    isErrorsMode,
    onAddAllErrors,
    onCategoryError,
    updateColumnCells,
  } = menuOptions;
  const isBuiltIn = !!categorization?.builtin;
  const isEditDisabled =
    !canEditCategory || isBuiltIn || (categorization && categorization?.levels > 1);
  const categorizationName = categorization && removeYear(categorization?.name, isBuiltIn);
  let tooltip: string | undefined;
  if (isBuiltIn) {
    tooltip = `you don't have permission to edit ${categorizationName} because it's a built-in categorization`;
  } else if (categorization?.createdFrom) {
    tooltip = `you don't have permission to edit ${categorizationName} because it's a standard categorization`;
  } else if (!categorization?.createdFrom && categorization?.levels) {
    tooltip = `you aren't able to edit ${categorizationName} because it's a multilevel categorization. Please resolve this in the project settings.`;
  } else if (!canEditCategory) {
    tooltip = `you don't have permission to edit ${categorizationName}`;
  }

  if (!hasGroups && isErrorsMode && errorsMap) {
    const keys = Array.from(errorsMap.keys()).sort();

    const options: MenuOption[] = [];

    if (keys.length > 1)
      options.push({
        name: `Add all to ${categorizationName}`,
        callback: onAddAllErrors,
        disabled: isEditDisabled,
        fixed: true,
        width,
        tooltip,
      });

    keys.forEach((key) =>
      options.push({
        name: categoryNotFound(key, errorsMap),
        tooltip: categoryNotFoundTooltip(key, errorsMap, categorizationName),
        callback: () => {},
        width,
        subOptions: [
          {
            name: `Add to ${categorizationName}`,
            callback: () => onCategoryError(key),
            disabled: isEditDisabled,
            tooltip,
            width,
            component: null,
          },
          {
            name: replaceCategoryTitle(key),
            callback: () => {},
            width,
            subOptions: [
              {
                name: '___',
                callback: () => {},
                component: replaceCategory(
                  categorization?.id || '',
                  key,
                  errorsMap,
                  updateColumnCells
                ),
              },
            ],
            disabled: false,
          },
        ],
        disabled: false,
      })
    );
    return options;
  }
  return [];
};
