import { createStyles } from '@material-ui/core';

import { CHART_HEIGHT, MARGIN, SHADOW } from '../PrintUtils';

const styles = () =>
  createStyles({
    controlHeader: {
      display: 'flex',
      flex: '1 1 auto',
      flexWrap: 'wrap',
      justifyContent: 'flex-end',
    },
    costReportChart: {
      height: CHART_HEIGHT,
      left: 0,
    },
    footer: {
      '@media not print': {
        height: MARGIN,
      },
    },
    page: {
      '@media not print': {
        background: 'white',
        boxShadow: SHADOW,
        outline: `white solid ${MARGIN}px`,
        '-webkit-box-shadow': SHADOW,
      },
    },
    pageBreak: {
      pageBreakBefore: 'always',
    },
    root: {
      alignItems: 'center',
      border: `0px none`,
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      height: 'auto',
      justifyContent: 'center',
      overflowX: 'visible',
      width: 'auto',
    },
  });

export default styles;
