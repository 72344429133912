import { FC, ReactNode } from 'react';

import { MuiThemeProvider } from '@material-ui/core/styles';

import { createAppTheme } from './komodo-mui-theme';
import NativeTheme from './NativeTheme';

type AppThemeProviderProps = {
  children: ReactNode;
};

const AppThemeProvider: FC<AppThemeProviderProps> = ({ children }) => {
  const hasGreenColorsFeature = true;

  const appTheme = createAppTheme(hasGreenColorsFeature);

  return (
    <>
      <MuiThemeProvider theme={appTheme}>{children}</MuiThemeProvider>
      <NativeTheme />
    </>
  );
};

export default AppThemeProvider;
