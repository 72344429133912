import {
  LoadCollaboratorsByRoleQuery,
  LoadCollaboratorsByRoleQueryVariables,
} from '../../../../generated/graphql';
import { useQuery } from '../../../../hooks/useMountAwareQuery';
import { MountPolicy } from '../../../../hooks/usePolicyOnFirstMount';

import { loadCollaboratorsByRoleQuery } from './queries';

export const useLoadCollaboratorsByRoleQuery = (projectID: UUID, roleID?: UUID) =>
  useQuery<LoadCollaboratorsByRoleQuery, LoadCollaboratorsByRoleQueryVariables>(
    loadCollaboratorsByRoleQuery,
    {
      variables: {
        projectID,
        roleID,
      },
      skip: !projectID || !roleID,
    },
    MountPolicy.FETCH_ON_VARS
  );
