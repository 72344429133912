import { FC, useEffect, useState } from 'react';

import { GROUP_BY } from '../../../constants';
import MultiGroupOrder from '../../shared-widgets/MultiGroup/MultiGroupOrder';
import {
  GroupByOption,
  MultiGroupOrderOption,
  NonCategorizationGroupBy,
} from '../../shared-widgets/MultiGroup/MultiGroupOrderCategorizationUtils';

type ItemsListGroupBySelectProps = {
  handleClose?: (groupBys: MultiGroupOrderOption[]) => void;
  options: MultiGroupOrderOption[];
  selected: MultiGroupOrderOption[];
};

const ItemsListGroupBySelect: FC<ItemsListGroupBySelectProps> = ({
  handleClose = () => {},
  options,
  selected,
}) => {
  const [groupBy, setGroupBy] = useState(selected);
  const [groupByOpen, setGroupByOpen] = useState(false);

  // Hooks
  useEffect(() => {
    // We need to validate the selections for 2 reasons
    // We may have bad inputs in the URL Group By (ex. "groupBy[0]=BAD%20INPUT")
    // We may not have all our options yet (takes a second to load our project categorizations)
    const filteredSelections = selected.filter(
      (selection) =>
        // Categorization
        (selection as DisplayGroupBy).id ||
        // Milestone, Status, Assignee, Creator
        (selection as NonCategorizationGroupBy).variant ||
        // Only has name, but matches GroupBy option (URL)
        options.some((option) => option.name === selection.name)
    );
    setGroupBy(filteredSelections);
  }, [selected, options]);

  // Functions
  const handleAdd = (toAdd: MultiGroupOrderOption[]) => {
    let currentGroupBy = groupBy;
    let categoriesGroupBy: MultiGroupOrderOption[] = [];
    toAdd.forEach((add) => {
      if ((add as DisplayGroupBy).id) {
        if (currentGroupBy.some((gb) => (gb as NonCategorizationGroupBy).variant)) {
          currentGroupBy = [];
        }
        categoriesGroupBy.push(add);
      }
      if ((add as NonCategorizationGroupBy).variant) {
        currentGroupBy = [add];
        categoriesGroupBy = [];
      }
    });
    setGroupBy(currentGroupBy.concat(...categoriesGroupBy));
  };

  const handleSet = (toSet: GroupByOption[]) => {
    setGroupBy(toSet);
  };

  return (
    <div className="gap-0.5">
      <div className="type-label">{GROUP_BY}</div>
      <MultiGroupOrder
        // hasSuggestions TODO: Add Support for Item list Milestone ID
        handleAdd={handleAdd}
        handleDelete={(toDelete: MultiGroupOrderOption[]) => {
          setGroupBy(groupBy.filter((gb) => toDelete.every((del) => del.name !== gb.name)));
        }}
        handleSet={handleSet}
        handleSort={(swapA: number, swapB: number) => {
          const newGroupBy = [...groupBy];
          newGroupBy[swapA] = groupBy[swapB];
          newGroupBy[swapB] = groupBy[swapA];
          setGroupBy(newGroupBy);
        }}
        open={groupByOpen}
        options={options}
        selected={groupBy}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
        setOpen={(newValue: any) => {
          if (
            // Menu Closed
            !newValue &&
            handleClose &&
            // GroupBys Changed
            (groupBy.length !== selected.length ||
              groupBy.some((gb, i) => selected[i].name !== gb.name))
          ) {
            handleClose(groupBy);
          }
          setGroupByOpen(newValue);
        }}
      />
    </div>
  );
};

export default ItemsListGroupBySelect;
