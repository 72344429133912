import {
  commonCategorizationFields,
  commonCategoryUniqueFields,
  commonMarkupFields,
  draftEstimateInfoFields,
  gql,
  gridFieldFields,
  gridLineFields,
  indexedGridCellFields,
  itemLinkFields,
  limitedUpdateEstimateResult,
  quantityUpdateEstimateResult,
} from '../../../api/graphqlFragments';

const gridEstimateLineIDsFields = gql`
  fragment gridEstimateLineIDsFields on Estimate {
    lineIDs
  }
`;

const gridEstimateArmatureFields = gql`
  fragment gridEstimateArmatureFields on Estimate {
    id
    linesCount
    name
    milestoneName
    fields {
      ...gridFieldFields
    }
    incorporatedMarkups {
      ...commonMarkupFields
    }
    incorporatedDraws {
      ...commonMarkupFields
    }
    inheritedMarkups {
      ...commonMarkupFields
    }
    inheritedOwnerCostMarkups {
      ...commonMarkupFields
    }
    markups {
      ...commonMarkupFields
    }
    contingencyDraws {
      id
      name
      error
      draw
    }
    subtotal
    markupSubtotal
    incorporatedSubtotal
    incorporatedDrawsSubtotal
    inheritedSubtotal
    inheritedOwnerCostMarkupsSubtotal
    drawSubtotal
    totalType
    isRunningTotal
  }
  ${gridFieldFields}
  ${commonMarkupFields}
`;

export const gridEstimateArmatureQuery = gql`
  query estimateArmature(
    $estimateID: UUID!
    $itemEstimateInput: ItemEstimateInput
    $costMode: CostMode!
    $viewFilter: ViewFilter
    $estimateSortBy: EstimateSortBy
    $pagination: Pagination
  ) {
    estimate(
      estimateID: $estimateID
      itemEstimateInput: $itemEstimateInput
      costMode: $costMode
      viewFilter: $viewFilter
      estimateSortBy: $estimateSortBy
      pagination: $pagination
    ) {
      ...gridEstimateArmatureFields
      ownerCostEstimate {
        ...gridEstimateArmatureFields
      }
    }
  }
  ${gridEstimateArmatureFields}
`;

export const draftEstimateInfoQuery = gql`
  query draftEstimateInfo($estimateID: UUID!, $costMode: CostMode!) {
    draftEstimateInfo(estimateID: $estimateID, costMode: $costMode) {
      ...draftEstimateInfoFields
    }
  }
  ${draftEstimateInfoFields}
`;

export const gridEstimateLineIDsQuery = gql`
  query estimateLineIDs(
    $estimateID: UUID!
    $costMode: CostMode!
    $viewFilter: ViewFilter
    $estimateSortBy: EstimateSortBy
  ) {
    estimate(
      estimateID: $estimateID
      costMode: $costMode
      viewFilter: $viewFilter
      estimateSortBy: $estimateSortBy
    ) {
      ...gridEstimateLineIDsFields
    }
  }
  ${gridEstimateLineIDsFields}
`;

const gridEstimateLinesFields = gql`
  fragment gridEstimateLinesFields on Estimate {
    lines {
      ...gridLineFields
    }
  }
  ${gridLineFields}
`;

export const gridEstimateLinesQuery = gql`
  query estimateLines(
    $estimateID: UUID!
    $itemEstimateInput: ItemEstimateInput
    $costMode: CostMode!
    $viewFilter: ViewFilter
    $estimateSortBy: EstimateSortBy
    $pagination: Pagination
  ) {
    estimate(
      estimateID: $estimateID
      itemEstimateInput: $itemEstimateInput
      costMode: $costMode
      viewFilter: $viewFilter
      estimateSortBy: $estimateSortBy
      pagination: $pagination
    ) {
      ...gridEstimateLinesFields
    }
  }
  ${gridEstimateLinesFields}
`;

export const createMarkupsMutation = gql`
  mutation createMarkups($projectID: UUID!, $estimate: UUID!, $number: Int!) {
    createMarkups(projectID: $projectID, estimate: $estimate, number: $number) {
      ...commonMarkupFields
    }
  }
  ${commonMarkupFields}
`;

export const updateMarkupsMutation = gql`
  mutation updateMarkups(
    $projectID: UUID!
    $estimate: UUID!
    $markups: [MarkupInput!]!
    $viewFilter: ViewFilter
  ) {
    updateMarkups(
      projectID: $projectID
      estimate: $estimate
      markups: $markups
      viewFilter: $viewFilter
    ) {
      markups {
        ...commonMarkupFields
      }
      estimateUpdateResult {
        ...limitedUpdateEstimateResult
      }
    }
  }
  ${commonMarkupFields}
  ${limitedUpdateEstimateResult}
`;

export const removeMarkupsMutation = gql`
  mutation removeMarkups(
    $projectID: UUID!
    $estimate: UUID!
    $markupIDs: [UUID!]!
    $viewFilter: ViewFilter
  ) {
    removeMarkups(
      projectID: $projectID
      estimate: $estimate
      markupIDs: $markupIDs
      viewFilter: $viewFilter
    ) {
      estimate {
        id
        inheritedMarkups {
          ...commonMarkupFields
        }
        markups {
          ...commonMarkupFields
        }
        incorporatedMarkups {
          ...commonMarkupFields
        }
        incorporatedDraws {
          ...commonMarkupFields
        }
        subtotal
        markupSubtotal
        inheritedSubtotal
        incorporatedSubtotal
        incorporatedDrawsSubtotal
        inheritedOwnerCostMarkupsSubtotal
      }
    }
  }
  ${commonMarkupFields}
`;

export const toggleInheritedItemMarkupMutation = gql`
  mutation toggleInheritedItemMarkup(
    $projectID: UUID
    $estimateId: UUID!
    $markupId: UUID!
    $disabled: Boolean!
  ) {
    toggleInheritedItemMarkup(
      projectID: $projectID
      estimateId: $estimateId
      markupId: $markupId
      disabled: $disabled
    ) {
      ...limitedUpdateEstimateResult
    }
  }
  ${limitedUpdateEstimateResult}
`;

export const toggleMarkupWithoutS2ReferenceMutation = gql`
  mutation toggleMarkupWithoutS2Reference(
    $estimateID: UUID!
    $markupID: UUID!
    $withoutS2Reference: Boolean!
  ) {
    toggleMarkupWithoutS2Reference(
      estimateID: $estimateID
      markupID: $markupID
      withoutS2Reference: $withoutS2Reference
    ) {
      ...limitedUpdateEstimateResult
    }
  }
  ${limitedUpdateEstimateResult}
`;

export const gridReorderMarkupsMutation = gql`
  mutation gridReorderMarkups($projectID: UUID!, $estimate: UUID!, $markups: [ReorderInput!]!) {
    reorderMarkups(projectID: $projectID, estimate: $estimate, markups: $markups) {
      markups {
        ...commonMarkupFields
      }
    }
  }
  ${commonMarkupFields}
`;

export const quantityRemoveLineMutation = gql`
  mutation quantityRemoveLine($projectID: UUID!, $estimateID: UUID!, $lineIDs: [UUID!]!) {
    removeLines(projectID: $projectID, estimateID: $estimateID, lineIDs: $lineIDs) {
      ...quantityUpdateEstimateResult
    }
  }
  ${quantityUpdateEstimateResult}
`;

export const quantityCreateLinesMutation = gql`
  mutation quantityCreateLines($projectID: UUID!, $estimate: UUID!, $lines: [LineInput!]!) {
    createLines(projectID: $projectID, estimateID: $estimate, lines: $lines) {
      lines {
        ...gridLineFields
      }
      estimateUpdateResult {
        ...quantityUpdateEstimateResult
      }
    }
  }
  ${gridLineFields}
  ${quantityUpdateEstimateResult}
`;

export const quantityUpdateCellsMutation = gql`
  mutation quantityUpdateCells($projectID: UUID!, $estimate: UUID!, $cells: [CellInput!]!) {
    updateCells(projectID: $projectID, estimateID: $estimate, cells: $cells) {
      cells {
        ...indexedGridCellFields
      }
      estimateUpdateResult {
        ...quantityUpdateEstimateResult
      }
    }
  }
  ${indexedGridCellFields}
  ${quantityUpdateEstimateResult}
`;

export const gridReplaceColumnCategoriesMutation = gql`
  mutation gridReplaceColumnCategories(
    $projectID: UUID!
    $estimateID: UUID!
    $fieldID: UUID!
    $fromCategories: [String!]!
    $toCategories: [UUID!]!
  ) {
    replaceFieldCategories(
      projectID: $projectID
      estimateID: $estimateID
      fieldID: $fieldID
      fromCategories: $fromCategories
      toCategories: $toCategories
    ) {
      cells {
        ...indexedGridCellFields
      }
      estimateUpdateResult {
        estimate {
          fields {
            ...gridFieldFields
          }
        }
      }
    }
  }
  ${indexedGridCellFields}
  ${gridFieldFields}
`;

export const createCategoriesMutation = gql`
  mutation createCategories(
    $projectID: UUID
    $categorizationID: UUID!
    $categories: [CategoryContentInput!]!
  ) {
    createCategories(
      projectID: $projectID
      categorizationID: $categorizationID
      categories: $categories
    ) {
      categories {
        ...commonCategoryUniqueFields
      }
      error
    }
  }
  ${commonCategoryUniqueFields}
`;

export const editCategoriesMutation = gql`
  mutation editCategories(
    $projectID: UUID
    $categorizationID: UUID!
    $categories: [EditCategoryInput!]!
  ) {
    editCategories(
      projectID: $projectID
      categorizationID: $categorizationID
      categories: $categories
    ) {
      ...commonCategoryUniqueFields
    }
  }
  ${commonCategoryUniqueFields}
`;

export const getCategorizationQuery = gql`
  query getCategorization($id: UUID!, $pagination: Pagination) {
    categorization(id: $id, pagination: $pagination) {
      ...commonCategorizationFields
      content {
        ...commonCategoryUniqueFields
      }
    }
  }
  ${commonCategorizationFields}
  ${commonCategoryUniqueFields}
`;

export const deleteCategoryMutation = gql`
  mutation deleteCategory($projectID: UUID, $category: CategoryInput!) {
    deleteCategory(projectID: $projectID, category: $category)
  }
`;

export const acceptImportEstimateErrorMutation = gql`
  mutation acceptImportEstimateError($projectID: UUID!, $estimateID: UUID!, $errorID: UUID!) {
    acceptImportEstimateError(projectID: $projectID, estimateID: $estimateID, errorID: $errorID)
  }
`;

export const setEstimateTotalTypeMutation = gql`
  mutation setEstimateTotalType(
    $projectID: UUID!
    $estimateID: UUID!
    $estimateTotalType: EstimateTotalType!
  ) {
    setEstimateTotalType(
      projectID: $projectID
      estimateID: $estimateID
      estimateTotalType: $estimateTotalType
    ) {
      fields {
        ...gridFieldFields
      }
    }
  }
  ${gridFieldFields}
`;

export const getEstimateSourceItemInfosQuery = gql`
  query getEstimateSourceItemInfos($projectID: UUID!, $estimateID: UUID!) {
    estimateSourceItemInfos(projectID: $projectID, estimateID: $estimateID) {
      ...itemLinkFields
    }
  }
  ${itemLinkFields}
`;

export const gridReorderColumnMutation = gql`
  mutation gridReorderColumn(
    $projectID: UUID!
    $estimateID: UUID!
    $fieldID: UUID!
    $ordering: Int!
  ) {
    reorderField(
      projectID: $projectID
      estimateID: $estimateID
      fieldID: $fieldID
      ordering: $ordering
    ) {
      fields {
        ...gridFieldFields
      }
    }
  }
  ${gridFieldFields}
`;

export const gridRemoveColumnsMutation = gql`
  mutation gridRemoveColumns($projectID: UUID!, $estimateID: UUID!, $fieldIDs: [UUID!]!) {
    removeFields(projectID: $projectID, estimateID: $estimateID, fieldIDs: $fieldIDs) {
      fields {
        ...gridFieldFields
      }
    }
  }
  ${gridFieldFields}
`;

export const gridAddColumnsMutation = gql`
  mutation gridAddColumns($projectID: UUID!, $estimateID: UUID!, $fields: [FieldInput!]!) {
    addFields(projectID: $projectID, estimateID: $estimateID, fields: $fields) {
      fields {
        ...gridFieldFields
      }
    }
  }
  ${gridFieldFields}
`;

export const gridReorderLinesMutation = gql`
  mutation gridReorderLines($projectID: UUID!, $estimate: UUID!, $lines: [ReorderInput!]!) {
    reorderLines(projectID: $projectID, estimate: $estimate, lines: $lines) {
      lines {
        ...gridLineFields
      }
    }
  }
  ${gridLineFields}
`;

export const gridCreateLinesMutation = gql`
  mutation gridCreateLines($projectID: UUID!, $estimate: UUID!, $lines: [LineInput!]!) {
    createLines(projectID: $projectID, estimateID: $estimate, lines: $lines) {
      lines {
        ...gridLineFields
      }
      estimateUpdateResult {
        ...limitedUpdateEstimateResult
      }
    }
  }
  ${gridLineFields}
  ${limitedUpdateEstimateResult}
`;

export const gridRemoveLineMutation = gql`
  mutation gridRemoveLine($projectID: UUID!, $estimateID: UUID!, $lineIDs: [UUID!]!) {
    removeLines(projectID: $projectID, estimateID: $estimateID, lineIDs: $lineIDs) {
      ...limitedUpdateEstimateResult
    }
  }
  ${limitedUpdateEstimateResult}
`;

export const gridUpdateCellsMutation = gql`
  mutation gridUpdateCells($projectID: UUID!, $estimate: UUID!, $cells: [CellInput!]!) {
    updateCells(projectID: $projectID, estimateID: $estimate, cells: $cells) {
      cells {
        ...indexedGridCellFields
      }
      estimateUpdateResult {
        ...limitedUpdateEstimateResult
      }
    }
  }
  ${indexedGridCellFields}
  ${limitedUpdateEstimateResult}
`;

export const getDoMilestoneMarkupsHaveContingencyDraws = gql`
  query getDoMilestoneMarkupsHaveContingencyDraws(
    $estimateID: UUID!
    $projectID: UUID!
    $markupIDs: [UUID!]!
  ) {
    getDoMilestoneMarkupsHaveContingencyDraws(
      estimateID: $estimateID
      projectID: $projectID
      markupIDs: $markupIDs
    )
  }
`;

export const createOwnerCostEstimateMutation = gql`
  mutation createOwnerCostEstimate($projectID: UUID!, $estimateID: UUID!) {
    createOwnerCostEstimate(projectID: $projectID, estimateID: $estimateID)
  }
`;
