import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../../theme/komodo-mui-theme';

export default (theme: KomodoTheme) =>
  createStyles({
    alignRight: {
      marginLeft: 'auto',
    },
    checkbox: {
      height: theme.iconSizes.compact,
      marginRight: 8,
      width: theme.iconSizes.compact,
    },
    container: {
      display: 'inline-flex',
      margin: 18,
      minWidth: '-webkit-fill-available',
    },
    containerSidebar: {
      display: 'flex',
      flexFlow: 'row wrap',
    },
    description: {
      ...theme.typography.webParagraph,
    },
    inline: {
      alignItems: 'center',
      display: 'inline-flex',
    },
    marginLeft: {
      marginLeft: 8,
    },
    marginTop: {
      marginTop: 18,
    },
    select: {
      width: 280,
    },
    selectSidebar: {
      width: '100%',
    },
  });
