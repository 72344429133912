import { JSX } from 'react';

import useHasModuleEntitlement from '../hooks/useHasModuleEntitlement';

type Props = {
  children: JSX.Element;
  fallback?: JSX.Element;
  flag: string;
};

export default function HasModuleEntitlement(props: Props) {
  const hasFlag = useHasModuleEntitlement(props.flag);
  if (!hasFlag) return props.fallback ?? null;

  return props.children;
}
