import { createStyles } from '@material-ui/core';

const FilterMenuStyles = () =>
  createStyles({
    alignItemsCenter: {
      alignItems: 'center',
      display: 'flex',
      marginTop: 3,
    },
    buttonContainer: {
      margin: '0 8px',
    },
    leftAlign: {
      display: 'flex',
      alignItems: 'start',
    },
    rightAlign: {
      display: 'flex',
      marginLeft: 'auto',
    },
    settingContainer: {
      padding: 0,
      '&> div': {
        margin: 0,
      },
    },
  });

export default FilterMenuStyles;
