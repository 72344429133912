import { forwardRef, useRef, useState } from 'react';
import { mergeProps, useButton, useFocusRing } from 'react-aria';

import { StrictUnion } from '../../../utilities/types';

type BaseOption = {
  disabled?: boolean;
  value: string;
};

type LabelledOption = BaseOption & {
  label: string;
  icon?: JSX.Element;
};

type UnlabelledOption = BaseOption & {
  icon: JSX.Element;
  'aria-label': string;
};

type Option = StrictUnion<LabelledOption | UnlabelledOption>;

type Props = {
  // TODO DD-1188: enforce data-cy to be kebab-case
  /** provided data-cy should be kebab case and end in '-button-bar', defaults to 'button-bar' if not provided */
  'data-cy'?: string;
  defaultValue?: string;
  isDisabled?: boolean;
  isFullWidth?: boolean;
  label?: string;
  onChange: (value: string) => void;
  options: Option[];
  /** Used when using the component as a controlled component. */
  value?: string;
};

export default forwardRef<HTMLDivElement, Props>(function ButtonBar(props, forwardedRef) {
  const [controlledValue, setControlledValue] = useState(props.defaultValue);
  const value = props.value !== undefined ? props.value : controlledValue;

  const onButtonClick = (value: string) => {
    setControlledValue(value);
    props.onChange(value);
  };

  return (
    <div className={`inline-flex flex-col gap-0.5 ${props.isFullWidth ? 'w-full' : ''}`}>
      {props.label && <div className="text-type-primary type-label">{props.label}</div>}
      <div
        ref={forwardedRef}
        className="inline-flex h-10 rounded border"
        data-cy={props['data-cy'] || 'button-bar'}
      >
        {props.options.map((option) => (
          <ButtonBarButton
            {...option}
            key={option.value}
            disabled={props.isDisabled || option.disabled}
            isSelected={value === option.value}
            onClick={onButtonClick}
            width={props.isFullWidth ? `${100 / props.options.length}%` : undefined}
          />
        ))}
      </div>
    </div>
  );
});

type ButtonBarButtonProps = Option & {
  isSelected: boolean;
  onClick: (value: string) => void;
  width?: string;
};

function ButtonBarButton(props: ButtonBarButtonProps) {
  const ref = useRef<HTMLButtonElement>(null);
  const { buttonProps } = useButton(
    {
      isDisabled: props.disabled,
      onPress: () => props.onClick(props.value),
    },
    ref
  );

  // Manage focus via useFocusRing since focus has a habit of appearing when clicking via
  // the mouse when we rely solely on useButton.
  const { isFocusVisible, focusProps } = useFocusRing();

  let backgroundClass = 'bg-button-secondary hover:bg-button-secondary-hover';
  if (props.isSelected) {
    backgroundClass = 'bg-background-2';
  } else if (props.disabled) {
    backgroundClass = 'bg-button-secondary';
  }

  return (
    <button
      {...mergeProps(buttonProps, focusProps)}
      aria-label={props['aria-label']}
      aria-pressed={props.isSelected}
      className={[
        'h-full items-center truncate px-3 transition type-body1',
        'border-r last:border-0 first-of-type:rounded-s last-of-type:rounded-e',
        isFocusVisible ? 'outline' : 'outline-none',
        backgroundClass,
        props.disabled ? 'cursor-not-allowed text-type-inactive' : 'text-type-primary',
      ].join(' ')}
      style={{ width: props.width }}
    >
      {props.icon}
      {props.label}
    </button>
  );
}
