import { FC, useEffect, useState } from 'react';
import { DragDropContext } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';

import { Typography } from '@material-ui/core';
import { RemoveCircleOutlineOutlined } from '@material-ui/icons';

import { CATEGORIZATION_REORDER } from '../../../../constants';
import { withStyles } from '../../../../theme/komodo-mui-theme';
import { formatMappedBuiltIn } from '../../../../utilities/string';
import DragChip from '../../../shared-widgets/DragChip';

import styles from './CategorizationsReorderStyles';

type CategorizationsReorderProps = {
  categorizations: DraftCategorization[];
  classes: Classes<typeof styles>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  mappingBuiltin?: any;
  sort: (reorderable: DraftCategorization[]) => void;
  setSelectCategorization?: (categorization: DraftCategorization, isSelected: boolean) => void;
};

const CategorizationsReorder: FC<CategorizationsReorderProps> = ({
  categorizations,
  classes,
  mappingBuiltin,
  sort: outerSort,
  setSelectCategorization,
}) => {
  const [reorderable, setReorderable] = useState(categorizations);
  const [reorderableName, setReordarableName] = useState<string>();

  // re-render to show updated excluded/included categorizations
  useEffect(() => {
    return setReorderable(categorizations);
  }, [categorizations, mappingBuiltin]);

  const handleSort = (swapA: number, swapB: number) => {
    const newReorderable = [...reorderable];
    newReorderable[swapA] = reorderable[swapB];
    newReorderable[swapB] = reorderable[swapA];
    setReorderable(newReorderable);
  };

  const sort = (a: number, b: number, name: string) => {
    setReordarableName(name);
    if (!a && !b) {
      outerSort(reorderable);
    } else {
      handleSort(a, b);
    }
  };

  const excludeCategorization = (categorizationName: string) => {
    const selectedCategorization = categorizations.find(
      (categorization) => categorization.name === categorizationName
    );
    if (selectedCategorization && setSelectCategorization) {
      setSelectCategorization(selectedCategorization, false);
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.chipContainer}>
        {reorderable.length === 0 ? (
          <Typography className={classes.selectColumnText}>Include a column to continue</Typography>
        ) : (
          reorderable.map(({ name, existingCategorization }, i) => {
            const isMappedToBuiltin = mappingBuiltin.has(name);
            const title = isMappedToBuiltin ? formatMappedBuiltIn(existingCategorization) : name;
            return (
              <div
                key={`${name} div`}
                className={name === reorderableName ? classes.chipHidden : classes.chipPadding}
                data-cy="reorder-categorization"
              >
                <DragChip
                  key={name}
                  chipCount={reorderable.length}
                  chipType={CATEGORIZATION_REORDER}
                  dataCy={`include-${title}`}
                  deleteIcon={
                    <RemoveCircleOutlineOutlined
                      className={classes.addRemoveIcon}
                      data-cy={`button-exclude${name}`}
                    />
                  }
                  handleClose={!isMappedToBuiltin ? () => excludeCategorization(name) : () => {}}
                  id={name}
                  index={i}
                  isDisabled={isMappedToBuiltin}
                  moveListItem={sort}
                  text={title}
                  title={title}
                />
              </div>
            );
          })
        )}
      </div>
    </div>
  );
};

export const StyledCategorizationsReorder = withStyles(styles)(CategorizationsReorder);

export default DragDropContext(HTML5Backend)(StyledCategorizationsReorder);
