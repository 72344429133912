import { FC } from 'react';

import ZeroState from '../../ZeroState/ZeroState';

import DashboardChartClearButton from './DashboardChartsClearButton';

type DashboardChartPlaceholdertProps = {
  emptyMessage: string;
  clearFilters?: () => void;
  height?: number;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  loading?: boolean;
};

const DashboardChartPlaceholder: FC<DashboardChartPlaceholdertProps> = ({
  clearFilters = undefined,
  emptyMessage,
  height = 420,
  loading = false,
}) => (
  <ZeroState height={height} loading={loading}>
    <div data-cy="placeholder">
      {emptyMessage}
      <DashboardChartClearButton clearFilters={clearFilters} />
    </div>
  </ZeroState>
);

export default DashboardChartPlaceholder;
