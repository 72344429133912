import { UNTITLED_SCENARIO } from '../../../constants';
import { getNewPickerColor } from '../../../utilities/colors';
import { ScenarioSandbox } from '../types';

export const getScenarioName = (scenario: { name: string }) => scenario.name || UNTITLED_SCENARIO;
export const getNewColorForScenario = (sandbox: ScenarioSandbox) => {
  const usedColors: string[] = [];
  sandbox.scenarios.forEach((scenario) => {
    if (scenario.color) usedColors.push(scenario.color);
  });
  return getNewPickerColor(usedColors);
};
