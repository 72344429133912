import { GroupAdd } from '@material-ui/icons';

import { LeadEvent, LeadKey, leadEvent } from '../../../analytics/analyticsEventProperties';
import useSendAnalytics from '../../../hooks/useSendAnalytics';
import { Button, Dialog, DialogContent } from '../../scales';

export type AddLeadtoProject = {
  user: User;
  projectID: string;
};

export type Props = {
  isOpen: boolean;
  onClose: () => void;
  onAddProjectCollaborator: (projectLeadUserID: UUID, projectID: UUID) => void;
  leadAddDetails: AddLeadtoProject | null;
};

export default function AddLeadToProjectDialog(props: Props) {
  const sendAnalytics = useSendAnalytics();
  const onClose = () => {
    props.onClose?.();
  };

  if (!props.leadAddDetails) {
    return null;
  }

  return (
    <Dialog
      footerLeft={
        <Button
          label="Cancel"
          onClick={() => {
            onClose();
            sendAnalytics(leadEvent(LeadKey.COMPANY_ADMIN_STATS, LeadEvent.InviteConfirmCancel));
          }}
          type="secondary"
        />
      }
      footerRight={
        <Button
          label="Add to Project"
          onClick={() => {
            if (props.leadAddDetails?.user.id) {
              props.onAddProjectCollaborator(
                props.leadAddDetails?.user.id,
                props.leadAddDetails?.projectID
              );
              sendAnalytics(leadEvent(LeadKey.COMPANY_ADMIN_STATS, LeadEvent.InviteConfirm));
            }
          }}
          startIcon={<GroupAdd />}
          type="primary"
        />
      }
      isOpen={props.isOpen}
      onClose={() => {
        onClose();
        sendAnalytics(leadEvent(LeadKey.COMPANY_ADMIN_STATS, LeadEvent.InviteConfirmClose));
      }}
      title="Invite Teammate"
    >
      <DialogContent className="flex flex-col gap-2">
        <div>{`${props.leadAddDetails?.user.name} is not currently a teammate on the selected project`}</div>
        <div>
          By selecting this employee as the project lead, they will be invited to the project as an
          administrator.
        </div>
      </DialogContent>
    </Dialog>
  );
}
