import { ReactNode } from 'react';

type Props = {
  children: ReactNode;
  id?: string;
  legend?: ReactNode;
  title: ReactNode;
};

export default function Section({ children, id, legend, title }: Props) {
  return (
    <div className="mb-5 mt-5" id={id}>
      <div className="flex items-center">
        <div className="ml-2 flex-auto type-heading2">{title}</div>
        <div className="ml-auto">{legend}</div>
      </div>
      {children}
    </div>
  );
}
