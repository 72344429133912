import { AnalyticsPropertiesQuery, AnalyticsPropertiesQueryVariables } from '../generated/graphql';

import { analyticsPropertiesQuery } from './queries';
import { useQuery } from './useMountAwareQuery';
import { MountPolicy } from './usePolicyOnFirstMount';

const useAnalyticsPropertiesQuery = (
  projectId?: UUID,
  previewRoleId?: UUID,
  previewUserId?: UUID
) =>
  useQuery<AnalyticsPropertiesQuery, AnalyticsPropertiesQueryVariables>(
    analyticsPropertiesQuery,
    {
      variables: { projectId, previewRoleId, previewUserId },
    },
    MountPolicy.SKIP_ON_MOUNT
  );

export default useAnalyticsPropertiesQuery;
