import { Selected } from '../../../../api/apollo/reactiveVars';
import { SourceAsset, SourceFolder, SourceHub, SourceProject } from '../../../../generated/graphql';
import { getAssetTempID } from '../../../assets/utils';

type SourceCommon = {
  isFolder?: boolean;
  blobUrl?: string;
  disabled?: boolean;
  pending?: boolean;
};
export type FileTableCell = (SourceFolder | SourceAsset | SourceProject | SourceHub) & SourceCommon;
type ColumnFn = (entry: FileTableCell) => JSX.Element;

export const getEntries = (fileTableEntries: FileTableCell[], columnFns: ColumnFn[]) =>
  fileTableEntries.map(
    (entry) => entry && columnFns.map((fn: ColumnFn) => ({ component: fn(entry), key: entry.id }))
  );

export const getSelectedIds = (selected: Selected) =>
  Object.keys(selected).filter((id) => selected[id]);

export const getSelectedIndices = (entries: FileTableCell[], selectedIds: UUID[]) =>
  entries.map(({ id }) => selectedIds.includes(id));

export const getSelectableIndices = (entries: FileTableCell[]) =>
  entries.map(({ isFolder }) => !isFolder);

export const getDisabledIndices = (entries: FileTableCell[]) =>
  entries.map(({ disabled }) => !!disabled);

export const getEntryIsPending = (asset: FileTableCell | Asset | FileAsset) =>
  asset.id === getAssetTempID(asset.name, (asset as Asset).size);
