import { JoinProjectRoutes } from '../../api/gqlEnums';
import ContingencyReportData from '../ContingencyReport/ContingencyReportData/ContingencyReportData';
import CostReportData from '../CostReport/CostReportData/CostReportData';
import ImportEstimateData from '../ImportEstimate/ImportEstimateData/ImportEstimateData';
import ConfirmCompanyData from '../login/Signup/SignupPages/ConfirmCompanyData';
import CreateCompanyData from '../login/Signup/SignupPages/CreateCompanyData';
import SetupUserData from '../login/Signup/SignupPages/SetupUserData';
import MilestoneData from '../Milestone/MilestoneData/MilestoneData';
import StatefulMilestones from '../Milestones/Milestones';
import ExportActiveMilestoneContingency from '../Print/ExportContingency/ExportActiveMilestoneContingency';
import ExportAllMilestonesContingency from '../Print/ExportContingency/ExportAllMilestonesContingency';
import ExportItemsList from '../Print/ExportItemsList/ExportItemsList';
import ExportMilestone from '../Print/ExportMilestone/ExportMilestone';
import ExportMilestoneSummaryReport from '../Print/ExportMilestoneSummaryReport/ExportMilestoneSummaryReport';
import ExportVariance from '../Print/ExportVariance/ExportVariance';
import PrintDashboardBreakdowns from '../Print/PrintBreakdowns/PrintBreakdownsData';
import PrintContingencyReport from '../Print/PrintContingencyReport/PrintContingencyReport';
import PrintCostReport from '../Print/PrintCostReport/PrintCostReport';
import PrintDashboardData from '../Print/PrintDashboard/PrintDashboardData';
import PrintViewItemDetails from '../Print/PrintItemDetails/PrintViewItemDetails';
import PrintViewItemsList from '../Print/PrintItemsListNew/PrintViewItemsList';
import PrintViewItemsListAndItemDetailsList from '../Print/PrintItemsListNew/PrintViewItemsListAndItemDetailsList';
import PrintProjectCostBreakdown from '../Print/PrintProjectCostBreakdown/PrintProjectCostBreakdown';
import PrintScenariosSandbox from '../Print/PrintScenariosSandbox/PrintScenariosSandbox';
import PrintVarianceReport from '../Print/PrintVarianceReport/PrintVarianceReport';
import ExportProjectCostBreakdownReportData from '../ProjectCostBreakdownReport/ExportProjectCostBreakdownReportData';
import ProjectCostBreakdownReportData from '../ProjectCostBreakdownReport/ProjectCostBreakdownReportData';
import ProjectPropertiesData from '../ProjectProperties/ProjectPropertiesData';
import ReportDistributionUnsubscribe from '../ReportDistributionUnsubscribe/ReportDistributionUnsubscribe';
import ReportDistributionView from '../ReportDistributionView/ReportDistributionView';
import ReportsTabData from '../ReportsTab/ReportsTab/ReportsTabData';
import RouteReport from '../ReportsTab/RouteReport';
import ScenariosTop from '../Scenarios/ScenariosTop';
import Timeline from '../Timeline/Timeline';
import TrendingCostReportData from '../TrendingCostReport/TrendingCostReportData/TrendingCostReportData';
import VarianceReportData from '../VarianceReport/VarianceReportData/VarianceReportData';

export const routeToProjectComponent = {
  /**
   * ================== 🧚🧚🧚 HEY, LISTEN! 🧚🧚🧚 ==================
   * You should probably be adding any new routes in `routes/index.tsx` instead
   * of in here. This file is our legacy way of adding routes and anything added
   * here is gonna need to be ported over. #ask-engineering for any Q's!
   * ================== 🧚🧚🧚 HEY, LISTEN! 🧚🧚🧚 ==================
   */
  [JoinProjectRoutes.BREAKDOWNS]: { element: <PrintDashboardBreakdowns /> },
  [JoinProjectRoutes.CONTINGENCY_ALL_MILESTONES_REPORT]: { element: <ContingencyReportData /> },
  [JoinProjectRoutes.PROJECT_COST_BREAKDOWN_REPORT]: {
    element: <ProjectCostBreakdownReportData />,
  },
  [JoinProjectRoutes.CONTINGENCY_ALLOWANCE_REPORT]: { element: <ContingencyReportData /> },
  [JoinProjectRoutes.EXPORT_ACTIVE_MILESTONE_CONTINGENCY]: {
    element: <ExportActiveMilestoneContingency />,
  },
  [JoinProjectRoutes.EXPORT_ALL_MILESTONES_CONTINGENCY]: {
    element: <ExportAllMilestonesContingency />,
  },
  [JoinProjectRoutes.EXPORT_ITEMS_LIST]: { element: <ExportItemsList /> },
  [JoinProjectRoutes.EXPORT_MILESTONE_BUDGET]: { element: <ExportMilestone /> },
  [JoinProjectRoutes.EXPORT_MILESTONE_ESTIMATE]: { element: <ExportMilestone /> },
  [JoinProjectRoutes.EXPORT_MSR]: { element: <ExportMilestoneSummaryReport /> },
  [JoinProjectRoutes.EXPORT_PROJECT_COST_BREAKDOWN_REPORT]: {
    element: <ExportProjectCostBreakdownReportData />,
  },
  [JoinProjectRoutes.EXPORT_VARIANCE]: { element: <ExportVariance /> },
  [JoinProjectRoutes.IMPORT_ESTIMATE]: { element: <ImportEstimateData /> },
  [JoinProjectRoutes.MILESTONE_DETAILS]: { element: <MilestoneData /> },
  [JoinProjectRoutes.MILESTONES]: { element: <StatefulMilestones /> },
  [JoinProjectRoutes.MSR]: { element: <CostReportData /> },
  [JoinProjectRoutes.PRINT_CONTINGENCY_ALL_MILESTONES_REPORT]: {
    element: <PrintContingencyReport />,
  },
  [JoinProjectRoutes.PRINT_CONTINGENCY_ALLOWANCE_REPORT]: { element: <PrintContingencyReport /> },
  [JoinProjectRoutes.PRINT_ITEM_DETAILS]: { element: <PrintViewItemDetails /> },
  [JoinProjectRoutes.PRINT_ITEMS_LIST_DETAILS]: {
    element: <PrintViewItemsListAndItemDetailsList />,
  },
  [JoinProjectRoutes.PRINT_ITEMS_LIST]: { element: <PrintViewItemsList /> },
  [JoinProjectRoutes.PRINT_MSR_SUMMARY]: { element: <PrintCostReport /> },
  [JoinProjectRoutes.PRINT_MSR]: { element: <PrintCostReport /> },
  [JoinProjectRoutes.PRINT_PROJECT_COST_BREAKDOWN]: {
    element: <PrintProjectCostBreakdown />,
  },
  [JoinProjectRoutes.PRINT_PROJECT_DASHBOARD_SUMMARY]: { element: <PrintDashboardData /> },
  [JoinProjectRoutes.PRINT_PROJECT_DASHBOARD]: { element: <PrintDashboardData /> },
  [JoinProjectRoutes.PRINT_REPORT_ROUTER]: { element: <RouteReport isPrint /> },
  [JoinProjectRoutes.PRINT_SCENARIOS]: { element: <PrintScenariosSandbox /> },
  [JoinProjectRoutes.PRINT_VARIANCE]: { element: <PrintVarianceReport /> },
  [JoinProjectRoutes.REPORT_DISTRIBUTION_FILE]: { element: <ReportDistributionView /> },
  [JoinProjectRoutes.REPORT_DISTRIBUTION_UNSUBSCRIBE]: {
    element: <ReportDistributionUnsubscribe />,
  },
  [JoinProjectRoutes.REPORT_ROUTER]: { element: <RouteReport /> },
  [JoinProjectRoutes.REPORTS]: { element: <ReportsTabData /> },
  [JoinProjectRoutes.SCENARIOS]: { element: <ScenariosTop /> },
  [JoinProjectRoutes.SETTINGS]: { element: <ProjectPropertiesData /> },
  [JoinProjectRoutes.SIGNUP_CONFIRM_COMPANY]: { element: <ConfirmCompanyData /> },
  [JoinProjectRoutes.SIGNUP_CREATE_COMPANY]: { element: <CreateCompanyData /> },
  [JoinProjectRoutes.SIGNUP_SETUP_USER]: { element: <SetupUserData /> },
  [JoinProjectRoutes.TIMELINE]: { element: <Timeline /> },
  [JoinProjectRoutes.TRENDING_COST_REPORT]: { element: <TrendingCostReportData /> },
  [JoinProjectRoutes.VARIANCE]: { element: <VarianceReportData /> },
};
