import { isEnumValue } from '../../utilities/types';
import { ButtonBar } from '../scales';

export enum ProjectsToggle {
  ALL = 'All',
  MY_PROJECTS = 'My Projects',
}

type ProjectsListShowToggleProps = {
  listShow: ProjectsToggle;
  projectsCounts: ProjectsCounts;
  setListShow: (view: ProjectsToggle) => void;
};

export default function ProjectsListShowToggle(props: ProjectsListShowToggleProps) {
  return (
    <ButtonBar
      onChange={(value: string) => {
        if (isEnumValue(ProjectsToggle, value)) props.setListShow(value);
      }}
      options={[
        {
          label: `All (${props.projectsCounts.allProjectsCount})`,
          value: ProjectsToggle.ALL,
        },
        {
          label: `My Projects (${props.projectsCounts.myProjectsCount})`,
          value: ProjectsToggle.MY_PROJECTS,
        },
      ]}
      value={props.listShow}
    />
  );
}
