import { Link, useLocation } from 'react-router-dom';

import { forecastingEventTypes } from '../../analytics/analyticsEventProperties';
import { FORECASTING_EXPLORE_PATH, FORECASTING_REPORTS_PATH } from '../../constants';
import { withStyles } from '../../theme/komodo-mui-theme';

import styles from './ForecastingHeaderStyles';
import useSendForecastingAnalytics from './hooks/useSendForecastingAnalytics';

const ForecastingHeader = (props: { classes: Classes<typeof styles> }) => {
  const { classes } = props;

  const { pathname } = useLocation();
  const sendForecastingAnalytics = useSendForecastingAnalytics();

  return (
    <div className={classes.container}>
      <Link
        aria-selected={pathname.includes(`/${FORECASTING_EXPLORE_PATH}`)}
        className={classes.button}
        data-cy="nav-forecasting-explore"
        onClick={() => sendForecastingAnalytics(forecastingEventTypes.EXPLORE_CTA)}
        tabIndex={pathname.includes(`/${FORECASTING_EXPLORE_PATH}`) ? -1 : undefined}
        title="Explore"
        to={FORECASTING_EXPLORE_PATH}
      >
        EXPLORE
      </Link>
      <Link
        aria-selected={pathname.includes(`/${FORECASTING_REPORTS_PATH}`)}
        className={classes.button}
        data-cy="nav-forecasting-reports"
        onClick={() => sendForecastingAnalytics(forecastingEventTypes.REPORTS_CTA)}
        tabIndex={pathname.includes(`/${FORECASTING_REPORTS_PATH}`) ? -1 : undefined}
        title="Reports"
        to={FORECASTING_REPORTS_PATH}
      >
        REPORTS
      </Link>
    </div>
  );
};

export default withStyles(styles)(ForecastingHeader);
