import {
  GetContingencyReportQuery,
  GetContingencyReportQueryVariables,
  Status,
} from '../../../generated/graphql';
import { useQuery } from '../../../hooks/useMountAwareQuery';

import { getContingencyReportQuery } from './queries';

export const useContingencyReportQuery = (
  projectID: UUID,
  milestoneID?: UUID,
  itemStatuses?: Status[],
  filteredContingencies?: string[],
  filteredAllowances?: string[]
) =>
  useQuery<GetContingencyReportQuery, GetContingencyReportQueryVariables>(
    getContingencyReportQuery,
    {
      variables: {
        projectID,
        milestoneID,
        itemStatuses,
        filteredContingencies,
        filteredAllowances,
      },
      skip: !projectID || !milestoneID,
    }
  );
