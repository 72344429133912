import { FC, useEffect, useRef, useState } from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  IconButton,
  LinearProgress,
  Typography,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';

import { useToggleEventDataVisibilityMutation } from '../../../../hooks/useToggleEventDataVisibilityMutation';
import { withStyles } from '../../../../theme/komodo-mui-theme';
import { getProjectIdFromUrl } from '../../../../utilities/url';
import { CostTrendlineEvent } from '../../../dashboard/DashboardCharts/DashboardChartsTrend/DashboardChartsTrendUtils';
import DialogsStyles, { DIALOG_PADDING } from '../../DialogsStyles';

import DialogsHideCostTrendlineEventList from './DialogsHideCostTrendlineEventList/DialogsHideCostTrendlineEventList';

type DialogHideTrendlineEventProps = {
  activeMilestone: Milestone;
  classes: Classes<typeof DialogsStyles>;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  loading: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  onClose: any;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  open: boolean;
  refetchCostTrendlineEvents: () => void;
  renderTrendline: (
    height: number,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
    onClick: (clicked: any) => void,
    onHoverEventTrendline: (v: CostTrendlineEvent) => void,
    onMouseLeaveTrendline: () => void,
    selectedEvent: CostTrendlineEvent | null
  ) => JSX.Element;
  trendlineEvents?: CostTrendlineEvent[];
};

const DialogsHideCostTrendlineEvents: FC<DialogHideTrendlineEventProps> = ({
  activeMilestone,
  classes,
  loading = true,
  onClose,
  open,
  refetchCostTrendlineEvents,
  renderTrendline,
  trendlineEvents,
}) => {
  const projectId = getProjectIdFromUrl();
  const title = `Hide Events from (${activeMilestone && activeMilestone.name}) Trendline Chart`;

  const [selectedEvent, setSelectedEvent] = useState<CostTrendlineEvent | null>(null);
  const toggleFn = useToggleEventDataVisibilityMutation(refetchCostTrendlineEvents);

  const onHoverEventTrendline = (v: CostTrendlineEvent) => {
    if (selectedEvent !== v) {
      setSelectedEvent(v);
    }
  };

  const onEventListHoverEvent = (v: CostTrendlineEvent) => {
    if (selectedEvent !== v) {
      setSelectedEvent(v);
    }
  };

  const onMouseLeaveTrendline = () => {
    if (selectedEvent) {
      setSelectedEvent(null);
    }
  };

  const onEventListItemMouseLeave = () => {
    if (selectedEvent) {
      setSelectedEvent(null);
    }
  };

  const filterUniqueEventsAndReverse = (events?: CostTrendlineEvent[]) => {
    if (!events) {
      return [];
    }

    const uniqueEvents = [];
    // the first event is only the base estimate
    // and can be excluded
    for (let i = 1; i < events.length; i += 1) {
      if (events[i - 1].ids !== events[i].ids) {
        uniqueEvents.push(events[i]);
      }
    }

    // reverse the order so newer events are first
    uniqueEvents.reverse();
    return uniqueEvents;
  };

  // remove any duplicated events from the list of trendline events
  const [uniqueEvents, setUniqueEvents] = useState<CostTrendlineEvent[]>(
    filterUniqueEventsAndReverse(trendlineEvents)
  );

  useEffect(() => {
    setUniqueEvents(filterUniqueEventsAndReverse(trendlineEvents));
  }, [trendlineEvents]);

  const onClickToggleEventVisibility = () => {
    if (selectedEvent) {
      toggleFn(projectId, !selectedEvent.visible, selectedEvent.ids ?? []);
    }
  };

  const onClickToggleAllEvents = (visible: boolean) => {
    if (uniqueEvents) {
      // get the ids for all events
      const allEventIds: UUID[] = [];
      uniqueEvents.forEach((event) => {
        if (event.visible !== visible) {
          event.ids?.forEach((id) => allEventIds.push(id));
        }
      });
      toggleFn(projectId, visible, allEventIds);
      refetchCostTrendlineEvents();
    }
  };
  const eventListItemRef = useRef<HTMLDivElement>(null);
  // Scroll the the currently highlighted event in the event list
  // from the trendline graph
  const scrollToRef = () => {
    if (eventListItemRef && eventListItemRef.current) {
      eventListItemRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  // determine the height we should make the trendline
  // because the way the graph library works we need to
  // give it an exact height, and we want the heigth
  // to be 50% of the dialog height for the user
  const [trendlineHeight, setTrendlineHeight] = useState(300);

  const adjustHeight = (container: HTMLDivElement) => {
    if (container) {
      const height = container.offsetHeight - 2 * DIALOG_PADDING;
      if (height !== trendlineHeight) setTrendlineHeight(height);
    }
  };

  const content = (
    <DialogContent className={classes.fullHeight}>
      <div className={classes.contentContainer}>
        <div ref={adjustHeight} className={classes.halfHeight}>
          <div className={classes.content}>
            {renderTrendline(
              trendlineHeight,
              scrollToRef,
              onHoverEventTrendline,
              onMouseLeaveTrendline,
              selectedEvent
            )}
          </div>
        </div>
        <div className={classes.halfHeight}>
          <DialogsHideCostTrendlineEventList
            eventListItemRef={eventListItemRef}
            milestoneID={activeMilestone?.id}
            onClickToggleAllEvents={onClickToggleAllEvents}
            onClickToggleEventVisibility={onClickToggleEventVisibility}
            onEventListItemMouseLeave={onEventListItemMouseLeave}
            onMouseLeave={onMouseLeaveTrendline}
            onMouseOver={onEventListHoverEvent}
            selectedEvent={selectedEvent}
            trendlineEvents={uniqueEvents}
          />
        </div>
      </div>
    </DialogContent>
  );

  return (
    <Dialog
      classes={{
        paper: classes.dialogPaperLarge,
      }}
      maxWidth={false}
      onClose={onClose}
      open={open}
    >
      <div className={classes.titleContainer}>
        <Typography variant="title">{title}</Typography>
        <IconButton onClick={onClose} title="Close dialog">
          <Close />
        </IconButton>
      </div>
      <Divider />
      <div className={classes.loading}>
        <LinearProgress hidden={!loading} />
      </div>
      {content}
      <Divider />
      <DialogActions className={classes.action}>
        <Button
          color="primary"
          data-cy="button-done-editingTrendline"
          onClick={() => {
            onClose();
          }}
          variant="contained"
        >
          Done Editing
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(DialogsStyles)(DialogsHideCostTrendlineEvents);
