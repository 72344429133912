import {
  EstimateTotalTypeConflictInfoQuery,
  EstimateTotalTypeConflictInfoQueryVariables,
} from '../../../../generated/graphql';
import { useQuery } from '../../../../hooks/useMountAwareQuery';
import { getEstimateTotalTypeConflictInfo } from '../queries';

export const useGetEstimateTotalTypeConflictInfoQuery = (
  variables: EstimateTotalTypeConflictInfoQueryVariables,
  skip = !variables.estimateID || !variables.milestoneID || !variables.projectID
) =>
  useQuery<EstimateTotalTypeConflictInfoQuery, EstimateTotalTypeConflictInfoQueryVariables>(
    getEstimateTotalTypeConflictInfo,
    {
      variables,
      skip,
    }
  );
