import { createStyles } from '@material-ui/core';

const styles = () =>
  createStyles({
    costReportChart: {
      position: 'sticky',
      left: 0,
      marginBottom: 40,
      paddingTop: 16,
      paddingLeft: 24,
      paddingRight: 24,
      '@media print': {
        width: 'calc(100% - 36px)',
        marginLeft: -36,
      },
    },
  });

export default styles;
