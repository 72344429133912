//* * Chart label color filter definition */
export default function CostFillPattern() {
  return (
    <defs>
      <filter height="100%" id="blur" width="100%" x="0" y="0">
        <feColorMatrix
          in="SourceAlpha"
          result="matrix"
          type="matrix"
          values="-1 0 0 0 1, 0 -1 0 0 1, 0 0 -1 0 1, 0 0 0 1 0"
        />
        <feMorphology in="matrix" operator="dilate" radius="2" result="offOut" />
        <feGaussianBlur in="offOut" result="blurOut" stdDeviation="0.6" />
        <feBlend in="SourceGraphic" in2="blurOut" />
      </filter>
    </defs>
  );
}
