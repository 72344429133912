import {
  LoadUserNotificationSettingQuery,
  LoadUserNotificationSettingQueryVariables,
} from '../generated/graphql';

import { loadUserNotificationSettingQuery } from './queries';
import { useQuery } from './useMountAwareQuery';

const useUserNotificationSettingQuery = (projectID: UUID | null) =>
  useQuery<LoadUserNotificationSettingQuery, LoadUserNotificationSettingQueryVariables>(
    loadUserNotificationSettingQuery,
    {
      variables: { projectID },
      skip: !projectID,
    }
  );

export default useUserNotificationSettingQuery;
