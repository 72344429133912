import Cell from './cells/Cell';
import CostCell from './cells/CostCell';
import NumberCell from './cells/NumberCell';
import TextCell from './cells/TextCell';
import Table from './Table';

export default Table;

export const TableComponents = {
  Cell,
  CostCell,
  NumberCell,
  TextCell,
};
