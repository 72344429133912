import { gql } from '../../../../../api/graphqlFragments';

export const searchItems = gql`
  query searchItems(
    $filters: ForecastingExploreItemsFilters!
    $projectsFilters: ForecastingProjectsFilters!
    $searchResultType: SearchResultType
    $search: String!
    $sortBy: ForecastingExploreItemsSortBy!
    $pagination: Pagination!
  ) {
    searchItems(
      filters: $filters
      projectsFilters: $projectsFilters
      searchResultType: $searchResultType
      search: $search
      sortBy: $sortBy
      pagination: $pagination
    ) {
      aggregations {
        name
        values {
          name
          count
        }
      }
      data {
        categories {
          id
          name
          number
          categorization {
            id
            name
          }
        }
        cost {
          min
          max
        }
        createdBy {
          name
        }
        hasAccess
        id
        milestoneDate
        name
        number
        numOptions
        parentID
        parentName
        project {
          budget
          estimate
          id
          location
          milestoneDate
          milestoneID
          milestoneName
          name
          orgNodes {
            id
            name
          }
          projectLeadName
          runningTotal
          status
          thumbnail
          type
          updatedAt
          updatedBy
          hasAccess
        }
        status
        type
        updatedAt
      }
      total
    }
  }
`;

export const searchCategoryFilterLabels = gql`
  query searchCategoryFilterLabels {
    searchCategoryFilterLabels {
      key
      label
    }
  }
`;
