import * as d3 from 'd3';
import { useState } from 'react';

import { Tooltip } from '../../../scales';

import { PieChartData, PieChartOptions, PieChartSize, describeArc } from './ChartsPieGraphUtils';
import TooltipPieSegment from './TooltipPieSegment';
import { chartColorUnassigned, getColor } from './utils';

type Props = {
  chartSize: PieChartSize;
  colors: string[];
  data: PieChartData[];
  dataOther?: PieChartData[]; // Used for the tooltip to display list of "Other" entries that are consisted in this grouping
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  displaySectionTooltip?: boolean;
  isCurrency?: boolean;
  onSliceClick?: (name: string) => void;
  onTooltip?: () => void;
  options?: PieChartOptions;
};

// By default the chart will start drawing at 3 o' clock,
// and draw clockwise around the circle
// We want to start drawing at noon.  So, to start drawing
// at noon we need change the start angle by -90 degrees
// which in radians is -π / 2
function ChartsPieGraphPaths({
  chartSize,
  colors,
  data,
  dataOther,
  displaySectionTooltip,
  isCurrency,
  onSliceClick,
  onTooltip,
  options,
}: Props) {
  const totalShare = data.reduce((sum, item) => sum + item.share, 0);
  const [highlightedIndex, setHighlightedIndex] = useState<number>(-1);

  // Filter out zero-share data points
  const nonZeroData = data.filter((item) => item.share > 0);

  const pieData = d3
    .pie<PieChartData>()
    .startAngle(-Math.PI / 2)
    .value((d) => d.share)
    .padAngle(options?.spaceBetweenWedges ? 0.02 : 0)
    .sort(null)(nonZeroData);

  const scale = d3.scaleOrdinal(nonZeroData.map((d) => d.name)).range(colors);
  const radius = chartSize.diameter / 2;

  // If there is only one non-zero data point, render a full circle
  if (nonZeroData.length === 1) {
    return (
      <svg height={chartSize.diameter} width={chartSize.diameter}>
        <Tooltip
          key={nonZeroData[0].name}
          content={
            <TooltipPieSegment
              fill={chartColorUnassigned}
              isCurrency={isCurrency}
              name={nonZeroData[0].name}
              share={nonZeroData[0].share}
              totalShare={totalShare}
            />
          }
          isDisabled={!displaySectionTooltip}
          onOpen={onTooltip}
          placement="bottom"
        >
          <circle cx={radius} cy={radius} fill={chartColorUnassigned} r={radius} />
        </Tooltip>
      </svg>
    );
  }

  return (
    <>
      <svg height={chartSize.diameter} width={chartSize.diameter}>
        {pieData.map((d) => (
          <Tooltip
            key={d.data.name}
            content={
              <TooltipPieSegment
                dataOther={dataOther}
                fill={getColor(colors, d.data, d.index)}
                isCurrency={isCurrency}
                name={d.data.name}
                share={d.data.share}
                totalShare={totalShare}
              />
            }
            isDisabled={!displaySectionTooltip}
            placement="bottom"
          >
            <path
              key={d.data.name}
              className={scale(d.index.toString())}
              d={describeArc(radius, radius, radius, d.startAngle, d.endAngle, d.padAngle)}
              fill={getColor(colors, d.data, d.index)}
              onClick={() => onSliceClick?.(d.data.name)}
              onPointerEnter={() => {
                // if there is a tooltip then adjust the opacity
                if (displaySectionTooltip) setHighlightedIndex(d.index);
              }}
              onPointerLeave={() => {
                // if there is a tooltip then adjust the opacity
                if (displaySectionTooltip) setHighlightedIndex(-1);
              }}
              opacity={highlightedIndex === -1 || d.index === highlightedIndex ? 1 : 0.6}
              style={{ cursor: onSliceClick && 'pointer' }}
            />
          </Tooltip>
        ))}
      </svg>
    </>
  );
}

export default ChartsPieGraphPaths;
