import { FC, MouseEvent } from 'react';
import { NavigateFunction } from 'react-router-dom';

import { itemNewWindowOpen, itemSidebarOpen } from '../../../analytics/analyticsEventProperties';
import useSendAnalytics from '../../../hooks/useSendAnalytics';
import { clickQuicklinkHandler } from '../../../utilities/clickHandler';
import { filterHtmlOutOfString } from '../../../utilities/string';
import EntryLink from '../../shared-widgets/EntryLink/EntryLink';
import { generateItemDisplayTitle, isPrivateVisibility } from '../ItemsUtils';

import { generateTitle } from './ItemsListItemUtils';

type ItemsListItemDataProps = {
  hasDoubleClick: boolean;
  item: ItemLike | ItemsListItem;
  link: string;
  sharedUsers: Pick<User, 'id' | 'name'>[];
};

const ItemsListItemLinkData: FC<ItemsListItemDataProps> = ({
  hasDoubleClick,
  item,
  link,
  sharedUsers,
}) => {
  const { id: itemID, name, visibility } = item;
  const title = (item as ItemLike).description
    ? filterHtmlOutOfString((item as ItemLike).description)
    : '';
  const sidebarAnalytics = () => {
    sendAnalytics(itemSidebarOpen(itemID));
  };

  const sendAnalytics = useSendAnalytics();

  const itemNewWindowAnalytics = () => {
    sendAnalytics(itemNewWindowOpen(itemID));
  };

  const clickHandler = (e: MouseEvent, navigate: NavigateFunction) =>
    clickQuicklinkHandler(e, navigate, link, itemNewWindowAnalytics, undefined, sidebarAnalytics);

  return (
    <EntryLink
      ariaLabel={`view ${generateTitle(item)}`}
      clickHandler={clickHandler}
      dataCy={`button-item-row-${name}`}
      dataCyDisplayName="itemRow-itemLikeTitle"
      displayData={generateItemDisplayTitle(item)}
      isPrivate={isPrivateVisibility(visibility)}
      sharedPrivateItemUsers={isPrivateVisibility(visibility) ? sharedUsers : undefined}
      title={hasDoubleClick ? '' : title}
    />
  );
};

export default ItemsListItemLinkData;
