import { CloseLibrary, OpenLibrary } from '../../Icons/OpenLibrary';
import { IconButton } from '../../scales';

type Props = {
  isClosed: boolean;
  onClick: () => void;
};

const SidebarCollapseButton = (props: Props) => (
  <IconButton
    aria-label="Toggle Library Sidebar"
    data-cy="toggle-library-sidebar-button"
    icon={props.isClosed ? <OpenLibrary /> : <CloseLibrary />}
    onClick={props.onClick}
    type="secondary"
  />
);

export default SidebarCollapseButton;
