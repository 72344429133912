import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';

const MultilevelCategorizationsStyles = (theme: KomodoTheme) =>
  createStyles({
    catBox: {
      border: theme.border.line,
      minHeight: 370,
    },
    descriptionFront: {
      alignItems: 'center',
      display: 'flex',
      height: 29,
    },
    dialogContent: {
      overflowY: 'auto',
      height: 375,
    },
    iconContainer: {
      minWidth: 24,
      display: 'flex',
    },
    icon: {
      color: theme.palette.joinPrimary,
      height: 20,
      margin: 'auto',
      width: 20,
      cursor: 'pointer',
    },
    iconRight: {
      display: 'flex',
      position: 'absolute',
      right: 20,
      bottom: 16,
    },
    iconReplace: {
      fontSize: 16,
    },
    iconGrid: {
      height: theme.iconSizes.compact,
      width: theme.iconSizes.compact,
      border: `solid ${theme.palette.joinGrey400} 1px`,
      marginRight: 7,
      paddingRight: 2,
      '&:hover': {
        border: theme.border.lineBorderBlack,
        background: theme.palette.backgroundGrey,
      },
      '&:focus': {
        border: theme.border.focus,
        background: theme.palette.hoverBackground,
      },
    },
    paddingLeft: {
      paddingLeft: 8,
    },
    child: {
      paddingLeft: 20,
      midWidth: 24,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      alignItems: 'center',
    },
    root: {
      position: 'relative',
    },
    title: {
      color: theme.palette.joinPrimary,
      fontSize: theme.typography.inputs.fontSize,
      fontWeight: theme.typography.title.fontWeight,
    },
    linkButton: {
      ...theme.typography.link,
      fontFamily: theme.typography.fontFamily,
      display: 'flex',
      alignItems: 'center',
      background: 'transparent',
      border: 'none',
    },
    TopContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
    },
  });

export default MultilevelCategorizationsStyles;
