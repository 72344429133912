import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';

const styles = (theme: KomodoTheme) =>
  createStyles({
    backgroundColor: { backgroundColor: theme.palette.backgroundWhite },
    cardHeaderTypography: theme.typography.cardHeader,
    containerPadding: {
      padding: '8px 18px',
      flexGrow: 1,
    },
    floatRight: {
      alignSelf: 'flex-end',
      display: 'flex',
    },
    itemSidebarOptionsLine: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingBottom: 8,
    },
    optionsContainerGrowth: {
      flexGrow: 1,
      backgroundColor: theme.palette.backgroundWhite,
    },
    optionsHeader: {
      display: 'flex',
      justifyContent: 'right',
      backgroundColor: theme.palette.app.disabledBackground,
      padding: '8px 16px',
    },
  });

export default styles;
