import { FC, useState } from 'react';

import { Typography } from '@material-ui/core';

import {
  UserSourceSystemEventType,
  getUserSourceSystemAnalyticsEvent,
} from '../../../../analytics/analyticsEventProperties';
import { SourceSystem, UserSource } from '../../../../generated/graphql';
import useSendAnalytics from '../../../../hooks/useSendAnalytics';
import { withStyles } from '../../../../theme/komodo-mui-theme';
import DialogsConfirm from '../../../Dialogs/DialogsConfirm/DialogsConfirm';
import { Button } from '../../../scales';
import { useRemoveUserSource, useUserSourcesQuery } from '../../hooks';
import {
  getSourceAuthURL,
  getSourceNameText,
  getSourcesInfo,
} from '../FilesDialog/FilesDialogUtils';

import styles from './FilesSourcesStyles';
import { getSignInButtonText, getSignInLabelText } from './FilesSourcesUtils';

type FilesSourcesProps = {
  classes: Classes<typeof styles>;
};

const FilesSources: FC<FilesSourcesProps> = ({ classes }) => {
  const { data } = useUserSourcesQuery();
  const sendAnalytics = useSendAnalytics();
  const availableSources = data?.userSources.sources || [];
  const clients = data?.userSources.clients || [];
  const sourcesInfo = getSourcesInfo(clients);
  const [userSourceToRemove, setUserSourceToRemove] = useState<UserSource | null>(null);
  const [removeUserSource] = useRemoveUserSource();
  const removeSource = () => {
    if (userSourceToRemove) {
      removeUserSource(userSourceToRemove.id, () => {
        sendAnalytics(
          getUserSourceSystemAnalyticsEvent(
            UserSourceSystemEventType.REMOVE,
            userSourceToRemove.sourceSystem
          )
        );

        setUserSourceToRemove(null);
      });
    }
  };

  const sourceComps = sourcesInfo.map((sourceInfo) => {
    const updatedSourceInfo = sourceInfo;
    const text = getSourceNameText(updatedSourceInfo.sourceSystem);
    const userSource = availableSources.find(
      (s) => s.sourceSystem === updatedSourceInfo.sourceSystem
    );
    // in order to make POST calls to the Autodesk API we need to define both read and write scopes
    if (updatedSourceInfo.sourceSystem === SourceSystem.AUTODESK) {
      updatedSourceInfo.scope = 'data:read data:write';
    }
    const onClick = () =>
      userSource
        ? setUserSourceToRemove(userSource)
        : window.open(getSourceAuthURL(updatedSourceInfo), '_blank');
    return (
      <div key={updatedSourceInfo.sourceSystem} className={classes.source}>
        <img alt={text} className={classes.logo} src={updatedSourceInfo.img} />
        <Typography className={classes.padded}>{getSignInLabelText(text, userSource)}</Typography>
        <Button
          // TODO: this should rely on button text instead of data-cy
          data-cy="integration-sign-in-button"
          label={getSignInButtonText(text, userSource)}
          onClick={onClick}
          type="secondary"
        />
      </div>
    );
  });

  return (
    <div className={classes.contentRoot}>
      <div className={classes.column}>
        <Typography className={classes.padded} variant="title">
          Link cloud storage accounts
        </Typography>
        <Typography className={classes.padded} variant="subheading">
          Connect online storage accounts to work with your files within Join.
        </Typography>
        <div className={classes.sources}>{sourceComps}</div>
      </div>
      <DialogsConfirm
        body="Would you like to disconnect this file connection? This will prevent you from navigating this cloud storage to attach linked assets."
        onClose={() => setUserSourceToRemove(null)}
        onConfirm={removeSource}
        open={!!userSourceToRemove}
        title={`Remove ${
          userSourceToRemove ? `${getSourceNameText(userSourceToRemove.sourceSystem)} ` : ''
        }Connection`}
      />
    </div>
  );
};

export default withStyles(styles)(FilesSources);
