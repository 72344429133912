export enum ProjectCompIntersectionElements {
  PROJECT_NAME = 'project-comp-project-name',
  COST_TABLE_HEADER = 'project-comp-cost-table-header',
  COST_TABLE_FOOTER = 'project-comp-cost-table-footer',
}

export enum ProjectCompPortals {
  LABEL_FOOTER_COSTS = 'portal-label-footer-costs',
  LABEL_FOOTER_CONTROLS = 'portal-label-footer-controls',
  AVG_COMP_FOOTER_COSTS = 'portal-avg-comp-footer-costs',
  AVG_COMP_FOOTER_CONTROLS = 'portal-avg-comp-footer-controls',
  COMP_FOOTER_COSTS = 'portal-comp-footer-costs',
  COMP_FOOTER_CONTROLS = 'portal-comp-footer-controls',
  LABEL_HEADER = 'portal-label-header',
  AVG_COMP_HEADER = 'portal-avg-comp-header',
  COMP_HEADER = 'portal-comp-header',
}
