import { DragIndicator } from '@material-ui/icons';

type Props = {
  children: JSX.Element;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  disabled?: boolean;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  selected?: boolean;
};

const DragTile = (props: Props) => {
  const handleColorClass = props.disabled ? 'text-button-inactive' : 'text-type-inactive';

  return (
    <div
      className={`flex cursor-grab items-center gap-1 rounded p-2 pl-0 type-label hover:bg-selection-hover ${
        props.selected ? 'bg-selection-selected' : 'bg-background-1'
      }`}
    >
      <div className={handleColorClass}>
        <DragIndicator />
      </div>
      {props.children}
    </div>
  );
};

export default DragTile;
