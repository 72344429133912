import { FC } from 'react';

import useListCategoryCombinationsQuery from '../../../../../hooks/useCategoryCombinationsQueries';
import { withStyles } from '../../../../../theme/komodo-mui-theme';
import { isNonNullable } from '../../../../../utilities/types';
import { GridController } from '../../../../JoinGrid/types';
import { formatCategorizationInput } from '../MilestoneDetailsQuantitiesUtils';

import MilestoneDetailsQuantitiesGridHeader from './MilestoneDetailsQuantitiesGridHeader';
import MilestoneDetailsQuantitiesGridStyles from './MilestoneDetailsQuantitiesGridHeaderStyles';

type MilestoneDetailsQuantitiesGridHeaderProps = {
  grid: GridController;
};

const MilestoneDetailsQuantitiesGridHeaderData: FC<MilestoneDetailsQuantitiesGridHeaderProps> = ({
  grid,
}) => {
  const {
    data: { columns },
    estimateID,
  } = grid;

  const categorizationIDs = columns
    .filter((column) => !!column.categorization && !!column.categorization.id)
    .map((column) => column.categorization && column.categorization.id)
    .filter(isNonNullable);

  const categorizationSearches = categorizationIDs.map((id) => formatCategorizationInput(id));

  const { data: { categoryCombinations } = { categoryCombinations: [] }, loading } =
    useListCategoryCombinationsQuery([...categorizationSearches], estimateID);

  if (loading) return null;

  return (
    <MilestoneDetailsQuantitiesGridHeader
      canEditMetrics
      categorizationIDs={categorizationIDs}
      categoryCombinations={categoryCombinations}
      grid={grid}
    />
  );
};

export default withStyles(MilestoneDetailsQuantitiesGridStyles)(
  MilestoneDetailsQuantitiesGridHeaderData
);
