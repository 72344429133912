import {
  GetProjectsUsingOrgNodeQuery,
  GetProjectsUsingOrgNodeQueryVariables,
} from '../../../../generated/graphql';
import { useQuery } from '../../../../hooks/useMountAwareQuery';
import { MountPolicy } from '../../../../hooks/usePolicyOnFirstMount';
import { getProjectsUsingOrgNodeQuery } from '../../../Organizations/hooks/queries';

export const useGetProjectsUsingOrgNodeQuery = (orgNodeID?: UUID | null) =>
  useQuery<GetProjectsUsingOrgNodeQuery, GetProjectsUsingOrgNodeQueryVariables>(
    getProjectsUsingOrgNodeQuery,
    {
      variables: { orgNodeID },
      skip: !orgNodeID,
    },
    MountPolicy.SKIP_ON_MOUNT
  );
