import { useState } from 'react';

import {
  CompanyAdminEventType,
  companyAdminAnalyticsEvent,
} from '../../../analytics/analyticsEventProperties';
import useSendAnalytics from '../../../hooks/useSendAnalytics';
import { companyTypes } from '../../login/Signup/SignupPages/CreateCompanyData';
import { useUpdateCompany } from '../CompanyHooks';
import CompanyLogo from '../CompanyLogo';
import { Select, TextInput } from '../../scales';

type Props = {
  company: Company;
};

export default function CompanyTabSettings(props: Props) {
  const [company, setCompany] = useState(props.company);
  const updateCompany = useUpdateCompany();
  const sendAnalytics = useSendAnalytics();

  return (
    <div className="flex w-100 flex-col gap-2 p-6">
      <div className="type-heading2">Company Profile</div>
      <TextInput
        data-cy="company-name-text-input"
        errorMessage={company?.name?.trim() ? undefined : 'Company Name is required.'}
        label="Company Name *"
        onBlur={() => {
          if (!company.name.trim()) return;

          sendAnalytics(companyAdminAnalyticsEvent(CompanyAdminEventType.SETTINGS_NAME_CHANGE));
          updateCompany({ companyID: props.company.id, name: company.name });
        }}
        onChange={(value) => setCompany({ ...company, name: value })}
        value={company.name}
      />
      <Select
        entries={companyTypes}
        label="Company Type *"
        onChange={(type) => {
          setCompany({ ...company, type });
          updateCompany({ companyID: props.company.id, type });
          sendAnalytics(companyAdminAnalyticsEvent(CompanyAdminEventType.SETTINGS_TYPE_CHANGE));
        }}
        value={company.type}
      />
      <CompanyLogo
        company={company}
        onChange={(logoAssetID) => {
          setCompany({ ...company, assetID: logoAssetID });
          updateCompany({ companyID: props.company.id, assetID: logoAssetID });
          sendAnalytics(companyAdminAnalyticsEvent(CompanyAdminEventType.SETTINGS_LOGO_CHANGE));
        }}
      />
    </div>
  );
}
