import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../../theme/komodo-mui-theme';

export default (theme: KomodoTheme) =>
  createStyles({
    clearEditingFields: {
      fontFamily: theme.typography.fontFamily,
      padding: '8px 8px 4px 8px',
      color: theme.palette.link,
      cursor: 'pointer',
      textDecoration: 'underline',
    },
    filterCount: {
      fontFamily: theme.typography.fontFamily,
      padding: '8px 8px 4px 8px',
      alignSelf: 'baseline',
    },
    header: {
      background: theme.palette.backgroundGrey,
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
    },
  });
