import * as d3 from 'd3';

import theme from '../../../../theme/komodo-mui-theme';
import { parseMonthYear } from '../../../../utilities/dates';
import { ChartSize } from '../ChartsD3GraphWrapper';

export type LineGraphData = {
  project: ProjectInfo;
  dateString: string;
  x: number;
  y: number;
};

export type LineDatum = {
  x: number;
  y: number;
};

export type d3Selection = d3.Selection<d3.BaseType, undefined, null, null>;

const NUM_MONTHS_DISPLAYED = 6;

export const formatMonthYearString = (
  data: LineGraphData[],
  month: number,
  i: number,
  currentMonth: number,
  currentYear: number
) => {
  // find the corresponding project name for this project id
  const dataPoint = data.find((d: LineGraphData) => d.x === month);
  let [monthString, yearString] = ['', ''];
  if (dataPoint) {
    [monthString, yearString] = parseMonthYear(dataPoint.dateString);
  } else {
    // get tick month from index and current month timestamp
    const tickMonthNum = i + 1 + currentMonth - NUM_MONTHS_DISPLAYED;

    // adjust for year wraparound
    let tickMonthNumAdjusted = tickMonthNum;
    let sameYear = true;
    if (tickMonthNum <= 0) {
      sameYear = false;
      tickMonthNumAdjusted = tickMonthNum + 12;
    }
    const tickYearNum = sameYear ? currentYear : currentYear - 1;
    [monthString, yearString] = [
      tickMonthNumAdjusted < 10
        ? `0${tickMonthNumAdjusted.toString()}`
        : tickMonthNumAdjusted.toString(),
      tickYearNum.toString(),
    ];
  }

  return `${monthString}/${yearString}`;
};

const getMaxYValue = (data: LineGraphData[]) => {
  let max = 0;
  data.forEach((elem) => {
    if (elem.y > max) max = elem.y;
  });
  return max;
};

export const generateAxis = (data: LineGraphData[], chartSize: ChartSize) => {
  const { width, height, padding, xAxisLayout, yAxisLayout } = chartSize;

  const currentMonth = new Date().getMonth() + 1; // months are zero-indexed
  const currentYear = new Date().getFullYear(); // years are not zero-indexed

  const scaleX = d3
    .scaleLinear()
    .domain([currentMonth - NUM_MONTHS_DISPLAYED + 1, currentMonth])
    .range([
      padding.left + yAxisLayout.padding,
      width - padding.left - padding.right - yAxisLayout.padding,
    ]);

  const scaleY = d3
    .scaleLinear()
    .domain([0, getMaxYValue(data)])
    .rangeRound([height - padding.bottom - padding.top - xAxisLayout.padding, 23]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  const xAxis = (g: any, _: number, format: string) =>
    g
      .attr(
        'transform',
        `translate(${padding.left + yAxisLayout.padding},${
          height - padding.bottom - padding.top + xAxisLayout.padding
        })`
      )
      .call(
        d3
          .axisBottom(scaleX)
          .ticks(NUM_MONTHS_DISPLAYED, format)
          .tickSize(0)
          // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
          .tickFormat((month: any, i: number) =>
            formatMonthYearString(data, month, i, currentMonth, currentYear)
          )
      )
      .call((h: d3Selection) =>
        h
          .selectAll('.tick line')
          .clone()
          .attr('stroke', theme.palette.chartMediumGrey)
          .attr('y2', -(height - padding.top - padding.bottom - xAxisLayout.padding + 4))
      )
      .call((h: d3Selection) => h.selectAll('.domain').remove());

  const yAxisTicks = scaleY.ticks(5).filter((tick) => Number.isInteger(tick));

  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  const yAxis = (g: any) => {
    g.attr('transform', `translate(${padding.left + yAxisLayout.padding},0)`)
      .attr('text-anchor', 'start')
      .call(d3.axisLeft(scaleY).tickValues(yAxisTicks).tickFormat(d3.format('d')).tickSize(0))
      .call((h: d3Selection) => h.selectAll('.domain').remove());
  };

  return { scaleX, xAxis, scaleY, yAxis };
};

export const generateChartAxis = (
  svg: d3.Selection<SVGSVGElement, unknown, null, undefined>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  xAxis: (g: any, _: number, format: string) => void,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  yAxis: (g: any) => void
) => {
  svg
    .append('g')
    .attr('class', 'x-axis')
    .call(xAxis)
    .selectAll('text')
    .style('font-size', 12)
    .style('font-weight', 400)
    .style('transform', 'translate(0px, 4px)');
  svg
    .append('g')
    .attr('class', 'y-axis')
    .call(yAxis)
    .style('font-size', 12)
    .style('font-weight', 400);
};

export const generateChartLines = (
  svg: d3.Selection<SVGSVGElement, unknown, null, undefined>,
  idLineDataMap: Map<UUID, LineDatum[]>,
  scaleX: d3.ScaleLinear<number, number, never>,
  scaleY: d3.ScaleLinear<number, number, never>,
  colors: string[],
  chartSize: ChartSize,
  formatValue: (value: number) => string,
  pointerEntered: (projectID: UUID) => void,
  pointerLeave: () => void,
  selected?: UUID
) => {
  const { padding, yAxisLayout } = chartSize;
  const lineIDs = Array.from(idLineDataMap.keys());

  lineIDs.forEach((id, i) => {
    const isSelected = selected === id;
    const isOpaque = !selected || isSelected;
    const opacity = isOpaque ? 0.8 : 0.2;
    const strokeWidth = isSelected ? 4 : 2;
    const lineColor = colors[i];

    const lineData = idLineDataMap.get(id);
    if (!lineData) {
      return;
    }

    svg
      .append('path')
      .datum(lineData)
      .attr('transform', `translate(${padding.left + yAxisLayout.padding},0)`)
      .attr('fill', 'none')
      .attr('stroke', lineColor)
      .attr('stroke-width', strokeWidth)
      .attr(
        'd',
        d3
          .line<LineDatum>()
          .x((d) => Number(scaleX(d.x)))
          .y((d) => Number(scaleY(d.y)))
      )
      .attr('stroke-opacity', opacity)
      .on('pointerenter', () => {
        pointerEntered(id);
      })
      .on('pointerleave', () => {
        pointerLeave();
      });
    const yOffset = -10;
    const xOffset = 16;

    if (isSelected) {
      const rect = svg.append('g').selectAll('g').data(lineData).join('g');
      const padding = 5;
      const digitWidth = 10;
      const rectWidth = (d: LineDatum) => padding + digitWidth * String(d.y).length;
      rect
        .append('rect')
        .attr('x', (d: LineDatum) => Number(scaleX(d.x) + xOffset - 0.5 * rectWidth(d)))
        .attr('y', (d: LineDatum) => Number(scaleY(d.y) + -22))
        .attr('width', (d: LineDatum) => rectWidth(d))
        .attr('height', 16)
        .attr('fill', lineColor)
        .attr('rx', 8);

      svg
        .selectAll('.text')
        .data(lineData)
        .enter()
        .append('text')
        .attr('class', 'text')
        .attr('text-anchor', 'middle')
        .attr('font-size', '0.75rem')
        .attr('fill', 'white')
        .attr('opacity', 1)
        .attr('x', (d: LineDatum) => Number(scaleX(d.x) + xOffset))
        .attr('y', (d: LineDatum) => Number(scaleY(d.y) + yOffset))
        .text((d: LineDatum) => formatValue(d.y));

      const xCircleOffset = 16;

      const circleNode = svg.append('g').selectAll('g').data(lineData).join('g');

      circleNode
        .append('circle')
        .attr('fill', lineColor)
        .attr('cx', (d: LineDatum) => Number(scaleX(d.x) + xCircleOffset))
        .attr('cy', (d: LineDatum) => Number(scaleY(d.y)))
        .attr('r', 4);
    }
  });
};
