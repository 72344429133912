import { createStyles } from '@material-ui/core';

import { KomodoTheme, singleLine } from '../../../theme/komodo-mui-theme';

const styles = (theme: KomodoTheme) =>
  createStyles({
    button: {
      border: theme.border.invisible,
      borderRight: theme.border.line,
      flexGrow: 0,
      lineHeight: 'unset !important',
      padding: '6px 12px',
      '&:hover': {
        backgroundColor: theme.palette.backgroundGrey,
      },
      '&:focus': {
        outline: theme.border.invisible,
      },
      '&:focus-visible': {
        background: theme.palette.transparent,
        border: theme.border.focus,
        outline: theme.border.focus,
      },
      '&:first-of-type': {
        '&:hover': {
          outline: singleLine(theme.palette.backgroundGrey),
        },
        '&:focus': {
          outline: theme.border.invisible,
        },
        '&:focus-visible': {
          background: theme.palette.transparent,
          border: theme.border.focus,
          outline: theme.border.focus,
        },
        borderRight: theme.border.invisible,
        borderBottomLeftRadius: '3px',
        borderBottomRightRadius: '0px',
        borderTopLeftRadius: '3px',
        borderTopRightRadius: '0px',
        marginRight: 1,
      },
      '&:last-child': {
        '&:hover': {
          outline: singleLine(theme.palette.backgroundGrey),
        },
        '&:focus': {
          outline: theme.border.invisible,
        },
        '&:focus-visible': {
          background: theme.palette.transparent,
          border: theme.border.focus,
          outline: theme.border.focus,
        },
        borderRight: theme.border.invisible,
        borderBottomLeftRadius: '0px',
        borderBottomRightRadius: '3px',
        borderTopLeftRadius: '0px',
        borderTopRightRadius: '3px',
      },
    },
    buttonCompact: {
      padding: '5px 12px 5px 12px',
      whiteSpace: 'nowrap',
    },
    buttonWide: {
      flexGrow: 1,
      width: '50%',
    },
    selected: {
      background: `${theme.palette.selectedBackground} !important`,
    },
    toggle: {
      borderRadius: theme.border.radius,
      display: 'inline-flex',
      border: theme.border.line,
      height: 40, // to match the Join Select
      backgroundColor: theme.palette.backgroundWhite,
    },
    toggleCompact: {
      height: 34, // to match the Join Select compact
    },
    toggleMarginless: {
      margin: '0px 4px 0 0',
    },
    toggleWide: {
      width: '100%',
      justifyContent: 'space-between',
    },
  });

export default styles;
