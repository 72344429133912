import { ReactNode } from 'react';

import { LinearProgress } from '@material-ui/core';

import { NULL_ID } from '../../constants';
import {
  CompanyUserQuery,
  CompanyUserQueryVariables,
  CurrentCollaboratorQuery,
  CurrentCollaboratorQueryVariables,
  FeatureFlagQuery,
  FeatureFlagQueryVariables,
} from '../../generated/graphql';
import useModuleEntitlementsQuery from '../../hooks/useModuleEntitlementsQuery';
import { useQuery } from '../../hooks/useMountAwareQuery';
import { MountPolicy } from '../../hooks/usePolicyOnFirstMount';
import { useProjectID } from '../../utilities/routes/params';

import { CurrentCollaboratorContextProvider } from './current-collaborator';
import { CurrentCompanyUserContextProvider } from './current-company-user';
import { useCurrentUser } from './current-user';
import { FeatureFlagContextProvider } from './feature-flags';
import { ModuleEntitlementsProvider } from './module-entitlements';
import { currentCollaboratorQuery, currentCompanyUserQuery, featureFlagQuery } from './queries';

/**
 * We render our various app contexts via this component. The intention here is
 * to parallelize any data fetches as much as possible and make dependencies
 * between contexts obvious. For example, we need to have the current user
 * fetched before we can fetch the feature flags since they can be set per-user.
 *
 * This approach avoids a waterfall rendering pitfall whereby each context would
 * block rendering for child contexts while it waits for data to be fetched.
 *  */
export default function AppContexts(props: { children: ReactNode }) {
  const currentUser = useCurrentUser();
  const featureFlags = useFetchFeatureFlags(currentUser?.email);

  const currentCompanyUser = useFetchCurrentCompanyUser();
  const moduleEntitlements = useFetchModuleEntitlements();

  const currentCollaborator = useFetchCurrentCollaborator(); // will remain undefined if we're not in a project

  if (!featureFlags || !currentCompanyUser || !moduleEntitlements) {
    return <LinearProgress />;
  }

  return (
    <CurrentCollaboratorContextProvider value={currentCollaborator}>
      <CurrentCompanyUserContextProvider value={currentCompanyUser}>
        <ModuleEntitlementsProvider value={moduleEntitlements}>
          <FeatureFlagContextProvider value={featureFlags}>
            {props.children}
          </FeatureFlagContextProvider>
        </ModuleEntitlementsProvider>
      </CurrentCompanyUserContextProvider>
    </CurrentCollaboratorContextProvider>
  );
}

// At some point it may start getting silly having all the `useFetch...` hooks
// in this file, but I intentionally put them here rather than in a separate
// file to minimize the confusion about whether to autoimport something called
// useCurrentUser vs useFetchCurrentUser. I could see it being easy to
// mistakenly import the one that kicks off a new query rather than using the
// context value.

function useFetchCurrentCompanyUser() {
  const result = useQuery<CompanyUserQuery, CompanyUserQueryVariables>(currentCompanyUserQuery);
  return result.data?.companyUser ?? result.previousData?.companyUser ?? undefined;
}

function useFetchCurrentCollaborator() {
  const projectID = useProjectID();
  const result = useQuery<CurrentCollaboratorQuery, CurrentCollaboratorQueryVariables>(
    currentCollaboratorQuery,
    {
      variables: { projectID },
      skip: !projectID,
    }
  );

  return result.data?.currentCollaborator ?? result.previousData?.currentCollaborator ?? undefined;
}

function useFetchFeatureFlags(userEmail?: string) {
  const projectID = useProjectID() || NULL_ID;

  const result = useQuery<FeatureFlagQuery, FeatureFlagQueryVariables>(
    featureFlagQuery,
    {
      variables: { projectID, userEmail },
      skip: !userEmail,
    },
    MountPolicy.FETCH_ON_VARS
  );

  return result.data?.loadFeatureFlags ?? result.previousData?.loadFeatureFlags;
}

function useFetchModuleEntitlements() {
  const result = useModuleEntitlementsQuery();
  return result.data?.loadModuleEntitlementFlags ?? result.previousData?.loadModuleEntitlementFlags;
}
