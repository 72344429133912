import { Permission, PermissionLevel } from '../../../../generated/graphql';

import MarkupModePermissionTableEdit from './MarkupModePermissionsTableEdit';
import PermissionsHideCostsEdit from './PermissionsHideCostsEdit';

type Props = {
  canViewCosts: boolean;
  displayCostPermission: Permission;
  markupModePermissions: Permission[];
  onChange: (id: UUID, level: PermissionLevel, permission?: string) => void;
};

const CostParamsTableEdit = (props: Props) => (
  <div>
    <div className="flex w-1/2">
      <div className="w-1/3 py-2 type-button">Feature</div>
      <div className="w-2/3 px-2 py-2 type-button">Description</div>
    </div>
    <PermissionsHideCostsEdit
      canViewCosts={props.canViewCosts}
      displayCostPermission={props.displayCostPermission}
      onChange={props.onChange}
    />
    <MarkupModePermissionTableEdit
      canViewCosts={props.canViewCosts}
      onChange={props.onChange}
      permissions={props.markupModePermissions}
    />
  </div>
);

export default CostParamsTableEdit;
