import { useCallback, useState } from 'react';

import { copyItemEventTypes } from '../../../analytics/analyticsEventProperties';
import { ItemCopyState } from '../hooks/useCopyItemMutationParams';

import CategorizationMappingsDialog from './CategorizationMappingsDialog/CategorizationMappingsDialog';
import GeneralInfoDialog from './GeneralInfoDialog/GeneralInfoDialog';
import ItemAttachmentsDialog from './ItemAttachmentsDialog/ItemAttachmentsDialog';
import ItemCategorizationsDialog from './ItemCategorizationsDialog/ItemCategorizationsDialog';
import ProjectSelectionDialog from './ProjectSelectionDialog/ProjectSelectionDialog';

type CopyItemDialogProps = {
  onClose: () => void;
  onComplete: () => void;
  onSelectProject: (projectID?: UUID) => void;
  onSendAnalytics: (eventType: copyItemEventTypes) => void;
  onUpdateState: (input: Partial<ItemCopyState>) => void;
  state: ItemCopyState;
};

const CopyItemDialog = ({
  onClose: onCloseOuter,
  onComplete: onCompleteOuter,
  onSelectProject,
  onSendAnalytics,
  onUpdateState,
  state,
}: CopyItemDialogProps) => {
  const TOTAL_STEPS = 5; // for now this is constant, but will be affected by source estimate/attachments
  const [index, setIndex] = useState(0);
  const progressPercent = (index + 1) / TOTAL_STEPS;

  const isProjectSelection = index === 0;
  const isGeneralInfo = index === 1;
  const isItemCategories = index === 2;
  const isCategorizationMappings = index === 3;
  const isItemAssets = index === 4;

  const onClose = useCallback(() => {
    onCloseOuter();
    onSendAnalytics(copyItemEventTypes.CLOSE_DIALOG_CTA);
  }, [onCloseOuter, onSendAnalytics]);

  const onComplete = useCallback(() => {
    onCompleteOuter();
    onSendAnalytics(copyItemEventTypes.COMPLETE_DIALOG_CTA);
  }, [onCompleteOuter, onSendAnalytics]);

  const onBack = useCallback(() => {
    if (index <= 0) onClose();
    else
      setIndex((prev) => {
        if (prev === 4 && state.skipCategorizationMap) {
          if (state.skipCategories) return prev - 3;
          return prev - 2;
        }
        if (prev === 3 && state.skipCategories) return prev - 2;
        return prev - 1;
      });
  }, [index, onClose, state.skipCategories, state.skipCategorizationMap]);

  const onNext = useCallback(() => {
    // analytics
    if (isProjectSelection) onSendAnalytics(copyItemEventTypes.PROJECT_SELECTION_CTA);
    else if (isGeneralInfo) onSendAnalytics(copyItemEventTypes.GENERAL_INFO_CTA);
    else if (isItemCategories) onSendAnalytics(copyItemEventTypes.ITEM_CATEGORIES_CTA);
    else if (isCategorizationMappings)
      onSendAnalytics(copyItemEventTypes.CATEGORIZATION_MAPPINGS_CTA);
    else if (isItemAssets) onSendAnalytics(copyItemEventTypes.ITEM_ASSETS_CTA);
    // contiue or complete
    if (index >= TOTAL_STEPS - 1) onComplete();
    else
      setIndex((prev) => {
        if (prev === 1 && state.skipCategories) {
          if (state.skipCategorizationMap) return prev + 3;
          return prev + 2;
        }
        if (prev === 2 && state.skipCategorizationMap) return prev + 2;
        return prev + 1;
      });
  }, [
    index,
    isCategorizationMappings,
    isGeneralInfo,
    isItemAssets,
    isItemCategories,
    isProjectSelection,
    onComplete,
    onSendAnalytics,
    state.skipCategories,
    state.skipCategorizationMap,
  ]);

  if (isProjectSelection)
    return (
      <ProjectSelectionDialog
        onBack={onClose}
        onChange={onSelectProject}
        onClose={onClose}
        onNext={onNext}
        progressPercent={progressPercent}
        selected={state?.projectID}
      />
    );
  if (isGeneralInfo)
    return (
      <GeneralInfoDialog
        onBack={onBack}
        onChange={onUpdateState}
        onClose={onClose}
        onNext={onNext}
        progressPercent={progressPercent}
        state={state}
      />
    );
  if (isItemCategories)
    return (
      <ItemCategorizationsDialog
        onBack={onBack}
        onChange={onUpdateState}
        onClose={onClose}
        onNext={onNext}
        progressPercent={progressPercent}
        state={state}
      />
    );
  if (isCategorizationMappings)
    return (
      <CategorizationMappingsDialog
        onBack={onBack}
        onChange={onUpdateState}
        onClose={onClose}
        onNext={onNext}
        progressPercent={progressPercent}
        state={state}
      />
    );
  return (
    <ItemAttachmentsDialog
      onBack={onBack}
      onChange={onUpdateState}
      onClose={onClose}
      onNext={onNext}
      progressPercent={progressPercent}
      state={state}
    />
  );
};

export default CopyItemDialog;
