import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';

export default (theme: KomodoTheme) =>
  createStyles({
    closeButton: {
      color: theme.palette.primaryGrey,
      fontSize: '17px',
      verticalAlign: 'middle',
    },
    container: {
      alignItems: 'center',
      backgroundColor: theme.palette.pending,
      borderRadius: 32,
      color: theme.palette.primaryGrey,
      display: 'flex !important',
      padding: '8px 12px',
    },
    errorStatusContainer: {
      backgroundColor: theme.palette.red,
      color: theme.palette.backgroundWhite,
    },
    loadingContainer: {
      backgroundColor: 'transparent',
      color: theme.palette.blue,
      marginLeft: 8,
    },
    statusIcon: {
      alignSelf: 'center',
      fontSize: '15px',
    },
    text: {
      padding: '2px 4px 0px 4px',
      fontWeight: 500,
    },
    textButton: {
      backgroundColor: theme.palette.pending,
      marginLeft: 8,
    },
    errorText: {
      color: theme.palette.backgroundWhite,
    },
  });
