import { reportDistributionExperiment } from '../../../analytics/analyticsEventProperties';
import useSendAnalytics from '../../../hooks/useSendAnalytics';
import { Button, Dialog, DialogContent } from '../../scales';

type Props = {
  isOpen: boolean;
  onPrint: () => void;
  onClose: () => void;
  setShowReportDistributionDialog: (value: boolean) => void;
  isViewingSavedReport: boolean;
  setReportModalOpenOverride: (value: boolean) => void;
};

export default function ReportDistributionPrompt(props: Props) {
  const sendAnalytics = useSendAnalytics();

  return (
    <Dialog
      footerRight={
        <div className="flex gap-4">
          <Button label="Continue to Print" onClick={props.onPrint} type="secondary" />
          <Button
            label={props.isViewingSavedReport ? 'Schedule a Report Distribution' : 'Save'}
            onClick={() => {
              if (props.isViewingSavedReport) {
                props.setShowReportDistributionDialog(true);
                sendAnalytics(
                  reportDistributionExperiment({
                    action:
                      'Opened Report Distribution Dialog from Report Distribution Prompt during print flow',
                  })
                );
              } else {
                props.setReportModalOpenOverride(true);
                sendAnalytics(
                  reportDistributionExperiment({
                    action: 'Saved a report from Report Distribution Prompt during print flow',
                  })
                );
              }
              props.onClose();
            }}
            type="primary"
          />
        </div>
      }
      isOpen={props.isOpen}
      onClose={props.onClose}
      title="Schedule a Report Distribution?"
    >
      <DialogContent>
        <div>
          {`You are about to view the Print Preview for this report. Do you also want to send this report directly to you or a collaborator's inbox? ${
            props.isViewingSavedReport ? '' : 'Save the Report first, then'
          } Schedule a report Distribution`}
        </div>
      </DialogContent>
    </Dialog>
  );
}
