import { useReactiveVar } from '@apollo/client';

import {
  Selected,
  itemSidebarOpenVar,
  selectedAssetsVar,
} from '../../../../api/apollo/reactiveVars';
import { getItemIdFromUrl, getMilestoneIdFromUrl } from '../../../../utilities/url';
import {
  useItemAttachments,
  useMilestoneAttachments,
} from '../../../assets/AssetsWrapper/AssetsWrapperUtils';

export const getEntityID = (itemSidebarID?: UUID | null) => {
  const itemID = getItemIdFromUrl();
  const milestoneID = getMilestoneIdFromUrl();
  return { itemID: itemID || itemSidebarID || '', milestoneID };
};

export const useEntityID = () => {
  const displayItemID = useReactiveVar(itemSidebarOpenVar);
  return getEntityID(displayItemID);
};

export const returnAssets = (
  itemAssets: EntityAttachment[] = [],
  milestoneAssets: EntityAttachment[] = []
) => {
  if (itemAssets.length > 0) {
    return itemAssets;
  }
  if (milestoneAssets.length > 0) {
    return milestoneAssets;
  }
  return [];
};

export const useEntityAttachments = () => {
  const { itemID, milestoneID } = useEntityID();
  const { itemAssets } = useItemAttachments(itemID);
  const { milestoneAssets } = useMilestoneAttachments(milestoneID);
  return returnAssets(itemAssets, milestoneAssets);
};

export const updateSelectedMap = (selectedMapUpdate: Selected) => {
  const selectedAssets = selectedAssetsVar();
  selectedAssetsVar({
    ...selectedAssets,
    selectedMap: { ...selectedAssets.selectedMap, ...selectedMapUpdate },
  });
};

export const getAssetIDsforDisabling = (assets: EntityAttachment[]) =>
  assets.map((asset) => ('sourceAssetID' in asset ? asset.sourceAssetID : asset.id));
