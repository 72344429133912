import { FC, ReactNode } from 'react';

import { Slide } from '@material-ui/core';
import { SlideProps } from '@material-ui/core/Slide';

type JoinSlideProps = SlideProps & {
  children: ReactNode;
};

const JoinSlide: FC<JoinSlideProps> = ({ children, direction, timeout = 200 }) => (
  // @ts-ignore 'children' should exist in SlideProps: https://v1.mui.com/api/slide/
  <Slide direction={direction} in timeout={timeout}>
    {children}
  </Slide>
);

export default JoinSlide;
