import { ReactElement } from 'react';

export const renderTextValue = (
  all: boolean,
  allText: string,
  none: boolean,
  noneText: string,
  render: (text: string) => () => ReactElement
) => {
  if (all) return render(allText);
  if (none) return render(noneText);
  return undefined;
};
