import { Status, Visibility } from '../../../../generated/graphql';
import AssigneeSelectData from '../../../AssigneeSelect/AssigneeSelectData';
import { ItemStatusSelect } from '../../../dragon-scales';
import { isPrivateVisibility } from '../../../Items/ItemsUtils';
import ItemVisibilityRadio from '../../../Items/ItemVisibilityToggle/ItemVisibilityRadio';
import { Button, Dialog, DialogContent, TextInput } from '../../../scales';
import DateInput from '../../../shared-widgets/DateInput';
import { ItemCopyState } from '../../hooks/useCopyItemMutationParams';
import {
  NEXT_ESTIMATE_CATEGORIZATIONS,
  NEXT_ITEM_ATTACHMENTS,
  NEXT_ITEM_CATEGORIZATIONS,
} from '../CopyItemDialogUtils';

import DescriptionInput from './inputs/DescriptionInput';
import EventsSelect from './inputs/EventsSelect';
import MilestoneSelect from './inputs/MilestoneSelect';

type GeneralInfoDialogProps = {
  state: ItemCopyState;
  onBack: () => void;
  onChange: (input: Partial<ItemCopyState>) => void;
  onClose: () => void;
  onNext: () => void;
  progressPercent?: number;
};

const GeneralInfoDialog = (props: GeneralInfoDialogProps) => {
  const nameInput = (
    <TextInput
      label="Item Name"
      onChange={(name) => props.onChange({ name })}
      value={props.state.name}
    />
  );
  const descriptionInput = (
    <DescriptionInput
      descriptionStyled={props.state.descriptionStyled}
      onChange={(description: string, descriptionStyled: string) =>
        props.onChange({ description, descriptionStyled })
      }
    />
  );
  const itemVisibilityInput = (
    <ItemVisibilityRadio
      isDisabled={false}
      onChange={() => {}}
      setVisibilitySetting={(visibility: Visibility) => {
        props.onChange({ visibility });
        if (isPrivateVisibility(visibility)) {
          props.onChange({ status: Status.PENDING });
          props.onChange({ assigneeEmail: 'Unassigned' });
        }
      }}
      visibilitySetting={props.state.visibility}
    />
  );
  const statusInput = (
    <ItemStatusSelect
      availableStatuses={[
        Status.NOTAPPLICABLE,
        Status.REJECTED,
        Status.PENDING,
        Status.ACCEPTED,
        Status.INCORPORATED,
      ]}
      isDisabled={isPrivateVisibility(props.state.visibility)}
      label="Status"
      onChange={(status) => props.onChange({ status })}
      value={props.state.status}
    />
  );
  const assigneeInput = (
    <AssigneeSelectData
      includeUnassigned
      isDisabled={isPrivateVisibility(props.state.visibility)}
      label="Assignee"
      onChange={(assigneeEmail?: string | null) => props.onChange({ assigneeEmail })}
      projectID={props.state.projectID ?? ''}
      value={props.state.assigneeEmail ?? undefined}
    />
  );
  const dueDateInput = (
    <DateInput
      date={props.state.dueDate}
      label="Due Date"
      onChange={(dueDate) => props.onChange({ dueDate })}
    />
  );
  const milestoneInput = (
    <MilestoneSelect
      milestoneID={props.state.milestoneID ?? undefined}
      onChange={(milestoneID?: UUID) => props.onChange({ milestoneID })}
      projectID={props.state.projectID}
    />
  );
  const eventsInput = (
    <EventsSelect
      onChange={(activityIDs: UUID[]) => props.onChange({ activityIDs })}
      projectID={props.state.projectID ?? ''}
      selected={props.state.activityIDs}
    />
  );

  let nextButtonLabel = NEXT_ITEM_CATEGORIZATIONS;
  if (props.state.skipCategories) nextButtonLabel = NEXT_ESTIMATE_CATEGORIZATIONS;
  if (props.state.skipCategories && props.state.skipCategorizationMap)
    nextButtonLabel = NEXT_ITEM_ATTACHMENTS;

  return (
    <Dialog
      footerLeft={<Button label="Back" onClick={props.onBack} type="secondary" />}
      footerRight={
        <div className="flex flex-row gap-3">
          <Button
            isDisabled={!props.state.name}
            label={nextButtonLabel}
            onClick={props.onNext}
            type="primary"
          />
        </div>
      }
      isFullHeight
      isOpen
      onClose={props.onClose}
      progressPercent={props.progressPercent}
      size="lg"
      title="Edit General Info"
    >
      <DialogContent>
        <div className="grid grid-cols-3 gap-x-6 gap-y-4">
          <div className="col-span-2 flex flex-col gap-4">{nameInput}</div>
          <div className="col-span-1 flex flex-col gap-4">{statusInput}</div>
          <div className="col-span-2 flex flex-col gap-4">
            {descriptionInput}
            {itemVisibilityInput}
          </div>
          <div className="col-span-1 flex flex-col gap-4">
            {assigneeInput}
            {dueDateInput}
            {milestoneInput}
            {eventsInput}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default GeneralInfoDialog;
