import { FC, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { Flag, FlagOutlined } from '@material-ui/icons';

import {
  projectCostBreakdownReportEvent,
  projectCostBreakdownReportEventTypes,
} from '../../analytics/analyticsEventProperties';
import { JoinProjectRoutes, TermKey } from '../../api/gqlEnums';
import { EstimateType } from '../../generated/graphql';
import useSendAnalytics from '../../hooks/useSendAnalytics';
import { generateSharedPath } from '../../utilities/routes/links';
import { isEnumValue } from '../../utilities/types';
import { ProjectTermStore } from '../ProjectDisplaySettings/TerminologyProvider';
import { ButtonBar, Select } from '../scales';
import ShadedHelpTip from '../shared-widgets/ShadedHelpTip/ShadedHelpTip';

type Props = {
  activeMilestoneID?: UUID;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  displayTip: boolean;
  milestoneID: UUID;
  milestones: Pick<Milestone, 'id' | 'name'>[];
  projectID: UUID;
  setMilestoneID: (id: UUID) => void;
  setSettings: (newSettings: ProjectCostBreakdownSettings) => void;
  settings: ProjectCostBreakdownSettings;
  print?: () => void;
};

const ProjectCostBreakdownReport: FC<Props> = ({
  activeMilestoneID,
  displayTip,
  milestoneID,
  milestones,
  projectID,
  setMilestoneID,
  setSettings,
  settings,
  print,
}) => {
  const navigate = useNavigate();
  const termStore = useContext(ProjectTermStore);

  const options = [
    { value: EstimateType.ACTIVE_ESTIMATE, label: termStore.titleCase(TermKey.ESTIMATE) },
    { value: EstimateType.BUDGET, label: termStore.titleCase(TermKey.TARGET) },
  ];

  const entries = milestones.map(({ id, name }) => {
    const startAdornment = (
      <div className="text-entities-milestone">
        {id === activeMilestoneID ? <Flag /> : <FlagOutlined />}
      </div>
    );

    return { id, label: name, startAdornment };
  });

  const sendAnalytics = useSendAnalytics();

  return (
    <div className="flex justify-end gap-4">
      {print
        ? displayTip && (
            <ShadedHelpTip
              tip={`To see a further breakdown of costs, switch your Markups Display to 'Separated ${termStore.titleCase(
                TermKey.MARKUP
              )}'`}
            />
          )
        : null}
      <div className="flex-column flex place-content-end items-center">
        {!!print && (
          <div className="w-72">
            <Select
              entries={entries}
              onChange={(id) => {
                setMilestoneID(id);
                sendAnalytics(
                  projectCostBreakdownReportEvent(
                    projectCostBreakdownReportEventTypes.BREAKDOWN_REPORT_CHANGE_MILESTONE
                  )
                );
                navigate(
                  generateSharedPath(JoinProjectRoutes.PROJECT_COST_BREAKDOWN_REPORT, {
                    projectId: projectID,
                    milestoneId: id,
                  })
                );
              }}
              value={milestoneID}
            />
          </div>
        )}
        <div className="mx-4">
          <ButtonBar
            onChange={(newValue) => {
              if (isEnumValue(EstimateType, newValue)) setSettings({ estimateType: newValue });
            }}
            options={options}
            value={settings.estimateType}
          />
        </div>
      </div>
    </div>
  );
};

export default ProjectCostBreakdownReport;
