import { DeleteOutline } from '@material-ui/icons';

import { DesignPhaseType, InsightsTriggerInput } from '../../../../generated/graphql';
import { IconButton, Switch } from '../../../scales';

import { TriggerNumberInput } from './TriggerNumberInput';
import { TriggerPhaseSelect } from './TriggerPhaseSelect';
import { ModifiedChip } from './ModifiedChip';
import { Alert } from '../types';

type AlertProps = {
  alert: Alert;
  designPhaseTypes: DesignPhaseType[];
  onDelete: (id: UUID) => void;
  onToggle: (id: UUID) => void;
  onUpdate: (triggerID: UUID, trigger: InsightsTriggerInput) => void;
};

const ContingenciesAlert = (props: AlertProps) => {
  const [contingencyTrigger, phaseTrigger] = props.alert.triggers;

  return (
    <div className="flex items-center justify-between">
      <div className="flex items-center gap-2">
        <Switch
          checked={props.alert.isActive}
          data-cy="switch-gap"
          label=""
          onChange={() => props.onToggle(props.alert.id)}
        />
        <div className="type-body2">Total contingency/allowance projected below</div>
        <div className="w-20">
          <TriggerNumberInput
            aria-label="Contingency/allowance value"
            isPercent
            onUpdate={props.onUpdate}
            trigger={contingencyTrigger}
          />
        </div>
        <div className="type-body2">remaining with pending items during</div>
        <div className="w-64">
          <TriggerPhaseSelect
            designPhaseTypes={props.designPhaseTypes}
            onUpdate={props.onUpdate}
            trigger={phaseTrigger}
          />
        </div>
        <ModifiedChip alert={props.alert} />
      </div>
      <IconButton
        aria-label="delete node"
        icon={<DeleteOutline color="error" />}
        onClick={() => props.onDelete(props.alert.id)}
        type="secondary"
      />
    </div>
  );
};

export default ContingenciesAlert;
