import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';

export default (theme: KomodoTheme) =>
  createStyles({
    itemReferenceBadge: {
      fontFamily: theme.typography.fontFamily,
      fontSize: theme.typography.fontSize,
      background: theme.palette.backgroundGrey,
      fontWeight: 400,
      color: theme.palette.joinPrimary,
      borderRadius: 4,
      padding: '1px 4px',
    },
    itemReferenceTextHovered: {
      background: theme.palette.selectedBackground,
      cursor: 'pointer',
    },
  });
