import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';
import { CHART_PADDING } from '../ExecutiveDashboardUtils';

const ExecutiveDashboardMostRecentlyCreatedStyles = (theme: KomodoTheme) =>
  createStyles({
    cell: {
      padding: 4,
      maxWidth: 600,
      verticalAlign: 'top',
      width: '100%',
      '& > *': {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },
    },
    flexContainer: {
      display: 'flex',
      paddingLeft: CHART_PADDING,
      paddingRight: CHART_PADDING,
      position: 'relative',
    },
    headerText: {
      ...theme.typography.h3TableHeader,
    },
    icon: {
      fontSize: 12,
      marginRight: 3,
      verticalAlign: 'middle',
      display: 'inline-block',
    },
    iconCell: {
      verticalAlign: 'middle',
    },
    iconText: {
      display: 'inline-flex',
      alignItems: 'center',
    },
    linkText: {
      color: theme.palette.primaryBlue,
      '&:link': {
        textDecoration: 'none',
      },
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    loadingSmall: {
      padding: 12,
      color: theme.palette.backgroundGrey,
    },
    nameText: {
      fontWeight: 700,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      flex: '0 1 auto',
    },
    noPermissionProject: {
      color: theme.palette.shadedGrey,
    },
    projectText: {
      color: theme.palette.chartBlack,
      width: '130%',
      display: 'flex',
      flexFlow: 'wrap',
    },
    paddingLeft: {
      paddingLeft: 4,
    },
    paddingRight: {
      paddingRight: 5,
    },
    projectType: {
      display: 'flex',
      flex: '0 1 auto',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
    row: {
      borderBottom: `solid 1px ${theme.palette.joinGrey400}`,
    },
    rowDisabled: {
      color: theme.palette.disabledGrey,
    },
    status: {
      color: theme.palette.chartBlack,
      fontSize: '0.9rem',
      fontWeight: 700,
    },
    table: {
      borderCollapse: 'collapse',
      width: '100%',
      '& th': {
        verticalAlign: 'bottom',
        '& p': {
          ...theme.typography.h3TableHeader,
        },
      },
    },
    thumbnail: {
      width: 35,
      height: 35,
      verticalAlign: 'middle',
    },
    thumbnailPlaceholder: {
      height: 35,
      width: 35,
      backgroundColor: theme.palette.chartMediumGrey,
    },
  });

export default ExecutiveDashboardMostRecentlyCreatedStyles;
