import { GetProjectsWithTypeQuery, GetProjectsWithTypeQueryVariables } from '../generated/graphql';

import { getProjectsWithTypeQuery } from './queries';
import { useQuery } from './useMountAwareQuery';
import { MountPolicy } from './usePolicyOnFirstMount';

export const useGetProjectsWithType = (typeID?: UUID | null) =>
  useQuery<GetProjectsWithTypeQuery, GetProjectsWithTypeQueryVariables>(
    getProjectsWithTypeQuery,
    {
      variables: { typeID },
      skip: !typeID,
    },
    MountPolicy.SKIP_ON_MOUNT
  );
