import { get } from 'lodash';

import { ALL_COLLABORATORS } from '../../../constants';
import {
  AccessLevel,
  ProgramCollaboratorFieldsFragment,
  SharedResource,
  SortDirection,
} from '../../../generated/graphql';

import { ProgramUsersSortKey, SortDataType, UsersTableHeaderContent } from './types';

export const usersTableGridWidth = [
  '48px',
  'minmax(160px, 3fr)',
  'minmax(140px, 2.5fr)',
  'minmax(140px, 2.5fr)',
  'minmax(120px, 1.5fr)',
];

export const membersHeaderContent = [
  {
    copy: ' ',
    headerSortKey: null,
    key: 'icon',
  },
  {
    copy: 'Name',
    headerSortKey: ProgramUsersSortKey.NAME,
    key: 'user.name',
  },
  {
    copy: 'Company',
    headerSortKey: ProgramUsersSortKey.COMPANY,
    key: 'company',
  },
  {
    copy: 'Belongs To',
    headerSortKey: ProgramUsersSortKey.BELONGS_TO,
    key: 'belongs',
  },
  {
    copy: 'Role',
    headerSortKey: ProgramUsersSortKey.ROLE,
    key: 'mostCommonRoleName',
  },
] as UsersTableHeaderContent[];

export const getSelectedIndices = (
  entries: ProgramCollaboratorFieldsFragment[],
  selectedIds: UUID[],
  disabledIds: UUID[]
) =>
  entries.map(({ user }) => selectedIds.includes(user.id || '') || disabledIds.includes(user.id));

export const getSelectableIndices = (entries: ProgramCollaboratorFieldsFragment[]) =>
  entries.map(() => true);

export const getDisabledIndices = (
  entries: ProgramCollaboratorFieldsFragment[],
  disabledIds: UUID[]
) => entries.map(({ user }) => disabledIds.includes(user.id));

export function sortCollaborators(
  sortData: SortDataType,
  collaborators: ProgramCollaboratorFieldsFragment[]
) {
  const { sortDirection, sortKey } = sortData;
  const newCollaboratorsList =
    sortKey !== ''
      ? collaborators.slice().sort((a, b) =>
          generateSortAlgorithm(
            a,
            b,
            sortDirection,
            getSortValue(sortKey),
            getSortValue(ProgramUsersSortKey.NAME) // This is particularly helpful when sorting by Belongs to or Role, because many users will have the same values
          )
        )
      : collaborators;
  return newCollaboratorsList;
}

// generates an algorithm by piecing all sort logic together
export const generateSortAlgorithm = (
  a: ProgramCollaboratorFieldsFragment,
  b: ProgramCollaboratorFieldsFragment,
  sortDirection: SortDirection,
  getSortKeyValue: (c: ProgramCollaboratorFieldsFragment) => string | number,
  getSecondarySortKeyValue?: (c: ProgramCollaboratorFieldsFragment) => string | number
) => {
  let aValue = getSortKeyValue(a);
  let bValue = getSortKeyValue(b);
  if (aValue === bValue && getSecondarySortKeyValue) {
    aValue = getSecondarySortKeyValue(a);
    bValue = getSecondarySortKeyValue(b);
  }
  return sortDirection === SortDirection.SORT_ASCENDING
    ? ascendingAlgorithm(aValue, bValue)
    : descendingAlgorithm(aValue, bValue);
};

const ascendingAlgorithm = (aValue: string | number, bValue: string | number) =>
  aValue > bValue ? 1 : -1;

const descendingAlgorithm = (aValue: string | number, bValue: string | number) =>
  aValue < bValue ? 1 : -1;

// gets the value that we want to sort by when given a key
export const getSortValue =
  (sortKey: string) =>
  (collaborator: ProgramCollaboratorFieldsFragment): string => {
    let returnValue;
    switch (sortKey) {
      case ProgramUsersSortKey.NAME:
        returnValue = collaborator.user.name.toLocaleLowerCase();
        break;
      case ProgramUsersSortKey.COMPANY:
        returnValue = collaborator.company.toLocaleLowerCase();
        break;
      case ProgramUsersSortKey.ROLE:
        returnValue = collaborator.mostCommonRoleName;
        break;
      case ProgramUsersSortKey.BELONGS_TO:
        returnValue = collaborator.roles.length;
        break;
      default:
        returnValue = get(collaborator, sortKey).toLocaleLowerCase();
        break;
    }
    return returnValue || '';
  };

export function sortToTop(
  entries: ProgramCollaboratorFieldsFragment[],
  topIds: UUID[]
): ProgramCollaboratorFieldsFragment[] {
  const top: ProgramCollaboratorFieldsFragment[] = [];
  const bottom: ProgramCollaboratorFieldsFragment[] = [];
  entries.forEach((entry) => {
    if (topIds.includes(entry.user.id)) {
      top.push(entry);
    } else {
      bottom.push(entry);
    }
  });
  return [...top, ...bottom];
}

export function filterCollaboratorsByKey<Type>(
  collaborators: Type[],
  filter: string,
  key: ProgramUsersSortKey
): Type[] {
  if (!filter || filter === ALL_COLLABORATORS) return collaborators;
  return collaborators.filter((collaborator) => get(collaborator, key) === filter);
}

export function getSharedResourceInput(
  resource: SharedResource | null | undefined,
  selectedUserIDs: UUID[]
) {
  if (!resource?.users.length) return { userIDs: [], accessLevels: [] };

  // iterate through every user that already has access to this resource and set
  // their access level based on if they are the owner of the resource or if
  // they are included in the list of selectedUserIDs
  const input = resource.users.map((u, i) => {
    let accessLevel = AccessLevel.DEFAULT;
    if (resource.accessLevels[i] === AccessLevel.OWNER) accessLevel = AccessLevel.OWNER;
    else if (!selectedUserIDs.includes(u.id)) accessLevel = AccessLevel.NONE;
    return {
      id: u.id,
      accessLevel,
    };
  });

  // Add users that do not currently have access to this resource
  selectedUserIDs.forEach((id) => {
    if (!input.find((v) => v.id === id)) input.push({ id, accessLevel: AccessLevel.DEFAULT });
  });

  return { userIDs: input.map((i) => i.id), accessLevels: input.map((i) => i.accessLevel) };
}
