import { useEffect, useState } from 'react';
import { useDebounce } from 'react-use';

import { GridController } from '../types';
import { LIMIT, ROWS_DEBOUNCE, fetchPage } from '../utilities/pagination';

export const offsetsLoadedArrFunc = (offsets: Map<number, boolean>) => Array.from(offsets.values());
export const offsetsLoadedCountFunc = (offsets: Map<number, boolean>) =>
  offsetsLoadedArrFunc(offsets).filter((l) => l).length;

export default function usePagination(endRow: number, startRow: number, grid: GridController) {
  const [rowsDebounced, setRowsDebounced] = useState({
    endRowDeb: endRow,
    startRowDeb: startRow,
  });
  useDebounce(() => setRowsDebounced({ endRowDeb: endRow, startRowDeb: startRow }), ROWS_DEBOUNCE, [
    endRow,
    startRow,
  ]);
  const { endRowDeb, startRowDeb } = rowsDebounced;

  const {
    data: { lines },
  } = grid;
  const loadedLinesCount = lines.filter((l) => l).length;

  const [offsetsLoaded, setOffsetsLoaded] = useState<Map<number, boolean>>(
    new Map<number, boolean>()
  );
  const offsetsCount = offsetsLoadedCountFunc(offsetsLoaded);

  const needUpdate = !loadedLinesCount && !!offsetsCount;
  // Initialize data
  useEffect(() => {
    const offsetsCount = Math.ceil(lines.length / LIMIT);
    const offsets = new Map<number, boolean>();
    for (let page = 0; page < offsetsCount; page += 1) {
      const offsetIndex = page * LIMIT;
      offsets.set(offsetIndex, !!lines[offsetIndex]);
    }
    setOffsetsLoaded(offsets);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO CT-566: Fix this pls :)
  }, [loadedLinesCount, needUpdate]);

  // Fetch next pages
  const onScroll = () => fetchPage(grid, startRowDeb, endRowDeb, offsetsLoaded);

  useEffect(() => {
    onScroll();
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO CT-566: Fix this pls :)
  }, [startRowDeb, endRowDeb]);

  if (lines.length && !loadedLinesCount && !offsetsCount) {
    onScroll();
  }
}
