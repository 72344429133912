import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../../../../theme/komodo-mui-theme';
import { BUTTON_SIZE } from '../AssetsPDFNavigationStyles';

export default (theme: KomodoTheme) =>
  createStyles({
    navigationButton: {
      height: BUTTON_SIZE,
      opacity: 1,
      width: BUTTON_SIZE,
    },
    pageNumber: {
      padding: '6px 0px 6px 0px',
      textAlign: 'center',
      width: BUTTON_SIZE,
    },
    pageNumberContainer: {
      marginTop: 4,
      width: 32,
    },
    pageNumberContainerEdit: {
      borderRadius: theme.border.radius,
      outline: theme.border.darkGrey,
    },
  });
