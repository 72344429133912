import {
  GetMilestoneContingenciesQuery,
  GetMilestoneContingenciesQueryVariables,
  GetProjectContingenciesQuery,
  GetProjectContingenciesQueryVariables,
} from '../../../generated/graphql';
import { useQuery } from '../../../hooks/useMountAwareQuery';

import {
  availableProjectContingencyQuery,
  itemsListFilterContingencyQuery,
} from './itemsListQuery';

export const useMilestoneContingenciesQuery = (
  projectID?: UUID,
  milestoneID?: UUID,
  skip = false
) =>
  useQuery<GetMilestoneContingenciesQuery, GetMilestoneContingenciesQueryVariables>(
    itemsListFilterContingencyQuery,
    {
      variables: { projectID, milestoneID },
      skip: skip || !projectID || !milestoneID,
    }
  );

export const useProjectContingenciesQuery = (projectID?: UUID, skip = false) =>
  useQuery<GetProjectContingenciesQuery, GetProjectContingenciesQueryVariables>(
    availableProjectContingencyQuery,
    {
      variables: { projectID },
      skip: skip || !projectID,
    }
  );
