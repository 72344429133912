import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';

const MilestoneMeetingChipStyles = (theme: KomodoTheme) =>
  createStyles({
    root: {
      color: theme.palette.primaryGrey,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    empty: {
      color: theme.palette.disabledGrey,
      fontWeight: 300,
    },
    icon: {
      padding: '6px 0 7px 0',
      height: 16,
      width: 16,
    },
    sectionHeaderText: {
      fontSize: '0.9rem',
      marginRight: `${theme.spacing.generic[7]}px`,
    },
    text: {
      fontSize: theme.typography.caption.fontSize,
      lineHeight: theme.typography.caption.lineHeight,
      padding: 0,
      paddingRight: theme.spacing.generic[3],
      paddingLeft: theme.spacing.generic[0],
    },
    title: {
      paddingLeft: 5,
      textTransform: 'none',
    },
  });

export default MilestoneMeetingChipStyles;
