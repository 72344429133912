import { Close } from '@material-ui/icons';

import Clock from '../../Icons/Clock';
import { IconButton } from '../../scales';

export default function RecentSearches(props: {
  onRemove: (value: string) => void;
  onSelect: (value: string) => void;
  searches: string[];
}) {
  if (!props.searches.length) return null;

  return (
    <div className="flex flex-col gap-2">
      <div className="type-body3">Recent searches</div>
      {props.searches.map((searchText) => (
        <div key={searchText} className="flex grow items-stretch gap-2">
          <button
            className="flex grow items-center gap-2 rounded outline-none focus-visible:bg-button-secondary-hover"
            onClick={() => props.onSelect(searchText)}
          >
            <Clock />
            <div className="text-left type-body1">{searchText}</div>
          </button>
          <IconButton
            aria-label="remove search from history"
            icon={<Close />}
            onClick={() => props.onRemove(searchText)}
            type="secondary"
          />
        </div>
      ))}
    </div>
  );
}
