import {
  GetSharedResourceQuery,
  GetSharedResourceQueryVariables,
  GetSharedResourcesQuery,
  GetSharedResourcesQueryVariables,
  ResourceType,
} from '../../../../generated/graphql';
import { QueryHookOptions, useQuery } from '../../../../hooks/useMountAwareQuery';

import { getSharedResourceQuery, getSharedResourcesQuery } from './queries';

export const useGetSharedResources = (
  resourceIDs: UUID[],
  resourceType: ResourceType,
  batch = false,
  options?: QueryHookOptions<GetSharedResourcesQuery, GetSharedResourcesQueryVariables>
) =>
  useQuery<GetSharedResourcesQuery, GetSharedResourcesQueryVariables>(getSharedResourcesQuery, {
    ...options,
    variables: { input: { resourceIDs, resourceType } },
    skip: options?.skip || resourceIDs.length === 0,
    context: { batch },
  });

export const useGetSharedResource = (
  resourceID: UUID | null | undefined,
  resourceType: ResourceType,
  options?: QueryHookOptions<GetSharedResourceQuery, GetSharedResourceQueryVariables>
) =>
  useQuery<GetSharedResourceQuery, GetSharedResourceQueryVariables>(getSharedResourceQuery, {
    ...options,
    variables: { resourceID, resourceType },
    skip: options?.skip || !resourceID,
    context: { batch: true },
  });
