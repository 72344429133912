import { FC } from 'react';

import Attachment from '../../../Icons/Attachment';

type ItemsListItemAssetsChipProps = {
  attachmentCount: number;
};

const ItemsListItemAssetsChip: FC<ItemsListItemAssetsChipProps> = ({ attachmentCount }) => (
  <div className="flex flex-row items-center">
    <div className="flex h-4 w-4">
      <Attachment />
    </div>
    <div className="type-label">{attachmentCount}</div>
  </div>
);

export default ItemsListItemAssetsChip;
