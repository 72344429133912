import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../../theme/komodo-mui-theme';

export default (theme: KomodoTheme) =>
  createStyles({
    lastUpdatedText: {
      ...theme.typography.webLabel,
      color: theme.palette.disabledGrey,
    },
  });
