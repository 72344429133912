import { useEffect } from 'react';

import { PermissionResource } from '../../../generated/graphql';
import usePermissions from '../../../utilities/permissions/usePermissions';
import { useUserSourcesQuery } from '../../DocumentMagic/hooks';
import IntegrationsDataWrapper from '../../Items/ItemsCollapse/Integrations/IntegrationsDataWrapper';

import { ITEM_HISTORY_ZOOM, useHeightOnLoad } from './PrintItemDetailsUtils';

type Props = {
  item: ItemDataQueryItem;
  itemIntegrationObjectsCount: number;
  permissions: ReturnType<typeof usePermissions>;
  triggerOnComplete?: () => void;
};

function PrintItemDetailsIntegrations(props: Props) {
  const canViewItemIntegrations = props.permissions.canView(PermissionResource.ITEM_INTEGRATIONS, {
    trades: props.item?.categories || [],
  });
  const userSourcesResult = useUserSourcesQuery();
  const userSourcesData = userSourcesResult.data?.userSources;
  const userSourcesLoading = userSourcesResult.loading;

  const { onLoadComplete, ref, height } = useHeightOnLoad(
    props.triggerOnComplete,
    1 / ITEM_HISTORY_ZOOM
  );

  const { itemIntegrationObjectsCount } = props;

  useEffect(() => {
    if (itemIntegrationObjectsCount <= 0 && !userSourcesLoading && !userSourcesData?.sources.length)
      onLoadComplete();
  }, [
    itemIntegrationObjectsCount,
    userSourcesLoading,
    userSourcesData?.sources.length,
    onLoadComplete,
  ]);

  if (!canViewItemIntegrations) return null;

  return (
    <div
      ref={ref}
      className="mt-5"
      style={{
        height,
        transform: `scale(${ITEM_HISTORY_ZOOM})`,
        transformOrigin: 'top left',
      }}
    >
      {props.itemIntegrationObjectsCount > 0 && (
        <div className="float-left w-full type-heading3">Integrations</div>
      )}
      {userSourcesData && (
        <IntegrationsDataWrapper
          canCreateChangeEvent={false}
          canDeleteItemIntegration={false}
          hasItemIntegrationObjects={props.itemIntegrationObjectsCount > 0}
          isPrint
          itemInfo={props.item}
          onLoadComplete={onLoadComplete}
          userSources={userSourcesData}
        />
      )}
    </div>
  );
}
export default PrintItemDetailsIntegrations;
