import { useMutation } from '@apollo/client';

import {
  CreateOrgNodeMutation,
  CreateOrgNodeMutationVariables,
} from '../../../../generated/graphql';
import { createOrgNodeMutation } from '../../../Organizations/hooks/queries';

const useCreateOrgNodeMutation = () => {
  const [createOrgNode] = useMutation<CreateOrgNodeMutation, CreateOrgNodeMutationVariables>(
    createOrgNodeMutation
  );
  return (organizationID: UUID, parentID: UUID | null) => {
    return createOrgNode({
      variables: {
        input: {
          organizationID,
          parentID,
        },
      },
    });
  };
};

export default useCreateOrgNodeMutation;
