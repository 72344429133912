import { ComponentProps } from 'react';

import SearchBar from '../../Search/SearchBar/SearchBar';
import JoinSelect from '../../Select/JoinSelect/JoinSelect';
import CreateScenarioButton from '../common/CreateScenarioButton';

import ScenarioTile from './ScenarioTile';

type ScenarioMetadata = ComponentProps<typeof ScenarioTile>['scenario'];

export enum ScenarioSortKey {
  COST_IMPACT = 'Cost impact',
  CREATION_TIME = 'Date created',
  NAME = 'Name',
}

type Props = {
  milestoneName: string;
  scenarios: ScenarioMetadata[];
  sandboxScenarioIDs: UUID[];
  onAddScenario: (scenarioID: UUID) => void;
  onCreateScenario: ComponentProps<typeof CreateScenarioButton>['onCreateScenario'];
  onDeleteScenario: (scenarioID: UUID) => void;
  sortKey: ScenarioSortKey;
  setSortKey: (sortBy: ScenarioSortKey) => void;
  searchText: string;
  setSearchText: (searchText: string) => void;
};

const ScenarioTiles = (props: Props) => (
  <>
    <div className="flex flex-col gap-1">
      <SearchBar
        data-cy="scenario-search-bar"
        onChange={(e) => {
          props.setSearchText(e.target.value);
        }}
        onClear={() => {
          props.setSearchText('');
        }}
        placeholder=""
        searchTerm={props.searchText}
      />
      <div className="flex flex-col gap-0.5">
        <div className="type-label">Sort By</div>
        <JoinSelect
          cySelect="scenario-sort-select"
          entries={[
            { name: ScenarioSortKey.CREATION_TIME, id: ScenarioSortKey.CREATION_TIME },
            { name: ScenarioSortKey.NAME, id: ScenarioSortKey.NAME },
            { name: ScenarioSortKey.COST_IMPACT, id: ScenarioSortKey.COST_IMPACT },
          ]}
          onChange={props.setSortKey}
          value={props.sortKey}
        />
      </div>
    </div>
    <CreateScenarioButton onCreateScenario={props.onCreateScenario} />
    <div className="flex flex-col gap-2">
      {props.scenarios.map((s) => (
        <ScenarioTile
          key={s.scenarioID}
          inSandbox={props.sandboxScenarioIDs.includes(s.scenarioID)}
          milestoneName={props.milestoneName}
          onAddScenario={props.onAddScenario}
          onDeleteScenario={props.onDeleteScenario}
          scenario={s}
          searchText={props.searchText}
        />
      ))}
    </div>
  </>
);

export default ScenarioTiles;
