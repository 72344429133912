import { CSSProperties, FC, ReactNode, createRef, useEffect } from 'react';

// @ts-expect-error because library is not typed
import { Page } from 'react-pdf/dist/esm/entry.webpack5';

import { withStyles } from '../../../../../theme/komodo-mui-theme';

import AssetsPDFPageStyles from './AssetsPDFPageStyles';
import { PdfPageCanvas, getAndSetPageRatio } from './utils';

type AssetsPDFPageProps = {
  classes: Classes<typeof AssetsPDFPageStyles>;
  isFullWidth: boolean;
  maxHeight: number;
  pageIndex: number;
  setPageRatio: (ratio: number) => void;
  style: CSSProperties;
  width?: number;
  zeroState: ReactNode;
};

const AssetsPDFPage: FC<AssetsPDFPageProps> = ({
  classes,
  isFullWidth,
  maxHeight,
  pageIndex,
  setPageRatio,
  style,
  width: outerWidth,
  zeroState,
}) => {
  const pageRef = createRef<PdfPageCanvas>();
  const elHeight = isFullWidth ? undefined : maxHeight;
  const elWidth = isFullWidth ? outerWidth : undefined;
  useEffect(() => {
    setTimeout(() => getAndSetPageRatio(pageRef, setPageRatio), 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO CT-566: Fix this pls :)
  }, [pageRef]);
  return (
    <div className={classes.pdfPage} style={style}>
      <Page
        canvasRef={pageRef}
        height={elHeight}
        loading={zeroState}
        pageIndex={pageIndex}
        renderAnnotationLayer={false}
        width={elWidth}
      />
    </div>
  );
};

export default withStyles(AssetsPDFPageStyles)(AssetsPDFPage);
