import { createContext, useContext } from 'react';

const CurrentCompanyUserContext = createContext<CompanyUser | undefined>(undefined);
export const CurrentCompanyUserContextProvider = CurrentCompanyUserContext.Provider;

export function useCurrentCompanyUser(): CompanyUser | undefined {
  const currentCompanyUser = useContext(CurrentCompanyUserContext);

  return currentCompanyUser;
}
