import useAssetBlobUrlQuery from '../../assets/hooks/useAssetBlobUrlQuery';

type Props = {
  assetID: UUID;
};

export default function ActiveBanner(props: Props) {
  const { url } = useAssetBlobUrlQuery(props.assetID);
  return (
    <img
      key="Selected Print Header"
      alt="logo"
      className="h-10 bg-background-primary object-contain"
      src={url}
    />
  );
}
