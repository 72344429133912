import { memo } from 'react';

import { withStyles } from '../../../theme/komodo-mui-theme';

import styles from './RowHighlightStyles';

const RowHighlight = memo(
  withStyles(styles)(
    (props: {
      classes: Classes<typeof styles>;
      height: number | string;
      left: number;
      top: number;
      width: number | string;
    }) => (
      <div
        className={props.classes.highlight}
        data-cy="highlight"
        style={{
          height: props.height,
          left: props.left,
          top: props.top,
          width: props.width,
        }}
      />
    )
  )
);

export default RowHighlight;
