export default (
  <svg
    className="fill-entities-phase"
    height="8"
    viewBox="0 0 8 8"
    width="8"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="4" cy="4" r="4" />
  </svg>
);
