import { isEqual } from 'lodash';
import { FC, memo, useContext, useRef } from 'react';

import { useReactiveVar } from '@apollo/client';

import { isAccordionVariant, makeGridAnalytics } from '../../actions/gridAnalytics';
import { gridSortDataVar } from '../../api/apollo/reactiveVars';
import {
  REFETCH_CHANGE_ITEM,
  REFETCH_CHANGE_MILESTONE_EVENTS_LIST,
  refetchItem,
} from '../../api/refetchSets';
import { useDebounceFn } from '../../hooks';
import {
  getEnabledCategorizationsIDsForProjectFromQueryData,
  useProjectCategorizationsQuery,
} from '../../hooks/useProjectCategorizationsQuery';
import { useRefetch } from '../../hooks/useRefetch';
import { SendAnalyticsFn } from '../../hooks/useSendAnalytics';
import { getGridType } from '../../utilities/analytics';
import JoinGridWrapper from '../JoinGrid/JoinGridWrapper';
import { EstimateArmatureSubtotals, GridVariant } from '../JoinGrid/types';
import { ProjectTermStore } from '../ProjectDisplaySettings/TerminologyProvider';

import { useGridPermissions } from './utils';

type CostEstimateProps = {
  activeEstimateID?: UUID;
  canViewDetails: boolean;
  clearFilters?: () => void;
  costOfConstruction: number;
  errors?: ImportEstimateError[];
  hasOwnerCostEstimate?: boolean;
  isExpanded?: boolean;
  isItemEstimateView?: boolean;
  isOption?: boolean;
  itemID?: UUID;
  itemTrade?: Category[];
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  linesReadOnly?: boolean;
  onItemMutated?: () => void;
  projectID: UUID;
  refetchOuter?: () => void;
  sendAnalytics: SendAnalyticsFn;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  sendRefetch?: boolean;
  variant: GridVariant;
  viewFilter?: ViewFilterInput;
  subtotals?: EstimateArmatureSubtotals;
  milestoneID?: UUID;
};

export const areEqual = (prevProps: CostEstimateProps, nextProps: CostEstimateProps) => {
  if (!prevProps.activeEstimateID || !nextProps.activeEstimateID) return false;
  if (
    prevProps.activeEstimateID !== nextProps.activeEstimateID ||
    prevProps.variant !== nextProps.variant ||
    !isEqual(prevProps.viewFilter, nextProps.viewFilter) ||
    prevProps.sendRefetch !== nextProps.sendRefetch ||
    prevProps.costOfConstruction !== nextProps.costOfConstruction
  ) {
    return false;
  }
  return true;
};

const CostEstimate: FC<CostEstimateProps> = ({
  activeEstimateID,
  canViewDetails,
  clearFilters,
  costOfConstruction,
  errors,
  hasOwnerCostEstimate = false,
  isExpanded,
  isItemEstimateView,
  isOption = false,
  itemID,
  itemTrade,
  linesReadOnly,
  refetchOuter = () => {},
  onItemMutated,
  projectID,
  sendAnalytics = () => {},
  sendRefetch = false,
  variant,
  viewFilter,
  subtotals,
  milestoneID,
}) => {
  // ANALYTICS
  const analytics = makeGridAnalytics(isOption, sendAnalytics, variant);

  // DATA + VARS
  const t = useContext(ProjectTermStore);
  const sortData = useReactiveVar(gridSortDataVar);
  const { data, loading } = useProjectCategorizationsQuery(projectID, true); // needs to include all to refresh with mutation return
  const enabledCategorizationsIDs = getEnabledCategorizationsIDsForProjectFromQueryData(data);

  // REFETCHES
  const refetchSidebar = useRefetch(REFETCH_CHANGE_ITEM);
  const refetchSidebarWithNoItem = useRefetch(REFETCH_CHANGE_MILESTONE_EVENTS_LIST);
  const refetchCombined = () => {
    if (itemID) {
      refetchSidebar([refetchItem(itemID)]);
    }
    refetchSidebarWithNoItem();
    refetchOuter();
  };
  const refetchDebounced = useDebounceFn(refetchCombined, 1000);

  const permissions = useGridPermissions(variant, canViewDetails, linesReadOnly, itemID, itemTrade);

  const collapseSizeRef = useRef(null);

  const gridType = getGridType(isOption);
  const isAccordion = isAccordionVariant(variant);

  if (loading) return null;

  if (isAccordion && activeEstimateID)
    return (
      <>
        <JoinGridWrapper
          analytics={analytics}
          clearFilters={clearFilters}
          collapseSizeRef={collapseSizeRef}
          costOfConstruction={costOfConstruction}
          enabledCategorizationsIDs={enabledCategorizationsIDs}
          errors={errors}
          estimateID={activeEstimateID}
          gridType={gridType}
          hasOwnerCostEstimate={!!hasOwnerCostEstimate}
          includeDraws={linesReadOnly}
          isExpanded={isExpanded}
          isItemEstimateView={isItemEstimateView}
          itemID={itemID}
          milestoneID={milestoneID}
          permissions={permissions}
          projectID={projectID}
          refetchOuter={refetchOuter}
          sendRefetch={sendRefetch}
          sortData={sortData}
          subtotals={subtotals}
          t={t}
          updateCostReports={() => {
            onItemMutated?.();
            refetchDebounced();
          }}
          variant={variant}
          viewFilter={viewFilter}
          width={linesReadOnly ? 1000 - 64 : undefined} // We display the read on only estimate in a dialog with a width of 1000px wide with 32px margins on each side
        />
        <div ref={collapseSizeRef} />
      </>
    );

  // This is used for the item estimate template
  return (
    <div className="flex flex-col p-2">
      <div ref={collapseSizeRef}>
        {activeEstimateID && (
          <JoinGridWrapper
            analytics={analytics}
            clearFilters={clearFilters}
            collapseSizeRef={collapseSizeRef}
            costOfConstruction={costOfConstruction}
            enabledCategorizationsIDs={enabledCategorizationsIDs}
            errors={errors}
            estimateID={activeEstimateID}
            gridType={gridType}
            hasOwnerCostEstimate={false}
            includeDraws={linesReadOnly}
            itemID={itemID}
            milestoneID={milestoneID}
            permissions={permissions}
            projectID={projectID}
            refetchOuter={refetchOuter}
            sendRefetch={loading}
            sortData={sortData}
            subtotals={subtotals}
            t={t}
            updateCostReports={() => {
              refetchDebounced();
            }}
            variant={variant}
            viewFilter={viewFilter}
          />
        )}
      </div>
    </div>
  );
};

export default memo(CostEstimate, areEqual);
