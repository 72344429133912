import { useMutation } from '@apollo/client';

import {
  DeleteOrgLevelMutation,
  DeleteOrgLevelMutationVariables,
} from '../../../../generated/graphql';
import { deleteOrgLevelMutation } from '../../../Organizations/hooks/queries';

const useDeleteOrgLevelMutation = () => {
  const [deleteOrgLevel] = useMutation<DeleteOrgLevelMutation, DeleteOrgLevelMutationVariables>(
    deleteOrgLevelMutation
  );
  return (organizationID: UUID, levelNameToRemove: string) => {
    return deleteOrgLevel({
      variables: {
        input: {
          organizationID,
          name: levelNameToRemove,
        },
      },
    });
  };
};

export default useDeleteOrgLevelMutation;
