import { FC } from 'react';

import { DRAG_DROP_ASSET_MILESTONE_DETAILS } from '../../../actions/actionTypes';
import { getMilestoneAssetAnalytics } from '../../../analytics/analyticsEventProperties';
import { PermissionResource } from '../../../generated/graphql';
import usePermissions from '../../../utilities/permissions/usePermissions';
import { useMilestoneUploadAttachAssets } from '../hooks/AssetsMutationHook';
import useMilestoneAttachmentsQuery from '../hooks/useMilestoneAttachmentsQuery';
import useOptimisticMilestoneUploadAssets from '../hooks/useOptimisticMilestoneUploadAssets';
import useOptimisticProjectUploadAssets from '../hooks/useOptimisticProjectUploadAssets';
import { getNonNullEntityAttachments } from '../utils';

import AssetsWrapper from './AssetsWrapper';

type AssetsWrapperMilestoneDataProps = {
  milestoneID: UUID;
};

const AssetsWrapperMilestoneData: FC<AssetsWrapperMilestoneDataProps> = ({ milestoneID }) => {
  // Permissions
  const { canView, canAdd, canDelete } = usePermissions();
  const canViewMilestoneAttachements = canView(PermissionResource.MILESTONE_ATTACHMENTS);
  const canAddMilestoneAttachements = canAdd(PermissionResource.MILESTONE_ATTACHMENTS);
  const canDeleteMilestoneAttachements = canDelete(PermissionResource.MILESTONE_ATTACHMENTS);

  const analyticsEvent = getMilestoneAssetAnalytics(DRAG_DROP_ASSET_MILESTONE_DETAILS, milestoneID);
  const { isAttach, onAttachAsset, onDropFile, uploadingAssets } = useMilestoneUploadAttachAssets(
    milestoneID,
    analyticsEvent
  );

  const { data, loading } = useMilestoneAttachmentsQuery(milestoneID, canViewMilestoneAttachements);
  const milestoneAttachments = getNonNullEntityAttachments(data?.milestoneAttachments);
  const assets = milestoneAttachments;

  useOptimisticMilestoneUploadAssets(milestoneID, uploadingAssets, isAttach);
  useOptimisticProjectUploadAssets(uploadingAssets);

  return (
    <AssetsWrapper
      assets={assets}
      assetsLoading={loading}
      hasAssetAddPermission={canAddMilestoneAttachements}
      hasAssetDeletePermission={canDeleteMilestoneAttachements}
      onAttachAsset={onAttachAsset}
      onDropFile={onDropFile}
    />
  );
};

export default AssetsWrapperMilestoneData;
