import { FC, useEffect, useState } from 'react';

import BannerImage from './BannerImage';

type PrintPageHeaderProps = {
  assetIDs: UUID[];
  milestoneName?: string;
  onCompleted: () => void;
  projectName: string;
  reportName: string;
};

export const PrintPageHeader: FC<PrintPageHeaderProps> = ({
  assetIDs,
  milestoneName = '',
  onCompleted,
  projectName,
  reportName,
}) => {
  const assetsCount = assetIDs.length || 0;
  const [completed, setCompleted] = useState<boolean[]>(Array(assetsCount).fill(false));
  useEffect(() => {
    if (assetsCount === 0) {
      onCompleted();
      return;
    }
    const allCompleted = completed.every((v) => v === true);
    if (allCompleted) {
      onCompleted();
    }
  }, [assetsCount, completed, onCompleted]);

  const bannerComponent = (
    <>
      {assetsCount ? (
        assetIDs.map((assetID, index) => (
          <BannerImage
            key={assetID}
            assetID={assetID}
            onCompleted={() => {
              completed[index] = true;
              setCompleted([...completed]);
            }}
          />
        ))
      ) : (
        <div />
      )}
    </>
  );

  return (
    <>
      <div className="print-report-header">
        <div className="print-header-title-container">
          <div>{projectName}</div>
          <div className="print-report-header-milestone">
            {milestoneName}
            {milestoneName && ': '}
            {reportName}
          </div>
        </div>
        <div className="print-right-align print-header-organization-pictures">
          {bannerComponent}
        </div>
      </div>
      <hr className="print-main-divider" />
    </>
  );
};
