import {
  DIVISION,
  MASTERFORMAT_CATEGORIZATION_ID,
  SECTION,
  SUBDIVISION,
  SUBSECTION,
} from '../../../../constants';
import {
  getEnabledCategorizationsForProjectFromQueryData,
  useProjectCategorizationsQuery,
} from '../../../../hooks/useProjectCategorizationsQuery';
import { removeYear } from '../../../../utilities/string';
import Tag from '../../../ItemsList/Tag';
import useMemoWrapper from '../../../useMemoWrapper';

export type CategorizationEntry = {
  categorizationID: UUID;
  categorizationLevel: number;
  id: string;
  name: string;
  rightIcon?: JSX.Element;
  type?: string;
};

export const getMatchedEntry = (
  entries: CategorizationEntry[],
  categorization: {
    categorizationID?: UUID;
    categorizationLevel?: number;
  }
) => {
  const { categorizationID, categorizationLevel = 1 } = categorization;
  // return match (if applicable)
  return entries.find((entry) => {
    return (
      entry.categorizationID === categorizationID &&
      entry.categorizationLevel === categorizationLevel
    );
  });
};

const getCategorizationLevelName = (
  categorization: ProjectCategorizationMetadata['categorization'],
  level: number
): string => {
  let categorizationLevelName = `${removeYear(
    categorization.name,
    categorization.builtin
  )} Level ${level}`;
  if (categorization.id === MASTERFORMAT_CATEGORIZATION_ID) {
    categorizationLevelName = `${removeYear(categorization.name, categorization.builtin)} ${
      [DIVISION, SUBDIVISION, SECTION, SUBSECTION][level - 1]
    }`;
  } else {
    switch (level) {
      case 1:
        categorizationLevelName =
          categorization?.importMetadata?.columnLevel1 || categorizationLevelName;
        break;
      case 2:
        categorizationLevelName =
          categorization?.importMetadata?.columnLevel2 || categorizationLevelName;
        break;
      case 3:
        categorizationLevelName =
          categorization?.importMetadata?.columnLevel3 || categorizationLevelName;
        break;
      case 4:
        categorizationLevelName =
          categorization?.importMetadata?.columnLevel4 || categorizationLevelName;
        break;
      default:
        categorizationLevelName = `${removeYear(
          categorization.name,
          categorization.builtin
        )} Level ${level}`;
        break;
    }
  }

  return categorizationLevelName;
};

const getEntriesForProjectCompCategorizationInput = (
  categorizations: ProjectCategorizationMetadata['categorization'][]
) => {
  const categorizationEntries: CategorizationEntry[] = [];

  categorizations.forEach((categorization) => {
    let rightIcon: JSX.Element | undefined;
    if (categorization.builtin) {
      rightIcon = <Tag name="Built-in" />;
    } else if (categorization.createdFrom) {
      rightIcon = <Tag name="Standard" />;
    }
    if (categorization.levels <= 1) {
      categorizationEntries.push({
        categorizationID: categorization.id,
        categorizationLevel: 1,
        id: categorization.id,
        name: categorization.name,
        rightIcon,
      });
    } else {
      for (let level = 1; level <= categorization.levels; level += 1) {
        categorizationEntries.push({
          categorizationID: categorization.id,
          categorizationLevel: level,
          id: `${categorization.id}:${level}`,
          name: getCategorizationLevelName(categorization, level),
          rightIcon,
        });
      }
    }
  });
  return categorizationEntries;
};

export const useCategorizationEntries = (projectID: UUID) => {
  const { data, loading } = useProjectCategorizationsQuery(projectID, true);
  const projectCategorizations = useMemoWrapper(
    getEnabledCategorizationsForProjectFromQueryData,
    data
  );

  const entries = getEntriesForProjectCompCategorizationInput(projectCategorizations);

  return { entries, loading };
};
