import { useMutation } from '@apollo/client';

import { REFETCH_ITEM_ASSETS, refetchItemBookmarks } from '../../../../../api/refetchSets';
import {
  CreateOrUpdateBookmarkMutation,
  CreateOrUpdateBookmarkMutationVariables,
  RemoveBookmarkFromItemMutation,
  RemoveBookmarkFromItemMutationVariables,
} from '../../../../../generated/graphql';

import { createOrUpdateBookmarkMutation, removeBookmarkFromItemMutation } from './queries';

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
export const useUpsertBookmark: any = (projectID: UUID, itemID: UUID) => {
  const [updateBookmarkFunc, mutationResult] = useMutation<
    CreateOrUpdateBookmarkMutation,
    CreateOrUpdateBookmarkMutationVariables
  >(createOrUpdateBookmarkMutation);

  const submitFunc = (bookmark: Bookmark) =>
    updateBookmarkFunc({
      variables: {
        projectID,
        bookmark: { item: itemID, ...bookmark, payload: JSON.stringify(bookmark.payload) },
      },
      refetchQueries: [...REFETCH_ITEM_ASSETS, refetchItemBookmarks(itemID)],
    });

  return [submitFunc, mutationResult];
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
export const useRemoveBookmark: any = (projectID: UUID, itemID: UUID) => {
  const [removeBookmarkFunc, mutationResult] = useMutation<
    RemoveBookmarkFromItemMutation,
    RemoveBookmarkFromItemMutationVariables
  >(removeBookmarkFromItemMutation);

  const submitFunc = (bookmark: Bookmark) =>
    removeBookmarkFunc({
      variables: {
        projectID,
        item: itemID,
        bookmark: bookmark.id,
      },
      refetchQueries: [...REFETCH_ITEM_ASSETS, refetchItemBookmarks(itemID)],
    });

  return [submitFunc, mutationResult];
};
