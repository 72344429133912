export function arrayEqual<T>(a: T[], b: T[]) {
  return a.length === b.length && a.every((v, i) => v === b[i]);
}

export const compareCategories = (a?: GridCategory | null, b?: GridCategory | null) => {
  if (!a && !b) return true; // both uncategorized
  if (!a || !b) return false; // one uncategorized
  return a.id === b.id && a.error === b.error; // categories match
};

export const isCostScalar = (value: Cost): value is CostScalar => {
  return (value as CostScalar).value !== undefined;
};

export const isCostRange = (value: Cost): value is CostRange => {
  const range = value as CostRange;
  return range !== undefined && range.max !== undefined;
};

// ensure two costs are within 1 cent of each other
const areWithinXCents = (a: string | number, b: string | number, numberCents = 10) => {
  const aValue = parseInt(a.toString(), 10);
  const bValue = parseInt(b.toString(), 10);
  return Math.abs(aValue - bValue) <= numberCents;
};

// check if two numbers are within 10 cents of each other.
// we are currently just using this for the items list to compare
// group by cost to item cost.  Since those costs are calculated
// in two very different ways there might be some rounding differences
export const compareCost = (a: Cost, b: Cost): boolean => {
  if (isCostScalar(a) && isCostScalar(b)) {
    const aScalar = a as CostScalar;
    const bScalar = b as CostScalar;
    return areWithinXCents(aScalar.value, bScalar.value);
  }

  if (isCostRange(a) && isCostRange(b)) {
    const aRange = a as CostRange;
    const bRange = b as CostRange;
    return areWithinXCents(aRange.min, bRange.min) && areWithinXCents(aRange.max, bRange.max);
  }

  if (isCostScalar(a) && isCostRange(b)) {
    const aScalar = a as CostScalar;
    const bRange = b as CostRange;
    return areWithinXCents(aScalar.value, bRange.min) && areWithinXCents(aScalar.value, bRange.max);
  }

  if (isCostRange(a) && isCostScalar(b)) {
    const aRange = a as CostRange;
    const bScalar = b as CostScalar;
    return areWithinXCents(aRange.min, bScalar.value) && areWithinXCents(aRange.max, bScalar.value);
  }

  return false;
};
