import Button from './Button';
import Link from './Link';
import ListDetail from './ListDetail';

export default ListDetail;

export const ListDetailComponents = {
  Button,
  Link,
};
