import { ProjectFilterManager, ProjectFilterSetSettings } from '../ProjectsListUtils';

import TypeFilterLevelsSelect from './ProjectsListFilterSelect/TypeFilterLevelsSelect';

type Props = {
  clearAllText?: string;
  filterManager: ProjectFilterManager;
  filterType: string;
  setSettings: ProjectFilterSetSettings;
};

export default function ProjectTypeFilter({
  clearAllText,
  filterManager,
  filterType,
  setSettings,
}: Props) {
  const filter = (
    <TypeFilterLevelsSelect
      clearAllText={clearAllText}
      filterManager={filterManager}
      filterType={filterType}
      setSettings={setSettings}
    />
  );

  return filter;
}
