import { ReactNode, forwardRef, useRef } from 'react';
import { mergeProps, useButton, useFocusRing } from 'react-aria';

import composeRefs from '@seznam/compose-react-refs';

import Badge from '../Badge/Badge';
import { TooltipTriggerProps, pickTooltipTriggerProps } from '../Tooltip';
import { validateDataCy } from '../utils/data-cy';

type Props = TooltipTriggerProps & {
  'aria-label': string;
  badge?: string | number;
  'data-cy'?: string;
  isDisabled?: boolean;
  icon: ReactNode;
  onClick?: () => void;
};

export default forwardRef<HTMLButtonElement, Props>(function BabyButton(props, forwardedRef) {
  const ref = useRef<HTMLButtonElement>(null);

  const { isFocusVisible, focusProps } = useFocusRing();

  const { buttonProps } = useButton(
    {
      'aria-label': props['aria-label'],
      isDisabled: Boolean(props.isDisabled),
      onPress: props.onClick,
    },
    ref
  );

  validateDataCy(props['data-cy'], 'button');

  return (
    <button
      {...mergeProps(pickTooltipTriggerProps(props), buttonProps, focusProps)}
      ref={composeRefs(ref, forwardedRef)}
      className={[
        'relative flex w-max shrink-0 items-center rounded-full p-1 text-type-primary transition hover:bg-button-icon-hover disabled:text-type-inactive',
        isFocusVisible ? 'outline' : 'outline-none',
        '[&>*]:!icon-sm',
      ].join(' ')}
      data-cy={props['data-cy']}
    >
      {props.icon}
      {props.badge !== undefined && props.badge !== '' ? (
        <Badge inset={{ right: 12 }}>{props.badge}</Badge>
      ) : null}
    </button>
  );
});
