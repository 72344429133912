import { JoinProjectRoutes, JoinRoutes } from '../../../api/gqlEnums';
import {
  AssignItem,
  AssignProjectLead,
  InviteToProject,
  NewComment,
  NewItem,
  NewMention,
  NewOption,
  NewReportMention,
  NotificationType,
  RemoveFromProject,
  ShareScenario,
} from '../../../generated/graphql';
import { RouteKeys } from '../../../routes/paths';
import { generateSharedPath } from '../../../utilities/routes/links';
import InAppNotificationIconAssign from '../../Icons/InAppNotificationIconAssign';
import InAppNotificationIconComment from '../../Icons/InAppNotificationIconComment';
import InAppNotificationIconEdit from '../../Icons/InAppNotificationIconEdit';
import InAppNotificationIconInvite from '../../Icons/InAppNotificationIconInvite';
import InAppNotificationIconMention from '../../Icons/InAppNotificationIconMention';
import InAppNotificationIconNewItem from '../../Icons/InAppNotificationIconNewItem';
import InAppNotificationIconProjectLead from '../../Icons/InAppNotificationIconProjectLead';
import InAppNotificationIconRemove from '../../Icons/InAppNotificationIconRemove';
import InAppNotificationIconShareDraftItem from '../../Icons/InAppNotificationIconShareDraftItem';
import { generateTitle } from '../../Items/ItemsListItem/ItemsListItemUtils';
import {
  generateItemDisplayTitle,
  generateScenarioDisplayTitle,
  isPrivateVisibility,
} from '../../Items/ItemsUtils';
import { Chip } from '../../scales';

// Types
export type InAppNotificationEntryDescriptionType = {
  id: UUID;
  project: InAppNotificationProject;
  whoDidWhat: string;
  item?: InAppNotificationItem;
  itemDue?: string;
  itemOption?: InAppNotificationItem;
  timeDisplay?: string;
  linkDisabled?: boolean;
  linkMentions?: Mention[];
  linkPath?: string;
  linkHash?: string;
  linkTitle?: string;
  linkTitleIcon?: JSX.Element | undefined;
  tinyDetailsLineSecondary?: string;
  tinyDetailsLineSecondaryIcon?: JSX.Element | undefined;
  displayFooter?: boolean;
};

// Icons
export const notificationIconsMap = {
  [NotificationType.ASSIGNITEM]: <InAppNotificationIconAssign />,
  [NotificationType.ASSIGNPROJECTLEAD]: <InAppNotificationIconProjectLead />,
  [NotificationType.INVITETOPROJECT]: <InAppNotificationIconInvite />,
  [NotificationType.NEWCOMMENT]: <InAppNotificationIconComment />,
  [NotificationType.NEWMENTION]: <InAppNotificationIconMention />,
  [NotificationType.NEWREPORTMENTION]: <InAppNotificationIconMention />,
  [NotificationType.NEWITEM]: <InAppNotificationIconNewItem />,
  [NotificationType.NEWOPTION]: <InAppNotificationIconNewItem />,
  [NotificationType.REMOVEFROMPROJECT]: <InAppNotificationIconRemove />,
  [NotificationType.SHAREDRAFTITEM]: <InAppNotificationIconShareDraftItem />,
  [NotificationType.SHARESCENARIO]: <InAppNotificationIconShareDraftItem />,
  Edit: <InAppNotificationIconEdit />,
};

export const getNotificationIcon = (notification: InAppNotificationWithTime) => {
  const type = notification.content.__typename;
  return type && notificationIconsMap[type];
};
// Descriptions
const getDescriptionNewComment = (notification: InAppNotificationWithTime) => {
  const { content } = notification;
  const { comment, item, project, triggeringUser } = content as NewComment;
  const { text } = comment;
  const { contents, mentions } = text;
  const linkTitle = contents;
  const description = {
    itemDue: generateItemDisplayTitle(item),
    linkHash: `#${comment.id}`,
    linkPath: generateSharedPath(RouteKeys.PROJECT_ITEMS_ITEM, {
      projectId: project.id,
      itemId: item.id,
    }),
    linkTitle,
    linkMentions: mentions,
    tinyDetailsLineSecondary: generateItemDisplayTitle(item),
    tinyDetailsLineSecondaryIcon: generateItemSharingIcon(item, true),
    whoDidWhat: `${triggeringUser.name} commented`,
    displayFooter: true,
  };
  return description;
};

const getDescriptionAssignItem = (notification: InAppNotificationWithTime) => {
  const { content } = notification;
  const { item, project, triggeringUser } = content as AssignItem;
  const description = {
    item,
    linkTitle: generateItemDisplayTitle(item),
    linkTitleIcon: generateItemSharingIcon(item),
    linkPath: generateSharedPath(RouteKeys.PROJECT_ITEMS_ITEM, {
      projectId: project.id,
      itemId: item.id,
    }),
    whoDidWhat: `${triggeringUser.name} assigned to you`,
  };
  return description;
};

const getDescriptionAssignProjectLead = (notification: InAppNotificationWithTime) => {
  const { content } = notification;
  const { project } = content as AssignProjectLead;
  const description = {
    linkPath: generateSharedPath(JoinRoutes.PROJECT, { projectId: project.id }),
    linkTitle: project.name,
    project,
    whoDidWhat: `You are a Lead`,
  };
  return description;
};

const getDescriptionNewMention = (notification: InAppNotificationWithTime) => {
  const { content } = notification;
  const { comment, item, project, triggeringUser } = content as NewMention;
  const { text } = comment;
  const { contents, mentions } = text;
  const linkTitle = contents;
  const description = {
    itemDue: generateItemDisplayTitle(item),
    linkHash: `#${comment.id}`,
    linkPath: generateSharedPath(RouteKeys.PROJECT_ITEMS_ITEM, {
      projectId: project.id,
      itemId: item.id,
    }),
    linkTitle,
    linkMentions: mentions,
    tinyDetailsLineSecondary: generateItemDisplayTitle(item),
    tinyDetailsLineSecondaryIcon: generateItemSharingIcon(item, true),
    whoDidWhat: `${triggeringUser.name} mentioned you`,
    displayFooter: true,
  };
  return description;
};

const getDescriptionShareDraftItem = (notification: InAppNotificationWithTime) => {
  const { content } = notification;
  const { item, project, triggeringUser } = content as AssignItem;
  const description = {
    item,
    linkTitle: generateItemDisplayTitle(item),
    linkTitleIcon: generateItemSharingIcon(item),
    linkPath: generateSharedPath(RouteKeys.PROJECT_ITEMS_ITEM, {
      projectId: project.id,
      itemId: item.id,
    }),
    whoDidWhat: `${triggeringUser.name} shared a draft Item`,
    displayFooter: true,
  };
  return description;
};

const getDescriptionShareScenario = (notification: InAppNotificationWithTime) => {
  const { content } = notification;
  const { scenario, project, triggeringUser } = content as ShareScenario;
  const description = {
    scenario,
    linkTitle: generateScenarioDisplayTitle(scenario),
    linkTitleIcon: null,
    linkHash: `#${scenario.id}`,
    linkPath: generateSharedPath(JoinProjectRoutes.SCENARIOS, {
      projectId: project.id,
    }),
    whoDidWhat: `${triggeringUser.name} shared a scenario`,
    displayFooter: true,
  };
  return description;
};

const getDescriptionNewReportMention = (notification: InAppNotificationWithTime) => {
  const { content } = notification;
  const {
    userReportComment: { comment },
    reportID,
    reportName,
    project,
    triggeringUser,
  } = content as NewReportMention;
  const { text } = comment;
  const { contents, mentions } = text;
  const linkTitle = contents;
  const description = {
    linkPath: generateSharedPath(JoinProjectRoutes.REPORT_ROUTER, {
      projectId: project.id,
      reportID,
    }),
    linkTitle,
    linkMentions: mentions,
    tinyDetailsLineSecondary: reportName,
    whoDidWhat: `${triggeringUser.name} mentioned you`,
    displayFooter: true,
  };
  return description;
};

const getDescriptionProjectInvite = (notification: InAppNotificationWithTime) => {
  const { content } = notification;
  const { project, triggeringUser, message } = content as InviteToProject;
  const description = {
    linkPath: generateSharedPath(JoinRoutes.PROJECT, { projectId: project.id }),
    linkTitle: message || project.name,
    whoDidWhat: `${triggeringUser.name} invited you`,
    displayFooter: !!message,
  };
  return description;
};

const getDescriptionProjectRemove = (notification: InAppNotificationWithTime) => {
  const { content } = notification;
  const { project } = content as RemoveFromProject;
  const description = {
    linkPath: generateSharedPath(JoinRoutes.PROJECT, { projectId: project.id }),
    linkTitle: project.name,
    linkDisabled: true,
    whoDidWhat: 'You have left',
    displayFooter: false,
  };
  return description;
};

const getDescriptionNewItem = (notification: InAppNotificationWithTime) => {
  const { content } = notification;
  const { project, triggeringUser, item } = content as NewItem;
  const description = {
    linkPath: generateSharedPath(RouteKeys.PROJECT_ITEMS_ITEM, {
      projectId: project.id,
      itemId: item.id,
    }),
    linkTitle: generateTitle(item),
    linkTitleIcon: generateItemSharingIcon(item),
    whoDidWhat: `${triggeringUser.name} added a new Item`,
    displayFooter: true,
  };
  return description;
};

const getDescriptionNewOption = (notification: InAppNotificationWithTime) => {
  const { content } = notification;
  const { project, triggeringUser, parentItem, option } = content as NewOption;
  const description = {
    linkPath: generateSharedPath(RouteKeys.PROJECT_ITEMS_ITEM, {
      projectId: project.id,
      itemId: option.id,
    }),
    parentItem,
    option,
    linkTitle: generateItemDisplayTitle(option),
    linkTitleIcon: generateItemSharingIcon(option),
    tinyDetailsLineSecondary: generateItemDisplayTitle(parentItem),
    whoDidWhat: `${triggeringUser.name} added a new Option`,
    displayFooter: true,
  };
  return description;
};

const generateItemSharingIcon = (
  item: ItemLink,
  tinyDetails?: boolean
): JSX.Element | undefined => {
  if (!item.visibility) return undefined;
  if (isPrivateVisibility(item.visibility)) {
    const style = `flex ${tinyDetails ? 'text-xs' : 'icon-sm'}`;
    return (
      <div className={style}>
        <Chip text="Draft" />
      </div>
    );
  }
  return undefined;
};

const typeGetDescriptionMap = {
  [NotificationType.ASSIGNITEM]: getDescriptionAssignItem,
  [NotificationType.ASSIGNPROJECTLEAD]: getDescriptionAssignProjectLead,
  [NotificationType.NEWMENTION]: getDescriptionNewMention,
  [NotificationType.NEWREPORTMENTION]: getDescriptionNewReportMention,
  [NotificationType.INVITETOPROJECT]: getDescriptionProjectInvite,
  [NotificationType.NEWCOMMENT]: getDescriptionNewComment,
  [NotificationType.NEWITEM]: getDescriptionNewItem,
  [NotificationType.NEWOPTION]: getDescriptionNewOption,
  [NotificationType.REMOVEFROMPROJECT]: getDescriptionProjectRemove,
  [NotificationType.SHAREDRAFTITEM]: getDescriptionShareDraftItem,
  [NotificationType.SHARESCENARIO]: getDescriptionShareScenario,
};

const getBaseDescription = (notification: InAppNotificationWithTime) => {
  const { content, timeDateDisplay, timeElapsedDisplay, id } = notification;
  const { project } = content;
  const baseDescription = {
    id,
    project,
    timeDisplay: timeElapsedDisplay || timeDateDisplay,
  };
  return baseDescription;
};

export const getNotificationDescription = (notification: InAppNotificationWithTime) => {
  const { content } = notification;
  const type = content?.__typename;
  const baseDescription = getBaseDescription(notification);
  const getDescription = type && typeGetDescriptionMap[type];
  if (!getDescription) return baseDescription as InAppNotificationEntryDescriptionType;
  const typeUniqueDescription = getDescription(notification);
  return {
    ...baseDescription,
    ...typeUniqueDescription,
  } as InAppNotificationEntryDescriptionType;
};
