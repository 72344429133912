import { useMutation } from '@apollo/client';

import {
  SetMilestoneTimelinePhaseInput,
  SetMilestoneTimelinePhaseMutation,
  SetMilestoneTimelinePhaseMutationVariables,
} from '../../../generated/graphql';

import { setMilestoneTimelinePhaseMutation } from './queries';

function useUpdateMilestoneTimelinePhase() {
  const [updateMilestoneTimelinePhaseFunc] = useMutation<
    SetMilestoneTimelinePhaseMutation,
    SetMilestoneTimelinePhaseMutationVariables
  >(setMilestoneTimelinePhaseMutation);

  const submitFunc = (
    projectID: UUID,
    input: SetMilestoneTimelinePhaseInput,
    onSuccess?: () => void
  ) =>
    updateMilestoneTimelinePhaseFunc({
      variables: { projectID, input },
    }).then(() => {
      onSuccess?.();
    });

  return [submitFunc];
}

export default useUpdateMilestoneTimelinePhase;
