import { FILES_ASSETS_DEFAULT_SORT } from '../../../api/apollo/reactiveVars';
import { getProjectIdFromUrl } from '../../../utilities/url';

import useOptimisticUploadAssets from './useOptimisticUploadAssets';

const useOptimisticProjectUploadAssets = (uploadingAssets: UploadingAsset[]) => {
  const projectID = getProjectIdFromUrl();
  const sortBy = FILES_ASSETS_DEFAULT_SORT;
  useOptimisticUploadAssets(projectID, sortBy, uploadingAssets);
};

export default useOptimisticProjectUploadAssets;
