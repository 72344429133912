import { gql } from '../../../../api/graphqlFragments';

export const itemBookmarksQuery = gql`
  query itemBookmarks($itemID: UUID!) {
    itemBookmarks(itemID: $itemID) {
      id
      name
      thumbnail
      payload
    }
  }
`;
