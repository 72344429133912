import { ApolloClient } from '@apollo/client';

import {
  ItemLink,
  LoadItemLinksQuery,
  LoadItemLinksQueryVariables,
} from '../../../../generated/graphql';
import { useQuery } from '../../../../hooks/useMountAwareQuery';

import { loadItemLinksQuery } from './queries';

export const readItems = (client: ApolloClient<object>, projectID: UUID): ItemLink[] => {
  const data = client.readQuery<LoadItemLinksQuery, LoadItemLinksQueryVariables>({
    query: loadItemLinksQuery,
    variables: { projectID },
  });
  return data?.itemLinks ?? [];
};

const useItemLinksQuery = (projectID?: UUID) =>
  useQuery<LoadItemLinksQuery, LoadItemLinksQueryVariables>(loadItemLinksQuery, {
    variables: { projectID },
    skip: !projectID,
  });

export default useItemLinksQuery;
