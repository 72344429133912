import { createStyles } from '@material-ui/core/styles';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';

const styles = (theme: KomodoTheme) =>
  createStyles({
    meetingLink: {
      cursor: 'pointer',
      '&:hover': { color: theme.palette.link },
      textDecoration: 'underline',
      display: 'inline-block',
      color: theme.palette.primaryGrey,
      fontWeight: 400,
    },
    meetingLinkExpanded: {
      cursor: 'pointer',
      '&:hover': { color: theme.palette.link },
      textDecoration: 'underline',
      display: 'inline-block',
      color: theme.palette.primaryGrey,
    },
    deletedMeeting: {
      display: 'inline-block',
      color: theme.palette.primaryGrey,
      fontWeight: 400,
    },
    deletedMeetingExpanded: {
      display: 'inline-block',
      color: theme.palette.primaryGrey,
    },
  });

export default styles;
