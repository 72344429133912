import * as cronParser from 'cron-parser';

import { ReportDistributionEvent } from '../../../generated/graphql';
import { pluralizeCountString } from '../../../utilities/string';
import { isEnumValue } from '../../../utilities/types';
import { SelectEntry } from '../../scales';

export type DistributionDialogsState = {
  distributionDialogDataIsOpen: boolean;
  distributionHistoryDialogIsOpen: boolean;
  reportID: string;
  reportName: string;
  isEditingDistribution: boolean;
};

export enum ScheduleOptions {
  NOW = 'Now',
  SCHEDULED = 'Scheduled',
}

export const scheduleOptions = [
  { label: 'Now', value: ScheduleOptions.NOW },
  { label: 'Scheduled', value: ScheduleOptions.SCHEDULED },
];

export enum FrequencyOptionsID {
  DAILY = 'Daily',
  DAILY_WEEKDAYS = 'Daily_Weekdays',
  WEEKLY = 'Weekly',
  EVERY_OTHER_WEEK = 'Every_Other_Week',
  MONTHLY = 'Monthly',
}

export const frequencyOptions: SelectEntry[] = [
  { label: 'Daily', id: FrequencyOptionsID.DAILY },
  { label: 'Daily (Weekdays)', id: FrequencyOptionsID.DAILY_WEEKDAYS },
  { label: 'Weekly', id: FrequencyOptionsID.WEEKLY },
  { label: 'Every Other Week', id: FrequencyOptionsID.EVERY_OTHER_WEEK },
  { label: 'Monthly', id: FrequencyOptionsID.MONTHLY },
];

export enum DayOfWeekOptionsID {
  SUNDAY = '0',
  MONDAY = '1',
  TUESDAY = '2',
  WEDNESDAY = '3',
  THURSDAY = '4',
  FRIDAY = '5',
  SATURDAY = '6',
}

export const dayOfWeekOptions: SelectEntry[] = [
  { label: 'Sunday', id: DayOfWeekOptionsID.SUNDAY },
  { label: 'Monday', id: DayOfWeekOptionsID.MONDAY },
  { label: 'Tuesday', id: DayOfWeekOptionsID.TUESDAY },
  { label: 'Wednesday', id: DayOfWeekOptionsID.WEDNESDAY },
  { label: 'Thursday', id: DayOfWeekOptionsID.THURSDAY },
  { label: 'Friday', id: DayOfWeekOptionsID.FRIDAY },
  { label: 'Saturday', id: DayOfWeekOptionsID.SATURDAY },
];

export enum WeekOfTheMonthOptionsID {
  FIRST = '1-7',
  SECOND = '8-14',
  THIRD = '15-21',
  FOURTH = '22-28',
}

export const weekOfTheMonthOptions: SelectEntry[] = [
  { label: 'First', id: WeekOfTheMonthOptionsID.FIRST },
  { label: 'Second', id: WeekOfTheMonthOptionsID.SECOND },
  { label: 'Third', id: WeekOfTheMonthOptionsID.THIRD },
  { label: 'Fourth', id: WeekOfTheMonthOptionsID.FOURTH },
];

export enum StartingWeekOptionsID {
  THIS_WEEK = 'This_week',
  NEXT_WEEK = 'Next_week',
  WEEK_AFTER_NEXT = 'Week_after_next',
}

export const startingWeekOptions: SelectEntry[] = [
  { label: 'This Week', id: StartingWeekOptionsID.THIS_WEEK },
  { label: 'Next Week', id: StartingWeekOptionsID.NEXT_WEEK },
  { label: 'Week after Next', id: StartingWeekOptionsID.WEEK_AFTER_NEXT },
];

export const startingWeekIfDayHasPassed: StartingWeekOptionsID[] = [
  StartingWeekOptionsID.NEXT_WEEK,
  StartingWeekOptionsID.WEEK_AFTER_NEXT,
];
export const startingWeekIfDayHasNotPassed: StartingWeekOptionsID[] = [
  StartingWeekOptionsID.THIS_WEEK,
  StartingWeekOptionsID.NEXT_WEEK,
];

// get all the time of day options users can select
// in 30 minute intervals.  We specifically exclude
// midnight so that if we happen to miss sending out a report
// at midnight when we look back at reports we missed
// we don't need to check across days / weeks / months/ years
export const timeOfDayOptions: SelectEntry[] = Array.from({ length: 47 }, (_, i) => {
  const hour = Math.floor((i + 1) / 2);
  const minute = (i + 1) % 2 === 0 ? '00' : '30';
  const period = hour < 12 ? 'AM' : 'PM';
  const timezone = new Date().toLocaleTimeString('en-us', { timeZoneName: 'short' }).split(' ')[2];
  const time = `${hour % 12 || 12}:${minute} ${period} ${timezone}`;
  const cronExpression = `${minute} ${hour}`;
  return { label: time, id: cronExpression };
});

export type DistributionFrequencyInputs = {
  selectedCollaboratorIDs: UUID[];
  selectedScheduleOption: ScheduleOptions;
  selectedFrequency: FrequencyOptionsID;
  selectedDayOfWeek: DayOfWeekOptionsID;
  selectedTimeOfDay: string;
  selectedWeekOfTheMonth: WeekOfTheMonthOptionsID;
  selectedStartingWeek: StartingWeekOptionsID;
};

export const defaultDistributionFrequencyInputs: DistributionFrequencyInputs = {
  selectedCollaboratorIDs: [],
  selectedScheduleOption: ScheduleOptions.SCHEDULED,
  selectedFrequency: FrequencyOptionsID.WEEKLY,
  selectedDayOfWeek: DayOfWeekOptionsID.MONDAY,
  selectedTimeOfDay: timeOfDayOptions[15].id,
  selectedWeekOfTheMonth: WeekOfTheMonthOptionsID.FIRST,
  selectedStartingWeek: StartingWeekOptionsID.THIS_WEEK,
};

export function areFrequencyInputsEqual(
  input1: DistributionFrequencyInputs,
  input2: DistributionFrequencyInputs
): boolean {
  return (
    input1.selectedScheduleOption === input2.selectedScheduleOption &&
    input1.selectedFrequency === input2.selectedFrequency &&
    input1.selectedDayOfWeek === input2.selectedDayOfWeek &&
    input1.selectedTimeOfDay === input2.selectedTimeOfDay &&
    input1.selectedWeekOfTheMonth === input2.selectedWeekOfTheMonth &&
    input1.selectedStartingWeek === input2.selectedStartingWeek &&
    areCollaboratorIDsEqual(input1.selectedCollaboratorIDs, input2.selectedCollaboratorIDs)
  );
}

function areCollaboratorIDsEqual(ids1: UUID[], ids2: UUID[]): boolean {
  return (
    ids1.length === ids2.length &&
    ids1.every((id) => ids2.includes(id)) &&
    ids2.every((id) => ids1.includes(id))
  );
}

export const SEND_NOW_CRON_EXPRESSION = '* * * * * *';
export function createCronExpression(input: Partial<DistributionFrequencyInputs>): string {
  const {
    selectedScheduleOption,
    selectedFrequency: frequencyOption,
    selectedDayOfWeek: dayOfWeekOption,
    selectedWeekOfTheMonth: weekOfTheMonthOption,
    selectedTimeOfDay: timeOfDayOption,
  } = input;

  if (selectedScheduleOption === ScheduleOptions.NOW) {
    return SEND_NOW_CRON_EXPRESSION; // send now
  }

  switch (frequencyOption) {
    case FrequencyOptionsID.DAILY:
      return `${timeOfDayOption ?? '* *'} * * * 0`;
    case FrequencyOptionsID.DAILY_WEEKDAYS:
      return `${timeOfDayOption ?? '* *'} * * 1-5 0`;
    case FrequencyOptionsID.WEEKLY:
      return `${timeOfDayOption ?? '* *'} * * ${dayOfWeekOption ?? '*'} 0`;
    case FrequencyOptionsID.EVERY_OTHER_WEEK: {
      return `${timeOfDayOption ?? '* *'} * * ${dayOfWeekOption ?? '*'} 1`;
    }
    case FrequencyOptionsID.MONTHLY:
      return `${timeOfDayOption ?? '* *'} ${weekOfTheMonthOption ?? '*'} * ${
        dayOfWeekOption ?? '*'
      } 0`;
    default:
      return '';
  }
}

export function createFrequencyInputsFromCronExpression(
  cronExpression: string,
  selectedCollaboratorIDs: UUID[],
  startDate: Date
): DistributionFrequencyInputs {
  const input: DistributionFrequencyInputs = {
    ...defaultDistributionFrequencyInputs,
    selectedCollaboratorIDs,
  };

  // this should not happen as a user can not edit a send now distribution
  if (cronExpression === SEND_NOW_CRON_EXPRESSION) {
    input.selectedScheduleOption = ScheduleOptions.NOW;
    return input;
  }

  const cronExpressionParts = cronExpression.split(' ');
  input.selectedTimeOfDay = `${cronExpressionParts[0]} ${cronExpressionParts[1]}`;

  const frequencyOption = getFrequencyOptionFromCronExpression(cronExpressionParts);
  input.selectedFrequency = frequencyOption;

  const selectedWeekOfTheMonth =
    isEnumValue(WeekOfTheMonthOptionsID, cronExpressionParts[2]) && cronExpressionParts[2];
  if (selectedWeekOfTheMonth) {
    input.selectedWeekOfTheMonth = selectedWeekOfTheMonth;
  }

  const selectedDayOfWeek =
    isEnumValue(DayOfWeekOptionsID, cronExpressionParts[4]) && cronExpressionParts[4];
  if (selectedDayOfWeek) {
    input.selectedDayOfWeek = selectedDayOfWeek;
  }

  if (input.selectedFrequency === FrequencyOptionsID.EVERY_OTHER_WEEK) {
    const today = new Date();

    const startingWeekOptions = getStartingWeekOptions(cronExpression);
    const canSendThisWeek = startingWeekOptions.includes(StartingWeekOptionsID.THIS_WEEK);

    if (canSendThisWeek) {
      // get the first day of this week
      const firstDayOfThisWeek = getFirstDayOfWeek(today);

      // look at how many there are betwen today the first day of this week, and the start date
      const weekDiff = weeksBetween(startDate, firstDayOfThisWeek);

      const selectedStartingWeek =
        weekDiff % 2 === 0 ? StartingWeekOptionsID.NEXT_WEEK : StartingWeekOptionsID.THIS_WEEK;
      input.selectedStartingWeek = selectedStartingWeek;
    } else {
      // get the first day of next week
      const nextWeek = new Date(today.getTime() + oneWeekInMilliseconds);
      const firstDayOfNextWeek = getFirstDayOfWeek(nextWeek);

      // look at how many there are betwen today the first day of next week, and the start date
      const weekDiff = weeksBetween(startDate, firstDayOfNextWeek);

      const selectedStartingWeek =
        weekDiff % 2 === 0
          ? StartingWeekOptionsID.WEEK_AFTER_NEXT
          : StartingWeekOptionsID.NEXT_WEEK;
      input.selectedStartingWeek = selectedStartingWeek;
    }
  }

  return input;
}

const getFirstDayOfWeek = (d: Date) => {
  const day = d.getDay();
  const diff = d.getDate() - day + (day === 0 ? -6 : 0); // adjust when day is sunday
  const firstDayOfWeek = new Date(d.setDate(diff));
  firstDayOfWeek.setHours(0, 0, 0, 0);
  return firstDayOfWeek;
};

const oneWeekInMilliseconds = 7 * 24 * 60 * 60 * 1000; // 7 days * 24 hours * 60 minutes * 60 seconds * 1000 millisecondszw

const weeksBetween = (d1: Date, d2: Date) => {
  return Math.ceil(Math.abs(d2.getTime() - d1.getTime()) / oneWeekInMilliseconds);
};

function getFrequencyOptionFromCronExpression(cronExpressionParts: string[]): FrequencyOptionsID {
  const weekOfTheMonthOption = cronExpressionParts[2];
  const dayOfWeekOption = cronExpressionParts[4];
  const nextWeekValue = cronExpressionParts[5];

  if (weekOfTheMonthOption === '*' && nextWeekValue === '1') {
    return FrequencyOptionsID.EVERY_OTHER_WEEK;
  }

  if (weekOfTheMonthOption === '*' && dayOfWeekOption === '*') {
    return FrequencyOptionsID.DAILY;
  }

  if (weekOfTheMonthOption === '*' && dayOfWeekOption === '1-5') {
    return FrequencyOptionsID.DAILY_WEEKDAYS;
  }

  if (weekOfTheMonthOption === '*' && dayOfWeekOption !== '*') {
    return FrequencyOptionsID.WEEKLY;
  }

  if (weekOfTheMonthOption !== '*' && dayOfWeekOption !== '*') {
    return FrequencyOptionsID.MONTHLY;
  }

  return FrequencyOptionsID.DAILY;
}

export function isCompleteFrequencySelection(input: Partial<DistributionFrequencyInputs>) {
  const {
    selectedCollaboratorIDs,
    selectedScheduleOption: scheduleOption,
    selectedFrequency: frequencyOption,
    selectedDayOfWeek: dayOfWeekOption,
    selectedWeekOfTheMonth: weekOfTheMonthOption,
    selectedTimeOfDay: timeOfDayOption,
    selectedStartingWeek: startingWeekOption,
  } = input;
  if (!selectedCollaboratorIDs || selectedCollaboratorIDs.length === 0) {
    return false;
  }
  if (scheduleOption === ScheduleOptions.NOW) {
    return true;
  }

  if (!frequencyOption) {
    return false;
  }

  switch (frequencyOption) {
    case FrequencyOptionsID.DAILY:
    case FrequencyOptionsID.DAILY_WEEKDAYS:
      return !!timeOfDayOption;
    case FrequencyOptionsID.WEEKLY:
      return !!timeOfDayOption && !!dayOfWeekOption;
    case FrequencyOptionsID.EVERY_OTHER_WEEK:
      return !!timeOfDayOption && !!dayOfWeekOption && !!startingWeekOption;
    case FrequencyOptionsID.MONTHLY:
      return !!timeOfDayOption && !!dayOfWeekOption && !!weekOfTheMonthOption;
    default:
      return false;
  }
}

export const formatCronExpression = (expression: string) => {
  // remove the last field of the cron expression which is a custom field
  const cronExpressionParts = expression.split(' ');
  const nextWeekValue = cronExpressionParts.pop();
  return { formattedCronExpression: cronExpressionParts.join(' '), nextWeekValue };
};

export const getStartDate = (cronExpression: string, startingWeekOption: StartingWeekOptionsID) => {
  if (cronExpression === SEND_NOW_CRON_EXPRESSION) {
    return new Date().toISOString();
  }

  const { formattedCronExpression, nextWeekValue } = formatCronExpression(cronExpression);
  const cron = cronParser.parseExpression(formattedCronExpression);

  const startingWeekOptions = getStartingWeekOptions(cronExpression);
  const canSendThisWeek = startingWeekOptions.includes(StartingWeekOptionsID.THIS_WEEK);

  if (nextWeekValue === '1') {
    if (
      (canSendThisWeek && startingWeekOption === StartingWeekOptionsID.NEXT_WEEK) ||
      (!canSendThisWeek && startingWeekOption === StartingWeekOptionsID.WEEK_AFTER_NEXT)
    )
      cron.iterate(1);
  }
  return cron.next().toDate().toISOString();
};

// based on the input cron expression get the available starting week options
export const getStartingWeekOptions = (cronExpression: string) => {
  const { formattedCronExpression } = formatCronExpression(cronExpression);
  const cron = cronParser.parseExpression(formattedCronExpression);

  // check if the day the distribution is scheduled to be sent out
  // has already passed this week
  const today = new Date();
  const nextSend = cron.next().toDate();
  const dayHasPassedThisWeek = nextSend.getDay() < today.getDay();
  const dayIsToday = nextSend.getDay() === today.getDay();

  // if the distribution is to be sent out today, has the time already passed
  const nextSendTime = nextSend.getHours() * 60 + nextSend.getMinutes();
  const nowTime = today.getHours() * 60 + today.getMinutes();
  const timeHasPassed = nextSendTime < nowTime;

  if (dayHasPassedThisWeek || (dayIsToday && timeHasPassed)) {
    return startingWeekIfDayHasPassed;
  }
  return startingWeekIfDayHasNotPassed;
};

// based on the input distribution get the selection entries for
// the Starting Week dropdown
export const getStartingWeekEntries = (distribution: DistributionFrequencyInputs) => {
  const cronExpression = createCronExpression(distribution);

  const options = getStartingWeekOptions(cronExpression);
  return startingWeekOptions.filter((o) => {
    if (isEnumValue(StartingWeekOptionsID, o.id)) return options.includes(o.id);
    return false;
  });
};

export const getDistributionSummaryText = (
  distribution: DistributionFrequencyInputs,
  cronExpression: string
) => {
  const { selectedScheduleOption, selectedStartingWeek: startingWeekOption } = distribution;

  const selectedText = '';
  let summaryText = '';
  if (selectedScheduleOption === ScheduleOptions.NOW) {
    return { summaryText, selectedText };
  }

  // update
  const startDate = getStartDate(cronExpression, startingWeekOption);
  // convert the starts date string to be abbreviated Month, Day
  const formattedStartDate = new Date(startDate).toLocaleDateString('en-us', {
    month: 'short',
    day: 'numeric',
  });

  summaryText = `Next Distribution: ${formattedStartDate}`;

  return { summaryText, selectedText };
};

export const getDistributionFrequencySummaryText = (
  numberOfCollaborators: number,
  distribution: DistributionFrequencyInputs,
  isSendNow: boolean
) => {
  const sendingToTeammatesString = `Sending to ${pluralizeCountString(
    'teammate',
    numberOfCollaborators
  )}`;

  if (isSendNow) {
    return sendingToTeammatesString;
  }

  const {
    selectedFrequency: frequencyOption,
    selectedDayOfWeek: dayOfWeekOption,
    selectedWeekOfTheMonth: weekOfTheMonthOption,
    selectedTimeOfDay: timeOfDayOption,
  } = distribution;

  let text = '';

  const timeOfDay = timeOfDayOptions.find((time) => time.id === timeOfDayOption)?.label;
  const dayOfWeek = dayOfWeekOptions.find((day) => day.id === dayOfWeekOption)?.label;
  const weekOfMonth = weekOfTheMonthOptions.find((week) => week.id === weekOfTheMonthOption)?.label;

  switch (frequencyOption) {
    case FrequencyOptionsID.DAILY:
      text = `${sendingToTeammatesString} daily at ${timeOfDay}`;
      break;
    case FrequencyOptionsID.DAILY_WEEKDAYS:
      text = `${sendingToTeammatesString} every weekday at ${timeOfDay}`;
      break;
    case FrequencyOptionsID.WEEKLY:
      text = `${sendingToTeammatesString} every ${dayOfWeek} at ${timeOfDay}`;
      break;
    case FrequencyOptionsID.EVERY_OTHER_WEEK: {
      text = `${sendingToTeammatesString} every other ${dayOfWeek} at ${timeOfDay}`;
      break;
    }
    case FrequencyOptionsID.MONTHLY:
      text = `${sendingToTeammatesString} monthly on the ${weekOfMonth} ${dayOfWeek} at ${timeOfDay}`;
      break;
    default:
      break;
  }

  return text;
};

export const getShareLabel = (
  isEditDialog: boolean,
  hasUnsavedChanges: boolean,
  isSendNow: boolean
): string => {
  let shareLabel = 'Schedule Distribution';
  if (isEditDialog || isSendNow) {
    shareLabel = 'Send Now';
  }
  if (hasUnsavedChanges) {
    shareLabel = 'Save Changes';
  }
  return shareLabel;
};

// get the count of users both by role name and by company name
export const getCollaboratorsCompanyAndRoleCount = (
  distributionInput: DistributionFrequencyInputs,
  collaborators: Collaborator[],
  companies: ProjectCompany[]
) => {
  const roleCount = new Map<string, number>();
  const companyCount = new Map<string, number>();
  distributionInput.selectedCollaboratorIDs.forEach((id) => {
    const collaborator = collaborators?.find((c) => c.id === id);
    if (!collaborator) return;
    if (roleCount.has(collaborator.role.name)) {
      roleCount.set(collaborator.role.name, 1);
    } else {
      const count = roleCount.get(collaborator.role.name) ?? 0;
      roleCount.set(collaborator.role.name, count + 1);
    }

    const company = companies.find((c) =>
      collaborator.user.email.endsWith(c.company.domain || '')
    )?.company;
    if (!company) return;
    if (companyCount.has(company.name)) {
      companyCount.set(company.name, 1);
    } else {
      const count = companyCount.get(company.name) ?? 0;
      companyCount.set(company.name, count + 1);
    }
  });

  return { roles: roleCount, companies: companyCount };
};

export const getReportDistributionAnalyticsProps = (
  distributionInput: DistributionFrequencyInputs,
  cronExpression: string,
  collaborators: Collaborator[],
  companies: ProjectCompany[]
) => {
  const time = `${cronExpression.split(' ')[1]}:${cronExpression.split(' ')[0]}`;
  const roleAndCompanyCount = getCollaboratorsCompanyAndRoleCount(
    distributionInput,
    collaborators,
    companies
  );
  const distributionType =
    distributionInput.selectedScheduleOption === ScheduleOptions.NOW
      ? 'One time'
      : distributionInput.selectedFrequency;

  const biweeklyStartingWeek =
    distributionInput.selectedFrequency === FrequencyOptionsID.EVERY_OTHER_WEEK
      ? distributionInput.selectedStartingWeek
      : '';

  return {
    distributionType,
    cadence: cronExpression,
    time,
    recipientCount: distributionInput.selectedCollaboratorIDs.length,
    biweeklyStartingWeek,
    roles: {
      ...Object.fromEntries(roleAndCompanyCount.roles),
    },
    companies: {
      ...Object.fromEntries(roleAndCompanyCount.companies),
    },
  };
};
export type EventDistributedReports = {
  role: string;
  link: string;
  recipients: string[];
};

export type DistributionHistoryEvent = {
  reportDistributionID: UUID;
  sentAt: Time;
  reportID: UUID;
  reportName: string;
  sentBy: string;
  reports: EventDistributedReports[];
  isEditable: boolean;
  isScheduled: boolean;
  isDeprecated: boolean;
};

export const transformDistributionHistoryData = (
  events: ReportDistributionEvent[],
  activeCollaborators?: Collaborator[]
): DistributionHistoryEvent[] => {
  return events.map((event) => {
    const sentBy =
      activeCollaborators?.find((collab) => collab.user.id === event.sentBy)?.user?.name ??
      'Unknown';

    const reports = event.reports.map((report) => {
      const recipients = report.sentTo.map((sentTo) => {
        const collaborator = activeCollaborators?.find((collab) => collab.id === sentTo);
        return collaborator?.user?.name ?? 'Unknown';
      });

      const role =
        activeCollaborators?.find((collab) => collab.role.id === report.roleID)?.role.name ??
        'Unknown';

      return {
        role,
        link: report.assetURL,
        recipients,
      };
    });

    return {
      reportDistributionID: event.id,
      sentAt: event.sentAt,
      reportID: event.reportID,
      reportName: event.reportName,
      sentBy,
      reports,
      isEditable: event.isScheduled && !event.deprecated,
      isScheduled: event.isScheduled,
      isDeprecated: event.deprecated,
    };
  });
};
