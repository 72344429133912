import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';
import { titleStyle } from '../../CostReport/CostReportHeader/CostReportHeaderStyles';

const styles = (theme: KomodoTheme) =>
  createStyles({
    actionIcon: {
      float: 'left',
      marginRight: '4px',
    },
    actionText: {
      ...theme.typography.body1,
      fontWeight: 500,
      float: 'left',
      textTransform: 'none',
      paddingTop: '3px',
    },
    button: {
      marginLeft: theme.spacing.unit * 2,
    },
    controlHeader: {
      alignItems: 'center',
      display: 'flex',
      flex: '1 1 auto',
      justifyContent: 'flex-end',
    },
    draftIcon: {
      alignItems: 'center',
      border: '2px solid '.concat(theme.palette.blue),
      borderRadius: 3,
      color: theme.palette.blue,
      fontFamily: theme.typography.fontFamily,
      fontSize: '12px',
      lineHeight: '16px',
      marginLeft: 20,
      padding: '4px 8px 2px 8px',
      textAlign: 'center',
    },
    header: {
      display: 'flex',
      '@media print': {
        display: 'none',
      },
    },
    headerOptions: {
      display: 'flex',
      alignItems: 'center',
    },
    iconMenu: {
      marginLeft: 8,
    },
    ...titleStyle,
    printHeader: {
      display: 'none',
      '@media print': {
        display: 'block',
      },
    },
    menu: {
      display: 'inline',
    },
    selectFilterMenu: {
      padding: '0px 5px 24px 24px',
    },
    subHeader: {
      display: 'flex',
      paddingRight: 24,
    },
    wrapper: {
      alignItems: 'center',
      display: 'flex',
    },
    link: {
      color: theme.palette.link,
    },
    tipText: {
      ...theme.typography.h4Medium,
      fontFamily: theme.typography.fontFamily,
    },
    shadedTipText: {
      fontFamily: theme.typography.fontFamily,
      fontSize: 12,
      fontWeight: 400,
      lineHeight: '144%',
      letterSpacing: '0.0119em',
    },
  });

export default styles;
