import { companyUserQuery } from '../components/login/hooks/queries';
import { CompanyUserQuery, CompanyUserQueryVariables } from '../generated/graphql';

import { QueryHookOptions, useQuery } from './useMountAwareQuery';
import { MountPolicy } from './usePolicyOnFirstMount';

export const useCompanyUserQuery = (
  mountPolicy?: MountPolicy,
  options?: QueryHookOptions<CompanyUserQuery, CompanyUserQueryVariables>
) => useQuery<CompanyUserQuery, CompanyUserQueryVariables>(companyUserQuery, options, mountPolicy);
