import { gql, itemActivityFields } from '../../../api/graphqlFragments';

export const projectActivityQuery = gql`
  query ProjectActivity(
    $projectID: UUID!
    $filterByUserID: UUID
    $dateFilter: DateFilter!
    $timezoneOffset: Int!
    $costMode: CostMode!
    $pagination: PaginationByOffsetID!
  ) {
    projectActivity(
      projectID: $projectID
      filterByUserID: $filterByUserID
      dateFilter: $dateFilter
      timezoneOffset: $timezoneOffset
      costMode: $costMode
      pagination: $pagination
    ) {
      ...itemActivityFields
    }
  }
  ${itemActivityFields}
`;
