import { FC } from 'react';

import { ReactiveVar } from '@apollo/client';
import { IconButton, Typography, createStyles } from '@material-ui/core';
import { InsertLink, Replay } from '@material-ui/icons';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';

import { setReactiveLocal } from '../../../../api/apollo/reactiveVars';
import useSendAnalytics from '../../../../hooks/useSendAnalytics';
import { KomodoTheme, withStyles } from '../../../../theme/komodo-mui-theme';
import { removeYear } from '../../../../utilities/string';
import NormalTooltip from '../../../NormalTooltip/NormalTooltip';
import IconMenuIcon from '../../../Select/SelectMenu/IconMenuIcon';
import { SelectVariants } from '../../../Select/SelectMenu/SelectStyles';
import { isMultilevelCategorization, updateMap } from '../../utils';
import CategorizationMatching, {
  SELECTOR_WIDTH,
} from '../CategorizationMatching/CategorizationMatching';
import CategorizationSelect from '../CategorizationSelect/CategorizationSelect';

import { getErrorsMap } from './CategorizationsMapUtils';

const styles = (theme: KomodoTheme) =>
  createStyles({
    categorizationName: {
      fontSize: 14,
      fontWeight: 400,
      paddingTop: 2,
    },
    categoryPreview: {
      fontSize: 10,
      minHeight: 16,
    },
    chipPadding: { padding: 2 },
    chipRoot: {
      justifyContent: 'flex-start',
      paddingRight: '4px',
      fontSize: 14,
    },
    verticalStack: {
      flexDirection: 'column',
      alignSelf: 'center',
    },
    chip: {
      '&:focus': {
        background: theme.palette.backgroundGrey,
      },
      alignSelf: 'center',
      background: theme.palette.backgroundGrey,
      border: theme.border.line,
      borderRadius: theme.border.radius,
      flexGrow: 1,
      width: SELECTOR_WIDTH,
    },
    chipLabel: { color: theme.palette.joinPrimary, flex: 1 },
    draftCategorization: {
      alignSelf: 'center',
      background: theme.palette.backgroundGrey,
      border: theme.border.line,
      borderRadius: theme.border.radius,
      boxSizing: 'border-box',
      flexGrow: 1,
      maxWidth: 258,
      minHeight: 40,
      padding: '2px 10px',
      '&:focus': {
        background: theme.palette.backgroundGrey,
      },
      width: SELECTOR_WIDTH + 18, // manually set, double-check this width
      position: 'relative',
    },
    iconLink: {
      transform: 'rotate(90deg)',
      backgroundColor: theme.palette.backgroundWhite,
    },
    iconPlacement: {
      top: '50%',
      transform: 'translateY(-50%)',
      position: 'absolute',
      left: '80%',
    },
    arrow: {
      width: 30,
      height: 30,
    },
    hidden: { visibility: 'hidden' },
    horizontal: { display: 'flex', gap: '6px', alignItems: 'center' },
    verified: { marginLeft: 'auto' },
    matching: { paddingLeft: 8, width: SELECTOR_WIDTH, display: 'flex' },
    select: { flexGrow: 1 },
    undo: { height: 34, width: 34, marginLeft: 8 },
    icon: { height: 22, width: 22 },
    overflowEllipsis: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    placeholder: { width: SELECTOR_WIDTH, paddingLeft: 8 },
    selectPlacehodler: { width: SELECTOR_WIDTH, display: 'flex' },
    selectPlacehodlerText: { alignSelf: 'center' },
    tooltip: { maxWidth: 350 }, // used by the mui tooltip component
  });

type CategorizationLineProps = {
  classes: Classes<typeof styles>;
  importEstimateKey: string;
  importEstimateVar: ReactiveVar<ImportEstimateParameters>;
  draftCategorization?: DraftCategorization;
  draftCategorizations: DraftCategorization[];
  multilevelCategorizations?: DraftCategorization[];
  isUniformatEnabled: boolean;
  isMasterformatEnabled: boolean;
  categorizationsProject: ProjectCategorizationMetadata['categorization'][];
  mapping: Map<string, string>; // fix
  mappingBuiltIn: Map<string, Level>; // ToDo: Make this into a real map
  setMapping: (mapping: Map<string, string>, cats: DraftCategorization[]) => void;
  newCategorizationMap: Map<string, boolean>;
};

const CategorizationLine: FC<CategorizationLineProps> = ({
  classes,
  importEstimateKey,
  importEstimateVar,
  draftCategorization,
  draftCategorizations,
  multilevelCategorizations,
  isUniformatEnabled,
  isMasterformatEnabled,
  categorizationsProject,
  mapping,
  mappingBuiltIn,
  setMapping,
  newCategorizationMap,
}) => {
  const { name = multilevelCategorizations?.[0].name || '', isBuiltIn = false } =
    draftCategorization || {};

  const existingCategorizationName =
    mappingBuiltIn.get(name)?.builtIn ||
    mapping.get(name) ||
    draftCategorization?.existingCategorization ||
    multilevelCategorizations?.[0]?.existingCategorization ||
    '';
  // if this is a built-in categorization ensure it isn't disabled
  const disabled = isBuiltIn && !(isUniformatEnabled && isMasterformatEnabled);
  const nameCategorizationProject = mapping.get(name);
  const existingCategorization = categorizationsProject.find(
    (p) => p.name === existingCategorizationName
  );

  // get all missing categories if this draft is mapped to an existing categorization
  // if we are mapping multiple categories to UF / MF then include all errors from all levels
  const [errorsMap, hasErrors, hasResolvedErrors, totalResolvedMatches] = getErrorsMap(
    draftCategorizations,
    mapping,
    mappingBuiltIn,
    existingCategorizationName,
    draftCategorization,
    multilevelCategorizations
  );

  const sendAnalytics = useSendAnalytics();

  const mappedBuiltIn = mappingBuiltIn.get(name);
  const displayName = removeYear(mappedBuiltIn?.builtIn || '', true, false) || name;
  const isMappedToBuiltin = !!mappedBuiltIn;

  const tooltipValues = [];
  if (draftCategorization?.categoryUpdates?.newCategories.length)
    tooltipValues.push(
      ` ${draftCategorization.categoryUpdates?.newCategories.length} Added to Categorization`
    );
  if (draftCategorization?.categoryUpdates?.categoryReplacements.length)
    tooltipValues.push(
      ` ${draftCategorization.categoryUpdates?.categoryReplacements.length} Replaced Categories`
    );

  // The filter keeps allows us to keep the same behavior where we only allow 1 column to map to 1 categorization level
  const selectableLines = () => {
    return categorizationsProject.filter((c) => {
      let count = 0;
      const { isMulti, levels } = isMultilevelCategorization(categorizationsProject, c);
      if (levels > 1) {
        mapping.forEach((value, key) => {
          // we need to make sure that the count is only of columns that weren't excluded in the prior screen
          if (value === c.name && draftCategorizations.find((dc) => dc.name === key)?.include)
            count += 1;
        });
      }
      return (
        // handles mapping a single column to a single or multi-level categorization
        (!multilevelCategorizations &&
          ((!isMulti && count <= c.levels) || (isMulti && count < c.levels))) ||
        // handles mapping multiple columns to a multi-level categorization
        (multilevelCategorizations &&
          (mapping.get(multilevelCategorizations?.[0]?.name || '') === c.name ||
            multilevelCategorizations.length <= c.levels - count))
      );
    });
  };

  const unlink = () => {
    const newMap = new Map(mapping);
    const draftCopy = [...draftCategorizations];
    if (multilevelCategorizations) {
      multilevelCategorizations.forEach((categorization, index) => {
        const update = draftCopy.find((dc) => dc.name === categorization.name);
        if (update) {
          update.level = 1;
        }
        if (index !== 0 && update) {
          newMap.delete(categorization.name);
          update.categoryUpdates = {
            newCategories: [],
            categoryReplacements: [],
          };
          // set the next two lines so that the (new) tag displays
          update.existingCategorization = '';
          update.isDraft = true;
        }
      });
      setMapping(newMap, draftCopy);
    }
  };

  return (
    <div key={name} className={classes.chipPadding} data-cy="categorization-line">
      <div className={classes.horizontal}>
        {multilevelCategorizations && multilevelCategorizations.length > 0 ? (
          <div className={`${classes.horizontal} ${classes.verticalStack}`}>
            <div className={classes.draftCategorization}>
              <div className={classes.iconPlacement}>
                <IconMenuIcon
                  cy="icon-link"
                  handleClick={() => unlink()}
                  icon={<InsertLink />}
                  iconButtonClassName={classes.iconLink}
                  variant={SelectVariants.MENU}
                />
              </div>
              {multilevelCategorizations.map((categorization) => (
                <div key={categorization.name}>
                  <Typography
                    className={`${classes.categorizationName} ${classes.overflowEllipsis}`}
                  >
                    {`${categorization.name}`}
                  </Typography>
                  <Typography className={`${classes.categoryPreview} ${classes.overflowEllipsis}`}>
                    {categorization.categoryPreview
                      ? `Preview: ${categorization.categoryPreview}`
                      : ''}
                  </Typography>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div className={classes.draftCategorization}>
            <Typography className={`${classes.categorizationName} ${classes.overflowEllipsis}`}>
              {displayName}
            </Typography>
            <Typography className={`${classes.categoryPreview} ${classes.overflowEllipsis}`}>
              {draftCategorization?.categoryPreview
                ? `Preview: ${draftCategorization.categoryPreview}`
                : ' '}
            </Typography>
          </div>
        )}

        <ArrowRightAltIcon className={classes.arrow} />
        {!isMappedToBuiltin ? (
          <div>
            <CategorizationSelect
              categorizationsProject={selectableLines()}
              disabled={disabled}
              draftCategorizations={draftCategorizations}
              isBuiltIn={isBuiltIn}
              mapping={mapping}
              name={nameCategorizationProject}
              nameNew={name}
              newCategorizationMap={newCategorizationMap}
              updateMap={(n?: string) =>
                updateMap(
                  (() =>
                    multilevelCategorizations?.length
                      ? multilevelCategorizations.map((c) => c.name)
                      : [name])(),
                  n,
                  draftCategorizations,
                  mapping,
                  categorizationsProject,
                  setMapping,
                  sendAnalytics
                )
              }
            />
          </div>
        ) : (
          <div className={classes.selectPlacehodler}>
            <Typography className={classes.selectPlacehodlerText}>
              Mapped in previous step
            </Typography>
          </div>
        )}
        {existingCategorization && hasErrors ? (
          <div className={classes.matching}>
            <div className={classes.select}>
              <CategorizationMatching
                categorizationsProject={existingCategorization}
                draftCategorizations={multilevelCategorizations || [draftCategorization]}
                errorsMap={errorsMap}
                importEstimateKey={importEstimateKey}
                importEstimateVar={importEstimateVar}
                totalResolvedMatches={totalResolvedMatches}
              />
            </div>
            {hasResolvedErrors && (
              <IconButton className={classes.undo}>
                <NormalTooltip
                  classes={classes} // uses the classes.tooltip style
                  title={
                    <div className={classes.tooltip}>
                      <Typography>Reset</Typography>
                      <Typography>{tooltipValues.join(', ')}</Typography>
                    </div>
                  }
                >
                  <Replay
                    className={classes.icon}
                    onClick={() => {
                      const importEstimate = importEstimateVar();
                      // undo all category updates
                      importEstimate.categorizations.forEach((c, i) => {
                        if (c.existingCategorization === existingCategorizationName) {
                          importEstimate.categorizations[i].categoryUpdates = {
                            categoryReplacements: [],
                            newCategories: [],
                          };
                        }
                      });
                      setReactiveLocal(importEstimateVar, importEstimateKey, {
                        ...importEstimate,
                      });
                    }}
                  />
                </NormalTooltip>
              </IconButton>
            )}
          </div>
        ) : (
          <div className={classes.placeholder} />
        )}
      </div>
    </div>
  );
};

export const StyledCategorizationLine = withStyles(styles)(CategorizationLine);

export default StyledCategorizationLine;
