import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';

const ExecutiveDashboardCurrentItemsBreakdownHintStyles = (theme: KomodoTheme) =>
  createStyles({
    active: {
      paddingBottom: 10,
    },
    label: {
      paddingTop: 4,
    },
    labelText: {
      ...theme.typography.h4,
    },
    project: {
      ...theme.typography.h3TableHeader,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    rowName: {
      ...theme.typography.h3,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    table: {
      borderCollapse: 'collapse',
      width: '100%',
      '& th': {
        verticalAlign: 'bottom',
        '& p': {
          ...theme.typography.h3TableHeader,
          fontSize: 12,
        },
      },
    },
    popper: {
      zIndex: 100,
    },
  });

export default ExecutiveDashboardCurrentItemsBreakdownHintStyles;
