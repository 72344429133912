import * as React from 'react';

import { withStyles } from '../../../../../theme/komodo-mui-theme';

import styles from './ParagraphCellStyles';

export default withStyles(styles)(
  ({ children, classes }: { classes: Classes<typeof styles>; children: React.ReactNode }) => (
    <div className={classes.container}>{children}</div>
  )
);
