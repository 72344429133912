import { Add } from '@material-ui/icons';

import {
  CompanyAdminEventType,
  companyAdminAnalyticsEvent,
} from '../../../../analytics/analyticsEventProperties';
import { Org } from '../../../../generated/graphql';
import useSendAnalytics from '../../../../hooks/useSendAnalytics';
import { Button } from '../../../scales';
import useAddOrgLevelMutation from '../hooks/useAddOrgLevelMutation';

import LevelInput from './LevelInput';
import OrgHeader from './OrgHeader';

type Props = {
  organization: Org;
};

export default function OrganizationDetailsHeader({ organization }: Props) {
  const sendAnalytics = useSendAnalytics();
  const addLevel = useAddOrgLevelMutation();
  const isAddLevelEnabled = organization.levels.length < 4;
  return (
    <div className="flex w-full flex-col items-start justify-between gap-1 px-4 py-3">
      <div className="flex flex-col gap-2">
        <OrgHeader
          description="Describe the levels of your organization. Name up to 4 levels representing your
          organization structure from top-down (left-right)."
          title="Organization Definition"
        />
        <div className="flex flex-col flex-wrap gap-2 pt-2 md:flex-row">
          {organization.levels.map((text, index, levels) => (
            <div key={`${index.toString()}`} className="flex">
              <LevelInput
                levelName={text}
                levelNumber={index + 1}
                levels={levels}
                levelsCount={levels.length}
                organization={organization}
              />
            </div>
          ))}
          {isAddLevelEnabled && (
            <Button
              label="Add Level"
              onClick={() => {
                addLevel(organization.id);
                sendAnalytics(
                  companyAdminAnalyticsEvent(CompanyAdminEventType.ORGANIZATIONS_ADD_LEVEL, {
                    levelNumber: organization.levels.length + 1,
                  })
                );
              }}
              startIcon={<Add />}
              type="secondary"
            />
          )}
        </div>
      </div>
    </div>
  );
}
