import { FC } from 'react';

import { Card, CardHeader, Divider, List, ListItem, Typography } from '@material-ui/core';

import { withStyles } from '../../theme/komodo-mui-theme';
import { formatCost } from '../../utilities/currency';
import JoinSelect, { SelectEntry, getEntry } from '../Select/JoinSelect/JoinSelect';

import DisplaySettingsListStyles from './DisplaySettingsListStyles';
import { useProjectSettings, useProjectTerminology, useUpdateProjectSetting } from './hooks';
import TerminologyListItem from './TerminologyListItem';

type DisplaySettingsListProps = {
  canEditCurrency: boolean;
  canEditTerminolgy: boolean;
  shouldDisplayCosts: boolean;
  classes: Classes<typeof DisplaySettingsListStyles>;
  projectID: UUID;
};

const DisplaySettingsList: FC<DisplaySettingsListProps> = ({
  canEditCurrency,
  canEditTerminolgy,
  shouldDisplayCosts,
  classes,
  projectID,
}) => {
  const projectTerminology = useProjectTerminology(projectID);
  const createTerminologyListItems = () =>
    projectTerminology &&
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
    projectTerminology.map((term: any) => (
      <TerminologyListItem
        key={term.key}
        canEdit={canEditTerminolgy}
        projectID={projectID}
        term={term}
      />
    ));

  const settings = useProjectSettings(projectID);
  const updateRounding = useUpdateProjectSetting(projectID, 'ROUNDING_PRECISION');

  const roundingEntriesIntl = [
    getEntry('-2', 'No rounding'),
    getEntry('0', formatCost(100, { showCents: false })),
    getEntry('1', formatCost(1000, { showCents: false })),
    getEntry('2', formatCost(10000, { showCents: false })),
    getEntry('3', formatCost(100000, { showCents: false })),
    getEntry('4', formatCost(1000000, { showCents: false })),
    getEntry('5', formatCost(10000000, { showCents: false })),
  ] as SelectEntry[];

  return (
    <Card className={classes.card} elevation={0} square>
      <CardHeader title="Customize Display" />
      <Divider />
      <List>
        {shouldDisplayCosts && (
          <>
            <CardHeader
              action={
                <JoinSelect
                  cySelect="selectRounding"
                  disabled={!canEditCurrency}
                  entries={roundingEntriesIntl}
                  onChange={updateRounding}
                  value={settings.ROUNDING_PRECISION.toString()}
                />
              }
              subheader="Customize the cost display numbers for all cost summaries"
              title="Rounding Display"
              titleTypographyProps={{ variant: 'title' }}
            />
            <Divider />
          </>
        )}
        <CardHeader
          subheader="Customize the terminology used across Join"
          title="Terminology"
          titleTypographyProps={{ variant: 'title' }}
        />
        <ListItem className={classes.header}>
          <Typography className={classes.nameHeading}>Name</Typography>
          <Typography className={classes.descriptionHeading}>Description</Typography>
        </ListItem>
        {createTerminologyListItems()}
      </List>
    </Card>
  );
};

export default withStyles(DisplaySettingsListStyles)(DisplaySettingsList);
