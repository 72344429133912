import { createStyles } from '@material-ui/core';

import {
  CHART_RIGHT_PADDING,
  SMALL_CHART_HEIGHT,
  SMALL_CHART_LEFT_PADDING,
} from '../../Charts/ChartsAllMilestones/ChartsAllMilestonesUtils';

import { COST_REPORT_HEIGHT, SUBHEADING_HEIGHT } from './PrintBreakdownStyles';

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
const styles = (theme: any) =>
  createStyles({
    container: {
      display: 'inline-block',
      height: SUBHEADING_HEIGHT + 24 + SMALL_CHART_HEIGHT + COST_REPORT_HEIGHT,
      margin: '16px 1% 16px 1%',
      pageBreakInside: 'avoid',
      verticalAlign: 'top',
      width: '31%',
    },
    dashboard: {
      paddingLeft: SMALL_CHART_LEFT_PADDING,
      paddingRight: CHART_RIGHT_PADDING,
    },
    charts: {
      display: 'table',
      width: '100%',
    },
    label: {
      flex: '0 0 auto',
      marginLeft: 'auto',
      marginRight: 16,
    },
    root: {
      alignItems: 'center',
      border: `0px none`,
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      height: 'auto',
      justifyContent: 'center',
      overflowX: 'visible',
      minWidth: 960,
    },
    selector: {
      flex: '0 0 300px',
    },
    subHeader: {
      alignItems: 'center',
      display: 'flex',
      paddingTop: 4,
      paddingBottom: 4,
    },
    subheading: {
      ...theme.typography.title,
      height: SUBHEADING_HEIGHT,
      paddingLeft: SMALL_CHART_LEFT_PADDING,
      paddingBottom: 4,
    },
    reportIcon: {
      paddingLeft: 8,
      paddingRight: 0,
    },
  });

export default styles;
