import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../../theme/komodo-mui-theme';

export default (theme: KomodoTheme) =>
  createStyles({
    container: { display: 'flex', gap: '20px', padding: '12px 0px' },
    info: { display: 'flex', flexDirection: 'column', gap: '8px' },
    name: { ...theme.typography.webHeading3 },
    chips: { display: 'flex', gap: '4px', marginTop: 'auto' },
    chip: {
      borderRadius: theme.border.radius,
      background: theme.palette.backgroundGrey,
      padding: '2.5px 6px',
      display: 'flex',
      gap: '2px',
      ...theme.typography.webTableText,
    },
    chip_icon: {
      display: 'block',
      width: 16,
      height: 16,
    },
    linkText: {
      color: theme.palette.brand.middleBlue,
      '&:link': {
        textDecoration: 'none',
      },
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  });
