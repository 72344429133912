import { useEffect } from 'react';

import { useApolloClient } from '@apollo/client';

import { readItemAttachments, writeItemAttachments } from './useItemAttachmentsQuery';
import { uploadingAssetToAsset } from './useOptimisticUploadAssets';

const useOptimisticItemUploadAssets = (
  itemID: UUID,
  uploadingAssets: UploadingAsset[],
  isAttach: boolean
) => {
  const client = useApolloClient();
  useEffect(() => {
    if (!isAttach) return;
    if (!uploadingAssets.length) return;
    const assets = readItemAttachments(client, itemID);
    const uploading = uploadingAssets.map(uploadingAssetToAsset);
    const uploadingFiltered = uploading.filter((ua) => assets.every((a) => a.id !== ua.id));
    if (!uploadingFiltered.length) return;
    const optimistic = [...assets, ...uploadingFiltered];
    writeItemAttachments(client, optimistic, itemID);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO CT-566: Fix this pls :)
  }, [uploadingAssets]);
};

export default useOptimisticItemUploadAssets;
