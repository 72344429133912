import { ReactNode, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { InsightsSort } from '../../../generated/graphql';
import { formatCost } from '../../../utilities/currency';
import { getTodayUtcNoon } from '../../../utilities/dates';
import { formatNumber } from '../../../utilities/string';
import { SortManager, Table, Tooltip } from '../../scales';
import CostLegend from '../Charts/Legends/CostLegend';
import XAxis from '../Charts/XAxis';
import ZoomTimeline from '../Charts/ZoomTimeline';
import ProjectCost from '../ProjectBabyCharts/ProjectCost';
import ProjectTableTooltip from '../ToolTips/ProjectTableTooltip';
import { InsightsProject, InsightsTabId } from '../types';

import ProjectTableEntry from './ProjectTableEntry';
import StickyBottomRow from './StickyBottomRow';
import { generateProjectPath, getProjectsRange } from './utils';
import useSendAnalytics from '../../../hooks/useSendAnalytics';
import { InsightsEvent, insightsEvent } from '../../../analytics/analyticsEventProperties';

type Props = {
  counts: { current: number; total: number };
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  loading: boolean;
  onFetchMore: () => void;
  projects: InsightsProject[];
  sortManager: SortManager;
  sortState: InsightsSort;
  stickyHeader: ReactNode;
};

export default function ProjectsCost(props: Props) {
  const sendAnalytics = useSendAnalytics();
  const navigate = useNavigate();
  const [startDate, endDate] = getProjectsRange(getTodayUtcNoon());
  const [start, setStart] = useState(startDate);
  const [end, setEnd] = useState(endDate);
  const setStartEnd = ([start, end]: string[]) => {
    setStart(new Date(start));
    setEnd(new Date(end));
  };
  const onZoom = (domain: string[]) => {
    setStartEnd(domain);
  };

  const stickyFooterEntry = StickyBottomRow({
    columnsCount: 5,
    component: (
      <ZoomTimeline
        onZoom={onZoom}
        projects={props.projects}
        zoom={[start.toISOString(), end.toISOString()]}
      />
    ),
  });

  if (!props.projects) return null;

  const chartsHeader = (
    <div className="w-full">
      <div className="flow-root h-8 w-full items-center">
        <div className="float-left type-table-header">Over timeline</div>
        <div className="float-right -mt-2">
          <CostLegend />
        </div>
      </div>
      <div className="-mb-[9px]">
        <XAxis placement="top" totalRange={[start.toISOString(), end.toISOString()]} />
      </div>
    </div>
  );

  return (
    <>
      <Table
        columnWidths={[
          'minmax(250px, 3fr)',
          'minmax(125px, 0.5fr)',
          'minmax(125px, 0.5fr)',
          'minmax(125px, 0.5fr)',
          'minmax(600px, 6fr)',
        ]}
        entries={props.projects.map((project) => {
          const key = project.id;
          return [
            {
              key,
              component: (
                <Tooltip
                  content={
                    <div>
                      <ProjectTableTooltip project={project} tabType={InsightsTabId.Items} />
                    </div>
                  }
                  onOpen={() =>
                    sendAnalytics(
                      insightsEvent(InsightsEvent.SUMMARY_PROJECT_TOOLTIP_VIEW, {
                        view: InsightsTabId.Costs,
                        projectID: project.id,
                        projectName: project.name,
                      })
                    )
                  }
                >
                  <div>
                    <ProjectTableEntry
                      hasAlert={project.activeAlerts.length > 0}
                      onClick={() =>
                        sendAnalytics(
                          insightsEvent(InsightsEvent.SUMMARY_PROJECT_CLICK, {
                            view: InsightsTabId.Costs,
                            source: 'link',
                          })
                        )
                      }
                      project={project}
                      sortState={props.sortState}
                      to={generateProjectPath(project.id)}
                    />
                  </div>
                </Tooltip>
              ),
            },
            {
              key,
              centered: true,
              component: (
                <div>
                  {project.runningTotal && project.runningTotal > 0
                    ? formatCost(project.runningTotal, { short: true })
                    : 'No estimate'}
                </div>
              ),
            },
            {
              key,
              centered: true,
              component: (
                <div className="text-entities-milestone">
                  {project.budget && project.budget > 0
                    ? formatCost(project.budget, { short: true })
                    : 'No budget'}
                </div>
              ),
            },
            {
              key,
              centered: true,
              component: (
                <div className="flex flex-col text-entities-gap">
                  <div className="type-body1">
                    {project.budget && project.budget > 0
                      ? formatCost((project.budget || 0) - (project.runningTotal || 0), {
                          short: true,
                        })
                      : '-'}
                  </div>
                  {project.budget &&
                    project.budget > 0 &&
                    project.runningTotal &&
                    project.runningTotal > 0 && (
                      <div className="type-body2">
                        {`${(
                          ((project.budget - project.runningTotal) / project.budget) *
                          100
                        ).toFixed()}%`}
                      </div>
                    )}
                </div>
              ),
            },
            {
              key,
              component: (
                <ProjectCost
                  end={end}
                  onClick={() => {
                    navigate(generateProjectPath(project.id, InsightsTabId.Costs));
                    sendAnalytics(
                      insightsEvent(InsightsEvent.SUMMARY_PROJECT_CLICK, {
                        view: InsightsTabId.Costs,
                        source: 'chart',
                      })
                    );
                  }}
                  project={project}
                  start={start}
                />
              ),
            },
          ];
        })}
        hasParentScroll
        headerContent={[
          {
            component: (
              <div className="h-12 type-table-header">{`Projects (${formatNumber(props.counts.current)} of ${formatNumber(
                props.counts.total
              )})`}</div>
            ),
            key: 'projects',
          },
          {
            component: <div className="h-12 type-table-header">Running Total</div>,
            key: 'Running Total',
          },
          {
            component: <div className="h-12 text-entities-milestone type-table-header">Budget</div>,
            key: 'Budget',
          },
          {
            component: <div className="h-12 text-entities-gap type-table-header">Gap</div>,
            key: 'Gap',
          },
          { component: chartsHeader, key: 'cost' },
        ]}
        isLoading={props.loading}
        onNeedMoreData={props.onFetchMore}
        sortManager={props.sortManager}
        stickyFooterEntry={stickyFooterEntry}
        stickyHeader={props.stickyHeader}
      />
    </>
  );
}
