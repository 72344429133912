import { FC, useRef } from 'react';

import { Typography } from '@material-ui/core';
import { DeleteOutline, Info } from '@material-ui/icons';

import { SELECT_DIFFERENT_FILE, SELECT_FILE } from '../../../../constants';
import { withStyles } from '../../../../theme/komodo-mui-theme';
import { Button } from '../../../scales';

import AssetsUploadAreaControlsStyles from './AssetsUploadAreaControlsStyles';

type AssetsUploadAreaControlsProps = {
  addFiles: (files: FileList | null) => void;
  asset: Asset | null;
  classes: Classes<typeof AssetsUploadAreaControlsStyles>;
  removeAsset: () => void;
};

const AssetsUploadAreaControls: FC<AssetsUploadAreaControlsProps> = ({
  addFiles,
  asset,
  classes,
  removeAsset,
}) => {
  // Handle file upload window
  let fileUpload = useRef(null);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  const showFileUpload = (fileUpload: any) => {
    if (fileUpload) {
      fileUpload.click();
    }
  };

  return (
    <div>
      <Typography className={classes.h4}>
        Drag and drop a file into this area, or upload an image from your computer.
      </Typography>
      <div className={classes.buttonContainer}>
        {asset && (
          <Button
            label="Delete"
            onClick={removeAsset}
            startIcon={<DeleteOutline />}
            type="destructive"
          />
        )}
        <Button
          label={asset ? SELECT_DIFFERENT_FILE : SELECT_FILE}
          onClick={() => showFileUpload(fileUpload)}
          startIcon={
            <input
              // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
              ref={(input: any) => {
                fileUpload = input;
              }}
              className={classes.hidden}
              id="upload-file"
              onChange={(evt) => {
                addFiles(evt.target.files);
              }}
              type="file"
            />
          }
          type={asset ? 'secondary' : 'primary'}
        />
      </div>
      <div className={classes.hintContainer}>
        <Info className={classes.hintIcon} />
        <Typography className={`${classes.h4} ${classes.hintText}`}>
          Files accepted: png, jpg, svg, or webp. We recommend a square crop with a min of 240px by
          240px
        </Typography>
      </div>
    </div>
  );
};

export default withStyles(AssetsUploadAreaControlsStyles)(AssetsUploadAreaControls);
