import {
  ProjectsFilterOptionsQuery,
  ProjectsFilterOptionsQueryVariables,
} from '../../../generated/graphql';
import { useQuery } from '../../../hooks/useMountAwareQuery';

import { projectsFilterOptionsQuery } from './queries';

// Deprecated: please use useSearchProjectsFilterOptions instead
// this exists in order to support Join Users' ability to toggle between companies in Insights and Admin tabs
// nowadays company impersonation is the preferred way to preview what other companies can see
function useDeprecatedProjectsFilterOptionsQuery(
  includeCompanyProjects = false,
  includeUserProjects = true,
  companyID?: UUID
) {
  return useQuery<ProjectsFilterOptionsQuery, ProjectsFilterOptionsQueryVariables>(
    projectsFilterOptionsQuery,
    {
      variables: {
        includeCompanyProjects,
        includeUserProjects,
        companyID,
      },
      nextFetchPolicy: 'cache-first',
    }
  );
}

export default useDeprecatedProjectsFilterOptionsQuery;
