import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';

const styles = (theme: KomodoTheme) =>
  createStyles({
    templateLink: {
      ...theme.typography.webSmallLink,
      display: 'flex',
      alignItems: 'center',
      gap: '4px',
    },
    templateLinkIcon: {
      fontSize: 16,
      transform: 'rotate(180deg)',
    },
  });

export default styles;
