import { FC, useMemo } from 'react';

import { UserReportType } from '../../../../generated/graphql';
import { MapUserReportTypeToConfig } from '../../../ReportsTab/config';
import SelectValue from '../../../Select/SelectValue/SelectValue';

type DialogsReportsTypeSelectorProps = {
  userReport: UserReportInput;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  setUserReport: (report: any) => void;
  allowableReportTypes: UserReportType[];
};

type UserReportTypeName = {
  name: string;
  type: UserReportType;
};

const userReportTypeNames = Object.entries(MapUserReportTypeToConfig)
  // we don't need an entry for NONE even though it's in the map
  .filter(([key]) => key !== UserReportType.NONE)
  .map((entry) => ({
    type: entry[0],
    name: entry[1]?.typeDisplayName,
  })) as UserReportTypeName[];

const DialogsReportsTypeSelector: FC<DialogsReportsTypeSelectorProps> = ({
  userReport,
  setUserReport,
  allowableReportTypes,
}) => {
  const allowableSettings = useMemo(
    () => userReportTypeNames.filter((s) => allowableReportTypes.find((t) => t === s.type)),
    [allowableReportTypes]
  );
  const currentSetting = useMemo(() => {
    const value = userReportTypeNames.find((s) => s.type === userReport.reportType);
    return value ? value.name : '';
  }, [userReport]);

  const values = allowableSettings.map(({ name }) => name);
  return (
    <SelectValue
      cySelect="select-reportType"
      hidePrint={false}
      onChange={(v: string | null) => {
        const newType = userReportTypeNames.find((r) => r.name === v);
        setUserReport({
          ...userReport,
          reportType: newType ? newType.type : UserReportType.NONE,
        });
      }}
      value={currentSetting}
      values={values}
    />
  );
};

export default DialogsReportsTypeSelector;
