import { useEffectOnce } from 'react-use';

const VALUE_POSTFIX = '_VALUE';
const getValueKey = (key: string) => `${key}${VALUE_POSTFIX}`;

export const triggerTabEvent = (key: string, value?: string) => {
  const { localStorage } = window;
  const valueKey = getValueKey(key);
  if (value) localStorage.setItem(valueKey, value);
  localStorage.setItem(key, Date.now().toString());
};

const useBrowserTabUpdate = (
  key: string,
  onTabEvent?: (value?: string | undefined | null) => void
) => {
  const { localStorage } = window;
  const valueKey = getValueKey(key);

  useEffectOnce(() =>
    window.addEventListener('storage', (event) => {
      if (event.storageArea !== localStorage) return;
      if (event.key === key && onTabEvent) {
        const value = localStorage.getItem(valueKey);
        onTabEvent(value);
        localStorage.removeItem(key);
        localStorage.removeItem(valueKey);
      }
    })
  );
};

export default useBrowserTabUpdate;
