import { FC } from 'react';

import { isString } from '../../../utilities/string';
import { Lightbulb } from '../../Icons/Lightbulb';

type ShadedHelpTipProps = {
  tip: React.ReactNode;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  shiftLeft?: boolean;
};

const ShadedHelpTip: FC<ShadedHelpTipProps> = ({ tip, shiftLeft }) => {
  const tipContent = isString(tip) ? (
    <div className="type-body3">
      <b>Tip: </b> {` ${tip}`}
    </div>
  ) : (
    tip
  );

  return (
    <div
      className={`flex items-center gap-2 rounded bg-background-2 p-2.5 ${
        shiftLeft ? 'ml-auto' : 'ml-2.5 mr-auto'
      } `}
    >
      <Lightbulb className="h-4 w-4" />
      {tipContent}
    </div>
  );
};

export default ShadedHelpTip;
