import { NULL_ID } from '../../constants';
import {
  GetProjectThumbnailQuery,
  GetProjectThumbnailQueryVariables,
} from '../../generated/graphql';
import { useQuery } from '../../hooks/useMountAwareQuery';
import { MountPolicy } from '../../hooks/usePolicyOnFirstMount';

import { getProjectThumbnailQuery } from './hooks/queries';

export default function useGetProjectThumbnailQuery(
  projectID: UUID | null | undefined,
  onCompleted?: (data: GetProjectThumbnailQuery) => void
) {
  return useQuery<GetProjectThumbnailQuery, GetProjectThumbnailQueryVariables>(
    getProjectThumbnailQuery,
    {
      skip: !projectID || projectID === NULL_ID,
      variables: {
        projectID,
      },
      onCompleted,
    },
    MountPolicy.SKIP_ON_MOUNT
  );
}
