import { createStyles } from '@material-ui/core/styles';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';

// CONSTANTS
const generalSize = '8px';
const hoverSize = '10px';

const ItemsOptionChipSquareStyles = (theme: KomodoTheme) => {
  const { accepted, notapplicable, incorporated, pending, rejected, shadedGrey } = theme.palette;
  return createStyles({
    accepted: {
      backgroundColor: accepted,
    },
    button: {
      background: 'transparent',
      border: 0,
      cursor: 'pointer',
      padding: 0,
    },
    notapplicable: {
      backgroundColor: notapplicable,
    },
    notchosen: {
      backgroundColor: shadedGrey,
    },
    outerContainer: {
      borderRadius: '1px',
      height: generalSize,
      margin: '1px',
      padding: '1px',
      transition: '.2s',
      width: generalSize,
      '&:hover': {
        height: hoverSize,
        margin: 0,
        width: hoverSize,
      },
      '&>a': {
        width: hoverSize,
      },
    },
    incorporated: {
      backgroundColor: incorporated,
    },
    pending: {
      backgroundColor: pending,
    },
    rejected: {
      backgroundColor: rejected,
    },
  });
};

export default ItemsOptionChipSquareStyles;
