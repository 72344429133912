import { useMemo } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
function useMemoWrapper<F extends (...args: any[]) => ReturnType<F>>(
  func: F,
  ...args: Parameters<F>
) {
  // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO CT-566: Fix this pls :)
  return useMemo<ReturnType<F>>(() => func(...args), [...args]);
}

export default useMemoWrapper;
