import { isUUID } from 'validator';

import { AssetQuery, AssetQueryVariables } from '../../../generated/graphql';
import { useQuery } from '../../../hooks/useMountAwareQuery';

import { assetQuery } from './queries';

// used in signup containers, frame titlebar, sidebar
export default function useAssetQuery(
  assetID: UUID | null | undefined,
  onCompleted?: (data: AssetQuery) => void
) {
  return useQuery<AssetQuery, AssetQueryVariables>(assetQuery, {
    variables: { assetID },
    skip: !assetID || !isUUID(assetID),
    context: { batch: true },
    onCompleted,
  });
}
