import { commonCommentFields, gql } from '../../../api/graphqlFragments';

export const createItemCommentMutation = gql`
  mutation createItemComment($projectID: UUID!, $comment: ItemCommentInput, $costMode: CostMode) {
    commentOnItem(projectID: $projectID, comment: $comment, costMode: $costMode) {
      ...commonCommentFields
    }
  }
  ${commonCommentFields}
`;
