import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';

export default (theme: KomodoTheme) =>
  createStyles({
    categoriesNotFound: {
      background: theme.palette.backgroundWhite,
      overflow: 'hidden',
      padding: 8.5,
    },
    categoryBack: {
      borderBottom: theme.border.line,
      justifyContent: 'flex-start',
    },
    categoryBulkToggle: {
      alignItems: 'center',
      background: theme.palette.backgroundWhite,
      borderBottom: theme.border.line,
      cursor: 'pointer',
      display: 'flex',
      fontSize: 14,
      fontWeight: 300,
      height: 40,
      justifyContent: 'flex-start',
      padding: '0 12px',
      textAlign: 'left',
      '& > span > svg': {
        width: 14,
      },
    },
    categoryOption: {
      alignItems: 'center',
      background: theme.palette.backgroundWhite,
      cursor: 'pointer',
      display: 'flex',
      fontSize: 14,
      fontWeight: 300,
      justifyContent: 'space-between',
      textAlign: 'left',
      '& > span > svg': {
        width: 14,
      },
    },
    categoryOptionTitle: {
      alignItems: 'center',
      display: 'flex',
      height: '100%',
      justifyContent: 'flex-start',
      lineHeight: '16px',
      padding: '12px',
      width: '100%',
    },
    categoryOptionTitleFirst: {
      height: '100%',
      padding: '12px 0 12px 0',
      width: '100%',
    },
    categoryHighlighted: {
      background: `${theme.palette.hoverBackground} !important`,
    },
    categoryUncategorized: {
      borderBottom: theme.border.line,
      lineHeight: '16px',
      padding: '13px 18px',
    },
    clearContentOption: {
      justifyContent: 'space-between',
    },
    checkboxRoot: {
      height: 20,
      marginRight: 12,
      width: 20,
    },
    clear: {
      background: 'none',
      border: 'none',
      fontSize: 14,
      fontWeight: 300,
      textDecoration: 'underline',
    },
    containerDropdownList: {
      maxHeight: 265,
      overflowX: 'hidden',
    },
    containerInput: {
      alignItems: 'center',
      backgroundColor: theme.palette.backgroundWhite,
      display: 'flex',
      fontFamily: theme.typography.fontFamily,
      justifyContent: 'flex-start',
      '& > svg': {
        paddingLeft: 5,
        width: 20,
      },
    },
    containerOuter: {
      backgroundColor: theme.palette.backgroundWhite,
      boxSizing: 'border-box',
      filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
      fontFamily: theme.typography.fontFamily,
      overflow: 'hidden',
      position: 'relative',
      width: '100%',
    },
    disabledOption: {
      background: theme.palette.backgroundWhite,
      border: 0,
      color: theme.palette.disabledGrey,
      cursor: 'pointer',
      display: 'block',
      fontSize: 14,
      fontWeight: 300,
      padding: '9px 13px',
      textAlign: 'left',
    },
    iconBack: {
      height: 14,
    },
    iconBackButton: {
      height: 32,
      marginLeft: 12,
      marginRight: 10,
      width: 32,
      '&:hover': {
        backgroundColor: theme.palette.hoverBackground,
      },
      '& > span > svg': {
        position: 'relative',
        right: '-2px',
        width: 14,
      },
    },
    iconButton: {
      height: 32,
      marginLeft: 12,
      marginRight: 10,
      width: 32,
      '&:hover': {
        backgroundColor: theme.palette.hoverBackground,
      },
      '& > span > svg': {
        width: 14,
      },
    },
    iconDropUpArrowButton: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
      padding: '6px',
    },
    iconSearch: {
      position: 'absolute',
    },
    input: {
      border: theme.border.invisible,
      borderRadius: 0,
      boxSizing: 'border-box',
      fontFamily: theme.typography.fontFamily,
      fontSize: 14,
      fontWeight: 300,
      padding: '8px 13px 8px 32px',
      width: '100%',
      '&:focus': {
        border: theme.border.focus,
        outline: theme.border.focus,
      },
    },
    list: {
      borderTop: theme.border.line,
      marginBlockEnd: 0,
      marginBlockStart: 0,
      minHeight: 100,
      overflowX: 'hidden',
      overflowY: 'scroll',
      paddingInlineStart: 0,
    },
    listEmpty: {
      overflowY: 'hidden',
    },
    listNoScroll: {
      borderTop: theme.border.line,
      marginBlockEnd: 0,
      marginBlockStart: 0,
      paddingInlineStart: 0,
    },
    underline: {
      textDecoration: 'underline',
    },
  });
