import { Column, GridData } from '../types';

export const closeErrorHeaders = (data: GridData) => {
  const { columns } = data;
  columns.forEach((column: Column) => {
    if (column.isErrorsMode) {
      // eslint-disable-next-line no-param-reassign
      column.isErrorsMode = false;
      if (column.update) column.update();
    }
  });
};
