import { ComponentProps } from 'react';
import Thumbnail from './Thumbnail';

type Props = Omit<ComponentProps<typeof Thumbnail>, 'shouldCrop'>;

/**
 * A thumbnail component explicitly for displaying a company logo. This is the
 * only current thumbnail that does not crop the image so this component exists
 * to make the process of rendering a company logo a bit more on-rails and
 * easier to refactor down the line.
 *
 * It takes all the same props as a standard `Thumbnail` component except the
 * `shouldCrop` prop.
 */
export default function CompanyLogoThumbnail(props: Props) {
  return <Thumbnail {...props} shouldCrop={false} />;
}
