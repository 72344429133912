import { Add } from '@material-ui/icons';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import {
  ProgramReportingAnalyticsEvent,
  ProgramReportingTypes,
} from '../../../analytics/analyticsEventProperties';
import { NS_PROGRAM_REPORTING } from '../../../features';
import { useHasFeature } from '../../../hooks/useHasFeature';
import useSendAnalytics from '../../../hooks/useSendAnalytics';
import { Button } from '../../scales';
import useHasModuleEntitlement from '../../../hooks/useHasModuleEntitlement';
import { PROJECT_CREATION } from '../../../moduleEntitlementFlagsList';
import { useProgramEligibleProjectsQuery } from '../ProgramDialog/hooks/useProgramEligibleProjectsQuery';
import ProgramDialog from '../ProgramDialog/ProgramDialog';
import { generateSharedPath } from '../../../utilities/routes/links';
import { RouteKeys } from '../../../routes/paths';

export default function NewProgramButton() {
  const hasProgramReportingFeature = useHasFeature(NS_PROGRAM_REPORTING);
  const canCreateProject = useHasModuleEntitlement(PROJECT_CREATION);
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const potentialProjects = useProgramEligibleProjectsQuery()?.data?.programEligibleProjects;
  // useProgramEligibleProjectsQuery only returns projects the user is an admin on
  // you must be an admin on at least two projects to create a program
  const canCreateProgram = (potentialProjects?.length ?? 0) >= 2;

  const sendAnalytics = useSendAnalytics();

  if (!hasProgramReportingFeature || !canCreateProject || !canCreateProgram) return null;

  return (
    <>
      <Button
        endIcon={<Add />}
        label="New Program"
        onClick={() => {
          setIsOpen(true);
          sendAnalytics(ProgramReportingAnalyticsEvent(ProgramReportingTypes.OPEN_PROGRAM_DIALOG));
        }}
        type="secondary"
      />
      {isOpen && (
        <ProgramDialog
          onClose={() => setIsOpen(false)}
          onCompleted={(programID) =>
            navigate(generateSharedPath(RouteKeys.PROGRAM_DASHBOARD, { programID }))
          }
        />
      )}
    </>
  );
}
