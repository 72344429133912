import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';
import { CHART_PADDING } from '../ExecutiveDashboardUtils';

const ExecutiveDashboardProjectsMovementStyles = (theme: KomodoTheme) =>
  createStyles({
    dataCell: {
      position: 'relative',
      verticalAlign: 'top',
      '& p': {
        ...theme.typography.h3Label,
        textAlign: 'right',
      },
    },
    dataHeader: {
      textAlign: 'right',
    },
    flexContainer: {
      display: 'flex',
      paddingLeft: CHART_PADDING,
      paddingRight: CHART_PADDING,
      paddingTop: CHART_PADDING,
      position: 'relative',
    },
    headerText: {
      ...theme.typography.h3TableHeader,
    },
    iconCell: {
      verticalAlign: 'middle',
    },
    lastUpdated: {
      color: theme.palette.disabledGrey,
      position: 'absolute',
      right: 0,
      whiteSpace: 'nowrap',
    },
    linkText: {
      ...theme.typography.h3TableHeader,
      fontFamily: theme.fontFamily,
      color: theme.palette.primaryBlue,
      '&:link': {
        textDecoration: 'none',
      },
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    milestoneName: {
      color: theme.palette.disabledGrey,
      width: '130%',
    },
    nameCell: {
      maxWidth: 0,
      verticalAlign: 'top',
      width: '40%',
      '& > *': {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },
    },
    nameHeader: {
      textAlign: 'left',
    },
    nameText: {
      display: 'block',
      fontWeight: 700,
      width: '130%',
    },
    noPermission: {
      color: theme.palette.shadedGrey,
    },
    row: {
      borderBottom: `solid 1px ${theme.palette.joinGrey400}`,
    },
    rowDisabled: {
      color: theme.palette.disabledGrey,
    },
    sectionCell: {
      borderColor: theme.palette.disabledGrey,
      borderStyle: 'solid',
      borderWidth: '1px 0',
      '& p': {
        ...theme.typography.h3Label,
        lineHeight: theme.typography.h3Label.fontSize,
        padding: '4px 0px',
      },
    },
    table: {
      borderCollapse: 'collapse',
      width: '100%',
      '& th': {
        verticalAlign: 'bottom',
        '& p': {
          ...theme.typography.h3TableHeader,
        },
      },
    },
  });

export default ExecutiveDashboardProjectsMovementStyles;
