import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { ROUTE_VIEW } from '../actions/actionTypes';
import {
  AnalyticsEvent,
  EventProperties,
  SendAnalyticsFn,
  analyticsEvent,
} from '../analytics/analyticsEventProperties';
import { usePreviewSettingsVar } from '../api/apollo/reactiveVars';
import { NULL_ID } from '../constants';
import { AnalyticsProperties, AnalyticsPropertiesQuery, CostDisplay } from '../generated/graphql';
import { useCostMode } from '../utilities/costMode';
import { getPageView } from '../utilities/getPageView';
import { getProjectIdFromUrl } from '../utilities/url';
import { useAuth0Properties } from '../utilities/userProfile';
import { replaceUUIDs } from '../utilities/uuid';

import useAnalyticsPropertiesQuery from './useAnalyticsPropertiesQuery';

const CLEAR_TYPENAME = { __typename: undefined } as const;

export type { EventProperties };
export type { AnalyticsEvent };
export type { SendAnalyticsFn };

// useAnalyticsProperties - Hook that queries Analytics Properties
const useAnalyticsProperties = () => {
  const projectId = getProjectIdFromUrl();
  const preview = usePreviewSettingsVar();
  // if lazy, use lazy query with onCompleted
  return useAnalyticsPropertiesQuery(projectId || undefined, preview.roleID, preview.userID);
};

//  accumulateProperties - Helper for gathering all analytics event properties
const accumulateProperties = (
  eventProperties: EventProperties | undefined,
  analyticsProperties: AnalyticsPropertiesQuery['analyticsProperties'],
  costMode: CostMode
) => {
  const { projectProperties, permissionsProperties, groupProperties } = analyticsProperties;
  const isProjectAnalytics =
    projectProperties?.projectId && projectProperties?.projectId !== NULL_ID;
  return {
    ...eventProperties,
    ...permissionsProperties,
    ...projectProperties,
    ...groupProperties,
    ...CLEAR_TYPENAME,
    screenWidth: window?.innerWidth,
    screenHeight: window?.innerHeight,
    costDisplay: isProjectAnalytics ? costMode.costDisplay : undefined,
    markupMode:
      isProjectAnalytics && costMode.costDisplay === CostDisplay.SHOW_COSTS
        ? costMode.markupMode
        : undefined,
  };
};

const track = (
  analyticsEvent: AnalyticsEvent,
  analyticsProperties: AnalyticsProperties,
  costMode: CostMode
) => {
  const { type, eventProperties } = analyticsEvent;

  const properties = accumulateProperties(eventProperties, analyticsProperties, costMode);

  window?.analytics?.track(type, properties);
};

// useSendAnalyticsEventHook - Hook for our most common sendAnalytics Call + Properties
const useSendAnalytics: () => SendAnalyticsFn = () => {
  const { analytics } = window || {};
  // ID user first ...
  const { email } = useAuth0Properties();
  if (analytics && typeof analytics.user === 'function') analytics.user().id(email);

  const costMode = useCostMode();
  // Then, return the callback with analytics props
  const { data, refetch } = useAnalyticsProperties();

  const sendAnalytics = useCallback(
    (analyticsEvent: AnalyticsEvent) => {
      if (data) {
        track(analyticsEvent, data.analyticsProperties, costMode);
      } else if (refetch) {
        refetch().then((refetchedData) =>
          track(analyticsEvent, refetchedData.data.analyticsProperties, costMode)
        );
      }
    },
    [costMode, data, refetch]
  );
  return sendAnalytics;
};

// EXPORTS
export default useSendAnalytics;

// useAnalyticsIdentifyUser - Hook for User Identify on Changes
export const useAnalyticsIdentifyUser = () => {
  const [isIdentified, setIsIdentified] = useState(false);
  const { analytics } = window || {};
  const auth0Properties = useAuth0Properties();
  const { data } = useAnalyticsProperties();
  const { analyticsProperties } = data || {};
  const { companyProperties, userProperties, projectProperties } = analyticsProperties || {};
  const { projectId } = projectProperties || {};
  const { companyId } = companyProperties || {};

  useEffect(() => {
    if (analytics && projectId) analytics.group(projectId);
  }, [analytics, projectId]);

  useEffect(() => {
    if (analytics && companyId) analytics.group(companyId);
  }, [analytics, companyId]);

  useEffect(() => {
    if (!isIdentified && analytics && userProperties && companyProperties && auth0Properties) {
      const userTraits = {
        ...auth0Properties,
        ...companyProperties,
        ...userProperties,
        ...CLEAR_TYPENAME,
      };
      analytics.identify(userTraits);
      setIsIdentified(true);
    }
  }, [analytics, auth0Properties, userProperties, companyProperties, isIdentified]);
};

// useAnalyticsLocationViewEvent - Hook for reacting to location change with an event
export const useAnalyticsLocationViewEvent = () => {
  const sendAnalytics = useSendAnalytics();

  const location = useLocation();
  const { pathname } = location;

  useEffect(() => {
    const routePath = pathname.split('/').map(replaceUUIDs).join('/');

    sendAnalytics(analyticsEvent(ROUTE_VIEW, { route: routePath }));

    // DEPRECATED - Consider not using these analytics going forward since they rely on hardcoded
    // strings and managing specifics for subroutes. If you/someone you know is asking for new page
    // view analytics, point them at the `route_view` event populated above which will catch everything
    // and can be segmented by the `route` property in Amplitude.
    sendAnalytics(analyticsEvent(getPageView(pathname)));
  }, [pathname, sendAnalytics]);
};
