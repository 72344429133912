import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';

export default (theme: KomodoTheme) =>
  createStyles({
    root: {
      margin: '24px 0',
      minHeight: '90vh',
    },
    commentEntryRoot: {
      paddingLeft: 72,
      color: theme.palette.primaryGrey,
      paddingTop: 0,
    },
    newComment: {
      '@media print': {
        display: 'none',
      },
    },
  });
