import { createStyles } from '@material-ui/core';

export default () =>
  createStyles({
    container: {
      display: 'flex',
      gap: '2px',
      alignItems: 'center',
    },
  });
