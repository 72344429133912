import { useContext } from 'react';

import { TermKey } from '../../../api/gqlEnums';
import { MarkupMode } from '../../../generated/graphql';
import { getMarkupModeLabel } from '../../../utilities/costMode';
import { isEnumValue } from '../../../utilities/types';
import {
  MarkupModeAllocatedIcon,
  MarkupModeNoMarkupsIcon,
  MarkupModeSeparatedIcon,
} from '../../Nav/icons';
import { ProjectTermStore } from '../../ProjectDisplaySettings/TerminologyProvider';
import { Select, SelectEntry } from '../../scales';

type Props = {
  availableMarkupModes: MarkupMode[];
  isDisabled?: boolean;
  label?: string;
  onChange: (markupMode: MarkupMode) => void;
  value: MarkupMode;
};

export default function MarkupModeSelect(props: Props) {
  const t = useContext(ProjectTermStore);

  const onChange = (value: string) => {
    if (isEnumValue(MarkupMode, value)) props.onChange(value);
  };
  const entries: SelectEntry[] = [];
  if (props.availableMarkupModes.includes(MarkupMode.ALLOCATED_MARKUPS))
    entries.push({
      startAdornment: <MarkupModeAllocatedIcon className="icon-md" />,
      id: MarkupMode.ALLOCATED_MARKUPS,
      label: getMarkupModeLabel(MarkupMode.ALLOCATED_MARKUPS, t),
      description: `Allocate selected ${t.lowerCase(TermKey.MARKUP)} pro-rata`,
    });
  if (props.availableMarkupModes.includes(MarkupMode.SEPARATED_MARKUPS))
    entries.push({
      startAdornment: <MarkupModeSeparatedIcon className="icon-md" />,
      id: MarkupMode.SEPARATED_MARKUPS,
      label: getMarkupModeLabel(MarkupMode.SEPARATED_MARKUPS, t),
      description: `Do not allocate ${t.lowerCase(TermKey.MARKUP)}`,
    });
  if (props.availableMarkupModes.includes(MarkupMode.NO_MARKUPS))
    entries.push({
      startAdornment: <MarkupModeNoMarkupsIcon className="icon-md" />,
      id: MarkupMode.NO_MARKUPS,
      label: getMarkupModeLabel(MarkupMode.NO_MARKUPS, t),
      description: `${t.sentenceCase(TermKey.MARKUP)} are not displayed or included in totals`,
    });
  if (!entries.length) return null;

  return (
    <Select
      aria-label={props.label ? undefined : 'select the markup mode'}
      data-cy="markup-mode-select"
      entries={entries}
      isDisabled={props.isDisabled}
      label={props.label}
      onChange={onChange}
      placeholder="No Markup Mode Selected"
      value={props.value}
    />
  );
}
