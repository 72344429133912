import { useEffect, useMemo, useState } from 'react';
import * as React from 'react';

import { projectCompsSetInputVar } from '../../../api/apollo/reactiveVars';
import { ProjectCompsSetInput } from '../../../generated/graphql';

import { ProjectCompsSetInputStoreContext } from './ProjectCompsSetInputStoreContext';

export const ProjectCompsSetInputStoreProvider = (props: {
  children: React.ReactChild | React.ReactChild[];
}) => {
  // The PCSI query is refetched whenever there are changes to the PCSI ReactiveVar.
  // We make changes to the PCSI ReactiveVar via this local state variable and...
  const [projectCompsSetInput, setProjectCompsSetInput] =
    useState<ProjectCompsSetInput>(projectCompsSetInputVar());

  // ...when the local state variable changes, we update the ReactiveVar via this useEffect.
  // The reason behind this indirection is so that all of the updater functions don't depend on the
  // value of the ReactiveVar and can instead use a functional form like setState gives us. This
  // minimizes the amount of rerenders that need to occur since the various functions in this hook
  // (and the functions in the hooks that use this hook) have stable identities. Apollo's ReactiveVar
  // API doesn't support a functional form and at the time of swapping to this pattern, we've been
  // heavily attached to the ReactiveVar pattern already. As a result, we've migrated to this pattern
  // as a stepping stone to getting off of a ReactiveVar entirely for this functionality.
  useEffect(() => {
    projectCompsSetInputVar(projectCompsSetInput);
  }, [projectCompsSetInput]);

  const value = useMemo(() => ({ setProjectCompsSetInput }), []);

  return (
    <ProjectCompsSetInputStoreContext.Provider value={value}>
      {props.children}
    </ProjectCompsSetInputStoreContext.Provider>
  );
};
