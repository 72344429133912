import { FC } from 'react';

import { TOP_USERS } from '../../../constants';
import ExecutiveDashboardChartFrame from '../ExecutiveDashboardChartFrame/ExecutiveDashboardChartFrame';
import { useActiveUsersQuery } from '../ExecutiveDashboardHooks';
import ExecutiveDashboardLoadingState from '../ExecutiveDashboardLoadingState/ExecutiveDashboardLoadingState';
import {
  ACTIVE_USERS_HEIGHT,
  ExecutiveDashboardDataProps,
  TOP_USERS_TOOLTIP,
} from '../ExecutiveDashboardUtils';

import ExecutiveDashboardActiveUsers from './ExecutiveDashboardActiveUsers';

const ExecutiveDashboardActiveUsersData: FC<ExecutiveDashboardDataProps> = ({
  dashboardLoading,
  projectIDs,
}) => {
  const { data: { activeUsers } = { activeUsers: [] }, loading } = useActiveUsersQuery(projectIDs);

  return (
    <ExecutiveDashboardChartFrame
      component={
        loading || dashboardLoading ? (
          <ExecutiveDashboardLoadingState />
        ) : (
          <ExecutiveDashboardActiveUsers activeUsers={activeUsers} />
        )
      }
      height={ACTIVE_USERS_HEIGHT}
      title={TOP_USERS}
      tooltip={TOP_USERS_TOOLTIP}
    />
  );
};

export default ExecutiveDashboardActiveUsersData;
