import { FC, useState } from 'react';

import { reportDistributionExperiment } from '../../../analytics/analyticsEventProperties';
import { PermissionResource, UserReportType } from '../../../generated/graphql';
import useSendAnalytics from '../../../hooks/useSendAnalytics';
import { setToast } from '../../../hooks/useToastParametersLocalQuery';
import usePermissions from '../../../utilities/permissions/usePermissions';
import { getProjectIdFromUrl } from '../../../utilities/url';
import { useCreateUserReport, useUpdateUserReport } from '../../ReportsTab/ReportHooks';

import DialogsReports from './DialogsReports';

type DialogReportsDataProps = {
  allowableReportTypes: UserReportType[];
  inputReport: UserReportInput;
  isFollowingActiveMilestone?: boolean;
  onClose: () => void;
  onSuccess?: (r?: UserReport) => void;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  open: boolean;
  setInputReport: (newInput: UserReportInput) => void;
  variant: 'reports-tab' | 'reports-manager';
  hasDistribution?: boolean;
  setShowReportDistributionDialog?: (value: boolean) => void;
};

const DialogReportsData: FC<DialogReportsDataProps> = ({
  allowableReportTypes,
  hasDistribution,
  inputReport,
  isFollowingActiveMilestone,
  onClose,
  onSuccess,
  open,
  setInputReport,
  variant,
  setShowReportDistributionDialog,
}) => {
  const projectID = getProjectIdFromUrl();
  const sendAnalytics = useSendAnalytics();
  const { canView } = usePermissions();
  const hasReportDistributionPermission = canView(PermissionResource.REPORT_DISTRIBUTION);
  const [shouldCreateReportDisribution, setShouldCreateReportDisribution] = useState(false);

  const createUserReport = useCreateUserReport({
    onCompleted: (data) => {
      if (onSuccess) onSuccess(data.createUserReport);
      if (shouldCreateReportDisribution && !hasDistribution) {
        setShowReportDistributionDialog?.(true);
        sendAnalytics(
          reportDistributionExperiment({
            action: 'Opened Report Distribution Dialog from save report dialog',
          })
        );
      } else {
        setToast({ message: 'Successfully created report' });
      }
    },
  });
  const updateUserReport = useUpdateUserReport({
    onCompleted: (data) => {
      if (onSuccess) onSuccess(data.updateUserReport);
    },
  });

  const onSubmitUserReport = () => {
    if (inputReport.id) updateUserReport(inputReport, projectID);
    else createUserReport(inputReport, projectID);
    onClose();
  };

  return (
    <DialogsReports
      allowableReportTypes={allowableReportTypes}
      hasDistribution={hasDistribution ?? false}
      hasReportDistributionPermission={hasReportDistributionPermission}
      inputReport={inputReport}
      isFollowingActiveMilestone={isFollowingActiveMilestone}
      onClose={onClose}
      onSubmit={onSubmitUserReport}
      open={open}
      setInputReport={setInputReport}
      setShouldCreateReportDisribution={setShouldCreateReportDisribution}
      shouldCreateReportDisribution={shouldCreateReportDisribution}
      variant={variant}
    />
  );
};
export default DialogReportsData;
