import { FilterPopover } from '../../FilterPopover/FilterPopover';
import { activeFiltersCountProjects } from '../../HomeTab/Search/projects/activeFilterCountProjects';
import { useFilterOptions } from '../../HomeTab/Search/projects/utils';
import { MultiSelect, NumberInput } from '../../scales';
import useSearchProjectsQueryParams from '../hooks/useSearchProjectsQueryParams';

type Props = {
  filterOptions: ReturnType<typeof useFilterOptions>;
  params: ReturnType<typeof useSearchProjectsQueryParams>;
};

export default function ProjectsListFilterPanel(props: Props) {
  // This is not design-approved, I'm just trying my best to maintain behavior with an improved implementation. Some notes on decisions I made:
  // - created my own header to match the ClearFiltersPanelHeader component
  // - hardcoded the width to 325px to match FilterPanelStyles.tsx current use of FILTER_SELECT_MIN_WIDTH
  // - I am ignoring ProjectTypeFilter in favor of MultiSelect, as the type selector is not DS-approved and would require additional work
  return (
    <FilterPopover
      numFiltersApplied={activeFiltersCountProjects(props.params.variables.filters)}
      onResetFilters={props.params.onResetFilters}
    >
      <MultiSelect
        entries={props.filterOptions.locations}
        isClearable
        isSearchable
        label="Project Location"
        onChange={(values: string[]) => props.params.onChangeFilters('locations', values)}
        value={props.params.variables.filters.locations}
      />
      <MultiSelect
        entries={props.filterOptions.types}
        isClearable
        isSearchable
        label="Project Type"
        onChange={(values: string[]) => props.params.onChangeFilters('types', values)}
        value={props.params.variables.filters.types}
      />
      <MultiSelect
        entries={props.filterOptions.statuses}
        isClearable
        isSearchable
        label="Status"
        onChange={(values: string[]) => props.params.onChangeFilters('statuses', values)}
        value={props.params.variables.filters.statuses}
      />
      <MultiSelect
        entries={props.filterOptions.milestoneDesignPhases}
        isClearable
        isSearchable
        label="Design Phase"
        onChange={(values: string[]) =>
          props.params.onChangeFilters('milestoneDesignPhases', values)
        }
        value={props.params.variables.filters.milestoneDesignPhases}
      />
      <GSFRangeFilter
        onChange={(value) => props.params.onChangeFilters('gsfRange', value)}
        value={props.params.variables.filters.gsfRange ?? undefined}
      />
      <MultiSelect
        entries={props.filterOptions.companies}
        isClearable
        isSearchable
        label="Companies"
        onChange={(values: string[]) => props.params.onChangeFilters('companies', values)}
        value={props.params.variables.filters.companies}
      />
    </FilterPopover>
  );
}

function GSFRangeFilter(props: {
  onChange: (value: { min: number | null; max: number | null }) => void;
  value?: { min?: number | null; max?: number | null };
}) {
  return (
    <div className="flex flex-col gap-1">
      <div className="type-label">Project Size (GSF)</div>
      <div className="flex flex-row gap-2">
        <div className="min-w-0 flex-1">
          <NumberInput
            label="Min"
            onChange={(value) => props.onChange({ min: value, max: props.value?.max ?? null })}
            onClear={() => props.onChange({ min: null, max: props.value?.max ?? null })}
            placeholder="0"
            value={props.value?.min ?? null}
          />
        </div>
        <div className="min-w-0 flex-1">
          <NumberInput
            label="Max"
            onChange={(value) => props.onChange({ min: props.value?.min ?? null, max: value })}
            onClear={() => props.onChange({ min: props.value?.min ?? null, max: null })}
            placeholder="99999"
            value={props.value?.max ?? null}
          />
        </div>
      </div>
    </div>
  );
}
