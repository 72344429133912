import { createStyles } from '@material-ui/core/styles';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';

const styles = (theme: KomodoTheme) =>
  createStyles({
    inline: {
      display: 'inline',
    },
    item: {
      display: 'inline-flex',
      alignItems: 'flex-end',
    },
    statusText: {
      paddingLeft: theme.spacing.generic[0],
    },
    overflow: {
      width: '100%',
    },
  });

export default styles;
