import { createStyles } from '@material-ui/core';

import { MAX_COL_WIDTH } from '../../constants';
import { KomodoTheme } from '../../theme/komodo-mui-theme';
import { tableStyles } from '../../theme/table-styles';

export const styles = (theme: KomodoTheme) => {
  const { row, cell, smallCell, tinyCell, limitCell, header } = tableStyles(theme);
  return createStyles({
    action: {
      margin: 0,
    },
    card: {
      overflow: 'unset',
      border: theme.border.line,
      marginBottom: 8,
      pageBreakInside: 'avoid',
      width: 'calc(100% - 2px)',
      [theme.breakpoints.up('xl')]: {
        maxWidth: MAX_COL_WIDTH,
      },
    },
    row,
    button: {
      padding: 0,
    },
    cell,
    cellLink: {
      background: 'none',
      border: 'none',
      cursor: 'pointer',
    },
    smallCell,
    tinyCell,
    limitCell,
    header,
    icon: {
      color: theme.palette.blue,
      verticalAlign: 'middle',
      minHeight: '64px',
    },
    iconNext: {
      verticalAlign: 'middle',
      cursor: 'pointer',
    },
    containerMilestoneReport: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'flex-end',
      cursor: 'pointer',
    },
    containerItemMilestoneReport: {
      justifyContent: 'flex-start',
    },
    draftIcon: {
      alignItems: 'center',
      verticalAlign: 'middle',
      border: '2px solid '.concat(theme.palette.pinkBadge),
      borderRadius: 3,
      color: theme.palette.pinkBadge,
      fontFamily: theme.typography.fontFamily,
      fontSize: '12px',
      lineHeight: '16px',
      marginLeft: 10,
      padding: '4px 8px 2px 8px',
      textAlign: 'center',
      display: 'inline',
      whiteSpace: 'nowrap',
    },
    iconBarChart: {
      color: theme.palette.link,
      height: '18px',
      paddingRight: `${theme.spacing.generic[1]}px`,
    },
    link: {
      ...theme.typography.link,
      paddingRight: 0,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    linkTableCell: {
      display: 'table-cell',
      textDecoration: 'none',
      color: theme.palette.joinPrimary,
    },
    loadingCost: {
      backgroundColor: theme.palette.nestingGrey,
      display: 'flex',
      width: '100%',
      padding: 4,
      minHeight: 32,
      marginLeft: 'auto',
      position: 'relative',
      top: 2,
      borderRadius: 8,
    },
    itemMilestoneLinkText: {
      cursor: 'pointer',
      textDecoration: 'underline',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      ...theme.typography.webParagraph,
    },
    nameLink: {
      cursor: 'pointer',
      display: 'inline',
      textDecoration: 'underline',
      color: theme.palette.joinPrimary,
    },
    itemMilestoneLinkDisabled: {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textDecoration: 'none',
    },
    varianceButton: {
      color: theme.palette.link,
      display: 'inline-flex',
      marginRight: 16,
      verticalAlign: 'middle',
      height: 34,
    },
    defaultCursor: {
      cursor: 'default',
    },
    cardHeader: {
      paddingTop: 12,
      paddingBottom: 12,
    },
    alignRight: {
      textAlign: 'end',
    },
    wrapContent: {
      display: 'inline-block',
      verticalAlign: 'middle',
    },
    numberCells: { ...theme.typography.number },
    milestoneInlineLink: {
      ...theme.typography.link,
      color: theme.palette.primaryGrey,
      fontSize: '12px',
      paddingLeft: '2px',
      textOverflow: 'elipsis',
    },
    itemsListHeaderMSRLink: {
      display: 'flex',
      flexWrap: 'wrap',
      fontSize: '12px',
      padding: '8px 0',
      paddingLeft: '12px',
    },
    itemsListMilestoneLinkText: {
      fontSize: '12px',
      whiteSpace: 'nowrap',
      '@media (max-width: 950px)': {
        display: 'none',
      },
    },
    currentMilestone: {
      fontWeight: 500,
    },
    tableGroup: {
      borderCollapse: 'collapse',
      display: 'table',
      width: '100%',
    },
    tableCell: {
      display: 'table-cell',
    },
    tableRow: {
      display: 'table-row',
    },
    paddingLeft: {
      paddingLeft: 14,
    },
    paddingLeftHeader: {
      paddingLeft: 18,
    },
    noWrap: {
      whiteSpace: 'nowrap',
    },
    noPadding: {
      paddingBottom: 0,
      paddingTop: 0,
    },
    alignContent: {
      alignContent: 'center',
    },
  });
};
