import { useCallback, useState } from 'react';

import { timelineEvent } from '../../../../analytics/analyticsEventProperties';
import { TimelineGroups } from '../../../../api/gqlEnums';
import { ITEMS, TIMELINE } from '../../../../constants';
import useSendAnalytics from '../../../../hooks/useSendAnalytics';
import { getTodayAtUtcNoon } from '../../../../utilities/dates';
import { TimelineEvent } from '../../../shared-widgets/TimelineChart/timeline/timeline.types';
import { computeExpandableMap, getExpandSettings } from '../../../Timeline/TimelineUtils';
import useMemoWrapper from '../../../useMemoWrapper';
import { InsightsProject } from '../../types';
import { useTimeline } from '../hooks/useTimeline';
import { LEFT_MARGIN, PADDING } from '../utils';

import TimelineChart from './TimelineChart';

export default function ProjectTimelineChart({
  project,
  startStr,
  endStr,
}: {
  project: InsightsProject;
  startStr: string;
  endStr: string;
}) {
  const projectID = project.id;

  const { timelineData, activities } = useTimeline(project);

  const [settings, setSettings] = useState({ collapse: [ITEMS], expand: [TIMELINE] });
  // Expand states
  const { collapse = [], expand = [] } = settings ?? {};
  const expandedMap = useMemoWrapper(computeExpandableMap, collapse, expand);

  const onExpand = useCallback(
    (id: UUID) => {
      expandedMap[id] = !expandedMap[id];
      const [newCollapse, newExpand] = getExpandSettings(expandedMap);
      setSettings({ collapse: newCollapse, expand: newExpand });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO CT-566: Fix this pls :)
    [collapse, expand, expandedMap]
  );

  const sendAnalytics = useSendAnalytics();

  const today = getTodayAtUtcNoon();

  const onAnalytics = (event: TimelineEvent) => {
    sendAnalytics(timelineEvent(event, { source: 'insights-timeline' }));
  };

  const chart = (
    <TimelineChart
      activities={activities}
      data={timelineData}
      endDate={endStr}
      expandedMap={expandedMap}
      maxHeight={400}
      onAnalytics={onAnalytics}
      onExpand={onExpand}
      onZoom={() => {}}
      settings={{
        collapse: [TimelineGroups.ITEMS],
        expand: [TimelineGroups.TIMELINE],
        projectID,
        isInsights: true,
      }}
      startDate={startStr}
      today={today}
      withoutDueDateCount={0}
      zoomLineCompressed
      zoomLineDisabled
    />
  );

  return (
    <div style={{ paddingLeft: LEFT_MARGIN, paddingTop: PADDING, paddingBottom: PADDING }}>
      {chart}
    </div>
  );
}
