import SvgIcon from '@material-ui/core/SvgIcon';

import { IconProps } from '.';

export default (props: IconProps) => (
  <SvgIcon
    {...props}
    fill="none"
    height="20"
    style={{ transform: 'scale(0.8) translate(0px, -2px)' }}
    viewBox="0 0 20 20"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1252_317495)">
      <path
        d="M10.0007 -4.99996H8.33398V-1.66663H10.0007C10.0007 -0.74996 10.7507 3.95775e-05 11.6673 3.95775e-05H14.1673V-6.66663H11.6673C10.7507 -6.66663 10.0007 -5.91663 10.0007 -4.99996Z"
        fill="black"
      />
      <path
        d="M15.332 17C15.332 18.1667 16.2487 19.0834 17.4154 19.0834C18.582 19.0834 19.4987 18.1667 19.4987 17C19.4987 16.25 18.582 14.5834 17.9154 13.5834C17.6654 13.25 17.1654 13.25 16.9154 13.5834C16.332 14.5834 15.332 16.25 15.332 17Z"
        fill="black"
      />
      <path
        d="M4.16602 10.1667L5.66602 8.66669C6.08268 8.25002 6.83268 7.91669 7.41602 7.91669H14.9993C15.5827 7.91669 15.666 8.25002 15.3327 8.66669L8.99935 15L4.16602 10.1667Z"
        fill="black"
      />
      <path
        d="M9.24935 19C8.99935 19 8.83268 18.9167 8.66602 18.75L0.416016 10.5C0.249349 10.3334 0.166016 10.0834 0.166016 9.91669C0.166016 9.75002 0.249349 9.41669 0.416016 9.33335L8.99935 0.666687C9.33268 0.333354 9.91602 0.333354 10.2493 0.666687C10.5827 1.00002 10.5827 1.58335 10.2493 1.91669L2.24935 9.91669L9.24935 16.9167L17.2493 8.91669C17.5827 8.58335 18.166 8.58335 18.4993 8.91669C18.8327 9.25002 18.8327 9.83335 18.4993 10.1667L9.91602 18.75C9.74935 18.9167 9.49935 19 9.24935 19Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_1252_317495">
        <rect fill="white" height="20" width="20" />
      </clipPath>
    </defs>
  </SvgIcon>
);
