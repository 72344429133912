import { ItemsTimelineQuery, ItemsTimelineQueryVariables } from '../../../generated/graphql';
import { useQuery } from '../../../hooks/useMountAwareQuery';
import { MountPolicy } from '../../../hooks/usePolicyOnFirstMount';
import { useCostMode } from '../../../utilities/costMode';

import { itemsTimelineQuery } from './queries';

export const useItemsTimelineQuery = (
  milestoneID: UUID | null,
  projectID: UUID | undefined,
  skipQuery = false
) =>
  useQuery<ItemsTimelineQuery, ItemsTimelineQueryVariables>(
    itemsTimelineQuery,
    {
      variables: {
        milestoneID,
        projectID,
        costMode: useCostMode(),
      },
      skip: skipQuery || !projectID,
    },
    MountPolicy.FETCH_ON_VARS
  );
