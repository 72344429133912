import * as React from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  IconButton,
  Typography,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';

import { withStyles } from '../../../theme/komodo-mui-theme';
import { generateTitle } from '../../Items/ItemsListItem/ItemsListItemUtils';
import DialogsStyles from '../DialogsStyles';

type Props = {
  classes: Classes<typeof DialogsStyles>;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  open: boolean;
  option: Option;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  onClose?: (event: any) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  onConfirm?: (event: any) => void;
};

const DialogsDetachOption: React.FC<Props> = ({
  classes,
  onClose,
  onConfirm,
  open,
  option,
  // status
}) => {
  const convertText = (
    <Typography>
      {`Your option `}
      <b>{generateTitle(option)}</b>
      {` will be detached from this item and will become a standalone item in this milestone.`}
    </Typography>
  );
  return (
    <Dialog
      classes={{
        paper: classes.dialogPaper,
      }}
      maxWidth={false}
      onClose={onClose}
      open={open}
    >
      <div className={classes.titleContainer}>
        <div>
          <Typography variant="title">Detach option</Typography>
        </div>
        <IconButton className={classes.close} title="Close dialog">
          <Close onClick={onClose} />
        </IconButton>
      </div>
      <Divider />
      <DialogContent className={classes.content}>
        {convertText}
        <Typography>You can undo by re-adding this item as an option later.</Typography>
      </DialogContent>
      <DialogActions className={classes.action}>
        <Button color="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button
          color="primary"
          data-cy="confirm-dialog--ok-button"
          onClick={onConfirm}
          variant="contained"
        >
          Detach
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(DialogsStyles)(DialogsDetachOption);
