import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../../theme/komodo-mui-theme';
import { dropZoneStyle } from '../../../shared-widgets/AddUploadDrag/AddUploadDragStyles';

const AssetsCardsDropzoneStyles = (theme: KomodoTheme) =>
  createStyles({
    root: {
      color: theme.palette.joinPrimary,
      display: 'flex',
      flexDirection: 'row',
      contentAlign: 'center',
      flexWrap: 'wrap',
      flexGrow: 1,
      margin: 2,
      minHeight: 48,
    },
    droppable: {
      outline: `2px solid ${theme.palette.joinGrey400}`,
    },
    targetDroppable: {
      ...dropZoneStyle,
      backgroundColor: theme.palette.backgroundGreen,
      padding: 4,
    },
    target: {
      borderRadius: 8,
      padding: 6,
    },
    emptyMessage: {
      flexGrow: 1,
      color: theme.palette.joinPrimary,
      textAlign: 'left',
    },
  });

export default AssetsCardsDropzoneStyles;
