import { FC, useContext, useEffect, useState } from 'react';
import 'react-vis/dist/style.css';
import { useNavigate } from 'react-router-dom';

import { setEstimateChartViewBy } from '../../../../analytics/analyticsEventProperties';
import { JoinProjectRoutes, TermKey } from '../../../../api/gqlEnums';
import { TOTAL } from '../../../../constants';
import { CostReportColumnType } from '../../../../generated/graphql';
import { useMilestoneCostReportsQueryGrouped } from '../../../../hooks/useMilestoneCostReportsQueryGrouped';
import useSendAnalytics from '../../../../hooks/useSendAnalytics';
import { withStyles } from '../../../../theme/komodo-mui-theme';
import { clickLinkKeyHandler } from '../../../../utilities/clickHandler';
import { getCategoriztionMap } from '../../../../utilities/dashboard';
import { generateSharedPath } from '../../../../utilities/routes/links';
import { removeYear } from '../../../../utilities/string';
import { getProjectIdFromUrl } from '../../../../utilities/url';
import EstimateChart from '../../../Charts/ChartsEstimate/ChartsEstimate';
import styles from '../../../Charts/ChartsEstimate/ChartsEstimateStyles';
import {
  hasTarget,
  makeEstimateChartCategoriesList,
  useLegendLabels,
} from '../../../Charts/ChartsEstimate/ChartsEstimateUtils';
import { FilterManager } from '../../../FilterPanel/filterUtils';
import UnitToggle from '../../../Inputs/UnitToggle/UnitToggle';
import { getNonZeroQuantities } from '../../../Inputs/UnitToggle/UnitToggleUtils';
import useMilestoneQuantitiesQuery, {
  emptyQuantities,
} from '../../../Milestone/hooks/useMilestoneQuantitiesQuery';
import { LANDSCAPE_WIDTH } from '../../../Print/PrintUtils';
import { ProjectTermStore } from '../../../ProjectDisplaySettings/TerminologyProvider';
import { MenuVariants } from '../../../Select/SelectTextMenu/SelectTextMenu';
import CTALink from '../../../shared-widgets/CTALink';
import useMemoWrapper from '../../../useMemoWrapper';
import DashboardAnchor from '../../DashboardAnchor';
import DashboardFilterDescription from '../../DashboardFilterDescription/DashboardFilterDescription';
import { DashboardGroupBySelect } from '../../DashboardGroupBySelect';
import { DASHBOARD_DEFAULTS } from '../../DashboardUtils';

type DashboardChartsEstimateProps = {
  activeMilestoneId: string;
  categorizations: Categorization[];
  classes: Classes<typeof styles>;
  clearFilters?: () => void;
  filterManager?: FilterManager;
  isFiltered?: boolean;
  isPrint?: boolean;
  setIsLoading?: (loading: boolean) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  setSetting?: (param: string, value: any) => void;
  settings: typeof DASHBOARD_DEFAULTS;
  viewFilter: ViewFilterInput;
  isTableDisplayed?: boolean;
};

const DashboardChartsEstimate: FC<DashboardChartsEstimateProps> = ({
  activeMilestoneId,
  categorizations,
  classes,
  clearFilters,
  filterManager,
  isFiltered = false,
  isPrint = false,
  setIsLoading = () => {},
  setSetting = () => {},
  settings,
  viewFilter,
  isTableDisplayed = false,
}) => {
  const projectId = getProjectIdFromUrl();
  const t = useContext(ProjectTermStore);
  const navigate = useNavigate();
  // STATE
  const [width, setWidth] = useState(400);
  const { estimatesGroupBy, estUnit } = settings;
  const setQuantity = (q: Quantity | undefined) => {
    setSetting('estUnit', (q && q.unit && q.unit.abbreviationSingular) || TOTAL);
  };
  // TODO: Add quantity to persistent settimgs
  const { data: { quantities = emptyQuantities } = {} } =
    useMilestoneQuantitiesQuery(activeMilestoneId);

  const nonZeroQuantities = getNonZeroQuantities(quantities);
  // get the freshest quantity
  const quantity =
    nonZeroQuantities &&
    nonZeroQuantities.find((q: Quantity) => estUnit === q.unit.abbreviationSingular);
  const unitID: UUID | undefined = quantity && quantity.unit.id;
  const sidebarColumnTypes: CostReportColumnType[] = [
    CostReportColumnType.TARGET_REPORT,
    CostReportColumnType.ACCEPTED_REPORT,
    CostReportColumnType.RUNNINGTOTAL_REPORT,
    CostReportColumnType.REMAINING_REPORT,
    CostReportColumnType.ESTIMATE_REPORT,
  ];
  const columnInputs: CostReportColumnInput[] = sidebarColumnTypes.map(
    (type: CostReportColumnType) => ({
      type,
      unitID,
    })
  );
  const hasCategorizations = categorizations.length > 0;
  const categorizationMap = useMemoWrapper(getCategoriztionMap, categorizations);

  const viewByCategorization = categorizationMap[estimatesGroupBy];
  const viewByName = viewByCategorization && viewByCategorization.name;

  const { data: { milestoneCostReports = [] } = {}, loading } = useMilestoneCostReportsQueryGrouped(
    {
      columns: columnInputs,
      groupByCategorization: viewByCategorization,
      milestoneID: activeMilestoneId,
      projectID: projectId,
      shouldSkip: !viewByCategorization,
      viewFilter,
    }
  );

  const costReport = milestoneCostReports && milestoneCostReports[0];

  const sendAnalytics = useSendAnalytics();

  const setViewByAll = (categorizationId: UUID) => {
    setSetting('estimatesGroupBy', categorizationId);
    sendAnalytics(setEstimateChartViewBy(categorizationId));
  };

  const groupedCategories = useMemoWrapper(makeEstimateChartCategoriesList, costReport, t);
  const legendLabels = useLegendLabels(t, hasTarget(groupedCategories));

  useEffect(() => {
    setIsLoading(loading);
  }, [loading, setIsLoading]);

  const localTerm = t.titleCase(TermKey.ESTIMATE);
  return (
    <div
      className={`${classes.root} ${isPrint ? classes.printArea : classes.minHeight}`}
      data-cy="dashboard-EstimateChart"
    >
      <div className={classes.header}>
        <div className={classes.description}>
          {!isPrint && <DashboardAnchor anchor={localTerm} />}
          {!isPrint && filterManager && (
            <DashboardFilterDescription
              categorizations={categorizations}
              filterManager={filterManager}
              isFiltered={isFiltered}
            />
          )}
          {isPrint && <div className={classes.printChartTitle}>Estimate</div>}
          {isPrint && (
            <div className={classes.printChartSetting}>{`Grouped by ${removeYear(
              viewByName
            )}`}</div>
          )}
        </div>
        {!isPrint && (
          <div className="flex gap-2">
            {nonZeroQuantities && nonZeroQuantities.length > 0 && (
              <UnitToggle
                quantities={nonZeroQuantities}
                quantity={quantity}
                setQuantity={setQuantity}
                variant={MenuVariants.INLINE}
              />
            )}
            {hasCategorizations && (
              <DashboardGroupBySelect
                categorizations={categorizations}
                includeStatus={false}
                onChange={setViewByAll}
                viewBy={estimatesGroupBy}
              />
            )}
          </div>
        )}
      </div>
      <div
        ref={(r) => {
          if (r && r.getBoundingClientRect()) {
            const w = r.getBoundingClientRect().width;
            if (!width || width !== w) {
              setWidth(w);
            }
          }
        }}
      >
        <EstimateChart
          clearFilters={clearFilters}
          groupedCategories={groupedCategories}
          isFiltered={isFiltered}
          isTableDisplayed={isTableDisplayed} // note: make sure this references properly, check true/false
          legend={legendLabels}
          loading={loading}
          milestoneId={activeMilestoneId}
          printWidth={isPrint ? LANDSCAPE_WIDTH : undefined}
          projectId={projectId}
          rounded={!quantity}
          viewByName={viewByName}
        />
        {!hasCategorizations && (
          <CTALink
            linkText="Add a categorization to your project."
            onClick={(e) => {
              const path = generateSharedPath(JoinProjectRoutes.SETTINGS, {
                projectId,
              });
              const pathHash = 'Categorizations';
              clickLinkKeyHandler(navigate, e, path, undefined, pathHash);
            }}
          />
        )}
      </div>
    </div>
  );
};

export const DashboardChartsEstimateStyled = withStyles(styles)(DashboardChartsEstimate);

export default DashboardChartsEstimateStyled;
