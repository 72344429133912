import { useEffect, useState } from 'react';

import { triggerTabEvent } from '../../../hooks/useBrowserTabUpdate';
import { FILES_DIALOG_TAB_KEY } from '../Files/FilesDialog/FilesDialog';

import { DISPLAY_INTERVAL, countdownLoop, percentOfCountdown } from './AuthorizeRedirectPageUtils';

export const useSuccessClose = (isReady: boolean, countdown: number, sourceSystem?: string) => {
  const [progress, setProgress] = useState(DISPLAY_INTERVAL);

  useEffect(() => {
    if (isReady) {
      triggerTabEvent(FILES_DIALOG_TAB_KEY, sourceSystem);
      countdownLoop(countdown, setProgress, window.close);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO CT-566: Fix this pls :)
  }, [isReady]);

  return percentOfCountdown(progress, countdown);
};
