import {
  ListCategoriesByIDsQuery,
  ListCategoriesByIDsQueryVariables,
} from '../../../generated/graphql';
import { QueryHookOptions, useQuery } from '../../../hooks/useMountAwareQuery';

import { listCategoriesByIDsQuery } from './queries';

const useListCategoriesByIDsQuery = (
  categorizationID: UUID,
  categoryIDs?: UUID[],
  options: QueryHookOptions<ListCategoriesByIDsQuery, ListCategoriesByIDsQueryVariables> = {}
) =>
  useQuery<ListCategoriesByIDsQuery, ListCategoriesByIDsQueryVariables>(listCategoriesByIDsQuery, {
    variables: { categorizationID, categoryIDs },
    skip: !categoryIDs || !categorizationID,
    ...options,
  });

export default useListCategoriesByIDsQuery;
