import { commonProjectFields, gql } from '../../../../api/graphqlFragments';

export const createProjectMutation = gql`
  mutation createProject(
    $name: String!
    $code: String
    $milestone: String!
    $milestoneDate: Time!
    $milestoneDesignPhaseID: UUID!
    $typeID: UUID
    $location: String!
    $lat: Float
    $lon: Float
    $statusID: UUID!
    $currency: String
    $templateID: UUID
    $projectDeliveryTypeID: UUID
    $locationDetails: LocationDetailsInput!
    $orgNodeIDs: [UUID!]
  ) {
    createProject(
      name: $name
      code: $code
      milestone: $milestone
      milestoneDate: $milestoneDate
      milestoneDesignPhaseID: $milestoneDesignPhaseID
      typeID: $typeID
      location: $location
      lat: $lat
      lon: $lon
      statusID: $statusID
      currency: $currency
      templateID: $templateID
      projectDeliveryTypeID: $projectDeliveryTypeID
      locationDetails: $locationDetails
      orgNodeIDs: $orgNodeIDs
    ) {
      ...commonProjectFields
    }
  }
  ${commonProjectFields}
`;
