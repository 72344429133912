import { Cell } from '../../CostReportColumns/CostReportColumns';

// This function returns the simple numeric value of a CostReportValue if it's a string/number or CostScalar
// It returns 0 for more complex CostReportValue types like PartialAddDeduct or Cost ranges
const getSimpleCellCostValue = (v: CostReportValue) => {
  if (v) {
    if (typeof v === 'string' || typeof v === 'number') {
      return Number(v);
    }
    if ('value' in v) {
      return Number(v.value);
    }
  }
  return 0;
};

export const getCellCosts = (cells: Cell[]) => {
  return cells.map((c) => getSimpleCellCostValue(c.cost));
};
