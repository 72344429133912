import { Link } from 'react-router-dom';

import { RouteKeys } from '../../../routes/paths';
import { generateSharedPath } from '../../../utilities/routes/links';
import { Button, Chip } from '../../scales';
import { ProjectIdea } from '../types';

type Props = { projectID: UUID; idea: ProjectIdea; onCreateItem: () => void };

export default function IdeasHeader(props: Props) {
  return (
    <div className="flex flex-col gap-4">
      <div className="flex items-center justify-between gap-4">
        <h1 className="flex w-full max-w-max items-center gap-2 type-heading2">
          <Link
            className="text-type-link"
            to={generateSharedPath(RouteKeys.PROJECT_IDEAS, { projectId: props.projectID })}
          >
            Ideas
          </Link>
          <div>/</div>
          <div>{props.idea.name}</div>
        </h1>
        <Button label="Add Idea To Project" onClick={props.onCreateItem} type="primary" />
      </div>
      <div className="flex gap-6">
        <Category categorization="UniFormat" category={props.idea.root.uniFormat} />
        <Category categorization="MasterFormat" category={props.idea.root.masterFormat} />
      </div>
    </div>
  );
}

function Category(props: {
  categorization: string;
  category: ProjectIdea['root']['masterFormat'] | ProjectIdea['root']['uniFormat'];
}) {
  if (!props.category) return null;
  return (
    <div className="flex flex-col gap-0.5">
      <div className="type-label">{props.categorization}</div>
      <div className="flex flex-row items-center gap-2">
        <div className="line-clamp-1">{props.category.name}</div>
        <Chip text={props.category.number} />
      </div>
    </div>
  );
}
