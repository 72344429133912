import queryString from 'query-string';

import { UserReportType } from '../../generated/graphql';
import { generateSharedPath } from '../../utilities/routes/links';
import { removeNullKeys } from '../../utilities/urlState';

import { MapUserReportTypeToConfig } from './config';
import { isDefaultReport } from './ReportsManagerMenu/utils';

type variant = 'app' | 'print' | 'export';

const variantToRoute = (variant: variant, type: UserReportType) => {
  const config = MapUserReportTypeToConfig[type];
  if (!config) return undefined;
  const { appViewRoute, exportViewRoute, printViewRoute, search } = config;
  switch (variant) {
    case 'app':
      return { route: appViewRoute, search };
    case 'print':
      return { route: printViewRoute, search };
    case 'export':
      return { route: exportViewRoute, search };
    default:
      return undefined;
  }
};

export const reportToPath = (
  variant: variant,
  report: Pick<UserReport, 'reportType' | 'settings' | 'milestoneID' | 'id'>,
  projectId: UUID,
  activeMilestoneId: UUID
): string => {
  const { route, search: viewSearch = '' } = variantToRoute(variant, report.reportType) ?? {};
  if (!route) return '';
  let settings;
  try {
    settings = JSON.parse(report.settings);
  } catch (e) {
    settings = {};
  }
  const settingsSearch = queryString.stringify(removeNullKeys(settings), {
    arrayFormat: 'index',
  });
  const search = `${viewSearch || settingsSearch ? '?' : ''}${viewSearch}${
    viewSearch && settingsSearch ? '&' : ''
  }${settingsSearch}`;
  const isDefault = isDefaultReport(report.id);
  try {
    return generateSharedPath(route, {
      projectId,
      milestoneId: isDefault ? activeMilestoneId : report.milestoneID || undefined,
      search,
      reportID: report.id,
    });
  } catch (e) {
    return '';
  }
};

export const groupUserReports = (
  userReports: UserReport[],
  userEmail: string | undefined,
  inPreviewMode: boolean
) => {
  const myUserReports = userReports
    .filter(({ createdBy: { email: reportEmail } }) => !inPreviewMode && userEmail === reportEmail)
    .map((report) => ({
      ...report,
      owner: true,
    }));
  const sharedUserReports = userReports
    .filter(
      ({ createdBy: { email: reportEmail }, shared }) =>
        shared && (inPreviewMode || userEmail !== reportEmail)
    )
    .map((report) => ({
      ...report,
      owner: false,
    }));
  return [myUserReports, sharedUserReports];
};
