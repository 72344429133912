import { UNCATEGORIZED_ID } from '../../../constants';
import { isMasterFormat } from '../../../utilities/string';

/*
STRING CONSTANTS
- BACK
- BULK_TOGGLE
- CLEAR_SEARCH
- NONE
- UNCATEGORIZED

TYPES
- SelectCategoryInteractionVariants
- SelectCategoryInteraction
- SelectCategoryValue

INTERACTION CONSTANTS
- clearSearchOption
- generateBackOption
- noneOption
- bulkToggleOption
- uncategorizedOption

HELPERS
- isSelectCategoryInteraction
- formatCategoryDisplayName
- generateSpacer
- isGoingBackwards
- isInTrade
- isSelectedCategory
- categoryDisplayName
*/

// STRING CONSTANTS
export const BACK = 'BACK';
export const BULK_TOGGLE = 'BULK_TOGGLE';
export const CLEAR_SEARCH = 'CLEAR SEARCH';
export const NONE = 'NONE';
export const UNCATEGORIZED = 'UNCATEGORIZED';

export type SelectCategoryInteractionVariants = {
  variant:
    | typeof CLEAR_SEARCH
    | typeof BACK
    | typeof BULK_TOGGLE
    | typeof NONE
    | typeof UNCATEGORIZED;
};
export type SelectCategoryInteraction =
  | (SelectCategoryInteractionVariants & Category)
  | Item['categories'][number];

export type SelectCategoryValue = Category | SelectCategoryInteraction;

// INTERACTION CONSTANTS

// used to stub a generic Category shape so we don't have to worry about getting stuck
// on undefined values
export const emptyCategoryShape = {
  categorization: null,
  id: '',
  level: 1,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  levels: [] as any[],
  name: '',
  number: '',
  hasSubCategories: false,
};

export const clearSearchOption: SelectCategoryInteraction = {
  ...emptyCategoryShape,
  variant: CLEAR_SEARCH,
} as SelectCategoryInteraction;

export const generateBackOption = (highlightedCategory: Category): SelectCategoryInteraction => {
  const { categorization, id, level, levels, name, number } = highlightedCategory;
  return {
    categorization,
    id,
    level,
    levels,
    name,
    number,
    variant: BACK,
  } as SelectCategoryInteraction;
};

export const noneOption: SelectCategoryInteraction = {
  ...emptyCategoryShape,
  variant: NONE,
} as SelectCategoryInteraction;

export const bulkToggleOption: SelectCategoryInteraction = {
  ...emptyCategoryShape,
  variant: BULK_TOGGLE,
} as SelectCategoryInteraction;

export const uncategorizedOption: SelectCategoryInteraction = {
  ...emptyCategoryShape,
  id: UNCATEGORIZED_ID,
  variant: UNCATEGORIZED_ID,
} as SelectCategoryInteraction;

// HELPERS

// Determines if a value is of type SelectCategoryInteraction or of type Category
export const isSelectCategoryInteraction = <X extends object>(
  obj: X
): obj is X & Record<'variant', unknown> => 'variant' in obj; // type Category does not have a value for "variant"

export const formatCategoryDisplayName = (
  category?: CategoryDisplay
): { number: string; name: string } => {
  if (!category) {
    return { name: '', number: '' };
  }
  const { categorization, number, name } = category;
  const isMasterFormatCategorization = !!category.categorization && isMasterFormat(categorization);
  const nameDisplay: string = name ? `${name}` : '';
  let formattedNumberDisplay = number;
  if (isMasterFormatCategorization && category.id !== UNCATEGORIZED) {
    const numberArray: string[] = number.split('.');
    const firstNumbers: string =
      numberArray && numberArray.length > 0 ? numberArray[0].match(/.{1,2}/g)!.join(' ') : '';
    const decimalNumbers: string = numberArray[1] ? `.${numberArray[1]}` : '';
    formattedNumberDisplay = `${firstNumbers}${decimalNumbers}`;
  }
  return { number: formattedNumberDisplay, name: !name && !number ? 'Uncategorized' : nameDisplay };
};

export const generateSpacer = (name: string, number: string): string =>
  name === '' || number === '' ? '' : ' - ';

export const isGoingBackwards = (highlightedCategory: SelectCategoryValue): boolean =>
  isSelectCategoryInteraction(highlightedCategory) && highlightedCategory.variant === BACK;

export const isHighlightedCategory = (
  category: SelectCategoryValue,
  highlightedCategory: Category
): boolean => category === highlightedCategory;

export type CategoryDisplay = {
  categorization?: { id: UUID; name: string } | null;
  id: UUID;
  name: string;
  number: string;
};

export const selectCategoryDisplayName = (category: SelectCategoryValue): string => {
  return categoryDisplayName({
    categorization: {
      id: category.categorization?.id ?? '',
      name: category.categorization?.name ?? '',
    },
    id: category.id,
    name: category.name,
    number: category.number,
  });
};

export const categoryDisplayName = (category: CategoryDisplay): string => {
  const { name, number } = formatCategoryDisplayName(category);
  const spacer = generateSpacer(name, number);
  return `${number}${spacer}${name}`;
};

// restrict view of subcategories based on trade perms only if filtering on item
export const getHasSubcategoryPermissions = (inTrade: boolean, itemID?: UUID) =>
  itemID ? inTrade : true;
