import { Link } from 'react-router-dom';

import { navEventTypes } from '../../../analytics/analyticsEventProperties';
import { RouteKeys } from '../../../routes/paths';
import { generateSharedPath } from '../../../utilities/routes/links';
import useSendNavAnalytics from '../hooks/useSendNavAnalytics';
import JoinLogo from '../JoinLogo';
import JoinWordmark from '../JoinWordMark';

type Props = {
  size: 'md' | 'lg';
};

export default function JoinLogoNavLink(props: Props) {
  const sendAnalytics = useSendNavAnalytics();

  return (
    <Link
      className={`group mr-auto outline-none ${props.size === 'lg' ? 'icon-lg' : 'icon-md'}`}
      data-cy="nav-logo-link"
      onClick={() => sendAnalytics(navEventTypes.LOGO_CTA)}
      to={generateSharedPath(RouteKeys.SEARCH_PROJECTS)}
    >
      <div className="text-type-primary outline-none group-focus-visible:text-selection-focus-outline">
        {props.size === 'lg' ? <JoinLogo /> : <JoinWordmark />}
      </div>
    </Link>
  );
}
