import { MutationHookOptions, useMutation } from '@apollo/client';

import { REFETCH_PROJECT_ROLES } from '../../../../api/refetchSets';
import {
  CollaboratorRoleInput,
  DeleteProjectRoleMutation,
  DeleteProjectRoleMutationVariables,
} from '../../../../generated/graphql';

import { deleteProjectRoleMutation } from './queries';

export const useDeleteProjectRoleMutation = (
  projectID: UUID,
  options?: MutationHookOptions<DeleteProjectRoleMutation, DeleteProjectRoleMutationVariables>
) => {
  const [deleteFn] = useMutation(deleteProjectRoleMutation, options);

  const deleteProjectRole = (roleID: UUID, collaboratorRoleInputs?: CollaboratorRoleInput[]) => {
    deleteFn({
      variables: {
        projectID,
        roleID,
        collaboratorRoleInputs: collaboratorRoleInputs || [],
      },
      refetchQueries: REFETCH_PROJECT_ROLES,
    });
  };

  return [deleteProjectRole];
};
