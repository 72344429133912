import { createStyles } from '@material-ui/core';

import { KomodoTheme, singleLine } from '../../../theme/komodo-mui-theme';

export default (theme: KomodoTheme) =>
  createStyles({
    button: {
      fontFamily: theme.fontFamily,
      border: theme.border.invisible,
      borderRight: theme.border.line,
      flexGrow: 0,
      lineHeight: 'unset !important',
      padding: '6px 12px',
      '&:hover': {
        backgroundColor: theme.palette.backgroundGrey,
      },
      '&:focus': {
        outline: theme.border.invisible,
      },
      '&:focus-visible': {
        background: theme.palette.transparent,
        border: theme.border.focus,
        outline: theme.border.focus,
      },
      '&:first-of-type': {
        '&:focus': {
          outline: theme.border.invisible,
        },
        '&:focus-visible': {
          background: theme.palette.transparent,
          border: theme.border.focus,
          outline: theme.border.focus,
        },
        borderBottomLeftRadius: '3px',
        borderBottomRightRadius: '0px',
        borderTopLeftRadius: '3px',
        borderTopRightRadius: '0px',
      },
      '&:last-child': {
        '&:hover': {
          outline: singleLine(theme.palette.backgroundGrey),
        },
        '&:focus': {
          outline: theme.border.invisible,
        },
        '&:focus-visible': {
          background: theme.palette.transparent,
          border: theme.border.focus,
          outline: theme.border.focus,
        },
        marginLeft: 1,
        borderRight: theme.border.invisible,
        borderBottomLeftRadius: '0px',
        borderBottomRightRadius: '3px',
        borderTopLeftRadius: '0px',
        borderTopRightRadius: '3px',
      },
    },
    buttonCompact: {
      padding: '4px 12px',
      whiteSpace: 'nowrap',
    },
    containerButtons: {
      border: singleLine(theme.palette.joinGrey400),
      borderRadius: '4px',
      display: 'grid',
      gridAutoFlow: 'column',
    },
    containerOuter: {
      display: 'flex',
      flexDirection: 'column',
    },
    label: {
      ...theme.typography.label,
    },
    selected: {
      background: `${theme.palette.selectedBackground} !important`,
    },
    title: {
      margin: 0,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    toggle: {
      borderRadius: theme.border.radius,
      display: 'inline-flex',
      border: theme.border.line,
      height: 38, // to match the Join Select
      margin: '4px 4px 0 0',
      backgroundColor: theme.palette.backgroundWhite,
    },
    toggleCompact: {
      height: 34, // to match the Join Select compact
    },
  });
