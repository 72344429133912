import { useState } from 'react';

import { VisibilityView } from '../../../generated/graphql';
import { getProjectIdFromUrl } from '../../../utilities/url';
import { useItemsListQuery } from '../../ItemsList/useItemsListQuery';
import useSetMilestoneStatus from '../../Milestone/hooks/SetMilestoneStatusHook';
import useMemoWrapper from '../../useMemoWrapper';
import useMilestonesQuery from '../hooks/useMilestonesQuery';

import DeleteActiveMilestoneDialog from './DeleteActiveMilestoneDialog';
import DeleteMilestoneConfirmDialog from './DeleteMilestoneConfirmDialog';
import DeleteMilestoneDraftItemsDialog from './DeleteMilestoneDraftItemsDialog';
import DeleteMilestoneItemsAndDraftItemsDialog from './DeleteMilestoneItemsAndDraftItemsDialog';
import DeleteMilestoneItemsDialog from './DeleteMilestoneItemsDialog';
import { getItemCreators } from './utilities';

export type Props = {
  isActiveMilestone: boolean;
  isOpen: boolean;
  milestone: Pick<Milestone, 'id' | 'name' | 'itemsCount' | 'draftItemsCount' | 'isDraft'>;
  onClose: () => void;
  onDelete: () => void;
};

export default function DeleteMilestoneDataDialog(props: Props) {
  const projectID = getProjectIdFromUrl();
  const { id, itemsCount = 0, draftItemsCount = 0 } = props.milestone ?? {};
  const [milestone, setMilestone] = useState<Pick<Milestone, 'id' | 'name'>>();

  const milestonesQueryResult = useMilestonesQuery(
    projectID || '',
    false,
    !props.isOpen || !props.isActiveMilestone
  );

  const itemsListQueryResult = useItemsListQuery(
    id,
    null,
    projectID,
    false,
    {},
    VisibilityView.PRIVATE_VIEW,
    [],
    false,
    draftItemsCount === 0 || !props.isOpen
  );
  const creators = useMemoWrapper(getItemCreators, itemsListQueryResult.data);

  const onSelectMilestone = (milestone: Pick<Milestone, 'id' | 'name'>) => setMilestone(milestone);
  const [setMilestoneStatus] = useSetMilestoneStatus();
  const onSetActiveSuccess = () => {
    props.onDelete();
  };
  const onSetActive = () => {
    setMilestoneStatus(projectID, milestone?.id ?? '', onSetActiveSuccess);
  };
  const onDeleteActive = () => onSetActive();

  if (milestonesQueryResult.loading) return null;

  if (itemsCount !== 0 && draftItemsCount === 0) {
    return (
      <DeleteMilestoneItemsDialog
        {...props}
        // display number of items in the milestone
        itemsCount={itemsCount}
      />
    );
  }
  if (itemsCount === 0 && draftItemsCount !== 0) {
    return (
      <DeleteMilestoneDraftItemsDialog
        {...props}
        draftItemsCount={draftItemsCount}
        draftItemsCreators={creators}
      />
    );
  }
  if (itemsCount !== 0 && draftItemsCount !== 0) {
    return (
      <DeleteMilestoneItemsAndDraftItemsDialog
        {...props}
        draftItemsCount={draftItemsCount}
        draftItemsCreators={creators}
        itemsCount={itemsCount}
      />
    );
  }
  if (props.isActiveMilestone) {
    return (
      <DeleteActiveMilestoneDialog
        {...props}
        milestones={milestonesQueryResult.data?.milestones ?? []}
        onDelete={onDeleteActive}
        onSelect={onSelectMilestone}
        selectedMilestone={milestone}
      />
    );
  }
  return <DeleteMilestoneConfirmDialog {...props} />;
}
