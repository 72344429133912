import { FC, useState } from 'react';

import { ArrowForward, DeleteOutline } from '@material-ui/icons';

import {
  CompanyAdminEventType,
  companyAdminAnalyticsEvent,
} from '../../../../analytics/analyticsEventProperties';
import { Org } from '../../../../generated/graphql';
import useSendAnalytics from '../../../../hooks/useSendAnalytics';
import { Button, Dialog, DialogContent, IconButton, Tooltip } from '../../../scales';
import useDeleteOrgLevelMutation from '../hooks/useDeleteOrgLevelMutation';

import OrgLevelInput from './OrgLevelInput';

interface LevelInputProps {
  levelNumber: number;
  levelName: string;
  levels: string[];
  levelsCount: number;
  organization: Org;
}

const LevelInput: FC<LevelInputProps> = ({
  levelNumber,
  levelName,
  levels,
  levelsCount,
  organization,
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const deleteOrgLevel = useDeleteOrgLevelMutation();
  const onDelete = () => {
    deleteOrgLevel(organization.id, levelName);
  };
  const sendAnalytics = useSendAnalytics();
  return (
    <div className="flex gap-1">
      <div className="flex max-w-xs flex-row items-baseline gap-1">
        <OrgLevelInput
          key={levelName}
          levelName={levelName}
          levelNumber={levelNumber}
          levels={levels}
          organizationID={organization.id}
        />
      </div>
      <div className="flex flex-row items-baseline">
        <Tooltip content={levelsCount === 1 ? '' : 'Delete Level'}>
          <IconButton
            aria-label="delete"
            icon={<DeleteOutline color={levelsCount > 1 ? 'error' : 'disabled'} />}
            isDisabled={levelsCount === 1}
            onClick={() => {
              setIsDialogOpen(true);
              sendAnalytics(
                companyAdminAnalyticsEvent(CompanyAdminEventType.ORGANIZATIONS_DELETE_LEVEL, {
                  levelName,
                })
              );
            }}
            type="secondary"
          />
        </Tooltip>
      </div>
      {levelNumber < levelsCount && (
        <div className="flex flex-row items-baseline pt-2">
          <ArrowForward color="disabled" />
        </div>
      )}
      <Dialog
        footerRight={
          <Button
            label="Delete"
            onClick={() => {
              onDelete();
              setIsDialogOpen(false);
              sendAnalytics(
                companyAdminAnalyticsEvent(
                  CompanyAdminEventType.ORGANIZATIONS_DELETE_CONFIRM_DELETE,
                  {
                    levelName,
                  }
                )
              );
            }}
            startIcon={<DeleteOutline />}
            type="destructive"
          />
        }
        isOpen={isDialogOpen}
        onClose={() => {
          setIsDialogOpen(false);
          sendAnalytics(
            companyAdminAnalyticsEvent(CompanyAdminEventType.ORGANIZATIONS_DELETE_CONFIRM_CLOSE, {
              levelName,
            })
          );
        }}
        title={`Delete Level: ${levelName}`}
      >
        <DialogContent>
          <div>
            Are you sure? By deleting this organization level, all nodes on this level will be
            deleted and nodes below this level will be reassigned to the parent level.{' '}
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default LevelInput;
