import { useState } from 'react';

import { ButtonBase } from '@material-ui/core';

import { ToastType } from '../../../api/gqlEnums';
import { setToast } from '../../../hooks/useToastParametersLocalQuery';
import { withStyles } from '../../../theme/komodo-mui-theme';
import { EscalationTargetLocation } from '../constants/projectCompTypes';

import EditLocationTargetDialog from './EditLocationTargetDialog';
import styles from './LocationEscalationTargetStyles';

const LocationEscalationTarget = (props: {
  classes: Classes<typeof styles>;
  dataCy: string;
  isFuzzyMatch: boolean;
  onTargetLocationChange: (location: EscalationTargetLocation) => void;
  targetLocation: EscalationTargetLocation;
}) => {
  const { classes, isFuzzyMatch, targetLocation } = props;
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  return (
    <div>
      <ButtonBase
        className={`${classes.button} ${
          isFuzzyMatch ? classes.button_fuzzyMatch : classes.button_autoEscalated
        }`}
        data-cy={`${props.dataCy}-button`}
        onClick={() => setIsDialogOpen(true)}
      >
        <div data-cy={props.dataCy}>{targetLocation?.location}</div>
      </ButtonBase>
      {isDialogOpen && (
        <EditLocationTargetDialog
          isFuzzyMatch={isFuzzyMatch}
          onApply={(newTargetlocation) => {
            props.onTargetLocationChange({
              location: newTargetlocation.name,
              lat: newTargetlocation.lat ?? undefined,
              lon: newTargetlocation.lon ?? undefined,
            });
            setIsDialogOpen(false);
            setToast(
              { message: `Target location changed to ${newTargetlocation.name}` },
              ToastType.SUCCESS
            );
          }}
          onClose={() => setIsDialogOpen(false)}
          targetLocation={targetLocation}
        />
      )}
    </div>
  );
};

export default withStyles(styles)(LocationEscalationTarget);
