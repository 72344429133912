import { RefObject } from 'react';

export type PdfPageCanvas = { height: number; width: number };

export const getAndSetPageRatio = (
  pageRef: RefObject<PdfPageCanvas>,
  setPageRatio: (ratio: number) => void
) => {
  const page = pageRef?.current;
  if (!page) return;
  const { height, width } = page;
  setPageRatio(width / height);
};
