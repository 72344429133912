import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';

export default (theme: KomodoTheme) =>
  createStyles({
    highlight: {
      zIndex: 3,
      display: 'block',
      position: 'fixed',
      background: theme.palette.backgroundGreen,
      pointerEvents: 'none',
    },
  });
