import { useMutation } from '@apollo/client';

import { deprecateItemAnalytics } from '../../../../analytics/analyticsEventProperties';
import { REFETCH_DEPRECATE_ITEM } from '../../../../api/refetchSets';
import {
  DeprecateItemMutation,
  DeprecateItemMutationVariables,
} from '../../../../generated/graphql';
import useSendAnalytics from '../../../../hooks/useSendAnalytics';

import { deprecateItemMutation } from './queries';

function useDeprecateItem(
  projectID: UUID,
  itemID: UUID,
  onSuccess: () => void,
  onFailure: () => void
) {
  const [deprecateItemFunction, deprecateItemResult] = useMutation<
    DeprecateItemMutation,
    DeprecateItemMutationVariables
  >(deprecateItemMutation);
  const triggerAnalytics = useSendAnalytics();
  const deprecateItem = () =>
    deprecateItemFunction({
      variables: { projectID, itemID },
      refetchQueries: REFETCH_DEPRECATE_ITEM,
    })
      .then(() => {
        onSuccess();
        // send analytics
        triggerAnalytics(deprecateItemAnalytics(itemID));
      })
      .catch((e) => {
        onFailure();
        // send analytics
        triggerAnalytics(deprecateItemAnalytics(e));
      });
  return { deprecateItem, deprecateItemResult };
}

export default useDeprecateItem;
