import { createStyles } from '@material-ui/core';

export default () =>
  createStyles({
    buttonContainer: {
      padding: '30px 30px 30px 0px',
      marginLeft: 20,
    },
    contentContainer: {
      display: 'flex',
      justifyContent: 'space-between',
    },
  });
