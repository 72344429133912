export type HiddenCostsLine = {
  displayName: string;
  isExcluded: boolean;
};

export const categoryDescriptor = (categoryContent: CategoryContentInput) => {
  const { name, number } = categoryContent;
  if (name && number) return `${number} - ${name}`;
  if (name) return name;
  return number;
};

export const getHiddenCategoryLines = (excludedCategoryContents: CategoryContentInput[]) => {
  return excludedCategoryContents.map((categoryContent) => ({
    displayName: categoryDescriptor(categoryContent),
    isExcluded: true,
  }));
};

export const getHiddenMarkupLines = (excludedMarkupNames: string[]) => {
  return excludedMarkupNames.map((markupName) => ({
    displayName: markupName,
    isExcluded: true,
  }));
};
