import { CalendarDate, getLocalTimeZone } from '@internationalized/date';

/** @toCalendarDate
 * Converts any number of date string formats (i.e "2024-02-14T08:00:00.000Z" or "2/14/2024") into a CalendarDate
 * */
export function toCalendarDate(dateString: string | null | undefined) {
  if (typeof dateString !== 'string') return dateString;
  const date = new Date(dateString);
  const calendarDate = new CalendarDate(date.getFullYear(), date.getMonth() + 1, date.getDate());
  if ([calendarDate.month, calendarDate.day, calendarDate.year].includes(NaN)) return null;
  return calendarDate;
}

/** @toISODateString
 * Converts a CalendarDate into an ISOString (i.e "2024-02-14T08:00:00.000Z")
 * */
export function toISODateString(date: CalendarDate) {
  return date.toDate(getLocalTimeZone()).toISOString();
}

/** @toLocaleDateString
 * Converts a CalendarDate into a LocaleDateString for display (i.e "2/14/2024")
 * */
export function toLocaleDateString(date: CalendarDate) {
  return date.toDate(getLocalTimeZone()).toLocaleDateString();
}

export function isValidDate(value: CalendarDate, minValue?: CalendarDate, maxValue?: CalendarDate) {
  if (minValue && value.compare(minValue) < 0) return false;
  if (maxValue && value.compare(maxValue) > 0) return false;
  return true;
}

export function getErrorMessage(
  value: CalendarDate | null,
  minValue?: CalendarDate,
  maxValue?: CalendarDate
) {
  if (value && maxValue && value.compare(maxValue) > 0)
    return `Date must be on or before ${toLocaleDateString(maxValue)}`;
  if (value && minValue && value.compare(minValue) < 0)
    return `Date must be on or after ${toLocaleDateString(minValue)}`;
  return undefined;
}
