import {
  ProgramEligibleProjectsQuery,
  ProgramEligibleProjectsQueryVariables,
} from '../../../../generated/graphql';
import { useQuery } from '../../../../hooks/useMountAwareQuery';
import { programEligibleProjectsQuery } from '../../queries';

// Ensure we always requery when mounting this component so we don't miss any
// newly created projects
export const useProgramEligibleProjectsQuery = () =>
  useQuery<ProgramEligibleProjectsQuery, ProgramEligibleProjectsQueryVariables>(
    programEligibleProjectsQuery
  );
