import { useReducer } from 'react';

// A generic hook that returns a function which, when called, will force a re-render of
// the component the hook was in, even if this is outside. One should take care that the
// component actually exists before calling it -- no harm will be done if you don't, but
// there will be a nasty-looking console error.
const useUpdate = () => {
  const updater = useReducer((x) => x + 1, 0);
  return updater[1];
};

export default useUpdate;
