import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';

const styles = (theme: KomodoTheme) =>
  createStyles({
    bodyContent: {
      backgroundColor: theme.palette.sidebarBackground,
      display: 'flex',
      flexDirection: 'column',
      overflow: 'auto',
    },
    collapseHeight: {
      overflowY: 'auto',
      background: theme.palette.backgroundWhite,
      minHeight: '75px',
    },
    containerPadding: {
      padding: '8px 18px',
      flexGrow: 1,
    },
    estimateIcon: {
      height: 36,
      width: 36,
    },
    estimateNullTextHeader: {
      color: theme.palette.disabledGrey,
    },
    icon: {
      fill: theme.palette.disabledGrey,
      width: 16,
      height: 16,
      marginTop: 4,
      marginLeft: 4,
    },
  });

export default styles;
