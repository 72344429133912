import { FC } from 'react';

import { Typography } from '@material-ui/core';

import { withStyles } from '../../../../theme/komodo-mui-theme';
import { formatCommas } from '../../../../utilities/currency';

import { styles } from './CostReportListHeaderStyles';
import { ListHeader, getHeaderWidth } from './CostReportListHeaderUtils';

type CostReportListHeaderColumnGroupProps = {
  classes: Classes<typeof styles>;
  header: ListHeader;
  index: number;
  isVariance: boolean;
  numSubheaders: number;
};

const CostReportListHeaderColumnGroup: FC<CostReportListHeaderColumnGroupProps> = ({
  classes,
  header,
  index,
  isVariance,
  numSubheaders,
}) => {
  const { text, subtext = '', isWide = false, metrics = [] } = header;
  const key = `${text}${index}header`;

  const headerSection = (textContent: string, subtextContent: string, isMetric = false) => (
    <>
      <Typography
        className={`${classes.bold} ${classes.wordBreakNormal}`}
        data-cy={isMetric ? 'header-metricText' : 'header-milestoneName'}
        title={textContent}
        variant="subheading"
      >
        {textContent}
      </Typography>
      <Typography
        className={classes.bold}
        data-cy={isMetric ? 'header-metricValue' : 'header-varianceColumnTerm'}
        variant="body1"
      >
        {subtextContent}
      </Typography>
    </>
  );

  const varianceContent = (
    <div
      className={`${classes.varianceHeader} ${
        numSubheaders !== 1 ? classes.alignLeft : classes.alignRight
      }`}
    >
      <div className={classes.varianceHeaderColumn}>{headerSection(text, subtext)}</div>
      <div className={classes.varianceHeaderMetricSection}>
        {metrics &&
          metrics.map((metric) => {
            const { abbreviation, magnitude } = metric;
            return (
              <div
                key={`${abbreviation}:${magnitude}`}
                className={classes.varianceHeaderMetricColumn}
              >
                {headerSection(`Total ${abbreviation}`, formatCommas(magnitude), true)}
              </div>
            );
          })}
      </div>
    </div>
  );

  const costReportContent = (
    <div
      className={`${classes.milestoneCostReportHeader} ${
        numSubheaders !== 1 ? classes.alignLeft : classes.alignRight
      }`}
    >
      {headerSection(text, '')}
    </div>
  );

  return (
    <div
      key={key}
      className={classes.columnHeaderCell}
      style={{
        width: getHeaderWidth(isVariance, numSubheaders, isWide),
      }}
    >
      {isVariance ? varianceContent : costReportContent}
    </div>
  );
};

export default withStyles(styles)(CostReportListHeaderColumnGroup);
