import { FC } from 'react';

import { DRAG_DROP_ASSET_ITEM_DETAILS } from '../../../actions/actionTypes';
import { getItemAssetAnalytics } from '../../../analytics/analyticsEventProperties';
import { PermissionResource } from '../../../generated/graphql';
import usePermissions from '../../../utilities/permissions/usePermissions';
import { useItemQuery } from '../../Items/hooks/useItemQuery';
import { useItemUploadAttachAssets } from '../hooks/AssetsMutationHook';
import useOptimisticItemUploadAssets from '../hooks/useOptimisticItemUploadAssets';
import useOptimisticProjectUploadAssets from '../hooks/useOptimisticProjectUploadAssets';

import AssetsWrapper from './AssetsWrapper';
import { useItemAttachments } from './AssetsWrapperUtils';

type AssetsWrapperItemDataProps = {
  itemID?: UUID;
  onItemMutated?: () => void;
};

const AssetsWrapperItemData: FC<AssetsWrapperItemDataProps> = ({ itemID, onItemMutated }) => {
  const { data: { item } = {} } = useItemQuery(itemID);

  const { canView, canAdd, canDelete } = usePermissions({
    trades: item?.categories,
  });

  // Permissions
  const canViewItemAttachments = canView(PermissionResource.ITEM_ATTACHMENTS);
  const canAddItemAttachments = canAdd(PermissionResource.ITEM_ATTACHMENTS);
  const canDeleteItemAttachments = canDelete(PermissionResource.ITEM_ATTACHMENTS);

  const analyticsEvent = getItemAssetAnalytics(DRAG_DROP_ASSET_ITEM_DETAILS, itemID);
  const { isAttach, onAttachAsset, onDropFile, uploadingAssets } = useItemUploadAttachAssets(
    item?.id || '',
    analyticsEvent,
    { onCompleted: onItemMutated }
  );

  const queriedAssetItemID = canViewItemAttachments ? itemID : undefined;
  const { itemAssets, loading } = useItemAttachments(queriedAssetItemID);

  useOptimisticItemUploadAssets(queriedAssetItemID || '', uploadingAssets, isAttach);
  useOptimisticProjectUploadAssets(uploadingAssets);

  return (
    <AssetsWrapper
      assets={itemAssets}
      assetsLoading={loading}
      hasAssetAddPermission={canAddItemAttachments}
      hasAssetDeletePermission={canDeleteItemAttachments}
      itemID={itemID}
      onAttachAsset={onAttachAsset}
      onDropFile={onDropFile}
      onItemMutated={onItemMutated}
    />
  );
};

export default AssetsWrapperItemData;
