import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';

const inputHeight = 38;
const inputHeightCompact = 33;

export default (theme: KomodoTheme) =>
  createStyles({
    closeBg: {
      alignSelf: 'flex-start',
      background: theme.palette.backgroundWhite,
      border: theme.border.line,
      borderRadius: theme.border.radius,
      cursor: 'pointer',
      padding: 0,
      width: '100%',
      '&:focus': {
        border: theme.border.focus,
        outline: theme.border.focus,
      },
    },
    height: {
      height: inputHeight,
    },
    heightCompact: {
      height: inputHeightCompact,
    },
    dropdownArrow: {
      content: '',
      margin: '6px 4px 4px auto',
      borderTop: '5px solid black',
      borderLeft: '5px solid transparent',
      borderRight: '5px solid transparent',
      cursor: 'pointer',
      '&:hover': {
        '&:after': {
          opacity: 0.6,
        },
      },
      '&:after': {
        content: 'no-open-quote',
        width: '24px',
        height: '24px',
        position: 'absolute',
        transform: 'translate(-50%, -15px)',
        borderRadius: '100%',
        backgroundColor: theme.palette.hoverSemitransparentBackground,
        opacity: 0,
      },
    },
    dropdownArrowExpanded: {
      transform: 'rotate(180deg)',
      paddingTop: 1,
    },
    dropdownArrowGrid: {
      margin: '1px 3px 4px auto',
    },
    containerChip: {
      ...theme.typography.h3,
      alignItems: 'center',
      borderRadius: theme.border.radius,
      boxSizing: 'border-box',
      display: 'flex',
      fontFamily: theme.typography.fontFamily,
      justifyContent: 'space-between',
      padding: 4,
      textAlign: 'left',
      width: '100%',
    },
    empty: {
      color: theme.palette.subtitleGrey,
    },
    input: {
      alignItems: 'center',
      // border: theme.border.line,
      borderRadius: theme.border.radius,
      boxSizing: 'border-box',
      display: 'flex',
      fontFamily: theme.typography.fontFamily,
      padding: 4,
      width: `100%`,
      // background: theme.palette.backgroundWhite,
    },
    inputCompact: {
      minHeight: inputHeightCompact,
      padding: '0px 4px 0px 4px',
    },
    inputGrid: {
      borderRadius: 0,
      height: inputHeightCompact,
      minHeight: 0,
    },
    value: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
  });
