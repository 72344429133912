import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';

const styles = (theme: KomodoTheme) =>
  createStyles({
    content: {
      overflowY: 'auto',
      minHeight: 250,
      textAlign: 'center',
      padding: 30,
    },
    innerPaper: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden',
      border: theme.border.line,
    },
    margin: {
      margin: 12,
    },
    paddingTop: {
      paddingTop: 24,
    },
    textBold: {
      fontWeight: 500,
    },
  });

export default styles;
