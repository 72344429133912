import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';

export default (theme: KomodoTheme) =>
  createStyles({
    avatarSimplified: {
      width: theme.iconSizes.compact,
      height: theme.iconSizes.compact,
      marginRight: 2,
      marginTop: 2,
    },
    row: {
      display: 'flex',
      gap: '8px',
      paddingLeft: 0,
      flexGrow: 1,
    },
    name: {
      flexGrow: 1,
      padding: '9px 4px',
    },
    line: {
      width: 1,
      height: '100%',
      position: 'absolute',
      backgroundColor: theme.palette.joinGrey400,
      left: 44,
    },
    quote: {
      width: 16,
      height: 16,
      marginTop: 8,
      padding: '4px 0',
      transform: 'scaleX(-1)',
      backgroundColor: 'white',
    },
    commentContainer: {
      display: 'flex',
      flexDirection: 'row',
    },
    grow: {
      flexGrow: 1,
    },
    commentText: {
      maxWidth: '45em',
      whiteSpace: 'pre-wrap',
    },
    toolbar: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingLeft: 0,
      color: theme.palette.shadedGrey,
    },
    key: {
      border: `1px solid ${theme.palette.shadedGrey}`,
      padding: '0 2px',
      borderRadius: 2,
    },
    button: {
      marginTop: 4,
      padding: '4px 8px',
      minHeight: 0,
      borderRadius: 0,
    },
  });
