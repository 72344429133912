import { useCallback } from 'react';

import { EventProperties, navEventTypes } from '../../../analytics/analyticsEventProperties';
import useSendAnalytics from '../../../hooks/useSendAnalytics';

export default function useSendNavAnalytics() {
  const sendAnalytics = useSendAnalytics();

  return useCallback(
    (type: navEventTypes, eventProperties?: EventProperties) => {
      sendAnalytics({
        type: `nav_${type}`,
        eventProperties,
      });
    },
    [sendAnalytics]
  );
}
