import { FC } from 'react';

import { SORT_ITEM_NUMBERS } from '../../../constants';
import { MilestoneEstimateInfo } from '../../../generated/graphql';
import { sortItemsBy } from '../../../utilities/sorting';
import { CollapseSettings } from '../../../utilities/urlState';
import { ColumnDescription } from '../CostReportColumns/CostReportColumns';
import CostReportCategoriesTree, {
  CostReportCategoriesTreeNode,
} from '../CostReportList/CostReportCategoriesTree';
import CostReportListRow from '../CostReportList/CostReportListRow/CostReportListRow';
import { RowVariants } from '../CostReportList/CostReportListRow/CostReportListRowUtils';

type CostReportMarkupsSectionProps = {
  breakpoints?: number[];
  canViewMarkups: boolean;
  columnKeyQuantityMap: Map<string, Numeric>;
  displayColumnDescriptions: ColumnDescription[];
  headerDescriptions: ColumnDescription[];
  isPrint: boolean;
  isVariance: boolean;
  markupsTree: CostReportCategoriesTree;
  reportAnalyticsExpandCollapse: (collapse: boolean) => void;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  scroll?: boolean;
  setCollapse?: (bool: boolean, nodes: string[], node: string) => void;
  settings: CollapseSettings;
  isNotesColumnExpanded?: boolean;
  setNotesColumnExpanded?: (isExpanded: boolean) => void;
  isAddingNoteInReport?: boolean;
  setAddingNoteInReport?: (isAddingNote: boolean) => void;
  currentReportID?: UUID;
  isCurrentReportShared?: boolean;
  groupByIDs?: UUID[];
  milestoneEstimates: MilestoneEstimateInfo[];
  hasComments: boolean;
  canDeleteUserReportComment: boolean;
  isTotalIncluded: boolean;
};

const CostReportMarkupsSection: FC<CostReportMarkupsSectionProps> = ({
  breakpoints = [],
  canViewMarkups,
  columnKeyQuantityMap,
  displayColumnDescriptions,
  headerDescriptions,
  isPrint,
  isVariance,
  markupsTree,
  reportAnalyticsExpandCollapse,
  scroll,
  setCollapse = () => {},
  settings,
  isNotesColumnExpanded = false,
  setNotesColumnExpanded = () => {},
  isAddingNoteInReport = false,
  setAddingNoteInReport = () => {},
  currentReportID,
  isCurrentReportShared = false,
  groupByIDs,
  milestoneEstimates,
  hasComments,
  canDeleteUserReportComment,
  isTotalIncluded,
}) => {
  const { branches } = markupsTree.root;
  if (!canViewMarkups) {
    return (
      branches &&
      sortItemsBy[SORT_ITEM_NUMBERS](branches).map((branch: CostReportCategoriesTreeNode) => (
        <CostReportListRow
          key={JSON.stringify(branch.data)}
          breakpoints={breakpoints}
          canDeleteUserReportComment={canDeleteUserReportComment}
          columnKeyQuantityMap={columnKeyQuantityMap}
          currentReportID={currentReportID}
          displayColumnDescriptions={displayColumnDescriptions}
          groupByIDs={groupByIDs}
          hasComments={hasComments}
          headerDescriptions={headerDescriptions}
          isAddingNoteInReport={isAddingNoteInReport}
          isCurrentReportShared={isCurrentReportShared}
          isNotesColumnExpanded={isNotesColumnExpanded}
          isPrint={isPrint}
          isTotalIncluded={isTotalIncluded}
          isVariance={isVariance}
          milestoneEstimates={milestoneEstimates}
          scroll={scroll}
          setAddingNoteInReport={setAddingNoteInReport}
          setCollapse={setCollapse}
          setNotesColumnExpanded={setNotesColumnExpanded}
          settings={settings as CollapseSettings}
          treeNode={branch}
          variant={RowVariants.MARKUP}
        />
      ))
    );
  }

  return (
    <CostReportListRow
      breakpoints={breakpoints}
      canDeleteUserReportComment={canDeleteUserReportComment}
      columnKeyQuantityMap={columnKeyQuantityMap}
      currentReportID={currentReportID}
      displayColumnDescriptions={displayColumnDescriptions}
      groupByIDs={groupByIDs}
      hasComments={hasComments}
      headerDescriptions={headerDescriptions}
      isAddingNoteInReport={isAddingNoteInReport}
      isCurrentReportShared={isCurrentReportShared}
      isNotesColumnExpanded={isNotesColumnExpanded}
      isPrint={isPrint}
      isTotalIncluded={isTotalIncluded}
      isVariance={isVariance}
      milestoneEstimates={milestoneEstimates}
      reportAnalyticsExpandCollapse={reportAnalyticsExpandCollapse}
      scroll={scroll}
      setAddingNoteInReport={setAddingNoteInReport}
      setCollapse={setCollapse}
      setNotesColumnExpanded={setNotesColumnExpanded}
      settings={settings}
      treeNode={markupsTree.root}
      variant={RowVariants.MARKUP}
    />
  );
};

export default CostReportMarkupsSection;
