import { CircularProgress } from '@material-ui/core';
import { FolderOpen } from '@material-ui/icons';

import { withStyles } from '../../../../theme/komodo-mui-theme';
import { getExtension } from '../../../../utilities/string';
import { isImage } from '../../../assets/utils';

import FileImage from './FileImage';
import styles from './FilesTableStyles';
import { FileTableCell } from './FilesTableUtils';
import FileWithBadgeIcon from './FileWithBadgeIcon/FileWithBadgeIcon';

type Props = {
  classes: Classes<typeof styles>;
  entry: FileTableCell;
};

function FileThumb(props: Props) {
  const { classes, entry } = props;
  if (entry.pending) {
    return (
      <div className={classes.avatarTable}>
        <CircularProgress />
      </div>
    );
  }
  if (isImage(entry.name)) {
    return <FileImage assetID={entry.id} />;
  }
  return (
    <div className={classes.avatarTable}>
      {entry.isFolder ? (
        <FolderOpen className={classes.folderIcon} />
      ) : (
        <FileWithBadgeIcon extension={getExtension(entry.name)} />
      )}
    </div>
  );
}

export default withStyles(styles)(FileThumb);
