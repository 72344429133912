import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';

const ItemsListItemStyles = (theme: KomodoTheme) =>
  createStyles({
    checkbox: {
      width: '48px',
    },
    checkboxCompact: {
      width: 34,
    },
    collapse: {
      background: 'transparent !important',
      border: '0px none !important',
      padding: '0px !important',
    },
    column1: {
      flex: '1 1 auto',
      display: 'flex',
      marginRight: 'auto',
    },
    column2: {
      flex: '0 1 240px',
      flexDirection: 'column',
      marginLeft: 'auto',
    },
    column3: {
      flex: '0 0 88px',
      flexDirection: 'column',
      marginLeft: 'auto',
    },
    columnSchedule: {
      flex: '0 1 240px',
      flexDirection: 'column',
      marginLeft: 'auto',
    },
    column4: {
      display: 'flex',
      flex: '0 0 88px',
      justifyContent: 'flex-end',
    },
    column4Wide: {
      display: 'flex',
      flex: '0 0 128px',
      justifyContent: 'flex-end',
    },
    column4WithCtaButton: {
      display: 'flex',
      flex: `0 0 170px`,
      flexDirection: 'row',
      justifyContent: 'flex-end',
      marginLeft: theme.spacing.unit,
      minWidth: 170,
      marginRight: `${theme.spacing.generic[4]}px`,
    },
    costImpact: {
      marginBottom: 8,
      marginTop: `${theme.spacing.generic[2]}px`,
    },
    cta: {
      flexShrink: 0,
      textTransform: 'none',
    },
    disabled: {
      opacity: 0.5,
    },
    drawContainer: {
      display: 'flex',
      flexDirection: 'column',
      paddingLeft: 8,
    },
    drawText: {
      ...theme.typography.h3,
      ...theme.typography.number,
      textAlign: 'end',
    },
    noEstimate: {
      textAlign: 'end',
    },
    generalLinkButton: {
      alignItems: 'center',
      background: 'transparent',
      border: 'none',
      cursor: 'pointer',
      display: 'flex',
      padding: `${theme.spacing.generic[12]}px ${theme.spacing.generic[5]}px`,
      width: '100%',
      '@media print': {
        padding: `${theme.spacing.generic[9]}px ${theme.spacing.generic[2]}px`,
      },
    },
    iconsContainer: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      width: 'min-content',
    },
    sidebarItem: {
      backgroundColor: theme.palette.backgroundGreen,
    },
    existingItemButton: {
      textTransform: 'none',
    },
    marginRight: {
      marginRight: 12,
    },
    nested: {
      paddingBottom: '0px !important',
    },
    noPrint: {
      '@media print': {
        display: 'none !important',
      },
    },
    column4Text: {
      textAlign: 'right',
      paddingRight: theme.spacing.unit * 4,
    },
    root: {
      border: theme.border.line,
      borderBottom: 'none',
      color: theme.palette.primaryGrey,
      display: 'flex',
      flexDirection: 'row',
      flexGrow: 1,
      padding: `${theme.spacing.generic[4]}px 0px`,
      pageBreakInside: 'avoid',
      position: 'relative',
      justifyContent: 'space-between',
      '&:last-child': {
        borderBottom: theme.border.line,
      },
      '@media print': {
        border: `0px solid ${theme.palette.joinGrey400}`,
        borderBottom: `2px solid ${theme.palette.joinGrey400}`,
      },
    },
    itemBackground: {
      backgroundColor: theme.palette.backgroundWhite,
    },
    rootCompact: {
      padding: '0px 0px',
    },
    itemPadding: {
      paddingLeft: theme.spacing.generic[4],
    },
    leftContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    itemLikeContainer: {
      paddingLeft: '28px',
    },
    nestedItemLikeContainer: {
      paddingLeft: '30px',
    },
  });

export default ItemsListItemStyles;
