import { merge } from 'lodash';

import grey from '@material-ui/core/colors/grey';
import { createMuiTheme, withStyles as withStylesUntyped } from '@material-ui/core/styles';

import designSystemTheme from './design-system-mui-theme';

const palette = {
  primaryGrey: '#000',
  disabledGrey: '#9b9b9b',
  primaryBlue: '#292B6F',
  blue: '#3F5B9C',
  middleBlue: '#4B71A9',
  borderBlue: '#9FC3E0',
  innerBorder: '#D9D9D9',
  budget: '#5572b7',
  runningTotal: '#000',
  // Statuses
  accepted: '#6AAF6F',
  incorporated: '#08493B',
  rejected: '#E11E29',
  pending: '#F6B901',
  notapplicable: '#D0D5D7',
  notApplicableTint: '#EAEAEA',
  costUp: '#000000',
  red: '#E11E29',
  toastBackground: '#FCE7E1',
  noticeRed: '#ee876b',
  pink: '#ffbaba',
  pinkBadge: '#D388B6',
  costDown: '#000000',
  focusSelectBackground: '#c8cfdb',
  hoverBackground: '#E7EAEF', // this is a distinct use case over-ridden by green
  backgroundGreen: 'rgba(127,250,105,.2)',
  backgroundGreenOpaque: 'rgba(232,254,227,255)',
  backgroundFocusSelectGreen: 'rgba(127,250,105,.4)',
  hoverSemitransparentBackground: `rgba(0, 0, 0, 0.08)`,
  hoverSemitransparentJoinGrey200: `rgba(231,234,239,0.35)`,
  selectedBackground: '#d8dde5',
  transparent: 'transparent',
  backgroundWhite: '#FFF',
  backgroundGrey: '#E7EAEF',
  badgeColor: '#EE876B',
  badgeBackground: '#EE876B33',
  borderDarkGrey: '#BAC1C4',
  focusBorderColor: '#000',
  borderGreen: '#6CF054',
  fillGreen: '#5CD746',
  brightGreen: '#7FFA69',
  nestingGrey: '#F6F7F9',
  shadedGrey: '#686B6C',
  subtitleGrey: '#7C7C7C',
  hiddenGrey: '#C4C4C4',
  buttonGrey: '#494B4B',
  joinGrey100: '#F3F5F7',
  joinGrey133: '#EFF1F4',
  joinGrey200: '#E7EAEF',
  joinGrey400: '#d8dde5',
  joinGrey600: '#c8cfdb',
  joinPrimary: '#000',
  sidebarBackground: '#F3F4F7',
  link: '#5572b7',
  linkFocusBackground: 'transparent',
  toastXHover: '#EAD3CE',
  // Exec Dashboard Charts Colors
  chartBlack: '#000000',
  chartBeige: '#EBE8DC',
  chartBlue: '#4B71A9',
  chartBrown: '#775901',
  chartBrightRed: '#EE441F',
  chartDarkGrey: '#686B6C',
  chartDarkGreen: '#08493B',
  chartFuscia: '#AE4887',
  chartGold: '#C79501',
  chartGrey: '#9B9B9B',
  chartGreen: '#9CD098',
  chartHeaderGrey: '#FBFBFB',
  chartMediumGrey: '#DCDCDC',
  chartLightBlue: '#9FC3E0',
  chartLightBlueGrey: '#CBD1DC',
  chartLightGreen: '#B3DAC6',
  chartLightGrey: '#C2C9CB',
  chartLightRed: '#EE876BB2',
  chartMagenta: '#651449',
  chartMediumBlue: '#759AC5',
  chartMediumGreen: '#6AAF6F',
  chartOrange: '#C75E1A',
  chartPeach: '#E7A57B',
  chartPink: '#D388B6',
  chartRed: '#951818',
  chartRoyalBlue: '#256ACF',
  chartYellow: '#E3D552',
  beta: '#D388B6',
  costTypeLabor: '#f7e3a2',
  costTypeEquipment: '#dcebe2',
  costTypeMaterial: '#d2e0ed',
  costTypeSubcontractor: '#e3c5d9',
  costTypeOther: '#efc7ac',
  costTypeUser: '#ebe8dc',

  // Sourced from Figma: Colors and Type -> Color - App
  app: {
    badgesCategoryOption: '#F3E7C9',
    badgesCategoryItem: '#B3DAC6',
    badgesNew: '#D388B6',
    borderGrey: '#D0D5D7',
    disabledBackground: '#E7EAEF',
    disabledText: '#9B9B9B',
    focusFill: '#5CD746',
    focusOutline: '#6CF054',
    highlight: '#7FFA6933',
    selected: '#7FFA6966',
  },
  // Source from Figma: Colors and Type -> Color - Brand Palette
  brand: {
    black: '#000',
    middleBlue: '#4B71A9',
    nestingGray1: '#F6F7F9',
    nestingGray2: '#E7EAEF',
    nestingGray3: '#DDE2E9',
    nestingGray4: '#C2C9CB',
    nestingGray5: '#C2C9CB',
    nestingGray6: '#979A9B',
    nestingGray7: '#686B6C',
    white: '#fff',
  },
  // Bauhaus color palette - used in Insights chart
  bauhaus: {
    red: '#CF0909',
    yellow: '#CC970E',
    lightGreen: '#98AD16',
    blue: '#2B56ED',
    black: '#0A0F1F',
    brown: '#AA612D',
    pink: '#DD8A8A',
    orange: '#FF7B31',
    darkGreen: '#17712A',
    grey: '#7E7C72',
  },
  costTypes: {
    labor: '#F4E4AA',
    equipment: '#DFEAE3',
    material: '#D5E0EC',
    subcontractor: '#DFC6D8',
    other: '#E9C7B0',
    user: '#EAE8DD',
  },
  chartsThemes: {
    pink: '#FF9C9C',
    yellow: '#FFC736',
    blue: '#2B56ED',
    orange: '#ED8E51',
    red: '#A02339',
    purple: '#A692DE',
    green: '#98AD16',
    lightBlue: '#B3DAC6',
    darkBlue: '#084A66',
  },
  // MUI uses these colors for Tabs
  primary: { ...grey },
  secondary: { ...grey },
  rvtIcon: '#176cfe',
  // Variance Modal Units Colors
  unitBeige: '#EBE8DC',
  unitBlue: '#4B71A9',
  unitMidPurple: '#9669AB',

  unitLightGreen: '#B3DAC6',
  unitFuscia: '#AE4887',
  unitMagenta: '#651449',
  unitYellow: '#E3D552',
  unitOrange: '#C75E1A',
  unitPeach: '#E7A57B',
  unitPink: '#D388B6',
  unitRed: '#951818',
  unitRoyalBlue: '#256ACF',
};

const spacing = {
  generic: [2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 32],
  selectIconRight: 5,
  selectPaddingLeft: 8,
  smallIcon: 16,
  unit: 4,
};

const borderShadow = '-4px 0px 4px 0px rgba(0,0,0,0.25)';
const hintShadow = '0 2px 4px rgb(0 0 0 / 50%)';

const fontFamily = '"Larsseit", "Helvetica Neue", "Helvetica", sans-serif';
const letterSpacing = '0.0119em';

const borderGrey = palette.joinGrey400;
export const singleLine = (color: string) => `1px solid ${color}`;
export const singleLine2 = (color: string) => `2px solid ${color}`;
const singleLine3 = (color: string) => `3px solid ${color}`;
const singleDashedLine = (color: string) => `1px dashed ${color}`;
const singleDashedLine2 = (color: string) => `2px dashed ${color}`;
const singleDottedLine = (color: string) => `0.5px dotted ${color}`;

const border = {
  darkGrey: singleLine(palette.joinGrey600),
  focus: singleLine(palette.joinPrimary),
  focusLink: singleLine2(palette.link),
  transparentLinkBorder: singleLine2(palette.transparent),
  invisible: singleLine('rgba(0,0,0,0)'),
  line: singleLine(borderGrey),
  lineDashed: singleDashedLine(borderGrey),
  lineDashed2: singleDashedLine2(borderGrey),
  lineBorderBlack: singleLine(palette.joinPrimary),
  lineBorderBlackDashed: singleDashedLine(palette.joinPrimary),
  lineDotted: singleDottedLine(borderGrey),
  radius: `4px`,
  errorRed: singleLine(palette.red),
  lineDragDropFocus: singleLine3(palette.fillGreen),
  lineDragDropUnFocus: singleLine3(palette.transparent),
};

// TODO: Rectify with other variations and rename
const iconSizes = {
  arrow: {
    arrowDown: {
      color: palette.joinPrimary,
      height: '1em',
      width: '1em',
    },
  },
  small: 16,
  mediumCompact: 20,
  compact: 24,
  medium: 40,
  large: 48,
} as const;

export const themeProps = {
  spacing,
  border,
  borderShadow,
  hintShadow,
  portal: {
    defaultWidth: 268,
    maxHeight: 450,
  },
  cardHeader: {
    padding: '8px 24px',
    backgroundColor: palette.backgroundGrey,
    '@media print': {
      backgroundColor: 'white',
    },
  },
  dialogColumn: {
    maxWidth: 273,
    minWidth: 240,
    padding: 16,
  },
  iconSizes,
  arrowIcon: {
    borderRadius: '50%',
    '&:hover': {
      backgroundColor: palette.backgroundGrey,
    },
  },
  arrowIconCollapsed: {
    transform: 'rotate(-90deg)',
    transition: 'transform 0.2s ease-in',
  },
  arrowIconExpanded: {
    transform: 'rotate(0deg)',
    transition: 'transform 0.2s ease-in',
  },
  arrowIconExpandedRight: {
    borderRadius: '50%',
    transform: 'rotate(90deg)',
  },
  expandNotesCircle: {
    width: 36,
    height: 36,
    background: 'none',
    border: '1px solid',
    borderColor: palette.joinGrey600,
    color: palette.buttonGrey,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: palette.backgroundGrey,
      borderColor: palette.buttonGrey,
    },
  },
  expandNotesCircleDisabled: {
    width: 36,
    height: 36,
    background: palette.backgroundGrey,
    color: palette.disabledGrey,
    cursor: 'pointer',
  },
  opacityFadeTransition: {
    transition: 'opacity 0.3s ease',
  },
  expander: {
    color: palette.joinPrimary,
    marginLeft: -8,
    marginRight: 8,
    '@media print': {
      display: 'none',
    },
  },
  grayscaleFilter: {
    filter: 'grayscale(1)',
  },
  inputs: {
    padding: 6,
  },
  itemFieldWidth: 268,
  modelViewer: {
    backgroundColor: 'rgba(255,255,255,0.8)',
    selectColor: '#4a90e2',
  },
  dropDownArrow: {
    '@media print': {
      display: 'none',
    },
    '&:hover': {
      backgroundColor: palette.backgroundGrey,
      borderRadius: '50%',
    },
    cursor: 'pointer',
    height: 24,
    marginRight: spacing.selectIconRight,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
    position: 'absolute' as any,
    right: 0,
    top: 'calc(50% - 12px)',
    width: 24,
  },
  roundButton: {
    '&:hover': {
      border: border.lineBorderBlack,
    },
    '&:focus': {
      border: border.lineBorderBlack,
      outline: border.lineBorderBlack,
    },
    border: border.line,
    height: iconSizes.medium,
    width: iconSizes.medium,
  },
  linkFocus: {
    '&:focus': {
      background: palette.linkFocusBackground,
      outline: border.focusLink,
    },
    '&:focus-visible': {
      background: palette.linkFocusBackground,
      outline: border.focusLink,
    },
  },
  overrides: {
    MuiButton: {
      root: {
        padding: '8px 16px',
        color: palette.primaryGrey,
        border: border.invisible,
        borderRadius: 40,
        minWidth: 90,
        textTransform: 'none',
      },
      textPrimary: {
        color: palette.primaryGrey,
      },
      sizeSmall: {
        padding: '1px 2px',
      },
      textSecondary: {
        backgroundColor: palette.backgroundGrey,
      },
      containedPrimary: {
        color: 'white',
        boxShadow: 'none',
        backgroundColor: palette.joinPrimary,
      },
      outlinedPrimary: {
        borderColor: palette.joinPrimary,
      },
    },
    MuiButtonBase: {
      root: {
        '&:focus-visible': {
          background: palette.hoverSemitransparentBackground,
        },
      },
    },
    MuiIconButton: {
      root: {
        color: 'currentColor',
      },
    },
    MuiSelect: {
      icon: {
        color: palette.joinPrimary,
        height: '1em',
        marginLeft: spacing.generic[7],
        marginRight: spacing.generic[2],
        width: '1em',
        '@media print': {
          color: 'white',
        },
      },
      select: {
        '&:focus': {
          borderRadius: border.radius,
          backgroundColor: 'white',
        },
        '&:hover': {
          backgroundColor: palette.backgroundGrey,
        },
      },
      disabled: {
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
    },
    MuiSpeedDialAction: {
      button: {
        backgroundColor: '#4a90e2',
        color: 'white',
      },
    },
    MuiChip: {
      avatarChildren: {
        width: 24,
        height: 24,
      },
      deleteIcon: {
        color: 'rgba(255,255,255,0.5)',
        '&:hover': {
          color: 'rgba(255,255,255,1)',
        },
        '@media print': {
          display: 'none',
        },
      },
      label: {
        color: 'white',
        paddingRight: '16px',
        display: 'inline-block',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        maxWidth: '140px',
      },
    },
    MuiCollapse: {
      container: {
        margin: '0 -1px',
      },
      entered: {
        overflow: 'visible', // Added for options list collapse
      },
    },
    MuiDialog: {
      paper: {
        minWidth: 300,
        overflow: 'visible',
        width: '50%',
      },
    },
    MuiDialogContent: {
      root: {
        '&:first-child': {
          paddingTop: 0,
        },
      },
    },
    MuiDialogTitle: {
      root: {
        borderBottom: border.line,
      },
    },
    MuiDialogActions: {
      root: {
        margin: '12px 8px',
      },
    },
    MuiInput: {
      root: {
        fontSize: 'inherit',
        lineHeight: '1rem',
        border: border.line,
        padding: 0,
        borderRadius: border.radius,
        '@media print': {
          borderColor: 'white',
        },
        '& $disabled': {
          color: palette.joinPrimary,
        },
      },
      focused: {
        backgroundColor: 'white',
        border: singleLine(palette.joinPrimary),
        outline: singleLine(palette.joinPrimary),
      },
      fullWidth: {
        width: 'auto',
      },
      multiline: {
        padding: 6,
      },
      disabled: {
        border: '0px solid transparent',
        backgroundColor: 'transparent',
        textOverflow: 'ellipsis',
        paddingLeft: `0px !important`,
      },
      input: {
        padding: 6,
      },
      inputMultiline: {
        overflow: 'hidden',
        color: palette.joinPrimary,
        padding: 0,
        lineHeight: 'unset',
      },
    },
    MuiInputAdornment: {
      positionEnd: {
        marginLeft: 0,
      },
    },
    MuiInputLabel: {
      root: {
        fontWeight: 400,
        color: palette.primaryGrey,
      },
      shrink: {
        width: '133%',
      },
    },
    MuiFormControl: {
      root: {
        wordBreak: 'break-all',
      },
    },
    MuiFormHelper: {
      root: {
        fontWeight: 300,
        color: borderGrey,
      },
    },
    MuiGrid: {
      'spacing-xs-16': {
        margin: 0,
      },
      item: {
        padding: '0px !important',
      },
    },
    MuiList: {
      padding: {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
    MuiCardHeader: {
      action: {
        marginTop: -4,
        '@media print': {
          display: 'none',
        },
        paddingRight: 4,
      },
    },
    MuiCheckbox: {
      root: {
        color: palette.primaryGrey,
      },
    },
    MuiRadio: {
      root: {
        color: palette.primaryGrey,
      },
    },
    MuiTab: {
      label: {
        fontSize: '0.8125rem',
      },
      root: {
        fontWeight: 300,
        color: palette.primaryGrey,
      },
      selected: {
        fontWeight: 500,
      },
    },
    MuiTable: {},
    MuiTableCell: {
      body: {
        fontWeight: 300,
      },
      root: {
        padding: 4,
        border: border.line,
        '&:last-child': {
          paddingRight: 'auto',
        },
      },
      head: {
        fontSize: 'inherit',
        fontWeight: 500,
        color: palette.primaryGrey,
        borderBottom: `2px solid ${borderGrey}`,
      },
      footer: {
        fontSize: 'inherit',
        fontWeight: 500,
        color: 'inherit',
        borderTop: `2px solid ${borderGrey}`,
      },
    },
    MuiTableRow: {
      root: {
        height: 'auto',
      },
      head: {
        height: 'auto',
      },
      footer: {
        height: 'auto',
      },
    },
    MuiTypography: {
      caption: {
        fontWeight: 400,
        color: palette.shadedGrey,
      },
    },
    Textarea: {
      root: {
        padding: 6,
      },
    },
    MuiPickersToolbarButton: {
      toolbarBtn: {
        fontWeight: 500,
        color: 'white',
        fontSize: 20,
      },
      toolbarBtnSelected: {
        fontWeight: 400,
        fontSize: 16,
      },
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: palette.primaryGrey,
        fontFamily,
      },
    },
    MuiPickersDay: {
      isSelected: {
        backgroundColor: palette.primaryGrey,
        fontFamily,
      },
    },
    MuiPickersCalendarHeader: {
      transitionContainer: {
        '& p': {
          fontWeight: 400,
          fontSize: 16,
        },
      },
    },
    MuiPickersModal: {
      dialogAction: {
        '&:last-of-type': {
          backgroundColor: palette.primaryGrey,
          color: 'white',
        },
        '&:hover': {
          backgroundColor: palette.backgroundGrey,
        },
      },
    },
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
  typography: {
    cardHeader: {
      fontSize: '1rem',
      lineHeight: '1rem',
      fontWeight: 400,
    },
    chip: {
      fontWeight: 400,
      fontSize: '14px',
    },
    suppressDeprecationWarnings: true,
    useNextVariants: true,
    fontFamily,
    headline: {
      color: palette.primaryGrey,
      fontWeight: 500,
    },
    subheading: {
      color: palette.primaryGrey,
      fontWeight: 400,
      wordBreak: 'break-word',
    },
    inputs: {
      fontSize: '0.9em',
      lineHeight: '1.5em',
    },
    title: {
      color: 'inherit',
      fontWeight: 400,
      fontSize: '1.3125rem',
      lineHeight: '1.16667rem',
    },
    link: {
      color: palette.link,
      cursor: 'pointer',
      textDecoration: 'underline',
      display: 'inline-block',
      border: '1px solid transparent',
      borderRadius: border.radius,
      paddingRight: `${spacing.generic[1]}px`,
      paddingLeft: `${spacing.generic[1]}px`,
      '&:focus': {
        outline: border.focusLink,
      },
      '&:focus-visible': {
        outline: border.focusLink,
      },
    },
    printTitle: {
      fontSize: '0.438rem',
      fontWeight: 700,
    },
    printBodyBold: {
      fontSize: '0.375rem',
      fontWeight: 500,
    },
    printBody: {
      fontSize: '0.375rem',
      fontWeight: 400,
    },
    subtitle1: {
      fontSize: '1.3125rem',
    },
    h4Title: {
      fontSize: '0.75rem',
      fontWeight: 700,
    },
    h4Bold: {
      fontSize: '0.75rem',
      fontWeight: 500,
    },
    h4Medium: {
      fontSize: '0.75rem',
      fontWeight: 400,
    },
    h5bold: {
      fontSize: 10,
      fontWeight: 500,
      color: palette.disabledGrey,
    },
    h4: {
      fontSize: '0.75rem',
      fontWeight: 300,
    },
    h4newBold: {
      // transition to this?
      fontSize: '0.8rem',
      fontWeight: 700,
    },
    h4new: {
      // transition to this?
      fontSize: '0.8rem',
    },
    h4newMedium: {
      // transition to this?
      fontSize: '0.8rem',
      fontWeight: 400,
    },
    h3TableHeader: {
      fontSize: '0.9rem',
      fontWeight: 700,
      color: palette.joinPrimary,
    },
    h3Label: {
      color: palette.shadedGrey,
      fontSize: '0.9rem',
      fontWeight: 500,
    },
    h3Bold: {
      fontSize: '0.9rem',
      fontWeight: 500,
    },
    h3CardHeader: {
      fontSize: '0.9rem',
      fontWeight: 400,
    },
    h3: {
      fontSize: '0.9rem',
      fontWeight: 300,
    },
    body2: {
      color: 'inherit',
      fontSize: '0.75rem',
      fontWeight: 300,
    },
    body1: {
      fontSize: '0.9rem',
      color: 'inherit',
      fontWeight: 300,
    },
    chart: {
      fontSize: '12',
    },
    chartBold: {
      fontSize: 14,
      fontWeight: 700,
    },
    chartSmall: {
      fontSize: 12,
      fontWeight: 400,
    },
    button: {
      color: 'inherit',
    },
    header: {
      fontSize: '1rem',
      fontWeight: 500,
    },
    boldHeader: {
      fontSize: '1rem',
      fontWeight: 700,
    },
    categorizationHeader: {
      fontSize: '1.2rem',
    },
    subMenuHeader: {
      fontSize: '1rem',
      fontWeight: 400,
      color: palette.joinPrimary,
    },
    label: {
      padding: '0',
      alignSelf: 'baseline',
      color: palette.shadedGrey,
      fontSize: '0.75rem',
      fontWeight: 400,
      fontFamily,
      lineHeight: '1.375em',
      margin: '4px 0px 0px 0px',
    },
    division: {
      color: palette.primaryGrey,
      fontSize: 18,
      fontWeight: 400,
    },
    subdivision: {
      color: palette.primaryGrey,
      fontSize: 18,
    },
    subsubdivision: {
      color: palette.primaryGrey,
      fontSize: 18,
      fontWeight: 300,
    },
    tableHeader: {
      fontSize: 10,
      fontWeight: 400,
    },
    number: {
      fontWeight: 400,
      fontVariantNumeric: 'lining-nums tabular-nums',
    },
    number2: {
      fontWeight: 400,
      fontSize: '0.9em',
      fontVariantNumeric: 'lining-nums tabular-nums',
    },

    // Sourced from Figma: Colors and Type -> App Typography
    webButton: {
      fontFamily,
      letterSpacing,
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '24px',
    },
    webHeading1: {
      fontFamily,
      letterSpacing,
      fontWeight: 700,
      fontSize: '32px',
      lineHeight: '40px',
    },
    webHeading2: {
      fontFamily,
      letterSpacing,
      fontWeight: 300,
      fontSize: '24px',
      lineHeight: '32px',
    },
    webHeading3: {
      fontFamily,
      letterSpacing,
      fontWeight: 500,
      fontSize: '21px',
      lineHeight: '28px',
    },
    webLabel: {
      fontFamily,
      letterSpacing,
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '16px',
    },
    webLink: {
      fontFamily,
      letterSpacing,
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '20px',
      color: palette.brand.middleBlue,
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    webNumber: {
      letterSpacing,
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '20px',
      fontVariantNumeric: 'lining-nums tabular-nums',
    },
    webParagraph: {
      fontFamily,
      letterSpacing,
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '20px',
    },
    webParagraphLabel: {
      fontFamily,
      letterSpacing,
      fontWeight: 700,
      fontSize: '16px',
      lineHeight: '20px',
    },
    webSmallLink: {
      fontFamily,
      letterSpacing,
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '16px',
      color: palette.brand.middleBlue,
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    webTableHeader: {
      fontFamily,
      letterSpacing,
      fontWeight: 700,
      fontSize: '14px',
      lineHeight: '16px',
    },
    webTableNumber: {
      letterSpacing,
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '16px',
      fontVariantNumeric: 'lining-nums tabular-nums',
    },
    webTableText: {
      fontFamily,
      letterSpacing,
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '16px',
    },
  },
  palette,
  fontFamily,
  breakpoints: {
    values: {
      xs: 300,
      sm: 600,
      col: 800,
      md: 1050,
      lg: 1350,
      xl: 1600,
    },
  },
};

const themePropsGreen = {
  arrowIcon: {
    borderRadius: '50%',
    '&:hover': {
      backgroundColor: palette.backgroundGrey,
    },
  },
  border: {
    focus: singleLine(palette.borderGreen),
    focusLink: singleLine2(palette.backgroundGreen),
  },
  dropDownArrow: {
    '&:hover': {
      backgroundColor: palette.backgroundGrey,
    },
  },
  roundButton: {
    '&:hover': {
      border: singleLine(palette.joinPrimary),
    },
    '&:focus': {
      border: border.line,
      outline: border.invisible,
    },
    '&:focus-visible': {
      border: singleLine(palette.borderGreen),
      outline: singleLine(palette.borderGreen),
    },
    border: border.line,
  },
  linkFocus: {
    '&:focus': {
      background: palette.transparent,
      outline: border.transparentLinkBorder,
    },
    '&:focus-visible': {
      background: palette.backgroundGreen,
      outline: singleLine2(palette.backgroundGreen),
    },
  },
  overrides: {
    MuiButton: {
      root: {
        '&:focus': {
          border: border.focus,
          outline: singleLine(palette.transparent),
        },
        '&:focus-visible': {
          border: singleLine(palette.borderGreen),
          outline: singleLine(palette.borderGreen),
        },
      },
      outlinedPrimary: {
        '&:hover': {
          backgroundColor: palette.backgroundGrey,
        },
      },
      outlined: {
        '&:hover': {
          backgroundColor: palette.backgroundGrey,
        },
      },
    },
    MuiIconButton: {
      root: {
        '&:hover': {
          backgroundColor: palette.backgroundGrey,
        },
      },
    },
    MuiTouchRipple: {
      rippleVisible: {
        color: palette.borderGreen,
      },
    },
    MuiInput: {
      focused: {
        backgroundColor: 'white',
        border: singleLine(palette.borderGreen),
        outline: singleLine(palette.borderGreen),
      },
    },
    MuiListItem: {
      button: {
        '&:hover': {
          backgroundColor: palette.backgroundGreen,
        },
      },
    },
  },
  typography: {
    link: {
      '&:focus': {
        background: palette.transparent,
        outline: singleLine2(palette.transparent),
      },
      '&:focus-visible': {
        background: palette.backgroundGreen,
        outline: singleLine2(palette.backgroundGreen),
      },
    },
  },
  palette: {
    badgeColor: palette.badgeColor,
    focusBorderColor: palette.borderGreen,
    hoverBackground: palette.backgroundGreen,
    focusSelectBackground: palette.backgroundFocusSelectGreen,
    hoverSemitransparentBackground: palette.backgroundGreen,
    linkFocusBackground: palette.backgroundGreen,
  },
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
const theme = createMuiTheme(themeProps as any);

export type KomodoTheme = typeof themePropsGreen &
  typeof designSystemTheme &
  typeof themeProps &
  typeof theme & {
    palette: typeof palette;
  };

export const createAppTheme = (hasGreenColorsFeature: boolean) => {
  const extraThemeProps = hasGreenColorsFeature ? themePropsGreen : {};
  return createMuiTheme(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
    merge(themeProps, extraThemeProps, designSystemTheme) as any
  ) as KomodoTheme;
};

// Classes denotes the type of the actual style definition, which
// is key -> CSSProperty (or other)
// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
type Classes<Key extends string> = Record<Key, any>;

// `T extends ConsistentWith<T, U>` indicates that where
// `T` has overlapping properties with `U`, their value types do not conflict.
type ConsistentWith<T, U> = Pick<U, keyof T & keyof U>;

// Removes the type of classes from the component props as-seen to the outer scope,
// i.e. the styled component has had classes passed to it by this function, so any
// callers (enclosing functions) do not need to.
type Result<Key extends string> = <P extends ConsistentWith<P, { classes: Classes<Key> }>>(
  component: React.FC<P>
) => React.FC<Omit<P, 'classes'>>;

// Rebinds the type of withStyles from MUI to overwrite what the type of the theme is,
// so that functions in our codebase can use KomodoTheme instead of just the MUI `Theme`
// type.
export function withStyles<Key extends string>(
  styles: (t: KomodoTheme) => Classes<Key>
): Result<Key> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  return withStylesUntyped(styles as any) as unknown as Result<Key>;
}

export default theme as KomodoTheme;
