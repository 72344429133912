import * as React from 'react';

import { withStyles } from '../../../../../theme/komodo-mui-theme';
import { isCostRange, isCostScalar } from '../../../../../utilities/compare';
import { formatCost } from '../../../../../utilities/currency';
import { EMPTY_COST } from '../../../../../utilities/string';
import NormalTooltip from '../../../../NormalTooltip/NormalTooltip';

import styles from './CostCellStyles';

const formatExactCost = (cost: number | string) =>
  formatCost(cost, { showCents: true, rounded: false });

const formatRoundedCost = (cost: number | string) =>
  formatCost(cost, { showCents: false, rounded: true });

export default withStyles(styles)(function CostCell({
  classes,
  cost,
}: {
  classes: Classes<typeof styles>;
  cost: CostRange | CostScalar | number | string | undefined | null;
}) {
  let exactCost = '';
  let displayedCost: React.ReactNode = null;

  if (cost === undefined || cost === null) {
    displayedCost = EMPTY_COST;
  } else if (typeof cost === 'number' || typeof cost === 'string') {
    exactCost = formatExactCost(cost);
    displayedCost = formatRoundedCost(cost);
  } else if (isCostScalar(cost)) {
    exactCost = formatExactCost(cost.value);
    displayedCost = formatRoundedCost(cost.value);
  } else if (isCostRange(cost)) {
    exactCost = formatExactCost(cost.min);
    displayedCost = formatRoundedCost(cost.min);

    if (cost.max !== cost.min) {
      exactCost += ` — ${formatExactCost(cost.max)}`;
      displayedCost = (
        <div className={classes.range}>
          <div>{displayedCost}</div>
          <div>—</div>
          <div>{formatRoundedCost(cost.max)}</div>
        </div>
      );
    }
  }

  return (
    <NormalTooltip title={exactCost}>
      <div className={classes.container}>{displayedCost}</div>
    </NormalTooltip>
  );
});
