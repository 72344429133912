import { createStyles } from '@material-ui/core/styles';

import { MAX_COL_WIDTH } from '../../../constants';
import { KomodoTheme } from '../../../theme/komodo-mui-theme';

const styles = (theme: KomodoTheme) =>
  createStyles({
    date: {
      flex: '0 0 120px',
      paddingRight: 8,
      lineHeight: 'unset',
      textAlign: 'left',
    },
    root: {
      height: '100%',
      [theme.breakpoints.up('xl')]: {
        maxWidth: MAX_COL_WIDTH,
      },
      display: 'flex',
      flexDirection: 'column',
    },
    header: {
      background: theme.palette.joinGrey400,
      paddingRight: 26,
      paddingLeft: 24,
      height: 34,
      display: 'flex',
      alignItems: 'center',
      position: 'sticky',
      zIndex: 10,
      top: 0,
    },
    headerText: {
      fontWeight: 500,
      flexGrow: 1,
      paddingTop: 6,
    },
    countText: {
      fontWeight: 500,
      paddingTop: 8,
      paddingLeft: 24,
      paddingBottom: 8,
    },
    item: {
      display: 'flex',
    },
    scroll: {
      height: '100%',
      overflowY: 'scroll',
    },
    text: {
      flex: '1 0 120px',
      lineHeight: 'unset',
    },
    toggleAllVisibleIcon: {
      width: 24,
      height: 24,
    },
    value: {
      flex: '0 0 120px',
      lineHeight: 'unset',
      textAlign: 'center',
    },
    visibleIcon: {
      width: 36,
      height: 36,
      margin: 0,
      color: 'inherit',
      '@media print': {
        display: 'none',
      },
    },
  });

export default styles;
