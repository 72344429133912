import { FC } from 'react';

import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { UNASSIGNED } from '../../constants';
import { getIsDeactivated } from '../CompanyTab/CompanyTabUtils';
import NormalTooltip from '../NormalTooltip/NormalTooltip';

import AssigneeChipStyles from './AssigneeChipStyles';
import UserAvatar from './UserAvatar';

type AssigneeChipProps = {
  assignee?: User | null;
  classes: Classes<typeof AssigneeChipStyles>;
  defaultLabel?: string;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  expanded?: boolean;
};

const AssigneeChip: FC<AssigneeChipProps> = ({
  assignee = null,
  classes,
  defaultLabel = 'Unassigned',
  expanded = false,
}) => {
  const name = assignee && (assignee.name || UNASSIGNED);
  const isDeactivated = getIsDeactivated(assignee);

  const component = (
    <div className={classes.root}>
      <UserAvatar assignee={assignee} deactivated={isDeactivated} />

      <div className={classes.label}>
        <Typography
          className={isDeactivated ? classes.deactivated : classes.text}
          data-cy="inputs-assignee-select"
          variant="body1"
        >
          {assignee ? name : defaultLabel}
          {isDeactivated ? ' (Deactivated)' : ''}
        </Typography>
        {expanded && (
          <Typography className={`${classes.text} ${classes.noPrint}`} variant="body2">
            {assignee && assignee.email}
          </Typography>
        )}
      </div>
    </div>
  );

  if (expanded || !assignee) return component;

  return <NormalTooltip title={assignee && assignee.email}>{component}</NormalTooltip>;
};

export default withStyles(AssigneeChipStyles)(AssigneeChip);
