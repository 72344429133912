import { useEffectOnce } from 'react-use';

import { TimelineEvent, timelineEvent } from '../../../analytics/analyticsEventProperties';
import { JoinProjectRoutes } from '../../../api/gqlEnums';
import { NONE_DUE_DATE } from '../../../constants';
import useSendAnalytics from '../../../hooks/useSendAnalytics';
import { ItemsListSettings } from '../../ItemsList/ItemsListUtils';

export const useItemsListAnalytics = (settings: ItemsListSettings) => {
  const { from, dueState, due, currentMilestone, activityID } = settings;
  const sendAnalytics = useSendAnalytics();
  useEffectOnce(() => {
    if (from === JoinProjectRoutes.TIMELINE) {
      if (dueState) {
        sendAnalytics(
          timelineEvent(TimelineEvent.DUE_DATE_TOOLTIP_CLICK, {
            type: dueState,
            dateRange: due,
          })
        );
      } else if (due === NONE_DUE_DATE) {
        sendAnalytics(timelineEvent(TimelineEvent.DUE_DATE_NO_DUE_DATE_CLICK));
      } else if (activityID) {
        sendAnalytics(timelineEvent(TimelineEvent.ACTIVITY_TOOLTIP_CLICK));
      } else if ((currentMilestone || [])[0]) {
        sendAnalytics(timelineEvent(TimelineEvent.MILESTONE_TOOLTIP_CLICK));
      }
    }
  });
};
