/* eslint react/prop-types: 0 */
import Menu, { SubMenu } from 'rc-menu';
import 'rc-menu/assets/index.css';
import { FC } from 'react';

import { ArrowBackIos } from '@material-ui/icons';

import { NULL_ID, Uncategorized } from '../../../constants';
import { withStyles } from '../../../theme/komodo-mui-theme';

import useListTopLevelCategoryQuery from './hooks/useListTopLevelCategoryQuery';
import InputsSelectReferenceCategorizationMenuItem from './InputsSelectReferenceCategorizationMenuItem';
import InputsSelectReferenceCategoryFiltersStyles from './InputsSelectReferenceCategoryFiltersStyles';
import CategoryMenuItem from './InputsSelectReferenceCategoryMenuItem';
import { subMenuOffset } from './ReferencesMenu';

type InputsSelectReferenceCategoryFiltersProps = {
  classes: Classes<typeof InputsSelectReferenceCategoryFiltersStyles>;
  categorization: Categorization;
  selectedFilters: Category[];
  toggleCategory: (category: Category) => void;
  setSelectedFilters: (selectedFilters: Category[]) => void;
};

export const ClearLink = ({ onClick, text }: { onClick: () => void; text: string }) => (
  <div className="shrink-0 cursor-pointer underline" onClick={onClick} onKeyDown={onClick}>
    {text}
  </div>
);

const InputsSelectReferenceCategoryFilters: FC<InputsSelectReferenceCategoryFiltersProps> = ({
  classes,
  categorization,
  selectedFilters,
  toggleCategory,
  setSelectedFilters,
}) => {
  const categorizationFilters = selectedFilters.filter(
    (f) => (f.categorization && f.categorization.id) === categorization.id
  );

  const { data, loading, error } = useListTopLevelCategoryQuery(categorization.id, '', 2000);

  if (loading || error)
    return (
      <InputsSelectReferenceCategorizationMenuItem
        categorization={categorization}
        categorizationFilters={categorizationFilters}
      />
    );

  const uncategorizedCategory = {
    id: NULL_ID,
    number: Uncategorized,
    name: Uncategorized,
    categorization,
  };
  const categories =
    data && data.category ? [uncategorizedCategory, ...data.category] : [uncategorizedCategory];

  // GENERATORS

  const generateCategoryMenuItems = categories.map((category) => {
    const isChecked = selectedFilters.some(
      (s) => s.id === category.id && (s.categorization && s.categorization.id) === categorization.id
    );
    return (
      <CategoryMenuItem
        key={`${category.id}:${category.categorization?.id}`}
        category={category as Category}
        checked={isChecked}
        onClick={() => toggleCategory(category as Category)}
      />
    );
  });
  return (
    <Menu
      className={classes.filterMenu}
      direction="rtl"
      expandIcon={<ArrowBackIos className="m-2 !w-[14px]" />}
      triggerSubMenuAction="click"
    >
      <SubMenu
        className="hover:bg-selection-hover"
        popupOffset={subMenuOffset}
        title={
          <InputsSelectReferenceCategorizationMenuItem
            categorization={categorization}
            categorizationFilters={categorizationFilters}
          />
        }
      >
        <div className={classes.categoryMenuItems}>
          <div className="sticky top-0 z-10 flex h-9 items-center !justify-between border-b bg-background-primary !px-3 type-body2">
            {categorizationFilters.length !== 0 ? (
              <ClearLink
                onClick={() => {
                  setSelectedFilters(
                    selectedFilters &&
                      selectedFilters.filter(
                        (f) => (f.categorization && f.categorization.id) !== categorization.id
                      )
                  );
                }}
                text="Clear"
              />
            ) : (
              <ClearLink
                onClick={() => {
                  setSelectedFilters(
                    selectedFilters.concat(
                      (categories as Category[]).filter(
                        (c) => c.categorization?.id === categorization.id
                      )
                    )
                  );
                }}
                text="Select all"
              />
            )}
            <div>:Choose categories</div>
          </div>
          {generateCategoryMenuItems}
        </div>
      </SubMenu>
    </Menu>
  );
};

export default withStyles(InputsSelectReferenceCategoryFiltersStyles)(
  InputsSelectReferenceCategoryFilters
);
