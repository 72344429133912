import { ProjectType } from '../../../generated/graphql';
import { makeTree } from '../../../utilities/utilities';

import FilterGroupLevels from './FilterGroupLevels';
import { ListFilterOption } from './FilterGroupUtils';

type Props = {
  'data-cy'?: string;
  entries: ListFilterOption[] | ProjectType[];
  isAllSelectable?: boolean;
  isSearchable?: boolean;
  onChange: (value: string[]) => void;
  searchPlaceholder?: string;
  selectedEntries: string[];
  title: string;
};

export default function ProjectTypeFilter(props: Props) {
  const entries = props.entries as ProjectType[];
  const filter = (
    <FilterGroupLevels
      data-cy={props['data-cy']}
      entries={makeTree(entries)}
      isAllSelectable={props.isAllSelectable}
      isSearchable={props.isSearchable}
      onChange={(ids: UUID[]) => props.onChange(ids)}
      searchPlaceholder={props.searchPlaceholder}
      selectedEntries={props.selectedEntries}
      title={props.title}
    />
  );

  return filter;
}
