import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Check } from '@material-ui/icons';

import {
  roleMutationAnalyticsEvent,
  roleMutationTypes,
} from '../../../analytics/analyticsEventProperties';
import {
  CollaboratorRoleInput,
  LoadCollaboratorsByRoleQuery,
  Role,
} from '../../../generated/graphql';
import useSendAnalytics from '../../../hooks/useSendAnalytics';
import { setToast } from '../../../hooks/useToastParametersLocalQuery';
import { RouteKeys } from '../../../routes/paths';
import theme from '../../../theme/design-system-mui-theme';
import { generateSharedPath } from '../../../utilities/routes/links';
import UserAvatar from '../../Collaborators/UserAvatar';
import { getIsActive, getIsDeactivated } from '../../CompanyTab/CompanyTabUtils';
import { Button, Dialog, DialogContent, Select } from '../../scales';

import { useDeleteProjectRoleMutation } from './hooks/useDeleteProjectRoleMutation';
import { useLoadCollaboratorsByRoleQuery } from './hooks/useLoadCollaboratorsByRoleQuery';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  projectID: UUID;
  role?: Role;
  roles: Role[];
};

export default function DeleteRoleDialog(props: Props) {
  const navigate = useNavigate();
  const sendAnalytics = useSendAnalytics();

  const [collaboratorRoleInputs, setCollaboratorRoleInputs] = useState<CollaboratorRoleInput[]>([]);

  const [deleteProjectRole] = useDeleteProjectRoleMutation(props.projectID, {
    onCompleted: () => {
      setToast({
        message: `'${props.role?.name}' has successfully been deleted`,
        icon: <Check style={{ color: theme.palette.ds.type.success }} />,
      });
      navigate(
        generateSharedPath(RouteKeys.PROJECT_TEAM_ROLES, {
          projectId: props.projectID,
        })
      );
    },
  });

  const { data } = useLoadCollaboratorsByRoleQuery(props.projectID, props.role?.id);
  const collaborators = data?.loadCollaboratorsByRole;

  const onDelete = () => {
    deleteProjectRole(props.role?.id || '', collaboratorRoleInputs);
    props.onClose();
    sendAnalytics(
      roleMutationAnalyticsEvent(roleMutationTypes.DELETE_ROLE, {
        roleID: props.role?.id,
      })
    );
  };

  const onClose = () => {
    props.onClose();
    sendAnalytics(
      roleMutationAnalyticsEvent(roleMutationTypes.DELETE_ROLE_CANCEL, {
        roleID: props.role?.id,
      })
    );
  };

  const onChangeRoleAssignment = (collaboratorID: UUID, roleID: UUID) => {
    setCollaboratorRoleInputs((prevState) => {
      const prevCollaboratorRoleInput = prevState.find((c) => c.collaboratorID === collaboratorID);
      if (!prevCollaboratorRoleInput) {
        return [...prevState, { collaboratorID, roleID }];
      }
      const unchangedInputs = prevState.filter((c) => c.collaboratorID !== collaboratorID);
      return [...unchangedInputs, { collaboratorID, roleID }];
    });
  };

  return collaborators?.length ? (
    <DeleteRoleReassign
      collaboratorRoleInputs={collaboratorRoleInputs}
      collaborators={collaborators}
      currentRoleID={props.role?.id}
      isOpen={props.isOpen}
      onChangeRoleAssignment={onChangeRoleAssignment}
      onClose={onClose}
      onDelete={onDelete}
      roles={props.roles}
    />
  ) : (
    <DeleteRoleConfirm
      isOpen={props.isOpen}
      onClose={onClose}
      onDelete={onDelete}
      role={props.role}
    />
  );
}

type DeleteRoleReassignProps = {
  collaborators: LoadCollaboratorsByRoleQuery['loadCollaboratorsByRole'];
  collaboratorRoleInputs: CollaboratorRoleInput[];
  currentRoleID?: UUID;
  onChangeRoleAssignment: (collaboratorID: UUID, roleID: UUID) => void;
  onClose: () => void;
  onDelete: () => void;
  isOpen: boolean;
  roles: Role[];
};

function DeleteRoleReassign(props: DeleteRoleReassignProps) {
  const entries = props.roles
    .filter((r) => r.id !== props.currentRoleID)
    .map((r) => ({ id: r.id, label: r.name }));

  const disableSubmit = props.collaboratorRoleInputs.length !== props.collaborators.length;
  return (
    <Dialog
      footerRight={
        <Button
          isDisabled={disableSubmit}
          label="Switch roles and delete"
          onClick={() => props.onDelete()}
          type="destructive"
        />
      }
      isOpen={props.isOpen}
      onClose={props.onClose}
      title="Delete this role?"
    >
      <DialogContent>
        <div className="flex flex-col gap-6">
          <div>
            Deleting this role will delete all configured settings. Teammates affected by this
            decision will need to be converted to an existing role.
          </div>
          <div className="flex flex-col gap-4">
            <div>Teammates affected:</div>
            <div className="flex flex-col gap-3">
              {props.collaborators.map((c) => (
                <div key={c.user.id} className="flex">
                  <div className="flex w-full items-center gap-2">
                    <div className={`${getIsActive(c.user) ? '' : 'opacity-50'}`}>
                      <UserAvatar assignee={c.user} deactivated={getIsDeactivated(c.user)} />
                    </div>
                    <div className={`${getIsActive(c.user) ? '' : 'text-type-muted'}`}>{`${
                      c.user.name
                    }${getIsActive(c.user) ? '' : ' (Invited)'}`}</div>
                  </div>
                  <Select
                    data-cy="collaborator-role-select"
                    entries={entries}
                    onChange={(value) => props.onChangeRoleAssignment(c.id, value)}
                    placeholder="Choose a new role"
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}

type DeleteRoleConfirmProps = {
  onClose: () => void;
  onDelete: () => void;
  role?: Role;
  isOpen: boolean;
};

function DeleteRoleConfirm(props: DeleteRoleConfirmProps) {
  return (
    <Dialog
      footerRight={<Button label="Delete" onClick={props.onDelete} type="destructive" />}
      isOpen={props.isOpen}
      onClose={props.onClose}
      title={`Delete '${props.role?.name}'`}
    >
      <DialogContent>
        <div>{`Once you delete '${props.role?.name}' this role will be removed.`}</div>
      </DialogContent>
    </Dialog>
  );
}
