import { gql } from '../../api/graphqlFragments';

export const reportPageLoad = gql`
  mutation reportPageLoad(
    $project: UUID
    $type: UserEventType!
    $page: String!
    $loadTime: Float!
  ) {
    reportUserEvent(
      userEvent: {
        type: $type
        projectID: $project
        eventData: { page: $page, loadTime: $loadTime }
      }
    )
  }
`;

export const reportImportEstimateTime = gql`
  mutation reportImportEstimateTime(
    $milestoneEstimateID: UUID!
    $loadTime: Float!
    $type: UserEventType!
  ) {
    reportImportEstimateTimeEvent(
      milestoneEstimateID: $milestoneEstimateID
      loadTime: $loadTime
      type: $type
    )
  }
`;
