import {
  CategoryCombinationCountsQuery,
  CategoryCombinationCountsQueryVariables,
  CategoryCombinationsQuery,
  CategoryCombinationsQueryVariables,
  CategorySearch,
} from '../generated/graphql';

import { categoryCombinationCountsQuery, categoryCombinationsQuery } from './queries';
import { useQuery } from './useMountAwareQuery';
import { MountPolicy } from './usePolicyOnFirstMount';

const useListCategoryCombinationsQuery = (searches: CategorySearch[], estimateID?: UUID) =>
  useQuery<CategoryCombinationsQuery, CategoryCombinationsQueryVariables>(
    categoryCombinationsQuery,
    {
      variables: { searches, estimateID },
      skip: !searches.length,
    },
    MountPolicy.SKIP_ON_MOUNT
  );

// Returns a count for each of the categories available
export const useCategoryCombinationCountsQuery = (searches: CategorySearch[]) =>
  useQuery<CategoryCombinationCountsQuery, CategoryCombinationCountsQueryVariables>(
    categoryCombinationCountsQuery,
    {
      variables: { searches },
      skip: !searches.length,
    },
    MountPolicy.SKIP_ON_MOUNT
  );

export const getCombinationCount = (
  categoryCombinationCounts: CategoryCombinationCountsQuery['categoryCombinationCounts'],
  selectedCategorizations: Pick<Categorization, 'id'>[]
) => {
  if (!selectedCategorizations.length) {
    return 0;
  }

  const categorizationIDs = selectedCategorizations.map((categorization) => categorization.id);
  return (
    categoryCombinationCounts
      // filter the counts
      .filter((cc) => categorizationIDs.includes(cc.categorizationID))
      // get the count
      .map((cc) => cc.count)
      // ignore "zero counts"
      .filter((count) => count > 0)
      // do multiplications
      .reduce((a, b) => a * b, 1)
  );
};

export default useListCategoryCombinationsQuery;
