import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';

const styles = (theme: KomodoTheme) =>
  createStyles({
    dropDownArrow: theme.dropDownArrow,
    dropDownRelative: {
      ...theme.dropDownArrow,
      marginLeft: 'auto',
      marginRight: 6,
      position: 'relative',
      top: 0,
    },
    borderless: {
      position: 'relative',
      right: 10,
      cursor: 'pointer',
      '&hover': {
        background: 'none',
      },
    },
  });

export default styles;
