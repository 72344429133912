import { Flag, FlagOutlined } from '@material-ui/icons';
import Add from '@material-ui/icons/Add';

import { TermKey } from '../../../../../api/gqlEnums';
import { NULL_ID } from '../../../../../constants';
import { CostReportColumnType } from '../../../../../generated/graphql';
import { computeCostString, computeSelectCostReport } from '../../../../../utilities/milestones';
import {
  getCostReportByType,
  getCostValue,
  renderCostString,
} from '../../../../CostReport/CostReportUtils';
import { SelectEntry } from '../../../../scales';

const addToEstimateCostOptions = { isExact: true, isWide: true, showCents: false };
export const addToEstimateCostFormatter = (cost: Cost) =>
  renderCostString({ cost, ...addToEstimateCostOptions });
export const getCostString = (term: string, cost: Cost, isNoEstimate: boolean) =>
  isNoEstimate ? `No ${term}` : `Currently ${addToEstimateCostFormatter(cost)}`;

const typeTermMap = new Map([
  [CostReportColumnType.ESTIMATE_REPORT, TermKey.ESTIMATE],
  [CostReportColumnType.TARGET_REPORT, TermKey.TARGET],
]);
export const getTermKey = (type: CostReportColumnType) => typeTermMap.get(type) || TermKey.ESTIMATE;
export const getReportTypeCost = (type: CostReportColumnType, costReport: MilestoneCostReport) => {
  const report = getCostReportByType(costReport, type);
  const cost = report?.range;
  const isNoCurrentEstimate = getCostValue(cost) === 0;
  return { cost, isNoCurrentEstimate };
};

const getTypeEntry = (t: TermStore, costReport: MilestoneCostReport, id: CostReportColumnType) => {
  const term = getTermKey(id);
  const label = t.titleCase(term);
  const { cost, isNoCurrentEstimate } = getReportTypeCost(id, costReport);
  const costString = getCostString(label, cost, isNoCurrentEstimate);
  return { id, label, endAddornment: <div className="type-number">{costString}</div> };
};

export const getTypeEntries = (
  milestoneCostReports: MilestoneCostReports,
  t: TermStore,
  selectedType: CostReportColumnType
) => {
  const costReport = computeSelectCostReport(milestoneCostReports, undefined);
  const costs = getReportTypeCost(selectedType, costReport);
  const isNoCurrentEstimate = costs.isNoCurrentEstimate;
  const typeEntries = Array.from(typeTermMap.keys()).map((type) =>
    getTypeEntry(t, costReport, type)
  );
  return { typeEntries, isNoCurrentEstimate };
};

type GetActionTextArgs = {
  t: TermStore;
  termKey: TermKey;
  isNewMilestone: boolean;
  isNoCurrentEstimate: boolean;
};

export const getActionText = ({
  t,
  termKey,
  isNewMilestone,
  isNoCurrentEstimate,
}: GetActionTextArgs) => {
  const term = t.lowerCase(termKey);
  if (isNewMilestone) {
    return `You will be creating a new ${term} for a new milestone named `;
  }
  return isNoCurrentEstimate
    ? `You will be adding a new ${term} for `
    : `You will be overwriting the current ${term} for `;
};

export const computeMilestoneSelectEntries = (
  milestones: Pick<Milestone, 'id' | 'name'>[],
  milestonesCostReports: Parameters<typeof computeCostString>[0],
  type: CostReportColumnType,
  activeMilestoneID: UUID | undefined
) => {
  const entries: SelectEntry[] = [
    { id: NULL_ID, label: 'Create a new milestone', startAdornment: <Add /> },
    ...milestones.map((milestone) => {
      const report = milestonesCostReports.find(({ milestoneID }) => milestoneID === milestone.id);
      const costString = computeCostString(report ? [report] : [], undefined, type);
      return {
        label: milestone.name,
        id: milestone.id,
        startAdornment: (
          <div className="text-entities-milestone">
            {milestone.id === activeMilestoneID ? <Flag /> : <FlagOutlined />}
          </div>
        ),
        endAdornment: <div className="type-number">{costString}</div>,
      };
    }),
  ];
  return entries;
};
