import { FC, ReactNode } from 'react';

import { withStyles } from '../../../theme/komodo-mui-theme';

import styles from './FloatingFooterStyles';

type Props = {
  children: ReactNode;
  classes: Classes<typeof styles>;
};

const FloatingFooter: FC<Props> = (props) => {
  return <div className={props.classes.container}>{props.children}</div>;
};

export default withStyles(styles)(FloatingFooter);
