import { Link } from 'react-router-dom';

import { useReactiveVar } from '@apollo/client';

import { projectSettingsVar } from '../../../../api/apollo/reactiveVars';
import { JoinProjectRoutes, TermKey } from '../../../../api/gqlEnums';
import useProjectTermStore from '../../../../hooks/useProjectTermStore';
import { generateSharedPath } from '../../../../utilities/routes/links';
import { EMPTY_COST } from '../../../../utilities/string';
import { getCostValue, renderCostString } from '../../../CostReport/CostReportUtils';
import { Tooltip } from '../../../scales';
import useSummaryCostReport from '../hooks/useSummaryCostReport';

export default function CostsSummary(props: {
  activeMilestoneID?: UUID;
  projectID?: UUID;
  quantity?: Quantity;
}) {
  const terms = useProjectTermStore();
  const report = useSummaryCostReport(props.projectID, props.activeMilestoneID, props.quantity);
  const hasNonzeroTarget = Boolean(getCostValue(report.target));
  const hasExactCost = Boolean(props.quantity); // If we are showing quantity, display exact values

  const path =
    props.projectID && props.activeMilestoneID
      ? generateSharedPath(JoinProjectRoutes.MSR, {
          projectId: props.projectID,
          milestoneId: props.activeMilestoneID,
        })
      : '';

  return (
    <Link
      className="flex flex-col gap-1 outline-none focus-visible:bg-selection-hover"
      data-cy="sidebar-cost-summary"
      to={path}
    >
      <CostRow
        className="text-type-primary"
        cost={report.estimate}
        data-cy="cost-summary-estimate"
        description="Base estimate for the active milestone"
        label={terms.titleCase(TermKey.ESTIMATE)}
        showExactCost={hasExactCost}
      />
      <CostRow
        className="text-item-status-accepted"
        cost={report.accepted}
        data-cy="cost-summary-accepted-changes"
        description="Value of all accepted items"
        label="Accepted Changes"
        showExactCost={hasExactCost}
      />
      <div className="col-span-2 h-px w-full bg-border-separator" />
      <CostRow
        className="text-type-primary"
        cost={report.running}
        data-cy="cost-summary-running-total"
        description="Revised estimate including accepted items"
        label={terms.titleCase(TermKey.RUNNING_TOTAL)}
        showExactCost={hasExactCost}
      />
      {hasNonzeroTarget ? ( // only show the gap and target if the target value is nonzero
        <>
          <CostRow
            className="text-entities-gap"
            cost={report.gap}
            data-cy="cost-summary-gap"
            description="Additional changes needed to meet target"
            label={terms.titleCase(TermKey.GAP)}
            showExactCost={hasExactCost}
          />
          <div className="col-span-2 h-px w-full bg-border-separator" />
          <CostRow
            className="text-entities-milestone"
            cost={report.target}
            data-cy="cost-summary-budget"
            description={`${terms.rawTerm(TermKey.TARGET)} value for this milestone`}
            label={terms.titleCase(TermKey.TARGET)}
            showExactCost={hasExactCost}
          />
        </>
      ) : null}
    </Link>
  );
}

type CostRowProps = {
  className: string;
  cost?: Cost;
  description: string;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  showExactCost: boolean;
  label: string;
  'data-cy': string;
};
function CostRow(props: CostRowProps) {
  // "Subscribe" to changes in the ROUNDING_PRECISION so that the cost report re-renders.
  // This is necessary because the value is normally pulled in formatCost (via renderCostString).
  // Since they're plain functions and not hooks, changes to the value don't trigger a re-render.
  useReactiveVar(projectSettingsVar);

  const roundedCost = renderCostString({
    cost: props.cost,
    isExact: false,
    isWide: true,
  });

  const exactCost = renderCostString({
    cost: props.cost,
    isExact: true,
    isWide: true,
  });

  return (
    <>
      <Tooltip
        content={
          <div className="flex flex-col gap-0.5">
            <div className="type-label">{props.description}</div>
            <div className="type-table-number">{exactCost || EMPTY_COST}</div>
          </div>
        }
        isDisabled={!props.cost}
        offset={20}
        placement="right"
      >
        <div className={`${props.className} flex gap-1 type-body3`}>
          <div>{props.label}</div>
          <div className="ml-auto tabular-nums" data-cy={props['data-cy']}>
            {props.showExactCost ? exactCost : roundedCost}
          </div>
        </div>
      </Tooltip>
    </>
  );
}
