import { MentionableTextInput } from '../../../generated/graphql';

export const getDraftLocation = (itemLikeID: UUID) => `draft-comment: ${itemLikeID}`;

export const setStoredDraftComment = (comment: MentionableTextInput | null, itemLikeID: UUID) => {
  localStorage.setItem(getDraftLocation(itemLikeID), JSON.stringify(comment));
};

export const getStoredDraftComment = (itemLikeID: UUID): MentionableTextInput => {
  const draftComment = localStorage.getItem(getDraftLocation(itemLikeID));
  return draftComment ? JSON.parse(draftComment) : null;
};
