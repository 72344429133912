export default (
  <svg
    className="fill-entities-event"
    height="11"
    viewBox="0 0 10 11"
    width="10"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect height="7" transform="rotate(-45 0 5.42969)" width="7" y="5.42969" />
  </svg>
);
