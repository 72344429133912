import { ComponentProps, FC } from 'react';

import { withStyles } from '../../../theme/komodo-mui-theme';
import { ProjectMap } from '../ExecutiveDashboardUtils';

import ExecutiveDashboardMostRecentlyCreatedRow from './ExecutiveDashboardMostRecentlyCreatedRow';
import ExecutiveDashboardMostRecentlyCreatedStyles from './ExecutiveDashboardMostRecentlyCreatedStyles';

type MostRecentlyCreatedProject = ComponentProps<
  typeof ExecutiveDashboardMostRecentlyCreatedRow
>['project'];

type ExecutiveDashboardProjectMovementProps = {
  classes: Classes<typeof ExecutiveDashboardMostRecentlyCreatedStyles>;
  count?: number;
  projects: MostRecentlyCreatedProject[];
  projectMap?: ProjectMap;
};

const ExecutiveDashboardMostRecentlyCreated: FC<ExecutiveDashboardProjectMovementProps> = ({
  classes,
  count = 10,
  projects,
  projectMap,
}) => {
  const projectRows = Array<MostRecentlyCreatedProject>(count).fill(null);
  projectRows.splice(0, projects.length ?? 0, ...projects.slice(0, count));

  return (
    <div className={classes.flexContainer}>
      <table className={classes.table}>
        <tbody>
          {projectRows.map((project, index: number) => (
            <ExecutiveDashboardMostRecentlyCreatedRow
              key={`${project ? project.projectId : index}`}
              project={project}
              projectMap={projectMap}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default withStyles(ExecutiveDashboardMostRecentlyCreatedStyles)(
  ExecutiveDashboardMostRecentlyCreated
);
