import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../../theme/komodo-mui-theme';

export default (theme: KomodoTheme) =>
  createStyles({
    bold: {
      fontWeight: 500,
    },
    columnLabelContainer: {
      display: 'flex',
    },
    columnLabels: {
      width: 'calc(50% - 22px)',
      paddingBottom: 4,
      '&:last-of-type': {
        paddingLeft: 44,
      },
    },
    footerContainer: {
      paddingTop: 12,
    },
    innerContainer: {
      background: 'none',
      display: 'flex',
    },
    swapButton: {
      backgroundColor: theme.palette.primaryGrey,
      color: theme.palette.backgroundWhite,
      height: 36,
      width: 36,
    },
    swapButtonContainer: {
      alignSelf: 'center',
      padding: 4,
    },
  });
