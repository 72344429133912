import { AssetInput, RemoteAssetInput, SourceSystem } from '../../../../generated/graphql';

type FormatAttachAssetInputsArgs = {
  hubID?: string;
  assetID?: string;
  sourceSystem?: SourceSystem;
  sourceID?: UUID;
  sourceProjectID?: string;
};

export const formatAttachAssetInputs = (args: FormatAttachAssetInputsArgs) => {
  if (args.sourceSystem) {
    const remoteAssetInput: RemoteAssetInput = {
      AssetID: args.assetID || '',
      HubID: args.hubID || '',
      SourceSystem: args.sourceSystem,
      SourceID: args.sourceID || '',
      ProjectID: args.sourceProjectID,
    };
    return { assetInput: undefined, remoteAssetInput };
  }

  const assetInput: AssetInput = {
    AssetID: args.assetID || '',
  };

  return { assetInput, remoteAssetInput: undefined };
};

export const formatDetachAssetInputs = (assetID?: UUID, sourceSystem?: SourceSystem) => {
  if (sourceSystem) {
    return { remoteAssetID: assetID };
  }
  return { joinAssetID: assetID };
};
