import { useEffect, useRef, useState } from 'react';
import * as React from 'react';

import { withStyles } from '../../../theme/komodo-mui-theme';
import { TextInput } from '../../scales';

import MilestoneTitleStyles from './MilestoneTitleStyles';

type MilestoneTitleProps = {
  name: string;
  onUpdate: (value: string) => void;
  onSet?: (value: string) => void;
  onEnterKey?: () => void;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  disabled: boolean;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  autoFocus?: boolean;
};

const MilestoneTitle: React.FC<MilestoneTitleProps> = ({
  name: icName,
  onUpdate,
  onSet,
  onEnterKey,
  disabled,
  autoFocus,
}) => {
  const [name, setName] = useState(icName);
  useEffect(() => {
    setName(icName);
  }, [icName]);

  const inputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (inputRef.current && autoFocus) inputRef.current.focus();
  }, [autoFocus]);

  const asterisk = disabled ? '' : ' *';

  return (
    <TextInput
      ref={inputRef}
      autoFocus={autoFocus}
      data-cy="active-milestone-name-text-input"
      id="name"
      isDisabled={disabled}
      label={`Milestone Name${asterisk}`}
      onBlur={() => {
        onUpdate(name);
      }}
      onChange={(value) => {
        setName(value);
        if (onSet) onSet(value);
      }}
      onKeyDown={(evt) => {
        if (evt.key === 'Enter') {
          if (evt.target instanceof HTMLElement) evt.target.blur();
          if (onEnterKey) onEnterKey();
        }
      }}
      placeholder="Add Milestone Name..."
      value={name}
    />
  );
};

export default withStyles(MilestoneTitleStyles)(MilestoneTitle);
