import { TermKey } from '../api/gqlEnums';
import {
  getScheduleType,
  scheduleDisplayTypes,
} from '../components/ProjectProperties/ProjectScheduleImpact/ProjectScheduleImpactSettings';
import {
  INTRODUCTION,
  MARKUPS,
  MASTERFORMAT_CATEGORIZATION_ID,
  MASTERFORMAT_CATEGORY,
  SEPARATED_COST,
  UNIFORMAT_CATEGORIZATION_ID,
  UNIFORMAT_CATEGORY,
  Uncategorized,
} from '../constants';
import { Scale } from '../enums';
import { Maybe } from '../generated/graphql';

import {
  MAX_SHORT_DIGITS,
  formatCommas,
  getThousandSeparator,
  scrubNumberString,
} from './currency';

export const EMPTY_COST = '--';
export const CATEGORY_SEPARATOR = '\u2318';
const bytesUnits = ['B', 'kB', 'MB', 'GB', 'TB', 'PB'];

export const capitalizeString = (text: string) => text.replace(/^\w/, (c) => c.toUpperCase());

export const isString = (s: React.ReactNode) => typeof s === 'string' || s instanceof String;

export const categoryDescription = (cat: Category, termStore: TermStore | null) => {
  if (cat.name === SEPARATED_COST || cat.number === SEPARATED_COST) {
    if (termStore) return termStore.titleCase(TermKey.MARKUP);
    return MARKUPS;
  }
  if (cat.name === Uncategorized || cat.number === Uncategorized) {
    return Uncategorized;
  }
  return cat.name;
};

export const categoryLabel = (
  name?: string,
  inputNumber?: string,
  categorization?: Categorization | null,
  termStore?: TermStore | null
) => {
  const number = formatCategoryNumber(inputNumber, categorization);
  let label = removeYear(number || '');
  if (name && number) label = label.concat(` - `);
  if (name) label = label.concat(name);
  if (number === SEPARATED_COST) {
    if (!termStore) label = MARKUPS;
    else label = termStore.titleCase(TermKey.MARKUP);
  }
  if (name === Uncategorized && number === Uncategorized) {
    label = Uncategorized;
  }
  return label;
};

export const categoryTitle = (cat: Category) => {
  if (cat.name === SEPARATED_COST || cat.number === SEPARATED_COST) {
    return ' ';
  }
  if (cat.name === Uncategorized || cat.number === Uncategorized) {
    return EMPTY_COST;
  }
  if (isUniformat(cat.categorization ?? undefined) && cat.number === INTRODUCTION) return '';
  return cat.number;
};

export const commaSeparatedString = (list: string[]): string => {
  const { length } = list;
  return length > 2
    ? `${list.slice(0, length - 1).join(', ')}, and ${list[length - 1]}`
    : list.join(' and ');
};

export const removeThousandsSeparator = (numberString: string | number, isCurrency: boolean) => {
  if (typeof numberString === 'number') return String(numberString);

  const thousandsSeparator = getThousandSeparator(isCurrency);
  // replace doesn't work for spaces....
  // the Int currency formatter also sometimes uses irregular whitespaces...
  if (!thousandsSeparator.trim().length || thousandsSeparator === ' ')
    // eslint-disable-next-line no-irregular-whitespace
    return numberString.replaceAll(/ | /g, '');
  return numberString.replaceAll(thousandsSeparator, '');
};

// removes HTML tags from a string
export const filterHtmlOutOfString = (text: string) => {
  const span = document.createElement('span');
  span.innerHTML = text;
  return span.textContent || span.innerText || '';
};

export const isUniformat = (categorization?: Maybe<Categorization>) =>
  Boolean(
    categorization &&
      (categorization.id === UNIFORMAT_CATEGORIZATION_ID ||
        (categorization.name && String(categorization.name).includes(UNIFORMAT_CATEGORY)))
  );

export const isMasterFormat = (categorization?: Maybe<Categorization>) =>
  Boolean(
    categorization &&
      (categorization.id === MASTERFORMAT_CATEGORIZATION_ID ||
        (categorization.name && String(categorization.name).includes(MASTERFORMAT_CATEGORY)))
  );

// Sometimes, we might have categorizations with just an ID, but no number on them.
const formatCategoryNumber = (number?: string, categorization?: Maybe<Categorization>) => {
  if (isUniformat(categorization) && number === INTRODUCTION) {
    return '';
  }
  if (isMasterFormat(categorization) && number && Number(number) >= 0) {
    return `${number.slice(0, 2)} ${number.slice(2, 4)} ${number.slice(4)}`;
  }
  return number || null;
};

// Check if the text has any characters besides spaces and newlines
export const isTextValid = (text: string | null | undefined) => {
  if (!text) {
    return false;
  }
  const pattern = /([\w\d].*|\p{Emoji_Presentation}|\p{Extended_Pictographic})/gu;
  const regex = new RegExp(pattern);
  return regex.test(text);
};

export const isValidEmail = (email: string) => {
  const emailPattern =
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return emailPattern.test(email);
};

// Remove the 'Year' Numbers from the categorization names even though they're in the database
// But only do it if we know for sure they're ours.
export const removeYear = (categorizationName: string, isBuiltIn = true, disabled = false) => {
  if (isBuiltIn && !disabled && categorizationName) {
    if (categorizationName === UNIFORMAT_CATEGORY) return 'UniFormat';
    if (categorizationName === MASTERFORMAT_CATEGORY) return 'MasterFormat';
  }
  return categorizationName;
};

export const formatMappedBuiltIn = (categorizationName: string) => {
  if (categorizationName === UNIFORMAT_CATEGORY) return 'UniFormat (Mapped in previous step)';
  if (categorizationName === MASTERFORMAT_CATEGORY) return 'MasterFormat (Mapped in previous step)';
  return categorizationName;
};

export const formatNumber = new Intl.NumberFormat('en-US').format;

export const formatNumberShort = new Intl.NumberFormat('en-US', {
  notation: 'compact',
  maximumSignificantDigits: MAX_SHORT_DIGITS,
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
}).format;

export const percentFormatterSimple = new Intl.NumberFormat('en-US', {
  style: 'percent',
  minimumSignificantDigits: 1,
  maximumSignificantDigits: 2,
});

const percentFormatter = new Intl.NumberFormat('en-US', {
  style: 'percent',
  minimumFractionDigits: 0,
  maximumFractionDigits: 9,
});

export const percentFormatterTwoFractionDigits = new Intl.NumberFormat('en-US', {
  style: 'percent',
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
});

type valueToggles = {
  value: number;
  overrideZeroCostDisplay?: boolean;
};

// HELPERS
export const renderPercentString: (toggles: valueToggles) => string = ({
  value,
  overrideZeroCostDisplay,
}) => {
  if (overrideZeroCostDisplay) {
    if (value === 0) return EMPTY_COST;
  }
  const percentDecimal = value / 100;
  if (value > 0 && percentFormatterTwoFractionDigits.format(percentDecimal) === '0%') return '>0%';
  return percentFormatterTwoFractionDigits.format(percentDecimal);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
export const formatPercent = (percent: any, scale = Scale.CURRENCY) => {
  let convertedPercent = percent;
  if (typeof percent === 'string') {
    convertedPercent = Number(percent); // Convert string to number
  }
  if (Number.isNaN(percent)) {
    return String(percent); // Don't format if NaN
  }
  const formatted = percentFormatter.format(convertedPercent / getPercentScaleFactor(scale));
  if (formatted === 'NaN%') return '0%';
  return formatted;
};

export const getPercentScaleFactor = (scale: number) =>
  10 ** (scale === 0 ? Scale.PERCENT_5 : scale);

export const parseCost = (costString: string, scale: number = Scale.CURRENCY) => {
  // TODO: why do we have this branch? Under what circumstances are we calling
  // this function with non-string arguments? Why?
  if (typeof costString === 'string') {
    const scrubbedString = scrubNumberString(costString, true);

    // We need to carefully call toFixed() AFTER all arithmetic, so that we round correctly, instead
    // of creating a number and casting to a string without rounding.
    const standardizedCostString = (scrubbedString * scale).toFixed(0);
    return Number(standardizedCostString);
  }
  return Number(costString);
};

export const parseCostNumber = (value: string) => Number(parseCost(value));

export const pluralizeString = (thing: string, count: number): string => {
  if (!thing) return '';
  const lastChar = thing.charAt(thing.length - 1);
  const useSpecialSuffix = lastChar === 'y' || lastChar === 's';
  const specialSuffix = lastChar === 'y' ? 'ies' : 'ses';
  const shouldPluralize = count > 1 || count === 0; // > 1 && 0 amounts pluralize

  const word =
    useSpecialSuffix && shouldPluralize
      ? `${thing.substring(0, thing.length - 1)}${specialSuffix}`
      : `${thing}${shouldPluralize ? 's' : ''}`;
  return `${word}`;
};

export const pluralizeCountString = (thing: string, count: number) =>
  `${count} ${pluralizeString(thing, count)}`;

export const prettyBytes = (bytes: number): string => {
  const exponent = Math.min(Math.floor(Math.log10(bytes) / 3), bytesUnits.length - 1);
  const parsedBytes = Number((bytes / 1000 ** exponent).toPrecision(3));
  const unit = bytesUnits[exponent];
  return `${parsedBytes} ${unit}`;
};

const pluralWordsMap = {
  this: 'these',
  was: 'were',
  status: 'statuses',
  its: 'their',
  is: 'are',
};

// takes in a singular word that can't be pluralized with an "s"
// and correctly pluralizes it according to a count"
export const pluralizeWord = (word: keyof typeof pluralWordsMap, count: number): string => {
  return count > 1 || count === 0 ? pluralWordsMap[word] : word;
};

// takes in a singular word and count;
// modifies it to be singular or plural posession
export const pluralizePosession = (word: string, count: number) =>
  count > 1 ? `${word}s'` : `${word}'s`;

export const formatListWithCommas = (items: string[]): string => {
  if (items.length === 0) {
    return '';
  }
  if (items.length === 1) {
    return items[0];
  }
  if (items.length === 2) {
    return `${items[0]} and ${items[1]}`;
  }
  const lastItem = items.pop();
  return `${items.join(', ')}, and ${lastItem}`;
};

export const constantCountLabel = (
  constant: string,
  num: number | string,
  disabled?: boolean
): string => (disabled ? constant : `${constant} (${num})`);

export const noPermissionTooltip = (projectName: string) => {
  return `You do not have permissions to access project ${projectName}, please contact project administrator.`;
};

export const generateClassNameTokens = (className?: string): string[] =>
  className
    ?.trim()
    .split(' ')
    .filter((c) => c) || [];

const getFontStringBySize = (fontSize: number) => `${fontSize}px Larsseit, Sans Serif`;

export const getTextWidth = (text: string, fontSize: number) => {
  const canvas = document.getElementById('canvas') || document.createElement('canvas');
  if (canvas instanceof HTMLCanvasElement) {
    const context = canvas.getContext('2d');
    if (context) {
      context.font = getFontStringBySize(fontSize);
      return context.measureText(text).width;
    }
    throw new Error('Could not find 2D context on HTMLCanvasElement');
  }

  throw new Error('`canvas` could not be created, or is not an HTMLCanvasElement');
};

const extention = new RegExp(/(?:\.([^.]+))?$/);
export const getExtension = (name: string) => extention.exec(name)?.[1] || '';

export const getScheduleImpactUnits = () => {
  const scheduleType = getScheduleType() || '';
  const isVisibleType = scheduleDisplayTypes.includes(scheduleType);
  const scheduleImpactUnits = isVisibleType ? scheduleType.toLowerCase() : '';
  return scheduleImpactUnits;
};

export const getSignedNumberText = (n: number) => {
  return n > 0 ? `+${n.toLocaleString()}` : n.toLocaleString();
};

export const pluralizeQuantityText = (quantity: Quantity) => {
  const magnitudeString = formatCommas(quantity.magnitude);
  const abbreviation =
    quantity.magnitude < 1 || quantity.magnitude > 1
      ? quantity.unit.abbreviationPlural
      : quantity.unit.abbreviationSingular;
  return `${magnitudeString} ${abbreviation}`;
};
