import {
  SearchProjectsFilterOptionsQuery,
  SearchProjectsFilterOptionsQueryVariables,
  SearchResultType,
} from '../../../generated/graphql';
import { useQuery } from '../../../hooks/useMountAwareQuery';

import { searchProjectsFilterOptions } from './queries';

function useSearchProjectsFilterOptionsQuery(searchResultType: SearchResultType, skip = false) {
  return useQuery<SearchProjectsFilterOptionsQuery, SearchProjectsFilterOptionsQueryVariables>(
    searchProjectsFilterOptions,
    {
      variables: {
        searchResultType,
      },
      nextFetchPolicy: 'cache-first',
      skip,
    }
  );
}

export default useSearchProjectsFilterOptionsQuery;
