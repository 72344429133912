import { pendingAllowances, pendingContingencies } from '../../InsightsIcons';

type Props = {
  allowanceCheckbox?: JSX.Element;
  contingencyCheckbox?: JSX.Element;
};

export default function ContingencyLegend(props: Props) {
  const legendEntry = (checkbox: JSX.Element | undefined, icon: JSX.Element, text: string) => (
    <div className="flex items-center pl-3">
      {checkbox ?? null}
      <div className={`p-1 ${checkbox ? '-ml-2' : ''}`}>{icon}</div>
      <div className="p-1">
        <div className="type-label">{text}</div>
      </div>
    </div>
  );

  const contingenciesImg = <div className="h-1 w-3 self-center bg-entities-contingencies" />;
  const allowancesImg = <div className="h-1 w-3 self-center bg-entities-allowances" />;

  const pendingImg = (
    <div className="flex gap-1">
      {pendingContingencies}
      {pendingAllowances}
    </div>
  );

  return (
    <div className="flex h-7 w-full items-center pt-1">
      {legendEntry(props.contingencyCheckbox, contingenciesImg, `Contingencies`)}
      {legendEntry(props.allowanceCheckbox, allowancesImg, `Allowances`)}
      {legendEntry(undefined, pendingImg, `Pending`)}
    </div>
  );
}
