import { FC } from 'react';

import { ChartLegendOrientation } from '../../../api/gqlEnums';
import { withStyles } from '../../../theme/komodo-mui-theme';

import ChartsLegendElement from './ChartsLegendElement';
import ChartsLegendStyles from './ChartsLegendStyles';
import { LegendElementType } from './ChartsLegendUtils';

type ChartsLegendProps = {
  classes: Classes<typeof ChartsLegendStyles>;
  getLegendElementColor: (element: LegendElementType) => string;
  getLegendHint?: (element: LegendElementType) => JSX.Element;
  legendElements: LegendElementType[];
  variant?: OrientationVariant;
  pointerEntered?: (projectID: UUID) => void;
  pointerLeave?: () => void;
  selected?: UUID;
  maxHeight?: number;
  maxTextWidth?: number;
};

type OrientationVariant = ChartLegendOrientation;

const getOrientationClass = (
  variant: OrientationVariant,
  classes: Classes<typeof ChartsLegendStyles>
) => {
  switch (variant) {
    case ChartLegendOrientation.VERTICAL:
      return classes.vertical;
    case ChartLegendOrientation.VERTICAL_WRAP:
      return classes.verticalWrap;
    case ChartLegendOrientation.WRAP:
      return classes.wrap;
    case ChartLegendOrientation.COLUMNS:
      return classes.columns;
    case ChartLegendOrientation.HORIZONTAL:
    default:
      return classes.horizontal;
  }
};

const ChartsLegend: FC<ChartsLegendProps> = ({
  classes,
  getLegendElementColor,
  getLegendHint,
  legendElements,
  variant = ChartLegendOrientation.HORIZONTAL,
  pointerEntered,
  pointerLeave,
  selected,
  maxHeight,
  maxTextWidth,
}) => {
  const orientationClass = getOrientationClass(variant, classes);
  return (
    <div className={orientationClass} style={{ maxHeight, overflow: 'hidden' }}>
      {legendElements &&
        legendElements.map((element) => (
          <ChartsLegendElement
            key={element.index || element.text}
            borderStyle={element.borderStyle}
            color={getLegendElementColor(element)}
            element={element}
            hint={getLegendHint ? getLegendHint(element) : undefined}
            isNarrow={variant === ChartLegendOrientation.WRAP}
            maxTextWidth={maxTextWidth}
            pointerEntered={pointerEntered}
            pointerLeave={pointerLeave}
            selected={selected}
          />
        ))}
    </div>
  );
};

export default withStyles(ChartsLegendStyles)(ChartsLegend);
