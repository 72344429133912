import Linkify from 'linkify-react';
import { FC } from 'react';

import { withStyles } from '../../theme/komodo-mui-theme';

import styles from './JoinLinkifyStyles';

type JoinLinkifyProps = {
  classes: Classes<typeof styles>;
  text: string;
};

const JoinLinkify: FC<JoinLinkifyProps> = ({ classes, text }) => (
  <Linkify
    options={{
      className: classes.link,
    }}
  >
    {text}
  </Linkify>
);

export default withStyles(styles)(JoinLinkify);
