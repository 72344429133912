import {
  ListProjectCategorizationsQuery,
  ListProjectCategorizationsQueryVariables,
  ProjectCategorizationsMetadataQuery,
  ProjectCategorizationsMetadataQueryVariables,
} from '../generated/graphql';

import { listProjectCategorizationsQuery, projectCategorizationsMetadataQuery } from './queries';
import { useQuery } from './useMountAwareQuery';
import { MountPolicy } from './usePolicyOnFirstMount';

export const useProjectCategorizationsQuery = (
  projectID: UUID,
  includeDisabled?: boolean,
  mountPolicy?: MountPolicy
) =>
  useQuery<ListProjectCategorizationsQuery, ListProjectCategorizationsQueryVariables>(
    listProjectCategorizationsQuery,
    {
      variables: { projectID, includeDisabled },
      skip: !projectID,
    },
    mountPolicy
  );

export const useEstimateProjectCategorizationsQuery = (
  projectID: UUID,
  mountPolicy?: MountPolicy
) =>
  useQuery<ProjectCategorizationsMetadataQuery, ProjectCategorizationsMetadataQueryVariables>(
    projectCategorizationsMetadataQuery,
    {
      variables: { projectID, includeDisabled: false },
      skip: !projectID,
    },
    mountPolicy
  );

export const getProjectCategorizationsFromQueryData = (data?: ListProjectCategorizationsQuery) => {
  // No null categorizations, or empty.
  return data?.projectCategorizations?.filter((c) => !!c) ?? [];
};

export const getCategorizationsForProjectFromQueryData = (data?: ListProjectCategorizationsQuery) =>
  getProjectCategorizationsFromQueryData(data).map(({ categorization }) => categorization);

export const getEnabledCategorizationsForProjectFromQueryData = (
  data?: ListProjectCategorizationsQuery
) =>
  getProjectCategorizationsFromQueryData(data)
    .filter(({ disabled }) => !disabled)
    .map(({ categorization }) => categorization);

export const getEnabledCategorizationsIDsForProjectFromQueryData = (
  data?: ListProjectCategorizationsQuery
) =>
  getProjectCategorizationsFromQueryData(data)
    .filter(({ disabled }) => !disabled)
    .map(({ categorization: { id } }) => id) as UUID[];
