import { MASTERFORMAT_CELL, UNIFORMAT_DISPLAY } from '../../constants';
import { removeYear } from '../string';

import { BUILTIN_CATEGORY_LEVELS } from './categorizationUtils';

const getDefaultCategoryLevelName = (categoryLevel: { levelNames: string[] }) =>
  categoryLevel.levelNames[0];
export const categoryDefaultLevelNames = BUILTIN_CATEGORY_LEVELS.map(getDefaultCategoryLevelName);

export const getCategorizationLevelName = (categorization: { name: string; builtin?: boolean }) => {
  const name = removeYear(categorization.name, categorization.builtin);
  const categoryLevel = BUILTIN_CATEGORY_LEVELS.find((cl) => cl.name === name);
  if (categoryLevel) return getDefaultCategoryLevelName(categoryLevel);
  return name;
};

export const makeDefaultLevelNames = (categorizations: { name: string; builtin?: boolean }[]) =>
  (categorizations || []).slice(0, 4).map(getCategorizationLevelName);

export const makeOptionsFromCategorizations = (
  categorizations: {
    id: UUID;
    name: string;
    levels?: number;
    level?: number;
    builtin?: boolean;
    importMetadata?: {
      columnLevel1?: string | null;
      columnLevel2?: string | null;
      columnLevel3?: string | null;
      columnLevel4?: string | null;
    } | null;
  }[]
) =>
  categorizations.flatMap((categorization) => {
    if (categorization) {
      const match = BUILTIN_CATEGORY_LEVELS.find(
        (cl) => cl && categorization && cl.id === categorization.id
      );
      if (match) return match;
      if ((categorization?.levels ?? 0) > 1) {
        const levelNames = [];

        for (let i = 1; i <= (categorization?.levels ?? 0); i += 1) {
          let categorizationLevelName = `${categorization.name} Level ${i}`;
          switch (i) {
            case 1:
              categorizationLevelName =
                categorization?.importMetadata?.columnLevel1 || categorizationLevelName;
              break;
            case 2:
              categorizationLevelName =
                categorization?.importMetadata?.columnLevel2 || categorizationLevelName;
              break;
            case 3:
              categorizationLevelName =
                categorization?.importMetadata?.columnLevel3 || categorizationLevelName;
              break;
            case 4:
              categorizationLevelName =
                categorization?.importMetadata?.columnLevel4 || categorizationLevelName;
              break;
            default:
              break;
          }
          levelNames.push(categorizationLevelName);
        }
        return {
          ...categorization,
          id: categorization.id || '',
          builtin: categorization.builtin ?? false,
          categorization,
          levelNames,
        };
      }
      return categorization;
    }
    return [];
  });

export const makeGroupByWithCategory = (value: string) => {
  let val;
  const getLevelName = (group: string) =>
    ((BUILTIN_CATEGORY_LEVELS.find(({ name }) => name === group) || {}).levelNames || [])[0];
  switch (value) {
    case UNIFORMAT_DISPLAY:
    case MASTERFORMAT_CELL:
      val = getLevelName(value);
      break;
    default:
      val = value;
      break;
  }
  return val;
};
