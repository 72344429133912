import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';

export enum ItemStatusVariants {
  COMPACT = 'COMPACT',
  COZY = 'COZY',
}

export type ItemStatusVariant = keyof typeof ItemStatusVariants;

// CONSTANTS
const optionsPaddingLeftCompact = 4;
const optionsPaddingLeftCozy = 16;
const labelSizeCompact = 42;
const labelSizeCozy = 55;
const outerContainerWidthCompact = 48;
const outerContainerWidthCozy = 60;
const fullComponentWidth = 260;

const createStylesItemsStatusHoverSelector = (theme: KomodoTheme, variant: ItemStatusVariant) => {
  const isCompact = variant === ItemStatusVariants.COMPACT;
  const labelSize = isCompact ? labelSizeCompact : labelSizeCozy;
  const optionsPaddingLeft = isCompact ? optionsPaddingLeftCompact : optionsPaddingLeftCozy;
  const outerContainerWidth = isCompact ? outerContainerWidthCompact : outerContainerWidthCozy;

  return createStyles({
    button: {
      background: 'none',
      border: '0',
      padding: '0',
      position: 'relative',
      zIndex: 50,
    },
    cover: {
      height: labelSize,
      width: labelSize / 2,
      position: 'relative',
      left: 0,
      background: theme.palette.backgroundWhite,
      zIndex: 99,
    },
    cursor: {
      cursor: 'pointer',
    },
    iconsWrapper: {
      opacity: 0,
      background: theme.palette.backgroundWhite,
      border: theme.border.line,
      borderRadius: '0 25px 25px 0',
      left: -180,
      padding: '6px 6px 6px 20px',
      position: 'absolute',
      top: 5,
      transition: '0s',
    },
    iconsWrapperVisible: {
      opacity: 1,
      left: 20,
      transition: '.3s',
    },
    innerContainer: {
      alignItems: 'center',
      display: 'flex',
      height: labelSize + 4,
      overflow: 'hidden',
      position: 'absolute',
      transition: '.3s',
    },
    innerContainerVisible: {
      width: `${fullComponentWidth}px`,
    },
    input: {
      display: 'none',
    },
    label: {
      alignItems: 'center',
      borderRadius: '100%',
      display: 'flex',
      height: labelSize,
      justifyContent: 'center',
      padding: '1px',
      width: labelSize,
    },
    labelContainer: {
      height: '100%',
      width: labelSize,
      display: 'flex',
      alignItems: 'center',
      zIndex: 100,
    },
    li: {
      alignItems: 'center',
      display: 'flex',
      padding: '0 6.5px',
      '&:hover': {
        transform: 'scale(1.1)',
      },
    },
    nested: {
      backgroundColor: theme.palette.nestingGrey,
      paddingLeft: 0,
    },
    onFocusBorder: {
      border: theme.border.line,
      padding: '0px',
      backgroundColor: theme.palette.backgroundWhite,
    },
    outerContainer: {
      alignItems: 'center',
      alignSelf: 'center',
      display: 'flex',
      flexShrink: 0,
      height: labelSize,
      justifyContent: 'left',
      marginTop: 1,
      overflow: 'hidden',
      width: outerContainerWidth,
    },
    outlineOnClick: {
      outline: 'none',
    },
    sidebarBackground: {
      opacity: 0,
    },
    ul: {
      display: 'flex',
      listStyleType: 'none',
      marginBlockEnd: '0',
      marginBlockStart: '0',
      padding: '0',
      paddingLeft: optionsPaddingLeft,
    },
    unNested: {
      paddingLeft: `${theme.spacing.generic[11]}px`,
    },
  });
};

export default createStylesItemsStatusHoverSelector;
