import { FC } from 'react';

import { Typography } from '@material-ui/core';

import { withStyles } from '../../../theme/komodo-mui-theme';
import TooltipTitles from '../../NormalTooltip/TooltipTitles';
import VarianceModalUnitsPreviewTooltip from '../../VarianceReport/VarianceModals/VarianceModalUnits/VarianceModalUnitsPreviewTooltip';
import { modalUnitsColors } from '../../VarianceReport/VarianceModals/VarianceModalUnits/VarianceModalUnitsUtils';

import CostReportMetricDiagramStyles, {
  PREVIEW_SECTION_WIDTH,
} from './CostReportMetricGroupDiagramStyles';
import { MetricGroup, MetricGroupRow, SelectedOption } from './CostReportMetricGroupUtils';

type CostReportMetricDiagramProps = {
  classes: Classes<typeof CostReportMetricDiagramStyles>;
  metricGroups: MetricGroup[];
  milestoneNames: string[];
};

const getMilestoneColumnKey = (milestoneName: string, n: number) => `${milestoneName}-${n}`;

const CostReportMetricDiagram: FC<CostReportMetricDiagramProps> = ({
  classes,
  metricGroups,
  milestoneNames,
}) => {
  // Helpers
  const calcWidth = (numSelected: number) => {
    const numSections = metricGroups.filter(
      ({ selectedOptions }) => selectedOptions.length > 0
    ).length;
    return (PREVIEW_SECTION_WIDTH - (numSections - 1) * 6) / numSections / numSelected;
  };

  const getSectionBands = (
    name: string,
    rows: MetricGroupRow[],
    selectedOptions: SelectedOption[],
    color: string
  ) => {
    const numSelected = selectedOptions.length;
    const bands: JSX.Element[] = [];
    rows.forEach((row: MetricGroupRow) => {
      const selectedOption = selectedOptions.find((o) => o.text === row.key);
      if (selectedOption) {
        bands.push(
          <VarianceModalUnitsPreviewTooltip
            title={<TooltipTitles boldHeader titles={[name, row.text]} />}
          >
            <div
              key={`${color}${selectedOption.opacity}`}
              className={classes.cursor}
              style={{
                background: color,
                width: calcWidth(numSelected),
                opacity: selectedOption.opacity,
              }}
            />
          </VarianceModalUnitsPreviewTooltip>
        );
      }
    });
    return bands;
  };

  return (
    <div className={classes.previewContainer}>
      {milestoneNames.map((milestoneName, n) => (
        <div key={getMilestoneColumnKey(milestoneName, n)} className={classes.previewSection}>
          <div className={classes.previewHeader}>
            <Typography className={classes.milestoneText}>{milestoneName}</Typography>
          </div>
          <div className={classes.previewBody}>
            {metricGroups.map(
              ({ selectedOptions, identifier, rows }, i) =>
                selectedOptions.length > 0 && (
                  <div
                    key={`${getMilestoneColumnKey(milestoneName, n)}-${identifier}-${
                      selectedOptions.length
                    }`}
                    className={classes.previewBodySection}
                  >
                    {getSectionBands(milestoneName, rows, selectedOptions, modalUnitsColors[i])}
                  </div>
                )
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default withStyles(CostReportMetricDiagramStyles)(CostReportMetricDiagram);
