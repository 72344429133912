import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../../theme/komodo-mui-theme';

export default (theme: KomodoTheme) =>
  createStyles({
    disabledLink: {
      ...theme.typography.webSmallLink,
      display: 'inherit',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      textDecoration: 'none',
      color: theme.palette.disabledGrey,
    },
    linkContainer: {
      flex: 1,
      minWidth: 0,
    },
    titleText: { ...theme.typography.webTableText },
    link: {
      ...theme.typography.webSmallLink,
      display: 'inherit',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
  });
