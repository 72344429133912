import { FC, useContext, useEffect, useMemo } from 'react';

import { useReactiveVar } from '@apollo/client';
import { Checkbox, Typography } from '@material-ui/core';

import {
  reportTransition,
  transitionEventTypes,
} from '../../../../analytics/analyticsEventProperties';
import { transitionOnboardingVar } from '../../../../api/apollo/reactiveVars';
import { EstimateType, TermKey } from '../../../../api/gqlEnums';
import { ACCEPTED, SORT_NUMBER } from '../../../../constants';
import { CostReportColumnType } from '../../../../generated/graphql';
import { useMilestoneCostReportsQuery } from '../../../../hooks/useMilestoneCostReportsQuery';
import useSendAnalytics from '../../../../hooks/useSendAnalytics';
import { withStyles } from '../../../../theme/komodo-mui-theme';
import { computeColumnInputs, computeSelectCostReport } from '../../../../utilities/milestones';
import { pluralizeString } from '../../../../utilities/string';
import { getMilestoneIdFromUrl, getProjectIdFromUrl } from '../../../../utilities/url';
import { getCostReportByType, renderCostString } from '../../../CostReport/CostReportUtils';
import {
  INTEGRATIONS_FILTERS,
  SCHEDULE_IMPACT_FILTERS,
  VISIBILITIES,
} from '../../../ItemsList/ItemsListUtils';
import { emptyItemsTree, useItemsTreeQuery } from '../../../ItemsList/useItemsTreeQuery';
import { ProjectTermStore } from '../../../ProjectDisplaySettings/TerminologyProvider';
import MilestoneSelect from '../../../Select/MilestoneSelect';
import TypeCostSelect from '../../../Select/TypeCostSelect/TypeCostSelect';
import useMemoWrapper from '../../../useMemoWrapper';

import styles from './TransitionMilestoneSelectionStyles';
import {
  filterMilestonesForEstimateTransition,
  getAvailableTypesForMilestone,
} from './TransitionMilestoneSelectionUtils';

const sidebarColumnTypes: CostReportColumnType[] = [
  CostReportColumnType.RUNNINGTOTAL_REPORT,
  CostReportColumnType.ESTIMATE_REPORT,
  CostReportColumnType.TARGET_REPORT,
];

const costFormatter = (value: Parameters<typeof renderCostString>[0]['cost']) =>
  renderCostString({ cost: value, isExact: true, isWide: true });

type TransitionMilestoneSelectionProps = {
  classes: Classes<typeof styles>;
  currentEstimateType: EstimateType.ACTIVE_ESTIMATE | EstimateType.BUDGET;
  projectMilestones: Milestone[] | undefined;
};

const TransitionMilestoneSelection: FC<TransitionMilestoneSelectionProps> = ({
  classes,
  currentEstimateType,
  projectMilestones,
}) => {
  const t = useContext(ProjectTermStore);
  const sendAnalytics = useSendAnalytics();
  const projectID = getProjectIdFromUrl();

  const currentMilestoneID = getMilestoneIdFromUrl();

  const currentMilestone = projectMilestones?.find((m: Milestone) => m.id === currentMilestoneID);
  const isDraft = currentMilestone?.isDraft ?? true;

  const { milestoneID, type, incorporateAccepted } = useReactiveVar(transitionOnboardingVar);
  const milestones = useMemoWrapper(
    filterMilestonesForEstimateTransition,
    projectMilestones ?? [],
    currentMilestoneID,
    currentEstimateType
  );

  // Running Total
  // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO CT-566: Fix this pls :)
  const columnInputs = useMemo(() => computeColumnInputs(sidebarColumnTypes), [projectMilestones]);
  const { data: { milestoneCostReports } = { milestoneCostReports: [] } } =
    useMilestoneCostReportsQuery(milestoneID, projectID, {}, columnInputs);
  const costReport = useMemo(
    () => computeSelectCostReport(milestoneCostReports),
    [milestoneCostReports]
  );
  const running = getCostReportByType(costReport, CostReportColumnType.RUNNINGTOTAL_REPORT);
  const runningTotal = running?.range;

  const estimate = getCostReportByType(costReport, CostReportColumnType.ESTIMATE_REPORT);
  const estimateTotal = estimate?.range;

  const budget = getCostReportByType(costReport, CostReportColumnType.TARGET_REPORT);
  const budgetTotal = budget?.range;

  // Accepted Items
  const { data: { itemsTree = emptyItemsTree } = {} } = useItemsTreeQuery(
    { sortKey: SORT_NUMBER },
    {
      creators: [],
      contingencyDraws: [],
      dueDateEnd: undefined,
      dueDateStart: undefined,
      integrations: INTEGRATIONS_FILTERS,
      scheduleImpacts: SCHEDULE_IMPACT_FILTERS,
      searchText: '',
      showOptions: false,
      statuses: [ACCEPTED as Status],
      visibilities: VISIBILITIES,
    },
    null,
    milestoneID,
    null,
    projectID,
    null,
    {},
    [],
    false
  );

  const hasAcceptedItems = !!itemsTree.totalItems;
  const showRunningTotal = hasAcceptedItems;
  const isIncludeAcceptedEnabled = type === CostReportColumnType.RUNNINGTOTAL_REPORT;
  const selectedMilestone = milestones.find((m) => m.id === milestoneID);
  const includeAcceptedText = `Transition ${itemsTree.totalItems} Accepted ${pluralizeString(
    'item',
    itemsTree.totalItems
  )} from ${selectedMilestone?.name} in this milestone with status Incorporated.`;
  const selectedMilestoneTypes = getAvailableTypesForMilestone(
    !!selectedMilestone?.activeEstimateID,
    !!selectedMilestone?.budgetID,
    showRunningTotal,
    milestoneID === currentMilestoneID ? currentEstimateType : undefined
  );

  const getTypeName = (name: TermKey) => t.titleCase(name);

  const typeEntries = selectedMilestoneTypes.map((availableType) => {
    switch (availableType) {
      case CostReportColumnType.ESTIMATE_REPORT:
        return {
          id: availableType,
          name: getTypeName(TermKey.ESTIMATE),
          nameOption: costFormatter(estimateTotal),
        };
      case CostReportColumnType.TARGET_REPORT:
        return {
          id: availableType,
          name: getTypeName(TermKey.TARGET),
          nameOption: costFormatter(budgetTotal),
        };
      case CostReportColumnType.RUNNINGTOTAL_REPORT:
        return {
          id: availableType,
          name: getTypeName(TermKey.RUNNING_TOTAL),
          nameOption: costFormatter(runningTotal),
        };
      default:
        return { id: availableType, name: availableType };
    }
  });

  const onChangeMilestone = (newMilestoneID: string | null) => {
    const newMilestone = milestones.find((m) => m.id === newMilestoneID);
    const newMilestoneTypes = getAvailableTypesForMilestone(
      !!newMilestone?.activeEstimateID,
      !!newMilestone?.budgetID,
      false, // we don't know if running total will be an available type yet here,
      newMilestoneID === currentMilestoneID ? currentEstimateType : undefined
    );
    const newType = newMilestoneTypes.length ? newMilestoneTypes[0] : undefined;

    transitionOnboardingVar({
      ...transitionOnboardingVar(),
      type: newType,
      milestoneID: newMilestoneID ?? undefined,
    });
    sendAnalytics(
      reportTransition(transitionEventTypes.TRANSITION_SELECT_MILESTONE, {
        milestoneID: newMilestoneID,
        type: newType,
        incorporateAccepted,
      })
    );
  };

  const onChangeType = (newType: CostReportColumnType) => {
    transitionOnboardingVar({
      ...transitionOnboardingVar(),
      type: newType,
    });
    sendAnalytics(
      reportTransition(transitionEventTypes.TRANSITION_SELECT_MILESTONE, {
        milestoneID,
        type: newType,
        incorporateAccepted,
      })
    );
  };

  const onChangeIncludeAccepted = (newIncludeAccepted: boolean) => {
    transitionOnboardingVar({
      ...transitionOnboardingVar(),
      incorporateAccepted: newIncludeAccepted,
      numberOfAcceptedItems: itemsTree.totalItems,
    });
    sendAnalytics(
      reportTransition(transitionEventTypes.TRANSITION_SELECT_MILESTONE, {
        milestoneID,
        type,
        incorporateAccepted: newIncludeAccepted,
      })
    );
  };

  useEffect(() => {
    if (!type && selectedMilestoneTypes.length) {
      onChangeType(selectedMilestoneTypes[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO CT-566: Fix this pls :)
  }, [type, selectedMilestoneTypes]);

  useEffect(() => {
    if (!milestoneID && milestones && milestones[0]?.id) onChangeMilestone(milestones[0].id);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO CT-566: Fix this pls :)
  }, [milestoneID, milestones]);

  return (
    <div className={classes.innerContainer}>
      <Typography className={classes.label}>Milestone</Typography>
      <div className={classes.horizontal}>
        <MilestoneSelect
          milestones={milestones}
          onChangeMilestone={onChangeMilestone}
          selectedMilestone={milestoneID}
        />
      </div>
      <div className={classes.horizontal}>
        <Typography className={classes.label}>Value</Typography>
        <TypeCostSelect entries={typeEntries} onChange={onChangeType} selected={type} />
        {isIncludeAcceptedEnabled && hasAcceptedItems && !isDraft && (
          <div className={`${classes.horizontal} ${classes.incorporateAccepted}`}>
            <Checkbox
              checked={incorporateAccepted}
              classes={{ root: classes.checkbox }}
              disableRipple
              onClick={(evt) => {
                evt.stopPropagation();
                onChangeIncludeAccepted(!incorporateAccepted);
              }}
            />
            <Typography>{includeAcceptedText}</Typography>
          </div>
        )}
      </div>
    </div>
  );
};

export default withStyles(styles)(TransitionMilestoneSelection);
