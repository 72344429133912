import { FC, memo } from 'react';
import * as React from 'react';

import usePagination from './hooks/usePagination';
import useUpdate from './hooks/useUpdate';
import JoinGridRow from './JoinGridRow';
import { startCellWidth } from './style/styleConstants';
import { GridController } from './types';
import range from './utilities/range';

const PRELOADER_DELAY = 50;
const PRELOADER_LINES_COUNT = 50;

interface Props {
  endRow: number;
  grid: GridController;
  scrollDirection: 'up' | 'down';
  isEditing: boolean;
  setEditing: (b: boolean) => void;
  setEditorDefaultValue: (s: GridCellValue) => void;
  parentUpdate: () => void;
  startRow: number;
  bodyRef: React.RefObject<HTMLDivElement>;
}

// Don't re-render the inner table even if the parent grid with the key handlers
// re-rendered (for example, because it had a transition between editing state).
// We do want to re-render if that component remounts and setEditing changes, though.
const InnerTable: FC<Props> = memo(
  ({
    endRow,
    grid,
    isEditing,
    setEditing,
    setEditorDefaultValue,
    startRow,
    parentUpdate,
    bodyRef,
    scrollDirection,
  }) => {
    // When we 're-render' this table from the GridController object due to content changes,
    // we want to render the parent VirtualTable (in case the heights changed), and also
    // the InnerTable explicitly, in case the heights & start row didn't change but
    // the content did in some way that is not re-rendered by single-cell updates - for
    // example, by deleting a row. Therefore the handle we give the GridController contains
    // both of these functions.
    const forceUpdate = useUpdate();
    grid.setUpdateTable(() => {
      parentUpdate();
      forceUpdate();
    });
    usePagination(endRow, startRow, grid);

    const {
      data: { columns, lines },
      isPrinting,
    } = grid;

    const heights = grid.getRowHeights();
    const startCellWidthValue = startCellWidth(lines.length);

    const isTotalCellArr = Array(columns.length).fill(false);
    isTotalCellArr[columns.length - 1] = true;

    const hidePreloader = lines.length < PRELOADER_LINES_COUNT || isPrinting;
    const isScrollingUp = scrollDirection === 'up';

    return (
      <>
        {range(startRow, endRow + 1, (i) => {
          if (i < 0 || i >= grid.numRows()) return null;
          // startRow === 0 - hide effect for first opening
          // !isScrollingUp - when user scrolls to the top and effect is on,
          // we need to show effect to rerender the data
          const delay = (startRow === 0 && !isScrollingUp) || hidePreloader ? 0 : PRELOADER_DELAY;

          return (
            <JoinGridRow
              key={i}
              bodyRef={bodyRef}
              /* Performance optimization for scrolling, skips outdated renderings */
              delay={delay}
              grid={grid}
              /* Forces re-render on height change but not otherwise */
              height={heights[i] || 0}
              i={i}
              id={(lines[i] || {}).id}
              isEditing={isEditing}
              isTotalCellArr={isTotalCellArr}
              numFields={grid.numCols()}
              renderGeneration={grid.getRenderGeneration()}
              setEditing={setEditing}
              setEditorDefaultValue={setEditorDefaultValue}
              startCellWidth={startCellWidthValue}
            />
          );
        })}
      </>
    );
  }
);

InnerTable.displayName = 'InnerTable';
export default InnerTable;
