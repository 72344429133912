import { Divider } from '@material-ui/core';
import { Add, Search } from '@material-ui/icons';

import { FORECASTING_REPORTS_CREATE_REPORT } from '../../../tagConstants';
import { Button, TextInput } from '../../scales';

export default function ForecastingReportsHeader(props: {
  onCreateReport: () => void;
  search: string;
  setSearch: (search: string) => void;
}) {
  return (
    <>
      <div className="flex items-center gap-4 p-4">
        {/* 240px is a arbitrary width that I am using to match search bar width on the SearchHeader, previously was 450px */}
        <div className="w-[240px]">
          <TextInput
            aria-label="search projects"
            // TODO CT-1036: make/use a SearchInput scales component, use a more specific data-cy
            data-cy="search-input"
            onChange={props.setSearch}
            onClear={() => props.setSearch('')}
            placeholder="Search reports"
            startAdornment={<Search />}
            value={props.search}
          />
        </div>
        <Button
          data-cy={FORECASTING_REPORTS_CREATE_REPORT}
          label="Create a report"
          onClick={props.onCreateReport}
          startIcon={<Add />}
          type="primary"
        />
      </div>
      <Divider />
    </>
  );
}
