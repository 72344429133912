// prettier-ignore
const brandTriggerMap: Record<string, string> = {
    '3form': '3Form',
    '9wood': '9Wood',
    'accutrol': 'Accutrol',
    'acrovyn': 'Acrovyn',
    'altro': 'Altro',
    'arcadia': 'Arcadia',
    'armstrong': 'Armstrong',
    'avadek': 'Avadek',
    'belden': 'Belden',
    'berridge': 'Berridge',
    'bobrick': 'Bobrick',
    'carlisle': 'Carlisle',
    'centria': 'Centria',
    'ceraclad': 'Ceraclad',
    'certainteed': 'Certainteed',
    'corian': 'Corian',
    'crl': 'CRL',
    'daikin': 'Daikin',
    'daltile': 'Daltile',
    'dirtt': 'Dirtt',
    'dolly varden': 'Dolly Varden',
    'equitone': 'Equitone',
    'filzfelt': 'Filzfelt',
    'gatco': 'Gatco',
    'ge': 'GE',
    'hanover': 'Hanover',
    'hardie': 'Hardie',
    'james hardie': 'James Hardie',
    'jci': 'Johnson Controls',
    'johnson controls': 'Johnson Controls',
    'kawneer': 'Kawneer',
    'kebony': 'Kebony',
    'kinetex': 'Kinetex',
    'kingspan': 'Kingspan',
    'knotwood': 'Knotwood',
    'kohler': 'Kohler',
    'kone': 'Kone',
    'kynar': 'Kynar',
    'lithonia': 'Lithonia',
    'mitrex': 'Mitrex',
    'monoglass': 'Monoglass',
    'morin': 'Morin',
    'nanawall': 'NanaWall',
    'nichiha': 'Nichiha',
    'otis': 'Otis',
    'pac clad': 'Pac-Clad',
    'pac-clad': 'Pac-Clad',
    'pacclad': 'Pac-Clad',
    'pella': 'Pella',
    'prodema': 'Prodema',
    'quaker': 'Quaker',
    'renlita': 'Renlita',
    'romex': 'Romex',
    'rulon': 'Rulon',
    'salto': 'Salto',
    'samsung': 'Samsung',
    'schindler': 'Schindler',
    'schlage': 'Schlage',
    'shaw': 'Shaw',
    'sherwin williams': 'Sherwin Williams',
    'siemens': 'Siemens',
    'solarban': 'Solarban',
    'square d': 'Square D',
    'sto': 'Sto',
    'stonezula': 'Stonezula',
    'stonhard': 'Stonhard',
    'tarkett': 'Tarkett',
    'taylor clay': 'Taylor Clay',
    'tectum': 'Tectum',
    'teknion': 'Teknion',
    'thermax': 'Thermax',
    'tke': 'TKE Elevator',
    'trane': 'Trane',
    'tremco': 'Tremco',
    'trespa': 'Trespa',
    'trex': 'Trex',
    'tyvek': 'Tyvek',
    'unistrut': 'Unistrut',
    'usg': 'USG',
    'victaulic': 'Victaulic',
    'viracon': 'Viracon',
    'vitro': 'Vitro',
    'whirlpool': 'Whirlpool',
    'wilsonart': 'Wilsonart',
    'xypex': 'Xypex',
    'aaon': 'AAON',
    'arizona tile': 'Arizona Tile',
    'asco': 'Asco',
    'assa abloy': 'Assa Abloy',
    'baswa': 'Baswa',
    'deguarda': 'Deguarda',
    'dekton': 'Dekton',
    'frasch': 'Frasch',
    'greenheck': 'Greenheck',
    'inpro': 'Inpro',
    'intus': 'Intus',
    'kember': 'Kember',
    'ks series': 'Kingspan',
    'moen': 'Moen',
    'nora': 'Nora',
    'raco': 'Raco',
    'rytec': 'Rytec',
    'safb': 'Safb',
    'sargent': 'Sargent',
    'sarnafil': 'Sarnafil',
    'scuffmaster': 'Scuffmaster',
    'sloan': 'Sloan',
    'strongar': 'Strongar',
    'swiss pearl': 'Swiss Pearl',
    'swisspearl': 'Swisspearl',
    'uponor': 'Uponor',
    'viva': 'Viva',
    'wausau': 'Wausau',
    'williams stone': 'Williams Stone',
    'won': 'Won Door',
    'won door': 'Won Door',
    'mecho': 'Mecho Shade Systems',
    'pergo': 'Pergo Flooring',
    'roppe': 'Roppe',
    'linder': 'Linder',
    'draper': 'Draper',
    'modernfold': 'Modernfold',
    'encore': 'Encore',
    'remco': 'Remco',
    'modernus': 'Modernus',
    'rockwool': 'Rockwool',
    'amico': 'Amico',
    'eaton': 'Eaton',
    'wiremold': 'Wiremold',
    'alucobond': 'Alucobond',
    'fry reglet': 'Fry Reglet',
    'kalwall': 'Kalwall',
    'gordon': 'Gordon',
    'rockfon': 'Rockfon',
    'johnsonite': 'Johnsonite',
    'sika': 'Sika',
    'landscape forms': 'Landscape Forms',
    'swisslog': 'Swisslog',
    'falcon': 'Falcon',
    'clark pacific': 'Clark Pacific',
    'thermoseal': 'Thermoseal',
    'forta': 'FORTA',
    'soprema': 'Soprema',
    'cummins': 'Cummins',
    'ad systems': 'AD Systems',
    'supra': 'Supra',
    'temtrol': 'Temtrol',
    'steris': 'Steris',
    'masonite': 'Masonite',
    'aquatherm': 'Aquatherm',
    'otis elevator': 'Otis Elevator',
    'aep span': 'AEP Span',
    'mohawk': 'Mohawk',
    'milliken': 'Milliken',
    'allura': 'Allura',
    'acgi': 'ACGI',
    'risco': 'Risco',
    'cookson': 'Cookson',
    'dri-design': 'Dri-Design',
    'dri design': 'Dri-Design',
    'pliteq': 'Pliteq',
    'kovach': 'Kovach',
    'chargepoint': 'Chargepoint',
    'lithocrete': 'Lithocrete',
    'von duprin': 'Von Duprin',
    'muraflex': 'Muraflex',
    'intus windows': 'Intus Windows',
    'construction specialties': 'Construction Specialties',
    'raynor': 'Raynor',
    'arcoplast': 'Arcoplast',
    'thermafiber': 'Thermafiber',
    'lyons sandstone': 'Lyons Sandstone',
};

// Check all our triggers and see if any are found in the provided text. Return the human
// readable name of the first trigger found if any.
export function CheckBrandTriggers(name: string, description: string): string | undefined {
  const keys = Object.keys(brandTriggerMap);
  for (let i = 0; i < keys.length; i += 1) {
    const k = keys[i];
    const regex = new RegExp(`(^${k} | ${k} | ${k}$)`, 'i');

    if (regex.test(name) || regex.test(description)) {
      return brandTriggerMap[keys[i]];
    }
  }
  return undefined;
}
