import { useNavigate } from 'react-router-dom';

import { useReactiveVar } from '@apollo/client';

import { reportVariance, varianceEventTypes } from '../../../../analytics/analyticsEventProperties';
import {
  currentUserReportVar,
  setReactiveLocal,
  varianceOnboardingVar,
} from '../../../../api/apollo/reactiveVars';
import { JoinProjectRoutes, VarianceModal } from '../../../../api/gqlEnums';
import { USER_REPORT_VAR, VARIANCE_REACTIVE_VAR } from '../../../../constants';
import {
  getCategorizationsForProjectFromQueryData,
  useProjectCategorizationsQuery,
} from '../../../../hooks/useProjectCategorizationsQuery';
import useSendAnalytics from '../../../../hooks/useSendAnalytics';
import { makeDefaultLevelNames } from '../../../../utilities/categorization';
import { getDefaultReportCategorizations } from '../../../../utilities/categorization/categorizationUtils';
import { clickLinkKeyHandler } from '../../../../utilities/clickHandler';
import { generateSharedPath } from '../../../../utilities/routes/links';
import { getProjectIdFromUrl } from '../../../../utilities/url';
import JoinDialogMulti from '../../../Dialogs/JoinDialog/JoinDialogMulti';
import { JoinDialogType } from '../../../Dialogs/JoinDialog/JoinDialogUtils';
import VarianceModalMilestones from '../VarianceModalMilestones/VarianceModalMilestones';
import VarianceModalUnits from '../VarianceModalUnits/VarianceModalUnits';
import VarianceSettingsSelection from '../VarianceSettingsSelection/VarianceSettingsSelection';

const headerText = 'Variance Report';

const contentHeader1milestones = 'Customize your Variance Report';

const contentHeader2milestones = 'First, tell us which Milestones and Values you want to compare';
const contentHeader2expression =
  'Choose the units of measure you want to use to express the Milestone and Variance values. Options available based on project Units of Measure';
const contentHeader2whatToSee = 'Finally, tell us how you want to see the report';

const tipText = 'Don’t worry, you can always change your selections after you create your report.';

export const openVarianceOnboarding = (clearReport = false) => {
  const varianceOnboarding = varianceOnboardingVar();
  const value = {
    ...varianceOnboarding,
    modalIsOpen: true,
    modal: VarianceModal.MILESTONES,
  };
  if (clearReport) {
    setReactiveLocal(currentUserReportVar, USER_REPORT_VAR, undefined);
  }
  setReactiveLocal(varianceOnboardingVar, VARIANCE_REACTIVE_VAR, value);
};

const VarianceManager = () => {
  const navigate = useNavigate();
  const projectId = getProjectIdFromUrl();
  const { data } = useProjectCategorizationsQuery(projectId);
  const categorizations = getCategorizationsForProjectFromQueryData(data);
  const defaultCategorizations = getDefaultReportCategorizations(categorizations);
  const defaultGroupBy = makeDefaultLevelNames(defaultCategorizations);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  const variance: any = useReactiveVar(varianceOnboardingVar);
  const sendAnalytics = useSendAnalytics();

  const openModal = (modal: VarianceModal) => {
    setReactiveLocal(varianceOnboardingVar, VARIANCE_REACTIVE_VAR, { ...variance, modal });
    sendAnalytics(reportVariance(varianceEventTypes.VARIANCE_VISIT_MENU, { modal }));
  };

  const { modal: modalStored, modalIsOpen } = variance;
  const modal: VarianceModal | undefined = modalIsOpen ? modalStored : undefined;

  const cancel = (isFinish?: boolean) => {
    setReactiveLocal(varianceOnboardingVar, VARIANCE_REACTIVE_VAR, {
      ...variance,
      modalIsOpen: false,
    });
    if (!isFinish) sendAnalytics(reportVariance(varianceEventTypes.VARIANCE_CANCEL_MENU));
  };

  const toReport = () => {
    const path = generateSharedPath(JoinProjectRoutes.VARIANCE, { projectId });
    clickLinkKeyHandler(navigate, undefined, path, undefined);
  };

  const finalize = () => {
    cancel(true);
    toReport();
    sendAnalytics(reportVariance(varianceEventTypes.VARIANCE_FINISH_MENU));
  };

  const dialogs: JoinDialogType[] = [
    {
      open: modal === VarianceModal.MILESTONES,
      onOpen: () => openModal(VarianceModal.MILESTONES),
      onClose: () => cancel(),
      onNext: () => {
        openModal(VarianceModal.EXPRESSION);
        toReport();
      },
      onBack: () => cancel(),
      contentHeader1: contentHeader1milestones,
      contentHeader2: contentHeader2milestones,
      contentComponent: <VarianceModalMilestones defaultGroupBy={defaultGroupBy} />,
      headerText,
      tipText,
    },
    {
      open: modal === VarianceModal.EXPRESSION,
      onOpen: () => openModal(VarianceModal.EXPRESSION),
      onClose: () => cancel(),
      onNext: () => {
        openModal(VarianceModal.WHAT_TO_SEE);
      },
      onBack: () => {
        openModal(VarianceModal.MILESTONES);
      },
      contentHeader1: contentHeader1milestones,
      contentHeader2: contentHeader2expression,
      contentComponent: <VarianceModalUnits defaultGroupBy={defaultGroupBy} />,
      headerText,
      tipText,
    },
    {
      open: modal === VarianceModal.WHAT_TO_SEE,
      onOpen: () => openModal(VarianceModal.WHAT_TO_SEE),
      onClose: () => cancel(),
      onNext: () => {
        finalize();
      },
      onBack: () => {
        openModal(VarianceModal.EXPRESSION);
      },
      contentHeader1: contentHeader1milestones,
      contentHeader2: contentHeader2whatToSee,
      contentComponent: (
        <VarianceSettingsSelection
          categorizations={categorizations}
          defaultGroupBy={defaultGroupBy}
        />
      ),
      headerText,
      tipText,
    },
  ];

  return <JoinDialogMulti dialogs={dialogs} />;
};

export default VarianceManager;
