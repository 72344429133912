import { JoinProjectRoutes, JoinRoutes } from '../../api/gqlEnums';
import { UserStatus } from '../../api/gqlEnumsBe';
import { PROJECTS } from '../../constants';
import { RouteKeys } from '../../routes/paths';
import { generateSharedPath } from '../../utilities/routes/links';
import { getProjectIdFromUrl } from '../../utilities/url';

// ROUTING
// Here's where we standardize Signup Routing
// We need to include search params to track path of arrival
// If there's no ProjectId, we will go to /projects/${route}
export const generateSignupPath = (route: JoinProjectRoutes, projectID?: UUID) => {
  const search = decodeURIComponent(window.location.search);
  return generateSharedPath(route, {
    projectId: projectID || PROJECTS,
    search,
  });
};

export const getSignupURL: (companyUser: CompanyUser) => string | null = (companyUser) => {
  const { company, isIndependent, matchedCompany, user } = companyUser;
  const { didSetup } = user || {};
  const needsCompany = (!company || user?.status === UserStatus.PENDING) && !isIndependent;
  // First roadblock: Company
  const projectId = getProjectIdFromUrl();
  const isCompanyPages = window.location.href.includes('/sign-up/company/');
  const isUserPages = window.location.href.includes('/sign-up/user/');
  if (needsCompany && !isCompanyPages) {
    const companyRoute = matchedCompany
      ? JoinProjectRoutes.SIGNUP_CONFIRM_COMPANY
      : JoinProjectRoutes.SIGNUP_CREATE_COMPANY;
    return generateSignupPath(companyRoute, projectId);
  }
  // Second roadblock: User
  if (!needsCompany && !isUserPages && !didSetup) {
    return generateSignupPath(JoinProjectRoutes.SIGNUP_SETUP_USER, projectId);
  }
  // Or, no need to be in signup pages
  if (!needsCompany && (isUserPages || isCompanyPages) && didSetup) {
    return projectId
      ? generateSharedPath(JoinRoutes.PROJECT, { projectId })
      : generateSharedPath(RouteKeys.SEARCH_PROJECTS, {});
  }
  return null;
};

export const requiresWaitForSignupRedirect = (
  companyUser: CompanyUser | undefined | null,
  skipSignupFlow: boolean
) => {
  if (skipSignupFlow) return { wait: false };
  if (!companyUser) return { wait: true };
  const url = getSignupURL(companyUser);
  return { wait: !!url, url };
};
