import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../theme/komodo-mui-theme';

export default (theme: KomodoTheme) =>
  createStyles({
    link: {
      ...theme.typography.link,
      paddingLeft: 0,
      paddingRight: 0,
    },
  });
