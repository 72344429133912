import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../../theme/komodo-mui-theme';

const ItemsListItemInfoStyles = (theme: KomodoTheme) =>
  createStyles({
    captionText: {
      fontSize: theme.typography.caption.fontSize,
      lineHeight: theme.typography.caption.lineHeight,
      marginLeft: theme.spacing.generic[0],
      marginRight: theme.spacing.generic[3],
    },
    disabled: {
      color: theme.palette.disabledGrey,
      textDecorationColor: theme.palette.disabledGrey,
      fontFamily: theme.typography.fontFamily,
    },
    hyphens: {
      flexShrink: 1,
      textAlign: 'left',
      wordBreak: 'break-word',
    },
    inlineRow: {
      alignItems: 'center',
      display: 'flex',
      flex: '0 1 auto',
      flexShrink: 0,
    },
    itemChips: {
      alignItems: 'center',
      display: 'inline-flex',
      flexWrap: 'wrap',
      width: '100%',
    },
    linkTitle: {
      ...theme.linkFocus,
      background: 'transparent',
      border: '0',
      borderRadius: theme.border.radius,
      cursor: 'pointer',
      textDecoration: 'underline',
      paddingLeft: '0',
    },
    noPrint: {
      '@media print': {
        display: 'none !important',
      },
    },
    rowContainer: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
      flexShrink: 1,
      flexWrap: 'wrap',
    },
    flexEnd: {
      justifyContent: 'flex-end',
    },
  });

export default ItemsListItemInfoStyles;
