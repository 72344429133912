import { useState } from 'react';

import { ALL_COLLABORATORS } from '../../../constants';
import { ProgramCollaboratorFieldsFragment } from '../../../generated/graphql';
import { useSearchProgramCollaborators } from '../../../hooks/useSearchCollaborators';
import { useCurrentUser } from '../../contexts/current-user';
import UsersFilter from '../../dragon-scales/Share/ShareDialog/UsersFilter';
import useMemoWrapper from '../../useMemoWrapper';

import ProgramUsersTable from './ProgramUsersTable';
import { ProgramUsersSortKey } from './types';
import { filterCollaboratorsByKey } from './utils';

export type Props = {
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  loading: boolean;
  programCollaborators: ProgramCollaboratorFieldsFragment[];
  projectCount: number;
  selectedUserIDs: UUID[];
  setSelectedUserIDs: (userIDs: UUID[]) => void;
};

export default function ProgamUsersTableFilter(props: Props) {
  const currentUser = useCurrentUser();

  const companies = props.programCollaborators?.map((c) => c.company) ?? [];
  const roles = [
    ...new Set(props.programCollaborators?.map((c) => c.roles.map((r) => r.roleName)).flat()),
  ];

  const programCollaborators = props.programCollaborators ?? [];

  const [search, setSearch] = useState('');
  const [role, setRole] = useState('');
  const [company, setCompany] = useState('');

  let filteredUsers = useMemoWrapper(
    (collaborators: ProgramCollaboratorFieldsFragment[], role: string) =>
      collaborators.filter(
        (c) => !role || role === ALL_COLLABORATORS || c.roles.find((r) => r.roleName === role)
      ),
    programCollaborators,
    role
  );
  filteredUsers = useMemoWrapper(
    filterCollaboratorsByKey<ProgramCollaboratorFieldsFragment>,
    filteredUsers,
    company,
    ProgramUsersSortKey.COMPANY
  );
  filteredUsers = useSearchProgramCollaborators(filteredUsers, search);

  const header = (
    <UsersFilter
      collaboratorsShown={filteredUsers.length}
      collaboratorsTotal={programCollaborators.length}
      companies={companies}
      onCompanyChange={(company) => {
        setCompany(company);
      }}
      onRoleChange={(role) => {
        setRole(role);
      }}
      roles={roles}
      search={search}
      setSearch={(term) => {
        setSearch(term);
      }}
    />
  );

  return (
    <>
      {header}
      <ProgramUsersTable
        disabledIds={[currentUser.id]}
        entries={filteredUsers}
        loading={props.loading}
        onSelectAll={() => props.setSelectedUserIDs(programCollaborators.map((m) => m.user.id))}
        onToggleEntry={(value) => {
          if (!props.selectedUserIDs.includes(value.user.id))
            props.setSelectedUserIDs([...props.selectedUserIDs, value.user.id]);
          else
            props.setSelectedUserIDs([
              ...props.selectedUserIDs.filter((id) => id !== value.user.id),
            ]);
        }}
        onUnselectAll={() => props.setSelectedUserIDs([currentUser.id])}
        projectCount={props.projectCount}
        searchTerm={search}
        selectedIds={props.selectedUserIDs}
      />
    </>
  );
}
