import { FC } from 'react';

import { pluralizeCountString } from '../../../utilities/string';
import CategoryIcon from '../../Icons/Category';

import { generateCategoryFiltersMenuItems } from './CategoryMenuItems';
import ReferencesMenu from './ReferencesMenu';

type Props = {
  categorizations: Categorization[];
  isFixedMarkup: boolean;
  selectedCategorizationFilters: Category[];
  setSelectedCategorizationFilters: (cats: Category[]) => void;
};

const InputsSelectReferenceCategories: FC<Props> = ({
  categorizations,
  isFixedMarkup,
  selectedCategorizationFilters,
  setSelectedCategorizationFilters,
}) => {
  const filterIsSelected = (category: Category) =>
    selectedCategorizationFilters.some(
      (filter) =>
        filter.id === category.id &&
        (filter.categorization && filter.categorization.id) ===
          (category.categorization && category.categorization.id)
    );

  const toggleFilter = (category: Category) => {
    if (filterIsSelected(category)) {
      // filters out the already selected category, effectively removing it
      setSelectedCategorizationFilters(
        selectedCategorizationFilters.filter(
          (f) =>
            f.id !== category.id ||
            (f.categorization && f.categorization.id) !==
              (category.categorization && category.categorization.id)
        )
      );
    } else {
      setSelectedCategorizationFilters([...selectedCategorizationFilters, category]);
    }
  };

  // VARIABLES
  const hasFilters = selectedCategorizationFilters.length > 0;

  return (
    <ReferencesMenu
      icon={<CategoryIcon />}
      subMenuItems={generateCategoryFiltersMenuItems(
        categorizations,
        selectedCategorizationFilters,
        setSelectedCategorizationFilters,
        toggleFilter,
        isFixedMarkup,
        hasFilters
      )}
      subTitle={`Applied to ${
        hasFilters
          ? pluralizeCountString('category', selectedCategorizationFilters.length)
          : 'all categories'
      }`}
      title="Categories"
    />
  );
};

export default InputsSelectReferenceCategories;
