import { ScaleLinear, ScalePoint, ScaleTime } from 'd3-scale';
import { ReactNode } from 'react';

import { Tooltip } from '../../scales';

type Data = { date: Date; value: number };

const DEFAULT_HOVER_SIZE = 1;
const DEFAULT_OFFSET = 0;

export default function TimelineBottomTooltip(props: {
  data: Data;
  isOpen?: boolean;
  offset?: number;
  onClick?: () => void;
  onHover?: () => void;
  onLeave?: () => void;
  pointSize?: number;
  content: ReactNode;
  x: ScaleTime<number, number> | ScalePoint<Date>;
  y: ScaleLinear<number, number>;
}) {
  if (!props.data) return null;

  const onOpen = () => {
    props.onHover?.();
  };

  const onClose = () => {
    props.onLeave?.();
  };

  const onClick = () => {
    props.onClick?.();
  };

  const hoverAttrs = {
    cx: props.x(props.data.date),
    cy: props.y(props.data.value),
    opacity: 0,
    r: DEFAULT_HOVER_SIZE,
  };

  return (
    <>
      <Tooltip
        content={props.content}
        isOpen={props.isOpen}
        offset={props.offset ?? DEFAULT_OFFSET}
        onClose={onClose}
        onOpen={onOpen}
        placement="bottom"
      >
        <circle {...hoverAttrs} onClick={onClick} />
      </Tooltip>
    </>
  );
}
