import * as React from 'react';

import { withStyles } from '@material-ui/core/styles';
import Tooltip, { TooltipProps } from '@material-ui/core/Tooltip';

import { tooltipStyles } from './ErrorTooltip';

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
const WarningTooltip: React.ComponentType<TooltipProps> = withStyles((theme: any) => ({
  tooltip: {
    ...tooltipStyles(theme),
    borderColor: theme.palette.noticeRed,
    color: theme.palette.joinPrimary,
  },
}))(Tooltip);

export default WarningTooltip;
