import { FC } from 'react';

import { withStyles } from '../../../../theme/komodo-mui-theme';

import styles from './ItemsListBulkEditingPanelHeaderStyles';

type ItemsListClearBulkEditPanelHeaderProps = {
  classes: Classes<typeof styles>;
  clearAllSelectedFields: () => void;
};

const ItemsListClearBulkEditPanelHeader: FC<ItemsListClearBulkEditPanelHeaderProps> = ({
  classes,
  clearAllSelectedFields,
}) => (
  <div className={classes.header}>
    <div className={classes.filterCount}> Edit Selected Items </div>
    {classes && (
      <div
        className={classes.clearEditingFields}
        onClick={clearAllSelectedFields}
        onKeyPress={clearAllSelectedFields}
        role="button"
        tabIndex={0}
      >
        clear
      </div>
    )}
  </div>
);

export default withStyles(styles)(ItemsListClearBulkEditPanelHeader);
