import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../theme/komodo-mui-theme';

const styles = (theme: KomodoTheme) =>
  createStyles({
    dialogPaper: {
      width: 'auto',
    },
    root: {
      position: 'relative',
    },
    promptText: {
      maxWidth: '452px',
      paddingBottom: '16px',
    },
    milestoneStatusSelect: {
      display: 'flex',
      flexDirection: 'row',
      marginTop: '8px',
      marginBottom: '18px',
      flexWrap: 'nowrap',
      alignItems: 'center',
    },
    modalHeader: {
      paddingLeft: '24px',
      paddingRight: '24px',
      paddingTop: '16px',
      paddingBottom: '16px',
    },
    modalBody: {
      paddingTop: '16px',
      paddingLeft: '24px',
      paddingRight: '24px',
      paddingBottom: '8px',
    },
    itemName: {
      fontWeight: 'bold',
      maxWidth: '300px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      verticalAlign: 'middle',
    },
    costImpact: {
      ...theme.typography.number,
    },
    withStatus: {
      marginLeft: '16px',
      marginRight: '16px',
      whiteSpace: 'nowrap',
    },
  });

export default styles;
