import {
  FilesSortBy,
  LoadSourceFolderContentsQuery,
  LoadSourceFolderContentsQueryVariables,
  LoadSourceFolderRootQuery,
  LoadSourceFolderRootQueryVariables,
  LoadSourceHubProjectsQuery,
  LoadSourceHubProjectsQueryVariables,
  LoadSourceHubRootQuery,
  LoadSourceHubRootQueryVariables,
  LoadSourceProjectFoldersQuery,
  LoadSourceProjectFoldersQueryVariables,
  SourceSystem,
} from '../../../../generated/graphql';
import { useQuery } from '../../../../hooks/useMountAwareQuery';

import {
  loadSourceFolderContents,
  loadSourceFolderRoot,
  loadSourceHubProjects,
  loadSourceHubRoot,
  loadSourceProjectFolders,
} from './queries';

export const useLoadSourceHubRootQuery = (
  sourceSystem: SourceSystem | undefined,
  sourceID: UUID | undefined
) =>
  useQuery<LoadSourceHubRootQuery, LoadSourceHubRootQueryVariables>(loadSourceHubRoot, {
    variables: {
      sourceSystem,
      sourceID,
    },
    skip: !sourceSystem || !sourceID,
  });

export const useLoadSourceFolderRootQuery = (
  sourceSystem: SourceSystem,
  sourceID: UUID,
  sortBy: FilesSortBy
) =>
  useQuery<LoadSourceFolderRootQuery, LoadSourceFolderRootQueryVariables>(loadSourceFolderRoot, {
    variables: {
      sourceSystem,
      sourceID,
      sortBy,
    },
    skip: !sourceSystem || !sourceID,
  });

export const useLoadSourceHubProjects = (
  sourceSystem: SourceSystem | undefined,
  sourceID: UUID | undefined,
  hubID: UUID | undefined
) =>
  useQuery<LoadSourceHubProjectsQuery, LoadSourceHubProjectsQueryVariables>(loadSourceHubProjects, {
    variables: {
      sourceSystem,
      sourceID,
      hubID,
    },
    skip: !sourceSystem || !sourceID || !hubID,
  });

export const useLoadSourceProjectFolders = (
  sourceSystem: SourceSystem,
  sourceID: UUID,
  hubID: string,
  projectID: string
) =>
  useQuery<LoadSourceProjectFoldersQuery, LoadSourceProjectFoldersQueryVariables>(
    loadSourceProjectFolders,
    {
      variables: {
        sourceSystem,
        sourceID,
        hubID,
        projectID,
      },
      skip: !sourceSystem || !sourceID || !projectID,
    }
  );

export const useLoadSourceFolderContents = (
  sourceSystem: SourceSystem,
  sourceID: UUID,
  sortBy: FilesSortBy,
  hubID?: string,
  folderID?: string,
  sourceProjectID?: string
) =>
  useQuery<LoadSourceFolderContentsQuery, LoadSourceFolderContentsQueryVariables>(
    loadSourceFolderContents,
    {
      variables: {
        sourceSystem,
        sourceID,
        sortBy,
        folderID,
        projectID: sourceProjectID,
        hubID,
      },
      skip: !sourceSystem || !sourceID,
    }
  );
