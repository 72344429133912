import { FC, useState } from 'react';

import { INTEGRATIONS } from '../../../constants';
import useItemsIntegrationObjectsCountsQuery, {
  getIntegrationObjectCountForItemID,
} from '../../../hooks/useItemsIntegrationObjectsCountsQuery';
import { constantCountLabel } from '../../../utilities/string';
import { useUserSourcesQuery } from '../../DocumentMagic/hooks';

import IntegrationsDataWrapper from './Integrations/IntegrationsDataWrapper';
import IntegrationsHeader from './Integrations/IntegrationsHeader';
import { ItemInfo } from './Integrations/IntegrationsUtils';
import ItemDetailsCollapse from './ItemDetailsCollapse';

type IntegrationsCollapseProps = {
  canCreateChangeEvent: boolean;
  canDeleteItemIntegration: boolean;
  itemInfo: ItemInfo;
};

const IntegrationsCollapse: FC<IntegrationsCollapseProps> = ({
  canCreateChangeEvent,
  canDeleteItemIntegration,
  itemInfo,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const userSources = useUserSourcesQuery().data?.userSources;

  const countData = useItemsIntegrationObjectsCountsQuery({ itemIDs: [itemInfo.id] });
  const count = getIntegrationObjectCountForItemID(itemInfo.id, countData.data);

  const headerContent = (
    <IntegrationsHeader name={constantCountLabel(INTEGRATIONS, count)} userSources={userSources} />
  );

  return (
    <ItemDetailsCollapse
      canExpand
      headerContent={headerContent}
      isExpanded={isExpanded}
      setIsExpanded={setIsExpanded}
    >
      {userSources && (
        <IntegrationsDataWrapper
          canCreateChangeEvent={canCreateChangeEvent}
          canDeleteItemIntegration={canDeleteItemIntegration}
          hasItemIntegrationObjects={count > 0}
          itemInfo={itemInfo}
          userSources={userSources}
        />
      )}
    </ItemDetailsCollapse>
  );
};

export default IntegrationsCollapse;
