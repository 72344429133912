import { ScaleLinear, ScalePoint, ScaleTime } from 'd3-scale';

type TimeDate = {
  date: Date;
};

type Value = {
  // The key string of your choice
  // Preferably API driven
  [key in string]: number;
};

// Default key fields:
const VALUE_KEY = 'value';

const DEFAULT_SIZE = 4;
const DEFAULT_OPACITY = 1;

export default function TimelineCircle<V extends Value>(props: {
  data: V & TimeDate;
  field?: string;
  fill: string;
  x: ScaleTime<number, number> | ScalePoint<Date>;
  y: ScaleLinear<number, number>;
  size?: number;
  opacity?: number;
}) {
  if (!props.data) return null;

  const attrs = {
    className: props.fill,
    r: props.size ?? DEFAULT_SIZE,
    cx: props.x(props.data.date),
    cy: props.y(props.data[props.field ?? VALUE_KEY]),
    style: {
      opacity: props.opacity ?? DEFAULT_OPACITY,
      fill: props.fill,
    },
  };

  return <circle {...attrs} />;
}
