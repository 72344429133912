import {
  ListCustomCategorizationsQuery,
  ListCustomCategorizationsQueryVariables,
} from '../generated/graphql';

import { listCustomCategorizations } from './queries';
import { QueryHookOptions, useQuery } from './useMountAwareQuery';
import { MountPolicy } from './usePolicyOnFirstMount';

export function useCustomCategorizations(
  options: QueryHookOptions<
    ListCustomCategorizationsQuery,
    ListCustomCategorizationsQueryVariables
  >,
  mountPolicy?: MountPolicy
) {
  return useQuery<ListCustomCategorizationsQuery, ListCustomCategorizationsQueryVariables>(
    listCustomCategorizations,
    options,
    mountPolicy
  );
}
