import {
  LoadSourceHubProjectsQuery,
  LoadSourceHubRootQuery,
  SourceSystem,
} from '../../../../generated/graphql';
import { Select } from '../../../scales';

import { getSourceSystemString } from './IntegrationsUtils';

export type SourceHub = Pick<
  LoadSourceHubRootQuery['sourceHubRoot']['hubs'][number],
  'id' | 'name'
>;

export type SourceProject = Pick<
  LoadSourceHubProjectsQuery['sourceHubProjects']['projects'][number],
  'id' | 'name'
>;

type Props = {
  sourceHubs?: SourceHub[];
  sourceProjects?: SourceProject[];
  hubID?: string;
  projectID?: string;
  setCompanyID: (value: string) => void;
  setProjectID: (value: string) => void;
  sourceSystem: SourceSystem;
};

export default function IntegrationsSourceCompanyProjectSelect(props: Props) {
  const selectEntries = props.sourceHubs?.map(({ name, id }) => ({
    id,
    label: name,
  }));

  const projectSelectEntries = props.sourceProjects?.map(({ name, id }) => ({
    id,
    label: name,
  }));

  const sourceSystemString = getSourceSystemString(props.sourceSystem);

  return (
    <div className="flex w-full items-center gap-2">
      <Select
        entries={selectEntries}
        label={`${sourceSystemString} Company`}
        onChange={(value) => props.setCompanyID(value)}
        placeholder="Select a company..."
        value={props.hubID}
      />
      <Select
        entries={projectSelectEntries}
        isDisabled={!projectSelectEntries}
        label={`${sourceSystemString} Project`}
        onChange={(value) => props.setProjectID(value)}
        placeholder="Select a project..."
        value={props.projectID}
      />
    </div>
  );
}
