import { useMutation } from '@apollo/client';

import { REFETCH_ITEM_ATTACHMENTS } from '../../../../api/refetchSets';
import {
  DetachFromItemMutation,
  DetachFromItemMutationVariables,
  SourceSystem,
} from '../../../../generated/graphql';
import { detachFromItemMutation } from '../queries';

import { formatDetachAssetInputs } from './mutationUtils';

export function useDetachFromItem() {
  const [detachFromItem, mutationResult] = useMutation<
    DetachFromItemMutation,
    DetachFromItemMutationVariables
  >(detachFromItemMutation);

  const submitFunc = (
    projectID: UUID,
    itemID: UUID,
    assetID: string,
    sourceSystem?: SourceSystem,
    onSuccess?: () => void,
    onFailure?: () => void
  ) => {
    const { joinAssetID, remoteAssetID } = formatDetachAssetInputs(assetID, sourceSystem);
    return detachFromItem({
      variables: { projectID, itemID, assetID: joinAssetID, remoteAssetID },
      // Refetch queries while on old apollo version
      refetchQueries: REFETCH_ITEM_ATTACHMENTS,
    })
      .then(() => {
        if (onSuccess) onSuccess();
      })
      .catch(() => {
        if (onFailure) onFailure();
      });
  };

  return [submitFunc, mutationResult] as const;
}
