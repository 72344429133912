import { useState } from 'react';

import { assetCacheVar } from '../../api/apollo/reactiveVars';
import { fetchBlobUrl } from '../../api/assets';
import { GetProjectThumbnailQuery } from '../../generated/graphql';

import useGetProjectThumbnailQuery from './useGetProjectThumbnailQuery';

export const getProjectBlobCacheKey = (projectID: UUID | null | undefined) =>
  `ProjectBlob:${projectID}`;

export default function useProjectBlobUrlQuery(projectID: UUID | null | undefined) {
  const blobUrlCached = assetCacheVar().get(getProjectBlobCacheKey(projectID));
  const [url, setUrl] = useState<string | undefined>(blobUrlCached);
  const onCompleted = async (data: GetProjectThumbnailQuery) => {
    const blobUrl = await fetchBlobUrl(data.projectThumbnail);
    setUrl(blobUrl ?? undefined);
    if (blobUrl) assetCacheVar().set(getProjectBlobCacheKey(projectID), blobUrl);
  };
  const { data, loading, refetch } = useGetProjectThumbnailQuery(projectID, onCompleted);
  const dataWithBlob = {
    projectThumbnail: data?.projectThumbnail
      ? { ...data?.projectThumbnail, blobUrl: url }
      : undefined,
  };
  const refetchWithBlob = async () => {
    const queryResult = await refetch();
    onCompleted(queryResult.data);
  };
  return {
    data: dataWithBlob,
    loading,
    refetch: refetchWithBlob,
  };
}
