import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';

const styles = (theme: KomodoTheme) =>
  createStyles({
    titleContainer: {
      flexGrow: 1,
      marginRight: 8,
    },
    editingLabel: {
      ...theme.typography.body1,
    },
  });

export default styles;
