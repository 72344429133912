import { FC } from 'react';

import { CostReportColumnType } from '../../../generated/graphql';
import { withStyles } from '../../../theme/komodo-mui-theme';
import JoinSelect, { getEntryObj } from '../JoinSelect/JoinSelect';

import styles from './TypeCostSelectStyles';

type TypeSelectCostEntry = {
  id: CostReportColumnType;
  name: string;
  nameOption?: string;
};

type TypeSelectCostProps = {
  classes: Classes<typeof styles>;
  entries: TypeSelectCostEntry[];
  onChange: (v: CostReportColumnType) => void;
  selected?: string;
};

const TypeCostSelect: FC<TypeSelectCostProps> = ({ classes, entries, selected, onChange }) => (
  <JoinSelect
    classNameRightText={classes.nameOption}
    cySelect="select-milestoneTypeSelect"
    entries={entries.map(getEntryObj)}
    hidePrint={false}
    onChange={(v: string | null) => onChange(v as CostReportColumnType)}
    value={selected}
  />
);

export default withStyles(styles)(TypeCostSelect);
