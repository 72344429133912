import { commonCategorizationFields, gql } from '../../api/graphqlFragments';

export const setDisabledProjectCategorizationMutation = gql`
  mutation setDisabledProjectCategorization(
    $projectID: UUID!
    $categorizationID: UUID!
    $disabled: Boolean!
  ) {
    setDisabledProjectCategorization(
      projectID: $projectID
      categorizationID: $categorizationID
      disabled: $disabled
    ) {
      projectID
      categorization {
        ...commonCategorizationFields
      }
      disabled
    }
  }
  ${commonCategorizationFields}
`;

export const reorderCategorizationMutation = gql`
  mutation reorderCategorization($projectID: UUID!, $categorizationID: UUID!, $ordering: Int!) {
    reorderCategorization(
      projectID: $projectID
      categorizationID: $categorizationID
      ordering: $ordering
    )
  }
`;
