import { isUUID } from 'validator';

import useMemoWrapper from '../../../useMemoWrapper';
import useAssetDisplayUrlQuery from '../../hooks/useAssetDisplayUrlQuery';
import AssetsCard from '../AssetsCard/AssetsCard';

import useItemBookmarksQuery from './useItemBookmarksQuery';
import { filterAssetBookmarks } from './utils';

type Props = {
  asset: Asset;
  canDelete?: boolean;
  detachAttachment?: (assetID: UUID) => void;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  disabled?: boolean;
  downloadAsset?: (location: string, name: string, id: string) => void;
  itemID: UUID;
  isPrint: boolean;
  openViewer: (i: number, a: Asset) => void;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  showMenu: boolean;
};

/**
 * ModelCardWithBookmarks is used to render model card with its bookmarks.
 * A model asset can have several different camera perspectives saved in bookmarks.
 * We display the model card and each of its bookmarks as separate cards.
 */
export default function ModelCardWithBookmarks({
  asset,
  canDelete = false,
  detachAttachment,
  disabled = false,
  downloadAsset,
  itemID,
  isPrint,
  openViewer,
  showMenu,
}: Props) {
  const { url: displayUrl } = useAssetDisplayUrlQuery(asset.id);
  const itemBookmarksQueryResult = useItemBookmarksQuery(itemID);
  const bookmarks = itemBookmarksQueryResult.data?.itemBookmarks ?? [];

  const getAssetCard = (asset: Asset) => {
    const assetUploadComplete = isUUID(asset.id || ''); // while upload not yet complete, asset id is the file name
    const onClick = () => {
      openViewer(-1, asset);
    };

    return (
      <AssetsCard
        key={`model-card-${asset.id}`}
        asset={asset}
        canDelete={canDelete && assetUploadComplete}
        detachAttachment={detachAttachment}
        disabled={disabled || isPrint}
        displayUrl={displayUrl}
        downloadAsset={downloadAsset}
        onClick={onClick}
        showMenu={showMenu && !disabled}
      />
    );
  };

  const getBookmarkCard = (bookmark: Bookmark, asset: Asset, index: number) => {
    return (
      <AssetsCard
        key={`model-bookmark-${asset.id}-${bookmark.id}`}
        asset={asset}
        bookmark={bookmark}
        canDelete={canDelete}
        disabled={disabled || isPrint}
        displayUrl={bookmark.thumbnail}
        onClick={() => openViewer(index, asset)}
        showMenu={showMenu}
      />
    );
  };

  const assetCard = getAssetCard(asset);
  const assetBookmarks = useMemoWrapper(filterAssetBookmarks, asset, bookmarks);
  const bookmarkCards = assetBookmarks.map((bookmark, index) =>
    getBookmarkCard(bookmark, asset, index)
  );

  if (itemBookmarksQueryResult.loading) return null;

  return (
    <>
      {assetCard}
      {bookmarkCards}
    </>
  );
}
