import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../../theme/komodo-mui-theme';
import { DIALOG_PADDING } from '../../../Dialogs/DialogsStyles';

export default (theme: KomodoTheme) =>
  createStyles({
    alignLeft: {
      justifyContent: 'flex-start',
    },
    alignCloseButton: {
      position: 'absolute',
      top: 12,
      right: 12,
      margin: 8,
      width: 32,
      height: 32,
    },
    avatar: {
      '&:focus': {
        background: theme.palette.backgroundGrey,
      },
      background: 'none',
      color: theme.palette.borderBlue,
      cursor: 'default',
      height: 32,
      width: 32,
    },
    arrow: {
      width: 30,
      height: 30,
      marginTop: 4,
    },
    chipHidden: {
      opacity: 0,
      padding: 2,
    },
    chipPadding: {
      padding: 2,
    },
    containerBuiltIn: {
      border: theme.border.line,
      overflowY: 'auto',
      width: 490,
    },
    containerDisabled: {
      color: theme.palette.disabledGrey,
      border: theme.border.line,
      padding: 8,
      overflowY: 'auto',
    },
    controlsButton: {
      cursor: 'pointer',
      color: theme.palette.link,
    },
    disablePadding: {
      paddingBottom: 8,
    },
    chip: {
      '&:focus': {
        background: theme.palette.backgroundGrey,
      },
      background: theme.palette.backgroundGrey,
      border: theme.border.line,
      borderRadius: theme.border.radius,
      flexGrow: 1,
      height: 38,
    },
    chipLabel: {
      color: theme.palette.joinPrimary,
      flex: 1,
    },
    checkIcon: {
      color: theme.palette.accepted,
      fontSize: 20,
      marginRight: 2,
    },
    errorIcon: {
      color: theme.palette.red,
      fontSize: 18,
      marginRight: 2,
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      paddingTop: 10,
    },
    headJoin: {
      ...theme.typography.caption,
      color: theme.palette.chartBlack,
      fontWeight: 500,
      width: 222,
    },
    headOuter: {
      ...theme.typography.caption,
      color: theme.palette.chartBlack,
      fontWeight: 500,
    },
    matchCountText: {
      font: theme.fontFamily,
      fontSize: 12,
      marginRight: 10,
    },
    unmatchCountText: {
      font: theme.fontFamily,
      color: theme.palette.red,
      fontSize: 12,
      marginRight: 10,
    },
    matchCountContainer: {
      display: 'flex',
      alignItems: 'center',
      paddingTop: 5,
    },
    missingBuiltin: {
      color: theme.palette.chartBlack,
      fontSize: 14,
      fontWeight: 400,
    },
    hidden: {
      visibility: 'hidden',
    },
    horizontal: {
      display: 'flex',
      gap: '6px',
      alignItems: 'start',
    },
    innerContainer: {
      alignContent: 'center',
      background: 'none',
      paddingLeft: 3,
    },
    verified: {
      marginLeft: 'auto',
    },
    center: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-evenly',
      marginBottom: 20,
    },
    dialogContent: {
      padding: DIALOG_PADDING,
      borderBottom: theme.border.line,
    },
    disableContent: {
      opacity: '40%',
      pointerEvents: 'none',
    },
    paddingBottom: {
      paddingBottom: 20,
    },
    ul: {
      listStyle: 'disc',
      margin: '8px 0 8px 16px',
    },
  });
