import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../../theme/komodo-mui-theme';

const ChartsPieChartHintStyles = (theme: KomodoTheme) =>
  createStyles({
    title: {
      ...theme.typography.h3TableHeader,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
  });

export default ChartsPieChartHintStyles;
