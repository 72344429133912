import {
  ArrowRightAlt as Arrow,
  CloudQueueOutlined as Cloud,
  Gesture,
  CropSquare as Square,
  TextFormat as Text,
} from '@material-ui/icons';

export const markupModes = [
  { name: 'Cloud Mode', icon: <Cloud />, command: 'cloud' },
  { name: 'Add Text', icon: <Text />, command: 'label' },
  { name: 'Arrow', icon: <Arrow />, command: 'arrow' },
  { name: 'Rectangle', icon: <Square />, command: 'rectangle' },
  { name: 'Freehand', icon: <Gesture />, command: 'freehand' },
];
