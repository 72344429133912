import { useRef } from 'react';
import {
  CalendarProps,
  DateValue,
  useCalendar,
  useCalendarCell,
  useCalendarGrid,
  useLocale,
} from 'react-aria';
import { CalendarState, useCalendarState } from 'react-stately';

import { CalendarDate, createCalendar, getWeeksInMonth } from '@internationalized/date';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons';

import BabyButton from '../BabyButton/BabyButton';

export default function Calendar(props: CalendarProps<DateValue>) {
  const { locale } = useLocale();
  const state = useCalendarState({
    ...props,
    createCalendar,
    locale,
  });
  const ref = useRef(null);
  const { calendarProps, prevButtonProps, nextButtonProps, title } = useCalendar(props, state);

  return (
    <div {...calendarProps} ref={ref} className="flex flex-col gap-2 p-2">
      <div className="flex items-center justify-between px-2">
        <strong className="cursor-default type-body2">{title}</strong>
        <div className="flex">
          <BabyButton
            aria-label="previous month"
            icon={<KeyboardArrowLeft />}
            isDisabled={prevButtonProps.isDisabled}
            onClick={state.focusPreviousPage}
          />
          <BabyButton
            aria-label="next month"
            icon={<KeyboardArrowRight />}
            isDisabled={nextButtonProps.isDisabled}
            onClick={state.focusNextPage}
          />
        </div>
      </div>
      <CalendarGrid state={state} />
    </div>
  );
}

function CalendarGrid({ state }: { state: CalendarState }) {
  const { locale } = useLocale();
  const { gridProps, headerProps, weekDays } = useCalendarGrid({ weekdayStyle: 'short' }, state);
  const weeks = [...new Array(getWeeksInMonth(state.visibleRange.start, locale)).keys()].map(
    (weekIndex) => state.getDatesInWeek(weekIndex)
  );

  return (
    <table {...gridProps} cellPadding="0" className="flex-1">
      <thead {...headerProps}>
        <tr>
          {weekDays.map((day) => (
            <th key={day} className="h-9 cursor-default text-center text-type-muted type-body3">
              {day[0]}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {weeks.map((week) => (
          <tr key={week[0]?.toString()}>
            {week.map((date) =>
              date ? (
                <CalendarCell key={date.toString()} date={date} state={state} />
              ) : (
                <td key="blank-cell" />
              )
            )}
          </tr>
        ))}
      </tbody>
    </table>
  );
}

function CalendarCell(props: { state: CalendarState; date: CalendarDate }) {
  const ref = useRef(null);
  const {
    buttonProps,
    cellProps,
    formattedDate,
    isDisabled,
    isFocused,
    isOutsideVisibleRange,
    isSelected,
  } = useCalendarCell({ date: props.date }, props.state, ref);

  return (
    <td {...cellProps}>
      <div
        {...buttonProps}
        ref={ref}
        className={[
          'flex h-9 w-9 items-center justify-center rounded-full outline-none type-body1',
          !isDisabled && !isSelected ? 'hover:bg-selection-hover' : '',
          isDisabled ? 'cursor-not-allowed text-type-inactive' : 'cursor-pointer text-type-primary',
          isFocused ? 'bg-selection-hover' : '',
          isOutsideVisibleRange ? 'hidden' : '',
          isSelected ? 'bg-selection-selected' : '',
        ].join(' ')}
      >
        {formattedDate}
      </div>
    </td>
  );
}
