import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../theme/komodo-mui-theme';

export default (theme: KomodoTheme) =>
  createStyles({
    button: {
      whiteSpace: 'nowrap',
      padding: 4,
      paddingLeft: 8,
      height: 40,
      marginLeft: 8,
      flex: '0 0 auto',
      minWidth: 36,
      '@media print': {
        display: 'none',
      },
    },
    buttonHover: {
      '&:hover': {
        '&:before': {
          cursor: 'pointer',
          borderRadius: 50,
          content: '""',
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          backgroundImage: `linear-gradient(${theme.palette.hoverSemitransparentBackground} 100%, white 100%)`,
        },
      },
    },
    buttonHoverYellow: {
      '&:hover': {
        '&:before': {
          cursor: 'pointer',
          borderRadius: 50,
          content: '""',
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          backgroundColor: theme.palette.pending,
          opacity: 0.3,
        },
      },
    },
    buttonPrimaryFocus: {
      '&:focus-visible': {
        background: theme.palette.joinPrimary,
      },
    },
    buttonPrimaryFocusYellow: {
      '&:focus-visible': {
        background: theme.palette.joinPrimary,
      },
      '&:hover': {
        '&:before': {
          cursor: 'pointer',
          borderRadius: 50,
          content: '""',
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          backgroundColor: theme.palette.joinPrimary,
          opacity: 0.3,
        },
      },
    },
    buttonReverse: {
      paddingRight: 8,
      marginRight: 8,
    },
    buttonText: {
      paddingLeft: 4,
      paddingRight: 12,
    },
    buttonTextReverse: {
      paddingRight: 4,
      paddingLeft: 12,
    },
    content: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    reverseContent: {
      flexDirection: 'row-reverse',
    },
    titlebarButton: {
      [theme.breakpoints.down('xs')]: {
        paddingLeft: 4,
      },
      height: 36,
    },
    titlebarText: {
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
  });
