import { gql } from '../../../../api/graphqlFragments';
import { itemsTimelineItemFields } from '../../../Timeline/hooks/queries';

// Combine queries
export const itemsTimelineWithEventsQuery = gql`
  query itemsTimelineWithEvents(
    # events
    $projectID: UUID!
    $milestoneID: UUID!
    $costMode: CostMode!
    $viewFilter: ViewFilter!
    $columnInputs: [CostReportColumnInput!]!
    $quantityMagnitude: Numeric
    # items
    $iMilestoneID: UUID
    $iProjectID: UUID!
    $iCostMode: CostMode!
  ) {
    itemsTimeline(
      parameters: { milestoneID: $iMilestoneID, projectID: $iProjectID, costMode: $iCostMode }
    ) {
      items {
        ...itemsTimelineItemFields
      }
    }
    costTrendlineEvents(
      projectID: $projectID
      milestoneID: $milestoneID
      costMode: $costMode
      viewFilter: $viewFilter
      columnInputs: $columnInputs
      quantityMagnitude: $quantityMagnitude
    ) {
      events {
        timestamp
        eventType
        item {
          id
        }
        eventContent {
          oldOptionStatus
          newOptionStatus
          oldStatus
          newStatus
        }
      }
    }
  }
  ${itemsTimelineItemFields}
`;
