import { Navigate } from 'react-router-dom';

import { PROJECT_COMPS } from '../../features';
import { PermissionResource } from '../../generated/graphql';
import { useHasFeature } from '../../hooks/useHasFeature';
import { FORECASTING } from '../../moduleEntitlementFlagsList';
import HasModuleEntitlement from '../HasModuleEntitlement';
import HasPermission from '../HasPermission';

import ForecastingTab from './ForecastingTab';

export default function ForecastingRoute() {
  const override = useHasFeature(PROJECT_COMPS);
  if (override) return <ForecastingTab />;

  return (
    <HasModuleEntitlement fallback={<Navigate replace to="/404" />} flag={FORECASTING}>
      <HasPermission
        fallback={<Navigate replace to="/404" />}
        permission={PermissionResource.PROJECT_COMPS_ACCESS}
      >
        <ForecastingTab />
      </HasPermission>
    </HasModuleEntitlement>
  );
}
