import { QueryHookOptions, useQuery } from '@apollo/client';
import {
  ProgramCategorizedTotalsQuery,
  ProgramCategorizedTotalsQueryVariables,
} from '../../../generated/graphql';
import { programCategorizedTotalsQuery } from '../queries';

export const useProgramCategorizedTotalsQuery = (
  programID: UUID,
  includedProjectIDs: UUID[],
  categorizationName?: string,
  options?: QueryHookOptions<ProgramCategorizedTotalsQuery, ProgramCategorizedTotalsQueryVariables>
) => {
  return useQuery<ProgramCategorizedTotalsQuery, ProgramCategorizedTotalsQueryVariables>(
    programCategorizedTotalsQuery,
    {
      variables: categorizationName
        ? { programID, includedProjectIDs, categorizationName }
        : undefined,
      ...options,
      skip: !categorizationName,
    }
  );
};

export type CategoryTotals = ProgramCategorizedTotalsQuery['programCategorizedTotals'][number];
