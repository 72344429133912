import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { LinearProgress } from '@material-ui/core';
import { DeleteOutline, Edit, FileCopyOutlined, Help } from '@material-ui/icons';

import {
  previewEventTypes,
  previewPermissionsAnalytics,
  previewTypes,
  roleMutationAnalyticsEvent,
  roleMutationTypes,
} from '../../../analytics/analyticsEventProperties';
import { setPreviewSettingsVar } from '../../../api/apollo/reactiveVars';
import { ADMINISTRATOR_ROLE } from '../../../constants';
import { PermissionResource, type Role } from '../../../generated/graphql';
import useProjectRoleQuery from '../../../hooks/useProjectRoleQuery';
import useSendAnalytics from '../../../hooks/useSendAnalytics';
import { RouteKeys } from '../../../routes/paths';
import usePermissions from '../../../utilities/permissions/usePermissions';
import { generateSharedPath } from '../../../utilities/routes/links';
import { BabyButton, Button, ScrollContainer, Tooltip } from '../../scales';

import DeleteRoleDialog from './DeleteRoleDialog';
import Header from './Header';
import { useCreateProjectRoleMutation } from './hooks/useCreateProjectRoleMutation';
import PermissionsTable from './PermissionsTable';

type Props = {
  roles: Role[];
  roleID: UUID;
  projectID: UUID;
};

export default function Role(props: Props) {
  const sendAnalytics = useSendAnalytics();
  const navigate = useNavigate();

  const { canAdd, canEdit, canDelete, isViewOnly } = usePermissions();
  // TODO DD-876: We currently make an exception for ViewOnly mode to access Preview Mode
  const canViewPreviewMode = canEdit(PermissionResource.PERMISSION_TEMPLATES) || isViewOnly;
  const canEditPermissionTemplates = canEdit(PermissionResource.PERMISSION_TEMPLATES);

  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const roleQueryResult = useProjectRoleQuery(props.projectID, props.roleID);
  const role = roleQueryResult.data?.projectRole ?? roleQueryResult.previousData?.projectRole;
  const roleLoading = roleQueryResult.loading;

  const localPermissionGroups =
    role?.permissionGroups?.map((group) => {
      const permissions = group && group.permissions;
      return { ...group, permissions };
    }) || [];

  const [createProjectRole] = useCreateProjectRoleMutation(props.projectID);
  const onCopy = () => {
    createProjectRole(role?.id, (roleId) => {
      navigate(
        generateSharedPath(RouteKeys.PROJECT_TEAM_ROLES_ROLE_VIEW, {
          projectId: props.projectID,
          roleId,
        })
      );
      sendAnalytics(
        roleMutationAnalyticsEvent(roleMutationTypes.CREATE_ROLE, { createdFromID: role?.id })
      );
    });
  };

  const onPreviewRole = () => {
    setPreviewSettingsVar({
      userID: undefined,
      roleID: role?.id,
    });
    sendAnalytics(
      previewPermissionsAnalytics(
        previewEventTypes.PREVIEW_ROLES_CTA,
        previewTypes.ROLE,
        role?.name ?? ''
      )
    );
    navigate(generateSharedPath(RouteKeys.PROJECT_TEAM_TEAMMATES, { projectId: props.projectID }));
  };

  const editButtonDisabled = role?.name === ADMINISTRATOR_ROLE;
  const deleteButtonDisabled =
    role?.name === ADMINISTRATOR_ROLE || !canDelete(PermissionResource.PERMISSION_TEMPLATES);

  const tradesTooltipContent = (
    <div className="flex flex-col gap-3">
      <div>
        {`Set this to “Yes” to control users' Items/Options permissions based on an Item's
        assigned categories, such as “Sitework,” “Electrical,” or “Plumbing.”`}
      </div>
      <div>
        {`Set this to “No” if you want users' Items/Options permissions to be the same for all
        Items/Options regardless of their assigned categories.`}
      </div>
    </div>
  );
  if (roleLoading || !role) return <LinearProgress />;

  return (
    <div className="flex grow flex-col overflow-auto">
      <Header roleName={role?.name ?? ''}>
        {canEditPermissionTemplates && (
          <div className="flex shrink-0 gap-2">
            <Tooltip
              content="Administrator role cannot be edited"
              isDisabled={!editButtonDisabled}
              placement="bottom"
            >
              <Button
                data-cy="edit-role-button"
                isDisabled={editButtonDisabled}
                label="Edit"
                onClick={() =>
                  navigate(
                    generateSharedPath(RouteKeys.PROJECT_TEAM_ROLES_ROLE_EDIT, {
                      projectId: props.projectID,
                      roleId: props.roleID,
                    })
                  )
                }
                startIcon={<Edit />}
                type="secondary"
              />
            </Tooltip>
            <Button
              data-cy="copy-role-button"
              isDisabled={!canAdd(PermissionResource.PERMISSION_TEMPLATES)}
              label="Copy"
              onClick={onCopy}
              startIcon={<FileCopyOutlined />}
              type="secondary"
            />
            {canViewPreviewMode && (
              <Button
                data-cy="preview-role-button"
                label="Preview"
                onClick={onPreviewRole}
                type="secondary"
              />
            )}
            <Tooltip
              content="Administrator role cannot be deleted"
              isDisabled={!deleteButtonDisabled}
              placement="bottom"
            >
              <Button
                data-cy="delete-role-button"
                isDisabled={deleteButtonDisabled}
                label="Delete"
                onClick={() => setShowDeleteDialog(true)}
                startIcon={<DeleteOutline />}
                type="destructive"
              />
            </Tooltip>
          </div>
        )}
      </Header>
      <ScrollContainer direction="vertical">
        <div className="flex flex-col gap-8 p-6 pt-0">
          <div className="flex w-1/2 flex-row gap-2">
            <div className="flex w-1/2 flex-col gap-2">
              <div className="h-6 content-center type-label">Role Name</div>
              <div className="type-body-1">{role?.name}</div>
            </div>
            <div className="flex w-1/2 flex-col gap-2">
              <div className="flex flex-row items-center">
                <div className="type-label">Limit Access by Category</div>
                <Tooltip content={tradesTooltipContent}>
                  <BabyButton aria-label="trades tooltip" icon={<Help />} />
                </Tooltip>
              </div>
              <div className="type-body-1">{role?.hasTrade ? 'Yes' : 'No'}</div>
            </div>
          </div>
          <PermissionsTable
            editable={false}
            hasTrade={role.hasTrade}
            onChange={() => {}}
            permissionGroups={localPermissionGroups}
          />
          {showDeleteDialog && (
            <DeleteRoleDialog
              isOpen
              onClose={() => setShowDeleteDialog(false)}
              projectID={props.projectID}
              role={role}
              roles={props.roles}
            />
          )}
        </div>
      </ScrollContainer>
    </div>
  );
}
