import * as React from 'react';

import { GridController, Position } from '../types';

import { bound } from './range';
import { Orderable } from './reorder';
import { calcPrefixSums, findNextPosition, findPosition } from './size';

const clearRowCheckBoxes = (grid: GridController) => {
  if (grid.numSelectedRows > 0) {
    grid.isRowSelectedArr.fill(false);
    // eslint-disable-next-line no-param-reassign
    grid.numSelectedRows = 0;
    // eslint-disable-next-line no-param-reassign
    grid.previouslySelectedRow = -1;
    grid.updateTable();
  }
};

export const getOrderingData = (line: GridLine): Orderable => ({
  id: line.id,
  numerator: Number(line.orderingNumerator),
  denominator: Number(line.orderingDenominator),
});

// At each of row move steps, we shift the CellData arrays if the row has moved
// from its previous position.
export const reorderHandler = (
  grid: GridController,
  bodyRef: React.RefObject<HTMLDivElement>,
  i: number
) => {
  const onDragStart = ({ row }: Position) => {
    grid.selectRow(row);
    grid.startReorderingRow(row);
    clearRowCheckBoxes(grid);
  };

  const onMoveRow = (prev: number, { row: next }: Position) => {
    grid.shiftRow(prev, next);
    grid.selectRow(next);
  };

  const onDragEnd = (initial: number, last: number) => {
    grid.finishReordering(initial, last);
  };

  const stopPropagation = true;

  return dragHandler(grid, bodyRef, i, 0, stopPropagation, onDragStart, onMoveRow, onDragEnd);
};

export const dragSelectHandler = (
  grid: GridController,
  bodyRef: React.RefObject<HTMLDivElement>,
  i: number,
  j: number
) => {
  const onDragStart = (position: Position) => {
    grid.setSelecting(true);
    grid.setSelectionRange(position, position);
    clearRowCheckBoxes(grid);
  };

  const onRowMove = (prev: number, next: Position) => {
    grid.setSelectionRangeEnd(next);
  };

  const onDragEnd = () => {
    const {
      renderedRows,
      selection: { start },
    } = grid;
    const row = bound(renderedRows.start, renderedRows.end, start.row);
    const toCell = { row, column: start.column };
    grid.moveFocus(toCell);
    grid.setSelecting(false);
  };

  // We still want the click to register to the DOM that we selected the cell -
  // if this proves to be problematic we should call moveFocus here directly.
  const stopPropagation = false;

  return dragHandler(grid, bodyRef, i, j, stopPropagation, onDragStart, onRowMove, onDragEnd);
};

// When clicked, select the line, and then track vertical movement
// in order to perform reordering. We will use this + a combination of
// the scrollHeight of the tableBody div to keep track of how far down
// the table we have gone. We keep track of where the row started, and we
// will scroll the scroll position of the table to move along with our row.
//
// Additionally, when we move the mouse outside the table, we continue scrolling
// using its position even if it hasn't moved.
//
// On mouseUp, we cleanup the effects and send the reorderLines mutation to the
// backend. If it succeeds, all is well, but if it fails, we have to make sure
// to restore the previous state.
const dragHandler = (
  grid: GridController,
  bodyRef: React.RefObject<HTMLDivElement>,
  i: number,
  j: number,
  stopPropagation: boolean,
  onDragStart: (start: Position) => void,
  onRowMove: (prev: number, next: Position) => void,
  onDragEnd: (initial: number, last: number) => void
) => {
  return (e: React.MouseEvent) => {
    const down = e.nativeEvent;
    if (bodyRef.current) {
      const widths = calcPrefixSums(grid.colWidths());
      const totals = grid.getRowTotals();
      let lastSelectedRow = i;
      onDragStart({ row: i, column: j });

      // Are we still scrolling?
      let active = true;
      // Generation of the mouse event, so we don't use old ones.
      let currentGeneration = 0;

      // The absolute value of 'original' isn't as strictly important as the difference
      // between here and where we let go. We use `pageY` so we aren't thrown off
      // if the user accidentally also scrolls the page while dragging a row.
      const originalY = down.pageY + bodyRef.current.scrollTop;
      const originalX = down.pageX;
      const clickHeight = totals[i] + down.offsetY; // We need the native mouse event for this property
      const clickWidth = (widths[j - 1] || 0) + down.offsetX;

      const mouseMove = (move: MouseEvent) => {
        currentGeneration += 1;
        const handleDrag = (generation: number) => {
          // Execute only if we're still scrolling && the event is still relevant.
          if (bodyRef.current && active && generation === currentGeneration) {
            const bodyOffset = bodyRef.current.scrollTop;
            const currentY = move.pageY + bodyOffset;
            const currentX = move.pageX;
            const yDifference = currentY - originalY;
            const xDifference = currentX - originalX;
            const nextIndex = Math.max(0, findPosition(totals, clickHeight + yDifference));
            const { start: startRow, end: lastRow } = grid.visibleRows;

            // If we moved or we're at either one of the boundaries,
            // take some action, whether that be scrolling or adjusting
            // the currently alive selection.
            if (nextIndex !== lastSelectedRow || nextIndex <= startRow || nextIndex >= lastRow) {
              if (nextIndex !== lastSelectedRow) {
                const column = bound(
                  0,
                  widths.length - 1,
                  findNextPosition(widths, 0, clickWidth + xDifference) + 1
                );
                onRowMove(lastSelectedRow, { row: nextIndex, column });
                lastSelectedRow = nextIndex;
              }
              if (nextIndex < startRow) {
                const top = totals[nextIndex - 1] || 0;
                // Bring the scrolled-to row fully into view
                // TODO: Slow this down? It might be a bit fast.
                bodyRef.current.scrollTo({ top, behavior: 'auto' });

                // Request the next frame if we're outside the bounds,
                // but pass the generation so that this doesn't execute if
                // we move the mouse and the position changes.
                requestAnimationFrame(() => handleDrag(generation));
              } else if (nextIndex >= lastRow) {
                // Calculate where we needa be in order to get this end-position fully into view.
                const newRow = bound(
                  -1,
                  totals.length - 2,
                  findNextPosition(
                    totals,
                    Math.max(0, startRow - 2), // Start a little earlier in case start is on a render boundary.
                    totals[bound(0, totals.length - 1, nextIndex)] - grid.maxHeight()
                  ) + 1 // Take the 'next' position after the found one
                );
                const top = totals[newRow];
                if (top > bodyRef.current.scrollTop) {
                  bodyRef.current.scrollTo({ top, behavior: 'auto' });
                }
                requestAnimationFrame(() => handleDrag(generation));
              }
            }
          }
        };
        requestAnimationFrame(() => handleDrag(currentGeneration));
      };

      const mouseUp = () => {
        active = false;
        onDragEnd(i, lastSelectedRow);
        window.removeEventListener('mousemove', mouseMove);
        window.removeEventListener('mouseup', mouseUp);
      };
      if (stopPropagation) {
        down.preventDefault();
        down.stopPropagation();
      }
      window.addEventListener('mousemove', mouseMove);
      window.addEventListener('mouseup', mouseUp);
    }
  };
};
