import { FC } from 'react';

import { EstimateType } from '../../generated/graphql';

import { useExportProjectCostBreakdownReport } from './hooks';

type Props = {
  projectID: UUID;
  milestoneID: UUID;
  estimateType: EstimateType;
  costMode: CostMode;
  milestoneName: string;
  projectName: string;
};

const ExportProjectCostBreakdownReport: FC<Props> = ({
  projectID,
  milestoneID,
  estimateType,
  milestoneName,
  projectName,
  costMode,
}) => {
  useExportProjectCostBreakdownReport(
    projectID,
    milestoneID,
    estimateType,
    costMode,
    projectName,
    milestoneName
  );

  return null;
};

export default ExportProjectCostBreakdownReport;
