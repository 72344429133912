import Add from '@material-ui/icons/Add';

import {
  TimelineEvent,
  timelineEvent as timelineEventOuter,
} from '../../analytics/analyticsEventProperties';
import { TimelineGroups } from '../../api/gqlEnums';
import { TimelineActivityType } from '../../api/gqlEnumsBe';
import { CREATE } from '../../constants';
import { DD_TIMELINE_ACTIVITIES_NUDGE } from '../../features';
import { PermissionResource } from '../../generated/graphql';
import { useHasFeature } from '../../hooks/useHasFeature';
import useSendAnalytics from '../../hooks/useSendAnalytics';
import usePermissions from '../../utilities/permissions/usePermissions';
import { KeyDatesBanner } from '../KeyDatesNudge/Banner/KeyDatesBanner';
import CTAIconButton from '../shared-widgets/CTAIconButton';

import TimelineActivitiesSelect from './TimelineActivitiesSelect';
import TimelineCollapse from './TimelineCollapse';
import TimelineDatePicker from './TimelineDatePicker';
import TimelineExpandAll from './TimelineExpandAll';
import TimelineLegend from './TimelineLegend/TimelineLegend';

const TWO_WEEKS_MS = 12096e5;

type Props = {
  activityTypes: TimelineActivityType[];
  canAddActivities: boolean;
  endDate: Date;
  expandableActivities: UUID[];
  rangeDate: Date[];
  setActivityTypes: React.Dispatch<React.SetStateAction<TimelineActivityType[]>>;
  setEndStr: React.Dispatch<React.SetStateAction<string>>;
  setStartStr: React.Dispatch<React.SetStateAction<string>>;
  setTimelineActivityModal: React.Dispatch<
    React.SetStateAction<{
      activity: undefined;
      open: boolean;
      type: string;
    }>
  >;
  source?: string;
  startDate: Date;
};

export default function TimelineHeader(props: Props) {
  const {
    activityTypes,
    canAddActivities,
    endDate,
    expandableActivities,
    rangeDate,
    setActivityTypes,
    setEndStr,
    setStartStr,
    setTimelineActivityModal,
    source = 'timeline',
    startDate,
  } = props;

  const { canAdd } = usePermissions();

  const canCreateMilestones = canAdd(PermissionResource.MILESTONES);
  const sendAnalytics = useSendAnalytics();
  const hasKeyDatesNudge = useHasFeature(DD_TIMELINE_ACTIVITIES_NUDGE);
  const timelineEvent = (event: TimelineEvent, props: { filter?: string[] } = {}) =>
    timelineEventOuter(event, { ...props, source });
  let maxDate = endDate < rangeDate[1] ? endDate : rangeDate[1];
  let minDate = startDate > rangeDate[0] ? startDate : rangeDate[0];
  maxDate = new Date(maxDate.getTime() - TWO_WEEKS_MS);
  minDate = new Date(minDate.getTime() + TWO_WEEKS_MS);

  const header = (
    <header className="flex items-center gap-4 p-4">
      <header className="type-heading1">Timeline</header>
      <div className="flex-grow">
        <TimelineExpandAll expandableActivities={expandableActivities} />
      </div>
    </header>
  );

  const inputs = (
    <div className="flex pr-6">
      <div className="flex self-end pl-4">
        <TimelineCollapse nodeId={TimelineGroups.TIMELINE} />
      </div>
      <TimelineLegend />
      <div className="ml-auto flex">
        <div className="-mt-3 gap-0.5">
          <div className="type-label">From</div>
          <TimelineDatePicker
            dataCy="timeline-date-picker-from"
            disableError
            maxDate={maxDate}
            minDate={rangeDate[0]}
            onChange={(d) => {
              setStartStr(d.toISOString());
              sendAnalytics(timelineEvent(TimelineEvent.TIMELINE_FILTER_START_CHANGE));
            }}
            value={startDate}
          />
        </div>
        <div className="-mt-3 gap-0.5 pl-1">
          <div className="type-label">To</div>
          <TimelineDatePicker
            dataCy="timeline-date-picker-to"
            disableError
            maxDate={rangeDate[1]}
            minDate={minDate}
            onChange={(d) => {
              setEndStr(d.toISOString());
              sendAnalytics(timelineEvent(TimelineEvent.TIMELINE_FILTER_END_CHANGE));
            }}
            value={endDate}
          />
        </div>
        <div className="-mt-3 gap-0.5 pl-3">
          <div className="type-label">Activities</div>
          <TimelineActivitiesSelect
            maxChips={3}
            onChange={(activities) => {
              setActivityTypes(activities);
              const filter = activities.map((type) => TimelineActivityType[type]);
              sendAnalytics(timelineEvent(TimelineEvent.TIMELINE_FILTER_ACTIVITY, { filter }));
            }}
            // list of selected activities EVENTS, MILESTONES or PHASES
            selected={activityTypes}
          />
        </div>
        {canAddActivities && (
          <CTAIconButton
            buttonText="New Activity"
            icon={<Add />}
            onClick={() => {
              setTimelineActivityModal({ activity: undefined, open: true, type: CREATE });
              sendAnalytics(timelineEvent(TimelineEvent.ACTIVITY_NEW_CTA));
            }}
          />
        )}
      </div>
    </div>
  );

  return (
    <>
      {header}
      {hasKeyDatesNudge && canAddActivities && canCreateMilestones && (
        <KeyDatesBanner activityTypes={activityTypes} />
      )}
      {inputs}
    </>
  );
}
