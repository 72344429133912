import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../theme/komodo-mui-theme';

const styles = (theme: KomodoTheme) =>
  createStyles({
    textarea: {
      ...theme.typography.webParagraph,
      boxSizing: 'border-box',
      border: `1px solid ${theme.palette.notapplicable}`,
      borderRadius: 4,
      padding: 16,
      width: '100%',

      '&::placeholder': {
        color: theme.palette.disabledGrey,
      },
    },
  });

export default styles;
