import { Button, Chip, Switch } from '../../scales';
import { ProjectAlternate, ProjectIdea } from '../types';

type Props = {
  idea: ProjectIdea;
  included: UUID[];
  onCreateItem: (alternateID: UUID) => void;
  onToggle: (alternateID: UUID) => void;
};

export default function AlternateTiles(props: Props) {
  return (
    <div className="grid w-full grid-cols-[repeat(auto-fill,minmax(310px,1fr))] gap-6">
      <AlternateTile alternate={props.idea.root} isRoot />
      {props.idea.alternates.map((alternate) => (
        <AlternateTile
          key={alternate.id}
          alternate={alternate}
          isIncluded={props.included.includes(alternate.id)}
          onCreateItem={() => props.onCreateItem(alternate.id)}
          onToggle={() => props.onToggle(alternate.id)}
        />
      ))}
    </div>
  );
}

function AlternateTile(props: {
  onToggle?: () => void;
  onCreateItem?: () => void;
  isRoot?: boolean;
  isIncluded?: boolean;
  alternate: ProjectAlternate;
}) {
  return (
    <div
      className={[
        'flex w-full flex-col rounded',
        props.isRoot ? 'bg-background-1' : 'border bg-background-primary',
      ].join(' ')}
    >
      <div
        className="flex h-[190px] flex-col justify-end rounded-t bg-cover bg-no-repeat"
        style={{ backgroundImage: `url(${props.alternate.imageURL})` }}
      >
        <div className="flex flex-col gap-2 bg-gradient-to-t from-[#00000099] from-45% to-[#0000] to-100% p-2 pt-6">
          <div className="text-type-secondary type-heading3">{props.alternate.name}</div>
          {props.isRoot && <Chip text="Current Material" />}
        </div>
      </div>
      <div className="flex flex-col justify-between gap-4 rounded-b p-4">
        <span className="line-clamp-6 type-body3">{props.alternate.description}</span>
        {!props.isRoot && (
          <div className="flex flex-row justify-between gap-2">
            <Switch
              checked={props.isIncluded}
              label="Compare"
              onChange={() => props.onToggle?.()}
            />
            <Button label="Add to Project" onClick={props.onCreateItem} type="secondary" />
          </div>
        )}
      </div>
    </div>
  );
}
