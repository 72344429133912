import { FC } from 'react';

import { UserSource } from '../../../generated/graphql';
import useMemoWrapper from '../../useMemoWrapper';
import FilesTable from '../Files/FilesTable/FilesTable';
import { useFilesTableSelection } from '../Files/FilesTable/FilesTableUseUtils';
import { FileTableCell } from '../Files/FilesTable/FilesTableUtils';

import FileExplorerFolderContents from './FileExplorerFolderContents';
import FilesExplorerError from './FilesExplorerError';
import { getErrorMessage, getFileTableRootEntries } from './FilesExplorerUtils';
import { useLoadSourceProjectFolders } from './hooks/FileExplorerHooks';

type FileExplorerProjectRootProps = {
  hubID: string;
  userSource: UserSource;
  sourceProjectID: string;
};

const FileExplorerProjectRoot: FC<FileExplorerProjectRootProps> = ({
  hubID,
  sourceProjectID,
  userSource,
}) => {
  const { data, loading, error } = useLoadSourceProjectFolders(
    userSource.sourceSystem,
    userSource.id,
    hubID,
    sourceProjectID
  );
  const folders = data?.sourceProjectFolders;
  const fileTableEntries = useMemoWrapper(getFileTableRootEntries, folders);
  const { onEntry, onToggleEntry, selectedIds, onSelectAll, onUnselectAll } =
    useFilesTableSelection();

  const errorMessage = getErrorMessage(folders, error);
  if (errorMessage) return <FilesExplorerError error={errorMessage} userSource={userSource} />;

  const onTableCell = (entry: FileTableCell) => {
    onEntry(
      entry,
      <FileExplorerFolderContents
        folderID={entry.id}
        hubID={hubID}
        sourceProjectID={sourceProjectID}
        userSource={userSource}
      />
    );
  };

  return (
    <FilesTable
      entries={fileTableEntries}
      loading={loading}
      onEntry={onTableCell}
      onSelectAll={onSelectAll}
      onToggleEntry={onToggleEntry}
      onUnselectAll={onUnselectAll}
      selectedIds={selectedIds}
    />
  );
};

export default FileExplorerProjectRoot;
