import { ApolloClient } from '@apollo/client';

import { loadCollaboratorsQuery } from '../components/Collaborators/hooks/queries';
import { LoadCollaboratorsQuery, LoadCollaboratorsQueryVariables } from '../generated/graphql';

import { QueryHookOptions, useQuery } from './useMountAwareQuery';

export const readCollaborators = (
  client: ApolloClient<object>,
  projectID: UUID
): Collaborator[] => {
  const data = client.readQuery<LoadCollaboratorsQuery, LoadCollaboratorsQueryVariables>({
    query: loadCollaboratorsQuery,
    variables: { projectId: projectID },
  });
  return data?.collaborators ?? [];
};

const useCollaboratorsQuery = (
  projectId?: UUID,
  options?: QueryHookOptions<LoadCollaboratorsQuery, LoadCollaboratorsQueryVariables>
) =>
  useQuery<LoadCollaboratorsQuery, LoadCollaboratorsQueryVariables>(loadCollaboratorsQuery, {
    variables: { projectId },
    skip: !projectId,
    ...options,
  });

export default useCollaboratorsQuery;
