export interface Orderable {
  id: UUID;
  numerator: number;
  denominator: number;
}

const calculateLineOrdering = (
  prevLine: Orderable | null,
  nextLine: Orderable | null,
  rows: Orderable[]
) => {
  if ((!prevLine && !nextLine) || !rows) return null;
  let n;
  let d;
  if (!prevLine) {
    // Line is inserted to top of grid
    n = rows[1].numerator - 1;
    d = rows[1].denominator;
  } else if (!nextLine) {
    // Line is inserted to bottom of grid
    n = rows[rows.length - 2].numerator + 1;
    d = rows[rows.length - 2].denominator;
  } else {
    // Line is inserted between lines
    let { numerator: n1 } = prevLine;
    const { denominator: d1 } = prevLine;
    let { numerator: n2 } = nextLine;
    const { denominator: d2 } = nextLine;
    if (d1 < d2) n1 *= d2 / d1;
    if (d1 > d2) n2 *= d1 / d2;

    n = n1 + n2;
    d = 2 * Math.max(d1, d2);
  }
  if (Math.abs(n) >= Number.MAX_SAFE_INTEGER || d >= Number.MAX_SAFE_INTEGER || d <= 0) return null;
  while (n % 2 === 0 && d % 2 === 0) {
    n /= 2;
    d /= 2;
  }
  return { numerator: n, denominator: d };
};

const resetLineOrderings = (rows: Orderable[]): ReorderLineInput[] => {
  let i;
  for (i = 0; i < rows.length; i += 1) {
    const node = rows[i];
    node.numerator = i + 1;
    node.denominator = 1;
  }
  return rows.map((row, index) => ({
    id: row.id,
    orderingNumerator: String(index + 1),
    orderingDenominator: String(1),
  }));
};

export const reorderedRowData = (rows: Orderable[], index: number): ReorderLineInput[] | null => {
  if (rows.length <= 1) return null;
  const prevRow = index - 1 >= 0 ? rows[index - 1] : null;
  const nextRow = index + 1 < rows.length ? rows[index + 1] : null;
  const ordering = calculateLineOrdering(prevRow, nextRow, rows);
  // In the rare case that we need to reorder all lines
  if (!ordering) return resetLineOrderings(rows);
  // In the likely case that we need to reorder one line
  const { numerator, denominator } = ordering;
  const node = rows[index];
  node.numerator = numerator;
  node.denominator = denominator;
  return [
    { id: node.id, orderingNumerator: String(numerator), orderingDenominator: String(denominator) },
  ];
};
