import { FC, KeyboardEvent, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  LeadEvent,
  LeadKey,
  NewProjectEvent,
  leadEvent,
  newProjectEvent,
} from '../../../analytics/analyticsEventProperties';
import { UserStatus } from '../../../api/gqlEnumsBe';
import { UNASSIGNED } from '../../../constants';
import { DD_TIMELINE_ACTIVITIES_NUDGE } from '../../../features';
import { CreateProjectMutationVariables, ProjectType } from '../../../generated/graphql';
import useCollaboratorsQuery from '../../../hooks/useCollaboratorsQuery';
import { useCompanyProjectTypesQuery } from '../../../hooks/useCompanyProjectTypesQuery';
import { useHasFeature } from '../../../hooks/useHasFeature';
import { useProjectDeliveryTypes } from '../../../hooks/useProjectDeliveryTypesQuery';
import useSendAnalytics from '../../../hooks/useSendAnalytics';
import { setToast } from '../../../hooks/useToastParametersLocalQuery';
import { EmptyThumbnail } from '../../AssigneeSelect/AssigneeSelect';
import { useCreateCollaborators } from '../../Collaborators/hooks';
import { useSetProjectLead } from '../../Collaborators/hooks/projectLead';
import UserAvatar from '../../Collaborators/UserAvatar';
import useOrganizationsQuery from '../../CompanyTab/CompanyTabOrganizations/hooks/useOrganizationsQuery';
import { filterPublished } from '../../CompanyTab/CompanyTabOrganizations/OrgSelector/utils';
import { useCompanyRoutesData } from '../../CompanyTab/CompanyTabUtils';
import { useCompanyUsersQuery } from '../../CompanyTab/useCompanyUsersQuery';
import { useKeyDates } from '../../KeyDatesNudge/hooks/useKeyDates';
import { useDesignPhaseTypes } from '../../Milestone/hooks/useDesignPhaseTypesQuery';
import { useSetProjectOrgs } from '../../Organizations/hooks/hooks';
import useProjectStatusesQuery from '../../ProjectsList/hooks/useProjectStatusesQuery';
import { DialogFlow, SelectEntry } from '../../scales';
import { ACTIVITIES_DEFAULT } from '../../Timeline/TimelineUtils';
import useMemoWrapper from '../../useMemoWrapper';

import {
  computeClearInactive,
  computeProjectTypes,
  getProjectInputDefault,
} from './DialogsNewProjectUtils';
import useCreateProject from './hooks/useCreateProject';
import isValidProjectInput from './isValidProjectInput';
import getKeyDatesStep from './steps/KeyDatesStep/getKeyDatesStep';
import getLeadStep from './steps/LeadStep/getLeadStep';
import getMilestoneStep from './steps/MilestoneStep/getMilestoneStep';
import getOverviewStep from './steps/OverviewStep/getOverviewStep';

type DialogsNewProjectProps = {
  isOpen: boolean;
  onClose: () => void;
  template?: ProjectTemplate;
};

const DialogsNewProject: FC<DialogsNewProjectProps> = ({
  isOpen: dialogOpen = false,
  onClose,
  template,
}) => {
  const createProject = useCreateProject();
  const [createCollaborators] = useCreateCollaborators();
  const [setProjectOrgs] = useSetProjectOrgs();
  const [setProjectLead] = useSetProjectLead();

  const sendAnalytics = useSendAnalytics();
  const hasKeyDatesFeature = useHasFeature(DD_TIMELINE_ACTIVITIES_NUDGE);
  const navigate = useNavigate();

  const [submitted, setSubmitted] = useState(false);

  const templateProject = template?.project;
  const projectInput = getProjectInputDefault(templateProject, template?.settings);
  const { data } = useCollaboratorsQuery(templateProject?.id);
  const collaboratorsFromTemplate = useMemo(() => data?.collaborators || [], [data?.collaborators]);
  const [project, setProject] = useState<Partial<CreateProjectMutationVariables>>(projectInput);
  const [projectID, setProjectID] = useState<string | null>(null);
  const [projectLeadID, setProjectLeadID] = useState<UUID | undefined>(
    collaboratorsFromTemplate.find((c) => c.isProjectLead === true)?.user.id
  );

  const onKeyDown = async (evt: KeyboardEvent<HTMLDivElement>) => {
    if (evt.key === 'Enter' && isValidProjectInput(project)) {
      return onCreateProject();
    }

    return Promise.resolve();
  };

  const { companyID } = useCompanyRoutesData();
  const { data: typesDataNew } = useCompanyProjectTypesQuery(false, companyID);
  const projectTypes: ProjectType[] = useMemoWrapper(
    computeProjectTypes,
    typesDataNew?.companyProjectTypes || []
  );

  const onCreateProject = async () => {
    setSubmitted(true);

    if (!isValidProjectInput(project)) {
      setToast({ message: 'Please fill out all fields' });
      throw new Error('Invalid project input');
    }

    sendAnalytics(
      newProjectEvent(NewProjectEvent.CREATE_CTA, {
        location: window.location.pathname,
        milestoneDesignPhase: project.milestoneDesignPhaseID,
        milestoneName: project.milestone,
        milestoneStartDate: project.milestoneDate,
        orgNodeIDs: project.orgNodeIDs ?? undefined,
        projectDeliveryMethod: project.projectDeliveryTypeID ?? undefined,
        projectStatus: project.statusID,
        projectType: project.typeID ?? undefined,
      })
    );

    try {
      const createdProject = await createProject(project);
      setProjectID(createdProject.id);
      setSubmitted(false);
    } catch (error) {
      setSubmitted(false);
      setToast({ message: 'Failed to create project' });
      throw new Error('Failed to create project');
    }
  };

  const designPhaseTypes = useDesignPhaseTypes();
  const milestoneStep = getMilestoneStep({
    designPhaseTypes,
    project,
    setProject,
    submitted,
    onKeyDown,
    onCreateProject,
    sendAnalytics,
  });

  const projectStatuses = useProjectStatusesQuery().data?.projectStatuses ?? [];
  const statuses = useMemoWrapper(computeClearInactive, projectStatuses);
  const projectDeliveryTypes = useProjectDeliveryTypes();
  const overviewStep = getOverviewStep({
    project,
    projectDeliveryTypes,
    projectTypes,
    statuses,
    setProject,
    submitted,
    template,
    onKeyDown,
  });

  const organizationsQueryResult = useOrganizationsQuery(companyID);
  const orgs = organizationsQueryResult.data?.organizations;
  const publishedOrgs = useMemoWrapper(filterPublished, orgs);
  const { data: companyUserData } = useCompanyUsersQuery({
    variables: { companyID },
    skip: !companyID,
  });
  const activeUsers =
    useMemo(
      () =>
        companyUserData?.companyUsers.filter(
          (companyUser) => companyUser?.user?.status !== UserStatus.DEACTIVATED
        ),
      [companyUserData]
    ) || [];
  const projectLeadEntries: SelectEntry[] = [];
  activeUsers.forEach((companyUser) => {
    if (companyUser.user) {
      projectLeadEntries.push({
        id: companyUser.user?.id,
        label: companyUser.user?.name,
        startAdornment: <UserAvatar assignee={companyUser.user} />,
      });
    }
  });
  const unassignedEntry: SelectEntry = {
    id: UNASSIGNED,
    label: UNASSIGNED,
    startAdornment: <EmptyThumbnail />,
    isSection: false,
  };
  projectLeadEntries.unshift(unassignedEntry);
  const leadStep = getLeadStep({
    activeUsers,
    createCollaborators,
    navigate,
    projectLeadEntries,
    projectLeadID,
    projectID,
    publishedOrgs,
    setProjectLeadID,
    setProjectLead,
    setProjectOrgs,
    submitted,
    templateProject,
    hasKeyDatesFeature: false,
    orgs: [],
    project,
    sendAnalytics,
    setProject,
  });

  const keyDates = useKeyDates({ activityTypes: ACTIVITIES_DEFAULT, projectID });

  const steps = [
    overviewStep,
    milestoneStep,
    ...(hasKeyDatesFeature
      ? [
          getKeyDatesStep({
            keyDates,
            name: project.name || '',
            submitted,
          }),
        ]
      : []),
    leadStep,
  ];

  return (
    <DialogFlow
      isOpen={dialogOpen}
      onClose={(index) => {
        onClose();
        sendAnalytics(
          leadEvent(LeadKey.NEW_PROJECT, LeadEvent.CLOSE, {
            step: index + 1,
          })
        );
      }}
      onComplete={() => onClose()}
      size="md"
      steps={steps}
      title="New Project"
    />
  );
};

export default DialogsNewProject;
