import { ProjectCompSectionType } from '../../../api/gqlEnums';
import {
  ProjectCompEscalationInput,
  ProjectCompEscalationMetaInput,
} from '../../../generated/graphql';
import { withStyles } from '../../../theme/komodo-mui-theme';
import { EMPTY_COST } from '../../../utilities/string';
import { StrictUnion } from '../../../utilities/types';
import NormalTooltipInfoIcon from '../../NormalTooltip/NormalTooltipInfoIcon';
import { EscalationTargetLocation, ProjectCompSectionVariant } from '../constants/projectCompTypes';
import { EscalationTarget } from '../hooks/useAutoEscalateMultiple';
import ProjectCompInformationField from '../ProjectCompInformationField';
import EscalationInput from '../ProjectCompsEscalation/EscalationInput';
import LocationEscalationInput from '../ProjectCompsEscalation/LocationEscalationInput';
import LocationEscalationTarget from '../ProjectCompsEscalation/LocationEscalationTarget';
import TimeEscalationInput from '../ProjectCompsEscalation/TimeEscalationInput';
import { scrubEmptyNumberString, useIsProjectCompSectionCollapsed } from '../ProjectCompsSetUtils';
import styles from '../ProjectCompsStyles';
import SidebarLabel from '../SidebarLabel';

import ProjectCompSectionHeader from './ProjectCompSectionHeader';
import { ESCALATION_LABEL } from './ProjectCompsSectionLabels';

type CommonEscalationProps = {
  classes: Classes<typeof styles>;
};

type ProjectCompEscalationProps = CommonEscalationProps & {
  variant: ProjectCompSectionVariant.PROJECT_COMP;
  activeMilestoneDate: string;
  escalation: ProjectCompEscalationInput;
  onAutoEscalateAllLocation: (target?: EscalationTarget) => void;
  onAutoEscalateAllTime: () => void;
  onTargetLocationChange: (location: EscalationTargetLocation) => void;
  project: Pick<ProjectProps, 'location' | 'lat' | 'lon' | 'name' | 'thumbnail'>;
  setEscalationFutureTime: (time: string | null) => void;
  setEscalationLocation: (location: string | null, meta?: ProjectCompEscalationMetaInput) => void;
  setEscalationTime: (time: string | null, meta?: ProjectCompEscalationMetaInput) => void;
  targetLocation: EscalationTargetLocation;
};

type AverageCompEscalationProps = CommonEscalationProps & {
  variant: ProjectCompSectionVariant.AVERAGE_COMP;
};

type SidebarEscalationProps = CommonEscalationProps & {
  autoEscalatedTo?: { location?: string; time?: string; isLocationTargetFuzzyMatch?: boolean };
  onTargetLocationChange: (location: EscalationTargetLocation) => void;
  targetLocation: EscalationTargetLocation;
  variant: ProjectCompSectionVariant.SIDEBAR;
};

type Props = StrictUnion<
  ProjectCompEscalationProps | AverageCompEscalationProps | SidebarEscalationProps
>;

const ProjectCompSectionEscalation = (props: Props) => {
  const { classes } = props;

  let locationEscalationField = <></>;
  let timeEscalationField = <></>;
  let futureTimeEscalationField = <></>;

  if (props.variant === ProjectCompSectionVariant.AVERAGE_COMP) {
    locationEscalationField = (
      <ProjectCompInformationField
        className={classes.field}
        classNameTypography={classes.labelText}
        value={EMPTY_COST}
      />
    );

    timeEscalationField = (
      <ProjectCompInformationField
        className={classes.field}
        classNameTypography={classes.labelText}
        value={EMPTY_COST}
      />
    );

    futureTimeEscalationField = (
      <ProjectCompInformationField
        className={classes.field}
        classNameTypography={classes.labelText}
        value={EMPTY_COST}
      />
    );
  } else if (props.variant === ProjectCompSectionVariant.SIDEBAR) {
    locationEscalationField = (
      <SidebarLabel>
        <div>Location Factor</div>
        {props.autoEscalatedTo?.location && (
          <LocationEscalationTarget
            dataCy="autoescalation-target-loc"
            isFuzzyMatch={props.autoEscalatedTo.isLocationTargetFuzzyMatch || false}
            onTargetLocationChange={props.onTargetLocationChange}
            targetLocation={{ location: props.autoEscalatedTo.location }}
          />
        )}
      </SidebarLabel>
    );

    timeEscalationField = (
      <SidebarLabel>
        <div>Time Factor</div>
        <div data-cy="autoescalation-target-time">{props.autoEscalatedTo?.time ?? ''}</div>
      </SidebarLabel>
    );

    futureTimeEscalationField = (
      <SidebarLabel
        endAdornment={
          <NormalTooltipInfoIcon title="Optional: Manually add a time factor beyond the present day." />
        }
        showEndAdornment
      >
        <div>Future Time Factor</div>
      </SidebarLabel>
    );
  } else if (props.variant === ProjectCompSectionVariant.PROJECT_COMP) {
    const setLocationEscalation = (
      locationEscalation: string,
      meta?: ProjectCompEscalationMetaInput
    ) => {
      props.setEscalationLocation(scrubEmptyNumberString(locationEscalation), meta);
    };

    const setTimeEscalation = (timeEscalation: string, meta?: ProjectCompEscalationMetaInput) => {
      props.setEscalationTime(scrubEmptyNumberString(timeEscalation), meta);
    };

    const setFutureTimeEscalation = (futureTimeEscalation: string) => {
      props.setEscalationFutureTime(scrubEmptyNumberString(futureTimeEscalation));
    };

    locationEscalationField = (
      <LocationEscalationInput
        locationFrom={{
          name: props.project.location,
          lat: props.project.lat ?? undefined,
          lon: props.project.lon ?? undefined,
        }}
        locationTo={{
          name: props.targetLocation.location,
          lat: props.targetLocation.lat ?? undefined,
          lon: props.targetLocation.lon ?? undefined,
        }}
        meta={props.escalation.locationMeta ?? undefined}
        onAutoEscalateAllLocation={props.onAutoEscalateAllLocation}
        onChange={setLocationEscalation}
        onTargetLocationChange={props.onTargetLocationChange}
        project={props.project}
        value={props.escalation.location ?? ''}
      />
    );

    timeEscalationField = (
      <TimeEscalationInput
        dateFrom={props.activeMilestoneDate}
        // TODO RSMEANS: Configurable in #17757. This should also default to today's date
        // when we're confident that we get RSMeans data often enough to do that.
        dateTo="2024-07-01T00:00:00Z"
        meta={props.escalation.timeMeta ?? undefined}
        onAutoEscalateAllTime={props.onAutoEscalateAllTime}
        onChange={setTimeEscalation}
        project={props.project}
        value={props.escalation.time ?? ''}
      />
    );

    futureTimeEscalationField = (
      <EscalationInput
        onChange={setFutureTimeEscalation}
        type="future"
        value={props.escalation.timeFuture ?? ''}
      />
    );
  }

  // Collapse Settings
  const escalationSection = ProjectCompSectionType.SECTION_ESCALATION;
  const isEscalationCollapsed = useIsProjectCompSectionCollapsed(escalationSection);

  return (
    <div className={classes.fieldsContainer}>
      <ProjectCompSectionHeader
        collapseSection={escalationSection}
        hoverInfo="Escalation is a percent increase applied to the project costs based on time and location conditions"
        isHighlightable={props.variant === ProjectCompSectionVariant.SIDEBAR}
        isTogglable={props.variant === ProjectCompSectionVariant.SIDEBAR}
        label={
          props.variant === ProjectCompSectionVariant.SIDEBAR ? ESCALATION_LABEL : 'Escalation'
        }
      />
      {!isEscalationCollapsed && (
        <div
          className={
            props.variant === ProjectCompSectionVariant.SIDEBAR
              ? `${classes.fields} ${classes.labelFields}`
              : `${classes.fields} ${classes.fieldsGroup}`
          }
        >
          {locationEscalationField}
          {timeEscalationField}
          {futureTimeEscalationField}
        </div>
      )}
    </div>
  );
};

export default withStyles(styles)(ProjectCompSectionEscalation);
