import { FC } from 'react';

import { ArrowDropUp } from '@material-ui/icons';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';

import { withStyles } from '../../../theme/komodo-mui-theme';

import styles from './DropDownArrowStyles';

interface BorderlessDropDownArrowProps {
  classes: Classes<typeof styles>;
  dataCy?: string;
  isOpen?: boolean;
  onClick: (isOpen: boolean) => void;
}

const BorderlessDropDownArrow: FC<BorderlessDropDownArrowProps> = ({
  classes,
  dataCy,
  isOpen,
  onClick,
}) => {
  return (
    <div
      className={classes.borderless}
      data-cy={dataCy}
      onClick={() => onClick(!isOpen)}
      onKeyPress={() => onClick(!isOpen)}
      role="button"
      tabIndex={-1}
    >
      {isOpen ? <ArrowDropUp /> : <ArrowDropDown />}
    </div>
  );
};

export default withStyles(styles)(BorderlessDropDownArrow);
