import { FC } from 'react';

import {
  ITEM_ACTIVITY_ALL_ACTIVITY,
  ITEM_ACTIVITY_MY_ACTIVITY,
} from '../../../actions/actionTypes';
import { analyticsEvent } from '../../../analytics/analyticsEventProperties';
import useSendAnalytics from '../../../hooks/useSendAnalytics';
import { isEnumValue } from '../../../utilities/types';
import BorderlessJoinSelect from '../../Select/BorderlessJoinSelect/BorderlessJoinSelect';
import { getEntry } from '../../Select/JoinSelect/JoinSelect';

import { ItemActivityFeedReportType } from './ItemActivityFeedUtils';

type Props = {
  selectedReportType: ItemActivityFeedReportType;
  setReportType: (reportType: ItemActivityFeedReportType) => void;
};

const ItemActivityFeedReportSelector: FC<Props> = (props) => {
  const sendAnalytics = useSendAnalytics();

  const entries = Object.values(ItemActivityFeedReportType).map((reportType) => {
    const subText =
      reportType === ItemActivityFeedReportType.MY_ACTIVITY
        ? 'All activity from Items you created, commented on, are assigned to, and were @-mentioned in'
        : 'All activity in order of date';
    return getEntry(reportType, reportType, undefined, undefined, undefined, subText);
  });

  return (
    <div className="mt-4 w-16" data-cy="item-activity-feed-report-selector">
      <BorderlessJoinSelect
        entries={entries}
        onChange={(value) => {
          if (isEnumValue(ItemActivityFeedReportType, value)) {
            props.setReportType(value);
            if (value === ItemActivityFeedReportType.ALL_ACTIVITY)
              sendAnalytics(analyticsEvent(ITEM_ACTIVITY_ALL_ACTIVITY));
            if (value === ItemActivityFeedReportType.MY_ACTIVITY)
              sendAnalytics(analyticsEvent(ITEM_ACTIVITY_MY_ACTIVITY));
          }
        }}
        value={props.selectedReportType}
      />
    </div>
  );
};

export default ItemActivityFeedReportSelector;
