import { useEffect } from 'react';

import { QueryHookOptions } from '@apollo/client';

import {
  SearchProjectsForProjectSelectionQuery,
  SearchProjectsForProjectSelectionQueryVariables,
  SearchProjectsQuery,
  SearchProjectsQueryVariables,
} from '../../../../../generated/graphql';
import { useQuery } from '../../../../../hooks/useMountAwareQuery';
import useQueryPagination from '../../../../../hooks/useQueryPagination';
import { useTypesIdsToNames } from '../../../../../hooks/useTypesIdsToNames';

import { searchProjects, searchProjectsForProjectSelection } from './queries';

export const useSearchProjectsQuery = (
  variablesOuter: SearchProjectsQueryVariables,
  options?: QueryHookOptions<SearchProjectsQuery, SearchProjectsQueryVariables>
) => {
  const variables = useTypesIdsToNames(variablesOuter, 'filters.types');

  const query = useQuery<SearchProjectsQuery, SearchProjectsQueryVariables>(searchProjects, {
    ...options,
    variables,
  });

  const { fetchMore, resetPagination } = useQueryPagination(
    variables.pagination.limit,
    query.data?.searchProjects?.data.length ?? 0,
    query.fetchMore
  );

  // Fully reset pagination whenever the query variables (filters, search, sort..) change.
  useEffect(() => {
    resetPagination();
  }, [resetPagination, variables]);

  return {
    ...query,
    fetchMore,
  };
};

export const useSearchProjectsForProjectSelectionQuery = (
  variablesOuter: SearchProjectsForProjectSelectionQueryVariables,
  options?: QueryHookOptions<
    SearchProjectsForProjectSelectionQuery,
    SearchProjectsForProjectSelectionQueryVariables
  >
) => {
  const variables = useTypesIdsToNames(variablesOuter, 'filters.types');

  const query = useQuery<
    SearchProjectsForProjectSelectionQuery,
    SearchProjectsForProjectSelectionQueryVariables
  >(searchProjectsForProjectSelection, {
    ...options,
    variables,
  });

  const { fetchMore, resetPagination } = useQueryPagination(
    variables.pagination.limit,
    query.data?.searchProjects?.data.length ?? 0,
    query.fetchMore
  );

  // Fully reset pagination whenever the query variables (filters, search, sort..) change.
  useEffect(() => {
    resetPagination();
  }, [resetPagination, variables]);

  return {
    ...query,
    fetchMore,
  };
};
