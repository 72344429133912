import { FC, useCallback } from 'react';
import * as React from 'react';
import { useParams } from 'react-router-dom';

import useProjectPropsQuery from '../../../hooks/useProjectPropsQuery';
import { useProjectComparisonReportQuery } from '../../ForecastingRoute/ForecastingReports/hooks/useProjectComparisonReportQuery';
import { useProjectCompsSetQuery } from '../../ProjectComps/hooks/useProjectCompsSetQuery';

import PrintProjectCompsSet from './PrintProjectCompsSet';

type PrintProjectCompsSetDataProps = {
  pageHeader: JSX.Element;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

const PrintProjectCompsSetData: FC<PrintProjectCompsSetDataProps> = ({
  pageHeader,
  setLoading,
}) => {
  // DATA
  const { reportID } = useParams();
  const { data: reportData, loading: loadingReport } = useProjectComparisonReportQuery(reportID);
  const { data: projectCompsSetData } = useProjectCompsSetQuery(reportID);
  const { data: parentProjectData, loading: loadingParentProject } = useProjectPropsQuery(
    reportData?.projectComparisonReport.attachedProject?.hasAccess
      ? reportData?.projectComparisonReport.attachedProject?.id
      : undefined
  );
  const projectCompsSet = projectCompsSetData?.projectCompsSet;
  const parentProject = parentProjectData?.project;
  const isLoadingData = !projectCompsSet || loadingParentProject || loadingReport;
  const onLoad = useCallback(() => setLoading(false), [setLoading]);

  // while waiting for data, render pageHeader as it has a builtin loading bar
  if (isLoadingData) return pageHeader;

  return (
    <PrintProjectCompsSet
      onLoad={onLoad}
      pageHeader={pageHeader}
      parentProject={parentProject}
      projectCompsSet={projectCompsSet}
    />
  );
};

export default PrintProjectCompsSetData;
