import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { Typography } from '@material-ui/core';

import { JoinRoutes } from '../../../api/gqlEnums';
import theme from '../../../theme/komodo-mui-theme';
import { generateSharedPath } from '../../../utilities/routes/links';
import { noPermissionTooltip } from '../../../utilities/string';
import {
  ChartTooltip,
  ProjectCostRange,
  chartTooltipDefault,
} from '../../Charts/ChartsD3/ChartsVerticalBarGraph/ChartsVerticalBarGraphUtils';
import NormalTooltip from '../../NormalTooltip/NormalTooltip';
import {
  ProjectMap,
  getCurrentItemsBreakdownTooltipText,
  getProjectDisplayName,
} from '../ExecutiveDashboardUtils';

type ExecutiveDashboardCurrentItemsBreakdownTooltipProps = {
  chartTooltip: ChartTooltip;
  projectMap?: ProjectMap;
  projectCostRanges: ProjectCostRange[];
  setChartTooltip: (tooltip: ChartTooltip) => void;
};

const ExecutiveDashboardCurrentItemsBreakdownTooltip: FC<
  ExecutiveDashboardCurrentItemsBreakdownTooltipProps
> = ({ chartTooltip, setChartTooltip, projectMap, projectCostRanges }) => {
  const navigate = useNavigate();
  const width = chartTooltip.right - chartTooltip.x;
  const height = chartTooltip.bottom - chartTooltip.y;

  // Note:
  // The typography component is a duplicate (same size & location)
  // of the correspoding label in the bar chart
  // We need to duplicate it to act as an anchor
  // for the tooltip.  The tooltip can't reference
  // an SVG element from the chart.
  // The text needs to be duplicated because we couldn't get the
  // underline for when hovering over the label while the tooltip
  // was open if the label is in d3.  I know the duplication isn't ideal.

  const pcr = projectCostRanges.find((p) => p.project.id === chartTooltip.projectID);
  const project = projectMap?.get(chartTooltip.projectID.toString()) || {
    code: '',
    hasAccess: false,
    milestoneName: '',
    name: '',
  };

  const tooltipText =
    project.hasAccess && pcr
      ? getCurrentItemsBreakdownTooltipText(project, pcr)
      : noPermissionTooltip(project.name);

  // have to set the pointer styles both here and in ChartsVerticalBarGraphUtils to match
  const tooltipCursor = project.hasAccess ? 'pointer' : 'default';

  return (
    <NormalTooltip title={tooltipText}>
      <Typography
        onClick={() =>
          project.hasAccess && pcr
            ? navigate(generateSharedPath(JoinRoutes.PROJECT, { projectId: pcr.project.id }))
            : null
        }
        onMouseLeave={() => setChartTooltip(chartTooltipDefault)}
        style={{
          position: 'fixed',
          cursor: tooltipCursor,
          fontSize: 14,
          fontWeight: 700,
          left: `${chartTooltip.x}px`,
          top: `${chartTooltip.y - 3}px`,
          backgroundColor: theme.palette.background.default,
          opacity: project.hasAccess ? 1 : 0, // only show the link / text if the user has access to the project
          textDecoration: 'underline',
          color: theme.palette.primaryBlue,
          display: 'inline',
          width: `${width}px`,
          height: `${height}px`,
        }}
      >
        {getProjectDisplayName(project.code, project.name)}
      </Typography>
    </NormalTooltip>
  );
};

export default ExecutiveDashboardCurrentItemsBreakdownTooltip;
