import { ProjectCompPortals } from './constants/elementIdentifiers';

export default function ProjectCompsStickyFooter() {
  return (
    <div className="sticky bottom-0 z-10 w-full bg-background-primary" data-cy="sticky-footer">
      <div className="flex w-max min-w-full bg-background-primary shadow-upwards">
        <div className="sticky left-0 z-10 flex bg-background-primary">
          <div className="flex flex-col border-r">
            <div className="z-20 flex" id={ProjectCompPortals.AVG_COMP_FOOTER_COSTS}>
              {/* match the sidebar's width */}
              <div className="w-75 border-r" id={ProjectCompPortals.LABEL_FOOTER_COSTS} />
            </div>
            <div className="z-20 flex grow" id={ProjectCompPortals.AVG_COMP_FOOTER_CONTROLS}>
              {/* match the sidebar's width */}
              <div className="w-75 border-r" id={ProjectCompPortals.LABEL_FOOTER_CONTROLS} />
            </div>
          </div>
        </div>
        <div className="flex flex-col">
          <div className="flex" id={ProjectCompPortals.COMP_FOOTER_COSTS} />
          <div className="flex grow items-center" id={ProjectCompPortals.COMP_FOOTER_CONTROLS} />
        </div>
      </div>
    </div>
  );
}
