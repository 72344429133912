import { FC, useEffect, useState } from 'react';

import { Card, CardHeader, Divider } from '@material-ui/core';

import {
  COMPANY_LOGO,
  CUSTOM_BANNER,
  NO_LOGO,
  SETTINGS_SELECT_BANNER,
  TEAM_LOGOS,
} from '../../../actions/actionTypes';
import { settingsPrintHeaderSelector } from '../../../analytics/analyticsEventProperties';
import { MountPolicy } from '../../../hooks/usePolicyOnFirstMount';
import { useProjectCompaniesQuery } from '../../../hooks/useProjectCompaniesQuery';
import useProjectPropsQuery from '../../../hooks/useProjectPropsQuery';
import useSendAnalytics from '../../../hooks/useSendAnalytics';
import { withStyles } from '../../../theme/komodo-mui-theme';
import { isNonNullable } from '../../../utilities/types';
import { getTeamGUID } from '../../assets/utils';

import { useActiveProjectBannerQuery } from './hooks/GetActiveProjectBannerQuery';
import { useProjectBannersQuery } from './hooks/GetProjectBannersQuery';
import {
  useRemoveActiveProjectBanner,
  useSetActiveProjectBanner,
} from './hooks/SetActiveProjectBannerMutationHook';
import styles from './PrintHeaderContainerStyles';
import PrintHeaderEdit from './PrintHeaderEdit';

type PrintHeaderContainerProps = {
  projectID: UUID;
  canEdit: boolean;
  classes: Classes<typeof styles>;
};

const PrintHeaderContainer: FC<PrintHeaderContainerProps> = ({ canEdit, classes, projectID }) => {
  const sendAnalytics = useSendAnalytics();
  // Get team images
  const {
    data: { project },
    loading: projectLoading,
  } = useProjectPropsQuery(projectID, MountPolicy.SKIP_ON_MOUNT);
  const { team } = project || {};
  const { designTeamGUID, generalContractorGUID, ownerGUID } = getTeamGUID(team);
  // the order here dictates the left to right appearance on button
  const orgLogoIDs = [ownerGUID, generalContractorGUID, designTeamGUID]
    .filter(isNonNullable)
    .filter((l) => !!l);

  // Get active project banner query
  const {
    data: { activeProjectBanner = null } = { activeProjectBanner: null },
    loading: selectedLoading,
  } = useActiveProjectBannerQuery(projectID);

  // Get project company logos
  const { data: { projectCompanies = [] } = { projectCompanies: [] }, loading: companiesLoading } =
    useProjectCompaniesQuery(projectID);

  // Get project banners
  const { data: { projectBanners = [] } = { projectBanners: [] }, loading: bannersLoading } =
    useProjectBannersQuery(projectID);

  // State
  const [printHeader, setPrintHeader] = useState<ActiveProjectBanner | null>(activeProjectBanner);
  useEffect(() => {
    if (printHeader !== activeProjectBanner) {
      setPrintHeader(activeProjectBanner);
    }
  }, [printHeader, activeProjectBanner]);
  // Variables
  const loading = companiesLoading || bannersLoading || projectLoading || selectedLoading;
  const subheader = canEdit
    ? 'Customize the header that appears on the project dashboard and at the top of print reports'
    : 'The header that appears on the project dashboard and at the top of print reports';

  const isAssetBanner = (assetID: UUID): boolean =>
    projectBanners.some((banner) => banner.id === assetID);
  // Updates
  const updateBanner = useSetActiveProjectBanner();
  const removeActiveBanner = useRemoveActiveProjectBanner();

  const setProjectBanner = (assetID: UUID | null | string) => {
    if (!assetID || assetID === NO_LOGO || assetID === TEAM_LOGOS) {
      setPrintHeader(null);
      sendAnalytics(
        settingsPrintHeaderSelector(SETTINGS_SELECT_BANNER, undefined, assetID || undefined)
      );
      return removeActiveBanner(projectID);
    }
    const onSuccessUpdate = (header: ActiveProjectBanner) => setPrintHeader(header);
    const assetIsBanner = isAssetBanner(assetID);
    sendAnalytics(
      settingsPrintHeaderSelector(
        SETTINGS_SELECT_BANNER,
        assetID,
        assetIsBanner ? CUSTOM_BANNER : COMPANY_LOGO
      )
    );
    return assetID && updateBanner(assetID, projectID, onSuccessUpdate);
  };

  return (
    <Card className={classes.card} elevation={0} square>
      <CardHeader subheader={subheader} title="Print Header" />
      <Divider />
      <PrintHeaderEdit
        banners={projectBanners}
        canEdit={canEdit}
        companies={projectCompanies}
        loading={loading}
        orgLogoIDs={orgLogoIDs}
        printHeader={printHeader}
        projectID={projectID}
        setPrintHeaderLocal={setPrintHeader}
        setProjectBanner={setProjectBanner}
      />
    </Card>
  );
};

export default withStyles(styles)(PrintHeaderContainer);
