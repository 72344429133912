import { CurrentCompanyRoleQuery, CurrentCompanyRoleQueryVariables } from '../generated/graphql';

import { currentCompanyRole } from './queries';
import { useQuery } from './useMountAwareQuery';
import { MountPolicy } from './usePolicyOnFirstMount';

export default function useCurrentCompanyRole() {
  const { data, loading } = useQuery<CurrentCompanyRoleQuery, CurrentCompanyRoleQueryVariables>(
    currentCompanyRole,
    undefined,
    MountPolicy.SKIP_ON_MOUNT
  );
  return {
    companyRole: data?.currentCompanyRole,
    loading,
  };
}
