type LabelPosition = {
  graphValue: number;
  labelTop: number;
};

/**
 * Places labels vertically and avoids overlap
 * @param plotHeight
 * @param labelHeight
 * @param graphValues
 * @param labelGap
 */
export function placeLabels(
  plotHeight: number,
  labelHeight: number,
  graphValuesCosts: number[],
  labelGap: number,
  min: number,
  max: number
): LabelPosition[] {
  // converts cost value into pixel value
  const toPixels = (value: number) => {
    const fraction = 1 - (value - min) / (max - min);
    const absoluteTop = fraction * plotHeight;
    return absoluteTop;
  };
  const graphValues = graphValuesCosts.map(toPixels);

  // Sort the graphValues to position labels from top to bottom
  const sortedGraphValues = [...graphValues].sort((a, b) => a - b);

  const labelPositions: LabelPosition[] = [];

  // The minimum and maximum y values where a label can be placed
  const minY = 0;
  const maxY = plotHeight + labelHeight * 3;

  for (let i = 0; i < sortedGraphValues.length; i += 1) {
    const graphValue = sortedGraphValues[i];
    // Try to center the label on the graph value
    let labelTop = graphValue - labelHeight / 2;

    // Ensure the label is within the plot bounds
    labelTop = Math.max(minY, Math.min(labelTop, maxY));

    // Adjust to avoid overlapping with previous labels and add the gap
    if (i > 0) {
      const prevLabelTop = labelPositions[i - 1].labelTop;
      const prevLabelBottom = prevLabelTop + labelHeight + labelGap;

      // If this label overlaps with the previous one (gap included), shift it down
      if (labelTop < prevLabelBottom) {
        labelTop = prevLabelBottom;
      }
    }

    // Ensure the label stays within the plot even after adjustment
    labelTop = Math.min(labelTop, maxY);

    // Save the position
    labelPositions.push({
      graphValue,
      labelTop,
    });
  }

  return labelPositions;
}
