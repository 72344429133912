import { createStyles } from '@material-ui/core';

import { printArea } from '../../../Charts/ChartsEstimate/ChartsEstimateStyles';

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
export default (theme: any) =>
  createStyles({
    description: {
      flexGrow: 1,
    },
    discreteColorLegend: {
      fontFamily: theme.typography.fontFamily,
    },
    formControl: {
      alignSelf: 'baseline',
      margin: theme.spacing.unit,
      minWidth: 120,
    },
    header: {
      display: 'flex',
      paddingBottom: 12,
    },
    hidePrint: {
      '@media print': {
        display: 'none',
      },
    },
    items: {
      fill: 'black',
      margin: '20px 0 12px 0',
      zIndex: 100,
    },
    menuLabel: {
      alignSelf: 'baseline',
      minWidth: '54px',
      padding: '13px 4px 4px 0px',
      '@media print': {
        flex: '1 1 auto',
        textAlign: 'right',
      },
    },
    rejectedOptionsNote: {
      paddingTop: `${theme.spacing.generic[6]}px`,
    },
    root: {
      paddingTop: theme.spacing.unit * 3,
      pageBreakInside: 'avoid',
    },
    itemArea: {
      minHeight: 800,
    },
    printArea,
    legendContainer: {
      display: 'flex',
      flexDirection: 'row',
      textAlign: 'right',
      '& .rv-discrete-color-legend-item__title': {
        ...theme.typography.label,
      },
    },
    spacer: {
      flexGrow: 1,
    },
    topBar: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
      '@media print': {
        borderBottom: theme.border.line,
      },
      [theme.breakpoints.down('sm')]: {
        flexWrap: 'wrap',
      },
    },
    noFilteredData: {
      color: theme.palette.primaryBlue,
      cursor: 'pointer',
      paddingTop: 24,
      paddingLeft: 24,
    },
    printChartTitle: {
      fontFamily: theme.typography.fontFamily,
      fontSize: 16,
      fontWeight: 500,
      lineHeight: '144%',
      letterSpacing: '0.0119em',
      width: '100%',
    },
    printChartSetting: {
      fontFamily: theme.typography.fontFamily,
      fontSize: 12,
      paddingTop: 8,
      letterSpacing: '0.0119em',
    },
  });
