import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';

export default (theme: KomodoTheme) =>
  createStyles({
    containerOuter: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      position: 'relative',
      zIndex: 201,
      fontFamily: theme.typography.fontFamily,
      paddingRight: 8,
      paddingLeft: 0,
    },
  });
