import {
  ActiveProjectBannerQuery,
  ActiveProjectBannerQueryVariables,
} from '../../../../generated/graphql';
import { useQuery } from '../../../../hooks/useMountAwareQuery';

import { getActiveProjectBannerQuery } from './queries';

export const useActiveProjectBannerQuery = (projectID: UUID) =>
  useQuery<ActiveProjectBannerQuery, ActiveProjectBannerQueryVariables>(
    getActiveProjectBannerQuery,
    {
      variables: { projectID },
      skip: !projectID,
    }
  );
