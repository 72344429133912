import { FC } from 'react';

import InputsToggle, { ToggleOption } from '../../Inputs/InputsToggle/InputsToggle';

import { SandboxView } from './SandboxUtil';

const mapTextToKey = (text: string) => {
  switch (text) {
    case SandboxView.SANDBOX_VIEW_WORK:
      return SandboxView.SANDBOX_VIEW_WORK;
    case SandboxView.SANDBOX_VIEW_PRESENT:
      return SandboxView.SANDBOX_VIEW_PRESENT;
    default:
      return SandboxView.SANDBOX_VIEW_WORK;
  }
};

const getToggleOption = (key: SandboxView) => ({
  key,
  text: key,
});

type SandboxViewModeToggleProps = {
  sandboxView: SandboxView;
  setSandboxView: (view: SandboxView) => void;
};

const SandboxViewModeToggle: FC<SandboxViewModeToggleProps> = ({ sandboxView, setSandboxView }) => (
  <InputsToggle
    options={Object.values(SandboxView).map((key: SandboxView) => getToggleOption(key))}
    selected={[getToggleOption(sandboxView)]}
    setSelected={(toggleOption: ToggleOption) => {
      if (toggleOption.key) {
        setSandboxView(mapTextToKey(toggleOption.key));
      }
    }}
  />
);

export default SandboxViewModeToggle;
