import { useState } from 'react';

import { Check } from '@material-ui/icons';

import {
  CompanyAdminEventType,
  companyAdminAnalyticsEvent,
} from '../../../../analytics/analyticsEventProperties';
import { Org } from '../../../../generated/graphql';
import useSendAnalytics from '../../../../hooks/useSendAnalytics';
import { useSetOrgPublished } from '../../../Organizations/hooks/hooks';
import { Button, Dialog, DialogContent } from '../../../scales';

export default function OrgPublishBtn(props: { organization: Org }) {
  const sendAnalytics = useSendAnalytics();
  const [publishOrg] = useSetOrgPublished();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const onPublish = () => {
    const id = props.organization.id;
    publishOrg({
      variables: {
        input: { organizationID: id },
      },
    });
  };

  return (
    <>
      <Button
        label="Publish"
        onClick={() => {
          setIsDialogOpen(true);
          sendAnalytics(
            companyAdminAnalyticsEvent(CompanyAdminEventType.ORGANIZATIONS_PUBLISH_ORG, {
              organization: props.organization.name,
            })
          );
        }}
        startIcon={<Check />}
        type="primary"
      />
      <Dialog
        footerRight={
          <Button
            label="Publish"
            onClick={() => {
              onPublish();
              setIsDialogOpen(false);
              sendAnalytics(
                companyAdminAnalyticsEvent(CompanyAdminEventType.ORGANIZATIONS_PUBLISH_CONFIRM, {
                  organization: props.organization.name,
                })
              );
            }}
            startIcon={<Check />}
            type="primary"
          />
        }
        isOpen={isDialogOpen}
        onClose={() => {
          setIsDialogOpen(false);
          sendAnalytics(
            companyAdminAnalyticsEvent(CompanyAdminEventType.ORGANIZATIONS_PUBLISH_CONFIRM_CLOSE, {
              organization: props.organization.name,
            })
          );
        }}
        title="Publish Draft?"
      >
        <DialogContent>
          <div>
            Publishing this organization will make it visible and usable to all your teammates in
            your company. This action cannot be undone.
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}
