import { FC } from 'react';

import { Help } from '@material-ui/icons';

import { ALLOWANCE_TYPE_DISPLAY, CONTINGENCY_TYPE_DISPLAY } from '../../../../constants';
import { MarkupDisplayType } from '../../../../generated/graphql';
import theme, { withStyles } from '../../../../theme/komodo-mui-theme';
import { formatCost } from '../../../../utilities/currency';
import { formatPercent } from '../../../../utilities/string';
import NormalTooltip from '../../../NormalTooltip/NormalTooltip';
import { Chip } from '../../../scales';

import AllMilestoneReportRowCell from './AllMilestoneReportRowCell';
import { styles } from './AllMilestoneReportRowStyles';

type AllMilestoneReportRowProps = {
  classes: Classes<typeof styles>;
  contingencyRow: MultiMilestoneContingencyReport['contingencies'][number];
  columnNames: string[];
  isFirstRow: boolean;
};

const initialAmountSetToolTip = (
  <>
    Initial amount set (relative to the Milestone Estimate it was created within)
    <br />
    <br />
    Example:
    <br />
    Milestone Estimate = $1,000,000
    <br />
    Design Contingency = $100,000
    <br />
    Initial Amount set = 10% of Milestone Estimate
  </>
);

const AllMilestoneReportRow: FC<AllMilestoneReportRowProps> = ({
  classes,
  contingencyRow,
  columnNames,
  isFirstRow,
}) => {
  return (
    <div className={classes.row}>
      <div className={classes.rowInfoContainer}>
        <div className={classes.rowTitleContainer}>
          <h2 className={classes.rowName}>{contingencyRow.name}</h2>
          <Chip
            backgroundColor={theme.palette.backgroundWhite}
            text={
              contingencyRow.type === MarkupDisplayType.ALLOWANCE
                ? ALLOWANCE_TYPE_DISPLAY
                : CONTINGENCY_TYPE_DISPLAY
            }
          />
        </div>
        <div className={`${classes.rowInfoLabels} ${classes.tableSpacing}`}>
          <div className={classes.initialAmountSetContainer}>
            <div>Initial amount set</div>
            {isFirstRow && (
              <NormalTooltip title={initialAmountSetToolTip}>
                <Help className={classes.info} />
              </NormalTooltip>
            )}
            <div className={isFirstRow ? classes.firstRowTablePercent : classes.tablePercent}>
              {contingencyRow.initialPercentOfMilestoneEstimate
                ? formatPercent(contingencyRow.initialPercentOfMilestoneEstimate, 4)
                : '-'}
            </div>
            <div className={classes.tableNumber}>
              {formatCost(contingencyRow.initialAmountSet) ?? '-'}
            </div>
          </div>
          <div>Amount</div>
          <div>Drawn</div>
          <div>Remaining</div>
        </div>
      </div>
      <div className={classes.rowContingenciesContainer}>
        {contingencyRow.report.map((entry, i) => (
          <AllMilestoneReportRowCell
            key={`${contingencyRow.name} ${contingencyRow.type} ${columnNames[i]}`}
            contingency={entry}
          />
        ))}
      </div>
    </div>
  );
};

export default withStyles(styles)(AllMilestoneReportRow);
