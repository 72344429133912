import DialogsSelectionProjects from '../DialogsSelectionProjects/DialogsSelectionProjects';

const DialogsReportsAddToProject = (props: {
  onAddToProject: (projectId: UUID) => void;
  onClose: () => void;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  open: boolean;
}) => {
  return (
    <DialogsSelectionProjects
      confirmButtonLabel="Add"
      isOpen={props.open}
      onClose={props.onClose}
      onDone={(selectedProjectIDs) => {
        const attachedProjectID = selectedProjectIDs[0];
        if (attachedProjectID) {
          props.onAddToProject(selectedProjectIDs[0]);
        }
      }}
      selectionMode="single"
      title="Add to a project"
    />
  );
};

export default DialogsReportsAddToProject;
