import { FC, useEffect, useState } from 'react';

import { isSecondPopupVar } from '../../api/apollo/reactiveVars';
import { GROUP_BY } from '../../constants';
import MultiGroupOrder from '../shared-widgets/MultiGroup/MultiGroupOrder';
import { MultiGroupOrderOption } from '../shared-widgets/MultiGroup/MultiGroupOrderCategorizationUtils';
import { EMPTY_MESSAGE_NONE } from '../shared-widgets/MultiGroup/MultiGroupOrderSelector';

type CostReportGroupBySelectProps = {
  displayChips?: number;
  emptyMessage?: string;
  header?: string;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  hideHeader?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  onClose: (selected: any) => void;
  options: MultiGroupOrderOption[];
  selected: MultiGroupOrderOption[];
};

const CostReportGroupBySelect: FC<CostReportGroupBySelectProps> = ({
  displayChips = undefined,
  emptyMessage = EMPTY_MESSAGE_NONE,
  header = GROUP_BY,
  hideHeader = false,
  onClose,
  options,
  selected,
}) => {
  // groupBy holds the local selector state that can differ from the state in usePersistentStates while the user has the selector open
  // onClose handles syncing the local selector state back to the global state controller for the CostReport
  const [groupBy, setGroupBy] = useState(selected);
  const [groupByOpen, setGroupByOpen] = useState(false);

  // here we need to watch for changes to selected which is parsed from the usePersistentStates settings object. If this changes because we've switched
  // reports we need to go ahead and update the local state as well
  useEffect(() => {
    if (selected !== groupBy) {
      setGroupBy(selected);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);
  return (
    <div className="flex flex-col gap-0.5">
      {!hideHeader && <div className="type-label">{header}</div>}
      <MultiGroupOrder
        displayChips={displayChips}
        emptyMessage={emptyMessage}
        handleAdd={(toAdd: MultiGroupOrderOption[]) => setGroupBy(groupBy.concat(...toAdd))}
        handleDelete={(toDelete: MultiGroupOrderOption[]) =>
          setGroupBy(groupBy.filter((gb) => toDelete.every((del) => del.name !== gb.name)))
        }
        handleSet={setGroupBy}
        handleSort={(swapA: number, swapB: number) => {
          const newGroupBy = [...groupBy];
          newGroupBy[swapA] = groupBy[swapB];
          newGroupBy[swapB] = groupBy[swapA];
          setGroupBy(newGroupBy);
        }}
        hasSuggestions
        open={groupByOpen}
        options={options}
        selected={groupBy}
        setOpen={(newValue: boolean) => {
          if (!newValue && onClose) onClose(groupBy);
          setGroupByOpen(newValue);
          isSecondPopupVar(newValue);
        }}
      />
    </div>
  );
};

export default CostReportGroupBySelect;
