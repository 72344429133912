import { createStyles } from '@material-ui/core';

import theme from '../../theme/komodo-mui-theme';

export const styles = () => {
  return createStyles({
    dateInput: {
      ...theme.typography.h3,
      width: '100%',
    },
    emptyDateInput: {
      ...theme.typography.h3,
      color: theme.palette.joinPrimary,
      display: 'flex',
      paddingLeft: 2,
      paddingTop: 6,
      width: '100%',
    },
    emptyDateInputIcon: {
      fontSize: 16,
      height: 24,
      textAlign: 'right',
      width: 24,
      marginRight: 13,
      marginTop: -1,
    },
    errorIcon: {
      color: theme.palette.red,
      fontSize: '19px',
      marginRight: '4px',
      marginTop: '6px',
    },
    eventIcon: {
      backgroundColor: theme.palette.chartsThemes.orange,
      height: 10,
      transform: 'rotate(45deg)',
      width: 10,
    },
    icon: {
      height: 32,
      width: 32,
    },
    phaseIcon: {
      backgroundColor: theme.palette.disabledGrey,
      borderRadius: 50,
      height: 10,
      width: 10,
    },
    textInput: {
      ...theme.typography.h3,
      width: '100%',
    },
    tooltip: {
      backgroundColor: '#F6F7F9',
    },
    helpIcon: {
      height: '14px',
      width: '14px',
      paddingLeft: '2px',
    },
  });
};
