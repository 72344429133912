import { useState } from 'react';

import { Info } from '@material-ui/icons';

import {
  CompanyAdminEventType,
  companyAdminAnalyticsEvent,
} from '../../../../analytics/analyticsEventProperties';
import { ProjectType } from '../../../../generated/graphql';
import useSendAnalytics from '../../../../hooks/useSendAnalytics';
import ProjectTypeSelect from '../../../dragon-scales/ProjectTypeSelect/ProjectTypeSelect';
import { Button, Callout, Dialog, DialogContent, TextInput } from '../../../scales';
import useMemoWrapper from '../../../useMemoWrapper';
import { useUpdateProjectTypeMutation } from '../../CompanyHooks';

import { AlreadyExistText } from './AddProjectSubtypeDialog';
import { filterParentTypes } from './utils';

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
export const hasName = (values: any[] | undefined, currentID: string, value: string) =>
  (values || []).some(
    ({ id, name }) => id !== currentID && name.toLowerCase() === value.toLowerCase()
  );

export type Props = {
  isOpen?: boolean;
  onClose?: () => void;
  subType: ProjectType;
  type: ProjectType;
  types?: ProjectType[];
};

export default function UpdateProjectSubtypeDialog(props: Props) {
  const [parentType, setParentType] = useState<UUID>(props.type.id);
  const [name, setName] = useState(props.subType.name);
  const [errorMsg, setErrorMsg] = useState<string | undefined>();
  const sendAnalytics = useSendAnalytics();
  const parentTypes = useMemoWrapper(filterParentTypes, props.types || []).map((x) => ({
    ...x,
    entries: undefined,
  }));

  const [updateProjectType, { loading }] = useUpdateProjectTypeMutation({
    onCompleted: () => {
      if (errorMsg) setErrorMsg(undefined);
      sendAnalytics(
        companyAdminAnalyticsEvent(CompanyAdminEventType.PROJECT_TYPES_EDIT_SUBTYPE_SAVE, {
          typeName: name,
        })
      );
      props.onClose?.();
    },
    onError: () => {
      setErrorMsg('Something went wrong');
    },
  });
  const disabled = Boolean(!name || errorMsg || loading || !parentType);

  const onClose = () => {
    sendAnalytics(
      companyAdminAnalyticsEvent(CompanyAdminEventType.PROJECT_TYPES_EDIT_SUBTYPE_CLOSE_CANCEL)
    );
    props.onClose?.();
  };

  const onUpdate = () => {
    updateProjectType(props.subType.id, name, parentType);
  };

  const onChange = (newValue: string) => {
    if (errorMsg) setErrorMsg(undefined);
    const isAlreadyExist = hasName(props.types, props.subType.id, newValue);
    if (isAlreadyExist) setErrorMsg(AlreadyExistText);
    setName(newValue);
  };
  return (
    <Dialog
      {...props}
      footerRight={
        <Button
          data-cy="create-button"
          isDisabled={disabled}
          label="Save"
          onClick={onUpdate}
          type="primary"
        />
      }
      isNotDismissable={loading}
      onClose={onClose}
      title="Edit Project Subtype"
    >
      <DialogContent className="flex flex-col gap-2">
        <ProjectTypeSelect
          entries={parentTypes}
          isSearchable
          label="Project Type Related To *"
          onChange={(typeID: UUID) => setParentType(typeID)}
          value={parentType}
        />
        <TextInput
          data-cy="project-subtype-text-input"
          errorMessage={errorMsg}
          label="Project Subtype Name *"
          onChange={onChange}
          placeholder="Project Subtype Name"
          value={name}
        />
        <Callout isFullWidth>
          <Info className="mb-0.5" fontSize="inherit" />
          <div className="inline pb-1 pl-1 type-label">
            Editing the Project Subtype Name will update all existing Projects using this subtype
            name.
          </div>
        </Callout>
      </DialogContent>
    </Dialog>
  );
}
