import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';

const ItemSidebarTextFieldStyles = (theme: KomodoTheme) =>
  createStyles({
    container: { display: 'flex', gap: '4px' },
    disabled: {
      color: theme.palette.app.disabledText,
    },
    textContent: {
      ...theme.typography.webParagraph,
    },
  });

export default ItemSidebarTextFieldStyles;
