import { FC } from 'react';

import { AssistantPhoto } from '@material-ui/icons';

import { itemEstimateHistoryHistoricalMilestoneID } from '../../../api/apollo/reactiveVars';
import { ItemPreviousMilestone } from '../../../utilities/items';
import { ItemStatusIcon } from '../../dragon-scales';
import { getItemDescription } from '../../Items/ItemActivityFeed/ItemActivityFeedUtils';
import ItemEstimateCollapse from '../../Items/ItemsCollapse/ItemEstimateCollapse';
import { GridVariant } from '../../JoinGrid/types';
import { Button, Dialog } from '../../scales';

import DialogNavigation from './DialogNavigation';

type Props = {
  activeMilestoneID?: UUID;
  historicalMilestoneID?: UUID;
  historicalMilestoneIDs: UUID[];
  item: ItemLike;
  itemPreviousMilestone?: ItemPreviousMilestone;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  loading: boolean;
  projectID: UUID;
  refetch: () => void;
};

const DialogsItemEstimateHistory: FC<Props> = ({
  activeMilestoneID,
  historicalMilestoneID,
  historicalMilestoneIDs,
  item,
  itemPreviousMilestone,
  loading,
  projectID,
  refetch,
}) => {
  if (!itemPreviousMilestone) return null; // itemPreviousMilestone shouldn't be null, but it's possible given how the code is written

  const { status, milestone } = itemPreviousMilestone;

  return (
    <Dialog
      footerRight={
        <Button
          label="Close"
          onClick={() => itemEstimateHistoryHistoricalMilestoneID(undefined)}
          type="primary"
        />
      }
      isFullHeight
      isOpen={!!historicalMilestoneIDs}
      onClose={() => itemEstimateHistoryHistoricalMilestoneID(undefined)}
      size="lg"
      title="Item Estimate"
    >
      {historicalMilestoneIDs.length > 1 && (
        <DialogNavigation
          shiftViewer={(direction: number) => {
            // direction = +1 for right and -1 for left
            const currentIndex = historicalMilestoneIDs.findIndex(
              (id) => id === historicalMilestoneID
            );
            if (currentIndex !== -1) {
              let nextIndex = currentIndex + direction;
              if (nextIndex >= historicalMilestoneIDs.length) {
                nextIndex = 0;
              } else if (nextIndex < 0) {
                nextIndex = historicalMilestoneIDs.length - 1;
              }
              itemEstimateHistoryHistoricalMilestoneID(historicalMilestoneIDs[nextIndex]);
            }
          }}
        />
      )}
      <div className="mx-6 my-2">
        <div className="mb-6 flex">
          <ItemStatusIcon value={status} />
          <div className="ml-1">
            <div className="type-heading3">#{getItemDescription(item.number, item.name)}</div>
            <div className="type-body1">
              {milestone?.id === activeMilestoneID ? (
                <div className="flex gap-1">
                  <AssistantPhoto className="text-entities-milestone" />
                  {milestone?.name}
                </div>
              ) : (
                milestone?.name
              )}
            </div>
          </div>
        </div>
        <ItemEstimateCollapse
          key={historicalMilestoneID} // this will unmount the component when changing the al milestone via the left and right arrows.  We need to remount the grid when switching estimates or it will not update all the columns & lines
          gridVariant={GridVariant.READ_ONLY}
          isAllExpandedByDefault={!!item}
          isExpanded
          isOption={'parentID' in item && !!item.parentID}
          isReadOnly
          item={item}
          itemStatus={item.status}
          loading={loading}
          milestoneID={historicalMilestoneID}
          projectID={projectID}
          refetch={refetch}
          setIsExpanded={() => {}}
          setOpenDialogAddOption={() => {}}
        />
      </div>
    </Dialog>
  );
};

export default DialogsItemEstimateHistory;
