import { FC, useEffect } from 'react';

import { useReactiveVar } from '@apollo/client';
import { Bookmarks } from '@material-ui/icons';

import { forecastingReportHasUnsavedChangesVar } from '../../../api/apollo/reactiveVars';
import { PROJECT_COMP_SAVE_PANEL } from '../../../tagConstants';
import IconMenu from '../../Select/SelectMenu/IconMenu';
import { PC_SAVE_ACTION } from '../ProjectCompUtils';

type ProjectCompsInputsSavePanelProps = {
  hasExistingReport: boolean;
  onSaveAction: (action: PC_SAVE_ACTION) => void;
};

const ProjectCompsInputsSavePanel: FC<ProjectCompsInputsSavePanelProps> = ({
  hasExistingReport,
  onSaveAction,
}) => {
  const hasUnsavedChanges = useReactiveVar(forecastingReportHasUnsavedChangesVar);

  useEffect(() => {
    if (hasUnsavedChanges) {
      const onLeave = (e: BeforeUnloadEvent) => {
        // returnValue needs to be set for the popup to show, but the provided value won't be used
        e.returnValue = 'If you leave before saving, your changes will be lost.';
      };
      window.addEventListener('beforeunload', onLeave);
      return () => window.removeEventListener('beforeunload', onLeave);
    }
    return undefined;
  }, [hasUnsavedChanges]);

  const options = [
    {
      callback: () => onSaveAction(PC_SAVE_ACTION.SAVE),
      name: 'Save',
      disabled: !hasExistingReport || !hasUnsavedChanges,
    },
    {
      callback: () => onSaveAction(PC_SAVE_ACTION.SAVE_AS),
      name: 'Save as...',
    },
    {
      callback: () => onSaveAction(PC_SAVE_ACTION.SAVE_AS_COPY),
      name: 'Save a copy...',
      disabled: !hasExistingReport,
    },
  ];

  return (
    <IconMenu
      cy={PROJECT_COMP_SAVE_PANEL}
      icon={<Bookmarks />}
      isBottomOriented
      options={options}
    />
  );
};

export default ProjectCompsInputsSavePanel;
