import * as React from 'react';

interface IObserverContext {
  projectCompIntersectionObserver?: IntersectionObserver;
  setActivateFooterPortal: React.Dispatch<React.SetStateAction<boolean>>;
  setActivateGridPortal: React.Dispatch<React.SetStateAction<boolean>>;
  showStickyCostsFooter?: boolean;
  showStickyCostsHeader?: boolean;
  showStickyProjectName?: boolean;
}

const ObserverContext = React.createContext<IObserverContext>({
  projectCompIntersectionObserver: undefined,
  setActivateFooterPortal: () => {},
  setActivateGridPortal: () => {},
  showStickyCostsFooter: false,
  showStickyCostsHeader: false,
  showStickyProjectName: false,
});

export default ObserverContext;
