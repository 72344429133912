import { LoadProjectRolesQuery, LoadProjectRolesQueryVariables } from '../generated/graphql';

import { loadProjectRolesQuery } from './queries';
import { QueryHookOptions, useQuery } from './useMountAwareQuery';
import { MountPolicy } from './usePolicyOnFirstMount';

function useRolesQuery(
  projectId?: UUID,
  mountPolicy?: MountPolicy,
  options?: QueryHookOptions<LoadProjectRolesQuery, LoadProjectRolesQueryVariables>
) {
  return useQuery<LoadProjectRolesQuery, LoadProjectRolesQueryVariables>(
    loadProjectRolesQuery,
    {
      variables: { projectId },
      skip: !projectId,
      ...options,
    },
    mountPolicy
  );
}

export default useRolesQuery;
