import { ReactNode } from 'react';
import { Link } from 'react-router-dom';

import { navEventTypes } from '../../../analytics/analyticsEventProperties';
import { JoinRoutes } from '../../../api/gqlEnums';
import useProjectPropsQuery from '../../../hooks/useProjectPropsQuery';
import { RouteKeys } from '../../../routes/paths';
import { generateSharedPath } from '../../../utilities/routes/links';
import { isNonNullable } from '../../../utilities/types';
import useSendNavAnalytics from '../hooks/useSendNavAnalytics';
import { ProjectsIcon } from '../icons';

import NavItem from './NavItem';
import ProjectTitle from './ProjectTitle';

type Props = {
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  expanded?: boolean;
  lastViewedProjectIDs: UUID[];
};

export default function ProjectsNavItem(props: Props) {
  const sendNavAnalytics = useSendNavAnalytics();

  const projects = [
    useProjectPropsQuery(props.lastViewedProjectIDs[1]).data.project,
    useProjectPropsQuery(props.lastViewedProjectIDs[2]).data.project,
    useProjectPropsQuery(props.lastViewedProjectIDs[3]).data.project,
  ].filter(isNonNullable);

  let tooltip: ReactNode | undefined;
  if (projects.length) {
    tooltip = (
      <div className="flex w-60 flex-col gap-4 p-2" data-cy="project-switcher">
        <div className="type-heading3">Recent Projects</div>
        {projects.map((project, index) => (
          <Link
            key={project.id}
            className="cursor-pointer"
            onClick={() => sendNavAnalytics(navEventTypes.RECENT_PROJECT_CTA, { index })}
            to={generateSharedPath(JoinRoutes.PROJECT, {
              projectId: project.id,
            })}
          >
            <ProjectTitle project={project} />
          </Link>
        ))}
      </div>
    );
  } else if (!props.expanded) {
    tooltip = 'Projects';
  }

  return (
    <NavItem
      icon={<ProjectsIcon />}
      label={props.expanded ? 'Projects' : undefined}
      path={generateSharedPath(RouteKeys.SEARCH_PROJECTS)}
      tooltip={tooltip}
      tooltipPlacement={projects.length ? 'right top' : undefined}
    />
  );
}
