import { KeyboardBackspace } from '@material-ui/icons';

import {
  inAppNotificationsAnalyticsEvent,
  inAppNotificationsEventTypes,
} from '../../../analytics/analyticsEventProperties';
import {
  IN_APP_NOTIFICATIONS_PAGINATION_PLUS_SIGN_AFTER,
  PAGINATION_DEFAULT_LIMIT,
} from '../../../constants';
import useSendAnalytics from '../../../hooks/useSendAnalytics';
import { getPageCounterText } from '../../../utilities/pagination';
import { Button } from '../../scales';

type Props = {
  currentPage: number;
  onViewNextPage?: () => void;
  onViewPreviousPage?: () => void;
  totalNotificationsCount: number;
};

export default function InAppNotificationsFooter(props: Props) {
  const sendAnalytics = useSendAnalytics();

  if (!props.totalNotificationsCount) return null;
  if (!props.onViewPreviousPage && !props.onViewNextPage) return null;

  return (
    <div className="flex w-full items-center">
      <Button
        data-cy="pagination-navigation-previous-button"
        isDisabled={!props.onViewPreviousPage}
        label="Previous"
        onClick={() => {
          props.onViewPreviousPage?.();
          sendAnalytics(
            inAppNotificationsAnalyticsEvent(
              inAppNotificationsEventTypes.IN_APP_NOTIFICATIONS_PREVIOUS
            )
          );
        }}
        startIcon={<KeyboardBackspace />}
        type="tertiary"
      />
      <div className="grow text-center type-body2" data-cy="pagination-counter">
        {getPageCounterText(
          props.currentPage,
          props.totalNotificationsCount,
          PAGINATION_DEFAULT_LIMIT,
          IN_APP_NOTIFICATIONS_PAGINATION_PLUS_SIGN_AFTER
        )}
      </div>
      <Button
        data-cy="pagination-navigation-next-button"
        endIcon={<KeyboardBackspace className="rotate-180" />}
        isDisabled={!props.onViewNextPage}
        label="Next"
        onClick={() => {
          props.onViewNextPage?.();
          sendAnalytics(
            inAppNotificationsAnalyticsEvent(inAppNotificationsEventTypes.IN_APP_NOTIFICATIONS_NEXT)
          );
        }}
        type="tertiary"
      />
    </div>
  );
}
