import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';

const CompanyTabStyles = (theme: KomodoTheme) =>
  createStyles({
    tabContainer: {
      fontSize: 24,
      borderTop: theme.border.line,
      borderBottom: theme.border.line,
      background: theme.palette.backgroundWhite,
    },
  });

export default CompanyTabStyles;
