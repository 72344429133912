import { ReactNode } from 'react';
import { Button, Dialog } from '../../../scales';

type DialogProps = {
  /** Hex color code to use when rendering an accent border */
  accentColor?: string;
  children: ReactNode;
  'data-cy'?: string;
  description?: string;
  /** Nodes rendered in the footer must have a height <= 40px */
  footerCenter?: ReactNode;
  /** Nodes rendered in the footer must have a height <= 40px */
  footerLeft?: ReactNode;
  /** Nodes rendered in the footer must have a height <= 40px */
  footerRight?: ReactNode;
  image?: ReactNode;
  isFullHeight?: boolean;
  isNotDismissable?: boolean;
  isOpen?: boolean;
  onClose?: () => void;
  /** A decimal number between 0 and 1 */
  progressPercent?: number;
  size?: 'md' | 'lg';
  title: ReactNode;
};

export type Props = DialogProps & {
  onShare: () => void;
  isShareDisabled: boolean;
  shareLabel: string;
  summaryText: string;
  selectedText: string;
};

export default function ShareDialog(props: Props) {
  return (
    <Dialog
      {...props}
      footerLeft={
        props.footerLeft ? (
          props.footerLeft
        ) : (
          <div className="type-heading3" data-cy="share-dialog-footer-status">
            {props.selectedText}
          </div>
        )
      }
      footerRight={
        props.footerRight ? (
          props.footerRight
        ) : (
          <div className="flex items-center gap-5">
            <div className="type-heading3" data-cy="share-dialog-footer-update-message">
              {props.summaryText}
            </div>
            <Button
              data-cy="share-button"
              isDisabled={props.isShareDisabled}
              label={props.shareLabel}
              onClick={props.onShare}
              type="primary"
            />
          </div>
        )
      }
      isFullHeight
      isNotDismissable={false}
      onClose={props.onClose}
      size="lg"
      title={props.title}
    >
      {props.children}
    </Dialog>
  );
}
