import { gql } from '../../api/graphqlFragments';

const alternateFields = gql`
  fragment alternateFields on Alternate {
    id
    imageURL
    uniFormat {
      id
      number
      name
    }
    masterFormat {
      id
      number
      name
    }
    categories
    title
    name
    otherNames
    description
    characteristics
    advantages
    disadvantages
    idealConditions
    costRangeHigh
    costRangeLow
    weight
  }
`;

export const ideaFields = gql`
  fragment ideaFields on Idea {
    id
    name
    comparisonSummary
    root {
      ...alternateFields
    }
    alternates {
      ...alternateFields
    }
    relevance {
      cost
      lines {
        description
        matchingTerms
        total
      }
    }
  }
  ${alternateFields}
`;

export const ideaGroupFields = gql`
  fragment ideaGroupFields on IdeaGroup {
    name
    ideas {
      ...ideaFields
    }
  }
  ${ideaFields}
`;
