import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';

const statusIconPadding = 4;

export default (theme: KomodoTheme) =>
  createStyles({
    bold: {
      fontWeight: 700,
    },
    ellipsis: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    inline: {
      display: 'inline-flex',
      alignItems: 'center',
    },
    name: {
      fontSize: 14,
    },
    number: {
      ...theme.typography.number,
    },
    spacer: {
      flexGrow: 1,
    },
    text: {
      fontFamily: theme.typography.fontFamily,
    },
    statusIcon: {
      paddingLeft: statusIconPadding,
      paddingRight: statusIconPadding,
    },
    rowSpacer: {
      color: theme.palette.joinPrimary,
      marginBottom: 2,
      marginTop: 2,
    },
  });
