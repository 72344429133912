import { ContingenciesTimeseries } from '../../../generated/graphql';
import { RouteKeys } from '../../../routes/paths';
import { generateSharedPath } from '../../../utilities/routes/links';
import { InsightsProject, InsightsTabId } from '../types';

export const IS_ZOOM = false;

export const getProjectsRange = (date: Date, monthCount = 6): [Date, Date] => {
  // Date 6 months before
  const monthsBefore = new Date(date);
  monthsBefore.setMonth(monthsBefore.getMonth() - monthCount);
  // Date 6 months after
  const monthsAfter = new Date(date);
  monthsAfter.setMonth(monthsAfter.getMonth() + monthCount);
  return [monthsBefore, monthsAfter];
};

export const getCandAPieCharData = (
  data: ContingenciesTimeseries | null,
  project: InsightsProject
) => {
  const pieChartData: { name: string; share: number }[] = [];
  const colors: string[] = [];

  if (!data || !project.estimate || project.estimate === 0) {
    return {
      pieChartData: [],
      caTotalPct: '0%',
      colors: [],
    };
  }

  const contingencyTotal = Number(data.contingencyTotal);
  const allowanceTotal = Number(data.allowanceTotal);
  const estimateTotal = Number(project.estimate);

  const addSegment = (name: string, value: number, color: string) => {
    if (value) {
      const share = value;
      pieChartData.push({ name, share });
      colors.push(color);
      return value;
    }
    return 0;
  };
  // Calculate C&A values
  const contingencyValue = addSegment(
    'Contingency',
    contingencyTotal,
    'var(--colors-entities-contingencies)'
  );
  const allowanceValue = addSegment(
    'Allowance',
    allowanceTotal,
    'var(--colors-entities-allowances)'
  );
  // Calculate Totals
  const totalCAValue = Number(contingencyValue) + Number(allowanceValue);
  const remainingValue = estimateTotal - totalCAValue;

  if (remainingValue > 0) {
    pieChartData.push({
      name: 'Remaining',
      share: Number(remainingValue),
    });
    colors.push('var(--colors-item-status-not-applicable-tint)');
  }

  const caTotalPct = formatPercentage(totalCAValue, estimateTotal);

  return { pieChartData, caTotalPct, colors };
};

export const formatPercentage = (count: number, total: number) => {
  if (Number(count) === 0 || Number(total) === 0) return '0%';
  const percentage = Math.floor((100 * count) / total);
  return `${percentage < 1 ? '<1' : percentage.toFixed(0)}%`;
};

export const generateProjectPath = (projectId: string, tabId?: InsightsTabId) => {
  const path = {
    pathname: generateSharedPath(RouteKeys.INSIGHTS_PROJECT, { projectId }),
  };

  if (tabId) {
    return { ...path, hash: `#${tabId}` };
  }

  return path;
};
