import { isUUID } from 'validator';

import { CLEAR_MEETINGS } from '../../../constants';
import {
  capitalizeString,
  commaSeparatedString,
  pluralizeCountString,
  pluralizeWord,
} from '../../../utilities/string';

export const formatBulkItemsUpdateInput = (
  itemsLikeIDsSelected: UUID[],
  input: ItemsListBulkEditingInput
) => {
  const {
    milestoneID,
    status,
    meetingID,
    clearMeetingID,
    assigneeEmail,
    clearAssigneeEmail,
    dueDate,
    addCategories,
    clearCategories,
  } = input;
  const bulkItemsUpdateInput: BulkItemsUpdateInput = {
    itemIDs: itemsLikeIDsSelected,
    milestoneID: milestoneID.length > 0 ? milestoneID : undefined,
    status,
    meetingID: meetingID.length > 0 && meetingID !== CLEAR_MEETINGS ? meetingID : undefined,
    clearMeetingID: meetingID === CLEAR_MEETINGS || clearMeetingID === true,
    assigneeEmail: !clearAssigneeEmail && assigneeEmail.length !== 0 ? assigneeEmail : undefined,
    clearAssigneeEmail: assigneeEmail.length === 0 && clearAssigneeEmail,
    dueDate,
    addCategories:
      addCategories && addCategories.length > 0
        ? addCategories.map((c) => ({
            categorizationID: (c.categorization && c.categorization.id) || '',
            id: c.id,
          }))
        : undefined,
    clearCategories: clearCategories && clearCategories.length > 0 ? clearCategories : undefined,
  };
  return bulkItemsUpdateInput;
};

export const calculateBulkItemUpdateAnalytics = (input: ItemsListBulkEditingInput) => {
  const {
    addCategories,
    assigneeEmail,
    clearAssigneeEmail,
    clearCategories,
    clearMeetingID,
    dueDate,
    meetingID,
    milestoneID,
    status,
  } = input;
  const updatedProperties: string[] = [] as string[];
  if (addCategories.length !== 0) {
    updatedProperties.push('addCategories');
  }
  if (assigneeEmail.length !== 0) {
    updatedProperties.push('assigneeEmail');
  }
  if (clearAssigneeEmail) {
    updatedProperties.push('clearAssigneeEmail');
  }
  if (clearCategories && clearCategories.length !== 0) {
    updatedProperties.push('clearCategories');
  }
  if (clearMeetingID) {
    updatedProperties.push('clearMeetingID');
  }
  if (dueDate) {
    updatedProperties.push('dueDate');
  }
  if (isUUID(meetingID)) {
    updatedProperties.push('meetingID');
  }
  if (isUUID(milestoneID)) {
    updatedProperties.push('milestoneID');
  }
  if (status) {
    updatedProperties.push('status');
  }
  return updatedProperties;
};

const calculateItemOptionsCountSummaryString = (
  optionCount: number,
  itemLikeCount: number
): string => {
  const itemsLikeString =
    itemLikeCount === 0 ? '' : `${pluralizeCountString('item', itemLikeCount)}`;
  const optionsString =
    optionCount === 0
      ? ''
      : `${itemsLikeString.length === 0 ? '' : 'and'} ${pluralizeCountString(
          'option',
          optionCount
        )}`;
  return `${itemsLikeString} ${optionsString}`;
};

export const calculateBulkItemInputUpdatesString = (input: ItemsListBulkEditingInput) => {
  const updatedProperties: string[] = [] as string[];
  const {
    addCategories,
    assigneeEmail,
    clearAssigneeEmail,
    clearCategories,
    clearMeetingID,
    dueDate,
    meetingID,
    milestoneID,
    status,
  } = input;

  if (assigneeEmail.length !== 0 || clearAssigneeEmail) {
    updatedProperties.push('assignee');
  }
  if (dueDate) {
    updatedProperties.push('due date');
  }
  if (status) {
    updatedProperties.push('status');
  }
  if (isUUID(milestoneID)) {
    updatedProperties.push('milestone');
  }
  if (isUUID(meetingID) || clearMeetingID) {
    updatedProperties.push('meeting');
  }
  if (addCategories.length !== 0 || (clearCategories && clearCategories.length !== 0)) {
    updatedProperties.push(
      pluralizeCountString('category', (clearCategories?.length ?? 0) + addCategories.length)
    );
  }
  let count: number = updatedProperties.length;
  if (
    count === 1 &&
    (addCategories.length !== 0 || (clearCategories && clearCategories.length !== 0))
  ) {
    count = clearCategories ? clearCategories.length + addCategories.length : addCategories.length;
  }
  const updatedSummary = commaSeparatedString(updatedProperties);
  return {
    updatedSummary: capitalizeString(updatedSummary),
    count,
  };
};

export const calculateBulkUpdateToastMessage = (
  itemCount: number,
  optionCount: number,
  input: ItemsListBulkEditingInput
) => {
  const itemsLikeOptionsString = calculateItemOptionsCountSummaryString(optionCount, itemCount);
  const { updatedSummary, count } = calculateBulkItemInputUpdatesString(input);

  return `${updatedSummary} ${pluralizeWord(
    'was',
    count
  )} successfully updated for ${itemsLikeOptionsString}`;
};
