import { FC, KeyboardEvent } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';

import { withStyles } from '@material-ui/core';

import DashboardAnchorStyles from './DashboardAnchorStyles';

type DashboardAnchorProps = {
  anchor: string;
  classes: Classes<typeof DashboardAnchorStyles>;
};

// HELPER
const cleanAnchor = (str: string) => str.replace(/\s/g, '').toLowerCase();

const onAction = (navigate: NavigateFunction, anchor: string) => {
  const idAnchor = `#${anchor}`;
  const el = document.querySelector(idAnchor);
  if (el) {
    el.scrollIntoView();
  }
  if (navigate) {
    navigate({ hash: idAnchor });
  } else {
    window.location.hash = idAnchor;
  }
};

const DashboardAnchor: FC<DashboardAnchorProps> = ({ anchor, classes }) => {
  const navigate = useNavigate();

  return (
    <div
      className={classes.link}
      onClick={() => {
        onAction(navigate, cleanAnchor(anchor));
      }}
      onKeyDown={(e: KeyboardEvent) => {
        if (e.key === 'Enter') {
          onAction(navigate, cleanAnchor(anchor));
        }
      }}
      role="button"
      tabIndex={-1}
    >
      <h1 className="type-heading2" id={cleanAnchor(anchor)}>
        {anchor}
      </h1>
    </div>
  );
};

export default withStyles(DashboardAnchorStyles)(DashboardAnchor);
