import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { JoinCompanyOrganizationsRoutes, JoinCompanyRoutes } from '../../../../api/gqlEnums';
import { MountPolicy } from '../../../../hooks/usePolicyOnFirstMount';
import { generateSharedPath } from '../../../../utilities/routes/links';

import useOrganizationsQuery from './useOrganizationsQuery';

/**
 * Go to organization route if no organization is selected
 * @param companyID UUID
 */
export default function useNavigateToDefaultOrganizationRoute(companyID: UUID) {
  const organizationsQueryResult = useOrganizationsQuery(companyID, MountPolicy.SKIP_ON_MOUNT);
  const organizationIDs = organizationsQueryResult.data?.organizations?.map(({ id }) => id);
  const toGoOrganizationId = organizationIDs?.[0];
  let { organizationId } = useParams();
  if (organizationId && !organizationIDs?.includes(organizationId)) {
    organizationId = undefined;
  }
  const navigate = useNavigate();
  useEffect(() => {
    // Organization is already selected
    if (organizationId) return;
    // Organizations array is empty - navigate to the root path
    if (!toGoOrganizationId && !organizationIDs?.length) {
      const route = generateSharedPath(JoinCompanyRoutes.COMPANY_ORGANIZATIONS);
      navigate(route, { replace: true });
      return;
    }
    // No organizations yet
    if (!toGoOrganizationId) return;
    const route = generateSharedPath(JoinCompanyOrganizationsRoutes.COMPANY_ORGANIZATION, {
      organizationId: toGoOrganizationId,
    });
    // Navigate
    navigate(route, { replace: true });
  }, [toGoOrganizationId, organizationId, navigate, organizationIDs?.length]);
}
