import { ListCategoriesQuery, ListCategoriesQueryVariables } from '../../../../generated/graphql';
import { useQuery } from '../../../../hooks/useMountAwareQuery';
import { MountPolicy } from '../../../../hooks/usePolicyOnFirstMount';

import { listCategoriesQuery } from './queries';

function useListTopLevelCategoryQuery(categorizationID: UUID, term: string, limit: number) {
  return useQuery<ListCategoriesQuery, ListCategoriesQueryVariables>(
    listCategoriesQuery,
    {
      variables: { categorizationID, term, limit, level: 1 },
      skip: !categorizationID,
    },
    MountPolicy.SKIP_ON_MOUNT
  );
}

export default useListTopLevelCategoryQuery;
