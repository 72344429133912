import { useState } from 'react';

import { CostTableColumnInput, InputMaybe } from '../../../../generated/graphql';
import { Button, Dialog, DialogContent } from '../../../scales';
import { useProjectCompsSetInputUpdateFunctions } from '../../ProjectCompsSetInputStore/ProjectCompsSetInputUpdaters';

import EditCostGridsCheckboxes from './EditCostGridsCheckboxes';
import EditCostGridsColumns from './EditCostGridsColumns';
import {
  MAX_NUM_COL_INPUTS,
  columnInputIsSelected,
  columnInputsMatch,
  getGridColumnsDefault,
} from './EditCostGridsUtils';

type Props = {
  costTableColumnInputs?: InputMaybe<CostTableColumnInput[]>;
  onCloseDialog: () => void;
  pinnedUnitID: UUID | undefined;
  units?: Unit[];
};

export default function EditCostGridsDialog(props: Props) {
  const { setProjectCompsSetCostTableColumnInputs } = useProjectCompsSetInputUpdateFunctions();

  const [selectedColumnInputs, setSelectedColumnInputs] = useState<CostTableColumnInput[]>(
    props.costTableColumnInputs ?? getGridColumnsDefault(props.pinnedUnitID)
  );

  const onSelect = (columnInput: CostTableColumnInput) =>
    setSelectedColumnInputs((prevState) => {
      if (columnInputIsSelected(prevState, columnInput)) {
        return prevState.filter((s) => !columnInputsMatch(s, columnInput));
      }
      return [...prevState, columnInput];
    });

  const onReset = () => {
    setSelectedColumnInputs(getGridColumnsDefault(props.pinnedUnitID));
  };

  const onClose = () => {
    props.onCloseDialog();
  };

  const onApply = () => {
    setProjectCompsSetCostTableColumnInputs(selectedColumnInputs);
    props.onCloseDialog();
  };

  return (
    <Dialog
      footerRight={
        <Button
          isDisabled={selectedColumnInputs?.length < MAX_NUM_COL_INPUTS}
          label="Apply"
          onClick={onApply}
          type="primary"
        />
      }
      isOpen
      onClose={onClose}
      size="lg"
      title="Edit cost grids"
    >
      <DialogContent>
        <div className="flex flex-col gap-6">
          <div className="type-paragraph">
            Make selections for which data appears in the project cost grids.
          </div>
          <div className="flex flex-col items-start gap-1">
            <button className="type-small-link" onClick={onReset}>
              Reset to default
            </button>
            <div className="type-paragraph">
              {`${selectedColumnInputs?.length ?? 0}/${MAX_NUM_COL_INPUTS} selected`}
            </div>
          </div>
          <div className="flex gap-2">
            <EditCostGridsCheckboxes
              onSelect={onSelect}
              selectedColumnInputs={selectedColumnInputs}
              units={props.units}
            />
            <EditCostGridsColumns selectedColumnInputs={selectedColumnInputs} units={props.units} />
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
