import { Fragment, ReactNode, useState } from 'react';

import { Search } from '@material-ui/icons';

import { TreeEntry, flatten, onSelectLevels } from '../../../utilities/utilities';
import { Checkbox, TextInput } from '../../scales';
import ExpandableFilterContainer from '../common/ExpandableFilterContainer';

import { calculateSelectAllLabel } from './FilterGroupUtils';

export const adornment = (depth: number) => <div style={{ paddingLeft: 24 * depth }} />;

export type SelectLevelsEntryExtra = {
  count?: number;
};

export type SelectLevelsEntry = TreeEntry<SelectLevelsEntryExtra>;

export type Props = {
  'data-cy'?: string;
  entries: SelectLevelsEntry[];
  isAllSelectable?: boolean;
  isSearchable?: boolean;
  onChange: (value: string[]) => void;
  searchPlaceholder?: string;
  selectedEntries: string[];
  title: string;
};

export default function FilterGroupLevels(props: Props) {
  const [searchString, setSearchString] = useState('');

  const isInSearch = (entry: SelectLevelsEntry) =>
    entry.label?.toLowerCase().includes(searchString.toLowerCase());

  const isSelected = (entry: SelectLevelsEntry) => props.selectedEntries.includes(entry.id);

  const handleCheck = (entry: SelectLevelsEntry) => {
    const filtered = onSelectLevels(entry, props.selectedEntries);
    props.onChange(filtered as string[]);
  };

  const flattenedEntries = flatten(props.entries);
  const hasEntries = flattenedEntries.length > 0;
  const hasSelectedEntries = props.selectedEntries.length > 0;
  const hasAllSelected = flattenedEntries.length === props.selectedEntries.length;

  const handleSelectAll = () => {
    if (hasAllSelected) {
      props.onChange([]);
    } else {
      props.onChange(flattenedEntries.map((e) => e.id));
    }
  };

  const renderEntry = (
    entry: SelectLevelsEntry,
    parentID: UUID | null,
    depth: number
  ): ReactNode => {
    const passFilter = isInSearch(entry);
    const selected = isSelected(entry);
    const entryEl = (
      <div key={entry.id} className="flex">
        {parentID && adornment(depth)}
        <Checkbox isSelected={selected} onChange={() => handleCheck(entry)}>
          <div className="flex w-full">
            <div data-cy={`filterGroupLevels-${entry.label}`}>{entry.label}</div>
            <div className="ml-auto">{entry.count}</div>
          </div>
        </Checkbox>
      </div>
    );
    return (
      <Fragment key={entry.id}>
        {passFilter && entryEl}
        {entry.entries?.map((e) => renderEntry(e, entry.id, depth + 1))}
      </Fragment>
    );
  };

  return (
    <ExpandableFilterContainer
      canReset={props.selectedEntries.length > 0}
      count={props.selectedEntries.length}
      data-cy={props['data-cy']}
      onReset={() => props.onChange([])}
      title={props.title}
    >
      {props.isSearchable && (
        <div className="mb-4">
          <TextInput
            aria-label="filter locations"
            onChange={setSearchString}
            placeholder={props.searchPlaceholder ?? 'Filter entries'}
            startAdornment={<Search />}
            value={searchString}
          />
        </div>
      )}
      {props.isAllSelectable && hasEntries && (
        <div className="border-b-2 pb-1">
          <Checkbox
            key="selectAll"
            isIndeterminate={!hasAllSelected && hasSelectedEntries}
            isSelected={hasAllSelected || hasSelectedEntries}
            onChange={() => handleSelectAll()}
          >
            <div>{calculateSelectAllLabel(props.selectedEntries, flattenedEntries)}</div>
          </Checkbox>
        </div>
      )}
      <div className="flex max-h-[280px] flex-col gap-2 overflow-auto pr-2 pt-2">
        {props.entries.map((entry) => renderEntry(entry, null, 0))}
      </div>
    </ExpandableFilterContainer>
  );
}
