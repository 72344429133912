import { useMutation } from '@apollo/client';

import { REFETCH_SCENARIO_SANDBOX } from '../../../api/refetchSets';

import { removeScenarioFromSandboxMutation } from './queries';

export const useRemoveScenarioFromSandbox = () => {
  const [removeScenarioFromSandbox] = useMutation(removeScenarioFromSandboxMutation, {
    refetchQueries: REFETCH_SCENARIO_SANDBOX,
  });

  const removeFn = (scenarioID: UUID, milestoneID: UUID) => {
    removeScenarioFromSandbox({
      variables: {
        scenarioID,
        milestoneID,
      },
    });
  };

  return [removeFn];
};
