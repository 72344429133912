import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';

export default (theme: KomodoTheme) =>
  createStyles({
    activeContainer: {
      background: theme.palette.backgroundGrey,
    },
    controlContainer: {
      padding: '2px 8px 4px 8px',
    },
    date: {
      '@media print': {
        width: 140,
      },
      '& svg': {
        '@media print': {
          display: 'none',
        },
      },
      background: theme.palette.backgroundWhite,
      border: theme.palette.backgroundWhite,
      borderRadius: theme.border.radius,
    },
    datePickerIcon: {
      width: 36,
      height: 36,
      marginTop: 1,
      color: theme.palette.joinPrimary,
      '&:hover': {
        backgroundColor: 'inherit',
      },
    },
    error: {
      display: 'none',
    },
    fullWidth: {
      width: '100%',
    },
    picker: {
      ...theme.typography.body1,
      ...theme.typography.number,
      fontWeight: 300,
      fontSize: 14,
      padding: 6,
      cursor: 'pointer',
    },
  });
