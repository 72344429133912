import useUserByIdQuery from '../../Collaborators/hooks/useUserByIdQuery';

export type Props = {
  user: Pick<UserLink, 'id' | 'name'>;
};

export default function UserEmail(props: Props) {
  const { data, loading } = useUserByIdQuery(props.user.id);
  if (!data?.userByID || loading) return null;
  const email = data.userByID?.email;
  return (
    <div>
      {props.user.name} (
      <a className="type-link" href={`mailto:${email}`} target="_top">
        {email}
      </a>
      )
    </div>
  );
}
