import { useState } from 'react';

import { ToastType } from '../../../api/gqlEnums';
import { setToast } from '../../../hooks/useToastParametersLocalQuery';
import { useCurrentUser } from '../../contexts/current-user';
import { Button, Dialog, DialogContent, TextArea } from '../../scales';
import useReportItemAssistExperimentRequest from '../../ve-item-details/ItemAssistantConfirmationDialog/useReportItemAssistExperimentRequest';

type Props = {
  brandName: string;
  projectID: UUID;
  itemID?: UUID;
  onSubmit: () => void;
  onClose: () => void;
};

export default function SuggestionDialog(props: Props) {
  const submitFunc = useReportItemAssistExperimentRequest();
  const userEmail = useCurrentUser().email;
  const [additionalInfo, setAdditionalInfo] = useState('');

  const onClick = () => {
    submitFunc(
      props.projectID,
      props.itemID ? props.itemID : props.projectID,
      `brand: ${props.brandName} request: ${additionalInfo}`,
      true
    );
    props.onSubmit();
    setToast(
      {
        message: 'Request submitted!',
      },
      ToastType.SUCCESS
    );
  };
  return (
    <Dialog
      footerLeft={<Button label="No thanks" onClick={props.onClose} type="secondary" />}
      footerRight={<Button label="Submit" onClick={onClick} type="primary" />}
      isOpen
      onClose={props.onClose}
      size="md"
      title={
        <div className="flex items-center gap-4">
          <div>Need information from {props.brandName}?</div>
        </div>
      }
    >
      <DialogContent>
        <div className="flex flex-col gap-4">
          <div className="flex type-body1">
            It looks like this item is related to {props.brandName}. If you would like you can
            submit a request for information. Join will find the appropriate {props.brandName}{' '}
            representative and connect you.
          </div>
          <div>Example requests:</div>
          <div className="pl-6">
            <li>Alternate products</li>
            <li>Product data sheets</li>
            <li>Customization options</li>
            <li>Lead times and availability</li>
          </div>
          <div>Or anything else that would be helpful.</div>
          <div className="flex">
            <TextArea
              onChange={setAdditionalInfo}
              placeholder="What are you looking for?"
              value={additionalInfo}
            />
          </div>
          <div className="flex text-type-muted type-body1">
            Join will provide the above information, along with the project city, to a
            representative of {props.brandName} connect you via email at {userEmail}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
