import { gql } from '../../../api/graphqlFragments';

export const getContingencyReportQuery = gql`
  query getContingencyReport(
    $milestoneID: UUID!
    $projectID: UUID!
    $itemStatuses: [Status!]
    $filteredContingencies: [String!]
    $filteredAllowances: [String!]
  ) {
    contingencyReport(
      milestoneID: $milestoneID
      projectID: $projectID
      itemStatuses: $itemStatuses
      filteredContingencies: $filteredContingencies
      filteredAllowances: $filteredAllowances
    ) {
      name
      contingencyID
      displayType
      startingCost
      usedCost
      totalPendingCost
      totalDrawnCost
      percentOfMilestoneEstimate
      rows {
        itemID
        status
        name
        number
        drawCost
        acceptedBy
        dateAccepted
        createdBy
        dateCreated
      }
    }
  }
`;

export const getAllMilestonesContingencyReportQuery = gql`
  query getAllMilestonesContingencyReport(
    $projectID: UUID!
    $filteredContingencies: [String!]
    $filteredAllowances: [String!]
  ) {
    multiMilestoneContingencyReport(
      projectID: $projectID
      filteredContingencies: $filteredContingencies
      filteredAllowances: $filteredAllowances
    ) {
      milestones {
        name
        startDate
        isActiveMilestone
      }
      contingencies {
        name
        type
        initialAmountSet
        initialPercentOfMilestoneEstimate
        report {
          startingCost
          usedCost
          totalPendingCost
          totalDrawnCost
          percentOfMilestoneEstimate
          acceptedItemCount
          existsInAllMilestonesReportMilestone
        }
      }
    }
  }
`;
