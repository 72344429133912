import { FC } from 'react';

import { Typography, withStyles } from '@material-ui/core';

import { OVERALL_PROJECT } from '../../../constants';
import { categoryLabel } from '../../../utilities/string';
import { getProjectIdFromUrl } from '../../../utilities/url';
import { SetSettingsFunctionType } from '../../../utilities/urlState';
import { SMALL_CHART_HEIGHT } from '../../Charts/ChartsAllMilestones/ChartsAllMilestonesUtils';
import DashboardChartPlaceholder from '../../dashboard/DashboardCharts/DashboardChartsPlaceholder';
import ReportsManagerMenu, {
  ReportManagerAnchors,
} from '../../ReportsTab/ReportsManagerMenu/ReportsManagerMenu';
import SelectTextMenu from '../../Select/SelectTextMenu/SelectTextMenu';
import { PrintPageHeaderWrapper } from '../PrintSharedComponents/PrintPageHeaderWrapper';

import PrintBreakdown from './PrintBreakdown';
import PrintBreakdownsStyles from './PrintBreakdownsStyles';

export type MenuOption = {
  key: string;
  text: string;
  subText: string;
};

type PrintBreakdownsProps = {
  categorization: Categorization;
  chartData: Category[];
  classes: Classes<typeof PrintBreakdownsStyles>;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  loading: boolean;
  menuOptions: MenuOption[];
  milestonesCostTrendsBreakdowns: MilestonesCostTrendsBreakdown[];
  milestoneName: string;
  onChange: (categorizationName: string) => void;
  selected: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  settings: any;
  setSettings: SetSettingsFunctionType;
};

const PrintBreakdowns: FC<PrintBreakdownsProps> = ({
  categorization,
  chartData,
  classes,
  loading,
  menuOptions,
  milestonesCostTrendsBreakdowns,
  milestoneName,
  onChange,
  selected,
  settings,
  setSettings,
}) => {
  const projectId = getProjectIdFromUrl();

  const emptyState = () => (
    <div className={classes.dashboard}>
      <DashboardChartPlaceholder
        emptyMessage="No data"
        height={SMALL_CHART_HEIGHT}
        loading={loading}
      />
    </div>
  );

  const categorySubheading = (category?: Category) => (
    <div className={classes.subheading}>
      {category && <>{categoryLabel(category.name, category.number, category.categorization)}</>}
    </div>
  );

  const charts = loading
    ? chartData.map((category: Category) => (
        <div key={category.id} className={classes.container}>
          {categorySubheading(category)}
          {emptyState()}
        </div>
      ))
    : milestonesCostTrendsBreakdowns.map((breakdown: MilestonesCostTrendsBreakdown) => {
        const { categoryID } = breakdown;
        const id = categoryID || OVERALL_PROJECT;
        const category = chartData.find((elem: Category) => elem.id === id);
        return (
          <PrintBreakdown
            key={id}
            breakdown={breakdown}
            categorySubheading={categorySubheading(category)}
            emptyState={emptyState}
            loading={loading}
          />
        );
      });

  return (
    <div className={classes.root}>
      <div className="print-breakdowns-page">
        <PrintPageHeaderWrapper
          milestoneName={milestoneName}
          projectId={projectId}
          reportName="Category Trends Breakdown"
        />
        <div />
        <div id={ReportManagerAnchors('breakdowns')} />
        <div className={classes.subHeader}>
          <div className={classes.label}>
            <Typography variant="subheading">Breakdown by Category</Typography>
          </div>
          <div className={classes.selector}>
            {selected && categorization && (
              <SelectTextMenu menuOptions={menuOptions} onChange={onChange} selected={selected} />
            )}
          </div>
          <div className={classes.reportIcon}>
            <ReportsManagerMenu
              setSettings={setSettings}
              settings={settings}
              variant="breakdowns"
            />
          </div>
        </div>
        <hr className="print-main-divider" />
        <div className={classes.charts}>{charts}</div>
      </div>
    </div>
  );
};

export default withStyles(PrintBreakdownsStyles)(PrintBreakdowns);
