import { useMemo } from 'react';

import { Aggregation, ForecastingExploreItemsFilters } from '../../../../../generated/graphql';
import { getItemStatusLabel } from '../../../../../utilities/item-status';
import { ListFilterOption } from '../../../../frame/AdvancedFiltersSidebar/FilterGroupUtils';

type KeyOfListTypeFilter = keyof Omit<
  ForecastingExploreItemsFilters,
  'costImpact' | 'milestoneDate'
>;

// Maps filter names to aggregation names.
const AGGREGATION_FIELDS: Record<KeyOfListTypeFilter, string> = {
  milestoneNames: 'MilestoneName',
  projectLocations: 'ProjectLocation',
  projectNames: 'ProjectName',
  projectTypes: 'ProjectType',
  statuses: 'Status',
  categoryNumbers: 'CategoryNumbers',
};

export const useSearchItemsFilterOptions = (
  aggregations: Aggregation[] | undefined,
  appliedFilters: ForecastingExploreItemsFilters
): Record<KeyOfListTypeFilter, ListFilterOption[]> | undefined => {
  return useMemo(() => {
    if (!aggregations) return undefined;

    return {
      categoryNumbers: getFilterOptions('categoryNumbers', aggregations, appliedFilters),
      milestoneNames: getFilterOptions('milestoneNames', aggregations, appliedFilters),
      projectLocations: getFilterOptions('projectLocations', aggregations, appliedFilters),
      projectNames: getFilterOptions('projectNames', aggregations, appliedFilters),
      projectTypes: getFilterOptions('projectTypes', aggregations, appliedFilters),
      statuses: getFilterOptions('statuses', aggregations, appliedFilters).map((av) => ({
        ...av,
        label: getItemStatusLabel(av.id),
      })),
    };
  }, [aggregations, appliedFilters]);
};

function getFilterOptions(
  field: keyof typeof AGGREGATION_FIELDS,
  aggregations: Aggregation[],
  appliedFilters: ForecastingExploreItemsFilters
): ListFilterOption[] {
  let options: ListFilterOption[] = [];

  // Pull out the aggregations that were returned and sort them.
  const aggregation = aggregations.find((a) => a.name === AGGREGATION_FIELDS[field]);
  if (aggregation) {
    options = [...aggregation.values]
      .sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true }))
      .map((av) => ({
        id: av.name,
        count: av.count,
        label: av.name || 'None',
      }));
  }

  // Run through the applied filters. If there is an applied filter that was not in the
  // aggregations, we need to create some kind of option for it so that it'll be
  // unselectable.
  const appliedFilterValues = appliedFilters[field];
  appliedFilterValues.forEach((v) => {
    if (options.find((o) => o.id === v)) return;

    // Because it wasn't in the aggregations array, we know the count is 0.
    options.unshift({ id: v, label: v, count: 0 });
  });

  return options;
}
