import { FC } from 'react';

import { pluralizeCountString } from '../../../utilities/string';
import { formatSettingsForExport } from '../../ItemsList/ItemsListUtils';
import { isScheduleSettingsDisabled } from '../../ProjectProperties/ProjectScheduleImpact/ProjectScheduleImpactSettings';
import { getDateLine } from '../PrintCostReport/PrintSubheader';
import { getFilterLabels, getGroupByLabels } from '../PrintUtils';

type PrintItemsListSubheaderProps = {
  shouldDisplayCosts: boolean;
  categorizations: Categorization[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  creators: any[];
  itemCount: number;
  settings: Settings;
};

const PrintItemsListSubheader: FC<PrintItemsListSubheaderProps> = ({
  shouldDisplayCosts,
  categorizations,
  creators,
  itemCount,
  settings,
}) => {
  const isScheduleImpactEnabled = !isScheduleSettingsDisabled();
  const filters = formatSettingsForExport(settings, creators, categorizations);
  const { groupBy } = settings;

  return (
    <>
      <div className="print-subheader print-subheader-columns">
        {getDateLine()}
        <div className="print-subheader-line">
          <div className="print-subheader-number-items">
            {pluralizeCountString('Item', itemCount)}
          </div>
          {getGroupByLabels(groupBy)}
          {getFilterLabels(filters)}
        </div>
      </div>
      <hr className="print-main-divider" />
      <div
        className={`${
          isScheduleImpactEnabled ? 'print-item-row' : 'print-item-row-2'
        } print-item-row-cost print-group-header-text print-bold-text`}
      >
        <div className="print-item-row-details" />
        {shouldDisplayCosts ? <div className="print-item-row-col">Cost Impact</div> : <div />}
        {isScheduleImpactEnabled && <div className="print-item-row-col">Schedule Impact</div>}
      </div>
      <hr className="print-main-divider" />
    </>
  );
};

export default PrintItemsListSubheader;
