import { ReactNode } from 'react';

export default function Callout(props: {
  children: ReactNode;
  'data-cy'?: string;
  isFullWidth?: boolean;
}) {
  return (
    <div
      className={`bg-background-2 p-2 type-body1 ${props.isFullWidth ? 'w-full' : 'inline-block'}`}
      data-cy={props['data-cy']}
    >
      {props.children}
    </div>
  );
}
