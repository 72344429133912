import { GSF_ID, GSM_ID } from '../../../constants';
import { formatCommas } from '../../../utilities/currency';

export const isQuantityMagnitudeNonZero = (q: Quantity | undefined) => q?.magnitude !== 0;

export const unitCompareFunc = (u1: Unit, u2: Unit): number => {
  if (u1.id === GSM_ID && u2.id === GSF_ID) return 1;
  if (u1.id === GSF_ID || u1.id === GSM_ID) return -1;
  const u1Name = u1.name.toUpperCase();
  const u2Name = u2.name.toUpperCase();
  if (u1Name < u2Name) return -1;
  if (u1Name > u2Name) return 1;
  return 0;
};

export const getNonZeroQuantities = (quantities?: Quantity[]) =>
  quantities
    ?.filter(isQuantityMagnitudeNonZero)
    .sort((q1, q2): number => unitCompareFunc(q1.unit, q2.unit)) ?? [];

// Show GSF if exists, then first set quantity
export const getProjectUnitDisplay = (quantities: Quantity[]): string[] => {
  const nonZero = getNonZeroQuantities(quantities);
  if (!nonZero || nonZero.length === 0) return [];
  return [nonZero[0]].map(
    (quantity) => `${formatCommas(quantity.magnitude)} ${quantity.unit.abbreviationPlural}`
  );
};
