import { useState } from 'react';

import {
  ProgramCategorizationsQuery,
  ProgramCostFieldsFragment,
  ProgramCostTrendline,
  ProgramFieldsFragment,
  ProjectCostBreakdown,
} from '../../generated/graphql';
import ProgramCostTrendlineChart from '../dragon-scales/TimelineCharts/ProgramCostTrendline/ProgramCostTrendline';
import {
  costBreakdownTypeToColorMap,
  programCostBreakdownHeaders,
  projectCostBreakdownHeaders,
} from '../ProjectCostBreakdownReport/utils';

import AllProjectsFilteredOutEmptyState from './AllProjectsFilteredOutEmptyState';
import ProgramDashboardHeader from './ProgramDashboardHeader';
import ProgramDashboardMenu from './ProgramDashboardMenu';
import ProgramDashboardPieChart from './ProgramDashboardPieChart';
import ProgramCombinedEstimateChart from './ProgramCombinedEstimateChart';
import ProjectSelectionList from '../ProjectsList/ProjectSelection/ProjectSelectionList';
import { pieChartDefaultColors } from '../Charts/ChartsD3/ChartsPieGraph/utils';
import { ScrollContainer } from '../scales';

type Props = {
  program?: ProgramFieldsFragment;
  programCosts?: ProgramCostFieldsFragment;
  programBreakdown: Omit<ProjectCostBreakdown, 'type'>[];
  projectBreakdown: Omit<ProjectCostBreakdown, 'type'>[];
  selectedProjectIDs: UUID[];
  setSelectedProjectIDs: (newIDs: UUID[]) => void;
  totalProgramCost: number;
  chartData?: ProgramCostTrendline;
  programCategorizations: ProgramCategorizationsQuery['programCategorizations'];
};

export default function ProgramDashboard({
  program,
  programCosts,
  programBreakdown,
  projectBreakdown,
  selectedProjectIDs,
  setSelectedProjectIDs,
  totalProgramCost,
  chartData,
  programCategorizations,
}: Props) {
  const [isOpen, setIsOpen] = useState(false);

  const projects = program?.projects ?? [];

  if (!projects || projects.length === 0) return null;

  const projectColors = new Map<string, string>();
  projectBreakdown.forEach((project, i) => {
    projectColors.set(project.name, pieChartDefaultColors[i]);
  });

  return (
    <div className="flex h-full flex-col">
      <ProgramDashboardMenu program={program} />
      <ScrollContainer direction="vertical">
        <div
          className={`flex w-full flex-col justify-center gap-6 p-6 ${
            !selectedProjectIDs.length ? 'h-full' : ''
          }`}
        >
          <ProgramDashboardHeader
            isOpen={isOpen}
            program={program}
            programCosts={programCosts}
            selectedProjectIDs={selectedProjectIDs}
            setIsOpen={setIsOpen}
          />
          {isOpen && (
            <ProjectSelectionList
              key="ProjectSelectionList"
              fetchMore={() => {}}
              onChange={setSelectedProjectIDs}
              projects={projects}
              searchTerm=""
              selectedProjectIDs={selectedProjectIDs}
              selectionMode="multiple"
            />
          )}
          {!selectedProjectIDs.length && (
            <AllProjectsFilteredOutEmptyState
              program={program}
              setSelectedProjectIDs={setSelectedProjectIDs}
            />
          )}
          {!!selectedProjectIDs.length && program && (
            <>
              <ProgramCostTrendlineChart
                combined={chartData?.combined?.combinedCostTimeSeries ?? []}
                dataRange={[chartData?.combined?.minCost ?? 0, chartData?.combined?.maxCost ?? 0]}
                height={500}
                isPrint={false}
                separated={chartData?.separated}
                separatedProjectColors={projectColors}
                timeRange={[new Date(chartData?.minDate ?? 0), new Date(chartData?.maxDate ?? 0)]}
              />
              <ProgramDashboardPieChart
                breakdown={projectBreakdown}
                colorMap={projectColors}
                headers={projectCostBreakdownHeaders}
                name="Project Breakdown"
                total={totalProgramCost}
              />
              <ProgramDashboardPieChart
                breakdown={programBreakdown}
                colorMap={costBreakdownTypeToColorMap}
                headers={programCostBreakdownHeaders}
                name="Program Breakdown"
                total={totalProgramCost}
              />
              {!!programCategorizations.length && (
                <ProgramCombinedEstimateChart
                  programCategorizations={programCategorizations}
                  programID={program.id}
                  selectedProjectIDs={selectedProjectIDs}
                />
              )}
            </>
          )}
        </div>
      </ScrollContainer>
    </div>
  );
}
