import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../theme/komodo-mui-theme';

export default (theme: KomodoTheme) =>
  createStyles({
    cardButton: {
      border: `${theme.palette.borderDarkGrey} 1px solid`,
      font: theme.fontFamily,
      color: theme.palette.joinPrimary,
      borderRadius: 8,
      height: 70,
      background: theme.palette.backgroundWhite,
    },
    cardButtonHover: {
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: theme.palette.backgroundFocusSelectGreen,
        border: `${theme.palette.fillGreen} 1px solid`,
      },
    },
    cardButtonDisabled: {
      border: `${theme.palette.backgroundGrey} 1px solid`,
      backgroundColor: theme.palette.backgroundGrey,
      color: theme.palette.chartGrey,
    },
    content: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    buttonText: {
      paddingLeft: 4,
      paddingRight: 12,
      paddingTop: 3,
    },
    defaultWidth: {
      width: 366,
    },
    fullWidth: {
      width: '100%',
    },
  });
