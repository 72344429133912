export default function JoinWordmark() {
  return (
    <svg
      fill="none"
      height="1em"
      viewBox="0 0 27 10"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.09942 6.47902C7.09942 7.57448 6.81773 8.38825 6.22305 8.98293C5.62837 9.54631 4.8146 9.8593 3.81303 9.8593C3.24965 9.8593 2.74887 9.7654 2.34198 9.60891C1.9351 9.42112 1.55951 9.20202 1.30912 8.88904C1.02743 8.57605 0.808337 8.23176 0.683142 7.82487C0.557946 7.41799 0.46405 6.9798 0.46405 6.51032H2.56108C2.56108 6.9485 2.65497 7.32409 2.87406 7.63708C3.06186 7.91877 3.37485 8.07526 3.81303 8.07526C4.18862 8.07526 4.50161 7.95007 4.7207 7.66838C4.93979 7.38669 5.03369 7.0111 5.03369 6.54162V0.532227H7.13071V6.47902H7.09942Z"
        fill="currentColor"
      />
      <path
        d="M11.763 9.7965C11.2309 9.7965 10.7301 9.70261 10.2919 9.51481C9.85375 9.32702 9.50946 9.10793 9.19647 8.79494C8.88349 8.48195 8.66439 8.13766 8.5079 7.76207C8.3514 7.35519 8.25751 6.9483 8.25751 6.47882C8.25751 6.00933 8.3514 5.60245 8.5079 5.19556C8.66439 4.78868 8.88349 4.44439 9.19647 4.1627C9.50946 3.88101 9.85375 3.63062 10.2919 3.44282C10.7301 3.25503 11.1996 3.16113 11.763 3.16113C12.2951 3.16113 12.7958 3.25503 13.234 3.44282C13.6722 3.63062 14.0165 3.84971 14.3295 4.1627C14.6425 4.47569 14.8616 4.81997 15.0181 5.19556C15.1746 5.60245 15.2372 6.00933 15.2372 6.47882C15.2372 6.9483 15.1746 7.35519 15.0181 7.76207C14.8616 8.16896 14.6425 8.51325 14.3295 8.79494C14.0165 9.10793 13.6722 9.32702 13.234 9.51481C12.7958 9.70261 12.2951 9.7965 11.763 9.7965ZM11.7317 8.10636C11.9508 8.10636 12.1699 8.07506 12.3577 7.98117C12.5455 7.88727 12.7019 7.79337 12.8271 7.63688C12.9523 7.48038 13.0462 7.32389 13.1401 7.1361C13.234 6.9483 13.2653 6.72921 13.2653 6.51012C13.2653 6.29103 13.234 6.07193 13.1401 5.88414C13.0462 5.69635 12.9523 5.50855 12.8271 5.38335C12.7019 5.25816 12.5455 5.13296 12.3577 5.03907C12.1699 4.94517 11.9508 4.91387 11.7317 4.91387C11.5126 4.91387 11.2935 4.94517 11.1057 5.03907C10.9179 5.13296 10.7614 5.22686 10.6362 5.38335C10.511 5.53985 10.4171 5.69635 10.3232 5.88414C10.2293 6.07193 10.2293 6.29103 10.2293 6.51012C10.2293 6.72921 10.2606 6.9483 10.3232 7.1361C10.3858 7.32389 10.4797 7.51168 10.6362 7.63688C10.7927 7.76207 10.9179 7.88727 11.1057 7.98117C11.3248 8.04376 11.5126 8.10636 11.7317 8.10636Z"
        fill="currentColor"
      />
      <path
        d="M16.2074 1.28325C16.2074 0.938967 16.3326 0.625977 16.583 0.375586C16.8334 0.125195 17.1151 0 17.4907 0C17.6784 0 17.8349 0.0312999 17.9914 0.0938977C18.1479 0.156495 18.2731 0.25039 18.3983 0.375586C18.5235 0.500781 18.6174 0.625978 18.68 0.782473C18.7426 0.938967 18.7739 1.09546 18.7739 1.28325C18.7739 1.47105 18.7426 1.62754 18.68 1.78404C18.6174 1.94053 18.5235 2.06573 18.3983 2.19092C18.2731 2.31612 18.1479 2.41002 17.9914 2.47261C17.8349 2.53521 17.6784 2.56651 17.4907 2.56651C17.3029 2.56651 17.1464 2.53521 16.9899 2.47261C16.8334 2.41002 16.7082 2.31612 16.583 2.19092C16.4578 2.06573 16.3639 1.94053 16.3013 1.78404C16.2387 1.62754 16.2074 1.43975 16.2074 1.28325ZM16.4891 3.34898H18.4922V9.64006H16.4891V3.34898Z"
        fill="currentColor"
      />
      <path
        d="M20.2137 3.34912H22.2168V4.25679C22.4046 3.9125 22.655 3.66211 22.9993 3.47432C23.3436 3.28652 23.7817 3.19263 24.2825 3.19263C24.7207 3.19263 25.065 3.25523 25.3467 3.38042C25.6284 3.50562 25.8475 3.69341 26.0353 3.8812C26.2231 4.1316 26.3796 4.41328 26.4422 4.72627C26.5048 5.03926 26.5361 5.41485 26.5361 5.88433V9.6089H24.5329V6.41641C24.5329 5.97823 24.439 5.60264 24.2825 5.32095C24.126 5.03926 23.8443 4.88277 23.4375 4.88277C23.0306 4.88277 22.7176 5.03926 22.4985 5.32095C22.2794 5.60264 22.1855 5.97823 22.1855 6.47901V9.6402H20.1824V3.34912H20.2137Z"
        fill="currentColor"
      />
    </svg>
  );
}
