import { FC, useMemo } from 'react';

import { ProjectCostBreakdownReportFieldsFragment } from '../../generated/graphql';
import { formatCost } from '../../utilities/currency';
import { isNonNullable } from '../../utilities/types';
import ChartsPieGraph from '../Charts/ChartsD3/ChartsPieGraph/ChartsPieGraph';

import ProjectCostBreakdownReportTable, {
  ReportTableHeader,
} from './ProjectCostBreakdownReportTable';
import { breakdownSorting, projectBreakdownChartOrder, projectBreakdownTableOrder } from './utils';

type Props = {
  costBreakdownTypeToColorMap: Map<string, string>; // used for fill styling
  label: string;
  headers: ReportTableHeader[];
  projectCostBreakdownReport: ProjectCostBreakdownReportFieldsFragment;
};

const ProjectCostBreakdownReportChart: FC<Props> = ({
  costBreakdownTypeToColorMap,
  label,
  headers,
  projectCostBreakdownReport,
}) => {
  const { breakdown, total } = projectCostBreakdownReport;

  // filter out zero cost values from the pie chart,
  // and sort + format the data for the chart
  // note: this table has a different sort order from the table
  const data = useMemo(() => {
    const sortedBreakdown = [...breakdown]
      .filter((b) => b.cost.toString() !== '0')
      .sort(breakdownSorting(projectBreakdownChartOrder));
    return sortedBreakdown.map(({ name, percent }) => {
      return { name, share: percent };
    });
  }, [breakdown]);

  // sort the table data
  // note: this table has a different sort order from the chart
  const tableBreakdown = useMemo(() => {
    return [...breakdown].sort(breakdownSorting(projectBreakdownTableOrder));
  }, [breakdown]);

  return (
    <div className="mt-10 flex w-full items-center justify-center">
      <div className="flex w-full max-w-[1000px] gap-20">
        <ChartsPieGraph
          centerLabel={{ label: formatCost(total, { rounded: true }), sublabel: label }}
          chartSize={{
            diameter: 300,
            insideDiameter: 216,
          }}
          colors={data.map((b) => costBreakdownTypeToColorMap.get(b.name)).filter(isNonNullable)}
          data={data}
          options={{ spaceBetweenWedges: breakdown.length > 1 }} // if there is only a single wedge then we don't need gaps
        />
        <ProjectCostBreakdownReportTable
          costBreakdownTypeToColorMap={costBreakdownTypeToColorMap}
          headers={headers}
          projectCostBreakdown={tableBreakdown}
        />
      </div>
    </div>
  );
};

export default ProjectCostBreakdownReportChart;
