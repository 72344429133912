import { Link, To } from 'react-router-dom';

import { ErrorOutline, LocationOnOutlined } from '@material-ui/icons';

import { InsightsSort, InsightsSortKey } from '../../../generated/graphql';
import { fromNow } from '../../../utilities/dates';
import { Thumbnail } from '../../dragon-scales';
import { SizeIcon } from '../../Nav/icons';
import { Chip } from '../../scales';

type Props = {
  hasAlert: boolean;
  onClick: () => void;
  project: InsightsProjects;
  sortState: InsightsSort;
  to: To;
};

export default function ProjectTableEntry({ hasAlert, onClick, project, sortState, to }: Props) {
  const chips = getChipsBySort(project, sortState.sortKey);

  return (
    <div className="flex items-center gap-2">
      <Thumbnail size={60} thumbnail={project.thumbnail}>
        {hasAlert && (
          <ErrorOutline
            color="error"
            style={{
              backgroundColor: 'white',
              borderRadius: '50%',
              left: 5,
              position: 'absolute',
              top: 5,
            }}
          />
        )}
      </Thumbnail>
      <div className="flex flex-col justify-center gap-2">
        <div>
          <Link onClick={onClick} to={to}>
            <div className="line-clamp-2 text-type-link type-heading3">{project.name}</div>
          </Link>
        </div>
        {chips}
      </div>
    </div>
  );
}

const getChipsBySort = (
  project: InsightsProjects,
  sortKey: InsightsSortKey
): JSX.Element | undefined => {
  const defaultChips = (
    <div className="flex flex-wrap gap-2">
      <Chip icon={<LocationOnOutlined />} text={project.location} />
    </div>
  );

  switch (sortKey) {
    case InsightsSortKey.COST:
      return project.location ? defaultChips : undefined;

    case InsightsSortKey.DELIVERY_TYPE:
      return project.deliveryType ? <Chip text={project.deliveryType} /> : undefined;

    case InsightsSortKey.DESIGN_PHASE:
      return project.milestoneDesignPhase ? (
        <Chip text={project.milestoneDesignPhase} />
      ) : undefined;

    case InsightsSortKey.LAST_UPDATED:
      return project.lastUpdated ? (
        <div className="type-table-text">
          Last updated {fromNow(project.lastUpdated)} by {project.lastUpdatedBy}
        </div>
      ) : undefined;

    case InsightsSortKey.SIZE:
      return project.milestoneGSF && Number(project.milestoneGSF) !== 0 ? (
        <Chip icon={<SizeIcon />} text={`${Number(project.milestoneGSF).toLocaleString()} GSF`} />
      ) : undefined;

    case InsightsSortKey.STATUS:
      return project.status ? <Chip text={project.status} /> : undefined;

    case InsightsSortKey.NAME:
    default:
      return defaultChips;
  }
};
