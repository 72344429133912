import { useMutation } from '@apollo/client';

import { createOrUpdateBucketAnalytics } from '../../../analytics/analyticsEventProperties';
import {
  CreateOrUpdateBucketMutation,
  CreateOrUpdateBucketMutationVariables,
} from '../../../generated/graphql';
import useSendAnalytics from '../../../hooks/useSendAnalytics';

import { createOrUpdateBucketMutation, getMilestoneQuery } from './queries';

function useCreateOrUpdateMilestoneBucket() {
  const [createOrUpdateMilestoneBucketFunc, mutationResult] = useMutation<
    CreateOrUpdateBucketMutation,
    CreateOrUpdateBucketMutationVariables
  >(createOrUpdateBucketMutation);

  const sendAnalytics = useSendAnalytics();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  const submitFunc = (projectId: UUID, milestoneId: UUID, bucket: any) =>
    createOrUpdateMilestoneBucketFunc({
      variables: {
        projectID: projectId,
        id: bucket.id,
        milestone: milestoneId,
        name: bucket.name,
        date: bucket.date,
      },
      update: (dataProxy, { data }) => {
        const createOrUpdateBucket = data?.createOrUpdateBucket;
        if (!createOrUpdateBucket) return;
        // update apollo cache
        // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
        const { milestone }: any = dataProxy.readQuery({
          query: getMilestoneQuery,
          variables: { id: milestoneId },
        });
        const milestoneUpdated = {
          ...milestone,
          buckets: createOrUpdateBucket,
        };
        dataProxy.writeQuery({
          query: getMilestoneQuery,
          variables: { id: milestoneId },
          data: { milestone: milestoneUpdated },
        });
        sendAnalytics(createOrUpdateBucketAnalytics(bucket.id, milestoneId));
      },
    });

  return [submitFunc, mutationResult];
}

export default useCreateOrUpdateMilestoneBucket;
