import { FC } from 'react';

import { withStyles } from '../../../../theme/komodo-mui-theme';

import styles from './ImportBuiltInTooltipStyles';

type ImportBuiltInTooltipProps = {
  classes: Classes<typeof styles>;
};

const ImportBuiltInTooltip: FC<ImportBuiltInTooltipProps> = ({ classes }) => (
  <div className={`${classes.container} `}>
    <div className={`${classes.text}`} data-cy="tooltip-import1">
      Join&apos;s UniFormat and MasterFormat built-ins are multi-level categorizations that appear
      as a single column in estimates.
    </div>
    <div className={`${classes.text} `} data-cy="tooltip-import2">
      In your estimating platform, each level of UniFormat or MasterFormat is a separate work
      breakdown structure.
    </div>
    <div className={`${classes.text} `} data-cy="tooltip-import3">
      Match the work breakdown structure from your estimate that corresponds with each UniFormat or
      MasterFormat level in Join.
    </div>
    <div className={`${classes.text} `} data-cy="tooltip-import4">
      The more levels matched, the more valuable your data becomes in Join.
    </div>
  </div>
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
export const StyledImportBuiltInTooltip: any = withStyles(styles)(ImportBuiltInTooltip);

export default StyledImportBuiltInTooltip;
