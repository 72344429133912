import { useCallback } from 'react';

import { useMutation } from '@apollo/client';

import { REFETCH_SCENARIO_SANDBOX } from '../../../api/refetchSets';

import { updateSandboxMutation } from './queries';

const useUpdateSandboxMutation = ({ milestoneID }: { milestoneID: UUID }) => {
  const [updateFn] = useMutation(updateSandboxMutation);
  const updateScenarios = useCallback(
    ({ scenarioIDs }: { scenarioIDs: UUID[] }) => {
      updateFn({
        variables: {
          milestoneID,
          scenarioIDs,
        },
        refetchQueries: REFETCH_SCENARIO_SANDBOX,
      });
    },
    [milestoneID, updateFn]
  );
  return [updateScenarios];
};

export default useUpdateSandboxMutation;
