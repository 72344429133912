import * as React from 'react';
import { SuggestionDataItem } from 'react-mentions';

import { ApolloClient } from '@apollo/client';
import { Avatar } from '@material-ui/core';
// eslint-disable-next-line import/no-unresolved
import { Mention as ReactMention } from 'react-mentions/lib/utils/getMentions';

import { UserStatus } from '../../../api/gqlEnumsBe';
import { SORT_NUMBER } from '../../../constants';
import {
  ItemLink,
  ItemReference,
  ItemReferenceInput,
  MentionableTextInput,
  MentionInput as UserMentionInput,
} from '../../../generated/graphql';
import { readCollaborators } from '../../../hooks/useCollaboratorsQuery';
import { getItemStatusLabel } from '../../../utilities/item-status';
import { sortItemsBy } from '../../../utilities/sorting';
import { getUserPicture } from '../../Collaborators/UserUtils';
import { readItems } from '../../Items/ItemReferences/hooks/useItemLinksQuery';
import { isPublicVisibility } from '../../Items/ItemsUtils';
import ItemsIconsMap, { SMALL } from '../../ItemsList/ItemsIcons/ItemsIconsMap';
import NormalTooltip from '../../NormalTooltip/NormalTooltip';

import { emojis } from './EmoijiUtils';
import styles from './MentionableTextAreaStyles';

const SUGGESTION_LIMIT = 10;

export const getUserSuggestions = (users: User[]) =>
  users.map(({ id, name, email, status }) => ({
    id,
    display: email ? `${name} (${email})` : name,
    status,
  }));

// format the text that will be displayed for each item in the list
export const getItemLinkSuggestions = (itemLinks: ItemLink[]) =>
  itemLinks.map(({ id, name, number }) => ({
    id,
    display: `#${number}: ${name}`,
  }));

export const getFilteredActiveUsers = (query: string, collaborators: Collaborator[]) => {
  const users = collaborators.map((c) => c.user);
  const notDeactivatedUsers = users.filter((user) => user.status !== UserStatus.DEACTIVATED);
  const suggestedUsers = getUserSuggestions(notDeactivatedUsers);
  const suggestedUsersFiltered = suggestedUsers
    .filter((user) => user.display.toLowerCase().includes(query.toLowerCase()))
    .slice(0, SUGGESTION_LIMIT);
  return suggestedUsersFiltered;
};

export const getPrivateVisible = (
  collaborators: Collaborator[],
  isPrivate?: boolean,
  sharedUsersId?: UUID[]
) => {
  if (!isPrivate) return collaborators;
  const sharedCollaborators = collaborators.filter((c) => sharedUsersId?.includes(c.user.id));
  return sharedCollaborators;
};

// get correct item links to display in suggestion list
export const getFilteredItemLinks = (query: string, itemLinks: ItemLink[]) => {
  const visibleItemLinks = itemLinks
    .filter((itemLink) => itemLink.visibility !== null && isPublicVisibility(itemLink.visibility))
    .sort((a, b) => sortItemsBy[SORT_NUMBER](a, b));

  const suggestedItemLinks = getItemLinkSuggestions(visibleItemLinks);
  const suggestItemLinksFiltered = suggestedItemLinks
    .filter((itemLink) => itemLink.display.toLowerCase().includes(query.toLowerCase()))
    .slice(0, SUGGESTION_LIMIT);
  return suggestItemLinksFiltered;
};

export const displayTransform =
  (client: ApolloClient<object>, projectID: UUID) => (id: UUID, display: string) => {
    const collaborators = readCollaborators(client, projectID);
    const users = collaborators.map((c) => c.user);
    const user = users.find((u) => u.id === id);
    let name = user ? user.name : display;
    if (!name.startsWith('@')) name = `@${name}`;
    return name;
  };

// transform what the displayed in the textbox once a suggestion is selected
export const displayItemTransform =
  (client: ApolloClient<object>, projectID: UUID) => (id: UUID, display: string) => {
    const itemLinks = readItems(client, projectID);
    const itemLink = itemLinks.find((i) => i.id === id);
    let itemDisplay = itemLink ? `${itemLink.number}` : display;
    if (!itemDisplay.startsWith('#')) itemDisplay = `#${itemDisplay}`;
    return itemDisplay;
  };

// map react mentions to UserMentionInputs
export const getUserMentionInputs: (mentionItems: ReactMention[]) => UserMentionInput[] = (
  mentionItems
) =>
  mentionItems.map(({ id, display, plainTextIndex }) => ({
    userID: String(id),
    text: display,
    position: plainTextIndex,
  }));

// map react mentions to ItemReferenceInputs
export const getItemReferenceInputs: (reactMentions: ReactMention[]) => ItemReferenceInput[] = (
  reactMentions
) =>
  reactMentions.map(({ id, display, plainTextIndex }) => ({
    referencedItemID: String(id),
    text: display,
    position: plainTextIndex,
  }));

const USER_PENDING_HOVER_MESSAGE =
  "This user hasn't finished setting up their account. They'll see your mention in their notifications list after they log in for the first time, but won't receive an email.";
const INVITE_PENDING_BADGE_DISPLAY = 'Invite Pending';
// Component rendering
export const renderSuggestions =
  (client: ApolloClient<object>, projectID: UUID, classes: Classes<typeof styles>) =>
  (suggestion: SuggestionDataItem, _search: string, highlightedDisplay: React.ReactNode) => {
    const collaborators = readCollaborators(client, projectID);
    const users = collaborators.map((c) => c.user);
    const { id } = suggestion;
    const user = users.find((u) => u.id === id);
    if (!user) return highlightedDisplay;
    return (
      <div className={classes.suggestion}>
        <Avatar className={classes.avatar} src={getUserPicture(user)} />
        <div className={classes.ellipsis}>{highlightedDisplay}</div>
        <div className={classes.spacer} />
        {user.status === UserStatus.PENDING && (
          <NormalTooltip title={USER_PENDING_HOVER_MESSAGE}>
            <span className={classes.infoBadge} data-cy="user-pending-chip">
              <span>{INVITE_PENDING_BADGE_DISPLAY}</span>
            </span>
          </NormalTooltip>
        )}
      </div>
    );
  };

// display the dropdown menu with item suggestions and icon
export const renderItemSuggestions =
  (client: ApolloClient<object>, projectID: UUID, classes: Classes<typeof styles>) =>
  (suggestion: SuggestionDataItem, _search: string, highlightedDisplay: React.ReactNode) => {
    const items = readItems(client, projectID);
    const { id } = suggestion;
    const item = items.find((i) => i.id === id);
    if (!item) return highlightedDisplay;

    return (
      <div className={classes.suggestion}>
        <NormalTooltip title={getItemStatusLabel(item.status)}>
          <span className={classes.statusIconHover} data-cy="item-suggestion-status">
            <ItemsIconsMap status={item.status} variant={SMALL} />
          </span>
        </NormalTooltip>
        <div className={classes.ellipsis}>{highlightedDisplay}</div>
      </div>
    );
  };

// Emojis
export const queryEmojis = (query: string) =>
  emojis
    .filter(({ name }) => (query.length === 0 ? true : name.indexOf(query.toLowerCase()) > -1))
    .slice(0, SUGGESTION_LIMIT)
    .map(({ emoji: id, name: display }) => ({ id, display }));
// we need name to display for the highlighting
export const displayEmojiTransform = (id: string) => id;
export const renderEmojiSuggestion =
  (classes: Classes<typeof styles>) =>
  (suggestion: SuggestionDataItem, _search: string, highlightedDisplay: React.ReactNode) => {
    const { id } = suggestion;
    const emoji = emojis.find((e) => e.emoji === suggestion.id);
    if (!emoji) return null;
    const { name } = emoji;

    return (
      <span className={classes.suggestion}>
        <NormalTooltip title={name}>
          <span>
            <span className={classes.emojiAvatar}>{id}</span>
            <span className={`${classes.ellipsis} ${classes.emojiText}`}>{highlightedDisplay}</span>
          </span>
        </NormalTooltip>
      </span>
    );
  };

// encode mentions / item references to a markup value for react-mentions library
export const getFormattedContent = (
  comment?: MentionableText | MentionableTextInput,
  isItemReferencesFeature?: boolean
) => {
  if (!comment) return '';
  const { contents, mentions, itemReferences } = comment;
  let newContents = contents;

  let offsetPosition = 0;
  if (mentions && mentions.length !== 0) {
    newContents = getMentionContents(mentions, contents);
    offsetPosition = newContents.length - contents.length;
  }

  if (isItemReferencesFeature && itemReferences && itemReferences.length !== 0) {
    newContents = getItemReferenceContents(itemReferences, newContents, offsetPosition);
  }
  return newContents;
};

function getItemReferenceContents(
  itemReferences: ItemReference[] | ItemReferenceInput[],
  contents: string,
  offsetPosition: number
) {
  let itemReferenceContents = contents;
  itemReferences
    .slice()
    .sort((a, b) => b.position - a.position)
    .forEach((itemReference) => {
      const { text, position } = itemReference;
      const itemID =
        (itemReference as ItemReference).referencedItem?.id ||
        (itemReference as ItemReferenceInput).referencedItemID;
      // for each item reference, replace the text with #[display](id)
      const encodedItemReference = `#[${text}](${itemID})`;
      const adjustedPosition = position + offsetPosition;
      itemReferenceContents =
        itemReferenceContents.slice(0, adjustedPosition) +
        encodedItemReference +
        itemReferenceContents.slice(adjustedPosition + text.length);
    });
  return itemReferenceContents;
}

function getMentionContents(mentions: Mention[] | UserMentionInput[], contents: string) {
  let mentionContents = contents;
  mentions
    .slice()
    .sort((a, b) => b.position - a.position) // as long as we move from back to front, position is ok
    .forEach((mention) => {
      const { text, position } = mention;
      const userID = (mention as Mention).user?.id || (mention as UserMentionInput).userID;
      // for each mention, replace the text with @[display](id)
      // we need the at for react-mention to recognize the format at first
      const encodedMention = `@[${text}](${userID})`;
      mentionContents =
        mentionContents.slice(0, position) +
        encodedMention +
        mentionContents.slice(position + text.length);
    });
  return mentionContents;
}

export function isUserMention(typedMention: ReactMention): boolean {
  return typedMention.display.startsWith('@');
}

export function isItemReference(typedMention: ReactMention): boolean {
  return typedMention.display.startsWith('#');
}
