import { FC, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { JoinCompanyRoutes, JoinCompanyStandardsRoutes } from '../../../api/gqlEnums';
import { generateSharedPath } from '../../../utilities/routes/links';
import { useCompanyRoutesData } from '../CompanyTabUtils';

import Standards from './Standards';

const StandardsData: FC = () => {
  const { companyID } = useCompanyRoutesData();
  useRedirectCompanyStandardsHome();
  if (!companyID) return null;
  return <Standards />;
};

export default StandardsData;

export const useRedirectCompanyStandardsHome = () => {
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    const pathnameWithoutTrailingSlash = location.pathname.replace(/\/$/, '');
    const rootCompanyStandardsPath = generateSharedPath(JoinCompanyRoutes.COMPANY_STANDARDS, {});

    if (pathnameWithoutTrailingSlash === rootCompanyStandardsPath) {
      navigate(generateSharedPath(JoinCompanyStandardsRoutes.COMPANY_PROJECT_TEMPLATES, {}));
    }
  }, [location, navigate]);
};
