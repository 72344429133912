import { FORECASTING, JOIN_INSIGHTS } from '../../../moduleEntitlementFlagsList';
import { TableHeader } from '../../scales';

export type CompanyRoleInfo = {
  featureFlag?: string;
  entitlement: string;
  header: TableHeader;
  permissions: string;
  companyRole: string;
};

export const entitlementCompanyInfo = [
  {
    entitlement: FORECASTING,
    header: { copy: 'Manager', headerSortKey: null, key: 'manager', centered: true },
    permissions: 'Access company-level historical project costs in Forecasting',
    companyRole: 'Manager',
  },
  {
    entitlement: JOIN_INSIGHTS,
    header: { copy: 'Executive', headerSortKey: null, key: 'executive', centered: true },
    permissions: 'Access company-level insights',
    companyRole: 'Executive',
  },
] as CompanyRoleInfo[];
export const basicCompanyInfo = [
  {
    entitlement: '',
    header: { copy: 'Role Permissions', headerSortKey: null, key: 'permissions' },
    permissions: '',
    companyRole: '',
  },
  {
    entitlement: '',
    header: { copy: 'Basic', headerSortKey: null, key: 'basic', centered: true },
    permissions: 'Ability to be invited on projects as a collaborator',
    companyRole: 'Basic',
  },
  {
    entitlement: '',
    header: {
      copy: 'Admin',
      headerSortKey: null,
      key: 'admin',
      centered: true,
    },
    permissions: 'Access to company-level administration',
    companyRole: 'Admin',
  },
  {
    entitlement: '',
    header: {
      copy: 'Admin',
      headerSortKey: null,
      key: 'admin',
      centered: true,
    },
    permissions: 'Access to all company projects and company-level administration',
    companyRole: 'Admin',
  },
] as CompanyRoleInfo[];

export const allRoles = [...basicCompanyInfo, ...entitlementCompanyInfo];

export const filterCompanyInfo = (entitlements: string[], featureFlags: boolean) => {
  const filteredEntitlements = entitlementCompanyInfo.filter((companyInfo) => {
    return (
      entitlements.findIndex((e) => {
        return e === companyInfo.entitlement;
      }) >= 0
    );
  });
  const adminFF = featureFlags ? basicCompanyInfo[3] : basicCompanyInfo[2];

  return [...basicCompanyInfo.slice(0, 2), ...filteredEntitlements, adminFF] as CompanyRoleInfo[];
};
