import { FC } from 'react';

import { Button, DialogContent, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import {
  CategorizationEvent,
  categorizationEvent,
} from '../../../analytics/analyticsEventProperties';
import useSendAnalytics from '../../../hooks/useSendAnalytics';
import { getProjectIdFromUrl } from '../../../utilities/url';
import { useAddCompanyCategorizations } from '../../CompanyTab/Standards/CompanyCategorizations/hooks';
import { useRemoveCategorizations } from '../hooks/hooks';

import styles from './ConfirmDeleteCategorizationsDialogStyles';

type ConfirmDeleteCategorizationsDialogProps = {
  classes: Classes<typeof styles>;
  catIDToAdd: UUID[];
  catIDToRemove: UUID[];
  categorizationNames: string[];
  onCancel: () => void;
  onClose: () => void;
};

const ConfirmDeleteCategorizationsDialog: FC<ConfirmDeleteCategorizationsDialogProps> = ({
  classes,
  categorizationNames,
  onCancel,
  catIDToAdd,
  catIDToRemove,
  onClose,
}) => {
  const projectID = getProjectIdFromUrl();

  const removeCategorizations = useRemoveCategorizations();
  const addCategorizations = useAddCompanyCategorizations();
  const sendAnalytics = useSendAnalytics();

  const deleteCategorizations = (categorizationNames || []).map((name, i) => (
    <Typography key={`${name}${i.toString()}`}>{name}</Typography>
  ));

  return (
    <>
      <div className={classes.innerPaper}>
        <DialogContent className={classes.content}>
          <Typography className={classes.margin}>
            You have selected to delete the following categorization(s):
          </Typography>
          <Typography component="div">{deleteCategorizations}</Typography>
          <Typography className={classes.margin}>
            Deleting categorization(s) is permanent and cannot be undone. If any items or milestone
            costs were tagged, their categories will be removed.
          </Typography>

          <Typography className={classes.textBold}>
            Confirm you are deleting categorization(s)?
          </Typography>
          <Button
            className={classes.margin}
            color="primary"
            onClick={() => {
              onCancel();
              sendAnalytics(
                categorizationEvent(CategorizationEvent.MANAGE_CONFIRM_DELETE_CANCEL, {
                  projectID,
                })
              );
            }}
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            className={classes.margin}
            color="primary"
            onClick={() => {
              if (catIDToAdd.length > 0) {
                addCategorizations(projectID, catIDToAdd);
              }
              removeCategorizations(projectID, catIDToRemove, () => {
                onCancel();
                onClose();
              });
              sendAnalytics(
                categorizationEvent(CategorizationEvent.MANAGE_CONFIRM_DELETE_CONFIRM, {
                  projectID,
                })
              );
            }}
            variant="contained"
          >
            Confirm
          </Button>
        </DialogContent>
      </div>
    </>
  );
};

export default withStyles(styles)(ConfirmDeleteCategorizationsDialog);
