import { FC } from 'react';

import { useReactiveVar } from '@apollo/client';

import { filesExplorerNavigationVar } from '../../../api/apollo/reactiveVars';
import { UserSource } from '../../../generated/graphql';
import { withStyles } from '../../../theme/komodo-mui-theme';
import NavigationBar from '../../shared-widgets/NavigationBar/NavigationBar';

import styles from './FilesExplorerStyles';
import { useInitFilesNavigation } from './FilesExplorerUseUtils';
import { getCurrentNavigationElement, onNavigationBarEntry } from './FilesExplorerUtils';

type FilesExplorerProps = {
  classes: Classes<typeof styles>;
  userSource: UserSource;
};

const FilesExplorer: FC<FilesExplorerProps> = ({ classes, userSource }) => {
  useInitFilesNavigation(userSource);
  const navBarEntries = useReactiveVar(filesExplorerNavigationVar);

  return (
    <div className={classes.sourceContent}>
      <div className={classes.sourceHeader}>
        <NavigationBar entries={navBarEntries} onEntryClick={onNavigationBarEntry} />
      </div>
      {getCurrentNavigationElement(navBarEntries)}
    </div>
  );
};

export default withStyles(styles)(FilesExplorer);
