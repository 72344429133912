import { ScaleLinear, ScalePoint, ScaleTime } from 'd3-scale';

export default function CostYAxis({
  range,
  tickFormat,
  tickTotal,
  x,
  y,
  xPosition,
}: {
  range: [number, number];
  tickFormat: (value: number) => string | null;
  tickTotal: number;
  x: ScaleTime<number, number> | ScalePoint<Date>;
  y: ScaleLinear<number, number>;
  xPosition: Date;
}) {
  const ticks = y.ticks(tickTotal).map((value) => ({
    value,
    yOffset: y(value),
  }));

  const xOffset = x(xPosition) ?? 0;

  return (
    <svg>
      <path
        d={['M', 0, 0, 'v', 0, 'H', 0, 'v', range[0]].join(' ')}
        fill="none"
        stroke="#e6e6e9"
        strokeWidth={2}
        transform={`translate(${xOffset}, 0)`}
      />
      {ticks.map(({ value, yOffset }) => (
        <g key={`y-tick-${value}`} transform={`translate(${xOffset}, ${yOffset})`}>
          <line stroke="#e6e6e9" strokeWidth={1} x2="-6" />
          <text
            key={`${value}`}
            style={{
              fontSize: '15px',
              fontWeight: 400,
              textAnchor: 'middle',
              transform: 'translateX(-32px) translateY(5px)',
            }}
          >
            {tickFormat(value)}
          </text>
        </g>
      ))}
    </svg>
  );
}
