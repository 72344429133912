import { Tooltip } from '../scales';
import EstimateChartHoverContent from './EstimateChartHoverContent';
import { CategoryTotals } from './hooks/useProgramCategorizedTotalsQuery';

type VerticalBarProps = {
  data: CategoryTotals;
  x: d3.ScaleBand<string>; // Categorical scale for the x-axis (categories)
  y: d3.ScaleLinear<number, number>; // Linear scale for the y-axis (numeric values)
};

export default function EstimateChartVerticalBar({ data, x, y }: VerticalBarProps) {
  const xPos = x(data.categoryName); // Get the x position of the category
  const yPos = y(Number(data.estimate)); // Get the y position for the value
  const barHeight = y(0) - y(Number(data.estimate)); // Calculate the bar height

  if (xPos === undefined || yPos === undefined) return null;

  const estimateYPos = y(Number(data.estimate));
  const budgetYPos = y(Number(data.budget));
  const runningTotalYPos = y(Number(data.runningTotal));
  const acceptedChanges = Number(data.accepted);
  const hasArrow = acceptedChanges !== 0;
  const arrowDirection = acceptedChanges > 0 ? 'up' : 'down';
  const arrowYPos = estimateYPos + 8; // Adjust the position of the arrow

  return (
    <g>
      <Tooltip
        content={
          <EstimateChartHoverContent
            categoryCostData={data}
            fieldsToBold={['estimate', 'runningTotal', 'accepted']}
          />
        }
      >
        <rect
          fill="var(--colors-chart-axis)"
          height={Math.abs(barHeight)}
          stroke="black"
          strokeWidth={1}
          width={x.bandwidth()}
          x={xPos}
          y={barHeight < 0 ? y(0) : yPos}
        />
      </Tooltip>
      <Tooltip
        content={
          <EstimateChartHoverContent
            categoryCostData={data}
            fieldsToBold={['estimate', 'runningTotal', 'accepted']}
          />
        }
      >
        <line
          className="stroke-entities-estimate"
          strokeDasharray="4 2"
          strokeWidth={3}
          x1={xPos}
          x2={xPos + x.bandwidth()}
          y1={estimateYPos}
          y2={estimateYPos}
        />
      </Tooltip>
      <Tooltip
        content={
          <EstimateChartHoverContent categoryCostData={data} fieldsToBold={['budget', 'gap']} />
        }
      >
        <line
          className="stroke-entities-budget"
          strokeWidth={7}
          x1={xPos}
          x2={xPos + x.bandwidth()}
          y1={budgetYPos}
          y2={budgetYPos}
        />
      </Tooltip>
      <Tooltip
        content={
          <EstimateChartHoverContent
            categoryCostData={data}
            fieldsToBold={['runningTotal', 'accepted', 'estimate']}
          />
        }
      >
        <line
          className="stroke-entities-estimate"
          strokeWidth={5}
          x1={xPos}
          x2={xPos + x.bandwidth()}
          y1={runningTotalYPos}
          y2={runningTotalYPos}
        />
      </Tooltip>
      {hasArrow && (
        <text
          fill="entities-estimate"
          fontSize="32"
          textAnchor="middle"
          x={xPos + x.bandwidth() / 2}
          y={arrowYPos}
        >
          {arrowDirection === 'up' ? '↑' : '↓'}
        </text>
      )}
    </g>
  );
}
