import { FC, useMemo, useState } from 'react';

import ChartsVerticalBarGraph from '../../Charts/ChartsD3/ChartsVerticalBarGraph/ChartsVerticalBarGraph';
import {
  ChartTooltip,
  chartTooltipDefault,
} from '../../Charts/ChartsD3/ChartsVerticalBarGraph/ChartsVerticalBarGraphUtils';
import ChartsLegend from '../../Charts/ChartsLegend/ChartsLegend';
import {
  formatLegendDataFromStrings,
  getLegendElementColorFunction,
} from '../../Charts/ChartsLegend/ChartsLegendUtils';
import {
  ItemBreakdowns,
  ProjectMap,
  calculateCurrentItemsBreakdown,
  currentItemBreakdownChartSize,
  formatItemStatusStackMap,
  itemStatusColorMap,
} from '../ExecutiveDashboardUtils';

import ExecutiveDashboardCurrentItemsBreakdownHint from './ExecutiveDashboardCurrentItemsBreakdownHint';
import ExecutiveDashboardCurrentItemsBreakdownTooltip from './ExecutiveDashboardCurrentItemsBreakdownTooltip';

type ExecutiveDashboardCurrentItemsBreakdownProps = {
  currentItemsBreakdown: ItemBreakdowns | null;
  projectMap?: ProjectMap;
};

const ExecutiveDashboardCurrentItemsBreakdown: FC<ExecutiveDashboardCurrentItemsBreakdownProps> = ({
  currentItemsBreakdown,
  projectMap,
}) => {
  // store the project that the user is hovering over
  const [chartTooltip, setChartTooltip] = useState<ChartTooltip>(chartTooltipDefault);
  // if the user is overing over a bar in the chart, find the chart
  const tooltipProjectTotals = useMemo(
    () =>
      currentItemsBreakdown && chartTooltip.projectID
        ? currentItemsBreakdown.projectTotals.find((p) => p.project.id === chartTooltip.projectID)
        : undefined,
    [currentItemsBreakdown, chartTooltip]
  );
  // convert the data to the correct type, and only take the top 10 projects
  const { data, projectCostRanges } = calculateCurrentItemsBreakdown(currentItemsBreakdown);

  const getLegendElementColor = getLegendElementColorFunction(itemStatusColorMap);
  return (
    <>
      <ChartsLegend
        getLegendElementColor={getLegendElementColor}
        legendElements={formatLegendDataFromStrings(Array.from(itemStatusColorMap.keys()))}
      />
      <>
        <ChartsVerticalBarGraph
          chartSize={currentItemBreakdownChartSize}
          data={data}
          projectCostRanges={projectCostRanges}
          projectMap={projectMap}
          setChartTooltip={setChartTooltip}
          stackMap={formatItemStatusStackMap(itemStatusColorMap)}
        />
        {false && !chartTooltip.isTitle && (
          <ExecutiveDashboardCurrentItemsBreakdownHint
            chartTooltip={chartTooltip}
            projectTotal={tooltipProjectTotals}
            setChartTooltip={setChartTooltip}
          />
        )}
        {chartTooltip.isTitle && (
          <ExecutiveDashboardCurrentItemsBreakdownTooltip
            chartTooltip={chartTooltip}
            projectCostRanges={projectCostRanges}
            projectMap={projectMap}
            setChartTooltip={setChartTooltip}
          />
        )}
      </>
    </>
  );
};

export default ExecutiveDashboardCurrentItemsBreakdown;
