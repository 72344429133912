import { lowerCase } from 'lodash';
import { titleCase } from 'title-case';

import { FilesDialogView } from '../../../../api/apollo/reactiveVars';
import { isProduction } from '../../../../api/joinAPI';
import { AUTHORIZE } from '../../../../constants';
import { SourceClient, SourceSystem, UserSource } from '../../../../generated/graphql';
import { generateTitle } from '../../../Items/ItemsListItem/ItemsListItemUtils';

// Sources
const PROCORE_PROD_URL = 'https://login.procore.com/oauth/authorize';
const PROCORE_DEV_URL = 'https://sandbox.procore.com/oauth/authorize';
const procoreUrl = isProduction ? PROCORE_PROD_URL : PROCORE_DEV_URL;

export const sourceSystemInfo = {
  [SourceSystem.AUTODESK]: {
    url: `https://developer.api.autodesk.com/authentication/v2/authorize`,
    img: '/img/sources/autodesk.png',
    scope: 'data:read',
  },
  [SourceSystem.EGNYTE]: {
    url: 'https://us-partner-integrations.egnyte.com/services/oauth/code',
    img: '/img/sources/egnyte.png',
    scope: 'Egnyte.filesystem Egnyte.permission Egnyte.projectfolders', // We should be able to limit this in order with v1 goals
  },
  [SourceSystem.PROCORE]: {
    url: procoreUrl,
    img: '/img/sources/procore.png',
  },
};

export type SourceInfo = {
  sourceSystem: SourceSystem;
  url: string;
  clientID: string;
  img: string;
  scope?: string;
};

export const getSourcesInfo: (clients: SourceClient[]) => SourceInfo[] = (clients) =>
  [...clients]
    .sort((a, b) => a.sourceSystem.localeCompare(b.sourceSystem))
    .map(({ sourceSystem, clientID }) => ({
      ...sourceSystemInfo[sourceSystem],
      clientID,
      sourceSystem,
    }));

export const getSourceAuthURL = ({ url, clientID, sourceSystem, scope }: SourceInfo) => {
  const { origin } = window.location;
  const redirectURI = `${origin}/${AUTHORIZE}/${lowerCase(sourceSystem)}/`;
  let params = `client_id=${clientID}&redirect_uri=${redirectURI}&response_type=code`;
  if (scope) {
    params = `${params}&scope=${scope}`;
  }
  return `${url}?${params}`;
};

// Dialog
export const getSourceNameText = (key: SourceSystem | FilesDialogView) =>
  titleCase(String(key).toLowerCase());

export const getHeaderText = (item?: ItemLike | ItemLink | null, milestone?: Milestone | null) => {
  if (item) return `Add files to ${generateTitle(item)}`;
  if (milestone) return `Add files to ${milestone.name}`;
  return 'Add files';
};

export const getCurrentUserSource = (userSources?: UserSource[], key?: string) =>
  userSources?.find((source) => source.sourceSystem === key);

export const getSourceButtonText = (key: SourceSystem | FilesDialogView, userSource?: UserSource) =>
  `${getSourceNameText(key)}${userSource?.email ? ` (${userSource.email})` : ''}`;
