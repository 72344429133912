import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../theme/komodo-mui-theme';

const styles = (theme: KomodoTheme) =>
  createStyles({
    compactInput: {
      height: '18px !important',
      textAlign: 'right',
      ...theme.typography.number,
    },
    dash: {
      color: 'inherit',
      padding: `0 ${theme.spacing.generic[4]}px`,
      textAlign: 'right',
      ...theme.typography.number,
    },
    down: {
      color: theme.palette.costDown,
    },
    fullWidth: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      width: '100%',
    },
    icon: {
      height: 20,
      padding: '7px 0',
    },
    input: {
      textAlign: 'right',
      width: 150,
      ...theme.typography.number,
    },
    number: {
      color: 'inherit',
      textAlign: 'right',
      ...theme.typography.number,
    },
    range: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
    },
    root: {
      display: 'flex',
      flexDirection: 'column',
    },
    rootWidth: {
      minWidth: 'min-content',
    },
    signFlip: {
      height: '40px',
      width: '40px',
    },
    spacer: {
      flexGrow: 1,
    },
    totalNumber: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      color: 'inherit',
      textAlign: 'right',
      ...theme.typography.h4,
      ...theme.typography.number,
    },
    up: {
      color: theme.palette.costUp,
    },
  });

export default styles;
