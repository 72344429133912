import { useEffect, useRef, useState } from 'react';
import * as React from 'react';

import { ProjectCompIntersectionElements } from '../constants/elementIdentifiers';

const intersectionIsValidForClassName = (className: string) => (en: IntersectionObserverEntry) =>
  en.target.classList.contains(className) && en.boundingClientRect.width > 0;

const useProjectCompsIntersectionObserver = (scrollContainer: React.RefObject<HTMLDivElement>) => {
  const projectCompIntersectionObserver = useRef<IntersectionObserver>();
  const [showStickyProjectName, setActivateHeadingPortal] = useState(false);
  const [showStickyCostsHeader, setActivateGridPortal] = useState(false);
  const [showStickyCostsFooter, setActivateFooterPortal] = useState(false);
  useEffect(() => {
    projectCompIntersectionObserver.current = new IntersectionObserver(
      (e) => {
        const projectNameIntersection = e.find(
          intersectionIsValidForClassName(ProjectCompIntersectionElements.PROJECT_NAME)
        );
        const costFooterIntersection = e.find(
          intersectionIsValidForClassName(ProjectCompIntersectionElements.COST_TABLE_FOOTER)
        );
        const costHeaderIntersection = e.find(
          intersectionIsValidForClassName(ProjectCompIntersectionElements.COST_TABLE_HEADER)
        );

        let activateFooterFromHeaderIntersection: boolean | null = null;
        if (costHeaderIntersection) {
          activateFooterFromHeaderIntersection =
            costHeaderIntersection.boundingClientRect.bottom <
            (costHeaderIntersection.rootBounds?.bottom ?? -Infinity);
        }

        let activateFooterFromFooterIntersection: boolean | null = null;
        if (costFooterIntersection) {
          activateFooterFromFooterIntersection =
            costFooterIntersection.boundingClientRect.bottom >
            (costFooterIntersection?.rootBounds?.bottom ?? Infinity);
        }

        if (
          activateFooterFromHeaderIntersection !== null ||
          activateFooterFromFooterIntersection !== null
        ) {
          // Only alter the visibility of the footer when each found intersection indicates to do so.
          setActivateFooterPortal(
            [null, true].includes(activateFooterFromHeaderIntersection) &&
              [null, true].includes(activateFooterFromFooterIntersection)
          );
        }

        // Show the sticky costs header when the costs header disappears (scrolling down), and hide it when
        // the user scrolls back up. This does not handle the case when we might want to hide the costs header
        // again if the user continues to scroll past the costs table. This is currently not possible as the
        // costs table is the last UI item in each comp column
        if (costHeaderIntersection) {
          setActivateGridPortal(
            costHeaderIntersection.boundingClientRect.top <
              (costHeaderIntersection.rootBounds?.top ?? -Infinity)
          );
        }

        // Show the sticky project name when the project name input disappears (scrolling down), and hide it when
        // the user scrolls back up
        if (projectNameIntersection) {
          setActivateHeadingPortal(
            projectNameIntersection.boundingClientRect.top <
              (projectNameIntersection.rootBounds?.top ?? -Infinity)
          );
        }
      },
      {
        rootMargin: '-60px 0px -32px 0px',
        threshold: 1,
        root: scrollContainer.current,
      }
    );
    return () => {
      projectCompIntersectionObserver.current?.disconnect();
    };
  }, [scrollContainer]);

  return {
    projectCompIntersectionObserver,
    showStickyProjectName,
    showStickyCostsHeader,
    showStickyCostsFooter,
    setActivateGridPortal,
    setActivateFooterPortal,
  };
};

export default useProjectCompsIntersectionObserver;
