import { createStyles } from '@material-ui/core';

const CompanyTabStyles = () =>
  createStyles({
    icon: {
      marginLeft: 4,
    },
  });

export default CompanyTabStyles;
