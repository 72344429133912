import { FC } from 'react';

import { Typography } from '@material-ui/core';

import { Status } from '../../../generated/graphql';
import { withStyles } from '../../../theme/komodo-mui-theme';
import { pluralizeCountString } from '../../../utilities/string';
import { IconsOptions } from '../../Icons';
import { styleItemNumber } from '../ItemsListItem/ItemsListItemUtils';
import { getAcceptedIncorporatedOption } from '../ItemsUtils';

import ItemsOptionChipSquareList from './ItemsOptionChipSquareList';
import ItemsOptionChipSquareListAreaStyles from './ItemsOptionChipSquareListAreaStyles';

type Props = {
  classes: Classes<typeof ItemsOptionChipSquareListAreaStyles>;
  parentStatus: Status;
  options: ItemLink[];
};

const ItemsOptionChipSquareListArea: FC<Props> = ({ classes, parentStatus, options }) => {
  // VARIABLES
  const acceptedOrIncorporatedOption = getAcceptedIncorporatedOption(options);

  //  GENERATORS
  const acceptedIncorporatedText = acceptedOrIncorporatedOption && (
    <Typography className={classes.text} variant="caption">
      {`(${styleItemNumber(
        acceptedOrIncorporatedOption.number
      )} ${acceptedOrIncorporatedOption.status.toLowerCase()})`}
    </Typography>
  );

  return (
    <div className={classes.outerContainer} data-cy="list-itemsOptionsChipSquare">
      <div className={classes.optionsSvgContainer}>
        <IconsOptions />
      </div>
      <Typography className={classes.text} variant="caption">
        {pluralizeCountString('option', options.length)}
      </Typography>
      {acceptedIncorporatedText}
      <ItemsOptionChipSquareList options={options} parentStatus={parentStatus} />
    </div>
  );
};

export default withStyles(ItemsOptionChipSquareListAreaStyles)(ItemsOptionChipSquareListArea);
