import { UserByIdQuery, UserByIdQueryVariables } from '../../../generated/graphql';
import { useQuery } from '../../../hooks/useMountAwareQuery';

import { userByIdQuery } from './queries';

export default function useUserByIdQuery(ID: UUID | null | undefined, skip?: boolean) {
  return useQuery<UserByIdQuery, UserByIdQueryVariables>(userByIdQuery, {
    variables: { ID },
    skip: skip || !ID,
    context: { batch: true },
  });
}
