import { createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
const ItemsListStickyHeaderStyles = (theme: any) =>
  createStyles({
    buttonHover: {
      height: theme.iconSizes.medium,
      width: theme.iconSizes.medium,
      boxShadow: '0 0 1px 0px white inset, 0 0 1px 0px white',
    },
    hidePrint: {
      '@media print': {
        display: 'none !important',
      },
    },
    iconSize: {
      fontSize: '15px',
      '@media print': {
        display: 'none !important',
      },
    },
  });

export default ItemsListStickyHeaderStyles;
