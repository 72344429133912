import { ForecastingReportsSortBy, ForecastingReportsSortKey } from '../../../generated/graphql';
import { SortManager } from '../../scales';

export const getSortManager = (
  sortBy: ForecastingReportsSortBy,
  setSortBy: (sortBy: ForecastingReportsSortBy) => void
) => {
  const sortManager: SortManager = {
    setSort: (input) => {
      const { sortDirection } = input;
      let sortKey: ForecastingReportsSortKey;
      switch (input.sortKey) {
        case ForecastingReportsSortKey.AVERAGE_QUANTITY_COST:
        case ForecastingReportsSortKey.NAME:
        case ForecastingReportsSortKey.PINNED_UNIT_QUANTITY_MAGNITUDE:
        case ForecastingReportsSortKey.PROJECTS_COUNT:
          sortKey = input.sortKey;
          break;
        default:
          sortKey = ForecastingReportsSortKey.UPDATED_AT;
          break;
      }
      setSortBy({ sortDirection, sortKey });
    },
    sortState: sortBy,
  };
  return sortManager;
};
