import * as React from 'react';

import useDetectBrowserPrint from '../../../utilities/hooks/detectPrint';
import { GridController } from '../types';

// Used by the JoinGridWrapper to synchronously set the state to what we need it to be for
// printing when printing occurs -- i.e. disabling virtualization and printing all rows in
// the grid.
const usePrintAllRows = (
  tables: (GridController | undefined)[],
  collapseSizeRef: React.RefObject<HTMLDivElement>
) => {
  const originalHeights = tables.map((t) => t && t.maxHeight());

  useDetectBrowserPrint(
    () => {
      tables.forEach((t) => {
        if (t) {
          const totals = t.getRowTotals();
          const height = totals[totals.length - 1] || 1000000;
          t.setMaxHeight(height);
          // eslint-disable-next-line no-param-reassign
          t.isPrinting = true;
          if (collapseSizeRef.current) {
            t.setOverallWidth(collapseSizeRef.current.clientWidth);
          }
        }
      });
    },
    () => {
      tables.forEach((t, i) => {
        if (t) {
          // eslint-disable-next-line no-param-reassign
          t.isPrinting = false;
          const height = originalHeights[i];
          if (height) t.setMaxHeight(height);
        }
      });
    }
  );
};

export default usePrintAllRows;
