import { useMemo, useState } from 'react';

import { Typography } from '@material-ui/core';

import {
  projectCompsAnalyticsEvent,
  projectCompsEventTypes,
} from '../../../analytics/analyticsEventProperties';
import { LocationInput } from '../../../generated/graphql';
import useSendAnalytics from '../../../hooks/useSendAnalytics';
import { withStyles } from '../../../theme/komodo-mui-theme';
import JoinDialog from '../../Dialogs/JoinDialog/JoinDialog';
import JoinSelect from '../../Select/JoinSelect/JoinSelect';
import { EscalationTargetLocation } from '../constants/projectCompTypes';

import styles from './EditLocationDialogStyles';
import { useCityCostIndicesQuery } from './hooks/useCityCostIndicesQuery';
import Warning from './Warning';

const EditLocationTargetDialog = (props: {
  classes: Classes<typeof styles>;
  isFuzzyMatch: boolean;
  onApply: (location: LocationInput) => void;
  onClose: () => void;
  targetLocation: EscalationTargetLocation;
}) => {
  const { classes } = props;
  const sendAnalytics = useSendAnalytics();

  // Fetch all the City Cost Indices
  const { data: cityCostIndicesData } = useCityCostIndicesQuery();
  const cityCostIndices = useMemo(() => {
    return (
      cityCostIndicesData?.cityCostIndices?.cityCostIndices?.map((cci) => ({
        ...cci,
        id: cci.name,
        nameOption: `${cci.index}`,
      })) ?? []
    );
  }, [cityCostIndicesData]);

  // Locally store the currently-selected location.
  const [location, setLocation] = useState<LocationInput>({
    name: props.targetLocation.location,
    lat: props.targetLocation.lat ?? undefined,
    lon: props.targetLocation.lon ?? undefined,
  });

  const isTargetLocationMissing = props.targetLocation.location === '';

  return (
    <JoinDialog
      dialog={{
        actionButtons: [
          {
            color: 'secondary',
            dataCy: 'cancel-button',
            onClick: props.onClose,
            text: 'Cancel',
            variant: 'flat',
          },
          {
            color: 'primary',
            dataCy: 'apply-button',
            disabled: !location.name,
            onClick: () => {
              if (location) {
                props.onApply(location);
              }
            },
            text: 'Apply',
            variant: 'contained',
          },
        ],
        contentComponent: (
          <div className={classes.container}>
            {props.isFuzzyMatch ? (
              <Warning dataCy="fuzzy-location-warning">
                <div>
                  No exact match found in RSMeans Index for project location. Closest city selected.
                  To change, select relevant city from the drop down below.
                </div>
              </Warning>
            ) : null}
            {isTargetLocationMissing ? (
              <div>Projects will escalate to this location when using RSMeans Index.</div>
            ) : (
              <div>
                Changing the target location will also change your average column’s location, if
                present. All project location factors will update to the new target.
              </div>
            )}
            {!isTargetLocationMissing && (
              <div className={classes.locationTo}>
                Escalating to:{' '}
                <strong data-cy="autoescalation-escalating-to-loc">
                  {props.targetLocation.location}
                </strong>
              </div>
            )}
            <div className={classes.locationDetails_targetLocation}>
              <Typography variant="caption">Target Location</Typography>
              <JoinSelect
                classNameSelect={location?.name ? '' : classes.selectLabelPlaceHolder}
                classNameSelectText={classes.locationSelect_selectedValue}
                cySelect="location-select"
                entries={cityCostIndices}
                menuProps={{
                  PaperProps: { className: classes.locationSelect_paper },
                }}
                onChange={(id: string) => {
                  const newLocation = cityCostIndices.find((cci) => cci.id === id);
                  if (!newLocation) return;
                  setLocation(newLocation);
                  sendAnalytics(
                    projectCompsAnalyticsEvent(
                      projectCompsEventTypes.PROJECT_COMPS_ESCALATION_CHANGE_SOURCE_LOCATION,
                      {
                        locationPrev: props.targetLocation?.location,
                        locationNew: newLocation.name,
                      }
                    )
                  );
                }}
                search
                value={location?.name || 'City, State, USA'}
              />
            </div>
          </div>
        ),
        headerText: isTargetLocationMissing ? 'Set a target location' : 'Location target',
        onClose: props.onClose,
        open: true,
      }}
      dynamicHeight
    />
  );
};

export default withStyles(styles)(EditLocationTargetDialog);
