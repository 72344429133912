import { SVGGSelection, SVGSVGSelection } from './types';

export const updateHorizontalLine = (
  axisSvg: SVGSVGSelection | SVGGSelection,
  width: number,
  top: number
) => {
  // Add axis line
  axisSvg
    .append('line')
    .attr('class', 'x-axis stroke-chart-axis')
    .attr('shape-rendering', 'geometricPrecision')
    .attr('x1', 16)
    .attr('x2', width - 16)
    .attr('y1', top)
    .attr('y2', top);
};

export const updateVerticalLine = (
  axisSvg: SVGSVGSelection | SVGGSelection,
  height: number,
  left: number
) => {
  // Add axis line
  axisSvg
    .append('line')
    .attr('class', 'x-axis stroke-chart-axis stroke')
    .attr('shape-rendering', 'geometricPrecision')
    .attr('x1', left)
    .attr('x2', left)
    .attr('y1', 0)
    .attr('y2', height);
};
