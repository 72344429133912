import { useMemo } from 'react';

import { ProjectCompaniesQuery, ProjectCompaniesQueryVariables } from '../generated/graphql';

import { getProjectCompaniesQuery } from './queries';
import useCollaboratorsQuery from './useCollaboratorsQuery';
import { useQuery } from './useMountAwareQuery';

export const useProjectCompaniesQuery = (projectID: UUID) =>
  useQuery<ProjectCompaniesQuery, ProjectCompaniesQueryVariables>(getProjectCompaniesQuery, {
    variables: { projectID },
  });

export const useCompaniesAndCollaborators = (projectID: UUID) => {
  const collaboratorsQueryResult = useCollaboratorsQuery(projectID);
  const collaborators =
    collaboratorsQueryResult.data?.collaborators ??
    collaboratorsQueryResult.previousData?.collaborators;

  const companiesQueryResult = useProjectCompaniesQuery(projectID);
  const companies =
    companiesQueryResult.data?.projectCompanies ??
    companiesQueryResult.previousData?.projectCompanies;

  const companiesWithCollaborators = useMemo(
    () =>
      (companies ?? []).map((c) => ({
        ...c,
        collaborators: (collaborators ?? []).filter((collaborator) =>
          collaborator.user.email.includes(`@${c.company.domain}`)
        ),
      })),
    [collaborators, companies]
  );

  return {
    collaborators: collaborators ?? [],
    companies: companiesWithCollaborators,
    loading: collaboratorsQueryResult.loading || companiesQueryResult.loading,
  };
};
