import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../../../theme/komodo-mui-theme';
import {
  CATEGORIZATIONS_WIDTH,
  HEADER_PADDING,
  QUANTITY_WIDTH,
  REPORT_WIDTH,
  UNIT_WIDTH,
} from '../MilestoneDetailsQuantitiesStyles';
import { HEADER_CONTAINER_HEIGHT } from '../MilestoneDetailsQuantitiesUtils';

const QUANTITY_TEXT_PADDING = 6;

const MilestoneDetailsQuantitiesCollapseHeaderStyles = (theme: KomodoTheme) =>
  createStyles({
    canExpand: {
      cursor: 'pointer',
    },
    container: {
      display: 'flex',
      minHeight: HEADER_CONTAINER_HEIGHT,
    },
    header: {
      display: 'flex',
      alignItems: 'center',
      height: '100%',
      outline: 'none !important',
      pageBreakInside: 'avoid',
      justifyContent: 'space-between',
    },
    headerContainer: {
      backgroundColor: theme.palette.backgroundWhite,
      borderBottom: `1px solid ${theme.palette.joinGrey400}`,
      width: '100%',
      pageBreakInside: 'avoid',
    },
    headerRowCenter: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
    headerRowContainer: {
      display: 'flex',
    },
    headerRowRight: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
    linkContainer: {
      paddingRight: 4,
    },
    rowTitle: {
      alignItems: 'center',
      display: 'flex',
      paddingLeft: 4,
      minWidth: QUANTITY_WIDTH,
    },
    rowTitleInner: {
      display: 'flex',
      width: '100%',
    },
    quantityContainer: {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      minHeight: HEADER_CONTAINER_HEIGHT,
      background: theme.palette.backgroundWhite,
    },
    quantityGroupBy: {
      display: 'flex',
      alignSelf: 'center',
      paddingRight: HEADER_PADDING,
      minWidth: CATEGORIZATIONS_WIDTH,
    },
    quantityGroupByText: {
      paddingLeft: HEADER_PADDING,
    },
    disabled: {
      color: theme.palette.disabledGrey,
    },
    quantityText: {
      width: QUANTITY_WIDTH - HEADER_PADDING,
      paddingRight: QUANTITY_TEXT_PADDING,
      textAlign: 'right',
    },
    quantityValue: {
      display: 'flex',
      justifyContent: 'flex-end',
      paddingRight: HEADER_PADDING,
    },
    reportsContainer: {
      display: 'flex',
      flexFlow: 'wrap',
      justifyContent: 'flex-end',
      minWidth: REPORT_WIDTH,
      paddingRight: HEADER_PADDING * 2,
    },
    title: {
      flexGrow: 1,
      alignSelf: 'center',
      paddingLeft: 5,
      paddingTop: 3,
    },
    unitContainer: {
      dislay: 'flex',
      flex: `1 0 ${UNIT_WIDTH}`,
      minWidth: UNIT_WIDTH,
    },
  });

export default MilestoneDetailsQuantitiesCollapseHeaderStyles;
