import { CostReportColumnType } from '../../../../../generated/graphql';
import { getCostReportByType, getCostValue } from '../../../../CostReport/CostReportUtils';
import { getScenarioName } from '../../../common/ScenariosUtils';
import { Scenario, ScenarioMilestone } from '../../../types';
import { BASE_MILESTONE } from '../../MilestoneCard';

import { BarRunningTotal } from './ScenariosChartUtils';

export type SandboxChartProps = {
  milestone: Pick<ScenarioMilestone, 'milestoneID' | 'costReports'>;
  scenarios: Pick<Scenario, 'scenarioID' | 'color' | 'costReports' | 'name'>[];
};

export const getChartPropsFromSandbox = (
  sandbox: SandboxChartProps,
  milestoneName?: string,
  showMilestone = true
) => {
  const { milestone, scenarios } = sandbox;
  const scenarioBRTs: BarRunningTotal[] = scenarios.map((scenario) => {
    const runningTotalCR = scenario.costReports.costReportColumns.find(
      (c) => c.type === CostReportColumnType.RUNNINGTOTAL_REPORT
    );
    return {
      name: getScenarioName(scenario),
      id: scenario.scenarioID,
      value: getCostValue(runningTotalCR?.report?.range),
      color: scenario.color ?? undefined,
    };
  });
  if (!showMilestone) {
    return { barRunningTotals: scenarioBRTs, estimate: 0, budget: 0 };
  }
  const cr = milestone?.costReports;
  const estimateCR = getCostReportByType(cr, CostReportColumnType.ESTIMATE_REPORT);
  const budgetCR = getCostReportByType(cr, CostReportColumnType.TARGET_REPORT);
  const runningTotalCR = getCostReportByType(cr, CostReportColumnType.RUNNINGTOTAL_REPORT);
  const estimate = getCostValue(estimateCR?.range);
  const budget = getCostValue(budgetCR?.range);
  const runningTotal = getCostValue(runningTotalCR?.range);
  const milestoneBRT: BarRunningTotal = {
    name: milestoneName || BASE_MILESTONE,
    value: runningTotal,
    id: milestone.milestoneID,
  };
  const barRunningTotals: BarRunningTotal[] = [milestoneBRT, ...scenarioBRTs];
  return { barRunningTotals, estimate, budget };
};
