import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';
import { tableStyles } from '../../../theme/table-styles';

const ProjectCategorizationsStyles = (theme: KomodoTheme) => {
  const { cell } = tableStyles(theme);
  return createStyles({
    cell,
    list: {
      overflowY: 'scroll',
      border: theme.border.line,
    },
    minHeight: {
      minHeight: 370,
    },
    compactListContent: {
      width: '100%',
    },
    container: {
      backgroundColor: theme.palette.backgroundWhite,
    },
    dataCell: {
      maxWidth: 120,
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
    dataElement: {
      paddingRight: 8,
      paddingLeft: 4,
      color: theme.palette.primaryGrey,
    },

    emptyHeaderCell: {
      minWidth: 66,
    },

    headerCell: {
      background: 'none',
      paddingLeft: 4,
      maxWidth: 'unset',
      textAlign: 'left',
    },
    linkTitle: {
      color: theme.palette.joinPrimary,
    },
    nameCell: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      alignSelf: 'center',
    },
    nullAsset: {
      width: 98,
      objectFit: 'cover',
      backgroundColor: theme.palette.backgroundGrey,
    },
    projectCell: {
      display: 'flex',
      flex: '1 1 auto',
      verticalAlign: 'middle',
      justifyContent: 'space-between',
      padding: '8px 0px',
    },
    smallDataElement: {
      paddingRight: 12,
      paddingLeft: 4,
      color: theme.palette.primaryGrey,
      display: 'flex',
      flexFlow: 'column',
      textAlign: 'left',
      flex: 1,
      minWith: 0,
    },
    textOverflow: {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
    projectRow: {
      margin: 3,
      backgroundColor: theme.palette.backgroundWhite,
      display: 'flex',
      justifyContent: 'space-between',
      border: theme.border.line,
    },

    title: {
      color: theme.palette.joinPrimary,
      fontSize: theme.typography.inputs.fontSize,
      fontWeight: theme.typography.title.fontWeight,
      display: 'inline-flex',
      midWidth: 0,
      alignItems: 'center',
    },
  });
};

export default ProjectCategorizationsStyles;
