import { createStyles } from '@material-ui/core';

const styles = () =>
  createStyles({
    detailsContainer: {
      paddingLeft: 8,
    },
    optionsContainer: {
      paddingTop: 16,
      paddingLeft: 8,
    },
  });

export default styles;
