import {
  LoadUserInAppNotificationSettingQuery,
  LoadUserInAppNotificationSettingQueryVariables,
} from '../generated/graphql';

import { loadUserInAppNotificationSettingQuery } from './queries';
import { useQuery } from './useMountAwareQuery';

const useUserInAppNotificationSettingQuery = (projectID: UUID | null) =>
  useQuery<LoadUserInAppNotificationSettingQuery, LoadUserInAppNotificationSettingQueryVariables>(
    loadUserInAppNotificationSettingQuery,
    {
      variables: { projectID },
      skip: !projectID,
    }
  );

export default useUserInAppNotificationSettingQuery;
