import { FC } from 'react';

import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';

import JoinSlide from '../../shared-widgets/JoinSlide';

import styles from './SelectFilterStyles';

interface SelectFilterMultiOptionProps {
  classes: Classes<typeof styles>;
  depth?: number;
  filterOption: string;
  filterLabel: string;
  handleClick: () => void;
  handleHover: (filterOption: string) => void;
  highlightedFilter: string;
  isIndeterminate: boolean;
  isSelected: boolean;
  formatValueForDisplay?: (value: string) => string;
}

const SelectFilterMultiOption: FC<SelectFilterMultiOptionProps> = ({
  classes,
  depth,
  filterOption,
  filterLabel,
  handleClick,
  handleHover,
  highlightedFilter,
  isIndeterminate,
  isSelected,
  formatValueForDisplay,
}) => {
  const formatOption = formatValueForDisplay ? formatValueForDisplay(filterOption) : filterOption;

  const isHighlightedOption = (highlighted: string): boolean => formatOption === highlighted;

  const isHighlighted = isHighlightedOption(highlightedFilter);

  const highlightedClass = isHighlighted && classes.filterHighlighted;

  const checkbox = (
    <Checkbox
      checked={isSelected}
      classes={{
        root: classes.checkboxRoot,
      }}
      data-cy={`SelectFilterMultiOption-${formatOption.replace(/\W/g, '')}`}
      indeterminate={isIndeterminate}
      style={{ paddingLeft: depth ? depth * 16 : 0 }}
    />
  );

  return (
    <JoinSlide direction="right">
      <li
        key={formatOption}
        aria-selected={isSelected}
        className={` ${highlightedClass} ${classes.filterOption}`}
        role="option"
        value={filterLabel || formatOption}
      >
        <span
          aria-selected={isSelected}
          className={`${classes.filterOptionTitle}`}
          onClick={() => {
            handleClick();
          }}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
          onKeyDown={(event: any) => {
            const { key } = event;
            if (key === 'Enter') {
              handleClick();
            }
          }}
          onMouseEnter={() => {
            handleHover(filterOption);
          }}
          role="option"
          tabIndex={0}
          title={formatOption}
        >
          {checkbox}
          <span>{filterLabel || formatOption}</span>
        </span>
      </li>
    </JoinSlide>
  );
};

export default withStyles(styles)(SelectFilterMultiOption);
