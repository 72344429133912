import { useNavigate } from 'react-router-dom';

import { DeleteOutline } from '@material-ui/icons';

import {
  CompanyAdminEventType,
  companyAdminAnalyticsEvent,
} from '../../../../analytics/analyticsEventProperties';
import { Org, OrgNode, ProjectOrgNodeInfo } from '../../../../generated/graphql';
import useSendAnalytics from '../../../../hooks/useSendAnalytics';
import {
  TreeEntry,
  collectEntriesByDepth,
  flatten,
  getTreeChildrenById,
} from '../../../../utilities/utilities';
import { Button, Chip, Dialog, DialogContent } from '../../../scales';
import useMemoWrapper from '../../../useMemoWrapper';
import { getProjectStatsLink } from '../../CompanyTabUtils';
import useDeleteOrgNodeMutation from '../hooks/useDeleteOrgNodeMutation';
import { useGetProjectsUsingOrgNodeQuery } from '../hooks/useGetProjectsUsingOrgNodeQuery';

export type Props = {
  allNodes: TreeEntry<OrgNode>[];
  isOpen: boolean;
  nodeDelete: TreeEntry<OrgNode>;
  onClose: () => void;
  organization: Org;
};

export default function DeleteOrgNodeDialog(props: Props) {
  const sendAnalytics = useSendAnalytics();
  const navigate = useNavigate();
  const deleteOrgNode = useDeleteOrgNodeMutation();
  const { data, loading } = useGetProjectsUsingOrgNodeQuery(props.nodeDelete.id);
  const allEntries = flatten(props.allNodes);
  const childrenEntries = getTreeChildrenById(props.allNodes, props.nodeDelete.id);
  const childrenByDepth = collectEntriesByDepth(childrenEntries);

  const levelIndex = props.nodeDelete.depth - 1;
  const levelName = props.organization.levels[levelIndex];
  const groupedNodesinProjects = useMemoWrapper(
    groupProjectsByNodeID,
    allEntries,
    data?.getProjectsUsingOrgNode
  );

  const onDelete = () => {
    deleteOrgNode(props.nodeDelete.id);
    props.onClose?.();
    sendAnalytics(
      companyAdminAnalyticsEvent(CompanyAdminEventType.ORGANIZATIONS_DELETE_CONFIRM_DELETE, {
        node: props.nodeDelete.name,
      })
    );
  };

  const onClose = () => {
    props.onClose?.();
    sendAnalytics(
      companyAdminAnalyticsEvent(CompanyAdminEventType.ORGANIZATIONS_DELETE_CONFIRM_CLOSE, {
        node: props.nodeDelete.name,
      })
    );
  };

  const onProjectStats = () => {
    const nodeIDUsedInProjects = [...new Set(data?.getProjectsUsingOrgNode?.map((n) => n.nodeID))]; // Ensure duplicate nodeIDs are removed
    navigate(
      getProjectStatsLink({
        orgNodeIDs: nodeIDUsedInProjects || [],
      })
    );
    props.onClose?.();
    sendAnalytics(
      companyAdminAnalyticsEvent(CompanyAdminEventType.ORGANIZATIONS_DELETE_CONFIRM_PROJECT_STATS, {
        node: props.nodeDelete.name,
      })
    );
  };

  const publishedOrgNote = props.organization.isDraft
    ? ''
    : 'Once deleted, teammates will no longer be able to make these selections for this organization.';

  const renderNodeProjects = (node: GroupedProjectNodeInfo) => {
    const isNodeSelectedForDeletion = node.nodeID === props.nodeDelete.id;
    return (
      <div
        key={node.nodeID}
        className={isNodeSelectedForDeletion ? '' : 'pl-4'}
      >{`${node.nodeName} (${node.projects.length})`}</div>
    );
  };

  if (loading) return null;

  if (groupedNodesinProjects.length) {
    return (
      <Dialog
        footerLeft={
          <Button label="Go to Project Stats" onClick={onProjectStats} type="secondary" />
        }
        footerRight={
          <Button
            label="Proceed with Delete"
            onClick={onDelete}
            startIcon={<DeleteOutline />}
            type="destructive"
          />
        }
        isOpen={props.isOpen}
        onClose={onClose}
        title={`Delete ${levelName}: ${props.nodeDelete.name}`}
      >
        <DialogContent className="flex flex-col gap-2">
          <div className="flex flex-col gap-3">
            <div className="flex type-body1">
              {`There are still projects assigned to this ${levelName} and/or its related child nodes. Deleting this ${levelName} will reassign all relevant projects to the parent.`}
            </div>
            <div className="type-heading3">{groupedNodesinProjects.map(renderNodeProjects)}</div>
            <div>
              You can use the Project Stats button below to reassign projects or proceed with
              deleting the state and its related child nodes.
            </div>
            {publishedOrgNote}
            <ChildNodesToDelete
              childrenByDepth={childrenByDepth}
              levelNames={props.organization.levels}
            />
          </div>
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Dialog
      footerRight={
        <Button
          label="Delete"
          onClick={onDelete}
          startIcon={<DeleteOutline />}
          type="destructive"
        />
      }
      isOpen={props.isOpen}
      onClose={onClose}
      title={`Delete ${levelName}: ${props.nodeDelete.name}`}
    >
      <DialogContent className="flex flex-col gap-2">
        <div>
          <div className="pb-4 type-body1">
            {`Are you sure? By deleting this ${levelName}, this ${levelName} and the related child nodes below
            will be deleted. ${publishedOrgNote}`}
          </div>
          <ChildNodesToDelete
            childrenByDepth={childrenByDepth}
            levelNames={props.organization.levels}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
}

type ChildNodesToDeleteProps = {
  childrenByDepth: {
    depth: number;
    nodes: TreeEntry<OrgNode>[];
  }[];
  levelNames: string[];
};

const ChildNodesToDelete: React.FC<ChildNodesToDeleteProps> = ({ childrenByDepth, levelNames }) => {
  if (childrenByDepth.length === 0) {
    return null;
  }
  return (
    <div>
      Child nodes also being deleted:
      {childrenByDepth.map(({ depth, nodes }) => (
        <div key={depth} className="flex flex-col">
          {nodes.length > 0 && (
            <div className="flex flex-row items-center pt-1">
              {depth > 0 && (
                <div className="mr-2 flex flex-shrink-0">
                  <Chip text={levelNames[depth - 1]} />
                </div>
              )}
              <div className="flex flex-wrap type-heading3">
                {nodes.map((node, index) => (
                  <div key={node.id} className="flex items-center type-heading3">
                    {node.label}
                    {index !== nodes.length - 1 && <div>,&nbsp;</div>}
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

type GroupedProjectNodeInfo = {
  nodeID: UUID;
  nodeName: string;
  depth: number;
  projects: {
    name: string;
    projectID: UUID;
  }[];
};

const groupProjectsByNodeID = (
  orgNodes: TreeEntry<OrgNode>[],
  getProjectsUsingOrgNode: ProjectOrgNodeInfo[] | undefined
): GroupedProjectNodeInfo[] => {
  // Create a map to hold the grouped projects by nodeID
  const groupedProjectsMap: Record<string, GroupedProjectNodeInfo> = {};

  if (!getProjectsUsingOrgNode) return Object.values(groupedProjectsMap);

  // Loop through getProjectsUsingOrgNode to group projects by nodeID
  getProjectsUsingOrgNode.forEach((project) => {
    if (!groupedProjectsMap[project.nodeID]) {
      groupedProjectsMap[project.nodeID] = {
        nodeID: project.nodeID,
        nodeName: '',
        depth: 0,
        projects: [],
      };
    }
    groupedProjectsMap[project.nodeID].projects.push({
      name: project.name,
      projectID: project.projectID,
    });
  });

  // Loop through orgNodes to link nodeID to node name
  orgNodes.forEach((node) => {
    if (groupedProjectsMap[node.id]) {
      groupedProjectsMap[node.id].nodeName = node.label;
      groupedProjectsMap[node.id].depth = node.depth;
    }
  });

  // Convert the map to an array
  return Object.values(groupedProjectsMap).sort((a, b) => a.depth - b.depth);
};
