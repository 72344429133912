import { FC } from 'react';

import { HelpOutlined } from '@material-ui/icons';

import { Tooltip } from '../../scales';

const EstimateTotalHeader: FC = () => {
  return (
    <div>
      Project Total
      <Tooltip content="Project Total (includes Cost of Construction and Owner Costs)">
        <span>
          <HelpOutlined className="mb-0.5 ml-1 !fill-button-icon-outline !icon-sm" />
        </span>
      </Tooltip>
    </div>
  );
};

export default EstimateTotalHeader;
