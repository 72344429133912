import { useCallback, useMemo } from 'react';

import {
  SearchCategoryFilterLabelsQuery,
  SearchCategoryFilterLabelsQueryVariables,
} from '../../../../../generated/graphql';
import { useQuery } from '../../../../../hooks/useMountAwareQuery';

import { searchCategoryFilterLabels } from './queries';

export const useSearchItemsCategoryLabels = () => {
  const { data } = useQuery<
    SearchCategoryFilterLabelsQuery,
    SearchCategoryFilterLabelsQueryVariables
  >(searchCategoryFilterLabels, { errorPolicy: 'all' });

  const labelMap = useMemo(() => {
    const map = new Map<string, string>();
    if (data?.searchCategoryFilterLabels)
      data.searchCategoryFilterLabels.forEach((value) => map.set(value.key, value.label));
    return map;
  }, [data]);

  return useCallback((id: string) => labelMap.get(id) ?? id, [labelMap]);
};
