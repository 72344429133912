import { Add } from '@material-ui/icons';

import { Org } from '../../../../generated/graphql';
import { Button } from '../../../scales';
import useCreateOrgNodeMutation from '../hooks/useCreateOrgNodeMutation';

export default function OrganizationDetailsFooter(props: { organization: Org }) {
  const addOrgNode = useCreateOrgNodeMutation();
  return (
    <div className="w-full bg-background-primary pb-2 pl-4 pt-2">
      <Button
        label={`Add ${props.organization.levels[0]}`}
        onClick={() => {
          addOrgNode(props.organization.id, null);
        }}
        startIcon={<Add />}
        type="secondary"
      />
    </div>
  );
}
