import { FC } from 'react';

import { UPLOAD_ATTACH_MILESTONE_ASSET } from '../../../../actions/actionTypes';
import { getMilestoneAssetAnalytics } from '../../../../analytics/analyticsEventProperties';
import { UserSource } from '../../../../generated/graphql';
import { useMilestoneUploadAttachAssets } from '../../../assets/hooks/AssetsMutationHook';
import useOptimisticProjectUploadAssets from '../../../assets/hooks/useOptimisticProjectUploadAssets';

import FilesDialogSidebar from './FilesDialogSidebar';

type FilesDialogSidebarMilestoneWrapperProps = {
  milestoneID: UUID;
  userSources?: UserSource[];
};

const FilesDialogSidebarMilestoneWrapper: FC<FilesDialogSidebarMilestoneWrapperProps> = ({
  milestoneID,
  userSources,
}) => {
  const analyticsEvent = getMilestoneAssetAnalytics(UPLOAD_ATTACH_MILESTONE_ASSET, milestoneID);
  const { onDropFile, uploadingAssets } = useMilestoneUploadAttachAssets(
    milestoneID,
    analyticsEvent
  );

  useOptimisticProjectUploadAssets(uploadingAssets);

  return <FilesDialogSidebar onDropFile={onDropFile} userSources={userSources} />;
};

export default FilesDialogSidebarMilestoneWrapper;
