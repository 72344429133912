import { JoinCompanyOrganizationsRoutes } from '../../../api/gqlEnums';
import { Org } from '../../../generated/graphql';
import { generateSharedPath } from '../../../utilities/routes/links';
import { Chip, ListDetailComponents } from '../../scales';

export default function OrganizationLink(props: Pick<Org, 'id' | 'isDraft' | 'name'>) {
  const { id, isDraft, name } = props;
  return (
    <ListDetailComponents.Link
      key={id}
      endAdornment={
        isDraft && (
          <div>
            <Chip text="Draft" />
          </div>
        )
      }
      label={name}
      to={generateSharedPath(JoinCompanyOrganizationsRoutes.COMPANY_ORGANIZATION, {
        organizationId: id,
      })}
    />
  );
}
