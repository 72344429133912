import { gql, itemHistoryLinkFields, itemLinkFields } from '../../../../api/graphqlFragments';

export const loadItemLinksQuery = gql`
  query loadItemLinks($projectID: UUID!) {
    itemLinks(projectID: $projectID) {
      ...itemLinkFields
    }
  }
  ${itemLinkFields}
`;

export const loadItemHistoryLinksQuery = gql`
  query loadItemHistoryLinks($projectID: UUID!) {
    itemHistoryLinks(projectID: $projectID) {
      ...itemHistoryLinkFields
    }
  }
  ${itemHistoryLinkFields}
`;
