import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';

// TODO: The variants are rather messy. We should clean this up.
export const ICON_GRID_MARGIN_RIGHT = 7;

export default (theme: KomodoTheme) =>
  createStyles({
    sidebarHeaderIcon: {
      backgroundColor: theme.palette.backgroundWhite,
      color: theme.palette.joinPrimary,
      height: theme.iconSizes.medium,
      width: theme.iconSizes.medium,
    },
    menuOutline: theme.roundButton,
    compact: {
      height: theme.iconSizes.compact,
      width: theme.iconSizes.compact,
      background: theme.palette.backgroundWhite,
    },
    bottomOriented: {
      paddingTop: 16,
    },
    asset: {
      height: theme.iconSizes.compact,
      width: theme.iconSizes.compact,
      background: theme.palette.backgroundWhite,
      border: `solid ${theme.palette.joinGrey400} 1px`,
      marginRight: ICON_GRID_MARGIN_RIGHT,
      '&:hover': {
        border: theme.border.lineBorderBlack,
        background: theme.palette.backgroundGrey,
      },
      '&:focus': {
        border: theme.border.focus,
        background: theme.palette.hoverBackground,
      },
    },
    iconGrid: {
      height: theme.iconSizes.compact,
      width: theme.iconSizes.compact,
      border: `solid ${theme.palette.joinGrey400} 1px`,
      marginRight: ICON_GRID_MARGIN_RIGHT,
      '&:hover': {
        border: theme.border.lineBorderBlack,
        background: theme.palette.backgroundGrey,
      },
      '&:focus': {
        border: theme.border.focus,
        background: theme.palette.hoverBackground,
      },
    },
    menuItem: {
      zIndex: 2,
    },
    open: {
      visibility: 'visible',
    },
    root: {
      border: theme.border.lineBorderBlack,
    },
    tooltipDiv: {
      pointerEvents: 'auto',
    },
    tooltipText: {
      fontSize: '0.8rem',
      padding: '2px 0px',
    },
    white: {
      color: theme.palette.backgroundWhite,
    },
  });
