import { useMutation } from '@apollo/client';

import { refetchActiveProjectBanner } from '../../../../api/refetchSets';
import {
  RemoveActiveProjectBannerMutation,
  RemoveActiveProjectBannerMutationVariables,
  SetActiveProjectBannerMutation,
  SetActiveProjectBannerMutationVariables,
} from '../../../../generated/graphql';

import { removeActiveProjectBannerMutation, setActiveProjectBannerMutation } from './queries';

export const useSetActiveProjectBanner = () => {
  const [setActiveProjectBannerFunc] = useMutation<
    SetActiveProjectBannerMutation,
    SetActiveProjectBannerMutationVariables
  >(setActiveProjectBannerMutation);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  return (assetID: UUID, projectID: UUID, onSuccess?: any, onFailure?: any) => {
    setActiveProjectBannerFunc({
      variables: { assetID, projectID },
      refetchQueries: refetchActiveProjectBanner(projectID),
    })
      .then((result) => {
        if (!result || !result.data) return;
        const {
          data: { setActiveProjectBanner: activeProjectBanner },
        } = result;
        if (onSuccess) onSuccess(activeProjectBanner);
      })
      .catch(() => {
        if (onFailure) onFailure();
      });
  };
};

export const useRemoveActiveProjectBanner = () => {
  const [removeActiveProjectBannerMutationFunc] = useMutation<
    RemoveActiveProjectBannerMutation,
    RemoveActiveProjectBannerMutationVariables
  >(removeActiveProjectBannerMutation);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  return (projectID: UUID, onSuccess?: any, onFailure?: any) => {
    removeActiveProjectBannerMutationFunc({
      variables: { projectID },
      refetchQueries: refetchActiveProjectBanner(projectID),
    })
      .then((result) => {
        if (!result || !result.data) return;
        const {
          data: { removeActiveProjectBanner: id },
        } = result;
        if (onSuccess) onSuccess(id);
      })
      .catch(() => {
        if (onFailure) onFailure();
      });
  };
};
