import { FC, useState } from 'react';

import { CONFIRM_DELETE, DELETE_AVERAGE_COLUMN, LOST_DATA_MESSAGE } from '../../../constants';
import { PcMarkup } from '../../../generated/graphql';
import DialogsConfirm from '../../Dialogs/DialogsConfirm/DialogsConfirm';
import { useDontShowConfirm } from '../../Dialogs/DialogsConfirm/useDontShowConfirm';
import { EscalationTargetLocation } from '../constants/projectCompTypes';
import { useAverageCompInputUpdateFunctions } from '../ProjectCompsSetInputStore/ProjectCompsSetInputUpdaters';
import { extractAverageCompValues } from '../ProjectCompsSetUtils';

import AverageComp from './AverageComp';
import AverageCompCustomizationDialog from './AverageCompCustomizationDialog';
import { getAverageCompDropdownMenuOptions } from './AverageCompUtils';

type AverageCompDataProps = {
  averageComp: AverageComp;
  categories: ProjectCompsSet['categories'];
  costTableColumnInputs: CostTableColumnInputs;
  hasMarkups: boolean;
  isLocationAutoEscalationApplied: boolean;
  location: EscalationTargetLocation;
  markups: PcMarkup[];
  parentProject: ProjectProps | undefined;
  setTargetLocation: (location: EscalationTargetLocation) => void;
  selectedUnits: Unit[];
  unit: Unit;
};

const AverageCompData: FC<AverageCompDataProps> = ({
  averageComp,
  categories,
  costTableColumnInputs,
  hasMarkups,
  isLocationAutoEscalationApplied,
  markups,
  parentProject,
  setTargetLocation,
  selectedUnits,
  location,
  unit,
}) => {
  const { color, isHidden, isResettable, thumbnailAssetID } = extractAverageCompValues(averageComp);
  const averageCompInputUpdateFunctions = useAverageCompInputUpdateFunctions();
  const { deleteAverageCompInput } = averageCompInputUpdateFunctions;

  // Local state
  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState(false);
  const [openCustomizationDialog, setOpenCustomizationDialog] = useState<boolean>(false);

  // Average Deletion Dialog
  const openDeleteConfirmDialog = () => setOpenConfirmDeleteDialog(true);
  const titleConfirmDelete = DELETE_AVERAGE_COLUMN;
  const dontShowConfirmDeleteAverage = useDontShowConfirm(
    titleConfirmDelete,
    openDeleteConfirmDialog,
    deleteAverageCompInput
  );
  const averageCompDeleteDialog = (
    <DialogsConfirm
      acceptCtaCopy={CONFIRM_DELETE}
      body={LOST_DATA_MESSAGE}
      destructiveConfirm
      onClose={() => setOpenConfirmDeleteDialog(false)}
      onConfirm={deleteAverageCompInput}
      onDisable={() => dontShowConfirmDeleteAverage.setDisabled(true)}
      open={openConfirmDeleteDialog}
      title={titleConfirmDelete}
    />
  );

  // Dropdown Menu Options
  const menuOptions = getAverageCompDropdownMenuOptions(
    isHidden,
    averageCompInputUpdateFunctions,
    () => setOpenCustomizationDialog(true),
    dontShowConfirmDeleteAverage.onClick,
    isResettable
  );

  return (
    <>
      <AverageCompCustomizationDialog
        color={color}
        open={openCustomizationDialog}
        setAverageCompInputColorAndThumbnailID={
          averageCompInputUpdateFunctions.setAverageCompInputColorAndThumbnailID
        }
        setOpen={setOpenCustomizationDialog}
        thumbnailAssetID={thumbnailAssetID ?? undefined}
      />
      {averageCompDeleteDialog}
      <AverageComp
        averageComp={averageComp}
        averageCompInputUpdateFunctions={averageCompInputUpdateFunctions}
        categories={categories}
        costTableColumnInputs={costTableColumnInputs}
        hasMarkups={hasMarkups}
        isLocationAutoEscalationApplied={isLocationAutoEscalationApplied}
        location={location}
        markups={markups}
        options={menuOptions}
        parentProject={parentProject}
        selectedUnits={selectedUnits}
        setTargetLocation={setTargetLocation}
        unit={unit}
      />
    </>
  );
};

export default AverageCompData;
