import { DatePicker, MuiPickersUtilsProvider } from 'material-ui-pickers';
import { FC, useEffect, useState } from 'react';
import MediaQuery from 'react-responsive';
import { isUUID } from 'validator';

import { useReactiveVar } from '@apollo/client';
import DateFnsUtils from '@date-io/date-fns';
import { Typography } from '@material-ui/core';
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';

import {
  ItemEvent,
  ItemSidebarLocation,
  addItemCategoryAnalytics,
  itemEvent,
  openItemMilestoneTransitionDialog,
  removeItemCategoryAnalytics,
  removeItemMilestoneAnalytics,
  updateItemAssigneeAnalytics,
  updateItemDueDateAnalytics,
  updateItemMilestoneAnalytics,
} from '../../analytics/analyticsEventProperties';
import { milestoneTransitionOpenVar, reloadGridVersionVar } from '../../api/apollo/reactiveVars';
import { PermissionResource, Status } from '../../generated/graphql';
import {
  getCategorizationsForProjectFromQueryData,
  useProjectCategorizationsQuery,
} from '../../hooks/useProjectCategorizationsQuery';
import useProjectPropsQuery from '../../hooks/useProjectPropsQuery';
import useSendAnalytics from '../../hooks/useSendAnalytics';
import { withStyles } from '../../theme/komodo-mui-theme';
import {
  isDateValid,
  localeDateFormat,
  maskFn,
  parseDate,
  placeholderDefault,
  preProcessDay,
} from '../../utilities/dates';
import { computeItemCostImpact, getItemMilestoneMappings } from '../../utilities/items';
import { computeMilestonesSorted } from '../../utilities/milestones';
import usePermissions from '../../utilities/permissions/usePermissions';
import { useShouldDisplayCosts } from '../../utilities/permissions/useShouldDisplayCosts';
import { categoryLabel, removeYear } from '../../utilities/string';
import { getItemIdFromUrl, getProjectIdFromUrl } from '../../utilities/url';
import AddToMilestoneModal from '../AddToMilestone/AddToMilestoneModal';
import InputsSelectAssigneeData from '../Inputs/InputsSelectAssignee/InputsSelectAssigneeData';
import { useItemQuery } from '../Items/hooks/useItemQuery';
import { getItemTypeAnalytics, isPrivateVisibility } from '../Items/ItemsUtils';
import { useMilestonesQuery } from '../Milestones/hooks';
import { BACK, isSelectCategoryInteraction } from '../Select/SelectCategory/SelectCategoryUtils';
import SelectCategoryChipInputSingle from '../Select/SelectCategoryChipInput/SelectCategoryChipInputSingle';
import SelectEventItemData from '../Select/SelectEvent/SelectEventItemData';
import SelectMilestoneButton from '../Select/SelectMilestoneButton/SelectMilestoneButton';
import useMemoWrapper from '../useMemoWrapper';

import {
  useItemAssigneeUpdate,
  useItemDueDateUpdate,
  useItemMilestoneUpdate,
  useRemoveItemCategoryUpdate,
  useRemoveItemMilestoneHistory,
  useSetItemCategoryUpdate,
} from './ItemDetailsHooks';
import { getPreviousMilestoneLabel } from './ItemDetailsUtils';
import ItemMilestoneMap, { ITEM_SUMMARY } from './ItemMilestoneMap';
import styles from './ItemSummaryStyles';

type ItemSummaryProps = {
  classes: Classes<typeof styles>;
  width: Breakpoint;
};

const ItemSummary: FC<ItemSummaryProps> = ({ classes, width }) => {
  const itemId = getItemIdFromUrl();
  const projectId = getProjectIdFromUrl();
  const sendAnalytics = useSendAnalytics();

  // QUERIES
  const { data: { item: currentItem = {} as ItemLike } = {}, loading } = useItemQuery(itemId);

  const { data: { milestones: milestonesRaw = [] } = {} } = useMilestonesQuery(projectId, false);
  const {
    data: { project },
  } = useProjectPropsQuery(projectId);
  const activeMilestoneId = project?.activeMilestone.id;

  const { data } = useProjectCategorizationsQuery(projectId);
  const categorizations = getCategorizationsForProjectFromQueryData(data);
  const isDraft = isPrivateVisibility(currentItem?.visibility);

  // STATE
  const [itemBucket, setItemBucket] = useState<string | undefined>(currentItem?.bucket?.id);
  const [itemCategories, setItemCategories] = useState<Category[]>(
    currentItem?.categories as Category[]
  );
  const [itemDueDate, setItemDueDate] = useState<string | undefined>(
    currentItem?.dueDate ?? undefined
  );
  const [itemMilestoneTransition, setItemMilestoneTransition] = useState<{
    milestone: string | undefined;
    status: Status | undefined;
  }>({ milestone: undefined, status: undefined });

  const showMilestoneTransition = useReactiveVar(milestoneTransitionOpenVar);
  const milestones = useMemoWrapper(computeMilestonesSorted, milestonesRaw, activeMilestoneId);
  const item = useMemoWrapper(computeItemCostImpact, currentItem);
  const milestoneMappings = getItemMilestoneMappings(currentItem as ItemLike, milestones);

  const { canView, canEdit } = usePermissions({ trades: currentItem?.categories });
  const canEditItemAssignee = canEdit(PermissionResource.ITEM_ASSIGNEES);
  const canEditItemCategories = canEdit(PermissionResource.ITEM_CATEGORIES);
  const canEditItemMilestone = canEdit(PermissionResource.ITEM_MILESTONE_AND_MEETINGS);
  const canViewEvent = canView(PermissionResource.ITEM_MILESTONE_AND_MEETINGS);
  const canEditEvent = canEdit(PermissionResource.ITEM_MILESTONE_AND_MEETINGS);
  const { shouldDisplayCosts } = useShouldDisplayCosts();

  useEffect(() => {
    if (item) {
      if (itemDueDate !== item.dueDate) setItemDueDate(item.dueDate || null);
      if (itemBucket !== item.bucket) setItemBucket(item && item.bucket ? item.bucket.id : null);
      if (itemCategories !== item.categories) setItemCategories(item.categories);
    }
  }, [item, itemDueDate, itemBucket, itemCategories]);

  // analytics
  const location = ItemSidebarLocation.ITEM_DETAILS_PAGE;

  // Milestone transition dialog
  const closeDialog = () => {
    sendAnalytics(
      openItemMilestoneTransitionDialog({
        id: itemId,
        milestoneId: item ? item.milestone : null,
        isOpen: 'CLOSE',
      })
    );
    setItemMilestoneTransition({ milestone: undefined, status: undefined });
    milestoneTransitionOpenVar(false);
  };

  // UPDATE STATE / UPDATE HOOKS

  // Assignee
  const updateAssignee = useItemAssigneeUpdate();
  const setAssignee = (id: UUID, assigneeEmail: string | undefined) => {
    if (item && ((item.assignee && item.assignee.email) || undefined) !== assigneeEmail) {
      sendAnalytics(
        updateItemAssigneeAnalytics({
          id,
          assigneeEmail,
          itemType: getItemTypeAnalytics(item),
          location,
        })
      );
      updateAssignee(projectId, id, assigneeEmail);
    }
  };

  // Categories
  const updateSetItemCategory = useSetItemCategoryUpdate();
  const setItemCategory = (id: UUID, category: Category) => {
    if (!category || !category.categorization) return;
    const isItemCategoryUpdate = !item.categories.some(
      (c: Category) =>
        category.categorization &&
        c.categorization &&
        c.categorization.id === category.categorization.id &&
        c.id === category.id
    );
    if (isItemCategoryUpdate) {
      sendAnalytics(addItemCategoryAnalytics(id, category, getItemTypeAnalytics(item), location));
      setItemCategories([
        ...itemCategories.filter(
          (c) =>
            c.categorization &&
            category.categorization &&
            c.categorization.id !== category.categorization.id
        ),
        category,
      ]);
      updateSetItemCategory(projectId, id, category.id);
    }
  };

  const updateRemoveItemCategory = useRemoveItemCategoryUpdate();
  const removeItemCategory = (id: UUID, category?: Category) => {
    if (!category || !category.categorization) return;
    sendAnalytics(removeItemCategoryAnalytics(id, category, getItemTypeAnalytics(item), location));
    setItemCategories([
      ...itemCategories.filter(
        (c) =>
          c.categorization &&
          category.categorization &&
          c.categorization.id !== category.categorization.id
      ),
    ]);
    updateRemoveItemCategory(projectId, id, category.id);
  };

  // Due date
  const updateDueDate = useItemDueDateUpdate();
  const setDueDate = (id: UUID, dueDate: string) => {
    if (item && (parseDate(item.dueDate) || undefined) !== parseDate(dueDate)) {
      sendAnalytics(
        updateItemDueDateAnalytics({ id, dueDate, itemType: getItemTypeAnalytics(item), location })
      );
      setItemDueDate(dueDate);
      updateDueDate(projectId, id, dueDate);
    }
  };

  // Milestones
  const updateItemMilestone = useItemMilestoneUpdate({
    onCompleted: () => {
      reloadGridVersionVar(reloadGridVersionVar() + 1);
    },
  });
  const setMilestone = (
    updateItem: ItemLike,
    milestoneId: string,
    status: Status,
    location: string
  ) => {
    sendAnalytics(
      updateItemMilestoneAnalytics({
        id: updateItem.id,
        itemType: updateItem.itemType,
        milestoneId,
        status,
        location,
      })
    );
    updateItemMilestone(projectId, updateItem, milestoneId, status);
    setItemMilestoneTransition({ milestone: undefined, status: undefined });
    milestoneTransitionOpenVar(false);
  };

  const removePreviousMilestoneHistory = useRemoveItemMilestoneHistory();
  const removeItemMilestones = (milestoneToDelete: Milestone | null) => {
    if (milestoneToDelete) {
      removePreviousMilestoneHistory(item, milestoneToDelete.id);
      sendAnalytics(
        removeItemMilestoneAnalytics({
          itemId: item.id,
          milestoneId: milestoneToDelete.id,
          location,
        })
      );
    }
    return milestoneMappings;
  };

  // COMPONENTS
  const renderAssignee = () => {
    const assignee = item ? item.assignee : null;
    const assigneeEmail = assignee?.email ?? '';
    return (
      <div className={`${classes.field} ${!assignee && classes.noPrint} flex flex-col gap-0.5`}>
        <div className="type-label">Assignee</div>
        <InputsSelectAssigneeData
          key={`${item?.id ?? ''}_${assigneeEmail}`}
          disabled={!canEditItemAssignee}
          isDraft={isDraft}
          itemID={item.id}
          onChange={(email) => setAssignee(itemId, email)}
          projectId={projectId}
          selected={assigneeEmail}
          selectedAssignee={assignee}
        />
      </div>
    );
  };

  const renderDueDate = () => {
    if (!item) return null;
    if (!canEditItemAssignee && !item.dueDate) return null;
    return (
      <div className={`${classes.field} flex flex-col gap-0.5 ${!item.dueDate && classes.noPrint}`}>
        <div className="type-label">Due Date</div>
        {canEditItemAssignee ? (
          <div className={classes.noPrint}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                animateYearScrolling={false}
                className={classes.date}
                clearable
                data-cy="input-date-picker"
                disabled={!canEditItemAssignee}
                format={localeDateFormat}
                FormHelperTextProps={{ classes: { error: classes.error } }}
                fullWidth
                InputProps={{
                  classes: { input: `picker ${classes.picker}` },
                  disableUnderline: true,
                }}
                invalidDateMessage=" "
                keepCharPositions
                keyboard
                KeyboardButtonProps={{
                  classes: { root: classes.datePickerIcon },
                }}
                mask={maskFn}
                onChange={(value) => {
                  if ((value && isDateValid(value)) || value === null) {
                    setDueDate(item.id, value);
                  }
                }}
                // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
                onKeyDown={(evt: any) => {
                  if (evt.key === 'Enter') {
                    evt.target.blur();
                  }
                }}
                placeholder={placeholderDefault}
                value={itemDueDate ? preProcessDay(new Date(itemDueDate)) : itemDueDate}
              />
            </MuiPickersUtilsProvider>
          </div>
        ) : (
          <Typography className={classes.disabledPicker}>
            {`${parseDate(item.dueDate)} `}
          </Typography>
        )}
        <div className={classes.printOnly}>
          <Typography>{parseDate(item.dueDate)}</Typography>
        </div>
      </div>
    );
  };

  const renderCategories = () => (
    <div>
      {item && itemCategories && itemCategories.length > 0 && (
        <div className="type-label print:hidden">Categories</div>
      )}
      {categorizations &&
        categorizations.map((cat: Categorization) => {
          const selectedCategory =
            item &&
            itemCategories &&
            itemCategories.find(
              // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
              (category: any) => category.categorization && category.categorization.id === cat.id
            );

          return (
            <div key={cat.id}>
              <div className={`${classes.inlinePrint} ${!selectedCategory && classes.noPrint}`}>
                <Typography>
                  {`${removeYear(cat.name)}: `}
                  {selectedCategory &&
                    categoryLabel(selectedCategory.name, selectedCategory.number, cat)}
                </Typography>
              </div>
              <div
                className={`${classes.field} flex flex-col gap-0.5 print:hidden`}
                id={`${cat.name}-${item ? item.id : ''}`}
              >
                <div className="type-label">{removeYear(cat.name)}</div>
                <div>
                  <SelectCategoryChipInputSingle
                    categorizations={[cat]}
                    disabled={!canEditItemCategories}
                    id={`${cat.name}-${item ? item.id : ''}`}
                    includeUncategorizedCategory={false}
                    isCompact={false}
                    selectedCategory={selectedCategory}
                    setCategory={(category) => {
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
                      let formattedCategory: any = {};
                      if (isSelectCategoryInteraction(category) && category.variant !== BACK) {
                        formattedCategory = category;
                      } else {
                        const { categorization, id, level, levels, name, number } = category;
                        formattedCategory = {
                          categorization,
                          level,
                          levels,
                          name,
                          number,
                          id,
                        };
                      }
                      // valid cat
                      if (
                        formattedCategory &&
                        formattedCategory.id &&
                        isUUID(formattedCategory.id)
                      ) {
                        setItemCategory(item.id, formattedCategory);
                      } else {
                        // if uncategorized remove
                        removeItemCategory(item.id, selectedCategory);
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );

  const renderTimeline = () => {
    if (loading) return null;
    if (!item) return null;
    const { milestone, parent } = item;
    const isOption = !!parent;
    const currentMilestone =
      // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
      milestone && milestones && milestones.find((m: any) => m.id === milestone.id);

    const previousMilestoneLabel = getPreviousMilestoneLabel(milestoneMappings);

    // Hide this section if there are no milestones
    if (milestones.length < 1) {
      return <></>;
    }

    const milestoneName = (currentMilestone && milestone && milestone.name) || '';
    const disabled = !canEditItemMilestone || isOption;
    return (
      // TODO - Clean up MilestoneTransition into a set of clearer components
      <>
        <div className={`${classes.field} flex flex-col gap-0.5 print:hidden`}>
          <div className="type-label">Milestone</div>
          <div>
            <SelectMilestoneButton
              canEditItemMilestone={canEditItemMilestone}
              disabled={disabled}
              name={milestoneName}
              onClick={() => {
                milestoneTransitionOpenVar(true);
                sendAnalytics(
                  openItemMilestoneTransitionDialog({
                    id: item ? item.id : itemId,
                    milestoneId: item ? item.milestoneID : '',
                    isOpen: 'OPEN',
                  })
                );
              }}
            />

            {previousMilestoneLabel && (
              <ItemMilestoneMap
                canEditItemMilestone={canEditItemMilestone && !isOption}
                currentItem={item}
                onChange={removeItemMilestones}
                projectID={projectId}
                shouldDisplayCosts={shouldDisplayCosts}
                variant={ITEM_SUMMARY}
              />
            )}
          </div>
        </div>

        {canViewEvent && (
          <div className={`${classes.field} flex flex-col gap-0.5`}>
            <div className="type-label print:hidden">Events</div>
            <div className={classes.noPrint}>
              <SelectEventItemData
                analyticsEvent={itemEvent(ItemEvent.EVENT_SELECT)}
                disabled={!canEditEvent}
                item={item}
                maxChips={2}
                projectID={projectId}
              />
            </div>
          </div>
        )}
      </>
    );
  };

  const renderRight = () => (
    <div className={classes.summaryContent}>
      {renderAssignee()}
      {renderDueDate()}
      {renderTimeline()}
      {renderCategories()}
    </div>
  );

  const renderCenter = () => (
    <div className={classes.summaryCenterContent}>
      <div className={classes.summaryCenterField}>
        {renderAssignee()}
        {renderDueDate()}
        {renderTimeline()}
      </div>
      <div className={classes.summaryCenterField}>{renderCategories()}</div>
    </div>
  );

  const renderCenterPrint = () => {
    const assignee = item ? item.assignee : null;

    return (
      <div className={classes.summaryCenterContent}>
        {assignee && <div className={classes.summaryCenterField}>{renderAssignee()}</div>}
        {item && item.dueDate && (
          <div className={classes.summaryCenterField}>{renderDueDate()}</div>
        )}
        <div className={classes.summaryCenterField}>{renderTimeline()}</div>
        {item && itemCategories && itemCategories.length > 0 && (
          <div className={classes.summaryCenterField}>{renderCategories()}</div>
        )}
      </div>
    );
  };

  const renderModal = () => {
    const selectedMilestone = itemMilestoneTransition.milestone || item.milestone.id;
    const selectedStatus = itemMilestoneTransition.status || item.status;
    return (
      <AddToMilestoneModal
        item={item}
        milestones={milestones.filter((m: Milestone) => !m.isDraft)}
        onCancel={closeDialog}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
        onChangeMilestone={(milestone: any) => {
          setItemMilestoneTransition({ milestone, status: selectedStatus });
        }}
        onChangeStatus={(status: Status) => {
          setItemMilestoneTransition({ milestone: selectedMilestone, status });
        }}
        onClose={closeDialog}
        onSubmit={() => {
          const { milestone, status } = itemMilestoneTransition;
          if (milestone && status && item) setMilestone(item, milestone, status, location);
        }}
        open={showMilestoneTransition}
        selectedMilestone={selectedMilestone}
        selectedStatus={selectedStatus}
      />
    );
  };

  const isOption = item ? !!item.parent : false;

  return (
    <div className={classes.summary}>
      {showMilestoneTransition && !isOption && renderModal()}
      <MediaQuery print>{renderCenterPrint()}</MediaQuery>
      <MediaQuery print={false}>
        {isWidthUp('lg', width) ? renderRight() : renderCenter()}
      </MediaQuery>
    </div>
  );
};

const ItemsDetailsStyled = withStyles(styles)(ItemSummary);
const ItemsDetailsWidth = withWidth()(ItemsDetailsStyled);
export default ItemsDetailsWidth;
