import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';
import { HEADER_HEIGHT } from '../../frame/ItemSidebar/ItemSidebarStyles';

const PADDING = 12;
const MIN_WIDTH = 180;
const MENU_OPTION_SIZE = 14;

export default (theme: KomodoTheme) =>
  createStyles({
    root: {
      minWidth: 32,
      marginLeft: 8,
    },
    chipContainer: {
      alignContent: 'center',
      minHeight: 38,
      display: 'grid',
      background: 'none',
      padding: PADDING,
      '&:focus': {
        outline: 'none',
        border: 'none',
      },
    },
    smallIcon: {
      '& svg': {
        fontSize: 20,
      },
    },
    radioIcon: {
      '& svg': {
        fontSize: 20,
      },
    },
    activeLabel: {
      backgroundColor: 'transparent',
    },
    checkboxMenuItem: {
      paddingLeft: 0,
    },
    suggestionText: {
      fontSize: MENU_OPTION_SIZE,
      paddingLeft: 12, // Lines up with icon padding for options
    },
    suggestion: {
      padding: PADDING,
      paddingTop: 8,
      paddingBottom: 8,
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      alignItems: 'start',
      '&:hover': {
        background: theme.palette.hoverBackground,
      },
    },
    suggestions: {
      borderBottom: theme.border.line,
    },
    suggestionsHeader: {
      display: 'flex',
      background: theme.palette.innerBorder,
      padding: PADDING,
      paddingTop: 8,
      paddingBottom: 8,
      borderBottom: theme.border.line,
    },
    chip: {
      background: theme.palette.backgroundGrey,
      cursor: 'pointer',
      height: 26,
      margin: 2,
      borderRadius: theme.border.radius,
      border: theme.border.line,
      '&:focus': {
        background: theme.palette.backgroundGrey,
      },
    },
    chipLabel: {
      color: theme.palette.joinPrimary,
      flex: 1,
      padding: '0px 4px',
    },
    chipPadding: {
      padding: 2,
    },
    chipSelectPadding: {
      padding: '38px 2px 0px 2px',
    },
    expanded: {
      marginLeft: 0,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    extra: {
      width: 28,
      height: 14,
    },
    formControl: {
      width: 120,
    },
    groupButton: {
      width: '100%',
      height: '100%',
      justifyContent: 'left',
      display: 'inline-flex',
      alignItems: 'center',
      borderRadius: theme.border.radius,
      border: theme.border.line,
      '&:focus': {
        border: theme.border.focus,
        outline: theme.border.focus,
      },
    },
    groupContainer: {
      background: theme.palette.backgroundWhite,
      minWidth: MIN_WIDTH,
      height: 34,
      borderRadius: theme.border.radius,
    },
    scroll: {
      overflowY: 'auto',
      minWidth: 176,
    },
    header: {
      padding: '6px 16px',
      backgroundColor: 'rgba(0, 0, 0, 0.14)',
      color: theme.palette.joinPrimary,
    },
    unitHeader: {
      display: 'flex',
      background: theme.palette.backgroundGrey,
      justifyContent: 'space-between',
      paddingLeft: 12,
      borderTop: theme.border.line,
      borderBottom: theme.border.line,
      height: HEADER_HEIGHT,
      alignItems: 'center',
    },
    unitHeaderText: theme.typography.h4,
    icon: {
      color: theme.palette.joinPrimary,
      width: 24,
      padding: '0px 2px',
    },
    singleOption: {
      height: 16,
      padding: '8px 16px',
      alignItems: 'center',
      '&:hover': {
        background: theme.palette.hoverBackground,
      },
    },
    menuOption: {
      height: 16,
      fontSize: MENU_OPTION_SIZE,
      padding: '8px 16px 8px 0px',
      '&:hover': {
        background: theme.palette.hoverBackground,
      },
    },
    multiOptionHeader: {
      backgroundColor: theme.palette.backgroundGrey,
    },
    optionOverflow: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      width: '100%',
      ...theme.typography.body1,
      fontSize: 'inherit',
    },
    paper: {
      display: 'flex',
      flexDirection: 'column',
      minWidth: MIN_WIDTH,
      maxHeight: '60%',
    },
    submenuOption: {
      margin: '2px 0px',
      fontSize: 12,
      fontWeight: 400,
      padding: '8px 16px 8px 16px',
      height: 16,
      '&:hover': {
        background: theme.palette.hoverBackground,
      },
    },
    spacer: {
      flexGrow: 1,
    },
    disabledEmpty: {
      padding: 8,
    },
    empty: {
      padding: '0px 8px',
    },
    counter: {
      paddingRight: '4px',
      fontSize: '13px',
      fontWeight: 400,
    },
    underline: {
      alignSelf: 'end',
      textDecoration: 'underline',
    },
    flex: {
      display: 'flex',
    },
  });
