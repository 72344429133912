import { createStyles } from '@material-ui/core/styles';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';

const InputsSelectReferenceCategorizationMenuItemStyles = (theme: KomodoTheme) =>
  createStyles({
    categoryLabel: {
      paddingTop: '14px',
      ...theme.typography.h3,
      color: 'inherit',
      fontFamily: theme.typography.fontFamily,
      direction: 'ltr',
    },
    uncategorizedLabel: {
      paddingTop: '14px',
      fontStyle: 'italic',
      paddingRight: '.8em',
      ...theme.typography.h3,
      color: 'inherit',
      fontFamily: theme.typography.fontFamily,
    },
    choice: {
      lineHeight: '20px',
      paddingTop: '8px',
      paddingBottom: '8px',
      paddingLeft: 0,
      '&:hover': { backgroundColor: theme.palette.backgroundGreen },
    },
    choiceContainer: {
      display: 'flex',
      flexDirection: 'row-reverse',
      width: '100%',
    },
  });

export default InputsSelectReferenceCategorizationMenuItemStyles;
