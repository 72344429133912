import { FetchResult } from 'apollo-link';

import { MutationHookOptions, useMutation } from '@apollo/client';

import {
  REFETCH_CHANGE_MILESTONE_EVENTS_LIST,
  REFETCH_ITEM_ATTACHMENTS,
} from '../../../../api/refetchSets';
import {
  AttachToItemMutation,
  AttachToItemMutationVariables,
  SourceSystem,
} from '../../../../generated/graphql';
import { logErrorToSentry } from '../../../../utilities/sentry';
import { replaceQueries } from '../../../../utilities/utilities';
import { attachToItemMutation } from '../queries';

import { formatAttachAssetInputs } from './mutationUtils';

export type ItemAttachmentMutationArgs = {
  input: ItemAttachmentMutationInput;
  onSuccess?: (result: FetchResult<AttachToItemMutation>) => void;
};

type ItemAttachmentMutationInput = {
  assetID: UUID;
  itemID: UUID;
  sourceSystem?: SourceSystem;
  sourceID?: UUID;
  sourceProjectID?: string;
  hubID?: string;
};

export const formatItemAttachmentMutationInput = (
  assetID: string,
  currentUpload: AssetActionData
): ItemAttachmentMutationInput => ({
  assetID,
  itemID: currentUpload.itemID || '',
  sourceSystem: currentUpload?.sourceSystem,
  sourceID: currentUpload?.sourceID,
  sourceProjectID: currentUpload?.sourceProjectID,
  hubID: currentUpload?.hubID,
});

export function useAttachToItem(
  options: MutationHookOptions<AttachToItemMutation, AttachToItemMutationVariables> = {}
) {
  const [attachToItem] = useMutation<AttachToItemMutation, AttachToItemMutationVariables>(
    attachToItemMutation,
    { ...options, onError: logErrorToSentry }
  );

  return (args: ItemAttachmentMutationArgs) => {
    const { assetInput, remoteAssetInput } = formatAttachAssetInputs({
      hubID: args.input.hubID,
      assetID: args.input.assetID,
      sourceSystem: args.input.sourceSystem,
      sourceID: args.input.sourceID,
      sourceProjectID: args.input.sourceProjectID,
    });
    return attachToItem({
      variables: {
        itemID: args.input.itemID,
        assetInput,
        remoteAssetInput,
      },
      refetchQueries: replaceQueries(
        REFETCH_CHANGE_MILESTONE_EVENTS_LIST,
        REFETCH_ITEM_ATTACHMENTS
      ),
    }).then((result) => {
      if (args.onSuccess) args.onSuccess(result);
    });
  };
}
