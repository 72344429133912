import { ScaleLinear, ScalePoint, ScaleTime } from 'd3-scale';
import { ReactNode } from 'react';
import { useOverlayPosition } from 'react-aria';
import { Tooltip } from '../../scales';

type Data = { date: Date; value: number };

export default function TimelineSvg(props: {
  data: Data;
  icon: ReactNode;
  iconWidth: number;
  iconHeight: number;
  tooltipContent?: ReactNode;
  tooltipContentPlacement?: Parameters<typeof useOverlayPosition>[0]['placement'];
  x: ScaleTime<number, number> | ScalePoint<Date>;
  y: ScaleLinear<number, number>;
}) {
  const positionX = props.x(props.data.date);
  const positionY = props.y(props.data.value);

  return (
    <Tooltip
      content={props.tooltipContent}
      isDisabled={!props.tooltipContent}
      placement={props.tooltipContentPlacement ?? 'bottom'}
    >
      <g transform={`translate(${positionX}, ${positionY})`}>
        <g transform={`translate(${-props.iconWidth / 2}, ${-props.iconHeight / 2})`}>
          {props.icon}
        </g>
      </g>
    </Tooltip>
  );
}
