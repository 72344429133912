import { FC } from 'react';

import { UploadPlatform } from '../../../api/gqlEnums';
import { withStyles } from '../../../theme/komodo-mui-theme';
import UploadFileSVG from '../../shared-widgets/AddUploadDrag/AddUploadDragSVG';
import { getUploadPlatformSVGs } from '../../shared-widgets/AddUploadDrag/AddUploadDragUtils';

import styles from './FilesAddUploadSVGStyles';

type FilesAddUploadSVGProps = {
  classes: Classes<typeof styles>;
};

const FilesAddUploadSVG: FC<FilesAddUploadSVGProps> = ({ classes }) => (
  <UploadFileSVG
    backgroundImage={getUploadPlatformSVGs(UploadPlatform.EMPTY_STATE).upper}
    svgClassName={classes.upperSVGIconEmptyStateLarge}
  />
);

export default withStyles(styles)(FilesAddUploadSVG);
