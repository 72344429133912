import { Typography } from '@material-ui/core';

import { setReactiveLocal, transitionOnboardingVar } from '../../../api/apollo/reactiveVars';
import { EstimateType, TransitionModal } from '../../../api/gqlEnums';
import { TRANSITION_REACTIVE_VAR } from '../../../constants';
import ChangeCalculationIcon from '../../table/estimate/ChangeCalculation.svg';

export const headerText = (
  estimateType: EstimateType.ACTIVE_ESTIMATE | EstimateType.BUDGET,
  milestoneName: string
) =>
  `Create new ${
    estimateType === EstimateType.ACTIVE_ESTIMATE ? 'estimate' : 'budget'
  } for ${milestoneName}`;

export const TransitionMangerTipTexts = {
  MilestoneModal: "You'll be able to edit the details of your estimate before sharing it.",
  RunningTotalHasConflict: (
    <div style={{ display: 'flex', maxWidth: '602px' }}>
      <Typography
        style={{ fontSize: 12, fontWeight: 400, lineHeight: '144%', letterSpacing: '0.0119em' }}
      >
        <b>Tip:</b> Because there are different levels of detail in these estimates, we will match
        to the highest level. You can readjust this to a lower level of detail within the Estimate
        Table with this icon in the Total column:
        <img
          alt="ChangeEstimateTotalType"
          src={ChangeCalculationIcon}
          style={{ paddingLeft: '6px', position: 'absolute', display: 'inline' }}
        />
      </Typography>
    </div>
  ),
};

export const TransitionManagerHeaderTexts = {
  MilestoneModal: 'Choose a value to start from.',
};

export const openTransitionOnboarding = (resetModal = false) => {
  const transitionOnboarding = transitionOnboardingVar();
  const value = {
    ...transitionOnboarding,
    modal: TransitionModal.MILESTONE,
    modalIsOpen: true,
    incorporateAccepted: false,
  };
  if (resetModal) {
    value.modal = TransitionModal.MILESTONE;
  }
  // reset the milestone and type to undefined so they can be set to valid values
  // in the TransitionMilestoneSelection component
  setReactiveLocal(transitionOnboardingVar, TRANSITION_REACTIVE_VAR, {
    ...value,
    type: undefined,
    milestoneID: undefined,
  });
};
