import { useState } from 'react';

import {
  getCategorizationsForProjectFromQueryData,
  useProjectCategorizationsQuery,
} from '../../../hooks/useProjectCategorizationsQuery';
import { getDefaultReportCategorizations } from '../../../utilities/categorization/categorizationUtils';
import { getProjectIdFromUrl } from '../../../utilities/url';
import useGetProjectNameQuery from '../../Collaborators/hooks/UseGetProjectNameQuery';
import { useGetProjectUnitsQuery } from '../../Milestone/hooks/UnitHooks';
import { useMilestonesQuery } from '../../Milestones/hooks';
import { useLoadTimer } from '../../PerfMonitor/utils';
import useMemoWrapper from '../../useMemoWrapper';
import CostReport from '../CostReport/CostReport';

const CostReportData = () => {
  const projectId = getProjectIdFromUrl();

  const { data: projectData, loading: projectLoading, error } = useGetProjectNameQuery(projectId);
  const { data: milestoneData, loading: milestonesLoading } = useMilestonesQuery(projectId, false);
  const { data: unitsData, loading: unitsLoading } = useGetProjectUnitsQuery(projectId, true);
  const { data: catData, loading: catLoading } = useProjectCategorizationsQuery(projectId);

  const projectName = projectData?.project?.name ?? '';
  const milestones = milestoneData?.milestones ?? [];
  const categorizations = getCategorizationsForProjectFromQueryData(catData);
  const enabledUnits = unitsData?.getProjectUnits ?? [];

  const defaultCategorizations = useMemoWrapper(getDefaultReportCategorizations, categorizations);
  const hooksLoading = projectLoading || milestonesLoading || catLoading || unitsLoading;

  const [costReportIsLoading, setIsLoading] = useState(true);
  useLoadTimer('CostReport', costReportIsLoading);

  return !hooksLoading ? (
    <CostReport
      categorizations={categorizations}
      defaultCategorizations={defaultCategorizations}
      enabledUnits={enabledUnits}
      error={error}
      milestones={milestones}
      projectName={projectName}
      setIsLoading={setIsLoading}
    />
  ) : null;
};

export default CostReportData;
