import { FC, MouseEvent } from 'react';

import { Button } from '@material-ui/core';

import { withStyles } from '../../theme/komodo-mui-theme';

import styles from './CardButtonStyles';

type CardButtonProps = {
  buttonText: string;
  classes: Classes<typeof styles>;
  dataCy?: string;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  disabled?: boolean;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  fullWidth?: boolean;
  icon: JSX.Element;
  onClick: (event: MouseEvent<HTMLAnchorElement>) => void;
};

const CardButton: FC<CardButtonProps> = ({
  buttonText,
  classes,
  dataCy,
  disabled,
  fullWidth,
  icon,
  onClick,
}) => {
  const buttonWidth = fullWidth ? classes.fullWidth : classes.defaultWidth;
  const buttonClass = `${classes.cardButton} ${buttonWidth} ${
    classes[disabled ? 'cardButtonDisabled' : 'cardButtonHover']
  }`;

  return (
    <Button
      aria-label={buttonText}
      className={buttonClass}
      data-cy={dataCy}
      disabled={disabled}
      onClick={onClick}
      title={buttonText}
    >
      <div className={classes.content}>
        {icon}
        <div className={classes.buttonText}>{buttonText}</div>
      </div>
    </Button>
  );
};

export default withStyles(styles)(CardButton);
