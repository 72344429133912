import {
  TimelineActivityOptionsQuery,
  TimelineActivityOptionsQueryVariables,
  TimelineInput,
  TimelineQuery,
  TimelineQueryVariables,
} from '../../../generated/graphql';
import { useQuery } from '../../../hooks/useMountAwareQuery';

import { timelineActivityOptionsQuery, timelineQuery } from './queries';

export const useTimelineQuery = (input: TimelineInput) => {
  return useQuery<TimelineQuery, TimelineQueryVariables>(timelineQuery, {
    variables: { input },
    skip: !input.projectID,
  });
};

export const useTimelineActivityOptionsQuery = (input: TimelineInput) => {
  return useQuery<TimelineActivityOptionsQuery, TimelineActivityOptionsQueryVariables>(
    timelineActivityOptionsQuery,
    {
      variables: { input },
      skip: !input.projectID,
    }
  );
};
