/* eslint-disable react/no-unused-prop-types */
import { brushX } from 'd3-brush';
import type { BrushBehavior, D3BrushEvent } from 'd3-brush';
import { type ScaleTime, scaleTime } from 'd3-scale';
import { select } from 'd3-selection';
import { createRef, useEffect, useState } from 'react';
import { useDeepCompareEffect } from 'react-use';

import { TimelineEvent } from '../../../analytics/analyticsEventProperties';

import { updateHorizontalLine, updateVerticalLine } from './renderUtils';
import {
  BrushGroupSelection,
  BrushSelection,
  DivSelection,
  GetterSetter,
  TimelineData,
} from './types';

type Props = {
  onAnalytics: (event: TimelineEvent) => void;
  onZoom: (domain: [string, string]) => void;
  today: Date;
  width: number;
  // Total zoom range
  totalRange: [string, string];
  // Current zoom range
  zoom: [string, string];
};

export default function TimelineZoomChart(props: Props) {
  const timelineRef = createRef<HTMLDivElement>();
  const [totalRange, setTotalRange] = useState(props.totalRange);
  const [timelineChart, setTimelineChart] = useState<Timeline<TimelineData>>();

  useDeepCompareEffect(() => {
    setTotalRange(props.totalRange);
  }, [props.totalRange]);

  useEffect(() => {
    if (!timelineRef || !timelineRef.current) return;
    if (!props.width) return;
    setTimelineChart(timeline(props).render(timelineRef.current));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timelineRef?.current, props.width, totalRange]);

  useEffect(() => {
    if (props.zoom[0] && props.zoom[1]) {
      timelineChart?.onSetZoom(props.zoom);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.zoom]);

  return (
    <div className="relative block">
      <div ref={timelineRef} className="overflow-hidden" id="timeline-chart" />
    </div>
  );
}

export const CHART_HEIGHT = 70;
export const BRUSH_HEIGHT = 48;

export interface Timeline<T extends TimelineData> {
  onSetZoom: GetterSetter<Timeline<T>, [string, string]>;
  render(domElement: HTMLElement): Timeline<T>;
}

export function timeline<T extends TimelineData = TimelineData>(props: Props) {
  let chartContainer: DivSelection;
  let brushXScale: ScaleTime<number, number>;
  const timeline: Timeline<T> = {
    render(domElement: HTMLElement) {
      chartContainer = chartContainer || createChartContainer(domElement, props);
      brushXScale = brushXScale || createXScale(props.totalRange, props.width);
      updateChart(chartContainer, brushXScale, props);
      return timeline;
    },
    onSetZoom: ((zoom: [string, string]) => {
      if (chartContainer && zoom) {
        const chart = chartContainer;
        zoomIn(chart, brushXScale, props, zoom);
      }
      return timeline;
    }) as GetterSetter<Timeline<T>, [string, string]>,
  };
  return timeline;
}

function createChartContainer(domElement: HTMLElement, props: Props): DivSelection {
  // Remove if exist
  const legacyOuterContainer = select(domElement).select('#timeline-outer-container');
  if (!legacyOuterContainer.empty()) {
    legacyOuterContainer.remove();
  }
  // Create outer container with margin
  const outerContainer = select(domElement).append('div').attr('id', 'timeline-outer-container');
  outerContainer.style('width', `${props.width}px`).style('height', `${CHART_HEIGHT}px`);
  return outerContainer;
}

const zoomIn = (
  chart: DivSelection,
  brushXScale: ScaleTime<number, number>,
  props: Props,
  zoom: [string, string]
) => {
  const domain = [new Date(zoom[0]), new Date(zoom[1])];
  const zoomRange = domain.map(brushXScale);
  updateChart(chart, brushXScale, props, zoomRange);
};

function updateChart(
  chart: DivSelection,
  brushXScale: ScaleTime<number, number>,
  props: Props,
  zoomRangeOuter?: number[]
): void {
  const zoomRange = zoomRangeOuter || [16, brushXScale.range()[1]];
  updateBrush(chart, brushXScale, zoomRange, props, () => {});
}

// brush handle shape's path
const HANDLE_PATH = [
  'M -4 3 V 21 C -4 23 -4 23 -2 23 H 2 C 4 23 4 23 4 21 V 3 C 4 1 4 1 2 1 H -2 C -4 1 -4 1 -4 3 Z M -1 16 V 8 Z M 1 16 V 8',
  'M -4 3 V 21 C -4 23 -4 23 -2 23 H 2 C 4 23 4 23 4 21 V 3 C 4 1 4 1 2 1 H -2 C -4 1 -4 1 -4 3 Z M -1 16 V 8 Z M 1 16 V 8',
];

export function updateBrush(
  chart: DivSelection,
  brushXScale: ScaleTime<number, number>,
  zoomRange: number[],
  props: Props,
  updateOuter: (domain: Date[]) => void
): void {
  const { today } = props;
  const brushHeight = BRUSH_HEIGHT;
  const onZoom = (domain: Date[]) => {
    const newMin = domain[0].toISOString();
    const newMax = domain[1].toISOString();
    props.onZoom([newMin, newMax]);
  };
  const timelineXScaleFixed = createXScale(props.totalRange, props.width);
  let brushContainer: DivSelection = chart.select('#brush-container');

  // Create brush container
  if (brushContainer.empty()) {
    brushContainer = chart
      .append('div')
      .attr('id', 'brush-container')
      .style('width', '100%')
      .style('height', `${80}px`)
      .style('padding-top', '8px');
  }

  let brushSvg: BrushSelection = brushContainer.select('#brush-svg');

  if (brushSvg.empty()) {
    // Create brush svg
    brushSvg = brushContainer
      .append('svg')
      .attr('id', 'brush-svg')
      .attr('width', '100%')
      .attr('height', `${brushHeight}px`);
  }

  // Add today line
  if (today) addToday(today, brushSvg, timelineXScaleFixed, 0, brushHeight);

  const existingBrush = brushSvg.select('#brush-group');
  if (!existingBrush.empty()) {
    existingBrush.remove();
  }

  // Create brush
  const brush = brushX().extent([
    [16, 0],
    [brushXScale.range()[1], brushHeight],
  ]);

  brush.on(
    'brush end',
    handleBrush(
      brushSvg,
      brush,
      updateOuter,
      brushXScale,
      timelineXScaleFixed,
      onZoom,
      props.onAnalytics
    )
  );

  // Add brush to band
  brushSvg.insert('g').attr('id', 'brush-group').call(brush);

  const brushGroup: BrushGroupSelection = brushSvg.select('#brush-group');
  updateHorizontalLine(brushGroup, props.width, 0);
  updateHorizontalLine(brushGroup, props.width, brushHeight);
  updateVerticalLine(brushGroup, brushHeight, 16);
  updateVerticalLine(brushGroup, brushHeight, props.width - 16);

  brushGroup
    .select('.selection')
    .attr('class', 'selection stroke-background-2 fill-background-2')
    .attr('height', brushHeight)
    .attr('y', 0)
    .attr('stroke-opacity', '0.08')
    .attr('fill-opacity', '0.4');

  addBrushHandle(brushGroup, HANDLE_PATH);
  brushGroup.call(brush).call(brush.move, zoomRange);
}

export const BRUSH_HANDLE_CLASS = 'handle-custom';

export const DAY_MILLISECONDS = 8.64e7;
export const WEEK_MS = 7 * DAY_MILLISECONDS;
export const TWO_WEEKS_MS = 2 * WEEK_MS;

export const handleBrush =
  (
    brushSvg: BrushSelection,
    brush: BrushBehavior<unknown>,
    updateOuter: (domain: Date[]) => void,
    brushXScale: ScaleTime<number, number>,
    timelineXScaleFixed: ScaleTime<number, number>,
    onZoom: (domain: Date[]) => void,
    onAnalytics: (event: TimelineEvent) => void
  ) =>
  (event: D3BrushEvent<TimelineData>) => {
    const { selection, sourceEvent, type } = event;
    const brushGroup: BrushGroupSelection = brushSvg.select('#brush-group');
    // update brush's handle position and visibility
    if (selection === null) {
      brushGroup
        .selectAll(`.${BRUSH_HANDLE_CLASS}`)
        // hide
        .attr('display', 'none');
    } else {
      const handleTop = 12;
      brushGroup
        .selectAll(`.${BRUSH_HANDLE_CLASS}`)
        .attr('transform', (_d, i) => {
          const pos = [selection[i], handleTop];
          return `translate(${pos})`;
        })
        // display
        .attr('display', null);
    }

    if (!sourceEvent) {
      return;
    }

    let domain: Date[] = brushXScale.domain();

    if (selection === null) {
      const range = [16, timelineXScaleFixed.range()[1]];
      brushGroup.call(brush).call(brush.move, range);
    } else {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
      domain = selection.map(brushXScale.invert as any);
    }
    domain = fixDomain(domain);

    if (type === 'end') {
      onZoom(domain);
      if (selection !== null) onAnalytics(TimelineEvent.TIMELINE_SUBCHART_ZOOM);
    }

    updateOuter(domain);
  };

export const addBrushHandle = (brush: BrushGroupSelection, path: string[]) =>
  brush
    .selectAll(`.${BRUSH_HANDLE_CLASS}`)
    .data([
      { type: 'w', cy: 'zoomMin' },
      { type: 'e', cy: 'zoomMax' },
    ])
    .enter()
    .append('path')
    .attr('class', `${BRUSH_HANDLE_CLASS} stroke-button-secondary-outline fill-button-secondary`)
    .attr('cursor', `${'ew'}-resize`)
    .attr('d', (d) => path[+/[se]/.test(d.type)])
    .attr('data-cy', (d) => d.cy);

export const addToday = (
  today: Date,
  brushSvg: BrushSelection,
  timelineXScaleFixed: ScaleTime<number, number>,
  y1: number,
  y2: number
) => {
  const todayLine = brushSvg.select('#line-today');
  if (!todayLine.empty()) return;
  brushSvg
    .data([today], () => today.getTime())
    .append('line')
    .attr('id', 'line-today')
    .attr('class', 'line brush-today')
    .attr('y1', y1)
    .attr('shape-rendering', 'geometricPrecision')
    .attr('stroke-width', '1.5px')
    .attr('x1', (d: Date) => timelineXScaleFixed(d))
    .attr('x2', (d: Date) => timelineXScaleFixed(d))
    .attr('y2', y2)
    .attr('stroke', '#5CD746');
};

/**
 * Make sure that domain range is never smaller than defined value
 *
 * @param rawDomain Date[]
 * @returns fixed domain or initial domain value - Date[]
 */
export const fixDomain = (rawDomain: Date[]) => {
  const rawStart = rawDomain[0].getTime();
  const rawEnd = rawDomain[1].getTime();
  if (rawEnd - rawStart < TWO_WEEKS_MS) {
    const average = Math.floor((rawEnd + rawStart) / 2);
    const start = average - WEEK_MS;
    const end = average + WEEK_MS;
    return [new Date(start), new Date(end)];
  }
  return rawDomain;
};

export const createXScale = (
  range: [string, string] | [Date, Date],
  width: number
): ScaleTime<number, number> => {
  const rangeDates = [new Date(range?.[0] ?? '0'), new Date(range?.[1] ?? '0')];
  return scaleTime()
    .domain(rangeDates as Iterable<Date>)
    .range([16, width - 16]);
};
