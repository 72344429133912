import { FC } from 'react';

import { FormControl } from '@material-ui/core';

import { Org, OrgNode } from '../../../../generated/graphql';
import { withStyles } from '../../../../theme/komodo-mui-theme';
import { isNonNullable } from '../../../../utilities/types';
import {
  TreeEntry,
  flatten,
  getSubtree,
  idsToNames,
  makeTree,
  onSelectLevels,
} from '../../../../utilities/utilities';
import useOrganizationsQuery from '../../../CompanyTab/CompanyTabOrganizations/hooks/useOrganizationsQuery';
import { useCompanyTabID } from '../../../CompanyTab/CompanyTabUtils';
import PanelSelectHeader from '../../../Select/PanelSelectHeader/PanelSelectHeader';
import SelectFilterChipInput from '../../../Select/SelectFilter/SelectFilterChipInput';
import useMemoWrapper from '../../../useMemoWrapper';
import {
  ProjectFilterManager,
  ProjectFilterSetSettings,
  calculateNumberOfFiltersToShow,
  getSelectedForFilterType,
  getTitleForFilterType,
} from '../../ProjectsListUtils';

import ProjectsListFilterSelectStyles from './ProjectsListFilterSelectStyles';

type SelectLevelsEntryExtra = {
  count?: number;
  parentID?: UUID | null; // Temporary
};

type SelectLevelsEntry = TreeEntry<SelectLevelsEntryExtra>;

const getEntries = (orgs: Org[], filterManager: ProjectFilterManager) => {
  const orgTrees = orgs.flatMap((org) =>
    makeTree(org.nodes.map((node) => ({ ...node, label: node.name })))
  );
  const subtree = getSubtree(
    orgTrees,
    (filterManager.filterOptions?.organizationNodes ?? []).map((node) => (node as OrgNode).id)
  );
  return subtree;
};

type Props = {
  classes: Classes<typeof ProjectsListFilterSelectStyles>;
  clearAllText?: string;
  filterManager: ProjectFilterManager;
  filterType: string;
  setSettings: ProjectFilterSetSettings;
  formatValueForDisplay?: (value: string) => string;
};

const OrgsFilterLevelsSelect: FC<Props> = ({
  classes,
  clearAllText,
  filterManager,
  filterType,
  setSettings,
  formatValueForDisplay,
}) => {
  const companyID = useCompanyTabID();
  const organizationsQueryResult = useOrganizationsQuery(companyID);
  const orgs = organizationsQueryResult.data?.organizations ?? [];

  const { clearFilterType, setFilters } = filterManager;

  const title = useMemoWrapper(getTitleForFilterType, filterType);
  const entries = useMemoWrapper(getEntries, orgs, filterManager);
  const allEntries = useMemoWrapper(flatten<TreeEntry<OrgNode>>, entries);
  const [selectedValues] = useMemoWrapper(getSelectedForFilterType, filterManager, filterType);
  const selectedLabels = useMemoWrapper(idsToNames, selectedValues, allEntries);

  const flat = useMemoWrapper(flatten<OrgNode>, entries);
  const options = flat.map(({ id }) => id);
  const labels = flat.map(({ label }) => label);
  const depths = flat.map(({ depth }) => depth);
  const selectedEntries = flat.filter(({ id }) => selectedValues.includes(id)).map(({ id }) => id);

  const filterAndSet = (filtered: UUID[]) => {
    const filteredIDs = filtered
      .map((id) => flat.find(({ id: flatId }) => flatId === id))
      .filter(isNonNullable)
      .map(({ id }) => id);
    setFilters({ type: filterType, value: '', values: filteredIDs }, setSettings);
  };

  const onSelect = (selectedID: string) => {
    const option = flat.find(({ id }) => id === selectedID) as SelectLevelsEntry;
    const filtered = onSelectLevels(option, selectedEntries);
    filterAndSet(filtered);
  };

  const clearFilter = () => {
    clearFilterType(filterType, setSettings);
  };

  if (!title) return null;
  if (options.length === 0) return null;

  const cy = `ProjectsListFilterSelect-${title.replace(' ', '')}`;
  return (
    <div
      className={
        selectedValues.length > 0
          ? `${classes.outerContainer} ${classes.activeContainer} ${classes.borderTop}`
          : `${classes.outerContainer} ${classes.borderTopPlaceholder}`
      }
    >
      <PanelSelectHeader
        clearAllText={clearAllText}
        clearFilter={clearFilter}
        showRemove={selectedValues.length > 0}
        title={title}
      />
      <div className={classes.selectContainer}>
        <FormControl
          classes={{
            root: classes.fullWidth,
          }}
        >
          <SelectFilterChipInput
            cy={cy || ''}
            depths={depths}
            formatValueForDisplay={formatValueForDisplay}
            labels={labels}
            maxChipsShown={calculateNumberOfFiltersToShow(selectedLabels)}
            onSelect={onSelect}
            options={options}
            selected={selectedValues}
            selectedLabels={selectedLabels}
            title={title}
            tooltip={selectedLabels}
          />
        </FormControl>
      </div>
    </div>
  );
};

export default withStyles(ProjectsListFilterSelectStyles)(OrgsFilterLevelsSelect);
