import { matchContributionsToItemsLike } from '../components/CostReport/CostReportList/CostReportList/CostReportListUtils';
import {
  MASTERFORMAT_CATEGORY,
  SEPARATED_COST,
  STATE_LIST_ARRAY,
  UNIFORMAT_CATEGORY,
} from '../constants';

// Filters out redundant UniFormat and MasterFormat Categories
export const filterRedundantCategories = (categories: Category[]) => {
  const filteredCategories: Category[] = [];
  let masterFormatLevel = 0;
  let masterFormatCategory: Category | undefined;
  let uniFormatLevel = 0;
  let uniFormatCategory: Category | undefined;
  categories.forEach((rc: Category) => {
    if (rc.number === SEPARATED_COST) {
      filteredCategories.push(rc);
    }
    const isUniFormat = rc.categorization && rc.categorization.name === UNIFORMAT_CATEGORY;
    const isMasterFormat = rc.categorization && rc.categorization.name === MASTERFORMAT_CATEGORY;
    if (!isUniFormat && !isMasterFormat && rc.categorization) {
      filteredCategories.push(rc);
    } else {
      const levelSet = new Set();
      if (rc.levels) {
        rc.levels.forEach((level: { number: string }) => {
          levelSet.add(level.number);
        });
        const levelCatCount = levelSet.size;
        if (isUniFormat) {
          if (levelCatCount > uniFormatLevel) {
            uniFormatLevel = levelCatCount;
            uniFormatCategory = rc;
          }
        } else if (isMasterFormat) {
          if (levelCatCount > masterFormatLevel) {
            masterFormatLevel = levelCatCount;
            masterFormatCategory = rc;
          }
        }
      }
    }
  });
  if (uniFormatCategory) filteredCategories.push(uniFormatCategory);
  if (masterFormatCategory) filteredCategories.push(masterFormatCategory);
  return filteredCategories;
};

type StatusReport = {
  status: string;
  report: CostReport;
};

const getStatusReports: (costReportColumns: CostReportColumn[]) => StatusReport[] = (
  costReportColumns
) => {
  const statusReports: StatusReport[] = [];
  STATE_LIST_ARRAY.forEach((status: string) => {
    const statusColumn = costReportColumns.find((column: CostReportColumn) =>
      column.type.includes(status)
    );
    if (statusColumn && statusColumn.report) {
      statusReports.push({
        status,
        report: statusColumn.report,
      });
    }
  });
  return statusReports;
};

// getItemContributionsFromCostReport goes through the status cost reports
// matching categorized item costs to corresponding items to make complete items with categorized cost
type CategorizedItemStateCost = {
  id: UUID;
  categories: Category[];
  categorizedState: { costImpact: Cost; status: string };
};

export const getItemLikeContributionsFromCostReport = (
  reports: MilestoneCostReport,
  items: Item[],
  options: Option[],
  // TODO: stop relying on categorizedState: undefined
  returnCategorized = false
) => {
  // do we have items?
  if (!items && !options) return [];
  // are some columns missing?
  if (!reports) return (items as ItemLike[]).concat(options);
  // we are going to gather all the keyed contributions first
  const categorizedItemStateCosts: CategorizedItemStateCost[] = [];
  const { costReportColumns } = reports;
  getStatusReports(costReportColumns as CostReportColumn[]).forEach(({ status, report }) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
    report.categorizedItemCosts.forEach((c: any) => {
      const { id } = c;
      const categories = filterRedundantCategories(c.categories);
      const costImpact = c.range;
      categorizedItemStateCosts.push({
        id,
        categories,
        categorizedState: {
          costImpact,
          status,
        },
      });
    });
  });
  const typeItemCosts = matchContributionsToItemsLike(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
    categorizedItemStateCosts.map((categorizedItemCost: any) => ({
      categorizedItemCost,
    })),
    items,
    options
  );
  const itemLikeContributions = typeItemCosts.map(({ categorizedItemCost, itemLike }) => ({
    ...itemLike,
    ...categorizedItemCost,
  }));
  // if item has only one contribution, then don't need to describe it as a partial categorization
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  return itemLikeContributions.map((item: { id: any }, index: any) => {
    const isOnlyContrib = itemLikeContributions.some(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
      (other: { id: any }, otherIndex: any) => item.id === other.id && index !== otherIndex
    );
    if (returnCategorized || isOnlyContrib) return item;
    return { ...item, categorizedState: undefined };
  });
};
