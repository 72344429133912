import { MutationHookOptions, useMutation } from '@apollo/client';

import {
  RemoveProjectLeadMutation,
  RemoveProjectLeadMutationVariables,
  RemoveProjectsLeadMutation,
  RemoveProjectsLeadMutationVariables,
  SetProjectLeadMutation,
  SetProjectLeadMutationVariables,
  SetProjectsLeadMutation,
  SetProjectsLeadMutationVariables,
} from '../../../generated/graphql';

import {
  removeProjectLeadMutation,
  removeProjectsLeadMutation,
  setProjectLeadMutation,
  setProjectsLeadMutation,
} from './queries';

export const useSetProjectLead = (
  options?: MutationHookOptions<SetProjectLeadMutation, SetProjectLeadMutationVariables>
) =>
  useMutation<SetProjectLeadMutation, SetProjectLeadMutationVariables>(
    setProjectLeadMutation,
    options
  );

export const useSetProjectsLead = (
  options?: MutationHookOptions<SetProjectsLeadMutation, SetProjectsLeadMutationVariables>
) =>
  useMutation<SetProjectsLeadMutation, SetProjectsLeadMutationVariables>(
    setProjectsLeadMutation,
    options
  );

export const useRemoveProjectLead = (
  options?: MutationHookOptions<RemoveProjectLeadMutation, RemoveProjectLeadMutationVariables>
) =>
  useMutation<RemoveProjectLeadMutation, RemoveProjectLeadMutationVariables>(
    removeProjectLeadMutation,
    options
  );

export const useRemoveProjectsLead = (
  options?: MutationHookOptions<RemoveProjectsLeadMutation, RemoveProjectsLeadMutationVariables>
) =>
  useMutation<RemoveProjectsLeadMutation, RemoveProjectsLeadMutationVariables>(
    removeProjectsLeadMutation,
    options
  );
