import { placeLabels } from '../../dragon-scales/TimelineCharts/TimelineMultiTooltip/utils';
import useMemoWrapper from '../../useMemoWrapper';

type Data = { date: Date; value: number };

type Props = {
  color: string[];
  data: Data[];
  content: string[];
  yDomain: [number, number];
};

const MIN_GAP = 0;
const VIEW_HEIGHT = 16;
const PLOT_HEIGHT = 72;
const TOOLTIP_HEIGHT = PLOT_HEIGHT + VIEW_HEIGHT * 3 - VIEW_HEIGHT / 2;

export default function TimelineLabels(props: Props) {
  const min = props.yDomain[0];
  const max = props.yDomain[1];
  const local = props.data.map((data, i) => ({
    data,
    color: props.color[i],
    content: props.content[i],
  }));
  const graphValuesCosts = props.data.map((d) => d.value);
  const labelPositions = useMemoWrapper(
    placeLabels,
    PLOT_HEIGHT,
    VIEW_HEIGHT,
    graphValuesCosts,
    MIN_GAP,
    min,
    max
  );
  return (
    <div className="relative" style={{ height: TOOLTIP_HEIGHT }}>
      {local
        .sort((a, b) => b.data.value - a.data.value)
        .map((l, i) => {
          const color = l.color;
          const content = l.content;
          const top = labelPositions[i].labelTop;
          return (
            <div
              key={`${i.toString()}-${content}`}
              className="absolute max-w-[100px] overflow-hidden rounded-md type-label"
              style={{
                color,
                filter: 'url(#blur)',
                top,
                height: VIEW_HEIGHT,
              }}
            >
              {content}
            </div>
          );
        })}
    </div>
  );
}
