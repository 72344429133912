import { ComponentProps, useEffect, useState } from 'react';
import { useMenuTriggerState } from 'react-stately';

import { navEventTypes } from '../../../analytics/analyticsEventProperties';
import { IconMenuButton, Tooltip } from '../../scales';
import useSendNavAnalytics from '../hooks/useSendNavAnalytics';
import {
  EmailIcon,
  HelpCenterIcon,
  HubSpotChatIcon,
  KBShortcutsIcon,
  SpinnerIcon,
  SupportMenuIcon,
  WhatsNewIcon,
} from '../icons';

type OpenState = 'closed' | 'loading' | 'open';

type Props = {
  position: {
    left: number;
  };
  tooltipOffset?: ComponentProps<typeof Tooltip>['offset'];
  tooltipPlacement?: ComponentProps<typeof Tooltip>['placement'];
};

export default function SupportMenuButton(props: Props) {
  const sendNavAnalytics = useSendNavAnalytics();

  const [openState, setOpenState] = useState<OpenState>(
    window.HubSpotConversations?.widget.status().loaded ? 'open' : 'closed'
  );

  const state = useMenuTriggerState({
    onOpenChange: (isOpen) => {
      if (isOpen) {
        sendNavAnalytics(navEventTypes.OPEN_SUPPORT_MENU);
      }
    },
  });

  useEffect(() => {
    // Despite the fact that we load the widget via `widgetOpen: true`, the widget
    // sometimes fails to open by itself. This generally seems to occur when the widget
    // took longer than usual to load. As a fallback, we hook into this widgetLoaded
    // event and call open() just in case.
    window.HubSpotConversations?.on('widgetLoaded', () => {
      window.HubSpotConversations?.widget.open();
      setOpenState('open');
    });

    // The widget can be closed by clicking on the big X button that Hubspot renders.
    // If the user closes the chat that way, we want to remove the default Hubspot
    // chat button, which is accomplished by removing the widget entirely.
    window.HubSpotConversations?.on('widgetClosed', () => {
      window.HubSpotConversations?.widget.remove();
      setOpenState('closed');
    });
  }, []);

  let icon = <SupportMenuIcon />;
  if (openState === 'loading') icon = <SpinnerIcon />;

  return (
    <>
      <Tooltip
        content="Open support menu"
        isDisabled={state.isOpen}
        offset={props.tooltipOffset}
        placement={props.tooltipPlacement}
      >
        <IconMenuButton
          aria-label="support menu button"
          data-cy="nav-support-menu-button"
          icon={icon}
          sections={[
            {
              entries: [
                {
                  id: 'whats-new',
                  label: "What's new",
                  onClick: () => {
                    openURL('https://success.join.build/en/knowledge/whats-new-in-join');
                    sendNavAnalytics(navEventTypes.OPEN_SUPPORT_WHATS_NEW);
                  },
                  startAdornment: (
                    <div className="icon-md">
                      <WhatsNewIcon />
                    </div>
                  ),
                },
                {
                  id: 'help-center',
                  label: 'Visit our help center',
                  onClick: () => {
                    openURL('https://success.join.build');
                    sendNavAnalytics(navEventTypes.OPEN_SUPPORT_VISIT_SUCCESS);
                  },
                  startAdornment: (
                    <div className="icon-md">
                      <HelpCenterIcon />
                    </div>
                  ),
                },
                {
                  id: 'contact-support',
                  label: 'Email Join support',
                  onClick: () => {
                    openURL('mailto:support@join.build');
                    sendNavAnalytics(navEventTypes.OPEN_SUPPORT_EMAIL_SUPPORT);
                  },
                  startAdornment: (
                    <div className="icon-md">
                      <EmailIcon />
                    </div>
                  ),
                },
                {
                  id: 'kb-shortcuts',
                  label: 'View keyboard shortcuts',
                  onClick: () => {
                    openURL('https://success.join.build/en/knowledge/keyboard-shortcuts');
                    sendNavAnalytics(navEventTypes.OPEN_SUPPORT_KEYBOARD_SHORTCUTS);
                  },
                  startAdornment: (
                    <div className="icon-md">
                      <KBShortcutsIcon />
                    </div>
                  ),
                },
              ],
            },
            {
              entries: [
                {
                  id: 'hubspot-chat',
                  label: 'Chat with Join',
                  onClick: () => {
                    if (openState === 'open') {
                      window.HubSpotConversations?.widget.remove();
                      setOpenState('closed');
                    } else {
                      window.HubSpotConversations?.widget.load({ widgetOpen: true });
                      setOpenState('loading');
                    }
                  },
                  startAdornment: (
                    <div className="icon-md">
                      <HubSpotChatIcon />
                    </div>
                  ),
                },
              ],
            },
          ]}
          type="secondary"
        />
      </Tooltip>
      <style>
        {/* Hyper-specificity to beat out Hubspot's !important styles */}
        {`div#hubspot-messages-iframe-container.widget-align-left:has(iframe) {
       left: ${props.position.left}px !important;
     }`}
      </style>
    </>
  );

  function openURL(url: string) {
    window.open(url, '_blank', 'noreferrer');
  }
}
