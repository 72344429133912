import { FC, useContext, useMemo } from 'react';
import MediaQuery from 'react-responsive';

import { withStyles } from '@material-ui/core';

import { BREAKDOWNS } from '../../../constants';
import ChartsAllMilestones from '../../Charts/ChartsAllMilestones/ChartsAllMilestones';
import { getTrendData } from '../../Charts/ChartsAllMilestones/ChartsAllMilestonesUtils';
import DashboardChartPlaceholder from '../../dashboard/DashboardCharts/DashboardChartsPlaceholder';
import CostSummary from '../../estimate/CostSummary';
import { ProjectTermStore } from '../../ProjectDisplaySettings/TerminologyProvider';

import PrintBreakdownStyles, { COST_REPORT_HEIGHT } from './PrintBreakdownStyles';

type PrintBreakdownProps = {
  breakdown: MilestonesCostTrendsBreakdown;
  categorySubheading: JSX.Element;
  classes: Classes<typeof PrintBreakdownStyles>;
  emptyState: () => JSX.Element;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  loading: boolean;
};

const PrintBreakdown: FC<PrintBreakdownProps> = ({
  breakdown,
  categorySubheading,
  classes,
  emptyState,
  loading,
}) => {
  const { segments, milestoneCostReports } = breakdown;
  const t = useContext(ProjectTermStore);
  const trendData = getTrendData(t, segments);

  const summary = useMemo(
    () =>
      milestoneCostReports.length > 0 ? (
        <CostSummary costReport={milestoneCostReports[0]} variant={BREAKDOWNS} />
      ) : (
        <div className={classes.summary}>
          <DashboardChartPlaceholder emptyMessage="" height={COST_REPORT_HEIGHT} loading />
        </div>
      ),
    [classes, milestoneCostReports]
  );

  // dependent logic for chart and loading, error messages
  const chart = useMemo(() => {
    const hasMilestone = trendData.lineData.length > 0;
    return hasMilestone ? (
      <>
        <MediaQuery print>
          <ChartsAllMilestones isPrint isSmall trendData={trendData} />
        </MediaQuery>
        <MediaQuery print={false}>
          <ChartsAllMilestones isSmall trendData={trendData} />
        </MediaQuery>
      </>
    ) : (
      <>{emptyState()}</>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO CT-566: Fix this pls :)
  }, [classes, loading, trendData]);

  return (
    <div className={classes.container}>
      {categorySubheading}
      <div className={classes.costSummary}>{summary}</div>
      {chart}
    </div>
  );
};

export default withStyles(PrintBreakdownStyles)(PrintBreakdown);
