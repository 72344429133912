import { KeyDatesNudgeContent } from '../../../../KeyDatesNudge/Content/KeyDatesNudgeContent';
import CreateButton from '../../../../KeyDatesNudge/Dialog/CreateButton';
import { useKeyDates } from '../../../../KeyDatesNudge/hooks/useKeyDates';
import { Button, DialogContent, DialogFlowStep } from '../../../../scales';
import FooterRightLoadingProject from '../../FooterRightLoadingProject';

type Props = {
  keyDates: ReturnType<typeof useKeyDates>;
  name: string;
  submitted: boolean;
};

export default function getKeyDatesStep(props: Props): DialogFlowStep {
  return {
    renderContent: () => (
      <DialogContent className="min-h-[400px]">
        <KeyDatesNudgeContent {...props.keyDates} />
      </DialogContent>
    ),
    renderFooterLeft: () => null, // No going back
    renderFooterRight: ({ onNext }) =>
      props.submitted ? (
        <FooterRightLoadingProject projectName={props.name} />
      ) : (
        <div className="flex gap-2">
          <Button data-cy="skip-button" label="Skip" onClick={onNext} type="secondary" />
          <CreateButton
            activityCount={props.keyDates.activityCount}
            onSubmit={() => {
              onNext();
              props.keyDates.onSubmit();
            }}
          />
        </div>
      ),
  };
}
