import { Typography } from '@material-ui/core';

import { withStyles } from '../../../../theme/komodo-mui-theme';
import ScheduleImpact, { ScheduleImpactVariant } from '../../../shared-widgets/ScheduleImpact';

import styles from './ScheduleImpactHeaderStyles';

interface ScheduleImpactHeaderProps {
  classes: Classes<typeof styles>;
  scheduleImpact: ScheduleImpact;
}

const ScheduleImpactHeader = ({ classes, scheduleImpact }: ScheduleImpactHeaderProps) => {
  return (
    <>
      <Typography className={classes.collapseTitle} data-cy="item-details-schedule-impact-panel">
        Schedule Impact
      </Typography>
      <div className={classes.spacer} />
      <div className={classes.collapseTitle}>
        <ScheduleImpact
          scheduleImpact={scheduleImpact}
          variant={ScheduleImpactVariant.ITEM_COLLAPSE}
        />
      </div>
    </>
  );
};

export default withStyles(styles)(ScheduleImpactHeader);
