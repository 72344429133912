import { useMemo, useState } from 'react';
import * as React from 'react';
import { useEffectOnce } from 'react-use';

import RowHighlightContext, { RowHighlightableElement } from './context';
import RowHighlight from './RowHighlight';

type HighlightPosition = {
  top: number;
  height: number;
  left: number;
  width: number | string;
} | null;

const RowHighlightProvider = (props: { children: React.ReactChild | React.ReactChild[] }) => {
  const [mouseY, setMouseY] = useState<number | null>(null);
  const [isScrollHandled, setIsScrollHandled] = useState(true);
  const [refs, setRefs] = useState<React.RefObject<RowHighlightableElement>[]>([]);
  const [highlightableAreaRef, setHighlightableAreaRef] =
    useState<React.RefObject<HTMLDivElement>>();

  useEffectOnce(() => {
    // This root element is the one that we render React into. It should always exist.
    const rootElement = document.querySelector('#root');
    if (!rootElement) {
      throw new Error(
        'Failed to find an element with an ID of "root". This is likely due to an unexpected change to our index.html'
      );
    }

    const handleMouseMove = (e: MouseEvent) => {
      if (e.target instanceof HTMLElement || e.target instanceof SVGElement) {
        // Check if this was triggered from inside a dialog/popover.
        // The heuristic we use to determine that is whether or not this was
        // rendered in a React Portal. We determine /that/ by checking whether
        // the root element contains the event target. If it does, we know the event
        // was triggered from inside the main React tree and not a Portal.
        const targetWasRenderedInMainTree = rootElement.contains(e.target);

        if (targetWasRenderedInMainTree) {
          setMouseY(e.pageY);
        } else {
          setMouseY(null);
        }
      }
    };
    document.addEventListener('mousemove', handleMouseMove);

    const handleMouseLeaveBody = () => {
      setMouseY(null);
    };
    document.body.addEventListener('mouseleave', handleMouseLeaveBody);

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.body.removeEventListener('mouseleave', handleMouseLeaveBody);
    };
  });

  const callbacks = useMemo(
    () => ({
      onRegisterAsHighlightable: (ref: React.RefObject<RowHighlightableElement>) => {
        setRefs((prevState) => [...prevState, ref]);
      },
      onRegisterHighlightableArea: (ref: React.RefObject<HTMLDivElement>) => {
        setHighlightableAreaRef(ref);
      },
      onScroll: () => {
        setIsScrollHandled(false);
      },
    }),
    []
  );

  const highlightPosition = useMemo(() => {
    if (!mouseY) return undefined;

    let computedHighlightPosition: HighlightPosition | undefined;

    // Search through our refs and compute a highlight position if one should exist.
    refs.some((ref) => {
      const bcr = ref.current?.getBoundingClientRect?.();
      if (!bcr) return false;

      const containerBcr = highlightableAreaRef?.current?.getBoundingClientRect?.();

      const { top, bottom, height } = bcr;

      // If the ref's element is outside of the scrollable highlightable area, no highlight.
      if (containerBcr && top <= containerBcr.top) return false;
      if (containerBcr && top >= containerBcr.bottom) return false;

      // If the mouse is between the ref's element's top and bottom, show highlight.
      if (mouseY > top && mouseY < bottom) {
        computedHighlightPosition = {
          top,
          height,
          left: containerBcr?.left ?? 0,
          width: containerBcr?.width ?? '100vw',
        };
        return true;
      }

      return false;
    });

    // We've handled the scroll by recomputing the highlight position.
    if (!isScrollHandled) {
      setIsScrollHandled(true);
    }

    return computedHighlightPosition;
  }, [highlightableAreaRef, mouseY, refs, isScrollHandled]);

  return (
    <>
      <RowHighlightContext.Provider value={callbacks}>
        {props.children}
      </RowHighlightContext.Provider>
      {highlightPosition ? (
        <RowHighlight
          height={highlightPosition.height}
          left={highlightPosition.left}
          top={highlightPosition.top}
          width={highlightPosition.width}
        />
      ) : null}
    </>
  );
};

export default RowHighlightProvider;
