import { Permission, PermissionLevel } from '../../../../generated/graphql';
import { Checkbox } from '../../../scales';

type Props = {
  canViewCosts: boolean;
  displayCostPermission: Permission;
  onChange: (id: UUID, level: PermissionLevel, permissionResource?: string) => void;
};

const PermissionsHideCostsEdit = (props: Props) => {
  return (
    <div className="mb-2 flex w-1/2 border">
      <div className="w-1/3 content-center border-r px-6 py-2 type-body1">Costs visibility</div>
      <div className="w-2/3 content-center px-4 py-2">
        <Checkbox
          data-cy="hide-costs-checkbox"
          isSelected={!props.canViewCosts}
          onChange={(checked) =>
            props.onChange(
              props.displayCostPermission.id,
              checked ? PermissionLevel.NONE : PermissionLevel.VIEW_ONLY,
              props.displayCostPermission.resource
            )
          }
        >
          <div>
            <div className="type-heading3">Hide all costs</div>
            <div className="whitespace-normal type-body2">
              All costs will be hidden. Overrides all cost configurations below.
            </div>
          </div>
        </Checkbox>
      </div>
    </div>
  );
};

export default PermissionsHideCostsEdit;
