import { MenuItem, Typography } from '@material-ui/core';

import { COST_OF_CONSTRUCTION_REF, S1 } from '../../../constants';
import GridRowCheckbox from '../../JoinGrid/JoinGridRowCheckbox';

import InputsSelectReferenceStyles from './InputsSelectReferenceStyles';

type ReferencesMenuSubtotalProps = {
  classes: Classes<typeof InputsSelectReferenceStyles>;
  key: string;
  isChecked: boolean;
  isUnchecked?: boolean;
  value: string;
  text: string;
  onClick: (newRef: string) => void;
};

// Note:
// Material-ui doesn't let us use a functional component to wrap
// a MenuItem component.  If we wrap it then the MenuItems aren't interactive
export const getReferencesMenuSubtotal = ({
  classes,
  key,
  isChecked,
  isUnchecked,
  value,
  text,
  onClick,
}: ReferencesMenuSubtotalProps) => (
  <MenuItem
    key={key}
    classes={{
      selected: classes.selected,
    }}
    className={`${classes.choice} ${
      key === S1 || key === COST_OF_CONSTRUCTION_REF ? classes.dividerBottom : ''
    }`}
    data-cy="markup-choice"
    onClick={() => onClick(value)}
    value={value}
  >
    <span
      className={
        isChecked
          ? `${classes.subtotalChoiceText} ${classes.selectedChoiceText}`
          : classes.subtotalChoiceText
      }
    >
      <div className={classes.checkBox}>
        <GridRowCheckbox
          checked={isChecked}
          cy={`GridRowCheckbox-${text}`}
          height={24}
          includeBorder={false}
          onClick={() => {}}
          position="none"
          unchecked={isUnchecked}
          width={24}
        />
        <Typography className={classes.checkboxLabel}>{text}</Typography>
      </div>
    </span>
  </MenuItem>
);
