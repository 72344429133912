import { gql } from '../../../api/graphqlFragments';

export const bulkItemUpdateMutation = gql`
  mutation bulkItemUpdate(
    $projectID: UUID!
    $itemIDs: [UUID!]!
    $milestoneID: UUID
    $status: Status
    $meetingID: UUID
    $clearMeetingID: Boolean
    $assigneeEmail: String
    $clearAssigneeEmail: Boolean
    $dueDate: Time
    $clearDueDate: Boolean
    $addCategories: [CategoryInput]
    $clearCategories: [UUID]
    $costMode: CostMode!
  ) {
    bulkItemUpdate(
      projectID: $projectID
      items: {
        itemIDs: $itemIDs
        milestoneID: $milestoneID
        status: $status
        meetingID: $meetingID
        clearMeetingID: $clearMeetingID
        assigneeEmail: $assigneeEmail
        clearAssigneeEmail: $clearAssigneeEmail
        dueDate: $dueDate
        clearDueDate: $clearDueDate
        addCategories: $addCategories
        clearCategories: $clearCategories
      }
      costMode: $costMode
    ) {
      itemCount
      optionCount
    }
  }
`;
