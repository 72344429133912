import { formatCost } from '../../utilities/currency';
import { EMPTY_COST } from '../../utilities/string';

export default function ProjectCostRow(props: {
  className: string;
  cost?: number | null;
  currency: string;
  label: string;
}) {
  const formattedCost =
    props.cost && props.cost > 0
      ? formatCost(props.cost, {
          rounded: true,
          showCents: false,
          settingsOverride: {
            ROUNDING_PRECISION: 3,
            CURRENCY: props.currency,
          },
        })
      : EMPTY_COST;

  return (
    <>
      <div className={`${props.className} type-table-text`}>{props.label}</div>
      <div className={`${props.className} text-right type-table-number`}>{formattedCost}</div>
    </>
  );
}
