import { gql } from '../../../api/graphqlFragments';

export const getItemContingenciesQuery = gql`
  query getItemContingencies($projectID: UUID!, $itemID: UUID!, $costMode: CostMode) {
    getItemContingencies(projectID: $projectID, itemID: $itemID, costMode: $costMode) {
      draws {
        id
        type
        name
        error
        draw
        drawFromID
        isDrawingFullAmount
      }
      availableMilestoneContingencies {
        id
        type
        name
        error
        total
        isBeingDrawn
      }
    }
  }
`;
