import { ProjectBannersQuery, ProjectBannersQueryVariables } from '../../../../generated/graphql';
import { useQuery } from '../../../../hooks/useMountAwareQuery';

import { getProjectBannersQuery } from './queries';

export const useProjectBannersQuery = (projectID: UUID) =>
  useQuery<ProjectBannersQuery, ProjectBannersQueryVariables>(getProjectBannersQuery, {
    variables: { projectID },
    skip: !projectID,
  });
