import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../theme/komodo-mui-theme';
import { titleStyle } from '../CostReport/CostReportHeader/CostReportHeaderStyles';

export const COST_HEIGHT = 32;
const GUTTER = 16;
export const PADDING = 12;
export const NAME_HEIGHT = 46;
export const COMP_WIDTH = 328;
const SIDEBAR_WIDTH = 300;
const BOTTOM_PADDING = 40;

export const BOX_SHADOW_COLOR = '#6d6d6d40';
const boxShadow = `0 -2px 4px 2px ${BOX_SHADOW_COLOR}`;

const ProjectCompsStyles = (theme: KomodoTheme) =>
  createStyles({
    contentClass: {
      marginTop: -24,
    },
    controlHeader: {
      alignItems: 'center',
      display: 'flex',
      paddingLeft: 16,
      flex: '0 1 auto',
      justifyContent: 'flex-end',
    },
    collapseIcon: {
      width: GUTTER,
      height: GUTTER,
    },
    dialogClass: {
      width: 920,
      maxWidth: 920,
      maxHeight: 678,
      height: '', // overrides dialog overlay class to wrap content
    },
    errorIcon: {
      color: theme.palette.red,
      alignSelf: 'center',
      height: theme.iconSizes.compact,
      width: theme.iconSizes.compact,
    },
    lightGreenBG: {
      backgroundColor: theme.palette.chartLightGreen,
    },
    lightRedBG: {
      backgroundColor: theme.palette.chartLightRed,
    },
    greenBG: {
      backgroundColor: theme.palette.backgroundGreen,
    },
    tabContainer: {
      borderBottom: theme.border.line,
    },
    averageColorField: {
      height: 8,
    },
    root: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden',
      height: '100%',
    },
    button: {
      paddingBottom: 8,
    },
    costCellField: {
      display: 'inline-flex',
      flex: '1 1 0',
      height: COST_HEIGHT - 1,
      borderLeft: theme.border.line,
      '&:first-of-type': {
        borderLeft: 'unset',
      },
    },
    costInput: {
      display: 'inline-flex',
      alignItems: 'center',
      gap: '10px',
      padding: '8px !important', // override MUI which sets paddingLeft: 0 !important
      border: 'none',
      borderRadius: '0px !important',
      height: 'inherit',
      ...theme.typography.number2,
      '& input': {
        textAlign: 'right',
        padding: 0,
      },
      fontSize: '0.8rem',
    },
    costInputError: {
      gap: '0px',
      '& input': {
        color: `${theme.palette.red} !important`, // override MUI disabled cell color !important
      },
    },
    descriptionIndicator: {
      position: 'absolute',
      top: 0,
      right: 0,
      height: 0,
      width: 0,
      borderRight: `12px solid ${theme.palette.bauhaus.blue}`,
      borderBottom: '12px solid transparent',
    },
    costInputFocused: {
      // We can't effectively render the green border /on top of/ the possibly-nested
      // .descriptionIndicator due to how borders, outlines, or box-shadows are applied
      // onto absolutely-positioned elements like the descriptionIndicator.
      // This is why we shift and shrink the descriptionIndicator.
      // We use only an outline to simplify the interactions between the base state,
      // a highlighted state, and a possibly-nested .descriptionIndicator
      outline: `2px solid ${theme.palette.borderGreen}`,
      outlineOffset: -2,
      '& $descriptionIndicator': {
        top: 2,
        right: 2,
        borderRight: `8px solid ${theme.palette.bauhaus.blue}`,
        borderBottom: '8px solid transparent',
      },
    },
    costInputWithIcon: {
      '& input': {
        paddingLeft: '0px',
      },
    },
    dialogError: {
      ...theme.typography.webParagraph,
      background: `${theme.palette.red}29`,
      border: `1px solid ${theme.palette.red}`,
      padding: 16,
      display: 'flex',
      alignItems: 'center',
      gap: '18px',
    },
    dialogParagraph: { ...theme.typography.body1, fontWeight: 400, marginBottom: 16 },
    arrowIcon: {
      height: 16,
      width: 16,
      color: theme.palette.chartBlack,
    },
    errorIconCostCell: {
      color: theme.palette.red,
      height: theme.iconSizes.small,
      width: theme.iconSizes.small,
      marginRight: '4px',
    },
    greenBorder: {
      borderColor: theme.palette.backgroundGreen,
    },
    labelFields: {
      flexGrow: 1,
      paddingLeft: PADDING,
      width: '100%',
    },
    labels: {
      backgroundColor: theme.palette.backgroundWhite,
      borderRight: theme.border.line,
      paddingBottom: BOTTOM_PADDING,
      width: SIDEBAR_WIDTH,
    },
    overflow: {
      boxShadow,
      zIndex: 2,
    },
    scrollContent: {
      display: 'flex',
      width: 'max-content',
      flexGrow: 1,
      borderBottom: theme.border.line,
    },
    stickyCostsHeaderContainer: {
      paddingTop: '1px',
      borderBottom: theme.border.lineBorderBlack,
      borderTopLeftRadius: '4px',
      borderTopRightRadius: '4px',
    },
    stickyCostsAverageHeaderContainer: {
      border: theme.border.line,
      borderBottom: theme.border.lineBorderBlack,
      backgroundColor: theme.palette.backgroundGrey,
      borderTopLeftRadius: '4px',
      borderTopRightRadius: '4px',
      marginTop: 8,
    },
    stickyProjectNameContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '12px 0',
    },
    sticky: {
      position: 'sticky',
      display: 'flex',
      flexDirection: 'row',
      left: 0,
      background: theme.palette.backgroundWhite,
      zIndex: 3,
    },
    sidebar: {
      background: theme.palette.backgroundWhite,
      display: 'flex',
      flexDirection: 'row',
      zIndex: 4,
    },
    header: {
      display: 'flex',
      borderBottom: theme.border.line,
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: theme.cardHeader.padding,
      paddingTop: PADDING,
      paddingBottom: PADDING,
      backgroundColor: theme.palette.backgroundWhite,
      zIndex: 3,
      flex: '0 0 auto',
      '@media print': {
        display: 'none',
      },
    },
    titleHeadingContainer: {
      overflow: 'hidden',
    },
    titleHeading: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    ...titleStyle, // Everything below is ProjectComp
    scrollBody: {
      alignItems: 'end',
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
    },
    comp: {
      flex: `0 0 auto`,
      background: theme.palette.backgroundWhite,
      height: 'auto',
      width: COMP_WIDTH,
      '&:last-of-type': {
        '& .line-fields:last-of-type': {
          opacity: 0, // important to make lines end
        },
      },
    },
    compHeaderPortal: {
      flex: `0 0 auto`,
      background: theme.palette.backgroundWhite,
      height: 'max-content',
      width: COMP_WIDTH,
    },
    hiddenAverage: {
      background: theme.palette.backgroundWhite,
      borderRight: theme.border.lineBorderBlack,
      flex: `0 0 auto`,
      height: '100%',
      '&:last-of-type': {
        '& .line-fields:last-of-type': {
          opacity: 1, // important to make lines end
        },
      },
    },
    hiddenAverageStickyFooter: {
      width: 48,
    },
    buttonSection: {
      paddingLeft: PADDING,
      paddingRight: PADDING,
      display: 'flex',
      flexDirection: 'column',
    },
    labelHeader: {
      display: 'flex',
      flexDirection: 'column',
    },
    costColumnHeaderLabel: {
      ...theme.typography.h4newBold,
    },
    labelText: {
      ...theme.typography.webLabel,
      display: 'flex',
      width: '100%',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      '& > :first-child': {
        marginRight: 'auto',
      },
    },
    link: {
      ...theme.typography.h3CardHeader,
      color: theme.palette.link,
      cursor: 'pointer',
      fontFamily: theme.typography.fontFamily,
      textDecoration: 'none',
    },
    selectorPadding: {
      paddingTop: 8,
    },
    selectValue: {
      marginLeft: theme.spacing.selectPaddingLeft,
      alignItems: 'center',
      padding: `5.5px 16px 5.5px 0px`,
    },
    selectText: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    spacer: {
      flexGrow: 1,
    },
    fields: {
      borderTop: theme.border.invisible,
      marginTop: 8,
    },
    fieldsContainer: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
    },
    milestoneSelect: {
      height: COST_HEIGHT,
    },
    milestoneLink: {
      alignSelf: 'center',
      color: theme.palette.chartBlue,
      cursor: 'pointer',
      fontSize: 20,
    },
    milestoneLinkDisabled: {
      color: theme.palette.disabledGrey,
    },
    fieldsGroup: {
      paddingLeft: PADDING,
      paddingRight: PADDING,
      width: `100%`,
    },
    fieldsAverageCompCosts: {
      borderRadius: theme.border.radius,
      outline: theme.border.line,
      backgroundColor: theme.palette.backgroundGrey,
      marginTop: 10,
    },
    fieldsProjectCompCosts: {
      marginTop: 10,
    },

    field: {
      // The key thing here is that we need the field to be exactly COST_HEIGHT pixels tall.
      // To do that with minimal jank, we set the height but leave all layout faffing to
      // flexbox using `align-items`. This lets us write border rules simply and not write
      // janky padding values.
      display: 'flex',
      height: COST_HEIGHT,
      alignItems: 'center',
      flexDirection: 'row',
      '& + $field': {
        borderTop: theme.border.line,
      },
      '&:first-of-type': {
        borderTop: 'unset',
      },
    },
    sidebarLabelField: {
      padding: '0 8px',
    },
    milestoneFieldContainer: {
      display: 'flex',
      flexDirection: 'row',
      gap: '6px',
    },
    collapsibleMarkupLabel: {
      borderTop: theme.border.line,
      height: COST_HEIGHT,
      padding: 0,
      width: '100%',
      display: 'flex',
      justifyContent: 'start',
    },
    markupField: {
      paddingLeft: 32,
    },
    lineField: {
      flexDirection: 'column',
      height: COST_HEIGHT - 2,
      marginBottom: 2, // this accounts for border above/below
      width: PADDING,
    },
    // TODO: Remove with PCIE
    fieldInput: {
      backgroundColor: theme.palette.backgroundWhite,
      borderRadius: theme.border.radius,
      // remove top border radius in the middle of a field group
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      border: theme.border.line,
      display: 'flex',
      '&:first-of-type': {
        borderBottom: 'unset',
        borderTopLeftRadius: theme.border.radius,
        borderTopRightRadius: theme.border.radius,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
      },
      height: COST_HEIGHT,
    },
    flexRow: {
      display: 'flex',
    },
    fullWidth: {
      width: '100%',
    },
    bold: {
      fontWeight: 600,
    },
    greyBG: {
      backgroundColor: theme.palette.backgroundGrey,
    },
    nestingGrey: {
      backgroundColor: theme.palette.nestingGrey,
    },
    number: {
      ...theme.typography.number,
      fontWeight: 600,
    },
    nameField: {
      paddingTop: PADDING,
      height: NAME_HEIGHT,
    },
    sectionMargin: {
      marginLeft: PADDING,
      marginRight: PADDING,
    },
    sectionPadding: {
      marginLeft: PADDING,
      marginRight: PADDING,
    },
    row: {
      borderTop: theme.border.line,
      height: COST_HEIGHT,
      display: 'grid',
      '&:first-of-type': {
        borderTop: 'unset',
      },
      '&:nth-of-type(2)': {
        borderTop: theme.border.lineBorderBlack,
      },
    },
    hardBorder: {
      borderTop: theme.border.lineBorderBlack,
    },
    // Modifying the above `row` class...
    row_tableHeader: {
      // Tuck the table header row just under other elements that specify
      // a z-index - namely the "Add to milestone" button
      zIndex: -1,
      // Also, we revert to auto height (from the fixed height specified in `row`) because
      // the box-sizing model would be thrown off by this funky padding we're adding.
      height: 'auto',
    },
    cell: {
      height: COST_HEIGHT,
      display: 'inline-flex',
      padding: 8,
      borderLeft: theme.border.line,
      flex: '1 1 0',
      justifyContent: 'end',
      minWidth: '0',
      '&:first-of-type': {
        borderLeft: 'unset',
      },
    },
    summaryCostCell: {
      ...theme.typography.number,
      width: '100%',
      display: 'flex',
      flexDirection: 'row-reverse',
      justifyContent: 'space-between',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      fontSize: '0.8rem',
      lineHeight: 'normal',
      fontWeight: 500,
    },
    sectionHeader: {
      display: 'flex',
      alignItems: 'center',
      fontWeight: 600,
      gap: '4px',
      height: 32,
      justifyContent: 'space-between',
      padding: '2px 12px',
      width: '100%',
    },
    sectionHeaderMargin: {
      marginTop: 1 + PADDING * 2,
    },
    sectionHeaderPadding: {
      paddingLeft: PADDING,
      paddingRight: PADDING,
    },
    sectionHeaderToggleBtn: {
      flex: '1 0',
      gap: '4px',
      display: 'flex',
    },
    actionButton: {
      height: 28,
      width: 28,
      '&:hover': {
        backgroundColor: theme.palette.ds.selection.selected,
      },
      '&[data-menu-open=true]': {
        backgroundColor: theme.palette.fillGreen,
      },
      '& svg': {
        height: 18,
        width: 18,
      },
    },
    settingInputs: {
      padding: PADDING,
    },
    inlineFlex: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      paddingTop: PADDING,
    },
    inlineFlexAverage: {
      paddingTop: PADDING - 8,
    },
    flexGrow: {
      flexGrow: 1,
    },
    labelComp: {
      background: theme.palette.backgroundWhite,
      flexGrow: 1,
    },
    // TODO: Remove with PCIE
    inputContainer: {
      flexGrow: 1,
      height: COST_HEIGHT - 3, // less the border
      border: theme.border.invisible,
      display: 'flex',
      ...theme.typography.number,
      '&:focus-within': {
        borderRadius: 4,
      },
    },
    // TODO: Remove with PCIE
    escalationInputContainer: {
      paddingLeft: 6,
      '&:focus-within': {
        border: theme.border.focus,
        outline: theme.border.focus,
      },
    },
    // TODO: Remove with PCIE
    escalationInput: {
      '&:focus': {
        outline: 'none',
      },
      backgroundColor: 'transparent',
      border: 0,
      padding: 8,
      paddingRight: 0,
      textAlign: 'right',
      ...theme.typography.number2,
    },
    percentDecoration: {
      padding: 6,
      paddingLeft: 2,
      color: theme.palette.disabledGrey,
    },
    averageLocation: {
      border: theme.border.line,
      height: COST_HEIGHT,
      borderRadius: 4,
    },
    locationInput: {
      border: '1px transparent solid',
      height: COST_HEIGHT,
      ...theme.typography.number2,
      '&:focus-within': {
        height: COST_HEIGHT,
        border: theme.border.focus,
        outline: theme.border.focus,
        color: theme.palette.joinPrimary,
      },
    },
    locationField: {
      border: theme.border.line,
      height: COST_HEIGHT,
      borderRadius: theme.border.radius,
    },
    whiteBG: {
      backgroundColor: theme.palette.backgroundWhite,
    },
    visualizationSection: {
      borderBottom: theme.border.line,
      flexDirection: 'column',
      display: 'flex',
    },
    addToMilestoneBtn: {
      display: 'flex',
      width: '100%',
      minHeight: 'unset',
      height: COST_HEIGHT,
      padding: '4px 8px',
      alignItems: 'end',
      justifyContent: 'space-between',
      border: theme.border.lineBorderBlack,
      borderRadius: theme.border.radius,
      background: theme.palette.backgroundWhite,
      '&:hover': {
        background: theme.palette.backgroundWhite,
      },
    },
    addToMilestoneBtnLabel: {
      flexGrow: 1,
      textAlign: 'left',
    },
    teamLogosContainer: {
      width: '100%',
      height: 64,
    },
    excludedCompContainer: {
      opacity: 0.5,
    },
    tableHeaderElipsis: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    newFunctionalityNagDialog: {
      ...theme.typography.webParagraph,
      display: 'flex',
      flexFlow: 'column',
      alignItems: 'center',
      gap: '1rem',
    },
    mediumIcon: {
      height: 18,
      width: 18,
      padding: '2px 2px 2px 2px',
      '&:hover': {
        backgroundColor: theme.palette.backgroundFocusSelectGreen,
      },
      '&[data-menu-open=true]': {
        backgroundColor: theme.palette.fillGreen,
      },
      '& svg': {
        height: 18,
        width: 18,
      },
    },
    pinMargin: {
      margin: '8px 2px 2px 10px',
    },
    unitAbbreviationInput: {
      color: theme.palette.disabledGrey,
      maxWidth: 100,
      padding: '6px 8px 6px 8px',
    },
    unitAbbreviationText: {
      color: theme.palette.disabledGrey,
      fontSize: '0.9em',
      fontWeight: 400,
      maxWidth: 100,
    },
  });

export default ProjectCompsStyles;
