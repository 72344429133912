import { FC } from 'react';

import { ReactiveVar, useReactiveVar } from '@apollo/client';
import { Typography } from '@material-ui/core';

import { setReactiveLocal } from '../../../../api/apollo/reactiveVars';
import { EstimateTotalType } from '../../../../generated/graphql';
import { withStyles } from '../../../../theme/komodo-mui-theme';
import ShadedHelpTip from '../../../shared-widgets/ShadedHelpTip/ShadedHelpTip';
import ChangeCalculationIcon from '../../../table/estimate/ChangeCalculation.svg';

import EstimateTotalTypeRadioGroup from './EstimateTotalTypeRadioGroup';
import styles from './EstimateTotalTypeSelectStyles';

type EstimateTotalTypeSelectProps = {
  classes: Classes<typeof styles>;
  importEstimateKey: string;
  importEstimateVar: ReactiveVar<ImportEstimateParameters>;
};

const EstimateTotalTypeSelect: FC<EstimateTotalTypeSelectProps> = ({
  classes,
  importEstimateKey,
  importEstimateVar,
}) => {
  const importEstimate = useReactiveVar(importEstimateVar);
  const { selectedTotalType } = importEstimate;
  const onChange = (newValue: string) => {
    setReactiveLocal(importEstimateVar, importEstimateKey, {
      ...importEstimate,
      selectedTotalType: newValue as EstimateTotalType,
    });
  };

  const tipContent = (
    <div className={classes.tipFlex}>
      <div className={classes.tipContent}>
        <Typography>
          <b className={classes.tipContent}>Tip: </b>You can readjust this to a lower level of
          detail
        </Typography>
        <Typography>within the Estimate Table with this icon in the Total column:</Typography>
      </div>
      <img alt="Join" className={classes.tipIcon} src={ChangeCalculationIcon} />
    </div>
  );

  return (
    <>
      <div className={classes.tip}>
        <ShadedHelpTip shiftLeft tip={tipContent} />
      </div>

      <EstimateTotalTypeRadioGroup onChange={onChange} selectedValue={selectedTotalType} />
    </>
  );
};

export default withStyles(styles)(EstimateTotalTypeSelect);
