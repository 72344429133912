import { FC } from 'react';

import { Typography } from '@material-ui/core';

import { withStyles } from '../../../theme/komodo-mui-theme';

import CostReportMetricGroupRow from './CostReportMetricGroupRow';
import CostReportMetricGroupStyles from './CostReportMetricGroupStyles';
import { MetricGroup } from './CostReportMetricGroupUtils';

type CostReportMetricGroupProps = {
  classes: Classes<typeof CostReportMetricGroupStyles>;
  color?: string;
  metricGroup: MetricGroup;
  selected: string[];
  toggleSelected: (selected: string) => void;
};

const CostReportMetricGroup: FC<CostReportMetricGroupProps> = ({
  classes,
  color,
  metricGroup,
  selected,
  toggleSelected,
}) => {
  const { headerText, rows } = metricGroup;

  const numSelected = selected && selected.filter((s) => rows.map((r) => r.key).includes(s)).length;
  const numSelectedString = `${numSelected}/${rows.length}`;

  // Components
  const header = (
    <div className={classes.headerContainer}>
      <div className={classes.headerTextContainer}>
        <Typography className={classes.headerText}>{headerText}</Typography>
        <div className={classes.selectContent}>
          <Typography className={`${classes.headerText} ${classes.selectedText}`}>
            {numSelectedString}
          </Typography>
        </div>
      </div>
    </div>
  );

  const rowComps = rows.map((row) => (
    <CostReportMetricGroupRow
      key={row.key}
      color={color}
      isSelected={selected.includes(row.key)}
      row={row}
      toggleSelected={() => {
        toggleSelected(row.key);
      }}
    />
  ));

  return (
    <>
      {header}
      {rowComps}
    </>
  );
};

export default withStyles(CostReportMetricGroupStyles)(CostReportMetricGroup);
