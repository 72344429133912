import { FC } from 'react';

import CostImpact from '../../shared-widgets/CostImpact';

type ContingencyDrawTotalProps = {
  contingencyDrawTotal: Cost;
};

const ContingencyDrawTotal: FC<ContingencyDrawTotalProps> = ({ contingencyDrawTotal }) => {
  return (
    <div className="flex justify-between border-t bg-background-2 py-2 pl-4 pr-7">
      <div>Contingency / Allowance Draw Subtotal</div>
      <CostImpact value={contingencyDrawTotal} />
    </div>
  );
};

export default ContingencyDrawTotal;
