import { useEffect, useMemo, useRef, useState } from 'react';

import { FieldType } from '../../../api/gqlEnumsBe';
import { CATEGORY_CELL, DESCRIPTION, QTY, TOTAL, UNIT_PRICE, U_M } from '../../../constants';
import { Scale } from '../../../enums';
import { EstimateTotalType } from '../../../generated/graphql';
import { formatCost } from '../../../utilities/currency';

export const ITEM_HISTORY_ZOOM = 0.6875;

export const useTriggerOnHeaderHisoricalComplete = (
  triggerOnComplete: (() => void) | undefined,
  withHistory: boolean
) => {
  const [headerComplete, setHeaderComplete] = useState(false);
  const [historyComplete, setHistoryComplete] = useState(!withHistory);
  const [integrationsComplete, setIntegrationsComplete] = useState(false);

  useEffect(() => {
    if (headerComplete && historyComplete && integrationsComplete) {
      triggerOnComplete?.();
    }
  }, [headerComplete, historyComplete, integrationsComplete, triggerOnComplete]);

  return {
    setHeaderComplete,
    setHistoryComplete,
    setIntegrationsComplete,
  };
};

export const useHeightOnLoad = (triggerOnComplete: (() => void) | undefined, zoom: number) => {
  const [loadingComplete, setLoadingComplete] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const { current } = ref || {};
  const { clientHeight } = current || { clientHeight: undefined };

  const height = useMemo(() => {
    if (loadingComplete) {
      triggerOnComplete?.();
      return clientHeight ? zoom * clientHeight : undefined;
    }
    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO CT-566: Fix this pls :)
  }, [loadingComplete]);
  const onLoadComplete = () => setLoadingComplete(true);
  return { onLoadComplete, ref, height };
};

export function isMarkup(row: PrintGridRow | PrintViewCell[]): row is Markup {
  return 'type' in row && 'value' in row && 'percentScale' in row;
}

export function getColumnWidths(
  estimateTotalType: EstimateTotalType,
  tableVariant: 'estimate' | 'markup' | 'inherited' | 'ownerCost',
  fields: Field[]
) {
  const hasCategories = fields.some((field) => field.type === CATEGORY_CELL);
  if (tableVariant === 'estimate') {
    let columns = ['25px', '175px', '220px', '20px', '75px', '50px', '75px', '50px'];
    if (!hasCategories) {
      columns = columns.filter((column) => column !== '175px');
    }

    if (estimateTotalType === EstimateTotalType.TOTAL_TYPE_COST_TYPES) {
      // add cost type columns (10 of them) to the 4th index 50px wide
      columns = columns.slice(0, 4).concat(Array(12).fill('75px')).concat(columns.slice(4));
    }

    return columns;
  }

  if (tableVariant === 'markup' || tableVariant === 'ownerCost') {
    return ['25px', '225px', '150px', '150px', '100px', '150px'];
  }

  if (tableVariant === 'inherited') {
    return ['25px', '100px', '125px', '150px', '150px', '100px', '150px'];
  }
  return ['25px', '175px', '225px', '75px', '50px', '75px', '150px'];
}

export function getEstimatePrintRowCellValues(
  gridRowCells: PrintViewCell[],
  gridFields: Field[],
  numCategorizationColumns: number
) {
  let colDescription = '';
  let colQTY = '';
  let colUM = '';
  let colUnitPrice = '';
  let colTotal = '';
  let subcontractorUnitPrice = '';
  let subcontractorTotal = '';
  let userUnitPrice = '';
  let userTotal = '';
  let laborUnitPrice = '';
  let laborTotal = '';
  let materialUnitPrice = '';
  let materialTotal = '';
  let equipmentUnitPrice = '';
  let equipmentTotal = '';
  let otherUnitPrice = '';
  let otherTotal = '';

  for (let i = numCategorizationColumns; i < gridRowCells.length; i += 1) {
    const field = gridFields[i];
    const format = field.type === FieldType.CURRENCY_9 ? { scale: Scale.CURRENCY_9 } : undefined;

    switch (field.group) {
      case '':
        if (field.name === DESCRIPTION && field.type === FieldType.STRING) {
          colDescription = gridRowCells[numCategorizationColumns].value.string;
        } else if (field.name === QTY && field.type === FieldType.NUMBER) {
          colQTY = gridRowCells[i].value.string;
        } else if (field.name === U_M && field.type === FieldType.STRING) {
          colUM = gridRowCells[2 + numCategorizationColumns].value.string;
        } else if (
          field.name === UNIT_PRICE &&
          (field.type === FieldType.CURRENCY || field.type === FieldType.CURRENCY_9)
        ) {
          colUnitPrice = formatCost(gridRowCells[i].value.string, format);
        } else if (field.name === TOTAL && i === gridRowCells.length - 1) {
          colTotal = formatCost(gridRowCells[gridRowCells.length - 1].value.string);
        }
        break;
      case 'Subcontractor':
        if (field.name === UNIT_PRICE) {
          subcontractorUnitPrice = formatCost(gridRowCells[i].value.string, format);
        } else if (field.name === TOTAL) {
          subcontractorTotal = formatCost(gridRowCells[i].value.string, format);
        }
        break;
      case 'User':
        if (field.name === UNIT_PRICE) {
          userUnitPrice = formatCost(gridRowCells[i].value.string, format);
        } else if (field.name === TOTAL) {
          userTotal = formatCost(gridRowCells[i].value.string, format);
        }
        break;
      case 'Labor':
        if (field.name === UNIT_PRICE) {
          laborUnitPrice = formatCost(gridRowCells[i].value.string, format);
        } else if (field.name === TOTAL) {
          laborTotal = formatCost(gridRowCells[i].value.string, format);
        }
        break;
      case 'Material':
        if (field.name === UNIT_PRICE) {
          materialUnitPrice = formatCost(gridRowCells[i].value.string, format);
        } else if (field.name === TOTAL) {
          materialTotal = formatCost(gridRowCells[i].value.string, format);
        }
        break;
      case 'Equipment':
        if (field.name === UNIT_PRICE) {
          equipmentUnitPrice = formatCost(gridRowCells[i].value.string, format);
        } else if (field.name === TOTAL) {
          equipmentTotal = formatCost(gridRowCells[i].value.string, format);
        }
        break;
      case 'Other':
        if (field.name === UNIT_PRICE) {
          otherUnitPrice = formatCost(gridRowCells[i].value.string, format);
        } else if (field.name === TOTAL) {
          otherTotal = formatCost(gridRowCells[i].value.string, format);
        }
        break;
      default:
        break;
    }
  }
  return {
    colDescription,
    colQTY,
    colUM,
    colUnitPrice,
    colTotal,
    subcontractorUnitPrice,
    subcontractorTotal,
    userUnitPrice,
    userTotal,
    laborUnitPrice,
    laborTotal,
    materialUnitPrice,
    materialTotal,
    equipmentUnitPrice,
    equipmentTotal,
    otherUnitPrice,
    otherTotal,
  };
}

// getGridLinesFromEstimate takes estimate lines
// and adds the necessary properties to make it a GridLine type
export const getGridLinesFromEstimate = (estimate: Estimate): GridLine[] =>
  estimate?.lines.map((l) => {
    return {
      ...l,
      isDisabled: false,
      cells: l.cells.map((c) => {
        return {
          ...c,
          value: {
            string: c.value && 'string' in c.value ? c.value?.string : '',
            category: c.value && 'category' in c.value ? c.value?.category : undefined,
            formula: '',
            formulaDisplay: [],
          },
        };
      }),
    };
  }) ?? [];
