import { LinearProgress } from '@material-ui/core';

export default function TableLoader(props: {
  hasNegativeMargin: boolean;
  offset?: 'none' | 'compact' | 'full';
}) {
  const classes = [
    '!sticky !w-full',
    // Putting this /under/ the header's z-index.
    // The rationale for this is that we want it to drop under the header
    // in the edge case where the header labels wrap onto two lines.
    // To properly position the loader in that scenario we'd have to truck
    // around refs and getBoundingClientRect and that's just not worth it.
    'z-90',
    // Span the entire grid's width.
    '!col-[1_/_-1]',
  ];

  if (props.offset === 'none') {
    classes.push('!top-0');
  } else if (props.offset === 'compact') {
    classes.push('!top-6');
  } else if (props.offset === 'full') {
    classes.push('!top-10');
  }

  classes.push('!h-1');

  if (props.hasNegativeMargin) {
    classes.push('-mb-1');
  }

  const className = classes.join(' ');

  return <LinearProgress className={className} />;
}
