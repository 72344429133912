import { InsightsEvent, insightsEvent } from '../../../analytics/analyticsEventProperties';
import { useCompanyUserQuery } from '../../../hooks/useCompanyUserQuery';
import { useCompaniesAndCollaborators } from '../../../hooks/useProjectCompaniesQuery';
import useSendAnalytics from '../../../hooks/useSendAnalytics';
import { Tooltip } from '../../scales';

export const ProjectCompanyList = (props: { projectID: UUID }) => {
  const sendAnalytics = useSendAnalytics();
  const COLLABORATOR_LIMIT = 3;
  const COMPANY_LIMIT = 3;

  const companiesResult = useCompaniesAndCollaborators(props.projectID);
  const companyUserResult = useCompanyUserQuery();

  if (companiesResult.loading || companyUserResult.loading) return null;

  const companies = companiesResult.companies;
  const companyID = companyUserResult.data?.companyUser?.company?.id;
  companies.sort((a, b) => {
    if (a.company.id === companyID) return -1;
    if (b.company.id === companyID) return 1;
    return b.collaborators.length - a.collaborators.length;
  });

  const companyTooltip = (projectCompany: (typeof companies)[number]) => {
    return (
      <div key={projectCompany.company.id} className="type-body2">
        <div className="mb-2">
          <div className="font-bold">{projectCompany.company.name}</div>
          {projectCompany.role && <div>{projectCompany.role}</div>}
        </div>
        <div>
          {projectCompany.collaborators.slice(0, COLLABORATOR_LIMIT).map((it) => (
            <div key={it.id}>{it.user.name}</div>
          ))}
          {projectCompany.collaborators.length > COLLABORATOR_LIMIT && (
            <div>+{projectCompany.collaborators.length - COLLABORATOR_LIMIT}</div>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="flex flex-col gap-[2px]">
      {companies.slice(0, COMPANY_LIMIT).map((it) => (
        <div key={it.company.id} className="flex">
          <Tooltip
            content={companyTooltip(it)}
            onOpen={() =>
              sendAnalytics(
                insightsEvent(InsightsEvent.DETAILS_PARTNER_TOOLTIP, {
                  companyName: it.company.name,
                  companyType: it.company.type,
                })
              )
            }
          >
            <div className="text-type-link type-body3">{`${it.company.name} (${it.collaborators.length})`}</div>
          </Tooltip>
        </div>
      ))}
      {companies.length > COMPANY_LIMIT && (
        <Tooltip
          content={
            <div className="flex flex-col gap-4">
              {companies.slice(COMPANY_LIMIT).map((it) => companyTooltip(it))}
            </div>
          }
        >
          <div className="text-type-link type-body3">{`+${companies.length - COMPANY_LIMIT} companies`}</div>
        </Tooltip>
      )}
    </div>
  );
};
