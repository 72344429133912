import { FC } from 'react';

import { ITEM, ITEM_WITH_OPTIONS, OPTION, PENDING } from '../../../constants';
import { parseDate } from '../../../utilities/dates';
import { getItemStatusLabel } from '../../../utilities/item-status';
import { getItemCost, getUnfilteredCostImpactByCostMode } from '../../../utilities/items';
import { pluralizeCountString } from '../../../utilities/string';
import { renderCostString } from '../../CostReport/CostReportUtils';
import { styleItemNumber } from '../../Items/ItemsListItem/ItemsListItemUtils';
import { getStatusIsAcceptedIncorporated } from '../../Items/ItemsUtils';
import ItemsIcons from '../../ItemsList/ItemsIcons/ItemsIcons';
import { SMALL } from '../../ItemsList/ItemsIcons/ItemsIconsMap';
import { isScheduleSettingsDisabled } from '../../ProjectProperties/ProjectScheduleImpact/ProjectScheduleImpactSettings';
import {
  SCHEDULE_IMPACT_DEFAULT,
  getScheduleImpactText,
} from '../../shared-widgets/ScheduleImpact';

type PrintItemLikeSimpleProps = {
  shouldDisplayCosts: boolean;
  isExact?: boolean;
  itemLike: ItemDataQueryItem;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  unfiltered?: boolean;
  parentItem?: ItemDataQueryItem;
};

const PrintItemLikeSimple: FC<PrintItemLikeSimpleProps> = ({
  shouldDisplayCosts,
  isExact = false,
  itemLike,
  unfiltered = false,
  parentItem,
}) => {
  const isScheduleImpactEnabled = !isScheduleSettingsDisabled();

  if (!itemLike) {
    return <></>;
  }

  // parentIsChosen is used to display option status icons
  const parentIsChosen =
    itemLike.itemType === OPTION && parentItem
      ? getStatusIsAcceptedIncorporated(parentItem.status)
      : false;

  // leftMainText dispalys main item information in the top left
  let leftMainText = '';
  const status = (itemLike as PrintOptionExtensions).categorizedState?.status || itemLike.status;
  if (itemLike.itemType === ITEM || itemLike.itemType === ITEM_WITH_OPTIONS) {
    const item = itemLike as PrintItem;
    leftMainText = `${styleItemNumber(item?.number)} (${getItemStatusLabel(status)}) ${item.name}`;
  } else if (itemLike.itemType === OPTION) {
    // Options have an extra bit of display logic for "Not Chosen" case
    const option = itemLike as PrintOption;
    const optionStatus =
      parentItem && getStatusIsAcceptedIncorporated(parentItem.status) && status === PENDING
        ? 'Not Chosen'
        : getItemStatusLabel(status);
    leftMainText = `${styleItemNumber(option.number)} (${optionStatus}) ${option.name}`;
  }

  // leftSubText displays assignee/due date information in the bottom left
  const hasAssignee = itemLike.assignee;
  const hasDueDate = itemLike.dueDate;
  const leftSubText = (
    <>
      {hasAssignee && `Assignee: ${itemLike.assignee?.name ?? ''}`}
      {hasAssignee && hasDueDate && <div className="print-spacer" />}
      {hasDueDate && `Due Date: ${parseDate(itemLike.dueDate)}`}
    </>
  );

  const costImpact = unfiltered
    ? getUnfilteredCostImpactByCostMode(itemLike as ItemLike)
    : getItemCost(itemLike as ItemLike);
  const categorizedCostImpact = (itemLike as PrintOptionExtensions).categorizedState?.costImpact;
  const hasDifferentCategorizedCost =
    categorizedCostImpact && JSON.stringify(costImpact) !== JSON.stringify(categorizedCostImpact);

  // rightMainText displays cost impact in the top right
  const rightMainText: string = renderCostString({
    cost: hasDifferentCategorizedCost ? categorizedCostImpact : costImpact,
    isExact,
    isWide: true,
  });

  // rightSubText displays option information in the bottom right
  let rightSubText = '';
  if (itemLike.itemType === ITEM_WITH_OPTIONS) {
    const item = itemLike as PrintItem;
    rightSubText += pluralizeCountString('option', item.optionCount);
    if (item.chosenOption)
      rightSubText += ` (${item.chosenOption.number} ${item.status.toLowerCase()})`;
  }
  if (itemLike.itemType !== ITEM && hasDifferentCategorizedCost) rightSubText += ', ';
  if (hasDifferentCategorizedCost)
    rightSubText += `across all categories: ${renderCostString({
      cost: costImpact,
      isExact,
      isWide: true,
    })}`;

  const [numberText, criticalPathText] = getScheduleImpactText(
    itemLike.scheduleImpact ?? SCHEDULE_IMPACT_DEFAULT,
    {
      critical: 'critical path',
      noncritical: '',
    }
  );

  return (
    <div className={isScheduleImpactEnabled ? 'print-item-row' : 'print-item-row-2'}>
      <div className="print-item-row-details">
        <div className="print-item-row-details-top">
          <ItemsIcons notChosen={parentIsChosen} status={status} variant={SMALL} />
          <div className="print-item-row-details-top-text">{leftMainText}</div>
        </div>
        <div className="print-item-row-bottom-text">{leftSubText}</div>
      </div>
      <div className="print-item-row-cost print-item-row-col">
        {shouldDisplayCosts && <div className="print-no-wrap-text">{rightMainText}</div>}
        <div className="print-item-row-bottom-text print-item-row-bottom-text-right print-right-align">
          {rightSubText}
        </div>
      </div>
      {isScheduleImpactEnabled && (
        <div className="print-item-row-cost print-item-row-col">
          <div className="print-no-wrap-text print-lowercase">{numberText}</div>
          <div className="print-item-row-bottom-text print-item-row-bottom-text-right print-right-align print-red">
            {criticalPathText}
          </div>
        </div>
      )}
    </div>
  );
};

export default PrintItemLikeSimple;
