import { FC, useEffect, useState } from 'react';

import { Select } from '@material-ui/core';

import { S1, S2 } from '../../../constants';
import { EstimateTotalType } from '../../../generated/graphql';
import {
  getCategorizationsForProjectFromQueryData,
  useProjectCategorizationsQuery,
} from '../../../hooks/useProjectCategorizationsQuery';
import { withStyles } from '../../../theme/komodo-mui-theme';
import { pluralizeCountString } from '../../../utilities/string';
import { getProjectIdFromUrl } from '../../../utilities/url';
import ResetButton from '../../frame/common/ResetButton';
import SourceIcon from '../../Icons/Source';

import InputsSelectReferenceCategories from './InputsSelectReferenceCategories';
import InputsSelectReferenceInheritedOptions from './InputsSelectReferenceInheritedOptions';
import InputsSelectReferenceOptions from './InputsSelectReferenceOptions';
import InputsSelectReferenceStyles from './InputsSelectReferenceStyles';
import ReferencesMenu from './ReferencesMenu';
import { getSourceFilterMenuItems } from './SourceFilterMenuItems';
import { MarkupApplicationOptions, MarkupSelectors, ReferenceSelectorOptions } from './utils';

type InputsSelectReferenceProps = {
  classes: Classes<typeof InputsSelectReferenceStyles>;

  error?: string;
  markupAppliesToOptions: MarkupApplicationOptions;
  options: ReferenceSelectorOptions;
  canViewDirectCosts: boolean;
  markups: SelectableMarkup[];
  markupLine: string;
  onClose: (selectors: MarkupSelectors) => void;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  open?: boolean;
  style: React.CSSProperties;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  s1RefShouldIncludeS2: boolean;
  sourceFilterMenuOptions?: ItemLink[];
  totalType?: EstimateTotalType;
  transformOrigin?: 'top' | 'bottom';
};

const InputsSelectReference: FC<InputsSelectReferenceProps> = ({
  classes,
  error = undefined,
  markupAppliesToOptions,
  options,
  markups,
  markupLine,
  onClose,
  open = false,
  style,
  s1RefShouldIncludeS2,
  sourceFilterMenuOptions,
  totalType,
  transformOrigin = 'top',
  canViewDirectCosts,
}) => {
  const projectID = getProjectIdFromUrl();
  const { data } = useProjectCategorizationsQuery(projectID);
  const categorizations = getCategorizationsForProjectFromQueryData(data);

  // STATE VARIABLES
  const [menuOpen, setMenuOpen] = useState(open);

  const [selectors, setSelectors] = useState<MarkupSelectors>({
    applyFilterToMarkups: markupAppliesToOptions.applyFilterToMarkups,
    references: markupAppliesToOptions.references,
    categories: markupAppliesToOptions.categories,
    costTypes: markupAppliesToOptions.costTypes ?? [],
    sources: markupAppliesToOptions.sources ?? [],
    sourceFilterIDsWithoutS2Reference:
      markupAppliesToOptions.sourceFilterIDsWithoutS2Reference ?? [],
  });

  const handleNavigation = (event: KeyboardEvent) => {
    const { key } = event;
    if (key === 'Escape') {
      setMenuOpen(false);
      onClose(selectors);
    }
  };

  useEffect(() => {
    window.addEventListener('keyup', handleNavigation);
    return () => {
      window.removeEventListener('keyup', handleNavigation);
    };
  });

  // VARIABLES
  const { categories, references } = selectors;
  const hasFilters = categories.length > 0;
  const hasSourceFilterMenuOptions = sourceFilterMenuOptions && sourceFilterMenuOptions.length > 0;

  const content = options.isInherited ? (
    <>
      <div className="flex h-9 items-center border-b px-3 type-body2">
        <div className="flex-grow">{`Apply markup ${markupLine} to:`}</div>
        {!references.includes(S2) && (
          <ResetButton
            onClick={() => setSelectors({ ...selectors, references: [S1, S2] })}
            small
            text="Reset"
          />
        )}
      </div>
      <InputsSelectReferenceInheritedOptions
        selectedReferences={references}
        setSelectedReferences={(newRefs: string[]) =>
          setSelectors({ ...selectors, references: newRefs })
        }
      />
    </>
  ) : (
    <>
      <div className="flex h-9 items-center border-b px-3 type-body2">{`Apply markup ${markupLine} to:`}</div>
      <InputsSelectReferenceOptions
        canViewDirectCosts={canViewDirectCosts}
        error={error}
        hasFilters={hasFilters}
        markups={markups}
        options={options}
        s1RefShouldIncludeS2={s1RefShouldIncludeS2}
        selectors={selectors}
        setSelectors={setSelectors}
        sourceFilterMenuOptions={sourceFilterMenuOptions}
        totalType={totalType}
      />
      <InputsSelectReferenceCategories
        categorizations={categorizations}
        isFixedMarkup={options.isFixedMarkup}
        selectedCategorizationFilters={categories}
        setSelectedCategorizationFilters={(newCategories) =>
          setSelectors({ ...selectors, categories: newCategories })
        }
      />
      {!options.isFixedMarkup && hasSourceFilterMenuOptions && (
        <ReferencesMenu
          icon={<SourceIcon />}
          subMenuItems={getSourceFilterMenuItems(
            selectors,
            setSelectors,
            sourceFilterMenuOptions,
            markupLine
          )}
          subTitle={`Applied to lines/markups from ${pluralizeCountString(
            'source',
            selectors.sources.length
          )}`}
          title="Sources"
        />
      )}
    </>
  );

  return (
    <Select
      autoWidth
      classes={{
        select: classes.select,
        icon: classes.hideIcon,
      }}
      className={classes.root}
      disabled={!open}
      disableUnderline
      displayEmpty
      MenuProps={{
        transformOrigin: {
          vertical: transformOrigin,
          horizontal: 'left',
        },
      }}
      multiple
      onClose={() => {
        setMenuOpen(false);
        onClose(selectors);
      }}
      onOpen={() => {
        setMenuOpen(true);
      }}
      open={menuOpen}
      style={style}
      value={[]}
    >
      <div>{content}</div>
    </Select>
  );
};

export default withStyles(InputsSelectReferenceStyles)(InputsSelectReference);
