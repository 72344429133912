import Entry, { Props as EntryProps } from './Entry';
import InputAutocomplete, { Props as InputProps } from './InputAutocomplete';

type Props = {
  entries: EntryProps[];
} & Omit<InputProps, 'children'>;

export default function TextInputAutocomplete(props: Props) {
  return (
    <InputAutocomplete {...props}>
      {
        //* * Render entries */
        (props.entries ?? []).map(Entry)
      }
    </InputAutocomplete>
  );
}
