import { scaleBand, scaleLinear } from 'd3-scale';
import { useMemo } from 'react';

import { formatCost } from '../../../utilities/currency';
import SVGWithDimensions from '../../Charts/ChartsD3/SVGWithDimensions';
import { useChartDimensions } from '../../Charts/ChartsD3/useChartDimensions';
import { Tooltip } from '../../scales';
import { HeaderDisplayBy } from '../PieCharts/InsightsListHeaderPieBar';
import DesignPhaseStackedBarChartTooltip from '../ToolTips/DesignPhaseStackedBarChartTooltip';

import { StackDataItem, calculatePercentage } from './utils';

type Props = {
  data: StackDataItem[];
  onBarTooltip?: () => void;
  onCategorySelect?: (category: string) => void; // Callback prop for category selection
  onSummaryTooltip?: () => void;
  selectedDisplayByOption: HeaderDisplayBy;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  showBarHoverTooltip: boolean;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  showLegendTooltip: boolean;
};

// TODO: to make this component more generic:
// - Add title props
// - Don't hard code stacked bar color assignment
// - Add ability to stack more than 2 rectangles.
// - Make data-cy tags more generic.
export default function DesignPhaseStackedBarChart(props: Props) {
  const BAR_WIDTH = 16; // Constant bar width
  const BAR_PADDING = 4; // Padding between bars
  const isCurrency = props.selectedDisplayByOption === HeaderDisplayBy.VOLUME;
  // Calculate total share using a single reduce with flatMap
  const totalShare = props.data
    .flatMap((item) => item.values)
    .reduce((sum, item) => sum + Number(item.value), 0);

  const { ref, dimensions } = useChartDimensions({
    height: 100,
    marginTop: 10,
    marginRight: 2,
    marginBottom: 10,
    marginLeft: 2,
  });

  // Calculate the total width needed for all bars
  const totalBarWidth = (BAR_WIDTH + BAR_PADDING) * props.data.length - BAR_PADDING;
  const totalWidth = Math.max(totalBarWidth, 100); // For fewer bars ensure total width is atleast 100px

  // Calculate dimensions in X axis
  const x = scaleBand<string>()
    .domain(props.data.map((d) => d.category))
    .range([0, totalBarWidth])
    .paddingInner(BAR_PADDING / BAR_WIDTH);

  // Calculate dimensions in Y axis
  const y = scaleLinear<number, number>()
    .domain([0, Math.max(...props.data.map((d) => Math.max(...d.values.map((v) => v.value))))])
    .range([dimensions.boundedHeight - dimensions.marginBottom, dimensions.marginTop]);

  // X-axis
  const xAxisYPosition = dimensions.boundedHeight;

  // Get the max value for the second index entry (total project counts)
  const maxTotal = useMemo(() => {
    return Math.max(...props.data.map((d) => d.values[1].value));
  }, [props.data]);

  if (props.data.length === 0) return null;

  let maxValueDisplayed = false;

  return (
    <div>
      <div className="pb-1 pl-1 type-label">{`Design Phase (${props.data.length})`}</div>
      <div className="w-full overflow-x-auto">
        <SVGWithDimensions
          ref={ref}
          dimensions={{
            ...dimensions,
            width: totalWidth + dimensions.marginLeft + dimensions.marginRight,
          }}
        >
          <g transform={`translate(${dimensions.marginLeft}, 0)`}>
            {/* Background rectangle for legend tooltip */}
            <Tooltip
              content={
                <DesignPhaseStackedBarChartTooltip
                  selectedDisplayBy={props.selectedDisplayByOption}
                  stackData={props.data}
                />
              }
              isDisabled={!props.showLegendTooltip}
              onOpen={props.onSummaryTooltip}
              placement="top"
            >
              <rect
                fill="transparent"
                height={dimensions.boundedHeight}
                pointerEvents="all" // Ensures the tooltip is triggered on hover
                width={totalWidth}
                x={0}
                y={0}
              />
            </Tooltip>

            {props.data.map((d) => {
              const bottomRectangleHeight = y(0) - y(d.values[0].value);
              const topRectangleHeight = y(0) - y(d.values[1].value);
              const xPosition = x(d.category) ?? 0;
              const currentTotalValue =
                d.values.find((v) => v.name === 'projectsCount')?.value || 0;
              const heightsAreEqual = Math.abs(bottomRectangleHeight - topRectangleHeight) === 0;

              const shouldShowLabel = !maxValueDisplayed && d.values[1].value === maxTotal;
              if (shouldShowLabel) {
                maxValueDisplayed = true;
              }

              return (
                <Tooltip
                  key={d.category}
                  content={
                    <div className="grid grid-cols-[160px_40px_40px] items-center gap-1 text-left type-body2">
                      <div className="line-clamp-1 w-full">{d.category}</div>
                      <div className="text-right">
                        {isCurrency
                          ? formatCost(currentTotalValue, { short: true, showCents: false })
                          : currentTotalValue}
                      </div>
                      {/* Calculate percentage */}
                      <div className="text-right">
                        {calculatePercentage(currentTotalValue, totalShare)}%
                      </div>
                    </div>
                  }
                  isDisabled={!props.showBarHoverTooltip}
                  onOpen={props.onBarTooltip}
                  placement="top"
                >
                  <g
                    data-cy={`bar-${d.category.toLowerCase()}`}
                    onClick={() => {
                      if (props.onCategorySelect) props.onCategorySelect(d.category);
                    }} // Trigger callback on click
                    style={{ cursor: 'pointer' }} // Change cursor to pointer to indicate interactivity
                  >
                    {/* Total projects rectangle, dont draw if heights are equal */}
                    {!heightsAreEqual && (
                      <rect
                        className="fill-chart-axis"
                        data-cy="bar-rect-projects"
                        height={topRectangleHeight}
                        width={BAR_WIDTH}
                        x={xPosition}
                        y={y(d.values[1].value)}
                      />
                    )}
                    {/* Alerts projects rectangle */}
                    <rect
                      className="fill-item-status-rejected"
                      data-cy="bar-rect-alert-projects"
                      height={bottomRectangleHeight}
                      width={BAR_WIDTH}
                      x={xPosition}
                      y={y(d.values[0].value)}
                    />
                    {/* Add value label above the maximum bar (only for first occurrence) */}
                    {shouldShowLabel && (
                      <text
                        className="fill-current text-xs"
                        dominantBaseline="text-before-edge"
                        textAnchor="middle"
                        x={xPosition + BAR_WIDTH / 2}
                        y={y(d.values[1].value) - 16}
                      >
                        {maxTotal}
                      </text>
                    )}
                  </g>
                </Tooltip>
              );
            })}
            {/* X-axis line with arrow */}
            <line
              className="stroke-chart-axis"
              strokeWidth="2"
              x1="0"
              x2={totalWidth - 2}
              y1={xAxisYPosition}
              y2={xAxisYPosition}
            />
            <path
              className="fill-chart-axis stroke-chart-axis"
              d={`M ${totalWidth - 10} ${xAxisYPosition - 6} L ${
                totalWidth - 2
              } ${xAxisYPosition} L ${totalWidth - 10} ${xAxisYPosition + 6}`}
              strokeWidth="2"
            />
          </g>
        </SVGWithDimensions>
      </div>
    </div>
  );
}
