import { createStyles } from '@material-ui/core';

const AuthorizeRedirectPageStyles = () =>
  createStyles({
    content: {
      padding: 24,
    },
    padded: {
      paddingTop: 8,
    },
  });

export default AuthorizeRedirectPageStyles;
