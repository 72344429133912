import { FC, useEffect, useState } from 'react';

import { useReactiveVar } from '@apollo/client';
import { DialogContent } from '@material-ui/core';

import { getEntityDragDropDialogAnalytics } from '../../../../analytics/analyticsEventProperties';
import {
  FilesDialogView,
  filesDialogViewVar,
  selectedAssetsVar,
} from '../../../../api/apollo/reactiveVars';
import { withStyles } from '../../../../theme/komodo-mui-theme';
import AddUploadDragHover from '../../../shared-widgets/AddUploadDrag/AddUploadDragHover';
import { useDragDropFunctions } from '../../../shared-widgets/AddUploadDrag/AddUploadDragUtils';
import FilesExplorer from '../../FileExplorer/FilesExplorer';
import { useUserSourcesQuery } from '../../hooks';
import FilesAssets from '../FilesAssets/FilesAssets';
import { useEntityID } from '../FilesAssets/FilesAssetsUtils';
import FilesSources from '../FilesSources/FilesSources';

import FilesDialogSidebarItemWrapper from './FilesDialogSidebarItemWrapper';
import FilesDialogSidebarMilestoneWrapper from './FilesDialogSidebarMilestoneWrapper';
import styles from './FilesDialogStyles';
import { getCurrentUserSource } from './FilesDialogUtils';

type FilesDialogContentProps = {
  classes: Classes<typeof styles>;
  disabledIDs: UUID[];
  onDropFile: (file: File) => void;
  onItemMutated?: () => void;
};

const FilesDialogContent: FC<FilesDialogContentProps> = ({
  classes,
  onDropFile,
  disabledIDs,
  onItemMutated,
}) => {
  const { data } = useUserSourcesQuery();

  const { itemID, milestoneID } = useEntityID();
  const view = useReactiveVar(filesDialogViewVar);

  const analyticsEvent = getEntityDragDropDialogAnalytics();
  const [lastTarget, setLastTarget] = useState<EventTarget | null>(null);
  const [dragging, setDragging] = useState(false);
  const [isZeroState, setIsZeroState] = useState(false);

  const { onDragOver, onDragEnter, onDragLeave, addFiles, onDrop } = useDragDropFunctions(
    analyticsEvent,
    onDropFile,
    lastTarget,
    setLastTarget,
    setDragging
  );

  // Content
  const selectedUserSource = getCurrentUserSource(data?.userSources.sources, view);
  const sourceID = selectedUserSource?.id;
  useEffect(() => {
    const currentSourceID = selectedAssetsVar().sourceID;
    if (sourceID !== currentSourceID) {
      selectedAssetsVar({ ...selectedAssetsVar(), sourceID });
    }
  }, [sourceID]);

  const sourceContent = () => {
    if (!selectedUserSource) return null;
    return <FilesExplorer userSource={selectedUserSource} />;
  };

  const dialogContentInner = () => {
    if (view === FilesDialogView.ALL_FILES || view === FilesDialogView.UPLOADED_FILES)
      return (
        <FilesAssets
          addFiles={addFiles}
          disabledIDs={disabledIDs}
          isFilteredToUser={view === FilesDialogView.UPLOADED_FILES}
          isZeroState={isZeroState}
          setIsZeroState={setIsZeroState}
        />
      );
    if (view === FilesDialogView.FILES_SOURCES) return <FilesSources />;
    return sourceContent();
  };

  const dialogContent = () => (
    <div
      className={`${classes.dragDropContainer} ${
        dragging ? classes.dragging : classes.notDragging
      }`}
      data-cy="files-dialog-drag"
      onDragEnter={onDragEnter}
      onDragLeave={onDragLeave}
      onDragOver={onDragOver}
      onDrop={onDrop}
    >
      <div className={classes.rightScroll}>{dialogContentInner()}</div>
      {!isZeroState && <AddUploadDragHover dragging={dragging} />}
    </div>
  );

  const sidebarContent = () => {
    if (itemID)
      return (
        <FilesDialogSidebarItemWrapper
          itemID={itemID}
          onItemMutated={onItemMutated}
          userSources={data?.userSources.sources}
        />
      );
    if (milestoneID)
      return (
        <FilesDialogSidebarMilestoneWrapper
          milestoneID={milestoneID}
          userSources={data?.userSources.sources}
        />
      );
    return <></>;
  };

  return (
    <DialogContent className={classes.content}>
      {sidebarContent()}
      {dialogContent()}
    </DialogContent>
  );
};

export default withStyles(styles)(FilesDialogContent);
