import { FC } from 'react';

import {
  DIRECT_COSTS,
  MARKUP_APPLIES_TO_CELL,
  MARKUP_TYPE_CELL,
  MARKUP_VALUE_CELL,
  QUANTITY,
  UNIT,
  UNIT_PRICE,
  Uncategorized,
} from '../../../constants';
import { withStyles } from '../../../theme/komodo-mui-theme';

import ItemSidebarCollapseEstimateTooltipStyles from './ItemSidebarCollapseEstimateTooltipStyles';
import { FormattedLine } from './ItemSidebarUtils';

interface ItemSidebarCollapseEstimateTooltipProps {
  classes: Classes<typeof ItemSidebarCollapseEstimateTooltipStyles>;
  id: string;
  line: FormattedLine;
}

const ItemSidebarCollapseEstimateTooltip: FC<ItemSidebarCollapseEstimateTooltipProps> = ({
  classes,
  id,
  line,
}) => {
  const { categories, markupType, markupReferences, markupValue, quantity, unit, unitPrice } = line;

  const tooltipLine = (leftContent?: string, rightContent?: string) => (
    <div className={classes.tooltipLine}>
      {leftContent && <div className={classes.leftContent}>{leftContent}</div>}
      {rightContent && (
        <div
          className={`${classes.rightContent} ${
            rightContent === Uncategorized ? classes.uncategorizedText : ''
          }`}
        >
          {rightContent}
        </div>
      )}
    </div>
  );

  const directCostsContent = (
    <>
      {categories &&
        categories.map((category) => tooltipLine(category.categorization, category.category))}
      {tooltipLine(QUANTITY, quantity)}
      {tooltipLine(UNIT, unit)}
      {tooltipLine(UNIT_PRICE, unitPrice)}
    </>
  );

  const markupsContent = (
    <>
      {tooltipLine(MARKUP_TYPE_CELL, markupType)}
      {tooltipLine(MARKUP_APPLIES_TO_CELL, markupReferences)}
      {tooltipLine(MARKUP_VALUE_CELL, markupValue)}
    </>
  );

  return (
    <div key={line.name} className={classes.overflow}>
      {id === DIRECT_COSTS ? directCostsContent : markupsContent}
    </div>
  );
};

export default withStyles(ItemSidebarCollapseEstimateTooltipStyles)(
  ItemSidebarCollapseEstimateTooltip
);
