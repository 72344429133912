import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../../../theme/komodo-mui-theme';

const MilestoneDetailsQuantitiesCollapseHeaderStyles = (theme: KomodoTheme) =>
  createStyles({
    arrowIcon: {
      transform: 'rotate(90deg)',
      fontSize: 15,
    },
    autoFillContainer: {
      display: 'flex',
    },
    autoFillContent: {
      padding: 4,
      paddingTop: 12,
    },
    autoFillText: {
      ...theme.typography.header,
      color: theme.palette.pinkBadge,
      paddingTop: 4,
    },
    breakdownContainer: {
      display: 'flex',
      paddingTop: 12,
    },
    button: {
      borderRadius: 20,
      color: theme.palette.backgroundWhite,
    },
    buttonContainer: {
      display: 'flex',
      alignItems: 'flex-end',
    },
    buttonDisabled: {
      backgroundColor: theme.palette.disabledGrey,
      pointerEvents: 'none',
    },
    buttonEnabled: {
      backgroundColor: theme.palette.joinPrimary,
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      padding: '28px 40px 8px 40px',
      background: theme.palette.nestingGrey,
      borderBottom: `1px solid ${theme.palette.joinGrey400}`,
    },
    disabled: {
      color: theme.palette.disabledGrey,
    },
    formLabelRoot: {
      marginLeft: -4,
    },
    groupByContainer: {
      flexGrow: 1,
      paddingRight: 8,
      paddingBottom: 2,
    },
    iconContainer: {
      paddingLeft: 6,
      paddingBottom: 2,
      display: 'flex',
      alignItems: 'center',
    },
    smallButton: {
      height: 32,
      width: 32,
    },
  });

export default MilestoneDetailsQuantitiesCollapseHeaderStyles;
