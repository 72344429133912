import { useState } from 'react';

import { ProgramCreationInput } from '../../../generated/graphql';
import { useCompanyProjectTypesQuery } from '../../../hooks/useCompanyProjectTypesQuery';
import { useCompanyRoutesData } from '../../CompanyTab/CompanyTabUtils';
import { DialogFlow } from '../../scales';

import { useCreateProgramMutation } from './hooks/useCreateProgramMutation';
import { useProgramEligibleProjectsQuery } from './hooks/useProgramEligibleProjectsQuery';
import getProgramDetailsStep from './steps/ProgramDetailsStep/getProgramDetailsStep';
import getProjectSelectionStep from './steps/ProjectSelectionStep/getProjectSelectionStep';
import { defaultProgramInput, updateProgramInput } from './utils';

type Props = {
  onCompleted: (programID: UUID) => void;
  onClose: () => void;
};

export default function ProgramDialog(props: Props) {
  // State
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isEditingDetails, setIsEditingDetails] = useState(false);
  const [input, setInput] = useState<ProgramCreationInput>({
    ...defaultProgramInput,
  });

  // Hooks
  const createProgram = useCreateProgramMutation({
    onCompleted: (result) => props.onCompleted(result.createProgram),
  });

  const { companyID } = useCompanyRoutesData();
  const projectTypes =
    useCompanyProjectTypesQuery(false, companyID).data?.companyProjectTypes ?? [];

  const projects = useProgramEligibleProjectsQuery().data?.programEligibleProjects ?? [];
  const selectedProjects = projects.filter((p) => input.projectIDs.includes(p.id));

  const projectSelectionStep = getProjectSelectionStep({
    onChange: (newProjectIDs) =>
      updateProgramInput(
        projects,
        newProjectIDs,
        input,
        isEditingDetails,
        selectedProjects,
        setInput
      ),
    onSubmit: () => setIsEditingDetails(true),
    selectedProjects,
  });

  const canSubmit =
    !isSubmitting &&
    (input.projectIDs?.length ?? 0) >= 2 &&
    !!input.name?.trim() &&
    !!input.typeID &&
    !!input.imageProjectID &&
    !!input.locationDetails?.name;

  const programDetailsStep = getProgramDetailsStep({
    canSubmit,
    input,
    isSubmitting,
    selectedProjects,
    projectTypes,
    setInput,
  });

  const steps = [projectSelectionStep, programDetailsStep];

  return (
    <DialogFlow
      isFullHeight
      isOpen
      onClose={props.onClose}
      onComplete={() => {
        if (isSubmitting) return;
        setIsSubmitting(true);
        createProgram(input);
      }}
      size="lg"
      steps={steps}
      title="Create Program"
    />
  );
}
