import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';

export default (theme: KomodoTheme) =>
  createStyles({
    action: {
      margin: 0,
      padding: 0,
    },
    bulkItemUpdateToastDescription: {
      color: theme.palette.link,
    },
    bulkItemUpdateToastMessage: {
      alignItems: 'flex-end',
      display: 'inline-flex',
      marginRight: '50px',
      paddingRight: `${theme.spacing.unit * 2}px`,
      paddingTop: '6px',
    },
    bulkItemUpdateToast: {
      position: 'relative',
      backgroundColor: theme.palette.backgroundWhite,
      color: theme.palette.primaryGrey,
      flexShrink: 0,
      [theme.breakpoints.down('sm')]: {
        flexShrink: 1,
        justifyContent: 'space-between',
      },
      [theme.breakpoints.down('xs')]: {
        justifyContent: 'center',
      },
    },
    detachOptionToast: {
      backgroundColor: theme.palette.backgroundWhite,
      color: theme.palette.primaryGrey,
      flexShrink: 0,
      display: 'flex',
      justifyContent: 'space-between',
      [theme.breakpoints.down('sm')]: {
        flexShrink: 1,
        justifyContent: 'space-between',
      },
      [theme.breakpoints.down('xs')]: {
        justifyContent: 'center',
      },
    },
    errorIcon: {
      color: theme.palette.badgeColor,
      fontSize: 21,
      paddingRight: 6,
      paddingTop: 4,
    },
    exportExcelToast: {
      position: 'relative',
      backgroundColor: theme.palette.backgroundWhite,
      color: theme.palette.primaryGrey,
      flexShrink: 0,
      [theme.breakpoints.down('sm')]: {
        flexShrink: 1,
        justifyContent: 'space-between',
      },
      [theme.breakpoints.down('xs')]: {
        justifyContent: 'center',
      },
    },
    exportHelpLink: {
      cursor: 'pointer',
      fontFamily: theme.typography.fontFamily,
      textDecoration: 'underline',
    },
    importEstimateToastText: {
      fontFamily: theme.typography.fontFamily,
      color: theme.palette.chartBlack,
      fontSize: 16,
      fontWeight: 400,
      width: 'auto',
    },
    importEstimateToast: {
      backgroundColor: theme.palette.toastBackground,
      color: theme.palette.chartBlack,
    },
    importEstimateContainter: {
      display: 'flex',
      alignItems: 'center',
      paddingTop: 4,
    },
    importEstimateIcon: {
      '&:Hover': {
        backgroundColor: theme.palette.toastXHover,
      },
    },
    root: {
      display: 'flex',
      flexWrap: 'nowrap',
      maxWidth: 1000,
      justifyContent: 'space-between',
      paddingLeft: `${theme.spacing.unit * 4}px`,
      paddingRight: `${theme.spacing.unit * 2}px`,
    },
    newItemUpdateToast: {
      backgroundColor: theme.palette.backgroundWhite,
      color: theme.palette.primaryGrey,
      flexShrink: 0,
      [theme.breakpoints.down('sm')]: {
        flexShrink: 1,
        justifyContent: 'space-between',
      },
      [theme.breakpoints.down('xs')]: {
        justifyContent: 'center',
      },
    },
    serverErrorToast: {
      backgroundColor: theme.palette.backgroundWhite,
      color: theme.palette.primaryGrey,
      flexShrink: 0,
      [theme.breakpoints.down('sm')]: {
        flexShrink: 1,
        justifyContent: 'space-between',
      },
      [theme.breakpoints.down('xs')]: {
        justifyContent: 'center',
      },
    },
    newItemUpdateToastDescription: {
      color: theme.palette.link,
      paddingLeft: theme.spacing.unit,
      textDecoration: 'underline',
    },
    newItemUpdateToastText: {
      paddingLeft: theme.spacing.unit,
    },
    newItemUpdateToastMilestone: {
      color: theme.palette.primaryBlue,
    },
    newItemUpdateToastMessage: {
      alignItems: 'center',
      display: 'flex',
      paddingRight: `${theme.spacing.unit * 2}px`,
    },
    successToast: {
      ...theme.typography.webParagraph,
      fontWeight: 500,
      background: theme.palette.backgroundWhite,
      color: theme.palette.primaryGrey,
      borderTop: `1px solid ${theme.palette.accepted}`,
    },
    successToastMessage: {
      display: 'flex',
      alignItems: 'center',
      gap: '24px',
    },
    successToastIcon: {
      color: theme.palette.accepted,
    },
    nameInBold: {
      fontWeight: 500,
    },
  });
