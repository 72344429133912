import { useCallback } from 'react';

import {
  EventProperties,
  KeyDatesNudgeEventType,
} from '../../../analytics/analyticsEventProperties';
import useSendAnalytics from '../../../hooks/useSendAnalytics';

import { useKeyDates } from './useKeyDates';

export default function useSendKeyDatesAnalytics() {
  const sendAnalytics = useSendAnalytics();

  return useCallback(
    (
      eventType: KeyDatesNudgeEventType,
      keyDates: ReturnType<typeof useKeyDates>,
      eventProperties?: EventProperties
    ) => {
      sendAnalytics({
        type: `keyDates_${eventType}`,
        eventProperties: {
          isMilestoneSuggested: keyDates.statuses.milestone.isSuggested,
          isTurnoverSuggested: keyDates.statuses.turnover.isSuggested,
          isMobilizationSuggested: keyDates.statuses.mobilization.isSuggested,
          ...eventProperties,
        },
      });
    },
    [sendAnalytics]
  );
}
