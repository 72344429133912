export default function EstimateChartLegend() {
  return (
    <div className="ml-10 flex gap-4">
      <div className="flex flex-col gap-2">
        <Line className="stroke-entities-estimate" isDashed />
        <div className="text-type-muted type-body3">Estimate Total</div>
      </div>
      <div className="flex flex-col gap-2">
        <Line className="stroke-entities-estimate" />
        <div className="text-type-muted type-body3">Running Total</div>
      </div>
      <div className="flex flex-col gap-2">
        <Line className="stroke-entities-budget" />
        <div className="text-type-muted type-body3">Budget</div>
      </div>
    </div>
  );
}

const Line = (props: { className?: string; isDashed?: boolean }) => (
  <svg height="10" width="16">
    <line
      className={props.className}
      strokeDasharray={props.isDashed ? '4, 3' : 'none'}
      strokeWidth="3"
      x1="0"
      x2="20"
      y1="5"
      y2="5"
    />
  </svg>
);
