import { MutationHookOptions, useMutation } from '@apollo/client';

import { REFETCH_COMPANY_STATS } from '../../../api/refetchSets';
import {
  DeleteOrgMutation,
  DeleteOrgMutationVariables,
  SetBulkProjectOrgsMutation,
  SetBulkProjectOrgsMutationVariables,
  SetOrgLevelMutation,
  SetOrgLevelMutationVariables,
  SetOrgNameMutation,
  SetOrgNameMutationVariables,
  SetOrgNodeNameMutation,
  SetOrgNodeNameMutationVariables,
  SetOrgPublishedMutation,
  SetOrgPublishedMutationVariables,
  SetProjectOrgsMutation,
  SetProjectOrgsMutationVariables,
} from '../../../generated/graphql';

import {
  deleteOrgMutation,
  setBulkProjectOrgsMutation,
  setOrgLevelMutation,
  setOrgNameMutation,
  setOrgNodeNameMutation,
  setOrgPublishedMutation,
  setProjectOrgsMutation,
} from './queries';

export const useDeleteOrg = (
  options?: MutationHookOptions<DeleteOrgMutation, DeleteOrgMutationVariables>
) => useMutation<DeleteOrgMutation, DeleteOrgMutationVariables>(deleteOrgMutation, options);

export const useSetOrgLevel = (
  options?: MutationHookOptions<SetOrgLevelMutation, SetOrgLevelMutationVariables>
) => useMutation<SetOrgLevelMutation, SetOrgLevelMutationVariables>(setOrgLevelMutation, options);

export const useSetOrgName = (
  options?: MutationHookOptions<SetOrgNameMutation, SetOrgNameMutationVariables>
) => useMutation<SetOrgNameMutation, SetOrgNameMutationVariables>(setOrgNameMutation, options);

export const useSetOrgNodeName = (
  options?: MutationHookOptions<SetOrgNodeNameMutation, SetOrgNodeNameMutationVariables>
) =>
  useMutation<SetOrgNodeNameMutation, SetOrgNodeNameMutationVariables>(
    setOrgNodeNameMutation,
    options
  );

export const useSetOrgPublished = (
  options?: MutationHookOptions<SetOrgPublishedMutation, SetOrgPublishedMutationVariables>
) =>
  useMutation<SetOrgPublishedMutation, SetOrgPublishedMutationVariables>(
    setOrgPublishedMutation,
    options
  );

export const useSetProjectOrgs = (
  options?: MutationHookOptions<SetProjectOrgsMutation, SetProjectOrgsMutationVariables>
) =>
  useMutation<SetProjectOrgsMutation, SetProjectOrgsMutationVariables>(
    setProjectOrgsMutation,
    options
  );

export const useSetBulkProjectOrgs = () => {
  const [setBulkProjectOrgs] = useMutation<
    SetBulkProjectOrgsMutation,
    SetBulkProjectOrgsMutationVariables
  >(setBulkProjectOrgsMutation);
  return (projectIDs: UUID[], nodeIDs: UUID[]) =>
    setBulkProjectOrgs({
      variables: {
        input: {
          projectIDs,
          nodeIDs,
        },
      },
      refetchQueries: REFETCH_COMPANY_STATS,
    });
};
