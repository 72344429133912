import {
  commonProjectStatusFields,
  commonProjectTypeFields,
  gql,
} from '../../../api/graphqlFragments';

export const projectsCountsQuery = gql`
  query projectsCounts($projectsFilterBy: ProjectsFilterBy!, $projectsSearch: String!) {
    projectsCounts(projectsFilterBy: $projectsFilterBy, projectsSearch: $projectsSearch) {
      allProjectsCount
      myProjectsCount
    }
  }
`;

export const projectsFilterOptionsQuery = gql`
  query projectsFilterOptions(
    $includeCompanyProjects: Boolean!
    $includeUserProjects: Boolean
    $companyID: UUID
  ) {
    projectsFilterOptions(
      includeCompanyProjects: $includeCompanyProjects
      includeUserProjects: $includeUserProjects
      companyID: $companyID
    ) {
      locations
      statuses
      companies
      types
      projectLeads {
        id
        name
      }
      projectTypes {
        ...commonProjectTypeFields
      }
      organizationNodes {
        id
        name
      }
    }
  }
  ${commonProjectTypeFields}
`;

export const searchProjectsFilterOptions = gql`
  query searchProjectsFilterOptions($searchResultType: SearchResultType!) {
    searchProjectsFilterOptions(searchResultType: $searchResultType) {
      locations
      statuses
      companies
      types
      milestoneDesignPhases
      projectLeads {
        id
        name
      }
      projectTypes {
        ...commonProjectTypeFields
      }
      organizationNodes {
        id
        name
      }
    }
  }
  ${commonProjectTypeFields}
`;

export const getProjectStatusesQuery = gql`
  query getProjectStatuses {
    projectStatuses {
      ...commonProjectStatusFields
    }
  }
  ${commonProjectStatusFields}
`;
