import { User, useAuth0 } from '@auth0/auth0-react';

const createUserProfileFromAuthProfile = (authProfile: User) => {
  const profile = {
    email: authProfile.email,
    emailVerified: authProfile.email_verified,
    name: authProfile.name,
    nickname: authProfile.nickname,
    picture: authProfile.picture,
    sub: authProfile.sub,
    updatedAt: authProfile.updated_at,
    creationTime: authProfile['http://join.build/creationTime'],
    displayName: authProfile['http://join.build/displayName'],
  };
  return profile;
};

export const useUserProfile = (): UserProfile | null => {
  const skip = localStorage.getItem('skip_user_profile_fetch');
  // sets user profile to the fake profile that we use for integration testing
  if (skip) {
    const jsonUserProfile = localStorage.getItem('user_profile');
    return jsonUserProfile ? (JSON.parse(jsonUserProfile) as UserProfile) : null;
  }

  // We should only ever skip for Cypress tests, so execution order of hooks should stay
  // consistent across renders.
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { user } = useAuth0();

  return user ? createUserProfileFromAuthProfile(user) : null;
};

export const useAuth0Properties = () => {
  const userProfile = useUserProfile();
  if (!userProfile) return {};
  const { displayName, email, name, picture, sub } = userProfile;
  return { displayName, email, name, picture, sub };
};
