import { FC } from 'react';

import {
  LoadUserInAppNotificationSettingQuery,
  LoadUserNotificationSettingQuery,
} from '../../generated/graphql';
import useUserInAppNotificationSettingQuery from '../../hooks/useUserInAppNotificationSettingQuery';
import useUserNotificationSettingQuery from '../../hooks/useUserNotificationSettingQuery';
import usePermissions from '../../utilities/permissions/usePermissions';
import { getProjectIdFromUrl } from '../../utilities/url';
import useUpdateUserInAppNotificationSettingMutation from '../ProjectProperties/hooks/useUpdateUserInAppNotificationSettingMutation';
import useUpdateUserNotificationSettingMutation from '../ProjectProperties/hooks/useUpdateUserNotificationSettingMutation';

import NotificationSettings from './NotificationSettings';

const NotificationSettingsData: FC = () => {
  const projectId = getProjectIdFromUrl();
  // Data
  const { data } = useUserNotificationSettingQuery(projectId);
  const { userNotificationSetting } = data || {};
  const { data: ianData } = useUserInAppNotificationSettingQuery(projectId);
  const { userInAppNotificationSetting } = ianData || {};
  const updateUserNotificationSetting = useUpdateUserNotificationSettingMutation();
  const updateUserInAppNotificationSetting = useUpdateUserInAppNotificationSettingMutation();

  const { isViewOnly, isEditTemplate } = usePermissions();

  // Setter
  const setSetting = (
    notificationSetting: LoadUserNotificationSettingQuery['userNotificationSetting']
  ) => {
    updateUserNotificationSetting(projectId, notificationSetting);
  };
  const setIanSetting = (
    notificationSetting: LoadUserInAppNotificationSettingQuery['userInAppNotificationSetting']
  ) => {
    updateUserInAppNotificationSetting(projectId, notificationSetting);
  };

  const hasSettings =
    userNotificationSetting &&
    Object.keys(userNotificationSetting).length > 0 &&
    userInAppNotificationSetting &&
    Object.keys(userInAppNotificationSetting).length > 0;
  if (!hasSettings) return null;
  return (
    <NotificationSettings
      isEditTemplate={isEditTemplate}
      isViewOnly={isViewOnly}
      setIanSetting={setIanSetting}
      setSetting={setSetting}
      userInAppNotificationSetting={userInAppNotificationSetting}
      userNotificationSetting={userNotificationSetting}
    />
  );
};

export default NotificationSettingsData;
