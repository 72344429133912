import { ReactNode, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { InsightsSort } from '../../../generated/graphql';
import { getTodayUtcNoon } from '../../../utilities/dates';
import { formatNumber } from '../../../utilities/string';
import ChartsPieGraph from '../../Charts/ChartsD3/ChartsPieGraph/ChartsPieGraph';
import ContingencyBar from '../../ContingencyReport/ContingencyReport/BarGraph/ContingencyReportBarGraph';
import { Checkbox, SortManager, Table, Tooltip } from '../../scales';
import ContingencyLegend from '../Charts/Legends/ContingencyLegend';
import XAxis from '../Charts/XAxis';
import ZoomTimeline from '../Charts/ZoomTimeline';
import ProjectContingenciesAndAllowances from '../ProjectBabyCharts/ProjectContingenciesAndAllowances';
import ContingenciesAllowanceBarToolTip from '../ToolTips/ContingenciesAllowanceBarToolTip';
import ContingenciesPieToolTip from '../ToolTips/ContingenciesPieToolTip';
import ProjectTableTooltip from '../ToolTips/ProjectTableTooltip';
import { InsightsProject, InsightsTabId } from '../types';

import ProjectTableEntry from './ProjectTableEntry';
import StickyBottomRow from './StickyBottomRow';
import { generateProjectPath, getCandAPieCharData, getProjectsRange } from './utils';
import useSendAnalytics from '../../../hooks/useSendAnalytics';
import { InsightsEvent, insightsEvent } from '../../../analytics/analyticsEventProperties';

type Props = {
  counts: { current: number; total: number };
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  loading: boolean;
  onFetchMore: () => void;
  projects: InsightsProject[];
  sortManager: SortManager;
  sortState: InsightsSort;
  stickyHeader: ReactNode;
};

export default function ProjectsContingenciesAndAllowances(props: Props) {
  const sendAnalytics = useSendAnalytics();
  const navigate = useNavigate();
  const [startDate, endDate] = getProjectsRange(getTodayUtcNoon());
  const [start, setStart] = useState(startDate);
  const [end, setEnd] = useState(endDate);
  const setStartEnd = ([start, end]: string[]) => {
    setStart(new Date(start));
    setEnd(new Date(end));
  };
  const onZoom = (domain: string[]) => {
    setStartEnd(domain);
  };

  const stickyFooterEntry = StickyBottomRow({
    columnsCount: 5,
    component: (
      <ZoomTimeline
        onZoom={onZoom}
        projects={props.projects}
        zoom={[start.toISOString(), end.toISOString()]}
      />
    ),
  });

  const [hasContingency, setHasContingency] = useState(true);
  const [hasAllowance, setHasAllowances] = useState(true);
  const contingencyCheckbox = (
    <Checkbox
      isSelected={hasContingency}
      onChange={() => {
        // never have a situation where both checkboxes are unchecked
        if (hasContingency && !hasAllowance) {
          setHasAllowances(!hasAllowance);
        }
        setHasContingency(!hasContingency);
        sendAnalytics(
          insightsEvent(InsightsEvent.SUMMARY_CA_FILTER_CHECKBOX, {
            contingencies: !hasContingency,
          })
        );
      }}
    >
      <></>
    </Checkbox>
  );
  const allowanceCheckbox = (
    <Checkbox
      isSelected={hasAllowance}
      onChange={() => {
        // never have a situation where both checkboxes are unchecked
        if (hasAllowance && !hasContingency) {
          setHasContingency(!hasContingency);
        }
        setHasAllowances(!hasAllowance);
        sendAnalytics(
          insightsEvent(InsightsEvent.SUMMARY_CA_FILTER_CHECKBOX, {
            allowances: !hasAllowance,
          })
        );
      }}
    >
      <></>
    </Checkbox>
  );

  const chartsHeader = (
    <div className="w-full">
      <div className="flow-root h-8 w-full items-center">
        <div className="float-left type-table-header">Over timeline</div>
        <div className="float-right -mt-2">
          <ContingencyLegend
            allowanceCheckbox={allowanceCheckbox}
            contingencyCheckbox={contingencyCheckbox}
          />
        </div>
      </div>
      <div className="-mb-[9px]">
        <XAxis placement="top" totalRange={[start.toISOString(), end.toISOString()]} />
      </div>
    </div>
  );

  return (
    <>
      <Table
        columnWidths={[
          'minmax(250px, 3fr)',
          'minmax(125px, 0.5fr)',
          'minmax(125px, 0.5fr)',
          'minmax(125px, 0.5fr)',
          'minmax(600px, 6fr)',
        ]}
        entries={props.projects.map((project) => {
          const key = project.id;
          const mostRecentData =
            project.contingenciesTimeseries.length > 0
              ? project.contingenciesTimeseries[project.contingenciesTimeseries.length - 1]
              : null;

          const { caTotalPct, pieChartData, colors } = getCandAPieCharData(mostRecentData, project);

          return [
            {
              key,
              component: (
                <Tooltip
                  content={
                    <div>
                      <ProjectTableTooltip project={project} tabType={InsightsTabId.Items} />
                    </div>
                  }
                  onOpen={() =>
                    sendAnalytics(
                      insightsEvent(InsightsEvent.SUMMARY_PROJECT_TOOLTIP_VIEW, {
                        view: InsightsTabId.Contingencies,
                        projectID: project.id,
                        projectName: project.name,
                      })
                    )
                  }
                >
                  <div>
                    <ProjectTableEntry
                      hasAlert={project.activeAlerts.length > 0}
                      onClick={() =>
                        sendAnalytics(
                          insightsEvent(InsightsEvent.SUMMARY_PROJECT_CLICK, {
                            view: InsightsTabId.Contingencies,
                            source: 'link',
                          })
                        )
                      }
                      project={project}
                      sortState={props.sortState}
                      to={generateProjectPath(project.id)}
                    />
                  </div>
                </Tooltip>
              ),
            },
            {
              key,
              centered: true,
              component:
                Number(project.estimate ?? 0) !== 0 ? (
                  <Tooltip
                    content={
                      <div>
                        <ContingenciesPieToolTip
                          currentContingencyData={mostRecentData}
                          estimateTotal={project.estimate || 1}
                        />
                      </div>
                    }
                  >
                    <div>
                      <ChartsPieGraph
                        centerLabel={{
                          label: '',
                          sublabel: `${caTotalPct}`,
                        }}
                        chartSize={{
                          diameter: 60,
                          insideDiameter: 35,
                        }}
                        colors={colors}
                        data={pieChartData}
                      />
                    </div>
                  </Tooltip>
                ) : (
                  <div className="text-type-muted type-body3">No estimate</div>
                ),
            },
            {
              key,
              centered: true,
              component:
                Number(mostRecentData?.contingencyTotal ?? 0) !== 0 ? (
                  <Tooltip
                    content={
                      <div>
                        <ContingenciesAllowanceBarToolTip type="Contingency" />
                      </div>
                    }
                    isDisabled
                  >
                    <div>
                      <ContingencyBar
                        drawn={mostRecentData?.contingencyDraw || 0}
                        isInsights
                        overdrawLimit={0.15}
                        pending={mostRecentData?.contingencyPending || 0}
                        starting={mostRecentData?.contingencyTotal || 0}
                        used={mostRecentData?.contingencyUsed || 0}
                      />
                    </div>
                  </Tooltip>
                ) : (
                  <div className="text-type-muted type-body3">No contingencies</div>
                ),
            },
            {
              key,
              centered: true,
              component:
                Number(mostRecentData?.allowanceTotal ?? 0) !== 0 ? (
                  <Tooltip
                    content={
                      <div>
                        <ContingenciesAllowanceBarToolTip type="Allowance" />
                      </div>
                    }
                    isDisabled
                  >
                    <div>
                      <ContingencyBar
                        drawn={mostRecentData?.allowanceDraw || 0}
                        isInsights
                        isOrangeTheme
                        overdrawLimit={0.15}
                        pending={mostRecentData?.allowancePending || 0}
                        starting={mostRecentData?.allowanceTotal || 0}
                        used={mostRecentData?.allowanceUsed || 0}
                      />
                    </div>
                  </Tooltip>
                ) : (
                  <div className="text-type-muted type-body3">No allowances</div>
                ),
            },
            {
              key,
              component: (
                <ProjectContingenciesAndAllowances
                  end={end}
                  hasAllowance={hasAllowance}
                  hasContingency={hasContingency}
                  onClick={() => {
                    sendAnalytics(
                      insightsEvent(InsightsEvent.SUMMARY_PROJECT_CLICK, {
                        view: InsightsTabId.Contingencies,
                        source: 'chart',
                      })
                    );
                    navigate(generateProjectPath(project.id, InsightsTabId.Contingencies));
                  }}
                  project={project}
                  start={start}
                />
              ),
            },
          ];
        })}
        hasParentScroll
        headerContent={[
          {
            component: (
              <div className="h-12 type-table-header">{`Projects (${formatNumber(props.counts.current)} of ${formatNumber(
                props.counts.total
              )})`}</div>
            ),
            key: 'projects',
          },
          {
            component: <div className="h-12 type-table-header">% of Est</div>,
            key: 'est',
          },
          {
            component: <div className="h-12 type-table-header">Contingencies</div>,
            key: 'cont',
          },
          {
            component: <div className="h-12 type-table-header">Allowances</div>,
            key: 'all',
          },
          { component: chartsHeader, key: 'ca' },
        ]}
        isLoading={props.loading}
        onNeedMoreData={props.onFetchMore}
        sortManager={props.sortManager}
        stickyFooterEntry={stickyFooterEntry}
        stickyHeader={props.stickyHeader}
      />
    </>
  );
}
