import { useEffect, useState } from 'react';

function usePrintWindow(...args: boolean[]) {
  const [isPrinting, setIsPrinting] = useState(false);
  return useEffect(() => {
    if (!isPrinting && args.every((arg) => !!arg)) {
      window.print();
      // NOTE: without this timeout the app will freeze from wherever the user originated from
      // ex: if the user is on the dashboard and prints it, then when navigating back to the
      // dashboard after printing the dashboard will be frozen.
      setTimeout(() => {
        setIsPrinting(true);
      }, 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO CT-566: Fix this pls :)
  }, args);
}

export default usePrintWindow;
