import { MASTERFORMAT_CELL, UNIFORMAT_CELL, UNIFORMAT_DISPLAY } from '../../constants';
import { CategoryCombinationCountsQuery } from '../../generated/graphql';

export const BUILTIN_CATEGORY_LEVELS = [
  {
    name: MASTERFORMAT_CELL,
    id: 'c22ed8a1-8fb3-43fc-b9ad-1ecf514c9852',
    levelNames: ['MF Division', 'MF Subdivision', 'MF Section', 'MF Subsection'],
    hints: [
      'MasterFormat Division, e.g. 03 Concrete',
      'MasterFormat Subdivision, e.g. 03 40 Precast Concrete',
      'MasterFormat Section, e.g. 03 41 00 Precast Structural Concrete',
      'MasterFormat Subsection, e.g. 03 41 16 Precast Concrete Slabs',
    ],
  },
  {
    name: UNIFORMAT_DISPLAY,
    id: 'f7716c5d-5970-40b9-b215-03cc1b9773bf',
    levelNames: ['UF Level 1', 'UF Level 2', 'UF Level 3', 'UF Level 4'],
    hints: [
      'UniFormat Level 1, e.g. B Shell',
      'UniFormat Level 2, e.g. B10 Superstructure',
      'UniFormat Level 3, e.g. B1010 Floors',
      'UniFormat Level 4, e.g. B1010.10 Floors',
    ],
  },
];

export const makeOptionsFromTopLevelCategorizations = (
  categorizations: Categorization[],
  categoryCombinationCounts: CategoryCombinationCountsQuery['categoryCombinationCounts']
) =>
  categorizations.map((categorization: Categorization) => {
    let optionText = categorization.name;

    const match = BUILTIN_CATEGORY_LEVELS.find(
      (cl) => cl && categorization && cl.id === categorization.id
    );
    if (match && match.name) {
      optionText = match.name;
    }

    const count =
      categoryCombinationCounts.find((ccc) => ccc.categorizationID === categorization.id)?.count ||
      0;
    optionText = `${optionText} (${count})`;

    return { name: optionText, id: categorization.id };
  });

export const getDefaultReportCategorizations = (categorizations: Categorization[]) => {
  if (categorizations.length === 0) return [];
  const mf = MASTERFORMAT_CELL.toLowerCase();
  const uf = UNIFORMAT_CELL.toLowerCase();
  const builtins = categorizations.filter((c) => {
    const name = c.name.toLowerCase();
    return name.startsWith(mf) || name.startsWith(uf);
  });
  if (builtins.length !== 0) return builtins;
  return [categorizations[0]];
};
