import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';

export default (theme: KomodoTheme) =>
  createStyles({
    container: { width: '16px', outline: 'none', margin: '6px' },
    iconSortArrow: {
      display: 'flex',
      alignItems: 'center',
      fill: theme.palette.joinGrey400,
      position: 'relative',
      width: 'inherit',
    },
    iconSortArrowActive: {
      display: 'flex',
      alignItems: 'center',
      fill: theme.palette.primaryBlue,
      width: 'inherit',
    },
    iconSortArrowFlipped: {
      display: 'flex',
      alignItems: 'center',
      transform: 'rotate(180deg)',
      width: 'inherit',
    },
    marginLeft: {
      marginLeft: '6px',
      marginRight: '0px',
    },
    marginRight: {
      marginLeft: '0px',
      marginRight: '6px',
    },
  });
