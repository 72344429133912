import { FC } from 'react';

import theme from '../../theme/komodo-mui-theme';

import { CELL_HORIZONTAL_OFFSET } from './style/styleConstants';

interface PlaceholderProps {
  isError: boolean;
  width: number;
  height: number;
}

const PRELOADR_HEIGHT = 16;
const PADDING_LEFT = 10;

const JoinPreloaderCell: FC<PlaceholderProps> = ({
  isError,
  width: totalWidth,
  height: totalHeight,
}) => {
  const width = totalWidth - CELL_HORIZONTAL_OFFSET;
  const height = totalHeight;

  const paddingTop = (height - PRELOADR_HEIGHT) / 2;

  const preloaderRect = (
    <svg height={height} width={width}>
      <rect
        height={PRELOADR_HEIGHT}
        rx="5"
        ry="5"
        style={{ fill: isError ? theme.palette.pink : theme.palette.backgroundGrey }}
        width={width - 2 * PADDING_LEFT}
        x={PADDING_LEFT}
        y={paddingTop}
      />
    </svg>
  );

  return preloaderRect;
};
export default JoinPreloaderCell;
