import {
  commonCollaboratorFields,
  commonCompanyUserFields,
  commonUserFields,
  gql,
} from '../../api/graphqlFragments';

export const currentCompanyUserQuery = gql`
  query companyUser {
    companyUser {
      ...commonCompanyUserFields
    }
  }
  ${commonCompanyUserFields}
`;

export const currentCollaboratorQuery = gql`
  query currentCollaborator($projectID: UUID!) {
    currentCollaborator(projectID: $projectID) {
      ...commonCollaboratorFields
    }
  }
  ${commonCollaboratorFields}
`;

export const currentUserQuery = gql`
  query currentUser {
    currentUser {
      ...commonUserFields
      status
    }
  }
  ${commonUserFields}
`;

export const featureFlagQuery = gql`
  query featureFlag($projectID: UUID!, $userEmail: String!) {
    loadFeatureFlags(projectID: $projectID, userEmail: $userEmail)
  }
`;
