import Help from '@material-ui/icons/Help';

import { BabyButton, Tooltip } from '../../scales';

type Props = {
  tooltipContent: React.ReactNode;
  onOpen?: () => void;
};

export default function HelpTooltip(props: Props) {
  return (
    <Tooltip
      content={<div className="type-label">{props.tooltipContent}</div>}
      isDisabled={!props.tooltipContent}
      onOpen={props.onOpen}
      placement="right"
    >
      <BabyButton aria-label="Info" icon={<Help />} isDisabled />
    </Tooltip>
  );
}
