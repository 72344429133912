import { ComponentProps } from 'react';

import { ProjectDeliveryType } from '../../../generated/graphql';
import { Select, SelectEntry } from '../../scales';

export type Props = {
  entries: ProjectDeliveryType[];
  isDisabled?: ComponentProps<typeof Select>['isDisabled'];
  isSearchable?: ComponentProps<typeof Select>['isSearchable'];
  label: ComponentProps<typeof Select>['label'];
  onChange: (value: ProjectDeliveryType) => void;
  value: UUID | undefined;
};

export default function ProjectDeliveryMethodSelect(props: Props) {
  const entries: SelectEntry[] = props.entries.map(({ id, name, abbreviation }) => ({
    id,
    label: abbreviation ? `${name} (${abbreviation})` : name,
  }));

  return (
    <Select
      aria-label="select a project delivery method"
      data-cy="project-delivery-select"
      entries={entries}
      isDisabled={props.isDisabled}
      isSearchable={props.isSearchable}
      label={props.label}
      onChange={(deliveryTypeID) => {
        const deliveryType = props.entries.find(({ id }) => id === deliveryTypeID);
        if (deliveryType) props.onChange(deliveryType);
      }}
      placeholder="Set project delivery method"
      value={props.value}
    />
  );
}
