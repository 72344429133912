import { FetchResult, useMutation } from '@apollo/client';

import { updateCollaboratorRole } from '../../../analytics/analyticsEventProperties';
import {
  UpdateCollaboratorRoleMutation,
  UpdateCollaboratorRoleMutationVariables,
} from '../../../generated/graphql';
import useSendAnalytics from '../../../hooks/useSendAnalytics';

import { updateCollaboratorRoleMutation } from './queries';

function useUpdateCollaboratorRole() {
  const [updateCollaboratorRoleFunc] = useMutation<
    UpdateCollaboratorRoleMutation,
    UpdateCollaboratorRoleMutationVariables
  >(updateCollaboratorRoleMutation);

  const sendAnalytics = useSendAnalytics();

  const submitFunc = (
    projectID: UUID,
    collaboratorID: UUID,
    roleID: UUID,
    onSuccess?: (c: UpdateCollaboratorRoleMutation['setCollaboratorRole']) => void,
    onFailure?: () => void
  ) =>
    updateCollaboratorRoleFunc({
      variables: { projectID, collaboratorID, roleID },
    })
      .then(({ data }: FetchResult<UpdateCollaboratorRoleMutation>) => {
        const setCollaboratorRole = data?.setCollaboratorRole;
        if (onSuccess && setCollaboratorRole) onSuccess(setCollaboratorRole);
        sendAnalytics(updateCollaboratorRole(roleID));
      })
      .catch(() => {
        if (onFailure) onFailure();
      });

  return submitFunc;
}

export default useUpdateCollaboratorRole;
