import * as d3 from 'd3';

import { formatCost } from '../../../../../utilities/currency';

export type BarRunningTotal = {
  id: string;
  name: string;
  color?: string;
  value: USCents;
};

export const generateD3Scales = (
  width: number,
  height: number,
  series: BarRunningTotal[],
  budget?: number,
  estimate?: number
) => {
  // set the ranges
  const x = d3
    .scaleBand()
    .range([0, width])
    .padding(0.4)
    .domain(series.map((d) => d.id));

  // Get values for y
  const yValues = series.map((d) => d.value);
  if (estimate) {
    yValues.push(estimate);
  }
  if (budget) {
    yValues.push(budget);
  }

  const PADDING_TOP = 8; // Allows space for "max" y label
  const yMax = d3.max(yValues) ?? 0;
  const yMin = d3.min(yValues) ?? 0;
  const extent = yMax - yMin;
  const paddedDomain = 0.1 * extent; // to make the bar look good near the bottom of the domain
  const yDomain = [yMin - paddedDomain, yMax];
  const y = d3.scaleLinear().range([height, PADDING_TOP]).domain(yDomain).nice();

  return { x, y };
};

export const getLabelString = (name: string, value: USCents) => {
  const costString = formatCost(value, {
    short: true,
    showCurrencySymbol: false,
  });
  return `${name}: ${costString}`;
};
