import { ProjectCompPortals } from './constants/elementIdentifiers';

export default function ProjectCompsStickyHeader() {
  return (
    <div className="sticky top-0 z-10 w-full bg-background-primary" data-cy="sticky-header">
      <div className="flex w-max min-w-full shadow-downwards" id={ProjectCompPortals.COMP_HEADER}>
        <div className="sticky left-0 z-10 flex bg-background-primary">
          <div className="z-20 flex border-r" id={ProjectCompPortals.AVG_COMP_HEADER}>
            <div className="w-75 border-r" />
          </div>
        </div>
      </div>
    </div>
  );
}
