import { ListSubcategoriesQuery, ListSubcategoriesQueryVariables } from '../generated/graphql';

import { listSubcategoriesQuery } from './queries';
import { useQuery } from './useMountAwareQuery';

const useListSubcategoriesQuery = (
  categorizationID: UUID,
  categoryID: UUID,
  limit: number,
  skip = false
) =>
  useQuery<ListSubcategoriesQuery, ListSubcategoriesQueryVariables>(listSubcategoriesQuery, {
    variables: {
      search: {
        categorizationID,
        categoryID,
        limit,
      },
    },
    skip,
  });

export default useListSubcategoriesQuery;
