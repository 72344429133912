import { FC, useEffect, useState } from 'react';

import { IconButton } from '@material-ui/core';
import { Backup } from '@material-ui/icons';

import {
  ItemsListImportEvent,
  itemsListImportEvent,
} from '../../analytics/analyticsEventProperties';
import useSendAnalytics from '../../hooks/useSendAnalytics';

import ImportItemsDialog from './ImportItems/ImportItemsDialog';

type ItemsListImportButtonProps = {
  activeMilestoneID: UUID;
  className: string;
  file?: File;
  fileSource?: FileSource;
  isViewOnly: boolean;
  itemsCount: number;
  milestones: Milestone[];
  onClose: () => void;
  projectName: string;
};

const ItemsListImportButton: FC<ItemsListImportButtonProps> = ({
  activeMilestoneID,
  className,
  file,
  fileSource,
  isViewOnly,
  itemsCount,
  milestones,
  onClose,
  projectName,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const sendAnalytics = useSendAnalytics();

  useEffect(() => {
    if (file) {
      setIsOpen(true);
    }
  }, [file]);

  if (isViewOnly) return null;

  const icon = (
    <IconButton
      className={className}
      onClick={() => {
        setIsOpen(true);
        sendAnalytics(itemsListImportEvent(ItemsListImportEvent.IMPORT_CTA));
      }}
    >
      <Backup />
    </IconButton>
  );

  const dialog = (
    <ImportItemsDialog
      activeMilestoneID={activeMilestoneID}
      file={file}
      fileSource={fileSource}
      isOpen={isOpen}
      itemsCount={itemsCount}
      milestones={milestones}
      onClose={() => {
        setIsOpen(false);
        onClose();
      }}
      projectName={projectName}
    />
  );

  return (
    <div className="pr-2" data-cy="button-ImportItems">
      {icon}
      {isOpen && dialog}
    </div>
  );
};

export default ItemsListImportButton;
