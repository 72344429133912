import { MutationHookOptions, useMutation } from '@apollo/client';

import {
  DeleteProgramMutation,
  DeleteProgramMutationVariables,
} from '../../../../generated/graphql';
import { deleteProgramMutation } from '../../mutations';

export const useDeleteProgramMutation = (
  programID: UUID,
  options?: MutationHookOptions<DeleteProgramMutation, DeleteProgramMutationVariables>
) =>
  useMutation<DeleteProgramMutation, DeleteProgramMutationVariables>(deleteProgramMutation, {
    variables: { programID },
    ...options,
  });
