import { FC } from 'react';

import { Flag, FlagOutlined, OpenInNew } from '@material-ui/icons';

import {
  projectCompsAnalyticsEvent,
  projectCompsEventTypes,
} from '../../../analytics/analyticsEventProperties';
import { JoinProjectRoutes } from '../../../api/gqlEnums';
import useSendAnalytics from '../../../hooks/useSendAnalytics';
import { withStyles } from '../../../theme/komodo-mui-theme';
import { generateSharedPath } from '../../../utilities/routes/links';
import NormalTooltip from '../../NormalTooltip/NormalTooltip';
import MilestoneSelect from '../../Select/MilestoneSelect';
import ProjectCompInformationField from '../ProjectCompInformationField';
import { extractProjectCompValues } from '../ProjectCompsSetUtils';
import styles from '../ProjectCompsStyles';

type ProjectCompsInputsMilestoneProps = {
  classes: Classes<typeof styles>;
  projectComp: ProjectComp;
  setMilestone: (milestone: string) => void;
  isHighlighted?: boolean;
  onMouseOver?: (isOver: boolean) => void;
};

const ProjectCompsInputsMilestone: FC<ProjectCompsInputsMilestoneProps> = ({
  classes,
  projectComp,
  setMilestone,
  isHighlighted,
  onMouseOver = () => {},
}) => {
  const { activeMilestoneID, milestone, milestones, projectID, hasAccess } =
    extractProjectCompValues(projectComp);

  const sendAnalytics = useSendAnalytics();

  const openMilestoneInNewTab = () => {
    window.open(
      generateSharedPath(JoinProjectRoutes.MILESTONE_DETAILS, {
        projectId: projectID,
        milestoneId: milestone.id,
        search: '?view=ESTIMATE',
      }),
      '_blank'
    );
    sendAnalytics(
      projectCompsAnalyticsEvent(projectCompsEventTypes.PROJECT_COMPS_MILESTONE_LINK_CTA, {
        linkMilestoneId: milestone.id,
        linkMilestoneName: milestone.name,
        linkProjectId: projectID,
      })
    );
  };
  const milestoneLink = (
    <NormalTooltip title={hasAccess ? 'Open in new tab' : 'You do not have permission to view'}>
      <OpenInNew
        className={`${classes.milestoneLink} ${!hasAccess ? classes.milestoneLinkDisabled : ''}`}
        onClick={hasAccess ? openMilestoneInNewTab : undefined}
      />
    </NormalTooltip>
  );
  let milestoneSelector;
  if (milestones.length > 1) {
    milestoneSelector = (
      <MilestoneSelect
        className={classes.milestoneSelect}
        milestones={milestones.map((m) => ({
          ...m,
          leftIcon: (
            <div className="text-entities-milestone">
              {m.id === activeMilestoneID ? <Flag /> : <FlagOutlined />}
            </div>
          ),
        }))}
        onChangeMilestone={(newMilestoneID) => {
          if (!newMilestoneID) return;

          setMilestone(newMilestoneID);
        }}
        selectedMilestone={milestone.id}
      />
    );
  } else {
    milestoneSelector = (
      <ProjectCompInformationField
        className={`${classes.field} ${classes.fullWidth}`}
        classNameTypography=""
        value={milestone.name}
      />
    );
  }
  return (
    <div
      className={`${classes.milestoneFieldContainer} ${isHighlighted ? classes.greenBG : ''}`}
      onMouseEnter={() => onMouseOver(true)}
      onMouseLeave={() => onMouseOver(false)}
    >
      {milestoneSelector}
      {milestoneLink}
    </div>
  );
};

export default withStyles(styles)(ProjectCompsInputsMilestone);
