import { ReactNode } from 'react';

import { formatNumber } from '../../../../utilities/string';

import Cell from './Cell';

type Props = {
  'data-cy'?: string;
  isBold?: boolean;
  isCompact?: boolean;
  number?: string | number | null;
};

export default function NumberCell(props: Props) {
  let number = props.number;
  if (typeof number === 'string') number = parseInt(number, 10);

  let content: ReactNode = typeof number === 'number' ? formatNumber(number) : '';
  if (props.isBold) content = <strong>{content}</strong>;

  return (
    <Cell data-cy={props['data-cy']} isCompact={props.isCompact}>
      <div className={props.isCompact ? 'type-table-number' : 'type-number'}>{content}</div>
    </Cell>
  );
}
