import { max, scaleBand, scaleLinear, stack, stackOrderReverse } from 'd3';

import BandCostAxes from '../../../Charts/ChartsD3/Axes/BandCostAxes';
import SVGWithDimensions from '../../../Charts/ChartsD3/SVGWithDimensions';
import { useChartDimensions } from '../../../Charts/ChartsD3/useChartDimensions';
import AverageColRect from '../AverageColRect';
import AverageLine from '../AverageLine';
import ProjectLabelChip from '../ProjectLabelChip';

import Bar from './Bar';
import {
  CHART_TOP_PADDING_PERCENT,
  COLUMN_X_PADDING_PERCENT,
  CategorizedValues,
  TICK_COUNT,
  TotalBar,
  getStackedBarColor,
  sumOfCategorizedValues,
} from './ProjectCompsChartUtils';
import Tooltip from './TooltipChartStackedBar';

// TODO: Next time we need stacked charts,
// make a re-usable component for stacked charts
// that is agnostic to Comps data
// and accepted Bars as a prop
type Props = {
  categorizedData: CategorizedValues[];
  categoryNameMap: Map<string, string>;
  filterKeys: string[];
  hasAverageComp: boolean;
  hover: string | null;
  isCost: boolean;
  onClickBarSetFilterKeys?: (filters: string[]) => void;
  onMouseSetHover?: (hover: string | null) => void;
  selectedUnits?: Unit[];
  totalData: TotalBar[];
  yLabel: string;
};

export default function ProjectCompsChart(props: Props) {
  // Sizing
  const { ref, dimensions } = useChartDimensions({});

  // D3 calculations
  const categoryIDs = props.categoryNameMap.keys();
  const maxY =
    (max(props.categorizedData, (cv) => sumOfCategorizedValues(cv)) ?? 0) *
    (1 + CHART_TOP_PADDING_PERCENT); // Add padding to top of chart
  const x = scaleBand<string>()
    .domain(props.totalData.map((c) => c.id))
    .range([0, dimensions.boundedWidth]);
  const y = scaleLinear<number, number>()
    .domain([0, maxY ?? 0])
    .range([dimensions.boundedHeight, 0]);

  const stackedData = stack<CategorizedValues>().order(stackOrderReverse).keys(categoryIDs)(
    props.categorizedData
  );

  // Geometry calculations
  const bandWidth = x.bandwidth();
  const columnWidth = bandWidth * (1 - COLUMN_X_PADDING_PERCENT * 2);

  // Hover visibility
  const isHoverCategoryVisible =
    props.hover && (props.filterKeys.length === 0 || props.filterKeys.includes(props.hover));

  return (
    <SVGWithDimensions ref={ref} dimensions={dimensions}>
      {props.hasAverageComp && (
        <AverageColRect
          height={dimensions.boundedHeight}
          width={(dimensions.width - dimensions.marginLeft) / props.totalData.length}
          x={0}
          y={0}
        />
      )}
      <BandCostAxes
        height={dimensions.height}
        isCost={props.isCost}
        marginLeft={dimensions.marginLeft}
        tickCount={TICK_COUNT}
        x={x}
        xComponents={props.totalData.map((c) => (
          <ProjectLabelChip
            key={c.id}
            color={c.color ?? undefined}
            text={c.name}
            width={bandWidth}
          />
        ))}
        y={y}
        yLabel={props.yLabel}
      />
      {stackedData.map((categoryStack, i) =>
        categoryStack.map((d, p) => {
          const color = getStackedBarColor(i);
          const categoryNumber = categoryStack.key;
          const projectData = props.totalData[p];
          const projectID = projectData.id;
          const height = y(d[0]) - y(d[1]);
          const isHoverCategory = props.hover === categoryNumber;
          const opacity = isHoverCategoryVisible && !isHoverCategory ? 0.15 : 1;
          const categoryLine = projectData.categoryLines?.find(
            (l) => l.category.number === categoryNumber
          );
          const onClick = () => {
            const newFilterKeys = props.filterKeys.includes(categoryNumber) ? [] : [categoryNumber];
            if (props.onClickBarSetFilterKeys) props.onClickBarSetFilterKeys(newFilterKeys);
            // TODO: Scroll key to filter?
          };
          const tooltip = categoryLine && (
            <Tooltip
              columnValues={categoryLine.columnValues}
              name={props.categoryNameMap.get(categoryNumber) ?? ''}
              selectedUnits={props.selectedUnits}
            />
          );
          // y range must update to calc valid heights
          if (height >= 0) {
            return (
              <Bar
                key={`${categoryNumber}-${projectID}`}
                fill={color}
                height={height}
                onClick={onClick}
                onMouseLeave={() => {
                  if (props.onMouseSetHover) props.onMouseSetHover(null);
                }}
                onMouseOver={() => {
                  if (props.onMouseSetHover) props.onMouseSetHover(categoryNumber);
                }}
                opacity={opacity}
                tooltip={tooltip}
                width={columnWidth}
                x={(x(projectID) ?? 0) + x.bandwidth() * COLUMN_X_PADDING_PERCENT}
                y={y(d[1])}
              />
            );
          }
          return null;
        })
      )}
      {props.hasAverageComp && (
        <AverageLine
          width={dimensions.boundedWidth}
          y={y(sumOfCategorizedValues(props.categorizedData[0]))}
        />
      )}
    </SVGWithDimensions>
  );
}
