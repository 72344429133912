import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../../theme/komodo-mui-theme';

export default (theme: KomodoTheme) =>
  createStyles({
    container: {
      display: 'flex',
      position: 'relative',
      alignItems: 'center',
      backgroundColor: theme.palette.backgroundWhite,
      boxSizing: 'border-box',
      height: 34,
      paddingLeft: 8,
      paddingRight: 8,
      border: theme.border.line,
      borderBottom: 'unset',
      '&:first-of-type': {
        paddingBottom: 1, // offset the focus-within border we'll add
        borderTopLeftRadius: theme.border.radius,
        borderTopRightRadius: theme.border.radius,
      },
      '&:last-of-type': {
        borderBottom: theme.border.line,
        borderBottomLeftRadius: theme.border.radius,
        borderBottomRightRadius: theme.border.radius,
      },

      '&:focus-within': {
        border: theme.border.focus,
        outline: theme.border.focus,
        borderRadius: 0,
        '&:first-of-type': {
          borderTopLeftRadius: theme.border.radius,
          borderTopRightRadius: theme.border.radius,
          paddingBottom: 0,
        },
        '&:last-of-type': {
          borderBottomLeftRadius: theme.border.radius,
          borderBottomRightRadius: theme.border.radius,
        },
      },
    },
    input: {
      backgroundColor: 'transparent',
      color: '#000',
      border: 0,
      padding: '0 0 0 6px',
      textAlign: 'right',
      flex: '1 1 30%',
      minWidth: 0, // Allow <input> to flex-shrink
      '&:focus': {
        outline: 'none',
      },
      width: 100,
      ...theme.typography.number2,
    },
  });
