import { Permission, PermissionLevel } from '../../../../generated/graphql';

type Props = {
  displayCostPermission: Permission;
};

const PermissionsHideCostsView = (props: Props) => {
  return (
    <div className="grid grid-cols-7 border-t leading-10">
      <div
        className="col-span-3 content-center px-2 py-2 type-body1"
        data-cy="permission-hide-costs"
      >
        Costs visibility
      </div>
      <div className="col-span-4 content-center px-2 py-2 type-body1">
        {props.displayCostPermission.level === PermissionLevel.VIEW_ONLY
          ? 'Costs are visible'
          : 'Costs are hidden'}
      </div>
    </div>
  );
};

export default PermissionsHideCostsView;
