import { FC, useCallback, useEffect } from 'react';

import {
  itemSidebarForecastingOpenVar,
  projectCompsSetInputVar,
  projectCompsSettingsInputDefault,
} from '../../../api/apollo/reactiveVars';
import { FORECASTING_TAB_NAME } from '../../../constants';
import useLocalStorage from '../../../hooks/useLocalStorage';

import ForecastingProjectsListData from './projects/ForecastingProjectsListData';

const ForecastingExplore: FC = () => {
  useSetDocumentTitle();
  const [isFilterMenuOpen, setFilterMenuOpen] = useLocalStorage(
    'FORECASTING_EXPLORE_FILTERS_OPEN',
    true
  );

  const onToggleFilterMenu = useCallback(() => {
    itemSidebarForecastingOpenVar(null);
    setFilterMenuOpen((prevState) => !prevState);
  }, [setFilterMenuOpen]);

  useEffect(() => {
    // avoid issues with persisted data on navigation by resetting reactive var
    projectCompsSetInputVar(projectCompsSettingsInputDefault);
  }, []);

  return (
    <ForecastingProjectsListData
      isFilterMenuOpen={isFilterMenuOpen}
      onToggleFilterMenu={onToggleFilterMenu}
    />
  );
};

const useSetDocumentTitle = () => {
  useEffect(() => {
    document.title = `${FORECASTING_TAB_NAME} - Explore`;
  }, []);
};

export default ForecastingExplore;
