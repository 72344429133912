import { RouterProvider, createBrowserRouter } from 'react-router-dom';

import { ApolloProvider } from '@apollo/client';
import * as Sentry from '@sentry/react';

import apolloClient from '../api/apollo';
import { AUTH_REDIRECT } from '../constants';
import routes from '../routes';
import AppThemeProvider from '../theme/AppThemeProvider';

import AppContexts from './contexts/AppContexts';
import { useJoinAuth } from './JoinAuthProvider';
import VerificationLayers from './login/VerificationLayers';

const router = createBrowserRouter(routes);

function App() {
  const auth = useJoinAuth();
  usePostLoginRedirect();

  return (
    <AppThemeProvider>
      <ApolloProvider client={apolloClient(auth)}>
        <VerificationLayers>
          <AppContexts>
            <RouterProvider router={router} />
          </AppContexts>
        </VerificationLayers>
      </ApolloProvider>
    </AppThemeProvider>
  );
}

function usePostLoginRedirect() {
  // Navigate to the last page the user was on before logging in.
  // We do this because url params are lost after authenticating
  const redirectTo = sessionStorage.getItem(AUTH_REDIRECT);
  if (redirectTo) {
    sessionStorage.removeItem(AUTH_REDIRECT);
    router.navigate(redirectTo, { replace: true });
  }
}

export default Sentry.withProfiler(App);
