import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';

export default (theme: KomodoTheme) =>
  createStyles({
    card: {
      backgroundColor: 'white',
      border: theme.border.line,
      marginBottom: 24,
      marginTop: 8,
      overflow: 'visible',
    },
    estimate: {
      marginLeft: 18,
      marginRight: 18,
    },
  });
