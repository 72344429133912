import * as d3 from 'd3';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import theme from '../../../../theme/komodo-mui-theme';
import { ProjectMap } from '../../../ExecutiveDashboard/ExecutiveDashboardUtils';
import ChartsD3GraphWrapper, { ChartSize, LabelOptions } from '../ChartsD3GraphWrapper';

import {
  ChartStackMap,
  ChartTooltip,
  ProjectCostRange,
  VerticalBarGraphData,
  formatData,
  generateAxis,
  generateChartAxis,
  generateChartBars,
} from './ChartsVerticalBarGraphUtils';

type ChartsVerticalBarGraphProps = {
  chartSize: ChartSize;
  data: VerticalBarGraphData[];
  labelOptions?: LabelOptions;
  projectCostRanges: ProjectCostRange[];
  setChartTooltip?: (tooltip: ChartTooltip) => void;
  stackMap: ChartStackMap[];
  projectMap?: ProjectMap;
};

// The input data is a list of stacks and their associated project & cost
// The chart allows us to map both positive and negative costs
// The positive and negative costs should be seperate entries in data array
// this allows us to make two seperate bars for both positive & negative parts of the cost
// ie => [{project: ...., stack: 'pending', value: 4000}, {stack: 'pending', value: -3500}]
//
// The stackMap input is a list of each stack, and it's associated color
// ie => [{pending: 'yellow'}]
//
// The projectCostRanges input is a list of the total costs for each project
// since the data can include multiple entries for each project
// this input sums up all the costs by project so we don't need to
// in this function.  That calculation is best done on the backend
// Note that the total cost is equivalent to item magnitude
// ie => [{id: ...., positiveCost: 4000, negativeCost: -3500, totalCost: 7500}]
const ChartsVerticalBarGraph: FC<ChartsVerticalBarGraphProps> = ({
  chartSize,
  data,
  projectCostRanges,
  setChartTooltip,
  stackMap,
  projectMap,
  labelOptions = { hyperlinkProject: true, ...theme.typography.chartBold },
}) => {
  const navigate = useNavigate();
  const projectOrder = new Map(projectCostRanges.map((p, i) => [p.project.id, i]));

  // add all the bars to the graph
  const renderChartFn = (svg: d3.Selection<SVGSVGElement, unknown, null, undefined>) => {
    // format the input data for use by d3
    const { color, rolledUpData, series } = formatData(data, stackMap);

    // generate the axis
    const { x, xAxis, y, yAxis } = generateAxis(
      data,
      chartSize,
      projectOrder,
      projectCostRanges,
      navigate,
      projectMap
    );

    generateChartBars(svg, chartSize, projectCostRanges, series, x, y, color, setChartTooltip);
    generateChartAxis(svg, rolledUpData, xAxis, yAxis, labelOptions, projectMap, setChartTooltip);
  };

  return (
    <ChartsD3GraphWrapper
      chartDependencies={[chartSize, data, stackMap, projectCostRanges]}
      chartSize={chartSize}
      renderChartFn={renderChartFn}
    />
  );
};

export default ChartsVerticalBarGraph;
