import { gql } from '../../../api/graphqlFragments';

export const contingencyDrawEventMutation = gql`
  mutation contingencyDrawEvent(
    $projectID: UUID!
    $estimateID: UUID!
    $itemID: UUID!
    $drawFromID: UUID!
    $value: Numeric!
    $isFirst: Boolean!
    $markupType: MarkupType!
    $currentValue: Numeric!
  ) {
    contingencyDrawEvent(
      projectID: $projectID
      estimateID: $estimateID
      itemID: $itemID
      drawFromID: $drawFromID
      value: $value
      isFirst: $isFirst
      markupType: $markupType
      currentValue: $currentValue
    )
  }
`;
