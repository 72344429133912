import { FC } from 'react';

import { ErrorOutline as Error } from '@material-ui/icons';

import ErrorTooltip from '../ErrorTooltip';

interface StringRendererProps {
  placeholder?: string;
  value: RegularCell;
  error: string;
}

export const StringRenderer: FC<StringRendererProps> = ({ placeholder, value, error }) => {
  const disabled = 'disabled' in value ? value.disabled : false;
  const disabledMarkupStyle = disabled ? `join-grid-line-disabled` : '';
  const errorDisplay = error ? (
    <div>
      <ErrorTooltip title={error}>
        <Error />
      </ErrorTooltip>
    </div>
  ) : (
    <div /> // Extra dummy div to get flex to play nice
  );

  const mainDisplay =
    (value.string && value.string.length > 0) || !placeholder ? (
      <div>{value.string}</div>
    ) : (
      <div className="join-grid-string-placeholder">{placeholder}</div>
    );

  return (
    <div
      className={`join-grid-renderer join-grid-string ${
        error ? 'join-grid-cell-error' : ''
      } ${disabledMarkupStyle}`}
    >
      {mainDisplay}
      {errorDisplay}
    </div>
  );
};
