import { ChangeEvent, FC, useState } from 'react';
import * as React from 'react';

import { TextField } from '@material-ui/core';

import { withStyles } from '../../../../../../theme/komodo-mui-theme';
import NormalTooltip from '../../../../../NormalTooltip/NormalTooltip';

import PageNumberInputStyles from './PageNumberInputStyles';

type PageNumberInputProps = {
  classes: Classes<typeof PageNumberInputStyles>;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  disabled?: boolean;
  onPageChange: (index: number) => void;
  pageIndex: number;
  pagesTotal: number;
  setPageNumberFocus?: (setFocus: boolean) => void;
  tooltipText?: string;
};

const PageNumberInput: FC<PageNumberInputProps> = ({
  classes,
  disabled,
  onPageChange,
  pageIndex,
  pagesTotal,
  setPageNumberFocus,
  tooltipText,
}) => {
  const [inputPageNumber, setInputPageNumber] = useState<number | undefined>(undefined);

  const onPageInput = (e: ChangeEvent<HTMLInputElement>) => {
    const newPageNumber = Number(e.target.value);
    if (Number.isNaN(newPageNumber)) return;
    setInputPageNumber(newPageNumber);
    setPageNumberFocus?.(true);
  };

  const onBlurPageNumber = () => {
    if (!inputPageNumber || inputPageNumber < 1 || inputPageNumber > pagesTotal) {
      setInputPageNumber(undefined);
      return;
    }
    onPageChange(inputPageNumber - 1);
    setInputPageNumber(undefined);
    setPageNumberFocus?.(false);
  };

  const onFocusPageNumber = () => setPageNumberFocus?.(true);

  const onKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    e.stopPropagation();
    if (e.key === 'Enter') {
      onBlurPageNumber();
    }
  };

  const value = (inputPageNumber === 0 ? '' : inputPageNumber) ?? pageIndex + 1;

  return (
    <NormalTooltip title={tooltipText}>
      <TextField
        className={`${classes.pageNumberContainer} ${
          disabled ? '' : classes.pageNumberContainerEdit
        }`}
        disabled={disabled}
        InputProps={{
          classes: {
            input: classes.pageNumber,
          },
          disableUnderline: true,
        }}
        onBlur={onBlurPageNumber}
        onChange={onPageInput}
        onFocus={onFocusPageNumber}
        onKeyDown={onKeyDown}
        // Value is a page number
        value={value}
      />
    </NormalTooltip>
  );
};

export default withStyles(PageNumberInputStyles)(PageNumberInput);
