import { FC } from 'react';

import { withStyles } from '../../../../theme/komodo-mui-theme';

import styles from './NewBadgeStyles';

type NewBadgeProps = {
  classes: Classes<typeof styles>;
};

const NewBadge: FC<NewBadgeProps> = ({ classes }) => (
  <p className={`${classes.container} `}>New!</p>
);

export default withStyles(styles)(NewBadge);
