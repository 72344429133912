import { ScaleLinear, ScalePoint, ScaleTime } from 'd3-scale';

type Props = {
  x: ScaleTime<number, number> | ScalePoint<Date>;
  y: ScaleLinear<number, number>;
  xPosition: Date;
  yPosition: number;
  label: string;
};

export default function ChartLabelX(props: Props) {
  const x = props.x(props.xPosition);
  const y = props.y(props.yPosition);
  return (
    <g transform={`translate(${x}, ${y})`}>
      <text textAnchor="middle" y="53">
        {props.label}
      </text>
    </g>
  );
}
