import { FC } from 'react';

import {
  CompanyAdminEventType,
  CompanyAdminView,
  companyAdminAnalyticsEvent,
} from '../../analytics/analyticsEventProperties';
import useSendAnalytics from '../../hooks/useSendAnalytics';
import JoinSelect, { SelectEntry, getEntry } from '../Select/JoinSelect/JoinSelect';

type CompanyTabUserSidebarFilterProps = {
  filterStatus: string;
  setFilterStatus: (s: string) => void;
  searchStorage: string;
  userCounts: number[];
  view?: CompanyAdminView;
};

const CompanyTabUserSidebarFilter: FC<CompanyTabUserSidebarFilterProps> = ({
  filterStatus,
  setFilterStatus,
  searchStorage,
  userCounts,
  view,
}) => {
  const entries = [
    getEntry('All', `All (${userCounts[0]})`),
    getEntry('Active', `Active (${userCounts[1]})`),
    getEntry('Deactivated', `Deactivated (${userCounts[2]})`),
    getEntry('Pending', `Pending (${userCounts[3]})`),
  ] as SelectEntry[];
  const sendAnalytics = useSendAnalytics();
  return (
    <JoinSelect
      entries={entries}
      onChange={(status: string) => {
        setFilterStatus(status);
        localStorage.setItem(searchStorage, status);
        sendAnalytics(
          companyAdminAnalyticsEvent(CompanyAdminEventType.MEMBER_FILTER, { view, filter: status })
        );
      }}
      value={filterStatus}
    />
  );
};

export default CompanyTabUserSidebarFilter;
