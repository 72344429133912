import { FC } from 'react';

import { Check } from '@material-ui/icons';

import GridRowCheckbox from '../JoinGridRowCheckbox';

interface Props {
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  checked: boolean;
  isIncorporatedItemDraw: boolean;
  isIncorporatedItemMarkupDisplayTypeCell: boolean;
  isInherited: boolean;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  linesReadOnly: boolean;
  row: number;
  toggleAllocatedMarkupLine: (row: number) => void;
  variant: 'icon' | 'disabledIcon' | '';
}

const CheckboxRenderer: FC<Props> = ({
  checked,
  isIncorporatedItemDraw,
  isIncorporatedItemMarkupDisplayTypeCell,
  isInherited,
  linesReadOnly,
  row,
  toggleAllocatedMarkupLine,
  variant,
}) => {
  if (variant !== '') {
    if (checked) {
      const color = variant === 'disabledIcon' ? 'text-radio-disabled' : '';
      return (
        <div className="flex w-full">
          <Check className={`h-4 w-4 w-full flex-grow ${color}`} />
        </div>
      );
    }
    return null;
  }
  return (
    <GridRowCheckbox
      checked={checked}
      cy="button-allocateCheckBox"
      disabled={
        linesReadOnly ||
        isIncorporatedItemMarkupDisplayTypeCell ||
        isIncorporatedItemDraw ||
        isInherited
      }
      onClick={() => {
        if (
          !(
            isIncorporatedItemMarkupDisplayTypeCell ||
            isInherited ||
            linesReadOnly ||
            isIncorporatedItemDraw
          )
        )
          toggleAllocatedMarkupLine(row);
      }}
      position="center"
      showHoverBackground={false}
    />
  );
};

export default CheckboxRenderer;
