import { FC } from 'react';

import { ITEMS_BY_ASSIGNEE } from '../../../constants';
import ExecutiveDashboardChartFrame from '../ExecutiveDashboardChartFrame/ExecutiveDashboardChartFrame';
import { useItemsOwnershipQuery } from '../ExecutiveDashboardHooks';
import ExecutiveDashboardLoadingState from '../ExecutiveDashboardLoadingState/ExecutiveDashboardLoadingState';
import {
  ExecutiveDashboardDataProps,
  ITEMS_OWNERSHIP_HEIGHT,
  ITEMS_OWNERSHIP_TOOLTIP,
} from '../ExecutiveDashboardUtils';

import ExecutiveDashboardItemsOwnership from './ExecutiveDashboardItemsOwnership';

const ExecutiveDashboardItemsOwnershipData: FC<ExecutiveDashboardDataProps> = ({
  dashboardLoading,
  projectIDs,
}) => {
  const { data: { itemsOwnership } = { itemsOwnership: null }, loading } =
    useItemsOwnershipQuery(projectIDs);

  return (
    <ExecutiveDashboardChartFrame
      component={
        loading || dashboardLoading ? (
          <ExecutiveDashboardLoadingState />
        ) : (
          <ExecutiveDashboardItemsOwnership itemsOwnership={itemsOwnership} />
        )
      }
      height={ITEMS_OWNERSHIP_HEIGHT}
      title={ITEMS_BY_ASSIGNEE}
      tooltip={ITEMS_OWNERSHIP_TOOLTIP}
    />
  );
};

export default ExecutiveDashboardItemsOwnershipData;
