import {
  GetActiveUsersQuery,
  GetActiveUsersQueryVariables,
  GetDecisionSummaryQuery,
  GetDecisionSummaryQueryVariables,
  GetItemsOwnershipQuery,
  GetItemsOwnershipQueryVariables,
  GetProjectActiveUsersQuery,
  GetProjectActiveUsersQueryVariables,
  GetProjectBudgetGapsQuery,
  GetProjectBudgetGapsQueryVariables,
  GetProjectLocationsQuery,
  GetProjectLocationsQueryVariables,
  GetProjectMovementsQuery,
  GetProjectMovementsQueryVariables,
  GetProjectsOverviewQuery,
  GetProjectsOverviewQueryVariables,
  GetRecentlyCreatedProjectsQuery,
  GetRecentlyCreatedProjectsQueryVariables,
  InsightsProjectCountsQuery,
  InsightsProjectCountsQueryVariables,
  ListCurrentItemsBreakdownQuery,
  ListCurrentItemsBreakdownQueryVariables,
  ListDashboardProjectsQuery,
  ListDashboardProjectsQueryVariables,
  ListItemDueDatesQuery,
  ListItemDueDatesQueryVariables,
} from '../../generated/graphql';
import { useQuery } from '../../hooks/useMountAwareQuery';
import { MountPolicy } from '../../hooks/usePolicyOnFirstMount';
import { useTypesIdsToNames } from '../../hooks/useTypesIdsToNames';
import { ProjectFilterState } from '../ProjectsList/ProjectsListUtils';

import {
  getActiveUsersQuery,
  getDecisionSummaryQuery,
  getItemsOwnershipQuery,
  getProjectActiveUsersQuery,
  getProjectBudgetGapsQuery,
  getProjectLocationsQuery,
  getProjectMovementsQuery,
  getProjectsOverviewQuery,
  getRecentlyCreatedProjectsQuery,
  insightsProjectCountsQuery,
  listCurrentItemsBreakdownQuery,
  listDashboardProjectsQuery,
  listItemDueDatesQuery,
} from './queries';

export const useActiveUsersQuery = (projectIDs: UUID[]) =>
  useQuery<GetActiveUsersQuery, GetActiveUsersQueryVariables>(
    getActiveUsersQuery,
    {
      variables: { projectIDs },
      skip: !projectIDs || !projectIDs.length,
    },
    MountPolicy.SKIP_ON_MOUNT
  );

export const useListDashboardProjects = (
  projectsFilterByOuter: ProjectFilterState,
  includeCompanyProjects?: boolean,
  companyID?: UUID
) => {
  const projectsFilterBy = useTypesIdsToNames(projectsFilterByOuter, 'types');

  return useQuery<ListDashboardProjectsQuery, ListDashboardProjectsQueryVariables>(
    listDashboardProjectsQuery,
    {
      variables: {
        companyID,
        projectsFilterBy,
        includeCompanyProjects,
      },
    },
    MountPolicy.SKIP_ON_MOUNT
  );
};

export const useInsightsProjectCountsQuery = (
  projectsFilterByOuter: ProjectFilterState,
  companyID?: UUID
) => {
  const projectsFilterBy = useTypesIdsToNames(projectsFilterByOuter, 'types');
  return useQuery<InsightsProjectCountsQuery, InsightsProjectCountsQueryVariables>(
    insightsProjectCountsQuery,
    {
      variables: { companyID, projectsFilterBy },
    },
    MountPolicy.SKIP_ON_MOUNT
  );
};

export const useDecisionSummaryQuery = (projectIDs: UUID[]) =>
  useQuery<GetDecisionSummaryQuery, GetDecisionSummaryQueryVariables>(
    getDecisionSummaryQuery,
    {
      variables: { projectIDs },
      skip: !projectIDs || !projectIDs.length,
    },
    MountPolicy.SKIP_ON_MOUNT
  );

export const useItemsOwnershipQuery = (projectIDs: UUID[]) =>
  useQuery<GetItemsOwnershipQuery, GetItemsOwnershipQueryVariables>(
    getItemsOwnershipQuery,
    {
      variables: { projectIDs },
      skip: !projectIDs || !projectIDs.length,
    },
    MountPolicy.SKIP_ON_MOUNT
  );

export const useProjectActiveUsersQuery = (projectIDs: UUID[]) =>
  useQuery<GetProjectActiveUsersQuery, GetProjectActiveUsersQueryVariables>(
    getProjectActiveUsersQuery,
    {
      variables: { projectIDs },
      skip: !projectIDs || !projectIDs.length,
    },
    MountPolicy.SKIP_ON_MOUNT
  );

export const useProjectBudgetGapsQuery = (projectIDs: UUID[]) =>
  useQuery<GetProjectBudgetGapsQuery, GetProjectBudgetGapsQueryVariables>(
    getProjectBudgetGapsQuery,
    {
      variables: { projectIDs },
      skip: !projectIDs || !projectIDs.length,
    },
    MountPolicy.SKIP_ON_MOUNT
  );

export const useProjectLocationsQuery = (projectIDs: UUID[]) =>
  useQuery<GetProjectLocationsQuery, GetProjectLocationsQueryVariables>(
    getProjectLocationsQuery,
    {
      variables: { projectIDs },
      skip: !projectIDs || !projectIDs.length,
    },
    MountPolicy.SKIP_ON_MOUNT
  );

export const useProjectMovementQuery = (projectIDs: UUID[]) =>
  useQuery<GetProjectMovementsQuery, GetProjectMovementsQueryVariables>(
    getProjectMovementsQuery,
    {
      variables: { projectIDs },
      skip: !projectIDs || !projectIDs.length,
    },
    MountPolicy.SKIP_ON_MOUNT
  );

export const useProjectsOverviewQuery = (projectIDs: UUID[]) =>
  useQuery<GetProjectsOverviewQuery, GetProjectsOverviewQueryVariables>(
    getProjectsOverviewQuery,
    {
      variables: { projectIDs },
      skip: !projectIDs || !projectIDs.length,
    },
    MountPolicy.SKIP_ON_MOUNT
  );

export const useListItemDueDatesQuery = (projectIDs: UUID[]) =>
  useQuery<ListItemDueDatesQuery, ListItemDueDatesQueryVariables>(
    listItemDueDatesQuery,
    {
      variables: { projectIDs },
      skip: !projectIDs || !projectIDs.length,
    },
    MountPolicy.SKIP_ON_MOUNT
  );

export const useCurrentItemsBreakdownQuery = (projectIDs: UUID[]) =>
  useQuery<ListCurrentItemsBreakdownQuery, ListCurrentItemsBreakdownQueryVariables>(
    listCurrentItemsBreakdownQuery,
    {
      variables: { projectIDs },
      skip: !projectIDs || !projectIDs.length,
    },
    MountPolicy.SKIP_ON_MOUNT
  );

export const useRecentlyCreatedProjectsQuery = (projectIDs: UUID[]) =>
  useQuery<GetRecentlyCreatedProjectsQuery, GetRecentlyCreatedProjectsQueryVariables>(
    getRecentlyCreatedProjectsQuery,
    {
      variables: { projectIDs },
      skip: !projectIDs || !projectIDs.length,
    },
    MountPolicy.SKIP_ON_MOUNT
  );
