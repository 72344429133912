import { gql, itemHistoryFields } from '../../../../api/graphqlFragments';

export const itemHistoryQuery = gql`
  query itemHistory(
    $itemID: UUID
    $costMode: CostMode!
    $viewFilter: ViewFilter!
    $eventTypeFilters: [EventType!]!
  ) {
    itemHistory(
      itemID: $itemID
      costMode: $costMode
      viewFilter: $viewFilter
      eventTypeFilters: $eventTypeFilters
    ) {
      ...itemHistoryFields
    }
  }
  ${itemHistoryFields}
`;
