import { useLocalStorageParams } from '../../../utilities/urlState';

type ConfirmParams = {
  disabled: boolean;
};

export const useDontShowConfirm = (
  title: string,
  openDialog: () => void,
  onConfirmAction: () => void
) => {
  const [settings, setSettings] = useLocalStorageParams<ConfirmParams>(
    { disabled: false },
    `Join - Disable Confirm ${title}`
  );
  const setDisabled = (disabled: boolean) => setSettings({ disabled });
  const { disabled } = settings;
  const onClick = disabled ? onConfirmAction : openDialog;
  return { disabled, setDisabled, onClick };
};
