import { DatePicker, MuiPickersUtilsProvider } from 'material-ui-pickers';
import { FC, useState } from 'react';

import DateFnsUtils from '@date-io/date-fns';
import { TextField, Typography } from '@material-ui/core';

import {
  updateItemAssigneeAnalytics,
  updateItemDescriptionAnalytics,
  updateItemDueDateAnalytics,
  updateItemNameAnalytics,
  updateItemNumberProps,
} from '../../../analytics/analyticsEventProperties';
import useProjectPropsQuery from '../../../hooks/useProjectPropsQuery';
import useSendAnalytics from '../../../hooks/useSendAnalytics';
import { withStyles } from '../../../theme/komodo-mui-theme';
import {
  getDateString,
  isDateValid,
  localeDateFormat,
  maskFn,
  parseDate,
  placeholderDefault,
} from '../../../utilities/dates';
import { getItemStatusLabel } from '../../../utilities/item-status';
import { computeMilestonesSorted } from '../../../utilities/milestones';
import { useAttachToItem } from '../../assets/hooks/mutations/useAttachToItem';
import UserAvatar from '../../Collaborators/UserAvatar';
import { getIsDeactivated } from '../../CompanyTab/CompanyTabUtils';
import { ItemStatusIcon, ItemStatusSelect } from '../../dragon-scales';
import InputsSelectAssigneeData from '../../Inputs/InputsSelectAssignee/InputsSelectAssigneeData';
import InputsTextAreaStyled from '../../Inputs/InputsTextAreaStyled/InputsTextAreaStyled';
import ItemNumberTextInput from '../../Inputs/ItemNumberTextInput/ItemNumberTextInput';
import useUpdateStatus from '../../Items/ItemsHooks';
import {
  getItemTypeAnalytics,
  isPrivateVisibility,
  isScenarioVisibility,
} from '../../Items/ItemsUtils';
import { ScrollContainer } from '../../scales';
import useMemoWrapper from '../../useMemoWrapper';
import {
  useItemAssigneeUpdate,
  useItemDescriptionUpdate,
  useItemDueDateUpdate,
  useItemNameUpdate,
  useItemNumberUpdate,
} from '../../ve-item-details/ItemDetailsHooks';

import ItemMilestoneContainer from './ItemMilestoneContainer/ItemMilestoneContainer';
import styles from './ItemSidebarCollapseGeneralStyle';
import ItemSidebarTextField from './ItemSidebarTextField';
import { ItemPermissions, getSidebarLocation } from './ItemSidebarUtils';

type ItemSidebarCollapseGeneralProps = {
  classes: Classes<typeof styles>;
  item: ItemLike;
  minHeight: number;
  projectId: UUID;
  permissions: ItemPermissions;
  projectMilestones: Milestone[];
  onItemMutated?: () => void;
};

const ItemSidebarCollapseGeneral: FC<ItemSidebarCollapseGeneralProps> = ({
  classes,
  item,
  minHeight,
  projectId,
  permissions,
  projectMilestones,
  onItemMutated,
}) => {
  // HOOKS
  const sendAnalytics = useSendAnalytics();

  // QUERIES
  const activeMilestoneId = useProjectPropsQuery(projectId).data.project?.activeMilestone?.id;

  // VARIABLES
  const {
    assignee,
    availableStates,
    id,
    number,
    descriptionStyled,
    name,
    itemType,
    status,
    dueDate,
    visibility,
  } = item || {};
  const isDraft = isPrivateVisibility(visibility);

  // STATE
  const isScenario = isScenarioVisibility(visibility);
  const [itemDueDate, setItemDueDate] = useState(dueDate || null);
  const [itemName, setItemName] = useState(name || '');

  const milestones = useMemoWrapper(computeMilestonesSorted, projectMilestones, activeMilestoneId);

  // UPDATES
  const attachToItem = useAttachToItem({ onCompleted: onItemMutated });
  const onAttachAsset = (asset: Asset) => {
    attachToItem({ input: { assetID: asset.id, itemID: item.id } });
  };

  const updateStatus = useUpdateStatus({ onCompleted: onItemMutated });
  const setUpdateStatus = (newStatus: Status) => {
    if (status !== newStatus) {
      updateStatus(projectId, item, newStatus);
    }
  };

  const updateAssignee = useItemAssigneeUpdate({ onCompleted: onItemMutated });
  const setAssignee = (id: UUID, assigneeEmail: string | undefined) => {
    if (item && ((item.assignee && item.assignee.email) || undefined) !== assigneeEmail) {
      sendAnalytics(
        updateItemAssigneeAnalytics({
          id,
          assigneeEmail,
          itemType: getItemTypeAnalytics(item),
          location: getSidebarLocation(),
        })
      );
      updateAssignee(projectId, id, assigneeEmail);
    }
  };

  const updateDueDate = useItemDueDateUpdate({ onCompleted: onItemMutated });
  const setDueDate = (id: UUID, dueDate: string) => {
    if (item && (parseDate(item.dueDate) || undefined) !== parseDate(dueDate)) {
      sendAnalytics(
        updateItemDueDateAnalytics({
          id,
          dueDate,
          itemType: getItemTypeAnalytics(item),
          location: getSidebarLocation(),
        })
      );
      setItemDueDate(dueDate);
      updateDueDate(projectId, id, dueDate);
    }
  };

  const sendItemDescriptionAnalytics = (newDescription: string) => {
    sendAnalytics(
      updateItemDescriptionAnalytics({
        itemID: id,
        newDescription,
        itemType,
        location: getSidebarLocation(),
      })
    );
  };

  const updateItemDescription = useItemDescriptionUpdate({ onCompleted: onItemMutated });
  const setUpdateItemDescription = (description: string, descriptionStyled: string) => {
    sendItemDescriptionAnalytics(description);
    updateItemDescription(projectId, item.id, description, descriptionStyled);
  };

  const updateItemName = useItemNameUpdate({ onCompleted: onItemMutated });
  const setUpdateItemName = () => {
    if (name !== itemName) {
      sendAnalytics(
        updateItemNameAnalytics({
          itemID: id,
          newName: itemName,
          itemType,
          location: getSidebarLocation(),
        })
      );
      updateItemName(projectId, id, itemName);
    }
  };

  const updateItemNumber = useItemNumberUpdate({ onCompleted: onItemMutated });
  const setItemNumber = (itemId: UUID, itemNumber: string, type: string) => {
    if (number !== itemNumber) {
      updateItemNumber(projectId, itemId, itemNumber);
      sendAnalytics(updateItemNumberProps(itemId, itemNumber, type, getSidebarLocation()));
    }
  };

  // COMPONENTS
  const renderItemAssignee = () => {
    const selectedAssigneeEmail = assignee?.email || '';
    return (
      <div className={classes.halfContainer}>
        <Typography className={classes.captionColor} variant="caption">
          Assignee
        </Typography>
        {permissions.canEditItemAssignee ? (
          <div>
            <InputsSelectAssigneeData
              key={item ? id : ''}
              disabled={!permissions.canEditItemAssignee}
              isDraft={isDraft}
              itemID={item.id}
              onChange={(email) => setAssignee(id, email)}
              projectId={projectId}
              selected={selectedAssigneeEmail}
              selectedAssignee={assignee}
            />
          </div>
        ) : (
          <div>
            {assignee ? (
              <div className={classes.avatarDisabled}>
                <UserAvatar assignee={assignee} deactivated={getIsDeactivated(assignee)} />
                <ItemSidebarTextField value={assignee.name} />
              </div>
            ) : (
              <div className={classes.entryTopPadding}>
                <ItemSidebarTextField disabled value="Unassigned" />
              </div>
            )}
          </div>
        )}
      </div>
    );
  };

  const renderItemDescription = () => (
    <div>
      <Typography className={classes.captionColor} variant="caption">
        Item Description
      </Typography>
      {permissions.canEditItemDetails ? (
        <InputsTextAreaStyled
          data-cy="item-description-input"
          isEditable
          onAttachAsset={onAttachAsset}
          onChange={(description, descriptionStyled) => {
            setUpdateItemDescription(description, descriptionStyled);
          }}
          placeholder="Describe this item..."
          value={descriptionStyled}
        />
      ) : (
        <div>
          {!descriptionStyled ? (
            <ItemSidebarTextField
              dataCy="item-description-input"
              disabled
              value="No item description"
            />
          ) : (
            <InputsTextAreaStyled
              data-cy="item-description-input"
              isEditable={false}
              placeholder="Describe this item..."
              value={descriptionStyled}
            />
          )}
        </div>
      )}
    </div>
  );

  const renderItemDueDate = () => (
    <div className={classes.halfContainer}>
      <Typography className={classes.captionColor} variant="caption">
        Due Date
      </Typography>
      <div>
        {permissions.canEditItemAssignee ? (
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              animateYearScrolling={false}
              className={classes.date}
              clearable
              data-cy="input-date-picker"
              disabled={!permissions.canEditItemAssignee}
              format={localeDateFormat}
              FormHelperTextProps={{ classes: { error: classes.error } }}
              fullWidth
              InputProps={{
                classes: { input: `picker ${classes.picker}` },
                disableUnderline: true,
              }}
              invalidDateMessage=" "
              keepCharPositions
              keyboard
              KeyboardButtonProps={{
                classes: { root: classes.datePickerIcon },
              }}
              mask={maskFn}
              onChange={(value) => {
                if ((value && isDateValid(value)) || value === null) {
                  setDueDate(item.id, value);
                }
              }}
              // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
              onKeyDown={(evt: any) => {
                if (evt.key === 'Enter') {
                  evt.target.blur();
                }
              }}
              placeholder={placeholderDefault}
              value={itemDueDate}
            />
          </MuiPickersUtilsProvider>
        ) : (
          <div className={classes.entryTopPadding}>
            {itemDueDate ? (
              <ItemSidebarTextField
                dataCy="input-date-picker"
                value={getDateString(new Date(itemDueDate))}
              />
            ) : (
              <ItemSidebarTextField dataCy="input-date-picker" disabled value="No due date" />
            )}
          </div>
        )}
      </div>
    </div>
  );

  const renderItemNumber = () => (
    <>
      {permissions.canEditItemNumber ? (
        <div className="w-40">
          <ItemNumberTextInput
            editable={permissions.canEditItemNumber}
            isPrivate={isPrivateVisibility(visibility)}
            itemID={id}
            itemType={itemType}
            label="Item Number"
            onChange={setItemNumber}
            value={number}
          />
        </div>
      ) : (
        <div className={classes.flexGrow}>
          <Typography className={classes.captionColor} variant="caption">
            Item Number
          </Typography>
          {number === '' ? (
            <ItemSidebarTextField dataCy="item-number-text-input" disabled value="None" />
          ) : (
            <ItemSidebarTextField dataCy="item-number-text-input" value={number} />
          )}
        </div>
      )}
    </>
  );

  const renderItemStatus = () => {
    return (
      <>
        {permissions.canEditItemStatus ? (
          <ItemStatusSelect
            availableStatuses={availableStates}
            data-cy="item-status-select"
            label="Item Status"
            onChange={(status) => setUpdateStatus(status)}
            value={status}
          />
        ) : (
          <div className={classes.flexGrow}>
            <Typography className={classes.captionColor} variant="caption">
              Item Status
            </Typography>
            <ItemSidebarTextField
              dataCy="statusIcon-statusIconSelect"
              icon={<ItemStatusIcon size="md" value={status} />}
              value={getItemStatusLabel(status)}
            />
          </div>
        )}
      </>
    );
  };

  const renderItemName = () => (
    <div className={classes.fullContainer}>
      <Typography className={classes.captionColor} variant="caption">
        Item Name
      </Typography>
      {permissions.canEditItemDetails ? (
        <TextField
          className={classes.backgroundColor}
          data-cy="item-name-text-input"
          disabled={!permissions.canEditItemDetails}
          fullWidth
          id="name"
          InputProps={{
            classes: {
              root: classes.title,
              input: classes.titleInput,
            },
            disableUnderline: true,
          }}
          multiline
          onBlur={() => {
            setUpdateItemName();
          }}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
          onChange={(e: any) => setItemName(e.target.value)}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
          onKeyDown={(evt: any) => {
            if (evt.key === 'Enter') evt.target.blur();
          }}
          placeholder="Item name"
          value={itemName}
        />
      ) : (
        <div>
          {!itemName ? (
            <ItemSidebarTextField dataCy="item-name-text-input" disabled value="No item name" />
          ) : (
            <ItemSidebarTextField dataCy="item-name-text-input" value={itemName} />
          )}
        </div>
      )}
    </div>
  );

  const renderItemMilestone = () => (
    <ItemMilestoneContainer
      item={item}
      milestones={milestones}
      onItemMutated={onItemMutated}
      permissions={permissions}
      projectID={projectId}
    />
  );

  return (
    <ScrollContainer direction="vertical" style={{ minHeight }}>
      <div className={classes.generalContainerHeight}>
        <div className={classes.generalContainerSections}>
          {!isScenario && renderItemNumber()}
          {!isScenario && renderItemStatus()}
        </div>
        <div className={classes.flexBetween}>{renderItemName()}</div>
        {renderItemDescription()}
        <div className={classes.generalContainerSections}>
          {!isScenario && renderItemAssignee()}
          {renderItemDueDate()}
        </div>
        {!isScenario && renderItemMilestone()}
      </div>
    </ScrollContainer>
  );
};

export default withStyles(styles)(ItemSidebarCollapseGeneral);
