import { OrganizationType } from '../../api/gqlEnums';
import { PermissionResource } from '../../generated/graphql';
import usePermissions from '../../utilities/permissions/usePermissions';
import useMemoWrapper from '../useMemoWrapper';

export default function useTeamData(
  team: Team | null | undefined,
  displayProjectID?: UUID
): TeamData[] {
  // Getting permissions
  const { canView } = usePermissions({ projectID: displayProjectID });
  const canViewProjectDetails = canView(PermissionResource.PROJECT_DETAILS);
  const canViewItemAttachments = canView(PermissionResource.ITEM_ATTACHMENTS);
  const canViewTeamAssets = canViewProjectDetails && canViewItemAttachments;

  const teamOrdered = useMemoWrapper(getOrderedTeam, team);

  const teamData = teamOrdered.map(
    (t: Organization, i: number) =>
      ({
        role: organizationLabels[i],
        image: canViewTeamAssets ? t.picture : undefined,
        name: t.name,
      }) as TeamData
  );
  // don't return any organizations that are missing an image
  return teamData.filter((t: TeamData) => !!t.image);
}

const defaultOrganization: Organization = { name: '', picture: '' };

// the order of this list determines the order of the organizations in the ui
const organizationLabels = Object.values(OrganizationType) as OrganizationType[];

const getOrderedTeam = (team: Team | null | undefined): Organization[] => {
  if (!team) return [];

  // note: the order is here is important.  We'll use the same order
  // as the organization labels
  const orderedTeam = organizationLabels.map((o) => {
    switch (o) {
      case OrganizationType.OWNER:
        return team.owner || defaultOrganization;
      case OrganizationType.GC:
        return team.generalContractor || defaultOrganization;
      case OrganizationType.DESIGNTEAM:
        return team.designTeam || defaultOrganization;
      default:
        return defaultOrganization;
    }
  });
  return orderedTeam;
};
