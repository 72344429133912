import { useCallback, useState } from 'react';

export default function useSearch({ onChange }: { onChange?: (value: string) => void }) {
  const [value, setValue] = useState('');

  const handleChange = useCallback(
    (value: string) => {
      setValue(value);
      onChange?.(value);
    },
    [onChange]
  );

  return {
    value,
    onChange: handleChange,
  };
}
