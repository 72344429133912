import { FC, MouseEvent, useRef } from 'react';

import { withStyles } from '../../../theme/komodo-mui-theme';

import SelectOption, { MenuOption } from './SelectOption';
import styles from './SelectStyles';

interface SelectOptionProps {
  classes: Classes<typeof styles>;
  options: MenuOption[];
  handleClick: (option: MenuOption, e?: MouseEvent) => void;
  handleHover: (option: MenuOption) => void;
  highlightedOption: MenuOption;
  slideDirection: 'left' | 'right';
  onClose: () => void;
  disableKeyboard: () => void;
  enableKeyboard: () => void;
}

const SelectOptionList: FC<SelectOptionProps> = ({
  classes,
  options,
  handleClick,
  handleHover,
  highlightedOption,
  slideDirection,
  onClose,
  disableKeyboard,
  enableKeyboard,
}) => {
  // options list ref
  const selectOptionRef = useRef(null);
  const listEmptyClass = options.length === 0 && classes.listEmpty;
  // prepare options components
  const optionsComponents = options.map((option: MenuOption, index: number) => {
    const { name, key } = option || {};
    return (
      <SelectOption
        key={key || name}
        disableKeyboard={disableKeyboard}
        enableKeyboard={enableKeyboard}
        handleClick={(e) => handleClick(option, e)}
        handleHover={handleHover}
        highlightedOption={highlightedOption}
        index={index}
        length={options.length}
        onClose={onClose}
        option={option}
        slideDirection={slideDirection}
      />
    );
  });

  return (
    <ul
      ref={selectOptionRef}
      className={`${listEmptyClass} ${classes.list}`}
      id="listbox-list"
      role="listbox"
    >
      {optionsComponents}
    </ul>
  );
};

export default withStyles(styles)(SelectOptionList);
