import { brushX } from 'd3-brush';
import { type ScaleTime } from 'd3-scale';

import {
  BaseDataBaseSelection,
  BrushGroupSelection,
  BrushSelection,
  DivSelection,
  type TimelineData,
  type TimelineOptions,
} from './timeline.types';
import { getInstantPath } from './timelinePath';
import {
  BRUSH_MILESTONE_MAX_RATIO,
  BRUSH_MILESTONE_MIN_RATIO,
  BRUSH_ROW_MAX_HEIGHT,
  BRUSH_ROW_MIN_HEIGHT,
  createXScale,
  isEvent,
  isInstantInterval,
  isInterval,
  isMilestone,
  updateHorizontalLine,
  updateVerticalLine,
} from './utils';
import { addBrushHandle, addToday, handleBrush } from './zoomUtils';

// brush handle shape's path
const HANDLE_PATH = [
  'M -4 3 V 21 C -4 23 -4 23 -2 23 H 2 C 4 23 4 23 4 21 V 3 C 4 1 4 1 2 1 H -2 C -4 1 -4 1 -4 3 Z M -1 16 V 8 Z M 1 16 V 8',
  'M -4 3 V 21 C -4 23 -4 23 -2 23 H 2 C 4 23 4 23 4 21 V 3 C 4 1 4 1 2 1 H -2 C -4 1 -4 1 -4 3 Z M -1 16 V 8 Z M 1 16 V 8',
];

export function updateBrush(
  chart: DivSelection,
  brushXScale: ScaleTime<number, number>,
  zoomRange: number[],
  options: TimelineOptions,
  updateOuter: (domain: Date[]) => void
): void {
  const { brushHeight, dataFlat, today, isPrint, zoomLineDisabled } = options;
  if (isPrint || zoomLineDisabled) return;
  const timelineXScaleFixed = createXScale({ ...options, data: dataFlat });

  const rowHeight = Math.min(brushHeight / dataFlat.length, BRUSH_ROW_MAX_HEIGHT);
  let nodeHeight = rowHeight * 0.8;
  nodeHeight = Math.max(nodeHeight, BRUSH_ROW_MIN_HEIGHT);
  const halfNodeHeight = nodeHeight / 2;
  let ratio = Math.min(rowHeight / (BRUSH_ROW_MAX_HEIGHT - 2), BRUSH_MILESTONE_MAX_RATIO);
  ratio = Math.max(ratio, BRUSH_MILESTONE_MIN_RATIO);

  let brushContainer: DivSelection = chart.select('#brush-container');

  // Create brush container
  if (brushContainer.empty()) {
    brushContainer = chart
      .append('div')
      .attr('id', 'brush-container')
      .style('width', '100%')
      .style('height', `${brushHeight}px`)
      .style('padding-top', '8px');
  }

  let brushSvg: BrushSelection = brushContainer.select('#brush-svg');

  // Remove brush if data is empty
  if (!brushSvg.empty() && dataFlat.length < 1) {
    brushSvg.remove();
    return;
  }

  if (brushSvg.empty()) {
    // Create brush svg
    brushSvg = brushContainer
      .append('svg')
      .attr('id', 'brush-svg')
      .attr('width', '100%')
      .attr('height', `${brushHeight}px`);
  }

  const datapoints: BaseDataBaseSelection = brushSvg.selectAll('g.datapoint');
  const brushGroups = datapoints.data(dataFlat, (d: TimelineData) => d.id);

  const transformDatapoint = (d: TimelineData, i: number) =>
    `translate(${brushXScale(new Date(d.start).getTime())}, ${
      i * rowHeight +
      halfNodeHeight +
      (i === 0 ? halfNodeHeight / 2 : 0) +
      (i === dataFlat.length - 1 ? -halfNodeHeight / 2 : 0)
    })`;

  brushGroups.join(
    function enter(enter) {
      // Add svg group for each data point in dataset group to svg
      const groups = enter
        .append('g')
        .attr('class', (d) =>
          d.end && !isMilestone(d) ? 'datapoint interval' : 'datapoint instant'
        )
        .attr('transform', transformDatapoint);

      // Add rectangle for each interval data point
      groups
        .filter(
          (d: TimelineData) =>
            (!!d.end && !isMilestone(d)) || (isInterval(d) && !isInstantInterval(d, options))
        )
        .append('rect')
        .attr('height', nodeHeight)
        .attr(
          'width',
          (d: TimelineData) =>
            brushXScale(new Date(d.end || today || 0)) - brushXScale(new Date(d.start))
        )
        .style('fill', (d: TimelineData) => options.nodeColor(d))
        .style('stroke', (d: TimelineData) => options.strokeColor(d))
        .style('stroke-width', '0.5')
        .style('rx', halfNodeHeight)
        .style('ry', halfNodeHeight);

      // Add circle for each interval without the end date
      groups
        .filter((d: TimelineData) => isInstantInterval(d, options))
        .append('circle')
        .attr('class', 'datapoint-circle')
        .attr('r', halfNodeHeight)
        .attr('cx', -halfNodeHeight)
        .attr('cy', halfNodeHeight)
        .style('fill', (d: TimelineData) => options.nodeColor(d))
        .style('stroke', (d: TimelineData) => options.strokeColor(d));

      // Add flag for each milestone and dimond for each event
      groups
        .filter((d: TimelineData) => isMilestone(d) || isEvent(d))
        .append('path')
        .attr('class', 'datapoint-path')
        .attr('d', (d) => getInstantPath(d))
        .attr('transform', `scale(${ratio})`)
        .style('stroke-width', '1.4')
        .style('fill', (d: TimelineData) => options.nodeColor(d))
        .style('stroke', (d: TimelineData) => options.strokeColor(d));

      return groups;
    },
    function update(update) {
      // Update x,y for each data point
      update.attr('transform', transformDatapoint);

      // Update width and color for each interval data point
      const intervalNodes: BaseDataBaseSelection = update.selectAll('rect');
      intervalNodes
        .attr(
          'width',
          (d: TimelineData) =>
            brushXScale(new Date(d.end || d.start)) - brushXScale(new Date(d.start))
        )
        .style('fill', (d: TimelineData) => options.nodeColor(d))
        .style('stroke', (d: TimelineData) => options.strokeColor(d))
        .style('stroke-width', '0.5');

      // Update color for each instant data point
      intervalNodes.style('fill', (d: TimelineData) => options.nodeColor(d));

      return update;
    },
    function exit(exit) {
      return exit.remove();
    }
  );

  // Add today line
  if (today) addToday(today, brushSvg, timelineXScaleFixed, 0, brushHeight);

  const existingBrush = brushSvg.select('#brush-group');
  if (!existingBrush.empty()) {
    existingBrush.remove();
  }

  // Create brush
  const brush = brushX().extent([
    [16, 0],
    [brushXScale.range()[1], brushHeight],
  ]);

  brush.on(
    'brush end',
    handleBrush(brushSvg, brush, updateOuter, brushXScale, timelineXScaleFixed, options)
  );

  // Add brush to band
  brushSvg.insert('g').attr('id', 'brush-group').call(brush);

  const brushGroup: BrushGroupSelection = brushSvg.select('#brush-group');

  updateHorizontalLine(brushGroup, options.width, 0);
  updateHorizontalLine(brushGroup, options.width, brushHeight);
  updateVerticalLine(brushGroup, brushHeight, 16);
  updateVerticalLine(brushGroup, brushHeight, options.width - 16);

  brushGroup
    .select('.selection')
    .attr('class', 'selection stroke-background-2 fill-background-2')
    .attr('height', brushHeight)
    .attr('y', 0)
    .attr('stroke-opacity', '0.08')
    .attr('fill-opacity', '0.4');

  addBrushHandle(brushGroup, HANDLE_PATH);
  brushGroup.call(brush).call(brush.move, zoomRange);
}
