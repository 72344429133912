import { commonCategorizationFields, gql } from '../../../../api/graphqlFragments';

export const createCompanyCategorizationMutation = gql`
  mutation createCompanyCategorization($companyID: UUID!, $name: String!, $description: String) {
    createCompanyCategorization(companyID: $companyID, name: $name, description: $description) {
      ...commonCategorizationFields
    }
  }
  ${commonCategorizationFields}
`;

export const companyCategorizationsQuery = gql`
  query companyCategorizations($companyID: UUID!) {
    companyCategorizations(companyID: $companyID) {
      companyID
      categorization {
        ...commonCategorizationFields
        createdFrom {
          id
        }
        modifiedBy {
          id
          name
        }
        importMetadata {
          columnLevel1
          columnLevel2
          columnLevel3
          columnLevel4
        }
        modifiedAt
      }
      disabled
    }
  }
  ${commonCategorizationFields}
`;

export const deleteCompanyCategorizationMutation = gql`
  mutation deleteCompanyCategorization($categorizationID: UUID!) {
    deleteCompanyCategorization(categorizationID: $categorizationID)
  }
`;

export const toggleBuiltInCategorizationMutation = gql`
  mutation toggleBuiltInCategorization($categorizationID: UUID!, $disabled: Boolean!) {
    toggleBuiltInCategorization(categorizationID: $categorizationID, disabled: $disabled)
  }
`;

export const addCompanyCategorizationsMutation = gql`
  mutation addCompanyCategorizations($projectID: UUID!, $categorizationIDs: [UUID!]!) {
    addCompanyCategorizationsToProject(
      projectID: $projectID
      categorizationIDs: $categorizationIDs
    ) {
      projectID
      categorization {
        ...commonCategorizationFields
      }
      disabled
    }
  }
  ${commonCategorizationFields}
`;
