import { OrganizationsQuery, OrganizationsQueryVariables } from '../../../../generated/graphql';
import { useQuery } from '../../../../hooks/useMountAwareQuery';
import { MountPolicy } from '../../../../hooks/usePolicyOnFirstMount';
import { organizationsQuery } from '../../../Organizations/hooks/queries';

export default function useOrganizationsQuery(
  companyID: UUID | null | undefined,
  policy = MountPolicy.FETCH_ON_MOUNT
) {
  return useQuery<OrganizationsQuery, OrganizationsQueryVariables>(
    organizationsQuery,
    {
      variables: { companyID },
      skip: !companyID,
    },
    policy
  );
}
