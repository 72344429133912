import { FC, ReactNode, useContext } from 'react';

import { Print } from '@material-ui/icons';

import { JoinProjectRoutes, TermKey } from '../../../api/gqlEnums';
import { Status } from '../../../generated/graphql';
import { isCostRange } from '../../../utilities/compare';
import { formatCost } from '../../../utilities/currency';
import { generateSharedPath } from '../../../utilities/routes/links';
import { EMPTY_COST } from '../../../utilities/string';
import Breadcrumbs from '../../Breadcrumbs/Breadcrumbs';
import { renderCostString, renderValueString } from '../../CostReport/CostReportUtils';
import { ItemStatusIcon } from '../../dragon-scales';
import { Export } from '../../Icons/Export';
import { ProjectTermStore } from '../../ProjectDisplaySettings/TerminologyProvider';
import { Table } from '../../scales';
import IconMenu from '../../Select/SelectMenu/IconMenu';

type TrendingCostReportProps = {
  estimateCost: number;
  budgetCost: number;
  acceptedTotal: number;
  projectID: UUID;
  milestoneName?: string;
  pendingRange?: CostRange | CostScalar;
};

const TrendingCostReport: FC<TrendingCostReportProps> = ({
  estimateCost,
  budgetCost,
  acceptedTotal,
  projectID,
  milestoneName,
  pendingRange,
}) => {
  const termStore = useContext(ProjectTermStore);

  let forecastedString = '';
  let gapString = '';

  if (isCostRange(pendingRange)) {
    forecastedString = renderCostString({
      cost: {
        max: estimateCost + acceptedTotal + Number(pendingRange.max),
        min: estimateCost + acceptedTotal + Number(pendingRange.min),
      },
      isExact: true,
      isWide: true,
    });

    gapString = renderCostString({
      cost: {
        max: budgetCost - (estimateCost + acceptedTotal + Number(pendingRange.max)),
        min: budgetCost - (estimateCost + acceptedTotal + Number(pendingRange.min)),
      },
      isExact: true,
      isWide: true,
    });
  } else {
    forecastedString = renderCostString({
      cost: {
        value: estimateCost + acceptedTotal + Number(pendingRange?.value ?? 0),
      },
      isExact: true,
      isWide: true,
    });
    gapString = renderCostString({
      cost: {
        value: budgetCost - (estimateCost + acceptedTotal + Number(pendingRange?.value ?? 0)),
      },
      isExact: true,
      isWide: true,
    });
  }

  const itemCellStyles = 'flex py-2 items-center gap-1';

  const getItemCell = (content: ReactNode | string) => (
    <div className={`${itemCellStyles} type-table-text`}>{content}</div>
  );
  const getNumberCell = (content: string) => (
    <div className={`${itemCellStyles} ml-auto type-table-number`}>{content}</div>
  );

  return (
    <div>
      <div className="mb-6 flex items-center justify-between border-b bg-background-primary pr-7 print:mt-16">
        <div className="flex flex-shrink-0 items-center px-6 py-4">
          <div className="flex flex-1 flex-col gap-2">
            <Breadcrumbs
              links={[
                {
                  display: 'Reports',
                  destination: generateSharedPath(JoinProjectRoutes.REPORTS, {
                    projectId: projectID,
                  }),
                  tooltip: 'Back to reports',
                },
                {
                  display: milestoneName || 'Loading...',
                  tooltip: milestoneName || 'Loading...',
                },
              ]}
            />
            <h1 className="type-heading1">Trending Cost Report</h1>
          </div>
        </div>

        <IconMenu
          className="print:hidden"
          icon={<Export />}
          isBottomOriented
          options={[
            {
              name: 'Print',
              icon: <Print />,
              callback: () => window.print(),
            },
          ]}
        />
      </div>
      <div className="mx-6 flex max-w-xl flex-col gap-2">
        <div className="border-l border-r border-t">
          <Table
            columnWidths={['1fr', '1fr']}
            entries={[
              [
                {
                  component: getItemCell(termStore.titleCase(TermKey.ESTIMATE)),
                  key: 'description',
                },
                { component: getNumberCell(formatCost(estimateCost)), key: 'cost' },
              ],
              [
                {
                  component: getItemCell(
                    <>
                      <span>Accepted Items</span>
                      <ItemStatusIcon size="sm" value={Status.ACCEPTED} />
                    </>
                  ),
                  key: 'description',
                },
                {
                  component: getNumberCell(
                    renderValueString({ value: acceptedTotal, isWide: true, isExact: true })
                  ),
                  key: 'cost',
                },
              ],
              [
                {
                  component: getItemCell(
                    <>
                      Pending Items
                      <ItemStatusIcon size="sm" value={Status.PENDING} />
                    </>
                  ),
                  key: 'description',
                  hasBlackBorderBottom: true,
                },
                {
                  component: getNumberCell(
                    pendingRange
                      ? renderCostString({ cost: pendingRange, isExact: true, isWide: true })
                      : EMPTY_COST
                  ),
                  key: 'cost',
                  hasBlackBorderBottom: true,
                },
              ],
              [
                {
                  component: getItemCell(<span className="font-medium">Trending Cost</span>),
                  key: 'description',
                },
                {
                  component: getNumberCell(forecastedString),
                  key: 'cost',
                },
              ],
            ]}
            headerContent={[
              { copy: 'Description', key: 'description' },
              { copy: 'Cost', key: 'cost', isRightAligned: true },
            ]}
            rowHeight="minmax(40px, auto)"
          />
        </div>
        <div className="border-l border-r border-t">
          <Table
            columnWidths={['1fr', '1fr']}
            entries={[
              [
                {
                  component: getItemCell(termStore.titleCase(TermKey.GAP)),
                  key: 'description',
                },
                {
                  component: getNumberCell(gapString),
                  key: 'cost',
                },
              ],
              [
                {
                  component: getItemCell(termStore.titleCase(TermKey.TARGET)),
                  key: 'description',
                },
                {
                  component: getNumberCell(formatCost(budgetCost)),
                  key: 'cost',
                },
              ],
            ]}
            rowHeight="minmax(40px, auto)"
          />
        </div>
      </div>
    </div>
  );
};

export default TrendingCostReport;
