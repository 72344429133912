import * as React from 'react';

import { ButtonBase } from '@material-ui/core';

import { PROJECT_COMP_ESCALATION_BUTTON } from '../../../tagConstants';
import { withStyles } from '../../../theme/komodo-mui-theme';

import styles from './StartAdornmentStyles';

const StartAdornment = ({
  classes,
  icon,
  isAutoEscalated,
  isFuzzyMatch,
  label,
  onClick,
}: {
  classes: Classes<typeof styles>;
  icon: React.ComponentType<{ className?: string }>;
  isAutoEscalated: boolean;
  isFuzzyMatch: boolean;
  label?: string;
  onClick: () => void;
}) => {
  const IconComponent = icon;

  if (!isAutoEscalated) {
    return <IconComponent className={`${classes.manualEscalationIcon}`} />;
  }

  return (
    <ButtonBase
      className={`${classes.button} ${
        isFuzzyMatch ? classes.button_fuzzyMatch : classes.button_autoEscalated
      }`}
      data-cy={PROJECT_COMP_ESCALATION_BUTTON}
      data-is-fuzzy-match={isFuzzyMatch ? true : undefined}
      onClick={onClick}
    >
      <IconComponent />
      <div>{label}</div>
    </ButtonBase>
  );
};

export default withStyles(styles)(StartAdornment);
