import {
  ProjectComparisonReportsQuery,
  ProjectComparisonReportsQueryVariables,
} from '../../../../generated/graphql';
import { useQuery } from '../../../../hooks/useMountAwareQuery';

import { projectComparisonReports } from './queries';

export const useProjectComparisonReportsQuery = (
  variables: ProjectComparisonReportsQueryVariables,
  skip = false
) =>
  useQuery<ProjectComparisonReportsQuery, ProjectComparisonReportsQueryVariables>(
    projectComparisonReports,
    {
      variables,
      skip,
    }
  );
