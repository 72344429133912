import { FC } from 'react';

import { Typography } from '@material-ui/core';

import { openItemSidebarExpandableHeaders } from '../../../analytics/analyticsEventProperties';
import {
  CONTINGENCY,
  COST_IMPACT,
  ESTIMATE_CONTENT,
  ESTIMATE_SUMMARY,
  NONE,
} from '../../../constants';
import useSendAnalytics from '../../../hooks/useSendAnalytics';
import { withStyles } from '../../../theme/komodo-mui-theme';
import CollapseIcon from '../../shared-widgets/CollapseIcon';

import styles from './ItemSidebarCollapseHeaderStyles';

type ItemSidebarCollapseHeaderProps = {
  classes: Classes<typeof styles>;
  content?: JSX.Element;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  disabled?: boolean;
  iconCTA?: JSX.Element;
  isExpanded: boolean;
  itemId?: UUID;
  expanded: string[];
  isLight?: boolean;
  name: React.ReactNode;
  sectionID: string;
  setExpanded: (name: string[]) => void;
  variant?: string | null;
};

const ItemSidebarCollapseHeader: FC<ItemSidebarCollapseHeaderProps> = ({
  classes,
  content = null,
  disabled = false,
  iconCTA = null,
  expanded,
  isExpanded = false,
  itemId = null,
  name,
  sectionID,
  setExpanded,
  variant = null,
  isLight = false,
}) => {
  const sendAnalytics = useSendAnalytics();

  const handleClick = () => {
    if (!disabled) {
      if (itemId) sendAnalytics(openItemSidebarExpandableHeaders(itemId, sectionID));

      const isCostImpactSubsection = sectionID === CONTINGENCY || sectionID === ESTIMATE_SUMMARY;

      if (isExpanded) {
        // if this is a cost impact subsection we want to still keep the cost impact section open
        if (isCostImpactSubsection) {
          setExpanded(expanded.filter((e) => e !== sectionID));
        } else {
          setExpanded([NONE]);
        }
      } else {
        if (isCostImpactSubsection) {
          const sections = [...expanded, sectionID];
          // if the parent cost impact section isn't already open then open it
          if (!sections.includes(COST_IMPACT)) sections.push(COST_IMPACT);
          setExpanded(sections);
          return;
        }
        setExpanded([sectionID]);
      }
    }
  };

  // Styles
  const isSidebarEstimateContent = variant && variant === ESTIMATE_CONTENT;
  const itemSidebarCollapseHeaderStyles = `${
    !isSidebarEstimateContent
      ? `${classes.expandableContainer} ${classes.cardHeader}`
      : `${classes.estimateContent}`
  } ${disabled && classes.disabledCursor}`;

  return (
    <div
      className={itemSidebarCollapseHeaderStyles}
      data-cy={`itemSidebarCollapse-${sectionID.replaceAll(' ', '-')}`}
      onClick={handleClick}
      onKeyDown={handleClick}
      role="button"
      tabIndex={0}
    >
      <div className={classes.containerLeft}>
        {!disabled ? (
          <CollapseIcon
            className={classes.expander}
            dataCy={`expand-more-toggle-${sectionID.replaceAll(' ', '-')}`}
            isCollapsed={!isExpanded}
          />
        ) : (
          <div className={classes.expanderPlaceholder} />
        )}
        <Typography
          className={`${isSidebarEstimateContent ? classes.subHeader : classes.header} ${
            isLight ? classes.light : ''
          }`}
        >
          {name}
        </Typography>
        {iconCTA && <div className={classes.iconContainer}>{iconCTA}</div>}
      </div>
      {content}
    </div>
  );
};

export default withStyles(styles)(ItemSidebarCollapseHeader);
