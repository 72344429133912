import { searchEventTypes } from '../../../analytics/analyticsEventProperties';
import { formatNumber } from '../../../utilities/string';
import { isEnumValue } from '../../../utilities/types';
import { ButtonBar } from '../../scales';

import useSendSearchAnalytics from './hooks/useSendSearchAnalytics';

export enum ProgramToggleValue {
  PROGRAMS = 'PROGRAMS',
  PROJECTS = 'PROJECTS',
}

type Props = {
  counts: { programs: number; projects: number };
  onChange: (value: ProgramToggleValue) => void;
  value: ProgramToggleValue;
};

export type ProgramToggleParams = Props;

export default function ProgramToggle({ counts, onChange, value }: Props) {
  const sendSearchAnalytics = useSendSearchAnalytics();

  return (
    <ButtonBar
      onChange={(value: string) => {
        if (isEnumValue(ProgramToggleValue, value)) {
          onChange(value);
          sendSearchAnalytics(searchEventTypes.SEARCH_TOGGLE, {
            toggleValue: value,
          });
        }
      }}
      options={[
        {
          value: ProgramToggleValue.PROJECTS,
          label: `Projects (${formatNumber(counts.projects)})`,
        },
        {
          value: ProgramToggleValue.PROGRAMS,
          label: `Programs (${formatNumber(counts.programs)})`,
        },
      ]}
      value={value}
    />
  );
}
