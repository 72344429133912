import { Bookmark as BookmarkIcon } from '@material-ui/icons';

export const getNoteTooltip = (canExpandVarianceReportNotesColumn = false): React.ReactNode => {
  return canExpandVarianceReportNotesColumn ? (
    'Open Notes'
  ) : (
    <span>
      Save the report with{' '}
      <BookmarkIcon style={{ height: 14, width: 12, verticalAlign: 'middle' }} /> above to add Notes
    </span>
  );
};
