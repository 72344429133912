import { useMutation } from '@apollo/client';

import {
  SendEmailVerificationMutation,
  SendEmailVerificationMutationVariables,
  VerifyEmailMutation,
  VerifyEmailMutationVariables,
} from '../../../../../generated/graphql';
import { sendEmailVerificationMutation, verifyEmailMutation } from '../../../hooks/queries';

export const useSendEmailVerificationMutation = (url: string, onSuccess?: () => void) => {
  const [sendEmailFunc] = useMutation<
    SendEmailVerificationMutation,
    SendEmailVerificationMutationVariables
  >(sendEmailVerificationMutation);
  return () =>
    sendEmailFunc({
      variables: { url },
    }).then(onSuccess);
};

export const useVerifyEmailMutation = () => {
  const [verifyEmailAddress, mutationResult] = useMutation<
    VerifyEmailMutation,
    VerifyEmailMutationVariables
  >(verifyEmailMutation);

  const verifyEmail = (emailVerify: string) =>
    verifyEmailAddress({
      variables: { emailVerify },
    });
  return { verifyEmail, mutationResult };
};
