import { FC, useState } from 'react';

import { getOperationName } from '@apollo/client/utilities';
import { DeleteOutline, MoreVert } from '@material-ui/icons';

import { REFETCH_DEPRECATE_ITEM } from '../../../api/refetchSets';
import { itemHistoryQuery } from '../../Events/EventsItemHistoryList/hooks/queries';
import { BabyMenuButton, Button, Dialog, DialogContent } from '../../scales';
import { useDeleteItemEstimate } from '../hooks/useDeleteItemEstimate';

type Props = {
  costMode: CostMode;
  itemID: UUID;
  refetch: (args: { id: UUID; costMode: CostMode }) => void;
};

const ItemEstimateCollapseMenu: FC<Props> = ({ costMode, itemID, refetch }) => {
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const [deleteItemEstimate] = useDeleteItemEstimate({
    variables: { input: { itemID } },
    onCompleted: () => {
      setIsDeleteDialogOpen(false);
      refetch({ id: itemID, costMode });
    },
    refetchQueries: [getOperationName(itemHistoryQuery)!, ...REFETCH_DEPRECATE_ITEM],
  });

  return (
    <>
      <BabyMenuButton
        aria-label="cost-impact-menu"
        entries={[
          {
            id: 'delete',
            label: 'Delete Cost Impact',
            onClick: () => setIsDeleteDialogOpen(true),
            startAdornment: <DeleteOutline />,
            type: 'destructive',
          },
        ]}
        icon={<MoreVert />}
      />
      <Dialog
        footerRight={
          <Button
            label="Delete"
            onClick={() => deleteItemEstimate()}
            startIcon={<DeleteOutline />}
            type="destructive"
          />
        }
        isOpen={isDeleteDialogOpen}
        onClose={() => setIsDeleteDialogOpen(false)}
        title="Delete Cost Impact"
      >
        <DialogContent>
          <p>
            Are you sure? You are deleting the Estimate and Contingency & Allowance Draw for this
            item.
          </p>
          <br />
          <p>This is a permanent action and cannot be undone.</p>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ItemEstimateCollapseMenu;
