import { ReactNode } from 'react';

import { formatCost } from '../../../../utilities/currency';
import { ContingenciesTimeSeries } from '../../../dragon-scales/TimelineCharts/InsightsContingencies/types';
import { pendingAllowances, pendingContingencies } from '../../InsightsIcons';
import { calculateDrawPercentage } from '../../utils';

const costFormat = { short: true, showCurrencySymbol: false };

type Props = {
  data: ContingenciesTimeSeries;
  hasAllowance: boolean;
  hasContingency: boolean;
};

export default function ContingenciesTooltipContent(props: Props) {
  const data = props.data;
  return (
    <div className="flex flex-col gap-2">
      <b className="type-body2">Trends on {props.data.date.toLocaleDateString()}</b>
      <div className="flex max-w-max flex-col gap-2 text-left type-table-text">
        {/* Contingency */}
        {props.hasContingency && (
          <>
            <CAndARow
              icon={<div className="h-3 w-3 self-center bg-entities-contingencies" />}
              label="Contingencies"
              percent={calculateDrawPercentage(data.contingencyRemaining, data.contingencyTotal)}
              value={formatCost(data.contingencyRemaining, costFormat)}
            />
            <CAndARow
              icon={pendingContingencies}
              label="Pending draws"
              value={formatCost(data.contingencyPending, costFormat)}
            />
          </>
        )}
        {/* Allowances */}
        {props.hasAllowance && (
          <>
            <CAndARow
              icon={<div className="h-3 w-3 self-center bg-entities-allowances" />}
              label="Allowance"
              percent={calculateDrawPercentage(data.allowanceRemaining, data.allowanceTotal)}
              value={formatCost(data.allowanceRemaining, costFormat)}
            />
            <CAndARow
              icon={pendingAllowances}
              label="Pending draws"
              value={formatCost(data.allowancePending, costFormat)}
            />
          </>
        )}
      </div>
    </div>
  );
}

type CAndARowProps = {
  icon: ReactNode;
  label: string;
  value: string | number;
  percent?: string;
};

export function CAndARow(props: CAndARowProps) {
  return (
    <div className="flex items-center justify-between gap-1 type-table-text">
      <div className="flex">
        <div className="flex w-8 items-center justify-center text-right">{props.icon}</div>
        <div className="line-clamp-1 w-[150px]">{props.label}</div>
        <div className="w-12 text-right">{props.value}</div>
        {props.percent && <div className="w-12 text-right">{props.percent}</div>}
      </div>
    </div>
  );
}
