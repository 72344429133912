export enum GlobalSearchMode {
  ALL_PROJECTS = 'All Projects',
  ALL_ITEMS = 'All Items',
  PROJECT_ITEMS = 'Items in this Project',
}

export const getRecentSearchesKey = (searchMode: GlobalSearchMode, project?: string) =>
  `GLOBAL_SEARCH_HISTORY_${searchMode}${
    searchMode === GlobalSearchMode.PROJECT_ITEMS && project ? `_${project}` : ''
  }`
    .toUpperCase()
    .replaceAll(' ', '_');
