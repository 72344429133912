import { ItemDataQuery } from '../../../generated/graphql';
import useSendAnalytics from '../../../hooks/useSendAnalytics';
import { checkCostModeIncludesMarkups, useCostMode } from '../../../utilities/costMode';
import { getItemCost } from '../../../utilities/items';
import { getCostValue } from '../../CostReport/CostReportUtils';
import { GridVariant } from '../../JoinGrid/types';
import useMemoWrapper from '../../useMemoWrapper';
import CostEstimate from '../CostEstimate';

import EstimateTotal from './EstimateTotal';

type Props = {
  estimateHandle: string;
  item: ItemDataQuery['item'];
  onItemMutated?: () => void;
  projectID: UUID;
};
export default function EstimateAccordionItemContent(props: Props) {
  const { activeEstimate, categories, id } = props.item || {};
  const costMode = useCostMode(props.projectID);
  const sendAnalytics = useSendAnalytics();
  const { parent } = (props.item as Option) || {};
  const isOption = !!parent;
  const cost = useMemoWrapper(getItemCost, props.item);

  const { estimateHandle } = props;
  return (
    <>
      <CostEstimate
        activeEstimateID={activeEstimate?.id}
        canViewDetails
        costOfConstruction={getCostValue(cost)}
        isOption={isOption}
        itemID={id}
        itemTrade={categories}
        onItemMutated={props.onItemMutated}
        projectID={props.projectID}
        sendAnalytics={sendAnalytics}
        variant={GridVariant.ITEM_FULLSCREEN}
      />
      <EstimateTotal
        boldTopBorder
        color="Blue"
        cost={cost as Cost}
        header={`${estimateHandle} (${
          checkCostModeIncludesMarkups(costMode) ? 'Including Markups' : 'Excluding Markups'
        })`}
        topBorder
      />
    </>
  );
}
