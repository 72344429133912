import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../theme/komodo-mui-theme';

const CheckboxContainerStyles = (theme: KomodoTheme) =>
  createStyles({
    checkbox: {
      '&:focus': {
        outline: theme.border.focusLink,
      },
      '&:hover': {
        outline: 0,
      },
      borderRadius: theme.border.radius,
      cursor: 'pointer',
      width: 48,
      height: 18,
    },
  });

export default CheckboxContainerStyles;
