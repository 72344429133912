import { CheckboxVariant } from '../../shared-widgets/CheckboxIndeterminateContainer';

export const filterEnabledSelectableEntries = (
  entries: { id: string }[],
  selectableIndices: boolean[],
  disabledIndices: boolean[]
) =>
  entries
    .map(({ id }) => id)
    .filter((_, index) => selectableIndices[index] && !disabledIndices[index]);

export const isSomeSelected = (selectedIndices: boolean[]) =>
  !!selectedIndices.length && selectedIndices.some((v) => v);

export const isAllSelected = (
  selectedIndices: boolean[],
  selectableIndices: boolean[],
  disabledIndices: boolean[]
) =>
  isSomeSelected(selectedIndices) &&
  selectableIndices.every(
    (selectable, index) => !selectable || disabledIndices[index] || selectedIndices[index]
  );

export const getCheckboxVariant = (
  selectedIndices: boolean[],
  selectableIndices: boolean[],
  disabledIndices: boolean[]
) => {
  if (isAllSelected(selectedIndices, selectableIndices, disabledIndices))
    return CheckboxVariant.Selected;
  if (isSomeSelected(selectedIndices)) return CheckboxVariant.Indeterminate;
  return CheckboxVariant.Empty;
};
