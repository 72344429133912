import * as React from 'react';

import { withStyles } from '@material-ui/core/styles';
import Tooltip, { TooltipProps } from '@material-ui/core/Tooltip';

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
export const tooltipStyles = (theme: any) => {
  return {
    maxWidth: 120,
    padding: 4,
    background: theme.palette.common.white,
    color: theme.palette.chartBlack,
    fontSize: parseInt(theme.typography.chart.fontSize, 10),
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: theme.palette.red,
  };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
const ErrorTooltip: React.ComponentType<TooltipProps> = withStyles((theme: any) => ({
  tooltip: {
    ...tooltipStyles(theme),
  },
}))(Tooltip);

export default ErrorTooltip;
