import ItemStatusDisplay from '../../../../common/ItemStatus/ItemStatusDisplay';
import { ScenarioItemEntry } from '../../../../common/ItemStatus/ItemStatusUtils';

type Props = {
  item: ScenarioItemEntry;
};

export default function StatusCellDisplay(props: Props) {
  return (
    <div className="flex h-8 items-center gap-1 type-table-text">
      <ItemStatusDisplay status={props.item.status} />
    </div>
  );
}
