import { RefObject, useRef } from 'react';
import { useDrop } from 'react-aria';

import { isNonNullable } from '../../../../utilities/types';
import { getLimitDropOperation } from '../../common/DropTargetUtils';

export const ITEM_ID_DROP_TYPE = 'item-id';

type Props = {
  children: (args: {
    dropProps: ReturnType<typeof useDrop>['dropProps'] & { ref: RefObject<HTMLDivElement> };
    isDropTarget: boolean;
  }) => JSX.Element;
  onItemDrop: (itemID: UUID) => void;
};
export default function DropTargetItems(props: Props) {
  const ref = useRef<HTMLDivElement>(null);
  const getDropOperation = getLimitDropOperation(ITEM_ID_DROP_TYPE);
  const { dropProps, isDropTarget } = useDrop({
    ref,
    getDropOperation,
    async onDrop(e) {
      const droppedItems = await Promise.all(
        e.items.map(async (item) => {
          if (item.kind === 'text' && item.types.has(ITEM_ID_DROP_TYPE)) {
            return item.getText(ITEM_ID_DROP_TYPE);
          }

          return undefined;
        })
      );

      const itemIds = droppedItems.filter(isNonNullable);
      itemIds.forEach((itemId) => props.onItemDrop?.(itemId));
    },
  });

  return props.children({
    dropProps: {
      ...dropProps,
      ref,
      role: 'button',
      tabIndex: 0,
    },
    isDropTarget,
  });
}
