import { Org } from '../../../generated/graphql';
import {
  ProjectFilterManager,
  ProjectFilterSetSettings,
} from '../../ProjectsList/ProjectsListUtils';

import InsightsFilterPanel from './InsightsFilterPanel';
import InsightsFilterSummaryText from './InsightsFilterSummaryText';

type Props = {
  filterManager: ProjectFilterManager;
  orgs: Org[];
  setSettings: ProjectFilterSetSettings;
};

export default function InsightsFilterMenu(props: Props) {
  return (
    <>
      <div className="flex items-center gap-3">
        <InsightsFilterSummaryText
          filterManager={props.filterManager}
          orgs={props.orgs || []}
          setSettings={props.setSettings}
        />
        <div className="flex">
          <InsightsFilterPanel
            filterManager={props.filterManager}
            setSettings={props.setSettings}
          />
        </div>
      </div>
    </>
  );
}
