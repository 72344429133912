import { createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
export default (theme: any) =>
  createStyles({
    container: {
      display: 'flex',
    },
    root: {
      color: theme.palette.primaryGrey,
      position: 'relative',
      pageBreakInside: 'avoid',
      padding: '0px 0px 8px 8px',
      flex: '1 1 100%',
    },
    header: {
      display: 'flex',
      flexDirection: 'row',
      padding: '8px 0px 0px 6px',
      cursor: 'pointer',
      alignItems: 'center',
      position: 'relative',
      pageBreakInside: 'avoid',
      outline: 'none !important',
    },
    itemHeader: {
      backgroundColor: theme.palette.joinGrey200,
      border: theme.border.darkGrey,
      borderBottom: '0px none',
    },
    categoryHeader: {
      backgroundColor: `${theme.palette.joinGrey400} !important`,
      // these help us overlap neighbors:
      outline: theme.border.darkGrey,
      marginTop: 1,
    },
    containedItems: {
      whiteSpace: 'nowrap',
      display: 'flex',
      alignSelf: 'center',
    },
    overflow: {
      overflow: 'visible',
    },
    spacer: {
      width: 8,
    },
    sidebarItem: {
      backgroundColor: theme.palette.backgroundGreen,
    },
    simpleItem: {
      backgroundColor: theme.palette.nestingGrey,
    },
    title: {
      marginRight: 11,
      paddingLeft: 5,
    },
    rowTitleInner: {
      display: 'flex',
      flexWrap: 'wrap',
      width: '100%',
      paddingTop: 2,
    },
  });
