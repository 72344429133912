import { COLUMN_WIDTH } from '../../Scenarios/Sandbox/Presentation/PresentationColumn';
import { Scenario, ScenarioMilestone } from '../../Scenarios/types';

export const NUM_SCENARIOS_PER_PAGE = 3;
const COLUMN_GAP = 20;
const MARGIN = 8;

export const printScenarioWidth =
  NUM_SCENARIOS_PER_PAGE * COLUMN_WIDTH + (NUM_SCENARIOS_PER_PAGE - 1) * COLUMN_GAP + 2 * MARGIN;

type ColumnGroup = (Scenario | ScenarioMilestone)[];

export const getColumnGroups = (
  scenarios: Scenario[],
  milestone: ScenarioMilestone,
  showMilestone: boolean
) => {
  const groups: ColumnGroup[] = [];
  const allColumns: ColumnGroup = showMilestone ? [milestone, ...scenarios] : [...scenarios];
  while (allColumns.length > 0) {
    const group = allColumns.splice(0, NUM_SCENARIOS_PER_PAGE);
    groups.push(group);
  }
  return groups;
};
