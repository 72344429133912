import { useEffect } from 'react';

import { useApolloClient } from '@apollo/client';

import {
  readMilestoneAttachments,
  writeMilestoneAttachments,
} from './useMilestoneAttachmentsQuery';
import { uploadingAssetToAsset } from './useOptimisticUploadAssets';

const useOptimisticMilestoneUploadAssets = (
  milestoneID: UUID,
  uploadingAssets: UploadingAsset[],
  isAttach: boolean
) => {
  const client = useApolloClient();
  useEffect(() => {
    if (!isAttach) return;
    if (!uploadingAssets.length) return;
    const assets = readMilestoneAttachments(client, milestoneID);
    const uploading = uploadingAssets.map(uploadingAssetToAsset);
    const uploadingFiltered = uploading.filter((ua) => assets.every((a) => a.id !== ua.id));
    if (!uploadingFiltered.length) return;
    const optimistic = [...assets, ...uploadingFiltered];
    writeMilestoneAttachments(client, optimistic, milestoneID);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO CT-566: Fix this pls :)
  }, [uploadingAssets]);
};

export default useOptimisticMilestoneUploadAssets;
