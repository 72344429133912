import { CREATE_CATEGORY } from '../../../actions/actionTypes';
import { EventProperties, analyticsEvent } from '../../../analytics/analyticsEventProperties';
import joinAPI from '../../../api/joinAPI';
import {
  AcceptImportEstimateErrorMutation,
  AcceptImportEstimateErrorMutationVariables,
  CreateCategoriesMutation,
  CreateCategoriesMutationVariables,
  DeleteCategoryMutation,
  DeleteCategoryMutationVariables,
  EditCategoriesMutation,
  EditCategoriesMutationVariables,
} from '../../../generated/graphql';

import {
  acceptImportEstimateErrorMutation,
  createCategoriesMutation,
  deleteCategoryMutation,
  editCategoriesMutation,
} from './queries';

type EditCategoryMutationResult = {
  data?: EditCategoriesMutation;
};

export const editCategories = (
  categorizationID: UUID,
  categories: EditCategoryInput[],
  projectID?: UUID,
  onSuccess?: (result: EditCategoryMutationResult) => void
) => {
  if (categories.length > 0) {
    const variables: EditCategoriesMutationVariables = {
      projectID,
      categorizationID,
      categories,
    };
    joinAPI
      .requestGraphQL(editCategoriesMutation, variables)
      .then((r: EditCategoryMutationResult) => onSuccess && onSuccess(r));
  }
};

type CreateCategoriesMutationResult = {
  data?: CreateCategoriesMutation;
};

export const createCategories = (
  categorizationID: UUID,
  categories: CategoryContentInput[],
  sendAnalytics?: (analyticsEvent: { type: string; eventProperties: EventProperties }) => void,
  projectID?: UUID,
  onSuccess?: (result: CreateCategoriesMutationResult) => void
) => {
  if (!(categories.length > 0)) return;
  const variables: CreateCategoriesMutationVariables = {
    projectID,
    categorizationID,
    categories,
  };
  joinAPI
    .requestGraphQL(createCategoriesMutation, variables)
    .then((r: CreateCategoriesMutationResult) => {
      sendAnalytics?.(analyticsEvent(CREATE_CATEGORY));
      if (onSuccess) onSuccess(r);
    });
};

type DeleteCategoriesMutationResult = {
  data?: DeleteCategoryMutation;
};

// TODO: batch
export const deleteCategory = (
  id: UUID,
  categorizationID: UUID,
  projectID?: UUID,
  onSuccess?: (result: DeleteCategoriesMutationResult) => void
) => {
  const variables: DeleteCategoryMutationVariables = {
    projectID,
    category: {
      id,
      categorizationID,
    },
  };
  joinAPI
    .requestGraphQL(deleteCategoryMutation, variables)
    .then((r: DeleteCategoriesMutationResult) => onSuccess && onSuccess(r));
};

type AcceptImportEstimateErrorResult = {
  data?: AcceptImportEstimateErrorMutation;
};

export const acceptImportEstimateError = (
  projectID: UUID,
  estimateID: UUID | undefined,
  errorID: UUID,
  onSuccess?: (result: AcceptImportEstimateErrorResult) => void
) => {
  if (!estimateID) return;
  const variables: AcceptImportEstimateErrorMutationVariables = {
    projectID,
    estimateID,
    errorID,
  };
  joinAPI
    .requestGraphQL(acceptImportEstimateErrorMutation, variables)
    .then((r: AcceptImportEstimateErrorResult) => onSuccess && onSuccess(r));
};
