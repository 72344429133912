import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../../theme/komodo-mui-theme';
import { printArea } from '../../../Charts/ChartsEstimate/ChartsEstimateStyles';
import { CHART_AREA_HEIGHT } from '../../../Charts/ChartsUtils';

export default (theme: KomodoTheme) =>
  createStyles({
    root: {
      paddingTop: 16,
      pageBreakInside: 'avoid',
    },
    itemArea: {
      minHeight: CHART_AREA_HEIGHT,
    },
    printArea,
    formControl: {
      alignSelf: 'baseline',
      minWidth: 120,
      paddingTop: 4,
    },
    header: {
      display: 'flex',
      paddingBottom: 12,
    },
    item: {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
    label: {
      fontSize: 10,
    },
    filterCTA: {
      color: theme.palette.primaryBlue,
      cursor: 'pointer',
      paddingTop: 24,
      paddingLeft: 24,
    },
    flexMenu: {
      display: 'flex',
      height: 64,
    },
    flexMessages: {
      flexGrow: 1,
    },
    topBar: {
      alignItems: 'center',
      flexGrow: 1,
      '@media print': {
        borderBottom: theme.border.line,
      },
      [theme.breakpoints.down('sm')]: {
        flexWrap: 'wrap',
      },
    },
    printChartTitle: {
      fontFamily: theme.typography.fontFamily,
      fontSize: 16,
      fontWeight: 500,
      lineHeight: '144%',
      letterSpacing: '0.0119em',
      width: '100%',
    },
    printChartSetting: {
      fontFamily: theme.typography.fontFamily,
      fontSize: 12,
      paddingTop: 8,
      letterSpacing: '0.0119em',
    },
    trendSelect: {
      height: 33,
    },
  });
