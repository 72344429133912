import { useCallback, useEffect, useMemo } from 'react';

import { useReactiveVar } from '@apollo/client';
import { LinearProgress } from '@material-ui/core';
import { Https } from '@material-ui/icons';

import { itemSidebarScenariosOpenVar, usePreviewSettingsVar } from '../../api/apollo/reactiveVars';
import { JoinProjectRoutes } from '../../api/gqlEnums';
import { ItemsSortKey, SortDirection } from '../../generated/graphql';
import useProjectPropsQuery from '../../hooks/useProjectPropsQuery';
import { generateSharedPath } from '../../utilities/routes/links';
import { getProjectIdFromUrl } from '../../utilities/url';
import { useLocalStorageParams } from '../../utilities/urlState';
import { useItemQuery } from '../Items/hooks/useItemQuery';

import useLoadOrCreateScenarioSandboxByMilestoneIDQuery from './hooks/useLoadOrCreateScenarioSandboxQuery';
import { useRemoveScenarioFromSandbox } from './hooks/useRemoveScenario';
import useSendScenariosAnalytics, {
  ScenariosAnalyticsEvents,
} from './hooks/useSendScenariosAnalytics';
import Sandbox from './Sandbox';
import SandboxPresentationView from './Sandbox/Presentation/SandboxPresentationView';
import { getChartPropsFromSandbox } from './Sandbox/Presentation/ScenariosChart/ScenariosChartDataUtils';
import { SandboxView, isWorkspaceView, sandboxKeyHandler } from './Sandbox/SandboxUtil';
import ScenariosItemSidebarWrapper from './Sandbox/ScenariosItemSidebarWrapper';
import { ScenariosHeader } from './ScenariosHeader';
import {
  FILES_SCENARIO_DEFAULT_SORT,
  getScenariosLocalStorageLocation,
  useGetMilestoneNameFromID,
} from './ScenarioUtils';

export type ScenariosSettings = {
  milestoneID: string;
  showMilestone: boolean;
  sortDirection: string;
  sortKey: string;
  sandboxView: SandboxView;
};

const Scenarios = () => {
  // Data
  const sendScenariosAnalytics = useSendScenariosAnalytics();
  const projectId = getProjectIdFromUrl();
  const { project } = useProjectPropsQuery(projectId).data;
  const projectName = project?.name;
  const activeMilestoneID = project?.activeMilestone.id;
  const preview = usePreviewSettingsVar();

  // State
  const [settings, setSettings] = useLocalStorageParams<ScenariosSettings>(
    {
      milestoneID: '',
      showMilestone: true,
      sortDirection: FILES_SCENARIO_DEFAULT_SORT.sortDirection,
      sortKey: FILES_SCENARIO_DEFAULT_SORT.sortKey,
      sandboxView: SandboxView.SANDBOX_VIEW_WORK,
    },
    getScenariosLocalStorageLocation(projectId, '')
  );
  const { milestoneID, showMilestone, sandboxView } = settings;
  const { id: sidebarItemId } = useReactiveVar(itemSidebarScenariosOpenVar);

  // Key Listener for Print + Sidebar
  useEffect(() => {
    const printFn = () => {
      sendScenariosAnalytics(ScenariosAnalyticsEvents.PRINT_SANDBOX, {
        milestoneID,
      });
      window.open(generateSharedPath(JoinProjectRoutes.PRINT_SCENARIOS, { projectId }), '_blank');
    };
    const keyHandler = sandboxKeyHandler(sidebarItemId, printFn);
    document.addEventListener('keydown', keyHandler);
    return () => {
      document.removeEventListener('keydown', keyHandler);
    };
  }, [sidebarItemId, sendScenariosAnalytics, milestoneID, projectId]);

  // Item
  const {
    data: itemData,
    loading: loadingItem,
    previousData: previousItemData,
    refetch: refetchItem,
  } = useItemQuery(sidebarItemId);
  const scenarioItem = itemData?.item ?? previousItemData?.item;

  // Functions
  // TODO: Memoize useLocalStorageParams or in FLYW-1134
  // to get the value of all the below memos

  const setMilestoneID = useCallback(
    (milestoneID: string | null) => {
      setSettings({ ...settings, milestoneID: milestoneID ?? '' });
      sendScenariosAnalytics(ScenariosAnalyticsEvents.SET_MILESTONE, {
        milestoneID,
        isActiveMilestone: milestoneID === activeMilestoneID,
      });
    },
    [setSettings, settings, sendScenariosAnalytics, activeMilestoneID]
  );

  const toggleMilestone = useCallback(
    (showMilestone: boolean) => {
      sendScenariosAnalytics(ScenariosAnalyticsEvents.TOGGLE_MILESTONE_VISIBILITY, {
        showMilestone,
      });

      const newSettings = { ...settings, showMilestone };
      setSettings(newSettings);
    },
    [sendScenariosAnalytics, settings, setSettings]
  );

  const setSandboxView = useCallback(
    (sandboxView: SandboxView) => {
      const newSettings = { ...settings, sandboxView };
      setSettings(newSettings);
    },
    [settings, setSettings]
  );

  // Sort
  const sortState = useMemo(
    () => ({
      sortDirection: SortDirection[settings.sortDirection as keyof typeof SortDirection],
      sortKey: ItemsSortKey[settings.sortKey as keyof typeof ItemsSortKey],
    }),
    [settings]
  );
  const sortManager = useMemo(() => {
    const setSort = (sortState: SortBy) => {
      sendScenariosAnalytics(ScenariosAnalyticsEvents.SORT_ITEMS, {
        ...sortState,
        milestoneID,
      });
      setSettings({
        ...settings,
        ...sortState,
      });
    };
    return { sortState, setSort };
  }, [milestoneID, sendScenariosAnalytics, setSettings, sortState, settings]);

  // Mutations
  const [removeScenarioFromSandbox] = useRemoveScenarioFromSandbox();

  // Queries
  useEffect(() => {
    if (!milestoneID && activeMilestoneID) setMilestoneID(activeMilestoneID);
  }, [milestoneID, activeMilestoneID, setMilestoneID]);
  const { data, loading, previousData } = useLoadOrCreateScenarioSandboxByMilestoneIDQuery(
    sortState,
    milestoneID || undefined
  );
  const sandbox = loading
    ? previousData?.loadOrCreateScenarioSandboxByMilestoneID
    : data?.loadOrCreateScenarioSandboxByMilestoneID;
  const milestone = sandbox?.milestone;
  const { milestoneName, milestonesData } = useGetMilestoneNameFromID(projectId, milestoneID);
  const milestones = milestonesData?.milestones;

  // Title the page for better clarity
  useEffect(() => {
    if (projectName) document.title = `${projectName} - Scenarios - ${milestoneName}`;
  }, [milestoneName, projectName]);

  const budget = useMemo(
    () => (sandbox ? getChartPropsFromSandbox(sandbox, milestoneName).budget : 0),
    [milestoneName, sandbox]
  );
  const hasBudget = budget > 0;

  if (preview.userID || preview.roleID) {
    return (
      <div className="flex h-full flex-col items-center justify-center">
        <div className="mt-28 flex max-w-3xl flex-col gap-2 rounded border bg-background-1">
          <div className="flex items-center gap-2 p-6 type-heading1">
            <Https />
            {`A user's Scenarios Sandbox is private`}
          </div>
          <div className="border-t p-6 type-body1">
            Scenarios and their sandbox are unique to each Join project user.
            <br />
            {`The ability to apply a scenario reflects the user's permission to create or edit Items.`}
          </div>
        </div>
      </div>
    );
  }

  return (
    // overflow-hidden to force the children to scroll themselves. The Sandbox's sidebar and
    // scenario cards will scroll independently, so we need to set it up on them.
    <div className="flex h-full overflow-hidden">
      <div className="flex flex-grow flex-col overflow-hidden bg-background-primary">
        <div className="h-1 flex-shrink-0">{loading && <LinearProgress />}</div>
        <ScenariosHeader
          hasBudget={hasBudget}
          milestones={milestones}
          onChangeMilestone={setMilestoneID}
          onToggleShowMilestone={toggleMilestone}
          sandboxView={sandboxView}
          selectedMilestoneID={milestoneID ?? undefined}
          setSandboxView={setSandboxView}
          showMilestone={showMilestone}
        />
        {sandbox &&
          milestone &&
          (isWorkspaceView(sandboxView) ? (
            <Sandbox
              activeMilestoneID={activeMilestoneID}
              milestone={milestone}
              milestoneName={milestoneName}
              onRemoveScenario={removeScenarioFromSandbox}
              sandbox={sandbox}
              showMilestone={showMilestone}
              sortManager={sortManager}
            />
          ) : (
            <SandboxPresentationView
              milestoneName={milestoneName}
              sandbox={sandbox}
              showMilestone={showMilestone}
              sortManager={sortManager}
            />
          ))}
      </div>
      {sidebarItemId && (
        <ScenariosItemSidebarWrapper
          item={scenarioItem as ItemLike}
          loading={loadingItem}
          refetchItem={refetchItem} // TODO: Remove this in favor of refetchSet
          setItemID={(id: string, color: string) => itemSidebarScenariosOpenVar({ id, color })}
        />
      )}
    </div>
  );
};

export default Scenarios;
