import { useCallback, useState } from 'react';
import { useLocalStorage as useRULS } from 'react-use';

/**
 * An updated version of react-use's useLocalStorage that works with updater signatures.
 *
 * Ie, it works around https://github.com/streamich/react-use/issues/2512
 */
export default function useLocalStorage<T>(
  key: string,
  initialValue: T,
  options?: Parameters<typeof useRULS<T>>[2]
) {
  const [localStorageValue, setLocalStorageValue, removeLocalStorageValue] = useRULS<T>(
    key,
    initialValue,
    options
  );
  const [localValue, setLocalValue] = useState(localStorageValue);

  const setValue: typeof setLocalStorageValue = useCallback(
    (update) => {
      setLocalValue(update);
      setLocalStorageValue(update);
    },
    [setLocalStorageValue]
  );

  return [localValue ?? initialValue, setValue, removeLocalStorageValue] as const;
}
