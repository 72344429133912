import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';

export default (theme: KomodoTheme) =>
  createStyles({
    currentMilestone: {
      padding: `9px 6px 8px ${theme.spacing.selectPaddingLeft}px`,
      textAlign: 'left',
      wordBreak: 'break-word',
    },
    currentMilestoneEdit: {
      ...theme.arrowIcon,
      alignItems: 'center',
      display: 'flex',
      height: 24,
      width: 24,
    },
    currentMilestoneEditIcon: {
      '@media print': {
        display: 'none',
      },
      fontSize: 16,
      margin: 'auto',
    },
    currentMilestoneSelector: {
      '@media print': {
        border: 'none',
        padding: 0,
      },
      alignItems: 'center',
      alignSelf: 'flex-start',
      background: theme.palette.backgroundWhite,
      border: theme.border.line,
      borderRadius: theme.border.radius,
      cursor: 'pointer',
      display: 'flex',
      justifyContent: 'space-between',
      margin: 0,
      padding: '0 5px 0 0',
      width: '100%',
      '&:focus': {
        border: theme.border.focus,
        outline: theme.border.focus,
      },
    },
    currentMilestoneSelectorDisabled: {
      backgroundColor: 'unset',
      border: 0,
      cursor: 'unset',
      padding: 8,
      paddingLeft: 0,
    },
    currentMilestoneViewOnly: {
      color: theme.palette.primaryGrey,
      flexWrap: 'nowrap',
      maxWidth: 180,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  });
