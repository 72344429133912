import { gql } from '../../../../api/graphqlFragments';

export const commonSourceHubFields = gql`
  fragment commonSourceHubFields on SourceHub {
    id
    name
    remoteLink
    sourceUpdatedAt
  }
`;

export const commonSourceProjectFields = gql`
  fragment commonSourceProjectFields on SourceProject {
    id
    name
    remoteLink
    sourceUpdatedAt
  }
`;

export const commonSourceFolderFields = gql`
  fragment commonSourceFolderFields on SourceFolder {
    id
    name
    remoteLink
    sourceUpdatedAt
    # TODO - add'l metadata fields
  }
`;

export const commonSourceAssetFields = gql`
  fragment commonSourceAssetFields on SourceAsset {
    id
    name
    remoteLink
    sourceUpdatedAt
    # TODO - add'l metadata fields
  }
`;

export const commonSourceEntityFields = gql`
  fragment commonSourceEntityFields on SourceEntity {
    ... on SourceAsset {
      ...commonSourceAssetFields
    }
    ... on SourceFolder {
      ...commonSourceFolderFields
    }
  }
  ${commonSourceAssetFields}
  ${commonSourceFolderFields}
`;

export const loadSourceHubRoot = gql`
  query loadSourceHubRoot($sourceSystem: SourceSystem!, $sourceID: UUID!) {
    sourceHubRoot(sourceSystem: $sourceSystem, sourceID: $sourceID) {
      hubs {
        ...commonSourceHubFields
      }
      sourceError
    }
  }
  ${commonSourceHubFields}
`;

export const loadSourceHubProjects = gql`
  query loadSourceHubProjects($sourceSystem: SourceSystem!, $sourceID: UUID!, $hubID: String!) {
    sourceHubProjects(sourceSystem: $sourceSystem, sourceID: $sourceID, hubID: $hubID) {
      projects {
        ...commonSourceProjectFields
      }
      sourceError
    }
  }
  ${commonSourceProjectFields}
`;

export const loadSourceProjectFolders = gql`
  query loadSourceProjectFolders(
    $sourceSystem: SourceSystem!
    $sourceID: UUID!
    $hubID: String!
    $projectID: String!
  ) {
    sourceProjectFolders(
      sourceSystem: $sourceSystem
      sourceID: $sourceID
      hubID: $hubID
      projectID: $projectID
    ) {
      folders {
        ...commonSourceFolderFields
      }
      sourceError
    }
  }
  ${commonSourceFolderFields}
`;

export const loadSourceFolderRoot = gql`
  query loadSourceFolderRoot(
    $sourceSystem: SourceSystem!
    $sourceID: UUID!
    $sortBy: FilesSortBy!
  ) {
    sourceFolderRoot(sourceSystem: $sourceSystem, sourceID: $sourceID, sortBy: $sortBy) {
      entities {
        ...commonSourceEntityFields
      }
    }
  }
  ${commonSourceEntityFields}
`;

export const loadSourceFolderContents = gql`
  query loadSourceFolderContents(
    $sourceSystem: SourceSystem!
    $sourceID: UUID!
    $projectID: String
    $folderID: String
    $hubID: String
    $sortBy: FilesSortBy!
  ) {
    sourceFolderContents(
      sourceSystem: $sourceSystem
      sourceID: $sourceID
      projectID: $projectID
      folderID: $folderID
      hubID: $hubID
      sortBy: $sortBy
    ) {
      entities {
        ...commonSourceEntityFields
      }
      sourceError
    }
  }
  ${commonSourceEntityFields}
`;
