import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';

const styles = (theme: KomodoTheme) =>
  createStyles({
    borderTop: {
      borderTop: theme.border.line,
    },
    listItem: {
      height: 62,
      padding: 8,
      justifyContent: 'space-between',
    },
    name: {
      flex: '1 1 auto',
      display: 'flex',
      paddingLeft: 8,
      flexDirection: 'column',
      justifyContent: 'flex-start',
    },
    text: {
      lineHeight: '0.9rem',
    },
    unit: {
      flex: '0 0 auto',
    },
  });

export default styles;
