import { createStyles } from '@material-ui/core';

import { CHART_PADDING } from '../ExecutiveDashboardUtils';

const ExecutiveDashboardDecisionSummaryStyles = () =>
  createStyles({
    flexContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: CHART_PADDING,
    },
    iconContainer: {
      display: 'flex',
      justifyContent: 'center',
    },
  });

export default ExecutiveDashboardDecisionSummaryStyles;
