import { ArrowRightAlt } from '@material-ui/icons';

import { MetricsInputReturn } from '../../../../../generated/graphql';
import { Callout } from '../../../../scales';
import { getHasMetricValue, getSelectedUnitLabel } from '../../../ProjectCompsSetUtils';
import { CreateEstimateParameters } from '../CreateEstimateDialogFlowUtils';

import { addToEstimateCostFormatter, getActionText, getTermKey } from './ContentsUtils';

type Props = {
  params: CreateEstimateParameters;
  totalCost: Cost;
  isNoCurrentEstimate: boolean;
  currentCost: string;
  metrics: MetricsInputReturn[];
  t: TermStore;
  unit: Unit;
};

export default function ConfirmationContent(props: Props) {
  // State
  const {
    currentCost,
    isNoCurrentEstimate,
    t,
    totalCost,
    params: {
      isNewCategorization,
      displayMilestoneName,
      displayCategorizationName,
      isNewMilestone,
      projectName,
      variables: { type },
    },
  } = props;

  if (!projectName) return null;

  // Content Generation
  const termKey = getTermKey(type);
  const term = t.lowerCase(termKey);
  const text = getActionText({ t, termKey, isNewMilestone, isNoCurrentEstimate });
  const newCost = addToEstimateCostFormatter(totalCost);
  const hasMetricValue = getHasMetricValue(props.unit, props.metrics);
  const quantityMetricString = hasMetricValue && getSelectedUnitLabel(props.unit, props.metrics);

  return (
    <div className="gap-6">
      <Callout isFullWidth>
        <div className="mb-2 type-heading3">Confirm the following before publishing</div>
        <div className="type-body1">
          {text}
          <strong>{displayMilestoneName}</strong>
          <span> within </span>
          <strong>{projectName}</strong>
          <span>.</span>
        </div>
        {hasMetricValue && (
          <div className="type-body1">
            The metric {props.unit.name} for the milestone will be set to{' '}
            <strong>{quantityMetricString}</strong>.
          </div>
        )}
        <div className="type-body1">
          The categorization <strong>{displayCategorizationName}</strong> will be{' '}
          {isNewCategorization ? 'created' : 'used'} in the {term}.
        </div>
      </Callout>
      {totalCost && (
        <div className="flex items-center justify-center gap-6 p-6">
          {!isNewMilestone && !isNoCurrentEstimate && (
            <>
              <div className="flex flex-col">
                <div className="type-heading3">{`Current ${term}`}</div>
                <div>{currentCost}</div>
              </div>
              <ArrowRightAlt />
            </>
          )}
          <div className="flex flex-col">
            <div className="type-heading3">{`New ${term}`}</div>
            <div>{newCost}</div>
          </div>
        </div>
      )}
    </div>
  );
}
