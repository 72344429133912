import { ReactiveVar } from '@apollo/client';

import {
  AnalyticsEvent,
  importEstimatesSelectCategory,
  importEstimatesSelectWBS,
} from '../../analytics/analyticsEventProperties';
import { setReactiveLocal } from '../../api/apollo/reactiveVars';
import { MASTERFORMAT_CATEGORY, UNIFORMAT_CATEGORY } from '../../constants';
import {
  getProjectCategorizationsFromQueryData,
  useProjectCategorizationsQuery,
} from '../../hooks/useProjectCategorizationsQuery';
import { getProjectIdFromUrl } from '../../utilities/url';

export function hasBuiltInMfName(name: string) {
  return MASTERFORMAT_CATEGORY.toLowerCase().startsWith(name.toLowerCase());
}

export function hasBuiltInUfName(name: string) {
  return UNIFORMAT_CATEGORY.toLowerCase().startsWith(name.toLowerCase());
}

export function hasBuiltInName(name: string) {
  return hasBuiltInUfName(name) || hasBuiltInMfName(name);
}

export function getKeyByValue(map: Map<string, string>, value: string | undefined) {
  let key = '';
  map.forEach((v, k) => {
    if (v && v === value) {
      key = k;
    }
  });
  return key;
}

export function getKeyByLevel(map: Map<string, Level>, value: Level | undefined) {
  let key = '';
  map.forEach((v, k) => {
    if (v.name && v.name === value?.name) key = k;
  });
  return key;
}

export function checkMfBuiltInToBuiltInMapping(map: Map<string, string>) {
  let hasBuiltInToBuiltInMapping = false;
  const mf = Object.values(map.keys()).includes(MASTERFORMAT_CATEGORY);
  if (mf) {
    const categorizationNew = getKeyByValue(map, MASTERFORMAT_CATEGORY);
    hasBuiltInToBuiltInMapping = hasBuiltInName(categorizationNew || '');
  }

  return hasBuiltInToBuiltInMapping;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
export function checkUfBuiltInToBuiltInMapping(map: any) {
  let hasBuiltInToBuiltInMapping = false;
  const uf = Object.values(map).includes(UNIFORMAT_CATEGORY);
  if (uf) {
    const categorizationNew = getKeyByValue(map, UNIFORMAT_CATEGORY);
    hasBuiltInToBuiltInMapping = hasBuiltInName(categorizationNew || '');
  }

  return hasBuiltInToBuiltInMapping;
}

export const useBuiltinToggles = () => {
  const projectId = getProjectIdFromUrl();
  const { data, loading } = useProjectCategorizationsQuery(projectId, true);
  const projectCategorizations = getProjectCategorizationsFromQueryData(data);

  const hasNoBuiltIns =
    !loading &&
    !projectCategorizations.some((pc) => !!pc.categorization.builtin && pc.disabled === false);

  const hasMasterFormat = projectCategorizations.some(
    (pc) => !!pc.categorization.builtin && !pc.disabled && hasBuiltInMfName(pc.categorization.name)
  );

  const hasUniFormat = projectCategorizations.some(
    (pc) => !!pc.categorization.builtin && !pc.disabled && hasBuiltInUfName(pc.categorization.name)
  );

  return { hasNoBuiltIns, hasMasterFormat, hasUniFormat, loading };
};

export const getImportModal = (importEstimateState: ImportEstimateParameters) => {
  const { modalIsOpen, modal } = importEstimateState;
  if (!modalIsOpen) {
    return undefined;
  }
  return modal;
};

export const getDraftEstimate = (
  importEstimateVar: ReactiveVar<ImportEstimateParameters>,
  categorizationName: string
): [ImportEstimateParameters, DraftCategorization | undefined] => {
  const importEstimate = importEstimateVar();
  const draft = importEstimate.categorizations.find((c) => c.name === categorizationName);
  return [importEstimate, draft];
};

// we don't support this for multilevel categorizations and the option is disabled
// this is because adding categories to a multilevel categorization requires you to know the parent of the category
export const setCategoryUpdates = (
  keys: string[],
  importEstimateVar: ReactiveVar<ImportEstimateParameters>,
  importEstimateKey: string,
  categorizationName: string
) => {
  const [importEstimate, draft] = getDraftEstimate(importEstimateVar, categorizationName);
  if (!draft) return;
  if (draft.categoryUpdates) {
    // if the user has already added some categores
    // then just add on to the existing list
    draft.categoryUpdates.newCategories.push(...keys);
  } else {
    draft.categoryUpdates = {
      newCategories: keys,
      categoryReplacements: [],
    };
  }
  setReactiveLocal(importEstimateVar, importEstimateKey, {
    ...importEstimate,
  });
};
export const setCategoryReplacement = (
  original: string,
  replacement: { category: Category | null; search: string },
  importEstimateVar: ReactiveVar<ImportEstimateParameters>,
  importEstimateKey: string,
  categorizationName: string
) => {
  const [importEstimate, draft] = getDraftEstimate(importEstimateVar, categorizationName);
  if (!draft || !replacement.category) return;

  const update = {
    original,
    new: replacement.category.number,
  };
  if (draft.categoryUpdates) {
    // if the user has already added some categores
    // then just add on to the existing list
    draft.categoryUpdates.categoryReplacements.push(update);
  } else {
    draft.categoryUpdates = {
      newCategories: [],
      categoryReplacements: [update],
    };
  }
  setReactiveLocal(importEstimateVar, importEstimateKey, {
    ...importEstimate,
  });
};

export const getExistingCategorizationMappings = (
  mapping: Map<string, string>,
  mappingBuiltIn: Map<string, Level>,
  draftCategorizations: DraftCategorization[],
  draftName: string,
  existingCategorizationName: string
) => {
  if (!existingCategorizationName) return [];

  const concurrentMappings: string[] = [];

  const nonBuiltInMapping = mapping.get(draftName);
  if (nonBuiltInMapping) concurrentMappings.push(nonBuiltInMapping);
  mappingBuiltIn.forEach((v, k) => {
    if (v.builtIn === existingCategorizationName) concurrentMappings.push(k);
  });

  return draftCategorizations
    .filter((c) => concurrentMappings.includes(c.name))
    .sort((a, b) => a.level - b.level);
};

function unassignLevel(cats: DraftCategorization[], level: Level) {
  const matchingInput = cats.find(
    (c) => c.level === level.level && c.existingCategorization === level.builtIn
  );
  if (matchingInput) {
    matchingInput.level = 1;
    matchingInput.existingCategorization = '';
    matchingInput.categoryUpdates = { newCategories: [], categoryReplacements: [] };
  }
}

function assignLevel(cats: DraftCategorization[], level: Level, cName: string) {
  const matchingInput = cats.find((c) => c.name === cName);
  if (matchingInput) {
    matchingInput.level = level.level;
    if (!matchingInput.include) matchingInput.include = true;
    matchingInput.existingCategorization = level.builtIn;
  }
}

export const setMapping = (
  importEstimateVar: ReactiveVar<ImportEstimateParameters>,
  importEstimateKey: string,
  importEstimate: ImportEstimateParameters,
  map: Map<string, string>,
  cats: DraftCategorization[]
) =>
  setReactiveLocal(importEstimateVar, importEstimateKey, {
    ...importEstimate,
    categorizations: cats,
    mapping: map,
  });

export const setBuiltInMapping = (
  importEstimateVar: ReactiveVar<ImportEstimateParameters>,
  importEstimateKey: string,
  importEstimate: ImportEstimateParameters,
  map: Map<string, Level>,
  cats: DraftCategorization[]
) =>
  setReactiveLocal(importEstimateVar, importEstimateKey, {
    ...importEstimate,
    categorizations: cats,
    mappingBuiltIn: map,
  });

// Given a list of base categorizations this function compares categorizations by name to see if it is a multilevel categorization
export const isMultilevelCategorization = (
  projectCategorizations: ProjectCategorization['categorization'][],
  categorization?: DraftCategorization | ProjectCategorization['categorization'],
  categorizationName?: string
): { isMulti: boolean; levels: number } => {
  const projectCategorization = projectCategorizations.find(
    (c) => c.name === categorization?.name || c.name === categorizationName
  );

  if (!projectCategorization) return { isMulti: false, levels: 0 };
  return {
    isMulti: projectCategorization && projectCategorization.levels > 1,
    levels: projectCategorization.levels,
  };
};

export const numCategorizationsMappedToMultilevel = (
  categorizationName: string,
  mapping: Map<string, string>,
  draftCategorizations: DraftCategorization[]
) => {
  let count = 0;
  mapping.forEach((v, k) => {
    if (
      v === categorizationName &&
      draftCategorizations.find((catz) => catz.name === k && catz.include)
    )
      count += 1;
  });
  return count;
};

export const updateMap = (
  newNames: string[],
  nameProj: string | undefined,
  categorizationsNewVar: DraftCategorization[],
  mapping: Map<string, string>,
  categorizations: ProjectCategorization['categorization'][],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  setMapping: (mapping: any, cats: DraftCategorization[]) => void,
  sendAnalytics: (analyticsEvent: AnalyticsEvent) => void
) => {
  const catsNew = [...categorizationsNewVar];
  const edit = new Map(mapping);
  const key = getKeyByValue(edit, nameProj);
  const { isMulti } = isMultilevelCategorization(categorizations, undefined, nameProj);

  if (!isMulti && key && edit.has(key)) {
    const name = edit.get(key);
    // update map
    edit.delete(key);
    // update the draft item
    const update = catsNew.find((v) => v.existingCategorization === name);
    if (update) {
      update.existingCategorization = '';
      update.categoryUpdates = { newCategories: [], categoryReplacements: [] };
    }
  }

  // update the level in which the column should be mapped to
  if (isMulti && nameProj) {
    const colsInMulti = numCategorizationsMappedToMultilevel(nameProj, mapping, catsNew);
    newNames.forEach((newName) => {
      const update = catsNew.find((v) => v.name === newName);
      // SUBJECT TO CHANGE: This assumes that the person that's mapping additional fields to the categorization is
      // mapping values that were not set in their categorization import, so we're going to default some behavior...
      // If they unlink - then we're assuming that they're rebuilding the categorization from scratch and each column's level is based on when they add it
      // The other edge case if they add a column to an already mapped categorization - depending on the levels of the already mapped catz things can get weird
      // i.e. if there is already catz levels 3/4 mapped, but not 1/2 and they add another one - it's going to be mapped as a level 3 catz.
      if (update) {
        update.level = colsInMulti + 1;
      }
    });
  }

  newNames.forEach((newName) => {
    // update map
    edit.set(newName, nameProj || '');
    // update the draft item. Update the existing categorization name
    // so we can display it in the next step
    const update = catsNew.find((v) => v.name === newName);
    // if we are setting this back to itself, then set the existing categorization prop back
    if (update) {
      if (!update.isDraft && nameProj === update.name) {
        update.existingCategorization = newName;
        update.categoryUpdates = { newCategories: [], categoryReplacements: [] };
      } else {
        update.existingCategorization = nameProj || '';
      }
    }
    sendAnalytics(importEstimatesSelectCategory(newName, nameProj));
  });
  setMapping(edit, catsNew);
};

export const updateBuiltInMap = (
  importEstimateVar: ReactiveVar<ImportEstimateParameters>,
  importEstimateKey: string,
  importEstimate: ImportEstimateParameters,
  categorizationsNew: DraftCategorization[],
  mappingBuiltIn: Map<string, Level>,
  cName: string | undefined,
  level: Level,
  sendAnalytics: (analyticsEvent: AnalyticsEvent) => void
) => {
  const catsNew = [...categorizationsNew];
  const edit = new Map(mappingBuiltIn);
  const key = getKeyByLevel(edit, level);
  if (!cName) {
    // when user un-assign
    if (key && edit.has(key)) {
      // update built in map
      edit.delete(key);
      // update draft item
      unassignLevel(catsNew, level);
    }
    setBuiltInMapping(importEstimateVar, importEstimateKey, importEstimate, edit, catsNew);
    return;
  }
  // when user re-assign
  if (key && edit.has(key)) {
    // when user reassign, remove previouselly assigned map
    const l = edit.get(key); // level
    // update built in map
    edit.delete(key);
    // update draft item
    if (l) unassignLevel(catsNew, l);
  }
  // when user assign
  // update built in map
  edit.set(cName, level);
  // update draft item: set the level and existingCategorization name
  assignLevel(catsNew, level, cName);
  setBuiltInMapping(importEstimateVar, importEstimateKey, importEstimate, edit, catsNew);
  sendAnalytics(importEstimatesSelectWBS(cName, level.builtIn, level.name, level.level));
};
