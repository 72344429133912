import { FC } from 'react';

// @ts-ignore TODO CT-545
import { GradientDefs } from 'react-vis/dist';

import theme from '../../../theme/komodo-mui-theme';

const CostTrendGradientDefinition: FC = () => (
  <svg height={0} width={0}>
    <GradientDefs>
      <pattern height="4" id="stripes" patternUnits="userSpaceOnUse" width="4">
        <path d="M 0, 0 l 5, 5" stroke={`${theme.palette.pending}95`} strokeLinecap="square" />
      </pattern>
    </GradientDefs>
    <defs>
      <filter height="100%" id="blur" width="100%" x="0" y="0">
        <feColorMatrix
          in="SourceAlpha"
          result="matrix"
          type="matrix"
          values="-1 0 0 0 1, 0 -1 0 0 1, 0 0 -1 0 1, 0 0 0 1 0"
        />
        <feMorphology in="matrix" operator="dilate" radius="2" result="offOut" />
        <feGaussianBlur in="offOut" result="blurOut" stdDeviation="0.6" />
        <feBlend in="SourceGraphic" in2="blurOut" />
      </filter>
    </defs>
  </svg>
);

export default CostTrendGradientDefinition;
