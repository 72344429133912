import type { BaseType, EnterElement, Selection } from 'd3-selection';

import { TimelineEvent } from '../../../../analytics/analyticsEventProperties';
import { TimelineGroups } from '../../../../api/gqlEnums';
import { TimelineActivityType as TimelineType } from '../../../../api/gqlEnumsBe';
import { TimelineExpandedMap } from '../../../Timeline/Timeline.types';

export { TimelineType, TimelineEvent };
export type { TimelineExpandedMap };

export type GetterSetter<Getter, Setter> = <Param extends Setter | undefined = undefined>(
  param?: Param
) => Param extends undefined ? Setter : Getter;

export interface Timeline<T extends TimelineData> {
  margin: GetterSetter<Timeline<T>, Margin>;
  width: GetterSetter<Timeline<T>, number>;
  height: GetterSetter<Timeline<T>, TimelineHeight>;
  isPrint: GetterSetter<Timeline<T>, boolean>;
  nodeColor: (color: Color<T>) => Timeline<T>;
  strokeColor: (color: Color<T>) => Timeline<T>;
  tooltips: GetterSetter<Timeline<T>, boolean>;
  tooltipElement: (tooltipElement: Tooltip<T>) => Timeline<T>;
  click: (click: Click<T>) => Timeline<T>;
  data: GetterSetter<Timeline<T>, T[]>;
  items: GetterSetter<Timeline<T>, TimelineItemData[]>;
  settings: GetterSetter<Timeline<T>, TimelineSettings>;
  range: GetterSetter<Timeline<T>, string[]>;
  expandedMap: GetterSetter<Timeline<T>, TimelineExpandedMap>;
  today: GetterSetter<Timeline<T>, Date | string>;
  zoomLineCompressed: GetterSetter<Timeline<T>, boolean>;
  zoomLineDisabled: GetterSetter<Timeline<T>, boolean>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  onAnalytics: GetterSetter<Timeline<T>, (event: any) => void>;
  onExpand: GetterSetter<Timeline<T>, (d: TimelineData) => void>;
  onZoomIn: GetterSetter<Timeline<T>, T>;
  onZoomInChart: GetterSetter<Timeline<T>, T>;
  onZoom: GetterSetter<Timeline<T>, (domain: Date[]) => void>;
  render(domElement: HTMLElement): Timeline<T>;
}

export interface TimelineData {
  id: UUID;
  name: string;
  start: Date | string;
  end?: Date | string;
  track?: number;
  type: TimelineType;
  children?: TimelineData[];
}

export enum ItemDueState {
  Upcoming,
  Decided,
  PastDue,
}

export interface TimelineItemData {
  id: UUID;
  name: string;
  dueDate: string;
  dueState: ItemDueState;
}

export interface TimelineItemGroup {
  counter: { [key in ItemDueState]: number };
  data: TimelineItemData[];
  end: string;
  start: string;
}

export enum GroupByType {
  Day,
  Week,
  Month,
}

export interface TimelineItemLegend {
  counter: { [key in ItemDueState]: number };
}

export type TimelineSettings = {
  collapse: TimelineGroups[];
  expand: TimelineGroups[];
  maxLineY?: number;
  projectID: UUID | undefined;
  isInsights?: boolean;
  isMiniChart?: boolean;
};

export type TimelineHeight = { totalHeight: number; timelineHeight: number; itemsHeight: number };

export interface TimelineOptions<T extends TimelineData = TimelineData> {
  outerWidth: number;
  outerHeight: number;
  brushHeight: number;
  isPrint: boolean;
  width: number;
  height: TimelineHeight;
  margin: Margin;
  nodeColor: Color<T>;
  strokeColor: Color<T>;
  showTooltips: boolean;
  tooltipElement: Tooltip<T>;
  itemsGroupBy: GroupByType;
  itemGroups: TimelineItemGroup[];
  itemsLegend: TimelineItemLegend;
  data: T[];
  items: TimelineItemData[];
  settings: TimelineSettings;
  range: string[];
  dataExpanded: T[];
  dataFlat: T[];
  expandedMap: TimelineExpandedMap;
  today?: string;
  isTimeline: boolean;
  isItems: boolean;
  click?: Click<T>;
  onAnalytics: (event: TimelineEvent) => void;
  onExpand: (d: T) => void;
  onZoom: (domain: Date[]) => void;
  zoomLineCompressed?: boolean;
  zoomLineDisabled?: boolean;
}

export type Color<T extends TimelineData> = (data: T) => string;
export type Click<T extends TimelineData> = (event: PointerEvent, data: T) => void;
export type Tooltip<T extends TimelineData> = (data: T, text?: string) => JSX.Element | null;
export type Margin = { top: number; right: number; bottom: number; left: number };

export type ChartContainer = {
  outerContainer: DivSelection;
  container: DivSelection;
};

export type DivSelection = Selection<HTMLDivElement, unknown, null, undefined>;
export type SVGSVGSelection = Selection<SVGSVGElement, unknown, null, undefined>;
export type BrushSelection = SVGSVGSelection;
export type SVGGSelection = Selection<SVGGElement, unknown, null, undefined>;
export type BrushGroupSelection = SVGGSelection;
export type SVGGDataSelection = Selection<SVGGElement, TimelineData, null, undefined>;
export type SVGGDataBaseSelection = Selection<SVGGElement, TimelineData, BaseType, unknown>;
export type BaseDataBaseSelection = Selection<BaseType, TimelineData, BaseType, unknown>;
export type SVGPathDataSelection = Selection<SVGPathElement, TimelineData, null, undefined>;
export type BaseDateSelection = Selection<BaseType, Date, BaseType, unknown>;
export type EnterDataSelection = Selection<EnterElement, TimelineData, BaseType, unknown>;

export type SVGGItemSelection = Selection<SVGGElement, TimelineItemGroup, null, undefined>;
export type SVGGItemBaseSelection = Selection<SVGGElement, TimelineItemGroup, BaseType, unknown>;
export type BaseItemBaseSelection = Selection<BaseType, TimelineItemGroup, BaseType, unknown>;
export type EnterItemSelection = Selection<EnterElement, TimelineItemGroup, BaseType, unknown>;
