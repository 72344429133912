import { CostReportColumnType } from '../../../../../generated/graphql';
import { useMilestoneCostReportsQuery } from '../../../../../hooks/useMilestoneCostReportsQuery';
import { useMilestonesCostReportsQuery } from '../../../../../hooks/useMilestonesCostReportsQuery';
import useProjectPropsQuery from '../../../../../hooks/useProjectPropsQuery';
import { computeColumnInputs } from '../../../../../utilities/milestones';
import { isEnumValue } from '../../../../../utilities/types';
import { useDesignPhaseTypes } from '../../../../Milestone/hooks/useDesignPhaseTypesQuery';
import { Callout, Select, TextInput } from '../../../../scales';
import DateInput from '../../../../shared-widgets/DateInput';
import useMemoWrapper from '../../../../useMemoWrapper';
import { CreateEstimateParameters, isReportType } from '../CreateEstimateDialogFlowUtils';

import { computeMilestoneSelectEntries, getTypeEntries } from './ContentsUtils';

type Props = {
  params: CreateEstimateParameters;
  t: TermStore;
};

export default function MilestoneSelectionContent(props: Props) {
  // State
  const {
    params: {
      isNewMilestone,
      milestones,
      onSetMilestoneDate,
      onSetMilestoneDesignPhase,
      onSetMilestoneID,
      onSetMilestoneName,
      onSetType,
      projectName,
      variables: { date, milestoneDesignPhase, milestoneID, milestoneName, projectID, type },
    },
    t,
  } = props;

  // Milestones Costs
  const milestonesColumnInputs = useMemoWrapper(
    computeColumnInputs,
    [CostReportColumnType.ESTIMATE_REPORT, CostReportColumnType.TARGET_REPORT],
    null
  );
  const activeMilestoneID = useProjectPropsQuery(projectID).data.project?.activeMilestone.id;
  const { data: { milestoneCostReports: milestonesCostReports } = { milestoneCostReports: [] } } =
    useMilestonesCostReportsQuery(
      milestones.map(({ id }) => ({ milestoneID: id })),
      projectID,
      {},
      milestonesColumnInputs
    );
  const milestoneEntries = useMemoWrapper(
    computeMilestoneSelectEntries,
    milestones,
    milestonesCostReports,
    type,
    activeMilestoneID
  );
  const designPhaseTypes = useDesignPhaseTypes();

  // Actions
  const onChangeDesignPhase = (designPhase: string) => {
    const milestoneDesignPhase = designPhaseTypes.find(({ id }) => id === designPhase);
    if (!milestoneDesignPhase) return;
    onSetMilestoneDesignPhase(milestoneDesignPhase);
  };
  const onChangeType = (newType: string) => {
    if (isEnumValue(CostReportColumnType, newType) && isReportType(newType)) {
      onSetType(newType);
    } else throw new Error('Invalid type');
  };

  // Costs
  const sidebarColumnTypes: CostReportColumnType[] = [
    CostReportColumnType.ESTIMATE_REPORT,
    CostReportColumnType.TARGET_REPORT,
  ];
  const columnInputs = useMemoWrapper(computeColumnInputs, sidebarColumnTypes, null);
  const { data: { milestoneCostReports } = { milestoneCostReports: [] } } =
    useMilestoneCostReportsQuery(isNewMilestone ? null : milestoneID, projectID, {}, columnInputs);
  const { typeEntries } = getTypeEntries(milestoneCostReports, t, type);

  return (
    <div className="flex flex-col gap-4">
      <Callout isFullWidth>
        <div className="mb-2 type-heading3">Choose Milestone</div>
        <div className="type-body1">
          You are adding the comparison average as a cost report for a milestone in the project{' '}
          <strong>{projectName}</strong>. Choose an existing or new milestone to proceed.
        </div>
      </Callout>
      <Select
        data-cy="milestone-select"
        entries={milestoneEntries}
        label="Milestone *"
        onChange={onSetMilestoneID}
        placeholder="Select an existing or new Milestone"
        value={milestoneID}
      />
      {isNewMilestone && (
        <>
          <div className="flex gap-2">
            <TextInput
              aria-label="milestone-name"
              data-cy="milestone-name-text-input"
              label="New Milestone Name *"
              onChange={onSetMilestoneName}
              placeholder="Milestone Name"
              value={milestoneName}
            />
            <DateInput
              date={date || undefined}
              label="Start Date *"
              onChange={onSetMilestoneDate}
            />
          </div>
          <Select
            data-cy="design-phase-select"
            entries={designPhaseTypes.map(({ id, name }) => ({ id, label: name }))}
            label="Milestone Design Phase *"
            onChange={onChangeDesignPhase}
            value={milestoneDesignPhase?.id}
          />
        </>
      )}
      <Select
        data-cy="type-select"
        entries={typeEntries}
        label={`Send to ${typeEntries.map((e) => e.label).join(' or ')}`}
        onChange={onChangeType}
        value={type}
      />
    </div>
  );
}
