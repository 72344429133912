import { useState } from 'react';

import {
  CompanyAdminEventType,
  companyAdminAnalyticsEvent,
} from '../../../../analytics/analyticsEventProperties';
import { OrgNode } from '../../../../generated/graphql';
import useSendAnalytics from '../../../../hooks/useSendAnalytics';
import { TreeEntry } from '../../../../utilities/utilities';
import { useSetOrgNodeName } from '../../../Organizations/hooks/hooks';
import { Chip, TextInput } from '../../../scales';
import useMemoWrapper from '../../../useMemoWrapper';
import { getUsedNodeNames } from '../utils';

export default function OrgNodeNameInput(props: {
  levelName: string;
  nodeID: UUID;
  nodeName: string;
  nodes: TreeEntry<OrgNode>[];
}) {
  const sendAnalytics = useSendAnalytics();
  const names = useMemoWrapper(getUsedNodeNames, props.nodes, props.nodeName);
  const [setOrgNodeName] = useSetOrgNodeName();
  const [error, setError] = useState('');
  const [name, setName] = useState(props.nodeName);

  return (
    <TextInput
      aria-label="Node Name"
      errorMessage={error}
      onBlur={() => {
        const fixed = name.trim();
        if (fixed === '') {
          setName(props.nodeName);
        }
        if (fixed === props.nodeName) return;
        if (error) {
          setError('');
          setName(props.nodeName);
        } else {
          setOrgNodeName({ variables: { input: { nodeID: props.nodeID, name: fixed } } });
          sendAnalytics(
            companyAdminAnalyticsEvent(CompanyAdminEventType.ORGANIZATIONS_CHANGE_NODE_NAME, {
              node: fixed,
            })
          );
        }
      }}
      onChange={(name) => {
        const isUnique = !names.includes(name);
        if (isUnique) {
          setError('');
        } else {
          setError(`${props.levelName} name already exists`);
        }
        if (name === '') {
          setError('Required');
        }
        setName(name);
      }}
      startAdornment={<Chip text={props.levelName} />}
      value={name}
    />
  );
}
