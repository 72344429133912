import { useContext, useMemo, useState } from 'react';
import { trim } from 'validator';

import {
  CreateEstimateFromProjectCompsEventTypes,
  createEstimateFromProjectCompsEvent,
} from '../../../../analytics/analyticsEventProperties';
import { JoinProjectRoutes } from '../../../../api/gqlEnums';
import {
  CATEGORIZATION_NAME_ALREADY_EXISTS_ERROR,
  CATEGORIZATION_NAME_NOT_VALID_ERROR,
} from '../../../../constants';
import { CostReportColumnType, MetricsInputReturn } from '../../../../generated/graphql';
import {
  getCategorizationsForProjectFromQueryData,
  useProjectCategorizationsQuery,
} from '../../../../hooks/useProjectCategorizationsQuery';
import useSendAnalytics from '../../../../hooks/useSendAnalytics';
import { generateSharedPath } from '../../../../utilities/routes/links';
import { MilestoneDetailsTabs } from '../../../Milestone/utils';
import { ProjectTermStore } from '../../../ProjectDisplaySettings/TerminologyProvider';
import { Button, DialogContent, DialogFlow, DialogFlowStep } from '../../../scales';

import CategorizationSelectionContent from './Contents/CategorizationSelectionContent';
import ConfirmationContent from './Contents/ConfirmationContent';
import MilestoneSelectionContent from './Contents/MilestoneSelectionContent';
import ProjectSelectionContent from './Contents/ProjectSelectionContent';
import PublishedContent from './Contents/PublishedContent';
import {
  getButtonLabel,
  getNextButtonDisabled,
  useCreateEstimateParams,
  useCurrentCost,
  useFinalizeCreateEstimate,
} from './CreateEstimateDialogFlowUtils';

type Props = {
  accentColor?: string;
  metrics: MetricsInputReturn[];
  onClose: () => void;
  parentProjectID?: UUID;
  totalCost: Cost;
  unit: Unit;
};

export default function CreateEstimateDialogFlow(props: Props) {
  const { totalCost, metrics, unit } = props;

  // State
  const [isDraftMilestone, setIsDraftMilestone] = useState(false);

  // Data
  const t = useContext(ProjectTermStore);
  const sendAnalytics = useSendAnalytics();
  const params = useCreateEstimateParams(props.parentProjectID);
  const {
    isNewCategorization,
    isNewMilestone,
    variables: {
      categorizationID,
      categorizationName,
      date,
      milestoneDesignPhase,
      milestoneID,
      milestoneName,
      projectID,
      type,
    },
  } = params;
  const [successMilestoneID, setSuccessMilestoneID] = useState<UUID | undefined>(undefined);
  const { currentCost, isNoCurrentEstimate } = useCurrentCost({
    projectID,
    milestoneID,
    type,
    isNewMilestone,
  });

  // Categorization name error
  const { data } = useProjectCategorizationsQuery(projectID, true);
  const categorizations = getCategorizationsForProjectFromQueryData(data);
  const errorMessage = useMemo(() => {
    if (categorizationName && !trim(categorizationName)) return CATEGORIZATION_NAME_NOT_VALID_ERROR;
    const categorizationNameAlreadyExists = categorizations.some(
      (categorization) => categorization.name === trim(categorizationName || '')
    );
    return categorizationNameAlreadyExists ? CATEGORIZATION_NAME_ALREADY_EXISTS_ERROR : undefined;
  }, [categorizationName, categorizations]);

  // Actions
  const cancel = () => {
    sendAnalytics(
      createEstimateFromProjectCompsEvent(CreateEstimateFromProjectCompsEventTypes.CANCEL_MENU)
    );
    props.onClose();
  };

  const onFinalize = useFinalizeCreateEstimate(params);

  // Open Estimate in new tab Link
  const onClickMilestoneDetails = (milestoneId: UUID) => {
    window.open(
      generateSharedPath(JoinProjectRoutes.MILESTONE_DETAILS, {
        projectId: projectID,
        milestoneId,
        search: `?view=${
          type === CostReportColumnType.ESTIMATE_REPORT
            ? MilestoneDetailsTabs.ESTIMATE
            : MilestoneDetailsTabs.TARGET
        }`,
      }),
      '_blank'
    );
  };

  // Step Components
  const stepProjectSelection: DialogFlowStep = {
    renderContent: () => <ProjectSelectionContent params={params} />,
    renderFooterRight: ({ onNext }) => <NextButton isDisabled={!projectID} onNext={onNext} />,
  };

  const stepMilestoneSelection: DialogFlowStep = {
    renderContent: () => (
      <DialogContent>
        <MilestoneSelectionContent params={params} t={t} />
      </DialogContent>
    ),
    renderFooterRight: ({ onNext }) => (
      <NextButton
        isDisabled={getNextButtonDisabled({
          isNewMilestone,
          milestoneName,
          date,
          milestoneDesignPhase,
          milestoneID,
        })}
        onNext={onNext}
      />
    ),
  };

  const stepCategorizationSelection: DialogFlowStep = {
    renderContent: () => (
      <DialogContent>
        <CategorizationSelectionContent errorMessage={errorMessage} params={params} />
      </DialogContent>
    ),
    renderFooterRight: ({ onNext }) => (
      <NextButton
        isDisabled={
          !categorizationID || (isNewCategorization && (!!errorMessage || !categorizationName))
        }
        onNext={onNext}
      />
    ),
  };

  const stepConfirmation: DialogFlowStep = {
    renderContent: () => (
      <DialogContent>
        <ConfirmationContent
          currentCost={currentCost}
          isNoCurrentEstimate={isNoCurrentEstimate}
          metrics={metrics}
          params={params}
          t={t}
          totalCost={totalCost}
          unit={unit}
        />
      </DialogContent>
    ),
    renderFooterRight: ({ onNext }) => (
      <div className="flex gap-2">
        {params.isNewMilestone ? (
          <>
            <Button
              data-cy="create-draft-milestone-button"
              label="Create as Draft Milestone"
              onClick={() => {
                setIsDraftMilestone(true);
                onFinalize(
                  true,
                  CreateEstimateFromProjectCompsEventTypes.CREATE_DRAFT,
                  type,
                  onNext,
                  setSuccessMilestoneID
                );
              }}
              type="secondary"
            />
            <Button
              data-cy="create-published-milestone-button"
              label="Create and Publish"
              onClick={() => {
                onFinalize(
                  false,
                  CreateEstimateFromProjectCompsEventTypes.CREATE_PUBLISH,
                  type,
                  onNext,
                  setSuccessMilestoneID
                );
              }}
              type="primary"
            />
          </>
        ) : (
          <Button
            data-cy="edit-existing-milestone-button"
            label={getButtonLabel({ t, isNoCurrentEstimate, type })}
            onClick={() => {
              const eventType = isNoCurrentEstimate
                ? CreateEstimateFromProjectCompsEventTypes.CREATE_ESTIMATE
                : CreateEstimateFromProjectCompsEventTypes.REPLACE_ESTIMATE;
              onFinalize(false, eventType, type, onNext, setSuccessMilestoneID);
            }}
            type="secondary"
          />
        )}
      </div>
    ),
  };

  const stepDone: DialogFlowStep = {
    renderContent: () => (
      <DialogContent>
        <PublishedContent isNewDraftMilestone={isDraftMilestone} params={params} t={t} />
      </DialogContent>
    ),
    renderFooterLeft: () => null, // No going back!
    renderFooterRight: ({ onNext }) => (
      <div className="flex gap-2">
        {successMilestoneID && (
          <Button
            data-cy="continue-to-estimate-button"
            label="Continue to estimate"
            onClick={() => {
              onClickMilestoneDetails(successMilestoneID);
              props.onClose();
            }}
            type="secondary"
          />
        )}
        <Button data-cy="done-button" label="Done" onClick={onNext} type="primary" />
      </div>
    ),
  };

  const steps = [
    stepProjectSelection,
    stepMilestoneSelection,
    stepCategorizationSelection,
    stepConfirmation,
    stepDone,
  ];

  return (
    <DialogFlow
      accentColor={props.accentColor}
      isFullHeight
      isOpen
      onClose={cancel}
      onComplete={props.onClose}
      size="lg"
      steps={steps}
      title="Create Estimate or Budget in a Project from Comparison Average"
    />
  );
}

function NextButton(props: { onNext: () => void; isDisabled: boolean }) {
  return (
    <Button
      data-cy="next-button"
      isDisabled={props.isDisabled}
      label="Next"
      onClick={props.onNext}
      type="primary"
    />
  );
}
