import { commonCategoryUniqueFields, gql } from '../../../api/graphqlFragments';

export const listCategoriesByIDsQuery = gql`
  query listCategoriesByIDs($categorizationID: UUID!, $categoryIDs: [UUID!]!) {
    categories(categorizationID: $categorizationID, categoryIDs: $categoryIDs) {
      ...commonCategoryUniqueFields
    }
  }
  ${commonCategoryUniqueFields}
`;
