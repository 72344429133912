import { commonProjectPropsFields, gql } from '../../api/graphqlFragments';

export const setProjectTypeMutation = gql`
  mutation setProjectType($projectID: UUID!, $typeID: UUID!) {
    setProjectType(projectID: $projectID, typeID: $typeID) {
      ...commonProjectPropsFields
    }
  }
  ${commonProjectPropsFields}
`;
