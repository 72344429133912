import { gql } from '../../api/graphqlFragments';

export const listDashboardProjectsQuery = gql`
  query listDashboardProjects(
    $companyID: UUID
    $projectsFilterBy: ProjectsFilterBy!
    $includeCompanyProjects: Boolean
  ) {
    dashboardProjects(
      companyID: $companyID
      projectsFilterBy: $projectsFilterBy
      includeCompanyProjects: $includeCompanyProjects
    ) {
      projects {
        code
        hasAccess
        id
        milestoneName
        name
      }
    }
  }
`;

export const insightsProjectCountsQuery = gql`
  query insightsProjectCounts($companyID: UUID, $projectsFilterBy: ProjectsFilterBy!) {
    insightsProjectCounts(companyID: $companyID, projectsFilterBy: $projectsFilterBy) {
      allProjectsCount
      myProjectsCount
    }
  }
`;

export const getActiveUsersQuery = gql`
  query getActiveUsers($projectIDs: [UUID!]!) {
    activeUsers(projectIDs: $projectIDs) {
      commentsAdded
      email
      itemsCreated
      name
      picture
      pictureAsset {
        thumbnailURL
      }
    }
  }
`;

export const getProjectActiveUsersQuery = gql`
  query getProjectActiveUsers($projectIDs: [UUID!]!) {
    projectActiveUsers(projectIDs: $projectIDs) {
      projectID
      userCount
      month
    }
  }
`;

export const getDecisionSummaryQuery = gql`
  query getDecisionSummary($projectIDs: [UUID!]!) {
    decisionSummary(projectIDs: $projectIDs) {
      acceptedCount
      acceptedCost
      incorporatedCount
      incorporatedCost
      notApplicableCount
      notApplicableCost
      pendingCount
      pendingCost
      rejectedCount
      rejectedCost
      totalItemCount
    }
  }
`;

export const getItemsOwnershipQuery = gql`
  query getItemsOwnership($projectIDs: [UUID!]!) {
    itemsOwnership(projectIDs: $projectIDs) {
      itemCount
      itemPercentage
      assigneeRoleName
    }
  }
`;

export const getProjectBudgetGapsQuery = gql`
  query getProjectBudgetGaps($projectIDs: [UUID!]!) {
    projectBudgetGaps(projectIDs: $projectIDs) {
      accepted
      budget
      estimate
      projectID
      relativeGap
      runningTotal
    }
  }
`;

const projectLocationFields = gql`
  fragment projectLocationFields on ProjectLocation {
    location
    lat
    lon
    projects {
      apv
      id
      name
      status
    }
    totalAPV
  }
`;

export const getProjectLocationsQuery = gql`
  query getProjectLocations($projectIDs: [UUID!]!) {
    projectLocations(projectIDs: $projectIDs) {
      projectLocations {
        ...projectLocationFields
      }
      projectsWithoutLocation {
        ...projectLocationFields
      }
    }
  }
  ${projectLocationFields}
`;

export const getProjectMovementsQuery = gql`
  query getProjectMovements($projectIDs: [UUID!]!) {
    projectMovements(projectIDs: $projectIDs) {
      increases {
        projectID
        lastUpdated
        name
        milestoneName
        absolute
        relative
      }
      decreases {
        projectID
        lastUpdated
        name
        milestoneName
        absolute
        relative
      }
    }
  }
`;

export const getProjectsOverviewQuery = gql`
  query getProjectsOverview($projectIDs: [UUID!]!) {
    projectsOverview(projectIDs: $projectIDs) {
      totalAPV
      projectStatusData {
        name
        count
        countPercentage
        apv
        apvPercentage
      }
      projectTypeData {
        name
        count
        countPercentage
        apv
        apvPercentage
      }
    }
  }
`;

export const listItemDueDatesQuery = gql`
  query listItemDueDates($projectIDs: [UUID!]!) {
    itemDueDates(projectIDs: $projectIDs) {
      noDueDateCount
      pastDueCount
      dueThisWeekCount
      upcomingCount
      openCount
      projectID
    }
  }
`;

export const listCurrentItemsBreakdownQuery = gql`
  query listCurrentItemsBreakdown($projectIDs: [UUID!]!) {
    currentItemsBreakdown(projectIDs: $projectIDs) {
      projectTotals {
        project {
          id
          name
          code
        }
        projectStatus
        statusDuration
        positiveCost
        negativeCost
        totalCost
        totalItemCount
        statusBreakdown {
          status
          percent
          itemCount
          cost
        }
        monthlyBreakdown {
          month
          positiveCost
          negativeCost
          totalCost
          itemBreakdown {
            status
            cost
          }
        }
      }
      itemBreakdownsByProject {
        project {
          id
          name
          code
        }
        status
        cost
      }
    }
  }
`;

export const getRecentlyCreatedProjectsQuery = gql`
  query getRecentlyCreatedProjects($projectIDs: [UUID!]!) {
    recentlyCreatedProjects(projectIDs: $projectIDs) {
      name
      status
      location
      createdBy
      createdAt
      thumbnail
      projectId
    }
  }
`;
