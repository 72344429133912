import { debounce } from 'lodash';
import { ReactChild, useContext, useEffect, useMemo } from 'react';
import * as React from 'react';

import { withStyles } from '../../theme/komodo-mui-theme';
import RowHighlightContext from '../shared-widgets/RowHighlight/context';

import styles from './ScrollContainerStyles';

const ScrollContainer = (props: {
  children: ReactChild | ReactChild[];
  classes: Classes<typeof styles>;
  containerRef: React.RefObject<HTMLDivElement>;
  onScroll: (el: HTMLDivElement) => void;
}) => {
  const { onRegisterHighlightableArea, onScroll } = useContext(RowHighlightContext);

  useEffect(() => {
    onRegisterHighlightableArea(props.containerRef);
  }, [onRegisterHighlightableArea, props.containerRef]);

  const { onScroll: onScrollContainer } = props;
  const handleScroll = useMemo(
    () =>
      debounce((e: React.UIEvent<HTMLDivElement, UIEvent>) => {
        onScroll();

        if (e.currentTarget) {
          onScrollContainer(e.currentTarget);
        }
      }, 10),
    [onScroll, onScrollContainer]
  );

  return (
    <div
      ref={props.containerRef}
      className={props.classes.container}
      onScroll={(e) => {
        e.persist();
        handleScroll(e);
      }}
    >
      {props.children}
    </div>
  );
};

export default withStyles(styles)(ScrollContainer);
