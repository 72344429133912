import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';

export default (theme: KomodoTheme) =>
  createStyles({
    containterCenter: {
      display: 'flex',
      justifyContent: 'center',
    },
    menuText: {
      fontFamily: theme.fontFamily,
      fontSize: 21,
      fontWeight: 500,
      width: 225,
    },
    centerItems: {
      display: 'flex',
      alignItems: 'center',
    },
  });
