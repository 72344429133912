import { MoreVert } from '@material-ui/icons';

import { UploadPlatform } from '../../../api/gqlEnums';
import { SelectEntry } from '../../Select/JoinSelect/JoinSelect';

// the order of these keys determines the order of how they are displayed
export const EstimatePlatformLabels = {
  [UploadPlatform.EMPTY_STATE]: '(estimating tool)',
  [UploadPlatform.DESTINI]: 'Destini',
  [UploadPlatform.WINEST]: 'WinEst',
  [UploadPlatform.SAGE]: 'Sage',
  [UploadPlatform.PROCORE]: 'Procore',
  [UploadPlatform.EXCEL]: 'Excel',
};

export const EstimatePlatformSubTitleText = {
  [UploadPlatform.DESTINI]: {
    title: EstimatePlatformLabels.Destini,
    subtitle: 'Home > Export > Spreadsheet (.xlsx)',
  },
  [UploadPlatform.WINEST]: {
    title: EstimatePlatformLabels.Winest,
    subtitle: 'Tools > Export Items > To .XML File for Reports (.xml)',
  },
  [UploadPlatform.SAGE]: {
    title: EstimatePlatformLabels.Sage,
    subtitle: 'File > Manage Estimates > Export Estimate > Browse > Save (.zip)',
  },
  [UploadPlatform.EXCEL]: {
    title: EstimatePlatformLabels.Excel,
    subtitle: 'Import .xlsx into Join',
  },
  [UploadPlatform.PROCORE]: {
    title: EstimatePlatformLabels.Procore,
    subtitle: (
      <>
        {`Preconstruction > Estimating > `}
        <MoreVert style={{ width: 20, height: 20 }} />
        {` > Export Estimate > Excel`}
      </>
    ),
  },
};

export const supportedEstimatePlatformEntries: SelectEntry[] = Object.entries(
  EstimatePlatformLabels
).map(([key, value]) => ({ name: value, id: key }));
