import { MARGIN } from '../PrintUtils';

type Props = {
  pageWidth?: number;
};

const PrintPageBreak = (props: Props) => {
  const pageWidthString = props.pageWidth ? `${props.pageWidth}px` : '100%';
  return (
    <div
      className="relative break-after-page border-t-[0.5px] border-dotted print:border-none"
      style={{
        width: `calc(${pageWidthString} + ${MARGIN * 2}px)`,
        left: -MARGIN,
      }}
    />
  );
};

export default PrintPageBreak;
