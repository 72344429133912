import { FC, useState } from 'react';

import { Add } from '@material-ui/icons';

import {
  CompanyAdminEventType,
  companyAdminAnalyticsEvent,
} from '../../../../analytics/analyticsEventProperties';
import useSendAnalytics from '../../../../hooks/useSendAnalytics';
import DialogsNewTemplate from '../../../Dialogs/DialogsTemplates/DialogsNewTemplate';
import { Button } from '../../../scales';

type NewProjectTemplateProps = {
  onClick?: () => void;
};

const NewProjectTemplate: FC<NewProjectTemplateProps> = ({ onClick }) => {
  const [open, setOpen] = useState(false);
  const sendAnalytics = useSendAnalytics();
  return (
    <>
      <Button
        data-cy="create-new-template-button"
        label="Create New Template"
        onClick={() => {
          onClick?.();
          sendAnalytics(companyAdminAnalyticsEvent(CompanyAdminEventType.PROJECT_TEMPLATES_NEW));
          setOpen(true);
        }}
        startIcon={<Add />}
        type="primary"
      />

      <DialogsNewTemplate
        open={open}
        setDialogOpen={(open: boolean) => {
          setOpen(open);
        }}
      />
    </>
  );
};

export default NewProjectTemplate;
