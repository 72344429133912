import { FC, useEffect, useState } from 'react';

import { isSecondPopupVar } from '../../../api/apollo/reactiveVars';
import MultiGroupMetric from '../../shared-widgets/MultiGroup/MultiGroupMetric';
import { EMPTY_MESSAGE_NONE } from '../../shared-widgets/MultiGroup/MultiGroupOrderSelector';
import {
  MetricGroup,
  toggleGroupsOptionsKeys,
} from '../CostReportMetricGroup/CostReportMetricGroupUtils';

type CostReportSelectMultiProps = {
  initialSelection: string[];
  groups: MetricGroup[];
  onClose: (selected: string[]) => void;
};

const CostReportSelectMulti: FC<CostReportSelectMultiProps> = ({
  initialSelection,
  groups,
  onClose,
}) => {
  // State, local while open
  const [selected, setSelected] = useState(initialSelection);
  useEffect(() => {
    setSelected(initialSelection);
  }, [initialSelection]);

  const [open, setGroupOpen] = useState(false);
  const setOpen = (newValue: boolean) => {
    setGroupOpen(newValue);
    isSecondPopupVar(newValue);
    if (open) {
      // need a slight wait to allow menu to close before kicking off query update
      setTimeout(() => onClose(selected), 1);
    }
  };

  const toggleSelected = toggleGroupsOptionsKeys(groups, selected, setSelected);

  return (
    <MultiGroupMetric
      displayChips={1}
      emptyMessage={EMPTY_MESSAGE_NONE}
      groups={groups}
      open={open}
      selected={selected}
      setOpen={setOpen}
      toggleSelected={toggleSelected}
    />
  );
};

export default CostReportSelectMulti;
