import { FC, useRef, useState } from 'react';

import { ReactiveVar } from '@apollo/client';
import { Menu, Select, Typography, createStyles } from '@material-ui/core';
import { Check, ErrorOutline } from '@material-ui/icons';

import { KomodoTheme, withStyles } from '../../../../theme/komodo-mui-theme';
import SelectMenu from '../../../Select/SelectMenu/SelectMenu';
import { MenuOption } from '../../../Select/SelectMenu/SelectOption';
import { getCategoriesMissingLabel } from '../../../table/estimate/EstimateHeaderUtils';
import { getSubMenuOptions } from '../../../table/estimate/ReplaceCategoryMenu/ReplaceCategoryMenu';
import { setCategoryReplacement, setCategoryUpdates } from '../../utils';

export const SELECTOR_WIDTH = 240;

const styles = (theme: KomodoTheme) =>
  createStyles({
    select: {
      width: '100%',
    },
    header: { display: 'flex', gap: '6px', alignItems: 'center' },
    headerText: { position: 'relative', top: 1, color: theme.palette.red },
    checkText: { position: 'relative', top: 1 },
    errorIcon: {
      color: theme.palette.red,
      fontSize: 21,
    },
    checkIcon: {
      color: theme.palette.accepted,
      fontSize: 21,
    },
    missingCount: {
      height: 32,
      backgroundColor: theme.palette.backgroundGrey,
      color: theme.palette.joinPrimary,
    },
    label: {
      padding: '5px 10px',
    },
  });

type CategorizationMatchingProps = {
  categorizationsProject: CategorizationMetadata;
  classes: Classes<typeof styles>;
  draftCategorizations: DraftCategorization[];
  errorsMap: Map<string, number[]>;
  importEstimateKey: string;
  importEstimateVar: ReactiveVar<ImportEstimateParameters>;
  totalResolvedMatches: number;
};

const CategorizationMatching: FC<CategorizationMatchingProps> = ({
  categorizationsProject,
  classes,
  draftCategorizations,
  errorsMap,
  importEstimateKey,
  importEstimateVar,
  totalResolvedMatches,
}) => {
  const ref = useRef(null);
  const [open, setOpen] = useState(false);

  const keys = Array.from(errorsMap.keys());
  const getErrorCategorization = (key: string) =>
    draftCategorizations.find((c) =>
      c.categoryMatchCounts.get(categorizationsProject.name)?.missingCategories.includes(key)
    );

  const optionsProps = {
    canEditCategory: !categorizationsProject.createdFrom || categorizationsProject.levels > 1, // Company standard catz can't be edited on a project.
    categorization: categorizationsProject,
    errorsMap,
    hasGroups: false,
    isErrorsMode: true,
    onAddAllErrors: () => {
      setCategoryUpdates(keys, importEstimateVar, importEstimateKey, draftCategorizations[0].name);
      setOpen(false);
    },
    onCategoryError: (category: string) => {
      setCategoryUpdates(
        [category],
        importEstimateVar,
        importEstimateKey,
        draftCategorizations[0].name
      );
      setOpen(false); // sometimes the dialog doesn't close if we just rely on the OnClose prop
    },
    updateColumnCells: (
      value: { category: Category | null; search: string },
      _: number[],
      issuedCategory: string
    ) => {
      setCategoryReplacement(
        issuedCategory,
        value,
        importEstimateVar,
        importEstimateKey,
        getErrorCategorization(issuedCategory)?.name || draftCategorizations[0].name
      );
      setOpen(false);
    },
    totalErrors: errorsMap.size,
  };
  const label = getCategoriesMissingLabel(errorsMap.size);
  const missingCategorylabel: MenuOption = {
    name: label,
    component: (
      <div className={classes.missingCount}>
        <Typography className={classes.label}>{label}</Typography>
      </div>
    ),
    width: SELECTOR_WIDTH,
    callback: () => {},
  };
  const categoryUpdateOptions = getSubMenuOptions(optionsProps, SELECTOR_WIDTH);
  const options = [missingCategorylabel, ...categoryUpdateOptions];

  const hasUnresolvedErrors = keys.length !== 0;
  const selectContent = hasUnresolvedErrors ? (
    <div className={classes.header}>
      <ErrorOutline className={classes.errorIcon} />
      <Typography className={classes.headerText}>{`${keys.length} Unmatched`}</Typography>
    </div>
  ) : (
    <div className={classes.header}>
      <Check className={classes.checkIcon} />
      <Typography className={classes.checkText}>{`${totalResolvedMatches} Matched`}</Typography>
    </div>
  );

  return (
    <div ref={ref}>
      <Select // this exists just for the UX, we aren't actually selecting anything.  When you click this it opens the Menu component below
        className={classes.select}
        disabled={!hasUnresolvedErrors}
        disableUnderline
        IconComponent={
          !hasUnresolvedErrors
            ? (): null => {
                // if this is null then the dropdown arrow component doesn't show
                // when this is disabled
                return null;
              }
            : undefined
        }
        onChange={() => setOpen(true)}
        onClick={(event) => {
          setOpen(true);
          event.preventDefault();
          event.stopPropagation();
        }}
        onOpen={() => {}} // needed to resolve a console error
        open={false}
        renderValue={() => selectContent}
        value // must have a value to see the render value
      />
      <Menu
        anchorEl={ref.current}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        getContentAnchorEl={null}
        id="menu"
        onClose={() => {
          setOpen(false);
        }}
        open={open}
        style={{ marginTop: 4 }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <SelectMenu onClose={() => setOpen(false)} options={options} />
      </Menu>
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
export const StyledCategorizationMatching: any = withStyles(styles)(CategorizationMatching);

export default StyledCategorizationMatching;
