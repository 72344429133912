/**
 * This file contains a definition of duplicated types.
 * In the future types will be generated automatically and this file will be removed
 */

export enum ScheduleImpactFilter {
  TBD,
  NA,
  INCREASE,
  DECREASE,
  CRITICAL,
  NONCRITICAL,
}

export enum TimelineActivityType {
  EVENT,
  PHASE,
  MILESTONE,
  ACTIVE_MILESTONE,
}

export enum UserStatus {
  PENDING = 'Pending',
  ACTIVE = 'Active',
  DEACTIVATED = 'Deactivated',
}

export enum CostReportType { // report_type.go
  NoReport = 0,
  GrossReport = 1,
  NetReport = 2,
  GrossNetReport = 3,
}

export enum ImportEstimateErrorType {
  IMPORT_ERROR_MISSING_CATEGORY = 'IMPORT_ERROR_MISSING_CATEGORY',
}

export enum FieldType {
  CATEGORY = 'Category',
  CURRENCY = 'USCents',
  CURRENCY_9 = 'Currency9',
  DECIMAL = 'Decimal',
  NUMBER = 'Number',
  SOURCE = 'Source',
  STRING = 'String',
  REFERENCE = 'Reference',
  INHERITED_REFERENCE = 'InheritedReference',
  MASTERFORMAT = 'MasterFormat',
  UNIFORMAT = 'Uniformat',
  INHERITED_MARKUP_CHECKBOX = 'InheritedMarkupCheckbox',
  SELECT = 'Select',
  MARKUP_VALUE = 'Value',
  ALLOCATE = 'Allocate',
  MARKUP_DISPLAY_TYPE = 'Display Type',
  DELETE = 'Delete',
}

export enum ScheduleImpactType {
  TBD,
  NA,
  DAYS,
}
