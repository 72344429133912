import { useMutation } from '@apollo/client';

import { disableProjectCategorizationAnalytics } from '../../../analytics/analyticsEventProperties';
import { REFETCH_DISABLE_CATEGORIZATION } from '../../../api/refetchSets';
import {
  SetDisabledProjectCategorizationMutation,
  SetDisabledProjectCategorizationMutationVariables,
} from '../../../generated/graphql';
import useSendAnalytics from '../../../hooks/useSendAnalytics';
import { setDisabledProjectCategorizationMutation } from '../queries';

const useSetDisabledProjectCategorization = (projectID: UUID, categorization: Categorization) => {
  const sendAnalytics = useSendAnalytics();
  const [setDisabledProjectCategorizationFunc] = useMutation<
    SetDisabledProjectCategorizationMutation,
    SetDisabledProjectCategorizationMutationVariables
  >(setDisabledProjectCategorizationMutation);
  const categorizationID = categorization.id;

  return (disabled: boolean) => {
    sendAnalytics(
      disableProjectCategorizationAnalytics(categorizationID, categorization.name, disabled)
    );
    return setDisabledProjectCategorizationFunc({
      variables: {
        projectID,
        categorizationID,
        disabled,
      },
      refetchQueries: REFETCH_DISABLE_CATEGORIZATION,
    });
  };
};

export default useSetDisabledProjectCategorization;
