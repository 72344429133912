import { useCallback } from 'react';

import { logErrorToSentry } from '../../../utilities/sentry';

type BaseQueryVariables = {
  filters: Record<string, unknown>;
  projectsFilters?: Record<string, unknown>;
  search: string;
  sortBy: object;
};

export function useSavedQuery<T extends BaseQueryVariables>(
  localStorageKey: string,
  defaultQuery: T
): [
  T,
  (
    value: Partial<Omit<T, 'filters' | 'projectsFilters'>> & {
      filters?: Partial<T['filters']>;
      projectsFilters?: Partial<T['projectsFilters']>;
    }
  ) => void,
] {
  // We don't really want to force the caller to send us the entire object (note the Partial<>s
  // in the type of this callback's parameter). If we required the entire object, then callers
  // would struggle with memoization of the calling function. As a result, we're pulling the
  // current settings and mixing in the new value on top of those.
  const setSavedQuery = useCallback(
    (
      value: Partial<Omit<T, 'filters' | 'projectsFilters'>> & {
        filters?: Partial<T['filters']>;
        projectsFilters?: Partial<T['projectsFilters']>;
      }
    ) => {
      let storedSettings;
      const storedSettingsJSON = localStorage.getItem(localStorageKey);
      if (storedSettingsJSON) {
        try {
          storedSettings = JSON.parse(storedSettingsJSON);
        } catch (error) {
          // it's fine, ignore it...
        }
      }

      const newSettings: T = {
        ...defaultQuery,
        ...(storedSettings ?? {}),
        ...value,
        filters: {
          ...defaultQuery.filters,
          ...(storedSettings ? storedSettings.filters : {}),
          ...value.filters,
        },
        projectsFilters: {
          ...defaultQuery.projectsFilters,
          ...(storedSettings ? storedSettings.projectsFilters : {}),
          ...value.projectsFilters,
        },
      };

      localStorage.setItem(localStorageKey, JSON.stringify(newSettings));
    },
    [defaultQuery, localStorageKey]
  );

  const settingsJSON = localStorage.getItem(localStorageKey);
  if (!settingsJSON) {
    return [defaultQuery, setSavedQuery];
  }

  try {
    const settings: unknown = JSON.parse(settingsJSON);
    if (!settings || typeof settings !== 'object') {
      throw Error('Rehydrated search query is not an object');
    }

    if (
      'filters' in settings &&
      'projectsFilters' in settings &&
      'search' in settings &&
      'sortBy' in settings
    ) {
      if (
        typeof settings.filters === 'object' &&
        typeof settings.projectsFilters === 'object' &&
        typeof settings.search === 'string' &&
        typeof settings.sortBy === 'object'
      ) {
        if ('filters' in settings && settings.filters && typeof settings.filters === 'object') {
          const expectedQueryFilterKeys = Object.keys(defaultQuery.filters);
          for (let i = 0; i < expectedQueryFilterKeys.length; i += 1) {
            const expectedQueryFilterKey = expectedQueryFilterKeys[i];
            // We failed to find a filter that we were expecting. We probably are dealing with an
            // old query that doesn't apply to the new version anymore.
            if (expectedQueryFilterKey in settings.filters === false) {
              return [defaultQuery, setSavedQuery];
            }
          }
        }
        if (
          'projectsFilters' in settings &&
          settings.projectsFilters &&
          typeof settings.projectsFilters === 'object'
        ) {
          const expectedProjectQueryFilterKeys = Object.keys(defaultQuery.projectsFilters || []);
          for (let i = 0; i < expectedProjectQueryFilterKeys.length; i += 1) {
            const expectedProjectsQueryFilterKey = expectedProjectQueryFilterKeys[i];
            // We failed to find a projectsFilters that we were expecting. We probably are dealing with an
            // old query that doesn't apply to the new version anymore.
            if (expectedProjectsQueryFilterKey in settings.projectsFilters === false) {
              return [defaultQuery, setSavedQuery];
            }
          }
        }
        return [settings as T, setSavedQuery];
      }
    }
    throw Error('Rehydrated search query has wrong shape.');
  } catch (error) {
    const e = error as Error;
    logErrorToSentry(
      `Failed to rehydrate settings for ${localStorageKey}. ${e?.name}: ${e?.message || e?.cause}`
    );
    return [defaultQuery, setSavedQuery];
  }
}
