import CollapseIcon from '../shared-widgets/CollapseIcon';

type Props = {
  text: string;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
};

export default function ProgramSelectionCollapse({ text, isOpen, setIsOpen }: Props) {
  function toggleCollapse() {
    setIsOpen(!isOpen);
  }

  return (
    <div className="w-full">
      <div
        className="flex cursor-pointer items-center justify-start"
        onClick={toggleCollapse}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            toggleCollapse();
          }
        }}
        role="button"
        tabIndex={0}
      >
        <CollapseIcon
          isCollapsed={!isOpen}
          onClick={() => {
            toggleCollapse();
          }}
        />
        <span className="type-body-1 font-bold">{text}</span>
      </div>
    </div>
  );
}
