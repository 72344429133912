import { commonProjectTypeFields, gql } from '../../../../../api/graphqlFragments';

export const createProjectTypeMutation = gql`
  mutation createProjectType($name: String!, $companyID: UUID!, $parentID: UUID) {
    createProjectType(name: $name, companyID: $companyID, parentID: $parentID) {
      ...commonProjectTypeFields
    }
  }
  ${commonProjectTypeFields}
`;
