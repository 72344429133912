import { FC, useState } from 'react';

import { withStyles } from '@material-ui/core/styles';
import {
  ChartLabel,
  DiscreteColorLegend,
  FlexibleXYPlot,
  HorizontalGridLines,
  LabelSeries,
  VerticalBarSeries,
  VerticalGridLines,
  XAxis,
  YAxis,
  // @ts-ignore JIRA: CT-224
} from 'react-vis/dist';
import 'react-vis/dist/style.css';

import { MAX_COL_WIDTH, SORT_STATUS } from '../../../constants';
import { Status } from '../../../generated/graphql';
import theme from '../../../theme/komodo-mui-theme';
import { useCostMode } from '../../../utilities/costMode';
import { formatCost } from '../../../utilities/currency';
import { fontSize, tickFormatFn } from '../../../utilities/dashboard';
import { getItemStatusLabel } from '../../../utilities/item-status';
import { getFilteredItemState } from '../../../utilities/items';
import { removeYear } from '../../../utilities/string';
import ItemsIconsMap, { SMALL } from '../../ItemsList/ItemsIcons/ItemsIconsMap';
import useMemoWrapper from '../../useMemoWrapper';
import { getChartLabelWithCurrency } from '../ChartsUtils';

import ChartsItemsStyles from './ChartsItemsStyles';
import { ChartItem, Label, computeItems, getColor } from './ChartsItemsUtils';

const generateItemKey = (item: ChartItem, viewBy = '', costMode: CostMode) => {
  const { isPositive, id, displayCostImpact, x, y, y0 } = item;
  const { status } = getFilteredItemState(item);
  const category = item.categories?.[0]?.id ?? '';
  return `${viewBy}${costMode}:${id}: ${category} ${isPositive}: ${status}: ${displayCostImpact} ${x} ${y} ${y0}`;
};

const generateLabelKey = (label: Label) => `${label.y}:${label.x}`;

const isHover = (item: ChartItem, itemValue: ChartItem, isPrint: boolean) =>
  itemValue && item && item.id === itemValue.id && !isPrint;

type ChartsItemsProps = {
  classes: Classes<typeof ChartsItemsStyles>;
  costReport?: MilestoneCostReport;
  isPrint: boolean;
  itemMap: Map<UUID, ItemsListItem>;
  onClick: (item: ChartItem) => void;
  onFocus: (item: ChartItem) => void;
  onGroupClick: (groupValue: string) => void;
  onMouseLeave: () => void;
  onMouseOver: (item: ChartItem) => void;
  onSpecificStatus: (statusValue: string) => void;
  rawItems: ItemsListItem[];
  value: ChartItem;
  viewBy: string | undefined;
};

const ChartsItems: FC<ChartsItemsProps> = ({
  classes,
  costReport,
  isPrint,
  itemMap,
  onClick,
  onFocus,
  onGroupClick,
  onMouseLeave,
  onMouseOver,
  onSpecificStatus,
  rawItems,
  value,
  viewBy,
}) => {
  // STATE
  const [width, setWidth] = useState(MAX_COL_WIDTH);
  const costMode = useCostMode();

  // DATA
  const { min, max, items, labels, buckets, xLabel } = useMemoWrapper(
    computeItems,
    rawItems,
    itemMap,
    viewBy,
    costReport
  );

  const costFormat = { short: true, showCurrencySymbol: false };

  const tickValue = tickFormatFn(buckets, width, viewBy);
  const availableStatuses =
    viewBy === SORT_STATUS
      ? []
      : Object.keys(Status)
          // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
          .filter((key: any) => items.map((i) => i.status).includes(key))
          .map((status) => ({
            title: getItemStatusLabel(status),
            strokeWidth: 12,
            color: getColor(status),
          }));

  // COMPONENTS
  const itemsLabelsSeries = items.map((item: ChartItem) => (
    <LabelSeries
      key={generateItemKey(item, viewBy, costMode)}
      className={classes.itemHint}
      data={[
        {
          x: item.x,
          y: (item.y + item.y0) / 2,
          id: item.id,
          style: { ...theme.typography.number },
        },
      ]}
      getLabel={() => {
        if (isHover(item, value, isPrint))
          return `${formatCost(item.displayCostImpact, costFormat)}`;
        return null;
      }}
      labelAnchorX="middle"
      labelAnchorY="middle"
      stack={false}
    />
  ));
  const itemsVerticalBarSeries = items.map((item: ChartItem) => (
    <VerticalBarSeries
      key={generateItemKey(item, viewBy, costMode)}
      className={`${classes.status} ${
        isHover(item, value, isPrint) ? `${classes.hover} ${classes.pointer}` : ''
      }`}
      colorType="literal"
      data={[item]}
      onFocus={(i: ChartItem) => onFocus(i)}
      onValueClick={() => onClick(item)}
      onValueMouseOut={onMouseLeave}
      onValueMouseOver={(i: ChartItem) => onMouseOver(i)}
      stack={false}
    />
  ));
  const labelsLabelSeries = labels.map((l: Label) => (
    <LabelSeries
      key={generateLabelKey(l)}
      className={classes.subtotal}
      data={[
        {
          x: l.x,
          y: l.y,
          yOffset: l.isPositive ? -17 : 17,
          style: { ...theme.typography.number },
        },
      ]}
      getLabel={() => `${formatCost(l.y * 100, costFormat)}`}
      labelAnchorX="middle"
      labelAnchorY={l.isPositive ? 'hanging' : 'baseline'}
      stack={false}
    />
  ));
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  const bucketXValues = buckets.map((b: any) => b.x);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  const ticks = (_: any, i: number) => {
    const { cost, description, name, status, tooltip } = tickValue(i);
    const icon = status && <ItemsIconsMap notChosen={false} status={status} variant={SMALL} />;
    const color = status && getColor(status);
    // we want to hide text when name is single &nbsp;
    const hideEmptyName = (v = '') => (v === '' ? 'hidden' : 'inherit');
    return (
      <>
        <g style={{ color, transform: 'translate(0,6px) scale(0.06) translate(-50%, 0)' }}>
          {icon}
        </g>
        <text style={{ transform: 'translate(0px,36px)', textAnchor: 'middle' }}>
          <tspan>
            <title id={tooltip} title={name}>
              {tooltip}
            </title>
            <tspan
              data-cy="items-chart-label"
              dy="1em"
              style={{
                fontSize: fontSize.name,
                fontWeight: 400,
                visibility: hideEmptyName(name),
              }}
              x="0"
              y="-8"
            >
              {name}
              &nbsp;
            </tspan>
            <tspan
              dy="1em"
              style={{
                fontSize: fontSize.description,
                visibility: hideEmptyName(description),
              }}
              x="0"
            >
              {description}
              &nbsp;
            </tspan>
            <tspan
              data-cy="items-chart-label-number"
              dy="1em"
              style={{ fontSize: fontSize.cost, ...theme.typography.number }}
              x="0"
            >
              {cost}
            </tspan>
          </tspan>
        </text>
      </>
    );
  };

  return (
    <>
      <div className={classes.legendContainer}>
        <DiscreteColorLegend items={availableStatuses} orientation="horizontal" />
      </div>
      <FlexibleXYPlot
        // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
        ref={(r: any) => {
          if (r && r.state) setWidth(r.state.width);
        }}
        className={classes.graph}
        height={400}
        margin={{ left: 90, right: 16, bottom: 105 }}
        onMouseLeave={onMouseLeave}
        stackBy="y"
        // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
        xDomain={buckets.map((b: any) => b.x)}
        xPadding={60}
        xType="ordinal"
        yBaseValue={0}
        yDomain={[min > 0 ? 0 : min, max < 0 ? 0 : max]}
        yRange={[264, 20]}
      >
        <VerticalGridLines />
        <HorizontalGridLines />
        <HorizontalGridLines
          style={{ strokeWidth: 2, stroke: theme.palette.primaryGrey }}
          tickTotal={1}
          tickValues={[0]}
        />
        <XAxis
          key={width}
          onClick={removeYear(xLabel) === 'Status' ? onSpecificStatus : undefined}
          style={{ fill: theme.palette.primaryGrey }}
          tick
          tickFormat={ticks}
          tickLabelAngle={0}
          tickValues={bucketXValues}
        />
        <YAxis
          style={{
            fill: theme.palette.primaryGrey,
            ...theme.typography.number,
            fontSize: fontSize.cost,
          }}
          tickFormat={(v: number) => formatCost(v * 100, costFormat)}
          tickTotal={6}
        />
        <ChartLabel
          className={classes.axisLabel}
          includeMargin={false}
          onClick={isPrint ? null : onGroupClick}
          style={{
            y: 112,
            textAnchor: 'middle',
          }}
          text={removeYear(xLabel)}
          xPercent={0.5}
          yPercent={1}
        />
        <ChartLabel
          className={classes.axisLabel}
          includeMargin={false}
          style={{
            y: -78,
            textAnchor: 'middle',
            transform: 'rotate(-90)',
          }}
          text={getChartLabelWithCurrency('Cost')}
          x="100px"
          xPercent={-0}
          yPercent={0.5}
        />
        {itemsLabelsSeries}
        {itemsVerticalBarSeries}
        {labelsLabelSeries}
        <LabelSeries className={classes.subtotal} data={labels} stack />
      </FlexibleXYPlot>
    </>
  );
};

export const ChartsItemsStyled = withStyles(ChartsItemsStyles)(ChartsItems);

export default ChartsItemsStyled;
