import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../theme/komodo-mui-theme';
import { lowerEstimatePlatformIcon } from '../ImportEstimate/EstimateAddUpload/EstimateImportPlatformSVGsStyles';

export default (theme: KomodoTheme) =>
  createStyles({
    ...lowerEstimatePlatformIcon,
    exportItemsButton: {
      padding: theme.spacing.generic[1],
      minWidth: 146,
      '@media print': {
        display: 'none',
      },
    },
    printButton: {
      padding: theme.spacing.generic[1],
      minWidth: 100,
      '@media print': {
        display: 'none',
      },
    },
    emptyBlank: {
      flex: '1 0 auto',
      padding: `20px 24px`,
      border: theme.border.line,
    },
    exportItemsButtonContainer: {
      display: 'inline',
      padding: theme.spacing.generic[1],
    },
    header: {
      borderRight: theme.border.line,
      alignItems: 'center',
      backgroundColor: theme.palette.backgroundGrey,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      minHeight: '30px',
    },
    bulkItemEditButton: {
      marginLeft: 8,
      padding: theme.spacing.unit,
      paddingLeft: 3 * theme.spacing.unit,
      textTransform: 'capitalize',
      width: 124,
    },
    sticky: {
      borderLeft: theme.border.line,
      borderRight: theme.border.line,
      borderTop: theme.border.line,
      minWidth: 800,
      zIndex: 101,
      position: 'sticky',
      top: 0,
      '@media print': {
        position: 'relative',
      },
    },
    footer: {
      padding: '8px 8px 8px 8px',
      marginTop: 8,
      border: theme.border.line,
      backgroundColor: 'white',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
    visibleTotal: {
      paddingBottom: 0,
    },
    labeledCostRow: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    label: {
      marginLeft: 12,
      textAlign: 'right',
      verticalAlign: 'middle',
      [theme.breakpoints.up('lg')]: {
        minWidth: 120,
      },
      [theme.breakpoints.down('md')]: {
        minWidth: 80,
      },
    },
    budgetLabel: {
      marginLeft: 12,
      textAlign: 'right',
      [theme.breakpoints.up('lg')]: {
        minWidth: 120,
      },
      [theme.breakpoints.down('md')]: {
        minWidth: 80,
      },
    },
    hidePrint: {
      '@media print': {
        display: 'none !important',
      },
    },
    showPrint: {
      display: 'none',
      '@media print': {
        display: 'block',
      },
    },
    buttonContainer: {
      padding: '4px 20px',
    },
    emptyMessage: {
      padding: 24,
      color: theme.palette.disabledGrey,
    },
    footerStatusIcon: {
      marginRight: theme.spacing.unit,
      verticalAlign: 'middle',
      display: 'inline',
    },
    button: {
      fontWeight: 300,
      textTransform: 'none',
      marginRight: theme.spacing.unit * 2,
      color: theme.palette.primaryGrey,
      '@media print': {
        display: 'none',
      },
    },
    listContainer: {
      backgroundColor: theme.palette.joinGrey400,
      color: theme.palette.primaryGrey,
      marginTop: 8,
      marginBottom: 8,
      padding: 4,
      paddingLeft: 16,
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    spacer: {
      flexGrow: 1,
    },
    title: theme.typography.division,
    budget: {
      ...theme.typography.number,
      flex: '0 0 133px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      paddingRight: 8,
    },
    selection: {
      margin: '4px',
      '@media print': {
        margin: '0px',
        marginLeft: 'auto',
      },
    },
    flexDisplay: {
      display: 'flex',
    },
    menuOutline: theme.roundButton,
  });
