import { COMPANY_INSIGHTS_ALERTS } from '../../../constants';
import { ScrollContainer } from '../../scales';
import CompanyTabHeader from '../Standards/CompanyTabHeader';

import InsightAlertsBody from './InsightAlertsBody';

type Props = {
  companyID: UUID;
};

export default function CompanyTabInsightsAlerts(props: Props) {
  const header = (
    <CompanyTabHeader
      description="Set up rules for which projects should be flagged as potentially needing attention in
        Insights. These will not send notifications."
      title={COMPANY_INSIGHTS_ALERTS}
    />
  );

  return (
    <div className="flex grow flex-col overflow-auto">
      {header}
      <ScrollContainer direction="vertical">
        <InsightAlertsBody companyID={props.companyID} />
      </ScrollContainer>
    </div>
  );
}
