import { FC } from 'react';

import { Typography } from '@material-ui/core';
import Https from '@material-ui/icons/Https';

import { Visibility } from '../../../generated/graphql';
import { withStyles } from '../../../theme/komodo-mui-theme';
import { GroupsIcon } from '../../Icons/GroupsIcon';
import ShareItemButton from '../../ItemsList/ItemsSharing/ShareItemButton/ShareItemButton';
import NormalTooltip from '../../NormalTooltip/NormalTooltip';
import { Button, Chip } from '../../scales';
import { isPrivateVisibility } from '../ItemsUtils';

import ItemVisibilityToggleStyles from './ItemVisibilityToggleStyles';
import {
  DISABLE_VISIBILITY_TOGGLE_TOOLTIP,
  DRAFT_ITEM_ICON_COPY_NEW,
  DRAFT_ITEM_LINK_COPY_NEW,
  DRAFT_ITEM_TOOLTIP,
  PUBLISH_ITEM_ICON_COPY_NEW,
  PUBLISH_ITEM_LINK_COPY,
  PUBLISH_ITEM_TOOLTIP,
} from './ItemVisibilityToggleUtils';

type ItemVisibilityToggleProps = {
  classes: Classes<typeof ItemVisibilityToggleStyles>;
  isDisabled: boolean;
  itemID?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  onChange: (event: any) => void;
  visibilitySetting: Visibility;
  setShowPublishItemDialog?: (setShowPublishItemDialog: boolean) => void;
  setVisibilitySetting: (visibility: Visibility) => void;
};

const ItemVisibilityToggle: FC<ItemVisibilityToggleProps> = ({
  classes,
  isDisabled,
  itemID,
  onChange,
  visibilitySetting,
  setShowPublishItemDialog,
  setVisibilitySetting,
}) => {
  const isPrivate = isPrivateVisibility(visibilitySetting);
  const linkCopy = isPrivate ? PUBLISH_ITEM_LINK_COPY : DRAFT_ITEM_LINK_COPY_NEW;
  const iconCaptionCopy = isPrivate ? DRAFT_ITEM_ICON_COPY_NEW : PUBLISH_ITEM_ICON_COPY_NEW;
  const toolTip = isPrivate ? DRAFT_ITEM_TOOLTIP : PUBLISH_ITEM_TOOLTIP;

  const icon = isPrivate ? <Https /> : <GroupsIcon />;
  const updateVisibility = (visibility: Visibility) => {
    if (setShowPublishItemDialog) {
      setShowPublishItemDialog(true);
    } else {
      setVisibilitySetting(visibility);
      onChange({ visibility });
    }
  };

  const onChangeInputVisibility = () => {
    if (isPrivateVisibility(visibilitySetting)) {
      updateVisibility(Visibility.PUBLISHED);
    } else {
      updateVisibility(Visibility.PRIVATE_DRAFT);
    }
  };

  const visibilityIcon = (
    <NormalTooltip title={toolTip}>
      <div className={classes.togglePadding}>
        <Chip data-cy="import-private-feedback" icon={icon} text={iconCaptionCopy} />
      </div>
    </NormalTooltip>
  );

  if (isPrivate && itemID) {
    if (setShowPublishItemDialog) {
      return (
        <ShareItemButton
          isDisabled={isDisabled}
          itemID={itemID}
          itemIDs={[itemID]}
          setShowPublishItemDialog={setShowPublishItemDialog}
        />
      );
    }
    return (
      <Button
        data-cy="publish-draft-button"
        label="Publish Draft"
        onClick={onChangeInputVisibility}
        type="primary"
      />
    );
  }

  return (
    <div className="flex items-center">
      {isDisabled ? (
        <NormalTooltip title={DISABLE_VISIBILITY_TOGGLE_TOOLTIP}>{visibilityIcon}</NormalTooltip>
      ) : (
        <>
          {visibilityIcon}
          <div
            className={classes.togglePadding}
            data-cy="toggle-itemVisibility"
            onClick={onChangeInputVisibility}
            onKeyPress={onChangeInputVisibility}
            role="button"
            tabIndex={0}
          >
            <Typography className={`${classes.text} ${classes.toggleText}`}>{linkCopy}</Typography>
          </div>
        </>
      )}
    </div>
  );
};

export default withStyles(ItemVisibilityToggleStyles)(ItemVisibilityToggle);
