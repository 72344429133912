import { searchEventTypes } from '../../../analytics/analyticsEventProperties';
import { NS_PROGRAM_REPORTING } from '../../../features';
import { useHasFeature } from '../../../hooks/useHasFeature';
import { formatNumber } from '../../../utilities/string';
import { isEnumValue } from '../../../utilities/types';
import { ButtonBar, Switch } from '../../scales';

import useSendSearchAnalytics from './hooks/useSendSearchAnalytics';

export enum SearchToggleValue {
  ALL = 'COMPANY_RESULTS',
  MY = 'MY_RESULTS',
}

type SearchToggleProps = {
  counts: { all: number; my: number };
  isViewingPrograms: boolean;
  labels: { all: string; my: string };
  onChange: (value: SearchToggleValue) => void;
  value: SearchToggleValue;
};

export type SearchToggleParams = SearchToggleProps;

export default function SearchToggle({
  counts,
  isViewingPrograms,
  labels,
  onChange,
  value,
}: SearchToggleProps) {
  const sendSearchAnalytics = useSendSearchAnalytics();

  const hasProgramReporting = useHasFeature(NS_PROGRAM_REPORTING);
  const checked = value === SearchToggleValue.MY;

  if (hasProgramReporting)
    return (
      <Switch
        checked={checked}
        id="allProjects"
        label={`Show only your ${isViewingPrograms ? 'Programs' : 'Projects'}`}
        onChange={() => {
          const newValue = checked ? SearchToggleValue.ALL : SearchToggleValue.MY;
          onChange(newValue);
        }}
      />
    );

  return (
    <ButtonBar
      onChange={(value: string) => {
        if (isEnumValue(SearchToggleValue, value)) {
          onChange(value);
          sendSearchAnalytics(searchEventTypes.SEARCH_TOGGLE, {
            toggleValue: value,
          });
        }
      }}
      options={[
        {
          value: SearchToggleValue.ALL,
          label: `${labels.all} (${formatNumber(counts.all)})`,
        },
        {
          value: SearchToggleValue.MY,
          label: `${labels.my} (${formatNumber(counts.my)})`,
        },
      ]}
      value={value}
    />
  );
}
