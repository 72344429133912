import { FC } from 'react';

import {
  CATEGORY_CELL,
  DIRECT_COSTS,
  MARKUPS,
  MARKUP_VALUE_CELL,
  MILESTONE_MARKUPS,
  OWNER_COSTS,
  QTY,
  S1,
  S2,
  S3,
  S4,
  TOTAL,
  UNIT_PRICE,
} from '../../../constants';
import { EstimateTotalType } from '../../../generated/graphql';
import { checkCostModeIncludesMarkups } from '../../../utilities/costMode';
import { formatCost } from '../../../utilities/currency';
import { hasValidRow, rowIsEmpty } from '../PrintUtils';

import PrintItemDetailsEstimateRow from './PrintItemDetailsEstimateRow';
import { getColumnWidths, getGridLinesFromEstimate } from './PrintItemDetailsUtils';

type TableColumn = {
  name: string;
  width: string;
  rightAlign?: boolean;
};

type PrintItemDetailsEstimateTableProps = {
  canViewTable?: boolean;
  costMode: CostMode;
  estimate: Estimate;
  tableVariant: 'estimate' | 'markup' | 'inherited' | 'ownerCost';
};

const PrintItemDetailsEstimateTable: FC<PrintItemDetailsEstimateTableProps> = ({
  canViewTable = true,
  costMode,
  estimate,
  tableVariant,
}) => {
  const lines = getGridLinesFromEstimate(estimate);
  const {
    markups,
    inheritedMarkups,
    inheritedOwnerCostMarkups,
    totalType,
    fields,
    subtotal,
    markupSubtotal,
    inheritedSubtotal,
    inheritedOwnerCostMarkupsSubtotal,
  } = estimate;
  const hasLines = lines && !!lines.length;
  const hasMarkups =
    (!!markups && !!markups.length) ||
    (!!inheritedOwnerCostMarkups && !!inheritedOwnerCostMarkups.length);
  const hasInheritedMarkups = !!inheritedMarkups && !!inheritedMarkups.length;
  const printTableSummary = checkCostModeIncludesMarkups(costMode) || tableVariant === 'estimate';
  const isLEMSOU = totalType === EstimateTotalType.TOTAL_TYPE_COST_TYPES;

  let tableName;
  let tableColumns: string[] = ['#'];
  let gridRows: PrintGridRow[] = [];
  let subtotalIndex;
  let subtotalValue;
  let rowNum: number;
  const tableColumnArray: TableColumn[] = [];
  let printTable = true;

  let markupTableColumns = ['#', 'Description', 'Value Type', 'Value', 'Applies to...', 'Total'];
  const columnWidths = getColumnWidths(totalType, tableVariant, fields);

  switch (tableVariant) {
    case 'estimate':
      tableName = DIRECT_COSTS;
      fields.forEach((field: Field) => {
        if (field.type === CATEGORY_CELL && !tableColumns.includes(CATEGORY_CELL)) {
          tableColumns.push(CATEGORY_CELL);
        }
        if (field.name) {
          if (field.group) {
            tableColumns.push(`${field.group} ${field.name}`);
          } else {
            tableColumns.push(field.name);
          }
        }
      });
      gridRows = [...lines];
      subtotalIndex = S1;
      subtotalValue = formatCost(subtotal);
      rowNum = 0;
      printTable = hasLines;
      break;
    case 'markup':
      tableName = MARKUPS;
      tableColumns = markupTableColumns;
      gridRows = [...markups];
      subtotalIndex = S2;
      subtotalValue = formatCost(markupSubtotal);
      rowNum = 0;
      printTable = hasMarkups && canViewTable && checkCostModeIncludesMarkups(costMode);
      break;
    case 'ownerCost':
      tableName = OWNER_COSTS;
      tableColumns = markupTableColumns;
      gridRows = inheritedOwnerCostMarkups ? [...inheritedOwnerCostMarkups] : [];
      subtotalIndex = S4;
      subtotalValue = formatCost(inheritedOwnerCostMarkupsSubtotal);
      rowNum = 0;
      printTable = hasMarkups && canViewTable && checkCostModeIncludesMarkups(costMode);
      break;
    case 'inherited':
      // for inherited markups we also want to include the displayType column
      markupTableColumns = [markupTableColumns[0], 'Type', ...markupTableColumns.slice(1)];

      tableName = MILESTONE_MARKUPS;
      tableColumns = markupTableColumns;
      gridRows = [...inheritedMarkups];
      subtotalIndex = S3;
      subtotalValue = formatCost(inheritedSubtotal);
      rowNum = 0;
      printTable = hasInheritedMarkups && canViewTable && checkCostModeIncludesMarkups(costMode);
      break;
    default:
      break;
  }

  for (let i = 0; i < tableColumns.length; i += 1) {
    if (tableVariant === 'estimate') {
      tableColumnArray.push({
        name: tableColumns[i],
        width: columnWidths[i],
      });
    } else {
      tableColumnArray.push({
        name: tableColumns[i],
        width: columnWidths[i],
      });
    }
    tableColumnArray[i].rightAlign = [QTY, UNIT_PRICE, TOTAL, MARKUP_VALUE_CELL].includes(
      tableColumnArray[i].name
    );
  }

  const subtotalName = `Subtotal of ${tableName}`;

  // remove any trailing rows that are empty
  if (hasValidRow(gridRows, tableVariant)) {
    while (
      rowIsEmpty(
        tableVariant === 'estimate'
          ? (gridRows[gridRows.length - 1] as unknown as PrintViewLine).cells
          : gridRows[gridRows.length - 1],
        tableVariant
      )
    ) {
      gridRows.pop();
    }
  } else {
    printTable = false;
  }

  if (!gridRows.length) return <></>;

  return (
    <>
      {printTableSummary && (
        <div className="print-table-title print-bold-text print-avoid-page-break">{tableName}</div>
      )}
      {printTable && (
        <>
          <div className="print-table-header-footer print-avoid-page-break">
            <div className="print-table-header-details">
              {tableColumnArray.map((tableColumn) => (
                <div
                  key={tableColumn.name}
                  className={`${
                    isLEMSOU
                      ? 'print-table-header-details-lemsou-text'
                      : 'print-table-header-details-text'
                  } ${tableColumn.rightAlign && 'print-table-align-right'}`}
                  style={{ width: tableColumn.width }}
                >
                  {tableColumn.name}
                </div>
              ))}
            </div>
          </div>

          <div>
            {gridRows.map((row) => {
              rowNum += 1;
              return (
                <PrintItemDetailsEstimateRow
                  key={'id' in row ? row.id : row.map((col) => col.line).join()}
                  columnWidths={columnWidths}
                  fields={fields}
                  gridRow={
                    tableVariant === 'estimate'
                      ? (row as PrintViewLine).cells
                      : (row as PrintGridRow)
                  }
                  inheritedMarkups={inheritedMarkups}
                  isLEMSOU={isLEMSOU}
                  markups={markups}
                  rowNum={rowNum}
                  tableVariant={tableVariant}
                />
              );
            })}
          </div>
        </>
      )}
      {printTableSummary && (
        <div className="print-table-header-footer print-avoid-page-break">
          <div className="print-table-subtotal-details">
            <div className="print-table-subtotal-details-text print-subtotal-index-column">
              {subtotalIndex}
            </div>
            <div className="print-table-subtotal-details-text print-subtotal-column">
              {subtotalName}
            </div>
            <div className="print-table-subtotal-details-text print-table-subtotal-cost-text print-table-align-right">
              {subtotalValue}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default PrintItemDetailsEstimateTable;
