import { useEffect, useRef, useState } from 'react';
import {
  AriaDatePickerProps,
  DateValue,
  mergeProps,
  useDateField,
  useDatePicker,
  useDateSegment,
  useLocale,
} from 'react-aria';
import { DateFieldState, DateSegment, useDateFieldState, useDatePickerState } from 'react-stately';

import { CalendarDate, createCalendar } from '@internationalized/date';
import { Event } from '@material-ui/icons';

import { StrictUnion } from '../../../utilities/types';
import BabyButton from '../BabyButton/BabyButton';
import ClearButton from '../ClearButton/ClearButton';
import ClearIconButton from '../ClearIconButton/ClearIconButton';
import useHasClearButton from '../hooks/useHasClearButton';
import Popover from '../Popover/Popover';

import Calendar from './Calendar';
import { getErrorMessage, isValidDate, toCalendarDate, toISODateString } from './utils';

type BaseProps = {
  'data-cy'?: string;
  defaultValue?: string | null;
  isDisabled?: boolean;
  maxValue?: string | null;
  minValue?: string | null;
  value?: string | null;
};

type LabelledProps = {
  'aria-label'?: string;
  label: string;
};
type UnlabelledProps = {
  'aria-label': string;
  label?: string;
};

type ClearableProps = {
  isClearable: true | boolean;
  onChange?: (value: string | null) => void;
};
type NotClearableProps = {
  isClearable?: false;
  onChange?: (value: string) => void;
};

type Props = BaseProps &
  StrictUnion<LabelledProps | UnlabelledProps> &
  StrictUnion<ClearableProps | NotClearableProps>;

export default function DateInput(props: Props) {
  // This component maintains an internal value in order to properly support
  // both controlled and uncontrolled components. When there is a maxValue or
  // minValue provided it becomes necessary to maintain an internal value
  // separately, as we will want to be able to show an invalid date with an
  // error message without changing the external props.value
  const [value, setValue] = useState(toCalendarDate(props.defaultValue) ?? null);
  useEffect(() => {
    if (props.value === undefined) return;
    setValue((prevValue) => {
      const newValue = toCalendarDate(props.value) ?? null;
      if (prevValue === null && newValue === null) return prevValue;
      if (prevValue && newValue && prevValue.compare(newValue) === 0) return prevValue;
      return newValue;
    });
  }, [props.value]);

  const minValue = toCalendarDate(props.minValue) ?? undefined;
  const maxValue = toCalendarDate(props.maxValue) ?? undefined;
  const errorMessage = getErrorMessage(value, minValue, maxValue);

  const onChange = (newValue: CalendarDate | null) => {
    if (newValue) {
      setValue(newValue);
      if (isValidDate(newValue, minValue, maxValue)) {
        props.onChange?.(toISODateString(newValue));
      }
    } else if (props.isClearable) {
      setValue(null);
      props.onChange?.(null);
    }
  };
  const onClear = () => onChange(null);

  const state = useDatePickerState({
    maxValue,
    minValue,
    onChange,
    value,
  });
  const ref = useRef<HTMLDivElement>(null);
  const { buttonProps, calendarProps, fieldProps, groupProps, labelProps, errorMessageProps } =
    useDatePicker(
      {
        'aria-label': props['aria-label'],
        isDisabled: props.isDisabled,
        label: props.label,
        maxValue,
        minValue,
        shouldForceLeadingZeros: true,
      },
      state,
      ref
    );
  const { hasInlineClearButton, hasTextClearButton } = useHasClearButton({
    isClearable: props.isClearable,
    isDisabled: props.isDisabled,
    label: props.label,
  });

  return (
    <div className="flex flex-col gap-0.5">
      {props.label && (
        <div className="flex">
          <label {...labelProps} className="mr-auto text-type-primary type-label">
            {props.label}
          </label>
          {hasTextClearButton && <ClearButton onClick={onClear} />}
        </div>
      )}
      <div
        {...groupProps}
        ref={ref}
        className={[
          'flex h-10 items-center gap-1 rounded-md border px-2 type-number',
          errorMessage ? 'border-type-error' : 'focus-within:outline',
          props.isDisabled ? 'bg-button-inactive' : 'bg-background-primary',
        ].join(' ')}
        data-cy={props['data-cy'] ?? 'date-input'}
      >
        <BabyButton
          {...mergeProps(buttonProps)}
          aria-label="open calendar"
          data-cy="calendar-button"
          icon={<Event />}
          isDisabled={props.isDisabled}
          onClick={state.toggle}
        />
        <div className="flex-grow">
          <DateField {...fieldProps} />
        </div>
        {hasInlineClearButton && <ClearIconButton onClick={onClear} />}
      </div>
      {errorMessage && (
        <div {...errorMessageProps} className="cursor-default text-type-error type-label">
          {errorMessage}
        </div>
      )}
      {state.isOpen && (
        <Popover className="h-min w-min" placement="bottom start" state={state} triggerRef={ref}>
          <Calendar {...calendarProps} data-cy="calendar" />
        </Popover>
      )}
    </div>
  );
}

function DateField(props: AriaDatePickerProps<DateValue>) {
  const { locale } = useLocale();
  const state = useDateFieldState({
    ...props,
    createCalendar,
    locale,
  });
  const ref = useRef(null);
  const { fieldProps } = useDateField(props, state, ref);

  return (
    <div {...fieldProps} ref={ref} className="inline-flex">
      {state.segments.map((segment, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <DateFieldSegment key={i} segment={segment} state={state} />
      ))}
    </div>
  );
}

function DateFieldSegment(props: { segment: DateSegment; state: DateFieldState }) {
  const ref = useRef(null);
  const { segmentProps } = useDateSegment(props.segment, props.state, ref);
  const {
    segment: { isEditable, isPlaceholder, text, type },
    state: { isDisabled },
  } = props;

  return (
    <div
      {...segmentProps}
      ref={ref}
      className={[
        'group box-content rounded-md text-right outline-none',
        isEditable && !isDisabled
          ? 'decoration-selection-focus-outline decoration-2 underline-offset-2 focus-visible:underline'
          : 'cursor-default',
        isPlaceholder || isDisabled ? 'text-type-inactive' : 'text-type-primary',
      ].join(' ')}
      data-cy={isEditable ? type : undefined} // can be 'month' 'day' or 'year'
    >
      {text}
    </div>
  );
}
