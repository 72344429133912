import { MouseEvent } from 'react';
import { Link } from 'react-router-dom';

import { KeyboardBackspace } from '@material-ui/icons';

export enum ArrowDirection {
  NEXT = 'next',
  PREVIOUS = 'previous',
}

type Props = {
  children: JSX.Element;
  direction: ArrowDirection;
  link?: string;
  title?: string;
  onClick?: (event: MouseEvent<HTMLElement>) => void;
};

const NavigationArrowLink = ({ children, direction, link = '#', onClick, title }: Props) => {
  const forwardArrow = direction === ArrowDirection.NEXT && (
    <KeyboardBackspace className="rotate-180 icon-md" />
  );
  const backArrow = direction === ArrowDirection.PREVIOUS && (
    <KeyboardBackspace className="icon-md" />
  );

  const containerComp = (
    <>
      {backArrow}
      {children}
      {forwardArrow}
    </>
  );

  if (!onClick) return <div className="flex items-center gap-1 opacity-50">{containerComp}</div>;

  return (
    <Link className="flex items-center gap-1" onClick={onClick} title={title} to={link}>
      {containerComp}
    </Link>
  );
};

export default NavigationArrowLink;
