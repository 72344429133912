import { ReactNode, RefObject, useRef } from 'react';
import { OverlayContainer, mergeProps, useOverlayPosition, useTooltip } from 'react-aria';
import { useOverlayTriggerState } from 'react-stately';

type Props = {
  children: ReactNode;
  isOpen: boolean;
  /** @default 8 */
  offset?: Parameters<typeof useOverlayPosition>[0]['offset'];
  /** @default "bottom left" */
  placement?: Parameters<typeof useOverlayPosition>[0]['placement'];
  triggerRef: RefObject<HTMLDivElement | SVGElement>;
};

export default function TransparentOverlay(props: Props) {
  const overlayRef = useRef(null);
  const popoverState = useOverlayTriggerState({ isOpen: props.isOpen });
  const { tooltipProps } = useTooltip({}, popoverState);
  const { overlayProps } = useOverlayPosition({
    offset: props.offset,
    overlayRef,
    placement: props.placement,
    targetRef: props.triggerRef,
  });

  return (
    <OverlayContainer>
      <div
        ref={overlayRef}
        className="pointer-events-none overflow-hidden"
        {...mergeProps(tooltipProps, overlayProps)}
      >
        {props.children}
      </div>
    </OverlayContainer>
  );
}

TransparentOverlay.defaultProps = {
  offset: 8,
  placement: 'bottom left',
};
