import { CheckboxType } from '../../../api/gqlEnums';
import {
  ALLOWANCE_TYPE_DISPLAY,
  CONTINGENCY_TYPE_DISPLAY,
  DRAW_TYPE_DISPLAY,
  LUMP_SUM_DISPLAY,
  MARKUP_APPLIED,
  MARKUP_APPLIED_DISPLAY,
  MARKUP_NOT_APPLIED,
  MARKUP_NOT_APPLIED_DISPLAY,
  MARKUP_TYPE_DISPLAY,
  PERCENT_DISPLAY,
} from '../../../constants';
import { MarkupDisplayType, MarkupType } from '../../../generated/graphql';
import { SelectEntry, getEntry } from '../../Select/JoinSelect/JoinSelect';

export type MarkupSelectCellEditorVariant = 'MarkupType' | 'MarkupDisplayType' | 'MarkupInherited';

export const markupDisplayValueMap = new Map([
  [MarkupDisplayType.MARKUP, MARKUP_TYPE_DISPLAY],
  [MarkupDisplayType.CONTINGENCY, CONTINGENCY_TYPE_DISPLAY],
  [MarkupDisplayType.ALLOWANCE, ALLOWANCE_TYPE_DISPLAY],
  [MarkupDisplayType.DRAW, DRAW_TYPE_DISPLAY],
]);

const markupValueTypeEntries = [
  getEntry(MarkupType.PERCENT, PERCENT_DISPLAY),
  getEntry(MarkupType.FIXED, LUMP_SUM_DISPLAY),
];

export const getMarkupDisplayTypeString = (
  displayType: MarkupDisplayType | undefined,
  isItem: boolean
) => {
  if (!displayType) return '';
  const displayValue = markupDisplayValueMap.get(displayType);
  let prefix = '';
  if (isItem) {
    if (displayType === MarkupDisplayType.MARKUP) {
      prefix = 'Item ';
    }
  }
  return `${prefix}${displayValue}`;
};

const markupDisplayTypeEntries = (isItem = false) => {
  return [
    getEntry(
      MarkupDisplayType.MARKUP,
      getMarkupDisplayTypeString(MarkupDisplayType.MARKUP, isItem)
    ),
    getEntry(
      MarkupDisplayType.CONTINGENCY,
      getMarkupDisplayTypeString(MarkupDisplayType.CONTINGENCY, isItem)
    ),
    getEntry(
      MarkupDisplayType.ALLOWANCE,
      getMarkupDisplayTypeString(MarkupDisplayType.ALLOWANCE, isItem)
    ),
  ];
};

const markupInheritedEntries = [
  getEntry(MARKUP_APPLIED, MARKUP_APPLIED_DISPLAY),
  getEntry(MARKUP_NOT_APPLIED, MARKUP_NOT_APPLIED_DISPLAY),
];

export type MarkupSelectCellEditorInput = {
  value: string;
  entries: SelectEntry[];
  onChange: (newValue: string | null) => void;
};

export const getMarkupSelectCellEditorInput = (
  variant: MarkupSelectCellEditorVariant,
  cell: GridCellValue,
  updateCell: (newValue?: MarkupTypeCell | MarkupDisplayTypeCell) => void,
  isItem = false
): MarkupSelectCellEditorInput => {
  let value = '';
  let entries: SelectEntry[] = [];
  let onChange: (newValue: string | null) => void = () => {};

  switch (variant) {
    case 'MarkupType':
      entries = markupValueTypeEntries;
      if ('type' in cell) value = cell.type;

      onChange = (type: string | null) => {
        const isValidMarkupType = type === MarkupType.FIXED || type === MarkupType.PERCENT;
        if (isValidMarkupType) updateCell({ type });
      };

      break;
    case 'MarkupDisplayType':
      entries = markupDisplayTypeEntries(isItem);
      if ('displayType' in cell) value = cell.displayType || '';

      onChange = (displayType: string | null) => {
        const isValidDisplayType =
          displayType === MarkupDisplayType.MARKUP ||
          displayType === MarkupDisplayType.CONTINGENCY ||
          displayType === MarkupDisplayType.ALLOWANCE;
        if (isValidDisplayType) updateCell({ displayType });
      };
      break;
    default:
      entries = markupInheritedEntries;

      onChange = (newValue: string | null) => {
        if (newValue !== value) updateCell();
      };
      if ('checked' in cell) {
        const checked = cell.checked === CheckboxType.CHECKED;
        value = checked ? MARKUP_APPLIED : MARKUP_NOT_APPLIED;
      }
  }
  return {
    value,
    entries,
    onChange,
  };
};
