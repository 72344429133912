import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../../theme/komodo-mui-theme';

export default (theme: KomodoTheme) =>
  createStyles({
    checkbox: {
      height: 20,
      paddingRight: 12,
      width: 20,
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingTop: 5,
      minHeight: 24,
    },
    headLeftText: {
      ...theme.typography.caption,
      color: theme.palette.shadedGrey,
      fontWeight: 400,
      paddingLeft: 3,
      lineHeight: 'unset',
    },
    horizontal: {
      display: 'flex',
      flexDirection: 'column',
    },
    incorporateAccepted: {
      flexDirection: 'row',
      paddingTop: 16,
    },
    innerContainer: {
      alignContent: 'center',
      background: 'none',
      paddingBottom: 5,
      paddingLeft: 4,
      paddingRight: 4,
    },
    label: {
      ...theme.typography.caption,
      color: theme.palette.shadedGrey,
      fontWeight: 400,
      marginTop: 8,
    },
  });
