import { FC } from 'react';

import { PieChartOptions, PieChartSize } from './ChartsPieGraphUtils';

type Props = {
  chartSize: PieChartSize;
  options?: PieChartOptions;
};

const ChartsPieGraphGradient: FC<Props> = ({ chartSize, options }) => {
  // if you want to include a gradient then there must be an inside diameter on the chart
  if (!options?.includeGradient) return null;
  if (!chartSize.insideDiameter) throw new Error('Must have an inside diameter for a gradient');

  const startGradient = chartSize.insideDiameter / chartSize.diameter;
  return (
    <>
      <radialGradient cx="50%" cy="50%" fx="50%" fy="50%" id="gradient">
        <stop offset={startGradient} stopColor="darkgrey" stopOpacity=".4" />
        <stop offset={(startGradient + 1) / 2} stopColor="darkgrey" stopOpacity="0" />
      </radialGradient>
      <circle cx="50%" cy="50%" fill="url(#gradient)" r={chartSize.diameter / 2} />
    </>
  );
};

export default ChartsPieGraphGradient;
