import { CircularProgress } from '@material-ui/core';
import { useState } from 'react';
import { ProgramCategorizationsQuery } from '../../generated/graphql';
import DashboardAnchor from '../dashboard/DashboardAnchor';
import { Select } from '../scales';
import EstimateChart from './EstimateChart';
import EstimateChartLegend from './EstimateChartLegend';
import { useProgramCategorizedTotalsQuery } from './hooks/useProgramCategorizedTotalsQuery';

type Props = {
  programID: UUID;
  selectedProjectIDs: UUID[];
  programCategorizations: ProgramCategorizationsQuery['programCategorizations'];
};

export default function ProgramCombinedEstimateChart(props: Props) {
  // Track selected categorization
  const [selectedCategorization, setSelectedCategorization] = useState<string>(
    props.programCategorizations[0].name
  );

  // Fetch costs for selected categorization
  const programCategorizedTotalsQueryResult = useProgramCategorizedTotalsQuery(
    props.programID,
    props.selectedProjectIDs,
    selectedCategorization
  );
  const programCategorizedTotals = (
    programCategorizedTotalsQueryResult.data ?? programCategorizedTotalsQueryResult.previousData
  )?.programCategorizedTotals;

  return (
    <div>
      <DashboardAnchor anchor="Combined Estimate" />
      <div className="flex w-full flex-col">
        <div className="flex justify-end gap-2">
          {/* TODO: add unit toggle */}
          <div className="flex w-52">
            <Select
              entries={props.programCategorizations.map((cat) => ({
                id: cat.name,
                label: cat.name,
              }))}
              label="Group By"
              onChange={setSelectedCategorization}
              value={selectedCategorization}
            />
          </div>
        </div>
        <EstimateChartLegend />
        <div className="relative">
          {!programCategorizedTotals && (
            <div className="flex h-[500px] w-full items-center justify-center">
              <CircularProgress />
            </div>
          )}
          {programCategorizedTotals && (
            <EstimateChart
              categoryCostData={programCategorizedTotals}
              selectedCategorization={selectedCategorization}
            />
          )}
        </div>
      </div>
    </div>
  );
}
