import { FC } from 'react';

import { Typography } from '@material-ui/core';

import { UploadPlatform } from '../../../api/gqlEnums';
import { withStyles } from '../../../theme/komodo-mui-theme';

import { EstimatePlatformSubTitleText } from './EstimateAddUploadDragUtils';
import styles from './ImportEstimateUpperSVGTestStyles';

type UpperSVGTextProps = {
  estimatePlatform: UploadPlatform;
  classes: Classes<typeof styles>;
};

const UpperSVGText: FC<UpperSVGTextProps> = ({ estimatePlatform, classes }) => {
  if (estimatePlatform === UploadPlatform.EMPTY_STATE) {
    return null;
  }

  const { title, subtitle } = EstimatePlatformSubTitleText[estimatePlatform];

  return (
    <>
      <Typography className={classes.textTitle}>{title}</Typography>
      <Typography className={classes.textSubTitle}>
        {estimatePlatform !== UploadPlatform.EXCEL && <b>Step 1: Export Estimate </b>}
        <>&nbsp;</>
        {subtitle}
      </Typography>
    </>
  );
};

export default withStyles(styles)(UpperSVGText);
