import { FC } from 'react';

import { Typography } from '@material-ui/core';

import theme, { withStyles } from '../../../theme/komodo-mui-theme';
import { CHART_DEFAULT_BAR_HEIGHT } from '../../ExecutiveDashboard/ExecutiveDashboardUtils';

import ChartsSingleHorizontalBarGraphSegmentStyles from './ChartsSingleHorizontalBarGraphSegmentStyles';

type ChartsSingleHorizontalBarGraphProps = {
  barHeight: number;
  classes: Classes<typeof ChartsSingleHorizontalBarGraphSegmentStyles>;
  color: string;
  isPlaceholder: boolean;
  label: string;
  labelLocation: number;
  placeholderText: string;
  projectID: UUID;
  selected: UUID | null;
  setSelected: (id: string | null) => void;
  width: number;
};
const ChartsSingleHorizontalBarGraphSegment: FC<ChartsSingleHorizontalBarGraphProps> = ({
  classes,
  width,
  barHeight,
  color,
  projectID,
  label,
  isPlaceholder,
  placeholderText = '',
  selected = null,
  setSelected,
  labelLocation,
}) => {
  if (width < 1 || label === '0' || Number.isNaN(width)) return null;

  if (isPlaceholder || !label)
    return (
      <td key={`${projectID}-${width}-${color}`} style={{ width }}>
        <Typography className={classes.placeholderText}>{placeholderText}</Typography>
      </td>
    );

  const blackText = color === theme.palette.chartLightGrey; // we need more constrast on the light gray background.
  const opacity = !selected || selected === projectID ? 1 : 0.4; // make the other bars 60% transparent when hovering
  const showLabel = width >= 15; // if the bar is too small then don't show the label in the bar
  const tooltipWidth = parseInt(label, 10) > 9 ? 23 : CHART_DEFAULT_BAR_HEIGHT - 2; // the popup

  return (
    <td
      key={`${projectID}-${width}-${color}-open-items`}
      onBlur={() => setSelected(null)}
      onFocus={() => setSelected(projectID)}
      onMouseOut={() => setSelected(null)}
      onMouseOver={() => setSelected(projectID)}
      style={{
        width,
        maxWidth: width,
        backgroundColor: color,
        paddingBottom: 5,
        height: barHeight,
        opacity,
      }}
    >
      {/* if the label is too small to read then dont' show it */}
      {showLabel && (
        <Typography className={`${classes.barLabel} ${blackText ? '' : classes.whiteText}`}>
          {label}
        </Typography>
      )}
      {!showLabel && selected === projectID && (
        <div
          style={{
            backgroundColor: color,
            borderRadius: 8,
            bottom: labelLocation,
            height: CHART_DEFAULT_BAR_HEIGHT,
            opacity: 1,
            position: 'relative',
            right: tooltipWidth / 2 - width / 2,
            textAlign: 'center',
            width: tooltipWidth,
            zIndex: 100,
          }}
        >
          <Typography
            className={`${classes.barLabel} ${classes.tooltip} ${
              blackText ? '' : classes.whiteText
            }`}
          >
            {label}
          </Typography>
        </div>
      )}
    </td>
  );
};

export default withStyles(ChartsSingleHorizontalBarGraphSegmentStyles)(
  ChartsSingleHorizontalBarGraphSegment
);
