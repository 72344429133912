import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';

export default (theme: KomodoTheme) =>
  createStyles({
    container: {
      ...theme.typography.webParagraph,
      display: 'flex',
      flexFlow: 'column',
      gap: '16px',
      '& strong': {
        fontWeight: 500,
      },
    },
    dateFrom: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    dateFrom_picker: {
      flexBasis: '50%',
    },
    dateTo: {
      background: theme.palette.backgroundGrey,
      padding: 8,
    },
    timeFactor: { display: 'flex', gap: '3px' },
    error: {
      color: theme.palette.error.main,
    },
    projectInfo: {
      display: 'flex',
      '& > :first-child': {
        flexGrow: 1,
      },
    },
    projectInfo_time: {
      display: 'flex',
      alignItems: 'center',
      gap: '4px',
    },
  });
