import { FC, useContext } from 'react';

import { getBranchDisplayName } from '../../ItemsList/ItemsListUtils';
import { ProjectTermStore } from '../../ProjectDisplaySettings/TerminologyProvider';
import PrintGroupHeader from '../PrintSharedComponents/PrintGroupHeader';

import PrintItemsListItem from './PrintItemsListItem';
import PrintItemsListOption from './PrintItemsListOption';

type PrintItemsListBodyProps = {
  shouldDisplayCosts: boolean;
  itemsAssigneeMap: Map<string, UserLink | undefined | null>;
  itemsTree?: ItemsTree | ItemsTreeBranch;
  itemsListItemsMap: Map<UUID, ItemsListItem>;
};

const PrintItemsListBody: FC<PrintItemsListBodyProps> = ({
  shouldDisplayCosts,
  itemsAssigneeMap,
  itemsTree,
  itemsListItemsMap,
}) => {
  const t = useContext(ProjectTermStore);

  const { branches, items, level } = itemsTree ?? {};
  const { displayName = undefined, displayNameForPrint = undefined } =
    itemsTree && 'displayName' in itemsTree ? itemsTree : {};
  const isBranch = !!displayName;
  let headerFlag = false;
  return (
    <>
      {isBranch && (
        <PrintGroupHeader
          hasHeader={level === 1}
          headerText={getBranchDisplayName(displayNameForPrint || displayName, t)}
        />
      )}
      {items?.map((item: ItemsTreeItem, index: number) => {
        const isLastItem = index === items.length - 1;
        const hasOptions = !!item.options.length;
        return (
          <div key={item.itemLikeID}>
            <div className="print-avoid-page-break">
              <PrintItemsListItem
                itemsAssigneeMap={itemsAssigneeMap}
                itemsListItemsMap={itemsListItemsMap}
                itemsTreeItem={item}
                shouldDisplayCosts={shouldDisplayCosts}
              />
              {(!isLastItem || hasOptions) && <hr className="print-sub-divider" />}
            </div>
            {item.options.map((option, optionIndex: number) => {
              const isLastOption = optionIndex === item.options.length - 1;
              return (
                <div key={option.itemLikeID} className="print-avoid-page-break">
                  <PrintItemsListOption
                    itemsAssigneeMap={itemsAssigneeMap}
                    itemsListItemsMap={itemsListItemsMap}
                    itemsTreeOption={option}
                    shouldDisplayCosts={shouldDisplayCosts}
                  />
                  {(!isLastItem || !isLastOption) && <hr className="print-sub-divider" />}
                </div>
              );
            })}
          </div>
        );
      })}

      {!!items?.length && !!branches?.length && <hr className="print-sub-divider" />}
      {branches?.map((subtree: ItemsTreeBranch) => {
        const addDividerAbove = headerFlag;
        if (!subtree.totalItems) return <></>;
        headerFlag = true;
        return (
          <>
            {isBranch && addDividerAbove && <hr className="print-sub-divider" />}
            <PrintItemsListBody
              itemsAssigneeMap={itemsAssigneeMap}
              itemsListItemsMap={itemsListItemsMap}
              itemsTree={subtree}
              shouldDisplayCosts={shouldDisplayCosts}
            />
          </>
        );
      })}
    </>
  );
};

export default PrintItemsListBody;
