import { FC, MouseEvent, useState } from 'react';

import { Button, Divider, Menu, MenuItem } from '@material-ui/core';

import { withStyles } from '../../../theme/komodo-mui-theme';
import { removeYear } from '../../../utilities/string';

import IssueChip from './IssueChip';
import styles from './IssuesSelectStyles';

type IssuesSelectProps = {
  classes: Classes<typeof styles>;
  issues: ImportEstimateError[] | undefined;
  onToColumn: (id: UUID) => void;
};

const IssuesSelect: FC<IssuesSelectProps> = ({ classes, issues = [], onToColumn }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const total = issues.reduce(
    (accumulator, { unresolvedCount }) => accumulator + unresolvedCount,
    0
  );
  const title = `${total} issues need your attention`;

  if (issues.length === 0 || total === 0) return null;

  return (
    <div>
      <Button
        aria-controls="customized-menu"
        aria-haspopup="true"
        className={classes.select}
        onClick={handleClick}
      >
        <IssueChip isHeader subTitle={undefined} title={title} />
      </Button>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        className={classes.popup}
        getContentAnchorEl={null}
        id="customized-menu"
        keepMounted
        onClose={handleClose}
        open={Boolean(anchorEl)}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {issues.map((issue: ImportEstimateError) => {
          const { id, content = {}, unresolvedCount = 0 } = issue;
          const {
            categorization: { builtin, name, id: categorizationID },
          } = content as ImportErrorContentMissingCategories;
          const categorizationName = removeYear(name, builtin);
          return (
            <MenuItem
              key={id}
              className={classes.item}
              onClick={() => onToColumn(categorizationID)}
            >
              <IssueChip
                subTitle={`${categorizationName}`}
                title={`${unresolvedCount} unrecognized categories in`}
              />
              <Divider />
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
};

export const StyledIssuesSelect = withStyles(styles)(IssuesSelect);

export default StyledIssuesSelect;
