import { ApolloClient } from '@apollo/client';

import { ItemAttachmentsQuery, ItemAttachmentsQueryVariables } from '../../../generated/graphql';
import { useQuery } from '../../../hooks/useMountAwareQuery';

import { itemAttachmentsQuery } from './queries';

export const readItemAttachments = (
  client: ApolloClient<object>,
  id: UUID
): (Asset | RemoteAsset)[] => {
  const data = client.readQuery<ItemAttachmentsQuery, ItemAttachmentsQueryVariables>({
    query: itemAttachmentsQuery,
    variables: { id },
  });
  return (data?.itemAttachments || []) as (Asset | RemoteAsset)[];
};

export const writeItemAttachments = (
  client: ApolloClient<object>,
  itemAttachments: (Asset | RemoteAsset)[],
  id: UUID
) => {
  client.writeQuery<ItemAttachmentsQuery, ItemAttachmentsQueryVariables>({
    query: itemAttachmentsQuery,
    data: {
      itemAttachments,
    },
    variables: { id },
  });
};

const useItemAttachmentsQuery = (id?: UUID) =>
  useQuery<ItemAttachmentsQuery, ItemAttachmentsQueryVariables>(itemAttachmentsQuery, {
    variables: { id },
    skip: !id,
  });

export default useItemAttachmentsQuery;
