import { ComponentProps } from 'react';

import Card from '../../common/Card';
import CreateScenarioButton from '../../common/CreateScenarioButton';

type Props = {
  hasNoScenarios: boolean;
  onCreateScenario: ComponentProps<typeof CreateScenarioButton>['onCreateScenario'];
};

const SCENARIO_IMAGE_WIDTH = 360;

const ScenarioPlaceholder = (props: Props) => (
  <Card>
    <div className="flex flex-grow flex-col place-content-center items-center gap-4 type-body1">
      {props.hasNoScenarios ? (
        <div className="flex flex-col items-center gap-2 p-6">
          <img
            alt="No scenarios add new animation"
            src="/img/Scenarios/Scenarios_AddNew.gif"
            width={SCENARIO_IMAGE_WIDTH}
          />
          <div className="text-type-inactive">No scenarios. Try to add one!</div>
        </div>
      ) : (
        <img
          alt="No scenarios add new static"
          src="/img/Scenarios/Scenarios_AddNew.svg"
          width={SCENARIO_IMAGE_WIDTH}
        />
      )}
      <div className="w-[289px]">
        <CreateScenarioButton onCreateScenario={props.onCreateScenario} />
      </div>
      <div>or</div>
      <div>Drag an existing scenario in from the Library.</div>
    </div>
  </Card>
);

export default ScenarioPlaceholder;
