import { cloneDeep, get, set } from 'lodash';

import useSearchProjectsFilterOptionsQuery from '../components/ProjectsList/hooks/useSearchProjectsFilterOptionsQuery';
import { SearchResultType } from '../generated/graphql';
import { idsToNames } from '../utilities/utilities';

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
export const useTypesIdsToNames = (variablesOuter: any = {}, ...paths: string[]) => {
  const { data: filterOptionsData } = useSearchProjectsFilterOptionsQuery(SearchResultType.ALL);
  const projectTypes = filterOptionsData?.searchProjectsFilterOptions?.projectTypes || [];
  const variables = cloneDeep(variablesOuter);
  paths.forEach((path) => set(variables, path, idsToNames(get(variables, path), projectTypes)));
  return variables;
};
