import {
  ProgramCollaboratorsQuery,
  ProgramCollaboratorsQueryVariables,
} from '../../../../generated/graphql';
import { QueryHookOptions, useQuery } from '../../../../hooks/useMountAwareQuery';
import { programCollaboratorsQuery } from '../../queries';

export const useProgramCollaboratorsQuery = (
  programID?: UUID,
  options: QueryHookOptions<ProgramCollaboratorsQuery, ProgramCollaboratorsQueryVariables> = {}
) =>
  useQuery<ProgramCollaboratorsQuery, ProgramCollaboratorsQueryVariables>(
    programCollaboratorsQuery,
    {
      variables: { programID },
      skip: !programID,
      ...options,
    }
  );
