import { generateTitle } from '../../Items/ItemsListItem/ItemsListItemUtils';
import SearchText from '../../Search/SearchText/SearchText';

type Props = {
  name: string;
  number: string;
  searchText: string;
};

const ItemName = (props: Props) => (
  <div
    className="flex-grow overflow-hidden text-ellipsis whitespace-nowrap type-body2"
    data-cy="item-tile-name"
  >
    <SearchText
      searchTerm={props.searchText}
      text={generateTitle({ name: props.name, number: props.number })}
    />
  </div>
);

export default ItemName;
