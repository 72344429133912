import { ReactNode, useRef } from 'react';
import {
  AriaPopoverProps,
  DismissButton,
  FocusScope,
  OverlayContainer,
  usePopover,
} from 'react-aria';
import { OverlayTriggerState } from 'react-stately';

import { ActiveOverlayStateContext } from '../hooks/useActiveOverlayState';

import useActiveTheme from './useActiveTheme';

interface Props extends Omit<AriaPopoverProps, 'popoverRef'> {
  children: ReactNode;
  className?: string;
  'data-cy'?: string;
  state: OverlayTriggerState;
  isWidthOfTrigger?: boolean;
}

export default function Popover(props: Props) {
  const popoverRef = useRef(null);
  const { popoverProps, underlayProps } = usePopover(
    {
      // If the popover is the width of its trigger, there is no need for the
      // popover to define its padding as the trigger should define its own.
      containerPadding: props.isWidthOfTrigger ? 0 : undefined,
      ...props,
      popoverRef,
    },
    props.state
  );

  const width = props.isWidthOfTrigger
    ? props.triggerRef.current?.getBoundingClientRect().width
    : undefined;

  const theme = useActiveTheme(props.triggerRef);

  return (
    <OverlayContainer>
      {/**
       * TODO: We've intentionally not turned on `contain` for the FocusScope because of
       * incompatibilities with MUI. If `contain` is on, then things like a MUI Select
       * or Combobox may not gain focus when necessary. Eg, a MUI Select with a search input
       * will not be focusable.
       *
       * When we're no longer rendering those kinds of elements, we can enable `contain` to
       * properly handle a11y and tab navigation.
       * */}
      <FocusScope autoFocus restoreFocus>
        <ActiveOverlayStateContext.Provider value={props.state}>
          {!props.isNonModal && <div {...underlayProps} className="fixed inset-0" />}
          <div
            {...popoverProps}
            ref={popoverRef}
            className={`overflow-hidden overflow-y-auto rounded border border-border-muted bg-background-primary text-type-primary shadow scrollbar-theme ${
              props.className ?? ''
            }`}
            data-cy={props['data-cy']}
            data-theme={theme}
            style={{
              ...popoverProps.style,
              width,
            }}
          >
            {props.children}
            {/* Add a hidden DismissButton to allow screen reader/kb users to close the popup easily. */}
            <DismissButton onDismiss={props.state.close} />
          </div>
        </ActiveOverlayStateContext.Provider>
      </FocusScope>
    </OverlayContainer>
  );
}
