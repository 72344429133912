import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';
import { DIALOG_PADDING } from '../../Dialogs/DialogsStyles';

const styles = (theme: KomodoTheme) =>
  createStyles({
    button: {
      backgroundColor: theme.palette.joinPrimary,
      borderRadius: 20,
      color: 'white',
    },
    buttonDelete: {
      marginLeft: theme.spacing.unit * 2,
    },
    content: {
      padding: DIALOG_PADDING,
      borderBottom: theme.border.line,
    },
    description: {
      color: theme.palette.shadedGrey,
      fontSize: '0.8em',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    disabled: {
      color: theme.palette.disabledGrey,
    },
    dragging: {
      cursor: 'grabbing',
    },
    dragHandle: {
      cursor: 'grab',
    },
    enabled: {
      color: theme.palette.accepted,
    },
    flex: { display: 'flex', gap: '8px' },
    gap: {
      gap: '1.375rem',
    },
    labelContainer: {
      width: 120, // room for expected text
      marginRight: 0,
    },
    listItemDragging: {
      '&:hover': {
        background: theme.palette.backgroundFocusSelectGreen,
      },
    },
    listItem: {
      borderTop: theme.border.line,
      display: 'grid',
      justifyContent: 'space-between',
    },
    gridTemplate: {
      gridTemplateColumns: 'auto minmax(100px, 1fr) minmax(100px, 2fr) 158px',
    },
    gridTemplateNoEdit: {
      gridTemplateColumns: 'minmax(100px, 1fr) minmax(100px, 2fr) 10px',
    },
    name: {
      fontWeight: 400,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    nameContainer: {
      alignItems: 'center',
      display: 'flex',
      lineHeight: '0.9rem',
      justifyContent: 'flex-start',
      overflow: 'hidden',
    },
    ul: {
      listStyle: 'disc',
      margin: '8px 0 8px 16px',
    },
  });

export default styles;
