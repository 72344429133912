import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';

const COLLAPSE_HEIGHT = 30;
const COLLAPSE_PADDING = 8;

export default (theme: KomodoTheme) =>
  createStyles({
    captionText: {
      padding: '2px 4px 0px 4px',
      ...theme.typography.h4,
    },
    headerContainer: {
      display: 'flex',
      alignItems: 'center',
      background: theme.palette.backgroundGrey,
      height: COLLAPSE_HEIGHT,
      marginBottom: 3,
      '&:last-child': {
        marginBottom: 0,
      },
    },
    headerText: {
      paddingTop: 3,
      ...theme.typography.h3CardHeader,
    },
    headerTextContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
      padding: `0px ${COLLAPSE_PADDING}px`,
    },
    disabledText: {
      color: theme.palette.disabledGrey,
    },
    expand: {
      cursor: 'pointer',
    },
    fullWidth: {
      width: '100%',
      cursor: 'pointer',
    },
    iconContainer: {
      color: theme.palette.joinPrimary,
      marginLeft: COLLAPSE_PADDING,
      marginTop: 10,
    },
    iconContainerVariance: {
      color: theme.palette.joinPrimary,
      marginLeft: COLLAPSE_PADDING,
    },
    selectContent: {
      display: 'flex',
      alignItems: 'center',
      padding: `2px 0px 2px ${COLLAPSE_PADDING}px`,
    },
    selectedText: {
      paddingLeft: 4,
      minWidth: 20,
    },
    tooltipIcon: {
      height: 18,
      color: theme.palette.disabledGrey,
    },
  });
