import { isUUID } from 'validator';

import { ItemBookmarksQuery, ItemBookmarksQueryVariables } from '../../../../generated/graphql';
import { useQuery } from '../../../../hooks/useMountAwareQuery';

import { itemBookmarksQuery } from './queries';

export default function useItemBookmarksQuery(
  itemID: UUID | null | undefined,
  onCompleted?: (data: ItemBookmarksQuery) => void
) {
  return useQuery<ItemBookmarksQuery, ItemBookmarksQueryVariables>(itemBookmarksQuery, {
    variables: { itemID },
    skip: !itemID || !isUUID(itemID),
    context: { batch: true },
    onCompleted,
  });
}
