import { Status } from '../../../../../generated/graphql';
import { withStyles } from '../../../../../theme/komodo-mui-theme';
import { getItemStatusLabel } from '../../../../../utilities/item-status';
import ItemsIconsMap from '../../../../ItemsList/ItemsIcons/ItemsIconsMap';

import styles from './ItemStatusCellStyles';
import ParagraphCell from './ParagraphCell';

export default withStyles(styles)(function CostCell({
  classes,
  status,
}: {
  classes: Classes<typeof styles>;
  status: Status;
}) {
  return (
    <ParagraphCell>
      <div className={classes.container}>
        <ItemsIconsMap status={status} variant="large" />
        <div>{getItemStatusLabel(status)}</div>
      </div>
    </ParagraphCell>
  );
});
