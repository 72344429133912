import { NOT_CHOSEN, PENDING } from '../../../constants';
import { Status } from '../../../generated/graphql';
import { RouteKeys } from '../../../routes/paths';
import { generateSharedPath } from '../../../utilities/routes/links';
import { getProjectIdFromUrl } from '../../../utilities/url';
import ItemsOptionChipSquare from '../ItemsOptionChipSquare/ItemsOptionChipSquare';
import { getStatusIsChosen } from '../ItemsUtils';

type Props = {
  parentStatus: Status;
  options: {
    id: UUID;
    name: string;
    number: string;
    status: Status;
  }[];
};

export default function ItemsOptionChipSquareList(props: Props) {
  const projectId = getProjectIdFromUrl();
  const hasChosenItem = getStatusIsChosen(props.parentStatus);

  return (
    <>
      {props.options.map((option) => {
        const { id, name, number, status } = option;
        const displayStatus = status === PENDING && !!hasChosenItem ? NOT_CHOSEN : status;
        return (
          <ItemsOptionChipSquare
            key={id}
            name={String(name)}
            number={String(number)}
            status={displayStatus}
            url={generateSharedPath(RouteKeys.PROJECT_ITEMS_ITEM, { projectId, itemId: id })}
          />
        );
      })}
    </>
  );
}
