import { RefObject, useRef } from 'react';
import { useDrop } from 'react-aria';

import { isNonNullable } from '../../../utilities/types';
import { getLimitDropOperation } from '../common/DropTargetUtils';

export const SCENARIO_ID_DROP_TYPE = 'scenario-id';

type Props = {
  children: (args: {
    dropProps: ReturnType<typeof useDrop>['dropProps'] & { ref: RefObject<HTMLDivElement> };
    isDropTarget: boolean;
  }) => JSX.Element;
  onScenarioDrop: (scenarioID: UUID) => void;
};

export default function DropTargetScenarios(props: Props) {
  const ref = useRef<HTMLDivElement>(null);
  const getDropOperation = getLimitDropOperation(SCENARIO_ID_DROP_TYPE);
  const { dropProps, isDropTarget } = useDrop({
    ref,
    getDropOperation,
    async onDrop(e) {
      const droppedItems = await Promise.all(
        e.items.map(async (item) => {
          if (item.kind === 'text' && item.types.has(SCENARIO_ID_DROP_TYPE)) {
            return item.getText(SCENARIO_ID_DROP_TYPE);
          }

          return undefined;
        })
      );

      const scenarioIds = droppedItems.filter(isNonNullable);
      scenarioIds.forEach((scenarioId) => props.onScenarioDrop?.(scenarioId));
    },
  });
  return props.children({
    dropProps: {
      ...dropProps,
      ref,
      role: 'button',
      tabIndex: 0,
    },
    isDropTarget,
  });
}
