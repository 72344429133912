import {
  COMPANY_TYPE_ARCHITECTURE_DESIGN,
  COMPANY_TYPE_DESIGN_ENGINEERING,
  COMPANY_TYPE_ENGINEERING_CONSTRUCTION,
} from '../../../constants';
import { useCompanyUserQuery } from '../../../hooks/useCompanyUserQuery';
import { MountPolicy } from '../../../hooks/usePolicyOnFirstMount';
import { RouteKeys } from '../../../routes/paths';
import usePermissions from '../../../utilities/permissions/usePermissions';

export const useDefaultProjectRoute = () => {
  const { loading: loadingPermissions } = usePermissions();
  const { data, loading: loadingCompanyUser } = useCompanyUserQuery(MountPolicy.SKIP_ON_MOUNT);
  const companyType = data?.companyUser?.company?.type ?? '';

  const route = getDefaultProjectRoute(companyType);
  const loading = loadingCompanyUser || loadingPermissions;
  return { route, loading };
};

export const getDefaultProjectRoute = (companyType: string) => {
  const shouldDirectToItemsList = [
    COMPANY_TYPE_ARCHITECTURE_DESIGN,
    COMPANY_TYPE_DESIGN_ENGINEERING,
    COMPANY_TYPE_ENGINEERING_CONSTRUCTION,
  ].includes(companyType);

  return !shouldDirectToItemsList ? RouteKeys.PROJECT_DASHBOARD : RouteKeys.PROJECT_ITEMS;
};
