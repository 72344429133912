import { FC } from 'react';

import { useReactiveVar } from '@apollo/client';

import { projectCompsSetInputVar } from '../../../api/apollo/reactiveVars';
import { ProjectCompSectionType } from '../../../api/gqlEnums';
import { MarkupMode } from '../../../generated/graphql';
import { withStyles } from '../../../theme/komodo-mui-theme';
import { MarkupModeSelect } from '../../dragon-scales';
import { useProjectCompsSetInputUpdateFunctions } from '../ProjectCompsSetInputStore/ProjectCompsSetInputUpdaters';
import { useIsProjectCompSectionCollapsed } from '../ProjectCompsSetUtils';
import styles from '../ProjectCompsStyles';

import ProjectCompSectionHeader from './ProjectCompSectionHeader';
import { DISPLAY_SETTINGS_LABEL } from './ProjectCompsSectionLabels';

type ProjectCompSectionDisplaySettingsProps = {
  classes: Classes<typeof styles>;
};

const ProjectCompSectionDisplaySettings: FC<ProjectCompSectionDisplaySettingsProps> = ({
  classes,
}) => {
  const { markupMode } = useReactiveVar(projectCompsSetInputVar);
  const { setMarkupMode } = useProjectCompsSetInputUpdateFunctions();
  const settingsSection = ProjectCompSectionType.SECTION_SETTINGS;
  const isSettingsCollapsed = useIsProjectCompSectionCollapsed(settingsSection);

  return (
    <div className={classes.fieldsContainer}>
      <ProjectCompSectionHeader
        collapseSection={settingsSection}
        isTogglable
        label={DISPLAY_SETTINGS_LABEL}
      />
      {!isSettingsCollapsed && (
        <div className={classes.settingInputs}>
          <div className={classes.selectorPadding} />
          <MarkupModeSelect
            availableMarkupModes={[
              MarkupMode.ALLOCATED_MARKUPS,
              MarkupMode.SEPARATED_MARKUPS,
              MarkupMode.NO_MARKUPS,
            ]}
            onChange={setMarkupMode}
            value={markupMode ?? MarkupMode.ALLOCATED_MARKUPS}
          />
        </div>
      )}
    </div>
  );
};

export default withStyles(styles)(ProjectCompSectionDisplaySettings);
