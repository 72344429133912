import { gql } from '../../../../api/graphqlFragments';
import { ideaFields } from '../../queries';

export const ideaQuery = gql`
  query idea($projectID: UUID!, $ideaID: UUID!) {
    idea(projectID: $projectID, ideaID: $ideaID) {
      ...ideaFields
    }
  }
  ${ideaFields}
`;
