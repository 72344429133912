import { ScaleBand, ScaleLinear, scaleBand, scaleLinear } from 'd3-scale';
import { Link } from 'react-router-dom';

import { Status } from '../../../../../generated/graphql';
import { RouteKeys } from '../../../../../routes/paths';
import { generateSharedPath } from '../../../../../utilities/routes/links';
import { pluralizeString } from '../../../../../utilities/string';
import SVGWithDimensions from '../../../../Charts/ChartsD3/SVGWithDimensions';
import { useChartDimensions } from '../../../../Charts/ChartsD3/useChartDimensions';
import { renderCostString } from '../../../../CostReport/CostReportUtils';
import { ItemStatusIcon } from '../../../../dragon-scales';
import { Tooltip } from '../../../../scales';
import useItemStatusCostReport from '../../hooks/useItemStatusCostReport';

type Props = {
  projectID: UUID;
  activeMilestoneID?: UUID;
  quantity?: Quantity;
};

export default function ItemsChart(props: Props) {
  const report = useItemStatusCostReport(props.projectID, props.activeMilestoneID, props.quantity);

  const numItems =
    (report.accepted.numItems ?? 0) +
    (report.incorporated.numItems ?? 0) +
    (report.pending.numItems ?? 0) +
    (report.rejected.numItems ?? 0);

  const itemsListURL = generateSharedPath(RouteKeys.PROJECT_ITEMS, {
    projectId: props.projectID,
    search: `?currentMilestone[0]=${props.activeMilestoneID}`,
  });

  if (numItems === 0) {
    return (
      <Link
        className="flex h-full w-full items-center justify-center text-type-muted type-body2 focus-visible:outline"
        to={itemsListURL}
      >
        No items
      </Link>
    );
  }

  return (
    <ItemsChartView
      acceptedItems={report.accepted}
      incorporatedItems={report.incorporated}
      pendingItems={report.pending}
      rejectedItems={report.rejected}
      to={itemsListURL}
    />
  );
}

type ItemStatusData = {
  cost?: AddDeductCost;
  numItems?: number;
};

type ViewProps = {
  acceptedItems: ItemStatusData;
  incorporatedItems: ItemStatusData;
  to: string;
  pendingItems: ItemStatusData;
  rejectedItems: ItemStatusData;
};
export function ItemsChartView(props: ViewProps) {
  /**
   * When computing the margins here, we reserve space above and below the bar
   * via margins. The top and bottom margin will both contain the labels which
   * have a 16px line-height and are placed with a 4px gap between them and the
   * bar. The bottom margin will also contain the item status icons, serving as
   * an x-axis. The icons will also be 16px tall, with a 4px gap reserved.
   */
  const { ref, dimensions } = useChartDimensions({
    height: 106,
    marginTop: 16,
    marginRight: 0,
    marginBottom: 40,
    marginLeft: 0,
  });

  const allItems = [
    props.acceptedItems,
    props.incorporatedItems,
    props.pendingItems,
    props.rejectedItems,
  ];

  const maxY = allItems.reduce((currentMaxY, value) => {
    // the types are lying to us...the BE gives us a string not a number :\
    const adds = value.cost?.adds ? parseInt(`${value.cost?.adds}`, 10) : 0;
    return adds > currentMaxY ? adds : currentMaxY;
  }, 0);

  const minY = allItems.reduce((currentMinY, value) => {
    // the types are lying to us...the BE gives us a string not a number :\
    const deducts = value.cost?.deducts ? parseInt(`${value.cost?.deducts}`, 10) : 0;
    return deducts < currentMinY ? deducts : currentMinY;
  }, 0);

  const x = scaleBand<Status>()
    .domain([Status.REJECTED, Status.PENDING, Status.ACCEPTED, Status.INCORPORATED])
    .range([0, dimensions.width])
    .padding(0.1);

  const y = scaleLinear<number, number>().domain([minY, maxY]).range([dimensions.boundedHeight, 0]);

  return (
    <SVGWithDimensions ref={ref} data-cy="items-chart" dimensions={dimensions}>
      <Bar
        data={props.rejectedItems}
        itemStatus={Status.REJECTED}
        to={`${props.to}&status[0]=${Status.REJECTED}`}
        x={x}
        y={y}
      />
      <Bar
        data={props.pendingItems}
        itemStatus={Status.PENDING}
        to={`${props.to}&status[0]=${Status.PENDING}`}
        x={x}
        y={y}
      />
      <Bar
        data={props.acceptedItems}
        itemStatus={Status.ACCEPTED}
        to={`${props.to}&status[0]=${Status.ACCEPTED}`}
        x={x}
        y={y}
      />
      <Bar
        data={props.incorporatedItems}
        itemStatus={Status.INCORPORATED}
        to={`${props.to}&status[0]=${Status.INCORPORATED}`}
        x={x}
        y={y}
      />
      <line
        className="pointer-events-none stroke-chart-axis"
        x1={0}
        x2={dimensions.width}
        y1={y(0)}
        y2={y(0)}
      />
    </SVGWithDimensions>
  );
}

const BAR_LABEL_GAP = 4;
const BAR_FILL: Partial<Record<Status, string>> = {
  [Status.PENDING]: 'fill-item-status-pending',
  [Status.REJECTED]: 'fill-item-status-rejected',
  [Status.ACCEPTED]: 'fill-item-status-accepted',
  [Status.INCORPORATED]: 'fill-item-status-incorporated',
};

function Bar(props: {
  data: ItemStatusData;
  itemStatus: Status;
  to: string;
  x: ScaleBand<Status>;
  y: ScaleLinear<number, number>;
}) {
  if (!props.data.cost) return null;

  const xLeft = props.x(props.itemStatus) ?? 0;
  const xMid = xLeft + props.x.bandwidth() / 2;

  return (
    <Tooltip
      content={
        <div className="flex flex-col">
          <div>{`${props.data.numItems} ${props.itemStatus.toLocaleLowerCase()} ${pluralizeString(
            'item',
            props.data.numItems ?? 0
          )}`}</div>
          <ul className="ml-4 list-disc">
            <li>{renderCostString({ cost: props.data.cost.adds, isSigned: true })} adds</li>
            <li>{renderCostString({ cost: props.data.cost.deducts, isSigned: true })} deducts</li>
          </ul>
        </div>
      }
      placement="right"
    >
      <Link className="focus-visible:outline" to={props.to}>
        <g data-cy={`bar-${props.itemStatus.toLocaleLowerCase()}`}>
          <BarLabel
            cost={props.data.cost.adds}
            data-cy="label-adds"
            dominantBaseline="auto"
            x={xMid}
            y={props.y(props.data.cost.adds) - BAR_LABEL_GAP}
          />

          <rect
            className={BAR_FILL[props.itemStatus]}
            data-cy="bar-rect"
            height={props.y(props.data.cost.deducts) - props.y(props.data.cost.adds)}
            width={props.x.bandwidth()}
            x={xLeft}
            y={props.y(props.data.cost.adds)}
          />

          <BarLabel
            cost={props.data.cost.deducts}
            data-cy="label-deducts"
            dominantBaseline="hanging"
            x={xMid}
            y={props.y(props.data.cost.deducts) + BAR_LABEL_GAP}
          />

          <g
            data-cy="icon"
            transform={`translate(${xMid - 8 /* 8 is half the width of the icon */}, ${
              74 /* The chart's bounded height minus the height of the icon */
            })`}
          >
            <foreignObject className="h-4 w-4">
              <ItemStatusIcon size="sm" value={props.itemStatus} />
            </foreignObject>
          </g>
        </g>
      </Link>
    </Tooltip>
  );
}

function BarLabel(props: {
  cost: number;
  'data-cy': string;
  dominantBaseline?: string;
  x: number;
  y: number;
}) {
  if (!parseInt(`${props.cost}`, 10)) return null;

  return (
    <text
      className="fill-type-primary text-center lining-nums tabular-nums type-label"
      data-cy={props['data-cy']}
      dominantBaseline={props.dominantBaseline}
      textAnchor="middle"
      x={props.x}
      y={props.y}
    >
      {renderCostString({ cost: props.cost, isSigned: true })}
    </text>
  );
}
