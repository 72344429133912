import {
  PreviewProjectRoleQuery,
  PreviewProjectRoleQueryVariables,
} from '../../../generated/graphql';
import { QueryHookOptions, useQuery } from '../../../hooks/useMountAwareQuery';

import { previewProjectRole } from './queries';

export default function usePreviewProjectRoleQuery(
  projectID: UUID | undefined,
  roleID: UUID | undefined,
  options: QueryHookOptions<PreviewProjectRoleQuery, PreviewProjectRoleQueryVariables> = {}
) {
  return useQuery<PreviewProjectRoleQuery, PreviewProjectRoleQueryVariables>(previewProjectRole, {
    variables: { projectID, roleID },
    skip: !projectID || !roleID,
    ...options,
  });
}
