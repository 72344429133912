import { FC } from 'react';

import { MOST_RECENTLY_CREATED } from '../../../constants';
import ExecutiveDashboardChartFrame from '../ExecutiveDashboardChartFrame/ExecutiveDashboardChartFrame';
import { useRecentlyCreatedProjectsQuery } from '../ExecutiveDashboardHooks';
import {
  CHART_LARGE,
  ExecutiveDashboardDataProps,
  MOST_RECENTLY_CREATED_HEIGHT,
  MOST_RECENTLY_CREATED_PROJECTS_TOOLTIP,
} from '../ExecutiveDashboardUtils';

import ExecutiveDashboardMostRecentlyCreated from './ExecutiveDashboardMostRecentlyCreated';

const ExecutiveDashboardMostRecentlyCreatedData: FC<ExecutiveDashboardDataProps> = ({
  projectIDs,
  onHover,
  projectMap,
}) => {
  const { data: { recentlyCreatedProjects } = { recentlyCreatedProjects: [] }, loading } =
    useRecentlyCreatedProjectsQuery(projectIDs);

  return (
    <>
      {loading ? (
        // TODO - implement loading state instead of using empty state
        <ExecutiveDashboardChartFrame
          component={
            <ExecutiveDashboardMostRecentlyCreated projectMap={projectMap} projects={[]} />
          }
          height={MOST_RECENTLY_CREATED_HEIGHT}
          title={MOST_RECENTLY_CREATED}
          tooltip={MOST_RECENTLY_CREATED_PROJECTS_TOOLTIP}
          variant={CHART_LARGE}
        />
      ) : (
        <div onFocus={onHover} onMouseOver={onHover}>
          <ExecutiveDashboardChartFrame
            component={
              <ExecutiveDashboardMostRecentlyCreated
                projectMap={projectMap}
                projects={recentlyCreatedProjects}
              />
            }
            height={MOST_RECENTLY_CREATED_HEIGHT}
            title={MOST_RECENTLY_CREATED}
            tooltip={MOST_RECENTLY_CREATED_PROJECTS_TOOLTIP}
            variant={CHART_LARGE}
          />
        </div>
      )}
    </>
  );
};

export default ExecutiveDashboardMostRecentlyCreatedData;
