import { FC } from 'react';

import { Typography } from '@material-ui/core';

import { withStyles } from '../../../theme/komodo-mui-theme';
import NormalTooltip from '../../NormalTooltip/NormalTooltip';

import ExecutiveDashboardDataHeadingStyles from './ExecutiveDashboardDataHeadingStyles';

type ExecutiveDashboardDataHeadingProps = {
  classes: Classes<typeof ExecutiveDashboardDataHeadingStyles>;
  label: string;
  value: number | string;
  tooltip?: string;
};

const ExecutiveDashboardDataHeading: FC<ExecutiveDashboardDataHeadingProps> = ({
  classes,
  label,
  value,
  tooltip = '',
}) => (
  <div>
    <Typography className={classes.label}>{label}</Typography>
    <NormalTooltip title={tooltip}>
      <Typography className={classes.value}>{value}</Typography>
    </NormalTooltip>
  </div>
);

export default withStyles(ExecutiveDashboardDataHeadingStyles)(ExecutiveDashboardDataHeading);
