import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../../theme/komodo-mui-theme';

export default (theme: KomodoTheme) =>
  createStyles({
    hintContainer: {
      display: 'flex',
      padding: '6px 4px 6px 4px',
    },
    hintContainerBorder: {
      borderTop: theme.border.line,
    },
    hintText: {
      ...theme.typography.h4,
    },
    hintTextBold: {
      ...theme.typography.h4Title,
    },
    hintTextContainer: {
      paddingLeft: 2,
    },
    hint: {
      flexGrow: 1,
      padding: 20,
      color: theme.palette.joinPrimary,
    },
    hintSubtitle: {
      borderTop: theme.border.line,
      padding: '4px 6px 2px 6px',
    },
    link: {
      ...theme.typography.link,
    },
  });
