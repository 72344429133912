import { FC } from 'react';
import { Link } from 'react-router-dom';

import { ItemHistoryLink, Visibility } from '../../../generated/graphql';
import { RouteKeys } from '../../../routes/paths';
import { generateSharedPath } from '../../../utilities/routes/links';
import { ItemStatusIcon } from '../../dragon-scales';
import { getItemDescription } from '../../Items/ItemActivityFeed/ItemActivityFeedUtils';
import { Chip } from '../../scales';

type Props = {
  isItemHistory: boolean;
  itemLink: ItemHistoryLink | undefined;
  eventID: string;
  projectID: UUID;
};

const ItemHistoryItems: FC<Props> = ({ isItemHistory = false, itemLink, eventID, projectID }) => {
  if (isItemHistory || !itemLink) return null;
  const { id, deprecated, status, number, name, visibility } = itemLink;
  const isDraftItem = visibility === Visibility.PRIVATE_DRAFT;
  const textClass =
    'flex items-center overflow-hidden overflow-ellipsis whitespace-nowrap text-type-muted type-body2';

  const itemDescription = deprecated ? (
    <div className={textClass}>{getItemDescription(number, name, deprecated)}</div>
  ) : (
    <Link
      className={`${textClass} hover:text-type-link`}
      to={generateSharedPath(RouteKeys.PROJECT_ITEMS_ITEM, {
        projectId: projectID,
        itemId: id,
      })}
    >
      {getItemDescription(number, name, deprecated, isDraftItem)}
    </Link>
  );

  return (
    <>
      <div key={`${eventID}${id}`} className="mt-1 flex gap-1">
        {!deprecated && <ItemStatusIcon size="sm" value={status} />}
        {isDraftItem && (
          <div className="flex items-center">
            <Chip text="Draft" />
          </div>
        )}
        {itemDescription}
      </div>
    </>
  );
};

export default ItemHistoryItems;
