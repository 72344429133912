import { createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
const styles = (theme: any) =>
  createStyles({
    axisLabel: {
      fill: 'black',
      fontWeight: 400,
      '& text': {
        fontSize: 16,
        fill: 'black',
      },
    },
    formControl: {
      margin: theme.spacing.unit,
      alignSelf: 'baseline',
      minWidth: 120,
    },
    hidePrint: {
      '@media print': {
        display: 'none',
      },
    },
    label: {
      fontSize: theme.typography.label.fontSize,
    },
    linechart: {
      fontFamily: theme.typography.fontFamily,
      marginTop: 20,
    },
    rowContainer: {
      contentAlign: 'right',
    },
    legendContainer: {
      display: 'flex',
      '& .rv-discrete-color-legend-item__title': {
        ...theme.typography.label,
      },
    },
  });

export default styles;
