import { useQuery } from '@apollo/client';

import {
  ProjectCompsSetInputQuery,
  ProjectCompsSetInputQueryVariables,
} from '../../../../generated/graphql';

import { projectCompsSetInput } from './queries';

export const useProjectCompsSetInputQuery = (reportID?: UUID) => {
  // Use bare Apollo useQuery so we can set the fetchPolicy
  // We don't want this query to be cached or updated from the cache since we always
  // want just the data that we got on the very first response.
  return useQuery<ProjectCompsSetInputQuery, ProjectCompsSetInputQueryVariables>(
    projectCompsSetInput,
    {
      fetchPolicy: 'no-cache',
      variables: {
        reportID: reportID ?? '',
      },
      skip: !reportID,
    }
  );
};
