import { useMutation } from '@apollo/client';

import { UPDATE_MILESTONE_DATE } from '../../../actions/actionTypes';
import { analyticsEvent } from '../../../analytics/analyticsEventProperties';
import {
  SetMilestoneDateMutation,
  SetMilestoneDateMutationVariables,
} from '../../../generated/graphql';
import useSendAnalytics from '../../../hooks/useSendAnalytics';
import { getMilestonesQuery } from '../../Milestones/hooks/queries';

import { setMilestoneDateMutation } from './queries';

function useUpdateMilestoneDate() {
  const [updateMilestoneDateFunc, mutationResult] = useMutation<
    SetMilestoneDateMutation,
    SetMilestoneDateMutationVariables
  >(setMilestoneDateMutation);

  const sendAnalytics = useSendAnalytics();

  const submitFunc = (projectId: UUID, milestoneID: UUID, date: string | null) => {
    updateMilestoneDateFunc({
      variables: {
        projectID: projectId,
        input: { milestoneID, date },
      },
      update: (dataProxy, { data }) => {
        const setMilestoneDate = data?.setMilestoneDate;
        if (!setMilestoneDate) return;
        // update apollo cache
        dataProxy.writeQuery({
          query: getMilestonesQuery,
          variables: { projectID: projectId },
          data: { milestones: setMilestoneDate },
        });
        sendAnalytics(analyticsEvent(UPDATE_MILESTONE_DATE));
      },
    });
  };

  return [submitFunc, mutationResult] as const;
}

export default useUpdateMilestoneDate;
