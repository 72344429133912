import {
  ConnectUserSourceMutation,
  ConnectUserSourceMutationVariables,
  SourceSystem,
} from '../../../generated/graphql';

export const getUserSourceVariables = (
  code: string,
  sourceSystem: SourceSystem,
  state?: string | null
) => {
  const variables: ConnectUserSourceMutationVariables = {
    publicToken: code,
    sourceSystem,
  };
  // Egnyte requires a host URI to identify the "company" for a user for this connection
  if (sourceSystem !== SourceSystem.EGNYTE) {
    return variables;
  }
  // This is where Egnyte returns the instance info :shrug:
  const host = state || '';
  return { ...variables, host };
};

export const DISPLAY_INTERVAL = 200; // Based on the animation duration

export const percentOfCountdown = (progress: number, countdown: number) =>
  (100 * progress) / countdown;

export const countdownLoop = (
  newValue: number,
  setCountdownProgress: (newValue: number) => void,
  onComplete: () => void
) => {
  if (newValue <= 0) {
    onComplete();
  } else {
    setCountdownProgress(newValue);
    setTimeout(() => {
      countdownLoop(newValue - DISPLAY_INTERVAL, setCountdownProgress, onComplete);
    }, DISPLAY_INTERVAL);
  }
};

export const checkResultData = (expected: boolean, data?: ConnectUserSourceMutation | null) =>
  !!data && data.connectUserSource === expected;
