import {
  GetAllMilestonesContingencyReportQuery,
  GetAllMilestonesContingencyReportQueryVariables,
} from '../../../generated/graphql';
import { useQuery } from '../../../hooks/useMountAwareQuery';

import { getAllMilestonesContingencyReportQuery } from './queries';

export const useAllMilestonesContingencyReportQuery = (
  projectID: UUID,
  filteredContingencies?: string[],
  filteredAllowances?: string[]
) =>
  useQuery<GetAllMilestonesContingencyReportQuery, GetAllMilestonesContingencyReportQueryVariables>(
    getAllMilestonesContingencyReportQuery,
    {
      variables: { projectID, filteredContingencies, filteredAllowances },
      skip: !projectID,
    }
  );
