import { ProjectCostBreakdown } from '../../generated/graphql';
import DashboardAnchor from '../dashboard/DashboardAnchor';
import ProjectCostBreakdownReportChart from '../ProjectCostBreakdownReport/ProjectCostBreakdownReportChart';
import { ReportTableHeader } from '../ProjectCostBreakdownReport/ProjectCostBreakdownReportTable';

type Props = {
  breakdown: Omit<ProjectCostBreakdown, 'type'>[];
  colorMap: Map<string, string>;
  headers: ReportTableHeader[];
  name: string;
  total: number;
};

export default function ProgramDashboardPieChart({
  breakdown,
  headers,
  colorMap,
  name,
  total,
}: Props) {
  return (
    <div>
      <DashboardAnchor anchor={name} />
      <div className="flex w-full justify-center">
        <div className="w-[1000px]">
          <ProjectCostBreakdownReportChart
            costBreakdownTypeToColorMap={colorMap}
            headers={headers}
            label="Running Total"
            projectCostBreakdownReport={{ breakdown, total }}
          />
        </div>
      </div>
    </div>
  );
}
