import {
  FORECASTING_REPORTS_METRIC_QUANTITY,
  FORECASTING_REPORTS_METRIC_UNIT_TYPE,
} from '../../../../tagConstants';
import { withStyles } from '../../../../theme/komodo-mui-theme';
import { EMPTY_COST } from '../../../../utilities/string';

import styles from './QuantityMagnitudeCellStyles';

export default withStyles(styles)(
  ({ classes, report }: { classes: Classes<typeof styles>; report: ForecastingReport }) => (
    <div>
      <div
        className={classes.titleText}
        data-cy={`${FORECASTING_REPORTS_METRIC_UNIT_TYPE}-${report.name}`}
      >
        {report.metadata.pinnedUnit.abbreviationPlural}
      </div>
      <div
        className={classes.valueText}
        data-cy={`${FORECASTING_REPORTS_METRIC_QUANTITY}-${report.name}`}
      >
        {report.metadata.pinnedUnitQuantityMagnitude ?? EMPTY_COST}
      </div>
    </div>
  )
);
