import {
  assetFields,
  attachmentFields,
  attachmentFieldsQuery,
  gql,
} from '../../../api/graphqlFragments';

export const itemAttachmentsQuery = gql`
  query itemAttachments($id: UUID!) {
    itemAttachments(itemID: $id) {
      ...attachmentFieldsQuery
    }
  }
  ${attachmentFieldsQuery}
`;

export const projectAssetsQuery = gql`
  query projectAssets($id: UUID!, $sortBy: FilesSortBy) {
    projectAssets(projectID: $id, sortBy: $sortBy) {
      ...assetFields
    }
  }
  ${assetFields}
`;

export const milestoneAttachmentsQuery = gql`
  query milestoneAttachments($id: UUID!) {
    milestoneAttachments(milestoneID: $id) {
      ...attachmentFieldsQuery
    }
  }
  ${attachmentFieldsQuery}
`;

export const assetQuery = gql`
  query asset($assetID: UUID!) {
    asset(assetID: $assetID) {
      ...assetFields
    }
  }
  ${assetFields}
`;

export const assetsQuery = gql`
  query assets($assetIDs: [UUID!]!) {
    assets(assetIDs: $assetIDs) {
      ...assetFields
    }
  }
  ${assetFields}
`;

export const attachToItemMutation = gql`
  mutation attachToItem(
    $itemID: UUID!
    $assetInput: AssetInput
    $remoteAssetInput: RemoteAssetInput
  ) {
    attachToItem(itemID: $itemID, assetInput: $assetInput, remoteAssetInput: $remoteAssetInput) {
      ...attachmentFields
    }
  }
  ${attachmentFields}
`;

export const attachToMilestoneMutation = gql`
  mutation attachToMilestone(
    $milestoneID: UUID!
    $assetInput: AssetInput
    $remoteAssetInput: RemoteAssetInput
  ) {
    attachToMilestone(
      milestoneID: $milestoneID
      assetInput: $assetInput
      remoteAssetInput: $remoteAssetInput
    ) {
      ...attachmentFields
    }
  }
  ${attachmentFields}
`;

export const detachFromItemMutation = gql`
  mutation detachFromItem($projectID: UUID!, $itemID: UUID!, $assetID: UUID, $remoteAssetID: UUID) {
    detachFromItem(
      projectID: $projectID
      itemID: $itemID
      assetID: $assetID
      remoteAssetID: $remoteAssetID
    ) {
      ...attachmentFields
    }
  }
  ${attachmentFields}
`;

export const detachFromMilestoneMutation = gql`
  mutation detachFromMilestone(
    $projectID: UUID!
    $milestoneID: UUID!
    $assetID: UUID
    $remoteAssetID: UUID
  ) {
    detachFromMilestone(
      projectID: $projectID
      milestoneID: $milestoneID
      assetID: $assetID
      remoteAssetID: $remoteAssetID
    ) {
      ...attachmentFields
    }
  }
  ${attachmentFields}
`;

export const assetThumbnailQuery = gql`
  query assetThumbnail($assetID: UUID!, $dimension: ImageDimension) {
    asset(assetID: $assetID, dimension: $dimension) {
      id
      location
      name
      thumbnailURL
    }
  }
`;
