import { Key, useCallback } from 'react';

import { PENDING } from '../../../constants';
import { Status } from '../../../generated/graphql';
import { getItemStatusLabel } from '../../../utilities/item-status';
import { pluralizeCountString } from '../../../utilities/string';
import { isEnumValue } from '../../../utilities/types';
import {
  getAcceptedIncorporatedOption,
  getStatusIsAcceptedIncorporated,
} from '../../Items/ItemsUtils';
import { Select } from '../../scales';
import ItemStatusIcon from '../ItemStatusIcon/ItemStatusIcon';

type Props = {
  'aria-label'?: string;
  'data-cy'?: string;
  availableStatuses: Status[];
  defaultValue?: Status;
  isDisabled?: boolean;
  label?: string;
  onChange: (status: Status) => void;
  options?: Pick<Option, 'number' | 'status'>[];
  value?: Status;
};

const ItemStatusSelect = (props: Props) => {
  // When an option is Accepted, all other options that are Pending are converted to Not Chosen
  // however these Not Chosen options can actually be changed back to Pending (though the change is not visible)
  // there is little clarity into why we need both Not Chosen and Pending since they seem to be interchangable
  // we should ultimately remove Not Chose from the Status enum, but for now it is what it is
  // I am checking if it is part of the availableStatuses here, to conditionally show it as an option
  const includesNotChosen = props.availableStatuses.includes(Status.NOTCHOSEN);
  const statuses = [
    Status.NOTAPPLICABLE,
    Status.REJECTED,
    includesNotChosen ? Status.NOTCHOSEN : Status.PENDING,
    Status.ACCEPTED,
    Status.INCORPORATED,
  ];

  // This is being used to generate additional item with options status information
  // such as Pending (6 options), Incorporated (#1.1) or Accepted (1.1).
  // We execute this function only when we have options passed as props.
  // I would love to get rid of this, but I also see the need to explain
  // users which option is driving the item status selection.
  const generateItemStatusSubtitle = (status: Status): string => {
    const statusLabel = getItemStatusLabel(status);
    if (props.options) {
      // its an Item
      const acceptedOrIncorporatedOption = getAcceptedIncorporatedOption(props.options);
      if (acceptedOrIncorporatedOption && getStatusIsAcceptedIncorporated(status)) {
        return `${statusLabel} (#${acceptedOrIncorporatedOption.number})`;
      }
      const optionsLength = props.options.length;
      if (props.value === PENDING && optionsLength > 0) {
        return `${statusLabel} (${pluralizeCountString('option', optionsLength)})`;
      }
    }
    return statusLabel;
  };

  const entries = statuses.map((status) => {
    const disabled = !props.availableStatuses.includes(status);
    return {
      disabled,
      id: status,
      label: generateItemStatusSubtitle(status),
      startAdornment: <ItemStatusIcon disabled={disabled} value={status} />,
    };
  });

  const { onChange: onChangeStatus } = props;
  const onChange = useCallback(
    (value: Key | null) => {
      if (typeof value !== 'string' || !isEnumValue(Status, value)) return;
      onChangeStatus(value);
    },
    [onChangeStatus]
  );

  return (
    <Select
      aria-label={props['aria-label']}
      data-cy={props['data-cy']}
      defaultValue={props.defaultValue}
      entries={entries}
      isDisabled={props.isDisabled}
      label={props.label}
      onChange={onChange}
      value={props.value}
    />
  );
};

export default ItemStatusSelect;
