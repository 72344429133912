import { FC } from 'react';

import { withStyles } from '../../../theme/komodo-mui-theme';
import { formatGridTemplateColumns } from '../../ProjectComps/ProjectCompsCostTable/ProjectCompCostTableUtils';
import { getColumnInputDisplayString } from '../../ProjectComps/ProjectCompsSetUtils';

import PrintColumnHeaderData from './PrintProjectComp/PrintColumnHeaderData';
import PrintColumnHeaderTitle from './PrintProjectComp/PrintColumnHeaderTitle';
import styles from './PrintProjectComp/PrintProjectCompStyles';
import {
  COLUMN_FONT_CLASSES,
  CompsPrintThumbnailSize,
  getColumnIndex,
  getGroupSize,
  isAverageCompType,
} from './PrintProjectCompsUtils';

type PrintProjectCompsColumnGroupHeaderProps = {
  classes: Classes<typeof styles>;
  columnGroup: (ProjectComp | AverageComp)[];
  costTableColumnInputs?: CostTableColumnInputs;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  displayHeaderData?: boolean;
  groupIndex: number;
  onThumbnailLoad: (columnIndex: number) => void;
  parentProject?: ProjectProps;
  projectCompsSet: ProjectCompsSet;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  showNotes: boolean;
  thumbnailSize: CompsPrintThumbnailSize;
};

const PrintProjectCompsColumnGroupHeader: FC<PrintProjectCompsColumnGroupHeaderProps> = ({
  classes,
  columnGroup,
  costTableColumnInputs,
  displayHeaderData,
  groupIndex,
  onThumbnailLoad,
  parentProject,
  projectCompsSet,
  showNotes,
  thumbnailSize,
}) => {
  const summaryCellTitle = (value: string, isUnitHeader?: boolean) => (
    <div className={`${classes.cell} ${classes.cellTotal}`}>
      <div
        className={`font-bold ${COLUMN_FONT_CLASSES} ${isUnitHeader && classes.tableHeaderElipsis}`}
      >
        {value}
      </div>
    </div>
  );
  const costCellHeaders = (
    <div className={`${classes.costRow} ${classes.cellTotal}`}>
      <div
        className="grid flex-grow self-center @container"
        style={{ gridTemplateColumns: formatGridTemplateColumns(costTableColumnInputs, true) }}
      >
        {costTableColumnInputs?.map((input) => {
          const unit = projectCompsSet.units?.find((u) => u.id === input.unitID);
          return summaryCellTitle(
            getColumnInputDisplayString(input.key, unit?.abbreviationSingular)
          );
        })}
      </div>
    </div>
  );

  const labelColumnPlaceholder = (
    <div className={`${classes.columnContent} ${classes.labelColumnContent}`} />
  );

  const hasAverageComp = Boolean(
    projectCompsSet.averageComp && !projectCompsSet.averageComp.isHidden
  );
  const numProjectComps = projectCompsSet.projectComps.filter((pc) => !pc.input.isExcluded).length;
  const columns = numProjectComps + (hasAverageComp ? 1 : 0);
  const groupSize = getGroupSize(columns);

  const columnHeaders = columnGroup.map((compData, index) => {
    const isAverageComp = isAverageCompType(compData);
    const headerKey = isAverageComp ? 'Average' : compData.project.id;
    const columnIndex = getColumnIndex(groupIndex, groupSize, index);
    const onLoad = () => onThumbnailLoad(columnIndex);
    return (
      <div
        key={headerKey}
        className={`${classes.columnContent} ${isAverageComp ? classes.average : ''}`}
      >
        <PrintColumnHeaderTitle
          compData={compData}
          onThumbnailLoad={onLoad}
          thumbnailSize={thumbnailSize}
        />
        {displayHeaderData && (
          <PrintColumnHeaderData
            compData={compData}
            parentProject={parentProject}
            projectCompsSet={projectCompsSet}
            showNotes={showNotes}
          />
        )}
      </div>
    );
  });

  const columnCostHeaders = columnGroup.map((compData) => {
    const isAverageComp = isAverageCompType(compData);
    const headerKey = isAverageComp ? 'Average' : compData.project.id;
    return (
      <div
        key={headerKey}
        className={`${classes.columnContent} ${isAverageComp ? classes.average : ''}`}
      >
        {costCellHeaders}
      </div>
    );
  });

  return (
    <>
      <div className={classes.content}>
        {labelColumnPlaceholder}
        {columnHeaders}
      </div>
      <div className={classes.content}>
        {labelColumnPlaceholder}
        {columnCostHeaders}
      </div>
    </>
  );
};

export default withStyles(styles)(PrintProjectCompsColumnGroupHeader);
