import { ServerError, ServerParseError } from 'apollo-link-http-common';
import { GraphQLError } from 'graphql';

import { Auth0ContextInterface } from '@auth0/auth0-react';

import { logErrorToSentry } from '../../utilities/sentry';
import { toastPermissions, toastServerError } from '../../utilities/toast';

export const handleErrors = (
  graphQlErrors: GraphQLError[] | null,
  networkError: Error | ServerError | ServerParseError | null,
  auth: Auth0ContextInterface
) => {
  // Note: The order of these if statements is important!!!!!
  // we need to check graphQL errors first so we know if a user
  // has permission to access a project
  if (graphQlErrors) {
    handleGraphQLError(graphQlErrors);
  }
  if (networkError) {
    handleNetworkError(networkError, auth);
  }
};

const handleGraphQLError = (errors: GraphQLError[]) => {
  // TODO: call analytics here.
  errors.forEach((error) => {
    logErrorToSentry(error);
  });
};

const handleNetworkError = (
  networkError: Error | ServerError | ServerParseError,
  auth: Auth0ContextInterface
) => {
  if (networkError) {
    const { statusCode } = networkError as ServerError;
    // The user is not authenticated...
    try {
      const { isAuthenticated } = auth;
      if (!isAuthenticated) {
        auth.logout({ returnTo: window.location.origin });
      } else if (statusCode === 401 || statusCode === 403) {
        // if the action is forbidden, send a message
        toastPermissions();
      } else if (statusCode === 500) {
        // if there is a network error then display a toast to warn the user
        toastServerError();
        logErrorToSentry(networkError);
      }
    } catch (e) {
      logErrorToSentry(e);
    }
  }
};
