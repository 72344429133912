import { FIELD_ERROR_DRAFT_CATEGORIZATION } from '../../../constants';
import { SortDirection } from '../../../generated/graphql';
import { Column, GridSortData, HeaderError } from '../types';

// Calculate error indices for column
export const calcErrorsIndices = (col: Column, lineCount: number) => {
  // generate a map of category errors for any cells
  // in this column from the category value
  // to the index / indicies of the cell in which they occur
  // ie "A" -> [1, 10]
  const errorsMap = new Map<string, number[]>();
  if (col.errors && errorsMap) {
    col.errors.forEach((e) => {
      if (e.categoryErrorIndicies) {
        // the category number and index should always be defined
        e.categoryErrorIndicies.map((c) => errorsMap.set(c.categoryNumber || '', c.indicies || []));
      }
    });
  }
  // create a flat map of all cells in this column with errors
  const errorsIndices: number[] = (errorsMap || {}).size
    ? Array.from(errorsMap.values()).flat()
    : [];
  const errorsPresence: boolean[] = new Array(lineCount).fill(false);
  errorsIndices.forEach((i: number) => {
    // for any cells in this column with errors set the corresponding
    // array index to true
    if (errorsPresence.length >= i) errorsPresence[i] = true;
  });

  return { errorsIndices, errorsMap, errorsPresence };
};

// Add error indices for column
export const addColumnErrors = (col: Column, lineCount: number, force?: boolean) => {
  const { errorsIndices, errorsMap, errorsPresence } = calcErrorsIndices(col, lineCount);
  // eslint-disable-next-line no-param-reassign
  if (!col.errors) col.errors = [];
  const { errors } = col;
  if (!errors[0]) errors[0] = { count: 0 };
  errors[0].count = errorsIndices.length;

  // As for now we have Not Found error only
  const errorNotFound = errors[0];
  errorNotFound.errorsIndices = errorsIndices;
  errorNotFound.errorsMap = errorsMap;
  errorNotFound.errorsPresence = errorsPresence;

  // update ui for header cell
  if (force && col.update) col.update();
};

// Add error indices for columns
export const addColumnsErrors = (cols: Column[], lineCount: number, force?: boolean) => {
  cols.forEach((col: Column) => {
    addColumnErrors(col, lineCount, force);
  });
};

// Add error resolution ids
// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
export const addColumnsResolutionErrors = (cols: Column[], resolutionErrors: any[]) => {
  if (!resolutionErrors) return;

  cols.forEach((col: Column) => {
    const categorizationId = ((col || {}).categorization || {}).id;
    if (!categorizationId) return;

    const isNewCategorization =
      col.errors && !!col.errors.find((e) => e.type === FIELD_ERROR_DRAFT_CATEGORIZATION);
    if (isNewCategorization) {
      // eslint-disable-next-line no-param-reassign
      if (!col.errors) col.errors = [];
      const { errors } = col;
      if (!errors[0]) errors[0] = { count: 0 };
      errors[0].isNewCategorization = isNewCategorization;
    }

    const errorsResolution = resolutionErrors.find((e) => {
      const eCategorizationId = (((e || {}).content || {}).categorization || {}).id;
      return eCategorizationId === categorizationId;
    });
    const errorsResolutionId = (errorsResolution || {}).id;
    if (errorsResolutionId) {
      // eslint-disable-next-line no-param-reassign
      if (!col.errors) col.errors = [];
      const { errors } = col;
      if (!errors[0]) errors[0] = { count: 0 };
      errors[0].errorsResolutionId = errorsResolutionId;
    }
  });
};

export const getSortBy = (sortData: GridSortData): EstimateSortBy | undefined =>
  sortData.sortDirection !== SortDirection.SORT_NONE
    ? { fieldID: sortData.sortKey, sortDirection: sortData.sortDirection }
    : undefined;

export const areEqualErrors = (
  es1: HeaderError[] | undefined,
  es2: HeaderError[] | undefined
): boolean => {
  if (!es1 && !es2) {
    return true;
  }
  if (es1 && es2) {
    if (es1.length !== es2.length) return false;
    let areEqual = true;
    for (let i = 0; i < es1.length; i += 1) {
      const e1 = es1[i];
      const e2 = es2[i];
      if ((e1 || {}).count !== (e2 || {}).count) {
        areEqual = false;
        break;
      }
    }
    return areEqual;
  }
  return false;
};
