import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';

export default (theme: KomodoTheme) =>
  createStyles({
    container: {
      ...theme.typography.webParagraph,
      display: 'flex',
      flexFlow: 'column',
      gap: '16px',
      '& strong': {
        fontWeight: 500,
      },
    },
    error: {
      color: theme.palette.error.main,
    },
    indexInfo: {
      padding: '8px 0',
    },
    locationDetails: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    locationDetails_factors: {
      display: 'flex',
      justifyContent: 'space-evenly',
      flexBasis: '50%',
    },
    locationDetails_sourceLocation: {
      flexBasis: '50%',
    },
    locationDetails_targetLocation: {
      flexBasis: '100%',
    },
    locationSelect_paper: {
      // There's no real way of extending MUI's default props for the Paper component
      // that drives the sizing of the dropdown. As a result, we need to redefine the
      // obvious stuff (position, overflow) ourselves before getting the stuff we really
      // want to overwrite (the max-height).
      outline: 'none',
      position: 'absolute',
      overflowY: 'auto',
      overflowX: 'hidden',
      minWidth: '16px',
      maxWidth: '30vw',
      minHeight: '16px',
      maxHeight: '30vh',
    },
    locationSelect_selectedValue: {
      ...theme.typography.webParagraph,
      padding: '8px 6px',
    },
    locationFactor: { display: 'flex', gap: '3px' },
    locationTo: {
      background: theme.palette.backgroundGrey,
      padding: 8,
    },
    projectInfo: {
      display: 'flex',
      '& > :first-child': {
        flexGrow: 1,
      },
    },
    projectInfo_pickerField: {
      display: 'flex',
      alignItems: 'center',
      gap: '4px',
    },
    selectLabelPlaceHolder: { color: theme.palette.disabledGrey },
  });
