import { FC } from 'react';

import { withStyles } from '@material-ui/core';
import { GetApp, Print } from '@material-ui/icons';

import { isDownloadingCostReportToExcelVar } from '../../../api/apollo/reactiveVars';
import { Export } from '../../Icons/Export';
import IconMenu from '../../Select/SelectMenu/IconMenu';

import styles from './VarianceReportExportPanelStyles';

type VarianceReportExportPanelProps = {
  classes: Classes<typeof styles>;
  exportCostReport: () => void;
  printOnClick: () => void;
};

const VarianceReportExportPanel: FC<VarianceReportExportPanelProps> = ({
  classes,
  exportCostReport,
  printOnClick,
}) => {
  const options = [
    {
      callback: () => printOnClick(),
      icon: <Print />,
      name: 'Print',
    },
    {
      callback: () => exportCostReport(),
      icon: <GetApp />,
      name: 'Download .xlsx',
      loadingVar: isDownloadingCostReportToExcelVar,
    },
  ];

  return (
    <div className={classes.padding}>
      <IconMenu icon={<Export />} isBottomOriented options={options} />
    </div>
  );
};

export default withStyles(styles)(VarianceReportExportPanel);
