import { FC } from 'react';

import { formatSettingsForExport } from '../../ItemsList/ItemsListUtils';
import { getDateLine } from '../PrintCostReport/PrintSubheader';
import { getFilterLabels, getGroupByLabels } from '../PrintUtils';

type PrintCostReportSubheaderProps = {
  categorizations: Categorization[];
  settings: VarianceSettings;
};

const PrintCostReportSubheader: FC<PrintCostReportSubheaderProps> = ({
  categorizations,
  settings,
}) => {
  const filters = formatSettingsForExport(settings, [], categorizations);
  const { groupBy } = settings;

  return (
    <>
      <div className="print-subheader print-subheader-columns">
        {getDateLine()}
        <div className="print-subheader-line">
          {getGroupByLabels(groupBy)}
          {getFilterLabels(filters)}
        </div>
      </div>
      <hr className="print-main-divider" />
    </>
  );
};

export default PrintCostReportSubheader;
