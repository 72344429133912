import {
  GetProjectStatusesQuery,
  GetProjectStatusesQueryVariables,
} from '../../../generated/graphql';
import { useQuery } from '../../../hooks/useMountAwareQuery';

import { getProjectStatusesQuery } from './queries';

function useProjectStatusesQuery() {
  return useQuery<GetProjectStatusesQuery, GetProjectStatusesQueryVariables>(
    getProjectStatusesQuery,
    {}
  );
}

export default useProjectStatusesQuery;
