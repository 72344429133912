import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';

export default (theme: KomodoTheme) =>
  createStyles({
    card: {
      backgroundColor: 'white',
      border: theme.border.line,
      marginBottom: 24,
      marginTop: 8,
      overflow: 'visible',
    },
    action: {
      marginTop: 0,
      marginRight: -16,
    },
    inputs: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: '8px',
    },
    enabled: {
      color: theme.palette.accepted,
    },
    disabled: {
      color: theme.palette.disabledGrey,
    },
  });
