import { CSSProperties, FC, createRef, useLayoutEffect, useState } from 'react';
import { useScrollbarWidth } from 'react-use';
import { FixedSizeList as List, ListChildComponentProps, ListOnScrollProps } from 'react-window';

// @ts-expect-error because library is not typed
import { Document } from 'react-pdf/dist/esm/entry.webpack5';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

import { ASSETS_PDF_FIT_HEIGHT, ASSETS_PDF_FIT_WIDTH } from '../../../../actions/actionTypes';
import { analyticsEvent } from '../../../../analytics/analyticsEventProperties';
import useSendAnalytics from '../../../../hooks/useSendAnalytics';
import theme, { withStyles } from '../../../../theme/komodo-mui-theme';
import useMemoWrapper from '../../../useMemoWrapper';
import ZeroState from '../../../ZeroState/ZeroState';
import { calcMaxViewerHeight } from '../../utils';

import AssetsPDFNavigationData, {
  HTMLDivElementScroll,
} from './AssetsPDFNavigation/AssetsPDFNavigationData';
import AssetsPDFPage from './AssetsPDFPage/AssetsPDFPage';
import AssetsPDFViewerStyles from './AssetsPDFViewerStyles';

type AssetsPDFViewerProps = {
  displayURL?: string;
  classes: Classes<typeof AssetsPDFViewerStyles>;
};

const AssetsPDFViewer: FC<AssetsPDFViewerProps> = ({ displayURL, classes }) => {
  const sendAnalytics = useSendAnalytics();
  const canvasRef = createRef<HTMLDivElement>();
  const listRef = createRef<List>();
  const navigationRef = createRef<HTMLDivElementScroll>();
  const [isFullWidth, setIsFullWidth] = useState<boolean>(false);
  const [width, setWidth] = useState<number>();
  const scrollBarWidth = useScrollbarWidth();
  // PDFs
  const [numPages, setNumPages] = useState<number>(0);
  const isLoading = !numPages;
  const [pageRatio, setPageRatio] = useState<number>(0);

  useLayoutEffect(() => {
    const canvasWidth = canvasRef.current?.offsetWidth;
    if (!canvasWidth) return;
    const newWidth = canvasWidth - (isFullWidth && scrollBarWidth ? scrollBarWidth : 0);
    if (width === newWidth) return;
    setWidth(newWidth);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO CT-566: Fix this pls :)
  }, [canvasRef, isFullWidth, scrollBarWidth]);

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  };

  const maxHeight = useMemoWrapper(calcMaxViewerHeight, window.innerHeight);

  const zeroState = (
    <ZeroState backgroundColor={theme.palette.shadedGrey} height={maxHeight} loading />
  );

  const onFullWidth = () => {
    setIsFullWidth(!isFullWidth);
    sendAnalytics(analyticsEvent(isFullWidth ? ASSETS_PDF_FIT_HEIGHT : ASSETS_PDF_FIT_WIDTH));
  };

  const pdfCanvasStyle = `${classes.pdfCanvas} ${isFullWidth ? classes.pdfFullWidth : ''} ${
    isLoading ? classes.pdfCanvasLoading : ''
  }`;

  const onScroll = ({ scrollOffset }: ListOnScrollProps) => {
    navigationRef?.current?.onScroll?.(scrollOffset);
  };

  const setPageRatioFn = (ratio: number) => {
    if (pageRatio !== 0) return;
    setPageRatio(ratio);
  };

  const Row = ({ index, style }: ListChildComponentProps) => (
    <AssetsPDFPage
      key={`page-key-${JSON.stringify(index)}`}
      isFullWidth={isFullWidth}
      maxHeight={maxHeight}
      pageIndex={index}
      setPageRatio={setPageRatioFn}
      style={style as CSSProperties}
      width={width}
      zeroState={zeroState}
    />
  );

  const itemHeight = isFullWidth && width ? width / pageRatio : maxHeight;
  const itemWidth = isFullWidth && width ? width : 'auto';

  return (
    <div className={classes.pdfContainer} style={{ maxHeight }}>
      <div ref={canvasRef} className={pdfCanvasStyle}>
        <Document
          file={displayURL}
          loading={zeroState}
          onLoadSuccess={onDocumentLoadSuccess}
          renderAnnotationLayer={false}
          renderTextLayer={false}
        >
          <List
            ref={listRef}
            className="pdf-list"
            height={itemHeight}
            itemCount={numPages}
            itemSize={itemHeight}
            onScroll={onScroll}
            width={itemWidth}
          >
            {Row}
          </List>
        </Document>
        {!isLoading && (
          <AssetsPDFNavigationData
            ref={navigationRef}
            canvasRef={canvasRef}
            isFullWidth={isFullWidth}
            listRef={listRef}
            maxHeight={itemHeight}
            onFullWidth={onFullWidth}
            pagesTotal={numPages}
          />
        )}
      </div>
    </div>
  );
};

export default withStyles(AssetsPDFViewerStyles)(AssetsPDFViewer);
