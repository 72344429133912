import { useMutation } from '@apollo/client';

import { USER_SOURCES } from '../../../api/refetchSets';
import {
  ConnectUserSourceMutation,
  ConnectUserSourceMutationVariables,
  RemoveUserSourceMutation,
  RemoveUserSourceMutationVariables,
  UserSourcesQuery,
  UserSourcesQueryVariables,
} from '../../../generated/graphql';
import { useQuery } from '../../../hooks/useMountAwareQuery';

import { connectUserSourceMutation, removeUserSourceMutation, userSourcesQuery } from './queries';

export const useUserSourcesQuery = () =>
  useQuery<UserSourcesQuery, UserSourcesQueryVariables>(userSourcesQuery);

export const useConnectUserSource = () =>
  useMutation<ConnectUserSourceMutation, ConnectUserSourceMutationVariables>(
    connectUserSourceMutation
  );

export const useRemoveUserSource = () => {
  const [removeUserSourceFunc] = useMutation<
    RemoveUserSourceMutation,
    RemoveUserSourceMutationVariables
  >(removeUserSourceMutation);
  const submitFunc = (sourceID: UUID, onSuccess?: () => void) =>
    removeUserSourceFunc({
      variables: {
        sourceID,
      },
      refetchQueries: USER_SOURCES,
    }).then(() => {
      if (onSuccess) onSuccess();
    });
  return [submitFunc];
};
