import { gql } from '../../../../../api/graphqlFragments';

export const forecastingProjects = gql`
  query forecastingProjects(
    $filters: ForecastingProjectsFilters!
    $search: String!
    $sortBy: ForecastingProjectsSortBy!
    $pagination: Pagination!
  ) {
    forecastingProjects(
      filters: $filters
      search: $search
      sortBy: $sortBy
      pagination: $pagination
    ) {
      budget
      estimate
      hasAccess
      id
      location
      milestoneDate
      milestoneID
      milestoneGSF
      milestoneName
      name
      orgNodes {
        id
        name
      }
      projectLeadName
      runningTotal
      status
      thumbnail
      type
      updatedAt
      updatedBy
    }
  }
`;
