import { get } from 'lodash';
import { useMemo, useState } from 'react';

import { SortManager } from '../components/scales';
import useMemoWrapper from '../components/useMemoWrapper';
import {
  TABLE_HEADER_EMAIL,
  TABLE_HEADER_NAME,
  TABLE_HEADER_RESPONSIBILITIES,
  TABLE_HEADER_ROLE,
} from '../constants';
import { SortDirection } from '../generated/graphql';

export type SortDataType = {
  sortKey: string;
  sortDirection: SortDirection;
};

// sorts a list of collaborators and handles the logic by which they are sorted
export default function useSortCollaborators(collaborators: Collaborator[]) {
  const [sortData, setSortData] = useState<SortDataType>({
    sortKey: '',
    sortDirection: SortDirection.SORT_ASCENDING,
  });

  const sortManager: SortManager = useMemo(
    () => ({
      setSort: setSortData,
      sortState: sortData,
    }),
    [sortData]
  );

  const sortedCollaborators = useMemoWrapper(sortCollaborators, sortData, collaborators);

  return { sortData, setSortData, sortManager, sortedCollaborators };
}

export function sortCollaborators<T extends Collaborator>(
  sortData: SortDataType,
  collaborators: T[]
): T[] {
  const { sortDirection, sortKey } = sortData;
  const newCollaboratorsList: T[] =
    sortKey !== ''
      ? collaborators
          .slice()
          .sort((a: T, b: T) => generateSortAlgorithm(a, b, sortDirection, getSortValue(sortKey)))
      : collaborators;
  return newCollaboratorsList;
}

// generates an algorithm by piecing all sort logic together
export const generateSortAlgorithm = (
  a: Collaborator,
  b: Collaborator,
  sortDirection: SortDirection,
  getSortKeyValue: (c: Collaborator) => string | number
) => {
  const aValue = getSortKeyValue(a);
  const bValue = getSortKeyValue(b);
  const ascendingAlgorithm = aValue > bValue ? 1 : -1;
  const descendingAlgorithm = aValue < bValue ? 1 : -1;
  return sortDirection === SortDirection.SORT_ASCENDING ? ascendingAlgorithm : descendingAlgorithm;
};

// gets the value that we want to sort by when given a key
export const getSortValue =
  (sortKey: string) =>
  (collaborator: Collaborator): string => {
    let returnValue;
    switch (sortKey) {
      case TABLE_HEADER_NAME:
        returnValue = collaborator.user.name.toLocaleLowerCase();
        break;
      case TABLE_HEADER_EMAIL:
        returnValue = collaborator.user.email.toLocaleLowerCase();
        break;
      case TABLE_HEADER_RESPONSIBILITIES:
        returnValue = (collaborator.responsibility ?? '').toLocaleLowerCase();
        break;
      case TABLE_HEADER_ROLE:
        returnValue = collaborator.role.name;
        break;
      default:
        returnValue = get(collaborator, sortKey).toLocaleLowerCase();
        break;
    }
    return returnValue || '';
  };
