import { useMutation } from '@apollo/client';

import { UPDATE_MILESTONE_NAME } from '../../../actions/actionTypes';
import { analyticsEvent } from '../../../analytics/analyticsEventProperties';
import {
  SetMilestoneNameMutation,
  SetMilestoneNameMutationVariables,
} from '../../../generated/graphql';
import useSendAnalytics from '../../../hooks/useSendAnalytics';

import { setMilestoneNameMutation } from './queries';

function useUpdateMilestoneName() {
  const [updateMilestoneNameFunc, mutationResult] = useMutation<
    SetMilestoneNameMutation,
    SetMilestoneNameMutationVariables
  >(setMilestoneNameMutation);
  const sendAnalytics = useSendAnalytics();

  const submitFunc = (projectID: UUID, milestoneID: UUID, value: string) =>
    updateMilestoneNameFunc({
      variables: { projectID, input: { milestoneID, name: value } },
    }).then(() => {
      sendAnalytics(analyticsEvent(UPDATE_MILESTONE_NAME));
    });

  return [submitFunc, mutationResult];
}

export default useUpdateMilestoneName;
