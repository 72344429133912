export type UserWithPicture = Pick<User, 'pictureAsset'> | Pick<User, 'picture'>;

export const getUserPicture = (user: UserWithPicture | null | undefined) => {
  if (user) {
    if ('pictureAsset' in user && user.pictureAsset) {
      return user.pictureAsset.thumbnailURL ?? undefined;
    }
    if ('picture' in user && user.picture) {
      return user.picture;
    }
  }
  return undefined;
};
