import { isImage, isModel, isPdf } from '../components/assets/utils';

import { assetCacheVar } from './apollo/reactiveVars';
import joinAPI from './joinAPI';

export const blobUrlCacheKey = (asset: Pick<Asset, 'id'>) => `Asset:${asset.id}`;

export const fetchBlobUrl = async (
  asset: Pick<Asset, 'id' | 'location' | 'name'> | undefined | null
) => {
  if (asset) {
    const blobUrlCached = assetCacheVar().get(blobUrlCacheKey(asset));
    if (blobUrlCached) return blobUrlCached;
    if (isImage(asset.name)) {
      // default images are already a url so we can return that
      if (asset.location.startsWith('https://static-assets.join.build/')) {
        return asset.location;
      }
      const url = await joinAPI.requestAssetBlobURL(asset.location, asset.name);
      assetCacheVar().set(blobUrlCacheKey(asset), url);
      return url;
    }
  }
  return null;
};

export const fetchDisplayUrl = async (
  asset: Pick<Asset, 'id' | 'derivations' | 'location' | 'name'> | undefined | null
) => {
  if (asset) {
    const displayURLCached = assetCacheVar().get(blobUrlCacheKey(asset));
    if (displayURLCached) return displayURLCached;
    const { name } = asset;
    if (isImage(name) || isPdf(name)) {
      const url = await joinAPI.makeDownloadURL(asset);
      assetCacheVar().set(blobUrlCacheKey(asset), url);
      return url;
    }
    if (isModel(name)) {
      let derivationID;
      let location;
      if (asset.derivations) {
        const derivation = asset.derivations.find((deriv) => deriv.kind === 'ADSKViewer');
        derivationID = derivation?.id;
        if (derivation && derivation.contents) {
          location = derivation.contents.find((content) => content.includes('.svf'));
        }
      }
      if (!derivationID || !location) return null;
      const url = encodeURI(joinAPI.makeDerivativeDownloadURL(derivationID, location));
      assetCacheVar().set(blobUrlCacheKey(asset), url);
      return url;
    }
  }
  return null;
};
