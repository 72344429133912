import { CopyItemDataQuery, CopyItemDataQueryVariables } from '../../../generated/graphql';
import { useQuery } from '../../../hooks/useMountAwareQuery';

import { copyItemDataQuery } from './queries';

export const useCopyItemDataQuery = (itemID: UUID, projectID?: UUID) => {
  return useQuery<CopyItemDataQuery, CopyItemDataQueryVariables>(copyItemDataQuery, {
    variables: {
      itemID,
      projectID,
    },
    skip: !itemID || !projectID,
  });
};
