import { ScaleLinear, ScalePoint, ScaleTime } from 'd3-scale';
import { ReactNode, useState } from 'react';
import { Placement } from 'react-aria';

import { Tooltip } from '../../scales';

import TimelineCircle from './TimelineCircle';

type Data = { date: Date; value: number };

const DEFAULT_HOVER_SIZE = 10;

export default function TimelinePointTooltip(props: {
  data: Data;
  fill: string;
  hoverSize?: number;
  isOpen?: boolean;
  offset?: number;
  onClick?: () => void;
  onHover?: () => void;
  onLeave?: () => void;
  placement?: Placement;
  pointSize?: number;
  content: ReactNode;
  x: ScaleTime<number, number> | ScalePoint<Date>;
  y: ScaleLinear<number, number>;
}) {
  const [isOpen, setIsOpen] = useState(props.isOpen);
  if (!props.data) return null;

  const onOpen = () => {
    setIsOpen(true);
    props.onHover?.();
  };

  const onClose = () => {
    setIsOpen(false);
    props.onLeave?.();
  };

  const onClick = () => {
    props.onClick?.();
  };

  const hoverAttrs = {
    cx: props.x(props.data.date),
    cy: props.y(props.data.value),
    opacity: 0,
    r: props.hoverSize ?? DEFAULT_HOVER_SIZE,
  };

  return (
    <>
      {(isOpen || props.isOpen) && (
        <TimelineCircle<{ value: number }> {...props} size={props.pointSize} />
      )}
      <Tooltip
        content={props.content}
        isOpen={props.isOpen}
        offset={props.offset}
        onClose={onClose}
        onOpen={onOpen}
        placement={props.placement}
      >
        <circle {...hoverAttrs} onClick={onClick} />
      </Tooltip>
    </>
  );
}
