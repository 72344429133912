import { FC, useState } from 'react';
import { useEffectOnce } from 'react-use';

import { useReactiveVar } from '@apollo/client';
import { Collapse, Typography } from '@material-ui/core';

import { itemSidebarOpenVar } from '../../../../api/apollo/reactiveVars';
import { ITEM_NUMBER_CHARLIMIT, SORT_ITEM_NUMBERS } from '../../../../constants';
import {
  CostReportColumnKey,
  MilestoneEstimateInfo,
  UserReportCommentInput,
} from '../../../../generated/graphql';
import { MILESTONE_REPORT_CELL, MILESTONE_REPORT_ROW } from '../../../../tagConstants';
import { withStyles } from '../../../../theme/komodo-mui-theme';
import { sortItemsBy } from '../../../../utilities/sorting';
import { getProjectIdFromUrl } from '../../../../utilities/url';
import { CollapseSettings } from '../../../../utilities/urlState';
import ItemSidebarIcon from '../../../Icons/ItemSidebarIcon/ItemSidebarIcon';
import ItemsQuickLink, { COST_REPORT } from '../../../Items/ItemsQuickLink/ItemsQuickLink';
import { getStatusIsAcceptedIncorporated } from '../../../Items/ItemsUtils';
import { SMALL } from '../../../ItemsList/ItemsIcons/ItemsIconsMap';
import { getCollapse } from '../../../ItemsList/ItemsListUtils';
import { getHasBreakpoint } from '../../../Print/PrintUtils';
import CollapseIcon from '../../../shared-widgets/CollapseIcon';
import {
  Cell,
  ColumnDescription,
  getCellsT,
  getColumnSubtotal,
  getShadedColumns,
} from '../../CostReportColumns/CostReportColumns';
import { CostReportCategoriesTreeNode, getNextBranches } from '../CostReportCategoriesTree';
import { isMetricKey } from '../CostReportList/CostReportListUtils';

import CostReportListRowCell, { getListRowTransforms } from './CostReportListRowCell';
import { getCellCosts } from './CostReportListRowNoteUtils';
import {
  DESCRIPTION_WIDTH,
  NOTES_ROW_HEIGHT_EXPANDED,
  RANGE_COL_WIDTH,
  REPORT_COL_WIDTH,
  ROW_HEIGHT,
  styles,
} from './CostReportListRowStyles';
import {
  RowVariants,
  RowVariantsString,
  cellDescriptionText,
  getDescriptionWidth,
  getFontWeightForLevel,
  getOptionCost,
} from './CostReportListRowUtils';
import MSRItemRowDescriptionPrint from './MSRItemRowDescriptionPrint';
import NoteCell from './NoteCell/NoteCell';

type CostReportListRowProps = {
  breakpoints?: number[];
  classes: Classes<typeof styles>;
  columnKeyQuantityMap: Map<string, Numeric>;
  displayColumnDescriptions: ColumnDescription[];
  headerDescriptions: ColumnDescription[];
  displayNodes?: number[];
  endFlags?: boolean[];
  headers?: JSX.Element;
  incrementRows?: () => void;
  isPrint?: boolean;
  isVariance?: boolean;
  level?: number;
  reportAnalyticsExpandCollapse?: (collapse: boolean) => void;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  parentIsChosen?: boolean;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  scroll?: boolean;
  setCollapse?: (bool: boolean, nodes: string[], nodeId: string) => void;
  settings?: CostReportSettings | CollapseSettings;
  treeNode: CostReportCategoriesTreeNode;
  variant?: RowVariantsString;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  useDirectCost?: boolean;
  isNotesColumnExpanded?: boolean;
  setNotesColumnExpanded?: (isExpanded: boolean) => void;
  isAddingNoteInReport?: boolean;
  setAddingNoteInReport?: (isAddingNote: boolean) => void;
  currentReportID?: UUID;
  isCurrentReportShared?: boolean;
  groupByIDs?: UUID[];
  milestoneEstimates: MilestoneEstimateInfo[];
  hasComments: boolean;
  canDeleteUserReportComment: boolean;
  isTotalIncluded: boolean;
};

const CostReportListRow: FC<CostReportListRowProps> = ({
  breakpoints = [],
  classes,
  columnKeyQuantityMap,
  displayColumnDescriptions,
  headerDescriptions,
  displayNodes,
  endFlags = [],
  headers,
  incrementRows = () => {},
  isPrint = false,
  isVariance = false,
  level = 0,
  reportAnalyticsExpandCollapse = () => {},
  parentIsChosen = false,
  scroll,
  setCollapse = () => {},
  settings,
  treeNode,
  variant,
  useDirectCost = false,
  isNotesColumnExpanded = false,
  setNotesColumnExpanded = () => {},
  isAddingNoteInReport = false,
  setAddingNoteInReport = () => {},
  currentReportID,
  isCurrentReportShared = false,
  groupByIDs,
  milestoneEstimates,
  hasComments,
  canDeleteUserReportComment,
  isTotalIncluded,
}) => {
  const projectID = getProjectIdFromUrl();
  const itemIdReactiveVar = useReactiveVar(itemSidebarOpenVar);

  const [hover, setHover] = useState(false);
  const [isAddingNoteInRow, setIsAddingNoteInRow] = useState(false);

  const setGlobalIsAddingNote = (isAddingNote: boolean) => {
    setIsAddingNoteInRow(isAddingNote);
    setAddingNoteInReport(isAddingNote);
  };

  const hoverBgnStyle = classes.hover;
  const hoverClass = hover && !isPrint ? hoverBgnStyle : '';
  const scrollClass = scroll ? classes.scroll : '';

  useEffectOnce(() => {
    incrementRows();
  });

  const {
    data: {
      columns,
      nodeNumber,
      id: rowID,
      name,
      number,
      status,
      parentId,
      isItemEstimateLine = false,
      categoryIDs,
    },
  } = treeNode;
  const isItem = !!rowID && !!status;
  const isHighlightedItem = !hover && itemIdReactiveVar && itemIdReactiveVar === rowID && !isPrint;
  const optionContribution = getColumnSubtotal(columns, CostReportColumnKey.OPTIONS_KEY);
  const { collapse, expand } = settings as CollapseSettings;
  const { metrics } = settings as MilestoneCostReportSettings;

  const nodeId = `${level} ${nodeNumber}`; // The id is very simple now, for one tree
  const commentCount = treeNode?.getCommentCount?.();
  const shouldExpandForChildComments = isPrint && commentCount && commentCount > 0;
  const collapsed = getCollapse(nodeId, collapse, expand) && !shouldExpandForChildComments;

  const itemIsChosen = !!status && getStatusIsAcceptedIncorporated(status);
  const optionCost = parentId ? getOptionCost(optionContribution, useDirectCost) : undefined;
  const msrPrintDescriptionWidths = displayColumnDescriptions.length
    ? displayColumnDescriptions.map((d: ColumnDescription) =>
        d?.isWide ? RANGE_COL_WIDTH : REPORT_COL_WIDTH
      )
    : [REPORT_COL_WIDTH];

  // CALCULATE WHEN TO BREAK THE PAGE
  const [pageHeight, zoomLevel] = breakpoints;
  const height = (isPrint ? zoomLevel : 1) * ROW_HEIGHT; // row height for display
  const { transform } = getListRowTransforms(zoomLevel);
  const hasBreakpoint =
    nodeNumber && getHasBreakpoint(nodeNumber, height, pageHeight, displayNodes);

  // Local Styles for font based on the level
  const fontWeight = getFontWeightForLevel(level);

  const varianceRowDescriptions =
    isTotalIncluded || !isVariance
      ? displayColumnDescriptions
      : displayColumnDescriptions.filter((description) => description && description.unitID);

  const shadedColumns = getShadedColumns(headerDescriptions);
  // Recursive function to move forward until we find non-repetitive branches
  const nextBranches = getNextBranches(treeNode);

  const getCellContent = (cells: Cell[], isMetric?: boolean) =>
    cells.map((cell: Cell) => (
      <CostReportListRowCell
        key={`${JSON.stringify(cell)}`}
        cell={cell}
        fontWeight={fontWeight}
        height={height}
        isHovering={hover}
        isMetric={isMetric}
        isPrint={isPrint}
        isVariance={isVariance}
        optionCost={optionCost}
        parentIsChosen={parentIsChosen}
        treeNode={treeNode}
        variant={variant}
        zoomLevel={zoomLevel}
      />
    ));

  const cells = treeNode.data
    ? getCellsT(
        treeNode,
        varianceRowDescriptions,
        shadedColumns,
        variant,
        useDirectCost,
        isVariance,
        columnKeyQuantityMap
      )
    : [];

  const nonMetricCells = cells.filter(
    (cell) => !cell.columnDescription?.columnKey || !isMetricKey(cell.columnDescription.columnKey)
  );
  const cellsContent = getCellContent(isVariance ? cells : nonMetricCells);

  let metricCellsContent: JSX.Element[] = [];
  if (!isVariance) {
    const metricCells = cells.filter(
      (cell) => cell.columnDescription?.columnKey && isMetricKey(cell.columnDescription.columnKey)
    );
    metricCellsContent = getCellContent(metricCells, true);
  }

  if (isPrint) {
    cellsContent.unshift(...metricCellsContent);
  }
  // Do not go into calculations if variance
  const costIndex = !isVariance ? cells.findIndex((c: Cell) => c && !c.isEmpty) : 0;
  const msrPrintDescriptionWidth =
    costIndex !== -1 && costIndex === 0
      ? DESCRIPTION_WIDTH
      : DESCRIPTION_WIDTH +
        msrPrintDescriptionWidths.reduce(
          (a: number, b: number, i: number) => (i < costIndex ? a + b : a + 0),
          0 // we need an initial value if msrPrintDescriptionWidths is empty
        );
  const descriptionWidth =
    isVariance || !metrics ? DESCRIPTION_WIDTH : getDescriptionWidth(metrics);

  const canCollapse =
    !!(nextBranches && nextBranches.length) ||
    (isItem && !!treeNode.branches && !!treeNode.branches.length);

  const hasCommentOnRow = !!treeNode?.data.commentWithCosts;
  let userReportCommentInput: Omit<UserReportCommentInput, 'text'> | undefined;
  if (milestoneEstimates && groupByIDs) {
    let itemID;
    if (isItem) itemID = rowID;
    if (isItemEstimateLine) itemID = parentId;

    userReportCommentInput = {
      categoryIDs,
      costs: getCellCosts(cells),
      viewParameters: {
        groupBys: groupByIDs,
        milestoneEstimates,
      },
      // we need to pass rowID as the rowID, itemLineID, or markupID
      // depending on what kind of row this is
      // Note: for some sandbox items the first estimate line ID is always a specific value
      // so we need the itemID for estimate lines
      itemID,
      itemLineID: isItemEstimateLine ? rowID : undefined,
      markupID: variant === RowVariants.MARKUP ? rowID : undefined,
    };
  }

  const sortedBranches = (nextBranches && sortItemsBy[SORT_ITEM_NUMBERS](nextBranches)) || [];
  const nextBranchRows = sortedBranches.map((branch: CostReportCategoriesTreeNode, b: number) => (
    <CostReportListRow
      key={JSON.stringify(branch.data)}
      breakpoints={breakpoints}
      canDeleteUserReportComment={canDeleteUserReportComment}
      classes={classes}
      columnKeyQuantityMap={columnKeyQuantityMap}
      currentReportID={currentReportID}
      displayColumnDescriptions={displayColumnDescriptions}
      displayNodes={displayNodes}
      endFlags={[...endFlags, b + 1 === sortedBranches.length]}
      groupByIDs={groupByIDs}
      hasComments={hasComments}
      headerDescriptions={headerDescriptions}
      headers={headers}
      isAddingNoteInReport={isAddingNoteInReport}
      isCurrentReportShared={isCurrentReportShared}
      isNotesColumnExpanded={isNotesColumnExpanded}
      isPrint={isPrint}
      isTotalIncluded={isTotalIncluded}
      isVariance={isVariance}
      level={level + 1}
      milestoneEstimates={milestoneEstimates}
      parentIsChosen={itemIsChosen}
      reportAnalyticsExpandCollapse={reportAnalyticsExpandCollapse}
      scroll={scroll}
      setAddingNoteInReport={setAddingNoteInReport}
      setCollapse={setCollapse}
      setNotesColumnExpanded={setNotesColumnExpanded}
      settings={settings}
      treeNode={branch}
      useDirectCost={useDirectCost}
      variant={variant}
    />
  ));

  const breakpointHeader = hasBreakpoint && <>{headers}</>;
  const rowClasses = `${classes.row} ${hoverClass} ${isHighlightedItem && classes.itemSidebarItem}`;
  const minRowHeight = isPrint ? undefined : ROW_HEIGHT;

  // we can only add notes on category or item/item estimate lines, which will have categoryIDs,
  // or markup lines, which will have a markupID
  const canAddNote =
    !isPrint &&
    (!!userReportCommentInput?.categoryIDs || !!userReportCommentInput?.markupID) &&
    !isAddingNoteInReport;

  return (
    <>
      {breakpointHeader}
      <div
        className={classes.listContainer}
        data-cy={MILESTONE_REPORT_ROW}
        onClick={() => {
          if (!isPrint) {
            setCollapse(!collapsed, [nodeId], nodeId);
          }
        }}
        onKeyDown={(evt) => {
          if (!isPrint && evt.key === 'Enter') {
            setCollapse(!collapsed, [nodeId], nodeId);
          }
        }}
        role="button"
        tabIndex={isPrint ? -1 : 0}
      >
        <div
          className={rowClasses}
          onFocus={() => {}}
          onMouseLeave={() => {
            setHover(false);
          }}
          onMouseOver={() => {
            setHover(true);
          }}
          style={{
            height: isAddingNoteInRow || hasCommentOnRow ? '100%' : height,
            minHeight: isAddingNoteInRow ? NOTES_ROW_HEIGHT_EXPANDED : minRowHeight,
            transformOrigin: 'center left',
            transform,
          }}
        >
          <div
            className={`${classes.descriptionColumn} ${
              isPrint && classes.descriptionWidth
            } ${scrollClass} ${isHighlightedItem && classes.itemSidebarItem}`}
            style={{
              height,
              width: descriptionWidth,
            }}
          >
            <div className={`${classes.descriptionFront} ${hoverClass}`} style={{ height }}>
              {Array(level)
                .fill(0)
                .map((_, i) => {
                  const isBracket = i + 1 === level;
                  const isLastBracket = endFlags[i]; // if there's supposed to be a line...
                  const hasLine = isBracket || !isLastBracket; // either is not last line, or has bracket
                  const key = `${i} ${isLastBracket} ${isBracket}`;
                  return (
                    <div key={key} className={isPrint ? classes.printNesting : classes.nesting}>
                      {hasLine && (
                        <>
                          <div
                            className={
                              isLastBracket ? classes.lastLevelSpacer : classes.levelSpacer
                            }
                          />
                          {isBracket && (
                            <div
                              className={`${classes.ledger} ${isPrint ? classes.printLedger : ''}`}
                            />
                          )}
                        </>
                      )}
                    </div>
                  );
                })}
              {!isPrint && (
                <div className={classes.iconContainer}>
                  {!!canCollapse && (
                    <CollapseIcon className={classes.icon} isCollapsed={collapsed} />
                  )}
                </div>
              )}
              {!isVariance && isPrint ? (
                <MSRItemRowDescriptionPrint
                  fontWeight={fontWeight}
                  height={height}
                  parentIsChosen={parentIsChosen}
                  treeNode={treeNode}
                  width={msrPrintDescriptionWidth}
                />
              ) : (
                <div className={classes.description} data-cy={MILESTONE_REPORT_CELL}>
                  {isItem ? (
                    <div className={classes.itemQuickLink}>
                      <ItemsQuickLink
                        charNumberLimit={ITEM_NUMBER_CHARLIMIT}
                        cost={optionCost}
                        ellipses
                        hasDefaultWidth={false}
                        isPrint={isPrint}
                        isVariance={isVariance}
                        item={{
                          id: rowID,
                          name: name || '',
                          number: number || '',
                          status,
                        }}
                        parentIsChosen={parentIsChosen}
                        textVariant="body1"
                        variant={COST_REPORT}
                      />
                    </div>
                  ) : (
                    <Typography
                      className={
                        variant === RowVariants.SUBTOTAL
                          ? classes.subtotal
                          : classes.descriptionText
                      }
                    >
                      <span style={{ fontWeight }}>{cellDescriptionText(treeNode)}</span>
                    </Typography>
                  )}
                </div>
              )}
              {isItem && !isPrint && !isVariance && (
                <div className={classes.itemQuickLinkPadding}>
                  <ItemSidebarIcon isHovering={hover} itemID={rowID} variant={SMALL} />
                </div>
              )}
              <div className={classes.gap} />
              <div className={classes.spacer} />
              {!isVariance && !isPrint && (
                <>
                  {metricCellsContent}
                  <div className={classes.gap} />
                </>
              )}
            </div>
          </div>
          <div className={classes.scrollContainer}>
            {cellsContent}

            {(!isPrint || hasComments) && (
              <NoteCell
                canAddNote={canAddNote}
                canDeleteUserReportComment={canDeleteUserReportComment}
                collapsedChildCommentCount={
                  canCollapse && collapsed ? treeNode.getCommentCount() : undefined
                }
                currentReportID={currentReportID}
                isAddingNoteInRow={isAddingNoteInRow}
                isCurrentReportShared={isCurrentReportShared}
                isNotesColumnExpanded={isNotesColumnExpanded}
                isPrint={isPrint}
                projectID={projectID}
                setIsAddingNote={setGlobalIsAddingNote}
                setNotesColumnExpanded={setNotesColumnExpanded}
                userCommentWithCosts={treeNode.data.commentWithCosts}
                userReportCommentInput={userReportCommentInput}
              />
            )}
          </div>
        </div>
      </div>

      {isPrint ? (
        !collapsed && nextBranchRows
      ) : (
        <Collapse
          className={classes.collapse}
          in={!collapsed}
          mountOnEnter
          timeout={{
            appear: 0,
            enter: 0,
            exit: 0,
          }}
        >
          {nextBranchRows}
        </Collapse>
      )}
    </>
  );
};

export default withStyles(styles)(CostReportListRow);
