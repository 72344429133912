import { createContext, useContext } from 'react';

const CurrentUserContext = createContext<User | undefined>(undefined);
export const CurrentUserContextProvider = CurrentUserContext.Provider;

/**
 * @returns The currently logged-in user.
 * @throws if there is no user
 */
export function useCurrentUser() {
  const currentUser = useContext(CurrentUserContext);

  // We throw here if there's no user since it allows us to return a non-nullable value.
  // This is safe because we currently render the children of the provider only if there is a currentUser.
  if (!currentUser) throw new Error('no current user');

  return currentUser;
}
