import SvgIcon from '@material-ui/core/SvgIcon';

import { IconProps } from '.';

export const TrendlineUpperBoundUp = (props: IconProps) => (
  <SvgIcon {...props} viewBox="0 0 20 14">
    <path
      d="M14 0L16.29 2.29L11.41 7.16998L7.41 3.16999L0 10.59L1.41 12L7.41 5.99999L11.41 9.99998L17.71 3.70999L20 5.99999V0H14Z"
      fill="#F6B901"
    />
    <path d="M16.5 7V14H17V6.5L16.5 7Z" fill="#F6B901" />
    <path d="M8.5 14V9.49999L9 9.99998V14H8.5Z" fill="#F6B901" />
    <path d="M10.5 11V14H11V11.5L10.5 11Z" fill="#F6B901" />
    <path d="M12.5 11V12.5V14H13V10.59L12.5 11Z" fill="#F6B901" />
    <path d="M14.5 9.00002V14H15V8.58998L14.5 9.00002Z" fill="#F6B901" />
  </SvgIcon>
);

export const TrendlineUpperBoundDown = (props: IconProps) => (
  <SvgIcon {...props} viewBox="0 0 20 14">
    <path
      d="M14 12L16.29 9.71L11.41 4.83L7.41 8.83L0 1.41L1.41 0L7.41 6L11.41 2L17.71 8.29L20 6V12H14Z"
      fill="#F6B901"
    />
    <path d="M2.5 5.5V13H3V6L2.5 5.5Z" fill="#F6B901" />
    <path d="M4.5 7.5V13H5V8L4.5 7.5Z" fill="#F6B901" />
    <path d="M6.5 9.21V13H7V9.71L6.5 9.21Z" fill="#F6B901" />
    <path d="M8.5 9.5V13H9V9L8.5 9.5Z" fill="#F6B901" />
    <path d="M10.5 7.5V13H11V7L10.5 7.5Z" fill="#F6B901" />
  </SvgIcon>
);

export const TrendlineLowerBoundUp = (props: IconProps) => (
  <SvgIcon {...props} viewBox="0 0 20 14">
    <path
      d="M14 1L16.29 3.29L11.41 8.17L7.41 4.17L0 11.59L1.41 13L7.41 7L11.41 11L17.71 4.71L20 7V1H14Z"
      fill="#F6B901"
    />
    <path d="M2.5 7.5V0H3V7L2.5 7.5Z" fill="#F6B901" />
    <path d="M4.5 5.5V0H5V5L4.5 5.5Z" fill="#F6B901" />
    <path d="M6.5 3.79V0H7V3.29L6.5 3.79Z" fill="#F6B901" />
    <path d="M8.5 3.5V0H9V4L8.5 3.5Z" fill="#F6B901" />
    <path d="M10.5 5.5V0H11V6L10.5 5.5Z" fill="#F6B901" />
  </SvgIcon>
);

export const TrendlineLowerBoundDown = (props: IconProps) => (
  <SvgIcon {...props} viewBox="0 0 20 14">
    <path
      d="M14 14L16.29 11.71L11.41 6.83003L7.41 10.83L0 3.41003L1.41 2.00003L7.41 8.00003L11.41 4.00003L17.71 10.29L20 8.00003V14H14Z"
      fill="#F6B901"
    />
    <path d="M16.5 7.00002V0H17V7.50002L16.5 7.00002Z" fill="#F6B901" />
    <path d="M8.5 1.55643e-05V4.50002L9 4.00003V1.55643e-05H8.5Z" fill="#F6B901" />
    <path d="M10.5 3.00002V2.9631e-05H11V2.50002L10.5 3.00002Z" fill="#F6B901" />
    <path d="M12.5 2.99999V1.49999V0H13V3.41003L12.5 2.99999Z" fill="#F6B901" />
    <path d="M14.5 4.99999V0H15V5.41003L14.5 4.99999Z" fill="#F6B901" />
  </SvgIcon>
);
