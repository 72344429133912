import { createStyles } from '@material-ui/core';

export const FILE_EXPLORER_PADDING = '16px';

export default () =>
  createStyles({
    sourceContent: {
      flexDirection: 'column',
      display: 'flex',
      flex: 1,
    },
    sourceHeader: {
      padding: `14px ${FILE_EXPLORER_PADDING}`,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    headerText: {
      lineHeight: '1.5rem',
    },
  });
