import { SourceSystem, UserSourcesQuery } from '../../../../generated/graphql';
import { Dialog, DialogContent } from '../../../scales';

import IntegrationsLinkAccount from './IntegrationsLinkAccount';
import { SUPPORTED_SOURCE_SYSTEMS, UserSource } from './IntegrationsUtils';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onEditAccount: (userSource: UserSource) => void;
  onSignOut: (userSource: UserSource) => void;
  userSources: UserSourcesQuery['userSources'];
  setInitialEditAccountSourceSystem: (sourceSystem: SourceSystem) => void;
};

export default function IntegrationsLinkAccountsModal(props: Props) {
  return (
    <Dialog isOpen={props.isOpen} onClose={props.onClose} title="Manage Accounts">
      <DialogContent>
        <div className="flex flex-col gap-6">
          <div className="flex flex-col gap-1">
            <div className="type-heading3">Link cloud storage accounts</div>
            <div className="type-body1">
              Connect construction cloud platforms to integrate with Join.
            </div>
          </div>
          <div className="flex flex-col gap-4">
            {SUPPORTED_SOURCE_SYSTEMS.map((sourceSystem) => (
              <>
                <IntegrationsLinkAccount
                  onEditAccount={props.onEditAccount}
                  onSignOut={props.onSignOut}
                  setInitialEditAccountSourceSystem={props.setInitialEditAccountSourceSystem}
                  sourceSystem={sourceSystem}
                  userSources={props.userSources}
                />
                <div className="border last:hidden" />
              </>
            ))}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
