import { createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
export default (theme: any) =>
  createStyles({
    filterOption: {
      alignItems: 'center',
      background: theme.palette.backgroundWhite,
      cursor: 'pointer',
      display: 'flex',
      fontSize: 14,
      fontWeight: 300,
      justifyContent: 'space-between',
      textAlign: 'left',
      '& > span > svg': {
        width: 14,
      },
    },
    filterOptionTitle: {
      alignItems: 'center',
      display: 'flex',
      height: '100%',
      justifyContent: 'flex-start',
      lineHeight: '16px',
      padding: '12px',
      width: '100%',
    },
    filterHighlighted: {
      background: theme.palette.hoverBackground,
    },
    checkboxRoot: {
      height: 20,
      paddingRight: 12,
      width: 20,
    },
    containerInput: {
      alignItems: 'center',
      backgroundColor: theme.palette.backgroundWhite,
      display: 'flex',
      fontFamily: theme.typography.fontFamily,
      justifyContent: 'flex-start',
      '& > svg': {
        marginLeft: 8,
        width: 16,
      },
      minHeight: 40,
    },
    containerOuter: {
      backgroundColor: theme.palette.backgroundWhite,
      boxSizing: 'border-box',
      filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
      fontFamily: theme.typography.fontFamily,
      overflow: 'hidden',
      position: 'relative',
      width: '100%',
    },
    iconDropUpArrowButton: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
      padding: '6px',
    },
    iconSearch: {
      position: 'absolute',
    },
    input: {
      '&:focus': {
        border: theme.border.focus,
        outline: theme.border.focus,
      },
      border: 'none',
      borderRadius: 0,
      boxSizing: 'border-box',
      fontFamily: theme.typography.fontFamily,
      fontSize: '0.9rem',
      fontWeight: 300,
      padding: '8px 13px 6px 32px',
      width: '100%',
    },
    list: {
      borderTop: theme.border.line,
      marginBlockEnd: 0,
      marginBlockStart: 0,
      overflowX: 'hidden',
      overflowY: 'scroll',
      paddingInlineStart: 0,
    },
    listEmpty: {
      overflowY: 'hidden',
    },
    listNoScroll: {
      borderTop: theme.border.line,
      marginBlockEnd: 0,
      marginBlockStart: 0,
      paddingInlineStart: 0,
    },
  });
