export default ({ milestoneName, onClick }: { milestoneName: string; onClick?: () => void }) => (
  <div
    className="flex h-24 w-full items-center type-label"
    onClick={onClick}
    onKeyDown={(event) => {
      if (onClick && event.key === 'Enter') onClick();
    }}
  >
    {milestoneName}
  </div>
);
