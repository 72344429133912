import { kebabDataCy } from '../../../utilities/cypress';
import { CompanyLogoThumbnail } from '../../dragon-scales';
import { Link } from '../types';

import NavItem from './NavItem';
import ProjectsNavItem from './ProjectsNavItem';

type Props = {
  company?: {
    assetID?: UUID | null;
    name: string;
  };
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  expanded?: boolean;
  lastViewedProjectIDs: UUID[];
  links: Link[];
};

export default function CompanySection(props: Props) {
  if (!props.company)
    return (
      <ProjectsNavItem
        expanded={props.expanded}
        lastViewedProjectIDs={props.lastViewedProjectIDs}
      />
    );

  const dataCy = 'company';

  return (
    <div data-cy={dataCy}>
      <div
        className={`flex items-center gap-2 px-2 py-1 type-body2 ${
          props.expanded ? '' : 'justify-center'
        }`}
        data-cy={kebabDataCy(dataCy, 'title')}
      >
        <div className="shrink-0 overflow-hidden rounded">
          {props.company.assetID ? (
            <CompanyLogoThumbnail size={32} thumbnail={props.company.assetID} />
          ) : (
            <InitialMark name={props.company.name} />
          )}
        </div>
        {props.expanded && (
          <div className="line-clamp-3 cursor-default type-heading3">{props.company.name}</div>
        )}
      </div>
      <div className="flex flex-col items-stretch" data-cy={kebabDataCy(dataCy, 'links')}>
        <ProjectsNavItem
          expanded={props.expanded}
          lastViewedProjectIDs={props.lastViewedProjectIDs}
        />
        {props.links.map((link) => (
          <NavItem
            key={link.name}
            icon={link.icon}
            label={props.expanded ? link.name : undefined}
            path={link.path}
            tooltip={props.expanded ? undefined : link.name}
          />
        ))}
      </div>
    </div>
  );
}

function InitialMark(props: { name?: string }) {
  return (
    <div className="flex h-8 w-8 cursor-default items-center justify-center bg-background-2">
      {(props.name ?? '-')
        .split(' ')
        .slice(0, 2)
        .map((word) => word[0])
        .join('')}
    </div>
  );
}
