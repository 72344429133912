import { createStyles } from '@material-ui/core';

import { textSubTitle } from './ImportEstimateUpperSVGTestStyles';

export const upperSVGIconEmptyStateLarge = createStyles({
  upperSVGIconEmptyStateLarge: {
    background: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    display: 'flex',
    position: 'relative',
    marginTop: 25,
    marginBottom: 25,
    width: '100%',
    height: '66%',
  },
});

export const lowerEstimatePlatformIcon = createStyles({
  lowerEstimatePlatformIcon: {
    background: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: '300px 150px',
    width: 300,
    height: 100,
    display: 'flex',
    position: 'relative',
  },
});

export default () =>
  createStyles({
    upperSVGIconEmptyState: {
      background: 'no-repeat',
      backgroundSize: 'contain',
      backgroundPosition: 'center',
      width: 744,
      height: 257,
      display: 'flex',
      position: 'relative',
      marginTop: 25,
      marginBottom: 25,
    },
    ...upperSVGIconEmptyStateLarge,
    upperEstimatePlatformIcon: {
      background: 'no-repeat',
      backgroundSize: '410px 350px',
      backgroundPosition: 'center',
      width: 744,
      height: 257,
      display: 'flex',
      position: 'relative',
    },
    upperSageEstimatePlatformIcon: {
      background: 'no-repeat',
      backgroundSize: '700px 350px',
      backgroundPosition: 'center',
      width: 744,
      height: 257,
      display: 'flex',
      position: 'relative',
    },
    upperExcelEstimatePlatformIcon: {
      background: 'no-repeat',
      backgroundSize: '410px 200px',
      backgroundPosition: 'center',
      width: 744,
      height: 257,
      display: 'flex',
      position: 'relative',
    },
    ...lowerEstimatePlatformIcon,
    image: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    textSubTitle,
  });
