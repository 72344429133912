import { Person } from '@material-ui/icons';
import { Chip, Tooltip } from '../../../scales';
import { Alert } from '../types';

export const ModifiedChip = (props: { alert: Alert }) => {
  const formatOptions: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  };
  let chipText = '';
  let tooltipText = '';
  if (props.alert.updatedBy && props.alert.updatedAt) {
    chipText = `Edited by ${props.alert.updatedBy.name}`;
    const date = new Date(props.alert.updatedAt);
    tooltipText = `Edited on ${date.toLocaleString(undefined, formatOptions)}`;
  } else if (props.alert.createdBy && props.alert.createdAt) {
    chipText = `Added by ${props.alert.createdBy.name}`;
    const date = new Date(props.alert.createdAt);
    tooltipText = `Added on ${date.toLocaleString(undefined, formatOptions)}`;
  } else {
    return null;
  }
  return (
    <Tooltip content={<div className="type-table-text">{tooltipText}</div>}>
      <Chip icon={<Person />} text={chipText} />
    </Tooltip>
  );
};
