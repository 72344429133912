import { createStyles } from '@material-ui/core';

const LOGO_SIZE = 64;
const PADDING = 24;
const MARGIN = 48;
const PADDING_TOP = 50;

export default () =>
  createStyles({
    contentRoot: {
      display: 'flex',
      flexDirection: 'row',
      flex: 1,
      paddingTop: PADDING_TOP,
    },
    column: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      alignItems: 'center',
    },
    logo: { width: LOGO_SIZE, height: LOGO_SIZE, marginBottom: PADDING },
    padded: { paddingBottom: PADDING },
    source: {
      display: 'flex',
      flexDirection: 'column',
      flex: '0 0 auto',
      height: 'fit-content',
      width: 240,
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    sources: {
      display: 'flex',
      maxWidth: 720,
      flexDirection: 'row',
      flex: 1,
      overflow: 'auto',
      justifyContent: 'space-between',
      marginTop: MARGIN,
    },
  });
