import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../../theme/komodo-mui-theme';
import { widthReportsSettings } from '../ReportsListEntrySettings/ReportsListEntrySettingsStyles';

export default (theme: KomodoTheme) =>
  createStyles({
    button: {
      minHeight: 28,
      minWidth: 120,
      padding: 0,
    },
    text: {
      ...theme.typography.body1,
      fontWeight: 400,
    },
    root: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
      flexGrow: 1,
      justifyContent: 'flex-end',
      pageBreakInside: 'avoid',
      position: 'relative',
      width: widthReportsSettings,
    },
  });
