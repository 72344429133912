import { useRef, useState } from 'react';

import { LocationOn } from '@material-ui/icons';

import { ToastType } from '../../../api/gqlEnums';
import { LocationInput, ProjectCompEscalationMetaInput } from '../../../generated/graphql';
import { setToast } from '../../../hooks/useToastParametersLocalQuery';
import Location from '../../Icons/Location';
import { EscalationTargetLocation } from '../constants/projectCompTypes';
import { EscalationTarget } from '../hooks/useAutoEscalateMultiple';

import EditLocationSourceDialog from './EditLocationSourceDialog';
import EditLocationTargetDialog from './EditLocationTargetDialog';
import EscalationInput from './EscalationInput';
import { useLocationEscalationLazyQuery } from './hooks/useLocationEscalationLazyQuery';
import StartAdornment from './StartAdornment';

enum SelectedEscalationOption {
  EscalateSingle,
  EscalateAll,
  None,
}

const LocationEscalationInput = (props: {
  isHighlighted?: boolean;
  locationFrom: LocationInput;
  locationTo: LocationInput;
  meta?: ProjectCompEscalationMetaInput;
  onAutoEscalateAllLocation: (target?: EscalationTarget) => void;
  onChange: (value: string, meta?: ProjectCompEscalationMetaInput) => void;
  onMouseOver?: (isOver: boolean) => void;
  onTargetLocationChange: (location: EscalationTargetLocation) => void;
  project: Pick<ProjectProps, 'location' | 'name' | 'thumbnail'>;
  value: string;
}) => {
  const isAutoEscalated = Boolean(props.meta?.isAutoEscalated);
  const isFuzzyMatch = Boolean(props.meta?.isFuzzyMatch);

  const [isNoTargetLocationDialogOpen, setIsNoTargetLocationDialogOpen] = useState(false);
  const [isEditLocationDialogOpen, setIsEditLocationDialogOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(SelectedEscalationOption.None);

  const [fetchLocationEscalation] = useLocationEscalationLazyQuery({
    onCompleted: (res) => {
      if (!res?.locationEscalation) {
        setToast({ message: 'Failed to calculate escalation' }, ToastType.SERVER_ERROR);
        props.onChange('');
        return;
      }
      const { fuzzyMatch, sourceIsFuzzyMatch, targetIsFuzzyMatch, percentage, from, to } =
        res.locationEscalation;
      props.onChange(percentage.toString(), {
        isAutoEscalated: true,
        isFuzzyMatch: fuzzyMatch,
        sourceLabel: from.name,
        sourceIsFuzzyMatch,
        targetLabel: to.name,
        targetIsFuzzyMatch,
      });
    },
  });

  // Note the wording on the dropdown menu item that this is triggered by. This function should autoescalate
  // from the /original/ location -- not a user-changed one -- so we reset the local location.
  const handleAutoEscalate = () => {
    if (!props.locationFrom.name) {
      setIsEditLocationDialogOpen(true);
      return;
    }

    if (!props.locationTo.name) {
      setSelectedOption(SelectedEscalationOption.EscalateSingle);
      setIsNoTargetLocationDialogOpen(true);
      return;
    }

    fetchLocationEscalation({
      variables: {
        from: props.locationFrom,
        to: props.locationTo,
      },
    });
  };

  const handleAutoEscalateAll = () => {
    if (!props.locationTo.name) {
      setSelectedOption(SelectedEscalationOption.EscalateAll);
      setIsNoTargetLocationDialogOpen(true);
      return;
    }

    props.onAutoEscalateAllLocation();
  };

  const handleManualEscalate = () => {
    handleRemoveEscalation();

    const focusInput = () => {
      if (!inputRef.current) return;

      // Removing escalation requires the full projectCompsSet query to rerun and this
      // to rerender. On a very slow connection with large projects (or in Jenkins), this
      // could take more than 500ms, so we retry.
      if (inputRef.current.disabled) {
        setTimeout(focusInput, 500);
      } else {
        inputRef.current.focus();
      }
    };

    setTimeout(focusInput, 500);
  };

  const handleRemoveEscalation = () => {
    props.onChange('');
  };

  // Create a ref so that we can focus the input when the user switches to manual mode.
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <>
      <EscalationInput
        inputRef={inputRef}
        isAutoEscalated={isAutoEscalated}
        isHighlighted={props.isHighlighted}
        onAutoEscalate={handleAutoEscalate}
        onAutoEscalateAll={handleAutoEscalateAll}
        onChange={props.onChange}
        onManualEscalate={handleManualEscalate}
        onMouseOver={props.onMouseOver}
        onRemoveEscalation={handleRemoveEscalation}
        startAdornment={
          <StartAdornment
            icon={isAutoEscalated ? LocationOn : Location}
            isAutoEscalated={isAutoEscalated}
            isFuzzyMatch={isFuzzyMatch}
            label={props.meta?.sourceLabel ?? ''}
            onClick={() => setIsEditLocationDialogOpen(true)}
          />
        }
        type="location"
        value={props.value}
      />
      {props.locationTo && isEditLocationDialogOpen && (
        <EditLocationSourceDialog
          isFuzzyMatch={isFuzzyMatch}
          locationFrom={{
            name: props.meta?.sourceLabel ?? props.locationFrom.name,
            lat: props.locationFrom.lat,
            lon: props.locationFrom.lon,
          }}
          locationTo={props.locationTo}
          onApply={(location) => {
            fetchLocationEscalation({
              variables: {
                from: location,
                to: props.locationTo,
              },
            });
            setIsEditLocationDialogOpen(false);
            setToast({ message: 'Escalation location changed' }, ToastType.SUCCESS);
          }}
          onClose={() => setIsEditLocationDialogOpen(false)}
          project={props.project}
        />
      )}
      {isNoTargetLocationDialogOpen && (
        <EditLocationTargetDialog
          isFuzzyMatch={isFuzzyMatch}
          onApply={(newTargetlocation) => {
            // Determine if escalating single project or all projects
            if (selectedOption === SelectedEscalationOption.EscalateSingle) {
              fetchLocationEscalation({
                variables: {
                  from: props.locationFrom,
                  to: newTargetlocation,
                },
              });
            } else if (selectedOption === SelectedEscalationOption.EscalateAll) {
              props.onAutoEscalateAllLocation({
                ...newTargetlocation,
                location: newTargetlocation.name,
                date: new Date(new Date().toISOString().split('T')[0]).toISOString(),
              });
            }
            // Also update target location reference
            props.onTargetLocationChange({
              ...newTargetlocation,
              location: newTargetlocation.name,
            });
            // Set toast
            setToast(
              { message: `Target location set to ${newTargetlocation.name}` },
              ToastType.SUCCESS
            );
            setSelectedOption(SelectedEscalationOption.None);
            setIsNoTargetLocationDialogOpen(false);
          }}
          onClose={() => {
            setSelectedOption(SelectedEscalationOption.None);
            setIsNoTargetLocationDialogOpen(false);
          }}
          targetLocation={{
            location: props.locationTo.name,
            lat: props.locationTo.lat,
            lon: props.locationTo.lon,
          }}
        />
      )}
    </>
  );
};

export default LocationEscalationInput;
