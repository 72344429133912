import {
  PreviewProjectCollaboratorQuery,
  PreviewProjectCollaboratorQueryVariables,
} from '../../../generated/graphql';
import { QueryHookOptions, useQuery } from '../../../hooks/useMountAwareQuery';

import { previewProjectCollaborator } from './queries';

export default function usePreviewProjectCollaboratorQuery(
  projectID: UUID | undefined,
  userID: UUID | undefined,
  options: QueryHookOptions<
    PreviewProjectCollaboratorQuery,
    PreviewProjectCollaboratorQueryVariables
  > = {}
) {
  return useQuery<PreviewProjectCollaboratorQuery, PreviewProjectCollaboratorQueryVariables>(
    previewProjectCollaborator,
    {
      variables: { projectID, userID },
      skip: !projectID || !userID,
      ...options,
    }
  );
}
