import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';

const styles = (theme: KomodoTheme) =>
  createStyles({
    close: {
      margin: 0,
      width: 32,
      height: 32,
      color: theme.palette.joinPrimary,
    },
    content: {
      padding: 24,
    },
    titleContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '24px 24px 20px',
    },
    action: {
      borderTop: `1px solid ${theme.palette.divider}`,
      margin: 0,
      padding: '12px 8px',
    },
    error: {
      color: theme.palette.red,
    },
    padding: {
      paddingTop: 12,
      paddingBottom: 12,
    },
    paddingLeft: {
      paddingLeft: 12,
    },
    catBox: {
      height: 275,
      overflowY: 'auto',
      border: theme.border.line,
    },
    metadataContainer: {
      paddingTop: '8px',
    },
    metadataContent: {
      alignItems: 'center',
      padding: '8px',
      gap: '4px',
      fontFamily: theme.typography.fontFamily,
      fontSize: 16,
      fontWeight: 400,
      lineHeight: '20px',
    },
  });

export default styles;
