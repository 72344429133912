import { useEffect } from 'react';
import { Navigate } from 'react-router-dom';

import { ApolloSandbox as Sandbox } from '@apollo/sandbox/react';

import { tokenUtil } from '../utilities/authUtils';

export default function ApolloSandbox() {
  const isUsable = process?.env?.NODE_ENV === 'development';

  useEffect(() => {
    if (isUsable) {
      document.title = 'Apollo Sandbox';
    }
  }, [isUsable]);

  if (!isUsable) {
    return <Navigate replace to="/404" />;
  }

  return (
    <Sandbox
      className="h-screen w-screen"
      handleRequest={async (endpointUrl, options) => {
        const token = await tokenUtil.getAccessTokenSilently();
        return fetch(endpointUrl, {
          ...options,
          headers: {
            ...options.headers,
            Authorization: `Bearer ${token}`,
          },
        });
      }}
      includeCookies={false}
      initialEndpoint="http://localhost:3001/graphql"
    />
  );
}
