import { useEffect, useState } from 'react';

import { ToastType } from '../../../../api/gqlEnums';
import {
  CreateItemIntegrationObjectInput,
  CreateItemIntegrationObjectMutation,
  SourceSystem,
} from '../../../../generated/graphql';
import { useCreateItemIntegrationObjectMutation } from '../../../../hooks/useCreateItemIntegrationObjectMutation';
import useSendAnalytics from '../../../../hooks/useSendAnalytics';
import { setToast } from '../../../../hooks/useToastParametersLocalQuery';
import { useProjectID } from '../../../../utilities/routes/params';
import { getCostValue, renderCostString } from '../../../CostReport/CostReportUtils';
import {
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  Select,
  StatusBanner,
  TextArea,
  TextInput,
} from '../../../scales';

import {
  ChangeEventTypes,
  ChangeReasons,
  EventStatuses,
  ItemInfo,
  changeEventChangeReasons,
  changeEventStatuses,
  changeEventTypes,
  getInitialItemIntegrationObjectInput,
  getItemIntegrationObjectAnalyticsTypeBySourceSystem,
  getItemIntegrationObjectCostType,
  getItemIntegrationObjectTypeBySourceSystem,
  getSourceSystemIcon,
  getSourceSystemString,
  potentialChangeOrderTypes,
} from './IntegrationsUtils';

type Props = {
  isCreatingItemIntegrationObject: boolean;
  setIsCreatingItemIntegrationObject: (value: boolean) => void;
  setSubmitted: (value: boolean) => void;
  itemInfo: ItemInfo;
  savedSourceProjectID: string;
  savedSourceCompanyID: string;
  sourceSystem: SourceSystem;
  userSourceID: string;
};

export default function IntegrationsCreateItemIntegrationObjectModal(props: Props) {
  const projectID = useProjectID();

  const sourceSystemString = getSourceSystemString(props.sourceSystem);
  const itemIntegrationObjectType = getItemIntegrationObjectTypeBySourceSystem(props.sourceSystem);
  const isProcore = props.sourceSystem === SourceSystem.PROCORE;

  const [itemIntegrationObjectInput, setItemIntegrationObjectInput] =
    useState<CreateItemIntegrationObjectInput>(
      getInitialItemIntegrationObjectInput(props.sourceSystem, props.itemInfo, projectID)
    );

  const [includeCost, setIncludeCost] = useState(false);
  // if the user updates the item description or name after opening the integration section
  // then we should also update the item integration object title / description accordingly
  useEffect(() => {
    setItemIntegrationObjectInput({
      ...itemIntegrationObjectInput,
      title: props.itemInfo.name,
      description: props.itemInfo.description,
    });
    // We only want to update itemIntegrationObjectInput if the user changes the item, we don't care if the itemIntegrationObjectInput updates
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.itemInfo]);

  const sendAnalytics = useSendAnalytics();

  // TODO: add attachment checkbox and subtotal area
  const closeModalAndSetToast = (message: string, toastType: ToastType) => {
    props.setIsCreatingItemIntegrationObject(false);
    setToast({ message }, toastType);
  };

  const onCompleted = (data: CreateItemIntegrationObjectMutation) => {
    if (data?.createItemIntegrationObject?.error) {
      setToast({ message: data?.createItemIntegrationObject?.error }, ToastType.SERVER_ERROR);
      return;
    }
    closeModalAndSetToast(
      `${sourceSystemString} ${itemIntegrationObjectType} successfully created and linked to this Item`,
      ToastType.SUCCESS
    );
    sendAnalytics({
      type: getItemIntegrationObjectAnalyticsTypeBySourceSystem(props.sourceSystem).create,
      eventProperties: {
        includeCosts: !!itemIntegrationObjectInput.cost,
        includeAttachments: !!itemIntegrationObjectInput.shouldIncludeAttachments,
      },
    });
    props.setSubmitted(false);
  };

  const onError = () => {
    closeModalAndSetToast(
      `Something went wrong! Try again, and if it continues, please contact support@join.build`,
      ToastType.SERVER_ERROR
    );
  };

  const [createItemIntegrationObject] = useCreateItemIntegrationObjectMutation({
    onCompleted,
    onError,
  });

  const handleSetCreateItemIntegrationObject = (
    field: keyof CreateItemIntegrationObjectInput,
    value: string | number | boolean
  ) => {
    setItemIntegrationObjectInput((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const handleCreateItemIntegrationObject = () => {
    createItemIntegrationObject(
      props.sourceSystem,
      props.userSourceID,
      props.savedSourceProjectID,
      props.itemInfo.id,
      itemIntegrationObjectInput,
      props.savedSourceCompanyID
    );
    props.setIsCreatingItemIntegrationObject(false);
    props.setSubmitted(true);
  };

  const title = (
    <div className="flex items-center justify-center gap-2">
      {getSourceSystemIcon(props.sourceSystem)}
      <div className="type-heading2">
        Create {sourceSystemString} {itemIntegrationObjectType} from Item
      </div>
    </div>
  );

  const estimateCost =
    props.itemInfo.estimateCost && 'value' in props.itemInfo.estimateCost
      ? props.itemInfo.estimateCost
      : 0;
  const estimateCostDisplay = renderCostString({
    cost: estimateCost,
    isExact: true,
    isWide: true,
    isSigned: true,
  });

  const costValue = getCostValue(estimateCost ?? 0);
  const assetCount = props.itemInfo?.assetCount;
  const hasAssets = assetCount > 0;

  return (
    <Dialog
      footerRight={
        <Button
          isDisabled={false}
          label="Create"
          onClick={handleCreateItemIntegrationObject}
          type="primary"
        />
      }
      isFullHeight
      isOpen={props.isCreatingItemIntegrationObject}
      onClose={() => props.setIsCreatingItemIntegrationObject(false)}
      size="lg"
      title={title}
    >
      <DialogContent>
        <div className="flex flex-col items-center gap-6">
          <div className="mr-auto type-body1">
            Fill out the fields to create a {itemIntegrationObjectType} within {sourceSystemString}{' '}
            that will then be linked with this Item.
          </div>
          <div className="flex w-full gap-2">
            {isProcore && (
              <div className="w-24">
                <TextInput isDisabled label="Number" placeholder="--" />
              </div>
            )}
            <TextInput
              defaultValue={props.itemInfo.name}
              label="Title"
              onChange={(newTitle) => handleSetCreateItemIntegrationObject('title', newTitle)}
              placeholder="Enter title..."
            />
          </div>
          <div className="flex w-full gap-2">
            {isProcore && (
              <div className="min-w-fit">
                <Select
                  defaultValue={EventStatuses.OPEN}
                  entries={changeEventStatuses}
                  label="Status"
                  onChange={(newStatus) =>
                    handleSetCreateItemIntegrationObject('status', newStatus)
                  }
                />
              </div>
            )}
            <div className="w-full">
              <Select
                defaultValue={ChangeEventTypes.ALLOWANCE}
                entries={isProcore ? changeEventTypes : potentialChangeOrderTypes}
                label="Type"
                onChange={(newType) => handleSetCreateItemIntegrationObject('eventType', newType)}
              />
            </div>
            {isProcore && (
              <div className="w-min-fit">
                <Select
                  defaultValue={ChangeReasons.ALLOWANCE}
                  entries={changeEventChangeReasons}
                  label="Change Reason"
                  onChange={(newReason) =>
                    handleSetCreateItemIntegrationObject('changeReason', newReason)
                  }
                />
              </div>
            )}
          </div>
          <div className="flex w-full flex-col">
            <TextArea
              isResizable
              label="Description"
              onChange={(newDescription) =>
                handleSetCreateItemIntegrationObject('description', newDescription)
              }
              placeholder="Enter a description..."
              value={itemIntegrationObjectInput.description || props.itemInfo.description}
            />
          </div>
          {hasAssets && isProcore && (
            <div className="mr-auto flex w-full">
              <Checkbox
                onChange={(event: boolean) => {
                  if (event) {
                    handleSetCreateItemIntegrationObject('shouldIncludeAttachments', true);
                  } else {
                    handleSetCreateItemIntegrationObject('shouldIncludeAttachments', false);
                  }
                }}
              >
                <div className="type-body2">{`Include ${assetCount} Attachments`}</div>
              </Checkbox>
            </div>
          )}
          <div className="flex w-full flex-col gap-3">
            <div className="mr-auto flex w-full">
              <Checkbox
                onChange={(event: boolean) => {
                  if (event) {
                    handleSetCreateItemIntegrationObject('cost', costValue);
                    setIncludeCost(true);
                  } else {
                    handleSetCreateItemIntegrationObject('cost', 0);
                    setIncludeCost(false);
                  }
                }}
              >
                <div className="type-body2">
                  Include Item Estimate Subtotal as {itemIntegrationObjectType}{' '}
                  {getItemIntegrationObjectCostType(itemIntegrationObjectType)}
                </div>
              </Checkbox>
            </div>
            <div
              className={`flex h-8 items-center justify-between ${
                includeCost ? 'bg-background-2' : 'bg-background-1'
              } pl-2 pr-2 text-type-${includeCost ? 'primary' : 'inactive'}`}
            >
              <div>Item Estimate Subtotal: Direct Costs + Markups</div>
              <div>{estimateCostDisplay}</div>
            </div>
          </div>
          {props.itemInfo.contingencyDrawCost && (
            <div className="flex w-full justify-start">
              <StatusBanner type="warning">
                Contingency and Allowance draws are not calculated in {itemIntegrationObjectType}{' '}
                {getItemIntegrationObjectCostType(itemIntegrationObjectType)} Subtotal
              </StatusBanner>
            </div>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
}
