import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';

export const FOOTER_PADDING = 12;
export const FOOTER_TEXT_PADDING = 4;

export const getFooterActionWidth = (theme: KomodoTheme, canOpenNewTab: boolean) =>
  (canOpenNewTab ? 3 : 2) * theme.iconSizes.medium + 2 * FOOTER_PADDING;

export default (theme: KomodoTheme) =>
  createStyles({
    footerButton: {
      color: theme.palette.primaryGrey,
    },
    footerButtonDisabled: {
      color: theme.palette.disabledGrey,
    },
    footerButtonContainer: {
      height: theme.iconSizes.medium,
      width: theme.iconSizes.medium,
    },
    footerButtonContainerDisabled: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    modalFooter: {
      display: 'flex',
      background: theme.palette.backgroundWhite,
      justifyContent: 'space-between',
      zIndex: 999,
      borderTop: theme.border.line,
      alignItems: 'center',
    },
    modalFooterAction: {
      display: 'flex',
      alignItems: 'center',
      padding: FOOTER_PADDING,
    },
    modalFooterInfo: {
      display: 'flex',
      flexDirection: 'column',
      padding: FOOTER_PADDING,
    },
    subtitleText: {
      ...theme.typography.h4Medium,
      color: theme.palette.buttonGrey,
      paddingTop: FOOTER_TEXT_PADDING,
    },
    titleText: {
      ...theme.typography.boldHeader,
      color: theme.palette.primaryGrey,
    },
  });
