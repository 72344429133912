import { useMutation } from '@apollo/client';

import {
  DeleteForecastingReportMutation,
  DeleteForecastingReportMutationVariables,
} from '../../../../generated/graphql';

import { deleteForecastingReport } from './queries';

export const useDeleteForecastingReportMutation = () => {
  const [deleteForecastingReportFunc] = useMutation<
    DeleteForecastingReportMutation,
    DeleteForecastingReportMutationVariables
  >(deleteForecastingReport);

  const mutation = (reportID: UUID, onSuccess?: () => void, onFailure?: () => void) => {
    deleteForecastingReportFunc({
      variables: {
        reportID,
      },
    }).then(({ data }) => {
      if (data?.deleteForecastingReport) {
        if (onSuccess) onSuccess();
      } else if (onFailure) onFailure();
    });
  };
  return [mutation];
};
