import { CostTableColumnInputKey } from '../../../generated/graphql';
import { formatCommas } from '../../../utilities/currency';
import { renderPercentString } from '../../../utilities/string';
import { getCostValue, renderValueString } from '../../CostReport/CostReportUtils';

export const formatGridTemplateColumns = (
  costTableColumnInputs: CostTableColumnInputs,
  isPrint?: boolean
) => {
  const gridTemplateColumns: string[] = [];
  if (!costTableColumnInputs) return '';
  costTableColumnInputs?.forEach((columnInput) =>
    gridTemplateColumns.push(`${getGridTemplateRatioByKey(columnInput.key, isPrint)}fr`)
  );
  return gridTemplateColumns.join(' ');
};

const getGridTemplateRatioByKey = (key?: CostTableColumnInputKey, isPrint?: boolean) => {
  switch (key) {
    case CostTableColumnInputKey.TOTAL:
      return isPrint ? 12 : 2;
    case CostTableColumnInputKey.TOTAL_PER_METRIC:
      return isPrint ? 8 : 1.67;
    case CostTableColumnInputKey.METRIC:
    case CostTableColumnInputKey.PERCENT:
      return isPrint ? 6 : 1;
    default:
      return isPrint ? 6 : 1;
  }
};

export const getFormattedColumnValue = (
  key: CostTableColumnInputKey,
  columnValueNumeric: Numeric
) => {
  switch (key) {
    case CostTableColumnInputKey.TOTAL:
    case CostTableColumnInputKey.TOTAL_PER_METRIC:
      return renderValueString({
        value: columnValueNumeric,
        isExact: true,
      });
    case CostTableColumnInputKey.PERCENT:
      return renderPercentString({
        value: columnValueNumeric / 100,
        overrideZeroCostDisplay: true,
      });
    case CostTableColumnInputKey.METRIC:
      return formatCommas(columnValueNumeric);
    default:
      return getCostValue(columnValueNumeric);
  }
};
