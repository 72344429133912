import { FC } from 'react';

import { useReactiveVar } from '@apollo/client';

import { filesExplorerSortStateVar } from '../../../api/apollo/reactiveVars';
import { UserSource } from '../../../generated/graphql';
import useMemoWrapper from '../../useMemoWrapper';
import {
  getAssetIDsforDisabling,
  useEntityAttachments,
} from '../Files/FilesAssets/FilesAssetsUtils';
import FilesTable from '../Files/FilesTable/FilesTable';
import { useFilesTableSelection } from '../Files/FilesTable/FilesTableUseUtils';
import { FileTableCell } from '../Files/FilesTable/FilesTableUtils';

import FilesExplorerError from './FilesExplorerError';
import { getErrorMessage, getFileTableEntriesForEntities } from './FilesExplorerUtils';
import { useLoadSourceFolderContents } from './hooks/FileExplorerHooks';

type FileExplorerFolderContentsProps = {
  userSource: UserSource;
  folderID?: string;
  hubID?: string;
  sourceProjectID?: string;
};

const FileExplorerFolderContents: FC<FileExplorerFolderContentsProps> = ({
  userSource,
  folderID,
  hubID,
  sourceProjectID,
}) => {
  const sortBy = useReactiveVar(filesExplorerSortStateVar);

  const { data, previousData, loading, error } = useLoadSourceFolderContents(
    userSource.sourceSystem,
    userSource.id,
    sortBy,
    hubID,
    folderID,
    sourceProjectID
  );

  const sourceFolderContents = data?.sourceFolderContents || previousData?.sourceFolderContents;
  const entityAssets = useEntityAttachments();
  const entityAssetIDs = getAssetIDsforDisabling(entityAssets);
  const fileTableEntries = useMemoWrapper(
    getFileTableEntriesForEntities,
    sourceFolderContents,
    entityAssetIDs
  );
  const { onEntry, onToggleEntry, onSelectAll, onUnselectAll, selectedIds } =
    useFilesTableSelection();

  const errorMessage = getErrorMessage(sourceFolderContents, error);
  if (errorMessage) return <FilesExplorerError error={errorMessage} userSource={userSource} />;

  const onTableCell = (entry: FileTableCell) => {
    onEntry(
      entry,
      <FileExplorerFolderContents
        folderID={entry.id}
        hubID={hubID}
        sourceProjectID={sourceProjectID}
        userSource={userSource}
      />
    );
  };

  return (
    <FilesTable
      entries={fileTableEntries}
      loading={loading}
      onEntry={onTableCell}
      onSelectAll={onSelectAll}
      onToggleEntry={onToggleEntry}
      onUnselectAll={onUnselectAll}
      selectedIds={selectedIds}
    />
  );
};

export default FileExplorerFolderContents;
