import { mountedItemsVar } from '../../api/apollo/reactiveVars';
import { ItemCostQuery, ItemCostQueryVariables } from '../../generated/graphql';
import { useQuery } from '../../hooks/useMountAwareQuery';
import { MountPolicy } from '../../hooks/usePolicyOnFirstMount';
import { useCostMode } from '../../utilities/costMode';

import { itemCostQuery } from './hooks/itemsListQuery';
import { ItemsListSettings, useItemsListMilestone } from './ItemsListUtils';

export const useItemCostQuery = (
  itemID: UUID,
  projectID: UUID,
  viewFilter: ViewFilterInput,
  settings: ItemsListSettings,
  skipQuery?: boolean
) => {
  const { loading, milestoneID } = useItemsListMilestone(settings);
  return useQuery<ItemCostQuery, ItemCostQueryVariables>(
    itemCostQuery,
    {
      variables: {
        itemID,
        milestoneID,
        projectID,
        costMode: useCostMode(),
        viewFilter,
      },
      skip: !!skipQuery || loading,
      context: { batch: true },
    },
    mountedItemsVar().get(itemID) ? MountPolicy.SKIP_ON_MOUNT : MountPolicy.FETCH_ON_MOUNT
  );
};
