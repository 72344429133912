import 'mapbox-gl/dist/mapbox-gl.css';
import { FC, RefObject, useEffect, useRef, useState } from 'react';
// eslint-disable-next-line react/no-deprecated -- TODO CT-1161: Fix this pls :)
import { render } from 'react-dom';

import { Avatar, Typography } from '@material-ui/core';
import IconError from '@material-ui/icons/ErrorOutline';

import { GetProjectLocationsQuery } from '../../../generated/graphql';
import theme, { withStyles } from '../../../theme/komodo-mui-theme';
import { formatCost } from '../../../utilities/currency';
import ExecutiveDashboardLoadingState from '../ExecutiveDashboardLoadingState/ExecutiveDashboardLoadingState';
import { PROJECTS_SOURCE, locationToGeoJSONFeature } from '../ExecutiveDashboardUtils';

import ExecutiveDashboardProjectMapHint from './ExecutiveDashboardProjectMapHint';
import ExecutiveDashboardProjectMapLocation from './ExecutiveDashboardProjectMapLocation';
import ExecutiveDashboardProjectMapStyles from './ExecutiveDashboardProjectMapStyles';

type ExecutiveDashboardProjectMapProps = {
  classes: Classes<typeof ExecutiveDashboardProjectMapStyles>;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  loading?: boolean;
  projectLocations?: ProjectLocationData[];
  projectsWithoutLocation?: GetProjectLocationsQuery['projectLocations']['projectsWithoutLocation'];
  onMouseOver?: () => void;
  onFocus?: () => void;
};

const renderMap = (
  classes: Classes<typeof ExecutiveDashboardProjectMapStyles>,
  features: GeoJSON.Feature<GeoJSON.Point>[],
  mapRef: RefObject<HTMLDivElement>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  setMap: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  M: any
) => {
  if (!mapRef || !mapRef.current) return;
  const map = new M.Map({
    container: mapRef.current as HTMLElement,
    style: 'mapbox://styles/join-build/cku641pch37wn17pj7gy4whms', // this is our map tiles style
    center: [-95.7, 37.1], // United States
    minZoom: 0.4,
    zoom: 1.9,
    interactive: true,
  });

  map.on('load', () => {
    setMap(map);
    map.addSource(PROJECTS_SOURCE, {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features,
      },
    });

    // Add a layer showing the projects. Circle areas are proportional to APV.
    const normalizedRadius = ['sqrt', ['get', 'apvFraction']];
    map.addLayer({
      id: PROJECTS_SOURCE,
      type: 'circle',
      source: PROJECTS_SOURCE,
      paint: {
        'circle-color': theme.palette.accepted,
        'circle-opacity': 0.7,
        'circle-radius': [
          'interpolate',
          ['linear'],
          ['zoom'],
          2,
          ['+', 1, ['*', 10.0, normalizedRadius]],
          10,
          ['+', 6, ['*', 30.0, normalizedRadius]],
        ],
      },
    });

    // Create a popup, but don't add it to the map yet.
    const popup = new M.Popup({
      anchor: 'top-left',
      className: classes.popup,
      closeButton: false,
      closeOnClick: false,
      maxWidth: '300px',
      offset: [10, 10],
    });

    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
    map.on('mousemove', PROJECTS_SOURCE, (e: any) => {
      const canvas = map.getCanvas();
      canvas.style.cursor = 'pointer';
      const { left, top } = canvas.getBoundingClientRect();

      // Copy coordinates array.
      const coordinates: [number, number] = e.features[0].geometry.coordinates.slice();
      const { location = '', projects = '[]' } = e.features[0].properties;

      const content = (
        <div className={classes.locationContainer} style={{ left, top }}>
          <ExecutiveDashboardProjectMapLocation
            classes={classes}
            location={location}
            projects={JSON.parse(projects)}
          />
        </div>
      );

      // Ensure that if the map is zoomed out such that multiple
      // copies of the feature are visible, the popup appears
      // over the copy being pointed to.
      while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
      }

      const placeholder = document.createElement('div');
      render(content, placeholder);

      // Populate the popup and set its coordinates based on the feature found.
      popup.setLngLat(coordinates).setDOMContent(placeholder).addTo(map);
    });

    map.on('mouseleave', PROJECTS_SOURCE, () => {
      map.getCanvas().style.cursor = '';
      popup.remove();
    });
  });
};

const ExecutiveDashboardProjectMap: FC<ExecutiveDashboardProjectMapProps> = ({
  classes,
  loading = false,
  projectLocations = [],
  projectsWithoutLocation = { totalAPV: 0, projects: [] },
  onMouseOver,
  onFocus,
}) => {
  const mapRef = useRef<HTMLDivElement>(null);
  const hintRef = useRef<HTMLDivElement>(null);
  const maxAPV =
    projectLocations.length < 1 || projectLocations[0].totalAPV < 1
      ? 1
      : projectLocations[0].totalAPV;
  const features = projectLocations.map((location: ProjectLocationData) =>
    locationToGeoJSONFeature(location, maxAPV)
  );
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  const [map, setMap] = useState<any>(null);
  const [hintOpen, setHintOpen] = useState(false);

  // projectsWithoutLocation could be null when reloading
  // after changing the filters on the chart
  const hasProjectsWithoutLocation =
    !!projectsWithoutLocation && projectsWithoutLocation.projects.length > 0;

  useEffect(() => {
    if (map) {
      map.resize();
      map.getSource(PROJECTS_SOURCE).setData(
        {
          type: 'FeatureCollection',
          features,
        },
        hasProjectsWithoutLocation
      );
      return;
    }

    import('mapbox-gl').then((module) => {
      const M = module.default;
      // This is a public access token linked to our shared Mapbox account (see 1Password).
      M.accessToken =
        'pk.eyJ1Ijoiam9pbi1idWlsZCIsImEiOiJja3U1c2ZzZTkwMG5iMm9tbjl1ZjBqZHN5In0.JHVLaAIMQlv9SmI7EH_2kA';

      renderMap(classes, features, mapRef, setMap, M);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO CT-566: Fix this pls :)
  }, [features]);

  return (
    <div className={classes.container} onFocus={onFocus} onMouseOver={onMouseOver}>
      <ExecutiveDashboardProjectMapHint
        anchorEl={hintRef}
        open={hintOpen}
        projects={hasProjectsWithoutLocation ? projectsWithoutLocation.projects : []}
      />
      {(loading || !map) && (
        <div className={classes.loading}>
          <ExecutiveDashboardLoadingState />
        </div>
      )}
      <div ref={mapRef} className={classes.map} />
      {hasProjectsWithoutLocation && (
        <div
          ref={hintRef}
          className={classes.locationlessProjects}
          onMouseEnter={() => setHintOpen(true)}
          onMouseLeave={() => setHintOpen(false)}
        >
          <div className={classes.project} data-cy="project-location-hover">
            <Avatar className={classes.avatar}>
              <IconError className={classes.iconError} />
            </Avatar>
            <Typography className={classes.label}>
              {`Unassigned Location Projects: ${formatCost(projectsWithoutLocation.totalAPV, {
                showCents: false,
              })}`}
            </Typography>
          </div>
        </div>
      )}
    </div>
  );
};

export default withStyles(ExecutiveDashboardProjectMapStyles)(ExecutiveDashboardProjectMap);
