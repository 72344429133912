import { useState } from 'react';

import { Close, Help } from '@material-ui/icons';

import {
  previewEventTypes,
  previewPermissionsAnalytics,
  previewTypes,
} from '../../../analytics/analyticsEventProperties';
import {
  defaultPreviewSettings,
  setPreviewSettingsVar,
  usePreviewSettingsVar,
} from '../../../api/apollo/reactiveVars';
import { Role } from '../../../generated/graphql';
import useCollaboratorsQuery from '../../../hooks/useCollaboratorsQuery';
import { MountPolicy } from '../../../hooks/usePolicyOnFirstMount';
import useRolesQuery from '../../../hooks/useRolesQuery';
import useSendAnalytics from '../../../hooks/useSendAnalytics';
import { useProjectID } from '../../../utilities/routes/params';
import { useUserProfile } from '../../../utilities/userProfile';
import AssigneeSelectData from '../../AssigneeSelect/AssigneeSelectData';
import { DialogsAddCollaborator } from '../../Dialogs';
import CategoriesMultiSelectPopover from '../../dragon-scales/CategoriesMultiSelectPopover/CategoriesMultiSelectPopover';
import ShareGroupAdd from '../../Icons/ShareGroupAdd';
import { Button, ButtonBar, Select, Tooltip } from '../../scales';

import ModeBar from './ModeBar';

export default function PreviewModeBar() {
  const sendAnalytics = useSendAnalytics();
  const preview = usePreviewSettingsVar();
  const isUserPreview = Boolean(preview.userID);

  const currentUserEmail = useUserProfile()?.email;

  const projectID = useProjectID();
  if (!projectID) {
    throw new Error('Failed to get projectID param');
  }

  const roles = useRolesQuery(projectID, MountPolicy.SKIP_ON_MOUNT).data?.projectRoles ?? [];
  const role = preview.roleID ? roles.find((r) => preview.roleID === r.id) : undefined;

  const collaborators = useCollaboratorsQuery(projectID).data?.collaborators ?? [];
  const collaborator = preview.userID
    ? collaborators.find((c) => preview.userID === c.user.id)
    : undefined;

  const previewAllTrades = preview.allTrades ?? collaborator?.allTrades ?? true;

  const [showAddCollaboratorDialog, setShowAddCollaboratorDialog] = useState(false);

  return (
    <>
      <ModeBar
        className="gap-2"
        data-cy="preview-mode-bar"
        title={
          <div className="flex gap-2">
            <div>Preview</div>
            <Tooltip content="Preview allows you to test drive as another person to see how your teammates will experience this project in Join. You can choose any teammate or role to preview.">
              <div className="icon-sm">
                <Help fontSize="inherit" />
              </div>
            </Tooltip>
          </div>
        }
      >
        <ButtonBar
          data-cy="preview-mode-button-bar"
          onChange={(value) => {
            if (value === 'User') {
              const newCollaborator =
                collaborator ?? collaborators.find((c) => c.user.email === currentUserEmail);

              if (!newCollaborator) return;

              setPreviewSettingsVar({
                userID: newCollaborator.user.id,
                roleID: preview.roleID,
              });

              sendAnalytics(
                previewPermissionsAnalytics(
                  previewEventTypes.PREVIEW_SELECT_PREVIEW_TYPE,
                  previewTypes.USER,
                  newCollaborator.role.name
                )
              );
            } else {
              const newRole = role ?? roles[0];
              if (!newRole) return;

              setPreviewSettingsVar({
                userID: undefined,
                roleID: role?.id ?? roles[0].id,
                trades: collaborator?.trades.map((t) => ({
                  id: t.id,
                  categorizationID: t.categorization?.id ?? '',
                })),
                allTrades: previewAllTrades,
              });

              sendAnalytics(
                previewPermissionsAnalytics(
                  previewEventTypes.PREVIEW_SELECT_PREVIEW_TYPE,
                  previewTypes.ROLE,
                  newRole.name
                )
              );
            }
          }}
          options={[
            { label: 'Role', value: 'Role' },
            { label: 'User', value: 'User' },
          ]}
          value={isUserPreview ? 'User' : 'Role'}
        />
        {isUserPreview && collaborator && (
          <>
            <div className="w-75">
              <AssigneeSelectData
                aria-label="select user to preview"
                data-cy="preview-user-select"
                onChange={(email) => {
                  const newCollaborator = collaborators.find((c) => email === c.user.email);
                  if (!newCollaborator) return;

                  setPreviewSettingsVar({
                    userID: newCollaborator.user.id,
                    roleID: newCollaborator.role.id,
                  });

                  sendAnalytics(
                    previewPermissionsAnalytics(
                      previewEventTypes.PREVIEW_SELECT_USER,
                      previewTypes.USER,
                      newCollaborator.role.name
                    )
                  );
                }}
                projectID={projectID!}
                value={collaborator.user.email}
              />
            </div>
            <div className="text-type-muted type-body2">Role: {collaborator.role.name}</div>
          </>
        )}
        {!isUserPreview && role && (
          <>
            <div className="w-50">
              <Select
                aria-label="select role to preview"
                data-cy="preview-role-select"
                entries={roles.map((r) => ({ id: r.id, label: r.name }))}
                onChange={(value) => {
                  const newRole = roles.find((r: Role) => value === r.id);
                  if (!newRole) return;

                  setPreviewSettingsVar({
                    userID: undefined,
                    roleID: newRole.id,
                    allTrades: previewAllTrades,
                  });

                  sendAnalytics(
                    previewPermissionsAnalytics(
                      previewEventTypes.PREVIEW_SELECT_ROLE_TYPE,
                      previewTypes.ROLE,
                      newRole.name
                    )
                  );
                }}
                value={role.id}
              />
            </div>
            {role.hasTrade && (
              <div className="w-75">
                <CategoriesMultiSelectPopover
                  defaultValue={{
                    categories: preview.trades ?? [],
                    isAllSelected: Boolean(preview.allTrades),
                  }}
                  onChange={(trades) => {
                    setPreviewSettingsVar({
                      roleID: role.id,
                      allTrades: trades.isAllSelected,
                      trades: trades.categories,
                    });
                  }}
                  projectID={projectID}
                />
              </div>
            )}
          </>
        )}
        <div className="ml-auto">
          <Button
            label="Invite Teammates"
            onClick={() => {
              sendAnalytics(
                previewPermissionsAnalytics(
                  previewEventTypes.PREVIEW_ADD_COLLABORATORS_CTA,
                  previewTypes.ROLE,
                  role?.name || collaborator?.role.name || ''
                )
              );
              setShowAddCollaboratorDialog(true);
            }}
            startIcon={<ShareGroupAdd />}
            type="secondary"
          />
        </div>
        <Button
          data-cy="preview-exit-button"
          label="Exit Preview"
          onClick={() => {
            setPreviewSettingsVar(defaultPreviewSettings);
            sendAnalytics(
              previewPermissionsAnalytics(
                previewEventTypes.PREVIEW_EXIT_CTA,
                isUserPreview ? previewTypes.USER : previewTypes.ROLE,
                role?.name ?? collaborator?.role.name ?? ''
              )
            );
          }}
          startIcon={<Close />}
          type="warning"
        />
      </ModeBar>
      {showAddCollaboratorDialog && (
        <DialogsAddCollaborator onClose={() => setShowAddCollaboratorDialog(false)} />
      )}
    </>
  );
}
