import { TimelineQuery } from '../../../generated/graphql';
import { getTodayUtcNoon } from '../../../utilities/dates';
import { ItemTimeSeries } from '../../dragon-scales/TimelineCharts/InsightsItems/types';
import { InsightsProject } from '../types';
import { fixMocksStartEnd, fixMocksToday } from '../utils';

import * as hardcodedDemoData from './exampleDemoData.json';

export type InsightsProjectDemoData = InsightsProjects & {
  demoDataReferenceDate: Date;
  itemsDueDateSeriesMockData?: ItemTimeSeries[];
  timelineActivitiesMockData?: TimelineQuery['timeline']['activities'];
};

// List of date field names that need to be parsed as dates, and not just strings.
const dateFields = ['date', 'demoDataReferenceDate'];

export const parseDemoData = (raw: string) => {
  let str = raw;
  if (raw.length === 0) {
    str = JSON.stringify(hardcodedDemoData);
  }

  try {
    const dd = JSON.parse(str, (key, value) => {
      if (dateFields.includes(key)) {
        return new Date(value);
      }
      return value;
    }) as InsightsProjectDemoData;
    // roll forward demodata.
    return dd;
  } catch {
    // Don't error, just skip.
    return {} as InsightsProjectDemoData;
  }
};

export const fixProjectMocks = (project: InsightsProject, isFix?: boolean): InsightsProject => {
  if (!isFix && (!project.demoData || project.demoData.length === 0 || project.demoData === '{}')) {
    return project;
  }
  const parsed = parseDemoData(project.demoData || '');

  const referenceDate = parsed.demoDataReferenceDate;
  const delta = referenceDate ? getTodayUtcNoon().getTime() - referenceDate.getTime() : 0;
  if (parsed.costTimeseries) {
    parsed.costTimeseries = fixMocksToday(parsed.costTimeseries, delta);
  }
  if (parsed.contingenciesTimeseries) {
    parsed.contingenciesTimeseries = fixMocksToday(parsed.contingenciesTimeseries, delta);
  }
  if (parsed.milestones) {
    parsed.milestones = fixMocksToday(parsed.milestones, delta);
  }
  if (parsed.itemsDueDateSeriesMockData) {
    parsed.itemsDueDateSeriesMockData = fixMocksToday(
      parsed.itemsDueDateSeriesMockData || [],
      delta
    );
  }
  if (parsed.timelineActivitiesMockData) {
    parsed.timelineActivitiesMockData = fixMocksStartEnd(
      parsed.timelineActivitiesMockData || [],
      delta
    );
  }

  // Override fields in project with the parsed demo data.
  const parsedProject: InsightsProject = {
    ...project,
    ...parsed,
  };
  return parsedProject;
};
