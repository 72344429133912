import { useMutation } from '@apollo/client';

import { AddOrgLevelMutation, AddOrgLevelMutationVariables } from '../../../../generated/graphql';
import { addOrgLevelMutation } from '../../../Organizations/hooks/queries';

const useAddOrgLevelMutation = () => {
  const [addOrgLevel] = useMutation<AddOrgLevelMutation, AddOrgLevelMutationVariables>(
    addOrgLevelMutation
  );
  return (organizationID: UUID) => {
    return addOrgLevel({
      variables: {
        input: {
          organizationID,
        },
      },
    });
  };
};

export default useAddOrgLevelMutation;
