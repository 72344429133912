import { FC } from 'react';

import { Typography } from '@material-ui/core';

import { withStyles } from '../../../../theme/komodo-mui-theme';

import ExcelHelpPageComponent from './ExcelHelpPageComponent';
import styles from './ExcelHelpPageStyles';
import { excelTemplates } from './ExcelHelpPageText';

type ExcelHelpPageProps = {
  classes: Classes<typeof styles>;
};

const ExcelHelpPage: FC<ExcelHelpPageProps> = ({ classes }) => {
  const content = excelTemplates.map((template) => {
    return <ExcelHelpPageComponent key={template} excelTemplate={template} />;
  });
  return (
    <div>
      <Typography> Download one of the Blank Spreadsheet Templates below.</Typography>
      <Typography className={classes.headerText}>
        Use the following links to download blank spreadsheet templates which can be modified and
        imported back into Join. Each spreadsheet template includes the required columns depending
        on the cost breakdown.
      </Typography>
      {content}
    </div>
  );
};

export default withStyles(styles)(ExcelHelpPage);
