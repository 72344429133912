import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';

export const SCROLL_MARGIN = 300;

export default (theme: KomodoTheme) =>
  createStyles({
    card: {
      overflow: 'unset',
      marginBottom: theme.spacing.unit * 2,
      marginTop: theme.spacing.unit * 2,
    },
    collapseTitle: {
      ...theme.typography.cardHeader,
      backgroundColor: 'transparent',
      fontWeight: 500,
      paddingTop: 2,
    },
    costContainer: {
      flexGrow: 1,
    },
    contingencySelect: {
      flexGrow: 1,
      minWidth: 0, // this looks weird, but it's necessary for the text ellipses
    },
    contingencyContainer: {
      margin: 14,
      display: 'flex',
    },
    congingencyButton: {
      display: 'flex',
      cursor: 'pointer',
      height: 32,
      width: 'auto',
      marginLeft: 4,
    },
    costImpact: {
      marginLeft: 8,
      marginRight: 4,
    },
    costImpactContainer: {
      display: 'flex',
    },
    contingencyFullDrawCheckbox: {
      width: 24,
      height: 24,
      margin: 4,
    },
    newContingencyIcon: {
      width: 24,
      height: 24,
      margin: 4,
      marginLeft: 4,
    },
    contingencyFullDrawText: {
      fontWeight: 400,
      alignSelf: 'center',
      textWrap: 'nowrap',
    },
    disabled: {
      color: theme.palette.disabledGrey,
    },
    editButtonContainer: {
      paddingLeft: 8,
    },
    icon: {
      fill: theme.palette.disabledGrey,
      width: 16,
      height: 16,
      marginTop: 4,
      marginLeft: 4,
    },
  });
