import { createStyles } from '@material-ui/core';

const panelPadding = '12px';

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
export default (theme: any) =>
  createStyles({
    disabled: {
      color: theme.palette.disabledGrey,
      cursor: 'auto',
    },
    header: {
      background: theme.palette.backgroundGrey,
      borderTopLeftRadius: theme.border.radius,
      display: 'flex',
      justifyContent: 'space-between',
      padding: panelPadding,
    },
    selectBtn: {
      background: 'none',
      border: 'none',
      cursor: 'pointer',
      textDecoration: 'underline',
    },
  });
