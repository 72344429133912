import { FC } from 'react';

import {
  CompanyAdminEventType,
  CompanyAdminView,
  companyAdminAnalyticsEvent,
} from '../../analytics/analyticsEventProperties';
import useSendAnalytics from '../../hooks/useSendAnalytics';
import { withStyles } from '../../theme/komodo-mui-theme';
import InputsToggle, { ToggleOption } from '../Inputs/InputsToggle/InputsToggle';

import CompanyTabStyles from './CompanyTabStyles';
import { UserStatusToggle } from './CompanyTabUtils';

type CompanyTabUsersFilterMenuProps = {
  classes: Classes<typeof CompanyTabStyles>;
  filterStatus: string;
  setFilterStatus: (view: string) => void;
  searchStorage: string;
  userCounts: number[];
  view?: CompanyAdminView;
};

const CompanyTabUsersFilterMenu: FC<CompanyTabUsersFilterMenuProps> = ({
  classes,
  filterStatus,
  setFilterStatus,
  searchStorage,
  userCounts,
  view,
}) => {
  const options = [
    {
      text: UserStatusToggle.ALL,
      icon: (
        <span className={classes.toggleText}>
          {UserStatusToggle.ALL} ({userCounts[0]})
        </span>
      ),
    },
    {
      text: UserStatusToggle.USER_STATUS_ACTIVE,
      icon: (
        <span className={classes.toggleText}>
          {UserStatusToggle.USER_STATUS_ACTIVE} ({userCounts[1]})
        </span>
      ),
    },
    {
      text: UserStatusToggle.USER_STATUS_DEACTIVATED,
      icon: (
        <span className={classes.toggleText}>
          {UserStatusToggle.USER_STATUS_DEACTIVATED} ({userCounts[2]})
        </span>
      ),
    },
    {
      text: UserStatusToggle.USER_STATUS_PENDING,
      icon: (
        <span className={classes.toggleText}>
          {UserStatusToggle.USER_STATUS_PENDING} ({userCounts[3]})
        </span>
      ),
    },
  ];

  const getToggleOption = (text: string) => {
    const option = options.find((o) => o.text === text);
    return {
      key: text,
      text,
      icon: option && option.icon,
    };
  };

  const getCompanyAnalyticsEventType = (view?: CompanyAdminView) => {
    if (view === CompanyAdminView.MEMBERS_LIST) {
      return CompanyAdminEventType.MEMBER_FILTER;
    }
    if (view === CompanyAdminView.COLLABORATORS_LIST) {
      return CompanyAdminEventType.COLLABORATORS_FILTER;
    }
    return undefined;
  };

  const selected = [getToggleOption(filterStatus)];
  const sendAnalytics = useSendAnalytics();
  const setSelected = (toggleOption: ToggleOption) => {
    setFilterStatus(toggleOption.text);
    localStorage.setItem(searchStorage, toggleOption.text);
    const analyticsEventType = getCompanyAnalyticsEventType(view);
    if (analyticsEventType)
      sendAnalytics(
        companyAdminAnalyticsEvent(analyticsEventType, { view, filter: toggleOption.text })
      );
  };

  return (
    <div className={classes.settingContainer}>
      <InputsToggle
        options={options.map(({ text }) => getToggleOption(text))}
        selected={selected}
        setSelected={setSelected}
      />
    </div>
  );
};

export default withStyles(CompanyTabStyles)(CompanyTabUsersFilterMenu);
