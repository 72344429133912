import { FC } from 'react';

import { columns } from '../../hooks/constants';
import { getProjectIdFromUrl } from '../../utilities/url';
import useMilestoneCostReportQuery from '../CostReport/hooks/useMilestoneCostReportQuery';

import CostSummary from './CostSummary';

type CostSummaryDataProps = {
  milestoneId?: UUID;
};

const CostSummaryData: FC<CostSummaryDataProps> = ({ milestoneId }) => {
  const projectId = getProjectIdFromUrl();

  const { data: { milestoneCostReports = null } = {}, loading } = useMilestoneCostReportQuery(
    milestoneId,
    [],
    columns,
    projectId,
    {}
  );
  const costReport = milestoneCostReports && milestoneCostReports[0];

  return loading ? null : (
    <div className="print-subheader-cost">
      <CostSummary costReport={costReport} noPadding />
    </div>
  );
};

export default CostSummaryData;
