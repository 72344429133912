import { MoreVert } from '@material-ui/icons';

import { UploadPlatform } from '../../../../api/gqlEnums';

export const getHelpContent = (
  filter: string
): {
  bulletPoints: React.ReactNode[];
  header: string;
  helpCenterLink: string;
  hint: string;
  image?: string;
  video: string;
} => {
  switch (filter) {
    case UploadPlatform.DESTINI:
      return {
        header:
          'To use the Destini integration with Join, first you’ll need to download an Excel file of your estimate. Watch the video above for a quick how-to guide, or ',

        hint: 'A few helpful hints:',
        bulletPoints: [
          '-  Once you’ve downloaded your estimate, use the original .zip folder (don’t unzip or extract any files)',
          ' -  Make sure to download your estimate from the Home tab rather than a specific view or the dashboard',
          " - Join will import all estimate lines, all WBS codes, and some markup information; you'll need to review and apply markups in Join",
        ],
        video: 'https://www.youtube.com/embed/xggE9HaZwkI?rel=0&amp;vq=hd1080',
        helpCenterLink:
          'https://success.join.build/en/knowledge/destini-integration-how-to-export-your-cost-estimate-from-destini',
      };
    case UploadPlatform.SAGE:
      return {
        header:
          'To use the Sage integration with Join, first you’ll need to download a zip file of your estimate. Watch the video above for a quick how-to guide, or ',
        hint: 'A few helpful hints:\n',
        bulletPoints: [
          '  - Once you’ve downloaded your estimate, use the original .zip folder (don’t unzip or extract any files)',
          '  - If you access Sage on a virtual machine, make sure to download your estimate to a place where you can easily find it',
          "  - Join will import all estimate lines, all WBS codes, and some markup information; you'll need to review and apply markups in Join",
        ],
        video: 'https://www.youtube.com/embed/M0wdbprIDG8?rel=0&amp;vq=hd1080',
        helpCenterLink:
          'https://success.join.build/en/knowledge/sage-estimating-integration-how-to-export-your-cost-estimate-from-sage-estimating',
      };
    case UploadPlatform.WINEST:
      return {
        header:
          'To use the WinEst integration with Join, first you’ll need to download an XML file of your estimate. Watch the video above for a quick how-to guide, or ',

        hint: 'A few helpful hints:',
        bulletPoints: [
          ' -  Make sure to use the XML export from your Tools menu, rather than exporting a specific estimate view',
          ' -  If you access WinEst on a virtual machine, make sure to download your estimate to a place where you can easily find it ',
          " -  Join will import all estimate lines, all WBS codes, and some markup information; you'll need to review and apply markups in Join",
        ],
        video: 'https://www.youtube.com/embed/iFby_zVtHfE?rel=0&amp;vq=hd1080',
        helpCenterLink:
          'https://success.join.build/en/knowledge/winest-integration-how-to-import-your-estimate-into-join',
      };
    case UploadPlatform.PROCORE:
      return {
        header:
          'To use the Procore integration with Join, first you’ll need to download an excel file of your estimate or',
        hint: '',
        bulletPoints: [
          <>
            - In Procore Estimating, click the <MoreVert style={{ height: 20, width: 20 }} />
            button in the top right of the estimate
          </>,
          '- Hover over Export Estimate and select Excel',
          '- This will download the estimate to a spreadsheet that can be directly imported into Join',
        ],
        image: 'url(/img/Import_Estimate/Procore.gif)',
        video: '',
        helpCenterLink:
          'https://success.join.build/en/knowledge/exporting-a-procore-estimating-estimate?hs_preview=jJVVbneL-164926678412',
      };
    default:
      return { header: '', hint: '', bulletPoints: [], video: '', helpCenterLink: '' };
  }
};
