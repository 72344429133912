import ContentRow from './EstimateChartHoverContentRow';
import { CategoryTotals } from './hooks/useProgramCategorizedTotalsQuery';

type Props = {
  categoryCostData: CategoryTotals;
  fieldsToBold: (keyof CategoryTotals)[];
};

export default function EstimateChartHoverContent({ categoryCostData, fieldsToBold }: Props) {
  const hasAcceptedChanges = Number(categoryCostData.accepted) !== 0;
  return (
    <div className="flex w-72 flex-col gap-1 bg-background-1">
      <div className="type-body2">{`${categoryCostData.categoryName}`}</div>
      <ContentRow
        isBold={shouldBold('estimate', fieldsToBold)}
        label="Combined Estimate Total"
        textColor="text-entities-estimate"
        value={categoryCostData.estimate}
      />
      {hasAcceptedChanges && (
        <>
          <ContentRow
            isBold={shouldBold('accepted', fieldsToBold)}
            label="Accepted Changes"
            textColor="text-item-status-accepted"
            value={categoryCostData.accepted}
          />
          <Divider />
          <ContentRow
            isBold={shouldBold('runningTotal', fieldsToBold)}
            label="Combined Running Total"
            textColor="text-entities-estimate"
            value={categoryCostData.runningTotal}
          />
        </>
      )}
      <ContentRow
        isBold={shouldBold('gap', fieldsToBold)}
        label="Gap"
        textColor="text-item-status-gap"
        value={categoryCostData.gap}
      />
      <Divider />
      <ContentRow
        isBold={shouldBold('budget', fieldsToBold)}
        label="Budget"
        textColor="text-entities-budget"
        value={categoryCostData.budget}
      />
    </div>
  );
}

const Divider = () => (
  <svg className="h-2 w-auto">
    <line className="stroke-border-default" strokeWidth={4} x1="0" x2="450" y1="0" y2="0" />
  </svg>
);

const shouldBold = (field: keyof CategoryTotals, fieldsToBold: (keyof CategoryTotals)[]) => {
  return fieldsToBold.includes(field);
};
