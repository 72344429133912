import { gql } from '../../../../api/graphqlFragments';

export const timeEscalationQuery = gql`
  query timeEscalation($from: Time!, $to: Time!) {
    timeEscalation(from: $from, to: $to) {
      percentage
      fuzzyMatch
      from {
        year
        quarter
        index
      }
      to {
        year
        quarter
        index
      }
    }
  }
`;

export const locationEscalationQuery = gql`
  query locationEscalation($from: LocationInput!, $to: LocationInput!) {
    locationEscalation(from: $from, to: $to) {
      percentage
      fuzzyMatch
      targetIsFuzzyMatch
      sourceIsFuzzyMatch
      from {
        index
        lat
        lon
        name
      }
      to {
        index
        lat
        lon
        name
      }
    }
  }
`;

export const cityCostIndicesQuery = gql`
  query cityCostIndices($pagination: Pagination!) {
    cityCostIndices(pagination: $pagination) {
      cityCostIndices {
        name
        lat
        lon
        index
      }
      total
    }
  }
`;
