import { useState } from 'react';

import { setCollapseAnalytics } from '../../../../analytics/analyticsEventProperties';
import { Org, OrgNode } from '../../../../generated/graphql';
import {
  CollapseSettings,
  usePersistentStates,
  useSetCollapse,
} from '../../../../utilities/urlState';
import { TreeEntry, makeTree } from '../../../../utilities/utilities';
import useMemoWrapper from '../../../useMemoWrapper';
import DeleteOrgNodeDialog from '../dialogs/DeleteOrgNodeDialog';
import useCreateOrgNodeMutation from '../hooks/useCreateOrgNodeMutation';
import { getOrgUrlConfig } from '../utils';

import OrganizationNodes from './OrganizationNodes';
import OrgHeader from './OrgHeader';

type Props = {
  organization: Org;
};

export default function OrganizationNodesList({ organization }: Props) {
  const allNodes = useMemoWrapper(makeTree<OrgNode>, organization.nodes);
  const addOrgNode = useCreateOrgNodeMutation();
  const [deleteNodeDialogOpen, setDeleteNodeDialogOpen] = useState<TreeEntry<OrgNode> | null>(null);

  const [settings, setSettings] = usePersistentStates(...getOrgUrlConfig(organization.id));

  const setCollapse = useSetCollapse(
    settings as CollapseSettings,
    setSettings,
    setCollapseAnalytics
  );

  const onAddNode = (parentNodeID: string | null) => {
    addOrgNode(organization.id, parentNodeID);
  };

  const onRemoveNode = (node: TreeEntry<OrgNode>) => {
    setDeleteNodeDialogOpen(node);
  };

  return (
    <div className="flex w-full flex-col items-start justify-between gap-1 p-4">
      <div className="flex w-full flex-col gap-2">
        <OrgHeader
          description="Build out your organization below. Each node will reflect the levels defined above."
          title="Organization Buildout"
        />
        <div className="flex w-full flex-col overflow-x-hidden">
          {allNodes.map((node, _, nodes) => (
            <OrganizationNodes
              key={node.id}
              levels={organization.levels}
              node={node}
              nodes={nodes}
              onAddNode={onAddNode}
              onRemoveNode={onRemoveNode}
              setCollapse={setCollapse}
              settings={settings}
            />
          ))}
        </div>
      </div>
      {deleteNodeDialogOpen !== null && (
        <DeleteOrgNodeDialog
          allNodes={allNodes}
          isOpen={deleteNodeDialogOpen !== null}
          nodeDelete={deleteNodeDialogOpen}
          onClose={() => {
            setDeleteNodeDialogOpen(null);
          }}
          organization={organization}
        />
      )}
    </div>
  );
}
