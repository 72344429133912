import { SyntheticEvent } from 'react';

import { FILES_ASSETS_DEFAULT_SORT, filesAssetsSortByVar } from '../../../api/apollo/reactiveVars';
import { UploadPlatform } from '../../../api/gqlEnums';
import useSendAnalytics, { AnalyticsEvent } from '../../../hooks/useSendAnalytics';

const UploadPlatformSVGs = {
  [UploadPlatform.EMPTY_STATE]: {
    upper: 'url(/img/Import_Estimate/EmptyState.svg)',
    lower: '',
  },
  [UploadPlatform.DESTINI]: {
    upper: 'url(/img/Import_Estimate/DESTINI1.svg)',
    lower: 'url(/img/Import_Estimate/DESTINI2.svg)',
  },
  [UploadPlatform.WINEST]: {
    upper: 'url(/img/Import_Estimate/WinEst1.svg)',
    lower: 'url(/img/Import_Estimate/WinEst2.svg)',
  },
  [UploadPlatform.SAGE]: {
    upper: 'url(/img/Import_Estimate/Sage1.svg)',
    lower: 'url(/img/Import_Estimate/Sage2.svg)',
  },
  [UploadPlatform.EXCEL]: {
    upper: 'url(/img/Import_Estimate/GoogleSheets2.svg)',
    lower: '',
  },
  [UploadPlatform.PROCORE]: {
    upper: 'url(/img/Import_Estimate/Procore1.svg)',
    lower: 'url(/img/Import_Estimate/GoogleSheets2.svg)',
  },
};

export const getUploadPlatformSVGs = (uploadPlatform: UploadPlatform) => {
  return UploadPlatformSVGs[uploadPlatform];
};

export const useDragDropFunctions = (
  analyticsEvent: AnalyticsEvent,
  onDropFile: (file: File) => void,
  lastTarget: EventTarget | null,
  setLastTarget: (target: EventTarget) => void,
  setDragging: (dragging: boolean) => void
) => {
  const sendAnalytics = useSendAnalytics();

  const onDragOver = (evt: SyntheticEvent<HTMLDivElement>) => {
    evt.preventDefault();
    setDragging(true);
  };

  const onDragEnter = (evt: SyntheticEvent<HTMLDivElement>) => {
    evt.preventDefault();
    setLastTarget(evt.target);
    setDragging(true);
  };

  const onDragLeave = (evt: SyntheticEvent<HTMLDivElement>) => {
    evt.preventDefault();
    if (evt.target === lastTarget) {
      setDragging(false);
    }
  };

  const addFiles = (files: FileList | null) => {
    filesAssetsSortByVar(FILES_ASSETS_DEFAULT_SORT);
    if (files) Array.from(files).forEach((file: File) => onDropFile(file));
    setDragging(false);
  };

  const onDrop = (evt: React.DragEvent) => {
    evt.preventDefault();
    const { files } = evt.dataTransfer;
    addFiles(files);
    sendAnalytics(analyticsEvent);
  };

  return { onDragOver, onDragEnter, onDragLeave, addFiles, onDrop };
};
