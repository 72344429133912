import { Navigate } from 'react-router-dom';

import { useAuth0 } from '@auth0/auth0-react';

import { useIsJoinAccessDeactivated } from '../hooks/useIsJoinAccessDeactivated';

import { Button, TextLink } from './scales';

export default function DeactivatedRoute() {
  const { logout } = useAuth0();

  // Note: This is the exact inverse of what's happening at RootRoute to send us here...
  // If we're not deactivated, we should be redirected to the home page.
  // These two locations must have the same logic to avoid an infinite loop.
  const isAccessDeactivated = useIsJoinAccessDeactivated();

  if (!isAccessDeactivated) return <Navigate replace to="/" />;

  return (
    <div className="flex items-center justify-center text-type-primary">
      <img
        alt=""
        className="h-100 w-100 object-cover"
        src={`/img/logos/${Math.floor(Math.random() * (9 - 1 + 1)) + 1}.png`}
      />
      <div className="flex flex-col gap-2">
        <div>
          <p className="type-heading1">Your access is currently deactivated.</p>
          <p className="type-heading2">
            Please contact your company administrator to restore access.
          </p>
        </div>
        <div className="text-center">
          <Button
            label="Log out"
            onClick={() => logout({ returnTo: window.location.origin })}
            type="primary"
          />
        </div>
        <p className="type-body1">
          If you think this is an error or reoccurring issue, please contact{' '}
          <TextLink label="support@join.build" to="mailto:support@join.build" />
        </p>
      </div>
    </div>
  );
}
