import { Typography } from '@material-ui/core';

import { MASTERFORMAT_CATEGORY, UNIFORMAT_CATEGORY } from '../../../../constants';
import theme from '../../../../theme/komodo-mui-theme';

export const levelsMf: Level[] = [
  {
    builtIn: MASTERFORMAT_CATEGORY,
    name: 'Level 1 (e.g. 03 00 00)',
    level: 1,
    showDisableTip: false,
  },
  {
    builtIn: MASTERFORMAT_CATEGORY,
    name: 'Level 2 (e.g. 03 01 00)',
    level: 2,
    showDisableTip: false,
  },
  {
    builtIn: MASTERFORMAT_CATEGORY,
    name: 'Level 3 (e.g. 03 01 30)',
    level: 3,
    showDisableTip: false,
  },
  {
    builtIn: MASTERFORMAT_CATEGORY,
    name: 'Level 4 (e.g. 03 01 30.51)',
    level: 4,
    showDisableTip: false,
  },
];

export const levelsUf: Level[] = [
  { builtIn: UNIFORMAT_CATEGORY, name: 'Level 1 (e.g. A)', level: 1, showDisableTip: false },
  { builtIn: UNIFORMAT_CATEGORY, name: 'Level 2 (e.g. A10)', level: 2, showDisableTip: false },
  { builtIn: UNIFORMAT_CATEGORY, name: 'Level 3 (e.g. A1020)', level: 3, showDisableTip: false },
  { builtIn: UNIFORMAT_CATEGORY, name: 'Level 4 (e.g. A1010.10)', level: 4, showDisableTip: false },
];

export enum BuiltInRadioButtonOptions {
  ENABLED = 'ENABLED',
  DISABLED = 'DISABLED',
}

export const NewCategorizationBuiltinMapText = {
  ContentHeader1: 'First, set up UniFormat and MasterFormat.',
  ContentHeader2: (
    <Typography>
      Match the work breakdown structure from your estimate that corresponds with each{' '}
      <a
        data-cy="link-usingUfMf"
        href="https://success.join.build/en/knowledge/using-masterformat-and-uniformat-in-join"
        rel="noreferrer"
        style={{ color: theme.palette.link }}
        target="_blank"
      >
        UniFormat and MasterFormat
      </a>{' '}
      level in Join, or disable built-ins and continue without mapping.
    </Typography>
  ),
};

export const DisableBuiltInsTip =
  'Because of the amount of unmatched categories, we recommend creating this without Built-ins (you can fix unmatched codes later in Step 3, or the Estimate View).';

export const shouldShowDisableBuiltInTip = (
  draftCategorization: DraftCategorization | undefined,
  level: Level
) => {
  const { matched, unmatched } = draftCategorization?.categoryMatchCounts.get(level.builtIn) || {
    matched: 0,
    unmatched: 0,
  };

  const totalCount = matched + unmatched > 0 ? matched + unmatched : 1;
  const unmatchedPercentage = (unmatched / totalCount) * 100;
  return unmatchedPercentage >= 10;
};

export const IncludeCategorizationTip =
  'You must import at-least one Categorization so your estimate has data before moving to the next step';
