import { EstimateType } from '../../../../api/gqlEnums';
import theme from '../../../../theme/komodo-mui-theme';
import { ColumnDescription } from '../../../CostReport/CostReportColumns/CostReportColumns';
import { FilterManager } from '../../../FilterPanel/filterUtils';
import { MilestoneDetailsTabs } from '../../utils';

export type CostParams = {
  categorizations: Categorization[];
  columnDescriptions: ColumnDescription[];
  displayColumnDescriptions: ColumnDescription[];
  displayGroupBy: DisplayGroupBy[];
  filterManager: FilterManager;
  page: string;
  settings: MilestoneCostReportSettings;
  setSetting: (param: string, value: string | string[] | undefined) => void;
};

export const getIconColorLabel = (
  key: MilestoneDetailsTabs | EstimateType,
  milestone: Milestone,
  label: string
) => {
  const { activeEstimateID, activeEstimateDraftID, budgetID, budgetDraftID } = milestone;
  let title;
  let color;
  const indicatorColor = theme.palette.fillGreen;
  const labelText = label.toLowerCase() || 'estimate';

  switch (key) {
    case MilestoneDetailsTabs.ESTIMATE:
    case EstimateType.ACTIVE_ESTIMATE:
      if (activeEstimateDraftID) {
        title = `Draft ${labelText}`;
        color = indicatorColor;
        break;
      }
      if (!activeEstimateID) {
        title = `Start ${labelText}`;
        color = indicatorColor;
      }
      break;
    case MilestoneDetailsTabs.TARGET:
    case EstimateType.BUDGET:
      if (budgetDraftID) {
        title = `Draft ${labelText}`;
        color = indicatorColor;
        break;
      }
      if (!budgetID) {
        title = `Start ${labelText}`;
        color = indicatorColor;
      }
      break;
    default:
      break;
  }
  return { color, title };
};

export const milestonePageStorageParam = (id: UUID) => `Milestone ${id} Page `;
