import { FC, useContext } from 'react';

import { TermKey } from '../../../../api/gqlEnums';
import { CostReportColumnType } from '../../../../generated/graphql';
import { ProjectTermStore } from '../../../ProjectDisplaySettings/TerminologyProvider';
import JoinSelect, { getEntry } from '../../../Select/JoinSelect/JoinSelect';

type TypeSelectProps = {
  keys?: TermKey[] | string[];
  onChange: (v: CostReportColumnType) => void;
  reports?: CostReportColumnType[];
  selected: string;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  skipFormatting?: boolean;
};

// TODO: Create a map from ColumnTerm to CostReportColumnType for all columns
const reports: CostReportColumnType[] = [
  CostReportColumnType.ESTIMATE_REPORT,
  CostReportColumnType.TARGET_REPORT,
  CostReportColumnType.RUNNINGTOTAL_REPORT,
];
const keys: TermKey[] = [TermKey.ESTIMATE, TermKey.TARGET, TermKey.RUNNING_TOTAL];

const TypeSelect: FC<TypeSelectProps> = ({
  keys: keysOuter,
  selected,
  skipFormatting,
  reports: reportsOuter,
  onChange,
}) => {
  const t = useContext(ProjectTermStore);

  const entries = (keysOuter || keys).map((k, i) =>
    getEntry((reportsOuter || reports)[i], skipFormatting ? k : t.titleCase(k as TermKey))
  );

  return (
    <JoinSelect
      cySelect="select-milestoneTypeSelect"
      entries={entries}
      hidePrint={false}
      onChange={(v: string | null) => onChange(v as CostReportColumnType)}
      value={selected}
    />
  );
};

export default TypeSelect;
