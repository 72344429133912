import {
  FILTER_DELIVERY_METHOD,
  FILTER_DESIGN_PHASE,
  FILTER_PROJECT_COMPANIES,
  FILTER_PROJECT_COSTS,
  FILTER_PROJECT_LEAD,
  FILTER_PROJECT_LOCATION,
  FILTER_PROJECT_ORGANIZATION,
  FILTER_PROJECT_SIZE,
  FILTER_PROJECT_STATUS,
  FILTER_PROJECT_TYPE,
} from '../../../constants';
import { Org, OrgNode } from '../../../generated/graphql';
import {
  FilterCollapseState,
  PROJECT_FILTER_TYPES,
  ProjectFilterManager,
  ProjectFilterType,
  getCurrentFilterSummaryString,
  getSelectedForFilterType,
} from '../../ProjectsList/ProjectsListUtils';

export const getFilterInsightsSummaryStrings = (
  filterManager: ProjectFilterManager,
  includeLabels: boolean,
  orgs: Org[]
) => {
  const isCollapsed = (length: number, maxValues: number) => length > maxValues;

  // removing FILTER_PROJECT_ORGANIZATION
  const insightsFilterTypes = PROJECT_FILTER_TYPES.filter(
    (filterType) => filterType !== FILTER_PROJECT_ORGANIZATION
  );

  // Initialize all filter types as not collapsed
  const filterCollapseState: FilterCollapseState = Object.fromEntries(
    insightsFilterTypes.map((filterType) => {
      const {
        types,
        locations,
        companies,
        statuses,
        projectLeadIDs,
        deliveryMethods,
        designPhases,
        estimateCostRange,
        gsfRange,
      } = filterManager.filterState;

      switch (filterType) {
        case FILTER_PROJECT_TYPE:
          return [filterType, isCollapsed(types.length, 2)];
        case FILTER_PROJECT_LOCATION:
          return [filterType, isCollapsed(locations.length, 2)];
        case FILTER_PROJECT_COMPANIES:
          return [filterType, isCollapsed(companies.length, 2)];
        case FILTER_PROJECT_STATUS:
          return [filterType, isCollapsed(statuses.length, 2)];
        case FILTER_PROJECT_LEAD:
          return [filterType, isCollapsed(projectLeadIDs.length, 2)];
        case FILTER_DELIVERY_METHOD:
          return [filterType, isCollapsed(deliveryMethods.length, 1)];
        case FILTER_DESIGN_PHASE:
          return [filterType, isCollapsed(designPhases.length, 1)];
        case FILTER_PROJECT_COSTS:
          return [filterType, estimateCostRange.min !== null || estimateCostRange.max !== null];
        case FILTER_PROJECT_SIZE:
          return [filterType, gsfRange.min !== null || gsfRange.max !== null];
        default:
          return [filterType, false];
      }
    })
  ) as FilterCollapseState;

  const filterSummary: Record<ProjectFilterType | string, string> = Object.fromEntries(
    insightsFilterTypes.map((filterType) => [
      filterType,
      getCurrentFilterSummaryString(filterType, filterManager, filterCollapseState, includeLabels),
    ])
  );

  // Add the orgs to the summary text. Each org has its own entry
  let newFilterSummary: Record<string, string> = {};
  let orgNodesDetails: Record<string, string[]> = {};
  const MAX_ORGS_NODES = 3;
  if (orgs) {
    // Currently selected Nodes for filtering
    const [selected] = getSelectedForFilterType(filterManager, FILTER_PROJECT_ORGANIZATION);
    // Org nodes ordered by Hierarchy
    const orgNodesByLevels = organizeNodesByOrgLevels(orgs);
    // Get a list of selectable org nodes and its parents
    const selectableOrgNodes = buildOrgNodeHierarchy(
      orgs,
      filterManager.filterOptions?.organizationNodes.map((n) => n.id) || []
    );
    // Create the labels for the Chips
    const orgGroupedLabels = createGroupedOrgsLabels(
      MAX_ORGS_NODES,
      selectableOrgNodes.nodeParentMap,
      orgNodesByLevels,
      selected
    );

    orgNodesDetails = orgGroupedLabels.groupedLabelNodeIDs;

    newFilterSummary = { ...orgGroupedLabels.groupedLabelsSummary, ...filterSummary };
  }

  return { newFilterSummary, orgNodesDetails };
};

type OrgRecord = Record<
  string, // orgID
  {
    nodeID: string;
    nodeName: string;
    parents: { parentID: string; parentName: string }[];
  }[]
>;

type NodeParentMap = Record<string, string[]>; // Maps nodeID to an array of parent node IDs

export function buildOrgNodeHierarchy(
  orgs: Org[],
  options: string[]
): { orgRecord: OrgRecord; nodeParentMap: NodeParentMap } {
  const orgRecord: OrgRecord = {};
  const nodeParentMap: NodeParentMap = {};

  const getParentNodes = (orgNodes: Record<string, OrgNode>, node: OrgNode) => {
    const parents = [];
    const parentIDs = [];
    let current = node;

    while (current.parentID) {
      const parentNode = orgNodes[current.parentID];
      if (!parentNode) break;

      parents.push({ parentID: parentNode.id, parentName: parentNode.name });
      parentIDs.push(parentNode.id);
      current = parentNode;
    }

    nodeParentMap[node.id] = parentIDs;
    return parents;
  };

  orgs.forEach((org) => {
    const orgNodes = Object.fromEntries(org.nodes.map((node) => [node.id, node]));
    const relevantNodes = org.nodes.filter((node) => options.includes(node.id));

    orgRecord[org.id] = relevantNodes.map((node) => ({
      nodeID: node.id,
      nodeName: node.name,
      parents: getParentNodes(orgNodes, node),
    }));
  });

  return { orgRecord, nodeParentMap };
}

function createGroupedOrgsLabels(
  maxOrgNodes: number,
  selectableNodeParentMap: NodeParentMap,
  orgNodesByLevels: OrgHierarchyMap,
  selected: string[]
) {
  // Value of each org id to at each level to a string representation of all nodes used in the filter
  const groupedLabelsSummary: Record<string, string> = {};
  // Value of each org id at each level to all its org node IDs at that level
  const groupedLabelNodeIDs: Record<string, string[]> = {};
  // Flat list of all the selectable nodes and its parents
  const selectableNodesAndItsParents: string[] = [];
  Object.entries(selectableNodeParentMap).forEach(([nodeID, parentIDs]) => {
    selectableNodesAndItsParents.push(nodeID);
    parentIDs.forEach((parentID) => {
      selectableNodesAndItsParents.push(parentID);
    });
  });

  // Process each organization
  Object.entries(orgNodesByLevels).forEach(([orgId, orgData]) => {
    const { levels } = orgData;

    // Process each hierarchy level starting from top level
    levels.forEach((levelName, levelIndex) => {
      const nodesInLevel = orgData.hierarchyMap[levelIndex] || [];

      // Find selected nodes at this level
      const selectedNodes = nodesInLevel.filter(
        (node) => selected.includes(node.id) && selectableNodesAndItsParents.includes(node.id)
      );

      // Create label if we have selected nodes
      if (selectedNodes.length > 0) {
        let displayNodeNames = selectedNodes.map((node) => node.name);
        if (displayNodeNames.length > maxOrgNodes) {
          const remainingCount = displayNodeNames.length - maxOrgNodes;
          displayNodeNames = [...displayNodeNames.slice(0, maxOrgNodes), `+${remainingCount} more`];
        }
        const displayNodeIDs = selectedNodes.map((node) => node.id);
        const key = `Organization_${orgId}_${levelName}`;
        groupedLabelsSummary[key] = `${levelName}: ${displayNodeNames.join(', ')}`;
        groupedLabelNodeIDs[key] = displayNodeIDs;
      }
    });
  });

  return { groupedLabelsSummary, groupedLabelNodeIDs };
}

export type OrgHierarchyMap = Record<
  string,
  {
    orgID: string;
    orgName: string;
    levels: string[];
    hierarchyMap: Record<number, OrgNode[]>;
  }
>;

export function organizeNodesByOrgLevels(orgs: Org[]): OrgHierarchyMap {
  const orgHierarchyMap: OrgHierarchyMap = {};

  orgs.forEach((org) => {
    // Initialize org entry
    orgHierarchyMap[org.id] = {
      orgID: org.id,
      orgName: org.name,
      levels: org.levels,
      hierarchyMap: {},
    };

    // Create a map for quick parent lookups
    const parentMap = new Map(org.nodes.map((node) => [node.id, node]));

    // Process each node
    org.nodes.forEach((node) => {
      // Calculate hierarchy level
      let level = 0;
      let currentId = node.parentID;

      // Traverse up the parent chain
      while (currentId) {
        const parent = parentMap.get(currentId);
        if (!parent) break;
        level += 1;
        currentId = parent.parentID;
      }

      // Initialize array for this level if it doesn't exist
      if (!orgHierarchyMap[org.id].hierarchyMap[level]) {
        orgHierarchyMap[org.id].hierarchyMap[level] = [];
      }

      // Add node to its level
      orgHierarchyMap[org.id].hierarchyMap[level].push(node);
    });
  });

  return orgHierarchyMap;
}
