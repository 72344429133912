import { FC, useContext, useEffect, useRef, useState } from 'react';

import { Typography } from '@material-ui/core';

import {
  CreateEstimateFromProjectCompsEventTypes,
  createEstimateFromProjectCompsEvent,
} from '../../../analytics/analyticsEventProperties';
import { NEW_COLUMN_FROM_AVERAGES } from '../../../constants';
import { MetricsInput, PcMarkup } from '../../../generated/graphql';
import useSendAnalytics from '../../../hooks/useSendAnalytics';
import { PROJECT_COMP_MENU } from '../../../tagConstants';
import theme, { withStyles } from '../../../theme/komodo-mui-theme';
import { Thumbnail } from '../../dragon-scales';
import NormalTooltip from '../../NormalTooltip/NormalTooltip';
import { Portal } from '../../Portal/Portal/Portal';
import { Button, IconMenuButton, MenuEntry } from '../../scales';
import {
  ProjectCompIntersectionElements,
  ProjectCompPortals,
} from '../constants/elementIdentifiers';
import { EscalationTargetLocation, ProjectCompSectionVariant } from '../constants/projectCompTypes';
import ObserverContext from '../context/observer';
import ProjectCompsCostTableHeader from '../ProjectCompsCostTable/ProjectCompsCostTableHeader';
import ProjectCompSectionCosts from '../ProjectCompSection/ProjectCompSectionCosts';
import ProjectCompSectionEscalation from '../ProjectCompSection/ProjectCompSectionEscalation';
import ProjectCompSectionMetrics from '../ProjectCompSection/ProjectCompSectionMetrics';
import ProjectCompSectionProjectInformation from '../ProjectCompSection/ProjectCompSectionProjectInformation';
import ProjectCompsInputsProjectName from '../ProjectCompsInputs/ProjectCompsInputsProjectName';
import { AverageCompInputUpdateFunctions } from '../ProjectCompsSetInputStore/ProjectCompsSetInputUpdaters';
import { extractAverageCompValues } from '../ProjectCompsSetUtils';
import styles from '../ProjectCompsStyles';

import CreateEstimateDialogFlow from './CreateEstimateDialogs/CreateEstimateDialogFlow';

type AverageCompProps = {
  averageComp: AverageComp;
  averageCompInputUpdateFunctions: AverageCompInputUpdateFunctions;
  categories: ProjectCompsSet['categories'];
  classes: Classes<typeof styles>;
  costTableColumnInputs: CostTableColumnInputs;
  hasMarkups: boolean;
  isLocationAutoEscalationApplied: boolean;
  location: EscalationTargetLocation;
  markups: PcMarkup[];
  options: MenuEntry[];
  parentProject: ProjectProps | undefined;
  setTargetLocation: (location: EscalationTargetLocation) => void;
  selectedUnits: Unit[];
  unit: Unit;
};

const AverageComp: FC<AverageCompProps> = ({
  averageComp,
  averageCompInputUpdateFunctions,
  categories,
  classes,
  costTableColumnInputs,
  hasMarkups,
  isLocationAutoEscalationApplied,
  location,
  markups,
  options,
  parentProject,
  setTargetLocation,
  selectedUnits,
  unit,
}) => {
  const [createEstimateOpen, setCreateEstimateOpen] = useState(false);
  const AVERAGE_COMP_INDEX = -1;
  const { color, description, isHidden, metrics, name, projectCompsCostTable, thumbnailAssetID } =
    extractAverageCompValues(averageComp);
  // Use metrics (and not quantity magnitude) for displaying pinned metric
  const pinnedUnit = metrics?.find((m: MetricsInput) => m.unitID === unit.id);
  const { quantityMagnitude } = pinnedUnit || {};

  const {
    averageCompLineInputUpdateFunctions,
    resetAverageCompInputName,
    setAverageCompInputDescription,
    setAverageCompInputName,
    setAverageCompMetricsInput,
  } = averageCompInputUpdateFunctions;

  const sendAnalytics = useSendAnalytics();
  const {
    projectCompIntersectionObserver,
    showStickyCostsFooter,
    showStickyCostsHeader,
    showStickyProjectName,
  } = useContext(ObserverContext);

  const projectNameRef = useRef(null);
  useEffect(() => {
    if (projectCompIntersectionObserver && projectNameRef.current) {
      projectCompIntersectionObserver.observe(projectNameRef.current);
    }
    return () => {
      if (projectCompIntersectionObserver && projectNameRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO CT-566: Fix this pls :)
        projectCompIntersectionObserver.unobserve(projectNameRef.current);
      }
    };
  }, [projectCompIntersectionObserver, projectNameRef]);
  // Thumbnail image
  const imageThumbnail = thumbnailAssetID ? (
    <Thumbnail size={200} thumbnail={thumbnailAssetID} />
  ) : (
    <div style={{ height: 200 }} /> // placeholder div
  );

  const iconMenuButton = (
    <IconMenuButton
      aria-label="more button"
      data-cy={PROJECT_COMP_MENU}
      entries={options}
      type="secondary"
    />
  );

  if (isHidden)
    return (
      <div className={classes.hiddenAverage} data-cy={`project-comp-${name}`}>
        <div className={classes.averageColorField} style={{ backgroundColor: color || '' }} />
        <div className={`${classes.sectionPadding} ${classes.inlineFlexAverage}`}>
          {iconMenuButton}
        </div>
        {showStickyProjectName && (
          <Portal
            attributes={{
              order: String(AVERAGE_COMP_INDEX + 1),
            }}
            portalTargetID={ProjectCompPortals.AVG_COMP_HEADER}
          >
            <div className={classes.sectionPadding}>
              <div className={classes.stickyProjectNameContainer}>{iconMenuButton}</div>
            </div>
          </Portal>
        )}
        {showStickyCostsFooter && (
          <Portal
            attributes={{
              order: String(AVERAGE_COMP_INDEX + 1),
            }}
            portalTargetID={ProjectCompPortals.AVG_COMP_FOOTER_COSTS}
          >
            <div className={classes.hiddenAverageStickyFooter} />
          </Portal>
        )}
      </div>
    );

  return (
    <div className={`${classes.comp} box-content border-r`} data-cy={`project-comp-${name}`}>
      <div className={classes.averageColorField} style={{ backgroundColor: color || '' }} />
      <div className={classes.sectionPadding}>
        <div className={`${classes.inlineFlex} ${classes.inlineFlexAverage}`}>
          {imageThumbnail}
          <div className={classes.scrollBody}>{iconMenuButton}</div>
        </div>
      </div>
      <div
        ref={projectNameRef}
        className={`${ProjectCompIntersectionElements.PROJECT_NAME} ${classes.sectionPadding}`}
      >
        <ProjectCompsInputsProjectName
          name={name ?? ''}
          nameDefault={NEW_COLUMN_FROM_AVERAGES}
          resetName={resetAverageCompInputName}
          setName={setAverageCompInputName}
        />
      </div>
      {showStickyProjectName && (
        <Portal
          attributes={{
            order: String(AVERAGE_COMP_INDEX + 1),
            className: classes.compHeaderPortal,
          }}
          portalTargetID={ProjectCompPortals.AVG_COMP_HEADER}
        >
          <div className="h-2" style={{ backgroundColor: color }} />
          <div className={classes.sectionPadding}>
            <div className="flex items-center justify-between">
              <div className="py-3">
                <NormalTooltip title={name}>
                  <Typography
                    style={{
                      ...theme.typography.header,
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {name}
                  </Typography>
                </NormalTooltip>
              </div>
              {iconMenuButton}
            </div>

            {showStickyCostsHeader && (
              <div className={classes.stickyCostsAverageHeaderContainer}>
                <ProjectCompsCostTableHeader
                  costTableColumnInputs={costTableColumnInputs}
                  isAverage
                  selectedUnits={selectedUnits}
                />
              </div>
            )}
          </div>
        </Portal>
      )}
      <Portal
        attributes={{
          order: String(AVERAGE_COMP_INDEX),
          className: 'flex grow p-2',
        }}
        portalTargetID={ProjectCompPortals.AVG_COMP_FOOTER_CONTROLS}
      >
        <div className="w-[312px]">
          <Button
            data-cy="create-estimate-button"
            isFullWidth
            label="Create Estimate"
            onClick={() => {
              sendAnalytics(
                createEstimateFromProjectCompsEvent(
                  CreateEstimateFromProjectCompsEventTypes.BUTTON_CTA
                )
              );
              setCreateEstimateOpen(true);
            }}
            type="primary"
          />
          {createEstimateOpen && (
            <CreateEstimateDialogFlow
              accentColor={color}
              metrics={averageComp.input.metrics}
              onClose={() => {
                setCreateEstimateOpen(false);
              }}
              parentProjectID={parentProject?.id}
              totalCost={
                averageComp.projectCompsCostTable.summaryLines.totalLine?.pinnedUnitValues
                  .totalValue
              }
              unit={unit}
            />
          )}
        </div>
      </Portal>
      <ProjectCompSectionProjectInformation
        averageCompName={name}
        isAverage
        isLocationAutoEscalationApplied={isLocationAutoEscalationApplied}
        location={location.location}
        projectFields={{
          description: description ?? undefined,
          quantityMagnitude: quantityMagnitude ?? undefined,
        }}
        setDescription={setAverageCompInputDescription}
        setLocation={setTargetLocation}
      />
      <ProjectCompSectionMetrics
        metrics={metrics}
        selectedUnits={selectedUnits}
        setMetrics={setAverageCompMetricsInput}
        unit={unit}
        variant={ProjectCompSectionVariant.AVERAGE_COMP}
      />
      <ProjectCompSectionEscalation variant={ProjectCompSectionVariant.AVERAGE_COMP} />
      <ProjectCompSectionCosts
        categories={categories}
        costTableColumnInputs={costTableColumnInputs}
        hasMarkups={hasMarkups}
        index={AVERAGE_COMP_INDEX}
        lineInputUpdateFunctions={averageCompLineInputUpdateFunctions}
        markups={markups}
        projectCompsCostTable={projectCompsCostTable}
        selectedUnits={selectedUnits}
        unit={unit}
        variant={ProjectCompSectionVariant.AVERAGE_COMP}
      />
    </div>
  );
};

export default withStyles(styles)(AverageComp);
