import { SpinnerIcon } from '../../Nav/icons';

type Props = {
  projectName: string;
};

export default function FooterRightLoadingProject(props: Props) {
  return (
    <div className="flex items-center gap-2">
      <SpinnerIcon />
      <p className="text-type-inactive">Creating Project {props.projectName}</p>
    </div>
  );
}
