import { MutationHookOptions, useMutation } from '@apollo/client';

import {
  CreateProgramMutation,
  CreateProgramMutationVariables,
  ProgramCreationInput,
} from '../../../../generated/graphql';
import { createProgramMutation } from '../../mutations';

export const useCreateProgramMutation = (
  options: MutationHookOptions<CreateProgramMutation, CreateProgramMutationVariables> = {}
) => {
  const [createProgram] = useMutation<CreateProgramMutation, CreateProgramMutationVariables>(
    createProgramMutation,
    options
  );
  return (input: ProgramCreationInput) => {
    return createProgram({
      variables: {
        input,
      },
    });
  };
};
