import { Org, OrgNode } from '../../../../generated/graphql';
import { TreeEntry, makeTree } from '../../../../utilities/utilities';
import { Chip, SelectEntry } from '../../../scales';

export function convertOrgsToSelectEntries(orgs: Org[] | undefined): SelectEntry[] {
  const selectEntries: SelectEntry[] = [];
  if (!orgs) {
    return selectEntries;
  }
  orgs.forEach((org) => {
    const allNodes = makeTree<OrgNode>(org.nodes);
    const processEntries = (nodes: TreeEntry<OrgNode>[]) => {
      nodes.forEach((node) => {
        const levelIndex = node.depth;
        const entry = {
          id: node.id,
          label: node.name,
          parentID: node.parentID,
          endAdornment: levelIndex >= 0 && <Chip text={org.levels[levelIndex]} />,
        };
        selectEntries.push(entry);
        // Check if the node has entries and recursively process them
        if (node.entries) {
          processEntries(node.entries);
        }
      });
    };
    // Call the recursive function with the initial allNodes
    processEntries(allNodes);
  });

  return selectEntries;
}

export function convertOrgToSelectEntries(org: Org, selected: UUID[]): SelectEntry[] {
  const selectEntries: SelectEntry[] = [];
  const allNodes = makeTree<OrgNode>(org.nodes);
  const processEntries = (nodes: TreeEntry<OrgNode>[]) => {
    nodes.forEach((node) => {
      const levelIndex = node.depth;
      const entry = {
        id: node.id,
        disabled: selected.includes(node.id),
        label: node.name,
        parentID: node.parentID,
        endAdornment: levelIndex >= 0 && <Chip text={org.levels[levelIndex]} />,
      };
      selectEntries.push(entry);
      // Check if the node has entries and recursively process them
      if (node.entries) {
        processEntries(node.entries);
      }
    });
  };
  // Call the recursive function with the initial allNodes
  processEntries(allNodes);

  return selectEntries;
}

export function filterPublished(orgs: Org[] | undefined) {
  return orgs?.filter(({ isDraft }) => !isDraft);
}

export function getIDs(nodes: OrgNode[] | undefined) {
  return nodes?.map(({ id }) => id);
}
