import { QueryHookOptions } from '@apollo/client';

import { ProgramsQuery, ProgramsQueryVariables } from '../../../../../generated/graphql';
import { useQuery } from '../../../../../hooks/useMountAwareQuery';
import { useTypesIdsToNames } from '../../../../../hooks/useTypesIdsToNames';

import { programs } from './queries';

export const useProgramsQuery = (
  variablesOuter: ProgramsQueryVariables,
  options?: QueryHookOptions<ProgramsQuery, ProgramsQueryVariables>
) => {
  const variables = useTypesIdsToNames(variablesOuter, 'filters.types');

  return useQuery<ProgramsQuery, ProgramsQueryVariables>(programs, {
    ...options,
    variables,
  });
};
