import { commonCompanyUserFields, gql } from '../../../api/graphqlFragments';

export const acceptTermsOfServiceMutation = gql`
  mutation acceptTermsOfService {
    acceptTermsOfService
  }
`;

export const acceptedTermsOfServiceQuery = gql`
  query acceptedTermsOfService {
    acceptedTermsOfService {
      accepted
      dateEffective
    }
  }
`;

const listCompanyFields = gql`
  fragment listCompanyFields on Company {
    id
    name
    type
    domain
    deactivated
  }
`;

export const listCompanyUserFieldsQuery = gql`
  fragment listCompanyUserFieldsQuery on CompanyUser {
    company {
      ...listCompanyFields
    }
    isIndependent
    matchedCompany {
      ...listCompanyFields
    }
    role {
      id
      name
    }
    user {
      email
      id
      name
      phone
      picture
      didSetup
      pictureAsset {
        thumbnailURL
      }
      jobTitle
      status
      activeAt
    }
  }
  ${listCompanyFields}
`;

export const commonCompanyFields = gql`
  fragment commonCompanyFields on Company {
    id
    name
    type
    domain
    assetID
  }
`;

export const createCompanyMutation = gql`
  mutation createCompany($name: String!, $type: String!, $assetID: UUID) {
    createCompany(name: $name, type: $type, assetID: $assetID) {
      ...commonCompanyFields
    }
  }
  ${commonCompanyFields}
`;

export const createCompanyUserMutation = gql`
  mutation createCompanyUser(
    $companyID: UUID!
    $isRejected: Boolean!
    $reasonForRejection: String
  ) {
    createCompanyUser(
      companyID: $companyID
      isRejected: $isRejected
      reasonForRejection: $reasonForRejection
    ) {
      ...commonCompanyUserFields
    }
  }
  ${commonCompanyUserFields}
`;

export const companyUserQuery = gql`
  query companyUser {
    companyUser {
      ...commonCompanyUserFields
    }
  }
  ${commonCompanyUserFields}
`;

export const companyQuery = gql`
  query company($companyID: UUID!) {
    company(companyID: $companyID) {
      ...commonCompanyFields
    }
  }
  ${commonCompanyFields}
`;

export const sendEmailVerificationMutation = gql`
  mutation sendEmailVerification($url: String!) {
    sendEmailVerification(url: $url)
  }
`;

export const verifyEmailMutation = gql`
  mutation verifyEmail($emailVerify: String!) {
    verifyEmail(emailVerify: $emailVerify)
  }
`;

export const userDidSetupMutation = gql`
  mutation userDidSetup {
    userDidSetup {
      id
      didSetup
    }
  }
`;

export const hubspotTokenQuery = gql`
  query hubspotToken {
    hubspotToken
  }
`;
