import { Org } from '../../../../generated/graphql';
import { Chip, Tooltip } from '../../../scales';

import OrgDeleteBtn from './OrgDeleteBtn';
import OrgNameInput from './OrgNameInput';
import OrgPublishBtn from './OrgPublishBtn';

type Props = {
  organization: Org;
  organizations: Org[];
};

export default function OrganizationDetailsPanel({ organization, organizations }: Props) {
  const draftTooltipContent = (
    <>
      Draft organizations are only visible
      <br />
      to company admins.
    </>
  );

  return (
    <div className="flex w-full flex-col items-start justify-between gap-1 px-4 py-3">
      <div className="flex w-full flex-col md:flex-row">
        <div className="flex items-center gap-2">
          <div className="h-10">
            <OrgNameInput
              organizationID={organization.id}
              organizationName={organization.name}
              organizations={organizations}
            />
          </div>
          {organization.isDraft && (
            <Tooltip content={draftTooltipContent} placement="right">
              <div>
                <Chip text="Draft" />
              </div>
            </Tooltip>
          )}
        </div>
        <div className="flex gap-2 py-2 pl-2 md:ml-auto">
          <OrgDeleteBtn organization={organization} />
          {organization.isDraft && <OrgPublishBtn organization={organization} />}
        </div>
      </div>
    </div>
  );
}
