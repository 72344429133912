import { createContext, useContext } from 'react';

const CurrentCollaboratorContext = createContext<Collaborator | undefined>(undefined);
export const CurrentCollaboratorContextProvider = CurrentCollaboratorContext.Provider;

export function useCurrentCollaborator(): Collaborator | undefined {
  const currentCollaborator = useContext(CurrentCollaboratorContext);

  return currentCollaborator;
}
