import { DateGroup } from '../../../generated/graphql';

export enum ItemActivityFeedReportType {
  MY_ACTIVITY = 'My Items Activity',
  ALL_ACTIVITY = 'All Activity',
}

export const getItemDescription = (
  number: string,
  name: string,
  deprecated?: boolean,
  isDraft?: boolean
) => {
  if (isDraft) return `${name}${deprecated ? ' (Deleted Item)' : ''}`;
  return `${number}: ${name}${deprecated ? ' (Deleted Item)' : ''}`;
};

const getDayWithSuffix = (date: Date) => {
  const day = date.getDate();
  let suffix = 'th';
  if (day % 10 === 1 && day !== 11) {
    suffix = 'st';
  } else if (day % 10 === 2 && day !== 12) {
    suffix = 'nd';
  } else if (day % 10 === 3 && day !== 13) {
    suffix = 'rd';
  }
  return `${day}${suffix}`;
};

const getWeekNumber = (date: Date) => {
  const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
  const pastDaysOfYear = (date.getTime() - firstDayOfYear.getTime()) / 86400000;
  return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
};

const isFromLastWeek = (date: Date) => {
  const currentWeekNumber = getWeekNumber(new Date());
  const dateWeekNumber = getWeekNumber(date);
  return dateWeekNumber === currentWeekNumber - 1;
};

export const getFormattedDateText = (date: Date, currentDateGroup?: DateGroup | null) => {
  const dayWithSuffix = getDayWithSuffix(date);
  const monthName = date.toLocaleDateString('default', { month: 'long' });
  const currentDateGroupText = currentDateGroup
    ? `${currentDateGroup.split(/(?=[A-Z])/).join(' ')}`
    : '';

  const isLastWeekDateGroup = currentDateGroup === DateGroup.LASTWEEK;
  const isLastWeek = isFromLastWeek(date);

  const formattedText =
    isLastWeekDateGroup && !isLastWeek
      ? `${monthName} ${dayWithSuffix}`
      : `${currentDateGroupText} ${monthName} ${dayWithSuffix}`;

  return formattedText.trim();
};
