import { HasDisplayCosts } from '../conditionals/HasDisplayCosts';

import Scenarios from './Scenarios';

export function ScenariosRoute() {
  return (
    <HasDisplayCosts>
      <Scenarios />
    </HasDisplayCosts>
  );
}
