import { FC } from 'react';

import { ButtonBase } from '@material-ui/core';
import { Done } from '@material-ui/icons';

import { withStyles } from '../../../theme/komodo-mui-theme';

import styles from './PageNumberStyles';

export enum PageNumberState {
  PREVIOUS = 'PREVIOUS',
  CURRENT = 'CURRENT',
  NEXT = 'NEXT',
}

const { PREVIOUS, CURRENT, NEXT } = PageNumberState;

type PageNumberProps = {
  classes: Classes<typeof styles>;
  onClick?: () => void;
  number: number;
  status: PageNumberState;
};

const PageNumber: FC<PageNumberProps> = ({ classes, number, onClick, status }) => {
  let statusClass;

  switch (status) {
    case PREVIOUS:
      statusClass = classes.previous;
      break;
    case CURRENT:
      statusClass = classes.current;
      break;
    case NEXT:
      statusClass = classes.next;
      break;
    default:
      break;
  }

  const content = status !== PREVIOUS ? number : <Done classes={{ root: classes.checkbox }} />;

  return (
    <ButtonBase className={`${classes.text} ${statusClass}`} onClick={onClick}>
      {content}
    </ButtonBase>
  );
};

export default withStyles(styles)(PageNumber);
