import { FC, ReactNode, RefObject, useContext, useRef, useState } from 'react';
import { OverlayTriggerState, useOverlayTriggerState } from 'react-stately';

import { Add, Check, Edit } from '@material-ui/icons';
import RemoveCircleOutline from '@material-ui/icons/RemoveCircleOutline';

import { ProjectCompSectionType, TermKey } from '../../../api/gqlEnums';
import { NULL_ID } from '../../../constants';
import { PcMarkup } from '../../../generated/graphql';
import { PROJECT_COMP_SUBTOTAL } from '../../../tagConstants';
import { withStyles } from '../../../theme/komodo-mui-theme';
import { categoryLabel } from '../../../utilities/string';
import { Portal } from '../../Portal/Portal/Portal';
import { ProjectTermStore } from '../../ProjectDisplaySettings/TerminologyProvider';
import { BabyButton, IconButton, Popover, TextInput } from '../../scales';
import { ProjectCompPortals } from '../constants/elementIdentifiers';
import ObserverContext from '../context/observer';
import { useProjectCompsSetInputUpdateFunctions } from '../ProjectCompsSetInputStore/ProjectCompsSetInputUpdaters';
import {
  toggleProjectCompSectionCollapsed,
  useIsProjectCompSectionCollapsed,
} from '../ProjectCompsSetUtils';
import styles from '../ProjectCompsStyles';
import SidebarLabel from '../SidebarLabel';

type ProjectCompsCostLabelsProps = {
  categories: ProjectCompsSet['categories'];
  classes: Classes<typeof styles>;
  hasMarkups: boolean;
  index?: number;
  markups: PcMarkup[];
};

const ProjectCompsCostLabels: FC<ProjectCompsCostLabelsProps> = ({
  categories,
  classes,
  hasMarkups,
  index = 0,
  markups,
}) => {
  // HOOKS
  const t = useContext(ProjectTermStore);
  const { showStickyCostsFooter } = useContext(ObserverContext);
  const markupsSubsection = ProjectCompSectionType.SUBSECTION_MARKUPS;
  const isMarkupsCollapsed = useIsProjectCompSectionCollapsed(markupsSubsection);

  const { addMarkupLine, renameMarkupLine, toggleExcludedCategoryContent, toggleExcludedMarkup } =
    useProjectCompsSetInputUpdateFunctions();

  const categoryLabels = categories.map((category) => {
    const categorization = category.categorization ?? { id: NULL_ID, name: '' };
    const categoryText = categoryLabel(category.name, category.number, categorization);
    return (
      <CostLabel
        key={categoryText}
        data-cy="categoryLine"
        endAdornment={<RemoveButton onRemove={() => toggleExcludedCategoryContent(category)} />}
        text={categoryText}
      />
    );
  });

  const markupLabels: ReactNode[] = [];
  if (hasMarkups) {
    markupLabels.push(
      <CostLabel key="subtotal" data-cy="subtotal" isHeader text="Subtotal" />,
      <CostLabel
        key="markups"
        data-cy="markups"
        endAdornment={
          <AddCostLineButton existingMarkups={markups} onDone={(name) => addMarkupLine(name)} />
        }
        isCollapsed={isMarkupsCollapsed}
        isCollapsible={!!markups.length}
        isHeader
        text={t.titleCase(TermKey.MARKUP)}
        toggleCollapsed={() => toggleProjectCompSectionCollapsed(markupsSubsection)}
      />
    );

    if (!isMarkupsCollapsed) {
      markupLabels.push(
        ...markups.map((markup) => (
          <CostLabel
            key={markup.name}
            data-cy="markupLine"
            endAdornment={
              <div className="flex">
                {markup.isCustom && (
                  <EditCostLineButton
                    currentName={markup.name}
                    existingMarkups={markups}
                    onDone={(name) => renameMarkupLine(name, markup.name)}
                  />
                )}
                <RemoveButton onRemove={() => toggleExcludedMarkup(markup.name)} />
              </div>
            }
            isIndented
            text={markup.name}
          />
        ))
      );
    }
  }

  return (
    <>
      <div className={`${classes.fields} ${classes.labelFields} `} data-cy={PROJECT_COMP_SUBTOTAL}>
        <CostLabel text="" />
        {categoryLabels}
        {markupLabels}
        <CostLabel data-cy="total" isHeader text="Total" />
      </div>

      {showStickyCostsFooter && (
        <Portal
          attributes={{
            order: String(index + 1),
          }}
          portalTargetID={ProjectCompPortals.LABEL_FOOTER_COSTS}
        >
          <div className={classes.sectionPadding}>
            <CostLabel isHeader text="Total" />
          </div>
        </Portal>
      )}
    </>
  );
};

const CostLabel = withStyles(styles)(function CostLabel(props: {
  'data-cy'?: string;
  classes: Classes<typeof styles>;
  endAdornment?: ReactNode;
  isHeader?: boolean;
  isCollapsed?: boolean; // wether the collapse icon is expanded or collapsed
  isCollapsible?: boolean; // label should include collapse icon
  isIndented?: boolean; // label is indented to indicate it is part of a collapsible section
  text: string;
  toggleCollapsed?: () => void;
}) {
  return (
    <SidebarLabel
      className={[
        props.classes.field,
        props.classes.sidebarLabelField,
        props.isHeader ? props.classes.hardBorder : '',
        props.isIndented ? props.classes.markupField : '',
      ].join(' ')}
      classNameTypography={`${props.classes.labelText} ${props.isHeader ? props.classes.bold : ''}`}
      dataCy={props['data-cy'] ? `label-${props['data-cy']}` : undefined}
      endAdornment={props.endAdornment}
      isCollapsed={props.isCollapsed}
      isCollapsible={props.isCollapsible}
      showEndAdornment={props.isHeader}
      toggleIsCollapsed={props.toggleCollapsed}
    >
      {props.text}
    </SidebarLabel>
  );
});

function AddCostLineButton(props: {
  existingMarkups: { name: string }[];
  onDone: (name: string) => void;
}) {
  const ref = useRef(null);
  const state = useOverlayTriggerState({});

  return (
    <>
      <BabyButton
        ref={ref}
        aria-label="add markup line"
        data-cy="add-markup-line-button"
        icon={<Add />}
        onClick={() => state.toggle()}
      />
      {state.isOpen && (
        <MarkupLinePopover
          existingMarkups={props.existingMarkups}
          onDone={props.onDone}
          state={state}
          triggerRef={ref}
        />
      )}
    </>
  );
}

function EditCostLineButton(props: {
  currentName: string;
  existingMarkups: { name: string }[];
  onDone: (name: string) => void;
}) {
  const ref = useRef(null);
  const state = useOverlayTriggerState({});

  return (
    <>
      <BabyButton
        ref={ref}
        aria-label="edit markup name"
        data-cy="edit-markup-name-button"
        icon={<Edit />}
        onClick={() => state.toggle()}
      />
      {state.isOpen && (
        <MarkupLinePopover
          defaultValue={props.currentName}
          existingMarkups={props.existingMarkups}
          onDone={props.onDone}
          state={state}
          triggerRef={ref}
        />
      )}
    </>
  );
}

function MarkupLinePopover(props: {
  defaultValue?: string;
  existingMarkups: { name: string }[];
  onDone: (name: string) => void;
  state: OverlayTriggerState;
  triggerRef: RefObject<HTMLButtonElement>;
}) {
  const [value, setValue] = useState(props.defaultValue ?? '');
  const errorMessage =
    props.existingMarkups.find((m) => m.name === value) && value !== props.defaultValue
      ? 'A markup with this name already exists.'
      : undefined;

  const handleCreate = () => {
    if (value && !errorMessage) {
      props.onDone(value);
      props.state.close();
    }
  };

  return (
    <Popover
      className={`flex w-75 gap-2 bg-background-primary p-2 shadow ${
        errorMessage ? 'items-center' : 'items-end'
      }`}
      offset={4}
      placement="right"
      state={props.state}
      triggerRef={props.triggerRef}
    >
      <TextInput
        data-cy="markup-name-text-input"
        errorMessage={errorMessage}
        label="Markup Name"
        onChange={setValue}
        onKeyDown={(e) => {
          if (e.key === 'Escape') props.state.close();
          if (e.key === 'Enter') handleCreate();
        }}
        placeholder="Enter a name..."
        value={value}
      />
      <IconButton
        aria-label="create markup"
        icon={<Check />}
        onClick={handleCreate}
        type="secondary"
      />
    </Popover>
  );
}

function RemoveButton(props: { onRemove: () => void }) {
  return (
    <BabyButton
      aria-label="remove line"
      data-cy="exclude-line-button"
      icon={<RemoveCircleOutline />}
      onClick={props.onRemove}
    />
  );
}

export default withStyles(styles)(ProjectCompsCostLabels);
