import { FC } from 'react';

import { ProjectCompSectionType } from '../../../api/gqlEnums';
import { withStyles } from '../../../theme/komodo-mui-theme';
import { useIsProjectCompSectionCollapsed } from '../ProjectCompsSetUtils';
import styles from '../ProjectCompsStyles';
import ProjectCompsChartsData from '../ProjectCompsVisualization/ProjectCompsChart/ProjectCompsChartData';

type ProjectCompSectionGraphsProps = {
  classes: Classes<typeof styles>;
  projectCompsSet: ProjectCompsSet;
};

const ProjectCompSectionGraphs: FC<ProjectCompSectionGraphsProps> = ({
  classes,
  projectCompsSet,
}) => {
  const graphsSection = ProjectCompSectionType.SECTION_GRAPHS;
  const isGraphsCollapsed = useIsProjectCompSectionCollapsed(graphsSection);

  return (
    <div className={`${classes.visualizationSection} ${classes.sticky}`}>
      {!isGraphsCollapsed && <ProjectCompsChartsData projectCompsSet={projectCompsSet} />}
    </div>
  );
};

export default withStyles(styles)(ProjectCompSectionGraphs);
