import { createStyles } from '@material-ui/core';

export const MIDDLE_TRUNC_PADDING = 16;

export default () =>
  createStyles({
    textContainer: {
      display: 'flex',
    },
    trunc: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
    truncEnd: {
      textOverflow: 'ellipsis',
    },
    truncBeginning: {
      textOverflow: 'clip',
      direction: 'rtl',
      textAlign: 'left',
    },
  });
