import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../../theme/komodo-mui-theme';

export default (theme: KomodoTheme) =>
  createStyles({
    title: {
      fontFamily: theme.fontFamily,
      color: theme.palette.budget,
      paddingTop: 22,
      display: 'block',
    },
    subText: {
      fontFamily: theme.fontFamily,
      paddingBottom: 6,
    },
    headerText: {
      fontFamily: theme.fontFamily,
    },
  });
