import { useMemo } from 'react';

import { CostRangeInput, ForecastingExploreItemsFilters } from '../../../generated/graphql';
import { useSearchItemsCategoryLabels } from '../../HomeTab/Search/items/hooks/useSearchItemsCategoryLabels';
import AcceptedIcon from '../../Icons/Accepted';
import ExpandableFiltersSection from '../common/ExpandableFiltersSection';

import CostRangeFilters from './CostRangeFilters';
import FilterGroup from './FilterGroup';
import { ListFilterOption } from './FilterGroupUtils';

type Props = {
  filterOptions?: {
    categoryNumbers: ListFilterOption[];
    milestoneNames: ListFilterOption[];
    projectLocations: ListFilterOption[];
    projectNames: ListFilterOption[];
    projectTypes: ListFilterOption[];
    statuses: ListFilterOption[];
  };
  filterValues: ForecastingExploreItemsFilters;
  isExpanded: boolean;
  onChangeFilters: (
    filterName: keyof ForecastingExploreItemsFilters,
    filterValue: string[] | CostRangeInput
  ) => void;
  onClickSectionName: () => void;
};

const ItemsFilters = (props: Props) => {
  const categoryLabel = useSearchItemsCategoryLabels();
  const categoryFilterOptions = useMemo(() => {
    if (!props.filterOptions?.categoryNumbers) return [];
    return props.filterOptions?.categoryNumbers.map((option) => ({
      ...option,
      label: categoryLabel(option.id),
    }));
  }, [categoryLabel, props.filterOptions?.categoryNumbers]);

  return (
    <ExpandableFiltersSection
      icon={<AcceptedIcon />}
      isExpanded={props.isExpanded}
      setIsExpanded={props.onClickSectionName}
      title="Item Filters"
    >
      <FilterGroup
        data-cy="itemCategory-FilterGroup"
        entries={categoryFilterOptions}
        isAllSelectable
        isSearchable
        onChange={(value) => props.onChangeFilters('categoryNumbers', value)}
        searchPlaceholder="Search categories"
        selectedEntries={props.filterValues.categoryNumbers}
        title="Category"
      />
      <FilterGroup
        data-cy="itemStatus-FilterGroup"
        entries={props.filterOptions?.statuses ?? []}
        isAllSelectable
        isSearchable
        onChange={(value) => props.onChangeFilters('statuses', value)}
        searchPlaceholder="Search statuses"
        selectedEntries={props.filterValues.statuses}
        title="Status"
      />
      <FilterGroup
        data-cy="itemMilestoneName-FilterGroup"
        entries={props.filterOptions?.milestoneNames ?? []}
        isAllSelectable
        isSearchable
        onChange={(value) => props.onChangeFilters('milestoneNames', value)}
        searchPlaceholder="Search milestone names"
        selectedEntries={props.filterValues.milestoneNames}
        title="Milestone"
      />
      <CostRangeFilters
        data-cy="itemFilter-cost-impact"
        onChange={(value: CostRangeInput) => props.onChangeFilters('costImpact', value)}
        title="Cost Impact"
        value={props.filterValues.costImpact}
      />
    </ExpandableFiltersSection>
  );
};

export default ItemsFilters;
