// Tag constants are used to inject hooks into the id param of HTML objects which
// allows tests (in e.g. Cypress) to easily select the areas on the page with semantic
// significance to application usage without being affected by style or layout.

/*
 DATA-CY
 CLASSES
 IDS
 PREFIXES
 FUNCTIONS
*/

// DATA-CY
export const GRID_ADD_ROW_BUTTON = 'AddRemoveRowButtons-add';
export const GRID_DELETE_ROW_BUTTON = 'AddRemoveRowButtons-delete';
export const COST_REPORT_EXPORT = 'cost-report-export';

// CLASSES
// many components or groups of components of the same type can exist at a time
export const MILESTONES_PAGE_SMALL_ROWS = 'Milestone-Page-Small-Rows';

// IDs
// 1 component exists at a time, or is unique within a group already selected by a class
export const CELL_REFERENCE_SELECTION = 'cell-reference-selection';
export const COST_SUMMARY_ESTIMATE = 'cost-summary-estimate';

export const BUTTON_ESTIMATE_TABLE_ADD_LINE = 'button-estimate-table-add-line';
export const BUTTON_MARKUP_TABLE_ADD_LINE = 'button-markup-table-add-line';

export const MILESTONE_REPORT_CELL = 'CostReportListCell';
export const MILESTONE_REPORT_COLLAPSE = 'CostReportListCollapse';
export const MILESTONE_REPORT_ROW = 'CostReportListRow';
export const MILESTONE_REPORT_TREE = 'CostReportListTree';

export const MILESTONE_OPTIONS = 'milestone-options';
export const MILESTONE_OPTIONS_SET_ACTIVE_MILESTONE =
  'milestone-options-set-active-milestone-button';
export const MILESTONE_OPTIONS_DELETE_MILESTONE = 'milestone-options-delete-milestone';
export const MILESTONE_EXPORT_OPTIONS = 'milestone-export-options';
export const MILESTONE_EXPORT_OPTIONS_ACTIVE_ESTIMATE = 'milestone-export-options-active-estimate';
export const MILESTONE_EXPORT_OPTIONS_BUDGET = 'milestone-export-options-budget';

export const PAGE_TITLE_PROJECT_SETTINGS = 'page-title-project-settings';

export const TEXT_FIELD_MILESTONE_NAME = 'active-milestone-name-text-input';
export const START_REPORT_BUTTON = 'start-report-button';
export const PROJECT_COMP_COLLAPSE_BUTTON = 'project-comp-collapse-button';
export const PROJECT_COMP_COST_INPUT = 'project-comp-cost-input';
export const PROJECT_COMP_COST_INPUT_DESCRIPTION_INDICATOR =
  'project-comp-cost-input-description-indicator';
export const PROJECT_COMP_CUSTOM_CATZ = 'project-comp-custom-catz';
export const PROJECT_COMP_ERROR_ICON_COST = 'project-comp-error-icon-cost';
export const PROJECT_COMP_ERROR_ICON_INPUT = 'project-comp-error-icon-input';
export const PROJECT_COMP_ERROR_ICON_SIDEBAR = 'project-comp-error-icon-sidebar';
export const PROJECT_COMP_ESCALATION = 'project-comp-escalation';
export const PROJECT_COMP_ESCALATION_BUTTON = 'project-comp-escalation-button';
export const PROJECT_COMP_ESCALATION_DROPDOWN_BUTTON = 'project-comp-escalation-dropdown-button';
export const PROJECT_COMP_FIELDS = 'project-comp-fields';
export const PROJECT_COMP_INCLUDE_IN_AVG_SWITCH = 'project-comp-switch-include-in-avg';
export const PROJECT_COMP_MARKUPS_PERCENT_FIELD = 'project-comp-markups-percent-field';
export const PROJECT_COMP_MARKUPS_QUANTITY_TOTAL_FIELD =
  'project-comp-markups-quantity-total-field';
export const PROJECT_COMP_MARKUPS_TOTAL_FIELD = 'project-comp-markups-total-field';
export const PROJECT_COMP_MENU = 'project-comp-menu-button';
export const PROJECT_COMP_MODIFIED_DOT = 'project-comp-modified-dot';
export const PROJECT_COMP_DIALOG_COST_DESCRIPTION = 'project-comp-dialog-cost-description';
export const PROJECT_COMP_DIALOG_COST_DESCRIPTION_ERROR = 'project-comp-dialog-error';
export const PROJECT_COMP_PERCENT_FIELD = 'project-comp-percent-field';
export const PROJECT_COMP_QUANTITY_TOTAL_FIELD = 'project-comp-quantity-total-field';
export const PROJECT_COMP_SAVE_PANEL = 'project-comp-save-panel';
export const PROJECT_COMP_SIDEBAR = 'project-comp-sidebar';
export const PROJECT_COMP_SUBTOTAL = 'project-comp-subtotals';
export const PROJECT_COMP_SUBTOTAL_PERCENT_FIELD = 'project-comp-subtotal-percent-field';
export const PROJECT_COMP_SUBTOTAL_QUANTITY_TOTAL_FIELD =
  'project-comp-subtotal-quantity-total-field';
export const PROJECT_COMP_SUBTOTAL_TOTAL_FIELD = 'project-comp-subtotal-total-field';
export const PROJECT_COMP_TOTAL_FIELD = 'project-comp-total-field';
export const QUANTITY_INPUT_GSF = 'unit-GSF-quantityInput';
export const FORECASTING_REPORTS_ICON_MENU = 'forecasting-reports-icon-menu';
export const FORECASTING_REPORTS_REPORT_NAME = 'forecasting-reports-report-name';
export const FORECASTING_REPORTS_AVG_METRIC_QUANTITY = 'forecasting-reports-avg-metric-quantity';
export const FORECASTING_REPORTS_METRIC_UNIT_TYPE = 'forecasting-reports-metric-unit-type';
export const FORECASTING_REPORTS_METRIC_QUANTITY = 'forecasting-reports-metric-quantity';
export const FORECASTING_REPORTS_PROJECT_COUNT = 'forecasting-reports-project-count';
export const FORECASTING_REPORTS_ATTACHED_PROJECT = 'forecasting-reports-attached-project';
export const FORECASTING_REPORTS_SAVE_TOAST_LINK = 'forecasting-reports-save-toast-link';
export const FORECASTING_REPORTS_CREATE_REPORT = 'forecasting-reports-create-report-button';

export const HOME_ITEM_NAME = 'home-item-name';
export const HOME_ITEM_SIDEBAR = 'home-item-sidebar-button';
export const HOME_PROJECT_NAME = 'home-project-name';
export const HOME_PROJECT_LOCATION = 'home-project-location';
export const HOME_PROJECT_TYPE = 'home-project-type';
export const HOME_PROJECT_STATUS = 'home-project-status';

export const ITEMSIDEBAR_TITLE = 'itemSidebar-title';
export const GLOBAL_PROJECT_NAME = 'global-project-name';
export const GLOBAL_PROJECT_LOCATION = 'global-project-location';
export const GLOBAL_PROJECT_TYPE = 'global-project-type';
export const GLOBAL_RECENT_SEARCH = 'global-recent-search';
export const GLOBAL_SEARCH_BUTTON = 'global-search-button';
