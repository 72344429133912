import { gql, organizationFields } from '../../../api/graphqlFragments';

export const organizationsQuery = gql`
  query organizations($companyID: UUID!) {
    organizations(companyID: $companyID) {
      ...organizationFields
    }
  }
  ${organizationFields}
`;

export const organizationQuery = gql`
  query organization($organizationID: UUID!) {
    organization(organizationID: $organizationID) {
      ...organizationFields
    }
  }
  ${organizationFields}
`;

export const getProjectsUsingOrgNodeQuery = gql`
  query getProjectsUsingOrgNode($orgNodeID: UUID!) {
    getProjectsUsingOrgNode(orgNodeID: $orgNodeID) {
      name
      projectID
      nodeID
    }
  }
`;

export const getProjectsUsingOrgQuery = gql`
  query getProjectsUsingOrg($organizationID: UUID!) {
    getProjectsUsingOrg(organizationID: $organizationID) {
      name
      projectID
      nodeID
    }
  }
`;

export const createOrgMutation = gql`
  mutation CreateOrg($input: CreateOrgInput!) {
    createOrg(input: $input) {
      organization {
        ...organizationFields
      }
    }
  }
  ${organizationFields}
`;

export const createOrgNodeMutation = gql`
  mutation CreateOrgNode($input: CreateOrgNodeInput!) {
    createOrgNode(input: $input) {
      organization {
        ...organizationFields
      }
    }
  }
  ${organizationFields}
`;

export const deleteOrgMutation = gql`
  mutation DeleteOrg($input: DeleteOrgInput!) {
    deleteOrg(input: $input) {
      organization {
        id
      }
    }
  }
`;

export const deleteOrgNodeMutation = gql`
  mutation DeleteOrgNode($input: DeleteOrgNodeInput!) {
    deleteOrgNode(input: $input) {
      organization {
        ...organizationFields
      }
    }
  }
  ${organizationFields}
`;

export const setOrgLevelMutation = gql`
  mutation setOrgLevel($input: SetOrgLevelInput!) {
    setOrgLevel(input: $input) {
      ...organizationFields
    }
  }
  ${organizationFields}
`;

export const setOrgNameMutation = gql`
  mutation setOrgName($input: SetOrgNameInput!) {
    setOrgName(input: $input) {
      ...organizationFields
    }
  }
  ${organizationFields}
`;

export const addOrgLevelMutation = gql`
  mutation addOrgLevel($input: AddOrgLevelInput!) {
    addOrgLevel(input: $input) {
      ...organizationFields
    }
  }
  ${organizationFields}
`;

export const deleteOrgLevelMutation = gql`
  mutation deleteOrgLevel($input: DeleteOrgLevelInput!) {
    deleteOrgLevel(input: $input) {
      ...organizationFields
    }
  }
  ${organizationFields}
`;

export const setOrgNodeNameMutation = gql`
  mutation SetOrgNodeName($input: SetOrgNodeNameInput!) {
    setOrgNodeName(input: $input) {
      organization {
        ...organizationFields
      }
    }
  }
  ${organizationFields}
`;

export const setOrgPublishedMutation = gql`
  mutation SetOrgPublished($input: SetOrgPublishedInput!) {
    setOrgPublished(input: $input) {
      organization {
        ...organizationFields
      }
    }
  }
  ${organizationFields}
`;

export const setProjectOrgsMutation = gql`
  mutation SetProjectOrgs($input: SetProjectOrgsInput!) {
    setProjectOrgs(input: $input) {
      removedNodeIDs
    }
  }
`;

export const setBulkProjectOrgsMutation = gql`
  mutation SetBulkProjectOrgs($input: SetBulkProjectOrgsInput!) {
    setBulkProjectOrgs(input: $input) {
      removedNodeIDs
    }
  }
`;
