import SvgIcon from '@material-ui/core/SvgIcon';

import { IconProps } from '.';

export default (props: IconProps) => (
  <SvgIcon
    {...props}
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.1806 0.923462H7.3472C6.3933 0.923462 5.5517 1.40246 5.0411 2.12956L2.1611 2.82166C0.649702 3.18526 -0.284798 4.71096 0.0783019 6.22346L2.1671 14.9137C2.3434 15.6464 2.7943 16.2676 3.437 16.661C3.8857 16.9351 4.3908 17.0766 4.9041 17.0766C5.1251 17.0766 5.3484 17.0501 5.5689 16.997L8.0103 16.4101C8.0209 16.4075 8.0284 16.3997 8.0389 16.3968H17.1807C18.7357 16.3968 20.0001 15.1325 20.0001 13.5774V3.74396C20 2.18896 18.7357 0.923462 17.1806 0.923462ZM4.4709 14.9711C4.2797 14.8539 4.1454 14.6694 4.0929 14.4517L2.0041 5.76086C1.8958 5.30996 2.1743 4.85516 2.6241 4.74686L4.5267 4.28966V13.5774C4.5267 14.1299 4.6923 14.6419 4.9681 15.0774C4.7954 15.0898 4.6222 15.0644 4.4709 14.9711ZM18.0195 13.5774C18.0195 14.0405 17.6437 14.4162 17.1807 14.4162H7.3472C6.8841 14.4162 6.5073 14.0404 6.5073 13.5774V3.74396C6.5073 3.28086 6.8842 2.90406 7.3472 2.90406H17.1807C17.6438 2.90406 18.0195 3.28096 18.0195 3.74396V13.5774Z"
      fill="black"
    />
  </SvgIcon>
);
