import { useMutation } from '@apollo/client';

import {
  forecastingReportHasUnsavedChangesVar,
  projectCompsSetInputVar,
} from '../../../../api/apollo/reactiveVars';
import {
  UpdateProjectComparisonReportMutation,
  UpdateProjectComparisonReportMutationVariables,
} from '../../../../generated/graphql';

import { updateProjectComparisonReport } from './queries';

export const useSaveProjectComparisonReportMutation = (
  onSuccess?: (report: ProjectComparisonReport) => void
) => {
  const [updateProjectComparisonReportFunc] = useMutation<
    UpdateProjectComparisonReportMutation,
    UpdateProjectComparisonReportMutationVariables
  >(updateProjectComparisonReport);

  const mutation = (reportID: UUID, attachedProjectID: UUID) => {
    updateProjectComparisonReportFunc({
      variables: {
        reportID,
        attachedProjectID: attachedProjectID || null,
        projectCompsSetInput: projectCompsSetInputVar(),
      },
    }).then(({ data }) => {
      if (data?.updateProjectComparisonReport) {
        if (forecastingReportHasUnsavedChangesVar()) forecastingReportHasUnsavedChangesVar(false);
        if (onSuccess) onSuccess(data?.updateProjectComparisonReport);
      }
    });
  };
  return [mutation];
};
