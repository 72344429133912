import useAssetBlobUrlQuery from '../../assets/hooks/useAssetBlobUrlQuery';

type Props = {
  assetID: UUID;
};

export default function TeamImage(props: Props) {
  const { url } = useAssetBlobUrlQuery(props.assetID);
  return <img alt="logo" className="w-1/5 object-contain opacity-50 grayscale" src={url} />;
}
