import { FORECASTING_REPORTS_AVG_METRIC_QUANTITY } from '../../../../tagConstants';
import { withStyles } from '../../../../theme/komodo-mui-theme';
import { formatCost } from '../../../../utilities/currency';
import { EMPTY_COST } from '../../../../utilities/string';

import styles from './AverageCostCellStyles';

export default withStyles(styles)(
  ({ classes, report }: { classes: Classes<typeof styles>; report: ForecastingReport }) => (
    <div>
      <div className={classes.titleText}>
        Average $/{report.metadata.pinnedUnit.abbreviationSingular}
      </div>
      <div
        className={classes.valueText}
        data-cy={`${FORECASTING_REPORTS_AVG_METRIC_QUANTITY}-${report.name}`}
      >
        {report.metadata.averageQuantityCost
          ? formatCost(report.metadata.averageQuantityCost, { showCents: false })
          : EMPTY_COST}
      </div>
    </div>
  )
);
