// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
export const getPreviousMilestoneLabel = (milestoneMappings: any) => {
  const count = (milestoneMappings && milestoneMappings.length) || 0;
  if (count === 1) {
    return `All Milestones`;
  }
  if (count > 1) {
    return `All ${count} Milestones`;
  }
  return '';
};

export const getTopNavLinks = (
  itemLink: ItemLink | ItemLike | null | undefined,
  orderedItemIDs: UUID[],
  itemsList: ItemsListItem[]
): NavigationLinks => {
  const index = itemLink ? orderedItemIDs.indexOf(itemLink.id) : -1;

  let nextLink: ItemLink | undefined;
  if (index < orderedItemIDs.length - 1) {
    const nextItemID = orderedItemIDs[index + 1];
    const nextItem = itemsList.find((item) => item.id === nextItemID);
    if (nextItem) nextLink = nextItem as ItemLink;
  }

  let previousLink: ItemLink | undefined;
  if (index > 0) {
    const previousItemID = orderedItemIDs[index - 1];
    const previousItem = itemsList.find((item) => item.id === previousItemID);
    if (previousItem) previousLink = previousItem as ItemLink;
  }

  return {
    next: nextLink,
    previous: previousLink,
  };
};
