import { PermissionResource } from '../../generated/graphql';
import { hasFeatureOption } from '../../hooks/useHasFeature';
import { hasModuleEntitlement } from '../../hooks/useModuleEntitlementsQuery';
import { SharedRoute } from '../../routes/routes';

export const isAccessibleRoute = (
  featureFlags: string[],
  moduleEntitlementFlags: string[],
  canView: (resource: PermissionResource) => boolean,
  route: SharedRoute
): boolean => {
  const { featureFlag, moduleEntitlementFlag, permissionResource, isEveryCheckRequired } = route;
  if (!featureFlag && !permissionResource && !moduleEntitlementFlag) {
    return true;
  }

  const hasFeature = featureFlag && hasFeatureOption(featureFlags, featureFlag);
  const hasEntitlement =
    !moduleEntitlementFlag || hasModuleEntitlement(moduleEntitlementFlags, moduleEntitlementFlag);
  const hasPermission = permissionResource && canView(permissionResource);

  if (isEveryCheckRequired) {
    return Boolean(moduleEntitlementFlag && hasFeature && hasEntitlement && hasPermission);
  }

  return Boolean(hasFeature || (hasEntitlement && hasPermission));
};
