import { useParams } from 'react-router-dom';
import { isUUID } from 'validator';

import useCreateItemFromAlternateMutation from './hooks/useCreateItemFromAlternateMutation';
import useCreateItemFromIdeaMutation from './hooks/useCreateItemFromIdeaMutation';
import useIdeaQuery from './hooks/useIdeaQuery';
import Idea from './Idea';

export default function IdeaRoute() {
  const { projectId, ideaID } = useParams();
  if (!projectId || !isUUID(projectId)) {
    throw new Error('Failed to get projectId param');
  } else if (!ideaID || !isUUID(ideaID)) {
    throw new Error('Failed to get ideaID param');
  }

  const idea = useIdeaQuery(projectId, ideaID).data?.idea;
  const [createItemFromAlternate] = useCreateItemFromAlternateMutation(ideaID);
  const [createItemFromIdea] = useCreateItemFromIdeaMutation(ideaID);

  if (!idea) return null;
  return (
    <Idea
      idea={idea}
      onCreateItem={(alternateID?: UUID) =>
        alternateID ? createItemFromAlternate(alternateID) : createItemFromIdea()
      }
      projectID={projectId}
    />
  );
}
