import { ReactNode } from 'react';

export default function UploadInfo(props: { icon?: ReactNode; label: string }) {
  return (
    <div className="flex justify-center gap-2" data-cy="uploader-info">
      {props.icon}
      <div className="type-body1">{props.label}</div>
    </div>
  );
}
