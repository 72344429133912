import { useMutation } from '@apollo/client';

import { createProjectAnalytics } from '../../../../analytics/analyticsEventProperties';
import {
  CreateProjectMutation,
  CreateProjectMutationVariables,
} from '../../../../generated/graphql';
import useSendAnalytics from '../../../../hooks/useSendAnalytics';

import { createProjectMutation } from './queries';

export default function useCreateProject() {
  const [createProjectFunc] = useMutation<CreateProjectMutation, CreateProjectMutationVariables>(
    createProjectMutation
  );

  const sendAnalytics = useSendAnalytics();

  return async (project: CreateProjectMutationVariables) => {
    try {
      const result = await createProjectFunc({ variables: project });

      if (result.data?.createProject) {
        const { id } = result.data.createProject;
        sendAnalytics(
          createProjectAnalytics(
            id,
            project.name,
            project.currency || 'USD',
            project.milestoneDesignPhaseID,
            project.projectDeliveryTypeID || ''
          )
        );

        return result.data.createProject;
      }

      throw new Error('Failed to create project');
    } catch (error) {
      throw new Error('Failed to create project');
    }
  };
}
