import { FC } from 'react';

import { Checkbox } from '@material-ui/core';

import InputsChips from '../../Inputs/InputsChips/InputsChips';
import JoinMultiSelect from '../JoinMultiSelect/JoinMultiSelect';
import { getEntry } from '../JoinSelect/JoinSelect';

export const EVENTS_DEFAULT = 'No Events';

type SelectEventProps = {
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  disabled?: boolean;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  displaySelectAll?: boolean;
  events: TimelineActivity[];
  labelFn?: (o: string) => string;
  maxChips?: number;
  onChange: (evt: UUID[]) => void;
  selected?: UUID[];
};

const SelectEvent: FC<SelectEventProps> = ({
  disabled = false,
  displaySelectAll = false,
  events = [],
  labelFn = (o) => o,
  maxChips = 2,
  onChange,
  selected: selectedOuter = [],
}) => {
  const selected = selectedOuter ?? [];
  const entries = (events ?? []).map((event) => {
    const checked = selected.includes(event.id) || false;
    return getEntry(
      event.id,
      event.name,
      false,
      <Checkbox checked={checked} style={{ width: 24, height: 24 }} />,
      undefined,
      undefined,
      checked
    );
  });

  return (
    <JoinMultiSelect
      cySelect="select-eventsSelect"
      disabled={disabled}
      displaySelectAll={displaySelectAll}
      entries={entries}
      menuProps={{
        getContentAnchorEl: null,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        id: 'InputsMultiSelectOption',
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
      }}
      onChange={(v) => onChange(v || [])}
      onClose={() => {}}
      onOpen={() => {}}
      renderValue={(chosen) => (
        <div>
          <InputsChips
            chosen={chosen}
            defaultChip={EVENTS_DEFAULT}
            labelFn={labelFn}
            max={maxChips}
            showTitle={false}
          />
        </div>
      )}
      value={selected}
    />
  );
};

export default SelectEvent;
