import { createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
export default (theme: any) =>
  createStyles({
    clear: {
      alignSelf: 'center',
      paddingLeft: theme.spacing.unit,
    },
    filter: {
      display: 'inline-flex',
    },
    filterSummary: {
      paddingTop: theme.spacing.unit,
    },
  });
