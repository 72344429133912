import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';

export const CHIP_MARGIN = 2;

export default (theme: KomodoTheme) =>
  createStyles({
    chip: {
      background: theme.palette.backgroundGrey,
      cursor: 'pointer',
      height: 26,
      margin: CHIP_MARGIN,
      marginRight: 0,
      borderRadius: theme.border.radius,
      border: theme.border.line,
      '&:focus': {
        background: theme.palette.backgroundGrey,
      },
      '@media print': {
        display: 'none',
      },
    },
    chipAll: {
      background: theme.palette.backgroundWhite,
      border: 'none',
    },
    chipLabel: {
      color: theme.palette.joinPrimary,
      flex: 1,
      padding: '0px 4px',
      '@media print': {
        display: 'none',
      },
    },
    inline: {
      display: 'inline-flex',
    },
    label: {
      ...theme.typography.body1,
      color: theme.palette.disabledGrey,
    },
    leftCount: {
      background: 'none',
      border: 'none',
      paddingTop: 1,
    },
    primaryLabel: {
      ...theme.typography.body1,
      color: theme.palette.joinPrimary,
    },
  });
