import theme from '../../../theme/komodo-mui-theme';
import { formatCommas, formatCost } from '../../../utilities/currency';
import { percentFormatterSimple } from '../../../utilities/string';
import { TableHeader, TableRow } from '../../Tables/DataTable/DataTable';

export const PIE_CHART_SMALL = 160;
export const PIE_CHART_LARGE = 240;
export const PIE_CHART_OFFSET = 28;
export const NA = 'N/A';
export const NA_COLOR = theme.palette.chartGrey;

export const MAX_PIE_CHART_ELEMS = 6;

export type PieChartLegendHintData = {
  hintCaption: string;
  isCost: boolean;
  pieChartData: PieChartData[];
};

export const emptyLegendHintData: PieChartLegendHintData = {
  hintCaption: '',
  isCost: false,
  pieChartData: [
    {
      angle: 0,
      color: '',
      percentage: '',
      text: '',
    },
  ],
};

export type PieChartData = {
  angle: number;
  color: string;
  percentage: string;
  text: string;
  otherData?: PieChartData[];
};

export const getPieChartSectionPercentage = (value: number) =>
  value > 0.01 ? percentFormatterSimple.format(value / 100) : '0%';

export const getPieChartSectionValue = (
  data: PieChartData[] | null,
  text: string,
  isCost: boolean
) => {
  if (!data) return '';
  const section = data.find((elem) => elem.text === text);
  if (!section) return '';
  return isCost ? formatCost(section.angle * 100, { rounded: true }) : formatCommas(section.angle);
};

export const formatPieChartHintTableHeaderData = (hintCaption: string): TableHeader => ({
  dataHeaders: [{ label: hintCaption }, { label: `% Total` }],
});

export const formatPieChartHintTableData = (
  row: PieChartData,
  hintData: PieChartData[] | null,
  isCost: boolean
): TableRow => ({
  data: [getPieChartSectionValue(hintData, row && row.text, isCost), row && row.percentage],
  name: row && row.text,
});
