import isEqual from 'lodash/isEqual';

import { UserReportType } from '../../../generated/graphql';
import { removeNullKeys } from '../../../utilities/urlState';

export type ReportManagerPages =
  | 'dashboard'
  | 'breakdowns'
  | 'itemsList'
  | 'msr'
  | 'variance'
  | 'contingencyDraw'
  | 'contingencyAllMilestones'
  | 'projectCostBreakdown';

export const MapReportManagerPagesToValidReports: {
  [key in ReportManagerPages]: UserReportType[];
} = {
  dashboard: [UserReportType.USER_REPORT_DASHBOARD],
  breakdowns: [UserReportType.USER_REPORT_BREAKDOWNS],
  itemsList: [UserReportType.USER_REPORT_ITEMS_LIST, UserReportType.USER_REPORT_ITEMS_LIST_DETAILS],
  msr: [UserReportType.USER_REPORT_MSR],
  variance: [UserReportType.USER_REPORT_VARIANCE],
  contingencyDraw: [UserReportType.USER_REPORT_CONTINGENCY_DRAW],
  contingencyAllMilestones: [UserReportType.USER_REPORT_CONTINGENCY_ALL_MILESTONES],
  projectCostBreakdown: [UserReportType.USER_REPORT_PROJECT_COST_BREAKDOWN],
};

export const areSettingsDirty = (
  currentReport: Omit<UserReport, 'createdBy' | 'updatedAt'> | undefined,
  prevReport: Record<string, unknown>,
  currentMilestone?: UUID
) => {
  if (!currentReport || !currentReport.settings) return false;

  // if the report is following the active milestone, we don't want to use the milestoneID
  const currentReportMilestoneID = currentReport?.isFollowingActiveMilestone
    ? undefined
    : currentReport.milestoneID;

  const milestonesAreEqual =
    currentMilestone === currentReportMilestoneID ||
    currentReport.reportType === UserReportType.USER_REPORT_VARIANCE ||
    currentReport.reportType === UserReportType.USER_REPORT_ITEMS_LIST ||
    currentReport.reportType === UserReportType.USER_REPORT_ITEMS_LIST_DETAILS;

  try {
    // JSON.parse sometimes interprests the viewFitler as `[object, object]`
    // the o in object may or may not be capitolized.  For our purposes
    // this result means there are no view filters so we'll convert it
    // to an empty object for ease of comparison
    const settingsForCompare = JSON.parse(JSON.stringify(prevReport));
    if (
      typeof settingsForCompare.viewFilter === 'string' &&
      settingsForCompare.viewFilter.toLowerCase() === '[object object]'
    )
      settingsForCompare.viewFilter = {};
    const currentReportSettings = JSON.parse(currentReport.settings);
    if (
      typeof currentReportSettings.viewFilter === 'string' &&
      currentReportSettings.viewFilter.toLowerCase() === '[object object]'
    )
      currentReportSettings.viewFilter = {};
    return (
      // we remove null keys to only compare the keys that are present
      !isEqual(removeNullKeys(settingsForCompare), removeNullKeys(currentReportSettings)) ||
      !milestonesAreEqual
    );
  } catch (e) {
    return false;
  }
};

export const isDefaultReport = (reportID: UUID | UserReportType): boolean => {
  switch (reportID) {
    case UserReportType.USER_REPORT_DASHBOARD:
    case UserReportType.USER_REPORT_BREAKDOWNS:
    case UserReportType.USER_REPORT_ITEMS_LIST:
    case UserReportType.USER_REPORT_ITEMS_LIST_DETAILS:
    case UserReportType.USER_REPORT_MSR:
    case `${UserReportType.USER_REPORT_MSR}_ITEMS`:
    case UserReportType.USER_REPORT_ACTIVE_ESTIMATE:
    case UserReportType.USER_REPORT_ACTIVE_BUDGET:
    case UserReportType.USER_REPORT_CONTINGENCY_ALL_MILESTONES:
    case UserReportType.USER_REPORT_PROJECT_COST_BREAKDOWN:
    case UserReportType.USER_REPORT_CONTINGENCY_DRAW:
      return true;
    default:
      return false;
  }
};
