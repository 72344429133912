import { useMutation } from '@apollo/client';

import {
  CreateCompanyUserMutation,
  CreateCompanyUserMutationVariables,
} from '../../../../generated/graphql';
import { createCompanyUserMutation } from '../../hooks/queries';

export const useCreateCompanyUserMutation = () => {
  const [createCompanyUserFunc] = useMutation<
    CreateCompanyUserMutation,
    CreateCompanyUserMutationVariables
  >(createCompanyUserMutation);
  return (
    companyID: UUID,
    isRejected: boolean,
    reasonForRejection: string | null,
    onSuccess?: (value: CreateCompanyUserMutation['createCompanyUser']) => void,
    onFailure?: () => void
  ) => {
    createCompanyUserFunc({
      variables: { companyID, isRejected, reasonForRejection },
    })
      .then((result) => {
        if (!result || !result.data) return;
        const {
          data: { createCompanyUser: value },
        } = result;
        if (onSuccess) onSuccess(value);
      })
      .catch(() => {
        if (onFailure) onFailure();
      });
  };
};
