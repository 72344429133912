import { JoinRoutes } from '../api/gqlEnums';

import CompanyTab from './CompanyTab/CompanyTab';
import { routeToCompanyComponent } from './CompanyTab/CompanyTabRouteUtils';
import ProjectRoute from './ProjectRoute/ProjectRoute';
import { routeToProjectComponent } from './ProjectRoute/routes';

// 🚨🚨🚨 NOTE: New routes should be defined in routes/index.tsx 🚨🚨🚨
export const routeToAppComponent = {
  [JoinRoutes.COMPANY_TAB]: { element: <CompanyTab />, subs: routeToCompanyComponent },
  [JoinRoutes.PROJECT]: { element: <ProjectRoute />, subs: routeToProjectComponent },
};
