import { FC } from 'react';

import { withStyles } from '../../../theme/komodo-mui-theme';

import ItemsStatusHoverSelector, {
  ItemsStatusHoverSelectorProps,
} from './ItemsStatusHoverSelector';
import ItemsStatusHoverSelectorStylesCozy from './ItemsStatusHoverSelectorStylesCozy';

const ItemsStatusHoverSelectorCozy: FC<ItemsStatusHoverSelectorProps> = (props) => (
  <ItemsStatusHoverSelector {...props} />
);

export default withStyles(ItemsStatusHoverSelectorStylesCozy)(ItemsStatusHoverSelectorCozy);
