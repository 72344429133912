import {
  MilestoneCostReportsQuery,
  MilestoneCostReportsQueryVariables,
} from '../generated/graphql';
import { useCostMode } from '../utilities/costMode';

import { columns as defaultColumns } from './constants';
import { milestoneCostReportsQuery } from './queries';
import { useQuery } from './useMountAwareQuery';

type MilestoneCostReportsQueryArgs = {
  columns?: CostReportColumnInput[];
  groupByCategorization: Categorization | null;
  milestoneID: UUID | null;
  projectID: UUID;
  shouldSkip?: boolean;
  viewFilter: ViewFilterInput;
};

export const useMilestoneCostReportsQueryGrouped = (props: MilestoneCostReportsQueryArgs) => {
  const groupByItems: GroupByItem[] = props.groupByCategorization
    ? [
        {
          categorization: {
            id: props.groupByCategorization.id,
            name: props.groupByCategorization.name,
          },
          level: 1,
        },
      ]
    : [];
  return useQuery<MilestoneCostReportsQuery, MilestoneCostReportsQueryVariables>(
    milestoneCostReportsQuery,
    {
      variables: {
        milestones: props.milestoneID ? [{ milestoneID: props.milestoneID }] : [],
        columns: props.columns ? props.columns : defaultColumns,
        groupByItems,
        costMode: useCostMode(),
        projectID: props.projectID,
        viewFilter: props.viewFilter,
      },
      skip: !props.milestoneID || !props.projectID || !!props.shouldSkip,
    }
  );
};
