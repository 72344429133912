import { SourceSystem } from '../../../../generated/graphql';
import { Button, Dialog, DialogContent } from '../../../scales';

import {
  getItemIntegrationObjectTypeBySourceSystem,
  getSourceSystemString,
} from './IntegrationsUtils';

type Props = {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  onClose: () => void;
  name: string;
  sourceSystem: SourceSystem;
};

export default function UnlinkItemIntegrationObjectDialog(props: Props) {
  const integrationObjectType = getItemIntegrationObjectTypeBySourceSystem(props.sourceSystem);
  const sourceSystemString = getSourceSystemString(props.sourceSystem);
  return (
    <Dialog
      footerLeft={<Button label="Close" onClick={() => props.setIsOpen(false)} type="secondary" />}
      footerRight={
        <Button
          label="Unlink"
          onClick={() => {
            props.onClose();
            props.setIsOpen(false);
          }}
          type="primary"
        />
      }
      isOpen={props.isOpen}
      onClose={() => props.setIsOpen(false)}
      title="Are you sure you want to unlink this Change Event?"
    >
      <DialogContent>
        <div>
          You are about to unlink {props.name} from this Item. The link to the{' '}
          {integrationObjectType} will be removed, but the {sourceSystemString}{' '}
          {integrationObjectType} will not be affected.
        </div>
      </DialogContent>
    </Dialog>
  );
}
