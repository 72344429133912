import * as React from 'react';

import { withStyles } from '@material-ui/core/styles';
import Tooltip, { TooltipProps } from '@material-ui/core/Tooltip';

const TOOLTIP_WIDTH = 100;

const VarianceModalUnitsPreviewTooltip: React.ComponentType<TooltipProps> = withStyles(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  (theme: any) => ({
    tooltip: {
      width: TOOLTIP_WIDTH,
      padding: 4,
      background: theme.palette.common.white,
      color: theme.palette.primaryGrey,
      textAlign: 'left',
      textTransform: 'none',
      border: `1px solid ${theme.palette.borderDarkGrey}`,
      borderRadius: 2,
      outline: '1px solid transparent',
      marginTop: -10,
      fontSize: parseInt(theme.typography.chart.fontSize, 10),
    },
  })
)(Tooltip);

export default VarianceModalUnitsPreviewTooltip;
