import { useMemo } from 'react';

import { makeVar, useReactiveVar } from '@apollo/client';

import { getReactiveLocal, setReactiveLocal } from '../api/apollo/reactiveVars';
import { getMarkupModePermissionLabel } from '../components/TeamRoute/RolesRoute/table/permissions';
import { ALLOCATED_MARKUPS, COST_MODE, NO_MARKUPS, SEPARATED_MARKUPS } from '../constants';
import { CostDisplay, MarkupMode, PermissionResource } from '../generated/graphql';

import usePermissions from './permissions/usePermissions';
import { useProjectID } from './routes/params';
import { getProjectIdFromUrl } from './url';

/** @deprecated this is our old LocalStorage key  */
const getOldCostModeLocalStorageKey = (projectID?: UUID) =>
  `${projectID || getProjectIdFromUrl()}-${COST_MODE}`;

export const checkCostModeIncludesMarkups = (costMode: CostMode) =>
  costMode.markupMode !== MarkupMode.NO_MARKUPS;

export const getMarkupModeLabel = (markupMode: MarkupMode, t: TermStore) => {
  switch (markupMode) {
    case MarkupMode.ALLOCATED_MARKUPS:
      return getMarkupModePermissionLabel(PermissionResource.ALLOCATED_MARKUPS_VIEW, t);
    case MarkupMode.SEPARATED_MARKUPS:
      return getMarkupModePermissionLabel(PermissionResource.SEPARATED_MARKUPS_VIEW, t);
    case MarkupMode.NO_MARKUPS:
      return getMarkupModePermissionLabel(PermissionResource.NO_MARKUPS_VIEW, t);
    default:
      return getMarkupModePermissionLabel(PermissionResource.ALLOCATED_MARKUPS_VIEW, t);
  }
};

export const useAvailableCostModes = (projectID?: UUID) => {
  const { canView, loading } = usePermissions({ projectID });
  const availableCostDisplays = useMemo(() => {
    if (loading) return [];
    return canView(PermissionResource.DISPLAY_COSTS)
      ? [CostDisplay.SHOW_COSTS, CostDisplay.HIDE_COSTS]
      : [CostDisplay.HIDE_COSTS];
  }, [canView, loading]);
  const availableMarkupModes = useMemo(() => {
    if (loading) return [];
    const availableMarkupModes = [];
    if (canView(PermissionResource.ALLOCATED_MARKUPS_VIEW))
      availableMarkupModes.push(MarkupMode.ALLOCATED_MARKUPS);
    if (canView(PermissionResource.SEPARATED_MARKUPS_VIEW))
      availableMarkupModes.push(MarkupMode.SEPARATED_MARKUPS);
    if (canView(PermissionResource.NO_MARKUPS_VIEW))
      availableMarkupModes.push(MarkupMode.NO_MARKUPS);
    return availableMarkupModes;
  }, [canView, loading]);
  return {
    availableCostDisplays,
    availableMarkupModes,
    loading,
  };
};

// NEW COST MODE CODE BELOW

const LOCAL_STORAGE_COST_MODES = 'PROJECTS_COST_MODES';
type ProjectsCostModes = Record<UUID, CostMode>;

const fetchLocalStorageCostModes = () =>
  getReactiveLocal<ProjectsCostModes>(LOCAL_STORAGE_COST_MODES, {}, false);
const fetchOldLocalStorageMarkupMode = (projectID: UUID) => {
  const costModeString = localStorage.getItem(getOldCostModeLocalStorageKey(projectID));
  switch (costModeString) {
    case ALLOCATED_MARKUPS:
      return MarkupMode.ALLOCATED_MARKUPS;
    case SEPARATED_MARKUPS:
      return MarkupMode.SEPARATED_MARKUPS;
    case NO_MARKUPS:
      return MarkupMode.NO_MARKUPS;
    default:
      return undefined;
  }
};

const projectsCostModesVar = makeVar<ProjectsCostModes>(fetchLocalStorageCostModes());

export const getCostMode = () =>
  projectsCostModesVar()[getProjectIdFromUrl()] || {
    markupMode: MarkupMode.ALLOCATED_MARKUPS,
    costDisplay: CostDisplay.SHOW_COSTS,
  };

export const useCostMode = (customProjectID?: UUID) => {
  const currentProjectID = useProjectID();
  const projectID = customProjectID || currentProjectID;
  const projectsCostModes = useReactiveVar(projectsCostModesVar);

  let markupMode = MarkupMode.ALLOCATED_MARKUPS;
  let costDisplay = CostDisplay.SHOW_COSTS;

  if (projectID && projectsCostModes[projectID]) {
    markupMode = projectsCostModes[projectID].markupMode;
    costDisplay = projectsCostModes[projectID].costDisplay ?? CostDisplay.SHOW_COSTS;
  } else if (projectID) {
    const previouslyStoredMarkupMode = fetchOldLocalStorageMarkupMode(projectID);
    if (previouslyStoredMarkupMode) markupMode = previouslyStoredMarkupMode;
  }
  // Update CostMode if you don't have access to the current value
  // NOTE: there are a couple of reasons we need to check permissions in order
  // to change the cost mode: a) when acessing a project for the first time, b)
  // when previewing a user/role, and c) your permissions have been changed
  // since the last time you accessed the project. In the future, we should see
  // if there is a better place to handle this logic, so it doesn't need to be
  // baked into all the places we call useCostMode.
  const { availableCostDisplays, availableMarkupModes, loading } = useAvailableCostModes(projectID);
  if (!loading) {
    if (Boolean(availableMarkupModes.length) && !availableMarkupModes.includes(markupMode))
      markupMode = availableMarkupModes[0];
    if (Boolean(availableCostDisplays.length) && !availableCostDisplays.includes(costDisplay))
      costDisplay = availableCostDisplays[0];
  }
  // Update LocalStorage if current value is different that what is stored there (and not in preview mode)
  const inPreviewMode = usePermissions().inPreviewMode;
  if (
    !loading &&
    !inPreviewMode &&
    projectID &&
    (projectsCostModes[projectID]?.markupMode !== markupMode ||
      projectsCostModes[projectID]?.costDisplay !== costDisplay)
  ) {
    const newProjectsCostModes = {
      ...projectsCostModes,
      [projectID]: { costDisplay, markupMode },
    };
    setReactiveLocal(projectsCostModesVar, LOCAL_STORAGE_COST_MODES, newProjectsCostModes, false);
  }

  return useMemo(() => ({ markupMode, costDisplay }), [costDisplay, markupMode]);
};

export const updateCostMode = (projectID: UUID, update: Partial<CostMode>) => {
  const projectsCostModes = projectsCostModesVar();
  const prev = projectsCostModes[projectID] ?? {
    markupMode: MarkupMode.ALLOCATED_MARKUPS,
    costDisplay: CostDisplay.SHOW_COSTS,
  };
  const newProjectsCostModes = {
    ...projectsCostModes,
    [projectID]: { ...prev, ...update },
  };
  setReactiveLocal(projectsCostModesVar, LOCAL_STORAGE_COST_MODES, newProjectsCostModes, false);
};
