import { HasDisplayCosts } from '../conditionals/HasDisplayCosts';

import Dashboard from './Dashboard';

export function DashboardRoute() {
  return (
    <HasDisplayCosts>
      <Dashboard />
    </HasDisplayCosts>
  );
}
