import { FC, useState } from 'react';

import Add from '@material-ui/icons/Add';

import {
  CompanyAdminEventType,
  companyAdminAnalyticsEvent,
} from '../../../../analytics/analyticsEventProperties';
import { CategorizationDialogType } from '../../../../api/gqlEnums';
import useSendAnalytics from '../../../../hooks/useSendAnalytics';
import CategorizationsListDialogs from '../../../Categorizations/CategorizationsListDialogs/CategorizationsListDialogs';
import { Button } from '../../../scales';

type NewCompanyCategorizationProps = {
  companyID?: UUID;
};

const NewCompanyCategorization: FC<NewCompanyCategorizationProps> = ({ companyID }) => {
  const sendAnalytics = useSendAnalytics();
  const [categorizationDialogType, setCategorizationDialogType] =
    useState<CategorizationDialogType>(CategorizationDialogType.NONE);

  return (
    <>
      <Button
        data-cy="create-categorization-button"
        label="Create Categorization"
        onClick={() => {
          setCategorizationDialogType(CategorizationDialogType.NEW);
          sendAnalytics(
            companyAdminAnalyticsEvent(CompanyAdminEventType.STANDARD_CATEGORIZATION_CREATE_CTA, {
              companyID,
            })
          );
        }}
        startIcon={<Add />}
        type="primary"
      />

      {categorizationDialogType !== CategorizationDialogType.NONE && companyID && (
        <CategorizationsListDialogs
          companyID={companyID}
          setType={setCategorizationDialogType}
          type={categorizationDialogType}
        />
      )}
    </>
  );
};

export default NewCompanyCategorization;
