import { TimelineQuery } from '../../generated/graphql';
import { ItemTimeSeries } from '../dragon-scales/TimelineCharts/InsightsItems/types';

export enum InsightsTabId {
  Timeline = 'Timeline',
  Costs = 'Costs',
  Items = 'Items',
  Contingencies = 'Contingencies',
}

export type InsightsProject = InsightsProjects & {
  itemsDueDateSeriesMockData?: ItemTimeSeries[];
  timelineActivitiesMockData?: TimelineQuery['timeline']['activities'];
};
