import { isUUID } from 'validator';

import { CircularProgress } from '@material-ui/core';

import { ImageDimension } from '../../../generated/graphql';
import useThumbnailQuery from '../../assets/hooks/useThumbnailQuery';

type Props = {
  /** Optional children to render on top of the thumbnail. */
  children?: React.ReactNode;
  onLoad?: () => void;
  /**
   * Whether to crop rectangular thumbnails into a square. If false, rectangular
   * thumbnails will have blank space on their long sides to fill the square
   * container. If true, rectangular thumbnails will be cropped to fully fill the
   * square container.
   *
   * @default true
   */
  shouldCrop?: boolean;
  /** Size in pixels of the square thumbnail that is rendered. */
  size: number;
  /** The thumbnail to display. Can be a URL or Asset UUID. */
  thumbnail: string | null | undefined;
};

export default function Thumbnail(props: Props) {
  const { data, loading } = useThumbnailQuery(props.thumbnail, getDimensionFromSize(props.size));

  const style = { width: props.size, height: props.size };
  const className = props.shouldCrop ? 'object-cover' : 'object-contain';

  const isThumbnailAnID = props.thumbnail ? isUUID(props.thumbnail) : false;
  const thumbnailURL = isThumbnailAnID ? data?.asset?.thumbnailURL : props.thumbnail;

  if (thumbnailURL)
    return (
      <div className="relative shrink-0" style={style}>
        <img
          alt="thumb"
          className={className}
          onLoad={props.onLoad}
          src={thumbnailURL}
          style={style}
        />
        {props.children}
      </div>
    );

  // this ensures we call onLoad when image can't be rendered
  if (!isThumbnailAnID) props.onLoad?.();

  return (
    <div className={`shrink-0 ${className}`} data-cy="thumbnail-loading" style={style}>
      {loading && <CircularProgress size={props.size} />}
    </div>
  );
}

Thumbnail.defaultProps = {
  shouldCrop: true,
};

const getDimensionFromSize = (size: number) => {
  if (size <= 50) return ImageDimension._50;
  if (size <= 92) return ImageDimension._92;
  if (size <= 144) return ImageDimension._144;
  return ImageDimension._200;
};
