import { FC } from 'react';

import LinearProgress from '@material-ui/core/LinearProgress';

import { withStyles } from '../../../theme/komodo-mui-theme';

import styles from './PageCounterStyles';
import PageNumber, { PageNumberState } from './PageNumber';

const { PREVIOUS, CURRENT, NEXT } = PageNumberState;

type PageCounterProps = {
  classes: Classes<typeof styles>;
  index: number;
  onOpenDialogs: (() => void)[];
};

const PageCounter: FC<PageCounterProps> = ({ classes, index, onOpenDialogs }) => {
  const total = onOpenDialogs.length;
  const current = index + 1;
  const tmin = 50;
  const coef = (1 / (2 * tmin * tmin)) * total * total + total * (-1 / tmin) + 1;
  const value = current === 1 ? 0 : (current / total) * 100 - 6 * coef;

  return (
    <div className={classes.container}>
      <LinearProgress
        classes={{
          colorPrimary: classes.colorPrimary,
          bar: classes.bar,
        }}
        className={classes.progress}
        value={value}
        variant="determinate"
      />
      <div className={classes.pages}>
        {Array.from(new Array(total)).map((_, i) => {
          const number = i + 1;
          let status = CURRENT;

          if (number < current) {
            status = PREVIOUS;
          } else if (number > current) {
            status = NEXT;
          }
          const key = `page${i}`;
          return (
            <PageNumber key={key} number={number} onClick={onOpenDialogs[i]} status={status} />
          );
        })}
      </div>
    </div>
  );
};

export default withStyles(styles)(PageCounter);
