import { createStyles } from '@material-ui/core';

const ChartsLegendStyles = () =>
  createStyles({
    vertical: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    verticalWrap: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      flexWrap: 'wrap',
    },
    horizontal: {
      display: 'flex',
      padding: 8,
      paddingBottom: 12,
    },
    wrap: {
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'wrap',
      maxHeight: 70,
      padding: '0px 8px 8px 8px',
    },
    columns: {
      display: 'block',
      flexDirection: 'column',
      columns: 2,
      columnGap: 0,
      flexWrap: 'wrap',
    },
  });

export default ChartsLegendStyles;
