import { FC, useEffect, useState } from 'react';

import { LinearProgress } from '@material-ui/core';

import {
  exportProjectCostBreakdownReportAnalytics,
  projectCostBreakdownReportEvent,
  projectCostBreakdownReportEventTypes,
} from '../../analytics/analyticsEventProperties';
import { currentUserReportVar } from '../../api/apollo/reactiveVars';
import { JoinProjectRoutes } from '../../api/gqlEnums';
import JoinAPI from '../../api/joinAPI';
import { PROJECT_COST_BREAKDOWN_REPORT } from '../../constants';
import { EstimateType, MarkupMode, PermissionResource } from '../../generated/graphql';
import useProjectPropsQuery from '../../hooks/useProjectPropsQuery';
import useSendAnalytics from '../../hooks/useSendAnalytics';
import { useCostMode } from '../../utilities/costMode';
import usePermissions from '../../utilities/permissions/usePermissions';
import { generateSharedPath } from '../../utilities/routes/links';
import { getMilestoneIdFromUrl, getProjectIdFromUrl } from '../../utilities/url';
import { usePersistentStates } from '../../utilities/urlState';
import { HasDisplayCosts } from '../conditionals/HasDisplayCosts';
import { useMilestoneQuery } from '../Milestone/hooks';
import { useMilestonesQuery } from '../Milestones/hooks';
import { printFn, printProps, printReport } from '../Print/PrintUtils';

import { useProjectCostBreakdownReportQuery } from './hooks';
import ProjectBreakdownReport from './ProjectBreakdownReport';
import { generateProjectCostBreakdownReportStorageParam } from './utils';

type ProjectCostBreakdownReportDataProps = {
  isPrint?: boolean;
};

const ProjectCostBreakdownReportData: FC<ProjectCostBreakdownReportDataProps> = ({
  isPrint = false,
}) => {
  const projectID = getProjectIdFromUrl();
  const [milestoneID, setMilestoneID] = useState(getMilestoneIdFromUrl());
  const costMode = useCostMode();
  const { canView } = usePermissions();
  const canViewSeparatedMarkups = canView(PermissionResource.SEPARATED_MARKUPS_VIEW);
  const canViewMarkups = canView(PermissionResource.MARKUPS);
  const displayTip =
    canViewMarkups &&
    canViewSeparatedMarkups &&
    costMode.markupMode === MarkupMode.ALLOCATED_MARKUPS;

  const sendAnalytics = useSendAnalytics();

  const report = currentUserReportVar();
  const [currentReport, setCurrentReport] = useState(report?.id);

  const [settings, setSettings] = usePersistentStates<
    ProjectCostBreakdownSettings,
    ProjectCostBreakdownSettings
  >(
    window.location,
    PROJECT_COST_BREAKDOWN_REPORT,
    { estimateType: EstimateType.ACTIVE_ESTIMATE },
    generateProjectCostBreakdownReportStorageParam(milestoneID)
  );

  // if the user switches to a saved report
  // then navigate to the correct milestone
  useEffect(() => {
    if (
      report?.milestoneID &&
      report?.id !== currentReport &&
      milestoneID !== report?.milestoneID
    ) {
      setMilestoneID(report?.milestoneID);
    }
    setCurrentReport(report?.id);
  }, [currentReport, milestoneID, projectID, report]);

  const milestoneData = useMilestoneQuery(milestoneID);

  const { data: milestonesData, loading: milestonesLoading } = useMilestonesQuery(projectID, false);
  const project = useProjectPropsQuery(projectID)?.data?.project;

  const { data: reportData, loading } = useProjectCostBreakdownReportQuery(
    projectID,
    milestoneID,
    settings.estimateType,
    costMode
  );

  const finishedLoading = !loading && !milestonesLoading;

  const milestoneName = milestoneData?.data?.milestone?.name ?? '';
  const milestones = milestonesData?.milestones ?? [];

  const exportReport = () => {
    const fileNameTokens = [project?.name ?? '', milestoneName];
    sendAnalytics(exportProjectCostBreakdownReportAnalytics());
    JoinAPI.exportProjectCostBreakdownReport(
      projectID,
      milestoneID,
      settings.estimateType,
      costMode,
      fileNameTokens
    );
  };

  // Printing
  const [hasOpenedPrintDialog, setHasOpenedPrintDialog] = useState(false);
  useEffect(() => {
    printReport(
      isPrint,
      !finishedLoading,
      hasOpenedPrintDialog,
      setHasOpenedPrintDialog,
      printProjectCostBreakdownReport,
      { projectID, milestoneID, sendAnalytics }
    );
  }, [projectID, milestoneID, isPrint, finishedLoading, hasOpenedPrintDialog, sendAnalytics]);

  return (
    <HasDisplayCosts>
      <div className="h-full bg-background-primary">
        {loading ? <LinearProgress /> : <div className="h-1 w-full" />}
        <ProjectBreakdownReport
          activeMilestoneID={project?.activeMilestone?.id}
          displayTip={displayTip}
          exportReport={exportReport}
          milestoneID={milestoneID}
          milestoneName={milestoneName}
          milestones={milestones}
          print={
            !isPrint
              ? () => printProjectCostBreakdownReport({ projectID, milestoneID, sendAnalytics })
              : undefined
          }
          projectCostBreakdownReport={reportData?.projectCostBreakdownReport ?? undefined}
          projectID={projectID}
          setMilestoneID={setMilestoneID}
          setSettings={setSettings}
          settings={settings}
        />
      </div>
    </HasDisplayCosts>
  );
};

export default ProjectCostBreakdownReportData;

const printProjectCostBreakdownReport: printFn = (pp: printProps) => {
  pp.sendAnalytics(
    projectCostBreakdownReportEvent(projectCostBreakdownReportEventTypes.BREAKDOWN_REPORT_PRINT)
  );
  const route = JoinProjectRoutes.PRINT_PROJECT_COST_BREAKDOWN;
  window.open(
    generateSharedPath(route, {
      projectId: pp.projectID,
      milestoneId: pp.milestoneID,
      search: window.location.search,
    }),
    '_blank'
  );
};
