import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';

const maxWidth = 130;

const styles = (theme: KomodoTheme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'baseline',
      color: 'black',
    },
    hover: {
      cursor: 'pointer',
      textDecoration: 'underline',
      '&:hover': { color: theme.palette.link },
    },
    list: {
      alignItems: 'center',
    },
    navLinkWidthDefault: {
      maxWidth,
    },
    next: {
      justifyContent: 'flex-end',
    },
    previous: {
      justifyContent: 'flex-start',
    },
    item: {
      // this textDecorationSkipLink prop is used so that any underlined
      // parenthasis have an underline.  Without it parenthesis are not
      // underlined.  In that case there is a
      // 1px underline just after the ending parenthesis, which is annoying
      textDecorationSkipInk: 'none',
      overflow: 'hidden !important',
      minWidth: 0,
      flex: '0 1 auto',
      paddingLeft: `${theme.spacing.generic[1]}px`,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    // this style needs to go after item
    // because it overrides some of the item styles
    body2: {
      fontSize: `${theme.typography.body2.fontSize}`,
      overflow: 'visible',
    },
    body1: {
      fontSize: `${theme.typography.body1.fontSize}`,
      overflow: 'visible',
    },
    bodySidebar: {
      fontSize: 16,
      fontWeight: 400,
      paddingTop: 2,
    },
    cost: theme.typography.number,
    privateBadge: {
      paddingLeft: 2,
      color: theme.palette.disabledGrey,
    },
  });

export default styles;
