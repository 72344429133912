import { useCallback, useEffect, useMemo, useState } from 'react';

import { EventProperties, copyItemEventTypes } from '../../../analytics/analyticsEventProperties';
import {
  CopyItemDataQuery,
  CopyItemMutationVariables,
  ItemAttachmentsQuery,
  ItemCopyInput,
  ListProjectCategorizationsQuery,
  PermissionResource,
  Status,
  Visibility,
} from '../../../generated/graphql';
import { useProjectCategorizationsQuery } from '../../../hooks/useProjectCategorizationsQuery';
import useProjectPropsQuery from '../../../hooks/useProjectPropsQuery';
import usePermissions, {
  getItemLinesPermissionResource,
} from '../../../utilities/permissions/usePermissions';
import useItemAttachmentsQuery from '../../assets/hooks/useItemAttachmentsQuery';
import { useItemQuery } from '../../Items/hooks/useItemQuery';

import { useCopyItemDataQuery } from './useCopyItemDataQuery';
import useSendCopyItemAnalytics from './useSendCopyItemAnalytics';

export type ItemCopyState = Omit<ItemCopyInput, 'categories' | 'categorizations' | 'projectID'> & {
  categories: Category[];
  categorizationMappings?: Map<UUID, UUID>;
  itemEstimateCategorizations: Categorization[];
  projectID?: UUID;
  projectName?: string;
  skipCategories?: boolean;
  skipCategorizationMap?: boolean;
};

const getItemCopyState = (
  projectID?: UUID,
  projectName?: string,
  item?: ItemDataQueryItem,
  copyItemData?: CopyItemDataQuery['copyItemData'],
  destinationProjectCategorizations?: ListProjectCategorizationsQuery['projectCategorizations'],
  assetIDs?: UUID[],
  canCopyItemEstimate?: boolean
): ItemCopyState => ({
  activityIDs: [],
  assetIDs: assetIDs ?? [],
  categories: copyItemData?.convertedItemCategories ?? [],
  categorizationMappings: getInitialCategorizationMap(
    copyItemData?.itemEstimateCategorizations ?? [],
    destinationProjectCategorizations ?? []
  ),
  description: item?.description ?? '',
  descriptionStyled: item?.descriptionStyled ?? '',
  dueDate: null,
  itemEstimateCategorizations: copyItemData?.itemEstimateCategorizations ?? [],
  name: item?.name ?? '',
  projectID,
  projectName,
  status: Status.PENDING,
  visibility: Visibility.PRIVATE_DRAFT,
  skipCategories: Boolean(
    !destinationProjectCategorizations || destinationProjectCategorizations.length === 0
  ),
  skipCategorizationMap: Boolean(
    !canCopyItemEstimate ||
      !destinationProjectCategorizations ||
      destinationProjectCategorizations.length === 0 ||
      !copyItemData ||
      copyItemData.itemEstimateCategorizations.length === 0
  ),
});

const getInitialCategorizationMap = (
  sourceCategorizations: Categorization[],
  destinationProjectCategorizations?: ListProjectCategorizationsQuery['projectCategorizations']
) => {
  const categorizationMappings = new Map<UUID, UUID>();
  if (!sourceCategorizations?.length || !destinationProjectCategorizations?.length)
    return categorizationMappings;
  sourceCategorizations.forEach((ic) => {
    const match = destinationProjectCategorizations?.find(
      ({ categorization }) => categorization.name === ic.name
    );
    if (match) {
      categorizationMappings.set(ic.id, match.categorization.id);
    }
  });
  return categorizationMappings;
};

export default (itemID: UUID) => {
  const [state, setState] = useState<ItemCopyState>(getItemCopyState());
  const [projectID, setProjectID] = useState<UUID>();

  const sendAnalytics = useSendCopyItemAnalytics();

  const { data: itemData } = useItemQuery(itemID);
  const { data: copyItemData } = useCopyItemDataQuery(itemID, projectID);
  const { data: projectCategorizationData } = useProjectCategorizationsQuery(projectID ?? '');
  const { data: itemAssets } = useItemAttachmentsQuery(itemID);
  const { data: projectData } = useProjectPropsQuery(projectID);

  // Permissions are accounting for the project you are copying an item from,
  // but not the project you are copying to. We should do more to proactively
  // check what you should be able to do, but you will get an error from the
  // backend if you can't copy the item to the new project.
  const { canView, inTrade } = usePermissions({
    projectID: itemData?.item?.project.id,
    trades: itemData?.item?.categories,
  });
  const hasEstimateLinesAccess = canView(getItemLinesPermissionResource(inTrade));
  const hasEstimateMarkupsAccess = canView(PermissionResource.MARKUPS);

  useEffect(() => {
    setState(
      getItemCopyState(
        projectID,
        projectData.project?.name,
        itemData?.item ?? undefined,
        copyItemData?.copyItemData,
        projectCategorizationData?.projectCategorizations,
        formatAssetIDsInput(itemAssets?.itemAttachments),
        hasEstimateLinesAccess && hasEstimateMarkupsAccess
      )
    );
  }, [
    copyItemData?.copyItemData,
    itemData?.item,
    projectCategorizationData?.projectCategorizations,
    projectID,
    projectData.project?.name,
    itemAssets,
    hasEstimateLinesAccess,
    hasEstimateMarkupsAccess,
  ]);

  const onUpdateState = useCallback(
    (update: Partial<ItemCopyState>) => setState((prev) => prev && { ...prev, ...update }),
    []
  );

  const formatCategorizationMapInput = (categorizationMappings?: Map<UUID, UUID>) => {
    const categorizationsInput: ItemCopyInput['categorizations'] = [];
    if (!categorizationMappings) return [];
    categorizationMappings.forEach((value, key) =>
      categorizationsInput.push({ oldCategorizationID: key, newCategorizationID: value })
    );
    return categorizationsInput;
  };

  const formatAssetIDsInput = (itemAssets?: ItemAttachmentsQuery['itemAttachments']) => {
    const assetIDs: UUID[] = [];
    if (!itemAssets) return [];
    itemAssets.forEach((a) => {
      if (a) assetIDs.push(a.id);
    });
    return assetIDs;
  };

  const variables: CopyItemMutationVariables | undefined = useMemo(
    () =>
      projectID
        ? {
            itemID,
            input: {
              ...state,
              projectID,
              categories: state?.categories.map((c) => ({
                id: c.id,
                categorizationID: c.categorization?.id ?? '',
                number: c.number,
              })),
              categorizations: formatCategorizationMapInput(state.categorizationMappings),
            },
          }
        : undefined,
    [itemID, projectID, state]
  );

  const onSendAnalytics = useCallback(
    (eventType: copyItemEventTypes) => {
      const defaultCategories = copyItemData?.copyItemData.convertedItemCategories ?? [];
      const defaultCategorizationMappings = getInitialCategorizationMap(
        copyItemData?.copyItemData.itemEstimateCategorizations ?? [],
        projectCategorizationData?.projectCategorizations ?? []
      );

      let eventProperties: EventProperties = { copiedItemID: itemID };
      switch (eventType) {
        case copyItemEventTypes.PROJECT_SELECTION_CTA:
          // user has set destination project
          eventProperties = { ...eventProperties, selectedProjectID: state.projectID };
          break;
        case copyItemEventTypes.GENERAL_INFO_CTA:
          // user has changed name input
          if (itemData?.item?.name !== state.name)
            eventProperties = {
              ...eventProperties,
              defaultName: itemData?.item?.name,
              updatedName: state.name,
            };
          // user has changed description input
          if (itemData?.item?.description !== state.description)
            eventProperties = {
              ...eventProperties,
              defaultDescription: itemData?.item?.description,
              updatedDescription: state.description,
            };
          // user has changed status input
          if (itemData?.item?.status !== Status.PENDING)
            eventProperties = {
              ...eventProperties,
              defaultStatus: Status.PENDING,
              updatedStatus: state.status,
            };
          // user has changed assignee input
          if (state.assigneeEmail)
            eventProperties = {
              ...eventProperties,
              updatedAssignee: state.assigneeEmail,
            };
          // user has changed due date input
          if (state.dueDate)
            eventProperties = {
              ...eventProperties,
              updatedDueDate: state.dueDate,
            };
          // user has changed milestone input
          if (state.milestoneID)
            eventProperties = {
              ...eventProperties,
              updatedMilestoneID: state.milestoneID,
            };
          break;
        case copyItemEventTypes.ITEM_CATEGORIES_CTA:
          // user has changed any category input
          if (
            defaultCategories.length !== state.categories.length ||
            defaultCategories.some((category, i) => category.id !== state.categories[i].id)
          )
            eventProperties = {
              ...eventProperties,
              defaultCategories: defaultCategories.map((c) => ({
                id: c.id,
                number: c.number,
                name: c.name,
                categorization: c.categorization?.name,
              })),
              updatedCategories: state.categories?.map((c) => ({
                id: c.id,
                number: c.number,
                name: c.name,
                categorization: c.categorization?.name,
              })),
            };
          break;
        case copyItemEventTypes.CATEGORIZATION_MAPPINGS_CTA:
          // user has changed any categorization mapping input
          if (
            defaultCategorizationMappings.size !== state.categorizationMappings?.size ||
            [...defaultCategorizationMappings.keys()].some(
              (key) => !state.categorizationMappings?.has(key)
            )
          )
            eventProperties = {
              ...eventProperties,
              defaultCategorizationMappings: [...defaultCategorizationMappings.keys()].map(
                (key) => ({
                  oldCategorizationID: key,
                  newCategorizationID: defaultCategorizationMappings.get(key),
                })
              ),
              updatedCategorizationMappings: [...(state.categorizationMappings?.keys() ?? [])].map(
                (key) => ({
                  oldCategorizationID: key,
                  newCategorizationID: state.categorizationMappings?.get(key),
                })
              ),
            };
          break;
        case copyItemEventTypes.ITEM_ASSETS_CTA:
          // user has changed assets input
          if (
            itemData?.item?.assets.length !== state.assetIDs.length ||
            itemData?.item?.assets.some((asset, i) => asset.id !== state.assetIDs[i])
          )
            eventProperties = {
              ...eventProperties,
              defaultAssetIDs: itemData?.item?.assets.map(({ id }) => id),
              updatedAssetIDs: state.assetIDs,
            };
          break;
        default:
          break;
      }
      sendAnalytics(eventType, eventProperties);
    },
    [
      copyItemData?.copyItemData.convertedItemCategories,
      copyItemData?.copyItemData.itemEstimateCategorizations,
      itemData?.item?.assets,
      itemData?.item?.description,
      itemData?.item?.name,
      itemData?.item?.status,
      itemID,
      projectCategorizationData?.projectCategorizations,
      sendAnalytics,
      state.assetIDs,
      state.assigneeEmail,
      state.categories,
      state.categorizationMappings,
      state.description,
      state.dueDate,
      state.milestoneID,
      state.name,
      state.projectID,
      state.status,
    ]
  );

  return useMemo(
    () => ({
      onSelectProject: setProjectID,
      onSendAnalytics,
      onUpdateState,
      state,
      variables,
    }),
    [onSendAnalytics, onUpdateState, state, variables]
  );
};
