import { Navigate } from 'react-router-dom';

import { YC_INSIGHTS_V2 } from '../../features';
import { INSIGHTS_V2, JOIN_INSIGHTS } from '../../moduleEntitlementFlagsList';
import ExecutiveDashboardV2 from '../ExecutiveDashboardV2/ExecutiveDashboardV2';
import HasFeatureFlag from '../HasFeatureFlag';
import HasModuleEntitlement from '../HasModuleEntitlement';

import ExecutiveDashboard from './ExecutiveDashboard';
import { PermissionResource } from '../../generated/graphql';
import HasPermission from '../HasPermission';

export default function ExecutiveDashboardRoute() {
  return (
    <HasPermission
      fallback={<Navigate replace to="/404" />}
      permission={PermissionResource.INSIGHTS_ACCESS}
    >
      <HasFeatureFlag
        fallback={
          <HasModuleEntitlement fallback={<Navigate replace to="/404" />} flag={JOIN_INSIGHTS}>
            <ExecutiveDashboard />
          </HasModuleEntitlement>
        }
        flag={YC_INSIGHTS_V2}
      >
        <HasModuleEntitlement fallback={<Navigate replace to="/404" />} flag={INSIGHTS_V2}>
          <ExecutiveDashboardV2 />
        </HasModuleEntitlement>
      </HasFeatureFlag>
    </HasPermission>
  );
}
