import { JSX } from 'react';

import usePermissions from '../utilities/permissions/usePermissions';

type Props = {
  children: JSX.Element;
  fallback?: JSX.Element;
  /** PermissionResource that the user must have VIEW perms for. */
  permission: PermissionResource;
};

export default function HasPermission(props: Props) {
  const { canView, isInitialLoadComplete } = usePermissions();

  if (!isInitialLoadComplete) return null;

  const hasPerm = canView(props.permission);
  if (!hasPerm) return props.fallback ?? null;

  return props.children;
}
