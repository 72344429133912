import { ContingenciesTimeseries } from '../../../generated/graphql';
import { formatCost } from '../../../utilities/currency';
import { isNonNullable } from '../../../utilities/types';
import { getYDomain } from '../../dragon-scales/TimelineCharts/InsightsContingencies/utils';
import { getYDomainPadded } from '../../dragon-scales/TimelineCharts/InsightsCost/utils';
import useMemoWrapper from '../../useMemoWrapper';

import TimelineLabels from './TimelineLabels';

const costFormat = { short: true, showCurrencySymbol: false };

type Props = {
  className?: string;
  costs: ContingenciesTimeseries[];
  hasAllowance: boolean;
  hasContingency: boolean;
  index: number;
};

export default function TimelineCAndALabels(props: Props) {
  const { hasContingency, hasAllowance } = props;
  const cost = props.costs[props.index];
  // y domain
  const [yDataMin, yDataMax] = useMemoWrapper(
    getYDomain,
    props.costs,
    hasAllowance,
    hasContingency
  );
  // Add vertical padding
  const { yDomain } = useMemoWrapper(getYDomainPadded, yDataMin, yDataMax);
  if (!cost) return null;
  if (
    // There is a difference in USCents type in BE and FE scalar definition
    (cost.contingencyTotal as unknown as string) === '0' &&
    (cost.allowanceTotal as unknown as string) === '0'
  )
    return null;

  return (
    <div className={['pointer-events-none absolute h-max', props.className].join(' ')}>
      <TimelineLabels
        color={[
          // Tooltip background colors
          hasContingency ? 'var(--colors-entities-contingencies-label)' : null,
          hasAllowance ? 'var(--colors-entities-allowances-label)' : null,
        ].filter(isNonNullable)}
        content={[
          hasContingency ? formatCost(cost.contingencyTotal, costFormat) : null,
          hasAllowance ? formatCost(cost.allowanceTotal, costFormat) : null,
        ].filter(isNonNullable)}
        data={[
          hasContingency
            ? {
                value: cost.contingencyTotal,
                date: cost.date,
              }
            : null,
          hasAllowance
            ? {
                value: cost.allowanceTotal,
                date: cost.date,
              }
            : null,
        ].filter(isNonNullable)}
        yDomain={yDomain}
      />
    </div>
  );
}
