import { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import { reportPrint } from '../analytics/analyticsEventProperties';
import { useDebounceFn } from '../hooks';
import useSendAnalytics, {
  useAnalyticsIdentifyUser,
  useAnalyticsLocationViewEvent,
} from '../hooks/useSendAnalytics';
import { getProjectIdFromUrl } from '../utilities/url';
import { replaceUUIDs } from '../utilities/uuid';

import AlertsSnackBar from './alerts/AlertsSnackBar/AlertsSnackBar';
import { ProjectTermProvider } from './ProjectDisplaySettings/TerminologyProvider';

export default function AppFrame() {
  // LOCATION HOOKS
  // TODO: Move this into the Router for useParams
  const location = useLocation();
  const { pathname } = location;
  const projectId = getProjectIdFromUrl(pathname);

  // ANALYTICS
  useAnalyticsIdentifyUser();
  useAnalyticsLocationViewEvent();
  const sendAnalytics = useSendAnalytics();
  const MILLISECONDS = 5000;
  const debouncedAnalytics = useDebounceFn(() => {
    sendAnalytics(reportPrint(replaceUUIDs(pathname)));
  }, MILLISECONDS);
  const debouncedReportPrintLocation = (e: MediaQueryListEvent) => {
    if (e.matches) {
      debouncedAnalytics();
    }
  };
  useEffect(() => {
    window.matchMedia('print').addEventListener('change', debouncedReportPrintLocation);
    return () => {
      window.matchMedia('print').removeEventListener('change', debouncedReportPrintLocation);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO CT-566: Fix this pls :)
  }, []);

  // otherwise, on to the regularly scheduled Join Application
  return (
    // TODO: ProjectTermProvider should probably be rendered by the ProjectRoute.
    <ProjectTermProvider projectID={projectId}>
      <Outlet />
      <AlertsSnackBar />
    </ProjectTermProvider>
  );
}
