import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../../theme/komodo-mui-theme';

export const styles = (theme: KomodoTheme) =>
  createStyles({
    row: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'start',
      height: 255,
      width: 'fit-content',
    },
    rowInfoContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      position: 'sticky',
      width: 320,
      flexShrink: 0,
      left: 0,
      whiteSpace: 'nowrap',
      padding: 0,
      borderRight: theme.border.darkGrey,
      paddingBottom: 12,
      flexDirection: 'column',
      background: theme.palette.backgroundWhite,
      zIndex: 1,
    },
    rowName: {
      textWrap: 'wrap',
      overflow: 'hidden',
      display: '-webkit-box',
      WebkitLineClamp: 2,
      WebkitBoxOrient: 'vertical',
    },
    rowTitleContainer: {
      background: theme.palette.nestingGrey,
      width: '100%',
      padding: '8px 24px',
      display: 'flex',
      flexDirection: 'column',
      rowGap: '20px',
      fontWeight: 700,
      marginTop: 18,
    },
    rowInfoLabels: {
      padding: '3px 0 3px 24px', // adding an extra pixel vertically to account for border
      width: '100%',
      textAlign: 'left',
      fontWeight: 700,
      fontSize: 14,
    },
    tableSpacing: {
      '& > div': {
        display: 'flex',
        alignItems: 'center',
        height: 32,
        padding: '4px 8px',
        '&:not(:first-child)': {
          borderTop: theme.border.darkGrey,
        },
      },
    },
    rowContingenciesContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'start',
    },
    tableNumber: {
      ...theme.typography.webTableNumber,
      padding: '0px 2px 0px 2px',
    },
    tablePercent: {
      ...theme.typography.webTableNumber,
      color: theme.palette.chartGrey,
      marginLeft: 30,
    },
    firstRowTablePercent: {
      ...theme.typography.webTableNumber,
      color: theme.palette.chartGrey,
      marginLeft: 10,
    },
    initialAmountSetContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    info: {
      color: theme.palette.joinGrey400,
      margin: 4,
      fontSize: 16,
    },
  });
