import { formatCost } from '../../../utilities/currency';
import { StackDataItem } from '../Charts/utils';
import { HeaderDisplayBy } from '../PieCharts/InsightsListHeaderPieBar';
import { formatPercentage } from '../ProjectsTables/utils';

type Props = {
  selectedDisplayBy: HeaderDisplayBy;
  stackData: StackDataItem[];
};

export default function DesignPhaseStackedBarChartTooltip(props: Props) {
  const sortByCounts = props.selectedDisplayBy === HeaderDisplayBy.COUNT;
  // Extract the projectsCount values and calculate the total
  const dataWithCounts = props.stackData.map((item) => ({
    category: item.category,
    totalCount: item.values.find((v) => v.name === 'projectsCount')?.value || 0,
  }));
  const totalCount = dataWithCounts.reduce((sum, item) => sum + item.totalCount, 0);
  const currencyFormat = { short: true };

  return (
    <div className="flex flex-col gap-2">
      <div className="type-heading3">Projects by Design Phase</div>
      <div className="flex flex-col gap-3 text-left">
        {dataWithCounts.map((item) => (
          <div key={item.category}>
            <div className="flex items-center justify-between gap-4">
              <div className="line-clamp-1 flex-1 type-body2">{item.category}</div>
              <div className="w-10 text-right type-body2">
                {sortByCounts ? item.totalCount : formatCost(item.totalCount, currencyFormat)}
              </div>
              <div className="w-10 text-right type-body2">
                {formatPercentage(item.totalCount, totalCount)}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
