import { FC } from 'react';

import { useCompanyRoutesData } from '../../CompanyTabUtils';

import { useProjectTemplatesQuery } from './hooks';
import ProjectTemplates from './ProjectTemplates';

const ProjectTemplatesData: FC = () => {
  const { companyID } = useCompanyRoutesData();

  const { data: { projectTemplates } = { projectTemplates: [] } } = useProjectTemplatesQuery(
    companyID,
    true
  );

  return <ProjectTemplates projectTemplates={projectTemplates} />;
};

export default ProjectTemplatesData;
