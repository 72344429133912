import { Button } from '../../../../scales';

type Props = {
  email: string;
  resendEmail: () => void;
};

export default function EmailVerification(props: Props) {
  return (
    <>
      <div className="type-body1">
        Please go to your inbox for <strong>{props.email}</strong> and follow the link in the email
        we just sent to verify your email.
      </div>
      <div className="flex items-center gap-1 type-body2">
        <div>Don&apos;t see an email after a few moments?</div>
        <Button label="Resend Email" onClick={props.resendEmail} type="tertiary" />
      </div>
    </>
  );
}
