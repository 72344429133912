import { ComponentProps } from 'react';

import { ScrollContainer } from '../../scales';
import CreateScenarioButton from '../common/CreateScenarioButton';
import DropTargetPlaceholder from '../common/DropTargetPlaceholder';
import Library from '../Library';

import DropTargetScenarios from './DropTargetScenarios';
import MilestoneCard from './MilestoneCard';
import ScenarioCard from './Scenario/ScenarioCard';
import ScenarioPlaceholder from './Scenario/ScenarioPlaceholder';

type Props = {
  activeMilestoneID?: UUID;
  milestone: ComponentProps<typeof MilestoneCard>['milestone'];
  milestoneName: ComponentProps<typeof MilestoneCard>['name'];
  onAddToAllScenarios: (itemID: UUID) => void;
  onAddToScenario: (itemID: UUID, scenarioID: UUID) => void;
  onAddScenario: (scenarioID: UUID) => void;
  onCreateScenario: ComponentProps<typeof CreateScenarioButton>['onCreateScenario'];
  onRemoveScenario: (scenarioID: UUID, milestoneID: UUID) => void;
  onReorder: (oldIndex: number) => (newIndex: number) => void;
  sandbox: ComponentProps<typeof Library>['sandbox'];
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  showMilestone: boolean;
  sortManager: ComponentProps<typeof MilestoneCard>['sortManager'];
};

export default function SandboxView(props: Props) {
  const { milestoneID } = props.milestone;

  return (
    <div className="flex w-full flex-grow overflow-hidden">
      <Library
        onAddToAllScenarios={props.onAddToAllScenarios}
        onAddToScenario={props.onAddToScenario}
        onCreateScenario={props.onCreateScenario}
        sandbox={props.sandbox}
      />
      <DropTargetScenarios onScenarioDrop={props.onAddScenario}>
        {({ dropProps, isDropTarget }) => (
          <div className="relative overflow-auto" {...dropProps}>
            {isDropTarget && (
              <DropTargetPlaceholder isOverlay text="Drop to add scenario to sandbox" />
            )}
            <ScrollContainer className="h-full" direction="horizontal">
              <div className="flex gap-2 p-2">
                {props.showMilestone && props.milestone && (
                  <MilestoneCard
                    isActiveMilestone={milestoneID === props.activeMilestoneID}
                    milestone={props.milestone}
                    name={props.milestoneName}
                    sortManager={props.sortManager}
                  />
                )}
                {props.sandbox.scenarios.map((s, i) => (
                  <ScenarioCard
                    key={s.scenarioID}
                    index={i}
                    milestoneID={milestoneID}
                    milestoneName={props.milestoneName}
                    onAddItem={(itemID: string) => {
                      props.onAddToScenario(itemID, s.scenarioID);
                    }}
                    onRemove={() => {
                      props.onRemoveScenario(s.scenarioID, milestoneID);
                    }}
                    onReorder={props.onReorder(i)}
                    sandbox={props.sandbox}
                    scenario={s}
                    sortManager={props.sortManager}
                  />
                ))}
                <ScenarioPlaceholder
                  hasNoScenarios={props.sandbox.scenarios.length === 0}
                  onCreateScenario={props.onCreateScenario}
                />
              </div>
            </ScrollContainer>
          </div>
        )}
      </DropTargetScenarios>
    </div>
  );
}
