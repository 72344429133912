type Props = {
  label?: string;
  isClearable?: boolean;
  isDisabled?: boolean;
};

export default function useHasClearButton(props: Props) {
  const hasLabel = Boolean(props.label);
  const hasClearButton = Boolean(props.isClearable && !props.isDisabled);
  const hasTextClearButton = hasClearButton && hasLabel;
  const hasInlineClearButton = hasClearButton && !hasLabel;

  return {
    hasClearButton,
    hasInlineClearButton,
    hasTextClearButton,
  };
}
