import { FC } from 'react';

import { Checkbox, ListItemText, MenuItem, Select } from '@material-ui/core';

import { ContingencyInfo, MarkupDisplayType } from '../../../generated/graphql';
import { withStyles } from '../../../theme/komodo-mui-theme';

import { styles } from './ContingencyReportStyles';
import { handleOptionClick, parentCheckboxState, toggleAllOptions } from './ContingencyReportUtils';

type ContingencyReportSelectProps = {
  classes: Classes<typeof styles>;
  availableContingencies: Pick<ContingencyInfo, 'type' | 'name'>[];
  selectedContingencies: string[];
  setSelectedContingencies: (value: string[]) => void;
  selectedAllowances: string[];
  setSelectedAllowances: (value: string[]) => void;
};

const ContingencyReportSelect: FC<ContingencyReportSelectProps> = ({
  classes,
  availableContingencies,
  selectedContingencies,
  setSelectedContingencies,
  selectedAllowances,
  setSelectedAllowances,
}) => {
  const allContingencyNames = availableContingencies
    .filter((contingency) => contingency.type === MarkupDisplayType.CONTINGENCY)
    .map((contingency) => contingency.name);
  const allAllowanceNames = availableContingencies
    .filter((contingency) => contingency.type === MarkupDisplayType.ALLOWANCE)
    .map((contingency) => contingency.name);

  const disableContingency = allContingencyNames.length === 0;
  const disableAllowance = allAllowanceNames.length === 0;

  const getMenuOptions = (
    contingencies: Pick<ContingencyInfo, 'name' | 'type'>[],
    displayType: MarkupDisplayType
  ) => {
    return contingencies
      .filter((contingency) => contingency.type === displayType)
      .map((contingency) => {
        const { name, type } = contingency;
        const selectedOptions =
          type === MarkupDisplayType.ALLOWANCE ? selectedAllowances : selectedContingencies;
        const stateFunctions =
          type === MarkupDisplayType.CONTINGENCY
            ? {
                selectedOptions: selectedContingencies,
                setSelectedOptions: setSelectedContingencies,
              }
            : {
                selectedOptions: selectedAllowances,
                setSelectedOptions: setSelectedAllowances,
              };

        return (
          <div key={name}>
            <MenuItem
              disableGutters
              onClick={() =>
                handleOptionClick(
                  stateFunctions.selectedOptions,
                  stateFunctions.setSelectedOptions,
                  name
                )
              }
              value={name}
            >
              <Checkbox checked={selectedOptions.includes(name)} />
              <ListItemText classes={{ root: classes.noPadding, primary: classes.option }}>
                {name}
              </ListItemText>
            </MenuItem>
          </div>
        );
      });
  };

  const contingencyMenuOptions: JSX.Element[] = getMenuOptions(
    availableContingencies,
    MarkupDisplayType.CONTINGENCY
  );
  const allowanceMenuOptions: JSX.Element[] = getMenuOptions(
    availableContingencies,
    MarkupDisplayType.ALLOWANCE
  );

  return (
    <Select
      classes={{
        icon: classes.hideBorder,
        selectMenu: classes.selectMenu,
        root: classes.bgnWhite,
      }}
      disableUnderline
      displayEmpty
      multiple
      renderValue={() => {
        const selectedOptions = [...selectedContingencies, ...selectedAllowances];
        if (selectedOptions.length === 0) {
          return <div className={classes.ellipsisOverflow}>Choose a Contingency or Allowance</div>;
        }
        if (selectedOptions.length === [...allContingencyNames, ...allAllowanceNames].length) {
          return <div className={classes.ellipsisOverflow}>All</div>;
        }
        return <div className={classes.ellipsisOverflow}>{selectedOptions.join(', ')}</div>;
      }}
      value={[...selectedContingencies, ...selectedAllowances]}
    >
      <div className={classes.flexCenter}>
        <Checkbox
          checked={parentCheckboxState(selectedContingencies, contingencyMenuOptions).allSelected}
          disabled={disableContingency}
          indeterminate={
            parentCheckboxState(selectedContingencies, contingencyMenuOptions).indeterminate
          }
          onClick={() => {
            toggleAllOptions(setSelectedContingencies, selectedContingencies, allContingencyNames);
          }}
        />
        <ListItemText
          classes={{
            root: classes.noPadding,
            primary: classes.option,
          }}
          disableTypography={disableContingency}
          onClick={() => {
            toggleAllOptions(setSelectedContingencies, selectedContingencies, allContingencyNames);
          }}
        >
          Contingencies
        </ListItemText>
      </div>
      {contingencyMenuOptions}
      <div className={classes.flexCenter}>
        <Checkbox
          checked={parentCheckboxState(selectedAllowances, allowanceMenuOptions).allSelected}
          disabled={disableAllowance}
          indeterminate={
            parentCheckboxState(selectedAllowances, allowanceMenuOptions).indeterminate
          }
          onClick={() => {
            toggleAllOptions(setSelectedAllowances, selectedAllowances, allAllowanceNames);
          }}
        />
        <ListItemText
          classes={{
            root: classes.noPadding,
            primary: classes.option,
          }}
          disableTypography={disableAllowance}
          onClick={() => {
            toggleAllOptions(setSelectedAllowances, selectedAllowances, allAllowanceNames);
          }}
        >
          Allowances
        </ListItemText>
      </div>
      {allowanceMenuOptions}
    </Select>
  );
};

export default withStyles(styles)(ContingencyReportSelect);
