/* eslint-disable func-names */

import type { ScaleTime } from 'd3-scale';
import { select } from 'd3-selection';

import './timeline.scss';

import { NULL_ID } from '../../../../constants';
import { TimelineDefaults } from '../../../Timeline/TimelineUtils';

import {
  BaseDataBaseSelection,
  type ChartContainer,
  type Click,
  type Color,
  DivSelection,
  EnterDataSelection,
  type GetterSetter,
  GroupByType,
  Margin,
  SVGGDataBaseSelection,
  SVGGDataSelection,
  SVGPathDataSelection,
  type Timeline,
  type TimelineData,
  TimelineEvent,
  type TimelineExpandedMap,
  TimelineHeight,
  TimelineItemData,
  type TimelineOptions,
  type TimelineSettings,
  TimelineType,
  type Tooltip,
} from './timeline.types';
import { PHASE_GREY } from './timelineColor';
import { updateAxis, updateGridlines, updateGroups, updateTodayLine } from './timelineDecorations';
import { addItemsContainer, removeItems, updateItems, updateItemsInternal } from './timelineItems';
import {
  ALERT_PATH,
  ARROW_COLLAPSED_PATH,
  ARROW_EXPANDED_PATH,
  getInstantPath,
} from './timelinePath';
import { END_DATE_ALERT, createTooltipHost, updateTooltips } from './timelineTooltips';
import {
  BRUSH_HEIGHT,
  CHART_GROUP,
  FONT_STYLE,
  ITEMS_HEIGHT,
  ROW_HEIGHT,
  createXScale,
  getDomainByPercentage,
  getExpandedData,
  getFlatData,
  getGroupSvgId,
  getItemsChart,
  getItemsGroupBy,
  getItemsGroups,
  getItemsLegend,
  getTimelineChart,
  isEndDateAlert,
  isExpandable,
  isInstantInterval,
  isInterval,
  isMilestone,
  isSettingsItems,
  isSettingsTimeline,
} from './utils';
import { updateDragAndZoom } from './zoomByDrag';
import { updateBrush as updateBrushBasic } from './zoomlineBasic';
import { updateBrush as updateBrushCompressed } from './zoomlineCompressed';
import { updateZoomResetBtn } from './zoomResetBtn';

export function timeline<T extends TimelineData = TimelineData>() {
  const outerWidth = 960;
  const outerHeight = 500;
  const margin = {
    top: 8,
    right: 8,
    bottom: 8,
    left: 8,
  };
  const width = outerWidth - margin.left - margin.right;
  const height = outerHeight - margin.top - margin.bottom;
  const options: TimelineOptions = {
    outerWidth,
    outerHeight,
    brushHeight: BRUSH_HEIGHT,
    isPrint: false,
    width,
    height: { totalHeight: height, timelineHeight: height, itemsHeight: ITEMS_HEIGHT },
    margin,
    showTooltips: false,
    itemsGroupBy: GroupByType.Month,
    itemGroups: [],
    itemsLegend: { counter: { 0: 0, 1: 0, 2: 0 } },
    data: [],
    items: [],
    settings: { ...TimelineDefaults, projectID: NULL_ID },
    range: ['0', '0'],
    dataExpanded: [],
    dataFlat: [],
    expandedMap: {},
    today: undefined,
    isTimeline: true,
    isItems: true,
    tooltipElement: () => null,
    nodeColor: () => PHASE_GREY,
    strokeColor: () => PHASE_GREY,
    onAnalytics: () => {},
    onExpand: () => {},
    onZoom: () => {},
  };
  let chartContainer: ChartContainer;
  let timelineXScale: ScaleTime<number, number>;
  let dragAndZoomXScale: ScaleTime<number, number>;
  let brushXScale: ScaleTime<number, number>;

  const timeline: Timeline<T> = {
    margin: ((margin?: Margin) => {
      if (margin) options.margin = margin;
      return timeline;
    }) as GetterSetter<Timeline<T>, Margin>,
    width: ((width?: number) => {
      if (typeof width === 'number') {
        options.outerWidth = width;
        options.width = options.outerWidth - options.margin.left - options.margin.right;

        if (chartContainer) {
          timelineXScale = createXScale(options);
          dragAndZoomXScale = createXScale(options);
          brushXScale = createXScale(options);
          updateChartContainer(chartContainer, options);
          const chart = chartContainer.container;
          updateChart(chart, timelineXScale, dragAndZoomXScale, brushXScale, options);
        }
        return timeline;
      }
      return options.width;
    }) as GetterSetter<Timeline<T>, number>,
    height: ((height: TimelineHeight) => {
      options.outerHeight = height.totalHeight + 16;
      options.height = height;

      if (chartContainer) {
        updateChartContainer(chartContainer, options);
        const chart = chartContainer.container;
        updateChart(chart, timelineXScale, dragAndZoomXScale, brushXScale, options);
      }
      return timeline;
    }) as GetterSetter<Timeline<T>, TimelineHeight>,
    isPrint: ((isPrint: boolean) => {
      options.isPrint = isPrint;

      if (chartContainer) {
        updateChartContainer(chartContainer, options);
      }
      return timeline;
    }) as GetterSetter<Timeline<T>, boolean>,
    nodeColor(color: Color<T>) {
      options.nodeColor = color as Color<TimelineData>;

      if (chartContainer) {
        updateChartContainer(chartContainer, options);
      }
      return timeline;
    },
    strokeColor(color: Color<T>) {
      options.strokeColor = color as Color<TimelineData>;

      if (chartContainer) {
        updateChartContainer(chartContainer, options);
      }
      return timeline;
    },
    tooltips: ((tooltips?: boolean) => {
      options.showTooltips = !!tooltips;

      if (chartContainer) {
        updateChartContainer(chartContainer, options);
      }
      return timeline;
    }) as GetterSetter<Timeline<T>, boolean>,
    tooltipElement(tooltipElement: Tooltip<T>) {
      options.tooltipElement = tooltipElement as Tooltip<TimelineData>;

      if (chartContainer) {
        updateChartContainer(chartContainer, options);
      }
      return timeline;
    },
    click(click: Click<T>) {
      if (click) {
        options.click = click as Click<TimelineData>;

        if (chartContainer) {
          const chart = chartContainer.container;
          updateChart(chart, timelineXScale, dragAndZoomXScale, brushXScale, options);
        }
      }

      return timeline;
    },
    data: ((data?: T[]) => {
      if (!!data && Array.isArray(data)) {
        options.data = data;
        options.dataFlat = getFlatData(data);
        timelineXScale = createXScale(options);
        dragAndZoomXScale = createXScale(options);
        brushXScale = createXScale(options);
        if (options.isPrint) options.data = options.dataFlat;
        if (chartContainer) {
          const chart = chartContainer.container;
          updateChart(chart, timelineXScale, dragAndZoomXScale, brushXScale, options);
        }
        return timeline;
      }
      return options.data;
    }) as GetterSetter<Timeline<T>, T[]>,
    items: ((items?: TimelineItemData[]) => {
      if (!!items && Array.isArray(items)) {
        options.items = items;
        timelineXScale = createXScale(options);
        dragAndZoomXScale = createXScale(options);
        brushXScale = createXScale(options);
        const groupBy = getItemsGroupBy(timelineXScale.domain());
        options.itemsGroupBy = groupBy;
        options.itemGroups = getItemsGroups(options.items, groupBy);
        options.itemsLegend = getItemsLegend(options.itemGroups);
        if (chartContainer) {
          const chart = chartContainer.container;
          updateChart(chart, timelineXScale, dragAndZoomXScale, brushXScale, options);
        }
        return timeline;
      }
      return options.items;
    }) as GetterSetter<Timeline<T>, TimelineItemData[]>,
    settings: ((settings: TimelineSettings) => {
      options.settings = settings;
      options.isTimeline = isSettingsTimeline(settings);
      options.isItems = isSettingsItems(settings);

      if (chartContainer) {
        const chart = chartContainer.container;
        updateChart(chart, timelineXScale, dragAndZoomXScale, brushXScale, options);
      }
      return timeline;
    }) as GetterSetter<Timeline<T>, TimelineSettings>,
    range: ((range: string[]) => {
      options.range = range;
      return timeline;
    }) as GetterSetter<Timeline<T>, string[]>,
    expandedMap: ((expandedMap: TimelineExpandedMap) => {
      options.expandedMap = expandedMap;
      options.dataExpanded = getExpandedData(options.data, options.expandedMap);
      if (chartContainer) {
        const zoomRange = timelineXScale.domain().map(brushXScale);
        const chart = chartContainer.container;
        removeNodes(chart);
        updateChart(chart, timelineXScale, dragAndZoomXScale, brushXScale, options, zoomRange);
      }
      return timeline;
    }) as GetterSetter<Timeline<T>, TimelineExpandedMap>,
    today: ((today?: string) => {
      if (today) {
        options.today = today;
        if (chartContainer) {
          const chart = chartContainer.container;
          updateChart(chart, timelineXScale, dragAndZoomXScale, brushXScale, options);
        }
        return timeline;
      }
      return timeline;
    }) as GetterSetter<Timeline<T>, string>,
    zoomLineCompressed: ((zoomLineCompressed?: boolean) => {
      if (zoomLineCompressed) {
        options.zoomLineCompressed = zoomLineCompressed;
        options.brushHeight = BRUSH_HEIGHT * 2;
        if (chartContainer) {
          const chart = chartContainer.container;
          updateChart(chart, timelineXScale, dragAndZoomXScale, brushXScale, options);
        }
        return timeline;
      }
      return timeline;
    }) as GetterSetter<Timeline<T>, boolean>,
    zoomLineDisabled: ((zoomLineDisabled?: boolean) => {
      if (zoomLineDisabled) {
        options.zoomLineDisabled = zoomLineDisabled;
        return timeline;
      }
      return timeline;
    }) as GetterSetter<Timeline<T>, boolean>,
    onExpand: ((onExpand: (d: TimelineData) => void) => {
      options.onExpand = onExpand;

      if (chartContainer) {
        updateChartContainer(chartContainer, options);
      }
      return timeline;
    }) as GetterSetter<Timeline<T>, (d: TimelineData) => void>,
    onZoomIn: ((d: TimelineData) => {
      if (chartContainer && d) {
        const chart = chartContainer.container;
        zoomInData(chart, timelineXScale, dragAndZoomXScale, brushXScale, options, d);
      }
      return timeline;
    }) as GetterSetter<Timeline<T>, T>,
    onZoomInChart: ((d: TimelineData) => {
      if (chartContainer && d) {
        const chart = chartContainer.container;
        zoomInData(chart, timelineXScale, dragAndZoomXScale, brushXScale, options, d, 100);
        // Update items chart group by
        const groupBy = getItemsGroupBy(timelineXScale.domain());
        options.itemsGroupBy = groupBy;
        options.itemGroups = getItemsGroups(options.items, groupBy);
        updateItems(chart, timelineXScale, dragAndZoomXScale, brushXScale, options);
      }
      return timeline;
    }) as GetterSetter<Timeline<T>, T>,
    onZoom: ((onZoom: (domain: Date[]) => void) => {
      options.onZoom = onZoom;

      if (chartContainer) {
        updateChartContainer(chartContainer, options);
      }
      return timeline;
    }) as GetterSetter<Timeline<T>, (domain: Date[]) => void>,
    onAnalytics: ((onAnalytics: (event: TimelineEvent) => void) => {
      options.onAnalytics = onAnalytics;

      if (chartContainer) {
        updateChartContainer(chartContainer, options);
      }
      return timeline;
    }) as GetterSetter<Timeline<T>, (event: TimelineEvent) => void>,
    render(domElement: HTMLElement) {
      chartContainer = createChartContainer(domElement, options);

      if (options.showTooltips && select('#timeline-tooltip-container').empty()) {
        createTooltipHost();
      }

      const newXScale = createXScale(options);

      timelineXScale = timelineXScale || { ...newXScale };
      dragAndZoomXScale = dragAndZoomXScale || { ...newXScale };
      brushXScale = brushXScale || { ...newXScale };
      const chart = chartContainer.container;
      updateChart(chart, timelineXScale, dragAndZoomXScale, brushXScale, options);

      return timeline;
    },
  };

  return timeline;
}

function createChartContainer(domElement: HTMLElement, options: TimelineOptions): ChartContainer {
  // Remove if exist
  const legacyOuterContainer = select(domElement).select('#timeline-outer-container');
  if (!legacyOuterContainer.empty()) {
    legacyOuterContainer.remove();
  }

  // Create outer container with margin
  const outerContainer = select(domElement).append('div').attr('id', 'timeline-outer-container');

  // Create chart container
  const container = outerContainer.append('div').attr('id', 'timeline-container');

  // Create group container
  container.append('div').attr('id', 'timeline-groups');

  const chartContainer = { outerContainer, container };
  updateChartContainer(chartContainer, options);
  return chartContainer;
}

function updateChartContainer(chartContainer: ChartContainer, options: TimelineOptions): void {
  const { margin, settings } = options;
  const { outerContainer, container } = chartContainer;

  outerContainer
    .style('width', `${options.outerWidth}px`)
    .style('margin', `${margin.top}px ${margin.right}px ${margin.bottom}px ${margin.left}px`);

  container.style('width', `${options.width}px`).attr('class', 'bg-background-primary');

  if (!settings.isInsights) {
    outerContainer.style('height', `${options.outerHeight}px`);
    container.style('height', `${options.height}px`);
  }
}

const zoomInData = (
  chart: DivSelection,
  timelineXScale: ScaleTime<number, number>,
  dragAndZoomXScale: ScaleTime<number, number>,
  brushXScale: ScaleTime<number, number>,
  options: TimelineOptions,
  d: TimelineData,
  percents = 80
) => {
  const domain = getDomainByPercentage(d, options, percents);
  options.onZoom(domain);
  timelineXScale.domain(domain);
  dragAndZoomXScale.domain(domain);
  const zoomRange = domain.map(brushXScale);
  updateChart(chart, timelineXScale, dragAndZoomXScale, brushXScale, options, zoomRange);
};

const onDblClick =
  (
    chart: DivSelection,
    timelineXScale: ScaleTime<number, number>,
    dragAndZoomXScale: ScaleTime<number, number>,
    brushXScale: ScaleTime<number, number>,
    options: TimelineOptions
  ) =>
  (event: PointerEvent, d: TimelineData) => {
    event.preventDefault();
    zoomInData(chart, timelineXScale, dragAndZoomXScale, brushXScale, options, d, 80);
    options.onAnalytics(TimelineEvent.TIMELINE_DBL_CLICK_ZOOM);
  };

const onExpandClick = (options: TimelineOptions) => (event: PointerEvent, d: TimelineData) => {
  event.preventDefault();
  event.stopPropagation();
  const { expandedMap, onAnalytics, onExpand } = options;
  onExpand(d);
  onAnalytics(expandedMap[d.id] ? TimelineEvent.TIMELINE_COLLAPSE : TimelineEvent.TIMELINE_EXPAND);
};

function updateChart(
  chart: DivSelection,
  timelineXScale: ScaleTime<number, number>,
  dragAndZoomXScale: ScaleTime<number, number>,
  brushXScale: ScaleTime<number, number>,
  optionsOuter: TimelineOptions,
  zoomRangeOuter?: number[]
): void {
  const options = optionsOuter;
  const { isItems, isTimeline, settings } = options;
  updateGroups(chart, options);
  updateGridlines(chart, timelineXScale, options);
  updateNodes(chart, timelineXScale, dragAndZoomXScale, brushXScale, options);
  if (!settings.isInsights) addItemsContainer(chart, options);
  updateTodayLine(chart, timelineXScale, options);
  updateAxis(chart, timelineXScale, options);

  const brushUpdates = (domain: Date[]) => {
    timelineXScale.domain(domain);
    dragAndZoomXScale.domain(domain);
    const newGroupBy = getItemsGroupBy(domain);
    if (isItems && options.itemsGroupBy !== newGroupBy) {
      options.itemsGroupBy = newGroupBy;
      options.itemGroups = getItemsGroups(options.items, newGroupBy);
      removeItems(chart);
      updateItems(chart, timelineXScale, dragAndZoomXScale, brushXScale, options);
    }
    updateGridlines(chart, timelineXScale, options);
    if (isTimeline) updateNodeInternal(chart, timelineXScale, options);
    if (isItems) updateItemsInternal(chart, timelineXScale, options);
    updateTodayLine(chart, timelineXScale, options);
    updateAxis(chart, timelineXScale, options);
    updateZoomResetBtn(chart, timelineXScale, brushXScale, options, dragAndZoomUpdates);
  };

  const zoomRange = zoomRangeOuter || [16, brushXScale.range()[1]];
  const { zoomLineCompressed } = options;
  const updateBrush = zoomLineCompressed ? updateBrushCompressed : updateBrushBasic;
  updateBrush(chart, brushXScale, zoomRange, options, brushUpdates);

  const dragAndZoomUpdates = (domain: Date[]) => {
    brushUpdates(domain);
    const zoomRange = domain.map(brushXScale);
    updateBrush(chart, brushXScale, zoomRange, options, brushUpdates);
  };

  updateDragAndZoom(
    getTimelineChart(chart),
    dragAndZoomXScale,
    timelineXScale,
    brushXScale,
    options,
    dragAndZoomUpdates,
    TimelineEvent.TIMELINE_DRAG_ZOOM
  );

  updateDragAndZoom(
    getItemsChart(chart),
    dragAndZoomXScale,
    timelineXScale,
    brushXScale,
    options,
    dragAndZoomUpdates,
    TimelineEvent.DUE_DATE_DRAG_ZOOM
  );

  updateZoomResetBtn(chart, timelineXScale, brushXScale, options, dragAndZoomUpdates);
  if (isItems) updateItems(chart, timelineXScale, dragAndZoomXScale, brushXScale, options);
}

function renderCollapsedChildren(
  node: SVGGDataSelection,
  children: TimelineData[],
  options: TimelineOptions
) {
  for (let index = 0; index < children.length; index += 1) {
    const child = children[index];
    const path = getInstantPath(child);
    const svgGroups: SVGPathDataSelection = node
      .append('path')
      .attr('class', 'datapoint-child')
      .attr('index', index)
      .attr('d', path)
      .style('fill', () => options.nodeColor(child))
      .style('stroke', () => options.strokeColor(child))
      .style('stroke-width', 0.97);
    updateTooltips(svgGroups, options, undefined, child);
  }
}

function renderAlertIcon(node: SVGGDataSelection, options: TimelineOptions) {
  const g = node
    // Alert Icon group
    .append('g')
    .attr('class', 'alert');
  g
    // Hover area
    .append('circle')
    .style('r', 7)
    .style('fill', 'black')
    .style('fill-opacity', '0.01');
  g.append('path')
    // Add Icon Path
    .attr('d', ALERT_PATH)
    .style('fill', 'red');

  updateTooltips(g, options, END_DATE_ALERT);
}

function renderExpandButton(node: SVGGDataSelection, expanded: boolean, options: TimelineOptions) {
  const g = node
    .append('g')
    .attr('class', 'expand')
    .style('cursor', 'pointer')
    .on('click', onExpandClick(options));
  g
    // clickable area
    .append('circle')
    .style('r', 7)
    .style('fill', 'black')
    .style('fill-opacity', '0.01');
  g.append('path')
    .attr('d', expanded ? ARROW_EXPANDED_PATH : ARROW_COLLAPSED_PATH)
    .style('fill', 'black');
}

function renderNodes(
  enter: EnterDataSelection,
  chart: DivSelection,
  timelineXScale: ScaleTime<number, number>,
  dragAndZoomXScale: ScaleTime<number, number>,
  brushXScale: ScaleTime<number, number>,
  options: TimelineOptions
) {
  const { expandedMap, isPrint } = options;
  return enter
    .append('g')
    .attr('class', (d) => (isInterval(d) ? 'datapoint interval' : 'datapoint instant'))
    .each(function (d) {
      const node: SVGGDataSelection = select(this);
      node.on(
        'dblclick',
        onDblClick(chart, timelineXScale, dragAndZoomXScale, brushXScale, options)
      );
      if (isInterval(d)) {
        const instantInterval = isInstantInterval(d, options);
        // Add rectangle for each interval data point
        if (instantInterval) {
          node
            .append('circle')
            .attr('class', 'datapoint-svg')
            .attr('r', 5)
            .attr('cx', -5)
            .attr('cy', 5)
            .style('fill', (d: TimelineData) => options.nodeColor(d))
            .style('stroke', (d: TimelineData) => options.strokeColor(d));
        } else {
          node
            .append('rect')
            .attr('class', 'datapoint-svg')
            .attr('height', 10)
            .style('fill', (d: TimelineData) => options.nodeColor(d))
            .style('fill-opacity', '1')
            .style('stroke', (d: TimelineData) => options.strokeColor(d))
            .style('stroke-width', '1')
            .style('rx', '6')
            .style('ry', '6');
        }

        if (isExpandable(d) && !isPrint) {
          const expanded = !!expandedMap[d.id];
          if (!expanded) {
            renderCollapsedChildren(node, d.children || [], options);
            node.on('click', onExpandClick(options));
          }
          renderExpandButton(node, expanded, options);
        }

        node
          .append('text')
          .attr('class', 'name-text')
          .style('font', FONT_STYLE)
          .attr('x', 4)
          .attr('y', 13)
          .text((d: TimelineData) => d.name);

        if (isEndDateAlert(d)) {
          renderAlertIcon(node, options);
        }
      } else if (d.type === TimelineType.EVENT || isMilestone(d)) {
        // Add rect for each instant data point
        node
          .append('path')
          .attr('class', 'datapoint-svg')
          .attr('d', getInstantPath(d))
          .style('fill', (d: TimelineData) => options.nodeColor(d))
          .style('stroke', (d: TimelineData) => options.strokeColor(d));
        node
          .append('text')
          .attr('class', 'name-text')
          .style('font', FONT_STYLE)
          .attr('x', 10)
          .attr('y', 9)
          .text((d: TimelineData) => d.name);
      }
    });
}

function updateNodes(
  chart: DivSelection,
  timelineXScale: ScaleTime<number, number>,
  dragAndZoomXScale: ScaleTime<number, number>,
  brushXScale: ScaleTime<number, number>,
  options: TimelineOptions
): void {
  const groupId = `#${getGroupSvgId(CHART_GROUP)}`;
  const { dataExpanded } = options;

  // Add svg group for each data point in dataset group to svg
  const datapoints: BaseDataBaseSelection = chart.select(groupId).selectAll('g.datapoint');

  datapoints
    .data(dataExpanded, (d: TimelineData) => d.id)
    .join(
      function enter(enter) {
        return renderNodes(enter, chart, timelineXScale, dragAndZoomXScale, brushXScale, options);
      },
      function update(update) {
        updateNodeInternal(chart, timelineXScale, options);
        return update;
      },
      function exit(exit) {
        return exit.remove();
      }
    );
}

function updateNodeInternal(
  chart: DivSelection,
  timelineXScale: ScaleTime<number, number>,
  options: TimelineOptions
): void {
  const groupId = `#${getGroupSvgId(CHART_GROUP)}`;

  const { today } = options;

  const svg = chart.select(groupId);

  const svgGroups: SVGGDataBaseSelection = chart.select(groupId).selectAll('g.datapoint');

  // Set x,y for each group data point
  svgGroups.attr(
    'transform',
    (_d: TimelineData, i: number) => `translate(${0}, ${i * ROW_HEIGHT})`
  );

  // Set x,y for each data point (rect - interval, path - event)
  const datapoints: SVGGDataBaseSelection = svgGroups.selectAll('.datapoint-svg');
  datapoints.attr(
    'transform',
    (d: TimelineData, i: number) =>
      `translate(${timelineXScale(new Date(d.start).getTime())}, ${
        i * ROW_HEIGHT + (isInterval(d) ? 3 : 8)
      })`
  );

  // Set x,y for each child data point
  const childDatapoints: SVGGDataBaseSelection = svgGroups.selectAll('.datapoint-child');
  childDatapoints.attr('transform', function (d: TimelineData) {
    // no clue why we need to do this...
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const el: SVGGElement = this;

    const index = Number(el.getAttribute('index'));
    const date = new Date((d.children || [])[index]?.start || d.start).getTime();
    return `translate(${timelineXScale(date)}, ${8}) scale(0.86)`;
  });

  // Set x,y for each expand / collapse element
  const expandElements: BaseDataBaseSelection = svgGroups.selectAll('.expand');
  expandElements.attr(
    'transform',
    (d: TimelineData) =>
      `translate(${timelineXScale(new Date(d.end || today || 0).getTime()) + 7}, ${8})`
  );

  // Set x,y for each alert element
  const alertElements: BaseDataBaseSelection = svgGroups.selectAll('.alert');
  alertElements.attr(
    'transform',
    (d: TimelineData) =>
      `translate(${
        timelineXScale(new Date(isInstantInterval(d, options) ? d.start : today || 0).getTime()) +
        7 +
        (isExpandable(d) ? 11 : 0)
      }, ${8})`
  );

  const getTextTranslateX = (d: TimelineData) =>
    timelineXScale(
      new Date(
        isInterval(d) && !isInstantInterval(d, options) ? d.end || today || 0 : d.start
      ).getTime()
    ) +
    (isExpandable(d) ? 10 : 0) +
    (isEndDateAlert(d) ? 10 : 0);

  // Set x,y for each text element
  const textElements: BaseDataBaseSelection = svgGroups.selectAll('.name-text');
  textElements.attr(
    'transform',
    (d: TimelineData) => `translate(${getTextTranslateX(d)}, ${isInterval(d) ? 0 : 3})`
  );

  // Set width for each interval data point
  const intervalNodes: BaseDataBaseSelection = svg.selectAll('.interval').selectAll('rect');
  intervalNodes.attr(
    'width',
    (d: TimelineData) =>
      timelineXScale(new Date(d.end || today || 0)) - timelineXScale(new Date(d.start))
  );

  updateTooltips(svgGroups, options);
  updateClickHandler(svgGroups, options, chart, timelineXScale);
}

function removeNodes(chart: DivSelection): void {
  const groupId = `#${getGroupSvgId(CHART_GROUP)}`;
  // Remove svg group for each data point in dataset group to svg
  chart.select(groupId).selectAll('g.datapoint').remove();
}

function updateClickHandler(
  svgGroups: SVGGDataBaseSelection,
  options: TimelineOptions,
  chart: DivSelection,
  timelineXScale: ScaleTime<number, number>
): void {
  if (options.click) {
    svgGroups.on('.click', null);
    svgGroups.attr('cursor', 'pointer');
    svgGroups.on('click', function (event: PointerEvent, d: TimelineData) {
      if (options.click) options.click(event, d);
      updateNodeInternal(chart, timelineXScale, options);
    });
  } else {
    svgGroups.attr('cursor', 'auto');
    svgGroups.on('.click', null);
  }
}

export function unload() {
  // Remove if exist
  const element = select('#timeline-tooltip-container');
  if (!element.empty()) {
    element.remove();
  }
}
