import { FC } from 'react';
import { Link } from 'react-router-dom';

import { Typography } from '@material-ui/core';

import {
  excelDownloadEventTypes,
  reportExcelDownload,
} from '../../../../analytics/analyticsEventProperties';
import useSendAnalytics from '../../../../hooks/useSendAnalytics';
import { withStyles } from '../../../../theme/komodo-mui-theme';

import styles from './ExcelHelpPageStyles';
import { ExcelTemplate, ExcelTemplateInfo } from './ExcelHelpPageText';

type ExcelHelpPageComponentProps = {
  classes: Classes<typeof styles>;
  excelTemplate: ExcelTemplate;
};

const ExcelHelpPageComponent: FC<ExcelHelpPageComponentProps> = ({ classes, excelTemplate }) => {
  const sendAnalytics = useSendAnalytics();
  return (
    <div>
      <Link
        className={classes.title}
        download={ExcelTemplateInfo[excelTemplate].downloadName}
        onClick={() =>
          sendAnalytics(
            reportExcelDownload(excelDownloadEventTypes.DOWNLOAD_JOIN_EXCEL_TEMPLATE, {
              excelTemplateType: excelTemplate,
            })
          )
        }
        target="blank"
        to={ExcelTemplateInfo[excelTemplate].file}
      >
        {ExcelTemplateInfo[excelTemplate].title}
      </Link>
      <Typography className={classes.subText}>
        {ExcelTemplateInfo[excelTemplate].subTitle}
      </Typography>
      <img alt={excelTemplate} src={ExcelTemplateInfo[excelTemplate].image} />
    </div>
  );
};

export default withStyles(styles)(ExcelHelpPageComponent);
