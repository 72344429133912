import { ItemsListTotalCostQuery, ItemsListTotalCostQueryVariables } from '../../generated/graphql';
import { useQuery } from '../../hooks/useMountAwareQuery';
import { MountPolicy } from '../../hooks/usePolicyOnFirstMount';
import { useCostMode } from '../../utilities/costMode';

import { itemsListTotalCostQuery } from './hooks/itemsListQuery';
import { ItemsListSettings, useItemsListMilestone } from './ItemsListUtils';

export const useItemsListTotalCostQuery = (
  itemIDs: UUID[] | undefined,
  projectID: UUID,
  viewFilter: ViewFilterInput,
  settings: ItemsListSettings,
  skipQuery?: boolean
) => {
  const { loading, milestoneID } = useItemsListMilestone(settings);
  return useQuery<ItemsListTotalCostQuery, ItemsListTotalCostQueryVariables>(
    itemsListTotalCostQuery,
    {
      variables: {
        itemIDs,
        milestoneID,
        projectID,
        costMode: useCostMode(),
        viewFilter,
      },
      skip: skipQuery || !itemIDs || !itemIDs.length || loading,
    },
    MountPolicy.FETCH_ON_VARS
  );
};
