import { FC } from 'react';

import CommentDisplay from '../../Comments/CommentDisplay/CommentDisplay';

type Props = {
  commentText?: ItemHistoryComment['text'];
  hoveredID?: string | null;
  setHoveredID?: (id: string | null) => void;
  curHoverTimeout?: NodeJS.Timeout | null;
  setCurHoverTimeout?: (timeout: NodeJS.Timeout | null) => void;
};

const ItemHistoryListComment: FC<Props> = ({
  commentText,
  hoveredID,
  setHoveredID,
  curHoverTimeout,
  setCurHoverTimeout,
}) => {
  // Content
  const { contents, mentions, itemReferences } = commentText || {};

  return (
    <div className="whitespace-break-spaces !font-light type-body2" data-cy="item-details-comment">
      <CommentDisplay
        className="text-entities-event"
        curHoverTimeout={curHoverTimeout}
        hoveredID={hoveredID}
        itemReferences={itemReferences ?? undefined}
        mentions={mentions ?? undefined}
        setCurHoverTimeout={setCurHoverTimeout}
        setHoveredID={setHoveredID}
        title={contents}
      />
    </div>
  );
};

export default ItemHistoryListComment;
