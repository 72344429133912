import { useRef } from 'react';
import { useButton } from 'react-aria';

import { Tooltip } from '../../../scales';

type Props = {
  title: string;
  icon: JSX.Element;
  onClick: () => void;
  isDisabled?: boolean;
  isActive?: boolean;
};

export default function MenuItem(props: Props) {
  const ref = useRef<HTMLButtonElement>(null);
  const { buttonProps } = useButton(
    {
      'aria-pressed': props.isActive,
      excludeFromTabOrder: true,
      isDisabled: props.isDisabled,
      onPress: props.onClick,
    },
    ref
  );

  return (
    <Tooltip content={props.title} isDisabled={props.isDisabled} placement="top">
      <button
        {...buttonProps}
        aria-label={props.title}
        className={[
          'h-6 w-6 rounded p-1 icon-sm [&>svg]:block',
          props.isActive && !props.isDisabled
            ? 'bg-selection-selected'
            : 'bg-transparent enabled:hover:bg-selection-hover',
          props.isDisabled ? 'fill-type-inactive text-type-inactive' : 'text-type-primary',
        ].join(' ')}
      >
        {props.icon}
      </button>
    </Tooltip>
  );
}
