import { FC } from 'react';

import { PROJECTS_OVERVIEW } from '../../../constants';
import ExecutiveDashboardChartFrame from '../ExecutiveDashboardChartFrame/ExecutiveDashboardChartFrame';
import { useProjectsOverviewQuery } from '../ExecutiveDashboardHooks';
import {
  CHART_LARGE,
  ExecutiveDashboardDataProps,
  PROJECTS_OVERVIEW_HEIGHT,
  PROJECTS_OVERVIEW_TOOLTIP,
} from '../ExecutiveDashboardUtils';

import ExecutiveDashboardProjectsOverview from './ExecutiveDashboardProjectsOverview';

const ExecutiveDashboardProjectsOverviewData: FC<ExecutiveDashboardDataProps> = ({
  dashboardLoading,
  projectIDs,
}) => {
  const { data: { projectsOverview } = { projectsOverview: null }, loading } =
    useProjectsOverviewQuery(projectIDs);

  return (
    <ExecutiveDashboardChartFrame
      component={
        <ExecutiveDashboardProjectsOverview
          loading={loading || dashboardLoading}
          projectCount={projectIDs.length}
          projectsOverview={projectsOverview}
        />
      }
      height={PROJECTS_OVERVIEW_HEIGHT}
      title={PROJECTS_OVERVIEW}
      tooltip={PROJECTS_OVERVIEW_TOOLTIP}
      variant={CHART_LARGE}
    />
  );
};

export default ExecutiveDashboardProjectsOverviewData;
