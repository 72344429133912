export default (
  <svg
    className="stroke-entities-today"
    fill="none"
    height="11"
    viewBox="0 0 2 11"
    width="2"
    xmlns="http://www.w3.org/2000/svg"
  >
    <line strokeWidth="2" x1="1" x2="1" y2="11" />
  </svg>
);
