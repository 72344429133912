import useProjectPropsQuery from '../../hooks/useProjectPropsQuery';
import { getProjectIdFromUrl } from '../../utilities/url';
import { useLocalStorageParams } from '../../utilities/urlState';

import { SandboxView } from './Sandbox/SandboxUtil';
import Scenarios from './Scenarios';
import {
  FILES_SCENARIO_DEFAULT_SORT,
  getScenariosLocalStorageLocation,
  useGetMilestoneNameFromID,
} from './ScenarioUtils';

export type ScenariosSettings = {
  milestoneID: string;
  showMilestone: boolean;
  sortDirection: string;
  sortKey: string;
  sandboxView: SandboxView;
};

const ScenariosTop = () => {
  // Data
  const projectId = getProjectIdFromUrl();
  const projectPropsQueryResult = useProjectPropsQuery(projectId);
  const activeMilestoneID = projectPropsQueryResult.data.project?.activeMilestone.id;

  // State
  const [settings, setSettings] = useLocalStorageParams<ScenariosSettings>(
    {
      milestoneID: '',
      showMilestone: true,
      sortDirection: FILES_SCENARIO_DEFAULT_SORT.sortDirection,
      sortKey: FILES_SCENARIO_DEFAULT_SORT.sortKey,
      sandboxView: SandboxView.SANDBOX_VIEW_WORK,
    },
    getScenariosLocalStorageLocation(projectId, '')
  );
  const { milestoneID } = settings;

  const onCompleted = ({ milestoneName }: { milestoneName: string }) => {
    const isMilestoneDeprecated = !milestoneName;
    if (isMilestoneDeprecated) {
      setSettings({ ...settings, milestoneID: activeMilestoneID ?? '' });
    }
  };

  const { milestoneName, milestonesLoading } = useGetMilestoneNameFromID(
    projectId,
    milestoneID,
    onCompleted
  );
  const isMilestoneDeprecated = !milestoneName;

  const loading = projectPropsQueryResult.loading || milestonesLoading;

  if (loading || isMilestoneDeprecated) return null;
  return <Scenarios />;
};

export default ScenariosTop;
