import { ItemShareKey } from '../../../../analytics/analyticsEventProperties';
import { Status } from '../../../../generated/graphql';
import { ItemStatusIcon } from '../../../dragon-scales';
import ItemsListItemPrivateBadge from '../../../Items/ItemsListItem/ItemsListItemPrivateBadge/ItemsListItemPrivateBadge';

export type Props = {
  name: string;
  sharedUsers: Pick<User, 'id' | 'name'>[] | undefined;
  status: Status;
};

export default function ShareItemBulkSummaryEntry(props: Props) {
  return (
    <div className="flex items-center gap-1 pb-1 pl-8 pt-1">
      <ItemStatusIcon size="md" value={props.status} />
      <ItemsListItemPrivateBadge
        analyticsKey={ItemShareKey.BULK}
        hideBadge
        sharedPrivateItemUsers={props.sharedUsers}
      />
      <div className="line-clamp-1 type-body1">{props.name}</div>
    </div>
  );
}
