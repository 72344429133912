import { createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
const UserAvatarStyles = (theme: any) =>
  createStyles({
    avatar: {
      height: 32,
      width: 32,
      backgroundColor: theme.palette.joinGrey400,
    },
    noPrint: {
      '@media print': {
        display: 'none',
      },
    },
    deactivated: {
      ...theme.grayscaleFilter,
    },
  });

export default UserAvatarStyles;
