import { gql } from '../../../../../api/graphqlFragments';

export const linkIntegrationsEntityMutation = gql`
  mutation linkIntegrationsEntity(
    $sourceSystem: SourceSystem!
    $sourceEntityType: SourceType!
    $sourceEntityID: String!
    $sourceCompanyID: String
    $projectID: UUID!
  ) {
    linkIntegrationsEntity(
      sourceSystem: $sourceSystem
      sourceEntityType: $sourceEntityType
      sourceEntityID: $sourceEntityID
      sourceCompanyID: $sourceCompanyID
      projectID: $projectID
    )
  }
`;

export const getSourceLinkedProjectQuery = gql`
  query sourceLinkedProject(
    $projectID: UUID!
    $source: SourceSystem!
    $sourceID: UUID
    $sourceUserEmail: String
  ) {
    sourceLinkedProject(
      projectID: $projectID
      source: $source
      sourceID: $sourceID
      sourceUserEmail: $sourceUserEmail
    ) {
      sourceProjectID
      sourceCompanyID
      canCreateItemIntegrationObject
    }
  }
`;
