import { ReactNode } from 'react';

type Props = {
  children?: ReactNode;
  roleName: string;
};

const Header = (props: Props) => {
  return (
    <div className="flex shrink-0 justify-between p-6">
      <div className="flex flex-col gap-2">
        <h1
          className="type-heading1"
          data-cy="div-rolePermissionHeader"
        >{`${props.roleName} Role Permissions`}</h1>
        <div className="type-body1">
          All teammates with this role will inherit the permissions below
        </div>
      </div>
      {props.children}
    </div>
  );
};

export default Header;
