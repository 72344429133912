import { Typography } from '@material-ui/core';

import useHasModuleEntitlement from '../../../hooks/useHasModuleEntitlement';
import { PROJECT_CREATION } from '../../../moduleEntitlementFlagsList';
import { withStyles } from '../../../theme/komodo-mui-theme';
import NewProjectButton from '../../shared-widgets/NewProjectButton/NewProjectButton';

import styles from './NoProjectsPageStyles';

// this component and its accompanying style sheet was created by pulling directly from ProjectsListPage.tsx
const NoProjectsPage = ({ classes }: { classes: Classes<typeof styles> }) => {
  const canCreateProject = useHasModuleEntitlement(PROJECT_CREATION);

  return (
    <div className={classes.emptyList}>
      <div className={classes.ctaContainer}>
        <Typography className={classes.headline} variant="headline">
          Get started with
          <br />
          Join
        </Typography>
        <Typography className={classes.valuePropTitle} variant="body1">
          Streamline your preconstruction decision-making process with more automated calculations
          and insights, powerful presentation capabilities, and increased collaboration.
        </Typography>
        {canCreateProject && <NewProjectButton isLarge />}
      </div>
      <div className={classes.valueProps}>
        <div className={classes.valueProp}>
          <div className={`${classes.iconContainer}`}>
            <img alt="Propose and track ideas" className={classes.icon} src="/img/trending.svg" />
          </div>
          <Typography className={classes.valuePropTitle} variant="body1">
            Automatically track the impact and details for every decision
          </Typography>
        </div>
        <div className={classes.valueProp}>
          <div className={`${classes.iconContainer}`}>
            <img alt="Present with power" className={classes.icon} src="/img/venn.svg" />
          </div>
          <Typography className={classes.valuePropTitle} variant="body1">
            Leverage powerful presentation tools to effectively communicate with stakeholders
          </Typography>
        </div>
        <div className={classes.valueProp}>
          <div className={`${classes.iconContainer}`}>
            <img
              alt="Invite your project team"
              className={classes.icon}
              src="/img/stakeholders.svg"
            />
          </div>
          <Typography className={classes.valuePropTitle} variant="body1">
            Increase transparency and accountability with centralized collaboration
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default withStyles(styles)(NoProjectsPage);
