import { FC } from 'react';

import { List, ListItem } from '@material-ui/core';

import { withStyles } from '../../../../theme/komodo-mui-theme';

import styles from './CategorizationSelectAndOrderStyles';

type CategorizationSelectAndOrderHeaderProps = {
  classes: Classes<typeof styles>;
  count: number;
};

const CategorizationSelectAndOrderHeader: FC<CategorizationSelectAndOrderHeaderProps> = ({
  classes,
  count,
}) => (
  <div>
    We found {count} more WBS Codes in your estimate.
    <List className={classes.list} disablePadding>
      <ListItem className={classes.listItem} dense disableGutters>
        Choose which to import. They will become categorizations in your project.
      </ListItem>

      <ListItem className={classes.listItem} dense disableGutters>
        Rearrange the order by dragging them. This will become the column order of your estimate
      </ListItem>
    </List>
  </div>
);

export default withStyles(styles)(CategorizationSelectAndOrderHeader);
