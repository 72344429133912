import {
  CompanyCollaboratorFilter,
  CompanyCollaboratorsQuery,
  CompanyCollaboratorsQueryVariables,
} from '../../generated/graphql';
import { useQuery } from '../../hooks/useMountAwareQuery';

import { getCompanyCollaboratorsQuery } from './queries';

export const useCompanyCollaboratorsQuery = (
  companyID: UUID | undefined,
  filters: CompanyCollaboratorFilter | undefined,
  userSortBy: CompanyUserSortBy
) =>
  useQuery<CompanyCollaboratorsQuery, CompanyCollaboratorsQueryVariables>(
    getCompanyCollaboratorsQuery,
    {
      variables: { companyID, filters, userSortBy },
      skip: !companyID,
    }
  );
