import {
  SourceLinkedProjectQuery,
  SourceLinkedProjectQueryVariables,
  SourceSystem,
} from '../../../../../generated/graphql';
import { useQuery } from '../../../../../hooks/useMountAwareQuery';

import { getSourceLinkedProjectQuery } from './queries';

export const useGetSourceLinkedProjectQuery = (
  projectID: UUID,
  source?: SourceSystem | null,
  sourceID?: UUID,
  sourceUserEmail?: string
) =>
  useQuery<SourceLinkedProjectQuery, SourceLinkedProjectQueryVariables>(
    getSourceLinkedProjectQuery,
    {
      variables: { projectID, source, sourceID, sourceUserEmail },
      skip: !source,
    }
  );
