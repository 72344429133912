import { Close } from '@material-ui/icons';

import { Button, IconButton, StatusBanner, Tooltip, useBannerVisibility } from '../../scales';

type Props = {
  header: string;
  description: string;
  buttonLabel: string;
  localStorageKey: string;
  onCTA: () => void;
  onDismiss?: () => void;
  onRemindMeLater?: () => void;
};

export default function DismissableStatusBanner(props: Props) {
  const { onDismiss, onRemindMeLater, isHidden } = useBannerVisibility({
    localStorageKey: props.localStorageKey,
    onDismiss: props.onDismiss,
    onRemindMeLater: props.onRemindMeLater,
  });

  if (isHidden) return null;
  return (
    <StatusBanner type="info">
      <div className="flex w-full items-center justify-between">
        <div>
          <div className="type-body1">{props.header}</div>
          <div className="type-body3">{props.description}</div>
        </div>
        <div className="flex items-center gap-2">
          <Button label="Remind me later" onClick={onRemindMeLater} type="tertiary" />
          <Button label={props.buttonLabel} onClick={props.onCTA} type="secondary" />
          <Tooltip content={`Don't show me this again`} placement="bottom">
            <IconButton
              aria-label="Close banner"
              icon={<Close />}
              onClick={onDismiss}
              type="secondary"
            />
          </Tooltip>
        </div>
      </div>
    </StatusBanner>
  );
}
