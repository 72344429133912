import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';

const styles = (theme: KomodoTheme) =>
  createStyles({
    container: {
      ...theme.typography.webHeading3,
      position: 'fixed',
      bottom: 0,
      left: 0,
      right: 0,
      display: 'flex',
      alignItems: 'center',
      gap: '16px',
      padding: 24,
      background: theme.palette.ds.background.primary,
      color: theme.palette.ds.type.primary,
      boxShadow: '0px -4px 8px 0px rgba(0, 0, 0, 0.18)',
    },
  });

export default styles;
