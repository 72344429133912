import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';

const styles = (theme: KomodoTheme) =>
  createStyles({
    label: {
      ...theme.typography.webLabel,
      scale: 1.333, // revert MUI scaling the label down via a `translate` property
      width: 'fit-content', // don't stretch the width based on the above `scale` factor
      color: theme.palette.joinPrimary,
    },
    icon: {
      height: 32,
      width: 32,
    },
    input: {
      ...theme.typography.webParagraph,
      padding: '8px',
    },
    root: {
      backgroundColor: theme.palette.backgroundWhite,
      alignItems: 'center',
    },
    formControlMargin: { 'label + &': { marginTop: '18px' } },
  });

export default styles;
