import { FC } from 'react';

import { Add, DeleteOutline, Edit, Visibility, VisibilityOff } from '@material-ui/icons';

import { ProjectType } from '../../../../../generated/graphql';
import IconMenu from '../../../../Select/SelectMenu/IconMenu';
import { SelectVariants } from '../../../../Select/SelectMenu/SelectStyles';

type IconMenuProjectTypeProps = {
  onAddSubType?: () => void;
  onEditType?: () => void;
  onHideType?: () => void;
  onShowType?: () => void;
  onDeleteType?: () => void;
  projectType: ProjectType | null;
};

const IconMenuProjectType: FC<IconMenuProjectTypeProps> = ({
  onAddSubType,
  onEditType,
  onHideType,
  onShowType,
  onDeleteType,
  projectType,
}) => {
  const term = projectType?.parentID ? 'Subtype' : 'Type';
  const menuOptions = [];

  if (onEditType) {
    menuOptions.push({
      icon: <Edit />,
      name: 'Edit',
      callback: () => {
        onEditType();
      },
    });
  }

  if (onHideType && !projectType?.hidden) {
    menuOptions.push({
      icon: <VisibilityOff />,
      name: `Hide ${term}`,
      callback: () => {
        onHideType();
      },
    });
  } else if (onShowType && projectType?.hidden) {
    menuOptions.push({
      icon: <Visibility />,
      name: `Show ${term}`,
      callback: () => {
        onShowType();
      },
    });
  }

  if (projectType?.parentID === null) {
    if (onAddSubType) {
      menuOptions.push({
        icon: <Add />,
        name: 'Add Subtype',
        callback: () => {
          onAddSubType();
        },
      });
    }
  }

  if (onDeleteType) {
    menuOptions.push({
      icon: <DeleteOutline />,
      name: 'Delete',
      color: 'red',
      callback: () => {
        onDeleteType();
      },
    });
  }

  return <IconMenu options={menuOptions} variant={SelectVariants.UNSTYLED} />;
};

export default IconMenuProjectType;
