import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../theme/komodo-mui-theme';

export default (theme: KomodoTheme) =>
  createStyles({
    containerOuter: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
      fontFamily: theme.typography.fontFamily,
      fontWeight: 500,
    },
  });
