import { useMutation } from '@apollo/client';

import { REFETCH_ORGS } from '../../../../api/refetchSets';
import { CreateOrgMutation, CreateOrgMutationVariables } from '../../../../generated/graphql';
import { createOrgMutation } from '../../../Organizations/hooks/queries';

const useCreateOrgMutation = () => {
  const [createOrg] = useMutation<CreateOrgMutation, CreateOrgMutationVariables>(createOrgMutation);
  return (companyID: UUID) => {
    return createOrg({
      variables: {
        input: {
          companyID,
        },
      },
      refetchQueries: REFETCH_ORGS,
    });
  };
};

export default useCreateOrgMutation;
