import {
  commonCostReportColumnFields,
  gql,
  quantityFields,
} from '../../../../api/graphqlFragments';

export const milestonesCostTrendsBreakdownsQuery = gql`
  query milestonesCostTrendsBreakdownsData(
    $projectID: UUID!
    $columns: [CostReportColumnInput!]!
    $costMode: CostMode!
    $intervalType: IntervalType!
    $viewFilters: [ViewFilter!]!
    $summary: Boolean!
    $milestones: [CostReportMilestoneInput!]!
    $groupByItems: [GroupByItem!]!
  ) {
    milestonesCostTrendsBreakdowns(
      parameters: {
        projectID: $projectID
        columns: $columns
        costMode: $costMode
        intervalType: $intervalType
        viewFilters: $viewFilters
        summary: $summary
        milestones: $milestones
        groupByItems: $groupByItems
      }
    ) {
      segments {
        milestone {
          id
          name
          description
          date
          quantities {
            ...quantityFields
          }
        }
        points {
          dateStart
          dateEnd
          values {
            type
            y
            isChanged
          }
          isVisible
        }
      }
      milestoneCostReports {
        costReportColumns {
          ...commonCostReportColumnFields
        }
      }
      categoryID
    }
  }
  ${quantityFields}
  ${commonCostReportColumnFields}
`;
