import { FC } from 'react';

import { CircularProgress } from '@material-ui/core';

import { ITEM_ACTIVITY_ALL_ACTIVITY } from '../../../actions/actionTypes';
import { analyticsEvent } from '../../../analytics/analyticsEventProperties';
import useSendAnalytics from '../../../hooks/useSendAnalytics';

import { ItemActivityFeedReportType } from './ItemActivityFeedUtils';

type Props = {
  selectedReportType: ItemActivityFeedReportType;
  setReportType: (reportType: ItemActivityFeedReportType) => void;
  isLoading: boolean;
};

const ItemActivityFeedEmptyState: FC<Props> = ({
  selectedReportType,
  setReportType,
  isLoading,
}) => {
  const sendAnalytics = useSendAnalytics();

  const emptyStateMessage =
    selectedReportType === ItemActivityFeedReportType.MY_ACTIVITY ? (
      <div>
        No Item Activity here yet! Assign yourself to Items or be @-mentioned in a comment to see
        activity here, or{' '}
        <button
          className="type-link"
          onClick={() => {
            setReportType(ItemActivityFeedReportType.ALL_ACTIVITY);
            sendAnalytics(analyticsEvent(ITEM_ACTIVITY_ALL_ACTIVITY));
          }}
        >
          switch to All Activity
        </button>
        .
      </div>
    ) : (
      <div>No Item Activity here yet!</div>
    );

  return (
    <div className="mt-2 h-[calc(100vh-240px)] w-full">
      <div className="flex h-full justify-center overflow-hidden">
        <div className="z-0 mt-3 flex h-full w-[837px] max-w-[837px] items-center justify-center bg-background-1">
          <div className="flex h-full max-w-sm content-center items-center text-center type-body1">
            {isLoading ? <CircularProgress /> : emptyStateMessage}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ItemActivityFeedEmptyState;
