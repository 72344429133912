// only edit visibility of items the user created
export const hasPrivateItemEditPermission = (createdByID: UUID, currentUserID: UUID) =>
  createdByID === currentUserID;

export const DISABLE_ITEM_NUMBER_TOOLTIP_NEW = `Draft items cannot have numbers. Publish this item to assign an item number.`;
export const DISABLE_ITEM_STATUS_TOOLTIP_NEW = `Draft items always have a pending status. Publish this item to update the status.`;

export const DISABLE_VISIBILITY_TOGGLE_TOOLTIP = `Options of private items must be private. Convert the parent item to public to update this option's visibility.`;

export const PUBLIC_ITEM_ICON_COPY = 'Public to all collaborators';
export const PRIVATE_ITEM_ICON_COPY = 'Private';
export const PUBLISH_ITEM_LINK_COPY = 'Publish Draft';
export const DRAFT_ITEM_LINK_COPY_NEW = 'Create as draft';
export const PUBLISH_ITEM_ICON_COPY_NEW = 'Published';
export const DRAFT_ITEM_ICON_COPY_NEW = 'Draft';
export const DRAFT_ITEM_TOOLTIP =
  'Draft item has limited availability to the team.  Shareable with select teammates.';
export const PUBLISH_ITEM_TOOLTIP = 'Published item is available to all teammates.';
