import { FC } from 'react';

import { PROJECT_ACTIVE_USERS } from '../../../constants';
import ExecutiveDashboardChartFrame from '../ExecutiveDashboardChartFrame/ExecutiveDashboardChartFrame';
import { useProjectActiveUsersQuery } from '../ExecutiveDashboardHooks';
import ExecutiveDashboardLoadingState from '../ExecutiveDashboardLoadingState/ExecutiveDashboardLoadingState';
import {
  CHART_LARGE,
  ExecutiveDashboardDataProps,
  PROJECT_ACTIVE_USERS_HEIGHT,
  PROJECT_ACTIVE_USERS_TOOLTIP,
} from '../ExecutiveDashboardUtils';

import ExecutiveDashboardProjectActiveUsers from './ExecutiveDashboardProjectActiveUsers';

const ExecutiveDashboardProjectActiveUsersData: FC<ExecutiveDashboardDataProps> = ({
  dashboardLoading,
  projectIDs,
  projectMap,
}) => {
  const { data, loading } = useProjectActiveUsersQuery(projectIDs);
  const projectActiveUsers = data?.projectActiveUsers ?? [];

  return (
    <ExecutiveDashboardChartFrame
      component={
        loading || dashboardLoading ? (
          <ExecutiveDashboardLoadingState />
        ) : (
          <ExecutiveDashboardProjectActiveUsers
            projectActiveUsers={projectActiveUsers}
            projectMap={projectMap}
          />
        )
      }
      height={PROJECT_ACTIVE_USERS_HEIGHT}
      title={PROJECT_ACTIVE_USERS}
      tooltip={PROJECT_ACTIVE_USERS_TOOLTIP}
      variant={CHART_LARGE}
    />
  );
};

export default ExecutiveDashboardProjectActiveUsersData;
