import { gql } from '../../../../../api/graphqlFragments';

export const removeBookmarkFromItemMutation = gql`
  mutation removeBookmarkFromItem($projectID: UUID!, $item: UUID!, $bookmark: UUID!) {
    removeBookmarkFromItem(projectID: $projectID, item: $item, bookmark: $bookmark) {
      id
      name
      kind
      payload
      thumbnail
    }
  }
`;

export const createOrUpdateBookmarkMutation = gql`
  mutation createOrUpdateBookmark($projectID: UUID!, $bookmark: BookmarkInput!) {
    createOrUpdateBookmark(projectID: $projectID, bookmark: $bookmark) {
      id
      kind
      name
      payload
      thumbnail
    }
  }
`;
