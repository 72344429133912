import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';

export default (theme: KomodoTheme) =>
  createStyles({
    clearFilters: {
      color: theme.palette.link,
      cursor: 'pointer',
      display: 'inline-flex',
      paddingLeft: 6,
      textDecoration: 'underline',
    },
  });
