import { createStyles } from '@material-ui/core';

export const CATEGORIZATIONS_WIDTH = 289;
export const QUANTITY_WIDTH = 220;
export const UNIT_WIDTH = 125;
export const REPORT_WIDTH = 122;
export const HEADER_PADDING = 8;
const HEADER_HEIGHT = 23;

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
const styles = (theme: any) =>
  createStyles({
    body: {
      padding: 24,
      display: 'block',
      flexGrow: 1,
      backgroundColor: theme.palette.backgroundWhite,
      borderRight: theme.border.line,
    },
    button: {
      backgroundColor: theme.palette.joinPrimary,
      borderRadius: 20,
      color: 'white',
    },
    container: {
      backgroundColor: theme.palette.backgroundWhite,
      display: 'flex',
      flexDirection: 'column',
      minHeight: 110,
      flex: '0 1 auto',
      flexGrow: 1,
    },
    header: {
      borderBottom: theme.border.line,
      backgroundColor: theme.palette.backgroundWhite,
      padding: '4px 24px',
      height: 48,
      color: theme.palette.primaryGrey,
      alignItems: 'center',
      display: 'flex',
    },
    link: {
      ...theme.typography.link,
      display: 'flex',
      alignItems: 'center',
      gap: '4px',
      marginLeft: 8,
    },
    linkIcon: {
      fontSize: 16,
    },
    manageDialogsButton: {
      height: 32,
      width: 32,
    },
    message: {
      padding: 24,
    },
    nullEstimateContainer: {
      display: 'flex',
      justifyContent: 'left',
      alignItems: 'left',
      flexGrow: 1,
    },
    spacer: {
      flexGrow: 1,
    },
    titleContainer: {
      flexGrow: 1,
      paddingBottom: 12,
    },
    unitHeader: {
      display: 'flex',
      background: theme.palette.backgroundGrey,
      justifyContent: 'space-between',
      paddingLeft: 12,
      borderTop: theme.border.line,
      borderBottom: theme.border.line,
      height: HEADER_HEIGHT,
      alignItems: 'center',
    },
    unitHeaderText: {
      ...theme.typography.h4Medium,
    },
    unitHeaderCategorizations: {
      width: CATEGORIZATIONS_WIDTH + HEADER_PADDING,
    },
    unitHeaderQuantity: {
      width: QUANTITY_WIDTH + HEADER_PADDING,
    },
    unitHeaderReport: {
      minWidth: REPORT_WIDTH,
      textAlign: 'right',
      paddingRight: HEADER_PADDING * 3,
    },
    unitHeaderRight: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    unitHeaderUnit: {
      width: UNIT_WIDTH,
    },
    unitHeaderTitle: {
      minWidth: QUANTITY_WIDTH - HEADER_PADDING,
    },
  });

export default styles;
