import { Dispatch, FC, SetStateAction, useContext, useState } from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';

import { TermKey } from '../../../../api/gqlEnums';
import { MASTERFORMAT_CATEGORIZATION_ID, UNIFORMAT_CATEGORIZATION_ID } from '../../../../constants';
import { withStyles } from '../../../../theme/komodo-mui-theme';
import { isEnumValue } from '../../../../utilities/types';
import { getProjectIdFromUrl } from '../../../../utilities/url';
import { ProjectTermStore } from '../../../ProjectDisplaySettings/TerminologyProvider';

import styles from './NewCategorizationBuiltinMapStyles';
import { BuiltInRadioButtonOptions } from './NewCategorizationsBuiltinMapUtils';
import { useSetDisabledProjectCategorizations } from './useSetDisabledProjectCategorizations';

type NewCategorizationsBuiltInMapRadioButtonsProps = {
  classes: Classes<typeof styles>;
  hasMasterFormat: boolean;
  hasUniFormat: boolean;
  selectedOption: BuiltInRadioButtonOptions;
  setSelectedOption: Dispatch<SetStateAction<BuiltInRadioButtonOptions>>;
};

const NewCategorizationsBuiltInMapRadioButtons: FC<
  NewCategorizationsBuiltInMapRadioButtonsProps
> = ({ classes, hasMasterFormat, hasUniFormat, selectedOption, setSelectedOption }) => {
  const [hasDisabledBuiltins, updateHasDisabledBuiltins] = useState(
    selectedOption === BuiltInRadioButtonOptions.DISABLED
  );
  const projectId = getProjectIdFromUrl();
  const categorizationName: string[] = [];

  const builtInIDs: UUID[] = [];
  if (hasMasterFormat) {
    builtInIDs.push(MASTERFORMAT_CATEGORIZATION_ID);
    categorizationName.push('MasterFormat');
  }
  if (hasUniFormat) {
    builtInIDs.push(UNIFORMAT_CATEGORIZATION_ID);
    categorizationName.push('UniFormat');
  }

  const setDisabledProjectCategprizations = useSetDisabledProjectCategorizations();

  const t = useContext(ProjectTermStore);
  const [openDialog, setOpenDialog] = useState(false);

  const disableDialog = () => (
    <Dialog open={!!openDialog}>
      <IconButton
        aria-label="close"
        className={classes.alignCloseButton}
        onClick={() => {
          setSelectedOption(BuiltInRadioButtonOptions.ENABLED);
          setOpenDialog(false);
        }}
      >
        <Close />
      </IconButton>
      <DialogTitle>
        You are about to disable Built-in {categorizationName.join(' and ')}
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Typography>
          By disabling Built-in {categorizationName.join(' and ')}, the project will not be able to
          use it to:
          <ul className={classes.ul}>
            <li>Filter</li>
            <li>Group</li>
            <li>Categorize Items</li>
            <li>Categorize Milestone {t.titleCase(TermKey.ESTIMATE)}</li>
            <li>Categorize Milestone {t.titleCase(TermKey.TARGET)}</li>
            <li>Give teammates access by trade</li>
          </ul>
          You can change this at any time in the settings page.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          data-cy="close-confirm-dialog"
          onClick={() => {
            // disable built-ins
            setDisabledProjectCategprizations(projectId, builtInIDs, true);
            updateHasDisabledBuiltins(true);
            setOpenDialog(false);
          }}
          variant="contained"
        >
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );

  const enableBuiltinsRadioButtonCopy = hasDisabledBuiltins
    ? 'Re-enable and Map to Built-ins'
    : 'Map to Built-ins';

  return (
    <div>
      <RadioGroup
        classes={{ root: classes.center }}
        onChange={(e) => {
          e.preventDefault();

          if (
            e.target instanceof HTMLInputElement &&
            isEnumValue(BuiltInRadioButtonOptions, e.target.value)
          ) {
            setSelectedOption(e.target.value);
          } else {
            throw Error('onChange triggered without valid value.');
          }
        }}
        row
        value={selectedOption}
      >
        <FormControlLabel
          control={<Radio />}
          data-cy="button-enableBuiltIns"
          label={enableBuiltinsRadioButtonCopy}
          onClick={() => {
            setDisabledProjectCategprizations(projectId, builtInIDs, false);
            updateHasDisabledBuiltins(false);
          }}
          value={BuiltInRadioButtonOptions.ENABLED}
        />
        <Typography>or</Typography>
        <FormControlLabel
          control={<Radio />}
          data-cy="button-disableBuiltIns"
          label="Disable Built-ins"
          onClick={() => setOpenDialog(true)}
          value={BuiltInRadioButtonOptions.DISABLED}
        />
      </RadioGroup>
      {openDialog && disableDialog()}
    </div>
  );
};

export default withStyles(styles)(NewCategorizationsBuiltInMapRadioButtons);
