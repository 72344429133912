import { ReactNode } from 'react';

import { Close } from '@material-ui/icons';

import Button from '../Button/Button';
import IconButton from '../IconButton/IconButton';
import Tooltip from '../Tooltip/Tooltip';

type Props = {
  header: string;
  description: string;
  buttonLabel: string;
  image: ReactNode;
  onCTA: () => void;
  onDismiss: () => void;
  onRemindMeLater: () => void;
};

export default function MarketingBanner(props: Props) {
  return (
    <div className="flex justify-between gap-4 rounded-xl bg-marketing-banner-default-bg p-4">
      <div className="flex flex-grow flex-col justify-between gap-5">
        <div className="flex flex-col gap-2">
          <div className="text-type-primary type-heading2">{props.header}</div>
          <div className="text-type-primary type-body1">{props.description}</div>
        </div>
        <div className="flex gap-2">
          <Button label={props.buttonLabel} onClick={props.onCTA} type="secondary" />
          <Button label="Remind me later" onClick={props.onRemindMeLater} type="tertiary" />
        </div>
      </div>
      <div className="flex flex-grow items-center justify-center">{props.image}</div>
      <div>
        <Tooltip content={`Don't show me this again`} placement="bottom">
          <IconButton
            aria-label="Close banner"
            icon={<Close />}
            onClick={props.onDismiss}
            type="secondary"
          />
        </Tooltip>
      </div>
    </div>
  );
}
