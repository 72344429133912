import { createStyles } from '@material-ui/core';

import { CHART_PADDING } from '../ExecutiveDashboardUtils';

const ExecutiveDashboardActiveUsersStyles = () =>
  createStyles({
    flexContainer: {
      display: 'flex',
      flexDirection: 'column',
      padding: CHART_PADDING,
    },
    picture: {
      fontSize: '0.75rem',
      height: 20,
      width: 20,
    },
    toggleContainer: {
      marginBottom: 6,
      marginTop: -4,
    },
  });

export default ExecutiveDashboardActiveUsersStyles;
