import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../../theme/komodo-mui-theme';
import { actionSection, titleSection } from '../../../Dialogs/DialogsStyles';

const EXTERNAL_PADDING = 16;

export default (theme: KomodoTheme) =>
  createStyles({
    // Todo: use Join Dialog once its easier to use
    action: actionSection(theme),
    assetDialog: {
      width: '80%',
      maxWidth: 'unset',
      height: '80%',
      maxHeight: 'unset',
    },
    content: {
      paddingLeft: 0,
      paddingRight: 0,
      paddingBottom: 0,
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'row',
      height: '100%',
    },
    dragDropContainer: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      paddingLeft: EXTERNAL_PADDING,
      paddingRight: EXTERNAL_PADDING,
    },
    dragging: {
      backgroundColor: theme.palette.backgroundGreen,
      border: theme.border.lineDragDropFocus,
    },
    notDragging: {
      border: theme.border.lineDragDropUnFocus,
    },
    flexShrink: {
      flexShrink: 1,
      overflowY: 'hidden',
      display: 'flex',
      marginRight: EXTERNAL_PADDING,
    },
    rightScroll: {
      overflowY: 'auto',
      overflowX: 'hidden',
      height: '100%',
      width: '100%',
      display: 'flex',
      paddingLeft: EXTERNAL_PADDING,
      paddingRight: EXTERNAL_PADDING,
    },
    titleContainer: titleSection(theme),
    titleText: {
      overflowX: 'hidden',
      lineHeight: 'unset',
      textOverflow: 'ellipsis',
    },
  });
