import { isNodeActive } from '@tiptap/core';
import TapListItem from '@tiptap/extension-list-item';
import { joinPoint } from '@tiptap/pm/transform';

import {
  findListItemPos,
  hasPreviousListItem,
  isAtEndOfNode,
  isAtStartOfNode,
  listItemHasSubList,
  nextListIsDeeper,
  nextListIsHigher,
} from './helpers';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    listItem: {
      joinListItemForward: () => ReturnType;
      joinListItemBackward: () => ReturnType;
    };
  }
}

// this fork of List Item corrects backspace issues
// https://github.com/ueberdosis/tiptap/pull/3819
// https://gist.github.com/AdventureBeard/1ae977213f9343221e78e250cc9a67d8

export default TapListItem.extend({
  name: 'listItem',
  defining: true,

  addCommands() {
    return {
      joinListItemBackward:
        () =>
        ({ tr, state, dispatch }) => {
          try {
            const point = joinPoint(state.doc, state.selection.$from.pos, -1);

            if (point === null || point === undefined) {
              return false;
            }

            tr.join(point, 2);

            if (dispatch) {
              dispatch(tr);
            }

            return true;
          } catch {
            return false;
          }
        },

      joinListItemForward:
        () =>
        ({ tr, state, dispatch }) => {
          try {
            const point = joinPoint(state.doc, state.selection.$from.pos, +1);

            if (point === null || point === undefined) {
              return false;
            }

            tr.join(point, 2);

            if (dispatch) {
              dispatch(tr);
            }

            return true;
          } catch {
            return false;
          }
        },
    };
  },

  addKeyboardShortcuts() {
    return {
      Enter: () => this.editor.commands.splitListItem(this.name),
      Tab: () => this.editor.commands.sinkListItem(this.name),
      'Shift-Tab': () => this.editor.commands.liftListItem(this.name),
      Delete: ({ editor }) => {
        // if the cursor is not inside the current node type
        // do nothing and proceed
        if (!isNodeActive(editor.state, this.name)) {
          return false;
        }

        // if the cursor is not at the end of a node
        // do nothing and proceed
        if (!isAtEndOfNode(editor.state)) {
          return false;
        }

        // check if the next node is a list with a deeper depth
        if (nextListIsDeeper(this.name, editor.state)) {
          return editor
            .chain()
            .focus(editor.state.selection.from + 4)
            .lift(this.name)
            .joinBackward()
            .run();
        }

        if (nextListIsHigher(this.name, editor.state)) {
          return editor.chain().joinForward().joinBackward().run();
        }

        // check if the next node is also a listItem
        return editor.commands.joinListItemForward();
      },
      Backspace: ({ editor }) => {
        // this is required to still handle the undo handling
        if (this.editor.commands.undoInputRule()) {
          return true;
        }

        // if the cursor is not inside the current node type
        // do nothing and proceed
        if (!isNodeActive(editor.state, this.name)) {
          return false;
        }

        // if the cursor is not at the start of a node
        // do nothing and proceed
        if (!isAtStartOfNode(editor.state)) {
          return false;
        }

        const listItemPos = findListItemPos(this.name, editor.state);

        if (!listItemPos) {
          return false;
        }

        const $prev = editor.state.doc.resolve(listItemPos.$pos.pos - 2);
        const prevNode = $prev.node(listItemPos.depth);

        const previousListItemHasSubList = listItemHasSubList(this.name, editor.state, prevNode);

        // if the previous item is a list item and doesn't have a sublist, join the list items
        if (hasPreviousListItem(this.name, editor.state) && !previousListItemHasSubList) {
          return editor.commands.joinListItemBackward();
        }

        // otherwise in the end, a backspace should
        // always just lift the list item if
        // joining / merging is not possible
        return editor.chain().liftListItem(this.name).run();
      },
    };
  },
});
