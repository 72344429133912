import { FC } from 'react';

import { getDateLine } from '../PrintCostReport/PrintSubheader';

const PrintScenariosSandboxSubheader: FC = () => (
  <>
    <div className="print-subheader print-subheader-columns">{getDateLine()}</div>
    <hr className="print-main-divider" />
  </>
);

export default PrintScenariosSandboxSubheader;
