import { useMemo } from 'react';

import { CostReportColumnType } from '../../../../generated/graphql';
import { getCostReportByType } from '../../../CostReport/CostReportUtils';
import { useMilestoneCostReportsQuery } from '../../../Milestone/hooks';

export default function useSummaryCostReport(
  projectID?: UUID,
  milestoneID?: UUID,
  quantity?: Quantity
) {
  const columns = useCostReportColumns(quantity);
  // Uses milestoneCostReportsQuery to get the cost reports for the summary.
  // This is setup included in the REFETCH_NAV refetch set, so update that if
  // the underlying query here is changed.
  const costReport = useMilestoneCostReportsQuery(milestoneID, projectID, {}, columns).data
    ?.milestoneCostReports[0];

  const accepted = getCostReportByType(costReport, CostReportColumnType.ACCEPTED_REPORT);
  const estimate = getCostReportByType(costReport, CostReportColumnType.ESTIMATE_REPORT);
  const remaining = getCostReportByType(costReport, CostReportColumnType.REMAINING_REPORT);
  const running = getCostReportByType(costReport, CostReportColumnType.RUNNINGTOTAL_REPORT);
  const target = getCostReportByType(costReport, CostReportColumnType.TARGET_REPORT);

  return {
    accepted: accepted?.range,
    estimate: estimate?.range,
    gap: remaining?.range,
    running: running?.range,
    target: target?.range,
  };
}

const useCostReportColumns = (quantity?: Quantity) =>
  useMemo(
    () =>
      [
        CostReportColumnType.ACCEPTED_REPORT,
        CostReportColumnType.ESTIMATE_REPORT,
        CostReportColumnType.REMAINING_REPORT,
        CostReportColumnType.RUNNINGTOTAL_REPORT,
        CostReportColumnType.TARGET_REPORT,
      ].map((type) => ({ type, unitID: quantity?.unit.id })),
    [quantity]
  );
