import * as React from 'react';

import { GridController } from '../types';

import { isTotalCell, minColWidthByType } from './size';

const resizeCurrentAndNext = (
  widths: number[],
  dx: number,
  i: number,
  nextMinWidth: number,
  nextWidth: number,
  curMinWidth: number,
  curWidth: number,
  total: number
) => {
  const nextWidths = [...widths];
  // Bound the change to the relevant column so it doesn't go under
  // the minimum width, and fatten the other column to make up the
  // space removed from its friend.
  if (dx > 0) {
    nextWidths[i + 1] = Math.max(nextMinWidth, nextWidth - dx);
    nextWidths[i] = total - nextWidths[i + 1];
  } else {
    nextWidths[i] = Math.max(curMinWidth, curWidth + dx);
    nextWidths[i + 1] = total - nextWidths[i];
  }
  return nextWidths;
};

// Handle resizing by installing a handler that starts triggering re-renders
// when the mouse is pressed down. This tracks the motion of the mouse on the window
// in case we move off of the resizing div itself, and triggers debounced re-renders
// using requestAnimationFrame until the mouse rises, where the window handlers are
// then cleaned up.
export const mouseDown = (
  grid: GridController,
  click: React.MouseEvent,
  i: number,
  isAllocated: boolean,
  isMarkupTotal: boolean
) => {
  const {
    data: { columns },
    colWidths,
    startResizingColumn,
    stopResizingColumn,
    scrollBarWidth,
    resizeColumns,
    visibleWidth,
  } = grid;

  if (!columns[i] || isMarkupTotal) return;
  const widths = colWidths();
  const visibleWidthVal = visibleWidth();

  startResizingColumn(i);
  const startX = click.clientX;
  const nextWidths = [...widths];
  const total = nextWidths.reduce((v, a) => a + v);
  const [curWidth, nextWidth] = widths.slice(i, i + 2);
  const minWidthI = visibleWidthVal - (total - curWidth);
  const sum = curWidth + nextWidth;
  const curMinWidth = minColWidthByType(
    columns[i],
    isTotalCell(columns[i], columns, i),
    scrollBarWidth()
  );
  const nextMinWidth = minColWidthByType(
    columns[i + 1],
    isTotalCell(columns[i + 1], columns, i),
    scrollBarWidth()
  );

  const shiftResize = (dx: number) =>
    resizeCurrentAndNext(widths, dx, i, nextMinWidth, nextWidth, curMinWidth, curWidth, sum);

  // Don't thrash resizes on fractional-pixel moves
  let prevDX = 0;
  const moveHandler = (move: MouseEvent) => {
    requestAnimationFrame(() => {
      const { shiftKey, clientX } = move;
      const dx = clientX - startX;
      if (dx === prevDX) return;
      prevDX = dx;

      // shift key
      if (shiftKey) {
        resizeColumns(shiftResize(dx));
        return;
      }
      // Set new width
      if (dx > 0) {
        const nextWidthI = curWidth + dx;
        nextWidths[i] = nextWidthI;
      } else if (!isAllocated) {
        let nextWidthI = Math.max(curMinWidth, curWidth + dx);
        if (nextWidthI < minWidthI) {
          if (curWidth === minWidthI) {
            resizeColumns(shiftResize(dx));
            return;
          }
          nextWidthI = minWidthI;
        }
        nextWidths[i] = nextWidthI;
      }
      let offset = 2;
      if (isAllocated) offset += 1;
      const isLastBeforeTotal = i === columns.length - offset;
      resizeColumns(nextWidths, isLastBeforeTotal);
    });
  };

  const upHandler = () => {
    window.removeEventListener('mousemove', moveHandler);
    window.removeEventListener('mouseup', upHandler);
    stopResizingColumn(i);
  };

  click.stopPropagation();
  click.preventDefault();
  window.addEventListener('mousemove', moveHandler);
  window.addEventListener('mouseup', upHandler);
};
