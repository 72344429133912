import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';
import { MARGIN, SHADOW } from '../PrintUtils';

const styles = (theme: KomodoTheme) =>
  createStyles({
    content: {
      background: theme.palette.backgroundWhite,
      display: 'flex',
      flexDirection: 'row',
      zIndex: 4,
    },
    page: {
      '@media not print': {
        background: 'white',
        boxShadow: SHADOW,
        outline: `white solid ${MARGIN}px`,
        '-webkit-box-shadow': SHADOW,
      },
    },
    printRoot: {
      alignItems: 'center',
      border: `0px none`,
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      height: 'auto',
      justifyContent: 'center',
      overflowX: 'visible',
      width: 'auto',
    },
  });

export default styles;
