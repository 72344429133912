import { createStyles } from '@material-ui/core';

const styles = () =>
  createStyles({
    content: {
      overflowY: 'auto',
      minHeight: 274,
      textAlign: 'center',
    },
    innerPaper: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden',
    },
    margin: {
      margin: 12,
    },
    textBold: {
      fontWeight: 500,
    },
  });

export default styles;
