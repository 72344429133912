import { FC } from 'react';

import { ScheduleImpactType } from '../../../api/gqlEnumsBe';
import { PermissionResource } from '../../../generated/graphql';
import usePermissions from '../../../utilities/permissions/usePermissions';
import { getScheduleImpactUnits } from '../../../utilities/string';
import {
  SCHEDULE_IMPACT_DEFAULT,
  getScheduleImpactText,
} from '../../shared-widgets/ScheduleImpact';

type PrintItemDetailsScheduleImpactProps = {
  item: ItemDataQueryItem;
  permissions: ReturnType<typeof usePermissions>;
};

const PrintItemDetailsScheduleImpact: FC<PrintItemDetailsScheduleImpactProps> = ({
  item,
  permissions,
}) => {
  const canViewScheduleImpact = permissions.canView(PermissionResource.SCHEDULE_IMPACT, {
    trades: item?.categories || [],
  });

  if (!canViewScheduleImpact) return null;

  const si = item.scheduleImpact ?? SCHEDULE_IMPACT_DEFAULT;
  const [numberText, criticalPathValue] = getScheduleImpactText(si, {
    unit: getScheduleImpactUnits(),
    critical: 'yes',
    noncritical: 'no',
  });

  return (
    <div className="print-table-header-footer print-avoid-page-break">
      <div className="print-table-subtotal-details">
        <div className="print-table-subtotal-details-text">Schedule Impact</div>
        <div className="print-table-subtotal-details-text print-align-right">
          {si.type === ScheduleImpactType.DAYS ? 'Critical path:' : ''}
        </div>
        <div className="print-estimate-footer-text print-cost-text print-padding-left-4">
          {si.type === ScheduleImpactType.DAYS ? criticalPathValue : ''}
        </div>
        <div className="print-estimate-footer-text print-cost-text print-padding-left-16 print-padding-right-5">
          {numberText}
        </div>
      </div>
    </div>
  );
};
export default PrintItemDetailsScheduleImpact;
