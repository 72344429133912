import { ApolloClient } from '@apollo/client';

import {
  MilestoneAttachmentsQuery,
  MilestoneAttachmentsQueryVariables,
} from '../../../generated/graphql';
import { useQuery } from '../../../hooks/useMountAwareQuery';

import { milestoneAttachmentsQuery } from './queries';

export const readMilestoneAttachments = (
  client: ApolloClient<object>,
  id: UUID
): (Asset | RemoteAsset)[] => {
  const data = client.readQuery<MilestoneAttachmentsQuery, MilestoneAttachmentsQueryVariables>({
    query: milestoneAttachmentsQuery,
    variables: { id },
  });
  return (data?.milestoneAttachments || []) as (Asset | RemoteAsset)[];
};

export const writeMilestoneAttachments = (
  client: ApolloClient<object>,
  milestoneAttachments: (Asset | RemoteAsset)[],
  id: UUID
) => {
  client.writeQuery<MilestoneAttachmentsQuery, MilestoneAttachmentsQueryVariables>({
    query: milestoneAttachmentsQuery,
    data: {
      milestoneAttachments,
    },
    variables: { id },
  });
};

function useMilestoneAttachmentsQuery(id?: UUID, canView?: boolean) {
  return useQuery<MilestoneAttachmentsQuery, MilestoneAttachmentsQueryVariables>(
    milestoneAttachmentsQuery,
    {
      variables: { id },
      skip: !id || !canView,
    }
  );
}

export default useMilestoneAttachmentsQuery;
