import { ComponentProps, useContext } from 'react';

import {
  DiscreteColorLegend,
  // @ts-ignore JIRA: CT-224
} from 'react-vis/dist';

import theme from '../../theme/komodo-mui-theme';
import { useLegendLabels } from '../Charts/ChartsEstimate/ChartsEstimateUtils';
import { ProjectTermStore } from '../ProjectDisplaySettings/TerminologyProvider';
import { Switch } from '../scales';
import MilestoneSelect from '../Select/MilestoneSelect';

import SandboxExportPanel from './Sandbox/SandboxExportPanel';
import { SandboxView, isWorkspaceView } from './Sandbox/SandboxUtil';
import SandboxViewModeToggle from './Sandbox/SandboxViewModeToggle';

type Props = {
  milestones: ComponentProps<typeof MilestoneSelect>['milestones'];
  onChangeMilestone: ComponentProps<typeof MilestoneSelect>['onChangeMilestone'];
  onToggleShowMilestone: ComponentProps<typeof Switch>['onChange'];
  sandboxView: SandboxView;
  setSandboxView: (view: SandboxView) => void;
  selectedMilestoneID: ComponentProps<typeof MilestoneSelect>['selectedMilestone'];
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  showMilestone: boolean;
  hasBudget: boolean;
};

export const ScenariosHeader = (props: Props) => {
  const t = useContext(ProjectTermStore);
  const legendLabels = useLegendLabels(t, props.hasBudget);
  return (
    <div className="flex shrink-0 grow-0 items-center gap-6 p-4 pt-3">
      <div className="type-heading1">Scenarios</div>
      <div className="w-[272px]">
        <MilestoneSelect
          milestones={props.milestones}
          onChangeMilestone={props.onChangeMilestone}
          selectedMilestone={props.selectedMilestoneID}
        />
      </div>
      <div className="flex flex-grow items-center">
        <SandboxViewModeToggle
          sandboxView={props.sandboxView}
          setSandboxView={props.setSandboxView}
        />
        <Switch
          checked={props.showMilestone}
          data-cy="switch-show-base-milestone"
          label="Show Base Milestone"
          onChange={props.onToggleShowMilestone}
        />
      </div>
      {!isWorkspaceView(props.sandboxView) && (
        <div className="relative top-[-11px] h-8 overflow-visible">
          <DiscreteColorLegend
            items={legendLabels}
            orientation="horizontal"
            style={{
              fontFamily: theme.typography.fontFamily,
            }}
          />
        </div>
      )}
      {props.selectedMilestoneID && <SandboxExportPanel milestoneID={props.selectedMilestoneID} />}
    </div>
  );
};
