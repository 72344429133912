import useAssetBlobUrlQuery from '../../../assets/hooks/useAssetBlobUrlQuery';

type Props = {
  assetID: UUID | undefined | null;
  onCompleted?: () => void;
};

export default function FileImage(props: Props) {
  const { url } = useAssetBlobUrlQuery(props.assetID, props.onCompleted);
  if (!url) return null;
  return (
    <div
      className="h-10 w-16"
      style={{
        background: `url(${url}) center center / cover no-repeat`,
      }}
    />
  );
}
