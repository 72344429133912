import {
  ForecastingProjectsSortKey,
  SearchProjectsQuery,
  SearchProjectsQueryVariables,
  SearchResultType,
  SortDirection,
} from '../../../../../generated/graphql';
import { useQuery } from '../../../../../hooks/useMountAwareQuery';

import { searchProjectsIds } from './queries';

const variables: SearchProjectsQueryVariables = {
  filters: {
    companies: [],
    estimateCostRange: {},
    locations: [],
    milestoneDateRange: {},
    milestoneDesignPhases: [],
    projectTypes: [],
    statuses: [],
    types: [],
    projectLeadIDs: ['00000000-0000-0000-0000-000000000000'],
  },
  searchResultType: SearchResultType.ALL,
  search: '',
  sortBy: {
    sortDirection: SortDirection.SORT_DESCENDING,
    sortKey: ForecastingProjectsSortKey.NAME,
  },
  pagination: { offset: 0, limit: 100000 },
};

export const useProjectsWithoutLeadsQuery = (skip?: boolean) =>
  useQuery<SearchProjectsQuery, SearchProjectsQueryVariables>(searchProjectsIds, {
    variables,
    skip,
  });
