import {
  detailedMilestoneFields,
  estimateTotalTypeConflictInfoFields,
  gql,
} from '../../../api/graphqlFragments';

export const createMilestoneDraftEstimateFromPreviousMilestoneMutation = gql`
  mutation createMilestoneDraftEstimateFromPreviousMilestone(
    $projectID: UUID!
    $currentMilestoneID: UUID!
    $currentEstimateType: EstimateType!
    $previousMilestoneID: UUID!
    $previousEstimateType: EstimateType!
    $isRunningTotal: Boolean!
    $incorporateAccepted: Boolean!
  ) {
    createMilestoneDraftEstimateFromPreviousMilestone(
      projectID: $projectID
      currentMilestoneID: $currentMilestoneID
      currentEstimateType: $currentEstimateType
      previousMilestoneID: $previousMilestoneID
      previousEstimateType: $previousEstimateType
      isRunningTotal: $isRunningTotal
      incorporateAccepted: $incorporateAccepted
    ) {
      ...detailedMilestoneFields
    }
  }
  ${detailedMilestoneFields}
`;

export const createMilestoneDraftEstimateMutation = gql`
  mutation createMilestoneDraftEstimate(
    $projectID: UUID!
    $milestoneID: UUID!
    $estimateType: EstimateType!
    $estimateTotalType: EstimateTotalType!
  ) {
    createMilestoneDraftEstimate(
      projectID: $projectID
      milestoneID: $milestoneID
      estimateType: $estimateType
      estimateTotalType: $estimateTotalType
    ) {
      ...detailedMilestoneFields
    }
  }
  ${detailedMilestoneFields}
`;

export const getEstimateTotalTypeConflictInfo = gql`
  query estimateTotalTypeConflictInfo($projectID: UUID!, $milestoneID: UUID!, $estimateID: UUID!) {
    estimateTotalTypeConflictInfo(
      projectID: $projectID
      milestoneID: $milestoneID
      estimateID: $estimateID
    ) {
      ...estimateTotalTypeConflictInfoFields
    }
  }
  ${estimateTotalTypeConflictInfoFields}
`;
