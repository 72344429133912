import { createStyles } from '@material-ui/core';

import theme, { KomodoTheme } from '../../../theme/komodo-mui-theme';

// TODO: move to theme, rectify with existing icon sizes
const smallIcon = theme.iconSizes.small;
const medIcon = theme.iconSizes.compact;
const largeIcon = 32;
const xLargeIcon = theme.iconSizes.medium;

const ItemsIconsStyles = (theme: KomodoTheme) => {
  const {
    palette: {
      accepted,
      pending,
      rejected,
      incorporated,
      shadedGrey,
      notapplicable,
      primaryBlue,
      budget,
      hiddenGrey,
    },
  } = theme;
  return createStyles({
    // icon size classes
    small: {
      width: smallIcon,
      height: smallIcon,
    },
    medium: {
      width: medIcon,
      height: medIcon,
    },
    large: {
      width: largeIcon,
      height: largeIcon,
    },
    xLarge: {
      width: xLargeIcon,
      height: xLargeIcon,
    },
    // icon color classes
    accepted: {
      color: accepted,
    },
    pending: {
      color: pending,
    },
    rejected: {
      color: rejected,
    },
    incorporated: {
      color: incorporated,
    },
    notapplicable: {
      color: notapplicable,
    },
    notChosen: {
      color: shadedGrey,
    },
    default: {
      color: primaryBlue,
    },
    target: {
      color: budget,
    },
    trendingPending: {
      width: smallIcon,
      height: smallIcon,
      color: pending,
    },
    disabledState: {
      color: theme.palette.disabledGrey,
    },
    hidden: {
      width: smallIcon,
      height: smallIcon,
      color: hiddenGrey,
    },
    statusContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      alignContent: 'center',
      alignSelf: 'center',
    },
  });
};

export default ItemsIconsStyles;
