import { useMutation } from '@apollo/client';

import {
  MutationRemoveMarkupsArgs,
  RemoveMarkupsMutationVariables,
} from '../../../generated/graphql';

import { removeContingencyMutation } from './removeContingencyMutation';

export function useRemoveContingency() {
  const [removeMarkups] = useMutation<MutationRemoveMarkupsArgs, RemoveMarkupsMutationVariables>(
    removeContingencyMutation
  );
  return (
    projectID: UUID,
    estimateID: UUID,
    markupIDs: UUID[],
    viewFilter?: ViewFilter,
    onSuccess?: () => void,
    analytics?: () => void
  ) => {
    return removeMarkups({
      variables: { projectID, estimate: estimateID, markupIDs, viewFilter },
    }).then(() => {
      if (onSuccess) onSuccess();
      if (analytics) analytics();
    });
  };
}
