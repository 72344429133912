import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../theme/komodo-mui-theme';

export default (theme: KomodoTheme) =>
  createStyles({
    action: {
      marginTop: 0,
      marginRight: -16,
    },
    button: {
      backgroundColor: theme.palette.joinPrimary,
      borderRadius: 20,
      color: 'white',
    },
    card: {
      backgroundColor: 'white',
      border: theme.border.line,
      marginBottom: 24,
      marginTop: 8,
      overflow: 'visible',
    },
  });
