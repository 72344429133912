import { createStyles } from '@material-ui/core/styles';

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
const styles = (theme: any) =>
  createStyles({
    icon: {
      color: theme.palette.disabledGrey,
      fontSize: 16,
    },
  });

export default styles;
