import { FC } from 'react';

import { Launch } from '@material-ui/icons';

import {
  CompanyAdminEventType,
  CompanyAdminView,
  companyAdminAnalyticsEvent,
} from '../../../../analytics/analyticsEventProperties';
import useSendAnalytics from '../../../../hooks/useSendAnalytics';
import CompanyTabSearch from '../../CompanyTabSearch';
import { CompanyCategorizationsSearchManager } from '../../CompanyTabUtils';

import List from './List';
import NewCompanyCategorization from './NewCompanyCategorization';

type CompanyCategorizationsProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  companyCategorizations: any; // update type later
  searchManager: CompanyCategorizationsSearchManager;
  companyID?: UUID;
};

const CompanyCategorizations: FC<CompanyCategorizationsProps> = ({
  companyCategorizations,
  searchManager,
  companyID,
}) => {
  const sendAnalytics = useSendAnalytics();
  const hasCategorizations = companyCategorizations && companyCategorizations.length !== 0;

  return (
    <>
      <div className="flex flex-col overflow-y-auto overflow-x-hidden border-b">
        <div className="sticky flex p-4">
          <div className="flex w-full items-center justify-between">
            <div className="flex flex-col">
              <div className="flex type-heading2">Categorizations</div>
              <div className="flex text-type-muted type-body2">
                Create a set of standard categorizations that all your projects will be able to
                access. These are helpful in your projects to group, sort, and filter costs and
                items. Setting up categorizations here will ensure consistency across your
                organization and dramatically speed up project setup time; any new projects for your
                company will automatically start with these categorizations below.
              </div>
            </div>
          </div>
          <div className="flex items-center">
            <NewCompanyCategorization companyID={companyID} />
          </div>
        </div>
        <div className="flex items-center px-4 pb-4">
          <CompanyTabSearch
            page={CompanyAdminView.STANDARD_CATEGORIZATIONS}
            searchManager={searchManager}
            variant="categorizations"
          />
        </div>
      </div>
      {!hasCategorizations && (
        <div className="border-b pb-4">
          <div className="flex flex-col overflow-y-auto overflow-x-hidden p-4">
            <div className="flex type-body2">Your company doesn’t have custom categorizations.</div>
          </div>
          <div className="flex w-full items-center justify-between">
            <img
              alt="lightbulb"
              className="m-5 w-12"
              src="https://static-assets.join.build/app-images/Lightbulb.svg"
            />
            <div className="flex flex-col">
              <div className="type-body2">
                Standard categorizations are a great way to group, sort, and categorize your cost
                data and items. By creating company standards, new projects can start quickly, while
                improving reporting and consistency across your organization. <p />
                At Join, we support
                <b> Single-Level</b> and <b> Multi-Level</b> categorizations.{' '}
                <li>
                  <b>Single-Level </b>
                  categorizations are a simple flat list of options. A great way to break down your
                  project by priority, or any other type of category.
                </li>
                <li>
                  <b>Multi-Level </b> categorizations have the added benefit of allowing your team
                  to roll up data to different levels, so you can zoom in or out to see granular or
                  high-level views of your data. UniFormat and MasterFormat are great examples for
                  Multi-Level categorizations, where some stakeholders might only want to see a
                  birds-eye view and other stakeholders want to dig into &quot;Level 4&quot;
                  details.
                </li>
              </div>
              <a
                className="flex py-1 align-bottom type-link"
                href="https://success.join.build/en/knowledge/standard-categorizations"
                onClick={() => {
                  sendAnalytics(
                    companyAdminAnalyticsEvent(
                      CompanyAdminEventType.STANDARD_CATEGORIZATION_HELP_CTA
                    )
                  );
                }}
                target="blank"
              >
                <div className="pr-1 type-body2">Learn more about Categorizations</div>
                <Launch className="!text-base" />
              </a>
            </div>
          </div>
        </div>
      )}
      {hasCategorizations && companyID && (
        <List
          companyCategorizations={companyCategorizations}
          companyID={companyID}
          searchManager={searchManager}
        />
      )}
    </>
  );
};

export default CompanyCategorizations;
