import { BULK_EDIT, PREVIEW_ROLE } from '../../../constants';
import { ResourceType } from '../../../generated/graphql';
import useCollaboratorsQuery from '../../../hooks/useCollaboratorsQuery';
import { toIds, toSharedResourceUsers } from '../../../utilities/utilities';
import AssigneeSelect from '../../AssigneeSelect/AssigneeSelect';
import { useGetSharedResource } from '../../ItemsList/ItemsSharing/hooks/useGetShareResourceHook';
import useMemoWrapper from '../../useMemoWrapper';

import { getAssignableCollaborators } from './InputsSelectAssigneeUtils';

type Props = {
  onChange: (collaborator: Collaborator | { user: null }) => void;
  isDraft?: boolean;
  isReset?: boolean;
  itemID?: UUID;
  label?: string;
  projectId: UUID;
  selectedCollaborator: Collaborator | undefined;
  variant?: typeof BULK_EDIT | typeof PREVIEW_ROLE;
};

export default function ItemAssigneeSelect({
  onChange,
  isDraft = false,
  isReset = false,
  itemID,
  label,
  projectId,
  selectedCollaborator,
  variant,
}: Props) {
  const { data, loading } = useCollaboratorsQuery(projectId);
  const collaborators = data?.collaborators ?? [];

  const { data: sharedResourceData, loading: loadingSharedResource } = useGetSharedResource(
    itemID,
    ResourceType.ITEM,
    !isDraft
  );
  const sharedUsersIDs = toIds(toSharedResourceUsers(sharedResourceData));

  const assignableCollaborators = useMemoWrapper(
    getAssignableCollaborators,
    collaborators,
    selectedCollaborator,
    isReset,
    variant,
    isDraft,
    sharedUsersIDs,
    true // includeAssigned
  );

  if (loading || loadingSharedResource) return null;

  const selectedEmail = selectedCollaborator?.user?.email;
  const users = (assignableCollaborators ?? []).map((c) => c.user).filter((u) => u.email);
  const assigneeSelectProps = {
    'aria-label': 'select user',
    'data-cy': 'inputs-assignee-select',
    includeUnassigned: true,
    isDisabled: false,
    label,
    onChange: (value: string | undefined | null) => {
      const collaborator = assignableCollaborators.find((col) => col.user.email === value) ?? {
        user: null,
      };
      onChange(collaborator);
    },
    value: selectedEmail,
  };

  return <AssigneeSelect availableUsers={users} {...assigneeSelectProps} />;
}
