import { FC } from 'react';

import { Edit } from '@material-ui/icons';

import ItemsButtonAddEstimate from '../ItemsButtonAddEstimate/ItemsButtonAddEstimate';
import ItemsButtonAddOption from '../ItemsButtonAddOption/ItemsButtonAddOption';

type Props = {
  canEditCosts: boolean;
  isOption: boolean;
  item: ItemDataQueryItem;
  projectID: UUID;
  setIsAllExpanded: (isExpanded: boolean) => void;
  setOpenDialogAddOption: (option: boolean) => void;
};

const NullEstimateContent: FC<Props> = ({
  canEditCosts,
  isOption = false,
  item,
  projectID,
  setIsAllExpanded,
  setOpenDialogAddOption,
}) => {
  return (
    <div className="flex items-center gap-2 bg-background-2 p-6">
      {canEditCosts && (
        <ItemsButtonAddEstimate
          icon={<Edit />}
          itemId={item.id}
          projectId={projectID}
          setIsAllExpanded={setIsAllExpanded}
        />
      )}
      {!isOption && canEditCosts && <div>or</div>}
      {!isOption && (
        <ItemsButtonAddOption setOpenDialogAddOption={setOpenDialogAddOption} type="secondary" />
      )}
    </div>
  );
};

export default NullEstimateContent;
