import { useParams } from 'react-router-dom';

import RoleEdit from './RoleEdit';
import { useRoles } from './RolesRoute';

const RoleEditRoute = () => {
  const { projectId, roleId } = useParams();
  if (!projectId) {
    throw new Error('Failed to get projectId param');
  }
  if (!roleId) {
    throw new Error('Failed to get roleId param');
  }

  const roles = useRoles();

  return <RoleEdit projectID={projectId} roleID={roleId} roles={roles} />;
};

export default RoleEditRoute;
