import { FC, useState } from 'react';

import { Checkbox, Typography } from '@material-ui/core';

import { withStyles } from '../../../theme/komodo-mui-theme';
import { styleItemNumber } from '../../Items/ItemsListItem/ItemsListItemUtils';
import ItemsIconsMap from '../../ItemsList/ItemsIcons/ItemsIconsMap';

import MilestoneDraftModalStyles from './MilestoneDraftModalStyles';

type SelectableSourceItemLineProps = {
  classes: Classes<typeof MilestoneDraftModalStyles>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  sourceItemInfo: any;
  setSelectedItemIDs: (itemID: string) => void;
};

const SelectableSourceItemLine: FC<SelectableSourceItemLineProps> = ({
  classes,
  sourceItemInfo,
  setSelectedItemIDs,
}) => {
  const [isSelected, toggleCheckbox] = useState(false);
  const itemInfo = `${styleItemNumber(sourceItemInfo?.number)}: ${sourceItemInfo?.name}`;

  return (
    <div className={classes.itemContainer}>
      <Checkbox
        checked={isSelected}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
        onClick={(e: any) => {
          e.stopPropagation();
          toggleCheckbox(!isSelected);
          setSelectedItemIDs(sourceItemInfo.id);
        }}
      />
      <span className={classes.itemStatus}>
        <ItemsIconsMap status={sourceItemInfo?.status} variant="statusContainer" />
      </span>
      <Typography>{itemInfo}</Typography>
    </div>
  );
};

export default withStyles(MilestoneDraftModalStyles)(SelectableSourceItemLine);
