import { DeleteOutline } from '@material-ui/icons';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';

import { setEditingCategorizationAnalytics } from '../../../analytics/analyticsEventProperties';
import { CategorizationDialogType } from '../../../api/gqlEnums';
import { FieldType } from '../../../api/gqlEnumsBe';
import { ADD_COLUMN, DELETE_COLUMN } from '../../../constants';
import { EstimateTotalType } from '../../../generated/graphql';
import theme from '../../../theme/design-system-mui-theme';
import { themeProps } from '../../../theme/komodo-mui-theme';
import { removeYear } from '../../../utilities/string';
import { MenuOption } from '../../Select/SelectMenu/SelectOption';

import { MenuOptionsProps, getCategoriesMissingLabel, getErrors } from './EstimateHeaderUtils';
import { getSubMenuOptions } from './ReplaceCategoryMenu/ReplaceCategoryMenu';

export const getMenuOptions = (menuOptions: MenuOptionsProps) => {
  const {
    addColumn,
    canEditCategory,
    canEditColumns,
    categorization,
    column,
    deleteColumn,
    errorsMap,
    estimateTotalType,
    hasGroups,
    id,
    index,
    isErrorsMode,
    isFirstCategoryColumn,
    isLastAddableColumn,
    isLastCategoryColumn,
    isTotalColumn,
    moveColumn,
    openCategorizationDialog,
    sendAnalytics,
    setDeleteId,
    setEditedCellType,
    setPendingTotalType,
    totalErrors,
  } = menuOptions;
  const options: MenuOption[] = [];
  const isEditDisabled =
    !canEditCategory || categorization?.builtin || !!categorization?.createdFrom || false;
  const categorizationName =
    categorization && removeYear(categorization.name, categorization?.builtin);
  const tooltipBuiltIn = categorization?.builtin
    ? `You don't have permission to edit ${categorizationName} because it's a built-in categorization.`
    : undefined;
  const toolTipStandard = categorization?.createdFrom
    ? `You don't have permission to edit ${categorizationName} because it's a standard categorization`
    : undefined;
  const tooltipEdit = !canEditCategory
    ? `You don't have permission to edit ${categorizationName}.`
    : undefined;
  const tooltip = tooltipEdit || tooltipBuiltIn || toolTipStandard || undefined;
  const isDescriptionColumn = column.name === 'Description' && column.type === FieldType.STRING;

  if (!hasGroups && isErrorsMode && errorsMap) {
    const keys = Array.from(errorsMap.keys());
    const subOptions = getSubMenuOptions(menuOptions);
    if (keys.length > 0) {
      options.push({
        name: `${getErrors(totalErrors)}`,
        color: themeProps.palette.red,
        callback: () => {},
        subText: getCategoriesMissingLabel(keys.length),
        subOptions,
      });
    }
  }

  // We offset by one as ordering is 1-indexed in the backend
  const ordering = index + 1;

  if (
    addColumn &&
    (!hasGroups || (isDescriptionColumn && index === 0)) &&
    (!isTotalColumn || index === 0)
  ) {
    options.push({
      name: 'Add column left',
      callback: () => {
        setEditedCellType(ADD_COLUMN);
        // insert column with current columns ordering
        addColumn(ordering);
      },
      disabled: !canEditColumns,
      icon: <AddIcon />,
    });
  }

  if (addColumn && !hasGroups && !isLastAddableColumn) {
    options.push({
      name: 'Add column right',
      callback: () => {
        setEditedCellType(ADD_COLUMN);
        // insert column after current column
        addColumn(ordering + 1);
      },
      disabled: !canEditColumns,
      icon: <AddIcon />,
    });
  }

  if (canEditCategory && categorization) {
    options.push({
      name: `Edit ${categorizationName}`,
      callback: () => {
        if (categorization.levels > 1) {
          openCategorizationDialog(CategorizationDialogType.MULTILEVEL_EDIT);
        } else {
          openCategorizationDialog(CategorizationDialogType.EDIT);
        }
        sendAnalytics(setEditingCategorizationAnalytics(categorization.id, 'Estimate Header'));
      },
      disabled: isEditDisabled,
      tooltip,
      icon: <EditIcon />,
    });
  }

  if (categorization && !isFirstCategoryColumn) {
    options.push({
      name: `Move this column left`,
      callback: () => moveColumn(index, index - 1),
      disabled: !canEditColumns,
      tooltip: `Move ${categorizationName} column left`,
      icon: <KeyboardBackspaceIcon />,
    });
  }

  if (categorization && !isLastCategoryColumn) {
    options.push({
      name: `Move this column right`,
      callback: () => moveColumn(index, index + 1),
      disabled: !canEditColumns,
      tooltip: `Move ${categorizationName} column right`,
      icon: <KeyboardBackspaceIcon style={{ transform: 'rotate(180deg)' }} />,
    });
  }

  if (deleteColumn && categorization && canEditColumns) {
    options.push({
      color: theme.palette.ds.type.delete,
      name: 'Delete column',
      callback: () => {
        setDeleteId(id);
        setEditedCellType(DELETE_COLUMN);
      },
      icon: <DeleteOutline />,
    });
  }

  if (canEditColumns && isTotalColumn) {
    options.push(
      {
        name: 'Enter totals only',
        callback: () => {
          setPendingTotalType(EstimateTotalType.TOTAL_TYPE_TOTAL);
        },
        disabled: estimateTotalType === EstimateTotalType.TOTAL_TYPE_TOTAL,
      },
      {
        name: 'Enter Quantity, Unit, Unit Price',
        callback: () => {
          setPendingTotalType(EstimateTotalType.TOTAL_TYPE_UNIT);
        },
        disabled: estimateTotalType === EstimateTotalType.TOTAL_TYPE_UNIT,
      },
      {
        name: 'Break down by cost type',
        callback: () => {
          setPendingTotalType(EstimateTotalType.TOTAL_TYPE_COST_TYPES);
        },
        disabled: estimateTotalType === EstimateTotalType.TOTAL_TYPE_COST_TYPES,
      }
    );
  }

  return options;
};
