import { CategorizationImportMetadataInput } from '../../../generated/graphql';

export enum CategoriesImportState {
  preview = 'preview',
  loading = 'loading',
  error = 'error',
  single = 'single',
  multi = 'multi',
  none = 'none',
}
export type CategoryMapInput = {
  code: string;
  description: string;
  level: number;
  subcategories: CategoryMapInput[];
};
export type CreateCategorizationInput = {
  name?: string;
  description?: string;
  categoryMaps?: CategoryMapInput[];
  companyID?: UUID;
  projectID?: UUID;
  metadata?: CategorizationImportMetadataInput;
  isReplacing?: boolean;
};
