import { VarianceReportsQuery, VarianceReportsQueryVariables } from '../../../generated/graphql';
import { useQuery } from '../../../hooks/useMountAwareQuery';
import { useCostMode } from '../../../utilities/costMode';
import { GetGroupBysInput } from '../../CostReport/hooks/useMilestoneCostReportQuery';

import { varianceReportsQuery } from './queries';

export const useVarianceReportsQuery = (
  projectID: UUID,
  groupBys: DisplayGroupBy[] = [],
  columnSets: VarianceColumnSet[],
  shouldIncludeOwnerCosts = false,
  viewFilter: ViewFilterInput = {}
) =>
  useQuery<VarianceReportsQuery, VarianceReportsQueryVariables>(varianceReportsQuery, {
    variables: {
      columnSets,
      groupByItems: GetGroupBysInput(groupBys),
      costMode: useCostMode(),
      shouldIncludeOwnerCosts,
      projectID,
      viewFilter,
    },
    skip: !columnSets?.length,
  });
