import { Button, Dialog, DialogContent } from '../../scales';

export type Props = {
  isOpen: boolean;
  itemsCount: number;
  milestone: Pick<Milestone, 'id' | 'name'>;
  onClose: () => void;
};

export default function DeleteMilestoneItemsDialog(props: Props) {
  return (
    <Dialog
      footerRight={<Button label="OK" onClick={props.onClose} type="primary" />}
      isOpen={props.isOpen}
      onClose={props.onClose}
      title="Further Action Required"
    >
      <DialogContent>
        <div>
          To delete {props.milestone.name}, all <b>items ({props.itemsCount})</b> need to be
          addressed.
        </div>
        <br />
        <div>
          <b>Action Required:</b>
          <div>Move existing items ({props.itemsCount}) to another milestone.</div>
        </div>
        <br />
        <div>
          <b>Note:</b> Deleting {props.milestone.name} will permanently remove all related scenarios
          and scenario items.
        </div>
      </DialogContent>
    </Dialog>
  );
}
