import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';

import { PIE_CHART_LARGE, PIE_CHART_OFFSET, PIE_CHART_SMALL } from './ChartsPieChartUtils';

const ChartsPieChartStyles = (theme: KomodoTheme) =>
  createStyles({
    flexContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    blockContainer: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      flexDirection: 'column',
      margin: 'inherit',
    },
    placeholderSmall: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      background: theme.palette.disabledGrey,
      height: PIE_CHART_SMALL - PIE_CHART_OFFSET,
      width: PIE_CHART_SMALL - PIE_CHART_OFFSET,
      borderRadius: '50%',
      margin: 14,
    },
    placeholderLarge: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      background: theme.palette.disabledGrey,
      height: PIE_CHART_LARGE - PIE_CHART_OFFSET,
      width: PIE_CHART_LARGE - PIE_CHART_OFFSET,
      borderRadius: '50%',
      margin: 14,
    },
    placeholderText: {
      ...theme.typography.label,
      color: theme.palette.backgroundWhite,
      alignSelf: 'unset',
      margin: 0,
    },
  });

export default ChartsPieChartStyles;
