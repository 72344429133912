import { FORECASTING_REPORTS_PROJECT_COUNT } from '../../../../tagConstants';
import { withStyles } from '../../../../theme/komodo-mui-theme';

import styles from './ProjectsCountCellStyles';

export default withStyles(styles)(
  ({ classes, report }: { classes: Classes<typeof styles>; report: ForecastingReport }) => (
    <div>
      <div className={classes.titleText}>Projects</div>
      <div
        className={classes.valueText}
        data-cy={`${FORECASTING_REPORTS_PROJECT_COUNT}-${report.name}`}
      >
        {report.metadata.projectsCount}
      </div>
    </div>
  )
);
