import { useState } from 'react';
import * as React from 'react';

import { Button, Dialog, DialogActions, Divider, IconButton, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';

import { UploadPlatform } from '../../../../api/gqlEnums';
import { withStyles } from '../../../../theme/komodo-mui-theme';
import DialogsStyles from '../../../Dialogs/DialogsStyles';
import SelectFilterMenu, {
  SelectFilterMenuData,
} from '../../../Select/SelectFilterMenu/SelectFilterMenu';

import ExcelHelpPage from './ExcelHelpPage';
import { getHelpContent } from './helpDialogText';

type DialogsHelpImportEstimateProps = {
  classes: Classes<typeof DialogsStyles>;
  defaultFilterKey?: string;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  open: boolean;
  onClose: () => void;
  subHeaderText: string;
};

const selectFilterMenuData = [
  { title: UploadPlatform.EXCEL },
  { title: UploadPlatform.SAGE },
  { title: UploadPlatform.WINEST },
  { title: UploadPlatform.DESTINI },
  { title: UploadPlatform.PROCORE },
] as SelectFilterMenuData[];

const DialogsHelpImportEstimate: React.FC<DialogsHelpImportEstimateProps> = ({
  classes,
  defaultFilterKey = UploadPlatform.EXCEL,
  open,
  onClose,
  subHeaderText,
}) => {
  // Display information for the first item before user  actually clicks
  const [filterKey, setFilterKey] = useState<string>(defaultFilterKey);

  function renderHelpCenterText(link: string) {
    return (
      <span
        className={classes.link}
        onClick={() => {
          window.open(link);
        }}
        onKeyPress={(): void => {
          window.open(link);
        }}
        role="button"
        tabIndex={-1}
      >
        see our Success Hub
      </span>
    );
  }

  const generateContentComponent = (filterKey: string) => {
    if (filterKey === UploadPlatform.EXCEL) {
      return <ExcelHelpPage />;
    }
    const helpContent = getHelpContent(filterKey);
    return (
      <div data-cy={`video-${filterKey}`}>
        {helpContent.video && (
          <iframe
            allowFullScreen
            className={classes.videoBox}
            frameBorder="0"
            src={helpContent.video}
            title="video"
          />
        )}
        {helpContent.image && (
          <div
            style={{
              backgroundImage: helpContent.image,
              backgroundSize: '488px 488px',
              height: 488,
              width: 488,
            }}
          />
        )}
        <div className={classes.dialogTextAll}>
          <br />
          <Typography className={`${classes.dialogHelpText}`}>
            {helpContent.header} {renderHelpCenterText(helpContent.helpCenterLink)}
          </Typography>

          <br />
          <Typography className={`${classes.dialogItalic} ${classes.dialogHelpText}`}>
            {helpContent.hint}
          </Typography>
          {helpContent.bulletPoints.map((b: React.ReactNode, i: number) => (
            <Typography key={filterKey + i.toString()} className={`${classes.dialogHelpText}`}>
              {b}
            </Typography>
          ))}
        </div>
      </div>
    );
  };

  const header = (
    <div className={`${classes.titleContainer}`}>
      <div>
        <Typography style={{ fontWeight: 600 }} variant="title">
          Need Help?
        </Typography>
        <Typography className={classes.subHeader} variant="subheading">
          {subHeaderText}
        </Typography>
      </div>
      <IconButton onClick={onClose} title="Close dialog">
        <Close />
      </IconButton>
    </div>
  );

  const content = (
    <div className={`${classes.content} ${classes.paddingBottom}`}>
      <div className={classes.selectOuterContainer}>
        <SelectFilterMenu
          data={selectFilterMenuData}
          filterKey={filterKey}
          label="Select Platform"
          onClick={setFilterKey}
        />
      </div>
      <div
        className={`${
          filterKey === UploadPlatform.EXCEL ? classes.paddingBottomExcel : classes.paddingBottom
        }`}
      />
      {generateContentComponent(filterKey)}
    </div>
  );

  const footer = (
    <DialogActions className={classes.action}>
      <Button
        color="primary"
        data-cy="button-closeDialog"
        disabled={false}
        onClick={onClose}
        variant="contained"
      >
        Close
      </Button>
    </DialogActions>
  );

  return (
    <Dialog
      classes={{
        paper: classes.dialogPaper,
      }}
      data-cy="dialog-ImportEstimateHelp"
      maxWidth={false}
      onClose={onClose}
      open={open}
    >
      {header}
      <Divider />
      {content}
      <Divider />
      {footer}
    </Dialog>
  );
};

const StyledDialogsHelpImportEstimate = withStyles(DialogsStyles)(DialogsHelpImportEstimate);

export default StyledDialogsHelpImportEstimate;
