import {
  CompanyAdminEventType,
  companyAdminAnalyticsEvent,
} from '../../../analytics/analyticsEventProperties';
import {
  COMPANY_CATEGORIZATIONS,
  COMPANY_PROJECT_TEMPLATES,
  COMPANY_PROJECT_TYPES,
} from '../../../constants';
import useSendAnalytics from '../../../hooks/useSendAnalytics';
import { NamedLink } from '../../../utilities/routes/types';
import { ListDetailComponents } from '../../scales';

type Props = {
  links: NamedLink[];
};

export default function StandardsSidebar({ links }: Props) {
  const sendAnalytics = useSendAnalytics();

  const renderLink = (link: NamedLink) => {
    const onClick = () => {
      switch (name) {
        case COMPANY_CATEGORIZATIONS:
          sendAnalytics(companyAdminAnalyticsEvent(CompanyAdminEventType.CATEGORIZATIONS_CTA));
          break;
        case COMPANY_PROJECT_TEMPLATES:
          sendAnalytics(companyAdminAnalyticsEvent(CompanyAdminEventType.PROJECT_TEMPLATES_CTA));
          break;
        case COMPANY_PROJECT_TYPES:
          sendAnalytics(companyAdminAnalyticsEvent(CompanyAdminEventType.PROJECT_TYPES_CTA));
          break;
        default:
          break;
      }
    };
    const {
      route: { path },
      name,
    } = link || {};
    return <ListDetailComponents.Link key={path} label={name} onClick={onClick} to={path} />;
  };

  return <>{links.map(renderLink)}</>;
}
