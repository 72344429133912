import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';
import { tableStyles } from '../../../theme/table-styles';
import {
  gridTemplateBuiltInColumnsReport,
  gridTemplateColumnsReport,
} from '../ReportsTab/ReportsTabStyles';

export default (theme: KomodoTheme) => {
  const { cell } = tableStyles(theme);
  return createStyles({
    cell: {
      ...cell,
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'flex-start',
      minHeight: 50,
      maxWidth: 'none',
      padding: `0px ${theme.spacing.generic[3]}px 0px 0px`,
      textAlign: 'left',
      '& > button': {
        background: 'none',
        border: 'none',
      },
      '&:first-child': {
        paddingLeft: `${theme.spacing.generic[3]}px`,
      },
    },
    containerRow: {
      backgroundColor: theme.palette.backgroundWhite,
      borderBottom: theme.border.line,
      borderLeft: theme.border.line,
      borderRight: theme.border.line,
      '&:first-of-type': {
        borderTop: theme.border.line,
      },
      display: 'grid',
    },
    containerRowBuiltIn: {
      backgroundColor: theme.palette.backgroundWhite,
      borderBottom: theme.border.line,
      borderLeft: theme.border.line,
      borderRight: theme.border.line,
      display: 'grid',
      '&:first-of-type': {
        borderTop: theme.border.line,
      },
    },
    containerRowBuiltInGrid: {
      gridTemplateColumns: gridTemplateBuiltInColumnsReport,
    },
    containerRowGrid: {
      gridTemplateColumns: gridTemplateColumnsReport,
    },
    inlineFlex: { display: 'inline-flex' },
    info: {
      color: theme.palette.disabledGrey,
    },
    text: {
      ...theme.typography.body1,
      fontSize: 14.4,
      minWidth: 30,
      width: '100%',
    },
    textName: {
      ...theme.typography.title,
      fontSize: 16,
    },
  });
};
