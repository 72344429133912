import { useMutation } from '@apollo/client';

import { REFETCH_SCENARIO_SANDBOX } from '../../../api/refetchSets';
import {
  DeleteScenarioMutation,
  DeleteScenarioMutationVariables,
} from '../../../generated/graphql';

import { deleteScenarioMutation } from './queries';

const useDeleteScenarioMutation = () => {
  const [deleteFn] = useMutation<DeleteScenarioMutation, DeleteScenarioMutationVariables>(
    deleteScenarioMutation,
    {
      refetchQueries: REFETCH_SCENARIO_SANDBOX,
    }
  );

  const deleteScenario = ({ scenarioID }: { scenarioID: UUID }) =>
    deleteFn({
      variables: {
        scenarioID,
      },
    });

  return [deleteScenario];
};

export default useDeleteScenarioMutation;
