import { isUUID } from 'validator';

import { AssetsQuery, AssetsQueryVariables } from '../../../generated/graphql';
import { useQuery } from '../../../hooks/useMountAwareQuery';

import { assetsQuery } from './queries';

function useAssetsQuery(assetIDs: UUID[] | null) {
  return useQuery<AssetsQuery, AssetsQueryVariables>(assetsQuery, {
    variables: { assetIDs },
    // check that the list of assetIDs exists, is not empty, and that all entries are valid IDs
    skip:
      !assetIDs ||
      !assetIDs.length ||
      assetIDs.filter((assetID) => isUUID(assetID)).length !== assetIDs.length,
  });
}

export default useAssetsQuery;
