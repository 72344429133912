import SvgIcon from '@material-ui/core/SvgIcon';

import { IconProps } from '.';

export const Export = (props: IconProps) => (
  <SvgIcon
    {...props}
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M19 19H5V5H12V3H5C3.89 3 3 3.9 3 5V19C3 20.1 3.89 21 5 21H19C20.1 21 21 20.1 21 19V14H19V19Z" />
    <path d="M17.3967 5.49828L17.5008 2.3639L22.7938 7.65686L17.1369 13.3137L17.2416 10.1656C14.9058 9.89463 12.4184 10.6882 10.5517 12.555C9.62536 13.4813 8.96733 14.5628 8.56709 15.704C8.10032 13.0876 8.92743 10.2095 11.0417 8.09527C12.8236 6.31336 15.149 5.45677 17.3967 5.49828Z" />
  </SvgIcon>
);
