import { ForecastingExploreItemsFilters } from '../../../../generated/graphql';

export const activeFiltersCountItems = (filters: ForecastingExploreItemsFilters) => {
  let count = 0;
  const filtersKeys = Object.keys(filters);
  filtersKeys.forEach((key: string) => {
    if (key === 'costImpact') {
      const values = filters[key];
      if (values.min || values.max) {
        count += 1;
      }
    } else if (key !== 'projectLocations' && key !== 'projectTypes' && key !== 'milestoneDate') {
      const values = filters[key as keyof ForecastingExploreItemsFilters];
      count += (values as string[]).length;
    }
  });
  return count;
};
