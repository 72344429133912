//* * Pattern definition for filling in the cost chart area */
export default function CostFillPattern() {
  return (
    <defs>
      <pattern height="4" id="stripes" patternUnits="userSpaceOnUse" width="4">
        <path
          d="M 0, 0 l 5, 5"
          stroke="var(--colors-chart-pending-cost-area)"
          strokeLinecap="square"
        />
      </pattern>
    </defs>
  );
}
