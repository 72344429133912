import { gql } from '@apollo/client';

export const commonProjectSetting = gql`
  fragment commonProjectSetting on ProjectSetting {
    key
    value
    validValues
  }
`;

export const projectSettingsQuery = gql`
  query projectSettings($projectID: UUID!) {
    projectSettings(projectID: $projectID) {
      ...commonProjectSetting
    }
  }
  ${commonProjectSetting}
`;

export const updateProjectSettingMutation = gql`
  mutation updateProjectSetting($projectID: UUID!, $key: String!, $value: String!) {
    updateProjectSetting(projectID: $projectID, key: $key, value: $value) {
      ...commonProjectSetting
    }
  }
  ${commonProjectSetting}
`;

export const commonTerminology = gql`
  fragment commonTerminology on Terminology {
    key
    maxLength
    override
    overrideValue
  }
`;

export const projectTerminologyQuery = gql`
  query projectTerminology($projectID: UUID!) {
    projectTerminology(projectID: $projectID) {
      ...commonTerminology
    }
  }
  ${commonTerminology}
`;

export const updateProjectTerminologyMutation = gql`
  mutation updateProjectTerminology($projectID: UUID!, $key: String!, $overrideValue: String!) {
    updateProjectTerminology(projectID: $projectID, key: $key, overrideValue: $overrideValue) {
      ...commonTerminology
    }
  }
  ${commonTerminology}
`;

export const resetProjectTerminologyMutation = gql`
  mutation resetProjectTerminology($projectID: UUID!, $key: String!) {
    resetProjectTerminology(projectID: $projectID, key: $key) {
      ...commonTerminology
    }
  }
  ${commonTerminology}
`;
