import { useState } from 'react';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import { JoinProjectRoutes } from '../../../api/gqlEnums';
import { MILESTONE_OPTIONS, MILESTONE_OPTIONS_DELETE_MILESTONE } from '../../../tagConstants';
import theme from '../../../theme/design-system-mui-theme';
import { withStyles } from '../../../theme/komodo-mui-theme';
import { generateSharedPath } from '../../../utilities/routes/links';
import { getProjectIdFromUrl } from '../../../utilities/url';
import DeleteMilestoneDataDialog from '../../Milestones/Dialogs/DeleteMilestoneDataDialog';
import IconMenu from '../../Select/SelectMenu/IconMenu';
import { MenuOption } from '../../Select/SelectMenu/SelectOption';
import { useDeleteMilestone } from '../hooks';

import MilestoneSettingsPanelStyles from './MilestoneSettingsPanelStyles';

type MilestoneSettingsPanelProps = {
  canDelete: boolean;
  classes: Classes<typeof MilestoneSettingsPanelStyles>;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  disabled: boolean;
  isActive: boolean;
  isDetails?: boolean;
  milestone: Milestone;
};

const MilestoneSettingsPanel: React.FC<MilestoneSettingsPanelProps> = ({
  canDelete,
  classes,
  disabled,
  isActive,
  isDetails,
  milestone,
}) => {
  const navigate = useNavigate();
  const projectId = getProjectIdFromUrl();
  const { id } = milestone;
  const [deleteMilestoneDialog, setDeleteMilestoneDialog] = useState(false);
  // ACTIONS
  const deleteMilestone = useDeleteMilestone();
  const onDeleteMilestoneApi = () => {
    if (!canDelete) return;
    deleteMilestone(projectId, id, true, () => {
      navigate(generateSharedPath(JoinProjectRoutes.MILESTONES, { projectId }));
    });
  };
  const onDeleteSubmit = () => onDeleteMilestoneApi();

  if (disabled) return null;
  const options: MenuOption[] = [];

  options.push({
    color: theme.palette.ds.type.delete,
    name: 'Delete this Milestone',
    cy: MILESTONE_OPTIONS_DELETE_MILESTONE,
    callback: () => setDeleteMilestoneDialog(true),
  });

  return (
    <div className={isDetails ? classes.header : classes.optionsContainer}>
      <IconMenu data-cy={MILESTONE_OPTIONS} id={MILESTONE_OPTIONS} options={options} />
      <DeleteMilestoneDataDialog
        isActiveMilestone={isActive}
        isOpen={deleteMilestoneDialog}
        milestone={milestone}
        onClose={() => setDeleteMilestoneDialog(false)}
        onDelete={onDeleteSubmit}
      />
    </div>
  );
};

export default withStyles(MilestoneSettingsPanelStyles)(MilestoneSettingsPanel);
