import InputsTextAreaStyled from '../../../../Inputs/InputsTextAreaStyled/InputsTextAreaStyled';

type DescriptionInputProps = {
  descriptionStyled: string;
  onChange: (description: string, descriptionStyles: string) => void;
};

const DescriptionInput = ({ descriptionStyled, onChange }: DescriptionInputProps) => {
  return (
    <div className="flex h-full flex-col gap-0.5">
      <div className="type-label">Description</div>
      <InputsTextAreaStyled
        data-cy="item-description-input"
        isEditable
        onChange={onChange}
        placeholder="Describe this item..."
        value={descriptionStyled}
      />
    </div>
  );
};

export default DescriptionInput;
