import {
  LocationEscalationQuery,
  LocationEscalationQueryVariables,
} from '../../../../generated/graphql';
import { QueryHookOptions, useQuery } from '../../../../hooks/useMountAwareQuery';

import { locationEscalationQuery } from './queries';

export const useLocationEscalationQuery = (
  options?: QueryHookOptions<LocationEscalationQuery, LocationEscalationQueryVariables>
) =>
  useQuery<LocationEscalationQuery, LocationEscalationQueryVariables>(
    locationEscalationQuery,
    options
  );
