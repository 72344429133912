import { DeleteOutline } from '@material-ui/icons';

import { Button, Dialog, DialogContent } from '../../scales';

export const DeleteScenarioDialog = (props: {
  isOpen: boolean;
  milestoneName: string;
  onClose: () => void;
  onDelete: () => void;
  scenarioName: string;
}) => {
  return (
    <Dialog
      footerRight={
        <Button
          label="Delete"
          onClick={props.onDelete}
          startIcon={<DeleteOutline />}
          type="destructive"
        />
      }
      isOpen={props.isOpen}
      onClose={props.onClose}
      title={`Delete Scenario: ${props.scenarioName}`}
    >
      <DialogContent>
        <div>
          Are you sure? You are deleting this scenario in the <b>{props.milestoneName}</b>{' '}
          milestone.
        </div>
        <br />
        <div>
          This is a permanent action and cannot be undone. This scenario will be deleted for you and
          all teammates it is shared with.
        </div>
      </DialogContent>
    </Dialog>
  );
};
