import { FC, ReactElement } from 'react';

import { Checkbox } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { isSecondPopupVar } from '../../../api/apollo/reactiveVars';
import JoinMultiSelect from '../../Select/JoinMultiSelect/JoinMultiSelect';
import { getEntry } from '../../Select/JoinSelect/JoinSelect';
import InputsChips from '../InputsChips/InputsChips';

import styles from './InputsFilterSelectStyles';

type InputsFilterSelectProps = {
  defaultLabel: string;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  disabled?: boolean;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  displaySelectAll?: boolean;
  filterKey: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  labelFn?: (item: any) => string;
  maxChipsShown?: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  onChange: (evt: any) => void;
  options: string[];
  renderValue?: (val: UUID) => ReactElement | null;
  selected: string[];
};

const InputsFilterSelect: FC<InputsFilterSelectProps> = ({
  defaultLabel,
  disabled = false,
  displaySelectAll = false,
  filterKey,
  labelFn = () => '',
  maxChipsShown = 2,
  onChange,
  options,
  renderValue,
  selected: selectedOuter = [],
}) => {
  const selected = selectedOuter || [];
  const entries = (options || []).map((o) => {
    const checked = selected.includes(o);
    return getEntry(
      o,
      labelFn(o),
      false,
      <Checkbox checked={checked} style={{ width: 24, height: 24 }} />,
      undefined,
      undefined,
      checked
    );
  });

  return (
    <JoinMultiSelect
      cySelect="select_filterSelect"
      disabled={disabled}
      displaySelectAll={displaySelectAll}
      entries={entries}
      menuProps={{
        getContentAnchorEl: null,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        id: 'InputsMultiSelectOption',
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
      }}
      onChange={(v) => onChange({ [filterKey]: v || [] })}
      onClose={() => {
        isSecondPopupVar(false);
      }}
      onOpen={() => {
        isSecondPopupVar(true);
      }}
      renderValue={
        renderValue ||
        ((chosen) => (
          <div>
            <InputsChips
              chosen={chosen}
              defaultChip={defaultLabel}
              labelFn={labelFn}
              max={maxChipsShown}
              showTitle={false}
            />
          </div>
        ))
      }
      value={selected}
    />
  );
};

export default withStyles(styles)(InputsFilterSelect);
