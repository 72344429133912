/**
 * validateDataCy is used to enforce that all scales components require a
 * kebab-case data-cy
 *
 * data-cy string should also include the scales component name as the suffix
 * (i.e. '-select', '-text-input', '-button'), however that enforcement is not
 * required at the moment
 */
export const validateDataCy = (dataCy?: string, enforcedSuffix?: string | string[]) => {
  if (!dataCy) return;
  // validate data-cy in kebab-case
  if (!new RegExp('^([a-z]+[-]?[-]?)*[a-z]+$').test(dataCy))
    throw new Error(`Provided data-cy is not in kebab-case: '${dataCy}'`);
  // validate data-cy ends in suffix
  if (
    enforcedSuffix &&
    typeof enforcedSuffix === 'string' &&
    !new RegExp(`(^|-)${enforcedSuffix}$`).test(dataCy)
  )
    throw new Error(
      `Provided data-cy does not have required suffix: '${dataCy}' must end in '-${enforcedSuffix}'`
    );
  if (
    enforcedSuffix &&
    typeof enforcedSuffix !== 'string' &&
    !new RegExp(`(^|-)${`(${enforcedSuffix.join('|')})`}$`).test(dataCy)
  )
    throw new Error(
      `Provided data-cy does not have required suffix: '${dataCy}' must end in one of [${enforcedSuffix
        .map((suffix) => `'-${suffix}'`)
        .join(', ')}]`
    );
};
