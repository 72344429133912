import { useEffect, useState } from 'react';

function useDetectBrowserPrint(onPrintCallback, afterPrintCallback) {
  const [printing, setPrinting] = useState(false);

  useEffect(() => {
    const printMediaQuery = window && window.matchMedia && window.matchMedia('print');
    if (!printMediaQuery) return () => {};

    const mediaQueryEvent = (mediaList) => {
      const isPrinting = !!mediaList.matches;
      setPrinting(isPrinting);
      if (isPrinting && onPrintCallback) {
        onPrintCallback();
      }
      if (!isPrinting && afterPrintCallback) {
        afterPrintCallback();
      }
    };

    printMediaQuery.addListener(mediaQueryEvent);
    return () => {
      printMediaQuery.removeListener(mediaQueryEvent);
    };
  }, [onPrintCallback, afterPrintCallback]);

  return printing;
}

export default useDetectBrowserPrint;
