import { createStyles } from '@material-ui/core/styles';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';

const styles = (theme: KomodoTheme) =>
  createStyles({
    bold: {
      fontWeight: 400,
    },
    milestoneLink: {
      ...theme.linkFocus,
      '&:hover': {
        color: theme.palette.link,
      },
      borderRadius: theme.border.radius,
      cursor: 'pointer',
      textDecoration: 'underline',
      display: 'inline-block',
    },
    milestoneLinkDisabled: {
      display: 'inline-block',
    },
    root: {
      display: 'inline',
    },
  });

export default styles;
