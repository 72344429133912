import { useCallback } from 'react';

import { useMutation } from '@apollo/client';

import { refetchChangeProjectMetadata } from '../api/refetchSets';
import { UpdateProjectMutation, UpdateProjectMutationVariables } from '../generated/graphql';

import { updateProjectMutation } from './queries';

export default function useUpdateProject() {
  const [updateProjectFunc] = useMutation<UpdateProjectMutation, UpdateProjectMutationVariables>(
    updateProjectMutation
  );

  return useCallback(
    (project: ProjectInput) =>
      updateProjectFunc({
        variables: {
          ...project,
          projectID: project.id,
          activeMilestoneID: project.activeMilestoneID ? project.activeMilestoneID : null,
        },
        refetchQueries: refetchChangeProjectMetadata(project.id),
      }),
    [updateProjectFunc]
  );
}
