import { useState } from 'react';

import { AccessTime } from '@material-ui/icons';

import {
  projectCompsAnalyticsEvent,
  projectCompsEventTypes,
} from '../../../analytics/analyticsEventProperties';
import useSendAnalytics from '../../../hooks/useSendAnalytics';
import { withStyles } from '../../../theme/komodo-mui-theme';
import { formatCommas } from '../../../utilities/currency';
import { EMPTY_DATE, parseDate } from '../../../utilities/dates';
import JoinDialog from '../../Dialogs/JoinDialog/JoinDialog';
import { Thumbnail } from '../../dragon-scales';
import MilestoneDatePicker from '../../Milestone/MilestoneDatePicker/MilestoneDatePicker';
import NormalTooltipInfoIcon from '../../NormalTooltip/NormalTooltipInfoIcon';

import styles from './EditTimeDialogStyles';
import { useTimeEscalationQuery } from './hooks/useTimeEscalationQuery';
import Warning from './Warning';

const EditTimeDialog = (props: {
  classes: Classes<typeof styles>;
  dateFrom?: string;
  dateTo: string;
  onApply: (date: string) => void;
  onClose: () => void;
  project: {
    name: string;
    thumbnail: string;
  };
}) => {
  const { classes } = props;

  const sendAnalytics = useSendAnalytics();

  const [date, setDate] = useState<string | undefined | null>(props.dateFrom);

  const { data: { timeEscalation } = {}, loading } = useTimeEscalationQuery({
    variables: {
      from: date!, // Will be defined if `skip` is false
      to: props.dateTo,
    },
    skip: !date || !props.dateTo,
  });

  const percentage = timeEscalation?.percentage;
  const fromIndex = timeEscalation?.from.index;
  let escalateTo = '...';
  if (timeEscalation?.to) {
    escalateTo = `${timeEscalation.to.year} Q${timeEscalation.to.quarter}`;
  }

  return (
    <JoinDialog
      dialog={{
        actionButtons: [
          {
            color: 'secondary',
            dataCy: 'cancel-button',
            onClick: props.onClose,
            text: 'Cancel',
            variant: 'flat',
          },
          {
            color: 'primary',
            dataCy: 'apply-button',
            disabled: !date || percentage === undefined,
            onClick: () => {
              if (date) {
                props.onApply(date);
              }
            },
            text: 'Apply',
            variant: 'contained',
          },
        ],
        contentComponent: (
          <div className={classes.container}>
            {!date ? (
              <Warning dataCy="no-date-warning">
                <div>Sorry, we cannot calculate time escalation without a milestone date.</div>
                <div>Please select a date from the dropdown below to proceed.</div>
              </Warning>
            ) : null}
            <div className={classes.dateTo}>
              All projects escalating to: <strong>{escalateTo}</strong>
            </div>
            <div className={classes.projectInfo} data-cy="project-info">
              <div>
                <div>{props.project.name}</div>
                <div className={classes.projectInfo_time}>
                  <div>
                    <AccessTime />
                  </div>
                  <div>{parseDate(props.dateFrom) ?? EMPTY_DATE}</div>
                </div>
              </div>
              <Thumbnail size={50} thumbnail={props.project.thumbnail} />
            </div>
            <div className={classes.dateFrom}>
              <MilestoneDatePicker
                date={date}
                disabled={loading}
                label="Project date index"
                onUpdate={(date) => {
                  setDate(date);

                  sendAnalytics(
                    projectCompsAnalyticsEvent(
                      projectCompsEventTypes.PROJECT_COMPS_ESCALATION_CHANGE_DATE,
                      {
                        datePrev: props.dateFrom,
                        dateNew: date,
                      }
                    )
                  );
                }}
              />
              <div>
                <div className="flex flex-col gap-0.5">
                  <div className="text-type-primary type-label">
                    RSMeans factor&nbsp;
                    <NormalTooltipInfoIcon title="Costs are updated by applying Gordian’s RSMeans Historical Cost Index to the original project cost." />
                  </div>
                </div>
                <div className="flex h-10 items-center" data-cy="escalation-factor">
                  {fromIndex}
                </div>
              </div>
              <div className="flex flex-col gap-0.5">
                <div className="text-type-primary type-label">% escalation</div>
                <div className="flex h-10 items-center" data-cy="escalation-percentage">
                  {date && percentage !== undefined ? `${formatCommas(String(percentage))}%` : '--'}
                </div>
              </div>
            </div>
            {timeEscalation && percentage === undefined ? (
              <div className={classes.error}>
                Failed to fetch escalation for this date - try an earlier one.
              </div>
            ) : null}
          </div>
        ),
        headerText: 'Edit time factor for this project',
        onClose: props.onClose,
        open: true,
      }}
      dynamicHeight
    />
  );
};

export default withStyles(styles)(EditTimeDialog);
