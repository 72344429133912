import { useQuery } from '@apollo/client';

import {
  ProgramCostTrendlineQuery,
  QueryProgramCostTrendlineArgs,
} from '../../../../generated/graphql';
import { programCostTrendlineQuery } from '../../queries';

export const useProgramCostTrendlineQuery = (programID: UUID, includedProjectIDs: UUID[]) => {
  return useQuery<ProgramCostTrendlineQuery, QueryProgramCostTrendlineArgs>(
    programCostTrendlineQuery,
    {
      variables: { programID, includedProjectIDs },
      skip: !programID,
    }
  );
};
