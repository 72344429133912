import { createStyles } from '@material-ui/core';

export default () =>
  createStyles({
    bold: {
      fontWeight: 600,
    },
    overflow: {
      display: 'block',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      width: '100%',
    },
  });
