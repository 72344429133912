import { FC } from 'react';

import { List, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { ASSIGNEE, BUCKET, UNASSIGNED, UNGROUPED } from '../../../constants';
import { getCollapse } from '../../ItemsList/ItemsListUtils';

import ItemsNestingCollapse from './ItemsNestingCollapse';
import styles from './ItemsNestingListStyles';

type Settings = {
  collapse: string[];
  expand: string[];
};

type ItemsNestingListProps = {
  branches?: ItemsTreeBranch[];
  classes: Classes<typeof styles>;
  displayName: string;
  items?: ItemsTreeItem[];
  itemsLikeIDsSelected?: UUID[];
  level?: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  node: any;
  pastLevel?: number;
  projectId: string;
  renderItemOptions?: (
    item: ItemsTreeItem,
    spacerCount: number,
    showCategory: boolean,
    key?: string
  ) => JSX.Element | null;
  selectItemLike?: (id: UUID, isSelected: boolean, isItem: boolean, milestoneID: UUID) => void;
  setCollapse: (bool: boolean, ids: UUID[], node: string) => void;
  settings: Settings;
  sharedUsersMap: Map<UUID, Pick<User, 'id' | 'name'>[]>;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  showCategory?: boolean;
  topLevelItemCount: number;
  variant?: string;
  view: string;
};

const ItemsNestingList: FC<ItemsNestingListProps> = ({
  branches,
  classes,
  displayName,
  items = [],
  itemsLikeIDsSelected = [],
  level = 1,
  node,
  pastLevel = 1,
  projectId,
  renderItemOptions,
  selectItemLike,
  setCollapse,
  settings,
  sharedUsersMap,
  showCategory = true,
  topLevelItemCount = 0,
  variant = BUCKET,
  view,
}) => {
  const { collapse, expand } = settings;
  const defaultID = variant === ASSIGNEE ? UNASSIGNED : UNGROUPED;
  const nodeId = node?.id || defaultID;
  const collapsed = getCollapse(nodeId, collapse, expand);
  const isSelected = itemsLikeIDsSelected.some((i: string) => i === node?.id);
  const spacerCount = level - pastLevel - 1;
  const collapseProps = {
    collapsed,
    displayName,
    isSelected,
    node,
    nodeId,
    selectItemLike,
    setCollapse,
    sharedUsersMap,
    spacerCount,
    topLevelItemCount,
    variant,
    view,
  };

  return (
    <div>
      <ItemsNestingCollapse {...collapseProps} key={nodeId}>
        <List key={`${nodeId} list`} className={classes.list}>
          {items.map((i) =>
            renderItemOptions?.(i, 0, showCategory, JSON.stringify(node && node.levels))
          )}
        </List>
        {branches &&
          branches.map((b) => (
            <ItemsNestingList
              key={b.key}
              branches={b.branches}
              classes={classes}
              displayName={b.displayName}
              items={b.items}
              itemsLikeIDsSelected={itemsLikeIDsSelected}
              level={b.level}
              node={{ display: b.displayName, id: b.key, level: b.level, nodeCosts: b.nodeCosts }}
              pastLevel={level}
              projectId={projectId}
              renderItemOptions={renderItemOptions}
              selectItemLike={selectItemLike}
              setCollapse={setCollapse}
              settings={settings}
              sharedUsersMap={sharedUsersMap}
              showCategory={false}
              topLevelItemCount={b.items.length}
              variant={variant}
              view={view}
            />
          ))}
        {(!items || !items.length) && (!branches || !branches.length) && (
          <div className={classes.empty}>
            <Typography>Nothing here.</Typography>
          </div>
        )}
      </ItemsNestingCollapse>
    </div>
  );
};

export default withStyles(styles)(ItemsNestingList);
