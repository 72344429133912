import { ReactNode, useRef } from 'react';

import { Check, ErrorOutline, Info } from '@material-ui/icons';

type BannerTypes = 'error' | 'warning' | 'info' | 'success';

type Props = {
  children: ReactNode;
  type: BannerTypes;
  'data-cy'?: string;
};

export default function StatusBanner(props: Props) {
  const ref = useRef<HTMLDivElement>(null);

  let backgroundClass = 'bg-status-banner-default-bg';
  let iconClass = 'text-status-banner-info-icon';
  if (props.type === 'warning') {
    iconClass = 'text-status-banner-warning-icon';
  } else if (props.type === 'error') {
    backgroundClass = 'bg-status-banner-error-bg';
    iconClass = 'text-status-banner-error-icon';
  } else if (props.type === 'success') {
    backgroundClass = 'bg-status-banner-success-bg';
    iconClass = 'text-status-banner-success-icon';
  }

  const containerClassName = `flex overflow-hidden items-center gap-2 ${backgroundClass} p-4 text-type-primary type-label rounded`;

  return (
    <div className={containerClassName}>
      <div className={iconClass}>
        <StatusBannerIcon type={props.type} />
      </div>
      <div ref={ref} className="grow" data-cy={props['data-cy']}>
        {props.children}
      </div>
    </div>
  );
}

type StatusBannerIconProps = {
  type: BannerTypes;
};

function StatusBannerIcon(props: StatusBannerIconProps) {
  if (props.type === 'success') {
    return <Check />;
  }
  if (props.type === 'info') {
    return <Info />;
  }

  return <ErrorOutline />;
}
