import { FC } from 'react';

import { ButtonBase } from '@material-ui/core';

import { withStyles } from '../../../theme/komodo-mui-theme';

import styles from './SelectFilterMenuStyles';

export type SelectFilterMenuData = { title: string };

type SelectFilterMenuProps = {
  classes: Classes<typeof styles>;
  data: SelectFilterMenuData[];
  filterKey: string;
  isCompact?: boolean;
  label?: string;
  onClick: (filter: string) => void;
};

const SelectFilterMenu: FC<SelectFilterMenuProps> = ({
  classes,
  data,
  filterKey,
  isCompact = true,
  label,
  onClick,
}) => {
  const buttons = data.map((menuData: SelectFilterMenuData) => {
    const { title } = menuData;
    const buttonClasses =
      filterKey === title ? `${classes.button} ${classes.selected}` : classes.button;
    return (
      <ButtonBase
        key={`SelectFilterMenu-${title}`}
        aria-label={`Filter teammates by ${title}`}
        className={`${buttonClasses} ${isCompact ? classes.buttonCompact : ''}`}
        data-cy={`SelectFilterMenu-${title.replace(' ', '')}Button`}
        onClick={() => {
          onClick(title);
        }}
        type="button"
      >
        <p className={classes.title}>{`${title}`}</p>
      </ButtonBase>
    );
  });
  return (
    <div className={classes.containerOuter}>
      {label && <p className={classes.label}>{label}</p>}
      <div className={`${classes.containerButtons} ${isCompact ? classes.toggleCompact : ''}`}>
        {buttons}
      </div>
    </div>
  );
};

/** @deprecated in favor of design system component, please use scales/ButtonBar or scales/Tabs */
export default withStyles(styles)(SelectFilterMenu);
