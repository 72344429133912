import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { AssistantPhoto } from '@material-ui/icons';

import { JoinProjectRoutes } from '../../../api/gqlEnums';
import theme from '../../../theme/design-system-mui-theme';
import { generateSharedPath } from '../../../utilities/routes/links';
import { pluralizeCountString } from '../../../utilities/string';
import Comment from '../../Icons/Comment';
import { Chip, Select, Tooltip } from '../../scales';

export const defaultMilestoneEntry = {
  id: 'follow-active-milestone',
  label: 'Follow Active Milestone',
  description:
    'Let Join change the milestone in this report based on the active milestone for this project.',
};

type MilestonesWithNoteCount = Milestone & { noteCount: number };

const isFollowingActiveMilestoneEntry = (entryID: string): boolean => {
  return entryID === defaultMilestoneEntry.id;
};

const getMilestoneIDForEntry = (selectedMilestoneID: string): string | undefined => {
  const isFollowingActiveMilestone = isFollowingActiveMilestoneEntry(selectedMilestoneID);
  return isFollowingActiveMilestone ? undefined : selectedMilestoneID;
};

const getEndAdornment = (
  milestone: MilestonesWithNoteCount,
  isActiveMilestone: boolean
): JSX.Element | null => {
  if (isActiveMilestone) {
    return (
      <Chip
        icon={
          <AssistantPhoto
            nativeColor={theme.palette.ds.entities.milestone}
            style={{ marginRight: 'auto' }}
          />
        }
        text="Active"
      />
    );
  }

  const content = `${pluralizeCountString('note', milestone.noteCount)} left in this Milestone`;
  return milestone.noteCount > 0 ? (
    <Tooltip content={content} placement="bottom">
      <div>
        <Comment />
      </div>
    </Tooltip>
  ) : null;
};

type Props = {
  activeMilestoneID: UUID;
  defaultSelectedMilestoneID?: string;
  milestones: MilestonesWithNoteCount[];
  onChange: (milestoneID?: string) => void;
  projectID: UUID;
  shouldNavigateOnChange: boolean;
  shouldNavigateOnClose: boolean;
};

export default function FollowingActiveMilestoneSelect(props: Props) {
  const navigate = useNavigate();
  const { search } = window.location;
  const defaultValue = props.defaultSelectedMilestoneID ?? defaultMilestoneEntry.id;
  const [selectedEntry, setSelectedEntry] = useState(defaultValue);

  const milestoneEntries = [
    defaultMilestoneEntry,
    ...props.milestones.map((milestone) => ({
      id: milestone.id,
      label: milestone.name,
      endAdornment: getEndAdornment(milestone, milestone.id === props.activeMilestoneID),
    })),
  ];

  // navigates to the correct milestoneID when a report is created
  useEffect(() => {
    return () => {
      if (props.shouldNavigateOnClose) {
        const { search } = window.location;
        const isFollowingActiveMilestone = isFollowingActiveMilestoneEntry(selectedEntry);
        const milestoneID = getMilestoneIDForEntry(selectedEntry);
        const milestoneIDToNavigate = isFollowingActiveMilestone
          ? props.activeMilestoneID
          : milestoneID;
        navigate(
          generateSharedPath(JoinProjectRoutes.MSR, {
            milestoneId: milestoneIDToNavigate,
            projectId: props.projectID,
            search,
          })
        );
      }
    };
  }, [
    navigate,
    props.activeMilestoneID,
    props.projectID,
    props.shouldNavigateOnClose,
    selectedEntry,
  ]);

  return (
    <div className="w-[275px]">
      <Select
        defaultValue={defaultValue}
        entries={milestoneEntries}
        label="Milestone"
        onChange={(selectedMilestoneID) => {
          setSelectedEntry(selectedMilestoneID);
          const milestoneID = getMilestoneIDForEntry(selectedMilestoneID);

          props.onChange(milestoneID);

          if (props.shouldNavigateOnChange) {
            const isFollowingActiveMilestone = isFollowingActiveMilestoneEntry(selectedMilestoneID);
            const milestoneIDToNavigate = isFollowingActiveMilestone
              ? props.activeMilestoneID
              : selectedMilestoneID;
            navigate(
              generateSharedPath(JoinProjectRoutes.MSR, {
                milestoneId: milestoneIDToNavigate,
                projectId: props.projectID,
                search,
              })
            );
          }
        }}
        value={selectedEntry}
      />
    </div>
  );
}
