import { UserStatus } from '../../../api/gqlEnumsBe';
import { Asset } from '../../../generated/graphql';

export const assetTemplate: Asset = {
  __typename: 'Asset',
  derivations: [],
  id: '',
  lastUpdated: '',
  location: '',
  name: '',
  provider: 0,
  providerMetadata: '',
  size: 0,
  type: 'Image',
  uploadedBy: {
    __typename: 'User',
    email: '',
    id: '00000000-0000-0000-0000-000000000000',
    name: '',
    isEmailVerified: true,
    jobTitle: '',
    picture: '',
    status: UserStatus.ACTIVE,
  },
};
