import { useMutation } from '@apollo/client';

import { projectCompsSetInputVar } from '../../../../../api/apollo/reactiveVars';
import {
  ExportAverageCompEstimateInput,
  ExportAverageCompToNewMilestoneMutation,
  ExportAverageCompToNewMilestoneMutationVariables,
} from '../../../../../generated/graphql';
import { logErrorToSentry } from '../../../../../utilities/sentry';

import { exportAverageCompToNewMilestone } from './queries';

export const useExportAverageCompToNewMilestone = () => {
  const [createEstimateFromProjectCompFunc] = useMutation<
    ExportAverageCompToNewMilestoneMutation,
    ExportAverageCompToNewMilestoneMutationVariables
  >(exportAverageCompToNewMilestone, { onError: logErrorToSentry });
  const mutation = (
    projectID: UUID,
    milestoneInput: MilestoneInput,
    exportAverageCompEstimateInput: ExportAverageCompEstimateInput,
    onSuccess: (newMilestoneID: UUID) => void
  ) =>
    createEstimateFromProjectCompFunc({
      variables: {
        projectID,
        milestoneInput,
        projectCompsSetInput: projectCompsSetInputVar(),
        exportAverageCompEstimateInput,
      },
    }).then(({ data }) => {
      if (data?.exportAverageCompToNewMilestone)
        onSuccess(data?.exportAverageCompToNewMilestone.id);
    });
  return [mutation];
};
