import {
  Permission,
  PermissionGroup,
  PermissionGroupType,
  PermissionLevel,
  PermissionResource,
} from '../../../generated/graphql';

export const permissionLevels = [
  PermissionLevel.NONE,
  PermissionLevel.VIEW_ONLY,
  PermissionLevel.STANDARD,
  PermissionLevel.ADMIN,
];

export const getAvailableLevelsForPermissionResource = (resource: PermissionResource) => {
  switch (resource) {
    case PermissionResource.MILESTONE_LINES:
      return [PermissionLevel.NONE, PermissionLevel.VIEW_ONLY, PermissionLevel.ADMIN];
    case PermissionResource.MILESTONE_BUDGET:
      return [PermissionLevel.NONE, PermissionLevel.VIEW_ONLY, PermissionLevel.ADMIN];
    case PermissionResource.ITEM_IN_TRADE_LINES:
      return [PermissionLevel.NONE, PermissionLevel.VIEW_ONLY, PermissionLevel.ADMIN];
    case PermissionResource.ITEM_OUT_OF_TRADE_LINES:
      return [PermissionLevel.NONE, PermissionLevel.VIEW_ONLY, PermissionLevel.ADMIN];
    case PermissionResource.SCHEDULE_IMPACT:
      return [PermissionLevel.VIEW_ONLY, PermissionLevel.STANDARD, PermissionLevel.ADMIN];
    case PermissionResource.DRAW_EVENTS_VIEW:
      return [PermissionLevel.NONE, PermissionLevel.VIEW_ONLY];
    case PermissionResource.ITEM_INTEGRATIONS:
      return [PermissionLevel.NONE, PermissionLevel.VIEW_ONLY, PermissionLevel.STANDARD];
    case PermissionResource.SHARE_ITEMS_SCENARIOS:
      return [PermissionLevel.STANDARD, PermissionLevel.ADMIN];
    case PermissionResource.PUBLISH_ITEMS_SCENARIOS:
      return [PermissionLevel.STANDARD, PermissionLevel.ADMIN];
    case PermissionResource.REPORT_DISTRIBUTION:
      return [PermissionLevel.NONE, PermissionLevel.ADMIN];
    case PermissionResource.MARKUPS:
      return [PermissionLevel.NONE, PermissionLevel.VIEW_ONLY, PermissionLevel.ADMIN];
    case PermissionResource.GROSS_VIEW:
      return [PermissionLevel.NONE, PermissionLevel.VIEW_ONLY];
    case PermissionResource.NET_VIEW:
      return [PermissionLevel.NONE, PermissionLevel.VIEW_ONLY];
    case PermissionResource.NO_MARKUPS_VIEW:
      return [PermissionLevel.NONE, PermissionLevel.VIEW_ONLY];
    case PermissionResource.ALLOCATED_MARKUPS_VIEW:
      return [PermissionLevel.NONE, PermissionLevel.VIEW_ONLY];
    case PermissionResource.SEPARATED_MARKUPS_VIEW:
      return [PermissionLevel.NONE, PermissionLevel.VIEW_ONLY];
    case PermissionResource.PROJECT_DETAILS:
      return [PermissionLevel.NONE, PermissionLevel.VIEW_ONLY, PermissionLevel.ADMIN];
    case PermissionResource.COLLABORATORS:
      return [PermissionLevel.VIEW_ONLY, PermissionLevel.ADMIN];
    case PermissionResource.PERMISSION_TEMPLATES:
      return [PermissionLevel.NONE, PermissionLevel.VIEW_ONLY, PermissionLevel.ADMIN];
    case PermissionResource.CATEGORIES_AND_TAGS:
      return [PermissionLevel.NONE, PermissionLevel.VIEW_ONLY, PermissionLevel.ADMIN];
    case PermissionResource.MILESTONES:
      return [PermissionLevel.VIEW_ONLY, PermissionLevel.ADMIN];
    case PermissionResource.MILESTONE_ATTACHMENTS:
      return [
        PermissionLevel.NONE,
        PermissionLevel.VIEW_ONLY,
        PermissionLevel.STANDARD,
        PermissionLevel.ADMIN,
      ];
    case PermissionResource.DRAFT_MILESTONE:
      return [PermissionLevel.NONE, PermissionLevel.VIEW_ONLY, PermissionLevel.ADMIN];
    case PermissionResource.DRAFT_ESTIMATE:
      return [PermissionLevel.NONE, PermissionLevel.VIEW_ONLY, PermissionLevel.ADMIN];
    case PermissionResource.METRICS:
      return [PermissionLevel.NONE, PermissionLevel.VIEW_ONLY, PermissionLevel.ADMIN];
    case PermissionResource.MEETINGS:
      return [PermissionLevel.VIEW_ONLY, PermissionLevel.ADMIN];
    case PermissionResource.ITEM_LIST:
      return [PermissionLevel.VIEW_ONLY];
    case PermissionResource.ITEM_DETAILS:
      return [PermissionLevel.VIEW_ONLY, PermissionLevel.STANDARD, PermissionLevel.ADMIN];
    case PermissionResource.ITEM_MILESTONE_AND_MEETINGS:
      return [PermissionLevel.VIEW_ONLY, PermissionLevel.ADMIN];
    case PermissionResource.ITEM_CATEGORIES:
      return [PermissionLevel.VIEW_ONLY, PermissionLevel.ADMIN];
    case PermissionResource.ITEM_ASSIGNEES:
      return [PermissionLevel.VIEW_ONLY, PermissionLevel.ADMIN];
    case PermissionResource.ITEM_NUMBER:
      return [PermissionLevel.VIEW_ONLY, PermissionLevel.STANDARD];
    case PermissionResource.ITEM_STATUS:
      return [PermissionLevel.VIEW_ONLY, PermissionLevel.STANDARD];
    case PermissionResource.PROJECT_TERMINOLOGY:
      return [PermissionLevel.NONE, PermissionLevel.VIEW_ONLY, PermissionLevel.ADMIN];
    case PermissionResource.PROJECT_UNITS:
      return [PermissionLevel.VIEW_ONLY, PermissionLevel.ADMIN];
    case PermissionResource.PROJECT_CURRENCY:
      return [PermissionLevel.VIEW_ONLY, PermissionLevel.ADMIN];
    case PermissionResource.ITEM_ATTACHMENTS:
      return [
        PermissionLevel.NONE,
        PermissionLevel.VIEW_ONLY,
        PermissionLevel.STANDARD,
        PermissionLevel.ADMIN,
      ];
    case PermissionResource.ITEM_COMMENTS:
      return [PermissionLevel.NONE, PermissionLevel.VIEW_ONLY, PermissionLevel.STANDARD];
    case PermissionResource.ITEM_TEMPLATE:
      return [PermissionLevel.NONE, PermissionLevel.VIEW_ONLY, PermissionLevel.ADMIN];
    case PermissionResource.USER_REPORT_NOTES:
      return [PermissionLevel.STANDARD, PermissionLevel.ADMIN];
    case PermissionResource.TIMELINE:
      return [PermissionLevel.VIEW_ONLY, PermissionLevel.STANDARD, PermissionLevel.ADMIN];
    case PermissionResource.ESTIMATE_COST_SUBTOTALS:
      return [PermissionLevel.NONE, PermissionLevel.VIEW_ONLY];
    case PermissionResource.DISPLAY_COSTS:
      return [PermissionLevel.NONE, PermissionLevel.VIEW_ONLY];
    case PermissionResource.SHARED_USER_REPORTS:
      return [PermissionLevel.VIEW_ONLY, PermissionLevel.STANDARD, PermissionLevel.ADMIN];
    case PermissionResource.OWNER_COSTS:
      return [PermissionLevel.NONE, PermissionLevel.VIEW_ONLY, PermissionLevel.ADMIN];
    default:
      return [];
  }
};

export const getPermissionLevelString = (level: PermissionLevel, disabled?: boolean) => {
  if (disabled) return 'None';
  switch (level) {
    case PermissionLevel.ADMIN:
      return 'Admin';
    case PermissionLevel.NONE:
      return 'None';
    case PermissionLevel.STANDARD:
      return 'Standard';
    case PermissionLevel.VIEW_ONLY:
      return 'View Only';
    default:
      return '';
  }
};

export const getPermissionDescriptionStrings = (
  permission: Pick<Permission, 'resource' | 'level'>,
  disabled?: boolean
) => {
  if (disabled) return ['Cannot view'];
  if (permission.level === PermissionLevel.ADMIN) {
    switch (permission.resource) {
      case PermissionResource.PERMISSION_TEMPLATES:
        return ['Can view, edit, add, delete'];
      case PermissionResource.ITEM_TEMPLATE:
        return ['Can view, edit columns'];
      case PermissionResource.SCHEDULE_IMPACT:
        return ['Can view, edit, change display'];
      case PermissionResource.SHARE_DRAFT_ITEMS:
      case PermissionResource.SHARE_ITEMS_SCENARIOS:
        return ['Can share draft items/scenarios shared with you and ones you created'];
      case PermissionResource.OWNER_COSTS:
        return ['Can view, add, edit'];
      case PermissionResource.PUBLISH_DRAFT_ITEMS:
      case PermissionResource.PUBLISH_ITEMS_SCENARIOS:
        return ['Can publish draft items/scenarios shared with you and ones you created'];
      case PermissionResource.PROJECT_TERMINOLOGY:
      case PermissionResource.PROJECT_CURRENCY:
        return ['Can view, edit'];
      case PermissionResource.ITEM_ATTACHMENTS:
      case PermissionResource.MILESTONE_ATTACHMENTS:
        return ['Can view, add, delete'];
      case PermissionResource.MILESTONES:
        return ['Can view, add, edit, delete', 'Can edit active milestone'];
      default:
        return ['Can view, add, edit, delete'];
    }
  }
  if (permission.level === PermissionLevel.STANDARD) {
    switch (permission.resource) {
      case PermissionResource.SHARE_DRAFT_ITEMS:
      case PermissionResource.SHARE_ITEMS_SCENARIOS:
        return ['Can share draft items/scenarios you created'];
      case PermissionResource.PUBLISH_DRAFT_ITEMS:
      case PermissionResource.PUBLISH_ITEMS_SCENARIOS:
        return ['Can publish draft items/scenarios you created'];
      case PermissionResource.USER_REPORT_NOTES:
        return ['Can view, add, edit'];
      case PermissionResource.ITEM_DETAILS:
        return [
          'Can add new Items and Options',
          'Can edit Items and Options that you created or that are assigned to you',
          'Can delete Items and Options that you created',
        ];
      case PermissionResource.SHARED_USER_REPORTS:
        return ['Can view, add shared reports', 'Can view, add, edit, delete reports you created'];
      case PermissionResource.ITEM_STATUS:
      case PermissionResource.SCHEDULE_IMPACT:
      case PermissionResource.PROJECT_UNITS:
      case PermissionResource.ITEM_NUMBER:
        return ['Can view, edit'];
      case PermissionResource.ITEM_INTEGRATIONS:
        return ['Can view, add, delete'];
      default:
        return ['Can view, add'];
    }
  }
  if (permission.level === PermissionLevel.VIEW_ONLY) return ['Can view'];
  return ['Cannot view'];
};

export const getPermissionsTableLabels = (editable: boolean) => {
  const featureAndDescription = [
    { key: 'feature', copy: 'Feature' },
    { key: 'description', copy: 'Description' },
  ];
  if (editable)
    return featureAndDescription.concat(
      permissionLevels.map((level) => {
        return { key: level, copy: getPermissionLevelString(level) || level };
      })
    );
  return featureAndDescription.concat([
    { key: 'permissions_level', copy: 'Permissions Level' },
    { key: 'permission_description', copy: 'Permission Description' },
  ]);
};

export const getIsDisabledCostPermission = (
  group: PermissionGroup,
  matchedPermission: Permission,
  shouldDisableMarkups?: boolean,
  canViewCosts?: boolean
) => {
  return (
    (group &&
      group.type === PermissionGroupType.COST &&
      matchedPermission &&
      matchedPermission.resource === PermissionResource.MARKUPS &&
      shouldDisableMarkups) ||
    (group?.type === PermissionGroupType.COST && !canViewCosts)
  );
};

export const filterMarkupModePermissions = (permissions: Permission[]) => {
  const orderedMarkupModeResources = [
    PermissionResource.ALLOCATED_MARKUPS_VIEW,
    PermissionResource.SEPARATED_MARKUPS_VIEW,
    PermissionResource.NO_MARKUPS_VIEW,
  ];
  return permissions
    .filter(({ resource }) => orderedMarkupModeResources.includes(resource))
    .sort(
      ({ resource: a }: Permission, { resource: b }: Permission) =>
        orderedMarkupModeResources.indexOf(a) - orderedMarkupModeResources.indexOf(b)
    );
};
