import { useEffect, useRef, useState } from 'react';

import { WatchLater } from '@material-ui/icons';

import { ToastType } from '../../../api/gqlEnums';
import { ProjectCompEscalationMetaInput } from '../../../generated/graphql';
import { setToast } from '../../../hooks/useToastParametersLocalQuery';
import { parseDate } from '../../../utilities/dates';

import EditTimeDialog from './EditTimeDialog';
import EscalationInput from './EscalationInput';
import { useTimeEscalationLazyQuery } from './hooks/useTimeEscalationLazyQuery';
import StartAdornment from './StartAdornment';

const TimeEscalationInput = (props: {
  isHighlighted?: boolean;
  dateFrom?: string;
  dateTo: string;
  meta?: ProjectCompEscalationMetaInput;
  onAutoEscalateAllTime: () => void;
  onChange: (value: string, meta?: ProjectCompEscalationMetaInput) => void;
  onMouseOver?: (isOver: boolean) => void;
  project: Pick<ProjectProps, 'name' | 'thumbnail'>;
  value: string;
}) => {
  const isAutoEscalated = Boolean(props.meta?.isAutoEscalated);

  // Store a local copy of the date we're escalating from so that the user can change it. Also,
  // respond to changes in the props because the date will need to be fetched from the backend first.
  const [dateFrom, setDateFrom] = useState(props.dateFrom);
  useEffect(() => {
    setDateFrom(props.dateFrom ?? '');
  }, [props.dateFrom]);

  const [isEditDateDialogOpen, setIsEditDateDialogOpen] = useState(false);

  const [fetchTimeEscalation] = useTimeEscalationLazyQuery({
    onCompleted: (res) => {
      if (!res?.timeEscalation) {
        setToast({ message: 'Failed to calculate escalation' }, ToastType.SERVER_ERROR);
        props.onChange('');
        return;
      }

      const { percentage, fuzzyMatch, to } = res.timeEscalation;

      props.onChange(percentage.toString(), {
        isAutoEscalated: true,
        isFuzzyMatch: fuzzyMatch,
        sourceLabel: parseDate(dateFrom),
        targetLabel: `${to.year} Q${to.quarter}`,
      });
    },
  });

  // Note the wording on the dropdown menu item that this is triggered by. This function should autoescalate
  // from the /original/ date -- not a user-changed one.
  const handleAutoEscalate = () => {
    if (!props.dateFrom || !props.dateTo) {
      setIsEditDateDialogOpen(true);
      return;
    }

    setDateFrom(props.dateFrom);
    fetchTimeEscalation({
      variables: {
        from: props.dateFrom,
        to: props.dateTo,
      },
    });
  };

  const handleManualEscalate = () => {
    handleRemoveEscalation();

    const focusInput = () => {
      if (!inputRef.current) return;

      // Removing escalation requires the full projectCompsSet query to rerun and this
      // to rerender. On a very slow connection with large projects (or in Jenkins), this
      // could take more than 500ms, so we retry.
      if (inputRef.current.disabled) {
        setTimeout(focusInput, 500);
      } else {
        inputRef.current.focus();
      }
    };

    setTimeout(focusInput, 500);
  };

  const handleRemoveEscalation = () => {
    props.onChange('');
  };

  // Create a ref so that we can focus the input when the user switches to manual mode.
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <>
      <EscalationInput
        inputRef={inputRef}
        isAutoEscalated={isAutoEscalated}
        isHighlighted={props.isHighlighted}
        onAutoEscalate={handleAutoEscalate}
        onAutoEscalateAll={props.onAutoEscalateAllTime}
        onChange={props.onChange}
        onManualEscalate={handleManualEscalate}
        onMouseOver={props.onMouseOver}
        onRemoveEscalation={handleRemoveEscalation}
        startAdornment={
          <StartAdornment
            icon={WatchLater}
            isAutoEscalated={isAutoEscalated}
            isFuzzyMatch={false}
            label={(props.meta?.sourceLabel || parseDate(dateFrom)) ?? ''}
            onClick={() => setIsEditDateDialogOpen(true)}
          />
        }
        type="date"
        value={props.value}
      />
      {props.dateTo && isEditDateDialogOpen && (
        <EditTimeDialog
          dateFrom={dateFrom}
          dateTo={props.dateTo}
          onApply={(date) => {
            fetchTimeEscalation({
              variables: {
                from: date,
                to: props.dateTo,
              },
            });

            setDateFrom(date);
            setIsEditDateDialogOpen(false);
            setToast({ message: 'Escalation date changed' }, ToastType.SUCCESS);
          }}
          onClose={() => setIsEditDateDialogOpen(false)}
          project={props.project}
        />
      )}
    </>
  );
};

export default TimeEscalationInput;
