import { createStyles } from '@material-ui/core';

const AssigneeChipStyles = () =>
  createStyles({
    label: {
      flexGrow: 1,
      overflow: 'hidden',
      textAlign: 'left',
      marginLeft: 8,
      '@media print': {
        marginLeft: 0,
      },
    },
    noPrint: {
      '@media print': {
        display: 'none',
      },
    },
    root: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
      flexGrow: 1,
      minWidth: 32,
    },
    text: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    deactivated: {
      opacity: 0.5,
    },
  });

export default AssigneeChipStyles;
