import { FC, useEffect, useState } from 'react';

import { LinearProgress } from '@material-ui/core';
import { Add } from '@material-ui/icons';

import { withStyles } from '../../../theme/komodo-mui-theme';
import { getProjectIdFromUrl } from '../../../utilities/url';
import { useSortedUnits, useToggleProjectUnits } from '../../Milestone/hooks/UnitHooks';
import { Button, Dialog } from '../../scales';
import DialogsStyles from '../DialogsStyles';

import DialogsCreateOrEditUnit from './DialogsCreateOrEditUnit';
import DialogsUnitsUnit from './DialogsUnitsUnit';

type DialogUnitsProps = {
  classes: Classes<typeof DialogsStyles>;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  open: boolean;
  onClose: () => void;
};

const togglesFromOverride = (override: Map<UUID, boolean>) => {
  const toggles: UnitToggleInput[] = [];
  override.forEach((isEnabled: boolean, id: UUID) => {
    toggles.push({ id, isEnabled });
  });
  return toggles;
};

const DialogUnits: FC<DialogUnitsProps> = ({ classes, open, onClose }) => {
  const projectID = getProjectIdFromUrl();

  // UNITS
  const { refetch, units, unitsLoading } = useSortedUnits(false);
  const toggleProjectUnits = useToggleProjectUnits();

  const [editingUnit, setEditingUnit] = useState<Unit | undefined>(undefined);
  const [isEditing, setIsEditing] = useState(false);
  const [override, setOverride] = useState<Map<UUID, boolean>>(new Map());

  useEffect(() => {
    if (open) {
      refetch();
      setEditingUnit(undefined);
      setIsEditing(false);
      setOverride(new Map());
    }
  }, [refetch, open]);

  const setLocalIsEnabled = (unitId: UUID, isEnabled: boolean) => {
    const newOverride = new Map(override);
    newOverride.set(unitId, isEnabled);
    setOverride(newOverride);
  };

  const setEditing = (unit: Unit) => {
    setIsEditing(true);
    setEditingUnit(unit);
  };

  const getIsEnabled = (unit: Unit) => {
    const isEnabledOverride = override.get(unit.id);
    const isEnabledServer = unit.isEnabled;
    if (typeof isEnabledOverride === 'undefined') return isEnabledServer;
    return isEnabledOverride;
  };

  if (!open) return null;

  return isEditing ? (
    <DialogsCreateOrEditUnit
      isOpen={open}
      onBack={() => setIsEditing(false)}
      onClose={onClose}
      unit={editingUnit}
    />
  ) : (
    <Dialog
      footerLeft={
        <Button
          data-cy="new-unit-button"
          label="New Unit"
          onClick={() => {
            setEditingUnit(undefined);
            setIsEditing(true);
          }}
          startIcon={<Add />}
          type="primary"
        />
      }
      footerRight={
        <Button
          data-cy="save-unit-button"
          label="Save"
          onClick={() => {
            const toggles = togglesFromOverride(override);
            if (toggles.length > 0)
              toggleProjectUnits(projectID, togglesFromOverride(override), () => {});
            onClose();
          }}
          type="primary"
        />
      }
      isOpen={open}
      onClose={onClose}
      title="Choose units of measure to enable"
    >
      <div className={classes.loading}>
        <LinearProgress hidden={!unitsLoading} />
      </div>
      <div className={`${classes.contentContainer} ${classes.content} ${classes.paddingBottom}`}>
        {units.map((unit, index) => (
          <DialogsUnitsUnit
            key={unit.id}
            canEdit
            isEnabled={getIsEnabled(unit)}
            setEditing={setEditing}
            setIsEnabled={setLocalIsEnabled}
            showBorder={index !== 0}
            unit={unit}
          />
        ))}
      </div>
    </Dialog>
  );
};

const StyledDialogUnits = withStyles(DialogsStyles)(DialogUnits);

export default StyledDialogUnits;
