import React from 'react';

import { formatCost } from '../../../../utilities/currency';

import { PieChartData } from './ChartsPieGraphUtils';

type Props = {
  dataOther?: PieChartData[];
  fill: string;
  isCurrency?: boolean;
  name: string;
  share: number | null;
  totalShare: number | null;
};

export default function TooltipPieSegment(props: Props) {
  return (
    <div className="flex type-body2">
      {props.name.includes('Other ') && props.dataOther ? (
        <div className="flex flex-col gap-1">
          <div className="flex flex-row items-center gap-2">
            <div className="h-3 w-3" style={{ backgroundColor: props.fill }} />
            <div className="type-heading3">{props.name}</div>
          </div>
          <div className="grid grid-cols-[130px_40px_40px] items-center gap-1 text-left">
            {props.dataOther?.map((item) => (
              <React.Fragment key={item.name}>
                <div className="line-clamp-1 w-full">{item.name}</div>
                <div className="text-right">
                  {!props.isCurrency
                    ? item.share
                    : formatCost(item.share, { short: true, showCents: false })}
                </div>
                {/* Calculate percentage */}
                <div className="text-right">
                  {props.totalShare && ((item.share / props.totalShare) * 100).toFixed()}%
                </div>
              </React.Fragment>
            ))}
          </div>
        </div>
      ) : (
        <div className="grid grid-cols-[16px_110px_40px_40px] items-center gap-1 text-left">
          <div className="h-3 w-3" style={{ backgroundColor: props.fill }} />
          <div className="line-clamp-1 w-full">{props.name}</div>
          <div className="text-right">
            {props.isCurrency
              ? formatCost(props.share, { short: true, showCents: false })
              : props.share}
          </div>
          {/* Calculate percentage */}
          <div className="text-right">
            {props.share && props.totalShare && ((props.share / props.totalShare) * 100).toFixed()}%
          </div>
        </div>
      )}
    </div>
  );
}
