import { ProjectType } from '../../../generated/graphql';

export type ListFilterOption = {
  id: string;
  count?: number;
  label: string;
};

export const calculateSelectAllLabel = (
  selectedEntries: string[],
  entries: ProjectType[] | ListFilterOption[]
) => {
  if (selectedEntries.length === 0) {
    return 'Select All';
  }
  if (selectedEntries.length === entries.length) {
    return 'All Selected';
  }
  return `${selectedEntries.length} Selected`;
};
