import { useCallback, useRef } from 'react';

import { FetchMoreQueryOptions } from '@apollo/client';

import { paginationHasMoreVar } from '../api/apollo/reactiveVars';

const useQueryPagination = (
  limit: number,
  nextOffsetToFetch: number,
  apolloFetchMore: (fetchMoreOptions: FetchMoreQueryOptions<{ pagination: Pagination }>) => void
) => {
  // Storing the offset as a ref because we don't want to trigger rerenders.
  const fetchedOffset = useRef(0);

  // We're wrapping the Apollo fetchMore function function for two reasons. This prevents:
  // 1. Accidentally querying multiple times for the same offset and generating duplicates.
  // 2. Attempting to `fetchMore` when we've already reached the end of the dataset.
  const fetchMore = useCallback(() => {
    if (paginationHasMoreVar() && fetchedOffset.current !== nextOffsetToFetch) {
      fetchedOffset.current = nextOffsetToFetch;

      return apolloFetchMore({
        variables: {
          pagination: {
            limit,
            offset: nextOffsetToFetch,
          },
        },
      });
    }

    return undefined;
  }, [apolloFetchMore, limit, nextOffsetToFetch]);

  // This should probably never have a non-empty deps array because of how it's
  // used by this hook's users (ie, in a useEffect). If this function does not have
  // a stable identity, callers may find themselves unexpectedly resetting pagination.
  const resetPagination = useCallback(() => {
    fetchedOffset.current = 0;
    paginationHasMoreVar(true);
  }, []);

  return { fetchMore, resetPagination };
};

export default useQueryPagination;
