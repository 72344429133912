import { MenuItem } from '@material-ui/core';

import { pluralizeString } from '../../../utilities/string';
import { Checkbox } from '../../scales';

import SourceFilterMenuItem from './InputsSelectReferenceSourceFilterMenuItem';
import SourceFilterSubMenuHeader from './SourceFilterSubMenuHeader';
import { MarkupSelectors } from './utils';

export const getSourceFilterMenuItems = (
  selectors: MarkupSelectors,
  setSelectors: (newSelectors: MarkupSelectors) => void,
  sourceFilterMenuOptions: ItemLink[],
  markupLine: string
): React.ReactNode[] => {
  const { sources } = selectors;
  const areAllSourcesSelected = sources.length === sourceFilterMenuOptions.length;

  const menuItems = [
    <SourceFilterSubMenuHeader key={markupLine} selectedMarkup={markupLine} />,
    <div key={sources.length} className="h-12 border-b border-t">
      <MenuItem
        onClick={() => {
          const newSources =
            sources.length < sourceFilterMenuOptions.length
              ? sourceFilterMenuOptions.map((s) => s.id)
              : [];
          setSelectors({ ...selectors, sources: newSources });
        }}
      >
        <div className="flex">
          <Checkbox
            aria-label="Select All"
            isDisabled={false}
            isIndeterminate={!areAllSourcesSelected}
            isSelected={!!sources.length}
          />
          <div className="ml-2 type-body1">{`${sources.length} ${pluralizeString(
            'source',
            sources.length
          )} selected`}</div>
        </div>
      </MenuItem>
      ,
    </div>,
  ];

  sourceFilterMenuOptions.forEach((source: ItemLink) => {
    menuItems.push(
      <SourceFilterMenuItem
        key={source.id}
        selectors={selectors}
        setSelectors={setSelectors}
        source={source}
      />
    );
  });
  return menuItems;
};
