import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../../theme/komodo-mui-theme';

const ICON_SIZE = 40;
const THUMB_WIDTH = 64;
const V_PADDING = 12;

const FilesTableStyles = (theme: KomodoTheme) =>
  createStyles({
    avatar: {
      height: ICON_SIZE,
      width: THUMB_WIDTH,
    },
    avatarTable: {
      marginLeft: 'auto',
      marginRight: 'auto',
      objectFit: 'cover',
      padding: `${V_PADDING}px 0px`,
    },
    folderIcon: {
      fontSize: ICON_SIZE,
    },
    linkTitle: {
      textDecoration: 'underline',
      cursor: 'pointer',
      flex: '0 1 auto',
      fontFamily: theme.typography.fontFamily,
      fontSize: theme.typography.inputs.fontSize,
      minWidth: 0,
      width: '100%',
    },
    title: {
      lineHeight: '1em',
      paddingTop: 4,
      width: '100%',
      '& > *': {
        lineHeight: '1em',
        textDecoration: 'inherit',
        display: 'inline-block',
        width: '100%',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
      },
    },
    smallIcon: {
      '& svg': {
        fontSize: 20,
      },
    },
    text: {
      lineHeight: '1em',
      fontFamily: theme.typography.fontFamily,
      fontSize: theme.typography.inputs.fontSize,
      fontWeight: 300,
      overflow: 'hidden',
      paddingTop: 4,
      textOverflow: 'ellipsis',
      whiteSpace: 'normal',
      wordBreak: 'break-word',
    },
    textOverflow: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'normal',
    },
  });

export default FilesTableStyles;
