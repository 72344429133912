import { useMutation } from '@apollo/client';

import {
  UpdateProjectComparisonReportMutation,
  UpdateProjectComparisonReportMutationVariables,
} from '../../../../generated/graphql';

import { updateProjectComparisonReport } from './queries';

export const useAddProjectComparisonReportToProjectMutation = () => {
  const [updateProjectComparisonReportFunc] = useMutation<
    UpdateProjectComparisonReportMutation,
    UpdateProjectComparisonReportMutationVariables
  >(updateProjectComparisonReport);

  const mutation = (
    reportID: UUID,
    attachedProjectID: UUID,
    onSuccess?: (report: ProjectComparisonReport) => void,
    onFailure?: () => void
  ) => {
    updateProjectComparisonReportFunc({
      variables: {
        reportID,
        attachedProjectID,
      },
    }).then(({ data }) => {
      if (data?.updateProjectComparisonReport) {
        if (onSuccess) onSuccess(data?.updateProjectComparisonReport);
      } else if (onFailure) onFailure();
    });
  };
  return [mutation];
};
