import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';

export default (theme: KomodoTheme) =>
  createStyles({
    activeContainer: {
      background: theme.palette.backgroundGrey,
    },
    fullWidth: {
      width: '100%',
      minWidth: 380,
      marginBottom: 2,
    },
    selectContainer: {
      paddingTop: 4,
    },
    outerContainer: {
      padding: 8,
      paddingBottom: 3,
    },
  });
