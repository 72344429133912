import { CSSProperties } from 'react';

import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';

export default (theme: KomodoTheme) =>
  createStyles({
    avatar: {
      backgroundColor: 'transparent',
      height: 32,
      width: 32,
    },
    bold: {
      fontWeight: '400 !important' as CSSProperties['fontWeight'],
    },
    icon: {
      color: theme.palette.primaryGrey,
      fontSize: 18,
    },
    iconError: {
      color: theme.palette.red,
      fontSize: 18,
    },
    iconForward: {
      color: theme.palette.primaryGrey,
      fontSize: 14,
    },
    label: {
      flexGrow: 1,
      overflow: 'hidden',
      textAlign: 'left',
    },
    root: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
      flexGrow: 1,
      marginLeft: 0,
      minWidth: 32,
    },
    text: {
      fontWeight: 200,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  });
