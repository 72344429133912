import { FC } from 'react';

import { useReactiveVar } from '@apollo/client';
import { ButtonBase, Typography } from '@material-ui/core';

import {
  FilesDialogView,
  filesDialogViewVar,
  selectedAssetsVar,
} from '../../../../api/apollo/reactiveVars';
import { SourceSystem, UserSource } from '../../../../generated/graphql';
import { withStyles } from '../../../../theme/komodo-mui-theme';
import NormalTooltip from '../../../NormalTooltip/NormalTooltip';
import { resetNavInitial } from '../../FileExplorer/FilesExplorerUseUtils';

import styles from './FilesDialogSidebarStyles';
import FilesDialogUploadButton from './FilesDialogUploadButton';
import { getCurrentUserSource, getSourceButtonText } from './FilesDialogUtils';

type FilesDialogSidebarProps = {
  classes: Classes<typeof styles>;
  onDropFile: (file: File) => void;
  userSources?: UserSource[];
};

const FilesDialogSidebar: FC<FilesDialogSidebarProps> = ({ classes, onDropFile, userSources }) => {
  const userSourceSystems = userSources?.map((source) => source.sourceSystem) || [];
  const view = useReactiveVar(filesDialogViewVar);

  // Components
  const buttonComp = (
    key: SourceSystem | FilesDialogView,
    userSource?: UserSource,
    underlined?: boolean
  ) => {
    const buttonText = getSourceButtonText(key, userSource);
    const isSelected = view === key;
    const textStyle = `${classes.buttonText} ${classes.buttonTypography} ${
      underlined ? classes.underlined : ''
    }`;
    return (
      <ButtonBase
        key={key}
        className={`${classes.button} ${isSelected ? classes.selectedButton : ''}`}
        data-cy={key}
        onClick={() => {
          filesDialogViewVar(key);
          if (userSource) resetNavInitial(userSource);
          // If you switch, we want to clear the selected assets
          selectedAssetsVar({ selectedMap: {}, sourceID: userSource?.id });
        }}
      >
        <Typography className={textStyle}>{buttonText}</Typography>
      </ButtonBase>
    );
  };

  const renderButton = (key: SourceSystem) => {
    const userSource = getCurrentUserSource(userSources, key);
    const userEmail = userSource?.email;
    const sourceEmailHint = userEmail && `Connected as ${userEmail}`;
    const sourceButton = buttonComp(key, userSource);
    if (!userEmail) return sourceButton;
    return (
      <NormalTooltip key={key} disableHoverListener={!userEmail} title={sourceEmailHint}>
        {sourceButton}
      </NormalTooltip>
    );
  };

  //  SIDEBAR SECTIONS
  const filesSection = (
    <div className={classes.buttonSection}>
      <Typography className={classes.header}>UPLOADED</Typography>
      {buttonComp(FilesDialogView.ALL_FILES)}
      {buttonComp(FilesDialogView.UPLOADED_FILES)}
    </div>
  );

  const sourceSection = (
    <div className={classes.buttonSection}>
      <Typography className={classes.header}>CLOUD STORAGE</Typography>
      {userSourceSystems.map(renderButton)}
      {buttonComp(FilesDialogView.FILES_SOURCES, undefined, true)}
    </div>
  );

  return (
    <div className={classes.sidebar}>
      <FilesDialogUploadButton onDropFile={onDropFile} />
      {filesSection}
      {sourceSection}
    </div>
  );
};

export default withStyles(styles)(FilesDialogSidebar);
