import useAssetBlobUrlQuery from '../../assets/hooks/useAssetBlobUrlQuery';

type Props = {
  assetID: UUID;
};

export default function TeamImage(props: Props) {
  const { url } = useAssetBlobUrlQuery(props.assetID);
  return (
    <img alt="logo" className="text-primary-grey w-1/3 min-w-0 object-contain pt-5" src={url} />
  );
}
