import { useMutation } from '@apollo/client';

import { CopyItemMutation, CopyItemMutationVariables } from '../../generated/graphql';

import { copyItem } from './queries';

export const useCopyItemMutation = () => {
  const [copyItemFunc] = useMutation<CopyItemMutation, CopyItemMutationVariables>(copyItem);

  const mutation = (
    variables: CopyItemMutationVariables,
    onSuccess?: (newID: UUID) => void,
    onFailure?: () => void
  ) => {
    copyItemFunc({
      variables,
    }).then(({ data }) => {
      if (data?.copyItem) {
        if (onSuccess) onSuccess(data?.copyItem.copyItemID);
      } else if (onFailure) onFailure();
    });
  };
  return [mutation];
};
