import { LegendLabel } from '../../dashboard/DashboardCharts/DashboardChartsTrend/DashboardChartsTrendUtils';

type Props = {
  legendLabels: LegendLabel[];
};

export default function ChartLegend({ legendLabels }: Props) {
  const renderEntry = (entry: Props['legendLabels'][number]) => (
    <div key={entry.title} className="inline-block px-[10px] py-[9px]">
      <svg className="my-2 overflow-visible" height="2" width="14">
        <path
          d="M 0, 1 L 14, 1"
          style={{
            strokeWidth: entry.strokeWidth ?? 2,
            stroke: entry.color,
            strokeDasharray: entry.strokeDasharray,
          }}
        />
      </svg>
      <span className="type-label" style={{ color: '#686B6C' }}>
        {entry.title}
      </span>
    </div>
  );
  return (
    <div className="text-[12px]">
      <div>{legendLabels.map(renderEntry)}</div>
    </div>
  );
}
