import { FC, useEffect } from 'react';

import { ReactiveVar, useReactiveVar } from '@apollo/client';
import { Typography } from '@material-ui/core';
import { Help } from '@material-ui/icons';

import { setReactiveLocal } from '../../../../api/apollo/reactiveVars';
import {
  getCategorizationsForProjectFromQueryData,
  useEstimateProjectCategorizationsQuery,
} from '../../../../hooks/useProjectCategorizationsQuery';
import { withStyles } from '../../../../theme/komodo-mui-theme';
import { isMasterFormat, isUniformat } from '../../../../utilities/string';
import { getProjectIdFromUrl } from '../../../../utilities/url';
import NormalTooltip from '../../../NormalTooltip/NormalTooltip';
import { getExistingCategorizationMappings, isMultilevelCategorization } from '../../utils';

import CategorizationLine from './CategorizationLine';
import styles from './CategorizationsMapStyles';
import { computeCategorizationMap } from './CategorizationsMapUtils';

type CategorizationsMapProps = {
  classes: Classes<typeof styles>;
  importEstimateKey: string;
  importEstimateVar: ReactiveVar<ImportEstimateParameters>;
};

const CategorizationsMap: FC<CategorizationsMapProps> = ({
  classes,
  importEstimateKey,
  importEstimateVar,
}) => {
  const projectID = getProjectIdFromUrl();
  const { data } = useEstimateProjectCategorizationsQuery(projectID);
  const categorizationsProjectLocked = getCategorizationsForProjectFromQueryData(data);

  const importEstimate = useReactiveVar(importEstimateVar);
  const setMapping = (mapping: Map<string, string>, cats: DraftCategorization[]) => {
    setReactiveLocal(importEstimateVar, importEstimateKey, {
      ...importEstimate,
      mapping,
      categorizations: cats,
    });
  };

  // check if the built in categorizations are enabled
  // if they don't exist in the categorization array then they are disabled
  const isUniformatEnabled = categorizationsProjectLocked.some((c) => isUniformat(c));
  const isMasterformatEnabled = categorizationsProjectLocked.some((c) => isMasterFormat(c));

  const { categorizations: categorizationsNewVar, mapping, mappingBuiltIn } = importEstimate;
  const categorizationsNew = categorizationsNewVar.filter(
    ({ include, existingCategorization, name }) => {
      if (!mappingBuiltIn.has(name)) return include;
      // for a built-in we only want to include the lowest level mapping
      // so we don't list the built-in mapped categorizations multiple times
      const concurrentMappings = getExistingCategorizationMappings(
        mapping,
        mappingBuiltIn,
        categorizationsNewVar,
        name,
        existingCategorization
      );
      if (concurrentMappings.length && concurrentMappings[0].name === name) {
        return true;
      }
      return false;
    }
  );

  const categorizationsProject = [...categorizationsProjectLocked]
    .map((c) => ({ ...c }))
    // sort alphabetically -- TODO: does this sort exist elsewhere to re-use?
    .sort(({ name: a = '' }, { name: b = '' }) => {
      if (a < b) return -1;
      if (a > b) return 1;
      return 0;
    });

  const newCategorizationMap = new Map<string, boolean>();
  categorizationsNewVar.forEach((c) => newCategorizationMap.set(c.name, c.isDraft));
  useEffect(() => {
    if (categorizationsProjectLocked.length)
      if (mapping && Object.keys(mapping).length === 0 && mapping.constructor === Object)
        // Opened for the first time
        setMapping(
          computeCategorizationMap(
            categorizationsNew,
            categorizationsProjectLocked,
            isUniformatEnabled,
            isMasterformatEnabled
          ),
          categorizationsNewVar
        );
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO CT-566: Fix this pls :)
  }, [categorizationsProjectLocked.length]);

  // We split categorizations here into groups of multilevel categorizations and single level categorizations
  const mappedCategorizations = categorizationsNew.reduce(
    (acc: Map<string, DraftCategorization[]>, c: DraftCategorization) => {
      if (mapping.has(c.name)) {
        const v = mapping.get(c.name);
        const { isMulti } = isMultilevelCategorization(categorizationsProject, undefined, v);
        if (v && isMulti) {
          if (!acc.has(v)) {
            acc.set(v, []);
          }
          // We can optionally check for level here to prevent the edge case where it's possible to have more than
          // number of levels of columns mapped
          acc.get(v)?.push(c);
          return acc;
        }
      }
      acc.get('single')?.push(c);
      return acc;
    },
    new Map<string, DraftCategorization[]>([['single', []]])
  );

  const generateCategorizationLines = (
    draftCategorizations: DraftCategorization[],
    isMulti: boolean
  ) => {
    const commonProps = {
      importEstimateKey,
      importEstimateVar,
      draftCategorizations: categorizationsNewVar,
      mapping,
      mappingBuiltIn,
      isUniformatEnabled: false,
      isMasterformatEnabled: false,
      categorizationsProject,
      setMapping,
      newCategorizationMap,
    };

    if (isMulti && draftCategorizations.length > 1) {
      return [
        <CategorizationLine
          key={draftCategorizations[0].name}
          multilevelCategorizations={draftCategorizations}
          {...commonProps}
        />,
      ];
    }

    return draftCategorizations.map((draftCategorization) => (
      <CategorizationLine
        key={draftCategorization.name}
        draftCategorization={draftCategorization}
        {...commonProps}
      />
    ));
  };

  // Here we create the list of lines that we are rendering
  const lines: JSX.Element[] = [];

  // We start with the multilevel categorizations such that they are rendered at the top of the list
  mappedCategorizations.forEach((v, k) => {
    if (k !== 'single') {
      lines.push(
        ...generateCategorizationLines(
          v.sort((a: DraftCategorization, b: DraftCategorization) => a.level - b.level),
          true
        )
      );
    }
  });
  lines.push(...generateCategorizationLines(mappedCategorizations.get('single') || [], false));

  return (
    <>
      <div className={classes.header}>
        <Typography className={`${classes.headerText} ${classes.firstHeader}`}>
          Imported WBS Codes
        </Typography>
        <Typography className={classes.headerText}>Join Categorizations</Typography>
        <Typography className={`${classes.headerText} ${classes.flex}`}>
          <span>Match Unmatched Categories </span>
          <NormalTooltip
            title="You can resolve any unmatched categories by replacing them with an existing category, 
              changing the mapping to another categorization, or continuing the import with the errors 
              and then updating the categorization by going to Settings > Categorizations."
          >
            <Help className={classes.help} />
          </NormalTooltip>
        </Typography>
      </div>
      <div className={classes.container} style={{ width: 800 }}>
        <div className={classes.innerContainer}>
          {lines.length === 0 ? (
            <Typography>No Categorizations</Typography>
          ) : (
            lines.map((line) => line)
          )}
        </div>
      </div>
    </>
  );
};

export const StyledCategorizationsMap = withStyles(styles)(CategorizationsMap);

export default StyledCategorizationsMap;
