import joinAPI from '../../../api/joinAPI';
import {
  EstimateTotalType,
  GridAddColumnsMutation,
  GridAddColumnsMutationVariables,
  GridCreateLinesMutation,
  GridCreateLinesMutationVariables,
  GridRemoveColumnsMutation,
  GridRemoveColumnsMutationVariables,
  GridRemoveLineMutation,
  GridRemoveLineMutationVariables,
  GridReorderColumnMutation,
  GridReorderColumnMutationVariables,
  GridReorderLinesMutation,
  GridReorderLinesMutationVariables,
  GridReplaceColumnCategoriesMutation,
  GridReplaceColumnCategoriesMutationVariables,
  GridUpdateCellsMutation,
  GridUpdateCellsMutationVariables,
  QuantityCreateLinesMutation,
  QuantityCreateLinesMutationVariables,
  QuantityRemoveLineMutation,
  QuantityRemoveLineMutationVariables,
  QuantityUpdateCellsMutation,
  QuantityUpdateCellsMutationVariables,
  SetEstimateTotalTypeMutation,
  SetEstimateTotalTypeMutationVariables,
} from '../../../generated/graphql';

import {
  createOwnerCostEstimateMutation,
  gridAddColumnsMutation,
  gridCreateLinesMutation,
  gridRemoveColumnsMutation,
  gridRemoveLineMutation,
  gridReorderColumnMutation,
  gridReorderLinesMutation,
  gridReplaceColumnCategoriesMutation,
  gridUpdateCellsMutation,
  quantityCreateLinesMutation,
  quantityRemoveLineMutation,
  quantityUpdateCellsMutation,
  setEstimateTotalTypeMutation,
} from './queries';

type EstimateMutationResult = {
  data?: GridUpdateCellsMutation;
};

type QuantityMutationResult = {
  data?: QuantityUpdateCellsMutation;
};

type GridMutationResult<T extends EstimateMutationResult | QuantityMutationResult> = T;

export const estimateMutation = (
  projectID: UUID,
  estimate: UUID,
  cells: CellInput[],
  quantity: QuantityInfo | undefined,
  onSuccess: (result: GridMutationResult<EstimateMutationResult | QuantityMutationResult>) => void
) => {
  if (estimate) {
    if (quantity) {
      const variables: QuantityUpdateCellsMutationVariables = {
        projectID,
        estimate,
        cells,
      };
      joinAPI.requestGraphQL(quantityUpdateCellsMutation, variables).then(onSuccess);
    } else {
      const variables: GridUpdateCellsMutationVariables = {
        projectID,
        estimate,
        cells,
      };
      joinAPI.requestGraphQL(gridUpdateCellsMutation, variables).then(onSuccess);
    }
  }
};

type EstimateCreateLinesMutationResult = {
  data?: GridCreateLinesMutation;
};

type QuantityCreateLinesMutationResult = {
  data?: QuantityCreateLinesMutation;
};

type GridCreateLinesMutationResult<
  T extends EstimateCreateLinesMutationResult | QuantityCreateLinesMutationResult,
> = T;

export const createLines = (
  projectID: UUID,
  estimate: UUID,
  lines: LineInput[],
  quantity: QuantityInfo | undefined,
  onSuccess: (
    result: GridCreateLinesMutationResult<
      EstimateCreateLinesMutationResult | QuantityCreateLinesMutationResult
    >
  ) => void
) => {
  if (quantity) {
    const variables: QuantityCreateLinesMutationVariables = {
      projectID,
      estimate,
      lines,
    };
    joinAPI.requestGraphQL(quantityCreateLinesMutation, variables).then(onSuccess);
  } else {
    const variables: GridCreateLinesMutationVariables = {
      projectID,
      estimate,
      lines,
    };
    joinAPI.requestGraphQL(gridCreateLinesMutation, variables).then(onSuccess);
  }
};

type EstimateDeleteLinesMutationResult = {
  data?: GridRemoveLineMutation;
};

type QuantityDeleteLinesMutationResult = {
  data?: QuantityRemoveLineMutation;
};

type GridDeleteLinesMutationResult<
  T extends EstimateDeleteLinesMutationResult | QuantityDeleteLinesMutationResult,
> = T;

export const deleteLines = (
  projectID: UUID,
  estimateID: UUID,
  lineIDs: UUID[],
  quantity: QuantityInfo | undefined,
  onSuccess: (
    result: GridDeleteLinesMutationResult<
      EstimateDeleteLinesMutationResult | QuantityDeleteLinesMutationResult
    >
  ) => void
) => {
  if (!lineIDs.length) return;
  if (quantity) {
    const variables: QuantityRemoveLineMutationVariables = {
      projectID,
      estimateID,
      lineIDs,
    };
    joinAPI.requestGraphQL(quantityRemoveLineMutation, variables).then(onSuccess);
  } else {
    const variables: GridRemoveLineMutationVariables = {
      projectID,
      estimateID,
      lineIDs,
    };
    joinAPI.requestGraphQL(gridRemoveLineMutation, variables).then(onSuccess);
  }
};

export const createOwnerCostEstimate = (
  projectID: UUID,
  estimateID: UUID,
  onSuccess?: (id: UUID) => void
) => {
  const variables = {
    projectID,
    estimateID,
  };
  joinAPI.requestGraphQL(createOwnerCostEstimateMutation, variables).then(onSuccess);
};

type AddColumnsMutationResult = {
  data?: GridAddColumnsMutation;
};

export const createColumns = (
  projectID: UUID,
  estimateID: UUID,
  fieldInputs: FieldInput[],
  onSuccess: (result: AddColumnsMutationResult) => void
) => {
  const variables: GridAddColumnsMutationVariables = {
    projectID,
    estimateID,
    fields: fieldInputs,
  };
  joinAPI.requestGraphQL(gridAddColumnsMutation, variables).then(onSuccess);
};

type ReorderColumnMutationResult = {
  data?: GridReorderColumnMutation;
};

export const reorderColumn = (
  projectID: UUID,
  estimateID: UUID,
  fieldID: UUID,
  ordering: number,
  onSuccess: (result: ReorderColumnMutationResult) => void
) => {
  const variables: GridReorderColumnMutationVariables = {
    projectID,
    estimateID,
    fieldID,
    ordering,
  };
  joinAPI.requestGraphQL(gridReorderColumnMutation, variables).then(onSuccess);
};

type RemoveColumnsMutationResult = {
  data?: GridRemoveColumnsMutation;
};

export const deleteColumns = (
  projectID: UUID,
  estimateID: UUID,
  fieldIDs: UUID[],
  onSuccess: (result: RemoveColumnsMutationResult) => void
) => {
  if (!fieldIDs.length) return;
  const variables: GridRemoveColumnsMutationVariables = {
    projectID,
    estimateID,
    fieldIDs,
  };
  joinAPI.requestGraphQL(gridRemoveColumnsMutation, variables).then(onSuccess);
};

type ReorderLineMutationResult = {
  data?: GridReorderLinesMutation;
};

export const reorderLine = (
  projectID: UUID,
  estimate: UUID | undefined,
  lines: ReorderLineInput[],
  onSuccess: (result: ReorderLineMutationResult) => void
) => {
  if (!estimate) return;
  const variables: GridReorderLinesMutationVariables = {
    projectID,
    estimate,
    lines,
  };
  joinAPI.requestGraphQL(gridReorderLinesMutation, variables).then(onSuccess);
};

export type SetEstimateTotalTypeMutationResult = {
  data?: SetEstimateTotalTypeMutation;
};

export const setEstimateTotalType = (
  projectID: UUID,
  estimateID: UUID,
  estimateTotalType: EstimateTotalType,
  onSuccess: (result: SetEstimateTotalTypeMutationResult) => void
) => {
  const variables: SetEstimateTotalTypeMutationVariables = {
    projectID,
    estimateID,
    estimateTotalType,
  };
  joinAPI.requestGraphQL(setEstimateTotalTypeMutation, variables).then(onSuccess);
};

type ReplaceCategoriesResult = {
  data?: GridReplaceColumnCategoriesMutation;
};

export const replaceFieldCategory = (
  projectID: UUID,
  estimateID: UUID,
  fieldID: UUID,
  oldValue: string,
  newValue: UUID,
  onSuccess: (result: ReplaceCategoriesResult) => void
) => {
  const variables: GridReplaceColumnCategoriesMutationVariables = {
    projectID,
    estimateID,
    fieldID,
    fromCategories: [oldValue],
    toCategories: [newValue],
  };
  joinAPI.requestGraphQL(gridReplaceColumnCategoriesMutation, variables).then(onSuccess);
};
