import { FC, useState } from 'react';

import { ButtonBase } from '@material-ui/core';
import { Fullscreen } from '@material-ui/icons';

import { IMAGE } from '../../../constants';
import { withStyles } from '../../../theme/komodo-mui-theme';
import AssetsViewer from '../AssetsViewer/AssetsViewer';

import ImageViewerWrapperStyles from './AssetsProjectCompsImageWrapperStyles';

type ImageViewerWrapperProps = {
  asset: ProjectThumbnail;
  blobUrl: string;
  children: JSX.Element;
  classes: Classes<typeof ImageViewerWrapperStyles>;
};

const ImageViewerWrapper: FC<ImageViewerWrapperProps> = ({ asset, blobUrl, children, classes }) => {
  const [openViewer, setOpenViewer] = useState(false);

  return (
    <>
      <AssetsViewer
        asset={asset}
        blobURL={blobUrl}
        closeModal={() => setOpenViewer(false)}
        displayURL={undefined}
        hideActions
        open={openViewer}
        showMenu
        type={IMAGE}
        viewOnly
      />
      <ButtonBase
        className={classes.thumbnailButton}
        onClick={() => {
          setOpenViewer(true);
        }}
      >
        {children}
        <div className={classes.expandThumbnailButton}>
          <Fullscreen />
        </div>
      </ButtonBase>
    </>
  );
};

export default withStyles(ImageViewerWrapperStyles)(ImageViewerWrapper);
