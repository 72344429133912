import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';

const ItemsDetailsTopNavStyles = (theme: KomodoTheme) =>
  createStyles({
    a: {
      ...theme.linkFocus,
      borderRadius: theme.border.radius,
      color: theme.palette.primaryGrey,
      cursor: 'pointer',
      textDecoration: 'underline',
    },
    breadcrumbs: {
      display: 'flex',
      alignItems: 'center',
    },
    breadcrumbsLocation: {
      paddingLeft: `${theme.spacing.generic[1]}px`,
    },
    currentNodeNumber: {
      fontWeight: 400,
      position: 'relative',
      width: 'auto',
    },
    divider: {
      padding: `0 ${theme.spacing.generic[2]}px`,
      position: 'relative',
      width: 'auto',
    },
    itemParentBreadcrumbs: {
      marginRight: `-${theme.spacing.generic[3]}px`,
      paddingLeft: `${theme.spacing.generic[4]}px`,
    },
    linkNumber: {
      display: 'flex',
      alignItems: 'center',
    },
    outerContainer: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: `space-between`,
      paddingBottom: `${theme.spacing.generic[8]}px`,
      paddingTop: `${theme.spacing.generic[11]}px`,
    },
    p: {
      overflow: 'hidden',
      paddingLeft: `${theme.spacing.generic[1]}px`,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
  });

export default ItemsDetailsTopNavStyles;
