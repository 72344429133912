import { renderCostString } from '../CostReport/CostReportUtils';

type ContentRowProps = {
  label: string;
  value: number;
  isBold: boolean;
  textColor: string;
};
export default function ContentRow({ label, value, isBold, textColor }: ContentRowProps) {
  const Wrapper = isBold ? 'strong' : 'div';

  return (
    <div className={`flex justify-between type-body2 ${textColor}`}>
      <Wrapper>{label}</Wrapper>
      <Wrapper>{renderCostString({ cost: value, showCents: false })}</Wrapper>
    </div>
  );
}
