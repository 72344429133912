import useAssetBlobUrlQuery from '../assets/hooks/useAssetBlobUrlQuery';

type Props = {
  name: string | undefined;
  role: string;
  image: string;
};

export default function TeamImage(props: Props) {
  const { name, role, image } = props;
  const { url } = useAssetBlobUrlQuery(image);
  if (!url) return null;
  return (
    <div className="h-full px-4 py-2">
      <img
        alt={`${name} logo (${role})`}
        className="h-full bg-background-primary object-cover print:p-1"
        src={url}
      />
    </div>
  );
}
