import { createStyles } from '@material-ui/core';

const CheckboxContainerStyles = () =>
  createStyles({
    checkboxUnnestedList: {
      padding: '2px',
      width: '48px',
    },
    checkboxWidthWithOptions: {
      width: '30px',
    },
    checkboxWidthWithGroupings: {
      width: '40px',
    },
    checkboxNestedList: {
      paddingLeft: '30px',
    },
  });

export default CheckboxContainerStyles;
