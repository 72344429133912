import { FC } from 'react';

import AssetsCardsHint from '../../../assets/AssetsCards/AssetsCardsHint/AssetsCardsHint';
import AddUploadDrag from '../../../shared-widgets/AddUploadDrag/AddUploadDrag';

type FilesAddUploadDragProps = {
  addFiles: (files: FileList | null) => void;
};

const FilesAddUploadDrag: FC<FilesAddUploadDragProps> = ({ addFiles }) => (
  <AddUploadDrag
    acceptedFileTypeComponent={<AssetsCardsHint isFileExplorer />}
    addFiles={addFiles}
  />
);

export default FilesAddUploadDrag;
