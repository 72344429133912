import { useMutation } from '@apollo/client';

import { gql } from '../api/graphqlFragments';
import { REFETCH_ITEM_INTEGRATIONS } from '../api/refetchSets';
import {
  SourceSystem,
  SourceType,
  UnlinkItemIntegrationObjectInput,
  UnlinkItemIntegrationObjectMutation,
  UnlinkItemIntegrationObjectMutationOptions,
  UnlinkItemIntegrationObjectMutationVariables,
} from '../generated/graphql';

const unlinkItemIntegrationObjectMutation = gql`
  mutation UnlinkItemIntegrationObject(
    $projectID: UUID!
    $itemID: UUID!
    $sourceSystem: SourceSystem!
    $sourceType: SourceType!
    $sourceID: UUID!
    $input: UnlinkItemIntegrationObjectInput!
  ) {
    unlinkItemIntegrationObject(
      projectID: $projectID
      itemID: $itemID
      sourceSystem: $sourceSystem
      sourceType: $sourceType
      sourceID: $sourceID
      input: $input
    )
  }
`;

export default function useUnlinkItemIntegrationObjectMutation(
  options?: UnlinkItemIntegrationObjectMutationOptions
) {
  const [mutationFunc, mutationResult] = useMutation<
    UnlinkItemIntegrationObjectMutation,
    UnlinkItemIntegrationObjectMutationVariables
  >(unlinkItemIntegrationObjectMutation, options);

  const submitFunc = (
    projectID: UUID,
    itemID: UUID,
    sourceSystem: SourceSystem,
    sourceType: SourceType,
    sourceID: UUID,
    input: UnlinkItemIntegrationObjectInput
  ) => {
    return mutationFunc({
      variables: { projectID, sourceSystem, sourceType, itemID, sourceID, input },
      refetchQueries: REFETCH_ITEM_INTEGRATIONS,
    });
  };

  return [submitFunc, mutationResult] as const;
}
