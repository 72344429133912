import { useRef, useState } from 'react';
import { useButton } from 'react-aria';

import { ArrowDropDown, ArrowDropUp } from '@material-ui/icons';

import { pluralizeCountString } from '../../../../utilities/string';
import { BabyButton } from '../../../scales';

import ShareItemBulkSummaryEntry, { Props as EntryProps } from './ShareItemBulkSummaryEntry';

export type Props = {
  entries: EntryProps[];
  onClick?: () => void;
};

export default function ShareItemBulkSummary(props: Props) {
  const btnRef = useRef<HTMLDivElement>(null);
  const { buttonProps } = useButton(
    {
      onPress: () => {
        setIsExpanded(!isExpanded);
        props.onClick?.();
      },
    },
    btnRef
  );
  const [isExpanded, setIsExpanded] = useState(false);
  const sharedItemsCount = props.entries.length;
  const sharedText = `${pluralizeCountString('draft item', sharedItemsCount)} selected`;
  return (
    <div className="flex flex-col gap-2 bg-background-2 p-2">
      <div {...buttonProps} className="flex cursor-pointer items-center">
        <div className="mr-auto type-heading3">{sharedText}</div>
        <BabyButton
          aria-label="expand shared"
          icon={isExpanded ? <ArrowDropUp /> : <ArrowDropDown />}
          onClick={() => setIsExpanded(!isExpanded)}
        />
      </div>
      {isExpanded &&
        props.entries.map((entry) => <ShareItemBulkSummaryEntry key={entry.name} {...entry} />)}
    </div>
  );
}
