import { Add, FileCopy } from '@material-ui/icons';

import { MenuButton } from '../../scales';

type Props = {
  onCreateScenario: (args: { cloneMilestoneItems: boolean }) => void;
};

const CreateScenarioButton = (props: Props) => {
  return (
    <div className="flex flex-col gap-2">
      <MenuButton
        data-cy="create-scenario-menu-button"
        entries={[
          {
            id: 'empty',
            label: 'Start with blank scenario',
            onClick: () => props.onCreateScenario({ cloneMilestoneItems: false }),
            startAdornment: <Add />,
          },
          {
            id: 'copy',
            label: 'Copy items from base milestone',
            onClick: () => props.onCreateScenario({ cloneMilestoneItems: true }),
            startAdornment: <FileCopy />,
          },
        ]}
        isFullWidth
        label="Create a new Scenario"
        startIcon={<Add />}
        type="secondary"
      />
    </div>
  );
};
export default CreateScenarioButton;
