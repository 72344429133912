import { useMutation } from '@apollo/client';

import {
  DeleteOrgNodeMutation,
  DeleteOrgNodeMutationVariables,
} from '../../../../generated/graphql';
import { deleteOrgNodeMutation } from '../../../Organizations/hooks/queries';

const useDeleteOrgNodeMutation = () => {
  const [deleteOrgNode] = useMutation<DeleteOrgNodeMutation, DeleteOrgNodeMutationVariables>(
    deleteOrgNodeMutation
  );
  return (nodeID: UUID) => {
    return deleteOrgNode({
      variables: {
        input: {
          nodeID,
        },
      },
    });
  };
};

export default useDeleteOrgNodeMutation;
