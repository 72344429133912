import { FC, useEffect, useState } from 'react';
import { useCounter } from 'react-use';

import { addItemCommentAnalytics } from '../../../analytics/analyticsEventProperties';
import { ResourceType } from '../../../generated/graphql';
import useSendAnalytics from '../../../hooks/useSendAnalytics';
import { isTextValid } from '../../../utilities/string';
import { toIds, toSharedResourceUsers } from '../../../utilities/utilities';
import { getUserPicture } from '../../Collaborators/UserUtils';
import { isPrivateVisibility } from '../../Items/ItemsUtils';
import { useGetSharedResource } from '../../ItemsList/ItemsSharing/hooks/useGetShareResourceHook';
import useCreateComment from '../hooks';
import { defaultComment } from '../MentionableTextArea/MentionableTextArea';

import CommentEntryInterface from './CommentEntryInterface';
import { getStoredDraftComment, setStoredDraftComment } from './CommentEntryUtils';

type CommentProps = {
  item: Pick<ItemDataQueryItem, 'id' | 'comments' | 'visibility'>;
  user: Pick<User, 'pictureAsset' | 'picture'> | null;
  onItemMutated?: () => void;
};

const Comment: FC<CommentProps> = ({ item, user, onItemMutated }) => {
  const isPrivate = isPrivateVisibility(item?.visibility);
  const { data: sharedResourceData, loading: loadingSharedResource } = useGetSharedResource(
    item.id,
    ResourceType.ITEM,
    !isPrivate
  );
  const sharedUsersIDs = toIds(toSharedResourceUsers(sharedResourceData));

  const [createComment] = useCreateComment(item, { onCompleted: onItemMutated });
  const [comment, setComment] = useState(getStoredDraftComment(item.id) || defaultComment);
  useEffect(() => {
    setStoredDraftComment(comment, item.id);
  }, [item.id, comment]);
  const [key, keyActions] = useCounter();
  const { contents } = comment;

  const sendAnalytics = useSendAnalytics();

  const submitComment = () => {
    if (isTextValid(contents)) {
      keyActions.inc();
      createComment(comment);
      sendAnalytics(addItemCommentAnalytics({ itemId: item.id, comment: contents }));
      setComment(defaultComment);
      setStoredDraftComment(null, item.id);
    }
  };

  const loading = loadingSharedResource;
  if (loading) return null;

  return (
    <CommentEntryInterface
      key={key}
      comment={comment}
      isPrivate={isPrivate}
      setComment={setComment}
      sharedUsersIDs={sharedUsersIDs}
      submitComment={submitComment}
      userPicture={getUserPicture(user)}
    />
  );
};

export default Comment;
