import { FC, useEffect, useState } from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  IconButton,
  Typography,
} from '@material-ui/core';
import { Add, Close, Info } from '@material-ui/icons';

import {
  CategorizationEvent,
  categorizationEvent,
} from '../../../analytics/analyticsEventProperties';
import useSendAnalytics from '../../../hooks/useSendAnalytics';
import { useStandardCategorizationsQuery } from '../../../hooks/useStandardCategorizationsQuery';
import { withStyles } from '../../../theme/komodo-mui-theme';
import { getProjectIdFromUrl } from '../../../utilities/url';
import { useAddCompanyCategorizations } from '../../CompanyTab/Standards/CompanyCategorizations/hooks';
import styles from '../../Dialogs/DialogsStyles';
import NormalTooltip from '../../NormalTooltip/NormalTooltip';
import CTAIconButton from '../../shared-widgets/CTAIconButton';
import useGetCategorization from '../tableUtils/GetCategorization';

import ConfirmDeleteCategorizationsDialog from './ConfirmDeleteCategorizationsDialog';
import JoinGridAddCategoriesDialog from './JoinGridAddCategoriesDialog';
import MultilevelCategorizationsDialog from './MultilevelCategorizationsDialog';
import ProjectCategorizationsList from './ProjectCategorizationsList';

type ManageCategorizationsDialogProps = {
  classes: Classes<typeof styles>;
  onClose: () => void;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  open: boolean;
  onCreateNew: () => void;
};

const ManageCategorizationsDialog: FC<ManageCategorizationsDialogProps> = ({
  classes,
  onClose,
  open,
  onCreateNew,
}) => {
  const standardsTooltip =
    'Standard categorizations are categorizations provided and managed by the company.  These help to quick start projects with the benefit of cross project standardization.  If you need to change a categorization, please contact the company admin.';

  const [openProjectCategorizationInfo, setOpenProjectCategorizationInfo] = useState({
    dialogOpen: false,
    categorizationID: '',
  });

  const [openConfirmDeleteCategorization, setOpenConfirmDeleteCategorization] = useState(false);

  const projectID = getProjectIdFromUrl();
  const sendAnalytics = useSendAnalytics();

  const { data } = useStandardCategorizationsQuery(projectID);

  const currentCategorizations = data?.standardCategorizations ?? [];

  const categorizationMetadata = useGetCategorization(
    openProjectCategorizationInfo.categorizationID
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  const categorizationToggleState: any = {};
  const [catSelectState, setCatSelectState] = useState(categorizationToggleState);

  const count = currentCategorizations?.length;

  currentCategorizations.forEach((categorization) => {
    categorizationToggleState[categorization.categorization.id] = !categorization.disabled;
  });

  const onCheckToggle = (catID: string) => {
    const temp = catSelectState;
    temp[catID] = !temp[catID];
    setCatSelectState({ ...temp });
  };

  const [catIDToAdd, setCatIDToAdd] = useState<UUID[]>([]);
  const [catIDToRemove, setCatIDToRemove] = useState<UUID[]>([]);
  const [catNameToRemove, setCatNameToRemove] = useState<string[]>([]);

  const onSubmit = () => {
    const tempAdd: UUID[] = [];
    const tempRemove: UUID[] = [];
    const tempName: string[] = [];

    currentCategorizations.forEach((cat) => {
      if (cat.disabled && catSelectState[cat.categorization.id]) {
        tempAdd.push(cat.categorization.id);
      } else if (!cat.disabled && !catSelectState[cat.categorization.id]) {
        tempName.push(cat.categorization.name);
        tempRemove.push(cat.categorization.id);
      }
    });
    setCatIDToAdd([...tempAdd]);
    setCatIDToRemove([...tempRemove]);
    setCatNameToRemove([...tempName]);
    if (tempRemove.length > 0) {
      setOpenConfirmDeleteCategorization(true);
    }
    if (tempRemove.length === 0 && tempAdd.length === 0) {
      setOpenConfirmDeleteCategorization(false);
      onClose();
    }
  };

  const addCategorizations = useAddCompanyCategorizations();

  useEffect(() => {
    if (data && categorizationToggleState !== catSelectState) {
      setCatSelectState({ ...categorizationToggleState });
    }
    if (catIDToAdd.length > 0 && catIDToRemove.length === 0) {
      addCategorizations(projectID, catIDToAdd, onClose());
      setCatIDToAdd([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO CT-566: Fix this pls :)
  }, [data, catIDToAdd, catIDToRemove, onClose]);
  // Note: Adding missing 'addCategorizations' dependency will break this

  const isMultilevel = categorizationMetadata?.data
    ? categorizationMetadata.data?.categorization?.levels > 1
    : null;
  const selectAllButton = (enableAll: boolean) => (
    <Typography className={`${classes.paddingBottomHalf}`} variant="body2">
      <button
        className={classes.linkButton}
        onClick={() => {
          const temp = new Map(Object.entries(catSelectState));
          temp.forEach((value, key) => {
            if (enableAll) {
              temp.set(key, true);
            } else {
              temp.set(key, false);
            }
          });
          setCatSelectState(Object.fromEntries(temp));
          sendAnalytics(
            categorizationEvent(
              enableAll
                ? CategorizationEvent.MANAGE_SELECT_ALL
                : CategorizationEvent.MANAGE_UNSELECT_ALL
            )
          );
        }}
        type="button"
      >
        {enableAll ? 'Select All' : 'Unselect All'}
      </button>
    </Typography>
  );

  return (
    <Dialog disableBackdropClick onClose={onClose} open={open}>
      <div className={classes.titleContainer}>
        <Typography variant="title">Manage Categorizations</Typography>
        <IconButton className={classes.close} title="Close dialog">
          <Close
            onClick={() => {
              sendAnalytics(categorizationEvent(CategorizationEvent.MANAGE_CLOSE, { projectID }));
              onClose();
              setOpenProjectCategorizationInfo({
                dialogOpen: false,
                categorizationID: '',
              });
            }}
          />
        </IconButton>
      </div>
      <Divider />
      <DialogContent
        className={
          !openProjectCategorizationInfo.dialogOpen ? classes.content : classes.viewOnlyPadding
        }
      >
        {!openProjectCategorizationInfo.dialogOpen && !openConfirmDeleteCategorization && (
          <>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <Typography
                className={`${classes.paddingBottomHalf} ${classes.flex} ${classes.alignItemsCenter}`}
                style={{ flexGrow: 1 }}
              >
                {count} standard categorizations available
                <NormalTooltip title={standardsTooltip}>
                  <Info
                    className={classes.iconTooltip}
                    onMouseEnter={() => {
                      sendAnalytics(
                        categorizationEvent(CategorizationEvent.MANAGE_STANDARD_TOOLTIP)
                      );
                    }}
                  />
                </NormalTooltip>
              </Typography>
              <div style={{ display: 'flex' }}>
                {selectAllButton(true)}
                {selectAllButton(false)}
              </div>
            </div>
            <ProjectCategorizationsList
              categorizations={currentCategorizations}
              onToggle={onCheckToggle}
              selectedCategorizations={catSelectState}
              setOpenProjectCategorizationInfo={(categorizationID: UUID) => {
                setOpenProjectCategorizationInfo({
                  dialogOpen: true,
                  categorizationID,
                });
                sendAnalytics(
                  categorizationEvent(CategorizationEvent.MANAGE_STANDARD_VIEW_CTA, {
                    projectID,
                    categorizationID,
                  })
                );
              }}
            />
          </>
        )}
      </DialogContent>
      {openProjectCategorizationInfo.dialogOpen &&
        categorizationMetadata?.data?.categorization &&
        isMultilevel && (
          <MultilevelCategorizationsDialog
            categorization={categorizationMetadata.data.categorization}
            contentOnly
            onClose={onClose}
            projectId={projectID}
            viewOnly
          />
        )}
      {openProjectCategorizationInfo.dialogOpen &&
        categorizationMetadata?.data?.categorization &&
        !isMultilevel && (
          <JoinGridAddCategoriesDialog
            buttonText="Done"
            categorization={categorizationMetadata.data.categorization}
            contentOnly
            onClose={onClose}
            title="View Categories"
            viewOnly
          />
        )}

      {openConfirmDeleteCategorization && !openProjectCategorizationInfo.dialogOpen && (
        <ConfirmDeleteCategorizationsDialog
          categorizationNames={catNameToRemove}
          catIDToAdd={catIDToAdd}
          catIDToRemove={catIDToRemove}
          onCancel={() => setOpenConfirmDeleteCategorization(false)}
          onClose={onClose}
        />
      )}

      {!openProjectCategorizationInfo.dialogOpen && !openConfirmDeleteCategorization && (
        <DialogActions className={classes.action}>
          <>
            <CTAIconButton
              buttonText="Create New"
              dataCy="ManageCategorizationsDialog-Create-button"
              icon={<Add />}
              onClick={() => {
                onCreateNew();
                sendAnalytics(
                  categorizationEvent(CategorizationEvent.MANAGE_CREATE_NEW_CTA, { projectID })
                );
              }}
              variant="outlined"
            />
            <Button
              color="primary"
              onClick={() => {
                onSubmit();
                sendAnalytics(
                  categorizationEvent(CategorizationEvent.MANAGE_CONFIRM_CTA, { projectID })
                );
              }}
              variant="contained"
            >
              Confirm Selection
            </Button>
          </>
        </DialogActions>
      )}
      {openProjectCategorizationInfo.dialogOpen && (
        <DialogActions className={classes.action}>
          <>
            <Button
              color="primary"
              onClick={() => {
                setOpenProjectCategorizationInfo({ dialogOpen: false, categorizationID: '' });
                sendAnalytics(
                  categorizationEvent(
                    isMultilevel
                      ? CategorizationEvent.MANAGE_STANDARD_ML_VIEW_BACK
                      : CategorizationEvent.MANAGE_STANDARD_SL_VIEW_BACK,
                    { projectID }
                  )
                );
              }}
              variant="outlined"
            >
              Back
            </Button>
            <Button
              color="primary"
              data-cy="button-toggle-select"
              onClick={() => {
                onCheckToggle(openProjectCategorizationInfo.categorizationID);
                setOpenProjectCategorizationInfo({ dialogOpen: false, categorizationID: '' });
                if (catSelectState[openProjectCategorizationInfo.categorizationID]) {
                  sendAnalytics(
                    categorizationEvent(
                      isMultilevel
                        ? CategorizationEvent.MANAGE_STANDARD_ML_VIEW_SELECT
                        : CategorizationEvent.MANAGE_STANDARD_SL_VIEW_SELECT,
                      { projectID }
                    )
                  );
                } else {
                  sendAnalytics(
                    categorizationEvent(
                      isMultilevel
                        ? CategorizationEvent.MANAGE_STANDARD_ML_VIEW_UNSELECT
                        : CategorizationEvent.MANAGE_STANDARD_SL_VIEW_UNSELECT,
                      { projectID }
                    )
                  );
                }
              }}
              variant="contained"
            >
              {catSelectState[openProjectCategorizationInfo.categorizationID]
                ? 'Unselect'
                : 'Select'}
            </Button>
          </>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default withStyles(styles)(ManageCategorizationsDialog);
