import { useReactiveVar } from '@apollo/client';

import { Selected, selectedAssetsVar } from '../../../../api/apollo/reactiveVars';
import { onNavigationForward } from '../../FileExplorer/FilesExplorerUtils';
import { updateSelectedMap } from '../FilesAssets/FilesAssetsUtils';

import { FileTableCell, getSelectedIds } from './FilesTableUtils';

export const useFilesTableSelection = () => {
  const { selectedMap } = useReactiveVar(selectedAssetsVar);
  const selectedIds = getSelectedIds(selectedMap);
  const toggleSelected = (id: string) => {
    const { selectedMap } = selectedAssetsVar();
    updateSelectedMap({ [id]: !selectedMap[id] });
  };
  const onEntry = (e: FileTableCell, element: JSX.Element) => {
    if (e.isFolder) {
      onNavigationForward(e, element);
    } else {
      const instanceName = 'App4join.egnyte.com';
      const deepLink = `https://${instanceName}/navigate/path/${e.remoteLink}`;
      window.open(deepLink, '_blank');
      toggleSelected(e.id);
    }
  };
  const onToggleEntry = ({ id }: FileTableCell) => {
    toggleSelected(id);
  };
  const onSelectAll = (ids: string[]) => {
    const selectedAssets = selectedAssetsVar();
    const { selectedMap } = selectedAssets;
    ids.forEach((id) => delete selectedMap[id]);
    const newSelectedMap = ids.reduce((ac, v) => ({ ...ac, [v]: true }), {});
    selectedAssetsVar({ ...selectedAssets, selectedMap: { ...selectedMap, ...newSelectedMap } });
  };
  const onUnselectAll = (ids: string[]) => {
    const selectedAssets = selectedAssetsVar();
    ids.forEach((id) => delete selectedAssets.selectedMap[id]);
    selectedAssetsVar({ ...selectedAssets });
  };
  return { onEntry, toggleSelected, onToggleEntry, onSelectAll, onUnselectAll, selectedIds };
};

export const filterSelectedAssets = (selectedMap: Selected) =>
  Object.keys(selectedMap).filter((assetID) => selectedMap[assetID]);
