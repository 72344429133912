import { useMutation } from '@apollo/client';

import { REFETCH_SCENARIO_SANDBOX } from '../../../api/refetchSets';
import { UpdateScenarioMetadataMutationVariables } from '../../../generated/graphql';

import { updateScenarioMetadataMutation } from './queries';

const useUpdateScenarioMetadataMutation = () => {
  const [updateFn] = useMutation(updateScenarioMetadataMutation, {
    refetchQueries: REFETCH_SCENARIO_SANDBOX,
  });

  const updateMetadata = (variables: UpdateScenarioMetadataMutationVariables) =>
    updateFn({ variables });

  return [updateMetadata];
};

export default useUpdateScenarioMetadataMutation;
