import { FC } from 'react';

import { CircularProgress } from '@material-ui/core';

import theme from '../../../../theme/komodo-mui-theme';

import { MAX_NUM_COMBINATIONS } from './MilestoneDetailsQuantitiesUtils';

type MilestoneDetailsQuantitiesCateogorizationDiagramProps = {
  categoryCount: number;
  categorizationCount: number;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  loading?: boolean;
};

const numberColor = 'black';
const EMPTY_COUNT = '--';

const {
  backgroundWhite,
  chartMediumGreen,
  chartBlue,
  notapplicable,
  pending,
  chartBlack,
  chartGreen,
  pinkBadge,
  chartsThemes: { orange },
} = theme.palette;

const colorRects: JSX.Element[][] = [
  [
    <rect key="blue-1" fill={backgroundWhite} height="41" width="85" x="198" y="17" />,
    <rect key="blue-2" fill={chartBlue} height="41" opacity="0.25" width="85" x="198" y="17" />,
    <rect key="blue-3" fill={chartBlue} height="16" width="85" x="198" />,
  ],
  [
    <rect key="pink-1" fill={backgroundWhite} height="41" width="85" x="206" y="17" />,
    <rect key="pink-2" fill={pinkBadge} height="41" opacity="0.25" width="85" x="206" y="17" />,
    <rect key="pink-3" fill={pinkBadge} height="16" width="85" x="206" />,
  ],
  [
    <rect key="orange-1" fill={backgroundWhite} height="41" width="85" x="213" y="17" />,
    <rect key="orange-2" fill={orange} height="41" opacity="0.25" width="85" x="213" y="17" />,
    <rect key="orange-3" fill={orange} height="16" width="85" x="213" />,
  ],
  [
    <rect key="pending-1" fill={backgroundWhite} height="41" width="85" x="220" y="17" />,
    <rect key="pending-2" fill={pending} height="41" opacity="0.25" width="85" x="220" y="17" />,
    <rect key="pending-3" fill={pending} height="16" width="85" x="220" />,
  ],
  [
    <rect key="green-1" fill={backgroundWhite} height="41" width="85" x="227" y="17" />,
    <rect key="green-2" fill={chartGreen} height="41" opacity="0.25" width="85" x="227" y="17" />,
    <rect key="green-3" fill={chartGreen} height="16" width="85" x="227" />,
  ],
  [
    <rect key="NA-1" fill={backgroundWhite} height="41" width="85" x="234" y="17" />,
    <rect key="NA-2" fill={notapplicable} height="41" opacity="0.25" width="85" x="234" y="17" />,
    <rect key="NA-3" fill={notapplicable} height="16" width="85" x="234" />,
  ],
];

const getColorBars = (count: number) => {
  const index = count - 1;
  const rectangleArrays: JSX.Element[][] = colorRects.slice(0, index);
  // We reverse to restack the first ones on top (order matters for SVG layers)
  // then flatten to have an array of Elements
  return rectangleArrays.reverse().flat();
};

// These are fine-tuned to display well in the space
const getFontSize = (count: number) => {
  if (count >= MAX_NUM_COMBINATIONS) return 24;
  if (count > 99) return 30;
  if (count > 9) return 36;
  return 42;
};

const getCountString = (count: number, hasCategorizations: boolean) => {
  if (!hasCategorizations) return EMPTY_COUNT;
  if (count >= MAX_NUM_COMBINATIONS) return '1000+';
  return String(count);
};

const CAT_1_CENTER = 42;
const COMBO_CENTER = 142;
const CAT_2_CENTER = 242;
const CAT_NAME_HEIGHT = 9;
const RECT_WIDTH = 76;
const PROGRESS_WIDTH = 32;

const MilestoneDetailsQuantitiesCategorizationDiagram: FC<
  MilestoneDetailsQuantitiesCateogorizationDiagramProps
> = ({ categoryCount, categorizationCount, loading = false }) => {
  const fontSize = getFontSize(categoryCount);
  const hasCategorizations = categorizationCount > 0;
  const hasManyCategorizations = categorizationCount > 1;
  const numberXposition = hasManyCategorizations ? COMBO_CENTER : CAT_1_CENTER;
  const firstCatzColor = hasCategorizations ? chartMediumGreen : notapplicable;
  return (
    <svg
      fill="none"
      height="58"
      viewBox="0 0 319 58"
      width="319"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect fill={firstCatzColor} height="16" width="85" />
      <rect fill={firstCatzColor} height="41" opacity="0.25" width="85" y="17" />
      {hasManyCategorizations && (
        <>
          {getColorBars(categorizationCount)}
          <line stroke={notapplicable} x1="85" x2="198" y1="23.5" y2="23.5" />
          <line stroke={notapplicable} x1="85.0615" x2="198.061" y1="23.5038" y2="37.5038" />
          <line stroke={notapplicable} x1="85.1203" x2="198.12" y1="23.5147" y2="51.5147" />
          <line stroke={notapplicable} x1="85" x2="198" y1="37.5" y2="37.5" />
          <line stroke={notapplicable} x1="84.9385" x2="197.939" y1="37.5038" y2="23.5038" />
          <line stroke={notapplicable} x1="85.0615" x2="198.061" y1="37.5038" y2="51.5038" />
          <line stroke={notapplicable} x1="85" x2="198" y1="51.5" y2="51.5" />
          <line stroke={notapplicable} x1="84.9385" x2="197.939" y1="51.5038" y2="37.5038" />
          <line stroke={notapplicable} x1="84.8797" x2="197.88" y1="51.5147" y2="23.5147" />
          <circle cx="198" cy="23.5" fill={chartBlue} r="4" />
          <circle cx="198" cy="37.5" fill={chartBlue} r="4" />
          <circle cx="198" cy="51.5" fill={chartBlue} r="4" />
          <circle cx="84" cy="23.5" fill={chartMediumGreen} r="4" />
          <circle cx="84" cy="37.5" fill={chartMediumGreen} r="4" />
          <circle cx="84" cy="51.5" fill={chartMediumGreen} r="4" />
          <text // the location is correlated to the center of the box
            dominantBaseline="middle"
            fill={backgroundWhite}
            fontFamily={theme.typography.fontFamily}
            fontSize={10}
            textAnchor="middle"
            x={CAT_2_CENTER}
            y={CAT_NAME_HEIGHT}
          >
            Categorization 2
          </text>
          <rect fill={backgroundWhite} height="58" opacity="0.7" width={RECT_WIDTH} x="103" />
          <text // the location is correlated to the center of the box
            dominantBaseline="middle"
            fill={chartBlack}
            fontFamily={theme.typography.fontFamily}
            fontSize={10}
            textAnchor="middle"
            x={COMBO_CENTER}
            y={CAT_NAME_HEIGHT}
          >
            Total Combos
          </text>
        </>
      )}
      <text // the location is correlated to the center of the box
        dominantBaseline="middle"
        fill={backgroundWhite}
        fontFamily={theme.typography.fontFamily}
        fontSize={10}
        textAnchor="middle"
        x={CAT_1_CENTER}
        y={CAT_NAME_HEIGHT}
      >
        {hasCategorizations ? 'Categorization 1' : 'Categorization'}
      </text>
      {loading ? (
        <foreignObject
          dominantBaseline="middle"
          height="58"
          textAnchor="middle"
          width={RECT_WIDTH}
          x={numberXposition - PROGRESS_WIDTH / 2}
          y={38 - PROGRESS_WIDTH / 2}
        >
          <CircularProgress size={PROGRESS_WIDTH} />
        </foreignObject>
      ) : (
        <text // the location is correlated to the center of the box
          dominantBaseline="middle"
          fill={numberColor}
          fontSize={fontSize}
          fontWeight="bold"
          textAnchor="middle"
          x={numberXposition}
          y="41"
        >
          {getCountString(categoryCount, hasCategorizations)}
        </text>
      )}
    </svg>
  );
};

export default MilestoneDetailsQuantitiesCategorizationDiagram;
