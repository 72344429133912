import { useEffect } from 'react';

import { Typography } from '@material-ui/core';

import { EXPORT_MILESTONE_SUMMARY_REPORT } from '../../../actions/actionTypes';
import { analyticsEvent } from '../../../analytics/analyticsEventProperties';
import { isDownloadingCostReportToExcelVar } from '../../../api/apollo/reactiveVars';
import JoinAPI from '../../../api/joinAPI';
import { COSTS, DISPLAY } from '../../../constants';
import { PermissionResource } from '../../../generated/graphql';
import {
  getCategorizationsForProjectFromQueryData,
  useProjectCategorizationsQuery,
} from '../../../hooks/useProjectCategorizationsQuery';
import useSendAnalytics from '../../../hooks/useSendAnalytics';
import { getDefaultReportCategorizations } from '../../../utilities/categorization/categorizationUtils';
import { checkCostModeIncludesMarkups, useCostMode } from '../../../utilities/costMode';
import usePermissions from '../../../utilities/permissions/usePermissions';
import { getMilestoneIdFromUrl, getProjectIdFromUrl } from '../../../utilities/url';
import useGetProjectNameQuery from '../../Collaborators/hooks/UseGetProjectNameQuery';
import useCostReportParams from '../../CostReport/CostReport/useCostReportParams';
import { getMSRExportColumns } from '../../CostReport/CostReportUtils';
import { useGetProjectUnitsQuery } from '../../Milestone/hooks/UnitHooks';
import { useMilestonesQuery } from '../../Milestones/hooks';
import useMemoWrapper from '../../useMemoWrapper';

const ExportMilestoneSummaryReport = () => {
  const projectId = getProjectIdFromUrl();
  const milestoneId = getMilestoneIdFromUrl();
  const { canView } = usePermissions();
  const canViewOwnerCosts = canView(PermissionResource.OWNER_COSTS);

  const { data: projectData, loading: projectLoading } = useGetProjectNameQuery(projectId);
  const { data: milestonesData, loading: milestonesLoading } = useMilestonesQuery(projectId, false);
  const { data: unitsData, loading: unitsLoading } = useGetProjectUnitsQuery(projectId, true);
  const { data: catData, loading: catLoading } = useProjectCategorizationsQuery(projectId);

  const projectName = projectData?.project?.name ?? '';
  const milestones = milestonesData?.milestones ?? [];
  const categorizations = getCategorizationsForProjectFromQueryData(catData);
  const enabledUnits = unitsData?.getProjectUnits ?? [];
  const milestoneName = milestones.find((m) => m.id === milestoneId)?.name ?? '';

  const defaultCategorizations = useMemoWrapper(getDefaultReportCategorizations, categorizations);

  const { displayGroupBy, filterManager, settings } = useCostReportParams(
    categorizations,
    defaultCategorizations,
    milestoneName,
    COSTS,
    enabledUnits
  );

  // Item sidebar analytics

  const costMode = useCostMode();
  const { filterQueryInput: viewFilter } = filterManager;
  const includesMarkups = checkCostModeIncludesMarkups(costMode);

  const columns = getMSRExportColumns(settings, enabledUnits);
  const columnSets = [{ milestoneID: milestoneId, columns }];

  // we need the project name to name the exported excel file
  const fileNameTokens = [projectName, milestoneName];

  // analytics
  const sendAnalytics = useSendAnalytics();
  const loading = projectLoading || milestonesLoading || catLoading || unitsLoading;

  useEffect(() => {
    // return if the report is already being downloaded
    if (isDownloadingCostReportToExcelVar()) return;

    if (!loading) {
      const exportAndClose = () => {
        sendAnalytics(analyticsEvent(EXPORT_MILESTONE_SUMMARY_REPORT));
        JoinAPI.exportCostReport(
          projectId,
          displayGroupBy,
          columnSets,
          viewFilter,
          costMode,
          includesMarkups,
          false,
          fileNameTokens,
          {
            groupBys: displayGroupBy.map((g) => g.id),
            milestoneEstimates: [],
          },
          settings?.estimateLines === DISPLAY,
          includesMarkups && canViewOwnerCosts,
          () => {
            // close this dialog when we're down exporting
            window.close();
          }
        );
      };
      setTimeout(exportAndClose, 100);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO CT-566: Fix this pls :)
  }, [
    projectId,
    displayGroupBy,
    columnSets,
    viewFilter,
    costMode,
    includesMarkups,
    fileNameTokens,
    sendAnalytics,
    loading,
  ]);

  return (
    <Typography style={{ fontSize: 18, paddingTop: 16 }}>
      &nbsp;&nbsp;&nbsp;&nbsp;Exporting Variance Report...
    </Typography>
  );
};

export default ExportMilestoneSummaryReport;
