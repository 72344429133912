import {
  gql,
  sharedDetailedItemLikeFields,
  sharedSourceItemLikeFields,
} from '../../../api/graphqlFragments';

export const itemQuery = gql`
  query itemData($id: UUID!, $costMode: CostMode!) {
    item(id: $id, costMode: $costMode) {
      ...sharedDetailedItemLikeFields
    }
  }
  ${sharedDetailedItemLikeFields}
`;

export const sourceItemQuery = gql`
  query sourceItem($id: UUID!, $costMode: CostMode!) {
    item(id: $id, costMode: $costMode) {
      ...sharedSourceItemLikeFields
    }
  }
  ${sharedSourceItemLikeFields}
`;
