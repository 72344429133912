import { FC, useEffect } from 'react';

import { Typography } from '@material-ui/core';

import { ExportType } from '../../../api/gqlEnums';
import JoinAPI from '../../../api/joinAPI';
import { PermissionResource } from '../../../generated/graphql';
import useProjectPropsQuery from '../../../hooks/useProjectPropsQuery';
import useSendAnalytics from '../../../hooks/useSendAnalytics';
import { checkCostModeIncludesMarkups, useCostMode } from '../../../utilities/costMode';
import usePermissions from '../../../utilities/permissions/usePermissions';
import { getMilestoneIdFromUrl, getProjectIdFromUrl } from '../../../utilities/url';
import { useMilestoneQuery } from '../../Milestone/hooks/useMilestoneQuery';

const ExportMilestone: FC = () => {
  const { location } = window;
  const isEstimate = location.pathname.indexOf('estimate') >= 0;
  const isBudget = location.pathname.indexOf('budget') >= 0;
  const projectId = getProjectIdFromUrl();
  const milestoneId = getMilestoneIdFromUrl();
  const costMode = useCostMode();
  const {
    data: { project },
    loading: loadingProject,
  } = useProjectPropsQuery(projectId);
  const projectName = project?.name ?? '';
  const { loading: loadingMilestone, data: { milestone = undefined } = {} } =
    useMilestoneQuery(milestoneId);
  const { canView } = usePermissions();
  const canViewMilestoneCosts = canView(PermissionResource.MILESTONE_LINES);
  const includeMarkups = checkCostModeIncludesMarkups(costMode);
  const sendAnalytics = useSendAnalytics();

  useEffect(() => {
    if (!loadingProject && !loadingMilestone && canViewMilestoneCosts && milestone) {
      const estimateID = milestone.activeEstimateID || '';
      const budgetID = milestone.budgetID || '';
      let id: UUID;
      if (isEstimate) {
        if (!estimateID) return;
        id = estimateID;
      } else if (isBudget) {
        if (!budgetID) return;
        id = budgetID;
      } else {
        return;
      }
      const type = ExportType[isEstimate ? 'ESTIMATE' : 'BUDGET'];
      const fileNameTokens = projectName
        ? [projectName, milestone.name, type]
        : [milestone.name, type];
      const exportAndClose = () => {
        JoinAPI.exportEstimates([id], type, includeMarkups, fileNameTokens, sendAnalytics, () => {
          window.close();
          return undefined;
        });
      };
      setTimeout(exportAndClose, 100);
    }
  }, [
    canViewMilestoneCosts,
    includeMarkups,
    isBudget,
    isEstimate,
    loadingMilestone,
    loadingProject,
    milestone,
    projectName,
    sendAnalytics,
  ]);

  return (
    <Typography style={{ fontSize: 18, paddingTop: 16 }}>
      &nbsp;&nbsp;&nbsp;&nbsp;Exporting {isEstimate ? 'Estimate' : 'Budget'}...
    </Typography>
  );
};

export default ExportMilestone;
