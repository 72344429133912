import { UNASSIGNED } from '../../../../constants';
import { EmptyThumbnail } from '../../../AssigneeSelect/AssigneeSelect';
import UserAvatar from '../../../Collaborators/UserAvatar';
import { useCompanyUsersQuery } from '../../useCompanyUsersQuery';

export const useLeadsEntries = (companyID: string) => {
  const { data } = useCompanyUsersQuery({
    variables: { companyID },
    skip: !companyID,
  });

  const companyUsers = data?.companyUsers ?? [];

  const leadsEntries = companyUsers.map((u) => ({
    id: u.user?.id || '',
    label: u.user?.name || '',
    startAdornment: <UserAvatar assignee={u.user} />,
  }));

  const unassignedEntry = {
    id: UNASSIGNED,
    label: UNASSIGNED,
    startAdornment: <EmptyThumbnail />,
    isSection: false,
  };

  leadsEntries.unshift(unassignedEntry);

  return { leadsEntries, companyUsers };
};
