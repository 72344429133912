import StatusBanner from '../StatusBanner/StatusBanner';

export default function UploadError(props: { label?: string; description?: string | string[] }) {
  return (
    <div className="w-full text-left">
      <StatusBanner data-cy="import-error-banner" type="error">
        <strong data-cy="import-text-error">{props.label}</strong>
        {Array.isArray(props.description)
          ? props.description.map((text) => (
              <div key={text} className="type-body2">
                {text}
              </div>
            ))
          : props.description}
      </StatusBanner>
    </div>
  );
}
