import { SortDirection } from '../../../generated/graphql';

export type UsersTableHeaderContent = {
  copy: string;
  headerSortKey: ProgramUsersSortKey | null;
  key: string;
};

export type SortDataType = {
  sortKey: string;
  sortDirection: SortDirection;
};

export enum ProgramUsersSortKey {
  COMPANY = 'company',
  NAME = 'name',
  BELONGS_TO = 'belongsTo',
  ROLE = 'mostCommonRoleName',
}

export type ProgramUsersSortBy = {
  sortDirection: SortDirection;
  sortKey: string;
};
