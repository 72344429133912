import queryString from 'query-string';
import { FC } from 'react';

import { useReactiveVar } from '@apollo/client';
import { LinearProgress } from '@material-ui/core';

import { importEstimateIsPublishedVar } from '../../../api/apollo/reactiveVars';
import { EstimateType } from '../../../api/gqlEnums';
import { TYPE } from '../../../constants';
import { PermissionResource } from '../../../generated/graphql';
import { useCostMode } from '../../../utilities/costMode';
import usePermissions from '../../../utilities/permissions/usePermissions';
import {
  getImportEstimateIdFromUrl,
  getMilestoneIdFromUrl,
  getProjectIdFromUrl,
} from '../../../utilities/url';
import { useCurrentUser } from '../../contexts/current-user';
import useMilestoneCostReportQuery from '../../CostReport/hooks/useMilestoneCostReportQuery';
import EstimateImportPage from '../../EstimateImport/EstimateImportPage/EstimateImportPage';
import { useImportEstimateErrorsQuery } from '../../EstimateImport/hooks';
import {
  useDraftEstimateInfoQuery,
  useGridEstimateArmatureQuery,
} from '../../JoinGrid/hooks/estimateQuery';
import { useMilestoneQuery } from '../../Milestone/hooks';
import { useGetProjectUnitsQuery } from '../../Milestone/hooks/UnitHooks';
import { useLoadTimer } from '../../PerfMonitor/utils';

const ImportEstimateData: FC = () => {
  // Read our project and milestone ids from the URL to populate queries
  const isPublished = useReactiveVar(importEstimateIsPublishedVar);
  const costMode = useCostMode();

  const { search } = window.location;
  const milestoneId = getMilestoneIdFromUrl();
  const projectId = getProjectIdFromUrl();
  const estimateId = getImportEstimateIdFromUrl();
  const parsed = queryString.parse(search, { arrayFormat: 'index' });
  const estimateType =
    parsed[TYPE] === EstimateType.BUDGET ? EstimateType.BUDGET : EstimateType.ACTIVE_ESTIMATE;

  const {
    loading: milestonesLoading,
    error,
    data: { milestone = undefined } = {},
    refetch: refetchMilestone,
  } = useMilestoneQuery(milestoneId);

  const draftEstimateID =
    estimateType === EstimateType.BUDGET
      ? milestone?.budgetDraftID
      : milestone?.activeEstimateDraftID;

  const draftEstimate = useDraftEstimateInfoQuery(draftEstimateID ?? undefined, costMode).data
    ?.draftEstimateInfo;
  const draftEstimateArmature = useGridEstimateArmatureQuery(
    draftEstimateID ?? undefined,
    undefined,
    costMode
  ).data;

  const {
    data: { importEstimateErrors = [] } = {},
    loading: errorsLoading,
    refetch,
  } = useImportEstimateErrorsQuery(estimateId);
  const { data: { milestoneCostReports = [] } = {}, refetch: refetchMilestoneCostReports } =
    useMilestoneCostReportQuery(
      milestoneId,
      [],
      undefined,
      projectId,
      {},
      estimateType === EstimateType.ACTIVE_ESTIMATE,
      estimateType === EstimateType.BUDGET,
      true,
      false
    );

  // load project enabled units
  const {
    data: { getProjectUnits: enabledUnits = [] } = { getProjectUnits: [] },
    loading: unitsLoading,
  } = useGetProjectUnitsQuery(projectId, true);

  const userID = useCurrentUser().id;
  const { canView, canEdit, isViewOnly } = usePermissions();
  const canEditDraftEstimates = canEdit(PermissionResource.DRAFT_ESTIMATE);
  const canViewProjectCategories = canView(PermissionResource.CATEGORIES_AND_TAGS);
  const didCreateDraftEstimate = draftEstimate?.author === userID;
  const canPublishDraftEstimate = !isViewOnly && (canEditDraftEstimates || didCreateDraftEstimate);

  const hooksLoading = errorsLoading || milestonesLoading || unitsLoading;
  useLoadTimer('ImportEstimate', hooksLoading);

  if (hooksLoading || isPublished || !draftEstimateArmature?.estimate) return <LinearProgress />;

  return (
    <EstimateImportPage
      canPublishDraftEstimate={canPublishDraftEstimate}
      canViewProjectCategories={canViewProjectCategories}
      draftEstimate={draftEstimateArmature.estimate}
      enabledUnits={enabledUnits}
      error={error}
      estimateAssetID={draftEstimate?.asset || ''} // ToDo: NS-1074 update
      estimateType={estimateType}
      importEstimateErrors={importEstimateErrors}
      milestone={milestone ?? undefined}
      milestoneCostReports={milestoneCostReports}
      refetch={refetch}
      refetchMilestone={refetchMilestone}
      refetchMilestoneCostReports={refetchMilestoneCostReports}
    />
  );
};

export default ImportEstimateData;
