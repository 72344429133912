import { gql } from '../../../../api/graphqlFragments';

export const upsertProjectCompanyRole = gql`
  mutation upsertProjectCompanyRole($projectID: UUID!, $companyID: UUID!, $role: String!) {
    upsertProjectCompanyRole(projectID: $projectID, companyID: $companyID, role: $role) {
      company {
        id
        domain
        name
        type
        asset {
          id
          name
        }
      }
      role
    }
  }
`;
