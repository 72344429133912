import { companyTabIDVar } from '../../api/apollo/reactiveVars';
import { useCompaniesQuery } from '../CompanyTab/CompanyHooks';
import { useCompanyTabID } from '../CompanyTab/CompanyTabUtils';
import JoinSelect from '../Select/JoinSelect/JoinSelect';

export default function CompanySelector() {
  // Company data
  const companyID = useCompanyTabID();
  const companies = useCompaniesQuery().data?.companies ?? [];
  if (!companies.length) return null;

  return (
    <div className="ml-1">
      <JoinSelect
        entries={companies}
        onChange={(newValue: UUID) => {
          if (newValue) companyTabIDVar(newValue);
        }}
        search
        value={companyID}
      />
    </div>
  );
}
