import { FC, useEffect, useRef } from 'react';

import { Popper, Typography } from '@material-ui/core';

import theme, { withStyles } from '../../../theme/komodo-mui-theme';
import ChartsVerticalBarGraph from '../../Charts/ChartsD3/ChartsVerticalBarGraph/ChartsVerticalBarGraph';
import {
  ChartTooltip,
  chartTooltipDefault,
} from '../../Charts/ChartsD3/ChartsVerticalBarGraph/ChartsVerticalBarGraphUtils';
import ChartsHintWrapper from '../../Charts/ChartsHintWrapper/ChartsHintWrapper';
import {
  CURRENT_ITEMS_BREAKDOWN_HINT_HEIGHT,
  CURRENT_ITEMS_BREAKDOWN_HINT_WIDTH,
  ProjectTotal,
  calculateMonthlyItemsBreakdown,
  currentItemBreakdownHintChartSize,
  formatItemStatusStackMap,
  itemStatusColorMap,
} from '../ExecutiveDashboardUtils';

import ExecutiveDashboardCurrentItemsBreakdownHintStyles from './ExecutiveDashboardCurrentItemsBreakdownHintStyles';
import ExecutiveDashboardCurrentItemsBreakdownHintTable from './ExecutiveDashboardCurrentItemsBreakdownHintTable';

type ExecutiveDashboardCurrentItemsBreakdownHintProps = {
  classes: Classes<typeof ExecutiveDashboardCurrentItemsBreakdownHintStyles>;
  chartTooltip: ChartTooltip;
  projectTotal: ProjectTotal | undefined;
  setChartTooltip: (tooltip: ChartTooltip) => void;
};

const ExecutiveDashboardCurrentItemsBreakdownHint: FC<
  ExecutiveDashboardCurrentItemsBreakdownHintProps
> = ({ classes, chartTooltip, projectTotal, setChartTooltip }) => {
  const ref = useRef<HTMLDivElement>(null);

  // the popper moves with the view and doesn't stay in place
  // when scrolling, which looks weird when the hint is no longer
  // next to the bar it's describing.  To avoid this we'll
  // hide the hint anytime the user scrolls in the view
  useEffect(() => {
    const handleScroll = () => {
      setChartTooltip(chartTooltipDefault);
    };

    if (ref) {
      document.addEventListener('scroll', handleScroll);
    } else {
      document.removeEventListener('scroll', handleScroll);
    }
    // Specify how to clean up after this effect:
    return () => document.removeEventListener('scroll', handleScroll);
  }, [ref, setChartTooltip]);

  const width = chartTooltip.right - chartTooltip.x;
  const height = chartTooltip.bottom - chartTooltip.y;

  // format the monthly data for use in the chart
  const { data, projectCostRanges } = calculateMonthlyItemsBreakdown(
    projectTotal ? projectTotal.monthlyBreakdown : []
  );
  // Note:
  // The base div is a duplicate (same size & location)
  // of a corresponding bar in the bar chart
  // We need to duplicate it to act as an anchor
  // for the popover.  The popover can't reference
  // an SVG element from the chart.
  // this div is transparent and invisible to the user
  return (
    <div
      ref={ref}
      onMouseLeave={() => setChartTooltip(chartTooltipDefault)}
      style={{
        position: 'fixed',
        left: `${chartTooltip.x}px`,
        top: `${chartTooltip.y}px`,
        backgroundColor: 'transparent',
        opacity: 0,
        display: 'inline',
        width: `${width}px`,
        height: `${height}px`,
      }}
    >
      <Popper
        anchorEl={ref.current}
        open={!!projectTotal && !!chartTooltip.projectID}
        placement="top-start"
        popperOptions={{
          modifiers: {
            flip: { enabled: false },
            offset: {
              enabled: true,
              offset: `${width / 2}px 0px`,
            },
          },
        }}
        style={{
          zIndex: 100,
          pointerEvents: 'none',
          height: CURRENT_ITEMS_BREAKDOWN_HINT_HEIGHT,
        }}
      >
        <ChartsHintWrapper
          content={
            <>
              <ExecutiveDashboardCurrentItemsBreakdownHintTable projectTotal={projectTotal} />
              <Typography className={`${classes.labelText} ${classes.label}`}>
                Breakdown Over Time
              </Typography>
              <ChartsVerticalBarGraph
                chartSize={currentItemBreakdownHintChartSize}
                data={data}
                labelOptions={{
                  hyperlinkProject: false,
                  ...theme.typography.chartSmall,
                }}
                projectCostRanges={projectCostRanges}
                stackMap={formatItemStatusStackMap(itemStatusColorMap)}
              />
            </>
          }
          width={CURRENT_ITEMS_BREAKDOWN_HINT_WIDTH}
        />
      </Popper>
    </div>
  );
};

export default withStyles(ExecutiveDashboardCurrentItemsBreakdownHintStyles)(
  ExecutiveDashboardCurrentItemsBreakdownHint
);
