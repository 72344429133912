import { Navigate, useOutlet } from 'react-router-dom';

import { DD_PROJECT_IDEAS } from '../../features';
import FourOhFour from '../FourOhFour';
import HasFeatureFlag from '../HasFeatureFlag';

export default function IdeasRoute() {
  const outlet = useOutlet();
  if (!outlet) return <Navigate replace to="/404" />;
  return (
    <HasFeatureFlag fallback={<FourOhFour />} flag={DD_PROJECT_IDEAS}>
      {outlet}
    </HasFeatureFlag>
  );
}
