import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';

const MARGIN = 16;
const ICON_HEIGHT = 50;

export default (theme: KomodoTheme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    iconContainer: {
      height: ICON_HEIGHT,
    },
    textContainer: {
      backgroundColor: theme.palette.primaryGrey,
      color: theme.palette.backgroundWhite,
      margin: MARGIN,
    },
    text: {
      ...theme.typography.h4Medium,
      padding: 8,
    },
  });
