import { ItemReference } from '../../../generated/graphql';

// Sorts mentions and item references by position
export const commentRefSort = (
  a: Pick<ItemReference | Mention, 'position'>,
  b: Pick<ItemReference | Mention, 'position'>
) => {
  return a.position > b.position ? 1 : -1;
};

// gets the end text position of a User Mention or Item Reference
export const getCommentRefEnd = ({
  position,
  text,
}: Pick<Mention | ItemReference, 'position' | 'text'>) => position + text.length;
