import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';

import { textSubTitle } from './ImportEstimateUpperSVGTestStyles';

export default (theme: KomodoTheme) =>
  createStyles({
    container: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
    },
    iconButtons: {
      display: 'flex',
      width: 744,
      justifyContent: 'space-between',
      paddingTop: 25,
    },
    link: {
      color: theme.palette.link,
      cursor: 'pointer',
      fontFamily: theme.typography.fontFamily,
      textDecoration: 'underline',
    },
    helpText: {
      paddingTop: 8,
      right: 25,
    },
    textSubTitle,
    shiftRight: {
      position: 'relative',
      right: 100,
      width: 550,
      marginBottom: 6,
    },
  });
