import { useState } from 'react';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import MoreVert from '@material-ui/icons/MoreVert';

import { copyItemEventTypes } from '../../../analytics/analyticsEventProperties';
import { setToast } from '../../../hooks/useToastParametersLocalQuery';
import { RouteKeys } from '../../../routes/paths';
import theme from '../../../theme/design-system-mui-theme';
import { generateSharedPath } from '../../../utilities/routes/links';
import { commaSeparatedString, pluralizeString } from '../../../utilities/string';
import CopyItemDialogData from '../../CopyItem/CopyItemDialog/CopyItemDialogData';
import useSendCopyItemAnalytics from '../../CopyItem/hooks/useSendCopyItemAnalytics';
import DialogsConfirm from '../../Dialogs/DialogsConfirm/DialogsConfirm';
import { getItemDescriptor, styleItemNumber } from '../../Items/ItemsListItem/ItemsListItemUtils';
import { IconMenuButton, MenuEntry } from '../../scales';
import { MenuOption } from '../../Select/SelectMenu/SelectOption';

import useDeprecateItem from './hooks/useDeprecateItemHook';

type ItemDetailsSettingssPanelProps = {
  item: ItemDataQueryItem;
  projectId: UUID;
  isOption?: boolean;
  options: Pick<Option, 'id' | 'name'>[];
  canDeprecateItems: boolean;
  setOpenDialogAddOption: (value: boolean) => void;
};

const ItemDetailsSettingsPanel: React.FC<ItemDetailsSettingssPanelProps> = ({
  projectId,
  options,
  item,
  isOption,
  setOpenDialogAddOption,
  canDeprecateItems,
}) => {
  const navigate = useNavigate();
  const sendCopyItemAnalytics = useSendCopyItemAnalytics();
  // state
  const [openDeprecateItemDialog, setOpenDeprecateItemDialog] = useState(false);
  const [openDialogCopyItem, setOpenDialogCopyItem] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  // variables
  const optionDescriptions =
    options && options.length ? options.map((o) => ` ${getItemDescriptor(o)} `) : [];

  // hooks

  const showToast = (message: string) => {
    setToast({ message });
  };
  const { deprecateItem } = useDeprecateItem(
    projectId,
    item.id,
    () => {
      showToast(
        `Successfully deleted item ${getItemDescriptor(item)}${
          optionDescriptions.length
            ? ` and ${pluralizeString('option', optionDescriptions.length)} ${commaSeparatedString(
                optionDescriptions
              )}`
            : ''
        }`
      );
      navigate({
        pathname: generateSharedPath(RouteKeys.PROJECT_ITEMS, { projectId }),
      });
    },
    () => {
      showToast(`Error, couldn't delete item ${styleItemNumber(item.number)}`);
      setOpenDeprecateItemDialog(false);
    }
  );

  // menu options
  const menuOptions: MenuOption[] = [];
  const iconMenuOptions: MenuEntry[] = [];

  if (!isOption) {
    menuOptions.push({
      name: 'Add options',
      cy: 'item-details-menu-button-addOption',
      callback: () => {
        setOpenDialogAddOption(true);
      },
    } as MenuOption);
    iconMenuOptions.push({
      id: 'add-option',
      label: 'Add options',
      onClick: () => setOpenDialogAddOption(true),
    });
  }

  menuOptions.push({
    name: 'Copy item',
    cy: 'item-details-menu-button-copyItem',
    callback: () => {
      sendCopyItemAnalytics(copyItemEventTypes.OPEN_DIALOG_CTA, {
        location: 'item details',
      });
      setOpenDialogCopyItem(true);
    },
  });
  iconMenuOptions.push({
    id: 'copy-item',
    label: 'Copy item',
    onClick: () => setOpenDialogCopyItem(true),
  });
  if (canDeprecateItems) {
    const deleteTooltip =
      options && options.length
        ? `Delete this item and its ${pluralizeString('option', optionDescriptions.length)}.`
        : 'Delete this item';
    menuOptions.push({
      name: `Delete ${isOption ? 'option' : 'item'}`,
      color: isOption ? undefined : theme.palette.ds.type.delete,
      cy: 'item-details-menu-button-deleteItem',
      disabled: isOption,
      callback: () => {
        setOpenDeprecateItemDialog(true);
      },
      tooltip: isOption ? 'Detach this option to delete it' : deleteTooltip,
    } as MenuOption);
    iconMenuOptions.push({
      disabled: isOption,
      id: 'delete-item',
      label: `Delete ${isOption ? 'option' : 'item'}`,
      onClick: () => setOpenDeprecateItemDialog(true),
      type: 'destructive',
    });
  }

  const dialogBody = `Are you sure you want to delete item ${getItemDescriptor(item)}${
    optionDescriptions.length
      ? ` and ${pluralizeString('option', optionDescriptions.length)}${commaSeparatedString(
          optionDescriptions
        )}`
      : ''
  }  from the project?`;

  if (!menuOptions.length) return null;
  return (
    <div>
      <IconMenuButton
        aria-label="export-menu"
        data-cy="item-details-menu-button"
        entries={iconMenuOptions}
        icon={<MoreVert />}
        type="secondary"
      />
      <DialogsConfirm
        acceptCtaCopy="Delete"
        body={dialogBody}
        cancelCtaCopy="Cancel"
        destructiveConfirm
        onClose={() => {
          if (!submitted) {
            setOpenDeprecateItemDialog(false);
          }
        }}
        onConfirm={() => {
          if (!submitted) {
            deprecateItem();
            setSubmitted(true);
          }
        }}
        open={openDeprecateItemDialog}
        title="Delete Item"
      />
      {openDialogCopyItem && (
        <CopyItemDialogData item={item} onClose={() => setOpenDialogCopyItem(false)} open />
      )}
    </div>
  );
};

export default ItemDetailsSettingsPanel;
