import { gql } from '../../../api/graphqlFragments';

export const timelineQuery = gql`
  query Timeline($input: TimelineInput!) {
    timeline(input: $input) {
      activities {
        id
        name
        type
        startDate
        endDate
        children
        itemCount
        milestone {
          id
          name
          description
          itemsCount
        }
      }
    }
  }
`;

export const timelineActivityOptionsQuery = gql`
  query TimelineActivityOptions($input: TimelineInput!) {
    timeline(input: $input) {
      activities {
        id
        name
        type
        itemCount
        milestone {
          id
          name
          description
          itemsCount
        }
      }
    }
  }
`;

export const createTimelineActivityMutation = gql`
  mutation createTimelineActivity($projectID: UUID!, $input: TimelineActivityInput!) {
    createTimelineActivity(projectID: $projectID, input: $input) {
      id
      type
      name
      startDate
      endDate
    }
  }
`;

export const updateTimelineActivityMutation = gql`
  mutation updateTimelineActivity(
    $projectID: UUID!
    $activityID: UUID!
    $input: TimelineActivityInput!
  ) {
    updateTimelineActivity(projectID: $projectID, activityID: $activityID, input: $input) {
      id
      type
      name
      startDate
      endDate
    }
  }
`;

export const deleteTimelineActivityMutation = gql`
  mutation deleteTimelineActivity($projectID: UUID!, $activityID: UUID!) {
    deleteTimelineActivity(projectID: $projectID, activityID: $activityID)
  }
`;

export const itemsTimelineItemFields = gql`
  fragment itemsTimelineItemFields on ItemsTimelineItem {
    dueDate
    id
    name
    status
    createdAt
  }
`;

export const itemsTimelineQuery = gql`
  query itemsTimeline($milestoneID: UUID, $projectID: UUID!, $costMode: CostMode!) {
    itemsTimeline(
      parameters: { milestoneID: $milestoneID, projectID: $projectID, costMode: $costMode }
    ) {
      items {
        ...itemsTimelineItemFields
      }
    }
  }
  ${itemsTimelineItemFields}
`;
