import { createStyles } from '@material-ui/core';

import {
  SMALL_CHART_HEIGHT,
  SMALL_CHART_LEFT_PADDING,
} from '../../Charts/ChartsAllMilestones/ChartsAllMilestonesUtils';

export const COST_REPORT_HEIGHT = 80;
export const SUBHEADING_HEIGHT = 44;

const styles = () =>
  createStyles({
    container: {
      display: 'inline-block',
      height: SUBHEADING_HEIGHT + 24 + SMALL_CHART_HEIGHT + COST_REPORT_HEIGHT,
      margin: '16px 1% 16px 1%',
      pageBreakInside: 'avoid',
      verticalAlign: 'top',
      width: '31%',
    },
    costSummary: {
      height: COST_REPORT_HEIGHT,
      paddingLeft: SMALL_CHART_LEFT_PADDING - 12, // minus internal padding
      paddingBottom: 4,
    },
    summary: {
      paddingLeft: 12, // internal padding equivalent
      paddingRight: 12,
    },
  });

export default styles;
