import { FC, ReactElement } from 'react';

import { ButtonBase, Typography } from '@material-ui/core';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

import { ProjectCompSectionType } from '../../../api/gqlEnums';
import {
  PROJECT_COMP_COLLAPSE_BUTTON,
  PROJECT_COMP_ERROR_ICON_SIDEBAR,
} from '../../../tagConstants';
import theme, { withStyles } from '../../../theme/komodo-mui-theme';
import NormalTooltipInfoIcon from '../../NormalTooltip/NormalTooltipInfoIcon';
import CollapseIcon from '../../shared-widgets/CollapseIcon';
import RowHighlightable from '../../shared-widgets/RowHighlight/RowHighlightable';
import {
  toggleProjectCompSectionCollapsed,
  useIsProjectCompSectionCollapsed,
} from '../ProjectCompsSetUtils';
import styles from '../ProjectCompsStyles';

type ProjectCompSectionHeaderProps = {
  actionButton?: ReactElement;
  classes: Classes<typeof styles>;
  collapseContentSummary?: string; // collapse section content summary e.g. 10 Beds, 1.23%, 123,45$/GSF
  collapseSection: ProjectCompSectionType;
  hasMarginTop?: boolean;
  hoverInfo?: string;
  isHighlightable?: boolean;
  isTogglable?: boolean;
  label: string; // name of the collapse section
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  showErrorIcon?: boolean;
};

const ProjectCompSectionHeader: FC<ProjectCompSectionHeaderProps> = ({
  actionButton,
  classes,
  collapseContentSummary,
  collapseSection,
  hasMarginTop = true,
  hoverInfo,
  isHighlightable = false,
  isTogglable = false,
  label,
  showErrorIcon,
}) => {
  const isSectionCollapsed = useIsProjectCompSectionCollapsed(collapseSection);

  const sectionHeaderClasses = [classes.sectionHeader];
  sectionHeaderClasses.push(isTogglable ? classes.nestingGrey : classes.sectionHeaderPadding);
  if (hasMarginTop) sectionHeaderClasses.push(classes.sectionHeaderMargin);

  let content = (
    <>
      {isTogglable && (
        <CollapseIcon className={classes.collapseIcon} isCollapsed={isSectionCollapsed} />
      )}
      <Typography style={theme.typography.boldHeader}>{label}</Typography>
      {isTogglable && hoverInfo && <NormalTooltipInfoIcon title={hoverInfo} />}
      <div className={classes.spacer} />
      {!actionButton && collapseContentSummary && !isTogglable && (
        <Typography className={classes.number} data-cy="collapse-content-summary">
          {collapseContentSummary}
        </Typography>
      )}
    </>
  );

  // Wrap content in a button to trigger expand/collapse if necessary.
  if (isTogglable) {
    content = (
      <ButtonBase
        className={classes.sectionHeaderToggleBtn}
        data-cy={`${PROJECT_COMP_COLLAPSE_BUTTON}-${label}`}
        disabled={!isTogglable}
        onClick={() => toggleProjectCompSectionCollapsed(collapseSection)}
        style={theme.typography.boldHeader}
      >
        {content}
      </ButtonBase>
    );
  }

  // Add end adornments
  content = (
    <>
      {content}
      {showErrorIcon ? (
        <ErrorOutlineIcon className={classes.errorIcon} data-cy={PROJECT_COMP_ERROR_ICON_SIDEBAR} />
      ) : null}
      {actionButton}
    </>
  );

  // Register this as highlightable if this is a toggleable section
  if (isHighlightable) {
    return (
      <RowHighlightable>
        {({ ref }) => (
          <div ref={ref} className={sectionHeaderClasses.join(' ')}>
            {content}
          </div>
        )}
      </RowHighlightable>
    );
  }

  return <div className={sectionHeaderClasses.join(' ')}>{content}</div>;
};

export default withStyles(styles)(ProjectCompSectionHeader);
