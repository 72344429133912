import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { JoinProjectRoutes } from '../../../api/gqlEnums';
import { IdeaFilters } from '../../../generated/graphql';
import useProjectPropsQuery from '../../../hooks/useProjectPropsQuery';
import { generateSharedPath } from '../../../utilities/routes/links';
import { CategoryMultiSelect } from '../../dragon-scales';
import { FilterPopover } from '../../FilterPopover/FilterPopover';
import { useMilestonesQuery } from '../../Milestones/hooks';
import { Button, Icon, TextInput } from '../../scales';
import MilestoneSelect from '../../Select/MilestoneSelect';

import useIdeasQuery from './hooks/useIdeasQuery';
import IdeasTable from './IdeasTable';
import SuggestedIdeas from './SuggestedIdeas';
import useIdeaGroupsQuery from './hooks/useIdeaGroupsQuery';

type Props = { projectID: UUID };

export default function Ideas(props: Props) {
  const [filters, setFilters] = useState<IdeaFilters>({ categories: [], search: '' });
  const [milestoneID, setMilestoneID] = useState<UUID>();

  const ideaGroupsQuery = useIdeaGroupsQuery(props.projectID, milestoneID);
  const ideaGroups =
    ideaGroupsQuery?.data?.ideaGroups ?? ideaGroupsQuery.previousData?.ideaGroups ?? [];
  const ideasQuery = useIdeasQuery(props.projectID, milestoneID, filters);
  const ideas = ideasQuery?.data?.ideas ?? ideasQuery.previousData?.ideas ?? [];

  const activeMilestoneID = useProjectPropsQuery(props.projectID).data.project?.activeMilestone.id;
  const milestones = useMilestonesQuery(props.projectID, true).data?.milestones ?? [];

  const isFiltered = filters.categories.length > 0 || filters.search;
  const onUpdateCategories = (categories: CategoryReference[]) =>
    setFilters((prev) => ({ ...prev, categories }));
  const onUpdateSearch = (search: string) => setFilters((prev) => ({ ...prev, search }));

  const navigate = useNavigate();
  const handleMilestoneNavigation = () =>
    navigate(
      generateSharedPath(JoinProjectRoutes.MILESTONE_DETAILS, {
        projectId: props.projectID,
        milestoneId: milestoneID ?? activeMilestoneID,
        search: '?view=ESTIMATE',
      })
    );

  return (
    <div className="flex flex-col gap-6 p-6">
      {/**
       * TODO: I'm using mr-auto temporarily to prevent MilestoneSelect from taking
       * up the full width. I will fix this when I update MilestoneSelect to not use
       * our deprecated JoinSelect under the hood.
       *  */}
      <div className="mr-auto flex flex-row items-center gap-4">
        <h1 className="type-heading1">Ideas</h1>
        <MilestoneSelect
          milestones={milestones}
          onChangeMilestone={(value) => {
            if (value) setMilestoneID(value);
          }}
          selectedMilestone={milestoneID ?? activeMilestoneID}
        />
        <Button
          label="View Milestone Estimate"
          onClick={handleMilestoneNavigation}
          type="tertiary"
        />
      </div>
      <div className="flex gap-4">
        <TextInput
          aria-label="Search Input"
          onChange={onUpdateSearch}
          placeholder="Search ideas"
          startAdornment={<Icon name="search" />}
          value={filters.search}
        />
        <FilterMenu onChange={onUpdateCategories} value={filters.categories} />
      </div>
      {isFiltered ? (
        // TODO: this should render a wrapping grid of IdeaTiles
        <div className="flex flex-col gap-4">
          <IdeasTable ideas={ideas} projectID={props.projectID} />
        </div>
      ) : (
        ideaGroups.map((group) => (
          // TODO: this should render a series of side-scrolling carousels of IdeaTiles
          <div key={group.name} className="flex flex-col gap-4">
            <h2 className="type-heading2">
              {group.name} {group.ideas.length ? `(${group.ideas.length})` : ''}
            </h2>
            <SuggestedIdeas ideas={group.ideas} projectID={props.projectID} />
          </div>
        ))
      )}
    </div>
  );
}

function FilterMenu(props: {
  value: CategoryReference[];
  onChange: (value: CategoryReference[]) => void;
}) {
  const numFiltersApplied = new Set(props.value.map((c) => c.categorizationID)).size;
  const categorizations = [
    { name: 'Masterformat', id: 'c22ed8a1-8fb3-43fc-b9ad-1ecf514c9852', builtin: true },
    { name: 'Uniformat', id: 'f7716c5d-5970-40b9-b215-03cc1b9773bf', builtin: true },
  ];

  return (
    <FilterPopover numFiltersApplied={numFiltersApplied} onResetFilters={() => props.onChange([])}>
      {categorizations.map((c) => (
        <CategoryMultiSelect
          key={c.id}
          categorization={c}
          onChange={(value) => {
            props.onChange([
              ...props.value.filter((category) => category.categorizationID !== c.id),
              ...value.map((id) => ({ id, categorizationID: c.id })),
            ]);
          }}
          value={props.value.flatMap((category) =>
            category.categorizationID === c.id ? [category.id] : []
          )}
        />
      ))}
    </FilterPopover>
  );
}
