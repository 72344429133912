import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../../theme/komodo-mui-theme';
import { HEADER_HEIGHT } from '../../CostReport/CostReportStyles';
import { hasUnitColumns } from '../../CostReportColumns/CostReportColumns';
import {
  CELL_PADDING,
  DESCRIPTION_WIDTH,
  HEADER_PADDING,
  LIST_PADDING,
  METRIC_COL_WIDTH,
  RANGE_COL_WIDTH,
  REPORT_COL_WIDTH,
  shadedClass,
} from '../CostReportListRow/CostReportListRowStyles';

// style constants

const HEADER_CELL_HEIGHT = 48; // 2 lines of subheading height at 24px
const SUBHEADER_CELL_HEIGHT = 48; // 2 lines of subheading height at 24px
const METRIC_CELL_PADDING = 6;
const METRIC_CELL_HEIGHT = 18 + METRIC_CELL_PADDING * 2;
const LIST_HEADER_PADDING_TOP = 16;
const LIST_HEADER_PADDING_SIDE = 8;
const VARIANCE_HEADER_PADDING = 12;
const METRIC_PADDING = 72;

// calculated constants
const LIST_HEADER_HEIGHT = HEADER_CELL_HEIGHT + LIST_HEADER_PADDING_TOP;
const LIST_SUBHEADER_HEIGHT = SUBHEADER_CELL_HEIGHT + LIST_HEADER_PADDING_TOP;

const headerPaddingString = `${LIST_HEADER_PADDING_SIDE}px !important`;

const columnSubheaderCell = {
  paddingTop: METRIC_CELL_PADDING,
  paddingBottom: METRIC_CELL_PADDING,
  height: SUBHEADER_CELL_HEIGHT,
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'flex-end',
};

export const getCostReportListHeaderHeight = (
  isVariance: boolean,
  settings: CostReportSettings
) => {
  const hasColumnTitles = hasUnitColumns(settings, isVariance);
  return hasColumnTitles
    ? 2 * LIST_HEADER_HEIGHT + LIST_SUBHEADER_HEIGHT + 1
    : LIST_HEADER_HEIGHT + LIST_SUBHEADER_HEIGHT + 1;
};

export const styles = (theme: KomodoTheme) =>
  createStyles({
    alignCenter: {
      textAlign: 'center',
    },
    alignLeft: {
      textAlign: 'left',
      paddingLeft: headerPaddingString,
    },
    alignRight: {
      paddingRight: headerPaddingString,
    },
    shaded: shadedClass(theme),
    borderBottom: {
      borderBottom: theme.border.lineBorderBlack,
    },
    columnHeaderCell: {
      paddingTop: LIST_HEADER_PADDING_TOP,
      width: REPORT_COL_WIDTH,
      paddingLeft: HEADER_PADDING,
      textAlign: 'right',
      height: LIST_HEADER_HEIGHT,
    },
    columnHeaderCellFlex: {
      display: 'flex',
      flexDirection: 'column-reverse',
    },
    columnHeaderRow: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    columnMetricCell: {
      width: METRIC_COL_WIDTH,
    },
    columnSubheaderCell,
    columnTerm: {
      overflowX: 'hidden',
      textOverflow: 'ellipsis',
      wordBreak: 'unset',
      paddingRight: CELL_PADDING,
      fontWeight: 600,
    },
    descriptionContainer: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    descriptionHeader: {
      zIndex: 6,
      backgroundColor: theme.palette.backgroundWhite,
      flex: '1 0 auto',
      display: 'flex',
      justifyContent: 'space-between',
      left: 0,
      minWidth: DESCRIPTION_WIDTH,
    },
    bold: {
      fontWeight: 600,
    },
    wordBreakNormal: {
      wordBreak: 'normal',
    },
    descriptionText: {
      marginLeft: LIST_PADDING,
      display: 'flex',
      flexDirection: 'column-reverse',
      '@media print': {
        marginLeft: 0,
      },
    },
    descriptionTextContainer: {
      paddingBottom: METRIC_CELL_PADDING,
      display: 'flex',
    },
    descriptionPrint: {
      marginLeft: 0,
      display: 'flex',
      flexDirection: 'column-reverse',
    },
    descriptionSpacer: {
      height: METRIC_CELL_HEIGHT,
    },
    expandCollapse: {
      margin: '5px 8px 3px',
      display: 'flex',
      flexDirection: 'column-reverse',
      cursor: 'pointer',
      '&:hover': {
        color: theme.palette.link,
      },
      '@media print': {
        display: 'none',
      },
    },
    gap: {
      width: 8,
    },
    metricContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'flex-end',
    },
    metricHeaderContainer: {
      display: 'flex',
      alignItems: 'flex-end',
    },
    metricHeaderCell: {
      paddingTop: METRIC_CELL_PADDING,
      paddingBottom: METRIC_CELL_PADDING,
      width: REPORT_COL_WIDTH,
      paddingLeft: HEADER_PADDING,
      textAlign: 'right',
      height: METRIC_CELL_HEIGHT,
    },
    metricText: {
      ...theme.typography.h4Medium,
      color: theme.palette.disabledGrey,
      overflowX: 'hidden',
      textOverflow: 'ellipsis',
      wordBreak: 'unset',
      maxWidth: 100,
      paddingRight: CELL_PADDING,
    },
    milestoneCostReportHeader: {
      padding: 2,
    },
    paddingLeft: {
      paddingLeft: `${METRIC_PADDING}px !important`,
    },
    printNoBackground: {
      backgroundColor: 'transparent !important',
    },
    range: {
      width: RANGE_COL_WIDTH,
    },
    row: {
      zIndex: 5,
      minWidth: '100%',
      width: 'fit-content',
      borderBottom: theme.border.line,
      backgroundColor: theme.palette.backgroundWhite,
      display: 'flex',
      flexDirection: 'row',
      top: HEADER_HEIGHT,
      '@media print': {
        marginRight: 0,
      },
    },
    scroll: {
      borderRight: theme.border.line,
    },
    scrollInvisible: {
      borderRight: theme.border.invisible,
    },
    scrollContainer: {
      flex: '0 1 auto',
      display: 'flex',
      width: 'min-content',
      flexDirection: 'column',
    },
    sticky: {
      position: 'sticky',
    },
    varianceHeaderOldFeature: {
      backgroundColor: theme.palette.joinGrey200,
      textAlign: 'center',
      padding: 2,
    },
    varianceHeader: {
      backgroundColor: theme.palette.joinGrey200,
      display: 'flex',
      justifyContent: 'space-between',
      padding: 2,
    },
    varianceHeaderColumn: {
      display: 'flex',
      flexDirection: 'column',
    },
    varianceHeaderMetricSection: {
      display: 'flex',
      flexDirection: 'row',
      paddingRight: LIST_HEADER_PADDING_SIDE,
    },
    varianceHeaderMetricColumn: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
      paddingLeft: VARIANCE_HEADER_PADDING,
    },
    notesHeader: {
      ...columnSubheaderCell,
      height: 'auto',
      flexDirection: 'row',
    },
    notesHeaderTitle: { paddingLeft: 24, fontWeight: 600 },
    notesHeaderTitleCollapsed: { fontWeight: 600 },
    expandNotesContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    expandNotesCircle: {
      ...theme.expandNotesCircle,
    },
    expandNotesCircleDisabled: {
      ...theme.expandNotesCircleDisabled,
    },
    notesHeaderCollapseLink: {
      ...theme.typography.h4Medium,
      color: theme.palette.disabledGrey,
      paddingLeft: 6,
      paddingBottom: 3,
    },
    expandNotesArrow: {
      ...theme.arrowIconExpandedRight,
    },
  });
