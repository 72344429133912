import { RefObject, useLayoutEffect, useRef, useState } from 'react';

export const useManageTextClamp = () => {
  const [showTextAsClamped, setShowTextAsClamped] = useState(false);
  const [isInitialTextClamped, setIsInitialTextClamped] = useState(true);

  return {
    showTextAsClamped,
    setShowTextAsClamped,
    isInitialTextClamped,
    setIsInitialTextClamped,
  };
};

export const useShowTextWithClamp = (
  setIsInitialTextClamped?: (isInitialTextClamped: boolean) => void
) => {
  const ref = useRef<HTMLDivElement>(null);

  // By checking scrollHeight > clientHeight we can tell if text display clamp styling is applied in the current view.
  const isDisplayedTextClamped = ({ current }: RefObject<HTMLDivElement>) => {
    return current ? current.scrollHeight > current.clientHeight : false;
  };

  useLayoutEffect(() => {
    setIsInitialTextClamped?.(isDisplayedTextClamped(ref));
  }, [setIsInitialTextClamped]);

  return { ref };
};
