import { FC } from 'react';

import { Chip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Visibility from '@material-ui/icons/Visibility';

import styles from './MetricsDragChipStyles';

type MetricsChipProps = {
  classes: Classes<typeof styles>;
  id: UUID;
  onSelect: (id: UUID) => void;
  text: string;
  unitCountText: string;
};

const MetricsChip: FC<MetricsChipProps> = ({ classes, id, onSelect, text, unitCountText }) => {
  const lineColor = classes.disabledColor;
  return (
    <div
      className={`${classes.drag} ${classes.metricsRow}`}
      data-cy="metricsDragChipRow-notSelected"
    >
      <Chip
        key={text}
        classes={{
          root: `${classes.root} ${classes.metricsLine}`,
          label: `${classes.chipLabel} ${classes.chipLabelPadding} ${lineColor}`,
        }}
        className={classes.chip}
        deleteIcon={
          <div className={classes.flexDisplay}>
            <div className={`${classes.chipUnitCount} ${lineColor}`}>{unitCountText}</div>
            <Visibility
              className={`${classes.delete} ${lineColor}`}
              data-cy={`metricsChipIcon-${text}`}
            />
          </div>
        }
        label={text}
        onDelete={() => onSelect(id)}
        title={text}
      />
    </div>
  );
};

export default withStyles(styles)(MetricsChip);
