import { detailedMilestoneFields, gql } from '../../../../api/graphqlFragments';

export const finalizeImportEstimateMutation = gql`
  mutation finalizeImportEstimate(
    $projectID: UUID!
    $draftID: UUID!
    $draftCategorizations: [DraftCategorization!]!
    $estimateTotalType: EstimateTotalType!
  ) {
    finalizeImportEstimate(
      projectID: $projectID
      draftID: $draftID
      draftCategorizations: $draftCategorizations
      estimateTotalType: $estimateTotalType
    ) {
      ...detailedMilestoneFields
    }
  }
  ${detailedMilestoneFields}
`;
