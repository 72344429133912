export default function InAppNotificationIconInvite() {
  return (
    <svg
      fill="none"
      height="1em"
      viewBox="0 0 24 24"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17 11V3H7V7H3V21H11V17H13V21H21V11H17ZM7 19H5V17H7V19ZM7 15H5V13H7V15ZM7 11H5V9H7V11ZM11 15H9V13H11V15ZM11 11H9V9H11V11ZM11 7H9V5H11V7ZM15 15H13V13H15V15ZM15 11H13V9H15V11ZM15 7H13V5H15V7ZM19 19H17V17H19V19ZM19 15H17V13H19V15Z"
        fill="currentColor"
      />
      <path
        d="M19.701 4.82199L19.165 3.51309L18 2.91099L19.165 2.3089L19.701 1L20.2369 2.3089L21.4019 2.91099L20.2369 3.51309L19.701 4.82199ZM21.4602 10L20.6447 8.06283L18.9204 7.1466L20.6447 6.23037L21.4602 4.29319L22.2757 6.23037L24 7.1466L22.2757 8.06283L21.4602 10Z"
        fill="currentColor"
      />
    </svg>
  );
}
