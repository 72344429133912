import { cloneElement, isValidElement } from 'react';

import { ApolloError } from '@apollo/client';

import {
  FileExplorerNavBarEntry,
  filesExplorerNavigationVar,
} from '../../../api/apollo/reactiveVars';
import {
  LoadSourceFolderContentsQuery,
  LoadSourceHubProjectsQuery,
  LoadSourceHubRootQuery,
  LoadSourceProjectFoldersQuery,
  SourceFolder,
  SourceFolderContents,
  SourceFolderEntities,
  SourceHub,
  SourceHubProjects,
  SourceHubRoot,
  SourceProjectFolders,
} from '../../../generated/graphql';
import { getDateString } from '../../../utilities/dates';
import { NavigationBarEntry } from '../../shared-widgets/NavigationBar/NavBreadcrumb/NavBreadcrumbUtils';
import { FileTableCell, getEntryIsPending } from '../Files/FilesTable/FilesTableUtils';

export const getCurrentNavigationEntry = (navBarEntries: FileExplorerNavBarEntry[]) =>
  navBarEntries[navBarEntries.length - 1];

// TODO: These need tests
export const getCurrentNavigationElement = (navBarEntries: FileExplorerNavBarEntry[]) => {
  if (!navBarEntries.length) {
    return null;
  }
  let { element } = getCurrentNavigationEntry(navBarEntries);
  if (isValidElement(element)) {
    const props = { key: Date.now() };
    element = cloneElement(element, props);
  }
  return element;
};

type HubsContent = LoadSourceHubRootQuery['sourceHubRoot'];
type ProjectsContent = LoadSourceHubProjectsQuery['sourceHubProjects'];
type FolderContent = LoadSourceProjectFoldersQuery['sourceProjectFolders'];
type EntitiesContent = LoadSourceFolderContentsQuery['sourceFolderContents'];
type RootContent = LoadSourceProjectFoldersQuery['sourceProjectFolders'];
type Entity =
  | EntitiesContent['entities'][0]
  | FolderContent['folders'][0]
  | HubsContent['hubs'][0]
  | ProjectsContent['projects'][0];

export const categorizeEntity = (sourceEntity: Entity, disabledIDs: string[]) => {
  const { id, name } = sourceEntity;
  const entry = {
    id,
    name,
    disabled: disabledIDs.includes(id),
    isFolder: sourceEntity.__typename !== 'SourceAsset',
    sourceUpdatedAt: undefined as string | undefined,
  };
  if (sourceEntity.sourceUpdatedAt) {
    entry.sourceUpdatedAt = getDateString(new Date(sourceEntity.sourceUpdatedAt));
  }
  return entry as FileTableCell;
};

export const getEntryForAsset = (asset: FileAsset, disabledIDs: UUID[]) => {
  const { id, name, lastUpdated } = asset;
  const pending = getEntryIsPending(asset);
  const entry = {
    // Use `pending` to disable the checkbox while uploading
    disabled: disabledIDs.includes(id) || pending,
    id,
    isFolder: false,
    name: name || id,
    pending,
    sourceUpdatedAt: lastUpdated,
  };
  return entry as FileTableCell;
};

export const getUploadedAssetEntries = (
  assets: FileAsset[],
  disabledAssetIDs: UUID[]
): FileTableCell[] => {
  return assets.map((asset) => getEntryForAsset(asset, disabledAssetIDs));
};

export const getEntries = (entities: Entity[], disabledIDs: string[] = []) =>
  entities.map((entity) => categorizeEntity(entity as Entity, disabledIDs));

export const getFileTableHubRootEntries = (contents?: HubsContent): FileTableCell[] =>
  !contents ? [] : [...getEntries(contents.hubs)];

export const getFileTableProjectsEntries = (contents?: ProjectsContent): FileTableCell[] =>
  !contents ? [] : [...getEntries(contents.projects)];

export const getFileTableRootEntries = (contents?: RootContent): FileTableCell[] =>
  !contents ? [] : [...getEntries(contents.folders)];

export const getFileTableEntriesForEntities = (
  contents?: EntitiesContent,
  disabledIDs?: string[]
): FileTableCell[] => (!contents ? [] : getEntries(contents.entities, disabledIDs));

export const onNavigationForward = (
  entry: SourceHub | SourceFolder | FileTableCell,
  element: JSX.Element
) => {
  filesExplorerNavigationVar([...filesExplorerNavigationVar(), { ...entry, element }]);
};

export const onNavigationBarEntry = (entry: NavigationBarEntry) => {
  const filesExplorerNavigation = filesExplorerNavigationVar();
  const index = filesExplorerNavigation.findIndex(({ id }) => id === entry.id);
  filesExplorerNavigationVar([...filesExplorerNavigation.slice(0, index + 1)]);
};

type QueryReponses =
  | SourceFolderEntities
  | SourceProjectFolders
  | SourceFolderContents
  | SourceHubProjects
  | SourceHubRoot;

export const getErrorMessage = (queryResponse?: QueryReponses, error?: ApolloError) => {
  if (queryResponse?.sourceError) {
    return queryResponse.sourceError;
  }
  if (error) {
    return error.message;
  }
  return undefined;
};
