import { ItemsSortKey } from '../../../../generated/graphql';
import { getProjectIdFromUrl } from '../../../../utilities/url';
import { useLocalStorageParams } from '../../../../utilities/urlState';
import { getItemsSortBy } from '../../../ItemsList/ItemsListUtils';
import { useItemsTreeQuery } from '../../../ItemsList/useItemsTreeQuery';
import useSendScenariosAnalytics, {
  ScenariosAnalyticsEvents,
} from '../../hooks/useSendScenariosAnalytics';
import { getCountText, sortEntriesByID } from '../../hooks/Utils';
import { ScenarioSandbox } from '../../types';

type ItemSettings = {
  sortKey: ItemsSortKey;
  searchText: string;
};

const useLibraryItems = (sandbox: ScenarioSandbox) => {
  // Analytics
  const sendScenariosAnalytics = useSendScenariosAnalytics();
  const { milestoneID } = sandbox;

  // State
  const [settings, setSettings] = useLocalStorageParams<ItemSettings>(
    { sortKey: ItemsSortKey.SORT_NUMBER, searchText: '' },
    `Scenarios-Library-Items-`
  );
  const { sortKey, searchText } = settings;
  const setSortKey = (sortKey: ItemsSortKey) => {
    setSettings({ ...settings, sortKey });
    sendScenariosAnalytics(ScenariosAnalyticsEvents.SORT_LIBRARY_ITEMS, {
      sortKey,
      milestoneID,
    });
  };
  const setSearchText = (searchText: string) => {
    setSettings({ ...settings, searchText });
    sendScenariosAnalytics(ScenariosAnalyticsEvents.SEARCH_LIBRARY_ITEMS, {
      searchText,
      milestoneID,
    });
  };

  // Data
  const projectId = getProjectIdFromUrl();
  const orderedItemIDs =
    useItemsTreeQuery(
      getItemsSortBy(sortKey, []),
      {
        creators: [],
        scheduleImpacts: [],
        searchText,
        showOptions: false,
        statuses: [],
        visibilities: [],
        currentMilestoneID: sandbox.milestoneID,
        integrations: [],
      },
      undefined,
      sandbox.milestoneID,
      null,
      projectId,
      undefined,
      {}
    ).data?.itemsTree.orderedItemIDs ?? [];

  // filtering and sorting
  const filteredItems = sortEntriesByID(orderedItemIDs, sandbox.milestone.items);

  const countString = getCountText(
    sandbox.milestone.items.filter((i) => !i.parentID).length,
    filteredItems.length
  );

  return {
    countString,
    filteredItems,
    searchText,
    setSearchText,
    setSortKey,
    sortKey,
  };
};

export default useLibraryItems;
