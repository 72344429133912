import { LazyQueryHookOptions, useLazyQuery } from '@apollo/client';

import { TimeEscalationQuery, TimeEscalationQueryVariables } from '../../../../generated/graphql';

import { timeEscalationQuery } from './queries';

export const useTimeEscalationLazyQuery = (
  options?: LazyQueryHookOptions<TimeEscalationQuery, TimeEscalationQueryVariables>
) =>
  useLazyQuery<TimeEscalationQuery, TimeEscalationQueryVariables>(timeEscalationQuery, {
    fetchPolicy: 'no-cache', // force refetches since we depend on onCompleted
    ...options,
  });
