import { FC } from 'react';

import { withStyles } from '../../../theme/komodo-mui-theme';
import { removeYear } from '../../../utilities/string';
import FilterIcon from '../../Icons/FilterIcon';

import InputsSelectReferenceCategorizationMenuItemStyles from './InputsSelectReferenceCategorizationMenuItemStyles';

type InputsSelectReferenceCategorizationMenuItemProps = {
  classes: Classes<typeof InputsSelectReferenceCategorizationMenuItemStyles>;
  categorization: Categorization;
  categorizationFilters: Category[];
};

const InputsSelectReferenceCategorizationMenuItem: FC<
  InputsSelectReferenceCategorizationMenuItemProps
> = ({ classes, categorization, categorizationFilters }) => {
  const hasFilters = categorizationFilters && categorizationFilters.length > 0;
  const categorizationTitle = `${removeYear(categorization.name)} (${
    hasFilters ? categorizationFilters.length : 'any'
  })`;
  const categorizationFilterList =
    categorizationFilters && categorizationFilters.map((f) => f.number || f.name).join(', ');

  return (
    <div
      key={categorization.id}
      className={`space-between flex flex-grow flex-row-reverse items-center gap-2 overflow-hidden pr-2 text-left type-body2 ${
        hasFilters ? '!font-medium' : '!font-light'
      }`}
    >
      <div className="flex flex-shrink flex-grow flex-col overflow-hidden">
        <div className="truncate" dir="ltr">
          {categorizationTitle}
        </div>
        <div className={classes.filterList}>{categorizationFilterList}</div>
      </div>
      {hasFilters && <FilterIcon isFilled={hasFilters} />}
    </div>
  );
};

export default withStyles(InputsSelectReferenceCategorizationMenuItemStyles)(
  InputsSelectReferenceCategorizationMenuItem
);
