import { useCallback, useMemo } from 'react';

import { ProjectCompInput } from '../../../../generated/graphql';
import { useProjectCategorizationsQuery } from '../../../../hooks/useProjectCategorizationsQuery';
import { CategoryMultiSelect } from '../../../dragon-scales';
import { FilterPopover } from '../../../FilterPopover/FilterPopover';
import { ProjectCompInputUpdateFunctions } from '../../ProjectCompsSetInputStore/ProjectCompsSetInputUpdaters';

type Props = {
  projectCompInput: ProjectCompInput;
  projectCompInputViewFilterFunctions: ProjectCompInputUpdateFunctions['projectCompInputViewFilterFunctions'];
};

export function ProjectCompFilterPanel(props: Props) {
  const { data } = useProjectCategorizationsQuery(props.projectCompInput.projectID, false);
  const categorizations = useMemo(
    () => data?.projectCategorizations.map((c) => c.categorization) ?? [],
    [data?.projectCategorizations]
  );

  const { projectCompInputViewFilterFunctions } = props;

  const onChange = useCallback(
    (categorizationID: UUID) => (selectedCategoryIDs: UUID[]) => {
      const existingFilters =
        props.projectCompInput.viewFilter?.categories?.filter(
          (c) => c.categorizationID !== categorizationID
        ) ?? [];
      const newFilters = selectedCategoryIDs.map((v) => ({ categorizationID, id: v }));

      projectCompInputViewFilterFunctions.setProjectCompInputViewFilter({
        categories: [...existingFilters, ...newFilters],
      });
    },
    [projectCompInputViewFilterFunctions, props.projectCompInput.viewFilter?.categories]
  );

  return (
    <FilterPopover
      numFiltersApplied={props.projectCompInput.viewFilter?.categories?.length ?? 0}
      onResetFilters={() => projectCompInputViewFilterFunctions.resetProjectCompInputViewFilter()}
    >
      {categorizations.map((c) => (
        <CategoryMultiSelect
          key={c.id}
          categorization={c}
          onChange={onChange(c.id)}
          value={
            props.projectCompInput.viewFilter?.categories
              ?.filter((f) => f.categorizationID === c.id)
              .map((f) => f.id) ?? []
          }
        />
      ))}
    </FilterPopover>
  );
}
