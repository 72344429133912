import { ComponentProps } from 'react';

import NormalTooltip from '../../NormalTooltip/NormalTooltip';
import { Button } from '../../scales';

import CostSummary from './CostSummary';

type Props = {
  costReports: ComponentProps<typeof CostSummary>['costReports'];
  hasChanges?: boolean;
  hasApplyPermission?: boolean;
  milestoneName?: ComponentProps<typeof CostSummary>['milestoneName'];
  onButtonClick?: () => void;
};

const CardFooter = (props: Props) => {
  const disableApply = !props.hasChanges || !props.hasApplyPermission;

  const button = (
    <div className="w-40">
      <Button
        data-cy="apply-scenario-button"
        isDisabled={disableApply}
        isFullWidth
        label="Apply"
        onClick={() => {
          if (props.onButtonClick) props.onButtonClick();
        }}
        type="secondary"
      />
    </div>
  );

  return (
    <div className="mt-auto flex items-center gap-4 border-t bg-background-1 px-4 py-2">
      <CostSummary costReports={props.costReports} milestoneName={props.milestoneName} />
      {props.onButtonClick &&
        (props.hasApplyPermission || !props.hasChanges ? (
          button
        ) : (
          <NormalTooltip title="You do not have permission to apply a scenario">
            {button}
          </NormalTooltip>
        ))}
    </div>
  );
};

export default CardFooter;
