import { createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
const styles = (theme: any) =>
  createStyles({
    assignee: {
      marginBottom: 8,
    },
    caption: { minWidth: 60, paddingRight: 4 },
    column1: {
      flex: `1 0 ${theme.dialogColumn.minWidth}px`,
      [theme.breakpoints.up('col')]: {
        flex: `2 1 ${2 * theme.dialogColumn.minWidth + theme.dialogColumn.padding}px`,
      },
      flexDirection: 'column',
      margin: `4px ${theme.dialogColumn.padding / 2}px`,
      maxWidth: 2 * theme.dialogColumn.maxWidth + 1 * theme.dialogColumn.padding,
      minWidth: theme.dialogColumn.minWidth,
    },
    column2: {
      flex: `1 0 ${theme.dialogColumn.minWidth}px`,
      flexDirection: 'column',
      margin: `4px ${theme.dialogColumn.padding / 2}px`,
      maxWidth: theme.dialogColumn.maxWidth,
      // minWidth: theme.dialogColumn.minWidth
    },
    content: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      padding: theme.dialogColumn.padding,
      pageBreakInside: 'avoid',
      [theme.breakpoints.up('col')]: {
        minWidth: 800,
      },
    },
    datePickerIcon: {
      width: 36,
      height: 36,
      marginTop: 1,
      color: theme.palette.joinPrimary,
      '&:hover': {
        backgroundColor: 'inherit',
      },
    },
    error: {
      color: theme.palette.red,
    },
    firstRow: {
      marginBottom: 8,
    },
    fillHeight: {
      height: 'calc(100% - 56px)',
    },
    fullHeight: {
      flexGrow: 1,
    },
    inputDateSelect: {
      flex: '1 1 auto',
    },
    inputHeight: {
      minHeight: 18,
    },
    inputRow: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    inputSelect: {
      flex: '1 1 auto',
    },
    milestoneRow: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      paddingTop: 8,
    },
    multiline: {
      height: '100%',
      lineHeight: '17px',
      paddingBottom: 8,
    },
    picker: {
      ...theme.typography.body1,
      ...theme.typography.number,
      width: '100%',
      fontWeight: 300,
      fontSize: 14,
      padding: 6,
      cursor: 'pointer',
    },
    rowContainer: {
      alignItems: 'center',
      display: 'flex',
      flexWrap: 'wrap',
    },
    spacer: {
      flexGrow: 1,
      height: 12,
    },
    timeline: {
      width: '100%',
    },
  });
export default styles;
