import { ComponentProps } from 'react';

import { SourceSystem } from '../../../../generated/graphql';

import ItemIntegrationObjectContent from './ItemIntegrationObjectContent';

type Props = {
  canDeleteItemIntegration: boolean;
  itemIntegrationObjects?: ComponentProps<
    typeof ItemIntegrationObjectContent
  >['itemIntegrationObject'][];
  itemID: UUID;
  linkedProjectID?: string;
  refetchChangeEvents: () => void;
  pageLoadTime?: Date;
  setSubmitted: (value: boolean) => void;
  sourceID: UUID;
  sourceSystem: SourceSystem;
};

export default function ItemIntegrationObjectsContent(props: Props) {
  const changeEventContent = props.itemIntegrationObjects?.map((o) => (
    <ItemIntegrationObjectContent
      key={o.integrationObjectID}
      canDeleteItemIntegration={props.canDeleteItemIntegration}
      itemID={props.itemID}
      itemIntegrationObject={o}
      linkedProjectID={props.linkedProjectID}
      pageLoadTime={props.pageLoadTime}
      refetchChangeEvents={props.refetchChangeEvents}
      setSubmitted={props.setSubmitted}
      sourceID={props.sourceID}
      sourceSystem={props.sourceSystem}
    />
  ));

  return <>{changeEventContent}</>;
}
