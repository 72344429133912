import React, { useEffect } from 'react';
import { InsightsEvent, insightsEvent } from '../../../analytics/analyticsEventProperties';
import { FILTER_PROJECT_ORGANIZATION } from '../../../constants';
import {
  InsightsOrganizationBreakdown,
  InsightsOrgsInput,
  InsightsProjectCounts,
  Org,
  OrgNode,
} from '../../../generated/graphql';
import useSendAnalytics from '../../../hooks/useSendAnalytics';
import ChartsPieGraph from '../../Charts/ChartsD3/ChartsPieGraph/ChartsPieGraph';
import {
  ProjectFilterManager,
  ProjectFilterSetSettings,
} from '../../ProjectsList/ProjectsListUtils';
import useMemoWrapper from '../../useMemoWrapper';

import { buildOrgNodeHierarchy } from './HeaderDonutOrgChartUtils';
import { HeaderDisplayBy } from './InsightsListHeaderPieBar';

export type InsightsOrgsDonutSelections = InsightsOrgsInput & {
  selectedNode?: OrgNode; // Optional selectedNode property to track selectedNode
};

interface Props {
  filterManager: ProjectFilterManager;
  orgLevels: InsightsOrgsDonutSelections[];
  orgs: Org[];
  projectCounts: InsightsProjectCounts;
  selectedDisplayBy: HeaderDisplayBy;
  setOrgLevels: React.Dispatch<React.SetStateAction<InsightsOrgsInput[]>>;
  setSettings: ProjectFilterSetSettings;
}

export default function HeaderDonutOrgCharts(props: Props) {
  const sendAnalytics = useSendAnalytics();
  const { setFilters } = props.filterManager;
  const { filterManager, orgs, setOrgLevels } = props;

  useEffect(() => {
    if (filterManager.filterState.orgNodeIDs.length === 0) {
      // Reset the orgLevels when orgNodeIDs changes to an empty array
      const resetOrgLevels = orgs.map((org) => ({
        orgID: org.id,
        levelIndex: 0, // Reset to the first level
      }));
      setOrgLevels(resetOrgLevels);
    }
  }, [filterManager.filterState.orgNodeIDs, orgs, setOrgLevels]);

  // Generate donut chart data
  const { selectedOrgsData } = convertToPieChartOrgsData(
    props.projectCounts.organizationBreakdowns,
    props.orgs,
    props.selectedDisplayBy,
    props.orgLevels
  );

  // Generate a list of selectable org nodes and its relative parent nodes
  const selectableOrgNodes = useMemoWrapper(
    buildOrgNodeHierarchy,
    props.orgs,
    props.filterManager.filterOptions?.organizationNodes.map((n) => n.id) || []
  );
  const selectableNodeParentMap = selectableOrgNodes.nodeParentMap;
  const selectableNodesAndItsParents: string[] = [];
  Object.entries(selectableNodeParentMap).forEach(([nodeID, parentIDs]) => {
    selectableNodesAndItsParents.push(nodeID);
    parentIDs.forEach((parentID) => {
      selectableNodesAndItsParents.push(parentID);
    });
  });

  const handleSliceClick = (name: string, orgID: string) => {
    let foundDescendantNodes: OrgNode[] = [];
    const matchingOrg = orgs.find((org) => org.id === orgID);
    let matchedNode: OrgNode | undefined;

    if (matchingOrg) {
      matchedNode = matchingOrg.nodes.find((node: OrgNode) => node.name === name);
      if (matchedNode) {
        foundDescendantNodes = collectNodeAndDescendants(matchedNode, matchingOrg.nodes);
      }

      if (name.startsWith('No ')) {
        const selectedLevelInput = props.orgLevels.find(
          (orgLevelInput) => orgLevelInput.orgID === orgID
        );
        if (selectedLevelInput?.selectedNode?.id) {
          setFilters(
            {
              type: FILTER_PROJECT_ORGANIZATION,
              value: '',
              values: [selectedLevelInput.selectedNode.id],
            },
            props.setSettings
          );
        }
      }

      // Filter to only include selectable nodes and their parents
      const selectedNodes = foundDescendantNodes.filter((node) =>
        selectableNodesAndItsParents.includes(node.id)
      );

      if (selectedNodes.length > 0) {
        // Update orgLevels with selectedNode included
        props.setOrgLevels((prevOrgLevels) => {
          const updatedLevels = prevOrgLevels.map((level) => {
            if (level.orgID === orgID) {
              // Increment levelIndex if within range and set selectedNode
              if (level.levelIndex < matchingOrg.levels.length - 1) {
                return { ...level, levelIndex: level.levelIndex + 1, selectedNode: matchedNode };
              }
            }
            return level;
          });
          return updatedLevels;
        });

        // Update the filter to include selected node IDs
        setFilters(
          {
            type: FILTER_PROJECT_ORGANIZATION,
            value: '',
            values: selectedNodes.map((n) => n.id),
          },
          props.setSettings
        );
      }
    }
  };

  return (
    <>
      {selectedOrgsData.map((orgData) => {
        const matchingOrgLevel = props.orgLevels.find((level) => level.orgID === orgData.orgID);
        const levelIndex = matchingOrgLevel?.levelIndex || 0;
        // Check if only one entry has a non-zero `share` value and the name is not "Unassigned"
        const nonZeroEntries = orgData.pieChartData.filter((entry) => entry.share > 0);
        const isSingleNonZeroEntry =
          nonZeroEntries.length === 1 && nonZeroEntries[0].name !== 'Unassigned';
        // If the data only has a valid single entry then display that data name as the center label
        const centerLabel = isSingleNonZeroEntry
          ? { label: nonZeroEntries[0].name, sublabel: orgData.sublabel }
          : { label: orgData.levels[levelIndex], sublabel: orgData.sublabel };

        return (
          <div key={orgData.orgName}>
            <ChartsPieGraph
              centerLabel={centerLabel}
              chartSize={{
                diameter: 100,
                insideDiameter: 70,
              }}
              data={orgData.pieChartData}
              // dataOther={pieChartOtherOrgs[i]} TODO
              displayLegendTooltip
              displaySectionTooltip
              headerTitle={orgData.orgName}
              isCurrency={Boolean(props.selectedDisplayBy === HeaderDisplayBy.VOLUME)}
              labelStyle="type-label"
              onSliceClick={(name: string) => handleSliceClick(name, orgData.orgID)}
              onSliceTooltip={() =>
                sendAnalytics(
                  insightsEvent(InsightsEvent.SUMMARY_TOOLTIP_VIEW, {
                    area: 'Orgs',
                    level: 'detail',
                    org: orgData.orgName,
                  })
                )
              }
              onSummaryTooltip={() =>
                sendAnalytics(
                  insightsEvent(InsightsEvent.SUMMARY_TOOLTIP_VIEW, {
                    area: 'Orgs',
                    level: 'summary',
                    org: orgData.orgName,
                  })
                )
              }
              title={orgData.orgName}
            />
          </div>
        );
      })}
    </>
  );
}

const convertToPieChartOrgsData = (
  organizationBreakdowns: InsightsOrganizationBreakdown[],
  orgs: Org[],
  selectedDisplayBy: HeaderDisplayBy,
  orgLevels: InsightsOrgsInput[]
): {
  selectedOrgsData: {
    orgName: string;
    levels: string[];
    orgID: string;
    sublabel: string;
    pieChartData: { name: string; share: number }[];
  }[];
} => {
  // Sort organizationBreakdowns by org name by matching organizationID to orgs.id
  const sortedOrganizationBreakdowns = [...organizationBreakdowns].sort((a, b) => {
    const orgA = orgs.find((org) => org.id === a.organizationID);
    const orgB = orgs.find((org) => org.id === b.organizationID);
    const orgNameA = orgA?.name || '';
    const orgNameB = orgB?.name || '';

    return orgNameA.localeCompare(orgNameB); // Sort alphabetically by org name
  });

  // Only display the first 3 org donut charts
  const organizationBreakdownsForDisplay = sortedOrganizationBreakdowns.slice(0, 3);

  const selectedOrgsData = organizationBreakdownsForDisplay.map((orgBreakdown) => {
    const matchedOrg = orgs.find((org) => org.id === orgBreakdown.organizationID);
    const orgLevelDetails = orgLevels.find((e) => e.orgID === orgBreakdown.organizationID);
    let currentLevel = '';
    const levelIndex = orgLevelDetails?.levelIndex || 0;
    if (matchedOrg && levelIndex > 0) {
      currentLevel = matchedOrg.levels[levelIndex];
    }

    const pieChartData = orgBreakdown.breakdown
      .map((item) => ({
        name: item.label,
        share:
          selectedDisplayBy === HeaderDisplayBy.COUNT ? Number(item.count) : Number(item.volume),
      }))
      // Sort by largest share first, but place "Unassigned" last
      .sort((a, b) => {
        if (a.name === 'Unassigned') return 1; // Place "Unassigned" last
        if (b.name === 'Unassigned') return -1; // Place "Unassigned" last
        return b.share - a.share; // Sort by share in descending order
      })
      // Replace "Unassigned" with "No {currentLevel}" for any levels after the top level
      .map((entry) => ({
        ...entry,
        name: entry.name === 'Unassigned' && levelIndex > 0 ? `No ${currentLevel}` : entry.name,
      }));

    const validEntries = pieChartData.filter((entry) => entry.share > 0);

    return {
      orgName: matchedOrg?.name || '',
      levels: matchedOrg?.levels || [],
      orgID: matchedOrg?.id || '',
      sublabel: `(${String(validEntries.length)})`,
      pieChartData,
    };
  });

  return { selectedOrgsData };
};

// Function to collect the node and its descendants in a flat list
const collectNodeAndDescendants = (node: OrgNode, allNodes: OrgNode[]): OrgNode[] => {
  const result: OrgNode[] = [node];

  const collectDescendants = (id: string) => {
    const children = allNodes.filter((node) => node.parentID === id);
    result.push(...children); // Add found children to the result list
    children.forEach((child) => collectDescendants(child.id)); // Recursively collect descendants
  };

  collectDescendants(node.id);
  return result; // Return the flat list of node entries
};
