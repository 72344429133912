import { gql, programFields, searchProjectFields } from '../../../../../api/graphqlFragments';

export const searchProjectsForProjectSelection = gql`
  query searchProjectsForProjectSelection(
    $filters: ForecastingProjectsFilters!
    $searchResultType: SearchResultType
    $search: String!
    $sortBy: ForecastingProjectsSortBy!
    $pagination: Pagination!
  ) {
    searchProjects(
      filters: $filters
      searchResultType: $searchResultType
      search: $search
      sortBy: $sortBy
      pagination: $pagination
    ) {
      data {
        budget
        estimate
        id
        isAdmin
        location
        milestoneGSF
        milestoneName
        name
        runningTotal
        status
        thumbnail
        type
        updatedAt
        updatedBy
      }
      total
    }
  }
`;

export const searchProjects = gql`
  query searchProjects(
    $filters: ForecastingProjectsFilters!
    $searchResultType: SearchResultType
    $search: String!
    $sortBy: ForecastingProjectsSortBy!
    $pagination: Pagination!
  ) {
    searchProjects(
      filters: $filters
      searchResultType: $searchResultType
      search: $search
      sortBy: $sortBy
      pagination: $pagination
    ) {
      data {
        ...searchProjectFields
      }
      total
    }
  }
  ${searchProjectFields}
`;

export const searchProjectsIds = gql`
  query searchProjectsIds(
    $filters: ForecastingProjectsFilters!
    $searchResultType: SearchResultType
    $search: String!
    $sortBy: ForecastingProjectsSortBy!
    $pagination: Pagination!
  ) {
    searchProjects(
      filters: $filters
      searchResultType: $searchResultType
      search: $search
      sortBy: $sortBy
      pagination: $pagination
    ) {
      data {
        id
      }
      total
    }
  }
`;

export const programs = gql`
  query programs($input: ProgramQueryInput!) {
    programs(input: $input) {
      ...programFields
    }
  }
  ${programFields}
`;
