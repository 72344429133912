import {
  costTypeFields,
  eventDataItemFields,
  gql,
  itemEventSummaryFields,
  quantityFields,
} from '../../../../../api/graphqlFragments';

export const costTrendlineEventsQuery = gql`
  query costTrendlineEvents(
    $projectID: UUID!
    $milestoneID: UUID!
    $costMode: CostMode!
    $viewFilter: ViewFilter!
    $columnInputs: [CostReportColumnInput!]!
    $quantityMagnitude: Numeric
  ) {
    costTrendlineEvents(
      projectID: $projectID
      milestoneID: $milestoneID
      costMode: $costMode
      viewFilter: $viewFilter
      columnInputs: $columnInputs
      quantityMagnitude: $quantityMagnitude
    ) {
      estimate
      target
      runningTotal
      events {
        timestamp
        eventType
        item {
          ...eventDataItemFields
        }
        eventContent {
          oldCost {
            ...costTypeFields
          }
          newCost {
            ...costTypeFields
          }
          option {
            ...itemEventSummaryFields
          }
          options {
            ...itemEventSummaryFields
          }
          oldMilestone
          newMilestone
          oldOptionStatus
          newOptionStatus
          oldStatus
          newStatus
          parent {
            ...itemEventSummaryFields
          }
        }
        visible
        cost
        y0
        y
        yPoint
        ids
      }
    }
  }
  ${costTypeFields}
  ${eventDataItemFields}
  ${itemEventSummaryFields}
`;

// it's likely unsafe to request a full milestone here as the backend doesn't load
// any of the cost information
export const milestonesCostTrendsQuery = gql`
  query milestonesCostTrendsData(
    $projectID: UUID!
    $columns: [CostReportColumnInput!]!
    $intervalType: IntervalType!
    $costMode: CostMode!
    $viewFilter: ViewFilter!
    $summary: Boolean!
  ) {
    milestonesCostTrends(
      parameters: {
        projectID: $projectID
        columns: $columns
        intervalType: $intervalType
        costMode: $costMode
        viewFilter: $viewFilter
        summary: $summary
      }
    ) {
      milestone {
        id
        name
        description
        date
        quantities {
          ...quantityFields
        }
      }
      points {
        dateStart
        dateEnd
        values {
          type
          y
          isChanged
        }
        isVisible
      }
    }
  }
  ${quantityFields}
`;
