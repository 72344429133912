import { useParams } from 'react-router-dom';

import { useMutation } from '@apollo/client';

import { refetchPermissions } from '../../../../api/refetchSets';
import {
  PermissionLevel,
  UpdatePermissionMutation,
  UpdatePermissionMutationVariables,
  UpdateRoleNameMutation,
  UpdateRoleNameMutationVariables,
  UpdateRoleTradeMutation,
  UpdateRoleTradeMutationVariables,
} from '../../../../generated/graphql';
import { useRefetch } from '../../../../hooks/useRefetch';
import { getProjectIdFromUrl } from '../../../../utilities/url';

import {
  updatePermissionMutation,
  updateRoleNameMutation,
  updateRoleTradeMutation,
} from './queries';

export type PermissionUpdate = {
  id: UUID;
  level: PermissionLevel;
};

export type PermissionChanges = {
  roleID: UUID;
  name?: string;
  hasTrade?: boolean;
  permissionUpdates: PermissionUpdate[];
};

export const isDefined = (name?: string | boolean) => name !== undefined;

const useSyncPermissionsWithBackend = () => {
  const [updateRoleNameMutationFunc] = useMutation<
    UpdateRoleNameMutation,
    UpdateRoleNameMutationVariables
  >(updateRoleNameMutation);
  const [updateRoleTradeMutationFunc] = useMutation<
    UpdateRoleTradeMutation,
    UpdateRoleTradeMutationVariables
  >(updateRoleTradeMutation);
  const [updatePermissionMutationFunc] = useMutation<
    UpdatePermissionMutation,
    UpdatePermissionMutationVariables
  >(updatePermissionMutation);
  const projectID = getProjectIdFromUrl();
  const { roleId } = useParams();

  if (!roleId) {
    throw new Error('Failed to get roleId param');
  }
  const refetch = useRefetch(refetchPermissions(projectID, roleId));

  return async ({ roleID, name, hasTrade = false, permissionUpdates }: PermissionChanges) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
    const mutations: Promise<any>[] = [];
    // if new role name, then do it!
    if (roleID) {
      if (name && isDefined(name))
        mutations.push(updateRoleNameMutationFunc({ variables: { projectID, roleID, name } }));
      if (isDefined(hasTrade))
        mutations.push(updateRoleTradeMutationFunc({ variables: { projectID, roleID, hasTrade } }));
      permissionUpdates.forEach((permissionUpdate: PermissionUpdate) => {
        mutations.push(
          updatePermissionMutationFunc({ variables: { projectID, ...permissionUpdate } })
        );
      });
      if (mutations.length > 0) {
        // wait til these all complete then...
        await Promise.all(mutations).then(() => {
          // refetch role
          refetch();
        });
      }
    }
  };
};

export default useSyncPermissionsWithBackend;
