import { useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Search } from '@material-ui/icons';

import { SortDirection } from '../../../generated/graphql';
import useDebouncedState from '../../../hooks/useDebouncedState';
import { useProjectID } from '../../../utilities/routes/params';
import { SortManager, TextInput } from '../../scales';

import CompaniesTable from './CompaniesTable';
import { CompaniesTableColumns } from './constants';
import useCompanies from './hooks/useCompanies';

export default function CompaniesRoute() {
  const projectID = useProjectID();
  if (!projectID) throw Error('No projectID found');

  const [searchParams, setSearchParams] = useSearchParams();
  const [searchTerm, debouncedSearchTerm, setSearchTerm] = useDebouncedState(
    searchParams.get('search') ?? ''
  );
  const onChangeSearchTerm = (value: string) => {
    setSearchTerm(value);
    setSearchParams(value ? { search: value } : {});
  };

  const [sortState, setSort] = useState<SortManager['sortState']>({
    sortKey: CompaniesTableColumns.COMPANY,
    sortDirection: SortDirection.SORT_ASCENDING,
  });
  const sortManager: SortManager = useMemo(() => ({ sortState, setSort }), [sortState]);

  const companies = useCompanies(projectID, debouncedSearchTerm, sortState);

  return (
    <div className="flex flex-col gap-2 overflow-auto">
      <div className="flex shrink-0 flex-row gap-2 px-6 pt-4">
        <div className="w-64">
          <TextInput
            // TODO CT-1036: make/use a SearchInput scales component
            data-cy="companies-list-search-input"
            label="Search"
            onChange={onChangeSearchTerm}
            onClear={() => onChangeSearchTerm('')}
            placeholder="Search text fields"
            startAdornment={<Search />}
            value={searchTerm}
          />
        </div>
      </div>
      <div className="w-full overflow-auto">
        <CompaniesTable
          companies={companies}
          projectID={projectID}
          searchTerm={searchTerm}
          sortManager={sortManager}
        />
      </div>
    </div>
  );
}
