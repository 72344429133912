import { FC } from 'react';

import { costReportMetricColumnsAnalytics } from '../../../analytics/analyticsEventProperties';
import { METRICS } from '../../../constants';
import useSendAnalytics from '../../../hooks/useSendAnalytics';
import { useDetailedMilestonesQuery } from '../../Milestones/hooks';
import { getGroupsForUnits } from '../CostReportMetricGroup/CostReportMetricGroupUtils';

import CostReportSelectMulti from './CostReportSelectMulti';
import { getInitialSelection, useEnabledUnitQuantities } from './CostReportSelectUtils';

type CostReportSelectMetricsProps = {
  milestoneID: UUID;
  settings: MilestoneCostReportSettings;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  setSettings?: (settings: Record<string, any>) => void;
};

const CostReportSelectMetrics: FC<CostReportSelectMetricsProps> = ({
  milestoneID,
  settings,
  setSettings,
}) => {
  // Data
  const sendAnalytics = useSendAnalytics();
  const { units, projectID } = useEnabledUnitQuantities(milestoneID);
  const { data: { milestones = [] } = {} } = useDetailedMilestonesQuery(projectID, false);
  const { metrics } = settings;
  const groups = getGroupsForUnits(milestones, [milestoneID], units, metrics, METRICS);

  const initialSelection = getInitialSelection(metrics, groups);

  const onClose = (selected: string[]) => {
    setSettings?.({ metrics: selected });
    sendAnalytics(costReportMetricColumnsAnalytics(selected));
  };

  return (
    <CostReportSelectMulti groups={groups} initialSelection={initialSelection} onClose={onClose} />
  );
};

export default CostReportSelectMetrics;
