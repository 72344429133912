import { ReactNode, useRef } from 'react';
import { useButton } from 'react-aria';

type Props = {
  label: string;
  onClick: () => void;
  startAdornment?: ReactNode;
};

export default function Button(props: Props) {
  const ref = useRef<HTMLButtonElement>(null);
  const { buttonProps } = useButton(
    {
      onPress: props.onClick,
    },
    ref
  );
  return (
    <button
      ref={ref}
      className="flex w-full cursor-pointer items-center gap-2 border-b px-4 py-2 type-button hover:bg-background-1 focus-visible:outline active:outline-none"
      {...buttonProps}
    >
      {props.startAdornment}
      <div className="whitespace-nowrap">{props.label}</div>
    </button>
  );
}
