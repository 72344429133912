import {
  gql,
  projectComparisonReportFields,
  projectCompsSetInputReturnFields,
} from '../../../../api/graphqlFragments';

export const projectComparisonReports = gql`
  query projectComparisonReports(
    $projectID: UUID
    $search: String!
    $sortBy: ForecastingReportsSortBy!
  ) {
    projectComparisonReports(projectID: $projectID, search: $search, sortBy: $sortBy) {
      ...projectComparisonReportFields
    }
  }
  ${projectComparisonReportFields}
`;

export const projectComparisonReport = gql`
  query projectComparisonReport($reportID: UUID!) {
    projectComparisonReport(reportID: $reportID) {
      ...projectComparisonReportFields
    }
  }
  ${projectComparisonReportFields}
`;

export const createProjectComparisonReport = gql`
  mutation createProjectComparisonReport(
    $attachedProjectID: UUID
    $reportName: String!
    $reportDescription: String!
    $projectCompsSetInput: ProjectCompsSetInput!
  ) {
    createProjectComparisonReport(
      attachedProjectID: $attachedProjectID
      reportName: $reportName
      reportDescription: $reportDescription
      projectCompsSetInput: $projectCompsSetInput
    ) {
      ...projectComparisonReportFields
    }
  }
  ${projectComparisonReportFields}
`;

export const projectCompsSetInput = gql`
  query projectCompsSetInput($reportID: UUID!) {
    projectCompsSetInput(reportID: $reportID) {
      ...projectCompsSetInputReturnFields
    }
  }
  ${projectCompsSetInputReturnFields}
`;

export const deleteForecastingReport = gql`
  mutation deleteForecastingReport($reportID: UUID!) {
    deleteForecastingReport(reportID: $reportID)
  }
`;

export const updateProjectComparisonReport = gql`
  mutation updateProjectComparisonReport(
    $reportID: UUID!
    $reportName: String
    $reportDescription: String
    $projectCompsSetInput: ProjectCompsSetInput
    $attachedProjectID: UUID
  ) {
    updateProjectComparisonReport(
      reportID: $reportID
      reportName: $reportName
      reportDescription: $reportDescription
      projectCompsSetInput: $projectCompsSetInput
      attachedProjectID: $attachedProjectID
    ) {
      ...projectComparisonReportFields
    }
  }
  ${projectComparisonReportFields}
`;
