import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';

export default (theme: KomodoTheme) =>
  createStyles({
    containerInner: {
      alignItems: 'center',
      background: theme.palette.backgroundGrey,
      display: 'flex',
      height: '100%',
      justifyContent: 'center',
      width: '100%',
    },
    containerOuter: {
      boxSizing: 'border-box',
      height: '100%',
      width: '100%',
    },
    padding: {
      padding: 12,
    },
  });
