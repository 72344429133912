import { FC } from 'react';

import { IconButton, Typography } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';

import { withStyles } from '../../../../../theme/komodo-mui-theme';
import { calendarString, parseDate } from '../../../../../utilities/dates';
import {
  CostTrendlineEvent,
  getEventDescription,
} from '../../../../dashboard/DashboardCharts/DashboardChartsTrend/DashboardChartsTrendUtils';
import EventsDescription from '../../../../Events/EventsDescription/EventsDescription';
import styles from '../../DialogsHideListItemStyles';

type Props = {
  charNameLimit: number;
  classes: Classes<typeof styles>;
  onClickToggleEventVisibility: () => void;
  onFocus: (trendlineEvent: CostTrendlineEvent) => void;
  onMouseLeave: () => void;
  onMouseOver: (trendlineEvent: CostTrendlineEvent) => void;
  milestoneID: UUID;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  selected: boolean;
  trendlineEvent: CostTrendlineEvent;
};

const DialogsHideCostTrendlineEventListItem: FC<Props> = ({
  charNameLimit = 100,
  classes,
  onClickToggleEventVisibility,
  onFocus,
  onMouseLeave,
  onMouseOver,
  milestoneID,
  selected,
  trendlineEvent,
}) => {
  const tooltip = parseDate(trendlineEvent.timestamp?.toLocaleString()) || '';
  const date = calendarString(trendlineEvent.timestamp?.toLocaleString() || '');

  const description = getEventDescription(trendlineEvent, milestoneID, false);

  return (
    <div
      className={selected ? `${classes.root} ${classes.selected}` : `${classes.root}`}
      onFocus={() => onFocus(trendlineEvent)}
      onMouseLeave={() => onMouseLeave()}
      onMouseOver={() => onMouseOver(trendlineEvent)}
    >
      {description && description.length > 0 && (
        <div
          className={
            trendlineEvent.visible
              ? `${classes.description}`
              : `${classes.description} ${classes.hidden}`
          }
        >
          <EventsDescription
            charNameLimit={charNameLimit}
            description={description}
            variant="body1"
          />
        </div>
      )}
      <Typography
        className={trendlineEvent.visible ? `${classes.date}` : `${classes.date} ${classes.hidden}`}
        title={tooltip}
      >
        {date}
      </Typography>
      <IconButton
        aria-label="Hide"
        className={classes.visibleIcon}
        onClick={onClickToggleEventVisibility}
      >
        {trendlineEvent.visible && <Visibility className={classes.visible} />}
        {!trendlineEvent.visible && (
          <VisibilityOff className={`${classes.visible} ${classes.hidden}`} />
        )}
      </IconButton>
    </div>
  );
};

export default withStyles(styles)(DialogsHideCostTrendlineEventListItem);
