import { useEffect, useMemo } from 'react';

import { Divider } from '@material-ui/core';

import { SearchResultType } from '../../../../generated/graphql';
import AdvancedFilters from '../../../frame/AdvancedFiltersSidebar/AdvancedFilters';
import ProjectsFilters from '../../../frame/AdvancedFiltersSidebar/ProjectsFilters';
import { ProgramToggleValue } from '../ProgramToggle';
import SearchHeader from '../SearchHeader';

import { activeFiltersCountProjects } from './activeFilterCountProjects';
import { useProgramsQuery } from './hooks/useProgramsQuery';
import { useSearchProjectsQuery } from './hooks/useSearchProjectsQuery';
import useSearchProjectsQueryParams from './hooks/useSearchProjectsQueryParams';
import { useUpdateSearchProjectsFromURL } from './hooks/useUpdateSearchProjectsFromURL';
import SearchProjects from './SearchProjects';
import { getProgramVariables, useFilterOptions } from './utils';
import SearchPrograms from './SearchPrograms';
import BannerManager from './BannerManager';

type Props = {
  isFilterMenuOpen: boolean;
  onToggleFilterMenu: (isOpen: boolean) => void;
};

const SearchProjectsData = (props: Props) => {
  useEffect(() => {
    document.title = 'Projects';
  }, []);

  const params = useSearchProjectsQueryParams();
  useUpdateSearchProjectsFromURL(
    params.onChangeSearch,
    params.onResetFilters,
    params.toggleParams?.onChange
  );

  const {
    data: projectData,
    fetchMore,
    loading: loadingProjects,
    previousData,
  } = useSearchProjectsQuery(params.variables);
  const programVariables = getProgramVariables(params.variables);
  const programQuery = useProgramsQuery(programVariables, {
    onCompleted: (data) => params.setProgramCounts(data?.programs?.length ?? 0),
  });

  const { searchResultType, filters } = params.variables;
  const filterOptions = useFilterOptions(searchResultType !== SearchResultType.MY, filters);
  const projects = projectData?.searchProjects?.data ?? previousData?.searchProjects?.data ?? [];
  // const programs = programData?.programs ?? [];
  const programs = programQuery.data?.programs ?? programQuery.previousData?.programs ?? [];
  const counts =
    params.programToggleParams?.value === ProgramToggleValue.PROJECTS
      ? {
          current: projectData?.searchProjects?.total ?? previousData?.searchProjects?.total ?? 0,
          total:
            searchResultType !== SearchResultType.MY
              ? params.toggleCounts.all
              : params.toggleCounts.my,
        }
      : {
          current: programQuery?.data?.programs?.length ?? 0,
          total: programQuery?.data?.programs?.length ?? 0,
        };

  const activeFiltersCount = useMemo(() => {
    return activeFiltersCountProjects(filters);
  }, [filters]);

  const isViewingPrograms = params.programToggleParams?.value === ProgramToggleValue.PROGRAMS;

  return (
    <div className="flex h-full grow flex-col">
      <SearchHeader
        activeFiltersCount={activeFiltersCount}
        isFilterMenuOpen={props.isFilterMenuOpen}
        isViewingPrograms={isViewingPrograms}
        onChangeSearch={params.onChangeSearch}
        onToggleFilterMenu={props.onToggleFilterMenu}
        programToggleParams={params.programToggleParams}
        search={params.variables.search}
        searchPlaceholder="Search projects"
        toggleParams={params.toggleParams}
      />
      <Divider />
      <BannerManager />
      <div className="flex flex-grow overflow-hidden">
        {props.isFilterMenuOpen && !isViewingPrograms && (
          <AdvancedFilters
            hasActiveFilters={activeFiltersCount > 0}
            onCloseFiltersPanel={props.onToggleFilterMenu}
            onResetFilters={params.onResetFilters}
          >
            <ProjectsFilters
              filterOptions={filterOptions}
              filterValues={params.variables.filters}
              isExpanded
              onChangeFilters={params.onChangeFilters}
            />
          </AdvancedFilters>
        )}
        <div className="grow overflow-x-auto">
          {isViewingPrograms ? (
            <SearchPrograms
              counts={counts}
              isLoading={programQuery?.loading}
              programs={programs}
              searchText={params.variables.search}
              sortManager={params.sortManager}
            />
          ) : (
            <SearchProjects
              counts={counts}
              isLoading={loadingProjects}
              onFetchMore={fetchMore}
              projects={projects}
              searchText={params.variables.search}
              sortManager={params.sortManager}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default SearchProjectsData;
