import { MutationHookOptions, useMutation, useReactiveVar } from '@apollo/client';

import { ITEMS_STATUS_CHANGE_ACCEPTED } from '../../actions/actionTypes';
import {
  analyticsEvent,
  updateItemStatusAnalytics,
} from '../../analytics/analyticsEventProperties';
import { itemSidebarOpenVar } from '../../api/apollo/reactiveVars';
import {
  REFETCH_CHANGE_ITEM,
  REFETCH_CHANGE_MILESTONE_EVENTS_LIST,
  REFETCH_ITEM_HISTORY,
  refetchItem,
} from '../../api/refetchSets';
import {
  AttachItemAsOptionMutation,
  AttachItemAsOptionMutationVariables,
  DetachOptionMutation,
  DetachOptionMutationVariables,
  SetStatusMutation,
  SetStatusMutationVariables,
  Status,
} from '../../generated/graphql';
import useSendAnalytics from '../../hooks/useSendAnalytics';
import { getCostMode } from '../../utilities/costMode';
import { getItemIdFromUrl, getProjectIdFromUrl } from '../../utilities/url';
import { replaceQueries } from '../../utilities/utilities';

import {
  attachItemAsOptionMutation,
  detachOptionMutation,
  setStatusMutation,
} from './hooks/itemMutation';

/*
- getReportRefetchQuery
- useUpdateStatus
- useDetachOption
- useAttachItem
*/

function useUpdateStatus(
  options: MutationHookOptions<SetStatusMutation, SetStatusMutationVariables> = {}
) {
  const [setStatus] = useMutation<SetStatusMutation, SetStatusMutationVariables>(
    setStatusMutation,
    options
  );
  const sendAnalytics = useSendAnalytics();
  return (projectID: UUID, item: Pick<ItemLike, 'id' | 'status'>, newStatus: Status) => {
    const urlItemID = getItemIdFromUrl();
    const itemID = item.id;
    const isTheSameID = urlItemID === itemID;
    const parentFetch = isTheSameID || !urlItemID ? [] : [refetchItem(urlItemID)];
    const refetchSet = [
      // make user we use current item/option id
      ...replaceQueries(REFETCH_CHANGE_ITEM, [refetchItem(itemID)]),
      // make sure we update parent item of the option
      ...parentFetch,
    ];
    setStatus({
      refetchQueries: refetchSet,
      variables: {
        projectID,
        itemStatusInput: {
          id: item.id,
          status: newStatus,
        },
        costMode: getCostMode(),
      },
      optimisticResponse: {
        __typename: 'Mutation',
        setStatus: {
          // @ts-ignore TODO CT-545
          __typename: 'setStatus_mutation_response',
          affected_rows: 1,
          returning: {
            ...item,
            status: newStatus,
          },
        },
      },
    }).then(() => {
      sendAnalytics(
        updateItemStatusAnalytics({ itemID: item.id, oldStatus: item.status, newStatus })
      );
      // this event is a bit redundant, but we need to know when a user accepts an item
      // within appcues, and in appcues we don't have access to event properties
      if (newStatus === Status.ACCEPTED)
        sendAnalytics(analyticsEvent(ITEMS_STATUS_CHANGE_ACCEPTED));
    });
  };
}

export default useUpdateStatus;

export function useDetachOption(
  option: Option,
  options: MutationHookOptions<DetachOptionMutation, DetachOptionMutationVariables> = {}
) {
  const [detachOption] = useMutation<DetachOptionMutation, DetachOptionMutationVariables>(
    detachOptionMutation,
    {
      ...options,
      variables: {
        projectID: ((option as Option).project || {}).id || getProjectIdFromUrl(),
        option: option.id,
        costMode: getCostMode(),
      },
      refetchQueries: [...REFETCH_ITEM_HISTORY, refetchItem(option.parent), refetchItem(option.id)],
    }
  );
  return [detachOption];
}

// HOOKS
export const useAttachItem = (projectID: UUID, parent: Item) => {
  const [attachItemAsOption] = useMutation<
    AttachItemAsOptionMutation,
    AttachItemAsOptionMutationVariables
  >(attachItemAsOptionMutation);
  const attachItemFunction = (child: ItemsListItem) =>
    attachItemAsOption({
      variables: {
        projectID,
        parent: parent.id,
        child: child.id,
        costMode: getCostMode(),
      },
      refetchQueries: [
        ...REFETCH_CHANGE_MILESTONE_EVENTS_LIST,
        refetchItem(parent.id),
        refetchItem(child.id),
      ],
    });
  return [attachItemFunction];
};

export const useIsHighlightedItem = (id: UUID) => {
  const isHighlightedItem = useReactiveVar(itemSidebarOpenVar) === id;
  return isHighlightedItem;
};
