import { useState } from 'react';

import { assetCacheVar } from '../../../api/apollo/reactiveVars';
import { fetchBlobUrl } from '../../../api/assets';
import { AssetQuery } from '../../../generated/graphql';

import useAssetQuery from './useAssetQuery';

export const getAssetBlobCacheKey = (assetID: UUID | null | undefined) => `AssetBlob:${assetID}`;

export default function useAssetBlobUrlQuery(
  assetID: UUID | null | undefined,
  onCompletedOuter?: (blobUrl: string | undefined) => void
) {
  const blobUrlCached = assetCacheVar().get(getAssetBlobCacheKey(assetID));
  const [url, setUrl] = useState<string | undefined>(blobUrlCached);
  const [loading, setLoading] = useState<boolean>(!url && !!assetID);
  const onCompleted = async (data: AssetQuery) => {
    const blobUrl = await fetchBlobUrl(data.asset);
    setUrl(blobUrl ?? undefined);
    if (blobUrl) assetCacheVar().set(getAssetBlobCacheKey(assetID), blobUrl);
    onCompletedOuter?.(blobUrl ?? undefined);
    setLoading(false);
  };
  const assetQueryResult = useAssetQuery(assetID, onCompleted);
  return { ...assetQueryResult, url, urlLoading: loading };
}
