import * as d3 from 'd3';

import { DEFAULT_MARGIN } from '../../../constants';
import SVGWithDimensions from '../../Charts/ChartsD3/SVGWithDimensions';
import { useChartDimensions } from '../../Charts/ChartsD3/useChartDimensions';
import { getYDomainPadded } from '../../dragon-scales/TimelineCharts/InsightsCost/utils';
import TimelineXAxis from '../../dragon-scales/TimelineCharts/TimelineXAxis';
import useMemoWrapper from '../../useMemoWrapper';

export const NARROW_WIDTH = 1200;

type Props = {
  placement?: 'top' | 'bottom';
  totalRange: [string, string];
};

export default function XAxis(props: Props) {
  const { placement, totalRange } = props;

  const { ref, dimensions } = useChartDimensions({
    height: 24,
    marginTop: 0,
    marginRight: 0,
    marginBottom: 0,
    marginLeft: 0,
  });
  const { width } = dimensions;
  const margin = {
    ...DEFAULT_MARGIN,
    left: 12,
    right: -12,
  };

  // x domain
  const xMin = new Date(totalRange[0]);
  const xMax = new Date(totalRange[1]);
  const xDomain: [Date, Date] = [xMin, xMax];
  const xRange: [number, number] = [margin.left, (width ?? 0) - margin.right];
  // create x scale
  const x = d3.scaleTime().domain(xDomain).range(xRange);
  // x ticks count
  const ticksTotal = width < NARROW_WIDTH ? 10 : 18;

  // y domain
  const [yDataMin, yDataMax] = [0, 24];

  // Add vertical padding
  const { yDomain, yMin, yMax } = useMemoWrapper(getYDomainPadded, yDataMin, yDataMax);
  const yRange: [number, number] = [24 - margin.bottom, margin.top];
  // create y scale
  const y = d3.scaleLinear().domain(yDomain).range(yRange);

  return (
    <SVGWithDimensions ref={ref} className="-ml-3 -mr-3" dimensions={dimensions}>
      <TimelineXAxis
        placement={placement}
        range={xRange}
        tickTotal={ticksTotal}
        x={x}
        y={y}
        yPosition={placement === 'top' ? yMin : yMax}
      />
    </SVGWithDimensions>
  );
}
