type Entry = { id: UUID };

export function sortEntriesByID<T extends Entry>(orderedIDs: UUID[], entries: T[]) {
  const sortedEntries: T[] = [];
  orderedIDs.forEach((id) => {
    const entry = entries.find((e) => e.id === id);
    if (entry) {
      sortedEntries.push(entry);
    }
  });
  return sortedEntries;
}

export const getCountText = (allCount: number, newCount: number) => {
  const newText = newCount !== allCount ? `${newCount} of ` : '';
  return ` (${newText}${allCount})`;
};
