import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';
import { COMP_WIDTH, PADDING } from '../ProjectCompsStyles';

export default (theme: KomodoTheme) =>
  createStyles({
    container: {
      display: 'flex',
      flex: `0 0 auto`,
      width: COMP_WIDTH,
      background: theme.palette.backgroundWhite,
    },
    innerPlaceholderContainer: {
      background: theme.palette.hoverSemitransparentJoinGrey200,
      display: 'flex',
      flex: `1 0 auto`,
      margin: PADDING,
      paddingTop: 206, // This lines up the button with the project name
    },
    comp: {
      flex: `0 0 auto`,
      background: theme.palette.backgroundWhite,
      height: 'auto',
      width: COMP_WIDTH,
    },
    compHeaderPortal: {
      flex: `0 0 auto`,
      background: theme.palette.backgroundWhite,
      height: 'max-content',
      width: COMP_WIDTH,
    },
    sectionPadding: {
      marginLeft: PADDING,
      marginRight: PADDING,
    },
  });
