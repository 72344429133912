import { FC, useContext, useMemo, useState } from 'react';

import { TermKey } from '../../../../api/gqlEnums';
import { SortDirection } from '../../../../generated/graphql';
import { withStyles } from '../../../../theme/komodo-mui-theme';
import { ProjectTermStore } from '../../../ProjectDisplaySettings/TerminologyProvider';
import { Table } from '../../../scales';
import { styles } from '../ContingencyReportStyles';
import {
  ContingencyReportType,
  ContingencySummaryReportSortKeys,
  ContingencySummaryReportSortManager,
} from '../ContingencyReportUtils';

import {
  getContingencySummaryHeaderRow,
  getContingencySummaryRow,
  processContingencySummary,
} from './ContingencySummaryReportUtils';

type ContingencySummaryReportProps = {
  classes: Classes<typeof styles>;
  contingencyData: ContingencyReport;
  selectedReportType: ContingencyReportType;
};

const ContingencySummaryReport: FC<ContingencySummaryReportProps> = ({
  classes,
  contingencyData,
  selectedReportType,
}) => {
  const [sortState, setSortState] = useState({
    sortKey: ContingencySummaryReportSortKeys.NAME,
    sortDirection: SortDirection.SORT_NONE,
  });

  const setSort = (sortInfo: SortBy) => {
    const { sortKey, sortDirection } = sortInfo;
    setSortState({ sortKey, sortDirection });
  };

  const sortManager: ContingencySummaryReportSortManager = {
    sortState,
    setSort,
  };

  const [hasOverdrawnContingency, setHasOverdrawnContingency] = useState(false);

  const entries = useMemo(() => {
    let hasOverdrawnContingency = false;
    const entryComponents = contingencyData.map((contingency) => {
      if (contingency) {
        const {
          starting,
          used,
          drawn,
          remaining,
          pending,
          isOverdrawn,
          name,
          percentOfMilestoneEstimate,
        } = processContingencySummary(contingency, selectedReportType);
        if (isOverdrawn) {
          hasOverdrawnContingency = true;
        }
        return getContingencySummaryRow(
          classes,
          name,
          starting,
          used,
          drawn,
          remaining,
          pending,
          selectedReportType,
          percentOfMilestoneEstimate ?? undefined
        );
      }
      return [];
    });
    setHasOverdrawnContingency(hasOverdrawnContingency);
    return entryComponents;
  }, [contingencyData, selectedReportType, classes]);

  const termStore = useContext(ProjectTermStore);
  const estimateTerm = termStore.titleCase(TermKey.ESTIMATE);

  const headerContent = getContingencySummaryHeaderRow(
    classes,
    selectedReportType,
    hasOverdrawnContingency,
    estimateTerm
  );

  return (
    <Table
      columnWidths={
        selectedReportType === ContingencyReportType.ACCEPTED_AND_PENDING
          ? ['2fr', '1fr', '1fr', '1fr', '1fr', '2fr']
          : ['3fr', '1fr', '1fr', '1fr', '2fr']
      }
      entries={entries}
      headerContent={headerContent}
      rowHeight="auto"
      sortManager={sortManager}
    />
  );
};

export default withStyles(styles)(ContingencySummaryReport);
