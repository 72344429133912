import { FC } from 'react';

import { CostReportColumnType } from '../../../generated/graphql';
import { getMilestoneIdFromUrl, getProjectIdFromUrl } from '../../../utilities/url';
import { getCostReportByType, getCostValue } from '../../CostReport/CostReportUtils';
import { useMilestoneCostReportsQuery, useMilestoneQuery } from '../../Milestone/hooks';
import TrendingCostReport from '../TrendingCostReport/TrendingCostReport';

const REPORT_STATUSES = [
  CostReportColumnType.ESTIMATE_REPORT,
  CostReportColumnType.PENDING_REPORT,
  CostReportColumnType.ACCEPTED_REPORT,
  CostReportColumnType.TARGET_REPORT,
];

const TrendingCostReportData: FC = () => {
  const milestoneID = getMilestoneIdFromUrl();
  const projectID = getProjectIdFromUrl();
  const columnInputs: CostReportColumnInput[] = REPORT_STATUSES.map((type) => ({
    type,
  }));

  const { data: { milestoneCostReports } = { milestoneCostReports: [] }, loading: reportLoading } =
    useMilestoneCostReportsQuery(milestoneID, projectID, {}, columnInputs);
  const costReport = (milestoneCostReports ?? [])[0];
  const milestone = useMilestoneQuery(milestoneID);

  if (!costReport || reportLoading) return null;
  const accepted = getCostReportByType(costReport, CostReportColumnType.ACCEPTED_REPORT);
  const pending = getCostReportByType(costReport, CostReportColumnType.PENDING_REPORT);
  const estimate = getCostReportByType(costReport, CostReportColumnType.ESTIMATE_REPORT);
  const budget = getCostReportByType(costReport, CostReportColumnType.TARGET_REPORT);

  return (
    <TrendingCostReport
      acceptedTotal={getCostValue(accepted?.range)}
      budgetCost={getCostValue(budget?.range)}
      estimateCost={getCostValue(estimate?.range)}
      milestoneName={milestone.data?.milestone?.name}
      pendingRange={pending?.range}
      projectID={projectID}
    />
  );
};

export default TrendingCostReportData;
