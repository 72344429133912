import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../../theme/komodo-mui-theme';

export default (theme: KomodoTheme) =>
  createStyles({
    pdfCanvas: {
      background: theme.palette.shadedGrey,
      overflowX: 'auto',
      overflowY: 'scroll',
      'text-align': '-webkit-center',
      width: '100%',
    },
    pdfCanvasLoading: {
      overflow: 'hidden',
    },
    pdfContainer: {
      display: 'flex',
      justifyContent: 'center',
    },
    pdfFullWidth: {
      width: '100%',
    },
  });
