import { UsersByIDsQuery, UsersByIDsQueryVariables } from '../../../generated/graphql';
import { useQuery } from '../../../hooks/useMountAwareQuery';

import { usersByIDsQuery } from './queries';

export default function useUsersByIDs(IDs: UUID[], skip?: boolean) {
  return useQuery<UsersByIDsQuery, UsersByIDsQueryVariables>(usersByIDsQuery, {
    variables: { IDs },
    skip,
  });
}
