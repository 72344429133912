import TipTapTextArea, { TipTapTextAreaVariant } from './TipTapTextArea/TipTapTextArea';

type Props = {
  'data-cy'?: string;
  isDisabled?: boolean;
  isEditable: boolean;
  label?: string;
  onAttachAsset?: (asset: Asset) => void;
  onChange?: (value: string, valueStyled: string) => void;
  placeholder?: string;
  value?: string;
  /** @deprecated do not use */
  // eslint-disable-next-line react/no-unused-prop-types
  variant?: TipTapTextAreaVariant;
};

const InputsTextAreaStyled = (props: Props) => {
  return (
    <div className="flex w-full flex-col gap-0.5">
      {props.label && <label className="mr-auto text-type-primary type-label">{props.label}</label>}
      <TipTapTextArea
        data-cy={props['data-cy']}
        isDisabled={props.isDisabled}
        isEditable={props.isEditable}
        onAttachAsset={props.onAttachAsset}
        onChange={props.onChange}
        placeholder={props.placeholder}
        value={props.value ?? ''}
      />
    </div>
  );
};

export default InputsTextAreaStyled;
