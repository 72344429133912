import { SendAnalyticsFn } from '../../../analytics/analyticsEventProperties';
import { FieldType } from '../../../api/gqlEnumsBe';
import { Scale } from '../../../enums';
import { MarkupType } from '../../../generated/graphql';
import {
  formatCommas,
  formatCost,
  getCurrencyScale,
  scrubNumberString,
} from '../../../utilities/currency';
import { getPercentScaleFactor } from '../../../utilities/string';
import { EditorPosition, GridController, GridVariant, Position } from '../types';
import {
  emptyCellValue,
  emptyMarkupValueCell,
  formulaOperators,
  isCategoryCell,
  isMarkupReference,
  isMarkupValue,
  isRegularCell,
  postprocessCost,
  postprocessPercent,
} from '../utilities/cell';

import CategoryCellEditor from './CategoryCellEditor';
import RegularCellEditor from './DefaultCellEditor';
import FormulaCellEditor from './FormulaCellEditor';
import ReferenceCellEditor from './ReferenceCellEditor';
import SelectCellEditor from './SelectCellEditor';
import StringCellEditor from './StringCellEditor';
import { getMarkupSelectCellEditorInput } from './utils';

// HELPERS

const displayValue = (state: GridController, type: string, p: Position): GridCellValue =>
  state.getCellValue(p.row, p.column) ?? emptyCellValue(type);

const parseCostValue = (stringValue: string, scale: number = Scale.CURRENCY) => {
  // if the cell contains invalid characters, return the full string value before scrubbing it
  if (formulaOperators.test(stringValue)) return stringValue;
  // take the input string, and try to scrub it of all non-numeric
  const numberValue = scrubNumberString(stringValue, true);
  if (Number.isNaN(numberValue)) return stringValue;
  if (numberValue === 0) return '0';

  const sign = numberValue < 0 ? -1 : 1;

  const wholeNumber = Math.floor(Math.abs(numberValue / scale));
  const fraction = Math.abs(Math.round(numberValue % scale));
  // we need to show zero cents so formatCost will work better than format commas
  const formatSettings = {
    showCurrencySymbol: false,
    showCents: true,
    showZeroCents: true,
  };
  if (fraction === 0) return formatCost(wholeNumber * sign, { ...formatSettings, scale: 1 });

  const paddedFraction = String(fraction)
    .padStart(String(scale).length - 1, '0')
    .replace(/0+$/, '')
    .padEnd(2, '0');

  const cost = Number(`${wholeNumber * sign}${paddedFraction}`);
  return formatCost(cost, {
    ...formatSettings,
    showAllDigits: true,
    scale: 10 ** String(paddedFraction).length,
  });
};

// PICK AN EDITOR

export const renderEditor = (
  state: GridController,
  editorPosition: EditorPosition,
  defaultValue: GridCellValue | undefined,
  setEditing: (b: boolean) => void,
  gridType: { gridType: string; model: string },
  gridVariant: GridVariant | undefined,
  sendAnalytics: SendAnalyticsFn
) => {
  const { data, selection, isItem, mutateData, toggleMarkupWithoutS2Reference, getCellPosition } =
    state;
  const field = data.columns[selection.start.column];
  if (!field) return null;
  const { type } = field;
  const position = getCellPosition(state.selection.start) || editorPosition;
  const display = displayValue(state, field.type, state.selection.start) ?? defaultValue;

  const editingCell = selection.start;
  const addBuffer = () => state.getKeyBufferString(editingCell);

  if (type === FieldType.CATEGORY && isCategoryCell(display)) {
    return (
      <CategoryCellEditor
        categorization={field.categorization ?? undefined}
        defaultValue={display}
        editingCell={editingCell}
        grid={state}
        gridType={gridType}
        position={position}
        sendAnalytics={sendAnalytics}
        stopEditing={() => setEditing(false)}
        updateCell={(newValue: GridCategoryCellInputs) => {
          mutateData(editingCell, editingCell, [[newValue]]);
        }}
      />
    );
  }
  if (type === FieldType.STRING && isRegularCell(display)) {
    return (
      <StringCellEditor
        defaultValue={display}
        editingCell={editingCell}
        grid={state}
        position={position}
        stopEditing={() => setEditing(false)}
        updateCell={(position: Position, string: string) => {
          mutateData(position, position, [
            [{ string: string + addBuffer(), formula: '', formulaDisplay: [] }],
          ]);
        }}
      />
    );
  }
  if (type === FieldType.SELECT) {
    return (
      <SelectCellEditor
        input={getMarkupSelectCellEditorInput(
          'MarkupType',
          display,
          (newValue?: MarkupTypeCell | MarkupDisplayTypeCell) => {
            mutateData(editingCell, editingCell, [[newValue]]);
          }
        )}
        position={position}
        stopEditing={() => setEditing(false)}
      />
    );
  }
  if (type === FieldType.MARKUP_VALUE) {
    // since the default value for a regular cell
    // doesn't match the type for a markupCell
    // we need to define a default value for the markupCell
    let markupCell = emptyMarkupValueCell;
    if (isMarkupValue(display)) markupCell = display;
    const { number, type: markupType, scale } = markupCell;
    const string =
      markupType === MarkupType.FIXED
        ? String(number)
        : String((number * 100) / getPercentScaleFactor(scale ?? 0));
    const preprocess = markupType === MarkupType.FIXED ? parseCostValue : (s: string) => s;
    const defaultValue: RegularCell = { string, formula: '', formulaDisplay: [] };
    return (
      <RegularCellEditor
        defaultValue={defaultValue}
        editingCell={editingCell}
        grid={state}
        position={position}
        preprocess={preprocess}
        stopEditing={() => setEditing(false)}
        updateCell={(position: Position, string: string) => {
          const s = string + addBuffer();
          let newValue = '';
          if (markupType === MarkupType.FIXED) {
            newValue = postprocessCost(s);
          } else {
            newValue = postprocessPercent(s);
          }
          const number = Number(newValue);
          const cell: MarkupValueCell = { type: markupType, number };
          mutateData(position, position, [[cell]]);
        }}
      />
    );
  }
  if (
    (type === FieldType.REFERENCE || type === FieldType.INHERITED_REFERENCE) &&
    isMarkupReference(display)
  ) {
    const val = state.getCellData(state.selection.start.row, state.selection.start.column);
    const error = val?.data.error;

    return (
      <ReferenceCellEditor
        error={error}
        grid={state}
        markupReferenceCell={display}
        position={position}
        row={editingCell.row}
        selectedRow={state.selection.start.row}
        stopEditing={() => {
          setEditing(false);
        }}
        toggleMarkupWithoutS2Reference={toggleMarkupWithoutS2Reference}
        updateCell={(newValue: MarkupReferenceCell) => {
          mutateData(editingCell, editingCell, [[newValue]]);
        }}
      />
    );
  }

  if ((type === FieldType.CURRENCY || type === FieldType.CURRENCY_9) && isRegularCell(display)) {
    if (display.formula) {
      return getFormulaEditor(
        state,
        editingCell,
        position,
        display,
        setEditing,
        mutateData,
        addBuffer
      );
    }
    return (
      <RegularCellEditor
        defaultValue={display}
        editingCell={editingCell}
        grid={state}
        position={position}
        preprocess={(s: string) => parseCostValue(s, getCurrencyScale(field.type))} // converts backend value to display value ie 500012 -> 5,000.12
        stopEditing={() => setEditing(false)}
        updateCell={(position: Position, value: string) => {
          const string = value.trim() + addBuffer();
          mutateData(position, position, [[{ string, formula: '', formulaDisplay: [] }]]);
        }}
      />
    );
  }
  if ((type === FieldType.NUMBER || type === FieldType.DECIMAL) && isRegularCell(display)) {
    if (display.formula) {
      return getFormulaEditor(
        state,
        editingCell,
        position,
        display,
        setEditing,
        mutateData,
        addBuffer
      );
    }
    return (
      <RegularCellEditor
        defaultValue={display}
        editingCell={editingCell}
        grid={state}
        position={position}
        preprocess={formatCommas} // converts backend value to display value ie 5000 -> 5,000
        stopEditing={() => setEditing(false)}
        updateCell={(position: Position, value: string) => {
          const string = value.trim() + addBuffer();
          mutateData(position, position, [[{ string, formula: '', formulaDisplay: [] }]]);
        }}
      />
    );
  }
  if (type === FieldType.INHERITED_MARKUP_CHECKBOX) {
    return (
      <SelectCellEditor
        input={getMarkupSelectCellEditorInput('MarkupInherited', display, () => {
          state.toggleInheritedItemMarkupLine(editingCell.row);
        })}
        position={position}
        stopEditing={() => setEditing(false)}
      />
    );
  }
  if (type === FieldType.MARKUP_DISPLAY_TYPE) {
    return (
      <SelectCellEditor
        input={getMarkupSelectCellEditorInput(
          'MarkupDisplayType',
          display,
          (newValue?: MarkupTypeCell | MarkupDisplayTypeCell) => {
            mutateData(editingCell, editingCell, [[newValue]]);
          },
          isItem
        )}
        position={position}
        stopEditing={() => setEditing(false)}
      />
    );
  }

  const regularCell: RegularCell = isRegularCell(display)
    ? display
    : { string: '', formula: '', formulaDisplay: [] }; // we shouldn't ever get here
  return (
    <RegularCellEditor
      defaultValue={regularCell}
      editingCell={editingCell}
      grid={state}
      position={position}
      stopEditing={() => setEditing(false)}
      updateCell={(position: Position, value: string) => {
        const string = value + addBuffer();
        mutateData(position, position, [[{ string, formula: '', formulaDisplay: [] }]]);
      }}
    />
  );
};

const getFormulaEditor = (
  state: GridController,
  editingCell: Position,
  position: EditorPosition,
  display: RegularCell,
  setEditing: (b: boolean) => void,
  mutateData: (start: Position, end: Position, values: (GridCellValue | undefined)[][]) => void,
  addBuffer: () => string
) => {
  return (
    <FormulaCellEditor
      defaultValue={display}
      editingCell={editingCell}
      grid={state}
      position={position}
      stopEditing={() => setEditing(false)}
      updateCell={(position: Position, value: string) => {
        const string = value.trim() + addBuffer();
        mutateData(position, position, [[{ string, formula: '', formulaDisplay: [] }]]);
      }}
    />
  );
};
