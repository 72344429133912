import { getIsDeactivated } from '../CompanyTab/CompanyTabUtils';

import useAssigneeByIdQuery from './hooks/useAssigneeByIdQuery';
import UserAvatar from './UserAvatar';

type Props = {
  itemID: UUID;
  userID: UUID;
};

export default function UserAvatarData({ itemID, userID }: Props) {
  const { data, loading } = useAssigneeByIdQuery(userID, itemID);
  if (!data?.userByID || loading) return null;
  const assignee = data.userByID;
  const isDeactivated = getIsDeactivated(assignee);
  return <UserAvatar assignee={assignee} deactivated={isDeactivated} />;
}
