import { createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
const DialogsNewItemCategoriesStyles = (theme: any) =>
  createStyles({
    caption: {
      paddingRight: 4,
      minWidth: 100,
    },
    cardHeader: theme.cardHeader,
    cardHeaderTypography: theme.typography.cardHeader,
    categories: {
      width: '100%',
      marginBottom: 4,
    },
    content: {
      display: 'flex',
      flexWrap: 'wrap',
      padding: theme.dialogColumn.padding,
      justifyContent: 'space-between',
    },
    dialogColumn: {
      display: 'flex',
      flex: `1 1 ${theme.dialogColumn.minWidth}px`,
      flexDirection: 'column',
      margin: `4px ${theme.dialogColumn.padding / 2}px`,
      maxWidth: theme.dialogColumn.maxWidth,
      minWidth: theme.dialogColumn.minWidth,
    },
  });

export default DialogsNewItemCategoriesStyles;
