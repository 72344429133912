import { DatePicker, MuiPickersUtilsProvider } from 'material-ui-pickers';
import { FC, useEffect, useState } from 'react';

import DateFnsUtils from '@date-io/date-fns';

import { withStyles } from '../../theme/komodo-mui-theme';
import { localeDateFormat, maskFn } from '../../utilities/dates';

import { styles } from './TimelineStyles';

type TimelineDatePickerProps = {
  classes: Classes<typeof styles>;
  dataCy?: string;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  disableError?: boolean;
  onChange: (date: Date) => void;
  value?: Date;
  minDate?: Date;
  maxDate?: Date;
};

const TimelineDatePicker: FC<TimelineDatePickerProps> = ({
  classes,
  dataCy,
  disableError,
  onChange,
  value,
  minDate,
  maxDate,
}) => {
  const [selectedDate, setSelectedDate] = useState(value);
  useEffect(() => {
    if (value !== selectedDate) {
      setSelectedDate(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO CT-566: Fix this pls :)
  }, [value]);

  let disableErrorProps = {};
  if (disableError) {
    disableErrorProps = { error: false, helperText: null };
  }

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <DatePicker
        {...disableErrorProps}
        clearable
        data-cy={dataCy}
        format={localeDateFormat}
        InputAdornmentProps={{ classes: { root: classes.icon } }} // this centers the calendar icon
        InputProps={{
          disableUnderline: true,
          classes: { input: classes.dateInput }, // this makes the width equal across columns
        }}
        invalidDateMessage="Not a valid date"
        keepCharPositions
        keyboard
        KeyboardButtonProps={{ classes: { root: classes.icon } }}
        mask={maskFn}
        maxDate={maxDate}
        minDate={minDate}
        onChange={(d) => {
          setSelectedDate(d);
          onChange(d);
        }}
        onKeyDown={(e: React.KeyboardEvent) => {
          if (e.key === 'Enter' && e.target instanceof HTMLInputElement) {
            e.preventDefault(); // prevent display of calendar dialog

            // Skip processing of non-empty strings that won't create valid Date objects.
            if (e.target.value && Number.isNaN(Date.parse(e.target.value))) {
              return;
            }

            // We know there's a valid entry, blur the field to let the onChange take over.
            e.target.blur();
          }
        }}
        placeholder="mm/dd/yyyy"
        value={selectedDate || minDate}
      />
    </MuiPickersUtilsProvider>
  );
};

export default withStyles(styles)(TimelineDatePicker);
