import { PermissionGroup, PermissionLevel } from '../../../../generated/graphql';
import { Table } from '../../../scales';
import {
  getAvailableLevelsForPermissionResource,
  getIsDisabledCostPermission,
  getPermissionsTableLabels,
  permissionLevels,
} from '../utils';

import LevelCell from './cells/LevelCell';
import PermissionDescriptionCell from './cells/PermissionDescriptionCell';
import PermissionTextCell from './cells/PermissionTextCell';
import { PermissionDisplayData } from './permissions';

type Props = {
  canViewCosts?: boolean;
  group: PermissionGroup;
  onChange: (id: UUID, level: PermissionLevel, permissionResource?: string) => void;
  permissionsDisplayData: PermissionDisplayData[];
  shouldDisableMarkups?: boolean;
};

const PermissionsTableEdit = (props: Props) => {
  return (
    <Table
      columnWidths={['1.5fr', '2fr', '1fr', '1fr', '2fr', '2fr']}
      entries={props.permissionsDisplayData.map((displayData) => {
        const levels = getAvailableLevelsForPermissionResource(displayData.resource);
        const matchedPermission = props.group.permissions.find(
          (p) => p.resource === displayData.resource
        );
        if (!matchedPermission) return [];

        const disabled = getIsDisabledCostPermission(
          props.group,
          matchedPermission,
          props.shouldDisableMarkups,
          props.canViewCosts
        );

        return [
          {
            key: displayData.label,
            component: <PermissionTextCell disabled={disabled} label={displayData.label} />,
          },
          {
            key: displayData.description,
            component: <PermissionDescriptionCell disabled={disabled} displayData={displayData} />,
          },
          ...permissionLevels.map((pl) => {
            const isLevelIncluded = levels.includes(pl);
            return {
              key: pl,
              component: (
                <LevelCell
                  disabled={disabled}
                  groupType={props.group.type}
                  isLevelIncluded={isLevelIncluded}
                  onChange={props.onChange}
                  permission={matchedPermission}
                  permissionLevel={pl}
                />
              ),
            };
          }),
        ];
      })}
      headerContent={getPermissionsTableLabels(true)}
    />
  );
};

export default PermissionsTableEdit;
