import { useMutation } from '@apollo/client';

import { REFETCH_COMPANY_PROJECT_TYPES } from '../../../../../api/refetchSets';
import {
  CreateProjectTypeMutationVariables,
  MutationCreateProjectTypeArgs,
} from '../../../../../generated/graphql';

import { createProjectTypeMutation } from './createProjectTypeMutation';

const useCreateProjectTypeMutation = () => {
  const [createProjectType] = useMutation<
    MutationCreateProjectTypeArgs,
    CreateProjectTypeMutationVariables
  >(createProjectTypeMutation, {
    refetchQueries: REFETCH_COMPANY_PROJECT_TYPES,
  });

  return (
    name: string,
    companyID: UUID,
    parentID?: UUID,
    onSuccess?: () => void,
    onFailure?: (e: string | undefined) => void,
    analytics?: () => void
  ) => {
    return createProjectType({
      variables: { name, companyID, parentID },
    })
      .then(() => {
        if (onSuccess) onSuccess();
        if (analytics) analytics();
      })
      .catch((e) => {
        if (onFailure) onFailure(e);
      });
  };
};
export default useCreateProjectTypeMutation;
