import { FC } from 'react';

import theme from '../../../theme/design-system-mui-theme';
import { withStyles } from '../../../theme/komodo-mui-theme';
import IconMenu from '../../Select/SelectMenu/IconMenu';
import { MenuOption } from '../../Select/SelectMenu/SelectOption';
import { SelectVariants } from '../../Select/SelectMenu/SelectStyles';

import AssetsActionMenuStyles from './AssetsActionMenuStyles';

type AssetsActionMenuProps = {
  canDelete: boolean;
  classes: Classes<typeof AssetsActionMenuStyles>;
  detachAttachment?: () => void;
  downloadAsset?: () => void;
  variant?: SelectVariants;
};

const AssetsActionMenu: FC<AssetsActionMenuProps> = ({
  canDelete,
  classes,
  detachAttachment,
  downloadAsset,
  variant = SelectVariants.ASSET,
}) => {
  const menuOptions: MenuOption[] = [];
  if (downloadAsset) {
    menuOptions.push({
      name: 'Download',
      callback: downloadAsset,
    });
  }
  const removeDisabled = !canDelete;
  menuOptions.push({
    color: removeDisabled ? undefined : theme.palette.ds.type.delete,
    name: 'Delete',
    disabled: removeDisabled,
    callback: () => detachAttachment?.(),
    tooltip: removeDisabled ? 'You do not have permission to remove this attachment.' : '',
  });

  return (
    <IconMenu
      className={classes.black}
      cy="assets-action-menu"
      options={menuOptions}
      tooltip="More actions"
      variant={variant}
    />
  );
};

export default withStyles(AssetsActionMenuStyles)(AssetsActionMenu);
