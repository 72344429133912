import SvgIcon from '@material-ui/core/SvgIcon';

import { IconProps } from '.';

export const KeyboardArrowEnd = (props: IconProps) => (
  <SvgIcon
    {...props}
    fill="none"
    height="24"
    overflow="visible"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0 0h24v24H0z" fill="none" />
    <path d="M21 11H6.83l3.58-3.59L9 6l-6 6 6 6 1.41-1.41L6.83 13H21z" />
    <line stroke="currentColor" strokeWidth="2" x1="1" x2="1" y1="20" y2="4" />
  </SvgIcon>
);
