import { QueryHookOptions, useQuery } from '@apollo/client';

import { ProgramQuery, ProgramQueryVariables } from '../../../generated/graphql';
import { programQuery } from '../queries';

export const useProgramQuery = (
  programID?: UUID,
  options: QueryHookOptions<ProgramQuery, ProgramQueryVariables> = {}
) =>
  useQuery<ProgramQuery, ProgramQueryVariables>(programQuery, {
    variables: { programID: programID ?? '' },
    ...options,
    skip: !programID,
  });
