import { useMutation } from '@apollo/client';

import {
  DeleteMilestoneDraftEstimateMutation,
  DeleteMilestoneDraftEstimateMutationOptions,
  DeleteMilestoneDraftEstimateMutationVariables,
} from '../../../generated/graphql';

import { deleteMilestoneDraftEstimateMutation } from './queries';

function useDeleteMilestoneDraftEstimate(options?: DeleteMilestoneDraftEstimateMutationOptions) {
  const [deleteMilestoneDraftEstimateFunc, mutationResult] = useMutation<
    DeleteMilestoneDraftEstimateMutation,
    DeleteMilestoneDraftEstimateMutationVariables
  >(deleteMilestoneDraftEstimateMutation, options);
  const submitFunc = (projectId: UUID, estimateId: UUID) =>
    deleteMilestoneDraftEstimateFunc({
      variables: { projectID: projectId, estimateID: estimateId },
    });

  return [submitFunc, mutationResult] as const;
}

export default useDeleteMilestoneDraftEstimate;
