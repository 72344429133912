import { FC, useState } from 'react';
import { isUUID } from 'validator';

import {
  itemAssetViewerOpenCloseAnalytics,
  milestoneAssetViewerOpenCloseAnalytics,
} from '../../../analytics/analyticsEventProperties';
import { assetViewerOpenVar } from '../../../api/apollo/reactiveVars';
import { SourceSystem } from '../../../generated/graphql';
import useSendAnalytics from '../../../hooks/useSendAnalytics';
import { getItemIdFromUrl, getMilestoneIdFromUrl } from '../../../utilities/url';
import { usePrintItemHistory } from '../../Events/EventsItemHistoryList/EvenItemsHistoryListUtils';
import AssetsViewerData from '../AssetsViewer/AssetsViewerData';
import { isModel, isRemoteAssetType } from '../utils';

import AssetsCardData from './AssetsCard/AssetsCardData';
import RemoteAssetCard from './AssetsCard/RemoteAssetCard';
import ModelCardWithBookmarks from './ModelCardWithBookmarks/ModelCardWithBookmarks';

type AssetsCardsProps = {
  assets: EntityAttachment[];
  detachAttachment?: (assetID: string, sourceSystem?: SourceSystem) => void;
  downloadAsset: (location: string, name: string, id: string) => void;
  hasDeletePermission: boolean;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  readOnly?: boolean;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  showMenu: boolean;
};

const AssetsCards: FC<AssetsCardsProps> = ({
  assets,
  detachAttachment,
  downloadAsset,
  hasDeletePermission,
  readOnly,
  showMenu,
}) => {
  const isPrint = usePrintItemHistory();
  const sendAnalytics = useSendAnalytics();
  const itemID = getItemIdFromUrl();
  const milestoneID = getMilestoneIdFromUrl();

  // State
  const [edittingThumbnail, setEdittingThumbnail] = useState(0);
  const [asset, setAsset] = useState<EntityAttachment | undefined>();

  // Action Functions

  const getAssetViewerOpenCloseAnalytics = (
    assetID: UUID,
    itemID: UUID | null,
    milestoneID: UUID | null,
    isOpen: boolean
  ) => {
    if (itemID) {
      sendAnalytics(itemAssetViewerOpenCloseAnalytics(itemID, assetID, isOpen));
    } else if (milestoneID) {
      sendAnalytics(milestoneAssetViewerOpenCloseAnalytics(milestoneID, assetID, isOpen));
    }
  };

  const openViewer = (i: number, a: EntityAttachment) => {
    setAsset(a);
    setEdittingThumbnail(i);
    assetViewerOpenVar(true);
    getAssetViewerOpenCloseAnalytics(a.id, itemID, milestoneID, true);
  };

  const closeViewer = (assetID: UUID) => {
    assetViewerOpenVar(false);
    getAssetViewerOpenCloseAnalytics(assetID, itemID, milestoneID, false);
  };

  const canDeleteAsset = hasDeletePermission && !readOnly;

  const getRemoteAssetCard = (asset: RemoteAsset) => {
    const card = (
      <RemoteAssetCard
        key={asset.id}
        asset={asset}
        canDelete={canDeleteAsset}
        detachAttachment={
          detachAttachment ? () => detachAttachment(asset.id, asset.sourceSystem) : undefined
        }
        disabled={isPrint}
        showMenu={showMenu && !isPrint}
      />
    );

    if (!isPrint) {
      return (
        <a
          key={asset.id}
          href={asset.remoteLink ?? undefined}
          rel="noopener noreferrer"
          target="_blank"
        >
          {card}
        </a>
      );
    }
    return card;
  };

  const getAssetCard = (asset: EntityAttachment, i: number) => {
    const assetUploadComplete = isUUID(asset.id || ''); // while upload not yet complete, asset id is the file name
    const key = `${asset.id}-${i}`;
    const onClick = () => {
      if ('remoteLink' in asset) {
        window.open(asset.remoteLink, '_blank');
        return;
      }
      openViewer(0, asset);
    };

    return (
      <AssetsCardData
        key={key}
        asset={asset}
        canDelete={canDeleteAsset && assetUploadComplete}
        detachAttachment={detachAttachment}
        disabled={isPrint}
        downloadAsset={downloadAsset}
        onClick={onClick}
        showMenu={showMenu && !isPrint}
      />
    );
  };

  const mapAssetBookmarkCards = (assets: EntityAttachment[]) => {
    return assets.map((a, i) => {
      if (isRemoteAssetType(a) && 'remoteLink' in a) {
        return getRemoteAssetCard(a as RemoteAsset);
      }
      if (isModel(a.name) && 'type' in a) {
        return (
          <ModelCardWithBookmarks
            key={`ModelCardWithBookmarks-${a.id}`}
            asset={a as Asset}
            isPrint={isPrint}
            itemID={itemID}
            openViewer={openViewer}
            showMenu={showMenu && !isPrint}
          />
        );
      }
      return getAssetCard(a, i);
    });
  };

  const shiftViewer = (d: number) => {
    const files = assets;
    const idx = files.findIndex((a) => a.id === (asset ? asset.id : null));
    const next = files[idx + d];
    if (next) {
      openViewer(0, next);
    } else {
      const wrapAroundOperator = d < 0 ? 1 : -1;
      const nextWrapAround = files[idx + d + wrapAroundOperator * files.length];
      if (nextWrapAround) {
        openViewer(0, nextWrapAround);
      }
    }
  };

  return (
    <>
      {mapAssetBookmarkCards(assets)}
      {!!asset && (
        <AssetsViewerData
          asset={asset}
          bookmarkEditIndex={edittingThumbnail}
          canDelete={canDeleteAsset}
          closeModal={closeViewer}
          detachAttachment={detachAttachment ? () => detachAttachment(asset.id) : undefined}
          downloadAsset={() =>
            downloadAsset('location' in asset ? asset.location : '', asset.name, asset.id)
          }
          numAssets={assets.length}
          setEditingView={setEdittingThumbnail}
          shiftViewer={shiftViewer}
          showMenu={showMenu}
          type={'type' in asset ? asset.type : undefined}
          viewOnly={false}
        />
      )}
    </>
  );
};

export default AssetsCards;
