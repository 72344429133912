import { MutationHookOptions, useMutation } from '@apollo/client';

import { REFETCH_CHANGE_ITEM, refetchItem } from '../../../../api/refetchSets';
import {
  CreateItemEstimateMutation,
  CreateItemEstimateMutationVariables,
} from '../../../../generated/graphql';
import { replaceQueries } from '../../../../utilities/utilities';

import { createItemEstimateMutation } from './queries';

export const useCreateItemEstimate = (
  projectID: UUID,
  itemId: UUID,
  options: MutationHookOptions<CreateItemEstimateMutation, CreateItemEstimateMutationVariables> = {}
) => {
  const [createItemEstimateFunc, mutationResult] = useMutation<
    CreateItemEstimateMutation,
    CreateItemEstimateMutationVariables
  >(createItemEstimateMutation, options);
  const submitFunc = () =>
    createItemEstimateFunc({
      variables: {
        projectID,
        item: itemId,
      },
      refetchQueries: replaceQueries(REFETCH_CHANGE_ITEM, [refetchItem(itemId)]),
    });
  return [submitFunc, mutationResult] as const;
};
