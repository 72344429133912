import { useFirstMountState } from 'react-use';

import { WatchQueryFetchPolicy } from '@apollo/client';

import { CACHE_AND_NETWORK, CACHE_FIRST } from './constants';

export enum MountPolicy {
  FETCH_ON_MOUNT, // default value
  SKIP_ON_MOUNT, // rely on the cached value
  FETCH_ON_VARS, // refetch query on variables change, even if there is a cached value
}

const usePolicyOnFirstMount = (mountPolicy = MountPolicy.FETCH_ON_MOUNT): WatchQueryFetchPolicy => {
  const isFirstMount = useFirstMountState();
  if (mountPolicy !== MountPolicy.SKIP_ON_MOUNT && isFirstMount) return CACHE_AND_NETWORK;
  if (mountPolicy === MountPolicy.FETCH_ON_VARS) return CACHE_AND_NETWORK;
  return CACHE_FIRST;
};

export default usePolicyOnFirstMount;
