import { FC, useRef, useState } from 'react';

import { ClickAwayListener, Popper } from '@material-ui/core';
import { Add, ArrowDropDown, Info } from '@material-ui/icons';

import {
  CompanyAdminEventType,
  NewProjectEvent,
  companyAdminAnalyticsEvent,
  newProjectEvent,
} from '../../../analytics/analyticsEventProperties';
import { useCompanyUserQuery } from '../../../hooks/useCompanyUserQuery';
import useHasModuleEntitlement from '../../../hooks/useHasModuleEntitlement';
import useSendAnalytics from '../../../hooks/useSendAnalytics';
import { PROJECT_CREATION } from '../../../moduleEntitlementFlagsList';
import { useProjectTemplatesQuery } from '../../CompanyTab/Standards/ProjectTemplates/hooks';
import DialogsNewProject from '../../Dialogs/DialogsNewProject/DialogsNewProject';
import { Thumbnail } from '../../dragon-scales';
import { YellowLightbulb } from '../../Icons/YellowLightbulb';
import NormalTooltip from '../../NormalTooltip/NormalTooltip';
import { Button, TextLink } from '../../scales';

import EntryCard, { EntryCardVariant } from './EntryCard/EntryCard';

type NewProjectButtonProps = {
  isLarge?: boolean;
};

const NewProjectButton: FC<NewProjectButtonProps> = ({ isLarge = false }) => {
  const canCreateProject = useHasModuleEntitlement(PROJECT_CREATION);

  const { data: companyUserData, loading: companyUserLoading } = useCompanyUserQuery();
  const companyID = companyUserData?.companyUser?.company?.id;
  const { data: projectTemplatesData, loading: projectTemplatesLoading } = useProjectTemplatesQuery(
    companyID,
    false
  );
  const projectTemplates = projectTemplatesData?.projectTemplates ?? [];

  const sendAnalytics = useSendAnalytics();

  const [dialogOpen, setDialogOpen] = useState(false);
  const [popperOpen, setPopperOpen] = useState(false);
  const [template, setTemplate] = useState<ProjectTemplate>();
  const anchorRef = useRef<HTMLDivElement>(null);

  const hasTemplates = projectTemplates.length > 0;

  const onClick = () => {
    if (isLarge) {
      setDialogOpen(true);
    } else {
      setPopperOpen(true);
      sendAnalytics(newProjectEvent(NewProjectEvent.CTA, { location: window.location.pathname }));
    }
  };

  const blankProjectCard = (
    <EntryCard
      dataCy="button-blankProject"
      description="Blank project"
      icon={<Add color="inherit" fontSize="inherit" />}
      onClick={() => {
        setTemplate(undefined);
        setPopperOpen(false);
        setDialogOpen(true);
      }}
    />
  );

  const noProjectTemplatesCard = (
    <EntryCard description="No project templates available." variant={EntryCardVariant.HINT} />
  );

  const templateHintCardDescription = (
    <div className="text-type-primary type-body1">
      <div>
        Start projects quicker with templates.{' '}
        <TextLink
          label="Learn More"
          onClick={() =>
            sendAnalytics(
              companyAdminAnalyticsEvent(CompanyAdminEventType.PROJECT_TEMPLATES_HELP_CTA)
            )
          }
          to="https://success.join.build/en/knowledge/project-templates"
        />
      </div>
      <div>To create a template, please contact your company admin.</div>
    </div>
  );
  const templateHintCard = (
    <EntryCard
      description={templateHintCardDescription}
      icon={<YellowLightbulb />}
      variant={EntryCardVariant.HINT}
    />
  );

  const dialog = (
    <DialogsNewProject
      isOpen={dialogOpen}
      onClose={() => setDialogOpen(false)}
      template={template}
    />
  );

  const loading = companyUserLoading || projectTemplatesLoading;
  if (loading || !companyID || !canCreateProject) {
    return null;
  }

  return (
    <>
      <div ref={anchorRef}>
        {isLarge ? (
          <Button
            data-cy="create-new-project-button"
            label="Create First Project"
            onClick={onClick}
            type="primary"
          />
        ) : (
          <Button
            data-cy="create-new-project-button"
            endIcon={<ArrowDropDown />}
            label="New Project"
            onClick={onClick}
            startIcon={<Add />}
            type="primary"
          />
        )}
      </div>
      <Popper
        anchorEl={anchorRef.current}
        className="z-10"
        open={popperOpen}
        placement="bottom-end"
      >
        <ClickAwayListener
          onClickAway={() => {
            setPopperOpen(false);
          }}
        >
          <div className="m-2 flex w-[360px] flex-col items-start gap-2 bg-background-primary p-3 drop-shadow">
            {/* Blank project */}
            {blankProjectCard}
            <div className="flex items-baseline gap-1">
              <div className="type-label">Or start from a template</div>
              <NormalTooltip
                title={
                  <>
                    <div>
                      You can start a project quickly by using a company provided template. These
                      templates are set up with your company standards including Categorizations,
                      Units of Measures, Item Templates, Team, Roles, Items and/or Milestones.
                    </div>
                    <div>
                      To create new template or change an existing one, please contact your company
                      admin.
                    </div>
                  </>
                }
              >
                <div className="!text-type-inactive !icon-sm">
                  <Info color="inherit" fontSize="inherit" />
                </div>
              </NormalTooltip>
            </div>
            {!hasTemplates && noProjectTemplatesCard}
            {!hasTemplates && templateHintCard}
            {/* Template projects */}
            {projectTemplates.map((t) => {
              return (
                <EntryCard
                  key={t.id}
                  dataCy="NewProjectButton-template"
                  description={t.name}
                  icon={<Thumbnail size={50} thumbnail={t.project.thumbnail} />}
                  onClick={() => {
                    setTemplate(t);
                    setPopperOpen(false);
                    setDialogOpen(true);
                  }}
                />
              );
            })}
          </div>
        </ClickAwayListener>
      </Popper>
      {dialogOpen && dialog}
    </>
  );
};

export default NewProjectButton;
