import { useContext } from 'react';

import { Flag } from '@material-ui/icons';

import { getCostMode, getMarkupModeLabel } from '../../../utilities/costMode';
import { ProjectTermStore } from '../../ProjectDisplaySettings/TerminologyProvider';
import { Chip, SortManager } from '../../scales';
import Card from '../common/Card';
import CardFooter from '../common/CardFooter';
import CardHeader from '../common/CardHeader';
import { ScenarioMilestone } from '../types';

import ItemsTableViewMilestone from './ItemsTable/ItemsTableViewMilestone';

type Props = {
  milestone: ScenarioMilestone;
  name: string;
  isActiveMilestone: boolean;
  sortManager: SortManager;
};

export const BASE_MILESTONE = 'Base Milestone';

const MilestoneCard = (props: Props) => {
  const terms = useContext(ProjectTermStore);
  const costMode = getCostMode();
  const markupModeLabel = getMarkupModeLabel(costMode.markupMode, terms);

  return (
    <Card data-cy="milestone-card">
      <CardHeader>
        <div className="space-between flex h-10 items-center justify-between">
          <Chip data-cy="milestone-chip" text={BASE_MILESTONE} />
        </div>
        <div className="flex flex-col gap-1">
          <div className="flex h-10">
            {props.isActiveMilestone && (
              <div className="text-type-link">
                <Flag />
              </div>
            )}
            <div className="type-body1">{props.name}</div>
          </div>
          <div className="type-label">{markupModeLabel}</div>
        </div>
      </CardHeader>
      <ItemsTableViewMilestone items={props.milestone.items} sortManager={props.sortManager} />
      <CardFooter costReports={props.milestone.costReports} milestoneName={props.name} />
    </Card>
  );
};

export default MilestoneCard;
