import { ReactNode, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { InsightsSort } from '../../../generated/graphql';
import { getTodayUtcNoon } from '../../../utilities/dates';
import { formatNumber } from '../../../utilities/string';
import { SortManager, Table, Tooltip } from '../../scales';
import ItemsLegend from '../Charts/Legends/ItemsLegend';
import XAxis from '../Charts/XAxis';
import ZoomTimeline from '../Charts/ZoomTimeline';
import PendingItemDonutChart from '../PieCharts/PendingItemDonutChart';
import ProjectItems from '../ProjectBabyCharts/ProjectItems';
import PendingPieToolTip from '../ToolTips/PendingPieToolTip';
import ProjectTableTooltip from '../ToolTips/ProjectTableTooltip';
import { InsightsProject, InsightsTabId } from '../types';

import ProjectTableEntry from './ProjectTableEntry';
import StickyBottomRow from './StickyBottomRow';
import { generateProjectPath, getProjectsRange } from './utils';
import useSendAnalytics from '../../../hooks/useSendAnalytics';
import { InsightsEvent, insightsEvent } from '../../../analytics/analyticsEventProperties';

type Props = {
  counts: { current: number; total: number };
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  loading: boolean;
  onFetchMore: () => void;
  projects: InsightsProject[];
  sortManager: SortManager;
  sortState: InsightsSort;
  stickyHeader: ReactNode;
};

export default function ProjectsItems(props: Props) {
  const sendAnalytics = useSendAnalytics();
  const navigate = useNavigate();
  const [startDate, endDate] = getProjectsRange(getTodayUtcNoon());
  const [startStr, setStartStr] = useState(startDate.toISOString());
  const [endStr, setEndStr] = useState(endDate.toISOString());
  const setStartEnd = ([start, end]: string[]) => {
    setStartStr(start);
    setEndStr(end);
  };
  const onZoom = (domain: string[]) => {
    setStartEnd(domain);
  };

  const stickyFooterEntry = StickyBottomRow({
    columnsCount: 5,
    component: <ZoomTimeline onZoom={onZoom} projects={props.projects} zoom={[startStr, endStr]} />,
  });

  const chartsHeader = (
    <div className="w-full">
      <div className="flow-root h-8 w-full items-center">
        <div className="float-left type-table-header">Over timeline</div>
        <div className="float-right -mt-2">
          <ItemsLegend />
        </div>
      </div>
      <div className="-mb-[9px]">
        <XAxis placement="top" totalRange={[startStr, endStr]} />
      </div>
    </div>
  );

  return (
    <>
      <Table
        columnWidths={[
          'minmax(250px, 3fr)',
          'minmax(125px, 0.5fr)',
          'minmax(125px, 0.5fr)',
          'minmax(125px, 0.5fr)',
          'minmax(600px, 6fr)',
        ]}
        entries={props.projects.map((project) => {
          const key = project.id;
          const totalPendingItems =
            project.pendingItemsDueInFuture +
            project.pendingItemsMissingDueDates +
            project.pendingItemsPastDue;

          return [
            {
              key,
              component: (
                <Tooltip
                  content={
                    <div>
                      <ProjectTableTooltip project={project} tabType={InsightsTabId.Items} />
                    </div>
                  }
                  onOpen={() =>
                    sendAnalytics(
                      insightsEvent(InsightsEvent.SUMMARY_PROJECT_TOOLTIP_VIEW, {
                        view: InsightsTabId.Items,
                        projectID: project.id,
                        projectName: project.name,
                      })
                    )
                  }
                >
                  <div>
                    <ProjectTableEntry
                      hasAlert={project.activeAlerts.length > 0}
                      onClick={() =>
                        sendAnalytics(
                          insightsEvent(InsightsEvent.SUMMARY_PROJECT_CLICK, {
                            view: InsightsTabId.Items,
                            source: 'link',
                          })
                        )
                      }
                      project={project}
                      sortState={props.sortState}
                      to={generateProjectPath(project.id)}
                    />
                  </div>
                </Tooltip>
              ),
            },
            {
              key,
              centered: true,
              component: (
                <Tooltip
                  content={
                    <div>
                      <PendingPieToolTip
                        pendingItemsDueInFuture={project.pendingItemsDueInFuture}
                        pendingItemsMissingDueDates={project.pendingItemsMissingDueDates}
                        pendingItemsPastDue={project.pendingItemsPastDue}
                        totalPendingItems={totalPendingItems}
                      />
                    </div>
                  }
                >
                  <div>
                    <PendingItemDonutChart isProjectsList project={project} />
                  </div>
                </Tooltip>
              ),
            },
            {
              key,
              centered: true,
              component: <div>{project.pendingItemsPastDue}</div>,
            },
            {
              key,
              centered: true,
              component: <div>{project.dueNext14DaysItems}</div>,
            },
            {
              key,
              component: (
                <ProjectItems
                  endStr={endStr}
                  onClick={() => {
                    navigate(generateProjectPath(project.id, InsightsTabId.Items));
                    sendAnalytics(
                      insightsEvent(InsightsEvent.SUMMARY_PROJECT_CLICK, {
                        view: InsightsTabId.Items,
                        source: 'chart',
                      })
                    );
                  }}
                  onZoom={onZoom}
                  project={project}
                  startStr={startStr}
                />
              ),
            },
          ];
        })}
        hasParentScroll
        headerContent={[
          {
            component: (
              <div className="h-12 type-table-header">{`Projects (${formatNumber(props.counts.current)} of ${formatNumber(
                props.counts.total
              )})`}</div>
            ),
            key: 'projects',
          },
          {
            component: <div className="h-12 type-table-header">Pending</div>,
            key: 'Pending',
          },
          {
            component: <div className="h-12 type-table-header">Past due</div>,
            key: 'Past',
          },
          {
            component: <div className="h-12 type-table-header">Due next 14d</div>,
            key: 'Due',
          },
          { component: chartsHeader, key: 'timeline' },
        ]}
        isLoading={props.loading}
        onNeedMoreData={props.onFetchMore}
        sortManager={props.sortManager}
        stickyFooterEntry={stickyFooterEntry}
        stickyHeader={props.stickyHeader}
      />
    </>
  );
}
