import { useMutation } from '@apollo/client';

import { finalizeImportEstimateAnalytics } from '../../../../analytics/analyticsEventProperties';
import { EstimateType } from '../../../../api/gqlEnums';
import {
  EstimateTotalType,
  FinalizeImportEstimateMutation,
  FinalizeImportEstimateMutationVariables,
} from '../../../../generated/graphql';
import useSendAnalytics from '../../../../hooks/useSendAnalytics';

import { finalizeImportEstimateMutation } from './queries';

function useFinalizeImportEstimate() {
  const [finalizeImportEstimateFunc, mutationResult] = useMutation<
    FinalizeImportEstimateMutation,
    FinalizeImportEstimateMutationVariables
  >(finalizeImportEstimateMutation);
  const sendAnalytics = useSendAnalytics();

  const submitFunc = (
    projectID: UUID,
    draftID: UUID,
    categorizations: DraftCategorization[],
    estimateType: EstimateType,
    estimateTotalType: EstimateTotalType,
    numberOfLines: number,
    mapping: Map<string, string>,
    mappingUfMF: Map<string, Level>,
    onSuccess?: (estimateID: UUID) => void,
    onFail?: () => void
  ) => {
    const draftCategorizations = categorizations.map((cat) => {
      const c = { ...cat };

      const existingCategorization = mapping.get(c.name);
      if (existingCategorization) c.existingCategorization = existingCategorization;
      else c.existingCategorization = '';

      const level = mappingUfMF.get(c.name);
      if (level) {
        c.level = level.level;
        c.include = true;
        c.existingCategorization = level.builtIn;
      } else c.level = c.level || 1;

      return c;
    });
    return finalizeImportEstimateFunc({
      variables: {
        projectID,
        draftID,
        draftCategorizations,
        estimateTotalType,
      },
    })
      .then(({ data }) => {
        const finalizeImportEstimate = data?.finalizeImportEstimate;
        // if the mutation succeeds then this should have a value.
        if (!finalizeImportEstimate) return;
        const { activeEstimateDraftID, budgetDraftID } = finalizeImportEstimate;
        const estimateID =
          estimateType === EstimateType.ACTIVE_ESTIMATE ? activeEstimateDraftID : budgetDraftID;
        // if failed and no estimateID, then no success callback
        if (estimateID) {
          if (onSuccess) onSuccess(estimateID);
          sendAnalytics(finalizeImportEstimateAnalytics(estimateType, numberOfLines));
        }
      })
      .catch(() => {
        if (onFail) onFail();
      });
  };
  return [submitFunc, mutationResult] as const;
}

export default useFinalizeImportEstimate;
