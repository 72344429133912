import { FC } from 'react';

import { Typography } from '@material-ui/core';

import JoinDialog from '../Dialogs/JoinDialog/JoinDialog';

type MarkupDeletionConfirmationDialogProps = {
  onClose: () => void;
  isOpen: boolean;
  onConfirm: () => void;
};

const MarkupDeletionConfirmationDialog: FC<MarkupDeletionConfirmationDialogProps> = ({
  onClose,
  isOpen,
  onConfirm,
}) => (
  <JoinDialog
    dialog={{
      actionButtons: [
        {
          isLeftAligned: false,
          color: 'secondary',
          dataCy: 'apply-button',
          onClick: () => {
            onConfirm();
            onClose();
          },
          text: 'Delete',
          variant: 'flat',
        },
        {
          isLeftAligned: true,
          color: 'primary',
          dataCy: 'cancel-button',
          onClick: onClose,
          text: 'Cancel',
          variant: 'contained',
        },
      ],
      contentComponent: (
        <Typography>
          You are about to delete one or more contingencies with associated item draws. The
          associated draws will also be removed. Are you sure you want to continue?
        </Typography>
      ),
      headerText: 'Delete contingency',
      onClose,
      open: isOpen,
    }}
    dynamicHeight
    hasDivider
  />
);

export default MarkupDeletionConfirmationDialog;
