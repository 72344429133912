import { TimelineActivityType } from '../../../api/gqlEnumsBe';
import { DesignPhaseType } from '../../../generated/graphql';
import { capitalizeString, pluralizeString } from '../../../utilities/string';

type StatusType = {
  isSuggested: boolean;
  isLoading: boolean;
};

type SuggestedStatusType = {
  milestone: StatusType;
  mobilization: StatusType;
  turnover: StatusType;
};

export const GMP = 'GMP';
export const MOBILIZATION = 'Mobilization';
export const TURNOVER = 'Turnover';

export const defaultActivityInputs = {
  milestone: {
    name: GMP,
    designPhaseID: '',
    date: new Date().toISOString(),
    included: true,
  },
  mobilization: {
    name: MOBILIZATION,
    date: new Date().toISOString(),
    included: true,
  },
  turnover: {
    name: TURNOVER,
    date: new Date().toISOString(),
    included: true,
  },
};

export type SuggestionActivityType = keyof typeof defaultActivityInputs;

export const getIsMobilizationSuggested = (events: TimelineActivity[] | undefined) => {
  if (!events) {
    return false;
  }
  const hasMobilization = events.some(
    (event) =>
      event.name.toLowerCase().includes(MOBILIZATION.toLowerCase()) &&
      event.type === TimelineActivityType.EVENT
  );
  const hasStart = events.some(
    (event) =>
      event.name.toLowerCase().includes('start') && event.type === TimelineActivityType.EVENT
  );
  return !hasMobilization && !hasStart;
};

export const getIsTurnoverSuggested = (events: TimelineActivity[] | undefined) => {
  if (!events) {
    return false;
  }
  const hasTurnover = events.some(
    (event) =>
      event.name.toLowerCase().includes(TURNOVER.toLowerCase()) &&
      event.type === TimelineActivityType.EVENT
  );
  const hasEnd = events.some(
    (event) => event.name.toLowerCase().includes('end') && event.type === TimelineActivityType.EVENT
  );
  return !hasTurnover && !hasEnd;
};

export const getIsMilestoneSuggested = (
  milestones: Pick<Milestone, 'name' | 'designPhase'>[] | undefined,
  hasDesignPhaseGMP: boolean
) =>
  !!milestones &&
  hasDesignPhaseGMP &&
  !milestones.find((milestone) => milestone.name.toLocaleUpperCase().includes(GMP)) &&
  !milestones.find((milestone) =>
    milestone.designPhase?.abbreviation.toLocaleUpperCase().includes(GMP)
  );

export const findDesignPhaseGMP = (designPhases: DesignPhaseType[]) =>
  designPhases.find((phase) => phase.abbreviation.toLocaleUpperCase().includes(GMP));

export const getIsActivityInputComplete = (
  inputs: typeof defaultActivityInputs,
  type: SuggestionActivityType
) => {
  // remove excluded from check
  const { included, ...otherEntries } = inputs[type];
  return Object.values(otherEntries).every((v) => !!v);
};

export const getShouldCreateActivity = (
  inputs: typeof defaultActivityInputs,
  statuses: SuggestedStatusType,
  type: SuggestionActivityType
) => {
  const status = statuses[type];
  const input = inputs[type];
  const isSuggested = status.isSuggested;
  const isIncluded = input.included;
  return isIncluded && isSuggested && getIsActivityInputComplete(inputs, type);
};

export const getToastMessage = ({
  shouldCreateMilestone,
  shouldCreateMobilization,
  shouldCreateTurnover,
}: {
  shouldCreateMilestone: boolean;
  shouldCreateMobilization: boolean;
  shouldCreateTurnover: boolean;
}) => {
  const eventCount = [shouldCreateMobilization, shouldCreateTurnover].filter((v) => v).length;
  const eventNoun = pluralizeString('event', eventCount);
  return capitalizeString(
    `${shouldCreateMilestone ? 'milestone ' : ''}${
      shouldCreateMilestone && eventCount > 0 ? 'and ' : ''
    }${eventCount > 0 ? `${eventNoun} ` : ''}added`
  );
};
