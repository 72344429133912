import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../theme/komodo-mui-theme';

const CollapseIconStyles = (theme: KomodoTheme) =>
  createStyles({
    icon: theme.arrowIcon,
    iconCollapsed: theme.arrowIconCollapsed,
    iconExpanded: theme.arrowIconExpanded,
  });

export default CollapseIconStyles;
