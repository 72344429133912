import { ReactNode } from 'react';

type Props = {
  children?: ReactNode;
  className?: string;
  'data-cy'?: string;
  isCompact?: boolean;
};

export default function Cell(props: Props) {
  return (
    <div
      className={`flex w-full items-center ${props.className ?? ''} ${
        props.isCompact ? 'min-h-[36px]' : 'min-h-[40px]'
      }`}
      data-cy={props['data-cy']}
    >
      {props.children}
    </div>
  );
}
