import { QueryHookOptions, useQuery } from '@apollo/client';

import { LoadProjectRoleQuery, LoadProjectRoleQueryVariables } from '../generated/graphql';

import { loadProjectRoleQuery } from './queries';

function useProjectRoleQuery(
  projectID: UUID,
  roleID: UUID,
  options?: QueryHookOptions<LoadProjectRoleQuery, LoadProjectRoleQueryVariables>
) {
  return useQuery<LoadProjectRoleQuery, LoadProjectRoleQueryVariables>(loadProjectRoleQuery, {
    variables: { projectID, roleID },
    skip: !projectID || !roleID,
    ...options,
  });
}

export default useProjectRoleQuery;
