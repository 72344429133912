import useAssetBlobUrlQuery from '../../assets/hooks/useAssetBlobUrlQuery';

type Props = {
  assetID: UUID | undefined | null;
  onCompleted: () => void;
};

export default function BannerImage(props: Props) {
  const { url } = useAssetBlobUrlQuery(props.assetID, props.onCompleted);
  if (!url) return null;
  return (
    <div className="print-header-organization-picture">
      <img alt="" className="print-header-organization-picture-img" src={url} />
    </div>
  );
}
