import { createStyles } from '@material-ui/core';

import { FieldType } from '../../../api/gqlEnumsBe';
import { KomodoTheme } from '../../../theme/komodo-mui-theme';
import { getPreferredWidthByType } from '../../JoinGrid/utilities/size';

const selectorWidth = getPreferredWidthByType(
  { type: FieldType.REFERENCE, name: FieldType.REFERENCE },
  0,
  0,
  false,
  0
);

export const selectorItemMinHeight = 60;

const InputsSelectReferenceStyles = (theme: KomodoTheme) =>
  createStyles({
    choice: {
      ...theme.typography.h3,
      paddingLeft: '0',
      paddingRight: '20px',
      paddingTop: '8px',
      paddingBottom: '8px',
      letterSpacing: '0.0119em',
      '&$selected': {
        backgroundColor: `white`,
      },
      opacity: 1, // override 'opacity: 0.5' from mui for disabled choices
    },
    filterMenuLabel: {
      display: 'flex',
      height: 44,
      alignItems: 'center',
      margin: 0,
      padding: 0,
    },
    checkBox: {
      display: 'flex',
      width: '100%',
      height: 28,
      marginLeft: 8,
      paddingBottom: 4,
    },
    checkboxLabel: {
      marginLeft: 8,
      position: 'relative',
      top: 2,
    },
    colorChip: {
      width: 12,
      height: 12,
      marginLeft: 4,
    },
    menuSubHeader: {
      lineHeight: '20px',
      paddingTop: '8px',
      paddingBottom: '8px',
      paddingLeft: '12px',
      borderBottom: `1px solid ${theme.palette.divider}`,
      borderTop: `1px solid ${theme.palette.divider}`,
    },
    choiceText: {
      paddingTop: '2px',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      maxWidth: 175,
    },
    subtotalChoiceText: {
      paddingTop: '4px',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
    markupSpacer: {
      width: 24,
      height: 12,
    },
    deleteText: {
      align: 'right',
      border: 'none',
    },
    dividerBottom: {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
    dividerTop: {
      borderTop: `1px solid ${theme.palette.divider}`,
    },
    errorMessage: {
      width: '100%-20px',
      color: theme.palette.red,
      fontSize: 20,
      paddingLeft: 12,
      paddingTop: '8px',
      paddingBottom: '8px',
      '&$selected': {
        backgroundColor: `white`,
      },
    },
    selectedChoiceText: {
      fontWeight: 400,
    },
    hideIcon: {
      display: 'none',
    },
    root: {
      margin: '-2px 0',
      color: theme.palette.joinPrimary,
      border: 'none',
      fontWeight: 300,
      overflow: 'hidden',
    },
    renderFilterIcon: {
      verticalAlign: 'middle',
      display: 'inline-block',
      paddingRight: 5,
      paddingTop: 5,
      marginLeft: -5,
    },
    renderText: {
      verticalAlign: 'middle',
      display: 'inline-block',
    },
    select: {
      '&$choice': {
        paddingLeft: '0px',
        paddingRight: '0px',
        backgroundColor: theme.palette.backgroundWhite,
      },
    },
    selected: {
      backgroundColor: theme.palette.backgroundWhite,
    },
    referenceContainer: {
      maxWidth: 250,
      display: 'flex',
      alignItems: 'center',
    },
    subHeaderText: {
      paddingTop: '4px',
      display: 'inline-block',
      overflowWrap: 'anywhere',
      whiteSpace: 'normal',
      blockSize: 'fit-content',
    },
    subMenuText: {
      ...theme.typography.h4new,
      paddingTop: '8px',
      display: 'inline-block',
      overflowWrap: 'anywhere',
      whiteSpace: 'normal',
      blockSize: 'fit-content',
    },
    iconButton: {
      height: 32,
      width: 32,
      '&:hover': {
        backgroundColor: 'transparent',
      },
      '& > span > svg': {
        width: 14,
      },
    },
    svgIconButton: {
      height: 16,
      marginLeft: 2,
      marginRight: 10,
      width: 24,
      '&:hover': {
        backgroundColor: 'transparent',
      },
      '& > span > svg': {
        width: 14,
      },
    },
    subText: {
      ...theme.typography.h4,
      whiteSpace: 'break-spaces',
    },
    menuMainText: {
      ...theme.typography.subMenuHeader,
      display: 'inline',
      textOverflow: 'ellipsis',
    },
    subMenuMain: {
      ...theme.typography.subMenuHeader,
      paddingTop: '4px',
      display: 'inline-block',
      overflowWrap: 'anywhere',
      whiteSpace: 'normal',
      blockSize: 'fit-content',
    },
    innerMenuTitleContainer: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      paddingLeft: 8,
    },
    menuMainInner: {
      ...theme.typography.subMenuHeader,
      border: 'none',
      boxShadow: 'none',
      width: '100%',
      padding: 0,
    },
    menuMainOuter: {
      ...theme.typography.subMenuHeader,
      border: 'none',
      boxShadow: 'none',
      padding: 0,
      backgroundColor: theme.palette.backgroundWhite,
    },
    menuMainOuterRoot: {
      width: selectorWidth,
      minHeight: selectorItemMinHeight - 8 * 2,
      height: 'auto',
    },
    hidden: {
      display: 'none',
    },
    menuCaption: {
      padding: 12,
    },
  });

export default InputsSelectReferenceStyles;
