import { FC, useCallback, useEffect, useState } from 'react';

import { CustomizationTab, ToastType } from '../../../api/gqlEnums';
import joinAPI from '../../../api/joinAPI';
import { setToast } from '../../../hooks/useToastParametersLocalQuery';
import { colorOptionsFromPalette } from '../../../utilities/colors';
import AssetsUploadArea from '../../assets/AssetsProjectCompImage/AssetsUploadArea/AssetsUploadArea';
import { useUploadImage } from '../../assets/hooks/AssetsMutationHook';
import useThumbnailQuery from '../../assets/hooks/useThumbnailQuery';
import { thumbnailSize } from '../../ProjectComps/ProjectComp/ProjectComp';
import { Button, Dialog } from '../../scales';

import ColorButton from './ColorButton';

const customizationDialogTabOptions = [CustomizationTab.IMAGE, CustomizationTab.COLOR].map(
  (tab) => ({ id: tab, children: tab })
);

type Props = {
  color: string | undefined;
  onClose: () => void;
  setColorAndThumbnailID: (color?: string, thumbnailID?: UUID | null) => void;
  thumbnailAssetID: UUID | undefined;
  title?: string;
};

const CustomizationDialog: FC<Props> = ({
  color,
  onClose,
  setColorAndThumbnailID,
  title = 'Customize',
  thumbnailAssetID,
}) => {
  // Local state
  const [tabValue, setTabValue] = useState<string>(CustomizationTab.IMAGE);
  const [uploading, setUploading] = useState(false);
  const [localThumbnailAssetID, setLocalThumbnailAssetID] = useState<UUID | undefined>(
    thumbnailAssetID
  );
  const [localColor, setLocalColor] = useState<string | undefined>(color);

  // Fetch Asset
  const { data, loading } = useThumbnailQuery(localThumbnailAssetID, thumbnailSize);
  const { asset } = data || {};

  // once asset is loaded, we need it to show up in customization dialog
  useEffect(() => {
    if (!loading && asset) {
      setUploading(false);
    }
  }, [asset, loading]);

  const removeAsset = useCallback(() => {
    setLocalThumbnailAssetID(undefined);
    setColorAndThumbnailID(localColor, null);
  }, [localColor, setColorAndThumbnailID]);

  const uploadImage = useUploadImage();
  const addFile = useCallback(
    (file: File) => {
      setUploading(true);
      const onSuccess = async (a: Asset) => {
        const assetWithBlobUrl = a;
        await joinAPI.requestAssetBlobURL(a.location, a.name);
        setColorAndThumbnailID(localColor, assetWithBlobUrl.id);
        setLocalThumbnailAssetID(assetWithBlobUrl.id);
      };
      const onFailure = (message: string) => {
        setUploading(false);
        setToast({ message }, ToastType.DEFAULT, null);
      };
      uploadImage(file, null, onSuccess, onFailure);
    },
    [localColor, setColorAndThumbnailID, uploadImage]
  );

  const setColor = (color: string) => {
    setLocalColor(color);
    setColorAndThumbnailID(color, localThumbnailAssetID);
  };

  // Components
  const generateDialogContent = (view: string) =>
    view === CustomizationTab.COLOR ? (
      <div className="grid grid-cols-[repeat(auto-fit,144px)] justify-between gap-4 p-6">
        <ColorButton color="" isChosen={!localColor} setColor={setColor} text="None" />
        {colorOptionsFromPalette.map((color) => (
          <ColorButton
            key={color}
            color={color}
            isChosen={color === localColor}
            setColor={setColor}
            text=""
          />
        ))}
      </div>
    ) : (
      <div className="px-6 pb-6">
        <AssetsUploadArea
          addFile={addFile}
          asset={asset as Asset | null}
          loading={uploading}
          removeAsset={removeAsset}
        />
      </div>
    );

  const actionButton = (
    <Button data-cy="done-button" label="Done" onClick={onClose} type="primary" />
  );

  const nav = {
    options: customizationDialogTabOptions,
    value: tabValue,
    onChange: setTabValue,
  };

  return (
    <Dialog footerRight={actionButton} isOpen onClose={onClose} size="lg" tabs={nav} title={title}>
      {generateDialogContent(tabValue)}
    </Dialog>
  );
};

export default CustomizationDialog;
