import { UserStatus } from '../../../api/gqlEnumsBe';
import { BULK_EDIT, PREVIEW_ROLE, UNASSIGNED } from '../../../constants';
import { ProjectRoleType } from '../../../generated/graphql';

export const getCollaboratorEmail = (collaborator: Collaborator | { user: null } | null) => {
  if (!collaborator || (collaborator && !collaborator.user)) return '';
  return (collaborator && collaborator.user && collaborator.user.email) || UNASSIGNED;
};

const nullUser: User = {
  email: '',
  picture: '',
  jobTitle: '',
  pictureAsset: undefined,
} as User;

export const unassignedCollaborator: Collaborator = {
  id: '',
  allTrades: false,
  isProjectLead: false,
  user: nullUser,
  role: { id: '', name: '', type: ProjectRoleType.UNSET, hasTrade: false, permissionGroups: [] },
  trades: [],
};

export const getAssignableCollaborators = (
  collaborators: Collaborator[],
  selectedCollaborator: Collaborator | undefined,
  isReset: boolean,
  variant: string | undefined,
  isDraft?: boolean,
  sharedUsersIDs?: UUID[],
  includeAssigned?: boolean
) => {
  let filtered = collaborators.filter(
    (collaborator) => collaborator.user.status !== UserStatus.DEACTIVATED
  );
  if (isDraft) {
    filtered = filtered.filter((collaborator) => sharedUsersIDs?.includes(collaborator.user.id));
  }
  const shouldIncludeUnassigned =
    variant === BULK_EDIT || (variant !== PREVIEW_ROLE && !!selectedCollaborator);
  if (shouldIncludeUnassigned) filtered.push(unassignedCollaborator);
  if (isReset) return filtered;
  if (selectedCollaborator && !includeAssigned) {
    return filtered.filter(
      (collaborator) =>
        getCollaboratorEmail(collaborator) !== getCollaboratorEmail(selectedCollaborator)
    );
  }
  return filtered;
};
