import { useState } from 'react';

import { InsightsTrigger, InsightsTriggerInput } from '../../../../generated/graphql';
import { TextInput } from '../../../scales';

type Props = {
  'aria-label': string;
  trigger: InsightsTrigger;
  isPercent: boolean;
  onUpdate: (triggerID: UUID, trigger: InsightsTriggerInput) => void;
};

export const TriggerNumberInput = (props: Props) => {
  const initialValue = props.trigger?.threshold?.toString() ?? '';
  const [value, setValue] = useState(initialValue);
  return (
    <TextInput
      aria-label={props['aria-label']}
      endAdornment={props.isPercent ? <div>%</div> : undefined}
      maxLength={4}
      onBlur={(event) => {
        if (Number.isNaN(Number(event.target.value))) {
          setValue(initialValue);
          return;
        }
        const threshold = Math.trunc(Number(event.target.value));
        const truncated = threshold.toFixed(0);
        if (truncated === initialValue) {
          return;
        }
        setValue(truncated);
        props.onUpdate(props.trigger.id, {
          ...props.trigger,
          threshold,
        });
      }}
      onChange={(value) => {
        setValue(value);
      }}
      value={value}
    />
  );
};
