import { ComponentProps } from 'react';

import { DesignPhaseType } from '../../../generated/graphql';
import { Select, SelectEntry } from '../../scales';

export type Props = {
  entries: DesignPhaseType[];
  isDisabled?: ComponentProps<typeof Select>['isDisabled'];
  label?: ComponentProps<typeof Select>['label'];
  onChange: (value: DesignPhaseType) => void;
  value: UUID | undefined;
};

export default function MilestoneDesignPhaseSelect(props: Props) {
  const entries: SelectEntry[] = props.entries.map(({ id, name, abbreviation }) => ({
    id,
    label: abbreviation ? `${name} (${abbreviation})` : name,
  }));

  return (
    <Select
      aria-label="select a design phase"
      data-cy="design-phase-select"
      entries={entries}
      isDisabled={props.isDisabled}
      label={props.label}
      onChange={(designPhaseID) => {
        const designPhase = props.entries.find(({ id }) => id === designPhaseID);
        if (designPhase) props.onChange(designPhase);
      }}
      placeholder="Set design phase"
      value={props.value}
    />
  );
}
