import { FC, useEffect, useRef, useState } from 'react';

import { TextField } from '@material-ui/core';
import { InputProps } from '@material-ui/core/Input';

import { formatCost } from '../../utilities/currency';
import { parseCost } from '../../utilities/string';

type CostFieldProps = {
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  alwaysNegative?: boolean;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  fullWidth?: boolean;
  inputProps?: Partial<InputProps>;
  placeholder?: string;
  value?: number;
  onChange?: (value: number) => void;
  onKeyDown?: (event: Pick<KeyboardEvent, 'key'>) => void;
  onBlur?: (event: FocusEvent) => void;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  editable?: boolean;
};

const getCostFromEvent = (
  costString: string,
  setDisplayString: (value: string) => void,
  onChange: (value: number) => void,
  alwaysNegative: boolean
) => {
  let costImpact = costString === '' ? 0 : Number(parseCost(costString));
  if (costImpact > 0 && alwaysNegative) costImpact *= -1;
  setDisplayString(costString);
  onChange(costImpact);
};

const CostField: FC<CostFieldProps> = ({
  alwaysNegative = false,
  fullWidth = false,
  inputProps,
  placeholder = '$0',
  value = 0,
  onChange = () => {},
  onKeyDown = () => {},
  onBlur = () => {},
  editable = false,
}) => {
  const [focused, setFocused] = useState(false);
  const [displayString, setDisplayString] = useState(value ? formatCost(value) : '');
  const ref = useRef<HTMLInputElement>(null);

  // when value is zero or undefined, we display a blank string
  useEffect(() => {
    // if not editting, add cost decor
    if (!focused) {
      let newCost = parseCost(displayString);
      if (alwaysNegative && newCost > 0) newCost *= -1;
      const newValue = displayString ? formatCost(newCost) : '';
      setDisplayString(newValue);
    }
  }, [focused, displayString, alwaysNegative]);

  return (
    <TextField
      data-cy="input-itemLikeCostImpact"
      disabled={!editable}
      fullWidth={fullWidth}
      InputProps={{ disableUnderline: true, ...inputProps }}
      inputRef={ref}
      onBlur={(event) => {
        // Don't trigger onChange if we've blurred below
        if (focused) {
          const costString = event.target?.value ?? '';
          getCostFromEvent(costString, setDisplayString, onChange, alwaysNegative);
          if (onBlur) {
            event.preventDefault();
            onBlur(event);
          }
        }
        setFocused(false);
      }}
      onChange={(event) => {
        const costString = event.target?.value ?? '';
        getCostFromEvent(costString, setDisplayString, onChange, alwaysNegative);
      }}
      onFocus={() => {
        setFocused(true);
      }}
      onKeyDown={(event) => {
        if (event.key === 'Enter' || event.key === 'Escape') {
          setFocused(false);
          if (ref.current) ref.current.blur();
        }
        if (onKeyDown) {
          onKeyDown(event);
        }
      }}
      placeholder={placeholder}
      value={displayString}
    />
  );
};

/** @deprecated in favor of design system component, please use scales/CostInput */
export default CostField;
