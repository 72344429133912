import { FC } from 'react';

import { removeYear } from '../../utilities/string';
import { SelectCategoryValue } from '../Select/SelectCategory/SelectCategoryUtils';
import SelectCategoryChipInputMulti from '../Select/SelectCategoryChipInput/SelectCategoryChipInputMulti';

import { FILTER_SELECT_MIN_WIDTH } from './FilterPanelStyles';

interface FilterPanelCategorizationItemProps {
  categorization: Categorization;
  categories: Category[];
  addCategories: (newCategories: SelectCategoryValue[]) => void;
  removeCategories: (oldCategories: Category[]) => void;
}

const FilterPanelCategorizationItem: FC<FilterPanelCategorizationItemProps> = ({
  categorization,
  categories,
  addCategories,
  removeCategories,
}) => (
  <div
    key={categorization.id}
    className="flex flex-col gap-0.5"
    id={`FilterPanel-${categorization.id}`}
    style={{ minWidth: FILTER_SELECT_MIN_WIDTH }}
  >
    <div
      className="type-label"
      data-cy={`FilterPanelCategorizationItem-title-${categorization.name}`}
    >
      {removeYear(categorization.name, (categorization as CategorizationFeDefined).builtin)}
    </div>
    <SelectCategoryChipInputMulti
      addCategories={addCategories}
      categorizations={[categorization]}
      id={`FilterPanel-${categorization.id}`}
      noun="category"
      placeholder="Select Categories"
      removeCategories={removeCategories}
      selectedCategories={categories}
    />
  </div>
);

export default FilterPanelCategorizationItem;
