import { FC } from 'react';

import { Typography } from '@material-ui/core';

import { SourceSystem, UserSource } from '../../../generated/graphql';
import { withStyles } from '../../../theme/komodo-mui-theme';
import { getSourceNameText } from '../Files/FilesDialog/FilesDialogUtils';

import styles from './FileExplorerZeroStateStyles';

type FileExplorerZeroStateProps = {
  classes: Classes<typeof styles>;
  userSource: UserSource;
};

const FileExplorerZeroState: FC<FileExplorerZeroStateProps> = ({ classes, userSource }) => {
  const isAutodesk = userSource.sourceSystem === SourceSystem.AUTODESK;
  const sourceName = isAutodesk
    ? `Autodesk Construction Cloud`
    : getSourceNameText(userSource.sourceSystem);

  return (
    <div className={classes.content}>
      <Typography variant="subheading">{`We didn’t find any ${sourceName} folders associated with your account.`}</Typography>
      <Typography>
        {`This usually means the Join integration hasn’t been enabled by your ${getSourceNameText(
          userSource.sourceSystem
        )} admin.`}
      </Typography>
      {isAutodesk && (
        <>
          <img alt="adsk" className={classes.img} src="/img/sources/enable-app-adsk.jpg" />
          <Typography>This can be done in the Autodesk App gallery.</Typography>
          <Typography>Find out more on our help page.</Typography>
        </>
      )}
    </div>
  );
};

export default withStyles(styles)(FileExplorerZeroState);
