import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../theme/komodo-mui-theme';

export default (theme: KomodoTheme) =>
  createStyles({
    card: {
      backgroundColor: 'white',
      border: theme.border.line,
      marginBottom: 24,
      marginTop: 8,
      overflow: 'visible',
    },
    header: {
      backgroundColor: theme.palette.ds.background.primary,
      paddingTop: 8,
      paddingBottom: 8,
    },
    listItem: {
      borderTop: theme.border.line,
      height: 62,
      // this is where flex setting belong
      display: 'inline-flex',
      flexDirection: 'row',
      justifyContent: 'space-around',
    },
    nameHeading: { ...theme.typography.header, flex: '0 0 220px', paddingRight: 24 },
    descriptionHeading: { fontSize: '1rem', fontWeight: 500, textAlign: 'left', flex: '1 1 auto' },
  });
