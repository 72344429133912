import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';

export default (theme: KomodoTheme) =>
  createStyles({
    disabled: {
      backgroundColor: `${theme.palette.joinGrey400} !important`,
    },
    delete: {
      color: theme.palette.ds.type.delete,
    },
    lineMessage: {
      padding: '8px 0',
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      '@media print': {
        display: 'none',
      },
    },
    key: {
      border: `1px solid ${theme.palette.shadedGrey}`,
      padding: '0 2px',
      margin: 4,
      borderRadius: 2,
    },
    deleteButton: {
      height: 21,
      minHeight: 21,
      padding: '0 9px 0 4px',
      textTransform: 'none',
      fontSize: 12,
      fontWeight: 'unset',
      marginRight: 4,
      whiteSpace: 'nowrap',
    },
    littleButton: {
      display: 'flex',
      backgroundColor: theme.palette.joinPrimary,
      height: 21,
      minHeight: 21,
      color: 'white',
      padding: '0 9px 0 4px',
      textTransform: 'none',
      fontSize: 12,
      fontWeight: 'unset',
      marginRight: 4,
      whiteSpace: 'nowrap',
      '&:hover': {
        backgroundColor: theme.palette.joinPrimary,
      },
    },
    addIcon: {
      height: 16,
      width: 16,
    },
    iconRight: {
      display: 'flex',
      left: 30,
      position: 'relative',
    },
    iconReplace: {
      fontSize: 16,
    },
    iconGrid: {
      height: theme.iconSizes.compact,
      width: theme.iconSizes.compact,
      border: `solid ${theme.palette.joinGrey400} 1px`,
      marginRight: 7,
      paddingRight: 2,
      '&:hover': {
        border: theme.border.lineBorderBlack,
        background: theme.palette.backgroundGrey,
      },
      '&:focus': {
        border: theme.border.focus,
        background: theme.palette.hoverBackground,
      },
    },
  });
