import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../../theme/komodo-mui-theme';
import { SELECTOR_WIDTH } from '../CategorizationMatching/CategorizationMatching';

export default (theme: KomodoTheme) =>
  createStyles({
    container: {
      border: theme.border.line,
    },
    firstHeader: {
      flexGrow: 1,
    },
    flex: {
      display: 'flex',
    },
    header: {
      display: 'flex',
      width: 800,
    },
    headerText: {
      fontWeight: 700,
      width: SELECTOR_WIDTH + 5,
    },
    help: {
      color: theme.palette.joinGrey400,
      marginLeft: 4,
      marginBottom: 4,
      fontSize: 16,
    },
    innerContainer: {
      '&:focus': {
        border: 'none',
        outline: 'none',
      },
      alignContent: 'center',
      background: 'none',
      display: 'grid',
      minHeight: 38,
      padding: 4,
    },
  });
