import { ReactNode, useEffect, useRef } from 'react';
import { fromNow } from '../../../utilities/dates';
import { Thumbnail } from '../../dragon-scales';
import { Table } from '../../scales';
import { InsightsProject as InsightsProjectType } from '../types';

import { ProjectCompanyList } from './ProjectCompanyList';
import ProjectCostTable from '../Tables/ProjectCostTable';
import { InsightsMilestone } from '../../../generated/graphql';
import { isNonNullable } from '../../../utilities/types';
import InsightsProjectAlerts from './InsightsProjectAlerts';

export function HeaderSection(props: { title: string; content: ReactNode }) {
  return (
    <div className="flex w-full flex-col gap-1">
      <strong className="type-body2">{props.title}</strong>
      {props.content}
    </div>
  );
}

export function LeftCell(props: { children: ReactNode }) {
  return <div className="my-1 -ml-2 mr-auto type-body3">{props.children}</div>;
}

export function RightCell(props: { children: ReactNode }) {
  return <div className="my-1 -mr-2 ml-auto text-right type-body3">{props.children}</div>;
}

export function ProjectTable(props: { project: InsightsProjectType }) {
  const orgsText = props.project.orgNodes
    .map((node) => node?.name)
    .filter(isNonNullable)
    .join('-');

  const entries = [
    [
      { component: <LeftCell>Location</LeftCell> },
      {
        component: <RightCell>{props.project.location}</RightCell>,
      },
    ],
    [
      { component: <LeftCell>Project Type</LeftCell> },
      {
        component: <RightCell>{props.project.type}</RightCell>,
      },
    ],
    [
      { component: <LeftCell>Delivery Method</LeftCell> },
      {
        component: <RightCell>{props.project.deliveryType}</RightCell>,
      },
    ],
    [
      { component: <LeftCell>Project Lead</LeftCell> },
      {
        component: <RightCell>{props.project.projectLeadName}</RightCell>,
      },
    ],
    [
      { component: <LeftCell>Organization</LeftCell> },
      {
        component: <RightCell>{orgsText}</RightCell>,
      },
    ],
    [
      { component: <LeftCell>Project Status</LeftCell> },
      {
        component: <RightCell>{props.project.status}</RightCell>,
      },
    ],
  ];

  return (
    <Table columnWidths={['auto', 'minmax(150px, max-content)']} entries={entries} isCompact />
  );
}

export function MilestoneTable(props: {
  project: InsightsProjectType;
  milestone: InsightsMilestone;
}) {
  const entries = [
    [
      { component: <LeftCell>Milestone</LeftCell> },
      {
        component: <RightCell>{props.milestone.name}</RightCell>,
      },
    ],
    [
      { component: <LeftCell>Design Phase</LeftCell> },
      {
        component: <RightCell>{props.milestone.designPhase}</RightCell>,
      },
    ],
    [
      { component: <LeftCell>Size</LeftCell> },
      {
        component: <RightCell>{`${props.project.milestoneGSF} GSF`}</RightCell>,
      },
    ],
  ];

  return (
    <Table columnWidths={['auto', 'minmax(150px, max-content)']} entries={entries} isCompact />
  );
}

export default function InsightsProjectHeader(props: {
  project: InsightsProjectType;
  onResize: (height: number) => void;
}) {
  const ref = useRef<HTMLDivElement>(null);

  const { onResize } = props;
  useEffect(() => {
    const ro = new ResizeObserver((entries) => {
      onResize(entries[0].contentRect.height);
    });
    if (ref.current) ro.observe(ref.current);
    return () => ro.disconnect();
  }, [onResize]);

  const activeMilestone = props.project.milestones.find((m) => m.active);

  const lastUpdated = (
    <div className="text-type-muted type-body3">
      Project last edited {fromNow(props.project.lastUpdated)} by {props.project.lastUpdatedBy}
    </div>
  );

  const projectInfo = (
    <HeaderSection content={<ProjectTable {...props} />} title="Project Information" />
  );

  const projectMilestone = activeMilestone && (
    <HeaderSection
      content={<MilestoneTable {...props} milestone={activeMilestone} />}
      title="Milestone"
    />
  );

  const projectTeam = (
    <HeaderSection content={<ProjectCompanyList projectID={props.project.id} />} title="Team" />
  );

  const projectCosts = (
    <HeaderSection
      content={<ProjectCostTable isInHeader project={props.project} showTooltips />}
      title="Project Costs"
    />
  );

  return (
    <div ref={ref} className="flex w-full justify-between gap-6 px-5">
      <div className="flex flex-col gap-4">
        <Thumbnail size={120} thumbnail={props.project?.thumbnail} />
        <InsightsProjectAlerts {...props} />
      </div>
      <div className="flex w-full flex-col justify-between gap-4">
        {lastUpdated}
        <div className="flex w-full flex-col justify-between gap-6 xl:flex-row">
          {projectInfo}
          <div className="flex w-full flex-col gap-4">
            {projectMilestone}
            {projectTeam}
          </div>
          {projectCosts}
        </div>
      </div>
    </div>
  );
}
