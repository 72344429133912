import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../../theme/komodo-mui-theme';

export default (theme: KomodoTheme) =>
  createStyles({
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingTop: 5,
      minHeight: 24,
    },
    headLeftText: {
      ...theme.typography.caption,
      color: theme.palette.shadedGrey,
      fontWeight: 400,
      paddingLeft: 3,
      lineHeight: 'unset',
    },
    headRightText: {
      ...theme.typography.caption,
      color: theme.palette.shadedGrey,
      fontWeight: 400,
      lineHeight: 'unset',
    },
    headRight: {
      display: 'flex',
      alignItems: 'center',
      width: 242,
    },
    horizontal: {
      display: 'flex',
    },
    info: {
      color: theme.palette.joinGrey400,
      height: 24,
      paddingLeft: 6,
    },
    innerContainer: {
      border: theme.border.line,
      alignContent: 'center',
      background: 'none',
      paddingBottom: 5,
      paddingLeft: 4,
    },
    paddingRight: {
      paddingRight: 6,
    },
    selectComponent: {
      width: 240,
    },
    selectContainer: {
      height: 39,
      overflowY: 'auto',
    },
  });
