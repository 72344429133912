import { useMutation } from '@apollo/client';

import { MILESTONES_CREATE, MILESTONES_CREATE_DRAFT } from '../../../../actions/actionTypes';
import { analyticsEvent } from '../../../../analytics/analyticsEventProperties';
import { REFETCH_TIMELINE_EVENTS_PHASES } from '../../../../api/refetchSets';
import {
  CreateMilestoneMutation,
  CreateMilestoneMutationVariables,
} from '../../../../generated/graphql';
import useSendAnalytics from '../../../../hooks/useSendAnalytics';
import { getMilestonesQuery } from '../../../Milestones/hooks/queries';
import { ACTIVITIES_DEFAULT } from '../../../Timeline/TimelineUtils';

import { createMilestoneMutation } from './queries';

function useCreateMilestone() {
  const [createOrUpdateMilestoneFunc, mutationResult] = useMutation<
    CreateMilestoneMutation,
    CreateMilestoneMutationVariables
  >(createMilestoneMutation);

  const sendAnalytics = useSendAnalytics();

  const submitFunc = (
    projectId: UUID,
    name: string,
    date: string | null,
    designPhaseID: UUID | undefined,
    isDraft: boolean,
    onSuccess: () => void,
    showDrafts = true
  ) =>
    createOrUpdateMilestoneFunc({
      variables: {
        projectID: projectId,
        input: {
          projectID: projectId,
          name,
          date,
          isDraft,
          designPhaseID,
        },
      },
      refetchQueries: REFETCH_TIMELINE_EVENTS_PHASES(projectId, ACTIVITIES_DEFAULT),
      update: (dataProxy, { data }) => {
        if (!data?.createMilestone) return;
        // update apollo cache
        dataProxy.writeQuery({
          query: getMilestonesQuery,
          variables: { projectID: projectId, showDrafts },
          data: { milestones: data.createMilestone },
        });
        sendAnalytics(analyticsEvent(isDraft ? MILESTONES_CREATE_DRAFT : MILESTONES_CREATE));
      },
    }).then(() => {
      if (onSuccess) onSuccess();
    });

  return [submitFunc, mutationResult] as const;
}

export default useCreateMilestone;
