import { FC } from 'react';

import { useCompanyQuery } from '../../login/Signup/SignupPages/CreateCompanyHooks';
import { useCompanyRoutesData } from '../CompanyTabUtils';

import CompanyTabSettings from './CompanyTabSettings';

const CompanyTabSettingsData: FC = () => {
  const { companyID } = useCompanyRoutesData();
  const { data: { company } = { company: undefined } } = useCompanyQuery(companyID);
  if (!companyID) return null;
  if (!company) return null;

  return <CompanyTabSettings key={company.id} company={company} />;
};

export default CompanyTabSettingsData;
