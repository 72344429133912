const CategorizationMappingsDialogInfo = () => {
  return (
    <div className="flex gap-12 bg-background-1 p-6">
      <div className="flex flex-col gap-2">
        <div className="type-heading3">Match categorizations in your estimate</div>
        <div className="type-body1">
          <div>
            Categorizations that exist in the project destination will automatically match. Make
            manual edits in the dropdowns.
          </div>
        </div>
      </div>
      <div className="flex-shrink-0">
        <InfoSVG />
      </div>
    </div>
  );
};

export default CategorizationMappingsDialogInfo;

const InfoSVG = () => (
  <svg
    fill="none"
    height="112"
    viewBox="0 0 392 112"
    width="392"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M167.011 6.15696L12.8666 6.07638C9.59757 6.07638 6.95026 8.73552 6.95026 12.0191V28.7596L6.91016 105.451C6.91016 108.735 9.55745 111.394 12.8265 111.414L166.971 111.495C170.24 111.495 172.888 108.836 172.888 105.552L172.928 28.8603V12.1199C172.948 8.81609 170.28 6.15696 167.011 6.15696Z"
      fill="#D1D6D6"
    />
    <path
      d="M1.85645 98.6422C1.85645 100.959 3.74165 102.873 6.04801 102.873L160.193 102.953C162.499 102.953 164.404 101.06 164.404 98.7429L164.445 22.0511L1.89656 21.9503L1.85645 98.6422Z"
      fill="white"
      stroke="#FAD567"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeWidth="2"
    />
    <path
      d="M82.9397 23.604L82.8994 101.625"
      stroke="#D0D5D7"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeWidth="1.7944"
    />
    <path
      d="M59.4345 23.604L59.374 101.625"
      stroke="#D0D5D7"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeWidth="1.7944"
    />
    <path
      d="M129.989 23.604L129.929 101.625"
      stroke="#D0D5D7"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeWidth="1.7944"
    />
    <path
      d="M153.494 23.604L153.454 101.625"
      stroke="#D0D5D7"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeWidth="1.7944"
    />
    <path
      d="M106.465 23.604L106.425 101.625"
      stroke="#D0D5D7"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeWidth="1.7944"
    />
    <path
      d="M2.45801 74.3482L153.615 74.449"
      stroke="#D0D5D7"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeWidth="1.7485"
    />
    <path
      d="M2.45801 92.0549L153.615 92.1556"
      stroke="#D0D5D7"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeWidth="1.7485"
    />
    <path
      d="M2.45801 56.6406L153.615 56.7212"
      stroke="#D0D5D7"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeWidth="1.7485"
    />
    <path
      d="M2.45801 38.9337L153.615 39.0143"
      stroke="#D0D5D7"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeWidth="1.7184"
    />
    <path
      d="M1.85645 98.6422C1.85645 100.959 3.74165 102.873 6.04801 102.873L160.193 102.953C162.499 102.953 164.404 101.06 164.404 98.7429L164.445 22.0511L1.89656 21.9503L1.85645 98.6422Z"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeWidth="2"
    />
    <path
      d="M16.598 1.00007H6.10907C3.80271 1.00007 1.89746 2.89369 1.89746 5.21036V21.9508L164.445 22.0515V5.31109C164.445 2.99442 162.56 1.08065 160.254 1.08065L32.2411 1.00007H16.598Z"
      fill="#E7EAEF"
      stroke="#E7EAEF"
      strokeMiterlimit="10"
      strokeWidth="2"
    />
    <path
      d="M124.254 11.7765L152.712 11.7966"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeWidth="1.7182"
    />
    <path
      d="M11.9238 11.7163H15.614"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeWidth="1.7182"
    />
    <path
      d="M25.9424 11.737H29.6326"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeWidth="1.7182"
    />
    <path
      d="M39.9404 11.737H43.6306"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeWidth="1.7182"
    />
    <path
      d="M386.084 6.15696L231.939 6.07638C228.67 6.07638 226.023 8.73552 226.023 12.0191V28.7596L225.982 105.451C225.982 108.735 228.63 111.394 231.899 111.414L386.044 111.495C389.313 111.495 391.96 108.836 391.96 105.552L392 28.8603V12.1199C392.02 8.81609 389.353 6.15696 386.084 6.15696Z"
      fill="#D1D6D6"
    />
    <path
      d="M220.929 98.6422C220.929 100.959 222.814 102.873 225.12 102.873L379.265 102.953C381.571 102.953 383.477 101.06 383.477 98.7429L383.517 22.0511L220.969 21.9503L220.929 98.6422Z"
      fill="white"
      stroke="#FAD567"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeWidth="2"
    />
    <path
      d="M302.012 23.604L301.972 101.625"
      stroke="#D0D5D7"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeWidth="1.7944"
    />
    <path
      d="M278.507 23.604L278.446 101.625"
      stroke="#D0D5D7"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeWidth="1.7944"
    />
    <path
      d="M349.061 23.604L349.001 101.625"
      stroke="#D0D5D7"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeWidth="1.7944"
    />
    <path
      d="M372.567 23.604L372.526 101.625"
      stroke="#D0D5D7"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeWidth="1.7944"
    />
    <path
      d="M325.537 23.604L325.497 101.625"
      stroke="#D0D5D7"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeWidth="1.7944"
    />
    <path
      d="M221.53 74.3482L372.687 74.449"
      stroke="#D0D5D7"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeWidth="1.7485"
    />
    <path
      d="M221.53 92.0546L372.687 92.1554"
      stroke="#D0D5D7"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeWidth="1.7485"
    />
    <path
      d="M221.53 56.6406L372.687 56.7212"
      stroke="#D0D5D7"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeWidth="1.7485"
    />
    <path
      d="M221.53 38.9337L372.687 39.0143"
      stroke="#D0D5D7"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeWidth="1.7184"
    />
    <path
      d="M220.929 98.6422C220.929 100.959 222.814 102.873 225.12 102.873L379.265 102.953C381.571 102.953 383.477 101.06 383.477 98.7429L383.517 22.0511L220.969 21.9503L220.929 98.6422Z"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeWidth="2"
    />
    <path
      d="M235.67 1.00007H225.181C222.875 1.00007 220.97 2.89369 220.97 5.21036V21.9508L383.518 22.0515V5.31109C383.518 2.99442 381.633 1.08065 379.326 1.08065L251.313 1.00007H235.67Z"
      fill="#E7EAEF"
    />
    <path
      d="M343.326 11.7765L371.785 11.7966"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeWidth="1.7182"
    />
    <path
      d="M230.996 11.7163H234.686"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeWidth="1.7182"
    />
    <path
      d="M245.015 11.737H248.705"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeWidth="1.7182"
    />
    <path
      d="M259.013 11.737H262.703"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeWidth="1.7182"
    />
    <rect height="15" stroke="#5CD746" strokeWidth="2" width="150" x="3" y="24" />
    <path
      d="M138.333 31C138.333 36.891 143.109 41.6667 149 41.6667C154.891 41.6667 159.667 36.891 159.667 31C159.667 25.109 154.891 20.3333 149 20.3333C143.109 20.3333 138.333 25.109 138.333 31ZM236.414 32.4142C237.195 31.6332 237.195 30.3668 236.414 29.5858L223.686 16.8579C222.905 16.0768 221.639 16.0768 220.858 16.8579C220.077 17.6389 220.077 18.9052 220.858 19.6863L232.172 31L220.858 42.3137C220.077 43.0948 220.077 44.3611 220.858 45.1421C221.639 45.9232 222.905 45.9232 223.686 45.1421L236.414 32.4142ZM149 33L235 33L235 29L149 29L149 33Z"
      fill="#4B71A9"
    />
  </svg>
);
