/*
TYPES
- SearchTextMatch

HELPER FUNCTIONS
- getMatchHtml
- getMatchIndexes 
- highlightText

*/

export type SearchTextMatch = number[];

// finds the indexes of search term matches in a string
// * Note: this function combines overlaping matches
export const getMatchIndexes = (searchTerm: string, text: string): SearchTextMatch[] => {
  const matches = [];
  const textLength = text?.length;
  const searchLength = searchTerm.length;
  let lastMatchIndex = -1;
  // find the matches
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < textLength; i++) {
    // if there is a match
    if (text.substring(i, i + searchLength).toLowerCase() === searchTerm.toLowerCase()) {
      // if there is an overlap
      if (matches[lastMatchIndex] && matches[lastMatchIndex][1] > i) {
        matches[lastMatchIndex][1] = i + searchLength;
      } else {
        matches.push([i, i + searchLength]);
        lastMatchIndex = i;
      }
    }
  }
  return matches;
};
