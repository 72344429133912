import { FC } from 'react';

import { Button, Typography } from '@material-ui/core';

import { Selected } from '../../../../api/apollo/reactiveVars';
import { withStyles } from '../../../../theme/komodo-mui-theme';
import { pluralizeCountString } from '../../../../utilities/string';

import styles from './FilesAttachButtonStyles';
import { getCanAttachFromSelected } from './FilesAttachUtils';

type FilesAttachButtonsProps = {
  classes: Classes<typeof styles>;
  selectedMap: Selected;
  onClick: () => void;
};

const FilesAttachButton: FC<FilesAttachButtonsProps> = ({ classes, selectedMap, onClick }) => {
  const { canAttach, count } = getCanAttachFromSelected(selectedMap);
  return (
    <div className={classes.div}>
      {canAttach && (
        <Typography data-cy="attach-files-text">{`${pluralizeCountString(
          'file',
          count
        )} selected`}</Typography>
      )}
      <Button
        className={classes.button}
        color="primary"
        data-cy="attach-files-button"
        disabled={!canAttach}
        onClick={onClick}
        variant="contained"
      >
        Add Files
      </Button>
    </div>
  );
};

export default withStyles(styles)(FilesAttachButton);
