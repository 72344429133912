import { FC } from 'react';

import { isSecondPopupVar } from '../../api/apollo/reactiveVars';

import JoinSelect, { SelectEntry, getEntry } from './JoinSelect/JoinSelect';

export const TRUE = 'true';
export const FALSE = 'false';

type OnOffSelectProps = {
  cySelect?: string;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  disabled?: boolean;
  entries?: SelectEntry[] | undefined;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  hidePrint?: boolean;
  onChange: (v: boolean) => void;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  value?: boolean;
};

const OnOffSelect: FC<OnOffSelectProps> = ({
  cySelect = undefined,
  disabled = false,
  entries = [getEntry(FALSE, 'None'), getEntry(TRUE, 'All')],
  hidePrint = false,
  onChange,
  value = false,
}) => {
  const v = value ? TRUE : FALSE;

  return (
    <JoinSelect
      cySelect={cySelect}
      disabled={disabled}
      entries={entries}
      hidePrint={hidePrint}
      onChange={(type: string | null) => onChange(type === TRUE)}
      onClose={() => isSecondPopupVar(false)}
      onOpen={() => isSecondPopupVar(true)}
      value={v}
    />
  );
};

export default OnOffSelect;
