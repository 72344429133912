import { debounce } from 'lodash';
import { useCallback, useMemo, useState } from 'react';

/**
 * A wrapper around useState that returns the instantaneously-updated
 * state and a secondary representation of the state who's updates are
 * debounced.
 *
 * This is useful when you want to update a single piece of state where
 * one user of the state needs to show updates immediately (eg, a text input)
 * and another user of the state should have the updates debounced (eg,
 * a network call based on the text input's value).
 *
 * @returns [value, debouncedValue, setValue]
 */
export default function useDebouncedState<T>(
  initialValue: T,
  opts: {
    duration: number;
    leading: boolean;
    trailing: boolean;
  } = {
    duration: 500,
    leading: false,
    trailing: true,
  }
) {
  const [value, setValue] = useState(initialValue);
  const [debouncedValue, _setDebouncedValue] = useState(initialValue);
  const setDebouncedValue = useMemo(
    () =>
      debounce(_setDebouncedValue, opts.duration, {
        leading: opts.leading,
        trailing: opts.trailing,
      }),
    [opts.duration, opts.leading, opts.trailing]
  );

  const onSetValue = useCallback(
    (update: Parameters<typeof setValue>[0]) => {
      setValue(update);
      setDebouncedValue(update);
    },
    [setDebouncedValue]
  );

  return [value, debouncedValue, onSetValue] as const;
}
