import * as React from 'react';

import { withStyles } from '../../theme/komodo-mui-theme';

import styles from './TextareaStyles';

type Props = {
  classes: Classes<typeof styles>;
  onChange: (value: string) => void;
  onKeyPress?: React.KeyboardEventHandler<HTMLTextAreaElement>;
  placeholder?: string;
  value?: string;
};

const Textarea = ({ classes, onChange, onKeyPress, placeholder, value }: Props) => {
  return (
    <textarea
      className={classes.textarea}
      onChange={(e) => onChange(e.target.value)}
      onKeyPress={onKeyPress}
      placeholder={placeholder}
      rows={5}
      value={value}
    />
  );
};

export default withStyles(styles)(Textarea);
