import { useParams } from 'react-router-dom';

import { useCompanyTabID } from '../../CompanyTab/CompanyTabUtils';
import { useInsightsProjectQuery } from '../hooks/useInsightsProjectQuery';

import InsightsProjectDetails from './InsightsProjectDetails';

export default function InsightsProjectRoute() {
  const companyID = useCompanyTabID();
  const { projectId } = useParams();

  if (!companyID) {
    throw new Error('Failed to get companyID param');
  }

  if (!projectId) {
    throw new Error('Failed to get projectId param');
  }

  const { data, loading } = useInsightsProjectQuery(projectId, companyID);

  if (loading) return null;

  return <InsightsProjectDetails project={data} />;
}
