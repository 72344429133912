import { ComponentProps } from 'react';

import { ProjectStatus } from '../../../generated/graphql';
import { Select, SelectEntry } from '../../scales';

export type Props = {
  entries: ProjectStatus[];
  isDisabled?: ComponentProps<typeof Select>['isDisabled'];
  label: ComponentProps<typeof Select>['label'];
  onChange: (value: ProjectStatus) => void;
  value: UUID | undefined;
};

export default function ProjectStatusSelect(props: Props) {
  const entries: SelectEntry[] = props.entries.map(({ id, name }) => ({ id, label: name }));

  return (
    <Select
      aria-label="select a project status"
      data-cy="project-status-select"
      entries={entries}
      isDisabled={props.isDisabled}
      label={props.label}
      onChange={(statusID) => {
        const status = props.entries.find(({ id }) => id === statusID);
        if (status) props.onChange(status);
      }}
      placeholder="Set project status"
      value={props.value}
    />
  );
}
