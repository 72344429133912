import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { LinearProgress } from '@material-ui/core';

import {
  reportDistributionEventTypes,
  userReportDistributionEvent,
} from '../../analytics/analyticsEventProperties';
import { REPORT_PDF } from '../../constants';
import useSendAnalytics from '../../hooks/useSendAnalytics';
import { getProjectIdFromUrl } from '../../utilities/url';
import AssetsPDFViewer from '../assets/AssetsViewer/AssetsPDFViewer/AssetsPDFViewer';
import AssetsViewerFooter from '../assets/AssetsViewer/AssetsViewerFooter';
import { useDownloadAsset } from '../assets/hooks/AssetsMutationHook';
import useAssetDisplayUrlQuery from '../assets/hooks/useAssetDisplayUrlQuery';
import FourOhFour from '../FourOhFour';
import { useLoadReportIDByReportDistributionAssetIDQuery } from '../ReportsTab/ReportHooks';

const ReportDistributionView = () => {
  const projectId = getProjectIdFromUrl();
  const { assetId } = useParams();

  // Hooks
  const sendAnalytics = useSendAnalytics();
  const { data, loading, url, urlLoading } = useAssetDisplayUrlQuery(assetId ?? null);
  const { data: reportData, loading: loadingReport } =
    useLoadReportIDByReportDistributionAssetIDQuery(assetId);

  const asset = data?.asset;
  const [downloadAssetFunc] = useDownloadAsset();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const reportType = params.get('utm_report_type');
    sendAnalytics(
      userReportDistributionEvent(reportDistributionEventTypes.REPORT_DISTRIBUTION_VIEW, {
        reportType,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading || urlLoading || loadingReport) {
    return <LinearProgress />;
  }

  if (!asset) {
    return <FourOhFour />;
  }

  const downloadAsset = () => downloadAssetFunc(asset.location, asset.name);
  // TODO: NS-969 add analytics for file download

  return (
    <div>
      <AssetsViewerFooter
        asset={asset}
        canDelete={false}
        displayURL={url}
        downloadAsset={downloadAsset}
        hideFileInfo
        projectId={projectId}
        reportID={reportData?.reportIDByReportDistributionAssetID}
        showMenu={false}
        type={REPORT_PDF}
      />
      <AssetsPDFViewer displayURL={url} />
    </div>
  );
};

export default ReportDistributionView;
