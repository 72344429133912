import { renderCostString } from '../../../../CostReport/CostReportUtils';

type Props = {
  cost: Cost;
};

export default function CostCell(props: Props) {
  return (
    <div className="truncate type-table-number">
      {renderCostString({ cost: props.cost, isSigned: true, isWide: true })}
    </div>
  );
}
