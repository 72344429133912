import { QueryHookOptions, useQuery } from '@apollo/client';
import {
  ProgramCategorizationsQuery,
  ProgramCategorizationsQueryVariables,
} from '../../../generated/graphql';
import { programCategorizationsQuery } from '../queries';

export function useProgramCategorizationsQuery(
  programID: UUID,
  includedProjectIDs: UUID[],
  options?: QueryHookOptions<ProgramCategorizationsQuery, ProgramCategorizationsQueryVariables>
) {
  return useQuery<ProgramCategorizationsQuery, ProgramCategorizationsQueryVariables>(
    programCategorizationsQuery,
    { variables: { includedProjectIDs, programID }, ...options }
  );
}
