import { FC, memo, useCallback, useMemo, useRef, useState } from 'react';

import { useReactiveVar } from '@apollo/client';

import {
  projectCompsAnalyticsEvent,
  projectCompsEventTypes,
} from '../../analytics/analyticsEventProperties';
import { projectCompsSetInputVar } from '../../api/apollo/reactiveVars';
import { GSF, GSF_ID } from '../../constants';
import useSendAnalytics from '../../hooks/useSendAnalytics';
import { PROJECT_COMP_SIDEBAR } from '../../tagConstants';
import { withStyles } from '../../theme/komodo-mui-theme';
import { SCROLL_BUFFER } from '../../utilities/scrolling';

import AverageCompData from './AverageComp/AverageCompData';
import { EscalationTargetLocation } from './constants/projectCompTypes';
import ObserverContext from './context/observer';
import useAutoEscalateMultiple from './hooks/useAutoEscalateMultiple';
import { useAutoEscalationTarget } from './hooks/useAutoEscalationTarget';
import useProjectCompsIntersectionObserver from './hooks/useProjectCompsIntersectionObserver';
import PlaceholderComp from './PlaceholderComp/PlaceholderComp';
import ProjectCompData from './ProjectComp/ProjectCompData';
import ProjectCompSectionGraphs from './ProjectCompSection/ProjectCompSectionGraphs';
import ProjectCompsHeader from './ProjectCompsHeader/ProjectCompsHeader';
import { useAverageCompInputUpdateFunctions } from './ProjectCompsSetInputStore/ProjectCompsSetInputUpdaters';
import CategorizationsDialog from './ProjectCompsSidebar/CategorizationDialog/CategorizationsDialog';
import ProjectCompsSidebar from './ProjectCompsSidebar/ProjectCompsSidebar';
import ProjectCompsStickyFooter from './ProjectCompsStickyFooter';
import ProjectCompsStickyHeader from './ProjectCompsStickyHeader';
import styles from './ProjectCompsStyles';
import { PC_SAVE_ACTION } from './ProjectCompUtils';
import ScrollContainer from './ScrollContainer';

const GSF_UNIT = { id: GSF_ID, name: GSF, abbreviationSingular: GSF } as Unit;

type ProjectCompsSetProps = {
  classes: Classes<typeof styles>;
  exportProjectCompsReport: () => void;
  onAddProjects: () => void;
  onSaveAction: (action: PC_SAVE_ACTION) => void;
  parentProject: ProjectProps | undefined;
  projectComparisonReport: ProjectComparisonReport | undefined;
  projectCompsSet: ProjectCompsSet;
};

const ProjectCompsSet: FC<ProjectCompsSetProps> = ({
  classes,
  exportProjectCompsReport,
  onAddProjects,
  onSaveAction,
  parentProject,
  projectComparisonReport,
  projectCompsSet,
}) => {
  const sendAnalytics = useSendAnalytics();

  const scrollContainer = useRef<HTMLDivElement>(null);
  const {
    projectCompIntersectionObserver,
    showStickyProjectName,
    showStickyCostsHeader,
    showStickyCostsFooter,
    setActivateGridPortal,
    setActivateFooterPortal,
  } = useProjectCompsIntersectionObserver(scrollContainer);

  // Input State
  const projectCompsSetInput = useReactiveVar(projectCompsSetInputVar);
  const { projectCompInputs } = projectCompsSetInput;

  // Dialog state
  const [showCategorizationsDialog, setShowCategorizationsDialog] = useState(false);

  // Output values
  const {
    averageComp,
    categories,
    input: { pinnedUnitID, costTableColumnInputs },
    markups,
    projectComps,
    selectedUnits,
    unitCounts,
    units: projectCompsSetUnits,
  } = projectCompsSet;

  const hasAverageComp = !!averageComp;
  const hasProjects = projectComps && !!projectComps.length && !!projectComps[0];
  const hasMarkups =
    hasProjects &&
    !!projectComps[0].projectCompsCostTable.summaryLines.markupsTotalLine?.pinnedUnitValues;

  const showVisualizationSection = projectComps.length >= 2;
  const selectedUnit = projectCompsSetUnits?.find((u: Unit) => u.id === pinnedUnitID) ?? GSF_UNIT;

  // Set initial autoescalation location: If average comp has a location then use it as starting point else use parent project location
  const averageCompLocation: EscalationTargetLocation = useMemo(
    () => ({
      location: averageComp?.input.location ?? '',
      lat: averageComp?.input.lat ?? undefined,
      lon: averageComp?.input.lon ?? undefined,
    }),
    [averageComp?.input]
  );
  const parentProjectLocation: EscalationTargetLocation = useMemo(
    () => ({
      location: parentProject?.location ?? '',
      lat: parentProject?.lat ?? undefined,
      lon: parentProject?.lon ?? undefined,
    }),
    [parentProject]
  );
  const defaultLocation = averageComp?.input.location ? averageCompLocation : parentProjectLocation;

  const { targetLocation, onTargetLocationChange } = useAutoEscalationTarget(
    projectComps,
    defaultLocation
  );

  const { setAverageCompInputLocation } = useAverageCompInputUpdateFunctions();
  const handleTargetLocationChange = useCallback(
    (newLocation: EscalationTargetLocation) => {
      onTargetLocationChange(newLocation);
      setAverageCompInputLocation(newLocation);
    },
    [onTargetLocationChange, setAverageCompInputLocation]
  );

  const { onAutoEscalateMultipleLocations, onAutoEscalateMultipleTimes } = useAutoEscalateMultiple(
    { ...targetLocation, date: new Date(new Date().toISOString().split('T')[0]).toISOString() },
    projectComps
  );

  // Check if any columns are missing the selected unit.
  let hasColumnMissingUnit = false;
  if (averageComp && averageComp.input) {
    let avgCompHasUnit = false;
    averageComp.input.metrics.forEach((m) => {
      if (m.unitID === selectedUnit.id && m.quantityMagnitude) {
        avgCompHasUnit = true;
      }
    });
    hasColumnMissingUnit = !avgCompHasUnit;
  }
  if (!hasColumnMissingUnit && projectComps) {
    projectComps.forEach((projectComp) => {
      let hasUnit = false;
      projectComp.input.metrics.forEach((m) => {
        if (m.unitID === selectedUnit.id && m.quantityMagnitude && m.quantityMagnitude !== '0') {
          hasUnit = true;
        }
      });
      if (!hasUnit) {
        hasColumnMissingUnit = true;
      }
    });
  }

  // Scroll appearance
  const [overflowLeft, setOverflowLeft] = useState(false);
  const onScrollOverflow = ({ offsetWidth, scrollWidth, scrollLeft }: HTMLDivElement) => {
    const hasOverflow = offsetWidth < scrollWidth;
    const atStart = scrollLeft < SCROLL_BUFFER;
    const overflowNowLeft = hasOverflow && !atStart;
    if (overflowNowLeft !== overflowLeft) setOverflowLeft(hasOverflow && !atStart);
  };

  // TODO - Move to utils and test what the function does
  // Grab the info about where we've autoescalated location and time to. This also functions
  // as a check to see /if/ we've autoescalated any of the projects.
  // Note that because we always autoescalate to a single date or location, we can just run
  // through the array and grab /any/ of the `targetLabel` values since they'll all be the same.
  const autoEscalatedTo = useMemo(
    () =>
      projectCompInputs.reduce(
        (
          prevTarget:
            | undefined
            | { location?: string; time?: string; isLocationTargetFuzzyMatch?: boolean },
          projectCompInput
        ) => {
          const locationLabel = projectCompInput.escalation?.locationMeta?.targetLabel;
          const locationTargetFuzzyMatch =
            projectCompInput.escalation?.locationMeta?.targetIsFuzzyMatch;
          const timeLabel = projectCompInput.escalation?.timeMeta?.targetLabel;

          if (!locationLabel && !timeLabel) return prevTarget;

          return {
            location: locationLabel || prevTarget?.location,
            time: timeLabel || prevTarget?.time,
            isLocationTargetFuzzyMatch:
              locationTargetFuzzyMatch || prevTarget?.isLocationTargetFuzzyMatch,
          };
        },
        undefined
      ),
    [projectCompInputs]
  );

  const onAddProjectsAnalytics = useCallback(
    (location: string) =>
      sendAnalytics(
        projectCompsAnalyticsEvent(projectCompsEventTypes.PROJECT_COMPS_ADD_PROJECT_CTA, {
          location,
        })
      ),
    [sendAnalytics]
  );
  const onAddProjectsFromNullColumn = useCallback(() => {
    onAddProjects();
    onAddProjectsAnalytics('null column');
  }, [onAddProjects, onAddProjectsAnalytics]);

  return (
    <ObserverContext.Provider
      value={{
        projectCompIntersectionObserver: projectCompIntersectionObserver.current,
        setActivateGridPortal,
        setActivateFooterPortal,
        showStickyProjectName,
        showStickyCostsHeader,
        showStickyCostsFooter,
      }}
    >
      <div className={classes.root}>
        <ProjectCompsHeader
          exportProjectCompsReport={exportProjectCompsReport}
          onSaveAction={onSaveAction}
          projectComparisonReport={projectComparisonReport}
          projectCompsSet={projectCompsSet}
        />
        {showVisualizationSection && <ProjectCompSectionGraphs projectCompsSet={projectCompsSet} />}
        <ScrollContainer containerRef={scrollContainer} onScroll={onScrollOverflow}>
          <ProjectCompsStickyHeader />
          <div className={classes.scrollContent}>
            <div className={classes.sticky} style={{ height: 'max-content' }}>
              <div className={classes.sidebar} data-cy={PROJECT_COMP_SIDEBAR}>
                <div className={classes.labels}>
                  <ProjectCompsSidebar
                    autoEscalatedTo={autoEscalatedTo}
                    categories={categories}
                    costTableColumnInputs={costTableColumnInputs}
                    hasAverage={hasAverageComp}
                    hasColumnMissingUnit={hasColumnMissingUnit}
                    hasMarkups={hasMarkups}
                    hasProjects={hasProjects}
                    markups={markups}
                    onEditCategorizations={() => setShowCategorizationsDialog(true)}
                    onTargetLocationChange={handleTargetLocationChange}
                    selectedUnits={selectedUnits}
                    targetLocation={targetLocation}
                    unit={selectedUnit}
                    unitCounts={unitCounts}
                    units={projectCompsSetUnits}
                  />
                  <CategorizationsDialog
                    isOpen={showCategorizationsDialog}
                    onClose={() => setShowCategorizationsDialog(false)}
                    projectComps={projectComps}
                  />
                </div>
                {hasAverageComp && (
                  <AverageCompData
                    averageComp={averageComp}
                    categories={categories}
                    costTableColumnInputs={costTableColumnInputs}
                    hasMarkups={hasMarkups}
                    isLocationAutoEscalationApplied={Boolean(autoEscalatedTo?.location)}
                    location={averageComp?.input.location ? averageCompLocation : targetLocation}
                    markups={markups}
                    parentProject={parentProject}
                    selectedUnits={selectedUnits}
                    // If there's currently a location defined in the Average Comp input, use it.
                    // If there isn't, then we need to init to the current `targetLocation` which is
                    // either the parent project's location, or a location that was configured by the
                    // user for autoescalation purposes.
                    setTargetLocation={handleTargetLocationChange}
                    unit={selectedUnit}
                  />
                )}
              </div>
              {overflowLeft && <div className={classes.overflow} />}
            </div>
            {projectCompInputs.map((projectCompInput, index) => {
              const projectComp = projectComps.find(
                (projectComp) => projectComp.input.id === projectCompInput.id
              );
              if (!projectComp) return null;
              return (
                <ProjectCompData
                  key={projectCompInput.id}
                  averageCompExists={hasAverageComp}
                  categories={categories}
                  costTableColumnInputs={costTableColumnInputs}
                  hasMarkups={hasMarkups}
                  index={index}
                  markups={markups}
                  onAutoEscalateAllLocation={onAutoEscalateMultipleLocations}
                  onAutoEscalateAllTime={onAutoEscalateMultipleTimes}
                  onTargetLocationChange={handleTargetLocationChange}
                  projectComp={projectComp}
                  projectCompInput={projectCompInput}
                  selectedUnits={selectedUnits}
                  targetLocation={targetLocation}
                  unit={selectedUnit}
                />
              );
            })}
            <PlaceholderComp
              index={projectComps.length + 1}
              onAddProjects={onAddProjectsFromNullColumn}
            />
          </div>
          <ProjectCompsStickyFooter />
        </ScrollContainer>
      </div>
    </ObserverContext.Provider>
  );
};

export default memo(withStyles(styles)(ProjectCompsSet));
