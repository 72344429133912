import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';

const styles = (theme: KomodoTheme) =>
  createStyles({
    abbreviation: {
      paddingTop: 24,
    },
    abbreviationRow: {
      paddingTop: 24,
      alignItems: 'center',
      display: 'flex',
      flexWrap: 'wrap',
      width: '100%',
    },
    abbreviationInput: {
      paddingLeft: 8,
      maxWidth: 100,
    },
    action: {
      background: theme.palette.backgroundWhite,
      borderTop: theme.border.line,
      borderBottomLeftRadius: '4px',
      borderBottomRightRadius: '4px',
      margin: 0,
      padding: '16px',
      display: 'flex',
      flex: 1,
      alignItems: 'right',
      justifyContent: 'space-between',
    },
    backButton: { marginRight: 8, transform: 'scaleX(-1)' },
    cardHeaderTypography: theme.typography.cardHeader,
    caption: { minWidth: 60, paddingRight: 4 },
    content: {
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'wrap',
      padding: theme.dialogColumn.padding,
      pageBreakInside: 'avoid',
    },
    dialogContent: {
      background: theme.palette.backgroundWhite,
      padding: 0,
      overflowY: 'scroll',
      maxHeight: 475,
      minHeight: 475,
    },
    paddingBottom: { paddingBottom: '24px !important' },
    paddingTop: {
      paddingTop: 8,
    },
    rowContainer: {
      alignItems: 'center',
      display: 'flex',
      flexWrap: 'wrap',
      width: '100%',
    },
    titleContainer: {
      alignItems: 'center',
      background: theme.palette.backgroundWhite,
      borderTopLeftRadius: '4px',
      borderTopRightRadius: '4px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      padding: '16px 24px',
    },
    titleBorderBottom: {
      borderBottom: theme.border.line,
    },
    margin: {
      marginRight: '4px',
      marginLeft: '4px',
    },
    nameInput: {
      minWidth: 247,
      marginRight: 8,
    },
    typeInput: {
      minWidth: 230,
    },
    descriptionInput: {
      width: '100%',
    },
    whiteButton: {
      border: `1px solid ${theme.palette.joinPrimary}`,
    },
  });

export default styles;
