import { FC, useContext } from 'react';
import * as React from 'react';

import { useReactiveVar } from '@apollo/client';
import { Typography } from '@material-ui/core';

import { currentUserReportVar } from '../../../../api/apollo/reactiveVars';
import { METRICS_TITLE } from '../../../../constants';
import { MILESTONE_REPORT_COLLAPSE } from '../../../../tagConstants';
import { withStyles } from '../../../../theme/komodo-mui-theme';
import { isNonNullable } from '../../../../utilities/types';
import { getMilestoneIdFromUrl, getProjectIdFromUrl } from '../../../../utilities/url';
import { useDetailedMilestonesQuery } from '../../../Milestones/hooks';
import { ProjectTermStore } from '../../../ProjectDisplaySettings/TerminologyProvider';
import { getMilestoneQuantities } from '../../../VarianceReport/VarianceModals/VarianceModalUnits/VarianceModalUnitsUtils';
import {
  ColumnDescription,
  VarianceColumnDescription,
  hasUnitColumns,
} from '../../CostReportColumns/CostReportColumns';
import {
  getCategorizedText,
  getNonCategorizedUnitText,
  keyIsCategorized,
} from '../CostReportList/CostReportListUtils';
import {
  DESCRIPTION_WIDTH,
  NOTES_COL_WIDTH_COLLAPSED,
  NOTES_COL_WIDTH_EXPANDED,
} from '../CostReportListRow/CostReportListRowStyles';
import { getDescriptionWidth, getMetricColumns } from '../CostReportListRow/CostReportListRowUtils';

import CostReportListHeaderColumnGroup from './CostReportListHeaderColumnGroup';
import { styles } from './CostReportListHeaderStyles';
import {
  ListHeader,
  ListSubheader,
  formatCostReportListHeaders,
  getSubHeaderWidth,
} from './CostReportListHeaderUtils';
import ExpandReportNotesHeader from './ExpandReportNotesHeader';
import ReportNoteHeader from './ReportNoteHeader';

type CostReportListHeaderProps = {
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  anyCollapsed: boolean;
  classes: Classes<typeof styles>;
  displayColumnDescriptions: ColumnDescription[] | VarianceColumnDescription[];
  headerDescriptions: ColumnDescription[] | VarianceColumnDescription[];
  isPrint: boolean;
  isVariance?: boolean;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  scroll?: boolean;
  setAllExpanded: (expanded: boolean) => void;
  settings: CostReportSettings;
  isNotesColumnExpanded: boolean;
  setNotesColumnExpanded?: (isExpanded: boolean) => void;
  notesHeaderRef?: React.MutableRefObject<HTMLDivElement | null>;
  canExpandVarianceReportNotesColumn?: boolean;
  hasComments: boolean;
};

const CostReportListHeader: FC<CostReportListHeaderProps> = ({
  anyCollapsed,
  classes,
  displayColumnDescriptions = [],
  headerDescriptions,
  isPrint,
  isVariance = false,
  scroll,
  setAllExpanded,
  settings,
  isNotesColumnExpanded,
  setNotesColumnExpanded = () => null,
  notesHeaderRef,
  canExpandVarianceReportNotesColumn = false,
  hasComments,
}) => {
  const t = useContext(ProjectTermStore);
  const milestoneID = getMilestoneIdFromUrl();
  const currentReport = useReactiveVar(currentUserReportVar);
  const currentReportMilestoneIDMatchsURL = currentReport?.milestoneID === milestoneID;
  const shouldCollapseNotesColumn = !currentReportMilestoneIDMatchsURL && !isVariance;
  if (shouldCollapseNotesColumn) {
    setNotesColumnExpanded(false);
  }

  const scrollClass = scroll ? classes.scroll : classes.scrollInvisible;

  const projectID = getProjectIdFromUrl();
  let varianceColumns: string[] = [];
  let milestoneIDs: UUID[] = [];
  let metrics: string[] = [];
  if ('varianceColumns' in settings) {
    varianceColumns = settings.varianceColumns;
    milestoneIDs = settings.milestoneIDs;
  } else {
    metrics = settings.metrics;
  }

  const { data: { milestones = [] } = {} } = useDetailedMilestonesQuery(projectID, false);
  const { milestone1Quantities, milestone2Quantities } = getMilestoneQuantities(
    milestones,
    milestoneIDs
  );

  const listHeaders = formatCostReportListHeaders(
    t,
    displayColumnDescriptions,
    headerDescriptions,
    isVariance,
    settings,
    [milestone1Quantities, milestone2Quantities].filter(isNonNullable)
  );

  const hasColumnTitles = hasUnitColumns(settings, isVariance);

  const subheaderComponent = (
    subheaderText: string,
    key: string,
    isMetric: boolean,
    title?: string,
    isWide = false
  ) => (
    <div
      key={key}
      className={` ${classes.columnHeaderCell} ${classes.columnSubheaderCell} ${isWide ? classes.range : ''} ${isMetric ? classes.columnMetricCell : ''}`}
      data-cy={`listSubheader-${subheaderText}`}
      style={{ width: getSubHeaderWidth(isVariance, isWide) }}
    >
      <Typography
        className={classes.columnTerm}
        data-cy="header"
        title={title}
        variant="subheading"
      >
        {subheaderText}
      </Typography>
    </div>
  );

  const columnTitleComponent = (
    columnTitleText: string,
    key: string,
    title?: string,
    isWide = false,
    isShaded?: boolean
  ) => (
    <div
      key={key}
      className={`${classes.metricHeaderCell} ${isWide && classes.range} ${
        classes.metricContainer
      } ${isShaded && !isPrint && classes.shaded}`}
      style={{ width: getSubHeaderWidth(isVariance, isWide) }}
    >
      <Typography
        className={classes.metricText}
        data-cy="header"
        title={title}
        variant="subheading"
      >
        {columnTitleText}
      </Typography>
    </div>
  );

  const metricColumns = isVariance ? getMetricColumns(varianceColumns) : metrics;

  const metricHeaders: JSX.Element[] = isVariance
    ? []
    : metricColumns.map((unit, i) =>
        subheaderComponent(getNonCategorizedUnitText(unit), `${unit}${i}sub`, true)
      );

  const metricSubheaders: JSX.Element[] = isVariance
    ? []
    : metricColumns.map((unit, i) => {
        const isCategorized = keyIsCategorized(unit);
        return columnTitleComponent(getCategorizedText(isCategorized), `${unit}${i}header`);
      });

  const columnSubheaders =
    listHeaders &&
    listHeaders.map((header, i) => {
      if (!header) return null;
      const { subheaders = [], title } = header;
      return subheaders.map((subheader: ListSubheader) => {
        const { subheaderText, isWide, isCategorized } = subheader;
        const key = `${subheaderText}${i}${header.text}${isCategorized}sub`;
        return subheaderComponent(subheaderText, key, false, title, isWide);
      });
    });

  if (isPrint) {
    columnSubheaders.unshift(...[metricHeaders]);
  }

  const columnTitles =
    listHeaders &&
    listHeaders.map((header: ListHeader, i: number) => {
      if (!header) return null;
      const { columnTitles, title } = header;
      return columnTitles?.map((columnTitle: ListSubheader, j: number) => {
        const { subheaderText, isWide, isShaded } = columnTitle;
        const key = `${subheaderText}${i}${header.text}${j}title`;
        return columnTitleComponent(subheaderText, key, title, isWide, isShaded);
      });
    });

  if (isPrint) {
    columnTitles.unshift(...[metricSubheaders]);
  }

  const msrDescriptionWidth = isPrint
    ? getDescriptionWidth(metricColumns) - 10 // tune for print spacing
    : getDescriptionWidth(metricColumns);

  const showMetricHeaderColumnGroup = !isVariance && !isPrint && !!metricHeaders.length;

  const commentColumnWidth = isNotesColumnExpanded
    ? NOTES_COL_WIDTH_EXPANDED
    : NOTES_COL_WIDTH_COLLAPSED;

  // Match column keys to column descriptions for header content
  return (
    <div className={`${classes.row} ${!isPrint ? classes.sticky : classes.printNoBackground}`}>
      <div
        className={`${classes.descriptionHeader} ${
          !isPrint ? classes.sticky : classes.printNoBackground
        } ${scrollClass}`}
        style={{
          width: isVariance ? DESCRIPTION_WIDTH : msrDescriptionWidth,
        }}
      >
        <div className={classes.descriptionContainer}>
          <div className={`${classes.descriptionHeader} ${classes.borderBottom}`}>
            <div className={classes.descriptionTextContainer}>
              <Typography
                className={`${classes.bold} ${
                  !isPrint ? classes.descriptionText : classes.descriptionPrint
                }`}
                variant="subheading"
              >
                Description
              </Typography>
              <Typography
                className={classes.expandCollapse}
                data-cy={MILESTONE_REPORT_COLLAPSE}
                onClick={() => setAllExpanded(anyCollapsed)}
                role="button"
                variant="caption"
              >
                {!isPrint && `${anyCollapsed ? 'expand' : 'collapse'} all`}
              </Typography>
            </div>
            {showMetricHeaderColumnGroup && (
              <div>
                <div className={classes.columnHeaderRow}>
                  <CostReportListHeaderColumnGroup
                    header={{ text: METRICS_TITLE, title: METRICS_TITLE }}
                    index={0}
                    isVariance={isVariance}
                    numSubheaders={metricHeaders.length}
                  />
                </div>
                <div className={classes.metricHeaderContainer}>{metricHeaders}</div>
              </div>
            )}
          </div>
          {hasColumnTitles && (
            <>
              {metricHeaders.length && !isPrint ? (
                <div className={classes.columnHeaderRow}>{metricSubheaders} </div>
              ) : (
                <div className={classes.descriptionSpacer} />
              )}
            </>
          )}
        </div>
      </div>
      <div className={classes.scrollContainer}>
        <div className={classes.borderBottom}>
          <div className={classes.columnHeaderRow}>
            {listHeaders &&
              listHeaders.map((header: ListHeader, i: number) => {
                if (!header) return null;
                return (
                  <CostReportListHeaderColumnGroup
                    key={`${header.text}-${header.subtext}`}
                    header={header}
                    index={i}
                    isVariance={isVariance}
                    numSubheaders={header.subheaders?.length ?? 0}
                  />
                );
              })}
            {(!isPrint || hasComments) && (
              <ReportNoteHeader
                canExpandVarianceReportNotesColumn={
                  !shouldCollapseNotesColumn && canExpandVarianceReportNotesColumn
                }
                isNotesColumnExpanded={isNotesColumnExpanded}
                setNotesColumnExpanded={setNotesColumnExpanded}
              />
            )}
          </div>
          <div className={classes.columnHeaderRow}>
            {columnSubheaders}
            <ExpandReportNotesHeader
              hasComments={hasComments}
              isNotesColumnExpanded={isNotesColumnExpanded}
              isPrint={isPrint}
              notesHeaderRef={notesHeaderRef}
              setNotesColumnExpanded={setNotesColumnExpanded}
            />
          </div>
        </div>
        {hasColumnTitles && (
          <div className={classes.columnHeaderRow}>
            {columnTitles}
            {(!isPrint || hasComments) && <div style={{ width: commentColumnWidth }} />}
          </div>
        )}
      </div>
    </div>
  );
};

export default withStyles(styles)(CostReportListHeader);
