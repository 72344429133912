import { FC, useContext, useRef } from 'react';

import { useReactiveVar } from '@apollo/client';

import { makeGridAnalytics } from '../../../../../actions/gridAnalytics';
import { gridSortDataVar } from '../../../../../api/apollo/reactiveVars';
import { refetchMilestoneQuantities } from '../../../../../api/refetchSets';
import { useDebounceFn } from '../../../../../hooks';
import { useEstimateQuery } from '../../../../../hooks/useEstimateQuery';
import {
  getEnabledCategorizationsIDsForProjectFromQueryData,
  useProjectCategorizationsQuery,
} from '../../../../../hooks/useProjectCategorizationsQuery';
import { useRefetch } from '../../../../../hooks/useRefetch';
import useSendAnalytics from '../../../../../hooks/useSendAnalytics';
import { getGridType } from '../../../../../utilities/analytics';
import { getMilestoneIdFromUrl, getProjectIdFromUrl } from '../../../../../utilities/url';
import JoinGridWrapper from '../../../../JoinGrid/JoinGridWrapper';
import { GridVariant } from '../../../../JoinGrid/types';
import { ProjectTermStore } from '../../../../ProjectDisplaySettings/TerminologyProvider';

type MilestoneDetailsQuantitiesGridProps = {
  canEditMetrics: boolean;
  quantityEstimateID: UUID | undefined;
  quantityInfo: QuantityInfo;
};

const MilestoneDetailsQuantitiesGrid: FC<MilestoneDetailsQuantitiesGridProps> = ({
  canEditMetrics,
  quantityEstimateID,
  quantityInfo,
}) => {
  const projectID = getProjectIdFromUrl();
  const milestoneID = getMilestoneIdFromUrl();
  const { refetch } = useEstimateQuery(quantityEstimateID);
  const refetchQuantities = useRefetch(refetchMilestoneQuantities(milestoneID));
  // LOAD DATA FOR REFRESHING
  const { data } = useProjectCategorizationsQuery(projectID, true);
  const enabledCategorizationsIDs = getEnabledCategorizationsIDsForProjectFromQueryData(data);

  // GRID WRAPPER PROPS
  const sendAnalytics = useSendAnalytics();
  const t = useContext(ProjectTermStore);
  const sortData = useReactiveVar(gridSortDataVar);
  const refetchCombined = () => {
    refetch();
    refetchQuantities();
  };

  const refetchDebounced = useDebounceFn(refetchCombined, 1000);
  const collapseSizeRef = useRef(null);
  const analytics = makeGridAnalytics(false, sendAnalytics, GridVariant.QUANTITY);
  const gridType = getGridType(false);

  // PERMISSIONS
  const canEditLines = canEditMetrics;
  const canEditColumns = canEditMetrics;

  return (
    <div>
      {!!quantityEstimateID && (
        <>
          <JoinGridWrapper
            analytics={analytics}
            collapseSizeRef={collapseSizeRef}
            costOfConstruction={0}
            enabledCategorizationsIDs={enabledCategorizationsIDs}
            estimateID={quantityEstimateID}
            gridType={gridType}
            hasOwnerCostEstimate={false}
            permissions={{
              canEditLines,
              canEditColumns,
              canViewEstimate: true, // cannot expand categorized metric if no view access
              canViewMarkupDetails: false,
              canViewMarkups: false,
              summaryMarkups: false,
              canEditMarkups: false,
              canEditOwnerCosts: false,
              canViewOwnerCosts: false,
              canViewEstimateCostSubtotals: false,
            }}
            projectID={projectID}
            quantity={quantityInfo}
            refetchOuter={refetchCombined}
            sortData={sortData}
            t={t}
            updateCostReports={() => {
              refetchDebounced();
            }}
            variant={GridVariant.QUANTITY}
          />
          <div ref={collapseSizeRef} />
        </>
      )}
    </div>
  );
};

export default MilestoneDetailsQuantitiesGrid;
