import {
  GetProjectsUsingOrgQuery,
  GetProjectsUsingOrgQueryVariables,
} from '../../../../generated/graphql';
import { useQuery } from '../../../../hooks/useMountAwareQuery';
import { getProjectsUsingOrgQuery } from '../../../Organizations/hooks/queries';

export const useGetProjectsUsingOrgQuery = (organizationID?: UUID | null) =>
  useQuery<GetProjectsUsingOrgQuery, GetProjectsUsingOrgQueryVariables>(getProjectsUsingOrgQuery, {
    variables: { organizationID },
    skip: !organizationID,
  });
