import { errorText } from '../ReportsListEntry/ReportsListEntrySettings/ReportsListEntrySettings.mock';

import { ReportsListEntryDataType } from './ReportsList';

export const headerLabels = [
  '',
  'Report Name',
  'Type',
  'Shared With',
  'Owner',
  'Last Customized',
  '',
];

export const reports: ReportsListEntryDataType[] = [
  {
    name: 'Dashboard Chart Report',
    description: 'This is a Dashboard Chart Report',
    type: 'Dashboard',
    shared: 'Private to me',
    owner: 'Me',
    last: '2019-02-01 09:00AM',
    settings: {
      onDelete: () => {},
      onEdit: () => {},
      onExcel: () => {},
      onGo: () => {},
      onLink: () => {},
      onPrint: () => {},
    },
  },
  {
    name: 'Breakdown Report',
    description: 'This is a Breakdown Report',
    type: 'Breakdown',
    shared: 'Private to me',
    owner: 'Me',
    last: '2019-02-01 09:00AM',
    settings: {
      onDelete: () => {},
      onEdit: () => {},
      onExcel: () => {},
      onGo: () => {},
      onLink: () => {},
      onPrint: () => {},
    },
  },
  {
    name: 'Items List',
    description: 'This is a Items List Report',
    type: 'Items List',
    shared: 'Private to me',
    owner: 'Me',
    last: '2019-02-01 09:00AM',
    settings: {
      onDelete: () => {},
      onEdit: () => {},
      onExcel: () => {},
      onGo: () => {},
      onLink: () => {},
      onPrint: () => {},
    },
  },
  {
    name: 'Items List and Details',
    description: 'This is a Items List and Details Report',
    type: 'Items List & Details',
    shared: 'Private to me',
    owner: 'Me',
    last: '2019-02-01 09:00AM',
    settings: {
      onDelete: () => {},
      onEdit: () => {},
      onExcel: () => {},
      onGo: () => {},
      onLink: () => {},
      onPrint: () => {},
    },
  },
  {
    name: 'Milestone Summary Report [Active]',
    description: 'This is a Milestone Summary Report [Active] Report',
    type: 'MS Summary',
    shared: 'Private to me',
    owner: 'Me',
    last: '2019-02-01 09:00AM',
    settings: {
      onDelete: () => {},
      onEdit: () => {},
      onExcel: () => {},
      onGo: () => {},
      onLink: () => {},
      onPrint: () => {},
    },
  },
  {
    name: 'Milestone Summary Report 1 [Active]',
    description: 'This is a Milestone Summary Report 1 [Active] Report',
    type: 'MS Summary',
    shared: 'Private to me',
    owner: 'Me',
    last: '2019-02-01 09:00AM',
    settings: {
      onDelete: () => {},
      onEdit: () => {},
      onExcel: () => {},
      onGo: () => {},
      onLink: () => {},
      onPrint: () => {},
    },
  },
  {
    name: 'Milestone Summary Report 2 [Active]',
    description: 'This is a Milestone Summary Report 2 [Active] Report',
    type: 'MS Summary',
    shared: 'Private to me',
    owner: 'Me',
    last: '2019-02-01 09:00AM',
    settings: {
      onDelete: () => {},
      onEdit: () => {},
      onExcel: () => {},
      onGo: () => {},
      onLink: () => {},
      onPrint: () => {},
    },
  },
  {
    name: 'Milestone Summary Report 1 [Active]',
    description: 'This is a Milestone Summary Report 1 [Active] Report',
    type: 'MS Summary',
    shared: 'Private to me',
    owner: 'Me',
    last: '2019-02-01 09:00AM',
    settings: {
      error: errorText,
      onDelete: () => {},
      onEdit: undefined,
      onExcel: () => {},
      onGo: () => {},
      onLink: () => {},
      onPrint: () => {},
    },
  },
];

export const reportsBuiltIn: ReportsListEntryDataType[] = reports.map((r) => {
  const b = { ...r };
  delete b.shared;
  delete b.owner;
  delete b.last;
  return b;
});
reportsBuiltIn.push({
  name: 'Variance Report',
  description: 'Compare costs between two milestones',
  type: 'Variance Report',
  settings: {
    onStart: () => {},
  },
});
