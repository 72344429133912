import { CostTimeseries } from '../../../generated/graphql';
import { formatCost } from '../../../utilities/currency';
import {
  getYDomain,
  getYDomainPadded,
} from '../../dragon-scales/TimelineCharts/InsightsCost/utils';
import useMemoWrapper from '../../useMemoWrapper';

import TimelineLabels from './TimelineLabels';

const costFormat = { short: true, showCurrencySymbol: false };

type Props = {
  className?: string;
  costs: CostTimeseries[];
  index: number;
};

export default function TimelineCostLabels(props: Props) {
  const cost = props.costs[props.index];
  // y domain
  const [yDataMin, yDataMax] = useMemoWrapper(getYDomain, props.costs);
  // Add vertical padding
  const { yDomain } = useMemoWrapper(getYDomainPadded, yDataMin, yDataMax);
  if (!cost) return null;
  if (
    // There is a difference in USCents type in BE and FE scalar definition
    (cost.estimate as unknown as string) === '0' &&
    (cost.runningTotal as unknown as string) === '0' &&
    (cost.budget as unknown as string) === '0'
  )
    return null;

  return (
    <div className={['pointer-events-none absolute h-max', props.className].join(' ')}>
      <TimelineLabels
        color={[
          'var(--colors-button-primary-hover)',
          'var(--colors-button-primary)',
          'var(--colors-entities-milestone)',
        ]}
        content={[
          formatCost(cost.estimate, costFormat),
          formatCost(cost.runningTotal, costFormat),
          formatCost(cost.budget, costFormat),
        ]}
        data={[
          // Data set
          { ...cost, value: cost.estimate },
          { ...cost, value: cost.runningTotal },
          { ...cost, value: cost.budget },
        ]}
        yDomain={yDomain}
      />
    </div>
  );
}
