import { isUUID } from 'validator';

import {
  AssetThumbnailQuery,
  AssetThumbnailQueryVariables,
  ImageDimension,
} from '../../../generated/graphql';
import { useQuery } from '../../../hooks/useMountAwareQuery';

import { assetThumbnailQuery } from './queries';

// the url will refresh every so often, so use the new one the network might return
export default function useThumbnailQuery(
  assetID: UUID | null | undefined,
  dimension?: ImageDimension,
  skip?: boolean
) {
  return useQuery<AssetThumbnailQuery, AssetThumbnailQueryVariables>(assetThumbnailQuery, {
    variables: { assetID, dimension },
    skip: skip || !assetID || !isUUID(assetID),
    context: { batch: true },
  });
}
