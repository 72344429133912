import { FC } from 'react';
import { Link } from 'react-router-dom';

import { JoinProjectRoutes } from '../../api/gqlEnums';
import { generateSharedPath } from '../../utilities/routes/links';

type Props = {
  milestoneID: UUID;
  projectID: UUID;
};

const ProjectBreakdownReportEmptyState: FC<Props> = ({ milestoneID, projectID }) => {
  return (
    <div className="m-10 flex h-40 h-[500px] items-center bg-background-1">
      <div className="flex w-full flex-grow flex-col items-center">
        Milestone doesn&#39;t contain a published estimate,
        <Link
          className="type-link"
          to={generateSharedPath(JoinProjectRoutes.MILESTONE_DETAILS, {
            projectId: projectID,
            milestoneId: milestoneID,
          })}
        >
          add it here
        </Link>
      </div>
    </div>
  );
};

export default ProjectBreakdownReportEmptyState;
