import { createStyles } from '@material-ui/core/styles';

import { themeProps as theme } from '../../../theme/komodo-mui-theme';

const ItemsOptionChipSquareListAreaStyles = () =>
  createStyles({
    clickable: {
      cursor: 'pointer',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    outerContainer: {
      alignItems: 'center',
      display: 'flex',
      padding: '0',
      paddingRight: theme.spacing.generic[3],
    },
    optionsSvgContainer: {
      display: 'flex',
      paddingRight: theme.spacing.generic[0],
      '&>svg': { height: theme.iconSizes.small, width: theme.iconSizes.small },
    },
    text: {
      color: theme.palette.joinPrimary,
      lineHeight: `${theme.spacing.generic[7]}px`,
      marginRight: theme.spacing.generic[2],
    },
  });

export default ItemsOptionChipSquareListAreaStyles;
