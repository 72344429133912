import queryString from 'query-string';
import { useState } from 'react';

import { useReactiveVar } from '@apollo/client';

import {
  CompanyAdminEventType,
  CompanyAdminView,
  companyAdminAnalyticsEvent,
} from '../../analytics/analyticsEventProperties';
import { companyTabIDVar } from '../../api/apollo/reactiveVars';
import { JoinCompanyRoutes } from '../../api/gqlEnums';
import { UserStatus } from '../../api/gqlEnumsBe';
import {
  COMPANY_COLLABORATORS,
  COMPANY_COLLABORATORS_COMPANY,
  COMPANY_COLLABORATORS_COMPANY_TYPE,
  COMPANY_COLLABORATORS_EMAIL,
  COMPANY_COLLABORATORS_JOB_TITLE,
  COMPANY_COLLABORATORS_NAME,
  COMPANY_COLLABORATORS_PATH,
  COMPANY_COLLABORATORS_STATUS,
  COMPANY_INSIGHTS_ALERTS,
  COMPANY_INSIGHTS_ALERTS_PATH,
  COMPANY_MEMBERS,
  COMPANY_MEMBERS_EMAIL,
  COMPANY_MEMBERS_JOB_TITLE,
  COMPANY_MEMBERS_LATEST_ACTIVITY,
  COMPANY_MEMBERS_NAME,
  COMPANY_MEMBERS_PATH,
  COMPANY_MEMBERS_ROLE,
  COMPANY_MEMBERS_STATUS,
  COMPANY_ORGANIZATIONS,
  COMPANY_ORGANIZATIONS_PATH,
  COMPANY_PROJECT_STATS,
  COMPANY_PROJECT_STATS_BUDGET,
  COMPANY_PROJECT_STATS_CREATED_BY,
  COMPANY_PROJECT_STATS_CREATED_ON,
  COMPANY_PROJECT_STATS_ESTIMATE,
  COMPANY_PROJECT_STATS_ITEMS,
  COMPANY_PROJECT_STATS_LOCATION,
  COMPANY_PROJECT_STATS_MILESTONES,
  COMPANY_PROJECT_STATS_PATH,
  COMPANY_PROJECT_STATS_PROJECT_NAME,
  COMPANY_PROJECT_STATS_RUNNING_TOTAL,
  COMPANY_PROJECT_STATS_STATUS,
  COMPANY_PROJECT_STATS_TEAM,
  COMPANY_PROJECT_STATS_TYPE,
  COMPANY_SETTINGS,
  COMPANY_SETTINGS_PATH,
  COMPANY_STANDARDS,
  COMPANY_STANDARDS_PATH,
  LOCALSTORAGE_COMPANY_COLLABORATORS_SORT,
  LOCALSTORAGE_COMPANY_COLLABORATOR_PROJECTS_SORT,
  LOCALSTORAGE_COMPANY_MEMBER_PROJECTS_SORT,
  LOCALSTORAGE_COMPANY_PROJECT_STATS_SORT,
  LOCALSTORAGE_COMPANY_SEARCH_USER,
  LOCALSTORAGE_COMPANY_USERS_SORT,
} from '../../constants';
import { YC_INSIGHTS_V2 } from '../../features';
import {
  CompanyUserSortKeys,
  Org,
  ProjectsSortKey,
  SortDirection,
  UserProjectSortKey,
} from '../../generated/graphql';
import { useCompanyUserQuery } from '../../hooks/useCompanyUserQuery';
import useSendAnalytics from '../../hooks/useSendAnalytics';
import theme from '../../theme/komodo-mui-theme';
import { fromNow } from '../../utilities/dates';
import { generateSharedPath } from '../../utilities/routes/links';
import { SortBy, SortManager, TableHeader } from '../scales';

import StatusTooltipContent from './StatusTooltipContent';
import { INSIGHTS_V2 } from '../../moduleEntitlementFlagsList';

export enum CompanyHeadingsTabs {
  MEMBERS = 'employees',
  COLLABORATORS = 'partners',
  PROJECT_STATS = 'project-stats',
  SETTINGS = 'settings',
  INSIGHTS_ALERT = 'insights-alerts',
}

export type CompanyHeadingsTabNames = {
  path: string;
  displayLabel: string;
  featureFlag?: string;
  moduleEntitlement?: string;
};

export const companyHeaders: CompanyHeadingsTabNames[] = [
  { path: COMPANY_MEMBERS_PATH, displayLabel: COMPANY_MEMBERS },
  { path: COMPANY_COLLABORATORS_PATH, displayLabel: COMPANY_COLLABORATORS },
  {
    path: COMPANY_PROJECT_STATS_PATH,
    displayLabel: COMPANY_PROJECT_STATS,
  },
  {
    path: COMPANY_ORGANIZATIONS_PATH,
    displayLabel: COMPANY_ORGANIZATIONS,
  },
  {
    path: COMPANY_STANDARDS_PATH,
    displayLabel: COMPANY_STANDARDS,
  },
  {
    path: COMPANY_INSIGHTS_ALERTS_PATH,
    displayLabel: COMPANY_INSIGHTS_ALERTS,
    featureFlag: YC_INSIGHTS_V2,
    moduleEntitlement: INSIGHTS_V2,
  },
  { path: COMPANY_SETTINGS_PATH, displayLabel: COMPANY_SETTINGS },
];

export const gridRowHeight = '45px';

export const companyMembersGridWidth = [
  '0.4fr',
  'minmax(300px,2fr)',
  '2fr',
  '2fr',
  'minmax(180px, 1fr)',
  'minmax(120px, 1fr)',
  'minmax(150px, 1fr)',
];

export const companyTabSidebarWidth = ['minmax(150px,3fr)', '2.1fr'];

export const collaboratorsGridWidth = [
  '0.4fr',
  'minmax(300px,2fr)',
  '2fr',
  '2fr',
  '2fr',
  '2fr',
  'minmax(120px, 1fr)',
];

export const projectStatsGridWidth = (orgCount: number) => [
  'minmax(40px, 1fr)',
  'minmax(275px, 3.7fr)', // Project Name
  'minmax(320px, 2.5fr)', // Project Type
  ...(orgCount > 0 ? [`repeat(${orgCount}, minmax(280px, 2.5fr))`] : []), // Organizations
  ...['minmax(280px, 2.5fr)'], // Project Leads
  'minmax(180px, 2.5fr)', // Location
  'minmax(130px, 1.9fr)',
  'minmax(100px, 1.5fr)', // Status
  '1.5fr',
  'minmax(140px, 1.5fr)', // Running Total
  '1.5fr',
  'minmax(120px, 2.5fr)', // Created by
  'minmax(120px, 1fr)',
  'minmax(80px, 1fr)',
  'minmax(80px, 1fr)',
];

export const getStatusColor = (status: UserStatus) => {
  switch (status) {
    case UserStatus.ACTIVE:
      return theme.palette.chartMediumGreen;
    case UserStatus.DEACTIVATED:
      return theme.palette.disabledGrey;
    default:
      return theme.palette.chartPink;
  }
};

// Employees tab

export const companyRoleTooltip =
  "Click on 'View Company Roles' to see a breakdown of permissions and access by role.";

export const membersHeaderContent: TableHeader[] = [
  { copy: '', headerSortKey: null, key: 'thumbnail' },
  {
    copy: COMPANY_MEMBERS_NAME,
    headerSortKey: CompanyUserSortKeys.SORT_COMPANY_USER_USER_NAME,
    key: 'user name',
  },
  {
    copy: COMPANY_MEMBERS_JOB_TITLE,
    headerSortKey: CompanyUserSortKeys.SORT_COMPANY_USER_JOB_TITLE,
    key: 'job title',
  },
  {
    copy: COMPANY_MEMBERS_EMAIL,
    headerSortKey: CompanyUserSortKeys.SORT_COMPANY_USER_EMAIL,
    key: 'email',
  },
  {
    copy: COMPANY_MEMBERS_ROLE,
    headerSortKey: CompanyUserSortKeys.SORT_COMPANY_USER_COMPANY_ROLE,
    key: 'role',
    tooltip: companyRoleTooltip,
  },
  {
    copy: COMPANY_MEMBERS_STATUS,
    headerSortKey: CompanyUserSortKeys.SORT_COMPANY_USER_STATUS,
    key: 'status',
    tooltip: StatusTooltipContent,
  },
  {
    copy: COMPANY_MEMBERS_LATEST_ACTIVITY,
    headerSortKey: CompanyUserSortKeys.SORT_COMPANY_USER_LATEST_ACTIVITY,
    key: 'latestActivity',
  },
];

export const sidebarHeaderContent: TableHeader[] = [
  {
    copy: COMPANY_MEMBERS_NAME,
    headerSortKey: CompanyUserSortKeys.SORT_COMPANY_USER_USER_NAME,
    key: 'user name',
  },
  {
    copy: COMPANY_MEMBERS_STATUS,
    headerSortKey: CompanyUserSortKeys.SORT_COMPANY_USER_STATUS,
    key: 'status',
    tooltip: StatusTooltipContent,
  },
];

// COLLABORATORS

export const collaboratorsHeaderContent: TableHeader[] = [
  { copy: '', headerSortKey: null, key: 'thumbnail' },
  {
    copy: COMPANY_COLLABORATORS_NAME,
    headerSortKey: CompanyUserSortKeys.SORT_COMPANY_USER_USER_NAME,
    key: 'user name',
  },
  {
    copy: COMPANY_COLLABORATORS_JOB_TITLE,
    headerSortKey: CompanyUserSortKeys.SORT_COMPANY_USER_JOB_TITLE,
    key: 'job title',
  },
  {
    copy: COMPANY_COLLABORATORS_EMAIL,
    headerSortKey: CompanyUserSortKeys.SORT_COMPANY_USER_EMAIL,
    key: 'email',
  },
  {
    copy: COMPANY_COLLABORATORS_COMPANY,
    headerSortKey: CompanyUserSortKeys.SORT_COMPANY_USER_COMPANY_NAME,
    key: 'company name',
  },
  {
    copy: COMPANY_COLLABORATORS_COMPANY_TYPE,
    headerSortKey: CompanyUserSortKeys.SORT_COMPANY_USER_COMPANY_TYPE,
    key: 'company type',
  },
  {
    copy: COMPANY_COLLABORATORS_STATUS,
    headerSortKey: CompanyUserSortKeys.SORT_COMPANY_USER_STATUS,
    key: 'status',
    tooltip: StatusTooltipContent,
  },
];

// PROJECT STATS

export const projectStatsHeaderContent = (orgs: Org[]): TableHeader[] => [
  {
    copy: COMPANY_PROJECT_STATS_PROJECT_NAME,
    headerSortKey: ProjectsSortKey.SORT_PROJECT_NAME,
    key: 'user name',
  },
  {
    copy: COMPANY_PROJECT_STATS_TYPE,
    headerSortKey: ProjectsSortKey.SORT_PROJECT_TYPE,
    key: 'type',
  },
  {
    copy: 'Project Lead',
    headerSortKey: null,
    key: 'lead',
  },
  ...orgs.map((org) => ({
    copy: org.name,
    headerSortKey: null,
    key: org.id,
  })),
  {
    copy: COMPANY_PROJECT_STATS_LOCATION,
    headerSortKey: null,
    key: 'locations',
  },
  {
    copy: COMPANY_PROJECT_STATS_CREATED_ON,
    headerSortKey: ProjectsSortKey.SORT_PROJECT_CREATED_AT,
    key: 'created on',
  },
  {
    copy: COMPANY_PROJECT_STATS_STATUS,
    headerSortKey: ProjectsSortKey.SORT_PROJECT_STATUS,
    key: 'status',
  },
  {
    copy: COMPANY_PROJECT_STATS_ESTIMATE,
    headerSortKey: ProjectsSortKey.SORT_PROJECT_ESTIMATE,
    key: 'estimate',
    isRightAligned: true,
  },
  {
    copy: COMPANY_PROJECT_STATS_RUNNING_TOTAL,
    headerSortKey: ProjectsSortKey.SORT_PROJECT_RUNNING_TOTAL,
    key: 'running total',
    isRightAligned: true,
  },
  {
    copy: COMPANY_PROJECT_STATS_BUDGET,
    headerSortKey: ProjectsSortKey.SORT_PROJECT_BUDGET,
    key: 'budget',
    isRightAligned: true,
  },
  {
    copy: COMPANY_PROJECT_STATS_CREATED_BY,
    headerSortKey: ProjectsSortKey.SORT_PROJECT_CREATED_BY,
    key: 'created by',
  },
  {
    copy: COMPANY_PROJECT_STATS_MILESTONES,
    headerSortKey: ProjectsSortKey.SORT_PROJECT_MILESTONE_COUNT,
    key: 'milestones',
  },
  {
    copy: COMPANY_PROJECT_STATS_ITEMS,
    headerSortKey: ProjectsSortKey.SORT_PROJECT_ITEM_COUNT,
    key: 'items',
  },
  {
    copy: COMPANY_PROJECT_STATS_TEAM,
    headerSortKey: ProjectsSortKey.SORT_PROJECT_TEAM_COUNT,
    key: 'team',
  },
];

// SORT

export const getEmptySort = (storageKey: string): SortBy => {
  switch (storageKey) {
    case LOCALSTORAGE_COMPANY_USERS_SORT:
      return {
        sortDirection: SortDirection.SORT_ASCENDING,
        sortKey: CompanyUserSortKeys.SORT_COMPANY_USER_USER_NAME,
      };
    case LOCALSTORAGE_COMPANY_MEMBER_PROJECTS_SORT:
      return {
        sortDirection: SortDirection.SORT_ASCENDING,
        sortKey: UserProjectSortKey.SORT_USER_PROJECT_NAME,
      };
    case LOCALSTORAGE_COMPANY_COLLABORATORS_SORT:
      return {
        sortDirection: SortDirection.SORT_ASCENDING,
        sortKey: CompanyUserSortKeys.SORT_COMPANY_USER_USER_NAME,
      };
    case LOCALSTORAGE_COMPANY_COLLABORATOR_PROJECTS_SORT:
      return {
        sortDirection: SortDirection.SORT_ASCENDING,
        sortKey: UserProjectSortKey.SORT_USER_PROJECT_NAME,
      };
    case LOCALSTORAGE_COMPANY_PROJECT_STATS_SORT:
      return {
        sortDirection: SortDirection.SORT_ASCENDING,
        sortKey: ProjectsSortKey.SORT_PROJECT_NAME,
      };
    default:
      return {
        sortDirection: SortDirection.SORT_NONE,
        sortKey: '',
      };
  }
};

export const getInitialSort = (storageKey: string): SortBy => {
  const savedSort = localStorage.getItem(storageKey);
  if (savedSort && JSON.parse(savedSort)) {
    return JSON.parse(savedSort);
  }
  const emptySort = getEmptySort(storageKey);
  localStorage.setItem(storageKey, JSON.stringify(emptySort));
  return emptySort;
};

const getCompanyAnalyticsSortEventType = (storageKey: string) => {
  if (storageKey === LOCALSTORAGE_COMPANY_USERS_SORT) {
    return CompanyAdminEventType.MEMBER_SORT;
  }
  if (storageKey === LOCALSTORAGE_COMPANY_COLLABORATORS_SORT) {
    return CompanyAdminEventType.COLLABORATORS_SORT;
  }
  if (storageKey === LOCALSTORAGE_COMPANY_PROJECT_STATS_SORT) {
    return CompanyAdminEventType.PROJECT_STATS_SORT;
  }
  return undefined;
};

export const useSort = (storageKey: string, view?: CompanyAdminView): SortManager => {
  // SORT HOOKS
  const [sortState, setSortState] = useState<SortBy>(getInitialSort(storageKey));
  const sendAnalytics = useSendAnalytics();
  const setSort = (sort: SortBy) => {
    setSortState(sort);
    localStorage.setItem(storageKey, JSON.stringify(sort));
    const sortEventType = getCompanyAnalyticsSortEventType(storageKey);
    if (
      sortEventType &&
      storageKey !== LOCALSTORAGE_COMPANY_COLLABORATOR_PROJECTS_SORT &&
      storageKey !== LOCALSTORAGE_COMPANY_MEMBER_PROJECTS_SORT &&
      view
    ) {
      sendAnalytics(companyAdminAnalyticsEvent(sortEventType, { view, sort: sort.sortKey }));
    }
  };
  return { sortState, setSort };
};

export enum UserStatusToggle {
  ALL = 'All',
  USER_STATUS_ACTIVE = 'Active',
  USER_STATUS_PENDING = 'Pending',
  USER_STATUS_DEACTIVATED = 'Deactivated',
}

export const filterCompanyUsers = (companyUsers: CompanyUser[], filterStatus: string) => {
  if (filterStatus === UserStatusToggle.ALL) return companyUsers;
  return companyUsers.filter((companyUser) => companyUser.user?.status === filterStatus);
};

export const getFilteredUserCount = (companyUsers: CompanyUser[]) => {
  const userCounts = { all: 0, active: 0, deactivated: 0, pending: 0 };
  companyUsers.forEach((companyUser) => {
    switch (companyUser.user?.status) {
      case UserStatus.ACTIVE:
        userCounts.active += 1;
        break;
      case UserStatus.DEACTIVATED:
        userCounts.deactivated += 1;
        break;
      case UserStatus.PENDING:
        userCounts.pending += 1;
        break;
      default:
        break;
    }
  });
  userCounts.all = companyUsers.length ?? 0;
  return userCounts;
};

// SEARCH
export type CompanyUserSearchManager = {
  searchTerm: string;
  setSearchTerm: (searchTerm: string) => void;
  clearSearch: () => void;
};

export const useCompanyUserSearchManager = (
  localStorageString?: string
): CompanyUserSearchManager => {
  // SEARCH HOOKS
  const [searchTerm, setState] = useState(
    (localStorageString && localStorage.getItem(localStorageString)) || ''
  );
  const setSearchTerm = (value: string) => {
    setState(value);
    if (localStorageString) localStorage.setItem(localStorageString, value);
  };
  const clearSearch = () => {
    setState('');
    if (localStorageString) localStorage.removeItem(localStorageString);
  };
  return {
    searchTerm,
    setSearchTerm,
    clearSearch,
  };
};

export const useCompanyFilterManager = () => {
  const [filterStatus, setFilterStatus] = useState<string>(UserStatusToggle.ALL);
  return { filterStatus, setFilterStatus };
};

export const searchCompanyUsers = (companyUsers: CompanyUser[], searchTerm: string) => {
  if (searchTerm === '') return companyUsers;
  const isMatch = (field?: string) =>
    field && field.toLowerCase().includes(searchTerm.toLowerCase());
  return companyUsers.filter((companyUser) => {
    const { user } = companyUser;
    return [user?.name, user?.jobTitle, user?.email].some(isMatch);
  });
};

// SEARCHING - Collaborators

export const searchCompanyCollaborators = (
  companyCollaborators: CompanyCollaborator[],
  searchTerm: string
) => {
  if (searchTerm === '') return companyCollaborators;
  const isMatch = (field?: string) =>
    field && field.toLowerCase().includes(searchTerm.toLowerCase());
  return companyCollaborators.filter((companyCollaborator) => {
    const { user, company } = companyCollaborator.companyUser;
    return [user?.name, user?.jobTitle, user?.email, company?.name, company?.type].some(isMatch);
  });
};

export const filterCompanyCollaborators = (
  companyCollaborators: CompanyCollaborator[],
  filterStatus: string
) => {
  if (filterStatus === UserStatusToggle.ALL) return companyCollaborators;
  return companyCollaborators.filter(
    (companyCollaborator) => companyCollaborator.companyUser.user?.status === filterStatus
  );
};

export const getFilteredCollaboratorCount = (companyCollaborators: CompanyCollaborator[]) => {
  const collabToUser = (collaborator: CompanyCollaborator) => collaborator.companyUser;

  const toUser = companyCollaborators.map(collabToUser);

  return getFilteredUserCount(toUser);
};

export const getCompanyName = (companyCollaborators: CompanyCollaborator[], companyID?: string) => {
  const matchedCompany = companyCollaborators.find(
    (companyCollaborator) => companyCollaborator?.companyUser?.company?.id === companyID
  );
  return matchedCompany?.companyUser?.company?.name || '-';
};

export const getIsDeactivated = (user: Pick<User, 'status'> | null) => {
  return user?.status === UserStatus.DEACTIVATED;
};

export const getIsPending = (user: Pick<User, 'status'> | null) => {
  return user?.status === UserStatus.PENDING;
};

export const getIsActive = (user: Pick<User, 'status'> | null) => {
  return user?.status === UserStatus.ACTIVE;
};

// SEARCHING - Project Stats

export const searchProjectStats = (
  companyProjectStats: CompanyProjectStats[],
  searchTerm: string
) => {
  if (searchTerm === '') return companyProjectStats;
  const isMatch = (field: string | null | undefined) =>
    field && field.toLowerCase().includes(searchTerm.toLowerCase());
  return companyProjectStats.filter((companyProject) => {
    return [
      companyProject?.name,
      companyProject?.type,
      companyProject?.location,
      companyProject?.createdBy?.user?.name,
    ].some(isMatch);
  });
};

export const useCompanyTabID: () => UUID | undefined = () => {
  // Default user company
  const { data: { companyUser } = { companyUser: undefined } } = useCompanyUserQuery();
  // Selected company (could differ from above)
  const companyID = useReactiveVar(companyTabIDVar);
  if (!companyID && companyUser) companyTabIDVar(companyUser?.company?.id);
  return companyID;
};

export const useCompanyRoutesData = () => {
  const searchManager = useCompanyUserSearchManager(LOCALSTORAGE_COMPANY_SEARCH_USER);

  const filterManager = useCompanyFilterManager();

  const companyID = useCompanyTabID();

  return { companyID, filterManager, searchManager };
};

export type CompanyCategorizationsSearchManager = {
  searchTerm: string;
  setSearchTerm: (searchTerm: string) => void;
  clearSearch: () => void;
};

export const formatModifiedAtString = (modifiedAt: string) => `Last updated ${fromNow(modifiedAt)}`;

// TO-DO: Delete the toggle & counts below

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
export const getProjectStatsLink = (newState: any) => {
  const state = { companies: [], locations: [], statuses: [], types: [], ...newState };
  const search = `?${queryString.stringify(state, { arrayFormat: 'index' })}`;
  return generateSharedPath(JoinCompanyRoutes.COMPANY_PROJECT_STATS, { search });
};
