import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';

import {
  ItemSidebarLocation,
  brandAssistAnalytics,
  itemAssistantCTAAnalytics,
  navigateItemsAnalytics,
  printItemDetail,
  updateItemDescriptionAnalytics,
  updateItemNameAnalytics,
  updateItemNumberProps,
} from '../../analytics/analyticsEventProperties';
import { assetViewerOpenVar } from '../../api/apollo/reactiveVars';
import { JoinProjectRoutes } from '../../api/gqlEnums';
import { ACCEPTED, ITEM_DETAILS } from '../../constants';
import { CT_BRAND_ASSIST_EXPERIMENT, CT_ITEM_ASSIST_EXPERIMENT } from '../../features';
import { PermissionResource, ResourceType, Status } from '../../generated/graphql';
import { useHasFeature } from '../../hooks/useHasFeature';
import useHasModuleEntitlement from '../../hooks/useHasModuleEntitlement';
import useLocalStorage from '../../hooks/useLocalStorage';
import useSendAnalytics from '../../hooks/useSendAnalytics';
import { PROJECT_CREATION } from '../../moduleEntitlementFlagsList';
import { RouteKeys } from '../../routes/paths';
import { getCostMode, useCostMode } from '../../utilities/costMode';
import { getDraftItemIDs, getSharedUsersMap } from '../../utilities/items';
import usePermissions from '../../utilities/permissions/usePermissions';
import { generateSharedPath } from '../../utilities/routes/links';
import { useProjectID } from '../../utilities/routes/params';
import { useScrollToHashOnce } from '../../utilities/scrolling';
import { getItemIdFromUrl } from '../../utilities/url';
import { useAttachToItem } from '../assets/hooks/mutations/useAttachToItem';
import CommentsHistory from '../Comments/CommentsHistory/CommentsHistory';
import { DialogsNewItem } from '../Dialogs';
import { CheckBrandTriggers } from '../Dialogs/DialogsNewItem/BrandAssistUtils';
import DialogsBrandAssistContent from '../Dialogs/DialogsNewItem/DialogsBrandAssistContent';
import { ItemStatusSelect } from '../dragon-scales';
import InputsTextAreaStyled from '../Inputs/InputsTextAreaStyled/InputsTextAreaStyled';
import ItemNumberTextInput from '../Inputs/ItemNumberTextInput/ItemNumberTextInput';
import ItemsDetailsTopNav from '../Items/ItemDetailsTopNav/ItemDetailsTopNav';
import ItemPublishDialog from '../Items/ItemPublishModal/ItemPublishDialog';
import AssetsCollapse from '../Items/ItemsCollapse/AssetsCollapse';
import IntegrationsCollapse from '../Items/ItemsCollapse/IntegrationsCollapse';
import ItemEstimateCollapse from '../Items/ItemsCollapse/ItemEstimateCollapse';
import OptionCollapse from '../Items/ItemsCollapse/OptionCollapse';
import ScheduleImpactCollapse from '../Items/ItemsCollapse/ScheduleImpactCollapse';
import useUpdateStatus from '../Items/ItemsHooks';
import { getTotalItemAttachmentCount, isPrivateVisibility } from '../Items/ItemsUtils';
import ItemVisibilityToggleEditWrapper from '../Items/ItemVisibilityToggle/ItemVisibilityToggleEditWrapper';
import { DISABLE_ITEM_NUMBER_TOOLTIP_NEW } from '../Items/ItemVisibilityToggle/ItemVisibilityToggleUtils';
import { useGetSharedResources } from '../ItemsList/ItemsSharing/hooks/useGetShareResourceHook';
import { GridVariant } from '../JoinGrid/types';
import { isPrintKeys } from '../Print/PrintUtils';
import { isScheduleSettingsDisabled } from '../ProjectProperties/ProjectScheduleImpact/ProjectScheduleImpactSettings';
import { Button, Chip, TextInput, Tooltip } from '../scales';
import useMemoWrapper from '../useMemoWrapper';

import ItemAssistantConfirmationDialog from './ItemAssistantConfirmationDialog/ItemAssistantConfirmationDialog';
import ItemDetailExportPanel from './ItemDetailExportPanel';
import {
  useItemDescriptionUpdate,
  useItemNameUpdate,
  useItemNumberUpdate,
} from './ItemDetailsHooks';
import ItemDetailsSettingsPanel from './ItemDetailsSettingsPanel/ItemDetailsSettingsPanel';
import ItemSummary from './ItemSummary';

type ItemDetailsProps = {
  canAddItemComments: boolean;
  canDeprecateItems: boolean;
  canEditItemDetails: boolean;
  canEditItemNumber: boolean;
  canEditItemStatus: boolean;
  canViewItemIntegrations: boolean;
  canDeleteItemIntegration: boolean;
  isExpanded: boolean;
  item: ItemDataQueryItem;
  navigationLinks?: NavigationLinks;
  parentItem: ItemLike;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  refetchItem: (variables?: any) => void;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  loading: boolean;
  setIsExpanded: (isExpanded: boolean) => void;
  width: Breakpoint;
};

const ItemDetails: FC<ItemDetailsProps> = ({
  canAddItemComments = false,
  canDeleteItemIntegration = false,
  canDeprecateItems = false,
  canEditItemDetails = false,
  canEditItemNumber = false,
  canEditItemStatus = false,
  canViewItemIntegrations = false,
  isExpanded,
  item,
  loading,
  navigationLinks,
  parentItem,
  refetchItem: refetchItemOuter,
  setIsExpanded,
  width = 'lg',
}) => {
  const navigate = useNavigate();
  const sendAnalytics = useSendAnalytics();
  const costMode = useCostMode();

  const projectId = useProjectID();
  if (!projectId) throw new Error('Could not find project id');

  const itemID = getItemIdFromUrl();

  const hasItemAssistFeature = useHasFeature(CT_ITEM_ASSIST_EXPERIMENT);
  const hasBrandAssistFeature = useHasFeature(CT_BRAND_ASSIST_EXPERIMENT);

  useScrollToHashOnce(window.location.hash);

  // REFETCH
  const refetchItem = () => refetchItemOuter({ id: itemID, costMode: getCostMode() });

  // DERIVED ITEM CONSTS
  const {
    comments,
    description,
    id,
    milestone,
    name,
    status,
    estimateCost,
    assetCount,
    contingencyDrawCost,
  } = item;
  const options = 'options' in item ? item.options : [];
  const hasOptions = options.length > 0;
  const { parentVisibility } = (item as Option) || {};
  const draftOptionIDs = useMemoWrapper(getDraftItemIDs, options);
  const sharedResourcesResult = useGetSharedResources(draftOptionIDs, ResourceType.ITEM);
  const sharedUsersMap = useMemoWrapper(getSharedUsersMap, options, sharedResourcesResult.data);

  // STATE VARIABLES
  const [openDialogAddOption, setOpenDialogAddOption] = useState(false);
  const [showPublishItemDialog, setShowPublishItemDialog] = useState(false);
  const [itemAssistItemIDs, setItemAssistItemIDs] = useLocalStorage(
    'ITEM_ASSIST_EXP_IDS',
    [] as string[]
  );
  // Used for both Item Assist and Brand Assist experiments.
  const itemAssistAlreadyRequested = itemAssistItemIDs.includes(itemID);
  const [openItemAssistDialog, setOpenItemAssistDialog] = useState(false);
  const [openBrandAssistDialog, setOpenBrandAssistDialog] = useState(false);

  const permissions = usePermissions({ trades: item.categories });
  const { canView, canEdit } = permissions;
  const canViewItemAttachments = canView(PermissionResource.ITEM_ATTACHMENTS);
  const canViewScheduleImpact = canView(PermissionResource.SCHEDULE_IMPACT);
  const canEditScheduleImpact = canEdit(PermissionResource.SCHEDULE_IMPACT);
  // Temporary. Used as a proxy permission for item assist experiment may 2024.
  const canEditEstimateMarkup = canEdit(PermissionResource.MARKUPS);

  const canCreateProject = useHasModuleEntitlement(PROJECT_CREATION);
  // Only show item assist if the FF is ON and the user is GC or admin (proxied by being able to edit markups)
  const showItemAssist =
    !itemAssistAlreadyRequested &&
    hasItemAssistFeature &&
    canCreateProject &&
    canEditEstimateMarkup;

  const triggeredBrand = CheckBrandTriggers(name, description);
  const showBrandAssist = triggeredBrand !== undefined && hasBrandAssistFeature;

  const hideScheduleImpact = isScheduleSettingsDisabled() || hasOptions;

  const numItemAttachments = getTotalItemAttachmentCount(item);

  // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO CT-566: Fix this pls :)
  const printOnClick = (withHistory?: boolean) => {
    if (id) {
      sendAnalytics(printItemDetail(id));
      const search = withHistory ? '?withHistory=true' : undefined;
      window.open(
        generateSharedPath(JoinProjectRoutes.PRINT_ITEM_DETAILS, { projectId, itemId: id, search }),
        '_blank'
      );
    }
  };

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
    const handleKey = (e: any) => {
      if (isPrintKeys(e)) {
        e.stopImmediatePropagation();
        e.preventDefault();
        printOnClick();
        return;
      }
      if (
        // return if we've got no node next/previous data...
        !navigationLinks ||
        // or this is a key on an input
        (e.target && ['INPUT', 'TEXTAREA', 'LI'].includes(e.target.tagName)) ||
        // or this is a grid
        (e.path &&
          // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
          e.path.filter((elt: any) => elt.classList && elt.classList.contains('ag-root')).length >
            0)
      )
        return;
      const { location: { search } = { search: '' } } = window;
      switch (e.keyCode) {
        case 37:
          if (navigationLinks.previous && !assetViewerOpenVar()) {
            sendAnalytics(navigateItemsAnalytics('Item Back Key'));
            navigate({
              pathname: generateSharedPath(RouteKeys.PROJECT_ITEMS_ITEM, {
                projectId,
                itemId: navigationLinks.previous.id,
              }),
              search,
            });
          }
          break;
        case 39:
          if (navigationLinks.next && !assetViewerOpenVar()) {
            sendAnalytics(navigateItemsAnalytics('Item Forward Key'));
            navigate({
              pathname: generateSharedPath(RouteKeys.PROJECT_ITEMS_ITEM, {
                projectId,
                itemId: navigationLinks.next.id,
              }),
              search,
            });
          }
          break;
        default:
          break;
      }
    };
    window.addEventListener('keydown', handleKey);
    return () => {
      window.removeEventListener('keydown', handleKey);
    };
  }, [navigate, navigationLinks, printOnClick, projectId, sendAnalytics]);

  // COMPONENTS
  const commentsList = comments && (
    <CommentsHistory
      canComment={canAddItemComments}
      costMode={costMode}
      item={item}
      permissions={permissions}
      variant={ITEM_DETAILS}
    />
  );

  const itemHeader = (
    <ItemHeader
      canDeprecateItems={canDeprecateItems}
      canEditItemDetails={canEditItemDetails}
      canEditItemNumber={canEditItemNumber}
      canEditItemStatus={canEditItemStatus}
      item={item}
      parentVisibility={parentVisibility ?? undefined}
      printOnClick={printOnClick}
      projectId={projectId}
      setOpenDialogAddOption={setOpenDialogAddOption}
      setShowPublishItemDialog={setShowPublishItemDialog}
    />
  );

  const itemDetails = (
    <div className="flex overflow-auto">
      <div
        className="w-[calc(100%-400px)] flex-auto flex-col border-r border-border-muted px-6"
        data-cy="itemDetails-centerCol"
      >
        <ItemsDetailsTopNav
          item={item}
          navigationLinks={navigationLinks}
          parentItem={parentItem}
          projectId={projectId}
        />
        <div>
          {showBrandAssist && (
            <Button
              isDisabled={itemAssistAlreadyRequested}
              label={`Contact ${triggeredBrand || ''}`}
              onClick={() => {
                sendAnalytics(
                  brandAssistAnalytics({
                    type: 'CTA',
                    brand: triggeredBrand || '',
                    itemID,
                  })
                );
                setOpenBrandAssistDialog(true);
              }}
              type="secondary"
            />
          )}
          {showItemAssist && (
            <Button
              label="Try Item Assist"
              onClick={() => {
                sendAnalytics(itemAssistantCTAAnalytics({ type: 'CTA', itemID }));
                setOpenItemAssistDialog(true);
              }}
              startIcon={
                <img alt="" className="colors-button-inactive" src="/img/science.svg" width={24} />
              }
              type="secondary"
            />
          )}
          {hasItemAssistFeature && itemAssistAlreadyRequested && (
            <div className="flex h-10 items-center gap-2">
              <img alt="" src="/img/science.svg" width={16} />
              <div className="type-body3">Item Assist Requested</div>
            </div>
          )}
        </div>
        {milestone && (
          <DialogsNewItem
            key={id}
            milestoneID={milestone?.id}
            onClose={() => {
              refetchItem();
              setOpenDialogAddOption(false);
            }}
            onCompleted={() => setIsExpanded(true)}
            onCreateAnother={refetchItem}
            open={openDialogAddOption}
            parentItem={item as Item}
          />
        )}
        <ItemPublishDialog
          isOpen={showPublishItemDialog}
          itemCreatedByID={item.createdBy?.id}
          itemID={itemID}
          projectID={projectId}
          setIsOpen={setShowPublishItemDialog}
        />
        {openItemAssistDialog && (
          <ItemAssistantConfirmationDialog
            itemID={itemID}
            onDismiss={() => {
              setOpenItemAssistDialog(false);
              sendAnalytics(itemAssistantCTAAnalytics({ type: 'Dismiss', itemID }));
            }}
            onSubmit={() => {
              setOpenItemAssistDialog(false);
              setItemAssistItemIDs([itemID, ...itemAssistItemIDs]);
              sendAnalytics(itemAssistantCTAAnalytics({ type: 'Submit', itemID }));
            }}
            projectID={projectId}
          />
        )}
        {openBrandAssistDialog && (
          <DialogsBrandAssistContent
            brandName={triggeredBrand || ''}
            itemID={itemID}
            onClose={() => {
              sendAnalytics(
                brandAssistAnalytics({ type: 'Dismiss', brand: triggeredBrand || '', itemID })
              );
              setOpenBrandAssistDialog(false);
            }}
            onSubmit={() => {
              sendAnalytics(
                brandAssistAnalytics({ type: 'Submit', brand: triggeredBrand || '', itemID })
              );
              setOpenBrandAssistDialog(false);
              setItemAssistItemIDs([itemID, ...itemAssistItemIDs]);
            }}
            projectID={projectId}
          />
        )}
        {itemHeader}
        {hasOptions ? (
          <OptionCollapse
            isExpanded={isExpanded}
            projectID={projectId}
            setIsExpanded={setIsExpanded}
            setOpenDialogAddOption={setOpenDialogAddOption}
            sharedUsersMap={sharedUsersMap}
          />
        ) : (
          <ItemEstimateCollapse
            gridVariant={GridVariant.ITEM_DETAILS}
            isExpanded={isExpanded}
            isOption={!!parentItem}
            item={item}
            itemStatus={status}
            loading={loading}
            milestoneID={item.milestone?.id}
            projectID={projectId}
            refetch={refetchItem}
            setIsExpanded={setIsExpanded}
            setOpenDialogAddOption={setOpenDialogAddOption}
          />
        )}
        {!hideScheduleImpact && canViewScheduleImpact && (
          <ScheduleImpactCollapse canEdit={canEditScheduleImpact} canView={canViewScheduleImpact} />
        )}
        {!isWidthUp('lg', width) && <ItemSummary width={width} />}
        {canViewItemAttachments && (
          <AssetsCollapse
            canView={canViewItemAttachments}
            numItemAttachments={numItemAttachments}
          />
        )}
        {canViewItemIntegrations && (
          <IntegrationsCollapse
            canCreateChangeEvent={item.status === ACCEPTED}
            canDeleteItemIntegration={canDeleteItemIntegration}
            itemInfo={{
              id,
              name,
              description,
              estimateCost,
              assetCount,
              contingencyDrawCost,
            }}
          />
        )}
        {commentsList}
      </div>
      {isWidthUp('lg', width) && (
        <div className="p-2">
          <ItemSummary width={width} />
        </div>
      )}
    </div>
  );

  return itemDetails;
};

export default withWidth()(ItemDetails);

type ItemHeaderProps = {
  canDeprecateItems: boolean;
  canEditItemDetails: boolean;
  canEditItemNumber: boolean;
  canEditItemStatus: boolean;
  item: ItemDataQueryItem;
  parentVisibility: Visibility | undefined;
  printOnClick: () => void;
  projectId: UUID;
  setOpenDialogAddOption: (open: boolean) => void;
  setShowPublishItemDialog: (open: boolean) => void;
};

const ItemHeader = (props: ItemHeaderProps) => {
  const sendAnalytics = useSendAnalytics();

  const [itemName, setItemName] = useState(props.item.name ?? '');

  const isPrivate = isPrivateVisibility(props.item.visibility);
  const options = 'options' in props.item ? props.item.options : [];
  const isOption = 'parent' in props.item && !!props.item.parent;
  const updateItemName = useItemNameUpdate();
  const setUpdateItemName = () => {
    if (props.item.name !== itemName) {
      sendAnalytics(
        updateItemNameAnalytics({
          itemID: props.item.id,
          newName: itemName,
          itemType: props.item.itemType,
          location: ItemSidebarLocation.ITEM_DETAILS_PAGE,
        })
      );
      updateItemName(props.projectId, props.item.id, itemName);
    }
  };

  const updateItemNumber = useItemNumberUpdate();
  const setItemNumber = (ID: UUID, itemNumber: string) => {
    if (props.item.number !== itemNumber) {
      updateItemNumber(props.projectId, ID, itemNumber);
      sendAnalytics(
        updateItemNumberProps(
          ID,
          itemNumber,
          props.item.itemType,
          ItemSidebarLocation.ITEM_DETAILS_PAGE
        )
      );
    }
  };

  const updateItemDescription = useItemDescriptionUpdate();
  const setUpdateItemDescription = (description: string, descriptionStyled: string) => {
    sendAnalytics(
      updateItemDescriptionAnalytics({
        itemID: props.item.id,
        newDescription: description,
        itemType: props.item.id,
        location: ItemSidebarLocation.ITEM_DETAILS_PAGE,
      })
    );
    updateItemDescription(props.projectId, props.item.id, description, descriptionStyled);
  };

  const updateStatus = useUpdateStatus();
  const setUpdateStatus = (newStatus: Status) => {
    if (props.item.status !== newStatus) updateStatus(props.projectId, props.item, newStatus);
  };

  const attachToItem = useAttachToItem();
  const onAttachAsset = (asset: Asset) => {
    attachToItem({ input: { assetID: asset.id, itemID: props.item.id } });
  };

  return (
    <div className="flex flex-col gap-4">
      {isPrivate && (
        <div className="flex justify-between">
          <div className="flex items-center gap-4">
            <Tooltip content={<div>{DISABLE_ITEM_NUMBER_TOOLTIP_NEW}</div>}>
              <Chip text="Draft" />
            </Tooltip>
            <ItemDetailExportPanel printOnClick={props.printOnClick} />
            <ItemDetailsSettingsPanel
              canDeprecateItems={props.canDeprecateItems}
              isOption={isOption}
              item={props.item}
              options={options}
              projectId={props.projectId}
              setOpenDialogAddOption={props.setOpenDialogAddOption}
            />
          </div>
          <ItemVisibilityToggleEditWrapper
            createdByID={props.item.createdBy?.id}
            isOption={isOption}
            parentVisibility={props.parentVisibility ?? undefined}
            setShowPublishItemDialog={props.setShowPublishItemDialog}
            visibility={props.item.visibility}
          />
        </div>
      )}
      <div className="flex items-end gap-2">
        {!isPrivate && (
          <div className="w-20">
            <ItemNumberTextInput
              editable={props.canEditItemNumber}
              isPrivate={isPrivate}
              itemID={props.item.id}
              itemType={props.item.itemType}
              label="Number"
              onChange={setItemNumber}
              value={props.item.number}
            />
          </div>
        )}
        <div className="grow">
          <TextInput
            aria-label="Item name"
            data-cy="item-name-text-input"
            id="name"
            isDisabled={!props.canEditItemDetails}
            label="Name"
            onBlur={() => {
              setUpdateItemName();
            }}
            onChange={(e) => setItemName(e)}
            onKeyDown={(evt) => {
              if (evt.key === 'Enter') setUpdateItemName();
            }}
            placeholder="Item name"
            value={itemName}
          />
        </div>
        <div className="w-56">
          <ItemStatusSelect
            availableStatuses={props.item.availableStates}
            data-cy="item-status-select"
            isDisabled={!props.canEditItemStatus || isPrivate}
            label="Status"
            onChange={(status: Status) => setUpdateStatus(status)}
            options={'options' in props.item ? props.item.options : undefined}
            value={props.item.status}
          />
        </div>
        {isPrivate ? null : <ItemDetailExportPanel printOnClick={props.printOnClick} />}
        {isPrivate ? null : (
          <ItemDetailsSettingsPanel
            canDeprecateItems={props.canDeprecateItems}
            isOption={isOption}
            item={props.item}
            options={options}
            projectId={props.projectId}
            setOpenDialogAddOption={props.setOpenDialogAddOption}
          />
        )}
      </div>
      <InputsTextAreaStyled
        data-cy="item-description-input"
        isDisabled={!props.canEditItemDetails}
        isEditable
        label="Description"
        onAttachAsset={onAttachAsset}
        onChange={(description, descriptionStyled) => {
          setUpdateItemDescription(description, descriptionStyled);
        }}
        placeholder="Describe this item..."
        value={props.item.descriptionStyled}
      />
    </div>
  );
};
