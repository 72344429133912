import { forwardRef } from 'react';

import { Dimensions } from './useChartDimensions';

type Props = {
  children: React.ReactNode;
  className?: string;
  'data-cy'?: string;
  dimensions: Dimensions;
};
export default forwardRef<HTMLDivElement, Props>(function SVGWithDimensions(props, ref) {
  return (
    <div ref={ref} className={`h-full w-full ${props.className ?? ''}`} data-cy={props['data-cy']}>
      <svg height={props.dimensions.height} width={props.dimensions.width}>
        <g
          transform={`translate(${[props.dimensions.marginLeft, props.dimensions.marginTop].join(
            ','
          )})`}
        >
          {props.children}
        </g>
      </svg>
    </div>
  );
});
