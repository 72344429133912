import { JoinRoutes } from '../../../../../api/gqlEnums';
import { generateSharedPath } from '../../../../../utilities/routes/links';
import ProjectLink from '../../../../dragon-scales/ProjectLink';
import { SearchItem } from '../../types';

export default function ProjectCell({ item }: { item: SearchItem }) {
  return (
    <ProjectLink
      hasAccess={item.project.hasAccess}
      projectName={item.project.name}
      to={generateSharedPath(JoinRoutes.PROJECT, {
        projectId: item.project.id,
      })}
    >
      {item.project.name}
    </ProjectLink>
  );
}
