import { FC } from 'react';

import { Typography } from '@material-ui/core';

import { ProjectStatuses } from '../../../api/gqlEnums';
import { withStyles } from '../../../theme/komodo-mui-theme';
import DataTable, { TableHeader, TableRow } from '../../Tables/DataTable/DataTable';
import {
  ProjectTotal,
  currentItemsBreakdownTableFooterData,
  currentItemsBreakdownTableHeaderData,
  formatCurrentItemsBreakdownTableData,
} from '../ExecutiveDashboardUtils';

import ExecutiveDashboardCurrentItemsBreakdownHintStyles from './ExecutiveDashboardCurrentItemsBreakdownHintStyles';

type ExecutiveDashboardCurrentItemsBreakdownHintTableProps = {
  classes: Classes<typeof ExecutiveDashboardCurrentItemsBreakdownHintStyles>;
  projectTotal: ProjectTotal | undefined;
};

const ExecutiveDashboardCurrentItemsBreakdownHintTable: FC<
  ExecutiveDashboardCurrentItemsBreakdownHintTableProps
> = ({ classes, projectTotal }) => {
  if (!projectTotal) {
    return null;
  }
  const { project, projectStatus, statusBreakdown, statusDuration, totalCost, totalItemCount } =
    projectTotal;
  const { code, name } = project;
  const projectCode = code ? `${code}: ` : '';
  const projectLabel = projectCode.concat(name);
  const statusLabel = `${projectStatus}${
    projectStatus === ProjectStatuses.ACTIVE ? ` ${statusDuration} days` : ''
  }`;

  const tableHeaderData: TableHeader = currentItemsBreakdownTableHeaderData;

  const tableRowData: TableRow[] = statusBreakdown.map((total) =>
    formatCurrentItemsBreakdownTableData(total)
  );

  const tableFooterData: string[] = currentItemsBreakdownTableFooterData(totalItemCount, totalCost);

  return (
    <>
      <Typography className={classes.project}>{projectLabel}</Typography>
      <Typography className={`${classes.labelText} ${classes.active}`}>{statusLabel}</Typography>
      <DataTable
        isHint
        tableFooterData={tableFooterData}
        tableHeaderData={tableHeaderData}
        tableRowData={tableRowData}
      />
    </>
  );
};

export default withStyles(ExecutiveDashboardCurrentItemsBreakdownHintStyles)(
  ExecutiveDashboardCurrentItemsBreakdownHintTable
);
