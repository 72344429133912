import { Maybe } from '../../../../generated/graphql';
import useAssetBlobUrlQuery from '../../hooks/useAssetBlobUrlQuery';

import AssetsCard from './AssetsCard';

type Props = {
  asset: EntityAttachment;
  bookmark?: Bookmark;
  canDelete?: boolean;
  detachAttachment?: (assetID: UUID) => void;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  disabled?: boolean;
  downloadAsset?: (location: string, name: string, id: string) => void;
  onClick?: () => void;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  showMenu: boolean;
  displayUrl?: Maybe<string>;
};

export default function AssetsCardData(props: Props) {
  // blobUrl is used to preview images
  const { url: blobUrl } = useAssetBlobUrlQuery(props.asset?.id);
  return <AssetsCard {...props} blobUrl={blobUrl} />;
}
