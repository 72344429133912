import usePermissions from '../../../utilities/permissions/usePermissions';

import EditProjectTemplateModeBar from './EditProjectTemplateModeBar';
import PreviewModeBar from './PreviewModeBar';
import ViewOnlyModeBar from './ViewOnlyModeBar';

export default function ModeBars() {
  const permissions = usePermissions();

  return (
    <>
      {permissions.isViewOnly && <ViewOnlyModeBar />}
      {permissions.isEditTemplate && <EditProjectTemplateModeBar />}
      {permissions.inPreviewMode && <PreviewModeBar />}
    </>
  );
}
