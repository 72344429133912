import { useMemo } from 'react';

import { CostReportColumnType } from '../../../../generated/graphql';
import { getCostReportByType } from '../../../CostReport/CostReportUtils';
import { useMilestoneCostReportsQuery } from '../../../Milestone/hooks';
import { useMilestoneItemCountQuery } from '../../../Milestone/hooks/useMilestoneQuery';

export default function useItemStatusCostReport(
  projectID?: UUID,
  milestoneID?: UUID,
  quantity?: Quantity
) {
  const columns = useCostReportColumns(quantity);
  // Uses milestoneCostReportsQuery to get the cost reports for the summary.
  // This is setup included in the REFETCH_NAV refetch set, so update that if
  // the underlying query here is changed.
  const costReport = useMilestoneCostReportsQuery(milestoneID, projectID, {}, columns).data
    ?.milestoneCostReports[0];

  const accepted = getCostReportByType(costReport, CostReportColumnType.ACCEPTED_REPORT);
  const incorporated = getCostReportByType(costReport, CostReportColumnType.INCORPORATED_REPORT);
  const pending = getCostReportByType(costReport, CostReportColumnType.PENDING_REPORT);
  const rejected = getCostReportByType(costReport, CostReportColumnType.REJECTED_REPORT);

  const itemCounts = useMilestoneItemCountQuery(milestoneID).data?.itemCounts;

  return {
    accepted: {
      cost: accepted?.segmented,
      numItems: itemCounts?.accepted,
    },
    incorporated: {
      cost: incorporated?.segmented,
      numItems: itemCounts?.incorporated,
    },
    pending: {
      cost: pending?.segmented,
      numItems: itemCounts?.pending,
    },
    rejected: {
      cost: rejected?.segmented,
      numItems: itemCounts?.rejected,
    },
  };
}

const useCostReportColumns = (quantity?: Quantity) =>
  useMemo(
    () =>
      [
        CostReportColumnType.ACCEPTED_REPORT,
        CostReportColumnType.INCORPORATED_REPORT,
        CostReportColumnType.PENDING_REPORT,
        CostReportColumnType.REJECTED_REPORT,
      ].map((type) => ({ type, unitID: quantity?.unit.id })),
    [quantity]
  );
