// Constants

import { NULL_ID } from '../../../constants';
import { ProjectType } from '../../../generated/graphql';
import { buildProjectSettingStore } from '../../ProjectDisplaySettings/hooks';

export const milestoneLabel = (showAsterisk: boolean) =>
  `Active Milestone Name ${showAsterisk ? '*' : ''}`;

export const milestoneTooltip = [
  `In a Join project, you'll manage and present items, which are potential
changes relative to an estimate at a particular project milestone, e.g.
"50% Construction Documents". The active milestone is the one for
which you're currently considering changes.`,
];

// Functions

export const computeClearInactive = (statuses: ProjectStatus[], doClear = false) =>
  doClear ? statuses.filter(({ type }) => type !== 'Inactive') : statuses;

// add an extra to the begining to clear the project type
export const computeProjectTypes = (types: ProjectType[], selectedType?: ProjectType) => {
  const newTypes = [...types];
  // if this is a custom type that was added before we standarized types, then
  // we'll also need to add it to the list
  if (
    selectedType &&
    (selectedType.id === NULL_ID ||
      selectedType.name === 'Unassigned' ||
      !types.some((t) => t.id === selectedType.id))
  ) {
    newTypes.push(selectedType);
  }

  return newTypes;
};

export const getProjectInputDefault = (
  templateProject: ProjectTemplateProject | undefined,
  templateSettings: ProjectSetting[] | undefined
) => {
  const settings = buildProjectSettingStore(templateSettings ?? []);

  return templateProject
    ? {
        currency: settings.CURRENCY,
        lat: templateProject.lat ?? undefined,
        location: templateProject.location,
        lon: templateProject.lon ?? undefined,
        name: '',
        milestone: templateProject.activeMilestone.name,
        milestoneDate: templateProject.activeMilestone.date || '',
        milestoneDesignPhaseID: templateProject.activeMilestone.designPhase?.id || '',
        templateID: templateProject.id,
        typeID: templateProject.type?.id === NULL_ID ? undefined : templateProject.type?.id,
        statusID: templateProject.status.id || '',
        projectDeliveryTypeID: templateProject.projectDeliveryType?.id || '',
        locationDetails: {
          city: templateProject.locationDetails?.city || '',
          country: templateProject.locationDetails?.country || '',
          lat: templateProject.locationDetails?.lat || 0,
          lon: templateProject.locationDetails?.lon || 0,
          name: templateProject.locationDetails?.name || '',
          state: templateProject.locationDetails?.state || '',
          stateLong: templateProject.locationDetails?.stateLong || '',
        },
        orgNodeIDs: templateProject.orgNodes.map((n) => n.id),
      }
    : {
        currency: settings.CURRENCY,
        location: '',
        name: '',
        milestone: '',
        milestoneDate: '',
        milestoneDesignPhaseID: '',
        statusID: '',
        projectDeliveryTypeID: '',
        locationDetails: {
          city: '',
          country: '',
          lat: 0,
          lon: 0,
          name: '',
          state: '',
          stateLong: '',
        },
        orgNodeIDs: [],
      };
};
