import { FC, useEffect, useRef } from 'react';

import { Close as CloseIcon } from '@material-ui/icons';

import { ToastType } from '../../../../../api/gqlEnums';
import { MentionableTextInput, UserReportCommentInput } from '../../../../../generated/graphql';
import { setToast } from '../../../../../hooks/useToastParametersLocalQuery';
import { withStyles } from '../../../../../theme/komodo-mui-theme';
import { isTextValid } from '../../../../../utilities/string';
import CommentEntryInterface from '../../../../Comments/CommentEntry/CommentEntryInterface';
import { defaultComment } from '../../../../Comments/MentionableTextArea/MentionableTextArea';
import {
  UserReportComment,
  useCreateUserReportComment,
  useUpdateUserReportComment,
} from '../../../../ReportsTab/ReportHooks';

import { styles } from './NoteCellCommentWrapperStyles';
import { setIsAddingNoteClick } from './NoteCellUtils';

const CostReportListRowNoteCell: FC<{
  classes: Classes<typeof styles>;
  commentInput: MentionableTextInput;
  currentReportID: UUID | undefined;
  isCurrentReportShared?: boolean;
  isAddingNoteInRow: boolean;
  isNotesColumnExpanded: boolean;
  projectID: UUID;
  setComment: (input: MentionableTextInput) => void;
  setIsAddingNote: (v: boolean) => void;
  userCommentWithCosts: Pick<UserReportComment, 'comment' | 'costs'> | undefined;
  userReportCommentInput: Omit<UserReportCommentInput, 'text'> | undefined;
  isPrint?: boolean;
  isVarianceReport?: boolean;
}> = ({
  classes,
  commentInput,
  currentReportID,
  isCurrentReportShared = false,
  isAddingNoteInRow,
  isNotesColumnExpanded,
  projectID,
  setComment,
  setIsAddingNote,
  userCommentWithCosts,
  userReportCommentInput,
  isPrint = false,
  isVarianceReport = true,
}) => {
  // ref for scrolling this cell into view
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // collapsing the notes column should close the "add note" interface
    if (!isNotesColumnExpanded) {
      setIsAddingNote(false);
    }
  }, [isNotesColumnExpanded, setIsAddingNote]);

  const userComment = userCommentWithCosts?.comment;

  const createComment = useCreateUserReportComment();
  const updateComment = useUpdateUserReportComment();
  const { contents } = commentInput;
  const commentIsAlreadyCreated = !!userComment?.id;

  const submitComment = () => {
    if (isTextValid(contents)) {
      if (!isCurrentReportShared && !!commentInput?.mentions?.length) {
        setToast(
          {
            message:
              'This report is set to private, and mentioned users will not receive notifications unless you share the report with them.  Remove the mention to post the note.',
          },
          ToastType.IMPORT_ESTIMATE
        );
        return;
      }
      if (!commentIsAlreadyCreated && userReportCommentInput && currentReportID) {
        createComment(
          projectID,
          currentReportID,
          {
            ...userReportCommentInput,
            text: commentInput,
          },
          () => {
            if (isVarianceReport) setComment(defaultComment);
            setIsAddingNote(false);
          }
        );
      } else {
        // don't bother updating if there are no changes
        if (userComment?.text?.contents === commentInput.contents) {
          setIsAddingNote(false);
          return;
        }
        if (userReportCommentInput && userComment)
          updateComment(
            projectID,
            userComment?.id,
            commentInput,
            userReportCommentInput.costs,
            () => {
              setIsAddingNote(false);
            }
          );
      }
    }
  };

  // when cancelling a note return the note contents to the original value
  const cancelAddingNote = () => {
    if (!commentIsAlreadyCreated) {
      setComment(defaultComment);
    } else if (userCommentWithCosts?.comment?.text) {
      const mentions =
        userCommentWithCosts.comment.text.mentions?.map((m) => {
          return { ...m, userID: m.user.id };
        }) || [];
      const input = {
        contents: userCommentWithCosts.comment.text.contents,
        mentions,
      };
      setComment(input);
    }

    setIsAddingNote(false);
  };

  // Stop editing if the user hits escape
  const handleNavigation = (event: KeyboardEvent) => {
    const { key } = event;
    // without isAddingNote it will clear away all comments
    if (key === 'Escape' && isAddingNoteInRow) cancelAddingNote();
  };

  useEffect(() => {
    window.addEventListener('keyup', handleNavigation);
    return () => {
      window.removeEventListener('keyup', handleNavigation);
    };
  });

  if (!isNotesColumnExpanded || !isAddingNoteInRow) return null;

  return (
    <div className={classes.newNoteContainer}>
      <CommentEntryInterface
        autoFocus
        comment={commentInput}
        isEditing={commentIsAlreadyCreated}
        isReportComment
        resetCommentAfterSubmitting={false}
        setComment={setComment}
        submitComment={submitComment}
      />
      <CloseIcon
        className={classes.closeAddNote}
        onClick={(e) =>
          setIsAddingNoteClick(e, false, isAddingNoteInRow, isPrint, setIsAddingNote, ref)
        }
        onKeyDown={(e) =>
          setIsAddingNoteClick(e, false, isAddingNoteInRow, isPrint, setIsAddingNote, ref)
        }
      />
    </div>
  );
};

export default withStyles(styles)(CostReportListRowNoteCell);
