import { createStyles } from '@material-ui/core/styles';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';

const InputsSelectReferenceCategorizationMenuItemStyles = (theme: KomodoTheme) =>
  createStyles({
    filterList: {
      ...theme.typography.caption,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      direction: 'ltr',
      justifyContent: 'flex-start',
    },
  });

export default InputsSelectReferenceCategorizationMenuItemStyles;
