import { Edit, MoreVert } from '@material-ui/icons';

import ScheduleDistribution from '../../Icons/ScheduleDistribution';
import { BabyMenuButton, Tooltip } from '../../scales';

import { EventDistributedReports } from './DistributionUtils';

const sharedStyles = 'gap-4 pb-2 pt-2 flex flex-col justify-center';

type ReportLinksCellProps = {
  reports: EventDistributedReports[];
};

export const ReportLinksCell = (props: ReportLinksCellProps) => {
  return (
    <div className={`${sharedStyles}`}>
      {props.reports.map((report) => (
        <a
          key={report.link}
          className="type-link type-table-text"
          href={report.link}
          rel="noreferrer"
          target="_blank"
        >
          {report.role}
        </a>
      ))}
    </div>
  );
};

type SentToCellProps = {
  reports: EventDistributedReports[];
};
export const SentToCell = (props: SentToCellProps) => {
  return (
    <div className={`${sharedStyles} w-full`}>
      {props.reports.map((report) => (
        <div key={report.link} className="flex flex-col justify-center">
          <Tooltip content={report.recipients.join(', ')}>
            <div className="overflow-hidden text-ellipsis whitespace-nowrap type-table-text">
              {report.recipients.join(', ')}
            </div>
          </Tooltip>
        </div>
      ))}
    </div>
  );
};

type ActionMenuCellProps = {
  openEditDistributionDialog: () => void;
};
export const ActionMenuCell = (props: ActionMenuCellProps) => {
  return (
    <div className={`${sharedStyles}`}>
      <BabyMenuButton
        aria-label=""
        entries={[
          {
            label: 'Edit Scheduled Distribution',
            onClick: props.openEditDistributionDialog,
            id: 'history-edit-distribution',
            startAdornment: <Edit />,
          },
        ]}
        icon={<MoreVert />}
      />
    </div>
  );
};

type SentByCellProps = {
  sentBy: string;
  isScheduled: boolean;
  isDeprecated: boolean;
};
export const SentByCell = (props: SentByCellProps) => {
  return (
    <div className="flex items-center justify-center gap-2 type-table-text">
      <div>{props.sentBy}</div>
      {props.isScheduled && (
        <ScheduleDistribution className={props.isDeprecated ? 'text-type-inactive' : ''} />
      )}
    </div>
  );
};
