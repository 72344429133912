import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';

const milestoneDraftModalStyles = (theme: KomodoTheme) =>
  createStyles({
    itemContainer: {
      alignItems: 'center',
      background: theme.palette.backgroundWhite,
      display: 'inline-flex',
      flexDirection: 'row',
      fontSize: 16,
      minHeight: 48,
    },
    itemStatus: {
      paddingRight: 10,
    },
  });

export default milestoneDraftModalStyles;
