import { createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
export default (): any =>
  createStyles({
    contentClass: {
      padding: 0,
    },
    dialogClass: {
      maxWidth: 700,
      maxHeight: 678,
      height: 'unset',
    },
    hintTextTop: {
      padding: '24px 24px 0px 24px',
      fontSize: 16,
      fontWeight: 400,
    },
    metricsText: {
      fontSize: 16,
    },
    sectionHeader: {
      padding: '24px 24px 0px 24px',
      fontSize: 16,
      fontWeight: 700,
    },
    sectionSubheader: {
      padding: '4px 24px 8px 24px',
      fontSize: 12,
      fontWeight: 400,
    },
    topHeaderRow: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'baseline',
      paddingRight: 24,
    },
  });
