import { CSSProperties, FC, MutableRefObject, ReactElement, useRef, useState } from 'react';

import { withStyles } from '@material-ui/core/styles';

import { InputVariant, SelectVariant } from '../../../api/gqlEnums';
import { KomodoTheme } from '../../../theme/komodo-mui-theme';
import NormalTooltip from '../../NormalTooltip/NormalTooltip';
import TooltipTitles from '../../NormalTooltip/TooltipTitles';
import { PortalPositional } from '../../Portal/PortalPositional/PortalPositional';
import { Chip } from '../../scales';
import DropDownArrow from '../DropDownArrow/DropDownArrow';
import SelectCategory from '../SelectCategory/SelectCategory';
import {
  SelectCategoryValue,
  selectCategoryDisplayName,
} from '../SelectCategory/SelectCategoryUtils';

import styles from './SelectCategoryChipInputStyles';

interface SelectCategoryChipInputMultiProps {
  addCategories: (categories: SelectCategoryValue[]) => void;
  categorizations: Categorization[];
  classes: Classes<typeof styles>;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  disabled?: boolean;
  id: string;
  isCompact?: boolean;
  isPreview?: boolean;
  noun: string;
  placeholder?: string;
  removeCategories: (categories: Category[]) => void;
  selectedCategories: Category[];
  theme: KomodoTheme;
}

const SelectCategoryChipInputMulti: FC<SelectCategoryChipInputMultiProps> = ({
  addCategories,
  categorizations,
  classes,
  disabled,
  id,
  isCompact = true,
  isPreview,
  noun,
  placeholder: defaultPlaceholder,
  removeCategories,
  selectedCategories,
  theme,
}) => {
  // STATE
  // input location generation
  const inputRef = useRef() as MutableRefObject<HTMLInputElement>;
  const buttonRef = useRef<HTMLDivElement>(null);
  const [showSelect, setShowSelect] = useState(false);
  const hasSelectedCategories = selectedCategories.length > 0;
  // bail if categorization isn't defined
  if (!categorizations) {
    return null;
  }

  // DISPLAY LOGIC
  // highlighted = hover, tab-ing through
  // selected = is checked

  // UI GENERATORS
  // styles
  const firstCategorization: Categorization = categorizations[0];
  const inputId = `category-input-${
    firstCategorization &&
    firstCategorization.name &&
    firstCategorization.name.replace(/\s+/g, '-').toLowerCase()
  }`;
  // show empty input OR Select dropdown logic
  // EMPTY SELECT
  // VARIABLES

  // generates display chips
  const generateChips = () => {
    const selectedCount = selectedCategories.length;
    // default input plaveholder
    const placeholder = defaultPlaceholder || 'Add Categories';
    const chipCount = selectedCategories.length;
    const chipLimit = 1;
    const extraCount = chipCount > chipLimit ? ` +${chipCount - chipLimit}` : '';
    const chipsPrintout = selectedCategories.map((category, index) => {
      const title = selectCategoryDisplayName(category);
      if (index < chipLimit) {
        return (
          <span key={`${id}-${category.id}-chip`}>
            <Chip data-cy="Chip-SelectCategoryChipInputMulti" text={title} />
          </span>
        );
      }
      return null;
    });
    return selectedCount > 0 ? (
      <>
        {chipsPrintout}
        {extraCount && <span className={classes.extraCount}>{extraCount}</span>}
      </>
    ) : (
      placeholder
    );
  };
  // UI
  const generateSelectedCategoriesCopy = (): string[] =>
    selectedCategories.map((category) => selectCategoryDisplayName(category));

  const withTooltip = (element: ReactElement) =>
    hasSelectedCategories ? (
      <NormalTooltip title={<TooltipTitles titles={generateSelectedCategoriesCopy()} />}>
        {element}
      </NormalTooltip>
    ) : (
      element
    );

  const placeholderClass = selectedCategories.length > 0 ? '' : classes.empty;

  const onClick = () => {
    if (!disabled) {
      // open the select
      setShowSelect(true);
    }
  };

  const button = !showSelect && (
    <div
      ref={buttonRef}
      className={`${classes.selectContentButton} ${
        isCompact ? classes.selectContentButtonCompact : ''
      }`}
      data-cy={inputId}
      onClick={onClick}
      onKeyDown={onClick}
      role="button"
      tabIndex={disabled ? -1 : 0}
    >
      {withTooltip(
        <span
          className={`${classes.chipsWrapper} ${placeholderClass}`}
          data-cy="SelectCategoryChipInputMulti-chipsWrapper"
        >
          {generateChips()}
        </span>
      )}
      {!disabled && <DropDownArrow isRelative onClick={() => setShowSelect(true)} />}
    </div>
  );

  const selectMenu = (
    // render a multiselect for only one categorization
    <PortalPositional>
      {(boundingRect: DOMRect) => {
        const element: HTMLElement | null = document!.getElementById(id);
        const width = (element && element.offsetWidth) || theme.portal.defaultWidth;
        const portalStyles: CSSProperties = {
          position: 'fixed',
          left: `${boundingRect.x}px`,
          top: `${boundingRect.y}px`,
          width: `${width}px`,
          zIndex: 999999,
        };
        return (
          <div style={portalStyles}>
            <div className={classes.containerSelect}>
              <SelectCategory
                addCategories={(categories: SelectCategoryValue[]) => {
                  addCategories(categories);
                }}
                categorizationID={firstCategorization.id}
                includeUncategorizedCategory={false}
                inputValue=""
                inputVariant={InputVariant.CHIP_INPUT}
                isPreview={isPreview}
                noun={noun}
                onSubmit={() => {
                  setShowSelect(false);
                  if (buttonRef!.current) {
                    buttonRef.current.focus();
                  }
                }}
                removeCategories={(categories) => {
                  removeCategories(categories);
                }}
                selectedCategories={selectedCategories}
                selectVariant={SelectVariant.MULTI}
              />
            </div>
          </div>
        );
      }}
    </PortalPositional>
  );
  return (
    <div
      ref={inputRef}
      className={`${classes.containerOuter} ${isCompact ? classes.containerOuterCompact : ''}`}
    >
      {button}
      {showSelect && selectMenu}
    </div>
  );
};

export default withStyles(styles, { withTheme: true })(SelectCategoryChipInputMulti);
