import usePermissions from '../utilities/permissions/usePermissions';

import { TextLink } from './scales';

type Props = {
  message?: string;
};

export default function FourOhFour(props: Props) {
  const permissions = usePermissions();

  return (
    <div className="flex items-center justify-center text-type-primary">
      <img
        alt=""
        // negative margin because the logos have a bunch of whitespace on the
        // left side of them
        className="-ml-40 h-100 w-100 object-cover"
        src={`/img/logos/${Math.floor(Math.random() * (9 - 1 + 1)) + 1}.png`}
      />
      <div className="flex flex-col gap-2">
        <div>
          <p className="type-heading1">Oops!</p>
          <p className="type-heading2">
            {props.message ?? "That page doesn't exist or you don't have permission to see it."}
          </p>
        </div>
        <p className="type-body1">
          {'Try going back to your '}
          <TextLink label="projects page." to="/" />
        </p>
        {permissions.inPreviewMode && (
          <p className="type-body2">
            {`You're currently in Preview Mode. The role/user you're previewing may not have access to this page.`}
          </p>
        )}
      </div>
    </div>
  );
}
