import useThumbnailQuery from '../components/assets/hooks/useThumbnailQuery';
import { ImageDimension } from '../generated/graphql';
import { containsValidUUID } from '../utilities/uuid';

export const useThumbnailURL = (projectThumbnailUUID?: string, skip?: boolean) => {
  const { data, loading } = useThumbnailQuery(projectThumbnailUUID, ImageDimension._92, skip);
  const { asset } = data || {};
  const { thumbnailURL } =
    asset ||
    (!(projectThumbnailUUID && containsValidUUID(projectThumbnailUUID)) && {
      thumbnailURL: projectThumbnailUUID,
    }) ||
    {};
  const thumbnailLoading = !skip && (loading || !thumbnailURL);

  return { thumbnailURL, thumbnailLoading };
};
