import { useCallback, useContext, useMemo } from 'react';

import { projectSettingsVar } from '../../../../../api/apollo/reactiveVars';
import { TermKey } from '../../../../../api/gqlEnums';
import { formatCost, getCurrencySymbol } from '../../../../../utilities/currency';
import BandCostAxes from '../../../../Charts/ChartsD3/Axes/BandCostAxes';
import SVGWithDimensions from '../../../../Charts/ChartsD3/SVGWithDimensions';
import { useChartDimensions } from '../../../../Charts/ChartsD3/useChartDimensions';
import { ProjectTermStore } from '../../../../ProjectDisplaySettings/TerminologyProvider';
import { Chip } from '../../../../scales';

import { BarRunningTotal, generateD3Scales, getLabelString } from './ScenariosChartUtils';

type Props = {
  budget: USCents;
  estimate: USCents;
  barRunningTotals: BarRunningTotal[];
};

const ScenariosChart = (props: Props) => {
  const { barRunningTotals, estimate, budget } = props;
  const t = useContext(ProjectTermStore);

  // Sizing
  const { ref, dimensions } = useChartDimensions({ marginBottom: 60 });

  const { x, y } = useMemo(
    () =>
      generateD3Scales(
        dimensions.boundedWidth,
        dimensions.boundedHeight,
        barRunningTotals,
        budget,
        estimate
      ),
    [barRunningTotals, budget, estimate, dimensions.boundedHeight, dimensions.boundedWidth]
  );
  const xComponents = useCallback(
    (maxWidth: number) =>
      barRunningTotals.map((d) => (
        <div key={d.id} className="flex flex-col items-center">
          <div style={{ maxWidth }}>
            <Chip backgroundColor={d.color} text={d.name} />
          </div>
          <div className="type-paragraph">
            {formatCost(d.value, { short: true, showCurrencySymbol: false })}
          </div>
        </div>
      )),
    [barRunningTotals]
  );

  // Range Consts
  const [x0, x1] = x.range();
  const y0 = y.range()[0];
  const bandwidth = x.bandwidth();

  const showBaseline = y(0) >= y0;

  // Line labels
  const LABEL_ANCHOR = 80;
  const labelPos = props.budget > props.estimate ? 1 : -1;
  const LABEL_OFFSET = 16;

  return (
    <SVGWithDimensions ref={ref} dimensions={dimensions}>
      <BandCostAxes
        height={dimensions.height}
        isCost
        marginLeft={dimensions.marginLeft}
        x={x}
        xComponents={xComponents(bandwidth)}
        y={y}
        yLabel={`${t.titleCase(TermKey.RUNNING_TOTAL)} in ${
          projectSettingsVar()?.CURRENCY
        } ${getCurrencySymbol()}`}
      />
      {props.barRunningTotals.map((d) => (
        <g key={d.id}>
          <rect
            className={`stroke-border-default ${d.color ? '' : 'fill-background-2'}`}
            fill={d.color}
            fillOpacity="0.6"
            height={y0 - y(d.value)}
            width={x.bandwidth()}
            x={x(d.id) ?? 0}
            y={y(d.value)}
          />
          <line
            className="stroke-entities-estimate stroke-[4px]"
            x1={(x(d.id) ?? 0) - 1}
            x2={(x(d.id) ?? 0) + x.bandwidth() + 1}
            y1={y(d.value)}
            y2={y(d.value)}
          />
        </g>
      ))}
      {props.estimate && (
        <>
          <line
            className="stroke-entities-estimate stroke-2"
            strokeDasharray="4,2"
            x1={x0}
            x2={x1}
            y1={y(props.estimate)}
            y2={y(props.estimate)}
          />
          <text
            className="type-label"
            dominantBaseline="middle"
            textAnchor="start"
            x={LABEL_ANCHOR}
            y={y(props.estimate) - labelPos * LABEL_OFFSET}
          >
            {getLabelString(t.titleCase(TermKey.ESTIMATE), props.estimate)}
          </text>
        </>
      )}
      {props.budget && (
        <>
          <line
            className="stroke-entities-milestone stroke-[8px] mix-blend-multiply"
            x1={x0}
            x2={x1}
            y1={y(props.budget)}
            y2={y(props.budget)}
          />
          <text
            className="fill-entities-milestone type-label"
            dominantBaseline="middle"
            textAnchor="start"
            x={LABEL_ANCHOR}
            y={y(props.budget) + labelPos * LABEL_OFFSET}
          >
            {getLabelString(t.titleCase(TermKey.TARGET), props.budget)}
          </text>
        </>
      )}
      {showBaseline && (
        <line
          className="stroke-chart-base-line stroke-[2px]"
          x1={x0 - 1}
          x2={x1 + 1}
          y1={y(0)}
          y2={y(0)}
        />
      )}
    </SVGWithDimensions>
  );
};

export default ScenariosChart;
