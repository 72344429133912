import { ProgramCostsQuery, ProgramCostsQueryVariables } from '../../../generated/graphql';
import { programCostsQuery } from '../queries';
import { mountedProgramsVar } from '../../../api/apollo/reactiveVars';
import { useQuery } from '../../../hooks/useMountAwareQuery';
import { MountPolicy } from '../../../hooks/usePolicyOnFirstMount';

export const useProgramCostsQuery = (includedProjectIDs: UUID[], programID: UUID) =>
  useQuery<ProgramCostsQuery, ProgramCostsQueryVariables>(
    programCostsQuery,
    {
      variables: { includedProjectIDs, programID },
      skip: !programID,
      context: { batch: true },
    },
    mountedProgramsVar().get(programID) ? MountPolicy.SKIP_ON_MOUNT : MountPolicy.FETCH_ON_MOUNT
  );
