import { ReactNode } from 'react';

type Props = {
  className?: string;
  detail: ReactNode;
  header?: ReactNode;
  list: ReactNode;
};

export default function ListDetail(props: Props) {
  return (
    <div
      className={`flex max-h-full max-w-full grow flex-col overflow-auto bg-background-primary text-type-primary ${
        props.className ?? ''
      }`}
    >
      {props.header && (
        <>
          {props.header}
          <div className="border-b" />
        </>
      )}
      <div className="flex grow items-stretch overflow-auto">
        <div className="w-50 shrink-0 overflow-auto border-r">{props.list}</div>
        <div className="flex grow flex-col overflow-auto">{props.detail}</div>
      </div>
    </div>
  );
}
