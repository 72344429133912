import { createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
const styles = (theme: any) =>
  createStyles({
    root: {
      color: theme.palette.joinPrimary,
    },
    close: {
      margin: 0,
      width: 32,
      height: 32,
      color: theme.palette.joinPrimary,
    },
    content: {
      padding: '12px 24px',
    },
    contentHeight: {
      overflowY: 'hidden',
      minHeight: 274,
    },
    icon: {
      position: 'absolute',
      fontSize: 16,
      marginRight: 10,
    },
    leftIconPosition: {
      marginLeft: -15,
      marginTop: 4,
    },
    left: {
      justifyContent: 'left',
      position: 'absolute',
      left: 30,
    },
    innerPaper: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden',
    },
    titleContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '14px 24px 10px',
    },
    link: {
      cursor: 'pointer',
      textDecoration: 'underline',
      display: 'inline-block',
    },
    linkButton: {
      ...theme.typography.link,
      fontFamily: theme.typography.fontFamily,
      display: 'flex',
      marginBottom: 12,
      alignItems: 'center',
      background: 'transparent',
      border: 'none',
    },
    action: {
      borderTop: `1px solid ${theme.palette.divider}`,
      margin: 0,
      padding: '12px 8px',
    },
    error: {
      color: theme.palette.red,
    },
    categoryPanel: {
      paddingTop: 12,
    },
    metadataContainer: {
      paddingTop: '8px',
    },
    metadataContent: {
      alignItems: 'center',
      padding: '8px',
      gap: '4px',
      fontFamily: theme.typography.fontFamily,
      fontSize: 16,
      fontWeight: 400,
      lineHeight: '20px',
    },
    shadedTipText: {
      fontFamily: theme.typography.fontFamily,
      fontSize: 12,
      fontWeight: 400,
      lineHeight: '144%',
      letterSpacing: '0.0119em',
    },
    tablesContainer: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: 12,
      overflowY: 'hidden',
      maxHeight: 380,
    },
  });

export default styles;
