import { useEffect, useRef, useState } from 'react';

type ChartDimensions = {
  width: number;
  height: number;
  marginTop?: number;
  marginRight?: number;
  marginBottom?: number;
  marginLeft?: number;
};

const combineChartDimensions = (dimensions: ChartDimensions) => {
  const defaultDimensions = {
    marginTop: 10,
    marginRight: 10,
    marginBottom: 40,
    marginLeft: 76,
  };
  const parsedDimensions = {
    ...defaultDimensions,
    ...dimensions,
  };
  const boundedHeight =
    parsedDimensions.height - parsedDimensions.marginTop - parsedDimensions.marginBottom;
  const boundedWidth =
    parsedDimensions.width - parsedDimensions.marginLeft - parsedDimensions.marginRight;
  return {
    ...parsedDimensions,
    boundedHeight,
    boundedWidth,
  };
};

export type ChartSettings = {
  width?: number;
  height?: number;
  marginTop?: number;
  marginRight?: number;
  marginBottom?: number;
  marginLeft?: number;
};

export type Dimensions = {
  width: number;
  height: number;
  marginTop: number;
  marginRight: number;
  marginBottom: number;
  marginLeft: number;
  /** The area reserved for the chart, excluding top and bottom margins. */
  boundedHeight: number;
  /** The area reserved for the chart, excluding left and right margins. */
  boundedWidth: number;
};

export const useChartDimensions = (passedSettings: ChartSettings) => {
  const ref = useRef(null);

  // container sizing
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  useEffect(() => {
    if (passedSettings.width && passedSettings.height) return () => {};
    const element = ref.current;
    if (!element) return () => {};

    const resizeObserver = new ResizeObserver((entries) => {
      if (!Array.isArray(entries)) return;
      if (!entries.length) return;
      const { contentRect } = entries[0];
      if (width !== contentRect.width) setWidth(contentRect.width);
      if (height !== contentRect.height) setHeight(contentRect.height);
    });

    resizeObserver.observe(element);
    return () => {
      resizeObserver.unobserve(element);
    };
  });

  const dimensions: Dimensions = combineChartDimensions({
    width,
    height,
    ...passedSettings,
  });

  return { ref, dimensions };
};
