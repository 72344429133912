import { FC, useMemo } from 'react';

import { reportVariance, varianceEventTypes } from '../../../../analytics/analyticsEventProperties';
import { VARIANCE } from '../../../../constants';
import useSendAnalytics from '../../../../hooks/useSendAnalytics';
import { withStyles } from '../../../../theme/komodo-mui-theme';
import { getProjectIdFromUrl } from '../../../../utilities/url';
import { usePersistentStates } from '../../../../utilities/urlState';
import CostReportMetricGroup from '../../../CostReport/CostReportMetricGroup/CostReportMetricGroup';
import CostReportMetricGroupDiagram from '../../../CostReport/CostReportMetricGroup/CostReportMetricGroupDiagram';
import {
  MetricGroup,
  getGroupsForUnits,
  toggleGroupsOptionsKeys,
} from '../../../CostReport/CostReportMetricGroup/CostReportMetricGroupUtils';
import { useSortedUnits } from '../../../Milestone/hooks/UnitHooks';
import { useDetailedMilestonesQuery } from '../../../Milestones/hooks';
import { generateVarianceStorageParam } from '../../useVarianceReportParams';
import { VARIANCE_DEFAULTS } from '../../VarianceReportUtils';

import styles from './VarianceModalUnitsStyles';
import { formatMilestoneNames, modalUnitsColors } from './VarianceModalUnitsUtils';

type VarianceModalUnitsProps = {
  classes: Classes<typeof styles>;
  defaultGroupBy: string[];
};

const VarianceModalUnits: FC<VarianceModalUnitsProps> = ({ classes, defaultGroupBy }) => {
  // Data
  const projectId = getProjectIdFromUrl();
  const { units, unitsLoading } = useSortedUnits(true);

  const { data: { milestones = [] } = {}, loading: milestonesLoading } = useDetailedMilestonesQuery(
    projectId,
    false
  );

  // Hooks
  const sendAnalytics = useSendAnalytics();

  const [settings, setSettings] = usePersistentStates(
    window.location,
    VARIANCE,
    { ...VARIANCE_DEFAULTS, groupBy: defaultGroupBy },
    generateVarianceStorageParam(projectId)
  );

  const { milestoneIDs, varianceColumns } = settings;

  // Categorized Metrics Actions
  const groups = useMemo(
    () => getGroupsForUnits(milestones, milestoneIDs, units, varianceColumns),
    [milestoneIDs, milestones, units, varianceColumns]
  );
  const setSettingsAndReport = (varianceColumns: string[]) => {
    setSettings({ varianceColumns });
    sendAnalytics(reportVariance(varianceEventTypes.VARIANCE_SELECT_UNITS, { varianceColumns }));
  };
  const toggleSelected = toggleGroupsOptionsKeys(groups, varianceColumns, setSettingsAndReport);

  if (unitsLoading || milestonesLoading) return null;

  // Categorized Metrics Comps
  const milestoneNames = formatMilestoneNames(milestoneIDs, milestones);

  const categorizedMetricSelect = () => (
    <div className={classes.container}>
      <div className={classes.categorizedMetricContainer}>
        {groups.map((group: MetricGroup, i: number) => (
          <CostReportMetricGroup
            key={group.headerText}
            color={modalUnitsColors[i]}
            metricGroup={group}
            selected={varianceColumns}
            toggleSelected={toggleSelected}
          />
        ))}
      </div>
      {milestoneNames?.length > 1 && (
        <CostReportMetricGroupDiagram metricGroups={groups} milestoneNames={milestoneNames} />
      )}
    </div>
  );

  return categorizedMetricSelect();
};

export default withStyles(styles)(VarianceModalUnits);
