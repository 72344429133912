import { ComponentProps, useRef } from 'react';
import { DragPreview, useDrag } from 'react-aria';

import { Add, AddCircle, MoreVert } from '@material-ui/icons';

import { ItemType, ItemsListItem, ScenarioColumn } from '../../../generated/graphql';
import { getDateString } from '../../../utilities/dates';
import ItemsOptionChipSquareListArea from '../../Items/ItemsOptionChipSquareListArea/ItemsOptionChipSquareListArea';
import { BabyMenuButton } from '../../scales';
import { costFormatter } from '../common/CostSummary';
import { DROP_OP_MOVE } from '../common/DropTargetUtils';
import ItemName from '../common/ItemName';
import ItemStatus from '../common/ItemStatus/ItemStatusDisplay';
import { getScenarioName } from '../common/ScenariosUtils';
import { ITEM_ID_DROP_TYPE } from '../Sandbox/Scenario/DropTargetItems';

import DragTile from './DragTile';

const ADD_ALL_KEY = 'add-all';

type Props = {
  item: Pick<
    ItemsListItem,
    'cost' | 'id' | 'name' | 'number' | 'status' | 'createdAt' | 'itemType'
  > & {
    options: ComponentProps<typeof ItemsOptionChipSquareListArea>['options'];
  } & {
    creator: Pick<UserLink, 'id' | 'name'>;
  };
  onAddToAllScenarios: (itemID: UUID) => void;
  onAddToScenario: (itemID: UUID, scenarioID: UUID) => void;
  scenarios: Pick<ScenarioColumn, 'name' | 'scenarioID'>[];
  searchText: string;
};

const ItemTile = (props: Props) => {
  const { item } = props;

  const preview = useRef(null);

  const { dragProps, isDragging } = useDrag({
    preview,
    getItems() {
      return [{ [ITEM_ID_DROP_TYPE]: item.id }];
    },
    getAllowedDropOperations() {
      return [DROP_OP_MOVE];
    },
  });

  return (
    <>
      <div
        {...dragProps}
        className={`rounded focus:bg-selection-selected ${
          isDragging ? 'outline-none' : 'focus:outline'
        }`}
        role="button"
        tabIndex={0}
        title={item.name}
      >
        <ItemTileContents {...props} selected={isDragging} />
      </div>
      <DragPreview ref={preview}>
        {() => (
          <div className="w-80">
            <ItemTileContents {...props} selected />
          </div>
        )}
      </DragPreview>
    </>
  );
};

// eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
const ItemTileContents = (props: Props & { selected?: boolean }) => {
  const { item } = props;
  return (
    <DragTile selected={props.selected}>
      <div className="flex flex-grow flex-col overflow-hidden">
        <div className="flex items-center">
          <ItemName name={item.name} number={item.number} searchText={props.searchText} />
          <ItemStatus hideLabel status={item.status} />
          <BabyMenuButton
            aria-label="add item to scenarios"
            data-cy="item-tile-menu-button"
            entries={[
              {
                id: ADD_ALL_KEY,
                label: 'Add to all scenarios',
                onClick: () => props.onAddToAllScenarios(item.id),
                startAdornment: <AddCircle />,
              },
              ...props.scenarios.map((scenario) => ({
                id: scenario.scenarioID,
                label: `Add to ${getScenarioName(scenario)}`,
                onClick: () => props.onAddToScenario(item.id, scenario.scenarioID),
                startAdornment: <Add />,
              })),
            ]}
            icon={<MoreVert />}
          />
        </div>
        <div>
          Cost Impact:&nbsp;
          {costFormatter(item.cost, true)}
        </div>
        {/* TODO: Schedule Impact not ready yet */}
        {/* <div>Schedule Impact:&nbsp;XXX</div> */}
        <div>
          Creator:&nbsp;{item.creator.name} ({getDateString(new Date(item.createdAt))})
        </div>
        {item.itemType === ItemType.ITEM_WITH_OPTIONS ? (
          <ItemsOptionChipSquareListArea options={item.options} parentStatus={item.status} />
        ) : null}
      </div>
    </DragTile>
  );
};

export default ItemTile;
