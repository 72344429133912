import { ComponentType, FC } from 'react';

import { ExpandMore } from '@material-ui/icons';

import { withStyles } from '../../theme/komodo-mui-theme';

import styles from './CollapseIconStyles';

type CollapseIconProps = {
  classes: Classes<typeof styles>;
  className?: string;
  dataCy?: string;
  fontSize?: 'inherit' | 'default';
  IconComponent?: ComponentType;
  isCollapsed?: boolean;
  onClick?: (isCollapsed: boolean) => void;
};

const CollapseIcon: FC<CollapseIconProps> = ({
  classes,
  className,
  dataCy = 'CollapseIcon',
  fontSize,
  IconComponent = ExpandMore,
  isCollapsed = false,
  onClick,
}) => {
  return (
    <IconComponent
      className={`${className} ${classes.icon} ${
        isCollapsed ? classes.iconCollapsed : classes.iconExpanded
      }`}
      data-cy={dataCy}
      fontSize={fontSize}
      onClick={() => onClick?.(!isCollapsed)}
    />
  );
};

export default withStyles(styles)(CollapseIcon);
