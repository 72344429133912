import * as React from 'react';

import { Divider, IconButton, Typography } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';

import { withStyles } from '../../../../../theme/komodo-mui-theme';
import { CostTrendlineEvent } from '../../../../dashboard/DashboardCharts/DashboardChartsTrend/DashboardChartsTrendUtils';
import styles from '../../DialogsHideListStyles';
import DialogsHideCostTrendlineEventListItem from '../DialogsHideCostTrendlineEventListItem/DialogsHideCostTrendlineEventListItem';

type Props = {
  classes: Classes<typeof styles>;
  eventListItemRef: React.RefObject<HTMLDivElement>;
  onClickToggleAllEvents: (visible: boolean) => void;
  onClickToggleEventVisibility: () => void;
  onMouseLeave: () => void;
  onMouseOver: (trendlineEvent: CostTrendlineEvent) => void;
  milestoneID: UUID;
  selectedEvent: CostTrendlineEvent | null;
  trendlineEvents: CostTrendlineEvent[];
  onEventListItemMouseLeave: () => void;
};

const DashboardEventList: React.FC<Props> = ({
  classes,
  onClickToggleAllEvents,
  onClickToggleEventVisibility,
  onMouseLeave,
  onMouseOver,
  milestoneID,
  selectedEvent,
  trendlineEvents,
  onEventListItemMouseLeave,
  eventListItemRef,
}) => {
  const charNameLimit = trendlineEvents.length > 10 ? 35 : 40;
  const hiddenEvents = trendlineEvents.filter((t) => !t.visible).length;
  const eventDescription = hiddenEvents !== 1 ? 'events' : 'event';
  return (
    <div className={classes.root}>
      <Divider />
      <div className={classes.header} data-cy="editTrendline-header">
        <Typography className={classes.headerText}>
          {trendlineEvents.length} {eventDescription} ({hiddenEvents} hidden)
        </Typography>
        <IconButton
          aria-label="Hide"
          className={classes.visibleIcon}
          onClick={() => onClickToggleAllEvents(hiddenEvents > 0)}
        >
          {hiddenEvents > 0 && <Visibility className={classes.toggleAllVisibleIcon} />}
          {hiddenEvents === 0 && <VisibilityOff className={classes.toggleAllVisibleIcon} />}
        </IconButton>
      </div>
      <div className={classes.scroll} onMouseLeave={onMouseLeave}>
        {trendlineEvents &&
          trendlineEvents.map((trendlineEvent) => {
            const isSelected = !!selectedEvent && selectedEvent === trendlineEvent;
            return (
              <div key={trendlineEvent.timestamp} ref={isSelected ? eventListItemRef : null}>
                <Divider />
                <DialogsHideCostTrendlineEventListItem
                  charNameLimit={charNameLimit}
                  milestoneID={milestoneID}
                  onClickToggleEventVisibility={onClickToggleEventVisibility}
                  onFocus={onMouseOver}
                  onMouseLeave={onEventListItemMouseLeave}
                  onMouseOver={onMouseOver}
                  selected={isSelected}
                  trendlineEvent={trendlineEvent}
                />
              </div>
            );
          })}
        {trendlineEvents && trendlineEvents.length < 8 && <Divider />}
      </div>
    </div>
  );
};
export default withStyles(styles)(DashboardEventList);
