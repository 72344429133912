import { ITEM, MILESTONE, OPTION } from '../constants';

/*
HELPER FUNCTIONS
- getGridType
*/

// returns a descriptive object telling what kind of estimate we are on
// gridType = location
// model = type of model that the esitmate is for
export const getGridType = (
  itemIsOption: boolean
): { gridType: string; model: typeof ITEM | typeof OPTION | typeof MILESTONE } => {
  const isMilestonePage = document.location.href.includes('milestones');
  const gridType = isMilestonePage ? 'milestone-estimate' : 'item-like-estimate';
  // eslint-disable-next-line no-nested-ternary
  const model = gridType === 'milestone-estimate' ? MILESTONE : itemIsOption ? OPTION : ITEM;
  return { gridType, model };
};
