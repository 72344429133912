import { OrganizationQuery, OrganizationQueryVariables } from '../../../../generated/graphql';
import { useQuery } from '../../../../hooks/useMountAwareQuery';
import { organizationQuery } from '../../../Organizations/hooks/queries';

export default function useOrganizationQuery(organizationID: UUID | null | undefined) {
  return useQuery<OrganizationQuery, OrganizationQueryVariables>(organizationQuery, {
    variables: { organizationID },
    skip: !organizationID,
  });
}
