import Menu, { SubMenu } from 'rc-menu';
import { FC } from 'react';

import { MenuItem, Typography } from '@material-ui/core';
import { ArrowForwardIos } from '@material-ui/icons';

import { withStyles } from '../../../theme/komodo-mui-theme';

import InputsSelectReferenceStyles from './InputsSelectReferenceStyles';

type ReferencesMenuProps = {
  classes: Classes<typeof InputsSelectReferenceStyles>;
  subMenuItems: React.ReactNode;
  icon?: React.ReactNode;
  title: React.ReactNode;
  subTitle: string;
};

// For consistency in various opening directions, we just want to have it line up closely.
// the x offset is 0 for bi-directional consistency
// the y offset is derived from the height of the header across menus
// 36 (height of selectHeader) + 1 (border)
export const subMenuOffset = [0, -37];

const ReferencesMenu: FC<ReferencesMenuProps> = ({
  classes,
  subMenuItems,
  icon,
  title,
  subTitle,
}) => {
  const titleComponent = (
    <div className="flex flex-grow flex-row items-center justify-between p-2">
      {icon}
      <div className={classes.innerMenuTitleContainer}>
        <Typography className={classes.menuMainText}>{title}</Typography>
        <Typography className={classes.subText}>{subTitle}</Typography>
      </div>
    </div>
  );
  return (
    <MenuItem classes={{ root: classes.menuMainOuterRoot }} className={classes.menuMainOuter}>
      <Menu
        className={classes.menuMainInner}
        data-cy={`menu-${title}`}
        expandIcon={<ArrowForwardIos className="m-2 !w-[14px]" />}
        triggerSubMenuAction="click"
      >
        {!subMenuItems && titleComponent}
        {subMenuItems && ( // the offset here is derived from the height of the grey header
          <SubMenu popupOffset={subMenuOffset} title={titleComponent}>
            {subMenuItems}
          </SubMenu>
        )}
      </Menu>
    </MenuItem>
  );
};

export default withStyles(InputsSelectReferenceStyles)(ReferencesMenu);
