import { isScenarioVisibility } from '../../../../../Items/ItemsUtils';
import ItemStatusChanged from '../../../../common/ItemStatus/ItemStatusChanged';
import { ScenarioItemEntry } from '../../../../common/ItemStatus/ItemStatusUtils';

type Props = {
  item: ScenarioItemEntry;
};

export default function StatusCellChanged(props: Props) {
  const baseStatus = props.item.overlay?.baseStatus ?? undefined;
  const isOptionSelection = props.item.options.length > 0;
  const isScenarioItem = isScenarioVisibility(props.item.visibility);

  return (
    <div className="flex h-8 items-center gap-1 type-table-text">
      <ItemStatusChanged
        baseStatus={baseStatus}
        isOptionSelection={isOptionSelection}
        isScenarioItem={isScenarioItem}
        status={props.item.status}
      />
    </div>
  );
}
