import {
  ImportEstimateErrorsQuery,
  ImportEstimateErrorsQueryVariables,
} from '../../../generated/graphql';
import { useQuery } from '../../../hooks/useMountAwareQuery';

import { importEstimateErrorsQuery } from './queries';

export function useImportEstimateErrorsQuery(estimateID: UUID | null) {
  return useQuery<ImportEstimateErrorsQuery, ImportEstimateErrorsQueryVariables>(
    importEstimateErrorsQuery,
    {
      variables: { estimateID },
      skip: !estimateID,
    }
  );
}
