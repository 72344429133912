import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../theme/komodo-mui-theme';

const SUMMARY_ROW_HEIGHT = 28; // Helps us with print, this is expected height at our sizing

export default (theme: KomodoTheme) =>
  createStyles({
    acceptedTotal: {
      color: theme.palette.accepted,
    },
    budgetTotal: {
      color: theme.palette.budget,
    },
    right: {
      textAlign: 'right',
    },
    input: {
      marginTop: 4,
      marginRight: -6,
    },
    number: {
      textAlign: 'right',
      ...theme.typography.number,
    },
    numberCell: {
      width: 105,
    },
    root: {
      display: 'flex',
      flexDirection: 'column',
      color: theme.palette.primaryGrey,
      paddingBottom: 4,
    },
    row: {
      display: 'flex',
      lineHeight: '1.2rem',
      flexDirection: 'row',
      alignItems: 'center',
      height: SUMMARY_ROW_HEIGHT,
    },
    estimateTotal: {
      padding: 0,
    },
    spacer: {
      width: 12,
    },
    runningLine: {
      backgroundColor: theme.palette.runningTotal,
      height: 1,
      opacity: 0.9,
      '@media print': {
        height: 0,
        borderBottom: `1px solid ${theme.palette.runningTotal}`,
      },
    },
    budgetLine: {
      backgroundColor: theme.palette.joinPrimary,
      height: 1,
      opacity: 0.9,
      '@media print': {
        height: 0,
        borderBottom: `1px solid ${theme.palette.joinPrimary}`,
      },
    },
    text: {
      paddingRight: '8px',
      flexGrow: 1,
    },
    textEmphasized: {
      fontSize: '1.2rem',
    },
    remaining: {
      color: theme.palette.shadedGrey,
    },
    padding: {
      paddingLeft: 12,
      paddingRight: 12,
    },
  });
