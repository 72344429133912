import { getTodayAtUtcNoon } from '../../../utilities/dates';
import useMemoWrapper from '../../useMemoWrapper';
import { computeItems } from '../TimelineUtils';

import { useItemsTimeline } from './ItemsHook';

export function useItems(projectID: UUID | undefined) {
  const { data: itemsData, loading: itemsListLoading } = useItemsTimeline(projectID);
  const itemsList = itemsData?.itemsTimeline?.items ?? [];
  const today = getTodayAtUtcNoon();
  const itemsListAll = itemsList;
  const items = useMemoWrapper(computeItems, itemsListAll, today);
  const withoutDueDateCount = itemsListAll.length - items.length;
  return {
    items,
    withoutDueDateCount,
    loading: itemsListLoading,
  };
}
