import queryString from 'query-string';
import { FC, useEffect, useMemo, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';

import { JoinProjectRoutes } from '../../../api/gqlEnums';
import { DASHBOARD, TRUE, VIEW_FILTER } from '../../../constants';
import {
  GetMilestoneQuery,
  GetProjectPropsQuery,
  ListProjectCategorizationsQuery,
} from '../../../generated/graphql';
import { getCategorizationsForProjectFromQueryData } from '../../../hooks/useProjectCategorizationsQuery';
import { useThumbnailURL } from '../../../hooks/useThumbnailURL';
import { generateSharedPath } from '../../../utilities/routes/links';
import { getProjectIdFromUrl } from '../../../utilities/url';
import { usePersistentStates } from '../../../utilities/urlState';
import { getDashboardStorageParam } from '../../dashboard/Dashboard';
import DashboardChartsEstimate from '../../dashboard/DashboardCharts/DashboardChartsEstimate/DashboardChartsEstimate';
import DashboardChartsItems from '../../dashboard/DashboardCharts/DashboardChartsItems/DashboardChartsItems';
import DashboardChartsTrend from '../../dashboard/DashboardCharts/DashboardChartsTrend/DashboardChartsTrend';
import { DASHBOARD_DEFAULTS } from '../../dashboard/DashboardUtils';
import { restoreFilters } from '../../FilterPanel/filterUtils';
import { useLoadTimer } from '../../PerfMonitor/utils';
import PrintSubheader from '../PrintCostReport/PrintSubheader';
import usePrintWindow from '../PrintHooks/usePrintWindow';
import PrintPageBreak from '../PrintSharedComponents/PrintPageBreak';
import { PrintPageHeaderWrapper } from '../PrintSharedComponents/PrintPageHeaderWrapper';
import { LANDSCAPE_WIDTH, LandscapeOrientation } from '../PrintUtils';

import PrintDashboardStyles from './PrintDashboardStyles';

type PrintDashboardProps = {
  classes: Classes<typeof PrintDashboardStyles>;
  milestoneData: GetMilestoneQuery | undefined;
  projectCategorizationsData: ListProjectCategorizationsQuery | undefined;
  projectPropsData: GetProjectPropsQuery;
};

const PrintDashboard: FC<PrintDashboardProps> = ({
  classes,
  milestoneData,
  projectCategorizationsData,
  projectPropsData,
}) => {
  // CONSTANTS
  const projectId = getProjectIdFromUrl();
  const routerLocation = useLocation();
  const hasSummary =
    routerLocation.pathname ===
    generateSharedPath(JoinProjectRoutes.PRINT_PROJECT_DASHBOARD_SUMMARY, { projectId });

  // SETTINGS
  const [settings] = usePersistentStates(
    window.location,
    DASHBOARD,
    DASHBOARD_DEFAULTS,
    getDashboardStorageParam(projectId)
  );

  // DATA
  const project = projectPropsData.project as ProjectProps;
  const data = projectCategorizationsData;
  const categorizations = getCategorizationsForProjectFromQueryData(data);
  const thumbnail = project?.thumbnail;
  const { thumbnailURL, thumbnailLoading } = useThumbnailURL(thumbnail, !hasSummary);
  const activeMilestone = milestoneData?.milestone;
  const milestoneName = (activeMilestone && activeMilestone.name) || '';

  const hasCategorization = useMemo(
    () => categorizations && categorizations.length > 0,
    [categorizations]
  );

  const filterState = restoreFilters(settings[VIEW_FILTER]);
  const { filterQueryInput: viewFilter } = filterState;

  // FIRE PRINT ON LOADING
  const [imagesAreReady, setImagesAreReady] = useState(false);

  const [trendlineIsLoading, setTrendlineIsLoading] = useState(true);
  const [estimateIsLoading, setEstimateIsLoading] = useState(true);
  const [itemsIsLoading, setItemsIsLoading] = useState(true);
  const hooksLoading =
    thumbnailLoading ||
    trendlineIsLoading ||
    estimateIsLoading ||
    itemsIsLoading ||
    !imagesAreReady;
  useLoadTimer('PrintDashboard', hooksLoading);
  usePrintWindow(!hooksLoading);

  useEffect(() => {
    if (project && project.name) document.title = `${project.name} - Dashboard`;
  }, [project]);

  if (project && project.name) {
    document.title = `${project.name} - Dashboard`;
  }

  // COMPONENT - hack to fully re-generate chart widths on print
  const landscapeOrientation = <LandscapeOrientation />;

  const { search } = window.location;
  const parsed = queryString.parse(search, { arrayFormat: 'index' });
  const { showTable } = parsed;
  const isTableDisplayed = showTable === TRUE;

  const hasFirstPage = activeMilestone && !isTableDisplayed;
  const hasSecondPage = hasCategorization && activeMilestone;
  const hasThirdPage = hasCategorization && activeMilestone && !isTableDisplayed;
  const first = hasFirstPage ? 1 : 0;
  const second = hasSecondPage ? 1 : 0;
  const third = hasThirdPage ? 1 : 0;
  const pagesCount = first + second + third;

  const [completed, setCompleted] = useState<boolean[]>(Array(pagesCount).fill(false));
  useEffect(() => {
    const allCompleted = completed.every((v) => v === true);
    if (allCompleted) {
      setImagesAreReady(true);
    }
  }, [pagesCount, completed, setImagesAreReady]);

  const header = (index: number) => (
    <div style={{ width: LANDSCAPE_WIDTH }}>
      <PrintPageHeaderWrapper
        milestoneName={!hasSummary ? milestoneName : undefined}
        projectId={projectId}
        reportName={isTableDisplayed ? 'Estimate Chart with Details' : 'Dashboard'}
        triggerOnComplete={() => {
          completed[index] = true;
          setCompleted([...completed]);
        }}
      />
      <PrintSubheader
        categorizations={categorizations}
        hasSummary={hasSummary}
        milestone={activeMilestone ?? undefined}
        project={project}
        settings={settings}
        thumbnailURL={thumbnailURL ?? undefined}
      />
    </div>
  );

  const charts = (
    <>
      {hasFirstPage && (
        <>
          {header(0)}
          <DashboardChartsTrend
            activeMilestoneId={activeMilestone.id}
            isPrint
            setIsLoading={setTrendlineIsLoading}
            settings={settings}
            viewFilter={viewFilter}
          />
        </>
      )}
      {hasSecondPage && (
        <>
          {!isTableDisplayed && <PrintPageBreak />}
          {header(1)}
          <DashboardChartsEstimate
            activeMilestoneId={activeMilestone.id}
            categorizations={categorizations}
            isPrint
            isTableDisplayed={isTableDisplayed}
            setIsLoading={setEstimateIsLoading}
            settings={settings}
            viewFilter={viewFilter}
          />
        </>
      )}
      {hasThirdPage && (
        <>
          <PrintPageBreak />
          {header(2)}
          <DashboardChartsItems
            activeMilestoneId={activeMilestone.id}
            categorizations={categorizations}
            isPrint
            setIsLoading={setItemsIsLoading}
            settings={settings}
            viewFilter={viewFilter}
          />
        </>
      )}
    </>
  );

  if (!projectId) return <Navigate to="/404" />;

  return (
    <div className="print-header-page">
      <div className={classes.root}>
        {landscapeOrientation}
        <div className={classes.page} style={{ width: LANDSCAPE_WIDTH }}>
          {charts}
        </div>
      </div>
    </div>
  );
};

export default withStyles(PrintDashboardStyles)(PrintDashboard);
