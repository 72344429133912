import { FC } from 'react';

import { TextField } from '@material-ui/core';

import { PROJECT_COMP_COST_INPUT } from '../../../tagConstants';
import { withStyles } from '../../../theme/komodo-mui-theme';
import { EMPTY_COST } from '../../../utilities/string';
import styles from '../ProjectCompsStyles';

type ProjectCompsCostTableNumericCellProps = {
  classes: Classes<typeof styles>;
  cy?: string;
  isAverageComp?: boolean;
  value: string | number;
};

const ProjectCompsCostTableNumericCell: FC<ProjectCompsCostTableNumericCellProps> = ({
  classes,
  cy,
  isAverageComp,
  value,
}) => {
  const isEmptyCost = value === EMPTY_COST || value === 0;
  const backgroundColor = isAverageComp || isEmptyCost ? classes.greyBG : classes.whiteBG;

  const cellStyle = `${classes.costCellField} ${backgroundColor}`;

  return (
    <TextField
      className={cellStyle}
      data-cy={`${PROJECT_COMP_COST_INPUT}-${cy}`}
      disabled
      InputProps={{
        classes: {
          focused: classes.costInputFocused,
        },
        className: classes.costInput,
        disableUnderline: true,
      }}
      value={value}
    />
  );
};

export default withStyles(styles)(ProjectCompsCostTableNumericCell);
