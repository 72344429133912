import SvgIcon from '@material-ui/core/SvgIcon';

import { IconProps } from '.';

export const CollapseAll = (props: IconProps) => (
  <SvgIcon
    {...props}
    fill="none"
    height="14"
    viewBox="0 0 12 14"
    width="12"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M1.41 6.59L6 11.17L10.59 6.59L12 8L6 14L0 8L1.41 6.59Z" fill="black" />
    <path d="M1.41 0.589996L6 5.17L10.59 0.589996L12 2L6 8L0 2L1.41 0.589996Z" fill="black" />
  </SvgIcon>
);

export const ExpandAll = (props: IconProps) => (
  <SvgIcon
    {...props}
    fill="none"
    height="13"
    viewBox="0 0 14 13"
    width="14"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.295 10.885L10.875 6.295L6.295 1.705L7.705 0.294998L13.705 6.295L7.705 12.295L6.295 10.885Z"
      fill="black"
    />
    <path
      d="M0.294998 10.885L4.875 6.295L0.294998 1.705L1.705 0.294998L7.705 6.295L1.705 12.295L0.294998 10.885Z"
      fill="black"
    />
  </SvgIcon>
);
