import { withStyles } from '../../../theme/komodo-mui-theme';

import ItemSidebarTextFieldStyles from './ItemSidebarTextFieldStyles';

type ItemSidebarTextFieldProps = {
  classes: Classes<typeof ItemSidebarTextFieldStyles>;
  dataCy?: string;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  disabled?: boolean;
  icon?: JSX.Element;
  value: string;
};

const ItemSidebarTextField = ({
  classes,
  dataCy,
  disabled,
  icon,
  value = '',
}: ItemSidebarTextFieldProps) => {
  return (
    <div className={classes.container}>
      {icon}
      <div className={`${classes.textContent} ${disabled && classes.disabled}`} data-cy={dataCy}>
        {value}
      </div>
    </div>
  );
};

export default withStyles(ItemSidebarTextFieldStyles)(ItemSidebarTextField);
