import { useRef, useState } from 'react';
import { useMenuTriggerState } from 'react-stately';

import { CategoryMultiSelect } from '..';
import { useProjectCategorizationsQuery } from '../../../hooks/useProjectCategorizationsQuery';
import { kebabDataCy } from '../../../utilities/cypress';
import { Button, Checkbox, Popover } from '../../scales';

type Value = {
  isAllSelected: boolean;
  categories: CategoryReference[];
};

const dataCy = 'categories-multi-select';

export default function CategoriesMultiSelectPopover(props: {
  defaultValue: Value;
  onChange: (value: Value) => void;
  projectID: UUID;
}) {
  const state = useMenuTriggerState({});
  const ref = useRef<HTMLButtonElement>(null);

  const categorizations = useProjectCategorizationsQuery(props.projectID, false).data
    ?.projectCategorizations;

  const [selectedCategories, setSelectedCategories] = useState<CategoryReference[]>(
    props.defaultValue.categories
  );
  const [isAllSelected, setIsAllSelected] = useState(Boolean(props.defaultValue.isAllSelected));

  let badge: string | undefined;
  if (selectedCategories.length) badge = `${selectedCategories.length}`;
  if (isAllSelected) badge = 'All';

  return (
    <>
      <Button
        ref={ref}
        badge={badge}
        data-cy={kebabDataCy(dataCy, 'button')}
        label="Select Categories"
        onClick={() => state.toggle()}
        type="secondary"
      />
      {state.isOpen && (
        <Popover
          className="flex w-80 flex-col overflow-auto"
          data-cy={kebabDataCy(dataCy, 'popover')}
          placement="bottom start"
          state={state}
          triggerRef={ref}
        >
          <div className="flex flex-col gap-2 p-2">
            <Checkbox
              data-cy={kebabDataCy(dataCy, 'all-checkbox')}
              isSelected={isAllSelected}
              onChange={(value) => {
                setIsAllSelected(value);
                setSelectedCategories([]);
                props.onChange({
                  categories: [],
                  isAllSelected: value,
                });
              }}
            >
              All Categories
            </Checkbox>
            {categorizations?.map(({ categorization }) => (
              <CategoryMultiSelect
                key={categorization.id}
                categorization={categorization}
                isDisabled={isAllSelected}
                onChange={(value) => {
                  setSelectedCategories((prevState) => {
                    const newState = [
                      ...prevState.filter((c) => c.categorizationID !== categorization.id),
                      ...value.map((id) => ({ id, categorizationID: categorization.id })),
                    ];

                    props.onChange({
                      categories: newState,
                      isAllSelected: false,
                    });

                    return newState;
                  });
                }}
                value={selectedCategories.flatMap((c) =>
                  c.categorizationID === categorization.id ? [c.id] : []
                )}
              />
            ))}
          </div>
        </Popover>
      )}
    </>
  );
}
