import { EXPORT_PROJECT_COST_BREAKDOWN_REPORT } from '../../actions/actionTypes';
import { analyticsEvent } from '../../analytics/analyticsEventProperties';
import { isDownloadingProjectCostBreakdownReportToExcelVar } from '../../api/apollo/reactiveVars';
import JoinAPI from '../../api/joinAPI';
import {
  EstimateType,
  ProjectCostBreakdownReportQuery,
  ProjectCostBreakdownReportQueryVariables,
} from '../../generated/graphql';
import { useQuery } from '../../hooks/useMountAwareQuery';
import useSendAnalytics from '../../hooks/useSendAnalytics';

import { getProjectCostBreakdownReportQuery } from './queries';

export const useProjectCostBreakdownReportQuery = (
  projectID: UUID,
  milestoneID: UUID,
  estimateType: EstimateType,
  costMode: CostMode
) =>
  useQuery<ProjectCostBreakdownReportQuery, ProjectCostBreakdownReportQueryVariables>(
    getProjectCostBreakdownReportQuery,
    {
      variables: { projectID, milestoneID, estimateType, costMode },
    }
  );

export const useExportProjectCostBreakdownReport = (
  projectID: UUID,
  milestoneID: UUID,
  estimateType: EstimateType,
  costMode: CostMode,
  projectName: string,
  milestoneName: string
) => {
  const sendAnalytics = useSendAnalytics();

  if (isDownloadingProjectCostBreakdownReportToExcelVar()) return;

  const fileNameTokens = [projectName, milestoneName];
  sendAnalytics(analyticsEvent(EXPORT_PROJECT_COST_BREAKDOWN_REPORT));
  JoinAPI.exportProjectCostBreakdownReport(
    projectID,
    milestoneID,
    estimateType,
    costMode,
    fileNameTokens,
    () => {
      // close this dialog when we're down exporting
      setTimeout(window.close, 100);
    }
  );
};
