import { DateRangeInput } from '../../../generated/graphql';
import DateField from '../../ForecastingRoute/common/DateField';
import ExpandableFilterContainer from '../common/ExpandableFilterContainer';

type Props = {
  'data-cy'?: string;
  onChange: (value: DateRangeInput) => void;
  title: string;
  value: DateRangeInput;
};

export default function DateRangeFilters(props: Props) {
  return (
    <ExpandableFilterContainer
      canReset={!!props.value.start || !!props.value.end}
      data-cy={props['data-cy']}
      onReset={() => props.onChange({ start: null, end: null })}
      title={props.title}
    >
      <div className="flex flex-col gap-2 overflow-auto">
        <DateField
          label="Dates Starting From"
          onChange={(value) => {
            props.onChange({
              start: value?.toISOString(),
              end: props.value.end,
            });
          }}
          value={props.value.start ? new Date(props.value.start) : null}
        />
        <DateField
          label="Dates Ending On"
          onChange={(value) => {
            props.onChange({
              start: props.value.start,
              end: value?.toISOString(),
            });
          }}
          value={props.value.end ? new Date(props.value.end) : null}
        />
      </div>
    </ExpandableFilterContainer>
  );
}
