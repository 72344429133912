import { createStyles } from '@material-ui/core';

import { MAX_COL_WIDTH } from '../../constants';
import { KomodoTheme } from '../../theme/komodo-mui-theme';

export default (theme: KomodoTheme) =>
  createStyles({
    card: {
      marginBottom: 24,
      overflow: 'visible',
      backgroundColor: 'white',
      border: theme.border.line,
    },
    root: {
      flexGrow: 1,
      overflow: 'auto',
      [theme.breakpoints.up('xl')]: {
        maxWidth: MAX_COL_WIDTH,
      },
    },
  });
