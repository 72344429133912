import { useMutation } from '@apollo/client';

import { REFETCH_SCENARIO_SANDBOX } from '../../../api/refetchSets';

import { copyScenarioMutation } from './queries';

export const useCopyScenario = ({
  scenarioID,
  milestoneID,
}: {
  scenarioID: UUID;
  milestoneID: UUID;
}) => {
  const [copyFn] = useMutation(copyScenarioMutation);

  const copyScenario = (color: string) =>
    copyFn({
      variables: {
        scenarioID,
        milestoneID,
        color,
      },
      refetchQueries: REFETCH_SCENARIO_SANDBOX,
    });

  return [copyScenario];
};
