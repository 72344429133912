import { createStyles } from '@material-ui/core';

export default () =>
  createStyles({
    thumbnail: {
      width: '100%',
      height: '100%',
      objectFit: 'contain',
    },
  });
