import { useMemo } from 'react';

import { VARIANCE, VIEW_FILTER } from '../../constants';
import {
  makeDefaultLevelNames,
  makeOptionsFromCategorizations,
} from '../../utilities/categorization';
import { getDefaultReportCategorizations } from '../../utilities/categorization/categorizationUtils';
import { returnGroupByOptions } from '../../utilities/grouping';
import { usePersistentStates } from '../../utilities/urlState';
import { transformVarianceSettings } from '../CostReport/CostReportList/CostReportList/CostReportListUtils';
import { useFilterManager } from '../FilterPanel/filterUtils';
import { useGetProjectUnitsQuery } from '../Milestone/hooks/UnitHooks';

import { transformVarianceDefaults } from './VarianceReportUtils';

// LOCAL "STATE" SETUP from URL and localStorage per milestone
export const generateVarianceStorageParam = (projectID: UUID) =>
  `${projectID} - Variance Report - `;

function useVarianceReportParams(categorizations: Categorization[], projectID: UUID) {
  // load project enabled units
  const { data: { getProjectUnits: enabledUnits = [] } = { getProjectUnits: [] } } =
    useGetProjectUnitsQuery(projectID, true);

  const transformSettings = transformVarianceSettings(enabledUnits);
  const defaultCategorizations = getDefaultReportCategorizations(categorizations);
  const defaultGroupBy = makeDefaultLevelNames(defaultCategorizations);

  const param = useMemo(() => generateVarianceStorageParam(projectID), [projectID]);
  const [settings, setSettings] = usePersistentStates(
    window.location,
    VARIANCE,
    { ...transformVarianceDefaults, groupBy: defaultGroupBy },
    param,
    transformSettings,
    ''
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  const setSetting = (param: string, value: any) => {
    const resetChanges = ['groupBy'];
    const resetCollapse = resetChanges.includes(param) ? { collapse: [], expand: [] } : {};
    setSettings({ [param]: value, ...resetCollapse });
  };

  // TODO: This doesn't do much, but could in the future
  const filterManager = useFilterManager(settings[VIEW_FILTER], (newValue: string) =>
    setSetting(VIEW_FILTER, newValue)
  );

  // We group by the estimate's categorizations as a default setting, but allow the user to override.
  const { groupBy } = settings;

  // GroupBy
  // We split up categorization levels as options
  const options: Categorization[] = makeOptionsFromCategorizations(categorizations);
  // We match the simple string to a complex option with level explanation for the tree
  // we also need to scrub for valid option ids
  const displayGroupBy: DisplayGroupBy[] =
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
    groupBy && returnGroupByOptions(groupBy, options).filter((gb: any) => gb.id);

  return {
    displayGroupBy,
    filterManager,
    setSetting,
    setSettings,
    settings,
  };
}

export default useVarianceReportParams;
