import { FC, ReactNode } from 'react';

import { ErrorOutline as Error } from '@material-ui/icons';

import { S1, S2 } from '../../../constants';
import FilterIcon from '../../Icons/FilterIcon';
import { Chip, Tooltip } from '../../scales';
import ErrorTooltip from '../ErrorTooltip';
import NoticeTooltip from '../NoticeTooltip';
import { ReferenceDisplay } from '../types';

import SelectedReferencesToolTip from './SelectedReferencesToolTip';

interface ReferenceRendererProps {
  onSelect: () => void;
  value: MarkupReferenceCell;
  isReadOnlyVariant: boolean;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  linesReadOnly: boolean;
  error: string;
  dataCy?: string;
}

const ReferenceRenderer: FC<ReferenceRendererProps> = ({
  value,
  onSelect,
  isReadOnlyVariant,
  linesReadOnly,
  error,
  dataCy,
}) => {
  const { names, filters, shouldNotApplyCategoryFiltersToOtherMarkups } = value;

  const hasNoReferences = names && names.length === 1 && names[0] === ReferenceDisplay.NOT_APPLIED;

  const hasDropdown = !linesReadOnly;
  const disabledMarkupStyle = value.disabled ? `join-grid-line-disabled` : '';

  let colorStyle = '';
  if (error) colorStyle = 'join-grid-error';
  if (hasNoReferences && !error) colorStyle = 'join-grid-notice';

  const errorTooltip =
    error || (hasNoReferences && 'Calculate your percent markups by selecting where they apply to');

  const errorText = (
    <Error
      className={colorStyle}
      color={hasNoReferences ? 'inherit' : 'error'}
      style={{ fontSize: '20px' }}
    />
  );

  return (
    <div
      className={`join-grid-renderer join-grid-string ${
        ((error || hasNoReferences) && 'join-grid-reference-cell-error') || ''
      }`}
    >
      <div className={`join-grid-trim-text gap-1 ${disabledMarkupStyle} ${colorStyle}`}>
        <ReferenceContent
          filters={filters}
          isReadOnlyVariant={isReadOnlyVariant}
          names={names}
          shouldNotApplyCategoryFiltersToOtherMarkups={shouldNotApplyCategoryFiltersToOtherMarkups}
        />
      </div>
      {error || hasNoReferences ? (
        <div className="push-right">
          {error && <ErrorTooltip title={errorTooltip}>{errorText}</ErrorTooltip>}
          {!error && hasNoReferences && (
            <NoticeTooltip title={errorTooltip}>{errorText}</NoticeTooltip>
          )}
        </div>
      ) : null}
      {hasDropdown && (
        <div
          className="join-grid-dropdown-arrow"
          data-cy={`button-${dataCy}`}
          onClick={onSelect}
          onKeyDown={onSelect}
        />
      )}
    </div>
  );
};

type ChipWithTooltipProps = {
  text: ReactNode;
  tooltipFilters: Category[];
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  showFilterIcon: boolean;
};

const ChipWithTooltip = (props: ChipWithTooltipProps) => (
  <Tooltip
    content={<SelectedReferencesToolTip categories={props.tooltipFilters} />}
    isDisabled={props.tooltipFilters.length === 0}
  >
    <div data-cy={`hover-target-${props.text}`}>
      <Chip icon={props.showFilterIcon && <FilterIcon isFilled />} text={props.text} />
    </div>
  </Tooltip>
);

type ReferenceContentProps = {
  names: string | string[] | undefined;
  filters: Category[];
  isReadOnlyVariant: boolean;
  shouldNotApplyCategoryFiltersToOtherMarkups?: boolean;
};

const ReferenceContent = ({
  filters,
  isReadOnlyVariant,
  names,
  shouldNotApplyCategoryFiltersToOtherMarkups,
}: ReferenceContentProps) => {
  if (isReadOnlyVariant && typeof names === 'string') {
    return (
      <>
        <div>{names}</div>
        {filters.length && (
          <Tooltip content={<SelectedReferencesToolTip categories={filters} />}>
            <div>
              <FilterIcon className="!icon-sm" isFilled />
            </div>
          </Tooltip>
        )}
      </>
    );
  }
  if (typeof names === 'string')
    return (
      <Chips
        filters={filters}
        referenceString={names}
        shouldNotApplyCategoryFiltersToOtherMarkups={shouldNotApplyCategoryFiltersToOtherMarkups}
      />
    );

  if (names && names.length > 0) {
    const name = names[0];
    if (name === ReferenceDisplay.NOT_APPLIED) return <>{name}</>;
    return <Chip text={name} />;
  }
  return null;
};

type ChipsProps = {
  referenceString: string;
  filters: Category[];
  shouldNotApplyCategoryFiltersToOtherMarkups?: boolean;
};

export const Chips = (props: ChipsProps) => {
  const hasFilters = (props.filters || []).length > 0;

  // For this unique case, express as S1 and S2 chips
  if (
    hasFilters &&
    props.referenceString === ReferenceDisplay.TOTAL &&
    props.shouldNotApplyCategoryFiltersToOtherMarkups
  )
    return (
      <ChipWithTooltip
        showFilterIcon={false}
        text={
          <div className="flex items-center">
            {`${ReferenceDisplay.TOTAL} = `}
            <FilterIcon className="!icon-sm" isFilled />
            {S1}
            {' +'}
            <div className="w-2" />
            {S2}
          </div>
        }
        tooltipFilters={props.filters}
      />
    );

  const individualRefStrings = props.referenceString.split(',').map((r) => r.trim());
  const hasMarkupFilters = hasFilters && !props.shouldNotApplyCategoryFiltersToOtherMarkups;
  const hasS1 = individualRefStrings.includes(S1);
  const hasS2 = individualRefStrings.includes(S2);
  const markupRefStrings = individualRefStrings
    .filter((name) => name !== S1 && name !== S2)
    .sort((a, b) => a.localeCompare(b, undefined, { numeric: true }));
  const hasMarkups = markupRefStrings.length > 0;
  const markupFiltersString = markupRefStrings.join(', ');

  return (
    <>
      {hasS1 && (
        <ChipWithTooltip showFilterIcon={hasFilters} text={S1} tooltipFilters={props.filters} />
      )}
      {hasS2 && (
        <ChipWithTooltip showFilterIcon={hasFilters} text={S2} tooltipFilters={props.filters} />
      )}
      {hasMarkups && (
        <ChipWithTooltip
          showFilterIcon={hasMarkupFilters}
          text={markupFiltersString}
          tooltipFilters={hasMarkupFilters ? props.filters : []}
        />
      )}
    </>
  );
};

export default ReferenceRenderer;
