import { FC, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { LinearProgress } from '@material-ui/core';

import { currentUserReportVar, setReactiveLocal } from '../../api/apollo/reactiveVars';
import { JoinProjectRoutes } from '../../api/gqlEnums';
import { REPORT_ROUTER, USER_REPORT_VAR } from '../../constants';
import { PermissionResource } from '../../generated/graphql';
import {
  getCategorizationsForProjectFromQueryData,
  useProjectCategorizationsQuery,
} from '../../hooks/useProjectCategorizationsQuery';
import { makeDefaultLevelNames } from '../../utilities/categorization';
import { getDefaultReportCategorizations } from '../../utilities/categorization/categorizationUtils';
import usePermissions from '../../utilities/permissions/usePermissions';
import { generateSharedPath } from '../../utilities/routes/links';
import { getProjectIdFromUrl } from '../../utilities/url';

import { DefaultReports, MapUserReportTypeToConfig } from './config';
import { useUserReportsAndProjectPropsQuery } from './ReportHooks';
import { reportToPath } from './utils';

type RouteReportProps = {
  isPrint?: boolean;
};

const RouteReport: FC<RouteReportProps> = ({ isPrint = false }) => {
  const navigate = useNavigate();
  const projectId = getProjectIdFromUrl();

  const fallback = () => {
    navigate(generateSharedPath(JoinProjectRoutes.PROJECT_DASHBOARD, { projectId }), {
      replace: true,
    });
    return <div />;
  };
  const { data, loading } = useUserReportsAndProjectPropsQuery(projectId);
  const activeMilestoneID = data?.project?.activeMilestone.id ?? '';

  const { canView } = usePermissions();
  const canViewMarkups = canView(PermissionResource.MARKUPS);
  const { data: catData, loading: catzLoading } = useProjectCategorizationsQuery(projectId);
  const categorizations = getCategorizationsForProjectFromQueryData(catData);
  const defaultCategorizations = getDefaultReportCategorizations(categorizations);
  const defaultGroupBy = makeDefaultLevelNames(defaultCategorizations);

  const routeMap: Map<string, UserReport> = useMemo(() => {
    const userReports = data?.userReports ?? [];
    const newRouteMap = new Map<string, UserReport>();
    if (!loading && !catzLoading) {
      DefaultReports(
        undefined,
        undefined,
        {
          showContingencyReport: canViewMarkups,
          showMarkupsInTooltip: canViewMarkups,
        },
        defaultGroupBy
      )
        .filter((r) => MapUserReportTypeToConfig[r.reportType]?.appViewRoute)
        .forEach((r) => newRouteMap.set(r.id, r));
      userReports.forEach((r) => newRouteMap.set(r.id, r as UserReport));
    }
    return newRouteMap;
  }, [data?.userReports, loading, catzLoading, canViewMarkups, defaultGroupBy]);

  if (loading || catzLoading) return <LinearProgress hidden={!loading} />;

  const parts = window.location.pathname.split(`/${REPORT_ROUTER}/`);
  if (parts.length < 2) return fallback();
  const reportID = parts[1];
  if (!reportID) return fallback();
  const report = routeMap.get(reportID);
  if (!report) return fallback();
  const newPath = reportToPath(isPrint ? 'print' : 'app', report, projectId, activeMilestoneID);
  if (!newPath) return fallback();
  // this controls the current report name
  setReactiveLocal(currentUserReportVar, USER_REPORT_VAR, report);
  navigate(newPath, { replace: true });

  return <LinearProgress hidden={!loading} />;
};

export default RouteReport;
