import { PermissionResource } from '../../../generated/graphql';
import { RouteKeys } from '../../../routes/paths';
import usePermissions from '../../../utilities/permissions/usePermissions';
import { generateSharedPath } from '../../../utilities/routes/links';
import { useProjectID } from '../../../utilities/routes/params';

export const useTeamLinks = () => {
  const projectId = useProjectID();
  const { canView } = usePermissions();

  const links = [
    {
      id: 'teammates',
      name: 'Teammates',
      path: generateSharedPath(RouteKeys.PROJECT_TEAM_TEAMMATES, { projectId }),
    },
    {
      id: 'companies',
      name: 'Companies',
      path: generateSharedPath(RouteKeys.PROJECT_TEAM_COMPANIES, { projectId }),
    },
  ];

  if (canView(PermissionResource.PERMISSION_TEMPLATES)) {
    links.push({
      id: 'roles',
      name: 'Roles',
      path: generateSharedPath(RouteKeys.PROJECT_TEAM_ROLES, { projectId }),
    });
  }

  return links;
};
