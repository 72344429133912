import {
  ProjectDeliveryTypesQuery,
  ProjectDeliveryTypesQueryVariables,
} from '../generated/graphql';

import { getProjectDeliveryTypes } from './queries';
import { useQuery } from './useMountAwareQuery';
import { MountPolicy } from './usePolicyOnFirstMount';

export const useProjectDeliveryTypes = () => {
  const { data } = useQuery<ProjectDeliveryTypesQuery, ProjectDeliveryTypesQueryVariables>(
    getProjectDeliveryTypes,
    {},
    MountPolicy.SKIP_ON_MOUNT
  );

  return data?.projectDeliveryTypes ?? [];
};
