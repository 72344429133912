import { FC } from 'react';

import { ApolloError } from '@apollo/client';
import { Typography } from '@material-ui/core';
import { createStyles } from '@material-ui/core/styles';

import { withStyles } from '../../../theme/komodo-mui-theme';

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
const styles = (theme: any) =>
  createStyles({
    error: {
      paddingTop: 10,
      color: theme.palette.red,
    },
  });

type Props = {
  classes: Classes<typeof styles>;
  message?: string;
};

const ErrorMessage: FC<Props> = ({ classes, message }) =>
  message ? <Typography className={classes.error}>{message}</Typography> : <div />;

// when creating categories the result of the mutation may also return an error
// this is because users can create multiple categories at a time
// the back end will return any successfully created categories along with
// an error if some categories could not be created becasue they already exist
// the second input will check the query result and return that error if no
// graphql or network errors were found
export const processError = (err: ApolloError, resultError?: string) => {
  if (!err) {
    return '';
  }
  if (err.networkError) {
    return 'Failed to add your categorization. Contact support@join.build';
  }
  if (err.graphQLErrors && err.graphQLErrors.length > 0) {
    return err.graphQLErrors[0].message || '';
  }
  if (resultError) {
    return resultError;
  }
  return '';
};

export default withStyles(styles)(ErrorMessage);
