import FilesAttach from '../../../DocumentMagic/Files/FilesAttach/FilesAttach';
import FilesDialogContent from '../../../DocumentMagic/Files/FilesDialog/FilesDialogContent';
import { Button, Dialog } from '../../../scales';
import { ItemCopyState } from '../../hooks/useCopyItemMutationParams';

type ItemAttachmentsDialogProps = {
  onAttachItemCopy: (assetIDs: UUID[]) => void;
  onBackFilesDialog: () => void;
  onDropFile: (file: File) => void;
  progressPercent?: number;
  state: ItemCopyState;
};

const ItemAttachmentsDialog = (props: ItemAttachmentsDialogProps) => {
  return (
    <Dialog
      footerLeft={<Button label="Back" onClick={props.onBackFilesDialog} type="secondary" />}
      footerRight={
        <FilesAttach onAttachItemCopy={props.onAttachItemCopy} onClick={props.onBackFilesDialog} />
      }
      isFullHeight
      isOpen
      onClose={props.onBackFilesDialog}
      progressPercent={props.progressPercent}
      size="lg"
      title={`Add files to '${props.state.name}'`}
    >
      <FilesDialogContent disabledIDs={props.state.assetIDs} onDropFile={props.onDropFile} />
    </Dialog>
  );
};

export default ItemAttachmentsDialog;
