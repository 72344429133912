import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';

export default (theme: KomodoTheme) =>
  createStyles({
    ctaContainer: {
      flex: '0 0 480px',
    },
    emptyList: {
      textAlign: 'left',
      maxWidth: '900px',
      display: 'flex',
      margin: '64px auto',
      flexWrap: 'wrap',
    },
    headline: {
      fontSize: 60,
      margin: '0px 0px 24px 0px',
      fontWeight: 'bold',
    },
    icon: {
      width: 60,
      height: 60,
    },
    iconContainer: {
      width: 60,
      height: 60,
      margin: '16px 24px',
    },
    valueProp: {
      alignItems: 'center',
      flex: '1 1 100%',
      display: 'flex',
    },
    valueProps: {
      padding: '12px',
      width: '50%',
      textAlign: 'left',
      marginTop: 0,
      color: theme.palette.primaryGrey,
      flex: '0 0 360px',
      display: 'inline-flex',
      flexDirection: 'row',
      justifyContent: 'space-around',
      flexWrap: 'wrap',
      border: '3px solid black',
      marginLeft: '24px',
    },
    valuePropTitle: {
      textAlign: 'left',
    },
  });
