import { useMutation } from '@apollo/client';

import {
  autodeskPotentialChangeOrderFields,
  gql,
  procoreChangeEventFields,
} from '../api/graphqlFragments';
import { REFETCH_ITEM_INTEGRATIONS } from '../api/refetchSets';
import {
  CreateItemIntegrationObjectInput,
  CreateItemIntegrationObjectMutation,
  CreateItemIntegrationObjectMutationOptions,
  CreateItemIntegrationObjectMutationVariables,
  SourceSystem,
} from '../generated/graphql';

const createItemIntegrationObjectMutation = gql`
  mutation CreateItemIntegrationObject(
    $sourceSystem: SourceSystem!
    $sourceID: UUID!
    $sourceProjectID: String!
    $sourceCompanyID: String
    $itemID: UUID!
    $input: CreateItemIntegrationObjectInput!
  ) {
    createItemIntegrationObject(
      sourceSystem: $sourceSystem
      sourceID: $sourceID
      sourceProjectID: $sourceProjectID
      sourceCompanyID: $sourceCompanyID
      itemID: $itemID
      input: $input
    ) {
      procoreChangeEvent {
        ...procoreChangeEventFields
      }
      autodeskPotentialChangeOrder {
        ...autodeskPotentialChangeOrderFields
      }
      error
    }
  }
  ${procoreChangeEventFields}
  ${autodeskPotentialChangeOrderFields}
`;

export const useCreateItemIntegrationObjectMutation = (
  options?: CreateItemIntegrationObjectMutationOptions
) => {
  const [mutationFunc, mutationResult] = useMutation<
    CreateItemIntegrationObjectMutation,
    CreateItemIntegrationObjectMutationVariables
  >(createItemIntegrationObjectMutation, options);
  return [
    (
      sourceSystem: SourceSystem,
      sourceID: UUID,
      sourceProjectID: string,
      itemID: UUID,
      input: CreateItemIntegrationObjectInput,
      sourceCompanyID?: string
    ) => {
      return mutationFunc({
        variables: { sourceSystem, sourceID, sourceProjectID, sourceCompanyID, itemID, input },
        refetchQueries: REFETCH_ITEM_INTEGRATIONS,
      });
    },
    mutationResult,
  ] as const;
};
