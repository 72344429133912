import { ScaleLinear, ScalePoint, ScaleTime } from 'd3-scale';
import { ReactNode, useRef, useState } from 'react';
import { Placement } from 'react-aria';

import TimelineCircle from '../TimelineCircle';
import TransparentOverlay from '../TimelineMultiTooltip/TransparentOverlay';

type Data = { date: Date; value: number };

const DEFAULT_HOVER_SIZE = 10;

export default function CostTooltip(props: {
  data: Data;
  fill: string;
  hoverSize?: number;
  isOpen?: boolean;
  offset?: number;
  onClick?: () => void;
  onHover?: () => void;
  onLeave?: () => void;
  placement?: Placement;
  pointSize?: number;
  content: ReactNode;
  x: ScaleTime<number, number> | ScalePoint<Date>;
  y: ScaleLinear<number, number>;
}) {
  const ref = useRef<SVGCircleElement>(null);
  const [isOpen, setIsOpen] = useState(props.isOpen);
  if (!props.data) return null;

  const onOpen = () => {
    setIsOpen(true);
    props.onHover?.();
  };

  const onClose = () => {
    setIsOpen(false);
    props.onLeave?.();
  };

  const onClick = () => {
    props.onClick?.();
  };

  const hoverAttrs = {
    cx: props.x(props.data.date),
    cy: props.y(props.data.value),
    opacity: 0,
    r: props.hoverSize ?? DEFAULT_HOVER_SIZE,
    onPointerEnter: onOpen,
    onPointerLeave: onClose,
    onClick,
  };

  return (
    <>
      {(isOpen || props.isOpen) && (
        <TimelineCircle<{ value: number }> {...props} size={props.pointSize} />
      )}
      <circle ref={ref} {...hoverAttrs} />
      {(isOpen || props.isOpen) && (
        <TransparentOverlay isOpen offset={props.offset} placement="top" triggerRef={ref}>
          <div className="m-1 rounded-md border border-border-muted shadow">{props.content}</div>
        </TransparentOverlay>
      )}
    </>
  );
}
