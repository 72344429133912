import { FC, useCallback, useEffect } from 'react';

import { NetworkStatus } from '@apollo/client';

import { usePreviewSettingsVar } from '../../api/apollo/reactiveVars';
import { IS_EXPANDED, VIEW_FILTER } from '../../constants';
import { PermissionResource } from '../../generated/graphql';
import { MountPolicy } from '../../hooks/usePolicyOnFirstMount';
import useProjectPropsQuery from '../../hooks/useProjectPropsQuery';
import usePermissions from '../../utilities/permissions/usePermissions';
import { getItemIdFromUrl, getProjectIdFromUrl } from '../../utilities/url';
import { useCurrentUser } from '../contexts/current-user';
import { useFilterManager } from '../FilterPanel/filterUtils';
import FourOhFour from '../FourOhFour';
import { useItemQuery } from '../Items/hooks/useItemQuery';
import { useItemsListSettings } from '../ItemsList/ItemsListSettingsUtils';
import { useItemsList, useItemsTree } from '../ItemsList/ItemsListUtils';

import ItemDetails from './ItemDetails';
import { getTopNavLinks } from './ItemDetailsUtils';

const ItemDetailsData: FC = () => {
  const loadItemCosts = false;
  const projectId = getProjectIdFromUrl();
  const itemID = getItemIdFromUrl();

  const settingsManager = useItemsListSettings();
  const { settings, persistSettingsChange } = settingsManager;
  const setIsExpanded = useCallback(
    (isExpanded: boolean) => persistSettingsChange(IS_EXPANDED, isExpanded),
    [persistSettingsChange]
  );

  // DATA QUERIES
  const {
    data,
    error,
    loading: itemLoading,
    refetch: refetchItem,
    networkStatus,
  } = useItemQuery(itemID, MountPolicy.SKIP_ON_MOUNT, undefined, {
    onCompleted: (data) => {
      if (data.item && 'options' in data.item && data.item.options.length) {
        setIsExpanded(true);
      }
    },
  });
  const item = data?.item;
  const { name: itemName } = item || {};

  const parentID = item && 'parent' in item ? item.parent : undefined;
  const { data: parentData, loading: parentLoading } = useItemQuery(parentID);
  const parentItem = parentData?.item ?? null;

  // get activeMilestoneId
  const projectQueryResult = useProjectPropsQuery(projectId);
  const projectName =
    projectQueryResult.data.project?.name ?? projectQueryResult.previousData?.project?.name ?? '';

  useEffect(() => {
    if (projectName) document.title = `${projectName} - ${itemName}`;
  }, [projectName, itemName]);

  const { canView, canEdit, canAdd, canDelete, inPreviewMode } = usePermissions({
    trades: item?.categories,
  });

  const userID = useCurrentUser().id;
  const displayUserID = usePreviewSettingsVar().userID ?? userID;
  const didCreateItem = item?.createdBy?.id === displayUserID;
  const isAssignedItem = item?.assignee?.id === displayUserID;
  const canEditItemDetails =
    canEdit(PermissionResource.ITEM_DETAILS) ||
    (canAdd(PermissionResource.ITEM_DETAILS) && (didCreateItem || isAssignedItem));
  const canEditItemStatus = canEdit(PermissionResource.ITEM_STATUS);
  const canEditItemNumber = canEdit(PermissionResource.ITEM_NUMBER);
  const canAddItemComments = canAdd(PermissionResource.ITEM_COMMENTS);
  const canDeleteItemDetails = canDelete(PermissionResource.ITEM_DETAILS) || didCreateItem;
  const canViewItemIntegrations = canView(PermissionResource.ITEM_INTEGRATIONS);
  const canDeleteItemIntegration = canDelete(PermissionResource.ITEM_INTEGRATIONS);
  const filterManager = useFilterManager(settings[VIEW_FILTER], (newValue: string) =>
    persistSettingsChange(VIEW_FILTER, newValue)
  );
  const { itemsTree, isEmpty } = useItemsTree(filterManager, settings, inPreviewMode, true);
  const { itemsList } = useItemsList(filterManager, settings, inPreviewMode, loadItemCosts, true);
  const navigationLinks = !isEmpty
    ? getTopNavLinks(item ?? undefined, itemsTree?.orderedItemIDs, itemsList)
    : undefined;
  const loading = itemLoading || parentLoading;
  if (loading) return null;
  if (error) return <FourOhFour message="Item not found" />;
  if (!item) return null;

  return (
    <ItemDetails
      key={itemID}
      canAddItemComments={canAddItemComments}
      canDeleteItemIntegration={canDeleteItemIntegration}
      canDeprecateItems={canDeleteItemDetails}
      canEditItemDetails={canEditItemDetails}
      canEditItemNumber={canEditItemNumber}
      canEditItemStatus={canEditItemStatus}
      canViewItemIntegrations={canViewItemIntegrations}
      isExpanded={Boolean(settings.isExpanded)}
      item={item}
      loading={networkStatus === NetworkStatus.refetch}
      navigationLinks={navigationLinks}
      parentItem={parentItem as ItemLike}
      refetchItem={refetchItem}
      setIsExpanded={setIsExpanded}
    />
  );
};
export default ItemDetailsData;
