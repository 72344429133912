import { createStyles } from '@material-ui/core';

const inputHeight = 40;
const inputHeightCompact = 35;

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
export default (theme: any) =>
  createStyles({
    chip: {
      background: theme.palette.backgroundGrey,
      borderRadius: theme.border.radius,
      marginRight: 6,
      padding: 4,
      textAlign: 'left',
      whiteSpace: 'pre',
    },
    chipsWrapper: {
      alignItems: 'center',
      display: 'flex',
      fontFamily: theme.typography.fontFamily,
      justifyContent: 'flex-start',
      width: '100%',
      padding: '0 4px',
    },
    containerOuter: {
      minHeight: inputHeight,
      position: 'relative',
    },
    containerOuterCompact: {
      minHeight: inputHeightCompact,
    },
    containerSelect: {
      position: 'absolute',
      top: 0,
      width: '100%',
      zIndex: 999,
    },
    disabled: {
      pointerEvents: 'none',
    },
    empty: {
      color: theme.palette.subtitleGrey,
      paddingLeft: 8, // to line up with other text inputs
    },
    extraCount: {
      paddingLeft: '4px',
      wordBreak: 'initial',
    },
    selectContentButton: {
      ...theme.typography.h3,
      alignItems: 'center',
      background: theme.palette.backgroundWhite,
      border: theme.border.line,
      borderRadius: theme.border.radius,
      boxSizing: 'border-box',
      cursor: 'pointer',
      display: 'flex',
      justifyContent: 'space-between',
      minHeight: inputHeight,
      padding: `4px 0px 4px 4px`,
      textAlign: 'left',
      width: '100%',
      wordBreak: 'break-word',
      '&:focus': {
        border: theme.border.focus,
        outline: theme.border.focus,
      },
    },
    selectContentButtonCompact: {
      minHeight: inputHeightCompact,
      padding: 0,
    },
  });
