import { toastParametersVar } from '../api/apollo/reactiveVars';
import { ToastType } from '../api/gqlEnums';

export const toastPermissions = () => {
  // if we've already set the server error toast, then don't do it again
  // we don't want the toast to flicker between different values
  if (toastParametersVar().type !== ToastType.NONE) return;

  toastParametersVar({
    item: null,
    message: `Sorry, you don't have permissions to do this.  Try again, and if this continues, please contact support@join.build`,
    show: true,
    type: ToastType.SERVER_ERROR,
  });
};

export const toastServerError = () => {
  toastParametersVar({
    item: null,
    message:
      'Something went wrong!  Try again, and if this continues, please contact support@join.build',
    show: true,
    type: ToastType.SERVER_ERROR,
  });
};

export const toastScenarioApplied = (scenarioName: string, milestoneName: string) => {
  toastParametersVar({
    message: `${scenarioName} was applied to ${milestoneName}`,
    show: true,
    type: ToastType.SUCCESS,
  });
};
