import { ProjectCompsCostTableColumnValue } from '../../../../generated/graphql';
import { getFormattedColumnValue } from '../../ProjectCompsCostTable/ProjectCompCostTableUtils';

import { getDisplayStringFromColumn } from './ProjectCompsChartUtils';

type RowProps = {
  label: string;
  value: string | number;
};

const Row = (props: RowProps) => (
  <div className="flex flex-grow justify-between gap-4">
    <div className="type-body1">{props.label}</div>
    <div className="type-number">{props.value}</div>
  </div>
);

type Props = {
  name: string;
  columnValues?: ProjectCompsCostTableColumnValue[];
  selectedUnits?: Unit[];
};

export default function TooltipChartStackedBar(props: Props) {
  return (
    <div className="p-2" data-cy="tooltip">
      <div className="truncate type-heading3">{props.name}</div>
      {props.columnValues?.map((columnValue) => {
        const unit = props.selectedUnits?.find(
          (u) => u.id === columnValue.costTableColumnInput.unitID
        );
        return (
          <Row
            key={columnValue.costTableColumnInput.key}
            label={getDisplayStringFromColumn(
              columnValue.costTableColumnInput.key,
              unit?.abbreviationSingular
            )}
            value={getFormattedColumnValue(
              columnValue.costTableColumnInput.key,
              columnValue.valueNumeric
            )}
          />
        );
      })}
    </div>
  );
}
