export const YellowLightbulb = () => (
  <img
    alt="lightbulb"
    src="https://static-assets.join.build/app-images/Lightbulb.svg"
    style={{
      height: 90,
      width: 50,
    }}
  />
);
