import { useCallback } from 'react';

import { useFeatureFlags } from '../components/contexts/feature-flags';
import { useModuleEntitlements } from '../components/contexts/module-entitlements';
import { SharedRoute } from '../routes/routes';
import usePermissions from '../utilities/permissions/usePermissions';
import { isAccessibleRoute } from '../utilities/routes/isAccessibleRoute';

export const useIsRouteAccessible = (args?: { projectID?: UUID }) => {
  const featureFlags = useFeatureFlags();
  const moduleEntitlements = useModuleEntitlements();
  const { canView, isInitialLoadComplete } = usePermissions({ projectID: args?.projectID });

  const loading = isInitialLoadComplete === false;

  const isAccessible = useCallback(
    (route: SharedRoute) => isAccessibleRoute(featureFlags, moduleEntitlements, canView, route),
    [canView, featureFlags, moduleEntitlements]
  );

  return { isAccessible, loading };
};
