import { Org, OrgNode } from '../../../generated/graphql';
import { TreeEntry } from '../../../utilities/utilities';

export type OrganizationSettings = {
  collapse: UUID[];
  expand: UUID[];
};

export const OrganizationDefaults: OrganizationSettings = {
  collapse: [],
  expand: [],
};

export const getOrgUrlConfig = (
  organizationID: UUID
): [Location, string, OrganizationSettings, string] => [
  window.location,
  'organizations',
  OrganizationDefaults,
  `${organizationID} - Organization - `,
];

export const getUsedLevels = (levels: string[], levelName: string) =>
  (levels ?? []).filter((level) => level !== levelName);

export const getUsedNames = (organizations: Org[], organizationName: string) =>
  (organizations ?? []).map(({ name }) => name).filter((name) => name !== organizationName);

export const getUsedNodeNames = (nodes: TreeEntry<OrgNode>[], nodeName: string) =>
  (nodes ?? []).map(({ name }) => name).filter((name) => name !== nodeName);
