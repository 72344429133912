import { ReactNode, useRef } from 'react';
import { useButton } from 'react-aria';

export default function UploaderLink(props: {
  'data-cy'?: string;
  endAdornment?: ReactNode;
  onClick: () => void;
  label: string;
  startAdornment?: ReactNode;
}) {
  const ref = useRef<HTMLButtonElement>(null);
  const { buttonProps } = useButton(
    {
      onPress: props.onClick,
    },
    ref
  );

  return (
    <button
      {...buttonProps}
      ref={ref}
      className="flex items-center gap-1 type-link type-body2"
      data-cy={props['data-cy']}
    >
      {props.startAdornment && props.startAdornment}
      {props.label}
      {props.endAdornment && props.endAdornment}
    </button>
  );
}
