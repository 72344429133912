import { MutableRefObject } from 'react';
import { AriaListBoxOptions, useListBox } from 'react-aria';
import { ComboBoxState } from 'react-stately';

import ComboBoxOption from './ComboBoxOption';

type Props = {
  listBoxRef: MutableRefObject<null>;
  state: ComboBoxState<object>;
} & AriaListBoxOptions<object>;

export default function ComboBoxList(props: Props) {
  const { listBoxRef, state } = props;
  const { listBoxProps } = useListBox(props, state, listBoxRef);

  return (
    <ul
      {...listBoxProps}
      ref={listBoxRef}
      className="m-0 overflow-hidden bg-background-primary p-0"
    >
      {[...state.collection].map((item) => (
        <ComboBoxOption
          // Set the key for iterable
          key={item.key}
          item={item}
          state={state}
        />
      ))}
    </ul>
  );
}
