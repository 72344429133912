import { ProgramCreationInput, ProgramProjectFieldsFragment } from '../../../generated/graphql';

export const defaultProgramInput = {
  name: '',
  projectIDs: [],
  imageProjectID: '',
  locationDetails: {
    city: '',
    country: '',
    lat: 0,
    lon: 0,
    name: '',
    state: '',
    stateLong: '',
  },
  typeID: '',
};

export const updateProgramInput = (
  projects: ProgramProjectFieldsFragment[],
  newProjectIDs: UUID[],
  input: ProgramCreationInput,
  isEditingDetails: boolean,
  selectedProjects: ProgramProjectFieldsFragment[],
  setInput: (newInput: ProgramCreationInput) => void
) => {
  // if we are setting the first projectID
  // then also set the default project type and location based on the
  // selected project
  const selectedProject = projects?.find((p) => p.id === newProjectIDs[0]);

  const newInput = { ...input };

  // if the user is removing all projectIDs and has not yet started editing program details
  //  then clear all settings
  if (!newProjectIDs.length && !isEditingDetails) {
    setInput({ ...defaultProgramInput });
    return;
  }

  // update the list of projectIDs
  newInput.projectIDs = newProjectIDs;

  // set the default type, location, and program image values if
  // they haven't yet been set
  if (selectedProject && !isEditingDetails && !newInput.typeID)
    newInput.typeID = selectedProject.typeID;
  if (selectedProject && !isEditingDetails && !newInput.locationDetails.name)
    newInput.locationDetails = selectedProject.locationDetails;
  if (selectedProject && !isEditingDetails && !newInput.imageProjectID)
    newInput.imageProjectID = selectedProject.id;

  // if the user has deselected the project they used for the program image
  // then choose a different project or clear out the program image prop
  if (newProjectIDs.length && !selectedProjects.find((p) => p.id === newInput?.imageProjectID)) {
    newInput.imageProjectID = newProjectIDs[0];
  }

  setInput(newInput);
};
