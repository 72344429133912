import { useState } from 'react';

import { ToastType } from '../../../../api/gqlEnums';
import { SourceLinkedProjectQuery, SourceType } from '../../../../generated/graphql';
import { setToast } from '../../../../hooks/useToastParametersLocalQuery';
import { useProjectID } from '../../../../utilities/routes/params';
import {
  useLoadSourceHubProjects,
  useLoadSourceHubRootQuery,
} from '../../../DocumentMagic/FileExplorer/hooks/FileExplorerHooks';
import { sourceSystemInfo } from '../../../DocumentMagic/Files/FilesDialog/FilesDialogUtils';
import { Button, Dialog, DialogContent, StatusBanner } from '../../../scales';

import { useLinkIntegrationsEntityMutation } from './hooks/useLinkIntegrationsEntityMutation';
import IntegrationsSourceCompanyProjectSelect from './IntegrationsSourceCompanyProjectSelect';
import { UserSource, getSourceSystemString } from './IntegrationsUtils';

type Props = {
  linkedProject: SourceLinkedProjectQuery['sourceLinkedProject'];
  onClose: () => void;
  onSignOut: (userSource: UserSource) => void;
  refetchLinkedProjectQuery: () => void;
  userSource: UserSource;
};

export default function IntegrationsEditAccountModal(props: Props) {
  const joinProjectID = useProjectID();

  const [sourceHubID, setSourceHubID] = useState<string | undefined>(
    props.linkedProject?.sourceCompanyID
  );
  const [sourceProjectID, setSourceProjectID] = useState<string | undefined>(
    props.linkedProject?.sourceProjectID
  );

  const [linkIntegrationsEntity] = useLinkIntegrationsEntityMutation({
    // we need to refetch the saved linked Procore project if they update the link
    onCompleted: props.refetchLinkedProjectQuery,
  });

  // Source hubs and projects queries
  const sourceHubsData = useLoadSourceHubRootQuery(
    props.userSource.sourceSystem,
    props.userSource.id
  ).data?.sourceHubRoot;
  const sourceHubs = sourceHubsData?.hubs;
  const sourceProjectsData = useLoadSourceHubProjects(
    props.userSource.sourceSystem,
    props.userSource.id,
    sourceHubID
  ).data?.sourceHubProjects;
  const sourceProjects = sourceProjectsData?.projects;

  const hasQuerySourceError = !!(sourceHubsData?.sourceError || sourceProjectsData?.sourceError);
  const sourceSystemString = getSourceSystemString(props.userSource.sourceSystem);

  return (
    <Dialog
      footerLeft={<Button label="Back" onClick={props.onClose} type="secondary" />}
      footerRight={
        <Button
          isDisabled={
            !sourceHubID ||
            !sourceProjectID ||
            (sourceHubID === props.linkedProject?.sourceCompanyID &&
              sourceProjectID === props.linkedProject?.sourceProjectID)
          }
          label="Update"
          onClick={() => {
            if (props.userSource) {
              linkIntegrationsEntity(
                props.userSource?.sourceSystem,
                SourceType.SOURCE_PROJECT,
                sourceProjectID || '',
                sourceHubID,
                joinProjectID || ''
              );
            }
            setToast(
              { message: `${sourceSystemString} Company and Project successfully linked` },
              ToastType.SUCCESS
            );
            props.onClose();
          }}
          type="primary"
        />
      }
      isOpen
      onClose={props.onClose}
      title="Integrations"
    >
      <DialogContent>
        <div className="flex flex-col items-center gap-6">
          {hasQuerySourceError && (
            <StatusBanner type="error">
              There was an error loading your {sourceSystemString}
              {sourceHubsData?.sourceError ? ' companies' : ' projects'}. Please sign out of your
              {sourceSystemString} account in Join and try again.
            </StatusBanner>
          )}
          {!hasQuerySourceError && (
            <div className="flex flex-col gap-4">
              <div className="flex flex-col gap-1">
                <div className="type-heading3">{`Edit ${sourceSystemString} login`}</div>
                <div>
                  Link your {sourceSystemString} account by selecting the Company and Project that
                  correspond to this Join project
                </div>
              </div>
              <div className="flex items-center justify-between bg-background-1 p-4">
                <div className="flex items-center gap-2">
                  <img
                    alt={props.userSource.sourceSystem}
                    className="m-2 h-12 w-12"
                    src={sourceSystemInfo[props.userSource.sourceSystem].img}
                  />
                  <div className="flex flex-col gap-1">
                    <div className="type-heading3">{sourceSystemString}</div>
                    <div className="type-body2">
                      Signed in as <b>{props.userSource.email}</b>
                    </div>
                  </div>
                </div>
                <Button
                  label="Sign out"
                  onClick={() => {
                    props.onClose();
                    if (props.userSource) {
                      props.onSignOut(props.userSource);
                    }
                  }}
                  type="destructive"
                />
              </div>
              <IntegrationsSourceCompanyProjectSelect
                hubID={sourceHubID}
                projectID={sourceProjectID}
                setCompanyID={(value) => {
                  setSourceProjectID(undefined);
                  setSourceHubID(value);
                }}
                setProjectID={setSourceProjectID}
                sourceHubs={sourceHubs}
                sourceProjects={sourceProjects}
                sourceSystem={props.userSource.sourceSystem}
              />
            </div>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
}
