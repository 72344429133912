import { FC, KeyboardEvent, useRef } from 'react';
import { Link } from 'react-router-dom';

import { JoinProjectRoutes } from '../../../../api/gqlEnums';
import { PermissionResource, UserReportType } from '../../../../generated/graphql';
import usePermissions from '../../../../utilities/permissions/usePermissions';
import { generateSharedPath } from '../../../../utilities/routes/links';
import { useCurrentUser } from '../../../contexts/current-user';
import { useUserReportQuery } from '../../../ReportsTab/ReportHooks';
import { StatusBanner, TextArea, TextInput } from '../../../scales';
import ShadedHelpTip from '../../../shared-widgets/ShadedHelpTip/ShadedHelpTip';
import FollowingActiveMilestoneSelectData from '../FollowingActiveMilestoneSelectData';

import DialogsReportsSharedSelector from './DialogsReportsSharedSelector';
import DialogsReportsTypeSelector from './DialogsReportsTypeSelector';

type DialogReportsContentProps = {
  allowableReportTypes: UserReportType[];
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  cannotShare?: boolean;
  hasDistribution?: boolean;
  isFollowingActiveMilestone?: boolean;
  onSubmit: () => void;
  projectId: string;
  setUserReport: (report: UserReportInput) => void;
  userReport: UserReportInput;
  variant: 'reports-tab' | 'reports-manager';
};

const DialogReportsContent: FC<DialogReportsContentProps> = ({
  allowableReportTypes,
  cannotShare,
  hasDistribution,
  isFollowingActiveMilestone,
  onSubmit,
  projectId,
  setUserReport,
  userReport,
  variant,
}) => {
  // store the initial value of shared to check if it has changed
  const isShared = useRef(userReport.shared);
  const shouldShowDistributionWarning = isShared.current && userReport.shared && hasDistribution;
  const handleKeydown = (event: KeyboardEvent) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      // submit the report if it has a name and a type
      if (userReport.name !== '' && userReport.reportType !== UserReportType.NONE) {
        if (event.target instanceof HTMLElement) {
          event.target.blur();
        }
        onSubmit();
      } else {
        // if the report has an invalid name
        // we need to ignore the new line
        // created by the enter key
        event.preventDefault();
      }
    }
  };

  const { canAdd, canEdit } = usePermissions();
  const userID = useCurrentUser().id;
  const canEditSharedReport = canEdit(PermissionResource.SHARED_USER_REPORTS);
  const canAddSharedReport = canAdd(PermissionResource.SHARED_USER_REPORTS);
  const didCreateReport =
    useUserReportQuery(projectId, userReport.id).data?.userReport.createdBy.id === userID;

  const isEditing = userReport.id;
  const showReportTypes = allowableReportTypes.length > 1;
  const showSharedSelector =
    !cannotShare &&
    (isEditing
      ? canEditSharedReport || (canAddSharedReport && didCreateReport)
      : canAddSharedReport);

  return (
    <div className="flex flex-col gap-6">
      <div>
        <TextInput
          aria-label="report-name"
          data-cy="report-name-text-input"
          label="Report Name *"
          onChange={(value) => {
            setUserReport({ ...userReport, name: value });
          }}
          onKeyDown={handleKeydown}
          placeholder="Name this report..."
          value={userReport.name}
        />
      </div>
      <div>
        <TextArea
          data-cy="report-description-text-area"
          label="Description"
          onChange={(value) => {
            setUserReport({ ...userReport, description: value });
          }}
          onKeyDown={handleKeydown}
          placeholder="Describe this report..."
          value={userReport.description}
        />
      </div>
      {!isEditing && showReportTypes && (
        // can't change the type for an existing report
        <div>
          <div className="pb-1">Report Type</div>
          <DialogsReportsTypeSelector
            allowableReportTypes={allowableReportTypes}
            setUserReport={setUserReport}
            userReport={userReport}
          />
        </div>
      )}
      {showSharedSelector && (
        <div>
          <DialogsReportsSharedSelector setUserReport={setUserReport} userReport={userReport} />
          {shouldShowDistributionWarning && (
            <div className="w-full pt-2">
              <StatusBanner type="warning">
                Changing this report back to &apos;Private to Me&apos; will remove the Scheduled
                Report Distribution
              </StatusBanner>
            </div>
          )}
        </div>
      )}
      {userReport.reportType === UserReportType.USER_REPORT_MSR && (
        <FollowingActiveMilestoneSelectData
          defaultSelectedMilestoneID={
            isEditing && !isFollowingActiveMilestone ? userReport.milestoneID : undefined
          }
          onChange={(milestoneID) => {
            setUserReport({ ...userReport, milestoneID });
          }}
          shouldNavigateOnChange={false}
          shouldNavigateOnClose={variant === 'reports-manager'}
        />
      )}
      {isEditing && variant === 'reports-tab' && (
        <ShadedHelpTip
          shiftLeft
          tip={
            <div className="flex">
              <div className="type-label">
                To edit configuration of the report,
                <Link
                  className="ml-1 mr-1 type-small-link"
                  data-cy="link-report"
                  to={generateSharedPath(JoinProjectRoutes.REPORT_ROUTER, {
                    projectId,
                    reportID: userReport.id,
                  })}
                >
                  click here
                </Link>
                to go to the report view where you can make updates and save the report.
              </div>
            </div>
          }
        />
      )}
    </div>
  );
};

export default DialogReportsContent;
