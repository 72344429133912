import { KeyboardEvent, MouseEvent } from 'react';
import { NavigateFunction, To } from 'react-router-dom';

import { itemSidebarOpenVar } from '../api/apollo/reactiveVars';
import { isCtrlMeta } from '../components/Print/PrintUtils';

export const clickLinkKeyHandler = (
  navigate: NavigateFunction,
  event: MouseEvent | KeyboardEvent | undefined,
  link: string,
  newSearch?: string,
  hash?: string
) => {
  const { location: { search: oldSearch } = { search: '' } } = window;
  let search = newSearch !== undefined ? newSearch : oldSearch;
  if (search && !search.startsWith('?')) search = `?${search}`;
  if (!!event && isCtrlMeta(event)) {
    window.open(`${link}${search}`);
  } else {
    const to: To = { hash, pathname: link, search };
    navigate(to);
  }
};

const multiClickHandler = (e: MouseEvent, onSingleClick: () => void, onMultiClick: () => void) => {
  const clicksNumber = e.detail;
  const isOneClick = clicksNumber === 1;
  if (isOneClick) onSingleClick();
  else onMultiClick();
};

export const clickQuicklinkHandler = (
  e: MouseEvent,
  navigate: NavigateFunction,
  link: string,
  itemNewWindowAnalytics: () => void,
  itemID?: UUID,
  sidebarAnalytics?: () => void,
  linkHash?: string
) => {
  const onSingleClick = () => {
    e.stopPropagation();
    // is shift, open in new tab
    if (isCtrlMeta(e)) {
      itemNewWindowAnalytics();
      window.open(link);
    } else if (itemID) {
      // open sidebar
      itemSidebarOpenVar(itemID);
      if (sidebarAnalytics) sidebarAnalytics();
    } else {
      clickLinkKeyHandler(navigate, e, link, undefined, linkHash);
    }
  };

  const onMultiClick = () => {
    e.stopPropagation();
    // close sidebar
    setTimeout(() => {
      itemSidebarOpenVar(null);
    }, 250);
    // handle opening item details IRT shift
    clickLinkKeyHandler(navigate, e, link);
  };

  if (itemID) {
    multiClickHandler(e, onSingleClick, onMultiClick);
  } else {
    onSingleClick();
  }
};
