import { withStyles } from '../../../../theme/komodo-mui-theme';
import { fromNow } from '../../../../utilities/dates';

import styles from './LastUpdatedCellStyles';

export default withStyles(styles)(
  ({ classes, report }: { classes: Classes<typeof styles>; report: ForecastingReport }) => (
    <div className={classes.lastUpdatedText}>{fromNow(report.updatedAt)}</div>
  )
);
