import { FC } from 'react';

import { CircularProgress } from '@material-ui/core';
import {
  CheckCircleOutline as Accepted,
  AssignmentTurnedInOutlined as Incorporated,
  NotInterested as NotApplicable,
  HelpOutline as Pending,
  HighlightOff as Rejected,
  AccountBalance as Target,
  TrendingDown,
  TrendingFlat,
  TrendingUp,
} from '@material-ui/icons';

import {
  ACCEPTED,
  INCORPORATED,
  NOT_APPLICABLE,
  NOT_CHOSEN,
  PENDING,
  REJECTED,
  TARGET,
} from '../../../constants';
import { withStyles } from '../../../theme/komodo-mui-theme';
import {
  TrendlineLowerBoundDown,
  TrendlineLowerBoundUp,
  TrendlineUpperBoundDown,
  TrendlineUpperBoundUp,
} from '../../Icons/DashboardTrendline';

import ItemsIconsStyles from './ItemsIconsStyles';

export const SMALL = 'small';
export const MEDIUM = 'medium';
export const LARGE = 'large';
export const XLARGE = 'xLarge';
export const HIDDEN = 'hidden';

export const TRENDING_UP = 'trendingUp';
export const TRENDING_FLAT = 'trendingFlat';
export const TRENDING_DOWN = 'trendingDown';
export const TRENDING_PENDING = 'trendingPending';
export const TRENDING_LOWER_BOUND_DOWN = 'TrendlineLowerBoundDown';
export const TRENDING_LOWER_BOUND_UP = 'TrendlineLowerBoundUp';
export const TRENDING_UPPER_BOUND_DOWN = 'TrendlineUpperBoundDown';
export const TRENDING_UPPER_BOUND_UP = 'TrendlineUpperBoundUp';

const getStatusClass = (status: string, classes: ClassesType) => {
  if (!status) return '';
  const statusType = status.toLowerCase() as ClassKey;
  return classes[statusType];
};

const getClassName = (
  status = '',
  variant: ClassKey | undefined,
  classes: ClassesType,
  stateAvailabilityClass: string
) => {
  const variantClass = variant ? classes[variant] : '';
  const statusClass = getStatusClass(status, classes);
  return `${statusClass} ${variantClass} ${stateAvailabilityClass}`;
};

export type ClassKey = keyof ReturnType<typeof ItemsIconsStyles>;
type ClassesType = Classes<typeof ItemsIconsStyles>;

type ItemsIconsMapProps = {
  classes: Classes<typeof ItemsIconsStyles>;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  disableLoading?: boolean;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  notChosen?: boolean;
  stateAvailabilityClass?: string;
  status?: string;
  variant: ClassKey | undefined;
};
const ItemsIconsMap: FC<ItemsIconsMapProps> = ({
  classes,
  disableLoading,
  notChosen,
  stateAvailabilityClass = '',
  status,
  variant,
}) => {
  // comment to include this file in PR, will remove commit

  const className = getClassName(status, variant, classes, stateAvailabilityClass);
  switch (status) {
    case ACCEPTED:
      return <Accepted className={className} data-cy="statusIcon-small" id={ACCEPTED} />;
    case PENDING:
      if (notChosen) {
        return (
          <Rejected
            className={`${className} ${classes.notChosen}`}
            data-cy="statusIcon-small"
            id={PENDING}
          />
        );
      }
      return <Pending className={className} data-cy="statusIcon-small" id={PENDING} />;
    case NOT_CHOSEN:
      return (
        <Rejected
          className={`${className} ${classes.notChosen}`}
          data-cy="statusIcon-small"
          id={PENDING}
        />
      );
    case REJECTED:
      return <Rejected className={className} data-cy="statusIcon-small" id={REJECTED} />;
    case NOT_APPLICABLE:
      return <NotApplicable className={className} data-cy="statusIcon-small" id={NOT_APPLICABLE} />;
    case INCORPORATED:
      return <Incorporated className={className} data-cy="statusIcon-small" id={INCORPORATED} />;
    case TRENDING_UP:
      return <TrendingUp className={className} />;
    case TRENDING_FLAT:
      return <TrendingFlat className={className} />;
    case TRENDING_DOWN:
      return <TrendingDown className={className} />;
    case TRENDING_LOWER_BOUND_DOWN:
      return <TrendlineLowerBoundDown className={className} />;
    case TRENDING_LOWER_BOUND_UP:
      return <TrendlineLowerBoundUp className={className} />;
    case TRENDING_UPPER_BOUND_DOWN:
      return <TrendlineUpperBoundDown className={className} />;
    case TRENDING_UPPER_BOUND_UP:
      return <TrendlineUpperBoundUp className={className} />;
    case TARGET:
      return <Target className={className} />;
    default:
      if (disableLoading) return null;
      return <CircularProgress className={classes.default} size={32} />;
  }
};

/**
 * @deprecated use ItemStatusIcon.tsx for item status icon, or import the individual icon yourself
 * Note: ItemStatusIcon.tsx uses lg=40px, while ItemsIconMap.tsx uses large=32px and xLarge=40px
 * */
export default withStyles(ItemsIconsStyles)(ItemsIconsMap);
