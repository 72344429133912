import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../../theme/komodo-mui-theme';

export default (theme: KomodoTheme) =>
  createStyles({
    container: {
      padding: 8,
      display: 'flex',
      flexDirection: 'column',
      gap: '1em',
    },
    text: {
      ...theme.typography.body1,
      color: theme.palette.primaryGrey,
      fontFamily: theme.typography.fontFamily,
    },
  });
