import { useMutation } from '@apollo/client';
import { getOperationName } from '@apollo/client/utilities';

import { REFETCH_ITEM_HISTORY } from '../../../api/refetchSets';
import {
  ContingencyDrawEventMutationVariables,
  MarkupType,
  MutationContingencyDrawEventArgs,
} from '../../../generated/graphql';
import { milestoneCostReportsQuery } from '../../../hooks/queries';
import { isNonNullable } from '../../../utilities/types';

import { contingencyDrawEventMutation } from './contingencyDrawEventMutation';

export function useContingencyDrawEvent() {
  const [contingencyDrawEvent] = useMutation<
    MutationContingencyDrawEventArgs,
    ContingencyDrawEventMutationVariables
  >(contingencyDrawEventMutation);
  return (
    projectID: UUID,
    estimateID: UUID,
    itemID: UUID,
    drawFromID: UUID,
    value: number,
    isFirst: boolean,
    markupType: MarkupType,
    currentValue: Numeric,
    onSuccess?: () => void,
    analytics?: () => void
  ) => {
    return contingencyDrawEvent({
      variables: {
        projectID,
        estimateID,
        itemID,
        drawFromID,
        value,
        isFirst,
        markupType,
        currentValue,
      },
      refetchQueries: [...REFETCH_ITEM_HISTORY, getOperationName(milestoneCostReportsQuery)].filter(
        isNonNullable
      ),
    }).then(() => {
      if (onSuccess) onSuccess();
      if (analytics) analytics();
    });
  };
}
