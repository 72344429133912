import { FC } from 'react';

import { Badge, Typography } from '@material-ui/core';
import { InsertDriveFileOutlined } from '@material-ui/icons';

import { withStyles } from '../../../../../theme/komodo-mui-theme';

import styles from './FileWithBadgeIconStyles';
import { getFileTypeColor } from './FileWithBadgeIconUtils';

type FileWithBadgeIconProps = {
  classes: Classes<typeof styles>;
  extension: string | null | undefined;
};

const FileWithBadgeIcon: FC<FileWithBadgeIconProps> = ({ classes, extension }) => {
  const color = getFileTypeColor(extension || '');
  const badge = extension && (
    <Typography className={classes.text} style={{ backgroundColor: color }}>
      {extension}
    </Typography>
  );
  return (
    <Badge badgeContent={badge} classes={{ badge: classes.badge }}>
      <InsertDriveFileOutlined className={classes.fileIcon} style={{ color }} />
    </Badge>
  );
};

export default withStyles(styles)(FileWithBadgeIcon);
