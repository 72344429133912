import { useEffect } from 'react';

import { useQuery } from '@apollo/client';

import { HubspotTokenQuery } from '../../../generated/graphql';
import { useCurrentUser } from '../../contexts/current-user';

import { hubspotTokenQuery } from './queries';

export default function useInitHubspotToken() {
  const identificationEmail = useCurrentUser().email;

  const identificationToken = useQuery<HubspotTokenQuery>(hubspotTokenQuery, {
    skip: !identificationEmail,
  }).data?.hubspotToken;

  useEffect(() => {
    if (identificationToken && identificationEmail) {
      window.hsConversationsSettings = {
        identificationEmail,
        identificationToken,
      };
    }
  }, [identificationToken, identificationEmail]);
}
