import { createStyles } from '@material-ui/core/styles';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';

const ItemCategoriesStyles = (theme: KomodoTheme) =>
  createStyles({
    category: {
      '&:focus': {
        border: theme.border.focus,
        outline: theme.border.focus,
      },
      ...theme.typography.h4,
      border: `1px solid ${theme.palette.shadedGrey}`,
      borderRadius: theme.border.radius,
      color: theme.palette.shadedGrey,
      display: 'block',
      fontFamily: theme.typography.fontFamily,
      lineHeight: '18px',
      margin: '0px 8px 2px 0px',
      maxWidth: '160px',
      overflow: 'hidden',
      padding: '0px 4px',
      textAlign: 'left',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    categoryChips: {
      display: 'inline-flex',
      width: '100%',
      flexWrap: 'wrap',
    },
  });

export default ItemCategoriesStyles;
