import {
  GetMilestoneItemCountQuery,
  GetMilestoneItemCountQueryVariables,
  GetMilestoneQuery,
  GetMilestoneQueryVariables,
  GetSourceMilestoneQuery,
  GetSourceMilestoneQueryVariables,
} from '../../../generated/graphql';
import { useQuery } from '../../../hooks/useMountAwareQuery';
import { MountPolicy } from '../../../hooks/usePolicyOnFirstMount';

import { getMilestoneItemCountQuery, getMilestoneQuery, getSourceMilestoneQuery } from './queries';

export const useMilestoneQuery = (milestoneId: UUID | undefined, mountPolicy?: MountPolicy) =>
  useQuery<GetMilestoneQuery, GetMilestoneQueryVariables>(
    getMilestoneQuery,
    {
      variables: { id: milestoneId },
      skip: !milestoneId,
    },
    mountPolicy
  );

export const useSourceMilestoneQuery = (milestoneId?: UUID | null, mountPolicy?: MountPolicy) =>
  useQuery<GetSourceMilestoneQuery, GetSourceMilestoneQueryVariables>(
    getSourceMilestoneQuery,
    {
      variables: { id: milestoneId },
      skip: !milestoneId,
    },
    mountPolicy
  );

export const useMilestoneItemCountQuery = (milestoneId?: UUID) =>
  useQuery<GetMilestoneItemCountQuery, GetMilestoneItemCountQueryVariables>(
    getMilestoneItemCountQuery,
    {
      variables: { id: milestoneId },
      skip: !milestoneId,
    }
  );
