import { MASTERFORMAT_CATEGORY, UNIFORMAT_CATEGORY } from '../../../../constants';
import { hasBuiltInMfName, hasBuiltInUfName } from '../../utils';

export const computeCategorizationMap = (
  categorizationsNew: DraftCategorization[],
  categorizationsProject: Categorization[],
  isUniformatEnabled = false,
  isMasterformatDisabled = false
): Map<string, string> => {
  const map = new Map<string, string>();
  categorizationsNew.forEach(({ name }) => {
    let pName = '';
    if (hasBuiltInMfName(name) && isMasterformatDisabled) {
      pName = MASTERFORMAT_CATEGORY;
    } else if (hasBuiltInUfName(name) && isUniformatEnabled) {
      pName = UNIFORMAT_CATEGORY;
    } else {
      const cP = categorizationsProject.find(({ name: cName }) => cName === name);
      pName = cP?.name ?? '';
    }

    map.set(name, pName);
  });
  return map;
};

export const addMissingCategoryMap = (
  errorsMap: Map<string, number[]>,
  categoryNumber: string,
  categoryUpdates?: CategoryUpdates
) => {
  const errorsLength = errorsMap.size;
  if (
    categoryUpdates &&
    (categoryUpdates.categoryReplacements.length || categoryUpdates.newCategories.length)
  ) {
    // check if the user has already decided to add this missing category to the project
    // or to replace this category with an existing cateogry
    if (
      !categoryUpdates.newCategories.find((n) => n === categoryNumber) &&
      !categoryUpdates?.categoryReplacements.find((r) => r.original === categoryNumber)
    )
      errorsMap.set(categoryNumber, []);
  } else {
    errorsMap.set(categoryNumber, []);
  }
  // if the length of the errors map is different return true
  return errorsLength === errorsMap.size;
};

export const getErrorsMap = (
  draftCategorizations: DraftCategorization[],
  mapping: Map<string, string>,
  mappingBuiltIn: Map<string, Level>,
  existingCategorizationName: string,
  draftCategorization?: DraftCategorization,
  multilevelCategorizations?: DraftCategorization[]
): [Map<string, number[]>, boolean, boolean, number] => {
  const errorsMap = new Map<string, number[]>();
  let matchCounts: CategoryMatchInfo[] = [];
  let hasErrors = false;
  let hasResolvedErrors = false;
  let totalResolvedMatches = 0;
  if (draftCategorization) {
    if (mappingBuiltIn.has(draftCategorization.name)) {
      mappingBuiltIn.forEach((level, k) => {
        if (level.builtIn !== existingCategorizationName) return;
        const count = draftCategorizations
          .find((d) => d.name === k)
          ?.categoryMatchCounts.get(level.builtIn);
        if (count) matchCounts.push(count);
      });
    } else if (mapping.has(draftCategorization.name)) {
      const count = draftCategorization.categoryMatchCounts.get(existingCategorizationName);
      if (count) matchCounts = [count];
    }
    matchCounts.forEach((count) => {
      if (count.unmatched) hasErrors = true;
      count?.missingCategories?.forEach((missingCategory) => {
        const resolved = addMissingCategoryMap(
          errorsMap,
          missingCategory,
          draftCategorization?.categoryUpdates
        );
        if (resolved) {
          hasResolvedErrors = true;
          totalResolvedMatches += 1;
        }
      });
    });
  }

  multilevelCategorizations?.forEach((categorization) => {
    const count = categorization.categoryMatchCounts.get(existingCategorizationName);
    if (count?.unmatched) hasErrors = true;
    count?.missingCategories?.forEach((missingCategory) => {
      const resolved = addMissingCategoryMap(
        errorsMap,
        missingCategory,
        categorization.categoryUpdates
      );
      if (resolved && (categorization.categoryUpdates?.categoryReplacements?.length ?? 0) > 0) {
        hasResolvedErrors = true;
        totalResolvedMatches += 1;
      }
    });
  });

  return [errorsMap, hasErrors, hasResolvedErrors, totalResolvedMatches];
};
