import { FC } from 'react';

import { Typography } from '@material-ui/core';

import { withStyles } from '../../../theme/komodo-mui-theme';
import {
  ProjectInfoBarChartData,
  ProjectMap,
  dueDateChartColors,
  dueDateChartPlaceholderColor,
} from '../../ExecutiveDashboard/ExecutiveDashboardUtils';

import ChartsHorizontalBarGraphStyles from './ChartsHorizontalBarGraphStyles';
import ChartsSingleHorizontalBarGraph from './ChartsSingleHorizontalBarGraph';

type ChartsHorizontalBarGraphProps = {
  classes: Classes<typeof ChartsHorizontalBarGraphStyles>;
  data: ProjectInfoBarChartData[];
  formatValue?: (value: number) => string;
  maxValue: number;
  maxBarLength: number;
  projectMap?: ProjectMap;
  selected: UUID | null;
  setSelected: (id: string | null) => void;
};

const ChartsHorizontalBarGraph: FC<ChartsHorizontalBarGraphProps> = ({
  classes,
  data,
  formatValue = (value: number) => value.toString(),
  maxValue,
  maxBarLength,
  projectMap,
  selected,
  setSelected,
}) => {
  if (!data || !data.length)
    return <Typography className={classes.placeholderText}>No Data</Typography>;

  return (
    <span>
      {data.map((d, i) => (
        <div key={`${d.project.id}-${i.toString()}-open-items-chart`} className={classes.row}>
          <ChartsSingleHorizontalBarGraph
            colors={!d.isPlaceholder ? dueDateChartColors : [dueDateChartPlaceholderColor]}
            data={d}
            formatValue={formatValue}
            labelLocation={i > 6 ? 26 : -26} // put the label either below the bar or above it depending on if we're in the top 1/2 or lower 1/2
            maxBarLength={
              !d.isPlaceholder ? Math.floor((d.total / maxValue) * maxBarLength) : maxBarLength
            }
            projectMap={projectMap}
            selected={selected}
            setSelected={setSelected}
          />
        </div>
      ))}
    </span>
  );
};

export default withStyles(ChartsHorizontalBarGraphStyles)(ChartsHorizontalBarGraph);
