import { FC } from 'react';

import { withStyles } from '../../../../theme/komodo-mui-theme';
import { calcMaxViewerHeight } from '../../utils';

import AssetsImageViewerStyles from './AssetsImageViewerStyles';

type AssetsImageViewerProps = {
  blobURL: string;
  classes: Classes<typeof AssetsImageViewerStyles>;
};

const AssetsImageViewer: FC<AssetsImageViewerProps> = ({ blobURL, classes }) => (
  <img
    alt="Asset"
    className={classes.thumbnail}
    src={blobURL ?? undefined}
    style={{ maxHeight: calcMaxViewerHeight(window.innerHeight) }}
  />
);

export default withStyles(AssetsImageViewerStyles)(AssetsImageViewer);
