import { FC, useEffect, useState } from 'react';

import { ButtonBase } from '@material-ui/core';

import { BULK_EDIT, PREVIEW_ROLE } from '../../../constants';
import { withStyles } from '../../../theme/komodo-mui-theme';
import AssigneeChip from '../../Collaborators/AssigneeChip';

import styles from './InputsSelectAssigneeStyles';
import { getCollaboratorEmail } from './InputsSelectAssigneeUtils';
import ItemAssigneeSelect from './ItemAssigneeSelect';

type Props = {
  classes: Classes<typeof styles>;
  defaultLabel?: string;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  disabled: boolean;
  onChange: (email: string | undefined) => void;
  projectId: UUID;
  selectedCollaborator?: Collaborator | undefined;
  isDraft?: boolean;
  isReset?: boolean;
  itemID?: UUID;
  variant?: typeof BULK_EDIT | typeof PREVIEW_ROLE;
};

const InputsSelectAssignee: FC<Props> = (props) => {
  const {
    classes,
    defaultLabel,
    disabled,
    projectId,
    selectedCollaborator,
    isDraft,
    isReset = false,
    itemID,
    variant,
  } = props;

  const [label, setDefaultLabel] = useState(defaultLabel);

  // this local state can be removed when we move to apollo optimistic responses for setAssignee
  const [localSelected, setLocalSelected] = useState<Collaborator | undefined>(
    selectedCollaborator
  );

  // Automatically come back to uncategorized selection once the state in the component is cleared
  const selected = getCollaboratorEmail(selectedCollaborator ?? null);
  useEffect(() => {
    if (localSelected !== undefined) setLocalSelected(localSelected);
  }, [selected, localSelected]);

  const onChange = (collaborator: Collaborator | { user: null }) => {
    const email = getCollaboratorEmail(collaborator);
    if (email) {
      setLocalSelected(collaborator as Collaborator);
      props.onChange(email);
    } else {
      setLocalSelected(undefined);
      props.onChange(undefined);
      setDefaultLabel(undefined);
    }
  };

  if (disabled) {
    return (
      <ButtonBase
        aria-label="toggle menu"
        className={classes.button}
        disabled={disabled}
        disableRipple
      >
        <AssigneeChip assignee={(localSelected || {}).user} defaultLabel={defaultLabel} />
      </ButtonBase>
    );
  }

  return (
    <ItemAssigneeSelect
      isDraft={isDraft}
      isReset={isReset}
      itemID={itemID}
      label={label}
      onChange={onChange}
      projectId={projectId}
      selectedCollaborator={selectedCollaborator}
      variant={variant}
    />
  );
};

export default withStyles(styles)(InputsSelectAssignee);
