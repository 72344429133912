import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../theme/komodo-mui-theme';

export default (theme: KomodoTheme) =>
  createStyles({
    listItem: {
      borderTop: theme.border.line,
      height: 62,
      display: 'inline-flex',
      flexDirection: 'row',
      justifyContent: 'space-around',
    },
    term: {
      flex: '0 0 220px',
      paddingRight: 24,
    },
    description: {
      textAlign: 'left',
      flex: '1 1 auto',
      paddingRight: 24,
    },
    button: {
      flex: '0 0 220px',
      backgroundColor: theme.palette.joinPrimary,
      borderRadius: 20,
      color: 'white',
    },
    paddedIcon: {
      marginRight: 10,
      height: 20,
      width: 20,
    },
  });
