import { getTextColor } from '../../../../utilities/colors';

import { placeLabels } from './utils';

type Data = { date: Date; value: number };

type Props = {
  backgroundColor: string[];
  data: Data[];
  content: string[];
  yDomain: [number, number];
};

const MIN_GAP = 1;
const VIEW_HEIGHT = 24;
const PLOT_HEIGHT = 72;
const TOOLTIP_HEIGHT = PLOT_HEIGHT + VIEW_HEIGHT * 3 - VIEW_HEIGHT / 2;

export default function TimelineMultiTooltipContent(props: Props) {
  const min = props.yDomain[0];
  const max = props.yDomain[1];
  const local = props.data.map((data, i) => ({
    data,
    backgroundColor: props.backgroundColor[i],
    content: props.content[i],
  }));

  const graphValuesCosts = props.data.map((d) => d.value);
  const labelPositions = placeLabels(PLOT_HEIGHT, VIEW_HEIGHT, graphValuesCosts, MIN_GAP, min, max);
  return (
    <div className="relative" style={{ height: TOOLTIP_HEIGHT, width: 100 }}>
      {local
        .sort((a, b) => b.data.value - a.data.value)
        .map((l, i) => {
          const backgroundColor = l.backgroundColor;
          const content = l.content;
          const textClassName = getTextColor(backgroundColor);
          const top = labelPositions[i].labelTop;
          return (
            <div
              key={`${i.toString()}-${content}`}
              className={[
                'absolute max-w-[400px] overflow-hidden rounded-md border-border-muted',
                'px-1 py-0',
                textClassName,
              ].join(' ')}
              style={{
                backgroundColor,
                top,
              }}
            >
              {content}
            </div>
          );
        })}
    </div>
  );
}
