import {
  InsightsInput,
  InsightsOrgsInput,
  InsightsV2ProjectCountsQuery,
  InsightsV2ProjectCountsQueryVariables,
} from '../../../generated/graphql';
import { useQuery } from '../../../hooks/useMountAwareQuery';

import { insightsV2ProjectCountsQuery } from './queries';

export const useInsightsV2ProjectCountsQuery = (
  input: InsightsInput,
  insightsOrgsInput: InsightsOrgsInput[]
) => {
  const counts = useQuery<InsightsV2ProjectCountsQuery, InsightsV2ProjectCountsQueryVariables>(
    insightsV2ProjectCountsQuery,
    {
      variables: { input, insightsOrgsInput },
      skip: !input.companyID,
    }
  )?.data?.insightsV2ProjectCounts;
  return counts;
};
