import { FC, useEffect, useRef, useState } from 'react';
import * as React from 'react';

import { FormulaDisplayType } from '../../../generated/graphql';
import useFocusHandler from '../hooks/useFocusHandler';
import { EditorPosition, GridController, KeyBufferState, Position } from '../types';

import FormulaCellEditorInput from './FormulaCellEditorInput';

interface FormulaEditorProps {
  grid: GridController;
  defaultValue: RegularCell;
  updateCell: (position: Position, newValue: string) => void;
  position: EditorPosition;
  stopEditing: () => void;
  editingCell: Position;
}

const FormulaEditor: FC<FormulaEditorProps> = ({
  grid,
  updateCell,
  defaultValue,
  stopEditing,
  editingCell,
  position,
}) => {
  const [formulas, setFormulas] = useState(defaultValue.formulaDisplay);
  const previousFocusedElement = document.activeElement;
  const [focused, setFocused] = useState(0);
  const [cursorLocation, setCursorLocation] = useState(1);
  const refs = useRef<HTMLInputElement[]>([]);

  const focusedRef = useRef(
    refs.current && refs.current.length > focused ? refs.current[focused] : null
  );

  // when we are no longer focused on the editor
  // send the updates to the backend
  useFocusHandler(
    focusedRef,
    previousFocusedElement,
    () => {
      // eslint-disable-next-line no-param-reassign
      grid.isRenderingEditor = KeyBufferState.CLEAR;
    },
    () => {
      if (refs.current && grid.isRenderingEditor === KeyBufferState.CLEAR) {
        // concatenate the values from all input objects
        const values = refs.current.map((r) => r.value);
        updateCell(
          editingCell,
          values
            .filter((v) => !!v)
            .join('')
            .trim()
        );
      }
    }
  );

  // when pressing some keys (left / rigth arrow + delete)
  // prevent the the grid from switching focus to the
  // adjacent cells or deleting the contents of the entire cell
  // which are the default key behaviors
  const handleKey = (event: React.KeyboardEvent<HTMLElement>) => {
    const isEscape = event.key === 'Escape';
    if (isEscape || (!event.shiftKey && (event.key === 'Enter' || event.key === 'Tab'))) {
      stopEditing();
      if (isEscape) {
        event.stopPropagation();
      }
    }
    if (
      event.key === 'Backspace' ||
      event.key === 'Delete' ||
      event.key === 'ArrowLeft' ||
      event.key === 'ArrowRight'
    ) {
      event.stopPropagation();
    }
  };

  // we need to track some key behavior specific to the formula editor
  const onKeyDown = (
    event: React.KeyboardEvent<HTMLElement>,
    form: FormulaDisplay,
    index: number
  ) => {
    const { key } = event;
    const { type } = form;
    const value = formulas[index].text;
    const currentRef = refs.current[index];
    // this shouldn't happen, but it maybe could if
    // we're in the middle of reconstructing
    // the list of formulas
    if (!currentRef) {
      return;
    }

    // the formula editor is made up of a list of inputs
    // the user needs to be able to use the keyboard to navigate
    // between adjacent inputs, which happens here
    if (
      key === 'ArrowLeft' &&
      currentRef.selectionStart &&
      currentRef.selectionStart <= 0 &&
      index > 0
    ) {
      const prevRef = refs.current[index - 1];
      prevRef.focus();
      prevRef.setSelectionRange(prevRef.value.length, prevRef.value.length);
      event.preventDefault();
      event.stopPropagation();
      // move to the adjacent input box if there is one, and the cursor is at the edge
    } else if (
      key === 'ArrowRight' &&
      currentRef.selectionStart &&
      currentRef.selectionStart >= value.length &&
      index < refs.current.length - 1
    ) {
      const nextRef = refs.current[index + 1];
      nextRef.focus();
      nextRef.setSelectionRange(0, 0);
      event.stopPropagation();
      event.preventDefault();

      // If this is a reference input, and the user has deleted all text
      // then delete this cell reference input object from the list
    } else if (
      (key === 'Backspace' || key === 'Delete') &&
      type === FormulaDisplayType.FORMULA_DISPLAY_REFERENCE &&
      currentRef.value.length === 0 &&
      refs.current.length > 1
    ) {
      removeFormula(index);
    }

    // default handle key function
    handleKey(event);
  };

  const removeFormula = (index: number) => {
    // combine the two elements on either side of this component
    // rather than have two adjacent string formulas
    const newCursorLocation = formulas[index - 1].text.length - 1;
    let spliceCount = 1;
    if (
      refs.current.length > index + 1 &&
      formulas[index].type === FormulaDisplayType.FORMULA_DISPLAY_REFERENCE
    ) {
      spliceCount += 1;
    }
    // splice out the un-needed formulas
    // and update the state
    const newFormula = [...formulas];
    newFormula.splice(index, spliceCount);
    newFormula[index - 1].text += formulas[index + 1].text;

    setFocused(index - 1);
    setCursorLocation(newCursorLocation);
    setFormulas(newFormula);
  };

  // whenever the length of formulas changes
  // ie when a user deletes a reference
  // or when first opening the editor
  // then we need to set which input should be focused
  useEffect(() => {
    refs.current = refs.current.splice(0, formulas.length);
    if (refs.current.length > focused && focused > -1) {
      refs.current[focused].focus();
      refs.current[focused].setSelectionRange(cursorLocation, cursorLocation);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO CT-566: Fix this pls :)
  }, [formulas.length]);

  return (
    <div
      className="join-grid-cell-reference-editor"
      onBlur={(event) => {
        // check if the user has clicked out of the current cell
        // if so, then stop editing
        if (!refs.current.find((r) => r === event.relatedTarget)) {
          stopEditing();
          event.stopPropagation();
          event.preventDefault();
        }
      }}
      style={position}
    >
      <div className="join-grid-cell-reference-editor-container">
        {formulas.map((f: RegularCell['formulaDisplay'][number], i: number) => (
          <FormulaCellEditorInput
            // eslint-disable-next-line react/no-array-index-key
            key={`${f.type}:${i}`}
            formulas={formulas}
            index={i}
            onKeyDown={onKeyDown}
            refs={refs}
            setFocused={setFocused}
            setFormulas={setFormulas}
          />
        ))}
      </div>
    </div>
  );
};

export default FormulaEditor;
