import { useMemo } from 'react';
import { isUUID } from 'validator';

import {
  MASTERFORMAT_CATEGORIZATION_ID,
  UNCATEGORIZED_ID,
  UNIFORMAT_CATEGORIZATION_ID,
} from '../../constants';
import { PermissionResource } from '../../generated/graphql';
import { useCustomCategorizations } from '../../hooks/useCustomCategorizations';
import { MountPolicy } from '../../hooks/usePolicyOnFirstMount';
import {
  getCategorizationsForProjectFromQueryData,
  useProjectCategorizationsQuery,
} from '../../hooks/useProjectCategorizationsQuery';
import truncateLabel from '../../utilities/dashboard';
import usePermissions from '../../utilities/permissions/usePermissions';
import { pluralizeString, removeYear } from '../../utilities/string';
import { getProjectIdFromUrl } from '../../utilities/url';
import { selectCategoryDisplayName } from '../Select/SelectCategory/SelectCategoryUtils';

export type CategorizationCategoryMap = Record<string, Category[]>;

const getActiveFilterCategorizations = (
  filters: CategorizationCategoryMap | undefined,
  enabledCategorizationIDs?: UUID[]
): UUID[] => {
  if (!filters) return [];
  let filteredCategorizationIDs = Object.keys(filters);
  if (enabledCategorizationIDs) {
    filteredCategorizationIDs = filteredCategorizationIDs.filter((id) =>
      enabledCategorizationIDs.includes(id)
    );
  }
  return filteredCategorizationIDs.filter(
    (categorizationID) => filters[categorizationID].length > 0
  );
};

export const getNumFilters = (
  filters: CategorizationCategoryMap,
  enabledCategorizationIDs?: UUID[]
): number => getActiveFilterCategorizations(filters, enabledCategorizationIDs).length;

export const useProjectCategorizationsFilterCount = (
  filters: CategorizationCategoryMap,
  projectID: UUID
) => {
  const { data } = useProjectCategorizationsQuery(projectID, false);
  const enabledCategorizationIDs = getCategorizationsForProjectFromQueryData(data).map(
    ({ id }) => id
  );
  return getNumFilters(filters, enabledCategorizationIDs);
};

export const isFiltered = (filters: CategorizationCategoryMap) => getNumFilters(filters) > 0;

export const getQueryObjectFromCategorizationCategoryMap = (
  viewFilterMap: CategorizationCategoryMap
): ViewFilterInput => {
  const categorizationIDs = getActiveFilterCategorizations(viewFilterMap);
  const categories: Omit<CategoryInput, 'number'>[] = [];
  const uncategorizedCategorizationIDs: string[] = [];
  const filterObject: ViewFilterInput = {};
  categorizationIDs.forEach((categorizationID) => {
    viewFilterMap[categorizationID].forEach((category) => {
      if (category.id === UNCATEGORIZED_ID) {
        uncategorizedCategorizationIDs.push(categorizationID);
      } else {
        categories.push({
          categorizationID,
          id: category.id,
        });
      }
    });
  });
  if (categories.length > 0) filterObject.categories = categories;
  if (uncategorizedCategorizationIDs.length > 0)
    filterObject.uncategorizedCategorizationIDs = uncategorizedCategorizationIDs;
  return filterObject;
};

export const stripCategories = (categories: Category[]): Category[] =>
  categories.map((category) => {
    const { id, name, number, level } = category;
    return { id, name, number, level } as Category;
  });

export const hasActiveFilter = (filter: ViewFilterInput): boolean => {
  if (!filter) return false;
  const { categories, uncategorizedCategorizationIDs } = filter;
  const hasCategories = (categories && categories.length > 0) || false;
  const hasUncategoriezed =
    (uncategorizedCategorizationIDs && uncategorizedCategorizationIDs.length > 0) || false;
  return hasCategories || hasUncategoriezed;
};

export type AddCategoriesToFilter = (
  categorizationID: UUID,
  newCategories: Category[],
  analytics?: (filters: CategorizationCategoryMap) => void
) => void;

export type FilterSetters = {
  addCategories: AddCategoriesToFilter;
  removeCategories: (categorizationID: UUID, oldCategories: Category[]) => void;
  clearFilters: () => void;
};

export type FilterState = {
  filters: CategorizationCategoryMap;
  filterQueryInput: ViewFilterInput;
};

export type AdditionalFilterManager = {
  // sometimes we have unclearable filters because they come with the
  // page defaults
  canClear: boolean;
  clearFilters: () => void;
  clearFiltersCTA: string;
  filters: JSX.Element;
  numFilters: number;
};

export type FilterManager = FilterSetters & FilterState;

export const validCategory = (category: Category) =>
  category && (category.id === UNCATEGORIZED_ID || isUUID(category.id));

export const useFilterManager = (
  setting: string,
  setSetting: (newSetting: string) => void
): FilterManager => {
  const projectID = getProjectIdFromUrl();

  const { canView } = usePermissions();
  const canViewCategories = canView(PermissionResource.CATEGORIES_AND_TAGS);

  const { data: { customCategorizations = [] } = {} } = useCustomCategorizations(
    {
      variables: {
        projectID,
      },
      skip: !projectID || !canViewCategories,
    },
    MountPolicy.SKIP_ON_MOUNT
  );
  const validCategoryMap = useMemo(() => {
    const returnMap = new Map<UUID, boolean>();
    customCategorizations.forEach((categorization) => {
      if (categorization.content) {
        categorization.content.forEach((category) => returnMap.set(category.id, true));
      }
    });
    return returnMap;
  }, [customCategorizations]);

  const filters = useMemo(() => {
    let parsed: CategorizationCategoryMap;
    // we might be starting with invalid JSON so don't error if it is
    try {
      parsed = JSON.parse(setting);
    } catch (e) {
      parsed = {};
    }
    if (isFiltered(parsed)) {
      getActiveFilterCategorizations(parsed).forEach((categorizationID) => {
        if (
          categorizationID !== UNIFORMAT_CATEGORIZATION_ID &&
          categorizationID !== MASTERFORMAT_CATEGORIZATION_ID
        ) {
          parsed[categorizationID] = parsed[categorizationID].filter((category) =>
            validCategoryMap.get(category.id)
          );
        }
      });
    }
    return parsed;
  }, [setting, validCategoryMap]) as CategorizationCategoryMap;

  const setFilters = (newFilters: CategorizationCategoryMap) =>
    setSetting(JSON.stringify(newFilters));

  const clearFilters = () => {
    if (filters) {
      Object.keys(filters).forEach((key) => delete filters[key]);
    }
    setSetting(JSON.stringify({}));
  };

  const filterQueryInput = getQueryObjectFromCategorizationCategoryMap(filters);

  const addCategories = (
    categorizationID: string,
    newCategories: Category[],
    analytics: (filters: CategorizationCategoryMap) => void = () => null
  ) => {
    const newFilters = filters;
    if (newCategories && newCategories.length > 0) {
      const existingCategories = newFilters[categorizationID] || [];
      stripCategories(newCategories)
        .filter(validCategory)
        .forEach((category) => {
          if (existingCategories.filter((eCat) => eCat.id === category.id).length === 0)
            existingCategories.push(category);
        });
      newFilters[categorizationID] = existingCategories;
    }
    setFilters(newFilters);
    analytics(newFilters);
  };

  const removeCategories = (categorizationID: string, oldCategories: Category[]) => {
    const newFilters = filters;
    if (oldCategories && oldCategories.length > 0) {
      const existingCategories = newFilters[categorizationID] || [];
      const removeIDs = oldCategories.filter(validCategory).map((oCat) => oCat.id);
      newFilters[categorizationID] = existingCategories.filter(
        (eCat) => removeIDs.indexOf(eCat.id) < 0
      );
    }
    setFilters(newFilters);
  };

  return { addCategories, clearFilters, filters, filterQueryInput, removeCategories };
};

export const filterAnalyticsCategoryList = (
  viewFilterMap: CategorizationCategoryMap,
  categorizationsNameMap: Record<UUID, string>
): string[] => {
  const list: string[] = [];
  const categorizationIDs = getActiveFilterCategorizations(viewFilterMap);
  categorizationIDs.forEach((categorizationID) => {
    viewFilterMap[categorizationID].forEach((category) => {
      const categorizationName = removeYear(categorizationsNameMap[categorizationID]);
      const title = `${categorizationName}: ${selectCategoryDisplayName(category)}`;
      list.push(title);
    });
  });
  return list;
};

// this is used in print views to restore filters from settings strings found in the URL. It could get bad inputs
// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
export const restoreFilters = (unsafeFilters: any): FilterState => {
  let filterQueryInput: ViewFilterInput = {};
  let filters: CategorizationCategoryMap = {};
  if (unsafeFilters && typeof unsafeFilters === 'string' && unsafeFilters.length > 2) {
    try {
      filters = JSON.parse(unsafeFilters) as CategorizationCategoryMap;
      filterQueryInput = getQueryObjectFromCategorizationCategoryMap(filters);
    } catch (e) {
      filters = {};
      filterQueryInput = {};
    }
  }
  return { filters, filterQueryInput };
};

export const getCategorizationsNameMap = (
  categorizations: Categorization[]
): Record<UUID, string> => {
  const map: Record<UUID, string> = {};
  if (categorizations && categorizations.length > 0)
    categorizations.forEach((categorization) => {
      map[categorization.id] = categorization.name || '';
    });
  return map;
};

export type SummaryValue = {
  header?: string;
  value: string;
};

export const formatFilterSummary = (
  filters: CategorizationCategoryMap | undefined,
  categorizationsNameMap: Record<UUID, string>,
  maxLineLength?: number,
  maxLines = 2
): SummaryValue[] => {
  const categorizationIDs = getActiveFilterCategorizations(filters);
  if (!filters || categorizationIDs.length === 0) return [];
  const lines: SummaryValue[] = [];
  categorizationIDs.forEach((categorizationID) => {
    if (!categorizationsNameMap[categorizationID]) return;
    const header = truncateLabel(
      removeYear(categorizationsNameMap[categorizationID]),
      28,
      true,
      true
    );
    let done = false;
    const numFilters = filters[categorizationID].length;
    const value = filters[categorizationID]
      .map((category) => {
        const displayName = selectCategoryDisplayName(category);
        return numFilters > 1 && category.number.length > 0 ? category.number : displayName;
      })
      .sort()
      .reduce((acc, name, index, all) => {
        if (done) return acc;
        // start by trying to add to the line
        const isFirst = index === 0;
        const appended = isFirst ? `${name}` : `${acc}, ${name}`;
        // if we're under the limit by variant, use it
        if (!maxLineLength || appended.length <= maxLineLength) return appended;
        // Too long, bail out
        // append # remaining
        done = true;
        const numRemainingCats = all.length - index;
        const noun = pluralizeString('category', numRemainingCats);
        return `${acc} + ${numRemainingCats} ${isFirst ? '' : 'other '}${noun}`;
      }, '');
    lines.push({ header, value });
  });
  if (lines.length <= maxLines) return lines;
  const allowedLines = lines.slice(0, maxLines);
  const numRemaining = lines.length - allowedLines.length;
  return [
    ...allowedLines,
    { value: `+ ${numRemaining} other ${pluralizeString('filter', numRemaining)}` },
  ];
};

export const getHeaderFilterString = (summary: SummaryValue) =>
  `${summary.header ? `${summary.header}: ` : ''}${summary.value}`;
