import { ReactNode, createContext, useContext } from 'react';
import {
  Tab as RACTab,
  TabList as RACTabList,
  TabPanel as RACTabPanel,
  Tabs as RACTabs,
} from 'react-aria-components';
import { NavLink } from 'react-router-dom';

/**
 * For rendering a TabsList and the corresponding TabPanels below it.
 * This handles the visibility of the content based on the selected Tab.
 */
export const Tabs = (props: {
  children: ReactNode;
  'data-cy'?: string;
  defaultValue?: string;
  onChange?: (key: string) => void;
  value?: string;
}) => (
  <RACTabs
    defaultSelectedKey={props.defaultValue}
    onSelectionChange={(key) => {
      // TabProps only accepts a `string` for the `id`, so even though a react-aria `Key`
      // can also be a number, that won't happen in our component.
      if (typeof key === 'string') {
        props.onChange?.(key);
      } else {
        throw new TypeError(`Expected string Tab key, got ${typeof key}`);
      }
    }}
    selectedKey={props.value}
    {...props}
  />
);

export const TabList = (props: {
  children: ReactNode;
  'data-cy'?: string;
  /** Grow child tabs so that the TabList spans the entire container */
  isFullWidth?: boolean;
}) => {
  let tabWidth = '';
  if (props.isFullWidth && Array.isArray(props.children)) {
    tabWidth = `${100 / props.children.length}%`;
  }

  return (
    <TabWidthContext.Provider value={tabWidth}>
      <RACTabList {...props} className="flex border-b text-type-primary type-body1" />
    </TabWidthContext.Provider>
  );
};

export function Tab(props: {
  children: ReactNode;
  'data-cy'?: string;
  id: string;
  isCompact?: boolean;
  isRightAligned?: boolean;
  to?: string;
}) {
  const width = useContext(TabWidthContext);

  const { children, to, isRightAligned, isCompact, ...rest } = props;

  return (
    <RACTab
      {...rest}
      className={`text-center outline-none ${isRightAligned ? 'ml-auto' : ''}`}
      style={{ width }}
    >
      {({ isSelected, isFocusVisible }) => {
        if (to) {
          return (
            <NavLink
              className={({ isActive }) =>
                getTabClassName({
                  isCompact,
                  isFocusVisible,
                  isSelected: isActive,
                })
              }
              to={to}
            >
              {children}
            </NavLink>
          );
        }

        return (
          <div
            className={getTabClassName({
              isCompact,
              isFocusVisible,
              isSelected,
            })}
          >
            {children}
          </div>
        );
      }}
    </RACTab>
  );
}

export const TabPanel = (props: { children: ReactNode; 'data-cy'?: string; id: string }) => (
  <RACTabPanel {...props} className="text-type-primary outline-none" />
);

const TabWidthContext = createContext('');

const getTabClassName = (opts: {
  isSelected?: boolean;
  isFocusVisible?: boolean;
  isCompact?: boolean;
}) => {
  // We always want to render a border so that heights stay consistent.
  let border = opts.isSelected ? 'border-radio-selected' : 'border-transparent';
  if (opts.isFocusVisible) {
    border = 'border-radio-focused';
  }
  return [
    'flex items-center block relative top-px cursor-pointer border-b-4 px-4 text-center truncate',
    border,
    opts.isCompact ? 'type-body2 h-9' : 'type-body1 h-10',
  ].join(' ');
};
