import { useParams } from 'react-router-dom';
import { isUUID } from 'validator';

import { getProjectIdFromUrl } from '../url';

export const useProjectID = () => {
  const { projectId } = useParams<{ projectId: UUID }>();
  if (projectId && isUUID(projectId)) return projectId;
  return getProjectIdFromUrl() || undefined;
};
