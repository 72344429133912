import { MouseEvent } from 'react';
import { useNavigate } from 'react-router-dom';

import { ScheduleImpactType } from '../../api/gqlEnumsBe';
import { withStyles } from '../../theme/komodo-mui-theme';
import { clickQuicklinkHandler } from '../../utilities/clickHandler';
import { getScheduleImpactUnits, getSignedNumberText } from '../../utilities/string';
import NormalTooltip from '../NormalTooltip/NormalTooltip';

import styles from './ScheduleImpactStyles';

export enum ScheduleImpactVariant {
  ITEM_COLLAPSE,
  LIST_OPTION,
  MINIMAL, // minimal view '+1d' or minimal range '0 to +1d', 'TBD' , 'N/A'
}

type ScheduleImpactProps = {
  classes: Classes<typeof styles>;
  variant: ScheduleImpactVariant;
  scheduleImpact: ScheduleImpact;
  tooltip?: boolean;
  link?: string;
};

const UnstyledScheduleImpact = ({
  classes,
  scheduleImpact,
  variant,
  tooltip,
  link,
}: ScheduleImpactProps) => {
  const navigate = useNavigate();

  const unit =
    variant === ScheduleImpactVariant.LIST_OPTION || variant === ScheduleImpactVariant.MINIMAL
      ? ''
      : getScheduleImpactUnits();
  const tbd = variant === ScheduleImpactVariant.MINIMAL ? '-' : 'TBD';
  const [scheduleImpactText, criticalPathText] = getScheduleImpactText(scheduleImpact, {
    unit,
    tbd,
  });
  const [tooltipImpactText] = getScheduleImpactText(scheduleImpact, {
    unit: getScheduleImpactUnits(),
  });

  const tooltipCopy = (
    <p className={classes.tooltip}>
      Schedule impact <br />
      {tooltipImpactText} <br />
      {criticalPathText}
    </p>
  );

  const withTooltip = (element: JSX.Element) => (
    <NormalTooltip title={tooltipCopy}>{element}</NormalTooltip>
  );

  const withButton = (element: JSX.Element) => (
    <button
      className={`${classes.fullWidth} ${classes.root} ${link && classes.link}`}
      onClick={(e: MouseEvent) =>
        link &&
        clickQuicklinkHandler(e, navigate, link, () => {}, undefined, undefined, `#schedule-impact`)
      }
    >
      {element}
    </button>
  );

  const renderTbdOrNA = (text: string) => (
    <div className={classes.descriptionContainer} data-cy="ScheduleImpact-Summary">
      <p className={classes.descriptionHeader}>{text}</p>
    </div>
  );

  const renderImpactListOption = (header: string, description?: string) => (
    <div className={`${classes.fullWidth} ${classes.root}`} data-cy="ScheduleImpact-Summary">
      <p className={classes.number}>{header}</p>
      <p className={classes.totalNumber}>{description}</p>
    </div>
  );

  const renderImpactItemCollapse = (header: string, description: string) => (
    <div className={classes.descriptionContainer} data-cy="ScheduleImpact-Summary">
      <p className={classes.descriptionHeader}>{header}</p>
      <p className={classes.description}>{description}</p>
    </div>
  );

  // Render the component based on the variant
  switch (variant) {
    // Items list
    case ScheduleImpactVariant.MINIMAL: {
      let element = renderImpactListOption(scheduleImpactText);
      element = withButton(element);
      if (tooltip) element = withTooltip(element);
      return element;
    }
    case ScheduleImpactVariant.LIST_OPTION:
      return renderImpactListOption(`Schedule: ${scheduleImpactText}`, criticalPathText);
    // Item details header
    case ScheduleImpactVariant.ITEM_COLLAPSE:
      if (
        scheduleImpact.type === ScheduleImpactType.TBD ||
        scheduleImpact.type === ScheduleImpactType.NA
      ) {
        return renderTbdOrNA(scheduleImpactText);
      }
      return renderImpactItemCollapse(scheduleImpactText, criticalPathText);
    default:
      return renderImpactItemCollapse(scheduleImpactText, criticalPathText);
  }
};

export default withStyles(styles)(UnstyledScheduleImpact);

export const SCHEDULE_IMPACT_DEFAULT: ScheduleImpact = {
  type: ScheduleImpactType.TBD,
  criticalPath: false,
  days: 0,
  minImpact: null,
  maxImpact: null,
};

type ScheduleImpactTextOptions = Record<'unit' | 'tbd' | 'na' | 'critical' | 'noncritical', string>;

export const getScheduleImpactText = (
  si: ScheduleImpact,
  options?: Partial<ScheduleImpactTextOptions>
): [string, string] => {
  const terms = {
    unit: '',
    tbd: 'TBD',
    na: '-',
    critical: 'critical path: yes',
    noncritical: 'critical path: no',
    ...options,
  };
  if (!si.minImpact && !si.maxImpact) {
    return [
      getScheduleImpactNumberText(si.type, si.days, terms),
      `${si.criticalPath ? terms.critical : terms.noncritical}`,
    ];
  }
  if (si.minImpact && si.minImpact === si.maxImpact) {
    return [getScheduleImpactNumberText(si.minImpact.type, si.minImpact.days, terms), ''];
  }
  return ['See options', ''];
};

const getScheduleImpactNumberText = (
  type: ScheduleImpactType,
  days: number,
  terms: ScheduleImpactTextOptions
) => {
  if (type === ScheduleImpactType.TBD) return terms.tbd;
  if (type === ScheduleImpactType.NA) return terms.na;
  const numberText = getSignedNumberText(days);
  if (!terms.unit) return `${numberText}d`;
  return `${numberText} ${terms.unit}`;
};
