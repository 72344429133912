import { CityCostIndicesQuery, CityCostIndicesQueryVariables } from '../../../../generated/graphql';
import { useQuery } from '../../../../hooks/useMountAwareQuery';

import { cityCostIndicesQuery } from './queries';

export const useCityCostIndicesQuery = () =>
  useQuery<CityCostIndicesQuery, CityCostIndicesQueryVariables>(cityCostIndicesQuery, {
    variables: {
      pagination: {
        limit: -1,
        offset: 0,
      },
    },
  });
