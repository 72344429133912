import { FC } from 'react';

import { FormControl } from '@material-ui/core';

import { withStyles } from '../../../theme/komodo-mui-theme';
import { calculateNumberOfFiltersToShow } from '../../ProjectsList/ProjectsListUtils';
import PanelSelectHeader from '../../Select/PanelSelectHeader/PanelSelectHeader';
import SelectFilterChipInput from '../../Select/SelectFilter/SelectFilterChipInput';

import styles from './FilterSelectStyles';

type FilterSelectProps = {
  allSelectedText?: string;
  classes: Classes<typeof styles>;
  clearAllText?: string;
  clearFilter: () => void;
  cy: string;
  formatValueForDisplay?: (value: string) => string;
  onSelect: (value: string) => void;
  options: string[];
  selectAll?: () => void;
  selected: string[];
  title: string;
  tooltip: string[];
};

const FilterSelect: FC<FilterSelectProps> = ({
  allSelectedText,
  classes,
  clearAllText = 'clear',
  clearFilter,
  cy,
  formatValueForDisplay,
  onSelect,
  options,
  selectAll,
  selected,
  title,
  tooltip,
}) => {
  if (!title) return null;

  return (
    <div
      className={
        selected.length !== options.length && selected.length > 0
          ? `${classes.outerContainer} ${classes.activeContainer}`
          : `${classes.outerContainer}`
      }
    >
      <PanelSelectHeader
        clearAllText={clearAllText}
        clearFilter={clearFilter}
        selectAll={selected.length < options.length ? selectAll : undefined}
        showRemove={selected.length > 0}
        title={title}
      />
      <div className={classes.selectContainer}>
        <FormControl
          classes={{
            root: classes.fullWidth,
          }}
        >
          <SelectFilterChipInput
            allSelectedText={allSelectedText}
            cy={cy}
            formatTooltip={false}
            formatValueForDisplay={formatValueForDisplay}
            hideAllChipsSelected
            maxChipsShown={calculateNumberOfFiltersToShow(selected)}
            onSelect={onSelect}
            options={options}
            selected={selected}
            title={title}
            tooltip={tooltip}
          />
        </FormControl>
      </div>
    </div>
  );
};

export default withStyles(styles)(FilterSelect);
