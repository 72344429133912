import { getSelectedText } from '../utils';

type Props = {
  sharedUsers: Pick<User, 'id' | 'name'>[];
  hasPrivateParent: boolean;
  noun?: string;
};

export default function SharedUsersTooltipContent(props: Props) {
  const sharedUserCount = props.sharedUsers.length;
  const renderSharedUsers = (user: Pick<User, 'id' | 'name'>) => (
    <div key={user.id} className="type-table-text">
      {user.name}
    </div>
  );

  if (props.hasPrivateParent) {
    return (
      <div className="type-table-text">
        Options of draft items inherit share settings from the parent item.
      </div>
    );
  }

  if (sharedUserCount >= 2) {
    return (
      <div>
        <div className="type-table-header" data-cy="shared-user-tooltip">{`${
          getSelectedText(
            props.sharedUsers.map((u) => u.id),
            [],
            [],
            props.noun
          ).selectedText
        }:`}</div>
        {sharedUserCount > 10 ? (
          <div className="type-table-text">
            <div>{props.sharedUsers.map(renderSharedUsers).slice(0, 10)}</div>
            <div>{`+ ${sharedUserCount - 10} more`}</div>
          </div>
        ) : (
          <div>{props.sharedUsers.map(renderSharedUsers).slice(0, 10)}</div>
        )}
      </div>
    );
  }

  // Currently, this state is not being used in the app because the only parent won't display
  return (
    <div className="type-table-text">
      Not Shared
      <br />
      <br />
      Click draft button and select share draft to start sharing with teammates.
    </div>
  );
}
