import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';
import { QUANTITY_WIDTH } from '../../Milestone/MilestoneDetails/MilestoneDetailsQuantities/MilestoneDetailsQuantitiesStyles';

const styles = (theme: KomodoTheme) =>
  createStyles({
    formControl: {
      height: 38,
    },
    input: {
      textAlign: 'end',
    },
    padding: {
      paddingBottom: 8,
    },
    quantityField: {
      flexGrow: 1,
      background: theme.palette.backgroundWhite,
      width: QUANTITY_WIDTH,
    },
  });

export default styles;
