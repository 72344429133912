import { FC } from 'react';

import { Typography } from '@material-ui/core';

import { ChartLegendOrientation } from '../../../../api/gqlEnums';
import { Scale } from '../../../../enums';
import { ContingencyReportContingency } from '../../../../generated/graphql';
import theme from '../../../../theme/komodo-mui-theme';
import { formatPercent } from '../../../../utilities/string';
import ChartsPieGraph from '../../../Charts/ChartsD3/ChartsPieGraph/ChartsPieGraph';
import {
  DEFAULT_EMPTY_VALUE,
  PieChartData,
} from '../../../Charts/ChartsD3/ChartsPieGraph/ChartsPieGraphUtils';
import ChartsLegend from '../../../Charts/ChartsLegend/ChartsLegend';
import { LegendElementType } from '../../../Charts/ChartsLegend/ChartsLegendUtils';
import { renderCostString } from '../../../CostReport/CostReportUtils';

type ContingencyReportPieGraphProps = {
  milestoneName: string;
  milestoneCost: Cost;
  contingencies: Pick<ContingencyReportContingency, 'name' | 'percentOfMilestoneEstimate'>[];
};

const colors: string[] = [
  theme.palette.notApplicableTint,
  theme.palette.chartBlue,
  theme.palette.chartLightBlue,
  theme.palette.chartDarkGreen,
  theme.palette.chartGreen,
  theme.palette.chartMediumGreen,
  theme.palette.chartBrown,
  theme.palette.chartBrightRed,
  theme.palette.chartDarkGrey,
  theme.palette.chartFuscia,
  theme.palette.chartGold,
  theme.palette.chartLightGreen,
  theme.palette.chartLightRed,
  theme.palette.chartMagenta,
  theme.palette.chartOrange,
  theme.palette.chartPeach,
  theme.palette.chartPink,
  theme.palette.chartRed,
  theme.palette.chartYellow,
  theme.palette.chartBlack,
];

const ContingencyReportPieGraph: FC<ContingencyReportPieGraphProps> = ({
  milestoneName,
  contingencies,
  milestoneCost,
}) => {
  const legendElements: LegendElementType[] = [];
  const data: PieChartData[] = [];
  contingencies.forEach((c) => {
    const { name, percentOfMilestoneEstimate } = c;
    data.push({
      share: percentOfMilestoneEstimate ? percentOfMilestoneEstimate / Scale.CURRENCY : 0,
      name,
    });
    legendElements.push({
      text: name,
      percentage: percentOfMilestoneEstimate
        ? formatPercent(percentOfMilestoneEstimate, 4)
        : undefined,
    });
  });

  // add a white piece for all the remaining cost
  const usedShare = data.reduce((total, c) => {
    return total + c.share;
  }, 0);
  const share = 100 - usedShare;
  data.unshift({
    share,
    name: DEFAULT_EMPTY_VALUE,
  });

  const colorMap = new Map<string, string>();
  data.forEach((d, i) => colorMap.set(d.name, colors[i]));

  return (
    <div style={{ display: 'flex', marginBottom: 20 }}>
      <div style={{ width: 148 }}>
        <ChartsPieGraph
          centerLabel={{ label: renderCostString({ cost: milestoneCost, showCents: false }) }}
          chartSize={{
            diameter: 148,
            insideDiameter: 120,
          }}
          colors={colors.slice(0, data.length)}
          data={data}
          options={{ includeGradient: true }}
        />
      </div>
      <div>
        <Typography style={{ ...theme.typography.webTableHeader, marginLeft: 22 }}>
          Active Milestone Estimate ({milestoneName})
        </Typography>
        <div style={{ maxHeight: 148, marginLeft: 14 }}>
          <ChartsLegend
            getLegendElementColor={(element) => {
              const index = data.findIndex((d) => d.name === element.text);
              return colors[index];
            }}
            legendElements={legendElements}
            maxHeight={148}
            maxTextWidth={300}
            variant={ChartLegendOrientation.VERTICAL_WRAP}
          />
        </div>
      </div>
    </div>
  );
};

export default ContingencyReportPieGraph;
