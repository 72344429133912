import { FC, SyntheticEvent } from 'react';

import { CircularProgress, Typography } from '@material-ui/core';

import { withStyles } from '../../../theme/komodo-mui-theme';

import TeamImage from './TeamImage';
import styles from './UploadHeaderStyles';
import UploadImage from './UploadImage';

const onDragOver = (evt: SyntheticEvent<HTMLDivElement>) => {
  evt.preventDefault();
  evt.stopPropagation();
};

const onDragLeave = (evt: SyntheticEvent<HTMLDivElement>) => {
  evt.preventDefault();
  evt.stopPropagation();
};

const onDragEnter = (evt: SyntheticEvent<HTMLDivElement>) => {
  evt.preventDefault();
  evt.stopPropagation();
};

type UploadHeaderProps = {
  addFile: (file: File) => void;
  asset: Asset | null;
  classes: Classes<typeof styles>;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  loading: boolean;
  orgLogoIDs: string[];
};

const UploadHeader: FC<UploadHeaderProps> = ({ addFile, asset, classes, loading, orgLogoIDs }) => {
  const addFiles = (files: FileList | null) => files && addFile(files[0]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  const onDrop = (evt: any) => {
    evt.preventDefault();
    evt.stopPropagation();
    const { files } = evt.dataTransfer;
    addFiles(files);
  };

  const loadingWheel = (
    <div className={classes.loadingContainer}>
      <CircularProgress className={classes.loading} />
    </div>
  );

  const teamImagesPlaceholder = orgLogoIDs.map((logoID: string) => (
    <TeamImage key={logoID} assetID={logoID} />
  ));

  const header = asset && <UploadImage key={asset.id} assetID={asset.id} />;

  const displayImage = asset ? header : teamImagesPlaceholder;

  return (
    <>
      <Typography className={classes.label}> Banner Image </Typography>
      <div className={classes.bannerUploadContainer}>
        <div
          onDragEnter={onDragEnter}
          onDragLeave={onDragLeave}
          onDragOver={onDragOver}
          onDrop={onDrop}
        >
          <input
            key="file"
            id="file"
            multiple
            onChange={(evt) => {
              addFiles(evt.target.files);
            }}
            style={{ display: 'none' }}
            type="file"
          />
          <div className={classes.logosContainer}>{loading ? loadingWheel : displayImage}</div>

          <Typography className={classes.hintSubtitle} variant="body2">
            {`Drag and drop a banner image here, or  `}
            <label className={classes.link} htmlFor="upload-header">
              select it from your computer
            </label>
            <input
              className={classes.hidden}
              id="upload-header"
              onChange={(evt) => {
                addFiles(evt.target.files);
              }}
              type="file"
            />
          </Typography>
        </div>
      </div>
    </>
  );
};

export default withStyles(styles)(UploadHeader);
