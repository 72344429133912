import { FC, useState } from 'react';

import IconButton from '@material-ui/core/IconButton';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import { SelectVariant } from '../../../api/gqlEnums';
import { withStyles } from '../../../theme/komodo-mui-theme';
import JoinSlide from '../../shared-widgets/JoinSlide';

import styles from './SelectCategoryStyles';
import {
  clearSearchOption,
  generateBackOption,
  isGoingBackwards,
  selectCategoryDisplayName,
  uncategorizedOption,
} from './SelectCategoryUtils';

interface SelectCategoryOptionFirstProps {
  classes: Classes<typeof styles>;
  firstCategory: Category;
  handleClick: (target: 'category' | 'parent' | 'subCategories' | 'bulkToggle') => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  handleHover: (event: any) => void;
  handleUpdate: (value: string) => void;
  hasHistory: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  highlightedCategory: any;
  lastCategory: Category;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  noCategoriesFound: boolean;
  selectVariant: SelectVariant;
  slideDirection: 'left' | 'right';
}

const SelectCategoryOptionFirst: FC<SelectCategoryOptionFirstProps> = ({
  classes,
  firstCategory,
  handleClick,
  handleHover,
  handleUpdate,
  hasHistory,
  highlightedCategory,
  lastCategory,
  noCategoriesFound,
  selectVariant,
  slideDirection,
}) => {
  // STATE
  // used to toggle hover styles
  const [isHoveringArrowButton, setIsHoveringArrowButton] = useState(false);

  // DISPLAY LOGIC
  // highlighted = hover, tab-ing through
  // selected = is checked
  // if user is nested in subcategories
  const isNested = lastCategory.level > 1;
  const isHighlighted =
    [uncategorizedOption, clearSearchOption].includes(highlightedCategory) ||
    isGoingBackwards(highlightedCategory);

  // UI GENERATORS
  const highlightedClass = isHighlighted && !isHoveringArrowButton && classes.categoryHighlighted;
  const copy = noCategoriesFound ? (
    <>
      No categories match your search. <span className={classes.clear}>Clear</span>
    </>
  ) : (
    <i>Uncategorized</i>
  );
  const title = selectCategoryDisplayName(firstCategory);
  const uncategorizedOrClear =
    selectVariant === SelectVariant.SINGLE || noCategoriesFound ? (
      <JoinSlide direction={slideDirection}>
        <li
          key="uncategorized"
          aria-selected={isHighlighted}
          className={`${classes.categoryOption} ${classes.categoryUncategorized} ${highlightedClass}`}
          onClick={() => {
            if (noCategoriesFound) {
              // reset the search
              handleUpdate('');
              // re focus the input
              document!.getElementById('listbox-input')!.focus();
            } else {
              handleClick('category');
            }
          }}
          onKeyDown={() => {
            if (noCategoriesFound) {
              // reset the search
              handleUpdate('');
              // re focus the input
              document!.getElementById('listbox-input')!.focus();
            } else {
              handleClick('category');
            }
          }}
          onMouseEnter={() => {
            handleHover(noCategoriesFound ? clearSearchOption : uncategorizedOption);
          }}
          role="option"
          title={noCategoriesFound ? 'No Categories found' : 'Uncategorized'}
          value={0}
        >
          {copy}
        </li>
      </JoinSlide>
    ) : (
      <></>
    );
  const backButton = (
    <JoinSlide direction={slideDirection}>
      <li
        key="back"
        aria-selected={isHighlighted}
        className={`${highlightedClass} ${classes.categoryOption} ${classes.categoryBack}`}
        role="option"
        value={0}
      >
        <span
          aria-selected={isHighlighted}
          onClick={() => {
            handleClick('parent');
          }}
          onKeyDown={() => {
            handleClick('parent');
          }}
          onMouseEnter={() => {
            handleHover(generateBackOption(highlightedCategory));
            setIsHoveringArrowButton(true);
          }}
          onMouseLeave={() => {
            setIsHoveringArrowButton(false);
          }}
          role="option"
          tabIndex={0}
          title={`back to ${title}`}
        >
          <IconButton
            classes={{
              root: classes.iconBackButton,
            }}
          >
            <ArrowBackIosIcon classes={{ root: classes.iconBack }} />
          </IconButton>
        </span>
        <span
          aria-selected={isHighlighted}
          className={`${classes.categoryOptionTitleFirst}`}
          onClick={() => {
            handleClick('category');
          }}
          onKeyDown={() => {
            handleClick('category');
          }}
          onMouseEnter={() => {
            handleHover(generateBackOption(highlightedCategory));
          }}
          role="option"
          tabIndex={0}
          title={title}
        >
          {title}
        </span>
      </li>
    </JoinSlide>
  );

  return isNested && hasHistory ? backButton : uncategorizedOrClear;
};

export default withStyles(styles)(SelectCategoryOptionFirst);
