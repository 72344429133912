import joinAPI from '../../../../api/joinAPI';
import { computePayload } from '../../../../utilities/items';
import { getDerivationIdFromUrl, getDomain } from '../../../../utilities/url';

export const filterAssetBookmarks = (asset: Pick<Asset, 'derivations'>, bookmarks: Bookmark[]) => {
  return (bookmarks ?? []).filter((bookmark) => {
    const bookmarkUrl = computePayload(bookmark.payload)?.url;
    const bookmarkDomain = getDomain(bookmarkUrl);
    const derivationID = getDerivationIdFromUrl(bookmarkUrl);
    const assetDerivationID = (asset.derivations ?? []).find(
      ({ kind }) => kind === 'ADSKViewer'
    )?.id;
    return (
      joinAPI.apiUrl === bookmarkDomain &&
      derivationID &&
      assetDerivationID &&
      derivationID === assetDerivationID
    );
  });
};
