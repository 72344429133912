import {
  StandardCategorizationsQuery,
  StandardCategorizationsQueryVariables,
} from '../generated/graphql';

import { standardCategorizationsQuery } from './queries';
import { useQuery } from './useMountAwareQuery';

export const useStandardCategorizationsQuery = (projectID: UUID) =>
  useQuery<StandardCategorizationsQuery, StandardCategorizationsQueryVariables>(
    standardCategorizationsQuery,
    {
      variables: { projectID },
      skip: !projectID,
    }
  );
