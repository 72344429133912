export default function ItemsLegend() {
  const legendEntry = (icon: JSX.Element, text: string) => (
    <div className="flex items-center pl-3">
      <div className="p-1">{icon}</div>
      <div className="p-1">
        <div className="type-label">{text}</div>
      </div>
    </div>
  );

  const pastDueImg = <div className="h-2 w-2 self-center bg-entities-item-pastdue" />;
  const upcomingImg = <div className="h-2 w-2 self-center bg-entities-item-upcoming" />;
  const decidedImg = <div className="h-2 w-2 self-center bg-entities-item-decided" />;

  return (
    <div className="flex h-7 w-full items-center pt-1">
      {legendEntry(pastDueImg, `Past Due`)}
      {legendEntry(upcomingImg, `Upcoming`)}
      {legendEntry(decidedImg, `Decided`)}
    </div>
  );
}
