import { FC, useEffect, useState } from 'react';

import { cloneDeep } from '@apollo/client/utilities';
import { Divider, Typography } from '@material-ui/core';

import {
  execDashboardEvent,
  execDashboardEventTypes,
} from '../../analytics/analyticsEventProperties';
import { companyTabIDVar } from '../../api/apollo/reactiveVars';
import {
  ACTIVE_ITEMS_SUMMARY,
  ACTIVE_STATUS,
  APV_BY_LOCATION,
  BUDGET_GAP_BY_PROJECT,
  COMPANY_DASHBOARD,
  CURRENT_ITEMS_BREAKDOWN_BY_COST_IMPACT,
  LARGEST_RECENT_MOVEMENT,
  LOCALSTORAGE_PROJECT_LIST_SHOW,
  MOST_RECENTLY_CREATED,
  OPEN_ITEMS_DUE,
  TOP_USERS,
} from '../../constants';
import { InsightsProjectCountsQuery } from '../../generated/graphql';
import { useDebounceFn } from '../../hooks';
import useSendAnalytics from '../../hooks/useSendAnalytics';
import { withStyles } from '../../theme/komodo-mui-theme';
import { usePersistentStates } from '../../utilities/urlState';
import { useUserProfile } from '../../utilities/userProfile';
import { useCompaniesQuery } from '../CompanyTab/CompanyHooks';
import { useCompanyTabID } from '../CompanyTab/CompanyTabUtils';
import useDeprecatedProjectsFilterOptionsQuery from '../ProjectsList/hooks/useDeprecatedProjectsFilterOptionsQuery';
import { ProjectsToggle } from '../ProjectsList/ProjectsListShowToggle';
import { useFilterProjects } from '../ProjectsList/ProjectsListUtils';
import CollapseEntry from '../Select/CollapseEntry/CollapseEntry';
import JoinSelect from '../Select/JoinSelect/JoinSelect';

import ExecutiveDashboardActiveUsersData from './ExecutiveDashboardActiveUsers/ExecutiveDashboardActiveUsersData';
import ExecutiveDashboardBudgetGapsData from './ExecutiveDashboardBudgetGaps/ExecutiveDashboardBudgetGapsData';
import ExecutiveDashboardCurrentItemsBreakdownData from './ExecutiveDashboardCurrentItemsBreakdown/ExecutiveDashboardCurrentItemsBreakdownData';
import ExecutiveDashboardDecisionSummaryData from './ExecutiveDashboardDecisionSummary/ExecutiveDashboardDecisionSummaryData';
import { useInsightsProjectCountsQuery, useListDashboardProjects } from './ExecutiveDashboardHooks';
import ExecutiveDashboardItemDueDateData from './ExecutiveDashboardItemDueDate/ExecutiveDashboardItemDueDateData';
import ExecutiveDashboardItemsOwnershipData from './ExecutiveDashboardItemsOwnership/ExecutiveDashboardItemsOwnershipData';
import ExecutiveDashboardMostRecentlyCreatedData from './ExecutiveDashboardMostRecentlyCreated/ExecutiveDashboardMostRecentlyCreatedData';
import ExecutiveDashboardProjectActiveUsersData from './ExecutiveDashboardProjectActiveUsers/ExecutiveDashboardProjectActiveUsersData';
import ExecutiveDashboardProjectMapData from './ExecutiveDashboardProjectMap/ExecutiveDashboardProjectMapData';
import ExecutiveDashboardProjectMovementData from './ExecutiveDashboardProjectMovement/ExecutiveDashboardProjectMovementData';
import ExecutiveDashboardProjectsOverviewData from './ExecutiveDashboardProjectsOverview/ExecutiveDashboardProjectsOverviewData';
import ExecutiveDashboardStyles from './ExecutiveDashboardStyles';
import {
  ACTIVITY,
  DECISIONS,
  PORTFOLIO_INSIGHTS,
  createProjectMap,
} from './ExecutiveDashboardUtils';
import FilterMenu from './FilterMenu/FilterMenu';

const MILLISECONDS = 5000;

type ExecutiveDashboardProps = {
  classes: Classes<typeof ExecutiveDashboardStyles>;
};

const ExecutiveDashboard: FC<ExecutiveDashboardProps> = ({ classes }) => {
  const profile = useUserProfile();
  document.title = `${COMPANY_DASHBOARD} ${profile ? ` - ${profile.nickname}` : ''}`;

  // Initialize filters.
  const [settings, setSettings] = usePersistentStates(
    window.location,
    '',
    {
      companies: [],
      locations: [],
      statuses: [ACTIVE_STATUS],
      types: [],
      orgNodeIDs: [],
      projectLeadIDs: [],
    },
    'Company Dashboard - '
  );

  const [listShow, setListShow] = useState<ProjectsToggle>(
    localStorage.getItem(LOCALSTORAGE_PROJECT_LIST_SHOW) === ProjectsToggle.ALL
      ? ProjectsToggle.ALL
      : ProjectsToggle.MY_PROJECTS
  );
  const includeCompanyProjects = listShow === ProjectsToggle.ALL;

  // Company data
  const companyID = useCompanyTabID();
  const companies = useCompaniesQuery().data?.companies ?? [];

  const filterManager = useFilterProjects(settings);
  const {
    data: { projectsFilterOptions } = {
      projectsFilterOptions: {
        locations: [] as string[],
        statuses: [] as string[],
        companies: [] as string[],
        types: [] as string[],
        projectTypes: [],
        organizationNodes: [],
        projectLeads: [],
      },
    },
  } = useDeprecatedProjectsFilterOptionsQuery(includeCompanyProjects, undefined, companyID);
  filterManager.filterOptions = cloneDeep(projectsFilterOptions);
  filterManager.filterOptions.types = projectsFilterOptions.projectTypes || [];

  // Collapse state.
  const [collapsedPortfolioContent, setCollapsedPortfolioContent] = useState(false);
  const [collapsedDecisions, setCollapsedDecisions] = useState(false);
  const [collapsedActivity, setCollapsedActivity] = useState(false);

  const sendAnalytics = useSendAnalytics();

  // Project data
  const { data, loading } = useListDashboardProjects(
    filterManager.filterState,
    includeCompanyProjects,
    companyID
  );
  const projects = data?.dashboardProjects.projects ?? [];

  const {
    data: {
      insightsProjectCounts: counts = {
        myProjectsCount: 0,
        allProjectsCount: 0,
      } as InsightsProjectCountsQuery['insightsProjectCounts'],
    } = {},
  } = useInsightsProjectCountsQuery(filterManager.filterState, companyID);

  const projectMap = createProjectMap(projects);
  const allProjectIDs = projects.map((p) => p.id);

  const [selectedExecProjects, setSelectedExecProjects] = useState(new Set<string>(allProjectIDs));
  // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO CT-566: Fix this pls :)
  useEffect(() => setSelectedExecProjects(new Set(allProjectIDs)), [allProjectIDs.length]);
  const projectIDs = Array.from(selectedExecProjects);

  const chartHoverAnalyticsEvent = (chartType: string) =>
    execDashboardEvent(execDashboardEventTypes.EXEC_DASHBOARD_HOVER, {
      chart: {
        chartType,
      },
    });

  return (
    <div className="flex h-full flex-col">
      {companies?.length > 0 && (
        <div>
          <JoinSelect
            classNameSelect={classes.companySelect}
            entries={companies}
            onChange={(newValue: UUID) => {
              if (newValue) companyTabIDVar(newValue);
            }}
            search
            value={companyID}
          />
          <Divider />
        </div>
      )}
      <div className={classes.title}>
        <Divider />
        <Typography className={classes.titleText} variant="title">
          {COMPANY_DASHBOARD}
        </Typography>
        <FilterMenu
          counts={counts}
          filterManager={filterManager}
          includeCompanyProjects={includeCompanyProjects}
          listShow={listShow}
          projectMap={projectMap}
          projectOptions={allProjectIDs}
          selectedProjects={projectIDs}
          setListShow={setListShow}
          setSelectedProjects={setSelectedExecProjects}
          setSettings={setSettings}
        />
        <Divider />
      </div>
      <div className={classes.contents}>
        <CollapseEntry
          analyticsEvent={execDashboardEvent(execDashboardEventTypes.EXEC_DASHBOARD_TAB_VIEW, {
            tab: {
              tabName: PORTFOLIO_INSIGHTS,
              isCollapsed: !collapsedPortfolioContent,
            },
          })}
          collapsed={collapsedPortfolioContent}
          fullWidth
          hasMargin
          hasPadding={false}
          setCollapse={setCollapsedPortfolioContent}
          title={PORTFOLIO_INSIGHTS}
        >
          <div className={classes.collapse}>
            <div className={classes.column}>
              <ExecutiveDashboardProjectsOverviewData
                dashboardLoading={loading}
                projectIDs={projectIDs}
              />
              <div className={classes.row}>
                <ExecutiveDashboardProjectMapData
                  dashboardLoading={loading}
                  onHover={useDebounceFn(
                    () => sendAnalytics(chartHoverAnalyticsEvent(APV_BY_LOCATION)),
                    MILLISECONDS
                  )}
                  projectIDs={projectIDs}
                />
              </div>
            </div>
            <div className={classes.column}>
              <ExecutiveDashboardBudgetGapsData
                dashboardLoading={loading}
                onHover={useDebounceFn(
                  () => sendAnalytics(chartHoverAnalyticsEvent(BUDGET_GAP_BY_PROJECT)),
                  MILLISECONDS
                )}
                projectIDs={projectIDs}
                projectMap={projectMap}
              />
              <div className={classes.row}>
                <ExecutiveDashboardProjectMovementData
                  dashboardLoading={loading}
                  onHover={useDebounceFn(
                    () => sendAnalytics(chartHoverAnalyticsEvent(LARGEST_RECENT_MOVEMENT)),
                    MILLISECONDS
                  )}
                  projectIDs={projectIDs}
                  projectMap={projectMap}
                />
              </div>
            </div>
          </div>
        </CollapseEntry>
        <CollapseEntry
          analyticsEvent={execDashboardEvent(execDashboardEventTypes.EXEC_DASHBOARD_TAB_VIEW, {
            tab: {
              tabName: DECISIONS,
              isCollapsed: !collapsedDecisions,
            },
          })}
          collapsed={collapsedDecisions}
          fullWidth
          hasMargin
          hasPadding={false}
          setCollapse={setCollapsedDecisions}
          title={DECISIONS}
        >
          <div className={classes.collapse}>
            <div className={classes.column}>
              <ExecutiveDashboardDecisionSummaryData
                dashboardLoading={loading}
                onHover={useDebounceFn(
                  () => sendAnalytics(chartHoverAnalyticsEvent(ACTIVE_ITEMS_SUMMARY)),
                  MILLISECONDS
                )}
                projectIDs={projectIDs}
                projectMap={projectMap}
              />
              <ExecutiveDashboardCurrentItemsBreakdownData
                dashboardLoading={loading}
                onHover={useDebounceFn(
                  () =>
                    sendAnalytics(chartHoverAnalyticsEvent(CURRENT_ITEMS_BREAKDOWN_BY_COST_IMPACT)),
                  MILLISECONDS
                )}
                projectIDs={projectIDs}
                projectMap={projectMap}
              />
            </div>
            <div className={classes.column}>
              <ExecutiveDashboardItemDueDateData
                dashboardLoading={loading}
                onHover={useDebounceFn(
                  () => sendAnalytics(chartHoverAnalyticsEvent(OPEN_ITEMS_DUE)),
                  MILLISECONDS
                )}
                projectIDs={projectIDs}
                projectMap={projectMap}
              />
            </div>
          </div>
        </CollapseEntry>
        <CollapseEntry
          analyticsEvent={execDashboardEvent(execDashboardEventTypes.EXEC_DASHBOARD_TAB_VIEW, {
            tab: {
              tabName: ACTIVITY,
              isCollapsed: !collapsedActivity,
            },
          })}
          collapsed={collapsedActivity}
          fullWidth
          hasMargin
          hasPadding={false}
          setCollapse={setCollapsedActivity}
          title={ACTIVITY}
        >
          <div className={classes.collapse}>
            <div className={classes.column}>
              <ExecutiveDashboardProjectActiveUsersData
                projectIDs={projectIDs}
                projectMap={projectMap}
              />
              <ExecutiveDashboardMostRecentlyCreatedData
                dashboardLoading={loading}
                onHover={useDebounceFn(
                  () => sendAnalytics(chartHoverAnalyticsEvent(MOST_RECENTLY_CREATED)),
                  MILLISECONDS
                )}
                projectIDs={projectIDs}
                projectMap={projectMap}
              />
            </div>
            <div className={classes.column}>
              <ExecutiveDashboardActiveUsersData
                dashboardLoading={loading}
                onHover={useDebounceFn(
                  () => sendAnalytics(chartHoverAnalyticsEvent(TOP_USERS)),
                  MILLISECONDS
                )}
                projectIDs={projectIDs}
              />
            </div>
            <div className={classes.column}>
              <ExecutiveDashboardItemsOwnershipData
                dashboardLoading={loading}
                projectIDs={projectIDs}
              />
            </div>
          </div>
        </CollapseEntry>
      </div>
    </div>
  );
};

export default withStyles(ExecutiveDashboardStyles)(ExecutiveDashboard);
