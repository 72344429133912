import { useEffect } from 'react';

import {
  ForecastingProjectsQuery,
  ForecastingProjectsQueryVariables,
} from '../../../../../generated/graphql';
import { useQuery } from '../../../../../hooks/useMountAwareQuery';
import useQueryPagination from '../../../../../hooks/useQueryPagination';
import { useTypesIdsToNames } from '../../../../../hooks/useTypesIdsToNames';

import { forecastingProjects } from './queries';

export const useForecastingProjectsQuery = (variablesOuter: ForecastingProjectsQueryVariables) => {
  const variables = useTypesIdsToNames(variablesOuter, 'filters.types');

  const query = useQuery<ForecastingProjectsQuery, ForecastingProjectsQueryVariables>(
    forecastingProjects,
    {
      variables,
    }
  );

  const { fetchMore, resetPagination } = useQueryPagination(
    variables.pagination.limit,
    query.data?.forecastingProjects?.length ?? 0,
    query.fetchMore
  );

  // Fully reset pagination whenever the query variables (filters, search, sort..) change.
  useEffect(() => {
    resetPagination();
  }, [resetPagination, variables]);

  return {
    ...query,
    fetchMore,
  };
};
