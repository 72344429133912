import { FC, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { useReactiveVar } from '@apollo/client';

import { PRINT_CONTINGENCY_REPORT } from '../../../actions/actionTypes';
import {
  analyticsEvent,
  exportActiveContingenciesAnalytics,
  exportAllContingenciesAnalytics,
} from '../../../analytics/analyticsEventProperties';
import { currentUserReportVar } from '../../../api/apollo/reactiveVars';
import { JoinProjectRoutes } from '../../../api/gqlEnums';
import JoinAPI from '../../../api/joinAPI';
import { CONTINGENCY_ALLOWANCE_REPORT } from '../../../constants';
import { CostReportColumnType, EstimateType, MarkupDisplayType } from '../../../generated/graphql';
import useProjectPropsQuery from '../../../hooks/useProjectPropsQuery';
import useSendAnalytics from '../../../hooks/useSendAnalytics';
import { computeColumnInputs } from '../../../utilities/milestones';
import { generateSharedPath } from '../../../utilities/routes/links';
import { getProjectIdFromUrl } from '../../../utilities/url';
import {
  useMilestoneContingenciesQuery,
  useProjectContingenciesQuery,
} from '../../ItemsList/hooks/useMilestoneContingenciesQuery';
import { useMilestoneCostReportsQuery } from '../../Milestone/hooks';
import { printFn, printProps, printReport } from '../../Print/PrintUtils';
import { useLoadUserReportComments } from '../../ReportsTab/ReportHooks';
import useMemoWrapper from '../../useMemoWrapper';
import { useCurrentUserReportID } from '../../VarianceReport/VarianceReportUtils';
import ContingencyReport from '../ContingencyReport/ContingencyReport';
import {
  ContingencyReportType,
  ContingencyReportView,
  getContingencyNamesForDisplayType,
  getItemStatuses,
  getReportSettings,
} from '../ContingencyReport/ContingencyReportUtils';
import { useAllMilestonesContingencyReportQuery } from '../hooks/useAllMilestonesContingencyReportQuery';
import { useContingencyReportQuery } from '../hooks/useContingencyReportQuery';

type ContingencyReportDataProps = {
  isPrint?: boolean;
};

const ContingencyReportData: FC<ContingencyReportDataProps> = ({ isPrint = false }) => {
  const projectID = getProjectIdFromUrl();
  const currentReport = useReactiveVar(currentUserReportVar);

  const [isFinishedLoading, setIsFinishedLoading] = useState(false);
  const [hasOpenedPrintDialog, setHasOpenedPrintDialog] = useState(false);

  const location = useLocation();
  const currentRoute = location.pathname;
  const lastSegment = currentRoute.substring(currentRoute.lastIndexOf('/') + 1);
  const currentReportView =
    lastSegment === CONTINGENCY_ALLOWANCE_REPORT
      ? ContingencyReportView.ACTIVE_MILESTONE
      : ContingencyReportView.ALL_MILESTONES;

  const [reportView, setReportView] = useState<ContingencyReportView>(currentReportView);

  const { data: projectData, loading: loadingProject } = useProjectPropsQuery(projectID);
  const activeMilestone = projectData?.project?.activeMilestone;
  const milestoneID = activeMilestone?.id;

  const columnInputs = useMemo(() => {
    const columnTypes: CostReportColumnType[] = [CostReportColumnType.ESTIMATE_REPORT];
    return computeColumnInputs(columnTypes);
  }, []);

  const costReports =
    useMilestoneCostReportsQuery(milestoneID, projectID, {}, columnInputs)?.data
      ?.milestoneCostReports ?? [];

  const milestoneCostRange =
    costReports.length && costReports[0].costReportColumns[0]
      ? costReports[0].costReportColumns[0]?.report?.range
      : { value: 0 };

  const { data: contingencyData, loading: loadingContingencies } = useMilestoneContingenciesQuery(
    projectID,
    milestoneID,
    false
  );
  const activeMilestoneContingencies = contingencyData?.getMilestoneContingencies;

  const projectContingencies =
    useProjectContingenciesQuery(projectID).data?.getProjectContingencies;
  const availableContingencies =
    currentReportView === ContingencyReportView.ACTIVE_MILESTONE
      ? activeMilestoneContingencies
      : projectContingencies;

  const [savedSelectedContingencies, savedSelectedAllowances, savedSelectedReportType] =
    useMemoWrapper(getReportSettings, currentReport, projectContingencies);

  const [selectedReportType, setSelectedReportType] = useState(
    savedSelectedReportType || ContingencyReportType.ACCEPTED_AND_PENDING
  );

  const itemStatuses = getItemStatuses(selectedReportType);

  const [selectedContingencies, setSelectedContingencies] = useState<string[]>(
    savedSelectedContingencies
  );
  const [selectedAllowances, setSelectedAllowances] = useState<string[]>(savedSelectedAllowances);

  const milestoneEstimates = milestoneID
    ? [{ milestoneID, estimateType: EstimateType.ACTIVE_ESTIMATE }]
    : [];

  const { data: commentData, loading: loadingComments } = useLoadUserReportComments(
    currentReport?.id,
    {
      groupBys: [],
      milestoneEstimates,
    }
  );
  const comments = commentData?.varianceReportComments;

  // reset the selected contingencies any time the available contingencies data changes
  useEffect(() => {
    if (availableContingencies) {
      if (savedSelectedContingencies) {
        setSelectedContingencies(
          getContingencyNamesForDisplayType(availableContingencies, MarkupDisplayType.CONTINGENCY)
        );
      }
      if (savedSelectedAllowances) {
        setSelectedAllowances(
          getContingencyNamesForDisplayType(availableContingencies, MarkupDisplayType.ALLOWANCE)
        );
      }
    }
  }, [availableContingencies, savedSelectedContingencies, savedSelectedAllowances]);

  // we will need to remove any contingencies that are not in the available contingencies from the all milestones report
  const { data: reportData, loading: loadingReport } = useContingencyReportQuery(
    projectID,
    milestoneID,
    itemStatuses,
    selectedContingencies,
    selectedAllowances
  );

  const { data: allMilestonesQueryData, loading: loadingAllMilestonesData } =
    useAllMilestonesContingencyReportQuery(projectID, selectedContingencies, selectedAllowances);

  const allMilestonesReportData = allMilestonesQueryData?.multiMilestoneContingencyReport;

  // Loading
  useEffect(() => {
    if (
      !loadingProject &&
      !loadingContingencies &&
      !loadingComments &&
      !loadingReport &&
      !loadingAllMilestonesData &&
      !isFinishedLoading
    )
      setIsFinishedLoading(true);
  }, [
    isFinishedLoading,
    loadingAllMilestonesData,
    loadingComments,
    loadingContingencies,
    loadingProject,
    loadingReport,
  ]);

  // Printing
  const sendAnalytics = useSendAnalytics();
  useEffect(() => {
    printReport(
      isPrint,
      !isFinishedLoading,
      hasOpenedPrintDialog,
      setHasOpenedPrintDialog,
      printContingencyReport,
      {
        projectID,
        currentReportView,
        sendAnalytics,
      }
    );
  }, [
    currentReportView,
    projectID,
    sendAnalytics,
    isPrint,
    isFinishedLoading,
    hasOpenedPrintDialog,
  ]);

  const reportID = useCurrentUserReportID();
  const exportReport = () => {
    const fileNameTokens = [projectData?.project?.name ?? ''];
    if (currentReportView === ContingencyReportView.ALL_MILESTONES) {
      sendAnalytics(exportAllContingenciesAnalytics());
      JoinAPI.exportAllMilestonesContingencyReport(
        projectID,
        selectedContingencies,
        selectedAllowances,
        fileNameTokens,
        reportID
      );
    } else if (milestoneID) {
      sendAnalytics(exportActiveContingenciesAnalytics());
      JoinAPI.exportActiveMilestoneContingencyReport(
        projectID,
        milestoneID,
        itemStatuses,
        savedSelectedContingencies,
        savedSelectedAllowances,
        fileNameTokens,
        reportID
      );
    }
  };

  if (!activeMilestone) return null;

  return (
    <ContingencyReport
      activeMilestoneContingencies={reportData?.contingencyReport}
      allMilestoneContingencies={allMilestonesReportData}
      availableContingencies={availableContingencies ?? []}
      comments={currentReport?.id ? comments : undefined}
      exportReport={exportReport}
      isLoading={!isFinishedLoading}
      milestoneCost={milestoneCostRange}
      milestoneID={activeMilestone.id}
      milestoneName={activeMilestone.name}
      print={
        !isPrint
          ? () => printContingencyReport({ projectID, currentReportView, sendAnalytics })
          : undefined
      }
      projectID={projectID}
      reportView={reportView}
      selectedAllowances={selectedAllowances}
      selectedContingencies={selectedContingencies}
      selectedReportType={selectedReportType}
      setReportView={setReportView}
      setSelectedAllowances={setSelectedAllowances}
      setSelectedContingencies={setSelectedContingencies}
      setSelectedReportType={setSelectedReportType}
    />
  );
};

export default ContingencyReportData;

const printContingencyReport: printFn = (pp: printProps) => {
  pp.sendAnalytics(analyticsEvent(PRINT_CONTINGENCY_REPORT));
  const route =
    pp.currentReportView === ContingencyReportView.ALL_MILESTONES
      ? JoinProjectRoutes.PRINT_CONTINGENCY_ALL_MILESTONES_REPORT
      : JoinProjectRoutes.PRINT_CONTINGENCY_ALLOWANCE_REPORT;
  window.open(
    generateSharedPath(route, {
      projectId: pp.projectID,
      search: window.location.search,
    }),
    '_blank'
  );
};
