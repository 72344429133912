import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../theme/komodo-mui-theme';

export default (theme: KomodoTheme) =>
  createStyles({
    card: {
      marginTop: 8,
      marginBottom: 24,
      overflow: 'visible',
      backgroundColor: 'white',
      border: theme.border.line,
    },
    notificationSwitch: {
      color: theme.palette.primaryGrey,
    },
    notificationSwitchButton: {
      height: 24,
    },
    notificationSwitchContents: {
      display: 'flex',
      alignItems: 'end',
    },
    notificationSwitchButtonLable: {
      position: 'relative',
      left: '-5px',
    },
    disabled: {
      opacity: 0.5,
    },
    header: {
      ...theme.typography.webButton,
      backgroundColor: theme.palette.ds.background.primary,
      border: 'none',
      color: theme.palette.primaryGrey,
      padding: 8,
    },
    subHeaderCell: { display: 'flex', alignItems: 'end', justifyContent: 'space-between' },
    cell: {
      ...theme.typography.body1,
      border: 'none',
      color: theme.palette.primaryGrey,
      padding: '8px 16px',
    },
    selectorCell: {
      minWidth: '160px',
    },
    leftCell: {
      paddingLeft: '24px',
    },
    row: {
      backgroundColor: 'white !important',
      '&:not(:first-child)': {
        borderTop: theme.border.line,
      },
    },
    formControl: {
      minWidth: 80,
      maxWidth: 300,
      color: theme.palette.primaryGrey,
    },
    menuItem: {
      display: 'block',
      height: 42,
    },
    text: {
      marginLeft: 8,
      marginRight: 8,
    },
    viewingLabel: {
      maxWidth: 160,
    },
  });
