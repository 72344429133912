import { FC, useRef } from 'react';
import * as React from 'react';

import JoinSelect from '../../Select/JoinSelect/JoinSelect';
import useFocusHandler from '../hooks/useFocusHandler';
import { EditorPosition } from '../types';

import { MarkupSelectCellEditorInput } from './utils';

type SelectCellEditorProps = {
  position: EditorPosition;
  stopEditing: () => void;
  input: MarkupSelectCellEditorInput;
};

const SelectCellEditor: FC<SelectCellEditorProps> = ({ position, stopEditing, input }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const previousFocusedElement = document.activeElement;
  useFocusHandler(inputRef, previousFocusedElement);

  const swallowKeys = (event: React.KeyboardEvent) => {
    if (event.key === 'ArrowUp' || event.key === 'ArrowDown' || event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
    }
  };

  const { value, entries, onChange } = input;

  return (
    <div onKeyDown={swallowKeys}>
      <JoinSelect
        classNameSelect="join-grid-select-editor"
        entries={entries}
        hidePrint={false}
        noFormControl
        onChange={onChange}
        onClose={stopEditing}
        open
        style={position}
        value={value}
      />
    </div>
  );
};

export default SelectCellEditor;
