import { gql } from '@apollo/client';

// Query
export const userSourcesQuery = gql`
  query userSources {
    userSources {
      sources {
        sourceSystem
        id
        email
        companyID
      }
      clients {
        clientID
        sourceSystem
      }
    }
  }
`;

// Mutation
export const connectUserSourceMutation = gql`
  mutation connectUserSource($publicToken: String!, $sourceSystem: SourceSystem!, $host: String) {
    connectUserSource(publicToken: $publicToken, sourceSystem: $sourceSystem, host: $host)
  }
`;

// Mutation
export const removeUserSourceMutation = gql`
  mutation removeUserSource($sourceID: UUID!) {
    removeUserSource(sourceID: $sourceID)
  }
`;
