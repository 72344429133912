import { createStyles } from '@material-ui/core';

import { KomodoTheme, singleLine } from '../../../../theme/komodo-mui-theme';

const PADDING = 8;
const EXTERNAL_PADDING = 16;
const TEXT_PADDING = 12;
const PADDING_BETWEEN = 24;
const SIDEBAR_WIDTH = 200 - EXTERNAL_PADDING - PADDING;

export default (theme: KomodoTheme) =>
  createStyles({
    button: {
      width: '100%',
      padding: PADDING,
      borderRadius: 4,
      justifyContent: 'left',
      marginBottom: 4,
    },
    buttonText: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    buttonTypography: theme.typography.h4Medium,
    buttonSection: {
      paddingLeft: PADDING,
      paddingRight: PADDING,
      paddingTop: PADDING_BETWEEN,
    },
    header: {
      paddingBottom: TEXT_PADDING,
      ...theme.typography.chartSmall,
      fontWeight: theme.typography.h4Bold.fontWeight,
    },
    selectedButton: {
      backgroundColor: theme.palette.backgroundGrey,
    },
    sidebar: {
      padding: PADDING,
      paddingLeft: EXTERNAL_PADDING,
      paddingTop: EXTERNAL_PADDING,
      display: 'flex',
      flexDirection: 'column',
      borderRight: singleLine(theme.palette.divider),
      backgroundColor: theme.palette.backgroundWhite,
      width: SIDEBAR_WIDTH,
      zIndex: 101,
    },
    underlined: {
      textDecoration: 'underline',
    },
  });
