import { ComponentProps, useRef, useState } from 'react';
import { mergeProps, useButton, useFocusRing, useMenuTrigger } from 'react-aria';
import { useMenuTriggerState } from 'react-stately';

import { useAuth0 } from '@auth0/auth0-react';

import { navEventTypes } from '../../../analytics/analyticsEventProperties';
import { kebabDataCy } from '../../../utilities/cypress';
import { getUserPicture } from '../../Collaborators/UserUtils';
import { DialogsEditProfile } from '../../Dialogs';
import { Menu, Popover, Tooltip } from '../../scales';
import useSendNavAnalytics from '../hooks/useSendNavAnalytics';
import { EditProfileIcon, LogoutIcon } from '../icons';
import { Thumbnail } from '../../dragon-scales';

type Props = {
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  expanded?: boolean;
  tooltipOffset?: ComponentProps<typeof Tooltip>['offset'];
  tooltipPlacement?: ComponentProps<typeof Tooltip>['placement'];
  user?: {
    name: string;
    picture: string;
  } & Parameters<typeof getUserPicture>[0];
};

export default function UserMenuButton(props: Props) {
  const sendNavAnalytics = useSendNavAnalytics();

  const { logout } = useAuth0();

  const state = useMenuTriggerState({
    onOpenChange: (isOpen) => {
      if (isOpen) {
        sendNavAnalytics(navEventTypes.OPEN_USER_MENU);
      }
    },
  });

  const ref = useRef<HTMLButtonElement>(null);
  const { menuProps, menuTriggerProps } = useMenuTrigger({}, state, ref);
  const { buttonProps } = useButton(
    {
      ...menuTriggerProps,
      'aria-label': 'show user options',
    },
    ref
  );

  const { isFocusVisible, focusProps } = useFocusRing();

  const [isEditProfileDialogOpen, setIsEditProfileDialogOpen] = useState(false);

  if (!props.user) return null;

  const dataCy = 'user-menu-button';

  return (
    <>
      <Tooltip
        content="Open user menu"
        isDisabled={state.isOpen}
        offset={props.tooltipOffset}
        placement={props.tooltipPlacement}
      >
        <button
          {...mergeProps(buttonProps, focusProps)}
          ref={ref}
          className={[
            'relative flex items-center gap-2 truncate rounded-full type-body2 hover:bg-button-secondary-hover',
            props.expanded ? 'grow p-1 pr-4' : 'p-1',
            isFocusVisible ? 'outline' : 'outline-none',
          ].join(' ')}
          data-cy={dataCy}
        >
          <div className="h-8 w-8 shrink-0 overflow-hidden rounded-full">
            <Thumbnail size={32} thumbnail={getUserPicture(props.user)} />
          </div>
          {props.expanded && (
            <div className="truncate" data-cy={kebabDataCy(dataCy, 'name')}>
              {props.user.name}
            </div>
          )}
          {state.isOpen && (
            <Popover offset={8} placement="bottom start" state={state} triggerRef={ref}>
              <Menu
                {...menuProps}
                data-cy={kebabDataCy(dataCy, 'menu')}
                onClose={state.close}
                sections={[
                  {
                    entries: [
                      {
                        id: 'edit-profile',
                        label: 'Edit profile',
                        onClick: () => {
                          setIsEditProfileDialogOpen(true);
                        },
                        startAdornment: (
                          <div className="icon-md">
                            <EditProfileIcon />
                          </div>
                        ),
                      },
                    ],
                  },
                  {
                    entries: [
                      {
                        id: 'log-out',
                        label: 'Log out',
                        onClick: () => {
                          logout({ returnTo: window.location.origin });
                        },
                        startAdornment: (
                          <div className="icon-md">
                            <LogoutIcon />
                          </div>
                        ),
                      },
                    ],
                  },
                ]}
              />
            </Popover>
          )}
        </button>
      </Tooltip>
      {isEditProfileDialogOpen && (
        <DialogsEditProfile onClose={() => setIsEditProfileDialogOpen(false)} />
      )}
    </>
  );
}
