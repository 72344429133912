import { FC } from 'react';

import { withStyles } from '../../../theme/komodo-mui-theme';
import { VarianceReportComments } from '../../ReportsTab/ReportHooks';

import ContingencyReportRow from './ContingencyReportRow';
import { styles } from './ContingencyReportStyles';
import { ContingencyReportColumns } from './ContingencyReportUtils';

type ContingencyDrawReportBodyProps = {
  columns: ContingencyReportColumns[];
  contingencies: ContingencyReport;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  allRowsCollapsed: boolean;
  isNotesColumnExpanded: boolean;
  setIsNotesColumnExpanded: (isNotesColumnExpanded: boolean) => void;
  projectID: UUID;
  comments?: VarianceReportComments;
  activeMilestoneID: UUID;
};

const ContingencyDrawReportBody: FC<ContingencyDrawReportBodyProps> = ({
  columns,
  contingencies,
  allRowsCollapsed,
  isNotesColumnExpanded,
  setIsNotesColumnExpanded,
  projectID,
  comments,
  activeMilestoneID,
}) => {
  const reportRows = contingencies.map((contingency) => {
    return (
      <ContingencyReportRow
        key={contingency.contingencyID}
        activeMilestoneID={activeMilestoneID}
        allRowsCollapsed={allRowsCollapsed}
        columns={columns}
        comments={comments}
        contingency={contingency}
        isNotesColumnExpanded={isNotesColumnExpanded}
        projectID={projectID}
        setIsNotesColumnExpanded={setIsNotesColumnExpanded}
      />
    );
  });

  return <div>{reportRows}</div>;
};

export default withStyles(styles)(ContingencyDrawReportBody);
