import useAssetBlobUrlQuery from '../hooks/useAssetBlobUrlQuery';
import useAssetDisplayUrlQuery from '../hooks/useAssetDisplayUrlQuery';

import AssetsViewer from './AssetsViewer';

type Props = {
  asset: EntityAttachment | ProjectThumbnail;
  bookmarkEditIndex?: number;
  canDelete?: boolean;
  closeModal: (assetID: UUID) => void;
  detachAttachment?: () => void;
  downloadAsset?: () => void;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  hideActions?: boolean;
  numAssets?: number;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  open?: boolean;
  setEditingView?: (index: number) => void;
  shiftViewer?: (index: number) => void;
  type?: string | null;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  viewOnly?: boolean;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  showMenu: boolean;
};

export default function AssetsViewerData(props: Props) {
  // blobUrl is used to view images
  const { url: blobUrl, urlLoading: blobLoading } = useAssetBlobUrlQuery(props.asset.id);
  // displayUrl is used to view PDFs and rvt 3D models
  const { url: displayUrl, urlLoading: displayLoading } = useAssetDisplayUrlQuery(props.asset.id);
  if (blobLoading || displayLoading) return null;
  return <AssetsViewer {...props} blobURL={blobUrl} displayURL={displayUrl} />;
}
