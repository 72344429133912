import { useCallback, useEffect, useMemo, useState } from 'react';
import { useEffectOnce } from 'react-use';

import { EscalationTargetLocation } from '../constants/projectCompTypes';

import useAutoEscalateMultiple from './useAutoEscalateMultiple';

export const useAutoEscalationTarget = (
  projectComps: ProjectComp[],
  defaultLocation: EscalationTargetLocation
) => {
  const [targetLocation, setTargetLocation] = useState<EscalationTargetLocation>(defaultLocation);

  // Initially check if parent project is used as the target project.
  useEffectOnce(() => {
    projectComps.some((p) => {
      const targetLocation = p.input.escalation?.locationMeta?.targetLabel;
      const parentTargetLocMismatch = targetLocation && targetLocation !== defaultLocation.location;
      if (parentTargetLocMismatch) {
        setTargetLocation({
          location: targetLocation,
        });
      }
      return parentTargetLocMismatch;
    });
  });

  // Update target location when defualt location is updated (ie when a parent project is attached to the report)
  // In this case the target location is only updated when the targetLocation was previusly not applied.
  // We don't want to change location if it has been manually added by the user by applying locaion auto-escalation
  useEffect(() => {
    if (targetLocation.location === '') {
      setTargetLocation(defaultLocation);
    }
  }, [targetLocation, defaultLocation]);

  const projectsToAutoEscalate = useMemo(
    () =>
      projectComps.filter((p) => {
        return p.input.escalation?.locationMeta?.isAutoEscalated;
      }),
    [projectComps]
  );

  const { onAutoEscalateMultipleLocations } = useAutoEscalateMultiple(
    { ...targetLocation, date: new Date(new Date().toISOString().split('T')[0]).toISOString() },
    projectsToAutoEscalate
  );

  const onTargetLocationChange = useCallback(
    (newTarget: EscalationTargetLocation) => {
      const newTargetLocation = {
        location: newTarget.location,
        lat: newTarget.lat ?? undefined,
        lon: newTarget.lon ?? undefined,
      };
      setTargetLocation(newTargetLocation);
      onAutoEscalateMultipleLocations(
        {
          ...newTargetLocation,
          date: new Date(new Date().toISOString().split('T')[0]).toISOString(),
        },
        projectsToAutoEscalate.map((p) => ({ ...p.project, inputID: p.input.id }))
      );
    },
    [onAutoEscalateMultipleLocations, projectsToAutoEscalate]
  );

  return { targetLocation, onTargetLocationChange };
};
