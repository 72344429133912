import { useContext, useEffect, useRef } from 'react';
import * as React from 'react';

import RowHighlightContext, { RowHighlightableElement } from './context';

const Highlightable = (props: {
  children: ({ ref }: { ref: React.RefObject<RowHighlightableElement> }) => JSX.Element;
}) => {
  const ref = useRef<RowHighlightableElement>(null);

  const { onRegisterAsHighlightable } = useContext(RowHighlightContext);

  useEffect(() => {
    onRegisterAsHighlightable(ref);
  }, [onRegisterAsHighlightable]);

  return props.children({ ref });
};

export default Highlightable;
