import { FC } from 'react';

import { FormControl } from '@material-ui/core';

import { FILTER_PROJECT_TYPE } from '../../../../constants';
import { withStyles } from '../../../../theme/komodo-mui-theme';
import PanelSelectHeader from '../../../Select/PanelSelectHeader/PanelSelectHeader';
import SelectFilterChipInput from '../../../Select/SelectFilter/SelectFilterChipInput';
import {
  ProjectFilterManager,
  ProjectFilterSetSettings,
  calculateNumberOfFiltersToShow,
  getLabelsForFilterType,
  getOptionsForFilterType,
  getSelectedForFilterType,
  getTitleForFilterType,
} from '../../ProjectsListUtils';

import ProjectsListFilterSelectStyles from './ProjectsListFilterSelectStyles';

type ProjectsListFilterSelectProps = {
  classes: Classes<typeof ProjectsListFilterSelectStyles>;
  clearAllText?: string;
  filterManager: ProjectFilterManager;
  filterType: string;
  setSettings: ProjectFilterSetSettings;
  formatValueForDisplay?: (value: string) => string;
};

const ProjectsListFilterSelect: FC<ProjectsListFilterSelectProps> = ({
  classes,
  clearAllText,
  filterManager,
  filterType,
  setSettings,
  formatValueForDisplay,
}) => {
  const { clearFilterType, setFilter } = filterManager;

  const title = getTitleForFilterType(filterType);
  const options = getOptionsForFilterType(filterManager, filterType) as string[];
  const labels = getLabelsForFilterType(filterManager, filterType);
  const [selected, selectedLabels] = getSelectedForFilterType(filterManager, filterType);

  const clearFilter = () => {
    clearFilterType(filterType, setSettings);
  };

  if (!title) return null;

  const cy = `ProjectsListFilterSelect-${title.replace(' ', '')}`;
  return (
    <div
      className={
        selected.length > 0
          ? `${classes.outerContainer} ${classes.activeContainer} ${
              filterType === FILTER_PROJECT_TYPE && classes.borderTop
            }`
          : `${classes.outerContainer} ${
              filterType === FILTER_PROJECT_TYPE && classes.borderTopPlaceholder
            }`
      }
    >
      <PanelSelectHeader
        clearAllText={clearAllText}
        clearFilter={clearFilter}
        showRemove={selected.length > 0}
        title={title}
      />
      <div className={classes.selectContainer}>
        <FormControl
          classes={{
            root: classes.fullWidth,
          }}
        >
          <SelectFilterChipInput
            cy={cy || ''}
            formatValueForDisplay={formatValueForDisplay}
            labels={labels}
            maxChipsShown={calculateNumberOfFiltersToShow(selected)}
            onSelect={(value: string) => {
              setFilter({ type: filterType, value }, setSettings);
            }}
            options={options}
            selected={selected}
            selectedLabels={selectedLabels}
            title={title}
            tooltip={selectedLabels ?? selected}
          />
        </FormControl>
      </div>
    </div>
  );
};

export default withStyles(ProjectsListFilterSelectStyles)(ProjectsListFilterSelect);
