import { FC } from 'react';

import { Typography } from '@material-ui/core';

import { withStyles } from '../../../../theme/komodo-mui-theme';

import AssetsCardsHintStyles from './AssetsCardsHintStyles';

type AssetsCardsHintProps = {
  classes: Classes<typeof AssetsCardsHintStyles>;
  isFileExplorer?: boolean;
};

const AssetsCardsHint: FC<AssetsCardsHintProps> = ({ classes, isFileExplorer }) => {
  const getTextClass = (isBold: boolean) => (isBold ? classes.hintTextBold : classes.hintText);

  return (
    <div
      className={`${classes.hintContainer} ${isFileExplorer ? '' : classes.hintContainerBorder}`}
    >
      <div className={classes.hintTextContainer}>
        <Typography className={getTextClass(false)}>
          Commonly uploaded file types include
        </Typography>
      </div>
      <div className={classes.hintTextContainer}>
        <Typography className={getTextClass(true)}>
          .pdf, .jpg, .png, .rvt, .lsx, .ocs, .ptx
        </Typography>
      </div>
    </div>
  );
};

export default withStyles(AssetsCardsHintStyles)(AssetsCardsHint);
