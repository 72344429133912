import { useNavigate } from 'react-router-dom';

import { Add } from '@material-ui/icons';

import {
  roleMutationAnalyticsEvent,
  roleMutationTypes,
} from '../../../analytics/analyticsEventProperties';
import { PermissionResource, Role } from '../../../generated/graphql';
import useSendAnalytics from '../../../hooks/useSendAnalytics';
import { RouteKeys } from '../../../routes/paths';
import usePermissions from '../../../utilities/permissions/usePermissions';
import { generateSharedPath } from '../../../utilities/routes/links';
import { ListDetailComponents } from '../../scales';

import { useCreateProjectRoleMutation } from './hooks/useCreateProjectRoleMutation';

type Props = {
  projectID: UUID;
  roles: Role[];
};

const VerticalMenu = (props: Props) => {
  const navigate = useNavigate();
  const sendAnalytics = useSendAnalytics();

  const { canAdd } = usePermissions();

  const [createProjectRole] = useCreateProjectRoleMutation(props.projectID);
  const onAddNewRole = () => {
    createProjectRole(undefined, (roleId) => {
      navigate(
        generateSharedPath(RouteKeys.PROJECT_TEAM_ROLES_ROLE_VIEW, {
          projectId: props.projectID,
          roleId,
        })
      );
      sendAnalytics(roleMutationAnalyticsEvent(roleMutationTypes.CREATE_ROLE));
    });
  };

  return (
    <>
      {canAdd(PermissionResource.PERMISSION_TEMPLATES) && (
        <ListDetailComponents.Button
          label="Add a role"
          onClick={onAddNewRole}
          startAdornment={<Add />}
        />
      )}
      {props.roles.map((role) => {
        return (
          <ListDetailComponents.Link
            key={role.id}
            label={role.name}
            to={generateSharedPath(RouteKeys.PROJECT_TEAM_ROLES_ROLE_VIEW, {
              projectId: props.projectID,
              roleId: role.id,
            })}
          />
        );
      })}
    </>
  );
};

export default VerticalMenu;
