import {
  ProjectCollaboratorQuery,
  ProjectCollaboratorQueryVariables,
} from '../../../generated/graphql';
import { useQuery } from '../../../hooks/useMountAwareQuery';
import { useProjectID } from '../../routes/params';

import { projectCollaboratorQuery } from './queries';

export default function useProjectCollaborator(projectID?: UUID) {
  const currentProjectID = useProjectID();
  const { data, loading, previousData } = useQuery<
    ProjectCollaboratorQuery,
    ProjectCollaboratorQueryVariables
  >(projectCollaboratorQuery, {
    variables: { projectID: projectID ?? currentProjectID },
    skip: !(projectID ?? currentProjectID),
  });
  return { collaborator: data?.currentCollaborator ?? previousData?.currentCollaborator, loading };
}
