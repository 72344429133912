import { FC } from 'react';

import { Typography } from '@material-ui/core';

import { withStyles } from '../../../theme/komodo-mui-theme';
import CostReportMetricGroup from '../../CostReport/CostReportMetricGroup/CostReportMetricGroup';
import { MetricGroup } from '../../CostReport/CostReportMetricGroup/CostReportMetricGroupUtils';

import MultiGroup from './MultiGroup';
import { EMPTY_MESSAGE_NONE } from './MultiGroupOrderSelector';
import MultiGroupStyles from './MultiGroupStyles';

type MultiGroupMetricProps = {
  classes: Classes<typeof MultiGroupStyles>;
  displayChips?: number;
  emptyMessage?: string;
  groups: MetricGroup[];
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  open?: boolean;
  selected?: string[];
  setOpen: (isOpen: boolean) => void;
  toggleSelected: (selected: string) => void;
};

const DISPLAY_CHIPS = 3;

const MultiGroupMetric: FC<MultiGroupMetricProps> = ({
  classes,
  displayChips = DISPLAY_CHIPS,
  emptyMessage = EMPTY_MESSAGE_NONE,
  groups,
  open = true,
  selected = [],
  setOpen,
  toggleSelected,
}) => {
  if (groups.length === 0)
    return <Typography className={classes.disabledEmpty}>{emptyMessage}</Typography>;

  const selectedNames = groups
    .map((g) => g.rows)
    .flat()
    .filter((r) => selected.includes(r.key))
    .map((r) => r.text);

  const metricGroupComponents = groups.map((group: MetricGroup) => (
    <CostReportMetricGroup
      key={group.headerText}
      metricGroup={group}
      selected={selected}
      toggleSelected={toggleSelected}
    />
  ));

  return (
    <MultiGroup
      displayChips={displayChips}
      emptyMessage={emptyMessage}
      open={open}
      selectedNames={selectedNames}
      setOpen={setOpen}
    >
      <>{metricGroupComponents}</>
    </MultiGroup>
  );
};

export default withStyles(MultiGroupStyles)(MultiGroupMetric);
