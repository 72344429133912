import { getCostValue } from '../components/CostReport/CostReportUtils';
import { getItemDescriptor } from '../components/Items/ItemsListItem/ItemsListItemUtils';
import {
  ACCEPTED,
  ALL_MILESTONES,
  INCORPORATED,
  INTRODUCTION,
  NOT_APPLICABLE,
  PENDING,
  REJECTED,
  SORT_CATEGORIES_LEVEL,
  SORT_COST_IMPACT,
  SORT_COST_IMPACT_MAGNITUDE,
  SORT_CREATION_TIME,
  SORT_CREATOR,
  SORT_DATE_MODIFIED,
  SORT_DUE_DATE,
  SORT_ITEM_CATEGORIZATION,
  SORT_ITEM_NUMBERS,
  SORT_NUMBER,
  SORT_STATUS,
  SORT_USER,
} from '../constants';
import { Visibility } from '../generated/graphql';

import { compareCostImpact, compareCostImpactMagnitude, compareStrings } from './comparison';

// UTILITIES

// Returns true if item was in milestone
const itemPreviousMilestoneStatus = (item: ItemLike, milestoneId: string) =>
  item.previousMilestoneStatus &&
  (item.previousMilestoneStatus as PreviousMilestoneStatus[]).find(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
    (s: { milestoneID: any }) => s.milestoneID === milestoneId
  );

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
export const getItemMilestoneState: any = (item: ItemLike, milestoneId: string) => {
  const milestoneStateId =
    milestoneId !== ALL_MILESTONES ? milestoneId : item.milestone && item.milestone.id;
  if (milestoneStateId) {
    const matchingStatus = itemPreviousMilestoneStatus(item, milestoneStateId);
    if (matchingStatus) return { ...item, ...matchingStatus };
  }
  return item;
};

const getItemCategorizedOrMilestoneState = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  item: ItemLike & { categorizedState: any },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  milestone: any
) => (item && item.categorizedState) || getItemMilestoneState(item, milestone);

export const statuses = [PENDING, ACCEPTED, REJECTED, INCORPORATED, NOT_APPLICABLE];

// SORT
// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
const sortNumber = (a: { number: any }, b: { number: any }) => compareStrings(a.number, b.number);

const sortCreation = (
  a: { creationTime: string | number | Date },
  b: { creationTime: string | number | Date }
) => {
  const aTime = new Date(a.creationTime).valueOf();
  const bTime = new Date(b.creationTime).valueOf();
  return aTime > bTime ? 1 : -1;
};

const sortDueDate = (
  a: { dueDate: string | number | Date },
  b: { dueDate: string | number | Date }
) => {
  if (a.dueDate && b.dueDate === null) return -1;
  if (a.dueDate === null && b.dueDate) return 1;
  if (a.dueDate === null && b.dueDate === null) return 0;
  const aTime = new Date(a.dueDate).valueOf();
  const bTime = new Date(b.dueDate).valueOf();
  return aTime > bTime ? 1 : -1;
};

const sortDateModified = (a: { updateTime: Date }, b: { updateTime: Date }) => {
  if (a.updateTime && b.updateTime === null) return -1;
  if (a.updateTime === null && b.updateTime) return 1;
  if (a.updateTime === null && b.updateTime === null) return 0;
  const aTime = new Date(a.updateTime).valueOf();
  const bTime = new Date(b.updateTime).valueOf();
  return aTime < bTime ? 1 : -1;
};

const getSortMagnitude = (cost: {
  max: number | undefined;
  min: number | undefined;
  value: undefined;
}) => {
  if (cost) {
    if (cost.max !== undefined && cost.min !== undefined) {
      if (Math.abs(cost.max) > Math.abs(cost.min)) return cost.max;
      return cost.min;
    }
    if (cost.value !== undefined) return cost.value;
  }
  return cost;
};

const sortMagnitude = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  a: (Option & { categorizedState: any }) | (Item & { categorizedState: any }),
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  b: (Option & { categorizedState: any }) | (Item & { categorizedState: any }),
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  m: any
) => {
  const aCostImpact = getItemCategorizedOrMilestoneState(a, m).costImpact;
  const bCostImpact = getItemCategorizedOrMilestoneState(b, m).costImpact;
  return compareCostImpactMagnitude(getSortMagnitude(aCostImpact), getSortMagnitude(bCostImpact));
};

// Sort by cost for itemLike state
const sortCostImpact = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  a: (Option & { categorizedState: any }) | (Item & { categorizedState: any }),
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  b: (Option & { categorizedState: any }) | (Item & { categorizedState: any }),
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  m: any
) => {
  const aCostImpact = getItemCategorizedOrMilestoneState(a, m).costImpact;
  const bCostImpact = getItemCategorizedOrMilestoneState(b, m).costImpact;
  return compareCostImpact(getCostValue(aCostImpact), getCostValue(bCostImpact));
};

// Sort by cost
export const compareCosts = (a?: Cost, b?: Cost) => {
  return compareCostImpact(getCostValue(a ?? 0), getCostValue(b ?? 0));
};

const sortUser = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  a: { email: any; name: { toLowerCase: () => number } },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  b: { email: any; name: { toLowerCase: () => number } }
) => {
  if (!a.email) return -1;
  if (!b.email) return 1;
  if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
  if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
  return 0;
};

const sortCreator = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  a: { createdBy: { email: any; name: { toLowerCase: () => number } } },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  b: { createdBy: { email: any; name: { toLowerCase: () => number } } }
) => {
  if (!a.createdBy) return -1;
  if (!b.createdBy) return 1;
  if (!a.createdBy.email) return -1;
  if (!b.createdBy.email) return 1;
  if (a.createdBy.name.toLowerCase() < b.createdBy.name.toLowerCase()) return -1;
  if (a.createdBy.name.toLowerCase() > b.createdBy.name.toLowerCase()) return 1;
  return 0;
};

const sortStatus = (a: ItemLike, b: ItemLike, m: string) => {
  const statusA = getItemMilestoneState(a, m).status;
  const statusB = getItemMilestoneState(b, m).status;
  return statuses.indexOf(statusA) - statuses.indexOf(statusB);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
const sortCategoryByLevel = (categories: any[]) => {
  categories.sort((a: { level: number }, b: { level: number }) => a.level - b.level);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
const sortItemNumber = (items: any[]) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  items.sort((a: { data: { number: any } }, b: { data: { number: any } }) =>
    compareStrings(a.data.number, b.data.number)
  );
  return items;
};

const sortCategorization =
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  (categorizationName: string) => (a: { categories: any[] }, b: { categories: any[] }) => {
    const aCat =
      a.categories &&
      a.categories.find(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
        (c: { categorization: { name: any } }) => c.categorization.name === categorizationName
      );
    const bCat =
      b.categories &&
      b.categories.find(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
        (c: { categorization: { name: any } }) => c.categorization.name === categorizationName
      );
    const aNum = aCat && aCat.number;
    const bNum = bCat && bCat.number;
    if (aNum && bNum === undefined) return -1;
    if (aNum === undefined && bNum) return 1;
    if (aNum === undefined && bNum === undefined) return 0;
    if (aNum === INTRODUCTION) return -1;

    return compareStrings(aNum, bNum);
  };

export const sortedCategoryChanges = (changes: CategoryChange[]): CategoryChange[] | [] =>
  changes.sort((a: CategoryChange, b: CategoryChange) => {
    const categoryA =
      a.oldCategory && a.oldCategory.categorization
        ? a.oldCategory.categorization
        : a.newCategory && a.newCategory.categorization;
    const categoryB =
      b.oldCategory && b.oldCategory.categorization
        ? b.oldCategory.categorization
        : b.newCategory && b.newCategory.categorization;
    return compareStrings((categoryA || {}).name, (categoryB || {}).name);
  });

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
type FnMap = Record<string, any>;

export const sortItemsBy: FnMap = {
  [SORT_NUMBER]: sortNumber,
  [SORT_CREATION_TIME]: sortCreation,
  [SORT_DUE_DATE]: sortDueDate,
  [SORT_DATE_MODIFIED]: sortDateModified,
  [SORT_COST_IMPACT_MAGNITUDE]: sortMagnitude,
  [SORT_COST_IMPACT]: sortCostImpact,
  [SORT_USER]: sortUser,
  [SORT_CREATOR]: sortCreator,
  [SORT_STATUS]: sortStatus,
  [SORT_CATEGORIES_LEVEL]: sortCategoryByLevel,
  [SORT_ITEM_NUMBERS]: sortItemNumber,
  [SORT_ITEM_CATEGORIZATION]: sortCategorization,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
function scrubFilter(filter: any) {
  return (filter || '')
    .toLowerCase()
    .split(',')
    .filter((f: string) => f !== ' ')
    .map((f: string) => f.trim());
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
export const shouldFilterItem = (i: ItemsListItem, search: any, isParentPrivate: boolean) => {
  // apply text search filter
  if (i) {
    const isPublished = i.visibility === Visibility.PUBLISHED;
    // Exclude published item (i.e. public items) when the parent is private.
    if (isPublished && isParentPrivate) return false;
    const searchFilters = scrubFilter(search);
    const name = i.name.toLowerCase();
    const itemNumber = getItemDescriptor(i);
    const categories =
      (i.categories &&
        i.categories
          // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
          .map((c: { name: any; number: any }) => `${c.name} ${c.number}`)
          .join(' ')
          .toLowerCase()) ||
      '';
    if (
      searchFilters &&
      !searchFilters.some(
        (f: string) =>
          name.includes(f.toLowerCase()) ||
          itemNumber.includes(f.toLowerCase()) ||
          categories.includes(f.toLowerCase())
      )
    )
      return false;
  }
  return true;
};
