import { SourceSystem } from '../../../../generated/graphql';
import { Button } from '../../../scales';

import { getSourceSystemIcon } from './IntegrationsUtils';

type Props = {
  isPrint?: boolean;
  isSidebar?: boolean;
  onClickAddAccount: () => void;
};

function IntegrationsEmptyState(props: Props) {
  if (props.isPrint) return <></>;
  return (
    <div className="bg-background-primary p-4">
      <div className="flex items-center justify-between bg-background-1 p-4">
        <div className="flex items-center gap-4">
          {getSourceSystemIcon(SourceSystem.AUTODESK)}
          {getSourceSystemIcon(SourceSystem.PROCORE)}
          <div className="flex flex-col gap-1">
            <div className="type-body3">Currently supporting Autodesk and Procore.</div>
            <div className="type-body1">
              Add a Partner account to link Join Items to your Partner application
            </div>
          </div>
        </div>
        <Button label="Add account" onClick={props.onClickAddAccount} type="primary" />
      </div>
      <div className="flex justify-center">
        <img
          alt="Link your partner account"
          className={props.isSidebar ? 'h-60' : 'h-100'}
          src="/img/Integrations/integrationsEmptyState.gif"
        />
      </div>
    </div>
  );
}

export default IntegrationsEmptyState;
