import { debounce } from 'lodash';
import { FC, useRef, useState } from 'react';

import { PROJECT_ACTIVITY_EVENT_TABLE_SCROLL_ID } from '../../../constants';
import { useShouldDisplayCosts } from '../../../utilities/permissions/useShouldDisplayCosts';
import ItemHistoryEventTable from '../../Events/ItemHistoryEvent/ItemHistoryEventTable';
import { ItemActivityEvent } from '../../Events/utils';
import ItemSidebarWrapper from '../../frame/ItemSidebar/ItemSidebarWrapper';

import ItemActivityFeedEmptyState from './ItemActivityFeedEmptyState';
import ItemActivityFeedHeader from './ItemActivityFeedHeader';
import { ItemActivityFeedReportType } from './ItemActivityFeedUtils';

type Props = {
  selectedReportType: ItemActivityFeedReportType;
  setReportType: (reportType: ItemActivityFeedReportType) => void;
  events: ItemActivityEvent[];
  projectID: UUID;
  fetchMoreEvents: () => void;
  milestones: Milestone[];
  isDoneFetching: boolean;
  isLoading: boolean;
  onItemMutated: () => void;
};

const ItemActivityFeed: FC<Props> = ({
  selectedReportType,
  setReportType,
  events,
  projectID,
  fetchMoreEvents,
  milestones,
  isDoneFetching,
  isLoading,
  onItemMutated,
}) => {
  const { shouldDisplayCosts } = useShouldDisplayCosts();
  const [selectedEventID, setSelectedEventID] = useState<string | null>(null);
  const showEventTable = !isLoading && events?.length;

  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const handleScroll = debounce(() => {
    if (scrollContainerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = scrollContainerRef.current;
      if (scrollTop + clientHeight >= scrollHeight - 200 && !isLoading) {
        fetchMoreEvents();
      }
    }
  }, 200);

  return (
    <div className="flex h-full w-full overflow-hidden">
      <div className="flex w-full flex-grow flex-col items-center overflow-auto bg-background-primary">
        <div className="inset-x-0 top-16 flex w-full justify-center shadow-md">
          <ItemActivityFeedHeader
            selectedReportType={selectedReportType}
            setReportType={setReportType}
          />
        </div>
        <div
          ref={scrollContainerRef}
          className={`mt-2 h-full max-h-[calc(100vh-185px)] w-full flex-grow ${
            showEventTable ? 'overflow-y-auto' : ''
          } `}
          id={PROJECT_ACTIVITY_EVENT_TABLE_SCROLL_ID}
          onScroll={handleScroll}
        >
          {showEventTable ? (
            <ItemHistoryEventTable
              canViewItemAttachments // The backend should automatically filter out any attachment events a user doesn't have access to
              events={events}
              isDoneFetching={isDoneFetching}
              projectID={projectID}
              selectedEventID={selectedEventID}
              setSelectedEventID={setSelectedEventID}
              shouldDisplayCosts={shouldDisplayCosts}
            />
          ) : (
            <ItemActivityFeedEmptyState
              isLoading={isLoading}
              selectedReportType={selectedReportType}
              setReportType={setReportType}
            />
          )}
        </div>
      </div>
      <ItemSidebarWrapper
        defaultExpanded=""
        milestones={milestones}
        onClose={() => setSelectedEventID(null)}
        onItemMutated={onItemMutated}
      />
    </div>
  );
};

export default ItemActivityFeed;
