import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';

export default (theme: KomodoTheme) =>
  createStyles({
    container: {
      ...theme.typography.webButton,
      boxSizing: 'border-box',
      display: 'flex',
      gap: '4px',
      height: 40,
      padding: '10px 14px',
      borderRadius: 24,
      borderWidth: 2,
      borderStyle: 'solid',
    },
    type_primary: {
      background: theme.palette.brand.black,
      borderColor: theme.palette.brand.black,
      color: theme.palette.brand.white,

      '&:focus': {
        background: theme.palette.brand.black,
        borderColor: theme.palette.app.focusOutline,
      },

      '&:hover': {
        background: theme.palette.brand.black,
        color: theme.palette.brand.white,
      },

      '&[disabled]': {
        color: theme.palette.app.disabledText,
        background: theme.palette.app.disabledBackground,
        borderColor: theme.palette.app.disabledBackground,
      },
    },
    type_secondary: {
      background: theme.palette.brand.white,
      borderColor: theme.palette.brand.black,
      color: theme.palette.brand.black,

      '&:focus': {
        background: theme.palette.brand.white,
        borderColor: theme.palette.app.focusOutline,
      },

      '&:hover': {
        background: theme.palette.brand.black,
        color: theme.palette.brand.white,
      },

      '&[disabled]': {
        color: theme.palette.app.disabledText,
        borderColor: theme.palette.app.disabledText,
      },
    },
    type_selected: {
      background: theme.palette.app.selected,
      borderColor: theme.palette.brand.black,
      color: theme.palette.brand.black,

      '&:focus': {
        background: theme.palette.app.selected,
        borderColor: theme.palette.app.focusOutline,
      },

      '&:hover': {
        background: theme.palette.app.focusOutline,
        color: theme.palette.brand.black,
      },

      '&[disabled]': {
        color: theme.palette.app.disabledText,
      },
    },
    type_text: {
      background: 'transparent',
      borderColor: 'transparent',
      color: theme.palette.brand.black,

      '&:focus': {
        background: theme.palette.brand.white,
        borderColor: theme.palette.app.focusOutline,
      },

      '&:hover': {
        background: theme.palette.app.focusOutline,
        color: theme.palette.brand.black,
      },

      '&[disabled]': {
        color: theme.palette.app.disabledText,
      },
    },
    type_link: {
      ...theme.typography.webParagraph,
      background: theme.palette.brand.white,
      borderColor: theme.palette.brand.white,
      color: theme.palette.brand.middleBlue,
      borderRadius: 4,
      padding: '4px 6px',

      '&:focus': {
        background: theme.palette.app.highlight,
        color: theme.palette.brand.middleBlue,
      },

      '&:hover': {
        ...theme.typography.webLink,
        background: theme.palette.brand.white,
        color: theme.palette.brand.middleBlue,
      },

      '&[disabled]': {
        color: theme.palette.app.disabledText,
      },
    },
    fullWidth: {
      width: '100%',
    },
    iconAndText: {
      padding: '6px 14px 6px 6px',
    },
    iconOnly: {
      padding: '6px',
    },
  });
