import { createStyles } from '@material-ui/core';

export const HEADER_HEIGHT = 88;

export const titleStyle = createStyles({
  title: {
    display: 'flex',
    flex: '1 0 315px',
    flexDirection: 'column',
    gap: '8px',
  },
});
