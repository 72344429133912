import { CompanyProjectTypesQuery, CompanyProjectTypesQueryVariables } from '../generated/graphql';

import { companyProjectTypes } from './queries';
import { useQuery } from './useMountAwareQuery';

export const useCompanyProjectTypesQuery = (includeHidden: boolean, companyID?: UUID) =>
  useQuery<CompanyProjectTypesQuery, CompanyProjectTypesQueryVariables>(companyProjectTypes, {
    variables: { companyID, includeHidden },
    skip: !companyID,
  });
