import { projectCompsSetInputVar } from '../../../api/apollo/reactiveVars';
import { ProjectCompsSetQuery, ProjectCompsSetQueryVariables } from '../../../generated/graphql';
import { useQuery } from '../../../hooks/useMountAwareQuery';
import { MountPolicy } from '../../../hooks/usePolicyOnFirstMount';

import { projectCompsSetQuery } from './queries';

export const useProjectCompsSetQuery = (reportID?: UUID) =>
  useQuery<ProjectCompsSetQuery, ProjectCompsSetQueryVariables>(
    projectCompsSetQuery,
    {
      variables: {
        projectCompsSetInput: projectCompsSetInputVar(),
        reportID,
      },
    },
    MountPolicy.FETCH_ON_VARS
  );
