import * as React from 'react';

import { SearchTextMatch, getMatchIndexes } from './SearchTextUtils';

type SearchTextProps = {
  searchTerm: string;
  text: string;
};

// Used to highlight some portion of text when searching for a value
const SearchText: React.FC<SearchTextProps> = ({ searchTerm, text }) => {
  const matches: SearchTextMatch[] = getMatchIndexes(searchTerm, text);
  // bail if no matches
  if (matches.length === 0) {
    return <span>{text}</span>;
  }

  const beforeFirstMatchText = text.substring(0, matches[0][0]);
  const matchHtml = matches.map((match: number[], index: number) => {
    // if there is another match after this one
    const next = matches[index + 1]
      ? text.substring(match[1], matches[index + 1][0])
      : text.substring(match[1], text.length);
    return (
      <span key={`${match[0]}-${text.length - index}`}>
        <u>
          <strong>{text.substring(match[0], match[1])}</strong>
        </u>
        <span>{next}</span>
      </span>
    );
  });
  return (
    <span>
      {searchTerm.length > 0 ? (
        <>
          {beforeFirstMatchText}
          {matchHtml}
        </>
      ) : (
        <span>{text}</span>
      )}
    </span>
  );
};

export default SearchText;
