import { useState } from 'react';

import {
  DeleteOutline,
  Edit,
  MoreVert,
  OpenInNewOutlined,
  RemoveCircleOutline,
} from '@material-ui/icons';

import { Status } from '../../../../../../../generated/graphql';
import DialogsConfirm from '../../../../../../Dialogs/DialogsConfirm/DialogsConfirm';
import { isScenarioVisibility } from '../../../../../../Items/ItemsUtils';
import { BabyMenuButton, MenuEntry } from '../../../../../../scales';
import { ScenarioItemEntry } from '../../../../../common/ItemStatus/ItemStatusUtils';

import StatusCellEditSelect from './StatusCellEditSelect';

enum ItemActions {
  DELETE = 'Delete',
  REMOVE = 'Remove',
  NAV = 'View Item Details',
  EDIT = 'Edit',
}

type ItemUpdate = {
  itemID: UUID;
  status: Status;
};

type Props = {
  item: ScenarioItemEntry;
  onDeleteScenarioItem: (itemID: UUID) => void;
  onEditItem: (itemID: UUID) => void;
  onNavToItem: (itemID: UUID) => void;
  onRemoveItem: (itemID: UUID) => void;
  onUpdateItems: (changes: ItemUpdate[]) => void;
};

export default function StatusCell(props: Props) {
  const itemID = props.item.id;
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const entries: MenuEntry[] = isScenarioVisibility(props.item.visibility)
    ? [
        {
          id: ItemActions.EDIT,
          label: ItemActions.EDIT,
          onClick: () => props.onEditItem(itemID),
          startAdornment: <Edit />,
        },
        {
          id: ItemActions.DELETE,
          label: 'Delete',
          onClick: () => setDeleteDialogOpen(true),
          startAdornment: <DeleteOutline />,
          type: 'destructive',
        },
      ]
    : [
        {
          id: ItemActions.REMOVE,
          label: ItemActions.REMOVE,
          onClick: () => props.onRemoveItem(itemID),
          startAdornment: <RemoveCircleOutline />,
        },
        {
          id: ItemActions.NAV,
          label: ItemActions.NAV,
          onClick: () => props.onNavToItem(itemID),
          startAdornment: <OpenInNewOutlined />,
        },
      ];

  return (
    <div className="flex h-8 flex-grow items-center gap-1 type-table-text">
      <StatusCellEditSelect item={props.item} onUpdateItems={props.onUpdateItems} />
      <BabyMenuButton
        aria-label="more options"
        data-cy="item-row-menu-button"
        entries={entries}
        icon={<MoreVert />}
      />
      <DialogsConfirm
        acceptCtaCopy="Delete"
        body={`Are you sure you want to delete "${props.item.name}" from this scenario?`}
        destructiveConfirm
        onClose={() => setDeleteDialogOpen(false)}
        onConfirm={() => props.onDeleteScenarioItem?.(itemID)}
        open={deleteDialogOpen}
        title="Delete Scenario Item"
      />
    </div>
  );
}
