import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';

const DataTableStyles = (theme: KomodoTheme) =>
  createStyles({
    chartText: {
      '& p': {
        ...theme.typography.h3,
      },
    },
    chartTextBold: {
      '& p': {
        ...theme.typography.h3TableHeader,
      },
    },
    dataCell: {
      '& p': {
        textAlign: 'right',
        ...theme.typography.number,
      },
    },
    disabled: {
      ...theme.typography.body1,
      color: theme.palette.disabledGrey,
    },
    footerRow: {
      '& th': {
        borderColor: theme.palette.disabledGrey,
        borderStyle: 'solid',
        borderWidth: '1px 0px 0px 0px',
      },
      '& p': {
        ...theme.typography.number,
        paddingTop: 4,
      },
    },
    fullWidth: {
      width: '100%',
    },
    halfWidth: {
      width: '50%',
    },
    headerRow: {
      '& th': {
        borderColor: theme.palette.disabledGrey,
        borderStyle: 'solid',
        borderWidth: '0 0 1px 0',
      },
    },
    hintText: {
      '& p': {
        ...theme.typography.h4,
      },
    },
    hintTextBold: {
      '& p': {
        ...theme.typography.h4Title,
      },
    },
    iconCell: {
      paddingRight: 3,
      width: 26,
    },
    leftAlign: {
      textAlign: 'left',
    },
    nameCell: {
      paddingTop: 2,
      paddingBottom: 0,
      maxWidth: 80,
    },
    rightAlign: {
      textAlign: 'right',
    },
    row: {
      borderBottom: `solid 1px ${theme.palette.joinGrey400}`,
      height: 25,
    },
    rowName: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    table: {
      borderCollapse: 'collapse',
      '& th': {
        verticalAlign: 'bottom',
      },
    },
  });

export default DataTableStyles;
