import { CostTableColumnInput } from '../../../../generated/graphql';
import { Checkbox } from '../../../scales';
import { getColumnInputDisplayString } from '../../ProjectCompsSetUtils';

import {
  MAX_NUM_COL_INPUTS,
  columnInputIsSelected,
  getCheckboxOptions,
} from './EditCostGridsUtils';

type Props = {
  onSelect: (inputs: CostTableColumnInput) => void;
  selectedColumnInputs: CostTableColumnInput[];
  units?: Unit[];
};

export default function EditCostGridsCheckboxes(props: Props) {
  return (
    <div className="flex h-64 w-40 flex-col gap-2 overflow-auto">
      {getCheckboxOptions(props.units).map((option) => {
        const unit = props.units?.find((u) => u.id === option.unitID);
        const isSelected = columnInputIsSelected(props.selectedColumnInputs, option);
        const label = getColumnInputDisplayString(
          option.key,
          unit?.abbreviationSingular,
          true,
          true
        );
        return (
          <Checkbox
            key={`${option.key}${option.unitID}`}
            aria-label="column  is selected"
            data-cy={`columnInput-${label}`}
            fullWidth
            isDisabled={!isSelected && props.selectedColumnInputs.length >= MAX_NUM_COL_INPUTS}
            isSelected={isSelected}
            onChange={() => props.onSelect(option)}
          >
            <div className="type-body1">{label}</div>
          </Checkbox>
        );
      })}
    </div>
  );
}
