import { ReactNode, forwardRef, useRef } from 'react';
import { VisuallyHidden, mergeProps, useCheckbox, useFocusRing } from 'react-aria';
import { useToggleState } from 'react-stately';

import { StrictUnion } from '../../../utilities/types';
import { TooltipTriggerProps, pickTooltipTriggerProps } from '../Tooltip';

type BaseProps = TooltipTriggerProps & {
  /** Required if you don't provide `children`. */
  'aria-label'?: string;
  /** Required if you don't provide `aria-label`. */
  children?: ReactNode;
  'data-cy'?: string;
  description?: ReactNode;
  fullWidth?: boolean;
  isDisabled?: boolean;
  /** Use this in combination with `isSelected` to indicate a "mixed" state such as the parent of a tree of checkboxes. */
  isIndeterminate?: boolean;
  isSelected?: boolean;
  onBlur?: Parameters<typeof useCheckbox>[0]['onBlur'];
  onChange?: Parameters<typeof useCheckbox>[0]['onChange'];
};

type LabelledProps = BaseProps & {
  'aria-label'?: string;
  children: ReactNode;
};

type UnlabelledProps = BaseProps & {
  'aria-label': string;
  children?: ReactNode;
};

type Props = StrictUnion<LabelledProps | UnlabelledProps>;

export default forwardRef<HTMLLabelElement, Props>(function Checkbox(props, forwardedRef) {
  const ref = useRef<HTMLInputElement>(null);

  const state = useToggleState({
    isSelected: props.isSelected,
    onChange: props.onChange,
  });

  const { inputProps, isDisabled, isSelected } = useCheckbox(
    {
      'aria-label': props['aria-label'],
      children: props.children,
      onBlur: props.onBlur,
      onChange: props.onChange,
      isDisabled: props.isDisabled,
      isIndeterminate: props.isIndeterminate,
      isSelected: state.isSelected,
    },
    state,
    ref
  );
  const { isFocusVisible, focusProps } = useFocusRing();

  let icon = <CheckUnselected isDisabled={isDisabled} />;
  if (isSelected) {
    icon = props.isIndeterminate ? <CheckIndeterminate /> : <CheckSelected />;
  }

  let iconColorClassName = isSelected ? 'text-checkbox-selected' : 'text-checkbox-unselected';
  if (isFocusVisible) {
    iconColorClassName = 'text-checkbox-focused';
  }
  if (isDisabled) {
    iconColorClassName = 'text-checkbox-disabled';
  }

  return (
    <label
      {...pickTooltipTriggerProps(props)}
      // One use case for ref is to position a tooltip
      ref={forwardedRef}
      // Why is `relative` here and /critical/?
      // https://github.com/adobe/react-spectrum/issues/5094#issuecomment-1724825584
      className={[
        'relative flex min-w-0 items-center gap-2',
        props.fullWidth ? 'w-full' : '',
        isDisabled ? 'cursor-not-allowed' : 'cursor-pointer',
      ].join(' ')}
      data-cy={props['data-cy']}
    >
      <VisuallyHidden>
        <input {...mergeProps(inputProps, focusProps)} ref={ref} />
      </VisuallyHidden>
      <div aria-hidden="true" className={iconColorClassName} data-cy="checkbox-icon">
        {icon}
      </div>
      {props.children && (
        <div
          className={`flex flex-grow flex-col gap-1 truncate ${
            isDisabled ? 'text-type-inactive' : 'text-type-primary'
          }`}
        >
          <div className="type-body1" data-cy="checkbox-label">
            {props.children}
          </div>
          {props.description && (
            <div className="type-label" data-cy="checkbox-description">
              {props.description}
            </div>
          )}
        </div>
      )}
    </label>
  );
});

const CheckUnselected = (props: { isDisabled?: boolean }) => (
  <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19 5V19H5V5H19ZM19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3Z"
      fill="currentColor"
    />
    {props.isDisabled && (
      <rect className="fill-checkbox-disabled-bg" height="14" width="14" x="5" y="5" />
    )}
  </svg>
);

const CheckSelected = () => (
  <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19 3H5C3.89 3 3 3.9 3 5V19C3 20.1 3.89 21 5 21H19C20.11 21 21 20.1 21 19V5C21 3.9 20.11 3 19 3ZM10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z"
      fill="currentColor"
    />
  </svg>
);

const CheckIndeterminate = () => (
  <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3ZM17 13H7V11H17V13Z"
      fill="currentColor"
    />
  </svg>
);
