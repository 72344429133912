import { createStyles } from '@material-ui/core';

import { MAX_COL_WIDTH } from '../../../constants';
import { KomodoTheme } from '../../../theme/komodo-mui-theme';

export default (theme: KomodoTheme) =>
  createStyles({
    inputs: {
      padding: 2,
      paddingRight: 8,
      border: theme.border.line,
      maxWidth: MAX_COL_WIDTH,
      margin: 'auto',
    },
    header: {
      padding: '4px 24px',
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'space-between',
      height: 56,
    },
    textButton: {
      alignItems: 'end',
      fontWeight: 500,
    },
    headerText: {
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    newEstimate: {
      padding: 24,
      height: '100%',
    },
    normal: {},
    dragging: {},
    draggingInputs: {
      border: `2px solid ${theme.palette.borderBlue}`,
    },
    spacer: { flexGrow: 1 },
    replaceUpload: {
      padding: 16,
    },
    newImportWizardInputs: {
      padding: 2,
      paddingRight: 8,
      margin: 'auto',
    },
    newImportWizardnewEstimate: {
      padding: '12px 0px',
      height: '85%', // note: this is dependent on the height of the page header, so if that increases we may get some y-overflow on the page
    },
    fullPage: {
      height: '100%',
      width: '100%',
    },
    newInputs: {
      margin: 'auto',
      height: '100%', // note: controls the drag and drop zone
    },
  });
