import { createStyles } from '@material-ui/core';

export const ROW_CONTAINER_HEIGHT = 48;

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
const AllMilestonesChartStyles = (theme: any) =>
  createStyles({
    axisLabel: {
      fill: theme.palette.primaryGrey,
      fontWeight: 400,
      '& text': {
        fill: 'black',
        fontSize: 15,
      },
    },
    date: {
      paddingLeft: 12,
      paddingRight: 12,
    },
    hint: {
      zIndex: 999,
      paddingTop: 20,
      color: theme.palette.primaryGrey,
      background: theme.palette.backgroundWhite,
      '@media print': {
        display: 'none',
      },
    },
    hintSummary: {
      paddingTop: 8,
      paddingBottom: 4,
    },
    item: {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
    linechart: {
      fontFamily: theme.typography.fontFamily,
    },
    rowContainer: {
      display: 'flex',
      flexDirection: 'row',
      minHeight: ROW_CONTAINER_HEIGHT,
    },
    spacer: {
      flexGrow: 1,
    },
    tickContainer: {
      width: '100%',
      height: '100%',
      overflow: 'visible',
      paddingLeft: 2,
      paddingTop: 6,
    },
    ticks: {
      fontSize: 11,
      color: theme.palette.primaryGrey,
      height: 12,
      overflowX: 'hidden',
      overflowY: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
  });

export default AllMilestonesChartStyles;
