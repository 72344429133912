import queryString from 'query-string';
import { FC } from 'react';

import { Typography } from '@material-ui/core';

import { TOTAL } from '../../../../../constants';
import { withStyles } from '../../../../../theme/komodo-mui-theme';
import { formatCommas } from '../../../../../utilities/currency';
import { getMilestoneIdFromUrl } from '../../../../../utilities/url';
import { COSTREPORT_DEFAULTS } from '../../../../CostReport/CostReport/useCostReportParams';
import { getUnitText } from '../../../../CostReport/CostReportList/CostReportList/CostReportListUtils';
import { GroupBy } from '../../../../Icons/GroupBy';
import QuantityTextField from '../../../../Inputs/QuantityTextField/QuantityTextField';
import MilestoneReportLink from '../../../../Milestones/MilestoneReportLink';
import NormalTooltip from '../../../../NormalTooltip/NormalTooltip';
import CollapseIcon from '../../../../shared-widgets/CollapseIcon';
import { useCategorizationsForQuantity } from '../../../../shared-widgets/MultiGroup/MultiGroupOrderCategorizationUtils';
import {
  QuantityListItem,
  formatGroupByDisplay,
  getGroupByNames,
  listGroupBy,
} from '../MilestoneDetailsQuantitiesUtils';

import MilestoneDetailsQuantitiesCollapseHeaderStyles from './MilestoneDetailsQuantitiesCollapseHeaderStyles';

const getMilestoneReportLinkCopy = (unitAbbreviation: string, isCategorized: boolean) =>
  `Milestone Report (${isCategorized ? `Categorized` : `Total`} ${unitAbbreviation})`;

type MilestoneDetailsQuantitiesCollapseHeaderProps = {
  canEditMetrics: boolean;
  canViewMetrics: boolean;
  classes: Classes<typeof MilestoneDetailsQuantitiesCollapseHeaderStyles>;
  getSetMagnitude: (unitID: UUID) => (magnitude: number) => void;
  getSubmitFunc: (unitID: UUID) => () => void;
  quantityListItem: QuantityListItem;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  quantityOpen: boolean;
  setCollapse: () => void;
};

const MilestoneDetailsQuantitiesCollapseHeader: FC<
  MilestoneDetailsQuantitiesCollapseHeaderProps
> = ({
  canEditMetrics,
  canViewMetrics,
  classes,
  getSetMagnitude,
  getSubmitFunc,
  quantityListItem,
  quantityOpen,
  setCollapse,
}) => {
  const milestoneID = getMilestoneIdFromUrl();
  const {
    abbreviationPlural,
    abbreviationSingular,
    cy,
    hasBreakdown,
    magnitude,
    name,
    placeholder,
    unitID,
  } = quantityListItem;
  const { categorizations, loading } = useCategorizationsForQuantity(quantityListItem);
  const categorizedByText = hasBreakdown ? formatGroupByDisplay(categorizations) : 'Uncategorized';
  const categorizedBy = getGroupByNames(categorizations);
  const disabledClass = !hasBreakdown ? classes.disabled : '';
  const categorizedClass = `${classes.quantityGroupByText} ${disabledClass}`;
  const categorizedQuantityComponent = (
    <div className={classes.quantityGroupBy}>
      <NormalTooltip title={listGroupBy(categorizations)}>
        <GroupBy className={disabledClass} />
      </NormalTooltip>
      <Typography className={categorizedClass} variant="subheading">
        {categorizedByText}
      </Typography>
    </div>
  );

  const hasValue = !!magnitude && magnitude > 0;
  const shouldDisable = !canEditMetrics || !canViewMetrics || hasBreakdown;
  const showBreakdown = canEditMetrics ? canViewMetrics : canViewMetrics && hasBreakdown;

  const quantityMagnitude = shouldDisable ? (
    <Typography
      className={classes.quantityText}
      data-cy={`text-Quantity-${cy}`}
      variant="subheading"
    >
      {formatCommas(magnitude || 0)}
    </Typography>
  ) : (
    <QuantityTextField
      key={unitID}
      cy={cy}
      disabled={shouldDisable}
      id={unitID}
      magnitude={magnitude}
      placeholder={placeholder}
      setMagnitude={getSetMagnitude(unitID)}
      submit={getSubmitFunc(unitID)}
    />
  );

  const milestoneReportLink = (
    isCategorized: boolean,
    abbreviationSingular: string,
    categorizedBy: string[]
  ) => {
    const metrics = [getUnitText(abbreviationSingular, true, isCategorized)];
    const subcolumns = [TOTAL, getUnitText(abbreviationSingular, false, isCategorized)];
    const params = { ...COSTREPORT_DEFAULTS, metrics, subcolumns };
    if (categorizedBy.length) params.groupBy = categorizedBy;
    const searchString = `?${queryString.stringify(params, { arrayFormat: 'index' })}`;

    return (
      <div className={classes.linkContainer}>
        <MilestoneReportLink
          linkCopy={getMilestoneReportLinkCopy(abbreviationPlural, isCategorized)}
          milestoneId={milestoneID}
          search={searchString}
        />
      </div>
    );
  };

  return (
    <div className={classes.container}>
      <div className={classes.headerContainer}>
        <div
          className={`${classes.header} ${showBreakdown && classes.canExpand}`}
          data-cy={`collapse-${name}`}
          onClick={() => setCollapse()}
          onKeyDown={() => {}}
          role="button"
          tabIndex={-1}
        >
          <div className={classes.rowTitle}>
            {showBreakdown && <CollapseIcon isCollapsed={!quantityOpen} />}
            <div className={classes.rowTitleInner}>
              <Typography
                className={classes.title}
                data-cy={`input-QuantityLabel-${cy}`}
                variant="subheading"
              >
                {name}
              </Typography>
            </div>
          </div>
          <div className={classes.headerRowContainer}>
            <div className={classes.headerRowCenter}>
              <div className={classes.reportsContainer}>
                {hasValue && milestoneReportLink(false, abbreviationSingular, categorizedBy)}
                {hasBreakdown && milestoneReportLink(true, abbreviationSingular, categorizedBy)}
              </div>
            </div>
            <div className={classes.headerRowRight}>
              {loading ? <></> : categorizedQuantityComponent}
              <div
                className={classes.quantityContainer}
                onClick={(e) => {
                  e.stopPropagation();
                }}
                onKeyDown={() => {}}
                role="button"
                tabIndex={-1}
              >
                <div className={classes.quantityValue}>{quantityMagnitude}</div>
              </div>
              <div className={classes.unitContainer}>
                <Typography variant="subheading">{abbreviationPlural}</Typography>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withStyles(MilestoneDetailsQuantitiesCollapseHeaderStyles)(
  MilestoneDetailsQuantitiesCollapseHeader
);
