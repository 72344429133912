import {
  ItemDataQuery,
  ItemDataQueryVariables,
  SourceItemQuery,
  SourceItemQueryVariables,
} from '../../../generated/graphql';
import { QueryHookOptions, useQuery } from '../../../hooks/useMountAwareQuery';
import { MountPolicy } from '../../../hooks/usePolicyOnFirstMount';
import { useCostMode } from '../../../utilities/costMode';

import { itemQuery, sourceItemQuery } from './itemQuery';

export const useItemQuery = (
  id?: UUID,
  mountPolicy?: MountPolicy,
  customCostMode?: CostMode,
  options?: QueryHookOptions<ItemDataQuery, ItemDataQueryVariables>
) => {
  let costMode = useCostMode();
  if (customCostMode) costMode = customCostMode;
  return useQuery<ItemDataQuery, ItemDataQueryVariables>(
    itemQuery,
    {
      ...options,
      variables: {
        id,
        costMode,
      },
      skip: !id,
      context: {
        batch: true,
      },
    },
    mountPolicy
  );
};

export const useSourceItemQuery = (id?: UUID) =>
  useQuery<SourceItemQuery, SourceItemQueryVariables>(sourceItemQuery, {
    variables: {
      id,
      costMode: useCostMode(),
    },
    skip: !id,
  });
