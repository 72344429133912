import { useState } from 'react';

import { ProjectIdea } from '../types';

import AlternateTiles from './AlternateTiles';
import IdeaHeader from './IdeaHeader';
import IdeaRelevance from './IdeaRelevance';
import IdeaTabs from './IdeaTabs';

type Props = { projectID: UUID; idea: ProjectIdea; onCreateItem: (alternateID?: UUID) => void };

export default function Idea(props: Props) {
  const [includedAlternates, setIncludedAlternates] = useState<UUID[]>(
    props.idea.alternates.map(({ id }) => id)
  );
  const handleToggleAlternate = (alternateID: UUID) => {
    setIncludedAlternates((prev) => {
      return prev.includes(alternateID)
        ? prev.filter((id) => id !== alternateID)
        : [...prev, alternateID];
    });
  };

  return (
    <div className="flex flex-col gap-6 py-6">
      <div className="flex flex-col gap-6 px-6">
        <IdeaHeader
          idea={props.idea}
          onCreateItem={props.onCreateItem}
          projectID={props.projectID}
        />
        <IdeaRelevance idea={props.idea} />
        <div className="flex flex-col gap-4">
          <h2 className="type-heading2">Overview</h2>
          <AlternateTiles
            idea={props.idea}
            included={includedAlternates}
            onCreateItem={props.onCreateItem}
            onToggle={handleToggleAlternate}
          />
        </div>
      </div>
      <div className="flex flex-col gap-4">
        <h2 className="px-6 type-heading2">Details</h2>
        <IdeaTabs idea={props.idea} includedAlternates={includedAlternates} />
      </div>
    </div>
  );
}
