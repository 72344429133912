import { useParams } from 'react-router-dom';

import Role from './Role';
import { useRoles } from './RolesRoute';

const RoleRoute = () => {
  const { projectId, roleId } = useParams();

  if (!projectId) {
    throw new Error('Failed to get projectId param');
  }

  if (!roleId) {
    throw new Error('Failed to get roleId param');
  }

  const roles = useRoles();

  return <Role projectID={projectId} roleID={roleId} roles={roles} />;
};

export default RoleRoute;
