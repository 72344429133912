import { ComponentProps, useRef } from 'react';
import { Link } from 'react-router-dom';

import { Tooltip } from '../../scales';

type Props = { hasAccess: boolean } & ComponentProps<typeof Link>;

export default function SearchResultLink(props: Props) {
  const ref = useRef<HTMLAnchorElement>(null);

  const className = 'flex items-center gap-4 rounded p-2 text-left';

  if (!props.hasAccess) {
    return (
      <Tooltip content="You don't have access to this project.">
        <div className={`${className} cursor-not-allowed`}>{props.children}</div>
      </Tooltip>
    );
  }

  return (
    <Link
      className={`${className} outline-none focus-visible:bg-button-secondary-hover`}
      {...props}
      ref={ref}
      onKeyDown={(e) => {
        if (e.key === 'ArrowDown') {
          const nextElement = ref.current?.nextElementSibling;
          if (nextElement instanceof HTMLAnchorElement) {
            nextElement.focus();
          }
        } else if (e.key === 'ArrowUp') {
          const prevElement = ref.current?.previousElementSibling;
          if (prevElement instanceof HTMLAnchorElement) {
            prevElement.focus();
          }
        }
      }}
    />
  );
}
