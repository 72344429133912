import { commonCategoryUniqueFields, gql } from '../../../../api/graphqlFragments';

export const listCategoriesQuery = gql`
  query listCategories($categorizationID: UUID!, $term: String!, $limit: Int, $level: Int) {
    category(
      search: { categorizationID: $categorizationID, term: $term, limit: $limit, level: $level }
    ) {
      ...commonCategoryUniqueFields
    }
  }
  ${commonCategoryUniqueFields}
`;
