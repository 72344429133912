import { ComponentProps, Suspense, lazy } from 'react';

const ScrollShadow = lazy(() => import('./ScrollShadow'));

export default function LazyLoadedScrollShadow(props: ComponentProps<typeof ScrollShadow>) {
  return (
    <Suspense fallback={<></>}>
      <ScrollShadow {...props} />
    </Suspense>
  );
}
