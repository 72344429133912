import { YC_DEMO_MOCKS_INSIGHTS_V2 } from '../../../features';
import {
  ContingenciesTimeseries,
  CostTimeseries,
  InsightsActiveAlert,
  InsightsMilestone,
  InsightsProjectQuery,
  InsightsProjectQueryVariables,
  OrgNode,
} from '../../../generated/graphql';
import { useHasFeature } from '../../../hooks/useHasFeature';
import { useQuery } from '../../../hooks/useMountAwareQuery';
import { InsightsProject } from '../types';

import { fixProjectMocks } from './demoData';
import { insightsProjectQuery } from './queries';

export const useInsightsProjectQuery = (projectID: UUID, companyID: UUID) => {
  const useDemoData = useHasFeature(YC_DEMO_MOCKS_INSIGHTS_V2);
  const query = useQuery<InsightsProjectQuery, InsightsProjectQueryVariables>(
    insightsProjectQuery,
    {
      variables: { projectID, companyID },
      skip: !projectID,
    }
  );
  const insightsProjectData =
    query?.data?.insightsProject ||
    ({
      activeAlerts: [] as InsightsActiveAlert[],
      milestones: [] as InsightsMilestone[],
      orgNodes: [] as OrgNode[],
      costTimeseries: [] as CostTimeseries[],
      contingenciesTimeseries: [] as ContingenciesTimeseries[],
    } as InsightsProject);

  return {
    ...query,
    data: !useDemoData ? insightsProjectData : fixProjectMocks(insightsProjectData),
  };
};
