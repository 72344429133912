import { useEffect, useState } from 'react';

import { getTodayUtcNoon } from '../../../../utilities/dates';
import TableChartPlaceholder from '../../Charts/TableChartPlaceholder';
import { useItems } from '../../ProjectBabyCharts/hooks/useItems';
import { seriesToTimeline } from '../../ProjectBabyCharts/ProjectItems';
import { InsightsProject } from '../../types';
import { CHART_HEIGHT, LEFT_MARGIN, PADDING, TOTAL_HEIGHT } from '../utils';

import TimelineDueDateChart from './TimelineDueDateChart';

const AXIS_HEIGHT = 32;

export default function ProjectItems(props: {
  project: InsightsProject;
  startStr: string;
  endStr: string;
  onZoom?: (domain: string[]) => void;
  today?: Date;
}) {
  const today = props.today ?? getTodayUtcNoon();
  const milestones = props.project?.milestones || [];
  const activeMilestoneID = milestones.find((m) => m.active)?.id;

  const { items: realItems, loading } = useItems(props.project.id, activeMilestoneID || '');
  const items = seriesToTimeline(props.project?.itemsDueDateSeriesMockData) || realItems;

  // Min and Max date range values for zoom domain and pickers
  const [startStr, setStartStr] = useState<string>(props.startStr);
  const [endStr, setEndStr] = useState<string>(props.endStr);

  useEffect(() => {
    if (startStr !== props.startStr) setStartStr(props.startStr);
    if (endStr !== props.endStr) setEndStr(props.endStr);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.startStr, props.endStr]);

  const setStartEnd = ([start, end]: string[]) => {
    setStartStr(start);
    setEndStr(end);
  };

  const onZoom = (domain: string[]) => {
    setStartEnd(domain);
    props.onZoom?.(domain);
  };

  if (loading) return <TableChartPlaceholder height={CHART_HEIGHT - AXIS_HEIGHT + PADDING * 2} />;

  const chart = (
    <TimelineDueDateChart
      height={CHART_HEIGHT - AXIS_HEIGHT}
      items={items}
      onZoom={onZoom}
      projectID={props.project.id}
      today={today.toISOString()}
      totalRange={[startStr, endStr]}
    />
  );

  return (
    <div
      className={`h-[${TOTAL_HEIGHT - AXIS_HEIGHT}px] min-w-full bg-background-primary`}
      style={{ paddingLeft: LEFT_MARGIN, paddingTop: PADDING, paddingBottom: PADDING }}
    >
      {chart}
    </div>
  );
}
