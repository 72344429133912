import { FC } from 'react';

import { Button, Typography } from '@material-ui/core';

import { START_REPORT_BUTTON } from '../../../../tagConstants';
import { withStyles } from '../../../../theme/komodo-mui-theme';

import styles from './ReportsListEntryStartReportStyles';

export type ReportsListEntryStartReportType = {
  onStart: () => void;
};

type ClassesType = {
  classes: Classes<typeof styles>;
  isViewOnly: boolean;
};

interface ReportsListEntryStartReportProps extends ClassesType, ReportsListEntryStartReportType {}

const ReportsListEntryStartReport: FC<ReportsListEntryStartReportProps> = ({
  classes,
  onStart,
  isViewOnly,
}) => (
  <div className={classes.root}>
    <Button
      className={classes.button}
      color="primary"
      data-cy={START_REPORT_BUTTON}
      disabled={isViewOnly}
      disableRipple
      onClick={onStart}
      variant="outlined"
    >
      <Typography className={classes.text}>Start Report</Typography>
    </Button>
  </div>
);

export const StyledReportsListEntryStartReport = withStyles(styles)(ReportsListEntryStartReport);

export default StyledReportsListEntryStartReport;
