import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';

const ExecutiveDashboardProjectActiveUsersStyles = (theme: KomodoTheme) =>
  createStyles({
    placeholder: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: theme.palette.chartMediumGrey,
      margin: 16,
      marginBottom: 0,
      height: 126,
    },
    placeholderLegend: {
      paddingLeft: 8,
      paddingTop: 8,
    },
    placeholderText: {
      color: theme.palette.chartGrey,
      textAlign: 'center',
      fontWeight: 700,
    },
  });

export default ExecutiveDashboardProjectActiveUsersStyles;
