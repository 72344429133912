import { useMutation } from '@apollo/client';

import {
  ToggleEventDataVisibilityMutation,
  ToggleEventDataVisibilityMutationVariables,
} from '../generated/graphql';

import { toggleEventDataVisibilityMutation } from './queries';

export function useToggleEventDataVisibilityMutation(refetch: () => void) {
  const [toggleEventDataVisibility] = useMutation<
    ToggleEventDataVisibilityMutation,
    ToggleEventDataVisibilityMutationVariables
  >(toggleEventDataVisibilityMutation);

  const toggleFunction = (projectID: UUID, visible: boolean, eventIds: UUID[]) => {
    toggleEventDataVisibility({
      variables: { projectID, visible, events: eventIds },
    }).then(refetch);
  };
  return toggleFunction;
}
