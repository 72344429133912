import { INTRODUCTION } from '../../../../constants';
import useListCategoriesByIDsQuery from '../../../dragon-scales/CategoryMultiSelect/useListCategoriesByIDsQuery';

type Props = {
  categorizationID: UUID;
  categoryIDs: UUID[];
  getDataLine: (label: string, text: string) => JSX.Element;
};
const FilterSpan = (props: Props) => {
  const list =
    useListCategoriesByIDsQuery(props.categorizationID, props.categoryIDs).data?.categories || [];
  const categorizationName = list[0]?.categorization?.name || 'Categories';
  const text = list.map((c) => c.number || INTRODUCTION).join(', ');
  return props.getDataLine(categorizationName, text);
};

export default FilterSpan;
