import { ChangeEvent, FC, useEffect, useState } from 'react';

import {
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core';

import { addQuantityBreakdownAnalytics } from '../../../../../analytics/analyticsEventProperties';
import { CATEGORIZATIONS } from '../../../../../constants';
import {
  getCombinationCount,
  useCategoryCombinationCountsQuery,
} from '../../../../../hooks/useCategoryCombinationsQueries';
import {
  getCategorizationsForProjectFromQueryData,
  useProjectCategorizationsQuery,
} from '../../../../../hooks/useProjectCategorizationsQuery';
import useSendAnalytics from '../../../../../hooks/useSendAnalytics';
import { withStyles } from '../../../../../theme/komodo-mui-theme';
import { makeOptionsFromTopLevelCategorizations } from '../../../../../utilities/categorization/categorizationUtils';
import { getProjectIdFromUrl } from '../../../../../utilities/url';
import CostReportGroupBySelect from '../../../../CostReport/CostReportGroupBySelect';
import { ScrollUp } from '../../../../Icons/ScrollUp';
import useAddQuantityBreakdown from '../../../hooks/AddQuantityBreakdownHook';
import MilestoneDetailsQuantitiesCategorizationDiagram from '../MilestoneDetailsQuantitiesCategorizationDiagram';
import MilestoneDetailsQuantitiesGrid from '../MilestoneDetailsQuantitiesGrid/MilestoneDetailsQuantitiesGrid';
import {
  AUTOFILL,
  MANUAL,
  MAX_NUM_COMBINATIONS,
  QuantityListItem,
  formatCategorizationInput,
  formatFieldInputs,
  formatQuantityInfo,
  generateAutoFillModeStorageLocation,
} from '../MilestoneDetailsQuantitiesUtils';

import MilestoneDetailsQuantitiesCollapseBodyStyles from './MilestoneDetailsQuantitiesCollapseBodyStyles';

const quickStartCopyYes =
  'Yes, help me auto fill all category combinations that I can customize after.';
const quickStartCopyNo = 'No thanks, I will add category combinations myself.';

type MilestoneDetailsQuantitiesCollapseBodyProps = {
  canEditMetrics: boolean;
  canViewMetrics: boolean;
  classes: Classes<typeof MilestoneDetailsQuantitiesCollapseBodyStyles>;
  groupBys: Categorization[];
  quantityListItem: QuantityListItem;
  setGroupBy: (groupBy: Categorization[]) => void;
};

const MilestoneDetailsQuantitiesCollapseBody: FC<MilestoneDetailsQuantitiesCollapseBodyProps> = ({
  canEditMetrics,
  canViewMetrics,
  classes,
  groupBys,
  quantityListItem,
  setGroupBy,
}) => {
  const sendAnalytics = useSendAnalytics();

  const projectID = getProjectIdFromUrl();

  const {
    abbreviationPlural,
    estimateID,
    hasBreakdown,
    milestoneID,
    quantityID,
    unitID,
    unitName,
  } = quantityListItem;

  const metricBreakdownCopy = `To see more specific costs per ${abbreviationPlural} by category, choose categorizations and add a breakdown.`;

  const { data } = useProjectCategorizationsQuery(projectID);
  const categorizations = getCategorizationsForProjectFromQueryData(data);
  const categorizationSearches = categorizations.map(({ id }) => formatCategorizationInput(id));
  const { data: { categoryCombinationCounts = [] } = {}, loading } =
    useCategoryCombinationCountsQuery(categorizationSearches);

  const onCloseGroupBy = (selected: Categorization[]) => setGroupBy(selected);

  const fieldInputs = formatFieldInputs(groupBys);

  const addBreakdown = useAddQuantityBreakdown();

  const quantityInfo = formatQuantityInfo(quantityListItem);

  const categorizationStrings = groupBys.map((categorization) => categorization.name);

  const onClickBreakdown = () => {
    // add selected categorizations as columns and populate total cell with quantity magnitude
    addBreakdown(projectID, milestoneID, quantityID, unitID, fieldInputs);
    sendAnalytics(
      addQuantityBreakdownAnalytics(quantityID ?? '', categorizationStrings, unitName, milestoneID)
    );
  };
  const showAddBreakdown = canViewMetrics && canEditMetrics && !hasBreakdown;

  const count = getCombinationCount(categoryCombinationCounts, groupBys);
  const disableQuickstart = count >= MAX_NUM_COMBINATIONS;
  const options = makeOptionsFromTopLevelCategorizations(
    categorizations,
    categoryCombinationCounts
  );

  // Auto Fill
  const autoFillStorageLocation = generateAutoFillModeStorageLocation(milestoneID, unitID);
  // TODO: Manage all global state in a common helper for autofill
  const [autoFillSetting, setAutoFillSetting] = useState(
    localStorage.getItem(autoFillStorageLocation) || AUTOFILL
  );
  const handleAutoFillRadio = (_: ChangeEvent<unknown>, newValue: string) => {
    setAutoFillSetting(newValue);
    localStorage.setItem(autoFillStorageLocation, newValue);
  };
  useEffect(() => {
    if (disableQuickstart) setAutoFillSetting(MANUAL);
  }, [disableQuickstart]);

  // Components + Styles
  const formControlLabel = (value: string, label: string) => (
    <FormControlLabel
      classes={{
        root: classes.formLabelRoot,
      }}
      control={<Radio className={classes.smallButton} />}
      data-cy={`radioButton-${value}`}
      label={label}
      value={value}
    />
  );

  const quickStartClass = `${classes.autoFillText} ${disableQuickstart ? classes.disabled : ''}`;
  const disabledContent = (
    <div className={quickStartClass}>
      <Typography>Autofill disabled because there are 1000+ category combinations.</Typography>
      <Typography>
        Change categorizations selection or breakdown category combinations manually.
      </Typography>
    </div>
  );
  const enabledContent = (
    <FormControl>
      <RadioGroup onChange={handleAutoFillRadio} value={autoFillSetting}>
        {formControlLabel(AUTOFILL, quickStartCopyYes)}
        {formControlLabel(MANUAL, quickStartCopyNo)}
      </RadioGroup>
    </FormControl>
  );

  const autofillQuickStart = (
    <div className={classes.autoFillContainer}>
      <div className={classes.autoFillContent}>
        <Typography className={quickStartClass}>QUICK START</Typography>
      </div>

      <div className={classes.autoFillContent}>
        {disableQuickstart ? disabledContent : enabledContent}
      </div>
      <div className={classes.autoFillContent}>
        <MilestoneDetailsQuantitiesCategorizationDiagram
          categorizationCount={groupBys.length}
          categoryCount={count}
          loading={loading}
        />
      </div>
    </div>
  );

  const buttonDisabled = !groupBys.length || loading;

  const addBreakdownComponent = (
    <div className={classes.container}>
      <Typography>{metricBreakdownCopy}</Typography>
      <div className={classes.breakdownContainer}>
        <div className={classes.groupByContainer}>
          <CostReportGroupBySelect
            emptyMessage="Choose categorization..."
            header={CATEGORIZATIONS}
            onClose={onCloseGroupBy}
            options={options}
            selected={groupBys}
          />
        </div>
        <div className={classes.buttonContainer}>
          <Button
            className={`${classes.button} ${
              buttonDisabled ? classes.buttonDisabled : classes.buttonEnabled
            }`}
            data-cy="button-addQuantityBreakdown"
            onClick={onClickBreakdown}
          >
            <span>Add Breakdown</span>
            <span className={classes.iconContainer}>
              <ScrollUp className={classes.arrowIcon} />
            </span>
          </Button>
        </div>
      </div>
      {autofillQuickStart}
    </div>
  );

  const quantityGrid = (
    <MilestoneDetailsQuantitiesGrid
      canEditMetrics={canEditMetrics}
      quantityEstimateID={estimateID}
      quantityInfo={quantityInfo}
    />
  );

  return showAddBreakdown ? addBreakdownComponent : quantityGrid;
};

export default withStyles(MilestoneDetailsQuantitiesCollapseBodyStyles)(
  MilestoneDetailsQuantitiesCollapseBody
);
