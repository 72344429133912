import { ComponentProps, useCallback, useMemo } from 'react';

import { getNewColorForScenario } from '../common/ScenariosUtils';
import useAddScenarioToSandboxMutation from '../hooks/useAddScenarioToSandboxMutation';
import { useCreateScenarioInSandboxMutation } from '../hooks/useCreateScenarioInSandboxMutation';
import useSendScenariosAnalytics, {
  ScenariosAnalyticsEvents,
} from '../hooks/useSendScenariosAnalytics';
import useUpdateSandboxMutation from '../hooks/useUpdateSandboxMutation';
import useAddItemToScenariosMutation from '../Library/hooks/useAddItemToScenariosMutation';

import { moveElementInArray } from './SandboxUtil';
import SandboxView from './SandboxView';

type Props = Pick<
  ComponentProps<typeof SandboxView>,
  | 'activeMilestoneID'
  | 'milestone'
  | 'milestoneName'
  | 'onRemoveScenario'
  | 'sandbox'
  | 'showMilestone'
  | 'sortManager'
>;

export default function Sandbox(props: Props) {
  const { milestoneID } = props.milestone;
  const sendScenariosAnalytics = useSendScenariosAnalytics();

  // Data
  const milestoneItemIDs = useMemo(
    () => props.sandbox.milestone.items.map((i) => i.id),
    [props.sandbox.milestone.items]
  );
  const scenarioIDs = useMemo(
    () => props.sandbox.scenarios.map((s) => s.scenarioID),
    [props.sandbox.scenarios]
  );

  // Mutations
  const [addItemToScenarios] = useAddItemToScenariosMutation();
  const [addScenario] = useAddScenarioToSandboxMutation({ milestoneID });
  const [createScenarioInSandbox] = useCreateScenarioInSandboxMutation(milestoneID);
  const [updateScenarios] = useUpdateSandboxMutation({ milestoneID });

  // Functions
  const onAddToAllScenarios = useCallback(
    (itemID: UUID) => {
      addItemToScenarios({ variables: { itemID, scenarioIDs } });
      sendScenariosAnalytics(ScenariosAnalyticsEvents.ADD_ITEM, {
        scenarioIDs,
        itemID,
      });
    },
    [addItemToScenarios, scenarioIDs, sendScenariosAnalytics]
  );

  const onAddToScenario = useCallback(
    (itemID: UUID, scenarioID: UUID) => {
      addItemToScenarios({ variables: { itemID, scenarioIDs: [scenarioID] } });
      sendScenariosAnalytics(ScenariosAnalyticsEvents.ADD_ITEM, {
        scenarioIDs: [scenarioID],
        itemID,
      });
    },
    [addItemToScenarios, sendScenariosAnalytics]
  );

  const onAddScenario = useCallback(
    (scenarioID: UUID) => {
      addScenario({ scenarioID });
      sendScenariosAnalytics(ScenariosAnalyticsEvents.ADD_ITEM, {
        scenarioID,
        milestoneID,
      });
    },
    [addScenario, milestoneID, sendScenariosAnalytics]
  );

  const onCreateScenario = useCallback(
    ({ cloneMilestoneItems }: { cloneMilestoneItems: boolean }) => {
      const color = getNewColorForScenario(props.sandbox);
      if (cloneMilestoneItems) {
        createScenarioInSandbox(color, milestoneItemIDs);
      } else {
        createScenarioInSandbox(color);
      }
      sendScenariosAnalytics(ScenariosAnalyticsEvents.CREATE_SCENARIO, {
        milestoneID,
        cloneMilestoneItems,
      });
    },
    [createScenarioInSandbox, milestoneID, milestoneItemIDs, props.sandbox, sendScenariosAnalytics]
  );

  const onReorder = useCallback(
    (oldIndex: number) => (newIndex: number) => {
      const currentIDs = props.sandbox.scenarios.map((s) => s.scenarioID);
      const scenarioIDs = moveElementInArray<UUID>(currentIDs, oldIndex, newIndex);
      sendScenariosAnalytics(ScenariosAnalyticsEvents.UPDATE_SCENARIOS, {
        scenarioIDs,
        milestoneID,
      });
      updateScenarios({ scenarioIDs });
    },
    [milestoneID, props.sandbox.scenarios, sendScenariosAnalytics, updateScenarios]
  );

  return (
    <SandboxView
      activeMilestoneID={props.activeMilestoneID}
      milestone={props.milestone}
      milestoneName={props.milestoneName}
      onAddScenario={onAddScenario}
      onAddToAllScenarios={onAddToAllScenarios}
      onAddToScenario={onAddToScenario}
      onCreateScenario={onCreateScenario}
      onRemoveScenario={props.onRemoveScenario}
      onReorder={onReorder}
      sandbox={props.sandbox}
      showMilestone={props.showMilestone}
      sortManager={props.sortManager}
    />
  );
}
