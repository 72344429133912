import { FC } from 'react';

import { withStyles } from '../../../theme/komodo-mui-theme';

import 'react-vis/dist/style.css';
import ChartsHintWrapperStyles from './ChartsHintWrapperStyles';

type ChartsHintWrapperProps = {
  classes: Classes<typeof ChartsHintWrapperStyles>;
  content: JSX.Element;
  width?: number;
};

const ChartsHintWrapper: FC<ChartsHintWrapperProps> = ({ classes, content, width }) => (
  <div className={`${classes.hint} rv-hint__content`} style={{ width }}>
    {content}
  </div>
);

export default withStyles(ChartsHintWrapperStyles)(ChartsHintWrapper);
