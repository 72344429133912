import colors from './colors';

/**
 * Why does this file exist? We're currently (and will be for a while) in a
 * state of development where we're transitioning away from MaterialUI towards
 * Tailwind. During this phase, we're also building out our Design System
 * and rejigging how we name our colors.
 *
 * That means that we want designers to be building /new/ UIs using the
 * Design System color names (available to them via Figma Variables). Since
 * these are different from what we've been using in the past, we want to ensure
 * that those names are valid for styles written in Tailwind and MUI.
 *
 * Once we, god willing, complete our transition from MUI, this file will be deleted.
 */

export default {
  palette: {
    ds: colors.light,
  },
};
