import { FetchResult } from 'apollo-link';

import { useMutation } from '@apollo/client';

import { REFETCH_MILESTONE_ATTACHMENTS } from '../../../../api/refetchSets';
import {
  AttachToMilestoneMutation,
  AttachToMilestoneMutationVariables,
  SourceSystem,
} from '../../../../generated/graphql';
import { attachToMilestoneMutation } from '../queries';

import { formatAttachAssetInputs } from './mutationUtils';

export type MilestoneAttachmentMutationArgs = {
  input: MilestoneAttachmentMutationInput;
  onSuccess?: (result: FetchResult<AttachToMilestoneMutation>) => void;
};

type MilestoneAttachmentMutationInput = {
  assetID: UUID;
  milestoneID: UUID;
  sourceSystem?: SourceSystem;
  sourceID?: UUID;
  sourceProjectID?: string;
  hubID?: string;
};

export const formatMilestoneAttachmentMutationInput = (
  assetID: string,
  currentUpload: AssetActionData
): MilestoneAttachmentMutationInput => ({
  assetID,
  milestoneID: currentUpload.milestoneID || '',
  sourceSystem: currentUpload?.sourceSystem,
  sourceID: currentUpload?.sourceID,
  sourceProjectID: currentUpload?.sourceProjectID,
  hubID: currentUpload?.hubID,
});

export function useAttachToMilestone() {
  const [attachToMilestone] = useMutation<
    AttachToMilestoneMutation,
    AttachToMilestoneMutationVariables
  >(attachToMilestoneMutation);

  return (args: MilestoneAttachmentMutationArgs) => {
    const { assetInput, remoteAssetInput } = formatAttachAssetInputs({
      hubID: args.input.hubID,
      assetID: args.input.assetID,
      sourceSystem: args.input.sourceSystem,
      sourceID: args.input.sourceID,
      sourceProjectID: args.input.sourceProjectID,
    });
    return attachToMilestone({
      variables: {
        milestoneID: args.input.milestoneID,
        assetInput,
        remoteAssetInput,
      },
      refetchQueries: () => REFETCH_MILESTONE_ATTACHMENTS,
    }).then((result) => {
      if (args.onSuccess) args.onSuccess(result);
    });
  };
}
