import { useCallback } from 'react';

import { forecastingAnalyticsEvent } from '../../../analytics/analyticsEventProperties';
import useSendAnalytics from '../../../hooks/useSendAnalytics';

const useSendForecastingAnalytics = () => {
  const sendAnalytics = useSendAnalytics();

  return useCallback(
    (
      type: Parameters<typeof forecastingAnalyticsEvent>[0],
      eventProperties?: Parameters<typeof forecastingAnalyticsEvent>[1]
    ) => {
      sendAnalytics(forecastingAnalyticsEvent(type, eventProperties));
    },
    [sendAnalytics]
  );
};

export default useSendForecastingAnalytics;
