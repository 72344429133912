import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';
import { PIE_CHART_SMALL } from '../../Charts/ChartsPieChart/ChartsPieChartUtils';
import { CHART_PADDING } from '../ExecutiveDashboardUtils';

const ExecutiveDashboardProjectsOverviewStyles = (theme: KomodoTheme) =>
  createStyles({
    captionText: {
      ...theme.typography.h3Label,
    },
    chartContainer: {
      height: PIE_CHART_SMALL,
    },
    container: {
      padding: CHART_PADDING,
    },
    flexContainer: {
      display: 'flex',
      '&> div': {
        flexBasis: '100%',
      },
    },
    icon: {
      height: 18,
      padding: '0 0 0 3px',
      color: theme.palette.disabledGrey,
    },
    iconAlign: {
      display: 'flex',
      alignItems: 'center',
    },
    legend: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    toggleContainer: {
      marginBottom: CHART_PADDING,
    },
  });

export default ExecutiveDashboardProjectsOverviewStyles;
