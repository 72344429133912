import { FC } from 'react';

import { Typography } from '@material-ui/core';

import { withStyles } from '../../../theme/komodo-mui-theme';
import { UploadFilesIcon } from '../../Icons/UploadFilesIcon';

import styles from './AddUploadDragHoverStyles';

type AddUploadDragHoverProps = {
  classes: Classes<typeof styles>;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  dragging: boolean;
};

const AddUploadDragHover: FC<AddUploadDragHoverProps> = ({ classes, dragging }) => {
  if (!dragging) return <></>;

  return (
    <div className={classes.container}>
      <div className={classes.iconContainer}>
        <UploadFilesIcon />
      </div>
      <div className={classes.textContainer}>
        <Typography className={classes.text}>Drop files to upload them</Typography>
      </div>
    </div>
  );
};

export default withStyles(styles)(AddUploadDragHover);
