import { useDraftCategorizationsQuery } from '../../hooks/useDraftCategorizationsQuery';
import {
  getCategorizationsForProjectFromQueryData,
  useProjectCategorizationsQuery,
} from '../../hooks/useProjectCategorizationsQuery';
import { getImportEstimateIdFromUrl, getProjectIdFromUrl } from '../../utilities/url';

import { FilterPanel } from './FilterPanel';
import { AdditionalFilterManager, FilterManager } from './filterUtils';

type Props = {
  additionalFilters?: AdditionalFilterManager;
  filterManager: FilterManager;
  page: string;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  showFilterSummary?: boolean;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  show: boolean;
  setShow: (show: boolean) => void;
};

const FilterPanelWrapper = (props: Props) => {
  const projectID = getProjectIdFromUrl();

  const { data } = useProjectCategorizationsQuery(projectID);
  const projectCategorizations = getCategorizationsForProjectFromQueryData(data);

  const estimateID = getImportEstimateIdFromUrl();
  const { data: { draftCategorizations = [] } = {} } = useDraftCategorizationsQuery(estimateID);

  const draftCategorizationsNotInProjectCategorizations = draftCategorizations.filter((draft) =>
    projectCategorizations.findIndex((t) => t.id !== draft.id)
  );

  const categorizations = [
    ...projectCategorizations,
    ...draftCategorizationsNotInProjectCategorizations,
  ];

  return (
    <FilterPanel
      additionalFilters={props.additionalFilters}
      categorizations={categorizations}
      filterManager={props.filterManager}
      page={props.page}
      projectID={projectID}
      setShow={props.setShow}
      show={props.show}
      showFilterSummary={props.showFilterSummary}
    />
  );
};

export default FilterPanelWrapper;
