import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';

export default (theme: KomodoTheme) =>
  createStyles({
    button: {
      '&:focus': {
        border: theme.border.focus,
        outline: theme.border.focus,
      },
      '&:hover': {
        backgroundColor: theme.palette.backgroundWhite,
      },
      background: theme.palette.backgroundWhite,
      border: theme.border.line,
      borderRadius: theme.border.radius,
      color: theme.palette.joinPrimary,
      height: 40,
      padding: `0px 0px 0px ${theme.spacing.selectPaddingLeft}px`,
      whiteSpace: 'nowrap',
      width: '100%',
    },
    dropDown: {
      display: 'flex',
      alignItems: 'center',
      '&:hover': {
        backgroundColor: theme.palette.hoverBackground,
        borderRadius: 100,
      },
    },
    text: {
      height: 18,
      flexGrow: 1,
    },
  });
