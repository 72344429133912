import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../theme/komodo-mui-theme';

const styles = (theme: KomodoTheme) =>
  createStyles({
    description: {
      ...theme.typography.webLabel,
      ...theme.typography.number,
    },
    descriptionContainer: {
      textAlign: 'right',
    },
    descriptionHeader: {
      ...theme.typography.number,
      fontSize: '0.9rem',
      fontWeight: 500,
    },
    fullWidth: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      width: '100%',
    },
    number: {
      ...theme.typography.number,
      color: 'inherit',
      fontSize: '0.9rem',
      textAlign: 'right',
    },
    root: {
      display: 'flex',
      flexDirection: 'column',
      minWidth: 'min-content',
    },
    spacer: {
      flexGrow: 1,
    },
    title: {
      ...theme.typography.number,
      fontSize: '0.9rem',
      paddingTop: 2,
    },
    tooltip: {
      ...theme.typography.h4,
      ...theme.typography.number,
      display: 'flex',
      justifyContent: 'flex-start',
    },
    totalNumber: {
      ...theme.typography.h4,
      ...theme.typography.number,
      color: 'inherit',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      textAlign: 'right',
    },
    link: {
      ...theme.typography.link,
      color: theme.palette.brand.black,
      display: 'flex',
      flexDirection: 'row',
      flexGrow: 1,
      alignContent: 'right',
    },
  });

export default styles;
