import { useCompanyRoutesData } from '../CompanyTabUtils';

import CompanyTabOrganizations from './CompanyTabOrganizations';
import useCreateOrgMutation from './hooks/useCreateOrgMutation';
import useOrganizationsQuery from './hooks/useOrganizationsQuery';

export default function CompanyTabOrganizationsData() {
  const { companyID } = useCompanyRoutesData();
  const organizationsQueryResult = useOrganizationsQuery(companyID);
  const createOrg = useCreateOrgMutation();

  const onAddNewOrganization = () => {
    if (companyID) createOrg(companyID);
  };

  const loading = organizationsQueryResult.loading;
  if (!companyID) return null;
  if (loading) return null;

  return (
    <CompanyTabOrganizations
      companyID={companyID}
      onAddNewOrganization={onAddNewOrganization}
      organizations={organizationsQueryResult.data?.organizations ?? []}
    />
  );
}
