import { DatePicker, MuiPickersUtilsProvider } from 'material-ui-pickers';

import DateFnsUtils from '@date-io/date-fns';

import { localeDateFormat, maskFn, placeholderDefault } from '../../utilities/dates';

type Props = {
  label: string;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  disabled?: boolean;
  maxDate?: Date;
  minDate?: Date;
  onChange: (date: string | null) => void;
  onOpen?: () => void;
  onClose?: () => void;
  onFocus?: () => void;
  date?: Date | string | null;
};

const DateInput = ({
  date = null,
  label,
  onChange,
  disabled,
  minDate,
  maxDate,
  onOpen = () => {},
  onClose = () => {},
  onFocus,
}: Props) => {
  return (
    <div className="flex flex-col gap-0.5">
      {label && <div className="type-label">{label}</div>}
      <div className="flex-auto">
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DatePicker
            animateYearScrolling={false}
            clearable
            disabled={disabled}
            format={localeDateFormat}
            fullWidth
            InputProps={{
              disableUnderline: true,
              classes: {
                root: '!rounded-md type-number !border-border-default !h-10 items-center',
                focused: '!outline',
              },
            }}
            invalidDateMessage=""
            keepCharPositions
            keyboard
            mask={maskFn}
            maxDate={maxDate}
            minDate={minDate}
            minDateMessage=""
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onChange={(value: any) => {
              // you may be wondering what the 'Invalid Date' part is about...
              // DatePicker weirdly returns an Invalid Date object when you type an incomplete or incorrect date
              // this intervenes to minimize bugs upstream where it expects string | null
              // note that I say minimize - as this is still sending a value with uncertain typing
              // TODO: Design System DateInput
              if (String(value) === 'Invalid Date') onChange(null);
              else onChange(value);
            }}
            onClose={onClose}
            onFocus={onFocus}
            onKeyDown={(evt: KeyboardEvent) => {
              if (evt.key === 'Enter' && evt.target instanceof HTMLElement) {
                evt.target.blur();
              }
            }}
            onOpen={onOpen}
            placeholder={placeholderDefault}
            value={date}
          />
        </MuiPickersUtilsProvider>
      </div>
    </div>
  );
};

export default DateInput;
