import { JoinCompanyOrganizationsRoutes } from '../../../api/gqlEnums';

import CompanyOrganizationData from './CompanyOrganizationData';

export type RouteToOrganizationsComponent = {
  [key in JoinCompanyOrganizationsRoutes]: { element: JSX.Element };
};
export const routeToOrganizationsComponent: RouteToOrganizationsComponent = {
  [JoinCompanyOrganizationsRoutes.COMPANY_ORGANIZATION]: { element: <CompanyOrganizationData /> },
};
