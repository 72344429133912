import { useMutation } from '@apollo/client';

import { REFETCH_DISABLE_CATEGORIZATION } from '../../../../api/refetchSets';
import { MutationSetDisabledProjectCategorizationsArgs } from '../../../../generated/graphql';

import { setDisabledProjectCategorizationsMutation } from './queries';

export const useSetDisabledProjectCategorizations = () => {
  const [setDisabledProjectCategorizationsFunc] =
    useMutation<MutationSetDisabledProjectCategorizationsArgs>(
      setDisabledProjectCategorizationsMutation
    );

  return (projectID: UUID, categorizationIDs: UUID[], disable: boolean) => {
    setDisabledProjectCategorizationsFunc({
      variables: {
        projectID,
        categorizationIDs,
        disable,
      },
      refetchQueries: REFETCH_DISABLE_CATEGORIZATION,
    });
  };
};
