import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';

const barText = (theme: KomodoTheme) => ({
  ...theme.typography.number,
  paddingLeft: 4,
  fontSize: 12,
});

const ChartsSingleHorizontalBarGraphStyles = (theme: KomodoTheme) =>
  createStyles({
    barLabel: {
      ...barText(theme),
      position: 'relative',
      top: 3,
    },
    placeholderText: {
      color: theme.palette.chartGrey,
      textAlign: 'center',
      fontWeight: 700,
    },
    tooltip: {
      padding: 0,
      textAlign: 'center',
      position: 'relative',
      top: 2,
    },
    whiteText: {
      color: theme.palette.backgroundWhite,
    },
  });

export default ChartsSingleHorizontalBarGraphStyles;
