import { ChangeEvent, useEffect, useRef, useState } from 'react';
import * as React from 'react';

import { PROJECT_COMP_ESCALATION } from '../../../tagConstants';
import { withStyles } from '../../../theme/komodo-mui-theme';
import { formatCommas } from '../../../utilities/currency';
import { getNumberString } from '../ProjectCompsSetUtils';

import EscalationInputDropdown from './EscalationInputDropdown';
import styles from './EscalationInputStyles';

export const PART_DIGIT_LIMIT = 9;
const DIGIT_LIMIT = 1 + PART_DIGIT_LIMIT * 2;

interface EscalationInputPropsCommon {
  classes: Classes<typeof styles>;
  inputRef?: React.RefObject<HTMLInputElement>;
  startAdornment?: JSX.Element;
  onMouseOver?: (isOver: boolean) => void;
  isHighlighted?: boolean;
  onChange: (value: string) => void;
  value: string;
}

interface EscalationInputPropsAutoEscalatable extends EscalationInputPropsCommon {
  isAutoEscalated: boolean;
  onAutoEscalate: () => void;
  onAutoEscalateAll: () => void;
  onManualEscalate: () => void;
  onRemoveEscalation: () => void;
  type: 'date' | 'location';
}

interface EscalationInputPropsBasic extends EscalationInputPropsCommon {
  isAutoEscalated?: never;
  onAutoEscalate?: never;
  onAutoEscalateAll?: never;
  onManualEscalate?: never;
  onRemoveEscalation?: never;
  type: 'future';
}

type EscalationInputProps = EscalationInputPropsAutoEscalatable | EscalationInputPropsBasic;

const EscalationInput = (props: EscalationInputProps) => {
  const { classes } = props;

  const containerRef = useRef<HTMLDivElement>(null);

  const isAutoEscalated = 'isAutoEscalated' in props ? Boolean(props.isAutoEscalated) : false;

  const [isEditing, setIsEditing] = useState(false);
  const [localValue, setLocalValue] = useState(props.value);

  // update the local value if the one passed in changes
  useEffect(() => {
    setLocalValue(props.value);
  }, [props.value]);

  // format the local value according to what input mode we're in
  let displayValue = localValue;
  if (!isEditing) {
    const hasValue = localValue && getNumberString(localValue);
    if (hasValue) {
      displayValue = `${formatCommas(localValue)}%`;
    } else if (isAutoEscalated) {
      displayValue = '0%';
    } else {
      displayValue = '';
    }
  }

  // <input> handlers
  // Not currently concerned about onCallback'ing these since we're applying them
  // directly to a DOM element and not a deep component chain.
  const handleBlur = () => {
    setIsEditing(false);
    props.onChange(localValue);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    if (value !== '-' && Number.isNaN(Number(value))) return;

    const [integerPart = '', fractionalPart = ''] = value.split('.');
    if (integerPart.length > PART_DIGIT_LIMIT || fractionalPart.length > PART_DIGIT_LIMIT) return;

    setLocalValue(value);
  };

  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    props.onMouseOver?.(false);
    setIsEditing(true);

    // For a better UX, we move the cursor to the end of the input.
    // We need to do a setTimeout due to browser quirks (Chrome),
    // and because we need to setTimeout, we need to persist
    // the event variable so that React doesn't garbage collect it.
    e.persist();
    setTimeout(() => {
      e.target.selectionStart = DIGIT_LIMIT * 2;
      e.target.selectionEnd = DIGIT_LIMIT * 2;
    }, 0);
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && e.target instanceof HTMLInputElement) {
      e.target.blur();
    }
  };

  // Show a tooltip if necessary.
  let tooltip: string | undefined;
  const [integerPart = '', fractionalPart = ''] = localValue.split('.');
  if (fractionalPart.length > 3) {
    // `applyEscalationToTotalValues` takes escalation to 9 decimal places
    tooltip = `${integerPart}.${fractionalPart.substring(0, PART_DIGIT_LIMIT)}%`;
  }

  return (
    <div
      ref={containerRef}
      className={`${classes.container} ${props.isHighlighted ? classes.highlighted : ''}`}
      data-cy={`${PROJECT_COMP_ESCALATION}-${props.type}`}
    >
      <div className={classes.startAdornment}>{props.startAdornment}</div>
      <input
        ref={props.inputRef}
        className={classes.input}
        data-cy={PROJECT_COMP_ESCALATION}
        disabled={isAutoEscalated}
        onBlur={handleBlur}
        onChange={handleChange}
        onFocus={handleFocus}
        onKeyPress={handleKeyPress}
        placeholder="Enter a percentage"
        title={tooltip}
        value={displayValue}
      />
      {props.type === 'date' || props.type === 'location' ? (
        <EscalationInputDropdown
          buttonClassName={classes.dropdownButton}
          containerRef={containerRef}
          isAutoEscalated={isAutoEscalated}
          onAutoEscalate={props.onAutoEscalate}
          onAutoEscalateAll={props.onAutoEscalateAll}
          onManualEscalate={props.onManualEscalate}
          onRemoveEscalation={props.onRemoveEscalation}
          type={props.type}
        />
      ) : null}
    </div>
  );
};

export default withStyles(styles)(EscalationInput);
