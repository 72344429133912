import { useFeatureFlags } from '../components/contexts/feature-flags';

export function hasFeatureOption(loadFeatureFlags: string[] | undefined, option: string) {
  return (loadFeatureFlags || []).some((value) => value === option);
}

export function useHasFeature(key: string) {
  const flags = useFeatureFlags();
  return hasFeatureOption(flags, key);
}
