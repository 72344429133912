import { createRef, useLayoutEffect, useState } from 'react';

import { useReactiveVar } from '@apollo/client';

import { insightsTimeDomain } from '../../../api/apollo/reactiveVars';
import { getTodayUtcNoon } from '../../../utilities/dates';
import { IS_ZOOM } from '../ProjectsTables/utils';

import ProjectsSummary from './ProjectsSummary';
import TimelineZoomChart from './TimelineZoomChart';

export default function ZoomTimeline(props: {
  // Current zoom range
  onZoom: (domain: [string, string]) => void;
  projects: { id: UUID }[];
  zoom: [string, string];
  today?: Date;
}) {
  const totalDomain = useReactiveVar(insightsTimeDomain);
  const totalStart = totalDomain[0];
  const totalEnd = totalDomain[1];

  const today = props.today ?? getTodayUtcNoon();

  const containerRef = createRef<HTMLDivElement>();
  const [width, setWidth] = useState<number>(0);

  useLayoutEffect(() => {
    const handleResize = () => {
      const newWidth = containerRef.current?.offsetWidth;
      if (!newWidth) return;
      if (width === newWidth) return;
      setWidth(newWidth);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [containerRef, width]);

  if (!IS_ZOOM) return null;

  const summary = (
    <div className="ml-4">
      <ProjectsSummary
        height={50}
        projects={props.projects}
        totalRange={[totalStart, totalEnd]}
        width={width - 32}
      />
    </div>
  );

  const chart = (
    // Position on top of the summary chart, position: absolute would break sticky footer behavior
    <div className="mt-[-58px]">
      <TimelineZoomChart
        onAnalytics={() => {}}
        onZoom={props.onZoom}
        today={today}
        totalRange={[totalStart, totalEnd]}
        width={width}
        zoom={props.zoom}
      />
    </div>
  );

  return (
    <div ref={containerRef} className="relative block w-full bg-background-primary pt-3">
      {summary}
      {chart}
    </div>
  );
}
