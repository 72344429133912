import { FC, Fragment } from 'react';

import { Typography } from '@material-ui/core';

import { withStyles } from '../../../theme/komodo-mui-theme';
import NormalTooltip from '../../NormalTooltip/NormalTooltip';

import DataTableStyles from './DataTableStyles';

type DataHeader = {
  label: string;
  alignLeft?: boolean;
};

export type TableHeader = {
  dataHeaders: DataHeader[];
};

export type TableRow = {
  data: string[];
  disabled?: boolean;
  icon?: JSX.Element;
  name: string;
  tooltip?: string;
  bold?: boolean;
};

type DataTableProps = {
  classes: Classes<typeof DataTableStyles>;
  isHalfWidth?: boolean;
  isHint?: boolean;
  tableFooterData?: string[];
  tableHeaderData: TableHeader;
  tableRowData: TableRow[];
};

const DataTable: FC<DataTableProps> = ({
  classes,
  isHalfWidth,
  isHint,
  tableFooterData,
  tableHeaderData,
  tableRowData,
}) => {
  const hasIcon = tableRowData.find((row) => !!row.icon);

  const getFontClass = (bold = true) => {
    if (isHint) {
      return bold ? classes.hintTextBold : classes.hintText;
    }
    return bold ? classes.chartTextBold : classes.chartText;
  };

  const getTextAlignClass = (alignLeft = false) =>
    alignLeft ? classes.leftAlign : classes.rightAlign;

  const tableRow = (row: TableRow, index: number) => {
    const { data, disabled, icon, bold, name, tooltip } = row;
    const rowName = (
      <td className={`${classes.nameCell} ${getFontClass(disabled ? false : bold)}`}>
        <Typography className={classes.rowName}>{name}</Typography>
      </td>
    );
    return (
      <tr
        key={row.name === '-' ? index : row.name}
        className={`${classes.row} ${disabled && classes.disabled}`}
      >
        <>{icon && <td className={classes.iconCell}>{icon}</td>}</>
        {tooltip ? <NormalTooltip title={tooltip}>{rowName}</NormalTooltip> : <>{rowName}</>}
        {data.map((dataElem, dataIndex) => (
          <td
            key={dataElem === '-' ? dataIndex : dataElem}
            className={`${classes.dataCell} ${getFontClass(false)}`}
          >
            <Typography>{dataElem}</Typography>
          </td>
        ))}
      </tr>
    );
  };

  const { dataHeaders } = tableHeaderData;
  const hasTableTitle = dataHeaders.find((header) => !!header.alignLeft);

  return (
    <table className={`${classes.table} ${isHalfWidth ? classes.halfWidth : classes.fullWidth}`}>
      <thead>
        <tr className={classes.headerRow}>
          {(hasIcon || !hasTableTitle) && <th aria-label="icon-header" />}
          {dataHeaders.map((dataHeader) => (
            <th
              key={dataHeader.label}
              className={`${getTextAlignClass(dataHeader.alignLeft)} ${getFontClass()}`}
            >
              <Typography>{dataHeader.label}</Typography>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {tableRowData.map((row, index) => (
          <Fragment key={row.name === '-' ? index : row.name}>{tableRow(row, index)}</Fragment>
        ))}
      </tbody>
      {tableFooterData && (
        <tfoot>
          <tr className={classes.footerRow}>
            <th aria-label="first-footerRow-header" />
            <th aria-label="second-footerRow-header" />
            {tableFooterData.map((dataFooter) => (
              <th key={dataFooter} className={`${classes.rightAlign} ${getFontClass()}`}>
                <Typography>{dataFooter}</Typography>
              </th>
            ))}
          </tr>
        </tfoot>
      )}
    </table>
  );
};

export default withStyles(DataTableStyles)(DataTable);
