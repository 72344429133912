import { MutationHookOptions, useMutation } from '@apollo/client';

import {
  SetAllInAppNotificationsReadMutation,
  SetAllInAppNotificationsReadMutationVariables,
} from '../../../generated/graphql';

import { setAllInAppNotificationsReadMutation } from './queries';

const useSetAllInAppNotificationsReadMutation = (
  options: MutationHookOptions<
    SetAllInAppNotificationsReadMutation,
    SetAllInAppNotificationsReadMutationVariables
  >
) => {
  return useMutation<
    SetAllInAppNotificationsReadMutation,
    SetAllInAppNotificationsReadMutationVariables
  >(setAllInAppNotificationsReadMutation, options);
};

export default useSetAllInAppNotificationsReadMutation;
