import { useSearchParams } from 'react-router-dom';

import { Search } from '@material-ui/icons';

import useCollaboratorsQuery from '../../../hooks/useCollaboratorsQuery';
import useDebouncedState from '../../../hooks/useDebouncedState';
import { useFilterCollaborators } from '../../../hooks/useFilterCollaborators';
import { useSearchCollaborators } from '../../../hooks/useSearchCollaborators';
import useSortCollaborators from '../../../hooks/useSortCollaborators';
import { useProjectID } from '../../../utilities/routes/params';
import { Select, TextInput } from '../../scales';

import TeammatesTable from './TeammatesTable';

export default function TeammatesRoute() {
  const projectID = useProjectID();
  if (!projectID) throw Error('No projectID found');

  const queryResult = useCollaboratorsQuery(projectID);
  const collaborators =
    queryResult.data?.collaborators ?? queryResult.previousData?.collaborators ?? [];

  const { entries, filterKey, filteredCollaborators, setFilterKey } =
    useFilterCollaborators(collaborators);

  const [searchParams, setSearchParams] = useSearchParams();
  const [searchTerm, debouncedSearchTerm, setSearchTerm] = useDebouncedState(
    searchParams.get('search') ?? ''
  );
  const searchedCollaborators = useSearchCollaborators(filteredCollaborators, debouncedSearchTerm);
  const onChangeSearchTerm = (value: string) => {
    setSearchTerm(value);
    setSearchParams(value ? { search: value } : {});
  };

  const { sortManager, sortedCollaborators } = useSortCollaborators(searchedCollaborators);

  return (
    <div className="flex flex-col gap-2 overflow-auto">
      <div className="flex shrink-0 flex-row gap-2 px-6 pt-4">
        <div className="w-64">
          <TextInput
            // TODO CT-1036: make/use a SearchInput scales component
            data-cy="teammates-list-search-input"
            label="Search"
            onChange={onChangeSearchTerm}
            onClear={() => onChangeSearchTerm('')}
            placeholder="Search text fields"
            startAdornment={<Search />}
            value={searchTerm}
          />
        </div>
        <div className="w-64">
          <Select
            data-cy="role-filter-select"
            entries={entries}
            label="Filter"
            onChange={setFilterKey}
            value={filterKey}
          />
        </div>
      </div>
      <div className="w-full overflow-auto">
        <TeammatesTable
          collaborators={sortedCollaborators}
          projectID={projectID}
          searchTerm={searchTerm}
          sortManager={sortManager}
        />
      </div>
    </div>
  );
}
