// FETCH POLICY
import { FetchPolicy, WatchQueryFetchPolicy } from '@apollo/client';

import { CostReportColumnType } from '../generated/graphql';

export const CACHE_FIRST: FetchPolicy = 'cache-first';
export const CACHE_AND_NETWORK: WatchQueryFetchPolicy = 'cache-and-network';

export const columns: CostReportColumnInput[] = [
  CostReportColumnType.ACCEPTED_REPORT,
  CostReportColumnType.ESTIMATE_REPORT,
  CostReportColumnType.INCORPORATED_REPORT,
  CostReportColumnType.NOTAPPLICABLE_REPORT,
  CostReportColumnType.OPTIONS_REPORT,
  CostReportColumnType.PENDING_REPORT,
  CostReportColumnType.REJECTED_REPORT,
  CostReportColumnType.REMAINING_REPORT,
  CostReportColumnType.RUNNINGTOTAL_REPORT,
  CostReportColumnType.TARGET_REPORT,
].map((type) => ({ type }));

export const costTrendColumns: CostReportColumnInput[] = [
  CostReportColumnType.ESTIMATE_REPORT,
  CostReportColumnType.TARGET_REPORT,
  CostReportColumnType.RUNNINGTOTAL_REPORT,
].map((type) => ({ type }));

export const milestoneColumns: CostReportColumnInput[] = [
  CostReportColumnType.ESTIMATE_REPORT,
  CostReportColumnType.TARGET_REPORT,
].map((type) => ({ type }));
