import { ReactNode, useMemo, useState } from 'react';

import { LeadEvent, LeadKey, leadEvent } from '../../../analytics/analyticsEventProperties';
import { ToastType } from '../../../api/gqlEnums';
import { UserStatus } from '../../../api/gqlEnumsBe';
import {
  TABLE_HEADER_EMAIL,
  TABLE_HEADER_NAME,
  TABLE_HEADER_RESPONSIBILITIES,
  TABLE_HEADER_ROLE,
} from '../../../constants';
import { CompanyProjectRole, PermissionResource } from '../../../generated/graphql';
import { useCompanyUserQuery } from '../../../hooks/useCompanyUserQuery';
import useRolesQuery from '../../../hooks/useRolesQuery';
import useSendAnalytics from '../../../hooks/useSendAnalytics';
import { setToast } from '../../../hooks/useToastParametersLocalQuery';
import usePermissions from '../../../utilities/permissions/usePermissions';
import { categoryLabel, pluralizeCountString } from '../../../utilities/string';
import { useRemoveProjectLead, useSetProjectLead } from '../../Collaborators/hooks/projectLead';
import UserAvatar from '../../Collaborators/UserAvatar';
import { useCurrentCompanyProjectRoleQuery } from '../../CompanyTab/CompanyHooks';
import { useCompanyUsersQuery } from '../../CompanyTab/useCompanyUsersQuery';
import DialogsConfirm from '../../Dialogs/DialogsConfirm/DialogsConfirm';
import CategoriesMultiSelectPopover from '../../dragon-scales/CategoriesMultiSelectPopover/CategoriesMultiSelectPopover';
import {
  Button,
  Chip,
  IconMenuButton,
  Select,
  SortManager,
  Table,
  TableComponents,
  TextInput,
  Tooltip,
} from '../../scales';
import SearchText from '../../Search/SearchText/SearchText';

import useTeammateMutations from './useTeammateMutations';

type Props = {
  collaborators: Collaborator[];
  projectID: UUID;
  searchTerm: string;
  sortManager: SortManager;
};

export default function TeammatesTable(props: Props) {
  const sendAnalytics = useSendAnalytics();
  const companyID = useCompanyUserQuery().data?.companyUser?.company?.id;
  const isAdministratingCompany =
    useCurrentCompanyProjectRoleQuery({
      variables: { projectID: props.projectID },
    }).data?.currentCompanyProjectRole === CompanyProjectRole.ADMINISTRATOR;
  const { canEdit } = usePermissions();
  const [setProjectLead] = useSetProjectLead();
  const [removeProjectLead] = useRemoveProjectLead();
  const canEditCollaborators = canEdit(PermissionResource.COLLABORATORS);
  const canEditPermissions = canEdit(PermissionResource.PERMISSION_TEMPLATES);

  const companyUsers =
    useCompanyUsersQuery({
      variables: { companyID },
      skip: !companyID || !canEditCollaborators || !canEditPermissions,
    }).data?.companyUsers ?? [];

  const roles = useRolesQuery(props.projectID).data?.projectRoles;
  const roleEntries = useMemo(
    () => roles?.map((c) => ({ id: c.id, label: c.name })) ?? [],
    [roles]
  );

  const {
    onPreviewUser,
    onRemoveUser,
    onResendInvite,
    onUpdateCollaboratorResponsibility,
    onUpdateCollaboratorRole,
    onUpdateCollaboratorTrades,
  } = useTeammateMutations(props.projectID);

  return (
    <Table
      columnWidths={['50px', '1fr', '1fr', '1fr', '1fr', '1fr', 'max-content']}
      entries={props.collaborators.map((c) => {
        const isDeactivated = c.user.status === UserStatus.DEACTIVATED;
        const isPending = c.user.status === UserStatus.PENDING;
        const isValidProjectLead =
          isAdministratingCompany && companyUsers.some((cu) => cu.user?.id === c.user.id);

        return [
          {
            key: `${c.id}-avatar`,
            component: (
              <TableComponents.Cell className="py-3">
                <UserAvatar assignee={c.user} deactivated={isDeactivated} />
              </TableComponents.Cell>
            ),
          },
          {
            key: `${c.id}-name`,
            component: (
              <TableComponents.Cell
                className={[
                  'flex',
                  'gap-2',
                  'type-body1',
                  isPending || isDeactivated ? 'text-type-muted' : 'text-type-primary',
                ].join(' ')}
                data-cy="teammate-name-cell"
              >
                <SearchText
                  searchTerm={props.searchTerm}
                  text={[
                    c.user.name,
                    isPending ? ' (Invited)' : '',
                    isDeactivated ? ' (Deactivated)' : '',
                  ].join('')}
                />
                {isAdministratingCompany && c.isProjectLead && (
                  <Chip
                    icon={<img alt="organization icon" src="/img/GroupsOutlined.svg" width={16} />}
                    text="Project Lead"
                  />
                )}
              </TableComponents.Cell>
            ),
          },
          {
            key: `${c.id}-email`,
            component: (
              <TableComponents.Cell
                className={[
                  'type-body1 [word-break:break-word]',
                  isPending || isDeactivated ? 'text-type-muted' : 'text-type-primary',
                ].join(' ')}
                data-cy="teammate-email-cell"
              >
                <SearchText searchTerm={props.searchTerm} text={c.user.email} />
              </TableComponents.Cell>
            ),
          },
          {
            key: `${c.id}-responsibilities`,
            component: (
              <TableComponents.Cell
                className={[
                  'w-full py-3 type-body1',
                  isPending || isDeactivated ? 'text-type-muted' : 'text-type-primary',
                ].join(' ')}
                data-cy="teammate-responsibilities-cell"
              >
                {canEditCollaborators ? (
                  <TextInput
                    aria-label="responsibility"
                    defaultValue={c.responsibility ?? ''}
                    onBlur={(e) => onUpdateCollaboratorResponsibility(c.id, e.currentTarget.value)}
                  />
                ) : (
                  <SearchText searchTerm={props.searchTerm} text={c.responsibility ?? ''} />
                )}
              </TableComponents.Cell>
            ),
          },
          {
            key: `${c.id}-role`,
            component: (
              <TableComponents.Cell
                className={[
                  'w-full py-3 type-body1',
                  isPending || isDeactivated ? 'text-type-muted' : 'text-type-primary',
                ].join(' ')}
                data-cy="teammate-role-cell"
              >
                {canEditCollaborators ? (
                  <Select
                    aria-label="role"
                    data-cy="project-role-select"
                    entries={roleEntries}
                    onChange={(value) => onUpdateCollaboratorRole(c.id, value)}
                    value={c.role.id}
                  />
                ) : (
                  <SearchText searchTerm={props.searchTerm} text={c.role.name} />
                )}
              </TableComponents.Cell>
            ),
          },
          {
            key: `${c.id}-permissions`,
            component: (
              <PermissionsCell
                canEditPermissions={canEditPermissions}
                collaborator={c}
                isDeactivated={isDeactivated}
                isPending={isPending}
                onUpdateCollaboratorTrades={onUpdateCollaboratorTrades}
                projectID={props.projectID}
              />
            ),
          },
          {
            key: `${c.id}-actions`,
            component:
              canEditCollaborators && canEditPermissions ? (
                <ActionsCell
                  collaborator={c}
                  isValidProjectLead={isValidProjectLead}
                  onPreviewUser={onPreviewUser}
                  onRemoveProjectLead={() =>
                    removeProjectLead({
                      variables: { projectID: props.projectID, userID: c.user.id },
                    })
                  }
                  onRemoveUser={onRemoveUser}
                  onResendInvite={onResendInvite}
                  onSetProjectLead={() => {
                    setProjectLead({
                      variables: { projectID: props.projectID, userID: c.user.id },
                    });
                    sendAnalytics(
                      leadEvent(LeadKey.TEAM_VIEW, LeadEvent.SET, { userID: c.user.id })
                    );
                  }}
                />
              ) : null,
          },
        ];
      })}
      headerContent={[
        { key: 'avatar' },
        { key: 'name', copy: 'Name', headerSortKey: TABLE_HEADER_NAME },
        { key: 'email', copy: 'Email', headerSortKey: TABLE_HEADER_EMAIL },
        {
          key: 'responsibilities',
          copy: 'Responsibilities',
          headerSortKey: TABLE_HEADER_RESPONSIBILITIES,
        },
        {
          key: 'role',
          copy: 'Role',
          headerSortKey: TABLE_HEADER_ROLE,
          tooltip:
            "Setting a teammate's role determines what that user can see and do within this project. View the Roles tab to see more detail around each role's permissions.",
        },
        {
          key: 'permissions',
          copy: 'Category Permissions',
          tooltip:
            'If you want to limit access for a teammate to a specific trade(s), you can set that here. View the Roles tab to see more detail or to enable/disable trade permissions.',
        },
        { key: 'actions' },
      ]}
      sortManager={props.sortManager}
    />
  );
}

function PermissionsCell(props: {
  canEditPermissions: boolean;
  collaborator: Collaborator;
  isDeactivated: boolean;
  isPending: boolean;
  onUpdateCollaboratorTrades: (value: {
    allTrades: boolean;
    collaboratorID: UUID;
    categoryIDs: UUID[];
  }) => void;
  projectID: UUID;
}) {
  const c = props.collaborator;

  let content: ReactNode = null;
  if (props.canEditPermissions) {
    if (c.role.hasTrade) {
      content = (
        <CategoriesMultiSelectPopover
          defaultValue={{
            categories:
              c.trades.map((t) => ({ id: t.id, categorizationID: t.categorization?.id ?? '' })) ??
              [],
            isAllSelected: c.allTrades,
          }}
          onChange={(v) =>
            props.onUpdateCollaboratorTrades({
              allTrades: v.isAllSelected,
              collaboratorID: c.id,
              categoryIDs: v.categories.map((cRef) => cRef.id),
            })
          }
          projectID={props.projectID}
        />
      );
    } else {
      content = 'All Categories';
    }
  } else if (c.role.hasTrade && !c.allTrades) {
    const trades = c.trades
      .map((t) => categoryLabel(t.name, t.number))
      .sort((a, b) => a.toLocaleLowerCase().localeCompare(b.toLocaleLowerCase()));

    content = (
      <Tooltip
        content={
          <ul className="list-disc">
            {trades.map((t) => (
              <li key={t} className="ml-4">
                {t}
              </li>
            ))}
          </ul>
        }
      >
        <div>{pluralizeCountString('Category', c.trades.length)}</div>
      </Tooltip>
    );
  } else {
    content = 'All Categories';
  }

  return (
    <TableComponents.Cell
      className={[
        'w-40 p-3 type-body1',
        props.isPending || props.isDeactivated ? 'text-type-muted' : 'text-type-primary',
      ].join(' ')}
      data-cy="teammate-permissions-cell"
    >
      {content}
    </TableComponents.Cell>
  );
}

function ActionsCell(props: {
  collaborator: Collaborator;
  isValidProjectLead: boolean;
  onPreviewUser: (userID: UUID) => void;
  onRemoveProjectLead: () => void;
  onRemoveUser: (userID: UUID) => void;
  onSetProjectLead: () => void;
  onResendInvite: (userID: UUID) => Promise<unknown>;
}) {
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

  return (
    <>
      <TableComponents.Cell className="flex gap-4 p-3" data-cy="teammate-actions-cell">
        <Tooltip content="Preview allows you to test drive as another person to see how your teammates will experience this project in Join. You can choose any teammate or role to preview.">
          <Button
            label="Preview"
            onClick={() => props.onPreviewUser(props.collaborator.user.id)}
            type="secondary"
          />
        </Tooltip>

        <IconMenuButton
          aria-label="more actions button"
          data-cy="teammate-actions-menu-button"
          entries={[
            ...(props.isValidProjectLead
              ? [
                  props.collaborator.isProjectLead
                    ? {
                        id: 'remove-project-lead',
                        label: 'Remove as Project Lead',
                        onClick: () => props.onRemoveProjectLead(),
                      }
                    : {
                        id: 'set-project-lead',
                        label: 'Set as Project Lead',
                        onClick: () => props.onSetProjectLead(),
                      },
                ]
              : []),

            ...(props.collaborator.user.status === UserStatus.PENDING
              ? [
                  {
                    id: 'resend-invite',
                    label: 'Resend invite',
                    onClick: async () => {
                      try {
                        await props.onResendInvite(props.collaborator.user.id);
                        setToast(
                          {
                            message: `Invite email has been sent to ${props.collaborator.user.email} `,
                          },
                          ToastType.SUCCESS
                        );
                      } catch (e) {
                        setToast({
                          message: `Failed to send invite to ${props.collaborator.user.email} `,
                        });
                      }
                    },
                  },
                ]
              : []),
            {
              id: 'remove',
              label: 'Remove from project',
              onClick: () => setShowConfirmationDialog(true),
              type: 'destructive',
            },
          ]}
          type="secondary"
        />
      </TableComponents.Cell>
      {showConfirmationDialog && (
        <DialogsConfirm
          acceptCtaCopy="Remove"
          body={`Are you sure? Removing ${props.collaborator.user.name} will prevent them from accessing any project information.`}
          onClose={() => {
            setShowConfirmationDialog(false);
          }}
          onConfirm={() => props.onRemoveUser(props.collaborator.id)}
          open
          title={`Remove ${props.collaborator.user.name} from project`}
        />
      )}
    </>
  );
}
