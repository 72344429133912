import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../../theme/komodo-mui-theme';

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
export default (theme: KomodoTheme): any =>
  createStyles({
    avatar: {
      height: 32,
      width: 32,
      background: 'none',
    },
    chip: {
      cursor: 'move',
      background: theme.palette.backgroundWhite,
      borderRadius: theme.border.radius,
      width: '100%',
      '&:focus': {
        background: theme.palette.backgroundWhite,
      },
    },
    chipLabel: {
      maxWidth: '90%',
      flex: '1 1 auto',
    },
    chipLabelPadding: {
      marginLeft: 7,
    },
    chipUnitCount: {
      fontSize: 12,
    },
    delete: {
      marginLeft: 8,
      fontSize: 16,
      color: theme.palette.disabledGrey,
      '&:hover': {
        opacity: 0.5,
        color: theme.palette.disabledGrey,
      },
    },
    disabledColor: {
      color: theme.palette.disabledGrey,
    },
    drag: {
      display: 'flex',
      background: 'none',
    },
    metricsRow: {
      transform: 'translate(0px, 0px)',
      paddingLeft: 24,
      paddingRight: 24,
    },
    flexDisplay: {
      display: 'flex',
      alignItems: 'center',
    },
    joinPrimaryColor: {
      color: theme.palette.joinPrimary,
    },
    metricsLine: {
      height: 40,
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      '&:hover': { backgroundColor: theme.palette.backgroundGreen },
    },
    root: {
      justifyContent: 'flex-start',
      backgroundColor: theme.palette.backgroundWhite,
      '&:hover': { backgroundColor: theme.palette.backgroundGreen },
    },
  });
