import { useMutation } from '@apollo/client';

import {
  MutationUpdateMarkupsArgs,
  UpdateMarkupsMutationVariables,
} from '../../../generated/graphql';
import { updateMarkupsMutation } from '../../JoinGrid/hooks/queries';

type UpdateMarkupInput = {
  projectID: UUID;
  estimateID: UUID;
  markups: MarkupInput[];
  viewFilter?: ViewFilter;
  onSuccess?: () => void;
  analytics?: () => void;
};

export function useUpdateMarkups() {
  const [updateMarkups] = useMutation<MutationUpdateMarkupsArgs, UpdateMarkupsMutationVariables>(
    updateMarkupsMutation
  );
  return ({
    projectID,
    estimateID,
    markups,
    viewFilter,
    onSuccess,
    analytics,
  }: UpdateMarkupInput) =>
    updateMarkups({
      variables: {
        projectID,
        estimate: estimateID,
        markups,
        viewFilter,
      },
    }).then(() => {
      if (onSuccess) onSuccess();
      if (analytics) analytics();
    });
}
