/* eslint-disable no-param-reassign */
import { CellData, GridSelectionState, KeyBufferState, Position } from '../types';
import { getBorders } from '../utilities/cell';
import { areEqual, boundingBox, isSelected, normalizeRectangle } from '../utilities/position';

export const newSelectingState = (
  cellData: ((CellData[] | null) | null)[]
): GridSelectionState => ({
  cellData,
  visibleRows: { start: 0, end: 0 },
  renderedRows: { start: 0, end: 0 },
  selection: { start: { row: -1, column: -1 }, end: { row: -1, column: -1 } },
  isRowSelectedArr: Array(cellData.length).fill(false),
  numSelectedRows: 0,
  previouslySelectedRow: -1,
  currentlySelecting: false,
  isRenderingEditor: KeyBufferState.CLEAR,
  keyBuffers: {},
});

export const addKeyToBuffer = (state: GridSelectionState, position: Position, key: string) => {
  const cacheKey = `${position.row}-${position.column}`;
  const keyBuffer = state.keyBuffers[cacheKey];
  if (keyBuffer !== undefined) {
    keyBuffer.push(key);
  } else {
    state.keyBuffers[cacheKey] = [key];
  }
};

// A one-time grab of the key-buffer. This makes sure we never re-use the buffer twice.
export const getKeyBufferString = (state: GridSelectionState, position: Position) => {
  const cacheKey = `${position.row}-${position.column}`;
  const keyBuffer = state.keyBuffers[cacheKey];
  if (keyBuffer !== undefined) {
    const result = keyBuffer.join('');
    delete state.keyBuffers[cacheKey];
    return result;
  }
  return '';
};

export const isRendered = (state: GridSelectionState, i: number) =>
  state.renderedRows.start <= i && i <= state.renderedRows.end;

const setRangeHead = (state: GridSelectionState, start: Position, selected: boolean) => {
  const { cellData } = state;
  const rangeHeadRow = cellData[start.row];
  const rangeHeadCell = rangeHeadRow && rangeHeadRow[start.column].data;
  if (rangeHeadCell) {
    rangeHeadCell.rangeHead = selected;
    if (isRendered(state, start.row)) rangeHeadCell.update();
  }
};

// Update the cellData to reflect the current selection range. This is manually
// triggered after any updates to the selection itself. Cells later use this
// data to decide which styles to display. This funciton also manually triggers
// re-renders on any affected cells.
export const setSelectionRange = (state: GridSelectionState, start: Position, end: Position) => {
  const { selection } = state;

  // Either selection might be out of bounds.
  // We allow this to be able to reset the selection back / forth to (-1,-1)
  const [oldStart, oldEnd] = normalizeRectangle(selection.start, selection.end);
  const [newStart, newEnd] = normalizeRectangle(start, end);
  const [boundStart, boundEnd] = boundingBox(oldStart, oldEnd, newStart, newEnd);

  // Todo: This has a pathological case where the two are really far apart
  // and don't overlap much at all. However, it's vastly more efficient in the case
  // where they overlap a lot, because the operating time is dominated by the render.
  for (let i = boundStart.row; i <= boundEnd.row; i += 1) {
    for (let j = boundStart.column; j <= boundEnd.column; j += 1) {
      const point = { row: i, column: j };
      const [oldSelected, oldBorder] = isSelected(point, oldStart, oldEnd);
      const [newSelected, newBorder] = isSelected(point, newStart, newEnd);
      if (oldSelected !== newSelected || oldBorder !== newBorder) {
        const cell = getCellData(state, i, j);
        if (cell && cell.data) {
          cell.data.borders = getBorders(newSelected, newStart, newEnd, i, j);
          cell.data.selected = newSelected;
          if (isRendered(state, i)) cell.data.update();
        }
      }
    }
  }

  if (!areEqual(state.selection.start, start)) {
    setRangeHead(state, state.selection.start, false);
    setRangeHead(state, start, true);
  }
  state.selection.start = start;
  state.selection.end = end;
};

// Reuse the old start
export const setSelectionRangeEnd = (state: GridSelectionState, e: Position) => {
  setSelectionRange(state, state.selection.start, e);
};

export const selectRow = (state: GridSelectionState, i: number) => {
  const lineOfCells = state.cellData[0];
  if (lineOfCells) {
    setSelectionRange(state, { row: i, column: 0 }, { row: i, column: lineOfCells.length - 1 });
  }
};

export const selectCell = (state: GridSelectionState, i: number, j: number) => {
  setSelectionRange(state, { row: i, column: j }, { row: i, column: j });
};

export const getCellPosition = (state: { cellData: (CellData[] | null)[] }, p: Position) => {
  const { cellData } = state;
  const row = cellData[p.row];
  const refs = row && row[p.column];
  if (refs && refs.dom && refs.dom.current) {
    const { top, left, width, height } = refs.dom.current.getBoundingClientRect();
    return { top, left, width, height };
  }
  return undefined;
};

export const getCellData = (
  state: GridSelectionState,
  i: number,
  j: number
): CellData | undefined => {
  const { cellData } = state;
  const lineOfCells = cellData[i];
  return (lineOfCells && lineOfCells[j]) || undefined;
};

export const getCellValue = (
  state: GridSelectionState,
  i: number,
  j: number
): GridCellValue | undefined => {
  const { cellData } = state;
  const lineOfCells = cellData[i];
  const cell = lineOfCells && lineOfCells[j];

  return cell?.data?.value;
};
