import { FC, useEffect, useState } from 'react';

import { TextField } from '@material-ui/core';

import { withStyles } from '../../../theme/komodo-mui-theme';
import { formatCommas } from '../../../utilities/currency';
import { removeThousandsSeparator } from '../../../utilities/string';

import QuantityTextFieldStyles from './QuantityTextFieldStyles';

export const MAGNITUDE_LIMIT = 1000000000;

const parseMagnitude = (number: string | number) => {
  const magnitude = parseInt(removeThousandsSeparator(number, false), 10);
  return magnitude || 0;
};

type QuantityTextFieldProps = {
  classes: Classes<typeof QuantityTextFieldStyles>;
  cy: string;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  disabled?: boolean;
  id: string;
  magnitude?: number;
  setMagnitude: (magnitude: number) => void;
  placeholder: string;
  submit?: () => void;
  unitName?: string;
};

const QuantityTextField: FC<QuantityTextFieldProps> = ({
  classes,
  cy,
  disabled = false,
  id,
  submit = () => {},
  setMagnitude,
  magnitude,
  placeholder,
  unitName,
}) => {
  const [isFocused, setFocused] = useState(false);
  const [fieldText, setFieldText] = useState(''); // very local string state
  const [errorText, setErrorText] = useState('');
  // Magnitude provides the display state when un-focused to match backend format
  useEffect(() => {
    if (!isFocused) {
      if (typeof magnitude === 'number') {
        setFieldText(magnitude > 0 ? formatCommas(magnitude) : '');
      }
      if (typeof magnitude === 'string') {
        setFieldText(parseInt(magnitude, 10) > 0 ? formatCommas(magnitude) : '');
      }
    }
  }, [isFocused, magnitude]);

  const onSubmit = () => {
    if (typeof magnitude !== 'undefined' && magnitude < MAGNITUDE_LIMIT) {
      submit();
    }
  };

  return (
    <TextField
      className={`${unitName && classes.padding} ${classes.quantityField}`}
      data-cy={`input-Quantity-${cy}`}
      disabled={disabled}
      error={!!errorText}
      fullWidth
      helperText={errorText}
      id={id}
      InputLabelProps={{ shrink: true }}
      InputProps={{
        disableUnderline: true,
        classes: { formControl: classes.formControl, input: classes.input },
        id: `input-Quantity-${cy}-input`,
      }}
      label={unitName}
      onBlur={() => {
        setFocused(false);
        onSubmit();
      }}
      onChange={(evt) => {
        setFieldText(evt.target.value); // While editting, we keep the field uncontrolled
        const magnitudeInput = parseMagnitude(evt.target.value);
        setMagnitude(magnitudeInput);
        if (magnitudeInput < MAGNITUDE_LIMIT) {
          setErrorText('');
        } else {
          setErrorText('Quantity is above maximum.');
        }
      }}
      onFocus={() => setFocused(true)}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          const target = e.target as HTMLInputElement;
          target.blur();
        }
      }}
      placeholder={placeholder}
      value={fieldText}
    />
  );
};

export default withStyles(QuantityTextFieldStyles)(QuantityTextField);
