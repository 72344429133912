import { FC } from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';

import theme, { withStyles } from '../../theme/komodo-mui-theme';

import ZeroStateStyles from './styles';

type ZeroStateProps = {
  backgroundColor?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  children?: any;
  classes: Classes<typeof ZeroStateStyles>;
  height?: number;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  loading?: boolean;
};

const ZeroState: FC<ZeroStateProps> = ({
  backgroundColor = null,
  children = null,
  classes,
  height = 420,
  loading = false,
}) => (
  <div
    className={classes.containerOuter}
    style={{
      height,
      backgroundColor: backgroundColor || theme.palette.backgroundGrey,
    }}
  >
    {loading ? <CircularProgress /> : <>{children}</>}
  </div>
);

export default withStyles(ZeroStateStyles)(ZeroState);
