import { useState } from 'react';

import { ButtonBar } from '../../scales';

export enum HeaderDisplayBy {
  COUNT = 'Count',
  VOLUME = 'Volume',
}

type CountVolumeBarProps = {
  onOptionChange: (option: HeaderDisplayBy) => void;
  selectedOption: HeaderDisplayBy;
};

export default function InsightsListHeaderPieBar({
  onOptionChange,
  selectedOption,
}: CountVolumeBarProps) {
  const [selected, setSelected] = useState<HeaderDisplayBy>(selectedOption);

  const handleOptionChange = (option: HeaderDisplayBy) => {
    setSelected(option);
    onOptionChange(option);
  };

  return (
    <div className="flex items-center">
      <ButtonBar
        isFullWidth={false}
        onChange={(option) => handleOptionChange(option as HeaderDisplayBy)}
        options={[
          {
            label: HeaderDisplayBy.COUNT,
            value: HeaderDisplayBy.COUNT,
          },
          {
            label: HeaderDisplayBy.VOLUME,
            value: HeaderDisplayBy.VOLUME,
          },
        ]}
        value={selected}
      />
    </div>
  );
}
