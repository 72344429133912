import { createStyles } from '@material-ui/core';

import { KomodoTheme } from './komodo-mui-theme';

export const tableStyles = (theme: KomodoTheme) =>
  createStyles({
    row: {
      backgroundColor: 'white !important',
      borderTop: theme.border.line,
      '&:last-child': {
        borderBottom: theme.border.line,
        paddingLeft: 24,
        borderTop: theme.border.line,
      },
      '&:nth-child(even)': {
        backgroundColor: 'white',
      },
    },
    smallCell: {
      padding: '0 0 0 24px',
      width: 48,
    },
    tinyCell: {
      padding: '0 0 0 12px',
      width: 24,
    },
    bigCell: {
      ...theme.typography.body1,
      border: 'none',
      width: 186,
    },
    cell: {
      ...theme.typography.body1,
      border: 'none',
      maxWidth: 155,
      color: theme.palette.primaryGrey,
      padding: '8px 8px',
    },
    limitCell: {
      maxWidth: 110,
      overflowWrap: 'break-word',
    },
    header: {
      cursor: 'default',
      backgroundColor: theme.palette.ds.background.primary,
      ...theme.typography.webButton,
    },
    tooltip: {
      maxWidth: 500,
      fontSize: '0.9rem',
      cursor: 'default',
    },
    middleAlign: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
  });
