import { FC, useEffect, useState } from 'react';

import { useReactiveVar } from '@apollo/client';
import { LinearProgress } from '@material-ui/core';

import { projectCompsSetInputVar } from '../../../api/apollo/reactiveVars';
import PrintSubheader, { PrintSubheaderParams } from '../PrintCostReport/PrintSubheader';
import { PrintPageHeaderWrapper } from '../PrintSharedComponents/PrintPageHeaderWrapper';

type PrintProjectCompsPageHeaderProps = {
  isLoading: boolean;
  onLoaded?: () => void;
  pageText: PrintSubheaderParams;
  projectID: string;
  reportName?: string;
};

const PrintProjectCompsPageHeader: FC<PrintProjectCompsPageHeaderProps> = ({
  isLoading,
  onLoaded,
  pageText,
  projectID,
  reportName,
}) => {
  const [loadingHeader, setLoadingHeader] = useState(true);
  const { projectCompInputs } = useReactiveVar(projectCompsSetInputVar) || {};

  const projectsCount = (projectCompInputs?.filter((pci) => !pci.isExcluded) ?? []).length;

  useEffect(() => {
    if (!loadingHeader && onLoaded) onLoaded();
  }, [loadingHeader, onLoaded]);

  return (
    <>
      <PrintPageHeaderWrapper
        projectId={projectID}
        reportName={reportName || 'Project Comparison Report'}
        triggerOnComplete={() => setLoadingHeader(false)}
      />
      <PrintSubheader
        params={[
          { title: 'Projects included', value: projectsCount },
          { ...pageText, alignRight: true },
        ]}
      />
      {isLoading && <LinearProgress />}
    </>
  );
};

export default PrintProjectCompsPageHeader;
