import { useMutation } from '@apollo/client';

import { createQuantity } from '../../../analytics/analyticsEventProperties';
import { REFETCH_QUANTITY } from '../../../api/refetchSets';
import {
  CreateQuantityMutation,
  CreateQuantityMutationVariables,
} from '../../../generated/graphql';
import useSendAnalytics from '../../../hooks/useSendAnalytics';

import { createQuantityMutation } from './queries';

export default () => {
  const [createQuantityFunc] = useMutation<CreateQuantityMutation, CreateQuantityMutationVariables>(
    createQuantityMutation
  );

  const sendAnalytics = useSendAnalytics();

  return (projectID: UUID, milestoneID: UUID, magnitude: number, unitID: UUID) =>
    createQuantityFunc({
      refetchQueries: REFETCH_QUANTITY, // need to do this after new unit/ quantity creation
      variables: {
        projectID,
        milestoneID,
        magnitude,
        unitID,
      },
    }).then((response) => {
      const quantity = response.data?.createQuantity;
      if (quantity)
        sendAnalytics(
          createQuantity(
            quantity.id,
            quantity.magnitude.toString(),
            quantity.unit.name,
            milestoneID
          )
        );
    });
};
