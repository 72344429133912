import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../../theme/komodo-mui-theme';

const CONTAINER_HEIGHT = 360;
const IMAGE_HEIGHT = 220;

export default (theme: KomodoTheme) =>
  createStyles({
    assetUploadContainer: {
      border: theme.border.line,
      borderRadius: '4px',
      marginTop: 24,
      marginBotom: 24,
      height: CONTAINER_HEIGHT,
      minWidth: 572,
    },
    cloudUploadIcon: {
      alignSelf: 'center',
      fontSize: 36,
    },
    contentContainer: {
      height: CONTAINER_HEIGHT,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      textAlign: 'center',
      marginTop: 8,
    },
    imageContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      paddingBottom: 8,
    },
    heading2: {
      ...theme.typography.subtitle1,
      padding: 16,
    },
    img: {
      objectFit: 'contain',
      borderRadius: theme.border.radius,
      height: IMAGE_HEIGHT,
      width: IMAGE_HEIGHT,
      alignSelf: 'center',
    },
    loadingContainer: {
      objectFit: 'contain',
      display: 'flex',
      background: theme.palette.backgroundWhite,
    },
    loading: {
      margin: 'auto',
    },
    placeholderContainer: {
      paddingTop: 100,
    },
  });
