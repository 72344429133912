import { FC } from 'react';

import { removeYear } from '../../../utilities/string';

type SelectedReferencesToolTipProps = { categories: Category[] };

const SelectedReferencesToolTip: FC<SelectedReferencesToolTipProps> = ({ categories }) => {
  const getCategoryNumberMap = (categories: Category[]): Map<string, string[]> => {
    const categoryNumberMap = new Map<string, string[]>();
    categories.forEach((category) => {
      const { categorization, number, name } = category;
      if (categorization && categorization.name) {
        const categorizationName = categorization.name;
        const category = number || name;
        if (categoryNumberMap.has(categorizationName)) {
          const categoryNumbers = categoryNumberMap.get(categorizationName);
          if (categoryNumbers) {
            categoryNumbers.push(category);
          }
        } else {
          categoryNumberMap.set(categorizationName, [category]);
        }
      }
    });
    return categoryNumberMap;
  };

  const getCategoryNumberTypography = (categories: Category[]): JSX.Element[] => {
    const categoryNumberMap = getCategoryNumberMap(categories);
    const categoryNumberTypography: JSX.Element[] = [];
    categoryNumberMap.forEach((numbers, categoryName) => {
      const categoryNumberText = `${removeYear(categoryName)}: ${numbers.join(', ')}`;
      categoryNumberTypography.push(<div>{categoryNumberText}</div>);
    });
    return categoryNumberTypography;
  };

  return (
    <div className="flex flex-col gap-2 type-body2">
      <div>{`Applies to these cost categories: `}</div>
      {getCategoryNumberTypography(categories)}
    </div>
  );
};

export default SelectedReferencesToolTip;
