import { useMutation } from '@apollo/client';

import { REFETCH_IN_APP_NOTIFICATIONS } from '../../../api/refetchSets';
import {
  SetInAppNotificationReadMutation,
  SetInAppNotificationReadMutationVariables,
} from '../../../generated/graphql';
import { useCostMode } from '../../../utilities/costMode';

import { setInAppNotificationReadMutation } from './queries';

export default (notificationEventID: UUID) => {
  const [setInAppNotificationAsRead] = useMutation<
    SetInAppNotificationReadMutation,
    SetInAppNotificationReadMutationVariables
  >(setInAppNotificationReadMutation);

  const costMode = useCostMode();
  const submitFunc = (isRead: boolean) =>
    setInAppNotificationAsRead({
      variables: { notificationEventID, isRead, costMode },
      refetchQueries: REFETCH_IN_APP_NOTIFICATIONS,
    });

  return submitFunc;
};
