import { FC } from 'react';

import { withStyles } from '@material-ui/core/styles';
import { Help } from '@material-ui/icons';

import NormalTooltip from './NormalTooltip';
import styles from './NormalTooltipInfoIconStyles';

interface TooltipInfoIconProps {
  classes: Classes<typeof styles>;
  title: string | string[]; // multple strings get concat per line, one string wraps
}

const TooltipInfoIcon: FC<TooltipInfoIconProps> = ({ classes, title }) => (
  <NormalTooltip title={title}>
    <Help className={classes.icon} />
  </NormalTooltip>
);

export default withStyles(styles)(TooltipInfoIcon);
