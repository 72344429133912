import { BusinessOutlined } from '@material-ui/icons';

import { SearchResultType } from '../../../generated/graphql';
import { RouteKeys } from '../../../routes/paths';
import { generateSharedPath } from '../../../utilities/routes/links';
import { formatNumber } from '../../../utilities/string';
import { ItemStatusIcon } from '../../dragon-scales';
import useCoreSearchPermissions from '../../HomeTab/Search/hooks/useCoreSearchPermissions';
import { useSearchItemsQuery } from '../../HomeTab/Search/items/hooks/useSearchItemsQuery';
import { DEFAULT_ITEMS_SEARCH_QUERY } from '../../HomeTab/Search/items/SearchItemsUtils';
import { Button } from '../../scales';
import { SpinnerIcon } from '../icons';

import SearchResultLink from './SearchResultLink';
import { GlobalSearchMode } from './utils';

export default function ItemSearchResults(props: {
  onNavigate: (selection?: { itemID?: UUID; projectID?: UUID }) => void;
  projectName?: UUID;
  searchMode: GlobalSearchMode;
  searchText: string;
}) {
  const { hasAllItemsAccess } = useCoreSearchPermissions();

  const { data, previousData, loading } = useSearchItemsQuery({
    ...DEFAULT_ITEMS_SEARCH_QUERY,
    filters: {
      ...DEFAULT_ITEMS_SEARCH_QUERY.filters,
      projectNames:
        props.searchMode === GlobalSearchMode.PROJECT_ITEMS && props.projectName
          ? [props.projectName]
          : [],
    },
    search: props.searchText,
    pagination: { limit: 5, offset: 0 },
    searchResultType: hasAllItemsAccess ? SearchResultType.ALL : SearchResultType.MY,
  });

  const items = data?.searchItems?.data ?? previousData?.searchItems?.data ?? [];
  const itemsCount = data?.searchItems?.total ?? previousData?.searchItems?.total ?? 0;

  return (
    <div className="flex flex-col gap-4">
      {itemsCount > 0 && (
        <div className="flex flex-col gap-2">
          <div className="flex h-4 items-center gap-1">
            <div className="type-body3">Relevant items</div>
            {loading && <SpinnerIcon className="icon-md" />}
          </div>
          {items.map((item) => (
            <SearchResultLink
              key={item.id}
              hasAccess={item.project.hasAccess}
              onClick={() => props.onNavigate({ itemID: item.id, projectID: item.project.id })}
              to={generateSharedPath(RouteKeys.PROJECT_ITEMS_ITEM, {
                projectId: item.project.id,
                itemId: item.id,
              })}
            >
              <ItemStatusIcon value={item.status} />
              <div>
                <div className="type-heading3">{item.name}</div>
                <div className="type-body3">{item.project.name}</div>
                <div className="flex items-center gap-1">
                  <div className="flex icon-sm">
                    <BusinessOutlined fontSize="inherit" />
                  </div>
                  <div className="type-body3">{item.project.type}</div>
                </div>
              </div>
            </SearchResultLink>
          ))}
        </div>
      )}
      {itemsCount === 0 && !loading && <div className="muted type-body2">No items found</div>}
      {itemsCount > 1 && (
        <div className="flex w-full justify-center">
          <Button
            label={`See all ${formatNumber(itemsCount)} items`}
            onClick={() => props.onNavigate()}
            type="primary"
          />
        </div>
      )}
    </div>
  );
}
