import { gql } from '../../../../../api/graphqlFragments';

export const exportAverageCompToExistingMilestone = gql`
  mutation exportAverageCompToExistingMilestone(
    $projectID: UUID!
    $milestoneID: UUID!
    $projectCompsSetInput: ProjectCompsSetInput!
    $exportAverageCompEstimateInput: ExportAverageCompEstimateInput!
  ) {
    exportAverageCompToExistingMilestone(
      projectID: $projectID
      milestoneID: $milestoneID
      projectCompsSetInput: $projectCompsSetInput
      exportAverageCompEstimateInput: $exportAverageCompEstimateInput
    ) {
      id
    }
  }
`;

export const exportAverageCompToNewMilestone = gql`
  mutation exportAverageCompToNewMilestone(
    $projectID: UUID!
    $milestoneInput: MilestoneInput!
    $projectCompsSetInput: ProjectCompsSetInput!
    $exportAverageCompEstimateInput: ExportAverageCompEstimateInput!
  ) {
    exportAverageCompToNewMilestone(
      projectID: $projectID
      milestoneInput: $milestoneInput
      projectCompsSetInput: $projectCompsSetInput
      exportAverageCompEstimateInput: $exportAverageCompEstimateInput
    ) {
      id
    }
  }
`;

export const projectCategorizationsCategoryMatchesQuery = gql`
  query projectCategorizationsCategoryMatches($projectID: UUID!, $categoryNumbers: [String!]!) {
    projectCategorizationsCategoryMatches(
      projectID: $projectID
      categoryNumbers: $categoryNumbers
    ) {
      categorizationID
      matchingCategories
    }
  }
`;
