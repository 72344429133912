import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../../../theme/komodo-mui-theme';

export default (theme: KomodoTheme) =>
  createStyles({
    container: {
      display: 'flex',
      gap: '20px',
      alignItems: 'center',
      padding: '12px 0px',
      width: '100%',
    },
    info: {
      display: 'flex',
      flexDirection: 'column',
      gap: '8px',
      width: '100%',
    },
    itemChip: {
      background: theme.palette.app.badgesCategoryItem,
    },
    optionChip: {
      background: theme.palette.app.badgesCategoryOption,
    },
    optionCount: {
      ...theme.typography.webLabel,
    },
    parentItem: {
      ...theme.typography.webSmallLink,
      textDecoration: 'none',
    },
    title: {
      ...theme.typography.webHeading3,
    },
    link: {
      color: theme.palette.brand.middleBlue,
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  });
