import { NULL_ID } from '../../../../constants';
import {
  DesignPhaseType,
  InsightsTrigger,
  InsightsTriggerInput,
  TriggerOperatorType,
} from '../../../../generated/graphql';
import MilestoneDesignPhaseSelect from '../../../dragon-scales/MilestoneDesignPhaseSelect/MilestoneDesignPhaseSelect';

type Props = {
  designPhaseTypes: DesignPhaseType[];
  trigger: InsightsTrigger;
  onUpdate: (triggerID: UUID, trigger: InsightsTriggerInput) => void;
};

export const TriggerPhaseSelect = (props: Props) => {
  const entries = [
    { id: NULL_ID, name: 'Any Milestone Design Phase', abbreviation: '' },
    ...props.designPhaseTypes,
  ];
  const value = entries[props.trigger.threshold ?? 0];

  return (
    <MilestoneDesignPhaseSelect
      entries={entries}
      onChange={(designPhase) => {
        if (designPhase.id === value.id) return;
        // Design phase orderings start at 1 in the database.
        const phaseIndex = entries.findIndex(({ id }) => id === designPhase.id);
        if (phaseIndex === -1) return; // this should never happen
        props.onUpdate(props.trigger.id, {
          ...props.trigger,
          operator: designPhase.id === NULL_ID ? TriggerOperatorType.GTE : TriggerOperatorType.EQ,
          threshold: phaseIndex,
        });
      }}
      value={value.id}
    />
  );
};
