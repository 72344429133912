import {
  commonEventDataFields,
  detailedItemFields,
  gql,
  scheduleImpactFields,
  sharedDetailedItemLikeFields,
} from '../../../api/graphqlFragments';

export const setItemAssigneeMutation = gql`
  mutation setItemAssignee(
    $projectID: UUID!
    $id: UUID!
    $assigneeEmail: String
    $costMode: CostMode!
  ) {
    setItemAssignee(
      projectID: $projectID
      payload: { id: $id, assigneeEmail: $assigneeEmail }
      costMode: $costMode
    ) {
      ...sharedDetailedItemLikeFields
    }
  }
  ${sharedDetailedItemLikeFields}
`;

export const removeItemCategoryMutation = gql`
  mutation removeItemCategory(
    $projectID: UUID!
    $id: UUID!
    $category: UUID!
    $costMode: CostMode!
  ) {
    removeItemCategory(projectID: $projectID, id: $id, category: $category, costMode: $costMode) {
      ...sharedDetailedItemLikeFields
    }
  }
  ${sharedDetailedItemLikeFields}
`;

export const setItemCategoryMutation = gql`
  mutation setItemCategory($projectID: UUID!, $id: UUID!, $category: UUID!, $costMode: CostMode!) {
    setItemCategory(projectID: $projectID, id: $id, category: $category, costMode: $costMode) {
      ...sharedDetailedItemLikeFields
    }
  }
  ${sharedDetailedItemLikeFields}
`;

export const setItemDueDateMutation = gql`
  mutation setItemDueDate($projectID: UUID!, $id: UUID!, $dueDate: Time, $costMode: CostMode!) {
    setItemDueDate(
      projectID: $projectID
      payload: { id: $id, dueDate: $dueDate }
      costMode: $costMode
    ) {
      ...sharedDetailedItemLikeFields
    }
  }
  ${sharedDetailedItemLikeFields}
`;

export const setItemNumberMutation = gql`
  mutation setItemNumber($projectID: UUID!, $id: UUID!, $number: String!, $costMode: CostMode!) {
    setItemNumber(
      projectID: $projectID
      payload: { id: $id, number: $number }
      costMode: $costMode
    ) {
      ...sharedDetailedItemLikeFields
    }
  }
  ${sharedDetailedItemLikeFields}
`;

export const setItemPublishedMutation = gql`
  mutation setItemPublished(
    $projectID: UUID!
    $id: UUID!
    $createdBy: UUID
    $costMode: CostMode!
    $activeMilestoneID: UUID
  ) {
    setItemPublished(
      projectID: $projectID
      payload: { id: $id, createdBy: $createdBy, activeMilestoneID: $activeMilestoneID }
      costMode: $costMode
    ) {
      ...sharedDetailedItemLikeFields
    }
  }
  ${sharedDetailedItemLikeFields}
`;

export const setItemMilestoneMutation = gql`
  mutation setItemMilestone(
    $projectID: UUID!
    $id: UUID!
    $milestone: UUID
    $status: Status!
    $costMode: CostMode!
  ) {
    setItemMilestone(
      projectID: $projectID
      itemMilestoneInput: { id: $id, milestone: $milestone, status: $status }
      costMode: $costMode
    ) {
      ...sharedDetailedItemLikeFields
    }
  }
  ${sharedDetailedItemLikeFields}
`;

export const setItemActivityMutation = gql`
  mutation setItemActivity($projectID: UUID!, $id: UUID!, $activityID: UUID, $costMode: CostMode!) {
    setItemActivity(
      projectID: $projectID
      payload: { id: $id, activityID: $activityID }
      costMode: $costMode
    ) {
      ...sharedDetailedItemLikeFields
    }
  }
  ${sharedDetailedItemLikeFields}
`;

export const unsetItemActivityMutation = gql`
  mutation unsetItemActivity(
    $projectID: UUID!
    $id: UUID!
    $activityID: UUID
    $costMode: CostMode!
  ) {
    unsetItemActivity(
      projectID: $projectID
      payload: { id: $id, activityID: $activityID }
      costMode: $costMode
    ) {
      ...sharedDetailedItemLikeFields
    }
  }
  ${sharedDetailedItemLikeFields}
`;

export const setStatusMutation = gql`
  mutation setStatus($projectID: UUID!, $itemStatusInput: SetStatusInput!, $costMode: CostMode!) {
    setStatus(projectID: $projectID, itemStatusInput: $itemStatusInput, costMode: $costMode) {
      ...sharedDetailedItemLikeFields
    }
  }
  ${sharedDetailedItemLikeFields}
`;

export const convertItemToItemWithOptionsMutation = gql`
  mutation convertItemToItemWithOptions($projectID: UUID!, $item: UUID!, $costMode: CostMode!) {
    convertItemToItemWithOptions(projectID: $projectID, item: $item, costMode: $costMode) {
      ...detailedItemFields
    }
  }
  ${detailedItemFields}
`;

export const attachItemAsOptionMutation = gql`
  mutation attachItemAsOption(
    $projectID: UUID!
    $parent: UUID!
    $child: UUID!
    $costMode: CostMode!
  ) {
    attachItemAsOption(projectID: $projectID, parent: $parent, child: $child, costMode: $costMode) {
      ...detailedItemFields
    }
  }
  ${detailedItemFields}
`;

export const detachOptionMutation = gql`
  mutation detachOption($projectID: UUID!, $option: UUID!, $costMode: CostMode!) {
    detachOption(projectID: $projectID, option: $option, costMode: $costMode) {
      ...detailedItemFields
    }
  }
  ${detailedItemFields}
`;

export const setItemNameMutation = gql`
  mutation setItemName($projectID: UUID!, $id: UUID!, $name: String!, $costMode: CostMode!) {
    setItemName(projectID: $projectID, itemID: $id, name: $name, costMode: $costMode) {
      ...sharedDetailedItemLikeFields
    }
  }
  ${sharedDetailedItemLikeFields}
`;

export const setItemDescriptionMutation = gql`
  mutation setItemDescription(
    $projectID: UUID!
    $id: UUID!
    $description: String!
    $descriptionStyled: String!
    $costMode: CostMode!
  ) {
    setItemDescription(
      projectID: $projectID
      itemID: $id
      description: $description
      descriptionStyled: $descriptionStyled
      costMode: $costMode
    ) {
      ...sharedDetailedItemLikeFields
    }
  }
  ${sharedDetailedItemLikeFields}
`;

export const updateScheduleImpactMutation = gql`
  mutation UpdateScheduleImpact(
    $projectID: UUID!
    $itemID: UUID!
    $type: ScheduleImpactType!
    $criticalPath: Boolean!
    $days: Int!
  ) {
    updateScheduleImpact(
      projectID: $projectID
      input: { itemID: $itemID, type: $type, criticalPath: $criticalPath, days: $days }
    ) {
      item {
        ... on Option {
          id
          scheduleImpact {
            ...scheduleImpactFields
          }
        }
        ... on Item {
          id
          scheduleImpact {
            ...scheduleImpactFields
          }
        }
      }
    }
  }
  ${scheduleImpactFields}
`;

export const createItemMutation = gql`
  mutation createItem(
    $activityIDs: [UUID!]
    $name: String!
    $costImpact: USCents
    $bucketID: UUID
    $milestoneID: UUID!
    $projectID: UUID!
    $categories: [CategoryInput!]
    $assigneeEmail: String
    $dueDate: Time
    $description: String
    $descriptionStyled: String
    $costMode: CostMode!
    $visibility: Visibility
  ) {
    createItem(
      item: {
        activityIDs: $activityIDs
        projectID: $projectID
        bucketID: $bucketID
        costImpact: $costImpact
        milestoneID: $milestoneID
        name: $name
        categories: $categories
        assigneeEmail: $assigneeEmail
        dueDate: $dueDate
        description: $description
        descriptionStyled: $descriptionStyled
        visibility: $visibility
      }
      costMode: $costMode
    ) {
      ...detailedItemFields
    }
  }
  ${detailedItemFields}
`;

export const createItemsFromImportMutation = gql`
  mutation CreateItemsFromImport(
    $projectID: UUID!
    $milestoneID: UUID!
    $assetID: UUID!
    $visibility: Visibility!
  ) {
    createItemsFromImport(
      projectID: $projectID
      milestoneID: $milestoneID
      assetID: $assetID
      visibility: $visibility
    ) {
      userErrors
    }
  }
`;

export const removeItemMilestoneHistoryMutation = gql`
  mutation removeItemMilestoneHistory($projectID: UUID!, $id: UUID!, $milestone: UUID!) {
    removeItemMilestoneHistory(projectID: $projectID, id: $id, milestone: $milestone) {
      ...commonEventDataFields
    }
  }
  ${commonEventDataFields}
`;

export const deleteItemEstimateMutation = gql`
  mutation DeleteItemEstimate($input: DeleteItemEstimateInput!) {
    deleteItemEstimate(input: $input) {
      itemID
    }
  }
`;
