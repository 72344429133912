import { FC } from 'react';

import { withStyles } from '../../../../theme/komodo-mui-theme';
import CompanyTabStyles from '../../CompanyTabStyles';

import ProjectTemplatesListEntry from './ProjectTemplatesListEntry';

type ProjectTemplatesListProps = {
  classes: Classes<typeof CompanyTabStyles>;
  projectTemplates: ProjectTemplate[];
};

const ProjectTemplatesList: FC<ProjectTemplatesListProps> = ({ classes, projectTemplates }) => {
  return (
    <div className={classes.overflowAuto}>
      {projectTemplates.map((p: ProjectTemplate) => (
        <ProjectTemplatesListEntry key={p.id} projectTemplate={p} />
      ))}
    </div>
  );
};

export default withStyles(CompanyTabStyles)(ProjectTemplatesList);
