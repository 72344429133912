import { gql } from '../../../api/graphqlFragments';

export const createContingencyDrawMutation = gql`
  mutation createContingencyDraw(
    $projectID: UUID!
    $estimateID: UUID!
    $itemID: UUID!
    $contingencyID: UUID
  ) {
    createContingencyDraw(
      projectID: $projectID
      estimateID: $estimateID
      itemID: $itemID
      contingencyID: $contingencyID
    )
  }
`;
