import { createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
const styles = (theme: any) =>
  createStyles({
    accepted: {
      '& rect': {
        fill: `${theme.palette.accepted} !important`,
        fillOpacity: 0.8,
      },
    },
    axisLabel: {
      fill: 'black',
      fontWeight: 400,
      '& text': {
        fill: 'black',
        fontSize: 16,
      },
    },
    graph: {
      fontFamily: theme.typography.fontFamily,
      marginTop: 20,
    },
    hover: {
      '& rect': {
        fillOpacity: 1,
        stroke: `${theme.palette.primaryGrey} !important`,
        strokeWidth: '2 !important',
      },
    },
    itemHint: {
      fill: 'black',
      fontSize: 9,
    },
    legendContainer: {
      display: 'flex',
      flexDirection: 'row',
      fontFamily: theme.typography.fontFamily,
      '& .rv-discrete-color-legend-item__title': {
        ...theme.typography.label,
      },
    },
    spacer: {
      flexGrow: 1,
    },
    status: {
      '& rect': {
        fillOpacity: 0.75,
        mixBlendMode: 'multiply',
        strokeWidth: 1,
      },
    },
    pointer: {
      '& rect': {
        cursor: 'pointer',
      },
    },
    subtotal: {
      fill: 'black',
      fontSize: 12,
    },
  });

export default styles;
