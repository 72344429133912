import { FC } from 'react';

import { Avatar, Typography } from '@material-ui/core';
import { ArrowDropDown, ArrowForwardIos } from '@material-ui/icons';
import IconError from '@material-ui/icons/ErrorOutline';

import { withStyles } from '../../../theme/komodo-mui-theme';

import styles from './IssueChipStyles';

type IssueChipProps = {
  classes: Classes<typeof styles>;
  title: string;
  subTitle?: string;
  isHeader?: boolean;
};

const IssueChip: FC<IssueChipProps> = ({ classes, title, subTitle, isHeader }) => (
  <div className={`${classes.root} `} title={title}>
    <Avatar className={classes.avatar}>
      <IconError className={classes.iconError} />
    </Avatar>
    <div className={classes.label}>
      <Typography className={`${classes.text} ${isHeader ? classes.bold : ''}`} variant="body1">
        {title}
      </Typography>
      {subTitle && (
        <Typography className={`${classes.text} ${isHeader ? classes.bold : ''}`} variant="body1">
          {subTitle}
        </Typography>
      )}
    </div>
    <Avatar className={classes.avatar}>
      {isHeader ? (
        <ArrowDropDown className={classes.icon} />
      ) : (
        <ArrowForwardIos className={classes.iconForward} />
      )}
    </Avatar>
  </div>
);

export const StyledIssueChip = withStyles(styles)(IssueChip);

export default StyledIssueChip;
