import { pluralizeCountString } from '../../../utilities/string';

export const getSelectedText = (
  sharedIDs: UUID[],
  newIDs: UUID[],
  removedIDs: UUID[],
  userNoun = 'teammate'
) => {
  const newCount = newIDs.length;
  const removedCount = removedIDs.length;

  const selectedText =
    sharedIDs.length >= 2
      ? `Shared between ${pluralizeCountString(userNoun, sharedIDs.length)}`
      : 'Not shared';

  const summaryTextNew = newCount ? `Adding ${pluralizeCountString(userNoun, newCount)}.` : '';
  const summaryTextRemoved = removedCount
    ? `Removing ${pluralizeCountString(userNoun, removedCount)}.`
    : '';

  const summaryText = `${summaryTextNew} ${summaryTextRemoved}`;
  return { summaryText, selectedText };
};
