import { Outlet } from 'react-router-dom';

import { Add } from '@material-ui/icons';

import {
  CompanyAdminEventType,
  companyAdminAnalyticsEvent,
} from '../../../analytics/analyticsEventProperties';
import { COMPANY_ORGANIZATIONS } from '../../../constants';
import { Org } from '../../../generated/graphql';
import useSendAnalytics from '../../../hooks/useSendAnalytics';
import { ListDetail, ListDetailComponents } from '../../scales';
import CompanyTabHeader from '../Standards/CompanyTabHeader';

import CompanyOrganizationsPlaceholder from './CompanyOrganizationsPlaceholder';
import useNavigateToDefaultOrganizationRoute from './hooks/useNavigateToOrganizationRoute';
import OrganizationLink from './OrganizationLink';

type Props = {
  companyID: UUID;
  onAddNewOrganization: () => void;
  organizations: Org[];
};

export default function CompanyTabOrganizations(props: Props) {
  useNavigateToDefaultOrganizationRoute(props.companyID);
  const sendAnalytics = useSendAnalytics();
  const onAddNewOrganization = () => {
    props.onAddNewOrganization();
    sendAnalytics(
      companyAdminAnalyticsEvent(CompanyAdminEventType.ORGANIZATIONS_ADD_ORG_CTA, {
        location: 'sidebar',
      })
    );
  };

  const sidebar = (
    <>
      <ListDetailComponents.Button
        label="Add Organization"
        onClick={onAddNewOrganization}
        startAdornment={<Add />}
      />
      {props.organizations.map((organization) => (
        <OrganizationLink {...organization} key={organization.id} />
      ))}
    </>
  );

  const isEmpty = !props.organizations.length;

  const content = isEmpty ? (
    <CompanyOrganizationsPlaceholder
      onAdd={() => {
        props.onAddNewOrganization();
        sendAnalytics(
          companyAdminAnalyticsEvent(CompanyAdminEventType.ORGANIZATIONS_ADD_ORG_CTA, {
            location: 'main',
          })
        );
      }}
    />
  ) : (
    <Outlet />
  );

  return (
    <ListDetail
      detail={content}
      header={<CompanyTabHeader title={COMPANY_ORGANIZATIONS} />}
      list={sidebar}
    />
  );
}
