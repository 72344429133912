import { useMutation } from '@apollo/client';

import { REFETCH_CHANGE_CATEGORIZATION } from '../../../api/refetchSets';
import {
  ApplyMilestoneDraftEstimateMutation,
  ApplyMilestoneDraftEstimateMutationVariables,
} from '../../../generated/graphql';

import { applyMilestoneDraftEstimateMutation } from './queries';

function useApplyMilestoneDraftEstimate() {
  const [applyMilestoneDraftEstimateFunc, mutationResult] = useMutation<
    ApplyMilestoneDraftEstimateMutation,
    ApplyMilestoneDraftEstimateMutationVariables
  >(applyMilestoneDraftEstimateMutation);

  const submitFunc = (
    projectId: UUID,
    estimateId: UUID,
    onSuccess: () => void,
    onFailure: () => void
  ) =>
    applyMilestoneDraftEstimateFunc({
      variables: { projectID: projectId, estimateID: estimateId },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
      update: (cache: any) => {
        // delete any reference to the draft estimate
        // since it exists in at least two queries (milestone & draftestimate)
        // it can cause issues with apollo to leave it.
        Object.keys(cache.data.data).forEach(
          (key) => key.match(estimateId) && cache.data.delete(key)
        );
      },
      refetchQueries: REFETCH_CHANGE_CATEGORIZATION,
    })
      .then(() => {
        if (onSuccess) onSuccess();
      })
      .catch(() => {
        if (onFailure) onFailure();
      });

  return [submitFunc, mutationResult] as const;
}

export default useApplyMilestoneDraftEstimate;
