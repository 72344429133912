import { ProgramCollaboratorFieldsFragment } from '../../../generated/graphql';
import UserAvatar from '../../Collaborators/UserAvatar';
import { Chip, TableCell, Tooltip } from '../../scales';
import SearchText from '../../Search/SearchText/SearchText';

export type Props = {
  onClick: (entry: ProgramCollaboratorFieldsFragment) => void;
  projectCount: number;
  searchTerm: string;
  entry: ProgramCollaboratorFieldsFragment;
};

export default function ProgramUsersTableRow({
  entry,
  projectCount,
  searchTerm,
  onClick,
}: Props): TableCell[] {
  const thumbnail = <UserAvatar assignee={entry.user} />;

  const name = (
    <button
      className="type-table-text"
      onClick={() => onClick(entry)}
      onKeyDown={() => onClick(entry)}
      tabIndex={-1}
    >
      <SearchText searchTerm={searchTerm} text={entry.user.name} />
    </button>
  );

  const company = (
    <div className="type-table-text">
      <SearchText searchTerm={searchTerm} text={entry.company} />
    </div>
  );

  const belongsTo = (
    <div className="type-table-text">
      <SearchText
        searchTerm={searchTerm}
        text={`${entry.roles.length} / ${projectCount} Projects`}
      />
    </div>
  );

  const role = (
    <div className="type-table-text">
      <Tooltip
        content={
          <div className="grid grid-cols-2 gap-4">
            <div className="type-table-header">Project</div>
            <div className="type-table-header">Role</div>
            {entry.roles.map((r) => [
              <div key={`name-${r.projectName}`} className="type-body3">
                {r.projectName}
              </div>,
              <div key={`name-${r.projectName}-${r.roleName}`} className="type-body3">
                {r.roleName}
              </div>,
            ])}
          </div>
        }
      >
        <Chip text={<SearchText searchTerm={searchTerm} text={entry.mostCommonRoleName} />} />
      </Tooltip>
    </div>
  );
  return [
    { component: thumbnail },
    { component: name },
    { component: company },
    { component: belongsTo },
    { component: role },
  ];
}
