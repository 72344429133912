import { useCallback } from 'react';

import { itemSidebarHomeOpenVar } from '../../../api/apollo/reactiveVars';
import useLocalStorage from '../../../hooks/useLocalStorage';
import { RouteKeys } from '../../../routes/paths';
import { generateSharedPath } from '../../../utilities/routes/links';
import useProjectsCountsQuery from '../../ProjectsList/hooks/useProjectsCountsQuery';

import useCoreSearchPermissions from './hooks/useCoreSearchPermissions';
import SearchItemsData from './items/SearchItemsData';
import NoProjectsPage from './NoProjectsPage';
import SearchProjectsData from './projects/SearchProjectsData';
import { SearchMode } from './types';

export default function Search() {
  const [isFilterMenuOpen, setFilterMenuOpen] = useLocalStorage('SEARCH_FILTERS_OPEN', true);

  const searchMode = useCurrentSearchMode();
  const { hasItemsAccess } = useCoreSearchPermissions();
  const hasNoProjects = useUserHasNoProjects();

  const onToggleFilterMenu = useCallback(() => {
    itemSidebarHomeOpenVar(null);
    setFilterMenuOpen((prevState) => !prevState);
  }, [setFilterMenuOpen]);

  if (hasNoProjects) return <NoProjectsPage />;

  let SearchComponent = SearchProjectsData;
  if (searchMode === SearchMode.ITEMS && hasItemsAccess) {
    SearchComponent = SearchItemsData;
  }

  return (
    <div className="flex h-full flex-col bg-background-primary">
      <SearchComponent
        isFilterMenuOpen={isFilterMenuOpen}
        onToggleFilterMenu={onToggleFilterMenu}
      />
    </div>
  );
}

const useUserHasNoProjects = () => {
  const { data, loading } = useProjectsCountsQuery();
  return (
    !loading && !data?.projectsCounts.allProjectsCount && !data?.projectsCounts.myProjectsCount
  );
};

const useCurrentSearchMode = (): SearchMode => {
  const isItemsSearch = window.location.pathname === generateSharedPath(RouteKeys.SEARCH_ITEMS);
  return isItemsSearch ? SearchMode.ITEMS : SearchMode.PROJECTS;
};
