import { useCallback } from 'react';

import { ToastType } from '../../../api/gqlEnums';
import { setToast } from '../../../hooks/useToastParametersLocalQuery';
import { useCopyItemMutation } from '../../ItemCopy/useCopyItem';
import useCopyItemMutationParams from '../hooks/useCopyItemMutationParams';

import CopyItemDialog from './CopyItemDialog';

type CopyItemDialogDataProps = {
  item: ItemDataQueryItem;
  onClose: () => void;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  open: boolean;
};

const CopyItemDialogData = (props: CopyItemDialogDataProps) => {
  const params = useCopyItemMutationParams(props.item.id);

  const onClose = useCallback(() => {
    params.onSelectProject(undefined);
    props.onClose();
  }, [params, props]);

  const [copyItem] = useCopyItemMutation();
  const onComplete = useCallback(() => {
    if (params.variables)
      copyItem(params.variables, (copyItemID) => {
        setToast(null, ToastType.COPY_ITEM, {
          name: params.state.name,
          number: '',
          visibility: params.state.visibility,
          status: params.state.status,
          id: copyItemID,
          project: {
            id: params.variables?.input.projectID ?? '',
            name: params.state.projectName ?? '',
          },
        });
      });
    onClose();
  }, [params.variables, params.state, copyItem, onClose]);

  if (!props.open) return null;
  return (
    <CopyItemDialog
      onClose={onClose}
      onComplete={onComplete}
      onSelectProject={params.onSelectProject}
      onSendAnalytics={params.onSendAnalytics}
      onUpdateState={params.onUpdateState}
      state={params.state}
    />
  );
};

export default CopyItemDialogData;
