import { commonMilestoneFields, detailedMilestoneFields, gql } from '../../../api/graphqlFragments';

export const getMilestonesQuery = gql`
  query getMilestones($projectID: UUID!, $showDrafts: Boolean!) {
    milestones(projectID: $projectID, showDrafts: $showDrafts) {
      ...commonMilestoneFields
    }
  }
  ${commonMilestoneFields}
`;

export const getDetailedMilestonesQuery = gql`
  query getDetailedMilestones($projectID: UUID!, $showDrafts: Boolean!) {
    milestones(projectID: $projectID, showDrafts: $showDrafts) {
      ...detailedMilestoneFields
    }
  }
  ${detailedMilestoneFields}
`;
