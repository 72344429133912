import { FC } from 'react';

import InputsToggle, { ToggleOption } from '../../../Inputs/InputsToggle/InputsToggle';

export const TREND = 'Trend';
export const DETAILED = 'Detailed';
export const options = [TREND, DETAILED];

type DetailToggleProps = {
  detailLevel: string;
  setDetailLevel: (level: string) => void;
};

const mapStringToOption: (text: string) => ToggleOption = (text) => ({ key: text, text });

const DetailToggle: FC<DetailToggleProps> = ({ setDetailLevel, detailLevel }) => (
  <InputsToggle
    options={options.map((text: string) => mapStringToOption(text))}
    selected={[mapStringToOption(detailLevel)]}
    setSelected={(toggleOption: ToggleOption) => setDetailLevel(toggleOption.text)}
  />
);

export default DetailToggle;
