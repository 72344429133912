import { useState } from 'react';

import { Thumbnail } from '../../../dragon-scales';
import { TextArea } from '../../../scales';

type Props = {
  description: string;
  onEditMetadata: (metadata: { name?: string; description?: string }) => void;
  thumbnailAssetID?: UUID;
};

const ScenarioCardTableHeader = (props: Props) => {
  const [description, setDescription] = useState(props.description);

  return (
    <div className="flex gap-2 pb-3 pl-3 pr-3">
      <TextArea
        aria-label="scenario description"
        data-cy="scenario-description-text-area"
        onBlur={() => props.onEditMetadata({ description })}
        onChange={setDescription}
        placeholder="Add a description..."
        value={description ?? ''}
      />
      {props.thumbnailAssetID && (
        <div className="shrink-0 overflow-hidden rounded-md">
          <Thumbnail
            size={80} // kinda fussy height, based  on the above field heights
            thumbnail={props.thumbnailAssetID}
          />
        </div>
      )}
    </div>
  );
};

export default ScenarioCardTableHeader;
