import { projectSettingsVar } from '../../api/apollo/reactiveVars';
import { getCurrencySymbol } from '../../utilities/currency';

export const yLabelStyle = {
  y: -78,
  textAnchor: 'middle',
  transform: 'rotate(-90)',
};

export const CHART_HEIGHT = 400;
export const CHART_AREA_HEIGHT = 600;

export const calculateTickTotalForDomain = (
  domain: number[] | undefined,
  formatFn: (value: number) => string,
  maxTicks = 6
): number => {
  if (!domain || domain.length !== 2 || maxTicks <= 0) return 1;

  const [min, max] = domain;
  const difference = max - min;

  let tickTotal = maxTicks;
  for (let i = 0; i < maxTicks; i += 1) {
    const curr = min + (i * difference) / maxTicks;
    const next = min + ((i + 1) * difference) / maxTicks;
    // If consecutive tick labels match, then decrement total ticks
    if (formatFn(curr) === formatFn(next)) tickTotal -= 1;
  }
  return tickTotal || 1;
};

export const allDataFilteredMessage = 'No data matching your filters.';
export const allPointsHiddenMessage = 'No data points are visible.';

export const getChartLabelWithCurrency = (label: string) => {
  const currency = projectSettingsVar().CURRENCY;
  const symbol = getCurrencySymbol();

  if (currency !== symbol) return `${label} in ${currency} ${symbol}`;
  return `${label} in ${currency}`;
};
