import { EstimateType } from '../../../../api/gqlEnums';
import { ESTIMATE, MILESTONES } from '../../../../constants';
import { CostReportColumnType } from '../../../../generated/graphql';
import { getURLState } from '../../../../utilities/urlState';

export const getAvailableTypesForMilestone = (
  hasEstimate: boolean,
  hasBudget: boolean,
  includeRunningTotal: boolean,
  excludeEstimateType?: EstimateType.ACTIVE_ESTIMATE | EstimateType.BUDGET
) => {
  const includeEstimate = hasEstimate && excludeEstimateType !== EstimateType.ACTIVE_ESTIMATE;
  const includeBudget = hasBudget && excludeEstimateType !== EstimateType.BUDGET;
  return [
    ...(includeEstimate ? [CostReportColumnType.ESTIMATE_REPORT] : []),
    ...(includeBudget ? [CostReportColumnType.TARGET_REPORT] : []),
    ...(includeEstimate && includeRunningTotal ? [CostReportColumnType.RUNNINGTOTAL_REPORT] : []),
  ];
};

export const filterMilestonesForEstimateTransition = (
  milestones: Milestone[],
  currentMilestoneID: string,
  currentEstimateType: EstimateType
) => {
  return milestones.filter((m: Milestone) => {
    if (m.id === currentMilestoneID) {
      const isEstimateAndHasBudget =
        currentEstimateType === EstimateType.ACTIVE_ESTIMATE && !!m.budgetID;
      const isBudgetAndHasEstimate =
        currentEstimateType === EstimateType.BUDGET && !!m.activeEstimateID;
      // only show the current milestone if it has the other estimate type
      return isEstimateAndHasBudget || isBudgetAndHasEstimate;
    }
    return !!m.budgetID || !!m.activeEstimateID;
  });
};

export const getCurrentEstimateType = (location: Location) =>
  getURLState(location, MILESTONES).view === ESTIMATE.toUpperCase()
    ? EstimateType.ACTIVE_ESTIMATE
    : EstimateType.BUDGET;
