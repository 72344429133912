import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../../../theme/komodo-mui-theme';
import { HEADER_CONTAINER_HEIGHT } from '../MilestoneDetailsQuantitiesUtils';

const MilestoneDetailsQuantitiesGridHeaderStyles = (theme: KomodoTheme) =>
  createStyles({
    button: {
      borderRadius: 20,
      height: 36,
    },
    buttonContainer: {
      paddingRight: 8,
    },
    buttonIcon: {
      fontSize: 20,
    },
    buttonNo: {
      backgroundColor: theme.palette.backgroundWhite,
    },
    buttonYes: {
      backgroundColor: theme.palette.primaryGrey,
      color: theme.palette.backgroundWhite,
    },
    buttonIconContainer: {
      paddingTop: 2,
      paddingRight: 6,
    },
    dialog: {
      height: 300,
    },
    header: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      minHeight: HEADER_CONTAINER_HEIGHT,
      background: theme.palette.nestingGrey,
    },
    populateContainer: {
      display: 'flex',
      flexGrow: 1,
    },
    padding: {
      paddingRight: 8,
      paddingLeft: 8,
      display: 'flex',
      alignItems: 'center',
    },
    spacer: {
      flexGrow: 1,
    },
  });

export default MilestoneDetailsQuantitiesGridHeaderStyles;
