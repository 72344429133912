import { FetchResult, useMutation } from '@apollo/client';

import {
  REFETCH_CHANGE_ITEM,
  REFETCH_ITEM_HISTORY,
  REFETCH_SCENARIO_SANDBOX,
} from '../../../../api/refetchSets';
import {
  AccessLevel,
  ResourceType,
  ShareResourcesMutation,
  ShareResourcesMutationVariables,
  SharedResource,
} from '../../../../generated/graphql';

import { shareResourcesMutation } from './queries';

export function useShareResources() {
  const [mutationFunc] = useMutation<ShareResourcesMutation, ShareResourcesMutationVariables>(
    shareResourcesMutation
  );
  const submitFunc = (
    resourceType: ResourceType,
    resourceIDs: UUID[],
    userIDs: UUID[],
    accessLevels: AccessLevel[],
    onSuccess?: (s: SharedResource[]) => void,
    onFailure?: () => void
  ) =>
    mutationFunc({
      variables: { resourceType, resourceIDs, userIDs, accessLevels },
      refetchQueries: getRefetchSet(resourceType),
    })
      .then(({ data }: FetchResult<ShareResourcesMutation>) => {
        const resources = data?.shareResources;
        if (resources?.resources) {
          onSuccess?.(resources.resources);
        }
      })
      .catch(() => {
        if (onFailure) onFailure();
      });

  return submitFunc;
}

function getRefetchSet(resourceType: ResourceType) {
  switch (resourceType) {
    case ResourceType.ITEM:
      return [...REFETCH_CHANGE_ITEM, ...REFETCH_ITEM_HISTORY];
    case ResourceType.SCENARIO:
      return REFETCH_SCENARIO_SANDBOX;
    default:
      return [];
  }
}
