import { FC } from 'react';

import { Typography } from '@material-ui/core';
import Info from '@material-ui/icons/Info';

import { withStyles } from '../../../theme/komodo-mui-theme';
import NormalTooltip from '../../NormalTooltip/NormalTooltip';
import ExecutiveDashboardStyles from '../ExecutiveDashboardStyles';
import { CHART_LARGE, CHART_SMALL } from '../ExecutiveDashboardUtils';

type ChartVariant = typeof CHART_SMALL | typeof CHART_LARGE;

type ExecutiveDashboardChartFrameProps = {
  classes: Classes<typeof ExecutiveDashboardStyles>;
  component: JSX.Element;
  height?: number;
  title: string;
  variant?: ChartVariant;
  tooltip?: string;
};

const ExecutiveDashboardChartFrame: FC<ExecutiveDashboardChartFrameProps> = ({
  classes,
  component,
  height,
  title,
  variant = CHART_SMALL,
  tooltip,
}) => (
  <div
    className={`${classes.tileContainer} ${
      variant === CHART_LARGE ? classes.tileLarge : classes.tileSmall
    }`}
    style={{ height }}
  >
    <div className={classes.tileTitleContainer}>
      <div className={classes.leftAlign}>
        <Typography className={classes.tileTitle}>{title}</Typography>
        <NormalTooltip title={tooltip}>
          <Info className={classes.icon} />
        </NormalTooltip>
      </div>
    </div>

    <div className={classes.tileContent}>{component}</div>
  </div>
);

export default withStyles(ExecutiveDashboardStyles)(ExecutiveDashboardChartFrame);
