import { DeleteOutline } from '@material-ui/icons';

import { Button, Dialog, DialogContent } from '../../scales';

export type Props = {
  isOpen: boolean;
  milestone: Pick<Milestone, 'id' | 'name' | 'isDraft'>;
  onClose: () => void;
  onDelete: () => void;
};

export default function DeleteMilestoneConfirmDialog(props: Props) {
  return (
    <Dialog
      footerRight={
        <Button
          label="Delete"
          onClick={props.onDelete}
          startIcon={<DeleteOutline />}
          type="destructive"
        />
      }
      isOpen={props.isOpen}
      onClose={props.onClose}
      title={`Confirm Delete Milestone: ${props.milestone.name}`}
    >
      <DialogContent>
        <div>
          Are you sure you want to delete <b>{props.milestone.name}</b>?
        </div>
        {!props.milestone.isDraft && (
          <>
            <br />
            <div>
              Deleting <b>{props.milestone.name}</b> will permanently remove all related scenarios
              and scenario items.
            </div>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
}
