import { FC } from 'react';

import { ENTER } from '../../constants';
import { withStyles } from '../../theme/komodo-mui-theme';
import { CheckboxEmpty, CheckboxIndeterminate, CheckboxSelected } from '../Icons/CheckboxItemsList';

import styles from './CheckboxIndeterminateContainerStyles';

export enum CheckboxVariant {
  Empty,
  Indeterminate,
  Selected,
}

type CheckboxIndeterminateContainerProps = {
  checkboxStyle?: string;
  classes: Classes<typeof styles>;
  onToggle: () => void;
  variant: CheckboxVariant;
};

const CheckboxIndeterminateContainer: FC<CheckboxIndeterminateContainerProps> = ({
  checkboxStyle = '',
  classes,
  onToggle,
  variant,
}) => {
  const toggleCheckbox = () => {
    onToggle();
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  const handleEnter = (evt: any) => {
    const { key } = evt;
    if (key === ENTER) {
      onToggle();
    }
  };

  let checkbox = <></>;
  if (variant === CheckboxVariant.Indeterminate) {
    checkbox = (
      <CheckboxIndeterminate
        className={`${classes.checkbox} ${checkboxStyle}`}
        onClick={toggleCheckbox}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
        onKeyDown={(evt: any) => {
          handleEnter(evt);
        }}
        tabIndex={0}
        type="checkbox"
      />
    );
  }
  if (variant === CheckboxVariant.Selected) {
    checkbox = (
      <CheckboxSelected
        className={`${classes.checkbox} ${checkboxStyle}`}
        onClick={toggleCheckbox}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
        onKeyDown={(evt: any) => {
          handleEnter(evt);
        }}
        tabIndex={0}
        type="checkbox"
      />
    );
  }
  if (variant === CheckboxVariant.Empty) {
    checkbox = (
      <CheckboxEmpty
        className={`${classes.checkbox} ${checkboxStyle}`}
        data-cy="select-multiple-checkbox"
        onClick={toggleCheckbox}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
        onKeyDown={(evt: any) => {
          handleEnter(evt);
        }}
        tabIndex={0}
        type="checkbox"
      />
    );
  }

  return checkbox;
};

export default withStyles(styles)(CheckboxIndeterminateContainer);
