import { createStyles } from '@material-ui/core';

import { MAX_COL_WIDTH } from '../../../constants';
import { KomodoTheme } from '../../../theme/komodo-mui-theme';

const styles = (theme: KomodoTheme) =>
  createStyles({
    contentContainer: {
      display: 'flex',
      flex: 1,
      justifyContent: 'space-between',
      [theme.breakpoints.down('xs')]: {
        flexWrap: 'wrap',
      },
    },
    contents: {
      marginTop: 4,
      marginBottom: 2,
      [theme.breakpoints.down('xs')]: {
        marginLeft: 40,
      },
      display: 'flex',
      flexDirection: 'column',
    },
    header: {
      borderBottom: theme.border.line,
      padding: '4px 24px',
      alignItems: 'center',
      backgroundColor: theme.palette.joinGrey133,
      display: 'flex',
      justifyContent: 'space-between',
      background: theme.palette.backgroundWhite,
    },
    meetingContainer: {},
    newEstimate: {
      padding: 24,
    },
    tabContent: {
      maxWidth: MAX_COL_WIDTH,
      flexGrow: 1,
      padding: theme.spacing.generic[11],
      background: theme.palette.backgroundWhite,
      borderRight: theme.border.line,
    },
  });

export default styles;
