import { ComponentProps, useContext, useEffect, useRef } from 'react';

import { Typography } from '@material-ui/core';

import {
  ImageDimension,
  MetricsInput,
  PcMarkup,
  ProjectCompInput,
} from '../../../generated/graphql';
import { PROJECT_COMP_INCLUDE_IN_AVG_SWITCH, PROJECT_COMP_MENU } from '../../../tagConstants';
import theme, { withStyles } from '../../../theme/komodo-mui-theme';
import ImageViewerWrapper from '../../assets/AssetsProjectCompImage/AssetsProjectCompsImageWrapper';
import { Thumbnail } from '../../dragon-scales';
import NormalTooltip from '../../NormalTooltip/NormalTooltip';
import { Portal } from '../../Portal/Portal/Portal';
import useProjectBlobUrlQuery from '../../ProjectProperties/useProjectBlobUrlQuery';
import { IconMenuButton, Menu, Switch } from '../../scales';
import {
  ProjectCompIntersectionElements,
  ProjectCompPortals,
} from '../constants/elementIdentifiers';
import { EscalationTargetLocation, ProjectCompSectionVariant } from '../constants/projectCompTypes';
import ObserverContext from '../context/observer';
import { EscalationTarget } from '../hooks/useAutoEscalateMultiple';
import ProjectCompsCostTableHeader from '../ProjectCompsCostTable/ProjectCompsCostTableHeader';
import ProjectCompSectionCosts from '../ProjectCompSection/ProjectCompSectionCosts';
import ProjectCompSectionEscalation from '../ProjectCompSection/ProjectCompSectionEscalation';
import ProjectCompSectionMetrics from '../ProjectCompSection/ProjectCompSectionMetrics';
import ProjectCompSectionProjectInformation from '../ProjectCompSection/ProjectCompSectionProjectInformation';
import ProjectCompsInputsProjectName from '../ProjectCompsInputs/ProjectCompsInputsProjectName';
import { ProjectCompInputUpdateFunctions } from '../ProjectCompsSetInputStore/ProjectCompsSetInputUpdaters';
import { extractProjectCompValues } from '../ProjectCompsSetUtils';
import styles from '../ProjectCompsStyles';

import { ProjectCompFilterPanel } from './ProjectCompFilterPanel/ProjectCompFilterPanel';

export const thumbnailSize = ImageDimension._200;

type Props = {
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  averageCompExists: boolean;
  categories: ProjectCompsSet['categories'];
  costTableColumnInputs: CostTableColumnInputs;
  classes: Classes<typeof styles>;
  hasMarkups: boolean;
  index: number;
  markups: PcMarkup[];
  onAutoEscalateAllLocation: (target?: EscalationTarget) => void;
  onAutoEscalateAllTime: () => void;
  onTargetLocationChange: (location: EscalationTargetLocation) => void;
  projectComp: ProjectComp;
  projectCompInput: ProjectCompInput;
  projectCompInputUpdateFunctions: ProjectCompInputUpdateFunctions;
  menuSections: ComponentProps<typeof Menu>['sections'];
  selectedUnits: Unit[];
  targetLocation: EscalationTargetLocation;
  unit: Unit;
};

const ProjectComp = ({
  averageCompExists,
  categories,
  costTableColumnInputs,
  classes,
  hasMarkups,
  index,
  markups,
  onAutoEscalateAllLocation,
  onAutoEscalateAllTime,
  onTargetLocationChange,
  projectComp,
  projectCompInput,
  projectCompInputUpdateFunctions,
  menuSections,
  selectedUnits,
  targetLocation,
  unit,
}: Props) => {
  const {
    description,
    escalation,
    isExcluded,
    location,
    metrics,
    milestone,
    name,
    projectCompsCostTable,
    projectType,
    projectID,
    projectName,
    projectStatus,
    thumbnail,
  } = extractProjectCompValues(projectComp);

  const pinnedMetric = metrics?.find((m: MetricsInput) => m.unitID === unit.id);
  const quantityMagnitude = pinnedMetric?.quantityMagnitude || '';

  const fields = {
    description: description ?? undefined,
    location,
    projectStatus,
    projectType,
    quantityMagnitude,
  };

  // PROJECT COMP UPDATE FUNCTIONS
  const {
    projectCompLineInputUpdateFunctions,
    resetProjectCompInputName,
    setProjectCompInputCategorization,
    setProjectCompInputEscalationFutureTime,
    setProjectCompInputEscalationLocation,
    setProjectCompInputEscalationTime,
    setProjectCompInputMilestoneID,
    setProjectCompInputDescription,
    setProjectCompInputName,
    setProjectCompMetricsInput,
    projectCompInputViewFilterFunctions,
  } = projectCompInputUpdateFunctions;

  // STICKY COMPONENT OBSERVERS
  const { projectCompIntersectionObserver, showStickyProjectName, showStickyCostsHeader } =
    useContext(ObserverContext);
  const projectNameRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const { current } = projectNameRef;
    if (!current) return undefined;

    projectCompIntersectionObserver?.observe(current);
    return () => {
      projectCompIntersectionObserver?.unobserve(current);
    };
  }, [projectCompIntersectionObserver]);

  // THUMBNAIL
  // TODO: Thumbanil and ProjectCompsInputsProjectName should be in a seperate ProjectCompHeader component
  const projectThumbnail = useProjectBlobUrlQuery(projectID).data?.projectThumbnail;
  const imageThumbnail = <Thumbnail size={200} thumbnail={thumbnail} />;
  const clickableThumbnail = projectThumbnail?.blobUrl ? (
    <ImageViewerWrapper asset={projectThumbnail} blobUrl={projectThumbnail?.blobUrl}>
      {imageThumbnail}
    </ImageViewerWrapper>
  ) : (
    imageThumbnail
  );

  return (
    <>
      <div
        className={`${classes.comp} ${isExcluded && classes.excludedCompContainer}`}
        data-cy={`project-comp-${name}`}
      >
        <div className={classes.sectionPadding}>
          <div className={classes.inlineFlex}>
            {clickableThumbnail}
            <div className={classes.scrollBody}>
              <div className="flex items-center">
                <ProjectCompFilterPanel
                  projectCompInput={projectCompInput}
                  projectCompInputViewFilterFunctions={projectCompInputViewFilterFunctions}
                />
                <IconMenuButton
                  aria-label="more button"
                  data-cy={PROJECT_COMP_MENU}
                  sections={menuSections}
                  type="secondary"
                />
              </div>
            </div>
          </div>
        </div>
        <div
          ref={projectNameRef}
          className={`${ProjectCompIntersectionElements.PROJECT_NAME} ${classes.sectionPadding}`}
        >
          <ProjectCompsInputsProjectName
            name={name}
            nameDefault={projectName}
            resetName={resetProjectCompInputName}
            setName={setProjectCompInputName}
          />
        </div>
        {showStickyProjectName && (
          <Portal
            attributes={{
              order: String(index + 1),
              className: `${classes.compHeaderPortal}`,
            }}
            portalTargetID={ProjectCompPortals.COMP_HEADER}
          >
            <div className={classes.sectionPadding}>
              <div className={classes.stickyProjectNameContainer}>
                <NormalTooltip title={name}>
                  <div className="flex items-center overflow-hidden">
                    <Typography
                      style={{
                        ...theme.typography.header,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {name}
                    </Typography>
                  </div>
                </NormalTooltip>
                <div className="flex items-center">
                  <ProjectCompFilterPanel
                    projectCompInput={projectCompInput}
                    projectCompInputViewFilterFunctions={projectCompInputViewFilterFunctions}
                  />
                  <IconMenuButton
                    aria-label="more button"
                    data-cy={PROJECT_COMP_MENU}
                    sections={menuSections}
                    type="secondary"
                  />
                </div>
              </div>
              {showStickyCostsHeader && (
                <div className={classes.stickyCostsHeaderContainer}>
                  <ProjectCompsCostTableHeader
                    costTableColumnInputs={costTableColumnInputs}
                    selectedUnits={selectedUnits}
                  />
                </div>
              )}
            </div>
          </Portal>
        )}
        <ProjectCompSectionProjectInformation
          projectComp={projectComp}
          projectFields={fields}
          selectedMilestone={milestone}
          setDescription={setProjectCompInputDescription}
          setMilestone={setProjectCompInputMilestoneID}
        />
        <ProjectCompSectionMetrics
          metrics={metrics}
          selectedUnits={selectedUnits}
          setMetrics={setProjectCompMetricsInput}
          unit={unit}
          variant={ProjectCompSectionVariant.PROJECT_COMP}
        />
        <ProjectCompSectionEscalation
          activeMilestoneDate={milestone.date ?? undefined}
          escalation={escalation}
          onAutoEscalateAllLocation={onAutoEscalateAllLocation}
          onAutoEscalateAllTime={onAutoEscalateAllTime}
          onTargetLocationChange={onTargetLocationChange}
          project={projectComp.project}
          setEscalationFutureTime={setProjectCompInputEscalationFutureTime}
          setEscalationLocation={setProjectCompInputEscalationLocation}
          setEscalationTime={setProjectCompInputEscalationTime}
          targetLocation={targetLocation}
          variant={ProjectCompSectionVariant.PROJECT_COMP}
        />
        <ProjectCompSectionCosts
          categories={categories}
          costTableColumnInputs={costTableColumnInputs}
          hasMarkups={hasMarkups}
          index={index}
          lineInputUpdateFunctions={projectCompLineInputUpdateFunctions}
          markups={markups}
          projectCompInput={projectCompInput}
          projectCompsCostTable={projectCompsCostTable}
          selectedUnits={selectedUnits}
          setCategorization={setProjectCompInputCategorization}
          unit={unit}
          variant={ProjectCompSectionVariant.PROJECT_COMP}
        />
      </div>
      {averageCompExists && (
        <Portal
          key="sticky-comp-controls"
          attributes={{
            order: String(index + 1),
            className: classes.comp,
          }}
          portalTargetID={ProjectCompPortals.COMP_FOOTER_CONTROLS}
        >
          <div className="p-0.5 text-right">
            <Switch
              checked={!projectCompInput.isExcluded}
              data-cy={PROJECT_COMP_INCLUDE_IN_AVG_SWITCH}
              label="Include in the average"
              onChange={() =>
                projectCompInputUpdateFunctions.setProjectCompInputIsExcluded(
                  !projectCompInput.isExcluded
                )
              }
            />
          </div>
        </Portal>
      )}
    </>
  );
};

export default withStyles(styles)(ProjectComp);
