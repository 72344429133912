import { useCallback, useMemo, useState } from 'react';

import { toastParametersVar } from '../../../api/apollo/reactiveVars';
import { ToastType } from '../../../api/gqlEnums';
import { TimelineActivityType } from '../../../api/gqlEnumsBe';
import useCreateMilestone from '../../Dialogs/DialogsNewMilestone/hooks/CreateMilestoneHook';
import { useDesignPhaseTypes } from '../../Milestone/hooks/useDesignPhaseTypesQuery';
import { useMilestonesQuery } from '../../Milestones/hooks';
import { useCreateTimelineActivity } from '../../Timeline/hooks/CreateTimelineActivityHook';
import { useTimelineQuery } from '../../Timeline/hooks/TimelineHook';

import {
  SuggestionActivityType,
  defaultActivityInputs,
  findDesignPhaseGMP,
  getIsMilestoneSuggested,
  getIsMobilizationSuggested,
  getIsTurnoverSuggested,
  getShouldCreateActivity,
  getToastMessage,
} from './utils';

type Props = {
  projectID?: UUID | null;
  activityTypes: TimelineActivityType[]; // This makes sure refetch matches here and on Timeline
  onCreateMilestone?: (milestone: Partial<Milestone>) => void;
  onCreateEvent?: (activity: Partial<TimelineActivity>) => void;
};

export function useKeyDates(props: Props) {
  const [createMilestone] = useCreateMilestone();
  const [createEvent] = useCreateTimelineActivity();

  const milestoneQuery = useMilestonesQuery(props.projectID || '', false, !props.projectID);
  const timelineQuery = useTimelineQuery({
    projectID: props.projectID || '',
    types: props.activityTypes,
  });

  const [activityInputs, setActivityInputs] = useState(defaultActivityInputs);
  const setActivityInput = useCallback(
    (
      type: SuggestionActivityType,
      fieldKey: 'name' | 'designPhaseID' | 'date' | 'included',
      value: string | boolean
    ) => {
      setActivityInputs((prev) => ({
        ...prev,
        [type]: { ...prev[type], [fieldKey]: value },
      }));
    },
    [setActivityInputs]
  );

  const [hasDesignPhaseGMP, setHasDesignPhaseGMP] = useState<boolean>(false);
  const designPhaseTypes = useDesignPhaseTypes({
    onCompleted: (data) => {
      const foundPhaseGMP = findDesignPhaseGMP(data.designPhaseTypes);
      if (foundPhaseGMP) {
        setHasDesignPhaseGMP(true);
        setActivityInput('milestone', 'designPhaseID', foundPhaseGMP.id);
      }
    },
  });

  const statuses = useMemo(() => {
    const milestone = {
      isSuggested: getIsMilestoneSuggested(milestoneQuery.data?.milestones, hasDesignPhaseGMP),
      isLoading: milestoneQuery.loading,
    };
    const mobilization = {
      isSuggested: getIsMobilizationSuggested(timelineQuery.data?.timeline?.activities),
      isLoading: timelineQuery.loading,
    };
    const turnover = {
      isSuggested: getIsTurnoverSuggested(timelineQuery.data?.timeline?.activities),
      isLoading: timelineQuery.loading,
    };
    return { milestone, mobilization, turnover };
  }, [
    milestoneQuery.data?.milestones,
    milestoneQuery.loading,
    timelineQuery.data?.timeline?.activities,
    timelineQuery.loading,
    hasDesignPhaseGMP,
  ]);

  const hasSuggestions =
    statuses.milestone.isSuggested ||
    statuses.mobilization.isSuggested ||
    statuses.turnover.isSuggested;

  const { activityCount } = useMemo(() => {
    let activityCount = 0;
    if (props.projectID) {
      if (getShouldCreateActivity(activityInputs, statuses, 'milestone')) {
        activityCount += 1;
      }
      if (getShouldCreateActivity(activityInputs, statuses, 'mobilization')) {
        activityCount += 1;
      }
      if (getShouldCreateActivity(activityInputs, statuses, 'turnover')) {
        activityCount += 1;
      }
    }
    return { activityCount };
  }, [activityInputs, props.projectID, statuses]);

  const onSubmit = () => {
    if (props.projectID) {
      const shouldCreateMilestone = getShouldCreateActivity(activityInputs, statuses, 'milestone');
      const shouldCreateMobilization = getShouldCreateActivity(
        activityInputs,
        statuses,
        'mobilization'
      );
      const shouldCreateTurnover = getShouldCreateActivity(activityInputs, statuses, 'turnover');
      const message = getToastMessage({
        shouldCreateMilestone,
        shouldCreateMobilization,
        shouldCreateTurnover,
      });
      let count = [shouldCreateMilestone, shouldCreateMobilization, shouldCreateTurnover].filter(
        (v) => v
      ).length;
      const onComplete = () => {
        count -= 1;
        if (count === 0) {
          toastParametersVar({
            message,
            show: true,
            type: ToastType.SUCCESS,
          });
        }
      };
      if (shouldCreateMilestone) {
        createMilestone(
          props.projectID,
          activityInputs.milestone.name,
          activityInputs.milestone.date,
          activityInputs.milestone.designPhaseID,
          false,
          () => {
            milestoneQuery.refetch();
            timelineQuery.refetch();
            onComplete();
          }
        );
        const milestoneInfo = {
          name: activityInputs.milestone.name,
          designPhase: designPhaseTypes.find(
            (phase) => phase.id === activityInputs.milestone.designPhaseID
          ),
          date: activityInputs.milestone.date,
        };
        props.onCreateMilestone?.(milestoneInfo);
      }
      if (shouldCreateMobilization) {
        const mobilization = {
          name: activityInputs.mobilization.name,
          startDate: activityInputs.mobilization.date,
          type: TimelineActivityType.EVENT,
        };
        createEvent(props.projectID, props.activityTypes, mobilization, () => {
          onComplete();
        });
        props.onCreateEvent?.(mobilization);
      }
      if (shouldCreateTurnover) {
        const turnover = {
          name: activityInputs.turnover.name,
          startDate: activityInputs.turnover.date,
          type: TimelineActivityType.EVENT,
        };
        createEvent(props.projectID, props.activityTypes, turnover, () => {
          onComplete();
        });
        props.onCreateEvent?.(turnover);
      }
    }
  };

  return {
    activityCount,
    activityInputs,
    designPhaseTypes,
    hasSuggestions,
    onSubmit,
    setActivityInput,
    statuses,
  };
}
