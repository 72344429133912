import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';
import { DIALOG_MODAL_WIDTH, DIALOG_PADDING } from '../../Dialogs/DialogsStyles';

const PREVIEW_PADDING = 6;

export const PREVIEW_SECTION_WIDTH =
  (DIALOG_MODAL_WIDTH - DIALOG_PADDING * 2 - 2 * PREVIEW_PADDING) / 3;

export default (theme: KomodoTheme) =>
  createStyles({
    cursor: {
      cursor: 'pointer',
    },
    milestoneText: {
      ...theme.typography.h4,
    },
    previewBody: {
      display: 'flex',
      height: 60,
      marginTop: PREVIEW_PADDING,
    },
    previewBodySection: {
      display: 'flex',
      paddingRight: PREVIEW_PADDING,
      '&:last-child': {
        paddingRight: 0,
      },
    },
    previewContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      gap: `${PREVIEW_PADDING}px`,
    },
    previewHeader: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: 24,
      background: theme.palette.hiddenGrey,
      marginTop: 24,
    },
    previewSection: {
      display: 'flex',
      flexDirection: 'column',
      width: '33%',
    },
  });
