import { FC } from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';

import { withStyles } from '../../../theme/komodo-mui-theme';

import ExecutiveDashboardLoadingStateStyles from './ExecutiveDashboardLoadingStateStyles';

type ExecutiveDashboardLoadingStateProps = {
  classes: Classes<typeof ExecutiveDashboardLoadingStateStyles>;
  hasPadding?: boolean;
};

const ExecutiveDashboardLoadingState: FC<ExecutiveDashboardLoadingStateProps> = ({
  classes,
  hasPadding = true,
}) => (
  <div className={`${classes.containerOuter} ${hasPadding ? classes.padding : ''}`}>
    <div className={classes.containerInner}>
      <CircularProgress />
    </div>
  </div>
);

export default withStyles(ExecutiveDashboardLoadingStateStyles)(ExecutiveDashboardLoadingState);
