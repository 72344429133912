import { RouteObject } from 'react-router-dom';

import ApolloSandbox from '../components/ApolloSandbox';
import CompanyRoute from '../components/CompanyTab/CompanyRoute';
import { DashboardRoute } from '../components/dashboard/DashboardRoute';
import DeactivatedRoute from '../components/DeactivatedRoute';
import AuthorizeRedirectPage from '../components/DocumentMagic/AuthorizeRedirectPage/AuthorizeRedirectPage';
import EstimateAccordionItem from '../components/estimate/EstimateAccordion/EstimateAccordionItem';
import ExecutiveDashboardRoute from '../components/ExecutiveDashboard/ExecutiveDashboardRoute';
import InsightsClassicRoute from '../components/ExecutiveDashboard/InsightsClassicRoute';
import InsightsProjectRoute from '../components/ExecutiveDashboardV2/InsightsProject/InsightsProjectRoute';
import ForecastingExplore from '../components/ForecastingRoute/ForecastingExplore/ForecastingExplore';
import ForecastingReports from '../components/ForecastingRoute/ForecastingReports/ForecastingReports';
import ForecastingRoute from '../components/ForecastingRoute/ForecastingRoute';
import FourOhFour from '../components/FourOhFour';
import Search from '../components/HomeTab/Search/Search';
import IdeaRoute from '../components/IdeasRoute/IdeaRoute/IdeaRoute';
import IdeasRoute from '../components/IdeasRoute/IdeasRoute';
import IdeasIndexRoute from '../components/IdeasRoute/IndexRoute/IdeasIndexRoute';
import ItemActivityFeedData from '../components/Items/ItemActivityFeed/ItemActivityFeedData';
import ItemsListData from '../components/ItemsList/ItemsListData';
import Nav from '../components/Nav/Nav';
import Router from '../components/Nav/Router';
import PrintProjectComps from '../components/Print/PrintProjectComps/PrintProjectComps';
import ProgramDashboardRoute from '../components/ProgramReporting/ProgramDashboardRoute';
import ProgramRoute from '../components/ProgramReporting/ProgramReportingRoute';
import ProjectCompsSetData from '../components/ProjectComps/ProjectCompsSetData';
import ProjectItemRoute from '../components/ProjectItemsRoute/ProjectItemRoute';
import ProjectItemsRoute from '../components/ProjectItemsRoute/ProjectItemsRoute';
import ProjectRoute from '../components/ProjectRoute/ProjectRoute';
import RootRoute from '../components/RootRoute';
import { ScenariosRoute } from '../components/Scenarios/ScenariosRoute';
import CompaniesRoute from '../components/TeamRoute/CompaniesRoute';
import RoleEditRoute from '../components/TeamRoute/RolesRoute/RoleEditRoute';
import RoleRoute from '../components/TeamRoute/RolesRoute/RoleRoute';
import RolesRoute from '../components/TeamRoute/RolesRoute/RolesRoute';
import TeammatesRoute from '../components/TeamRoute/TeammatesRoute';
import TeamRoute from '../components/TeamRoute/TeamRoute';
import ItemDetailsData from '../components/ve-item-details/ItemDetailsData';

import paths from './paths';

/**
 * https://reactrouter.com/en/main/upgrading/v6-data#add-routerprovider-with-a-root-splat-route
 *
 * We're upgrading the router onto RR 6.4 incrementally. All new routes should
 * ideally be added here instead of the routes.tsx file that's in this directory
 * unless there's a good reason for it not to be the case. See comments
 * throughout this file to see how to add routes incrementally.
 *
 * To do so:
 *   1. `paths.ts`: Add a new entry to the `RouteKeys` enum.
 *   2. `paths.ts`: Add the path into `paths`. Note that paths are relative and
 *      do not include the full path.
 *   3. `index.tsx`: Add a route into the `routes` array. Note that:
 *      - You can define index routes if desired:
 *          https://reactrouter.com/en/main/start/concepts#index-routes
 *
 */

// RouteObject: https://reactrouter.com/en/main/route/route
const routes: RouteObject[] = [
  {
    /**
     * We define all our top-level routes that rely on auth'd users here.
     *
     * In this transition period between routers, we don't need to fully
     * define all routes in this array because we use the splat route
     * at the end to render the <Router /> as a fallback method of routing.
     *
     * As we bring in more routes, the children will get more filled-out and
     * eventually we'll be able to render <Navigate to="/404" /> instead of
     * <Router /> as the fallback.
     */
    path: paths.ROOT,
    element: <RootRoute />,
    children: [
      /** These routes do not render the Nav. */
      { path: paths.APOLLO, element: <ApolloSandbox /> },
      { path: paths.AUTHORIZE, element: <AuthorizeRedirectPage /> },
      { path: paths.DEACTIVATED, element: <DeactivatedRoute /> },
      { path: paths.NOT_FOUND, element: <FourOhFour /> },

      /** Most of our routes should render the Nav sidebar but some do not. We
       * use a Layout Route [1] to provide this functionality for those
       * routes.
       *
       * [1](https://reactrouter.com/en/main/start/concepts#layout-routes)
       */
      {
        element: <Nav />,
        children: [
          { path: paths.COMPANY, element: <CompanyRoute />, children: [] },
          {
            path: paths.FORECASTING,
            element: <ForecastingRoute />,
            children: [
              { path: paths.FORECASTING_EXPLORE, element: <ForecastingExplore /> },
              { path: paths.FORECASTING_REPORTS, element: <ForecastingReports /> },
              { path: paths.FORECASTING_PROJECT_COMPS, element: <ProjectCompsSetData /> },
              { path: paths.FORECASTING_PROJECT_COMPS_SAVED, element: <ProjectCompsSetData /> },
              { path: paths.FORECASTING_PROJECT_COMPS_PRINT, element: <PrintProjectComps /> },
              { path: paths.FORECASTING_PROJECT_COMPS_SAVED_PRINT, element: <PrintProjectComps /> },
            ],
          },
          {
            path: paths.INSIGHTS,
            element: <ExecutiveDashboardRoute />,
            children: [{ path: paths.INSIGHTS_PROJECT, element: <InsightsProjectRoute /> }],
          },
          {
            path: paths.INSIGHTS_CLASSIC,
            element: <InsightsClassicRoute />,
            children: [],
          },
          {
            path: paths.PROGRAM,
            element: <ProgramRoute />,
            children: [{ path: paths.PROGRAM_DASHBOARD, element: <ProgramDashboardRoute /> }],
          },
          {
            path: paths.PROJECT,
            element: <ProjectRoute />,
            children: [
              { path: paths.PROJECT_DASHBOARD, element: <DashboardRoute /> },
              {
                path: paths.PROJECT_IDEAS,
                element: <IdeasRoute />,
                children: [
                  { index: true, element: <IdeasIndexRoute /> },
                  { path: paths.PROJECT_IDEAS_IDEA, element: <IdeaRoute /> },
                ],
              },
              {
                path: paths.PROJECT_ITEMS,
                element: <ProjectItemsRoute />,
                children: [
                  { index: true, element: <ItemsListData /> },
                  { path: paths.PROJECT_ITEMS_ACTIVITY, element: <ItemActivityFeedData /> },
                  {
                    path: paths.PROJECT_ITEMS_ITEM,
                    element: <ProjectItemRoute />,
                    children: [
                      { index: true, element: <ItemDetailsData /> },
                      {
                        path: paths.PROJECT_ITEMS_ITEM_ESTIMATE,
                        element: <EstimateAccordionItem />,
                      },
                    ],
                  },
                ],
              },
              { path: paths.PROJECT_SCENARIOS, element: <ScenariosRoute /> },
              {
                path: paths.PROJECT_TEAM,
                element: <TeamRoute />,
                children: [
                  { path: paths.PROJECT_TEAM_TEAMMATES, element: <TeammatesRoute /> },
                  { path: paths.PROJECT_TEAM_COMPANIES, element: <CompaniesRoute /> },
                  {
                    path: paths.PROJECT_TEAM_ROLES,
                    element: <RolesRoute />,
                    children: [
                      { path: paths.PROJECT_TEAM_ROLES_ROLE_VIEW, element: <RoleRoute /> },
                      { path: paths.PROJECT_TEAM_ROLES_ROLE_EDIT, element: <RoleEditRoute /> },
                    ],
                  },
                ],
              },
            ],
          },
          { path: paths.SEARCH_ITEMS, element: <Search /> },
          { path: paths.SEARCH_PROJECTS, element: <Search /> },
          {
            // See above comments. This is our fallback to the legacy style of routing.
            path: paths.SPLAT,
            element: <Router />,
          },
        ],
      },
    ],
  },
];

export default routes;
