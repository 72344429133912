import { useMutation } from '@apollo/client';

import { REFETCH_COMPANIES } from '../../../api/refetchSets';
import {
  DeleteCollaboratorMutation,
  DeleteCollaboratorMutationVariables,
} from '../../../generated/graphql';

import { deleteCollaboratorMutation } from './queries';

function useDeleteCollaborator() {
  const [deleteCollaboratorFunc] = useMutation<
    DeleteCollaboratorMutation,
    DeleteCollaboratorMutationVariables
  >(deleteCollaboratorMutation);

  const submitFunc = (
    projectID: UUID,
    collaboratorId: UUID,
    onSuccess: (result: boolean) => void,
    onFailure?: () => void
  ) =>
    deleteCollaboratorFunc({
      refetchQueries: REFETCH_COMPANIES,
      variables: { projectID, collaboratorId },
      update: (cache) => {
        cache.modify({
          fields: {
            collaborators(collaborators, { readField }) {
              // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
              return collaborators.filter((c: any) => collaboratorId !== readField('id', c));
            },
          },
        });
        cache.evict({ id: `Collaborator:${collaboratorId}` });
      },
    })
      .then((result) => {
        const removeCollaborator = result.data?.removeCollaborator;
        if (onSuccess && removeCollaborator) onSuccess(removeCollaborator);
      })
      .catch(() => {
        if (onFailure) onFailure();
      });

  return submitFunc;
}

export default useDeleteCollaborator;
