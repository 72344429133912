import { Navigate } from 'react-router-dom';

import { PermissionResource } from '../../generated/graphql';
import HasPermission from '../HasPermission';

import CompanyTab from './CompanyTab';

export default function CompanyRoute() {
  return (
    <HasPermission
      fallback={<Navigate replace to="/404" />}
      permission={PermissionResource.COMPANY_DETAILS}
    >
      <CompanyTab />
    </HasPermission>
  );
}
