import { FC, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import { LinearProgress } from '@material-ui/core';

import { STATE_LIST_HOVER_SELECTOR_ARRAY, VIEW_OPTIONS } from '../../../constants';
import {
  getCategorizationsForProjectFromQueryData,
  useProjectCategorizationsQuery,
} from '../../../hooks/useProjectCategorizationsQuery';
import useProjectPropsQuery from '../../../hooks/useProjectPropsQuery';
import { withStyles } from '../../../theme/komodo-mui-theme';
import { makeOptionsFromCategorizations } from '../../../utilities/categorization';
import { returnGroupByOptions } from '../../../utilities/grouping';
import { getProjectIdFromUrl } from '../../../utilities/url';
import ChartsReportVariance from '../../Charts/ChartsReport/ChartsReportVariance';
import { HEADER_HEIGHT } from '../../CostReport/CostReport/CostReportStyles';
import CostReportList from '../../CostReport/CostReportList/CostReportList/CostReportList';
import { getCostReportListHeaderHeight } from '../../CostReport/CostReportList/CostReportListHeader/CostReportListHeaderStyles';
import {
  DESCRIPTION_WIDTH,
  HEADER_PADDING,
  LIST_PADDING,
  NOTES_COL_WIDTH_EXPANDED,
  VARIANCE_COL_WIDTH,
} from '../../CostReport/CostReportList/CostReportListRow/CostReportListRowStyles';
import { getHeight, getListHeaderHeight } from '../../CostReport/CostReportUtils';
import { useLoadTimer } from '../../PerfMonitor/utils';
import { useLoadUserReportComments } from '../../ReportsTab/ReportHooks';
import { filterDisplayGroupBys } from '../../shared-widgets/MultiGroup/MultiGroupOrderCategorizationUtils';
import useMemoWrapper from '../../useMemoWrapper';
import { useVarianceReportsQuery } from '../../VarianceReport/hooks/useVarianceReportsQuery';
import useVarianceReportParams from '../../VarianceReport/useVarianceReportParams';
import {
  getUserReportCommentQueryVariables,
  getVarianceColumnInputs,
  useIncludedColumnExpressions,
  useVarianceDescriptions,
} from '../../VarianceReport/VarianceReportUtils';
import usePrintWindow from '../PrintHooks/usePrintWindow';
import PrintPageBreak from '../PrintSharedComponents/PrintPageBreak';
import { PrintPageHeaderWrapper } from '../PrintSharedComponents/PrintPageHeaderWrapper';
import {
  LANDSCAPE_WIDTH,
  LandscapeOrientation,
  PORTRAIT_WIDTH,
  PortraitOrientation,
} from '../PrintUtils';

import PrintVarianceReportStyles from './PrintVarianceReportStyles';
import PrintVarianceReportSubheader from './PrintVarianceReportSubheader';

type PrintVarianceReportProps = {
  classes: Classes<typeof PrintVarianceReportStyles>;
};

const PrintVarianceReport: FC<PrintVarianceReportProps> = ({ classes }) => {
  // Read our project and milestone ids from the URL to populate queries
  const projectId = getProjectIdFromUrl();
  const { reportID } = useParams();
  const {
    data: { project },
  } = useProjectPropsQuery(projectId);
  const { data } = useProjectCategorizationsQuery(projectId);
  const categorizations = getCategorizationsForProjectFromQueryData(data);

  const milestoneID = project && project.activeMilestone && project.activeMilestone.id;

  // We group by the estimate's categorizations as a default setting, but allow the user to override.

  // LOCAL "STATE" SETUP from URL and localStorage per milestone
  // TODO: Just get the local blah
  const { settings, filterManager } = useVarianceReportParams(categorizations, projectId);
  const { viewMode, groupBy = [] } = settings;
  const viewFilter = filterManager.filterQueryInput;

  const options = makeOptionsFromCategorizations(categorizations);
  const groupByOptions = returnGroupByOptions(groupBy, options);
  const displayGroupBy = filterDisplayGroupBys(groupByOptions);

  const { includedExpressions, expressionCount } = useIncludedColumnExpressions(settings);
  const varianceColumnInputs = getVarianceColumnInputs(settings, includedExpressions);
  const { data: varianceData, loading: varianceReportsLoading } = useVarianceReportsQuery(
    projectId,
    displayGroupBy,
    varianceColumnInputs,
    true,
    viewFilter
  );
  const varianceReports = varianceData?.varianceReports || [];

  const viewParams = getUserReportCommentQueryVariables(displayGroupBy, varianceColumnInputs);
  const { data: commentData, loading: loadingVarianceReportComments } = useLoadUserReportComments(
    reportID,
    viewParams
  );
  const varianceReportComments = commentData?.varianceReportComments || undefined;

  // Columns for Variance Report
  const { varianceDescriptions, headerDescriptions } = useVarianceDescriptions(
    varianceReports,
    settings,
    includedExpressions,
    expressionCount
  );
  // Size the list section for print
  const columnCount = varianceDescriptions.length;
  const thinCount = columnCount > 6 ? columnCount - 6 : 0;
  const wideCount = 3;
  const LINE_PADDING = 9;
  const VARIANCE_HEADER_WIDTH = 2 * HEADER_PADDING + 2 * VARIANCE_COL_WIDTH;
  const tableWidth =
    wideCount * VARIANCE_HEADER_WIDTH +
    thinCount * VARIANCE_COL_WIDTH +
    thinCount * HEADER_PADDING +
    DESCRIPTION_WIDTH +
    LINE_PADDING +
    (varianceReportComments ? NOTES_COL_WIDTH_EXPANDED : 0);

  const isLandscape = tableWidth > PORTRAIT_WIDTH;
  const orientationStyle = isLandscape ? <LandscapeOrientation /> : <PortraitOrientation />;
  const width = isLandscape ? LANDSCAPE_WIDTH : PORTRAIT_WIDTH;
  const [listIsLoading, setListIsLoading] = useState(true);
  const zoomLevel =
    (listIsLoading || width > tableWidth) && groupBy.length !== 0 ? 1 : width / tableWidth;
  const transform = `scaleX(${zoomLevel})`;

  const chart = () =>
    varianceReportsLoading ? (
      <div className={classes.costReportChart} />
    ) : (
      projectId &&
      varianceReports && (
        <ChartsReportVariance
          categorizations={categorizations}
          classes={{
            root: classes.costReportChart,
          }}
          costReports={varianceReports}
          groupBy={displayGroupBy}
          headerDescriptions={headerDescriptions}
          isPrint
          projectId={projectId}
        />
      )
    );
  // CALCULATE CONSTS FOR PAGE BREAK IN ROW - TODO: Share helpers with MSR
  const paperHeight = isLandscape ? PORTRAIT_WIDTH : LANDSCAPE_WIDTH;
  const headerRef = useRef<HTMLDivElement>(null);
  const { current } = headerRef;
  const headerHeight = useMemoWrapper(getHeight, current, HEADER_HEIGHT);

  const pageHeight =
    paperHeight -
    headerHeight -
    LIST_PADDING - // We will leave a little space in case they modify the print settings
    getListHeaderHeight(zoomLevel, getCostReportListHeaderHeight(true, settings));
  const breakpoints = [pageHeight, zoomLevel];

  const [imagesAreReady, setImagesAreReady] = useState(false);
  const triggerOnComplete = () => setImagesAreReady(true);
  const hasList = viewMode !== VIEW_OPTIONS.CHART;
  const hooksLoading =
    varianceReportsLoading ||
    !imagesAreReady ||
    loadingVarianceReportComments ||
    (hasList && listIsLoading);

  useLoadTimer('PrintCostReport', hooksLoading);
  useLoadTimer('PrintVarianceReport', hooksLoading);

  usePrintWindow(!hooksLoading);

  useEffect(() => {
    if (project && project.name) document.title = `${project.name} - Variance Report`;
  }, [project]);

  // Most of the below is the same as PrintCostReport
  const hasChart = viewMode !== VIEW_OPTIONS.LIST && displayGroupBy.length;
  const header = (
    <div ref={headerRef}>
      <PrintPageHeaderWrapper
        projectId={projectId}
        reportName="Variance Report"
        triggerOnComplete={triggerOnComplete}
      />
      <PrintVarianceReportSubheader categorizations={categorizations} settings={settings} />
    </div>
  );

  const chartMargin = 64; // protection for wide margins on page 1
  const page1 = () => (
    <div style={{ height: paperHeight - chartMargin }}>
      {header}
      {chart()}
    </div>
  );

  const list = () => (
    <>
      {header}
      <div style={{ transformOrigin: 'top left', transform }}>
        <CostReportList
          breakpoints={breakpoints}
          costReports={varianceReports}
          displayColumnDescriptions={varianceDescriptions}
          groupBys={displayGroupBy}
          header={header}
          headerDescriptions={headerDescriptions}
          isVariance
          itemStatuses={STATE_LIST_HOVER_SELECTOR_ARRAY}
          milestoneEstimates={viewParams.milestoneEstimates}
          milestoneID={milestoneID ?? undefined}
          reportComments={varianceReportComments}
          setIsLoading={setListIsLoading}
          settings={settings}
          viewFilter={{}}
        />
      </div>
    </>
  );

  const chartPage = hasChart ? page1() : null;
  const firstBreak = (hasChart && hasList && <PrintPageBreak />) || null;
  const listPages = hasList ? list() : null;

  return (
    <div
      className={classes.root}
      // add a test ID so automated report distribution PDF generation (pdf/lib.go) knows the report has loaded
      data-testid={!hooksLoading ? 'report-distribution-print-hooks-loaded' : undefined}
    >
      {orientationStyle}
      <div className={classes.page} style={{ width }}>
        {chartPage}
        {firstBreak}
        {(varianceReportsLoading || !imagesAreReady) && <LinearProgress />}
        {listPages}
      </div>
      <div className={classes.footer} />
    </div>
  );
};

export default withStyles(PrintVarianceReportStyles)(PrintVarianceReport);
