import { Typography } from '@material-ui/core';

import { withStyles } from '../../../theme/komodo-mui-theme';

import TableStyles from './TableStyles';
import { TableCell as TableCellType } from './types';

type Props = {
  classes: Classes<typeof TableStyles>;
  entry: TableCellType;
  /** @deprecated */
  hasBlackBorderBottom?: boolean;
  isCentered?: boolean;
  isRightAligned?: boolean;
  isStickyBottom?: boolean;
};

const TableCell = ({
  isCentered: centered = false,
  classes,
  entry,
  isRightAligned: rightAligned = false,
  isStickyBottom = false,
  hasBlackBorderBottom = false,
}: Props) => {
  const { disabled = false, text, component, span = 1 } = entry;
  const listElementStyle = `${classes.listElement} ${
    hasBlackBorderBottom ? classes.blackBorderBottom : ''
  } ${isStickyBottom ? 'sticky bottom-0 bg-background-primary' : ''}`;

  return (
    <div className={listElementStyle} data-cy="table-cell" style={{ gridColumn: `span ${span}` }}>
      <div
        className={`${classes.listElementContent} ${centered ? classes.centered : ''} ${
          rightAligned ? classes.rightAligned : ''
        }`}
      >
        {component}
        {text && (
          <Typography
            className={`${classes.text} ${disabled ? classes.disabled : ''} ${
              entry.isNumber ? classes.isNumber : ''
            }`}
            data-cy={`table-cell-${text}`}
            variant="body1"
          >
            {text}
          </Typography>
        )}
      </div>
    </div>
  );
};

export default withStyles(TableStyles)(TableCell);
