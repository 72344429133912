import { useMutation } from '@apollo/client';

import { REFETCH_MILESTONE_ATTACHMENTS } from '../../../../api/refetchSets';
import {
  DetachFromMilestoneMutation,
  DetachFromMilestoneMutationVariables,
  SourceSystem,
} from '../../../../generated/graphql';
import { detachFromMilestoneMutation } from '../queries';

import { formatDetachAssetInputs } from './mutationUtils';

export function useDetachFromMilestone() {
  const [detachFromMilestone, mutationResult] = useMutation<
    DetachFromMilestoneMutation,
    DetachFromMilestoneMutationVariables
  >(detachFromMilestoneMutation);

  const submitFunc = (
    projectID: UUID,
    milestoneID: UUID,
    assetID: string,
    sourceSystem?: SourceSystem,
    onSuccess?: () => void,
    onFailure?: () => void
  ) => {
    const { joinAssetID, remoteAssetID } = formatDetachAssetInputs(assetID, sourceSystem);
    return detachFromMilestone({
      variables: { projectID, milestoneID, assetID: joinAssetID, remoteAssetID },
      // Refetch queries while on old apollo version
      refetchQueries: REFETCH_MILESTONE_ATTACHMENTS,
    })
      .then(() => {
        if (onSuccess) onSuccess();
      })
      .catch(() => {
        if (onFailure) onFailure();
      });
  };

  return [submitFunc, mutationResult] as const;
}
