import { commonCollaboratorFields, commonRoleFields, gql } from '../../../api/graphqlFragments';

export const previewProjectCollaborator = gql`
  query previewProjectCollaborator($projectID: UUID!, $userID: UUID!) {
    previewProjectCollaborator(projectID: $projectID, userID: $userID) {
      ...commonCollaboratorFields
    }
  }
  ${commonCollaboratorFields}
`;

export const previewProjectRole = gql`
  query previewProjectRole($projectID: UUID!, $roleID: UUID!) {
    previewProjectRole(projectID: $projectID, roleID: $roleID) {
      ...commonRoleFields
    }
  }
  ${commonRoleFields}
`;

export const projectCollaboratorQuery = gql`
  query projectCollaborator($projectID: UUID!) {
    currentCollaborator(projectID: $projectID) {
      ...commonCollaboratorFields
    }
  }
  ${commonCollaboratorFields}
`;
