import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../theme/komodo-mui-theme';

const ThumbnailStyles = (theme: KomodoTheme) =>
  createStyles({
    emptyMessage: {
      flexGrow: 1,
      textAlign: 'left',
    },
    hoverThumbnail: {
      color: theme.palette.shadedGrey,
      width: '244px',
      marginTop: '16px',
      transition: '.2s',
    },
    thumbButton: {
      maxWidth: '100%',
      width: '244px',
      maxHeight: '100%',
      height: '244px',
      boxSizing: 'border-box',
      borderRadius: 0,
      border: `1x dashed ${theme.palette.shadedGrey}`,
    },
    fullButton: {
      border: `1px dashed ${theme.palette.shadedGrey}`,
      borderRadius: 0,
      marginBottom: 8,
    },
    icon: {
      height: 16,
      color: theme.palette.disabledGrey,
      verticalAlign: 'text-top',
    },
    img: {
      objectFit: 'cover',
      height: '240px',
      width: '240px',
    },
    link: {
      ...theme.typography.link,
    },
    loadingContainer: {
      objectFit: 'cover',
      height: '240px',
      width: '240px',
      display: 'flex',
      background: theme.palette.backgroundGrey,
    },
    loading: {
      margin: 'auto',
    },
  });

export default ThumbnailStyles;
