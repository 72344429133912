import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';

const styles = (theme: KomodoTheme) =>
  createStyles({
    black: {
      color: `${theme.palette.primaryGrey} !important`,
    },
    hidden: {
      display: 'none',
    },
    date: {
      display: 'flex',
    },
    dateDisabled: {
      width: '100px',
      paddingLeft: 0,
    },
    picker: {
      ...theme.typography.body1,
      ...theme.typography.number,
      paddingRight: 0,
    },
    pickerHidden: {
      display: 'none',
    },
    pickerEditable: {
      cursor: 'pointer',
    },
    datePickerIcon: {
      width: 32,
      height: 32,
      marginTop: 1,
      color: theme.palette.joinPrimary,
      '&:hover': {
        backgroundColor: 'inherit',
      },
    },
    error: {},
  });

export default styles;
