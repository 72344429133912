import { useCallback, useState } from 'react';

/** @Alpha -- Note: This method for tracking Dialog Process is still in active development
  we will be adding more features to handle optional steps and other flow progressions
  it may end up taking more information about steps and returning more information about
  handling the flow */
export const useDialogFlow = (
  steps: number,
  onComplete: () => void,
  onClose: (index: number) => void
) => {
  const [index, setIndex] = useState(0);
  const progressPercent = (index + 1) / steps;

  const onBack = useCallback(() => {
    if (index <= 0) onClose(index);
    else setIndex((prev) => prev - 1);
  }, [index, onClose]);

  const onNext = useCallback(() => {
    if (index >= steps - 1) onComplete();
    else setIndex((prev) => prev + 1);
  }, [index, onComplete, steps]);

  return { index, onBack, onNext, progressPercent };
};
