import { FC } from 'react';

import { withStyles } from '../../../theme/komodo-mui-theme';

import styles from './DashboardChartsClearButtonStyles';

type Props = {
  classes: Classes<typeof styles>;
  clearFilters?: () => void;
};

const DashboardChartClearButton: FC<Props> = ({ classes, clearFilters = undefined }) =>
  clearFilters ? (
    <div
      className={classes.clearFilters}
      onClick={clearFilters}
      onKeyPress={clearFilters}
      role="button"
      tabIndex={0}
    >
      Clear filters.
    </div>
  ) : null;

export default withStyles(styles)(DashboardChartClearButton);
