import { useMutation } from '@apollo/client';

import { TimelineActivityType } from '../../../api/gqlEnumsBe';
import { REFETCH_TIMELINE_EVENTS_PHASES } from '../../../api/refetchSets';
import {
  CreateTimelineActivityMutation,
  CreateTimelineActivityMutationVariables,
  TimelineActivityInput,
} from '../../../generated/graphql';

import { createTimelineActivityMutation } from './queries';

export function useCreateTimelineActivity() {
  const [createTimelineActivityFn, mutationResult] = useMutation<
    CreateTimelineActivityMutation,
    CreateTimelineActivityMutationVariables
  >(createTimelineActivityMutation);

  const submitFunc = (
    projectID: UUID,
    types: TimelineActivityType[],
    input: TimelineActivityInput,
    onSuccess?: () => void,
    onFailure?: () => void
  ) => {
    return createTimelineActivityFn({
      variables: {
        projectID,
        input,
      },
      refetchQueries: REFETCH_TIMELINE_EVENTS_PHASES(projectID, types),
    })
      .then(() => {
        onSuccess?.();
      })
      .catch(() => {
        onFailure?.();
      });
  };

  return [submitFunc, mutationResult] as const;
}
