import { ReactNode, useContext } from 'react';

import {
  PermissionGroup,
  PermissionGroupType,
  PermissionLevel,
  PermissionResource,
} from '../../../generated/graphql';
import { ProjectTermStore } from '../../ProjectDisplaySettings/TerminologyProvider';
import { TextLink } from '../../scales';

import CostParamsTableEdit from './table/CostParamsTableEdit';
import CostParamsTableView from './table/CostParamsTableView';
import CostPermissionsTable from './table/CostPermissionsTable';
import {
  getItemPermissionDisplayDataSorted,
  getMilestonePermissionDisplayDataSorted,
  getProjectPermissionDisplayDataSorted,
} from './table/permissions';
import PermissionsTableEdit from './table/PermissionsTableEdit';
import PermissionsTableView from './table/PermissionsTableView';

type Props = {
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  editable: boolean;
  hasTrade: boolean;
  onChange: (id: UUID, level: PermissionLevel, permission?: string) => void;
  permissionGroups: PermissionGroup[];
};

const PermissionsTable = (props: Props) => {
  const termStore = useContext(ProjectTermStore);

  const costPermissionsGroup = props.permissionGroups
    .filter((group) => group.permissions.length > 0)
    .find((group) => group.type === PermissionGroupType.COST);

  if (!costPermissionsGroup) throw new Error('Could not find cost permissions for the role');

  const displayCostPermission = costPermissionsGroup?.permissions.find(
    (permission) => permission.resource === PermissionResource.DISPLAY_COSTS
  );
  if (!displayCostPermission)
    throw new Error('Could not find cost display permissions for the role');
  const canViewCosts = displayCostPermission?.level === PermissionLevel.VIEW_ONLY;

  const projectPermissionsGroup = props.permissionGroups
    .filter((group) => group.permissions.length > 0)
    .find((group) => group.type === PermissionGroupType.PROJECT);
  if (!projectPermissionsGroup) throw new Error('Could not find permissions for the project');
  const projectPermissionsDisplayData = getProjectPermissionDisplayDataSorted();

  const milestonePermissionsGroup = props.permissionGroups
    .filter((group) => group.permissions.length > 0)
    .find((group) => group.type === PermissionGroupType.MILESTONE);
  if (!milestonePermissionsGroup) throw new Error('Could not find permissions for the milestone');
  const milestonePermissionsDisplayData = getMilestonePermissionDisplayDataSorted(termStore);

  const itemInTradePermissionsGroup = props.permissionGroups
    .filter((group) => group.permissions.length > 0)
    .find((group) => group.type === PermissionGroupType.ITEM_IN_TRADE);
  if (!itemInTradePermissionsGroup && props.hasTrade)
    throw new Error('Could not find permissions for the item');

  const itemWithoutTradePermissionsGroup = props.permissionGroups
    .filter((group) => group.permissions.length > 0)
    .find((group) => group.type === PermissionGroupType.ITEM_OUT_OF_TRADE);
  if (!itemWithoutTradePermissionsGroup && props.hasTrade)
    throw new Error('Could not find permissions for the item not in trade');

  const itemPermissionsDisplayData = getItemPermissionDisplayDataSorted();

  return (
    <div className="flex flex-col gap-8">
      <div>
        <h2 className="pb-3 type-heading2">Cost Display Permissions</h2>
        <p className="pb-4 type-body1">
          Selecting multiple options in the “Markups display options” section allows the user role
          to switch the markups calculations of reports, estimates and project costs. Unchecked
          options will not appear. Selecting one option limits the user to one calculated markups
          view. Selecting “Hide all costs” eliminates visibility of the menu of Markups displays.
        </p>
        {props.editable ? (
          <CostParamsTableEdit
            canViewCosts={canViewCosts}
            displayCostPermission={displayCostPermission}
            markupModePermissions={costPermissionsGroup.permissions}
            onChange={props.onChange}
          />
        ) : (
          <CostParamsTableView
            displayCostPermission={displayCostPermission}
            hideCosts={displayCostPermission.level === PermissionLevel.NONE}
            markupModePermissions={costPermissionsGroup.permissions}
          />
        )}
      </div>
      <Section header="Cost Detail Permissions">
        <CostPermissionsTable
          canViewCosts={canViewCosts}
          editable={props.editable}
          group={costPermissionsGroup}
          hasTrade={props.hasTrade}
          onChange={props.onChange}
        />
      </Section>
      <div className="flex flex-col gap-3">
        <h2 className="type-heading2">Project Permissions</h2>
        <div className="flex flex-col gap-6">
          <Subsection
            link="https://success.join.build/en/knowledge/settings"
            subheader="Project Settings"
          >
            {props.editable ? (
              <PermissionsTableEdit
                group={projectPermissionsGroup}
                onChange={props.onChange}
                permissionsDisplayData={projectPermissionsDisplayData.projectSettings}
              />
            ) : (
              <PermissionsTableView
                group={projectPermissionsGroup}
                permissionsDisplayData={projectPermissionsDisplayData.projectSettings}
              />
            )}
          </Subsection>
          <Subsection
            link="https://success.join.build/en/knowledge/team"
            subheader="Project Administration"
          >
            {props.editable ? (
              <PermissionsTableEdit
                group={projectPermissionsGroup}
                onChange={props.onChange}
                permissionsDisplayData={projectPermissionsDisplayData.projectAdministration}
              />
            ) : (
              <PermissionsTableView
                group={projectPermissionsGroup}
                permissionsDisplayData={projectPermissionsDisplayData.projectAdministration}
              />
            )}
          </Subsection>
          <Subsection
            link="https://success.join.build/en/knowledge/reports"
            subheader="Report Permissions"
          >
            {props.editable ? (
              <PermissionsTableEdit
                group={projectPermissionsGroup}
                onChange={props.onChange}
                permissionsDisplayData={projectPermissionsDisplayData.reportPermissions}
              />
            ) : (
              <PermissionsTableView
                group={projectPermissionsGroup}
                permissionsDisplayData={projectPermissionsDisplayData.reportPermissions}
              />
            )}
          </Subsection>
        </div>
      </div>
      <Section header="Milestone Permissions">
        {props.editable ? (
          <PermissionsTableEdit
            group={milestonePermissionsGroup}
            onChange={props.onChange}
            permissionsDisplayData={milestonePermissionsDisplayData}
          />
        ) : (
          <PermissionsTableView
            group={milestonePermissionsGroup}
            permissionsDisplayData={milestonePermissionsDisplayData}
          />
        )}
      </Section>
      {itemInTradePermissionsGroup && (
        <Section
          description={
            props.hasTrade ? (
              <div className="type-body1">
                Control access to Items and Options that match the user&apos;s Category Permissions.
                Refer to Teammates page to assign Category Permissions.
              </div>
            ) : null
          }
          header={`Items and Options ${props.hasTrade ? 'with Category access' : 'Permissions'}  `}
        >
          {props.editable ? (
            <PermissionsTableEdit
              group={itemInTradePermissionsGroup}
              onChange={props.onChange}
              permissionsDisplayData={itemPermissionsDisplayData}
            />
          ) : (
            <PermissionsTableView
              group={itemInTradePermissionsGroup}
              permissionsDisplayData={itemPermissionsDisplayData}
            />
          )}
        </Section>
      )}
      {itemWithoutTradePermissionsGroup && props.hasTrade && (
        <Section
          description={
            <div className="type-body1">
              Control access to Items and Options that <u>do not match</u> the user&apos;s Category
              Permissions. User <u> does not </u>have access to these items&apos; categories.
            </div>
          }
          header="Items and Options without Category access"
        >
          {props.editable ? (
            <PermissionsTableEdit
              group={itemWithoutTradePermissionsGroup}
              onChange={props.onChange}
              permissionsDisplayData={itemPermissionsDisplayData}
            />
          ) : (
            <PermissionsTableView
              group={itemWithoutTradePermissionsGroup}
              permissionsDisplayData={itemPermissionsDisplayData}
            />
          )}
        </Section>
      )}
    </div>
  );
};

export default PermissionsTable;

type SectionProps = {
  children: ReactNode;
  header: string;
  description?: ReactNode;
};

function Section(props: SectionProps) {
  return props.description ? (
    <div className="flex flex-col gap-2">
      <h2 className="type-heading2">{props.header}</h2>
      <div className="flex flex-col gap-4">
        {props.description}
        {props.children}
      </div>
    </div>
  ) : (
    <div className="flex flex-col gap-4">
      <h2 className="type-heading2">{props.header}</h2>
      {props.children}
    </div>
  );
}

type SubsectionProps = {
  children: ReactNode;
  subheader: string;
  link: string;
};

function Subsection(props: SubsectionProps) {
  return (
    <div className="flex flex-col gap-1">
      <h3 className="type-heading3">{props.subheader}</h3>
      <div className="flex flex-col gap-4">
        <span className="type-body1">
          Learn more about the features below by visiting the{' '}
          <TextLink label="Success Hub" to={props.link} />
        </span>
        {props.children}
      </div>
    </div>
  );
}
