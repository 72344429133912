import { Position } from '../types';

import { bound } from './range';

export const normalizeRectangle = (start: Position, end: Position): [Position, Position] => {
  const r1 = { row: Math.min(end.row, start.row), column: Math.min(end.column, start.column) };
  const r2 = { row: Math.max(end.row, start.row), column: Math.max(end.column, start.column) };
  return [r1, r2];
};

export const boundedRectangle = (rows: number, cols: number, start: Position, end: Position) => {
  const [s, e] = normalizeRectangle(start, end);
  return [
    { row: bound(0, rows, s.row), column: bound(0, cols, s.column) },
    { row: bound(0, rows, e.row), column: bound(0, cols, e.column) },
  ];
};

// For normal move operations, stay on the same row or column if we overflow
// the bounds, but go back to the opposite end of the row/column.

export const moveRight = (p: Position, numCols: number) => {
  return {
    row: p.row,
    column: (p.column + 1) % numCols,
  };
};

export const moveLeft = (p: Position, numCols: number) => {
  return {
    row: p.row,
    column: (p.column + numCols - 1) % numCols,
  };
};

export const moveUp = (p: Position, numRows: number) => {
  return {
    row: (p.row + numRows - 1) % numRows,
    column: p.column,
  };
};

export const moveDown = (p: Position, numRows: number) => {
  return {
    row: (p.row + 1) % numRows,
    column: p.column,
  };
};

// For wrapping operations, we wrap down to the next row if we tabbed off the end,
// and back up to the previous if we shift-tabbed off the beginning.

export const wrappingRight = (p: Position, numRows: number, numCols: number) => {
  const willOverflow = p.column === numCols - 1;
  return willOverflow ? moveDown({ ...p, column: 0 }, numRows) : moveRight(p, numCols);
};

export const wrappingLeft = (p: Position, numRows: number, numCols: number) => {
  const willOverflow = p.column === 0;
  return willOverflow ? moveUp({ ...p, column: numCols - 1 }, numRows) : moveLeft(p, numCols);
};

export const boundingBox = (point: Position, ...points: Position[]): [Position, Position] => {
  const [start, end] = [{ ...point }, { ...point }];
  for (let i = 0; i < points.length; i += 1) {
    if (points[i].row < start.row) start.row = points[i].row;
    if (points[i].column < start.column) start.column = points[i].column;
    if (points[i].row > end.row) end.row = points[i].row;
    if (points[i].column > end.column) end.column = points[i].column;
  }
  return [start, end];
};

export const isSelected = (point: Position, start: Position, end: Position): [boolean, number] => {
  const selected =
    start.row <= point.row &&
    point.row <= end.row &&
    start.column <= point.column &&
    point.column <= end.column;
  let border = 0;
  if (selected) {
    // Add the length-4 bitwise vector representing the border.
    border += (point.row === start.row && 8) || 0;
    border += (point.row === end.row && 4) || 0;
    border += (point.column === start.column && 2) || 0;
    border += (point.column === end.column && 1) || 0;
  }
  return [selected, border];
};

export const areEqual = (a: Position, b: Position) => {
  return a.row === b.row && a.column === b.column;
};
