import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';

export enum SelectVariants {
  MENU = 'menu',
  GRID = 'grid',
  ASSET = 'asset',
  HEADER = 'header',
  COMPACT = 'compact',
  UNSTYLED = 'unstyled',
}

export default (theme: KomodoTheme) =>
  createStyles({
    clear: {
      background: 'none',
      border: 'none',
      fontSize: 14,
      fontWeight: 300,
      textDecoration: 'underline',
    },
    background: {
      backgroundColor: theme.palette.backgroundWhite,
    },
    containerOuter: {
      alignSelf: 'end',
      backgroundColor: theme.palette.backgroundWhite,
      boxSizing: 'border-box',
      filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
      fontFamily: theme.typography.fontFamily,
      overflowX: 'hidden',
      overflowY: 'hidden',
      position: 'relative',
      width: '100%',
    },
    disabledOption: {
      background: theme.palette.backgroundWhite,
      border: 0,
      color: theme.palette.disabledGrey,
    },
    iconBack: {
      height: 14,
    },
    icon: {
      fontSize: 'unset',
      height: 24,
      marginLeft: 16,
      marginRight: 16,
      marginTop: 8,
      marginBottom: 8,
      width: 24,
    },
    iconLabel: {
      display: 'block',
    },
    iconBackButton: {
      '& > span > svg': {
        position: 'relative',
        right: '-2px',
        width: 14,
      },
      '&:hover': {
        backgroundColor: theme.palette.backgroundGrey,
      },
      height: 32,
      marginLeft: 4,
      width: 32,
    },
    iconButton: {
      '& > span > svg': {
        width: 14,
      },
      '&:hover': {
        backgroundColor: theme.palette.backgroundGrey,
      },
      height: 32,
      marginRight: 4,
      width: 32,
    },
    list: {
      marginBlockEnd: 0,
      marginBlockStart: 0,
      overflowX: 'hidden',
      overflowY: 'auto',
      paddingInlineStart: 0,
    },
    listEmpty: {
      overflowY: 'hidden',
    },
    listNoScroll: {
      marginBlockEnd: 0,
      marginBlockStart: 0,
      overflowY: 'auto',
      paddingInlineStart: 0,
    },
    menuGrid: {
      width: '240px !important',
    },
    option: {
      '& > span > svg': {
        width: 14,
      },
      alignItems: 'center',
      background: theme.palette.backgroundWhite,
      cursor: 'pointer',
      display: 'flex',
      fontSize: 14,
      fontWeight: 300,
      justifyContent: 'space-between',
      textAlign: 'left',
      minWidth: 160,
    },
    optionBack: {
      borderBottom: theme.border.line,
      justifyContent: 'flex-start',
    },
    optionSelected: {
      background: `${theme.palette.hoverBackground} !important`,
    },
    optionSubText: {
      background: theme.palette.joinGrey400,
      borderBottom: theme.border.line,
      display: 'flex',
      fontSize: 14,
      fontWeight: 300,
      height: '100%',
      padding: '6px 12px 6px 12px',
      textAlign: 'left',
      width: '100%',
    },
    optionTitle: {
      height: '100%',
      outlineStyle: 'none',
      padding: '12px 0 12px 0',
      width: '100%',
    },
    paddingLeft: {
      paddingLeft: '12px',
    },
    paddingRight: {
      paddingRight: '12px',
    },
    root: {
      alignItems: 'center',
      color: theme.palette.joinPrimary,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginTop: '3px',
    },
    stickyBottom: {
      borderTop: theme.border.line,
      bottom: 0,
      position: 'sticky',
      zIndex: 1,
      backgroundColor: 'white',
    },
    stickyTop: {
      position: 'sticky',
      top: 0,
      zIndex: 1,
    },
  });
