import { createStyles } from '@material-ui/core';
import { MenuProps } from '@material-ui/core/Menu';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';

export const menuPropsDefault: Partial<MenuProps> = {
  anchorOrigin: {
    horizontal: 'left',
    vertical: 'bottom',
  },
  getContentAnchorEl: null,
  transformOrigin: {
    horizontal: 'left',
    vertical: 'top',
  },
};

const styles = (theme: KomodoTheme) =>
  createStyles({
    bgnWhite: {
      backgroundColor: theme.palette.backgroundWhite,
      borderRadius: 3,
    },
    borderBox: {
      boxSizing: 'border-box',
    },
    fullWidth: {
      width: '100%',
    },
    hide: {
      opacity: 0,
    },
    horizontal: {
      display: 'flex',
      flex: 1,
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    locationCompact: {
      marginTop: -33,
    },
    marginLeft: {
      marginLeft: 6,
    },
    name: {
      '@media print': {
        backgroundColor: theme.palette.backgroundWhite,
        color: 'black',
        fontSize: '16px',
        fontStyle: 'normal',
        paddingLeft: '0px',
        position: 'relative',
        zIndex: 1,
      },
      flexWrap: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    nameBold: {
      fontWeight: 400,
    },
    disabledItem: {
      padding: '5px 9px',
      cursor: 'default',
    },
    rootItem: {
      '&$selected': {
        // we need both this and the selected style to set the selected item's color
        backgroundColor: theme.palette.focusSelectBackground,
      },
      '&:hover': {
        backgroundColor: theme.palette.hoverBackground,
      },
      '&:focus-visible': {
        backgroundColor: theme.palette.focusSelectBackground,
      },
      height: 'unset',
      padding: `7px 8px`,
    },
    rootItemCompact: {
      padding: `4px 8px`,
    },
    row: {
      '@media print': {
        backgroundColor: theme.palette.backgroundWhite,
      },
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
      minHeight: 24,
      width: '100%',
    },
    select: {
      paddingLeft: 0,
    },
    selectMenu: {
      '&:hover': {
        backgroundColor: 'transparent',
      },
      minHeight: 32,
      padding: '0px 32px 0px 0px',
    },
    selected: {
      // we need both this and the root item style to set the selected item's color
      backgroundColor: theme.palette.hoverBackground,
    },
    subName: {
      '@media print': {
        ...theme.typography.h3,
      },
      ...theme.typography.body2,
    },
    hideBorder: {
      border: 'none',
      borderStyle: 'none',
      fontSize: 22,
      background: 'none',
      display: 'flex',
      alignItems: 'center',
      '&:hover': {
        backgroundColor: theme.palette.hoverBackground,
      },
    },
  });

export default styles;
