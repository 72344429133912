import { FC } from 'react';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

import { ITEM_DETAILS } from '../../../constants';
import { withStyles } from '../../../theme/komodo-mui-theme';
import usePermissions from '../../../utilities/permissions/usePermissions';
import { useCurrentUser } from '../../contexts/current-user';
import EventsItemHistoryList from '../../Events/EventsItemHistoryList/EventsItemHistoryList';
import CommentEntry from '../CommentEntry/CommentEntry';

import styles from './CommentsHistoryStyles';

type CommentsHistoryProps = {
  canComment: boolean;
  classes: Classes<typeof styles>;
  costMode: CostMode;
  item: ItemDataQueryItem;
  permissions: ReturnType<typeof usePermissions>;
  projectId?: UUID;
  triggerOnComplete?: () => void;
  variant: string;
  onItemMutated?: () => void;
};

const CommentsHistory: FC<CommentsHistoryProps> = ({
  canComment,
  classes,
  costMode,
  item,
  permissions,
  projectId,
  triggerOnComplete,
  variant,
  onItemMutated,
}) => {
  const user = useCurrentUser();

  return (
    <div className={variant === ITEM_DETAILS ? classes.root : ''}>
      <List>
        <EventsItemHistoryList
          costMode={costMode}
          customProjectId={projectId}
          item={item ?? undefined}
          permissions={permissions}
          triggerOnComplete={triggerOnComplete}
          variant={variant}
        />
        {canComment && item && (
          <ListItem className={`${classes.commentEntryRoot} ${classes.newComment}`} disableGutters>
            <CommentEntry item={item} onItemMutated={onItemMutated} user={user} />
          </ListItem>
        )}
      </List>
    </div>
  );
};

export default withStyles(styles)(CommentsHistory);
