import { FC } from 'react';

import { formatCost } from '../../../utilities/currency';
import { eventDateString } from '../../../utilities/dates';
import CommentsChip from '../../Comments/CommentsChip';
import { ItemStatusIcon } from '../../dragon-scales';
import { getItemDescription } from '../../Items/ItemActivityFeed/ItemActivityFeedUtils';
import ItemsListItemAssetsChip from '../../Items/ItemsListItem/ItemsListItemInfo/ItemsListItemAssetsChip';
import { ItemEventDetails } from '../utils';

type Props = {
  itemDetails?: ItemEventDetails;
};

const ItemHistoryEvent: FC<Props> = ({ itemDetails }) => {
  if (!itemDetails) return null;
  const { name, number, cost, dueDate, schedule, attachments, comments } = itemDetails;

  const itemDescription = getItemDescription(number, name);
  const dueDateString = dueDate ? eventDateString(dueDate) : undefined;
  const costImpact = cost
    ? `Cost Impact: ${formatCost(('value' in cost && cost.value) ?? 0)}`
    : undefined;

  return (
    <div className="flex">
      <ItemStatusIcon size="lg" value={itemDetails.status} />
      <div className="ml-2">
        <div className="type-body2">${itemDescription}</div>
        <div className="flex flex-row gap-2 whitespace-nowrap text-type-inactive type-body2">
          {costImpact && <div>{costImpact}</div>}
          {itemDetails.schedule && <div>Schedule: {itemDetails.schedule}</div>}
          {dueDateString && <div>Due Date: {dueDateString}</div>}
          {schedule && <div>Schedule: {schedule} days</div>}
          {attachments && <ItemsListItemAssetsChip attachmentCount={attachments} />}
          {comments && <CommentsChip commentCount={comments} />}
        </div>
      </div>
    </div>
  );
};

export default ItemHistoryEvent;
