import { useState } from 'react';
import { useEffectOnce } from 'react-use';

import { SIGN_UP_ABOUT_YOU_CTA, SIGN_UP_ABOUT_YOU_VIEW } from '../../../../actions/actionTypes';
import { analyticsEvent } from '../../../../analytics/analyticsEventProperties';
import { JOIN_SUPPORT_EMAIL } from '../../../../constants';
import useSendAnalytics from '../../../../hooks/useSendAnalytics';
import { setToast } from '../../../../hooks/useToastParametersLocalQuery';
import { useUploadImage } from '../../../assets/hooks/AssetsMutationHook';
import { useCurrentUser } from '../../../contexts/current-user';
import {
  useUpdateUserJobTitleMutation,
  useUpdateUserNameMutation,
  useUpdateUserPictureMutation,
  useUserDidSetupMutation,
} from '../../../Dialogs/DialogsEditProfile/DialogsEditProfileHooks';
import SignupContainer from '../SignupContainer';
import SignupContentBox from '../SignupContentBox';

import SetupUser from './SetupUser';

// This is where we define blocked types...
export const blockedTypes = ['svg'];

const SetupUserData = () => {
  document.title = `Join - Sign Up`;
  const sendAnalytics = useSendAnalytics();
  useEffectOnce(() => {
    sendAnalytics(analyticsEvent(SIGN_UP_ABOUT_YOU_VIEW));
  });

  const currentUser = useCurrentUser();
  const [inputName, setInputName] = useState(currentUser.name);
  const [inputJobTitle, setInputJobTitle] = useState(currentUser.jobTitle);
  const [loading, setLoading] = useState(false);

  const isJoinSupport = currentUser.email === JOIN_SUPPORT_EMAIL;

  const updateUserName = useUpdateUserNameMutation();
  const updateUserJobTitle = useUpdateUserJobTitleMutation();
  const updateUserPicture = useUpdateUserPictureMutation();
  const uploadImage = useUploadImage(blockedTypes);
  const confirmDidSetup = useUserDidSetupMutation();

  const onSubmit = async () => {
    sendAnalytics(analyticsEvent(SIGN_UP_ABOUT_YOU_CTA));

    const onComplete = () => {
      confirmDidSetup();
    };
    const trimmedName = inputName?.trim();
    const trimmedJobTitle = inputJobTitle?.trim();
    if (trimmedName !== currentUser.name) {
      await updateUserName(trimmedName);
    }
    if (trimmedJobTitle !== currentUser.jobTitle) {
      await updateUserJobTitle(trimmedJobTitle);
    }
    onComplete();
  };

  const addFile = (file: File) => {
    setLoading(true);
    const onSuccess = (asset: Asset) => {
      updateUserPicture(asset.location, () => {
        setLoading(false);
      });
    };
    const onFailure = (message: string) => {
      setLoading(false);
      setToast({ message });
    };
    uploadImage(file, null, onSuccess, onFailure);
    // sendAnalytics(initUploadAnalytics(file.name, 'User Thumbnail'));
  };

  const disableNext = loading || !inputName?.trim();

  return (
    <SignupContainer>
      <SignupContentBox
        disableNext={disableNext}
        onNext={onSubmit}
        title="A little more about you..."
      >
        <SetupUser
          disabled={isJoinSupport}
          jobTitle={inputJobTitle}
          name={inputName}
          setJobTitle={setInputJobTitle}
          setName={setInputName}
          setThumbnail={addFile}
          thumbnail={currentUser.pictureAsset?.thumbnailURL || currentUser.picture}
          thumbnailLoading={loading}
        />
      </SignupContentBox>
    </SignupContainer>
  );
};

export default SetupUserData;
