import { DesignPhaseTypesQuery, DesignPhaseTypesQueryVariables } from '../../../generated/graphql';
import { QueryHookOptions, useQuery } from '../../../hooks/useMountAwareQuery';
import { MountPolicy } from '../../../hooks/usePolicyOnFirstMount';

import { getDesignPhaseTypes } from './queries';

export const useDesignPhaseTypes = (
  options?: QueryHookOptions<DesignPhaseTypesQuery, DesignPhaseTypesQueryVariables>
) => {
  const { data } = useQuery<DesignPhaseTypesQuery, DesignPhaseTypesQueryVariables>(
    getDesignPhaseTypes,
    options,
    MountPolicy.SKIP_ON_MOUNT
  );

  return data?.designPhaseTypes ?? [];
};
