export enum CostFields {
  budget = 'budget',
  estimate = 'estimate',
  runningTotal = 'runningTotal',
  pendingMax = 'pendingMax',
  pendingMin = 'pendingMin',
}

export type CostTimeSeries = {
  date: Date;
  budget: number;
  estimate: number;
  runningTotal: number;
  pendingMax: number;
  pendingMin: number;
};

export type InsightsMilestone = {
  date: Date;
  id: UUID;
  active: boolean;
  designPhase?: string | null;
  name: string;
};
