import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';

export default (theme: KomodoTheme) =>
  createStyles({
    bar: {
      backgroundColor: theme.palette.accepted,
    },
    colorPrimary: {
      backgroundColor: theme.palette.joinGrey400,
    },
    container: {
      margin: 'auto',
      position: 'relative',
      width: 'max-content',
    },
    pages: {
      '& button + button': {
        marginLeft: 20,
      },
      alignItems: 'center',
      display: 'flex',
      width: 'max-content',
    },
    progress: {
      borderColor: theme.palette.accepted,
      height: 5,
      left: 2,
      position: 'absolute',
      top: 17,
      width: '98%',
    },
  });
