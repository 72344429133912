import { Button, Dialog, DialogContent } from '../../../scales';

export type Props = {
  onClose?: () => void;
};

// This is a very rare edge case protection code in case of a race condition.
export default function NoShareAccessData(props: Props) {
  return (
    <Dialog
      footerRight={<Button label="OK" onClick={props.onClose} type="primary" />}
      onClose={props.onClose}
      title="Share Draft"
    >
      <DialogContent>
        <div className="flex flex-col items-center">
          <div className="mr-auto type-body1">{`Current user doesn't have share access`}</div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
