import { pendingCostsImg } from '../../InsightsIcons';

export default function CostLegend() {
  const legendEntry = (icon: JSX.Element, text: string) => (
    <div className="flex items-center pl-3">
      <div className="p-1">{icon}</div>
      <div className="p-1">
        <div className="type-label">{text}</div>
      </div>
    </div>
  );

  const estimateImg = (
    <svg fill="none" height="4" viewBox="0 0 12 4" width="12" xmlns="http://www.w3.org/2000/svg">
      <mask fill="white" id="path-1-inside-1_5473_97274">
        <path d="M0 0H12V4H0V0Z" />
      </mask>
      <path
        d="M0 6H1V2H0V6ZM3 6H5V2H3V6ZM7 6H9V2H7V6ZM11 6H12V2H11V6ZM0 8H1V0H0V8ZM3 8H5V0H3V8ZM7 8H9V0H7V8ZM11 8H12V0H11V8Z"
        fill="black"
        mask="url(#path-1-inside-1_5473_97274)"
      />
    </svg>
  );
  const runningImg = <div className="h-1 w-3 self-center bg-border-calculations-total" />;
  const budgetImg = <div className="h-1 w-3 self-center bg-entities-budget" />;

  return (
    <div className="flex h-7 w-full items-center pt-1">
      {legendEntry(estimateImg, `Estimate`)}
      {legendEntry(runningImg, `Running Total`)}
      {legendEntry(budgetImg, `Budget`)}
      {legendEntry(pendingCostsImg, `Pending`)}
    </div>
  );
}
