import { useMutation } from '@apollo/client';

import { REFETCH_CHANGE_MILESTONE_EVENTS_LIST } from '../../../api/refetchSets';
import {
  BulkItemUpdateMutation,
  BulkItemUpdateMutationVariables,
} from '../../../generated/graphql';
import { getCostMode } from '../../../utilities/costMode';

import { bulkItemUpdateMutation } from './queries';

function useUpdateBulkItems(
  toastAlert: (message: string) => void,
  onSuccess: (itemCount: number, optionCount: number) => void
) {
  const [bulkItemUpdate] = useMutation<BulkItemUpdateMutation, BulkItemUpdateMutationVariables>(
    bulkItemUpdateMutation
  );
  return (projectID: UUID, itemsInput: BulkItemsUpdateInput) => {
    const {
      addCategories,
      assigneeEmail,
      clearAssigneeEmail,
      clearCategories,
      clearMeetingID,
      dueDate,
      itemIDs,
      meetingID,
      milestoneID,
      status,
    } = itemsInput;

    if (itemIDs) {
      bulkItemUpdate({
        refetchQueries: REFETCH_CHANGE_MILESTONE_EVENTS_LIST,
        variables: {
          projectID,
          addCategories,
          assigneeEmail,
          clearAssigneeEmail,
          clearCategories,
          clearMeetingID,
          dueDate,
          itemIDs,
          meetingID,
          milestoneID,
          status,
          costMode: getCostMode(),
        },
      })
        .then((response) => {
          const { data } = response;
          if (onSuccess && data) {
            const { itemCount, optionCount } = data.bulkItemUpdate;
            onSuccess(itemCount, optionCount);
          }
        })
        .catch(() => {
          // setErrorToast for user
          toastAlert(`Could not edit items, please try again or contact Support`);
        });
    }
  };
}

export default useUpdateBulkItems;
