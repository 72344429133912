import { gql } from '@apollo/client';

export const createProjectRoleMutation = gql`
  mutation createProjectRole($projectID: UUID!, $roleID: UUID) {
    createProjectRole(projectID: $projectID, roleID: $roleID)
  }
`;

export const deleteProjectRoleMutation = gql`
  mutation deleteProjectRole(
    $projectID: UUID!
    $roleID: UUID!
    $collaboratorRoleInputs: [CollaboratorRoleInput!]!
  ) {
    deleteProjectRole(
      projectID: $projectID
      roleID: $roleID
      collaboratorRoleInputs: $collaboratorRoleInputs
    )
  }
`;

export const loadCollaboratorsByRoleQuery = gql`
  query loadCollaboratorsByRole($projectID: UUID!, $roleID: UUID!) {
    loadCollaboratorsByRole(projectID: $projectID, roleID: $roleID) {
      id
      user {
        id
        name
        picture
        status
      }
    }
  }
`;

export const updatePermissionMutation = gql`
  mutation updatePermission($projectID: UUID!, $id: UUID!, $level: PermissionLevel!) {
    updatePermission(projectID: $projectID, permission: $id, level: $level) {
      id
      level
      resource
      actions {
        view
        add
        delete
        edit
      }
    }
  }
`;

export const updateRoleNameMutation = gql`
  mutation updateRoleName($projectID: UUID!, $roleID: UUID!, $name: String!) {
    setRoleName(projectID: $projectID, roleID: $roleID, name: $name)
  }
`;

export const updateRoleTradeMutation = gql`
  mutation updateRoleTrade($projectID: UUID!, $roleID: UUID!, $hasTrade: Boolean!) {
    setRoleHasTrade(projectID: $projectID, roleID: $roleID, hasTrade: $hasTrade)
  }
`;
