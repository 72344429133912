import {
  FALSE,
  MILESTONE,
  SORT_STATUS,
  TOTAL,
  UNIFORMAT_CATEGORIZATION_ID,
  VIEW_FILTER,
} from '../../constants';

import { TREND } from './DashboardCharts/DashboardChartsTrend/DashboardChartsTrendDetailToggle';

export const DASHBOARD_DEFAULTS = {
  [VIEW_FILTER]: {},
  costTrendTimeFrame: MILESTONE,
  detailLevel: TREND,
  trendUnit: TOTAL,
  estUnit: TOTAL,
  estimatesGroupBy: UNIFORMAT_CATEGORIZATION_ID,
  itemsGroupBy: SORT_STATUS,
  showTable: FALSE,
  integrations: [],
};
