import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';

export const COMMENT_HEIGHT = 20;

export default (theme: KomodoTheme) =>
  createStyles({
    hint: {
      background: theme.palette.backgroundWhite,
      color: theme.palette.primaryGrey,
      display: 'flex',
      flexDirection: 'column',
      zIndex: 999,
      position: 'absolute',
    },
    containsPreviewContent: {
      width: 300,
      '&:hover': { background: theme.palette.backgroundGrey, cursor: 'pointer' },
    },
  });
