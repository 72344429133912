import { useState } from 'react';

import Search from '@material-ui/icons/Search';

import {
  projectCompsAnalyticsEvent,
  projectCompsEventTypes,
} from '../../../../analytics/analyticsEventProperties';
import { CostTableColumnInputKey } from '../../../../generated/graphql';
import useSendAnalytics from '../../../../hooks/useSendAnalytics';
import { isEnumValue } from '../../../../utilities/types';
import { ButtonBar, Checkbox, ScrollContainer, TextInput } from '../../../scales';
import SearchText from '../../../Search/SearchText/SearchText';

import { TypeToggle, marshallColumnInputParam } from './ProjectCompsChartUtils';

export type LegendValue = {
  legendKey: string;
  name: string;
  color: string;
};

type Props = {
  columnToggleOptions: { label: string; value: string }[];
  filterKeys: string[];
  hover: string | null;
  isCategorized: boolean;
  onChangeColumn: (value: string) => void;
  onChangeType: (value: TypeToggle) => void;
  onFiltersChange: (ids: UUID[]) => void;
  values: LegendValue[];
  selectedColumnInputParam: string;
  selectedType: TypeToggle;
  setHover: (hover: string | null) => void;
};

export default function ProjectCompsChartSidebar(props: Props) {
  const sendAnalytics = useSendAnalytics();

  // Toggle + Labels
  const typeToggleOptions = [
    { label: 'Total', value: TypeToggle.TOTAL },
    { label: 'Categorized', value: TypeToggle.CATEGORIZED },
  ];

  // Filter
  const isAnyFiltered = props.filterKeys.length > 0;
  const isAllFiltered = props.filterKeys.length === props.values.length;
  const isIndeterminate = isAnyFiltered && !isAllFiltered;

  // Search Text
  const [searchText, setSearchText] = useState('');
  const filteredValues = props.values.filter(({ name }) =>
    name.toLowerCase().includes(searchText.toLowerCase())
  );
  const keyValues = props.isCategorized && searchText ? filteredValues : props.values;

  return (
    <div className="border-border flex h-full w-75 flex-shrink-0 flex-col gap-2 border-r bg-background-1">
      <div className="flex flex-col gap-2 px-4 pt-4">
        <ButtonBar
          isFullWidth
          label="Data type"
          onChange={(type) => {
            if (isEnumValue(TypeToggle, type)) {
              props.onChangeColumn(marshallColumnInputParam(CostTableColumnInputKey.TOTAL));
              props.onChangeType(type);
              sendAnalytics(
                projectCompsAnalyticsEvent(
                  projectCompsEventTypes.PROJECT_COMPS_CHARTS_CHANGE_TYPE,
                  {
                    unit: CostTableColumnInputKey.TOTAL,
                    type,
                  }
                )
              );
            }
          }}
          options={typeToggleOptions}
          value={props.selectedType}
        />
        <ButtonBar
          isFullWidth
          label="Display"
          onChange={(column: string) => {
            props.onChangeColumn(column);
            sendAnalytics(
              projectCompsAnalyticsEvent(projectCompsEventTypes.PROJECT_COMPS_CHARTS_CHANGE_UNIT, {
                unit: CostTableColumnInputKey.TOTAL,
                type: props.selectedType,
              })
            );
          }}
          options={props.columnToggleOptions}
          value={props.selectedColumnInputParam}
        />
      </div>
      <div className="flex flex-col overflow-y-auto">
        <strong className="px-4 type-body3">Key</strong>
        {props.isCategorized && (
          <div className="px-4 py-1">
            <TextInput
              aria-label="filter locations"
              label="Search categories"
              onChange={setSearchText}
              onClear={
                searchText
                  ? () => {
                      setSearchText('');
                    }
                  : undefined
              }
              placeholder="Find a category"
              startAdornment={<Search />}
              value={searchText}
            />
          </div>
        )}
        <div className="mx-4 flex border-b">
          {props.isCategorized && (
            <Checkbox
              aria-label="Filter all categories"
              data-cy="chart-key-checkbox-select-all"
              isIndeterminate={isIndeterminate}
              isSelected={isAnyFiltered}
              onChange={(isSelected) => {
                const selected = isSelected ? props.values.map(({ legendKey }) => legendKey) : [];
                props.onFiltersChange(selected);
              }}
            >
              <div className="type-label">{isAnyFiltered ? 'Select None' : 'Select All'}</div>
            </Checkbox>
          )}
        </div>
        <ScrollContainer direction="vertical">
          {keyValues.map(({ legendKey, name, color }) => {
            const isSelected = props.filterKeys.includes(legendKey);
            const title = isSelected ? `Remove filter ${name}` : `Filter on category ${name}`;
            const isHover = props.isCategorized && props.hover === legendKey;
            const label = (
              <Label
                color={color ?? undefined}
                isHover={isHover}
                searchText={searchText}
                text={name}
              />
            );
            return (
              <div
                key={legendKey}
                className="justify-left flex h-6 px-4"
                onFocus={() => props.setHover(legendKey)}
                onMouseLeave={() => props.setHover(null)}
                onMouseOver={() => props.setHover(legendKey)}
                title={title}
              >
                {props.isCategorized ? (
                  <Checkbox
                    aria-label={title}
                    data-cy="chart-key-checkbox"
                    isSelected={isSelected}
                    onChange={(isSelected) => {
                      const newFilters = props.filterKeys.slice();
                      if (isSelected) newFilters.push(legendKey);
                      else newFilters.splice(props.filterKeys.indexOf(legendKey), 1);
                      props.onFiltersChange(newFilters);
                      sendAnalytics(
                        projectCompsAnalyticsEvent(
                          projectCompsEventTypes.PROJECT_COMPS_CHARTS_FILTER,
                          {
                            filteredCategoryNumbers: newFilters,
                          }
                        )
                      );
                    }}
                  >
                    {label}
                  </Checkbox>
                ) : (
                  label
                )}
              </div>
            );
          })}
        </ScrollContainer>
      </div>
    </div>
  );
}

type LabelProps = {
  text: string;
  color?: string;
  isHover?: boolean;
  searchText: string;
};

const Label = (props: LabelProps) => (
  <div className="flex flex-grow items-center gap-1 type-label">
    <div className="h-4 w-1 bg-background-2" style={{ backgroundColor: props.color }} />
    <div className={`truncate ${props.isHover ? 'font-bold' : ''}`}>
      <SearchText searchTerm={props.searchText} text={props.text} />
    </div>
  </div>
);
