import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../../theme/komodo-mui-theme';

export const widthReportsSettings = 280;

export default (theme: KomodoTheme) =>
  createStyles({
    avatar: {
      backgroundColor: 'transparent',
      height: 32,
      width: 32,
    },
    disabled: {
      color: theme.palette.joinGrey400,
      margin: '0px 12px',
    },
    go: {
      minHeight: 28,
      minWidth: 70,
      padding: '0px 0px 0px 10px',
      marginLeft: 4,
    },
    goText: {
      ...theme.typography.body1,
      fontWeight: 400,
    },
    button: {
      margin: '0px 4px',
    },
    iconError: {
      color: theme.palette.red,
      fontSize: 24,
    },
    root: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
      flexGrow: 1,
      justifyContent: 'flex-end',
      pageBreakInside: 'avoid',
      position: 'relative',
      width: widthReportsSettings,
    },
  });
