import { Link } from 'react-router-dom';

import { RouteKeys } from '../../../routes/paths';
import { generateSharedPath } from '../../../utilities/routes/links';
import { pluralizeCountString } from '../../../utilities/string';
import { Chip, Table, TableComponents } from '../../scales';
import { ProjectIdea } from '../types';

type Props = { ideas: ProjectIdea[]; projectID: UUID };

export default function IdeasTable(props: Props) {
  return (
    <Table
      columnWidths={['2fr', '1fr', '1fr']}
      entries={props.ideas.map((idea) => {
        return [
          {
            key: `${idea.id}-link`,
            component: (
              <TableComponents.Cell className="w-full">
                <span>
                  <Link
                    className="type-link type-body1"
                    to={generateSharedPath(RouteKeys.PROJECT_IDEAS_IDEA, {
                      projectId: props.projectID,
                      ideaID: idea.id,
                    })}
                  >
                    {idea.name}
                  </Link>
                  &nbsp;&nbsp;({pluralizeCountString('alternate', idea.alternates.length)})
                </span>
              </TableComponents.Cell>
            ),
          },
          {
            key: `${idea.id}-uniformat`,
            component: idea.root.uniFormat ? (
              <TableComponents.Cell className="gap-2 py-1 type-body1">
                {idea.root.uniFormat.name}
                {idea.root.uniFormat.number && <Chip text={idea.root.uniFormat.number} />}
              </TableComponents.Cell>
            ) : null,
          },
          {
            key: `${idea.id}-masterformat`,
            component: idea.root.masterFormat ? (
              <TableComponents.Cell className="gap-2 py-1 type-body1">
                {idea.root.masterFormat.name}
                {idea.root.masterFormat.number && <Chip text={idea.root.masterFormat.number} />}
              </TableComponents.Cell>
            ) : null,
          },
        ];
      })}
      headerContent={[
        { key: 'idea', copy: 'Idea' },
        { key: 'masterformat', copy: 'MasterFormat' },
        { key: 'uniformat', copy: 'UniFormat' },
      ]}
    />
  );
}
