import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';
import { CHART_BORDER } from '../ExecutiveDashboardUtils';

const TeamStyles = (theme: KomodoTheme) =>
  createStyles({
    bottomChart: {
      paddingTop: 5,
    },
    chart: {
      display: 'flex',
      flexDirection: 'column',
    },
    chartDivider: {
      marginLeft: CHART_BORDER,
    },
    content: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      paddingBottom: CHART_BORDER / 2,
    },
    dueThisWeek: {
      border: `1px solid ${theme.palette.chartBlue}`,
    },
    empty: {
      display: 'flex',
      height: 160,
    },
    emptyLabel: {
      margin: 'auto',
      fontSize: 14,
      fontWeight: 400,
    },
    legendDivider: {
      marginRight: CHART_BORDER,
    },
    noDueDate: {
      borderColor: theme.palette.chartGrey,
    },
    pastDue: {
      border: `1px solid ${theme.palette.chartPink}`,
    },
    upcoming: {
      border: `1px solid ${theme.palette.chartLightGrey}`,
    },
    legend: {
      display: 'flex',
      padding: '4px 0px 12px 8px',
    },
    title: {
      fontsize: 12,
      fontWeight: 700,
    },
    toggle: {
      padding: 8,
      paddingBottom: 0,
      width: '100%',
    },
    topLabelContainer: {
      borderRadius: 0,
      borderWidth: 1.5,
      marginBottom: 4,
      display: 'flex',
      width: 110,
      minHeight: 22,
    },
    topLabel: {
      fontSize: 12,
      fontWeight: 400,
      margin: 'auto',
    },
  });

export default TeamStyles;
