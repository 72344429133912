import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';

const PanelSelectHeaderStyles = (theme: KomodoTheme) =>
  createStyles({
    componentHeader: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    link: {
      cursor: 'pointer',
      textDecoration: 'underline',
      fontFamily: theme.typography.fontFamily,
      fontSize: theme.typography.h4.fontSize,
    },
    linkContainer: {
      display: 'flex',
      flexDirection: 'row-reverse',
    },
    linkPadding: {
      padding: '4px 4px 0 0px',
      alignSelf: 'baseline',
    },
    marginRight: {
      marginRight: 4,
    },
  });

export default PanelSelectHeaderStyles;
