import { LinearProgress } from '@material-ui/core';

import { Button, Dialog, DialogContent } from '../../scales';
import { KeyDatesNudgeContent } from '../Content/KeyDatesNudgeContent';
import { useKeyDates } from '../hooks/useKeyDates';

import CreateButton from './CreateButton';

type Props = {
  keyDates: ReturnType<typeof useKeyDates>;
  onClose: () => void;
  isOpen: boolean;
  projectID: UUID | undefined;
};

export default function KeyDatesNudgeDialog(props: Props) {
  const onSubmit = () => {
    props.onClose();
    props.keyDates.onSubmit();
  };

  return (
    <Dialog
      footerRight={
        <div className="flex gap-2">
          <Button label="Cancel" onClick={() => props.onClose()} type="secondary" />
          <CreateButton activityCount={props.keyDates.activityCount} onSubmit={onSubmit} />
        </div>
      }
      isOpen={props.isOpen}
      onClose={props.onClose}
      title="Add Key Dates to Timeline"
    >
      {!props.projectID && <LinearProgress />}
      {props.projectID && (
        <DialogContent>
          <KeyDatesNudgeContent {...props.keyDates} />
        </DialogContent>
      )}
    </Dialog>
  );
}
