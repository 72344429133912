import { useEffect } from 'react';

import { useReactiveVar } from '@apollo/client';

import {
  itemSidebarMenuClose,
  setItemsListNewItemDialogOpen,
} from '../analytics/analyticsEventProperties';
import {
  itemEstimateDialogOpenVar,
  itemSidebarOpenVar,
  newItemDialogOpenVar,
} from '../api/apollo/reactiveVars';
import { isCtrlMeta, isPrintKeys } from '../components/Print/PrintUtils';

import useSendAnalytics from './useSendAnalytics';

export const useListKeyPressListener = (onPrint: () => void) => {
  const newItemDialogOpen = useReactiveVar(newItemDialogOpenVar);
  const itemEstimateDialogOpen = useReactiveVar(itemEstimateDialogOpenVar);
  const isItemSidebarOpen = useReactiveVar(itemSidebarOpenVar);
  const sendAnalytics = useSendAnalytics();

  useEffect(() => {
    const handleKey = (event: KeyboardEvent) => {
      if (event.repeat) return;
      if (isPrintKeys(event)) {
        event.stopImmediatePropagation();
        event.preventDefault();
        onPrint();
      }
      if (event.key === 'Escape') {
        if (newItemDialogOpen) {
          // close Dialog!
          newItemDialogOpenVar(false);
          sendAnalytics(setItemsListNewItemDialogOpen(false));
        } else if (!itemEstimateDialogOpen) {
          // close Sidebar!
          itemSidebarOpenVar(null);
          if (isItemSidebarOpen) sendAnalytics(itemSidebarMenuClose(isItemSidebarOpen));
        }
      }
      if (event.key === 'i' && isCtrlMeta(event) && !newItemDialogOpen) {
        newItemDialogOpenVar(true);
        sendAnalytics(setItemsListNewItemDialogOpen(true));
      }
    };
    window.addEventListener('keydown', handleKey);
    return () => {
      window.removeEventListener('keydown', handleKey);
    };
  }, [isItemSidebarOpen, itemEstimateDialogOpen, newItemDialogOpen, onPrint, sendAnalytics]);
};
