import { ReactNode } from 'react';

import { renderCostString } from '../../../CostReport/CostReportUtils';

import Cell from './Cell';

type Props = {
  'data-cy'?: string;
  cost: Cost | USCents;
  isBold?: boolean;
  isCompact?: boolean;
} & Omit<Parameters<typeof renderCostString>[0], 'cost'>;

export default function CostCell(props: Props) {
  const { isCompact: compact, cost, ...rest } = props;

  let content: ReactNode = renderCostString({ isWide: true, ...rest, cost });
  if (props.isBold) content = <strong>{content}</strong>;

  return (
    <Cell data-cy={props['data-cy']} isCompact={props.isCompact}>
      <div className={compact ? 'type-table-number' : 'type-number'}>{content}</div>
    </Cell>
  );
}
