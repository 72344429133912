import { loadCollaboratorsMultipleProjects } from '../components/Collaborators/hooks/queries';
import {
  LoadCollaboratorsMultipleProjectsQuery,
  LoadCollaboratorsMultipleProjectsQueryVariables,
} from '../generated/graphql';

import { QueryHookOptions, useQuery } from './useMountAwareQuery';

export const useCollaboratorsMultipleProjectsQuery = (
  projectIDs?: UUID[],
  options?: QueryHookOptions<
    LoadCollaboratorsMultipleProjectsQuery,
    LoadCollaboratorsMultipleProjectsQueryVariables
  >
) => {
  const result = useQuery<
    LoadCollaboratorsMultipleProjectsQuery,
    LoadCollaboratorsMultipleProjectsQueryVariables
  >(loadCollaboratorsMultipleProjects, {
    variables: { projectIDs },
    skip: !projectIDs,
    ...options,
  });

  const collaborators = result.data?.loadCollaboratorsMultipleProjects ?? [];

  return { ...result, collaborators };
};
