import {
  getShareResourceFields,
  gql,
  shareResourcesFields,
} from '../../../../api/graphqlFragments';

export const shareResourcesMutation = gql`
  mutation ShareResources(
    $resourceType: ResourceType!
    $resourceIDs: [UUID!]!
    $userIDs: [UUID!]!
    $accessLevels: [AccessLevel!]!
  ) {
    shareResources(
      input: {
        resourceType: $resourceType
        resourceIDs: $resourceIDs
        userIDs: $userIDs
        accessLevels: $accessLevels
      }
    ) {
      ...shareResourcesFields
    }
  }
  ${shareResourcesFields}
`;

export const getSharedResourcesQuery = gql`
  query getSharedResources($input: GetSharedResourcesInput!) {
    getSharedResources(input: $input) {
      resources {
        id
        type
        accessLevels
        users {
          id
          email
          isEmailVerified
          jobTitle
          name
          picture
          status
        }
      }
    }
  }
`;

export const getSharedResourceQuery = gql`
  query getSharedResource($resourceID: UUID!, $resourceType: ResourceType!) {
    getSharedResource(input: { resourceID: $resourceID, resourceType: $resourceType }) {
      ...getShareResourceFields
    }
  }
  ${getShareResourceFields}
`;
