import { useMutation } from '@apollo/client';

import { REFETCH_SCENARIO_SANDBOX } from '../../../../api/refetchSets';
import {
  ItemOverlay,
  UpdateScenarioItemOverlaysMutation,
  UpdateScenarioItemOverlaysMutationVariables,
} from '../../../../generated/graphql';
import { useCostMode } from '../../../../utilities/costMode';

import { updateScenarioItemOverlaysMutation } from './queries';

type Inputs = {
  scenarioID: UUID;
  milestoneID: UUID;
};

const useUpdateItemOverlaysMutation = ({ scenarioID, milestoneID }: Inputs) => {
  const [updateFn] = useMutation<
    UpdateScenarioItemOverlaysMutation,
    UpdateScenarioItemOverlaysMutationVariables
  >(updateScenarioItemOverlaysMutation, {
    refetchQueries: REFETCH_SCENARIO_SANDBOX,
  });
  const costMode = useCostMode();
  const updateItemOverlays = ({ itemOverlays }: { itemOverlays: ItemOverlay[] }) =>
    updateFn({
      variables: {
        itemOverlays,
        scenarioID,
        milestoneID,
        costMode,
      },
    });

  return [updateItemOverlays];
};

export default useUpdateItemOverlaysMutation;
