import { FC } from 'react';

import { Avatar } from '@material-ui/core';

import { UserWithPicture, getUserPicture } from '../../Collaborators/UserUtils';

type Props = {
  user?: UserWithPicture;
};

const ItemHistoryEventPath: FC<Props> = ({ user }) => (
  <div className="h-full">
    {user ? (
      <Avatar className="!h-6 !w-6" src={(user && getUserPicture(user)) || undefined} />
    ) : (
      <svg viewBox="0 0 24 24">
        <circle
          className="h-4 w-4 bg-background-primary fill-border-default"
          cx={12}
          cy={12}
          r={4}
        />
      </svg>
    )}
    <svg // The vertical line needs to scale depending on how much info this event contains
      height="calc(100% - 24px)" // for whatever reason 100% height always includes the 24px of the previous svg so we need to subtract that
      preserveAspectRatio="xMidYMin slice"
      viewBox="0 0 24 24"
      width="24"
    >
      <line
        className="stroke-border-default"
        vectorEffect="non-scaling-stroke" // don't change stroke width as svg changes size
        x1="50%"
        x2="50%"
        y1={user ? 2 : 0} // the user avatar is larger than the grey dot
        y2="100%"
      />
    </svg>
  </div>
);

export default ItemHistoryEventPath;
