import InputsSelectReferenceCategoryFilters, {
  ClearLink,
} from './InputsSelectReferenceCategoryFilters';

export const generateCategoryFiltersMenuItems = (
  categorizations: Categorization[],
  selectedCategorizationFilters: Category[],
  setSelectedCategorizationFilters: (value: Category[]) => void,
  toggleFilter: (value: Category) => void,
  isFixedMarkup: boolean,
  hasFilters: boolean
) => {
  return [
    <div key="header" className="flex h-9 items-center !justify-between !border-b px-3 type-body2">
      <div className="truncate">{`Filter categories${
        isFixedMarkup ? '' : ' for S1' // for percent markups, specify that filters only apply to direct costs
      }:`}</div>
      {hasFilters && (
        <ClearLink onClick={() => setSelectedCategorizationFilters([])} text="Clear" />
      )}
    </div>,
    categorizations.map((categorization) => (
      <InputsSelectReferenceCategoryFilters
        key={categorization.id}
        categorization={categorization}
        selectedFilters={selectedCategorizationFilters}
        setSelectedFilters={setSelectedCategorizationFilters}
        toggleCategory={toggleFilter}
      />
    )),
  ];
};
