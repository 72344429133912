import { useUpdate } from 'react-use';

import { useProjectID } from '../../../utilities/routes/params';

export function getIsHiddenProject(hiddenProjectIDsJSONString: string | null, projectID?: UUID) {
  let hiddenProjectIDs = [];
  if (hiddenProjectIDsJSONString) {
    try {
      hiddenProjectIDs = JSON.parse(hiddenProjectIDsJSONString);
    } catch (error) {
      // it's fine, ignore it...
    }
  }
  const isHiddenProject = Array.isArray(hiddenProjectIDs) && hiddenProjectIDs.includes(projectID);
  return { hiddenProjectIDs, isHiddenProject };
}

export function getIsHiddenSession(
  sessionStartAt: string | null,
  hideSessionStartAt: string | null
) {
  if (!sessionStartAt || !hideSessionStartAt) return false;
  return hideSessionStartAt === sessionStartAt;
}

export function getProjectIDsToStore(hiddenProjectIDs: UUID[], projectID?: UUID) {
  const projectIDSet = new Set(hiddenProjectIDs);
  if (projectID) {
    projectIDSet.add(projectID);
  }
  return Array.from(projectIDSet);
}

function getIsHiddenGlobal(localStorageGlobalKey: string) {
  const storedGlobalJSON = localStorage.getItem(localStorageGlobalKey);
  if (storedGlobalJSON) {
    try {
      const storedGlobal = JSON.parse(storedGlobalJSON);
      if (storedGlobal[localStorageGlobalKey] === true) {
        return true;
      }
    } catch (error) {
      // it's fine, ignore it...
    }
  }
  return false;
}

type Props = {
  localStorageKey: string;
  onDismiss?: () => void;
  onRemindMeLater?: () => void;
};

export function useBannerVisibility(props: Props) {
  const projectID = useProjectID();
  const forceUpdate = useUpdate(); // used to force localStore refresh

  const sessionStartAt = localStorage.getItem('user_session_start_at');
  const localStorageSessionKey = `${props.localStorageKey}_session`;
  const localStorageProjectKey = `${props.localStorageKey}_project`;
  const localStorageGlobalKey = `${props.localStorageKey}_global`;
  const hideSessionStartAt = localStorage.getItem(localStorageSessionKey);
  const hiddenProjectIDsJSONString = localStorage.getItem(localStorageProjectKey);

  const { hiddenProjectIDs, isHiddenProject } = getIsHiddenProject(
    hiddenProjectIDsJSONString,
    projectID
  );
  const isHiddenSession = getIsHiddenSession(sessionStartAt, hideSessionStartAt);
  const isHiddenGlobal = getIsHiddenGlobal(localStorageGlobalKey);

  const onRemindMeLater = () => {
    localStorage.setItem(localStorageSessionKey, sessionStartAt ?? '');
    forceUpdate();
    props.onRemindMeLater?.();
  };

  const onDismiss = () => {
    if (projectID) {
      localStorage.setItem(
        localStorageProjectKey,
        JSON.stringify(getProjectIDsToStore(hiddenProjectIDs, projectID))
      );
    } else {
      localStorage.setItem(
        localStorageGlobalKey,
        JSON.stringify({
          [localStorageGlobalKey]: true,
        })
      );
    }
    forceUpdate();
    props.onDismiss?.();
  };

  const isHidden = isHiddenSession || (projectID ? isHiddenProject : isHiddenGlobal);

  return { isHidden, onRemindMeLater, onDismiss };
}
