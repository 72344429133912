import { ScaleLinear, ScalePoint, ScaleTime } from 'd3-scale';

import { Label } from '../../../dashboard/DashboardCharts/DashboardChartsTrend/DashboardChartsTrendUtils';

type Props = {
  data: Label;
  x: ScaleTime<number, number> | ScalePoint<Date>;
  y: ScaleLinear<number, number>;
};

export default function CostLabel(props: Props) {
  return (
    <g style={{ filter: 'url("#blur")' }}>
      <text
        className="type-label"
        dominantBaseline={props.data.anchorY}
        fill={props.data.style?.fill}
        textAnchor={props.data.anchorX}
        x={props.x(props.data.x as Date)}
        y={props.y(props.data.y ?? 0)}
      >
        {props.data.label}
      </text>
    </g>
  );
}
