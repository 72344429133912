import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';
import { CHART_AREA_HEIGHT } from '../ChartsUtils';

export const CHART_LEFT_MARGIN = 90;

export const printArea = {
  paddingTop: 4,
};

export default (theme: KomodoTheme) =>
  createStyles({
    accepted: {
      color: theme.palette.accepted,
      '& rect': {
        fill: `${theme.palette.accepted} !important`,
      },
    },
    axisLabel: {
      fill: 'black',
      fontWeight: 400,
      '& text': {
        fill: 'black',
        fontSize: 16,
      },
    },
    bold: {
      fontWeight: 600,
    },
    budget: {
      color: theme.palette.budget,
      '& rect': {
        fill: `rgba(255,255,255,0) !important`,
        mixBlendMode: 'multiply',
        stroke: `${theme.palette.budget} !important`,
        strokeWidth: 8,
      },
    },
    category: {
      fontSize: '16px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    change: {
      '& rect': {
        fill: 'none !important',
        stroke: `${theme.palette.primaryGrey} !important`,
        strokeWidth: 0.4,
      },
    },
    description: {
      flexGrow: 1,
    },
    divider: {
      color: theme.palette.joinPrimary,
      marginBottom: 2,
      marginTop: 2,
    },
    estimate: {
      '& rect': {
        fill: `rgba(255,255,255,0) !important`,
        stroke: `${theme.palette.primaryGrey} !important`,
        strokeDasharray: '2 4',
        strokeWidth: 2,
      },
    },
    graph: {
      fontFamily: theme.typography.fontFamily,
      marginTop: 20,
      width: '100%',
    },
    header: {
      display: 'flex',
    },
    hidePrint: {
      '@media print': {
        display: 'none',
      },
    },
    hint: {
      background: theme.palette.backgroundWhite,
      '@media print': {
        display: 'none',
      },
      color: theme.palette.primaryGrey,
      display: 'flex',
      flexDirection: 'column',
      width: 200,
    },
    icon: {
      fill: theme.palette.primaryBlue,
      fontSize: 30,
    },
    inline: {
      display: 'flex',
    },
    menuLabel: {
      alignSelf: 'baseline',
      marginBottom: 4,
      minWidth: '54px',
      '@media print': {
        flex: '1 1 auto',
        textAlign: 'right',
      },
    },
    leftPadding: {
      paddingLeft: 24,
    },
    minHeight: {
      minHeight: CHART_AREA_HEIGHT,
    },
    number: {
      ...theme.typography.number,
      fontWeight: 'unset',
    },
    remaining: {
      color: theme.palette.shadedGrey,
      '& rect': {
        fill: `${theme.palette.primaryGrey} !important`,
        fillOpacity: 0.1,
        stroke: `${theme.palette.primaryGrey} !important`,
        strokeWidth: 0.4,
      },
    },
    root: {
      paddingTop: theme.spacing.unit * 3,
      '@media print': {
        width: '100%',
      },
    },
    printArea,
    legendContainer: {
      display: 'flex',
      flexDirection: 'row',
      '& .rv-discrete-color-legend-item__title': {
        ...theme.typography.label,
      },
    },
    running: {
      '& rect': {
        fill: `${theme.palette.runningTotal} !important`,
        fillOpacity: 0,
        stroke: `${theme.palette.runningTotal} !important`,
        strokeWidth: 4,
      },
    },
    spacer: {
      flexGrow: 1,
    },
    topBar: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
      '@media print': {
        borderBottom: theme.border.line,
      },
      [theme.breakpoints.down('sm')]: {
        flexWrap: 'wrap',
      },
    },
    printChartTitle: {
      fontFamily: theme.typography.fontFamily,
      fontSize: 16,
      fontWeight: 500,
      lineHeight: '144%',
      letterSpacing: '0.0119em',
      width: '100%',
    },
    printChartSetting: {
      fontFamily: theme.typography.fontFamily,
      fontSize: 12,
      paddingTop: 8,
      letterSpacing: '0.0119em',
    },
    rowName: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
  });
