import { FC, useState } from 'react';

import { Button } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';

import { MentionableTextInput } from '../../../generated/graphql';
import { withStyles } from '../../../theme/komodo-mui-theme';
import { isTextValid } from '../../../utilities/string';
import MentionableTextArea, {
  DEFAULT_PLACEHOLDER_TEXT,
} from '../MentionableTextArea/MentionableTextArea';

import styles from './CommentEntryStyles';

type CommentEntryInterfaceProps = {
  classes: Classes<typeof styles>;
  key?: number;
  userPicture?: string;
  isReportComment?: boolean;
  comment: MentionableTextInput;
  setComment: (result: MentionableTextInput) => void;
  submitComment: () => void;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  autoFocus?: boolean;
  isEditing?: boolean;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  resetCommentAfterSubmitting?: boolean;
  isPrivate?: boolean;
  sharedUsersIDs?: UUID[];
};

const CommentEntryInterface: FC<CommentEntryInterfaceProps> = ({
  classes,
  setComment,
  submitComment,
  comment,
  key,
  userPicture,
  isReportComment = false,
  autoFocus = false,
  isEditing = false,
  resetCommentAfterSubmitting = true,
  isPrivate,
  sharedUsersIDs,
}) => {
  const [focused, setFocused] = useState(autoFocus);

  let buttonLabel = '';
  if (isReportComment) {
    buttonLabel = isEditing ? 'Save' : 'Add';
  } else {
    buttonLabel = 'Add Comment';
  }
  return (
    <div className={classes.row}>
      {!isReportComment && <Avatar className={classes.avatarSimplified} src={userPicture} />}
      <div className={classes.grow}>
        <div className={classes.commentContainer} data-cy="item-details-add-comment">
          <MentionableTextArea
            key={key}
            autoFocus={autoFocus}
            comment={comment}
            isPrivate={isPrivate}
            isReportComment={isReportComment}
            placeholderText={isReportComment ? DEFAULT_PLACEHOLDER_TEXT : undefined}
            resetCommentAfterSubmitting={resetCommentAfterSubmitting}
            setComment={setComment}
            setFocused={setFocused}
            sharedUsersIDs={sharedUsersIDs}
            submitComment={submitComment}
          />
        </div>
        {(focused || comment.contents.length > 0) && (
          <div className={classes.toolbar}>
            <Typography style={isReportComment ? { fontSize: 11 } : undefined} variant="body2">
              <span className={classes.key}>Enter</span>
              {' to submit, '}
              <span className={classes.key}>Shift</span>
              {' + '}
              <span className={classes.key}>Enter</span>
              {' to start a new line.'}
            </Typography>
            <Button
              className={classes.button}
              data-cy="button-SaveComment"
              disabled={!isTextValid(comment.contents)}
              onClick={(event) => {
                submitComment();
                event.preventDefault();
              }}
              style={isReportComment ? { minWidth: 0, fontSize: 12 } : undefined}
            >
              {buttonLabel}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default withStyles(styles)(CommentEntryInterface);
