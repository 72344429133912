import { FC } from 'react';
import { useLocation } from 'react-router-dom';

import { CompanyAdminView } from '../../../analytics/analyticsEventProperties';
import {
  LOCALSTORAGE_COMPANY_MEMBER_PROJECTS_SORT,
  LOCALSTORAGE_COMPANY_USERS_SORT,
} from '../../../constants';
import { getMemberUserIdFromUrl } from '../../../utilities/url';
import { useCompanyQuery } from '../../login/Signup/SignupPages/CreateCompanyHooks';
import {
  useCompanyProjectRolesQuery,
  useCompanyRolesQuery,
  useCompanyUserDetailsQuery,
} from '../CompanyHooks';
import {
  filterCompanyUsers,
  getFilteredUserCount,
  useCompanyRoutesData,
  useSort,
} from '../CompanyTabUtils';
import { useCompanyUsersQuery } from '../useCompanyUsersQuery';

import CompanyTabMembersProfile from './CompanyTabMembersProfile';

const CompanyTabMembersProfileData: FC = () => {
  useLocation();
  const { companyID, filterManager, searchManager } = useCompanyRoutesData();
  const { filterStatus, setFilterStatus } = filterManager;

  const memberID = getMemberUserIdFromUrl();
  const sortManager = useSort(LOCALSTORAGE_COMPANY_USERS_SORT, CompanyAdminView.MEMBER_SIDEBAR);
  const projectSortManager = useSort(LOCALSTORAGE_COMPANY_MEMBER_PROJECTS_SORT);

  const companyUserDetails = useCompanyUserDetailsQuery(
    memberID,
    projectSortManager.sortState as UserProjectSortBy
  ).data?.companyUserDetails;

  const { data: { company } = { company: undefined } } = useCompanyQuery(companyID);
  const { data: { companyProjectRoles } = { companyProjectRoles: [] } } =
    useCompanyProjectRolesQuery(companyID);
  const { data: { companyRoles } = { roles: undefined }, loading: companyRolesLoading } =
    useCompanyRolesQuery(companyID);
  const { data: { companyUsers } = { companyUsers: [] }, loading: companyUsersLoading } =
    useCompanyUsersQuery({
      variables: { companyID, sortBy: sortManager.sortState as CompanyUserSortBy },
      skip: !companyID,
    });

  const filteredCompanyUsers = filterCompanyUsers(companyUsers, filterStatus);

  if (companyRolesLoading || !companyID || !company) return null;

  const counts = getFilteredUserCount(companyUsers);
  const userCounts = [counts.all, counts.active, counts.deactivated, counts.pending];
  const countTotalMembers = companyUsers?.length ?? 0;

  return (
    <div>
      <CompanyTabMembersProfile
        key={companyUserDetails?.user.id}
        company={company}
        companyProjectRoles={companyProjectRoles}
        companyRoles={companyRoles ?? []}
        companyUserDetails={companyUserDetails}
        companyUsers={filteredCompanyUsers}
        companyUsersLoading={companyUsersLoading}
        countTotalMembers={countTotalMembers}
        filterStatus={filterStatus}
        projectSortManager={projectSortManager}
        searchManager={searchManager}
        setFilterStatus={setFilterStatus}
        sortManager={sortManager}
        userCounts={userCounts}
      />
    </div>
  );
};

export default CompanyTabMembersProfileData;
