import { createStyles } from '@material-ui/core';

const AddEmployeeStyles = () =>
  createStyles({
    icon: {
      marginLeft: 4,
    },
  });

export default AddEmployeeStyles;
