import {
  commonCompanyUserFields,
  commonUserFields,
  gql,
  insightsTriggerFields,
} from '../../api/graphqlFragments';
import { commonCompanyFields, listCompanyUserFieldsQuery } from '../login/hooks/queries';

export const userProjectFields = gql`
  fragment userProjectFields on UserProject {
    id
    name
    status
    type
    location
    collaboratorID
    roleID
    roles {
      id
      name
    }
  }
`;

export const updateCompanyMutation = gql`
  mutation updateCompany($companyID: UUID!, $name: String, $type: String, $assetID: UUID) {
    updateCompany(companyID: $companyID, name: $name, type: $type, assetID: $assetID) {
      ...commonCompanyFields
    }
  }
  ${commonCompanyFields}
`;

export const updateCompanyUserCompanyRoleMutation = gql`
  mutation updateCompanyUserCompanyRole($userID: UUID!, $roleID: UUID!) {
    updateCompanyUserCompanyRole(userID: $userID, roleID: $roleID) {
      ...commonCompanyUserFields
    }
  }
  ${commonCompanyUserFields}
`;

export const updateCompanyUserNameMutation = gql`
  mutation updateCompanyUserName($userID: UUID!, $name: String!) {
    updateCompanyUserName(userID: $userID, name: $name) {
      id
      name
      phone
      picture
      email
      jobTitle
    }
  }
`;

export const updateCompanyUserJobTitleMutation = gql`
  mutation updateCompanyUserJobTitle($userID: UUID!, $jobTitle: String!) {
    updateCompanyUserJobTitle(userID: $userID, jobTitle: $jobTitle) {
      id
      name
      phone
      picture
      email
      jobTitle
    }
  }
`;

export const updateCompanyUserPictureMutation = gql`
  mutation updateCompanyUserPicture($userID: UUID!, $picture: String!) {
    updateCompanyUserPicture(userID: $userID, picture: $picture) {
      ...commonUserFields
      status
    }
  }
  ${commonUserFields}
`;

export const updateCompanyUserStatusMutation = gql`
  mutation updateCompanyUserStatus($userID: UUID!, $status: UserStatus!) {
    updateCompanyUserStatus(userID: $userID, status: $status) {
      id
      name
      phone
      picture
      email
      jobTitle
      status
    }
  }
`;

export const companyUserDetailsQuery = gql`
  query companyUserDetails($userID: UUID!, $sortBy: UserProjectSortBy) {
    companyUserDetails(userID: $userID, sortBy: $sortBy) {
      user {
        ...commonUserFields
        activeAt
        isEmailVerified
        status
      }
      role {
        id
        name
        companyID
      }
      companyProjects {
        ...userProjectFields
      }
      outsideProjects {
        ...userProjectFields
      }
    }
  }
  ${commonUserFields}
  ${userProjectFields}
`;

export const companyCollaboratorDetailsQuery = gql`
  query companyCollaboratorDetails($userID: UUID!, $companyID: UUID!, $sortBy: UserProjectSortBy) {
    companyCollaboratorDetails(userID: $userID, companyID: $companyID, sortBy: $sortBy) {
      user {
        ...commonUserFields
        activeAt
        isEmailVerified
        status
      }
      role {
        id
        name
        companyID
      }
      companyProjects {
        ...userProjectFields
      }
      outsideProjects {
        ...userProjectFields
      }
    }
  }
  ${commonUserFields}
  ${userProjectFields}
`;

// get company project stats
export const getCompanyProjectStatsQuery = gql`
  query companyProjectStats(
    $companyID: UUID!
    $filters: ProjectsFilterBy!
    $sortBy: ProjectsSortBy!
  ) {
    companyProjectStats(companyID: $companyID, filters: $filters, sortBy: $sortBy) {
      id
      name
      type
      projectType {
        id
        name
      }
      location
      createdAt
      status
      estimate
      runningTotal
      budget
      createdBy {
        company {
          id
        }
        user {
          id
          name
        }
      }
      projectLead {
        id
      }
      milestoneCount
      itemCount
      teamCount
      orgNodes {
        id
        name
      }
    }
  }
`;

export const companyRolesQuery = gql`
  query companyRoles($companyID: UUID!) {
    companyRoles(companyID: $companyID) {
      id
      name
    }
  }
`;

export const companiesQuery = gql`
  query companies {
    companies {
      id
      name
    }
  }
`;

export const addCompanyEmployeeMutation = gql`
  mutation addCompanyEmployee($input: CompanyEmployeeInput!) {
    addCompanyEmployee(input: $input) {
      ...commonCompanyUserFields
    }
  }
  ${commonCompanyUserFields}
`;

export const companyProjectRolesQuery = gql`
  query companyProjectRoles($companyID: UUID!) {
    companyProjectRoles(companyID: $companyID) {
      id
      name
      roles {
        id
        name
      }
    }
  }
`;

export const currentCompanyProjectRoleQuery = gql`
  query CurrentCompanyProjectRole($projectID: UUID!) {
    currentCompanyProjectRole(projectID: $projectID)
  }
`;

export const updateCompanyProjectTypeMutation = gql`
  mutation UpdateCompanyProjectType($projectID: UUID!, $typeID: UUID!) {
    updateCompanyProjectType(projectID: $projectID, typeID: $typeID) {
      id
      type
      projectType {
        id
        name
      }
    }
  }
`;

export const updateBulkCompanyProjectTypeMutation = gql`
  mutation UpdateBulkCompanyProjectType($projectIDs: [UUID!]!, $typeID: UUID!) {
    updateBulkCompanyProjectType(projectIDs: $projectIDs, typeID: $typeID) {
      id
      type
      projectType {
        id
        name
      }
    }
  }
`;

export const updateProjectTypeMutation = gql`
  mutation UpdateProjectType($typeID: UUID!, $name: String!, $parentID: UUID) {
    updateProjectType(typeID: $typeID, name: $name, parentID: $parentID) {
      type {
        id
        name
        parentID
        hidden
      }
    }
  }
`;

export const toggleProjectTypeVisibilityMutation = gql`
  mutation ToggleProjectTypeVisibility($typeID: UUID!, $hide: Boolean) {
    toggleProjectTypeVisibility(typeID: $typeID, hide: $hide) {
      type {
        id
        name
        parentID
        hidden
      }
    }
  }
`;

export const deleteProjectTypeMutation = gql`
  mutation DeleteProjectType($typeID: UUID!) {
    deleteProjectType(typeID: $typeID)
  }
`;

export const getCompanyUsersQuery = gql`
  query companyUsers($companyID: UUID!, $filters: CompanyUserFilter, $sortBy: CompanyUserSortBy) {
    companyUsers(companyID: $companyID, filters: $filters, sortBy: $sortBy) {
      ...commonCompanyUserFields
    }
  }
  ${commonCompanyUserFields}
`;

export const getCompanyCollaboratorsQuery = gql`
  query companyCollaborators(
    $companyID: UUID!
    $filters: CompanyCollaboratorFilter
    $userSortBy: CompanyUserSortBy
  ) {
    companyCollaborators(companyID: $companyID, filters: $filters, userSortBy: $userSortBy) {
      companyUser {
        ...listCompanyUserFieldsQuery
      }
    }
  }
  ${listCompanyUserFieldsQuery}
`;

export const getAlertsQuery = gql`
  query GetAlerts($companyID: UUID!) {
    getAlerts(companyID: $companyID) {
      id
      isActive
      createdAt
      createdBy {
        id
        name
      }
      updatedAt
      updatedBy {
        id
        name
      }
      triggers {
        ...insightsTriggerFields
      }
    }
  }
  ${insightsTriggerFields}
`;

export const createAlertMutation = gql`
  mutation CreateAlert($input: CreateAlertInput!) {
    createAlert(input: $input) {
      alert {
        id
        isActive
        triggers {
          ...insightsTriggerFields
        }
      }
    }
  }
  ${insightsTriggerFields}
`;

export const deprecateAlertMutation = gql`
  mutation DeprecateAlert($input: DeprecateAlertInput!) {
    deprecateAlert(input: $input) {
      alert {
        id
      }
    }
  }
`;

export const toggleAlertMutation = gql`
  mutation ToggleAlert($input: ToggleAlertInput!) {
    toggleAlert(input: $input) {
      alert {
        id
        isActive
      }
    }
  }
`;

export const updateTriggerMutation = gql`
  mutation UpdateTrigger($input: UpdateTriggerInput!) {
    updateTrigger(input: $input) {
      trigger {
        ...insightsTriggerFields
      }
    }
  }
  ${insightsTriggerFields}
`;
