import { FC, useContext, useState } from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ListItem,
  Typography,
} from '@material-ui/core';
import { Assignment, DeleteOutline, DragHandle, Edit, LayersOutlined } from '@material-ui/icons';

import {
  CategorizationEvent,
  categorizationEvent,
} from '../../../analytics/analyticsEventProperties';
import { CategorizationDialogType, TermKey } from '../../../api/gqlEnums';
import useSendAnalytics from '../../../hooks/useSendAnalytics';
import { withStyles } from '../../../theme/komodo-mui-theme';
import { removeYear } from '../../../utilities/string';
import { getProjectIdFromUrl } from '../../../utilities/url';
import Tag from '../../ItemsList/Tag';
import NormalTooltip from '../../NormalTooltip/NormalTooltip';
import { ProjectTermStore } from '../../ProjectDisplaySettings/TerminologyProvider';
import { Switch } from '../../scales';
import IconMenu from '../../Select/SelectMenu/IconMenu';
import { MenuOption } from '../../Select/SelectMenu/SelectOption';
import CategorizationsListDialogs from '../CategorizationsListDialogs/CategorizationsListDialogs';

import styles from './CategorizationsListItemStyles';
import useSetDisabledProjectCategorization from './useSetDisabledProjectCategorization';

type CategorizationsListItemProps = {
  classes: Classes<typeof styles>;
  canEdit: boolean;
  categorization: CategorizationMetadata;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  disabled: boolean;
  projectId: UUID;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  dragging: boolean;
  onDragStart: React.MouseEventHandler<HTMLElement>;
  onDragEnter: React.MouseEventHandler<HTMLElement>;
  onDragEnd: React.MouseEventHandler<HTMLElement>;
};

const CategorizationsListItem: FC<CategorizationsListItemProps> = ({
  classes,
  canEdit,
  categorization,
  disabled,
  projectId,
  dragging,
  onDragStart,
  onDragEnter,
  onDragEnd,
}) => {
  const projectID = getProjectIdFromUrl();
  const sendAnalytics = useSendAnalytics();

  // Data
  const t = useContext(ProjectTermStore);
  const [openDialog, setOpenDialog] = useState(false);
  const setDisabledProjectCategorization = useSetDisabledProjectCategorization(
    projectId,
    categorization
  );

  // Settings
  const { builtin, name, levels } = categorization;
  const isMultilevel = levels > 1;

  const categorizationName = removeYear(name, builtin);
  // check if category is built in or user has access to edit
  const isNotGreyedOut = !disabled;
  const [categorizationDialogType, setCategorizationDialogType] =
    useState<CategorizationDialogType>(CategorizationDialogType.NONE);
  const toggleLabel = disabled ? 'Disabled' : 'Enabled';

  const dialog = () => (
    <Dialog open={!!openDialog}>
      <DialogTitle>Disabling {categorizationName}?</DialogTitle>
      <DialogContent className={classes.content}>
        <Typography>
          You just disabled a built-in categorization on your project settings. By disabling
          built-in {categorizationName}, the project will not be able to use it to:
          <ul className={classes.ul}>
            <li>Filter</li>
            <li>Group</li>
            <li>Categorize Items</li>
            <li>Categorize Milestone {t.titleCase(TermKey.ESTIMATE)}</li>
            <li>Categorize Milestone {t.titleCase(TermKey.TARGET)}</li>
            <li>Give teammates access by trade</li>
          </ul>
          You can change this at any time by re-enabling it here.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          data-cy="close-confirm-dialog"
          onClick={() => {
            setOpenDialog(false);
          }}
          variant="contained"
        >
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );

  const viewCallback = (isMultilevel: boolean) => {
    return isMultilevel
      ? setCategorizationDialogType(CategorizationDialogType.MULTILEVEL_VIEW)
      : setCategorizationDialogType(CategorizationDialogType.VIEW);
  };

  const editCallback = (isMultilevel: boolean) => {
    return isMultilevel
      ? setCategorizationDialogType(CategorizationDialogType.MULTILEVEL_EDIT)
      : setCategorizationDialogType(CategorizationDialogType.EDIT);
  };

  const viewOption = {
    callback: () => {
      viewCallback(isMultilevel);
      sendAnalytics(
        categorizationEvent(CategorizationEvent.VIEW_CTA, {
          projectID,
          categorizationID: categorization.id,
        })
      );
    },
    name: 'View',
    cy: 'option-view',
    icon: <Assignment />,
  };

  const editOption = {
    callback: () => {
      editCallback(isMultilevel);
      sendAnalytics(
        categorizationEvent(CategorizationEvent.EDIT_CTA, {
          projectID,
          categorizationID: categorization.id,
        })
      );
    },
    name: 'Edit',
    cy: 'option-Edit',
    icon: <Edit />,
  };

  const deleteOption = {
    callback: () => {
      setCategorizationDialogType(CategorizationDialogType.REMOVE);
      sendAnalytics(
        categorizationEvent(CategorizationEvent.DELETE_CTA, {
          projectID,
          categorizationID: categorization.id,
        })
      );
    },
    name: 'Delete',
    cy: 'option-Delete',
    icon: <DeleteOutline />,
    color: 'red',
  };

  const iconMenuArray: MenuOption[] = [];

  const isStandard = !!categorization.createdFrom;

  if (isStandard) {
    iconMenuArray.push(viewOption);
    iconMenuArray.push(deleteOption);
  } else if (canEdit) {
    iconMenuArray.push(editOption);
    iconMenuArray.push(deleteOption);
  } else iconMenuArray.push(viewOption);

  const iconMenu = <IconMenu cy="CategorizationsListItem-menu" options={iconMenuArray} />;

  const listItemStyle = ` ${classes.listItem} !py-2
  ${canEdit ? classes.gridTemplate : classes.gridTemplateNoEdit}  
  ${isNotGreyedOut ? '' : classes.disabled} 
  ${dragging ? classes.listItemDragging : ''} 
  ${classes.gap}`;

  return (
    <ListItem
      key={categorization.id}
      className={listItemStyle}
      data-cy="categorization-list-entry"
      onMouseEnter={onDragEnter}
      onMouseUp={onDragEnd}
    >
      {openDialog && dialog()}
      {canEdit && (
        <div
          className={dragging ? classes.dragging : classes.dragHandle}
          data-cy="drag-handle-container"
          onMouseDown={onDragStart}
        >
          <DragHandle />
        </div>
      )}

      <div className={classes.nameContainer} data-cy="CategorizationsListItem-name">
        <NormalTooltip title={categorization.name}>
          <Typography className={classes.name}>{categorizationName}</Typography>
        </NormalTooltip>
        {!!builtin && (
          <Tag
            name="Built-in"
            tooltipText="This categorization is managed by Join and cannot be edited. If you want to set up your own company version, contact a company admin. If you don’t use or need them, you can disable them."
          />
        )}
        {!!categorization.createdFrom && (
          <Tag
            name="Standard"
            onMouseEnter={() => {
              sendAnalytics(categorizationEvent(CategorizationEvent.STANDARD_TOOLTIP));
            }}
            tooltipText="This categorization is standardized across all company projects. If you need to change any details here, contact a company admin."
          />
        )}
      </div>
      <div className={classes.flex}>
        {isMultilevel && (
          <NormalTooltip title="Multi-level categorization">
            <LayersOutlined
              onMouseEnter={() => {
                sendAnalytics(categorizationEvent(CategorizationEvent.MULTILEVEL_TOOLTIP));
              }}
            />
          </NormalTooltip>
        )}
        <NormalTooltip title={categorization.description}>
          <Typography className={classes.description}>{categorization.description}</Typography>
        </NormalTooltip>
      </div>
      <div className="flex items-center justify-end gap-2">
        {builtin && canEdit && (
          <Switch
            checked={!disabled}
            data-cy="toggleDisable_categorization"
            isDisabled={!canEdit}
            label={toggleLabel}
            onChange={() => {
              setDisabledProjectCategorization(!disabled);
              if (!disabled) {
                setOpenDialog(true);
                sendAnalytics(
                  categorizationEvent(CategorizationEvent.BUILTINS_DISABLE, {
                    projectID,
                    categorizationID: categorization.id,
                  })
                );
              } else {
                sendAnalytics(
                  categorizationEvent(CategorizationEvent.BUILTINS_ENABLE, {
                    projectID,
                    categorizationID: categorization.id,
                  })
                );
              }
            }}
          />
        )}
        {iconMenu}
      </div>
      {categorizationDialogType !== CategorizationDialogType.NONE && (
        <CategorizationsListDialogs
          categorization={categorization}
          projectId={projectId}
          setType={setCategorizationDialogType}
          type={categorizationDialogType}
        />
      )}
    </ListItem>
  );
};

export default withStyles(styles)(CategorizationsListItem);
