const StatusTooltipContent = (
  <div>
    <span>ACTIVE: User has access to Join and the projects they are permissioned for.</span>
    <br />
    <span>PENDING: User has been invited to Join but has not signed up.</span>
    <br />
    <span>DEACTIVATED: User has no access to Join. Login is blocked.</span>
  </div>
);

export default StatusTooltipContent;
