import { MutationHookOptions, useMutation } from '@apollo/client';

import {
  UpsertProjectCompanyRoleMutation,
  UpsertProjectCompanyRoleMutationVariables,
} from '../../../../generated/graphql';

import { upsertProjectCompanyRole } from './queries';

export default function useUpsertProjectCompanyRoleMutation(
  options?: MutationHookOptions<
    UpsertProjectCompanyRoleMutation,
    UpsertProjectCompanyRoleMutationVariables
  >
) {
  return useMutation<UpsertProjectCompanyRoleMutation, UpsertProjectCompanyRoleMutationVariables>(
    upsertProjectCompanyRole,
    options
  );
}
