import { useQuery } from '@apollo/client';

import { gql } from '../api/graphqlFragments';
import {
  ItemsIntegrationObjectsCountsQuery,
  ItemsIntegrationObjectsCountsQueryVariables,
} from '../generated/graphql';

export const itemsIntegrationObjectsCountsQuery = gql`
  query ItemsIntegrationObjectsCounts($itemIDs: [UUID!]!) {
    itemsIntegrationObjectsCounts(itemIDs: $itemIDs) {
      itemID
      count
    }
  }
`;

export default function useItemsIntegrationObjectsCountsQuery(
  variables: ItemsIntegrationObjectsCountsQueryVariables
) {
  return useQuery<ItemsIntegrationObjectsCountsQuery, ItemsIntegrationObjectsCountsQueryVariables>(
    itemsIntegrationObjectsCountsQuery,
    {
      variables: {
        ...variables,
      },
      skip: !variables.itemIDs || !variables.itemIDs.length,
    }
  );
}

export const getIntegrationObjectCountForItemID = (
  itemID: UUID,
  data?: ItemsIntegrationObjectsCountsQuery
) => {
  return data?.itemsIntegrationObjectsCounts.find((i) => i.itemID === itemID)?.count ?? 0;
};
