import { useState } from 'react';

import { assetCacheVar } from '../../../api/apollo/reactiveVars';
import { fetchDisplayUrl } from '../../../api/assets';
import { AssetQuery } from '../../../generated/graphql';

import useAssetQuery from './useAssetQuery';

export const getAssetDisplayCacheKey = (assetID: UUID | null | undefined) =>
  `AssetDisplay:${assetID}`;

export default function useAssetDisplayUrlQuery(
  assetID: UUID | null | undefined,
  onCompletedOuter?: (displayUrl: string | undefined) => void
) {
  const displayUrlCached = assetCacheVar().get(getAssetDisplayCacheKey(assetID));
  const [url, setUrl] = useState<string | undefined>(displayUrlCached);
  const [loading, setLoading] = useState<boolean>(!url && !!assetID);
  const onCompleted = async (data: AssetQuery) => {
    const displayUrl = await fetchDisplayUrl(data.asset as Asset);
    setUrl(displayUrl ?? undefined);
    if (displayUrl) assetCacheVar().set(getAssetDisplayCacheKey(assetID), displayUrl);
    onCompletedOuter?.(displayUrl ?? undefined);
    setLoading(false);
  };
  const assetQueryResult = useAssetQuery(assetID, onCompleted);
  return { ...assetQueryResult, url, urlLoading: loading };
}
