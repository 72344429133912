import { FC } from 'react';

import { useCompanyProjectTypesQuery } from '../../../../hooks/useCompanyProjectTypesQuery';
import { useCompanyRoutesData } from '../../CompanyTabUtils';

import ProjectTypes from './ProjectTypes';

const ProjectTypesData: FC = () => {
  const { companyID } = useCompanyRoutesData();
  const { data, loading } = useCompanyProjectTypesQuery(true, companyID);
  const projectTypes = data?.companyProjectTypes ?? [];

  if (loading) return null;

  return <ProjectTypes projectTypes={projectTypes} />;
};

export default ProjectTypesData;
