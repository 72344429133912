import { FC } from 'react';

import { DeleteOutline, Edit } from '@material-ui/icons';

import {
  MentionableTextInput,
  UserReportCommentViewParameters,
} from '../../../../../generated/graphql';
import { withStyles } from '../../../../../theme/komodo-mui-theme';
import { UserReportComment, useDeleteUserReportComment } from '../../../../ReportsTab/ReportHooks';
import IconMenu from '../../../../Select/SelectMenu/IconMenu';
import { SelectVariants } from '../../../../Select/SelectMenu/SelectStyles';

import { styles } from './NoteCellIconMenuStyles';

const NoteCellIconMenu: FC<{
  canDeleteUserReportComment: boolean;
  classes: Classes<typeof styles>;
  currentReportID: UUID | undefined;
  projectID: UUID;
  setIsAddingNote: (value: boolean) => void;
  setComment: (input: MentionableTextInput) => void;
  setNotesCellHovered: (value: boolean) => void;
  userComment: UserReportComment['comment'] | undefined;
  viewParameters?: UserReportCommentViewParameters;
}> = ({
  canDeleteUserReportComment,
  classes,
  currentReportID,
  projectID,
  setComment,
  setIsAddingNote,
  setNotesCellHovered,
  userComment,
  viewParameters,
}) => {
  const deleteComment = useDeleteUserReportComment();

  const menuOptions = [
    {
      name: 'Edit',
      callback: () => setIsAddingNote(true),
      icon: <Edit />,
    },
  ];
  if (canDeleteUserReportComment && currentReportID && viewParameters && userComment) {
    menuOptions.push({
      name: 'Delete',
      callback: () => {
        deleteComment(projectID, userComment.id, currentReportID, viewParameters);
        setComment({ contents: '', mentions: [] });
        setNotesCellHovered(false);
      },
      icon: <DeleteOutline />,
    });
  }

  return (
    <IconMenu
      className={classes.editButton}
      cy={`button-reportComment-${userComment?.text?.contents}`}
      options={menuOptions}
      variant={SelectVariants.COMPACT}
    />
  );
};

export default withStyles(styles)(NoteCellIconMenu);
