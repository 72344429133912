import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../theme/komodo-mui-theme';

export const FILTER_SELECT_MIN_WIDTH = 325;

export default (theme: KomodoTheme) =>
  createStyles({
    containerOuter: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      position: 'relative',
      zIndex: 201,
      fontFamily: theme.typography.fontFamily,
      paddingRight: 4,
      paddingLeft: 4,
    },
    filterSummaryLine: {
      display: 'inline',
      paddingRight: 14,
    },
    filterSummary: {
      display: 'flex',
      alignItems: 'flex-end',
      flexDirection: 'column',
    },
    menuOutline: {
      border: theme.border.line,
      '&:hover': {
        border: theme.border.lineBorderBlack,
      },
      '&:focus': {
        border: theme.border.lineBorderBlack,
      },
    },
  });
