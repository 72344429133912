import { SortManager } from '../../scales';
import CostSummary from '../common/CostSummary';
import ScenarioItemSummarySection from '../ScenarioItemSummarySection';
import { ScenarioItems } from '../types';

import { getApplyChanges } from './ApplyScenarioUtils';

type Props = {
  items: ScenarioItems;
  costReports: MilestoneCostReport | MilestonesCostTrendsBreakdownReport | null;
  sortManager: SortManager;
  milestoneName: string;
};

function ApplyScenarioDialogContent(props: Props) {
  const { updatedItems, newItems } = getApplyChanges(props.items);
  return (
    <div className="flex flex-col">
      <div className="pb-6 type-body1">
        You’re applying this scenario in the <strong>{props.milestoneName}</strong> milestone.
        Here’s what your project team will see happen:
      </div>
      <ScenarioItemSummarySection
        itemEntries={updatedItems}
        sortManager={props.sortManager}
        title="Updated Items"
      />
      <ScenarioItemSummarySection
        itemEntries={newItems}
        sortManager={props.sortManager}
        title="New Items"
      />
      <CostSummary costReports={props.costReports} />
    </div>
  );
}

export default ApplyScenarioDialogContent;
