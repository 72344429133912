import { useState } from 'react';

import { renderCostString } from '../../CostReport/CostReportUtils';
import { Button, Icon, Table } from '../../scales';
import { ProjectIdea } from '../types';
import { getRelevanceText } from '../utils/getRelevanceText';

type Props = { idea: ProjectIdea };

export default function IdeaRelevance(props: Props) {
  const relevance = props.idea.relevance;
  let relevanceText = '';
  if (relevance) relevanceText = getRelevanceText(props.idea);

  const [isCollapsed, setIsCollapsed] = useState(true);
  const toggleCollapse = () => setIsCollapsed((value) => !value);
  const toggleButtonText = isCollapsed
    ? 'Show Relevant Estimate Lines'
    : 'Hide Relevant Estimate Lines';

  if (!relevance) return null;
  return (
    <div className="flex flex-col gap-4 rounded-md bg-background-2 p-4">
      <div className="flex items-center justify-between gap-2">
        <div className="flex items-center gap-2">
          <Icon name="bulb_ray" />
          <div className="type-body2">{relevanceText}</div>
        </div>
        <Button label={toggleButtonText} onClick={toggleCollapse} type="secondary" />
      </div>
      {!isCollapsed && (
        <div className="max-h-[400px] overflow-y-auto">
          <Table
            columnWidths={['5fr', '1fr']}
            entries={relevance.lines.map((line) => [
              { component: <TextCell text={line.description} /> },
              { component: <CostCell cost={line.total} /> },
            ])}
            headerContent={[
              {
                component: (
                  <HeaderCell text={`Relevant Estimate Lines (${relevance.lines.length})`} />
                ),
                key: 'description',
              },
              { component: <HeaderCell text="Line Total" />, key: 'total', isRightAligned: true },
            ]}
            stickyFooterEntry={[
              { component: <TextCell text="Baseline Cost" /> },
              { component: <CostCell cost={relevance.cost / 100} /> },
            ]}
          />
        </div>
      )}
    </div>
  );
}

/** TODO: I've made these sub-components as a way around some of the flaws with
 * TableComponents. I would like to revisit how the Table is being implemented
 * as it feels like a case of "death by a thousand cuts".
 *
 * Note: Table cells themselves (in the listElement MUI class) add 8px of
 * horizontal padding, so my 8px padding is actually 16px.
 *  */

function HeaderCell(props: { text: string }) {
  return <div className="flex items-center px-2">{props.text}</div>;
}

function CostCell(props: { cost: number }) {
  return (
    <div className="flex min-h-[40px] w-full items-center justify-end px-2">
      {renderCostString({ isWide: true, cost: props.cost })}
    </div>
  );
}

function TextCell(props: { text: string }) {
  return <div className="flex min-h-[40px] w-full items-center px-2">{props.text}</div>;
}
