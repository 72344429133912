import { FC } from 'react';
import * as React from 'react';

import { S2 } from '../../../constants';
import { MarkupType } from '../../../generated/graphql';
import InputsSelectReference from '../../Inputs/InputsSelectReference/InputsSelectReference';
import {
  MarkupApplicationOptions,
  MarkupSelectors,
  markupAppliesToOptionsHaveChanged,
} from '../../Inputs/InputsSelectReference/utils';
import { useGetEstimateSourceItemInfos } from '../hooks/estimateQuery';
import { EditorPosition, GridController, GridType } from '../types';
import { getSelectableMarkups } from '../utilities/cell';

type ReferenceCellEditorProps = {
  error?: string;
  grid: GridController;
  row: number;
  markupReferenceCell: MarkupReferenceCell;
  selectedRow: number;
  updateCell: (newValue: MarkupReferenceCell) => void;
  toggleMarkupWithoutS2Reference: (row: number, withoutS2Reference: boolean) => void;
  position: EditorPosition;
  stopEditing: () => void;
};

// 200 is the size of the category menu without the source selector
// 280 (categoryMenuHeight + sourceMenuHeight) is the size with the source selector
// I don't want to hardcode this but useRef refuses to tell me the height of these components
const categoryMenuHeight = 200;
const sourceMenuHeight = 80;

const ReferenceCellEditor: FC<ReferenceCellEditorProps> = ({
  error,
  grid,
  row,
  markupReferenceCell,
  selectedRow,
  position,
  stopEditing,
  updateCell,
  toggleMarkupWithoutS2Reference,
}) => {
  // VARIABLES
  const {
    data: { lines }, // markup lines
    s1RefShouldIncludeS2 = false,
    linePrefix,
    projectID,
    estimateID,
    totalType,
  } = grid;
  const isFixedMarkup = markupReferenceCell.type === MarkupType.FIXED;

  const isMilestoneMarkupsGrid = grid.type === GridType.MARKUP_GRID;
  const { data: { estimateSourceItemInfos = [] } = {} } = useGetEstimateSourceItemInfos(
    projectID,
    estimateID
  );
  const sourceFilterOptions = isMilestoneMarkupsGrid ? estimateSourceItemInfos : [];

  const {
    references,
    filters: categoryFilters,
    sourceFilterIDs,
    costTypeFilters,
    shouldNotApplyCategoryFiltersToOtherMarkups,
    sourceFilterIDsWithoutS2Reference,
  } = markupReferenceCell;
  const applyFilterToMarkups = !shouldNotApplyCategoryFiltersToOtherMarkups;

  const selectableMarkups = getSelectableMarkups(grid.type, lines, linePrefix, selectedRow);

  const swallowKeys = (event: React.KeyboardEvent) => {
    if (
      event.key === 'ArrowUp' ||
      event.key === 'ArrowDown' ||
      event.key === 'Enter' ||
      event.key === 'Escape'
    ) {
      event.preventDefault();
      event.stopPropagation();
    }
  };

  const markupAppliesToOptions: MarkupApplicationOptions = {
    categories: categoryFilters,
    references,
    sources: sourceFilterIDs,
    costTypes: costTypeFilters,
    applyFilterToMarkups,
    sourceFilterIDsWithoutS2Reference: sourceFilterIDsWithoutS2Reference ?? [],
  };

  const hasSourceFilterMenuOptions = !!sourceFilterOptions && sourceFilterOptions.length > 0;

  let top = position.top + (position.height + 4);
  const screenHeight = document.documentElement.clientHeight;

  const subMenuHeight = hasSourceFilterMenuOptions
    ? categoryMenuHeight + sourceMenuHeight
    : categoryMenuHeight;
  const totalHeight = screenHeight - subMenuHeight;

  // if the dropdown is too close to the bottom of the screen then open upwards
  const openDown = top > totalHeight;
  if (openDown) top = position.top + 2;

  const isInherited = grid.type === GridType.INHERITED_GRID;

  return (
    <div onKeyDown={swallowKeys}>
      <InputsSelectReference
        canViewDirectCosts={grid.canViewDirectCosts}
        error={error}
        markupAppliesToOptions={markupAppliesToOptions}
        markupLine={`${linePrefix}${selectedRow + 1}`}
        markups={selectableMarkups}
        onClose={(selectors: MarkupSelectors) => {
          const { hasChanged, haveRefsChanged } = markupAppliesToOptionsHaveChanged(
            markupAppliesToOptions,
            selectors
          );
          if (hasChanged && !isInherited) {
            updateCell({
              type: markupReferenceCell.type,
              references: selectors.references,
              filters: selectors.categories,
              sourceFilterIDs: selectors.sources,
              costTypeFilters: selectors.costTypes,
              shouldNotApplyCategoryFiltersToOtherMarkups: !selectors.applyFilterToMarkups,
              sourceFilterIDsWithoutS2Reference: selectors.sourceFilterIDsWithoutS2Reference,
            });
          } else if (haveRefsChanged && isInherited) {
            toggleMarkupWithoutS2Reference(row, references.includes(S2));
          }
          stopEditing();
        }}
        open
        options={{
          isInherited,
          isFixedMarkup,
          isItem: grid.isItem,
          isOwnerCost: grid.type === GridType.OWNER_COST_GRID,
        }}
        s1RefShouldIncludeS2={s1RefShouldIncludeS2}
        sourceFilterMenuOptions={sourceFilterOptions}
        style={{
          top,
          left: position.left,
          position: 'fixed',
        }}
        totalType={totalType}
        transformOrigin={openDown ? 'bottom' : 'top'}
      />
    </div>
  );
};

export default ReferenceCellEditor;
