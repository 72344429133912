import { createStyles } from '@material-ui/core';

import { CHART_PADDING } from '../ExecutiveDashboardUtils';

const ExecutiveDashboardItemsOwnershipStyles = () =>
  createStyles({
    legend: {
      display: 'flex',
      flexDirection: 'column',
    },
    flexContainer: {
      display: 'flex',
      height: '100%',
      alignItems: 'start',
      paddingLeft: CHART_PADDING,
      paddingRight: CHART_PADDING,
      paddingTop: CHART_PADDING / 3,
    },
  });

export default ExecutiveDashboardItemsOwnershipStyles;
