import { ReactNode } from 'react';
import { NavLink } from 'react-router-dom';

type Props = {
  endAdornment?: ReactNode;
  label: string;
  onClick?: () => void;
  to: string;
};

export default function Link(props: Props) {
  return (
    <NavLink
      className={({ isActive }) =>
        [
          'flex shrink-0 justify-between gap-1 px-6 py-2 type-button focus-visible:outline active:bg-background-1 active:outline-none',
          isActive ? 'bg-background-1' : '',
        ].join(' ')
      }
      onClick={props.onClick}
      to={props.to}
    >
      <div className="line-clamp-3">{props.label}</div>
      {props.endAdornment}
    </NavLink>
  );
}
