import {
  CSSProperties,
  ComponentProps,
  FC,
  ReactNode,
  UIEventHandler,
  memo,
  useEffect,
  useRef,
  useState,
} from 'react';

import TableCellComponent from './TableCell';
import TableHeaderComponent from './TableHeader';
import TableLoader from './TableLoader';
import { SortManager, TableCell, TableHeader } from './types';

type TableProps = {
  columnWidths: string[];
  entries: TableCell[][];
  hasParentScroll?: boolean;
  headerContent?: TableHeader[];
  isCompact?: boolean;
  isLoading?: boolean;
  onNeedMoreData?: () => void;
  onScroll?: UIEventHandler<HTMLDivElement>;
  /**
   * Supports any explicit or implicit row-height supported by `grid-auto-rows` rule.
   *
   * @see https://developer.mozilla.org/docs/Web/CSS/grid-auto-rows
   */
  rowHeight?: CSSProperties['gridAutoRows'];
  sortManager?: SortManager;
  stickyFooterEntry?: TableCell[];
  stickyHeader?: ReactNode;
};

const Table: FC<TableProps> = ({
  columnWidths,
  isCompact: compact,
  entries,
  stickyFooterEntry,
  stickyHeader,
  headerContent,
  isLoading: loading = false,
  onNeedMoreData,
  onScroll,
  hasParentScroll: parentScroll,
  rowHeight = 'auto',
  sortManager,
}) => {
  const [stickyHeaderHeight, setStickyHeaderHeight] = useState(0);

  // Styles
  const columnWidthStyle = `${columnWidths.join(' ')}`;

  const rows = entries.map((row) => {
    return row.map((entry, j) => (
      <TableCellComponent
        key={`${headerContent?.[j]?.key}-${entry.key}`}
        entry={entry}
        hasBlackBorderBottom={entry.hasBlackBorderBottom}
        isCentered={headerContent?.[j]?.centered}
        isRightAligned={headerContent?.[j]?.isRightAligned}
      />
    ));
  });

  let loaderOffset: ComponentProps<typeof TableLoader>['offset'] = 'none';
  if (headerContent) {
    loaderOffset = compact ? 'compact' : 'full';
  }

  let gridTemplateRows = '';
  if (headerContent) gridTemplateRows += 'auto ';
  if (loading) gridTemplateRows += 'auto ';

  return (
    <div
      className={[
        'relative grid min-w-[120px] bg-background-primary',
        parentScroll ? '' : 'max-h-full overflow-auto print:overflow-visible',
      ].join(' ')}
      onScroll={(event) => {
        onScroll?.(event);
        const {
          currentTarget: { clientHeight, scrollHeight, scrollTop },
        } = event;
        if (scrollTop + clientHeight > scrollHeight - clientHeight) onNeedMoreData?.();
      }}
      style={{
        gridAutoRows: rowHeight,
        gridTemplateColumns: columnWidthStyle,
        gridTemplateRows,
      }}
    >
      {stickyHeader ? (
        <CustomHeader onResize={setStickyHeaderHeight}>{stickyHeader}</CustomHeader>
      ) : null}
      {headerContent?.map((header, index) => (
        <TableHeaderComponent
          key={`${index.toString()}-${header.copy}`}
          dataCy={header.key}
          header={header}
          isCompact={compact}
          sortManager={sortManager}
          top={stickyHeaderHeight}
        />
      ))}
      {loading && <TableLoader hasNegativeMargin={Boolean(entries.length)} offset={loaderOffset} />}
      {rows}
      {stickyFooterEntry &&
        stickyFooterEntry.map((entry, j) => (
          <TableCellComponent
            key={`${headerContent?.[j]?.key}-sticky-footer`}
            entry={entry}
            hasBlackBorderBottom={entry.hasBlackBorderBottom}
            isCentered={headerContent?.[j]?.centered}
            isRightAligned={headerContent?.[j]?.isRightAligned}
            isStickyBottom
          />
        ))}
    </div>
  );
};

function CustomHeader(props: { children: ReactNode; onResize: (height: number) => void }) {
  const ref = useRef<HTMLDivElement>(null);

  const { onResize } = props;
  useEffect(() => {
    const ro = new ResizeObserver((entries) => {
      onResize(entries[0].contentRect.height);
    });
    if (ref.current) ro.observe(ref.current);
    return () => ro.disconnect();
  }, [onResize]);

  return (
    <div ref={ref} className="sticky top-0 z-10 !col-[1_/_-1] w-full bg-background-primary">
      {props.children}
    </div>
  );
}

// TODO: consider memoizing in a different fashion, based on data?
export default memo(Table);
