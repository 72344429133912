import { FILTER_PROJECT_ORGANIZATION } from '../../../constants';
import { Org } from '../../../generated/graphql';
import { useTypesIdsToNames } from '../../../hooks/useTypesIdsToNames';
import {
  ProjectFilterManager,
  ProjectFilterSetSettings,
} from '../../ProjectsList/ProjectsListUtils';
import { Chip, Tooltip } from '../../scales';
import useMemoWrapper from '../../useMemoWrapper';
import { getFilterInsightsSummaryStrings } from '../PieCharts/HeaderDonutOrgChartUtils';

type Props = {
  filterManager: ProjectFilterManager;
  orgs: Org[];
  setSettings: ProjectFilterSetSettings;
};

export default function InsightsFilterSummaryText(props: Props) {
  const { clearFilterType, setFilters } = props.filterManager;
  const filterState = useTypesIdsToNames(props.filterManager.filterState, 'types');
  const filters = { ...props.filterManager, filterState };
  const { newFilterSummary, orgNodesDetails } = useMemoWrapper(
    getFilterInsightsSummaryStrings,
    filters,
    true,
    props.orgs
  );
  // Create an orgID: Org Name record for quick lookup
  const orgsIdToNameLookup: Record<string, string> = {};
  props.orgs.forEach((org) => {
    orgsIdToNameLookup[org.id] = org.name;
  });

  const handleRemove = (filterType: string) => {
    // Logic to remove selected orgs only (since each org has a seperate selector and chips we dont want to clear out all filtered orgs)
    if (filterType.startsWith('Organization_')) {
      const selectedNodes = orgNodesDetails[filterType];
      // Previously selected org nodes
      const orgNodeIDsSelected = props.filterManager.filterState.orgNodeIDs;
      // Only keep that org nodes that already exist in the filterState
      const filteredIDs = orgNodeIDsSelected.filter((id) => !selectedNodes.includes(id));
      if (filteredIDs) {
        setFilters(
          {
            type: FILTER_PROJECT_ORGANIZATION,
            value: '',
            values: filteredIDs,
          },
          props.setSettings
        );
      }
    } else {
      // General clear filter function for all filters other than Orgs
      clearFilterType(filterType, props.setSettings);
    }
  };

  // Limit visible filter chips
  const VISIBLE_CHIPS_COUNT = 4;
  const summaryEntries = Object.entries(newFilterSummary).filter(
    ([_, filterValue]) => filterValue && filterValue !== ''
  );

  const displayedEntries = summaryEntries.slice(0, VISIBLE_CHIPS_COUNT);
  const remainingEntries = summaryEntries.slice(VISIBLE_CHIPS_COUNT);
  const remainingCount = remainingEntries.length;

  return (
    <div className="flex flex-wrap justify-end gap-2">
      {displayedEntries.map(([filterType, filterValue]) => {
        // Check if `filterType` starts with "Organization_"
        if (filterType.startsWith('Organization_')) {
          // Extract ord ID UUID using regex
          const uuidMatch = filterType.match(/Organization_([a-f0-9-]+)/);
          const uuid = uuidMatch ? uuidMatch[1] : null;
          const orgName = uuid ? orgsIdToNameLookup[uuid] : null;

          return (
            <Tooltip
              key={filterType}
              content={<div className="type-label">{orgName && `Organization: ${orgName}`}</div>}
            >
              <Chip key={filterType} onRemove={() => handleRemove(filterType)} text={filterValue} />
            </Tooltip>
          );
        }
        // Default rendering for other filter types
        return (
          <Chip key={filterType} onRemove={() => handleRemove(filterType)} text={filterValue} />
        );
      })}
      {remainingCount > 0 && (
        <Tooltip
          content={
            <div className="flex flex-col gap-2">
              {remainingEntries.map(([filterType, filterValue]) => (
                <div key={filterType} className="type-table-text">
                  {filterValue}
                </div>
              ))}
            </div>
          }
        >
          <div className="cursor-pointer type-body1">+{remainingCount}</div>
        </Tooltip>
      )}
    </div>
  );
}
