import { useMutation } from '@apollo/client';

import { REFETCH_SCENARIO_SANDBOX } from '../../../api/refetchSets';
import {
  DeleteScenarioItemMutation,
  DeleteScenarioItemMutationVariables,
} from '../../../generated/graphql';

import { deleteScenarioItemMutation } from './queries';

const useDeleteScenarioItemMutation = () => {
  const [deleteFn] = useMutation<DeleteScenarioItemMutation, DeleteScenarioItemMutationVariables>(
    deleteScenarioItemMutation,
    {
      refetchQueries: REFETCH_SCENARIO_SANDBOX,
    }
  );

  const deleteScenarioItem = ({ itemID, milestoneID }: { itemID: UUID; milestoneID: UUID }) =>
    deleteFn({
      variables: {
        itemID,
        milestoneID,
      },
    });

  return [deleteScenarioItem];
};

export default useDeleteScenarioItemMutation;
