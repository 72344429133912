import { useMutation } from '@apollo/client';

import { importEstimatesManual } from '../../../../analytics/analyticsEventProperties';
import { EstimateType } from '../../../../api/gqlEnums';
import {
  CreateMilestoneDraftEstimateMutation,
  CreateMilestoneDraftEstimateMutationVariables,
  EstimateTotalType,
} from '../../../../generated/graphql';
import useSendAnalytics from '../../../../hooks/useSendAnalytics';
import { createMilestoneDraftEstimateMutation } from '../queries';

function useCreateMilestoneDraftEstimate() {
  const [createMilestoneDraftEstimateFunc] = useMutation<
    CreateMilestoneDraftEstimateMutation,
    CreateMilestoneDraftEstimateMutationVariables
  >(createMilestoneDraftEstimateMutation);

  const sendAnalytics = useSendAnalytics();
  const submitFunc = (
    projectID: UUID,
    milestoneID: UUID,
    estimateType: EstimateType,
    estimateTotalType: EstimateTotalType,
    onSuccess?: (estimateID: UUID) => void
  ) =>
    createMilestoneDraftEstimateFunc({
      variables: {
        projectID,
        milestoneID,
        estimateType,
        estimateTotalType,
      },
    }).then(({ data }) => {
      const milestone = data?.createMilestoneDraftEstimate;
      const estimateID =
        estimateType === EstimateType.ACTIVE_ESTIMATE
          ? milestone?.activeEstimateDraftID
          : milestone?.budgetDraftID;
      if (estimateID) {
        if (onSuccess) onSuccess(estimateID);
        sendAnalytics(importEstimatesManual(estimateType, estimateID));
      }
    });

  return [submitFunc];
}

export default useCreateMilestoneDraftEstimate;
