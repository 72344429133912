import { FC } from 'react';

import { Typography } from '@material-ui/core';

import { importEstimateHelpDialogVar } from '../../../api/apollo/reactiveVars';
import { UploadPlatform } from '../../../api/gqlEnums';
import { withStyles } from '../../../theme/komodo-mui-theme';

import { supportedEstimatePlatformEntries } from './EstimateAddUploadDragUtils';
import EstimateToolSelect from './EstimateToolSelect';
import styles from './EstimateToolSelectStyles';

type EstimateToolMenuProps = {
  selectedEstimatePlatform: UploadPlatform;
  setEstimatePlatform: (estimatePlatform: UploadPlatform) => void;
  classes: Classes<typeof styles>;
};

const EstimateToolMenu: FC<EstimateToolMenuProps> = ({
  selectedEstimatePlatform,
  setEstimatePlatform,
  classes,
}) => {
  return (
    <div className={classes.centerItems}>
      <Typography className={classes.menuText}>Import an Estimate from </Typography>
      <EstimateToolSelect
        entries={supportedEstimatePlatformEntries}
        selected={selectedEstimatePlatform}
        setSelectedEstimatePlatform={(selectedEstimatePlatform: UploadPlatform) => {
          setEstimatePlatform(selectedEstimatePlatform);
          if (selectedEstimatePlatform !== UploadPlatform.EMPTY_STATE) {
            importEstimateHelpDialogVar({ isOpen: false, platform: selectedEstimatePlatform });
          } else {
            importEstimateHelpDialogVar({ isOpen: false, platform: UploadPlatform.EXCEL });
          }
        }}
      />
    </div>
  );
};

export default withStyles(styles)(EstimateToolMenu);
