import useItemAttachmentsQuery from '../hooks/useItemAttachmentsQuery';
import useMilestoneAttachmentsQuery from '../hooks/useMilestoneAttachmentsQuery';
import { getNonNullEntityAttachments } from '../utils';

export const useItemAttachments = (itemID?: UUID) => {
  const { data, loading } = useItemAttachmentsQuery(itemID);
  const itemAssets = getNonNullEntityAttachments(data?.itemAttachments);
  return { itemAssets, loading };
};

export const useMilestoneAttachments = (milestoneID?: UUID) => {
  const { data, loading } = useMilestoneAttachmentsQuery(milestoneID);
  const milestoneAssets = getNonNullEntityAttachments(data?.milestoneAttachments);
  return { milestoneAssets, loading };
};
