import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';

export default (theme: KomodoTheme) =>
  createStyles({
    nameOption: {
      ...theme.typography.number,
      fontSize: '0.9rem',
      fontWeight: 300,
    },
  });
