import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../../theme/komodo-mui-theme';

const ProjectsListFilterSelectStyles = (theme: KomodoTheme) =>
  createStyles({
    activeContainer: {
      background: theme.palette.backgroundGrey,
    },
    borderTop: {
      borderTop: theme.border.line,
    },
    borderTopPlaceholder: {
      borderTop: `1px solid ${theme.palette.backgroundWhite}`,
    },
    fullWidth: {
      width: '100%',
      minWidth: 380,
      marginBottom: 2,
    },
    selectContainer: {
      paddingTop: 4,
    },
    selectMenu: {
      minHeight: 24,
      height: 20,
      '&:focus': {
        background: 'none',
      },
      display: 'flex',
      alignItems: 'center',
      backgroundColor: 'white',
    },
    outerContainer: {
      padding: 8,
      paddingBottom: 3,
    },
  });

export default ProjectsListFilterSelectStyles;
