import { FC } from 'react';

import { ArrowDropUp } from '@material-ui/icons';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';

import { withStyles } from '../../../theme/komodo-mui-theme';

import styles from './DropDownArrowStyles';

interface DropDownArrowProps {
  classes: Classes<typeof styles>;
  dataCy?: string;
  isOpen?: boolean;
  isRelative?: boolean;
  onClick: (isOpen: boolean) => void;
}

const DropDownArrow: FC<DropDownArrowProps> = ({
  classes,
  dataCy,
  isOpen,
  isRelative,
  onClick,
}) => (
  <div
    className={isRelative ? classes.dropDownRelative : classes.dropDownArrow}
    data-cy={dataCy}
    onClick={() => onClick(!isOpen)}
    onKeyPress={() => onClick(!isOpen)}
    role="button"
    tabIndex={-1}
  >
    {isOpen ? <ArrowDropUp /> : <ArrowDropDown />}
  </div>
);

export default withStyles(styles)(DropDownArrow);
