import { Close } from '@material-ui/icons';

import { CheckboxType } from '../../../api/gqlEnums';
import { FieldType } from '../../../api/gqlEnumsBe';
import {
  LUMP_SUM_DISPLAY,
  MARKUP_APPLIED_DISPLAY,
  MARKUP_NOT_APPLIED_DISPLAY,
  PERCENT_DISPLAY,
} from '../../../constants';
import { Scale } from '../../../enums';
import { MarkupDisplayType, MarkupType } from '../../../generated/graphql';
import { SCROLLBAR_MARGIN } from '../controller/sizing';
import { getMarkupDisplayTypeString } from '../editors/utils';
import { GridController, GridType, GridVariant } from '../types';
import {
  getShouldExcludeFromAllocationFromCell,
  isCategoryCell,
  isDisplayTypeCell,
  isInheritedMarkupCheckbox,
  isMarkupDisplayType,
  isMarkupReference,
  isMarkupType,
  isMarkupValue,
  isRegularCell,
  isRegularOrMarkupCell,
  isSourceCell,
} from '../utilities/cell';

import CategoryRenderer from './Category';
import CheckboxRenderer from './Checkbox';
import { CostRenderer, NumberRenderer } from './Number';
import PercentRenderer from './Percent';
import { StringRenderer } from './Plain';
import ReferenceRenderer from './Reference';
import DropdownRenderer from './Select';
import SourceRenderer from './Source';

export const renderCell = (
  grid: GridController,
  column: { type: string; placeholder?: string },
  columnIndex: number,
  row: number,
  cell: GridCellValue,
  error: string,
  select: () => void,
  linesReadOnly: boolean,
  isTotalCell: boolean,
  dataCy?: string
) => {
  const columnType = column.type;
  const isReadOnlyVariant = grid.variant === GridVariant.READ_ONLY;
  const isIncorporatedItemMarkup = grid.type === GridType.INCORPORATED_ITEM_MARKUP_GRID;
  const isIncorporatedItemDraw = grid.type === GridType.INCORPORATED_ITEM_DRAWS_GRID;
  const cellValue = grid.getCellValue(row, 0); // get value from cell in first column to see if it is markup display type
  const isIncorporatedItemMarkupDisplayTypeCell =
    isIncorporatedItemMarkup && isMarkupDisplayType(cellValue);
  let scrollbarWidth = grid.scrollBarWidth();
  if (scrollbarWidth) scrollbarWidth += SCROLLBAR_MARGIN;

  if (columnType === FieldType.CATEGORY && isCategoryCell(cell)) {
    return (
      <CategoryRenderer error={error} onSelect={select} readOnly={linesReadOnly} value={cell} />
    );
  }
  if (columnType === FieldType.CURRENCY && isRegularCell(cell)) {
    return (
      <CostRenderer
        error={error}
        isTotalCell={isTotalCell}
        scrollbarWidth={scrollbarWidth}
        value={cell}
      />
    );
  }
  if (columnType === FieldType.CURRENCY_9 && isRegularCell(cell)) {
    return (
      <CostRenderer
        error={error}
        isTotalCell={isTotalCell}
        scale={Scale.CURRENCY_9}
        scrollbarWidth={scrollbarWidth}
        value={cell}
      />
    );
  }
  if (
    (columnType === FieldType.NUMBER || columnType === FieldType.DECIMAL) &&
    isRegularOrMarkupCell(cell)
  ) {
    return <NumberRenderer error={error} value={cell} />;
  }

  if (columnType === FieldType.SELECT && isMarkupType(cell)) {
    const typeCell = {
      string: cell.type === MarkupType.FIXED ? LUMP_SUM_DISPLAY : PERCENT_DISPLAY,
      disabled: cell.disabled,
      formula: '',
    };
    return (
      <DropdownRenderer
        disabled={typeCell.disabled || false}
        linesReadOnly={linesReadOnly || grid.isInherited || isIncorporatedItemDraw}
        onSelect={select}
        value={typeCell.string}
      />
    );
  }

  if (columnType === FieldType.MARKUP_VALUE && isMarkupValue(cell)) {
    const { type, number, disabled, scale } = cell;
    if (type === MarkupType.FIXED) {
      return (
        <CostRenderer
          error={error}
          value={{ string: String(number), formula: '', formulaDisplay: [] }}
        />
      );
    }
    return <PercentRenderer disabled={disabled} error={error} scale={scale} value={number} />;
  }

  if (
    (columnType === FieldType.REFERENCE || columnType === FieldType.INHERITED_REFERENCE) &&
    isMarkupReference(cell)
  ) {
    return (
      <ReferenceRenderer
        dataCy={dataCy}
        error={error}
        isReadOnlyVariant={isReadOnlyVariant}
        linesReadOnly={!grid.isCellEditable({ row, column: columnIndex })}
        onSelect={select}
        value={cell}
      />
    );
  }

  if (columnType === FieldType.INHERITED_MARKUP_CHECKBOX && isInheritedMarkupCheckbox(cell)) {
    const { checked } = cell;
    const checkedCell = {
      string:
        checked === CheckboxType.CHECKED ? MARKUP_APPLIED_DISPLAY : MARKUP_NOT_APPLIED_DISPLAY,
      disabled: false,
      formula: '',
    };
    return (
      <DropdownRenderer
        disabled={checkedCell.disabled || false}
        linesReadOnly={linesReadOnly}
        onSelect={select}
        value={checkedCell.string}
      />
    );
  }

  if (columnType === FieldType.DELETE) {
    return !linesReadOnly ? (
      <button className="join-grid-delete-button column-cell-content" type="button">
        <Close className="join-grid-delete-icon" data-cy="deleteLineButton" />
      </button>
    ) : null;
  }

  if (columnType === FieldType.ALLOCATE) {
    // the first cell will indicate if this row is disabled
    const firstCellValue = grid.getCellData(row, 0)?.data?.value ?? {};
    const isDisabled = 'checked' in firstCellValue ? !firstCellValue?.checked : false;
    const isAllocated = !getShouldExcludeFromAllocationFromCell(cell);
    let variant: '' | 'disabledIcon' | 'icon' = '';
    if (isReadOnlyVariant) variant = isDisabled ? 'disabledIcon' : 'icon';
    return (
      <CheckboxRenderer
        checked={isAllocated}
        isIncorporatedItemDraw={isIncorporatedItemDraw}
        isIncorporatedItemMarkupDisplayTypeCell={isIncorporatedItemMarkupDisplayTypeCell}
        isInherited={grid.isInherited}
        linesReadOnly={linesReadOnly}
        row={row}
        toggleAllocatedMarkupLine={grid.toggleAllocatedMarkupLine}
        variant={variant}
      />
    );
  }

  if (columnType === FieldType.SOURCE && isSourceCell(cell)) {
    return <SourceRenderer value={cell} />;
  }

  if (columnType === FieldType.MARKUP_DISPLAY_TYPE && isDisplayTypeCell(cell)) {
    const displayType = cell.displayType || MarkupDisplayType.MARKUP;
    return (
      <DropdownRenderer
        disabled={cell.disabled || false}
        linesReadOnly={
          grid.type === GridType.INHERITED_GRID ||
          isIncorporatedItemMarkupDisplayTypeCell ||
          isIncorporatedItemDraw
        }
        onSelect={select}
        value={getMarkupDisplayTypeString(
          displayType,
          grid.isItem && grid.type !== GridType.INHERITED_GRID
        )}
      />
    );
  }

  const regularCell: RegularCell = isRegularCell(cell)
    ? cell
    : { string: '', formula: '', formulaDisplay: [] }; // we shouldn't ever have to use this default value
  return <StringRenderer error={error} placeholder={column.placeholder} value={regularCell} />;
};
