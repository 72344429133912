import { FC, useMemo, useState } from 'react';
import { useEffectOnce } from 'react-use';

import {
  Button,
  Dialog,
  DialogActions,
  Divider,
  IconButton,
  TextField,
  Typography,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';

import {
  SETTINGS_ADD_CUSTOM_BANNER_CANCEL,
  SETTINGS_ADD_CUSTOM_BANNER_CTA,
  SETTINGS_ADD_CUSTOM_BANNER_VIEW,
} from '../../../actions/actionTypes';
import { settingsPrintHeaderSelector } from '../../../analytics/analyticsEventProperties';
import { ToastType } from '../../../api/gqlEnums';
import joinAPI from '../../../api/joinAPI';
import useSendAnalytics from '../../../hooks/useSendAnalytics';
import { setToast } from '../../../hooks/useToastParametersLocalQuery';
import { withStyles } from '../../../theme/komodo-mui-theme';
import { isTextValid } from '../../../utilities/string';
import { useUploadImage } from '../../assets/hooks/AssetsMutationHook';
import { useCreateProjectBanner } from '../../ProjectProperties/PrintHeader/PrintHeaderHooks';
import DialogsStyles from '../DialogsStyles';

import UploadHeader from './UploadHeader';

type DialogsNewPrintHeaderProps = {
  classes: Classes<typeof DialogsStyles>;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  open: boolean;
  onClose: () => void;
  projectId: UUID;
  orgLogoIDs: string[];
  setPrintHeaderLocal: (asset: ActiveProjectBanner | null) => void;
};

const DialogsNewPrintHeader: FC<DialogsNewPrintHeaderProps> = ({
  classes,
  open,
  onClose,
  projectId,
  orgLogoIDs,
  setPrintHeaderLocal,
}) => {
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState<string | null>(null);
  const [asset, setAsset] = useState<Asset | null>(null);
  const isValidName = useMemo(() => isTextValid(name), [name]);

  const sendAnalytics = useSendAnalytics();
  useEffectOnce(() => {
    sendAnalytics(settingsPrintHeaderSelector(SETTINGS_ADD_CUSTOM_BANNER_VIEW));
  });

  const uploadImage = useUploadImage();
  const addFile = (file: File) => {
    setAsset(null);
    setLoading(true);
    const onSuccess = async (a: Asset) => {
      const assetWithBlobUrl = a;
      await joinAPI.requestAssetBlobURL(a.location, a.name);
      setAsset(assetWithBlobUrl);
      setLoading(false);
    };
    const onFailure = (message: string) => {
      setLoading(false);
      setToast({ message }, ToastType.DEFAULT, null);
    };
    uploadImage(file, projectId, onSuccess, onFailure);
  };

  const createProjectBanner = useCreateProjectBanner();
  const createBanner = () => {
    const onSuccessUpdate = (header: ActiveProjectBanner) => setPrintHeaderLocal(header);
    setLoading(true);
    if (asset && isValidName && name) {
      sendAnalytics(settingsPrintHeaderSelector(SETTINGS_ADD_CUSTOM_BANNER_CTA));
      createProjectBanner(asset.id, name, projectId, onSuccessUpdate);
    }
    onClose();
  };

  const header = (
    <div className={`${classes.titleContainer}`}>
      <Typography data-cy="input-itemOptionTitle" variant="title">
        Add Custom Banner
      </Typography>
      <IconButton onClick={onClose} title="Close dialog">
        <Close />
      </IconButton>
    </div>
  );

  const content = (
    <div className={` ${classes.content} ${classes.paddingBottom}`}>
      <TextField
        fullWidth
        InputLabelProps={{
          shrink: true,
          className: classes.dialogHelpText,
        }}
        InputProps={{ disableUnderline: true, className: classes.borderGrey }}
        label="Banner Name"
        onChange={(evt) => setName(evt.target.value)}
        placeholder="Give this image a name..."
        value={name || ''}
      />
      <UploadHeader addFile={addFile} asset={asset} loading={loading} orgLogoIDs={orgLogoIDs} />
      <Typography className={classes.helpText}>
        This banner will be centered at the top of the project dashboard, right justified at the top
        of every printed report, and featured in invitations to collaborate on this project.
      </Typography>
      <Typography className={classes.helpText}>
        To print well in reports, your banner should be at least 300px tall and be no wider than
        1:16. A SVG or a PNG with a transparent background works best.
      </Typography>
    </div>
  );

  const footer = (
    <DialogActions className={classes.action}>
      <Button
        color="secondary"
        data-cy="cancel-button"
        disabled={false}
        onClick={() => {
          onClose();
          sendAnalytics(settingsPrintHeaderSelector(SETTINGS_ADD_CUSTOM_BANNER_CANCEL));
        }}
      >
        Cancel
      </Button>
      <Button
        color="primary"
        data-cy="button-createAnother"
        disabled={!isValidName || !asset || loading}
        onClick={() => createBanner()}
        variant="contained"
      >
        Save and Close
      </Button>
    </DialogActions>
  );

  return (
    <Dialog
      classes={{
        paper: `${classes.dialogPaper} ${classes.dialogPaperSmall}`,
      }}
      data-cy="dialog-newPrintHeader"
      maxWidth={false}
      onClose={() => {
        onClose();
        sendAnalytics(settingsPrintHeaderSelector(SETTINGS_ADD_CUSTOM_BANNER_CANCEL));
      }}
      open={open}
    >
      {header}
      <Divider />
      {content}
      <Divider />
      {footer}
    </Dialog>
  );
};

const StyledDialogsNewPrintHeader = withStyles(DialogsStyles)(DialogsNewPrintHeader);

export default StyledDialogsNewPrintHeader;
