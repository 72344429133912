import { CostTableColumnInputKey } from '../../../../generated/graphql';
import { formatCost } from '../../../../utilities/currency';
import { getUnitLabel } from '../../ProjectCompsSetUtils';
import { getCostValue } from '../../ProjectCompUtils';

import { getDisplayStringFromColumn } from './ProjectCompsChartUtils';

type RowProps = {
  label: string;
  value: string | number;
};

const Row = (props: RowProps) => (
  <div className="flex flex-grow justify-between gap-4">
    <div className="type-body1">{props.label}</div>
    <div className="type-number">{props.value}</div>
  </div>
);

type Props = {
  name: string;
  unitAbbreviation: string;
  total: {
    totalValue: CostRange | CostScalar;
    quantityValue: CostRange | CostScalar;
    quantityMagnitude?: number;
  };
};

export default function TooltipChartBar(props: Props) {
  return (
    <div className="p-2" data-cy="tooltip">
      <div className="truncate type-heading3">{props.name}</div>
      <Row
        label={getDisplayStringFromColumn(CostTableColumnInputKey.TOTAL)}
        value={formatCost(getCostValue(props.total.totalValue))}
      />
      <Row
        label={getDisplayStringFromColumn(CostTableColumnInputKey.TOTAL_PER_METRIC)}
        value={`${formatCost(getCostValue(props.total.quantityValue))}/${props.unitAbbreviation}`}
      />
      <Row
        label={getDisplayStringFromColumn(CostTableColumnInputKey.METRIC)}
        value={getUnitLabel(props.unitAbbreviation, String(props.total.quantityMagnitude))}
      />
    </div>
  );
}
