import { ProgramFieldsFragment } from '../../generated/graphql';

type Props = {
  program?: ProgramFieldsFragment;
  setSelectedProjectIDs: (newIDs: UUID[]) => void;
};

export default function AllProjectsFilteredOutEmptyState({
  program,
  setSelectedProjectIDs,
}: Props) {
  return (
    <div className="flex h-full w-full items-center justify-center bg-background-1">
      <div className="type-body1">
        All Projects filtered out,
        <button
          className="pl-1 type-link"
          onClick={() => setSelectedProjectIDs(program?.projects.map((p) => p.id) ?? [])}
        >
          clear filters
        </button>
      </div>
    </div>
  );
}
