import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';

export default (theme: KomodoTheme) =>
  createStyles({
    menuitem: {
      ...theme.typography.webTableText,
      display: 'flex',
      gap: '8px',
      padding: '8px 16px',
    },
  });
