import { useMutation } from '@apollo/client';

import { REFETCH_PROJECT_ROLES } from '../../../../api/refetchSets';

import { createProjectRoleMutation } from './queries';

export const useCreateProjectRoleMutation = (projectID: UUID) => {
  const [createFn] = useMutation(createProjectRoleMutation);

  const createProjectRole = (roleID?: UUID, onSuccess?: (roleId: UUID) => void) => {
    createFn({
      variables: {
        projectID,
        roleID,
      },
      refetchQueries: REFETCH_PROJECT_ROLES,
    }).then((result) => {
      if (onSuccess) onSuccess(result.data.createProjectRole);
    });
  };

  return [createProjectRole];
};
