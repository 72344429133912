import { KomodoTheme } from '../../../../theme/komodo-mui-theme';

const ChartsCostTrendlineTooltipStyles = (theme: KomodoTheme) => ({
  hint: {
    color: 'black',
    fill: 'black',
    background: theme.palette.backgroundWhite,
    '@media print': {
      display: 'none',
    },
    padding: '5, 10, 10, 10',
  },
  lower: {
    display: 'flex',
  },
  trendIcon: {
    margin: '2px 5px 0 0',
  },
});

export default ChartsCostTrendlineTooltipStyles;
