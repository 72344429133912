import { ApolloError, useMutation } from '@apollo/client';

import {
  ReorderCategorizationMutation,
  ReorderCategorizationMutationVariables,
} from '../../generated/graphql';

import { reorderCategorizationMutation } from './queries';

export const useReorderCategorization = () => {
  const [mutationFunc] = useMutation<
    ReorderCategorizationMutation,
    ReorderCategorizationMutationVariables
  >(reorderCategorizationMutation);
  return (
    projectID: UUID,
    categorizationID: UUID,
    ordering: number,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
    onSuccess?: any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
    onFailure?: any
  ) =>
    mutationFunc({
      variables: {
        projectID,
        categorizationID,
        ordering,
      },
    }).then(
      (result) => {
        const ok = result?.data?.reorderCategorization;
        if (ok) {
          onSuccess?.();
        }
      },
      (error: ApolloError) => {
        onFailure?.(error);
      }
    );
};
