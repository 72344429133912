import { SourceSystem, UserSourcesQuery } from '../../../../generated/graphql';
import {
  SourceInfo,
  getSourceAuthURL,
  sourceSystemInfo,
} from '../../../DocumentMagic/Files/FilesDialog/FilesDialogUtils';
import { Button } from '../../../scales';

import { UserSource, getSourceSystemString } from './IntegrationsUtils';

type IntegrationsLinkAccountProps = {
  onEditAccount: (userSource: UserSource) => void;
  onSignOut: (userSource: UserSource) => void;
  sourceSystem: SourceSystem;
  userSources?: UserSourcesQuery['userSources'];
  setInitialEditAccountSourceSystem: (sourceSystem: SourceSystem) => void;
};

export default function IntegrationsLinkAccount(props: IntegrationsLinkAccountProps) {
  const matchingClient = props.userSources?.clients.find(
    (c) => c.sourceSystem === props.sourceSystem
  );

  if (!matchingClient) return <></>; // we cannot sign in without a valid client

  const matchingSource = props.userSources?.sources.find(
    (s) => s.sourceSystem === props.sourceSystem
  );

  const img = sourceSystemInfo[props.sourceSystem].img;
  const url = sourceSystemInfo[props.sourceSystem].url;

  const sourceInfo: SourceInfo = {
    sourceSystem: props.sourceSystem,
    clientID: matchingClient.clientID,
    url,
    img,
  };
  if (props.sourceSystem === SourceSystem.AUTODESK)
    sourceInfo.scope = 'data:read data:write account:read';

  return (
    <div className="flex items-center justify-between">
      <div className="flex gap-6">
        <div className="flex h-20 w-20 items-center justify-center rounded bg-background-1">
          <img alt={props.sourceSystem} className="h-12 w-12" src={img} />
        </div>
        <div className="flex flex-col justify-center gap-1">
          <div className="type-heading3">{getSourceSystemString(props.sourceSystem)}</div>
          <div className="type-body1">{matchingSource?.email}</div>
        </div>
      </div>
      {matchingSource ? (
        <div className="flex gap-2">
          <Button
            label="Edit"
            onClick={() => props.onEditAccount(matchingSource)}
            type="secondary"
          />
          <Button
            label="Sign out"
            onClick={() => props.onSignOut(matchingSource)}
            type="destructive"
          />
        </div>
      ) : (
        <Button
          label="Sign in"
          onClick={() => {
            window.open(getSourceAuthURL(sourceInfo), '_blank');
            props.setInitialEditAccountSourceSystem(sourceInfo.sourceSystem);
          }}
          type="secondary"
        />
      )}
    </div>
  );
}
