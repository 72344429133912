import { Navigate, useOutlet } from 'react-router-dom';

import { RouteKeys } from '../../routes/paths';
import { generateSharedPath } from '../../utilities/routes/links';
import { useProjectID } from '../../utilities/routes/params';

import TeamListHeader from './Header';
import NavBar from './Nav';

export default function TeamRoute() {
  const projectId = useProjectID();
  const outlet = useOutlet();
  if (!outlet)
    return <Navigate to={generateSharedPath(RouteKeys.PROJECT_TEAM_TEAMMATES, { projectId })} />;

  return (
    <div className="flex h-full flex-col overflow-hidden bg-background-primary">
      <TeamListHeader />
      <div className="flex flex-col overflow-auto">
        <NavBar />
        {outlet}
      </div>
    </div>
  );
}
