import { EstimateQuery, EstimateQueryVariables } from '../generated/graphql';
import { getCostMode } from '../utilities/costMode';

import { estimateQuery } from './queries';
import { useQuery } from './useMountAwareQuery';
import { MountPolicy } from './usePolicyOnFirstMount';

export const useEstimateQuery = (estimateID: UUID | undefined) =>
  useQuery<EstimateQuery, EstimateQueryVariables>(
    estimateQuery,
    {
      variables: { estimateID, costMode: getCostMode() },
      skip: !estimateID,
    },
    MountPolicy.SKIP_ON_MOUNT
  );
