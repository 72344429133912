import { FC } from 'react';

import NewProjectTemplate from './NewProjectTemplate';
import ProjectTemplatesList from './ProjectTemplatesList';

type ProjectTemplatesProps = {
  projectTemplates: ProjectTemplate[];
};

const ProjectTemplates: FC<ProjectTemplatesProps> = ({ projectTemplates }) => {
  const hasTemplates = projectTemplates && projectTemplates.length !== 0;
  return (
    <div className="flex flex-col overflow-y-auto overflow-x-hidden">
      <div className="sticky flex border-b p-4">
        <div className="flex w-full items-center justify-between">
          <div className="flex flex-col">
            <div className="flex type-heading2">Project Templates</div>
            <div className="flex text-type-muted type-body2">
              Create project templates to quick start new projects.
            </div>
          </div>
        </div>
        <div className="flex items-center">
          <NewProjectTemplate />
        </div>
      </div>
      {!hasTemplates && (
        <>
          <div className="flex border-b p-4">
            <div className="flex type-body2">There are currently no templates.</div>
          </div>
        </>
      )}
      {projectTemplates && <ProjectTemplatesList projectTemplates={projectTemplates} />}
    </div>
  );
};

export default ProjectTemplates;
