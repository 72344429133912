import { FC } from 'react';

import { ButtonBase, CircularProgress, Typography } from '@material-ui/core';

import { IMAGE, MODEL } from '../../../../constants';
import { withStyles } from '../../../../theme/komodo-mui-theme';
import { fromNow } from '../../../../utilities/dates';
import { prettyBytes } from '../../../../utilities/string';
import { getEntryIsPending } from '../../../DocumentMagic/Files/FilesTable/FilesTableUtils';
import NormalTooltip from '../../../NormalTooltip/NormalTooltip';
import AssetsActionMenu from '../../AssetsActionMenu/AssetsActionMenu';
import { getTruncatedAssetText, getUploadedByText } from '../../utils';

import AssetsCardStyles from './AssetsCardStyles';

type AssetsCardProps = {
  asset: EntityAttachment;
  blobUrl?: string | null;
  bookmark?: Bookmark;
  canDelete?: boolean;
  classes: Classes<typeof AssetsCardStyles>;
  detachAttachment?: (assetID: UUID) => void;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  disabled?: boolean;
  displayUrl?: string | null;
  downloadAsset?: (location: string, name: string, id: string) => void;
  onClick?: () => void;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  showMenu: boolean;
};

const AssetsCard: FC<AssetsCardProps> = ({
  asset,
  blobUrl,
  bookmark,
  canDelete = false,
  classes,
  detachAttachment,
  disabled = false,
  displayUrl,
  downloadAsset,
  onClick,
  showMenu,
}) => {
  const { id, name } = asset ?? {};
  const isPending = 'type' in asset && getEntryIsPending(asset);
  const isViewable = 'type' in asset && !!asset.type;
  const isImage = 'type' in asset && asset.type === IMAGE;
  // This makes an assumption that if an asset does not have a blobUrl or
  // displayUrl, it is deleted. This may not always be the case, but is used to
  // address an issue with displaying since-deleted assets in the item history.
  const isDeleted = isImage && !blobUrl && !displayUrl;

  return (
    <div className={classes.container}>
      <NormalTooltip
        title={getUploadedByText('uploadedBy' in asset ? asset.uploadedBy?.name : undefined)}
      >
        <ButtonBase
          className={`${classes.root} ${isViewable ? '' : classes.cursorDefault} ${classes.tile} ${
            isPending ? classes.pending : ''
          }`}
          data-cy="buttonBase-projectAssetIcon"
          disabled={disabled || isPending || isDeleted}
          onClick={onClick}
          style={
            bookmark || (isImage && blobUrl && !isPending)
              ? {
                  background: `url(${
                    bookmark ? displayUrl : blobUrl
                  }) center center / cover no-repeat`,
                }
              : undefined
          }
        >
          {isPending && <CircularProgress className={classes.icon} variant="indeterminate" />}
          <div className={classes.summaryTitleBookmark} data-cy="div-attachmentItemAsset">
            <Typography
              className={`break-words ${isDeleted ? '!text-type-muted' : ''}`}
              variant="body2"
            >
              {isDeleted ? 'Deleted Asset' : getTruncatedAssetText(asset, bookmark)}
            </Typography>
            {bookmark || (isImage && !isPending) ? null : (
              <div>
                <Typography className={classes.metadata} variant="caption">
                  {isPending
                    ? 'Uploading...'
                    : fromNow('lastUpdated' in asset ? asset.lastUpdated : null)}
                </Typography>
                <Typography className={classes.metadata} variant="caption">
                  {'size' in asset && asset.size && prettyBytes(asset.size)}
                </Typography>
              </div>
            )}
            {'type' in asset && asset.type === MODEL && displayUrl && !disabled && (
              <Typography className={classes.hasUrl} variant="body2">
                Open Model Viewer
              </Typography>
            )}
          </div>
        </ButtonBase>
      </NormalTooltip>
      {showMenu && !bookmark && (
        <div className={classes.menu}>
          <AssetsActionMenu
            canDelete={canDelete}
            detachAttachment={detachAttachment ? () => detachAttachment(id) : undefined}
            downloadAsset={
              downloadAsset
                ? () => downloadAsset('location' in asset ? asset.location : '', name, id)
                : undefined
            }
          />
        </div>
      )}
    </div>
  );
};

export default withStyles(AssetsCardStyles)(AssetsCard);
