import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';

const ExecutiveDashboardDataHeadingStyles = (theme: KomodoTheme) =>
  createStyles({
    label: {
      ...theme.typography.h3Label,
    },
    value: {
      ...theme.typography.number,
      lineHeight: 1.5,
      fontSize: 32,
      fontWeight: 700,
    },
  });

export default ExecutiveDashboardDataHeadingStyles;
