import { createStyles } from '@material-ui/core';

export const CHART_LEFT_MARGIN = 10;
export const VARIANCE_BAR_WIDTH = 0.65;
export const CHART_BAR_GAP = 5;
const rightTransform = CHART_LEFT_MARGIN + CHART_BAR_GAP;
const leftTransform = CHART_LEFT_MARGIN - CHART_BAR_GAP;

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
export default (theme: any) =>
  createStyles({
    accepted: {
      color: theme.palette.accepted,
      '& rect': {
        fill: `${theme.palette.accepted} !important`,
      },
    },
    axisLabel: {
      fill: 'black',
      fontWeight: 400,
      '& text': {
        fill: 'black',
        fontSize: 16,
      },
    },
    bold: {
      fontWeight: 600,
    },
    budget: {
      color: theme.palette.budget,
      '& rect': {
        fill: `rgba(255,255,255,0) !important`,
        mixBlendMode: 'multiply',
        stroke: `${theme.palette.budget} !important`,
        strokeWidth: 8,
      },
    },
    category: {
      fontSize: '16px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    divider: {
      color: theme.palette.joinPrimary,
      marginBottom: 2,
      marginTop: 2,
    },
    emptyMessageContainer: {
      display: 'flex',
      flexFlow: 'wrap',
      paddingLeft: 24,
      paddingBottom: 32,
    },
    emptyMessageText: {
      padding: 2,
    },
    graph: {
      fontFamily: theme.typography.fontFamily,
      paddingTop: 20,
      width: '100%',
    },
    hint: {
      background: theme.palette.backgroundWhite,
      '@media print': {
        display: 'none',
      },
      color: theme.palette.primaryGrey,
      display: 'flex',
      flexDirection: 'column',
      width: 240,
    },
    inline: {
      display: 'inline-flex',
    },
    overflow: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      paddingRight: 4,
    },
    milestone1: {
      color: theme.palette.shadedGrey,
      '& rect': {
        fill: `${theme.palette.shadedGrey} !important`,
        stroke: `${theme.palette.shadedGrey} !important`,
      },
      transform: `translate(${leftTransform}px, 10px)`,
    },
    milestone2: {
      color: theme.palette.budget,
      '& rect': {
        fill: `${theme.palette.budget} !important`,
        stroke: `${theme.palette.budget} !important`,
      },
      transform: `translate(${rightTransform}px, 10px)`,
    },
    milestoneContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    number: {
      ...theme.typography.number,
    },
    remaining: {
      color: theme.palette.shadedGrey,
      '& rect': {
        fill: `${theme.palette.primaryGrey} !important`,
        fillOpacity: 0.1,
        stroke: `${theme.palette.primaryGrey} !important`,
        strokeWidth: 0.4,
      },
    },
    root: {
      minHeight: 500,
      '@media print': {
        width: '100%',
      },
      pageBreakInside: 'avoid',
    },
    legendContainer: {
      flexDirection: 'row',
      '& .rv-discrete-color-legend-item__title': {
        ...theme.typography.label,
      },
    },
    spacer: {
      flexGrow: 1,
    },
    subtotal: {
      fill: 'black',
      fontSize: 12,
    },
  });
