import { HTMLAttributes, ReactNode, UIEventHandler, forwardRef, useCallback } from 'react';
// eslint-disable-next-line no-restricted-imports
import LazyLoadedScrollShadow from '../scales/ScrollContainer/LazyLoadedScrollShadow';

type Props = {
  children: ReactNode;
  className?: string;
  direction: 'horizontal' | 'vertical';
  hasNoShadow?: boolean;
  /** onNeedMoreData will be called when you reach the end of your scrollable content */
  onNeedMoreData?: () => void;
  onScroll?: UIEventHandler<HTMLDivElement>;
} & HTMLAttributes<HTMLDivElement>;

export default forwardRef<HTMLDivElement, Props>(function ScrollContainer(props, forwardedRef) {
  const { children, className, direction, onNeedMoreData, onScroll, ...divProps } = props;

  const handleScroll: UIEventHandler<HTMLDivElement> = useCallback(
    (event) => {
      onScroll?.(event);
      if (onNeedMoreData) {
        if (direction === 'vertical') {
          const {
            currentTarget: { clientHeight, scrollHeight, scrollTop },
          } = event;
          if (scrollTop + clientHeight > scrollHeight - clientHeight) onNeedMoreData();
        } else if (direction === 'horizontal') {
          const {
            currentTarget: { clientWidth, scrollLeft, scrollWidth },
          } = event;
          if (scrollLeft + clientWidth > scrollWidth - clientWidth) onNeedMoreData();
        }
      }
    },
    [direction, onNeedMoreData, onScroll]
  );

  return (
    <div
      {...divProps}
      ref={forwardedRef}
      className={`flex ${direction === 'vertical' ? 'flex-col' : ''} overflow-auto ${
        className ?? ''
      }`}
      onScroll={handleScroll}
    >
      {!props.hasNoShadow && (
        <LazyLoadedScrollShadow position={direction === 'horizontal' ? 'left' : 'top'} />
      )}
      {children}
      {!props.hasNoShadow && (
        <LazyLoadedScrollShadow position={direction === 'horizontal' ? 'right' : 'bottom'} />
      )}
    </div>
  );
});
