import { ReactNode, createContext, useContext, useRef } from 'react';
import { VisuallyHidden, useFocusRing, useRadio, useRadioGroup } from 'react-aria';
import { RadioGroupState, useRadioGroupState } from 'react-stately';

const RadioContext = createContext<RadioGroupState | null>(null);

type RadioGroupProps = {
  /** Required if you don't provide `children`. */
  'aria-label'?: string;
  /** Required if you don't provide `aria-label`. */
  children: ReactNode;
  'data-cy'?: string;
  defaultValue?: string;
  isDisabled?: boolean;
  isFullWidth?: boolean;
  label?: ReactNode;
  onChange?: Parameters<typeof useRadioGroup>[0]['onChange'];
  value?: string;
};

export function RadioGroup(props: RadioGroupProps) {
  const state = useRadioGroupState(props);

  const { labelProps, radioGroupProps } = useRadioGroup(props, state);

  return (
    <div
      {...radioGroupProps}
      className={`flex flex-col gap-2 text-type-primary ${props.isFullWidth ? 'w-full' : ''}`}
      data-cy={props['data-cy']}
    >
      {props.label && (
        <div {...labelProps} className="type-body1">
          {props.label}
        </div>
      )}
      <RadioContext.Provider value={state}>{props.children}</RadioContext.Provider>
    </div>
  );
}

type RadioProps = {
  children: ReactNode;
  isDisabled?: boolean;
  'data-cy'?: string;
  value: string;
};
export function Radio(props: RadioProps) {
  const state = useContext(RadioContext);
  if (!state) {
    throw Error(
      'Radio must be rendered as a child of a RadioContext.Provider such as a RadioGroup'
    );
  }

  const ref = useRef<HTMLInputElement>(null);
  const { inputProps, isSelected, isDisabled } = useRadio(props, state, ref);
  const { focusProps, isFocusVisible } = useFocusRing();

  let iconColorClassName = isSelected ? 'text-radio-selected' : 'text-radio-unselected';
  if (isFocusVisible) {
    iconColorClassName = 'text-radio-focused';
  }
  if (isDisabled) {
    iconColorClassName = 'text-radio-disabled';
  }

  return (
    <label
      // Why is `relative` here and /critical/?
      // https://github.com/adobe/react-spectrum/issues/5094#issuecomment-1724825584
      className={[
        'relative flex items-center gap-2',
        isDisabled ? 'cursor-not-allowed' : 'cursor-pointer',
      ].join(' ')}
      data-cy={props['data-cy']}
    >
      <VisuallyHidden>
        <input {...inputProps} {...focusProps} ref={ref} />
      </VisuallyHidden>
      <div aria-hidden="true" className={iconColorClassName}>
        {isSelected ? <RadioSelected /> : <RadioUnselected isDisabled={isDisabled} />}
      </div>
      <div
        className={`flex-grow type-body1 ${
          isDisabled ? 'text-type-inactive' : 'text-type-primary'
        }`}
      >
        {props.children}
      </div>
    </label>
  );
}

const RadioUnselected = (props: { isDisabled?: boolean }) => (
  <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.58 20 4 16.42 4 12C4 7.58 7.58 4 12 4C16.42 4 20 7.58 20 12C20 16.42 16.42 20 12 20Z"
      fill="currentColor"
    />
    {props.isDisabled && <circle className="fill-radio-disabled-bg" cx="12" cy="12" r="8" />}
  </svg>
);

const RadioSelected = () => (
  <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 7C9.24 7 7 9.24 7 12C7 14.76 9.24 17 12 17C14.76 17 17 14.76 17 12C17 9.24 14.76 7 12 7ZM12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.58 20 4 16.42 4 12C4 7.58 7.58 4 12 4C16.42 4 20 7.58 20 12C20 16.42 16.42 20 12 20Z"
      fill="currentColor"
    />
  </svg>
);
