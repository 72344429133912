import { DragTypes } from 'react-aria';

export const DROP_OP_MOVE = 'move';
export const DROP_OP_CANCEL = 'cancel';

export const getLimitDropOperation = (expectedType: string) => (types: DragTypes) => {
  if (types.has(expectedType)) {
    return DROP_OP_MOVE;
  }
  return DROP_OP_CANCEL;
};
