import { FC } from 'react';

import { getItemStatusLabel } from '../../../utilities/item-status';
import { getUnfilteredCostImpactByCostMode } from '../../../utilities/items';
import { renderCostString } from '../../CostReport/CostReportUtils';
import { getItemDescriptor } from '../../Items/ItemsListItem/ItemsListItemUtils';
import ItemsIcons from '../../ItemsList/ItemsIcons/ItemsIcons';
import { SMALL } from '../../ItemsList/ItemsIcons/ItemsIconsMap';

type PrintItemDetailsSubheaderProps = {
  shouldDisplayCosts: boolean;
  item: ItemDataQueryItem;
};

export const PrintItemDetailsSubheader: FC<PrintItemDetailsSubheaderProps> = ({
  shouldDisplayCosts,
  item,
}) => {
  const mainText = `${getItemDescriptor(item)} ${getItemStatusLabel(item.status)}`;
  const costDescriptionText = 'Estimated Cost Impact:';
  const costText = renderCostString({
    cost: getUnfilteredCostImpactByCostMode(item as ItemLike),
    isWide: true,
  });

  return (
    <>
      <div className="print-subheader">
        <div className="print-flex">
          <ItemsIcons status={item.status} variant={SMALL} />
          <div className="print-item-row-details-top-text">{mainText}</div>
        </div>
        {shouldDisplayCosts && (
          <div className="print-right-align">
            <span className="print-bold-text">{costDescriptionText}</span>
            <span className="print-subheader-cost-text">&nbsp;{costText}</span>
          </div>
        )}
      </div>
      <hr className="print-main-divider" />
    </>
  );
};
