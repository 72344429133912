import { ReactNode, cloneElement, isValidElement } from 'react';

import { GetApp, Launch } from '@material-ui/icons';

import UploaderLink from './UploaderLink';

export default function UploadFooter(props: { footerLeft?: ReactNode; footerRight?: ReactNode }) {
  // Update the left footer with default props
  let footerLeft = props.footerLeft;
  if (isValidElement(footerLeft) && footerLeft.type === UploaderLink) {
    const extProps = {
      'data-cy': 'uploader-footer-btn-left',
      startAdornment: footerLeft.props.startAdornment || <GetApp fontSize="inherit" />,
    };
    footerLeft = cloneElement(footerLeft, extProps);
  }
  // Update the right footer with default props
  let footerRight = props.footerRight;
  if (isValidElement(footerRight) && footerRight.type === UploaderLink) {
    const extProps = {
      'data-cy': 'uploader-footer-btn-right',
      endAdornment: footerRight.props.endAdornment || <Launch fontSize="inherit" />,
    };
    footerRight = cloneElement(footerRight, extProps);
  }

  return (
    <div className="flex flex-row-reverse place-content-between">
      {footerRight}
      {footerLeft}
    </div>
  );
}
