import { FC } from 'react';

import { Reorder, ViewList } from '@material-ui/icons';

import { ViewTypeAnalytics, setViewTypeAnalytics } from '../../analytics/analyticsEventProperties';
import useSendAnalytics from '../../hooks/useSendAnalytics';
import { ButtonBar } from '../scales';

export const LIST = 'List';
export const COMPACT_LIST = 'Compact List';
const COZY_VIEW = 'Cozy view';
const COMPACT_VIEW = 'Compact view';

const options = [
  { icon: <ViewList />, value: LIST, 'aria-label': COZY_VIEW },
  { icon: <Reorder />, value: COMPACT_LIST, 'aria-label': COMPACT_VIEW },
];

type ItemsListViewToggleProps = {
  listView: string;
  setListView: (view: string) => void;
};

const ItemsListViewToggle: FC<ItemsListViewToggleProps> = ({ setListView, listView }) => {
  const sendAnalytics = useSendAnalytics();

  const onChange = (toggleOption: string) => {
    setListView(toggleOption);
    sendAnalytics(setViewTypeAnalytics(ViewTypeAnalytics.ITEMS_SET_VIEW, toggleOption));
  };

  return <ButtonBar onChange={onChange} options={options} value={listView} />;
};

export default ItemsListViewToggle;
