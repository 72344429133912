import { MutableRefObject, ReactNode } from 'react';
import { OverlayContainer, mergeProps, useOverlayPosition, usePopover } from 'react-aria';
import { OverlayTriggerState } from 'react-stately';

import { useActiveTheme } from '../../scales';

type Props = {
  children: ReactNode;
  placement: Parameters<typeof useOverlayPosition>[0]['placement'];
  popoverRef: MutableRefObject<null>;
  state: OverlayTriggerState;
  triggerRef: Parameters<typeof useOverlayPosition>[0]['targetRef'];
};

export function ComboBoxPopover(props: Props) {
  const positioningProps = { containerPadding: 0, placement: props.placement, shouldFlip: false };

  const { popoverProps } = usePopover(
    {
      popoverRef: props.popoverRef,
      triggerRef: props.triggerRef,
      ...positioningProps,
    },
    props.state
  );

  const { overlayProps } = useOverlayPosition({
    overlayRef: props.popoverRef,
    targetRef: props.triggerRef,
    ...positioningProps,
  });

  const activeTheme = useActiveTheme(props.triggerRef);

  const width = props.triggerRef.current?.getBoundingClientRect().width;

  return (
    <OverlayContainer data-theme={activeTheme}>
      <div
        ref={props.popoverRef}
        {...mergeProps(popoverProps, overlayProps)}
        className="overflow-hidden overflow-y-auto rounded border border-border-muted bg-background-primary text-type-primary shadow scrollbar-theme"
        style={{
          ...popoverProps.style,
          width,
        }}
      >
        {props.children}
      </div>
    </OverlayContainer>
  );
}
