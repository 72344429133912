import { Button, Dialog, DialogContent } from '../../scales';

import UserEmail from './UserEmail';

export type Props = {
  draftItemsCreators: Pick<User, 'id' | 'name'>[];
  draftItemsCount: number;
  isOpen: boolean;
  milestone: Pick<Milestone, 'id' | 'name'>;
  onClose: () => void;
};

export default function DeleteMilestoneDraftItemsDialog(props: Props) {
  return (
    <Dialog
      footerRight={<Button label="OK" onClick={props.onClose} type="primary" />}
      isOpen={props.isOpen}
      onClose={props.onClose}
      title="Further Action Required"
    >
      <DialogContent>
        <div>
          To delete {props.milestone.name}, all <b>draft items ({props.draftItemsCount})</b> need to
          be addressed.
        </div>
        <br />
        <div>
          <b>Action Required:</b>
          <div>
            Contact draft item creators below to move their draft items ({props.draftItemsCount}) to
            another milestone.
          </div>
        </div>
        <br />
        <div>
          <b>Draft item creators:</b>
          {props.draftItemsCreators.map((user) => (
            <UserEmail key={user.id} user={user} />
          ))}
        </div>
        <br />
        <div>
          <b>Note:</b> Deleting {props.milestone.name} will permanently remove all related scenarios
          and scenario items.
        </div>
      </DialogContent>
    </Dialog>
  );
}
