import {
  ProjectComparisonReportQuery,
  ProjectComparisonReportQueryVariables,
} from '../../../../generated/graphql';
import { useQuery } from '../../../../hooks/useMountAwareQuery';

import { projectComparisonReport } from './queries';

export const useProjectComparisonReportQuery = (reportID: UUID | undefined) => {
  return useQuery<ProjectComparisonReportQuery, ProjectComparisonReportQueryVariables>(
    projectComparisonReport,
    {
      variables: {
        reportID,
      },
      skip: !reportID,
    }
  );
};
