import { DeleteOutline } from '@material-ui/icons';

import { Button, Dialog, DialogContent, Select } from '../../scales';

export type Props = {
  isOpen: boolean;
  milestone: Pick<Milestone, 'id' | 'name'>;
  milestones: Pick<Milestone, 'id' | 'name'>[];
  onClose: () => void;
  onDelete: () => void;
  onSelect: (milestone: Pick<Milestone, 'id' | 'name'>) => void;
  selectedMilestone: Pick<Milestone, 'id' | 'name'> | undefined;
};

export default function DeleteActiveMilestoneDialog(props: Props) {
  const milestones = props.milestones
    .filter(({ id }) => id !== props.milestone.id)
    .map(({ id, name }) => ({ id, label: name }));
  const onSelect = (entryID: UUID) => {
    props.onSelect(props.milestones.filter(({ id }) => id === entryID)[0]);
  };

  return (
    <Dialog
      footerRight={
        <Button
          isDisabled={!props.selectedMilestone}
          label="Delete"
          onClick={props.onDelete}
          startIcon={<DeleteOutline />}
          type="destructive"
        />
      }
      isOpen={props.isOpen}
      onClose={props.onClose}
      title={`Delete Milestone: ${props.milestone.name}`}
    >
      <DialogContent>
        <div>
          <b>{props.milestone.name}</b> is currently active and cannot be deleted. Deactivate it by
          setting another milestone as active.
        </div>
        <br />
        <Select
          entries={milestones}
          label="New active milestone"
          onChange={onSelect}
          placeholder="Set new active milestone"
        />
      </DialogContent>
    </Dialog>
  );
}
