import { createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
const styles = (theme: any) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      minWidth: 900,
    },
    dialogContent: {
      padding: 0,
      height: 362, // TODO: this is a patch for items as options
    },
    containerSearchBar: {
      padding: `${theme.spacing.generic[1]}px`,
    },
    loading: {
      height: 3,
    },
  });

export default styles;
