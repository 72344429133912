import { useState } from 'react';

import { Add } from '@material-ui/icons';

import { PermissionResource } from '../../generated/graphql';
import usePermissions from '../../utilities/permissions/usePermissions';
import { DialogsAddCollaborator } from '../Dialogs';
import { Button } from '../scales';

export default function Header() {
  const { canEdit } = usePermissions();
  const canEditCollaborators = canEdit(PermissionResource.COLLABORATORS);

  const [showAddCollaboratorDialog, setShowAddCollaboratorDialog] = useState(false);

  return (
    <div className="flex p-4">
      <header className="flex h-10 items-center type-heading1">Team</header>
      <div className="ml-auto flex gap-2">
        {canEditCollaborators && (
          <Button
            data-cy="add-collaborators-button"
            label="Invite Teammates"
            onClick={() => setShowAddCollaboratorDialog(true)}
            startIcon={<Add />}
            type="primary"
          />
        )}
        {showAddCollaboratorDialog && (
          <DialogsAddCollaborator onClose={() => setShowAddCollaboratorDialog(false)} />
        )}
      </div>
    </div>
  );
}
