import { UNASSIGNED } from '../../../constants';
import { Org, OrgNode, ProjectType } from '../../../generated/graphql';

export type ProjectCollaboratorsMap = Map<UUID, ProjectUserDetails[]>;
export type ProjectUserDetails = {
  userID: UUID;
  userName: string;
  isProjectLead?: boolean;
};
export type SelectedProjectDetails = Map<
  UUID,
  { orgNodes: OrgNode[]; projectType: ProjectType; projectLead?: ProjectUserDetails | undefined }
>;

export const findUniqueProjectTypes = (
  selectedProjectDetails: SelectedProjectDetails
): ProjectType[] | null => {
  const projectTypeMap = new Map<string, ProjectType>();

  selectedProjectDetails.forEach((detail) => {
    const projectType = detail.projectType;
    if (!projectTypeMap.has(projectType.id)) {
      projectTypeMap.set(projectType.id, projectType);
    }
  });

  const uniqueProjectTypes = Array.from(projectTypeMap.values());

  return uniqueProjectTypes;
};

export const findUniqueOrgs = (
  selectedProjectDetails: SelectedProjectDetails,
  orgs: Org[]
): OrgNode[] => {
  let commonOrgIds: Set<string> | null = null;

  // Finding common org ids across selected project details
  selectedProjectDetails.forEach((detail) => {
    const projectOrgIds = new Set(detail.orgNodes.map((org) => org.id));

    if (commonOrgIds === null) {
      commonOrgIds = new Set(projectOrgIds);
    } else {
      // Intersecting sets to find common ids
      commonOrgIds = new Set([...commonOrgIds].filter((id) => projectOrgIds.has(id)));
    }
  });

  if (!commonOrgIds || [...commonOrgIds].length === 0) {
    return [];
  }

  const uniqueOrgs: OrgNode[] = [];
  orgs.forEach((org) => {
    org.nodes.forEach((node: OrgNode) => {
      if (commonOrgIds!.has(node.id)) {
        uniqueOrgs.push(node);
      }
    });
  });

  return uniqueOrgs;
};

export const getCommonProjectLeadID = (
  projectDetails: SelectedProjectDetails
): UUID | undefined => {
  // Extract all projectLead userIDs
  const userIDs = Array.from(projectDetails.values()).map((details) => details.projectLead?.userID);

  // If there are no userIDs, return undefined
  if (userIDs.length === 0) return undefined;

  // Check if all userIDs are undefined
  const allUndefined = userIDs.every((userID) => userID === undefined);
  if (allUndefined) return UNASSIGNED;

  // Check if all userIDs are the same
  const firstUserID = userIDs[0];
  const allSame = userIDs.every((userID) => userID === firstUserID);

  return allSame ? firstUserID : undefined;
};
