import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../../theme/komodo-mui-theme';

const styles = (theme: KomodoTheme) =>
  createStyles({
    button: {
      backgroundColor: theme.palette.joinPrimary,
      color: theme.palette.joinPrimary,
      textColor: theme.palette.backgroundWhite,
      alignSelf: 'center',
      width: 'fit-content',
      height: 40,
    },
    buttonContainer: {
      justifyContent: 'center',
      display: 'flex',
      gap: '8px',
      paddingTop: 8,
      paddingBottom: 8,
    },
    buttonText: {
      paddingLeft: 4,
      paddingRight: 4,
      color: theme.palette.backgroundWhite,
    },
    h4: {
      ...theme.typography.h4,
    },
    hidden: {
      opacity: 0,
      position: 'absolute',
      zIndex: -1,
    },
    hintContainer: {
      width: 510,
      display: 'grid',
      gridTemplateColumns: '24px 486px',
      height: 20,
      color: theme.palette.disabledGrey,
      margin: 'auto',
    },
    hintIcon: {
      fontSize: 20,
      marginRight: 8,
      alignSelf: 'center',
    },
    hintText: {
      color: theme.palette.disabledGrey,
      marginTop: 3,
    },
  });

export default styles;
