// import { useMutation } from '@apollo/client';

import { useMutation } from '@apollo/client';

import { gql } from '../../../api/graphqlFragments';
import {
  ReportItemAssistExperimentRequestMutation,
  ReportItemAssistExperimentRequestMutationVariables,
} from '../../../generated/graphql';

const reportItemAssistExperimentRequestMutation = gql`
  mutation reportItemAssistExperimentRequest(
    $projectID: UUID!
    $itemID: UUID!
    $additionalInfo: String!
    $connectWithBPM: Boolean!
  ) {
    reportItemAssistExperimentRequest(
      projectID: $projectID
      itemID: $itemID
      additionalInfo: $additionalInfo
      connectWithBPM: $connectWithBPM
    )
  }
`;

export default function useReportItemAssistExperimentRequest() {
  const [sendRequest] = useMutation<
    ReportItemAssistExperimentRequestMutation,
    ReportItemAssistExperimentRequestMutationVariables
  >(reportItemAssistExperimentRequestMutation);

  const submitFunc = (
    projectID: UUID,
    itemID: UUID,
    additionalInfo: string,
    connectWithBPM: boolean
  ) => {
    return sendRequest({ variables: { projectID, itemID, additionalInfo, connectWithBPM } });
  };
  return submitFunc;
}
