import { JSX } from 'react';

import { useHasFeature } from '../hooks/useHasFeature';

type Props = {
  children: JSX.Element;
  fallback?: JSX.Element;
  flag: string;
};

export default function HasFeatureFlag(props: Props) {
  const hasFeature = useHasFeature(props.flag);

  if (!hasFeature) return props.fallback ?? null;

  return props.children;
}
