import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';
import { widthReportsSettings } from '../ReportsListEntry/ReportsListEntrySettings/ReportsListEntrySettingsStyles';

export const gridTemplateColumnsReport = '40px 6fr 4fr 3fr 2fr 4fr 5fr';
export const gridTemplateBuiltInColumnsReport = '40px 6fr 13fr 5fr';

export default (theme: KomodoTheme) =>
  createStyles({
    card: {
      overflow: 'unset',
      border: theme.border.line,
      marginBottom: 8,
      pageBreakInside: 'avoid',
      width: 'calc(100% - 2px)',
    },
    action: {
      alignSelf: 'center',
      margin: 0,
    },
    defaultCursor: {
      cursor: 'default',
    },
    reportsCount: {
      ...theme.typography.title,
      backgroundColor: theme.palette.backgroundWhite,
      borderTop: theme.border.line,
      padding: `16px 0px 16px 24px`,
      fontSize: 14,
      margin: 0,
    },
    cell: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'flex-start',
      height: 34,
      maxWidth: 'none',
      textAlign: 'left',
      '& > button': {
        background: 'none',
        border: 'none',
      },
      '&:first-child': {
        paddingLeft: `${theme.spacing.generic[3]}px`,
      },
      '&:last-child': {
        width: widthReportsSettings,
      },
    },
    containerRowHeader: {
      display: 'grid',
      gridTemplateColumns: gridTemplateColumnsReport,
      backgroundColor: theme.palette.ds.background.primary,
      paddingLeft: 8,
    },
    headerText: {
      ...theme.typography.webButton,
    },
  });
