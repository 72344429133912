import { AnalyticsEvent, gridAnalytics } from '../analytics/analyticsEventProperties';
import {
  AddColumnProperties,
  CreateLinesProperties,
  CreateMarkupsProperties,
  EditCellsProperties,
  GridType,
  GridVariant,
  RemoveColumnProperties,
  RemoveLinesProperties,
  SetTotalTypeProperties,
  ToggleAllocatedMarkupProperties,
  ToggleInheritedItemMarkupProperties,
  ToggleMarkupWithoutS2ReferenceProperties,
  UpdateMarkupsProperties,
} from '../components/JoinGrid/types';

export type EventCategory = 'MILESTONE' | 'ITEM';
type MilestoneEventKind = 'ESTIMATE' | 'BUDGET' | 'QUANTITY';
type ItemEventKind =
  | 'ITEM_DETAILS'
  | 'OPTION_DETAILS'
  | 'ITEM_FULLSCREEN'
  | 'OPTION_FULLSCREEN'
  | 'ITEM_TEMPLATE';
type EventKind<T extends EventCategory> = T extends 'MILESTONE'
  ? MilestoneEventKind
  : ItemEventKind;

type AnalyticsFn<Properties = object> = (
  type: EventCategory,
  kind: EventKind<EventCategory>,
  gridType?: GridType
) => (p: Properties) => AnalyticsEvent;

// For grid analytics, we always want to include certain properties distinguishing the different
// kinds of events that occur on each milestone and item estimates. This function dedupicates
// that creation logic in a type-safe way.
export function makeMilestoneItemAnalytics<Type extends EventCategory, Properties>(
  type: Type,
  kind: EventKind<Type>,
  name: (type: Type) => string
) {
  const kindProps = type === 'MILESTONE' ? { estimateType: kind } : { itemType: kind };
  const eventName: string = name(type);

  return (properties: Properties) => gridAnalytics(eventName, { ...properties, ...kindProps });
}

export const addColumnAnalytics: AnalyticsFn<AddColumnProperties> = (t, k) =>
  makeMilestoneItemAnalytics(t, k, (t) => `ADD_${t}_ESTIMATE_COLUMN`);

export const createLinesAnalytics: AnalyticsFn<CreateLinesProperties> = (t, k) =>
  makeMilestoneItemAnalytics(t, k, (t) => `ADD_${t}_ESTIMATE_LINES`);

export const createMarkupAnalytics: AnalyticsFn<CreateMarkupsProperties> = (t, k, gridType) => {
  const type = gridType === GridType.MARKUP_GRID ? 'MARKUP' : 'OWNER_COST';
  return makeMilestoneItemAnalytics(t, k, (t) => `ADD_${t}_ESTIMATE_${type}`);
};

export const updateCellsAnalytics: AnalyticsFn<EditCellsProperties> = (t, k) =>
  makeMilestoneItemAnalytics(t, k, (t) => `EDIT_${t}_ESTIMATE_CELLS`);

export const moveColumnAnalytics: AnalyticsFn = (t, k) =>
  makeMilestoneItemAnalytics(t, k, (t) => `MOVE_${t}_ESTIMATE_COLUMN`);

export const removeColumnAnalytics: AnalyticsFn<RemoveColumnProperties> = (t, k) =>
  makeMilestoneItemAnalytics(t, k, (t) => `REMOVE_${t}_ESTIMATE_COLUMN`);

export const removeLineAnalytics: AnalyticsFn<RemoveLinesProperties> = (t, k) =>
  makeMilestoneItemAnalytics(t, k, (t) => `REMOVE_${t}_ESTIMATE_LINES`);

export const removeMarkupAnalytics: AnalyticsFn = (t, k, gridType) => {
  const type = gridType === GridType.MARKUP_GRID ? 'MARKUP' : `OWNER_COST`;
  return makeMilestoneItemAnalytics(t, k, (t) => `REMOVE_${t}_ESTIMATE_${type}`);
};

export const reorderLineAnalytics: AnalyticsFn = (t, k) =>
  makeMilestoneItemAnalytics(t, k, (t) => `REORDER_${t}_ESTIMATE_LINES`);

export const reorderMarkupAnalytics: AnalyticsFn = (t, k) =>
  makeMilestoneItemAnalytics(t, k, (t) => `REORDER_${t}_MARKUPS`);

export const setTotalTypeAnalytics: AnalyticsFn<SetTotalTypeProperties> = (t, k) =>
  makeMilestoneItemAnalytics(t, k, (t) => `${t.toLowerCase()}Estimate_setTotalType`);

export const toggleInheritedItemMarkupAnalytics: AnalyticsFn<
  ToggleInheritedItemMarkupProperties
> = (t, k) => makeMilestoneItemAnalytics(t, k, (t) => `TOGGLE_INHERITED_${t}_MARKUP`);

export const toggleMarkupWithoutS2ReferenceAnalytics: AnalyticsFn<
  ToggleMarkupWithoutS2ReferenceProperties
> = (t, k) => makeMilestoneItemAnalytics(t, k, (t) => `TOGGLE_MARKUP_WITHOUT_S2_REF_${t}`);

export const toggleAllocatedMarkupAnalytics: AnalyticsFn<ToggleAllocatedMarkupProperties> = (
  t,
  k,
  gridType
) => {
  const type = gridType === GridType.MARKUP_GRID ? 'MARKUP' : 'OWNER_COST';
  return makeMilestoneItemAnalytics(t, k, (t) => `TOGGLE_ALLOCATED_${t}_${type}`);
};

export const updateMarkupAnalytics: AnalyticsFn<UpdateMarkupsProperties> = (t, k, gridType) => {
  const type = gridType === GridType.MARKUP_GRID ? 'MARKUP' : 'OWNER_COST';
  return makeMilestoneItemAnalytics(t, k, (t) => `UPDATE_${t}_ESTIMATE_${type}`);
};

export const isItemEstimateVariant = (variant?: GridVariant) =>
  variant === GridVariant.ITEM_DETAILS || variant === GridVariant.ITEM_FULLSCREEN;

export const isNonFullscreenVariant = (variant?: GridVariant) =>
  variant === GridVariant.CATEGORIZATION ||
  variant === GridVariant.ITEM_DETAILS ||
  variant === GridVariant.ITEM_TEMPLATE ||
  variant === GridVariant.QUANTITY;

export const isAccordionVariant = (variant?: GridVariant) =>
  isItemEstimateVariant(variant) ||
  variant === GridVariant.READ_ONLY ||
  variant === GridVariant.MILESTONE_BUDGET ||
  variant === GridVariant.MILESTONE_ESTIMATE;

export const isItemVariant = (variant?: GridVariant) =>
  isItemEstimateVariant(variant) || variant === GridVariant.ITEM_TEMPLATE;

export const isMilestoneVariant = (variant?: GridVariant) =>
  !!variant && [GridVariant.MILESTONE_ESTIMATE, GridVariant.MILESTONE_BUDGET].includes(variant);

const getGridKind = (variant: GridVariant, isOption: boolean) => {
  switch (variant) {
    case GridVariant.ITEM_DETAILS:
      return isOption ? 'OPTION_DETAILS' : 'ITEM_DETAILS';
    case GridVariant.ITEM_FULLSCREEN:
      return isOption ? 'OPTION_FULLSCREEN' : 'ITEM_FULLSCREEN';
    case GridVariant.ITEM_TEMPLATE:
      return 'ITEM_TEMPLATE';
    case GridVariant.MILESTONE_ESTIMATE:
      return 'ESTIMATE';
    case GridVariant.MILESTONE_BUDGET:
      return 'BUDGET';
    case GridVariant.QUANTITY:
      return 'QUANTITY';
    default:
      return 'ITEM_FULLSCREEN';
  }
};

export const makeGridAnalytics = (
  isOption: boolean,
  sendAnalytics: (analyticsEvent: AnalyticsEvent) => void,
  variant: GridVariant
) => {
  const type: EventCategory = isItemVariant(variant) ? 'ITEM' : 'MILESTONE';
  const kind: EventKind<EventCategory> = getGridKind(variant, isOption);

  const analytics = {
    createLinesAnalytics: (p: CreateLinesProperties) => {
      sendAnalytics(createLinesAnalytics(type, kind)(p));
    },
    createMarkupAnalytics: (p: CreateMarkupsProperties, gridType: GridType) => {
      sendAnalytics(createMarkupAnalytics(type, kind, gridType)(p));
    },
    updateMarkupAnalytics: (p: UpdateMarkupsProperties, gridType: GridType) => {
      sendAnalytics(updateMarkupAnalytics(type, kind, gridType)(p));
    },
    removeLineAnalytics: (p: RemoveLinesProperties) => {
      sendAnalytics(removeLineAnalytics(type, kind)(p));
    },
    removeMarkupAnalytics: (gridType: GridType) => {
      sendAnalytics(removeMarkupAnalytics(type, kind, gridType)({}));
    },
    reorderLineAnalytics: () => {
      sendAnalytics(reorderLineAnalytics(type, kind)({}));
    },
    reorderMarkupAnalytics: () => {
      sendAnalytics(reorderMarkupAnalytics(type, kind)({}));
    },
    updateCellsAnalytics: (p: EditCellsProperties) => {
      sendAnalytics(updateCellsAnalytics(type, kind)(p));
    },
    addColumnAnalytics: (p: AddColumnProperties) => {
      sendAnalytics(addColumnAnalytics(type, kind)(p));
    },
    moveColumnAnalytics: () => {
      sendAnalytics(moveColumnAnalytics(type, kind)({}));
    },
    removeColumnAnalytics: (p: RemoveColumnProperties) => {
      sendAnalytics(removeColumnAnalytics(type, kind)(p));
    },
    setTotalTypeAnalytics: (p: SetTotalTypeProperties) => {
      sendAnalytics(setTotalTypeAnalytics(type, kind)(p));
    },
    toggleInheritedItemMarkupAnalytics: (p: ToggleInheritedItemMarkupProperties) => {
      sendAnalytics(toggleInheritedItemMarkupAnalytics(type, kind)(p));
    },
    toggleMarkupWithoutS2ReferenceAnalytics: (p: ToggleMarkupWithoutS2ReferenceProperties) => {
      sendAnalytics(toggleInheritedItemMarkupAnalytics(type, kind)(p));
    },
    toggleAllocatedMarkupAnalytics: (p: ToggleAllocatedMarkupProperties, gridtype: GridType) => {
      sendAnalytics(toggleAllocatedMarkupAnalytics(type, kind, gridtype)(p));
    },
  };
  return analytics;
};
