import { ToastType } from '../../../../api/gqlEnums';
import { setToast } from '../../../../hooks/useToastParametersLocalQuery';

export default function useCreateItemFromAlternateMutation(ideaID: UUID) {
  const submitFunc = (alternateID: UUID) => {
    setToast(
      {
        message: `[NOT YET IMPLEMENTED] useCreateItemFromAlternateMutation: ${ideaID} ${alternateID}`,
      },
      ToastType.SERVER_ERROR
    );
  };
  return [submitFunc];
}
