import { getOperationName } from 'apollo-link';

import { useMutation } from '@apollo/client';

import {
  DateVisibilityToggle,
  IntervalType,
  ToggleAllMilestonesTrendDateVisibilityMutation,
  ToggleAllMilestonesTrendDateVisibilityMutationVariables,
} from '../../../../generated/graphql';
import { isNonNullable } from '../../../../utilities/types';
import { milestonesCostTrendsQuery } from '../../../dashboard/DashboardCharts/DashboardChartsTrend/hooks/queries';

import { toggleAllMilestonesTrendDateVisibilityMutation } from './queries';

export const useToggleAllMilestonesTrendDataVisibility = (
  projectID: UUID,
  intervalType: IntervalType
) => {
  const mutation = useMutation<
    ToggleAllMilestonesTrendDateVisibilityMutation,
    ToggleAllMilestonesTrendDateVisibilityMutationVariables
  >(toggleAllMilestonesTrendDateVisibilityMutation);

  const [toggleEventDataVisibility, { loading }] = mutation;

  const toggleFn = (toggles: DateVisibilityToggle[]) => {
    toggleEventDataVisibility({
      variables: { projectID, intervalType, toggles },
      refetchQueries: [getOperationName(milestonesCostTrendsQuery)].filter(isNonNullable),
      awaitRefetchQueries: true,
    });
  };

  return { toggleFn, toggleLoading: loading };
};
