import { FC } from 'react';

import { ButtonBase, Checkbox, Typography } from '@material-ui/core';
import {
  CheckBoxRounded as CheckBoxChecked,
  CheckBoxOutlineBlankRounded as CheckBoxUnchecked,
} from '@material-ui/icons';

import { withStyles } from '../../../theme/komodo-mui-theme';

import CostReportMetricGroupRowStyles from './CostReportMetricGroupRowStyles';
import { MetricGroupRow, getOpacity } from './CostReportMetricGroupUtils';

type CostReportMetricGroupRowProps = {
  classes: Classes<typeof CostReportMetricGroupRowStyles>;
  isSelected: boolean;
  row: MetricGroupRow;
  toggleSelected: () => void;
  color?: string;
};

const CostReportMetricGroupRow: FC<CostReportMetricGroupRowProps> = ({
  classes,
  row,
  isSelected,
  toggleSelected,
  color,
}) => {
  const { key, text } = row;

  const hasSwatch = !!color;
  const opacity = getOpacity(text);

  const unitSwatch = (background: string) => (
    <div
      className={classes.unitSwatch}
      style={{
        background,
        opacity,
      }}
    />
  );

  return (
    <ButtonBase key={key} className={classes.rowContainer} onClick={toggleSelected}>
      <Checkbox
        checked={isSelected}
        checkedIcon={<CheckBoxChecked className={classes.checkbox} />}
        className={classes.checkbox}
        data-cy={`metricCheckbox-${key}`}
        icon={<CheckBoxUnchecked className={classes.checkbox} />}
        onChange={toggleSelected}
      />
      {hasSwatch && <div className={classes.unitSwatchContainer}>{unitSwatch(color)}</div>}
      <div className={classes.rowContent}>
        <Typography className={classes.titleText}>{text}</Typography>
      </div>
    </ButtonBase>
  );
};

export default withStyles(CostReportMetricGroupRowStyles)(CostReportMetricGroupRow);
