import { ReactNode } from 'react';

import Cell from './Cell';

type Props = {
  'data-cy'?: string;
  isBold?: boolean;
  isCompact?: boolean;
  text?: string | null;
};

export default function TextCell(props: Props) {
  let content: ReactNode = props.text;
  if (props.isBold) content = <strong>{content}</strong>;

  return (
    <Cell data-cy={props['data-cy']} isCompact={props.isCompact}>
      <div className={props.isCompact ? 'type-table-text' : 'type-body1'}>{content}</div>
    </Cell>
  );
}
