import { useMutation } from '@apollo/client';

import { REFETCH_NEW_ITEM } from '../../../api/refetchSets';
import {
  CreateItemsFromImportMutation,
  CreateItemsFromImportMutationOptions,
  CreateItemsFromImportMutationVariables,
} from '../../../generated/graphql';
import { createItemsFromImportMutation } from '../../Items/hooks/itemMutation';

export const useCreateItemsFromImportMutation = (
  options?: CreateItemsFromImportMutationOptions
) => {
  const [mutationFunc, mutationResult] = useMutation<
    CreateItemsFromImportMutation,
    CreateItemsFromImportMutationVariables
  >(createItemsFromImportMutation, options);

  return [
    (projectID: UUID, milestoneID: UUID, assetID: UUID, visibility: Visibility) => {
      return mutationFunc({
        refetchQueries: REFETCH_NEW_ITEM,
        variables: {
          projectID,
          milestoneID,
          assetID,
          visibility,
        },
      });
    },
    mutationResult,
  ] as const;
};
