import { useCallback } from 'react';

import { setCostDisplayAnalytics } from '../../analytics/analyticsEventProperties';
import { CostDisplay, PermissionResource } from '../../generated/graphql';
import useSendAnalytics from '../../hooks/useSendAnalytics';
import { updateCostMode, useCostMode } from '../costMode';
import { useProjectID } from '../routes/params';

import usePermissions from './usePermissions';

/**
 * useSouldDisplayCosts - Hook to get the shouldDisplayCosts and setDisplayCostsToggle function
 * @param projectID The optional project ID to check the permissions, as an override to URL;
 */
export const useShouldDisplayCosts = (customProjectID?: UUID) => {
  const currentProjectID = useProjectID();
  const projectID = customProjectID || currentProjectID;
  const sendAnalytics = useSendAnalytics();
  // Get the shouldDisplayCosts for the project by combining the permissions and the local state
  const { canView } = usePermissions({ projectID });
  const hasToggleAccess = canView(PermissionResource.DISPLAY_COSTS);

  const costMode = useCostMode(projectID);
  const shouldDisplayCosts = costMode.costDisplay !== CostDisplay.HIDE_COSTS;

  // Build up the setShouldDisplayCosts function
  const setShouldDisplayCosts = useCallback(
    (showCosts: boolean) => {
      const costDisplay = showCosts ? CostDisplay.SHOW_COSTS : CostDisplay.HIDE_COSTS;
      sendAnalytics(setCostDisplayAnalytics(costDisplay));
      if (projectID)
        updateCostMode(projectID, {
          costDisplay,
        });
    },
    [projectID, sendAnalytics]
  );

  const setDisplayCostsToggle = hasToggleAccess ? setShouldDisplayCosts : null;

  return {
    /**
     * shouldDisplayCosts: Whether project costs should be displayed or not
     * based on your permissions and if you choose to display them
     */
    shouldDisplayCosts,
    /**
     * setDisplayCostsToggle: Function to toggle the display of project costs
     * the function is null if the user does not have the permission to view costs
     */
    setDisplayCostsToggle,
  };
};
