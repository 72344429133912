import { useCallback, useMemo, useState } from 'react';

import { forecastingEventTypes } from '../../../../../analytics/analyticsEventProperties';
import {
  ForecastingProjectsFilters,
  ForecastingProjectsSortBy,
  ForecastingProjectsSortKey,
  SortDirection,
} from '../../../../../generated/graphql';
import { isEnumValue } from '../../../../../utilities/types';
import { SortManager } from '../../../../scales';
import { useSavedQuery } from '../../../hooks/useSavedQuery';
import useSendForecastingAnalytics from '../../../hooks/useSendForecastingAnalytics';

type ForecastingExploreProjectsVariables = {
  filters: ForecastingProjectsFilters;
  search: string;
  sortBy: ForecastingProjectsSortBy;
};

const LOCAL_STORAGE_KEY = 'FORECASTING_EXPLORE_PROJECTS_QUERY';

const DEFAULT_QUERY: ForecastingExploreProjectsVariables = {
  filters: {
    companies: [],
    estimateCostRange: {},
    locations: [],
    milestoneDateRange: {},
    milestoneDesignPhases: [],
    projectTypes: [],
    statuses: [],
    types: [],
  },
  search: '',
  sortBy: {
    sortDirection: SortDirection.SORT_DESCENDING,
    sortKey: ForecastingProjectsSortKey.UPDATED_AT,
  },
};

export default () => {
  const sendForecastingAnalytics = useSendForecastingAnalytics();
  const [savedQuery, setSavedQuery] = useSavedQuery<ForecastingExploreProjectsVariables>(
    LOCAL_STORAGE_KEY,
    DEFAULT_QUERY
  );

  const [filters, setFilters] = useState<ForecastingProjectsFilters>(savedQuery.filters);
  const [search, setSearch] = useState(savedQuery.search);
  const [sortBy, setSortBy] = useState<ForecastingProjectsSortBy>(savedQuery.sortBy);
  const [unitID, setUnitID] = useState<UUID>();

  const onChangeFilters = useCallback(
    (
      filterName: keyof ForecastingProjectsFilters,
      filterValue: ForecastingProjectsFilters[keyof ForecastingProjectsFilters],
      isSendAnalytics = true
    ) => {
      setFilters((prevState) => ({
        ...prevState,
        [filterName]: filterValue,
      }));
      setSavedQuery({
        filters: { [filterName]: filterValue },
      });
      if (isSendAnalytics) {
        sendForecastingAnalytics(forecastingEventTypes.SELECT_FILTERS, {
          filterSelected: filterValue,
          filterType: filterName,
          location: 'exploreProjects',
          filterSection: 'projectsFilter',
        });
      }
    },
    [sendForecastingAnalytics, setSavedQuery]
  );
  const onChangeSearch = useCallback(
    (search: string) => {
      setSearch(search);
      setSavedQuery({ search });
      sendForecastingAnalytics(forecastingEventTypes.SEARCH, {
        location: 'exploreProjects',
        search,
        searchType: 'projects',
      });
    },
    [sendForecastingAnalytics, setSavedQuery]
  );
  const onChangeSortBy = useCallback(
    (sortBy: ForecastingProjectsSortBy) => {
      setSortBy(sortBy);
      setSavedQuery({ sortBy });
      sendForecastingAnalytics(forecastingEventTypes.SORT, {
        location: 'exploreProjects',
        sortDirection: sortBy.sortDirection,
        sortKey: sortBy.sortKey,
        sortType: 'projects',
      });
    },
    [sendForecastingAnalytics, setSavedQuery]
  );

  const onResetFilters = useCallback(() => {
    setFilters(DEFAULT_QUERY.filters);
    setSavedQuery({
      filters: DEFAULT_QUERY.filters,
    });
  }, [setSavedQuery]);

  const sortManager = useMemo(
    () => getSortManager(sortBy, onChangeSortBy),
    [sortBy, onChangeSortBy]
  );

  return useMemo(
    () => ({
      onChangeFilters,
      onChangeSearch,
      onChangeUnitID: setUnitID,
      onResetFilters,
      sortManager,
      variables: {
        filters,
        search,
        sortBy,
        unitID,
        pagination: {
          offset: 0,
          limit: 25,
        },
      },
    }),
    [filters, onChangeFilters, onChangeSearch, onResetFilters, search, sortBy, sortManager, unitID]
  );
};

export const getSortManager = (
  sortBy: ForecastingProjectsSortBy,
  setSortBy: (sortBy: ForecastingProjectsSortBy) => void
) => {
  const sortManager: SortManager = {
    setSort: (input) => {
      const { sortDirection } = input;
      const sortKey = isEnumValue(ForecastingProjectsSortKey, input.sortKey)
        ? input.sortKey
        : ForecastingProjectsSortKey.UPDATED_AT;
      setSortBy({ sortDirection, sortKey });
    },
    sortState: sortBy,
  };
  return sortManager;
};
