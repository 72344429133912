import { createStyles } from '@material-ui/core';

const styles = () =>
  createStyles({
    dialogPaper: {
      width: 500,
    },
    root: {
      position: 'relative',
    },
    promptText: {
      paddingTop: 16,
      maxWidth: 452,
      paddingBottom: 16,
    },
    modalHeader: {
      paddingLeft: 24,
      paddingRight: 24,
      paddingTop: 16,
      paddingBottom: 16,
    },
    modalBody: {
      paddingTop: 16,
      paddingLeft: 24,
      paddingRight: 24,
      paddingBottom: 8,
    },
  });

export default styles;
