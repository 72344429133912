import { FC } from 'react';

type PrintGroupHeaderProps = {
  hasHeader: boolean;
  hasTwoColumns?: boolean;
  headerText: string;
  headerTextRight?: React.ReactNode;
};

const PrintGroupHeader: FC<PrintGroupHeaderProps> = ({
  hasHeader,
  hasTwoColumns,
  headerText,
  headerTextRight,
}) => {
  if (headerText === '') return <></>;
  const classNames = [];
  classNames.push(hasHeader ? 'print-group-header print-flex' : 'print-group-subheader');
  if (headerTextRight) {
    classNames.push(hasTwoColumns ? 'print-item-row-2' : 'print-item-row');
  }
  return (
    <>
      <div className={classNames.join(' ')}>
        <div className="print-group-header-text">{headerText}</div>
        {headerTextRight}
      </div>
      {!hasHeader && <hr className="print-sub-divider" />}
    </>
  );
};

export default PrintGroupHeader;
