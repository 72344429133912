import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';

const ChartsHorizontalBarGraphStyles = (theme: KomodoTheme) =>
  createStyles({
    placeholderText: {
      color: theme.palette.backgroundWhite,
    },
    row: {
      paddingBottom: 4,
      maxHeight: 25,
    },
  });

export default ChartsHorizontalBarGraphStyles;
