export const HorizontalRuleIcon = () => (
  <svg
    height="1em"
    version="1.1"
    viewBox="0 0 16 16"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
  >
    <line stroke="currentColor" strokeWidth={2} x1="0" x2="16" y1="8" y2="8" />
  </svg>
);
