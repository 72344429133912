export const splitByTabs = (text: string) => {
  // check for any new lines within the text
  if (!text || !text.split) return [];

  // If the user is pasting in a value that has a line break from excel
  // we'll need to parse the data a bit differently
  //
  // Cell data with line breaks has this format:
  // `"this is \n my cell data"`
  // Note: Excel adds double quotes around strings with a newline in them
  // Note: Any double quotes within the value are escapesd with double quotes -> `"full length 6"" deep beam"`
  //
  // The logic below splits the data by new lines and then tabs
  // Then we iterate through the data and try to combine any values
  // in the array that are multi-line values by looking for values that
  // start and end with double quotes

  const splitText = text.split('\n').map((t) => t.split('\t'));
  // get all the new lines and tabs from the text we know what's a new row vs a new column
  const valueDelimeters = Array.from(text.matchAll(/[\n\t]/g)).map((m) => m.toString());
  const flattenedText = splitText.flat();

  // initialize values
  let isMultilineValue = false;
  let result: string[][] = [[]];

  // create counters that we'll need
  let splitTextRowIndex = 0; // keep track of the cell index for a row in for splitText
  let resultRowIndex = 0; // keep track of the row we are inserting into for result
  let rowOffset = 0; // the offset is the difference between the resultIndex and the splitText index
  let delimeterIndex = -1; // the index into the list of delimeteres

  // iterate through the flattened array, and add values to the result array
  // if we find any multi-line values enclosed in quotes we will concatenate them
  // on to the last value in the array
  for (let i = 0; i < flattenedText.length; i += 1) {
    const value = flattenedText[i].trim();

    // check if we've reached the end of a row of values
    // const goToNextRow = splitText.length < rowIndex + rowOffset && splitText[rowIndex + rowOffset].;
    const isValidLength = splitText.length > resultRowIndex + rowOffset;
    if (
      resultRowIndex + delimeterIndex >= 0 &&
      (splitTextRowIndex === -1 ||
        (isValidLength && splitText[resultRowIndex + rowOffset].length <= splitTextRowIndex))
    ) {
      // check if the next delimeter is a newline
      if (delimeterIndex >= 0 && valueDelimeters[delimeterIndex] === '\n') {
        // if this is a not a multiline value then add a new array to the matrix
        // for the next row of values
        if (result.length <= resultRowIndex + 1 && !isMultilineValue) {
          result.push([]);
          resultRowIndex += 1;
        }

        // check if the next delimeter is a tab
      } else if (
        delimeterIndex >= 0 &&
        valueDelimeters[delimeterIndex] === '\t' &&
        resultRowIndex > 0
      ) {
        // do nothing
      } else {
        // rowOffset += 1;
      }
      splitTextRowIndex = 0;
    }

    const lastValueIndex = value.length - 1;

    const isValidValue = value.length > 0;

    // check if the current value is the first part of a multiline value
    if (!isMultilineValue && isValidValue && value[0] === `"`) {
      // since this value starts with double quotes it might be a multiline value
      // let's check if there is another part in the array that ends with
      // double quotes to enclose the multiline value
      if (hasValueEnclosedInQuotes(flattenedText, resultRowIndex)) {
        isMultilineValue = true;
        rowOffset += 1;
        splitTextRowIndex = -1;
      }
      result[resultRowIndex].push(value);

      // if we are in a multiline value, but this isn't the last part in the value
      // then just concatenate this part to the last value in our result array
    } else if (isMultilineValue && value[lastValueIndex] !== `"`) {
      rowOffset += 1;
      splitTextRowIndex = -1;
      result = appendCellToPreviousLine(result, resultRowIndex, value);

      // check if this is the last part of a multi-line value
    } else if (isMultilineValue && value[lastValueIndex] === `"`) {
      isMultilineValue = false;

      // by setting line index to -2 it becomes -1 by the end of this loop
      // then on the next loop we set it to 0, and incriment the k
      // lineIndex = -2;
      // concat this part onto the last value in the results array
      result = appendCellToPreviousLine(result, resultRowIndex, value, true);

      // if this isn't a multiline value then just add the value to the output
    } else {
      result[resultRowIndex].push(value);
    }
    // incrimenent counters
    delimeterIndex += 1;
    splitTextRowIndex += 1;
  }

  return result;
};

// check if any values in the array end with double quotes
// this tells us that there is a multi-line value that is enclosed in quotes
const hasValueEnclosedInQuotes = (input: string[], i: number) => {
  // we only want to search value infront of the current index
  const remainingValues = input.slice(i + 1);
  // search all remaning values in the array, and check if any value ends with double quotes
  const valueIndex = remainingValues.findIndex(
    (value) => value.length > 0 && value.slice(value.length - 1) === `"`
  );
  return valueIndex >= 0;
};

// This function merges the input value with the last cell value in the input matrix
const appendCellToPreviousLine = (
  input: string[][],
  i: number, // current index
  value: string,
  removeQuotes = false
) => {
  const inputCopy = [...input];
  // get the last cell value from the input array
  const j = inputCopy[i].length === 0 ? 0 : inputCopy[i].length - 1;
  const existingValue = inputCopy[i][j];
  // merge the existing value and the input value with a newline between them
  const newValue = existingValue.concat('\n', value);
  // When appending the last value we need to remove the quotes at the begining and end of a multi-line value
  if (removeQuotes) {
    inputCopy[i][j] = newValue.slice(1, newValue.length - 1);
  } else {
    inputCopy[i][j] = newValue;
  }
  return inputCopy;
};
