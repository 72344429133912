import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../../theme/komodo-mui-theme';

export default (theme: KomodoTheme) =>
  createStyles({
    collapseTitle: {
      ...theme.typography.cardHeader,
      paddingTop: 2,
      fontWeight: 500,
    },
    spacer: {
      flexGrow: 1,
    },
  });
