import { SharedResource } from '../../../../generated/graphql';
import { mapOrder, toIds } from '../../../../utilities/utilities';

export const getSummaryEntries = (
  items: ItemsListItem[] | undefined,
  sharedResources: SharedResource[]
) => {
  const sharedResourcesOrdered = mapOrder(sharedResources ?? [], toIds(items ?? []));
  return (items ?? []).map(({ name, status }, index) => ({
    name,
    status,
    sharedUsers: sharedResourcesOrdered[index]?.users ?? [],
  }));
};
