import * as d3 from 'd3';
import { FC, useEffect, useRef } from 'react';

export type ChartPadding = {
  top: number;
  left: number;
  bottom: number;
  right: number;
};

export type AxisLayout = {
  padding: number;
  numberOfTicks: number;
  showLabels: boolean;
};

export type ChartSize = {
  height: number;
  width: number;
  padding: ChartPadding;
  yAxisLayout: AxisLayout;
  xAxisLayout: AxisLayout;
};

export type LabelOptions = {
  hyperlinkProject: boolean;
  fontSize: number;
  fontWeight: number;
};

type ChartsD3GraphWrapperProps = {
  chartSize: ChartSize;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  chartDependencies?: any[];
  renderChartFn: (node: d3.Selection<SVGSVGElement, unknown, null, undefined>) => void;
};

const ChartsD3GraphWrapper: FC<ChartsD3GraphWrapperProps> = ({
  chartSize,
  chartDependencies = [],
  renderChartFn,
}) => {
  const { height, width, padding } = chartSize;
  const ref = useRef<SVGSVGElement | null>(null);

  useEffect(() => {
    if (!ref.current) return () => {};
    d3.select(ref.current).selectChildren().remove();
    renderChartFn(d3.select(ref.current));
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...chartDependencies, ref, renderChartFn]);

  return (
    <svg
      ref={ref}
      style={{
        height,
        width,
        padding: `${padding.top}px ${padding.right}px ${padding.bottom}px ${padding.left}px`,
        // There's a tonne of calculation that's offsetting based on paddings in the
        // charts. Rather than refactoring it all we're leaving this section in content-box
        // for legacy purposes.
        boxSizing: 'content-box',
      }}
    />
  );
};

export default ChartsD3GraphWrapper;
