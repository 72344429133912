import { YC_TIMELINE_WITHOUT_DUE } from '../../../features';
import { useHasFeature } from '../../../hooks/useHasFeature';
import useProjectPropsQuery from '../../../hooks/useProjectPropsQuery';
import { useItems } from '../../ExecutiveDashboardV2/ProjectBabyCharts/hooks/useItems';

import { useItems as useItemsClassic } from './useItems';

export function useItemsConditionally(projectID: UUID) {
  const hasWithoutDue = useHasFeature(YC_TIMELINE_WITHOUT_DUE);

  const projectPropsResults = useProjectPropsQuery(hasWithoutDue ? projectID : undefined);
  const milestoneID = projectPropsResults.data.project?.activeMilestone?.id;

  const itemsResults = useItems(hasWithoutDue ? projectID : undefined, milestoneID);
  const itemsClassicResults = useItemsClassic(hasWithoutDue ? undefined : projectID);

  const results = hasWithoutDue ? itemsResults : itemsClassicResults;
  return { withoutDueDateCount: 0, ...results };
}
