import {
  LoadItemHistoryLinksQuery,
  LoadItemHistoryLinksQueryVariables,
} from '../../../../generated/graphql';
import { useQuery } from '../../../../hooks/useMountAwareQuery';

import { loadItemHistoryLinksQuery } from './queries';

const useItemHistoryLinksQuery = (projectID?: UUID) =>
  useQuery<LoadItemHistoryLinksQuery, LoadItemHistoryLinksQueryVariables>(
    loadItemHistoryLinksQuery,
    {
      variables: { projectID },
      skip: !projectID,
    }
  );

export default useItemHistoryLinksQuery;
