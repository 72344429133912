import { SORT_STATUS } from '../constants';
import { CostReportColumnType } from '../generated/graphql';

import { formatCost } from './currency';
import { getItemStatusLabel } from './item-status';
import { EMPTY_COST, categoryDescription, categoryTitle, isMasterFormat } from './string';

export const reportTypes = [
  CostReportColumnType.RUNNINGTOTAL_REPORT,
  CostReportColumnType.ESTIMATE_REPORT,
  CostReportColumnType.TARGET_REPORT,
  CostReportColumnType.ACCEPTED_REPORT,
  CostReportColumnType.REMAINING_REPORT,
];

// The average character width is 50% of the font size (height)
function fontWidth(fontSize: number) {
  return fontSize * 0.65;
}

// based on the count of elements and chart width, and font, how wide can it be?
export function labelCharacterLimit(categoryCount: number, width: number, fontSize: number) {
  if (categoryCount) {
    const charWidth = fontWidth(fontSize);
    return Math.floor(width / categoryCount / charWidth);
  }
  return 0;
}

// truncate names if they longer than the space available
// if the word is less long than the charLimit, it will appear fully
export default function truncateLabel(
  text: string | undefined,
  charLimit: number | undefined,
  ellipses = true,
  space = false
): string {
  if (!text) {
    return '';
  }
  if (text.length && charLimit && text.length > charLimit) {
    const ellipsesChars = space ? '... ' : '...';
    return text.substring(0, charLimit).concat(ellipses ? ellipsesChars : '');
  }
  return text.toString();
}

const PADDING = 176; // the padding subtracted from the chart width where the labels go
export const PRINT_CHART_BAR_COUNT_BREAKPOINT = 12;

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
export const fontSize: any = {
  name: 14,
  description: 10,
  cost: 15,
  estimate: 10,
  budget: 10,
  accepted: 10,
  remaining: 10,
  running: 10,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
export const fontSizeTable: any = {
  name: 14,
  description: 10,
  cost: 15,
  estimate: 10,
  budget: 10,
  accepted: 10,
  remaining: 10,
  running: 10,
  categoryRegular: 12,
  categorySmall: 10,
};
// to-do:update specific to the table

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
const minLength: any = {
  name: 5,
  description: 12,
  estimate: 14,
  budget: 14,
  accepted: 14,
  remaining: 14,
  running: 14,
};

type TickLabel = {
  cost: string;
  description: string;
  name: string;
  status?: string;
  tooltip?: string;
};

const tickData = ({
  category,
  limits,
  viewBy,
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  category: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  limits: any;
  viewBy?: string;
}) => {
  const labels: TickLabel = {
    cost:
      formatCost(category[CostReportColumnType.RUNNINGTOTAL_REPORT], {
        short: true,
        showCurrencySymbol: false,
      }) || '',
    description: category.name || '',
    name: viewBy === SORT_STATUS ? getItemStatusLabel(category.x) : category.number || '',
  };

  const tooltip = `${labels.name}${(labels.name && labels.description && ' - ') || ''}${
    labels.description
  }`;

  // for each label, if is has a limit, obey it, if not, hide since its too long
  (Object.keys(labels) as (keyof typeof labels)[]).forEach((k) => {
    labels[k] = limits[k] ? truncateLabel(labels[k], limits[k], true) : '';
  });

  labels.tooltip = tooltip;
  // using raw status name for icons
  if (viewBy === SORT_STATUS) labels.status = category.x;
  return labels;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
const longest = (array: any[]) => Math.max(...array.filter((v) => v).map((v) => v.length));

// This is the data required for our print label
// We wanna isolate this from the "old" labels
// The shortening logic should be less convoluted, based on # of categories

export const getTableData = (categories: Category[]) => {
  const isShort = categories.length > PRINT_CHART_BAR_COUNT_BREAKPOINT;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  return categories.map((groupedCategory: any) =>
    Object.fromEntries(
      reportTypes.map((type) => [
        type,
        formatCost(groupedCategory[type], {
          short: isShort,
          rounded: !isShort,
          showCurrencySymbol: false,
        }) || EMPTY_COST,
      ])
    )
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
export const tickFormatFn = (categories: any[], width: number, viewBy?: string) => {
  if (categories) {
    const labelCount = categories && categories.length;
    const paddedWidth = width - PADDING;
    // longest string lengths for each label
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
    const longestValue: any = {
      name: longest(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
        categories.map((g: any) => (viewBy === SORT_STATUS ? getItemStatusLabel(g.x) : g.number))
      ),
      // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
      description: longest(categories.map((g: any) => g.name)),
      cost: longest(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
        categories.map((g: any) =>
          formatCost(g[CostReportColumnType.RUNNINGTOTAL_REPORT], {
            short: true,
            showCurrencySymbol: false,
          })
        )
      ),
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
    const limits: any = {};
    Object.keys(fontSize).forEach((k: string) => {
      limits[k] = labelCharacterLimit(labelCount, paddedWidth, fontSize[k]);
    });

    // Cost: will make sure if maxLabel is greater than our limit, hide, don't concat
    minLength.cost = limits.cost;

    // if the available limit is less than min and the max label is bigger and what can appear there, hide!
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
    const tooLong = (min: any, limit: any, maxLabel: any) => min >= limit && maxLabel > limit;

    Object.keys(limits).forEach((k) => {
      if (minLength[k] && longestValue[k] && tooLong(minLength[k], limits[k], longestValue[k])) {
        limits[k] = null;
      }
    });

    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
    return (i: any) => {
      const category = categories[i];
      return tickData({
        category,
        limits,
        viewBy,
      });
    };
  }
  const emptyLabel: TickLabel = {
    cost: '',
    description: '',
    name: '',
  };
  return () => emptyLabel;
};

export const getCategoriztionMap = (
  categorizations: Categorization[]
): Record<string, Categorization> => {
  const returnMap: Record<string, Categorization> = {};
  categorizations.forEach((c) => {
    returnMap[c.id] = c;
  });
  return returnMap;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
export function cleanCategoryForChart(groupedCategory: any, termStore: TermStore | null = null) {
  let number = categoryTitle(groupedCategory);
  if (isMasterFormat(groupedCategory.categorization)) number = number.substring(0, 2);
  return {
    ...groupedCategory,
    number,
    name: categoryDescription(groupedCategory, termStore),
  };
}
