// This file contains all of the helper functions for measuring row height, auto-sizing columns, and
// summing and searching for a position within sizes.

import { FieldType } from '../../../api/gqlEnumsBe';
import { ALLOCATED_CELL, DELETE_CELL } from '../../../constants';
import { Column, GridData } from '../types';

// If this value is changed, make sure that `measureHeight` also returns
// an equal value as its result for single-line-height string (e.g. "")
export const DEFAULT_ROW_HEIGHT = 35;
export const DEFAULT_COLLAPSE_HEIGHT = 48;

export const isVariableHeight = (type: string) => type === FieldType.STRING;

export const getLeftFixedLocation = (hasCheckbox: boolean, cellWidth: number) =>
  hasCheckbox ? cellWidth : 0;

const calcRowHeightForValues = (
  indicesToMeasure: number[],
  values: (GridCellValue | undefined)[],
  measureHeight: (s: string, i: number) => number
) => {
  let max = DEFAULT_ROW_HEIGHT;
  indicesToMeasure.forEach((index) => {
    if (values[index]) {
      const txt = getValueAtIndex(values, index);
      max = Math.max(max, measureHeight(txt, index));
    }
  });
  return max;
};

const calcRowHeightForLine = (
  indicesToMeasure: number[],
  line: GridLine,
  measureHeight: (s: string, i: number) => number
) => {
  let max = DEFAULT_ROW_HEIGHT;
  indicesToMeasure.forEach((index) => {
    const txt = getValueAtIndex(line.cells, index);
    max = Math.max(max, measureHeight(txt, index));
  });
  return max;
};

const getResizableCellColumns = (data: GridData) => {
  const indicesToMeasure: number[] = [];
  let i = 0;
  // eslint-disable-next-line no-restricted-syntax
  for (const column of data.columns) {
    // These are the only ones that can have variable heights in our table.
    if (isVariableHeight(column.type)) {
      indicesToMeasure.push(i);
    }
    i += 1;
  }
  return indicesToMeasure;
};

export const calcLineHeight = (
  data: GridData,
  values: (GridCellValue | undefined)[],
  measureHeight: (s: string, i: number) => number
) => {
  const indicesToMeasure = getResizableCellColumns(data);
  return calcRowHeightForValues(indicesToMeasure, values, measureHeight);
};

export const calcRowHeights = (
  data: GridData,
  measureHeight: (s: string, i: number) => number
): number[] => {
  const indicesToMeasure = getResizableCellColumns(data);
  return data.lines.map((line) =>
    line ? calcRowHeightForLine(indicesToMeasure, line, measureHeight) : DEFAULT_ROW_HEIGHT
  );
};

// Sum the row heights to obtain the index of y positions of each row.
// This keeps track of the end positions. To find the starts, we can just
// look up one index.
export const calcPrefixSums = (heights: number[]) => {
  let total = 0;
  const result = Array<number>(heights.length);
  for (let i = 0; i < heights.length; i += 1) {
    total += heights[i];
    result[i] = total;
  }
  return result;
};

// Binary search to find the index of the first row to display, i.e.
// the greatest element in the positions that is still before our search.
export const findPosition = (positions: number[], search: number) => {
  let lo = -1;
  let hi = positions.length;
  while (1 + lo < hi) {
    // eslint-disable-next-line no-bitwise
    const mi = lo + ((hi - lo) >> 1);
    if (positions[mi] > search) {
      hi = mi;
    } else {
      lo = mi;
    }
  }
  return hi - 1;
};

// Employ a cheap linear scan to find the index of the next row to display given that
// we know the index of a position known to be smaller than the search, and given that
// the search is likely to be quite nearby to said index.
export const findNextPosition = (positions: number[], index: number, search: number) => {
  for (let i = index; i < positions.length; i += 1) {
    if (positions[i] > search) return i - 1;
  }
  return positions.length - 1;
};

const DEFAULT_MINIMUM_COLUMN_WIDTH = 60;

export const isTotalCell = (column: Column, columns: Column[], index: number) =>
  column.name === 'Total' && index === columns.length - 1;

export const minColWidthByType = (
  field: Column,
  isTotal: boolean,
  scrollbarWidth: number
): number => {
  switch (field.type) {
    case FieldType.CURRENCY:
    case FieldType.CURRENCY_9:
    case FieldType.NUMBER:
    case FieldType.DECIMAL:
    case FieldType.SOURCE:
    case FieldType.MARKUP_DISPLAY_TYPE:
      return !isTotal ? 116 : 130 + 30 - scrollbarWidth; // 10 digits (1 billion + cents) + (margins for total cells)
    case FieldType.STRING:
      switch (field.name.toUpperCase()) {
        case 'DESCRIPTION':
          return 140;
        case 'U/M':
          return 75;
        default:
          return DEFAULT_MINIMUM_COLUMN_WIDTH;
      }
    case FieldType.CATEGORY:
      return 150;
    case FieldType.INHERITED_MARKUP_CHECKBOX:
      return 75;
    case ALLOCATED_CELL:
      return 100;
    case DELETE_CELL:
      return 35;
    default:
      return DEFAULT_MINIMUM_COLUMN_WIDTH;
  }
};

export const getPreferredWidthByType = (
  field: Pick<Column, 'type' | 'name'>,
  stretchTo: number,
  columns: number,
  isTotal: boolean,
  scrollbarWidth: number
): number | undefined => {
  switch (field.type) {
    case FieldType.CURRENCY:
    case FieldType.CURRENCY_9:
      return !isTotal ? 130 : 130 + 30 - scrollbarWidth; // 10 digits (1 billion + cents) + (margins for total cells)
    case FieldType.MARKUP_DISPLAY_TYPE:
      return 160;
    case FieldType.NUMBER:
    case FieldType.DECIMAL:
      return 116;
    case FieldType.REFERENCE:
    case FieldType.INHERITED_REFERENCE:
      return 290;
    case FieldType.STRING:
      switch (field.name.toUpperCase()) {
        case 'DESCRIPTION':
          // Prefer wider description room, but never go below
          // the width we'd get if we weren't setting a preference.
          return Math.round(Math.max(200, stretchTo / columns));
        case 'U/M':
          return 75;
        default:
          return undefined;
      }
    case FieldType.INHERITED_MARKUP_CHECKBOX:
      return 75;
    case FieldType.ALLOCATE:
      return 100;
    case DELETE_CELL:
      return 35;
    default:
      return undefined;
  }
};

export const getColumnWidths = (
  data: GridData,
  stretchTo: number,
  scrollbarWidth: number
): number[] => {
  const colMapping = data.columns.map((col, i) => {
    const isTotal = isTotalCell(col, data.columns, i);
    return getPreferredWidthByType(col, stretchTo, data.columns.length, isTotal, scrollbarWidth);
  });
  const minWidths = data.columns.map((col, i) => {
    return minColWidthByType(col, isTotalCell(col, data.columns, i), scrollbarWidth);
  });
  let colsToMap = colMapping.length;
  let variableAmount = stretchTo;

  for (let i = 0; i < colMapping.length; i += 1) {
    const width = colMapping[i];

    if (width !== undefined) {
      variableAmount -= width;
      colsToMap -= 1;
    }
  }
  const widths = colMapping.map((w, j) =>
    w === undefined ? Math.max(minWidths[j], Math.floor(variableAmount / colsToMap)) : w
  );
  let sum = widths.reduce((acc, x) => acc + x, 0);
  if (sum < stretchTo) {
    const result = widths.map((w) => Math.floor(w + (stretchTo - sum) / widths.length));
    sum = result.reduce((acc, x) => acc + x, 0);
    if (sum !== stretchTo) result[0] += stretchTo - sum;
    return result;
  }
  // If the width with the preferred sizes is too large,
  // reduce equally from everyone (but respecting minimum widths).
  // This is done by taking as much as we can in order from the columns
  // who can contribute least, but not more than a fair share. That way
  // we will always be evenly distributed.
  let remainingOverflow = sum - stretchTo;
  const remainders: [number, number][] = widths.map((w, j) => [w - minWidths[j], j]);
  const sortedAscending = remainders.sort((a, b) => a[0] - b[0]);
  for (let i = 0; i < sortedAscending.length; i += 1) {
    const [available, j] = sortedAscending[i];
    const requiredContribution = Math.floor(remainingOverflow / (sortedAscending.length - i));
    const actualContribution = Math.min(requiredContribution, available);
    widths[j] -= actualContribution;
    remainingOverflow -= actualContribution;
  }
  sum = widths.reduce((acc, x) => acc + x, 0);
  if (sum < stretchTo) widths[0] += stretchTo - sum;
  return widths;
};

export const adjustColumnWidths = (widths: number[], newStretchTo: number) => {
  const last = widths.pop() || 0;
  let sum = widths.reduce((acc, x) => acc + x, 0);
  if (newStretchTo !== sum) {
    const result = widths.map((w) => Math.floor(w + (newStretchTo - sum) / widths.length));
    sum = result.reduce((acc, x) => acc + x, 0);
    if (sum !== newStretchTo) result[0] += newStretchTo - sum;
    result.push(last);
    return result;
  }
  widths.push(last);
  return widths;
};

const getValueAtIndex = (cells: (GridCellValue | GridCell | undefined)[], index: number) => {
  const cell = cells[index];
  let value = '';
  // if this is a GridCell then we need to get the cellValue from the cell
  if (cell && 'value' in cell) {
    const cellValue = cell.value;
    if (cellValue && 'string' in cellValue) value = cellValue.string;
  }
  // if this is a GridCellValue then we can just get the string value
  if (cell && 'string' in cell) value = cell.string;
  return value;
};
