import { createStyles } from '@material-ui/core';

import { DIALOG_PADDING } from '../../Dialogs/DialogsStyles';

import { FILE_EXPLORER_PADDING } from './FilesExplorerStyles';

export default () =>
  createStyles({
    content: {
      padding: FILE_EXPLORER_PADDING,
    },
    img: {
      paddingTop: DIALOG_PADDING,
      paddingBottom: DIALOG_PADDING,
      width: '80%',
      height: 'auto',
    },
  });
