import { createHttpLink, split } from '@apollo/client';
import { BatchHttpLink } from '@apollo/client/link/batch-http/batchHttpLink';

import joinAPI from '../joinAPI';

const fetchParams = {
  uri: `${joinAPI.apiUrl}/graphql`,
  credentials: 'same-origin',
};

const batchHttpLink = new BatchHttpLink({
  ...fetchParams,
  batchMax: 25,
});

const normalHttpLink = createHttpLink(fetchParams);

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
export default split(({ getContext }: any) => getContext().batch, batchHttpLink, normalHttpLink);
