import { FC, RefObject, useRef, useState } from 'react';
import { OverlayTriggerState, useOverlayTriggerState } from 'react-stately';

import { Check, Edit } from '@material-ui/icons';

import { ProjectCompSectionType } from '../../../api/gqlEnums';
import { PROJECT_COMP_FIELDS } from '../../../tagConstants';
import theme, { withStyles } from '../../../theme/komodo-mui-theme';
import { EMPTY_DATE, parseDate } from '../../../utilities/dates';
import { BabyButton, IconButton, Popover, TextArea } from '../../scales';
import { EscalationTargetLocation } from '../constants/projectCompTypes';
import ProjectCompInformationField from '../ProjectCompInformationField';
import ProjectCompsInputsLocation from '../ProjectCompsInputs/ProjectCompsInputsLocation';
import ProjectCompsInputsProjectCompMilestone from '../ProjectCompsInputs/ProjectCompsInputsProjectCompMilestone';
import { useIsProjectCompSectionCollapsed } from '../ProjectCompsSetUtils';
import styles from '../ProjectCompsStyles';
import SidebarLabel from '../SidebarLabel';

import ProjectCompSectionHeader from './ProjectCompSectionHeader';
import { PROJECT_INFORMATION_LABEL } from './ProjectCompsSectionLabels';

const LOCATION = 'Location';
const MILESTONE = 'Milestone';
const MILESTONE_DATE = 'Milestone Date';
const NOTE = 'Note';
const PROJECT_STATUS = 'Project Status';
const PROJECT_TYPE = 'Project Type';

type ProjectCompFieldsType = {
  description?: string;
  location?: string;
  projectType?: string;
  projectStatus?: string;
  quantityMagnitude?: string;
};

type ProjectCompSectionProjectInformationProps = {
  averageCompName?: string;
  classes: Classes<typeof styles>;
  isAverage?: boolean;
  isLabels?: boolean;
  isLocationAutoEscalationApplied?: boolean;
  location?: string | null;
  projectComp?: ProjectComp;
  projectFields?: ProjectCompFieldsType;
  selectedMilestone?: ProjectComp['project']['milestones'][number];
  setDescription?: (description: string | undefined) => void;
  setLocation?: (location: EscalationTargetLocation) => void;
  setMilestone?: (milestoneID: UUID) => void;
};

const ProjectCompSectionProjectInformation: FC<ProjectCompSectionProjectInformationProps> = ({
  averageCompName,
  classes,
  isAverage,
  isLabels = false,
  isLocationAutoEscalationApplied = false,
  location = null,
  projectComp,
  projectFields = {},
  selectedMilestone,
  setLocation = () => {},
  setMilestone = () => {},
  setDescription = () => {},
}) => {
  // Components
  const descriptionField = isLabels ? (
    <SidebarLabel>{NOTE}</SidebarLabel>
  ) : (
    <div className="w-full">
      <ProjectCompInformationField
        className={classes.field}
        classNameTypography="truncate"
        endAdornment={
          <AddDescriptionButton
            description={projectFields.description}
            onDone={(description) => setDescription(description)}
          />
        }
        showEndAdornment={!isLabels}
        value={
          projectFields.description ?? (
            <div style={{ color: theme.palette.disabledGrey }}>Add a note</div>
          )
        }
      />
    </div>
  );

  let locationField = <></>;
  if (isLabels) {
    locationField = <SidebarLabel>{LOCATION}</SidebarLabel>;
  } else if (isAverage) {
    locationField = (
      <ProjectCompsInputsLocation
        averageCompName={averageCompName}
        isLocationAutoEscalationApplied={isLocationAutoEscalationApplied}
        location={location}
        setLocation={setLocation}
      />
    );
  } else {
    locationField = (
      <ProjectCompInformationField
        className={classes.field}
        value={location || projectFields.location || '--'}
      />
    );
  }

  const projectStatusField = isLabels ? (
    <SidebarLabel>{PROJECT_STATUS}</SidebarLabel>
  ) : (
    <ProjectCompInformationField
      className={classes.field}
      value={projectFields.projectStatus ?? '--'}
    />
  );

  const projectTypeField = isLabels ? (
    <SidebarLabel>{PROJECT_TYPE}</SidebarLabel>
  ) : (
    <ProjectCompInformationField
      className={classes.field}
      value={projectFields.projectType ?? '--'}
    />
  );

  let milestoneField = <></>;
  if (isLabels) {
    milestoneField = <SidebarLabel>{MILESTONE}</SidebarLabel>;
  } else if (projectComp) {
    milestoneField = (
      <ProjectCompsInputsProjectCompMilestone
        projectComp={projectComp}
        setMilestone={setMilestone}
      />
    );
  } else {
    milestoneField = (
      <ProjectCompInformationField
        className={classes.field}
        classNameTypography={classes.labelText}
        value="--"
      />
    );
  }

  const milestoneDateField = isLabels ? (
    <SidebarLabel>{MILESTONE_DATE}</SidebarLabel>
  ) : (
    <ProjectCompInformationField
      className={classes.field}
      value={parseDate(selectedMilestone?.date) ?? EMPTY_DATE}
    />
  );

  // Styles
  const fieldsSection = ProjectCompSectionType.SECTION_FIELDS;
  const fieldsClasses = `${classes.fields} ${isLabels ? classes.labelFields : classes.fieldsGroup}`;

  return (
    <div className={classes.fieldsContainer}>
      <ProjectCompSectionHeader
        collapseSection={fieldsSection}
        isHighlightable={isLabels}
        isTogglable={isLabels}
        label={PROJECT_INFORMATION_LABEL}
      />
      {!useIsProjectCompSectionCollapsed(fieldsSection) && (
        <div className={fieldsClasses} data-cy={PROJECT_COMP_FIELDS}>
          {descriptionField}
          {locationField}
          {projectTypeField}
          {projectStatusField}
          {milestoneField}
          {milestoneDateField}
        </div>
      )}
    </div>
  );
};

export default withStyles(styles)(ProjectCompSectionProjectInformation);

function DescriptionFieldPopover(props: {
  existingDescription: string;
  onDone: (description: string | undefined) => void;
  state: OverlayTriggerState;
  triggerRef: RefObject<HTMLButtonElement>;
}) {
  const [value, setValue] = useState(props.existingDescription ?? '');

  const handleCreate = () => {
    if (value.length > 0) {
      props.onDone(value);
    } else {
      props.onDone(undefined);
    }
    props.state.close();
  };

  return (
    <Popover
      className="flex w-75 items-center gap-2 bg-background-primary p-2 shadow"
      offset={4}
      placement="bottom right"
      state={props.state}
      triggerRef={props.triggerRef}
    >
      <TextArea
        data-cy="project-comp-description-text-area"
        label={NOTE}
        onChange={setValue}
        onKeyDown={(e) => {
          if (e.key === 'Escape') props.state.close();
          if (e.key === 'Enter' && !e.shiftKey && !e.metaKey) handleCreate();
        }}
        placeholder="Add a note..."
        value={value}
      />
      <IconButton
        aria-label="add a description"
        icon={<Check />}
        onClick={handleCreate}
        type="secondary"
      />
    </Popover>
  );
}

function AddDescriptionButton(props: {
  description?: string;
  onDone: (description: string | undefined) => void;
}) {
  const ref = useRef(null);
  const state = useOverlayTriggerState({});

  return (
    <>
      <BabyButton
        ref={ref}
        aria-label="add a description"
        data-cy="add-description-button"
        icon={<Edit />}
        onClick={() => state.toggle()}
      />
      {state.isOpen && (
        <DescriptionFieldPopover
          existingDescription={props.description ?? ''}
          onDone={props.onDone}
          state={state}
          triggerRef={ref}
        />
      )}
    </>
  );
}
