import { Line } from './CombinedHoverContent';

type Props = {
  chartType: 'combined' | 'separated';
};

export default function ProgramCostTrendlineLegend(props: Props) {
  return (
    <div className="ml-12 flex gap-4">
      {props.chartType === 'combined' && (
        <div className="flex items-center gap-6">
          <div>
            <Line className="stroke-entities-estimate" strokeDasharray="4, 3" strokeWidth={3} />
            <div className="text-type-muted type-body3">Estimate Total</div>
          </div>
          <div className="flex flex-col">
            <Line className="stroke-entities-estimate" strokeWidth={3} />
            <div className="text-type-muted type-body3">Running Total</div>
          </div>
          <div className="flex flex-col">
            <Line className="stroke-entities-budget" strokeWidth={3} />
            <div className="text-type-muted type-body3">Budget Total</div>
          </div>
          <div>
            <Line
              className="stroke-entities-item-upcoming"
              strokeDasharray="2, 2"
              strokeWidth={3}
            />
            <div className="text-type-muted type-body3">Min/Max Available Range</div>
          </div>
        </div>
      )}
      {props.chartType === 'separated' && (
        <div className="flex flex-col">
          <Line className="stroke-entities-estimate" strokeWidth={3} />
          <div className="text-type-muted type-body3">Running Total</div>
        </div>
      )}
    </div>
  );
}
