import { Selected } from '../../../../api/apollo/reactiveVars';
import { filterSelectedAssets } from '../FilesTable/FilesTableUseUtils';

import FilesAttachButton from './FilesAttachButton';

type FilesAttachItemCopyProps = {
  onAttachItemCopy: (assetIDs: UUID[]) => void;
  onClick: () => void;
  selectedMap: Selected;
};

const FilesAttachItemCopy = (props: FilesAttachItemCopyProps) => {
  // Actions
  const onClickAttachToItem = () => {
    const newIDs = filterSelectedAssets(props.selectedMap).map((a) => a);
    props.onAttachItemCopy(newIDs);
    props.onClick();
  };

  return <FilesAttachButton onClick={onClickAttachToItem} selectedMap={props.selectedMap} />;
};

export default FilesAttachItemCopy;
