const CommentIcon: React.FC = () => {
  return (
    <svg fill="none" height="20" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18 15.17L16.83 14H2V2H18V15.17ZM18 0H2C0.9 0 0 0.9 0 2V14C0 15.1 0.9 16 2 16H16L20 20V2C20 0.9 19.1 0 18 0Z"
        fill="black"
      />
    </svg>
  );
};

export default CommentIcon;
