import { FC, MouseEvent, cloneElement, isValidElement, useState } from 'react';
import * as React from 'react';

import { ReactiveVar, useReactiveVar } from '@apollo/client';
import { CircularProgress } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

import { selectOptionsLoadingDefaultVar } from '../../../api/apollo/reactiveVars';
import { withStyles } from '../../../theme/komodo-mui-theme';
import NormalTooltip from '../../NormalTooltip/NormalTooltip';
import JoinSlide from '../../shared-widgets/JoinSlide';

import styles from './SelectStyles';

export interface MenuOption {
  callback: (e?: MouseEvent) => void;
  color?: string;
  component?: React.ReactNode;
  cy?: string;
  disabled?: boolean;
  fixed?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  icon?: any;
  isRoot?: boolean;
  key?: string;
  loadingVar?: ReactiveVar<boolean>;
  name: string;
  onClick?: () => void;
  subOptions?: MenuOption[];
  subText?: string;
  tooltip?: string;
  width?: number;
}

interface SelectOptionProps {
  classes: Classes<typeof styles>;
  disableKeyboard: () => void;
  enableKeyboard: () => void;
  handleClick: (e?: MouseEvent) => void;
  handleHover: (option: MenuOption) => void;
  highlightedOption: MenuOption;
  index: number;
  length: number;
  onClose: () => void;
  option: MenuOption;
  slideDirection: 'left' | 'right';
}

const getOptionLoadingComponent = (loading: boolean, option: MenuOption) => {
  const disabledOption = {
    ...option,
    icon: <CircularProgress size={20} />,
    disabled: true,
  };
  return loading ? disabledOption : option;
};

const SelectOption: FC<SelectOptionProps> = ({
  classes,
  disableKeyboard,
  enableKeyboard,
  handleClick,
  handleHover,
  highlightedOption,
  index,
  length,
  onClose,
  option,
  slideDirection,
}) => {
  const { loadingVar } = option || {};
  const loading = useReactiveVar(loadingVar || selectOptionsLoadingDefaultVar);
  const optionComponent = getOptionLoadingComponent(loading, option);

  const {
    color,
    component,
    cy,
    disabled,
    fixed,
    icon,
    isRoot,
    key,
    name,
    subOptions,
    subText,
    tooltip,
    width,
  } = optionComponent || {};

  const { key: highlightedOptionKey, name: highlighedOptionName } = highlightedOption || {};
  const isSelected = key ? key === highlightedOptionKey : name === highlighedOptionName;
  const [isHoveringArrowButton, setIsHoveringArrowButton] = useState(false);
  const selectedClass = isSelected && !isHoveringArrowButton && classes.optionSelected;

  if (isValidElement(component)) {
    const {
      props: { addCategory, onRendered },
    }: // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
    any = component;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
    let props: any = {
      onBlur: () => enableKeyboard(),
    };
    if (addCategory) {
      props = {
        ...props,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
        addCategory: (...args: any[]) => {
          addCategory(...args);
          onClose();
        },
      };
    }
    if (onRendered) {
      props = { ...props, onRendered: () => disableKeyboard() };
    }
    return cloneElement(component, props);
  }

  const shouldStickToBottom = index === length - 1 && fixed;
  const stickyTopWithOptionBackClass = isRoot ? `${classes.optionBack} ${classes.stickyTop}` : '';
  const stickyBottomClass = shouldStickToBottom ? classes.stickyBottom : '';
  const stickyClass = stickyTopWithOptionBackClass || stickyBottomClass;

  const title = (
    <span
      aria-selected={isSelected}
      className={`${classes.optionTitle} ${subOptions ? '' : classes.paddingRight} ${
        isRoot || icon ? '' : classes.paddingLeft
      }`}
      data-cy={cy || `${name?.replace(/\s/g, '-')}-IconMenu`}
      onClick={(e) => {
        handleClick(e);
      }}
      onKeyDown={() => {}}
      onMouseEnter={() => {
        handleHover(option);
      }}
      role="option"
      tabIndex={0}
      title={tooltip ? undefined : name}
    >
      {name}
    </span>
  );

  const content = (
    <div className={classes.background}>
      <li
        key={key || name}
        aria-selected={isSelected}
        className={` ${selectedClass} ${classes.option} ${
          disabled ? classes.disabledOption : ''
        } ${stickyClass} `}
        id={`result-item-${index}`}
        role="option"
        style={{ color, width }}
      >
        {icon && (
          <span
            aria-selected={isSelected}
            onClick={() => {
              handleClick();
            }}
            onKeyDown={() => {}}
            onMouseEnter={() => {
              handleHover(option);
            }}
            role="option"
            tabIndex={0}
            title={tooltip ? undefined : name}
          >
            <div className={`${classes.iconLabel} ${classes.icon}`}>{icon}</div>
          </span>
        )}
        {isRoot && (
          <span
            aria-selected={isSelected}
            onClick={() => {
              setIsHoveringArrowButton(false);
              handleClick();
            }}
            onKeyDown={() => {}}
            onMouseEnter={() => {
              handleHover(option);
              setIsHoveringArrowButton(true);
            }}
            onMouseLeave={() => {
              setIsHoveringArrowButton(false);
            }}
            role="option"
            tabIndex={0}
            title={`back to ${name}`}
          >
            <IconButton classes={{ root: classes.iconBackButton }}>
              <ArrowBackIosIcon classes={{ root: classes.iconBack }} />
            </IconButton>
          </span>
        )}
        {tooltip ? (
          <NormalTooltip placement="left" title={tooltip}>
            {title}
          </NormalTooltip>
        ) : (
          title
        )}
        {subOptions && (
          <span
            onMouseEnter={() => {
              handleHover(option);
              setIsHoveringArrowButton(true);
            }}
            onMouseLeave={() => {
              setIsHoveringArrowButton(false);
            }}
            title={`view ${name} suboptions`}
          >
            <IconButton classes={{ root: classes.iconButton }}>
              <ArrowForwardIosIcon
                onClick={() => {
                  setIsHoveringArrowButton(false);
                  handleClick();
                }}
              />
            </IconButton>
          </span>
        )}
      </li>
      {isRoot && subText ? <span className={`${classes.optionSubText}`}>{subText}</span> : null}
    </div>
  );

  return <JoinSlide direction={slideDirection}>{content}</JoinSlide>;
};

export default withStyles(styles)(SelectOption);
