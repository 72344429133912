import { FC, useContext, useEffect, useState } from 'react';

import { useReactiveVar } from '@apollo/client';

import { ONBOARDING_STEP1_CTA } from '../../../actions/actionTypes';
import {
  analyticsEvent,
  importEstimatesSelectFile,
  importEstimatesSelectFileInvalid,
} from '../../../analytics/analyticsEventProperties';
import {
  getImportEstimateVar,
  helpTipTrackerVar,
  importEstimateActiveSkipMsVar,
  importEstimateBudgetSkipMsVar,
  isEstimateUploadDialogOpen,
  setReactiveLocal,
} from '../../../api/apollo/reactiveVars';
import { EstimateType, ImportModal, TermKey } from '../../../api/gqlEnums';
import { IMPORT_ESTIMATE_ACTIVE, IMPORT_ESTIMATE_BUDGET } from '../../../constants';
import { GetDetailedMilestonesQuery, UserEventType } from '../../../generated/graphql';
import useSendAnalytics from '../../../hooks/useSendAnalytics';
import { withStyles } from '../../../theme/komodo-mui-theme';
import { getExtension } from '../../../utilities/string';
import { getMilestoneIdFromUrl } from '../../../utilities/url';
import { useImportEstimate } from '../../assets/hooks/AssetsMutationHook';
import { initUploadWithEstimateType } from '../../assets/utils';
import EstimateAddUploadDrag from '../../ImportEstimate/EstimateAddUpload/EstimateAddUploadDrag';
import ImportManager from '../../ImportEstimate/ImportManager/ImportManager';
import { useBuiltinToggles } from '../../ImportEstimate/utils';
import MilestoneSettingsReplaceDialog from '../../Milestone/MilestoneSettingsPanel/MilestoneSettingsReplaceDialog';
import { useImportEstimateTimer } from '../../PerfMonitor/utils';
import { ProjectTermStore } from '../../ProjectDisplaySettings/TerminologyProvider';
import ShadedHelpTip from '../../shared-widgets/ShadedHelpTip/ShadedHelpTip';
import { Status } from '../types';
import { getEstimateFileError, isUploadingStatusDisplayed } from '../utils';

import EstimateUploadContainer from './EstimateUploadContainer';
import styles from './EstimateUploadStyles';
import {
  onFailedEstimateUpload,
  onSuccessfulEstimateUpload,
  uploadedActionAnalytics,
} from './utils';

type Asset = GetDetailedMilestonesQuery['milestones'][number]['importedEstimates'][number];

type EstimateUploadProps = {
  classes: Classes<typeof styles>;
  estimateType: EstimateType;
  hasEstimate: boolean;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  hideCurrentUpload: boolean;
  estimateId?: UUID;
  lastUploadedEstimate?: Asset;
  name?: string;
  refetchMilestone: () => void;
};

const EstimateUploadMilestone: FC<EstimateUploadProps> = ({
  classes,
  estimateId,
  estimateType,
  hasEstimate,
  hideCurrentUpload,
  lastUploadedEstimate,
  name,
  refetchMilestone,
}) => {
  const milestoneID = getMilestoneIdFromUrl();
  const t = useContext(ProjectTermStore);
  const isTarget = estimateType === EstimateType.BUDGET;
  const termKey: TermKey = isTarget ? TermKey.TARGET : TermKey.ESTIMATE;
  const lowerTerm = t.lowerCase(termKey);

  const helpTipTrackerInfo = useReactiveVar(helpTipTrackerVar);
  const showHelpTip = helpTipTrackerInfo.estimateImportCount <= 3 && !hasEstimate;
  const tipText = isTarget
    ? `A ${lowerTerm} is the target value for the project and acts as the goal for the team to work towards.`
    : `An ${lowerTerm} is the original baseline opinion of cost for the project in any given milestone.`;

  const importEstimateVar = getImportEstimateVar(estimateType);
  const importEstimate: ImportEstimateParameters = useReactiveVar(importEstimateVar);
  const { assetId, modalIsOpen: displayManager } = importEstimate;

  const importEstimateKey =
    estimateType === EstimateType.ACTIVE_ESTIMATE ? IMPORT_ESTIMATE_ACTIVE : IMPORT_ESTIMATE_BUDGET;

  const { hasNoBuiltIns, loading } = useBuiltinToggles();
  const showMapUfMfModal = !hasNoBuiltIns;
  useEffect(() => {
    const modal = showMapUfMfModal ? ImportModal.MAP_UF_MF : ImportModal.INCLUDE;
    return setReactiveLocal(importEstimateVar, importEstimateKey, {
      ...importEstimate,
      modal,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO CT-566: Fix this pls :)
  }, [showMapUfMfModal]);

  // Analytics
  const sendAnalytics = useSendAnalytics();

  // subscribe to check if the estimate dialog modal is open
  const isOpen = useReactiveVar(isEstimateUploadDialogOpen);

  const initialFileStatus: Status = {
    isUploading: false,
    file: { name: name || null },
    estimateId,
  };
  const [fileStatus, setFileStatus] = useState<Status>(initialFileStatus);
  useEffect(() => {
    return setFileStatus({
      isUploading: false,
      file: { name: name || null },
      estimateId,
    });
  }, [estimateId, name, assetId]);

  const [elapsedTime, setElapsedTime] = useState<number | null>(null);
  useImportEstimateTimer(
    importEstimate.id,
    UserEventType.IMPORT_ESTIMATE_UPLOAD_FINISH,
    elapsedTime,
    setElapsedTime
  );

  const [importEstimateHook] = useImportEstimate();
  const onFileSelected = (file: File, source: FileSource) => {
    if (fileStatus.isUploading) return;
    const errorMessage = getEstimateFileError(file);
    if (errorMessage) {
      setFileStatus({
        isUploading: false,
        file,
        estimateId: undefined,
        error: errorMessage,
      });
      sendAnalytics(
        importEstimatesSelectFileInvalid(estimateType, source, getExtension(file.name))
      );
      return;
    }
    sendAnalytics(importEstimatesSelectFile(estimateType, source));

    if (file.size === 0) {
      const errorMessage = 'The file is empty';
      setFileStatus({
        isUploading: false,
        file,
        estimateId: undefined,
        error: errorMessage,
      });
      return;
    }

    setFileStatus({ isUploading: true, file });
    const start = window.performance.now();
    const action = initUploadWithEstimateType(
      {
        milestoneID,
        file,
      },
      estimateType
    );
    const uploadStart = Date.now();
    importEstimateHook(
      action,
      (uploaded) => {
        onSuccessfulEstimateUpload(
          uploaded,
          estimateType,
          uploadStart,
          showMapUfMfModal,
          loading,
          start,
          file,
          setFileStatus,
          setElapsedTime,
          refetchMilestone
        );
        sendAnalytics(analyticsEvent(ONBOARDING_STEP1_CTA));
        uploadedActionAnalytics(true, uploadStart, sendAnalytics);
      },
      (result: { response: string; status?: number }) => {
        onFailedEstimateUpload(result, file, setFileStatus);
        uploadedActionAnalytics(false, uploadStart, sendAnalytics);
      }
    );
  };

  const onCancelUploading = () => {
    setFileStatus(initialFileStatus);
    const importEstimateSkipMsVar =
      estimateType === EstimateType.ACTIVE_ESTIMATE
        ? importEstimateActiveSkipMsVar
        : importEstimateBudgetSkipMsVar;
    importEstimateSkipMsVar(Date.now());
    if (isEstimateUploadDialogOpen()) isEstimateUploadDialogOpen(false);
  };

  // Display Logic
  const isDraftingStatus = isUploadingStatusDisplayed(
    importEstimate,
    fileStatus,
    !!lastUploadedEstimate,
    hideCurrentUpload
  );
  const needsEstimate = !hasEstimate;
  const hasDraftNew = needsEstimate && !isDraftingStatus;

  // Display components
  const actionButtons = isDraftingStatus ? (
    <EstimateUploadContainer
      estimateType={estimateType}
      fileStatus={fileStatus}
      hideCurrentUpload={hideCurrentUpload}
      lastUploadedEstimate={lastUploadedEstimate}
    />
  ) : undefined;

  const createDraftEstimateContent = (
    <div className={classes.newImportWizardnewEstimate}>
      <div className={classes.newInputs} data-cy={`drag-importEstimate-${estimateType}`}>
        <EstimateAddUploadDrag
          errorText={fileStatus.error}
          estimateType={estimateType}
          loading={fileStatus.isUploading}
          lowerTerm={lowerTerm}
          onCancelUploading={onCancelUploading}
          onFileSelected={onFileSelected}
          refetchMilestone={refetchMilestone}
        />
      </div>
    </div>
  );

  const replaceDisplay = (
    <div className={classes.fullPage}>
      {!isDraftingStatus || fileStatus.isUploading || fileStatus.error ? (
        createDraftEstimateContent
      ) : (
        <div className={classes.replaceUpload}>
          <EstimateUploadContainer
            estimateType={estimateType}
            fileStatus={fileStatus}
            hideCurrentUpload={hideCurrentUpload}
            lastUploadedEstimate={lastUploadedEstimate}
          />
        </div>
      )}
    </div>
  );

  // Return components
  const showHeader = !!showHelpTip || !!actionButtons;
  const draftContent = (
    <div className={!hasEstimate ? classes.fullPage : ''}>
      {showHeader && (
        <div className={classes.header}>
          {showHelpTip && <ShadedHelpTip tip={tipText} />}
          <div className={classes.spacer} />
          {actionButtons}
        </div>
      )}
      {(!isDraftingStatus || !hasEstimate) &&
        (hasDraftNew || fileStatus.isUploading || fileStatus.error) &&
        createDraftEstimateContent}
    </div>
  );

  return (
    <>
      <MilestoneSettingsReplaceDialog
        onClose={() => {
          isEstimateUploadDialogOpen(false);
          if (fileStatus.error || fileStatus.isUploading) onCancelUploading();
        }}
        open={isOpen}
        term={lowerTerm}
      >
        {replaceDisplay}
      </MilestoneSettingsReplaceDialog>
      {displayManager && <ImportManager estimateType={estimateType} />}
      {draftContent}
    </>
  );
};

export default withStyles(styles)(EstimateUploadMilestone);
