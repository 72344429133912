import { FC } from 'react';

import { ITEM_DETAILS } from '../../../constants';
import usePermissions from '../../../utilities/permissions/usePermissions';
import {
  ITEM_HISTORY_ZOOM,
  useHeightOnLoad,
} from '../../Print/PrintItemDetails/PrintItemDetailsUtils';

import CommentsHistory from './CommentsHistory';

type CommentsHistoryPrintProps = {
  costMode: CostMode;
  item: ItemDataQueryItem;
  permissions: ReturnType<typeof usePermissions>;
  triggerOnComplete?: () => void;
};

const CommentsHistoryPrint: FC<CommentsHistoryPrintProps> = ({
  costMode,
  item,
  permissions,
  triggerOnComplete,
}) => {
  const { onLoadComplete, ref, height } = useHeightOnLoad(triggerOnComplete, ITEM_HISTORY_ZOOM);
  return (
    <div
      ref={ref}
      style={{ height, transform: `scale(${ITEM_HISTORY_ZOOM})`, transformOrigin: 'top left' }}
    >
      <CommentsHistory
        canComment={false}
        costMode={costMode}
        item={item}
        permissions={permissions}
        triggerOnComplete={onLoadComplete}
        variant={ITEM_DETAILS}
      />
    </div>
  );
};

export default CommentsHistoryPrint;
