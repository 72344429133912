import theme from '../../../../../theme/komodo-mui-theme';

export const getFileTypeColor = (type: string) => {
  switch (type) {
    case 'pdf':
      return theme.palette.red;
    case 'png':
    case 'jpeg':
    case 'jpg':
    case 'xls':
    case 'xlsx':
      return theme.palette.bauhaus.darkGreen;
    case 'doc':
    case 'docx':
      return theme.palette.primaryBlue;
    case 'rvt':
      return theme.palette.rvtIcon;
    default:
      return theme.palette.primaryGrey;
  }
};
