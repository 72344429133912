import { FC, useState } from 'react';

import { Typography } from '@material-ui/core';

import { withStyles } from '../../../../theme/komodo-mui-theme';
import { getDateString } from '../../../../utilities/dates';
import AllMilestoneContingencyReportEmptyState from '../AllMilestoneContingencyReportEmptyState';
import ContingencyReportPieGraph from '../PieGraph/ContingencyReportPieGraph';
import Legend from '../SummaryReport/ContingencySummaryReportLegend';

import AllMilestoneReportRow from './AllMilestoneReportRow';
import { styles } from './AllMilestoneReportStyles';

type AllMilestoneReportProps = {
  classes: Classes<typeof styles>;
  allMilestoneContingencies: MultiMilestoneContingencyReport;
  activeMilestoneContingencies: ContingencyReport;
  milestoneName: string;
  milestoneCost: Cost;
  projectID: string;
  milestoneID: string;
  hasNoSelectedContingenciesOrAllowances: boolean;
  clearFilters: () => void;
};

const AllMilestoneReport: FC<AllMilestoneReportProps> = ({
  classes,
  allMilestoneContingencies,
  activeMilestoneContingencies,
  milestoneName,
  milestoneCost,
  projectID,
  milestoneID,
  hasNoSelectedContingenciesOrAllowances,
  clearFilters,
}) => {
  const rows: React.ReactNode[] = [];
  allMilestoneContingencies.contingencies.forEach((contingency, i) => {
    // add a page break every 4 lines
    // except for the first page which gets 3 lines
    const isFirstPageBreak = i === 2;
    const isPageBreak = i > 2 && (i + 2) % 4 === 0;
    if (isFirstPageBreak || isPageBreak) {
      rows.push(<div className={classes.pageBreak} />);
    }
    rows.push(
      <AllMilestoneReportRow
        key={`${contingency.name} ${contingency.type}`}
        columnNames={allMilestoneContingencies.milestones.map((c) => c.name)}
        contingencyRow={contingency}
        isFirstRow={i === 0}
      />
    );
  });
  const hasVisibleContingencies = allMilestoneContingencies.contingencies.length > 0;
  const [showPieChart, setShowPieChart] = useState(true);
  const pieChartToggleText = showPieChart ? 'Hide Chart' : 'Show Chart';

  return (
    <div className={classes.root}>
      {hasVisibleContingencies && (
        <div className={classes.pieChartContainer}>
          <div className={classes.pieChart}>
            {showPieChart && (
              <ContingencyReportPieGraph
                contingencies={activeMilestoneContingencies}
                milestoneCost={milestoneCost}
                milestoneName={milestoneName}
              />
            )}
          </div>
          <button
            className={classes.hideChartButton}
            onClick={() => setShowPieChart(!showPieChart)}
          >
            <Typography className={classes.hideChartText}>{pieChartToggleText}</Typography>
          </button>
        </div>
      )}
      <div className={classes.header}>
        <div className={classes.headerLegend}>
          <Legend hasOverdrawnContingency hasRowGap />
        </div>
        <div className={classes.contingencyHeaderContainer}>
          {allMilestoneContingencies.milestones.map((milestone) => (
            <div key={milestone.name} className={classes.contingencyHeader}>
              {milestone.isActiveMilestone && (
                <div className={classes.activeMilestoneBadge}>
                  <span className={classes.greenDot}>{'\u2B24'}</span>Active
                </div>
              )}
              <h2>{milestone.name}</h2>
              {!!milestone.startDate && <p>{getDateString(new Date(milestone.startDate))}</p>}
            </div>
          ))}
        </div>
      </div>
      {!hasVisibleContingencies ? (
        <AllMilestoneContingencyReportEmptyState
          clearFilters={clearFilters}
          hasNoSelectedContingenciesOrAllowances={hasNoSelectedContingenciesOrAllowances}
          milestoneID={milestoneID}
          projectID={projectID}
        />
      ) : (
        rows
      )}
    </div>
  );
};

export default withStyles(styles)(AllMilestoneReport);
