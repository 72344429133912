import { useLocalStorage } from 'react-use';

import { ProjectCompSectionType } from '../../../api/gqlEnums';
import { ProjectCompsSetQuery } from '../../../generated/graphql';
import useProjectPropsQuery from '../../../hooks/useProjectPropsQuery';
import { isEnumValue } from '../../../utilities/types';
import PrintProjectCompsColumnGroup from '../../Print/PrintProjectComps/PrintProjectCompsColumnGroup';
import PrintProjectCompsPageHeader from '../../Print/PrintProjectComps/PrintProjectCompsPageHeader';
import {
  CompsPrintThumbnailSize,
  PRINT_PARAMS_DEFAULTS,
  PRINT_PARAMS_KEY,
  getColumnGroupKey,
  getColumnGroups,
  getHasMarkups,
} from '../../Print/PrintProjectComps/PrintProjectCompsUtils';
import { LANDSCAPE_WIDTH, PORTRAIT_WIDTH } from '../../Print/PrintUtils';
import { Button, Checkbox, Dialog, Radio, RadioGroup } from '../../scales';
import { useIsProjectCompSectionCollapsed } from '../ProjectCompsSetUtils';
import PrintProjectCompsChartData from '../ProjectCompsVisualization/ProjectCompsChart/PrintProjectCompsChartData';

type Props = {
  isOpen: boolean;
  onClickPrint: () => void;
  projectComparisonReport?: ProjectComparisonReport;
  projectCompsSet: ProjectCompsSetQuery['projectCompsSet'];
  setIsOpen: (isOpen: boolean) => void;
};

export function ProjectCompsPrintDialog(props: Props) {
  const isChartsCollapsed = useIsProjectCompSectionCollapsed(ProjectCompSectionType.SECTION_GRAPHS);
  const [params, setParams] = useLocalStorage(PRINT_PARAMS_KEY, {
    ...PRINT_PARAMS_DEFAULTS,
    hasCharts: !isChartsCollapsed,
  });

  const projectID = props.projectComparisonReport?.attachedProject?.hasAccess
    ? props.projectComparisonReport.attachedProject?.id
    : undefined;

  const pageHeader = (
    <PrintProjectCompsPageHeader
      isLoading={false}
      onLoaded={() => {}}
      pageText={{ title: '', value: '' }}
      projectID={projectID ?? ''}
      reportName={props.projectComparisonReport?.name}
    />
  );

  // Data transformations
  const parentProject = useProjectPropsQuery(projectID).data?.project;
  const hasMarkups = getHasMarkups(props.projectCompsSet);
  const { columnGroups } = getColumnGroups(props.projectCompsSet);
  const columnGroup = columnGroups[0];

  return (
    <Dialog
      data-cy="print-dialog"
      footerRight={<Button label="Print" onClick={props.onClickPrint} type="primary" />}
      isOpen={props.isOpen}
      onClose={() => props.setIsOpen(false)}
      size="lg"
      title="Print preview"
    >
      <div className="flex">
        <div className="flex flex-col gap-6 px-6 py-2">
          <div className="flex flex-col gap-1">
            <div className="type-body-1">Contents</div>
            <Checkbox
              isSelected={params?.hasNotes}
              onChange={(hasNotes) => {
                if (params) {
                  setParams({ ...params, hasNotes });
                }
              }}
            >
              Include notes
            </Checkbox>
            <Checkbox
              isSelected={params?.hasCharts}
              onChange={(hasCharts) => {
                if (params) {
                  setParams({ ...params, hasCharts });
                }
              }}
            >
              Include charts
            </Checkbox>
          </div>
          <div className="flex flex-col gap-1">
            <RadioGroup
              label="Image Size"
              onChange={(imageSize) => {
                if (isEnumValue(CompsPrintThumbnailSize, imageSize) && params) {
                  setParams({ ...params, imageSize });
                }
              }}
              value={params?.imageSize}
            >
              <Radio value={CompsPrintThumbnailSize.LARGE}>Large</Radio>
              <Radio value={CompsPrintThumbnailSize.SMALL}>Small</Radio>
              <Radio value={CompsPrintThumbnailSize.NONE}>No Images</Radio>
            </RadioGroup>
          </div>
        </div>
        <div className="flex flex-grow flex-col items-center justify-center gap-2 bg-background-2 p-4">
          <div className="type-body-3">Preview</div>
          <div className="flex gap-4">
            {params?.hasCharts && (
              <ScalingPage>
                <PrintProjectCompsChartData
                  height={PORTRAIT_WIDTH}
                  pageHeader={pageHeader}
                  projectCompsSet={props.projectCompsSet}
                />
              </ScalingPage>
            )}
            <ScalingPage>
              <PrintProjectCompsColumnGroup
                key={getColumnGroupKey(columnGroup)}
                columnGroup={columnGroup}
                costTableColumnInputs={props.projectCompsSet.input.costTableColumnInputs}
                groupIndex={0}
                hasMarkups={hasMarkups}
                onThumbnailLoad={() => {}}
                pageHeader={pageHeader}
                parentProject={parentProject}
                projectCompsSet={props.projectCompsSet}
                showNotes={params?.hasNotes ?? false}
                showOnlyFirstPage
                thumbnailSize={params?.imageSize ?? CompsPrintThumbnailSize.SMALL}
              />
            </ScalingPage>
          </div>
          <div className="type-body-3">{`Showing first ${
            params?.hasCharts ? 'two pages' : 'page'
          } only`}</div>
        </div>
      </div>
    </Dialog>
  );
}

const PAGE_PADDING = 8;
const TARGET_WIDTH = 360;
const SCALE_FACTOR = (TARGET_WIDTH - 2 * PAGE_PADDING) / LANDSCAPE_WIDTH;

/**
 * ScalingPage component for print preview
 * In order to scale, we end up hard-coding the width and height of the page
 */
const ScalingPage = ({ children }: { children: React.ReactNode }) => (
  <div
    className="bg-background-primary"
    style={{
      padding: PAGE_PADDING,
      height: PORTRAIT_WIDTH * SCALE_FACTOR + PAGE_PADDING * 2,
      width: LANDSCAPE_WIDTH * SCALE_FACTOR + PAGE_PADDING * 2,
    }}
  >
    <div
      className="origin-top-left overflow-hidden"
      style={{
        height: PORTRAIT_WIDTH,
        width: LANDSCAPE_WIDTH,
        transform: `scale(${SCALE_FACTOR})`,
      }}
    >
      {children}
    </div>
  </div>
);
