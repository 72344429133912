import { Extension } from '@tiptap/core';

export const ShiftEnterExtension = Extension.create({
  addKeyboardShortcuts() {
    return {
      'Shift-Enter': ({ editor }) => {
        editor.commands.enter();
        return true;
      },
    };
  },
  name: 'ShiftEnterExtension',
});

export const TabExtension = Extension.create({
  addKeyboardShortcuts() {
    return {
      Tab: ({ editor }) => {
        if (!editor.can().undo()) {
          editor.commands.blur();
          return true;
        }
        return false;
      },
    };
  },
  name: 'TabExtension',
});
