import useAssetBlobUrlQuery from '../../assets/hooks/useAssetBlobUrlQuery';

type Props = {
  assetID: UUID;
  bannerName: string;
};

export default function TeamLogo(props: Props) {
  const { url } = useAssetBlobUrlQuery(props.assetID);
  return (
    <img
      alt={`${props.bannerName}`}
      className="h-10 bg-background-primary object-contain"
      src={url}
    />
  );
}
