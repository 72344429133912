import { commonCollaboratorFields, gql } from '../../../api/graphqlFragments';
import {
  userFields,
  userLinkFields,
  userLinkFieldsWithoutPicture,
} from '../../ItemsList/hooks/itemsListQuery';

export const usersByIDsQuery = gql`
  query usersByIDs($IDs: [UUID!]!) {
    usersByIDs(IDs: $IDs) {
      ...userLinkFieldsWithoutPicture
    }
  }
  ${userLinkFieldsWithoutPicture}
`;

export const assigneeByIdQuery = gql`
  query assigneeById($ID: UUID!) {
    userByID(ID: $ID) {
      ...userLinkFields
    }
  }
  ${userLinkFields}
`;

export const userByIdQuery = gql`
  query userById($ID: UUID!) {
    userByID(ID: $ID) {
      ...userFields
    }
  }
  ${userFields}
`;

export const getProjectNameQuery = gql`
  query getProjectName($projectID: UUID!) {
    project(id: $projectID) {
      id
      name
    }
  }
`;

export const loadCollaboratorsQuery = gql`
  query loadCollaborators($projectId: UUID!) {
    collaborators(project: $projectId) {
      ...commonCollaboratorFields
    }
  }
  ${commonCollaboratorFields}
`;

export const loadCollaboratorsMultipleProjects = gql`
  query loadCollaboratorsMultipleProjects($projectIDs: [UUID!]!) {
    loadCollaboratorsMultipleProjects(projectIDs: $projectIDs) {
      collaborators {
        ...commonCollaboratorFields
      }
      projectID
    }
  }
  ${commonCollaboratorFields}
`;

export const createCollaboratorsMutation = gql`
  mutation createCollaborators(
    $projectID: UUID!
    $collaborators: [CollaboratorInput!]!
    $message: String
    $disableNotifications: Boolean
  ) {
    addCollaborators(
      projectID: $projectID
      collaborators: $collaborators
      message: $message
      disableNotifications: $disableNotifications
    ) {
      ...commonCollaboratorFields
    }
  }
  ${commonCollaboratorFields}
`;

export const updateCollaboratorAllTradesMutation = gql`
  mutation updateCollaboratorAllTrades(
    $projectID: UUID!
    $collaboratorId: UUID!
    $tradeSetting: Boolean!
  ) {
    setCollaboratorAllTrades(
      projectID: $projectID
      collaboratorID: $collaboratorId
      allTrades: $tradeSetting
    ) {
      ...commonCollaboratorFields
    }
  }
  ${commonCollaboratorFields}
`;

export const updateCollaboratorRoleMutation = gql`
  mutation updateCollaboratorRole(
    $projectID: UUID!
    $collaboratorID: UUID!
    $roleID: UUID!
    $tradeIDs: [UUID!]
  ) {
    setCollaboratorRole(
      projectID: $projectID
      collaboratorID: $collaboratorID
      roleID: $roleID
      tradeIDs: $tradeIDs
    ) {
      ...commonCollaboratorFields
    }
  }
  ${commonCollaboratorFields}
`;

export const updateCollaboratorResponsibilityMutation = gql`
  mutation updateCollaboratorResponsibility(
    $projectID: UUID!
    $collaboratorID: UUID!
    $responsibility: String!
  ) {
    setCollaboratorResponsibility(
      projectID: $projectID
      collaboratorID: $collaboratorID
      responsibility: $responsibility
    ) {
      ...commonCollaboratorFields
    }
  }
  ${commonCollaboratorFields}
`;

export const setCollaboratorTradesMutation = gql`
  mutation setCollaboratorTrades(
    $allTrades: Boolean!
    $categoryIDs: [UUID!]!
    $collaboratorID: UUID!
    $projectID: UUID!
  ) {
    setCollaboratorTrades(
      allTrades: $allTrades
      categoryIDs: $categoryIDs
      collaboratorID: $collaboratorID
      projectID: $projectID
    )
  }
`;

export const deleteCollaboratorMutation = gql`
  mutation deleteCollaborator($projectID: UUID!, $collaboratorId: UUID!) {
    removeCollaborator(projectID: $projectID, collaborator: $collaboratorId)
  }
`;

export const addCollaboratorTradesMutation = gql`
  mutation addCollaboratorTrades(
    $projectID: UUID!
    $collaboratorID: UUID!
    $categoryIDs: [UUID!]!
  ) {
    addTrades(projectID: $projectID, collaboratorID: $collaboratorID, categoryIDs: $categoryIDs) {
      ...commonCollaboratorFields
    }
  }
  ${commonCollaboratorFields}
`;

export const removeCollaboratorTradesMutation = gql`
  mutation removeCollaboratorTrades(
    $projectID: UUID!
    $collaboratorID: UUID!
    $categoryIDs: [UUID!]!
  ) {
    removeTrades(
      projectID: $projectID
      collaboratorID: $collaboratorID
      categoryIDs: $categoryIDs
    ) {
      ...commonCollaboratorFields
    }
  }
  ${commonCollaboratorFields}
`;

export const setProjectLeadMutation = gql`
  mutation SetProjectLead($projectID: UUID!, $userID: UUID!) {
    setProjectLead(projectID: $projectID, userID: $userID) {
      updated {
        id
        isProjectLead
      }
    }
  }
`;

export const setProjectsLeadMutation = gql`
  mutation SetProjectsLead($projectIDs: [UUID!]!, $userID: UUID!) {
    setProjectsLead(projectIDs: $projectIDs, userID: $userID) {
      updated {
        id
        isProjectLead
      }
    }
  }
`;

export const removeProjectLeadMutation = gql`
  mutation RemoveProjectLead($projectID: UUID!, $userID: UUID!) {
    removeProjectLead(projectID: $projectID, userID: $userID) {
      updated {
        id
        isProjectLead
      }
    }
  }
`;

export const removeProjectsLeadMutation = gql`
  mutation RemoveProjectsLead($projectIDs: [UUID!]!) {
    removeProjectsLead(projectIDs: $projectIDs) {
      updated {
        id
        isProjectLead
      }
    }
  }
`;
