import { FC } from 'react';

import { ButtonBase, Typography } from '@material-ui/core';

import { withStyles } from '../../../theme/komodo-mui-theme';
import { GroupBy } from '../../Icons/GroupBy';
import {
  formatGroupByDisplay,
  listGroupBy,
} from '../../Milestone/MilestoneDetails/MilestoneDetailsQuantities/MilestoneDetailsQuantitiesUtils';
import NormalTooltip from '../../NormalTooltip/NormalTooltip';
import { formatUnitName } from '../../VarianceReport/VarianceModals/VarianceModalUnits/VarianceModalUnitsUtils';

import {
  GroupByOption,
  MultiGroupOrderOption,
  getTopLevelOptionsforCategorizations,
  useCategorizationsForQuantity,
} from './MultiGroupOrderCategorizationUtils';
import MultiGroupOrderStyles from './MultiGroupStyles';

type MultiGroupOrderSuggestionProps = {
  classes: Classes<typeof MultiGroupOrderStyles>;
  handleSet: (toSet: GroupByOption[]) => void;
  options: MultiGroupOrderOption[];
  quantity: Quantity;
};

const MultiGroupOrderSuggestion: FC<MultiGroupOrderSuggestionProps> = ({
  classes,
  handleSet,
  options,
  quantity,
}) => {
  const { unit } = quantity;
  const { categorizations } = useCategorizationsForQuantity(quantity);
  // TODO: How to unify this with the generation of cat-level options?
  const groupBys = getTopLevelOptionsforCategorizations(categorizations, options);

  return (
    <ButtonBase className={classes.suggestion} onClick={() => handleSet(groupBys)}>
      <Typography variant="caption">{formatUnitName(unit)}</Typography>
      <div className={classes.flex}>
        <NormalTooltip title={listGroupBy(categorizations)}>
          <GroupBy />
        </NormalTooltip>
        <Typography className={classes.suggestionText}>
          {formatGroupByDisplay(categorizations)}
        </Typography>
      </div>
    </ButtonBase>
  );
};

export default withStyles(MultiGroupOrderStyles)(MultiGroupOrderSuggestion);
