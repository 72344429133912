//* * Pattern definition for filling in the Contingencies chart area */
export default function ContingenciesFillPattern({
  fill,
  fillBgn,
  id,
}: {
  fill: string;
  fillBgn: string;
  id: string;
}) {
  return (
    <defs>
      <pattern height="4" id={id} patternUnits="userSpaceOnUse" width="4">
        <rect className={fillBgn} height="4" width="4" />
        <rect className={fill} height="1" width="1" />
        <rect className={fill} height="1" width="1" x="1" y="1" />
        <rect className={fill} height="1" width="1" x="2" y="2" />
        <rect className={fill} height="1" width="1" x="3" y="3" />
      </pattern>
    </defs>
  );
}
