import { useState } from 'react';

import {
  ItemShareEvent,
  ItemShareEventProp,
  ItemShareKey,
  itemShareEvent,
} from '../../../../analytics/analyticsEventProperties';
import { ResourceType } from '../../../../generated/graphql';
import useCollaboratorsQuery from '../../../../hooks/useCollaboratorsQuery';
import useSendAnalytics from '../../../../hooks/useSendAnalytics';
import { getShareResourcesUpdate, hasSharedAccess } from '../../../../utilities/utilities';
import { ShareDialog, UsersTableFilter } from '../../../dragon-scales';
import { filterActiveCollaborators } from '../../../dragon-scales/Share/ShareDialog/utils';
import { DialogContent } from '../../../scales';
import useMemoWrapper from '../../../useMemoWrapper';
import { useGetSharedResources } from '../hooks/useGetShareResourceHook';
import { useShareResources } from '../hooks/useShareResourceHook';
import ShareItemBulkSummary from '../ShareItemBulkSummary/ShareItemBulkSummary';

import NoShareAccessDialog from './NoShareAccessDialog';
import { getSummaryEntries } from './utils';
import { getSelectedText } from '../../../dragon-scales/Share/utils';

export type Props = {
  // Current Collaborator ID
  collaboratorID: UUID;
  // For Bulk mode
  itemIDs: UUID[];
  // For Bulk mode
  items?: ItemsListItem[];
  isOpen?: boolean;
  onClose?: () => void;
  projectID: UUID;
};

export default function ShareDraftItemDialog(props: Props) {
  const isBulk = props.itemIDs?.length > 1;
  const analyticsKey = isBulk ? ItemShareKey.BULK : ItemShareKey.ITEM;
  const [isLoading, setIsLoading] = useState(false);

  const [newCollaboratorIDs, setNewCollaboratorIDs] = useState<UUID[]>([]);
  const [removedCollaboratorIDs, setRemovedCollaboratorIDs] = useState<UUID[]>([]);
  const [sharedCollaboratorIDs, setSharedCollaboratorIDs] = useState<UUID[]>([]);

  const { data, loading: loadingCollaborators } = useCollaboratorsQuery(props.projectID);
  const activeCollaborators = useMemoWrapper(filterActiveCollaborators, data?.collaborators || []);
  const { data: sharedResourcesData, loading: loadingSharedResources } = useGetSharedResources(
    props.itemIDs,
    ResourceType.ITEM
  );
  const sharedResources = sharedResourcesData?.getSharedResources?.resources ?? [];

  const currentUserId = activeCollaborators.find((c) => c.id === props.collaboratorID)?.user?.id;
  const hasShareAccess = useMemoWrapper(hasSharedAccess, sharedResources, currentUserId);

  const loading = loadingCollaborators || isLoading || loadingSharedResources;

  const disableShareSubmit =
    (newCollaboratorIDs.length === 0 && removedCollaboratorIDs.length === 0) || loading;

  const sendAnalytics = useSendAnalytics();
  const onShareEvent = (event: ItemShareEvent, eventProperties: ItemShareEventProp = {}) =>
    sendAnalytics(itemShareEvent(analyticsKey, event, eventProperties));
  const onFilterEvent = (eventProperties: ItemShareEventProp = {}) =>
    sendAnalytics(itemShareEvent(analyticsKey, ItemShareEvent.FILTER, eventProperties));

  const onClose = () => {
    props.onClose?.();
    onShareEvent(ItemShareEvent.CLOSE);
  };

  const shareResources = useShareResources();
  const onShare = () => {
    if (disableShareSubmit) return;
    setIsLoading(true);
    const { userIDs, accessLevels } = getShareResourcesUpdate(
      newCollaboratorIDs,
      removedCollaboratorIDs,
      activeCollaborators
    );
    if (!userIDs.length) return;
    const onSuccess = () => {
      setIsLoading(false);
      props.onClose?.();
    };
    const onFail = () => {
      setIsLoading(false);
    };
    shareResources(ResourceType.ITEM, props.itemIDs, userIDs, accessLevels, onSuccess, onFail);
    const addCount = newCollaboratorIDs.length;
    if (addCount) onShareEvent(ItemShareEvent.ADD, { addCount });
    const removeCount = removedCollaboratorIDs.length;
    if (removeCount) onShareEvent(ItemShareEvent.REMOVE, { removeCount });
    onShareEvent(ItemShareEvent.SHARE_CLICK);
  };

  const header = (
    <ShareItemBulkSummary
      entries={useMemoWrapper(getSummaryEntries, props.items, sharedResources)}
      onClick={() => onShareEvent(ItemShareEvent.VIEW_OPEN_CLOSE)}
    />
  );

  if (!hasShareAccess && !loading) return <NoShareAccessDialog onClose={onClose} />;

  if (!sharedResourcesData) return null;

  const { selectedText, summaryText } = getSelectedText(
    sharedCollaboratorIDs,
    newCollaboratorIDs,
    removedCollaboratorIDs
  );
  return (
    <ShareDialog
      {...props}
      isOpen
      isShareDisabled={disableShareSubmit}
      onClose={onClose}
      onShare={onShare}
      selectedText={selectedText}
      shareLabel="Share"
      summaryText={summaryText}
      title={`Share Draft ${isBulk ? 'Items' : 'Item'}`}
    >
      <DialogContent className="flex h-full flex-col gap-2 pb-0">
        {isBulk && header}
        <UsersTableFilter
          collaboratorID={props.collaboratorID}
          // Data update callbacks
          onCollaboratorNewIDs={(ids) => setNewCollaboratorIDs(ids)}
          onCollaboratorRemovedIDs={(ids) => setRemovedCollaboratorIDs(ids)}
          onCollaboratorSharedIDs={(ids) => setSharedCollaboratorIDs(ids)}
          // For Analytics:
          onCompany={(company) => onFilterEvent({ filterCompany: company })}
          onOpenCheckboxTooltip={() => onShareEvent(ItemShareEvent.INDETERMINATE_TOOLTIP)}
          onRole={(role) => onFilterEvent({ filterRole: role })}
          onSearch={(term) => onFilterEvent({ filterSearch: term })}
          // Current project ID
          projectID={props.projectID}
          sharedResources={sharedResources}
        />
      </DialogContent>
    </ShareDialog>
  );
}
