/* eslint-disable no-param-reassign */
import * as clipboardy from 'clipboardy';

import { isAccordionVariant } from '../../../actions/gridAnalytics';
import { ItemEstimateInput } from '../../../generated/graphql';
import { setToast } from '../../../hooks/useToastParametersLocalQuery';
import { reorderLine } from '../hooks/estimateMutation';
import { reorderMarkup } from '../hooks/markupMutation';
import { startCellWidth } from '../style/styleConstants';
import {
  CellData,
  CellState,
  CreationMethod,
  EstimateGridState,
  GenericGridState,
  GridData,
  GridVariant,
  IDMapping,
  MarkupGridState,
  Position,
} from '../types';
import {
  compareValues,
  deserializeClipboardCell,
  emptyCellValue,
  isActivatableCell,
  serializeAPICell,
  serializeClipboardCell,
} from '../utilities/cell';
import { splitByTabs } from '../utilities/copyPaste';
import { addColumnsErrors } from '../utilities/data';
import { getOrderingData } from '../utilities/drag';
import { reorderedRowData } from '../utilities/reorder';
import {
  calcLineHeight,
  calcPrefixSums,
  getColumnWidths,
  isVariableHeight,
} from '../utilities/size';

import {
  getCellData,
  getCellValue,
  isRendered,
  newSelectingState,
  setSelectionRange,
} from './selecting';
import {
  adjustToNewTableWidth,
  hasScrollBar,
  measureHeight,
  newSizingState,
  normalizeRectangle,
  scrollBarWidth,
  tableCellWidth,
  updateWidths,
} from './sizing';

const emptyCellState: CellState = {
  value: { string: '', formula: '', formulaDisplay: [] },
  error: '',
  selected: false,
  borders: {
    top: true,
    bottom: true,
    left: true,
    right: true,
  },
  rangeHead: false,
  update: () => {},
};

export const newGenericGridState = (
  data: GridData,
  maxHeight: number,
  maxWidth: number | undefined,
  updateCostReports: () => void,
  linesReadOnly: boolean,
  isItem: boolean,
  variant?: GridVariant,
  itemEstimateInput?: ItemEstimateInput
): GenericGridState => {
  const isAccordion = !!variant && isAccordionVariant(variant);
  const sizingState = newSizingState(data, maxHeight, maxWidth, undefined, isAccordion);
  const hasLines = !!data.lines[0];
  const cellData: ((CellData[] | null) | null)[] = hasLines
    ? data.lines.map((line) => newCellRow(data, line))
    : Array(data.lines.length).fill(null);
  const selectingState = newSelectingState(cellData);
  const orderingData = hasLines
    ? data.lines.map(getOrderingData)
    : Array(data.lines.length).fill(undefined);
  const mutationHistory: IndexedGridCell[][] = [];
  const indexMap: IDMapping = {};
  if (hasLines) {
    for (let i = 0; i < data.lines.length; i += 1) {
      const line = data.lines[i];
      indexMap[line.id] = i;
    }
  }
  for (let j = 0; j < data.columns.length; j += 1) {
    const field = data.columns[j];
    indexMap[field.id] = j;
  }
  return {
    ...sizingState,
    ...selectingState,
    linesReadOnly,
    isItem,
    currentlyReorderingRow: -1,
    indexMap,
    orderingData,
    mutationHistory,
    linesCallParams: { offset: -1, limit: -1, time: 0 },
    updateCostReports,
    updateHeader: () => {},
    variant,
    itemEstimateInput,
  };
};

// Initialize a CellData object from the initial estimate. This will later be mutated
// by grid edits (particularly, update will be set) but these are all reasonable defaults
// for an initial paint.
export const newCellState = (
  type: string,
  cell: { value?: GridCellValue; error?: string }
): CellState => {
  const value: GridCellValue = cell?.value || emptyCellValue(type);
  const error = cell?.error || '';
  // TODO: sometimes `value` has an extra `__resolveType` on it that is just
  // dead weight. We can consider a copy here.
  return {
    value,
    error,
    selected: false,
    borders: { top: false, left: false, right: false, bottom: false },
    rangeHead: false,
    update: () => {},
  };
};

export const newCellRow = (data: GridData, line: GridLine) => {
  const rows: CellData[] = [];
  data.columns.forEach((col, j) => {
    const cell = line.cells[j];
    if (!cell) return;
    const value: GridCellValue | undefined = 'value' in cell && cell.value ? cell.value : undefined;
    rows.push({
      data: newCellState(col.type, { value, error: cell.error || undefined }),
      dom: null,
    });
  });
  return rows;
};

// Updates the actual cellData values after mutateEstimate() does its thing. The cells
// here are received directly from the API response. This function is responsible for:
// - Setting the correct value
// - Triggering row resizing, if necessary
// - Triggering cell re-rendering
//
// Returns true if the cell's value changed in the data.
export const updateCellIndexed = (
  state: GenericGridState,
  i: number,
  j: number,
  newCell: GridCell
): boolean => {
  const { cellData, data, heights } = state;
  const current = getCellData(state, i, j);
  if (!current) return false;

  const currentData = current.data;
  const newValue = newCell.value;

  const { type } = data.columns[j];
  const diffValue = newValue && !compareValues(type, currentData.value, newValue);
  if (diffValue) {
    if (isVariableHeight(type)) {
      const newHeight = calcLineHeight(
        data,
        (cellData[i] ?? []).map((c, k) => (k === j ? newValue : c.data.value)),
        (s, i) => measureHeight(state, s, i)
      );
      if (newHeight !== heights[i]) {
        state.updateRowHeights = true;
        state.heights[i] = newHeight;
      }
    }
  }
  // Always update any new error to return from the backend.
  currentData.error = newCell.error || '';
  if (newValue) {
    setLineCell(state, i, j, newValue, newCell.error || '');
    currentData.value = newValue;
  }
  if (isRendered(state, i)) {
    currentData.update();
  }
  return diffValue || false;
};

export const updateCell = (state: GenericGridState, cell: IndexedGridCell): [boolean, number] => {
  const { indexMap } = state;
  const i = indexMap[cell.line];
  const j = indexMap[cell.field];
  const diffValue = updateCellIndexed(state, i, j, cell);
  // Construct return values for analytics.
  return [diffValue, j];
};

// Both estimate and markup grids have this property.
export const updateSubtotal = (
  state: {
    subtotal: CellData;
    updateHeader: () => void;
  },
  newTotal: number
) => {
  state.subtotal.data.value = {
    string: String(newTotal),
    formula: '',
    formulaDisplay: [],
  };
  state.subtotal.data.update(); // update the footer subtotal
  if (state.updateHeader) state.updateHeader();
};

export const updateTableWidth = (state: GenericGridState) => {
  const hasCheckbox = !state.data.linesReadOnly;
  updateWidths(
    state,
    getColumnWidths(
      state.data,
      tableCellWidth(state.overallWidth, state.data.lines.length, hasScrollBar(state), hasCheckbox),
      scrollBarWidth(state)
    )
  );
};

export const isReorderingRow = (state: GenericGridState, row: number) =>
  state.currentlyReorderingRow === row;

// TODO: Prevent other actions like editing happening when a row is dragged -
// currently the drag a handle, start reordering, and then press enter,
// and weirdness will occur.
export const startReorderingRow = (state: GenericGridState, row: number) => {
  state.currentlyReorderingRow = row;
  // Propagate the drag handle all the way around
  if (state.rowUpdaters[row]) {
    state.rowUpdaters[row]();
  }
};

export const isCellActivatable = (state: GenericGridState, p: Position) => {
  const { data } = state;
  if (p.column < 0 || p.column >= data.columns.length) return false;
  return isActivatableCell(data.columns[p.column].type);
};

// Copy tab-separated values corresponding to the selection range.
// This will use the upper-left corner of the selection rectangle as the start,
// always, even if the range start is at the bottom/right.
export const copyToClipboard = (state: GenericGridState, start: Position, end: Position) => {
  const {
    data: { columns },
  } = state;

  // TODO: we should test this function in the presence of cells that have tabs and newlines
  // within them, and optionally do processing on copying to replace those characters (and then
  // restore their values on paste). We should look into how sheets, excel, airtable deal with this
  // in order to maintain compatibility.
  const [s, e] = normalizeRectangle(state, start, end);
  const strings = [];
  // reset the category map
  for (let i = s.row; i <= e.row; i += 1) {
    const row = [];
    for (let j = s.column; j <= e.column; j += 1) {
      const value = getCellValue(state, i, j);
      if (value) {
        row.push(serializeClipboardCell(columns[j].type, value));
      }
    }
    strings.push(row.join('\t'));
  }
  const copyString = strings.join('\n');
  // clipboardy works with cypress so we're using that rather than navigator
  // to manage the clipboard here.
  clipboardy.write(copyString);
};

// Get the value we should send to the backend for a given cell stored as part of history.
export const getCellAPIValue = (state: GenericGridState, value: IndexedGridCell): string => {
  const { data, indexMap } = state;
  const { type } = data.columns[indexMap[value.field]];
  return serializeAPICell(type, value.value);
};

type MutateDataFn<T> = (
  state: T,
  start: Position,
  end: Position,
  values: (GridCellValue | undefined)[][]
) => void;

type AddLinesFn<T> = (
  state: T,
  method: CreationMethod,
  number: number,
  inputs: (GridCellValue | undefined)[][]
) => void;

export function asynchronousPaste<T extends GenericGridState>(
  state: T,
  start: Position,
  mutateData: MutateDataFn<T>,
  addLines: AddLinesFn<T>,
  estimateTerm?: string
) {
  withParsedPaste(state, start, (end, numRows, parsedRows) => {
    if (estimateTerm && parsedRows.length > 200) {
      setToast({
        message: `Need a mirror image of a previous milestone? Try going to “Replace ${estimateTerm} > Copy from Existing Milestone” to ensure accuracy`,
      });
    }
    // This is required to remove NAN, undefined lines at the end which delete existing data
    // and are 0
    // In addition, since we are removing rows from parsedRows, we need to update the end.row number
    if (parsedRows && parsedRows.length > 0) {
      const lastRow = parsedRows[parsedRows.length - 1];
      const lastCell = Array.isArray(lastRow) ? lastRow[0] : lastRow;
      const search = lastCell && 'search' in lastCell ? lastCell.search : undefined;
      const string = lastCell && 'string' in lastCell ? lastCell.string : undefined;
      if (search === '\u0000' || string === '\u0000' || string === '') {
        parsedRows.pop();
        end.row -= 1;
      }
    }
    // If we're creating new lines, we use two calls: one to edit the
    // lines we already have, and one to add new ones with the new values.
    // This forces the table to redraw potentially twice: once in mutating
    // the estimate to resize row heights, and again when adding lines in
    // order to display the new ones. Only rows that are affected should
    // end up redrawing. Note that this is asynchronous, and the second request
    // almost certainly fires before the table actually gets updated from the first.
    if (end.row >= numRows) {
      const endOfGridPosition = { ...end, row: numRows - 1 };
      mutateData(state, start, endOfGridPosition, parsedRows);
      const newLinesAdded = end.row - numRows + 1;
      const pastedInNewLines = parsedRows.slice(parsedRows.length - newLinesAdded);
      addLines(state, 'Paste', newLinesAdded, pastedInNewLines);
    } else {
      mutateData(state, start, end, parsedRows);
    }
  });
}

export function synchronousPaste<T extends GenericGridState>(
  state: T,
  start: Position,
  mutateData: MutateDataFn<T>,
  addLines: (state: T, method: CreationMethod, n: number, callback: () => void) => void
) {
  withParsedPaste(state, start, (end, numRows, parsedRows) => {
    if (end.row >= numRows) {
      const newLinesAdded = end.row - numRows + 1;
      addLines(state, 'Paste', newLinesAdded, () => {
        mutateData(state, start, end, parsedRows);
      });
    } else {
      mutateData(state, start, end, parsedRows);
    }
  });
}

export function withParsedPaste<T extends GenericGridState>(
  state: T,
  start: Position,
  action: (end: Position, numRows: number, parsedRows: (GridCellValue | undefined)[][]) => void
) {
  const { columns } = state.data;
  const [numRows, numCols] = [state.data.lines.length, state.data.columns.length];

  // This function is called immediately after CTRL+V was keydown'd, so...
  // we can install a one-time paste handler that will get the clipboard data
  // from this event, by-passing the need to request arbitrary clipboard access
  // from the navigator API, so we don't get any blocked popups.
  const pasteListener = (event: ClipboardEvent) => {
    // https://developer.mozilla.org/en-US/docs/Web/API/Element/paste_event
    const text = event.clipboardData?.getData('text') || '';
    event.preventDefault();
    event.stopPropagation();

    const pastedRows: (string | undefined)[][] = splitByTabs(text);
    // Calculate the maximum width of any row of strings being pasted.
    const maxPastedRowWidth = Math.max(...pastedRows.map((s) => s.length)) || 0;
    if (pastedRows.length > 0 && maxPastedRowWidth > 0) {
      const end = {
        row: start.row + pastedRows.length - 1,
        // Truncate extra columns that would be out of bounds width-wise
        column: Math.min(start.column + maxPastedRowWidth, numCols - 1),
      };

      const targetPasteWidth = end.column - start.column;
      for (let i = 0; i < pastedRows.length; i += 1) {
        const pastedRow = pastedRows[i];
        if (pastedRow.length < targetPasteWidth) {
          pastedRow.push(...Array(targetPasteWidth - pastedRow.length).fill(undefined));
        }
        if (pastedRow.length > targetPasteWidth) {
          pastedRows[i] = pastedRow.slice(0, targetPasteWidth + 1);
        }
      }

      const parsedRows: (GridCellValue | undefined)[][] = pastedRows.map((row, i) =>
        row.map(
          (value, j) =>
            (value !== undefined &&
              deserializeClipboardCell(
                columns[start.column + j].type,
                value,
                start.column + j,
                start.row + i,
                state
              )) ||
            undefined
        )
      );

      action(end, numRows, parsedRows);
      document.body.removeEventListener('paste', pasteListener);
    }
  };
  document.body.addEventListener('paste', pasteListener);
}

// Wrap any update to the grid in this function, and it will automatically
// handle any resizing for row heights or scrolling that needs to happen.
// Works great for adding, removing, and editing rows.
export const withGridReflow = (state: GenericGridState, action: () => void) => {
  const oldScrollbar = hasScrollBar(state);
  const oldStartCellWidth = startCellWidth(state.data.lines.length);
  const oldNumRows = state.data.lines.length;
  const oldNumCols = state.data.columns.length;

  action();
  const { updateRowHeights, updateTable, heights, data } = state;
  let willUpdateTable = false;
  const newNumRows = state.data.lines.length;
  const newNumCols = state.data.columns.length;

  // First, check if 'action' set 'updateRowHeights' to true -- if so,
  // we have a change we need to make to reflow the row heights. We
  // also need to do it if rows or columns changed, since those mean that
  // there is guaranteed to be a new layout.
  if (updateRowHeights || oldNumRows > newNumRows || oldNumCols !== newNumCols) {
    state.rowTotals = calcPrefixSums(heights);
    willUpdateTable = true;
    state.updateRowHeights = false;
  }

  // Now that the heights have been updated, we can recompute whether
  // the grid, with its new height + width, has a scrollbar.
  const newScrollbar = hasScrollBar(state);
  const newStartcellWidth = startCellWidth(data.lines.length);

  // Did we cross a threshold where the line number cell needs to become wider?
  if (oldStartCellWidth !== newStartcellWidth) {
    adjustToNewTableWidth(state);
    willUpdateTable = true;
  } else if (oldScrollbar !== newScrollbar) {
    updateTableWidth(state);
    willUpdateTable = true;
  }

  // Render any updates.
  if (willUpdateTable) updateTable();
  return willUpdateTable;
};

export const addLinesToTable = (
  state: GenericGridState,
  lines: GridLine[],
  postprocess?: () => void
) => {
  const { data, indexMap, orderingData, cellData, heights, rowTotals, updateTable } = state;

  const updated = withGridReflow(state, () => {
    // update our existing data locally to patch it back in and trigger a re-render.
    // eslint-disable-next-line no-restricted-syntax
    for (const line of lines) {
      const newRow = newCellRow(data, line);
      data.lines.push(line);
      indexMap[line.id] = cellData.length;
      cellData.push(newRow);
      orderingData.push(getOrderingData(line));

      const values: (GridCellValue | undefined)[] = [];
      line.cells.forEach((c) => (c.value ? values.push(c.value) : values.push(undefined)));

      const lineHeight = calcLineHeight(data, values, (s, i) => measureHeight(state, s, i));
      heights.push(lineHeight);
      rowTotals.push(rowTotals[rowTotals.length - 1] + lineHeight);
    }
    if (postprocess) postprocess();
    state.isRowSelectedArr.push(false);
  });
  if (!updated) updateTable();
};

export const deleteLineFromTable = (state: GenericGridState, index: number) =>
  deleteLinesFromTable(state, index, 1);

export const deleteLinesFromTable = (state: GenericGridState, index: number, numLines: number) => {
  const {
    data: { lines },
    cellData,
    orderingData,
    heights,
    indexMap,
    isRowSelectedArr,
  } = state;

  withGridReflow(state, () => {
    // Reset the selection.
    setSelectionRange(state, { row: -1, column: -1 }, { row: -1, column: -1 });
    for (let i = index + numLines; i < lines.length; i += 1) {
      const line = lines[i];
      if (line) {
        indexMap[line.id] -= numLines;
      }
    }
    cellData.splice(index, numLines);
    orderingData.splice(index, numLines);
    lines.splice(index, numLines);
    heights.splice(index, numLines);
    isRowSelectedArr.splice(index, numLines);
  });
};

export const scrollToTop = (state: GenericGridState) => {
  const { bodyRef } = state;
  if (bodyRef && bodyRef.current) {
    if (hasScrollBar(state)) {
      bodyRef.current.scrollTo({ top: 0, behavior: 'auto' });
    }
  }
};

export const scrollToBottom = (state: GenericGridState) => {
  const { bodyRef } = state;
  if (bodyRef && bodyRef.current) {
    if (hasScrollBar(state)) {
      const { maxHeight, rowTotals } = state;
      const bottom = rowTotals[rowTotals.length - 1];
      const top = bottom - maxHeight;
      bodyRef.current.scrollTo({ top, behavior: 'auto' });
    }
  }
};

// Scrolls to place the indexed row in the middle of the view.
export const scrollToRow = (state: GenericGridState, i: number) => {
  const { bodyRef } = state;
  if (
    bodyRef &&
    bodyRef.current &&
    hasScrollBar(state) &&
    (i <= state.visibleRows.start || i >= state.visibleRows.end)
  ) {
    const targetTop = Math.round(state.rowTotals[i] - state.heights[i] / 2 - state.maxHeight / 2);
    const top = Math.max(targetTop, 0);
    bodyRef.current.scrollTo({ top, behavior: 'auto' });
  }
};

// Scrolls to place the indexed column in the view.
export const scrollToColumn = (state: GenericGridState, j: number) => {
  const { bodyRef } = state;
  if (bodyRef && bodyRef.current) {
    const width = j === 0 ? 0 : [...state.widths].slice(0, j - 1).reduce((a, b) => a + b, 0);
    bodyRef.current.scrollTo({ left: width, behavior: 'auto' });
  }
};

export function moveFocus(state: GenericGridState, toCell: Position) {
  // move the browser focus to follow selection range
  const refs = getCellData(state, toCell.row, toCell.column);
  if (refs && refs.dom && refs.dom.current) {
    refs.dom.current.focus();
  }
}

export const setLineCell = (
  state: GenericGridState,
  i: number,
  j: number,
  value: GridCellValue,
  error: string
) => {
  const { data } = state;
  const cell = data.lines[i] && data.lines[i].cells[j];
  if (cell) {
    cell.error = error;
    cell.value = value;
  }
};

export const setLineCellValue = (
  state: GenericGridState,
  i: number,
  j: number,
  value: GridCellValue
) => {
  const { data } = state;
  const cell = data.lines[i] && data.lines[i].cells[j];
  if (cell) {
    cell.value = value;
  }
};

// Given that this works on mouse drag, they should almost always be
// contiguous unless the user is really yankin that bad boy around
export const shiftRow = (
  state: EstimateGridState | MarkupGridState,
  prev: number,
  next: number
) => {
  const { data, orderingData, cellData, heights, rowTotals, renderedRows, updateRow } = state;
  // We want to switch the row data, the heights, and update the totals
  // accordingly without actually having to recalculate all the info.
  if (
    prev === next ||
    prev < 0 ||
    prev >= data.lines.length ||
    next < 0 ||
    next >= data.lines.length
  )
    return;

  state.mutationHistory = [];
  const increment = prev < next ? 1 : -1;
  // Save the data of the row we're moving

  const movedRowData: CellState[] = data.columns.map((_, j) => {
    const lineOfCellsPrev = cellData[prev];
    const lineOfCellsNext = cellData[next];
    if (!lineOfCellsPrev || !lineOfCellsNext) {
      return emptyCellState;
    }
    return {
      ...lineOfCellsPrev[j].data,
      update: lineOfCellsNext[j].data.update,
    };
  });

  const movedRowOrdering = orderingData[prev];
  const movedRowHeight = heights[prev];
  // Shift all the rows in-between up or down one, depending
  for (let i = prev; i !== next; i += increment) {
    orderingData[i] = orderingData[i + increment];
    heights[i] = heights[i + increment];
    for (let j = 0; j < data.columns.length; j += 1) {
      const lineOfCells = cellData[i];
      const lineOfCellsInc = cellData[i + increment];
      if (lineOfCells && lineOfCellsInc) {
        const [prevCell, nextCell] = [lineOfCells[j], lineOfCellsInc[j]];
        prevCell.data = { ...nextCell.data, update: prevCell.data.update };
      }
    }
  }

  // Update the target row data with the original saved state
  orderingData[next] = movedRowOrdering;
  heights[next] = movedRowHeight;
  for (let j = 0; j < data.columns.length; j += 1) {
    const cellDataNext = cellData[next];
    if (cellDataNext) cellDataNext[j].data = movedRowData[j];
  }

  // Recalculate derived state (rowTotals) from changed values
  let [start, end] = prev < next ? [prev, next] : [next, prev];
  for (let i = start; i <= end; i += 1) {
    rowTotals[i] = (rowTotals[i - 1] || 0) + heights[i];
  }

  // Manually update the cells that need it, within bounds
  // to avoid updating any unmounted components.
  state.currentlyReorderingRow = next;
  start = Math.max(start, renderedRows.start);
  end = Math.min(end, renderedRows.end);
  for (let i = start; i <= end; i += 1) {
    updateRow(i);
  }
};

const onSuccess = (
  analytics: () => void,
  data: GridData,
  indexMap: IDMapping,
  original: number,
  next: number,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  result: any,
  state: EstimateGridState | MarkupGridState
) => {
  if (result.data) {
    // This succeeded, all good -- let's reorder everything within the estimate itself
    const increment = original < next ? 1 : -1;
    const originalLine = data.lines[original];
    for (let i = original; i !== next; i += increment) {
      const nextLine = data.lines[i + increment];
      data.lines[i] = nextLine;
      indexMap[nextLine.id] = i;
    }
    data.lines[next] = originalLine;
    indexMap[originalLine.id] = next;
    // Update errors for columns
    addColumnsErrors(state.data.columns, state.data.lines.length);
  } else {
    // Revert the drag we made in the cell data
    shiftRow(state, next, original);
  }
  const lastRow = state.currentlyReorderingRow;
  state.currentlyReorderingRow = -1;
  // Depopulate the drag icon
  state.updateRow(lastRow);
  analytics();
};

export const finishReordering = (
  state: EstimateGridState | MarkupGridState,
  original: number,
  next: number
) => {
  const {
    projectID,
    estimateID,
    data,
    orderingData,
    indexMap,
    analytics: { reorderLineAnalytics, reorderMarkupAnalytics },
  } = state;
  // TODO: maybe pass this a copy and only set it to our real orderingData
  // if the result succeeded?
  const reorderInput = reorderedRowData(orderingData, next);
  if (reorderInput) {
    if ('replaceMarkups' in state && !!state.replaceMarkups) {
      reorderLine(projectID, estimateID, reorderInput, (result) => {
        onSuccess(reorderLineAnalytics, data, indexMap, original, next, result, state);
      });
    } else {
      reorderMarkup(projectID, estimateID, reorderInput, (result) => {
        onSuccess(reorderMarkupAnalytics, data, indexMap, original, next, result, state);
      });
    }
  }
};
