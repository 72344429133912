import { useMemo } from 'react';

import { MultiSelection, SelectEntry, SelectEntryID } from '../types';

type Args = {
  entries: SelectEntry[];
  onFilterEntries?: (
    searchString: string,
    entry: SelectEntry,
    index: number,
    entries: SelectEntry[]
  ) => boolean;
  rootEntryID?: SelectEntryID | null;
  searchString?: string;
  selectedEntryIDs?: MultiSelection;
};

/**
 * This function provides two ease-of-use functionalities for developers using the Select
 * and MultiSelect functions.
 *
 * 1. It backfills the value of `hasChildren` for ease-of-use by developers if they want to
 * rely only on `parentID`.
 * 2. It pulls out the IDs of disabled entries so that it can be passed into React Aria easily.
 *
 * @param entries A flat array of SelectEntry objects.
 *
 * @returns  An object containing:
 *   `entries` a flat array of SelectEntry objects with the `hasChildren` property populated.
 *   `disabledKeys` a flat array of SelectEntryIDs indicating which entries are disabled
 */
export default function useEntries({ entries: inputEntries }: Args) {
  return useMemo(() => {
    const parentIDs = new Set<SelectEntryID>();
    const disabledKeys: SelectEntryID[] = [];

    inputEntries.forEach((entry) => {
      if (entry.isSection) {
        disabledKeys.push(...entry.entries.filter((e) => e.disabled).map((e) => e.id));
      } else if (entry.disabled) {
        disabledKeys.push(entry.id);
      }

      // If this entry has a parent ID, track that the parent has children.
      if (entry.parentID) {
        parentIDs.add(entry.parentID);
      }
    });

    const entries: SelectEntry[] = inputEntries.map((e) => ({
      ...e,
      hasChildren: e.hasChildren ?? parentIDs.has(e.id),
    }));

    return { disabledKeys, entries };
  }, [inputEntries]);
}
