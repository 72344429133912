import costTypesIllustration from '../EstimateTotalTypeSelect/CostTypesIllustration.svg';
import totalIllustration from '../EstimateTotalTypeSelect/TotalIllustration.svg';
import unitIllustration from '../EstimateTotalTypeSelect/UnitIllustration.svg';

export enum ExcelTemplate {
  TOTALS_ONLY = 'TOTALS_ONLY',
  QUANTIY_UNIT_UNIT_PRICE = 'QUANTITY_UNIT_UNIT_PRICE',
  BREAKDOWN_BY_COST_TYPE = 'BREAKDOWN_BY_COSY_TYPE',
}

export const ExcelTemplateInfo = {
  [ExcelTemplate.TOTALS_ONLY]: {
    image: totalIllustration,
    file: '/joinExcelTemplates/totalsOnly.xlsx',
    downloadName: 'Join Blank Import Template - Totals Only',
    title: 'Spreadsheet Template- Totals Only',
    subTitle: `Don't pull in cost types. Your estimate will have all the categorizations you selected,
    a Description, and a Total column with the total of each line item.`,
  },
  [ExcelTemplate.QUANTIY_UNIT_UNIT_PRICE]: {
    image: unitIllustration,
    file: '/joinExcelTemplates/quantityUnitUnitPrice.xlsx',
    downloadName: 'Join Blank Import Template - Quantity, Unit, Unit Price',
    title: 'Spreadsheet Template- Quantity, Unit, Unit Price',
    subTitle: `Show Quantity and Unit Price for each line. Don't pull in cost type columns, but do
    include the takeoff quantity and aggregated unit price on each line in addition to a line
    total.`,
  },
  [ExcelTemplate.BREAKDOWN_BY_COST_TYPE]: {
    image: costTypesIllustration,
    file: '/joinExcelTemplates/breakDownByCostType.xlsx',
    downloadName: 'Join Blank Import Template - Break Down by Cost Type',
    title: 'Spreadsheet Template- Break Down by Cost Type',
    subTitle: `Your estimate will have columns showing the Quantity and Unit for each line, and a pair of
    columns showing the $/Unit and subtotal for each of the Labor, Equipment, Material,
    Subcontractor, Other, and User Costs as well as a grand total for each line item.`,
  },
};

export const excelTemplates: ExcelTemplate[] = Object.values(ExcelTemplate);
