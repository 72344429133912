import { FC } from 'react';

import {
  execDashboardEvent,
  execDashboardEventTypes,
} from '../../../analytics/analyticsEventProperties';
import { PieChartComponentOrientation } from '../../../api/gqlEnums';
import { ITEMS_BY_ASSIGNEE } from '../../../constants';
import { useDebounceFn } from '../../../hooks';
import useSendAnalytics from '../../../hooks/useSendAnalytics';
import { withStyles } from '../../../theme/komodo-mui-theme';
import ChartsPieChart from '../../Charts/ChartsPieChart/ChartsPieChart';
import {
  ItemsOwnership,
  NUM_ITEMS,
  formatItemsOwnershipLegendData,
  formatItemsOwnershipPieChartData,
  getOtherItemsOwnershipData,
  groupItemsOwnership,
} from '../ExecutiveDashboardUtils';

import ExecutiveDashboardItemsOwnershipStyles from './ExecutiveDashboardItemsOwnershipStyles';
// analytics

type ExecutiveDashboardItemsOwnershipProps = {
  classes: Classes<typeof ExecutiveDashboardItemsOwnershipStyles>;
  itemsOwnership: ItemsOwnership;
};

const ExecutiveDashboardItemsOwnership: FC<ExecutiveDashboardItemsOwnershipProps> = ({
  classes,
  itemsOwnership,
}) => {
  const sendAnalytics = useSendAnalytics();
  const MILLISECONDS = 5000;
  const debouncedAnalytics = useDebounceFn(() => {
    sendAnalytics(
      execDashboardEvent(execDashboardEventTypes.EXEC_DASHBOARD_HOVER, {
        chart: {
          chartType: ITEMS_BY_ASSIGNEE,
        },
      })
    );
  }, MILLISECONDS);

  const groupedItemsOwnership = groupItemsOwnership(itemsOwnership);
  const otherItemsOwnershipData = getOtherItemsOwnershipData(itemsOwnership);

  const otherItemsOwnershipChartData = formatItemsOwnershipPieChartData(otherItemsOwnershipData);
  const itemsOwnershipChartData = formatItemsOwnershipPieChartData(
    groupedItemsOwnership,
    otherItemsOwnershipChartData
  );

  const legendData = formatItemsOwnershipLegendData(groupedItemsOwnership);

  return (
    <div className={classes.flexContainer}>
      <ChartsPieChart
        data={itemsOwnershipChartData}
        hintCaption={NUM_ITEMS}
        legendData={legendData}
        onMouseOver={debouncedAnalytics}
        variant={PieChartComponentOrientation.VERTICAL}
      />
    </div>
  );
};

export default withStyles(ExecutiveDashboardItemsOwnershipStyles)(ExecutiveDashboardItemsOwnership);
