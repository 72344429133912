import { getTodayAtUtcNoon } from '../../../../utilities/dates';
import useMemoWrapper from '../../../useMemoWrapper';
import { computeItemDecidedDate } from '../../utils';

import { useItemsTimelineWithEventsQuery } from './useItemsTimelineWithEventsQuery';

export function useItems(projectID: UUID | undefined, milestoneID: UUID | undefined) {
  const itemsQueryResult = useItemsTimelineWithEventsQuery(milestoneID ?? null, projectID);
  const itemsList = itemsQueryResult.data?.itemsTimeline?.items ?? [];
  const today = getTodayAtUtcNoon();
  const items = useMemoWrapper(
    computeItemDecidedDate,
    itemsQueryResult.loading ? [] : itemsList,
    today,
    itemsQueryResult.data?.costTrendlineEvents?.events ?? []
  );
  return {
    items,
    loading: itemsQueryResult.loading,
  };
}
