import { useEffect, useState } from 'react';

import { createStyles } from '@material-ui/core';

import {
  projectCompsAnalyticsEvent,
  projectCompsEventTypes,
} from '../../../../analytics/analyticsEventProperties';
import { DIALOG_CANCEL } from '../../../../constants';
import useSendAnalytics from '../../../../hooks/useSendAnalytics';
import { withStyles } from '../../../../theme/komodo-mui-theme';
import JoinDialog from '../../../Dialogs/JoinDialog/JoinDialog';
import NavigationTabs, { TabOption } from '../../../Navigation/NavigationTabs';
import { useProjectCompsSetInputUpdateFunctions } from '../../ProjectCompsSetInputStore/ProjectCompsSetInputUpdaters';

import {
  HiddenCostsLine,
  categoryDescriptor,
  getHiddenCategoryLines,
  getHiddenMarkupLines,
} from './HiddenCostsDialogUtils';
import HiddenCostsLineSelection from './HiddenCostsLineSelection';

const HiddenCostsDialog = (props: {
  classes: Classes<typeof styles>;
  excludedCategoryContents: CategoryContentInput[];
  excludedMarkupNames: string[];
  onClose: () => void;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  open: boolean;
}) => {
  const sendAnalytics = useSendAnalytics();

  const { setExcludedLines } = useProjectCompsSetInputUpdateFunctions();
  const hasExcludedCategories = !!props.excludedCategoryContents.length;
  const hasExcludedMarkups = !!props.excludedMarkupNames.length;

  // Dialog Tabs
  enum HiddenCostsDialogTabs {
    CATEGORIES = 'CATEGORIES',
    MARKUPS = 'MARKUPS',
  }
  const [view, setView] = useState(HiddenCostsDialogTabs.CATEGORIES);
  useEffect(() => {
    if (hasExcludedCategories) setView(HiddenCostsDialogTabs.CATEGORIES);
    else setView(HiddenCostsDialogTabs.MARKUPS);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO CT-566: Fix this pls :)
  }, [hasExcludedCategories]);

  const navigationTabOptions: TabOption[] = [];
  if (hasExcludedCategories)
    navigationTabOptions.push({
      key: HiddenCostsDialogTabs.CATEGORIES,
      label: `Categories (${props.excludedCategoryContents.length})`,
      title: HiddenCostsDialogTabs.CATEGORIES,
    });
  if (hasExcludedMarkups)
    navigationTabOptions.push({
      key: HiddenCostsDialogTabs.MARKUPS,
      label: `Markup rows (${props.excludedMarkupNames.length})`,
      title: HiddenCostsDialogTabs.MARKUPS,
    });

  // Excluded Category Content
  const [categoryLines, setCategoryLines] = useState<HiddenCostsLine[]>(
    getHiddenCategoryLines(props.excludedCategoryContents)
  );
  useEffect(
    () => setCategoryLines(getHiddenCategoryLines(props.excludedCategoryContents)),
    [props.excludedCategoryContents]
  );
  const handleToggleCategoryLine = (displayName: string) => {
    setCategoryLines(
      categoryLines.map((categoryLine) => {
        if (categoryLine.displayName !== displayName) return categoryLine;
        return { ...categoryLine, isExcluded: !categoryLine.isExcluded };
      })
    );
  };
  const handleIncludeAllCategoryLines = () => {
    setCategoryLines(categoryLines.map((categoryLine) => ({ ...categoryLine, isExcluded: false })));
  };

  const categoryLinesSelection = () => (
    <HiddenCostsLineSelection
      lines={categoryLines}
      onIncludeAllLines={handleIncludeAllCategoryLines}
      onToggleLine={handleToggleCategoryLine}
    />
  );

  // Excluded Markup Content
  const [markupLines, setMarkupLines] = useState<HiddenCostsLine[]>(
    getHiddenMarkupLines(props.excludedMarkupNames)
  );
  useEffect(
    () => setMarkupLines(getHiddenMarkupLines(props.excludedMarkupNames)),
    [props.excludedMarkupNames]
  );
  const handleToggleMarkupLine = (displayName: string) => {
    setMarkupLines(
      markupLines.map((categoryLine) => {
        if (categoryLine.displayName !== displayName) return categoryLine;
        return { ...categoryLine, isExcluded: !categoryLine.isExcluded };
      })
    );
  };
  const handleIncludeAllMarkupLines = () => {
    setMarkupLines(markupLines.map((categoryLine) => ({ ...categoryLine, isExcluded: false })));
  };
  const markupsLineSelection = () => (
    <HiddenCostsLineSelection
      lines={markupLines}
      onIncludeAllLines={handleIncludeAllMarkupLines}
      onToggleLine={handleToggleMarkupLine}
    />
  );

  const canSubmit =
    categoryLines.some((categoryLine) => !categoryLine.isExcluded) ||
    markupLines.some((markupLine) => !markupLine.isExcluded);
  const onSubmit = () => {
    const updatedExcludedCategoryContents = props.excludedCategoryContents.filter(
      (categoryContent) =>
        categoryLines.some(
          (categoryLine) =>
            categoryLine.isExcluded &&
            categoryLine.displayName === categoryDescriptor(categoryContent)
        )
    );
    const updatedExcludedMarkupNames = props.excludedMarkupNames.filter((markupName) =>
      markupLines.some(
        (markupLine) => markupLine.isExcluded && markupLine.displayName === markupName
      )
    );
    setExcludedLines(updatedExcludedCategoryContents, updatedExcludedMarkupNames);
    sendAnalytics(
      projectCompsAnalyticsEvent(projectCompsEventTypes.PROJECT_COMPS_HIDDEN_COSTS_DIALOG_CTA)
    );
    props.onClose();
  };

  const tabContents: Record<string, JSX.Element> = {
    [HiddenCostsDialogTabs.CATEGORIES.toString()]: categoryLinesSelection(),
    [HiddenCostsDialogTabs.MARKUPS.toString()]: markupsLineSelection(),
  };
  const tabContent = tabContents[view];

  const { classes } = props;

  const navigationSubHeader = (
    <NavigationTabs options={navigationTabOptions} setView={setView} view={view} />
  );
  const dialogContent = <div className={classes.contentPadding}>{tabContent}</div>;

  return (
    <JoinDialog
      dialog={{
        contentComponent: dialogContent,
        contentClass: classes.contentClass,
        disabledNext: !canSubmit,
        headerText: 'Hidden Costs',
        onBack: () => props.onClose(),
        onBackText: DIALOG_CANCEL,
        onClose: () => props.onClose(),
        onNext: () => onSubmit(),
        onNextText: 'Apply',
        open: props.open,
        subHeader: navigationSubHeader,
      }}
      dynamicHeight
      index={0}
    />
  );
};
const styles = () =>
  createStyles({
    contentClass: {
      padding: 8,
    },
    contentPadding: {
      paddingLeft: 8,
      paddingRight: 8,
    },
  });

export default withStyles(styles)(HiddenCostsDialog);
