import { ComponentProps } from 'react';

import PrintViewScenariosSandbox from './PrintViewScenariosSandbox';
import { printScenarioWidth } from './PrintViewScenariosSandboxUtils';

type Props = ComponentProps<typeof PrintViewScenariosSandbox>;

export default function PrintPageScenariosSandbox(props: Props) {
  return (
    <div className="m-auto bg-background-primary" style={{ width: printScenarioWidth }}>
      <PrintViewScenariosSandbox {...props} />
    </div>
  );
}
