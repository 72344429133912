import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';

export default (theme: KomodoTheme) =>
  createStyles({
    checkbox: {
      display: 'block',
      marginLeft: 'auto',
      marginRight: 'auto',
      width: '30px',
      height: '30px',
    },
    text: {
      ...theme.typography.subtitle1,
      borderRadius: 100,
      borderStyle: 'solid',
      borderWidth: 3,
      fontWeight: 600,
      height: 40,
      lineHeight: 'unset',
      margin: 0,
      textAlign: 'center',
      width: 40,
      zIndex: 1,
    },
    previous: {
      backgroundColor: theme.palette.backgroundWhite,
      borderColor: theme.palette.accepted,
      color: theme.palette.accepted,
    },
    current: {
      backgroundColor: theme.palette.accepted,
      borderColor: theme.palette.accepted,
      color: theme.palette.backgroundWhite,
    },
    next: {
      backgroundColor: theme.palette.backgroundWhite,
      borderColor: theme.palette.joinGrey400,
      color: theme.palette.joinGrey400,
    },
  });
