import { FC } from 'react';
import * as React from 'react';

import { SearchResultType } from '../../../generated/graphql';
import { useCompanyUserQuery } from '../../../hooks/useCompanyUserQuery';
import { MountPolicy } from '../../../hooks/usePolicyOnFirstMount';
import { withStyles } from '../../../theme/komodo-mui-theme';
import NormalTooltip from '../../NormalTooltip/NormalTooltip';
import FilterSummaryText from '../../ProjectsList/FilterSummaryText';
import useSearchProjectsFilterOptionsQuery from '../../ProjectsList/hooks/useSearchProjectsFilterOptionsQuery';
import ProjectsListShowToggle, { ProjectsToggle } from '../../ProjectsList/ProjectsListShowToggle';
import {
  ProjectFilterManager,
  ProjectFilterSetSettings,
  generateFilterTooltipCopy,
} from '../../ProjectsList/ProjectsListUtils';
import ProjectsShownAndClear from '../../ProjectsList/ProjectsShownAndClear/ProjectsShownAndClear';
import { ProjectMap } from '../ExecutiveDashboardUtils';

import styles from './FilterMenuStyles';
import FilterPanel from './FilterPanel';

type FilterMenuProps = {
  classes: Classes<typeof styles>;
  counts: ProjectsCounts;
  filterManager: ProjectFilterManager;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  hideAllMy?: boolean;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  includeCompanyProjects: boolean;
  listShow: ProjectsToggle;
  projectMap: ProjectMap;
  projectOptions: string[];
  selectedProjects: string[];
  setListShow: (value: ProjectsToggle) => void;
  setSelectedProjects: React.Dispatch<React.SetStateAction<Set<string>>>;
  setSettings: ProjectFilterSetSettings;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  showAsButton?: boolean;
};

const FilterMenu: FC<FilterMenuProps> = ({
  classes,
  counts,
  filterManager,
  hideAllMy,
  includeCompanyProjects,
  listShow,
  projectMap,
  projectOptions,
  selectedProjects,
  setListShow,
  setSelectedProjects,
  setSettings,
  showAsButton,
}) => {
  const { data: { companyUser } = { companyUser: undefined }, loading: companyLoading } =
    useCompanyUserQuery(MountPolicy.SKIP_ON_MOUNT);
  const company = companyUser?.company;
  const { data: filterOptionsData } = useSearchProjectsFilterOptionsQuery(SearchResultType.ALL);
  const projectTypes = filterOptionsData?.searchProjectsFilterOptions?.projectTypes || [];

  const { filterState } = filterManager;
  const filteredCounts = {
    myProjectsCount: selectedProjects.length,
    allProjectsCount: selectedProjects.length,
  } as ProjectsCounts;

  const normalTooltipCopy = generateFilterTooltipCopy(filterState, projectTypes).map((t) => {
    return <div key={t}>{t}</div>;
  });

  return (
    <>
      <div className={classes.alignItemsCenter}>
        <ProjectsShownAndClear
          filterManager={filterManager}
          includeCompanyProjects={includeCompanyProjects}
          projectsCounts={counts}
          projectsFilteredCounts={filteredCounts}
          searchManager={{ searchTerm: '', setSearchTerm: () => {}, clearSearch: () => {} }}
          setSettings={setSettings}
          showClearFilters={false}
        />
      </div>
      <div className={classes.rightAlign}>
        <div className={classes.rightAlign}>
          <div className={classes.alignItemsCenter}>
            <NormalTooltip title={normalTooltipCopy}>
              <div>
                <FilterSummaryText filterManager={filterManager} includeLabels />
              </div>
            </NormalTooltip>
          </div>
          <div className={classes.buttonContainer}>
            <FilterPanel
              clearProjectFilter={() => {
                setSelectedProjects(new Set<string>());
              }}
              filterManager={filterManager}
              onProjectSelect={(value: string) => {
                setSelectedProjects((previousValue) => {
                  const set = new Set(previousValue);
                  if (set.has(value)) set.delete(value);
                  else set.add(value);
                  return set;
                });
              }}
              projectMap={projectMap}
              projectOptions={projectOptions}
              selectAllProjects={() => {
                setSelectedProjects(new Set<string>(projectOptions));
              }}
              selectedProjects={selectedProjects}
              setSettings={setSettings}
              showAsButton={showAsButton}
            />
          </div>
        </div>
        {!hideAllMy && (
          <div className={classes.leftAlign}>
            {!companyLoading && !!company && (
              <div className={classes.settingContainer}>
                <ProjectsListShowToggle
                  listShow={listShow}
                  projectsCounts={counts}
                  setListShow={setListShow}
                />
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default withStyles(styles)(FilterMenu);
