import { FC, useContext } from 'react';

import { Divider, Typography } from '@material-ui/core';

import { setMilestoneEstimateExpanded } from '../../analytics/analyticsEventProperties';
import { TermKey } from '../../api/gqlEnums';
import { ALL_MILESTONES, BREAKDOWNS, WITHOUT_NAME, WITH_NAME } from '../../constants';
import { CostReportColumnType } from '../../generated/graphql';
import useSendAnalytics from '../../hooks/useSendAnalytics';
import { COST_SUMMARY_ESTIMATE } from '../../tagConstants';
import { withStyles } from '../../theme/komodo-mui-theme';
import { getMarkupModeLabel, useCostMode } from '../../utilities/costMode';
import { EMPTY_COST } from '../../utilities/string';
import {
  costDescription,
  getCostReportByType,
  getCostValue,
  renderCostString,
} from '../CostReport/CostReportUtils';
import NormalTooltip from '../NormalTooltip/NormalTooltip';
import { ProjectTermStore } from '../ProjectDisplaySettings/TerminologyProvider';

import styles from './CostSummaryStyles';

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  classes: any;
  costReport: MilestoneCostReport | MilestonesCostTrendsBreakdownReport | null;
  estimateName?: string;
  variant?: string;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  rounded?: boolean;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  noPadding?: boolean;
};

const CostSummary: FC<Props> = ({
  classes,
  costReport,
  estimateName = 'No Estimate',
  noPadding = false,
  variant = WITHOUT_NAME,
  rounded = true,
}) => {
  const sendAnalytics = useSendAnalytics();
  const costMode = useCostMode();
  const t = useContext(ProjectTermStore);

  if (!costReport) return null;

  const target = getCostReportByType(costReport, CostReportColumnType.TARGET_REPORT);
  const accepted = getCostReportByType(costReport, CostReportColumnType.ACCEPTED_REPORT);
  const running = getCostReportByType(costReport, CostReportColumnType.RUNNINGTOTAL_REPORT);
  const remaining = getCostReportByType(costReport, CostReportColumnType.REMAINING_REPORT);
  const estimate = getCostReportByType(costReport, CostReportColumnType.ESTIMATE_REPORT);

  const budgetTotal = target?.range;
  const remainingTotal = remaining?.range;
  const acceptedTotal = accepted?.range;
  const runningTotal = running?.range;
  const estimateTotal = estimate?.range;

  const hasAccepted = accepted || variant !== ALL_MILESTONES;
  const hasBudget = !!Number(getCostValue(budgetTotal));
  const hasRemaining = remaining || variant !== ALL_MILESTONES;
  const hasRunningTotal = running || variant !== ALL_MILESTONES;
  const hasTarget = target || variant !== ALL_MILESTONES;
  const costFormatter = (value: Cost) =>
    renderCostString({
      cost: value,
      isExact: !rounded,
      isWide: true,
      showZero: variant !== ALL_MILESTONES,
      showCents: !rounded,
    });

  const generateTitle = (titleText: string, costImpactValue: Cost) => {
    const { exactCost, roundedCost } = costDescription(costImpactValue, true);
    return [titleText, exactCost, roundedCost].map((t) => {
      return <div key={t}>{t}</div>;
    });
  };

  return (
    <div className={`${classes.root} ${noPadding ? '' : classes.padding}`}>
      {variant === WITH_NAME && (
        <NormalTooltip
          title={`The most recent full ${getMarkupModeLabel(
            costMode.markupMode,
            t
          ).toLowerCase()} estimate for this milestone`}
        >
          <>
            <div className={classes.row}>
              <div className={`${classes.text} ${classes.row} ${classes.estimateTotal}`}>
                <Typography>{`${t.titleCase(TermKey.ESTIMATE)} Level`}</Typography>
              </div>
              <Typography
                className={`${classes.right}`}
                onClick={() => {
                  sendAnalytics(setMilestoneEstimateExpanded(true));
                }}
                variant="subheading"
              >
                {estimateName}
              </Typography>
            </div>
          </>
        </NormalTooltip>
      )}
      {variant !== BREAKDOWNS && (
        <>
          <NormalTooltip title={generateTitle('Base estimate for this milestone:', estimateTotal)}>
            <div className={classes.row}>
              <div className={`${classes.text} ${classes.row} ${classes.estimateTotal}`}>
                <Typography data-cy="sidebar-terminology-ESTIMATE">{`${t.titleCase(
                  TermKey.ESTIMATE
                )} Total`}</Typography>
              </div>
              <Typography
                className={`${classes.number} ${classes.textEmphasized}`}
                data-cy="cost-summary-estimate"
                id={COST_SUMMARY_ESTIMATE}
                onClick={() => {
                  sendAnalytics(setMilestoneEstimateExpanded(true));
                }}
              >
                {getCostValue(estimateTotal) > 0 ? costFormatter(estimateTotal) : EMPTY_COST}
              </Typography>
            </div>
          </NormalTooltip>

          {hasAccepted && (
            <NormalTooltip title={generateTitle('Value of all accepted items:', acceptedTotal)}>
              <div className={classes.row}>
                <Typography className={`${classes.acceptedTotal} ${classes.text}`}>
                  Accepted Changes
                </Typography>
                <Typography
                  className={`${classes.number} ${classes.acceptedTotal} ${classes.textEmphasized}`}
                  data-cy="cost-summary-accepted-changes"
                >
                  {costFormatter(acceptedTotal)}
                </Typography>
              </div>
            </NormalTooltip>
          )}
          <Divider className={classes.runningLine} />
        </>
      )}
      {hasRunningTotal && (
        <NormalTooltip
          title={generateTitle('Revised estimate including accepted items:', runningTotal)}
        >
          <div className={classes.row}>
            <Typography className={classes.text} data-cy="sidebar-terminology-RUNNING_TOTAL">
              {t.titleCase(TermKey.RUNNING_TOTAL)}
            </Typography>
            <Typography
              className={`${classes.number} ${classes.textEmphasized}`}
              data-cy="cost-summary-running-total"
            >
              {costFormatter(runningTotal)}
            </Typography>
          </div>
        </NormalTooltip>
      )}
      {hasBudget ? (
        <>
          {hasRemaining && (
            <NormalTooltip
              title={generateTitle('Additional changes needed to meet target:', remainingTotal)}
            >
              <div className={classes.row}>
                <Typography
                  className={`${classes.text} ${classes.remaining}`}
                  data-cy="sidebar-terminology-GAP"
                >
                  {t.titleCase(TermKey.GAP)}
                </Typography>
                <Typography
                  className={`${classes.number} ${classes.remaining} ${classes.textEmphasized}`}
                  data-cy="cost-summary-gap"
                >
                  {costFormatter(remainingTotal)}
                </Typography>
              </div>
            </NormalTooltip>
          )}
          {hasTarget && (
            <div>
              <Divider className={classes.budgetLine} />
              <NormalTooltip
                title={generateTitle(
                  `${t.rawTerm(TermKey.TARGET)} value for this milestone:`,
                  budgetTotal
                )}
              >
                <div className={classes.row}>
                  <Typography
                    className={`${classes.budgetTotal} ${classes.text}`}
                    data-cy="sidebar-terminology-TARGET"
                  >
                    {t.titleCase(TermKey.TARGET)}
                  </Typography>
                  <Typography
                    className={`${classes.number} ${classes.budgetTotal} ${classes.textEmphasized}`}
                    data-cy="cost-summary-budget"
                  >
                    {costFormatter(budgetTotal)}
                  </Typography>
                </div>
              </NormalTooltip>
            </div>
          )}
        </>
      ) : null}
    </div>
  );
};

export default withStyles(styles)(CostSummary);
