import { FC, ReactNode } from 'react';

import { Hidden } from '@material-ui/core';
import { HiddenProps } from '@material-ui/core/Hidden';

type JoinHiddenProps = HiddenProps & {
  children: ReactNode;
};

const JoinHidden: FC<JoinHiddenProps> = (props) => <Hidden {...props}>{props.children}</Hidden>;

export default JoinHidden;
