import { FC, useMemo, useState } from 'react';

import { Typography } from '@material-ui/core';

import {
  UserSourceSystemEventType,
  getUserSourceSystemAnalyticsEvent,
} from '../../../analytics/analyticsEventProperties';
import { UserSource } from '../../../generated/graphql';
import useSendAnalytics from '../../../hooks/useSendAnalytics';
import { withStyles } from '../../../theme/komodo-mui-theme';
import DialogsConfirm from '../../Dialogs/DialogsConfirm/DialogsConfirm';
import { getSourceAuthURL, getSourcesInfo } from '../Files/FilesDialog/FilesDialogUtils';
import { useRemoveUserSource, useUserSourcesQuery } from '../hooks';

import styles from './FileExplorerStyles';

type FileExplorerErrorProps = {
  classes: Classes<typeof styles>;
  error: string;
  userSource: UserSource;
};

const FileExplorerError: FC<FileExplorerErrorProps> = ({ classes, error, userSource }) => {
  const [removeUserSource] = useRemoveUserSource();
  const sendAnalytics = useSendAnalytics();
  const { data } = useUserSourcesQuery();
  const clients = data?.userSources.clients || [];

  // Offer reconnect opportunity
  const [openReconnect, setOpenReconnect] = useState(false);
  useMemo(() => {
    if (error.includes('Try to log in again')) {
      setOpenReconnect(true);
    }
  }, [error]);

  const sourceInfo = getSourcesInfo(clients).find(
    (s) => s.sourceSystem === userSource.sourceSystem
  );
  if (!sourceInfo) return null;

  return (
    <div className={classes.content}>
      <Typography variant="title">Error</Typography>
      <Typography variant="subheading">{error}</Typography>
      <DialogsConfirm
        body="Would you like to reconnect to this account?"
        onClose={() => {
          setOpenReconnect(false);
        }}
        onConfirm={() => {
          removeUserSource(userSource.id);
          sendAnalytics(
            getUserSourceSystemAnalyticsEvent(
              UserSourceSystemEventType.RELOGIN,
              userSource.sourceSystem
            )
          );
          window.open(getSourceAuthURL(sourceInfo), '_blank');
          setOpenReconnect(false);
        }}
        open={openReconnect}
        title="Relink Account"
      />
    </div>
  );
};

export default withStyles(styles)(FileExplorerError);
