import { commonMarkupFields, gql } from '../../../api/graphqlFragments';

export const removeContingencyMutation = gql`
  mutation removeContingency(
    $projectID: UUID!
    $estimate: UUID!
    $markupIDs: [UUID!]!
    $viewFilter: ViewFilter
  ) {
    removeMarkups(
      projectID: $projectID
      estimate: $estimate
      markupIDs: $markupIDs
      viewFilter: $viewFilter
    ) {
      estimate {
        id
        inheritedMarkups {
          ...commonMarkupFields
        }
        markups {
          ...commonMarkupFields
        }
        incorporatedMarkups {
          ...commonMarkupFields
        }
        subtotal
        markupSubtotal
        inheritedSubtotal
        incorporatedSubtotal
      }
    }
  }
  ${commonMarkupFields}
`;
