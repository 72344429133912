import { createStyles } from '@material-ui/core/styles';

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
const FilteredMilestoneInfoStyles = (theme: any) =>
  createStyles({
    costImpact: {
      ...theme.typography.number,
      alignItems: 'center',
      color: theme.palette.joinPrimary,
      fontSize: 12,
      lineHeight: `${theme.spacing.generic[7]}px`,
      marginRight: theme.spacing.generic[3],
    },
    inlineRow: {
      alignItems: 'center',
      display: 'flex',
      flex: '0 1 auto',
      flexShrink: 0,
    },
    milestoneStatus: {
      display: 'inline',
    },
    milestoneStatusContainer: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
    },
    statusIcon: {
      paddingRight: 2,
    },
  });

export default FilteredMilestoneInfoStyles;
