import { createStyles } from '@material-ui/core';

export default () =>
  createStyles({
    expandThumbnailButton: {
      visibility: 'hidden',
      display: 'flex',
      position: 'absolute',
      bottom: 0,
      right: 0,
      background: 'rgba(0, 0, 0, 0.5)',
      color: 'white',
      '& svg': {
        fontSize: 55,
      },
    },
    thumbnailButton: {
      '&:hover $expandThumbnailButton': {
        visibility: 'visible',
      },
    },
  });
