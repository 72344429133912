import { useState } from 'react';

import { Search } from '@material-ui/icons';

import { Checkbox, TextInput } from '../../scales';
import ExpandableFilterContainer from '../common/ExpandableFilterContainer';

import { ListFilterOption, calculateSelectAllLabel } from './FilterGroupUtils';

type Props = {
  'data-cy'?: string;
  description?: string;
  entries: ListFilterOption[];
  isAllSelectable?: boolean;
  isSearchable?: boolean;
  onExpand?: () => void;
  onChange: (value: string[]) => void;
  searchPlaceholder?: string;
  selectedEntries: string[];
  title: string;
};

export default function FilterGroup(props: Props) {
  const [searchString, setSearchString] = useState('');
  const entries = props.entries.filter((entry) =>
    entry.label?.toLowerCase().includes(searchString.toLowerCase())
  );

  const hasEntries = props.entries.length > 0;
  const hasSelectedEntries = props.selectedEntries.length > 0;
  const hasAllSelected = entries.length === props.selectedEntries.length;

  const handleCheck = (option: ListFilterOption) => {
    const isCurrentlySelected = props.selectedEntries.find((e) => e === option.id) !== undefined;
    if (isCurrentlySelected) {
      props.onChange(props.selectedEntries.filter((v) => v !== option.id));
    } else {
      props.onChange([...props.selectedEntries, option.id]);
    }
  };

  const handleSelectAll = () => {
    if (hasAllSelected) {
      props.onChange([]);
    } else {
      props.onChange(entries.map((e) => e.id));
    }
  };

  const isSelected = (entry: ListFilterOption) => {
    return props.selectedEntries.some((se) => se === entry.id);
  };

  return (
    <ExpandableFilterContainer
      canReset={props.selectedEntries.length > 0}
      count={props.selectedEntries.length}
      data-cy={props['data-cy']}
      description={props.description}
      onExpand={props.onExpand}
      onReset={() => props.onChange([])}
      title={props.title}
    >
      {props.isSearchable && (
        <div className="mb-4">
          <TextInput
            aria-label="filter locations"
            onChange={setSearchString}
            placeholder={props.searchPlaceholder ?? 'Filter entries'}
            startAdornment={<Search />}
            value={searchString}
          />
        </div>
      )}
      <div className="flex max-h-[280px] flex-col gap-2 overflow-auto">
        {props.isAllSelectable && hasEntries && (
          <div className="border-b-2 pb-1">
            <Checkbox
              key="selectAll"
              isIndeterminate={!hasAllSelected && hasSelectedEntries}
              isSelected={hasAllSelected || hasSelectedEntries}
              onChange={() => handleSelectAll()}
            >
              <div>{calculateSelectAllLabel(props.selectedEntries, entries)}</div>
            </Checkbox>
          </div>
        )}
        <div className="flex flex-col gap-2 pr-2">
          {entries.map((entry) => (
            <Checkbox
              key={entry.label}
              data-cy={`filterGroupItem-${entry.label}`}
              isSelected={isSelected(entry)}
              onChange={() => handleCheck(entry)}
            >
              <div className="flex w-full">
                <div>{entry.label || 'None'}</div>
                <div className="ml-auto">{entry.count}</div>
              </div>
            </Checkbox>
          ))}
        </div>
      </div>
    </ExpandableFilterContainer>
  );
}
