import { useEffectOnce } from 'react-use';

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
export default function useDebounceFn(func: (...args: any[]) => void, delay: number) {
  let handler: NodeJS.Timeout | null;

  // dg4: todo figure out why we're not cancelling this refetch call when we switch components
  // See https://github.com/JoinCAD/komodo/issues/6487

  useEffectOnce(() => () => {
    if (handler) clearTimeout(handler);
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  const debounceFunc = (...args: any[]) => {
    if (handler) clearTimeout(handler);
    handler = setTimeout(() => {
      handler = null;
      try {
        if (func) func(...args);
      } catch (e) {
        // this is to solve for https://github.com/apollographql/@apollo/client/issues/3862
        return null;
      }
      return null;
    }, delay);
  };

  return debounceFunc;
}
