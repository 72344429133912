export default (
  <svg
    className="fill-entities-milestone"
    fill="none"
    height="11.9"
    viewBox="0 0 10.5 11.9"
    width="10.5"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M 6.3 1.4 L 5.6 0 H 0 V 11.9 H 1.4 V 7 H 4.9 L 5.6 8.4 H 10.5 V 1.4 H 6.3 Z M 9.1 7 H 6.3 L 5.6 5.6 H 1.4 V 1.4 H 4.9 L 5.6 2.8 H 9.1 V 7 Z" />
  </svg>
);
