import { FC } from 'react';
import * as React from 'react';

import { Avatar, Typography } from '@material-ui/core';
import { FiberManualRecord as Circle } from '@material-ui/icons';

import { withStyles } from '../../../../../theme/komodo-mui-theme';
import NormalTooltip from '../../../../NormalTooltip/NormalTooltip';

import { styles } from './NoteIconStyles';

const NoteIcon: FC<{
  classes: Classes<typeof styles>;
  collapsedChildCommentCount: number | undefined;
  commentContents?: string;
  hasCollapsedChildComments: boolean;
  hasContent: boolean;
  isAddingNoteInRow: boolean;
  isExpanded: boolean;
  showAddNoteLink: boolean;
  onClick: (e: React.KeyboardEvent | React.MouseEvent) => void;
}> = ({
  classes,
  collapsedChildCommentCount,
  commentContents = '',
  hasCollapsedChildComments,
  hasContent,
  isAddingNoteInRow,
  isExpanded,
  showAddNoteLink,
  onClick,
}) => {
  if (hasCollapsedChildComments && !showAddNoteLink)
    return (
      <NormalTooltip title={commentContents}>
        <Avatar
          className={`${classes.commentCount} ${
            hasContent ? classes.commentCountDarkBackground : ''
          }`}
        >
          <Typography
            data-cy={`icon-CommentCount-${collapsedChildCommentCount}`}
            icon-CommentCountclassName={classes.commentCountText}
          >
            {collapsedChildCommentCount}
          </Typography>
        </Avatar>
      </NormalTooltip>
    );
  if (!hasCollapsedChildComments && hasContent && !isAddingNoteInRow)
    return (
      <NormalTooltip title={!isExpanded ? commentContents : ''}>
        <Circle
          className={classes.noteIndicator}
          data-cy="icon-ReportComment"
          onClick={onClick}
          onKeyDown={onClick}
        />
      </NormalTooltip>
    );
  return null;
};

export default withStyles(styles)(NoteIcon);
