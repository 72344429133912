import { FC } from 'react';

import { withStyles } from '../../../theme/komodo-mui-theme';
import { isCostScalar } from '../../../utilities/compare';
import { formatCost } from '../../../utilities/currency';
import { parseDate } from '../../../utilities/dates';
import { getItemStatusLabel } from '../../../utilities/item-status';
import { generateTitle } from '../../Items/ItemsListItem/ItemsListItemUtils';
import ItemsOptionChipSquareListArea from '../../Items/ItemsOptionChipSquareListArea/ItemsOptionChipSquareListArea';
import ItemsIcons from '../../ItemsList/ItemsIcons/ItemsIcons';
import { SMALL } from '../../ItemsList/ItemsIcons/ItemsIconsMap';

import styles from './ItemReferencePreviewStyles';

type ItemReferencePreviewProps = {
  classes: Classes<typeof styles>;
  item: ItemsListItem;
};

// this takes in an item reference summary and renders a preview box with information about the item
const ItemReferencePreview: FC<ItemReferencePreviewProps> = ({ classes, item }) => {
  const { name, number, currentStatus, currentCost, options, creator, createdAt } = item;
  const creatorName = creator ? creator.name : 'Unknown';

  let costImpactText = '';
  if (currentCost) {
    costImpactText = isCostScalar(currentCost)
      ? formatCost(currentCost?.value, { rounded: true, showCents: false })
      : `${formatCost(currentCost.min, { rounded: true, showCents: false })} — ${formatCost(
          currentCost.max,
          { rounded: true, showCents: false }
        )}`;
  }

  const createdAtTime = createdAt ? `(${parseDate(createdAt)})` : '';

  const isDisplayOptions = (options || []).length;
  const itemOptions = isDisplayOptions ? (
    <ItemsOptionChipSquareListArea options={options} parentStatus={item.status} />
  ) : null;

  return (
    <>
      <span className={classes.inline}>
        <span className={`${classes.bold} ${classes.name} ${classes.ellipsis}`}>
          {generateTitle({ name, number })}
        </span>
        <span className={classes.spacer} />
        <span className={classes.statusIcon}>
          <ItemsIcons status={currentStatus} variant={SMALL} />
        </span>
        <span className={`${classes.text} `}>{getItemStatusLabel(currentStatus)}</span>
      </span>
      <span className={classes.rowSpacer} />
      <span className={classes.text}>Cost Impact: {costImpactText}</span>
      <span>{`Creator: ${creatorName} ${createdAtTime}`}</span>
      <span>{itemOptions}</span>
    </>
  );
};

export default withStyles(styles)(ItemReferencePreview);
