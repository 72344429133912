import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';

const modifiedDotStyles = (theme: KomodoTheme) =>
  createStyles({
    icon: {
      width: 12,
      height: 12,
      color: theme.palette.fillGreen,
    },
    iconButton: {
      width: 18,
      height: 18,
      marginTop: 'auto',
      marginBottom: 'auto',
    },
    iconMenu: {
      display: 'inherit',
    },
    paddingRight: {
      paddingRight: 6,
    },
  });

export default modifiedDotStyles;
