import { FC } from 'react';

import { Select } from '../../../scales';

type DialogsReportsSharedSelectorProps = {
  userReport: UserReportInput;
  setUserReport: (report: UserReportInput) => void;
};

const entries = [
  { id: 'team', label: 'Team', 'data-cy': 'select-Team' },
  { id: 'private to me', label: 'Private to me', 'data-cy': 'select-Private-to-me' },
];

const DialogsReportsSharedSelector: FC<DialogsReportsSharedSelectorProps> = ({
  userReport,
  setUserReport,
}) => {
  // set the default value by checking the userReport.shared
  const defaultEntry = userReport.shared ? entries[0] : entries[1];

  return (
    <div className="w-[275px]">
      <Select
        data-cy="report-sharing-select"
        defaultValue={defaultEntry ? defaultEntry.id : entries[0].id}
        entries={entries}
        label="Shared With"
        onChange={(v: string | null) => {
          const newEntry = entries.find((e) => e.id === v);
          setUserReport({
            ...userReport,
            shared: newEntry ? newEntry.id === 'team' : false,
          });
        }}
      />
    </div>
  );
};

export default DialogsReportsSharedSelector;
