import { FC, KeyboardEvent, useState } from 'react';
import * as React from 'react';

import { useReactiveVar } from '@apollo/client';

import { reloadGridVersionVar } from '../../api/apollo/reactiveVars';
import useSendAnalytics from '../../hooks/useSendAnalytics';
import { isCtrlMeta } from '../Print/PrintUtils';

import { renderEditor } from './editors';
import {
  ClearSelectedRange,
  CopyToClipboard,
  CutToClipboard,
  DefaultKeypress,
  EditCellWithContents,
  FillRangeDown,
  FillRangeRight,
  FillSelectedRange,
  HandleCellEnter,
  HandleCellTab,
  HandleEsc,
  HandleState,
  MoveToRowEnd,
  MoveToRowStart,
  MoveToTableEnd,
  MoveToTableStart,
  NavigateDown,
  NavigateLeft,
  NavigateRight,
  NavigateUp,
  PasteClipboard,
  ScrollToActiveCell,
  SelectAll,
  SelectColumn,
  SelectRow,
  UndoLastMutation,
} from './handlers/keyHandlers';
// eslint-disable-next-line import/no-cycle
import VirtualTable, { ItemGridDisplayProps } from './JoinGridVirtualTable';
import { GridButtonData, GridController, Position } from './types';

// Contains all the global key handling for a JoinGrid instance. These will always
// dispatch key commands based on the current active selection in the grid controller.
const keyHandler = (event: KeyboardEvent, handleState: HandleState) => {
  const { grid, start, editing } = handleState;
  if (
    start.row < 0 ||
    start.row >= grid.numRows() ||
    start.column < 0 ||
    start.column >= grid.numCols()
  ) {
    return;
  }

  // Support both mac/windows copy paste.
  const ctrl = isCtrlMeta(event);
  const shift = event.shiftKey;

  const handlerFunction = (() => {
    switch (event.key) {
      case 'C':
      case 'c':
        if (ctrl) return CopyToClipboard;
        return DefaultKeypress;
      case 'V':
      case 'v':
        if (ctrl) return PasteClipboard;
        return DefaultKeypress;
      case 'X':
      case 'x':
        if (ctrl) return CutToClipboard;
        return DefaultKeypress;
      case 'Z':
      case 'z':
        if (ctrl) return UndoLastMutation;
        return DefaultKeypress;
      case 'A':
      case 'a':
        if (ctrl) return SelectAll;
        return DefaultKeypress;
      case 'Delete':
      case 'Backspace':
        if (ctrl) return ScrollToActiveCell;
        if (editing) return DefaultKeypress;
        return ClearSelectedRange;
      case 'Enter':
        if (ctrl) return FillSelectedRange;
        return HandleCellEnter;
      case 'ArrowLeft':
        return NavigateLeft;
      case 'ArrowRight':
        return NavigateRight;
      case 'ArrowUp':
        return NavigateUp;
      case 'ArrowDown':
        return NavigateDown;
      case 'Escape':
        return HandleEsc;
      case 'Tab':
        return HandleCellTab;
      case 'F2':
        return EditCellWithContents;
      case 'Home':
        if (ctrl) return MoveToTableStart;
        return MoveToRowStart;
      case 'End':
        if (ctrl) return MoveToTableEnd;
        return MoveToRowEnd;
      case ' ':
        if (ctrl) return SelectColumn;
        if (shift && !editing) return SelectRow;
        return DefaultKeypress;
      case 'd':
      case 'D':
        if (ctrl) return FillRangeDown;
        return DefaultKeypress;
      case 'r':
      case 'R':
        if (ctrl) return FillRangeRight;
        return DefaultKeypress;
      default:
        return DefaultKeypress;
    }
  })();
  handlerFunction(event, handleState);
};

export interface GridProps {
  gridType: { gridType: string; model: string };
  grid: GridController;
  itemGridDisplayProps?: ItemGridDisplayProps;
  buttons?: GridButtonData;
}

// This component is responsible for the interaction layer on top of cells
// that are 'focused' (in context of our grid), but not yet being edited.
// It handles spawning and positioning the editors correctly, and we use
// `useState()` here in order to trigger a re-render of the editor when needed.
// Its child component `innerTable` is memo'd to never re-render unless the
// Grid unmounts.
export const Grid: FC<GridProps> = ({ gridType, grid, itemGridDisplayProps, buttons }) => {
  const [editing, setEditing] = useState(false);
  // this is used to reload the grid from outside it
  const [currentGridVersion, setCurrentGridVersion] = useState(reloadGridVersionVar());
  const [editorDefaultValue, setEditorDefaultValue] = useState<GridCellValue | undefined>(
    undefined
  );
  const [editorPosition, setEditorPosition] = useState({
    top: 0,
    left: 0,
    width: 100,
    height: 100,
  });
  const sendAnalytics = useSendAnalytics();

  const setNewPosition = (event: React.KeyboardEvent) => {
    const handleState = {
      grid,
      start: grid.selection.start,
      end: grid.selection.end,
      editing,
      setEditing,
      setEditorDefaultValue,
      setEditorPosition: (p: Position) => {
        const newPosition = grid.getCellPosition(p);
        if (newPosition) {
          setEditorPosition(newPosition);
        }
      },
    };
    keyHandler(event, handleState);
  };

  const { refetch } = grid;
  const reloadGridVersion = useReactiveVar(reloadGridVersionVar);
  if (reloadGridVersion !== currentGridVersion) {
    if (refetch) refetch();
    setCurrentGridVersion(currentGridVersion + 1);
  }

  return (
    <div onKeyDown={setNewPosition}>
      <VirtualTable
        buttons={buttons}
        editing={editing}
        grid={grid}
        itemGridDisplayProps={itemGridDisplayProps}
        setEditing={setEditing}
        setEditorDefaultValue={setEditorDefaultValue}
      />
      {editing &&
        renderEditor(
          grid,
          editorPosition,
          editorDefaultValue,
          setEditing,
          gridType,
          grid.variant,
          sendAnalytics
        )}
    </div>
  );
};
