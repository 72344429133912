import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';

export default (theme: KomodoTheme) =>
  createStyles({
    button: {
      ...theme.typography.webSmallLink,
      textDecorationSkipInk: 'none' as const,
      display: 'flex',
      cursor: 'pointer',
    },
    button_autoEscalated: {
      color: theme.palette.middleBlue,
    },
    button_fuzzyMatch: {
      color: theme.palette.chartOrange,
    },
  });
