import {
  CategoryMapInput,
  PreviewCategoriesQuery,
  PreviewCategoriesQueryVariables,
} from '../../../generated/graphql';
import { useQuery } from '../../../hooks/useMountAwareQuery';
import { previewImportedCategoriesQuery } from '../hooks/queries';

function usePreviewCategoriesQuery(assetID: UUID) {
  const result = useQuery<PreviewCategoriesQuery, PreviewCategoriesQueryVariables>(
    previewImportedCategoriesQuery,
    {
      variables: { assetID },
      skip: !assetID,
    }
  );
  let categoryMap: CategoryMapInput[] | undefined;
  if (result.data?.previewImportedCategories?.categoryMap) {
    categoryMap = JSON.parse(result.data?.previewImportedCategories?.categoryMap);
  }

  const errors = result.data?.previewImportedCategories?.errors;
  const metadata = result.data?.previewImportedCategories?.metadata;

  return { ...result, categoryMap, metadata, errors };
}

export default usePreviewCategoriesQuery;
