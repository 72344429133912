import * as React from 'react';

import { ErrorOutline } from '@material-ui/icons';

import { withStyles } from '../../../theme/komodo-mui-theme';

import styles from './WarningStyles';

const Warning = withStyles(styles)(
  (props: {
    children: React.ReactChild | React.ReactChild[];
    classes: Classes<typeof styles>;
    dataCy?: string;
  }) => (
    <div className={props.classes.container} data-cy={props.dataCy}>
      <ErrorOutline className={props.classes.icon} />
      <div>{props.children}</div>
    </div>
  )
);

export default Warning;
