import { detailedItemFields, gql } from '../../../../api/graphqlFragments';

export const createOptionMutation = gql`
  mutation createOption(
    $projectID: UUID!
    $parent: UUID!
    $item: ItemCreationInput!
    $costMode: CostMode!
  ) {
    createOption(projectID: $projectID, parent: $parent, item: $item, costMode: $costMode) {
      ...detailedItemFields
    }
  }
  ${detailedItemFields}
`;
