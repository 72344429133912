import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';

const QUANTITY_HEIGHT = 32;

export default (theme: KomodoTheme) =>
  createStyles({
    errorFeedbackText: {
      color: theme.palette.red,
      '& input::placeholder': {
        opacity: 1,
      },
    },
    errorIcon: {
      color: theme.palette.red,
      alignSelf: 'center',
      height: theme.iconSizes.compact,
      width: theme.iconSizes.compact,
    },
    errorIconTextBox: {
      margin: '0 2px 0 6px',
    },
    errorTextColor: {
      color: theme.palette.red,
    },
    greenBG: {
      backgroundColor: theme.palette.backgroundGreen,
    },
    quantityField: {
      display: 'flex',
      border: theme.border.line,
      height: QUANTITY_HEIGHT,
      borderRadius: theme.border.radius,
      boxSizing: 'border-box',
      '& + $quantityField': {
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
      },
      '&:has(+ $quantityField)': {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        borderBottomColor: 'transparent',
      },
      '&$quantityFieldError': {
        borderColor: theme.palette.red,
      },
      '&:focus-within': {
        border: theme.border.focus,
        outline: theme.border.focus,
      },
    },
    quantityFieldError: {},
    quantityInput: {
      border: '1px transparent solid',
      ...theme.typography.number2,
      '&:focus-within': {
        border: 'none',
        outline: 'none',
      },
    },
    whiteBG: {
      backgroundColor: theme.palette.backgroundWhite,
    },
    titleHeading: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    unitAbbreviationInput: {
      color: theme.palette.disabledGrey,
      maxWidth: 100,
      padding: '6px 8px 6px 8px',
    },
    unitAbbreviationText: {
      color: theme.palette.disabledGrey,
      fontSize: '0.9em',
      fontWeight: 400,
      maxWidth: 100,
    },
  });
