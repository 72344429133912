import tinycolor from 'tinycolor2';

import theme from '../theme/komodo-mui-theme';

export const getRandomFromArray = (colors: string[]) =>
  colors[Math.floor(Math.random() * colors.length)];

const isDarkColor = (color: string | undefined) => color && tinycolor(color).isDark();

export const getTextColor = (backgroundColor: string) =>
  isDarkColor(backgroundColor) ? 'text-type-secondary' : 'text-type-primary';

export const colorOptionsFromPalette = Object.values(theme.palette.chartsThemes);
export const getNewPickerColor = (usedColors: string[]) => {
  let colors = colorOptionsFromPalette.filter((c) => !usedColors.includes(c));
  if (colors.length === 0) {
    colors = colorOptionsFromPalette;
  }
  return getRandomFromArray(colors);
};
