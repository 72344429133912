import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';

const TOTAL_NUMBER_PAD_RIGHT = 32;

export default (theme: KomodoTheme) =>
  createStyles({
    root: {
      color: theme.palette.primaryGrey,
      flex: '1 1 100%',
      padding: '0px 0px 8px 8px',
      pageBreakInside: 'avoid',
      position: 'relative',
    },
    border: {
      borderLeft: theme.border.darkGrey,
    },
    container: {
      display: 'flex',
    },
    endIcon: { display: 'flex', padding: '0px 4px', justifyContent: 'space-between' },
    costPadding: {
      paddingRight: TOTAL_NUMBER_PAD_RIGHT - 3,
      fontWeight: 'bold',
    },
    entryHeader: {
      backgroundColor: theme.palette.backgroundGrey,
      borderTop: theme.border.darkGrey,
    },
    borderBottom: { borderBottom: theme.border.darkGrey },
    inActive: { color: theme.palette.disabledGrey },
    fullWidth: {
      width: '100%',
    },
    header: {
      alignItems: 'center',
      cursor: 'pointer',
      display: 'flex',
      flexDirection: 'row',
      outline: 'none !important',
      padding: '8px 0px 0px 5px',
      pageBreakInside: 'avoid',
      position: 'relative',
      flexGrow: 1,
    },
    iconUp: {
      transform: 'rotate(180deg)',
      transition: 'transform 0.2s ease-in',
    },
    noBackground: {
      backgroundColor: theme.palette.backgroundWhite,
      borderTop: 'none',
    },
    noPadding: {
      padding: 0,
    },
    number: {
      color: 'inherit',
      textAlign: 'right',
      ...theme.typography.number,
    },
    margin: {
      borderBottom: `1px solid ${theme.palette.joinGrey400}`,
      marginBottom: 16,
    },
    menu: { paddingRight: '16px' },
    overflow: {
      overflow: 'visible',
    },
    rowTitle: {
      alignItems: 'center',
      display: 'flex',
      width: '100%',
    },
    rowTitleInner: {
      display: 'flex',
      flexWrap: 'wrap',
      width: '100%',
    },
    title: {
      marginRight: 8,
      paddingLeft: '5px',
      flexGrow: 1,
    },
    zeroMargin: {
      margin: 0,
    },
  });
