import { getImportEstimateVar } from '../../api/apollo/reactiveVars';
import { EstimateType } from '../../api/gqlEnums';
import { GetDetailedMilestonesQuery } from '../../generated/graphql';
import { getTotalAttachmentCount } from '../assets/utils';

type Asset = GetDetailedMilestonesQuery['milestones'][number]['importedEstimates'][number];

export enum MilestoneDetailsTabs {
  BASICS = 'BASICS',
  METRICS = 'METRICS',
  ESTIMATE = 'ESTIMATE',
  TARGET = 'TARGET',
  ATTACHMENTS = 'ATTACHMENTS',
}

export const getLastUploadedEstimate = (
  importedEstimates: Asset[] | undefined,
  estimateType: EstimateType
): Asset | undefined => {
  const importEstimateVar = getImportEstimateVar(estimateType);
  const importEstimate = importEstimateVar();
  const uploadedEstimate = (importedEstimates || []).find(
    ({ id }) => id === (importEstimate || {}).assetId
  );
  return uploadedEstimate;
};

export const getTotalMilestoneAttachmentCount = (milestone: Milestone | undefined) => {
  if (!milestone) return 0;
  if ('remoteAssetCount' in milestone && 'assetCount' in milestone) {
    return getTotalAttachmentCount(milestone.assetCount, milestone.remoteAssetCount);
  }
  return 0;
};
