import { useMutation } from '@apollo/client';

import {
  forecastingReportHasUnsavedChangesVar,
  projectCompsSetInputVar,
} from '../../../../api/apollo/reactiveVars';
import {
  CreateProjectComparisonReportMutation,
  CreateProjectComparisonReportMutationVariables,
} from '../../../../generated/graphql';

import { createProjectComparisonReport } from './queries';

export const useCreateProjectComparisonReportMutation = (
  onSuccess?: (report: ForecastingReport) => void
) => {
  const [createProjectComparisonReportFunc, { loading }] = useMutation<
    CreateProjectComparisonReportMutation,
    CreateProjectComparisonReportMutationVariables
  >(createProjectComparisonReport);

  const mutation = (reportName: string, reportDescription = '', attachedProjectID: UUID) => {
    if (loading) return; // prevent multiple mutations at once (i.e. double-clicking button)
    createProjectComparisonReportFunc({
      variables: {
        attachedProjectID: attachedProjectID || null,
        reportName,
        reportDescription,
        projectCompsSetInput: projectCompsSetInputVar(),
      },
    }).then(({ data }) => {
      if (data?.createProjectComparisonReport) {
        if (forecastingReportHasUnsavedChangesVar()) forecastingReportHasUnsavedChangesVar(false);
        if (onSuccess) onSuccess(data.createProjectComparisonReport);
      }
    });
  };
  return [mutation];
};
