import * as React from 'react';

import {
  isDownloadingBudgetToExcelVar,
  isDownloadingEstimateToExcelVar,
} from '../../../api/apollo/reactiveVars';
import { ExportType } from '../../../api/gqlEnums';
import JoinAPI from '../../../api/joinAPI';
import useSendAnalytics from '../../../hooks/useSendAnalytics';
import {
  MILESTONE_EXPORT_OPTIONS,
  MILESTONE_EXPORT_OPTIONS_ACTIVE_ESTIMATE,
  MILESTONE_EXPORT_OPTIONS_BUDGET,
} from '../../../tagConstants';
import { withStyles } from '../../../theme/komodo-mui-theme';
import { Export } from '../../Icons/Export';
import IconMenu from '../../Select/SelectMenu/IconMenu';

import MilestoneExportPanelStyles from './MilestoneExportPanelStyles';

type MilestoneExportPanelProps = {
  budgetID: UUID | null;
  classes: Classes<typeof MilestoneExportPanelStyles>;
  canView: boolean;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  disabled: boolean;
  estimateID?: UUID | null;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  includeMarkups: boolean;
  isDetails?: boolean;
  projectName: string;
  milestoneName: string;
};

const MilestoneExportPanel: React.FC<MilestoneExportPanelProps> = ({
  budgetID,
  classes,
  canView,
  disabled,
  estimateID,
  includeMarkups,
  isDetails,
  projectName,
  milestoneName,
}) => {
  const sendAnalytics = useSendAnalytics();

  if (disabled) return null;

  const exportEstimate = (id: UUID, type: ExportType) => {
    const fileNameTokens = [projectName, milestoneName, type];
    if (isDownloadingEstimateToExcelVar()) return;
    JoinAPI.exportEstimates([id], type, includeMarkups, fileNameTokens, sendAnalytics);
  };

  const options = [
    {
      callback: () => canView && estimateID && exportEstimate(estimateID, ExportType.ESTIMATE),
      disabled: !canView,
      id: MILESTONE_EXPORT_OPTIONS_ACTIVE_ESTIMATE,
      loadingVar: isDownloadingEstimateToExcelVar,
      name: 'Export Estimate as .xlsx',
      tooltip: 'Export estimate to excel (.xlsx)',
    },
    {
      callback: () => canView && budgetID && exportEstimate(budgetID, ExportType.BUDGET),
      disabled: !canView,
      id: MILESTONE_EXPORT_OPTIONS_BUDGET,
      loadingVar: isDownloadingBudgetToExcelVar,
      name: 'Export Budget as .xlsx',
      tooltip: 'Export budget to excel (.xlsx)',
    },
  ];

  return (
    <div className={isDetails ? classes.detailsContainer : classes.optionsContainer}>
      <IconMenu
        icon={<Export />}
        id={MILESTONE_EXPORT_OPTIONS}
        isBottomOriented
        options={options}
      />
    </div>
  );
};

const MilestoneExportPanelStyled = withStyles(MilestoneExportPanelStyles)(MilestoneExportPanel);

export default MilestoneExportPanelStyled;
