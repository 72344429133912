import { useMutation } from '@apollo/client';

import { projectCompsSetInputVar } from '../../../../../api/apollo/reactiveVars';
import {
  ExportAverageCompEstimateInput,
  ExportAverageCompToExistingMilestoneMutation,
  ExportAverageCompToExistingMilestoneMutationVariables,
} from '../../../../../generated/graphql';
import { logErrorToSentry } from '../../../../../utilities/sentry';

import { exportAverageCompToExistingMilestone } from './queries';

export const useExportAverageCompToExistingMilestone = () => {
  const [createEstimateFromProjectCompFunc] = useMutation<
    ExportAverageCompToExistingMilestoneMutation,
    ExportAverageCompToExistingMilestoneMutationVariables
  >(exportAverageCompToExistingMilestone, { onError: logErrorToSentry });
  const mutation = (
    projectID: UUID,
    milestoneID: UUID,
    exportAverageCompEstimateInput: ExportAverageCompEstimateInput,
    onSuccess: (milestoneID: UUID) => void
  ) =>
    createEstimateFromProjectCompFunc({
      variables: {
        projectID,
        milestoneID,
        projectCompsSetInput: projectCompsSetInputVar(),
        exportAverageCompEstimateInput,
      },
    }).then(({ data }) => {
      if (data?.exportAverageCompToExistingMilestone) onSuccess(milestoneID);
    });
  return [mutation];
};
