type IconProps = {
  className?: string;
};

export const LongRightIcon = (props: IconProps) => (
  <svg
    className={props.className}
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3 13L17.17 13L13.59 16.59L15 18L21 12L15 6L13.59 7.41L17.17 11L3 11V13Z"
      fill="black"
    />
  </svg>
);

export const ReportIcon = (props: IconProps) => (
  <svg
    className={props.className}
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.9047 17.9459H18.9348V5.5232C18.9348 5.2343 18.7005 5 18.4116 5H16.497C16.208 5 15.9738 5.2342 15.9738 5.5232V17.9459H13.4809V13.2557C13.4809 12.9667 13.2466 12.7325 12.9577 12.7325H11.0431C10.7541 12.7325 10.5199 12.9668 10.5199 13.2557V17.9459H8.02676V9.7234C8.02676 9.4344 7.79246 9.2002 7.50356 9.2002H5.58886C5.29986 9.2002 5.06566 9.4344 5.06566 9.7234V17.9459H4.09536C3.80436 17.9459 3.56836 18.182 3.56836 18.4729C3.56836 18.7638 3.80446 18.9999 4.09536 18.9999H19.9048C20.1958 18.9999 20.4318 18.7638 20.4318 18.4729C20.4318 18.182 20.1957 17.9459 19.9047 17.9459Z"
      fill="black"
    />
  </svg>
);
