import { TooltipTriggerProps } from './types';

export const pickTooltipTriggerProps = (
  props: TooltipTriggerProps & unknown
): TooltipTriggerProps => ({
  'aria-describedby': props['aria-describedby'],
  onBlur: props.onBlur,
  onFocus: props.onFocus,
  onMouseEnter: props.onMouseEnter,
  onMouseLeave: props.onMouseLeave,
  onPointerEnter: props.onPointerEnter,
  onPointerLeave: props.onPointerLeave,
});
