import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';

export default (theme: KomodoTheme) =>
  createStyles({
    caption: { ...theme.typography.label, paddingLeft: 5, paddingRight: 8 },
    dropdownMenu: {
      backgroundColor: theme.palette.backgroundWhite,
      border: theme.border.line,
      borderRadius: theme.border.radius,
      marginTop: -36,
      maxWidth: 300,
      position: 'absolute',
      zIndex: 1,
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    },
    button: {
      '&:focus': {
        border: theme.border.focus,
        outline: theme.border.focus,
      },
      alignItems: 'center',
      background: theme.palette.backgroundWhite,
      border: theme.border.line,
      borderRadius: theme.border.radius,
      cursor: 'pointer',
      display: 'flex',
      whiteSpace: 'nowrap',
      wordBreak: 'break-word',
    },
    mainButton: {
      width: '100%',
      padding: '0px 0px 0px 2px', // this brings it into conformity with JoinSelect
    },
    inlineButton: {
      marginRight: 8,
    },
    menuOptionButton: {
      '&:focus': {
        backgroundColor: theme.palette.hoverBackground,
      },
      '&:hover': {
        backgroundColor: theme.palette.hoverBackground,
      },
      backgroundColor: theme.palette.backgroundWhite,
      border: 'none',
      cursor: 'pointer',
      height: 'auto',
      padding: '8px 6px 8px 12px',
      textAlign: 'left',
      fontFamily: theme.typography.fontFamily,
      whiteSpace: 'nowrap',
      width: '100%',
    },
    menuOptionSubText: {
      ...theme.typography.h4,
      textAlign: 'left',
      fontSize: 12,
      width: 'auto',
      whiteSpace: 'normal',
    },
    menuOptionText: {
      ...theme.typography.chip,
      fontFamily: theme.typography.fontFamily,
      textAlign: 'left',
      whiteSpace: 'normal',
      width: 'auto',
    },
    inlineText: {
      ...theme.typography.body1,
      lineHeight: '21px',
      padding: '5px 0px',
    },
    selectedText: {
      ...theme.typography.body1,
      fontFamily: theme.typography.fontFamily,
      textAlign: 'left',
      whiteSpace: 'normal',
      width: '100%',
      padding: 6,
    },
    selectedOption: {
      backgroundColor: theme.palette.focusSelectBackground,
    },
    spacer: {
      flex: '1 1 auto',
    },
  });
