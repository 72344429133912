import { ScaleLinear, ScalePoint, ScaleTime } from 'd3-scale';
import { useRef } from 'react';

import TimelineCircle from '../TimelineCircle';

import TimelineMultiTooltipContent from './TimelineMultiTooltipContent';
import MultiTooltipOverlay from './TransparentOverlay';

const OFFSET = 8;

type Data = { date: Date; value: number };

type Props = {
  backgroundColor: string[];
  data: Data[];
  fill: string[];
  isOpen?: boolean;
  onHover?: () => void;
  onLeave?: () => void;
  pointSize?: number;
  content: string[];
  x: ScaleTime<number, number> | ScalePoint<Date>;
  y: ScaleLinear<number, number>;
  yDomain: [number, number];
};

export default function TimelineMultiTooltip(props: Props) {
  const ref = useRef<SVGCircleElement>(null);
  if (!props.data) return null;

  const hostAttrs = {
    cx: (props.x(props.data[0]?.date) ?? 0) + OFFSET,
    cy: 0,
    opacity: 0,
    r: 1,
  };

  return (
    <>
      {props.isOpen &&
        props.data.map((data: Data, index: number) => (
          <TimelineCircle<{ value: number }>
            key={`hover-point-${index.toString()}-${data.value}`}
            data={data}
            fill={props.fill[index]}
            size={props.pointSize}
            x={props.x}
            y={props.y}
          />
        ))}
      <circle ref={ref} {...hostAttrs} />
      {props.isOpen && (
        <MultiTooltipOverlay isOpen offset={0} triggerRef={ref}>
          <TimelineMultiTooltipContent {...props} />
        </MultiTooltipOverlay>
      )}
    </>
  );
}
