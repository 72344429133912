import {
  LoadInAppNotificationsQuery,
  LoadInAppNotificationsQueryVariables,
} from '../../../generated/graphql';
import { QueryHookOptions, useQuery } from '../../../hooks/useMountAwareQuery';

import { loadInAppNotificationsQuery } from './queries';

const useLoadInAppNotificationsQuery = (
  options: QueryHookOptions<LoadInAppNotificationsQuery, LoadInAppNotificationsQueryVariables>
) =>
  useQuery<LoadInAppNotificationsQuery, LoadInAppNotificationsQueryVariables>(
    loadInAppNotificationsQuery,
    options
  );

export default useLoadInAppNotificationsQuery;
