import { createStyles } from '@material-ui/core';

const styles = () =>
  createStyles({
    optionsContainer: {
      paddingTop: 16,
    },
    header: {},
  });

export default styles;
