import { FC } from 'react';

import { Typography } from '@material-ui/core';

import { GetEstimateSourceItemInfosQuery } from '../../../generated/graphql';

import SelectableSourceItemLine from './SelectableSourceItemLine';

type RunningTotalEsimtateDraftPublishModalProps = {
  sourceItemInfos: GetEstimateSourceItemInfosQuery['estimateSourceItemInfos'];
  setSelectedItemIDs: (itemID: string) => void;
};

const RunningTotalEstimateDraftPublishModal: FC<RunningTotalEsimtateDraftPublishModalProps> = ({
  sourceItemInfos,
  setSelectedItemIDs,
}) => {
  const dialog = `Lines from ${sourceItemInfos?.length} Accepted Items were included in your Estimate for this milestone. Selected items will be incorporated into this milestone:`;

  return (
    <div>
      <Typography>{dialog}</Typography>
      {sourceItemInfos.map((itemInfo) => (
        <SelectableSourceItemLine
          key={itemInfo.id}
          setSelectedItemIDs={setSelectedItemIDs}
          sourceItemInfo={itemInfo}
        />
      ))}
    </div>
  );
};

export default RunningTotalEstimateDraftPublishModal;
