import { FC, useState } from 'react';

import {
  CompanyAdminEventType,
  CompanyAdminView,
  companyAdminAnalyticsEvent,
} from '../../../analytics/analyticsEventProperties';
import useSendAnalytics from '../../../hooks/useSendAnalytics';
import { isNonNullable } from '../../../utilities/types';
import { useCurrentUser } from '../../contexts/current-user';
import { useModuleEntitlements } from '../../contexts/module-entitlements';
import { allRoles } from '../../Dialogs/DialogsCompanyRoles/DialogsCompanyRolesUtils';
import JoinSelect, { getEntry } from '../../Select/JoinSelect/JoinSelect';
import { useUpdateCompanyUserCompanyRole } from '../CompanyHooks';

type CompanyTabRoleSelectorProps = {
  companyID?: UUID;
  roles: PartialRole[];
  currentRole?: UUID;
  userID: UUID;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  disabled?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  onChange?: (value: any) => void;
  analyticsView?: CompanyAdminView;
};

const CompanyTabRoleSelector: FC<CompanyTabRoleSelectorProps> = ({
  companyID,
  roles,
  currentRole,
  userID,
  disabled = false,
  onChange,
  analyticsView,
}) => {
  const sendAnalytics = useSendAnalytics();
  const [newRole, setNewRole] = useState(currentRole);
  const updateRole = useUpdateCompanyUserCompanyRole(companyID);

  const moduleEntitlementFlags = useModuleEntitlements();

  const entries = roles.map((r) => {
    if (
      allRoles.find(
        (a) =>
          a.companyRole === r.name &&
          (!a.entitlement || moduleEntitlementFlags.find((m) => m === a.entitlement))
      )
    ) {
      return getEntry(r.id, r.name);
    }
    return null;
  });

  const isCurrentUser = useCurrentUser().id === userID;

  return (
    <JoinSelect
      disabled={disabled || isCurrentUser}
      entries={entries.filter(isNonNullable)}
      onChange={(entry) => {
        updateRole(userID, entry);
        setNewRole(entry);
        onChange?.(entry);
        sendAnalytics(
          companyAdminAnalyticsEvent(CompanyAdminEventType.MEMBER_COMPANY_ROLE_CHANGE, {
            view: analyticsView,
            targetID: userID,
            userRole: entry,
          })
        );
      }}
      value={newRole}
    />
  );
};

export default CompanyTabRoleSelector;
