import {
  ProjectCategorizationsCategoryMatchesQuery,
  ProjectCategorizationsCategoryMatchesQueryVariables,
} from '../../../../../generated/graphql';
import { useQuery } from '../../../../../hooks/useMountAwareQuery';

import { projectCategorizationsCategoryMatchesQuery } from './queries';

export const useProjectCategorizationsCategoryMatchesQuery = (
  projectID: UUID,
  categoryNumbers: string[]
) => {
  return useQuery<
    ProjectCategorizationsCategoryMatchesQuery,
    ProjectCategorizationsCategoryMatchesQueryVariables
  >(projectCategorizationsCategoryMatchesQuery, {
    variables: {
      projectID,
      categoryNumbers,
    },
    skip: !projectID,
  });
};
