import { TableComponents } from '../../../../scales';

type Props = {
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  disabled: boolean;
  labels: string[];
};

const LevelDescriptionCell = (props: Props) => {
  const disabledClass = props.disabled ? 'text-type-inactive' : '';
  return (
    <TableComponents.Cell className="flex flex-col items-baseline justify-center py-2">
      {props.labels.length === 1 ? (
        <div key={props.labels[0]} className={disabledClass}>
          {props.labels[0]}
        </div>
      ) : (
        <ul className="ml-4 list-disc">
          {props.labels.map((label) => (
            <li key={label} className={disabledClass}>
              {label}
            </li>
          ))}
        </ul>
      )}
    </TableComponents.Cell>
  );
};

export default LevelDescriptionCell;
