import { ComponentProps } from 'react';

import { Select, TextInput } from '../../../scales';
import UploadIcon from '../../../Select/UploadIcon/UploadIcon';

type CompanyInput = {
  companyType: string;
  companyName: string;
  thumbnail?: string;
};

type Props = {
  addFile: (file: File) => void;
  companyTypes: ComponentProps<typeof Select>['entries'];
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  loading: boolean;
  onUpdate: (company: { companyName: string; companyType: string }) => void;
  company: CompanyInput;
};

export default function CreateCompany(props: Props) {
  return (
    <div className="flex flex-col gap-2">
      <TextInput
        data-cy="company-name-text-input"
        label="Company Name *"
        onChange={(value) => {
          props.onUpdate({ ...props.company, companyName: value });
        }}
        placeholder="Add company name"
        value={props.company.companyName}
      />
      <Select
        data-cy="company-type-select"
        entries={props.companyTypes}
        label="Company Type *"
        onChange={(value) => {
          props.onUpdate({ ...props.company, companyType: value });
        }}
        placeholder="Choose company type"
        value={props.company.companyType}
      />
      <div>
        <div className="text-type-primary type-label">Logo</div>
        <UploadIcon
          addFile={props.addFile}
          height={92}
          loading={props.loading}
          thumbnail={props.company.thumbnail || undefined}
          width={92 * 2}
        />
      </div>
    </div>
  );
}
