import { FC, useState } from 'react';

import { IconButton, Typography } from '@material-ui/core';
import { Cached } from '@material-ui/icons';

import { ListCategoriesQuery } from '../../../generated/graphql';
import { withStyles } from '../../../theme/komodo-mui-theme';
import NormalTooltip from '../../NormalTooltip/NormalTooltip';
import CollapseIcon from '../../shared-widgets/CollapseIcon';

import MultilevelAccordion from './MultilevelAccordion';
import MultilevelCategorizationsStyles from './MultilevelCategorizationStyles';
import { CategoryMapInput } from './types';

type MultilevelCategorizationsProps = {
  classes: Classes<typeof MultilevelCategorizationsStyles>;
  categories: CategoryMapInput[] | ListCategoriesQuery['category'];
  categorizationID?: string;
  onClickReplace?: () => void;
  onExpandCollapse?: () => void;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  viewOnly?: boolean;
};

const MultilevelCategorizations: FC<MultilevelCategorizationsProps> = ({
  classes,
  categories,
  categorizationID,
  onClickReplace,
  onExpandCollapse,
  viewOnly,
}) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const [isCollapsed, setIsCollapsed] = useState<boolean[]>(
    Array(categories.length).fill(!expanded)
  );

  const onClick = (index: number) => {
    const copy = [...isCollapsed];
    copy[index] = !copy[index];
    setIsCollapsed(copy);
    onExpandCollapse?.();
  };

  const expandAllToggle = () => (
    <Typography variant="body2">
      <button
        className={classes.linkButton}
        onClick={() => {
          setIsCollapsed(Array(categories.length).fill(expanded));
          setExpanded(!expanded);
        }}
        type="button"
      >
        {expanded ? 'Collapse All' : 'Expand All'}
      </button>
    </Typography>
  );

  return (
    <div className={classes.root}>
      <div className={classes.TopContainer}>
        <Typography variant="caption">Categories</Typography>
        {expandAllToggle()}
      </div>

      <div className={classes.dialogContent}>
        <div className={classes.catBox}>
          {/* eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :) */}
          {categories.map((singleCategory: any, index) => (
            <div key={singleCategory.number || singleCategory.code}>
              <div
                className={classes.descriptionFront}
                onClick={() => onClick(index)}
                onKeyDown={() => {}}
              >
                <div className={classes.iconContainer}>
                  <CollapseIcon className={classes.icon} isCollapsed={isCollapsed?.[index]} />
                  <Typography className={classes.title}>
                    {singleCategory.number || singleCategory.code}
                    {singleCategory.name || singleCategory.description ? ' - ' : ''}
                    {singleCategory.name || singleCategory.description}
                  </Typography>
                </div>
              </div>
              <div className={classes.paddingLeft}>
                {!isCollapsed?.[index] && isCollapsed.length > 0 && (
                  <MultilevelAccordion
                    categorizationID={categorizationID}
                    category={singleCategory}
                    categoryMap={singleCategory.subcategories}
                    expanded={isCollapsed?.[index]}
                    id={singleCategory.code}
                  />
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
      {!viewOnly && onClickReplace && (
        <div className={classes.iconRight}>
          <NormalTooltip title="Replace categories">
            <IconButton className={classes.iconGrid} disableRipple onClick={onClickReplace}>
              <Cached className={classes.iconReplace} />
            </IconButton>
          </NormalTooltip>
        </div>
      )}
    </div>
  );
};

export default withStyles(MultilevelCategorizationsStyles)(MultilevelCategorizations);
