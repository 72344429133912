import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';
import { DIALOG_PADDING } from '../../Dialogs/DialogsStyles';

export default (theme: KomodoTheme) =>
  createStyles({
    content: {
      padding: DIALOG_PADDING,
    },
    selected: theme.typography.h3Bold,
  });
