import { FC, useEffect } from 'react';

import { GROUP_BY, SORT_STATUS } from '../../constants';
import { removeYear } from '../../utilities/string';
import JoinSelect, { SelectEntry, getEntry } from '../Select/JoinSelect/JoinSelect';

type DashboardGroupBySelectProps = {
  categorizations: Categorization[];
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  includeStatus: boolean;
  onChange: (newViewBy: UUID) => void;
  viewBy: string;
};

export const DashboardGroupBySelect: FC<DashboardGroupBySelectProps> = ({
  categorizations,
  includeStatus,
  onChange,
  viewBy,
}) => {
  // Options
  const entries = includeStatus
    ? [getEntry(SORT_STATUS, 'Status'), ...(categorizations as SelectEntry[])]
    : (categorizations as SelectEntry[]);

  // Hooks
  // If the selected has been disabled or deleted, we will reset to the
  // first available option...
  useEffect(() => {
    if (!entries.map(({ id }) => id).includes(viewBy)) {
      onChange(entries[0].id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO CT-566: Fix this pls :)
  }, [entries, viewBy]);

  return (
    <div className="flex flex-col gap-0.5">
      <div className="type-label print:hidden">{GROUP_BY}</div>
      <JoinSelect
        entries={entries}
        nameFunc={(v: string) => removeYear(v)}
        onChange={(cID: string | null) => onChange(cID || '')}
        value={viewBy}
      />
    </div>
  );
};
