import { Bookmark } from '@material-ui/icons';

export enum PlaceholderVariants {
  MY = 'MY',
  SHARED = 'SHARED',
}

const variantMy = `You haven't created any reports yet`;
const variantShared = 'No reports have been shared with you yet';
const header = (variant: PlaceholderVariants) =>
  variant === PlaceholderVariants.MY ? variantMy : variantShared;

export const getPlaceholderVariant = (i: number) => {
  switch (i) {
    case 0:
      return PlaceholderVariants.MY;
    case 1:
      return PlaceholderVariants.SHARED;
    default:
      return undefined;
  }
};

type Props = {
  variant?: PlaceholderVariants;
};

export default function ReportsListPlaceholder(props: Props) {
  if (!props.variant) return null;

  return (
    <div className="flex flex-col gap-2 bg-background-primary p-6 text-type-primary">
      <p className="type-body1">{header(props.variant)}</p>
      {props.variant === PlaceholderVariants.MY ? (
        <div className="type-body2">
          To create a custom report, go to a Built-In Report, customize the report view, and then
          save the report using the Report Icon <Bookmark className="!icon-sm" />
        </div>
      ) : null}
    </div>
  );
}
