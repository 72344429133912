import { CostTableColumnInput } from '../../../../generated/graphql';
import { getColumnInputDisplayString } from '../../ProjectCompsSetUtils';

type Props = {
  selectedColumnInputs: CostTableColumnInput[];
  units?: Unit[];
};

// TODO - implement drag drop of columns DD-678
export default function EditCostGridsColumns(props: Props) {
  return (
    <div className="flex gap-2">
      {props.selectedColumnInputs.map((selectedColumnInput) => (
        <EditCostGridsColumn
          key={`${selectedColumnInput.key}${selectedColumnInput.unitID}`}
          selectedColumnInput={selectedColumnInput}
          units={props.units}
        />
      ))}
    </div>
  );
}

type EditCostGridsColumn = {
  selectedColumnInput: CostTableColumnInput;
  units?: Unit[];
};

function EditCostGridsColumn(props: EditCostGridsColumn) {
  const unit = props.units?.find((u) => u.id === props.selectedColumnInput.unitID);
  return (
    <div className="flex h-64 w-40 flex-col gap-4 rounded border-2 stroke-border-default p-3">
      <div className="flex flex-col gap-4">
        <div className="flex w-full justify-end">
          {getColumnInputDisplayString(
            props.selectedColumnInput.key,
            unit?.abbreviationSingular,
            true,
            true
          )}
        </div>
        <EditCostGridsColumnLinesGraphic />
      </div>
    </div>
  );
}

function EditCostGridsColumnLinesGraphic() {
  return (
    <div className="flex flex-col gap-1">
      <div className="h-6 bg-background-2" />
      <div className="h-6 bg-background-2" />
      <div className="h-6 bg-background-2" />
      <div className="h-6 bg-background-2" />
      <div className="h-6 bg-background-2" />
      <div className="h-6 bg-background-2" />
    </div>
  );
}
