import { useMutation } from '@apollo/client';

import { refetchProjectBannersInformation } from '../../../api/refetchSets';
import {
  CreateProjectBannerMutation,
  CreateProjectBannerMutationVariables,
  RemoveProjectBannerMutation,
  RemoveProjectBannerMutationVariables,
} from '../../../generated/graphql';

import { createProjectBannerMutation, removeProjectBannerMutation } from './hooks/queries';

export function useCreateProjectBanner() {
  const [createProjectBannerFunc] = useMutation<
    CreateProjectBannerMutation,
    CreateProjectBannerMutationVariables
  >(createProjectBannerMutation);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  return (assetID: UUID, assetName: string, projectID: UUID, onSuccess?: any, onFailure?: any) => {
    createProjectBannerFunc({
      variables: { assetID, assetName, projectID },
      refetchQueries: refetchProjectBannersInformation(projectID),
    })
      .then((result) => {
        if (!result || !result.data) return;
        const {
          data: { createProjectBanner: projectBanner },
        } = result;
        if (onSuccess) onSuccess(projectBanner);
      })
      .catch(() => {
        if (onFailure) onFailure();
      });
  };
}

export function useRemoveProjectBanner() {
  const [removeProjectBannerFunc] = useMutation<
    RemoveProjectBannerMutation,
    RemoveProjectBannerMutationVariables
  >(removeProjectBannerMutation);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  return (bannerID: UUID, projectID: UUID, onSuccess?: any, onFailure?: any) => {
    removeProjectBannerFunc({
      variables: { bannerID, projectID },
      refetchQueries: refetchProjectBannersInformation(projectID),
    })
      .then((result) => {
        if (!result || !result.data) return;
        const {
          data: { removeProjectBanner: projectBanner },
        } = result;
        if (onSuccess) onSuccess(projectBanner);
      })
      .catch(() => {
        if (onFailure) onFailure();
      });
  };
}
