import { createStyles } from '@material-ui/core/styles';

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
const styles = (theme: any) =>
  createStyles({
    tooltip: {
      zIndex: 999999,
      maxWidth: 250,
      padding: 8,
      background: theme.palette.joinGrey400,
      color: theme.palette.primaryGrey,
      textAlign: 'left',
      textTransform: 'none',
      border: `1px solid ${theme.palette.joinGrey400}`,
      outline: '1px solid transparent',
      fontSize: 13,
      boxShadow: theme.hintShadow,
    },
    popper: {
      opacity: 1,
    },
  });

export default styles;
