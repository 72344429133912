import { FC, useEffect, useState } from 'react';
import { isUUID } from 'validator';

import { Typography } from '@material-ui/core';

import {
  addItemCategoryAnalytics,
  removeItemCategoryAnalytics,
} from '../../../analytics/analyticsEventProperties';
import {
  getCategorizationsForProjectFromQueryData,
  useProjectCategorizationsQuery,
} from '../../../hooks/useProjectCategorizationsQuery';
import useSendAnalytics from '../../../hooks/useSendAnalytics';
import { withStyles } from '../../../theme/komodo-mui-theme';
import { removeYear } from '../../../utilities/string';
import { getItemTypeAnalytics } from '../../Items/ItemsUtils';
import { BACK, isSelectCategoryInteraction } from '../../Select/SelectCategory/SelectCategoryUtils';
import SelectCategoryChipInputSingle from '../../Select/SelectCategoryChipInput/SelectCategoryChipInputSingle';
import {
  useRemoveItemCategoryUpdate,
  useSetItemCategoryUpdate,
} from '../../ve-item-details/ItemDetailsHooks';

import styles from './ItemSidebarStyles';
import { getSidebarLocation } from './ItemSidebarUtils';

type ItemSidebarCollapseCategoriesProps = {
  canEditItemCategories: boolean;
  classes: Classes<typeof styles>;
  item: ItemLike;
  projectId: UUID;
  onItemMutated?: () => void;
};

const ItemSidebarCollapseCategories: FC<ItemSidebarCollapseCategoriesProps> = ({
  canEditItemCategories,
  classes,
  item,
  projectId,
  onItemMutated,
}) => {
  const sendAnalytics = useSendAnalytics();
  const { data } = useProjectCategorizationsQuery(projectId);
  const categorizations = getCategorizationsForProjectFromQueryData(data);
  const { categories } = item || {};

  const [itemCategories, setItemCategories] = useState<Category[]>([]);

  useEffect(() => {
    if (itemCategories !== categories) setItemCategories(categories as Item['categories']);
  }, [itemCategories, categories]);

  // Categories
  const updateSetItemCategory = useSetItemCategoryUpdate({ onCompleted: onItemMutated });
  const setItemCategory = (id: UUID, category: Category) => {
    if (!category || !category.categorization) return;
    const isItemCategoryUpdate = !item.categories.some(
      (c) =>
        category.categorization &&
        c.categorization &&
        c.categorization.id === category.categorization.id &&
        c.id === category.id
    );
    if (isItemCategoryUpdate) {
      sendAnalytics(
        addItemCategoryAnalytics(id, category, getItemTypeAnalytics(item), getSidebarLocation())
      );
      setItemCategories([
        ...itemCategories.filter(
          (c) =>
            c.categorization &&
            category.categorization &&
            c.categorization.id !== category.categorization.id
        ),
        category,
      ]);
      updateSetItemCategory(projectId, id, category.id);
    }
  };

  const updateRemoveItemCategory = useRemoveItemCategoryUpdate({ onCompleted: onItemMutated });
  const removeItemCategory = (id: UUID, category?: Category) => {
    if (!category || !category.categorization) return;
    sendAnalytics(
      removeItemCategoryAnalytics(id, category, getItemTypeAnalytics(item), getSidebarLocation())
    );
    setItemCategories([
      ...itemCategories.filter(
        (c) =>
          c.categorization &&
          category.categorization &&
          c.categorization.id !== category.categorization.id
      ),
    ]);
    updateRemoveItemCategory(projectId, id, category.id);
  };

  return (
    <div className={classes.containerPadding}>
      {categorizations &&
        categorizations.map((cat: Categorization) => {
          const selectedCategory =
            item &&
            itemCategories &&
            itemCategories.find(
              // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
              (category: any) => category.categorization && category.categorization.id === cat.id
            );
          return (
            <div key={cat.id} data-cy="itemSidebar-collapseCategories">
              <div className={classes.categoryPadding} id={`${cat.name}-${item ? item.id : ''}`}>
                <Typography variant="caption">{removeYear(cat.name)}</Typography>
                <div>
                  <SelectCategoryChipInputSingle
                    categorizations={[cat]}
                    disabled={!canEditItemCategories}
                    id={`${cat.name}-${item ? item.id : ''}`}
                    selectedCategory={selectedCategory}
                    setCategory={(category) => {
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
                      let formattedCategory: any = {};
                      if (isSelectCategoryInteraction(category) && category.variant !== BACK) {
                        formattedCategory = category;
                      } else {
                        const { categorization, id, level, levels, name, number } = category;
                        formattedCategory = {
                          categorization,
                          level,
                          levels,
                          name,
                          number,
                          id,
                        };
                      }
                      // valid cat
                      if (
                        formattedCategory &&
                        formattedCategory.id &&
                        isUUID(formattedCategory.id)
                      ) {
                        setItemCategory(item.id, formattedCategory);
                      } else {
                        // if uncategorized remove
                        removeItemCategory(item.id, selectedCategory);
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
};
export default withStyles(styles)(ItemSidebarCollapseCategories);
