import { fromNow } from '../../../../../utilities/dates';

export default ({
  updatedAt,
  updatedBy,
  onClick,
}: {
  updatedAt?: string;
  updatedBy?: string;
  onClick?: () => void;
}) => (
  <div
    className="flex h-24 items-center type-label"
    onClick={onClick}
    onKeyDown={(event) => {
      if (onClick && event.key === 'Enter') onClick();
    }}
  >
    {updatedAt ? fromNow(updatedAt) : ''} {updatedBy ? `by ${updatedBy}` : ''}
  </div>
);
