import { ApolloError } from '@apollo/client';

import useProjectPropsQuery from '../../../hooks/useProjectPropsQuery';

const getStatusCodeFromApolloError = (error: ApolloError | undefined) =>
  error &&
  error.networkError &&
  'statusCode' in error.networkError &&
  error.networkError.statusCode;

export const useHasProjectAccess = (projectID: string | null | undefined) => {
  const { data, error, loading } = useProjectPropsQuery(projectID);
  const statusCode = getStatusCodeFromApolloError(error);
  const hasError = statusCode && [401, 404].includes(statusCode);
  const hasAccess = !hasError && data?.project?.hasAccess !== false;
  return { loading, hasAccess };
};
