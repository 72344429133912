import { Refresh } from '@material-ui/icons';

import { SourceSystem } from '../../../../generated/graphql';
import { BabyButton, Tooltip } from '../../../scales';

import {
  getItemIntegrationObjectTypeBySourceSystem,
  getSourceSystemString,
  loadTimeDateString,
} from './IntegrationsUtils';

type Props = {
  onClick: () => void;
  pageLoadTime?: Date;
  sourceSystem: SourceSystem;
};

export default function ItemIntegrationObjectLastUpdated(props: Props) {
  const time = props.pageLoadTime ?? new Date();
  return (
    <div className="h-6 w-6">
      <Tooltip
        content={`${getItemIntegrationObjectTypeBySourceSystem(
          props.sourceSystem
        )} last updated from ${getSourceSystemString(props.sourceSystem)} ${loadTimeDateString(
          time
        )}`}
        placement="bottom"
      >
        <BabyButton aria-label="currency edit icon" icon={<Refresh />} onClick={props.onClick} />
      </Tooltip>
    </div>
  );
}
