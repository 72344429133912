import { getOperationName } from '@apollo/client/utilities';
import { Add, Help } from '@material-ui/icons';

import {
  CompanyAdminEventType,
  companyAdminAnalyticsEvent,
} from '../../../analytics/analyticsEventProperties';
import { currentCollaborator } from '../../../hooks/queries';
import useIsJoinSupport from '../../../hooks/useIsJoinSupport';
import useSendAnalytics from '../../../hooks/useSendAnalytics';
import { useProjectID } from '../../../utilities/routes/params';
import { isNonNullable } from '../../../utilities/types';
import useCreateCollaborators from '../../Collaborators/hooks/CreateCollaboratorsHook';
import { useCurrentUser } from '../../contexts/current-user';
import { Button, Tooltip } from '../../scales';

import ModeBar from './ModeBar';

export default function ViewOnlyModeBar() {
  const sendAnalytics = useSendAnalytics();

  const [createCollaborators] = useCreateCollaborators(
    [getOperationName(currentCollaborator)].filter(isNonNullable) // only include if operation name exists
  );

  const isJoinSupport = useIsJoinSupport();

  const currentUserEmail = useCurrentUser().email;
  const projectID = useProjectID();
  if (!projectID) return null;

  const isInsights = window.location.pathname.startsWith('/insights/');
  if (isInsights) return null;

  return (
    <ModeBar
      data-cy="view-only-bar"
      title={
        <div className="flex gap-2">
          <div>View-Only Mode</div>
          <Tooltip content='As Company Admin, you have access to view all your company projects. To make changes to a project, click on "Add yourself as Project Administrator".'>
            <div className="icon-sm">
              <Help fontSize="inherit" />
            </div>
          </Tooltip>
        </div>
      }
    >
      <div className="ml-auto">
        <Tooltip
          content={
            <div>
              <p>
                You are part of the Join Support company and cannot add yourself to the project this
                way.
              </p>
              <ul className="ml-4 list-disc">
                <li>In non-production environments, use the Admin console.</li>
                <li>
                  In production, either contact the project administrator or use the Admin console
                  if you have the company&apos;s permission.
                </li>
              </ul>
            </div>
          }
          isDisabled={!isJoinSupport}
        >
          <Button
            data-cy="view-only-bar-add-self-button"
            isDisabled={isJoinSupport}
            label="Add yourself as Project Administrator"
            onClick={() => {
              createCollaborators(
                projectID,
                [
                  {
                    userEmail: currentUserEmail,
                    allTrades: true,
                    roleName: 'Administrator',
                    responsibility: '',
                    trades: [],
                  },
                ],
                ''
              );
              sendAnalytics(companyAdminAnalyticsEvent(CompanyAdminEventType.ADD_PROJECT_ADMIN));
            }}
            startIcon={<Add />}
            type="warning"
          />
        </Tooltip>
      </div>
    </ModeBar>
  );
}
