import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../../theme/komodo-mui-theme';

export default (theme: KomodoTheme) =>
  createStyles({
    avatar: {
      width: 48,
      height: 48,
    },
    bookmarkName: {
      width: 100,
    },
    button: {
      zIndex: 1066,
      margin: 16,
      width: 56,
      height: 56,
      position: 'absolute',
      right: 0,
      [theme.breakpoints.up('md')]: {
        top: 0,
      },
      [theme.breakpoints.down('sm')]: {
        bottom: 0,
      },
      opacity: 0.9,
    },
    chipLabel: {
      position: 'absolute',
      left: 48,
      top: 12,
      width: 100,
      textAlign: 'left',
    },
    control: {
      backgroundColor: theme.modelViewer.selectColor,
      opacity: 0.9,
      margin: 4,
    },
    editOptions: {
      color: theme.palette.joinPrimary,
      backgroundColor: theme.modelViewer.backgroundColor,
      position: 'absolute',
      top: 16,
      left: 100,
      whiteSpace: 'nowrap',
      padding: '4px',
      borderRadius: '40px',
      [theme.breakpoints.down('sm')]: {
        left: 16,
      },
    },
    editMarkup: {
      backgroundColor: theme.modelViewer.backgroundColor,
      position: 'absolute',
      top: 106,
      left: 80,
      whiteSpace: 'nowrap',
      borderRadius: '40px',
    },
    interface: {
      position: 'absolute',
      color: 'white',
      padding: '16px',
      width: 64,
      opacity: 0.9,
      zIndex: 1066,
    },
    markup: {
      color: theme.modelViewer.selectColor,
      border: theme.border.line,
      backgroundColor: theme.modelViewer.backgroundColor,
    },
    select: {
      color: theme.modelViewer.selectColor,
      backgroundColor: theme.modelViewer.backgroundColor,
    },
    selected: {
      backgroundColor: theme.modelViewer.selectColor,
      opacity: 0.8,
      margin: 4,
    },
    thumbnails: {
      [theme.breakpoints.down('sm')]: {
        position: 'absolute',
        top: 100,
      },
    },
    view: {
      backgroundColor: theme.palette.primaryGrey,
      opacity: 0.7,
      margin: 4,
    },
  });
