import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';

export const mentionInputClassName = 'mentions';
const minHeight = 48;
const padding = 4;
export const suggestionPadding = 8;
export const avatarSize = 16;
const suggestionBoxWidth = 400;

export default (theme: KomodoTheme) =>
  createStyles({
    root: {
      color: theme.palette.primaryGrey,
      padding: 4,
      borderRadius: 4,
      border: theme.border.line,
      '&:focus': {
        border: theme.border.lineBorderBlack,
      },
    },
    container: {
      ...theme.typography.body1,
      lineHeight: 'inherit',
      position: 'relative',
      width: '100%',
      margin: 0,
      zIndex: 6, // needs to be > 5 to appear above the "row" class in CostReportListHeaderStyles
      // Here's all our mention styles
      [`& .${mentionInputClassName}__control`]: {
        minHeight,
      },
      [`& .${mentionInputClassName}__input`]: {
        minHeight,
        padding,
        color: 'transparent',
        caretColor: theme.palette.joinPrimary,
        border: theme.border.line,
        borderRadius: 4,
        [`&::placeholder`]: {
          color: theme.palette.joinGrey600,
        },
        lineHeight: 'inherit',
      },
      [`& .${mentionInputClassName}__highlighter`]: {
        padding,
        border: theme.border.line,
      },
      [`& .${mentionInputClassName}__highlighter__substring`]: {
        visibility: 'unset !important',
        color: theme.palette.joinPrimary,
      },
      [`& .${mentionInputClassName}__input:focus`]: {
        outlineWidth: 2,
        outlineColor: theme.palette.focusBorderColor,
      },
      [`& .${mentionInputClassName}__suggestions`]: {
        marginTop: '10px !important', // to correctly locate the popover
        minWidth: 'unset !important',
      },
      [`& .${mentionInputClassName}__suggestions__list`]: {
        boxShadow: 'rgba(0, 0, 0, 0.4) 0px 1px 4px',
        marginLeft: 4,
      },
      [`& .${mentionInputClassName}__suggestions__item`]: {
        padding: suggestionPadding,
        [`&:hover`]: {
          backgroundColor: theme.palette.hoverBackground,
        },
      },
      [`& .${mentionInputClassName}__suggestions__item--focused`]: {
        backgroundColor: theme.palette.focusSelectBackground,
      },
    },
    avatar: {
      height: avatarSize,
      width: avatarSize,
      marginRight: suggestionPadding,
    },
    mention: {
      'font-weight': 'inherit !important',
      color: theme.palette.badgeColor,
      background: 'transparent',
    },
    itemReference: {
      background: theme.palette.backgroundGrey,
      color: theme.palette.joinPrimary,
      borderRadius: 4,
    },
    suggestion: {
      width: suggestionBoxWidth,
      display: 'inline-flex',
      alignItems: 'center',
    },
    spacer: {
      flexGrow: 1,
    },
    suggestions: {
      '&:hover': {
        backgroundColor: theme.palette.backgroundGrey,
      },
      '&selected': {
        backgroundColor: theme.palette.backgroundGrey,
      },
    },
    infoBadge: {
      ...theme.typography.webLabel,
      background: theme.palette.backgroundGrey,
      borderRadius: 4,
      padding: '1px 6px',
      flex: 'auto',
      flexGrow: 0,
      marginLeft: 10,
      display: 'inline',
      whiteSpace: 'nowrap',
    },
    control: {
      'white-space': 'pre-wrap',
      flexGrow: 1,
      overflowWrap: 'break-word',
      wordBreak: 'break-word',
      padding: 4,
    },
    dropdown: {
      position: 'absolute',
      display: 'block',
      borderRadius: '6px',
      background: 'white',
      boxShadow: 'rgba(0, 0, 0, 0.4) 0px 1px 4px',
      zIndex: 1,
    },
    statusIconHover: {
      marginRight: suggestionPadding,
    },
    ellipsis: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    emojiAvatar: {
      fontSize: avatarSize,
    },
    emojiText: {
      paddingLeft: suggestionPadding,
    },
  });
