import {
  EventType,
  ItemHistoryQuery,
  ItemHistoryQueryVariables,
} from '../../../../generated/graphql';
import { useQuery } from '../../../../hooks/useMountAwareQuery';

import { itemHistoryQuery } from './queries';

export default function useItemHistoryQuery(
  itemID: UUID | null,
  viewFilter: ViewFilterInput,
  eventTypeFilters: EventType[] = [],
  costMode: CostMode
) {
  return useQuery<ItemHistoryQuery, ItemHistoryQueryVariables>(itemHistoryQuery, {
    variables: {
      viewFilter,
      costMode,
      itemID,
      eventTypeFilters,
    },
    skip: !itemID,
    context: { batch: true },
  });
}
