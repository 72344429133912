import { ReactNode } from 'react';

type Props = {
  children: ReactNode;
  shouldGrow: boolean;
};

export const COLUMN_WIDTH = 470;

export default function PresentationColumn(props: Props) {
  return (
    <div
      className={`flex flex-col gap-3 ${
        props.shouldGrow ? 'min-w-0 flex-shrink flex-grow' : 'flex-shrink-0'
      }`}
      style={{ width: props.shouldGrow ? undefined : COLUMN_WIDTH }}
    >
      {props.children}
    </div>
  );
}
