import { useMutation } from '@apollo/client';

import {
  LinkIntegrationsEntityMutation,
  LinkIntegrationsEntityMutationOptions,
  LinkIntegrationsEntityMutationVariables,
  SourceSystem,
  SourceType,
} from '../../../../../generated/graphql';

import { linkIntegrationsEntityMutation } from './queries';

export const useLinkIntegrationsEntityMutation = (
  options?: LinkIntegrationsEntityMutationOptions
) => {
  const [mutationFunc, mutationResult] = useMutation<
    LinkIntegrationsEntityMutation,
    LinkIntegrationsEntityMutationVariables
  >(linkIntegrationsEntityMutation, options);
  return [
    (
      sourceSystem: SourceSystem,
      sourceEntityType: SourceType,
      sourceEntityID: string,
      sourceCompanyID: string | undefined,
      projectID: UUID
    ) => {
      return mutationFunc({
        variables: {
          sourceSystem,
          sourceEntityType,
          sourceEntityID,
          sourceCompanyID,
          projectID,
        },
      });
    },
    mutationResult,
  ] as const;
};
