export type LegendElementType = {
  borderStyle?: string;
  component?: JSX.Element;
  index?: number;
  percentage?: string;
  text: string;
  title?: string;
  link?: string;
  projectID?: UUID;
};

export type LegendData = {
  colorMap: Map<string, string>;
  customColors: string[];
  legendElements: LegendElementType[];
};

export const formatLegendDataFromStrings = (data: string[]) => {
  if (!data) return [];
  return data.map((text) => ({
    text,
  }));
};

export const getColorMapItem = (
  index: number | undefined,
  text: string,
  colorMap?: Map<string, string>,
  customColors?: string[]
) => {
  const colorValue = colorMap?.get(text);
  if (colorValue) {
    return colorValue;
  }
  if (!index) return '';
  const customColorIndex =
    customColors && index >= customColors.length ? index - customColors.length : index;
  return customColors && index >= 0 ? customColors[customColorIndex] : '';
};

export const getLegendElementColorFunction =
  (colorMap?: Map<string, string>, customColors?: string[]) => (element: LegendElementType) => {
    const { index, text } = element;
    return getColorMapItem(index, text, colorMap, customColors);
  };
