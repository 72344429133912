import { ComponentProps, useCallback, useMemo } from 'react';

import { itemSidebarScenariosOpenVar } from '../../../../api/apollo/reactiveVars';
import { ItemsSortKey } from '../../../../generated/graphql';
import { generateTitle } from '../../../Items/ItemsListItem/ItemsListItemUtils';
import { SortManager, Table } from '../../../scales';
import useSendScenariosAnalytics, {
  ScenariosAnalyticsEvents,
} from '../../hooks/useSendScenariosAnalytics';
import { Scenario, ScenarioItems } from '../../types';

import CostCell from './cells/CostCell';
import IndicatorCell from './cells/IndicatorCell';
import NameCell from './cells/NameCell';
import StatusCellEdit from './cells/StatusCell/StatusCellEdit/StatusCellEdit';
import { getScenarioItemEntries } from './ItemsTableUtils';

type Props = {
  scenario: Scenario;
  items: ScenarioItems;
  sortManager: SortManager;
  onDeleteScenarioItem: ComponentProps<typeof StatusCellEdit>['onDeleteScenarioItem'];
  onNavToItem: ComponentProps<typeof StatusCellEdit>['onNavToItem'];
  onRemoveItem: ComponentProps<typeof StatusCellEdit>['onRemoveItem'];
  onUpdateItems: ComponentProps<typeof StatusCellEdit>['onUpdateItems'];
};

enum ScenarioTableColumns {
  INDICATOR = 'indicator',
  TITLE = 'title',
  COST = 'cost',
  STATUS = 'status',
}

const ItemsTable = (props: Props) => {
  // Data
  const sendScenariosAnalytics = useSendScenariosAnalytics();

  // Content
  const headerContent = [
    {
      key: ScenarioTableColumns.INDICATOR,
      rightAligned: true,
    },
    {
      key: ScenarioTableColumns.TITLE,
      headerSortKey: ItemsSortKey.SORT_NUMBER,
      copy: `Items (${props.items.filter((i) => !i.parentID).length})`,
    },
    {
      key: ScenarioTableColumns.COST,
      headerSortKey: ItemsSortKey.SORT_COST_IMPACT,
      copy: 'Cost',
      rightAligned: true,
    },
    {
      key: ScenarioTableColumns.STATUS,
      headerSortKey: ItemsSortKey.SORT_STATUS,
      copy: 'Status',
    },
  ];

  const onEditItem = useCallback(
    (id: string) => {
      sendScenariosAnalytics(ScenariosAnalyticsEvents.EDIT_SCENARIO_ITEM_SIDE_BAR, {
        scenarioID: props.scenario.scenarioID,
        itemID: id,
      });
      itemSidebarScenariosOpenVar({ id, color: props.scenario.color ?? '' });
    },
    [props.scenario.color, props.scenario.scenarioID, sendScenariosAnalytics]
  );

  const entries = useMemo(
    () =>
      getScenarioItemEntries(props.items).map((item) => [
        {
          component: <IndicatorCell item={item} scenario={props.scenario} />,
          key: ScenarioTableColumns.INDICATOR,
        },
        {
          component: <NameCell showClamped title={generateTitle(item)} />,
          key: ScenarioTableColumns.TITLE,
        },
        {
          component: <CostCell cost={item.cost} />,
          key: ScenarioTableColumns.COST,
        },
        {
          component: (
            <StatusCellEdit
              key={item.id}
              item={item}
              onDeleteScenarioItem={props.onDeleteScenarioItem}
              onEditItem={onEditItem}
              onNavToItem={props.onNavToItem}
              onRemoveItem={props.onRemoveItem}
              onUpdateItems={props.onUpdateItems}
            />
          ),
          key: ScenarioTableColumns.STATUS,
        },
      ]),
    [
      onEditItem,
      props.items,
      props.onDeleteScenarioItem,
      props.onNavToItem,
      props.onRemoveItem,
      props.onUpdateItems,
      props.scenario,
    ]
  );

  return (
    <Table
      columnWidths={['max-content', 'minmax(100px,6fr)', 'max-content', 'max-content']}
      entries={entries}
      hasParentScroll
      headerContent={headerContent}
      isCompact
      sortManager={props.sortManager}
    />
  );
};

export default ItemsTable;
