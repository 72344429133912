import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../../theme/komodo-mui-theme';

export default (theme: KomodoTheme) =>
  createStyles({
    container: {
      ...theme.typography.body1,
      backgroundColor: theme.palette.pinkBadge,
      borderRadius: theme.border.radius,
      color: theme.palette.backgroundWhite,
      display: 'inline',
      fontFamily: theme.typography.fontFamily,
      fontWeight: 500,
      margin: 0,
      padding: '2px 6px',
      textAlign: 'center',
    },
  });
