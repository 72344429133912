import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';

const UNIT_SWATCH_SIZE = 10;

export default (theme: KomodoTheme) =>
  createStyles({
    checkbox: {
      height: 20,
      width: 20,
      margin: 2,
    },
    rowContainer: {
      display: 'flex',
      alignItems: 'center',
      padding: '4px 8px 4px 12px',
    },
    rowContent: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
      paddingTop: 1,
      paddingLeft: 2,
    },
    titleText: {
      paddingTop: 2,
      paddingLeft: 2,
      ...theme.typography.h3CardHeader,
    },
    unitSwatch: {
      height: UNIT_SWATCH_SIZE,
      width: UNIT_SWATCH_SIZE,
    },
    unitSwatchContainer: {
      padding: 4,
    },
  });
