import { useMutation } from '@apollo/client';

import { REFETCH_SCENARIO_SANDBOX } from '../../../api/refetchSets';

import { removeItemFromScenariosMutation } from './queries';

export const useRemoveItemFromScenariosMutation = () => {
  const [removeItemFromScenarios] = useMutation(removeItemFromScenariosMutation, {
    refetchQueries: REFETCH_SCENARIO_SANDBOX,
  });
  const removeItemFromScenariosFn = ({
    scenarioIDs,
    itemID,
  }: {
    scenarioIDs: UUID[];
    itemID: UUID;
  }) => {
    removeItemFromScenarios({
      variables: {
        scenarioIDs,
        itemID,
      },
    });
  };
  return [removeItemFromScenariosFn];
};
