import { FC, useMemo } from 'react';

import { useReactiveVar } from '@apollo/client';
import { Typography } from '@material-ui/core';

import { filesExplorerSortStateVar } from '../../../../api/apollo/reactiveVars';
import { FilesSortBy, FilesSortKey } from '../../../../generated/graphql';
import { withStyles } from '../../../../theme/komodo-mui-theme';
import { getExtension } from '../../../../utilities/string';
import TableSelectable from '../../../dragon-scales/TableSelectable/TableSelectable';
import { filterEnabledSelectableEntries } from '../../../dragon-scales/TableSelectable/TableSelectableUtils';
import SearchText from '../../../Search/SearchText/SearchText';
import useMemoWrapper from '../../../useMemoWrapper';

import styles from './FilesTableStyles';
import {
  FileTableCell,
  getDisabledIndices,
  getEntries,
  getEntryIsPending,
  getSelectableIndices,
  getSelectedIndices,
} from './FilesTableUtils';
import FileThumb from './FileThumb';

export const filesTableGridWidth = ['96px', 'minmax(300px, 3fr)', '88px', '152px'];

const FILE_UPLOADING_NAME = 'Uploading...';

export type FilesTableHeaderContent = {
  copy: string;
  headerSortKey: FilesSortKey | null;
  key: string;
};

export const membersHeaderContent = [
  {
    copy: ' ',
    headerSortKey: null,
    key: 'sourceIcon',
  },
  {
    copy: 'Name',
    headerSortKey: FilesSortKey.NAME,
    key: 'sourceName',
  },
  {
    copy: 'Type',
    headerSortKey: FilesSortKey.TYPE,
    key: 'sourceType',
  },
  {
    copy: 'Date Updated',
    headerSortKey: FilesSortKey.DATE,
    key: 'dateUpdated',
  },
] as FilesTableHeaderContent[];

type FilesTableProps = {
  classes: Classes<typeof styles>;
  entries: FileTableCell[];
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  loading?: boolean;
  onEntry: (entry: FileTableCell) => void;
  onToggleEntry: (entry: FileTableCell) => void;
  onSelectAll: (ids: string[]) => void;
  onUnselectAll: (ids: string[]) => void;
  selectedIds: string[];
  sortManager?: {
    sortState: FilesSortBy;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
    setSort: (state: any) => void;
  };
};

const FilesTable: FC<FilesTableProps> = ({
  classes,
  entries,
  loading,
  onEntry,
  onToggleEntry,
  onSelectAll,
  onUnselectAll,
  selectedIds,
  sortManager: sortManagerOuter,
}) => {
  const searchTerm = '';

  // Component Fns
  const columnFns = useMemo(() => {
    const thumbnail = (entry: FileTableCell) => <FileThumb entry={entry} />;
    const name = (entry: FileTableCell) => (
      <Typography
        className={`${classes.title} ${entry.isFolder ? classes.linkTitle : ''}`}
        data-cy="entry-name"
        onClick={entry.isFolder ? () => onEntry(entry) : undefined}
      >
        <SearchText
          searchTerm={searchTerm}
          text={getEntryIsPending(entry) ? FILE_UPLOADING_NAME : entry.name}
        />
      </Typography>
    );

    const type = (entry: FileTableCell) => (
      <Typography className={classes.text}>
        <SearchText
          searchTerm={searchTerm}
          text={entry.isFolder ? 'Folder' : getExtension(entry.name).toUpperCase()}
        />
      </Typography>
    );

    const latestActivity = (entry: FileTableCell) => (
      <Typography className={classes.text}>
        {entry.sourceUpdatedAt ? new Date(entry.sourceUpdatedAt).toLocaleDateString() : ''}
      </Typography>
    );
    return [thumbnail, name, type, latestActivity];
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO CT-566: Fix this pls :)
  }, [searchTerm]);

  const joinTableEntries = useMemoWrapper(getEntries, entries, columnFns);

  const sortState = useReactiveVar(filesExplorerSortStateVar);

  const sortManager = sortManagerOuter || {
    sortState,
    setSort: (state) => filesExplorerSortStateVar(state),
  };

  const onToggleTableCell = (index: number) => onToggleEntry(entries[index]);

  const selectedIndices = getSelectedIndices(entries, selectedIds);
  const selectableIndices = getSelectableIndices(entries);
  const disabledIndices = getDisabledIndices(entries);

  const onJoinTableSelectAll = () =>
    onSelectAll(filterEnabledSelectableEntries(entries, selectableIndices, disabledIndices));

  const onJoinTableUnselectAll = () =>
    onUnselectAll(filterEnabledSelectableEntries(entries, selectableIndices, disabledIndices));

  return (
    <TableSelectable
      columnWidths={filesTableGridWidth}
      disabledIndices={disabledIndices}
      entries={joinTableEntries}
      headerContent={membersHeaderContent}
      loading={loading}
      onSelectAll={onJoinTableSelectAll}
      onToggleEntry={onToggleTableCell}
      onUnselectAll={onJoinTableUnselectAll}
      parentScroll
      selectableIndices={selectableIndices}
      selectedIndices={selectedIndices}
      sortManager={sortManager}
    />
  );
};

export default withStyles(styles)(FilesTable);
