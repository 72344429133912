import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';

export default (theme: KomodoTheme) =>
  createStyles({
    header: {
      paddingBottom: theme.spacing.unit,
    },
    inputHeight: {
      minHeight: 18,
      lineHeight: 'normal',
    },
    setting: {
      paddingBottom: theme.spacing.unit * 6,
    },
  });
