import { FC } from 'react';

import { setCollapseAnalytics } from '../../../analytics/analyticsEventProperties';
import { ReportGroups } from '../../../api/gqlEnums';
import { COLLAPSE } from '../../../constants';
import {
  CollapseSettings,
  SetSettingsFunctionType,
  useSetCollapse,
} from '../../../utilities/urlState';
import CollapseEntry from '../../Select/CollapseEntry/CollapseEntry';
import ReportsList, { ReportsListEntryDataType } from '../ReportsList/ReportsList';
import ReportsListPlaceholder, {
  getPlaceholderVariant,
} from '../ReportsList/ReportsListPlaceholder';

const typesByIndex = [
  ReportGroups.MY_REPORTS,
  ReportGroups.SHARED_REPORTS,
  ReportGroups.BUILT_IN_REPORTS,
];

type ReportsAccordionProps = {
  accordionReports: ReportsListEntryDataType[][];
  accordionHeaders: ((count: number) => string)[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  settings: Record<string, any>;
  setSettings: SetSettingsFunctionType;
};

const ReportsAccordion: FC<ReportsAccordionProps> = ({
  accordionReports,
  accordionHeaders,
  settings,
  setSettings,
}) => {
  const setCollapse = useSetCollapse(
    settings as CollapseSettings,
    setSettings,
    setCollapseAnalytics
  );

  return (
    <>
      {accordionReports.map((reports, i) => {
        const nodeId = typesByIndex[i];
        return (
          <CollapseEntry
            key={nodeId}
            collapsed={settings[COLLAPSE].includes(nodeId)}
            hasNoBorder
            setCollapse={(collapsed: boolean) => setCollapse(collapsed, [nodeId], nodeId)}
            title={accordionHeaders[i](reports.length)}
          >
            <ReportsList
              placeholder={<ReportsListPlaceholder variant={getPlaceholderVariant(i)} />}
              reports={reports}
            />
          </CollapseEntry>
        );
      })}
    </>
  );
};

export default ReportsAccordion;
