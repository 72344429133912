import { Item, Section } from 'react-stately';

import { SelectEntry } from './types';

/**
 * This function is used as a default renderer function that's passed into React Aria hooks as `children`.
 * React Aria uses it mainly to register the entries and their relationships with one another. It does
 * this via the fact that we're rendering react-stately Item and Section components which do...magic.
 * Note that despite this looking verrrrry much like a component, it's not, and can't be due to their API.
 */
export default function entryRenderer(entry: SelectEntry) {
  if (entry.isSection) {
    return (
      <Section key={entry.id} items={entry.entries}>
        {(entry: SelectEntry) => (
          <Item key={entry.id} hasChildItems={entry.hasChildren}>
            {entry.label}
          </Item>
        )}
      </Section>
    );
  }

  return (
    <Item key={entry.id} hasChildItems={entry.hasChildren}>
      {entry.label}
    </Item>
  );
}
