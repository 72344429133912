import { GetProjectTypesQuery, GetProjectTypesQueryVariables } from '../generated/graphql';

import { getAllProjectTypesQuery } from './queries';
import { useQuery } from './useMountAwareQuery';
import { MountPolicy } from './usePolicyOnFirstMount';

export const useGetProjectTypesQuery = (projectID: UUID) =>
  useQuery<GetProjectTypesQuery, GetProjectTypesQueryVariables>(
    getAllProjectTypesQuery,
    {
      variables: { projectID },
      skip: !projectID,
    },
    MountPolicy.SKIP_ON_MOUNT
  );
