import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';

export default (theme: KomodoTheme) =>
  createStyles({
    container: {
      ...theme.typography.webLabel,
      background: theme.palette.badgeBackground,
      padding: 16,
      display: 'flex',
      gap: '10px',
    },
    icon: {
      color: theme.palette.badgeColor,
    },
  });
