import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../../../theme/komodo-mui-theme';

export default (theme: KomodoTheme) =>
  createStyles({
    innerContainer: {
      border: theme.border.line,
      background: 'none',
      display: 'flex',
    },
    formLabel: {
      lineHeight: 'unset',
    },
    formLabelRoot: {
      marginLeft: -4,
    },
    horizontal: {
      display: 'flex',
      flexDirection: 'column',
    },
    innerColumn: {
      flex: '1 0 auto',
      width: 'calc(50% - 40px)',
      padding: 8,
      border: theme.border.line,
    },
    label: {
      ...theme.typography.caption,
      color: theme.palette.shadedGrey,
      fontWeight: 400,
      marginTop: 8,
    },
    smallButton: {
      height: 32,
      width: 32,
    },
    unSelected: {
      opacity: 0.5,
    },
    picker: { marginTop: 8, marginLeft: 24 },
  });
