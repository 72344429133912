import { FC } from 'react';

import { Checkbox, SortManager, Table, TableCell, TableHeader, Tooltip } from '../../scales';
import { CheckboxVariant } from '../../shared-widgets/CheckboxIndeterminateContainer';

import { getCheckboxVariant, isAllSelected } from './TableSelectableUtils';

const CheckboxColumnWidth = '0.1fr';

type TableSelectableProps = {
  columnWidths: string[];
  entries: TableCell[][];
  headerContent: TableHeader[];
  indeterminateIndices?: boolean[];
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  loading?: boolean;
  onOpenCheckboxTooltip?: () => void;
  onSelectAll: () => void;
  onToggleEntry: (index: number) => void;
  onUnselectAll: () => void;
  disabledIndices?: boolean[];
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  parentScroll?: boolean;
  selectableIndices?: boolean[];
  selectedIndices: boolean[];
  sortManager: SortManager;
  tooltips?: string[];
};

const TableSelectable: FC<TableSelectableProps> = (props) => {
  const {
    columnWidths: columnWidthsOuter,
    entries: entriesOuter,
    headerContent: headerContentOuter,
    indeterminateIndices = [],
    loading = false,
    onOpenCheckboxTooltip,
    onSelectAll,
    onToggleEntry,
    onUnselectAll,
    disabledIndices = [],
    selectableIndices = [],
    selectedIndices = [],
    sortManager,
    tooltips = [],
  } = props;

  const onToggleAll = () =>
    isAllSelected(selectedIndices, selectableIndices, disabledIndices)
      ? onUnselectAll()
      : onSelectAll();

  const getHeaderCheckbox = () => {
    const variant = getCheckboxVariant(selectedIndices, selectableIndices, disabledIndices);
    const isSelected = variant === CheckboxVariant.Selected;
    const isIndeterminate = variant === CheckboxVariant.Indeterminate;
    return {
      component: (
        <div className="mx-3">
          <Checkbox
            aria-label="multi-select checkbox"
            data-cy="select-multiple-checkbox"
            isIndeterminate={!isSelected && isIndeterminate}
            isSelected={isIndeterminate || isSelected}
            onChange={() => onToggleAll()}
          />
        </div>
      ),
      copy: '',
      key: 'selection-checkbox',
    };
  };
  const headerContent = [getHeaderCheckbox(), ...headerContentOuter];

  const getCheckboxComponent = (index: number) => {
    const selectable = selectableIndices[index];
    if (!selectable) return undefined;
    const isSelected = selectedIndices[index];
    const isDisabled = disabledIndices[index];
    const isIndeterminate = indeterminateIndices[index];
    const tooltip = tooltips[index];

    return (
      <div className="m-3">
        <Tooltip content={tooltip} isDisabled={!tooltip} onOpen={onOpenCheckboxTooltip}>
          <Checkbox
            aria-label="users"
            data-cy="join-table-selectable-checkbox"
            isDisabled={isDisabled}
            isIndeterminate={!isSelected && isIndeterminate}
            isSelected={isIndeterminate || isSelected}
            onChange={() => onToggleEntry(index)}
          />
        </Tooltip>
      </div>
    );
  };

  const getCheckbox = (index: number) => ({
    component: getCheckboxComponent(index),
    key: index.toString(),
  });
  const entries = entriesOuter.map((row, i) => [getCheckbox(i), ...row]);

  const columnWidths = [CheckboxColumnWidth, ...columnWidthsOuter];

  return (
    <Table
      columnWidths={columnWidths}
      entries={entries}
      hasParentScroll={props.parentScroll}
      headerContent={headerContent}
      isLoading={loading}
      sortManager={sortManager}
    />
  );
};

export default TableSelectable;
