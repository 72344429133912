import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  CompanyAdminEventType,
  companyAdminAnalyticsEvent,
  setCollapseAnalytics,
} from '../../../../analytics/analyticsEventProperties';
import { ProjectType, ProjectVariant } from '../../../../generated/graphql';
import { useGetProjectsWithType } from '../../../../hooks/useGetProjectsWithType';
import useSendAnalytics from '../../../../hooks/useSendAnalytics';
import {
  CollapseSettings,
  usePersistentStates,
  useSetCollapse,
} from '../../../../utilities/urlState';
import { TreeEntry, makeTree } from '../../../../utilities/utilities';
import CollapseEntry from '../../../Select/CollapseEntry/CollapseEntry';
import useMemoWrapper from '../../../useMemoWrapper';
import { useToggleProjectTypeVisibilityMutation } from '../../CompanyHooks';
import { getProjectStatsLink } from '../../CompanyTabUtils';
import AddProjectSubtypeDialog from '../Dialogs/AddProjectSubtypeDialog';
import DeleteProjectTypeDialog from '../Dialogs/DeleteProjectTypeDialog';
import UpdateProjectTypeDialog from '../Dialogs/UpdateProjectTypeDialog';

import IconMenuProjectType from './Common/IconMenuProjectType';
import SubTypesList from './SubTypesList';

type ProjectTypesListProps = {
  projectTypes: ProjectType[];
};

type ProjectTypeEntry = TreeEntry<ProjectType>;

const ProjectTypesList: FC<ProjectTypesListProps> = ({ projectTypes }) => {
  // Create Project Type tree
  const projectTypesTree = useMemoWrapper(makeTree<ProjectType>, projectTypes);
  const accordionHeaders = projectTypesTree.map((t) => t.name);

  // Hooks
  const [projectType, setProjectType] = useState<ProjectTypeEntry | null>(null);
  const toggleProjectTypeVisibility = useToggleProjectTypeVisibilityMutation();
  const { data, loading, refetch } = useGetProjectsWithType(projectType?.id);
  const projectsWithType = data?.getProjectsWithType ?? [];
  const templateCount = projectsWithType.filter(
    (x) => x.variant === ProjectVariant.TEMPLATE
  ).length;
  const navigate = useNavigate();
  const [settings, setSettings] = usePersistentStates(
    window.location,
    'Types',
    { collapse: [], expand: [] },
    `Project Types `
  );
  const setCollapse = useSetCollapse(
    settings as CollapseSettings,
    setSettings,
    setCollapseAnalytics
  );
  const sendAnalytics = useSendAnalytics();

  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [isAddSubtypeDialogOpen, setIsAddSubtypeDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const eventProperties = (type: ProjectTypeEntry, action: string) => ({
    typeName: type.name,
    typeLevel: type.parentID ? 'subtype' : 'type',
    action,
  });

  const onAddSubType = (type: ProjectTypeEntry) => () => {
    setProjectType(type);
    refetch({ typeID: type.id });
    setIsAddSubtypeDialogOpen(true);
    sendAnalytics(
      companyAdminAnalyticsEvent(
        CompanyAdminEventType.PROJECT_TYPES_ACTION,
        eventProperties(type, 'add')
      )
    );
  };
  const onEditType = (type: ProjectTypeEntry) => () => {
    setProjectType(type);
    refetch({ typeID: type.id });
    setIsEditDialogOpen(true);
    sendAnalytics(
      companyAdminAnalyticsEvent(
        CompanyAdminEventType.PROJECT_TYPES_ACTION,
        eventProperties(type, 'edit')
      )
    );
  };
  const onHideType = (type: ProjectTypeEntry) => () => {
    toggleProjectTypeVisibility(type.id, true);
    type.entries?.forEach((subType: ProjectTypeEntry) =>
      toggleProjectTypeVisibility(subType.id, true)
    );
    sendAnalytics(
      companyAdminAnalyticsEvent(
        CompanyAdminEventType.PROJECT_TYPES_ACTION,
        eventProperties(type, 'hide')
      )
    );
  };
  const onShowType = (type: ProjectTypeEntry) => () => {
    toggleProjectTypeVisibility(type.id, false);
    type.entries?.forEach((subType: ProjectTypeEntry) =>
      toggleProjectTypeVisibility(subType.id, false)
    );
    sendAnalytics(
      companyAdminAnalyticsEvent(
        CompanyAdminEventType.PROJECT_TYPES_ACTION,
        eventProperties(type, 'show')
      )
    );
  };
  const onDeleteType = (type: ProjectTypeEntry) => () => {
    setProjectType(type);
    refetch({ typeID: type.id });
    setIsDeleteDialogOpen(true);
    sendAnalytics(
      companyAdminAnalyticsEvent(
        CompanyAdminEventType.PROJECT_TYPES_ACTION,
        eventProperties(type, 'delete')
      )
    );
  };

  return (
    <div className="flex flex-col overflow-y-auto overflow-x-hidden">
      {projectTypesTree.map((type, i) => {
        const nodeId = accordionHeaders[i];
        return (
          <CollapseEntry
            key={nodeId}
            collapsed={!settings.expand.includes(nodeId)}
            endIcon={
              <div data-cy={`menu-${type?.name}`}>
                <IconMenuProjectType
                  onAddSubType={onAddSubType(type)}
                  onDeleteType={onDeleteType(type)}
                  onEditType={onEditType(type)}
                  onHideType={onHideType(type)}
                  onShowType={onShowType(type)}
                  projectType={type}
                />
              </div>
            }
            hasBorderBottom
            hasNoBackground
            hasNoBorder
            hasPadding={false}
            isInactive={type.hidden || false}
            setCollapse={(collapsed: boolean) => {
              setCollapse(collapsed, [nodeId], nodeId);
              sendAnalytics(
                companyAdminAnalyticsEvent(CompanyAdminEventType.PROJECT_TYPES_EXPAND_COLLAPSE, {
                  action: collapsed ? 'collapse' : 'expand',
                })
              );
            }}
            showCollapseIcon={Boolean(type.entries?.length)}
            title={type.name}
          >
            {type.entries && <SubTypesList parentType={type} projectTypes={projectTypesTree} />}
          </CollapseEntry>
        );
      })}
      {projectType && !loading && (
        <UpdateProjectTypeDialog
          isOpen={isEditDialogOpen}
          onClose={() => {
            setIsEditDialogOpen(false);
            setProjectType(null);
          }}
          type={projectType}
          types={projectTypesTree}
        />
      )}
      {projectType && !loading && (
        <AddProjectSubtypeDialog
          isOpen={isAddSubtypeDialogOpen}
          onClose={() => {
            setIsAddSubtypeDialogOpen(false);
            setProjectType(null);
          }}
          parentType={projectType}
          types={projectTypes}
        />
      )}
      {projectType && !loading && (
        <DeleteProjectTypeDialog
          isBlocked={projectsWithType.length !== 0}
          isOpen={isDeleteDialogOpen}
          onClose={() => {
            setIsDeleteDialogOpen(false);
            setProjectType(null);
          }}
          onProjectStats={() => {
            navigate(
              getProjectStatsLink({
                types: [projectType.id, ...(projectType.entries ?? []).map((x) => x.id)],
              })
            );
          }}
          subTypes={projectType.entries ?? []}
          subTypesProjectsCountMap={(() => {
            const map: { [key in UUID]: { projectCount: number; templateCount: number } } = {};
            (projectsWithType ?? []).forEach((v) => {
              const value = map[v.typeID] ?? { projectCount: 0, templateCount: 0 };
              if (v.variant === ProjectVariant.TEMPLATE) {
                map[v.typeID] = { ...value, templateCount: value.templateCount + 1 };
              } else {
                map[v.typeID] = { ...value, projectCount: value.projectCount + 1 };
              }
            });
            return map;
          })()}
          templateCount={templateCount}
          type={projectType}
          typeProjectsCount={projectsWithType.length - templateCount}
        />
      )}
    </div>
  );
};

export default ProjectTypesList;
