import { FC, ReactNode } from 'react';

import { HelpOutlined } from '@material-ui/icons';

import { renderCostString } from '../../CostReport/CostReportUtils';
import { Tooltip } from '../../scales';

type EstimateTotalProps = {
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  boldTopBorder?: boolean;
  color?: 'White' | 'Grey' | 'Blue';
  cost?: Cost;
  cy?: string;
  header?: ReactNode;
  tooltip?: string;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  topBorder?: boolean;
};

const EstimateTotal: FC<EstimateTotalProps> = ({
  boldTopBorder = false,
  color = 'Grey',
  cost = { value: '0' },
  cy = 'project',
  header,
  tooltip,
  topBorder = false,
}) => {
  const rootClass = ['flex', 'p-2', 'pr-7', 'items-center'];
  if (topBorder) rootClass.push('border-t');
  switch (color) {
    case 'White':
      break;
    case 'Blue':
      rootClass.push('bg-entities-milestone');
      rootClass.push('text-type-secondary');
      break;
    default:
      rootClass.push('bg-background-2');
      break;
  }

  if (boldTopBorder) {
    rootClass.push('border-border-calculations-total');
  }

  return (
    <div className={rootClass.join(' ')} data-cy={`${cy}-total`}>
      <div className="ml-9 flex" data-cy="estimate-subheader-total">
        {header}
        {tooltip && (
          <Tooltip content={tooltip}>
            <span>
              <HelpOutlined className="mb-0.5 ml-1 !fill-button-icon-outline !icon-sm" />
            </span>
          </Tooltip>
        )}
      </div>
      <div className="flex-grow text-right type-table-number">
        {renderCostString({
          cost,
          isWide: true,
          isExact: true,
        })}
      </div>
    </div>
  );
};

export default EstimateTotal;
