import { Navigate } from 'react-router-dom';

import { PermissionResource } from '../../generated/graphql';
import { JOIN_INSIGHTS } from '../../moduleEntitlementFlagsList';
import HasModuleEntitlement from '../HasModuleEntitlement';
import HasPermission from '../HasPermission';

import ExecutiveDashboard from './ExecutiveDashboard';

export default function InsightsClassicRoute() {
  return (
    <HasModuleEntitlement fallback={<Navigate replace to="/404" />} flag={JOIN_INSIGHTS}>
      <HasPermission
        fallback={<Navigate replace to="/404" />}
        permission={PermissionResource.INSIGHTS_ACCESS}
      >
        <ExecutiveDashboard />
      </HasPermission>
    </HasModuleEntitlement>
  );
}
