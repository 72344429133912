import { isScenarioVisibility } from '../../Items/ItemsUtils';
import { getScenarioItemEntries } from '../Sandbox/ItemsTable/ItemsTableUtils';
import { ScenarioItems } from '../types';

export const getApplyChanges = (items: ScenarioItems) => {
  const scenarioItemEntries = getScenarioItemEntries(items);
  const updatedItems = scenarioItemEntries
    .filter((i) => i.isOverlay)
    .filter((item) => !isScenarioVisibility(item.visibility));
  const newItems = scenarioItemEntries.filter((item) => isScenarioVisibility(item.visibility));
  const hasChanges = !!(updatedItems.length + newItems.length);
  return { updatedItems, newItems, hasChanges };
};
