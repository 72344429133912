import { KomodoTheme } from '../../../theme/komodo-mui-theme';

import createStylesItemsStatusHoverSelector, {
  ItemStatusVariants,
} from './ItemsStatusHoverSelectorStyles';

const ItemsStatusHoverSelectorStyles = (theme: KomodoTheme) =>
  createStylesItemsStatusHoverSelector(theme, ItemStatusVariants.COZY);

export default ItemsStatusHoverSelectorStyles;
