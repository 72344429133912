import { MutationHookOptions, useMutation } from '@apollo/client';

import {
  DeleteItemEstimateMutation,
  DeleteItemEstimateMutationVariables,
} from '../../../generated/graphql';

import { deleteItemEstimateMutation } from './itemMutation';

export const useDeleteItemEstimate = (
  options?: MutationHookOptions<DeleteItemEstimateMutation, DeleteItemEstimateMutationVariables>
) =>
  useMutation<DeleteItemEstimateMutation, DeleteItemEstimateMutationVariables>(
    deleteItemEstimateMutation,
    options
  );
