import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../../theme/komodo-mui-theme';

export default (theme: KomodoTheme) =>
  createStyles({
    chipContainer: {
      background: 'none',
      minHeight: 40,
      paddingBottom: 40,
    },
    chipHidden: {
      opacity: 0,
      padding: 2,
    },
    chipPadding: {
      padding: 2,
    },
    container: {
      width: 488,
    },
    addRemoveIcon: {
      marginLeft: 'auto',
      fontSize: 24,
      color: theme.palette.chartBlack,
    },
    selectColumnText: {
      fontFamily: theme.fontFamily,
      fontWeight: 400,
      paddingTop: 14,
      color: theme.palette.disabledGrey,
    },
  });
