import { Org } from '../../../generated/graphql';
import { ScrollContainer } from '../../scales';

import OrganizationDetailsFooter from './OrganizationDetails/OrganizationDetailsFooter';
import OrganizationDetailsHeader from './OrganizationDetails/OrganizationDetailsHeader';
import OrganizationDetailsPanel from './OrganizationDetails/OrganizationDetailsPanel';
import OrganizationNodesList from './OrganizationDetails/OrganizationNodesList';

type Props = {
  organization: Org;
  organizations: Org[];
};

export default function CompanyOrganization({ organization, organizations }: Props) {
  return (
    <div className="flex grow flex-col overflow-auto">
      <OrganizationDetailsPanel organization={organization} organizations={organizations} />
      <ScrollContainer direction="vertical">
        <OrganizationDetailsHeader organization={organization} />
        <OrganizationNodesList organization={organization} />
      </ScrollContainer>
      <OrganizationDetailsFooter organization={organization} />
    </div>
  );
}
