import { formatDistance, formatRelative } from 'date-fns';

import en from 'date-fns/locale/en-US';

export const EMPTY_DATE = '--/--/----';

export const isDateValid = (dateString: string) => !Number.isNaN(new Date(dateString).getTime());

// We will try to localize date treatment. We will follow local protocol
// but will make digit counts consistent for masking behavior
export const getDateString = (date: Date, shouldIncludeTime = false, locale?: string) =>
  date.toLocaleDateString(locale, {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    ...(shouldIncludeTime && { hour: 'numeric', minute: 'numeric', timeZoneName: 'shortGeneric' }),
  });

const wideDate = new Date('3333-11-22T12:00:00');
const localeDateString = getDateString(wideDate);
export const localeDateFormat = localeDateString
  .replace('33', 'yy')
  .replace('33', 'yy')
  .replace('11', 'MM')
  .replace('22', 'dd');

//
export const preProcessDay = (date: Date) => {
  const isDayOnly = date.getUTCHours() === 0 && date.getUTCMinutes() === 0;
  // handle GMT UTC so that we don't slide to yesterday...
  if (isDayOnly) date.setUTCHours((date.getTimezoneOffset() + 1) / 60);
  return date;
};

// parse the input string and return null or a date that may be invalid
// In some instances we want to know if a date is invalid so we can use the previous value
export const parseDate = (dateString: Date | string | undefined | null) => {
  if (dateString) {
    // date strings can arrive in UTC Z +8, adjust if need-be
    return getDateString(preProcessDay(new Date(dateString)));
  }
  return null;
};

export const placeholderDefault = parseDate('01/01/2021');

const MASK = localeDateString
  ? localeDateString.split('').map((char) => {
      if (['/', '-'].includes(char)) return char;
      return /\d/;
    })
  : [];

export const maskFn = (value: string) => (value ? MASK : []);

// parse the input string and return a valid date or null
export const parseAndValidateDate = (dateString?: string | null) => {
  const date = parseDate(dateString);
  if (date && isDateValid(date)) {
    return new Date(date);
  }
  return null;
};

const localTime = 'p';
const localTimeWithSeconds = 'h:mm:ss aa';

export interface LocaleRelative {
  lastWeek?: string;
  yesterday?: string;
  today?: string;
  other?: string;
  nextWeek?: string;
  tomorrow?: string;
}
export const calendarString = (dateTimeString: string, includeSeconds = false) => {
  const timeFormat = includeSeconds ? localTimeWithSeconds : localTime;
  const formatRelativeLocale: LocaleRelative = {
    lastWeek: `'last' eeee 'at' ${timeFormat}`,
    yesterday: `'yesterday at' ${timeFormat}`,
    today: `'today at' ${timeFormat}`,
    other: `'on' P ${includeSeconds ? `'at' ${timeFormat}` : ''}`,
    nextWeek: `eeee 'at' ${timeFormat}`, // i think this is technically this week, ie the next 7 days? for exmple if today is wednesday then this path would apply to Friday
    tomorrow: `'tomorrow' at ${timeFormat}`,
  };

  const locale: Locale = {
    ...en,
    formatRelative: (token: keyof LocaleRelative) =>
      formatRelativeLocale[token] || formatRelativeLocale.other,
  };

  const date = new Date(dateTimeString);
  const dateString = formatRelative(date, new Date(), { locale });
  return dateString;
};

export const eventDateString = (date: Date, showTimeForToday?: boolean) => {
  const formatRelativeLocale: LocaleRelative = {
    today: showTimeForToday ? `h:mm aa` : '',
    other: `MM/dd/yyyy`,
  };

  const locale: Locale = {
    ...en,
    formatRelative: (token: keyof LocaleRelative) =>
      formatRelativeLocale[token] || formatRelativeLocale.other,
  };

  const dateString = formatRelative(date, new Date(), { locale });
  return dateString;
};

export const fromNow = (dateString?: string | null) => {
  if (dateString && isDateValid(dateString)) {
    return formatDistance(new Date(dateString), new Date(), { addSuffix: true });
  }
  return '';
};

export const parseMonthYear = (dateString: string) => {
  const parts = dateString.split('/');
  if (parts.length > 2) return [parts[0], parts[2]];
  return ['', ''];
};

const getRangeParam = (input = '', index: number) => {
  const range = input.split(' to ');
  let dateStr = range[index];
  if (!dateStr) return undefined;
  const year = dateStr.split('/')[2];
  if (year && year.length !== 4) return undefined;
  if (!year) {
    dateStr = dateStr.concat('/', new Date().getFullYear().toString());
  }
  const date = new Date(dateStr);
  if (!isDateValid(date.toString())) return undefined;
  return date;
};

export const getRangeDates = (due?: string) => {
  const dueStart = getRangeParam(due, 0);
  const dueEnd = getRangeParam(due, 1);
  if (!dueStart || !dueEnd) return [undefined, undefined];
  if (dueStart > dueEnd) return [undefined, undefined];
  setAllDayRange([dueStart, dueEnd]);
  return [dueStart.toISOString(), dueEnd.toISOString()];
};

/**
 * Decomposes ISO date string into [year, month, day] strings
 *
 * @param isoStr string date string in ISO format
 * @returns [year, month, day]: string[]
 */
export const getDatePrimitives = (isoStr: string) => isoStr.split('T')[0].split('-');

/**
 * Decomposes Date into UTC [year, month, day] numbers
 *
 * @param date Date
 * @returns [year, month, day]: number[]
 */
export const getUtcPrimitives = (date: Date) => [
  date.getUTCFullYear(),
  date.getUTCMonth(),
  date.getUTCDate(),
];

/**
 * Sets the range for start day and end day dates as maximum as possible
 *
 * @param [start, end]: Date[]
 * @returns [start, end]: Date[]
 */
export const setAllDayRange = ([start, end]: Date[]) => {
  start.setUTCHours(0, 0, 0, 0);
  end.setUTCHours(23, 59, 59, 999);
  return [start, end];
};

/**
 * Gets today at UTC noon Date
 * For the time difference comparison we are a single day precise - yesterday, today or tomorrow
 *
 * @returns today: Date
 */
export const getTodayUtcNoon = () => {
  const today = new Date(Date.now());
  today.setUTCHours(12, 0, 0, 0);
  return today;
};

/**
 * Gets today at UTC noon ISO string
 * We are time zones intolerant, everyone is in UTC
 * For the time difference comparison we are a single day precise - yesterday, today or tomorrow
 *
 * @returns today: ISO string
 */
export const getTodayAtUtcNoon = () => {
  const today = getTodayUtcNoon().toISOString();
  return today;
};
