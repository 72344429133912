import { Tab, TabList, Tabs } from '../scales';

import { useTeamLinks } from './hooks/useTeamLinks';

export default function Nav() {
  const links = useTeamLinks();
  const selectedTab = window.location.pathname.split('/')[3];

  return (
    <Tabs value={selectedTab}>
      <TabList>
        {links.map((link) => {
          return (
            <Tab key={link.path} id={link.id} to={link.path}>
              {link.name}
            </Tab>
          );
        })}
      </TabList>
    </Tabs>
  );
}
