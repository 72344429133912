import { brushX } from 'd3-brush';
import type { D3BrushEvent } from 'd3-brush';
import { type ScaleTime } from 'd3-scale';
import { max } from 'lodash';

import { insightsTimeDomain } from '../../../../api/apollo/reactiveVars';

import {
  BrushGroupSelection,
  BrushSelection,
  DivSelection,
  type TimelineData,
  TimelineEvent,
  type TimelineOptions,
} from './timeline.types';
import { ROW_HEIGHT, fixDomain } from './utils';

export function updateDragAndZoom(
  brushContainer: DivSelection,
  dragAndZoomXScale: ScaleTime<number, number>,
  timelineXScale: ScaleTime<number, number>,
  brushXScale: ScaleTime<number, number>,
  options: TimelineOptions,
  updateOuter: (selection: Date[]) => void,
  dragZoomEvent: TimelineEvent,
  isReactive?: boolean
): void {
  const { data, outerHeight, isPrint } = options;
  if (isPrint) return;
  const brushHeightData = data.length * ROW_HEIGHT + ROW_HEIGHT;
  const brushHeight = max([brushHeightData, outerHeight]) || brushHeightData;

  let brushSvg: BrushSelection = brushContainer.select('#drag-and-zoom-brush-svg');

  if (brushSvg.empty()) {
    // Create brush svg
    brushSvg = brushContainer
      .insert('svg', '.datapoint')
      .attr('id', 'drag-and-zoom-brush-svg')
      .attr('width', '100%')
      .attr('height', `${brushHeight}px`);
  }

  const handleBrush = (event: D3BrushEvent<TimelineData>) => {
    const { selection, sourceEvent, type } = event;
    if (!sourceEvent) {
      return;
    }

    // Remove the brush area
    const brushGroup: BrushGroupSelection = brushSvg.select('#brush-group');
    brushGroup.call(brush.move, null);

    const domain: Date[] = fixDomain(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
      selection === null ? brushXScale.domain() : selection.map(dragAndZoomXScale.invert as any)
    );

    if (type === 'end') {
      options.onZoom(domain);
      if (selection !== null) options.onAnalytics(dragZoomEvent);
      if (selection === null && isReactive) {
        const rDomain = insightsTimeDomain();
        options.onZoom([new Date(rDomain[0]), new Date(rDomain[1])]);
      }
    }

    updateOuter(domain);
  };

  const existingBrush = brushSvg.select('#brush-group');
  if (!existingBrush.empty()) {
    existingBrush.remove();
  }

  // Create brush
  const brush = brushX()
    .extent([
      [16, 0],
      [timelineXScale.range()[1], brushHeight],
    ])
    .on('end', handleBrush);

  // Add brush to band
  brushSvg.append('g').attr('id', 'brush-group').call(brush);

  const brushGroup: BrushGroupSelection = brushSvg.select('#brush-group');

  brushGroup
    .select('.selection')
    .attr('class', 'selection stroke-background-2 fill-background-2')
    .attr('height', brushHeight)
    .attr('y', 0)
    .attr('stroke-opacity', '0.08')
    .attr('fill-opacity', '0.4');
}
