import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';

export default (theme: KomodoTheme) => {
  const FONT = {
    fontFamily: theme.typography.fontFamily,
    fontSize: '1rem',
    fontWeight: 400,
  };

  const PADDING = {
    padding: '8px 8px 4px 8px',
  };

  return createStyles({
    filterCount: {
      ...FONT,
      ...PADDING,
      alignSelf: 'baseline',
    },
    link: {
      color: theme.palette.link,
      cursor: 'pointer',
      textDecoration: 'underline',
      ...FONT,
    },
    dashboard: {
      fontWeight: theme.typography.body1.fontWeight,
    },
    filterPanel: {
      ...PADDING,
    },
  });
};
