import { CreateProjectMutationVariables } from '../../../generated/graphql';

export default function isValidProjectInput(
  project: Partial<CreateProjectMutationVariables>
): project is CreateProjectMutationVariables {
  if (
    !project.location ||
    !project.milestone ||
    !project.milestoneDate ||
    !project.milestoneDesignPhaseID ||
    !project.name ||
    !project.projectDeliveryTypeID ||
    !project.statusID ||
    !project.typeID
  ) {
    return false;
  }

  return true;
}
