import { ComponentProps, HTMLAttributes, ReactNode, forwardRef, useRef } from 'react';
import { HiddenSelect, mergeProps, useButton, useFocusRing, useSelect } from 'react-aria';
import { useSelectState } from 'react-stately';

import { ArrowDropDown } from '@material-ui/icons';
import composeRefs from '@seznam/compose-react-refs';

import useStack from '../../../hooks/useStack';
import { StrictUnion } from '../../../utilities/types';
import ClearButton from '../ClearButton/ClearButton';
import ClearIconButton from '../ClearIconButton/ClearIconButton';
import useHasClearButton from '../hooks/useHasClearButton';
import Popover from '../Popover/Popover';
import ScrollContainer from '../ScrollContainer';
import { validateDataCy } from '../utils/data-cy';

import defaultEntryFilter from './defaultEntryFilter';
import defaultEntryRenderer from './defaultEntryRenderer';
import EntryList from './EntryList';
import FilterTextInput from './FilterTextInput';
import useEntries from './hooks/useEntries';
import useSearch from './hooks/useSearch';
import TriggerButton from './TriggerButton';
import { SelectEntry, SelectEntryID } from './types';

type BaseProps = {
  'aria-label'?: string;
  'data-cy'?: string;
  defaultValue?: SelectEntryID;
  endAdornment?: ReactNode;
  entries?: SelectEntry[];
  errorMessage?: string;
  isDisabled?: boolean;
  isSearchable?: boolean;
  label?: string;
  name?: string;
  onSearchChange?: (searchString: string) => void;
  onViewChildren?: ComponentProps<typeof EntryList>['onViewChildren'];
  placeholder?: string;
  startAdornment?: ReactNode;
  type?: 'default' | 'header';
  value?: SelectEntryID | null;
};

type ClearableProps = BaseProps & {
  isClearable: true | boolean;
  onChange?: (value: SelectEntryID | null) => void;
};

type NotClearableProps = BaseProps & {
  isClearable?: false;
  onChange?: (value: SelectEntryID) => void;
};

type Props = StrictUnion<ClearableProps | NotClearableProps>;

export default forwardRef<HTMLButtonElement, Props>(function Select(props, forwardedRef) {
  const type = props.type ?? 'default';

  const search = useSearch({ onChange: props.onSearchChange });

  // This is a stack of SelectEntryIDs that holds our current position in a tree of entries. This
  // is used when we have multilevel entries such that an entry has child entries, which
  // have child entries, which have child entries, etc.
  //
  // The most common use of this is multilevel categorizations. In a case like that, a stack may
  // look like ['030000', '030600', '030620'] which would have us showing the entries underneath
  // "Schedules for Concrete Reinforcing" - 030620.13 and 030620.16
  const currentRootStack = useStack<SelectEntryID>();

  // Pull the entries we're going to feed into React Aria.
  const { disabledKeys, entries } = useEntries({ entries: props.entries ?? [] });

  const selectProps:
    | Parameters<typeof useSelectState<SelectEntry>>[0]
    | Parameters<typeof useSelect<SelectEntry>>[0] = {
    'aria-label': props['aria-label'],
    children: defaultEntryRenderer,
    defaultSelectedKey: props.defaultValue,
    disabledKeys,
    isDisabled: props.isDisabled || !entries.length,
    items: entries,
    label: props.label,
    name: props.name,
    onOpenChange: () => {
      search.onChange('');
      currentRootStack.clear();
    },
    onSelectionChange: (key) => {
      // The type of `key` is a bit stricter than it really should be.
      // `key` can be null if the value is being cleared, so it's broader
      // than just React.Key. Here we're handling that null so we don't try
      // to coerce it to a string.
      if (props.isClearable && key === null) {
        props.onChange?.(null);
        return;
      }

      if (typeof key !== 'string') {
        console.error(
          'Select: Unexpected non-string value was selected! Entry IDs must be strings.'
        );
      }

      const newKey = `${key}`;
      props.onChange?.(newKey);
    },
    selectedKey: props.value,
    ...(props.errorMessage
      ? { validationState: 'invalid' as const, 'aria-errormessage': props.errorMessage }
      : {}),
  };

  const state = useSelectState<SelectEntry>(selectProps);

  const ref = useRef<HTMLButtonElement>(null); // This gets passed into RA
  const composedRef = composeRefs(ref, forwardedRef); // This gets applied.
  const { labelProps, triggerProps, errorMessageProps, valueProps, menuProps } = useSelect(
    selectProps,
    state,
    ref
  );

  const { buttonProps } = useButton(triggerProps, ref);
  const { focusProps, isFocusVisible } = useFocusRing();
  const triggerButtonProps = mergeProps(buttonProps, focusProps);

  const { hasTextClearButton, hasInlineClearButton } = useHasClearButton({
    label: props.label,
    isClearable: props.isClearable && state.selectedKey !== null,
    isDisabled: props.isDisabled,
  });
  const handleClear = () => {
    state.setSelectedKey(null);
    ref.current?.focus();
  };

  validateDataCy(props['data-cy'], 'select');

  return (
    <div className="relative flex w-full flex-col gap-0.5">
      {props.label && (
        <div className="flex">
          <label {...labelProps} className="mr-auto text-type-primary type-label">
            {props.label}
          </label>
          {hasTextClearButton && <ClearButton onClick={handleClear} />}
        </div>
      )}
      <TriggerButton
        buttonProps={triggerButtonProps}
        data-cy={props['data-cy'] ?? 'select'}
        isDisabled={triggerProps.isDisabled}
        isFocusVisible={isFocusVisible}
        isInvalid={Boolean(props.errorMessage)}
        triggerRef={composedRef}
        type={type}
      >
        {props.startAdornment}
        <SelectedValue
          placeholder={props.placeholder}
          selectedItem={state.selectedItem?.value ?? null}
          valueProps={valueProps}
        />
        {hasInlineClearButton && <ClearIconButton onClick={handleClear} />}
        {props.endAdornment}
        <div aria-hidden="true">
          <ArrowDropDown />
        </div>
      </TriggerButton>
      {props.errorMessage && (
        <div {...errorMessageProps} className="cursor-default text-type-error type-label">
          {props.errorMessage}
        </div>
      )}
      <HiddenSelect label={props.label} name={props.name} state={state} triggerRef={ref} />
      {state.isOpen && (
        <Popover className="flex flex-col" isWidthOfTrigger state={state} triggerRef={ref}>
          {props.isSearchable && (
            <FilterTextInput
              onChange={search.onChange}
              onEscape={() => state.setOpen(false)}
              value={search.value}
            />
          )}
          <ScrollContainer direction="vertical">
            <EntryList
              menuProps={{
                ...menuProps,
                autoFocus: false, // let the FilterTextInput grab focus
              }}
              onFilterEntry={(entry) =>
                defaultEntryFilter(entry, search.value, currentRootStack.top)
              }
              // Don't show the option to see children if we're searching for entries.
              // The UX gets confusing otherwise because its unclear whether we should be
              // search all the entries or just the children.
              onViewChildren={search.value ? undefined : currentRootStack.push}
              onViewParent={currentRootStack.pop}
              // Don't show the parent if we're searching for entries
              parentEntryID={search.value ? undefined : currentRootStack.top}
              state={state}
            />
          </ScrollContainer>
        </Popover>
      )}
    </div>
  );
});

const SelectedValue = (props: {
  selectedItem: SelectEntry | null;
  placeholder?: string;
  valueProps: HTMLAttributes<HTMLDivElement>;
}) => {
  let contents: ReactNode = props.placeholder ?? 'Select an option...';
  if (props.selectedItem) {
    const { endAdornment, label, startAdornment } = props.selectedItem;
    contents = (
      <>
        {startAdornment && <div>{startAdornment}</div>}
        <div className="mr-auto truncate">{label}</div>
        {endAdornment && <div>{endAdornment}</div>}
      </>
    );
  }

  return (
    <div
      {...props.valueProps}
      className={[
        'mr-auto flex w-full items-center gap-2 truncate',
        props.selectedItem ? '' : 'text-type-inactive',
      ].join(' ')}
    >
      {contents}
    </div>
  );
};
