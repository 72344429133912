import { ToastType } from '../../../../api/gqlEnums';
import { setToast } from '../../../../hooks/useToastParametersLocalQuery';

export default function useCreateItemFromIdeaMutation(ideaID: UUID) {
  const submitFunc = () => {
    setToast(
      {
        message: `[NOT YET IMPLEMENTED] useCreateItemFromIdeaMutation: ${ideaID}`,
      },
      ToastType.SERVER_ERROR
    );
  };
  return [submitFunc];
}
