import { PureQueryOptions, useApolloClient } from '@apollo/client';

import { replaceQueries } from '../utilities/utilities';

// This function takes a list of named queries or a list of named queries with variables
// and then refetches the queries listed in refetchQueries. See api/refetchSets.ts for sets of queries to refetch

export const useRefetch = (refetchQueries: (string | PureQueryOptions)[]) => {
  const client = useApolloClient();

  return (queries?: (string | PureQueryOptions)[]) => {
    let include = refetchQueries;
    if (queries) {
      // updating queries if needed, adding variables
      include = replaceQueries(refetchQueries, queries);
    }
    // @ts-ignore TODO CT-545
    client.refetchQueries({ include });
  };
};
