import { FieldGroup } from '../../../api/gqlEnums';
import theme from '../../../theme/komodo-mui-theme';

export const COLUMN_VERTICAL_BORDER = 1; // 1px only right
export const ROW_HORIZONTAL_BORDER = 1; // 1px only bottom
export const CELL_BORDER = 2; // 2px padding all around

export const CELL_HORIZONTAL_OFFSET = CELL_BORDER * 2 + COLUMN_VERTICAL_BORDER;
export const CELL_TEXT_PADDING = 8;

// Min-width specified for the header for line number & checkbox icon columns.
export const HEADER_START_CELL_WIDTH = 36;

export const startCellWidth = (lines: number, isNumberColumn = false) => {
  if (isNumberColumn) return HEADER_START_CELL_WIDTH - 1;
  if (lines < 99) return HEADER_START_CELL_WIDTH;
  if (lines < 999) return HEADER_START_CELL_WIDTH + 4;
  if (lines < 9999) return HEADER_START_CELL_WIDTH + 8;
  if (lines < 99999) return HEADER_START_CELL_WIDTH + 12;
  return HEADER_START_CELL_WIDTH + 16;
};

export const OVERALL_HEIGHT = 500;
export const CATEGORIZATION_HEIGHT = 300;
export const TABLE_HEADER_HEIGHT = 50;
export const TABLE_HEADER_BORDER = 3;
export const TABLE_BODY_HEIGHT =
  OVERALL_HEIGHT - TABLE_HEADER_HEIGHT - TABLE_HEADER_BORDER - ROW_HORIZONTAL_BORDER;
export const CATEGORIZATION_TABLE_BODY_HEIGHT =
  CATEGORIZATION_HEIGHT - TABLE_HEADER_HEIGHT - TABLE_HEADER_BORDER - ROW_HORIZONTAL_BORDER;
export const TABLE_MARGIN = 3;

export const ROW_HEADER_CLASS = 'join-grid-header';
export const ROW_FOOTER_CLASS = 'join-grid-footer';
export const ROW_SPACER_CLASS = 'join-grid-spacer';

export const columnGroupColors = new Map([
  [FieldGroup.CATEGORIZATIONS, theme.palette.chartLightGrey],
  [FieldGroup.LABOR, theme.palette.costTypeLabor],
  [FieldGroup.EQUIPMENT, theme.palette.costTypeEquipment],
  [FieldGroup.MATERIAL, theme.palette.costTypeMaterial],
  [FieldGroup.SUBCONTRACTOR, theme.palette.costTypeSubcontractor],
  [FieldGroup.OTHER, theme.palette.costTypeOther],
  [FieldGroup.USER, theme.palette.costTypeUser],
]);
