import { createStyles } from '@material-ui/core/styles';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';

const CategoryFilterMenuItemStyles = (theme: KomodoTheme) =>
  createStyles({
    filterMenu: {
      padding: 0,
      border: 'none',
      borderRadius: 0,
      boxShadow: 'none',
    },
    selectHeaderText: {
      height: '20px',
      paddingTop: '8px',
      paddingBottom: '8px',
      paddingLeft: '16px',
      ...theme.typography.h3,
      color: theme.palette.joinPrimary,
      fontFamily: theme.typography.fontFamily,
    },
    categoryMenuItems: {
      maxHeight: 400,
    },
    search: {
      margin: 0,
      width: '100%',
    },
    headerRow: {
      cursor: 'pointer',
      height: '36px',
      paddingLeft: '16px',
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      backgroundColor: theme.palette.backgroundGrey,
      borderBottom: `1px solid ${theme.palette.divider}`,
      borderTop: `1px solid ${theme.palette.divider}`,
      '&:hover': { backgroundColor: theme.palette.backgroundGrey },
      '&:focus': { backgroundColor: theme.palette.backgroundGrey },
    },
  });

export default CategoryFilterMenuItemStyles;
