import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../theme/komodo-mui-theme';

export const SIDEBAR_ICON_BUTTON_HEIGHT_SMALL = 24;

const ItemSidebarIconStyles = (theme: KomodoTheme) =>
  createStyles({
    iconContainer: {
      marginRight: 10,
      flex: '0 0 40px',
    },
    sidebarIcon: {
      paddingRight: 6,
      height: theme.iconSizes.medium,
      width: theme.iconSizes.medium,
    },
    sidebarIconBackground: {
      background: theme.palette.backgroundWhite,
      border: theme.border.line,
      '&:hover': {
        border: theme.border.lineBorderBlack,
      },
      '&:focus': {
        border: theme.border.lineBorderBlack,
      },
    },
    sidebarIconFlipped: {
      transform: 'rotate(180deg)',
    },
    smallIconButton: {
      height: SIDEBAR_ICON_BUTTON_HEIGHT_SMALL,
      width: SIDEBAR_ICON_BUTTON_HEIGHT_SMALL,
      '&:hover': {
        backgroundColor: theme.palette.hoverBackground,
      },
      '&:focus': {
        backgroundColor: theme.palette.hoverBackground,
      },
    },
    smallIcon: {
      height: theme.iconSizes.mediumCompact,
      width: theme.iconSizes.mediumCompact,
    },
  });

export default ItemSidebarIconStyles;
