import { columns as defaultColumns } from './constants';
import { useMilestonesCostReportsQuery } from './useMilestonesCostReportsQuery';

export function useMilestoneCostReportsQuery(
  milestoneID: UUID | null | undefined,
  projectID: UUID | null | undefined,
  viewFilter: ViewFilterInput = {},
  columns: CostReportColumnInput[] = defaultColumns
) {
  return useMilestonesCostReportsQuery(
    milestoneID ? [{ milestoneID }] : [],
    projectID,
    viewFilter,
    columns,
    !milestoneID || !projectID
  );
}
