import { ApolloError, useMutation } from '@apollo/client';

import {
  REFETCH_CHANGE_CATEGORIZATION,
  REFETCH_COMPANY_CATEGORIZATIONS,
} from '../../../api/refetchSets';
import {
  CategorizationImportMetadata,
  CreateCategoriesFromImportMutation,
  CreateCategoriesFromImportMutationVariables,
} from '../../../generated/graphql';
import { CategoryMapInput } from '../categorization/types';
import { createCategoriesFromImportMutation } from '../hooks/queries';

function useCreateCategoriesFromImport() {
  const [createCategoriesFromImport, createCategoriesFromImportResults] = useMutation<
    CreateCategoriesFromImportMutation,
    CreateCategoriesFromImportMutationVariables
  >(createCategoriesFromImportMutation, {
    fetchPolicy: 'network-only',
  });
  const createCategoriesFromImportFunction = (
    categorizationID: UUID,
    assetID: UUID,
    categories: CategoryMapInput[],
    metadata: CategorizationImportMetadata,
    projectID?: UUID,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
    onSuccess?: (res: any) => void,
    onError?: (e: ApolloError) => void
  ) =>
    createCategoriesFromImport({
      variables: {
        categorizationID,
        assetID,
        categories,
        metadata,
        projectID,
      },
      refetchQueries: projectID ? REFETCH_CHANGE_CATEGORIZATION : REFETCH_COMPANY_CATEGORIZATIONS,
    }).then(
      (result) => {
        if (onSuccess) onSuccess(result);
      },
      (error: ApolloError) => {
        if (onError) onError(error);
      }
    );
  return [createCategoriesFromImportFunction, createCategoriesFromImportResults] as const;
}

export default useCreateCategoriesFromImport;
