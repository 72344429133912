import { MutationHookOptions, useMutation } from '@apollo/client';

import {
  EditProgramInput,
  EditProgramMutation,
  EditProgramMutationVariables,
} from '../../../../generated/graphql';
import { editProgramMutation } from '../../mutations';

export const useEditProgramMutation = (
  input: EditProgramInput,
  options?: MutationHookOptions<EditProgramMutation, EditProgramMutationVariables>
) =>
  useMutation<EditProgramMutation, EditProgramMutationVariables>(editProgramMutation, {
    variables: { input },
    ...options,
  });
