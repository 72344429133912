import { useMutation } from '@apollo/client';

import { UPDATE_MILESTONE_DESIGN_PHASE } from '../../../actions/actionTypes';
import { analyticsEvent } from '../../../analytics/analyticsEventProperties';
import {
  DesignPhaseType,
  SetMilestoneDesignPhaseMutation,
  SetMilestoneDesignPhaseMutationVariables,
} from '../../../generated/graphql';
import useSendAnalytics from '../../../hooks/useSendAnalytics';

import { setMilestoneDesignPhaseMutation } from './queries';

function useUpdateMilestoneDesignPhase() {
  const [updateMilestoneDesignPhaseFunc] = useMutation<
    SetMilestoneDesignPhaseMutation,
    SetMilestoneDesignPhaseMutationVariables
  >(setMilestoneDesignPhaseMutation);
  const sendAnalytics = useSendAnalytics();
  const submitFunc = (projectID: UUID, milestoneID: UUID, designPhase: DesignPhaseType) =>
    updateMilestoneDesignPhaseFunc({
      variables: { projectID, input: { milestoneID, designPhaseID: designPhase.id } },
    }).then(() => {
      sendAnalytics(analyticsEvent(UPDATE_MILESTONE_DESIGN_PHASE));
    });

  return [submitFunc];
}

export default useUpdateMilestoneDesignPhase;
