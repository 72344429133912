import { HTMLAttributes, ReactNode, Ref } from 'react';

type Props = {
  'data-cy'?: string;
  buttonProps: HTMLAttributes<HTMLButtonElement>;
  children: ReactNode;
  isInvalid?: boolean;
  isDisabled?: boolean;
  isFocusVisible?: boolean;
  triggerRef: Ref<HTMLButtonElement>;
  type: 'default' | 'header';
};

export default function TriggerButton(props: Props) {
  const typeClassNames = {
    default: [
      'type-body1 px-2 border',
      props.isDisabled ? 'bg-button-inactive' : 'bg-background-primary',
    ].join(' '),
    header: 'type-heading2 px-2 py-1 bg-transparent',
  };

  return (
    <button
      ref={props.triggerRef}
      aria-label="select an entry"
      className={[
        'flex h-10 items-center gap-1 truncate rounded-md',
        typeClassNames[props.type],
        props.isFocusVisible ? 'outline' : 'outline-none',
        props.isDisabled ? 'text-type-inactive' : 'text-type-primary',
        props.isInvalid ? 'border border-type-error' : '',
      ].join(' ')}
      data-cy={props['data-cy']}
      {...props.buttonProps}
    >
      {props.children}
    </button>
  );
}
