export const emojis = [
  {
    emoji: '😂',
    name: 'face with tears of joy',
    shortname: ':joy:',
    rank: 1,
  },
  {
    emoji: '❤️',
    name: 'red heart',
    shortname: ':heart:',
    rank: 4,
  },
  {
    emoji: '🤣',
    name: 'rolling on the floor laughing',
    shortname: ':rolling_on_the_floor_laughing:',
    rank: 7,
  },
  {
    emoji: '👍',
    name: 'thumbs up',
    shortname: ':thumbsup:',
    rank: 10,
  },
  {
    emoji: '😭',
    name: 'loudly crying face',
    shortname: ':sob:',
    rank: 13,
  },
  {
    emoji: '🙏',
    name: 'folded hands',
    shortname: ':pray:',
    rank: 16,
  },
  {
    emoji: '😘',
    name: 'face blowing a kiss',
    shortname: ':kissing_heart:',
    rank: 19,
  },
  {
    emoji: '🥰',
    name: 'smiling face with hearts',
    shortname: ':smiling_face_with_hearts:',
    rank: 22,
  },
  {
    emoji: '😍',
    name: 'smiling face with heart-eyes',
    shortname: ':heart_eyes:',
    rank: 25,
  },
  {
    emoji: '😊',
    name: 'smiling face with smiling eyes',
    shortname: ':blush:',
    rank: 28,
  },
  {
    emoji: '🎉',
    name: 'party popper',
    shortname: ':tada:',
    rank: 31,
  },
  {
    emoji: '😁',
    name: 'beaming face with smiling eyes',
    shortname: ':grin:',
    rank: 34,
  },
  {
    emoji: '💕',
    name: 'two hearts',
    shortname: ':two_hearts:',
    rank: 37,
  },
  {
    emoji: '🥺',
    name: 'pleading face',
    shortname: ':pleading_face:',
    rank: 40,
  },
  {
    emoji: '😅',
    name: 'grinning face with sweat',
    shortname: ':sweat_smile:',
    rank: 43,
  },
  {
    emoji: '🔥',
    name: 'fire',
    shortname: ':fire:',
    rank: 46,
  },
  {
    emoji: '☺️',
    name: 'smiling face',
    shortname: ':smiling:',
    rank: 49,
  },
  {
    emoji: '🤦',
    name: 'person facepalming',
    shortname: ':person_facepalming:',
    rank: 52,
  },
  {
    emoji: '♥️',
    name: 'heart suit',
    shortname: ':heart_suit:',
    rank: 55,
  },
  {
    emoji: '♥',
    name: 'heart suit',
    shortname: ':hearts:',
    rank: 55,
  },
  {
    emoji: '🤷',
    name: 'person shrugging',
    shortname: ':person_shrugging:',
    rank: 58,
  },
  {
    emoji: '🙄',
    name: 'face with rolling eyes',
    shortname: ':rolling_eyes:',
    rank: 61,
  },
  {
    emoji: '😆',
    name: 'grinning squinting face',
    shortname: ':laughing:',
    rank: 64,
  },
  {
    emoji: '🤗',
    name: 'hugging face',
    shortname: ':hugging:',
    rank: 67,
  },
  {
    emoji: '😉',
    name: 'winking face',
    shortname: ':wink:',
    rank: 70,
  },
  {
    emoji: '🎂',
    name: 'birthday cake',
    shortname: ':birthday:',
    rank: 73,
  },
  {
    emoji: '🤔',
    name: 'thinking face',
    shortname: ':thinking:',
    rank: 76,
  },
  {
    emoji: '👏',
    name: 'clapping hands',
    shortname: ':clap:',
    rank: 79,
  },
  {
    emoji: '🙂',
    name: 'slightly smiling face',
    shortname: ':slight_smile:',
    rank: 82,
  },
  {
    emoji: '😳',
    name: 'flushed face',
    shortname: ':flushed:',
    rank: 85,
  },
  {
    emoji: '🥳',
    name: 'partying face',
    shortname: ':partying_face:',
    rank: 88,
  },
  {
    emoji: '😎',
    name: 'smiling face with sunglasses',
    shortname: ':sunglasses:',
    rank: 91,
  },
  {
    emoji: '👌',
    name: 'OK hand',
    shortname: ':ok_hand:',
    rank: 94,
  },
  {
    emoji: '💜',
    name: 'purple heart',
    shortname: ':purple_heart:',
    rank: 97,
  },
  {
    emoji: '😔',
    name: 'pensive face',
    shortname: ':pensive:',
    rank: 100,
  },
  {
    emoji: '💪',
    name: 'flexed biceps',
    shortname: ':muscle:',
    rank: 103,
  },
  {
    emoji: '✨',
    name: 'sparkles',
    shortname: ':sparkles:',
    rank: 106,
  },
  {
    emoji: '💖',
    name: 'sparkling heart',
    shortname: ':sparkling_heart:',
    rank: 108,
  },
  {
    emoji: '👀',
    name: 'eyes',
    shortname: ':eyes:',
    rank: 111,
  },
  {
    emoji: '😋',
    name: 'face savoring food',
    shortname: ':yum:',
    rank: 114,
  },
  {
    emoji: '😏',
    name: 'smirking face',
    shortname: ':smirk:',
    rank: 117,
  },
  {
    emoji: '😢',
    name: 'crying face',
    shortname: ':cry:',
    rank: 120,
  },
  {
    emoji: '👉',
    name: 'backhand index pointing right',
    shortname: ':point_right:',
    rank: 123,
  },
  {
    emoji: '💗',
    name: 'growing heart',
    shortname: ':heartpulse:',
    rank: 126,
  },
  {
    emoji: '😩',
    name: 'weary face',
    shortname: ':weary:',
    rank: 129,
  },
  {
    emoji: '💯',
    name: 'hundred points',
    shortname: ':hundred_points:',
    rank: 132,
  },
  {
    emoji: '🌹',
    name: 'rose',
    shortname: ':rose:',
    rank: 135,
  },
  {
    emoji: '💞',
    name: 'revolving hearts',
    shortname: ':revolving_hearts:',
    rank: 138,
  },
  {
    emoji: '🎈',
    name: 'balloon',
    shortname: ':balloon:',
    rank: 141,
  },
  {
    emoji: '💙',
    name: 'blue heart',
    shortname: ':blue_heart:',
    rank: 144,
  },
  {
    emoji: '😃',
    name: 'grinning face with big eyes',
    shortname: ':smiley:',
    rank: 147,
  },
  {
    emoji: '😡',
    name: 'pouting face',
    shortname: ':rage:',
    rank: 150,
  },
  {
    emoji: '💐',
    name: 'bouquet',
    shortname: ':bouquet:',
    rank: 153,
  },
  {
    emoji: '😜',
    name: 'winking face with tongue',
    shortname: ':stuck_out_tongue_winking_eye:',
    rank: 156,
  },
  {
    emoji: '🙈',
    name: 'see-no-evil monkey',
    shortname: ':see_no_evil:',
    rank: 159,
  },
  {
    emoji: '🤞',
    name: 'crossed fingers',
    shortname: ':crossed_fingers:',
    rank: 162,
  },
  {
    emoji: '😄',
    name: 'grinning face with smiling eyes',
    shortname: ':smile:',
    rank: 165,
  },
  {
    emoji: '🤤',
    name: 'drooling face',
    shortname: ':drooling_face:',
    rank: 168,
  },
  {
    emoji: '🙌',
    name: 'raising hands',
    shortname: ':raised_hands:',
    rank: 171,
  },
  {
    emoji: '🤪',
    name: 'zany face',
    shortname: ':zany_face:',
    rank: 174,
  },
  {
    emoji: '❣️',
    name: 'heart exclamation',
    shortname: ':heart_exclamation:',
    rank: 177,
  },
  {
    emoji: '❣',
    name: 'heart exclamation',
    shortname: ':heart_exclamation:',
    rank: 177,
  },
  {
    emoji: '😀',
    name: 'grinning face',
    shortname: ':grinning:',
    rank: 180,
  },
  {
    emoji: '💋',
    name: 'kiss mark',
    shortname: ':kiss:',
    rank: 183,
  },
  {
    emoji: '💀',
    name: 'skull',
    shortname: ':skull:',
    rank: 186,
  },
  {
    emoji: '👇',
    name: 'backhand index pointing down',
    shortname: ':point_down:',
    rank: 189,
  },
  {
    emoji: '💔',
    name: 'broken heart',
    shortname: ':broken_heart:',
    rank: 192,
  },
  {
    emoji: '😌',
    name: 'relieved face',
    shortname: ':relieved:',
    rank: 195,
  },
  {
    emoji: '💓',
    name: 'beating heart',
    shortname: ':heartbeat:',
    rank: 198,
  },
  {
    emoji: '🤩',
    name: 'star-struck',
    shortname: ':starstruck:',
    rank: 201,
  },
  {
    emoji: '🙃',
    name: 'upside-down face',
    shortname: ':upside_down:',
    rank: 204,
  },
  {
    emoji: '😬',
    name: 'grimacing face',
    shortname: ':grimacing:',
    rank: 207,
  },
  {
    emoji: '😱',
    name: 'face screaming in fear',
    shortname: ':scream:',
    rank: 210,
  },
  {
    emoji: '😴',
    name: 'sleeping face',
    shortname: ':sleeping:',
    rank: 213,
  },
  {
    emoji: '🤭',
    name: 'face with hand over mouth',
    shortname: ':face_with_hand_over_mouth:',
    rank: 216,
  },
  {
    emoji: '😐',
    name: 'neutral face',
    shortname: ':neutral_face:',
    rank: 219,
  },
  {
    emoji: '🌞',
    name: 'sun with face',
    shortname: ':sun_with_face:',
    rank: 222,
  },
  {
    emoji: '😒',
    name: 'unamused face',
    shortname: ':unamused:',
    rank: 225,
  },
  {
    emoji: '😇',
    name: 'smiling face with halo',
    shortname: ':innocent:',
    rank: 228,
  },
  {
    emoji: '🌸',
    name: 'cherry blossom',
    shortname: ':cherry_blossom:',
    rank: 231,
  },
  {
    emoji: '😈',
    name: 'smiling face with horns',
    shortname: ':smiling_imp:',
    rank: 234,
  },
  {
    emoji: '🎶',
    name: 'musical notes',
    shortname: ':notes:',
    rank: 237,
  },
  {
    emoji: '✌️',
    name: 'victory hand',
    shortname: ':v:',
    rank: 240,
  },
  {
    emoji: '✌',
    name: 'victory hand',
    shortname: ':victory_hand:',
    rank: 240,
  },
  {
    emoji: '🎊',
    name: 'confetti ball',
    shortname: ':confetti_ball:',
    rank: 243,
  },
  {
    emoji: '🥵',
    name: 'hot face',
    shortname: ':hot_face:',
    rank: 246,
  },
  {
    emoji: '😞',
    name: 'disappointed face',
    shortname: ':disappointed:',
    rank: 249,
  },
  {
    emoji: '💚',
    name: 'green heart',
    shortname: ':green_heart:',
    rank: 252,
  },
  {
    emoji: '☀️',
    name: 'sun',
    shortname: ':sunny:',
    rank: 255,
  },
  {
    emoji: '☀',
    name: 'sun',
    shortname: ':sun:',
    rank: 255,
  },
  {
    emoji: '🖤',
    name: 'black heart',
    shortname: ':black_heart:',
    rank: 258,
  },
  {
    emoji: '💰',
    name: 'money bag',
    shortname: ':moneybag:',
    rank: 261,
  },
  {
    emoji: '😚',
    name: 'kissing face with closed eyes',
    shortname: ':kissing_closed_eyes:',
    rank: 264,
  },
  {
    emoji: '👑',
    name: 'crown',
    shortname: ':crown:',
    rank: 267,
  },
  {
    emoji: '🎁',
    name: 'wrapped gift',
    shortname: ':gift:',
    rank: 270,
  },
  {
    emoji: '💥',
    name: 'collision',
    shortname: ':boom:',
    rank: 273,
  },
  {
    emoji: '🙋',
    name: 'person raising hand',
    shortname: ':raising_hand:',
    rank: 276,
  },
  {
    emoji: '☹',
    name: 'frowning face',
    shortname: ':frowning_face:',
    rank: 279,
  },
  {
    emoji: '☹️',
    name: 'frowning face',
    shortname: ':frowning_face:',
    rank: 279,
  },
  {
    emoji: '😑',
    name: 'expressionless face',
    shortname: ':expressionless:',
    rank: 282,
  },
  {
    emoji: '🥴',
    name: 'woozy face',
    shortname: ':woozy_face:',
    rank: 285,
  },
  {
    emoji: '👈',
    name: 'backhand index pointing left',
    shortname: ':point_left:',
    rank: 288,
  },
  {
    emoji: '💩',
    name: 'pile of poo',
    shortname: ':poop:',
    rank: 291,
  },
  {
    emoji: '✅',
    name: 'check mark button',
    shortname: ':white_check_mark:',
    rank: 294,
  },
  {
    emoji: '👋',
    name: 'waving hand',
    shortname: ':wave:',
    rank: 296,
  },
  {
    emoji: '🤮',
    name: 'face vomiting',
    shortname: ':face_vomiting:',
    rank: 299,
  },
  {
    emoji: '😤',
    name: 'face with steam from nose',
    shortname: ':triumph:',
    rank: 302,
  },
  {
    emoji: '🤢',
    name: 'nauseated face',
    shortname: ':nauseated_face:',
    rank: 305,
  },
  {
    emoji: '🌟',
    name: 'glowing star',
    shortname: ':star2:',
    rank: 308,
  },
  {
    emoji: '❗',
    name: 'exclamation mark',
    shortname: ':exclamation:',
    rank: 311,
  },
  {
    emoji: '😥',
    name: 'sad but relieved face',
    shortname: ':disappointed_relieved:',
    rank: 313,
  },
  {
    emoji: '🌈',
    name: 'rainbow',
    shortname: ':rainbow:',
    rank: 316,
  },
  {
    emoji: '💛',
    name: 'yellow heart',
    shortname: ':yellow_heart:',
    rank: 319,
  },
  {
    emoji: '😝',
    name: 'squinting face with tongue',
    shortname: ':stuck_out_tongue_closed_eyes:',
    rank: 322,
  },
  {
    emoji: '😫',
    name: 'tired face',
    shortname: ':tired_face:',
    rank: 325,
  },
  {
    emoji: '😲',
    name: 'astonished face',
    shortname: ':astonished:',
    rank: 328,
  },
  {
    emoji: '🖕',
    name: 'middle finger',
    shortname: ':middle_finger:',
    rank: 331,
  },
  {
    emoji: '‼️',
    name: 'double exclamation mark',
    shortname: ':bangbang:',
    rank: 334,
  },
  {
    emoji: '‼',
    name: 'double exclamation mark',
    shortname: ':double_exclamation_mark:',
    rank: 334,
  },
  {
    emoji: '🔴',
    name: 'red circle',
    shortname: ':red_circle:',
    rank: 337,
  },
  {
    emoji: '🌻',
    name: 'sunflower',
    shortname: ':sunflower:',
    rank: 340,
  },
  {
    emoji: '🤯',
    name: 'exploding head',
    shortname: ':exploding_head:',
    rank: 343,
  },
  {
    emoji: '💃',
    name: 'woman dancing',
    shortname: ':dancer:',
    rank: 346,
  },
  {
    emoji: '👊',
    name: 'oncoming fist',
    shortname: ':oncoming_fist:',
    rank: 349,
  },
  {
    emoji: '🤬',
    name: 'face with symbols on mouth',
    shortname: ':face_with_symbols_on_mouth:',
    rank: 352,
  },
  {
    emoji: '🏃',
    name: 'person running',
    shortname: ':runner:',
    rank: 355,
  },
  {
    emoji: '😕',
    name: 'confused face',
    shortname: ':confused:',
    rank: 358,
  },
  {
    emoji: '👁',
    name: 'eye',
    shortname: ':eye:',
    rank: 361,
  },
  {
    emoji: '👁️',
    name: 'eye',
    shortname: ':eye:',
    rank: 361,
  },
  {
    emoji: '⚡',
    name: 'high voltage',
    shortname: ':zap:',
    rank: 365,
  },
  {
    emoji: '☕',
    name: 'hot beverage',
    shortname: ':coffee:',
    rank: 367,
  },
  {
    emoji: '🍀',
    name: 'four leaf clover',
    shortname: ':four_leaf_clover:',
    rank: 369,
  },
  {
    emoji: '💦',
    name: 'sweat droplets',
    shortname: ':sweat_drops:',
    rank: 372,
  },
  {
    emoji: '⭐',
    name: 'star',
    shortname: ':star:',
    rank: 375,
  },
  {
    emoji: '🦋',
    name: 'butterfly',
    shortname: ':butterfly:',
    rank: 377,
  },
  {
    emoji: '🤨',
    name: 'face with raised eyebrow',
    shortname: ':face_with_raised_eyebrow:',
    rank: 380,
  },
  {
    emoji: '🌺',
    name: 'hibiscus',
    shortname: ':hibiscus:',
    rank: 383,
  },
  {
    emoji: '😹',
    name: 'cat with tears of joy',
    shortname: ':joy_cat:',
    rank: 386,
  },
  {
    emoji: '🤘',
    name: 'sign of the horns',
    shortname: ':sign_of_the_horns:',
    rank: 389,
  },
  {
    emoji: '🌷',
    name: 'tulip',
    shortname: ':tulip:',
    rank: 392,
  },
  {
    emoji: '💝',
    name: 'heart with ribbon',
    shortname: ':gift_heart:',
    rank: 395,
  },
  {
    emoji: '💤',
    name: 'zzz',
    shortname: ':zzz:',
    rank: 398,
  },
  {
    emoji: '🤝',
    name: 'handshake',
    shortname: ':handshake:',
    rank: 401,
  },
  {
    emoji: '🐰',
    name: 'rabbit face',
    shortname: ':rabbit:',
    rank: 404,
  },
  {
    emoji: '😓',
    name: 'downcast face with sweat',
    shortname: ':sweat:',
    rank: 407,
  },
  {
    emoji: '💘',
    name: 'heart with arrow',
    shortname: ':cupid:',
    rank: 410,
  },
  {
    emoji: '🍻',
    name: 'clinking beer mugs',
    shortname: ':beers:',
    rank: 413,
  },
  {
    emoji: '😟',
    name: 'worried face',
    shortname: ':worried:',
    rank: 416,
  },
  {
    emoji: '😣',
    name: 'persevering face',
    shortname: ':persevere:',
    rank: 419,
  },
  {
    emoji: '🧐',
    name: 'face with monocle',
    shortname: ':face_with_monocle:',
    rank: 422,
  },
  {
    emoji: '😠',
    name: 'angry face',
    shortname: ':angry:',
    rank: 425,
  },
  {
    emoji: '🤠',
    name: 'cowboy hat face',
    shortname: ':cowboy_hat_face:',
    rank: 428,
  },
  {
    emoji: '😻',
    name: 'smiling cat with heart-eyes',
    shortname: ':heart_eyes_cat:',
    rank: 431,
  },
  {
    emoji: '🌙',
    name: 'crescent moon',
    shortname: ':crescent_moon:',
    rank: 434,
  },
  {
    emoji: '😛',
    name: 'face with tongue',
    shortname: ':stuck_out_tongue:',
    rank: 437,
  },
  {
    emoji: '🤙',
    name: 'call me hand',
    shortname: ':call_me_hand:',
    rank: 440,
  },
  {
    emoji: '🙊',
    name: 'speak-no-evil monkey',
    shortname: ':speak_no_evil:',
    rank: 443,
  },
  {
    emoji: '🧡',
    name: 'orange heart',
    shortname: ':orange_heart:',
    rank: 446,
  },
  {
    emoji: '🤡',
    name: 'clown face',
    shortname: ':clown_face:',
    rank: 449,
  },
  {
    emoji: '🤫',
    name: 'shushing face',
    shortname: ':shushing_face:',
    rank: 452,
  },
  {
    emoji: '🌼',
    name: 'blossom',
    shortname: ':blossom:',
    rank: 455,
  },
  {
    emoji: '🥂',
    name: 'clinking glasses',
    shortname: ':clinking_glasses:',
    rank: 458,
  },
  {
    emoji: '😷',
    name: 'face with medical mask',
    shortname: ':mask:',
    rank: 461,
  },
  {
    emoji: '🤓',
    name: 'nerd face',
    shortname: ':nerd:',
    rank: 464,
  },
  {
    emoji: '☠',
    name: 'skull and crossbones',
    shortname: ':skull_crossbones:',
    rank: 467,
  },
  {
    emoji: '☠️',
    name: 'skull and crossbones',
    shortname: ':skull_and_crossbones:',
    rank: 467,
  },
  {
    emoji: '🥶',
    name: 'cold face',
    shortname: ':cold_face:',
    rank: 470,
  },
  {
    emoji: '😶',
    name: 'face without mouth',
    shortname: ':no_mouth:',
    rank: 473,
  },
  {
    emoji: '😖',
    name: 'confounded face',
    shortname: ':confounded:',
    rank: 476,
  },
  {
    emoji: '🎵',
    name: 'musical note',
    shortname: ':musical_note:',
    rank: 479,
  },
  {
    emoji: '🚶',
    name: 'person walking',
    shortname: ':walking:',
    rank: 482,
  },
  {
    emoji: '😙',
    name: 'kissing face with smiling eyes',
    shortname: ':kissing_smiling_eyes:',
    rank: 485,
  },
  {
    emoji: '🍆',
    name: 'eggplant',
    shortname: ':eggplant:',
    rank: 488,
  },
  {
    emoji: '🤑',
    name: 'money-mouth face',
    shortname: ':moneymouth_face:',
    rank: 491,
  },
  {
    emoji: '💅',
    name: 'nail polish',
    shortname: ':nail_care:',
    rank: 494,
  },
  {
    emoji: '😗',
    name: 'kissing face',
    shortname: ':kissing:',
    rank: 497,
  },
  {
    emoji: '🐶',
    name: 'dog face',
    shortname: ':dog:',
    rank: 500,
  },
  {
    emoji: '🍓',
    name: 'strawberry',
    shortname: ':strawberry:',
    rank: 503,
  },
  {
    emoji: '✋',
    name: 'raised hand',
    shortname: ':raised_hand:',
    rank: 506,
  },
  {
    emoji: '👅',
    name: 'tongue',
    shortname: ':tongue:',
    rank: 508,
  },
  {
    emoji: '👄',
    name: 'mouth',
    shortname: ':lips:',
    rank: 511,
  },
  {
    emoji: '🌿',
    name: 'herb',
    shortname: ':herb:',
    rank: 514,
  },
  {
    emoji: '🚨',
    name: 'police car light',
    shortname: ':rotating_light:',
    rank: 517,
  },
  {
    emoji: '➡',
    name: 'right arrow',
    shortname: ':arrow_right:',
    rank: 520,
  },
  {
    emoji: '➡️',
    name: 'right arrow',
    shortname: ':right_arrow:',
    rank: 520,
  },
  {
    emoji: '📣',
    name: 'megaphone',
    shortname: ':mega:',
    rank: 523,
  },
  {
    emoji: '🤟',
    name: 'love-you gesture',
    shortname: ':loveyou_gesture:',
    rank: 526,
  },
  {
    emoji: '🍑',
    name: 'peach',
    shortname: ':peach:',
    rank: 529,
  },
  {
    emoji: '🍃',
    name: 'leaf fluttering in wind',
    shortname: ':leaves:',
    rank: 532,
  },
  {
    emoji: '😮',
    name: 'face with open mouth',
    shortname: ':open_mouth:',
    rank: 535,
  },
  {
    emoji: '💎',
    name: 'gem stone',
    shortname: ':gem:',
    rank: 538,
  },
  {
    emoji: '📢',
    name: 'loudspeaker',
    shortname: ':loudspeaker:',
    rank: 541,
  },
  {
    emoji: '🌱',
    name: 'seedling',
    shortname: ':seedling:',
    rank: 544,
  },
  {
    emoji: '⚠️',
    name: 'warning',
    shortname: ':warning:',
    rank: 547,
  },
  {
    emoji: '⚠',
    name: 'warning',
    shortname: ':warning:',
    rank: 547,
  },
  {
    emoji: '🙁',
    name: 'slightly frowning face',
    shortname: ':slightly_frowning_face:',
    rank: 550,
  },
  {
    emoji: '🍷',
    name: 'wine glass',
    shortname: ':wine_glass:',
    rank: 553,
  },
  {
    emoji: '😪',
    name: 'sleepy face',
    shortname: ':sleepy:',
    rank: 556,
  },
  {
    emoji: '🌚',
    name: 'new moon face',
    shortname: ':new_moon_with_face:',
    rank: 559,
  },
  {
    emoji: '🏆',
    name: 'trophy',
    shortname: ':trophy:',
    rank: 562,
  },
  {
    emoji: '🍒',
    name: 'cherries',
    shortname: ':cherries:',
    rank: 565,
  },
  {
    emoji: '✔️',
    name: 'check mark',
    shortname: ':heavy_check_mark:',
    rank: 568,
  },
  {
    emoji: '✔',
    name: 'check mark',
    shortname: ':check_mark:',
    rank: 568,
  },
  {
    emoji: '💉',
    name: 'syringe',
    shortname: ':syringe:',
    rank: 571,
  },
  {
    emoji: '❌',
    name: 'cross mark',
    shortname: ':x:',
    rank: 574,
  },
  {
    emoji: '💢',
    name: 'anger symbol',
    shortname: ':anger:',
    rank: 576,
  },
  {
    emoji: '🛒',
    name: 'shopping cart',
    shortname: ':shopping_cart:',
    rank: 579,
  },
  {
    emoji: '😸',
    name: 'grinning cat with smiling eyes',
    shortname: ':smile_cat:',
    rank: 582,
  },
  {
    emoji: '🐾',
    name: 'paw prints',
    shortname: ':feet:',
    rank: 585,
  },
  {
    emoji: '👎',
    name: 'thumbs down',
    shortname: ':thumbsdown:',
    rank: 588,
  },
];
