import { FC } from 'react';

import { Typography } from '@material-ui/core';

import { withStyles } from '../../theme/komodo-mui-theme';

import styles from './CTALinkStyles';

type CTALinkProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  linkText: any;
  classes: Classes<typeof styles>;
  dataCy?: string;
  onClick: React.MouseEventHandler<HTMLElement>;
};

const CTALink: FC<CTALinkProps> = ({ linkText, classes, dataCy, onClick }) => {
  return (
    <Typography className={classes.link} data-cy={dataCy} onClick={onClick} variant="subheading">
      {linkText}
    </Typography>
  );
};

export default withStyles(styles)(CTALink);
