import { useState } from 'react';

import { useProgramBreakdownQuery } from './hooks/useProgramBreakdownQuery';
import { useProgramQuery } from './hooks/useProgramQuery';
import ProgramDashboard from './ProgramDashboard';
import { useProgramCostTrendlineQuery } from './ProgramDialog/hooks/useProgramCostTrendlineQuery';
import { useProgramCostsQuery } from './hooks/useProgramCostsQuery';
import { useProgramCategorizationsQuery } from './hooks/useProgramCategorizationsQuery';

type Props = {
  programID: UUID;
};

export default function ProgramDashboardData({ programID }: Props) {
  const [selectedProjectIDs, setSelectedProjectIDs] = useState<UUID[]>([]);

  const { data } = useProgramQuery(programID, {
    onCompleted: () => {
      setSelectedProjectIDs(data?.program?.projects?.map((p) => p.id) ?? []);
    },
  });
  const program = data?.program;

  const { data: breakdownData } = useProgramBreakdownQuery(programID, selectedProjectIDs);

  const { data: programCostTrendlineData } = useProgramCostTrendlineQuery(
    programID,
    selectedProjectIDs
  );

  const { data: programCostData } = useProgramCostsQuery(selectedProjectIDs, programID);
  const programCosts = programCostData?.programCosts;

  const programCategorizationsQueryResult = useProgramCategorizationsQuery(
    programID,
    selectedProjectIDs
  );
  const programCategorizations = (
    programCategorizationsQueryResult.data ?? programCategorizationsQueryResult.previousData
  )?.programCategorizations;

  return (
    <ProgramDashboard
      chartData={programCostTrendlineData?.programCostTrendline}
      program={program}
      programBreakdown={breakdownData?.programBreakdown?.program ?? []}
      programCategorizations={programCategorizations ?? []}
      programCosts={programCosts}
      projectBreakdown={breakdownData?.programBreakdown?.projects ?? []}
      selectedProjectIDs={selectedProjectIDs}
      setSelectedProjectIDs={setSelectedProjectIDs}
      totalProgramCost={breakdownData?.programBreakdown?.total ?? 0}
    />
  );
}
