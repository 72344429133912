import { useState } from 'react';

import {
  reportDistributionEventTypes,
  userReportDistributionEvent,
} from '../../../analytics/analyticsEventProperties';
import { ToastType } from '../../../api/gqlEnums';
import { getTimezone } from '../../../api/joinAPI';
import { ReportDistributionQuery } from '../../../generated/graphql';
import useCollaboratorsQuery from '../../../hooks/useCollaboratorsQuery';
import { useProjectCompaniesQuery } from '../../../hooks/useProjectCompaniesQuery';
import useSendAnalytics from '../../../hooks/useSendAnalytics';
import { setToast } from '../../../hooks/useToastParametersLocalQuery';
import ScheduleDistribution from '../../Icons/ScheduleDistribution';
import SendDistribution from '../../Icons/SendDistribution';
import {
  useCreateReportDistribution,
  useDeleteReportDistribution,
  useLoadReportDistribution,
  useUpdateReportDistribution,
} from '../ReportHooks';

import DistributionDeleteDialog from './DistributionDeleteDialog';
import DistributionDialog from './DistributionDialog';
import {
  DistributionDialogsState,
  DistributionFrequencyInputs,
  SEND_NOW_CRON_EXPRESSION,
  areFrequencyInputsEqual,
  createCronExpression,
  createFrequencyInputsFromCronExpression,
  defaultDistributionFrequencyInputs,
  getReportDistributionAnalyticsProps,
  getShareLabel,
  getStartDate,
  getStartingWeekOptions,
  isCompleteFrequencySelection,
} from './DistributionUtils';

type Props = {
  projectID: UUID;
  distributionDialogsState: DistributionDialogsState;
  onClose: () => void;
  refetchUserReports?: () => void;
};

export default function DistributionDialogData(props: Props) {
  // State
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);
  const [cronExpression, setCronExpression] = useState<string>('');
  const [reportDistributionID, setReportDistributionID] = useState<UUID>('');
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState<boolean>(false);
  const [initialDistributionInput, setInitialDistributionInput] =
    useState<DistributionFrequencyInputs>(defaultDistributionFrequencyInputs);
  const [distributionInput, setDistributionInput] = useState<DistributionFrequencyInputs>(
    defaultDistributionFrequencyInputs
  );

  // Constants
  const isEditDialog = props.distributionDialogsState.isEditingDistribution;
  const isShareEnabled = isCompleteFrequencySelection(distributionInput);

  // Hooks
  const sendAnalytics = useSendAnalytics();
  const { data: dataCollaborators } = useCollaboratorsQuery(props.projectID);
  const { data: dataCompanies } = useProjectCompaniesQuery(props.projectID);

  useLoadReportDistribution(
    props.projectID,
    props.distributionDialogsState.reportID,
    !isEditDialog,
    {
      onCompleted: (data: ReportDistributionQuery) => {
        const { cadence, collaboratorIDs, id, startDate } = data.reportDistribution;
        setReportDistributionID(id);
        const input = createFrequencyInputsFromCronExpression(
          cadence,
          collaboratorIDs,
          new Date(startDate)
        );
        setDistributionInput({ ...input });
        setInitialDistributionInput({ ...input });
      },
    }
  );

  const [createReportDistribution] = useCreateReportDistribution(
    props.projectID,
    {
      cadence: cronExpression,
      reportID: props.distributionDialogsState.reportID,
      collaboratorIDs: distributionInput.selectedCollaboratorIDs,
      startDate: getStartDate(cronExpression, distributionInput.selectedStartingWeek),
      timezone: getTimezone(),
    },
    {
      onCompleted: () => {
        if (props.refetchUserReports) {
          props.refetchUserReports();
        }
        props.onClose();
        setToast(
          {
            message:
              cronExpression === SEND_NOW_CRON_EXPRESSION ||
              props.distributionDialogsState.isEditingDistribution
                ? `Successfully sent ${props.distributionDialogsState.reportName}, please allow a minute to receive`
                : 'Successfully created Scheduled Distribution',
          },
          ToastType.SUCCESS
        );
        // analytics
        const eventProperties = getReportDistributionAnalyticsProps(
          distributionInput,
          cronExpression,
          dataCollaborators?.collaborators ?? [],
          dataCompanies?.projectCompanies ?? []
        );
        sendAnalytics(
          userReportDistributionEvent(
            reportDistributionEventTypes.REPORT_DISTRIBUTION_CREATE,
            eventProperties
          )
        );
      },
    }
  );

  const [updateReportDistribution] = useUpdateReportDistribution(
    props.projectID,
    {
      cadence: cronExpression,
      reportID: props.distributionDialogsState.reportID,
      collaboratorIDs: distributionInput.selectedCollaboratorIDs,
      startDate: getStartDate(cronExpression, distributionInput.selectedStartingWeek),
      timezone: getTimezone(),
    },
    {
      onCompleted: () => {
        setInitialDistributionInput({ ...distributionInput });
        setHasUnsavedChanges(false);
        setToast({ message: 'Successfully updated Scheduled Distribution' }, ToastType.SUCCESS);

        // analytics
        const eventProperties = getReportDistributionAnalyticsProps(
          distributionInput,
          cronExpression,
          dataCollaborators?.collaborators ?? [],
          dataCompanies?.projectCompanies ?? []
        );
        sendAnalytics(
          userReportDistributionEvent(
            reportDistributionEventTypes.REPORT_DISTRIBUTION_CREATE,
            eventProperties
          )
        );
      },
    }
  );

  const [deleteReportDistribution] = useDeleteReportDistribution(
    props.projectID,
    reportDistributionID,
    {
      onCompleted: () => {
        setToast({ message: 'Successfully deleted Scheduled Distribution' }, ToastType.SUCCESS);
        props.onClose();
        if (props.refetchUserReports) {
          props.refetchUserReports();
        }
        // analytics
        const eventProperties = getReportDistributionAnalyticsProps(
          distributionInput,
          cronExpression,
          dataCollaborators?.collaborators ?? [],
          dataCompanies?.projectCompanies ?? []
        );
        sendAnalytics(
          userReportDistributionEvent(
            reportDistributionEventTypes.REPORT_DISTRIBUTION_DELETE,
            eventProperties
          )
        );
      },
    }
  );

  // if there are no unsaved changes and it is the edit dialog, create the distribution as a send now report
  const onSendNow = () => {
    createReportDistribution({
      variables: {
        projectID: props.projectID,
        input: {
          cadence: SEND_NOW_CRON_EXPRESSION,
          collaboratorIDs: distributionInput.selectedCollaboratorIDs,
          reportID: props.distributionDialogsState.reportID,
          startDate: getStartDate(SEND_NOW_CRON_EXPRESSION, distributionInput.selectedStartingWeek),
          timezone: getTimezone(),
        },
      },
    });
  };

  // Click Handlers
  const onEdit = (input: Partial<DistributionFrequencyInputs>) => {
    const updatedInput = { ...input };

    const hasUnsavedChanges =
      isEditDialog &&
      !areFrequencyInputsEqual(initialDistributionInput, { ...distributionInput, ...updatedInput });
    setHasUnsavedChanges(hasUnsavedChanges);

    // set the cron expression based on the selected options
    const cronExpression = createCronExpression({ ...distributionInput, ...updatedInput });

    // if this is a biweekly cadence, and the selected day of the week has already passed
    // then choose a different starting week option if the current option is invalid
    const validStartingWeekOptions = getStartingWeekOptions(cronExpression);
    const needsToUpdateStartingWeekOption =
      input.selectedStartingWeek && !validStartingWeekOptions.includes(input.selectedStartingWeek);

    if (needsToUpdateStartingWeekOption) {
      updatedInput.selectedStartingWeek = validStartingWeekOptions[0];
    }

    setDistributionInput({ ...distributionInput, ...updatedInput });

    setCronExpression(cronExpression);
  };

  const onSave = () => {
    updateReportDistribution();
  };

  const handleShareClose = () => {
    if (hasUnsavedChanges) {
      onSave();
    } else if (isEditDialog) {
      onSendNow();
    } else {
      createReportDistribution();
    }
  };

  const onDelete = () => {
    deleteReportDistribution();
  };

  // determine the correct start icon to use for the share button

  const shareLabel = getShareLabel(
    isEditDialog,
    hasUnsavedChanges,
    cronExpression === SEND_NOW_CRON_EXPRESSION
  );

  const shareLabelIcon = () => {
    switch (shareLabel) {
      case 'Send Now':
        return <SendDistribution />;
      case 'Schedule Distribution':
        return <ScheduleDistribution />;
      default:
        return null;
    }
  };

  return (
    <>
      <DistributionDialog
        cronExpression={cronExpression}
        distributionInput={distributionInput}
        handleShareClose={handleShareClose}
        isEditDialog={isEditDialog}
        isOpen={props.distributionDialogsState.distributionDialogDataIsOpen}
        onClose={props.onClose}
        onDelete={() => {
          setIsDeleteDialogOpen(true);
        }}
        onEdit={onEdit}
        projectID={props.projectID}
        reportName={props.distributionDialogsState.reportName}
        shareDisabled={!isShareEnabled}
        shareLabel={shareLabel}
        shareLabelIcon={shareLabelIcon()}
      />
      {isDeleteDialogOpen && (
        <DistributionDeleteDialog
          isOpen={isDeleteDialogOpen}
          onCancel={() => setIsDeleteDialogOpen(false)}
          onDelete={onDelete}
        />
      )}
    </>
  );
}
