import Check from '@material-ui/icons/Check';

import { CreateEstimateParameters } from '../CreateEstimateDialogFlowUtils';

import { getTermKey } from './ContentsUtils';

type Props = {
  isNewDraftMilestone: boolean;
  params: CreateEstimateParameters;
  t: TermStore;
};

const PublishedContent = (props: Props) => {
  const {
    isNewDraftMilestone,
    params: {
      displayMilestoneName,
      projectName,
      variables: { type },
    },
    t,
  } = props;
  const term = t.lowerCase(getTermKey(type));

  return (
    <div className="flex flex-row gap-2">
      <div>
        <Check />
      </div>
      <div className="type-body1">
        <span>{`Your ${term} has been added to `}</span>
        <span className="type-heading3">{displayMilestoneName}</span>
        <span>{isNewDraftMilestone ? ', a draft,' : ''}</span>
        <span> within </span>
        <span className="type-heading3">{projectName}</span>
        <span>.</span>
      </div>
    </div>
  );
};

export default PublishedContent;
