import { createStyles } from '@material-ui/core';

import { KomodoTheme } from '../../../../../theme/komodo-mui-theme';

const Styles = (theme: KomodoTheme) =>
  createStyles({
    badge: {
      backgroundColor: 'transparent',
      borderRadius: 0,
      left: 0,
      margin: 0,
      maxWidth: 27,
      padding: 0,
      top: 12,
      width: 'min-content',
    },
    fileIcon: {
      fontSize: 38,
      strokeWidth: 1,
    },
    text: {
      backgroundColor: theme.palette.joinPrimary,
      color: theme.palette.backgroundWhite,
      cursor: 'default',
      flex: '0 1 auto',
      fontFamily: theme.typography.fontFamily,
      fontSize: theme.typography.inputs.fontSize,
      fontWeight: 500,
      height: 14,
      margin: '0px -1px -1px 0px',
      minWidth: 0,
      overflow: 'hidden',
      padding: '0px 2px 0px 2px',
      textTransform: 'uppercase',
      width: 'auto',
    },
  });

export default Styles;
