import { FC } from 'react';

import { MenuItem, SvgIcon, Typography } from '@material-ui/core';
import { ArrowBackIos } from '@material-ui/icons';

import { withStyles } from '../../../theme/komodo-mui-theme';

import InputsSelectReferenceStyles from './InputsSelectReferenceStyles';

type SourceFilterSubeMenuHeaderProps = {
  classes: Classes<typeof InputsSelectReferenceStyles>;
  selectedMarkup: string;
};

const SourceFilterSubMenuHeader: FC<SourceFilterSubeMenuHeaderProps> = ({
  classes,
  selectedMarkup,
}) => {
  return (
    <>
      <MenuItem className={classes.menuSubHeader}>
        <SvgIcon className={classes.svgIconButton}>
          <ArrowBackIos />
        </SvgIcon>
        <Typography className={classes.subMenuMain}>Sources</Typography>
      </MenuItem>
      <MenuItem className={classes.subHeaderText}>
        <Typography className={classes.subMenuText}>
          {`Markup ${selectedMarkup} is applied to all lines and markups without a source, or with a source selected here`}
        </Typography>
      </MenuItem>
    </>
  );
};

export default withStyles(InputsSelectReferenceStyles)(SourceFilterSubMenuHeader);
