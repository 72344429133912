import React, { ReactNode, SyntheticEvent, useState } from 'react';

import { CircularProgress } from '@material-ui/core';

import Verified from '../../Icons/Verified';
import Button from '../Button/Button';

import { Error, Footer, Header, Info, Upload } from '.';

type Props = {
  children?: ReactNode;
  errorText?: string;
  errorTextDescription?: string | string[];
  footerLeft?: ReactNode;
  footerRight?: ReactNode;
  header?: string;
  headerTooltip?: React.ReactNode;
  isLoading: boolean;
  onCancelUploading?: () => void;
  onFileSelected: (file: File | undefined, source: FileSource) => void;
  onHoverHeaderTooltip?: () => void;
  onResetImport?: () => void;
  successText?: string;
  supportText?: string;
};

type InitialProps = {
  children?: ReactNode;
  errorText?: string;
  errorTextDescription?: string | string[];
  header?: string;
  headerTooltip?: React.ReactNode;
  onHoverHeaderTooltip?: () => void;
  onFileSelected: (file: File | undefined, source: FileSource) => void;
  supportText?: string;
};

function InitialContent({
  children,
  errorText,
  errorTextDescription,
  header,
  headerTooltip,
  onFileSelected,
  onHoverHeaderTooltip,
  supportText,
}: InitialProps) {
  const onDragOver = (event: SyntheticEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    event.currentTarget.classList.add('bg-selection-hover');
    event.currentTarget.classList.remove('bg-transparent');
  };
  const onDragLeave = (event: SyntheticEvent<HTMLDivElement>) => {
    event.currentTarget.classList.add('bg-transparent');
    event.currentTarget.classList.remove('bg-selection-hover');
  };
  const errorEl = (
    <Error
      description={errorTextDescription}
      label={errorText || 'Import Failed. Please try again.'}
    />
  );
  const headerEl = <Header label={header} onHelp={onHoverHeaderTooltip} tooltip={headerTooltip} />;
  const supportEl = <div className="text-type-muted type-body2">{supportText}</div>;
  return (
    <div
      className="flex h-full w-full flex-grow flex-col items-center justify-center gap-4 rounded-lg p-4"
      onDragLeave={onDragLeave}
      onDragOver={onDragOver}
      onDrop={(event) => {
        event.preventDefault();
        onFileSelected((event.dataTransfer?.files || [])[0], 'dragAndDrop');
      }}
    >
      {(errorText || errorTextDescription) && errorEl}
      <div className="mb-auto mt-auto flex h-full w-full flex-grow flex-col items-center justify-center gap-4">
        {header && headerEl}
        {children}
        <div className="flex flex-col items-center justify-center gap-2">
          <Info label="Drag & drop, or" />
          <Upload
            onFileSelected={(event) => onFileSelected((event.target?.files || [])[0], 'fileSystem')}
          />
        </div>
        {supportEl}
      </div>
    </div>
  );
}

type LoadingProps = {
  fileName: string;
  onCancelUploading?: () => void;
};

function LoadingContent({ fileName, onCancelUploading }: LoadingProps) {
  return (
    <div className="flex flex-grow flex-col items-center justify-center gap-2 p-4">
      <Info
        icon={<CircularProgress size={18} />}
        label={`Uploading: ${fileName}. This might take a few minutes.`}
      />
      {onCancelUploading && (
        <Button
          data-cy="cancel-uploader-button"
          isDisabled={false}
          label="Cancel"
          onClick={onCancelUploading}
          type="secondary"
        />
      )}
    </div>
  );
}

type SuccessProps = {
  onResetImport?: () => void;
  successText?: string;
};

function SuccessContent({ onResetImport, successText }: SuccessProps) {
  return (
    <div className="flex flex-grow flex-col items-center justify-center gap-4 p-4">
      <Info icon={<Verified />} label={successText || ''} />
      {onResetImport && (
        <button
          className="type-small-link"
          data-cy="uploader-btn-reset"
          onClick={onResetImport}
          type="button"
        >
          Wrong file? Try again.
        </button>
      )}
    </div>
  );
}

export default function Uploader(props: Props) {
  const { isLoading: loading, footerLeft, footerRight, successText } = props;
  const [fileName, setFileName] = useState('');
  const onFileSelected: InitialProps['onFileSelected'] = (file, source) => {
    props.onFileSelected(file, source);
    setFileName(file?.name || '');
  };

  const isInitial = !loading && !successText;
  const isLoading = loading && !successText;
  const isSuccess = !loading && successText;
  const isFooter = !!footerLeft || !!footerRight;

  const className = 'min-h-[128px] h-full flex flex-col rounded-lg border-2 bg-background-primary';

  return (
    <div className="h-full">
      <div className={`${className} ${isInitial ? 'border-dashed' : 'border-solid'}`}>
        {isInitial && <InitialContent {...props} onFileSelected={onFileSelected} />}
        {isLoading && <LoadingContent {...props} fileName={fileName} />}
        {isSuccess && <SuccessContent {...props} />}
      </div>
      {isFooter && <Footer {...props} />}
    </div>
  );
}
