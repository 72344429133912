import { FC } from 'react';

import { ErrorOutline as Error } from '@material-ui/icons';

import { Uncategorized } from '../../../constants';
import { categoryLabel } from '../../../utilities/string';
import ErrorTooltip from '../ErrorTooltip';

interface CategoryRendererProps {
  value: CategoryCell;
  error: string;
  // eslint-disable-next-line react/boolean-prop-naming -- TODO CT-1172: Please update this prop name using F2 :)
  readOnly: boolean;
  onSelect: () => void;
}

const CategoryRenderer: FC<CategoryRendererProps> = ({ readOnly, value, error, onSelect }) => {
  const hasDropdown = !readOnly;

  if (!error && (value.category || value.search === '')) {
    const { categorization, name = '', number = '' } = value.category || {};
    const categoryString = (value.category && categoryLabel(name, number, categorization)) || '';
    const isPlaceholder = categoryString === '';

    return (
      <div className="join-grid-renderer join-grid-category">
        <span
          className={`join-grid-trim-text ${isPlaceholder ? 'join-grid-string-placeholder' : ''}`}
          title={name}
        >
          {isPlaceholder ? Uncategorized : categoryString}
        </span>
        {hasDropdown && (
          <div className="join-grid-dropdown-arrow" onClick={onSelect} onKeyDown={onSelect} />
        )}
      </div>
    );
  }
  return (
    <div className={`join-grid-renderer ${error ? 'join-grid-cell-error' : ''}`}>
      <div className="join-grid-trim-text">{value.search}</div>
      {error && (
        <>
          <div className="push-right">
            <ErrorTooltip title={error}>
              <Error />
            </ErrorTooltip>
          </div>
          {hasDropdown && (
            <div className="join-grid-dropdown-arrow" onClick={onSelect} onKeyDown={onSelect} />
          )}
        </>
      )}
    </div>
  );
};

export default CategoryRenderer;
