import { Button, Dialog, DialogContent } from '../../../scales';

import CategorizationsDialogProject from './CategorizationsDialogProject';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  projectComps: ProjectComp[];
};

export default function CategorizationsDialog(props: Props) {
  return (
    <Dialog
      footerRight={<Button label="Done" onClick={props.onClose} type="primary" />}
      isOpen={props.isOpen}
      onClose={props.onClose}
      title="Select Categorizations"
    >
      <DialogContent className="flex min-h-[330px] flex-col">
        <div className="pb-2 type-body1">
          {props.projectComps.length > 0
            ? 'Set categorizations to compare across projects:'
            : 'Add projects to your report to select categorizations.'}
        </div>
        <div className="flex-grow">
          {props.projectComps.map((projectComp) => (
            <CategorizationsDialogProject
              key={projectComp.input.projectID}
              projectComp={projectComp}
            />
          ))}
        </div>
      </DialogContent>
    </Dialog>
  );
}
